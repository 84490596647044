import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { IconSize } from 'src/core/ds/icon';
import FundingSourceIcon from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceIcon';
import {
  getPaymentMethodIcon,
  getPaymentMethodLabel,
} from 'src/pages/regular-batch-payments/components/menu/fundingSource/utils';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSource: AccountType;
  iconSize?: IconSize;
  size?: string;
};

export const FundingSourceLabel = ({ fundingSource }: Props) => {
  const { icon, imageSrc } = getPaymentMethodIcon(fundingSource);
  const { label, values, nickname } = getPaymentMethodLabel(fundingSource);

  return (
    <>
      <FundingSourceIcon imageSrc={imageSrc} icon={icon} size="4" iconSize={IconSize.s} />
      <Box textStyle="body4Semi" pl={2}>
        {nickname || <MIFormattedText label={label} values={values} />}
      </Box>
    </>
  );
};
