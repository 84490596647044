import { useAnonymousFeature } from '@melio/shared-web/dist/feature-flags';
import { useEffect, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { RegisterPageCondensedBlue } from 'src/pages/auth/RegisterPageCondensedBlue';
import { FeatureFlags, RegistrationFlow } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { RegisterPage } from '../RegisterPage';
import { RegisterPageCondensed } from '../RegisterPageCondensed';
import { RegisterPageSideImageA } from '../RegisterPageSideImageA';
import { RegisterPageSideImageB } from '../RegisterPageSideImageB';
import { registrationLocations } from './locations';

export enum RegistrationPageVariants {
  Control = 'Control',
  Condensed = 'Condensed',
  CondensedBlue = 'CondensedBlue',
  SideViewA = 'SideViewA',
  SideViewB = 'SideViewB',
}
export function AuthRegisterRouter() {
  const match = useRouteMatch();
  const [registrationFlow] = useLocationState<RegistrationFlow>('registrationFlow');
  const [variant, variantLoading] = useFeatureWithTimeout(
    FeatureFlags.GrowthRegisterPageExperiment,
    RegistrationPageVariants.Control
  );

  let RegisterPageComponent = RegisterPage;

  // avoid jumpy rerender between variants, in case it takes some time to load
  if (variantLoading) {
    return <AreaLoader />;
  }

  if (variant === RegistrationPageVariants.Condensed) {
    RegisterPageComponent = RegisterPageCondensed;
  } else if (variant === RegistrationPageVariants.CondensedBlue) {
    RegisterPageComponent = RegisterPageCondensedBlue;
  } else if (variant === RegistrationPageVariants.SideViewA) {
    RegisterPageComponent = RegisterPageSideImageA;
  } else if (variant === RegistrationPageVariants.SideViewB) {
    RegisterPageComponent = RegisterPageSideImageB;
  }

  return (
    <Switch>
      <SmartRoute path={registrationLocations.vendorRegister} exact>
        <RegisterPageComponent registrationFlow={registrationFlow ?? RegistrationFlow.VENDOR} />
      </SmartRoute>
      <SmartRoute path={registrationLocations.spendRegister} exact>
        <RegisterPageComponent registrationFlow={registrationFlow ?? RegistrationFlow.SPEND_MANAGEMENT} />
      </SmartRoute>
      <SmartRoute path={match.path} exact>
        <RegisterPageComponent registrationFlow={registrationFlow ?? RegistrationFlow.REGULAR} />
      </SmartRoute>
    </Switch>
  );
}

/*
  return the defaultValue in case the feature-flag didn't load in a reasonable timeframe
  so that there's no chance of an infinite loading screen
 */
function useFeatureWithTimeout<T>(key: string, defaultValue: T): [T, boolean] {
  const [variant, variantLoading] = useAnonymousFeature(key, defaultValue);
  const [timeoutReached, setTimeoutReached] = useState(false);
  useEffect(() => {
    function onTimeout() {
      if (variantLoading) {
        setTimeoutReached(true);
      }
    }
    const timeoutHandle = setTimeout(onTimeout, 3000);

    return () => clearTimeout(timeoutHandle);
  }, [variantLoading]);

  return [variant, variantLoading ? !timeoutReached : false];
}
