import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { GetProTabsSummaryParams } from 'src/pages/get-pro/types';
import { getProApi } from './api';
import { name } from './consts';

type PayloadType = {
  totalAmount: number;
  totalCount: number;
};

type StateType = {
  alertsSummary: {
    loading: boolean;
    error?: Error;
    summary?: {
      totalAmount: number;
      totalCount: number;
    };
  };
};

export const getAlertsSummarySlice = createApiCallSlice<GetProTabsSummaryParams, StateType, PayloadType>({
  api: getProApi.alertsSummary,
  initialState: {
    alertsSummary: {
      loading: false,
      error: undefined,
      summary: undefined,
    },
  },
  name: generateSliceName(name, 'get_alerts_summary'),
  reducers: {
    [ON_REQUEST]: (state) => {
      state.alertsSummary = {
        ...state.alertsSummary,
        loading: true,
        error: undefined,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      state.alertsSummary = {
        loading: false,
        summary: action?.payload,
      };
    },
    [ON_FAILURE]: (state, action) => {
      state.alertsSummary = {
        ...state.alertsSummary,
        loading: false,
        error: action?.error,
      };
    },
  },
  selectors: {
    alertsSummary: (state) => state[name]?.alertsSummary.summary,
    loading: (state) => state[name]?.alertsSummary.loading,
  },
});
