import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Tooltip from 'src/core/ds/tooltip';
import { ReactComponent as WarningIcon } from 'src/images/get-pro/warning.svg';
import { ReactComponent as WarningHollowIcon } from 'src/images/get-pro/warning-hollow.svg';
import { ReactComponent as WarningHollowPressedIcon } from 'src/images/get-pro/warning-hollow-pressed.svg';
import { ReactComponent as WarningPressedIcon } from 'src/images/get-pro/warning-pressed.svg';
import { alertDotStyle, iconContainerStyle } from './styles';

type Props = {
  showAlertsIndication?: boolean;
  onClick: () => void;
  isPressed: boolean;
};

export const AlertsFilter = ({ showAlertsIndication, onClick, isPressed }: Props) => {
  const getIcon = () => {
    if (showAlertsIndication) {
      if (isPressed) {
        return <WarningHollowPressedIcon />;
      }

      return <WarningHollowIcon />;
    }

    if (isPressed) {
      return <WarningPressedIcon />;
    }

    return <WarningIcon />;
  };

  return (
    <Tooltip placement="top" label={<MIFormattedText label="getPro.tooltip.filterAlerts" />}>
      <Box display="flex">
        {showAlertsIndication && <Box __css={alertDotStyle} data-testid="alerts-indication-dot" />}
        <Box __css={iconContainerStyle} onClick={onClick} data-testid="alerts-filter-button">
          {getIcon()}
        </Box>
      </Box>
    </Tooltip>
  );
};
