import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { WizardIllustration } from 'src/components/layout/WizardElements';
import { IllustrationName } from 'src/core/ds/illustration';
import { useModal } from 'src/helpers/react/useModal';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { financingStore } from 'src/modules/financing/financing-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { billLocations } from 'src/pages/bill/locations';
import CollectVendorEmailModalMessage from 'src/pages/bill/pay/components/CollectVendorEmailModalMessage';
import { useIsFinancingPayFlow } from 'src/pages/bill/pay/hooks/useIsFinancingPayFlow';
import { useSetInstallmentOption } from 'src/pages/bill/pay/hooks/useSetInstallmentOption';
import { getLabelsWithValues } from 'src/pages/bill/pay/PayBillSuccessPageContainer/utils';
import { endPayBillFlowAction } from 'src/redux/payBillWizard/actions';
import {
  getBill,
  getContactEmail,
  getFirstBillIdWithRecurringBill,
  getIsCancelAndRetryPaymentFlow,
  getIsLoading,
  getIsRecurring,
  getPayment,
  getRecurringBill,
} from 'src/redux/payBillWizard/selectors';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, getBillsDefaultFilters, serializePaymentId } from 'src/utils/bills';
import { BillStatus, DeliveryType, DialogType, DialogVariants, VendorManagedByEnum } from 'src/utils/consts';
import { paymentSuccess } from 'src/utils/external-events';
import { getDeliveryMethodById } from '../../utils/billGetters';
import { AccountingPlatformNotification } from '../components/bill-notification/AccountingPlatformNotification';
import { withPayBillData } from '../hoc/withPayBillData';
import { useCheckOwnedVendorExists } from '../hooks/useCheckOwnedVendorExists';

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any>) => void;
};

const eventPage = 'pay-bill';

const PlainPayBillSuccessPageContainer = ({ navigate }: Props) => {
  const site = useSiteContext();
  const { isMobile } = useBreak();
  const dispatch = useDispatch();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const isRecurring = useSelector(getIsRecurring);
  const recurringBill = useSelector(getRecurringBill);
  const isLoading = useSelector(getIsLoading);
  const firstBillIdWithRecurringBill = useSelector(getFirstBillIdWithRecurringBill);
  const orgId = useSelector(getOrgId);
  const companyName = useSelector(getCompanyInfo)?.companyName;
  const vendorName = bill.vendor?.companyName;
  const deliveryMethod = getDeliveryMethodById(bill, payment.deliveryMethodId);
  const deliveryType = deliveryMethod?.deliveryType;
  const { isOwnedVendorExists, isGettingOwnedVendorData } = useCheckOwnedVendorExists({ bill });
  const { vendorId } = bill;
  const vendor = useSelector(vendorsStore.selectors.byId(vendorId));
  const vendorContactEmail = useSelector(getContactEmail);
  const hasVendorEmail =
    (!isGettingOwnedVendorData && isOwnedVendorExists) || !!vendor?.contactEmail || !!vendorContactEmail;
  const analyticsProps = {
    vendorId,
    partialBillId: getBillPaymentIndex(bill),
  };
  const { isConnected, connectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const isVirtualCard = deliveryType === DeliveryType.VIRTUAL_CARD;
  const isDirectoryVendor = bill.vendor?.managedBy === VendorManagedByEnum.MSN;
  const shouldShowNotifyVendorButton = !hasVendorEmail && !isVirtualCard && !isDirectoryVendor;
  const isCancelAndRetryFlow = useSelector(getIsCancelAndRetryPaymentFlow);
  const isFinancingPayment = useIsFinancingPayFlow();
  const numOfInstallments = useSelector(financingStore.selectors.intentIdWithInstallmentOption.numberOfInstallments);
  const { result: setInstallmentOptionResult } = useSetInstallmentOption();

  const handleCollectVendorEmailSuccessModalOk = () => {
    analytics.track(eventPage, 'success-vendor-email-modal-closed', {
      partialBillId: getBillPaymentIndex(bill),
    });
    goBillsList();
  };

  const [CollectVendorEmailSuccessModal, showCollectVendorEmailSuccessModal] = useModal(Dialog, {
    type: DialogType.CONFIRM,
    variant: DialogVariants.SUCCESS,
    title: 'bills.pay.collectVendorEmailModal.successTitle',
    titleValues: { vendorName },
    image: site.theme?.pages?.PayBillSuccessPageContainer?.successImage,
    subtitle: 'bills.pay.collectVendorEmailModal.successSubTitle',
    okButtonText: 'bills.pay.collectVendorEmailModal.confirm',
    onOkAction: handleCollectVendorEmailSuccessModalOk,
    onCancelAction: handleCollectVendorEmailSuccessModalOk,
    showCancel: false,
    modalName: 'successCollectEmail',
  });

  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(CollectVendorEmailModalMessage, {
    onSuccess: showCollectVendorEmailSuccessModal,
    payment,
    bill,
    eventPage,
    modalName: 'collectEmail',
  });

  const goBillsList = () => {
    const billStatus = isCancelAndRetryFlow ? BillStatus.PAID : BillStatus.SCHEDULED;
    const defaultFilters = getBillsDefaultFilters(billStatus);
    analytics.track(eventPage, isRecurring ? 'recurring-done' : 'done', analyticsProps);

    if (site.embeddedMode && !isMobile) {
      paymentSuccess(payment?.originId);

      return;
    }

    dispatch(endPayBillFlowAction());

    if (!site.embeddedMode) {
      if (isRecurring) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const id = serializePaymentId(firstBillIdWithRecurringBill!, payment.id);
        navigate(
          generatePath(billLocations.filteredView, {
            orgId,
            id,
            ...defaultFilters,
          })
        );
      } else {
        // The bill id is taken from the payment, to support payment requests
        const id = serializePaymentId(payment.billId, payment.id);
        navigate(
          generatePath(billLocations.filteredView, {
            orgId,
            id,
            ...defaultFilters,
          })
        );
      }
    } else if (isMobile) {
      navigate(generatePath(billLocations.filteredViewWithoutId, { orgId, ...defaultFilters }));
    }
  };

  const collectVendorEmail = () => {
    showCollectVendorEmailModal();
    analytics.track(eventPage, 'notify-my-vendor', {
      screenName: 'success',
      ...analyticsProps,
    });
  };

  const { title, subtitle, titleValues, textValues, buttonLabel } = getLabelsWithValues({
    payment,
    billVendorCompanyName: bill.vendor?.companyName ?? '',
    deliveryMethod: deliveryMethod ?? undefined,
    companyName,
    isRecurring,
    recurringBill,
    hasVendorEmail,
  });

  useEffect(() => {
    analytics.track('pay-bill', 'success', {
      billId: bill.id,
      deliveryMethod: deliveryMethod?.id,
      paymentMethodId: payment.fundingSourceId,
      isFinancing: isFinancingPayment,
      numOfInstallments,
      repaymentAmount: setInstallmentOptionResult.totalAmount,
      totalAmount: payment.amount,
    });
  }, [
    bill.id,
    deliveryMethod?.id,
    payment.fundingSourceId,
    isFinancingPayment,
    numOfInstallments,
    payment.amount,
    setInstallmentOptionResult.totalAmount,
  ]);

  useEffect(() => {
    if (shouldShowNotifyVendorButton) {
      analytics.track(eventPage, 'exposed-to-notify-my-vendor-button', {
        screenName: 'success',
        ...analyticsProps,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNotifyVendorButton]);

  if (isGettingOwnedVendorData) {
    return <AreaLoader />;
  }

  return (
    <Wrapper>
      {CollectVendorEmailModal}
      {CollectVendorEmailSuccessModal}
      {
        // eslint-disable-next-line
        payment && payment.amount && isNaN(payment.amount) ? (
          <></>
        ) : (
          <SuccessLayoutPage
            illustration={IllustrationName.checkCircle}
            title={title}
            titleValues={titleValues}
            text={subtitle}
            textValues={textValues}
            buttonLabel={buttonLabel}
            buttonAction={shouldShowNotifyVendorButton ? collectVendorEmail : goBillsList}
            linkLabel={shouldShowNotifyVendorButton ? 'bills.pay.payBillSuccess.buttonLabel.done' : ''}
            linkAction={goBillsList}
            isLoading={isLoading}
            hideHeader
            engagementFeed
          >
            {isConnected && connectedAccountingPlatform ? (
              <AccountingPlatformNotification connectedAccountingPlatform={connectedAccountingPlatform} />
            ) : null}
          </SuccessLayoutPage>
        )
      }
    </Wrapper>
  );
};

export const PayBillSuccessPageContainer = compose(withPayBillData())(PlainPayBillSuccessPageContainer);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  ${WizardIllustration} {
    height: 10rem;
    margin-bottom: 2rem;
  }
`;
