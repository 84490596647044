import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Indicator } from './helpers/Indicator';
import { IndicatorOverlay } from './helpers/IndicatorOverlay';

export const BlockedSelectedMethodCard = () => (
  <Flex>
    <Flex align="center">
      <Box position="relative">
        <Icon name={IconNames.wallet} size={IconSize.lg} color="black" alignSelf="center" />
        <IndicatorOverlay>
          <Indicator />
        </IndicatorOverlay>
      </Box>
    </Flex>
    <Flex direction="column" marginInlineStart={4}>
      <Box textStyle="body2Semi" mb={1}>
        <MIFormattedText label="settings.billing.paymentMethod.assignedByFirmTitle" />
      </Box>
      <Box textStyle="body4" color="grey.600">
        <MIFormattedText label="settings.billing.paymentMethod.assignedByFirmSubTitle" />
      </Box>
    </Flex>
  </Flex>
);
