import indefinite from 'indefinite';
import { Children } from 'react';
import Box from 'src/core/ds/box/Box';
import { FONT_WEIGHT } from 'src/core/theme/foundations/textStyles';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';

export const defaultFormatters = {
  b: (...chunks) => <b>{Children.toArray(chunks)}</b>,
  capb: (...chunks) => (
    <Box as="b" textTransform="uppercase">
      {Children.toArray(chunks)}
    </Box>
  ),
  sb: (...chunks) => (
    <Box as="b" fontWeight={FONT_WEIGHT.SEMI}>
      {Children.toArray(chunks)}
    </Box>
  ),
  em: (...chunks) => <em>{Children.toArray(chunks)}</em>,
  un: (...chunks) => (
    <Box as="b" textDecor="underline">
      {Children.toArray(chunks)}
    </Box>
  ),
  br: <br />,
  ind: (string) => indefinite(string, { articleOnly: true }),
  ap: (value) => checkApostropheForPluralPossessive(typeof value === 'object' && value !== null ? value?.key : value),
  colorSubtitle: (...chunks) => (
    <Box as="span" color="grey.700">
      {Children.toArray(chunks)}
    </Box>
  ),
  private: (...chunks) => <span className={FULL_STORY_MASK_RULE_CLASS}>{Children.toArray(chunks)}</span>,
  li: (...chunks) => (
    <Box as="li" pl="0.3rem">
      {Children.toArray(chunks)}
    </Box>
  ),
  ul: (...chunks) => (
    <Box as="ul" p={0} margin={0} paddingInlineStart={5}>
      {Children.toArray(chunks)}
    </Box>
  ),
  i: (...chunks) => (
    <Box as="span" fontStyle="italic">
      {Children.toArray(chunks)}
    </Box>
  ),
  up: (...chunks) => (
    <Box as="span" textTransform="uppercase">
      {Children.toArray(chunks)}
    </Box>
  ),
};
