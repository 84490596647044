import { useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import config from 'src/config';
import { getOrgId } from 'src/redux/user/selectors';
import { convertStringToUrlObject } from 'src/utils/query-utils';
import { NavigatePathType, NavigateType } from 'src/utils/types';

type NavigatorType = {
  navigate: NavigateType;
};

export const useNavigator = (): NavigatorType => {
  const orgId = useSelector(getOrgId);
  const match = useRouteMatch();
  const history = useHistory();

  const navigate = (
    url: NavigatePathType,
    shouldReplaceCurrent = false,
    state: Record<string, unknown> = {},
    exitIframe = false,
    newTab = false
  ) => {
    const parsedUrl = convertStringToUrlObject(url);
    parsedUrl.pathname = generatePath(parsedUrl.pathname ?? '', { orgId, ...match.params });

    if (newTab) {
      const urlAsString = parsedUrl.search ? parsedUrl.pathname : `${parsedUrl.pathname}?${parsedUrl.search}`;
      window.open(urlAsString);

      return null;
    }

    if (exitIframe) {
      const appUrl = new URL(parsedUrl.pathname, config.web.baseUrl);
      appUrl.search = parsedUrl.search;

      if (window.top && window !== window.parent) {
        window.top.location.href = appUrl.href;

        return null;
      }
    }

    if (shouldReplaceCurrent) {
      return history.replace({ ...parsedUrl, state });
    }

    return history.push({ ...parsedUrl, state });
  };

  return {
    navigate,
  };
};
