import isEmpty from 'lodash/isEmpty';
import {
  BusinessDetailsModel,
  BusinessType,
} from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { TAX_ID_EIN_REGEX } from 'src/pages/bill/pay/installments/utils';

const businessTypeToShouldAskForTaxId: Record<BusinessType, boolean> = {
  [BusinessType.SoleProprietorship]: false,
  [BusinessType.LLC]: true,
  [BusinessType.Municipal]: true,
  [BusinessType.Partnership]: true,
  [BusinessType.Corporation]: true,
  [BusinessType.NonProfitCorporation]: true,
  [BusinessType.NGO]: true,
  [BusinessType.Trust]: true,
};

export type ValidatableBusinessTaxRelatedKeys = keyof Pick<BusinessDetailsModel, 'type' | 'taxIdNumber'>;

export const useBusinessTaxRelatedValidator = () => {
  const shouldBusinessTypeFillTaxId = (businessType: BusinessType) => businessTypeToShouldAskForTaxId[businessType];

  const validate = (key: ValidatableBusinessTaxRelatedKeys, modelState: BusinessDetailsModel) => {
    switch (key) {
      case 'type':
        if (isEmpty(modelState.type)) return 'financing.businessDetails.fields.businessType.errors.empty';

        if (!Object.values(BusinessType).includes(modelState.type as BusinessType))
          return 'financing.businessDetails.fields.businessType.errors.invalid';

        break;
      case 'taxIdNumber':
        if (!isEmpty(modelState.type) && shouldBusinessTypeFillTaxId(modelState.type as BusinessType)) {
          if (isEmpty(modelState.taxIdNumber)) return 'financing.businessDetails.fields.taxIdNumber.errors.empty';

          if (!TAX_ID_EIN_REGEX.test(modelState.taxIdNumber))
            return 'financing.businessDetails.fields.taxIdNumber.errors.invalid';
        }

        break;
      default:
        return undefined;
    }

    return undefined;
  };

  return {
    shouldBusinessTypeFillTaxId,
    validator: validate,
  };
};
