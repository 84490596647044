import { useState } from 'react';
import { useSelector } from 'react-redux';
import qbLogo from 'src/images/accounting-software/quickbooks-logo.svg';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { globalLocations } from 'src/pages/locations';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { DisconnectedView } from 'src/pages/settings/components/accounting-software/components/DisconnectedView';
import { ErrorView } from 'src/pages/settings/components/accounting-software/components/ErrorView';
import { useAccountingSoftwareSyncActions } from 'src/pages/settings/hooks/useAccountingSoftwareSyncActions';
import { useConnectAccountingPlatform } from 'src/pages/settings/hooks/useConnectAccountingPlatform';
import { settingsLocations } from 'src/pages/settings/locations';
import { analytics } from 'src/services/analytics';
import { AccountingSoftware, SyncStatus } from 'src/utils/consts';
import { InitialView } from './components/InitialView';
import { QBDConnectedView } from './components/qbd/QBDConnectedView';
import { eventPage } from './consts';

export const SyncWithQBDCard = () => {
  const [historyPush] = useHistoryWithOrgId();
  const [connecting, setConnecting] = useState(false);
  const accountPlatform = useSelector(
    accountingPlatformsStore.selectors.getByName(AccountingSoftware.QUICKBOOKS_DESKTOP)
  );
  const lastFullUpdate = accountPlatform?.lastSyncAt;
  const QBCompanyName = accountPlatform?.accountingPlatformCompanyName;
  const failed = accountPlatform?.syncStatus === SyncStatus.FAILURE;
  const [handleConnectQBD] = useConnectAccountingPlatform({ eventPage, redirect: RedirectOriginPages.SETTINGS });

  const { onDisconnect, onReconnect } = useAccountingSoftwareSyncActions(AccountingSoftware.QUICKBOOKS_DESKTOP);
  const onConnectConfirmed = async () => {
    const redirectUrlOnFinish = settingsLocations.accountingSoftware;
    const exitUrl = settingsLocations.accountingSoftware;
    await handleConnectQBD(AccountingSoftware.QUICKBOOKS_DESKTOP, accountPlatform.id);

    historyPush({
      path: globalLocations.settings.syncQBDLocations.entry,
      state: {
        redirectUrlOnFinish,
        exitUrl,
      },
    });
  };

  const goLinkQBD = () => {
    analytics.track(eventPage, 'link', {
      type: AccountingSoftware.QUICKBOOKS_DESKTOP,
    });

    setConnecting(true);
    onConnectConfirmed();
  };

  if (failed) {
    return (
      <ErrorView onLink={goLinkQBD} accountingSoftwareType={AccountingSoftware.QUICKBOOKS_DESKTOP} logo={qbLogo} />
    );
  }

  if (accountPlatform?.syncStatus) {
    if (accountPlatform?.isActive) {
      return (
        <QBDConnectedView
          QBCompanyName={QBCompanyName}
          lastFullUpdate={lastFullUpdate}
          status={accountPlatform.syncStatus}
          onUnlink={onDisconnect}
        />
      );
    }

    return (
      <DisconnectedView
        companyName={QBCompanyName}
        accountingSoftwareType={AccountingSoftware.QUICKBOOKS_DESKTOP}
        logo={qbLogo}
        onLink={onReconnect}
      />
    );
  }

  return (
    <InitialView
      accountingSoftwareType={AccountingSoftware.QUICKBOOKS_DESKTOP}
      onLink={goLinkQBD}
      logo={qbLogo}
      connecting={connecting}
    />
  );
};
