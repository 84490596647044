import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { analytics } from 'src/services/analytics';
import { ButtonSize } from 'src/utils/consts';

type Props = {
  label: string;
  icon?: IconNames;
  svgIcon?: React.ReactNode;
  variant?: 'iconAndText' | 'inlineIconAndText' | 'svgIconAndText' | 'inlineIconAndTextPrimary';
  size?: 'normal' | 'padded';
  fullWidth?: boolean;
  onClick: (event: React.MouseEvent<HTMLInputElement>) => void | string | Promise<void>;
  testId?: string;
  id?: string;
};

export const IconButton = ({ label, variant, size, onClick, icon, svgIcon, fullWidth, testId, id }: Props) => {
  const onClickWithEvent = (event) => {
    analytics.trackAction(label);

    return onClick && onClick(event);
  };
  switch (variant) {
    case 'iconAndText':
      return (
        <ButtonWithIconAndText fullWidth={fullWidth} onClick={onClickWithEvent} data-testid={testId}>
          <Image src={icon} border="none" w="4.4rem" h="4.4rem" mb={2} />
          <Box>
            <MIFormattedText label={label} />
          </Box>
        </ButtonWithIconAndText>
      );
    case 'inlineIconAndText':
      return (
        <InlineButtonWithIconAndText
          fullWidth={fullWidth}
          onClick={onClickWithEvent}
          padded={size === 'padded'}
          size={size}
          data-testid={testId}
          id={id}
        >
          {icon && (
            <Flex mr={2} userSelect="none">
              <Icon name={icon} color="primary.500" />
            </Flex>
          )}
          <Box textTransform="none">
            <MIFormattedText label={label} />
          </Box>
        </InlineButtonWithIconAndText>
      );
    case 'inlineIconAndTextPrimary':
      return (
        <InlinePrimaryButtonWithIconAndText
          fullWidth={fullWidth}
          onClick={onClickWithEvent}
          padded={size === 'padded'}
          size={size}
          data-testid={testId}
        >
          {icon && (
            <Flex mr={2} userSelect="none">
              <Icon name={icon} color="white" />
            </Flex>
          )}
          <Box textTransform="none" mt={0.5}>
            <MIFormattedText label={label} />
          </Box>
        </InlinePrimaryButtonWithIconAndText>
      );
    case 'svgIconAndText':
      return (
        <SvgIcanoButtonWithText fullWidth={fullWidth} onClick={onClickWithEvent}>
          {svgIcon}
          <Box ml={5} textStyle="body2">
            <MIFormattedText label={label} />
          </Box>
        </SvgIcanoButtonWithText>
      );
    default:
      return <></>;
  }
};

IconButton.defaultProps = {
  variant: 'iconAndText',
  svgIcon: null,
  size: 'normal',
  fullWidth: false,
  testId: null,
};

const Button = ({ children, fullWidth, ...rest }) => (
  <Flex
    borderRadius="lg"
    border="2px"
    borderColor="transparent"
    cursor="pointer"
    letterSpacing="0.0007rem"
    overflow="hidden"
    justify="center"
    align="center"
    textAlign="center"
    outline="none"
    flexWrap="wrap"
    boxSizing="border-box"
    minWidth="12rem"
    color="black"
    bgColor="white"
    boxShadow={300}
    px={6}
    textStyle="body4Semi"
    w={fullWidth ? 'full' : 'unset'}
    _hover={{ boxShadow: 'none', borderColor: 'primary.500' }}
    _active={{ boxShadow: 'none', borderColor: 'primary.500' }}
    {...rest}
  >
    {children}
  </Flex>
);

const SvgIcanoButtonWithText = ({ children, fullWidth, ...rest }) => (
  <Button h="9rem" justify="flex-start" fullWidth={fullWidth} {...rest}>
    {children}
  </Button>
);

const ButtonWithIconAndText = ({ children, fullWidth, ...rest }) => (
  <Button h="12.7rem" flexDirection="column" fullWidth={fullWidth} {...rest}>
    {children}
  </Button>
);

const InlineButtonWithIconAndText = ({ children, fullWidth, padded, size, ...rest }) => (
  <Button
    flexDirection="row"
    p={padded ? 5 : 0}
    h={size === 'normal' ? 12 : 'full'}
    minH={12}
    textTransform="capitalize"
    textStyle={size === ButtonSize.TINY ? 'body4Semi' : 'body3Semi'}
    fullWidth={fullWidth}
    {...rest}
  >
    {children}
  </Button>
);

const InlinePrimaryButtonWithIconAndText = ({ children, fullWidth, padded, size, ...rest }) => (
  <InlineButtonWithIconAndText
    bgColor="primary.500"
    color="white"
    fullWidth={fullWidth}
    padded={padded}
    size={size}
    {...rest}
  >
    {children}
  </InlineButtonWithIconAndText>
);
