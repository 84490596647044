import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';

type Props = {
  label?: string;
  text?: string;
  to?: string;
  className?: string;
  onClick?: (event: any) => void;
  testId?: string;
  target?: string;
  disabled?: boolean;
};

export const MIInlineLink = ({ label, text, className, onClick, to, testId, target = '_blank', disabled }: Props) =>
  to ? (
    <StyledMIInlineLink
      target={target}
      href={to}
      className={className}
      data-testid={testId}
      onClick={(event) => {
        text && analytics.trackAction(text, {});
        label && analytics.trackAction(label, {});
        onClick && onClick(event);
      }}
      disabled={disabled}
    >
      {text && <span>{text}</span>}
      {label && <MIFormattedText label={label} />}
    </StyledMIInlineLink>
  ) : (
    <StyledMIInlineButton
      onClick={(event) => {
        text && analytics.trackAction(text, {});
        label && analytics.trackAction(label, {});
        onClick && onClick(event);
      }}
      className={className}
      data-testid={testId}
      disabled={disabled}
    >
      {text && <span>{text}</span>}
      {label && <MIFormattedText label={label} />}
    </StyledMIInlineButton>
  );

const commonStyles = css<{ disabled?: boolean }>`
  color: ${(props) => (props.disabled ? props.theme.text.color.label : props.theme.colors.brand)};
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  height: 1.6rem;
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  letter-spacing: 0;

  &:hover {
    text-decoration: ${(props) => (props.disabled ? 'none' : 'underline')};
  }

  &:active {
    color: rgba(92, 53, 203, 1);
  }
`;

export const StyledMIInlineButton = styled.button<{ disabled?: boolean }>`
  ${commonStyles};
  font-size: ${(props) => props.theme.text.size.button};

  @media ${devices.mobile}, ${devices.phablet} {
    white-space: nowrap;
  }
  ${(props) => props.theme?.components?.MIInlineLink?.StyledMIInlineButton}
`;

export const StyledMIInlineLink = styled.a`
  ${commonStyles};
  font-size: ${(props) => props.theme.text.size.button};
  text-decoration: none;
`;
