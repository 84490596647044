import { ChangeEvent } from 'react';
import { TextField } from 'src/core/ds/form/fields';
import { ModelViewField } from 'src/ui/form';

type AccountNumberFieldProps = {
  model: ModelViewField<string>;
  errorMessage?: string;
};

export const AccountNumberField: React.FC<AccountNumberFieldProps> = ({ model, errorMessage }) => (
  <TextField
    testId="input-accountNumber"
    id="accountNumber"
    label="vendors.deliveryMethods.international.billing.accountNumber"
    placeholder="vendors.deliveryMethods.international.billing.accountNumberPlaceholder"
    errorMessage={errorMessage}
    model={model}
    onChange={(e: ChangeEvent<HTMLInputElement>) => {
      model.onChange({ value: e.target.value.replace(/-/g, '') });
    }}
    isRequired
  />
);
