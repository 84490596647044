import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { VendorCardTitle } from 'src/components/common/VendorCardTitle';
import { ListItemContainer } from 'src/components/list/ListItemContainer';
import Box from 'src/core/ds/box';
import { HStack, VStack } from 'src/core/ds/stack';
import { InvoiceStatus, MiMoneyFlavor } from 'src/utils/consts';
import { StatusLabel } from './StatusLabel';

const mapStatusToFlavor = {
  [InvoiceStatus.PAID]: MiMoneyFlavor.POSITIVE,
  [InvoiceStatus.CANCELLED_BY_CUSTOMER]: MiMoneyFlavor.NEGATIVE,
  [InvoiceStatus.FAILED]: MiMoneyFlavor.NEGATIVE,
};

type Props = {
  name: string;
  amount: number;
  updatedAt: Date;
  invoiceNumber?: string;
  deliveryETA?: Date;
  status: InvoiceStatus;
  onClick?: () => void;
};

export const PaymentCard = ({ name, amount, updatedAt, invoiceNumber, deliveryETA, status, onClick }: Props) => (
  <ListItemContainer testId="msn-portal-mobile-dashboard-payment-card" onClick={onClick}>
    <VStack spacing={4}>
      <HStack w="full" justifyContent="space-between">
        <VendorCardTitle name={name} />
        <StatusLabel status={status} />
      </HStack>
      <HStack w="full" justifyContent="space-between">
        <VStack alignItems="start" spacing={0}>
          <Box textStyle="body4Semi" color="grey.700">
            <MIFormattedText
              label="incomingPayments.mobile.paymentCard.updated"
              values={{ date: <MIFormattedDate date={updatedAt} /> }}
            />
          </Box>
          {invoiceNumber && (
            <Box textStyle="body4" color="grey.700">
              {invoiceNumber.startsWith('#') ? invoiceNumber : `#${invoiceNumber}`}
            </Box>
          )}
        </VStack>
        <MIMoney
          privateData
          amount={amount}
          showAmountArrowIcon={status === InvoiceStatus.PAID}
          flavor={mapStatusToFlavor[status]}
        />
      </HStack>
      {deliveryETA && (
        <HStack borderTop="1px" borderColor="grey.400" w="full" justifyContent="space-between">
          <Box textStyle="body4" color="grey.700" mt={4}>
            <MIFormattedText
              label="incomingPayments.mobile.paymentCard.deliveryEta"
              values={{ date: <MIFormattedDate date={deliveryETA} /> }}
            />
          </Box>
        </HStack>
      )}
    </VStack>
  </ListItemContainer>
);
