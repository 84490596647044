import { useFeature } from '@melio/shared-web/dist/feature-flags';
import successAnimation from 'src/animations/get-paid-onboarding/success.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { FeatureFlags } from 'src/utils/consts';
import { useVendorCompanyInfoConfig } from '../context/VendorCompanyInfoContext';
import { nextStepsListStyle } from './next-step-list/styles';

type Props = {
  goToDashboard: () => void;
  onNext: () => void;
  onExit: () => void;
};

export const VendorCompanyAddedPage = ({ goToDashboard, onExit, onNext }: Props) => {
  const {
    title,
    subTitle,
    secondaryActionButtonLabel,
    nextLabel,
    content,
  } = useVendorCompanyInfoConfig<'VendorCompanyAdded'>({
    page: 'VendorCompanyAdded',
  });

  const [isDeferredOnboardingEnabled] = useFeature<boolean>(FeatureFlags.EnableGetPaidDeferredOnboarding, false);

  return (
    <StepLayoutPage
      title={title}
      subtitle={subTitle}
      goExit={onExit}
      relativeStep={1}
      animatedIllustration={{
        animationData: successAnimation,
        loop: false,
      }}
      secondaryActionButtonLabel={!isDeferredOnboardingEnabled ? secondaryActionButtonLabel : undefined}
      onSecondaryActionButtonClick={goToDashboard}
      nextLabel={nextLabel}
      onNext={onNext}
    >
      <Box __css={nextStepsListStyle} data-testid="vendor-company-added-page-content">
        {content}
      </Box>
    </StepLayoutPage>
  );
};
