import head from 'lodash/head';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { AppLayout } from 'src/components/layout/AppLayout';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { profileStore } from 'src/modules/profile/profile-store';
import { CreateRouter } from 'src/pages/contacts/create/CreateRouter';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getContactsTabs } from 'src/pages/contacts/utils';
import ContactsListPageContainer from './list/ContactsListPageContainer';

export function ContactsRouter() {
  const match = useRouteMatch();
  const profile = useSelector(profileStore.selectors.profile);

  return (
    <Switch>
      <SmartRoute path={contactsLocations.create.base}>
        <CreateRouter />
      </SmartRoute>
      <SmartRoute path={contactsLocations.index}>
        <AppLayout>
          <ContactsListPageContainer />
        </AppLayout>
      </SmartRoute>
      <SmartRoute path={contactsLocations.dashboard}>
        <Redirect
          to={generatePath(contactsLocations.index, {
            ...match.params,
            type: head(getContactsTabs(profile?.registrationFlow)),
          })}
        />
      </SmartRoute>
    </Switch>
  );
}
