import { getValidationErrors } from '@melio/sizzers-js-common';
import head from 'lodash/head';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore from 'src/modules/organizations/organizations-store';
import usersStore from 'src/modules/users/users-store';
import { useLegalAddress } from 'src/pages/bill/pay/hooks/useLegalAddress';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { getCompanyInfo, getOrgId, getProfile } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import {
  convertLegalAddressToGoogleAddress,
  convertToServerAddress,
  convertToServerLegalAddress,
} from 'src/utils/address';
import { AddressFormControlMode, FORM_AUTOCOMPLETE, NotificationVariant } from 'src/utils/consts';
import { GoogleCombinedAddressType } from 'src/utils/types';
import { analytics, Context, Event, Page } from './SpendManagementAnalytics';

type LegalInfoType = {
  legalCompanyName: string | null;
  legalGooglePlaceId?: string | null;
  contactFirstName: string | null;
  contactLastName: string | null;
  phone: string | null;
};

const getValidationErrorForField = (address: GoogleCombinedAddressType) => (
  key: string,
  value: string
): string | undefined => {
  if (key === 'legalGooglePlaceId') {
    const legalGooglePlaceIdValue = {
      legalGooglePlaceId: address.placeId === 'synced-from-qbo' ? null : address.placeId,
    };
    const addressValue = { ...convertToServerLegalAddress(address), ...legalGooglePlaceIdValue };
    const addressValidationErrors = getValidationErrors('companyInfo', addressValue, [
      'legalGooglePlaceId',
      'legalZipCode',
      'legalAddressLine1',
    ]);

    return addressValidationErrors.legalGooglePlaceId || head(Object.values(addressValidationErrors));
  }

  const validationErrors = getValidationErrors('companyInfo', { [key]: value }, [
    'legalCompanyName',
    'contactFirstName',
    'contactLastName',
    'phone',
  ]);

  return validationErrors[key];
};
export function Onboarding() {
  const dispatch = useDispatch();
  const updateCompanyInfo = getStoreActions(organizationStore)(dispatch).update;
  const orgId: ReturnType<typeof getOrgId> = useSelector(getOrgId);
  const companyInfo: ReturnType<typeof getCompanyInfo> = useSelector(getCompanyInfo);
  const legalAddress = useLegalAddress(companyInfo);

  const { legalCompanyName, contactFirstName, contactLastName, phone } = companyInfo;
  const profile: ReturnType<typeof getProfile> = useSelector(getProfile);
  const isUpdatingUserInfo: boolean = useSelector(usersStore.selectors.update.status(profile.id))?.loading;
  const isUpdatingCompanyInfo: boolean = useSelector(organizationStore.selectors.update.status(orgId))?.loading;
  const [historyPush] = useHistoryWithOrgId();

  const legalInfo = useMemo<LegalInfoType>(
    () => ({
      legalCompanyName,
      legalGooglePlaceId: legalAddress.legalGooglePlaceId,
      contactFirstName,
      contactLastName,
      phone,
    }),
    [legalCompanyName, legalAddress.legalGooglePlaceId, contactFirstName, contactLastName, phone]
  );

  const [address, setAddress] = useState(convertLegalAddressToGoogleAddress(legalAddress));

  const isUpdateCompanyInfoAllowed =
    !companyInfo.legalCompanyName ||
    !companyInfo.companyName ||
    !companyInfo.legalFormattedAddress ||
    !companyInfo.formattedAddress ||
    !companyInfo.contactFirstName ||
    !companyInfo.contactLastName ||
    !companyInfo.companyType ||
    !companyInfo.phone;

  const [modelView, formActions, validationErrors] = useForm<LegalInfoType>(legalInfo, {
    submit: async (values) => {
      if (isUpdateCompanyInfoAllowed) {
        try {
          await updateCompanyInfo({
            id: orgId,
            ...values,
            companyName: values.legalCompanyName,
            companyType: 'smb',
            ...convertToServerLegalAddress(address),
            ...convertToServerAddress(address),
          });
          analytics.track(Page.ORG_DETAILS, Context.ONBOARDING_PROCESS, Event.CONTINUE_SUCCESS);
        } catch (error: any) {
          analytics.track(Page.ORG_DETAILS, Context.ONBOARDING_PROCESS, Event.CONTINUE_FAILED);
          pushNotification({
            type: NotificationVariant.ERROR,
            msg: error?.code ? `server.${error?.code}` : 'serverErrors.ERR',
          });
        }
      }

      historyPush({ path: spendManagementLocations.enroll });
    },
    validator: getValidationErrorForField(address),
  });

  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (submitted) {
      if (Object.keys(validationErrors).some((fieldName) => !!validationErrors[fieldName])) {
        analytics.track(Page.ORG_DETAILS, Context.ONBOARDING_PROCESS, Event.VALIDATION_ERROR, validationErrors);
      }

      setSubmitted(false);
    }
  }, [validationErrors, submitted]);

  const onAddressChange = (address) => {
    setAddress(address);

    if (address.placeId) {
      modelView.setValidationErrors({ ...validationErrors, legalGooglePlaceId: '' });
    }
  };

  return isUpdatingCompanyInfo || isUpdatingUserInfo ? (
    <AreaLoader />
  ) : (
    <StepLayoutPage
      title="spendManagement.pages.onboarding.title"
      onNext={() => {
        analytics.track(Page.ORG_DETAILS, Context.ONBOARDING_PROCESS, Event.CLICK_SAVE);

        setSubmitted(true);

        formActions.submit();
      }}
      nextLabel="spendManagement.pages.onboarding.buttonLabel"
      testId="spend-onboarding-page"
    >
      <PrivateDataContainer>
        <TextField
          id="legalCompanyName"
          label="bills.pay.confirm.completeLegalInfo.legalName.inputTitle"
          value={modelView.legalCompanyName.value || ''}
          onChange={(event) => modelView.legalCompanyName.onChange({ value: event.target.value })}
          isRequired
          errorMessage={validationErrors.legalCompanyName}
          helperText="bills.pay.confirm.completeLegalInfo.legalName.inputHint"
          autoFocus
        />
        <MIAddressAutocomplete
          id="legalAddress"
          addressLabel="bills.pay.confirm.completeLegalInfo.legalAddress.inputTitle"
          aptNumberLabel="form.addressAutocomplete.aptNumberLabel"
          emptyAddressLabel="bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel"
          emptyNumberLabel="form.addressAutocomplete.emptyNumberLabel"
          address={address}
          mode={AddressFormControlMode.INLINE}
          onChange={onAddressChange}
          errorMessage={validationErrors.legalGooglePlaceId}
          required
          hideSuite
          autoComplete={FORM_AUTOCOMPLETE.OFF}
        />
      </PrivateDataContainer>
      <RowContainer>
        <TextField
          id="contactFirstName"
          label="onboarding.companyInfo.contacts.inputTitleFirstName"
          value={modelView.contactFirstName.value || ''}
          onChange={(event) => modelView.contactFirstName.onChange({ value: event.target.value })}
          errorMessage={validationErrors.contactFirstName}
          isRequired
        />
        <TextField
          id="contactLastName"
          label="onboarding.companyInfo.contacts.inputTitleLastName"
          value={modelView.contactLastName.value || ''}
          onChange={(event) => modelView.contactLastName.onChange({ value: event.target.value })}
          errorMessage={validationErrors.contactLastName}
          isRequired
        />
      </RowContainer>
      <PrivateDataContainer>
        <MaskField
          id="phone"
          label="onboarding.companyInfo.contacts.inputTitlePhone"
          helperText="onboarding.companyInfo.contacts.inputHintPhone"
          value={phone}
          type="tel"
          format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
          onChange={({ value }) => modelView.phone.onChange({ id: 'phone', value })}
          errorMessage={validationErrors.phone}
          isRequired
        />
      </PrivateDataContainer>
    </StepLayoutPage>
  );
}

const RowContainer = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 2rem;
  }

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;
