import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useAbortControllerSignal } from 'src/hooks/useAbortControllerSignal';
import { financingStore } from 'src/modules/financing/financing-store';
import { SetInstallmentsOptionsResponse } from 'src/pages/bill/pay/installments/types';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { capture } from 'src/utils/error-tracking';

export const useSetOptionOnRepaymentTerms = (): {
  setOption: (deliveryDate: Date, numOfInstallments: number) => void;
  isLoading: boolean;
  error: boolean;
  resetError: () => void;
  result: SetInstallmentsOptionsResponse;
} => {
  const [error, setError] = useState<boolean>(false);
  const orgId = useSelector(getOrgId);
  const payment = useSelector(getPayment);
  const { loading, data: result } = useSelector(financingStore.selectors.setInstallmentOption.status);
  const { setInstallmentOption } = useStoreActions(financingStore);
  const intentId = useSelector(financingStore.selectors.intentIdWithInstallmentOption.intentId);
  const { getAbortControllerSignal } = useAbortControllerSignal();

  const setOption = async (deliveryDate: Date, numOfInstallments: number) => {
    const signal = getAbortControllerSignal();
    setError(false);
    try {
      await setInstallmentOption({
        orgId,
        intentId,
        numberOfInstallments: numOfInstallments,
        paymentAmount: payment.amount,
        scheduleDate: deliveryDate,
        conf: { signal },
      });
    } catch (e) {
      setError(true);
      capture(e as Error, {
        message: 'an error occurred while setting an installment option',
        orgId,
        intentId,
        numOfInstallments,
        scheduleDate: deliveryDate,
      });
      throw e;
    }
  };

  const resetError = () => {
    setError(false);
  };

  return { setOption, result, isLoading: loading, error, resetError };
};
