import { AmexCardEnum } from '@melio/spend-management-api-axios-client';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';

export const CardCell: React.FC<CellProps<any>> = ({ cell: { value } }) => {
  const statusLabel = value.status && `spendManagement.pages.dashboard.cardStatus.${value.status}.label`;

  return useMemo(() => {
    const STATUS_COLORS = {
      [AmexCardEnum.Active]: BadgeColors.SUCCESS,
    };

    return (
      <Box isTruncated textStyle="body3" paddingBottom="12px" paddingTop="12px">
        {value.nickname ? (
          <>
            <Box paddingBottom="4px">{value.nickname}</Box>
            <Box textStyle="body4">{value.last5 && `••• ${value.last5}`}</Box>
          </>
        ) : (
          <>
            <Box textStyle="body3">
              {value.last5 && `••• ${value.last5}`}
              {value.status && (
                <>
                  &nbsp;
                  <Badge color={STATUS_COLORS[value.status] || BadgeColors.NEUTRAL} label={statusLabel} />
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    );
  }, [statusLabel, value]);
};
