import Highlighter from 'react-highlight-words';
import { Avatar } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type Props = {
  name: string;
  search?: string;
  showBadge?: boolean;
};

export const VendorCardTitle = ({ name, search = '', showBadge = true }: Props) => (
  <Flex align="center" overflow="hidden" grow={1} h="2.4rem">
    {showBadge && <Avatar name={name} textColor="white" />}
    <Box __css={VendorNameStyle}>
      <Highlighter searchWords={[search]} autoEscape textToHighlight={name} />
    </Box>
  </Flex>
);

const VendorNameStyle = {
  color: 'black',
  flex: 1,
  textStyle: 'body2Semi',
  display: 'table-cell',
  paddingLeft: '1.2rem',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};
