import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import negate from 'lodash/negate';
import { useCallback } from 'react';
import { MIAutocomplete } from 'src/components/common/MIAutocomplete';
import { MIAutocompleteProps } from 'src/components/common/MIAutocompleteExtras';
import { getAddressSuggestion } from 'src/services/address-autocomplete';
import { analytics } from 'src/services/analytics';
import { ModelViewField } from 'src/ui/form';
import { AddressType } from 'src/utils/types';

const addressToLabel = (address: AddressType) => Object.values(address).filter(negate(isEmpty)).join(', ');

const searchSuggestions = async (value) => {
  const results = await getAddressSuggestion(value);

  return map(results, (address) => ({
    label: addressToLabel(address),
    value: address,
  }));
};

type Props = Omit<MIAutocompleteProps<AddressType>, 'getSuggestions' | 'onSelected'> & {
  onSelected: (value: AddressType | null) => unknown;
  eventPage: string;
  privateData?: boolean;
  onSuggestionsLoadError?: () => void;
};

export const MIAddressAutocompleteSmartyStreets = <T,>(props: Props & { model: ModelViewField<T> }) => {
  const { model, eventPage, privateData, onSuggestionsLoadError } = props;

  const onSelected = useCallback(
    (suggestion) => {
      props.onSelected(suggestion?.value ?? null);
      analytics.track(eventPage, 'suggested-option-address-selected');
    },
    [props, eventPage]
  );

  return (
    <MIAutocomplete
      {...props}
      text={model.value}
      onType={(value) => model.onChange({ value })}
      onSelected={onSelected}
      getSuggestions={searchSuggestions}
      privateData={privateData}
      onSuggestionsLoadError={onSuggestionsLoadError}
    />
  );
};
