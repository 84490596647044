import { PureComponent } from 'react';
import { compose } from 'recompose';
import MISearchBar from 'src/components/common/MISearchBar';
import { withListSearchBarContext } from 'src/hoc/withListSearchBarContext';
import { ListSearchBarContextType } from 'src/utils/types';

type Props = {
  autoFocus?: boolean;
  eventPage: string;
  onClear?: () => void;
} & ListSearchBarContextType;

class ListSearchBar extends PureComponent<Props> {
  onChangeSearch = ({ value }) => {
    this.props.setContextSearchInputValue(value);
  };

  onSubmit = () => {
    this.props.setContextSearchFilterValue(this.props.contextSearchInputValue);
  };

  onClear = () => {
    const { clearSearchContext, onClear } = this.props;

    if (onClear) {
      onClear();
    }

    clearSearchContext();
  };

  render() {
    const { autoFocus, contextSearchInputValue, eventPage, searchInputRef } = this.props;

    return (
      <MISearchBar
        id="listSearch"
        value={contextSearchInputValue}
        autoFocus={autoFocus}
        placeholder="list.search"
        eventPage={eventPage}
        onChange={this.onChangeSearch}
        onClear={this.onClear}
        onSubmit={this.onSubmit}
        inputRef={searchInputRef}
      />
    );
  }
}

export default compose(withListSearchBarContext())(ListSearchBar);
