import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useState } from 'react';
import styled from 'styled-components';
import { AccordionItem } from 'src/pages/settings/faq/AccordionItem';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/featureFlags';
import { SUPPORT_FAQ, SUPPORT_FAQ_NO_FAST_CHECKS } from '../constants';

type Props = {
  supportEmail: string;
};

export const FaqAccordion = ({ supportEmail }: Props) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [isFastCheckEnabled] = useFeature<boolean>(FeatureFlags.FastCheckEnabled, false);

  const onToggle = (id: string) => {
    analytics.trackAction('toggle-faq-item', { id });

    if (id === selectedItem) {
      setSelectedItem('');
    } else {
      setSelectedItem(id);
    }
  };

  const supportFAQ = isFastCheckEnabled ? SUPPORT_FAQ : SUPPORT_FAQ_NO_FAST_CHECKS;

  return (
    <AccordionList>
      {supportFAQ.map(({ question, answer }) => (
        <AccordionItem
          id={question}
          onToggle={onToggle}
          isOpen={selectedItem === question}
          key={question}
          question={question}
          answer={answer}
          supportEmail={supportEmail}
        />
      ))}
    </AccordionList>
  );
};

const AccordionList = styled.ul`
  list-style: none;
  margin: 2rem 0;
  padding: 0;
  border-radius: 0.4rem;
  overflow: hidden;
`;
