import isEmpty from 'lodash/isEmpty';
import { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import NewDeliveryMethodForm from 'src/pages/vendor/components/NewDeliveryMethodForm';
import { ContentWrapper, Footer, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import WhitePageAddressContainer from 'src/pages/vendor/components/WhitePageAddressContainer';
import { shiftToDebitLocations } from 'src/pages/vendor/shift-vendor-to-debit/locations';
import { ModelView } from 'src/ui/form';
import { DeliveryType } from 'src/utils/consts';
import { AddressType } from 'src/utils/types';
import { ShiftToDebitDeliveryMethodType, ShiftToDebitState } from './hoc/shift-to-debit-hooks';
import useAddCardAccount from './hooks/useAddCardAccount';

type Props = {
  token: string;
  state: ShiftToDebitState;
  deliveryMethodMV: ModelView<ShiftToDebitDeliveryMethodType>;
  onValidateAddress: MouseEventHandler<HTMLButtonElement>;
};

const AddAddressDetailsPage = ({ token, state, deliveryMethodMV, onValidateAddress }: Props) => {
  const history = useHistory();
  const { payment, organization } = state;
  const { onSubmit, onClearAddressState, isLoading, errorModal } = useAddCardAccount(token, payment, deliveryMethodMV);

  const [whitePageAddressView, setWhitePageAddressView] = useState(false);
  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId)
  );

  const goOnPrev = async () => {
    await onClearAddressState();
    history.push(generatePath(shiftToDebitLocations.addMethod, { token }));
  };

  const handleWhitePageAddress = useCallback(() => {
    setWhitePageAddressView(
      whitePageAddress &&
        !isEmpty(whitePageAddress) &&
        ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid)
    );

    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit();
    }
  }, [whitePageAddress]);

  useEffect(handleWhitePageAddress, [handleWhitePageAddress]);

  if (whitePageAddressView) {
    return (
      <>
        {errorModal}
        <WhitePageAddressContainer
          setWhitePageAddressView={setWhitePageAddressView}
          isLoading={isAddressLoading || isLoading}
          whitePageAddress={whitePageAddress}
          submit={onSubmit}
          model={deliveryMethodMV.cardAccount as ModelView<AddressType>}
          deliveryMethodType={DeliveryType.CARD}
        />
      </>
    );
  }

  return (
    <OutsideLayout hideLogin>
      {errorModal}
      <Header
        payment={payment}
        organization={organization}
        onPrev={goOnPrev}
        showFullHeader
        subTitle="vendors.deliveryMethods.shiftToDebit.subtitle"
        hideInvoice
      />
      <ContentWrapper title="vendors.deliveryMethods.shiftToDebit.addressDetails.title">
        <NewDeliveryMethodForm
          submit={onValidateAddress}
          selectedDeliveryMethod={DeliveryType.CARD}
          cardModel={deliveryMethodMV.cardAccount}
        />
        <Button
          label="vendors.deliveryMethods.shiftToDebit.addressDetails.submit"
          data-testid="shiftToDebit.addressDetails.submit"
          onClick={onValidateAddress}
          isLoading={isAddressLoading || isLoading}
          size={ButtonSizes.lg}
          isFullWidth
        />
        <Footer companyName={organization?.companyName} />
      </ContentWrapper>
    </OutsideLayout>
  );
};

export default AddAddressDetailsPage;
