export const fundingSourcesLocations = {
  base: [
    '/funding-sources/:id/micro-deposits/verify/:token',
    '/funding-sources/:id/micro-deposits/success',
    '/funding-sources/:id/micro-deposits/blocked',
  ],
  microDepositsVerify: '/funding-sources/:id/micro-deposits/verify/:token',
  microDepositsSuccess: '/funding-sources/:id/micro-deposits/success',
  microDepositsBlocked: '/funding-sources/:id/micro-deposits/blocked',
};
