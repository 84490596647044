import { generatePath } from 'react-router-dom';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getProLocations } from 'src/pages/get-pro/locations';
import { globalLocations } from 'src/pages/locations';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { NavigateType } from 'src/utils/types';
import { onboardingLocations } from '../../locations';
import { Origin } from './types';

export const getPrefix = (origin: Origin) =>
  origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION ? 'MsnPortal' : '';

export const createLabel = ({ subject, prefix, suffix }: { subject: string; prefix: string; suffix: string }) =>
  `onboarding.vendorCompanyInfo.${subject}${prefix}.${suffix}`;

export const handleLegalInfoNextStep = async ({
  origin,
  navigate,
  state,
}: {
  origin: Origin;
  navigate: NavigateType;
  state: Record<string, unknown>;
}) => {
  if (origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION) {
    navigate(onboardingLocations.vendorCompanyInfo.added, false, { origin });
  } else if (origin === DeliveryMethodOrigin.GET_PAID_SHOW_HANDLE) {
    navigate(getPaidLocations.create.options, false, { origin });
  } else if (origin === DeliveryMethodOrigin.GET_PAID_SHARE_INVOICE_URL) {
    navigate(`${getPaidLocations.create.share}?id=${state.paymentRequestId}`, false, { origin, ...state });
  } else if (origin === DeliveryMethodOrigin.GET_PAID_CREATE_PAYMENT_REQUEST) {
    navigate(`${getPaidLocations.create.share}?id=${state.paymentRequestId}`, false, state);
  } else if (origin === DeliveryMethodOrigin.GET_PAID_CREATE_BATCH_PAYMENT_REQUESTS) {
    navigate(getProLocations.batchSend, true, state);
  } else {
    navigate(onboardingLocations.vendorCompanyInfo.businessType, false, { origin });
  }
};

export const handleLegalInfoPrevStep = ({
  origin,
  navigate,
  state,
}: {
  origin: Origin;
  navigate: NavigateType;
  state: Record<string, unknown>;
}) => {
  if (origin === DeliveryMethodOrigin.GET_PAID_SHOW_HANDLE) {
    navigate(getPaidLocations.create.options, true);
  } else if (origin === DeliveryMethodOrigin.GET_PAID_SHARE_INVOICE_URL) {
    navigate(`${getPaidLocations.create.share}?id=${state.paymentRequestId}`, true, { ...state, origin: undefined });
  } else if (origin === DeliveryMethodOrigin.GET_PAID_CREATE_PAYMENT_REQUEST) {
    navigate(`${getPaidLocations.create.share}?id=${state.paymentRequestId}`, true, state);
  } else if (origin === DeliveryMethodOrigin.GET_PAID_CREATE_BATCH_PAYMENT_REQUESTS) {
    navigate(getPaidLocations.dashboard, true);
  } else {
    navigate(onboardingLocations.vendorCompanyInfo.logo, false, { origin });
  }
};

export const goToAddReceivingMethod = ({
  origin = '',
  navigate,
  orgId,
  ownedVendorId,
  shouldSkipAbsorbFeesStep,
}: {
  origin?: Origin;
  navigate: NavigateType;
  orgId: number;
  ownedVendorId: null | number;
  shouldSkipAbsorbFeesStep: boolean;
}) =>
  navigate(
    generatePath(globalLocations.receivingMethod.ach.addFromGetPaidOnboardingWrapper.base, {
      orgId,
      vendorId: ownedVendorId ?? undefined,
    }),
    false,
    {
      preservedState: {
        origin: origin || DeliveryMethodOrigin.ONBOARDING,
      },
      redirectUrl: shouldSkipAbsorbFeesStep
        ? onboardingLocations.vendorCompanyInfo.added
        : onboardingLocations.vendorCompanyInfo.absorbFees,
      exitUrl: generatePath(getPaidLocations.dashboard, { orgId }),
    }
  );
