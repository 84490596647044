import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

export const VirtualCardDetailsFAQ = () => (
  <Flex w="full" direction="column" mt={6}>
    <Flex w="full" align="center" bg="grey.200" borderRadius="lg" mb={3}>
      <Box textStyle="h1Semi" my={4} mx={6}>
        <MIFormattedText label="vendors.virtualCard.valid.content.numberOne" />
      </Box>
      <Flex direction="column">
        <Box textStyle="body3Semi">
          <MIFormattedText label="vendors.virtualCard.valid.content.firstElementTitle" />
        </Box>
        <Box textStyle="body4">
          <MIFormattedText label="vendors.virtualCard.valid.content.firstElementSubtitle" />
        </Box>
      </Flex>
    </Flex>
    <Flex w="full" align="center" bg="grey.200" borderRadius="lg" mb={3}>
      <Box textStyle="h1Semi" my={4} mx={6}>
        <MIFormattedText label="vendors.virtualCard.valid.content.numberTwo" />
      </Box>
      <Flex direction="column">
        <Box textStyle="body3Semi">
          <MIFormattedText label="vendors.virtualCard.valid.content.secondElementTitle" />
        </Box>
        <Box textStyle="body4">
          <MIFormattedText label="vendors.virtualCard.valid.content.secondElementSubtitle" />
        </Box>
      </Flex>
    </Flex>
    <Flex w="full" align="center" bg="grey.200" borderRadius="lg">
      <Box textStyle="h1Semi" my={4} mx={6}>
        <MIFormattedText label="vendors.virtualCard.valid.content.numberThree" />
      </Box>
      <Flex direction="column">
        <Box textStyle="body3Semi">
          <MIFormattedText label="vendors.virtualCard.valid.content.thirdElementTitle" />
        </Box>
        <Box textStyle="body4">
          <MIFormattedText label="vendors.virtualCard.valid.content.thirdElementSubtitle" />
        </Box>
      </Flex>
    </Flex>
  </Flex>
);
