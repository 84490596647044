import { FormatDateOptions, FormattedDate } from 'react-intl';
import { MIFormattedText } from './MIFormattedText';

type Props = {
  date?: Date | string | number | null;
  placeholder?: string;
} & FormatDateOptions;

export const MIFormattedDate = ({ date, placeholder, ...props }: Props) => {
  const value = date && new Date(date);

  return value ? (
    <FormattedDate value={value} day="numeric" month="short" year="numeric" {...props} />
  ) : (
    <MIFormattedText label={placeholder} />
  );
};
