import { NotificationVariant } from 'src/core/ds/toast';
import { ALL_AT_ONCE_OPTION_ID, BY_DUE_DATE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { dismissNotification, pushNotification } from 'src/services/notifications';

const sameDateLabel = 'regularBatchPayments.BatchSettings.sameDate';
const arriveByDueDateLabel = 'regularBatchPayments.BatchSettings.arriveByDueDate';

export const dateOptionsMenu = [
  {
    label: sameDateLabel,
    value: ALL_AT_ONCE_OPTION_ID,
  },
  {
    label: arriveByDueDateLabel,
    value: BY_DUE_DATE_OPTION_ID,
  },
];

export const analyticsValues = {
  [sameDateLabel]: 'sameDate',
  [arriveByDueDateLabel]: 'arriveByDueDate',
};

export const sendBulkPaymentToggleToastNotification = (isChecked: boolean): void => {
  dismissNotification();
  pushNotification({
    testId: 'bulk-payment-toast-notification',
    type: NotificationVariant.info,
    msg: isChecked
      ? 'regularBatchPayments.Settings.bulkPaymentToast'
      : 'regularBatchPayments.Settings.separatePaymentToast',
  });
};
