import { generatePath } from 'react-router';
import { dashboardLocations } from 'src/pages/dashboard/locations';
import { useHasAccessToCurrentOrg } from 'src/pages/team/team-management/hooks/useHasAccessToCurrentOrg';
import { analytics } from 'src/services/analytics';
import locations from 'src/utils/locations';
import { AddProfilePicturePage } from '../AddProfilePicture';
import { PAGE_EVENT } from '../consts';

export function AddProfilePictureContainer(props) {
  const { hasAccessToCurrentOrg } = useHasAccessToCurrentOrg();

  const handleOnNext = (orgId) => {
    analytics.track(PAGE_EVENT, 'register-from-invitation-start');

    if (hasAccessToCurrentOrg) {
      props.history.push(locations.MainApp.dashboard.url({ orgId }));
    } else {
      props.history.push(generatePath(dashboardLocations.companies, { orgId }));
    }
  };

  return <AddProfilePicturePage onNext={handleOnNext} />;
}
