import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { ButtonSizes } from 'src/core/ds/button';
import { Icon, IconNames } from 'src/core/ds/icon';
import { LoginWithGoogleButton } from 'src/pages/auth/components/login-with-google-button';
import { TertiaryButton } from 'src/pages/auth/components/penny-like/TertiaryButton';
import { CardWithSideImageLayout } from 'src/pages/auth/components/register-page-experiment/CardWisthSideImageLayout';
import { RegisterServerErrors, useRegisterPageLogic } from 'src/pages/auth/RegisterPage';
import { UserPasswordForm, useShowEmailPassword } from 'src/pages/auth/RegisterPageCondensed';
import { analytics } from 'src/services/analytics';
import { RegistrationFlow } from 'src/utils/consts';
import SideImage from './assets/signup-sideimage-A.png';
import { LoginWithIntuitButton } from './components/login-with-intuit-button';

type Props = {
  registrationFlow: RegistrationFlow;
};

type SignUpFooterProps = {
  loginWithQuickbooks?: () => void;
  loginWithGoogle?: () => void;

  loginWithUserPassword?: () => void;
  textValues?: Record<string, any>;
};

export const AlternativeLoginButtons = ({
  loginWithGoogle,
  loginWithQuickbooks,
  loginWithUserPassword,
  textValues,
}: SignUpFooterProps) => {
  const wizardButtonContainerStyles = {
    margin: 0,
    button: {
      marginBottom: 0,
    },
  };

  const google = loginWithGoogle ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithGoogleButton onClick={loginWithGoogle} />
    </WizardElements.WizardButtonContainer>
  ) : null;

  const intuit = loginWithQuickbooks ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithIntuitButton onClick={loginWithQuickbooks} />
      <Box
        textStyle="body4"
        color="#6A7281"
        py={3}
        textAlign="center"
        sx={{
          '@media(min-width:905px)': {
            display: 'none',
          },
        }}
      >
        <MIFormattedText label="auth.signUpCondensed.signInWithIntuitHint" />
      </Box>
    </WizardElements.WizardButtonContainer>
  ) : null;

  return (
    <>
      <Box mb={3}>
        <TertiaryButton
          label="auth.signUpCondensed.signInWithEmail"
          size={ButtonSizes.lg}
          onClick={loginWithUserPassword}
          leftIcon={<Icon name={IconNames.email} />}
          isFullWidth
        />
      </Box>
      <Box mb={3}>{google}</Box>
      <Box mb={0}>{intuit}</Box>
      <Box
        fontSize="14px"
        lineHeight="20px"
        sx={{
          '@media(min-width:905px)': {
            marginTop: '32px',
          },
          marginTop: '60px',
        }}
        textAlign="center"
        color="#4A505B"
      >
        <MIFormattedText label="auth.signUpCondensed.alreadyHaveAnAccount" values={textValues} />
      </Box>
    </>
  );
};

export const RegisterPageSideImageA: React.VFC<Props> = (props) => {
  const {
    goLinkQuickbooks,
    goLogin,
    handleRequestPassword,
    registerMV,
    submit,
    openGoogleOAuth,
    isLoading,
    isPasswordRequestSucceeded,
    combinedError,
    isEmailPrefilled,
    isSpendManagementRegistrationFlow,
    errorRequestPassword,
  } = useRegisterPageLogic(props);
  const { showEmailPassword, setShowUserPassword } = useShowEmailPassword();

  const alternativeLoginButtons = (
    <AlternativeLoginButtons
      loginWithQuickbooks={isSpendManagementRegistrationFlow ? undefined : goLinkQuickbooks}
      loginWithGoogle={() => {
        analytics.track('register', 'login-with-google');
        openGoogleOAuth();
      }}
      textValues={{
        link: (label) => <StyledMIInlineLink onClick={goLogin} label={label} testId={`link-${label}`} />,
      }}
      loginWithUserPassword={() => setShowUserPassword(true)}
    />
  );

  return (
    <CardWithSideImageLayout
      image={SideImage}
      imageCaption="auth.signUpSideImageA.imageDescription"
      title="auth.signUpCondensed.title"
      subtitle="auth.signUpCondensed.subtitle"
    >
      <RegisterServerErrors
        errorRequestPassword={errorRequestPassword}
        isPasswordRequestSucceeded={isPasswordRequestSucceeded}
        handleRequestPassword={handleRequestPassword}
        combinedError={combinedError}
        goLogin={goLogin}
      />
      <>
        {showEmailPassword ? (
          <UserPasswordForm
            registerMV={registerMV}
            isEmailPrefilled={isEmailPrefilled}
            submit={submit}
            isLoading={isLoading}
          />
        ) : (
          alternativeLoginButtons
        )}
      </>
    </CardWithSideImageLayout>
  );
};

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: inherit;
  line-height: inherit;
  height: auto;
`;
