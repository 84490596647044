import { useEffect, useState } from 'react';
import * as React from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import useGetAchDeliveryMethod from 'src/pages/get-paid/hooks/useGetAchDeliveryMethod';
import { useVerifyDeliveryMethodDialog } from 'src/pages/get-paid/hooks/useVerifyDeliveryMethodDialog';
import { analytics } from 'src/services/analytics';
import { useQueryString } from 'src/utils/hooks';

type ShowVerifyMessageResponseType = {
  title: string;
  description: string;
  action: string;
  onClick: () => void;
  onClose: () => void;
  openMessage: boolean;
  shouldShowVerifyBankAccount?: boolean;
  dialog?: React.ReactNode;
};

type Props = {
  onVerify?: (success?: boolean) => Promise<void> | void;
};

const useShowVerifyMessage = ({ onVerify }: Props): ShowVerifyMessageResponseType => {
  const [openMessage, setOpenMessage] = useState(true);
  const deliveryMethod = useGetAchDeliveryMethod();
  const deliveryMethodsActions = useStoreActions(deliveryMethodsStore);
  const query = useQueryString();

  const onVerifyFinished = (success: boolean) => {
    if (success) {
      setOpenMessage(false);
    }

    onVerify && onVerify(success);
  };

  const {
    VerifyMicroDepositDialog,
    showVerifyDeliveryMethod,
    shouldShowVerifyBankAccount,
  } = useVerifyDeliveryMethodDialog({
    deliveryMethodIdToVerify: Number(deliveryMethod?.id),
    onVerifyFinished,
  });

  const onCloseMessage = () => {
    analytics.trackAction('click-close-show-verify-message');
    deliveryMethodsActions.shouldCloseVerifyDeliveryMethodIndicatorSlice.update();
    setOpenMessage(false);
  };

  useEffect(() => {
    if (query.verifyDeliveryMethodId) {
      showVerifyDeliveryMethod();
    }
  }, [query.verifyDeliveryMethodId, showVerifyDeliveryMethod]);

  return {
    title: 'getPro.message.verifyAccount.title',
    description: 'getPro.message.verifyAccount.description',
    action: 'getPro.message.verifyAccount.action',
    onClick: showVerifyDeliveryMethod,
    onClose: onCloseMessage,
    openMessage,
    shouldShowVerifyBankAccount,
    dialog: VerifyMicroDepositDialog,
  };
};

export { useShowVerifyMessage };
