import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';

export const RepaymentDeductionTooltip = ({ label, testId }: { label: string; testId: string }) => (
  <Tooltip
    placement="top"
    shouldWrapChildren
    label={<MIFormattedText label={label} />}
    data-testid={`tooltip-${testId}`}
  >
    <Box display="inline-block" verticalAlign="middle" ml={1} data-testid={`icon-${testId}`}>
      <Icon color="grey.700" name={IconNames.infoCircle} size={IconSize.s} />
    </Box>
  </Tooltip>
);
