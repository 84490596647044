import { keyframes } from 'src/core/ds';

const goDown = keyframes`
0% {
    height: 0;
    opacity: 0;
  }
  100% {
     height: 6rem;
     opacity: 1;
  }`;

const goUp = keyframes`
0% {
    height: 6rem;
  }
  100% {
    height: 0;
  }
  `;

export const searchInputFadeOutAnimation = `${goUp} 0.3s ease-out forwards`;
export const searchInputFadeInAnimation = `${goDown} 0.3s ease-out forwards`;
