import { spacing } from './spacing';

const largeSizes = {
  max: 'max-content',
  min: 'min-content',
  full: '100%',
};

const sizes = {
  ...spacing,
  ...largeSizes,
};

export type Sizes = typeof spacing & typeof largeSizes;

export default sizes;
