import { format, isValid, parse } from 'date-fns';
import { ChangeEvent, useState } from 'react';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Flex from 'src/core/ds/flex';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { profileStore } from 'src/modules/profile/profile-store';
import { CompanyInfoType } from 'src/utils/types';

type Props = {
  onPrev: () => void;
  onNext: (
    companyInfo: CompanyInfoType,
    dataToUpdate: Record<string, any>,
    userDataToUpdate: Record<string, any>
  ) => void;
  onChange: (name: string, id: string) => void;
  companyInfo: CompanyInfoType;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
};
const DATE_FORMAT = 'MMddyyyy';

export const VendorCompanyContactNameForm = ({
  companyInfo,
  onPrev,
  isLoading = false,
  validationErrors = {},
  onNext,
  onChange,
}: Props) => {
  const { profile } = useStructuredSelectors(profileStore.selectors);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(
    profile?.dateOfBirth ? new Date(profile.dateOfBirth) : null
  );
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    onChange(value, id);
  };

  const onDateOfBirthChange = (value: string) => {
    const parsedDate = parse(value, DATE_FORMAT, new Date());

    if (value.length < DATE_FORMAT.length || !isValid(parsedDate)) {
      setDateOfBirth(null);

      return;
    }

    setDateOfBirth(parsedDate);
    onChange(String(parsedDate), 'dateOfBirth');
  };

  const onNextHandler = () => {
    const { contactFirstName, contactLastName, phone } = companyInfo;
    onNext(companyInfo, { contactFirstName, contactLastName, phone }, { dateOfBirth });
  };

  return (
    <StepLayoutPage
      title="onboarding.vendorCompanyInfo.contactName.title"
      onPrev={onPrev}
      onNext={onNextHandler}
      isLoading={isLoading}
      relativeStep={4 / 10}
    >
      <Flex gap={10}>
        <TextField
          id="contactFirstName"
          label="onboarding.vendorCompanyInfo.contactName.inputTitleFirstName"
          value={companyInfo.contactFirstName}
          autoFocus
          isRequired
          onSubmit={onNextHandler}
          onChange={onInputChange}
          errorMessage={validationErrors.contactFirstName}
        />
        <TextField
          id="contactLastName"
          label="onboarding.vendorCompanyInfo.contactName.inputTitleLastName"
          value={companyInfo.contactLastName}
          isRequired
          onSubmit={onNextHandler}
          onChange={onInputChange}
          errorMessage={validationErrors.contactLastName}
        />
      </Flex>
      <MaskField
        id="phoneInput"
        label="onboarding.vendorCompanyInfo.phone.inputTitle"
        helperText="onboarding.vendorCompanyInfo.phone.inputHint"
        value={companyInfo.phone}
        type="tel"
        format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
        onSubmit={onNextHandler}
        onChange={(e) => {
          onChange(e.formattedValue, 'phone');
        }}
        errorMessage={validationErrors.phone}
        isRequired
      />
      <MaskField
        id="contactDateOfBirth"
        label="onboarding.companyInfo.contacts.inputTitleDateOfBirth"
        value={dateOfBirth ? format(dateOfBirth, DATE_FORMAT) : dateOfBirth}
        helperText="onboarding.companyInfo.contacts.inputHintDateOfBirth"
        placeholder="onboarding.companyInfo.contacts.dateOfBirthFormat"
        type="text"
        format={Consts.MASK_FORMAT_OPTIONS.dateOfBirth}
        onSubmit={onNextHandler}
        onChange={({ value }) => {
          onDateOfBirthChange(value);
        }}
        errorMessage={validationErrors?.dateOfBirth}
        isRequired
      />
    </StepLayoutPage>
  );
};
