import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes, PendingReasons } from 'src/utils/consts';

type Props = {
  pendingReason?: PendingReasons | null;
};

const PendingNotification = ({ pendingReason }: Props) => {
  if (pendingReason) {
    return (
      <Box my={8}>
        <MINotificationCard
          type={NotificationCardTypes.INFO}
          subtitle={{ label: `getPro.cards.notification.pending.${pendingReason}` }}
        />
      </Box>
    );
  }

  return null;
};

export { PendingNotification };
