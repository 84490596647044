import { PAGE_EVENT } from 'src/pages/get-pro/consts';
import { EventMappingType } from 'src/services/analytics/types';

export const ACTION_MAPPING: Record<string, EventMappingType> = {
  'getPro.table.actions.name': [PAGE_EVENT, 'actions-drop-down'],
  'getPro.table.actions.invoices.sendRequests': [
    PAGE_EVENT,
    'invoices_send-requests_batch',
    { selectedIds: 'selectedIds' },
  ],
  'getPro.table.actions.invoices.markAsPaid': [PAGE_EVENT, 'invoices_mark-as-paid_batch'],
  'getPro.table.actions.invoices.remove': [PAGE_EVENT, 'invoices_remove_batch'],

  'getPro.table.actions.requests.markAsPaid': [PAGE_EVENT, 'requests_mark-as-paid_batch'],
  'getPro.table.actions.requests.cancel': [PAGE_EVENT, 'requests_cancel_batch'],
  'getPro.table.item.menu.invoices.send': [PAGE_EVENT, 'invoice_send_page_open', { id: 'id' }],
  'getPro.table.item.menu.invoices.edit': [PAGE_EVENT, 'invoices_edit_3dots'],
  'getPro.table.item.menu.invoices.markAsPaid': [PAGE_EVENT, 'invoices_mark-as-paid_3dots'],
  'getPro.table.item.menu.invoices.viewInvoice': [PAGE_EVENT, 'invoices_view-invoice_3dots'],
  'getPro.table.item.menu.invoices.customerDetails': [PAGE_EVENT, 'invoices_customer-details_3dots'],
  'getPro.table.item.menu.invoices.remove': [PAGE_EVENT, 'invoices_remove_3dots'],
  'getPro.table.item.menu.requests.resend': [PAGE_EVENT, 'request_resend_page_open', { id: 'id' }],
  'getPro.table.item.menu.requests.edit': [PAGE_EVENT, 'requests_edit_3dots'],
  'getPro.table.item.menu.requests.markAsPaid': [PAGE_EVENT, 'requests_mark-as-paid_3dots'],
  'getPro.table.item.menu.requests.viewInvoice': [PAGE_EVENT, 'requests_view-invoice_3dots'],
  'getPro.table.item.menu.requests.customerDetails': [PAGE_EVENT, 'requests_customer-details_3dots'],
  'getPro.table.item.menu.requests.remove': [PAGE_EVENT, 'requests_remove_3dots'],

  'getPro.table.item.menu.inProgress.viewInvoice': [PAGE_EVENT, 'inProgress_view-invoice_3dots'],
  'getPro.table.item.menu.inProgress.customerDetails': [PAGE_EVENT, 'inProgress_customer-details_3dots'],
  'getPro.table.item.menu.inProgress.markAsUnpaid': [PAGE_EVENT, 'inProgress_mark-as-unpaid_3dots'],
  'getPro.table.item.menu.inProgress.remove': [PAGE_EVENT, 'inProgress_remove_3dots'],

  'getPro.table.item.menu.paid.viewInvoice': [PAGE_EVENT, 'paid_view-invoice_3dots'],
  'getPro.table.item.menu.paid.customerDetails': [PAGE_EVENT, 'paid_customer-details_3dots'],
  'getPro.table.item.menu.paid.markAsUnpaid': [PAGE_EVENT, 'paid_mark-as-unpaid_3dots'],
  'getPro.table.item.menu.paid.remove': [PAGE_EVENT, 'paid_remove_3dots'],
  'getPro.confirmationModals.markedAsPaidRequests.confirm': [
    PAGE_EVENT,
    'requests_marked-as-paid-requests-confirm_batch',
  ],
  'getPro.confirmationModals.markedAsPaidRequests.cancel': [
    PAGE_EVENT,
    'requests_marked-as-paid-requests-cancel_batch',
  ],
  'getPro.confirmationModals.markedAsPaidRequest.confirm': [PAGE_EVENT, 'requests_marked-as-paid-request-confirm'],
  'getPro.confirmationModals.markedAsPaidRequest.cancel': [PAGE_EVENT, 'requests_marked-as-paid-request-cancel'],
  'getPro.confirmationModals.removeInvoices.confirm': [PAGE_EVENT, 'invoices_remove-invoices-confirm_batch'],
  'getPro.confirmationModals.removeInvoices.cancel': [PAGE_EVENT, 'invoices_remove-invoices-cancel_batch'],
  'getPro.confirmationModals.removePaymentRequests.confirm': [
    PAGE_EVENT,
    'requests_remove-payment-requests-confirm_batch',
  ],
  'getPro.confirmationModals.removePaymentRequests.cancel': [
    PAGE_EVENT,
    'requests_remove-payment-requests-cancel_batch',
  ],
  'getPro.downloadCsvReportModal': [
    PAGE_EVENT,
    'download_csv_report_modal',
    {
      toggle: 'toggle',
    },
  ],
  'getPro.downloadCsvReportModal.download': [
    PAGE_EVENT,
    'download_csv_report_modal-download',
    {
      startDate: 'startDate',
      endDate: 'endDate',
      includeInProgress: 'includeInProgress',
      countPayments: 'countPayments',
      tpv: 'tpv',
    },
  ],
};
