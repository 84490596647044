import merge from 'lodash/merge';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { joinOrganizationRequestsApi } from 'src/modules/join-organization-requests/api';
import { name } from './../consts';

export const resendJoinRequestSlice = createApiCallSlice({
  api: joinOrganizationRequestsApi.resend,
  name: generateSliceName(name, 'resend'),
  initialState: {
    byId: {},
    update: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      state.update[action.payload.id] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      delete state.update[action.payload.id];
      state.byId[action.payload.id] = merge(state.byId[action.payload.id] || {}, action.payload);
    },
    [ON_FAILURE](state, action) {
      state.update[action.payload.id] = { loading: false, error: action.error };
    },
  },
});
