import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { TextInputSize } from 'src/utils/consts';

type Props = {
  inputId?: string;
  label: string;
  required?: boolean;
  size?: TextInputSize;
  errorMessage?: string | null;
  labelValues?: Record<string, any>;
  afterLabelComponent?: React.ReactNode;
  labelSuffix?: string;
};

export const MIInputLabel = ({
  inputId,
  label,
  required,
  size = TextInputSize.WIZARD,
  errorMessage,
  labelSuffix = 'input.optional',
  labelValues,
  afterLabelComponent,
}: Props) => {
  if (!label) return null;

  return (
    <InputLabel size={size} errorMessage={errorMessage} htmlFor={inputId}>
      <MIFormattedText label={label} values={labelValues} />
      {!required && (
        <LabelContainer>
          <MIFormattedText label={labelSuffix} />
        </LabelContainer>
      )}
      {afterLabelComponent && <LabelContainer>{afterLabelComponent}</LabelContainer>}
    </InputLabel>
  );
};

type InputLabelProps = {
  children?: React.ReactNode;
  htmlFor?: string;
  size: TextInputSize;
  errorMessage?: string | null;
};

const InputLabel = ({ children, size, errorMessage, htmlFor }: InputLabelProps) => (
  <Box
    as="label"
    htmlFor={htmlFor}
    textStyle="body4Semi"
    letterSpacing="0.02rem"
    display="block"
    textAlign="left"
    mb={size === TextInputSize.INLINE ? 0 : 1}
    color={errorMessage ? 'red.500' : 'grey.600'}
  >
    {children}
  </Box>
);

type LabelContainerProps = {
  children?: React.ReactNode;
};

const LabelContainer = ({ children }: LabelContainerProps) => (
  <Box as="span" textStyle="body4" textTransform="none">
    {children}
  </Box>
);
