import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { BillStatus, PaymentApprovalStatus } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';
import { billFactory } from '../bill/records';

export function paymentFactory(initialValues?: Partial<PaymentType>): PaymentType {
  return {
    id: NaN,
    status: '',
    organizationId: 0,
    amount: NaN,
    balance: NaN,
    billId: '',
    bill: {
      id: '',
      invoiceNumber: '',
      status: BillStatus.UNPAID,
      balance: 0,
      totalAmount: 0,
    },
    bills: [billFactory()],
    billPayment: {
      amount: NaN,
      billId: NaN,
      createdAt: '',
      id: NaN,
      paymentId: NaN,
      uniqueBillPaymentId: false,
      updatedAt: '',
    },
    vendorId: NaN,
    vendor: {
      id: NaN,
      companyName: '',
      contactEmail: '',
      contactPhone: '',
    },
    fundingSourceId: NaN,
    fundingSource: {
      displayName: '',
      origin: null,
    },
    deliveryMethodId: NaN,
    deliveryMethod: deliveryMethodFactory(),
    currency: '',
    originId: '',
    scheduledDate: null,
    deliveryEta: null,
    maxDeliveryEta: null,
    actualStartDate: '',
    deliveryCompleteDate: '',
    deliverStatus: null,
    note: null,
    manual: false,
    createdAt: new Date(),
    updatedAt: '',
    createOrigin: undefined,
    createdById: null,
    transactions: [],
    paymentApprovalDecisions: [],
    approvalDecisionById: NaN,
    approvalDecisionDate: new Date(),
    approvalDecisionReason: '',
    approvalDecisionStatus: PaymentApprovalStatus.SKIP,
    riskStatus: null,
    paymentApprovalActions: [],
    deliveryPreference: '',
    metadata: {
      isPaymentRefundable: false,
      isSelfServeRefundEligible: false,
      achDeposited: false,
      canUserContactSupport: false,
      canUserRetry: false,
      failureMessage: '',
      paymentType: '',
      audit: [],
    },
    checkTracks: [],
    intuitAccountId: null,
    virtualCards: [],
    voidChecks: [],
    purpose: '',
    isFinanced: false,
    ...initialValues,
  };
}
