import { useEffect, useState } from 'react';
import { generatePath, Redirect, useRouteMatch } from 'react-router-dom';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { MicroDepositVerificationResponse } from 'src/modules/delivery-methods/types';
import { analytics } from 'src/services/analytics';
import {
  ERR_BANK_ACCOUNT_BLOCKED_CODE,
  ERR_VERIFY_MICRO_DEPOSITS,
  MicroDepositStatuses,
  MicroDepositStatusURLMapper,
  msnMicroDepositPagePage,
} from '../consts';
import { VerificationParams } from '../types';
import { VerificationPage } from './verification/Page';

export const MicroDepositVerification = () => {
  const [alertText, setAlertText] = useState('');
  const [microDepositVerificationStatus, setMicroDepositVerificationStatus] = useState<MicroDepositStatuses>(
    MicroDepositStatuses.NOT_VERIFIED
  );

  const {
    onApiCall: verifyMicroDepositsWithToken,
    result: apiResult,
    error: apiError,
    loading: isFetchingData,
  } = useApi<[VerificationParams], MicroDepositVerificationResponse>({
    api: deliveryMethodsApi.verifyMicroDepositsWithToken,
  });
  const { token } = useRouteMatch<{ token: string }>().params;
  const isNotVerified = microDepositVerificationStatus === MicroDepositStatuses.NOT_VERIFIED;

  useEffect(() => {
    if (apiResult) {
      apiResult.verificationResult
        ? setMicroDepositVerificationStatus(MicroDepositStatuses.SUCCESS)
        : setMicroDepositVerificationStatus(MicroDepositStatuses.NOT_VERIFIED);
    }

    if (apiError) {
      const { code } = apiError;

      if (code === ERR_VERIFY_MICRO_DEPOSITS) {
        setAlertText('microDepositVerification.verification.alert');
      } else if (code === ERR_BANK_ACCOUNT_BLOCKED_CODE) {
        setMicroDepositVerificationStatus(MicroDepositStatuses.TOO_MANY_ATTEMPTS);
      } else {
        analytics.track(msnMicroDepositPagePage, 'verify-micro-deposit-error', { token });
        setMicroDepositVerificationStatus(MicroDepositStatuses.ERROR);
      }
    }
  }, [apiResult, apiError, token]);

  const verifyMicroDeposit = async (amounts) => {
    verifyMicroDepositsWithToken({ ...amounts, token });
  };

  return isNotVerified ? (
    <VerificationPage
      title="microDepositVerification.verification.title"
      subtitle="microDepositVerification.verification.subtitle"
      alertText={alertText}
      onClick={verifyMicroDeposit}
      isLoading={isFetchingData}
    />
  ) : (
    <Redirect
      to={{
        pathname: generatePath(MicroDepositStatusURLMapper[microDepositVerificationStatus], { token }),
      }}
    />
  );
};
