import { CardComponentProps } from 'src/components/braze/types';
import { CSSObject } from 'src/core/ds';
import Flex from 'src/core/ds/flex/Flex';

const contentCardStyles: CSSObject = {
  color: 'white',
  bgColor: 'grey.700',
  p: 2,
  cursor: 'pointer',
  rounded: 'md',
  textStyle: 'body4',
  w: 'fit-content',
};

export const ContentCard = ({ card, onClose }: CardComponentProps) => {
  const { description } = card;

  const handleClose = () => {
    onClose(card);
  };

  return (
    <Flex onClick={handleClose} sx={contentCardStyles}>
      {description}
    </Flex>
  );
};
