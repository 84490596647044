import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import accountantsImage from 'src/images/dashboard/accountants-illustration.svg';
import companiesImage from 'src/images/dashboard/companies-illustration.svg';
import { devices } from 'src/theme/appDevices';

type Props = {
  isAccountingFirm: boolean;
  hasHiddenOrgs: boolean;
  onAddClientClick: () => void;
};

export const ZeroStateMessage = ({ isAccountingFirm, hasHiddenOrgs }: Props) => {
  const titleForFirmWithoutHiddenOrgs = isAccountingFirm
    ? `companies.zeroStateMessage.accountant.title`
    : 'companies.zeroStateMessage.smb.title';
  const titleForFirmWithHiddenOrgs = 'companies.zeroStateMessage.noVisibleClients.title';
  const subtitleForFirmWithoutHiddenOrgs = isAccountingFirm
    ? `companies.zeroStateMessage.accountant.subtitle`
    : 'companies.zeroStateMessage.smb.subtitle';
  const subtitleForFirmWithHiddenOrgs = 'companies.zeroStateMessage.noVisibleClients.subtitle';
  const title = hasHiddenOrgs ? titleForFirmWithHiddenOrgs : titleForFirmWithoutHiddenOrgs;
  const subtitle = hasHiddenOrgs ? subtitleForFirmWithHiddenOrgs : subtitleForFirmWithoutHiddenOrgs;
  const testId = hasHiddenOrgs
    ? 'companies-zero-state-message-existing-clients'
    : 'companies-zero-state-message-no-existing-clients';

  return (
    <MessageWrapper data-testid={testId}>
      <MessageContainer>
        <Title>
          <MIFormattedText label={title} />
        </Title>
        <Subtitle>
          <MIFormattedText label={subtitle} />
        </Subtitle>
      </MessageContainer>
      <ImageContainer>
        <Image src={isAccountingFirm ? accountantsImage : companiesImage} />
      </ImageContainer>
    </MessageWrapper>
  );
};

const MessageWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  padding: 4rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: ${(props) => `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}`};
  color: ${(props) => props.theme.text.color.main};
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    padding: 1.2rem 1.2rem 2rem 2rem;
  }
`;

const MessageContainer = styled.div``;

const Title = styled.p`
  margin: 1.2rem 0 1.2rem 0;
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
`;

const Subtitle = styled.p`
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
`;

const ImageContainer = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    display: none;
  }
`;

const Image = styled.img`
  width: 29rem;
`;
