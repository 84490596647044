import { FinancingPreEligibilityDecision, IneligibilityReason } from 'src/modules/financing/types';

export const learnMoreLink = 'https://melio.zendesk.com/hc/en-us/articles/6464064007964#h_01GHVKAHQYTQ12YED5XXT38F6D';

type getIneligibleTooltipParams = {
  eligibilityStatus: FinancingPreEligibilityDecision;
  reason: IneligibilityReason;
  error: any;
  isBusinessOwner: boolean;
};

export const getIneligibleTooltip = ({
  eligibilityStatus,
  reason,
  error,
  isBusinessOwner,
}: getIneligibleTooltipParams) => {
  if (error) return 'financing.fundingSource.installmentsDisabledTooltip.generalError';

  if (eligibilityStatus === FinancingPreEligibilityDecision.Pending) {
    return 'financing.fundingSource.installmentsDisabledTooltip.pendingDecision';
  } else if (isBusinessOwner === false) {
    return 'financing.fundingSource.installmentsDisabledTooltip.notAnOwner';
  }

  switch (reason) {
    case IneligibilityReason.FailedRepayments:
      return 'financing.fundingSource.installmentsDisabledTooltip.failedRepayments';
    case IneligibilityReason.AmountTooHigh:
      return 'financing.fundingSource.installmentsDisabledTooltip.amountTooHigh';
    case IneligibilityReason.IneligibleVendor:
      return 'financing.fundingSource.installmentsDisabledTooltip.ineligibleVendor';
    case IneligibilityReason.IneligibleFundingSource:
      return 'financing.fundingSource.installmentsDisabledTooltip.ineligibleFundingSource';
    case IneligibilityReason.FailedPreEligibility:
      return 'financing.fundingSource.installmentsDisabledTooltip.preEligibility';
    case IneligibilityReason.GeneralError:
    default:
      return 'financing.fundingSource.installmentsDisabledTooltip.generalError';
  }
};
