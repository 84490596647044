import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

export const PAGE_EVENT = 'team';
const REMOVE_INVITATION_MODAL = 'remove-invitation-modal';
const REMOVE_MEMBER_MODAL = 'remove-member-modal';
const INVITE_MEMBER_MODAL = 'invite-member-modal';
const EDIT_MEMBER_MODAL = 'edit-member-modal';
const MANAGE_CLIENTS_MODAL = 'manage-clients-modal';

export const MAPPING: EventMappingsTree = {
  '/orgs/*/team': {
    'page.view': [PAGE_EVENT, 'team-page-load'],
    'toggle-actions-menu': [PAGE_EVENT, 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'team.actions.newMember': [PAGE_EVENT, 'invite-member-clicked'],
    'team.actions.user.remove': [PAGE_EVENT, 'remove-member-menu'],
    'team.actions.user.edit': [PAGE_EVENT, 'edit-member-menu'],
    'team.actions.user.manageClients': [PAGE_EVENT, 'manage-clients-menu'],
    'invitation.action.resend': [PAGE_EVENT, 'resend-invite-menu'],
    'team.actions.invitation.remove': [PAGE_EVENT, 'remove-invite-menu'],
    ...mapApiSlice('[INVITATIONS] CREATE', PAGE_EVENT, 'invite-member', {
      request: { payload: 'payload' },
      success: { user: 'payload.email' },
    }),
    removeInvitationModal: [PAGE_EVENT, REMOVE_INVITATION_MODAL, { toggle: 'toggle' }],
    inviteTeamMemberModal: [PAGE_EVENT, INVITE_MEMBER_MODAL, { toggle: 'toggle' }],
    editUserModal: [PAGE_EVENT, EDIT_MEMBER_MODAL, { toggle: 'toggle' }],
    deleteUserModal: [PAGE_EVENT, REMOVE_MEMBER_MODAL, { toggle: 'toggle' }],
    assignClientsModal: [PAGE_EVENT, MANAGE_CLIENTS_MODAL, { toggle: 'toggle' }],
    'team.modals.invite.buttons.invite': [PAGE_EVENT, `${INVITE_MEMBER_MODAL}_invite-only-clicked`],
    'team.modals.invite.buttons.continue': [PAGE_EVENT, `${INVITE_MEMBER_MODAL}_continue-to-assign-clicked`],
    'invite-user-submit': [
      PAGE_EVENT,
      `${INVITE_MEMBER_MODAL}_invite-only-submit`,
      {
        inviteeCanAccessFirm: 'canAccessFirm',
        inviteeApprovalAmountThreshold: 'approvalAmountThreshold',
        inviteeEmail: 'email',
        inviteeFirstName: 'firstName',
        inviteeLastName: 'lastName',
        inviteeRequireApproval: 'requireApproval',
        inviteeRole: 'role',
      },
    ],
    'invite-and-assign-user-submit': [
      PAGE_EVENT,
      `${INVITE_MEMBER_MODAL}_invite-and-assign-submit`,
      {
        inviteeCanAccessFirm: 'canAccessFirm',
        invitations: 'invitations',
      },
    ],
    'team.modals.assignClients.buttons.save': [PAGE_EVENT, `${MANAGE_CLIENTS_MODAL}_save-clicked`],
    'manage-clients-submit': [
      PAGE_EVENT,
      `${MANAGE_CLIENTS_MODAL}_submit`,
      { targetUserId: 'targetUserId', userOrganizations: 'userOrganizations' },
    ],
    'team.modals.edit.buttons.save': [PAGE_EVENT, `${EDIT_MEMBER_MODAL}_save-clicked`],
    'team.modals.assignClients.learnMore': [PAGE_EVENT, `${MANAGE_CLIENTS_MODAL}_learn-more-clicked`],
    'team.modals.remove.buttons.remove': [PAGE_EVENT, `${REMOVE_MEMBER_MODAL}_remove-clicked`],
    'team.modals.remove.buttons.cancel': [PAGE_EVENT, `${REMOVE_MEMBER_MODAL}_cancel-clicked`],
    'team.modals.removeInvitation.buttons.remove': [PAGE_EVENT, `${REMOVE_INVITATION_MODAL}_remove-clicked`],
    'team.modals.removeInvitation.buttons.cancel': [PAGE_EVENT, `${REMOVE_INVITATION_MODAL}_cancel-clicked`],
    'remove-invitation-submit': [PAGE_EVENT, `${REMOVE_INVITATION_MODAL}_submit`, { invitation: 'invitation' }],
    'remove-member-submit': [
      PAGE_EVENT,
      `${REMOVE_MEMBER_MODAL}_submit`,
      { targetUserId: 'targetUserId', userOrganization: 'userOrganization' },
    ],
    'edit-member-submit': [PAGE_EVENT, `${EDIT_MEMBER_MODAL}_submit`, { canAccessFirm: 'canAccessFirm' }],
    'form-validation-error': [PAGE_EVENT, 'validation-error', { validationsErrors: 'validationErrors' }],
  },
};
