import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';
import { boxStyle } from './styles';

type Props = {
  label: string;
  description: string;
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  testId?: string | null;
};

export const SelectCategoryListItem = ({ label, description, isSelected, testId, onClick }: Props) => {
  const layerStyle = isSelected ? 'selectedBorder' : 'transparentBorder';

  return (
    <Flex data-testid={testId} layerStyle={layerStyle} {...boxStyle} onClick={onClick}>
      <Flex w="full">
        <VStack w="full" alignItems="flex-start">
          <Box textStyle="body2Semi">
            <MIFormattedText label={label} />
          </Box>
          <Box textStyle="body4" color="grey.700">
            <MIFormattedText label={description} />
          </Box>
        </VStack>
      </Flex>
    </Flex>
  );
};
