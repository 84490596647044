import { isValidationOk } from '@melio/sizzers-js-common';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { FlowComponentPropsType } from 'src/pages/vendor/delivery-methods/add-ach-flow/types';
import { useDeliveryMethodData } from 'src/pages/vendor/delivery-methods/hooks/useDeliveryMethodData';
import { useFetchDeliveryMethod } from 'src/pages/vendor/delivery-methods/hooks/useFetchDeliveryMethod';
import { bankFactory } from 'src/pages/vendor/records';
import { getFundingSources, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryType } from 'src/utils/consts';
import { BankType, FieldType } from 'src/utils/types';
import { getValidationsError } from '../../utils';
import { AddBankDetailsPage } from './AddBankDetailsPage';

export const AddBankDetailsPageContainer = ({
  vendorId,
  deliveryMethodId,
  flowCustomizations,
  cancelFlow,
  saveAndContinue,
  saveAndClose,
}: FlowComponentPropsType) => {
  const ownedVendorId = useSelector(getOwnedVendorId);
  const userFundingSources = useSelector(getFundingSources);
  const { isVendorLoading } = useFetchVendor(vendorId);
  const [bankAccount, setBankAccount] = useState<BankType | null>(null);
  const [validationErrors, setValidationErrors] = useState({});
  const { isSubmitting, submitErrorCode, submitDeliveryMethod } = useDeliveryMethodData(
    vendorId,
    deliveryMethodId,
    true
  );
  const { deliveryMethod, isDeliveryMethodLoading } = useFetchDeliveryMethod(deliveryMethodId, vendorId);
  const initialBankAccount = deliveryMethod?.bankAccount;
  const isGetPaidVendor = Number(vendorId) === Number(ownedVendorId);
  const eventPage = isGetPaidVendor ? 'vendor-company-delivery-method' : 'vendor-delivery-method';

  useEffect(() => {
    if (initialBankAccount && !isDeliveryMethodLoading) {
      setBankAccount(bankFactory(initialBankAccount));
    }

    if (!deliveryMethodId) {
      setBankAccount(bankFactory());
    }
  }, [initialBankAccount, deliveryMethodId, isDeliveryMethodLoading]);

  const onChange = ({ id, value }: FieldType) => {
    if (bankAccount) {
      setBankAccount({
        ...bankAccount,
        [id]: value,
      });
    }
  };

  const onBankAdded = async (continueToAddBill?: boolean) => {
    const deliveryMethod = {
      deliveryType: DeliveryType.ACH,
      bankAccount,
    };
    await submitDeliveryMethod(deliveryMethod);
    continueToAddBill ? saveAndContinue() : saveAndClose();
  };

  const onDone = useCallback(
    async (continueToAddBill?: boolean) => {
      const validationErrors = getValidationsError(bankAccount, isGetPaidVendor, userFundingSources);
      setValidationErrors(validationErrors);

      if (isValidationOk(validationErrors)) {
        await onBankAdded(continueToAddBill);
      } else if (deliveryMethodId) {
        analytics.track(eventPage, 'edit-delivery-method-validation-error', validationErrors);
      } else {
        analytics.track(eventPage, 'add-delivery-method-validation-error', validationErrors);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bankAccount]
  );

  const isLoading = isSubmitting || isVendorLoading || isDeliveryMethodLoading;

  return (
    <>
      {bankAccount ? (
        <AddBankDetailsPage
          bank={bankAccount}
          vendorId={String(vendorId)}
          errorCode={submitErrorCode}
          isLoading={isLoading}
          flowCustomizations={flowCustomizations}
          validationErrors={validationErrors}
          onDone={onDone}
          onChange={onChange}
          goExit={cancelFlow}
        />
      ) : null}
    </>
  );
};
