import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { shiftVendorToAchLocations } from 'src/pages/vendor/shift-vendor-to-ach/locations';
import { devices } from 'src/theme/appDevices';
import { PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
  organization: any;
  token: string;
  labelPrefix: string;
};

export const ShiftToAchUpsale = ({ payment, organization, token, labelPrefix }: Props) => {
  const history = useHistory();

  const goShiftToAch = () =>
    history.push(generatePath(shiftVendorToAchLocations.index, { id: payment?.vendorId, token }));

  return (
    <ShiftToAchContainer>
      <ShiftToAchTitle>
        <MIFormattedText
          label={`${labelPrefix}.upsaleTitle`}
          values={{
            companyName: (
              <Box as="span" color="grey.700">
                {organization?.companyName}
              </Box>
            ),
          }}
        />
      </ShiftToAchTitle>
      <ShiftToAchSubtitle>
        <MIFormattedText label={`${labelPrefix}.upsaleSubTitle`} />
      </ShiftToAchSubtitle>
      <Button onClick={goShiftToAch} label={`${labelPrefix}.upsaleCTA`} size={ButtonSizes.lg} />
    </ShiftToAchContainer>
  );
};

const ShiftToAchContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-radius: 0 0 0.6rem 0.6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ShiftToAchTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  font-weight: ${(props) => props.theme.text.weight.bold};
  line-height: 3.2rem;
`;

const ShiftToAchSubtitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  margin: 1.2rem 0 4rem;
`;
