import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Tooltip from 'src/core/ds/tooltip';

export const RegularTooltip = ({ children }: { children: React.ReactNode }) => (
  <Tooltip placement="top" label={<MIFormattedText label="bills.form.partialPayments.tooltip.regular" />}>
    {children}
  </Tooltip>
);

export const DisabledTooltip = ({ children }: { children: React.ReactNode }) => (
  <Tooltip placement="right" label={<MIFormattedText label="bills.form.partialPayments.tooltip.disabled" />}>
    {children}
  </Tooltip>
);
