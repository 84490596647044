import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { AmountCurrencyField } from 'src/core/ds/form/fields';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { InlineCheckboxField } from 'src/ui/form/InlineCheckboxField';
import { UserOrganizationFormType } from './RegisterSetAmountContainer';

type Props = {
  onSubmit: (value: UserOrganizationFormType) => Promise<void>;
  userOrg: UserOrganizationFormType;
  isLoading?: boolean;
  invitingUser?: { firstName: string; lastName: string };
  userName: string;
  skipSetAmount: boolean;
  onSkipSetAmountChange: ({ value }: { value: boolean }) => void;
};
export const RegisterSetAmount = ({
  onSubmit,
  userOrg,
  isLoading,
  invitingUser,
  userName,
  skipSetAmount,
  onSkipSetAmountChange,
}: Props) => {
  const [userOrgMV, { submit }] = useForm<UserOrganizationFormType>(userOrg, {
    submit: onSubmit,
    onetimeModel: true,
  });

  return (
    <StepLayoutPage
      relativeStep={1 / 2}
      isLoading={isLoading}
      nextLabel="auth.register.setAmount.btnText"
      onNext={submit}
      title="auth.register.setAmount.title"
      subtitle="auth.register.setAmount.subtitle"
      subTitleValues={{
        name: <SubTitle>{`${invitingUser?.firstName} ${invitingUser?.lastName}`}</SubTitle>,
      }}
      titleValues={{ user: <Title>{userName}</Title> }}
    >
      <FormRow>
        <AmountCurrencyField
          label="auth.register.setAmount.enterAmountLabel"
          id="register-from-invitation-set-amount"
          model={userOrgMV.approvalAmountThreshold}
          isDisabled={skipSetAmount}
          placeholder="auth.register.setAmount.placeholder"
          decimalScale={2}
          fixedDecimalScale
          isRequired
        />
      </FormRow>
      <FormRow>
        <Comment>
          <MIFormattedText label="auth.register.setAmount.comment" />
        </Comment>
      </FormRow>
      <FormRow>
        <Checkbox>
          <InlineCheckboxField
            label="auth.register.setAmount.checkboxText"
            value={skipSetAmount}
            onChange={onSkipSetAmountChange}
          />
        </Checkbox>
      </FormRow>
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const SubTitle = styled.span`
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const Checkbox = styled.span`
  margin-top: 5rem;
  margin-bottom: 2.5rem;
`;

const Comment = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-top: 1rem;
  ${(props) => props.theme.text.fontType.medium};
`;
