import { useSelector } from 'react-redux';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { profileStore } from 'src/modules/profile/profile-store';
import {
  convertDateToMonthYearFormat,
  getCardIcon,
  getCardImageUrl,
  getExpirationDateHint,
  isCardExpired,
} from 'src/utils/card';
import { CardTypes } from 'src/utils/consts';
import { getModifyFundingSourceOptions, getNameParts } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';
import { getDebitCardMethodDescription, getDebitCardNonMethodDescription } from './utils';

type Props = {
  onClick?: () => void;
  method: AccountType;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  isDefault: boolean;
  showFirmBillingFeeBadge: boolean;
  modifyActions: Record<string, VoidFunction>;
  isInternationalVendor?: boolean;
  isBillingFee?: boolean;
};

export const DebitPaymentMethod = ({
  onClick,
  method,
  isSelected,
  optionComponent: OptionComponent,
  disabled,
  isDefault,
  showFirmBillingFeeBadge,
  modifyActions,
  isInternationalVendor,
  isBillingFee,
}: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const { isConnected: isConnectedToAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const isDebitCardExpired = method?.cardAccount && isCardExpired(method.cardAccount);

  if (!method) {
    return (
      <OptionComponent
        id="debit"
        label="onboarding.fundingSources.options.debit.label"
        description={getDebitCardNonMethodDescription(isInternationalVendor, isBillingFee)}
        descriptionValues={{
          cardIcons: null,
        }}
        icon="icon-debit-card-icon"
        onClick={onClick}
        isEmptyList
        disabled={isInternationalVendor}
      />
    );
  }

  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  const actionOptions = getModifyFundingSourceOptions(
    permissions,
    modifyActions,
    isConnectedToAccountingPlatform,
    disabled,
    method.origin,
    isDebitCardExpired,
    !!method.nickname,
    isDefault
  );

  return (
    <OptionComponent
      id={method.id}
      isSelected={isSelected}
      disabled={disabled}
      isDefault={isDefault}
      showFirmBillingFeeBadge={showFirmBillingFeeBadge}
      isExpired={isDebitCardExpired}
      icon={icon}
      imageSrc={imageSrc}
      label="bills.pay.fundingSource.debitLabel"
      labelValues={{
        ...getNameParts(method),
      }}
      onTopDescription={method.nickname}
      hint={getExpirationDateHint(method.cardAccount)}
      hintValues={{
        expirationDate: convertDateToMonthYearFormat(method.cardAccount?.expiration),
      }}
      description={getDebitCardMethodDescription(!!isDebitCardExpired, isBillingFee)}
      onClick={onClick}
      actionOptions={actionOptions}
    />
  );
};

DebitPaymentMethod.methodType = CardTypes.DEBIT;
DebitPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherDebitCard';
DebitPaymentMethod.isDisabled = (method) => isCardExpired(method.cardAccount);
DebitPaymentMethod.showAddAnotherOption = true;
