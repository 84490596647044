import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';

type Props = {
  goTryAgain: () => void;
  goCancel: () => void;
  errorCode?: string;
};

export const ErrorLinkingPage = ({ goTryAgain, goCancel, errorCode = '' }: Props) => (
  <SuccessLayoutPage
    illustration={IllustrationName.errorScreen}
    title="onboarding.accountingSoftware.error.title"
    text={errorCode ? `server.${errorCode}` : 'onboarding.accountingSoftware.error.recommendation'}
    buttonLabel="onboarding.accountingSoftware.error.actions.tryAgain"
    buttonAction={goTryAgain}
    linkLabel="onboarding.accountingSoftware.error.actions.cancel"
    linkAction={goCancel}
  />
);
