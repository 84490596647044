import { isValidationOk } from '@melio/sizzers-js-common';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authApi } from 'src/modules/auth/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { authLocations } from 'src/pages/auth/locations';
import { initUserAction } from 'src/redux/user/actions';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { useQueryString } from 'src/utils/hooks';
import locations from 'src/utils/locations';
import clientServiceApi from '../../services/api/clientService';
import { globalLocations } from '../locations';
import { ResetPasswordPage } from './components/ResetPasswordPage';

const eventPage = 'reset-password';

export const ResetPasswordPageContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const resetPasswordModel = useMemo(() => ({ password: '', confirmedPassword: '' }), []);
  const [historyPush] = useHistoryWithOrgId();
  const history = useHistory();
  const { email, token, hasNoPasswordDefined: hasNoPasswordDefinedString } = useQueryString();
  const hasNoPasswordDefined = hasNoPasswordDefinedString?.toLowerCase() === 'true';

  const initUser = async (user) =>
    new Promise((resolve, reject) => {
      dispatch(initUserAction(user, false, resolve, reject));
    });

  const onResetPassword = async (password, confirmedPassword) => {
    setIsLoading(true);

    const { validationErrors } = await clientServiceApi.getPasswordValidationErrors('userRegistration', {
      password,
      confirmedPassword,
    });

    analytics.track(eventPage, 'password-continue');

    if (isValidationOk(validationErrors)) {
      try {
        const resetPasswordResult = await authApi.resetPassword({ token, email, password, hasNoPasswordDefined });

        if (hasNoPasswordDefined) {
          const { user, isFirstTimeVisitedRegularMelio } = resetPasswordResult;

          await initUser(user);

          analytics.track(eventPage, 'password-continue-success');
          setIsLoading(false);
          const shouldRedirectToQuickbookMultiOrgOnboarding =
            isFirstTimeVisitedRegularMelio &&
            user.organizations.length > 1 &&
            user.organizations.every((org) => org.isIntuitLinked);
          const redirectUrl = shouldRedirectToQuickbookMultiOrgOnboarding
            ? globalLocations.quickbookMultiOrgOnboarding.companyType
            : locations.index.url();

          history.push(redirectUrl);
        } else {
          analytics.track(eventPage, 'password-continue-success');
          setIsLoading(false);
          historyPush({ path: authLocations.login });
        }
      } catch {
        setIsLoading(false);
      }
    } else {
      analytics.track(eventPage, 'password-validation-error', validationErrors);
      setIsLoading(false);

      throw new ValidationError({ validationErrors });
    }
  };

  const [resetPasswordMV, { submit }] = useForm(resetPasswordModel, {
    submit: ({ password, confirmedPassword }) => onResetPassword(password, confirmedPassword),
  });

  return (
    <ResetPasswordPage
      model={resetPasswordMV}
      onResetPassword={submit}
      isLoading={isLoading}
      hasNoPasswordDefined={hasNoPasswordDefined}
    />
  );
};
