import React from 'react';
import { CardComponentProps } from 'src/components/braze/types';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';

export const BannerCard = ({ card, onClick, onClose, sxForCard }: CardComponentProps) => {
  const { title, description, imageUrl, extras } = card;
  const borderColor = extras?.color ? extras?.color : 'grey.500';

  const handleClick = () => {
    onClick(card);
  };
  const handleClose = () => {
    onClose(card);
  };

  return (
    <Flex
      sx={sxForCard}
      onClick={handleClick}
      border="1px"
      bgColor="white"
      borderColor={borderColor}
      p={4}
      cursor="pointer"
      rounded="lg"
      textAlign="left"
    >
      {imageUrl && <Image src={imageUrl} w={24} h={24} marginRight={5} />}
      <Box w="full">
        <Box textStyle="body3Semi">{title}</Box>
        <Box textStyle="body3">{description}</Box>
      </Box>
      <Box color="grey.600" _hover={{ color: 'black' }} m={1} mb={0}>
        <Icon name={IconNames.close} size={IconSize.s} onClick={handleClose} />
      </Box>
    </Flex>
  );
};
