import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { PASSWORD } from 'src/ui/form/components/StrongPassword/consts';

export const ShowPasswordStrengthNotice = ({
  score,
  label,
  dotSize,
  marginBottomOverride,
  size,
}: {
  score: number;
  label: string;
  dotSize?: number;
  marginBottomOverride?: string;
  size?: string;
}): JSX.Element => (
  <Flex
    alignItems="center"
    mb={marginBottomOverride || 10}
    data-testid="password-score-notice"
    mt={size === 'sm' ? 0 : 1}
  >
    <Box
      marginInline={1}
      h={dotSize || 2}
      w={dotSize || 2}
      bgColor={score < PASSWORD.VALID_SCORE ? 'red.500' : 'green.500'}
      borderRadius="full"
      data-testid="password-score-notice-strong"
    />
    <Box color="grey.600" textStyle={size === 'sm' ? 'caption1' : 'body3'}>
      <MIFormattedText label={label} />
    </Box>
  </Flex>
);
