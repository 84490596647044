import { createSlice } from '@reduxjs/toolkit';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { BatchItemType } from 'src/modules/regular-batch-payments/types';
import { actionName, name } from './consts';

const initialState = {
  byId: {},
  billsToUpdate: [],
};

export const PAYMENT_UPDATE_DELIVERY_OPTIONS = generateSliceName(actionName, 'update payment');

const createRegularBatchUpdatePaymentsSlice = () => {
  const slice = createSlice({
    name: PAYMENT_UPDATE_DELIVERY_OPTIONS,
    initialState,
    reducers: {
      setMemo(state, { payload }) {
        const { itemId, memo } = payload;

        state.byId[itemId].payment.note = memo;
      },
      setAmount(state, { payload }) {
        const { itemId, amount } = payload;

        state.byId[itemId].payment.amount = amount;
      },
      setPaymentScheduledDate(state, { payload }) {
        const { itemId, scheduledDate } = payload;
        state.byId[itemId].payment.scheduledDate = scheduledDate;
      },
      resetDeliveryMethod(state, { payload }) {
        const { itemId } = payload;
        state.byId[itemId].fee = null;
        state.byId[itemId].payment.deliveryPreference = null;
        state.byId[itemId].payment.deliveryEta = null;
        state.byId[itemId].payment.maxDeliveryEta = null;
        state.byId[itemId].payment.deliveryMethodId = null;
        state.byId[itemId].payment.deliveryMethod = null;
      },
      setPaymentByDeliveryOption(state, { payload }) {
        const {
          itemId,
          deliveryOption: { scheduledDate, deliveryDate, type, maxDeliveryDate, minScheduledDate, fee },
        } = payload;
        state.byId[itemId].minScheduledDate = minScheduledDate;
        state.byId[itemId].fee = fee;
        state.byId[itemId].payment.deliveryPreference = type;
        state.byId[itemId].payment.scheduledDate = scheduledDate;
        state.byId[itemId].payment.deliveryEta = deliveryDate;
        state.byId[itemId].payment.maxDeliveryEta = maxDeliveryDate;
      },
      setBillsToUpdate(state, { payload }) {
        const { billsToUpdate } = payload;
        state.billsToUpdate = billsToUpdate;
      },
      setPaymentPurpose(state, { payload }) {
        const { itemId, purpose, purposeDescription } = payload;

        state.byId[itemId].payment.purpose = purpose;
        state.byId[itemId].payment.purposeDescription = purposeDescription;
      },
      setBillPaymentFile(state, { payload }) {
        const { itemId, billId, files } = payload;
        const bill = state.byId[itemId].payment.bills.find((b) => b.id === billId);
        bill.files = files;
      },
    },
  });

  return {
    ...slice,
    initialState,
    selectors: {
      billsToUpdate: (state): BatchItemType[] => state[name].billsToUpdate.map((billId) => state[name].byId[billId]),
    },
    dispatchers: {
      setMemo: (dispatch) => (params) => dispatch(slice.actions.setMemo(params)),
      setAmount: (dispatch) => (params) => dispatch(slice.actions.setAmount(params)),
      setPaymentByDeliveryOption: (dispatch) => (params) => dispatch(slice.actions.setPaymentByDeliveryOption(params)),
      resetDeliveryMethod: (dispatch) => (params) => dispatch(slice.actions.resetDeliveryMethod(params)),
      setPaymentScheduledDate: (dispatch) => (params) => dispatch(slice.actions.setPaymentScheduledDate(params)),
      setBillsToUpdate: (dispatch) => (params) => dispatch(slice.actions.setBillsToUpdate(params)),
      setPaymentPurpose: (dispatch) => (params) => dispatch(slice.actions.setPaymentPurpose(params)),
      setBillPaymentFile: (dispatch) => (params) => dispatch(slice.actions.setBillPaymentFile(params)),
    },
  };
};

export const regularBatchUpdatePaymentsSlice = createRegularBatchUpdatePaymentsSlice();
