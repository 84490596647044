import { BATCH_PAYMENTS_MAPPING as amexBatchAddressModalMapping } from 'src/components/funding-source/amex/event-mapping-amex-address';
import { BATCH_PAYMENTS_MAPPING as amexBatchIndustryModalMapping } from 'src/components/funding-source/amex/event-mapping-amex-industry';
import { EventMappingsTree } from 'src/services/analytics/types';

export const PAGE_EVENT = 'regular-batch-payments';

const basicProperties = {
  batchBillIds: 'batchBillIds',
  partialBillIds: 'partialBillIds',
  batchBillCount: 'batchBillCount',
  batchBillTotalAmount: 'batchBillTotalAmount',
  batchPaymentsBillFlowUuids: 'batchPaymentsBillFlowUuids',
};

export const allProperties = {
  ...basicProperties,
  fundingSourceId: 'fundingSourceId',
  isAllAtOnceOption: 'isAllAtOnceOption',
  deductionDate: 'deductionDate',
};

export const MAPPING: EventMappingsTree = {
  '/orgs/*/batch-payments': {
    'page.view': [PAGE_EVENT, 'batch-payments-table-page-load'],
    'regularBatchPayments.cancel': [PAGE_EVENT, 'cancel-click'],
    'regularBatchPayments.back': [PAGE_EVENT, 'back-click'],
    'regularBatchPayments.BatchSettings.sameDate': [
      PAGE_EVENT,
      'update-deduction-date-type',
      { deductionDateType: 'deductionDateType' },
    ],
    'regularBatchPayments.BatchSettings.arriveByDueDate': [
      PAGE_EVENT,
      'update-deduction-date-type',
      { deductionDateType: 'deductionDateType' },
    ],
    'regularBatchPayments.removeBillId': [
      PAGE_EVENT,
      'remove-bill-from-batch',
      {
        billIds: 'billIds',
        deliveryMethodId: 'deliveryMethodId',
        payBillFlowUUID: 'payBillFlowUUID',
      },
    ],
    'regularBatchPayments.removeBillSubRow': [
      PAGE_EVENT,
      'remove-bill-from-bulk',
      {
        billId: 'billId',
        deliveryMethodId: 'deliveryMethodId',
        payBillFlowUUID: 'payBillFlowUUID',
      },
    ],
    'regularBatchPayments.clickExpandRow': [
      PAGE_EVENT,
      'bulk-grouping-click',
      {
        groupingState: 'groupingState',
        billIds: 'billIds,',
        vendorId: 'vendorId',
        payBillFlowUUID: 'payBillFlowUUID',
      },
    ],
    'regularBatchPayments.createPayments.fastAchExposure': [PAGE_EVENT, 'fast-ach-exposure', basicProperties],
    'regularBatchPayments.createPayments.expressCheckExposure': [PAGE_EVENT, 'express-check-exposure', basicProperties],
    'regularBatchPayments.loadBills': [
      PAGE_EVENT,
      'load_batch_payments_table',
      {
        ...basicProperties,
        noDeliveryMethodBillIds: 'noDeliveryMethodBillIds',
        noDeliveryMethodBillCount: 'noDeliveryMethodBillCount',
      },
    ],
    'regularBatchPayments.createPayments': [
      PAGE_EVENT,
      'schedule-payments',
      {
        ...allProperties,
        failedBillIds: 'failedBillIds',
        failedBillCount: 'failedBillCount',
        scheduledBillIds: 'scheduledBillIds',
        scheduledBillCount: 'scheduledBillCount',
        FastACHBillIds: 'FastACHBillIds',
        FastACHBillCount: 'FastACHBillCount',
        ExpressCheckBillsIds: 'ExpressCheckBillsIds',
        ExpressCheckBillsCount: 'ExpressCheckBillsCount',
      },
    ],
    'regularBatchPayments.cells.actions.viewBillDetails': [
      PAGE_EVENT,
      'view-bill-details-click',
      {
        billId: 'billId',
        payBillFlowUUID: 'payBillFlowUUID',
      },
    ],
    'regularBatchPayments.cells.actions.addMemoToVendor': [
      PAGE_EVENT,
      'add-memo-to-vendor-click',
      {
        billId: 'billId',
        vendorId: 'vendorId',
        payBillFlowUUID: 'payBillFlowUUID',
      },
    ],
    'regularBatchPayments.addMemo.button': [
      PAGE_EVENT,
      'save-memo-to-vendor-click',
      {
        billId: 'billId',
      },
    ],
    'regularBatchPayments.addInvoiceAttachment.button': [PAGE_EVENT, 'add-invoice-attachment', { billId: 'billId' }],
    ChangeDeliveryMethod: [
      PAGE_EVENT,
      'change-delivery-method',
      {
        billId: 'billId',
        vendorId: 'vendorId',
        DeliveryMethodId: 'DeliveryMethodId',
        payBillFlowUUID: 'payBillFlowUUID',
      },
    ],
    AddDeliveryMethodClick: [
      PAGE_EVENT,
      'add-delivery-method-click',
      {
        billId: 'billId',
        vendorId: 'vendorId',
        payBillFlowUUID: 'payBillFlowUUID',
      },
    ],
    FailedCreatePaymentModal: [PAGE_EVENT, 'timeout-failed-create-payments', { toggle: 'toggle' }],
    FailedBillsListModal: [
      PAGE_EVENT,
      'some-failed-create-payments',
      {
        toggle: 'toggle',
      },
    ],
    AllFailedBillsListModal: [
      PAGE_EVENT,
      'all-failed-create-payments',
      {
        toggle: 'toggle',
      },
    ],
    'payment-on-focus-amount': [
      PAGE_EVENT,
      'focus-payment-amount',
      { amount: 'amount', billId: 'billId', partialBillId: 'partialBillId', payBillFlowUUID: 'payBillFlowUUID' },
    ],
    'bulk-payment-switch': [PAGE_EVENT, 'bulk-payment-switch', { newToggleState: 'newToggleState' }],
    ...amexBatchIndustryModalMapping,
    ...amexBatchAddressModalMapping,
  },
  '/orgs/*/batch-payments/success': {
    'page.view': [PAGE_EVENT, 'success-page-load'],
  },
};
