import { featureFlags } from '@melio/shared-web';
import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { FeatureFlags, NotificationVariant } from 'src/utils/consts';

export const useInboxEmailAddress = ({ eventPage }: { eventPage: string }) => {
  const companyInfo = useSelector(getCompanyInfo);
  const { inboxEmailAddress } = companyInfo;
  const [isBillInboxEnabled] = featureFlags.useFeature(FeatureFlags.BillInbox, false);
  const onCopyEmail = () => {
    copy(inboxEmailAddress || '');
    analytics.track(eventPage, 'copy-email-pay-inbox-message');
    pushNotification({
      type: NotificationVariant.INFO,
      msg: 'settings.payInbox.toast',
    });
  };

  return {
    inboxEmailAddress,
    isBillInboxEnabled,
    onCopyEmail,
  };
};
