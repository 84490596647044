import { batch } from 'react-redux';
import { Dispatch } from 'redux';
import {
  AccountType,
  CompanyInfoType,
  OrganizationPreferencesType,
  UserContextType,
  UserPreferencesType,
} from 'src/utils/types';
import {
  CHECK_AND_INIT_USER,
  CHECK_AND_INIT_USER_FINISH,
  CLEAR_USER_INFO,
  DELETE_DELIVERY_METHOD,
  DELETE_DELIVERY_METHOD_FAILED,
  DELETE_DELIVERY_METHOD_SUCCESS,
  DELETE_FUNDING_SOURCE,
  DELETE_FUNDING_SOURCE_FAILED,
  DELETE_FUNDING_SOURCE_SUCCESS,
  INIT_INTERCOM_USER,
  INIT_USER,
  INIT_USER_SUCCESS,
  LOAD_COMPANY_INFO,
  LOAD_COMPANY_INFO_FAILED,
  LOAD_COMPANY_INFO_SUCCESS,
  LOAD_COMPANY_LOGO,
  LOAD_COMPANY_LOGO_FAILED,
  LOAD_COMPANY_LOGO_SUCCESS,
  LOAD_DELIVERY_METHODS,
  LOAD_DELIVERY_METHODS_FAILED,
  LOAD_DELIVERY_METHODS_SUCCESS,
  LOAD_FUNDING_SOURCES,
  LOAD_FUNDING_SOURCES_FAILED,
  LOAD_FUNDING_SOURCES_SUCCESS,
  LOAD_PROFILE,
  LOAD_PROFILE_FAILED,
  LOAD_PROFILE_SUCCESS,
  REMOVE_FUNDING_SOURCE_LABEL,
  REMOVE_FUNDING_SOURCE_LABEL_FAILED,
  REMOVE_FUNDING_SOURCE_LABEL_SUCCESS,
  SET_COMPANY_INFO,
  SET_ORGANIZATION_PREFERENCES,
  SET_PROFILE,
  SET_USER_PREFERENCES,
  UPDATE_FUNDING_SOURCE_LABEL,
  UPDATE_FUNDING_SOURCE_LABEL_FAILED,
  UPDATE_FUNDING_SOURCE_LABEL_SUCCESS,
  UPDATE_ORIGIN_PLAID_ITEM_ID,
  UPDATE_USER_PREFERENCE,
  UPDATE_USER_PREFERENCE_FAILED,
  UPDATE_USER_PREFERENCE_SUCCESS,
  VERIFY_FUNDING_SOURCE,
  VERIFY_FUNDING_SOURCE_FAILED,
  VERIFY_FUNDING_SOURCE_SUCCESS,
} from './actionTypes';

export const loadFundingSourcesAction = (
  resolve: (value: void | PromiseLike<void>) => void,
  reject?: (error: Error) => void
) => ({
  type: LOAD_FUNDING_SOURCES,
  resolve,
  reject,
});

export const loadFundingSourcesSuccessAction = (fundingSources: readonly AccountType[]) => ({
  type: LOAD_FUNDING_SOURCES_SUCCESS,
  fundingSources,
});

export const loadFundingSourcesFailedAction = () => ({
  type: LOAD_FUNDING_SOURCES_FAILED,
});

export const deleteFundingSourceAction = (
  id: number,
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: Error) => void
) => ({
  type: DELETE_FUNDING_SOURCE,
  id,
  resolve,
  reject,
});

export const deleteFundingSourceSuccessAction = (deletedFundingSourceId: number) => ({
  type: DELETE_FUNDING_SOURCE_SUCCESS,
  deletedFundingSourceId,
});

export const deleteFundingSourceFailedAction = () => ({
  type: DELETE_FUNDING_SOURCE_FAILED,
});

export const updateFundingSourceLabelAction = (
  id: number,
  nickname: string,
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: Error) => void
) => ({
  type: UPDATE_FUNDING_SOURCE_LABEL,
  id,
  nickname,
  resolve,
  reject,
});

export const updateFundingSourceLabelSuccessAction = (updatedLabelFundingSource: AccountType) => ({
  type: UPDATE_FUNDING_SOURCE_LABEL_SUCCESS,
  updatedLabelFundingSource,
});

export const updateFundingSourceLabelFailedAction = () => ({
  type: UPDATE_FUNDING_SOURCE_LABEL_FAILED,
});

export const removeFundingSourceLabelAction = (
  id: number,
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: Error) => void
) => ({
  type: REMOVE_FUNDING_SOURCE_LABEL,
  id,
  resolve,
  reject,
});
export const removeFundingSourceLabelSuccessAction = (removedLabelFundingSource: AccountType) => ({
  type: REMOVE_FUNDING_SOURCE_LABEL_SUCCESS,
  removedLabelFundingSource,
});

export const removeFundingSourceLabelFailedAction = () => ({
  type: REMOVE_FUNDING_SOURCE_LABEL_FAILED,
});

export const loadProfileAction = (
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: any) => void
) => ({
  type: LOAD_PROFILE,
  resolve,
  reject,
});

export const verifyFundingSourceAction = (
  id: number,
  token: string | null | undefined,
  amount1: number,
  amount2: number,
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: Error | { code: string }) => void
) => ({
  type: VERIFY_FUNDING_SOURCE,
  id,
  token,
  amount1,
  amount2,
  resolve,
  reject,
});

export const loadProfileSuccessAction = (profile: UserContextType) => ({
  type: LOAD_PROFILE_SUCCESS,
  profile,
});

export const loadProfileFailedAction = () => ({
  type: LOAD_PROFILE_FAILED,
});

export const setProfileAction = (profile: UserContextType, organizations?: readonly CompanyInfoType[]) => ({
  type: SET_PROFILE,
  profile,
  organizations,
});

export const clearUserInfoAction = () => ({
  type: CLEAR_USER_INFO,
});

export const verifyFundingSourceSuccessAction = (verifiedFundingSourceId: number) => ({
  type: VERIFY_FUNDING_SOURCE_SUCCESS,
  verifiedFundingSourceId,
});

export const verifyFundingSourceFailedAction = (verifiedFundingSourceId: number, errorCode: string) => ({
  type: VERIFY_FUNDING_SOURCE_FAILED,
  verifiedFundingSourceId,
  errorCode,
});

export const loadDeliveryMethodsAction = (
  resolve: (value: void | PromiseLike<void>) => void,
  reject?: (error: Error) => void
) => ({
  type: LOAD_DELIVERY_METHODS,
  resolve,
  reject,
});

export const loadDeliveryMethodsSuccessAction = (deliveryMethods: AccountType[]) => ({
  type: LOAD_DELIVERY_METHODS_SUCCESS,
  deliveryMethods,
});

export const loadDeliveryMethodsFailedAction = () => ({
  type: LOAD_DELIVERY_METHODS_FAILED,
});
export const deleteDeliveryMethodAction = (
  id: number,
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: Error) => void
) => ({
  type: DELETE_DELIVERY_METHOD,
  id,
  resolve,
  reject,
});
export const deleteDeliveryMethodSuccessAction = (deletedDeliveryMethodId: number) => ({
  type: DELETE_DELIVERY_METHOD_SUCCESS,
  deletedDeliveryMethodId,
});

export const deleteDeliveryMethodFailedAction = () => ({
  type: DELETE_DELIVERY_METHOD_FAILED,
});

export const loadCompanyInfoAction = (
  resolve: (value: void | PromiseLike<void>) => void,
  reject?: (error: Error) => void
) => ({
  type: LOAD_COMPANY_INFO,
  resolve,
  reject,
});

export const loadCompanyInfoSuccessAction = (companyInfo: CompanyInfoType) => ({
  type: LOAD_COMPANY_INFO_SUCCESS,
  companyInfo,
});

export const loadCompanyInfoFailedAction = () => ({
  type: LOAD_COMPANY_INFO_FAILED,
});

export const loadCompanyLogoAction = (fileId: number) => ({
  type: LOAD_COMPANY_LOGO,
  fileId,
});

export const loadCompanyLogoSuccessAction = (logoUrl: string, orgId: number) => ({
  type: LOAD_COMPANY_LOGO_SUCCESS,
  logoUrl,
  orgId,
});

export const loadCompanyLogoFailedAction = () => ({
  type: LOAD_COMPANY_LOGO_FAILED,
});

export const setCompanyInfoAction = (companyInfo: CompanyInfoType) => ({
  type: SET_COMPANY_INFO,
  companyInfo,
});

export const updateUserPreferenceAction = (
  id: string,
  value: boolean | string | null,
  resolve: (value: void | PromiseLike<void>) => void = () => undefined,
  reject: (error: Error) => void = () => undefined
) => ({
  type: UPDATE_USER_PREFERENCE,
  id,
  value,
  resolve,
  reject,
});

export const updateUserPreferenceSuccessAction = (id: string, value: boolean | string | null) => ({
  type: UPDATE_USER_PREFERENCE_SUCCESS,
  id,
  value,
});

export const updateUserPreferenceFailedAction = () => ({
  type: UPDATE_USER_PREFERENCE_FAILED,
});

export const setUserPreferencesAction = (userPreferences: UserPreferencesType) => ({
  type: SET_USER_PREFERENCES,
  userPreferences,
});

export const setOrganizationPreferencesAction = (organizationPreferences: OrganizationPreferencesType) => ({
  type: SET_ORGANIZATION_PREFERENCES,
  organizationPreferences,
});

export const updateOriginPlaidItemIdAction = (id: number | null) => ({
  type: UPDATE_ORIGIN_PLAID_ITEM_ID,
  id,
});

export const initIntercomUserAction = (user: UserContextType, isLoggedInAs = false) => ({
  type: INIT_INTERCOM_USER,
  user,
  isLoggedInAs,
});

export const initUserAction = (
  user: UserContextType,
  isLoggedIn = false,
  resolve: (
    value: void | PromiseLike<void> | { companyInfo: CompanyInfoType } | PromiseLike<{ companyInfo: CompanyInfoType }>
  ) => void,
  reject: (error: Error) => void
) => ({
  type: INIT_USER,
  user,
  isLoggedIn,
  resolve,
  reject,
});

export const initUserSuccessAction = (isLoggedInAs: boolean) => ({
  type: INIT_USER_SUCCESS,
  isLoggedInAs,
});

export const checkAndInitUserAction = (
  params?: {
    orgId?: number;
  },
  resolve?: (
    value: void | PromiseLike<void> | { companyInfo: CompanyInfoType } | PromiseLike<{ companyInfo: CompanyInfoType }>
  ) => void,
  reject: ((error?: Error) => void) | undefined = () => undefined
) => ({
  type: CHECK_AND_INIT_USER,
  ...(params || {}),
  resolve,
  reject,
});

export const checkAndInitUserFinishAction = () => ({
  type: CHECK_AND_INIT_USER_FINISH,
});

export const updateCompanyInfoAction = (
  dispatch: Dispatch<any>,
  companyInfo: CompanyInfoType & { dateOfBirth?: string },
  profile: UserContextType
) => {
  batch(() => {
    let profileUpdateData: Partial<CompanyInfoType & UserContextType> = {
      orgName: companyInfo.companyName,
      ...(companyInfo?.dateOfBirth && { dateOfBirth: companyInfo.dateOfBirth }),
    };

    if (companyInfo.contactFirstName && companyInfo.contactLastName && !profile.firstName && !profile.lastName) {
      profileUpdateData = {
        ...profileUpdateData,
        firstName: companyInfo.contactFirstName,
        lastName: companyInfo.contactLastName,
      };
    }

    dispatch(setProfileAction({ ...profile, ...profileUpdateData }));
    dispatch(setCompanyInfoAction(companyInfo));
  });
};
