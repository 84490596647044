import { useCallback, useEffect, useMemo } from 'react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { UserExistErrorNotificationCard } from 'src/components/common/UserExistNotificationCard';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import intuitSignInLogo from 'src/images/accounting-software/sign-in-with-intuit.svg';
import authenticationStore from 'src/modules/auth/auth-store';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { authLocations } from 'src/pages/auth/locations';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { initUserAction } from 'src/redux/user/actions';
import { analytics } from 'src/services/analytics';
import guestApi from 'src/services/api/guests';
import { useForm } from 'src/ui/form';
import { ButtonVariant, CompanyInfoOnboardingOrigin, RegistrationFlow, RegistrationOrigin } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import { parseQueryString } from 'src/utils/query-utils';
import { UserContextType } from 'src/utils/types';

type Props = {
  registrationFlow: RegistrationFlow;
};

const getRegistrationFlowData = (registrationFlow, site) => {
  switch (registrationFlow) {
    case RegistrationFlow.VENDOR:
      return site.createOrigin.pay.payee;
    case RegistrationFlow.PUSH_TO_DEBIT:
      return RegistrationFlow.PUSH_TO_DEBIT;
    case RegistrationFlow.PUSH_TO_FAST_ACH:
      return RegistrationFlow.PUSH_TO_FAST_ACH;
    default:
      return site.createOrigin.pay.payor;
  }
};

const RegisterPageVendor: React.FC<Props> = ({ registrationFlow }) => {
  const param = parseQueryString(window.location.search);
  const { state: locationState } = useLocation<Record<string, any>>();
  const { navigate } = usePreservedStateNavigator();

  const isVendorRegistrationFlow =
    registrationFlow === RegistrationFlow.VENDOR || registrationFlow === RegistrationFlow.PUSH_TO_DEBIT;
  const eventPage = isVendorRegistrationFlow ? 'vendor-register' : 'register-with-email';
  const prefilledEmail = locationState?.email || locationState?.preservedState?.email || param?.email || '';
  const site = useSiteContext();
  const isEmailPrefilled = !!prefilledEmail;
  const dispatch = useDispatch();
  const initUser = useCallback(
    async (user: UserContextType) =>
      new Promise((resolve, reject) => {
        dispatch(initUserAction(user, false, resolve, reject));
      }),
    [dispatch]
  );
  const { onApiCall: onSendVerificationCodeCall } = useApi({
    api: guestApi.sendEmailVerificationCode,
  });
  const { vendorRegistration, regularRegistration } = getStoreActions(authenticationStore)(dispatch);
  const { isLoading, error, user } = useStructuredSelectors(authenticationStore.selectors.registration());
  const model = useMemo(
    () => ({
      password: '',
      email: prefilledEmail || '',
    }),
    [prefilledEmail]
  );
  const vendorIdToConvertToOwned = isVendorRegistrationFlow
    ? locationState?.vendorIdToConvertToOwned || param?.vendorIdToConvertToOwned
    : '';
  const onRegistration = useCallback(
    async (email, password) => {
      const registerData = {
        email,
        password,
        registrationOrigin: isVendorRegistrationFlow ? RegistrationFlow.VENDOR : RegistrationOrigin.APP,
        registrationFlow: getRegistrationFlowData(registrationFlow, site),
        referringVendor: !isVendorRegistrationFlow ? locationState?.preservedState?.vendorId : null,
      };

      if (isVendorRegistrationFlow) {
        await vendorRegistration(registerData);
      } else {
        await regularRegistration(registerData);
      }

      analytics.triggerCampaignEvents();
    },
    [vendorRegistration, regularRegistration, isVendorRegistrationFlow, registrationFlow, site, locationState]
  );

  const [registerMV, { submit }] = useForm(model, {
    submit: ({ email, password }) => onRegistration(email, password),
  });

  const goRestoreGuestSession = useCallback(async () => {
    const email = registerMV.email.value;
    analytics.track(eventPage, 'register-guest-restore-session');
    onSendVerificationCodeCall(email).then(() => {
      navigate(melioMeLocations.registerEmailVerify, false, { email });
    });
  }, [registerMV.email.value, eventPage, navigate, onSendVerificationCodeCall]);

  useEffect(() => {
    if (error?.code === 'ATH18') {
      goRestoreGuestSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (user) {
      initUser(user);

      if (user?.isEmailVerified) {
        const isFromPaymentRequestFlow = !!locationState?.preservedState?.paymentRequest;
        const origin = isFromPaymentRequestFlow
          ? CompanyInfoOnboardingOrigin.PAYMENT_REQUEST
          : CompanyInfoOnboardingOrigin.SETUP;
        navigate(site.onboardingEntryUrl, false, { origin });
      } else {
        navigate(authLocations.register.codeVerification, false, {
          vendorIdToConvertToOwned,
        });
      }
    }
  }, [
    user,
    locationState,
    initUser,
    navigate,
    site.onboardingEntryUrl,
    vendorIdToConvertToOwned,
    isVendorRegistrationFlow,
  ]);

  const goLogin = () => {
    navigate(authLocations.login);
  };

  const goLinkQuickbooks = () => {
    analytics.track(eventPage, 'link-quickbooks');
    intuit.goConnectToIntuit({
      registrationOrigin: RegistrationOrigin.APP,
      intuitReturnUrl: authLocations.register.authorizeIntuitSuccess,
      intent: 'melioSyncSignIn',
    });
  };

  const title =
    registrationFlow === RegistrationFlow.VENDOR ? 'auth.vendorRegister.title' : 'auth.regularRegister.title';
  const subtitle =
    registrationFlow === RegistrationFlow.VENDOR ? 'auth.vendorRegister.subtitle' : 'auth.regularRegister.subtitle';

  const hideQuickbooksButton = site.embeddedMode || registrationFlow === RegistrationFlow.VENDOR;

  return (
    <RegisterLayoutPage
      title={title}
      text={subtitle}
      linkHint="auth.register.loginHint"
      linkLabel="auth.register.loginLink"
      linkAction={goLogin}
      buttonLabel="auth.registerFromLanding.signupButton"
      buttonAction={submit}
      secondButtonAction={hideQuickbooksButton ? undefined : goLinkQuickbooks}
      secondButtonVariant={ButtonVariant.INTUIT}
      secondButtonLogo={intuitSignInLogo}
      isLoading={isLoading}
    >
      {error?.code && <UserExistErrorNotificationCard errorCode={error?.code} onLogin={goLogin} preserveState />}
      <FormContainer>
        <PrivateDataContainer>
          <TextField
            label="auth.vendorRegister.workEmail"
            model={registerMV.email}
            autoFocus={!isEmailPrefilled}
            type="email"
            isRequired
          />
        </PrivateDataContainer>

        <PasswordField
          label="auth.vendorRegister.password"
          model={registerMV.password}
          autoFocus={isEmailPrefilled}
          helperText="auth.vendorRegister.passwordNotice"
          isRequired
        />
      </FormContainer>
    </RegisterLayoutPage>
  );
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default RegisterPageVendor;
