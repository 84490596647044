import { useState } from 'react';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { useInvoiceGenerationEnabled } from 'src/pages/get-pro/hooks/useInvoiceGenerationEnabled';
import { settingsLocations } from 'src/pages/settings/locations';
import { analytics } from 'src/services/analytics';
import { OrgPreferencesTypeKeys } from 'src/utils/consts';

type ShowInvoiceGenerationMessage = {
  title: string;
  description: string;
  action: string;
  badge: string;
  onClick: () => void;
  onClose: () => void;
  openMessage: boolean;
  shouldShowInvoiceGenerationMessage: boolean;
  testId: string;
};

export const useShowInvoiceGenerationMessage = (): ShowInvoiceGenerationMessage => {
  const [openMessage, setOpenMessage] = useState(true);
  const { shouldGenerateInvoiceFile, isInvoiceGenerationEnabled } = useInvoiceGenerationEnabled();
  const [historyPush] = useHistoryWithOrgId();
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();
  const organizationPreferences = useOrganizationPreferences();
  const shouldShowInvoiceGenerationMessage =
    organizationPreferences?.[OrgPreferencesTypeKeys.invoiceGenerationMessage] === 'true' &&
    isInvoiceGenerationEnabled &&
    !shouldGenerateInvoiceFile;

  const onClose = async () => {
    analytics.trackAction('close-invoice-generation-message');
    setOpenMessage(false);
    await updateOrganizationPreference({
      key: OrgPreferencesTypeKeys.invoiceGenerationMessage,
      value: 'false',
    });
  };

  const onClick = async () => {
    analytics.trackAction('cta-invoice-generation-message');
    setOpenMessage(false);
    await updateOrganizationPreference({
      key: OrgPreferencesTypeKeys.invoiceGenerationMessage,
      value: 'false',
    });
    historyPush({ path: settingsLocations.accountingSoftware });
  };

  return {
    title: 'getPro.message.invoiceGeneration.title',
    description: 'getPro.message.invoiceGeneration.description',
    action: 'getPro.message.invoiceGeneration.action',
    badge: 'getPro.message.invoiceGeneration.badge',
    shouldShowInvoiceGenerationMessage,
    openMessage,
    onClick,
    onClose,
    testId: 'invoice-generation-message',
  };
};
