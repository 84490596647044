import { useCallback, useMemo } from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { getDeliveryOption, getFastType, isFastDeliveryType } from 'src/utils/delivery-methods';

const useDeliverySpeed = (itemId, deliveryPreference, deliveryMethodType, deliveryMethodId, deliveryOptions) => {
  const actions = useStoreActions(regularBatchPaymentsStore);

  const isFastDelivery = useMemo(() => isFastDeliveryType(deliveryPreference), [deliveryPreference]);

  const isDeliverySpeedOptionDisabled = useMemo(() => !deliveryMethodType || deliveryOptions.length <= 1, [
    deliveryMethodType,
    deliveryOptions.length,
  ]);

  const toggleType = useCallback(() => {
    if (isFastDelivery) return null;

    return getFastType(deliveryMethodType);
  }, [deliveryMethodType, isFastDelivery]);

  const onDeliverySpeedChange = useCallback(async () => {
    const selectedDeliveryPreference = toggleType();
    const selectedDeliveryOption = getDeliveryOption(selectedDeliveryPreference || deliveryMethodType, deliveryOptions);

    if (selectedDeliveryOption) {
      await actions.updatePayments.setPaymentByDeliveryOption({
        itemId,
        deliveryOption: selectedDeliveryOption,
      });
    }
  }, [toggleType, deliveryMethodType, deliveryOptions, actions, itemId]);

  return [isFastDelivery, isDeliverySpeedOptionDisabled, onDeliverySpeedChange, deliveryOptions];
};

export default useDeliverySpeed;
