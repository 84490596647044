import merge from 'lodash/merge';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from './createApiCallSlice';
import { RestfulSliceParams } from './types';

export function updateSliceReducer(actionName = 'update') {
  return {
    [ON_REQUEST](state, action) {
      state[actionName][action.payload.id] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const id = action.payload.id || action.meta?.identifier?.id;
      delete state[actionName][id];
      state.byId[id] = merge(state.byId[id] || {}, action.payload);
    },
    [ON_FAILURE](state, action) {
      state[actionName][action.payload.id] = {
        loading: false,
        error: action.error,
      };
    },
  };
}

export function createUpdateSlice<T>({
  storeName,
  api,
  validate,
  sagas,
  selectors,
  actionName = 'update',
}: RestfulSliceParams<T>) {
  return createApiCallSlice({
    initialState: {
      byId: {},
      [actionName]: {},
    },
    name: generateSliceName(storeName, actionName),
    api,
    reducers: updateSliceReducer(actionName),
    validate,
    selectors: {
      status: (id) => (state) => state[storeName][actionName]?.[id],
      ...(selectors || {}),
    },
    sagas,
  });
}
