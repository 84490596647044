import { Button, ButtonVariants } from 'src/core/ds/button';
import { useModal } from 'src/helpers/react/useModal';
import useRemoveBatch from 'src/pages/get-paid/hooks/useRemoveBatch';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';

type Props = {
  numOfInvoices: number;
  totalAmount: number;
};

const DeleteUnsentInvoicesButton = ({ numOfInvoices, totalAmount }: Props) => {
  const successLabel =
    numOfInvoices > 1 ? 'getPaid.deleteInvoices.success.multi' : 'getPaid.deleteInvoices.success.single';
  const [removeBatch] = useRemoveBatch(successLabel, 'getPaid.deleteInvoices.error');
  const [DeleteUnsentInvoicesModal, onDeleteUnsentInvoices] = useModal(CommonDialog, {
    confirm: () => removeBatch(true),
    title: 'getPaid.view.batch.deleteMessage.unsent.title',
    textValues: {
      totalAmount,
      numOfInvoices,
    },
    description: 'getPaid.view.batch.deleteMessage.unsent.description',
    confirmText: 'getPaid.view.batch.deleteMessage.unsent.confirm',
    cancelText: 'getPaid.view.batch.deleteMessage.unsent.cancel',
  });

  return (
    <>
      {DeleteUnsentInvoicesModal}
      <Button
        label="getPaid.view.batch.delete.unsent"
        onClick={onDeleteUnsentInvoices}
        variant={ButtonVariants.tertiary}
      />
    </>
  );
};

export default DeleteUnsentInvoicesButton;
