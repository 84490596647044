import cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import config from 'src/config';
import { analytics } from 'src/services/analytics';
import { fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { ResolveEmailVerificationRequestData } from 'src/services/api/guests';
import { RegistrationOrigin } from 'src/utils/consts';
import { UserContextType } from 'src/utils/types';

type BasePayloadType = {
  email: string;
  password: string;
};
type LoginPayloadType = {
  isAfterRegister?: boolean;
} & BasePayloadType;

type GuestRegisterPayloadType = {
  registrationFlow: string;
  referringVendor?: number;
} & BasePayloadType;

export type MfaVerifyCodePayloadType = {
  auth_code: string; // eslint-disable-line camelcase
  authenticator_id: string; // eslint-disable-line camelcase
  mfa_token: string; // eslint-disable-line camelcase
  agent_id?: string; // eslint-disable-line camelcase
};

export type RegisterPayloadType = {
  registrationOrigin: RegistrationOrigin.LANDING | RegistrationOrigin.LANDING_NO_VERIFICATION;
  registrationFlow: string;
  referringVendor?: string;
} & BasePayloadType;

const API = {
  LOGIN: '/auth/login',
  LOGIN_WITH_TOKEN: '/auth/login-with-token',
  LOGIN_WITH_QUICKBOOKS_UNSYNCED: '/qbo-unsynced/login',
  CHECK: '/auth/check',
  REGISTER: '/auth/register',
  VENDOR_REGISTER: '/auth/vendor/register',
  VENDOR_RESOLVE_EMAIL_VERIFICATION: '/auth/vendor/resolve-email-verification',
  GUEST_REGISTER: '/auth/guest/register',
  REGISTER_INVITATION: '/auth/invitation/register',
  VALIDATE_INVITATION: '/auth/invitation/validate',
  DETAILS: '/auth/details',
  MARKETING_DETAILS: '/auth/marketing-details',
  CHANGE_PASSWORD: '/auth/change-password',
  CHANGE_EMAIL: '/auth/change-email',
  CHECK_EMAIL_UNIQUENESS: '/auth/register/check-email',
  LOGOUT: '/auth/logout',
  RESET_PASSWORD_REQUEST: '/auth/reset-password/request',
  RESET_PASSWORD_RESOLVE: '/auth/reset-password/resolve',
  CHANGE_PASSWORD_WITH_TOKEN: '/auth/change-password-with-token',
  RESEND_EMAIL_VERIFICATION_CODE: '/auth/resend-email-verification-code',
  RESOLVE_EMAIL_VERIFICATION_CODE: '/auth/resolve-email-verification-code',
  LOGIN_WITH_GOOGLE_REDIRECT: '/auth/google/redirect',
  INTUIT_UNLINK: '/auth/intuit/unlink',
  ACCEPT_USER_INVITATION: '/auth/invitation/accept-user-invitation',
} as const;

export async function login({ email, password, isAfterRegister }: LoginPayloadType) {
  const url = API.LOGIN;
  const res = (await postRequest(url, { email, password })) || {};

  const redirectURL = res?.redirectURL?.redirectURL;

  if (redirectURL) {
    window.location.replace(redirectURL);
  }

  const { user } = res;

  initializeUserAnalytics(user, isAfterRegister);

  return res;
}

function initializeUserAnalytics(user: UserContextType, isAfterRegister?: boolean) {
  if (user) {
    if (isAfterRegister) {
      analytics.alias(user.id);
      analytics.setOneTimeMarketingTraits();
    }

    analytics.identify(user, {
      integrations: {
        Salesforce: false,
      },
    });
  }
}

export const authApi = {
  async check() {
    const url = API.CHECK;
    const res = await fetchRequest(url, null, { isBypassThrowError: true });
    const redirectURL = res?.redirectURL?.redirectURL;

    if (redirectURL) {
      window.location.replace(redirectURL);
    }

    return res;
  },

  loginWithToken(token) {
    return postRequest(API.LOGIN_WITH_TOKEN, { token }).then(this.check);
  },

  async register({ email, password, registrationOrigin, registrationFlow, referringVendor }: RegisterPayloadType) {
    const url = API.REGISTER;
    const { isFirstTimeVisitedRegularMelio, user } = await postRequest(url, {
      email,
      password,
      registrationOrigin,
      registrationFlow,
      marketingTam: cookies.get('trackingTam'),
      marketingUtms: cookies.get('utms'),
      referringVendor,
    });

    return {
      user,
      isFirstTimeVisitedRegularMelio,
    };
  },

  async vendorRegister({ email, password, registrationOrigin, registrationFlow }) {
    const url = API.VENDOR_REGISTER;
    const { user, isFirstTimeVisitedRegularMelio } = await postRequest(url, {
      email,
      password,
      registrationOrigin,
      registrationFlow,
      marketingTam: cookies.get('trackingTam'),
      marketingUtms: cookies.get('utms'),
    });

    initializeUserAnalytics(user, true);

    return { user, isFirstTimeVisitedRegularMelio };
  },

  async guestRegister({ email, password, registrationFlow, referringVendor }: GuestRegisterPayloadType) {
    const url = API.GUEST_REGISTER;

    const { user } = await postRequest(url, {
      email,
      password,
      registrationFlow,
      marketingTam: cookies.get('trackingTam'),
      marketingUtms: cookies.get('utms'),
      referringVendor,
    });

    initializeUserAnalytics(user, true);

    return { user };
  },

  async getUserDetails() {
    const url = API.DETAILS;

    return fetchRequest(url, {});
  },

  async updateUserDetails(userDetails) {
    const url = API.DETAILS;

    return putRequest(url, userDetails);
  },

  async updateUserMarketingDetails(userDetails) {
    const url = API.MARKETING_DETAILS;

    return putRequest(url, userDetails);
  },

  async changePassword(oldPassword, newPassword) {
    const url = API.CHANGE_PASSWORD;

    return postRequest(url, { oldPassword, newPassword });
  },

  async checkEmailUniqueness({ email }) {
    const url = API.CHECK_EMAIL_UNIQUENESS;

    return postRequest(url, { email });
  },

  async changeEmail({ email }) {
    const url = API.CHANGE_EMAIL;

    return postRequest(url, { email });
  },

  requestResetPassword({ email, hasNoPasswordDefined = false }: { email: string; hasNoPasswordDefined?: boolean }) {
    const url = API.RESET_PASSWORD_REQUEST;

    return postRequest(url, { email, hasNoPasswordDefined });
  },

  async resetPassword({ token, email, password, hasNoPasswordDefined }) {
    const url = API.RESET_PASSWORD_RESOLVE;
    const res = await postRequest(url, { token, email, password, hasNoPasswordDefined });

    initializeUserAnalytics(res.user, false);

    return res;
  },

  async changePasswordWithToken({ token, oldPassword, newPassword }) {
    const url = API.CHANGE_PASSWORD_WITH_TOKEN;
    const res = await postRequest(url, { token, oldPassword, newPassword });

    return res;
  },

  resendEmailVerificationCode(email) {
    const url = API.RESEND_EMAIL_VERIFICATION_CODE;

    return postRequest(url, { email });
  },

  resolveEmailVerificationCode(email, code) {
    const url = API.RESOLVE_EMAIL_VERIFICATION_CODE;

    return postRequest(url, { email, code });
  },

  async logout() {
    const url = API.LOGOUT;
    const res = await postRequest(url);

    analytics.reset(true);

    return res;
  },

  async loginWithQuickbooksUnsynced() {
    const url = API.LOGIN_WITH_QUICKBOOKS_UNSYNCED;
    const res = await postRequest(url);

    return res;
  },

  unlinkQuickbooksAccount(orgId) {
    const url = API.INTUIT_UNLINK;

    return postRequest(url, { orgId });
  },

  showMelioMeCard(handle, paymentRequestLink) {
    const url = '/auth/show-meliome';

    return fetchRequest(url, { handle, paymentRequestLink });
  },

  acceptInvitation({ password, token }) {
    return postRequest(API.REGISTER_INVITATION, { password, token });
  },

  acceptUserInvitation({ token }) {
    return postRequest(API.ACCEPT_USER_INVITATION, { token });
  },

  getLoginWithGoogleRedirectUrl({ failureUrl, successUrl }: { failureUrl: string; successUrl: string }) {
    const url = new URL(
      `api${API.LOGIN_WITH_GOOGLE_REDIRECT}`,
      !isEmpty(config.server.baseUrl) ? config.server.baseUrl : window.location.origin
    );

    const trackingTam = cookies.get('trackingTam');

    if (trackingTam) {
      url.searchParams.append('marketingTam', trackingTam);
    }

    const utms = cookies.get('utms');

    if (utms) {
      url.searchParams.append('marketingUtms', utms);
    }

    url.searchParams.append('registrationOrigin', RegistrationOrigin.APP);
    url.searchParams.append('failureUrl', failureUrl);
    url.searchParams.append('successUrl', successUrl);

    return url.toString();
  },

  request2FACode() {
    const url = '/auth/send-auth-verification-code';

    return postRequest(url);
  },

  verify2FACode(code) {
    const url = '/auth/verify-auth-code';

    return postRequest(url, { code });
  },

  vendorResolveEmailVerification(data: ResolveEmailVerificationRequestData) {
    const { email, code, fallbackTams, fallbackUtms, registrationFlow } = data;
    const cookiesUtms = cookies.get('utms');
    const cookiesTam = cookies.get('trackingTam');

    return postRequest(API.VENDOR_RESOLVE_EMAIL_VERIFICATION, {
      email,
      code,
      registrationFlow,
      marketingTam: cookiesTam || fallbackTams,
      marketingUtms: cookiesUtms || fallbackUtms,
    });
  },

  verifyMfaToken(data: MfaVerifyCodePayloadType) {
    const url = '/auth-service/oauth/token';

    return postRequest(url, {
      grant_type: 'melio:mfa_token',
      ...data,
    });
  },

  resendMfaCode(authenticatorId: string, mfaToken: string) {
    const url = '/auth-service/mfa/challenge';

    return postRequest(url, {
      authenticatorId,
      mfaToken,
    });
  },
};
