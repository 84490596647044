import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { billLocations } from 'src/pages/bill/locations';
import { getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { DeliveryMethodOrigin, FundingType, NotificationVariant } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { sendVendorDetailsRequest } from 'src/utils/vendors';
import { useVirtualDeliveryMethodState } from './hooks/useVirtualDeliveryMethodState';

const eventPage = 'new-vendor_virtual-delivery-method';
const successSubmitNotificationParams = {
  type: NotificationVariant.SUCCESS,
  msg: 'vendors.unilateralWithoutPayment.successNotification',
};

type Props = {
  vendorId: number;
  orgId: number;
};

const VirtualDeliveryMethodPageContainer = ({ vendorId, orgId }: Props) => {
  const site = useSiteContext();
  const { navigate } = useNavigator();
  const {
    virtualDeliveryMethodVM,
    submit,
    vendor,
    isFormProcessing,
    isVendorLoading,
    goExit,
  } = useVirtualDeliveryMethodState({ vendorId });
  const { billingSetting } = useSelector(getCompanyInfo);
  const [origin] = useLocationState<DeliveryMethodOrigin | null>('origin', null);
  const isPayBillOrigin = DeliveryMethodOrigin.PAY_BILL === origin;
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const isVendorOrigin =
    origin &&
    [
      DeliveryMethodOrigin.VENDOR_DETAILS,
      DeliveryMethodOrigin.NEW_VENDOR,
      DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS,
    ].includes(origin);

  if (isVendorLoading) {
    return <AreaLoader />;
  }

  const handleSendRequestAndAddBill = async () => {
    await submit();
    pushNotification(successSubmitNotificationParams);
    sendVendorDetailsRequest(orgId, vendorId);

    if (virtualDeliveryMethodVM.virtualAccount.email.value) {
      analytics.track(eventPage, `send-request-and-add-bill`, {
        vendorId,
      });
      navigate(
        `${generatePath(billLocations.create.index, {
          orgId,
        })}?vendorId=${vendorId}&manually=true`,
        false
      );
    }
  };

  const handleSendRequestAndClose = async () => {
    await submit();
    pushNotification(successSubmitNotificationParams);
    !isPayBillOrigin && sendVendorDetailsRequest(orgId, vendorId);
  };

  return (
    <StepLayoutPage
      title="vendors.deliveryMethods.virtual.title"
      titleValues={{ vendor: vendor?.companyName }}
      subtitle={
        selectedFundingSource?.fundingType !== FundingType.CARD
          ? 'vendors.deliveryMethods.virtual.subtitle.withCheckFees'
          : 'vendors.deliveryMethods.virtual.subtitle.default'
      }
      subTitleValues={{ vendor: vendor?.companyName, amount: billingSetting?.fee['ach-to-check']?.value.toFixed(2) }}
      goExit={goExit}
      onNext={isVendorOrigin ? handleSendRequestAndAddBill : handleSendRequestAndClose}
      nextLabel={
        isVendorOrigin
          ? 'vendors.deliveryMethods.virtual.sendRequestAndAddBill'
          : 'vendors.deliveryMethods.virtual.done'
      }
      isLoading={isFormProcessing}
      innerSize={50}
      fullWidthCTA={!isVendorOrigin}
      secondaryActionButtonLabel="vendors.deliveryMethods.virtual.sendRequestAndClose"
      onSecondaryActionButtonClick={isVendorOrigin ? handleSendRequestAndClose : undefined}
      footer={
        <Footer>
          <WizardElements.SimpleTextFooter>
            <MIFormattedText label="vendors.deliveryMethods.virtual.footer" values={{ vendor: vendor?.companyName }} />
          </WizardElements.SimpleTextFooter>
          <SupportLinkContainer>
            <MIInlineLink
              label="vendors.deliveryMethods.virtual.supportLink" // eslint-disable-next-line max-len
              to={`${site.config.support.site}en/articles/3994795-what-do-my-vendor-see-when-i-request-their-payment-delivery-details`}
            />
          </SupportLinkContainer>
        </Footer>
      }
    >
      <FormContainer>
        <PrivateDataContainer>
          <TextField
            label="vendors.deliveryMethods.virtual.email"
            model={virtualDeliveryMethodVM.virtualAccount.email}
            type="email"
            autoComplete="username email"
            autoFocus
            isRequired
          />
        </PrivateDataContainer>

        <PrivateDataContainer>
          <MaskField
            label="vendors.deliveryMethods.virtual.phone"
            model={virtualDeliveryMethodVM.virtualAccount.phone}
            format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
            isModelOnChangeFormatted
          />
        </PrivateDataContainer>
      </FormContainer>
    </StepLayoutPage>
  );
};

const Footer = styled.div`
  > div {
    text-align: left;
  }
`;

const SupportLinkContainer = styled.div`
  margin-top: 1.8rem;
`;
const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;

export default VirtualDeliveryMethodPageContainer;
