import every from 'lodash/every';
import filter from 'lodash/filter';
import flow from 'lodash/flow';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { actionName, name } from 'src/modules/regular-batch-payments/consts';
import { fetchBatchBillsListSlice } from 'src/modules/regular-batch-payments/regular-batch-bills-list-slice';
import { CreateBatchValidationErrorType } from 'src/modules/regular-batch-payments/types';
import {
  getBatchItemByBillIds,
  isValidInternationalPayment,
  mapFailedPaymentsData,
} from 'src/modules/regular-batch-payments/utils';
import { PaymentType } from 'src/utils/types';
import { regularBatchPaymentsApi } from './api';

export type CreateBatchPaymentType = {
  payments: PaymentType[];
  failedBills: any;
  orgId: number;
  vendorWithoutMcc?: boolean;
  vendorWithoutAddress?: boolean;
};

export const batchHashFunc = (ids: readonly string[]) => [...ids].sort().join(',');
const createActionName = 'createBatch';

export const createBatchPaymentSlice = createApiCallSlice<CreateBatchPaymentType, any>({
  name: generateSliceName(actionName, 'create'),
  api: regularBatchPaymentsApi.createBatchPayments,
  initialState: {
    byId: {},
    createBatch: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      const ids = action.payload.payments.map((p) => p.id.toString());
      const key = batchHashFunc(ids);

      state[createActionName][key] = { loading: true };
    },
    [ON_SUCCESS]: (state, action) => {
      const ids = action.meta.identifier.payments.map((p) => p.id);
      const key = batchHashFunc(ids);

      state[createActionName][key] = {
        loading: false,
        scheduled: action.payload.payments,
        failedPayments: action.payload.failedBills.map((payment) => {
          const batchItem = getBatchItemByBillIds(state, payment.billIds);

          return mapFailedPaymentsData(batchItem!, payment.error.code);
        }),
      };
    },
    [ON_FAILURE]: (state, action) => {
      const ids = action.meta.identifier.payments.map((p) => p.id);
      const key = batchHashFunc(ids);

      state[createActionName][key] = {
        loading: false,
        error: action.error,
      };
    },
    [fetchBatchBillsListSlice.actions.success]: (state) => {
      state.createBatch = {};
    },
  },
  async validate(payload): Promise<CreateBatchValidationErrorType> {
    const allBillsReady =
      every(payload.payments, (payment) => payment.deliveryMethodId) &&
      !payload.vendorWithoutMcc &&
      !payload.vendorWithoutAddress;
    const filterInternationalBillsWithoutPurpose = (payments) =>
      filter(payments, (payment) => !isValidInternationalPayment(payment, payment.isInvoiceAttachmentRequired));
    const mapBills = (payments) => map(payments, 'billId');
    const internationalBills = flow([filterInternationalBillsWithoutPurpose, mapBills])(payload.payments);
    const validationErrors: CreateBatchValidationErrorType = {};

    if (!allBillsReady) {
      validationErrors.payments = 'deliveryMethodMissed';
    }

    if (!isEmpty(internationalBills)) {
      forEach(internationalBills, (billId) => {
        validationErrors.payments = `${billId}: paymentPurposeMissed`;
      });
    }

    return isEmpty(validationErrors) ? null : validationErrors;
  },
  selectors: {
    createBatch: (ids) => (state) => {
      const stateHash = state[name]?.[createActionName]?.[batchHashFunc(ids)];
      const isCreating = stateHash?.loading;
      const error = stateHash?.error;
      const scheduledPayments = stateHash?.scheduled;
      const scheduledPaymentsCount = scheduledPayments?.length;
      const scheduledPaymentsAmount = sumBy(scheduledPayments, 'amount');
      const failedPayments = stateHash?.failedPayments;
      const failedPaymentsCount = stateHash?.failedPayments?.length;

      return {
        isCreating,
        error,
        scheduledPayments,
        scheduledPaymentsCount,
        scheduledPaymentsAmount,
        failedPayments,
        failedPaymentsCount,
      };
    },
  },
});
