import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { BatchUploadContacts } from 'src/pages/contacts/create/BatchUploadContacts';
import BatchUploadExplain from 'src/pages/contacts/create/BatchUploadExplain';
import { BatchUploadVendorsExplain } from 'src/pages/contacts/create/BatchUploadVendors/BatchUploadVendorsExplain';
import { BatchUploadVendorsReview } from 'src/pages/contacts/create/BatchUploadVendors/BatchUploadVendorsReview';
import { BatchUploadVendorsSuccess } from 'src/pages/contacts/create/BatchUploadVendors/BatchUploadVendorsSuccess';
import { NewCustomerPage } from 'src/pages/contacts/create/NewCustomerPage';
import { NewVendorDeliveryMethodPageContainer } from 'src/pages/contacts/create/NewVendorDeliveryMethodPageContainer';
import { NewVendorPage } from 'src/pages/contacts/create/NewVendorPage';
import SelectContactType from 'src/pages/contacts/create/SelectContactType';
import { SelectCreateOption } from 'src/pages/contacts/create/SelectCreateOption';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getContactType } from 'src/pages/contacts/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { ContactsTab } from 'src/utils/consts';

export const CreateRouter = () => {
  const history = useHistory<{ returnLocations?: any; file?: any; origin?: any }>();
  const match = useRouteMatch<{ type: string }>();
  const orgId = useSelector(getOrgId);
  const [lastLocation] = useState(history.location?.state?.returnLocations);
  const contactType = getContactType(match?.params?.type);

  const onPrev = () => history.goBack();

  const goToContactUrl = (match, url, extra = {}, query = '', state?: Record<string, any>) => () =>
    history.push(generatePath(url, { type: contactType, orgId, ...extra }) + query, state);

  const goToContactUrlById = ({ id }) => goToContactUrl(match, contactsLocations.view, { orgId }, `?id=${id}`)();

  const goToVendorDeliveryMethods = ({ id, primaryButtonClicked, origin }) => {
    !primaryButtonClicked
      ? goToContactUrlById({ id })
      : history.push(
          generatePath(contactsLocations.create.deliveryMethods, {
            orgId,
            id,
          }),
          { origin }
        );
  };

  const goExit = () =>
    lastLocation
      ? history.push(lastLocation.pathname + lastLocation.search)
      : history.push(contactsLocations.dashboard);

  return (
    <Switch>
      <SmartRoute path={contactsLocations.create.type}>
        <SelectContactType onPrev={onPrev} onExit={goExit} />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.options}>
        <SelectCreateOption goExit={goExit} onPrev={onPrev} />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.customersSingle}>
        <NewCustomerPage goExit={goExit} onPrev={onPrev} onNext={goToContactUrlById} />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.vendorsSingle}>
        <NewVendorPage goExit={goExit} onPrev={onPrev} onNext={goToVendorDeliveryMethods} />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.deliveryMethods}>
        <NewVendorDeliveryMethodPageContainer />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.batchUploadCustomersExplain}>
        <BatchUploadExplain
          goExit={goExit}
          onPrev={onPrev}
          onNext={(file) =>
            history.push(generatePath(contactsLocations.create.batchUploadCustomersReview, { orgId }), { file })
          }
        />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.batchUploadCustomersReview}>
        <BatchUploadContacts
          type={ContactsTab.CUSTOMERS}
          file={history.location.state?.file}
          goExit={goExit}
          onPrev={onPrev}
          onNext={goToContactUrl(match, contactsLocations.index)}
        />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.batchUploadVendorsExplain}>
        <BatchUploadVendorsExplain
          goExit={goExit}
          onPrev={onPrev}
          onNext={(file) =>
            history.push(generatePath(contactsLocations.create.batchUploadVendorsReview, { orgId }), { file })
          }
        />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.batchUploadVendorsReview}>
        <BatchUploadVendorsReview
          file={history.location.state?.file}
          goExit={goExit}
          onPrev={onPrev}
          onNext={goToContactUrl(match, contactsLocations.create.batchUploadVendorsSuccess)}
        />
      </SmartRoute>
      <SmartRoute path={contactsLocations.create.batchUploadVendorsSuccess}>
        <BatchUploadVendorsSuccess />
      </SmartRoute>
    </Switch>
  );
};
