import { useState } from 'react';
import { generatePath, Redirect, useLocation, useParams } from 'react-router-dom';
import { receivingMethodLocations } from 'src/pages/receiving-method/locations';
import { LocationState } from 'src/pages/receiving-method/ReceivingMethodRouter';
import { AddBankAccountFlow } from '../AddBankAccountFlow';
import { FLOW_ENTRY_POINT } from '../consts';
import { FlowCustomizationsType } from '../types';

type Props = {
  vendorId: number;
  saveAndContinuePath: string;
  shouldEnableDMVerificationTypeSelection: boolean;
};

export const AddBankAccountFromGetPaidOnboardingWrapper = ({
  vendorId,
  saveAndContinuePath,
  shouldEnableDMVerificationTypeSelection,
}: Props) => {
  const params = useParams<{ orgId: string; vendorId: string }>();
  const { state: locationState } = useLocation<LocationState>();
  const [initialShouldEnableDMVerificationTypeSelection] = useState<boolean>(shouldEnableDMVerificationTypeSelection);
  const flowCustomizations: FlowCustomizationsType = {
    flowEntryPoint: FLOW_ENTRY_POINT.ONBOARDING,
    newAccountNotificationLabel: 'onboarding.fundingSources.bank.accountingPlatformAccounts.notification',
  };

  if (!initialShouldEnableDMVerificationTypeSelection)
    return (
      <Redirect
        to={{
          pathname: generatePath(receivingMethodLocations.ach.exists, params),
          state: locationState,
        }}
      />
    );

  return (
    <AddBankAccountFlow
      vendorId={vendorId.toString()}
      isDeliveryMethod
      flowCustomizations={flowCustomizations}
      saveAndContinuePath={saveAndContinuePath}
    />
  );
};
