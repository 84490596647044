import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { errorRedDotStyle, syncErrorPopoverStyle } from 'src/components/accounting-software/SyncNow/syncNowStyle';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from 'src/core/ds/popover';
import { useOrgId } from 'src/hooks';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { SyncNowEventsOrigin } from 'src/modules/accounting-platforms/consts';
import { settingsLocations } from 'src/pages/settings/locations';
import { analytics } from 'src/services/analytics';

export const SyncNowErrorView = () => {
  const accountingPlatformSummary = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.syncSummary);
  const orgId = useOrgId();
  const history = useHistory();
  const onErrorLinkClick = () => {
    analytics.track('top-bar', 'accountingSoftwareManualSyncErrorMessage-clicked', {
      entryPointName: SyncNowEventsOrigin.TOP_BAR,
      AccountingSoftwareType: accountingPlatformSummary.accountingPlatformName,
    });
    history.push(generatePath(settingsLocations.accountingSoftware, { orgId }));
  };

  const onPopoverOpen = () => {
    analytics.track('top-bar', 'accountingSoftwareManualSyncErrorMessage-shown', {
      entryPointName: SyncNowEventsOrigin.TOP_BAR,
      AccountingSoftwareType: accountingPlatformSummary.accountingPlatformName,
    });
  };

  return (
    <Popover trigger="hover" placement="bottom-start" onOpen={onPopoverOpen}>
      <PopoverTrigger>
        <Box __css={syncErrorPopoverStyle}>
          <IconButton testId="top-bar-sync-now-button" aria-label="Options" icon={<Icon name={IconNames.refresh} />} />
          <Box data-testid="top-bar-sync-now-button-red-dot" __css={errorRedDotStyle} />
        </Box>
      </PopoverTrigger>
      <PopoverContent bg="black" color="white">
        <PopoverBody
          backgroundColor="black"
          color="white"
          px={4}
          pt={4}
          pb={2}
          borderRadius="lg"
          cursor="default"
          data-testid="top-bar-sync-now-error-popover"
        >
          <Box mb={2} maxW="24rem" textStyle="body4">
            <MIFormattedText
              label="settings.newAccountingSoftware.syncFailurePopover"
              values={{
                accountingSoftwareName: accountingPlatformSummary.accountingPlatformName,
              }}
            />
          </Box>
          <Flex justify="end">
            <Box
              py={2}
              pr={0}
              display="inline-block"
              textStyle="body4Semi"
              _hover={{ color: '#9470FF' }}
              cursor="pointer"
              onClick={onErrorLinkClick}
              data-testid="top-bar-sync-now-button-failure-popover-link"
            >
              <MIFormattedText label="settings.newAccountingSoftware.syncFailureLink" />
            </Box>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
