import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { clientsAmountOptions } from 'src/modules/organizations/constants';
import { useInitOrganization } from 'src/modules/organizations/hooks/useInitOrganization';
import organizationStore from 'src/modules/organizations/organizations-store';
import { SelectCategory, useForm } from 'src/ui/form';
import { CompanyType } from 'src/utils/consts';

type Props = {
  onNext: (createdOrgId: number) => void;
  onExit: () => void;
  onPrev: () => void;
};

type ClientAmountForm = {
  clientsAmount: string | null | undefined;
};

const AddAccountingFirmClientsAmount = ({ onNext, onPrev, onExit }: Props) => {
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const [createdOrgId, setCreatedOrgId] = useState(null);
  const isCreating = useSelector(organizationStore.selectors.create.status())?.loading;
  const isAccountingFirmSwitching = useSelector(
    organizationStore.selectors.switchAccountingFirm.isAccountingFirmSwitching(createdOrgId)
  );
  const organizationActions = useStoreActions(organizationStore);
  const { initOrganization, isOrganizationInitializing } = useInitOrganization();

  const model = useMemo(() => ({ clientsAmount: null }), []);
  const onSubmit = async ({ clientsAmount }) => {
    let data = {
      ...newCompanyInfo,
      companyType: CompanyType.ACCOUNTING_FIRM,
    };

    if (clientsAmount) {
      data = {
        ...data,
        preferences: { onboardingBookkeeperNumOfCompanies: clientsAmount },
      };
    }

    const res = await organizationActions.create(data);
    const newAccountingFirmId = res.payload.id;
    setCreatedOrgId(newAccountingFirmId);
    await organizationActions.switchAccountingFirm({
      orgId: newAccountingFirmId,
    });
    await initOrganization(newAccountingFirmId);

    onNext(newAccountingFirmId);
  };
  const [clientsAmountMV, { submit }] = useForm<ClientAmountForm>(model, {
    submit: onSubmit,
  });

  const onSkip = () => {
    onSubmit({ clientsAmount: null });
  };

  const Footer = (
    <WizardElements.WizardButtonContainer>
      <Button label="progress.skip" variant={ButtonVariants.tertiaryNaked} onClick={onSkip} />
    </WizardElements.WizardButtonContainer>
  );

  const isLoading = isOrganizationInitializing || isCreating || isAccountingFirmSwitching;

  return (
    <StepLayoutPage
      title="addAccountingFirm.clientsAmount.title"
      titleValues={{
        companyName: (
          <Box as="span" color="grey.700">
            {newCompanyInfo.companyName}
          </Box>
        ),
      }}
      relativeStep={4 / 5}
      onNext={submit}
      isNextDisabled={!clientsAmountMV.clientsAmount.value}
      onPrev={onPrev}
      goExit={onExit}
      footer={Footer}
      isLoading={isLoading}
      nextLabel="addAccountingFirm.clientsAmount.done"
    >
      <form>
        <SelectCategory options={clientsAmountOptions} model={clientsAmountMV.clientsAmount} />
      </form>
    </StepLayoutPage>
  );
};

export default AddAccountingFirmClientsAmount;
