import { useMemo } from 'react';

const page = 'sign-in';

type ReturnValue = {
  isSessionExpired: boolean;
  screenName: string;
};

export const useSignInScreenName = (): ReturnValue => {
  const isSessionExpiredActiveTab = sessionStorage.getItem('isSessionExpiredActiveTab');
  const isSessionExpired = useMemo(() => isSessionExpiredActiveTab === 'true', [isSessionExpiredActiveTab]);
  const screenName = isSessionExpired ? `${page}-sessionExpired` : page;

  return { isSessionExpired, screenName };
};
