import {
  add,
  endOfDay,
  endOfMonth,
  formatISO,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  subDays,
  subMonths,
} from 'date-fns';
import {
  DateTableRowType,
  GetProListItemType,
  GetProPaymentType,
} from 'src/components/shared/tables/PaymentsTable/types';
import { getProInProgressPaymentsStore } from 'src/modules/get-pro-in-progress-payments/get-pro-in-progress-payments-store';
import { getProPaidPaymentsStore } from 'src/modules/get-pro-paid-payments/get-pro-paid-payments-store';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { InvoiceType } from 'src/modules/invoices/types';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import { MAP_TAB_DEFAULT_SORT } from 'src/pages/get-pro/components/table/consts';
import { GetProTabs, PaymentRequestCreateOrigin } from 'src/utils/consts';
import {
  ACCOUNTING_SOFTWARE_ORIGIN,
  InProgressDateFilters,
  InvoicesColumns,
  InvoicesDateFilters,
  InvoicesQueryActions,
  PaidDateFilters,
  RequestsDateFilters,
} from './consts';

const getAmount = (item: GetProListItemType) =>
  (item as InvoiceType)?.totalAmount !== undefined
    ? parseFloat((item as InvoiceType)?.totalAmount)
    : parseFloat((item as GetProPaymentType).amount);

export const isBetweenDates = (date: Date, startDate: Date, endDate: Date) =>
  isAfter(date, startDate) && isBefore(date, endDate);

export const mapGetProListItemToDateTableRowType = (item: GetProListItemType): DateTableRowType => ({
  id: item.id,
  customer: {
    name: (item as InvoiceType).customer?.companyName || (item as InvoiceType).customer?.contactName,
    isMelioUser: !!item?.isMelioUser,
  },
  invoiceNumber: item.invoiceNumber,
  created: item.createdAt,
  dueDate: item.dueDate ? item.dueDate : undefined,
  amount: {
    value: getAmount(item),
    status: item.status,
  },
  status: {
    deliveryDate: (item as GetProPaymentType)?.deliveryDate,
    markedAsPaidAt: (item as GetProPaymentType)?.markedAsPaidAt,
    type: item.status,
    updatedAt: item.updatedAt,
    approvalStatus: (item as InvoiceType).approvalStatus,
  },
  initiatedBy: (item as GetProPaymentType).initiatedBy,
  deliveryDate: {
    date: (item as GetProPaymentType)?.deliveryDate,
    delayReason: (item as InvoiceType)?.delayReason,
    status: item.status,
  },
  isInvoice: (item as GetProPaymentType)?.isInvoice,
  createOrigin: item.createOrigin,
});

export const MAP_STORE_BY_TAB = {
  [GetProTabs.INVOICES]: invoicesStore,
  [GetProTabs.REQUESTS]: paymentRequestsStore,
  [GetProTabs.IN_PROGRESS]: getProInProgressPaymentsStore,
  [GetProTabs.PAID]: getProPaidPaymentsStore,
};

export const mapInvoiceFilter = (filterVal: string = InvoicesDateFilters.ALL) => {
  const date = startOfDay(new Date());
  const MAP_FILTER_BY_TYPE = {
    [InvoicesDateFilters.ALL]: {},
    [InvoicesDateFilters.DUE_7_DAYS]: {
      startDate: formatISO(date),
      endDate: formatISO(add(date, { days: 7 })),
    },
    [InvoicesDateFilters.DUE_MONTH]: {
      startDate: formatISO(startOfMonth(date)),
      endDate: formatISO(endOfMonth(date)),
    },
    [InvoicesDateFilters.DUE_NEXT_MONTH]: {
      startDate: formatISO(startOfMonth(add(date, { months: 1 }))),
      endDate: formatISO(endOfMonth(add(date, { months: 1 }))),
    },
    [InvoicesDateFilters.PAST_DUE]: {
      startDate: formatISO(date),
    },
  };

  return {
    type: filterVal,
    ...MAP_FILTER_BY_TYPE[filterVal],
  };
};
export const mapRequestsFilter = (filterVal: string = RequestsDateFilters.ALL) => {
  const date = startOfDay(new Date());
  const MAP_FILTER_BY_TYPE = {
    [RequestsDateFilters.ALL]: {},
    [RequestsDateFilters.DUE_7_DAYS]: {
      startDate: formatISO(date),
      endDate: formatISO(add(date, { days: 7 })),
    },
    [RequestsDateFilters.DUE_MONTH]: {
      startDate: formatISO(startOfMonth(date)),
      endDate: formatISO(endOfMonth(date)),
    },
    [RequestsDateFilters.DUE_NEXT_MONTH]: {
      startDate: formatISO(startOfMonth(add(date, { months: 1 }))),
      endDate: formatISO(endOfMonth(add(date, { months: 1 }))),
    },
    [RequestsDateFilters.PAST_DUE]: {
      startDate: formatISO(date),
    },
  };

  return {
    type: filterVal,
    ...MAP_FILTER_BY_TYPE[filterVal],
  };
};

export const mapInProgressPaymentsFilter = (
  filterVal: string = InProgressDateFilters.ALL,
  statuses: Array<string> = []
) => {
  const date = startOfDay(new Date());
  const MAP_FILTER_BY_TYPE = {
    [InProgressDateFilters.ALL]: {},
    [InProgressDateFilters.NEXT_7_DAYS]: {
      startDate: formatISO(date),
      endDate: formatISO(add(date, { days: 7 })),
    },
    [InProgressDateFilters.THIS_MONTH]: {
      startDate: formatISO(startOfMonth(date)),
      endDate: formatISO(endOfMonth(date)),
    },
  };

  return {
    type: filterVal,
    statuses,
    ...MAP_FILTER_BY_TYPE[filterVal],
  };
};

export const mapPaidPaymentsFilter = (filterVal: string = PaidDateFilters.ALL) => {
  const date = startOfDay(new Date());
  const MAP_FILTER_BY_TYPE = {
    [PaidDateFilters.ALL]: {},
    [PaidDateFilters.TODAY]: {
      startDate: formatISO(date),
      endDate: formatISO(endOfDay(date)),
    },
    [PaidDateFilters.THIS_MONTH]: {
      startDate: formatISO(startOfMonth(date)),
      endDate: formatISO(endOfMonth(date)),
    },
    [PaidDateFilters.LAST_7_DAYS]: {
      startDate: formatISO(subDays(date, 7)),
      endDate: formatISO(endOfDay(date)),
    },
    [PaidDateFilters.LAST_MONTH]: {
      startDate: formatISO(startOfMonth(subMonths(date, 1))),
      endDate: formatISO(endOfMonth(subMonths(date, 1))),
    },
  };

  return {
    type: filterVal,
    ...MAP_FILTER_BY_TYPE[filterVal],
  };
};

const filterByCreatedLast7Days = (rows) => {
  const date = startOfDay(new Date());
  const startDate = subDays(date, 7);
  const endDate = endOfDay(date);

  return rows.filter((row) => {
    const date = new Date(row.values[InvoicesColumns.CREATED]);

    return isBetweenDates(date, startDate, endDate);
  });
};

const filterByCreatedSinceDate = (rows, date) => {
  const formattedDate = new Date(date);

  return rows.filter((row) => {
    const createdDate = new Date(row.values[InvoicesColumns.CREATED]);

    return !isBefore(createdDate, formattedDate);
  });
};

export const MAP_ACTIONS = {
  [InvoicesQueryActions.MARK_CREATED_LAST_7_DAYS]: filterByCreatedLast7Days,
  [InvoicesQueryActions.MARK_CREATED_SINCE]: filterByCreatedSinceDate,
};

export function isAccountingSoftwareItem(createOrigin: PaymentRequestCreateOrigin) {
  return ACCOUNTING_SOFTWARE_ORIGIN.includes(createOrigin);
}

export const getInitialSortBy = (tab: string, sort?: string) => {
  const { id: defaultId, desc: defaultDesc } = MAP_TAB_DEFAULT_SORT[tab];

  if (sort) {
    const [sortField, sortDir] = sort.split(':');

    return [
      {
        id: sortField || defaultId,
        desc: sortDir ? sortDir === 'desc' : defaultDesc,
      },
    ];
  }

  return [
    {
      id: defaultId,
      desc: defaultDesc,
    },
  ];
};

export const createQueryDefaultSortBy = (tab: string) => {
  const { id, desc } = MAP_TAB_DEFAULT_SORT[tab];

  return `${id}:${desc ? 'desc' : 'asc'}`;
};

export const shouldPresentNewDataAvailableNotification = (
  prevDataLastUpdated?: string,
  currentDataLastUpdated?: string
) =>
  prevDataLastUpdated &&
  currentDataLastUpdated &&
  new Date(prevDataLastUpdated).getTime() < new Date(currentDataLastUpdated).getTime();
