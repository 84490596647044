import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from '../constants';

const proInvoicesUpdateSlice = createUpdateSlice({
  storeName: name,
  actionName: 'proUpdate',
  api: getProApi.invoices.update,
});

export default proInvoicesUpdateSlice;
