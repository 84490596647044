import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { name as organizationStoreName } from 'src/modules/organizations/constants';
import { multiOrgsApi } from './api';

type PaymentRequestOrganizationAssignment = {
  loading: boolean;
  error?: Error;
  orgAssignedToPaymentRequest?: number;
};

type AssignPaymentRequestToOrgState = {
  assignPaymentRequestToOrg: PaymentRequestOrganizationAssignment;
};

type GetOrgAssignedToPaymentRequestState = {
  getOrgAssignedToPaymentRequest: PaymentRequestOrganizationAssignment;
};

const assignPaymentRequestToOrgSlice = createApiCallSlice<any, AssignPaymentRequestToOrgState>({
  name: generateSliceName(organizationStoreName, 'assign_vendor_to_user_org'),
  api: multiOrgsApi.assignPaymentRequestToOrg,
  initialState: {
    assignPaymentRequestToOrg: {
      loading: false,
      error: undefined,
      orgAssignedToPaymentRequest: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.assignPaymentRequestToOrg.loading = true;
      delete state.assignPaymentRequestToOrg.error;
    },
    [ON_SUCCESS]: (state, action) => {
      const { paymentRequestOrganizationAssignment } = action.payload;
      state.assignPaymentRequestToOrg.loading = false;
      state.assignPaymentRequestToOrg.orgAssignedToPaymentRequest = paymentRequestOrganizationAssignment.organizationId;
    },
    [ON_FAILURE]: (state, action) => {
      state.assignPaymentRequestToOrg.loading = false;
      state.assignPaymentRequestToOrg.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state[organizationStoreName].assignPaymentRequestToOrg.loading,
    error: (state) => state[organizationStoreName].assignPaymentRequestToOrg.error,
    orgAssignedToPaymentRequest: (state) =>
      state[organizationStoreName].assignPaymentRequestToOrg.orgAssignedToPaymentRequest,
  },
});

const getOrgAssignedToPaymentRequestSlice = createApiCallSlice<any, GetOrgAssignedToPaymentRequestState>({
  name: generateSliceName(organizationStoreName, 'get_org_assigned_to_vendor'),
  api: multiOrgsApi.getOrgAssignedToPaymentRequest,
  initialState: {
    getOrgAssignedToPaymentRequest: {
      loading: false,
      error: undefined,
      orgAssignedToPaymentRequest: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.getOrgAssignedToPaymentRequest.loading = true;
      delete state.getOrgAssignedToPaymentRequest.error;
    },
    [ON_SUCCESS]: (state, action) => {
      const { organizationId } = action.payload;
      state.getOrgAssignedToPaymentRequest.loading = false;
      state.getOrgAssignedToPaymentRequest.orgAssignedToPaymentRequest = organizationId;
    },
    [ON_FAILURE]: (state, action) => {
      state.getOrgAssignedToPaymentRequest.loading = false;
      state.getOrgAssignedToPaymentRequest.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state[organizationStoreName].getOrgAssignedToPaymentRequest.loading,
    error: (state) => state[organizationStoreName].getOrgAssignedToPaymentRequest.error,
    orgAssignedToPaymentRequest: (state) =>
      state[organizationStoreName].getOrgAssignedToPaymentRequest.orgAssignedToPaymentRequest,
  },
});

export { assignPaymentRequestToOrgSlice, getOrgAssignedToPaymentRequestSlice };
