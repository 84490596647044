import { useMemo } from 'react';
import { InvoiceType } from 'src/modules/invoices/types';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import { useGetProSingleItemActions } from 'src/pages/get-pro/hooks/useGetProSingleItemActions';
import { GetProTabs } from 'src/utils/consts';

type RequestActionItemType = {
  action: () => void;
  label: string;
  color?: string;
  disabledLabel?: string;
};

const disableEditAccountingSoftwareTabs = [GetProTabs.INVOICES, GetProTabs.REQUESTS];

export const useGetItemActions = (invoice: InvoiceType, tab: GetProTabs) => {
  const {
    edit,
    markAsPaid,
    viewCustomerDetails,
    cancel,
    remove,
    canViewInvoiceFile,
    openPdfPreview,
  } = useGetProSingleItemActions(invoice, tab);

  const accountingSoftwareName = useGetAccountingSoftwareName(invoice.createOrigin);

  return useMemo<RequestActionItemType[]>(
    () => [
      {
        action: edit,
        label: `getPro.table.item.menu.${tab}.edit`,
        disabledLabel:
          accountingSoftwareName && disableEditAccountingSoftwareTabs.includes(tab)
            ? 'getPro.table.item.menu.disabledMenuItemTooltip.edit'
            : undefined,
      },
      {
        action: markAsPaid,
        label: `getPro.table.item.menu.${tab}.markAsPaid`,
        disabledLabel: accountingSoftwareName ? 'getPro.table.item.menu.disabledMenuItemTooltip.markAsPaid' : undefined,
      },
      ...(canViewInvoiceFile
        ? [
            {
              action: openPdfPreview,
              label: `getPro.table.item.menu.${tab}.viewInvoice`,
            },
          ]
        : []),
      {
        action: viewCustomerDetails,
        label: `getPro.table.item.menu.${tab}.customerDetails`,
      },
      tab === GetProTabs.INVOICES
        ? {
            action: remove,
            label: `getPro.table.item.menu.${tab}.remove`,
            color: 'red.500',
          }
        : {
            action: cancel,
            label: `getPro.table.item.menu.${tab}.cancel`,
            color: 'red.500',
          },
    ],
    [accountingSoftwareName, tab]
  );
};
