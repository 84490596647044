import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

type Props = {
  onToggle(): void;
};

export const InstallmentsListCollapsed = ({ onToggle }: Props) => (
  <Flex w="full" textStyle="body4Semi" color="grey.700" data-testid="installments-list-header-collapsed">
    <Flex alignItems="center">
      <Flex cursor="pointer" onClick={onToggle} data-testid="installments-list-expand">
        <Box mr={2}>
          <MIFormattedText label="financing.installmentsList.title" />
        </Box>
        <Icon name={IconNames.shevronDown} size={IconSize.s} />
      </Flex>
    </Flex>
  </Flex>
);
