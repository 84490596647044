import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import usersStore from 'src/modules/users/users-store';
import { UserOrganizationFormType } from 'src/pages/auth/RegisterSetAmount/RegisterSetAmountContainer';
import { ApprovalWorkflowCreatorsGroup } from 'src/utils/consts';
import { callNeededApprovalActions } from './callNeededApprovalActions';

const DISABLE_NOTIFICATION = { disableNotification: true };

type Props = {
  userId?: number;
  orgId: number;
  skipSetAmount: boolean;
  replace?: boolean;
};
export const useReplaceApprovalThreshold = ({ orgId, skipSetAmount, userId, replace }: Props) => {
  const approvalWorkflowActions = useStoreActions(approvalWorkflowsStore);
  const userOrgActions = useStoreActions(usersStore);
  const matchingApprovalWorkflowId: number | undefined = useSelector(
    approvalWorkflowsStore.selectors.findMatching1X1Workflow(userId)
  )?.id;

  const replaceApprovalThreshold = useCallback(
    async (value: UserOrganizationFormType) => {
      const threshold = Number(value.approvalAmountThreshold) || 0;

      const createOrUpdateParams = {
        orgId,
        approvalDecisionsCount: 1,
        creatorsGroup: ApprovalWorkflowCreatorsGroup.SOME,
        threshold,
        paymentCreatorIds: [value.userId],
      };

      await callNeededApprovalActions({
        approvalWorkflowId: matchingApprovalWorkflowId,
        skipSetAmount,
        replace,
        actions: {
          createWorkflow: () => approvalWorkflowActions.create(createOrUpdateParams, DISABLE_NOTIFICATION),
          updateWorkflow: () =>
            approvalWorkflowActions.update(
              { ...createOrUpdateParams, id: matchingApprovalWorkflowId },
              DISABLE_NOTIFICATION
            ),
          deleteWorkflow: () =>
            approvalWorkflowActions.delete({ orgId, id: matchingApprovalWorkflowId }, DISABLE_NOTIFICATION),
          updateUserOrg: () =>
            userOrgActions.userManagementUpdateSlice(
              {
                ...value,
                approvalAmountThreshold: threshold,
                requireApproval: true,
                id: value.userId,
                orgId,
                syncApprovalWorkflows: false,
              },
              DISABLE_NOTIFICATION
            ),
        },
      });
    },
    [approvalWorkflowActions, matchingApprovalWorkflowId, orgId, replace, skipSetAmount, userOrgActions]
  );

  return { replaceApprovalThreshold };
};
