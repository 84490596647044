import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { MICard, MICardEditableField, MICardForm, MICardHeader, MICardTitle } from 'src/components/common/MICard';
import { UploadPictureLayout } from 'src/components/layout/UploadPictureLayout';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { ModelView } from 'src/ui/form';
import { UserContextType } from 'src/utils/types';
import { UploadProfilePictureContainer } from './components/UploadProfilePictureContainer';

export type ProfileDetailsViewProps = {
  profileMV: ModelView<UserContextType>;
  viewOnly?: boolean;
  editable?: boolean;
  toggleViewOnly?: () => void;
  role?: string;
  dateOfBirth?: string;
  showDateOfBirth?: boolean;
};
export function ProfileDetailsView({
  profileMV,
  viewOnly,
  toggleViewOnly,
  editable,
  role,
  dateOfBirth,
  showDateOfBirth,
}: ProfileDetailsViewProps) {
  const intl = useIntl();

  return (
    <MICard>
      <MICardForm>
        <MICardHeader>
          <MICardTitle label="profile.details.title" />
          {editable && viewOnly && (
            <Button
              onClick={toggleViewOnly}
              label="profile.details.edit"
              size={ButtonSizes.md}
              variant={ButtonVariants.tertiary}
            />
          )}
        </MICardHeader>
        <MICardEditableField>
          <PrivateDataContainer>
            <TextField
              label="profile.details.email"
              isViewOnly={viewOnly}
              model={profileMV.email}
              type="email"
              size="sm"
              mb={0}
              isRequired
              isDisabled
            />
          </PrivateDataContainer>
        </MICardEditableField>
        <MICardEditableField>
          <TextField
            label="profile.details.firstName"
            isViewOnly={viewOnly}
            model={profileMV.firstName}
            size="sm"
            mb={0}
            isRequired
          />
        </MICardEditableField>
        <MICardEditableField>
          <TextField
            label="profile.details.lastName"
            isViewOnly={viewOnly}
            model={profileMV.lastName}
            mb={0}
            size="sm"
            isRequired
          />
        </MICardEditableField>
        {role && (
          <MICardEditableField>
            <TextField
              label="profile.details.role"
              isViewOnly={viewOnly}
              value={intl.formatMessage({ id: `user.role.${role}` })}
              size="sm"
              mb={0}
              isRequired
              isDisabled
            />
          </MICardEditableField>
        )}
        {showDateOfBirth && (
          <MICardEditableField>
            <TextField label="profile.details.dateOfBirth" value={dateOfBirth} size="sm" mb={0} isViewOnly isRequired />
          </MICardEditableField>
        )}
        <Box mt={16}>
          <ProfilePictureTitle label="profile.details.picture.title" />
          <UploadPictureLayout helpTextLabel="settings.account.profilePicture.helpText">
            <UploadProfilePictureContainer
              editable={editable || !viewOnly}
              firstName={profileMV.firstName.value}
              lastName={profileMV.lastName.value}
            />
          </UploadPictureLayout>
        </Box>
      </MICardForm>
    </MICard>
  );
}

const ProfilePictureTitle = styled(MICardTitle)`
  margin-bottom: 1.6rem; ;
`;
