import moment from 'moment-timezone';
import { fetchRequest } from 'src/services/api/api';
import { PaymentApprovalStatus } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';

type GetDeliveryTimeResponse = {
  suggestedScheduledDate: Date;
  actualDeliveryDays: number;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  deliveryOptions: DeliveryOptionType[];
};

export const deliveryApi = {
  getLatestProcessingDate(
    orgId: number,
    dueDate: Date,
    deliveryMethodId: number,
    fundingSourceId: number | null,
    amount: number,
    paymentId?: number,
    payBillFlowUUID?: string,
    isFinancingPayment = false
  ): Promise<GetDeliveryTimeResponse> {
    const url = `/orgs/${orgId}/delivery/date/latest`;
    const params = {
      dueDate: this.dateInCentral(dueDate).toISOString(),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID,
      isFinancingPayment,
    };

    return fetchRequest(url, params).then((data) => {
      const { dates } = data;

      return {
        suggestedScheduledDate: dates[0].scheduledDate,
        actualDeliveryDays: dates[0].totalDays,
        deliveryDate: dates[0].deliveryDate,
        maxDeliveryDate: dates[0].maxDeliveryDate,
        deliveryOptions: dates,
      };
    });
  },

  getDeliveryTime(
    orgId: number,
    scheduledDate: Date | null,
    deliveryMethodId: number,
    fundingSourceId: number | null,
    amount: number,
    paymentId?: number,
    payBillFlowUUID?: string,
    isFinancingPayment = false
  ): Promise<GetDeliveryTimeResponse> {
    const url = `/orgs/${orgId}/delivery/date`;
    const params = {
      scheduledDate: scheduledDate ? this.dateInCentral(scheduledDate).toISOString() : null,
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID,
      isFinancingPayment,
    };

    return fetchRequest(url, params).then((data) => {
      const { dates } = data;

      return {
        suggestedScheduledDate: dates[0].scheduledDate,
        actualDeliveryDays: dates[0].totalDays,
        deliveryDate: dates[0].deliveryDate,
        maxDeliveryDate: dates[0].maxDeliveryDate,
        deliveryOptions: dates,
      };
    });
  },

  dateInCentral(date: Date) {
    const m = moment(date);

    return moment
      .tz('US/Central')
      .year(m.year())
      .month(m.month())
      .date(m.date())
      .hour(9)
      .minute(0)
      .second(0)
      .millisecond(0)
      .toDate();
  },

  getApprovalDecisionStatus(orgId: number, amount: string): Promise<{ approvalDecisionStatus: PaymentApprovalStatus }> {
    const url = `/orgs/${orgId}/delivery/approval-decision-status`;
    const params = {
      amount,
    };

    return fetchRequest(url, params);
  },

  getClosestDeliveryDates(orgId: number, deliveryMethodId: number, fundingSourceId: number, amount: number) {
    const url = `/orgs/${orgId}/delivery/closest-delivery-dates`;
    const params = {
      deliveryMethodId,
      fundingSourceId,
      amount,
    };

    return fetchRequest(url, params);
  },

  getExpeditedAchDeliveryDates(orgId: number, deliveryMethodId: number, fundingSourceId: number, amount: number) {
    const url = `/orgs/${orgId}/delivery/get-expedited-ach-delivery-dates`;
    const params = {
      deliveryMethodId,
      fundingSourceId,
      amount,
    };

    return fetchRequest(url, params);
  },

  getEffectiveScheduleDate({
    orgId,
    scheduledDate,
    fundingSourceId,
  }: {
    orgId: number;
    scheduledDate: Date;
    fundingSourceId: number;
  }) {
    const url = `/orgs/${orgId}/delivery/effective-schedule-date`;
    const params = {
      scheduledDate,
      fundingSourceId,
    };

    return fetchRequest(url, params);
  },
};
