import { useDispatch } from 'react-redux';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { useFundingSourcesNavigate } from 'src/pages/bill/pay/hooks/useFundingSourcesNavigate';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { AddBankAccountFlow } from '../AddBankAccountFlow';
import { FLOW_ENTRY_POINT } from '../consts';
import { FlowCustomizationsType } from '../types';

type Props = {
  nextStepURL: string;
};

export const AddBankAccountFromPayBillWrapper = ({ nextStepURL }: Props) => {
  const dispatch = useDispatch();
  const { navigate } = useNavigator();
  const { getUrlAfterFundingSource } = useFundingSourcesNavigate({ navigate, nextStepURL });
  const saveAndContinuePath = getUrlAfterFundingSource();

  const onFSBankAccountAdded = (newFundingSourceId) => {
    dispatch(selectFundingSourceAction(newFundingSourceId));
  };
  const flowCustomizations: FlowCustomizationsType = {
    flowEntryPoint: FLOW_ENTRY_POINT.PAY_BILL,
    onFSBankAccountAdded,
    newAccountNotificationLabel: 'onboarding.fundingSources.bank.accountingPlatformAccounts.paymentNotification',
  };

  return <AddBankAccountFlow flowCustomizations={flowCustomizations} saveAndContinuePath={saveAndContinuePath} />;
};
