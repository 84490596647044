import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/consts';

type ReturnType = {
  isMicroDepositOptimizationsEnabled: boolean;
};

export const useMicroDepositOptimizationsEnabled = (): ReturnType => {
  const [isMDOptimizationsEnabled] = featureFlags.useFeature(FeatureFlags.MicroDepositOptimizations, false);

  return {
    isMicroDepositOptimizationsEnabled: !!isMDOptimizationsEnabled,
  };
};
