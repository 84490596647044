import { billsApi } from 'src/modules/bills/api';
import { isBusinessDay } from 'src/utils/dates';
import moment from 'src/utils/moment-business-days';

export const getBillAttachments = async (billId, orgId) => {
  let result;

  if (billId) {
    try {
      result = await billsApi.getBillAttachments({
        orgId,
        billId,
      });

      return result?.attachments.map((a) => ({
        name: a.FileName,
        url: a.TempDownloadUri,
      }));
    } catch {
      return null;
    }
  }

  return null;
};

export const getClosestBusinessDay = (date = moment()) => {
  if (isBusinessDay(date)) {
    return date;
  }

  return moment(date).nextBusinessDay();
};
