import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';

type Props = {
  label: string;
};

export const ManualAddressSubtitle: React.FC<Props> = ({ label }) => (
  <WizardElements.WizardFormRow>
    <WizardElements.WizardStepSubTitle>
      <MIFormattedText label={label} />
    </WizardElements.WizardStepSubTitle>
  </WizardElements.WizardFormRow>
);
