import { useEffect } from 'react';
import styled from 'styled-components';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IconNames } from 'src/core/ds/icon';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';

export const MicroDepositsBlockedPage = () => {
  const site = useSiteContext();
  useEffect(() => {
    analytics.page('funding-sources', 'micro-payments-blocked');
  }, []);

  return (
    <SuccessLayoutPage
      hideHeader
      icon={IconNames.cancelFill}
      title="settings.microDeposits.verifyDialogLimitReached.title"
      text="settings.microDeposits.verifyDialogLimitReached.subtitleWithoutChat"
      textValues={{
        supportEmail: (
          <SupportLink href={`mailto:${site.config.support.email}`}>{site.config.support.email}</SupportLink>
        ),
        br: <br />,
      }}
      buttonLabel="fundingSources.microDeposits.chatWithSupport"
      buttonValues={{
        icon: <SupportIcon />,
      }}
      buttonAction={() => {
        intercomService.show();
      }}
    />
  );
};

const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const SupportIcon = styled.i.attrs({ className: 'icon-support-icon' })`
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 2.4rem;
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1rem;
  vertical-align: middle;
`;
