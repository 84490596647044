import { ReactNode } from 'react';
import Box from 'src/core/ds/box';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  children: ReactNode;
};

export const PrivateDataContainer = ({ children }: Props) => (
  <Box w="full" className={`input-wrapper ${FULL_STORY_MASK_RULE_CLASS}`} data-private>
    {children}
  </Box>
);
