import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

type Props = {
  handleUploadCancel: () => void;
  goManual: () => void;
};

const UploadFileError = (props: Props) => (
  <Flex w="full" h="full" align="center" justify="center" textStyle="body2">
    <Flex align="center" justify="center" direction="column" w="50%" maxW="40rem" textAlign="center" color="red.500">
      <Flex
        align="center"
        justify="center"
        w="11rem"
        h="11rem"
        borderRadius="full"
        border="2px"
        borderColor="red.500"
        mb={8}
      >
        <Box as="i" textStyle="display2Semi" className="icon-notification-icon" />
      </Flex>
      <Box mb={8}>
        <MIFormattedText label="bills.new.errorProcessingFile" /> <br />
        <MIFormattedText label="bills.new.errorProcessingFileAction" />
      </Box>
      <Button
        variant={ButtonVariants.secondary}
        size={ButtonSizes.lg}
        label="bills.new.retry"
        onClick={props.handleUploadCancel}
      />
      <Button
        variant={ButtonVariants.tertiaryNaked}
        size={ButtonSizes.lg}
        label="bills.new.enterManually"
        onClick={props.goManual}
      />
    </Flex>
  </Flex>
);

export default UploadFileError;
