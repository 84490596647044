import { generatePath, Redirect } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { useAccountingFirmSkipsStep } from 'src/hooks/accountants/useAccountingFirmSkipsStep';
import { companiesLocations } from 'src/pages/companies/locations';
import CompanyManualAddressPageContainer from 'src/pages/onboarding/company-info/CompanyManualAddressPageContainer';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { withCompanyInfo } from '../company-info/hoc/withCompanyInfoUpdates';
import { VendorCompanyAbsorbedFeeTypeSelection } from './VendorCompanyAbsorbedFeeTypeSelection';
import { VendorCompanyAddedPageContainer } from './VendorCompanyAddedPageContainer';
import { VendorCompanyAddressPageContainer } from './VendorCompanyAddressPageContainer';
import { VendorCompanyBusinessTypePageContainer } from './VendorCompanyBusinessTypePageContainer';
import { VendorCompanyContactNamePageContainer } from './VendorCompanyContactNamePageContainer';
import { VendorCompanyIndustryPageContainer } from './VendorCompanyIndustryPageContainer';
import { VendorCompanyInfoIndex } from './VendorCompanyInfoIndex';
import { VendorCompanyLegalInfoPageContainer } from './VendorCompanyLegalInfoPageContainer';
import { VendorCompanyLinkContainer as VendorCompanyLinkPageContainer } from './VendorCompanyLinkPageContainer';
import { VendorCompanyLogoPageContainer } from './VendorCompanyLogoPageContainer';
import { VendorCompanyNamePageContainer } from './VendorCompanyNamePageContainer';
import { CompanyPhonePageContainer as VendorCompanyPhonePageContainer } from './VendorCompanyPhonePageContainer';
import { VendorCompanyRouteWrapper } from './VendorCompanyRouteWrapper';

const eventPage = 'onboarding-vendor-company-info';

const StepOrRedirectAccountingFirm = ({
  Component,
  redirectUrl,
}: {
  Component: () => JSX.Element;
  redirectUrl: string;
}) => {
  const orgId = useOrgId();
  const shouldSkip = useAccountingFirmSkipsStep();

  if (shouldSkip) {
    return <Redirect to={generatePath(redirectUrl, { orgId })} />;
  }

  return <Component />;
};

export const vendorCompanyInfoRoutes = [
  {
    path: onboardingLocations.vendorCompanyInfo.index,
    exact: true,
    component: VendorCompanyInfoIndex,
  },
  {
    path: onboardingLocations.vendorCompanyInfo.name,
    exact: true,
    component: (props) => (
      <VendorCompanyRouteWrapper>
        <VendorCompanyNamePageContainer
          {...props}
          companyInfoField="companyName"
          nextStepURL={onboardingLocations.vendorCompanyInfo.address}
          inputFields={['companyName']}
        />
      </VendorCompanyRouteWrapper>
    ),
  },
  {
    path: onboardingLocations.vendorCompanyInfo.address,
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'address');

      return (
        <VendorCompanyRouteWrapper>
          <VendorCompanyAddressPageContainer
            {...props}
            companyInfoField="address"
            prevStepURL={onboardingLocations.vendorCompanyInfo.name}
            nextStepURL={onboardingLocations.vendorCompanyInfo.industry}
            inputFields={['googlePlaceId', 'zipCode']}
          />
        </VendorCompanyRouteWrapper>
      );
    },
  },
  {
    path: onboardingLocations.vendorCompanyInfo.addressManual,
    exact: true,
    component: withCompanyInfo()(({ companyType, organizationPreferences, ...props }) => (
      <CompanyManualAddressPageContainer
        {...props}
        companyInfoField="address"
        prevStepURL={onboardingLocations.vendorCompanyInfo.address}
        nextStepURL={onboardingLocations.vendorCompanyInfo.industry}
        inputFields={['addressLine1', 'city', 'state', 'zipCode']}
      />
    )),
  },
  {
    path: onboardingLocations.vendorCompanyInfo.industry,
    exact: true,
    component: (props) => (
      <VendorCompanyRouteWrapper>
        <VendorCompanyIndustryPageContainer
          prevStepURL={onboardingLocations.vendorCompanyInfo.address}
          nextStepURL={onboardingLocations.vendorCompanyInfo.contactName}
          {...props}
        />
      </VendorCompanyRouteWrapper>
    ),
  },
  {
    path: onboardingLocations.vendorCompanyInfo.phone,
    exact: true,
    component: (props) => (
      <VendorCompanyRouteWrapper>
        <VendorCompanyPhonePageContainer
          {...props}
          companyInfoField="phone"
          prevStepURL={onboardingLocations.vendorCompanyInfo.address}
          nextStepURL={onboardingLocations.vendorCompanyInfo.contactName}
          inputFields={['phone']}
        />
      </VendorCompanyRouteWrapper>
    ),
  },
  {
    path: onboardingLocations.vendorCompanyInfo.contactName,
    exact: true,
    component: (props) => (
      <VendorCompanyRouteWrapper>
        <VendorCompanyContactNamePageContainer
          {...props}
          companyInfoField="contactName"
          prevStepURL={onboardingLocations.vendorCompanyInfo.industry}
          nextStepURL={onboardingLocations.vendorCompanyInfo.link}
          inputFields={['contactFirstName', 'contactLastName', 'phone']}
        />
      </VendorCompanyRouteWrapper>
    ),
  },
  {
    path: onboardingLocations.vendorCompanyInfo.link,
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'handle');

      return (
        <VendorCompanyRouteWrapper>
          <VendorCompanyLinkPageContainer
            {...props}
            companyInfoField="handle"
            prevStepURL={onboardingLocations.vendorCompanyInfo.contactName}
            nextStepURL={onboardingLocations.vendorCompanyInfo.logo}
          />
        </VendorCompanyRouteWrapper>
      );
    },
  },
  {
    path: onboardingLocations.vendorCompanyInfo.logo,
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'logo');

      return (
        <VendorCompanyRouteWrapper>
          <VendorCompanyLogoPageContainer {...props} prevStepURL={onboardingLocations.vendorCompanyInfo.link} />
        </VendorCompanyRouteWrapper>
      );
    },
  },
  {
    path: onboardingLocations.vendorCompanyInfo.legal,
    exact: true,
    component: (props) => (
      <VendorCompanyRouteWrapper>
        <VendorCompanyLegalInfoPageContainer
          {...props}
          companyInfoField="legalCompanyDetails"
          nextStepURL={onboardingLocations.vendorCompanyInfo.businessType}
          prevStepURL={onboardingLocations.vendorCompanyInfo.logo}
          inputFields={['legalCompanyName', 'businessType', 'taxId', 'taxIdType', 'legalGooglePlaceId', 'legalZipCode']}
        />
      </VendorCompanyRouteWrapper>
    ),
  },
  {
    path: onboardingLocations.vendorCompanyInfo.businessType,
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'business-type');

      return (
        <VendorCompanyRouteWrapper>
          <VendorCompanyBusinessTypePageContainer {...props} />
        </VendorCompanyRouteWrapper>
      );
    },
  },
  {
    path: onboardingLocations.vendorCompanyInfo.absorbFees,
    exact: true,
    component: (props) => (
      <VendorCompanyRouteWrapper>
        <StepOrRedirectAccountingFirm
          redirectUrl={companiesLocations.index}
          Component={() => {
            analytics.page(eventPage, 'absorb-fee');

            return <VendorCompanyAbsorbedFeeTypeSelection {...props} />;
          }}
        />
      </VendorCompanyRouteWrapper>
    ),
  },
  {
    path: onboardingLocations.vendorCompanyInfo.added,
    exact: true,
    component: (props) => (
      <VendorCompanyRouteWrapper>
        <StepOrRedirectAccountingFirm
          redirectUrl={companiesLocations.index}
          Component={() => {
            analytics.page(eventPage, 'added');

            return <VendorCompanyAddedPageContainer {...props} />;
          }}
        />
      </VendorCompanyRouteWrapper>
    ),
  },
];
