import React from 'react';
import Box from 'src/core/ds/box';
import { Card } from 'src/core/ds/card';
import Flex from 'src/core/ds/flex/Flex';
import { Separator } from 'src/core/ds/separator';
import { Collapse } from 'src/core/ds/transition';
import { InstallmentPlanDetails } from 'src/pages/bill/pay/installments/pages/installments-options/installment/InstallmentPlanDetails';
import { NumberOfInstallments } from 'src/pages/bill/pay/installments/pages/installments-options/installment/NumberOfInstallments';
import { FinancingRepaymentTermsDateComponent } from 'src/pages/bill/pay/installments/pages/repayment-terms/FinancingRepaymentTermsDateComponent';
import { RepaymentTermsFundingSource } from 'src/pages/bill/pay/installments/pages/repayment-terms/RepaymentTermsFundingSource';
import { InstallmentOption } from 'src/pages/bill/pay/installments/types';

type Props = {
  option: InstallmentOption;
  onInstallmentClick: () => void;
  isSelected: boolean;
  onDeliveryDateChange: (Date) => void;
  isInstallmentDatesLoading: boolean;
  isDisabled: boolean;
};

export const InstallmentOptionWithRepayment = ({
  option,
  onInstallmentClick,
  isSelected,
  onDeliveryDateChange,
  isInstallmentDatesLoading,
  isDisabled,
}: Props) => (
  <Box
    mb={4}
    backgroundColor="white"
    borderRadius="lg"
    opacity={!isSelected && isDisabled ? 0.5 : 1}
    data-testid="installment-option"
    aria-disabled={isDisabled}
  >
    <Card id="installment-card" testId="installment-card" selectedColor="primary.500" isSelected={isSelected}>
      <Flex
        justifyContent="space-between"
        height={16}
        onClick={onInstallmentClick}
        cursor={isSelected || isDisabled ? 'auto' : 'pointer'}
      >
        <NumberOfInstallments numberOfInstallments={option.numberOfInstallments} />
        <InstallmentPlanDetails
          amountPerMonth={option.installmentAmount}
          totalFees={option.feeAmount}
          totalFeesPercent={option.feePercent}
          paymentAmount={option.paymentAmount}
        />
      </Flex>
      <Collapse unmountOnExit in={isSelected} css={{ overflow: 'unset !important' }}>
        <Flex direction="column" textStyle="body2Semi" textColor="grey.600">
          <Separator mt={6} mb={6} />
          <Flex direction="column">
            <FinancingRepaymentTermsDateComponent
              isInstallmentDatesLoading={isInstallmentDatesLoading}
              onDateChange={onDeliveryDateChange}
              isDisabled={isDisabled}
            />
            <Separator mt={6} mb={6} />
            <RepaymentTermsFundingSource isDisabled={isDisabled} />
          </Flex>
        </Flex>
      </Collapse>
    </Card>
  </Box>
);
