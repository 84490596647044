import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { UseModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { BatchAnalyticsPropsType } from '../../analytics/BatchPaymentsAnalytics';

const eventPage = 'regular-batch-payments';

type Props = {
  vendorId: string;
  analyticsProps: BatchAnalyticsPropsType;
};

export const AddVendorEmailModal: React.FC<UseModal<Props>> = ({ dismiss, vendorId, analyticsProps }) => {
  const orgId = useSelector(getOrgId);
  const [vendorEmail, setVendorEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const vendorActions = useStoreActions(vendorsStore);

  const saveVendorEmail = async () => {
    const validationErrors = getValidationErrors('vendor', { contactEmail: vendorEmail }, ['contactEmail']);
    setValidationErrors(validationErrors);
    analytics.track(eventPage, 'add-email-for-bulk-reconciliation-save', analyticsProps);

    if (isValidationOk(validationErrors)) {
      setIsLoading(true);

      try {
        await vendorActions.update({
          orgId,
          id: vendorId,
          contactEmail: vendorEmail,
        });

        analytics.track(eventPage, 'add-email-for-bulk-reconciliation-Succeeded', analyticsProps);
        setIsLoading(false);

        dismiss();
      } catch (e) {
        setIsLoading(false);
        analytics.track(eventPage, 'add-email-for-bulk-reconciliation-failed', analyticsProps);
      }
    } else {
      setIsLoading(false);
      analytics.track(eventPage, 'vendor-email-modal-save-validation-error', {
        ...validationErrors,
        ...analyticsProps,
      });
    }
  };

  const cancel = () => {
    analytics.track(eventPage, 'add-email-for-bulk-reconciliation-cancel', analyticsProps);
    dismiss();
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => setVendorEmail(event.currentTarget.value);

  return (
    <ModalMessage
      id="add-vendor-email"
      alignLeft
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText label="regularBatchPayments.cells.vendor.modal.title" />
        </Box>
      }
      contentComponent={
        <Box>
          <Box mb={10} textStyle="body3">
            <MIFormattedText label="regularBatchPayments.cells.vendor.modal.subtitle" />
          </Box>
          <TextField
            id="email"
            value={vendorEmail}
            label="regularBatchPayments.cells.vendor.modal.textFieldTitle"
            placeholder="regularBatchPayments.cells.vendor.modal.placeholder"
            size="sm"
            type="email"
            onChange={handleEmailChange}
            errorMessage={validationErrors.contactEmail}
            isRequired
            autoFocus
          />
        </Box>
      }
      buttonComponent={
        <Flex justify="end" mt="3">
          <Button
            onClick={cancel}
            label="regularBatchPayments.cells.vendor.modal.cancelButton"
            variant={ButtonVariants.tertiaryNaked}
          />
          <Button
            onClick={saveVendorEmail}
            label="regularBatchPayments.cells.vendor.modal.saveButton"
            variant={ButtonVariants.primary}
            isLoading={isLoading}
            isDisabled={isEmpty(vendorEmail)}
          />
        </Flex>
      }
      onCloseClick={cancel}
      isLoading={isLoading}
    />
  );
};
