const baseUrl = '/orgs/:orgId/sync-qbd';

export const syncQBDLocations = {
  base: [baseUrl],
  entry: `${baseUrl}/entry`,
  licenseKey: `${baseUrl}/license-key`,
  open: `${baseUrl}/open`,
  region: `${baseUrl}/select-region`,
  access: `${baseUrl}/access`,
  invoiceGeneration: `${baseUrl}/invoice-generation`,
  inProgress: `${baseUrl}/in-progress`,
  success: `${baseUrl}/success`,
  mobile: `${baseUrl}/mobile`,
  disabled: `${baseUrl}/disabled`,
};
