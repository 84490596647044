import { BillType, DeliveryMethodType, PaymentType, RecurringBillType } from 'src/utils/types';
import {
  ADD_NEW_DELIVERY_METHOD,
  BEGIN_RECURRING_PAY_BILL_FLOW,
  BEGIN_RECURRING_PAY_BILL_FLOW_FAILED,
  BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS,
  BEGIN_REGULAR_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
  BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  CANCEL_AND_RETRY_PAYMENT,
  CANCEL_AND_RETRY_PAYMENT_ERROR,
  CANCEL_AND_RETRY_PAYMENT_SUCCESS,
  CREATE_BILL_AND_PAYMENT_FROM_PAYMENT_REQUEST,
  CREATE_FINANCING_PAYMENT,
  CREATE_INTERNATIONAL_PAYMENT,
  CREATE_PAYMENT,
  CREATE_PAYMENT_ERROR,
  CREATE_PAYMENT_SUCCESS,
  CREATE_RECURRING_BILL,
  CREATE_RECURRING_BILL_ERROR,
  CREATE_RECURRING_BILL_SUCCESS,
  END_PAY_BILL_FLOW,
  FETCH_BILL,
  FETCH_BILL_FAILED,
  FETCH_BILL_SUCCESS,
  RESCHEDULE_PAYMENT,
  RESET_ERROR,
  RETRY_FAILED_PAYMENT_ERROR,
  RETRY_FAILED_PAYMENT_SUCCESS,
  RETRY_FAILED_TO_COLLECT_PAYMENT,
  RETRY_FAILED_TO_DELIVER_PAYMENT,
  SELECT_DELIVERY_METHOD,
  SELECT_FUNDING_SOURCE,
  SELECT_NEW_DELIVERY_METHOD,
  SELECT_PAYMENT_DATES,
  SELECT_REPAYMENT_METHOD,
  SET_BILL_ID,
  SET_FEE,
  SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW,
  SET_PAYMENT_AMOUNT,
  SET_PAYMENT_PURPOSE,
  SET_URL_TO_BACK,
  UPDATE_BILL,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_ERROR,
  UPDATE_PAYMENT_MEMO,
  UPDATE_PAYMENT_SUCCESS,
} from './actionTypes';
import {
  BeginRecurringPayBillFlowType,
  BeginRegularPayBillFlowType,
  BillIdType,
  CreatePaymentType,
  CreateRecurringBillType,
  PaymentFeeInfo,
  SelectNewDeliveryMethodType,
  SetUrlToBackType,
  UpdatePaymentType,
} from './types';

export const beginRegularPayBillFlowSuccessAction = (bill: BillType, payment: PaymentType) => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  bill,
  payment,
});

export const beginRegularPayBillFlowFailedAction = () => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
});

export const beginRegularPayBillFlowAction = (
  id: string,
  paymentId: string,
  redirectUrl?: string,
  exitUrl?: string
): BeginRegularPayBillFlowType => ({
  type: BEGIN_REGULAR_PAY_BILL_FLOW,
  id,
  paymentId,
  redirectUrl,
  exitUrl,
});

export const beginRecurringPayBillFlowAction = (
  bill: BillType,
  recurringBill: RecurringBillType,
  scannedInvoiceId?: number
): BeginRecurringPayBillFlowType => ({
  type: BEGIN_RECURRING_PAY_BILL_FLOW,
  bill,
  recurringBill,
  scannedInvoiceId,
});

export const beginRecurringPayBillFlowSuccessAction = ({
  bill,
  payment,
  recurringBill,
  scannedInvoiceId,
}: {
  bill: BillType;
  payment: PaymentType;
  recurringBill: RecurringBillType;
  scannedInvoiceId?: number;
}) => ({
  type: BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS,
  bill,
  payment,
  recurringBill,
  scannedInvoiceId,
});

export const beginRecurringPayBillFlowFailedAction = () => ({
  type: BEGIN_RECURRING_PAY_BILL_FLOW_FAILED,
});

export const endPayBillFlowAction = (isCloseEvent?: boolean, preventCloseIframe?: boolean) => ({
  type: END_PAY_BILL_FLOW,
  isCloseEvent,
  preventCloseIframe,
});

export const selectFundingSourceAction = (id?: number | null) => ({
  type: SELECT_FUNDING_SOURCE,
  id,
});

export const selectRepaymentAction = (id?: number | null) => ({
  type: SELECT_REPAYMENT_METHOD,
  id,
});

export const setPaymentAmountAction = (amount?: number | null) => ({
  type: SET_PAYMENT_AMOUNT,
  amount,
});

export const setPaymentPurposeAction = (purpose?: string) => ({
  type: SET_PAYMENT_PURPOSE,
  purpose,
});

export const selectPaymentDatesAction = (
  scheduledDate: Date,
  deliveryEta: Date,
  maxDeliveryEta: Date,
  deliveryPreference?: string
) => ({
  type: SELECT_PAYMENT_DATES,
  scheduledDate,
  deliveryEta,
  maxDeliveryEta,
  deliveryPreference,
});

export const setFeeAction = (payload: PaymentFeeInfo) => ({
  type: SET_FEE,
  payload,
});

export const setBillIdAction = (billId: BillIdType) => ({
  type: SET_BILL_ID,
  billId,
});

export const selectDeliveryMethodAction = (deliveryMethod?: DeliveryMethodType) => ({
  type: SELECT_DELIVERY_METHOD,
  deliveryMethod,
});

export const addNewDeliveryMethodAction = (deliveryMethod: DeliveryMethodType) => ({
  type: ADD_NEW_DELIVERY_METHOD,
  deliveryMethod,
});

export const selectNewDeliveryMethodAction = (deliveryMethod: DeliveryMethodType): SelectNewDeliveryMethodType => ({
  type: SELECT_NEW_DELIVERY_METHOD,
  deliveryMethod,
});

export const updatePaymentMemoAction = (memo: string) => ({
  type: UPDATE_PAYMENT_MEMO,
  memo,
});

export const updateBillAction = (goodsReceived: boolean) => ({
  type: UPDATE_BILL,
  goodsReceived,
});

export const fetchBillAction = (id: string) => ({
  type: FETCH_BILL,
  id,
});

export const fetchBillSuccessAction = (bill: BillType) => ({
  type: FETCH_BILL_SUCCESS,
  bill,
});

export const fetchBillFailedAction = () => ({
  type: FETCH_BILL_FAILED,
});

export const createRecurringBillSuccessAction = (firstBillIdWithRecurringBill: string, payment: PaymentType) => ({
  type: CREATE_RECURRING_BILL_SUCCESS,
  firstBillIdWithRecurringBill,
  payment,
});

export const createRecurringBillErrorAction = (errorCode?: string | null) => ({
  type: CREATE_RECURRING_BILL_ERROR,
  errorCode,
});

export const createRecurringBillAction = (
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: Error) => void
): CreateRecurringBillType => ({
  type: CREATE_RECURRING_BILL,
  resolve,
  reject,
});

export const createPaymentSuccessAction = (payment: PaymentType) => ({
  type: CREATE_PAYMENT_SUCCESS,
  payment,
});
export const createPaymentErrorAction = (errorCode?: string | null) => ({
  type: CREATE_PAYMENT_ERROR,
  errorCode,
});

export const createPaymentAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: CREATE_PAYMENT,
  resolve,
  reject,
});

export const updatePaymentSuccessAction = (payment: PaymentType) => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payment,
});

export const updatePaymentErrorAction = (errorCode?: string | null) => ({
  type: UPDATE_PAYMENT_ERROR,
  errorCode,
});

export const updatePaymentAction = (
  resolve: (value: void | PromiseLike<void>) => void,
  reject: (error: Error) => void
): UpdatePaymentType => ({
  type: UPDATE_PAYMENT,
  resolve,
  reject,
});

export const retryFailedPaymentSuccessAction = (payment: PaymentType) => ({
  type: RETRY_FAILED_PAYMENT_SUCCESS,
  payment,
});

export const retryFailedPaymentErrorAction = (errorCode?: string | null) => ({
  type: RETRY_FAILED_PAYMENT_ERROR,
  errorCode,
});

export const reschedulePaymentAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: RESCHEDULE_PAYMENT,
  resolve,
  reject,
});

export const retryFailedToDeliverPaymentAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: RETRY_FAILED_TO_DELIVER_PAYMENT,
  resolve,
  reject,
});

export const retryFailedToCollectPaymentAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: RETRY_FAILED_TO_COLLECT_PAYMENT,
  resolve,
  reject,
});

export const cancelAndRetryPaymentSuccessAction = (payment: PaymentType) => ({
  type: CANCEL_AND_RETRY_PAYMENT_SUCCESS,
  payment,
});

export const cancelAndRetryPaymentErrorAction = (errorCode?: string | null) => ({
  type: CANCEL_AND_RETRY_PAYMENT_ERROR,
  errorCode,
});

export const cancelAndRetryPaymentAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: CANCEL_AND_RETRY_PAYMENT,
  resolve,
  reject,
});

export const setIsCancelAndRetryFlowAction = (payload: boolean) => ({
  type: SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW,
  payload,
});

export const resetErrorAction = () => ({
  type: RESET_ERROR,
});

export const setUrlToBackAction = (urlToBack: string): SetUrlToBackType => ({
  type: SET_URL_TO_BACK,
  urlToBack,
});

export const createBillAndPaymentFromPaymentRequestAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: CREATE_BILL_AND_PAYMENT_FROM_PAYMENT_REQUEST,
  resolve,
  reject,
});

export const createInternationalPaymentAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: CREATE_INTERNATIONAL_PAYMENT,
  resolve,
  reject,
});
export const createFinancingPaymentAction = (
  resolve: (value: PaymentType | PromiseLike<PaymentType>) => void,
  reject: (error: Error) => void
): CreatePaymentType => ({
  type: CREATE_FINANCING_PAYMENT,
  resolve,
  reject,
});
