import size from 'lodash/size';
import { ParseResult } from 'papaparse';
import { useCallback, useState } from 'react';
import { loadCSVFile } from 'src/utils/csv';
import { useAsyncAction } from './useAsyncAction';

export function useLoadCsv(
  file: File | null
): [Record<string, string>[] | undefined, boolean, boolean, string[] | undefined] {
  const [fileContent, setFileContent] = useState<ParseResult<Record<string, string>>>();

  const loadFile = useCallback(async () => {
    if (!file) {
      throw new Error('No file');
    }

    const loadedFile = await loadCSVFile<Record<string, string>>({ header: true }, file);

    if (size(loadedFile.data) === 0) {
      throw new Error('no data');
    }

    setFileContent(loadedFile);
  }, [file]);
  const [loading, error] = useAsyncAction(loadFile);

  return [fileContent?.data, loading, error, fileContent?.meta?.fields];
}
