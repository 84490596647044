import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from '../constants';

const proInvoicesSendSlice = createUpdateSlice({
  storeName: name,
  api: getProApi.invoices.send,
  actionName: 'proSend',
});

export default proInvoicesSendSlice;
