import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MICompanyInitialsCircle } from 'src/components/common/MICompanyInitialsCircle/MICompanyInitialsCircle';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import { profileStore } from 'src/modules/profile/profile-store';
import { CompanyType } from 'src/utils/consts';

type Props = {
  onNext: () => void;
  onPrev: () => void;
};

export const QuickbookMultiOrgOnboardingCompaniesList = ({ onNext, onPrev }: Props) => {
  const intuitOrganizations = (useSelector(profileStore.selectors.getOrganizations) || []).filter(
    (org) => org.isIntuitLinked
  );
  const organizations = sortBy(intuitOrganizations, [
    (org) => (org.companyType === CompanyType.ACCOUNTING_FIRM ? 0 : 1),
  ]);

  const submitAction = async () => {
    onNext();
  };

  return (
    <StepLayoutPage
      onPrev={onPrev}
      title="multiOrgOnboarding.companiesList.title"
      subtitle="multiOrgOnboarding.companiesList.subtitle"
      nextLabel="multiOrgOnboarding.companiesList.done"
      relativeStep={2 / 3}
      onNext={submitAction}
    >
      <ListContainer>
        {organizations.map((organization) => (
          <Company key={organization.id}>
            <MICompanyInitialsCircle organization={organization} />
            <CompanyNameContainer>{organization.companyName}</CompanyNameContainer>
            {organization.companyType !== CompanyType.ACCOUNTING_FIRM && (
              <Badge label="companies.accountingFirm" color={BadgeColors.PRIMARY_LIGHT} />
            )}
          </Company>
        ))}
      </ListContainer>
    </StepLayoutPage>
  );
};

const Company = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0;
  padding: 2.5rem 2.5rem 2.5rem 3.2rem;
  border-bottom: 1px solid ${(props) => props.theme.text.color.readonly};
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  ${(props) => props.theme.text.fontType.regular};

  &:last-of-type {
    border-bottom: none;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.text.color.readonly};
  border-radius: 0.8rem;
  width: 100%;
  overflow-y: auto;
  margin: 2.5rem 0 4rem 0;
`;

const CompanyNameContainer = styled.div`
  margin: 0 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;
