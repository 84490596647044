import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { removeIdsFromState } from 'src/helpers/redux/listsActions';
import { InvoiceType } from 'src/modules/invoices/types';
import { invoicesApi } from './api';
import { name } from './constants';

type State = {
  byId: {
    [key: string]: InvoiceType;
  };
  lists: {
    [key: string]: {
      order: string[];
    };
  };
  batchDelete: {
    loading: boolean;
    error?: Error;
  };
};

const batchDeleteShare = createApiCallSlice<any, State>({
  name: generateSliceName(name, 'batch_delete'),
  api: invoicesApi.batchDelete,
  initialState: {
    byId: {},
    lists: {},
    batchDelete: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.batchDelete.loading = true;
    },
    [ON_SUCCESS]: (state, action) => {
      state.batchDelete.loading = false;
      const { ids } = action.payload;
      removeIdsFromState(state, ids);
    },
    [ON_FAILURE]: (state, action) => {
      state.batchDelete.loading = false;
      state.batchDelete.error = action.error;
    },
  },
  selectors: {
    status: (state) => state[name]?.batchDelete,
    loading: (state) => state[name].batchDelete.loading,
    error: (state) => state[name].batchDelete.error,
  },
});

export default batchDeleteShare;
