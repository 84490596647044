import * as React from 'react';
import { MutableRefObject } from 'react';
import { compose } from 'recompose';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Input } from 'src/core/ds/input';
import { withBreak } from 'src/hoc';
import { analytics } from 'src/services/analytics';

type Props = {
  id: string;
  value: string | number | null;
  placeholder?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  eventPage: string;
  onFocus: () => void;
  onBlur: () => void;
  onChange: (value: { id: string; value: string }) => void;
  onClear: () => void;
  onSubmit: () => void;
  device: { isMobile: boolean };
  inputRef?: MutableRefObject<HTMLInputElement>;
};

class MISearchBar extends React.PureComponent<Props> {
  static defaultProps = {
    disabled: false,
    placeholder: '',
    autoFocus: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onFocus: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onBlur: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClear: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {},
    testId: '',
  };

  constructor(props: Props) {
    super(props);

    this.searchInputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { value, onSubmit, inputRef } = this.props;

    if (prevProps.value !== value && !value) {
      onSubmit();
    }

    if (inputRef && this.searchInputRef.current) {
      inputRef.current = this.searchInputRef.current;
    }
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { disabled, onChange, id } = this.props;

    if (!disabled && onChange) {
      onChange({ id, value: e.currentTarget.value });
    }
  };

  handleSubmit = (event: any) => {
    const { onSubmit, device, eventPage, value } = this.props;

    event.preventDefault();

    if (device.isMobile && this.searchInputRef.current) {
      this.searchInputRef.current.blur();
    }

    analytics.track(eventPage, 'search-enter', { searchTerm: value });
    onSubmit();
  };

  handleSearchIconClick = () => {
    const { value, onSubmit, eventPage } = this.props;

    if (value) {
      analytics.track(eventPage, 'search-icon', { searchTerm: value });
      onSubmit();
    }
  };

  handleClearClick = () => {
    const { onClear, eventPage } = this.props;

    analytics.track(eventPage, 'search-clear');
    onClear();
  };

  searchInputRef: { current: HTMLInputElement | null };

  render() {
    const { id, placeholder, disabled, value, autoFocus, onFocus, onBlur } = this.props;

    return (
      <SearchBarForm onSubmit={this.handleSubmit}>
        <SearchBarInput
          ref={this.searchInputRef}
          id={id}
          name={id}
          disabled={disabled}
          value={value || ''}
          placeholder={placeholder}
          autoFocus={autoFocus}
          onChange={this.handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <SearchIcon isSearchable={!!value} onClick={this.handleSearchIconClick} />

        {value && <ClearIcon onClick={this.handleClearClick} />}
      </SearchBarForm>
    );
  }
}

type SearchBarFormProps = {
  children?: React.ReactNode;
  onSubmit: (event: any) => void;
};

const SearchBarForm = ({ children, onSubmit }: SearchBarFormProps) => (
  <Box as="form" onSubmit={onSubmit} w="full" pos="relative" action=".">
    {children}
  </Box>
);

type SearchBarInputProps = {
  id: string;
  name: string;
  disabled?: boolean;
  value: string | number;
  placeholder?: string;
  autoFocus?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: () => void;
};

const SearchBarInput = React.forwardRef<HTMLInputElement, SearchBarInputProps>(({ ...rest }, ref) => (
  <Input
    ref={ref}
    sx={{
      w: 'full',
      h: 10,
      pl: 12,
      pr: 10,
      border: 0,
      borderBottom: 0,
      outline: 'none',
      color: 'black',
      borderRadius: 'lg',
      textStyle: 'body2',
      transition: '300ms',
      WebkitAppearance: 'none',
      '&::-webkit-search-decoration, &::-webkit-search-cancel-button, &::-webkit-search-results-button, &::-webkit-search-results-decoration': {
        WebkitAppearance: 'none',
      },
      '&::-ms-clear, &::-ms-reveal': {
        display: 'none',
        w: 0,
        h: 0,
      },
      _placeholder: {
        color: 'overlay',
        textStyle: 'body2',
      },
      _hover: {
        bgColor: 'white',
      },
      _focus: {
        bgColor: 'white',
        boxShadow: 400,
        '~ div': {
          opacity: 1,
        },
      },
      bgColor: { base: 'white', lg: 'grey.300' },
      boxShadow: { base: 400, lg: 'none' },
    }}
    type="search"
    testId="search-input"
    {...rest}
  />
));

type SearchIconProps = {
  onClick: () => void;
  isSearchable: boolean;
};

const SearchIcon = ({ isSearchable, onClick }: SearchIconProps) => (
  <Flex
    onClick={onClick}
    cursor={isSearchable ? 'pointer' : 'default'}
    pos="absolute"
    top={0}
    left={0}
    w={12}
    h={10}
    justify="center"
    align="center"
    zIndex="docked"
    opacity={0.3}
  >
    <Icon name={IconNames.search} size={IconSize.lg} />
  </Flex>
);

type ClearIconProps = {
  onClick: () => void;
};

const ClearIcon = ({ onClick }: ClearIconProps) => (
  <Flex
    onClick={onClick}
    pos="absolute"
    top={0}
    right={0}
    justify="center"
    align="center"
    zIndex="docked"
    opacity={0.3}
    cursor="pointer"
    w={10}
    h={10}
  >
    <Icon name={IconNames.closeMini} data-testid="clear-search" />
  </Flex>
);

export default compose(withBreak())(MISearchBar);
