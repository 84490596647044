import { RadioGroup as ChakraRadioGroup, RadioGroupProps } from '@chakra-ui/react';
import * as React from 'react';
import { Stack } from 'src/core/ds/stack';
import type { Props as RadioProps } from './Radio';

export type Props = Omit<RadioGroupProps, 'variant' | 'defaultValue'> & {
  /** Specifies the default checked radio by it's value  */
  defaultValue?: string | number | undefined;
  /** Specifies the group direction display  */
  direction?: 'row' | 'column';
  marginLastChild?: boolean;
  children: React.ReactNode;
  testId?: string;
  label?: string;
};

export type Option = {
  id: string;
  label?: string;
  labelValues?: Record<string, any>;
  component?: React.ReactNode;
  icon?: string;
};

export const RadioGroup = ({
  defaultValue,
  onChange,
  direction = 'row',
  marginLastChild = true,
  name,
  value,
  children,
  testId,
  label,
  ...rest
}: Props) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue || value);
  const dataTestId = testId || `radio-group-${label}`;
  let lastChildMargin;

  if (direction === 'column') {
    const marginValue = marginLastChild ? 3 : 0;
    lastChildMargin = { marginBottom: marginValue };
  } else {
    const marginValue = marginLastChild ? 5 : 0;
    lastChildMargin = { marginInlineEnd: marginValue };
  }

  const handleChange = (checkedValue: string) => {
    setSelectedValue(checkedValue);
    onChange && onChange(checkedValue);
  };

  return (
    <ChakraRadioGroup onChange={handleChange} value={selectedValue} data-testid={dataTestId} {...rest}>
      <Stack
        direction={{ base: 'column', md: direction }}
        alignItems="start"
        spacing={{ base: 3, md: 6 }}
        _last={lastChildMargin}
        textStyle="body2"
      >
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement<RadioProps>, { groupName: name })
        )}
      </Stack>
    </ChakraRadioGroup>
  );
};
