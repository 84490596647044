import { createBatchDeleteSlice } from 'src/helpers/redux/restBatchDeleteSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { InvoiceType } from 'src/modules/invoices/types';
import { name } from '../constants';

const proInvoicesBatchDeleteSlice = createBatchDeleteSlice<InvoiceType>({
  actionName: 'proBatchDelete',
  api: getProApi.invoices.batchDelete,
  storeName: name,
});

export default proInvoicesBatchDeleteSlice;
