import batchCancelSlice from './batch-cancel';
import batchDeleteSlice from './batch-delete';
import batchShareSlice from './batch-share';
import { clearFileUploadSlice, fileUploadSlice } from './file-upload';
import invoicesBatchUpload from './invoices-batch-upload';
import { cancelSlice, markAsSendSlice, resetCreateInvoiceSlice, shareSlice } from './invoices-slices';
import markedAsPaidSlice from './marked-as-paid';
import proInvoicesBatchDeleteSlice from './pro-slices/pro-invoices-batch-delete';
import { proInvoicesBatchMarkAsPaidSlice } from './pro-slices/pro-invoices-batch-mark-as-paid';
import proInvoicesBatchSendSlice from './pro-slices/pro-invoices-batch-send';
import proInvoicesDeleteSlice from './pro-slices/pro-invoices-delete';
import proInvoicesFetchSlice from './pro-slices/pro-invoices-fetch-slice';
import proInvoicesIsListRefreshableSlice from './pro-slices/pro-invoices-is-list-refreshable';
import proListSlice from './pro-slices/pro-invoices-list';
import proInvoicesMarkAsPaidSlice from './pro-slices/pro-invoices-mark-as-paid';
import proInvoicesSendSlice from './pro-slices/pro-invoices-send';
import proInvoicesUpdateSlice from './pro-slices/pro-invoices-update';
import totalCountersSlice from './total-counters';

export const INVOICES_FILE_UPLOAD_CLEAR = clearFileUploadSlice.actions.clear;
export const INVOICES_FILE_UPLOAD_SUCCESS = fileUploadSlice.actions.success;
export const INVOICES_BATCH_UPLOAD_SUCCESS = invoicesBatchUpload.actions.success;
export const INVOICES_FETCH_TOTAL_COUNTER_COUNTERS_SUCCESS = totalCountersSlice.actions.success;
export const INVOICES_BATCH_SHARE_SUCCESS = batchShareSlice.actions.success;
export const INVOICES_BATCH_DELETE_SUCCESS = batchDeleteSlice.actions.success;
export const INVOICES_BATCH_CANCEL_SUCCESS = batchCancelSlice.actions.success;
export const INVOICES_MARK_AS_PAID_SUCCESS = markedAsPaidSlice.actions.success;
export const INVOICES_CANCEL_SUCCESS = cancelSlice.actions.success;
export const INVOICES_MARK_AS_SEND_SUCCESS = markAsSendSlice.actions.success;
export const INVOICES_RESET_CREATE_INVOICE_RESET = resetCreateInvoiceSlice.actions.reset;
export const INVOICES_SHARE_SUCCESS = shareSlice.actions.success;
export const GET_PRO_INVOICES_BATCH_DELETE_SUCCESS = proInvoicesBatchDeleteSlice.actions.success;
export const GET_PRO_INVOICES_BATCH_MARK_AS_PAID_SUCCESS = proInvoicesBatchMarkAsPaidSlice.actions.success;
export const GET_PRO_INVOICES_BATCH_SEND_SUCCESS = proInvoicesBatchSendSlice.actions.success;
export const GET_PRO_INVOICES_DELETE_SUCCESS = proInvoicesDeleteSlice.actions.success;
export const GET_PRO_INVOICES_FETCH_SUCCESS = proInvoicesFetchSlice.actions.success;
export const GET_PRO_INVOICES_IS_LIST_REFRESHABLE_SUCCESS = proInvoicesIsListRefreshableSlice.actions.success;
export const GET_PRO_INVOICES_LIST = proListSlice.actions.success;
export const GET_PRO_INVOICES_MARK_AS_PAID = proInvoicesMarkAsPaidSlice.actions.success;
export const GET_PRO_INVOICES_SEND = proInvoicesSendSlice.actions.success;
export const GET_PRO_INVOICES_UPDATE = proInvoicesUpdateSlice.actions.success;
