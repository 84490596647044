import xeroLogo from 'src/images/accounting-software/cards-logo/xero_logo.svg';
import freshbooksLogo from 'src/images/accounting-software/freshbooks-logo.svg';
import microsoftDynamicsLogo from 'src/images/accounting-software/microsoft-dynamics-logo.svg';
import { AccountingSoftware } from 'src/utils/consts';

export const SSOLogoMap = {
  [AccountingSoftware.FRESHBOOKS]: freshbooksLogo,
  [AccountingSoftware.MICROSOFT_DYNAMICS_365]: microsoftDynamicsLogo,
  [AccountingSoftware.XERO]: xeroLogo,
};
