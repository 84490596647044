import styled from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { ShiftToAchUpsale } from 'src/pages/vendor/shift-vendor-to-ach/ShiftToAchUpsale';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { devices } from 'src/theme/appDevices';
import { PaymentType } from 'src/utils/types';

type Props = {
  token: string;
  payment: PaymentType;
  organization: any;
  hideLogin?: boolean;
};

export const CheckAlreadyDepositedPage = ({ token, payment, organization, hideLogin = false }: Props) => (
  <OutsideLayout hideLogin={hideLogin}>
    <Flex direction="column">
      <BillInfoContainer>
        <Box mb={5}>
          <Icon name={IconNames.checkFill} color="green.500" size={IconSize.xl} />
        </Box>
        <Title data-testid="success-title">
          <MIFormattedText label="vendors.editCheckAddress.checkDeposited.title" />
        </Title>
        <Subtitle>
          <MIFormattedText
            label="vendors.editCheckAddress.checkDeposited.subtitle"
            values={{
              totalAmount: <MIFormattedCurrency value={payment?.amount} />,
              companyName: organization?.companyName,
              invoiceNumber: payment?.bill?.invoiceNumber,
            }}
          />
        </Subtitle>
        <ConvertedUnilateralPaymentActivity payment={payment} />
      </BillInfoContainer>
      <ShiftToAchUpsale
        token={token}
        payment={payment}
        organization={organization}
        labelPrefix="vendors.editCheckAddress.checkDeposited"
      />
    </Flex>
  </OutsideLayout>
);

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;
