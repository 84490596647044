import { BlockCreateLinkModalWithDismiss } from 'src/components/common/BlockCreateLinkModal';
import NewEmptyList from 'src/components/list/NewEmptyList';
import { useModal } from 'src/helpers/react/useModal';
import { useStartVendorCompany } from 'src/hooks/useStartVendorCompany';
import requestEmptyStateIcon from 'src/images/general/request-empty-state-icon.svg';
import { DeliveryMethodOrigin } from 'src/utils/consts';

export const EmptyBlockState = () => {
  const [BlockCreateLinkMsg, showBlockCreateLink] = useModal(BlockCreateLinkModalWithDismiss, {
    modalName: 'block-create-link',
  });

  const { startVendorOnboarding, isLoading } = useStartVendorCompany({ onBlockCreateLink: showBlockCreateLink });

  const onActionClick = () => startVendorOnboarding({ origin: DeliveryMethodOrigin.GET_PAID });

  return (
    <>
      {BlockCreateLinkMsg}
      <NewEmptyList
        iconSrc={requestEmptyStateIcon}
        title="getPaid.emptyState.createLink.title"
        text="getPaid.emptyState.createLink.text"
        ctaLabel="getPaid.emptyState.createLink.action"
        ctaAction={onActionClick}
        isCtaLoading={isLoading}
      />
    </>
  );
};
