import { getDeliveryMethodById } from 'src/pages/bill/utils/billGetters';
import { DeliveryType } from 'src/utils/consts';
import { GlobalState } from '../types';
import { getFundingSources } from '../user/selectors';

export const getBill = (state: GlobalState) => state.payBillWizard.bill;
export const getBillAmount = (state: GlobalState) => state.payBillWizard.bill.totalAmount;
export const getCompanyName = (state: GlobalState) => state.payBillWizard.bill.vendor?.companyName;
export const getContactEmail = (state: GlobalState) => state.payBillWizard.bill.vendor?.contactEmail;
export const getPayment = (state: GlobalState) => state.payBillWizard.payment;
export const getRecurringBill = (state: GlobalState) => state.payBillWizard.recurringBill;
export const getIsRecurring = (state: GlobalState) => state.payBillWizard.isRecurring;
export const getScannedInvoiceId = (state: GlobalState) => state.payBillWizard.scannedInvoiceId;
export const getIsLoading = (state: GlobalState) => state.payBillWizard.isLoading;
export const getUrlToBack = (state: GlobalState) => state.payBillWizard.urlToBack;
export const getSelectedFundingSource = (state: GlobalState) => {
  const fundingSources = getFundingSources(state);
  const payment = getPayment(state);

  return fundingSources.find((fs) => fs.id === payment.fundingSourceId);
};
export const getDeliveryMethodType = (state: GlobalState): DeliveryType | null => {
  const { deliveryMethodId } = state.payBillWizard.payment;
  const deliveryMethod = getDeliveryMethodById(state.payBillWizard.bill, deliveryMethodId);

  if (deliveryMethod) {
    return deliveryMethod.deliveryType;
  }

  return null;
};
export const getErrorCode = (state: GlobalState) => state.payBillWizard.errorCode;
export const getFirstBillIdWithRecurringBill = (state: GlobalState) => state.payBillWizard.firstBillIdWithRecurringBill;

export const getFee = (state: GlobalState) => state.payBillWizard.fee;

export const getRedirectUrl = (state: GlobalState) => state.payBillWizard.redirectUrl;
export const getExitUrl = (state: GlobalState) => state.payBillWizard.exitUrl;
export const getIsCancelAndRetryPaymentFlow = (state: GlobalState) => state.payBillWizard.isCancelAndRetryPaymentFlow;
