import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';

export const HelpFooter = () => (
  <Box textStyle="body2" color="black" textAlign={{ base: 'left', sm: 'center' }} mt={8}>
    <MIFormattedText
      label="onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.link"
      values={{
        microDepositsLink: (...chunks) => (
          <Link
            data-testid="need-help-footer"
            target="_blank"
            href="https://melio.zendesk.com/hc/en-us/articles/4447505319314-How-to-verify-a-bank-account-with-micro-deposits"
          >
            {chunks}
          </Link>
        ),
      }}
    />
  </Box>
);
