import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMounted } from 'src/hooks/useMounted';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { useRefreshDeliveryMethods } from 'src/modules/delivery-methods/hooks/useRefreshDeliveryMethods';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { useRefreshFundingSources } from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { PlaidLink } from 'src/pages/onboarding/funding-sources/bank/components/PlaidLink';
import { PlaidUpgradeModal } from 'src/pages/onboarding/funding-sources/bank/components/PlaidUpgradeModal/PlaidUpgradeModal';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DbAnalyticsTraits, DeliveryType, FeatureFlags } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { FlowCustomizationsType } from '../../types';

type Props = {
  vendorId?: string;
  isNewPlaidMDRecoveryFlow: boolean;
  isDeliveryMethod: boolean;
  flowCustomizations?: FlowCustomizationsType;
  onSuccess: () => void;
  onFail: () => void;
};

export const AddPlaidBankAccountPage = ({
  vendorId,
  isNewPlaidMDRecoveryFlow,
  isDeliveryMethod,
  flowCustomizations,
  onSuccess,
  onFail,
}: Props) => {
  const eventPage = 'bank-add-plaid';
  const { isMounted } = useMounted();
  const { onFSBankAccountAdded, flowEntryPoint } = flowCustomizations || {};
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const [isPlaidInUpgrade] = featureFlags.useFeature(FeatureFlags.IsPlaidInUpgrade, false);
  const [isPlaidMicroDepositsGetPaidEnabled] = featureFlags.useFeature(
    FeatureFlags.PlaidMicroDepositsGetPaidEnabled,
    false
  );
  const supportMD = isDeliveryMethod && isPlaidMicroDepositsGetPaidEnabled;

  const { refreshDeliveryMethods, isDeliveryMethodsRefreshing } = useRefreshDeliveryMethods();
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  const onPlaidSuccess = (token: string, metadata: Record<string, any>) => {
    if (!metadata || metadata.error) {
      analytics.track(eventPage, 'plaid-error', { error: metadata.error });

      return;
    }

    const { account } = metadata;

    if (!account) {
      analytics.track(eventPage, 'plaid-error', {
        error: 'account not selected',
      });

      return;
    }

    const selectedAccountsIds = [account.id];

    if (isDeliveryMethod) {
      addDeliveryMethodPlaidAccount(selectedAccountsIds, token);
    } else {
      addFundingSourcePlaidAccount(selectedAccountsIds, token);
    }
  };

  const addDeliveryMethodPlaidAccount = async (selectedAccountsIds: Array<string>, token: string) => {
    try {
      await deliveryMethodsApi.addPlaidAccount(orgId, vendorId, {
        token,
        selectedAccountsIds,
      });

      if (ownedVendorId && vendorId && parseInt(vendorId, 10) === ownedVendorId) {
        await refreshDeliveryMethods();
      }

      analytics.track(eventPage, 'add-delivery-method-success', {
        type: DeliveryType.ACH,
        vendorId,
      });
      onSuccess();
    } catch (e) {
      capture(e as Error);
      onFail();
    }
  };

  const addFundingSourcePlaidAccount = async (selectedAccountsIds: Array<string>, token: string) => {
    try {
      const response = await financialAccountsApi.addPlaidAccount(orgId, { token, selectedAccountsIds });

      if (isEmpty(response.accounts)) {
        onFail();
      } else {
        const { accounts } = response;
        await refreshFundingSources();
        analytics.track(eventPage, 'plaid-success');
        analytics.trackMqlEvent('added-funding', 'mql');
        analytics.trackMqlEvent('added-funding-plaid', 'mql');
        analytics.setTraits({
          [DbAnalyticsTraits.ADDED_FUNDING]: true,
        });
        analytics.setFundingSourceTraits();
        onFSBankAccountAdded && onFSBankAccountAdded(accounts[0].id);
        onSuccess();
      }
    } catch (e) {
      capture(e as Error);
      onFail();
    }
  };

  const onPlaidExit = () => {
    if (isMounted()) {
      analytics.track('bank-add-plaid', 'plaid-exit', {
        isNewPlaidMDRecoveryFlow,
        flowEntryPoint,
      });
      onFail();
    }
  };

  useEffect(() => {
    analytics.page(`add-${isDeliveryMethod ? 'delivery-method' : 'funding-source'}`, 'bank-plaid', { flowEntryPoint });
  }, []);

  return isPlaidInUpgrade ? (
    <PlaidUpgradeModal goExit={onPlaidExit} />
  ) : (
    <PlaidLink
      isLoading={isDeliveryMethodsRefreshing || isFundingSourcesRefreshing}
      orgId={orgId}
      onSuccess={onPlaidSuccess}
      onExit={onPlaidExit}
      supportMD={supportMD}
      showDialog
    />
  );
};
