import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { getJWTPayload } from 'src/helpers/jwt';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import { usePaymentDataFromToken } from 'src/pages/vendor/hooks/usePaymentDataFromToken';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { devices } from 'src/theme/appDevices';

type Props = {
  token: string;
};

const SwitchedToACHAlreadyPage = ({ token }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const { contactEmail, vendorId } = getJWTPayload(token);
  const { payment, isPaymentLoading, organization } = usePaymentDataFromToken({ token });
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  const goLearnMore = () => {
    window.open('https://www.meliopayments.com/request');
  };

  const goSignUp = () => {
    historyPush({
      path: registrationLocations.vendorRegister,
      state: {
        email: contactEmail,
        vendorIdToConvertToOwned: vendorId,
      },
    });
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout>
      <BillPaymentBlock>
        <BillInfoContainer>
          <InfoIcon />
          <Title data-testid="success-title">
            <MIFormattedText label="vendors.editCheckAddress.switchedToACHAlready.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.switchedToACHAlready.subtitle"
              values={{
                companyName: organization?.companyName,
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
                digits: deliveryMethod?.bankAccount?.accountNumber?.slice(-4),
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
        <ShiftToAchContainer>
          <ShiftToAchTitle>
            <MIFormattedText
              label="vendors.editCheckAddress.switchedToACHAlready.upsaleTitle"
              values={{
                companyName: <Label>{organization?.companyName}</Label>,
              }}
            />
          </ShiftToAchTitle>
          <ShiftToAchSubtitle>
            <MIFormattedText label="vendors.editCheckAddress.switchedToACHAlready.upsaleSubTitle" />
            <LearnMoreLink onClick={goLearnMore}>
              <MIFormattedText label="vendors.editCheckAddress.switchedToACHAlready.learnMore" />
            </LearnMoreLink>
          </ShiftToAchSubtitle>
          <Button
            onClick={goSignUp}
            label="vendors.editCheckAddress.switchedToACHAlready.upsaleCTA"
            size={ButtonSizes.lg}
            isFullWidth
          />
        </ShiftToAchContainer>
      </BillPaymentBlock>
    </OutsideLayout>
  );
};

export default SwitchedToACHAlreadyPage;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const InfoIcon = styled.i.attrs({ className: 'icon-intuit-info-icon' })`
  font-size: 4rem;
  color: ${(props) => props.theme.text.color.blue};
  margin-bottom: 2rem;
`;

const ShiftToAchContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-radius: 0 0 0.6rem 0.6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ShiftToAchTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  font-weight: ${(props) => props.theme.text.weight.bold};
  line-height: 3.2rem;
`;

const ShiftToAchSubtitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  margin: 1.2rem 0 4rem;
`;

const Label = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const LearnMoreLink = styled.span`
  color: ${(props) => props.theme.colors.brand};
  cursor: pointer;
  ${(props) => props.theme.text.fontType.medium};
  margin-left: 0.5rem;
  font-size: 1.6rem;
`;
