import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

const Title = () => (
  <Container data-testId="spend-management-title">
    <MIFormattedText label="spendManagement.pages.dashboard.title" />
  </Container>
);

// eslint-disable-next-line import/no-default-export
export default Title;

const Container = styled.div`
  font-size: 3.4rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  color: ${(props) => props.theme.text.color.main};
  line-height: 4.2rem;
  text-transform: capitalize;
  flex: 0 0 auto;
`;
