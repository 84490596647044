import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { payBillEventPage, usePayBillDataContext } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { vendorLocations } from 'src/pages/vendor/locations';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, getBillTag, isVirtualCardExpired } from 'src/utils/bills';
import {
  DeliveryMethodOrigin,
  DeliveryType,
  FailedPaymentMessage,
  FailedPaymentType,
  PayEditLocationName,
  PaymentStatus,
} from 'src/utils/consts';
import { hasFailedInstallment } from 'src/utils/financing';
import {
  isDirectPayment,
  isReturnedCheck,
  isUndepositedPayment,
  shouldGoBackToFastPaymetOfferPage,
} from 'src/utils/payments';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { NavigateType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
};

export const useConfirmPaymentNavigate = ({ navigate, nextStepURL, prevStepURL }: Params) => {
  const location = useLocation();
  const locationState = location.state as Record<string, string>;
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);
  const isReturnedCheckPayment = isReturnedCheck(payment);
  const isOriginDeliveryTypeCheck = locationState?.originDeliveryType === DeliveryType.CHECK;
  const isUndepositedCheckPayment = isOriginDeliveryTypeCheck && isUndepositedPayment(payment);
  const { deliveryOptionsDates } = usePayBillDataContext();

  const { getUrl, onPrev } = usePayBillNavigateCommon({ navigate, nextStepURL, prevStepURL });

  const onPrevConfirmPayment = () => {
    const status = getBillTag(bill);
    const eventName = `set-${getEventNameFromLocation(location)}`;
    analytics.track(payBillEventPage, `${eventName}-back`, {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    if (status === PaymentStatus.FAILED && payment && payment.id) {
      if (payment?.metadata?.failedType === FailedPaymentType.FAILED_TO_DELIVER) {
        const isUnilateral = payment.deliveryMethod?.deliveryType === DeliveryType.VIRTUAL;
        const redirectUrl = generatePath(billLocations.pay.edit.confirm, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        });
        const exitUrl = generatePath(billLocations.view, { orgId, id: bill.id });

        if (isUnilateral) {
          return navigate(
            generatePath(vendorLocations.deliveryMethods.virtual.create, {
              orgId,
              id: bill.vendorId!,
            }),
            false,
            {
              origin: locationState?.origin || DeliveryMethodOrigin.EDIT_PAYMENT,
              redirectUrl,
              exitUrl,
            }
          );
        } else if (isVirtualCardExpired(payment)) {
          return navigate(
            generatePath(billLocations.pay.edit.virtualCardRecovery, {
              orgId,
              billId: bill.id,
              paymentId: payment.id,
            }),
            false,
            {
              origin: locationState?.origin || DeliveryMethodOrigin.EDIT_PAYMENT,
              redirectUrl,
              exitUrl,
            }
          );
        } else if (payment.metadata?.failureMessage === FailedPaymentMessage.VIRTUAL_CARD_EXPIRED) {
          return navigate(
            generatePath(billLocations.pay.edit.virtualCardRecovery, {
              orgId,
              billId: bill.id,
              paymentId: payment.id,
            }),
            false,
            {
              origin: DeliveryMethodOrigin.EDIT_PAYMENT,
              redirectUrl,
              exitUrl,
            }
          );
        } else if (isReturnedCheckPayment) {
          return navigate(
            generatePath(billLocations.pay.edit.returnedCheckRecovery, {
              orgId,
              billId: bill.id,
              paymentId: payment.id,
            }),
            false,
            {
              origin: locationState?.origin || DeliveryMethodOrigin.EDIT_PAYMENT,
              redirectUrl,
              exitUrl,
            }
          );
        }

        return navigate(
          generatePath(billLocations.pay.edit.deliveryMethodAch, {
            orgId,
            billId: bill.id,
            paymentId: payment.id,
            deliveryMethodId: payment.deliveryMethodId,
          })
        );
      }

      const { id: paymentId, deliveryPreference, deliveryMethod } = payment;
      const shouldShowFastPaymentPage = shouldGoBackToFastPaymetOfferPage({
        deliveryOptions: deliveryOptionsDates?.deliveryOptions,
        deliveryType: deliveryMethod.deliveryType,
        deliveryPreference,
      });

      return shouldShowFastPaymentPage
        ? navigate(
            generatePath(billLocations.pay.edit.fastPayment, {
              orgId,
              billId: bill.id,
              paymentId,
            })
          )
        : navigate(
            generatePath(billLocations.pay.edit.funding, {
              orgId,
              billId: bill.id,
              paymentId,
            })
          );
    }

    if (isUndepositedCheckPayment && payment) {
      const redirectUrl = generatePath(billLocations.pay.edit.confirm, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      });
      const exitUrl = generatePath(billLocations.view, { orgId, id: bill.id });

      return navigate(
        generatePath(billLocations.pay.edit.resendUndepositedCheck, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        }),
        false,
        {
          origin: locationState?.origin || DeliveryMethodOrigin.EDIT_PAYMENT,
          redirectUrl,
          exitUrl,
        }
      );
    }

    if (isDirectPayment(payment)) {
      const url = getUrl(PayEditLocationName.DATE);

      return navigate(url);
    }

    const isFailedInstallment = hasFailedInstallment(payment);

    if (isFailedInstallment) {
      return navigate(
        generatePath(billLocations.pay.edit.funding, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        })
      );
    }

    return onPrev();
  };

  return { onPrevConfirmPayment };
};
