import { featureFlags } from '@melio/shared-web';
import { AddDeliveryMethodPage as AddDeliveryMethodBasisTheoryPage } from 'src/pages/vendor/shift-vendor-to-debit/basis-theory/AddDeliveryMethodPage';
import { ShiftToDebitState } from 'src/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import AddDeliveryMethodTabapayPage from 'src/pages/vendor/shift-vendor-to-debit/tabapay/AddDeliveryMethodPage';
import { FeatureFlags } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  token: string;
  state: ShiftToDebitState;
};

export const AddDeliveryMethodPageContainer = (props: Props) => {
  const [isCardVaultingPushEnabled] = featureFlags.useAnonymousFeature(FeatureFlags.CardVaultingPush, true);
  const [parentFlow] = useLocationState('parentFlow', undefined);

  return isCardVaultingPushEnabled ? (
    <AddDeliveryMethodBasisTheoryPage {...props} parentFlow={parentFlow} />
  ) : (
    <AddDeliveryMethodTabapayPage {...props} parentFlow={parentFlow} />
  );
};
