import { InternationalPaymentPurpose } from 'src/pages/vendor/international-delivery-method/consts';
import { InternationalWizardState } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalWizard';

export type InternationalPaymentPurposeModel = Partial<InternationalWizardState>;

export const paymentPurposeFormValidator = (isInvoiceAttachmentRequired?: boolean) => (
  key: string,
  value: string | number | boolean,
  modelState: InternationalPaymentPurposeModel
) => {
  if (key === 'purpose' && !value) {
    return 'vendors.deliveryMethods.international.paymentPurpose.error';
  } else if (
    key === 'purposeDescription' &&
    modelState.purpose === InternationalPaymentPurpose.OTHER &&
    !modelState.purposeDescription
  ) {
    return 'vendors.deliveryMethods.international.paymentPurpose.errorDesc';
  } else if (isInvoiceAttachmentRequired && key === 'invoiceAttachment' && !value) {
    return 'vendors.deliveryMethods.international.paymentPurpose.errorInvoice';
  }

  return undefined;
};
