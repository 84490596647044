import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import {
  InternationalPaymentPurposeModel,
  paymentPurposeFormValidator,
} from 'src/pages/vendor/international-delivery-method/payment-purpose/utils';
import { ModelView, useForm } from 'src/ui/form';

export const useAddPaymentPurposeModal = (
  onClose,
  itemId,
  isInvoiceAttachmentRequired
): {
  model: ModelView<InternationalPaymentPurposeModel>;
  submit: () => void;
} => {
  const actions = useStoreActions(regularBatchPaymentsStore);

  const paymentPurpose = useSelector(regularBatchPaymentsStore.selectors.getPaymentPurpose(itemId));
  const paymentPurposeModel = useMemo<InternationalPaymentPurposeModel>(() => {
    const initialFieldSet = {
      purpose: paymentPurpose.purpose || '',
      purposeDescription: paymentPurpose.purposeDescription || '',
    };

    if (isInvoiceAttachmentRequired) {
      return {
        ...initialFieldSet,
        invoiceAttachment: paymentPurpose.invoiceAttachment || false,
      };
    }

    return initialFieldSet;
  }, [isInvoiceAttachmentRequired]);

  const [model, { submit }] = useForm<InternationalPaymentPurposeModel>(paymentPurposeModel, {
    submit: async ({ purpose, purposeDescription }) => {
      await actions.updatePayments.setPaymentPurpose({ purpose, purposeDescription, itemId });
      onClose();
    },
    validateOnChange: false,
    validator: paymentPurposeFormValidator(isInvoiceAttachmentRequired),
  });

  return {
    model,
    submit,
  };
};
