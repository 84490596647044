import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { Menu, MenuButton, MenuList } from 'src/core/ds/menu';
import { useOrgId } from 'src/hooks';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import {
  AddBankAccountMenuItem,
  AddCreditCardMenuItem,
  AddFundingSourceMenuItem,
} from 'src/pages/regular-batch-payments/components/menu/fundingSource/AddFundingSourceMenuItem';
import { FundingSourceLabel } from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceLabel';
import { FundingSourceMenuItem } from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceMenuItem';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';

type Props = {
  onChange: (fundingSourceId: number) => void;
  isLoading: boolean;
};

export const PaymentMethodMenu = ({ onChange, isLoading }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const [filteredFundingSources] = useGetValidFundingSources();
  const isMultipleFundingSource = useSelector(regularBatchPaymentsStore.selectors.isMultipleFundingSource);
  const { fundingSourceId } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const orgId = useOrgId();
  const fundingSourceDefault = filteredFundingSources.find((fs) => fs.id === fundingSourceId);
  const batchPaymentsPath = generatePath(batchPaymentsLocations.base, { orgId });

  const handleAddCreditCardClick = () => {
    historyPush({
      path: onboardingLocations.fundingSources.card.index,
      state: {
        redirectUrl: batchPaymentsPath,
        exitUrl: batchPaymentsPath,
      },
    });
  };

  const handleBankAccountClick = () => {
    historyPush({ path: onboardingLocations.fundingSources.bank.addFromPayBatchFlow.base });
  };

  const handleAddFundingSourceClick = () => {
    historyPush({
      path: onboardingLocations.fundingSources.index,
      state: {
        redirectUrl: batchPaymentsPath,
        exitUrl: batchPaymentsPath,
        isNewPlaidMDRecoveryFlow: true,
      },
    });
  };

  const handleFundingSourceSelected = useCallback(
    (fundingSource) => {
      onChange(fundingSource.id);
    },
    [onChange]
  );

  return (
    <Menu placement="bottom-end" autoSelect={false}>
      <MenuButton
        disabled={isLoading}
        as={Button}
        w="auto"
        variant={ButtonVariants.tertiary}
        rightIcon={<ChevronDownIcon width={8} height={8} />}
        size={ButtonSizes.sm}
        textStyle="body4Semi"
        data-testid="payment-method-menu-button"
        skipAnalyticsEvent
        paddingX={3}
      >
        {isMultipleFundingSource ? (
          <Box textStyle="body4Semi">
            <MIFormattedText label="regularBatchPayments.BatchSettings.multiplePaymentMethod" />
          </Box>
        ) : (
          <Flex>
            <FundingSourceLabel fundingSource={fundingSourceDefault!} />
          </Flex>
        )}
      </MenuButton>
      {filteredFundingSources.length ? (
        <MenuList>
          {filteredFundingSources.map((fundingSource) => (
            <FundingSourceMenuItem
              key={fundingSource.id}
              fundingSource={fundingSource}
              onClick={handleFundingSourceSelected}
              isSelected={fundingSourceId ? fundingSourceDefault!.id === fundingSource.id : false}
              testId="-setting"
            />
          ))}
          <AddFundingSourceMenuItem onClick={handleAddFundingSourceClick} />
        </MenuList>
      ) : (
        <MenuList>
          <AddBankAccountMenuItem onClick={handleBankAccountClick} />
          <AddCreditCardMenuItem onClick={handleAddCreditCardClick} />
        </MenuList>
      )}
    </Menu>
  );
};
