import { DeletePaymentAction, PaymentStatus } from 'src/utils/consts';
import { BillType, PaymentType } from 'src/utils/types';

export const getDeletePaymentModalLabels = (
  bill: BillType,
  payment: PaymentType,
  paymentForDeleteType: DeletePaymentAction
) => {
  let title = 'payments.form.deleteDialog.title';
  let okButtonText = 'payments.form.deleteDialog.confirm';
  let subtitle = 'payments.form.deleteDialog.subtitle';
  let cancelButtonText = 'payments.form.deleteDialog.cancelText';

  if (bill.recurringBillId) {
    if (paymentForDeleteType === DeletePaymentAction.RECURRING_CURRENT) {
      title = 'payments.form.deleteDialog.deleteRecurringCurrentTitle';
      subtitle = 'payments.form.deleteDialog.deleteRecurringCurrentSubtitle';
      okButtonText = 'payments.form.deleteDialog.confirmRecurringCurrent';
    } else {
      title = 'payments.form.deleteDialog.deleteRecurringAllTitle';
      okButtonText = 'payments.form.deleteDialog.confirmRecurringAll';
    }
  } else if (payment.status === PaymentStatus.FAILED) {
    title = 'payments.form.deleteDialog.deleteSingleTitle';
    subtitle = 'payments.form.deleteDialog.deleteSingleSubtitle';
    okButtonText = 'payments.form.deleteDialog.deleteSingleConfirm';
    cancelButtonText = 'payments.form.deleteDialog.deleteSingleCancel';
  }

  return {
    title,
    subtitle,
    okButtonText,
    cancelButtonText,
  };
};
