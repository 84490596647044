import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ManualAddressOptionsContainer } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useManualAddressState } from 'src/hoc/manual-address';
import organizationStore from 'src/modules/organizations/organizations-store';
import { ManualAddress } from 'src/utils/consts';

type Props = {
  onPrev: () => void;
  onNext: () => void;
};

export const QuickbookMultiOrgAddAccountingFirmManualAddress = ({ onPrev, onNext }: Props) => {
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const dispatch = useDispatch();

  const onSubmit = async (manualAddress) => {
    dispatch(
      organizationStore.actions.updateNewCompanySlice.update({
        ...newCompanyInfo,
        ...manualAddress,
      })
    );
    onNext();
  };

  const [selectedAddressId, setSelectedAddressId] = useState(ManualAddress.SUGGESTED);
  const [state, actions, addressModel] = useManualAddressState({
    selectedAddressId,
    onSuccess: onSubmit,
  });
  const { submit, confirmAddress, editAddress } = actions;
  const {
    isValidAddress,
    whitePageValidationErrors,
    whitePageAddressView,
    whitePageAddress,
    originalAddress,
    isAddressLoading,
  } = state;

  const getTitle = () =>
    whitePageAddressView && !isValidAddress
      ? 'manualAddress.invalidTitle'
      : 'onboarding.companyInfo.address.manualTitle';

  const getNextLabel = () => {
    if (whitePageAddressView && isValidAddress) {
      return 'manualAddress.confirm';
    } else if (whitePageAddressView && !isValidAddress) {
      return 'manualAddress.confirmInvalid';
    }

    return '';
  };

  const getSubtitle = () => {
    if (whitePageAddressView && isValidAddress) {
      return 'onboarding.companyInfo.address.confirmSubTitleLine1';
    } else if (whitePageAddressView && !isValidAddress) {
      return 'manualAddress.invalidSubtitle';
    }

    return '';
  };

  return (
    <StepLayoutPage
      title={getTitle()}
      subtitle={getSubtitle()}
      nextLabel={getNextLabel()}
      onPrev={onPrev}
      onNext={whitePageAddressView ? confirmAddress : submit}
      isLoading={isAddressLoading}
    >
      <ManualAddressOptionsContainer
        selectedAddressId={selectedAddressId}
        setSelectedAddressId={setSelectedAddressId}
        addressModel={addressModel}
        editAddress={editAddress}
        isValidAddress={isValidAddress}
        whitePageValidationErrors={whitePageValidationErrors as any}
        whitePageAddress={whitePageAddress as any}
        originalAddress={originalAddress}
        submitAddressForm={submit}
        whitePageAddressView={whitePageAddressView}
      />
    </StepLayoutPage>
  );
};
