import { MaskField } from 'src/core/ds/form/fields';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { ModelViewField } from 'src/ui/form';

type Props = {
  modelViewField: ModelViewField<InternalBusinessOwnershipDetailsModel['ownershipPercentage']>;
  testId?: string;
  errorMessage?: string;
};

export const OwnershipPercentageField = ({ modelViewField, testId, errorMessage }: Props) => (
  <MaskField
    testId={testId || 'input-ownershipPercentage'}
    label="financing.businessOwnershipDetails.fields.ownershipPercentage.title"
    placeholder="financing.businessOwnershipDetails.fields.ownershipPercentage.placeholder"
    errorMessage={errorMessage}
    isRequired
    model={modelViewField}
    suffix="%"
    allowNegative={false}
    decimalSeparator={false}
    isAllowed={({ value }) => value <= 100}
    mb={0}
  />
);
