import { useCallback, useEffect, useRef } from 'react';

export const useAbortControllerSignal = (): {
  abortController: AbortController | null;
  getAbortControllerSignal: () => AbortSignal;
} => {
  const abortController = useRef<null | AbortController>(null);
  const getAbortControllerSignal = useCallback((): AbortSignal => {
    if (abortController.current) {
      abortController.current.abort();
    }

    abortController.current = new AbortController();

    return abortController.current.signal;
  }, []);

  useEffect(
    () => () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    },
    []
  );

  return { abortController: abortController.current, getAbortControllerSignal };
};
