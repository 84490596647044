import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getAmexAllowedIndustries, SortedAmexIndustriesByCategory } from 'src/pages/bill/utils';
import { getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { CardNetworkTypes, FeatureFlags } from 'src/utils/consts';
import { isCreditAmexNetwork } from 'src/utils/funding-sources';

const useAmexIndustry = (
  vendorId: number | undefined | null,
  selectedFundingSourceId?: number,
  isBatchFlow = false
) => {
  const orgId = useSelector(getOrgId);
  const fundingSources = useSelector(getFundingSources);
  const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);
  const vendorActions = useStoreActions(vendorsStore);
  const vendorMccCode = useSelector(vendorsStore.selectors.fetchVendorMccCode.getMccCode(vendorId));
  const [industriesList, setIndustriesList] = useState<SortedAmexIndustriesByCategory>({
    sortedCategories: [],
    byCategory: {},
  });
  const [pendingResources, setPendingResources] = useState(false);
  const [isAmexBatchRestrictionsEnabled] = featureFlags.useFeature(FeatureFlags.BatchAmexMccPay, false);

  const isFeatureFlagEnabled = isBatchFlow ? isAmexBatchRestrictionsEnabled : true;
  const isAmexCreditCard = isCreditAmexNetwork(selectedFundingSource);
  const shouldShowAmexIndustryModal = isAmexCreditCard && !vendorMccCode && isFeatureFlagEnabled;

  const getMccCode = useCallback(async () => {
    if (isFeatureFlagEnabled && selectedFundingSource && vendorId) {
      await vendorActions.fetchVendorMccCode({ orgId, id: vendorId });
    }
  }, [selectedFundingSource, isFeatureFlagEnabled, orgId, vendorId, vendorActions]);

  const fetchIndustries = useCallback(async () => {
    setPendingResources(true);
    const list = await getAmexAllowedIndustries(orgId, CardNetworkTypes.AMEX);
    setIndustriesList(list);
    setPendingResources(false);
  }, [orgId]);

  useEffect(() => {
    if (isAmexCreditCard && !vendorMccCode) {
      getMccCode();
    }
  }, [isAmexCreditCard, orgId, selectedFundingSource, vendorId, getMccCode, vendorMccCode]);

  useEffect(() => {
    if (isAmexCreditCard && isEmpty(industriesList.byCategory)) {
      fetchIndustries();
    }
  }, [isAmexCreditCard, isBatchFlow, industriesList, fetchIndustries]);

  return { vendorMccCode, industriesList, shouldShowAmexIndustryModal, pendingResources };
};

export { useAmexIndustry };
