export const quickbookMultiOrgOnboardingLocations = {
  base: ['/quickbooks-multi-org-onboarding'],
  companyType: '/quickbooks-multi-org-onboarding/company-type',
  companiesList: '/quickbooks-multi-org-onboarding/companies-list',
  connectQuickbooks: '/quickbooks-multi-org-onboarding/connect-quickbooks',
  quickbooksSuccess: '/quickbooks-multi-org-onboarding/quickbooks-success',
  quickbooksError: '/quickbooks-multi-org-onboarding/quickbooks-error',
  addAccountingFirm: {
    base: ['/quickbooks-multi-org-onboarding/add-accounting-firm'],
    details: '/quickbooks-multi-org-onboarding/add-accounting-firm/details',
    name: '/quickbooks-multi-org-onboarding/add-accounting-firm/company-name',
    address: '/quickbooks-multi-org-onboarding/add-accounting-firm/address',
    manualAddress: '/quickbooks-multi-org-onboarding/add-accounting-firm/manual-address',
    clientsAmount: '/quickbooks-multi-org-onboarding/add-accounting-firm/clients-amount',
  },
};
