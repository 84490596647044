import { all, fork, getContext, put } from 'redux-saga/effects';
import { financingStore } from 'src/modules/financing/financing-store';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import joinOrganizationRequests from 'src/modules/join-organization-requests/join-organization-requests-store';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';
import { intercomService } from 'src/services/intercom';
import { getOrgIdByUrl } from '../helpers/utilities';
import { accountingPlatformBankAccountsStore } from '../modules/accounting-platform-bank-accounts/accounting-platform-bank-accounts-store';
import { accountingPlatformsStore } from '../modules/accounting-platforms/accounting-platforms-store';
import { approvalWorkflowsStore } from '../modules/approval-workflows/approval-workflows-store';
import auth from '../modules/auth/auth-store';
import { billsStore as bills } from '../modules/bills/bills-store';
import customers from '../modules/customers/customers-store';
import deliveryMethods from '../modules/delivery-methods/delivery-methods-store';
import { getProStore } from '../modules/get-pro/get-pro-store';
import { getProInProgressPaymentsStore } from '../modules/get-pro-in-progress-payments/get-pro-in-progress-payments-store';
import { getProPaidPaymentsStore } from '../modules/get-pro-paid-payments/get-pro-paid-payments-store';
import invitations from '../modules/invitations/invitations-store';
import invoices from '../modules/invoices/invoices-store';
import navigation from '../modules/navigation/navigation-store';
import { notificationPreferencesStore } from '../modules/notification-preferences/notification-preferences-store';
import organizations from '../modules/organizations/organizations-store';
import { paymentRequestsStore } from '../modules/payment-requests/payment-requests-store';
import { paymentsStore } from '../modules/payments/payment-store';
import { profileStore } from '../modules/profile/profile-store';
import regularBatchPaymentsStore from '../modules/regular-batch-payments/regular-batch-payments-store';
import { scannedInvoicesStore } from '../modules/scanned-invoices/store';
import users from '../modules/users/users-store';
import vendors from '../modules/vendors/vendors-store';
import payBillWizardSagas from './payBillWizard/sagas';
import { checkAndInitUserAction } from './user/actions';
import userSagas from './user/sagas';

function* initialSaga() {
  const params = {
    orgId: getOrgIdByUrl(),
  };
  const site = yield getContext('site');

  if (site.authOnEntry) {
    yield put(checkAndInitUserAction(params));
  } else {
    intercomService.setChat({
      isHideDefault: true,
      isLoggedInAs: false,
      user: null,
      intercomSettings: site.intercomSettings,
      canContactSupport: true,
    });
  }
}

export default function* rootSaga() {
  yield fork(initialSaga);
  yield all([
    payBillWizardSagas(),
    userSagas(),
    auth.saga(),
    users.saga(),
    profileStore.saga(),
    organizations.saga(),
    invitations.saga(),
    vendors.saga(),
    paymentsStore.saga(),
    deliveryMethods.saga(),
    bills.saga(),
    navigation.saga(),
    customers.saga(),
    paymentRequestsStore.saga(),
    joinOrganizationRequests.saga(),
    invoices.saga(),
    fundingSourcesStore.saga(),
    accountingPlatformsStore.saga(),
    getProInProgressPaymentsStore.saga(),
    getProPaidPaymentsStore.saga(),
    regularBatchPaymentsStore.saga(),
    accountingPlatformBankAccountsStore.saga(),
    getProStore.saga(),
    notificationPreferencesStore.saga(),
    approvalWorkflowsStore.saga(),
    payListItemsStore.saga(),
    scannedInvoicesStore.saga(),
    financingStore.saga(),
  ]);
}
