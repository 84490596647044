export const CardStyle = {
  display: 'flex',
  boxShadow: '500',
  py: 6,
  pr: 5,
  pl: 6,
  boxSizing: 'border-box',
  cursor: 'pointer',
  backgroundColor: 'white',
  mb: 2,
  width: 'full',
  maxWidth: '45rem',
  borderRadius: 'lg',
  transition: '300ms',
  transitionProperty: 'box-shadow',
  _hover: {
    boxShadow: '600',
    _active: {
      boxShadow: '500',
    },
  },
};

export const CardLabelTextStyle = (disabled?: boolean) => ({
  color: 'black',
  opacity: disabled ? '0.3' : '1',
  textStyle: 'body2Semi',
  mb: 0.5,
});

export const DescriptionTextStyle = (disabled?: boolean) => ({
  color: disabled ? 'black' : 'grey.700',
  opacity: disabled ? '0.3' : '1',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  textStyle: 'body4',
});
