import * as React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Flex from 'src/core/ds/flex';
import {
  MethodOptionDescription,
  MethodOptionIcon,
  MethodOptionImage,
  MethodOptionLabel,
  MethodOptionOnTopDescription,
} from '../components/MethodElements';

type RadioSelectMethodOptionProps = {
  id: string;
  icon?: string;
  imageSrc?: string;
  label: string;
  labelValues?: Record<string, any>;
  description: string;
  descriptionValues?: Record<string, any>;
  descriptionIcon?: React.ReactNode;
  onTopDescription?: string | null;
  disabled?: boolean;
  isDefault: boolean;
  showFirmBillingFeeBadge: boolean;
  isSelected?: boolean;
  onClick: (event: any) => void;
  hint?: string;
  hintValues?: Record<string, any>;
  origin: string;
};

export const RadioSelectMethodOption = ({
  id,
  icon,
  imageSrc,
  label,
  labelValues = {},
  description,
  descriptionValues = {},
  descriptionIcon,
  onTopDescription,
  disabled,
  isDefault,
  showFirmBillingFeeBadge = false,
  isSelected,
  onClick,
  hint,
  hintValues,
  origin,
}: RadioSelectMethodOptionProps) => (
  <RadioSelectOptionContainer
    className={isSelected ? 'selected-funding-source' : undefined}
    data-testid={`radio-select-method-${id}`}
    disabled={disabled}
    isSelected={isSelected}
    onClick={disabled ? undefined : onClick}
  >
    {icon && <MethodOptionIcon name={icon} disabled={disabled} />}
    {imageSrc && <MethodOptionImage src={imageSrc} alt="option-icon" />}
    <MethodInfoContainer>
      <MethodOptionLabel disabled={disabled}>
        {onTopDescription && <MethodOptionOnTopDescription>{onTopDescription}</MethodOptionOnTopDescription>}
        <Flex alignItems="center">
          <MIFormattedText label={label} values={labelValues} />
          {!disabled && isDefault && (
            <Flex mb={0.5} pl={2} alignItems="center">
              <Badge
                testId="default-funding-source-badge"
                color={BadgeColors.NEUTRAL_DARK}
                label="settings.paymentMethods.defaultLabel"
              />
            </Flex>
          )}
          {!disabled && showFirmBillingFeeBadge && (
            <Flex mb={0.5} pl={2} alignItems="center">
              <Badge
                testId="firm-funding-source-badge"
                color={BadgeColors.NEUTRAL_DARK}
                label="billing.addMethod.firmBadge"
                variant={BadgeVariants.OUTLINED}
              />
            </Flex>
          )}
        </Flex>
        {hint && (
          <PaymentInfoAdditionalText>
            <MIFormattedText label={hint} values={hintValues} />
          </PaymentInfoAdditionalText>
        )}
      </MethodOptionLabel>
      {description && (
        <Flex alignItems="flex-end">
          {descriptionIcon}
          <MethodOptionDescription disabled={disabled} origin={origin}>
            <MIFormattedText label={description} values={descriptionValues} />
          </MethodOptionDescription>
        </Flex>
      )}
    </MethodInfoContainer>
  </RadioSelectOptionContainer>
);

const getBorder = ({ selected, disabled, colors }) => {
  if (disabled) {
    return `0.1rem solid ${colors.dark.translucent2}`;
  } else if (selected) {
    return `0.2rem solid ${colors.primary.opaque}`;
  }

  return '0.2rem solid transparent';
};

export const RadioSelectOptionContainer = styled.div<{
  disabled?: boolean;
  isSelected?: boolean;
  isLoading?: boolean;
}>`
  display: flex;
  align-items: center;
  box-shadow: ${(props) => (props.disabled ? 'none' : `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}`)};
  padding: ${(props) => (props.disabled ? '3.1rem 2.3rem' : '3.0rem 2.2rem')};
  box-sizing: border-box;
  cursor: ${(props) => (props.disabled || props.isLoading ? 'default' : 'pointer')};
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.white.veryLightGrey : props.theme.colors.white.opaque};
  margin: 0 auto;
  border: ${(props) =>
    getBorder({
      selected: props.isSelected,
      disabled: props.disabled,
      colors: props.theme.colors,
    })};
  width: 100%;
  border-radius: 0.8rem;
  transition: ${(props) => props.theme.animation.transition.default};
  transition-property: box-shadow;

  &:hover:not(:active) {
    ${(props) =>
      !(props.disabled || props.isSelected || props.isLoading) &&
      css`
        box-shadow: 0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2};
      `}
  }
  margin-bottom: 2rem;
  ${(props) => props.theme.components?.RadioSelectMethodOption?.RadioSelectOptionContainer}
`;

const MethodInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const PaymentInfoAdditionalText = styled.span`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.regular};
  white-space: nowrap;
`;
