import { useEffect } from 'react';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import SuccessImage from 'src/images/core/success-icon.svg';
import CalendarImage from 'src/images/icons/calendar-icon.svg';
import Mastercard from 'src/images/icons/mastercard.svg';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { analytics } from 'src/services/analytics';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardRecoverSuccessView = ({ state, analyticsProperties }: Props) => {
  const { organization, payment, virtualCard } = state;
  const eventPage = 'virtual-card-details';
  useEffect(() => {
    if (virtualCard) {
      analytics.track(eventPage, 'recover-success', {
        virtualCardId: virtualCard?.id,
        ...analyticsProperties,
      });
    }
  }, [analyticsProperties, virtualCard]);

  return (
    <ContentWrapper>
      <Image width={8} height={8} src={SuccessImage} />
      <Box textStyle="body2Semi" mt={4} mb={2} data-testid="title">
        <MIFormattedText label="vendors.virtualCard.recoverSuccess.title" />
      </Box>
      <Box textStyle="body2" data-testid="description">
        <MIFormattedText
          label="vendors.virtualCard.recoverSuccess.description"
          values={{
            payorName: organization?.companyName,
            amount: <MIFormattedCurrency value={payment?.amount} />,
          }}
        />
      </Box>
      <Box textStyle="body4Uppercase" color="grey.700" mt={5} mb={4}>
        <MIFormattedText label="vendors.virtualCard.recoverSuccess.youWillReceive" />
      </Box>
      <Flex align="center" borderBottom="1px" borderColor="grey.400" pb={5}>
        <Image width={6} height={5} src={Mastercard} />
        <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
          <Box textStyle="body4Semi" color="grey.600">
            <MIFormattedText label="vendors.virtualCard.recoverSuccess.singleUse" />
          </Box>
          <Box textStyle="body2" isTruncated>
            {payment?.deliveryMethod?.virtualCardAccount?.accountEmail}
          </Box>
        </Box>
      </Flex>
      <Flex align="center" pt={5}>
        <Image width={6} height={6} src={CalendarImage} />
        <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
          <Box textStyle="body4Semi" color="grey.600">
            <MIFormattedText label="vendors.virtualCard.recoverSuccess.deliveryOn" />
          </Box>
          <Box textStyle="body2" isTruncated>
            <MIFormattedDate date={payment?.deliveryEta} />
          </Box>
        </Box>
      </Flex>
    </ContentWrapper>
  );
};
