import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

type Props = {
  isEnabled: boolean;
  onClick: VoidFunction;
};

export const DebitCardPromotion = ({ isEnabled, onClick }: Props) => {
  const handleDebitCardPromotionClick = () => {
    if (isEnabled) {
      onClick();
    }
  };

  return (
    <Flex width="full" bgColor="grey.200" borderRadius="lg" p={4} mt={10} boxSizing="border-box" align="center">
      <Icon name={IconNames.debitCard} size={IconSize.lg} />
      <Box textStyle="body3" color="black" ml={4}>
        <MIFormattedText
          label="vendors.upgradePayment.p2dPromotion.message"
          values={{
            link: (
              <Box
                as="span"
                color="primary.500"
                onClick={handleDebitCardPromotionClick}
                data-testid="label-vendors.upgradePayment.p2dPromotion.link"
                cursor={isEnabled ? 'pointer' : 'not-allowed'}
                opacity={isEnabled ? 1 : 0.5}
              >
                <MIFormattedText label="vendors.upgradePayment.p2dPromotion.link" />
              </Box>
            ),
          }}
        />
      </Box>
    </Flex>
  );
};
