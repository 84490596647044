import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

type FooterTextProps = {
  submit: () => void;
  isComingFromVendorDetails: boolean;
  isLoading: boolean;
};

const FooterText = ({ isComingFromVendorDetails, submit, isLoading }: FooterTextProps) => (
  <>
    <Box mb={4}>
      <Button
        size={ButtonSizes.lg}
        variant={ButtonVariants.primary}
        onClick={submit}
        label="progress.continue"
        isLoading={isLoading}
      />
    </Box>
    {!isComingFromVendorDetails && (
      <Box alignItems="left">
        <WizardElements.SimpleTextFooter>
          <MIFormattedText label="vendors.deliveryMethods.international.billing.footer" />
        </WizardElements.SimpleTextFooter>
      </Box>
    )}
  </>
);

type ExpandedFooterProps = {
  submit: (value, onSubmitChanges?) => void;
  isLoading: boolean;
};

const ExpandedFooter = ({ submit, isLoading }: ExpandedFooterProps) => (
  <Flex direction="column" mt={4} w="100%">
    <Button
      size={ButtonSizes.lg}
      variant={ButtonVariants.primary}
      onClick={(value) => submit(value, { shouldRedirectToAddBill: true })}
      label="vendors.deliveryMethods.international.saveAndAddBill"
      mb={2}
      isLoading={isLoading}
    />
    <Button
      size={ButtonSizes.lg}
      variant={ButtonVariants.tertiaryNaked}
      onClick={(value) => submit(value)}
      label="vendors.deliveryMethods.international.save"
    />
  </Flex>
);

export type ShowFooterProps = {
  isComingFromVendorDetails: boolean;
  shouldShowAddBillButton?: boolean;
};

type FooterProps = {
  isLoading: boolean;
  submit: () => void;
  showFooterProps: ShowFooterProps;
};

const Footer = ({ showFooterProps, submit, isLoading }: FooterProps) => (
  <>
    {showFooterProps.shouldShowAddBillButton && showFooterProps.isComingFromVendorDetails ? (
      <ExpandedFooter submit={submit} isLoading={isLoading} />
    ) : (
      <FooterText
        isComingFromVendorDetails={showFooterProps.isComingFromVendorDetails}
        submit={submit}
        isLoading={isLoading}
      />
    )}
  </>
);

export { Footer, FooterText };
