import { AmexNotificationDetails, CardResponse, TransactionResponse } from '@melio/spend-management-api-axios-client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { FullPageLayout } from 'src/components/layout/FullPageLayout';
import Box from 'src/core/ds/box';
import { Drawer, DrawerContent, DrawerOverlay } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';
import { HStack } from 'src/core/ds/stack';
import { Tabs } from 'src/core/ds/tabs';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { isSuccessfulEnrollment, useAccounts } from 'src/modules/spend-management/accounts/hooks/useAccounts';
import { useCards } from 'src/modules/spend-management/cards/hooks/useCards';
import { useDeleteCard } from 'src/modules/spend-management/cards/hooks/useDeleteCard';
import { useTransactions } from 'src/modules/spend-management/transactions/hooks/useTransactions';
import { tabsContainerStyle } from 'src/pages/get-pro/components/tabs/styles';
import { CardsEmptyState } from 'src/pages/spend-management/components/empty-states/CardsEmptyState';
import { EnrollEmptyState } from 'src/pages/spend-management/components/empty-states/EnrollEmptyState';
import { InstallEmptyState } from 'src/pages/spend-management/components/empty-states/InstallEmptyState';
import { SuccessfullyEnrolledEmptyState } from 'src/pages/spend-management/components/empty-states/SuccessfullyEnrolledEmptyState';
import { TransactionsEmptyState } from 'src/pages/spend-management/components/empty-states/TransactionsEmptyState';
import { CardDetails } from 'src/pages/spend-management/components/table/components/CardDetails';
import { CreateCard } from 'src/pages/spend-management/components/table/components/CreateCard';
import { useExtension } from 'src/pages/spend-management/hooks/useExtension';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import Header from './components/header/Header';
import CardsTable from './components/table/components/CardsTable';
import { TransactionDetails } from './components/table/components/TransactionDetails';
import TransactionsTable, { DateTableRowType } from './components/table/TransactionsTable';
import { analytics, Context, Event, Page } from './SpendManagementAnalytics';
import { headerStyle, pageContentStyle, tabsStyle } from './styles';

declare global {
  interface Window {
    isAmexExtensionInstalled?: true;
  }
}

const CARDS_REFETCH_INTERVAL = 30 * 1000;

enum Tab {
  TRANSACTIONS = 'transactions',
  CARDS = 'cards',
}
const TABS: Tab[] = [Tab.CARDS, Tab.TRANSACTIONS];

const tabLocations = {
  [Tab.CARDS]: spendManagementLocations.cardsList,
  [Tab.TRANSACTIONS]: spendManagementLocations.transactionsList,
};

const tabPages = {
  [Tab.CARDS]: Page.CARD_LIST,
  [Tab.TRANSACTIONS]: Page.TRANSACTION_LIST,
};

const Content = ({
  accounts,
  transactions,
  cards,
  isTabSelected,
  justEnrolled,
  onEnrolled,
  onTransactionClick,
  onCardClick,
  onInstallExtensionClick,
  onAddCardClick,
}: {
  accounts: AmexNotificationDetails[];
  transactions: TransactionResponse[];
  cards: CardResponse[];
  justEnrolled: boolean;
  isTabSelected: (tab: Tab) => boolean;
  onEnrolled: () => void;
  onTransactionClick: (transaction: DateTableRowType) => void;
  onCardClick: (card: CardResponse) => void;
  onInstallExtensionClick: () => void;
  onAddCardClick: () => void;
}) => {
  const { isExtensionInstalled } = useExtension(); // todo: validate extension version >= minimum version and display message accordingly

  if (!accounts.length) {
    return <EnrollEmptyState onEnrolled={onEnrolled} />;
  }

  if (isTabSelected(Tab.TRANSACTIONS)) {
    if (transactions.length)
      return <TransactionsTable transactions={transactions} onTransactionClicked={onTransactionClick} />;
  } else if (cards.length) return <CardsTable cards={cards} onCardClicked={onCardClick} />;

  if (cards.length) {
    return <TransactionsEmptyState />;
  }

  if (justEnrolled) {
    return (
      <SuccessfullyEnrolledEmptyState
        onInstallExtensionClick={onInstallExtensionClick}
        onAddCardClick={onAddCardClick}
      />
    );
  }

  if (!isExtensionInstalled) {
    return <InstallEmptyState onInstallExtensionClick={onInstallExtensionClick} onAddCardClick={onAddCardClick} />;
  }

  return <CardsEmptyState onAddCardClick={onAddCardClick} />;
};

export const SpendManagementPage = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.CARDS);
  const [selectedCard, setSelectedCard] = useState<CardResponse | null>(null);
  const [selectedTransaction, setSelectedTransaction] = useState<DateTableRowType | null>(null);
  const [showAddCard, setShowAddCard] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const { accounts, refetch: refetchAccounts, loading: loadingAccounts } = useAccounts({});
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [justEnrolled, setJustEnrolled] = useState(false);
  const isTabSelected = useCallback((tab: Tab) => selectedTab === tab, [selectedTab]);
  const { transactions } = useTransactions({});
  const { cards, refetch: refetchCards } = useCards({});
  const { deleteCard, loading: deleteCardLoading } = useDeleteCard();
  const [historyPush] = useHistoryWithOrgId();
  const { webStoreUrl, webStoreUrlParams, extensionLoading } = useExtension(); // todo: validate extension version >= minimum version and display message accordingly
  const activeAccounts = !loadingAccounts && accounts?.filter(isSuccessfulEnrollment);
  const isActiveAccount = !!activeAccounts?.[0];

  const { path } = useRouteMatch();

  useEffect(() => {
    TABS.forEach((tab) => {
      if (path === tabLocations[tab]) setSelectedTab(tab);
    });
  }, [path]);

  useEffect(() => {
    let interval;

    if (isActiveAccount) {
      interval = setInterval(async () => {
        refetchCards({});
      }, CARDS_REFETCH_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [isActiveAccount, refetchCards]);

  const onEnrolled = () => {
    setJustEnrolled(true);
    refetchAccounts({});

    if (activeAccounts && activeAccounts.length > 0) {
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'spendManagement.pages.enroll.toasts.successEnrollment',
      });
    }
  };

  const hideCreateCard = () => {
    setShowAddCard(false);
  };
  const onAddCardClick = ({ emptyState }: { emptyState?: boolean } = {}) => {
    analytics.track(
      tabPages[selectedTab],
      Context[emptyState ? 'EMPTY_STATE' : 'VIRTUAL_CARDS'],
      Event.CLICK_CREATE_CARD
    );
    setShowAddCard(true);
  };
  const onCreateCardSuccess = () => {
    hideCreateCard();
    refetchCards({});
  };
  const onCardClick = (card: CardResponse) => {
    analytics.track(Page.CARD_LIST, Context.VIRTUAL_CARDS, Event.CLICK_CARD);
    setSelectedCard(card);
    setShowCardDetails(true);
  };
  const hideCardDetails = () => {
    setSelectedCard(null);
    setShowCardDetails(false);
  };

  const onTransactionClick = (transaction: DateTableRowType) => {
    analytics.track(Page.TRANSACTION_LIST, Context.TRANSACTIONS, Event.CLICK_CARD);
    setSelectedTransaction(transaction);
    setShowTransactionDetails(true);
  };
  const hideTransactionDetails = () => {
    setSelectedTransaction(null);
    setShowTransactionDetails(false);
  };

  const onDeleteCard = async (cardId: string) => {
    try {
      await deleteCard({ cardId });
      analytics.track(tabPages[selectedTab], Context.CARD_DETAILS_DRAWER_TERMINATE_CARD_MODAL, Event.CONTINUE_SUCCESS);
    } catch (e) {
      analytics.track(tabPages[selectedTab], Context.CARD_DETAILS_DRAWER_TERMINATE_CARD_MODAL, Event.CONTINUE_FAILED);
    }

    hideCardDetails();
    refetchCards({});
  };

  const onShowTransactions = (card: CardResponse) => {
    hideCardDetails();
    setSelectedTab(Tab.TRANSACTIONS);

    analytics.track(Page.TRANSACTION_LIST, Context.TRANSACTIONS, Event.CLICK_TAB_TRANSACTIONS);
    historyPush({
      path: tabLocations[Tab.TRANSACTIONS],
      query: {
        cardId: card.cardId,
      },
    });
  };

  const onInstallExtensionClick = () => {
    analytics.track(tabPages[selectedTab], Context.EMPTY_STATE, Event.CLICK_INSTALL, undefined, undefined, () => {
      window.open(`${webStoreUrl}${webStoreUrlParams('dashboard')}`, '_blank');
    });
  };

  if (loadingAccounts || !activeAccounts || !transactions || !cards || extensionLoading) return <AreaLoader />;

  return (
    <FullPageLayout noPadding>
      <Flex direction="column" h="full" ref={pageRef} data-testid="spend-management-card-page">
        <Box __css={headerStyle}>
          <Header
            accounts={activeAccounts}
            pageName={tabPages[selectedTab]}
            onAddCardClicked={() => onAddCardClick()}
            onEnrolled={onEnrolled}
          />
        </Box>

        <Flex direction="column" __css={pageContentStyle}>
          <Box __css={tabsStyle}>
            <HStack spacing={0} __css={tabsContainerStyle}>
              <Tabs
                data-testid="spend-management-tabs"
                tabsData={TABS.map((tab) => ({
                  to: {},
                  label: `spendManagement.pages.dashboard.tabs.${tab}`,
                  onClick: () => {
                    analytics.track(
                      tabPages[selectedTab],
                      Context[tab === Tab.CARDS ? 'VIRTUAL_CARDS' : 'TRANSACTIONS'],
                      tab === Tab.CARDS ? Event.CLICK_TAB_CARDS : Event.CLICK_TAB_TRANSACTIONS
                    );
                    historyPush({ path: tabLocations[tab] });
                  },
                  trackEvent: { page: '', name: '', properties: {} },
                }))}
                activeTabIndex={TABS.findIndex((tab) => selectedTab === tab)}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                index={TABS.findIndex((tab) => selectedTab === tab)}
              />
            </HStack>
          </Box>
          <Flex flexGrow={1} w="full">
            <Content
              accounts={activeAccounts}
              transactions={transactions}
              cards={cards}
              justEnrolled={justEnrolled}
              isTabSelected={isTabSelected}
              onEnrolled={onEnrolled}
              onAddCardClick={onAddCardClick}
              onTransactionClick={onTransactionClick}
              onInstallExtensionClick={onInstallExtensionClick}
              onCardClick={onCardClick}
            />
          </Flex>
        </Flex>
      </Flex>
      {showAddCard && (
        <Drawer
          isOpen={showAddCard}
          onClose={hideCreateCard}
          onOverlayClick={() => analytics.track(tabPages[selectedTab], Context.NEW_CARD_DRAWER, Event.CLICK_CLOSE_BG)}
          closeOnOverlayClick
        >
          <DrawerOverlay>
            <DrawerContent maxW="45rem">
              <CreateCard accounts={activeAccounts} onSuccess={onCreateCardSuccess} />
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}
      {showCardDetails && selectedCard && (
        <Drawer
          isOpen={showCardDetails}
          onClose={hideCardDetails}
          onOverlayClick={() => analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.CLICK_CLOSE_BG)}
          closeOnOverlayClick
        >
          <DrawerOverlay>
            <DrawerContent maxW="45rem">
              <CardDetails
                card={selectedCard}
                loading={deleteCardLoading}
                onDeleteCard={onDeleteCard}
                onShowTransactions={onShowTransactions}
              />
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}
      {showTransactionDetails && selectedTransaction && (
        <Drawer
          isOpen={showTransactionDetails}
          onClose={hideTransactionDetails}
          onOverlayClick={() =>
            analytics.track(Page.TRANSACTION_LIST, Context.TRANSACTION_DETAILS_DRAWER, Event.CLICK_CLOSE_BG)
          }
        >
          <DrawerOverlay>
            <DrawerContent maxW="45rem">
              <TransactionDetails transaction={selectedTransaction} onClose={hideTransactionDetails} />
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      )}
    </FullPageLayout>
  );
};
