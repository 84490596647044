import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { ValidationError } from 'src/ui/ValidationError';
import { MsnBusinessTypeEnum } from 'src/utils/types';
import { CandidatesDetailsModelType } from './CandidatesDetailsForm';

const TAX_ID_REGEX = /^[0-9]{9}$|^[0-9]{3}-[0-9]{2}-[0-9]{4}$|^[0-9]{2}-[0-9]{7}$/;

const validateTaxFields = ({ taxId, taxIdType }: Partial<CandidatesDetailsModelType>) => {
  const emptyTaxId = isEmpty(taxId);
  const emptyTaxIdType = isEmpty(taxIdType);
  const validateErrors: { taxId?: string; taxIdType?: string } = {};

  if (!emptyTaxId) {
    if (!TAX_ID_REGEX.test(taxId as string)) {
      validateErrors.taxId = 'vendors.candidates.form.validation.taxId.invalid';
    }

    if (emptyTaxIdType) {
      validateErrors.taxIdType = 'vendors.candidates.form.validation.taxIdType.empty';
    }
  }

  return validateErrors;
};

export const validateCandidatesDetails = (value: CandidatesDetailsModelType, hasCapLimitSet) => {
  const isBankAccountRequired =
    hasCapLimitSet || value.msnBusinessType === MsnBusinessTypeEnum.PART_OF_A_LARGER_ORGANIZATION;

  const companyInfo = pickBy({
    phone: value.phone,
    googlePlaceId: value.googlePlaceId,
    contactFirstName: value.contactFirstName,
    contactLastName: value.contactLastName,
    companyName: value.companyName,
  });

  const optionalInfo = pickBy({
    accountNumber: value.accountNumber,
    routingNumber: value.routingNumber,
    phoneExt: value.phoneExt,
  });

  let optionalInfoValidationErrors = getValidationErrors('virtualCardCandidate', optionalInfo);

  optionalInfoValidationErrors = mapValues(
    optionalInfoValidationErrors,
    (_error, key) => `vendors.virtualCard.candidates.form.validation.${key}`
  );

  if (isBankAccountRequired && isEmpty(value.accountNumber)) {
    optionalInfoValidationErrors = {
      ...optionalInfoValidationErrors,
      accountNumber: 'vendors.virtualCard.candidates.form.validation.accountNumberRequired',
    };
  }

  if (isBankAccountRequired && isEmpty(value.routingNumber)) {
    optionalInfoValidationErrors = {
      ...optionalInfoValidationErrors,
      routingNumber: 'vendors.virtualCard.candidates.form.validation.routingNumberRequired',
    };
  }

  const companyInfoValidationErrors = getValidationErrors('companyInfo', companyInfo, [
    'phone',
    'googlePlaceId',
    'companyName',
    'contactFirstName',
    'contactLastName',
  ]);

  let emailValidationErrors = {};

  if (isEmpty(value.email)) {
    emailValidationErrors = { email: 'vendors.candidates.form.validation.email.empty' };
  } else if (!isEmpty(getValidationErrors('userRegistration', { email: value.email }))) {
    emailValidationErrors = { email: 'vendors.candidates.form.validation.email.invalid' };
  }

  let emailConfirmationErrors = {};

  if (isEmpty(value.emailConfirmation)) {
    emailConfirmationErrors = { emailConfirmation: 'vendors.candidates.form.validation.emailConfirmation.empty' };
  } else if (!isEmpty(value.email) && value.emailConfirmation !== value.email) {
    emailConfirmationErrors = { emailConfirmation: 'vendors.candidates.form.validation.emailConfirmation.invalid' };
  }

  const msnBusinessTypeErrors: { msnBusinessType?: string } = {};

  if (isEmpty(value.msnBusinessType)) {
    msnBusinessTypeErrors.msnBusinessType = 'vendors.virtualCard.candidates.form.validation.fcbStatus';
  }

  const taxFieldsErrors = validateTaxFields(value);

  const validationErrors = {
    ...optionalInfoValidationErrors,
    ...emailValidationErrors,
    ...emailConfirmationErrors,
    ...companyInfoValidationErrors,
    ...msnBusinessTypeErrors,
    ...taxFieldsErrors,
  };

  const fieldToMessagesMap = {
    phone: 'vendors.virtualCard.candidates.form.validation.phone',
    googlePlaceId: 'vendors.virtualCard.candidates.form.validation.googlePlaceId',
    contactFirstName: 'vendors.virtualCard.candidates.form.validation.firstName',
    contactLastName: 'vendors.virtualCard.candidates.form.validation.lastName',
    companyName: 'vendors.virtualCard.candidates.form.validation.companyName',
  };

  const errorsWithCorrectMessages = Object.fromEntries(
    Object.entries(validationErrors).map(([field, message]) => [field, fieldToMessagesMap[field] ?? message])
  );

  if (!isValidationOk(validationErrors)) {
    throw new ValidationError({
      validationErrors: errorsWithCorrectMessages,
    });
  }

  return true;
};
