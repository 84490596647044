import { isAfter } from 'date-fns';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { DeliveryOptionType } from 'src/utils/types';
import moment, { TIMEZONE } from './moment-business-days';

type InitialProcessingDatesParams = {
  scheduledDate: Date | null;
  orgId: number;
  deliveryMethodId: number;
  fundingSourceId: number | null;
  amount: number;
  dueDate: string | Date;
  paymentId?: number;
  payBillFlowUUID?: string;
  isFinancingPayment?: boolean;
};

type InitialProcessingDatesResponse = {
  suggestedScheduledDate: Date;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  minScheduledDate: Date;
  maxScheduledDate: Date | null;
  deliveryOptions: DeliveryOptionType[];
};

export const isBusinessDay = (date) => moment(date).isBusinessDay();

export const isHoliday = (date) => moment(date).isHoliday();

export const isSameDay = (date) => moment(date).isSame(moment(), 'day');

export const isTomorrow = (date) => moment(date).startOf('day').isSame(moment().startOf('day').add(1, 'days'));

export const getClosestBusinessDateLessThanOrEqualTo = (date: Date) =>
  isBusinessDay(date) ? date : moment(date).prevBusinessDay().startOf('day').toDate();

export const convertDateToCalendarDate = (date: Date) => moment(date).hour(12);

export const addDaysToCurrentUSCentralDate = (numOfDays: number): Date =>
  new Date(moment().tz(TIMEZONE).add(numOfDays, 'days').format('YYYY/MM/DD'));

export const addBusinessDaysToCurrentUSCentralDate = (numOfDays: number): Date =>
  new Date(moment().tz(TIMEZONE).businessAdd(numOfDays, 'days').format('YYYY/MM/DD'));

export const MAX_FINANCING_SCHEDULE_DATE = 30;
export const MIN_FINANCING_SCHEDULE_DATE = 1;

// TODO: move this function logic to the backend
export const getInitialProcessingDates = async ({
  scheduledDate,
  orgId,
  deliveryMethodId,
  fundingSourceId,
  amount,
  dueDate,
  paymentId,
  payBillFlowUUID,
  isFinancingPayment,
}: InitialProcessingDatesParams): Promise<InitialProcessingDatesResponse> => {
  const minScheduledDateRequest = deliveryApi.getDeliveryTime(
    orgId,
    new Date(),
    deliveryMethodId,
    fundingSourceId,
    amount,
    paymentId,
    payBillFlowUUID,
    isFinancingPayment
  );
  let deliveryDateRequest = minScheduledDateRequest;
  const maxScheduledDate = isFinancingPayment ? addDaysToCurrentUSCentralDate(MAX_FINANCING_SCHEDULE_DATE) : null;

  if (scheduledDate) {
    deliveryDateRequest = deliveryApi.getDeliveryTime(
      orgId,
      scheduledDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID,
      isFinancingPayment
    );
  } else if (isFinancingPayment && maxScheduledDate && isAfter(new Date(dueDate), maxScheduledDate)) {
    deliveryDateRequest = deliveryApi.getDeliveryTime(
      orgId,
      getClosestBusinessDateLessThanOrEqualTo(convertDateToCalendarDate(maxScheduledDate).toDate()),
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID,
      isFinancingPayment
    );
  } else if (dueDate) {
    deliveryDateRequest = deliveryApi.getLatestProcessingDate(
      orgId,
      new Date(dueDate),
      deliveryMethodId,
      fundingSourceId,
      amount,
      undefined,
      payBillFlowUUID,
      isFinancingPayment
    );
  }

  const [
    { suggestedScheduledDate: minScheduledDate },
    { suggestedScheduledDate, deliveryDate, maxDeliveryDate, deliveryOptions },
  ] = await Promise.all([minScheduledDateRequest, deliveryDateRequest]);

  return {
    suggestedScheduledDate,
    deliveryDate,
    maxDeliveryDate,
    minScheduledDate: isFinancingPayment
      ? addBusinessDaysToCurrentUSCentralDate(MIN_FINANCING_SCHEDULE_DATE)
      : minScheduledDate,
    maxScheduledDate,
    deliveryOptions,
  };
};
