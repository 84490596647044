import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { deleteFundingSourceAction } from 'src/redux/user/actions';
import { getFundingSources, getIsFundingSourceDeleting, getIsLoading, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { AddFundingSourceWizardOrigin, FundingType } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { AccountType } from 'src/utils/types';
import { onboardingLocations } from '../onboarding/locations';
import { PaymentMethodsSettingsPage } from './components/PaymentMethodsPage/PaymentMethodsSettingsPage';
import { settingsLocations } from './locations';

const eventPage = 'settings-payment-methods';

export type EditFundingSourceLabelState = {
  id: number;
  currentNickname?: string | null;
} | null;

export type DeleteFundingSourceErrorData = {
  payments?: number[];
  failedPayments?: number[];
} | null;

export const PaymentMethodsSettingsPageContainer = () => {
  const orgId = useSelector(getOrgId);
  const isLoading = useSelector(getIsLoading);
  const fundingSources = useSelector(getFundingSources);
  const isFundingSourceDeleting = useSelector(getIsFundingSourceDeleting);
  const query = useQueryString();
  const { navigate } = useNavigator();
  const dispatch = useDispatch();
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorData, setErrorData] = useState<DeleteFundingSourceErrorData>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [verifyingId, setVerifyingId] = useState<number | null>(null);
  const [editFundingSourceLabel, setEditFundingSourceLabel] = useState<EditFundingSourceLabelState>(null);

  const onEditLabelClicked = (id: number, currentNickname: string | null) => {
    analytics.trackAction('add-payment-method-label', {
      id,
    });
    setEditFundingSourceLabel({
      id,
      currentNickname,
    });
  };

  const onDeleteClicked = (id: number) => {
    setDeletingId(id);
  };

  const onDeletePaymentMethodCanceled = () => {
    setDeletingId(null);
  };

  const onDeleteFailedError = () => {
    setErrorCode('');
    setErrorData(null);
  };

  const deleteFundingSource = (id: number) =>
    new Promise((resolve, reject) => {
      dispatch(deleteFundingSourceAction(id, resolve, reject));
    });

  const onDeleteConfirmed = () => {
    if (deletingId) {
      deleteFundingSource(deletingId)
        .then(() => {
          setDeletingId(null);
        })
        .catch((error) => {
          setDeletingId(null);
          setErrorCode(error && error.code ? error.code : '');
          setErrorData(error?.responseData);
        });
    }
  };

  const onVerifyClicked = (id: number) => {
    analytics.track(eventPage, 'verify-click');
    setVerifyingId(id);
  };

  const onVerifyFinished = () => {
    analytics.track(eventPage, 'verify-finish');
    setVerifyingId(null);
  };

  const goAddFundingSources = (type: string) => {
    if (type === FundingType.ACH) {
      analytics.track(eventPage, 'add-bank-account');
      navigate(onboardingLocations.fundingSources.bank.addFromSettingsFlow.base, false, {
        redirectUrl: settingsLocations.paymentMethods,
        exitUrl: settingsLocations.paymentMethods,
        preservedState: {
          origin: AddFundingSourceWizardOrigin.SETTINGS,
        },
      });
    } else {
      analytics.track(eventPage, `add-${type}-card`);
      navigate(onboardingLocations.fundingSources.card.index, false, {
        redirectUrl: settingsLocations.paymentMethods,
        exitUrl: settingsLocations.paymentMethods,
      });
    }
  };

  const goEditFundingSource = (fundingSource: AccountType) => {
    analytics.track(eventPage, fundingSource.fundingType === FundingType.ACH ? 'plaid-edit-start' : 'card-edit-start');

    navigate(onboardingLocations.fundingSources.bank.account, false, {
      redirectUrl: settingsLocations.paymentMethods,
      exitUrl: settingsLocations.paymentMethods,
      fundingSourceId: fundingSource.id,
      editMode: true,
    });
  };

  const goViewFundingSource = (fundingSource: AccountType) => {
    analytics.track(eventPage, 'manual-view-start');
    navigate(
      generatePath(onboardingLocations.fundingSources.bank.manuallyView, {
        orgId,
        id: fundingSource.id,
      })
    );
  };

  const resetFundingSourceLabelEdit = () => {
    setEditFundingSourceLabel(null);
  };

  const modifyActions = (fs: AccountType) => ({
    view: () => goViewFundingSource(fs),
    edit: () => goEditFundingSource(fs),
    editLabel: () => onEditLabelClicked(fs.id, fs.nickname),
    delete: () => onDeleteClicked(fs.id),
  });

  useEffect(() => {
    const verifyingId = Number(query.verifyFundingSourceId);
    const fundingSourceToVerify = fundingSources.find((fs) => fs.id === verifyingId);

    if (fundingSourceToVerify?.bankAccount?.canVerify || fundingSourceToVerify?.isVerified) {
      setVerifyingId(verifyingId);
    }
  }, [fundingSources, query.verifyFundingSourceId]);

  const fundingSourceToDelete = fundingSources.filter((fs) => fs.id === deletingId)[0];

  return (
    <PaymentMethodsSettingsPage
      onDeleteConfirmed={onDeleteConfirmed}
      onDeleteFailedError={onDeleteFailedError}
      onDeletePaymentMethodCanceled={onDeletePaymentMethodCanceled}
      goAddFundingSources={goAddFundingSources}
      isSingleLoading={isLoading || isFundingSourceDeleting}
      errorCode={errorCode}
      errorData={errorData}
      deletingId={deletingId}
      editingFundingSourceLabel={editFundingSourceLabel}
      resetFundingSourceLabelEdit={resetFundingSourceLabelEdit}
      fundingSourceToDelete={fundingSourceToDelete}
      fundingSources={fundingSources}
      onVerifyClicked={onVerifyClicked}
      onVerifyFinished={onVerifyFinished}
      verifyingId={verifyingId}
      modifyActions={modifyActions}
    />
  );
};
