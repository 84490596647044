import * as dateFns from 'date-fns';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import dateFromModelValue from 'src/components/modals/DownloadCsvReportModal/modules/dateFromModelValue';
import { useSwitch } from 'src/helpers/react/useSwitch';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import MultipleCompaniesSwitcher from 'src/modules/profile/components/MultipleCompaniesSwitcher';
import { PasswordChangeView } from 'src/modules/profile/components/PasswordChangeView';
import { ProfileDetailsView } from 'src/modules/profile/components/ProfileDetailsView';
import { getProfileActions, profileStore } from 'src/modules/profile/profile-store';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { AccessLevel } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';

export const AccountSettingsPage = () => {
  const { profile, userLoading, changingPassword, getUserRole, accessLevel } = useStructuredSelectors(
    profileStore.selectors
  );
  const actions = getProfileActions(useDispatch());
  const changePassword = useMemo(() => ({ currentPassword: '', password: '', confirmedPassword: '' }), []);
  const [currentlyEditing, { toggleProfile, togglePassword, toggle }] = useSwitch(null, ['Profile', 'Password']);

  const [profileMV, profileActions] = useForm(profile, {
    submit: actions.update,
    onClear: toggle,
  });

  const [changePasswordMV, changePasswordActions] = useForm(changePassword, {
    submit: actions.changePassword,
    onClear: toggle,
  });

  const canAccessOrg = accessLevel !== AccessLevel.NONE;

  const submit = currentlyEditing === 'Profile' ? profileActions.submit : changePasswordActions.submit;
  const cancel = currentlyEditing === 'Profile' ? profileActions.cancel : changePasswordActions.cancel;
  const { dateOfBirth } = profile;
  const usersDateOfBirth = dateOfBirth
    ? dateFns.format(dateFromModelValue(dateOfBirth), DateFormats.monthShortWithDateAndYear)
    : '';

  const isMSNPortalVendor = useIsMsnDashboardDisplay();

  return (
    <AccountPageContainer isEditMode={currentlyEditing}>
      <ProfileDetailsView
        profileMV={profileMV}
        viewOnly={currentlyEditing !== 'Profile'}
        editable={!currentlyEditing}
        toggleViewOnly={toggleProfile}
        role={getUserRole}
        dateOfBirth={usersDateOfBirth}
        showDateOfBirth={!!dateOfBirth}
      />
      {canAccessOrg && !isMSNPortalVendor && <MultipleCompaniesSwitcher />}
      <PasswordChangeView
        model={changePasswordMV}
        viewOnly={currentlyEditing !== 'Password'}
        editable={!currentlyEditing}
        toggleViewOnly={togglePassword}
      />
      {currentlyEditing && (
        <MIFloatedEditDoneButtons
          onDone={submit}
          onCancel={cancel}
          doneLabel="general.save"
          cancelLabel="general.cancel"
          isDisabled={userLoading || changingPassword}
        />
      )}
    </AccountPageContainer>
  );
};

const AccountPageContainer = styled.div<{ isEditMode?: boolean }>`
  @media ${devices.desktop}, ${devices.tablet} {
    margin-bottom: ${(props) => (props.isEditMode ? '11rem' : '0')};
  }
`;
