import { fetchRequest } from 'src/services/api/api';
import { AccountingPlatformBankAccountType, AccountingSoftwareAccountCategory } from 'src/utils/types';

type ListParams = { orgId: number };

type ListAccountingPlatformResponse = {
  accounts?: AccountingSoftwareAccountCategory[];
};

type ListResponse = {
  items: AccountingPlatformBankAccountType[];
};

const list = ({ orgId }: ListParams): Promise<ListResponse> => {
  const url = `/orgs/${orgId}/accounts-payment-options`;

  return fetchRequest(url).then((res: ListAccountingPlatformResponse) => ({
    items: (res.accounts || []).map((account) => ({
      id: account.Id,
      name: account.Name,
      fullyQualifiedName: account.FullyQualifiedName,
      accountSubType: account.AccountSubType,
      accountType: account.AccountType,
      classification: account.Classification,
    })),
  }));
};

export const accountingPlatformBankAccountsApi = {
  list,
};
