import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { generatePath, Switch, useHistory } from 'react-router-dom';
import { UserOrgType } from 'src/modules/invitations/invitations-store';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { billLocations } from 'src/pages/bill/locations';
import AcceptAccountantRequestSuccess from 'src/pages/companies/accept-request/AcceptAccountantRequestSuccess';
import AcceptRequest from 'src/pages/companies/accept-request/AcceptRequest';
import { AcceptRequestSetAmount } from 'src/pages/companies/accept-request/AcceptRequestSetAmount';
import AddFundingSource from 'src/pages/funding-sources/components/AddFundingSource';
import { settingsLocations } from 'src/pages/settings/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/featureFlags';
import { useLocationState } from 'src/utils/hooks';
import { companiesLocations } from '../locations';

export function AcceptRequestRouter() {
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const [invitingUser] = useLocationState<UserOrgType>('invitingUser');
  const orgId = useSelector(getOrgId);
  const [isPlatformAccountantsNewFlowEnabled] = featureFlags.useFeature<boolean>(
    FeatureFlags.PlatformAccountantsNewFlow,
    false
  );

  return (
    <Switch>
      <SmartRoute path={companiesLocations.acceptRequest.successPage}>
        <AcceptAccountantRequestSuccess onNext={() => historyPush({ path: billLocations.index })} />
      </SmartRoute>
      <SmartRoute path={companiesLocations.acceptRequest.setAmount}>
        <AcceptRequestSetAmount
          onNext={(invitingUser, hasFundingSource) =>
            hasFundingSource
              ? historyPush({
                  path: companiesLocations.acceptRequest.successPage,
                  state: {
                    invitingUser,
                  },
                })
              : historyPush({
                  path: companiesLocations.acceptRequest.fundingSource,
                  state: {
                    invitingUser,
                  },
                })
          }
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.acceptRequest.fundingSource}>
        <AddFundingSource
          preservedState={{ invitingUser }}
          relativeStep={2 / 3}
          redirectUrl={generatePath(companiesLocations.acceptRequest.successPage, { orgId })}
          exitUrl={generatePath(companiesLocations.acceptRequest.fundingSource, { orgId })}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.acceptRequest.index}>
        <AcceptRequest
          onSuccess={(invitingUser, invitingOrgId) => {
            if (!invitingUser) {
              return historyPush({ path: billLocations.index });
            }

            if (isPlatformAccountantsNewFlowEnabled) {
              return history.push(
                generatePath(companiesLocations.acceptRequest.successPage, {
                  orgId: invitingOrgId,
                }),
                { invitingUser }
              );
            }

            return history.push(
              generatePath(companiesLocations.acceptRequest.setAmount, {
                orgId: invitingOrgId,
              }),
              { invitingUser }
            );
          }}
          goExit={() => historyPush({ path: billLocations.index })}
          onContactSupport={() => historyPush({ path: settingsLocations.support })}
        />
      </SmartRoute>
    </Switch>
  );
}
