import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { billPayLocations } from 'src/pages/bill/pay/locations';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';

export const EligibilityRejectionPage = () => {
  const { id: billId } = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const { navigate } = useNavigator();
  const goToFundingSourcePage = useCallback(() => {
    navigate(generatePath(billPayLocations.funding, { orgId, billId }), true);
  }, [orgId, billId, navigate]);

  return (
    <Flex height="full" width="full" justifyContent="center" alignItems="center">
      <Flex direction="column" height="full" maxWidth="48.8rem" justifyContent="center" alignItems="center">
        <Box mb={3}>
          <Illustration name={IllustrationName.declined} size={IllustrationSize.lg} />
        </Box>
        <Box textAlign="center" textStyle="h1Semi" mb={2} data-testid="financing-application-rejected-title">
          <MIFormattedText label="financing.errors.rejected.title" />
        </Box>
        <Box textAlign="center" textStyle="body2" mb={12}>
          <MIFormattedText label="financing.errors.rejected.description" />
        </Box>
        <Button
          w="full"
          mb={12}
          label="financing.errors.rejected.cta"
          onClick={goToFundingSourcePage}
          size={ButtonSizes.lg}
          data-testid="financing-application-rejected-cta"
        />
      </Flex>
    </Flex>
  );
};
