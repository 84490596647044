import createRequestImage from 'src/images/general/request-empty-2-state-icon.svg';
import invoiceEmptyStateImage from 'src/images/get-pro/add-invoice-empty-state.svg';
import { InvoicesDateFilters } from 'src/pages/get-pro/consts';
import { GetProTabs } from 'src/utils/consts';

type NoItemsMessageParamsType = {
  tab: GetProTabs;
  filter?: string;
  isConnected: boolean;
  accountingSoftwareName?: string;
  createInvoice: () => void;
  search?: string;
};

type NoFilteredItemsMessageParamsType = {
  tab: GetProTabs;
  filter?: string;
  search?: string;
  customerCompanyName?: string;
};

export const getNoItemsMessage = ({
  tab,
  isConnected,
  accountingSoftwareName,
  createInvoice,
  search,
}: NoItemsMessageParamsType) => {
  if (search) return { exp: 'getPro.emptyState.search' };

  switch (tab) {
    case GetProTabs.INVOICES: {
      if (isConnected) {
        return {
          exp: 'getPro.emptyState.zeroState.invoices.ASConnected',
          expValues: { accountingSoftwareName },
          icon: createRequestImage,
          title: 'getPro.emptyState.zeroState.invoices.ASConnectedTitle',
        };
      }

      return {
        exp: 'getPro.emptyState.zeroState.invoices.ASNotConnected',
        cta: 'getPro.emptyState.zeroState.invoices.cta',
        ctaAction: createInvoice,
        icon: invoiceEmptyStateImage,
        title: 'getPro.emptyState.zeroState.invoices.ASNotConnectedTitle',
      };
    }
    case GetProTabs.IN_PROGRESS: {
      return {
        exp: 'getPro.emptyState.zeroState.inProgress.anyFilter',
      };
    }
    case GetProTabs.PAID: {
      return {
        exp: 'getPro.emptyState.zeroState.paid.anyFilter',
      };
    }
    default: {
      return {
        exp: 'getPro.emptyState.zeroState.requests',
      };
    }
  }
};

export const getNoFilteredItemsMessage = ({
  tab,
  filter,
  search,
  customerCompanyName,
}: NoFilteredItemsMessageParamsType) => {
  if (search) return { exp: 'getPro.emptyState.search' };

  if (customerCompanyName) {
    const forFilter = filter === InvoicesDateFilters.ALL ? filter : 'other';

    return {
      exp: `getPro.emptyState.specificCustomer.${tab}.${forFilter}`,
      expValues: {
        customerName: customerCompanyName,
      },
    };
  }

  return {
    exp: `getPro.emptyState.filter.${tab}.other`,
    expValues: {
      customerName: customerCompanyName,
    },
  };
};

export const getNoInvoicesEverAddedMessage = (connectSupported, createInvoice) => ({
  cta: 'getPro.emptyState.noInvoicesEverAdded.cta',
  ...(connectSupported
    ? {
        title: 'getPro.emptyState.noInvoicesEverAdded.titleConnectSupported',
        exp: 'getPro.emptyState.noInvoicesEverAdded.expConnectSupported',
      }
    : {
        title: 'getPro.emptyState.noInvoicesEverAdded.title',
        exp: 'getPro.emptyState.noInvoicesEverAdded.exp',
        ctaAction: createInvoice,
      }),
});
