import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import {
  AddCardAccountSuccessParams,
  LocationState,
} from 'src/pages/onboarding/funding-sources/card/SetCardAccountsHolderPageContainer';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DbAnalyticsTraits, ServerResponseCodes } from 'src/utils/consts';

type Props = {
  eventPage: string;
  onSuccess({ id, responseCode, cardAccount }: AddCardAccountSuccessParams): void;
  onError(error: any): void;
};

export const useBasisTheoryAddCardAccount = ({ eventPage, onSuccess, onError }: Props) => {
  const orgId = useSelector(getOrgId);
  const { token, cardBin, expiration, digits } = useLocation<LocationState>().state;

  const addCardAccountApiCall = ({ firstName, lastName, address, city, state, zipCode }) =>
    financialAccountsApi
      .createCardAccount(
        orgId,
        {
          cardBin,
          token,
          expiration,
          card4digits: digits,
          firstName,
          lastName,
          address,
          city,
          state,
          zipCode,
        },
        { catchCall: true }
      )
      .then(({ accounts, code }) => {
        const success = code === ServerResponseCodes.OK;
        const cardAccount = get(accounts, '0.cardAccount', null);
        const id = get(accounts, '0.id');

        if (success) {
          const cardTypeEventLabel = cardAccount ? cardAccount.cardType.toLowerCase() : null;
          analytics.track(eventPage, 'done-adding-new-card');
          analytics.trackMqlEvent('added-funding', 'mql');

          if (cardTypeEventLabel) {
            analytics.trackMqlEvent(`added-funding-${cardTypeEventLabel}`, 'mql');
          }

          analytics.setTraits({
            [DbAnalyticsTraits.ADDED_FUNDING]: true,
          });
          analytics.setFundingSourceTraits();
        } else {
          analytics.track(eventPage, 'adding-new-card-error', { code });
        }

        onSuccess({
          id,
          responseCode: code,
          cardAccount,
        });
      })
      .catch((e) => {
        analytics.track(eventPage, 'failed-adding-new-card');
        onError(e.code);
      });

  return { addCardAccountApiCall };
};
