import noop from 'lodash/noop';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useDeliveryOptionsDates } from 'src/pages/bill/pay/hooks/useDeliveryOptionsDates';
import { usePayBillActions } from 'src/pages/bill/pay/hooks/usePayBillActions';
import { useFetchFreeChecks } from 'src/pages/pay/hooks/useFetchFreeChecks';
import { getBill, getExitUrl, getIsRecurring, getPayment, getRedirectUrl } from 'src/redux/payBillWizard/selectors';
import { DeliveryOptionType, ValidationErrors } from 'src/utils/types';

export type DeliveryOptionsDates = {
  suggestedScheduledDate: Date;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  minScheduledDate: Date;
  deliveryOptions: DeliveryOptionType[];
} | null;

export const payBillEventPage = 'pay-bill';

export type PayBillDataContextType = {
  isLoading: boolean;
  validationErrors: ValidationErrors<any>;
  deliveryOptionsDates: DeliveryOptionsDates;
  setIsLoading: (isLoading: boolean) => void;
  setValidationErrors: (errors: ValidationErrors<any>) => void;
  getDeliveryOptionsDates: () => Promise<DeliveryOptionsDates> | null;
  availableFreeChecks?: number;
  fetchFreeChecks: (
    orgId: number
  ) => Promise<{
    total: number;
    available: number;
  }>;
};

export const PayBillDataContext = createContext<PayBillDataContextType>({
  isLoading: false,
  validationErrors: {},
  deliveryOptionsDates: null,
  setIsLoading: () => noop(),
  setValidationErrors: () => noop(),
  getDeliveryOptionsDates: () => null,
  availableFreeChecks: 0,
  fetchFreeChecks: () => Promise.resolve({ total: 0, available: 0 }),
});

export const PayBillDataContextProvider = ({ children }) => {
  const match = useRouteMatch<{ billId: string; paymentId: string }>();
  const { billId, paymentId } = match.params;

  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [deliveryOptionsDates, setDeliveryOptionsDates] = useState<DeliveryOptionsDates>(null);

  const history = useHistory();
  const site = useSiteContext();

  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const isRecurring = useSelector(getIsRecurring);
  const redirectUrl = useSelector(getRedirectUrl);
  const exitUrl = useSelector(getExitUrl);

  const actions = usePayBillActions();

  const [getDeliveryOptionsDates] = useDeliveryOptionsDates({ setIsLoading, setDeliveryOptionsDates });

  const { available: availableFreeChecks, fetchFreeChecks } = useFetchFreeChecks();

  useEffect(() => {
    if ((!bill.id || !billId || bill.id.toString() !== billId.toString()) && !isRecurring) {
      actions.beginRegularPayBillFlow(billId, paymentId, redirectUrl ?? undefined, exitUrl ?? undefined);
    }

    return () => {
      const payBillRelatedRoutesRegexes = {
        payBill: /^\/orgs\/\d+\/bills\/.+\/pay/,
        payRecurringBill: /^\/orgs\/\d+\/bills\/pay\/recurring/,
        editPayment: /^\/orgs\/\d+\/bills\/.+\/edit/,
        addFundingSource: /^\/orgs\/\d+\/welcome\/funding-sources/,
        addDeliveryMethod: /^\/orgs\/\d+\/vendors\/.+\/delivery-method\/.+/,
        companyInfo: /^\/orgs\/\d+\/welcome\/business/,
      };
      const nextLocation = history.location.pathname;
      const willStayInsidePayBillFlow = Object.values(payBillRelatedRoutesRegexes).some((regex: RegExp) =>
        regex.test(nextLocation)
      );

      if (!willStayInsidePayBillFlow && !site.embeddedMode) {
        actions.endPayBillFlow(true);
      }
    };
  }, []);

  useEffect(() => {
    if (payment?.id) {
      getDeliveryOptionsDates();
    }
  }, [payment?.id]);

  return (
    <PayBillDataContext.Provider
      value={{
        isLoading,
        validationErrors,
        deliveryOptionsDates,
        setIsLoading,
        setValidationErrors,
        getDeliveryOptionsDates,
        availableFreeChecks,
        fetchFreeChecks,
      }}
    >
      {children}
    </PayBillDataContext.Provider>
  );
};

export function usePayBillDataContext() {
  return useContext(PayBillDataContext);
}
