import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { billsApi } from 'src/modules/bills/api';
import { billsStore } from 'src/modules/bills/bills-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useBillFiles } from 'src/pages/bill/hooks/useBillFiles';
import { billLocations } from 'src/pages/bill/locations';
import { billFactory } from 'src/pages/bill/records';
import { InvoiceAttachmentField } from 'src/pages/vendor/international-delivery-method/components/InvoiceAttachmentField';
import { InternationalPaymentPurpose } from 'src/pages/vendor/international-delivery-method/consts';
import { fireInternationalAnalyticsOnSubmit } from 'src/pages/vendor/international-delivery-method/event-mapping';
import { useInternationalWizard } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalWizard';
import { useInvoiceAttachmentRequired } from 'src/pages/vendor/international-delivery-method/hooks/useInvoiceAttachmentRequired';
import {
  InternationalPaymentPurposeModel,
  paymentPurposeFormValidator,
} from 'src/pages/vendor/international-delivery-method/payment-purpose/utils';
import { setPaymentPurposeAction } from 'src/redux/payBillWizard/actions';
import { getIsRecurring } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { useForm } from 'src/ui/form';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  vendorId?: number;
};

export const InternationalPaymentPurposePage = ({ vendorId }: Props) => {
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();
  const [billId] = useLocationState('billId', '');
  const isRecurring = useSelector(getIsRecurring);
  const orgId = useSelector(getOrgId);
  const [isBillLoading, setIsBillLoading] = useState<boolean>(false);
  const { wizardState, setWizardState } = useInternationalWizard();
  const bill = useSelector(billsStore.selectors.byId(billId));
  const [isInvoiceAttachmentRequired] = useInvoiceAttachmentRequired(orgId, vendorId, bill);

  const {
    fileName,
    files,
    isUploading,
    isUploadError,
    handleRetry,
    uploadAttachment,
    removeAttachment,
  } = useBillFiles();

  const handleSelectFile = useCallback(
    async (file: File) => {
      await uploadAttachment(file, billFactory(), false);
    },
    [uploadAttachment]
  );

  const options = Object.values(InternationalPaymentPurpose).map((value) => ({
    label: value,
    value,
  }));

  const internationalPaymentPurposeModel = useMemo<InternationalPaymentPurposeModel>(() => {
    const initialFieldSet = {
      purpose: wizardState.purpose || null,
      purposeDescription: wizardState.purposeDescription || '',
    };

    if (isInvoiceAttachmentRequired) {
      return {
        ...initialFieldSet,
        invoiceAttachment: wizardState.invoiceAttachment || false,
      };
    }

    return initialFieldSet;
  }, [isInvoiceAttachmentRequired, wizardState]);

  const [internationalPaymentPurposeMV, { submit }] = useForm<InternationalPaymentPurposeModel>(
    internationalPaymentPurposeModel,
    {
      submit: async (model) => {
        setWizardState((prevState) => ({ ...prevState, ...model }));

        if (isInvoiceAttachmentRequired && !isRecurring && files.length) {
          setIsBillLoading(true);
          await billsApi.editBillById(
            orgId,
            bill.id,
            {
              ...bill,
              files,
            },
            'all'
          );
          setIsBillLoading(false);
        }

        await dispatch(setPaymentPurposeAction(`${model.purpose} ${model.purposeDescription}`));
        const nextUrl = isRecurring
          ? billLocations.pay.recurring.memo
          : generatePath(billLocations.pay.date, {
              orgId,
              billId,
            });
        fireInternationalAnalyticsOnSubmit();
        historyPush({ path: nextUrl });
      },
      validateOnChange: false,
      validator: paymentPurposeFormValidator(isInvoiceAttachmentRequired),
    }
  );

  const onExit = () =>
    historyPush({
      path: billLocations.pay.funding,
      params: { billId },
    });

  return (
    <StepLayoutPage
      title="vendors.deliveryMethods.international.paymentPurpose.title"
      nextLabel="vendors.deliveryMethods.international.paymentPurpose.continue"
      onSubmit={submit}
      goExit={onExit}
      isLoading={isBillLoading}
    >
      <FormContainer>
        <FormRow>
          <WizardSelectField
            label="vendors.deliveryMethods.international.paymentPurpose.label"
            model={internationalPaymentPurposeMV.purpose}
            placeholder="vendors.deliveryMethods.international.paymentPurpose.labelPlaceholder"
            options={options}
            required
          />
        </FormRow>
        <FormRow>
          <TextField
            testId="input-purposeDescription"
            label="vendors.deliveryMethods.international.paymentPurpose.description"
            placeholder="vendors.deliveryMethods.international.paymentPurpose.descriptionPlaceholder"
            model={internationalPaymentPurposeMV.purposeDescription}
            isRequired
          />
        </FormRow>
        {isInvoiceAttachmentRequired ? (
          <FormRow>
            <InvoiceAttachmentField
              onSelectFile={handleSelectFile}
              onRetry={handleRetry}
              fileName={fileName}
              files={files}
              testId="input-invoiceAttachment"
              isFileUploading={isUploading}
              isFileUploadError={isUploadError}
              onChangeInvoiceFile={handleSelectFile}
              onDeleteInvoiceFile={removeAttachment}
              model={internationalPaymentPurposeMV.invoiceAttachment}
            />
          </FormRow>
        ) : null}
      </FormContainer>
    </StepLayoutPage>
  );
};
