import { useSelector } from 'react-redux';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { CONNECTED_STATUSES } from 'src/modules/accounting-platforms/consts';
import { AccountingPlatform } from 'src/utils/types';

type UseConnectedAccountingPlatformType = {
  isConnected: boolean;
  connectedAccountingPlatform?: AccountingPlatform;
};

export function useConnectedAccountingPlatform(): UseConnectedAccountingPlatformType {
  const connectedAccountingPlatform: AccountingPlatform | undefined = useSelector(
    accountingPlatformsStore.selectors.connectedPlatform
  );

  if (!connectedAccountingPlatform) {
    return {
      isConnected: false,
    };
  }

  const isConnected = Boolean(
    connectedAccountingPlatform.isActive && CONNECTED_STATUSES.includes(connectedAccountingPlatform.syncStatus)
  );

  return {
    isConnected,
    connectedAccountingPlatform,
  };
}
