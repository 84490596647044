import { useConnectedAccountingPlatform } from 'src/hooks';
import { AccountingSoftware, PaymentRequestCreateOrigin } from 'src/utils/consts';
import { isAccountingSoftwareItem } from '../utils';

export function useGetAccountingSoftwareName(
  createOrigin?: PaymentRequestCreateOrigin
): AccountingSoftware | undefined {
  const { isConnected, connectedAccountingPlatform } = useConnectedAccountingPlatform();
  const isAccountingPlatformItem = createOrigin && isAccountingSoftwareItem(createOrigin);

  return isConnected && isAccountingPlatformItem && connectedAccountingPlatform
    ? connectedAccountingPlatform.name
    : undefined;
}
