import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { invoicesApi } from './api';
import { name } from './constants';

type BatchUploadState = {
  createBatch: {
    loading: boolean;
    error?: Error;
  };
};

const invoicesBatchUpload = createApiCallSlice<any, BatchUploadState>({
  name: generateSliceName(name, 'create_batch'),
  api: invoicesApi.createBatch,
  initialState: {
    createBatch: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.createBatch.loading = true;
      state.createBatch.error = undefined;
    },
    [ON_SUCCESS]: (state) => {
      state.createBatch.loading = false;
      state.createBatch.error = undefined;
    },
    [ON_FAILURE]: (state, action) => {
      state.createBatch.loading = false;
      state.createBatch.error = action.error;
    },
  },
  selectors: {
    status: (state) => state[name]?.createBatch,
    loading: (state) => state[name].createBatch.loading,
    error: (state) => state[name].createBatch.error,
  },
});

export default invoicesBatchUpload;
