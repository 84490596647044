import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import qbLogo from 'src/images/accounting-software/quickbooks-logo.svg';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { receivingMethodLocations } from 'src/pages/receiving-method/locations';
import { DisconnectedView } from 'src/pages/settings/components/accounting-software/components/DisconnectedView';
import { InitialView } from 'src/pages/settings/components/accounting-software/components/InitialView';
import { getConnectionErrorType } from 'src/pages/settings/components/accounting-software/components/qbo/utils';
import { getIsIntuitLinked, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { AccountingSoftware } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import { useFetchQBCompanyInfo } from '../../hooks/useFetchQBCompanyInfo';
import { useQBORedirectQueryParams } from '../../hooks/useQBORedirectQueryParams';
import { SyncWithAccountingSoftwareCardProps } from '../../types';
import { QBOConnectedView } from './components/qbo/QBOConnectedView';
import { QBOErrorView } from './components/qbo/QBOErrorView';
import { eventPage } from './consts';

const SyncWithQBOCard = ({ model, isLoading, viewMode, onEdit }: SyncWithAccountingSoftwareCardProps) => {
  const { connectedAccountingPlatform, status: fetchStatus } = useFetchAccountingPlatformsAndData();
  const ownedVendorId = useSelector(getOwnedVendorId);
  const isIntuitLinked = useSelector(getIsIntuitLinked);
  const { QBCompany, error: queryError } = useQBORedirectQueryParams();
  const [connecting, setConnecting] = useState(false);
  const orgId = useSelector(getOrgId);
  const hasQBOAccountingPlatformLink = !isEmpty(connectedAccountingPlatform) && fetchStatus?.loading === false;
  const shouldLoadQBCompanyInfo = !queryError && hasQBOAccountingPlatformLink;
  const { QBOAccount, loading } = useFetchQBCompanyInfo({
    shouldLoadQBCompanyInfo,
    error: queryError,
  });
  const QBCompanyName = QBOAccount?.originObject?.CompanyName || '';

  const goLinkQuickbooks = async () => {
    const returnUrl = `${generatePath(receivingMethodLocations.redirect, {
      orgId,
      vendorId: ownedVendorId || 'vendorId',
    })}?redirect=${RedirectOriginPages.SETTINGS}`;
    analytics.track(eventPage, 'link', {
      type: AccountingSoftware.QUICKBOOKS,
    });
    setConnecting(true);

    try {
      await intuit.goConnectToIntuit({
        intuitReturnUrl: returnUrl,
        intuitErrorReturnUrl: returnUrl,
        linkOrgId: orgId,
      });
    } catch (e) {
      setConnecting(false);
    }
  };

  if (loading) {
    return <AreaLoader />;
  }

  const errorType = getConnectionErrorType(connectedAccountingPlatform?.connectionStatus, queryError);

  if (errorType) {
    return (
      <QBOErrorView errorType={errorType} onLink={goLinkQuickbooks} QBCompany={QBCompany} connecting={connecting} />
    );
  }

  if (isIntuitLinked) {
    return (
      <QBOConnectedView
        QBCompanyName={QBCompanyName}
        model={model}
        isLoading={isLoading}
        viewMode={viewMode}
        onEdit={onEdit}
      />
    );
  }

  if (QBCompanyName) {
    return (
      <DisconnectedView
        companyName={QBCompanyName}
        accountingSoftwareType={AccountingSoftware.QUICKBOOKS}
        logo={qbLogo}
        onLink={goLinkQuickbooks}
      />
    );
  }

  return (
    <InitialView
      accountingSoftwareType={AccountingSoftware.QUICKBOOKS}
      onLink={goLinkQuickbooks}
      logo={qbLogo}
      connecting={connecting}
      subtitle="settings.newAccountingSoftware.Quickbooks.content"
    />
  );
};

export { SyncWithQBOCard };
