import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import dateFromModelValue from 'src/components/modals/DownloadCsvReportModal/modules/dateFromModelValue';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import organizationStore from 'src/modules/organizations/organizations-store';
import usersStore from 'src/modules/users/users-store';
import { LegalInfoForm } from 'src/pages/bill/pay/components/LegalInfoForm';
import { useLegalAddress } from 'src/pages/bill/pay/hooks/useLegalAddress';
import { isCompanyLegalInfoDone as isCompanyLegalInfoDoneCheck } from 'src/pages/settings/records';
import { getCompanyInfo, getOrgId, getProfile } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { convertLegalAddressToGoogleAddress, convertToServerLegalAddress } from 'src/utils/address';
import {
  CompanyFormPage,
  NotificationVariant,
  TaxIdType,
  VENDOR_ONBOARDING_PROGRESS_BAR_STEPS,
  WizardProgressBar,
} from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import { NavigationCallbacks } from 'src/utils/types';
import { getValidationErrorForField, isDateOfBirthRequired, LegalInfoType } from './legalAddressUtils';

type Props = Partial<NavigationCallbacks> & {
  title: string;
  titleValues?: Record<string, any>;
  subtitle: string;
  nextLabel: string;
  skipLabel?: string;
  isLoading?: boolean;
  progressProps?: Partial<{
    relativeStep: number | null;
    minorSteps: number[];
  }>;
  showDateOfBirth?: boolean;
  onSkip?: () => void;
  forceUpdate?: boolean;
};

export const useLegalInfoPage = ({
  onPrev,
  goExit,
  onNext,
  onSkip,
  isLoading,
  progressProps,
  title,
  titleValues,
  subtitle,
  nextLabel,
  skipLabel,
  showDateOfBirth,
  forceUpdate = false,
}: Props) => {
  const dispatch = useDispatch();
  const updateCompanyInfo = getStoreActions(organizationStore)(dispatch).update;
  const orgId: ReturnType<typeof getOrgId> = useSelector(getOrgId);
  const companyInfo: ReturnType<typeof getCompanyInfo> = useSelector(getCompanyInfo);
  const legalAddress = useLegalAddress(companyInfo);

  const { legalCompanyName, taxId, taxIdType } = companyInfo;
  const profile: ReturnType<typeof getProfile> = useSelector(getProfile);
  const userActions = getStoreActions(usersStore)(dispatch);
  const isUserLoading: boolean = useSelector(usersStore.selectors.update.status(profile.id));
  const usersDateOfBirth = profile.dateOfBirth
    ? format(dateFromModelValue(profile.dateOfBirth), DateFormats.dayMonthYearDateSlash)
    : '';
  const isUpdatingCompanyInfo: boolean = useSelector(organizationStore.selectors.update.status(orgId))?.loading;
  const { isConnected } = useFetchAccountingPlatformsAndData();

  const legalInfo = useMemo<LegalInfoType>(
    () => ({
      legalCompanyName,
      taxId,
      taxIdType: taxIdType ?? undefined,
      dateOfBirth: usersDateOfBirth,
      legalGooglePlaceId: legalAddress.legalGooglePlaceId,
    }),
    [legalCompanyName, usersDateOfBirth, legalAddress.legalGooglePlaceId, taxId, taxIdType]
  );

  const [address, setAddress] = useState(convertLegalAddressToGoogleAddress(legalAddress));

  const isCompanyLegalInfoDone = isCompanyLegalInfoDoneCheck({
    ...companyInfo,
    ...legalAddress,
  });

  const isUpdateCompanyInfoAllowed = !isCompanyLegalInfoDone || forceUpdate;

  const [legalMV, legalActions, validationErrors] = useForm<LegalInfoType>(legalInfo, {
    submit: async (values) => {
      // we need to collect dateOfBirth for international payments and if taxIdType is SSN;
      if (values.taxIdType && isDateOfBirthRequired(values.taxIdType, showDateOfBirth)) {
        const dateToUpdate = format(dateFromModelValue(values.dateOfBirth), DateFormats.isoDate);
        await userActions.update({ id: profile.id, dateOfBirth: dateToUpdate });
      }

      if (isUpdateCompanyInfoAllowed) {
        await updateCompanyInfo({
          id: orgId,
          ...values,
          ...convertToServerLegalAddress(address),
        }).catch(({ error }) => {
          pushNotification({
            type: NotificationVariant.ERROR,
            msg: error?.code ? `server.${error?.code}` : 'serverErrors.ERR',
          });
        });
      }

      onNext && onNext(values);
    },
    validator: getValidationErrorForField(address, isCompanyLegalInfoDone, showDateOfBirth),
  });

  const onAddressChange = (address) => {
    setAddress(address);

    if (address.placeId) {
      legalMV.setValidationErrors({ ...validationErrors, legalGooglePlaceId: '' });
    }
  };

  const progressBarType = progressProps?.minorSteps
    ? WizardProgressBar.WITH_SUBSTEPS
    : WizardProgressBar.WITHOUT_SUBSTEPS;

  const isShowDateOfBirth =
    (isConnected && isDateOfBirthRequired(taxIdType, showDateOfBirth)) ||
    isDateOfBirthRequired(legalMV.taxIdType.value, showDateOfBirth);

  const LegalInfoPage = (
    <>
      {isUpdatingCompanyInfo || isUserLoading || isLoading ? (
        <AreaLoader />
      ) : (
        <StepLayoutPage
          title={title}
          titleValues={titleValues}
          subtitle={subtitle}
          subTitleValues={{ companyName: companyInfo.companyName }}
          goExit={goExit}
          onPrev={onPrev}
          onNext={legalActions.submit}
          secondaryActionButtonLabel={skipLabel}
          onSecondaryActionButtonClick={onSkip}
          nextLabel={nextLabel}
          isLoading={isLoading}
          progressBarType={progressBarType}
          progressBarSteps={VENDOR_ONBOARDING_PROGRESS_BAR_STEPS}
          testId="legal-info-page"
          {...(progressProps || {})}
        >
          <LegalInfoForm
            legalCompanyName={legalMV.legalCompanyName.value ?? null}
            address={address}
            onChange={(event) => legalMV[event.id].onChange(event)}
            validationErrors={validationErrors}
            taxId={legalMV.taxId.value}
            taxIdType={legalMV.taxIdType.value}
            dateOfBirth={legalMV.dateOfBirth.value}
            onAddressChange={onAddressChange}
            companyFormPage={CompanyFormPage.ONBOARDING}
            isAutofocus
            isCompanyLegalInfoMissed={isUpdateCompanyInfoAllowed}
            showDateOfBirth={isShowDateOfBirth}
            showTaxIdTypeNotice={legalMV.taxIdType.value === TaxIdType.EIN && showDateOfBirth}
          />
        </StepLayoutPage>
      )}
    </>
  );

  return { LegalInfoPage } as const;
};
