import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';

export function ExtensionSuccessPage() {
  const [historyPush] = useHistoryWithOrgId();
  const goToDashboard = () => {
    analytics.track(Page.EXTENSION_SUCCESS, Context.EXTENSION, Event.CLICK_DASHBOARD);
    historyPush({ path: spendManagementLocations.index });
  };

  return (
    <WizardElements.WizardContainer>
      <WizardElements.WizardInner isCenterVert>
        <Box mb={4} textAlign="center">
          <video
            data-component="Video"
            autoPlay
            muted
            src="https://platform-static.meliopayments.com/assets/melio/illustrations/success.webm"
            width="96px"
          >
            <track kind="captions" />
          </video>
        </Box>
        <Box textAlign="center" maxW="48.8rem" mb={2} textStyle="h1Semi">
          <MIFormattedText label="spendManagement.pages.extensionSuccess.title" />
        </Box>
        <Box textAlign="center" maxW="48.8rem" mb={12} textStyle="body2">
          <MIFormattedText label="spendManagement.pages.extensionSuccess.subtitle" />
        </Box>
        <Flex maxW="48.8rem" justifyContent="center" w="full">
          <Button
            label="spendManagement.pages.extensionSuccess.buttonLabel"
            variant={ButtonVariants.primary}
            onClick={goToDashboard}
          />
        </Flex>
      </WizardElements.WizardInner>
    </WizardElements.WizardContainer>
  );
}
