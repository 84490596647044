import head from 'lodash/head';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import csvExample from 'src/images/invoices/csv-example.svg';
import { ACCEPT_FILES } from 'src/utils/consts';
import { NavigationCallbacks } from 'src/utils/types';
import { useFileInput } from 'src/utils/useFileInput';

export const UploadInvoicesExplainPage = ({ onPrev, goExit, onNext }: NavigationCallbacks) => {
  const handleLoadBatchInvoices = useFileInput(
    (files) => {
      onNext && onNext(head(files));
    },
    { accept: ACCEPT_FILES.TEXT_LISTS, multiple: false }
  );

  return (
    <StepLayoutPage
      title="getPaid.new.create.batchCsvExplain.title"
      subtitle="getPaid.new.create.batchCsvExplain.subtitle"
      nextLabel="getPaid.new.create.batchCsvExplain.action"
      onNext={handleLoadBatchInvoices}
      onPrev={onPrev}
      goExit={goExit}
      fullWidthCTA
    >
      <Image src={csvExample} alt="csv-example" />
    </StepLayoutPage>
  );
};

const Image = styled.img`
  width: 100%;
`;
