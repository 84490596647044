import * as React from 'react';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { GetProDateFilters } from 'src/pages/get-pro/types';
import { menuPlacement } from 'src/pages/pay/components/menu-consts';
import { GetProTabs } from 'src/utils/consts';
import { filterMenuIconStyle } from './styles';
import { OptionType } from './types';

type Props = {
  onChange: (value: GetProDateFilters) => void;
  type: GetProTabs;
  selectedValue?: string;
  options: OptionType[];
};

const FilterMenu: React.FC<Props> = ({ selectedValue, options, onChange, type }) => (
  <Menu placement={menuPlacement} autoSelect={false}>
    <MenuButton
      as={Button}
      variant={ButtonVariants.tertiary}
      rightIcon={<ChevronDownIcon />}
      leftIcon={<Icon name={IconNames.calendar} size={IconSize.s} />}
      size={ButtonSizes.sm}
      __css={filterMenuIconStyle}
      label={`getPro.filter.${type}.${selectedValue || options[0].value}`}
      data-testid="getPro-filter-menu-button"
      skipAnalyticsEvent
    />
    <MenuList>
      {options.map(({ value, option }) => (
        <MenuItem
          key={value}
          onClick={() => onChange(value as GetProDateFilters)}
          as={Button}
          isActive={value === selectedValue}
          label={`getPro.filter.${type}.${value}`}
          analyticsProperties={{ tab: type, filter: value }}
          skipAnalyticsEvent={value === selectedValue}
        >
          {option}
        </MenuItem>
      ))}
    </MenuList>
  </Menu>
);

export default FilterMenu;
