import axios from 'axios';
import { useCallback, useState } from 'react';
import { useMounted } from 'src/hooks/useMounted';

type ReturnType<P extends any[], R> = {
  onApiCall: (...params: P) => Promise<R>;
  result: R | null;
  loading: boolean;
  error: any;
  resetState: () => void;
};

type UseApiProps<P extends any[], R> = {
  api: (...params: P) => Promise<R>;
  throwError?: boolean;
  initialLoading?: boolean;
};

type StateType<R> = {
  loading: boolean;
  error: any;
  result: R | null;
};

export function useApi<P extends any[], R>({
  api,
  throwError = true,
  initialLoading = false,
}: UseApiProps<P, R>): ReturnType<P, R> {
  const { isMounted } = useMounted();

  const [{ loading, error, result }, setState] = useState<StateType<R>>({
    loading: initialLoading,
    error: null,
    result: null,
  });

  const resetState = useCallback(() => {
    if (isMounted()) {
      setState({ loading: false, error: null, result: null });
    }
  }, []);

  const onApiCall = useCallback(
    (...params: P) => {
      setState((prev) => ({ ...prev, loading: true }));

      return api(...params)
        .then((value) => {
          if (isMounted()) {
            setState((prev) => ({ ...prev, loading: false, result: value }));
          }

          return value;
        })
        .catch((error) => {
          if (isMounted()) {
            setState((prev) => ({ ...prev, loading: false }));
          }

          if (axios.isCancel(error)) {
            throw error;
          }

          if (isMounted()) {
            setState((prev) => ({ ...prev, error }));
          }

          if (throwError) {
            if (error instanceof Error) {
              throw error;
            }

            throw new Error(error);
          } else {
            return error;
          }
        });
    },
    [api]
  );

  return {
    onApiCall,
    result,
    loading,
    error,
    resetState,
  };
}
