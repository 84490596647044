import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { IconNames } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { organizationBillingFeeApi } from 'src/modules/organization-billing-fee/api';
import { billingLocations } from '../../locations';

type Props = {
  dismiss: () => void;
  billingFeeId: number;
  billingFee: {
    fundingSourceId: number;
    managedByOrganizationId?: number | null;
    isActive?: boolean;
  };
};

export const UpdateBillingModal = ({ dismiss, billingFeeId, billingFee }: Props) => {
  const currentOrgId = useOrgId();
  const [historyPush] = useHistoryWithOrgId();

  const { onApiCall: updateOrganizationBillingFee, loading: isUpdatingOrgBillingFee } = useApi({
    api: organizationBillingFeeApi.updateBillingFee,
  });

  const handleUpdateClick = async () => {
    await updateOrganizationBillingFee({
      orgId: currentOrgId,
      billingFeeId,
      billingFee,
    });
    historyPush({ path: billingLocations.success, state: { preservedState: { isMethodChanged: true } } });
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen onClose={dismiss} closeOnEsc>
      <Flex data-testid="change-billing-method-modal" direction="column">
        <Modal.CloseButton />
        <Flex align="center" direction="column" marginBlockStart={4}>
          <Modal.Icon name={IconNames.alertFill} color="yellow.500" marginBlockEnd={4} />
          <Modal.HeaderTitle>
            <Box textStyle="h2Semi">
              <MIFormattedText label="billing.changeMethodModal.title" />
            </Box>
          </Modal.HeaderTitle>
          <Box textStyle="body2" marginBlockStart={4} marginBlockEnd={10}>
            <MIFormattedText label="billing.changeMethodModal.description" />
          </Box>
        </Flex>
        <Modal.Footer>
          <Flex w="full" justifyContent="flex-end">
            <Button
              testId="cancel-change-method-button"
              onClick={dismiss}
              variant={ButtonVariants.tertiaryNaked}
              size={ButtonSizes.lg}
              label="general.cancel"
            />
            <Button
              testId="confirm-change-method-button"
              onClick={handleUpdateClick}
              variant={ButtonVariants.primary}
              size={ButtonSizes.lg}
              isLoading={isUpdatingOrgBillingFee}
              label="billing.changeMethodModal.confirm"
            />
          </Flex>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};
