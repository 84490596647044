import qs from 'qs';
import { fetchRequest } from 'src/services/api/api';

export const paymentsInApi = {
  fetch({ orgId, filters }) {
    const url = `/orgs/${orgId}/payments-in?${qs.stringify(filters)}`;

    return fetchRequest(url);
  },
};
