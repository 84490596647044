import * as dateFns from 'date-fns';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useMounted } from 'src/hooks/useMounted';
import { paymentsApi } from 'src/modules/payments/api';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { buildReport } from './buildReport';
import dateFromModelValue from './dateFromModelValue';

export type PaymentsCSVReportData = {
  startDate: string;
  endDate: string;
  includeScheduledPayments?: boolean;
};

type DownloadAndProcess = (data: PaymentsCSVReportData) => Promise<void>;

type UseCsvReportReturnType = {
  csv: any[] | null;
  clearCsv: () => void;
  loading: boolean;
  downloadAndProcess: DownloadAndProcess;
};

export function useCsvReport(): UseCsvReportReturnType {
  const { hasDebitInBankAccounts } = useSiteContext();
  const intl = useIntl();
  const { isMounted } = useMounted();
  const [csv, setCsv] = useState<any[] | null>(null);
  const orgId = useSelector(getOrgId);
  const { onApiCall, loading } = useApi({
    api: paymentsApi.getPaymentsReport,
  });

  const clearCsv = useCallback(() => {
    setCsv(null);
  }, []);

  const downloadAndProcess: DownloadAndProcess = async ({ endDate, startDate, includeScheduledPayments }) => {
    setCsv(null);

    const { payments } = await onApiCall({
      orgId,
      // include the full end day
      filters: {
        startDate: dateFromModelValue(startDate),
        endDate: dateFns.addDays(dateFromModelValue(endDate), 1),
        includeScheduledPayments,
      },
    });

    analytics.trackAction('payments-csv-report.downloaded', {
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      countPayments: payments?.length || 0,
      includeScheduledPayments,
    });

    if (isMounted()) {
      setCsv(buildReport(intl, payments, { hasDebitInBankAccounts }));
    }
  };

  return {
    csv,
    clearCsv,
    loading,
    downloadAndProcess,
  };
}
