import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import { IllustrationName } from 'src/core/ds/illustration';
import { Link } from 'src/core/ds/link';
import { Stack } from 'src/core/ds/stack';
import bankAccountVerifiedStatusProgressBar from 'src/images/onboarding/msn-registration/bank-account-verified-status-progress-bar.svg';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { ImageWithAlt } from 'src/pages/onboarding/msn-registration/public-form/components/ImageWithAlt';
import { getDeliveryMethods, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getIsDeliveryMethodVerifiedByVerificationMethod } from 'src/utils/delivery-methods';
import { HelpFooter } from './HelpFooter';
import { MicroDepositInfo } from './MicroDepositInfo';

const MICRO_DEPOSITS_INFO = [
  { label: 'onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.info1' },
  { label: 'onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.info2' },
  { label: 'onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.info3' },
];
export const DeliveryMethodStatusPage = () => {
  const intl = useIntl();
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const orgId = useSelector(getOrgId);
  const user = useSelector(profileStore?.selectors?.profile);
  const { navigate } = useNavigator();
  const isMsnDashboardDisplay = useIsMsnDashboardDisplay();
  const isDeliveryMethodVerified = getIsDeliveryMethodVerifiedByVerificationMethod(deliveryMethod);
  const analyticsData = {
    msnOptinFlow: true,
    isGuest: user?.isGuest,
    flows: 'public',
    msnVerified: false,
    dmVerified: isDeliveryMethodVerified,
  };
  const learnMoreLink = intl.formatMessage({
    id: 'onboarding.msnCompanyInfo.deliveryMethodStatus.verified.learnMore.link',
  });

  const onClick = () => {
    const redirectRoute = generatePath(`${globalLocations.getPro.base}?status=inProgress`, {
      orgId,
    });
    navigate(redirectRoute);
  };

  const handleLearnMoreClick = () => {
    analytics.track('msn-registration-delivery-method-status-page', 'click-learn-more');
  };

  if (!deliveryMethod || !orgId) return <Redirect to={onboardingLocations.msnRegistration.register} />;

  const { title, text, footer, body } = isDeliveryMethodVerified
    ? {
        title: 'onboarding.msnCompanyInfo.deliveryMethodStatus.verified.title',
        text: 'onboarding.msnCompanyInfo.deliveryMethodStatus.verified.text',
        footer: null,
        body: (
          <ImageWithAlt
            testid="dm-status-image"
            src={bankAccountVerifiedStatusProgressBar}
            altLabel="onboarding.msnCompanyInfo.deliveryMethodStatus.verified.imageAlt"
          />
        ),
      }
    : {
        title: 'onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.title',
        text: null,
        footer: <HelpFooter />,
        body: (
          <Box mt={5}>
            <MicroDepositInfo infoOptions={MICRO_DEPOSITS_INFO} />
          </Box>
        ),
      };

  return (
    <SuccessLayoutPage illustration={IllustrationName.checkCircle} title={title} text={text} footer={footer} hideHeader>
      <Stack spacing={8}>
        {body}
        {isMsnDashboardDisplay ? null : (
          <Box justifyContent="center">
            <Button
              onClick={onClick}
              alignItems="center"
              justifyContent="center"
              label="onboarding.msnCompanyInfo.deliveryMethodStatus.continueButton"
              analyticsProperties={analyticsData}
            />
          </Box>
        )}
        <Box textStyle="body2">
          <MIFormattedText label="onboarding.msnCompanyInfo.deliveryMethodStatus.verified.learnMore.prefix" />
          <Link
            data-testid="delivery-method-status-learn-more-link"
            href={learnMoreLink}
            target="_blank"
            isExternal
            onClick={handleLearnMoreClick}
          >
            <MIFormattedText label="onboarding.msnCompanyInfo.deliveryMethodStatus.verified.learnMore.title" />
          </Link>
        </Box>
      </Stack>
    </SuccessLayoutPage>
  );
};
