import { compose } from 'recompose';
import { withNavigator } from '../../../hoc';
import CompanyContactsForm from './components/CompanyContactsForm';
import { CompanyInfoProps, withCompanyInfoUpdates } from './hoc/withCompanyInfoUpdates';

type Props = {
  locationState: Record<string, any>;
} & CompanyInfoProps;

const CompanyContactsPageContainer = ({
  onNext,
  onPrev,
  onChange,
  companyInfo,
  isLoading,
  validationErrors,
  locationState,
}: Props) => (
  <CompanyContactsForm
    onNext={onNext}
    onPrev={onPrev}
    onChange={onChange}
    companyInfo={companyInfo}
    isLoading={isLoading}
    locationState={locationState}
    validationErrors={validationErrors}
  />
);

export default compose(withNavigator(), withCompanyInfoUpdates())(CompanyContactsPageContainer);
