import { featureFlags } from '@melio/shared-web';
import { AddBankAccountFromGuestWrapper } from 'src/flows/add-bank-account-flow/wrappers/AddBankAccountFromGuestWrapper';
import { AddBankAccountFromPayBatchWrapper } from 'src/flows/add-bank-account-flow/wrappers/AddBankAccountFromPayBatchWrapper';
import { AddBankAccountFromSettingsWrapper } from 'src/flows/add-bank-account-flow/wrappers/AddBankAccountFromSettingsWrapper';
import { AddCardFundingSourceContainer } from 'src/pages/onboarding/funding-sources/card/add-card/basis-theory/AddCardFundingSourceContainer';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/consts';
import { CantFindBankPageContainer } from './bank/CantFindBankPageContainer';
import { CompleteAddingBankAccountsPageContainer } from './bank/CompleteAddingBankAccountsPageContainer';
import { LinkAccountingPlatformAccountToFundingSourcePageContainer } from './bank/LinkAccountingPlatformAccountToFundingSourcePageContainer';
import { SelectBankAccountVerificationTypePageContainer } from './bank/SelectBankAccountVerificationTypePageContainer';
import { SetBankPlaidPageContainer } from './bank/SetBankPlaidPageContainer';
import SetManuallyBankAccountPageContainer from './bank/SetManuallyBankAccountPageContainer';
import ViewManualBankAccountPage from './bank/ViewManualBankAccountPage';
import AddCardAccountsPageContainer from './card/add-card/tabapay/AddCardAccountsPageContainer';
import { SetCardAccountsHolderPageContainer } from './card/SetCardAccountsHolderPageContainer';
import { FundingSourcePageContainer } from './FundingSourcePageContainer';

const eventPage = 'add-funding-source';

export default [
  {
    path: onboardingLocations.fundingSources.index,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'index');

      return <FundingSourcePageContainer {...cProps} />;
    },
  },
  {
    path: onboardingLocations.fundingSources.bank.account,
    exact: true,
    component: LinkAccountingPlatformAccountToFundingSourcePageContainer,
  },
  {
    path: onboardingLocations.fundingSources.bank.plaid,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'bank-plaid');

      return <SetBankPlaidPageContainer {...cProps} />;
    },
  },
  {
    path: onboardingLocations.fundingSources.bank.complete,
    exact: true,
    component: CompleteAddingBankAccountsPageContainer,
  },
  {
    path: onboardingLocations.fundingSources.bank.cantFind,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'bank-cant-find');

      return <CantFindBankPageContainer {...cProps} />;
    },
  },
  {
    path: onboardingLocations.fundingSources.bank.manually,
    exact: true,
    component: SetManuallyBankAccountPageContainer,
  },
  {
    path: onboardingLocations.fundingSources.bank.manuallyView,
    exact: true,
    component: ViewManualBankAccountPage,
  },
  {
    path: onboardingLocations.fundingSources.card.index,
    exact: true,
    component: (cProps) => {
      const [isCardVaultingPullEnabled] = featureFlags.useFeature(FeatureFlags.CardVaultingPull, false);

      analytics.page(eventPage, 'card', { vaulting: isCardVaultingPullEnabled ? 'basis-theory' : 'taba-pay' });

      return isCardVaultingPullEnabled ? (
        <AddCardFundingSourceContainer {...cProps} />
      ) : (
        <AddCardAccountsPageContainer {...cProps} />
      );
    },
  },
  {
    path: onboardingLocations.fundingSources.bank.select,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'bank-verification-type-select');

      return <SelectBankAccountVerificationTypePageContainer {...cProps} />;
    },
  },
  {
    path: onboardingLocations.fundingSources.card.cardholder,
    exact: true,
    component: SetCardAccountsHolderPageContainer,
  },
  {
    path: onboardingLocations.fundingSources.bank.addFromSettingsFlow.index,
    exact: true,
    component: AddBankAccountFromSettingsWrapper,
  },
  {
    path: onboardingLocations.fundingSources.bank.addFromPayBatchFlow.index,
    exact: true,
    component: AddBankAccountFromPayBatchWrapper,
  },
  {
    path: onboardingLocations.fundingSources.bank.addFromGuestFlow.index,
    exact: true,
    component: AddBankAccountFromGuestWrapper,
  },
];
