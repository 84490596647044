import {
  NUM_OF_DISPLAYED_CARDS,
  supportedAccountingSoftwareOptions,
  unsupportedAccountingSoftwareOptions,
} from 'src/pages/get-paid/create/connectAccountingSoftware/consts';
import { AccountingSoftwareOption } from 'src/pages/get-paid/create/connectAccountingSoftware/types';
import { accountingSoftwareDisplayName } from 'src/pages/settings/components/accounting-software/consts';

export const useGetAccountingSoftwareIntegrationOptions = () => {
  const getAccountingSoftwareDropdownOptions = (accountingSoftwareOptions: AccountingSoftwareOption[]) =>
    accountingSoftwareOptions.map(({ accountingSoftware }) => {
      const { displayName } = accountingSoftwareDisplayName[accountingSoftware];

      return {
        label: displayName,
        value: accountingSoftware,
      };
    });

  const getAccountingSoftwareUnsupportedOptions = (accountingSoftwareOptions: AccountingSoftwareOption[]) => {
    const cardOptions = accountingSoftwareOptions.slice(0, NUM_OF_DISPLAYED_CARDS);
    const otherOptions = accountingSoftwareOptions.slice(NUM_OF_DISPLAYED_CARDS);
    const dropdownOptions = getAccountingSoftwareDropdownOptions(otherOptions);

    return { cardOptions, dropdownOptions };
  };

  return {
    accountingSoftwareSupportedOptions: supportedAccountingSoftwareOptions,
    accountingSoftwareUnsupportedOptions: getAccountingSoftwareUnsupportedOptions(unsupportedAccountingSoftwareOptions),
  };
};
