import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'src/components/common/Loader';
import { usePagination } from 'src/core/components/pagination/hooks/usePagination';
import { Pagination } from 'src/core/components/pagination/Pagination';
import Box from 'src/core/ds/box';
import { VStack } from 'src/core/ds/stack';
import { useApi } from 'src/hoc/useApi';
import { paymentsInApi } from 'src/modules/msn-portal-payments-in/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { useQueryString } from 'src/utils/hooks';
import { EVENT_PAGE } from '../consts';
import { msnPortalLocations } from '../locations';
import { mapPaymentsInListToTableRows } from '../utils';
import { DEFAULT_FILTERS_VALUES } from './data-table/consts';
import { EmptyState } from './data-table/EmptyState';
import { MSNMobilePaymentsGroup } from './MSNMobilePaymentsGroup';
import { splitPaymentsByUpdatedAt } from './utils';

export const MSNMobileDashboard = ({ onOpenDrawer }) => {
  const orgId = useSelector(getOrgId);
  const currentQueryParams = useQueryString();
  const [historyPush] = useHistoryWithOrgId();

  const { onApiCall: onFetchPaymentsInList, loading, result } = useApi({ api: paymentsInApi.fetch });

  const getPaymentsInList = useCallback(
    async ({ start, limit }) => {
      try {
        await onFetchPaymentsInList({ orgId, filters: { sort: '', start, limit } }).then(({ totalItems }) => {
          analytics.track(EVENT_PAGE, 'incoming-payments-loaded', { totalPaymentsAmount: totalItems });
        });
      } catch (error: any) {
        capture(error);
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'incomingPayments.errors.paymentsListLoadingError',
        });
      }
    },
    [onFetchPaymentsInList, orgId]
  );

  const { start, limit } = useMemo(
    () => ({
      start: Number(currentQueryParams.start) || DEFAULT_FILTERS_VALUES.start,
      limit: Number(currentQueryParams.limit) || DEFAULT_FILTERS_VALUES.limit,
    }),
    [currentQueryParams]
  );

  const payments = (result?.items || []).map(mapPaymentsInListToTableRows);

  const { statusUpdatedWithinLast7DaysPayments, statusUpdatedEarlierPayments } = splitPaymentsByUpdatedAt(payments);

  const { canNextPage, canPreviousPage, previousPage, nextPage, showPagination, pageIndex } = usePagination({
    start,
    limit,
    totalItems: result?.totalItems,
    setPage: (page) => historyPush({ path: msnPortalLocations.base, query: { limit, start: page * limit } }),
  });

  useEffect(() => {
    getPaymentsInList({ start, limit });
  }, [getPaymentsInList, start, limit]);

  const isPaymentsListEmpty = !statusUpdatedWithinLast7DaysPayments.length && !statusUpdatedEarlierPayments.length;

  return (
    <Box h="full">
      {loading ? (
        <Loader color="primary" context="page" />
      ) : (
        <VStack alignItems="flex-start" spacing={5} p={6}>
          {isPaymentsListEmpty ? (
            <EmptyState />
          ) : (
            <>
              {!!statusUpdatedWithinLast7DaysPayments.length && (
                <MSNMobilePaymentsGroup
                  payments={statusUpdatedWithinLast7DaysPayments}
                  label="incomingPayments.mobile.dashboard.statusUpdatedWithinLast7Days"
                  onOpenDrawer={onOpenDrawer}
                />
              )}
              {!!statusUpdatedEarlierPayments.length && (
                <MSNMobilePaymentsGroup
                  payments={statusUpdatedEarlierPayments}
                  label="incomingPayments.mobile.dashboard.statusUpdatedEarlier"
                  onOpenDrawer={onOpenDrawer}
                />
              )}
              {showPagination && !isPaymentsListEmpty && (
                <Pagination
                  totalItems={result?.totalItems}
                  pageItemCount={limit}
                  pageIndex={pageIndex}
                  canNextPage={canNextPage}
                  canPreviousPage={canPreviousPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                />
              )}
            </>
          )}
        </VStack>
      )}
    </Box>
  );
};
