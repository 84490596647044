import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { ApprovalDecision, BatchApprovalDecisionUpdateResponse, BatchItemStatus } from 'src/modules/payments/api';
import { Status } from 'src/modules/payments/batch-set-approval-decision.slice';
import { paymentsStore } from 'src/modules/payments/payment-store';

type Api = {
  status: Status;
  data?: BatchItemStatus[];
  approve(): Promise<BatchItemStatus[] | undefined>;
  decline(reason: string): Promise<BatchItemStatus[] | undefined>;
};

export function useBatchSetApprovalDecision(ids: Array<string>): Api {
  const { batchSetApprovalDecision } = useStoreActions(paymentsStore);
  const orgId = useOrgId();
  const status: Status = useSelector(paymentsStore.selectors.batchSetApprovalDecision.status);
  const data: BatchItemStatus[] | undefined = useSelector(paymentsStore.selectors.batchSetApprovalDecision.results);

  async function approve() {
    const { payload }: { payload: Partial<BatchApprovalDecisionUpdateResponse> } = await batchSetApprovalDecision({
      orgId,
      decisions: ids.map<ApprovalDecision>((id) => ({ id: Number(id), decision: 'approved' })),
    });

    return payload.statuses;
  }
  async function decline(reason: string) {
    const { payload }: { payload: Partial<BatchApprovalDecisionUpdateResponse> } = await batchSetApprovalDecision({
      orgId,
      decisions: ids.map<ApprovalDecision>((id) => ({ id: Number(id), decision: 'declined', reason })),
    });

    return payload.statuses;
  }

  return { approve, decline, status, data };
}
