import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { authApi } from 'src/modules/auth/api';
import { emailValidation } from 'src/modules/auth/utils';
import { CLEAR_STATE } from 'src/redux/user/actionTypes';

const initialState = {
  registration: {
    loading: false,
    error: null,
    user: null,
  },
};

const registrationReducers = {
  [ON_REQUEST]: (state) => {
    state.registration.loading = true;
    state.registration.error = null;
  },
  [ON_SUCCESS]: (state, action) => {
    state.registration.loading = false;
    state.registration.error = null;
    state.registration.user = action.payload.user;
    state.registration.isFirstTimeVisitedRegularMelio = action.payload.isFirstTimeVisitedRegularMelio;
  },
  [ON_FAILURE]: (state, action) => {
    state.registration.loading = false;
    state.registration.error = action.error || null;
  },
  [CLEAR_STATE]: (state) => {
    state.registration = initialState.registration;
  },
};
export const regularRegistrationSlice = createApiCallSlice({
  name: '[AUTH] REGULAR_REGISTRATION',
  api: authApi.register,
  initialState,
  reducers: registrationReducers,
  validate({ email, password }) {
    return emailValidation(email, password);
  },
});
export const vendorRegistrationSlice = createApiCallSlice({
  name: '[AUTH] VENDOR_REGISTRATION',
  api: authApi.vendorRegister,
  initialState,
  reducers: registrationReducers,
  validate({ email, password }) {
    return emailValidation(email, password);
  },
});
