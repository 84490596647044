import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { NotificationCardTypes } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';
import { getLearnMoreAboutFinancingLink } from '../consts';
import { RenderMessage } from './RenderMessage';

type Props = { payment: PaymentType };

export const RenderScheduleRefundMessage = ({ payment }: Props) => {
  const refundReason = payment.metadata?.refundReason;
  const refundInitiatedDate = payment.metadata?.paymentStatusDates?.refundInitiated;
  const scheduleRefundSubtitle = {
    label: refundReason
      ? 'bills.form.paymentActivity.schedulePaymentDescription'
      : 'bills.form.paymentActivity.schedulePaymentDescriptionPops',
    values: {
      learnMore: getLearnMoreAboutFinancingLink,
      date: <MIFormattedDate date={refundInitiatedDate} />,
    },
  };

  return (
    <RenderMessage
      type={NotificationCardTypes.INFO}
      title={{ label: 'bills.status.paymentRefundScheduled.messageTitle' }}
      subtitle={scheduleRefundSubtitle}
    />
  );
};
