import { CustomFeed } from 'src/components/braze/CustomFeed';
import Box from 'src/core/ds/box';
import { sxForSettingsCard } from 'src/pages/settings/styles';
import { ModelView } from 'src/ui/form';
import { ScreenMode } from 'src/utils/consts';
import { CompanyInfoType, DeliveryMethodType, FieldType, GoogleCombinedAddressType } from 'src/utils/types';
import { CompanyInformation } from './components/CompanyInformation';
import { CompanyLegalInformation } from './components/CompanyLegalInformation';
import { CompanyLogo } from './components/CompanyLogo';
import { PayInbox } from './components/PayInbox';
import { PaymentPage } from './components/PaymentPage';
import { PaymentsReport } from './components/PaymentsReport';
import { TaxForm } from './components/TaxForm';

type Props = {
  validationErrors: Record<string, any>;
  deliveryMethods: readonly DeliveryMethodType[];
  handle: string;
  mode: ScreenMode;
  model: ModelView<Partial<CompanyInfoType>>;
  address: GoogleCombinedAddressType;
  legalAddress: GoogleCombinedAddressType;
  onAddressChange: (address: GoogleCombinedAddressType) => void;
  onLegalAddressChange: (legalAddress: GoogleCombinedAddressType) => void;
  onToggleMode: () => void;
  onGoToPaymentClassificationForm: () => void;
  isLoading: boolean;
  goToManualAddress: () => void;
  editable: boolean;
  inboxEmailAddress?: string | null;
  onCopyEmail: () => void;
  isMSNPortal: boolean;
  onChangeModel: (value: FieldType) => void;
  isBillInboxEnabled?: boolean;
  shouldDisplayZendesk?: boolean;
};

export const CompanySettingsPage = ({
  model,
  isMSNPortal,
  isLoading,
  mode,
  onToggleMode,
  deliveryMethods,
  validationErrors,
  handle,
  onGoToPaymentClassificationForm,
  goToManualAddress,
  editable,
  inboxEmailAddress,
  onCopyEmail,
  onChangeModel,
  onLegalAddressChange,
  onAddressChange,
  legalAddress,
  address,
  isBillInboxEnabled,
  shouldDisplayZendesk,
}: Props) => {
  const isEditMode = mode === ScreenMode.EDIT;

  return (
    <Box mb={{ md: isEditMode ? 20 : 0 }}>
      <CustomFeed feedType="settingscompanysettings_above" sxForCard={sxForSettingsCard} />
      <CompanyInformation
        onToggleMode={onToggleMode}
        isEditMode={isEditMode}
        editable={editable}
        isLoading={isLoading}
        mode={mode}
        model={model}
        address={address}
        onAddressChange={onAddressChange}
        validationErrors={validationErrors}
        goToManualAddress={goToManualAddress}
      />
      {!isMSNPortal ? (
        <PayInbox
          onCopyEmail={onCopyEmail}
          isBillInboxEnabled={isBillInboxEnabled}
          inboxEmailAddress={inboxEmailAddress}
        />
      ) : null}
      <PaymentPage handle={handle} deliveryMethods={deliveryMethods} />
      {!isMSNPortal ? (
        <>
          <PaymentsReport shouldDisplayZendesk={shouldDisplayZendesk} />
          <TaxForm onClick={onGoToPaymentClassificationForm} />
        </>
      ) : null}
      <CompanyLegalInformation
        onToggleMode={onToggleMode}
        isEditMode={isEditMode}
        editable={editable}
        isLoading={isLoading}
        mode={mode}
        model={model}
        legalAddress={legalAddress}
        onLegalAddressChange={onLegalAddressChange}
        onChangeModel={onChangeModel}
        validationErrors={validationErrors}
      />

      <CompanyLogo editable={editable} />
    </Box>
  );
};
