import { featureFlags } from '@melio/shared-web';
import { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { UserBadge } from 'src/components/layout/SideMenu/components/UserBadge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import withOutsideClickHandler from 'src/hoc/withOutsideClickHandler';
import { Logo } from 'src/images/Logo';
import { authApi } from 'src/modules/auth/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { profileStore } from 'src/modules/profile/profile-store';
import { authLocations } from 'src/pages/auth/locations';
import { useLoginFlowType } from 'src/pages/auth/multi-factor-authentication/hooks/useLoginFlowType';
import { settingsLocations } from 'src/pages/settings/locations';
import { clearUserInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { FeatureFlags, LogoFillType } from 'src/utils/consts';
import { MenuItemRenderer } from './components/MenuItemRenderer';
import { MenuItemsSeparator } from './components/MenuItemSeparetor';
import { useSideMenuItems } from './hooks/useSideMenuItems';
import { useSideMenuLogo } from './hooks/useSideMenuLogo';
import { MenuItemKey, MenuItemPropsType } from './types/type';

type Props = {
  isOpen?: boolean;
  onCloseClicked?: () => void;
};

export const SideMenu = ({ onCloseClicked, isOpen = false }: Props) => {
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const [logoFill, setLogoFill] = useState<LogoFillType>(LogoFillType.DARK);
  const { isDesktop, isMobile, isPhablet } = useBreak();
  const dispatch = useDispatch();
  const { clearLoginFlowType } = useLoginFlowType();
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const { logoNavigationPath } = useSideMenuLogo();

  const onLogout = () => {
    analytics.track('side-menu', 'logout');

    authApi.logout().then(() => {
      dispatch(clearUserInfoAction());
      clearLoginFlowType();
      historyPush({ path: authLocations.login });
    });
  };

  const profile = useSelector(profileStore.selectors.profile);
  const companyInfo = useSelector(getCompanyInfo);
  const isNonDesktopMenuOpen = isOpen || !isDesktop;
  const openClassName = isNonDesktopMenuOpen ? 'open' : '';

  const menuItems = useSideMenuItems();

  const goToAccountSettings = () => {
    historyPush({ path: settingsLocations.account });
  };

  const toggleMenu = () => {
    // in desktop click on logo toggles the sidebar (narrow to wide)
    if (isDesktop) {
      analytics.track('side-menu', 'logo-clicked');
      history.push(logoNavigationPath);
      // on mobile, we see the sidebar only when it's open (it has no narrow state),
      // so the click only closes the side menu
    } else {
      onCloseClicked && onCloseClicked();
      analytics.track('hamburger-menu', 'close');
    }
  };

  const shouldShowGroupSeparator = (index: number) =>
    shouldSplitContactsTab && !isMobile && index < menuItems.topMenu.length - 1;

  const shouldShowCompaniesTeamSeparator = (menuItem: MenuItemPropsType) =>
    !shouldSplitContactsTab &&
    !isMobile &&
    (['menu-item-companies', 'menu-item-team'] as MenuItemKey[]).includes(menuItem.key) && <MenuItemsSeparator />;

  return (
    <SideMenuContainer
      data-testid="side-menu"
      handleClickOutside={() => {
        if (isOpen) toggleMenu();
      }}
    >
      <Menu isOpen={isOpen} className={isOpen ? 'open' : ''} data-testid="side-menu-content">
        <MenuTop>
          {isOpen && (isMobile || isPhablet) && <CloseButton onClick={toggleMenu} className="icon-close-icon" />}

          <LogoContainer
            hasAccessToCurrentOrg={false}
            className={openClassName}
            onClick={toggleMenu}
            onMouseEnter={() => setLogoFill(LogoFillType.MELIO)}
            onMouseLeave={() => setLogoFill(LogoFillType.DARK)}
            data-testid="side-menu-app-logo"
          >
            <LogoInnerContainer>
              {!isMobile && <Logo fill={logoFill} isFull={isNonDesktopMenuOpen} />}
            </LogoInnerContainer>
          </LogoContainer>

          {menuItems.topMenu.map((group, groupIndex) => (
            <Fragment key={groupIndex}>
              {group.map((menuItem) => (
                <Fragment key={menuItem.key}>
                  <MenuItemRenderer item={menuItem} isOpen={isOpen} />
                  {shouldShowCompaniesTeamSeparator(menuItem)}
                </Fragment>
              ))}
              {shouldShowGroupSeparator(groupIndex) && <MenuItemsSeparator />}
            </Fragment>
          ))}
        </MenuTop>

        <MenuBottom>
          {!isMobile && <MenuItemsSeparator />}
          {menuItems.bottomMenu.map((group, groupIndex) => (
            <Fragment key={groupIndex}>
              {group.map((menuItem) => (
                <MenuItemRenderer key={menuItem.key} item={menuItem} isOpen={isOpen} />
              ))}
            </Fragment>
          ))}
          <Box>
            <UserBadge
              profilePicUrl={profile?.logoUrl}
              isSideMenuOpen={isOpen}
              firstName={profile?.firstName}
              lastName={profile?.lastName}
              companyName={companyInfo?.companyName ?? null}
              email={profile?.email}
              onLogout={onLogout}
              onAccountSettingsClick={goToAccountSettings}
            />
          </Box>
          {isMobile && (
            <Flex
              p={4}
              w="full"
              boxSizing="border-box"
              alignItems="center"
              justify="space-between"
              border="1px"
              borderColor="grey.400"
            >
              <LogoutButton onClick={onLogout} data-testid="button-logout">
                <LogoutIcon className="icon-logout-icon" />
                <MIFormattedText label="nav.logout" />
              </LogoutButton>
              <Logo fill={LogoFillType.LIGHT} isFull={isOpen} size="mobileSmall" />
            </Flex>
          )}
        </MenuBottom>
      </Menu>
    </SideMenuContainer>
  );
};

const Menu = styled.div<{ isOpen?: boolean }>`
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: visible;
  position: absolute;
  transition: ${(props) => props.theme.animation.transition.menu};
  z-index: ${(props) => props.theme.zIndex.menu};

  @media ${devices.nonDesktop} {
    width: 0;
    overflow-x: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    overflow-y: ${(props) => (props.isOpen ? 'visible' : 'auto')};
  }

  @media ${devices.mobile} {
    &.open {
      width: 100%;
    }
  }

  @media ${devices.phablet} {
    &.open {
      width: 100%;
    }
  }

  @media ${devices.tablet} {
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    &.open {
      width: 24rem;
    }
  }

  @media ${devices.desktop} {
    width: ${(props) => props.theme.sizes.sideMenu};
    border-right: 1px solid ${(props) => props.theme.colors.border.darkGrey};

    &.open {
      width: 24rem;
    }
  }
`;

const MenuTop = styled.div`
  flex-grow: 1;
  position: relative;
  overflow-y: auto;
`;

const CloseButton = styled.i`
  position: absolute;
  top: 2.3rem;
  left: 2.2rem;
  font-size: 2rem;
`;

const MenuBottom = styled.div``;

const LogoInnerContainer = styled.div`
  height: 3.1rem;
  width: auto;
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  &.open {
    width: 11rem;
  }
`;
const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4rem auto 0 auto;
  -webkit-tap-highlight-color: transparent;
  @media ${devices.desktop} {
    margin-top: 0;
    padding-top: 2rem;
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
    margin-bottom: ${(props) => (props.hasAccessToCurrentOrg ? '10rem' : '1.6rem')};
    padding-bottom: 2rem;
  }
`;

const LogoutButton = styled.div`
  color: ${(props) => props.theme.text.color.light};
  font-size: ${(props) => props.theme.text.size.button};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  display: flex;
  align-items: center;
`;

const LogoutIcon = styled.i`
  font-size: 2.4rem;
  margin-right: 1.2rem;
`;

const SideMenuContainer = withOutsideClickHandler(styled.div``);
