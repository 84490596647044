import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import Tooltip, { PlacementType } from 'src/core/ds/tooltip';
import { ReactComponent as SyncIcon } from 'src/images/get-pro/sync-icon.svg';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { AccountingSoftware } from 'src/utils/consts';

type Props = {
  accountingSoftwareName: AccountingSoftware;
  placement?: PlacementType;
};

export function SyncedItemIcon({ accountingSoftwareName, placement = 'top' }: Props) {
  const integrationName = getAccountingSoftwareDisplayName(accountingSoftwareName);

  return (
    <Box as="span" display="inline-block">
      <Tooltip
        placement={placement}
        label={
          <MIFormattedText
            label="getPro.table.item.syncedItemTooltip"
            values={{ name: integrationName?.displayShortName }}
          />
        }
      >
        <Flex
          data-testid="synced-item-indicator"
          as="span"
          borderRadius="50%"
          bg="grey.300"
          p={1}
          justifyContent="center"
          alignItems="center"
        >
          <SyncIcon />
        </Flex>
      </Tooltip>
    </Box>
  );
}
