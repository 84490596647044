import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import { HStack } from 'src/core/ds/stack';

type SuggestedListItemProps = {
  iconLink: string;
  title: string;
  category?: string;
};

const SuggestedListItem = ({ iconLink, title, category }: SuggestedListItemProps) => (
  <HStack {...suggestedListItemContainer} _hover={suggestedListItemContainerHoverStyle}>
    <Box position="relative">
      <Avatar link={iconLink} size={Size.lg} bg="white" />
      <Icon name={IconNames.checkFill} __css={melioDirectoryIndicationImageStyle} />
    </Box>
    <Box>
      <Box textStyle="body3">
        <MIFormattedText label={title} />
      </Box>
      <Box textStyle="caption1" color="grey.600">
        <MIFormattedText label={category} />
      </Box>
    </Box>
  </HStack>
);

const suggestedListItemContainer = {
  borderColor: 'grey.300',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 'lg',
  alignItems: 'center',
  w: 'full',
  px: 2,
  py: 3,
  spacing: 2,
};

const suggestedListItemContainerHoverStyle = {
  background: 'grey.200',
  cursor: 'pointer',
};

const melioDirectoryIndicationImageStyle = {
  width: 4,
  height: 4,
  border: '2px',
  borderColor: 'white',
  borderRadius: 'full',
  top: '2.4rem',
  left: '2.4rem',
  position: 'absolute',
  zIndex: 1,
};

export default SuggestedListItem;
