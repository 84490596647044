import { BlockCreateLinkModalWithDismiss } from 'src/components/common/BlockCreateLinkModal';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useModal } from 'src/helpers/react/useModal';
import { useStartVendorCompany } from 'src/hooks/useStartVendorCompany';
import { CopyLinkSize } from 'src/utils/consts';

type Props = {
  size?: CopyLinkSize;
  origin?: string;
};

export const CreatePaymentLink = ({ size, origin }: Props) => {
  const [BlockCreateLinkMsg, showBlockCreateLink] = useModal(BlockCreateLinkModalWithDismiss, {
    modalName: 'block-create-link',
  });

  const { startVendorOnboarding } = useStartVendorCompany({ onBlockCreateLink: showBlockCreateLink });

  const onGenericLinkButtonClick = () => startVendorOnboarding({ origin });

  return (
    <>
      {BlockCreateLinkMsg}
      <Button
        variant={ButtonVariants.tertiary}
        label="financing.common.createPaymentLink"
        size={size === CopyLinkSize.BIG ? ButtonSizes.lg : ButtonSizes.sm}
        onClick={onGenericLinkButtonClick}
      />
    </>
  );
};
