import get from 'lodash/get';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CodeVerificationPage } from 'src/components/layout/CodeVerificationPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { authApi } from 'src/modules/auth/api';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useSuccessRegisterRedirectUrl } from 'src/pages/auth/email-verification/hooks/useSuccessRegisterRedirectUrl';
import { authLocations } from 'src/pages/auth/locations';
import { setProfileAction } from 'src/redux/user/actions';
import { getOwnedVendorId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CompanyInfoOnboardingOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';

const eventPage = 'registration';
const eventName = 'email-code-verification';

export const CodeVerificationPageContainer = () => {
  const profile = useSelector(getProfile);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const dispatch = useDispatch();
  const [showError, setIsErrorShown] = useState(false);
  const [codeInputKey, setCodeInputKey] = useState(1);
  const [vendorIdToConvertToOwned] = useLocationState('vendorIdToConvertToOwned', '');
  const { navigate } = usePreservedStateNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();
  const { convertLocalToOwned } = useStoreActions(vendorsStore);
  const { successRedirectUrl } = useSuccessRegisterRedirectUrl();
  const isConverting = useSelector(vendorsStore.selectors.convertLocalToOwned.status(vendorIdToConvertToOwned))
    ?.loading;

  const { onApiCall: resolveEmailVerification, loading: isResolving, error: resolveError } = useApi<
    [string, number],
    Record<string, any>
  >({
    api: authApi.resolveEmailVerificationCode,
  });
  const { onApiCall: resendEmailVerificationCode, result: codeResendResult, loading: isResending } = useApi<
    [string],
    Record<string, any>
  >({
    api: authApi.resendEmailVerificationCode,
  });

  const onCodeChange = async (code: string) => {
    if (code.length === 4) await onResolveEmailVerification(Number(code));

    if (code.length === 0) setIsErrorShown(false);
  };

  const onChangeEmail = () => {
    navigate(authLocations.register.changeEmail);
  };

  const onResolveEmailVerification = async (code: number) => {
    try {
      await resolveEmailVerification(profile.email, code);
      analytics.track(eventPage, `${eventName}-correct-code`);
      dispatch(
        setProfileAction({
          ...profile,
          isEmailVerified: true,
        })
      );
      analytics.track(eventPage, `${eventName}-continue-success`);

      if (ownedVendorId && vendorIdToConvertToOwned) {
        await convertLocalToOwned({
          orgId: profile.orgId,
          id: vendorIdToConvertToOwned,
        });
      }

      const isFromPaymentRequestFlow = !!get(locationState, 'preservedState.paymentRequest', null);
      const origin = isFromPaymentRequestFlow
        ? CompanyInfoOnboardingOrigin.PAYMENT_REQUEST
        : CompanyInfoOnboardingOrigin.SETUP;
      navigate(successRedirectUrl, true, { origin });
    } catch (e) {
      if (e) {
        setIsErrorShown(true);
        analytics.track(eventPage, `${eventName}-incorrect-code`);
      }
    }
  };

  const sendEmailVerificationCode = () => {
    resendEmailVerificationCode(profile.email).then(() => {
      setCodeInputKey(codeInputKey + 1);
      setIsErrorShown(false);
    });
  };

  return (
    <CodeVerificationPage
      codeInputKey={codeInputKey}
      email={profile.email}
      errorCode={showError ? resolveError?.code : ''}
      isLoading={isResolving || isResending || isConverting}
      sendEmailVerificationCode={sendEmailVerificationCode}
      onChange={onCodeChange}
      onChangeEmail={onChangeEmail}
      isCodeResend={!!codeResendResult}
    />
  );
};
