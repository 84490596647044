import { BillStatus, PaymentApprovalStatus, PaymentStatus, TagVariant } from 'src/utils/consts';
import { isPaymentCompletedRefund, isPaymentScheduleRefund, isTerminatedPayment } from 'src/utils/payments';
import { BillType, PaymentType } from 'src/utils/types';
import { RenderCompletedRefundMessage } from './components/RenderCompletedRefundMessage';
import { RenderErrorMessage } from './components/RenderErrorMessage';
import { RenderScheduleRefundMessage } from './components/RenderScheduleRefundMessage';
import { RenderTerminatedMessage } from './components/RenderTerminatedMessage';

export type RenderStatusMessageProps = {
  bill: BillType;
  isEmbeddedMode: boolean;
  payment: PaymentType;
  status?: TagVariant | BillStatus | PaymentStatus | PaymentApprovalStatus;
  isSelfServeRefundEnabled: boolean;
  onRefundPayment: (paymentId: number, isSelfServeRefund?: boolean | undefined) => void;
};

export const RenderStatusMessage = ({
  bill,
  isEmbeddedMode,
  payment,
  status,
  isSelfServeRefundEnabled,
  onRefundPayment,
}: RenderStatusMessageProps) => {
  const isFailedPayment = status === PaymentStatus.FAILED;
  const isTerminated = isTerminatedPayment(payment);
  const isCompletedRefund = isPaymentCompletedRefund(payment);
  const isScheduledRefund = isPaymentScheduleRefund(payment);

  if (isTerminated) {
    return <RenderTerminatedMessage />;
  }

  if (isCompletedRefund) {
    return <RenderCompletedRefundMessage payment={payment} />;
  }

  if (isScheduledRefund) {
    return <RenderScheduleRefundMessage payment={payment} />;
  }

  if (isFailedPayment) {
    return (
      <RenderErrorMessage
        payment={payment}
        bill={bill}
        onRefundPayment={onRefundPayment}
        isEmbeddedMode={isEmbeddedMode}
        isSelfServeRefundEnabled={isSelfServeRefundEnabled}
      />
    );
  }

  return null;
};
