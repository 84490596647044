import { Flex, FlexProps } from '@chakra-ui/react';
import { MICard } from 'src/components/common/MICard';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import { double, multiple } from '../styles';

type GalleryProps = {
  onChange: (currentId: string) => void;
  items: string[];
  currentIndex: number;
  children: React.ReactNode;
  isFloatingNavigation?: boolean;
};

export const Gallery: React.FC<GalleryProps> = ({ children, items, onChange, currentIndex, isFloatingNavigation }) => {
  const shouldShowArrows = items.length > 1;

  const prevBill = () => {
    const prev = items[currentIndex - 1];
    onChange(prev || items[items.length - 1]);
  };

  const nextBill = () => {
    const next = items[currentIndex + 1];
    onChange(next || items[0]);
  };

  return (
    <>
      <Flex justify="center" h={items.length === 1 ? 'full' : 'initial'} position="relative">
        {shouldShowArrows && (
          <Flex
            flex="1"
            alignSelf="center"
            justify="center"
            position={isFloatingNavigation ? 'absolute' : 'sticky'}
            left={isFloatingNavigation ? '-3.2rem' : 'initial'}
            top="50%"
          >
            <IconButton
              aria-label="Bills gallery prev bill"
              onClick={prevBill}
              color="grey.700"
              icon={<Icon name={IconNames.shevronLeft} />}
            />
          </Flex>
        )}
        {children}
        {shouldShowArrows && (
          <Flex
            flex="1"
            alignSelf="center"
            justify="center"
            position={isFloatingNavigation ? 'absolute' : 'sticky'}
            right={isFloatingNavigation ? '-3.2rem' : 'initial'}
            top="50%"
          >
            <IconButton
              aria-label="Bills gallery next bill"
              onClick={nextBill}
              color="grey.700"
              icon={<Icon name={IconNames.shevronRight} />}
            />
          </Flex>
        )}
      </Flex>
      {items.length > 1 && (
        <Flex w="full" justifyContent="center" alignItems="center" mt="15" textStyle="body2">
          {currentIndex + 1} out of {items.length}
        </Flex>
      )}
    </>
  );
};

type GalleryItemsProps = {
  itemsAmount?: number;
  children: React.ReactNode;
};

export const GalleryItemsContainer: React.FC<GalleryItemsProps & FlexProps> = (props) => {
  const { children, itemsAmount, ...rest } = props;

  return (
    <Flex
      w="full"
      maxW="63rem"
      direction="column"
      h="full"
      alignItems="center"
      justify="center"
      {...rest}
      {...((itemsAmount || 0) > 1 && double)}
      {...((itemsAmount || 0) > 2 && multiple)}
    >
      <MICard mode="natural">{children}</MICard>
    </Flex>
  );
};
