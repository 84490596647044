import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { AccountingSoftware } from 'src/utils/consts';
import { Timeline } from './Timeline';

export type TimelineItemType = {
  isActive: boolean;
  title: {
    label: string;
    values?: {
      accountingSoftwareName: AccountingSoftware;
    };
  };
  date: string;
  additionalInfo: {
    label: string;
    values?: {
      contactName?: string;
    };
  };
};

type Props = {
  item: TimelineItemType;
  last?: boolean;
};

export const TimelineItem = ({ item, last }: Props) => {
  const { title, isActive, date, additionalInfo } = item;

  return (
    <Flex data-testid="timeline-item">
      <Timeline active={isActive} last={last} />
      <Box mb={8}>
        <Box textStyle={isActive ? 'body3Semi' : 'body3'} color="black">
          <MIFormattedText label={title.label} values={title.values} />
        </Box>
        <Box textStyle="body4" color="grey.700" my={0.5}>
          <MIFormattedDateTime date={date} month="long" day="2-digit" hour12={false} />
        </Box>
        <Box textStyle="body4" color="grey.600">
          <MIFormattedText label={additionalInfo.label} values={additionalInfo.values} />
        </Box>
      </Box>
    </Flex>
  );
};
