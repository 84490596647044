import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Checkbox } from 'src/core/ds/checkbox';
import { InlineFieldGroup } from './InlineFieldGroup';
import type { ModelViewField } from './use-form';

export type InlineCheckboxFieldProps = {
  model?: ModelViewField<boolean>;
  value?: boolean;
  label: string;
  labelValues?: Record<string, any>;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: ({ value }: { value: boolean }) => any;
};
export function InlineCheckboxField(props: InlineCheckboxFieldProps) {
  const { model, label, labelValues, onChange, value, id, ...rest } = props;
  const innerValue = !!(value === undefined ? model?.value || false : props.value);
  const realOnChange = onChange || model?.onChange;
  const innerId = id || model?.id;

  return (
    <InlineFieldGroup>
      <Checkbox
        {...rest}
        isChecked={innerValue}
        onChange={(e) => realOnChange && realOnChange({ value: e.target.checked })}
        id={innerId}
        ml={0.5}
      >
        <MIFormattedText label={label} values={labelValues} />
      </Checkbox>
    </InlineFieldGroup>
  );
}
