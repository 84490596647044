import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { CompanyType } from 'src/utils/consts';
import { getUserRole } from 'src/utils/user';

export const useIsAccountingFirmAdmin = () => {
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);
  const currentUser = useSelector(profileStore.selectors.profile);

  const isAccountingFirm = currentOrg.companyType === CompanyType.ACCOUNTING_FIRM;
  const isAccountingFirmAdmin = isAdminRole(getUserRole(currentUser, currentOrg.id));

  return {
    isAccountingFirmAdmin: isAccountingFirm && isAccountingFirmAdmin,
  };
};
