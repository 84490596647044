import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { useIsFinancingPayFlow } from 'src/pages/bill/pay/hooks/useIsFinancingPayFlow';
import { payBillEventPage } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { getBill, getIsRecurring, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, isVirtualCardExpired } from 'src/utils/bills';
import { DeliveryMethodOrigin, PayEditLocationName } from 'src/utils/consts';
import { getLatestPayment, isReturnedCheck, isUndepositedCheck } from 'src/utils/payments';
import { NavigateType } from 'src/utils/types';
import { PayBillFlowType, usePayBillFlowType } from './usePayBillFlowType';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
};

export const useGoEditPage = ({ navigate, nextStepURL, prevStepURL }: Params) => {
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const payment = useSelector(getPayment);
  const originPayment = getLatestPayment(bill.payments);
  const isRecurring = useSelector(getIsRecurring);
  const isUndepositedCheckPayment = originPayment ? isUndepositedCheck(originPayment) : false;
  const isReturnedCheckPayment = originPayment ? isReturnedCheck(originPayment) : false;
  const isFinancing = useIsFinancingPayFlow();
  const payBillFlowType = usePayBillFlowType();

  const { getUrl } = usePayBillNavigateCommon({ navigate, nextStepURL, prevStepURL });

  const goEditDeliveryMethod = () => {
    analytics.track(payBillEventPage, 'edit-delivery-method', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });
    const redirectUrl = generatePath(billLocations.pay.edit.confirm, {
      orgId,
      billId: bill.id,
      paymentId: payment.id,
    });
    const exitUrl = generatePath(billLocations.view, { orgId, id: bill.id });

    if (isRecurring) {
      navigate(billLocations.pay.recurring.deliveryMethod);
    } else if (isVirtualCardExpired(payment)) {
      navigate(
        generatePath(billLocations.pay.edit.virtualCardRecovery, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        }),
        false,
        {
          origin: DeliveryMethodOrigin.EDIT_PAYMENT,
          redirectUrl,
          exitUrl,
        }
      );
    } else if (isUndepositedCheckPayment) {
      navigate(
        generatePath(billLocations.pay.edit.resendUndepositedCheck, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        }),
        false,
        {
          origin: DeliveryMethodOrigin.PAY_BILL,
          redirectUrl,
          exitUrl,
        }
      );
    } else if (isReturnedCheckPayment) {
      navigate(
        generatePath(billLocations.pay.edit.returnedCheckRecovery, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        }),
        false,
        {
          origin: DeliveryMethodOrigin.PAY_BILL,
          redirectUrl,
          exitUrl,
        }
      );
    } else if (payment.isFinanced && payBillFlowType === PayBillFlowType.RetryFailedToDeliver) {
      navigate(
        generatePath(billLocations.pay.edit.deliveryMethodAch, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        }),
        false,
        {
          origin: DeliveryMethodOrigin.PAY_BILL,
          redirectUrl,
          exitUrl,
        }
      );
    } else {
      navigate(getUrl(PayEditLocationName.DELIVERY_METHOD));
    }
  };

  const goEditFundingSource = () => {
    analytics.track(payBillEventPage, 'edit-funding-source', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    let url;

    if (isRecurring) {
      url = billLocations.pay.recurring.funding;
    } else {
      url = getUrl(PayEditLocationName.FUNDING);
    }

    navigate(url);
  };

  const goEditPartialAmount = () => {
    analytics.track(payBillEventPage, 'edit-partial-amount', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    const url = getUrl(PayEditLocationName.FUNDING);

    navigate(url, false, {
      editAmount: true,
    });
  };

  const goEditNote = () => {
    analytics.track(payBillEventPage, 'edit-note', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    let url;

    if (isRecurring) {
      url = billLocations.pay.recurring.memo;
    } else {
      url = payment.id
        ? generatePath(billLocations.pay.edit.memo, {
            orgId,
            billId: bill.id,
            paymentId: payment.id,
          })
        : generatePath(billLocations.pay.memo, { orgId, billId: bill.id });
    }

    navigate(url);
  };

  const goEditDate = (hasCustomTrackEvent?: boolean) => {
    const regularFlowUrl = payment.id
      ? generatePath(billLocations.pay.edit.date, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        })
      : generatePath(billLocations.pay.date, { orgId, billId: bill.id });

    if (!hasCustomTrackEvent) {
      analytics.track(payBillEventPage, 'edit-date');
    }

    const repaymentTermsUrl = generatePath(billLocations.pay.installments.scheduleFlow, {
      orgId,
      billId: bill.id,
    });

    const url = isFinancing ? repaymentTermsUrl : regularFlowUrl;

    navigate(url);
  };

  return { goEditDeliveryMethod, goEditFundingSource, goEditPartialAmount, goEditNote, goEditDate };
};
