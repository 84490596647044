import { featureFlags } from '@melio/shared-web';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import { Children, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import { DrawerBody, DrawerFooter, DrawerHeader } from 'src/core/ds/drawer';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { InvoiceType } from 'src/modules/invoices/types';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { contactsLocations } from 'src/pages/contacts/locations';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import { getProLocations } from 'src/pages/get-pro/locations';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { pushNotification } from 'src/services/notifications/notificationService';
import { ContactsTab, FeatureFlags, GetProTabs, NotificationCardTypes, NotificationVariant } from 'src/utils/consts';
import BatchPaymentRequestItem from '../../cards/batch/BatchPaymentRequestItem';
import BatchPaymentRequestsActions from '../../cards/batch/BatchPaymentRequestsActions';
import { validateInvoice } from '../utils';

const convertInvoices = (invoices) =>
  keyBy(
    invoices.map((invoice) => ({
      ...invoice,
      customerEmail: invoice?.customer?.contactEmail,
    })),
    'id'
  );

type Props = {
  selectedInvoices?: InvoiceType[];
  onCancel(): void;
  setClearSelected(): void;
};
const BatchPaymentRequestsDrawer = ({ selectedInvoices, onCancel, setClearSelected }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const { listParams } = useGetProListParams();
  const [shouldAttachInvoice, setAttachInvoice] = useState(true);
  const [isAttachInvoiceFileOpen] = featureFlags.useFeature(FeatureFlags.AttachInvoiceFile, false);
  const store = MAP_STORE_BY_TAB[GetProTabs.INVOICES];
  const actions = useStoreActions(store);
  const [keySelectedInvoices, setKeySelectedInvoices] = useState(convertInvoices(selectedInvoices));
  const [validationErrorsByKey, setValidationErrorsByKey] = useState<
    Record<string, Record<string, string> | undefined>
  >({});

  const selectedIds = Object.keys(keySelectedInvoices);

  const isLoading = useSelector(store.selectors.proBatchSend.status(selectedIds))?.loading;

  const onRemove = (id: string) => {
    setKeySelectedInvoices((prevData) => {
      const newData = { ...prevData };
      delete newData[id];

      return newData;
    });

    setValidationErrorsByKey((prevState) => {
      const newData = { ...prevState };
      delete newData[id];

      return newData;
    });

    if (selectedIds.length === 1) {
      onCancel();
    }
  };

  const onSubmit = async () => {
    const items = Object.values(keySelectedInvoices);
    await actions.proBatchSend({
      orgId: listParams.orgId,
      items,
      shouldAttachInvoice,
    });

    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'getPro.table.item.toasts.sendBatch.success',
      textValues: {
        count: items.length,
      },
    });

    setClearSelected();
    historyPush({
      path: getProLocations.dashboard,
      query: {
        status: GetProTabs.INVOICES,
      },
    });
  };

  const canSubmit = useMemo(() => every(validationErrorsByKey, (validationError) => isEmpty(validationError)), [
    validationErrorsByKey,
  ]);

  const onBatchImportClick = () => {
    historyPush({
      path: contactsLocations.create.options,
      params: { type: ContactsTab.CUSTOMERS },
    });
  };

  const initValidationError = useCallback(() => {
    const validationErrors: Record<string, Record<string, string> | undefined> = {};

    if (selectedInvoices?.length) {
      for (const invoice of selectedInvoices) {
        const validationError = validateInvoice(invoice);

        if (validationError) {
          validationErrors[invoice.id] = validationError;
        }
      }
    }

    setValidationErrorsByKey(validationErrors);
  }, [selectedInvoices]);

  const onChangeInvoiceData = (id: string, customerEmail?: string) => {
    const updatedInvoice: InvoiceType = {
      ...keySelectedInvoices[id],
      customer: {
        ...keySelectedInvoices[id].customer,
        contactEmail: customerEmail,
      },
      customerEmail,
    };
    const invoiceValidationError = validateInvoice(updatedInvoice);

    if (invoiceValidationError) {
      setValidationErrorsByKey((prevState) => ({
        ...prevState,
        [id]: invoiceValidationError,
      }));
    } else {
      setValidationErrorsByKey((prevState) => {
        const newData = { ...prevState };
        delete newData[id];

        return newData;
      });
    }

    setKeySelectedInvoices((prevState) => ({
      ...prevState,
      [id]: {
        ...updatedInvoice,
      },
    }));
  };

  const toggleInvoice = () => {
    setAttachInvoice((isChecked) => !isChecked);
  };

  useEffect(() => {
    if (!isLoading) {
      initValidationError();
    }
  }, [initValidationError, isLoading]);

  const hasMissingEmails = Object.values(validationErrorsByKey)?.some((v) => Boolean(v?.customerEmail));

  return (
    <>
      <DrawerHeader>
        <Box textStyle="h2Semi">
          <MIFormattedText
            label="getPro.cards.batchPaymentRequests.title"
            values={{
              numberOfItems: Object.entries(keySelectedInvoices).length,
            }}
          />
        </Box>
      </DrawerHeader>
      <DrawerBody>
        {hasMissingEmails && (
          <Box mb={10}>
            <MINotificationCard
              type={NotificationCardTypes.INFO}
              subtitle={{
                label: 'getPro.cards.batchPaymentRequests.subtitle',
                values: {
                  link: (...chunks) => (
                    <Box display="inline" cursor="pointer" onClick={onBatchImportClick} textStyle="link1">
                      {Children.toArray(chunks)}
                    </Box>
                  ),
                },
              }}
            />
          </Box>
        )}
        {isAttachInvoiceFileOpen && (
          <Box mb={10}>
            <Checkbox
              isChecked={shouldAttachInvoice}
              onChange={toggleInvoice}
              data-testid="attach-invoice-files-checkbox"
            >
              <MIFormattedText label="getPro.cards.batchPaymentRequests.attachInvoiceFiles" />
            </Checkbox>
          </Box>
        )}
        {Object.entries(keySelectedInvoices)?.map(([id, invoice]) => (
          <BatchPaymentRequestItem
            key={id}
            invoice={invoice}
            onRemove={onRemove}
            onChangeInvoiceData={onChangeInvoiceData}
            validationError={validationErrorsByKey[id]}
          />
        ))}
      </DrawerBody>
      <DrawerFooter>
        <BatchPaymentRequestsActions
          isDisabled={!canSubmit}
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={isLoading}
          submitAnalyticsProperties={{ selectedIds }}
        />
      </DrawerFooter>
    </>
  );
};

export default BatchPaymentRequestsDrawer;
