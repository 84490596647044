import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { EditFundingSourceLabelState } from 'src/pages/settings/PaymentMethodsSettingsPageContainer';
import { removeFundingSourceLabelAction, updateFundingSourceLabelAction } from 'src/redux/user/actions';
import { getIsRemovingFundingSourceLabel } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';

type Props = {
  editingFundingSourceLabel: NonNullable<EditFundingSourceLabelState>;
  dismiss: (event?: React.MouseEvent) => void;
};

export const EditPaymentMethodLabelDialog = (props: Props) => {
  const { editingFundingSourceLabel, dismiss } = props;
  const dispatch = useDispatch();
  const isRemovingLabel = useSelector(getIsRemovingFundingSourceLabel);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  const formModelData = useMemo(
    () => ({
      id: editingFundingSourceLabel?.id,
      nickname: editingFundingSourceLabel?.currentNickname,
    }),
    [editingFundingSourceLabel]
  );

  const onSubmit = (id, nickname) =>
    new Promise((resolve, reject) => {
      dispatch(updateFundingSourceLabelAction(id, nickname, resolve, reject));
    });

  const onRemove = async () => {
    await new Promise((resolve, reject) => {
      dispatch(removeFundingSourceLabelAction(editingFundingSourceLabel.id, resolve, reject));
    });
    dismiss();
  };

  const [editLabelMV, { submit }, , loading] = useForm(formModelData, {
    submit: async ({ id, nickname }) => {
      if (!nickname) {
        setDisplayErrorMessage(true);

        return false;
      }

      await onSubmit(id, nickname);
      analytics.trackAction('add-payment-method-label-success', {
        id,
        label: nickname,
      });
      dismiss();

      return true;
    },
    onChange: ({ value, modelState }) => {
      setDisplayErrorMessage(!value);

      return modelState;
    },
  });

  return (
    <ModalMessage
      isLoading={loading}
      titleComponent={<MIFormattedText label="settings.paymentMethods.editLabelDialog.title" />}
      descriptionComponent={
        <Box textAlign="left">
          <MIFormattedText label="settings.paymentMethods.editLabelDialog.description" />
        </Box>
      }
      contentComponent={
        <FormContainer onSubmit={submit}>
          <FormRow>
            <TextField
              id="editPMLabel"
              label="settings.paymentMethods.editLabelDialog.inputLabel"
              placeholder="settings.paymentMethods.editLabelDialog.placeholder"
              model={editLabelMV.nickname}
              type="text"
              errorMessage={displayErrorMessage ? 'settings.paymentMethods.editLabelDialog.emptyError' : ''}
              size="sm"
              autoFocus
              isRequired
            />
          </FormRow>
        </FormContainer>
      }
      alignLeft
      onCloseClick={dismiss}
      buttonComponent={
        <Button
          label="settings.paymentMethods.editLabelDialog.saveCta"
          size={ButtonSizes.lg}
          type="submit"
          onClick={submit}
          isLoading={loading}
          disabled={isRemovingLabel}
          isFullWidth
        />
      }
      secondaryButtonComponent={
        editingFundingSourceLabel?.currentNickname && (
          <Button
            label="settings.paymentMethods.editLabelDialog.removeCta"
            variant={ButtonVariants.tertiaryNaked}
            size={ButtonSizes.lg}
            onClick={onRemove}
            isLoading={isRemovingLabel}
            disabled={loading}
            isFullWidth
          />
        )
      }
    />
  );
};
