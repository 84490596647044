import pick from 'lodash/pick';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import {
  BankAccountType,
  DeliveryType,
  InternationalIdentifierType,
  MANAGED_DELIVERY_TYPES,
  VendorManagedByEnum,
  VerifiedStatus,
} from 'src/utils/consts';
import { getFormattedAddress, getFormattedCardInfo } from 'src/utils/formatting';
import { getTranslationValue } from 'src/utils/translations';
import { AddressType, BankType, CheckType, DeliveryMethodType, InternationalAccountType } from 'src/utils/types';
import { addressDefaults } from '../settings/records';

export const isPaymentPushToDebit = (deliveryMethod: DeliveryMethodType) =>
  deliveryMethod.deliveryType === DeliveryType.CARD && deliveryMethod.cardAccount?.cardType === 'debit';

export function deliveryMethodFactory(initialValues?: Partial<DeliveryMethodType>): DeliveryMethodType {
  return {
    id: null,
    logo: '',
    deliveryType: DeliveryType.ACH,
    paperCheck: null,
    bankAccount: null,
    plaidAccount: null,
    hasScheduledPayments: false,
    isFilledByVendor: false,
    isVerified: false,
    verifiedStatus: VerifiedStatus.NOT_VERIFIED,
    createdById: '',
    updatedAt: null,
    cardAccountId: null,
    intuitAccountId: null,
    virtualCardAccount: null,
    internationalAccount: null,
    cardAccount: null,
    ...initialValues,
  };
}

const getInternationalAccountDetails = ({ payeeBankIban, payeeBankBicSwift }: InternationalAccountType) => {
  const identifierType = payeeBankIban ? InternationalIdentifierType.IBAN : InternationalIdentifierType.SWIFT;
  const identifier = payeeBankIban || payeeBankBicSwift;
  const identifier4digits = identifier?.slice(-4);

  return { identifierType, identifier4digits };
};

export function getDeliveryMethodDisplay({
  deliveryMethod,
  vendorName = '',
  shorten = false,
  isVendorView = false,
}: {
  deliveryMethod: DeliveryMethodType;
  isVendorView?: boolean;
  vendorName?: string;
  shorten?: boolean;
  managedBy?: VendorManagedByEnum;
}): string {
  const isACHDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.ACH;
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.RPPS;
  const isInternationalDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.INTERNATIONAL;
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isCheckDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.CHECK;
  const isCardDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.CARD;

  if (deliveryMethod.managedBy) {
    if (isACHDeliveryMethod && shorten) {
      return getTranslationValue(`deliveryMethods.managed.short`);
    }

    if (MANAGED_DELIVERY_TYPES.includes(deliveryMethod.deliveryType)) {
      return isVendorView
        ? getTranslationValue(`deliveryMethods.managed.vendorView.${deliveryMethod.deliveryType}`)
        : getTranslationValue(`deliveryMethods.managed.${deliveryMethod.deliveryType}`);
    }
  }

  if (isCardDeliveryMethod && deliveryMethod.cardAccount) {
    return getFormattedCardInfo(deliveryMethod.cardAccount);
  }

  if (isRPPSDeliveryMethod) {
    return getTranslationValue('deliveryMethods.rpps');
  }

  if (isVirtualCardDeliveryMethod && deliveryMethod.virtualCardAccount) {
    return deliveryMethod.virtualCardAccount.accountEmail;
  }

  const accountNumber4digits = getAccountNumber4digits(deliveryMethod.bankAccount);

  if (isACHDeliveryMethod && accountNumber4digits) {
    return `${vendorName ? `${vendorName} ` : ''}(...${accountNumber4digits})`;
  }

  if (isInternationalDeliveryMethod && deliveryMethod.internationalAccount) {
    const { identifierType, identifier4digits } = getInternationalAccountDetails(deliveryMethod.internationalAccount);

    return `${identifierType.toUpperCase()} ...${identifier4digits}`;
  }

  if (isCheckDeliveryMethod && deliveryMethod.paperCheck) {
    return `${deliveryMethod.paperCheck.printName}  | ${getFormattedAddress(deliveryMethod.paperCheck)}`;
  }

  if (isPaymentPushToDebit(deliveryMethod)) {
    return `${vendorName ? `${vendorName} ` : ''}(...${deliveryMethod.cardAccount?.card4digits})`;
  }

  return '';
}

export const getDeliveryMethodByType = (type: DeliveryType, deliveryMethods: readonly DeliveryMethodType[]) => {
  let deliveryMethod: DeliveryMethodType | undefined;

  if (deliveryMethods) {
    deliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === type);
  }

  if (!deliveryMethod) {
    return deliveryMethodFactory({ deliveryType: type });
  }

  return deliveryMethodFactory(deliveryMethod);
};

export function bankFactory(initialValues?: Partial<BankType>): BankType {
  return {
    accountType: BankAccountType.CHECKING,
    routingNumber: '',
    accountNumber: '',
    intuitAccountId: '',
    isBlocked: false,
    accountNumber4digits: '',
    // to retain the immutable.js Record behavior, we don't want to include extra properties
    // TODO: ideally this would be done at the caller's side, but at right point its too much of a hassle to fix it there.
    ...pick(initialValues, [
      'accountType',
      'routingNumber',
      'accountNumber',
      'intuitAccountId',
      'isBlocked',
      'accountNumber4digits',
    ]),
  };
}

export function checkFactory(initialValues?: Partial<CheckType>): CheckType {
  return {
    ...addressDefaults,
    printName: '',
    zipCode: '',
    isAddressSuggestionIgnored: undefined,
    ...initialValues,
  };
}

export function addressFactory(initialValues?: Partial<AddressType>): AddressType {
  return {
    ...addressDefaults,
    ...initialValues,
  };
}
