import { useState } from 'react';
import { ManualAddressOptionsContainer } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useManualAddressState } from 'src/hoc/manual-address';
import { ManualAddress } from 'src/utils/consts';

type Props = {
  onSuccess: any;
  onExit: () => void;
};

export const ManualAddressContainer = ({ onSuccess, onExit }: Props) => {
  const [selectedAddressId, setSelectedAddressId] = useState(ManualAddress.SUGGESTED);
  const [state, actions, addressModel] = useManualAddressState({
    selectedAddressId,
    onSuccess,
  });
  const { submit, confirmAddress, editAddress } = actions;
  const {
    isValidAddress,
    whitePageValidationErrors,
    whitePageAddressView,
    whitePageAddress,
    originalAddress,
    isAddressLoading,
  } = state;

  return (
    <StepLayoutPage
      title={whitePageAddressView ? 'manualAddress.title' : 'manualAddress.form.title'}
      subtitle={whitePageAddressView ? 'manualAddress.subtitle' : ''}
      nextLabel={whitePageAddressView ? 'manualAddress.confirmInvalid' : 'progress.continue'}
      isPrevDisabled
      goExit={onExit}
      onNext={whitePageAddressView ? confirmAddress : submit}
      isLoading={isAddressLoading}
    >
      <ManualAddressOptionsContainer
        selectedAddressId={selectedAddressId}
        setSelectedAddressId={setSelectedAddressId}
        addressModel={addressModel}
        editAddress={editAddress}
        isValidAddress={isValidAddress}
        whitePageValidationErrors={whitePageValidationErrors as any}
        whitePageAddress={whitePageAddress as any}
        originalAddress={originalAddress}
        submitAddressForm={submit}
        whitePageAddressView={whitePageAddressView}
      />
    </StepLayoutPage>
  );
};
