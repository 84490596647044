import { Component } from 'react';
import { WizardFormColumn, WizardFormRow } from 'src/components/layout/WizardElements';
import { TextField } from 'src/core/ds/form/fields';

type Props = {
  value: string | null | undefined;
  label: string;
  id: string;
  required?: boolean;
  placeHolder: string;
  errorMessage: string;
  validationTest: string;
  wizardColumn?: boolean;
  submitValidation: boolean;
  returnVal: ({ id, value }: { id: string; value: any }) => void;
  autoFocus: boolean;
  disabled?: boolean;
};

type State = {
  stateValue: string | null | undefined;
  isValid: boolean;
  Timer: any;
};

const validationUtils = {
  string: (value) => value.length >= 2,
  state: (value) => value === value.toUpperCase() && value.length === 2,
};

// eslint-disable-next-line import/no-default-export
export default class CardFormInput extends Component<Props, State> {
  static defaultProps = {
    wizardColumn: true,
    required: false,
    autoFocus: false,
    disabled: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      stateValue: props.value,
      isValid: props.value && props.value.length ? validationUtils[props.validationTest](props.value) : true,
      Timer: '',
    };
  }

  static getDerivedStateFromProps(props: Props) {
    if (props.value === null) {
      return {
        stateValue: '',
        isValid: true,
        Timer: '',
      };
    }

    return null;
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextState.stateValue !== this.state.stateValue || nextProps.submitValidation) return true;

    if (nextProps.value === this.props.value) return false;

    return true;
  }

  handleChange = (event: Record<string, any>) => {
    const { value } = event.currentTarget;
    clearTimeout(this.state.Timer);
    this.setState({
      stateValue: value,
      Timer: setTimeout(() => {
        this.setState({
          isValid: validationUtils[this.props.validationTest](value),
        });
        this.props.returnVal({ value, id: this.props.id });
      }, 500),
    });
  };

  render() {
    const {
      id,
      label,
      placeHolder,
      required,
      errorMessage,
      wizardColumn,
      submitValidation,
      autoFocus,
      disabled,
    } = this.props;
    const { stateValue, isValid } = this.state;
    const WizardWrapper = wizardColumn ? WizardFormColumn : WizardFormRow;

    return (
      <WizardWrapper>
        <TextField
          id={id}
          value={stateValue || ''}
          label={label}
          placeholder={placeHolder}
          isRequired={required}
          onChange={this.handleChange}
          autoFocus={autoFocus}
          errorMessage={!isValid || submitValidation ? errorMessage : ''}
          isDisabled={disabled}
        />
      </WizardWrapper>
    );
  }
}
