import { MIDropDown } from 'src/components/common/DropDown/MIDropDown';
import Box from 'src/core/ds/box/Box';
import { FormRow } from 'src/core/ds/form';
import { PasswordMaskField } from 'src/core/ds/form/fields/PasswordMaskField';
import { useBusinessTaxRelatedOptions } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/business-tax-related/useBusinessTaxRelatedOptions';
import { useBusinessTaxRelatedValidator } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/business-tax-related/useBusinessTaxRelatedValidator';
import { TooltipLabel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/TooltipLabel';
import {
  BusinessDetailsModel,
  BusinessType,
} from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { ModelView } from 'src/ui/form';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  businessDetailsModelView: ModelView<BusinessDetailsModel>;
  errorMessages: {
    type?: string;
    taxIdNumber?: string;
  };
};

export const BusinessTaxRelatedFormRows = ({ businessDetailsModelView, errorMessages }: Props) => {
  const { businessTypeOptions } = useBusinessTaxRelatedOptions();
  const { shouldBusinessTypeFillTaxId } = useBusinessTaxRelatedValidator();

  const handleBusinessTypeChange = ({ value }: { value: BusinessType }) => {
    businessDetailsModelView.type.onChange({ value });
  };

  const shouldAskForTaxData =
    businessDetailsModelView.type.value && shouldBusinessTypeFillTaxId(businessDetailsModelView.type.value);

  return (
    <>
      <FormRow>
        <Box width="full" zIndex={5}>
          <MIDropDown
            testId="input-type"
            label="financing.businessDetails.fields.businessType.title"
            labelValues={{
              tooltip: (
                <TooltipLabel label="financing.businessDetails.fields.businessType.tooltip" testId="business-type" />
              ),
            }}
            placeholder="financing.businessDetails.fields.businessType.placeholder"
            errorMessage={errorMessages?.type}
            required
            value={businessDetailsModelView.type.value}
            options={businessTypeOptions}
            onChange={handleBusinessTypeChange}
          />
        </Box>
      </FormRow>
      {shouldAskForTaxData ? (
        <FormRow>
          <PasswordMaskField
            testId="input-taxIdNumber"
            label="financing.businessDetails.fields.taxIdNumber.title"
            placeholder="financing.businessDetails.fields.taxIdNumber.placeholder"
            errorMessage={errorMessages?.taxIdNumber}
            mb={0}
            isRequired
            model={businessDetailsModelView.taxIdNumber}
            isModelOnChangeFormatted
            format="##-#######"
            className={FULL_STORY_MASK_RULE_CLASS}
          />
        </FormRow>
      ) : null}
    </>
  );
};
