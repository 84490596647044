import noop from 'lodash/noop';
import { createContext, useCallback, useContext, useState } from 'react';
import { FinancingPreEligibilityDecision, IneligibilityReason } from 'src/modules/financing/types';

type AnalyticsProperties = {
  installmentAmount: number;
  repaymentAmount: number;
};

type PreEligibilityData = {
  decision: FinancingPreEligibilityDecision;
  reason: IneligibilityReason;
};

export type PayBillFinancingContextType = {
  preEligibilityData:
    | {
        decision: FinancingPreEligibilityDecision;
        reason: IneligibilityReason;
      }
    | undefined;
  intentId: string | undefined;
  numberOfInstallments: number | undefined;
  setInstallments: (intentId: string | undefined, numberOfInstallments: number | undefined) => void;
  setPreEligibilityData: (preEligibilityResult: PreEligibilityData | undefined) => void;
  isInstallmentSelected: boolean;
  setIsInstallmentSelected: (isInstallmentSelected: boolean) => void;
  clearInstallments: () => void;
  analyticsProperties: AnalyticsProperties | undefined;
  setAnalyticsProperties: (analyticsProperties: AnalyticsProperties) => void;
};

export const PayBillFinancingContext = createContext<PayBillFinancingContextType>({
  intentId: undefined,
  numberOfInstallments: undefined,
  setInstallments: () => noop(),
  preEligibilityData: undefined,
  setPreEligibilityData: () => noop,
  isInstallmentSelected: false,
  setIsInstallmentSelected: () => noop,
  clearInstallments: () => noop,
  setAnalyticsProperties: () => noop,
  analyticsProperties: undefined,
});

export const PayBillFinancingContextProvider = ({ children }) => {
  const [numberOfInstallments, setNumberOfInstallments] = useState<number | undefined>();
  const [intentId, setIntentId] = useState<string | undefined>();
  const [preEligibilityData, setPreEligibilityData] = useState<PreEligibilityData | undefined>();
  const [isInstallmentSelected, setIsInstallmentSelected] = useState<boolean>(false);
  const [analyticsProperties, setAnalyticsProperties] = useState<AnalyticsProperties>();

  const clearInstallments = () => {
    setInstallments(undefined, undefined);
    setIsInstallmentSelected(false);
  };

  const setInstallments = useCallback((intentId: string | undefined, numberOfInstallments: number | undefined) => {
    setNumberOfInstallments(numberOfInstallments);
    setIntentId(intentId);
    setIsInstallmentSelected(true);
  }, []);

  return (
    <PayBillFinancingContext.Provider
      value={{
        intentId,
        numberOfInstallments,
        setInstallments,
        preEligibilityData,
        setPreEligibilityData,
        isInstallmentSelected,
        setIsInstallmentSelected,
        clearInstallments,
        analyticsProperties,
        setAnalyticsProperties,
      }}
    >
      {children}
    </PayBillFinancingContext.Provider>
  );
};

export function usePayBillFinancingData() {
  return useContext(PayBillFinancingContext);
}
