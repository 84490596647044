import { useFeature } from '@melio/shared-web/dist/feature-flags';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import Box from 'src/core/ds/box';
import { isFundingSourceAllowedForInternational } from 'src/pages/vendor/international-delivery-method/utils';
import { FeatureFlags } from 'src/utils/consts';
import { AddAnotherRadioMethodOption } from './MethodElements';

export type MethodListItemType = {
  componentType: any;
  methods: any[];
  onVerifyClicked?: (id: number) => void;
  isSettings?: boolean;
  companyName?: string;
  isVendorAbsorbedFee?: boolean;
  isInternationalVendor?: boolean;
  isBillingFee?: boolean;
  totalAmount?: number;
  isInstallmentDisabled?: boolean;
};

type Props = {
  value: any;
  list: MethodListItemType[];
  onAddMethod: (type: string) => void;
  onChange?: (value: any) => void;
  showAddAnotherOption?: boolean;
  isDisabled?: (method: any) => boolean;
  optionComponent: any;
  modifyFundingSourceActions?: (method: any) => Record<string, VoidFunction>;
  defaultFundingSourceId?: number | null;
  firmBillingMethodId?: number | null;
};

export const MethodsList = ({
  value,
  list,
  onAddMethod,
  onChange,
  showAddAnotherOption,
  isDisabled = () => false,
  modifyFundingSourceActions,
  defaultFundingSourceId,
  optionComponent,
  firmBillingMethodId,
}: Props) => {
  const [isInternationalMastercardEnabled] = useFeature(FeatureFlags.EnableMastercardFSForInternational, false);

  return (
    <Box data-testid="list-container">
      {list.map(({ componentType: ComponentType, methods, isInternationalVendor, ...rest }, index) => (
        <GroupContainer key={index} data-testid={`list-group-${ComponentType.methodType}`}>
          {showAddAnotherOption && isEmpty(methods) ? (
            <ComponentType
              onClick={() => {
                onAddMethod(ComponentType.methodType);
              }}
              optionComponent={optionComponent}
              isInternationalVendor={isInternationalVendor}
              {...rest}
            />
          ) : (
            <>
              {methods.map((method) => {
                const isDisabledInternationalVendor =
                  isInternationalVendor &&
                  !isFundingSourceAllowedForInternational({
                    fundingSource: method,
                    isInternationalMastercardEnabled,
                  });
                const disabled =
                  isDisabled(method) || ComponentType.isDisabled(method) || isDisabledInternationalVendor;
                const isDefault = defaultFundingSourceId === method.id;
                const isFirmBillingFeeMethod = firmBillingMethodId === method.id;

                return (
                  <ComponentType
                    key={method.id}
                    method={method}
                    onClick={(event: { stopPropagation: () => void }) => {
                      if (disabled) {
                        event.stopPropagation();
                      } else if (onChange) {
                        onChange(method);
                      }
                    }}
                    disabled={disabled}
                    isDefault={isDefault}
                    showFirmBillingFeeBadge={isFirmBillingFeeMethod}
                    isSelected={value === method}
                    optionComponent={optionComponent}
                    modifyActions={modifyFundingSourceActions ? modifyFundingSourceActions(method) : null}
                    {...rest}
                  />
                );
              })}
              {showAddAnotherOption && ComponentType.showAddAnotherOption && (
                <AddAnotherRadioMethodOption
                  key={`add-another-${ComponentType.methodType}`}
                  testId={`add-another-${ComponentType.methodType}`}
                  label={ComponentType.addAnotherMethodLabel}
                  onClick={() => {
                    onAddMethod(ComponentType.methodType);
                  }}
                />
              )}
            </>
          )}
        </GroupContainer>
      ))}
    </Box>
  );
};

const GroupContainer = styled.div`
  margin-bottom: 2rem;
  ${(props) => props.theme.components?.MethodsList?.GroupContainer}
`;
