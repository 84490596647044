import { AddressType, ValidationErrors } from 'src/utils/types';
import { postRequest } from './api';

export enum MeasurementLabel {
  PAGE_LOAD_INTERACTIVE = 'PAGE_LOAD_INTERACTIVE',
  PAGE_LOAD_COMPLETE = 'PAGE_LOAD_COMPLETE',
  PAGE_LOAD_TTFB = 'PAGE_LOAD_TTFB',
}
export interface MeasurementType {
  label: MeasurementLabel;
  value: number;
}

export default {
  getValidationErrors<T>(schemaName: string, dataObject: T, inputFields: (keyof T)[] | null = null, options: any = {}) {
    const url = '/client-service/validate';
    const data = {
      schemaName,
      dataObject,
      inputFields,
      options,
    };

    return postRequest(url, data) as Promise<{
      validationErrors: ValidationErrors<T>;
    }>;
  },

  getPasswordValidationErrors<T>(
    schemaName: string,
    dataObject: T,
    inputFields: (keyof T)[] | null = null,
    options: any = {}
  ) {
    const url = '/client-service/validatePassword';
    const data = {
      schemaName,
      dataObject,
      inputFields,
      options,
    };

    return postRequest(url, data) as Promise<{
      validationErrors: ValidationErrors<T>;
    }>;
  },

  getPasswordScore(password: string) {
    const url = '/client-service/passwordScore';
    const data = { password };

    return postRequest(url, data);
  },

  getAddressValidation(addressToCheck: AddressType) {
    const url = '/client-service/address-validation';

    return postRequest(url, addressToCheck);
  },

  getAddressValidationSmartyStreets(addressToCheck) {
    const url = '/client-service/address-validation-streets';

    return postRequest(url, addressToCheck);
  },

  sendAnalyticsEvent(eventName, userId, properties) {
    const url = '/client-service/analytics-event';

    return postRequest(url, { eventName, userId, properties });
  },

  measure(measurements: MeasurementType[], route: string) {
    const url = '/client-service/measure';

    return postRequest(url, { measurements, route });
  },
};
