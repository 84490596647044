import pick from 'lodash/pick';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { Form, FormRow } from 'src/core/ds/form';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { profileStore } from 'src/modules/profile/profile-store';
import {} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { BusinessTaxRelatedFormRows } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/business-tax-related/BusinessTaxRelatedFormRows';
import { LegalBusinessAddressFormRows } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/legal-business-address/LegalBusinessAddressFormRows';
import { TooltipLabel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/TooltipLabel';
import { useValidator } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/useValidator';
import { BusinessDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { TermsAndConditions } from 'src/pages/bill/pay/installments/pages/components/TermsAndConditions';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { useForm } from 'src/ui/form';
import { convertToFormattedPhoneNumber, convertToFormattedTaxIdNumber } from '../../../../utils';
import { BusinessIndustryFormRow } from './business-industry/BusinessIndustryFormRow';

type Props = {
  onSubmit(businessDetailsModel: BusinessDetailsModel): void;
};

const mapBusinessDetailsModelFromOrganization = (organization): BusinessDetailsModel => ({
  companyName: organization.companyName || '',
  legalCompanyName: organization.legalCompanyName || '',
  addressLine1: organization.legalAddressLine1 || '',
  state: organization.legalState || '',
  city: organization.legalCity || '',
  zipCode: organization.legalZipCode || '',
  formattedAddress: organization.legalFormattedAddress || '',
  annualRevenue: '',
  ownerPhone: convertToFormattedPhoneNumber(organization.phone),
  type: '',
  taxIdNumber: convertToFormattedTaxIdNumber(organization.taxId),
  industry: organization.industryText || '',
});

export const BusinessDetailsForm = ({ onSubmit }: Props) => {
  const organization = useSelector(profileStore.selectors.getCurrentOrg);
  const { validator } = useValidator();
  const { payBillFlowUUID } = useSelector(getPayment);

  const initialBusinessDetailsModel: BusinessDetailsModel = useMemo(
    () => mapBusinessDetailsModelFromOrganization(organization),
    [organization]
  );

  const [businessDetailsModelView, { submit }, validationErrors] = useForm<BusinessDetailsModel>(
    initialBusinessDetailsModel,
    {
      submit: async (model: BusinessDetailsModel) => {
        onSubmit(model);
      },
      validator,
      validateOnChange: false,
    }
  );

  return (
    <Form onSubmit={submit}>
      <FormRow>
        <TextField
          testId="input-companyName"
          label="financing.businessDetails.fields.companyName.title"
          errorMessage={validationErrors.companyName}
          isRequired
          mb={0}
          model={businessDetailsModelView.companyName}
        />
      </FormRow>
      <FormRow>
        <TextField
          testId="input-legalCompanyName"
          label="financing.businessDetails.fields.legalBusinessName.title"
          helperText="financing.businessDetails.fields.legalBusinessName.hint"
          errorMessage={validationErrors.legalCompanyName}
          isRequired
          mb={0}
          model={businessDetailsModelView.legalCompanyName}
        />
      </FormRow>
      <LegalBusinessAddressFormRows
        businessDetailsModelView={businessDetailsModelView}
        errorMessages={pick(validationErrors, ['addressLine1', 'city', 'state', 'zipCode'])}
      />
      <FormRow>
        <PrivateDataContainer>
          <MaskField
            testId="input-annualRevenue"
            label="financing.businessDetails.fields.annualRevenue.title"
            labelValues={{
              tooltip: (
                <TooltipLabel label="financing.businessDetails.fields.annualRevenue.tooltip" testId="annual-revenue" />
              ),
            }}
            placeholder="financing.businessDetails.fields.annualRevenue.placeholder"
            prefix="currency.symbol"
            thousandSeparator
            errorMessage={validationErrors.annualRevenue}
            isRequired
            mb={0}
            model={businessDetailsModelView.annualRevenue}
          />
        </PrivateDataContainer>
      </FormRow>
      <FormRow>
        <PrivateDataContainer>
          <MaskField
            testId="input-ownerPhone"
            label="financing.businessDetails.fields.ownerPhone.title"
            placeholder="financing.businessDetails.fields.ownerPhone.placeholder"
            type="tel"
            mb={0}
            isRequired
            errorMessage={validationErrors.ownerPhone}
            model={businessDetailsModelView.ownerPhone}
            format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
            isModelOnChangeFormatted
          />
        </PrivateDataContainer>
      </FormRow>
      <BusinessTaxRelatedFormRows
        businessDetailsModelView={businessDetailsModelView}
        errorMessages={pick(validationErrors, ['type', 'taxIdNumber'])}
      />
      <BusinessIndustryFormRow
        label="financing.businessDetails.fields.businessIndustry.title"
        model={businessDetailsModelView.industry}
      />
      <TermsAndConditions payBillFlowUUID={payBillFlowUUID} />
      <Flex justifyContent="center" w="full">
        <Button testId="submit-form" size={ButtonSizes.lg} label="general.continue" type="submit" />
      </Flex>
    </Form>
  );
};
