import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useFetchApprovalWorkflows } from 'src/hooks/accountants/approval-workflows/useFetchApprovalWorkflows';
import { useReplaceApprovalThreshold } from 'src/hooks/accountants/approval-workflows/useReplaceApprovalThreshold';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import invitationsStore from 'src/modules/invitations/invitations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { UserOrganization } from 'src/utils/types';
import { RegisterSetAmount } from './RegisterSetAmount';

export type UserOrganizationFormType = Omit<UserOrganization, 'approvalAmountThreshold'> & {
  approvalAmountThreshold?: string | null;
};

type Props = {
  onNext: (userFundingSources?: boolean) => void;
};

export const RegisterSetAmountContainer = ({ onNext }: Props) => {
  const { invitingUser } = useSelector(invitationsStore.selectors.validation);
  const { firstName } = useSelector(profileStore.selectors.profile);
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const userFundingSources = useSelector(paymentsStore.selectors.getUserFundingSources);
  const approvalWorkflowsLoading = useSelector(approvalWorkflowsStore.selectors.list.status())?.loading;
  const isApprovalWorkflowsCreating = useSelector(approvalWorkflowsStore.selectors.create.status())?.loading;
  const [skipSetAmount, setSkipAmount] = useState<boolean>(false);
  const { userOrg, isUpdating } = useStructuredSelectors(
    usersStore.selectors.userOrganization(invitingUser?.id, orgId)
  );
  const { replaceApprovalThreshold } = useReplaceApprovalThreshold({
    orgId,
    skipSetAmount,
    userId: userOrg?.userId,
  });

  useFetchApprovalWorkflows(orgId);

  const onSkipSetAmountChange = ({ value }) => setSkipAmount(value);

  const onSubmit = async (value: UserOrganizationFormType) => {
    await replaceApprovalThreshold(value);

    onNext(userFundingSources?.length > 0);
  };

  const isLoading = approvalWorkflowsLoading || !orgId || !userOrg || !invitingUser;

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <RegisterSetAmount
      onSubmit={onSubmit}
      isLoading={isUpdating || isApprovalWorkflowsCreating}
      userName={firstName}
      onSkipSetAmountChange={onSkipSetAmountChange}
      skipSetAmount={skipSetAmount}
      userOrg={userOrg}
      invitingUser={invitingUser}
    />
  );
};
