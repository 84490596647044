const parts = ['container', 'label', 'closeButton'];

const baseStyleContainer = {
  outline: 0,
  bg: 'none',
  color: 'black',
  border: '1px',
  borderRadius: 'xl',
  _focus: {
    boxShadow: 'outline',
  },
};

const baseStyleLabel = {
  apply: 'textStyles.caption1Semi',
};

const baseStyleCloseButton = {
  apply: 'textStyles.body4Semi',
  w: 3,
  h: 3,
  border: 'none',
  cursor: 'pointer',
  color: 'black',
  ms: 1,
  bg: 'none',
};

const baseStyle = {
  container: baseStyleContainer,
  label: baseStyleLabel,
  closeButton: baseStyleCloseButton,
};

const sizes = {
  md: {
    container: {
      h: 3,
      minW: 3,
      px: 2,
      py: 1,
    },
  },
  sm: {
    container: {
      h: 2,
      minW: 3,
      px: 2,
      py: 1,
    },
  },
};

const primaryVariant = {
  bg: 'primary.300',
  color: 'primary.500',
};

const variants = {
  primary: primaryVariant,
};

const defaultProps = {
  size: 'md',
  variant: 'primary',
};

export default {
  parts,
  variants,
  baseStyle,
  sizes,
  defaultProps,
};
