import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import { AddAchFlow } from 'src/pages/vendor/delivery-methods/add-ach-flow/AddAchFlow';
import { FlowCustomizationsType } from 'src/pages/vendor/delivery-methods/add-ach-flow/types';
import { getBill } from 'src/redux/payBillWizard/selectors';

type Props = {
  nextPageUrl: string;
};

export const AddAchFromPaymentFlowWrapper = ({ nextPageUrl }: Props) => {
  const match = useRouteMatch<{ deliveryMethodId?: string }>();
  const { deliveryMethodId } = match.params;
  const { SimpleTextFooter } = WizardElements;
  const bill = useSelector(getBill);
  const { vendor } = bill;
  const vendorName = vendor?.companyName;

  const flowCustomizations: FlowCustomizationsType = {
    stepLayout: {
      hideHeader: true,
      title: isEmpty(vendorName)
        ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
        : 'vendors.deliveryMethods.bank.title',
      titleValues: { vendor: vendorName },
      nextLabel: 'vendors.deliveryMethods.bank.save',
      footer: (
        <SimpleTextFooter>
          <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
        </SimpleTextFooter>
      ),
      fullWidthCTA: true,
    },
  };

  return (
    <AddAchFlow
      flowCustomizations={flowCustomizations}
      vendorId={bill.vendorId!}
      saveAndContinue={nextPageUrl}
      deliveryMethodId={deliveryMethodId}
    />
  );
};
