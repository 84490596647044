import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Link } from 'src/core/ds/link';
import SuccessIllustration from 'src/images/qbo/success-check.svg';
import { analytics } from 'src/services/analytics';
import { ButtonsRowPosition, DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  onOkAction: () => void;
};

export const MicroDepositsAlreadyVerifiedDialog = ({ onOkAction }: Props) => {
  const LEARN_HOW_TO_PAY_LINK =
    'https://melio.zendesk.com/hc/en-us/articles/4416107725084-How-to-pay-or-schedule-a-bill-payment';

  const goLearnHowToPay = () => {
    analytics.track('micro-deposits-unable-to-verify', 'click-learn-how-to-pay');
  };

  return (
    <Dialog
      type={DialogType.CONFIRM}
      variant={DialogVariants.SUCCESS}
      title="settings.microDeposits.verifiedDialog.title"
      subtitle="settings.microDeposits.verifiedDialog.subtitle"
      subtitleValues={{
        learnHow: (
          <Link href={LEARN_HOW_TO_PAY_LINK} target="_blank" isExternal onClick={goLearnHowToPay}>
            <MIFormattedText label="settings.microDeposits.verifiedDialog.learnHow" />
          </Link>
        ),
      }}
      okButtonText="settings.microDeposits.verifiedDialog.ok"
      onOkAction={onOkAction}
      buttonsRowPosition={ButtonsRowPosition.RIGHT}
      minHeight="36.8rem"
      image={SuccessIllustration}
      hideClose
    />
  );
};
