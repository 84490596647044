import { useSelector } from 'react-redux';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { MCBStepLayoutPage } from 'src/components/layout/MCBStepLayoutPage';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CardTypes } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import locations from 'src/utils/locations';

function FundingSourcePageContainer() {
  const { navigate, navigateWithPreservedState, navigateToExitWithPreservedState } = usePreservedStateNavigator();
  const profile = useSelector(getProfile);
  const eventPage = profile.isGuest ? 'payor-funding-source' : 'add-funding-source';
  const [isNewPlaidMDRecoveryFlow] = useLocationState('isNewPlaidMDRecoveryFlow', false);
  const title = 'onboarding.fundingSources.title';
  const subtitle = 'onboarding.fundingSources.subtitle';
  const relativeStep = 1 / 2;

  const onSelectFundingSource = (selectedFundingSource) => {
    const analyticProperty = selectedFundingSource === CardTypes.CREDIT ? 'add-credit-card' : 'add-debit-card';
    const addBankAccountUrl = isNewPlaidMDRecoveryFlow
      ? onboardingLocations.fundingSources.bank.addFromPayBatchFlow.base
      : onboardingLocations.fundingSources.bank.select;
    switch (selectedFundingSource) {
      case 'ach':
        analytics.track(eventPage, 'add-bank-account');
        navigate(addBankAccountUrl);
        break;
      case CardTypes.CREDIT:
      case CardTypes.DEBIT:
        analytics.track(eventPage, analyticProperty);
        navigate(onboardingLocations.fundingSources.card.index);
        break;
      default:
        break;
    }
  };

  const goExit = () => {
    analytics.track(eventPage, 'exit');

    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState();
    } else if (navigateWithPreservedState) {
      navigateWithPreservedState();
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  /* eslint-disable @typescript-eslint/no-empty-function */
  return (
    <MCBStepLayoutPage
      title={title}
      subtitle={subtitle}
      goExit={goExit}
      relativeStep={relativeStep}
      innerSize={50}
      isHideActions
      footer={<MISecurityDetails eventPage={eventPage} />}
    >
      <FundingSourcesListLayout fundingSources={[]} onAddMethod={onSelectFundingSource} onChange={() => {}} />
    </MCBStepLayoutPage>
  );
  /* eslint-enable @typescript-eslint/no-empty-function */
}

export { FundingSourcePageContainer };
