import Box from 'src/core/ds/box';
import { ReactComponent as Amex } from 'src/icons/symbols/amex.svg';
import { ReactComponent as BankOfAmerica } from 'src/icons/symbols/bankOfAmerica.svg';
import { ReactComponent as Chase } from 'src/icons/symbols/chase.svg';
import { ReactComponent as CitiBank } from 'src/icons/symbols/citiBank.svg';
import { ReactComponent as CreditKey } from 'src/icons/symbols/creditkey.svg';
import { ReactComponent as Diners } from 'src/icons/symbols/diners.svg';
import { ReactComponent as Discover } from 'src/icons/symbols/discover.svg';
import { ReactComponent as Freshbooks } from 'src/icons/symbols/freshbooks.svg';
import { ReactComponent as Google } from 'src/icons/symbols/google.svg';
import { ReactComponent as MasterCard } from 'src/icons/symbols/mastercard.svg';
import { ReactComponent as Melio } from 'src/icons/symbols/melio.svg';
import { ReactComponent as Privacy } from 'src/icons/symbols/privacy.svg';
import { ReactComponent as Quickbooks } from 'src/icons/symbols/quickbooks.svg';
import { ReactComponent as SvbBank } from 'src/icons/symbols/svbBank.svg';
import { ReactComponent as UsBank } from 'src/icons/symbols/usBank.svg';
import { ReactComponent as Visa } from 'src/icons/symbols/visa.svg';
import { ReactComponent as WellsFargo } from 'src/icons/symbols/wellsFargo.svg';
import { ReactComponent as Xero } from 'src/icons/symbols/xero.svg';
import { BrandName } from './consts';

type BrandMiniLogoProps = {
  name: BrandName;
};

const SYMBOLS_MAP = {
  [BrandName.discoverSymbol]: <Discover />,
  [BrandName.freshbooksSymbol]: <Freshbooks />,
  [BrandName.melioSymbol]: <Melio />,
  [BrandName.privacySymbol]: <Privacy />,
  [BrandName.amexSymbol]: <Amex />,
  [BrandName.bankOfAmericaSymbol]: <BankOfAmerica />,
  [BrandName.chaseSymbol]: <Chase />,
  [BrandName.citiBankSymbol]: <CitiBank />,
  [BrandName.dinersSymbol]: <Diners />,
  [BrandName.googleSymbol]: <Google />,
  [BrandName.masterCardSymbol]: <MasterCard />,
  [BrandName.quickBooksSymbol]: <Quickbooks />,
  [BrandName.svbBankSymbol]: <SvbBank />,
  [BrandName.usBank]: <UsBank />,
  [BrandName.visaSymbol]: <Visa />,
  [BrandName.wellsFargoSymbol]: <WellsFargo />,
  [BrandName.xeroSymbol]: <Xero />,
  [BrandName.creditKey]: <CreditKey />,
};

const BrandMiniLogo = ({ name = BrandName.default }: BrandMiniLogoProps) => (
  <Box as="svg" width="2.4rem" height="2.4rem">
    {name === BrandName.default ? null : SYMBOLS_MAP[name]}
  </Box>
);
export { BrandMiniLogo };
