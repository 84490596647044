import { Flex } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PayListItemEntityType } from 'src/modules/bills/types';
import { useMarkableBills } from '../../BillsGallery/components/useMarkableBills';

type Props = {
  billIds: string[];
};

export const PartialBillsWarning: React.FC<Props> = ({ billIds }) => {
  const { nonMarkableBills } = useMarkableBills(billIds);

  return (
    <Flex mb="10" flexDirection="column">
      <Flex mb="8">
        <span>
          <MIFormattedText
            label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.partialMarked"
            values={{ from: billIds.length - nonMarkableBills.length, to: billIds.length }}
          />
        </span>
      </Flex>
      <Flex mb="3">
        <span>
          <MIFormattedText
            label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.notMarkedText"
            values={{ billsCount: nonMarkableBills.length }}
          />
        </span>
      </Flex>
      <Flex mb="4" flexDir="column">
        {nonMarkableBills.map((bill) => (
          <Flex mb="4">
            <span>
              <MIFormattedText
                key={bill.id}
                label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.singleNotMarkedBill"
                values={{
                  vendorName: bill.vendorCompanyName,
                  totalAmount: bill.type === PayListItemEntityType.Bill ? bill.balance : bill.totalAmount,
                }}
              />
            </span>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
