import { TimelineEventType } from 'src/pages/get-pro/types';
import { fetchRequest } from 'src/services/api/api';

type GetTimelineParams = {
  orgId: number;
  entityId: string;
  entityType: string;
};

export const timelineApi = {
  getTimeline({ orgId, entityId, entityType }: GetTimelineParams): Promise<{ timelineEvents: TimelineEventType[] }> {
    const url = `/orgs/${orgId}/pro/timeline`;

    return fetchRequest(url, { entityId, entityType });
  },
};
