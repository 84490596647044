import get from 'lodash/get';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RetrievedTokenizedData } from 'src/components/basis-theory/link-card-view/LinkCardViewContainer';
import { ScrollToTop } from 'src/components/layout/ScrollToTop';
import { useApi } from 'src/hoc/useApi';
import { useMounted } from 'src/hooks/useMounted';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { AddCardFundingSource } from 'src/pages/onboarding/funding-sources/card/add-card/basis-theory/AddCardFundingSource';
import { AddCardFundingSourceErrorModal } from 'src/pages/onboarding/funding-sources/card/add-card/basis-theory/AddCardFundingSourceErrorModal';
import { getCardHolderNavigationState } from 'src/pages/onboarding/funding-sources/card/add-card/basis-theory/utils';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { capture } from 'src/utils/error-tracking';
import locations from 'src/utils/locations';

const navigationEventPage = 'payment-method-credit-card';
const eventPage = 'basis-theory';

type Props = {
  location: { state: { redirectUrl: string } };
};

export const AddCardFundingSourceContainer = ({ location }: Props) => {
  const { isMounted } = useMounted();
  const { onApiCall: onValidateCard, loading: shouldShowLinkCardLoader } = useApi({
    api: financialAccountsApi.validateCard,
  });
  const [isCardDetailsViewLoading, setIsCardDetailsViewLoading] = useState(true);
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const orgId = useSelector(getOrgId);
  const { navigate, navigateToExitWithPreservedState } = usePreservedStateNavigator();

  const navigateOnError = () => {
    navigateToExitWithPreservedState
      ? navigateToExitWithPreservedState()
      : navigate(onboardingLocations.fundingSources.index, false, {
          isNewPlaidMDRecoveryFlow: true,
        });
  };

  const handleNext = () => {
    analytics.track(navigationEventPage, 'link-credit-card', { vaulting: eventPage });
    navigate(onboardingLocations.fundingSources.card.cardholder, false);
  };

  const handleExit = () => {
    analytics.track(navigationEventPage, 'exit', { vaulting: eventPage });
    navigateToExitWithPreservedState ? navigateToExitWithPreservedState() : navigate(locations.MainApp.dashboard.url());
  };

  const handleError = () => {
    analytics.track(eventPage, 'iframe-error-response');
    navigateOnError();
    capture(new Error('iframe-error-response-test'), 'basis-theory iframe-error-response');
  };

  const handleLoaded = () => {
    setIsCardDetailsViewLoading(false);
  };

  const handleLinkCardSuccess = ({
    id,
    last4Digits,
    expirationMonth,
    expirationYear,
    cardBin,
  }: {
    id: string;
    last4Digits: string;
    expirationMonth: string;
    expirationYear: string;
    cardBin: string;
  }) => {
    analytics.track(eventPage, 'iframe-response-success');

    navigate(onboardingLocations.fundingSources.card.cardholder, false, {
      ...getCardHolderNavigationState({
        orgId,
        tokenId: id,
        last4Digits,
        expirationMonth,
        expirationYear,
        redirectUrl: location?.state?.redirectUrl,
        cardBin,
      }),
    });
  };

  const handleLinkCardFailure = (e: any) => {
    setErrorCode(get(e, 'code') || get(e, 'response.status'));
  };

  const handleLinkCard = async ({
    id,
    expirationMonth,
    expirationYear,
    last4Digits,
    cardBin,
    cardNumberIdentifiers,
  }: RetrievedTokenizedData) => {
    analytics.track(eventPage, 'iframe-response');
    analytics.track(eventPage, 'iframe-response-data');

    if (!id) navigateOnError();
    else {
      try {
        await onValidateCard(orgId, {
          token: id,
          cardBin,
          cardNumberFingerprint: cardNumberIdentifiers.fingerprint,
          cardNumberToken: cardNumberIdentifiers.token,
        });

        if (isMounted()) {
          handleLinkCardSuccess({ id, last4Digits, expirationMonth, expirationYear, cardBin });
        }
      } catch (e) {
        if (isMounted()) {
          handleLinkCardFailure(e);
        }
      }
    }
  };

  const handleCloseErrorModal = () => {
    setErrorCode(undefined);
  };

  return (
    <ScrollToTop>
      <>
        <AddCardFundingSourceErrorModal
          isOpen={!!errorCode}
          onClose={handleCloseErrorModal}
          bodyLabel={`server.${errorCode}`}
        />
        <AddCardFundingSource
          onLinkCard={handleLinkCard}
          onNext={handleNext}
          onExit={handleExit}
          onError={handleError}
          isLoading={isCardDetailsViewLoading}
          showLinkCardButtonSpinner={shouldShowLinkCardLoader}
          onLoaded={handleLoaded}
        />
      </>
    </ScrollToTop>
  );
};
