import { TaxIdType } from './consts';
import { CompanyInfoType } from './types';

function getTaxIdTypesOptions() {
  return [
    { id: TaxIdType.EIN, label: 'companyInfo.taxIdTypes.EIN' },
    { id: TaxIdType.SSN, label: 'companyInfo.taxIdTypes.SSN' },
    { id: TaxIdType.ITIN, label: 'companyInfo.taxIdTypes.ITIN' },
  ];
}

function getMissedCompanyInfoFields(companyInfo: CompanyInfoType): string[] {
  const missedFields: string[] = [];

  if (!companyInfo.companyName) {
    missedFields.push('companyName');
  }

  const isAddressFilled = !!(companyInfo.city && companyInfo.zipCode && companyInfo.addressLine1 && companyInfo.state);

  if (!isAddressFilled) {
    missedFields.push('address');
  }

  if (!companyInfo.phone) {
    missedFields.push('phone');
  }

  if (!(companyInfo.contactFirstName && companyInfo.contactLastName)) {
    missedFields.push('contactFirstName', 'contactLastName');
  }

  return missedFields;
}

export { getMissedCompanyInfoFields, getTaxIdTypesOptions };
