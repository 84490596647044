import { getMaxLength } from '@melio/sizzers-js-common';
import * as React from 'react';
import { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import { ButtonSizes, ButtonVariants } from 'src/core/ds/button/consts';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';

export enum PreferredDuplicateVendorAction {
  Existing = 'existing',
  New = 'new',
}

export type DuplicateVendorNameModalProps = {
  dismiss: (event: React.MouseEvent) => void;
  vendorName: string;
  onClickSave: (newVendorName: string) => void;
  cancelLabel: string;
  saveLabel: string;
  onClickCancel: () => void;
  isDuplicateName: (name: string) => boolean;
  preferredAction: PreferredDuplicateVendorAction;
};

export const DuplicateVendorNameModal = ({
  dismiss,
  vendorName,
  onClickSave,
  onClickCancel,
  cancelLabel = 'cancelButton',
  saveLabel = 'saveButton',
  isDuplicateName = () => false,
  preferredAction = PreferredDuplicateVendorAction.New,
}: DuplicateVendorNameModalProps) => {
  const [newVendorName, setNewVendorName] = useState<string>(`${vendorName.toString()} - New`);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  const onClickUseExistingVendorButton = (event: React.MouseEvent) => {
    onClickCancel();
    dismiss(event);
  };

  const onClickSaveButton = (event: React.MouseEvent) => {
    const newVendorNameNoSpaces = newVendorName.trim();

    if (isDuplicateName(newVendorNameNoSpaces)) {
      setDisplayErrorMessage(true);

      return;
    }

    if (newVendorNameNoSpaces !== '') {
      onClickSave(newVendorNameNoSpaces);
      dismiss(event);
    }
  };

  const onNewVendorInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setDisplayErrorMessage(false);
    setNewVendorName(value);
  };

  return (
    <ModalMessage
      alignLeft
      titleComponent={
        <>
          <Box textStyle="h2Semi">
            <MIFormattedText label="vendorDirectory.duplicateNameModal.title" />
          </Box>
          <Box mt={3} maxW="43rem" textStyle="body2">
            <MIFormattedText label="vendorDirectory.duplicateNameModal.subTitle" />
          </Box>
        </>
      }
      contentComponent={
        <TextField
          id="newVendorName"
          label="vendorDirectory.duplicateNameModal.inputField.title"
          placeholder="vendorDirectory.duplicateNameModal.inputField.placeholder"
          errorMessage={displayErrorMessage ? 'vendorDirectory.duplicateNameModal.notice' : ''}
          maxLength={getMaxLength('vendor', 'companyName')}
          onChange={onNewVendorInputChange}
          value={newVendorName}
          size="sm"
          mb={0}
          autoFocus
          isRequired
        />
      }
      buttonComponent={
        <Flex
          justify="flex-end"
          direction={{ base: 'column-reverse', md: 'row' }}
          position={{ base: 'fixed', md: 'initial' }}
          bottom={{ base: '0' }}
          right={{ base: '0' }}
          left={{ base: '0' }}
          boxShadow={{
            base: '0 -0.4rem 1.5rem 0 rgba(0, 0, 0, 0.05)',
            md: 'none',
          }}
          p={{ base: '2rem', md: '0' }}
        >
          <Button
            size={ButtonSizes.md}
            variant={
              preferredAction === PreferredDuplicateVendorAction.New
                ? ButtonVariants.tertiaryNaked
                : ButtonVariants.primary
            }
            testId={preferredAction === PreferredDuplicateVendorAction.New ? 'secondary-button' : 'primary-button'}
            onClick={onClickUseExistingVendorButton}
            label={`vendorDirectory.duplicateNameModal.${cancelLabel}`}
          />
          <Button
            size={ButtonSizes.md}
            variant={
              preferredAction === PreferredDuplicateVendorAction.New
                ? ButtonVariants.primary
                : ButtonVariants.tertiaryNaked
            }
            testId={preferredAction === PreferredDuplicateVendorAction.New ? 'primary-button' : 'secondary-button'}
            onClick={onClickSaveButton}
            label={`vendorDirectory.duplicateNameModal.${saveLabel}`}
          />
        </Flex>
      }
    />
  );
};
