import { MouseEvent as ReactMouseEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { GlobalState } from 'src/redux/types';
import { analytics } from 'src/services/analytics';
import { UnhideClientModalMessageButtons } from './components/UnhideClientModalMessageButtons';
import { UnhideClientModalMessageContent } from './components/UnhideClientModalMessageContent';
import { UnhideClientModalMessageTitle } from './components/UnhideClientModalMessageTitle';

type Props = {
  companyName: string;
  dismiss: (event: ReactMouseEvent) => void;
  onConfirm: (event: ReactMouseEvent) => void;
  orgId: number;
};

export const UnhideClientModalMessage = ({ companyName, dismiss, onConfirm, orgId }: Props) => {
  const isLoading = useSelector((state: GlobalState) => state.users.hideFirmClient.loading);

  useEffect(() => {
    if (orgId) {
      analytics.trackAction('unhide-client-modal-opened', { hiddenOrganizationId: orgId });
    }
  }, [orgId]);

  const handleDismissClick = (e: ReactMouseEvent) => {
    analytics.trackAction('unhide-client-modal-dismissed', { hiddenOrganizationId: orgId });
    dismiss(e);
  };

  return (
    <ModalMessage
      id="unhide-client-modal"
      titleComponent={<UnhideClientModalMessageTitle companyName={companyName} />}
      contentComponent={<UnhideClientModalMessageContent />}
      buttonComponent={
        <UnhideClientModalMessageButtons onConfirm={onConfirm} onCancel={handleDismissClick} isLoading={isLoading} />
      }
      onCloseClick={handleDismissClick}
    />
  );
};
