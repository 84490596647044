import isEmpty from 'lodash/isEmpty';
import { PayListFilters } from 'src/pages/pay/components/filters/types';
import { isBillHasPartialPayments, serializePaymentId } from 'src/utils/bills';
import { PayTabs } from 'src/utils/consts';
import { PayListItemType } from '../bills/types';
import { PR_AS_BILL_ID_PREFIX, SCANNED_INVOICE_PREFIX } from './consts';

export const convertPayFilters = (filters: PayListFilters) => ({
  itemStatus: filters.itemStatus?.join(','),
  vendorId: filters.vendorId?.join(','),
  dueDate: filters.dueDate?.join(','),
});

export const mapItemsResponse = (res: PayListItemType[], type: PayTabs): PayListItemType[] => {
  const getSerializedId = (item) => {
    if (type === PayTabs.INBOX && isBillHasPartialPayments(item)) {
      return item.billId;
    }

    if (!isEmpty(item.payments)) {
      return serializePaymentId(item.billId, item.payments[0].id);
    }

    if (item.requestId) {
      return `${PR_AS_BILL_ID_PREFIX}-${item.requestId}`;
    }

    if (item.scannedInvoiceId) {
      return `${SCANNED_INVOICE_PREFIX}-${item.scannedInvoiceId}`;
    }

    return item.billId;
  };

  const items =
    res?.map((item) => ({
      ...item,
      id: getSerializedId(item),
    })) || [];

  return items;
};
