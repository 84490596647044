const searchInputStyle = {
  'input[type=search]::-webkit-search-cancel-button': {
    display: 'none',
  },
};

const inputStyle = { ps: 12, h: 'fit-content' };

const inputRightElementStyle = { w: 'fit-content', pe: 4, h: 'full' };

const inputLeftElementStyle = { ps: 4, w: 'fit-content', h: 'full' };

const dateInputStyle = {
  _readOnly: {
    boxShadow: 'none !important',
    userSelect: 'all',
  },
};

export { dateInputStyle, inputLeftElementStyle, inputRightElementStyle, inputStyle, searchInputStyle };
