import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import doneImage from 'src/images/general/check-circle.svg';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { vendorLocations } from 'src/pages/vendor/locations';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { ConvertedUnilateralRequestActivity } from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralRequestActivity';
import {
  UnilateralDeliveryMethodType,
  UnilateralPaymentProps,
} from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { getDeliveryMethods, getIsLoggedIn } from 'src/redux/user/selectors';
import { DeliveryType } from 'src/utils/consts';
import { deliveryTypePredicate } from 'src/utils/delivery-methods';
import { SignUpOffer } from './components/SignUpOffer';
import { getSuccessSubtitle, getSuccessTitle, shouldOfferSignUpToVendor } from './utils';

type Props = {
  newDeliveryMethod: UnilateralDeliveryMethodType;
} & UnilateralPaymentProps;

export const AddDeliveryMethodSuccessPage = (props: Props) => {
  const history = useHistory();
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const { token, newDeliveryMethod } = props;
  const { paymentId, vendorId, orgId } = getJWTPayload(token);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const paymentActions = useStoreActions(paymentsStore);
  useEffect(() => {
    if (paymentId && !payment) {
      paymentActions.fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' }).catch(() => {
        history.push(generatePath(vendorLocations.unilateral.invalidToken, { token }));
      });
    }
  }, [paymentId, paymentActions, token, history, payment]);
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id || orgId));
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor = useSelector(fetchSelector.byId(payment?.vendor?.id || vendorId));
  const isVirtualCardDeliveryMethodExist = vendor?.deliveryMethods?.some(
    (dm) => dm.deliveryType === DeliveryType.VIRTUAL_CARD
  );
  const deliveryMethods = useSelector(deliveryMethodsStore.selectors.all);
  const deliveryMethod =
    useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId)) ||
    deliveryMethods?.find(deliveryTypePredicate(newDeliveryMethod?.deliveryType)) ||
    orgDeliveryMethods[0];
  const isAchMethod = deliveryMethod?.deliveryType === DeliveryType.ACH;
  const isVirtualCardMethod = deliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD;
  const shouldOfferSignUp = shouldOfferSignUpToVendor(isVirtualCardDeliveryMethodExist, isLoggedIn, deliveryMethod);
  const analyticsProps = {
    flow: payment ? 'Unilateral with payment' : 'Unilateral without payment',
    vendorId: vendor?.id,
    vendorEmail: vendor?.contactEmail,
    payorOrgId: organization?.id,
  };
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout analyticsProps={analyticsProps}>
      <Flex direction="column">
        <Box px={{ base: 10, sm: 5 }} py={{ base: 10, sm: 10 }}>
          <Image src={doneImage} w="4rem" h="4rem" mb={2} />
          <Box data-testid="success-title" textStyle="body2Semi" mb={1}>
            <MIFormattedText
              label={getSuccessTitle(paymentId, isAchMethod, isVirtualCardMethod, isVirtualCardDeliveryMethodExist)}
            />
          </Box>
          <Box textStyle="body2Semi" color="grey.700">
            <MIFormattedText
              label={getSuccessSubtitle(paymentId, isAchMethod, isVirtualCardMethod, isVirtualCardDeliveryMethodExist)}
              values={{
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
                companyName: organization?.companyName,
                invoiceNumber: bill.invoiceNumber,
                virtualCardAccountEmail: deliveryMethod?.virtualCardAccount?.accountEmail,
              }}
            />
          </Box>
          {paymentId ? (
            <ConvertedUnilateralPaymentActivity payment={payment} />
          ) : (
            <ConvertedUnilateralRequestActivity deliveryMethod={deliveryMethod} />
          )}
        </Box>
        {shouldOfferSignUp && (
          <SignUpOffer payment={payment} deliveryMethod={deliveryMethod} vendor={vendor} organization={organization} />
        )}
      </Flex>
    </OutsideLayout>
  );
};
