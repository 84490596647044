import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import { paymentsApi } from 'src/modules/payments/api';
import { paymentsStore } from 'src/modules/payments/payment-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { vendorLocations } from 'src/pages/vendor/locations';
import { useForm } from 'src/ui/form';
import { BANK_ACCOUNT_EXIST, BankAccountType, DeliveryType } from 'src/utils/consts';
import { deliveryTypePredicate } from 'src/utils/delivery-methods';
import { capture } from 'src/utils/error-tracking';
import { VirtualCardType } from 'src/utils/types';
import { eventType } from '../consts';
import { useSuvcToAchAnalytics } from '../hooks/useSuvcToAchAnalytics';
import { StateAndActionsReturnsProps, TokenValuesType } from '../types';
import { getReadableBankAccountFailedReason } from '../utils';

type UseStateAndActionsProps = {
  token: string;
  tokenValues: TokenValuesType;
};

export const useStateAndActions = ({ token, tokenValues }: UseStateAndActionsProps): StateAndActionsReturnsProps => {
  const history = useHistory();
  const intl = useIntl();
  const { deliveryMethodId, orgId, vendorId, paymentId, virtualCardId } = tokenValues;
  const { trackEvent } = useSuvcToAchAnalytics(tokenValues);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const deliveryMethodDetails = useSelector(deliveryMethodsStore.selectors.byId(tokenValues?.deliveryMethodId));
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const paymentActions = useStoreActions(paymentsStore);
  const actions = getDeliveryMethodActions(useDispatch());
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor = useSelector(fetchSelector.byId(payment?.vendor?.id || vendorId));
  const updatingDeliveryMethodId = vendor?.deliveryMethods?.find(deliveryTypePredicate(DeliveryType.ACH))?.id;

  const [virtualCardState, setVirtualCardState] = useState<{ virtualCard: VirtualCardType }>({
    virtualCard: {} as VirtualCardType,
  });
  const [formValues, setFormValues] = useState({
    id: null,
    routingNumber: '',
    accountNumber: '',
  });

  const { onApiCall: getDeliveryMethod } = useApi({
    api: deliveryMethodsApi.getDeliveryMethodACHWithToken,
  });

  const { invalidTokenData, loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation);

  const editMode = !!formValues.id;

  const model = useMemo(
    () => ({
      deliveryType: DeliveryType.ACH,
      bankAccount: {
        accountType: BankAccountType.CHECKING,
        routingNumber: formValues.routingNumber,
        accountNumber: formValues.accountNumber,
      },
    }),
    [formValues.routingNumber, formValues.accountNumber]
  );

  const validateBankAccount = async (values) => {
    const bankAccountValidationErrors = getValidationErrors('fundingSourceBank', values.bankAccount, [
      'routingNumber',
      'accountNumber',
    ]);

    deliveryMethod.setValidationErrors({
      'bankAccount.routingNumber': bankAccountValidationErrors?.routingNumber || '',
      'bankAccount.accountNumber': bankAccountValidationErrors?.accountNumber || '',
    });

    if (!isEmpty(bankAccountValidationErrors)) {
      const errorString = getReadableBankAccountFailedReason(bankAccountValidationErrors);
      trackEvent(eventType, 'achDetails-failed', {
        failedReason: intl.formatMessage({ id: errorString }),
      });

      return false;
    }

    trackEvent(eventType, 'achDetails-continue');

    return true;
  };

  const [deliveryMethod, { submit }, validationErrors] = useForm(model, {
    submit: validateBankAccount,
  });

  const updateDeliveryMethod = async (values) => {
    if (updatingDeliveryMethodId) {
      await deliveryMethodActions.updateWithUnilateralToken({
        token,
        deliveryMethod: {
          ...values,
          isFilledByVendor: true,
          id: deliveryMethodId,
        },
        vendorId,
        paymentId: payment?.id,
        id: updatingDeliveryMethodId || values?.id,
        orgId,
      });
    } else {
      await deliveryMethodActions.replaceVirtualDeliveryMethod({
        token,
        deliveryMethod: {
          ...values,
          isFilledByVendor: true,
          id: payment?.deliveryMethodId || deliveryMethodId,
        },
        paymentId: payment?.id,
      });
    }

    await paymentActions.fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' });
  };

  const createDeliveryMethod = async (values) => {
    await actions.shiftVirtualCardToAch({
      token,
      deliveryMethod: {
        ...values,
        isFilledByVendor: true,
        id: deliveryMethodId,
      },
      paymentId: payment?.id,
      id: updatingDeliveryMethodId || values?.id,
      vendorId,
      orgId,
    });

    await paymentActions.fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' });
  };

  const submitBankAccount = async () => {
    trackEvent(eventType, 'acceptPayment-click');
    const values = {
      ...model,
      bankAccount: {
        ...model.bankAccount,
        routingNumber: deliveryMethod.bankAccount.routingNumber.value,
        accountNumber: deliveryMethod.bankAccount.accountNumber.value,
      },
    };

    if (editMode) {
      return updateDeliveryMethod(values);
    }

    return createDeliveryMethod(values);
  };

  const initialState = async () => {
    try {
      const response = await getDeliveryMethod({ token });

      if (response?.deliveryMethod?.bankAccount) {
        const { deliveryMethod } = response;
        const { routingNumber, accountNumber } = deliveryMethod?.bankAccount;
        setFormValues({
          id: deliveryMethod?.id,
          routingNumber,
          accountNumber,
        });
      }
    } catch (err: any) {
      capture(err, {
        message: `Can not fetch delivery method details using token: ${token}`,
      });
      history.push(generatePath(vendorLocations.shiftVendorToAch.invalidToken, { token, id: vendorId }));
    }
  };

  const resetFormValues = () => {
    setFormValues((prevState) => ({
      ...prevState,
      routingNumber: '',
      accountNumber: '',
    }));

    deliveryMethod.setModelState((prevState) => ({
      ...prevState,
      bankAccount: {
        ...prevState.bankAccount,
        routingNumber: '',
        accountNumber: '',
      },
    }));
  };

  useEffect(() => {
    initialState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    paymentsApi
      .getVirtualCardData({
        token,
        virtualCardId: virtualCardId?.toString(),
      })
      .then((response) => {
        const { virtualCard } = response;
        setVirtualCardState({
          virtualCard,
        });
      })
      .catch((err) => {
        capture(err, {
          message: `Can not fetch virtual card details using token: ${token}`,
        });
        history.push(generatePath(vendorLocations.shiftVendorToAch.invalidToken, { token, id: vendorId }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (invalidTokenData && invalidTokenData?.code) {
      if (invalidTokenData?.code === BANK_ACCOUNT_EXIST) {
        validationErrors['bankAccount.accountNumber'] = `server.${invalidTokenData?.code}`;
      } else {
        history.push(generatePath(vendorLocations.shiftVendorToAch.invalidToken, { token, id: vendorId }));
      }
    }
  }, [invalidTokenData, history, token, validationErrors, vendorId]);

  return {
    actions: {
      resetFormValues,
      submitBankAccount,
      validateBankAccount: submit,
    },
    state: { isLoading, deliveryMethodMV: deliveryMethod, virtualCardState, deliveryMethod: deliveryMethodDetails },
  };
};
