import { getMaxLength } from '@melio/sizzers-js-common';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getIsIntuitLinked } from 'src/redux/user/selectors';
import { REGEX } from 'src/utils/consts';
import { formatCheckPrintName } from 'src/utils/delivery-methods';
import { AddressType } from 'src/utils/types';

type Props = {
  onNext: (printName: string, address?: AddressType) => void;
  goExit: () => void;
  printName: string;
  isLoading: boolean;
  validationErrors: Record<'printName', string>;
  getVendorInfo: () => void;
};

const EditCheckNameDeliveryMethodPage = ({
  onNext,
  goExit,
  printName,
  isLoading,
  validationErrors,
  getVendorInfo,
}: Props) => {
  const match = useRouteMatch<{
    orgId: string;
    id?: string;
    deliveryMethodId: string;
  }>();
  const [currentPrintName, changeCurrentPrintName] = useState(formatCheckPrintName(printName));
  const [isInvalidChar, setIsInvalidChar] = useState(false);
  const errorMessage = isInvalidChar
    ? 'inputErrors.deliveryMethodCheck.printName.any.invalidChar'
    : validationErrors.printName;
  const vendorId = match.params?.id ? Number(match.params.id) : undefined;
  const vendor = useSelector(vendorsStore.selectors.byId(vendorId));
  const vendorAddress = match.params?.deliveryMethodId ? undefined : vendor?.address;
  const isVendorInfoLoading = useSelector((state) => vendorsStore.selectors.vendorInfo.loading(state, vendorId));
  const isIntuitLinked = useSelector(getIsIntuitLinked);
  const { isConnected } = useFetchAccountingPlatformsAndData();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    const isInvalid = REGEX.CHECK_PRINT_NAME.test(value);
    setIsInvalidChar(isInvalid);

    if (isInvalid) {
      const formattedValue = value.replace(REGEX.CHECK_PRINT_NAME, '');
      changeCurrentPrintName(formattedValue);
    } else {
      changeCurrentPrintName(value);
    }
  };

  const onSubmit = () => {
    onNext(formatCheckPrintName(currentPrintName), vendorAddress);
  };

  useEffect(() => {
    if (isConnected || isIntuitLinked) {
      getVendorInfo();
    }
  }, [isConnected, isIntuitLinked, getVendorInfo]);

  return (
    <StepLayoutPage
      title="vendors.deliveryMethods.check.name.title"
      goExit={goExit}
      onSubmit={onSubmit}
      isLoading={isLoading || isVendorInfoLoading}
      hideHeader
    >
      <TextInputContainer>
        <TextField
          id="printName"
          value={currentPrintName}
          label="vendors.deliveryMethods.check.name.label"
          onChange={onChange}
          errorMessage={errorMessage}
          autoComplete="off"
          helperText="vendors.deliveryMethods.check.name.notice"
          maxLength={getMaxLength('deliveryMethodCheck', 'printName')}
          autoFocus
          isRequired
        />
      </TextInputContainer>
    </StepLayoutPage>
  );
};

export default EditCheckNameDeliveryMethodPage;

const TextInputContainer = styled.div`
  margin-bottom: 4rem;
  & input {
    text-transform: uppercase;
  }
`;
