import { CopyEmailLink } from 'src/components/common/CopyLink/CopyEmailLink';
import { MICard, MICardField, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';
import { BILL_INBOX_LINK } from 'src/pages/settings/constants';
import { CopyLinkSize } from 'src/utils/consts';
import { getEmailHref } from 'src/utils/links';

type Props = {
  inboxEmailAddress?: string | null;
  isBillInboxEnabled?: boolean;
  onCopyEmail: () => void;
};

export const PayInbox = ({ inboxEmailAddress, onCopyEmail, isBillInboxEnabled }: Props) => {
  const inboxEmailLink = inboxEmailAddress ? getEmailHref(inboxEmailAddress) : '';

  return isBillInboxEnabled && inboxEmailAddress ? (
    <MICard>
      <MICardForm>
        <Badge mb={2} label="general.new" />
        <MICardTitle label="settings.payInbox.title" />
        <Box textStyle="body2" mb={4} mt={6}>
          <MIFormattedText
            label="settings.payInbox.subtitle"
            values={{ link: (...chunks) => <Link href={BILL_INBOX_LINK}>{chunks}</Link> }}
          />
        </Box>
        <MICardField label="settings.payInbox.labelField">
          <CopyEmailLink
            onCopy={onCopyEmail}
            link={inboxEmailLink}
            clipboardText={inboxEmailAddress}
            size={CopyLinkSize.NORMAL}
          />
        </MICardField>
      </MICardForm>
    </MICard>
  ) : null;
};
