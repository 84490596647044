import { Switch, useHistory } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { globalLocations } from 'src/pages/locations';
import { QuickbookMultiOrgAddAccountingFirmAddress } from './components/QuickbookMultiOrgAddAccountingFirmAddress';
import { QuickbookMultiOrgAddAccountingFirmClientsAmount } from './components/QuickbookMultiOrgAddAccountingFirmClientsAmount';
import { QuickbookMultiOrgAddAccountingFirmDetails } from './components/QuickbookMultiOrgAddAccountingFirmDetails';
import { QuickbookMultiOrgAddAccountingFirmManualAddress } from './components/QuickbookMultiOrgAddAccountingFirmManualAddress';
import { QuickbookMultiOrgAddAccountingFirmName } from './components/QuickbookMultiOrgAddAccountingFirmName';

type Props = {
  onFinish: () => void;
};

export function QuickbookMultiOrgAddManualAccountingFirmRouter({ onFinish }: Props) {
  const history = useHistory();

  const onPrev = () => {
    history.goBack();
  };

  const onNext = (path: string) => () => {
    history.push(path);
  };

  const addAccountingFirmLocations = globalLocations.quickbookMultiOrgOnboarding.addAccountingFirm;

  return (
    <Switch>
      <SmartRoute path={addAccountingFirmLocations.details} exact>
        <QuickbookMultiOrgAddAccountingFirmDetails onNext={onNext(addAccountingFirmLocations.name)} onPrev={onPrev} />
      </SmartRoute>
      <SmartRoute path={addAccountingFirmLocations.name} exact>
        <QuickbookMultiOrgAddAccountingFirmName onPrev={onPrev} onNext={onNext(addAccountingFirmLocations.address)} />
      </SmartRoute>
      <SmartRoute path={addAccountingFirmLocations.address} exact>
        <QuickbookMultiOrgAddAccountingFirmAddress
          onPrev={onPrev}
          onNext={onNext(addAccountingFirmLocations.clientsAmount)}
          goToManual={onNext(addAccountingFirmLocations.manualAddress)}
        />
      </SmartRoute>
      <SmartRoute path={addAccountingFirmLocations.manualAddress} exact>
        <QuickbookMultiOrgAddAccountingFirmManualAddress
          onPrev={onPrev}
          onNext={onNext(addAccountingFirmLocations.clientsAmount)}
        />
      </SmartRoute>
      <SmartRoute path={addAccountingFirmLocations.clientsAmount} exact>
        <QuickbookMultiOrgAddAccountingFirmClientsAmount onPrev={onPrev} onNext={onFinish} />
      </SmartRoute>
    </Switch>
  );
}
