import * as vendor from 'src/pages/vendor/records';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { getPaymentRequestIdById } from 'src/utils/bills';
import { DeliveryType, VendorManagedByEnum } from 'src/utils/consts';
import { BillType } from 'src/utils/types';

export function getFirstFileId(bill: BillType) {
  return bill.files?.[0]?.id ?? null;
}

export function getPaymentRequestId(bill: BillType) {
  return getPaymentRequestIdById(bill.id);
}

export const getDeliveryMethodByType = (bill: BillType, type: DeliveryType) =>
  vendor.getDeliveryMethodByType(type, bill.vendor?.deliveryMethods ?? []);

export const getDeliveryMethodById = (bill: BillType, id: number) => {
  if (bill.vendor?.deliveryMethods && id) {
    return deliveryMethodFactory(bill.vendor?.deliveryMethods?.find((dm) => dm.id === id));
  }

  return null;
};

export const isBillBelongsToSharedVendor = (bill: Pick<BillType, 'vendor'>) =>
  bill?.vendor?.managedBy === VendorManagedByEnum.SHARED;
