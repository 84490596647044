import * as dateFns from 'date-fns';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { fetchBatchBillsListSlice } from 'src/modules/regular-batch-payments/regular-batch-bills-list-slice';
import { regularBatchBillsSettingsSlice } from 'src/modules/regular-batch-payments/regular-batch-bills-settings-slice';
import { createBatchPaymentSlice } from 'src/modules/regular-batch-payments/regular-batch-payments-create-slice';
import {
  BATCH_UPDATE_DEDUCTION_DATE,
  regularBatchUpdateDeductionDatesSlice,
} from 'src/modules/regular-batch-payments/regular-batch-update-deduction-dates-slice';
import {
  BATCH_UPDATE_DELIVERY_OPTIONS,
  regularBatchUpdateDeliveryOptionsSlice,
} from 'src/modules/regular-batch-payments/regular-batch-update-delivery-options-slice';
import {
  PAYMENT_UPDATE_DELIVERY_OPTIONS,
  regularBatchUpdatePaymentsSlice,
} from 'src/modules/regular-batch-payments/regular-batch-update-payments-slice';
import { ALL_AT_ONCE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { DateFormats } from 'src/utils/date-fns';
import vendorsStore from '../vendors/vendors-store';
import { batchPaymentsListName, name } from './consts';
import { BatchItemType } from './types';
import { getCurrentBatchItems, getReadyBatchItems } from './utils';

const persistConfig = {
  whitelist: ['settings'],
};

const syncTableListWithSetting = (state) => {
  const batchItems = getCurrentBatchItems(state);
  const fundingSourceIds = batchItems.map((batchItem) => batchItem.payment.fundingSourceId);

  const isMultipleFundingSource = uniq(fundingSourceIds).length > 1;

  if (!isMultipleFundingSource) {
    // eslint-disable-next-line prefer-destructuring
    state.settings.fundingSourceId = fundingSourceIds[0];
  }
};

const regularBatchPaymentsStore = createRestfulSlice<BatchItemType>({
  api: {},
  name,
  persistConfig,
  selectors: {
    isMultipleFundingSource: (state) => {
      if (!state.regularBatchPayments.settings?.billIds || !state.regularBatchPayments.byId) {
        return false;
      }

      const batchItems = getCurrentBatchItems(state.regularBatchPayments);
      const fundingSourceIds = batchItems.map((batchItem) => batchItem.payment.fundingSourceId);

      return uniq(fundingSourceIds).length > 1;
    },
    isMultipleDeductionDate: (state) => {
      if (!state.regularBatchPayments.settings?.billIds || !state.regularBatchPayments.byId) {
        return false;
      }

      const batchItems = getCurrentBatchItems(state.regularBatchPayments);

      const deductionDates = batchItems.map((batchItem) =>
        dateFns.format(new Date(batchItem.payment.scheduledDate), DateFormats.dayMonthYearDateSlash)
      );

      return (
        state.regularBatchPayments.settings?.dateOption === ALL_AT_ONCE_OPTION_ID && uniq(deductionDates).length > 1
      );
    },
    totalAmount: (state) =>
      sumBy(getCurrentBatchItems(state[name]), (batchItem: BatchItemType) =>
        batchItem.payment.deliveryMethodId ? batchItem.payment.amount : 0
      ),
    readyBillsCount: (isAmexAddressFeatureEnabled: boolean) => (state) =>
      getReadyBatchItems(state, isAmexAddressFeatureEnabled).length,
    allBillsReady: (isAmexAddressFeatureEnabled: boolean) => (state) =>
      getCurrentBatchItems(state[name]).length === getReadyBatchItems(state, isAmexAddressFeatureEnabled).length,
    totalCount: (state) => getCurrentBatchItems(state[name]).length,
    getMemo: (id: string) => (state) => state[name].byId[id]?.payment.note,
    getPaymentPurpose: (id: string) => (state) => ({
      purpose: state[name].byId[id]?.payment.purpose,
      purposeDescription: state[name].byId[id]?.payment.purposeDescription,
    }),
    byId: (id: string) => (state) => state[name].byId[id],
    getBillIds: (state) => state[name].settings.billIds,
  },
  extraReducers: {
    [`${BATCH_UPDATE_DELIVERY_OPTIONS}_SUCCESS`](state) {
      syncTableListWithSetting(state);
    },
    [`${BATCH_UPDATE_DEDUCTION_DATE}_SUCCESS`](state) {
      syncTableListWithSetting(state);
    },
    [`${PAYMENT_UPDATE_DELIVERY_OPTIONS}_SUCCESS`](state) {
      syncTableListWithSetting(state);
    },
    [vendorsStore.actions.update.type](state, action) {
      if (!action.payload.address) {
        return;
      }

      Object.keys(state.byId).forEach((id) => {
        const batchItem = state.byId[id];

        if (batchItem.payment?.vendor && batchItem.payment.vendor.id === action.payload.id) {
          batchItem.payment.vendor.address = action.payload.address;
        }
      });
    },
  },
  slices: {
    list: fetchBatchBillsListSlice,
    settings: regularBatchBillsSettingsSlice,
    createBatchPayment: createBatchPaymentSlice,
    updatePayments: regularBatchUpdatePaymentsSlice,
    updatePaymentsDeliveryOptions: regularBatchUpdateDeliveryOptionsSlice,
    updatePaymentsDeductionDates: regularBatchUpdateDeductionDatesSlice,
  },
  listHashFunc: () => batchPaymentsListName,
});

export default regularBatchPaymentsStore;
