import { generatePath } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { companiesLocations } from 'src/pages/companies/locations';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getProLocations } from 'src/pages/get-pro/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { QBO_PLATFORM_LABEL, RedirectOriginPages } from './consts';
import { receivingMethodLocations } from './locations';

type GetListBankAccountParamsType = {
  vendorId: number;
  orgId: number;
  deliveryMethodId: number;
};

export const getUrlByRedirectOrigin = (orgId: number, redirect?: RedirectOriginPages) => {
  const MAP_REDIRECT_URL = {
    [RedirectOriginPages.PAY_DASHBOARD]: billLocations.index,
    [RedirectOriginPages.PAID_DASHBOARD]: getProLocations.dashboard,
    [RedirectOriginPages.NEW_BILL]: billLocations.create.options,
    [RedirectOriginPages.NEW_REQUEST]: getPaidLocations.create.options,
    [RedirectOriginPages.RECEIVING_METHOD]: settingsLocations.receivingMethods,
    [RedirectOriginPages.SETTINGS]: settingsLocations.accountingSoftware,
    [RedirectOriginPages.PAY_SUCCESS]: onboardingLocations.accountingSoftware.quickbooks.success,
    [RedirectOriginPages.ACCOUNTANT_NEW_CLIENT]: companiesLocations.create.connectAccountingSoftwareReturn,
  };

  return generatePath(MAP_REDIRECT_URL[redirect || RedirectOriginPages.PAY_DASHBOARD], { orgId });
};

export const getListBankAccountPath = ({ vendorId, orgId, deliveryMethodId }: GetListBankAccountParamsType) =>
  generatePath(receivingMethodLocations.ach.linkBankAccount, {
    orgId,
    vendorId,
    deliveryMethodId,
  });

export const getPlatformLabel = (platformName = '') =>
  platformName.includes('Quickbooks') ? QBO_PLATFORM_LABEL : platformName;
