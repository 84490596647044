import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Redirect, Switch, useHistory } from 'react-router-dom';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import invoiceStore from 'src/modules/invoices/invoices-store';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { UploadInvoicesExplainPage } from 'src/pages/get-paid/create/batch/UploadInvoicesExplainPage';
import { UploadInvoicesPage } from 'src/pages/get-paid/create/batch/UploadInvoicesPage';
import { InvoiceOptionsPage } from 'src/pages/get-paid/create/InvoiceOptionsPage';
import { NewFileInvoicePage } from 'src/pages/get-paid/create/NewFileInvoicePage';
import NewInvoicePage from 'src/pages/get-paid/create/NewInvoicePage';
import { NewInvoiceSendPaymentRequestPage } from 'src/pages/get-paid/create/NewInvoiceSendPaymentRequestPage';
import { useGetProEnabled } from 'src/pages/get-pro/hooks/useGetProEnabled';
import { getProLocations } from 'src/pages/get-pro/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { GetProTabs, PaymentRequestTabs } from 'src/utils/consts';
import { useQueryState } from 'src/utils/hooks';
import { encodeQuery } from 'src/utils/query-utils';
import { getPaidLocations } from '../locations';
import { ConnectAccountingSoftwarePage } from './connectAccountingSoftware/ConnectAccountingSoftwarePage';
import { ConnectUnsupportedAccountingSoftwarePage } from './connectAccountingSoftware/ConnectUnsupportedAccountingSoftwarePage';
import { ConnectUnsupportedAccountingSoftwareSuccessPage } from './connectAccountingSoftware/ConnectUnsupportedAccountingSoftwareSuccessPage';
import { InvoiceManuallyImportPage } from './InvoiceManuallyImportPage';

export type PageProps = {
  onExit: () => void;
};

const CreateInvoiceRouter = () => {
  const history = useHistory<{ returnLocations?: any; file?: File }>();
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const [status] = useQueryState('status', undefined);
  const [lastLocation] = useState(history.location?.state?.returnLocations);
  const getProEnabled = useGetProEnabled();
  const { isConnected } = useFetchAccountingPlatformsAndData();
  const dashboardLocation = !getProEnabled ? getPaidLocations.dashboard : getProLocations.dashboard;
  useEffect(() => {
    dispatch(invoiceStore.actions.resetCreateInvoice.reset());
  }, [dispatch]);

  const goToUrl = (url: string, state: Record<string, any> = {}) => history.push(generatePath(url, { orgId }), state);

  const goToGetPaidDashboard = () => {
    if (getProEnabled) {
      history.push({
        pathname: generatePath(getProLocations.dashboard, { orgId }),
        search: encodeQuery({ status: GetProTabs.INVOICES }, [], ''),
      });
    } else {
      history.push({
        pathname: generatePath(getPaidLocations.dashboard, { orgId }),
        search: encodeQuery({ status: PaymentRequestTabs.UNSENT }, [], ''),
      });
    }
  };

  const onExit = () => (lastLocation ? history.push(lastLocation) : goToUrl(dashboardLocation, { orgId }));

  const onLinkAction = () => {
    goToUrl(getPaidLocations.create.batchInvoices.batchExplain);
  };

  const onGoToDashboardSelectedPaymentRequest = (id: string) => {
    if (getProEnabled) {
      history.push({
        pathname: generatePath(getProLocations.dashboard, { orgId }),
        search: encodeQuery({ status }, [], ''),
      });
    } else {
      history.push({
        pathname: generatePath(getPaidLocations.dashboard, { orgId }),
        search: encodeQuery({ id, status: PaymentRequestTabs.SENT }, [], ''),
      });
    }
  };

  const onShareInvoice = (id: string) => {
    history.push({
      pathname: generatePath(getPaidLocations.create.share, { orgId }),
      search: encodeQuery({ id }, [], ''),
    });
  };

  const onGoToDashboardSelectedInvoice = (id: string) => {
    if (getProEnabled) {
      history.push({
        pathname: generatePath(getProLocations.dashboard, { orgId }),
        search: encodeQuery({ status: GetProTabs.INVOICES }, [], ''),
      });
    } else {
      history.push({
        pathname: generatePath(getPaidLocations.dashboard, { orgId }),
        search: encodeQuery({ id, status: PaymentRequestTabs.UNSENT }, [], ''),
      });
    }
  };

  const onCreateManualInvoice = () => {
    history.push(generatePath(getPaidLocations.create.invoice, { orgId }));
  };

  const onPrev = () => history.goBack();

  return (
    <Switch>
      <SmartRoute path={getPaidLocations.create.options}>
        {isConnected ? (
          <Redirect to={generatePath(getPaidLocations.create.manually, { orgId })} />
        ) : (
          <InvoiceOptionsPage onExit={onExit} goToUrl={goToUrl} />
        )}
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.manually}>
        <InvoiceManuallyImportPage onPrev={onPrev} onExit={onExit} onLinkAction={onLinkAction} goToUrl={goToUrl} />
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.connectAccountingSoftware.connect} exact>
        <ConnectAccountingSoftwarePage onPrev={onPrev} onExit={onExit} />
      </SmartRoute>
      <SmartRoute
        path={getPaidLocations.create.connectAccountingSoftware.unsupportedAccountingSoftware.unsupported}
        exact
      >
        <ConnectUnsupportedAccountingSoftwarePage onPrev={onPrev} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.connectAccountingSoftware.unsupportedAccountingSoftware.success}>
        <ConnectUnsupportedAccountingSoftwareSuccessPage onPrev={onPrev} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.invoice}>
        <NewInvoicePage
          onExit={onExit}
          onShareInvoice={onShareInvoice}
          onGoToSelectedItem={onGoToDashboardSelectedInvoice}
        />
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.fileInvoice}>
        <NewFileInvoicePage
          onExit={onExit}
          onShareInvoice={onShareInvoice}
          onGoToSelectedItem={onGoToDashboardSelectedInvoice}
          onCreateManualInvoice={onCreateManualInvoice}
        />
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.share}>
        <NewInvoiceSendPaymentRequestPage
          onExit={onExit}
          onGoToSelectedItem={onGoToDashboardSelectedPaymentRequest}
          getProEnabled={getProEnabled}
        />
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.batchInvoices.batchExplain}>
        <UploadInvoicesExplainPage
          goExit={onExit}
          onPrev={onPrev}
          onNext={(file) => goToUrl(getPaidLocations.create.batchInvoices.batch, { file })}
        />
      </SmartRoute>
      <SmartRoute path={getPaidLocations.create.batchInvoices.batch}>
        <UploadInvoicesPage onExit={onExit} onPrev={onPrev} onNext={goToGetPaidDashboard} />
      </SmartRoute>
    </Switch>
  );
};

export default CreateInvoiceRouter;
