import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { FormRow } from 'src/core/ds/form';
import { TextField } from 'src/core/ds/form/fields';
import { ApplicantHeader } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/ApplicantHeader';
import { getDynamicFieldBuilder } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/beneficial-owners/utils';
import { DateOfBirthField } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/common-fields/DateOfBirthField';
import { OwnershipPercentageField } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/common-fields/OwnershipPercentageField';
import { SSNField } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/common-fields/SSNField';
import {
  BeneficialOwner,
  InternalBusinessOwnershipDetailsModel,
} from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { ModelView, ValidationErrors } from 'src/ui/form';

type Props = {
  modelView: ModelView<InternalBusinessOwnershipDetailsModel>;
  index: number;
  uuid: string;
  onRemoveBeneficialOwner(uuid: string): void;
  validationErrors: ValidationErrors<InternalBusinessOwnershipDetailsModel>;
};

export const BeneficialOwnerSubForm = ({
  modelView,
  index,
  uuid,
  onRemoveBeneficialOwner,
  validationErrors,
}: Props) => {
  const dynamicFieldBuilder = getDynamicFieldBuilder(uuid);
  const dynamicFieldAccess: BeneficialOwner = {
    firstName: dynamicFieldBuilder('firstName'),
    lastName: dynamicFieldBuilder('lastName'),
    email: dynamicFieldBuilder('email'),
    ownershipPercentage: dynamicFieldBuilder('ownershipPercentage'),
    dateOfBirth: dynamicFieldBuilder('dateOfBirth'),
    ssn: dynamicFieldBuilder('ssn'),
  };

  return (
    <>
      <FormRow mb={10}>
        <ApplicantHeader
          headerLabel="financing.businessOwnershipDetails.applicant.beneficialOwner.title"
          tooltipLabel="financing.businessOwnershipDetails.applicant.beneficialOwner.tooltip"
        />
      </FormRow>
      <FormRow mb={10}>
        <TextField
          testId={`input-beneficialOwner${index}-firstName`}
          label="financing.businessOwnershipDetails.fields.firstName.title"
          errorMessage={validationErrors[dynamicFieldAccess.firstName]}
          isRequired
          mb={0}
          model={modelView[dynamicFieldAccess.firstName]}
        />
        <TextField
          testId={`input-beneficialOwner${index}-lastName`}
          label="financing.businessOwnershipDetails.fields.lastName.title"
          errorMessage={validationErrors[dynamicFieldAccess.lastName]}
          isRequired
          mb={0}
          model={modelView[dynamicFieldAccess.lastName]}
        />
      </FormRow>
      <FormRow mb={10}>
        <TextField
          testId={`input-beneficialOwner${index}-email`}
          label="financing.businessOwnershipDetails.fields.email.title"
          errorMessage={validationErrors[dynamicFieldAccess.email]}
          isRequired
          mb={0}
          model={modelView[dynamicFieldAccess.email]}
        />
      </FormRow>
      <FormRow mb={10}>
        <OwnershipPercentageField
          testId={`input-beneficialOwner${index}-ownershipPercentage`}
          modelViewField={modelView[dynamicFieldAccess.ownershipPercentage]}
          errorMessage={validationErrors[dynamicFieldAccess.ownershipPercentage]}
        />
      </FormRow>
      <FormRow mb={10}>
        <DateOfBirthField
          testId={`input-beneficialOwner${index}-dateOfBirth`}
          modelViewField={modelView[dynamicFieldAccess.dateOfBirth]}
          errorMessage={validationErrors[dynamicFieldAccess.dateOfBirth]}
        />
        <SSNField
          testId={`input-beneficialOwner${index}-ssn`}
          modelViewField={modelView[dynamicFieldAccess.ssn]}
          errorMessage={validationErrors[dynamicFieldAccess.ssn]}
        />
      </FormRow>
      <FormRow>
        <Button
          data-testid={`remove-beneficialOwner${index}`}
          label="financing.businessOwnershipDetails.applicant.removeOwner"
          size={ButtonSizes.md}
          variant={ButtonVariants.tertiary}
          onClick={() => onRemoveBeneficialOwner(uuid)}
        />
      </FormRow>
    </>
  );
};
