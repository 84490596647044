import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { IconNames } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';
import { HStack } from 'src/core/ds/stack';

type EmailAlreadyExistProps = {
  isOpen: boolean;
  onClose(): void;
  onContinue(): void;
};

export const EmailAlreadyExistModal = ({ isOpen, onClose, onContinue }: EmailAlreadyExistProps) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Header>
      <Modal.Icon name={IconNames.alertFill} color="yellow.500" />
      <Modal.HeaderTitle>
        <MIFormattedText label="vendors.candidates.modals.emailAlreadyExists.title" />
      </Modal.HeaderTitle>
      <Modal.HeaderSubTitle>
        <Box textStyle="body2">
          <MIFormattedText label="vendors.candidates.modals.emailAlreadyExists.text" />
        </Box>
      </Modal.HeaderSubTitle>
    </Modal.Header>
    <Modal.CloseButton />
    <Modal.Footer>
      <HStack spacing={4}>
        <Button onClick={onClose} variant={ButtonVariants.tertiary} size={ButtonSizes.lg} label="general.close" />
        <Button onClick={onContinue} size={ButtonSizes.lg} label="general.continue" />
      </HStack>
    </Modal.Footer>
  </Modal>
);
