const parts = ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'];
type Dict = Record<string, any>;
/**
 * Since the `maxWidth` prop references theme.sizes internally,
 * we can leverage that to size our modals.
 */
function getSize() {
  return { dialog: { maxW: 'calc(100vw - 50%)' } };
}

const baseStyleOverlay = {
  bg: 'overlay',
  zIndex: 1400,
};

const baseStyleDialogContainer = ({ scrollBehavior }: Dict) => ({
  m: 4,
  display: 'flex',
  zIndex: 1400,
  justifyContent: 'center',
  overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
});

const baseStyleDialog = {
  zIndex: 1400,
  maxH: '100vh',
  bg: 'white',
  color: 'inherit',
};

const baseStyleHeader = {
  py: 10,
  px: 16,
  display: 'flex',
  alignContent: 'space-between',
  justifyContent: 'space-between',
  alignItems: 'center',
  minH: 12,
  maxW: '63rem',
  borderBottom: '1px',
  borderColor: 'grey.400',
};

const baseStyleCloseButton = {
  border: 'none',
  bg: 'transparent',
  color: 'grey.700',
  apply: 'textStyles.body2',
  flex: 0,
  cursor: 'pointer',
  _hover: {
    color: 'black',
  },
  _active: {
    color: 'black',
  },
};

const baseStyleBody = {
  py: 10,
  px: 16,
  flex: 1,
  overflow: 'auto',
  maxW: '63rem',
};

const baseStyleFooter = {
  p: 5,
  boxShadow: '600',
};

const baseStyle = (props: Dict) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog,
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody,
  footer: baseStyleFooter,
});

const sizes = {
  lg: getSize(),
};

const defaultProps = {
  size: 'lg',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
