import { isValid } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import dateFromModelValue from 'src/components/modals/DownloadCsvReportModal/modules/dateFromModelValue';
import {
  ValidatableLegalAddressKeys,
  validator as legalAddressValidator,
} from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/main-owner/legal-address/validator';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { EMAIL_REGEX, TAX_ID_SSN_REGEX, US_PHONE_NUMBER_REGEX } from 'src/pages/bill/pay/installments/utils';

export type ValidatableMainOwnerKeys = keyof InternalBusinessOwnershipDetailsModel;
export const validator = (key: ValidatableMainOwnerKeys, modelState: InternalBusinessOwnershipDetailsModel) => {
  switch (key) {
    case 'firstName':
      if (isEmpty(modelState.firstName)) return 'financing.businessOwnershipDetails.fields.firstName.errors.empty';

      break;
    case 'lastName':
      if (isEmpty(modelState.lastName)) return 'financing.businessOwnershipDetails.fields.lastName.errors.empty';

      break;
    case 'email':
      if (isEmpty(modelState.email)) return 'financing.businessOwnershipDetails.fields.email.errors.empty';

      if (!EMAIL_REGEX.test(modelState.email)) return 'financing.businessOwnershipDetails.fields.email.errors.invalid';

      break;
    case 'dateOfBirth':
      if (isEmpty(modelState.dateOfBirth)) return 'financing.businessOwnershipDetails.fields.dateOfBirth.errors.empty';

      if (
        (modelState.dateOfBirth.length >= 1 && modelState.dateOfBirth.length < 10) ||
        !isValid(dateFromModelValue(modelState.dateOfBirth))
      )
        return 'financing.businessOwnershipDetails.fields.dateOfBirth.errors.invalid';

      break;
    case 'ssn':
      if (isEmpty(modelState.ssn)) return 'financing.businessOwnershipDetails.fields.ssn.errors.empty';

      if (!TAX_ID_SSN_REGEX.test(modelState.ssn)) return 'financing.businessOwnershipDetails.fields.ssn.errors.invalid';

      break;
    case 'phone':
      if (isEmpty(modelState.phone)) return 'financing.businessOwnershipDetails.fields.phone.errors.empty';

      if (!US_PHONE_NUMBER_REGEX.test(modelState.phone))
        return 'financing.businessOwnershipDetails.fields.phone.errors.invalid';

      break;
    case 'ownershipPercentage':
      if (isEmpty(modelState.ownershipPercentage))
        return 'financing.businessOwnershipDetails.fields.ownershipPercentage.errors.empty';

      break;
    case 'addressLine1':
    case 'city':
    case 'state':
    case 'zipCode':
      return legalAddressValidator(key as ValidatableLegalAddressKeys, modelState);
    default:
      return undefined;
  }

  return undefined;
};
