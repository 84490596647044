import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { asyncPromiseMiddleware } from 'src/helpers/redux/asyncPromiseMiddleware';
import { flattenMessages } from 'src/locale';
import { DEFAULT_VALUES } from 'src/locale/default-values';
import { reduxAnalyticsMiddleware } from 'src/services/analytics/reduxAnalyticsMiddleware';
import { notificationMiddleware } from 'src/services/notifications/notificationMiddleware';
import { createTranslationService } from 'src/services/translation-service';
import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export function setupStore(site, history, initialState = {}) {
  const locale = 'en-US';
  const intl = createTranslationService(
    {
      locale,
      defaultLocale: locale,
      formats: {},
      defaultFormats: {},
      messages: flattenMessages(site.messages[locale]),
    },
    DEFAULT_VALUES
  );
  const sagaMiddleware = createSagaMiddleware({
    context: {
      intl,
      site,
    },
  });
  const composeEnhancers =
    process.env.REACT_APP_ENV !== 'production' // eslint-disable-next-line no-underscore-dangle
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
      : compose;
  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        reduxAnalyticsMiddleware,
        notificationMiddleware(intl),
        asyncPromiseMiddleware
      )
    )
  );
  sagaMiddleware.run(rootSaga);

  return {
    store,
    persistor: persistStore(store),
    intl,
  };
}
