import { ComponentPropsWithRef } from 'react';
import Box from 'src/core/ds/box';

type Props = ComponentPropsWithRef<typeof Box>;

export const OrganizationBadgeItemContainer = (props: Props) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="absolute"
    h={4}
    w={4}
    borderRadius="full"
    border="1px"
    borderColor="grey.200"
    bgColor="grey.200"
    {...props}
  />
);
