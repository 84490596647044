import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  day: number;
  month: number;
  label: string;
  testId: string;
};

export const DatePickerTooltip = ({ day, month, label, testId }: Props) => (
  <Tooltip
    data-testid={testId}
    data-testgroupid="disabled-date-tooltip"
    placement="top"
    label={<MIFormattedText label={label} />}
  >
    <Box data-testid={`${day}-${month}-day-content`}>{day}</Box>
  </Tooltip>
);
