import { useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { AmountSize } from 'src/pages/get-pro/components/summary/components/consts';
import SummaryAmount from 'src/pages/get-pro/components/summary/components/SummaryAmount';
import { Fees } from 'src/pages/regular-batch-payments/components/fees/Fees';

export const AmountSummaryInfo: React.FC = () => {
  const totalAmount = useSelector(regularBatchPaymentsStore.selectors.totalAmount);

  return useMemo(
    () => (
      <Flex direction="column" align="flex-end">
        <Box textStyle="body4Semi" color="grey.700" textAlign="right" textTransform="uppercase" mb={2}>
          <MIFormattedText label="regularBatchPayments.totalAmount" />
        </Box>
        <Box mb={1}>
          <SummaryAmount amount={totalAmount?.toString()} size={AmountSize.LARGE} />
        </Box>
        <Fees />
      </Flex>
    ),
    [totalAmount]
  );
};
