export const getSortBy = ({ column, sortBy }) => {
  const { id, desc } = sortBy[0] || {};

  if (column.id === id) {
    return desc ? [] : [{ id: column.id, desc: true }];
  }

  return [{ id: column.id, desc: false }];
};

export const convertSortByToQueryString = (sortBy) => {
  const value = sortBy?.[0];

  if (value) {
    const { id, desc } = value;

    return `${id}:${desc ? 'desc' : 'asc'}`;
  }

  return '';
};

export const convertQueryStringToSortBy = (queryString) => {
  const [id, direction] = (queryString || '').split(':');

  if (id && direction) {
    return [{ id, desc: direction === 'desc' }];
  }

  return [];
};
