import { featureFlags } from '@melio/shared-web';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getDeliveryMethods } from 'src/redux/user/selectors';
import { pendingDeliveryMethodsApi } from 'src/services/api/pendingDeliveryMethods';
import { FeatureFlags } from 'src/utils/consts';
import { PendingDeliveryMethodType } from 'src/utils/types';
import { pendingDeliveryMethodFactory } from '../utils/pendingDeliveryMethodFactory';

type Props = {
  orgId: number;
  ownedVendorId: number | null;
};
export const useDeliveryMethods = ({ orgId, ownedVendorId }: Props) => {
  const deliveryMethods = useSelector(getDeliveryMethods);
  const [pendingDeliveryMethods, setPendingDeliveryMethods] = useState<PendingDeliveryMethodType[]>([]);
  const [shouldShowPendingDeliveryMethods] = featureFlags.useFeature(FeatureFlags.MsnMvp1, false);

  const { onApiCall: getPendingDeliveryMethods, result } = useApi<
    [number, number | null],
    { pendingDeliveryMethods: PendingDeliveryMethodType[] }
  >({
    api: pendingDeliveryMethodsApi.getPendingDeliveryMethods,
  });

  const formattedPendingDeliveryMethods = () =>
    result?.pendingDeliveryMethods
      ? result.pendingDeliveryMethods.map((deliveryMethod) => ({
          ...pendingDeliveryMethodFactory(deliveryMethod),
          isPending: true,
        }))
      : [];

  useEffect(() => {
    getPendingDeliveryMethods(orgId, ownedVendorId);
  }, []);

  useEffect(() => {
    setPendingDeliveryMethods(shouldShowPendingDeliveryMethods ? formattedPendingDeliveryMethods() : []);
  }, [result?.pendingDeliveryMethods]);

  return { deliveryMethods, pendingDeliveryMethods };
};
