export const paginationContainer = {
  bg: 'grey.300',
  h: '4rem',
  pos: 'absolute',
  borderRadius: { base: 'md', md: 'lg' },
  bottom: { base: '4rem', md: '2.2rem' },
  maxWidth: { base: 'inherit', md: '40%' },
  w: { base: 'inherit', md: 'full' },
  display: 'flex',
};

export const invoiceWrapper = {
  display: 'flex',
  alignItems: 'center',
  w: 'full',
  justifyContent: 'space-between',
  '> div:nth-of-type(1)': {
    pl: { base: 1, md: 2 },
  },
  '> div:nth-of-type(3)': {
    pr: { base: 1, md: 2 },
  },
};

export const itemNavLink = (isDisabled?: boolean) => ({
  color: 'black',
  opacity: isDisabled ? 0.3 : 1,
  textStyle: 'body2Semi',
  pointerEvents: isDisabled ? 'none' : 'auto',
  cursor: 'pointer',
  mt: 1,
});

export const buttonRemoveFile = {
  color: 'red.500',
  bg: 'transparent',
  _hover: { color: 'red.500' },
  _active: { color: 'red.500' },
  mt: 1,
  h: '2.5rem',
};

export const downloadButtonWrapper = {
  display: 'flex',
  justify: 'center',
  alignContent: 'space-between',
  mt: { base: 'unset', md: -16 },
  mr: { base: 'unset', md: 6 },
  alignSelf: { base: 'unset', md: 'end' },
  position: { base: 'absolute', md: 'unset' },
  right: { base: '4rem', md: 'unset' },
  top: { base: '2rem', md: 'unset' },
};
