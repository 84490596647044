import { TabSettingsType } from './types';

const getDefaultTabSetting = ({
  tab,
  type,
  isActive,
  baseQueryParams = '',
  unreadBadge = false,
}: {
  tab: string;
  type: string;
  isActive: boolean;
  baseQueryParams?: string;
  unreadBadge?: boolean;
}): TabSettingsType => ({
  label: `list.tab.${type}.${tab}`,
  active: isActive,
  to: {
    search: `${baseQueryParams ? `${baseQueryParams}&` : ''}status=${tab}`,
  },
  trackEvent: {
    page: tab,
    name: `${type}-tab`,
    properties: undefined,
  },
  unreadBadge,
});

const getTabsSettings = (
  TYPES: Record<string, any>,
  selected: number,
  type: string,
  baseSearch = ''
): TabSettingsType[] =>
  Object.keys(TYPES).map((tab) =>
    getDefaultTabSetting({
      tab,
      type,
      isActive: selected === TYPES[tab],
      baseQueryParams: baseSearch,
    })
  );

export { getDefaultTabSetting, getTabsSettings };
