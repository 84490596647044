import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Radio, RadioGroup } from 'src/core/ds/radio';
import { HStack, VStack } from 'src/core/ds/stack';
import { useModal } from 'src/helpers/react/useModal';
import { useApi } from 'src/hoc/useApi';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { getOrgId } from 'src/redux/user/selectors';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { INVOICE_GENERATION_GROUP, InvoiceGenerationOption, invoiceGenerationOptions } from './consts';
import { NoOptInInvoiceGenerationModal } from './NoOptInInvoiceGenerationModal';
import { containerStyle } from './styles';

type Props = {
  onNext: () => void;
};

export const SyncInvoiceGenerationPage = ({ onNext }: Props) => {
  const [
    optInToInvoiceGenerationSelectedOption,
    setOptInToInvoiceGenerationSelectedOption,
  ] = useState<InvoiceGenerationOption>(InvoiceGenerationOption.OptIn);
  const [noOptInvoiceGenerationModal, openNoOptInvoiceGenerationModal] = useModal(NoOptInInvoiceGenerationModal, {
    id: 'noOptInInvoiceGenerationModal',
  });
  const toastId = useRef<null | number>(null);
  const orgId = useSelector(getOrgId);

  const { onApiCall: handleOptIn, result: results, loading: isLoading, error } = useApi({
    api: async () => accountingPlatformsApi.optInInvoiceGeneration({ orgId }),
  });

  const handleSubmit = () => {
    if (optInToInvoiceGenerationSelectedOption === InvoiceGenerationOption.NoOptIn) {
      openNoOptInvoiceGenerationModal({ onConfirm: onNext });
    } else if (optInToInvoiceGenerationSelectedOption === InvoiceGenerationOption.OptIn) {
      handleOptIn();
    }
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (error && !checkIfToastIsDisplayedById(toastId.current)) {
      toastId.current = pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.optInFailed',
      });
    }

    if (results) {
      onNext();
    }
  }, [isLoading, results, error, onNext]);

  return (
    <StepLayoutPage
      title="settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.title"
      subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.subtitle"
      onSubmit={handleSubmit}
      isNextDisabled={!optInToInvoiceGenerationSelectedOption}
      isCenterVert={false}
      nextButtonAnalyticsProperties={{ selectedOption: optInToInvoiceGenerationSelectedOption }}
    >
      {noOptInvoiceGenerationModal}
      <VStack spacing={5}>
        <Box sx={containerStyle}>
          <RadioGroup
            direction="column"
            onChange={(value) => setOptInToInvoiceGenerationSelectedOption(value as InvoiceGenerationOption)}
            defaultValue={InvoiceGenerationOption.OptIn}
          >
            <VStack spacing={6} alignItems="start">
              <Radio
                label={invoiceGenerationOptions.optIn.label}
                value={invoiceGenerationOptions.optIn.value}
                secondaryLabel={invoiceGenerationOptions.optIn.subLabel}
                groupName={INVOICE_GENERATION_GROUP}
              />
              <Radio
                label={invoiceGenerationOptions.noOptIn.label}
                value={invoiceGenerationOptions.noOptIn.value}
                secondaryLabel={invoiceGenerationOptions.noOptIn.subLabel}
                groupName={INVOICE_GENERATION_GROUP}
              />
            </VStack>
          </RadioGroup>
        </Box>
        {optInToInvoiceGenerationSelectedOption === InvoiceGenerationOption.OptIn && (
          <HStack spacing={3} alignItems="start">
            <Box>
              <Icon name={IconNames.infoCircle} color="black" />
            </Box>
            <Box textStyle="caption1Semi" color="grey.700">
              <MIFormattedText label="settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.disclaimer" />
            </Box>
          </HStack>
        )}
      </VStack>
    </StepLayoutPage>
  );
};
