import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { NotificationCardTypes } from 'src/utils/consts';
import { MIInlineLink } from './MIInlineLink';

type LabelValues = {
  label: string;
  values?: Record<string, any>;
};
type LinkValue = {
  label: string;
  path?: string;
};

export type MINotificationCardProps = {
  title?: LabelValues;
  subtitle?: LabelValues;
  type: NotificationCardTypes;
  link?: LinkValue;
};

export const MINotificationCard = ({ title, subtitle, link, type, ...rest }: MINotificationCardProps) => {
  const icon = getIcon(type);
  const color = getColor(type);

  return (
    <Flex
      data-testid={`notification-${type}`}
      border="1px"
      borderColor={color}
      borderRadius="lg"
      p={4}
      bgColor="white"
      color="black"
      {...rest}
    >
      <Flex mr={4}>
        <Icon name={icon} color={color} size={IconSize.lg} />
      </Flex>
      <Flex direction="column" justify="center" textStyle="body3">
        {title && (
          <Flex textStyle="body3Semi">
            <MIFormattedText label={title.label} values={title.values} />
          </Flex>
        )}
        {subtitle && (
          <Box textAlign="left">
            <MIFormattedText label={subtitle.label} values={subtitle.values} />
            {link && (
              <Box as="span" ml={1}>
                <MIInlineLink label={link.label} to={link.path} />
              </Box>
            )}
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

const getIcon = (type: NotificationCardTypes) => {
  switch (type) {
    case NotificationCardTypes.SUCCESS:
      return IconNames.checkCircle;
    case NotificationCardTypes.ERROR:
      return IconNames.alertCircle;
    case NotificationCardTypes.INFO:
      return IconNames.infoCircle;
    case NotificationCardTypes.HELP:
      return IconNames.helpCircle;
    case NotificationCardTypes.WARNING:
    default:
      return IconNames.warning;
  }
};

const getColor = (type: NotificationCardTypes) => {
  switch (type) {
    case NotificationCardTypes.SUCCESS:
      return 'green.500';
    case NotificationCardTypes.ERROR:
      return 'red.500';
    case NotificationCardTypes.INFO:
      return 'blue.500';
    case NotificationCardTypes.HELP:
      return 'grey.700';
    case NotificationCardTypes.WARNING:
    default:
      return 'yellow.500';
  }
};
