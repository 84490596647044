type Props = {
  isRecurring: boolean;
  availableFreeChecks: number;
  totalFreeChecks: number;
  paperCheckFee: number;
  basePaperCheckFee: number;
};

export function getAchToCheckFeeDescription({
  availableFreeChecks,
  isRecurring,
  paperCheckFee,
  basePaperCheckFee,
  totalFreeChecks,
}: Props) {
  if (isRecurring) {
    return {
      label:
        availableFreeChecks > 0
          ? 'bills.pay.confirm.ach-to-check.recurring.freeDescription'
          : 'bills.pay.confirm.ach-to-check.recurring.feeDescription',
      values: { available: availableFreeChecks, paperCheckFee: basePaperCheckFee },
    };
  }

  const isFree = availableFreeChecks > 0 && !paperCheckFee;

  return {
    label: isFree ? 'bills.pay.confirm.ach-to-check.freeDescription' : '',
    values: isFree ? { total: totalFreeChecks, available: availableFreeChecks } : {},
  };
}
