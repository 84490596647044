import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Switch, useHistory } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { getProfileActions, profileStore } from 'src/modules/profile/profile-store';
import { companiesLocations } from 'src/pages/companies/locations';
import AddFundingSource from 'src/pages/funding-sources/components/AddFundingSource';
import { getOrgId } from 'src/redux/user/selectors';
import { defineRelationshipLocations } from './locations';
import NewCompanyInviteOwnerPage from './NewCompanyInviteOwnerPage';
import { NewCompanyRequireApprovalPage } from './NewCompanyRequireApprovalPage';
import { NewCompanySuccessPage } from './NewCompanySuccessPage';

export function DefineRelationshipRouter() {
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const organizationPreferences = useOrganizationPreferences();
  const orgId = useSelector(getOrgId);
  const accountingFirm = useSelector(profileStore.selectors.getFirstAccountingFirm);
  const { switchOrganization } = getProfileActions(useDispatch());

  const onFinishFlow = () => {
    const firmOrgId = accountingFirm?.id;

    if (firmOrgId && firmOrgId !== orgId) {
      switchOrganization(firmOrgId);
    }

    const orgIdParam = firmOrgId || orgId;

    historyPush({
      path: companiesLocations.index,
      params: {
        orgId: orgIdParam,
      },
    });
  };

  const onPrev = () => {
    history.goBack();
  };

  const onNext = () => historyPush({ path: defineRelationshipLocations.requireApproval });

  const onNoOwnerNext = () => historyPush({ path: defineRelationshipLocations.companyAdded });

  return (
    <Switch>
      <SmartRoute path={defineRelationshipLocations.inviteOwner} exact>
        <NewCompanyInviteOwnerPage
          onPrev={onPrev}
          onNext={onNext}
          onExit={onFinishFlow}
          onNoOwnerNext={onNoOwnerNext}
        />
      </SmartRoute>
      <SmartRoute path={defineRelationshipLocations.companyAdded} exact>
        <NewCompanySuccessPage onNext={onFinishFlow} />
      </SmartRoute>
      <SmartRoute path={defineRelationshipLocations.addFundingSource} exact>
        <AddFundingSource
          onPrev={onPrev}
          relativeStep={4 / 6}
          onExit={onFinishFlow}
          redirectUrl={
            organizationPreferences.uninvitedOwnerEmail
              ? generatePath(defineRelationshipLocations.inviteOwner, { orgId })
              : generatePath(defineRelationshipLocations.requireApproval, { orgId })
          }
          exitUrl={generatePath(defineRelationshipLocations.addFundingSource, { orgId })}
        />
      </SmartRoute>
      <SmartRoute path={defineRelationshipLocations.requireApproval} exact>
        <NewCompanyRequireApprovalPage onPrev={onPrev} onNext={onNoOwnerNext} onExit={onFinishFlow} />
      </SmartRoute>
    </Switch>
  );
}
