import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import snakeCase from 'lodash/snakeCase';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { analytics } from 'src/services/analytics';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Option = {
  name: string;
  values: string[];
};

type ColumnOptionComponentProps = Option & {
  selected: boolean;
  onClick: () => void;
};

type ColumnOptionProps = {
  children?: ReactNode;
  onClick: () => void;
  selected: boolean;
  'data-testid': string;
};

type TableSampleProps = {
  children?: ReactNode;
};

type ColumnNameProps = {
  children?: ReactNode;
};

type RowProps = {
  children?: ReactNode;
};

type RowNumberProps = {
  children?: ReactNode;
};

type ValueProps = {
  children?: ReactNode;
};

type SelectColumnProps = {
  selected?: string;
  options: Option[];
  onNext: (columnName?: string) => void;
  onPrev: () => void;
  onExit?: () => void;
  title: string;
  label: string;
};

type ColumnNameTitleProps = {
  children?: ReactNode;
};

const ColumnOptionComponent: React.FC<ColumnOptionComponentProps> = ({ name, values, selected, onClick }) => (
  <ColumnOption
    onClick={onClick}
    selected={selected}
    data-testid={`getPaid.new.create.importInvoicesList.column-${snakeCase(name)}`}
  >
    <TableSample>
      <ColumnName>{name}</ColumnName>
      {map(values, (value, row) => (
        <Row key={row}>
          <RowNumber>{row + 1}</RowNumber>
          <Value>{value}</Value>
        </Row>
      ))}
    </TableSample>
  </ColumnOption>
);

const ColumnOption: React.FC<ColumnOptionProps> = ({ children, selected, ...rest }) => (
  <Flex
    my={8}
    mx={0}
    border={selected ? '2px' : '1px'}
    borderColor={selected ? 'primary.500' : 'grey.400'}
    p={selected ? 0 : 'px'}
    borderRadius="lg"
    transition="300ms"
    transitionProperty="box-shadow"
    boxShadow={500}
    _hover={{ boxShadow: !selected ? 600 : '' }}
    _active={{ boxShadow: 500 }}
    {...rest}
  >
    {children}
  </Flex>
);

const TableSample: React.FC<TableSampleProps> = ({ children }) => (
  <Box flexGrow={1} margin={0.5}>
    {children}
  </Box>
);

const ColumnName: React.FC<ColumnNameProps> = ({ children }) => (
  <Box
    className={FULL_STORY_MASK_RULE_CLASS}
    textStyle="body3Semi"
    opacity={0.8}
    bgColor="white"
    pl={4}
    borderBottom="1px"
    borderBottomColor="grey.400"
  >
    {children}
  </Box>
);

const Row = ({ children }: RowProps) => (
  <Flex
    _notLast={{
      borderBottom: '1px',
      borderBottomColor: 'grey.400',
    }}
  >
    {children}
  </Flex>
);

const RowNumber: React.FC<RowNumberProps> = ({ children }) => (
  <Box
    as="span"
    textStyle="body3Semi"
    borderRight="1px"
    borderRightColor="grey.400"
    opacity={0.5}
    bgColor="white"
    w="4.35rem"
    textAlign="center"
  >
    {children}
  </Box>
);

const Value: React.FC<ValueProps> = ({ children }) => (
  <Box
    as="span"
    color="black"
    opacity={0.8}
    flexGrow={1}
    pl={4}
    textStyle="body3"
    className={FULL_STORY_MASK_RULE_CLASS}
  >
    {children}
  </Box>
);

export const SelectColumnPage: React.FC<SelectColumnProps> = ({
  options,
  selected,
  onNext,
  onExit,
  onPrev,
  title,
  label,
}) => {
  const [currentSelected, setSelected] = useState(selected);
  useEffect(() => {
    setSelected(selected);
  }, [selected]);
  const onClick = (option) => () => {
    analytics.trackAction('column.selected', option);
    setSelected(option.name);
  };

  return (
    <StepLayoutPage
      title={title}
      titleValues={{
        columnName: (
          <ColumnNameTitle>
            <MIFormattedText label={label} />
          </ColumnNameTitle>
        ),
      }}
      onNext={() => {
        onNext(currentSelected);
        setSelected(undefined);
      }}
      onPrev={onPrev}
      goExit={onExit}
      isNextDisabled={isEmpty(currentSelected)}
      docked
    >
      {options.map((option) => (
        <ColumnOptionComponent
          onClick={onClick(option)}
          key={option.name}
          selected={option.name === currentSelected}
          {...option}
        />
      ))}
    </StepLayoutPage>
  );
};

const ColumnNameTitle: React.FC<ColumnNameTitleProps> = ({ children }) => (
  <Box as="span" color="grey.700">
    {children}
  </Box>
);
