import { getValidationErrors } from '@melio/sizzers-js-common';
import { isBefore } from 'date-fns';
import head from 'lodash/head';
import { InvoiceType } from 'src/modules/invoices/types';
import { EventTypes } from 'src/pages/get-pro/consts';
import { TimelineEventType } from 'src/pages/get-pro/types';
import { AccountingSoftware, PaymentApprovalStatus } from 'src/utils/consts';
import { AccountingSoftwareCreateOrigin } from './consts';

export const validateInvoice = (invoice: InvoiceType) => {
  const validationErrors: Record<string, string> = getValidationErrors(
    'paymentRequest',
    { customerEmail: invoice?.customer?.contactEmail },
    ['customerEmail']
  );
  const email = invoice?.customer?.contactEmail;

  if (!email) {
    validationErrors.customerEmail = 'inputErrors.paymentRequest.customerEmail.any.required';
  }

  return validationErrors;
};

const getPaymentScheduledAdditionalInfo = (entity?: InvoiceType): { label: string } => {
  const payment = head(entity?.payments);
  let label = '';

  if (payment?.approvalDecisionStatus === PaymentApprovalStatus.PENDING) {
    label = 'getPro.drawerTabs.timeline.additionalInfo.pendingAdminApproval';
  } else if (!payment?.fundingSource?.isVerified) {
    label = 'getPro.drawerTabs.timeline.additionalInfo.pendingCustomerAccountVerification';
  }

  return { label };
};

const getEmailSentToAdditionalInfo = (
  entity: InvoiceType
): {
  label: string;
  values?: { contactEmail: string };
} => {
  const contactEmail = entity.customer?.contactEmail;

  return contactEmail
    ? {
        label: 'getPro.drawerTabs.timeline.additionalInfo.emailToContact',
        values: { contactEmail },
      }
    : {
        label: '',
      };
};

const getInvoiceCreatedTitle = ({
  metadata,
  accountingSoftwareName,
  entity,
}: {
  metadata: { createOrigin: AccountingSoftwareCreateOrigin };
  accountingSoftwareName?: AccountingSoftware;
  entity: InvoiceType;
}): {
  label: string;
  values?: { accountingSoftwareName?: AccountingSoftware };
} => {
  const paymentRequestCreateOrigin = metadata?.createOrigin || entity?.createOrigin;

  if (Object.values(AccountingSoftwareCreateOrigin).includes(paymentRequestCreateOrigin)) {
    return {
      label: 'getPro.drawerTabs.timeline.events.invoiceSyncedFromAccountingSoftware',
      values: { accountingSoftwareName },
    };
  }

  return {
    label: 'getPro.drawerTabs.timeline.events.invoiceCreated',
  };
};

const getPaymentSyncedTitle = ({
  accountingSoftwareName,
}: {
  accountingSoftwareName?: AccountingSoftware;
}): {
  label: string;
  values?: { accountingSoftwareName?: AccountingSoftware };
} => ({
  label: 'getPro.drawerTabs.timeline.events.paymentSyncedWithAccountingSoftware',
  values: { accountingSoftwareName },
});

const getPaymentScheduledTitle = ({ entity }: { entity: InvoiceType }): { label: string } => {
  const { payments } = entity;

  if (payments?.length && payments[0].createOrigin !== 'request') {
    return {
      label: 'getPro.drawerTabs.timeline.events.paymentCreatedByCustomer',
    };
  }

  return {
    label: 'getPro.drawerTabs.timeline.events.paymentScheduled',
  };
};

const EventsTitleMap = {
  [EventTypes.INVOICE_CREATED]: getInvoiceCreatedTitle,
  [EventTypes.PAYMENT_SYNCED_WITH_ACCOUNTING_SOFTWARE]: getPaymentSyncedTitle,
  [EventTypes.PAYMENT_SCHEDULED]: getPaymentScheduledTitle,
};

const additionalInfoEventsMap = {
  [EventTypes.PAYMENT_SCHEDULED]: getPaymentScheduledAdditionalInfo,
  [EventTypes.PAYMENT_REQUEST_SHARED]: getEmailSentToAdditionalInfo,
  [EventTypes.PAYMENT_REQUEST_NOTIFICATION_FOUND]: getEmailSentToAdditionalInfo,
};

export const getEventTitle = ({
  eventName,
  entity,
  metadata,
  accountingSoftwareName,
}: {
  eventName: EventTypes;
  entity?: InvoiceType;
  metadata?: { [key: string]: number | string };
  accountingSoftwareName?: AccountingSoftware;
}) =>
  EventsTitleMap[eventName]?.({ entity, accountingSoftwareName, metadata }) ?? {
    label: `getPro.drawerTabs.timeline.events.${eventName}`,
  };

export const getEventAdditionalInfo = (eventName: EventTypes, entity: InvoiceType) =>
  additionalInfoEventsMap[eventName]?.(entity) ?? { label: '' };

export const filterEvents = (event: TimelineEventType, entity: InvoiceType) => {
  const { eventName, timestamp } = event;

  if (eventName === EventTypes.FUNDING_SOURCE_VERIFIED) {
    const payment = head(entity?.payments);

    return payment?.createdAt && isBefore(new Date(payment?.createdAt), new Date(timestamp));
  }

  return true;
};
