import { defineRelationshipLocations } from './define-relationship/locations';

export const onboardingLocations = {
  quickbooks: '/orgs/:orgId/welcome/quickbooks',
  defineRelationship: defineRelationshipLocations,
  addVendor: {
    index: '/orgs/:orgId/welcome/add-vendor',
    name: '/orgs/:orgId/welcome/add-vendor/name',
    accountNumber: '/orgs/:orgId/welcome/add-vendor/account-number/:billerId',
    added: '/orgs/:orgId/welcome/add-vendor/:vendorId/added',
  },
  justPay: {
    index: '/orgs/:orgId/just-pay',
  },
  companyInfo: {
    index: '/orgs/:orgId/welcome/business',
    connectedFromAccountingSoftware: '/orgs/:orgId/welcome/business/accounting-software-connected',
    connectedFromQuickbooks: '/orgs/:orgId/welcome/business/quickbooks-connected',
    newCombined: '/orgs/:orgId/welcome/business/new-combined',
    contacts: '/orgs/:orgId/welcome/business/contacts',
    name: '/orgs/:orgId/welcome/business/name',
    address: '/orgs/:orgId/welcome/business/address',
    addressManual: '/orgs/:orgId/welcome/business/address-manual',
    type: '/orgs/:orgId/welcome/business/type',
    intent: '/orgs/:orgId/welcome/business/intent',
    legalInfo: '/orgs/:orgId/welcome/business/legal-info',
    industry: '/orgs/:orgId/welcome/business/industry',
    monthlyPaymentsVolume: '/orgs/:orgId/welcome/business/monthly-payments-volume',
    clientsAmount: '/orgs/:orgId/welcome/business/clients-amount',
    added: '/orgs/:orgId/welcome/business/added',
    promotion: '/orgs/:orgId/welcome/business/promotion',
  },
  mobileApp: {
    promotion: '/orgs/:orgId/download-mobile-app',
  },
  fundingSources: {
    index: '/orgs/:orgId/welcome/funding-sources',
    bank: {
      index: '/orgs/:orgId/welcome/funding-sources/bank',
      account: '/orgs/:orgId/welcome/funding-sources/bank/account',
      select: '/orgs/:orgId/welcome/funding-sources/bank/select',
      plaid: '/orgs/:orgId/welcome/funding-sources/bank/plaid',
      nickname: '/orgs/:orgId/welcome/funding-sources/bank/nickname',
      complete: '/orgs/:orgId/welcome/funding-sources/bank/complete',
      cantFind: '/orgs/:orgId/welcome/funding-sources/bank/cant-find',
      manually: '/orgs/:orgId/welcome/funding-sources/bank/manually',
      manuallyView: '/orgs/:orgId/welcome/funding-sources/bank/manually/:id',
      addFromSettingsFlow: {
        base: '/orgs/:orgId/welcome/funding-sources/bank/add-from-settings/',
        index: '/orgs/:orgId/welcome/funding-sources/bank/add-from-settings/*',
      },
      addFromPayBatchFlow: {
        base: '/orgs/:orgId/welcome/funding-sources/bank/add-from-pay-batch/',
        index: '/orgs/:orgId/welcome/funding-sources/bank/add-from-pay-batch/*',
      },
      addFromGuestFlow: {
        base: '/orgs/:orgId/welcome/funding-sources/bank/add-from-guest/',
        index: '/orgs/:orgId/welcome/funding-sources/bank/add-from-guest/*',
      },
    },
    card: {
      index: '/orgs/:orgId/welcome/funding-sources/card',
      cardholder: '/orgs/:orgId/welcome/funding-sources/card/holder',
      nickname: '/orgs/:orgId/welcome/funding-sources/card/nickname',
      complete: '/orgs/:orgId/welcome/funding-sources/card/complete',
    },
  },
  accountingSoftware: {
    index: '/orgs/:orgId/welcome/accounting-software',
    quickbooks: {
      index: '/orgs/:orgId/welcome/accounting-software/quickbooks',
      success: '/orgs/:orgId/welcome/accounting-software/quickbooks/success',
      backToBills: '/orgs/:orgId/welcome/accounting-software/quickbooks?action=bills',
    },
    comingSoon: {
      index: '/orgs/:orgId/welcome/accounting-software/coming-soon/:type',
    },
  },
  vendorCompanyInfo: {
    index: '/orgs/:orgId/meliome/welcome/business',
    name: '/orgs/:orgId/meliome/welcome/business/name',
    address: '/orgs/:orgId/meliome/welcome/business/address',
    addressManual: '/orgs/:orgId/meliome/welcome/business/address-manual',
    industry: '/orgs/:orgId/meliome/welcome/business/industry',
    phone: '/orgs/:orgId/meliome/welcome/business/phone',
    contactName: '/orgs/:orgId/meliome/welcome/business/contact-name',
    link: '/orgs/:orgId/meliome/welcome/business/link',
    logo: '/orgs/:orgId/meliome/welcome/business/logo',
    legal: '/orgs/:orgId/meliome/welcome/business/legal-info',
    businessType: '/orgs/:orgId/meliome/welcome/business/select-business-type',
    added: '/orgs/:orgId/meliome/welcome/business/added',
    successPage: '/orgs/:orgId/meliome/welcome/business/success-page',
    absorbFees: '/orgs/:orgId/meliome/welcome/business/absorb-fees-settings',
  },
  msnRegistration: {
    base: ['/msn-portal', '/orgs/:orgId/msn-portal'],
    register: '/msn-portal/register',
    emailVerification: '/msn-portal/email-verification',
    msnVerificationStatusCheck: '/orgs/:orgId/msn-portal/msn-verification-status-check',
    deliveryMethodStatusCheck: '/orgs/:orgId/msn-portal/delivery-method-status',
    existingOrganizations: '/orgs/:orgId/msn-portal/existing-organizations',
    contactDetails: '/orgs/:orgId/msn-portal/business/contact-details',
    companyDetails: '/orgs/:orgId/msn-portal/business/company-details',
    selectBusinessType: '/orgs/:orgId/msn-portal/business/select-business-type',
    deliveryMethodStatus: '/orgs/:orgId/msn-portal/business/delivery-method-status',
    companyVerified: '/orgs/:orgId/msn-portal/business/verified',
    companyRejected: '/orgs/:orgId/msn-portal/business/rejected',
    verifyBankAccount: '/orgs/:orgId/msn-portal/business/verify-bank-account',
    verifyBankAccountStatus: '/orgs/:orgId/msn-portal/business/verify-bank-account-status',
    confirmDetails: '/orgs/:orgId/msn-portal/business/confirm-details',
    claimAccount: {
      index: ['/msn-portal/claim-account', '/orgs/:orgId/msn-portal/claim-account'],
      public: '/msn-portal/claim-account/public/:token',
      authenticated: '/orgs/:orgId/msn-portal/claim-account',
      emailVerification: '/msn-portal/claim-account/public/:token/email-verification',
      createPassword: '/msn-portal/claim-account/public/:token/create-password',
      generalError: '/msn-portal/claim-account/general-error',
      deliveryMethodStatusCheck: '/orgs/:orgId/msn-portal/claim-account/delivery-method-status-check',
      verifyWithDeposits: '/orgs/:orgId/msn-portal/claim-account/verify-with-deposits',
      verifyWithDepositsStatus: '/orgs/:orgId/msn-portal/claim-account/verify-with-deposits-status',
    },
  },
  deliveryMethods: {
    index: '/orgs/:orgId/meliome/welcome/vendors/:id/delivery-methods',
    bank: {
      create: '/orgs/:orgId/meliome/welcome/vendors/:id/delivery-methods/ach/create/bank-details',
      select: '/orgs/:orgId/meliome/welcome/vendors/:id/delivery-methods/select',
      plaid: '/orgs/:orgId/meliome/welcome/vendors/:id/delivery-methods/plaid',
      edit: '/orgs/:orgId/meliome/welcome/vendors/:id/delivery-methods/ach/edit/:deliveryMethodId/bank-details',
      cantFind: '/orgs/:orgId/meliome/welcome/vendors/:id/delivery-methods/cant-find',
    },
  },
  vendorAddition: {
    base: '/orgs/:orgId/welcome/vendors/add/',
    index: '/orgs/:orgId/welcome/vendors/add/*',
  },
};
