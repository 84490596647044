import { useCallback } from 'react';
import { HeaderGroup } from 'react-table';
import Box from 'src/core/ds/box';
import Table from 'src/core/ds/table';
import { ReactComponent as ArrowDownIcon } from 'src/images/get-pro/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'src/images/get-pro/arrow-up.svg';
import { DATA_TABLE_RENDER } from 'src/pages/get-pro/components/table/consts';
import { iconStyle } from 'src/pages/get-pro/components/table/tableStyles';

export const TableHeaderGroup = ({
  headerGroup,
  disableCheckBoxes,
  onClick,
}: {
  headerGroup: HeaderGroup<any>;
  disableCheckBoxes?: boolean;
  onClick?: (column) => void;
}) => {
  const handleColumnClick = useCallback((column) => onClick?.(column), [onClick]);

  return (
    <Table.Row {...headerGroup.getHeaderGroupProps()}>
      {headerGroup.headers.map((column) => (
        <Table.HeaderCell
          w={column.width}
          minW={column.minWidth}
          isNumeric={column.isNumeric}
          data-testid={`column-${column.id}`}
          {...column.getHeaderProps(column.getSortByToggleProps())}
        >
          <Box px={4} onClick={() => handleColumnClick(column)}>
            {column.render(DATA_TABLE_RENDER.Header, { disableCheckBoxes })}
            <Box as="span" pl={2} h={4} __css={iconStyle}>
              {column.isSorted &&
                (column.isSortedDesc ? (
                  <ArrowDownIcon aria-label="sorted descending" />
                ) : (
                  <ArrowUpIcon aria-label="sorted ascending" />
                ))}
            </Box>
          </Box>
        </Table.HeaderCell>
      ))}
    </Table.Row>
  );
};
