import { useIntl } from 'react-intl';
import { MsnBusinessTypeEnum } from 'src/utils/types';

const businessTypeOptionsValues = [
  {
    label: 'vendors.candidates.msnBusinessTypesLabels.standaloneBusiness',
    value: MsnBusinessTypeEnum.STANDALONE_BUSINESS,
  },
  {
    label: 'vendors.candidates.msnBusinessTypesLabels.partOfALargerOrganization',
    value: MsnBusinessTypeEnum.PART_OF_A_LARGER_ORGANIZATION,
  },
  {
    label: 'vendors.candidates.msnBusinessTypesLabels.notSure',
    value: MsnBusinessTypeEnum.NOT_SURE,
  },
];

export const useBusinessTypeOptions = () => {
  const intl = useIntl();

  const businessTypeOptions = businessTypeOptionsValues.map(({ label, ...rest }) => ({
    ...rest,
    label: intl.formatMessage({ id: label }),
  }));

  return {
    businessTypeOptions,
  };
};
