import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { SelectBillingMethodPage } from './components/SelectBillingMethodPage';
import { SelectClientsOrgBillingFee } from './components/SelectClientsOrgBillingFee';
import { SuccessBillingPage } from './components/SuccessBillingPage';
import { billingLocations } from './locations';

// to be used when using useWizard:
// type Steps = 'select-billing-method' | 'assign-clients-org-billing-fee' | 'success-billing';

// type NavigationMap = { [key in Steps]: (...args: any[]) => Steps | void };

export function BillingRouter() {
  return (
    <Switch>
      <SmartRoute exact path={billingLocations.add}>
        <SelectBillingMethodPage />
      </SmartRoute>
      <SmartRoute path={billingLocations.accountingFirm.assignClients}>
        <SelectClientsOrgBillingFee />
      </SmartRoute>
      <SmartRoute exact path={billingLocations.success}>
        <SuccessBillingPage />
      </SmartRoute>
    </Switch>
  );
}
