import { useSelector } from 'react-redux';
import useUpdateUserPreference from 'src/modules/users/hooks/useUpdateUserPreferences';
import { getUserPreferences } from 'src/redux/user/selectors';
import { UserPreferencesKey } from 'src/utils/consts';

type ShowWelcomeModalType = {
  shouldShowWelcomeModal: boolean;
  setWelcomeModalWasShown(isShown: boolean): void;
};
export const useShowWelcomeModal = (): ShowWelcomeModalType => {
  const userPreferences = useSelector(getUserPreferences);
  const { updateUserPreference } = useUpdateUserPreference();
  const welcomeModalWasShown = userPreferences?.getPaidProWelcomeModalShown;
  const shouldShowWelcomeModal = typeof welcomeModalWasShown === 'boolean' && !welcomeModalWasShown;

  const setWelcomeModalWasShown = (isShown: boolean) => {
    updateUserPreference({
      key: UserPreferencesKey.GetPaidProWelcomeModalShown,
      value: isShown,
    });
  };

  return {
    shouldShowWelcomeModal,
    setWelcomeModalWasShown,
  };
};
