import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { useIsFinancingPayFlow } from 'src/pages/bill/pay/hooks/useIsFinancingPayFlow';
import { usePayBillActions } from 'src/pages/bill/pay/hooks/usePayBillActions';
import { payBillEventPage } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { getBill, getIsCancelAndRetryPaymentFlow, getIsRecurring, getPayment } from 'src/redux/payBillWizard/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { NavigateType } from 'src/utils/types';
import { billPayLocations } from '../locations';
import { useFinancedPaymentRecoveryFlows } from './useFinancedPaymentRecoveryFlows';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
};

export const useMemoNavigate = ({ navigate, nextStepURL, prevStepURL }: Params) => {
  const location = useLocation();
  const match = useRouteMatch<{ billId: string }>();
  const { billId } = match.params;
  const isCancelAndRetryFlow = useSelector(getIsCancelAndRetryPaymentFlow);
  const bill = useSelector(getBill);
  const isRecurring = useSelector(getIsRecurring);
  const payment = useSelector(getPayment);
  const isFinancingPayFlow = useIsFinancingPayFlow();
  const { isFinancedPaymentRecoveryFlow } = useFinancedPaymentRecoveryFlows();

  const actions = usePayBillActions();

  const { onNext } = usePayBillNavigateCommon({ navigate, nextStepURL, prevStepURL });

  const onNextMemo = () => {
    if (!isRecurring) {
      actions.fetchBill(billId);
    }

    return onNext();
  };

  const onPrevMemo = () => {
    const eventName = `set-${getEventNameFromLocation(location)}`;
    analytics.track(payBillEventPage, `${eventName}-back`, {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    if (isCancelAndRetryFlow) {
      navigate(billPayLocations.edit.confirm);
    } else if (isRecurring) {
      if (payment.deliveryMethodId) {
        navigate(billLocations.pay.recurring.funding);
      }
    } else if (isFinancingPayFlow) {
      navigate(billLocations.pay.installments.scheduleFlow);
    } else if (isFinancedPaymentRecoveryFlow) {
      navigate(billPayLocations.edit.confirm);
    } else if (prevStepURL) {
      navigate(prevStepURL);
    }
  };

  return { onNextMemo, onPrevMemo };
};
