import styled, { css } from 'styled-components';
import { Input } from 'src/components/common/Amount/Input';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { getNameInitials } from 'src/utils/string-utils';
import { FieldType } from 'src/utils/types';

type Props = {
  logoUrl: string | null;
  companyName: string;
  hint?: string;
  totalAmount?: string;
  validationErrors?: Record<string, any>;
  viewOnly?: boolean;
  hideAmountInput?: boolean;
  onChange?: (value: FieldType) => void;
  contactName?: string;
};

export const MelioMeCardVendorInvoiceInfo = ({
  logoUrl,
  companyName,
  hint,
  totalAmount,
  validationErrors,
  viewOnly,
  onChange,
  hideAmountInput,
  contactName,
}: Props) => (
  <VendorInfoContainer>
    {logoUrl ? (
      <VendorLogo src={logoUrl} alt={companyName} id="vendor-logo" />
    ) : (
      <DefaultUserPicContainer id="default-user-pic-container">{getNameInitials(companyName)}</DefaultUserPicContainer>
    )}
    <VendorTitle data-testid="title-vendor-name">{companyName}</VendorTitle>
    <VendorInfoHint data-testid="vendor-info-hint">
      {hint && <MIFormattedText label={hint} values={{ contactName }} />}
    </VendorInfoHint>
    {!hideAmountInput && (
      <Input totalAmount={totalAmount} validationErrors={validationErrors} viewOnly={viewOnly} onChange={onChange} />
    )}
    <PaymentRequestText textStyle="body3" color="grey.700" id="payment-request-text">
      <MIFormattedText label="guests.paymentRequest.paymentRequestText" />
    </PaymentRequestText>
  </VendorInfoContainer>
);

const picStyles = css`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  margin: 0 auto 1.6rem;
  border: 0.2rem solid ${(props) => props.theme.colors.progress.undone};
`;

const DefaultUserPicContainer = styled.div`
  ${picStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.light.opaque};
  font-size: ${(props) => props.theme.text.size.subNav};
  color: white;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-transform: uppercase;
`;

const VendorLogo = styled.img`
  ${picStyles}
  display: block;
  object-fit: contain;
`;

const VendorTitle = styled.div`
  font-size: 2.3rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 0.6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const VendorInfoHint = styled.div`
  font-weight: normal;
  margin-bottom: 1.1rem;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.medium};
`;

const VendorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentRequestText = styled(Box)`
  display: none;
`;
