import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';

type Props = {
  organization: any;
  hideLogin?: boolean;
};

export const PaymentFailedPage = ({ organization, hideLogin = false }: Props) => {
  const site = useSiteContext();

  const onEmailClick = (email) => () => {
    analytics.trackAction('support-email');
    window.open(`mailto:${email}`, '_blank');
  };

  return (
    <OutsideLayout hideLogin={hideLogin}>
      <Flex direction="column">
        <BillInfoContainer>
          <Box mb={5}>
            <Icon name={IconNames.alertFill} color="red.500" size={IconSize.xl} />
          </Box>
          <Box textStyle="body2">
            <MIFormattedText
              label="vendors.editCheckAddress.checkBlocked.description"
              values={{
                companyName: organization?.companyName,
                companyEmail: (
                  <StyledEmailLink href={`mailto:${organization?.userOrganizations?.[0]?.user?.email}`}>
                    {organization?.userOrganizations?.[0]?.user?.email}
                  </StyledEmailLink>
                ),
                supportMail: (
                  <Box
                    as="a"
                    color="primary.500"
                    textDecoration="none"
                    onClick={onEmailClick(site.config.support.email)}
                    href="#"
                  >
                    {site.config.support.email}
                  </Box>
                ),
              }}
            />
          </Box>
        </BillInfoContainer>
      </Flex>
    </OutsideLayout>
  );
};

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const StyledEmailLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${(props) => props.theme.text.color.highlight};
  text-decoration: none;
`;
