import sortBy from 'lodash/sortBy';
import { MenuItem, MenuList } from 'src/core/ds/menu';
import { Portal } from 'src/core/ds/portal';
import { AddDeliveryMethodMenuItem } from 'src/pages/regular-batch-payments/components/deliveryMethod/AddDeliveryMethodMenuItem';
import DeliveryMethodMenuItem from 'src/pages/regular-batch-payments/components/deliveryMethod/DeliveryMethodMenuItem';
import { DeliveryType } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  deliveryMethods: DeliveryMethodType[];
  onAddDeliveryMethod: () => void;
  setCurrentDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  selectedDeliveryMethodId?: number;
  isOpen?: boolean;
  testId?: string;
};

export const DeliveryMethodMenuList = ({
  deliveryMethods,
  onAddDeliveryMethod,
  setCurrentDeliveryMethod,
  selectedDeliveryMethodId,
  isOpen,
  testId,
}: Props) => {
  const filteredDeliveryMethods = sortBy(deliveryMethods, 'deliveryType').filter(
    (dm) => dm.deliveryType !== DeliveryType.VIRTUAL
  );
  const showAddNewMethodLabel =
    deliveryMethods.length === 1 ||
    (deliveryMethods.length <= 2 && deliveryMethods.some((dm) => dm.deliveryType === DeliveryType.VIRTUAL));

  return (
    <Portal>
      <MenuList maxW="23rem" display={isOpen ? 'block' : 'none'} data-testid={testId}>
        {filteredDeliveryMethods.length > 1 &&
          filteredDeliveryMethods.map((deliveryMethod) => (
            <DeliveryMethodMenuItem
              key={deliveryMethod.id}
              deliveryMethod={deliveryMethod}
              onClick={(dm) => setCurrentDeliveryMethod(dm)}
              isSelected={deliveryMethod.id === selectedDeliveryMethodId}
            />
          ))}
        {showAddNewMethodLabel && (
          <MenuItem>
            <AddDeliveryMethodMenuItem
              onClick={onAddDeliveryMethod}
              label="regularBatchPayments.addAnotherDeliveryMethod"
            />
          </MenuItem>
        )}
      </MenuList>
    </Portal>
  );
};
