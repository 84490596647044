import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { shiftVendorToAchLocations } from 'src/pages/vendor/shift-vendor-to-ach/locations';
import { useForm } from 'src/ui/form';
import { BANK_ACCOUNT_EXIST, BankAccountType, DeliveryType, PaymentStatus } from 'src/utils/consts';

export type ReturnType = [(params: any) => Promise<any>, Record<string, any>, boolean, boolean];

export function useShiftVendorToACHState(vendorId, orgId, token): ReturnType {
  const history = useHistory();
  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const paymentActions = useStoreActions(paymentsStore);
  const [formValues, setFormValues] = useState({
    id: null,
    routingNumber: '',
    accountNumber: '',
  });
  const { onApiCall: getDeliveryMethod } = useApi<[Record<string, any>], Record<string, any>>({
    api: deliveryMethodsApi.getDeliveryMethodACHWithToken,
  });

  const deliveryMethodActions = getDeliveryMethodActions(useDispatch());
  const { invalidTokenData, loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation);

  const editMode = !!formValues.id;

  const model = useMemo(
    () => ({
      deliveryType: DeliveryType.ACH,
      bankAccount: {
        accountType: BankAccountType.CHECKING,
        routingNumber: formValues.routingNumber,
        accountNumber: formValues.accountNumber,
      },
    }),
    [formValues.routingNumber, formValues.accountNumber]
  );

  const submitAction = async (values) => {
    if (editMode) {
      return updateDeliveryMethod(values);
    }

    return createDeliveryMethod(values);
  };

  const goToSuccessPage = () => {
    const isCurrentPaymentToAch = paymentId && payment?.status === PaymentStatus.SCHEDULED;
    const path = isCurrentPaymentToAch
      ? shiftVendorToAchLocations.shiftedCurrentPayment
      : shiftVendorToAchLocations.success;
    history.push(
      generatePath(path, {
        id: vendorId,
        token,
      })
    );
    paymentActions.fetchEmailToVendorDetails({ token });
  };

  const updateDeliveryMethod = async (values) => {
    await deliveryMethodActions.updateDeliveryMethodACHWithToken({
      token,
      deliveryMethod: {
        ...values,
        id: formValues.id,
      },
      orgId,
      vendorId,
    });

    goToSuccessPage();
  };

  const createDeliveryMethod = async (values) => {
    await deliveryMethodActions.replaceDeliveryMethodWithToken({
      token,
      deliveryMethod: { ...values },
      orgId,
      vendorId,
    });

    goToSuccessPage();
  };

  const [deliveryMethod, { submit }, validationErrors] = useForm(model, {
    submit: submitAction,
  });

  const initialState = useCallback(async () => {
    try {
      const response = await getDeliveryMethod({ token });
      const { deliveryMethod } = response;

      if (deliveryMethod) {
        const { routingNumber, accountNumber } = deliveryMethod.bankAccount;
        setFormValues({
          id: deliveryMethod.id,
          routingNumber,
          accountNumber,
        });
      }
    } catch (e) {
      history.push(
        generatePath(shiftVendorToAchLocations.invalidToken, {
          id: vendorId,
          token,
        })
      );
    }
  }, [token, getDeliveryMethod, history, vendorId]);

  useEffect(() => {
    initialState();
  }, [initialState]);

  useEffect(() => {
    if (invalidTokenData && invalidTokenData?.code) {
      if (invalidTokenData?.code === BANK_ACCOUNT_EXIST) {
        validationErrors['bankAccount.accountNumber'] = `server.${invalidTokenData?.code}`;
      } else {
        history.push(
          generatePath(shiftVendorToAchLocations.invalidToken, {
            id: vendorId,
            token,
          })
        );
      }
    }
  }, [invalidTokenData, history, token, validationErrors, vendorId]);

  return [submit, deliveryMethod, isLoading, editMode];
}
