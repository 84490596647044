import * as React from 'react';
import Box from 'src/core/ds/box';

export type ListItemContainerProps = {
  isSelected?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
  testId?: string;
  isCheckboxDisabled?: boolean;
};

export const ListItemContainer = ({
  isSelected,
  disabled,
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  testId,
  isCheckboxDisabled,
}: ListItemContainerProps) => {
  const hoverBoxShadow = disabled ? '500' : '600';
  const hoverProperties = { boxShadow: hoverBoxShadow };

  return (
    <Box
      data-testid={testId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      backgroundColor="white"
      borderRadius="lg"
      width="full"
      cursor={disabled || isCheckboxDisabled ? 'auto' : 'pointer'}
      boxSizing="border-box"
      textDecoration="none"
      overflow="hidden"
      mb={4}
      transitionProperty="box-shadow"
      transitionDuration="300ms"
      minHeight="10rem"
      border="2px"
      borderColor={isSelected && !disabled ? 'green.500' : 'white'}
      padding={5}
      opacity={disabled ? 0.5 : 1}
      boxShadow="500"
      _hover={hoverProperties}
    >
      {children}
    </Box>
  );
};
