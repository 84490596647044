import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { NotificationCardTypes } from 'src/utils/consts';
import { getVendorHandleURL } from 'src/utils/organizations';
import { CopyCompanyHandle } from './copy-company-handle';

type Props = {
  dismiss: () => void;
  handle: string;
  companyName: string;
};

export function CompanyHandlerModal({ dismiss, handle, companyName }: Props) {
  const intl = useIntl();
  const link = getVendorHandleURL(handle);
  const [historyPush] = useHistoryWithOrgId();
  const clipboardText = intl.formatMessage(
    { id: 'requests.melioMeLinkCard.clipboardText' },
    { link, vendorName: companyName }
  );

  const onGoToDashboardClick = () => {
    historyPush({
      path: getPaidLocations.dashboard,
    });
  };

  return (
    <ModalMessage
      largeSize
      onCloseClick={dismiss}
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText label="getPaid.new.create.shareModal.shareYourCompanyURL" />
        </Box>
      }
      contentComponent={
        <Box w="full" maxW={{ md: '60rem' }}>
          <Box mb="6" w="full" textStyle="body14">
            <MINotificationCard
              subtitle={{
                label: 'getPaid.new.create.shareModal.shareYourCompanyURLModalDescription',
              }}
              type={NotificationCardTypes.INFO}
            />
          </Box>
          <Box border="1px" borderRadius="lg" p={{ base: 6, xl: 8 }} borderColor="grey.500">
            <CopyCompanyHandle link={link} clipboardText={clipboardText} />
          </Box>
        </Box>
      }
      buttonComponent={
        <Flex justify="flex-end">
          <Button onClick={dismiss} label="general.cancel" variant={ButtonVariants.tertiaryNaked} mr={3} />
          <Button
            onClick={onGoToDashboardClick}
            label="getPaid.new.create.shareModal.goDashboard"
            variant={ButtonVariants.secondary}
          />
        </Flex>
      }
    />
  );
}
