import { useGetDeliveryMethod } from 'src/hooks';

const useGetAchDeliveryMethod = () => {
  const orgDeliveryMethods = useGetDeliveryMethod();
  const manualDeliveryMethod = orgDeliveryMethods.find(
    (deliveryMethod) => deliveryMethod.deliveryType === 'ach' && deliveryMethod.plaidAccount === null
  );

  return manualDeliveryMethod;
};

export default useGetAchDeliveryMethod;
