import { InvoiceType } from 'src/modules/invoices/types';
import { useGetProSingleItemActions } from 'src/pages/get-pro/hooks/useGetProSingleItemActions';
import { analytics } from 'src/services/analytics';
import { GetProTabs } from 'src/utils/consts';

type Props = {
  invoice: InvoiceType;
  tab: GetProTabs;
  eventPage?: string;
  remove: () => void;
  cancelPending: () => void;
};

export const useDrawerFooter = ({ invoice, tab, eventPage, remove, cancelPending }: Props) => {
  const { canRemove, canAcknowledge, canCancelPending } = useGetProSingleItemActions(invoice, tab);

  const actionLabel = canRemove || canAcknowledge ? 'getPro.cards.actions.remove' : 'getPro.cards.actions.cancel';

  const shouldShowDrawerFooter = canRemove || canAcknowledge || canCancelPending;

  const onAction = async () => {
    if (canRemove || canAcknowledge) {
      eventPage && analytics.track(eventPage, 'remove-payment-click', { paymentId: invoice.id });
      await remove();
    } else {
      eventPage && analytics.track(eventPage, 'cancel-payment-click', { paymentId: invoice.id });
      await cancelPending();
    }
  };

  return { onAction, actionLabel, shouldShowDrawerFooter };
};
