import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { IconNames } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';
import { HStack } from 'src/core/ds/stack';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';

type Props = {
  isOpen: boolean;
  onChangeFundingSource: () => void;
  onTryAgain: () => void;
};

export const InstallmentsErrorModal = ({ isOpen, onChangeFundingSource, onTryAgain }: Props) => (
  <Modal isOpen={isOpen} onClose={onTryAgain} closeOnOverlayClick={false} data-testId="installments-error-modal">
    <Modal.Header>
      <Modal.Icon name={IconNames.alertFill} color="yellow.500" />
      <Modal.HeaderTitle>
        <MIFormattedText label="financing.installmentsErrorModal.headerTitle" />
      </Modal.HeaderTitle>
    </Modal.Header>
    <Modal.Body>
      <Box textStyle="body2">
        <MIFormattedText
          label="financing.installmentsErrorModal.body"
          values={{
            contactSupport: <ContactSupportLink />,
          }}
        />
      </Box>
    </Modal.Body>
    <Modal.Footer>
      <HStack spacing={4}>
        <Button
          size={ButtonSizes.md}
          onClick={onChangeFundingSource}
          label="financing.installmentsErrorModal.payAnotherWay"
          variant={ButtonVariants.tertiary}
        />
        <Button size={ButtonSizes.md} onClick={onTryAgain} label="financing.installmentsErrorModal.tryAgain" />
      </HStack>
    </Modal.Footer>
  </Modal>
);

const ContactSupportLink = () => {
  const onClickSupport = () => {
    intercomService.show();
    analytics.track('installmentsErrorModal', 'installments-error-contact-support');
  };

  return (
    <Box textStyle="link1" as="span" onClick={onClickSupport}>
      <MIFormattedText label="financing.installmentsErrorModal.contactSupport" />
    </Box>
  );
};
