import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import { MicroDepositActions, MicroDepositState, Resolutions } from 'src/pages/settings/hooks/microDepositsCommon';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { MicroDepositsLimitReachedDialog } from './modals/MicroDepositsLimitReachedDialog';
import { MicroDepositsSuccessDialog } from './modals/MicroDepositsSuccessDialog';
import { VerifyMicroDepositsDialog } from './modals/VerifyMicroDepositsDialog';
import { VerifyFundingSourceMicroDeposits } from './VerifyFundingSourceMicroDeposits';

type DialogActionsType = {
  dismiss: () => void;
  onVerifyFinished: () => void;
  onContinueWithoutVerifying?: () => void;
};

type Props = {
  eventPage: string;
  dialogSuccessTitle: string;
  dialogSuccessSubtitle: string;
  verifyingId: number | null;
} & DialogActionsType &
  MicroDepositState &
  MicroDepositActions;

function bindEvents(eventPage: string, actions: DialogActionsType): Record<string, () => void> {
  const { dismiss, onVerifyFinished, onContinueWithoutVerifying } = actions;

  return {
    cancel: () => {
      analytics.track(eventPage, 'cancel-dialog');
      dismiss();
      onVerifyFinished();
    },

    dismissFailed: () => {
      analytics.track(eventPage, 'dismiss-failed-dialog');
      dismiss();
      onVerifyFinished();
    },

    dismissSuccess: () => {
      analytics.track(eventPage, 'dismiss-success-dialog');
      dismiss();
      onVerifyFinished();
    },

    continueWithoutVerifying: () => {
      analytics.track(eventPage, 'continue-without-verifying');
      dismiss();
      onVerifyFinished();
      onContinueWithoutVerifying && onContinueWithoutVerifying();
    },
  };
}

export const VerifyMicroDeposits = (props: Props) => {
  const {
    onVerifyFinished,
    dismiss,
    eventPage,
    resolution,
    verifyingId,
    dialogSuccessTitle,
    dialogSuccessSubtitle,
    onContinueWithoutVerifying,
  } = props;
  const dismissEvents = useMemo(
    () => bindEvents(eventPage, { onVerifyFinished, dismiss, onContinueWithoutVerifying }),
    [onVerifyFinished, dismiss, eventPage, onContinueWithoutVerifying]
  );
  const companyInfo = useSelector(getCompanyInfo);
  const companyName = companyInfo?.companyName ?? '';
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(verifyingId));
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(verifyingId));
  const digits = getAccountNumber4digits(fundingSource ? fundingSource.bankAccount : deliveryMethod.bankAccount);

  if (fundingSource && !resolution) {
    return (
      <VerifyFundingSourceMicroDeposits
        {...props}
        fundingSource={fundingSource}
        digits={digits}
        dismissEvents={dismissEvents}
        onContinueWithoutVerifying={onContinueWithoutVerifying}
        companyName={companyName}
      />
    );
  }

  switch (resolution) {
    case Resolutions.SUCCESS:
      return (
        <MicroDepositsSuccessDialog
          title={dialogSuccessTitle}
          subtitle={dialogSuccessSubtitle}
          onOkAction={dismissEvents.dismissSuccess}
        />
      );
    case Resolutions.SUPPORT:
      return <MicroDepositsLimitReachedDialog onOkAction={dismissEvents.dismissFailed} />;
    default:
      return (
        <VerifyMicroDepositsDialog
          {...props}
          companyName={companyName}
          dismiss={dismissEvents.cancel}
          digits={digits}
        />
      );
  }
};
