import * as React from 'react';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';

type Props = {
  onNext: () => void;
  onPrev?: () => void;
  onExit?: () => void;
  title?: string;
  label: string;
  isLoading: boolean;
  children: React.ReactNode;
  isNextDisabled?: boolean;
  hideLayout?: boolean;
};

type SideScrolledContentProps = {
  children?: React.ReactNode;
};

type TableWrapperProps = {
  children?: React.ReactNode;
};

export const BatchUploadReviewPage: React.FC<Props> = ({
  onNext,
  onPrev,
  onExit,
  isLoading,
  title,
  label,
  children,
  isNextDisabled,
  hideLayout,
}) => {
  const tableWrapperWithChildren = (
    <TableWrapper>
      <SideScrolledContent>{children}</SideScrolledContent>
    </TableWrapper>
  );

  return hideLayout ? (
    <Box>{tableWrapperWithChildren}</Box>
  ) : (
    <StepLayoutPage
      title={title}
      nextLabel={label}
      onNext={onNext}
      onPrev={onPrev}
      goExit={onExit}
      isLoading={isLoading}
      innerSize={100}
      isNextDisabled={isNextDisabled}
      docked
    >
      {tableWrapperWithChildren}
    </StepLayoutPage>
  );
};

const SideScrolledContent: React.FC<SideScrolledContentProps> = ({ children }) => (
  <Box
    py={{ base: 0, md: 'auto' }}
    px={{ base: 5, md: 'auto' }}
    overflow={{ base: 'scroll', md: 'auto' }}
    bgImage={{
      base: `linear-gradient(to left, white, white), linear-gradient(to left, white, white), linear-gradient(to left, white, white), linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0)), linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0))`,
      md: 'initial',
    }}
    bgPos={{ base: 'left center, right center, right center, left center, right center', md: 'initial' }}
    bgRepeat={{ base: 'no-repeat', md: 'initial' }}
    bgColor={{ base: 'white', md: 'initial' }}
    bgSize={{ base: '30px 100%, 30px 100%, 5px 100%, 20px 100%, 20px 100%', md: 'initial' }}
    bgAttachment={{ base: 'local, local, scroll, scroll, scroll', md: 'initial' }}
  >
    {children}
  </Box>
);

// those negative margin based on positive padding of WizardContainer to make it full screen in mobile
const TableWrapper: React.FC<TableWrapperProps> = ({ children }) => (
  <Box mx={{ base: -5, sm: -10, md: 'auto' }}>{children}</Box>
);
