import React from 'react';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  type: NotificationCardTypes;
  label: string;
};

export const NotificationCard = ({ type, label }: Props) => (
  <Box mb={{ base: 5, sm: 10 }} mt={{ base: 0, sm: 2 }}>
    <MINotificationCard
      type={type}
      subtitle={{
        label,
      }}
    />
  </Box>
);
