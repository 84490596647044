import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useLegalInfoPage } from 'src/pages/get-paid/hooks/useLegalInfoPage';
import { useCreateBatchPayments } from 'src/pages/regular-batch-payments/hooks/useCreateBatchPayments';

const BatchPaymentsLegalInfoPage: React.FC = () => {
  const history = useHistory();
  const {
    createBatchPaymentAction,
    createBatchPaymentData: { isCreating },
    vendorsAddressesAreUpdating,
  } = useCreateBatchPayments();

  const { LegalInfoPage } = useLegalInfoPage({
    title: 'bills.pay.confirm.completeLegalInfo.title',
    subtitle: 'bills.pay.confirm.completeLegalInfo.subtitle',
    nextLabel: 'progress.continue',
    isLoading: isCreating || vendorsAddressesAreUpdating,
    onNext: createBatchPaymentAction,
    onPrev: () => history.goBack(),
  });

  return LegalInfoPage;
};

export default BatchPaymentsLegalInfoPage;
