import { Box, useStyleConfig } from '@chakra-ui/react';
import { BottomBar } from './BottomBar';
import { Content } from './Content';
import { Alert } from './Content/Alert';
import { Body } from './Content/Body';
import { Footer } from './Content/Footer';
import { Header } from './Content/Header';
import { TopBar } from './TopBar';

type Props = {
  children: React.ReactNode;
};

export const WizardLayout = ({ children }: Props) => {
  const styles = useStyleConfig('WizardLayout');

  return <Box __css={styles}>{children}</Box>;
};

WizardLayout.TopBar = TopBar;
WizardLayout.Content = Content;
WizardLayout.BottomBar = BottomBar;
WizardLayout.Header = Header;
WizardLayout.Alert = Alert;
WizardLayout.Body = Body;
WizardLayout.Footer = Footer;
