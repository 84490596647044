import { featureFlags } from '@melio/shared-web';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Separator } from 'src/core/ds/separator';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useDefaultFundingSourceId } from 'src/pages/bill/components/BillsGallery/hooks/useDefaultFundingSourceId';
import { ALL_AT_ONCE_OPTION_ID, BatchDateOption } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { BatchPaymentsHeaderProps } from 'src/pages/regular-batch-payments/components/header/Header';
import {
  ChangeBatchSettingParams,
  useBatchSettingsChange,
} from 'src/pages/regular-batch-payments/hooks/useBatchSettingsChange';
import { FeatureFlags } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { BatchDatePicker } from './BatchDatePicker';
import { BulkPaymentToggle } from './BulkPaymentToggle';
import { PaymentDeductionDateMenu } from './PaymentDeductionDateMenu';
import { PaymentMethodMenu } from './PaymentMethodMenu';

export const BillSummaryInfo = ({ isLoadingBills }: BatchPaymentsHeaderProps) => {
  const isMultipleDates = useSelector(regularBatchPaymentsStore.selectors.isMultipleDeductionDate);
  const {
    isBulkPaymentsToggleDisabled,
    changeBatchSetting,
    invalidBatchPaymentListGrouping,
    invalidBatchPaymentRiskTotalAmount,
  } = useBatchSettingsChange();
  const currentParams: ChangeBatchSettingParams & { billIds: number[] } = useSelector(
    regularBatchPaymentsStore.selectors.settings.settings
  );
  const { scheduledDate, minScheduledDate } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const isMultipleFundingSource = useSelector(regularBatchPaymentsStore.selectors.isMultipleFundingSource);

  const [batchActionMerge, setBatchActionMerge] = useLocationState('batchActionMerge');
  const { getDefaultFundingSourceId, defaultFundingSourceId } = useDefaultFundingSourceId();

  const isDatePickerOpen = currentParams.dateOption === ALL_AT_ONCE_OPTION_ID;

  const [isBatchUncombinedEnbled] = featureFlags.useFeature(FeatureFlags.BatchUncombined, false);
  const [isBulkPaymentEnabled] = featureFlags.useFeature(FeatureFlags.BulkPayment, false);

  const didBatchPaymentsPageLoaded = useRef<boolean>(false);

  useEffect(() => {
    getDefaultFundingSourceId();
  }, [getDefaultFundingSourceId]);

  const loadBatchPaymentsPage = useCallback(async () => {
    if (batchActionMerge) {
      setBatchActionMerge(false);

      return;
    }

    const fsId = currentParams.fundingSourceId || defaultFundingSourceId;

    if (fsId) {
      didBatchPaymentsPageLoaded.current = true;
      changeBatchSetting(
        {
          fundingSourceId: fsId,
          bulkPaymentToggleOn: isBatchUncombinedEnbled ? false : currentParams.bulkPaymentToggleOn,
        },
        true
      );
    }
  }, [
    batchActionMerge,
    changeBatchSetting,
    currentParams,
    defaultFundingSourceId,
    setBatchActionMerge,
    isBatchUncombinedEnbled,
  ]);

  useEffect(() => {
    if (!didBatchPaymentsPageLoaded.current) {
      loadBatchPaymentsPage();
    }
  }, [loadBatchPaymentsPage]);

  const toggleBulkPayment = useCallback(
    (bulkPaymentToggleOn: boolean) => {
      changeBatchSetting(
        {
          bulkPaymentToggleOn,
        },
        true
      );
    },
    [changeBatchSetting]
  );

  const onDateChange = useCallback(
    (date: string | null) => {
      changeBatchSetting({
        scheduledDate: date,
      });
    },
    [changeBatchSetting, currentParams.dateOption]
  );

  const onFundingSourceChange = useCallback(
    (selectedFundingSourceId: number) => {
      changeBatchSetting({
        fundingSourceId: selectedFundingSourceId,
      });
    },
    [changeBatchSetting]
  );

  const onDateOptionChanged = useCallback(
    (selectedDateOption: BatchDateOption) => {
      const scheduledDate = currentParams.dateOption === ALL_AT_ONCE_OPTION_ID ? currentParams.scheduledDate : null;
      changeBatchSetting({
        scheduledDate,
        dateOption: selectedDateOption,
      });
    },
    [changeBatchSetting, currentParams.dateOption, currentParams.scheduledDate]
  );

  return (
    <Box mr={4}>
      <Box textStyle="body4Semi" color="grey.700" textTransform="uppercase" pb={10}>
        <MIFormattedText label="regularBatchPayments.batchSettings" />
      </Box>
      <Flex direction="row" mb={2}>
        <Flex justifyContent="flex-start">
          {isBulkPaymentEnabled && (
            <>
              <BulkPaymentToggle
                invalidBatchPaymentListGrouping={invalidBatchPaymentListGrouping}
                invalidBatchPaymentRiskTotalAmount={invalidBatchPaymentRiskTotalAmount}
                bulkPaymentToggleOn={currentParams.bulkPaymentToggleOn}
                onChange={toggleBulkPayment}
                isDisabled={isBulkPaymentsToggleDisabled}
              />
              <Box justifyContent="center" marginX={6}>
                <Separator color="grey.300" size="sm" orientation="vertical" m={0} />
              </Box>
            </>
          )}
          <Box>
            <Box textStyle="body4Semi" color="grey.600" pb={2}>
              <MIFormattedText label="regularBatchPayments.BatchSettings.selectPaymentMethod" />
            </Box>
            {currentParams.fundingSourceId && (
              <PaymentMethodMenu onChange={onFundingSourceChange} isLoading={isLoadingBills} />
            )}
          </Box>
          <Box justifyContent="center" marginX={6}>
            <Separator color="grey.300" size="sm" orientation="vertical" m={0} />
          </Box>
          <Box>
            <Box textStyle="body4Semi" color="grey.600" pb={2}>
              <MIFormattedText label="regularBatchPayments.BatchSettings.setDeductionDate" />
            </Box>
            <Flex>
              <PaymentDeductionDateMenu onChange={onDateOptionChanged} disableButton={isLoadingBills} />
              {isDatePickerOpen && !isMultipleDates ? (
                <Box ml={6}>
                  <BatchDatePicker
                    scheduledDate={scheduledDate}
                    minScheduledDate={minScheduledDate}
                    onChange={onDateChange}
                    disableButton={isLoadingBills || isMultipleFundingSource}
                  />
                </Box>
              ) : null}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
