import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { analytics } from 'src/services/analytics';
import { NotificationCardTypes } from 'src/utils/consts';
import { getFailedPaymentDescription, getFailedPaymentTitle } from 'src/utils/payments';
import { RenderStatusMessageProps } from '../RenderStatusMessage';
import { getLearnMoreLink } from '../utils';
import { RenderMessage } from './RenderMessage';

type Props = Omit<RenderStatusMessageProps, 'status'>;

export const RenderErrorMessage = ({
  bill,
  isEmbeddedMode,
  isSelfServeRefundEnabled,
  onRefundPayment,
  payment,
}: Props) => {
  const shouldShowRefundLink = isSelfServeRefundEnabled && payment?.metadata?.isPaymentRefundable;
  const failedPaymentTitle = getFailedPaymentTitle(payment);
  const failedPaymentDescription = getFailedPaymentDescription(payment, bill, isEmbeddedMode);
  const learnMoreLink = getLearnMoreLink(payment);
  const errorSubtitle = {
    label: failedPaymentDescription.label,
    values: {
      fundingSource: failedPaymentDescription.values.fundingSource,
      vendorCompanyName: failedPaymentDescription.values.vendorCompanyName,
      returnedCheckReason: failedPaymentDescription.values.returnedCheckReason,
      formattedReturnedCheckReason: failedPaymentDescription.values.formattedReturnedCheckReason,
      learnMore: learnMoreLink,
      refund: shouldShowRefundLink ? (
        <MIFormattedText
          label="bills.form.paymentActivity.failedPaymentDescription.requestRefund"
          values={{
            link: (
              <MIInlineLink
                testId="request-refund-link"
                label="bills.form.paymentActivity.failedPaymentDescription.link"
                onClick={() => onRefundPayment(payment.id, payment.metadata?.isSelfServeRefundEligible)}
              />
            ),
          }}
        />
      ) : null,
    },
  };

  useEffect(() => {
    if (shouldShowRefundLink) {
      analytics.trackAction('refund-link', {
        paymentId: payment.id,
        billId: bill.id,
        isSelfServeEligible: payment?.metadata?.isSelfServeRefundEligible,
      });
    }
  }, [shouldShowRefundLink, payment.id, bill.id, payment?.metadata?.isSelfServeRefundEligible]);

  return (
    <RenderMessage type={NotificationCardTypes.ERROR} title={{ label: failedPaymentTitle }} subtitle={errorSubtitle} />
  );
};
