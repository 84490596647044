export const SUPPORT_FAQ = [
  {
    question: 'settings.support.faq.question1',
    answer: 'settings.support.faq.answer1',
  },
  {
    question: 'settings.support.faq.question2',
    answer: 'settings.support.faq.answer2',
  },
  {
    question: 'settings.support.faq.question3',
    answer: 'settings.support.faq.answer3',
  },
  {
    question: 'settings.support.faq.question4',
    answer: 'settings.support.faq.answer4',
  },
  {
    question: 'settings.support.faq.question5',
    answer: 'settings.support.faq.answer5',
  },
];

export const SUPPORT_FAQ_NO_FAST_CHECKS = [
  {
    question: 'settings.support.faq.question1',
    answer: 'settings.support.faq.answer1',
  },
  {
    question: 'settings.support.faq.question2',
    answer: 'settings.support.faq.answer2',
  },
  {
    question: 'settings.support.faq.question4',
    answer: 'settings.support.faq.answer4',
  },
  {
    question: 'settings.support.faq.question5',
    answer: 'settings.support.faq.answer5',
  },
];

export const ADD_A_BANK_ACCOUNT_LINK =
  'https://help.meliopayments.com/en/articles/3353832-how-to-add-a-bank-account-automatically';

export const BROWSE_ALL_TOPICS_LINK = 'https://help.meliopayments.com';

export const PAYMENTS_REPORT_HELP_LINK =
  'https://help.meliopayments.com/en/articles/5136350-how-can-i-download-my-payment-history-data';

export const BANK_ACCOUNT_VERIFIED_ABSORBED_FEES_HELP_LINK =
  'https://help.meliopayments.com/en/articles/5187223-how-can-i-pay-the-credit-card-fee-on-behalf-of-my-customers';

export const INTERNATIONAL_PAYMENTS_LINK =
  'https://help.meliopayments.com/en/articles/5697160-what-countries-are-open-to-international-payments-through-melio';

export const WHAT_CAN_YOU_PAY_LINK = 'https://help.meliopayments.com/en/articles/5775116-what-can-you-pay-with-melio';

export const RECURRING_PAYMENTS_LINK =
  'https://help.meliopayments.com/en/articles/5733727-how-to-make-recurring-payments-in-melio';

export const EXPEDITE_YOU_CHECK_PAYMENT_LINK =
  'https://help.meliopayments.com/en/articles/5619550-expedite-your-check-payment';

export const PARTIAL_PAYMENTS_LINK =
  'https://help.meliopayments.com/en/articles/5619512-how-to-use-the-partial-payments-feature';

export const BILL_INBOX_LINK = 'https://melio.zendesk.com/hc/en-us/articles/5531082739996';
