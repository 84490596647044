import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useModal } from 'src/helpers/react/useModal';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { ReactComponent as MelioDirectoryIndication } from 'src/images/business-directory/melio-directory-indication.svg';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { billLocations } from 'src/pages/bill/locations';
import { vendorLocations } from 'src/pages/vendor/locations';
import { DuplicateVendorNameModal } from 'src/pages/vendor-directory/DuplicateVendorNameModal/DuplicateVendorNameModal';
import { analytics } from 'src/services/analytics';
import { directoryApi } from 'src/services/api/directory';
import { useForm } from 'src/ui/form';
import { FormContainer } from 'src/ui/form/FormElements';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { convertToDisplayAddress } from 'src/utils/address';
import { CompanyFormPage } from 'src/utils/consts';
import locations from 'src/utils/locations';

const eventPage = 'onboarding-add-vendor';

const VendorAccountNumberPage = () => {
  const history = useHistory();
  const { navigate } = usePreservedStateNavigator();
  const {
    params: { orgId, billerId },
  } = useRouteMatch<{ orgId: string; billerId: string }>();
  const [createdVendorId, setCreatedVendorId] = useState(null);
  const { selectors } = vendorsStore;
  const createStatus = useSelector(selectors.create.status());
  const isCreating = createStatus?.loading;
  const error = createStatus?.error || {};
  const validationErrors = error?.validationErrors;
  const vendorsActions = getStoreActions(vendorsStore)(useDispatch());
  const { onApiCall: fetchBillerById } = useApi({
    api: directoryApi.getBiller,
  });
  const [nextActionButtonClicked, setNextActionButtonClicked] = useState('');
  const [, setDuplicateDirectoryVendorNameError] = useState<boolean>(false);
  const [duplicateVendorNameModalView, showDuplicateVendorNameModal] = useModal(DuplicateVendorNameModal, {
    modalName: 'duplicate-vendor-name',
  });

  useEffect(() => {
    fetchBillerById({
      orgId: Number(orgId),
      billerId,
    }).then((data) => {
      if (data) {
        const { address, ...rest } = data.biller;
        vendorDataMV.setModelState((prevState) => ({
          ...prevState,
          ...rest,
          address: convertToDisplayAddress(address),
        }));
      }
    });
  }, []);

  const vendor = useMemo<any>(
    () => ({
      companyName: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      address: '',
      accountIdentifier: '',
    }),
    []
  );

  const [vendorDataMV, vendorFormActions] = useForm(vendor, {
    submit: async ({ companyName, contactName, contactEmail, contactPhone, address, accountIdentifier }) => {
      await createVendor({
        companyName,
        contactName,
        contactEmail,
        contactPhone,
        accountIdentifier,
        address,
        billerId,
      });
    },
  });

  const createVendor = async (values) => {
    try {
      const {
        payload: { id },
      } = await vendorsActions.create({ orgId, ...values });
      setCreatedVendorId(id);
    } catch (error: any) {
      if (values.billerId && error.error.code === 'VERR') {
        setDuplicateDirectoryVendorNameError(true);
        const eventProperties = {
          billerId: values.billerId,
          oldVendorName: values.companyName,
        };
        showDuplicateVendorNameModal({
          vendorName: vendorDataMV.companyName?.value,
          onClickSave: async (newVendorName) => {
            analytics.track(eventPage, 'duplicate-name-modal-click-save', {
              ...eventProperties,
              newVendorName,
            });
            await createVendor({
              ...values,
              companyName: newVendorName,
            });
          },
          onClickCancel: () => {
            analytics.track(eventPage, 'duplicate-name-modal-cancel', eventProperties);
          },
        });
      } else {
        setDuplicateDirectoryVendorNameError(false);
        throw error;
      }
    }
  };

  const saveVendorAndGoToCreateBill = async () => {
    analytics.track(eventPage, 'biller-account-number-save-and-create-bill', {});

    setNextActionButtonClicked('primary');
    await vendorFormActions.submit();
  };

  const saveVendorAndClose = async () => {
    analytics.track(eventPage, 'biller-account-number-save-and-close', {});

    setNextActionButtonClicked('secondary');
    await vendorFormActions.submit();
  };

  const goToBill = (id) =>
    navigate(
      `${generatePath(billLocations.create.index, {
        orgId,
      })}?vendorId=${id}&manually=true`,
      false
    );

  const goToDashboard = () =>
    navigate(locations.MainApp.dashboard.url(), false, {
      origin: CompanyFormPage.ONBOARDING,
    });

  const goToVendorView = (id) =>
    navigate(
      generatePath(vendorLocations.view, {
        orgId,
        id,
      }),
      false,
      {
        origin: CompanyFormPage.ONBOARDING,
      }
    );

  const goToAddVendorName = () => history.goBack();

  useEffect(() => {
    if (createdVendorId) {
      nextActionButtonClicked === 'primary' ? goToBill(createdVendorId) : goToVendorView(createdVendorId);
    }
  }, [createdVendorId]);

  return (
    <StepLayoutPage
      title="onboarding.addVendor.accountNumber.title"
      nextLabel="onboarding.addVendor.accountNumber.nextLabel"
      secondaryActionButtonLabel="onboarding.addVendor.accountNumber.nextLabelSecondary"
      isLoading={isCreating}
      onPrev={goToAddVendorName}
      goExit={goToDashboard}
      onSubmit={saveVendorAndGoToCreateBill}
      onSecondaryActionButtonClick={saveVendorAndClose}
    >
      {duplicateVendorNameModalView}
      <FormContainer>
        <WizardTextInputField
          id="companyName"
          label="onboarding.addVendor.accountNumber.vendorNameLabel"
          model={vendorDataMV.companyName}
          suffix={<MelioDirectoryIndication />}
          type="text"
          disabled
          required
        />
        <WizardTextInputField
          id="address"
          label="onboarding.addVendor.accountNumber.vendorAddressLabel"
          model={vendorDataMV.address}
          type="text"
          disabled
          required
        />
        <WizardTextInputField
          id="accountIdentifier"
          label="onboarding.addVendor.accountNumber.vendorAccNumberLabel"
          placeholder="onboarding.addVendor.accountNumber.vendorAccNumberPlaceholder"
          hint="onboarding.addVendor.accountNumber.vendorAccNumberHint"
          model={vendorDataMV.accountIdentifier}
          type="text"
          errorMessage={validationErrors?.accountIdentifier}
          autoFocus
          required
        />
      </FormContainer>
    </StepLayoutPage>
  );
};

export default VendorAccountNumberPage;
