import { createBatchUpdateSlice } from 'src/helpers/redux/restBatchUpdateSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { InvoiceType } from 'src/modules/invoices/types';
import { name } from '../constants';

export const proInvoicesBatchMarkAsPaidSlice = createBatchUpdateSlice<InvoiceType>({
  storeName: name,
  api: getProApi.invoices.batchMarkAsPaid,
  actionName: 'proBatchMarkAsPaid',
});
