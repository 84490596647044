import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { MenuItem } from 'src/core/ds/menu';

type Props = {
  onClick: () => void;
};

export const AddFundingSourceMenuItem = ({ onClick }: Props) => (
  <MenuItem testId="add-funding-source-item" as={Box} w="auto">
    <Box as="a" onClick={onClick} textStyle="link3" _hover={{ textDecoration: 'underline' }}>
      <MIFormattedText label="regularBatchPayments.addAnotherPaymentMethod" />
    </Box>
  </MenuItem>
);

export const AddCreditCardMenuItem = ({ onClick }: Props) => (
  <MenuItem testId="credit-card-item" onClick={onClick}>
    <Icon name={IconNames.creditCard} size={IconSize.lg} />
    <Box pl={4} textStyle="body3">
      <MIFormattedText label="regularBatchPayments.creditCard" />
    </Box>
  </MenuItem>
);

export const AddDebitCardMenuItem = ({ onClick }: Props) => (
  <MenuItem testId="debit-card-item" onClick={onClick}>
    <Icon name={IconNames.debitCard} size={IconSize.lg} />
    <Box pl={4} textStyle="body3">
      <MIFormattedText label="regularBatchPayments.debitCard" />
    </Box>
  </MenuItem>
);

export const AddBankAccountMenuItem = ({ onClick }: Props) => (
  <MenuItem testId="bank-account-item" onClick={onClick}>
    <Icon name={IconNames.bank} size={IconSize.lg} />
    <Box pl={4} textStyle="body3">
      <MIFormattedText label="regularBatchPayments.bank" />
    </Box>
  </MenuItem>
);
