import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { ErrorPage } from 'src/components/layout/ErrorLayoutPage';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useSiteContext } from 'src/hoc/withSiteContext';
import failedToLoad from 'src/images/qbo/failed-to-load-002-icon.png';
import { AddressNameType } from 'src/pages/bill/consts';
import { resetErrorAction } from 'src/redux/payBillWizard/actions';
import { getErrorCode } from 'src/redux/payBillWizard/selectors';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { CompanyFormPage, DialogType, DialogVariants, TaxIdType } from 'src/utils/consts';
import { CompanyInfoType, FieldType, GoogleCombinedAddressType } from 'src/utils/types';
import { LegalInfoForm } from './LegalInfoForm';

type Props = {
  legalCompanyName: string | null;
  companyName: string | null;
  taxId?: string;
  taxIdType?: TaxIdType;
  validationErrors: Record<string, any>;
  onLegalInfoChange: (field: FieldType) => void;
  isLoading: boolean;
  onAddressChange: (address: GoogleCombinedAddressType, type?: AddressNameType) => void;
  address: GoogleCombinedAddressType;
  onPrev?: () => void;
  goExit: () => void;
  handleOnSubmit: () => void;
  contactFirstName: string;
  contactLastName: string;
  phone?: string;
  companyAddress: GoogleCombinedAddressType;
  companyInfo: CompanyInfoType;
};

const PayBillCompleteLegalInfoPage = ({
  legalCompanyName,
  taxId,
  taxIdType,
  address,
  isLoading,
  goExit,
  onPrev,
  validationErrors,
  onLegalInfoChange,
  onAddressChange,
  handleOnSubmit,
  companyName,
}: Props) => {
  const errorCode = useSelector(getErrorCode);
  const intl = useIntl();
  const site = useSiteContext();
  const dispatch = useDispatch();

  const hideErrorAlert = () => {
    analytics.track('legal-info', 'hide-error-alert');
    dispatch(resetErrorAction());
  };

  if (site.embeddedMode && errorCode) {
    analytics.track('bill-error', 'payment-cannot-be-approved');

    return (
      <ErrorPage
        illustration={failedToLoad}
        title="bills.pay.confirm.error.approvingPaymentTitle"
        subtitle="bills.pay.confirm.error.approvingPaymentSubtitle"
        buttonAction={goExit}
        buttonLabel="bills.pay.confirm.error.approvingPaymentCTA"
      />
    );
  }

  return (
    <StepLayoutPage
      title="bills.pay.confirm.completeLegalInfo.title"
      subtitle="bills.pay.confirm.completeLegalInfo.subtitle"
      subTitleValues={{ companyName }}
      goExit={goExit}
      onPrev={onPrev}
      onNext={handleOnSubmit}
      innerSize={65}
      nextLabel="bills.pay.confirm.completeLegalInfo.action"
      isLoading={isLoading}
      fullWidthCTA
    >
      {errorCode && (
        <Dialog
          type={DialogType.ALERT}
          variant={DialogVariants.ERROR}
          title="bills.pay.confirm.error.title"
          subtitle={intl.messages[`server.${errorCode}`] ? `server.${errorCode}` : 'server.Network Error'}
          onCancelAction={hideErrorAlert}
          cancelButtonText="bills.pay.confirm.error.CTA"
        />
      )}
      <LegalInfoContainer>
        <FormWrapper>
          <LegalInfoForm
            legalCompanyName={legalCompanyName}
            address={address}
            onChange={onLegalInfoChange}
            validationErrors={validationErrors}
            taxId={taxId}
            taxIdType={taxIdType}
            onAddressChange={onAddressChange}
            companyFormPage={CompanyFormPage.ONBOARDING}
            isAutofocus
          />
        </FormWrapper>
      </LegalInfoContainer>
    </StepLayoutPage>
  );
};

export default PayBillCompleteLegalInfoPage;

const LegalInfoContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 45rem;
  padding-top: 0.7rem;

  @media ${devices.desktop} {
    justify-content: space-between;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2.4rem 0 2rem;
  }
`;

const FormWrapper = styled.div``;
