import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { Link } from 'src/core/ds/link';
import {
  financingAnalyticsEvents,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { learnMoreInstallmentInMelio } from 'src/pages/bill/pay/installments/consts';
import { useBusinessApplicationContext } from 'src/pages/bill/pay/installments/context/business-context/useBusinessApplicationContext';
import { BusinessDetailsForm } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/BusinessDetailsForm';
import { BusinessDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { analytics } from 'src/services/analytics';

type Props = {
  goNext: () => void;
  cancelFlow: () => void;
};

export const BusinessDetailsPage = ({ goNext, cancelFlow }: Props) => {
  const { setBusinessDetails } = useBusinessApplicationContext();

  const handleSubmit = (businessDetailsModel: BusinessDetailsModel) => {
    analytics.track(financingPage.businessDetails, financingAnalyticsEvents.businessDetailsContinue, {
      annualRevenueReported: businessDetailsModel.annualRevenue,
      businessTypeReport: businessDetailsModel.type,
      state: businessDetailsModel.state,
      city: businessDetailsModel.city,
      industry: businessDetailsModel.industry,
    });
    setBusinessDetails?.(businessDetailsModel);
    goNext();
  };

  return (
    <WizardLayout>
      <WizardLayout.TopBar onCancelClick={cancelFlow} />
      <WizardLayout.Content>
        <WizardLayout.Header>
          <Box textStyle="h1Semi" textAlign="center" mb={4} data-testid="financing-business-details-title">
            <MIFormattedText label="financing.businessDetails.header.title" />
          </Box>
          <Box textStyle="body2" textAlign="center" mb={10}>
            <MIFormattedText
              label="financing.businessDetails.header.subtitle"
              values={{
                link: (text) => (
                  <Link
                    href={learnMoreInstallmentInMelio}
                    target="_blank"
                    data-testid="financing-business-details-learn-more"
                  >
                    {text}
                  </Link>
                ),
              }}
            />
          </Box>
        </WizardLayout.Header>
        <WizardLayout.Body>
          <BusinessDetailsForm onSubmit={handleSubmit} />
        </WizardLayout.Body>
      </WizardLayout.Content>
    </WizardLayout>
  );
};
