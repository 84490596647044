import curry from 'lodash/curry';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import { InvoiceType } from 'src/modules/invoices/types';
import { serializePaymentId } from 'src/utils/bills';
import { PaymentRequestTabs, PaymentStatus } from 'src/utils/consts';
import { isSameDay } from 'src/utils/dates';
import { getLatestPayment } from 'src/utils/payments';
import { PaymentRequestType, PaymentType } from 'src/utils/types';

enum InvoicesGroups {
  FAILED = 'failed',
  OVER_DUE = 'overDue',
  DUE_THIS_WEEK = 'dueThisWeek',
  DUE_THIS_MONTH = 'dueThisMonth',
  DUE_LATER = 'dueLater',
  TODAY_SCHEDULED = 'todayScheduled',
  PAST_WEEK_SCHEDULED = 'pastWeekScheduled',
  TODAY_PAID = 'todayPaid',
  PAST_WEEK_PAID = 'pastWeekPaid',
  PAST_EARLIER = 'paidEarlier',
  REST = 'rest',
}

const compareLastPaymentDate = curry(
  (dateField: string, compare: (date) => boolean, invoice: PaymentRequestType): boolean => {
    const isMarkedAsPaid = !isEmpty(invoice?.markedAsPaidAt);

    if (isMarkedAsPaid) {
      return compare(invoice.markedAsPaidAt);
    }

    const lastPayment = invoice?.payments ? getLatestPayment(invoice.payments) : null;

    if (!lastPayment) {
      return false;
    }

    return compare(lastPayment[dateField]);
  }
);

const groupFunctions = {
  [InvoicesGroups.FAILED]: (invoice) =>
    getLatestPayment(invoice?.bill?.payments || [])?.status === PaymentStatus.FAILED,
  [InvoicesGroups.OVER_DUE]: (invoice) => moment(invoice.dueDate).isBefore(moment()),
  [InvoicesGroups.DUE_THIS_WEEK]: (invoice) => moment(invoice.dueDate).isSame(moment(), 'week'),
  [InvoicesGroups.DUE_THIS_MONTH]: (invoice) => moment(invoice.dueDate).isSame(moment(), 'month'),
  [InvoicesGroups.DUE_LATER]: (invoice) => moment(invoice.dueDate).isAfter(moment(), 'month'),
  [InvoicesGroups.TODAY_SCHEDULED]: compareLastPaymentDate('createdAt', (date) => isSameDay(date)),
  [InvoicesGroups.PAST_WEEK_SCHEDULED]: compareLastPaymentDate('createdAt', (date) =>
    moment(date).isBefore(moment(), 'week')
  ),
  [InvoicesGroups.TODAY_PAID]: compareLastPaymentDate('deliveryEta', (date) => isSameDay(date)),
  [InvoicesGroups.PAST_WEEK_PAID]: compareLastPaymentDate('deliveryEta', (date) =>
    moment(date).isAfter(moment().startOf('day').subtract(1, 'week'), 'days')
  ),
  [InvoicesGroups.PAST_EARLIER]: compareLastPaymentDate('deliveryEta', (date) =>
    moment(date).isSameOrBefore(moment().startOf('day').subtract(1, 'week'), 'days')
  ),
  [InvoicesGroups.REST]: () => true,
};

const groupsOrder = {
  [PaymentRequestTabs.UNSENT]: [
    InvoicesGroups.FAILED,
    InvoicesGroups.OVER_DUE,
    InvoicesGroups.DUE_THIS_WEEK,
    InvoicesGroups.DUE_THIS_MONTH,
    InvoicesGroups.DUE_LATER,
  ],
  [PaymentRequestTabs.SENT]: [
    InvoicesGroups.FAILED,
    InvoicesGroups.OVER_DUE,
    InvoicesGroups.DUE_THIS_WEEK,
    InvoicesGroups.DUE_THIS_MONTH,
    InvoicesGroups.DUE_LATER,
  ],
  [PaymentRequestTabs.SCHEDULED]: [
    InvoicesGroups.TODAY_SCHEDULED,
    InvoicesGroups.PAST_WEEK_SCHEDULED,
    InvoicesGroups.REST,
  ],
  [PaymentRequestTabs.PAID]: [InvoicesGroups.TODAY_PAID, InvoicesGroups.PAST_WEEK_PAID, InvoicesGroups.PAST_EARLIER],
};

const getGroupInvoicesByTab = (tab, invoices) =>
  groupBy(invoices, (invoice) =>
    groupsOrder[tab].reduce(
      (foundGroupName, groupName) => foundGroupName || (groupFunctions[groupName](invoice) && groupName),
      null
    )
  );

const convertPaymentToInvoice = (invoice: InvoiceType, payment: PaymentType) => ({
  ...invoice,
  totalAmount: payment.amount,
  id: serializePaymentId(invoice.id, payment.id),
});

export const getOrderedGroupedInvoicesByTab = (tab, invoices) => {
  const grouped = getGroupInvoicesByTab(tab, invoices);

  return groupsOrder[tab].reduce((acc, groupName) => {
    if (!grouped[groupName]) return acc;

    grouped[groupName] = grouped[groupName].reduce((acc, invoice) => {
      if (invoice.payments.length > 0) {
        const invoices = sortBy(invoice.payments, 'id').map((payment) => convertPaymentToInvoice(invoice, payment));

        return [...acc, ...invoices];
      }

      return [...acc, invoice];
    }, []);

    return [
      ...acc,
      {
        groupName: `list.group.invoice.${groupName}`,
        invoices: grouped[groupName],
      },
    ];
  }, []);
};
