import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Flex from 'src/core/ds/flex/Flex';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { VirtualCardType } from 'src/utils/types';
import { ContentWrapper } from '../../components/ContentWrapper';
import { ConvertedPaymentActivity } from '../../components/ConvertedPaymentActivity';
import { EDGE_CASES, eventType } from '../../consts';
import { useSuvcToAchAnalytics } from '../../hooks/useSuvcToAchAnalytics';
import { TokenValuesType } from '../../types';
import { getPaymentStatuses } from '../../utils';

type Props = {
  tokenValues: TokenValuesType;
  virtualCardState: { virtualCard: VirtualCardType };
};

export const InfoPage = ({ tokenValues, virtualCardState }: Props) => {
  const { paymentId } = tokenValues;
  const { pageEvent } = useSuvcToAchAnalytics(tokenValues);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { isPaymentVirtualCardCleared, isPaymentCompleted, isVirtualCardCanceled } = getPaymentStatuses(payment);

  const getPageEventName = () => {
    if (isVirtualCardCanceled) return EDGE_CASES.CANCELED;

    if (isPaymentVirtualCardCleared) return EDGE_CASES.CLEARED;

    if (isPaymentCompleted) return EDGE_CASES.DEPOSITED;

    return EDGE_CASES.REQUESTED;
  };

  useEffect(() => {
    if (payment) {
      pageEvent(eventType, getPageEventName());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment, pageEvent]);

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout isExperimentalView>
      <ContentWrapper>
        <Flex
          background="white"
          position="relative"
          alignItems="left"
          borderRadius="lg"
          padding={{ md: 10, base: '4rem 2rem' }}
          flexDirection="column"
        >
          <ConvertedPaymentActivity virtualCardState={virtualCardState} payment={payment} />
        </Flex>
      </ContentWrapper>
    </OutsideLayout>
  );
};
