import { generatePath, useHistory } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import locations from 'src/utils/locations';
import { CantFindBankPage } from './components/CantFindBankPage';

const eventPage = 'bank-add-cant-find';

type Props = {
  vendorId: string;
};

export const CantFindBankPageContainer = ({ vendorId }: Props) => {
  const { navigate, navigateToExitWithPreservedState } = usePreservedStateNavigator();
  const orgId = useOrgId();
  const history = useHistory();
  const [origin] = useLocationState('origin');
  const hideGoCancel = origin === DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS;

  const goNextFlow = () => {
    if (
      origin === DeliveryMethodOrigin.ONBOARDING ||
      origin === DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS ||
      origin === DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_REQUESTS_LIST ||
      origin === DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST
    ) {
      analytics.track(eventPage, 'delivery-method-cant-find-bank');
      history.push({
        pathname: generatePath(onboardingLocations.deliveryMethods.bank.create, {
          orgId,
          id: vendorId,
        }),
        state: {
          origin,
        },
      });
    } else {
      analytics.track(eventPage, 'funding-source-cant-find-bank');
      navigate(onboardingLocations.fundingSources.bank.manually);
    }
  };

  const goCancel = () => {
    analytics.track(eventPage, 'cancel');

    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState({ origin });
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  return <CantFindBankPage goNextFlow={goNextFlow} goCancel={hideGoCancel ? undefined : goCancel} />;
};
