import { createListSlice } from 'src/helpers/redux/restListSlice';
import {
  BatchItemType,
  BatchListResultType,
  GroupedBatchListResultType,
} from 'src/modules/regular-batch-payments/types';
import { mapGroupedPayloadToBatchBill, mapPayloadToBatchBill } from 'src/modules/regular-batch-payments/utils';
import { regularBatchPaymentsApi } from './api';
import { batchPaymentsListName, name } from './consts';

export const fetchBatchBillsListSlice = createListSlice<
  BatchListResultType | GroupedBatchListResultType,
  BatchItemType
>({
  storeName: name,
  api: ({ isBulkPaymentEnabled, ...params }) =>
    isBulkPaymentEnabled ? regularBatchPaymentsApi.groupedList(params) : regularBatchPaymentsApi.list(params),
  actionName: 'batchBillsList',
  listHashFunc: () => batchPaymentsListName,
  resultMapFunction: (state, currItem, newItem): BatchItemType =>
    newItem.isBulkPaymentEnabled
      ? mapGroupedPayloadToBatchBill(newItem as GroupedBatchListResultType, state.settings)
      : mapPayloadToBatchBill(newItem as BatchListResultType, state.settings),
});
