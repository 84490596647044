import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { useValidateBillForm } from 'src/hooks/bill/useValidateBillForm';
import { billsStore } from 'src/modules/bills/bills-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useBillVendorId } from 'src/pages/bill/hooks/useBillVendorId';
import { billLocations } from 'src/pages/bill/locations';
import { billFactory } from 'src/pages/bill/records';
import { JustPayForm } from 'src/pages/onboarding/just-pay/JustPayForm';
import { getJustPayBillModel } from 'src/pages/onboarding/just-pay/utils';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { CompanyFormPage } from 'src/utils/consts';
import locations from 'src/utils/locations';

const page = 'bills';

export const JustPayPage = () => {
  const orgId = useOrgId();
  const history = useHistory();
  const billActions = useStoreActions(billsStore);
  const isBillCreating = useSelector(billsStore.selectors.create.status())?.loading;

  const model = useMemo(() => getJustPayBillModel(), []);
  const isVendorCreating = useSelector(vendorsStore.selectors.create.status())?.loading;
  const {
    createNewVendor,
    filteredVendors,
    onVendorsInputChange,
    onVendorIdChange,
    getDirectoryVendorFromVendorOption,
  } = useBillVendorId();

  const { getBillValidationErrors } = useValidateBillForm(orgId, getDirectoryVendorFromVendorOption);

  const [justPayMV, { submit }] = useForm(model, {
    submit: async (value) => {
      let { vendorId } = value;
      const { totalAmount, dueDate, accountIdentifier } = value;
      const validationErrors = await getBillValidationErrors(value, ['vendorId', 'totalAmount']);

      if (!isEmpty(validationErrors)) {
        analytics.track('bill-create', 'form-validation-error', { validationErrors });
        throw new ValidationError({ validationErrors });
      }

      if (!!vendorId && vendorId < 0) {
        vendorId = await createNewVendor(Number(vendorId), accountIdentifier);
      }

      const billToCreate = billFactory({
        ...value,
        occurrences: value.occurrences ? Number(value.occurrences) : undefined,
        vendorId: Number(vendorId),
        balance: Number(totalAmount),
        dueDate: dueDate ? new Date(dueDate) : new Date(),
      });
      const { payload } = await billActions.create({ orgId, params: billToCreate });
      const billDetailsAdded = pick(value, ['invoiceNumber', 'note', 'dueDate']);
      analytics.track('bill-create', 'save-success', { billDetailsAdded });
      history.push(generatePath(billLocations.pay.funding, { orgId, billId: payload.id }));
    },
  });

  const navigateToDashboard = () => {
    analytics.track(page, 'go-to-dashboard');
    history.push(locations.MainApp.dashboard.url({ orgId }), { origin: CompanyFormPage.ONBOARDING });
  };

  const navigateCompanyAddedPage = () => {
    analytics.track(page, 'go-back');
    const url = generatePath(onboardingLocations.companyInfo.added, { orgId });
    history.push(url);
  };

  return (
    <StepLayoutPage goExit={navigateToDashboard} onPrev={navigateCompanyAddedPage} title="bills.justPay.title">
      <JustPayForm
        filteredVendors={filteredVendors}
        onVendorIdChange={onVendorIdChange}
        onVendorsInputChange={onVendorsInputChange}
        submit={submit}
        model={justPayMV}
        isLoading={isVendorCreating || isBillCreating}
      />
    </StepLayoutPage>
  );
};
