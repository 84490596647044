import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { CompanyType } from 'src/utils/consts';

export const useIsAccountingFirmSelected = () => {
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);

  const isAccountingFirmSelected = currentOrg?.companyType === CompanyType.ACCOUNTING_FIRM;

  return {
    isAccountingFirmSelected,
  };
};
