import { useEffect } from 'react';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { organizationBillingFeeApi } from 'src/modules/organization-billing-fee/api';
import { getBillingMethod } from 'src/pages/billing/utils';
import { OrganizationBillingFee } from 'src/utils/types';

export const useGetOrganizationBillingFees = () => {
  const currentOrgId = useOrgId();
  const { onApiCall: getOrganizationBillingFees, result, loading, error } = useApi<
    [{ orgId: number }],
    { organizationBillingFees: OrganizationBillingFee[] | [] }
  >({
    api: organizationBillingFeeApi.getBillingFees,
  });
  const currentBillingMethod = getBillingMethod(result?.organizationBillingFees);

  useEffect(() => {
    if (!currentOrgId) return;

    getOrganizationBillingFees({ orgId: currentOrgId });
  }, [currentOrgId, getOrganizationBillingFees]);

  return {
    loading,
    error,
    organizationBillingFees: result?.organizationBillingFees,
    currentOrganizationBillingMethod: currentBillingMethod,
    isMethodAssignedByFirm: Boolean(currentBillingMethod?.managedByOrganizationId),
    refetch: () => getOrganizationBillingFees({ orgId: currentOrgId }),
  };
};
