import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import Box from 'src/core/ds/box';
import Tooltip from 'src/core/ds/tooltip';
import { CompanyInfoType } from 'src/utils/types';
import { OrganizationBadgeItemContainer } from './components/OrganizationBadgeItemContainer';

const DEFAULT_MAX = 4;

type Props = {
  organizations: readonly CompanyInfoType[];
  max?: number;
};

export function OrganizationBadgeList({ organizations, max = DEFAULT_MAX }: Props) {
  const organizationsLeftAfterMax = organizations.slice(max);
  const leftOrganizationsNames = organizationsLeftAfterMax.map((org) => org.companyName);
  const leftOrganizationsNamesWithCommas = leftOrganizationsNames.join(', ');

  return (
    <Box position="relative" data-testid="org-badge-list">
      {organizations.slice(0, max).map((organization, index) => (
        <OrganizationBadgeItemContainer
          key={organization.id}
          left={index * 15}
          zIndex={max + 2 - index}
          apply="textStyles.caption1Uppercase"
          data-testid={`org-badge-wrapper-${organization.id}`}
        >
          <Tooltip placement="top" label={organization.companyName} textStyle="body4" shouldWrapChildren>
            <Avatar
              name={organization.companyName ?? ''}
              link={organization.logoUrl ?? ''}
              size={Size.xs}
              textColor="white"
            />
          </Tooltip>
        </OrganizationBadgeItemContainer>
      ))}
      {organizations.length > max && (
        <Tooltip
          placement="top"
          left={max * 15}
          label={leftOrganizationsNamesWithCommas}
          textStyle="body4"
          shouldWrapChildren
        >
          <OrganizationBadgeItemContainer
            color="grey.700"
            cursor="pointer"
            marginInlineStart={0.5}
            left={max * 15}
            zIndex={2}
            apply="textStyles.body4Semi"
          >
            <span>{`+${organizationsLeftAfterMax.length}`}</span>
          </OrganizationBadgeItemContainer>
        </Tooltip>
      )}
    </Box>
  );
}
