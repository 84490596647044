import usRegionAnimation from 'src/animations/accountingSoftware/sync-qbd/us-region.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { getQBDRelativeStep } from './utils';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const SyncQBDRegionPage = ({ onNext, onPrev, onExit }: Props) => (
  <StepLayoutPage
    title="settings.newAccountingSoftware.QuickbooksDesktopSync.region.title"
    subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.region.subtitle"
    nextLabel="progress.next"
    goExit={onExit}
    onNext={onNext}
    onPrev={onPrev}
    relativeStep={getQBDRelativeStep(4)}
    displaySupportLink
    animatedIllustration={{
      animationData: usRegionAnimation,
      loop: false,
    }}
  />
);

export default SyncQBDRegionPage;
