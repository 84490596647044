import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';

type Props = {
  id: string;
  label: string;
  error: boolean;
};

export const FieldTitle = ({ id, label, error }: Props) => (
  <Box
    id={id}
    as="span"
    height="1.7rem"
    fontSize="1.2rem"
    fontWeight={600}
    letterSpacing="0.02rem"
    lineHeight="1.8rem"
    display="block"
    textAlign="left"
    marginBottom="0.8rem"
    textTransform="capitalize"
    color={error ? 'red.500' : 'grey.600'}
  >
    <MIFormattedText label={label} />
  </Box>
);
