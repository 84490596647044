import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { onboardingLocations } from '../locations';
import { ClaimAccountRouter } from './claim-account/ClaimAccountRouter';
import { ConfirmBusinessDetails } from './public-form/components/ConfirmBusinessDetails';
import { DeliveryMethodStatusCheck } from './public-form/components/DeliveryMethodStatusCheck';
import { ExistingOrganizationsPage } from './public-form/components/existing-organizations/Page';
import { MsnVerificationStatusCheck } from './public-form/components/MsnVerificationStatusCheck';
import { MSNPortalRegisterPage } from './public-form/components/RegisterPage';
import { SelectBusinessTypePage } from './public-form/components/SelectBusinessTypePage';
import { VerifyBankAccountPage } from './public-form/components/verify-bank-account/Page';
import { VerifyBankAccountStatus } from './public-form/components/VerifyBankAccountStatus';
import { CodeVerificationPageContainer } from './public-form/containers/CodeVerificationPageContainer';
import { CompanyContactDetailsPage } from './public-form/containers/CompanyContactDetailsPage';
import { CompanyDetailsPage } from './public-form/containers/CompanyDetailsPage';
import { CompanyRejectedPage } from './public-form/containers/CompanyRejectedPage';
import { CompanyVerifiedPage } from './public-form/containers/CompanyVerifiedPage';
import { DeliveryMethodStatusPage } from './public-form/containers/delivery-method-status/Page';

export const MSNRegistrationRouter = (props) => (
  <Switch>
    <SmartRoute path={onboardingLocations.msnRegistration.register}>
      <MSNPortalRegisterPage />
    </SmartRoute>
    <SmartRoute path={onboardingLocations.msnRegistration.emailVerification}>
      <CodeVerificationPageContainer prevRoute={onboardingLocations.msnRegistration.register} />
    </SmartRoute>
    <SmartRoute path={onboardingLocations.msnRegistration.existingOrganizations}>
      <ExistingOrganizationsPage />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.msnVerificationStatusCheck}>
      <MsnVerificationStatusCheck />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.deliveryMethodStatusCheck}>
      <DeliveryMethodStatusCheck />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.contactDetails}>
      <CompanyContactDetailsPage
        {...props}
        companyInfoField="contactDetails"
        inputFields={['contactFirstName', 'contactLastName', 'phone']}
        nextStepURL={onboardingLocations.msnRegistration.companyDetails}
      />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.companyDetails}>
      <CompanyDetailsPage
        {...props}
        companyInfoField="companyDetails"
        inputFields={['companyName', 'googlePlaceId', 'zipCode']}
        prevStepURL={onboardingLocations.msnRegistration.contactDetails}
      />
    </SmartRoute>
    <SmartRoute path={onboardingLocations.msnRegistration.selectBusinessType}>
      <SelectBusinessTypePage />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.deliveryMethodStatus}>
      <DeliveryMethodStatusPage />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.companyVerified}>
      <CompanyVerifiedPage />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.companyRejected}>
      <CompanyRejectedPage />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.verifyBankAccount}>
      <VerifyBankAccountPage />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.verifyBankAccountStatus}>
      <VerifyBankAccountStatus />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.confirmDetails}>
      <ConfirmBusinessDetails />
    </SmartRoute>
    <SmartRoute path={onboardingLocations.msnRegistration.claimAccount.index}>
      <ClaimAccountRouter />
    </SmartRoute>
  </Switch>
);
