import noop from 'lodash/noop';
import { UseWizardArgs } from 'src/hooks/useWizard/useWizard';
import { Steps } from 'src/pages/bill/pay/installments/types';

export const learnMoreApplicationIsPending = 'https://melio.zendesk.com/hc/en-us/articles/6463790405660';
export const learnMoreInstallmentInMelio = 'https://melio.zendesk.com/hc/en-us/articles/6457841438108';
export const learnMoreListOfOwnersRequired = 'https://melio.zendesk.com/hc/en-us/articles/6605712405788';

export const stepToPageUrlSuffix: UseWizardArgs<Steps>['locationsMap'] = {
  ownerDecision: 'owner-decision',
  businessDetails: 'business-details',
  businessOwnershipDetails: 'business-ownership-details',
  reviewingDetails: 'reviewing-details',
  repaymentTerms: 'repayment-terms',
};

export const navigationMap = {
  ownerDecision: () => 'businessDetails' as const,
  businessDetails: () => 'businessOwnershipDetails' as const,
  businessOwnershipDetails: () => 'reviewingDetails' as const,
  reviewingDetails: () => 'repaymentTerms' as const,
  repaymentTerms: noop,
};
