import { ReactNode } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize, OldToNewIconNames } from 'src/core/ds/icon';
import { Input } from 'src/core/ds/input';
import { analytics } from 'src/services/analytics';

export type Option<TId extends string = string> = {
  id: TId;
  label: string;
  disabled?: boolean;
  icon?: string;
  hint?: string;
  tag?: string;
  info?: string;
};

type Props<TId extends string> = {
  onSelect: (id: TId) => void;
  categories: Option<TId>[];
  selectedId?: string | null;
  analyticsProps?: Record<string, any>;
};

type ListContainerProps = {
  children?: ReactNode;
};

type CategoryProps = {
  children?: ReactNode;
  disabled?: boolean;
};

type LabelProps = {
  children?: ReactNode;
  htmlFor?: string;
  selected?: boolean;
  disabled?: boolean;
  'data-testid'?: string;
  itemIcon?: string;
};

type HintContainerProps = {
  children?: ReactNode;
};

export const MICategoryList = <TId extends string>({
  categories,
  onSelect,
  selectedId,
  analyticsProps,
}: Props<TId>) => {
  const onSelectCategory = (id: TId) => {
    analytics.trackAction('select-category', {
      value: id,
      ...analyticsProps,
    });
    onSelect(id);
  };

  return (
    <ListContainer>
      {categories.map(({ id, label, icon, disabled, hint, tag, info }) => (
        <Label
          htmlFor={id}
          key={id}
          selected={id === selectedId}
          disabled={disabled}
          data-testid={`category-list-${id}`}
          itemIcon={icon}
        >
          {icon && (
            <Flex mb={1}>
              <Icon name={OldToNewIconNames[icon]} size={IconSize.lg} color={disabled ? 'grey.600' : 'black'} />
            </Flex>
          )}
          <Category disabled={disabled}>
            <Input type="radio" id={id} onClick={() => onSelectCategory(id)} disabled={disabled} display="none" />
            <MIFormattedText label={label} />
            {hint && (
              <HintContainer>
                <MIFormattedText label={hint} />
              </HintContainer>
            )}
            {tag && (
              <Box pt={1}>
                <Badge label={tag} variant={BadgeVariants.FILLED} color={BadgeColors.PRIMARY} />
              </Box>
            )}
            {info && (
              <Flex pt={1} alignItems="center" w="full" justify="center">
                <Box pl={1} textStyle="body4" color="grey.700">
                  <MIFormattedText label={info} />
                </Box>
                <Box ml={2} mt={1}>
                  <Icon name={IconNames.infoCircle} size={IconSize.s} />
                </Box>
              </Flex>
            )}
          </Category>
        </Label>
      ))}
    </ListContainer>
  );
};

const ListContainer = ({ children }: ListContainerProps) => (
  <Flex flexWrap="wrap" justify={{ base: 'space-between', md: 'center' }}>
    {children}
  </Flex>
);

const Category = ({ children, disabled }: CategoryProps) => (
  <Box
    letterSpacing={0}
    color={disabled ? 'grey.600' : 'black'}
    textStyle={{ base: 'body4Semi', md: 'body3Semi' }}
    boxSizing="border-box"
    whiteSpace="nowrap"
    overflow="hidden"
    textOverflow="ellipsis"
    textAlign="center"
  >
    {children}
  </Box>
);

const Label = ({ children, disabled, itemIcon, selected, ...rest }: LabelProps) => (
  <Box
    as="label"
    {...rest}
    display="flex"
    justifyContent="center"
    flexDir={itemIcon ? 'column' : undefined}
    alignItems={itemIcon ? 'center' : undefined}
    px={{ base: itemIcon ? 6 : 2, md: 8 }}
    py={{ base: itemIcon ? 6 : 2, md: itemIcon ? 6 : 5 }}
    cursor="pointer"
    color="grey.700"
    border="2px"
    borderColor={selected ? 'primary.500' : 'transparent'}
    borderRadius="lg"
    boxSizing="border-box"
    boxShadow={400}
    w={{ base: '48%', md: '21.5rem' }}
    maxW={{ base: '48%', md: '21.5rem' }}
    bgColor="white"
    mb={5}
    transition="300ms"
    _even={{
      ml: { base: 0, md: 4 },
    }}
    _hover={{
      boxShadow: !selected && !disabled ? 600 : 400,
    }}
  >
    {children}
  </Box>
);

const HintContainer = ({ children }: HintContainerProps) => (
  <Box color="grey.700" textStyle="body4" whiteSpace={{ base: 'normal', sm: undefined }}>
    {children}
  </Box>
);
