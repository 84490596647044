import React from 'react';
import { ProgressBarElements } from 'src/components/payment-progress-bar/ProgressBarElements';
import { ProgressBarLabels } from 'src/components/payment-progress-bar/ProgressBarLabels';
import Box from 'src/core/ds/box';

export type StatusInfo = {
  label: string;
  date?: string;
  comment?: string;
  icon?: React.ReactNode;
};

type Props = {
  paymentProcess: StatusInfo[];
  currentStage: number;
};

export const ProgressBar = ({ paymentProcess, currentStage }: Props): JSX.Element => (
  <Box
    justifyContent="center"
    py={10}
    px={{ base: 0, md: 3, lg: 10 }}
    borderBottom="1px"
    borderColor="grey.400"
    data-testid="payment-progress-bar"
  >
    <ProgressBarElements paymentProcess={paymentProcess} paymentCurrentStageIndex={currentStage} />
    <ProgressBarLabels paymentProcess={paymentProcess} paymentCurrentStageIndex={currentStage} />
  </Box>
);
