import { useSelector } from 'react-redux';
import { generatePath, Redirect, Switch } from 'react-router-dom';
import { AppLayout } from 'src/components/layout/AppLayout';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { getOrgId } from 'src/redux/user/selectors';
import { GetProPage } from './GetProPage';
import { getProLocations } from './locations';

export const GetProRouter = () => {
  const orgId = useSelector(getOrgId);
  const isMsnDashboardDisplay = useIsMsnDashboardDisplay();

  if (isMsnDashboardDisplay) {
    return <Redirect to={{ pathname: generatePath(msnPortalLocations.base, { orgId }) }} />;
  }

  return (
    <AppLayout>
      <Switch>
        <SmartRoute path={getProLocations.base}>
          <GetProPage />
        </SmartRoute>
      </Switch>
    </AppLayout>
  );
};
