import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { withNavigator } from 'src/hoc';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { authLocations } from 'src/pages/auth/locations';
import { billLocations } from 'src/pages/bill/locations';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { isCompanyInfoDone } from 'src/pages/settings/records';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { getCompanyInfo, getIsLoggedIn, getOrgId, getOwnedVendorId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { Site } from 'src/sites/site';
import { getBillsDefaultFilters, getDefaultMemo } from 'src/utils/bills';
import { BillStatus, CompanyType } from 'src/utils/consts';
import locations from 'src/utils/locations';
import GuestPaymentRequestInfoPage from './components/GuestPaymentRequestInfoPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type Props = {
  intl: IntlShape;
  site: Site;
} & GuestDataProps;

const eventPage = 'payor';
const eventName = 'payment-request-info';

const GuestPaymentRequestInfoPageContainer = (props: Props) => {
  const {
    companyName,
    contactName,
    paymentRequest,
    coupons,
    isLoading,
    logoUrl,
    showMelioMeCard,
    vendorId,
    filePreviewUrls,
    fileStorageUrl,
    overrides,
    paymentRequestStatus,
    isPayorGuest,
    blockCCMoneyIn,
    promotionName,
    memo,
    navigateToGuestPage,
    invoiceNumber,
    site,
    link,
    intuitAcctNum,
  } = props;
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState({});
  const orgId = useSelector(getOrgId);
  const { navigate } = useNavigator();
  const companyInfo = useSelector(getCompanyInfo);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const profile = useSelector(getProfile);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const isMultiOrg = organizations && organizations.length > 1;
  const isAccountingOrg =
    isMultiOrg && organizations.find((org) => org?.id === orgId)?.companyType === CompanyType.ACCOUNTING_FIRM;
  const organizationsActions = useStoreActions(organizationStore);
  const orgAssignedToPaymentRequest = useSelector(
    organizationStore.selectors.getOrgAssignedToPaymentRequest.orgAssignedToPaymentRequest
  ) as number | undefined;
  const skipSelectOrgStep = profile.isGuest || (organizations && organizations.length === 1);

  const initUserContext = useCallback(
    async (orgId: number) =>
      new Promise((resolve, reject) => {
        dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
      }),
    [dispatch]
  );

  const onPageLoad = useCallback(async () => {
    if (isLoggedIn && !profile.isGuest && vendorId && profile.organizations.length > 1) {
      const { customerEmail, customerName } = paymentRequest;

      if (customerEmail) {
        const params = {
          vendorId,
          contactEmail: customerEmail,
          companyName: customerName,
        };
        const { payload } = await organizationsActions.getOrgAssignedToPaymentRequest(params);
        const { organizationId } = payload;

        initUserContext(organizationId);
      }
    }
  }, [
    isLoggedIn,
    profile.isGuest,
    profile.organizations.length,
    vendorId,
    paymentRequest,
    organizationsActions,
    initUserContext,
  ]);

  useEffect(() => {
    onPageLoad();
  }, [onPageLoad]);

  useEffect(() => {
    if (!showMelioMeCard) {
      analytics.track('payor', 'vendor-not-found');
    }
  }, [showMelioMeCard]);

  const onNext = () => {
    const isNextDisabled = vendorId === ownedVendorId;

    if (isNextDisabled && !isAccountingOrg) {
      setValidationErrors({
        totalAmount: 'inputErrors.guest.totalAmount.custom.nopay',
      });
    } else {
      analytics.track(eventPage, `${eventName}-continue-success`);
      const data: Record<string, unknown> = {
        isAccountingOrg,
      };

      if (isNil(memo)) {
        data.memo = getDefaultMemo(invoiceNumber, intuitAcctNum);
      }

      if (isLoggedIn) {
        if (isCompanyInfoDone(companyInfo)) {
          if (skipSelectOrgStep) {
            navigateToGuestPage(
              generatePath(melioMeLocations.wizard.editFundingSource, { link }),
              data,
              !site.embeddedMode
            );
          } else if (orgAssignedToPaymentRequest) {
            navigateToGuestPage(generatePath(melioMeLocations.wizard.editFundingSource, { link }), {}, false, true);
          } else {
            navigateToGuestPage(generatePath(melioMeLocations.wizard.selectOrg, { link }), data, !site.embeddedMode);
          }
        } else if (profile.isGuest) {
          navigateToGuestPage(generatePath(melioMeLocations.wizard.infoDetails, { link }), data, !site.embeddedMode);
        } else {
          navigate(onboardingLocations.companyInfo.type, false, undefined, false, site.embeddedMode);
        }
      } else {
        navigateToGuestPage(generatePath(melioMeLocations.wizard.signUp, { link }), data, !site.embeddedMode);
      }
    }
  };

  const onSignUpClick = () => navigate(authLocations.register.index, false, undefined, false, site.embeddedMode);

  const goDashboard = () => navigate(locations.MainApp.dashboard.url(), false, undefined, false, site.embeddedMode);

  const goToAccount = () => {
    if (paymentRequest.organizationId !== orgId) {
      goDashboard();

      return;
    }

    let status;

    if (paymentRequestStatus.failed) {
      status = BillStatus.UNPAID;
    } else if (paymentRequestStatus.delivered) {
      status = BillStatus.PAID;
    } else {
      status = BillStatus.SCHEDULED;
    }

    const defaultFilters = getBillsDefaultFilters(status);
    navigate(
      generatePath(billLocations.filteredView, {
        orgId,
        id: paymentRequest.billId,
        ...defaultFilters,
      }),
      false,
      undefined,
      false,
      site.embeddedMode
    );
  };

  // logic explanation: when real user logged in go to dashboard,
  // when guest user logged in or the payor of the request is guest, take him to register
  const isActionRegister = (!isLoggedIn || profile.isGuest) && isPayorGuest;
  const invoiceName = paymentRequest.files && paymentRequest.files.length > 0 ? paymentRequest.files[0].fileName : '';

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <GuestPaymentRequestInfoPage
      logoUrl={logoUrl}
      companyName={companyName}
      contactName={contactName}
      totalAmount={paymentRequest.totalAmount}
      invoiceNumber={paymentRequest.invoiceNumber}
      onNext={onNext}
      dueDate={paymentRequest.dueDate}
      note={paymentRequest.customerNote}
      isNextDisabled={vendorId === ownedVendorId}
      goDashboard={goDashboard}
      onSignUpClick={onSignUpClick}
      showMelioMeCard={showMelioMeCard}
      validationErrors={validationErrors}
      filePreviewUrls={filePreviewUrls}
      fileStorageUrl={fileStorageUrl}
      invoiceName={invoiceName}
      paymentRequestStatus={paymentRequestStatus}
      isActionRegister={isActionRegister}
      goToAccount={goToAccount}
      blockCCMoneyIn={blockCCMoneyIn}
      promotionName={promotionName}
      payeeEmail={paymentRequest?.vendor?.contactEmail}
      coupons={coupons}
      overrides={overrides}
      paymentRequest={paymentRequest}
    />
  );
};

export default compose(
  withNavigator(),
  withGuestData(),
  withSiteContext()
)(injectIntl(GuestPaymentRequestInfoPageContainer));
