import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { FailedBillsList } from 'src/pages/regular-batch-payments/components/wizard/BatchPaymentsSuccess/FailedBillsListModal/FailedBillsList';
import { DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  dismiss: () => void;
};

export const FailedBillsListModal = ({ dismiss }: Props) => (
  <Dialog
    type={DialogType.CONFIRM}
    variant={DialogVariants.ERROR}
    title="regularBatchPayments.failedBillsList.title"
    subtitle="regularBatchPayments.failedBillsList.subtitle"
    onCancelAction={dismiss}
    showCancel={false}
  >
    <Box w="100%" mt={2}>
      <FailedBillsList />
      <Flex justify="flex-end" mt={50}>
        <Button
          textTransform="uppercase"
          variant={ButtonVariants.tertiaryNaked}
          onClick={dismiss}
          label="dialogs.buttons.close"
        />
      </Flex>
    </Box>
  </Dialog>
);
