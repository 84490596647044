import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { vendorsApi } from 'src/modules/vendors/api';
import { getOrgId } from 'src/redux/user/selectors';
import { BillType } from 'src/utils/types';

type Params = {
  bill: BillType;
};

export const useCheckOwnedVendorExists = ({ bill }: Params) => {
  const orgId = useSelector(getOrgId);

  const {
    onApiCall: getOwnedVendorExistsRequest,
    result: isOwnedVendorExists,
    loading: isGettingOwnedVendorData,
  } = useApi<Parameters<typeof vendorsApi['getOwnedVendorExists']>, boolean>({
    api: vendorsApi.getOwnedVendorExists,
    throwError: false,
    initialLoading: true,
  });

  const getOwnedVendor = useCallback(async () => {
    if (bill.vendor?.id) {
      await getOwnedVendorExistsRequest({
        orgId,
        id: bill.vendor?.id,
      });
    }
  }, [bill.vendor, getOwnedVendorExistsRequest, orgId]);

  useEffect(() => {
    const { vendor } = bill;

    if (vendor) {
      getOwnedVendor();
    }
  }, [bill, getOwnedVendor]);

  const getOwnedVendorExists = () => {
    if (!bill?.vendor?.id) {
      return false;
    }

    return vendorsApi.getOwnedVendorExists({
      orgId,
      id: bill?.vendor?.id,
    });
  };

  return {
    isOwnedVendorExists,
    isGettingOwnedVendorData,
    getOwnedVendorExists,
  };
};
