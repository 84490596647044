import * as React from 'react';
import styled from 'styled-components';
import billPlaceholderImage from 'src/images/dashboard/right-side-empty-noshadow.svg';
import { devices } from 'src/theme/appDevices';

type Props = {
  isLoading?: boolean;
};

export const RightPanelPlaceholder: React.FC<Props> = ({ isLoading }) => {
  if (isLoading) {
    return null;
  }

  return <Placeholder />;
};

const Placeholder = styled.div`
  background: url(${billPlaceholderImage}) repeat-y;
  width: 65rem;
  margin-top: 2rem;
  margin-left: -1rem;
  height: 89rem;
  flex: 1 4 auto;
  background-repeat: no-repeat;

  @media ${devices.mobile}, ${devices.phablet} {
    display: none;
  }
`;
