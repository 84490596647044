import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { accountingFirmsApi } from 'src/modules/accounting-firms/api';
import { fundingSourcesApi } from 'src/modules/funding-sources/api';
import { profileStore } from 'src/modules/profile/profile-store';
import { getBillingMethod } from 'src/pages/billing/utils';
import { AccountType, OrganizationBillingFee } from 'src/utils/types';

export const useGetFirmBillingFees = () => {
  const { id: firmId } = useSelector(profileStore.selectors.getFirstAccountingFirm) || {};
  const {
    onApiCall: getFirmBillingFees,
    result: billingFeeRes,
    loading: billingFeeLoading,
    error: billingFeeError,
  } = useApi<
    [{ orgId: number }],
    { firmBillingFees: OrganizationBillingFee[]; firmClientsBillingFees: Record<string, boolean> }
  >({
    api: accountingFirmsApi.getBillingFees,
  });
  const { onApiCall: getFirmFundingSources, result: fundingSourcesRes, loading: firmFundingSourceLoading } = useApi<
    [{ orgId: number }],
    { fundingSources: AccountType[] }
  >({
    api: fundingSourcesApi.list,
  });

  const firmBillingMethod = getBillingMethod(billingFeeRes?.firmBillingFees);
  const firmFundingSourceId = firmBillingMethod?.fundingSourceId;
  const firmFundingSource = fundingSourcesRes?.fundingSources?.find(({ id }) => id === firmFundingSourceId);

  useEffect(() => {
    if (!firmId) return;

    getFirmBillingFees({ orgId: firmId });
  }, [firmId, getFirmBillingFees]);

  useEffect(() => {
    if (!firmId) return;

    getFirmFundingSources({ orgId: firmId });
  }, [firmId, getFirmFundingSources]);

  return {
    loading: billingFeeLoading || firmFundingSourceLoading,
    error: billingFeeError,
    firmBillingFees: billingFeeRes?.firmBillingFees,
    assignedClients: billingFeeRes?.firmClientsBillingFees,
    firmFundingSource,
    currentFirmBillingMethod: firmBillingMethod,
    firmId,
    refetch: () => firmId && getFirmBillingFees({ orgId: firmId }),
  };
};
