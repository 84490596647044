import netsuiteLogo from 'src/images/accounting-software/cards-logo/netsuite_logo.svg';
import qboLogo from 'src/images/accounting-software/cards-logo/qbo_logo.svg';
import rightNetworksLogo from 'src/images/accounting-software/cards-logo/rightnetworks_qbdt_logo.svg';
import sageIntacctLogo from 'src/images/accounting-software/cards-logo/sage_logo.svg';
import zohoLogo from 'src/images/accounting-software/cards-logo/zoho_logo.png';
import { AccountingSoftwareOption } from 'src/pages/get-paid/create/connectAccountingSoftware/types';
import { AccountingSoftware } from 'src/utils/consts';

export const NUM_OF_DISPLAYED_CARDS = 2;

export const supportedAccountingSoftwareOptions: AccountingSoftwareOption[] = [
  {
    accountingSoftware: AccountingSoftware.QUICKBOOKS,
    logo: qboLogo,
  },
  // We removed the QBDT option from the supportedAccountingSoftwareOptions array
  // We should restore it once we change handleConnectQBD in ConnectAccountingSoftwarePage.tsx to point to the correct qbdt entry location in platform.
  // {
  //   accountingSoftware: AccountingSoftware.QUICKBOOKS_DESKTOP,
  //   logo: qbdtLogo,
  // },
];

export const unsupportedAccountingSoftwareOptions: AccountingSoftwareOption[] = [
  {
    accountingSoftware: AccountingSoftware.ORACLE_NETSUITE,
    logo: netsuiteLogo,
    isSupportedSoon: true,
    width: 120,
  },
  {
    accountingSoftware: AccountingSoftware.RIGHT_NETWORKS,
    logo: rightNetworksLogo,
    isSupportedSoon: true,
  },
  {
    accountingSoftware: AccountingSoftware.SAGE_INTACCT,
    logo: sageIntacctLogo,
    isSupportedSoon: true,
  },
  {
    accountingSoftware: AccountingSoftware.ZOHO_BOOKS,
    logo: zohoLogo,
    isSupportedSoon: true,
  },
  {
    accountingSoftware: AccountingSoftware.KASHOO,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.MICROSOFT_DYNAMICS,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.QUICKBOOKS_FOR_MAC,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.QUICKEN,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.SAGE,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.SAGE_50,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.SAP,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.THOMSON,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.WAVE,
    isSupportedSoon: false,
  },
  {
    accountingSoftware: AccountingSoftware.YARDI,
    isSupportedSoon: false,
  },
];
