import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import organizationStore from 'src/modules/organizations/organizations-store';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import { masking } from 'src/utils/masking';

type Props = {
  onNext: () => void;
  onPrev: () => void;
};

export const QuickbookMultiOrgAddAccountingFirmDetails = ({ onNext, onPrev }: Props) => {
  const dispatch = useDispatch();
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);

  const model = useMemo(
    () => ({
      contactFirstName: newCompanyInfo.contactFirstName || '',
      contactLastName: newCompanyInfo.contactLastName || '',
      phone: newCompanyInfo.phone || '',
    }),
    [newCompanyInfo]
  );

  const [companyInfoMV, { submit }] = useForm(model, {
    submit: async (value) => {
      const validationErrors = getValidationErrors('companyInfo', value);

      if (!isEmpty(validationErrors)) {
        analytics.trackAction('form-validation-error', { validationErrors });
        throw new ValidationError({ validationErrors });
      }

      dispatch(
        organizationStore.actions.updateNewCompanySlice.update({
          ...newCompanyInfo,
          ...value,
        })
      );
      onNext();
    },
  });

  return (
    <StepLayoutPage title="multiOrgOnboarding.details.title" relativeStep={1 / 5} onNext={submit} onPrev={onPrev}>
      <WizardFormContainer>
        <RowContainer>
          <WizardTextInputField
            model={companyInfoMV.contactFirstName}
            label="multiOrgOnboarding.details.firstNameLabel"
            required
            autoFocus
          />
          <WizardTextInputField
            model={companyInfoMV.contactLastName}
            label="multiOrgOnboarding.details.lastNameLabel"
            required
          />
        </RowContainer>
        <WizardTextInputField
          model={companyInfoMV.phone}
          label="multiOrgOnboarding.details.phoneLabel"
          required
          type="tel"
          mask={masking.USA_PHONE}
        />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};

const RowContainer = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 2rem;
  }
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;
