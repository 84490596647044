import { PureComponent, SyntheticEvent } from 'react';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { MaskField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';

type Props = {
  onPrev: () => void;
  onNext: () => void;
  onChange: (phone: string) => void;
  phone: string;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
};

export class VendorCompanyPhoneForm extends PureComponent<Props> {
  static defaultProps = {
    isLoading: false,
    validationErrors: {},
  };

  handleChange = ({ event }: { event: SyntheticEvent<HTMLInputElement> }) => {
    const { value } = event.currentTarget;
    this.props.onChange(value);
  };

  render() {
    const { phone, onNext, onPrev, isLoading, validationErrors } = this.props;

    return (
      <StepLayoutPage
        title="onboarding.vendorCompanyInfo.phone.title"
        onPrev={onPrev}
        onNext={onNext}
        isLoading={isLoading}
        relativeStep={3 / 10}
      >
        <MaskField
          id="phoneInput"
          label="onboarding.vendorCompanyInfo.phone.inputTitle"
          helperText="onboarding.vendorCompanyInfo.phone.inputHint"
          value={phone}
          type="tel"
          format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
          onSubmit={onNext}
          onChange={this.handleChange}
          errorMessage={validationErrors.phone}
          autoFocus
          isRequired
        />
      </StepLayoutPage>
    );
  }
}
