import styled from 'styled-components';
import { EmailLink } from 'src/components/common/EmailLink';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { useSiteContext } from 'src/hoc/withSiteContext';
import canceledImage from 'src/images/outside/canceled.png';
import invalidImage from 'src/images/outside/invalid.png';
import { devices } from 'src/theme/appDevices';

type Props = {
  companyName?: string;
  email?: string;
};

const GuestInvalidPaymentRequest = ({ companyName, email }: Props) => {
  const site = useSiteContext();

  const icon = companyName ? canceledImage : invalidImage;
  const label = companyName ? 'guests.notFound.cancelled' : 'guests.notFound.invalid';

  return (
    <GuestInvalidPaymentRequestContainer data-testid="guest-invalid-payment-request-container">
      <ErrorImage src={icon} alt="error" />
      <Subtitle>
        <MIFormattedText
          label={label}
          values={{
            companyName,
            email: email ? <EmailLink email={email} /> : <EmailLink email={site.config.support.email} />,
            supportEmail: <EmailLink email={site.config.support.email} />,
          }}
        />
      </Subtitle>
    </GuestInvalidPaymentRequestContainer>
  );
};

export default GuestInvalidPaymentRequest;

const GuestInvalidPaymentRequestContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 0rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0rem 2rem;
  }
`;

const Subtitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: 2.4rem;
  padding-bottom: 2rem;
`;

const ErrorImage = styled.img`
  width: 13rem;
`;
