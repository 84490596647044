import {
  ComponentWithAs,
  forwardRef,
  MenuItem as ChakraMenuItem,
  MenuItemProps,
  useStyleConfig,
} from '@chakra-ui/react';
import * as React from 'react';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { AnalyticsPropsType } from 'src/core/ds/types';
import { analytics } from 'src/services/analytics';

type Props = Omit<MenuItemProps, 'colorScheme' | 'variant' | 'size'> &
  AnalyticsPropsType & {
    label?: string;
    values?: Record<string, any>;
    testId?: string | null;
  };

const MenuItem: ComponentWithAs<'button', Props> = forwardRef(
  (
    {
      label,
      values,
      onClick,
      testId,
      children,
      analyticsProperties,
      skipAnalyticsEvent,
      isDisabled,
      'aria-label': passedAriaLabel,
      ...rest
    },
    ref
  ) => {
    const styles = useStyleConfig('MenuItem');
    const dataTestId = testId || `button-${label}`;

    const intl = useIntl();
    const ariaLabel = passedAriaLabel && intl.formatMessage({ id: passedAriaLabel });

    const onClickWithEvent = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!isDisabled) {
          const trackedLabel = label || passedAriaLabel;

          if (!skipAnalyticsEvent && trackedLabel) {
            analytics.trackAction(trackedLabel, analyticsProperties);
          }

          return onClick && onClick(event);
        }

        return undefined;
      },
      [isDisabled, label, analyticsProperties, onClick]
    );

    return (
      <ChakraMenuItem
        sx={styles}
        ref={ref}
        onClick={onClickWithEvent}
        isDisabled={isDisabled}
        data-testid={dataTestId}
        {...rest}
        aria-label={ariaLabel}
      >
        {label ? <MIFormattedText label={label} values={values} /> : children}
      </ChakraMenuItem>
    );
  }
);

export { MenuItem };
