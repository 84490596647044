import { microDepositLocations as microDepositVerification } from 'src/pages/micro-deposit-veification/locations';
import { authLocations as auth } from './auth/locations';
import { billLocations as bill } from './bill/locations';
import { billingLocations as billingFee } from './billing/locations';
import { companiesLocations as companies } from './companies/locations';
import { contactsLocations as contacts } from './contacts/locations';
import { dashboardLocations as dashboard } from './dashboard/locations';
import { getPaidLocations as getPaid } from './get-paid/locations';
import { getProLocations as getPro } from './get-pro/locations';
import { melioMeLocations as melioMe } from './meliome/locations';
import { msnPortalLocations as msnPortal } from './msn-portal';
import { onboardingLocations as onboarding } from './onboarding/locations';
import { orgLocations as orgs } from './orgs/locations';
import { quickbookMultiOrgOnboardingLocations as quickbookMultiOrgOnboarding } from './quickbook-multi-org-onboarding/locations';
import { receivingMethodLocations as receivingMethod } from './receiving-method/locations';
import { batchPaymentsLocations as regularBatchPayments } from './regular-batch-payments/locations';
import { approvalWorkflowsLocations as approvalWorkflows } from './settings/components/ApprovalWorkflows/locations';
import { settingsLocations as settings } from './settings/locations';
import { spendManagementLocations as spendManagement } from './spend-management/locations';
import { teamLocations as team } from './team/locations';
import { vendorLocations as vendor } from './vendor/locations';

export const globalLocations = {
  approvalWorkflows,
  auth,
  dashboard,
  bill,
  vendor,
  melioMe,
  contacts,
  orgs,
  companies,
  getPaid,
  getPro,
  onboarding,
  microDepositVerification,
  quickbookMultiOrgOnboarding,
  receivingMethod,
  regularBatchPayments,
  settings,
  msnPortal,
  team,
  billingFee,
  spendManagement,
};
