import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard, MINotificationCardProps } from 'src/components/common/MINotificationCard';
import { Avatar, Consts } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Separator } from 'src/core/ds/separator';
import { VStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import { analytics } from 'src/services/analytics';
import { DirectoryVendorLogoStyle } from './consts';
import { BusinessContactInfo } from './types';
import { getOrderedBusinessContactInfo } from './utils';

type Props = {
  businessName: string;
  businessAddress: string;
  businessContactInfo: BusinessContactInfo;
  buttonLabel?: string;
  isLoading?: boolean;
  onClick?: () => void;
  notification?: MINotificationCardProps;
};
export const VerifiedVendorDetails = ({
  businessName,
  businessAddress,
  businessContactInfo,
  onClick,
  buttonLabel,
  isLoading,
  notification,
}: Props) => {
  const orderedBusinessContactInfo = getOrderedBusinessContactInfo(businessContactInfo);

  const intl = useIntl();
  const deliveryMethodDefaultValue = intl.formatMessage({
    id: 'bills.form.verifiedVendorDetailsInfo.deliveryMethodDefaultValue',
  });

  useEffect(() => {
    if (businessName) {
      analytics.track('verified-vendor-details-modal', 'vendor-details-exposed', {
        businessName,
        businessAddress,
        businessContactInfo,
      });
    }
  }, [businessName, businessAddress, businessContactInfo]);

  return (
    <VStack spacing={5}>
      <Box
        border="1px"
        w="auto"
        borderRadius="lg"
        borderColor="grey.400"
        bg="white"
        data-testId="verified-vendor-details-container"
      >
        <Flex alignItems="center" position="relative" p={6}>
          <Avatar name={businessName} size={Consts.Size.xl} bg="grey.600" textColor="white" mr={4} />
          {buttonLabel ? (
            <Icon
              sx={DirectoryVendorLogoStyle}
              size={IconSize.xs}
              name={IconNames.verifiedSolid}
              color="primary.500"
              data-testid="directory-vendor-sign"
              background="white"
            />
          ) : null}

          <VStack spacing={1} flex={1} align="flex-start">
            <Box color="black" textStyle="body2Semi" overflow="hidden" wordBreak="break-word">
              {businessName ?? ''}
            </Box>
            {businessAddress && (
              <Box color="grey.700" textStyle="body4" overflow="hidden" wordBreak="break-word">
                {businessAddress ?? ''}
              </Box>
            )}
          </VStack>
          {buttonLabel ? (
            <Button
              label={buttonLabel}
              onClick={onClick}
              variant={ButtonVariants.tertiary}
              size={ButtonSizes.sm}
              isLoading={isLoading}
            />
          ) : (
            <Tooltip
              placement="top"
              label={<MIFormattedText label="bills.form.verifiedVendorDetailsInfo.melioDirectoryIndicationTooltip" />}
            >
              <Icon
                name={IconNames.verifiedSolid}
                pl={4}
                ml="auto"
                flexShrink={0}
                color="primary.500"
                size={IconSize.lg}
                data-testid="directory-vendor-sign"
              />
            </Tooltip>
          )}
        </Flex>
        <Separator m={0} color="grey.400" />
        <Flex pl={6} pt={6} pb={1} flexWrap="wrap">
          {orderedBusinessContactInfo?.map(({ key, value }) => (
            <Box w={{ base: 'full', md: '50%' }} mb={5} key={key}>
              <TextField
                mr={6}
                key={key}
                label={`bills.form.verifiedVendorDetailsInfo.${key}`}
                value={key === 'deliveryMethodLabel' ? deliveryMethodDefaultValue : value}
                isViewOnly
                isRequired
                isTruncated
                size="sm"
                testId={`verified-vendor-details-${key}`}
                title={value}
                _hover={{ text: value || '' }}
              />
            </Box>
          ))}
        </Flex>
      </Box>
      {notification ? <MINotificationCard {...notification} /> : null}
    </VStack>
  );
};
