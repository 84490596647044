import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { AnalyticPropsInState, category } from 'src/pages/vendor/components/InvalidTokenPage';
import { useForm } from 'src/ui/form';
import { DeliveryType, PaymentDeliverStatus, PaymentStatus } from 'src/utils/consts';
import {
  CheckType,
  DeliveryMethodType,
  FetchFileResultType,
  PaymentFeeItem,
  PaymentType,
  VendorType,
} from 'src/utils/types';
import { shiftToDebitLocations } from '../locations';
import { extractFirstAndLastNameFromFullName } from '../utils';

export type ShiftToDebitState = {
  payment: PaymentType;
  organization: any;
  filesUrls: FetchFileResultType;
  isPaymentLoading: boolean;
  deliveryMethod: DeliveryMethodType;
  vendor: VendorType;
  fee: PaymentFeeItem;
};

export type ShiftToDebitDeliveryMethodType = {
  cardToken?: string;
  expiration?: string;
  digits?: string;
  cardBin?: string;
  deliveryType?: DeliveryType;
  cardAccount: CheckType;
};

export type ShiftToDebitLocationState = AnalyticPropsInState;

export type ShiftToDebitDeliveryMethodActionsType = {
  validateAddress: (address) => void;
};

const onValidateAddress = async (
  state: ShiftToDebitState,
  actions: ShiftToDebitDeliveryMethodActionsType,
  value: ShiftToDebitDeliveryMethodType
) => {
  const { payment } = state;
  const { firstName, lastName } = extractFirstAndLastNameFromFullName(value.cardAccount.printName);
  const printNameParts = [firstName, lastName].filter((name) => !!name);
  const cardAccount = {
    ...value.cardAccount,
    deliveryType: DeliveryType.CARD,
    deliveryMethodId: payment.deliveryMethodId,
    printName: printNameParts,
  };
  await actions.validateAddress(cardAccount);
};

export function useShiftToDebit(token: string) {
  const history = useHistory();
  const paymentActions = useStoreActions(paymentsStore);

  useShiftToDebitRedirects(token);
  const redirectToErrorPage = () =>
    history.push(generatePath(shiftToDebitLocations.invalidToken, { token }), {
      analyticsProps: { category: category.push2debit },
    } as AnalyticPropsInState);

  const fetchPaymentDetailsWithToken = useCallback(async () => {
    try {
      await paymentActions.fetchPaymentDetailsWithToken({
        token,
        action: 'addDebitCardToVendor',
      });
    } catch (err) {
      console.error(err);
      redirectToErrorPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentActions, token, history]);

  useEffect(() => {
    fetchPaymentDetailsWithToken();
  }, [fetchPaymentDetailsWithToken]);

  const shiftToDebitState = useShiftToDebitState(token);

  return shiftToDebitState;
}

function useShiftToDebitState(token) {
  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const currentDeliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id));
  const { filesUrls } = useStructuredSelectors(paymentsStore.selectors.payment(payment?.id));
  const { fee } = useStructuredSelectors(paymentsStore.selectors.payment(payment?.id));
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const { vendor } = useFetchVendor(payment?.vendorId);

  const deliveryMethodModel: ShiftToDebitDeliveryMethodType = useMemo(
    () => ({
      deliveryType: DeliveryType.CARD,
      cardAccount: {
        addressLine1: '',
        addressLine2: '',
        state: '',
        city: '',
        zipCode: '',
        printName: '',
      },
    }),
    []
  );

  const enhancedState: ShiftToDebitState = {
    payment,
    organization,
    filesUrls,
    isPaymentLoading,
    deliveryMethod: currentDeliveryMethod,
    vendor,
    fee,
  };

  const [deliveryMethodMV, deliveryMethodMVActions] = useForm(deliveryMethodModel, {
    submit: (value) => onValidateAddress(enhancedState, deliveryMethodActions, value),
  });

  return {
    state: enhancedState,
    deliveryMethodMV,
    onValidateAddress: deliveryMethodMVActions.submit,
  };
}

function useShiftToDebitRedirects(token: string) {
  const history = useHistory();
  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));

  const currentDeliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));

  useEffect(() => {
    if (currentDeliveryMethod && payment) {
      const { redirectUrl, locationState } = getShiftToDebitRedirect({
        token,
        payment,
        currentDeliveryMethod,
      });

      if (redirectUrl) {
        history.replace(redirectUrl, locationState);
      }
    }
  }, [currentDeliveryMethod, payment, token]);
}

function getShiftToDebitRedirect({
  token,
  payment,
  currentDeliveryMethod,
}: {
  token: string;
  payment: PaymentType;
  currentDeliveryMethod: DeliveryMethodType;
}) {
  let redirectUrl = '';
  let locationState: ShiftToDebitLocationState | Record<string, unknown> = {};

  if (payment?.status === PaymentStatus.FAILED) {
    redirectUrl = generatePath(shiftToDebitLocations.paymentFailed, { token });
  } else if (payment?.status === PaymentStatus.BLOCKED) {
    redirectUrl = generatePath(shiftToDebitLocations.paymentBlocked, { token });
  } else if (currentDeliveryMethod?.deliveryType === DeliveryType.CARD) {
    redirectUrl = generatePath(shiftToDebitLocations.success, { token });
  } else if (
    currentDeliveryMethod?.deliveryType === DeliveryType.CHECK &&
    payment?.deliverStatus === PaymentDeliverStatus.CLEARED
  ) {
    redirectUrl = generatePath(shiftToDebitLocations.checkDeposited, { token });
  } else if (currentDeliveryMethod?.deliveryType === DeliveryType.ACH && payment?.status === PaymentStatus.COMPLETED) {
    [redirectUrl, locationState] = [
      generatePath(shiftToDebitLocations.invalidToken, { token }),
      {
        analyticsProps: { category: category.push2debit },
      } as AnalyticPropsInState,
    ];
  } else if (payment?.isCheckToP2DOfferExpired) {
    redirectUrl = generatePath(shiftToDebitLocations.checkOfferExpired, { token });
  }

  return { redirectUrl, locationState };
}
