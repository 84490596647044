import { MIForm } from 'src/components/common/MIForm';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Option } from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { HStack, VStack } from 'src/core/ds/stack';
import { AccountingSoftwareModelViewType } from 'src/pages/settings/types';
import { AccountCategoryOption, AccountingSoftwareAccountCategoryOption } from 'src/pages/shared';
import { FormControlType, FormType, ScreenMode, SingleSelectFlavor } from 'src/utils/consts';
import {
  accountingSoftwareDisplayName,
  DEFAULT_FEES_ACCOUNT_OPTION_ID,
  DEFAULT_MELIO_FEES_ACCOUNT_NAME,
  LEGACY_QBO_MELIO_FEES_ACCOUNT_NAME,
} from '../consts';

type MelioFeesExpenseAccountViewProps = {
  model: AccountingSoftwareModelViewType;
  accountingSoftwareType: string;
  viewMode: ScreenMode;
  onEdit: () => Promise<void>;
  isLoading: boolean;
  accountCategoriesOptions: Option[];
};

const getDefaultMelioFeesAccountSelectOption = (accountNameLabel: string = DEFAULT_MELIO_FEES_ACCOUNT_NAME) => ({
  value: DEFAULT_FEES_ACCOUNT_OPTION_ID,
  label: accountNameLabel,
  account: {
    AccountSubType: '',
    AccountType: 'Expense',
    Classification: 'Expense',
    FullyQualifiedName: '',
    Id: DEFAULT_FEES_ACCOUNT_OPTION_ID,
    Name: DEFAULT_MELIO_FEES_ACCOUNT_NAME,
  },
});

const extendAccountCategoriesOptions = (options: Option[]): Option[] => {
  const melioFeesAccount = options.find((item: Option) => item.account.Name === DEFAULT_MELIO_FEES_ACCOUNT_NAME);
  const melioFeesLegacyAccount = options.find(
    (item: Option) => item.account.Name === LEGACY_QBO_MELIO_FEES_ACCOUNT_NAME
  );

  return melioFeesAccount || melioFeesLegacyAccount ? options : [getDefaultMelioFeesAccountSelectOption(), ...options];
};

export const MelioFeesExpenseAccountView = ({
  accountingSoftwareType,
  model,
  viewMode,
  isLoading,
  onEdit,
  accountCategoriesOptions,
}: MelioFeesExpenseAccountViewProps) => {
  const extendedAccountCategoriesOptions = extendAccountCategoriesOptions(accountCategoriesOptions);
  const selectedAccountOption = extendedAccountCategoriesOptions.find(
    (option) =>
      option.value === model.melioFeesAccountId.value || option.account.Name === LEGACY_QBO_MELIO_FEES_ACCOUNT_NAME
  );

  // select the default 'Melio Service Fees' account in case the selected account is not found
  const selectedValue = selectedAccountOption?.value || DEFAULT_FEES_ACCOUNT_OPTION_ID;

  const handleAccountChange = ({ value }: { value: string }) => {
    model.melioFeesAccountId.onChange({ value });
  };

  if (!accountCategoriesOptions.length) {
    return null;
  }

  return (
    <VStack
      data-testid="melio-fees-expense-account-container"
      w="full"
      alignItems="stretch"
      spacing={5}
      pt={8}
      borderTop="1px"
      borderColor="grey.400"
    >
      <HStack justifyContent="space-between">
        <Box textStyle="h2Semi">
          <MIFormattedText label="settings.accountingSoftware.melioFeesAccount.title" />
        </Box>
        {viewMode === ScreenMode.VIEW && (
          <Button
            label="general.edit"
            variant={ButtonVariants.tertiary}
            size={ButtonSizes.md}
            onClick={onEdit}
            disabled={isLoading}
            testId="button-edit-melio-fees-expense-account"
          />
        )}
      </HStack>
      <Box textStyle="body2">
        <MIFormattedText
          label="settings.accountingSoftware.melioFeesAccount.subtitle"
          values={{ accountingSoftware: accountingSoftwareDisplayName[accountingSoftwareType].displayName }}
        />
      </Box>
      <MIForm
        formControls={[
          {
            isDisabled: isLoading,
            id: 'accountCategoriesList',
            controlType: FormControlType.SELECT,
            label: 'settings.accountingSoftware.melioFeesAccount.accountsListTitle',
            noOptionsLabel: 'select.noOptions',
            flavor: SingleSelectFlavor.DS,
            testId: 'input-melio-fees-expense-account-id',
            formatOptionLabel: ({ account }: AccountingSoftwareAccountCategoryOption, { context }) => (
              <AccountCategoryOption context={context} account={account} />
            ),
            required: true,
            placeholder: 'settings.accountingSoftware.melioFeesAccount.accountsPlaceholder',
            options: extendedAccountCategoriesOptions,
            onChange: handleAccountChange,
            value: selectedValue,
          },
        ]}
        mode={viewMode}
        formType={FormType.DETAILS}
      />
    </VStack>
  );
};
