import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { IllustrationName } from 'src/core/ds/illustration';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { analytics } from 'src/services/analytics';

type Props = {
  signUp: () => void;
  learnMore: () => void;
};

export const MicroDepositsSuccessPage = ({ signUp, learnMore }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    analytics.page('funding-sources', 'micro-payments-success');
  }, []);

  useEffect(() => {
    dispatch(checkAndInitUserAction());
  }, [dispatch]);

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.thumbUp}
      title="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
      text="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
      buttonLabel="fundingSources.microDeposits.signUpNow"
      buttonAction={signUp}
      linkLabel="fundingSources.microDeposits.learnMore"
      linkAction={learnMore}
    >
      <WizardElements.WizardOptionLine />
      <WhatNextTitle>
        <MIFormattedText label="fundingSources.microDeposits.whatsNext" />
      </WhatNextTitle>
      <WhatNextText>
        <MIFormattedText label="fundingSources.microDeposits.signUp" />
      </WhatNextText>
    </SuccessLayoutPage>
  );
};

const WhatNextTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-align: left;
  margin-top: 4.5rem;
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;
const WhatNextText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  text-align: left;
  margin-bottom: 4.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;
