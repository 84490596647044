import { ReactNode, useMemo } from 'react';
import { defaultOptionRender, OptionType } from 'src/components/common/DropDown/MIDropDown';
import useGetDeliveryMethodDisplayName from 'src/modules/delivery-methods/hooks/useGetDeliveryMethodDisplayName';
import { getPlatformLabel } from 'src/pages/receiving-method/utils';
import { WizardNewSelectField, WizardSelectFieldProps } from 'src/ui/form/WizardNewSelectField';
import { DeliveryMethodType } from 'src/utils/types';
import { NEW_AP_BANK_ACCOUNT_ID } from '../consts';
import { AccountPlatformBankAccount } from '../types';
import AddOption from './AddOption';

type Props = {
  bankAccounts?: AccountPlatformBankAccount[];
  deliveryMethod: DeliveryMethodType | null;
  platformName?: string;
} & Omit<WizardSelectFieldProps, 'label' | 'options'>;

const WizardBankAccountSelectField = ({ bankAccounts = [], deliveryMethod, platformName, ...restProps }: Props) => {
  const displayName = useGetDeliveryMethodDisplayName(deliveryMethod, true);
  const platformLabel = getPlatformLabel(platformName);
  const bankAccountsOptions = useMemo(() => {
    const options = bankAccounts.map((account) => ({
      value: account.id,
      label: account.name,
    }));

    if (options.some(({ label }) => label === displayName)) {
      return options;
    }

    return [{ value: NEW_AP_BANK_ACCOUNT_ID, label: `${displayName} (New)` }, ...options];
  }, [bankAccounts, displayName]);

  const renderOption = (option: OptionType, searchText?: string | null): ReactNode | null => {
    if (option.value === NEW_AP_BANK_ACCOUNT_ID) {
      return <AddOption platformLabel={platformLabel} searchText={searchText} />;
    }

    return defaultOptionRender(option, searchText);
  };

  return (
    <WizardNewSelectField
      {...restProps}
      label="linkBankAccountReceivingMethod.link.bankAccount.label"
      labelValues={{ platformLabel }}
      placeholder="linkBankAccountReceivingMethod.link.bankAccount.placeholder"
      required
      renderOption={renderOption}
      options={bankAccountsOptions}
    />
  );
};

export default WizardBankAccountSelectField;
