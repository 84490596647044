import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  headerLabel: string;
  tooltipLabel: string;
};

export const ApplicantHeader = ({ headerLabel, tooltipLabel }: Props) => (
  <Flex alignItems="center" textStyle="body4Semi" color="grey.700">
    <MIFormattedText label={headerLabel} />
    <Tooltip
      label={<MIFormattedText label={tooltipLabel} />}
      shouldWrapChildren
      placement="top"
      data-testid="financing-application-applicant-tooltip"
    >
      <Box ml={2} data-testid="financing-application-owner-description">
        <Icon name={IconNames.helpCircle} size={IconSize.lg} />
      </Box>
    </Tooltip>
  </Flex>
);
