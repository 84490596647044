import { useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { getUrlByRedirectOrigin } from 'src/pages/receiving-method/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { useLocationState, useQueryString } from 'src/utils/hooks';
import { syncSSOLocations } from './locations';
import { RedirectFromCodatPage } from './RedirectFromCodatPage';
import { SyncSSOInProgressPage } from './SyncSSOInProgressPage';
import { SyncSSOSuccessPage } from './SyncSSOSuccessPage';

type LocationState = {
  exitUrl: string;
  redirectUrl: string;
  queryParams: {
    redirect: string;
    platformName: string;
  };
};

export const SyncSSORouter = () => {
  const query = useQueryString();
  const orgId = useSelector(getOrgId);
  const locationState = useLocation<{ state: LocationState }>().state;
  const [queryParams] = useLocationState<{ redirect: string; platformName: string }>('queryParams');
  const [historyPush] = useHistoryWithOrgId();
  const redirect = (query?.redirect as RedirectOriginPages) || queryParams?.redirect;
  const redirectUrlFromQuery = getUrlByRedirectOrigin(orgId, redirect);

  const onNext = (path: string) => () => {
    historyPush({
      path,
      state: {
        ...locationState,
        redirectUrl: redirectUrlFromQuery,
      },
    });
  };

  return (
    <Switch>
      <SmartRoute path={syncSSOLocations.redirect} exact>
        <RedirectFromCodatPage />
      </SmartRoute>
      <SmartRoute path={syncSSOLocations.base} exact>
        <SyncSSOInProgressPage onNext={onNext(syncSSOLocations.success)} />
      </SmartRoute>
      <SmartRoute path={syncSSOLocations.success} exact>
        <SyncSSOSuccessPage onNext={onNext(redirectUrlFromQuery)} />
      </SmartRoute>
    </Switch>
  );
};
