import { fetchRequest } from 'src/services/api/api';

const list = ({ orgId, ...params }) => {
  const url = `/orgs/${orgId}/funding-sources`;

  return fetchRequest(url, params);
};

export const fundingSourcesApi = {
  list,
};
