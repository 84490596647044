import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';
import { boxStyle, stepIndexText } from './styles';

type Props = {
  index: string;
  title: string;
  subtitle: string;
};

export function NextStepListItem({ index, title, subtitle }: Props) {
  return (
    <Flex data-testid={title}>
      <Flex __css={stepIndexText}>{index}</Flex>
      <Flex __css={boxStyle}>
        <Flex flex={1} justifyContent="center">
          <VStack w="full" alignItems="flex-start">
            <Box textStyle="body2Semi">
              <MIFormattedText label={title} />
            </Box>
            <Box textStyle="body2" textColor="grey.700">
              <MIFormattedText label={subtitle} />
            </Box>
          </VStack>
        </Flex>
      </Flex>
    </Flex>
  );
}
