import { PAGE_EVENT } from 'src/pages/get-pro/consts';
import { EventMappingType } from 'src/services/analytics/types';

export const MESSAGE_MAPPING: Record<string, EventMappingType> = {
  'close-accounting-software-migration-message': [PAGE_EVENT, 'close-accounting-software-migration'],
  'click-close-match-account-message': [PAGE_EVENT, 'click-close-match-account-message'],
  'click-close-show-verify-message': [PAGE_EVENT, 'click-close-show-verify-message'],
  'getPro.message.verifyAccount.action': [PAGE_EVENT, 'click-show-verify-message'],
  'getPro.message.matchAccount.action': [PAGE_EVENT, 'click-match-account-message'],
  'getPro.message.invoiceGeneration.action': [PAGE_EVENT, 'click-invoice-generation-message'],
  'mtl-click-complete-details': [PAGE_EVENT, 'mtl-click-complete-details'],
  'mtl-close-complete-details': [PAGE_EVENT, 'mtl-close-complete-details'],
};
