import { FC } from 'react';
import {
  LinkCardViewContainer,
  RetrievedTokenizedData,
} from 'src/components/basis-theory/link-card-view/LinkCardViewContainer';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';

type Props = {
  onLinkCard(tokenizedData: RetrievedTokenizedData): void;
  onNext(): void;
  onExit(): void;
  onError(): void;
  onLoaded(): void;
  isLoading: boolean;
  showLinkCardButtonSpinner: boolean;
};

export const AddCardFundingSource: FC<Props> = ({
  onLinkCard,
  onNext,
  onExit,
  onError,
  onLoaded,
  isLoading,
  showLinkCardButtonSpinner,
}) => (
  <StepLayoutPage
    onNext={onNext}
    goExit={onExit}
    isLoading={isLoading}
    title="onboarding.fundingSources.card.iframeTitle"
    innerSize={75}
    isHideActions
    hideHeader
  >
    <LinkCardViewContainer
      onLinkCard={onLinkCard}
      onError={onError}
      showComplianceMessage
      showLinkCardButtonSpinner={showLinkCardButtonSpinner}
      onReady={onLoaded}
    />
  </StepLayoutPage>
);
