// <reference types="googlemaps" />
import { GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { ChangeEvent, PureComponent } from 'react';
import { compose } from 'recompose';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import config from 'src/config';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { withBreak } from 'src/hoc';
import pin from 'src/images/general/map-pin.png';
import { googleMapStyles, mapContainerStyle } from 'src/theme/GoogleMapStyle';
import { ADDRESS_DEFAULTS, AddressFormControlMode, Country, DEFAULT_LOCATION } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { GoogleCombinedAddressType, GooglePlaceType } from 'src/utils/types';

/**
 This component uses '@react-google-maps/api' to display a Google Maps widget and a places autocomplete input.
 Documentation: https://react-google-maps-api-docs.netlify.com/
 */
type Props = {
  id: string;
  addressLabel: string;
  addressPlaceholder?: string;
  errorMessage?: string;
  aptNumberLabel: string;
  aptNumberPlaceholder?: string;
  address?: GoogleCombinedAddressType;
  disabled?: boolean;
  required?: boolean;
  mode?: AddressFormControlMode;
  autoFocus?: boolean;
  showAddManualAddress?: boolean;
  limitToCountry: string;
  onChange: (arg0: GoogleCombinedAddressType, type?: string) => void;
  onType?: (value: string) => void;
  goToManualAddress?: () => void;
  device: { isMobile: boolean };
  onSelect: (value: boolean) => void;
  hideSuite: boolean;
  hideInput: boolean;
  marginBottomOverride?: string;
  allowAllCountries?: string;
  isViewOnly?: boolean;
};

type State = {
  place: GooglePlaceType;
  marker: google.maps.LatLngLiteral;
  aptNumber?: string;
  formattedAddress?: string;
  isMarkerSet: boolean;
  zoom: number;
  isAddressSelected: boolean;
};

const DEFAULT_ADDRESS_STATE: any = {
  place: {
    address_components: [],
    name: undefined,
    formatted_address: undefined,
    place_id: undefined,
    geometry: {
      location: DEFAULT_LOCATION,
    },
  },
  marker: DEFAULT_LOCATION,
  isMarkerSet: false,
  zoom: 10,
  aptNumber: undefined,
  formattedAddress: '',
  isAddressSelected: false,
};
const PLACE_ZOOM = 16;
const COUNTRY_TERRITORIES = Object.values(Country);
const places = [`${config.services.googleMaps.url}&libraries=places&callback=initMap`];
export const INPUT_AUTOCOMPLETE = {
  // According to MDN, to disable the autocomplete we need to use 'off' string
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
  OFF: 'off',
};

class MIAddressAutocomplete extends PureComponent<Props, State> {
  static defaultProps = {
    errorMessage: null,
    addressPlaceholder: 'form.addressAutocomplete.addressPlaceholder',
    aptNumberPlaceholder: '',
    address: {
      addressComponents: [],
      formattedAddress: '',
      geometry: DEFAULT_LOCATION,
      placeId: '',
      name: '',
      aptNumber: undefined,
    },
    disabled: false,
    required: false,
    mode: AddressFormControlMode.WITH_MAP,
    autoFocus: false,
    showAddManualAddress: false,
    limitToCountry: COUNTRY_TERRITORIES,
    onType: null,
    goToManualAddress: null,
    hideInput: false,
    allowAllCountries: false,
  };

  constructor(props: Props) {
    super(props);

    this.state = cloneDeep(DEFAULT_ADDRESS_STATE);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.address && !isEmpty(props.address.formattedAddress)) {
      if ((isEmpty(state.formattedAddress) && !state.isMarkerSet) || props.isViewOnly) {
        return {
          place: {
            address_components: props.address.addressComponents,
            name: props.address.name,
            formatted_address: props.address.formattedAddress,
            place_id: props.address.placeId,
            geometry: {
              location: props.address.geometry,
            },
          },
          marker: props.address.geometry,
          isMarkerSet: true,
          zoom: PLACE_ZOOM,
          aptNumber: props.address.aptNumber,
          formattedAddress: props.address.formattedAddress,
        };
      }

      return {
        formattedAddress: state.formattedAddress,
      };
    }

    return null;
  }

  onLoad = () => {
    const input = document.getElementById(this.props.id) as HTMLInputElement;
    this.autocomplete = new window.google.maps.places.Autocomplete(input);

    if (!this.props.allowAllCountries) {
      this.autocomplete.setComponentRestrictions({
        country: this.props.limitToCountry,
      });
    }

    this.autocomplete.addListener('place_changed', this.onPlacesChanged);
  };

  onPlacesChanged = () => {
    const place = this.autocomplete.getPlace();

    if (place?.geometry?.location) {
      this.setState(
        {
          place,
          isAddressSelected: true,
          formattedAddress: place.formatted_address,
          marker: place.geometry.location.toJSON(),
          isMarkerSet: true,
          zoom: PLACE_ZOOM,
        },
        () => {
          this.props.onChange(this.buildAddress(), this.props.id);
        }
      );
    }
  };

  onAddressInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    this.setState(
      {
        isAddressSelected: false,
        formattedAddress: value,
      },
      () => {
        this.props.onChange(this.buildAddress(), this.props.id);
        this.props.onType?.(value);
      }
    );
  };

  onAptNumberChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    if (this.state.isMarkerSet) {
      this.setState(
        {
          aptNumber: value,
        },
        () => {
          this.props.onChange(this.buildAddress(), this.props.id);
        }
      );
    }
  };

  autocomplete!: google.maps.places.Autocomplete;

  buildAddress = () => {
    if (!this.state.isAddressSelected) {
      return MIAddressAutocomplete.defaultProps.address;
    }

    const geometry = this.state.place.geometry?.location?.toJSON
      ? this.state.place.geometry.location.toJSON()
      : this.state.place.geometry?.location;

    return {
      addressComponents: this.state.place.address_components,
      formattedAddress: this.state.place.formatted_address,
      placeId: this.state.place.place_id,
      geometry,
      name: this.state.place.name,
      aptNumber: this.state.aptNumber,
    } as any;
  };

  // Google Maps API has a side effect that override the input's autocomplete attribute
  // This will override it back. Read more:
  // https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3
  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.setAttribute('autocomplete', INPUT_AUTOCOMPLETE.OFF);
  };

  render() {
    const {
      address,
      addressLabel,
      addressPlaceholder,
      errorMessage,
      disabled,
      required,
      aptNumberLabel,
      aptNumberPlaceholder,
      mode,
      id,
      device,
      goToManualAddress,
      showAddManualAddress,
      hideSuite,
      hideInput,
      marginBottomOverride,
      isViewOnly,
    } = this.props;
    const { marker, zoom, isMarkerSet, formattedAddress, aptNumber, isAddressSelected } = this.state;
    const autoFocus = device.isMobile ? false : this.props.autoFocus;
    const isGoogleAddressSelected = !!address?.placeId && address?.placeId !== ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID;
    const textInputSize = mode === AddressFormControlMode.FORM ? 'sm' : 'md';
    const showCantFindAddress = showAddManualAddress && !isAddressSelected && !isViewOnly;

    // This is here to make sure no one listens to enter events on the address component, but the component itself
    const onKeyPressed = (event) => {
      event.stopPropagation();
    };

    return (
      <MIInnerAddressAutocompleteContainer hideInput={hideInput}>
        <MapContainer mode={mode}>
          <LoadScriptNext onError={capture} googleMapsApiKey={config.services.googleMaps.key} libraries={places as any}>
            <GoogleMap
              onLoad={this.onLoad}
              mapContainerStyle={mapContainerStyle}
              zoom={zoom}
              center={marker}
              options={{
                styles: googleMapStyles,
                fullscreenControl: false,
              }}
            >
              {isMarkerSet && <Marker position={marker} icon={{ url: pin }} />}
            </GoogleMap>
          </LoadScriptNext>
        </MapContainer>

        <AddressContainer data-testid={isGoogleAddressSelected ? 'address-selected' : ''}>
          <StandaloneSearchBoxContainer onKeyDown={onKeyPressed}>
            <TextField
              id={id}
              testId={`input-${id}`}
              value={formattedAddress}
              label={addressLabel}
              placeholder={addressPlaceholder}
              errorMessage={errorMessage}
              isDisabled={disabled}
              isRequired={required}
              autoFocus={autoFocus}
              onChange={this.onAddressInputChanged}
              size={textInputSize}
              mb={marginBottomOverride}
              isViewOnly={isViewOnly}
              autoComplete={INPUT_AUTOCOMPLETE.OFF}
              onFocus={this.onFocus}
              name="notASearchField"
            />
            {!errorMessage && showCantFindAddress && (
              <CantFindAddress mode={mode} onClick={goToManualAddress} data-testid="cant-find-address">
                <MIFormattedText label="form.addressAutocomplete.cantFindAddress" />
              </CantFindAddress>
            )}
          </StandaloneSearchBoxContainer>
          {!hideSuite && (
            <AptNumberContainer mode={mode}>
              <TextField
                id="businessAddressAptNumber"
                value={aptNumber || ''}
                label={aptNumberLabel}
                placeholder={aptNumberPlaceholder}
                isDisabled={!isMarkerSet || this.props.disabled}
                onChange={this.onAptNumberChanged}
                size={mode === AddressFormControlMode.FORM ? 'sm' : 'md'}
                isViewOnly={isViewOnly}
              />
            </AptNumberContainer>
          )}
        </AddressContainer>
      </MIInnerAddressAutocompleteContainer>
    );
  }
}

type MIInnerAddressAutocompleteContainerProps = {
  children?: React.ReactNode;
  hideInput: boolean;
};

const MIInnerAddressAutocompleteContainer = ({ hideInput, children }: MIInnerAddressAutocompleteContainerProps) => (
  <Box w="full" display={hideInput ? 'none' : 'block'}>
    {children}
  </Box>
);

type MapContainerProps = {
  children?: React.ReactNode;
  mode?: AddressFormControlMode;
};

const MapContainer = ({ children, mode }: MapContainerProps) => (
  <Box
    mb={mode !== AddressFormControlMode.WITH_MAP ? 0 : 12}
    display={mode === AddressFormControlMode.WITH_MAP ? 'block' : 'none'}
  >
    {children}
  </Box>
);

type AptNumberContainerProps = {
  children?: React.ReactNode;
  mode?: AddressFormControlMode;
};

const AptNumberContainer = ({ children, mode }: AptNumberContainerProps) => (
  <Flex w="full" mt={mode === AddressFormControlMode.FORM ? 4 : undefined} mx={0} mb={0}>
    {children}
  </Flex>
);

type AddressContainerProps = {
  children?: React.ReactNode;
  'data-testid'?: string;
};

const AddressContainer = ({ children, ...rest }: AddressContainerProps) => (
  <Box w="full" {...rest}>
    {children}
  </Box>
);

type StandaloneSearchBoxContainerProps = {
  children?: React.ReactNode;
  onKeyDown: (e: React.KeyboardEvent) => void;
};

const StandaloneSearchBoxContainerStyle: CSSObject = {
  w: 'full',
  flexDir: 'column',
  pos: 'relative',
  "input[type='search']::-webkit-search-cancel-button": {
    WebkitAppearance: 'none',
  },
};

const StandaloneSearchBoxContainer = ({ onKeyDown, children }: StandaloneSearchBoxContainerProps) => (
  <Flex onKeyDown={onKeyDown} sx={StandaloneSearchBoxContainerStyle}>
    {children}
  </Flex>
);

type CantFindAddressProps = {
  children?: React.ReactNode;
  mode?: AddressFormControlMode;
  'data-testid': string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const CantFindAddress = ({ children, mode, ...rest }: CantFindAddressProps) => (
  <Flex
    h="3rem"
    w="full"
    position="absolute"
    textStyle="body4"
    alignItems="center"
    bg="white"
    boxShadow={400}
    py={0}
    px={2}
    color="primary.500"
    boxSizing="border-box"
    cursor="pointer"
    mt={-2}
    zIndex={100}
    top={mode === AddressFormControlMode.FORM ? '4.8rem' : '7.2rem'}
    {...rest}
  >
    {children}
  </Flex>
);

export default compose(withBreak())(MIAddressAutocomplete);
