import { ReactNode } from 'react';
import Box from 'src/core/ds/box';

type TopPartContainerProps = {
  children?: ReactNode;
};

export const TopPartContainer = ({ children }: TopPartContainerProps) => (
  <Box zIndex={1} pt={3} pb={2}>
    {children}
  </Box>
);
