import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import organizationStore from 'src/modules/organizations/organizations-store';
import { analytics } from 'src/services/analytics';
import { convertToGoogleAddress, convertToServerAddress } from 'src/utils/address';
import { GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  onNext: () => void;
  onExit: () => void;
  onPrev: () => void;
  goToManual: () => void;
};

const AddAccountingFirmAddress = ({ onExit, onNext, onPrev, goToManual }: Props) => {
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const dispatch = useDispatch();
  const [address, setAddress] = useState(convertToGoogleAddress(newCompanyInfo));
  const [validationErrors, setValidationErrors] = useState<Record<string, any> | null>(null);
  const onSubmit = async () => {
    const companyAddress = convertToServerAddress(address);
    const validationErrors = getValidationErrors('companyInfo', companyAddress);

    if (!isEmpty(validationErrors)) {
      analytics.trackAction('form-validation-error', { validationErrors });
      setValidationErrors(validationErrors);
    } else {
      dispatch(
        organizationStore.actions.updateNewCompanySlice.update({
          ...newCompanyInfo,
          ...companyAddress,
        })
      );
      onNext();
    }
  };

  const onChange = (address: GoogleCombinedAddressType) => {
    setAddress(address);
    setValidationErrors(null);
  };

  return (
    <StepLayoutPage
      title="addAccountingFirm.address.title"
      titleValues={{ companyName: <Title>{newCompanyInfo.companyName}</Title> }}
      relativeStep={3 / 5}
      onPrev={onPrev}
      goExit={onExit}
      onNext={onSubmit}
    >
      <MIAddressAutocomplete
        id="address"
        addressLabel="addAccountingFirm.address.addressLabel"
        aptNumberLabel="addAccountingFirm.address.aptNumberLabel"
        address={address}
        onChange={onChange}
        goToManualAddress={goToManual}
        required
        autoFocus
        errorMessage={validationErrors?.googlePlaceId || validationErrors?.zipCode}
        showAddManualAddress
        privateData
      />
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default AddAccountingFirmAddress;
