import isNil from 'lodash/isNil';
import { MICategoryList } from 'src/components/common/MICategoryList';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { productReceivedOptions } from 'src/utils/bills';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  setGoodsReceived: (selectedId: string) => void;
  goodsReceivedId: string | null;
};

const GuestExpeditePaymentPage = ({ onNext, onPrev, setGoodsReceived, goodsReceivedId }: Props) => {
  const { StepLayout } = useSiteContext().components;

  return (
    <StepLayout
      title="bills.pay.expedite.title"
      subtitle="bills.pay.expedite.meliomeSubtitle"
      onNext={onNext}
      onPrev={onPrev}
      isNextDisabled={isNil(goodsReceivedId)}
    >
      <MICategoryList
        selectedId={goodsReceivedId}
        categories={productReceivedOptions}
        onSelect={(id) => setGoodsReceived(id)}
      />
    </StepLayout>
  );
};

export default GuestExpeditePaymentPage;
