import { microDepositLocations } from './locations';

export enum MicroDepositStatuses {
  WAITING_FOR_STATUS = 'waiting-for-status',
  ALREADY_VERIFIED = 'already-verified',
  NOT_VERIFIED = 'not-verified',
  TOO_MANY_ATTEMPTS = 'too-many-attempts',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const ERR_BANK_ACCOUNT_BLOCKED_CODE = 'DLM13';
export const ERR_VERIFY_MICRO_DEPOSITS = 'DLM12';
export const VERIFIED = 'VERIFIED';

export const MicroDepositStatusURLMapper = {
  [MicroDepositStatuses.ALREADY_VERIFIED]: microDepositLocations.alreadyVerified,
  [MicroDepositStatuses.SUCCESS]: microDepositLocations.success,
  [MicroDepositStatuses.TOO_MANY_ATTEMPTS]: microDepositLocations.tooManyAttempts,
  [MicroDepositStatuses.ERROR]: microDepositLocations.error,
  [MicroDepositStatuses.NOT_VERIFIED]: microDepositLocations.verification,
};

export const LEARN_MORE_LINK =
  'https://melio.zendesk.com/hc/en-us/articles/4620783240092-Failed-verification-of-bank-account-with-micro-deposits';

export const msnMicroDepositPagePage = 'msn-micro-deposit';
