import { analytics } from 'src/services/analytics';
import { EventMappingsTree } from 'src/services/analytics/types';

const internationalDeliveryMethod = 'delivery-method-international';

export const fireInternationalAnalyticsOnSubmit = () => analytics.trackAction('form-success');

export const MAPPING: EventMappingsTree = {
  '/orgs/*/vendors/*/delivery-method/international/billing': {
    'page.view': [internationalDeliveryMethod, 'payment-details'],
    'option-changed-bankCountry': [
      internationalDeliveryMethod,
      'selected-country',
      { selectedCountry: 'option.value', suggestedCount: 'optionsCount', searchTerm: 'searchTerm' },
    ],
    'option-changed-country': [
      internationalDeliveryMethod,
      'selected-country',
      { selectedCountry: 'option.value', suggestedCount: 'optionsCount', searchTerm: 'searchTerm' },
    ],
    'form-validation-error': [
      internationalDeliveryMethod,
      'payment-details-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'progress.continue': [internationalDeliveryMethod, 'request-payment-details'],
    'form-success': [internationalDeliveryMethod, 'success-payment-details'],
  },
  '/orgs/*/vendors/*/delivery-method/international/vendor-details': {
    'page.view': [internationalDeliveryMethod, 'vendor-details'],
    'form-validation-error': [
      internationalDeliveryMethod,
      'vendor-details-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'vendors.deliveryMethods.international.vendorDetails.submit': [
      internationalDeliveryMethod,
      'request-vendor-details',
    ],
    'vendors.deliveryMethods.international.save': [internationalDeliveryMethod, 'request-vendor-details'],
    'vendors.deliveryMethods.international.saveAndAddBill': [
      internationalDeliveryMethod,
      'request-vendor-details-and-add-bill',
    ],
    'form-success': [internationalDeliveryMethod, 'success-vendor-details'],
    'international-delivery-method-failure': [internationalDeliveryMethod, 'create-failure', { errors: 'errors' }],
  },
  '/orgs/*/vendors/*/delivery-method/international/payment-purpose': {
    'page.view': [internationalDeliveryMethod, 'payment-purpose'],
    'option-changed-purpose': [
      internationalDeliveryMethod,
      'select-payment-purpose',
      { selectedValue: 'option.value', suggestedCount: 'optionsCount', searchTerm: 'searchTerm' },
    ],
    'form-validation-error': [
      internationalDeliveryMethod,
      'payment-purpose-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'vendors.deliveryMethods.international.paymentPurpose.continue': [
      internationalDeliveryMethod,
      'request-payment-purpose',
    ],
    'form-success': [internationalDeliveryMethod, 'success-payment-purpose'],
  },
  '/orgs/*/vendors/*/delivery-method/international/business-details': {
    'page.view': [internationalDeliveryMethod, 'business-details'],
    'form-validation-error': [
      internationalDeliveryMethod,
      'business-details-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'progress.continue': [internationalDeliveryMethod, 'request-business-details'],
    'form-success': [internationalDeliveryMethod, 'success-business-details'],
    'international-delivery-method-failure': [internationalDeliveryMethod, 'create-failure', { errors: 'errors' }],
  },
  '/orgs/*/vendors/*/delivery-method/international/ubo': {
    'page.view': [internationalDeliveryMethod, 'ubo'],
    'form-validation-error': [internationalDeliveryMethod, 'ubo-error', { validationErrors: 'validationErrors' }],
    'form-success': [internationalDeliveryMethod, 'success-ubo-details'],
    'vendors.deliveryMethods.international.ubo.continue': [internationalDeliveryMethod, 'request-ubo-details'],
    'vendors.deliveryMethods.international.save': [internationalDeliveryMethod, 'request-ubo-details'],
    'vendors.deliveryMethods.international.saveAndAddBill': [
      internationalDeliveryMethod,
      'request-ubo-details-and-add-bill',
    ],
    'international-delivery-method-failure': [internationalDeliveryMethod, 'create-failure', { errors: 'errors' }],
    'go.exit': [internationalDeliveryMethod, 'exit'],
    'vendors.deliveryMethods.international.ubo.addOwner': [internationalDeliveryMethod, 'add-owner'],
    'vendors.deliveryMethods.international.ubo.removeOwner': [internationalDeliveryMethod, 'remove-owner'],
  },
};
