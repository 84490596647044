export const headerStyle = {
  h: { base: '5.6rem', md: '7rem' },
  px: { base: 5, md: 10 },
  pt: { base: 4, md: 8 },
  bg: 'primary.500',
  position: 'fixed',
  w: 'full',
  boxSizing: 'border-box',
  zIndex: 1000,
};
