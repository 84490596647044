import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  display: 'flex',
  mb: 1,
  apply: 'textStyles.body4Semi',
  color: 'grey.600',
  _disabled: {
    color: 'grey.400',
    pointerEvents: 'none',
    '&[disabled-search=true]': {
      color: 'grey.600',
    },
  },
  _invalid: {
    color: 'red.500',
  },
  _readOnly: {
    pointerEvents: 'none',
  },
  _hidden: {
    display: 'flex',
    pointerEvents: 'none',
  },
  'span:first-of-type': { order: 2 },
};

export default {
  baseStyle,
};
