export const FONT_WEIGHT = {
  BOLD: 'bold',
  SEMI: 600,
  NORMAL: 'normal',
};

const textStyles = {
  display1: {
    fontSize: '4.8rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '6rem',
  },
  display2Semi: {
    fontSize: '4rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '4.8rem',
  },
  display2: {
    fontSize: '4rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '4.8rem',
  },
  h1Semi: {
    fontSize: '3.2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '4.0rem',
  },
  h2Semi: {
    fontSize: '2.4rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '3.2rem',
  },
  h2: {
    fontSize: '2.4rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '3.2rem',
  },
  body1Semi: {
    fontSize: '2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2.8rem',
  },
  body1: {
    fontSize: '2rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2.8rem',
  },
  body2: {
    fontSize: '1.6rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2.4rem',
  },
  body2Semi: {
    fontSize: '1.6rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2.4rem',
  },
  body3Semi: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '2rem',
  },
  body3: {
    fontSize: '1.4rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2rem',
  },
  body4Semi: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.6rem',
  },
  body4: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.6rem',
  },
  body4Uppercase: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.6rem',
    textTransform: 'uppercase',
  },
  caption1: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.4rem',
  },
  caption1Semi: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.4rem',
  },
  caption1Uppercase: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.4rem',
    textTransform: 'uppercase',
  },
  leftMenu: {
    fontSize: '1rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.4rem',
  },
  toastAction: {
    fontSize: '1.2rem',
    fontWeight: FONT_WEIGHT.BOLD,
    lineHeight: '1.6rem',
    textTransform: 'uppercase',
  },
  link1: {
    fontSize: '1.6rem',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2.4rem',
    cursor: 'pointer',
    color: 'primary.500',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
  },
  link2: {
    fontSize: '1.4rem',
    cursor: 'pointer',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '2rem',
    color: 'primary.500',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
  },
  link3: {
    fontSize: '1.2rem',
    cursor: 'pointer',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.6rem',
    color: 'primary.500',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
  },
  link4: {
    fontSize: '1rem',
    cursor: 'pointer',
    fontWeight: FONT_WEIGHT.NORMAL,
    lineHeight: '1.4rem',
    color: 'primary.500',
    textDecoration: 'none',
    _hover: {
      textDecoration: 'underline',
    },
  },
  tabLink: {
    fontSize: '0.8rem',
    fontWeight: FONT_WEIGHT.SEMI,
    lineHeight: '1.2rem',
  },
};

export type TextStyles = typeof textStyles;

export default textStyles;
