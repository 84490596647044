import { MouseEvent as ReactMouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';

type Props = {
  onSubmit: (e: ReactMouseEvent) => void;
  dismiss: (event: ReactMouseEvent | null) => void;
  workflowId?: number;
};

export const ConfirmApprovalWorkflowEditModal = ({ onSubmit, dismiss, workflowId }: Props) => {
  const isUpdating = useSelector(approvalWorkflowsStore.selectors.update.status(workflowId))?.loading;

  const handleConfirm = (e: ReactMouseEvent) => {
    onSubmit(e);
  };

  return (
    <ModalMessage
      id="editApprovalWorkflowModalConfirm"
      titleComponent={
        <Flex justifyContent="flex-start">
          <MIFormattedText label="settings.approvalWorkflows.modal.edit.confirmTitle" />
        </Flex>
      }
      contentComponent={
        <Box textStyle="body2" marginBlockStart="10px" marginBlockEnd="25px">
          <MIFormattedText label="settings.approvalWorkflows.modal.edit.confirmContent" />
        </Box>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <Button
            variant={ButtonVariants.tertiaryNaked}
            label="settings.approvalWorkflows.modal.edit.confirmCancel"
            size={ButtonSizes.lg}
            onClick={dismiss}
          />
          <Button
            label="settings.approvalWorkflows.modal.edit.confirmSubmit"
            size={ButtonSizes.lg}
            isLoading={isUpdating}
            onClick={handleConfirm}
          />
        </ModalButtonsWrapper>
      }
    />
  );
};
