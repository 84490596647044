import { differenceInCalendarDays, isSameDay, isToday, setHours } from 'date-fns';
import moment from 'moment';
import { DatePickerTooltip } from 'src/components/common/datepicker/DatePickerTooltip';
import { DaySlotLabel } from 'src/components/common/datepicker/DaySlotLabel';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Tooltip from 'src/core/ds/tooltip';
import { isHoliday } from 'src/utils/dates';

type WithLabelOptionsType = {
  day: number;
  date: Date;
  scheduledDate: Date;
  dueDate: string | Date | null;
  deliveryDate: Date;
};

export const renderDayContentsCustomWithLabel = (options: WithLabelOptionsType) => {
  const { day, date, scheduledDate, dueDate, deliveryDate } = options;
  const currentDate = new Date(date);
  const month = currentDate.getMonth();
  const isHolidayDay = isHoliday(moment(date).hour(12));
  const isDeductionDate = isSameDay(currentDate, new Date(scheduledDate));
  const isDueDate = dueDate && isSameDay(currentDate, new Date(dueDate));
  const isDeliveryDate = isSameDay(currentDate, new Date(deliveryDate));

  return (
    <Tooltip
      data-testid="federal-bank-holiday-tooltip"
      isDisabled={!isHolidayDay}
      placement="top"
      label={<MIFormattedText label="bills.pay.date.federalBankHoliday" />}
    >
      <Box data-testid={`${day}-${month}-day-content`}>
        {day}
        <DaySlotLabel isDeductionDate={isDeductionDate} isDueDate={isDueDate} isDeliveryDate={isDeliveryDate} />
      </Box>
    </Tooltip>
  );
};

export const renderDayContentsCustomTodayCutoffOver = (
  day,
  date,
  minScheduledDate,
  scheduledDate?,
  dueDate?,
  deliveryDate?,
  isFastAchBetterExposeEtaEnabled?
) => {
  const isHolidayDay = isHoliday(setHours(date, 12));
  const isTodayDate = isToday(date);
  const dateFormatted = new Date(date);
  const month = dateFormatted.getMonth();

  const isTodayDateUnavailable = differenceInCalendarDays(minScheduledDate, new Date()) > 0;
  const isDeductionDate = isSameDay(dateFormatted, new Date(scheduledDate));
  const isDueDate = isSameDay(dateFormatted, new Date(dueDate));
  const isDeliveryDate = isSameDay(dateFormatted, new Date(deliveryDate));

  if (isHolidayDay) {
    return (
      <DatePickerTooltip
        day={day}
        month={month}
        label="bills.pay.date.federalBankHoliday"
        testId="federal-bank-holiday-tooltip"
      />
    );
  } else if (isTodayDate && isTodayDateUnavailable) {
    return (
      <DatePickerTooltip
        day={day}
        month={month}
        label="bills.pay.date.cutOffTimesTooltip"
        testId="cutoff-times-tooltip"
      />
    );
  }

  return (
    <Box data-testid={`${day}-${month}-day-content`}>
      {day}
      {isFastAchBetterExposeEtaEnabled ? (
        <DaySlotLabel isDeductionDate={isDeductionDate} isDueDate={isDueDate} isDeliveryDate={isDeliveryDate} />
      ) : null}
    </Box>
  );
};

type FinancingOptionsType = {
  day: number;
  date: Date;
  maxDate?: Date;
  scheduledDate: Date;
  dueDate: string | Date;
  deliveryDate: Date;
  isFastAchBetterExposeEtaEnabled: boolean;
};

export const renderDayContentsCustomFinancing = (options: FinancingOptionsType) => {
  const { day, date, maxDate, scheduledDate, dueDate, deliveryDate, isFastAchBetterExposeEtaEnabled } = options;
  const isHolidayDay = isHoliday(setHours(date, 12));
  const month = new Date(date).getMonth();
  const currentDate = new Date(date);
  const afterMaxDate = maxDate && differenceInCalendarDays(date, maxDate) > 0;
  const isDeductionDate = isSameDay(currentDate, new Date(scheduledDate));
  const isDueDate = isSameDay(currentDate, new Date(dueDate));
  const isDeliveryDate = isSameDay(currentDate, new Date(deliveryDate));

  if (afterMaxDate) {
    return (
      <DatePickerTooltip
        day={day}
        month={month}
        label="bills.pay.date.financingMaxDate"
        testId="after-max-date-tooltip"
      />
    );
  } else if (isHolidayDay) {
    return (
      <DatePickerTooltip
        day={day}
        month={month}
        label="bills.pay.date.federalBankHoliday"
        testId="federal-bank-holiday-tooltip"
      />
    );
  }

  return (
    <Box data-testid={`${day}-${month}-day-content`}>
      {day}
      {isFastAchBetterExposeEtaEnabled ? (
        <DaySlotLabel isDeductionDate={isDeductionDate} isDueDate={isDueDate} isDeliveryDate={isDeliveryDate} />
      ) : null}
    </Box>
  );
};

export const getBillScheduledAndDueDateLabels = (isFinancingPayment: boolean) => {
  if (isFinancingPayment) {
    return {
      scheduledDateLabel: 'bills.pay.date.financing.billSendDate',
      dueDateLabel: 'bills.pay.date.financing.billDueDate',
    };
  }

  return {
    scheduledDateLabel: 'bills.pay.date.billScheduledDate',
    dueDateLabel: 'bills.pay.date.billDueDate',
  };
};
