import React from 'react';
import { BrandFullLogo } from 'src/core/ds/brandFullLogo';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { brandNameByCardNetwork } from 'src/utils/card';
import { CardNetworkTypes, CardTypes, FundingType } from 'src/utils/consts';
import { hasFundingLogo } from 'src/utils/funding-sources';
import { AccountType, CardNetworkKeysType } from 'src/utils/types';

type FundingSourceIconProps = {
  fundingSource: AccountType;
};

export const FundingSourceIcon = ({ fundingSource }: FundingSourceIconProps) => {
  const site = useSiteContext();
  const bankIcon =
    fundingSource.fundingType === FundingType.ACH && hasFundingLogo(fundingSource) ? (
      <img src={`data:image/jpeg;base64,${fundingSource.logo}`} alt="funding source icon" />
    ) : (
      <Icon name={IconNames.bank} size={IconSize.lg} />
    );
  const cardType = fundingSource.cardAccount?.cardType || '';
  const network = fundingSource.cardAccount?.network?.toUpperCase() as CardNetworkKeysType;
  let icon = bankIcon;

  if (cardType === CardTypes.CREDIT || (cardType === CardTypes.DEBIT && !site?.hasDebitInBankAccounts)) {
    icon = <BrandFullLogo name={brandNameByCardNetwork[CardNetworkTypes[network]]} />;
  }

  return icon;
};
