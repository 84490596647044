export const MAPPINGS = {
  'regularBatchPayments/removeBatchItem': {
    type: 'info',
    msg: 'regularBatchPayments.notifications.paymentRemoved',
  },
  'regularBatchPayments/removeBillFromPayment': {
    type: 'info',
    msg: 'regularBatchPayments.notifications.billRemoved',
  },
};
