import { Box, Flex } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { Button } from 'src/core/ds/button';
import { useMarkableBills } from '../../BillsGallery/components/useMarkableBills';
import { PartialBillsWarning } from './PartialBillsWarning';

export interface MarkBillsModalContentProps {
  id: string;
  billIds: string[];
  onConfirm: () => void;
  isLoading: boolean;
  isPartialBatch: boolean;
  dismiss: () => void;
}

export const MarkBillsModalContent = ({
  id,
  dismiss,
  billIds = [],
  onConfirm,
  isLoading,
  isPartialBatch,
}: MarkBillsModalContentProps) => {
  const { markableBills } = useMarkableBills(billIds);

  return (
    <ModalMessage
      id={id}
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText
            label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.title"
            values={{ billsCount: markableBills.length }}
          />
        </Box>
      }
      onCloseClick={dismiss}
      contentComponent={
        isPartialBatch ? (
          <PartialBillsWarning billIds={billIds} />
        ) : (
          <MIFormattedText label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.subtitle" />
        )
      }
      buttonComponent={
        <Flex justifyContent="center">
          <Button
            onClick={() => onConfirm()}
            label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.buttonLabel"
            testId="mark-bills-as-paid-modal-confirm-button"
            values={{ billsCount: markableBills.length }}
            isLoading={isLoading}
            isFullWidth
          />
        </Flex>
      }
    />
  );
};
