import * as React from 'react';
import { ComponentProps, ReactNode, RefObject } from 'react';
import { MITextInput } from 'src/components/common/MITextInput';
import { TextInputSize } from 'src/utils/consts';
import { ModelViewField } from './use-form';

export type WizardTextInputFieldProps = {
  model?: ModelViewField<string>;
  value?: string;
  label: string;
  type?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (event: { value: string }) => any;
  viewOnly?: boolean;
  placeholder?: string;
  hint?: string;
  autoFocus?: boolean;
  mask?: (string | RegExp)[];
  autocomplete?: string;
  errorMessage?: string;
  notices?: string[];
  maxlength?: number;
  inputRef?: RefObject<any>;
  suffix?: ReactNode;
  labelSuffix?: string;
  size?: TextInputSize;
  afterLabelComponent?: React.ReactNode;
  leftSideIcon?: string;
} & ComponentProps<typeof MITextInput>;

export function WizardTextInputField(props: WizardTextInputFieldProps) {
  const { model, hint, ...rest } = props;
  const value = props.value === undefined ? model?.value : props.value;
  const onChange = props.onChange || model?.onChange;
  const id = props.id || model?.id;

  return (
    <MITextInput
      size="none"
      {...rest}
      value={value}
      onChange={onChange}
      id={id}
      errorMessage={model?.error}
      notices={hint && [hint]}
    />
  );
}
