import { ComponentStyleConfig } from '@chakra-ui/react';

export const ModalCover: ComponentStyleConfig = {
  baseStyle: {
    w: 'full',
  },

  sizes: {
    sm: {
      h: '14.4rem',
    },
    md: {
      h: '24rem',
    },
    lg: {
      h: '32rem',
    },
  },

  defaultProps: {
    size: 'md',
  },
};
