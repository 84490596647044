import config from 'src/config';

enum localStorageKeys {
  accessToken = 'access_token',
}

export const clearPlatformTokens = () => {
  localStorage.removeItem(localStorageKeys.accessToken);
};

export const getPlatformRedirectUrl = (path: string, orgId: number) =>
  `${config.server.baseUrl}/api/platform/redirect-to-platform/${orgId}?redirect_url=${encodeURIComponent(path)}`;
