import { StatusInfo } from 'src/components/payment-progress-bar/ProgressBar';
import { DeliveryType, PaymentStatus } from 'src/utils/consts';
import { getTranslationValue } from 'src/utils/translations';
import { PaymentType } from 'src/utils/types';

const getIsProgressBarActive = (payment: PaymentType) =>
  [DeliveryType.ACH, DeliveryType.PUSH_TO_DEBIT, DeliveryType.VIRTUAL_CARD, DeliveryType.CHECK].includes(
    payment.deliveryMethod?.deliveryType
  );

const getProcessDates = (payment: PaymentType) => {
  const { createdAt, deliveryEta, paidDate, scheduledDate, status } = payment;
  const collectDate = payment.metadata?.paymentStatusDates?.collectDate;
  const deliveryType = payment.deliveryMethod?.deliveryType;
  const isProgressBarActive = getIsProgressBarActive(payment);

  if (isProgressBarActive) {
    if (status === PaymentStatus.COMPLETED) {
      return [createdAt, collectDate, deliveryType === DeliveryType.CHECK ? deliveryEta : paidDate];
    }

    if (status === PaymentStatus.IN_PROGRESS || status === PaymentStatus.PROCESSED) {
      return [createdAt, collectDate, deliveryEta];
    }

    if (status === PaymentStatus.SCHEDULED || status === PaymentStatus.BLOCKED) {
      return [createdAt, scheduledDate, deliveryEta];
    }
  }

  return [];
};

export const getPaymentProcess = (payment: PaymentType): StatusInfo[] => {
  const { status } = payment;
  const deliveryType = payment.deliveryMethod?.deliveryType;
  const [scheduledDate, collectedDate, deliveredDate] = getProcessDates(payment);

  const isETACommentVisible =
    deliveredDate && !(deliveryType !== DeliveryType.CHECK && status === PaymentStatus.COMPLETED);

  return [
    {
      label: getTranslationValue('requests.tracking.title.scheduled'),
      date: scheduledDate,
    },
    {
      label: getTranslationValue('requests.tracking.title.collected'),
      date: collectedDate,
    },
    {
      label: getTranslationValue('requests.tracking.title.delivered'),
      date: deliveredDate,
      ...(isETACommentVisible && { comment: 'general.eta' }),
    },
  ];
};

const mapStatusToStage = {
  [PaymentStatus.SCHEDULED]: 0,
  [PaymentStatus.BLOCKED]: 0,
  [PaymentStatus.IN_PROGRESS]: 1,
  [PaymentStatus.PROCESSED]: 1,
  [PaymentStatus.COMPLETED]: 2,
  [PaymentStatus.FAILED]: -1,
};

export const getCurrentStage = (payment: PaymentType) => {
  const isProgressBarActive = getIsProgressBarActive(payment);

  return isProgressBarActive ? mapStatusToStage[payment.status] : -1;
};
