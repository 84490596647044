import { forwardRef, Input as ChakraInput, InputProps } from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { useBreak } from 'src/hoc';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

export type Props = InputProps & {
  id?: string;
  testId?: string | null;
  isViewOnly?: boolean;
  autoFocus?: boolean;
  placeholder?: string | null;
  placeholderValues?: Record<string, any>;
  privateData?: boolean;
};

export const Input = forwardRef<Props, 'input'>(
  (
    {
      id,
      testId,
      value,
      privateData = false,
      isViewOnly = false,
      autoFocus = false,
      placeholder,
      placeholderValues,
      ...rest
    }: Props,
    ref
  ) => {
    const intl = useIntl();
    const device = useBreak();
    const inputTestId = testId || `input-${id}`;
    const inputAutoFocus = device?.isMobile ? false : autoFocus;
    const placeholderText = !placeholder ? '' : intl.formatMessage({ id: placeholder }, placeholderValues);

    return (
      <ChakraInput
        {...rest}
        className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined}
        id={id}
        data-testid={inputTestId}
        hidden={isViewOnly}
        autoFocus={inputAutoFocus}
        placeholder={placeholderText}
        value={value}
        ref={ref}
      />
    );
  }
);
