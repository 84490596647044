import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { FC } from 'react';
import { RadioSelectMethodOption } from 'src/components/common/SelectMethods/components/RadioSelectMethodOption';
import { SettingsMethodsListItem } from 'src/components/common/SelectMethods/components/SettingsMethodsListItem';
import {
  getPaymentMethodsListItems,
  PaymentMethodType,
  sortPaymentMethods,
} from 'src/components/common/SelectMethods/containers/utils';
import { CreditPaymentMethod } from 'src/components/common/SelectMethods/types/CreditPaymentMethod';
import { DebitPaymentMethod } from 'src/components/common/SelectMethods/types/DebitPaymentMethod';
import { InstallmentsPaymentMethod } from 'src/components/common/SelectMethods/types/InstallmentsPaymentMethod';
import { useFinancingFundingSourceDisabledViewsCount } from 'src/pages/bill/pay/hooks/useFinancingFundingSourceDisabledViewsCount';
import { useFinancingFundingSourceViewCount } from 'src/pages/bill/pay/hooks/useFinancingFundingSourceViewCount';
import { analytics } from 'src/services/analytics';
import { CardTypes, FeatureFlags, FundingSourceOrigin, FundingType } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { MethodListItemType, MethodsList } from '../components/MethodsList';
import { BankAccountPaymentMethod } from '../types/BankAccountPaymentMethod';

type Props<T> = {
  value?: T;
  fundingSources: readonly T[];
  defaultFundingSourceId?: number | null;
  firstFundingSourceId?: number | null;
  onAddMethod: (type: string) => void;
  vendorId?: number;
  modifyFundingSourceActions?: (method: T) => Record<string, any>;
  onChange?: (value: T) => void;
  onVerifyClicked?: (id: number) => void;
  blockCCMoneyIn?: boolean;
  isVendorEnableCCPayments?: boolean;
  isVendorAbsorbedFee?: boolean;
  isBillingFee?: boolean;
  isInternationalVendor?: boolean;
  totalAmount?: number;
  firmBillingMethodId?: number | null;
  showInstallments?: boolean;
  disabledInstallments?: boolean;
  showAddAnotherOption?: boolean;
};

export const METHOD_TYPE_TO_COMPONENT_TYPE: Record<
  PaymentMethodType,
  | typeof CreditPaymentMethod
  | typeof DebitPaymentMethod
  | typeof BankAccountPaymentMethod
  | typeof InstallmentsPaymentMethod
> = {
  [CardTypes.CREDIT]: CreditPaymentMethod,
  [CardTypes.DEBIT]: DebitPaymentMethod,
  [FundingType.ACH]: BankAccountPaymentMethod,
  [FundingType.INSTALLMENTS]: InstallmentsPaymentMethod,
};

export const FundingSourcesListLayout: FC<Props<AccountType>> = ({
  value,
  fundingSources,
  defaultFundingSourceId,
  firstFundingSourceId,
  onAddMethod,
  onChange,
  onVerifyClicked,
  blockCCMoneyIn,
  modifyFundingSourceActions,
  vendorId,
  isVendorEnableCCPayments = true,
  isVendorAbsorbedFee = false,
  isInternationalVendor = false,
  isBillingFee = false,
  totalAmount,
  firmBillingMethodId,
  showInstallments = false,
  disabledInstallments = false,
  showAddAnotherOption = true,
}) => {
  const [isInternationalMastercardEnabled] = useFeature(FeatureFlags.EnableMastercardFSForInternational, false);
  const [showDisabledInstallmentsIfNotEligibleFF] = useFeature(
    FeatureFlags.ShowDisabledInstallmentsIfNotEligible,
    false
  );
  const creditMethods = fundingSources.filter(
    (fs) => fs.fundingType === FundingType.CARD && fs.cardAccount?.cardType === CardTypes.CREDIT
  );
  const debitMethods = fundingSources.filter(
    (fs) => fs.fundingType === FundingType.CARD && fs.cardAccount?.cardType === CardTypes.DEBIT
  );
  const bankAccountMethods = fundingSources.filter(
    (fs) => fs.fundingType === FundingType.ACH && fs.origin !== FundingSourceOrigin.QBCASH
  );

  const { financingFundingSourceFTXViewsCount, isFinancingFundingSourceFTX } = useFinancingFundingSourceViewCount();
  const { financingFundingSourceDisablediewsCount } = useFinancingFundingSourceDisabledViewsCount();

  const paymentMethodsItems: MethodListItemType[] = [];
  paymentMethodsItems.push(
    ...getPaymentMethodsListItems(
      {
        creditMethods: !blockCCMoneyIn && isVendorEnableCCPayments ? creditMethods : undefined,
        debitMethods,
        bankAccountMethods,
      },
      {
        isSettings: !onChange,
        isInternationalVendor,
        isVendorAbsorbedFee,
        isBillingFee,
        onVerifyClicked,
        showInstallments,
        disabledInstallments,
        totalAmount,
        vendorId,
        isInternationalMastercardEnabled,
        showDisabledInstallmentsIfNotEligibleFF,
        financingFundingSourceDisablediewsCount,
      }
    )
  );

  const sortedPaymentMethodsItems = sortPaymentMethods({
    paymentMethodsItems,
    firstFundingSourceId,
    shouldKeepInitialOrder: isInternationalVendor,
    isFinancingFundingSourceFTX,
    financingFundingSourceFTXViewsCount,
  });

  const handleAddMethod = (type: string) => {
    if (type === CardTypes.DEBIT) {
      analytics.track('bank-add-variants', 'add-debit');
    }

    onAddMethod(type);
  };

  return (
    <MethodsList
      value={value}
      list={sortedPaymentMethodsItems}
      onChange={onChange}
      onAddMethod={handleAddMethod}
      showAddAnotherOption={showAddAnotherOption}
      modifyFundingSourceActions={modifyFundingSourceActions}
      defaultFundingSourceId={defaultFundingSourceId}
      firmBillingMethodId={firmBillingMethodId}
      optionComponent={onChange ? RadioSelectMethodOption : SettingsMethodsListItem}
    />
  );
};
