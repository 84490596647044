import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormHelperText } from 'src/core/ds/form';

type Props = {
  testId?: string;
  label?: string;
  labelValues?: Record<string, unknown>;
};

export const HelperText = ({ testId, label, labelValues }: Props) =>
  label ? (
    <FormHelperText data-testid={testId}>
      <MIFormattedText label={label} values={labelValues} />
    </FormHelperText>
  ) : (
    <></>
  );
