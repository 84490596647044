import merge from 'lodash/merge';
import { hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { createListSlice } from 'src/helpers/redux/restListSlice';
import { name } from 'src/modules/bills/consts';
import { BillType } from 'src/utils/types';
import { billsApi } from './api';

export const billListIndexSlice = createListSlice<BillType>({
  storeName: name,
  api: (params) =>
    billsApi.getBillListInbox(params).then((res) => ({
      items: res.bills.objects,
      totalCount: res.bills.totalCount,
      paymentRequests: res.requests.objects,
      paymentRequestsTotalCount: res.requests.totalCount,
      payments: res.payments.objects,
      paymentsTotalCount: res.payments.totalCount,
    })),
  actionName: 'BILL_LIST_INBOX',
  listHashFunc: hashListKey,
  resultMapFunction: (state, currItem, newItem) => ({
    ...merge(currItem, newItem),
    payments: newItem.payments.map((payment) =>
      merge(
        currItem.payments.find((p) => p.id === payment.id),
        payment
      )
    ),
  }),
});
