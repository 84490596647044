import { useState } from 'react';
import { useSelector } from 'react-redux';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { useAccountingSoftwareSyncActions } from 'src/pages/settings/hooks/useAccountingSoftwareSyncActions';
import { useConnectSSOAccountingPlatform } from 'src/pages/settings/hooks/useConnectSSOAccountingPlatform';
import { SyncWithAccountingSoftwareCardProps } from 'src/pages/settings/types';
import { analytics } from 'src/services/analytics';
import { AccountingSoftware, SyncStatus } from 'src/utils/consts';
import { eventPage } from '../consts';
import { ConnectedView } from './ConnectedView';
import { DisconnectedView } from './DisconnectedView';
import { ErrorView } from './ErrorView';
import { InitialView } from './InitialView';

type Props = {
  accountingSoftwareType: AccountingSoftware;
  accountingSoftwareLogo: string;
} & SyncWithAccountingSoftwareCardProps;
export const SyncWithSSOCard = ({
  accountingSoftwareType,
  accountingSoftwareLogo,
  model,
  viewMode,
  onEdit,
  isLoading,
}: Props) => {
  const [connecting, setConnecting] = useState(false);
  const accountPlatform = useSelector(accountingPlatformsStore.selectors.getByName(accountingSoftwareType));
  const connectStatus = useSelector(accountingPlatformsStore.selectors.connect.status);
  const [connect] = useConnectSSOAccountingPlatform(accountingSoftwareType, eventPage, RedirectOriginPages.SETTINGS);
  const syncStatusFailed = accountPlatform?.syncStatus === SyncStatus.FAILURE;
  const syncStatusSynced = accountPlatform?.syncStatus === SyncStatus.SYNCED;
  const syncStatusStarted = accountPlatform?.syncStatus === SyncStatus.STARTED;
  const companyName = accountPlatform?.accountingPlatformCompanyName;
  const shouldShowNotification = true;
  const bankAccountsListError = useSelector(accountingPlatformsStore.selectors.listBankAccounts.error);
  const createAndLinkBankAccountError = useSelector(accountingPlatformsStore.selectors.createAndLinkBankAccount.error);
  const connectedAccountingPlatform = useSelector(accountingPlatformsStore.selectors.connectedPlatform);

  const connectionError = connectStatus?.error && connectStatus?.id === accountPlatform?.id;
  const activePlatformNoActiveConnection =
    accountPlatform?.isActive && accountPlatform?.connectionStatus === 'disconnected';
  const accountingSoftwareBankAccountError =
    (bankAccountsListError || createAndLinkBankAccountError) && connectedAccountingPlatform?.id === accountPlatform?.id;
  const { onDisconnect, onReconnect } = useAccountingSoftwareSyncActions(accountingSoftwareType);
  const shouldShowErrorView =
    !connecting &&
    (connectionError || activePlatformNoActiveConnection || accountingSoftwareBankAccountError || syncStatusFailed);
  const shouldShowDisconnectedView =
    !connecting && !accountPlatform?.isActive && accountPlatform?.connectionStatus === 'connected';
  const shouldShowConnectedView = !connecting && (syncStatusSynced || syncStatusStarted) && accountPlatform?.isActive;

  const goLinkAccountingPlatform = () => {
    analytics.track(eventPage, 'link', { type: accountingSoftwareType });
    setConnecting(true);
    connect();
  };

  if (shouldShowErrorView) {
    return (
      <ErrorView
        onLink={goLinkAccountingPlatform}
        accountingSoftwareType={accountingSoftwareType}
        logo={accountingSoftwareLogo}
      />
    );
  }

  if (shouldShowConnectedView) {
    return (
      <ConnectedView
        isLoading={isLoading}
        model={model}
        viewMode={viewMode}
        onEdit={onEdit}
        companyName={companyName}
        accountingSoftwareType={accountingSoftwareType}
        logo={accountingSoftwareLogo}
        onUnlink={onDisconnect}
      />
    );
  }

  if (shouldShowDisconnectedView) {
    return (
      <DisconnectedView
        companyName={companyName}
        accountingSoftwareType={accountingSoftwareType}
        logo={accountingSoftwareLogo}
        onLink={onReconnect}
        shouldShowNotification={shouldShowNotification}
      />
    );
  }

  return (
    <InitialView
      onLink={goLinkAccountingPlatform}
      accountingSoftwareType={accountingSoftwareType}
      logo={accountingSoftwareLogo}
      connecting={connecting}
    />
  );
};
