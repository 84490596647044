import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import { analytics } from 'src/services/analytics';
import { VerifyDeliveryMethodMicroDepositsErrorCodes } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import {
  dismiss,
  MicroDepositActions,
  MicroDepositProps,
  MicroDepositState,
  onChange,
  parseErrorToState,
  Resolutions,
  State,
  validateInputs,
} from './microDepositsCommon';

export type DeliveryMethodMicroDepositProps = {
  deliveryMethodId: number;
  token?: string;
  orgId: number;
  vendorId?: number | null;
} & MicroDepositProps;

const MicroDepositErrors = VerifyDeliveryMethodMicroDepositsErrorCodes;
const errorToResolutionMap: Record<string, any> = {
  [MicroDepositErrors.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS]: Resolutions.SUPPORT,
  [MicroDepositErrors.ERR_VERIFY_MICRO_DEPOSITS]: null,
  [MicroDepositErrors.NOT_FOUND]: Resolutions.NOT_FOUND,
};
const { ERR_VERIFY_MICRO_DEPOSITS } = MicroDepositErrors;

async function verifyDeliveryMethod(
  deliveryMethodId: number,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>,
  orgId: number,
  vendorId?: number
) {
  const deliveryMethodActions = getDeliveryMethodActions(dispatch);
  try {
    await deliveryMethodActions.verifyMicroDepositsForDeliveryMethod({
      orgId,
      vendorId,
      params: {
        id: deliveryMethodId,
        amount1: parseFloat(convertCurrencyToNumber(state.amount1)),
        amount2: parseFloat(convertCurrencyToNumber(state.amount2)),
      },
    });
    setState({
      ...state,
      resolution: Resolutions.SUCCESS,
    });

    return [true];
  } catch (err: any) {
    setState({
      ...state,
      ...parseErrorToState(err.error, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS),
    });

    return [false, err.error];
  }
}

async function onSubmit(
  eventPage: string,
  props: DeliveryMethodMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>
) {
  analytics.track(eventPage, 'submit-continue');
  const [validationOk, validationErrors] = validateInputs(state, setState);

  if (validationOk) {
    const { orgId, vendorId, deliveryMethodId } = props;
    const [success, errorMessage] = await verifyDeliveryMethod(
      deliveryMethodId,
      state,
      setState,
      dispatch,
      orgId,
      vendorId ?? undefined
    );
    analytics.track(eventPage, `verify-confirmed-${success ? 'success' : 'failed'}`, errorMessage);

    if (success && props.onSuccess) {
      props.onSuccess();
    }

    const error = parseErrorToState(errorMessage, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);

    if (!success && error.resolution === Resolutions.SUPPORT && props.onBlocked) {
      props.onBlocked();
    }

    if (!success && error.resolution === Resolutions.NOT_FOUND && props.onInvalidToken) {
      props.onInvalidToken();
    }
  } else {
    analytics.track(eventPage, 'submit-validation-error', validationErrors as Record<string, any>);
  }
}

export function createActions(
  eventPage: string,
  props: DeliveryMethodMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>
): MicroDepositActions {
  return {
    onChange: onChange(state, setState),
    dismiss: () => dismiss(setState),
    onSubmit: () => onSubmit(eventPage, props, state, setState, dispatch),
  };
}

async function checkAndUpdateVerificationStatus(props, state, setState) {
  const { orgId, vendorId, deliveryMethodId } = props;

  if (deliveryMethodId) {
    try {
      const verificationResult = await deliveryMethodsApi.getVerificationStatus({
        orgId,
        vendorId,
        deliveryMethodId,
      });

      if (verificationResult.status === 'VERIFIED') {
        setState({
          ...state,
          resolution: Resolutions.SUCCESS,
        });
      }
    } catch (e) {
      const parsedError = parseErrorToState(e, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);
      setState({
        ...state,
        ...parsedError,
      });
    }
  }
}

export function useDeliveryMethodMicroDepositState(eventPage: string, props: DeliveryMethodMicroDepositProps) {
  const [state, setState] = useState<State>({
    amount1: '',
    amount2: '',
    validationErrors: {},
  });
  const isLoading = useSelector(deliveryMethodsStore.selectors.isDeliveryMethodBeingVerifying);
  const dispatch = useDispatch();

  const enhancedState: MicroDepositState = {
    ...state,
    isLoading,
  };

  const actions = createActions(eventPage, props, state, setState, dispatch);

  useEffect(() => {
    checkAndUpdateVerificationStatus(props, state, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deliveryMethodId]);

  return [enhancedState, actions] as const;
}
