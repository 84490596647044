import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { IntlShape } from 'react-intl';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';
import { BankType, DeliveryMethodType } from 'src/utils/types';

export const validateBankAccount = async (
  orgId: number,
  bank: BankType,
  getVendorsDeliveryMethods: (orgId: number) => Promise<any>,
  intl: IntlShape
) => {
  const { routingNumber, accountNumber } = bank;
  const syncValidationErrors = getValidationErrors('fundingSourceBank', bank, [
    'accountType',
    'routingNumber',
    'accountNumber',
  ]);

  if (!isEmpty(syncValidationErrors)) {
    return syncValidationErrors;
  }

  const { vendorsDeliveryMethods } = await getVendorsDeliveryMethods(orgId);
  const deliveryMethod = vendorsDeliveryMethods.find((deliveryMethodType: DeliveryMethodType) => {
    const { bankAccount } = deliveryMethodType;

    return bankAccount?.routingNumber === routingNumber && bankAccount?.accountNumber === accountNumber;
  });

  if (deliveryMethod?.vendor) {
    return {
      accountNumber: intl.formatMessage(
        { id: 'inputErrors.fundingSourceBank.alreadyDefined' },
        {
          vendorName: checkApostropheForPluralPossessive(deliveryMethod.vendor.companyName),
        }
      ),
    };
  }

  return {};
};

export const getLabels = (isManuallyFromPlaid: boolean) => {
  if (isManuallyFromPlaid) {
    return {
      title: 'flows.addBankAccount.manually.fallbackLabels.title',
      subtitle: 'flows.addBankAccount.manually.fallbackLabels.subtitle',
      footer: 'flows.addBankAccount.manually.fallbackLabels.footer',
    };
  }

  return {
    title: 'flows.addBankAccount.manually.title',
    subtitle: 'flows.addBankAccount.manually.subtitle',
    footer: 'flows.addBankAccount.manually.footer',
  };
};
