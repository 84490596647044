import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Card } from 'src/core/ds/card';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Stack } from 'src/core/ds/stack';
import { useBreak } from 'src/hoc';
import { analytics } from 'src/services/analytics';

type Props = {
  id: string;
  title: string;
  subTitle?: string;
  iconName?: IconNames;
  isSelected?: boolean;
  onSelect?: (value: string) => void;
  analyticAction: string;
};

export const SelectionCard = ({ id, title, subTitle, iconName, isSelected, onSelect, analyticAction }: Props) => {
  const { isMobile } = useBreak();

  const handleCardClick = (_e: React.SyntheticEvent, id: string) => {
    analytics.trackAction(analyticAction, {
      value: id,
    });
    onSelect && onSelect(id);
  };

  return (
    <Card
      id={id}
      testId={`selection-card-${id}`}
      isSelected={isSelected}
      selectedColor="primary.500"
      withSelectedColor
      onClick={handleCardClick}
    >
      <Flex direction={{ base: 'row', md: 'column' }} align="center" width={{ base: 'full', md: '16.4rem' }}>
        {iconName ? (
          <Icon
            name={iconName}
            size={isMobile ? IconSize.xl : IconSize.lg}
            color="black"
            marginBlockEnd={{ base: 'unset', md: 4 }}
          />
        ) : null}
        <Stack
          direction="column"
          marginInlineStart={{ base: 5, md: 'unset' }}
          spacing={{ base: 1, md: 'unset' }}
          align={{ base: 'unset', md: 'center' }}
        >
          <Box textStyle={{ base: 'body3Semi', md: 'body2Semi' }} color="black">
            <MIFormattedText label={title} />
          </Box>
          {subTitle ? (
            <Box
              textStyle={{ base: 'body3', md: 'body4' }}
              color="grey.700"
              textAlign={{ base: 'unset', md: 'center' }}
            >
              <MIFormattedText label={subTitle} />
            </Box>
          ) : null}
        </Stack>
      </Flex>
    </Card>
  );
};
