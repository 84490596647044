/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AmexCardEnum, CardResponse } from '@melio/spend-management-api-axios-client';
import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { DrawerCloseButton } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';
import Spacer from 'src/core/ds/spacer';
import { useModal } from 'src/helpers/react/useModal';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { CardDetailsPlaceholder, CardPlaceholder } from './cards';
import { CancelledFooter, DetailsFooter } from './footers';
import { Body, Header } from './styles';

export const CardDetails = ({
  card,
  onDeleteCard,
  onShowTransactions,
  loading,
}: {
  card: CardResponse;
  onDeleteCard: (cardId: string) => void;
  onShowTransactions: (card: CardResponse) => void;
  loading: boolean;
}) => {
  const [DeleteCard, showDeleteCard] = useModal(CommonDialog, {
    confirm: async () => {
      analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.DEACTIVATE_CARD_CONFIRM);
      onDeleteCard(card.cardId);
    },
    onDismiss: (reason) => {
      if (reason) {
        analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.DEACTIVATE_CARD_CANCEL);
      }
    },
    title: 'spendManagement.pages.dashboard.cardDetails.actions.deleteDialog.title',
    description: 'spendManagement.pages.dashboard.cardDetails.actions.deleteDialog.description',
    confirmText: 'spendManagement.pages.dashboard.cardDetails.actions.deleteDialog.confirm',
    cancelText: 'spendManagement.pages.dashboard.cardDetails.actions.deleteDialog.cancel',
    modalName: 'deleteCardDialog',
  });
  const isCancelled = card.status === AmexCardEnum.Cancelled;

  const handleClose = () => {
    analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.CLICK_CLOSE_X);
  };

  const onCardDelete = () => {
    analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.CLICK_TERMINATE_CARD);
    showDeleteCard();
  };

  const Footer = !!card && !isCancelled ? DetailsFooter : CancelledFooter;

  return (
    <>
      <Header flexDirection="column" minH="auto">
        <Flex justify="space-between" w="full">
          <Box textStyle="body1Semi">
            <MIFormattedText label="spendManagement.pages.dashboard.cardDetails.title" />
          </Box>
          <Spacer />
          <DrawerCloseButton onClick={handleClose} size="md" />
        </Flex>
      </Header>
      <Body>
        <Flex direction="column" w="full">
          <CardPlaceholder card={card} />
          <CardDetailsPlaceholder card={card} />
        </Flex>
      </Body>
      <Footer isLoading={loading} onSecondary={onCardDelete} onPrimary={() => onShowTransactions(card)} />
      {DeleteCard}
    </>
  );
};
