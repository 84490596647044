import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

type Props = {
  numOfInvoices: number;
  totalAmount: number;
  headerLabel: string;
  descriptionLabel: string;
};

const LeftHeaderText = ({ numOfInvoices, totalAmount, headerLabel, descriptionLabel }: Props) => (
  <ContainerText>
    <SubHeader>
      <MIFormattedText label={headerLabel} values={{ numOfInvoices }} />
    </SubHeader>
    <HeaderDescription>
      <MIFormattedText label={descriptionLabel} values={{ totalAmount }} />
    </HeaderDescription>
  </ContainerText>
);

export default LeftHeaderText;

const ContainerText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 1.6rem;
`;

const SubHeader = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.01rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const HeaderDescription = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  line-height: 1.8rem;
`;
