import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import { billLocations } from 'src/pages/bill/locations';
import { AddAchFlow } from 'src/pages/vendor/delivery-methods/add-ach-flow/AddAchFlow';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { FlowCustomizationsType } from '../types';

type Props = {
  orgId: number;
  vendorId: number;
};

export const AddAchFromResendUndepositedCheckFlowWrapper = ({ orgId, vendorId }: Props) => {
  const { SimpleTextFooter } = WizardElements;
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const vendorName = bill?.vendor?.companyName;

  const confirmPageUrl = generatePath(billLocations.pay.edit.confirm, {
    orgId,
    billId: bill.id,
    paymentId: payment.id,
  });

  const flowCustomizations: FlowCustomizationsType = {
    stepLayout: {
      hideHeader: true,
      title: isEmpty(vendorName)
        ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
        : 'vendors.deliveryMethods.bank.title',
      titleValues: { vendor: vendorName },
      nextLabel: 'vendors.deliveryMethods.bank.save',
      footer: (
        <SimpleTextFooter>
          <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
        </SimpleTextFooter>
      ),
      fullWidthCTA: true,
    },
  };

  return <AddAchFlow vendorId={vendorId} flowCustomizations={flowCustomizations} saveAndContinue={confirmPageUrl} />;
};
