import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { Role } from 'src/utils/consts';
import { UserRoleTag } from '../../../components/user/UserRoleTag';
import { RoleOption } from './types';

type Props = {
  currentRole: Role;
  roles: RoleOption[];
  disabled?: boolean;
  onRoleChange: (role: RoleOption) => void;
};

export const UserRoleDropdown = ({ currentRole, roles, disabled, onRoleChange }: Props) => (
  <Box position="relative">
    <Menu autoSelect={false} placement="bottom-end" computePositionOnMount>
      <MenuButton
        testId="user-role-dropdown-button"
        disabled={disabled}
        p={0}
        variant={ButtonVariants.tertiaryNaked}
        as={Button}
        rightIcon={<ChevronDownIcon />}
        size={ButtonSizes.sm}
      >
        <UserRoleTag userRole={currentRole} />
      </MenuButton>
      <MenuList>
        {roles.map((role) => (
          <MenuItem
            key={role.id}
            as={Button}
            boxSizing="border-box"
            width="100%"
            onClick={() => onRoleChange(role)}
            label={role.label}
            justifyContent="flex-start"
            color="black"
          />
        ))}
      </MenuList>
    </Menu>
  </Box>
);
