import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { AddNewDeliveryMethodPage } from 'src/pages/regular-batch-payments/components/deliveryMethod/AddNewDeliveryMethodPage';
import { BatchPaymentsPage } from './BatchPaymentsPage';
import BatchPaymentsLegalInfoPage from './components/wizard/BatchPaymentsLegalInfoPage';
import { BatchPaymentsSuccessPage } from './components/wizard/BatchPaymentsSuccess/BatchPaymentsSuccessPage';
import { batchPaymentsLocations } from './locations';

const BatchPaymentsRouter = () => (
  <Switch>
    <SmartRoute path={batchPaymentsLocations.base} exact>
      <BatchPaymentsPage />
    </SmartRoute>
    <SmartRoute path={batchPaymentsLocations.batchLegal} exact>
      <BatchPaymentsLegalInfoPage />
    </SmartRoute>
    <SmartRoute path={batchPaymentsLocations.success} exact>
      <BatchPaymentsSuccessPage />
    </SmartRoute>
    <SmartRoute path={batchPaymentsLocations.addDeliveryMethod} exact>
      <AddNewDeliveryMethodPage />
    </SmartRoute>
  </Switch>
);

export default BatchPaymentsRouter;
