const MAPPINGS = {
  '[INVOICES] PROUPDATE_SUCCESS': {
    type: 'success',
    msg: 'getPro.cards.actions.edit.invoice.saveSuccess',
  },
  '[INVOICES] PROUPDATE_FAILURE': {
    type: 'error',
    msg: 'getPro.cards.actions.edit.invoice.saveFailed',
  },
  '[PAYMENTREQUESTS] PROUPDATE_SUCCESS': {
    type: 'info',
    msg: 'getPro.cards.actions.edit.payment.saveSuccess',
  },
  '[PAYMENTREQUESTS] PROUPDATE_FAILURE': {
    type: 'error',
    msg: 'getPro.cards.actions.edit.payment.saveFailed',
  },

  '[INVOICES] PROMARKASPAID_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.markInvoiceAsPaid.success',
  },
  '[INVOICES] PROMARKASPAID_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.markInvoiceAsPaid.error',
  },
  '[PAYMENTREQUESTS] PROMARKASPAID_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.markRequestAsPaid.success',
  },
  '[PAYMENTREQUESTS] PROMARKASPAID_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.markRequestAsPaid.error',
  },
  '[GETPROPAYMENTS] PROMARKASUNPAID_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.markInvoiceAsUnpaid.success',
  },
  '[GETPROPAYMENTS] PROMARKASUNPAID_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.markInvoiceAsUnpaid.error',
  },

  '[INVOICES] PRODELETE_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.removeInvoice.success',
  },
  '[INVOICES] PRODELETE_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.removeInvoice.error',
  },
  '[PAYMENTREQUESTS] PRODELETE_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.deletePayment.success',
  },
  '[PAYMENTREQUESTS] PRODELETE_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.deletePayment.error',
  },
  '[GETPROPAYMENTS] PRODELETE_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.removePayment.success',
  },
  '[GETPROPAYMENTS] PRODELETE_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.removePayment.error',
  },

  '[PAYMENTREQUESTS] PROCANCEL_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.cancelPayment.success',
  },
  '[PAYMENTREQUESTS] PROCANCEL_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.cancelPayment.error',
  },

  '[PAYMENTREQUESTS] PRORESEND_SUCCESS': {
    type: 'success',
    msg: 'getPaid.share.success',
  },
  '[PAYMENTREQUESTS] PRORESEND_FAILURE': {
    type: 'error',
    msg: 'getPaid.share.error',
  },

  '[INVOICES] PROBATCHMARKASPAID_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.markAsPaidBatchInvoices.success',
  },
  '[INVOICES] PROBATCHMARKASPAID_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.markAsPaidBatchInvoices.error',
  },
  '[PAYMENTREQUESTS] PROBATCHMARKASPAID_SUCCESS': {
    type: 'info',
    msg: 'getPro.table.item.toasts.markAsPaidBatchRequest.success',
  },
  '[PAYMENTREQUESTS] PROBATCHMARKASPAID_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.markAsPaidBatchRequest.error',
  },
  '[INVOICES] PROBATCHSEND_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.sendBatch.error',
  },
  '[INVOICES] PROBATCHDELETE_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.removeBatchInvoices.error',
  },
  '[PAYMENTREQUESTS] PROBATCHDELETE_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.removeBatchRequests.error',
  },
  '[PAYMENTREQUESTS] PROBATCHCANCEL_FAILURE': {
    type: 'error',
    msg: 'getPro.table.item.toasts.cancelBatchRequests.error',
  },
  '[GETPROINPROGRESSPAYMENTS] PROCANCELPENDING_FAILURE': {
    type: 'error',
    msg: 'getPro.drawerTabs.toasts.cancelPendingPayment.error',
  },
};

export default MAPPINGS;
