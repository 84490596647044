import { MICard, MICardField, MICardTitle } from 'src/components/common/MICard';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import Flex from 'src/core/ds/flex';
import { ScannedInvoiceType } from 'src/modules/scanned-invoices/types';
import { BillAttachment } from 'src/pages/bill/components/BillAttachment';
import { useGetFileUrlsByFileId } from 'src/pages/bill/hooks/useGetFileUrlsByFileId';
import { ScreenMode } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { ScannedInvoiceNotification } from '../Notification/ScannedInvoiceNotification';
import { CardContainer } from './CardContainer';

type Props = {
  scannedInvoice: ScannedInvoiceType;
  eventPage: string;
  onClick: () => void;
};

export const ScannedInvoiceDetails = ({ scannedInvoice, eventPage, onClick }: Props) => {
  const { fileId, isMissingBillDetails } = scannedInvoice;
  const { fileStorageUrl, filePreviewUrls } = useGetFileUrlsByFileId(fileId?.toString(), scannedInvoice.id.toString());

  return (
    <MICard>
      <CardContainer>
        <Flex direction="column" gap={6}>
          <ScannedInvoiceNotification onClick={onClick} isMissingBillDetails={isMissingBillDetails} />
          <Flex justify="space-between">
            <Flex direction="column" flexGrow={3}>
              <Flex alignItems="center">
                <MICardTitle label="pay.scannedInvoice.details.title" />
              </Flex>
              <MICardField label="pay.scannedInvoice.details.vendorName">
                <MIFieldOrEmpty
                  value={scannedInvoice?.vendorName}
                  label="pay.scannedInvoice.details.vendorNameEmpty"
                  type="text"
                />
              </MICardField>
              <MICardField label="pay.scannedInvoice.details.amount">
                <MIFormattedCurrency
                  emptyLabel="pay.scannedInvoice.details.amountEmpty"
                  value={convertCurrencyToNumber(scannedInvoice?.amount)}
                  privateData
                />
              </MICardField>
              <MICardField label="pay.scannedInvoice.details.dueDate">
                <MIFieldOrEmpty
                  value={scannedInvoice?.dueDate}
                  label="pay.scannedInvoice.details.dueDateEmpty"
                  type="date"
                />
              </MICardField>
              <MICardField label="pay.scannedInvoice.details.invoiceNumber">
                <MIFieldOrEmpty
                  value={scannedInvoice?.invoiceNumber}
                  label="pay.scannedInvoice.details.invoiceNumberEmpty"
                  type="text"
                />
              </MICardField>
            </Flex>
            <Flex>
              <BillAttachment
                eventPage={eventPage}
                mode={ScreenMode.VIEW}
                fileStorageUrl={fileStorageUrl}
                filePreviewUrls={filePreviewUrls}
                onClick={onClick}
                withCounter
              />
            </Flex>
          </Flex>
        </Flex>
      </CardContainer>
    </MICard>
  );
};
