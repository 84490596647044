import { ReactElement } from 'react';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MILink } from 'src/components/common/MILink';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { addressRowStyle } from './styles';

type Props = {
  address: string | ReactElement[][];
  printName?: string;
  onEditAddress?: () => void;
  editLink?: boolean;
};

export const ManualAddressRow = ({ printName, address, editLink, onEditAddress }: Props) => (
  <Box>
    <Flex className={FULL_STORY_MASK_RULE_CLASS} __css={addressRowStyle}>
      {printName && (
        <>
          <Box fontWeight="bold">
            <MIFieldOrEmpty value={printName} label="" />
          </Box>
          <Box as="span" _after={{ content: "','", mr: '0.5rem' }} />
        </>
      )}
      {address}
    </Flex>
    {editLink && onEditAddress && (
      <Box>
        <MILink plain to={onEditAddress} label="manualAddress.edit" />
      </Box>
    )}
  </Box>
);
