import { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { renderDayContents } from 'src/components/common/datepicker/PaymentDatePicker';
import Box from 'src/core/ds/box/Box';
import Colors from 'src/core/theme/foundations/colors';
import { spacing } from 'src/core/theme/foundations/spacing';
import { PAGE_EVENT } from 'src/pages/regular-batch-payments/analytics/event-mapping';
import { ButtonBatchDatePicker } from 'src/pages/regular-batch-payments/components/header/components/ButtonBatchDatePicker';
import { analytics } from 'src/services/analytics';
import { convertDateToCalendarDate, isBusinessDay } from 'src/utils/dates';

type Props = {
  onChange: (date: string | null) => void;
  disableButton: boolean;
  scheduledDate: Date;
  minScheduledDate: Date;
};

export const BatchDatePicker = ({ onChange, disableButton, scheduledDate, minScheduledDate }: Props) => {
  const getFilteredDate = (date: Date) => isBusinessDay(convertDateToCalendarDate(date));

  const handleChange = useCallback(
    (date: Date | null) => {
      const dateString = date?.toISOString() || null;
      onChange(dateString);
      analytics.track(PAGE_EVENT, 'update-deduction-date', { newDate: dateString });
    },
    [onChange]
  );

  return (
    <Box sx={DatePickerOverride}>
      <DatePicker
        selected={new Date(scheduledDate) || new Date(minScheduledDate)}
        id="batch-payment-picker-date"
        minDate={new Date(minScheduledDate)}
        filterDate={getFilteredDate}
        renderDayContents={renderDayContents}
        onChange={handleChange}
        customInput={<ButtonBatchDatePicker value={scheduledDate} disableButton={disableButton} />}
      />
    </Box>
  );
};

const DatePickerOverride = {
  '.react-datepicker__day': {
    color: Colors.black,
  },
  '.react-datepicker__day--disabled': {
    color: '#ccc',
  },
  '.react-datepicker-popper': {
    marginTop: spacing['2'],
  },
  '.react-datepicker__day--selected': {
    height: ' 2.6rem',
    width: '2.6rem',
    lineHeight: '2.6rem',
  },
  '.react-datepicker__day--keyboard-selected': {
    background: Colors.white,
  },
};
