import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import { devices } from 'src/theme/appDevices';

type Props = {
  label: string;
  icon: string;
  fee: number;
  isAbsorbedFee: boolean;
};

const FundingSourceBox = ({ label, icon, fee, isAbsorbedFee }: Props) => {
  const isAbsorbedFeeDisplay = isAbsorbedFee && fee > 0;

  return (
    <Container>
      <Icon className={icon} />
      <FundingSourceType>
        <MIFormattedText label={label} />
      </FundingSourceType>
      <FeeContainer>
        {fee === 0 ? (
          <MIFormattedText label="guests.register.fee.free" />
        ) : (
          <Fee>
            <FeePercentage isAbsorbedFeeDisplay={isAbsorbedFeeDisplay}>
              <MIFormattedText label="guests.register.fee.percentage" values={{ percentage: fee }} />
            </FeePercentage>
            {isAbsorbedFeeDisplay && <Badge label="guests.register.fee.freeTag" color={BadgeColors.SUCCESS} />}
          </Fee>
        )}
      </FeeContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 14rem;
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.medium};

  @media ${devices.mobile}, ${devices.phablet} {
    ${(props) => props.theme.text.fontType.small};
  }
`;

const Icon = styled.i`
  font-size: 3.2rem;
  margin-top: 2.4rem;
  margin-bottom: 1.6rem;
`;

const FundingSourceType = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.2rem;
`;

const FeeContainer = styled.div`
  font-weight: ${(props) => props.theme.text.weight.regular};
  margin-bottom: 2rem;
`;

const Fee = styled.div`
  display: flex;
  gap: 0.6rem;
  align-items: center;
`;

const FeePercentage = styled.div<{ isAbsorbedFeeDisplay?: boolean }>`
  text-decoration: ${(props) => (props.isAbsorbedFeeDisplay ? 'line-through' : 'none')};
  color: ${(props) => (props.isAbsorbedFeeDisplay ? '#99999C' : props.theme.text.color.main)};
`;

export default FundingSourceBox;
