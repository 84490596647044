import * as dateFns from 'date-fns';
import { forwardRef } from 'react';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Menu, MenuButton } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { DateFormats } from 'src/utils/date-fns';

type Props = {
  value: Date;
  disableButton: boolean;
};
export const ButtonBatchDatePicker = forwardRef(
  ({ value, onClick, disableButton }: Props & { onClick?: () => void }, ref: any) => (
    <Menu placement="bottom-end" autoSelect={false}>
      <Tooltip
        label={disableButton ? <MIFormattedText label="regularBatchPayments.BatchSettings.tooltip" /> : null}
        placement="top"
        shouldWrapChildren
        closeOnClick={false}
      >
        <Box>
          <MenuButton
            data-testid="batch-payment-picker-button"
            disabled={disableButton}
            ref={ref}
            as={Button}
            size={ButtonSizes.sm}
            variant={ButtonVariants.tertiary}
            textStyle="body4Semi"
            onClick={onClick}
            rightIcon={<ChevronDownIcon width={8} height={8} />}
            leftIcon={<Icon name={IconNames.calendar} size={IconSize.s} />}
            paddingX={3}
          >
            <MIFormattedDate date={value} />
            {dateFns.format(new Date(), DateFormats.dayMonthYearDateSlash) === value.toString() ? (
              <Box as="span" ml={1}>
                <MIFormattedText label="regularBatchPayments.BatchSettings.today" />
              </Box>
            ) : null}
          </MenuButton>
        </Box>
      </Tooltip>
    </Menu>
  )
);
