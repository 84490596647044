import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Option } from 'src/components/common/MISingleSelect';
import { useConnectedAccountingPlatform } from 'src/hooks';
import { useMounted } from 'src/hooks/useMounted';
import { organizationsApi } from 'src/modules/organizations/api';
import { accountCategoryToAccountCategoryOption } from 'src/pages/shared';
import { getOrgId } from 'src/redux/user/selectors';
import { capture } from 'src/utils/error-tracking';

export const useFetchBillAccountCategoryOptions = () => {
  const orgId = useSelector(getOrgId);
  const { isConnected } = useConnectedAccountingPlatform();
  const { isMounted } = useMounted();
  const [accountCategoriesOptions, setBillAccountsOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (isConnected) {
      loadAccounts();
    }
  }, [isConnected]);

  const loadAccounts = async () => {
    try {
      const result = await organizationsApi.getAccountsForBill(orgId);

      if (result.accounts && isMounted()) {
        const accountCategoriesOptions = result.accounts.map(accountCategoryToAccountCategoryOption);

        setBillAccountsOptions(accountCategoriesOptions);
      }
    } catch (e: any) {
      capture(e, { extra: 'getAccountsForBill' });
    }
  };

  return accountCategoriesOptions;
};
