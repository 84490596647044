import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  EditElement,
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Stack } from 'src/core/ds/stack';
import { Installment } from 'src/utils/types';

type Props = {
  onEdit?: () => void;
  installments: Installment[];
  isEditDisabled: boolean;
  canEdit?: boolean;
};
export const InstallmentsFundingType = ({ onEdit, isEditDisabled, installments, canEdit = false }: Props) => (
  <PaymentActivityContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <PaymentIcon>
          <Icon name={IconNames.installments} size={IconSize.lg} />
        </PaymentIcon>
        <PaymentTextContainer>
          <PaymentMethodContainer>
            <PaymentMethodText data-testid="installments-funding-type-text">
              <Stack direction="column">
                <Box textStyle="body2" color="black">
                  <MIFormattedText
                    label="payBillPaymentActivity.fundingSource.financingLabel"
                    values={{ installmentsAmount: installments.length }}
                  />
                </Box>
                <Box textStyle="body4" color="grey.600">
                  <MIFormattedText label="payBillPaymentActivity.fundingSource.financingCreditKeyProvider" />
                </Box>
              </Stack>
            </PaymentMethodText>
          </PaymentMethodContainer>
        </PaymentTextContainer>
      </PaymentInfoContainer>
      {canEdit && <EditElement testId="edit-payment-method" onEdit={onEdit} isDisabled={isEditDisabled} />}
    </PaymentActivityMainContainer>
  </PaymentActivityContainer>
);
