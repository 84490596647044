import { MouseEvent as ReactMouseEvent, useState } from 'react';
import { ApprovalWorkflowModal } from 'src/pages/settings/components/ApprovalWorkflows/ApprovalWorkflowModal';
import { ApprovalWorkflowUserType } from 'src/pages/settings/components/ApprovalWorkflows/ApprovalWorkflowModal/types';
import { ApprovalWorkflowType } from 'src/utils/types';
import { ConfirmApprovalWorkflowEditModal } from './components/ConfirmApprovalWorkflowEditModal';

export type Props = {
  companyName: string;
  onSubmit: (value: ApprovalWorkflowType) => void;
  dismiss: (event: ReactMouseEvent | null) => void;
  orgUsers: ApprovalWorkflowUserType[];
  orgId: number;
  dbWorkflow?: ApprovalWorkflowType;
  maxThreshold?: number;
};

type ModalMode = 'edit' | 'confirm';

export const EditApprovalWorkflowModal = ({
  dismiss,
  onSubmit,
  dbWorkflow,
  companyName,
  orgUsers,
  orgId,
  maxThreshold,
}: Props) => {
  const [mode, setMode] = useState<ModalMode>('edit');
  const [updatedWorkflow, setUpdatedWorkflow] = useState<ApprovalWorkflowType | undefined>();

  const handleSaveWorkflowClick = (newWorkflow: ApprovalWorkflowType) => {
    setMode('confirm');
    setUpdatedWorkflow(newWorkflow);
  };

  const handleConfirmClick = async (e: ReactMouseEvent) => {
    updatedWorkflow && (await onSubmit(updatedWorkflow));
    dismiss(e);
  };

  return mode === 'edit' ? (
    <ApprovalWorkflowModal
      onSubmit={handleSaveWorkflowClick}
      dismiss={dismiss}
      companyName={companyName}
      orgId={orgId}
      orgUsers={orgUsers}
      dbWorkflow={dbWorkflow}
      maxThreshold={maxThreshold}
    />
  ) : (
    mode === 'confirm' && (
      <ConfirmApprovalWorkflowEditModal
        workflowId={updatedWorkflow?.id}
        dismiss={dismiss}
        onSubmit={handleConfirmClick}
      />
    )
  );
};
