import React, { useState } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues, SourceInfo } from 'react-number-format';
import { IconNames } from 'src/core/ds/icon';
import { IconInput } from 'src/core/ds/iconInput';
import { Input, InputGroup, InputProps, InputRightElement } from 'src/core/ds/input';

export type PasswordMaskInputProps = Omit<NumberFormatProps, 'size' | 'onChange'> &
  Omit<InputProps, 'onChange'> & {
    onChange: (args: {
      value: string;
      floatValue: number | undefined;
      formattedValue: string;
      event: React.SyntheticEvent<HTMLInputElement>;
    }) => void;
  };

export const PasswordMaskInput = ({
  id,
  testId,
  onChange,
  iconTestId,
  isViewOnly = false,
  isDisabled = false,
  isReadOnly = false,
  shouldShowValue = false,
  size = 'md',
  ...rest
}: PasswordMaskInputProps) => {
  const [showValue, setShowValue] = useState(shouldShowValue);
  const [isFocus, setIsFocus] = useState(false);

  const shouldHideIcon = isReadOnly || isViewOnly;
  const inputType = showValue && !isViewOnly ? 'text' : 'password';

  const handleClick = () => !isDisabled && setShowValue(!showValue);

  const handleFocus = () => setIsFocus(true);

  const handleBlur = () => setIsFocus(false);

  const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    onChange({
      value: values.value,
      floatValue: values.floatValue,
      formattedValue: values.formattedValue,
      event: sourceInfo.event,
    });
  };

  return (
    <InputGroup>
      <NumberFormat
        customInput={Input}
        type={inputType}
        id={id}
        testId={testId}
        onValueChange={handleChange}
        {...(rest as any)}
        isRequired={false}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {!shouldHideIcon && (
        <InputRightElement
          data-testid="input-password-show"
          onClick={handleClick}
          cursor={isDisabled ? 'default' : 'pointer'}
          h="full"
        >
          <IconInput
            name={showValue ? IconNames.eyeHide : IconNames.eyeShow}
            size={size}
            isDisabled={isDisabled}
            isFocus={isFocus}
            testId={iconTestId ? `${iconTestId}-show` : null}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
