import { tabsAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  PartsStyleInterpolation,
  PartsStyleObject,
  SystemStyleFunction,
} from '@chakra-ui/theme-tools';

const baseStyleRoot: SystemStyleFunction = (props) => {
  const { orientation } = props;

  return {
    display: 'flex',
    flexDirection: orientation === 'vertical' ? 'column' : 'row',
  };
};

const baseStyleTab: SystemStyleFunction = () => ({
  position: 'relative',
  cursor: 'pointer',
  h: '2.4rem',
  color: 'grey.600',
  bg: 'transparent',
  apply: 'textStyles.body2Semi',
  _focus: {
    zIndex: 1,
  },
  me: 0,
  _notLast: {
    me: 4,
  },
});

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  root: baseStyleRoot(props),
  tab: baseStyleTab(props),
});

const variantUnstyled: PartsStyleObject<typeof parts> = {
  tab: {
    border: 'none',
    borderBottom: '2px',
    borderColor: 'transparent',
    padding: 0,
    _selected: {
      borderBottom: '2px',
      borderColor: 'black',
      color: 'black',
    },
    _hover: { color: 'black' },
  },
};

const variants: Record<string, PartsStyleInterpolation<typeof parts>> = {
  unstyled: variantUnstyled,
};

const defaultProps = {
  variant: 'unstyled',
};

export default {
  parts: parts.keys,
  baseStyle,
  variants,
  defaultProps,
};
