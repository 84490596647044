import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { ButtonsRowPosition, DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  digits: string;
  dismiss: () => void;
};

export const MicroDepositsNotEligibleDialog = ({ dismiss, digits }: Props) => (
  <Dialog
    type={DialogType.CONFIRM}
    variant={DialogVariants.WARNING}
    title="settings.microDeposits.depositsNotEligible.title"
    subtitle="settings.microDeposits.depositsNotEligible.subtitle"
    subtitleValues={{ digits }}
    okButtonText="settings.microDeposits.depositsNotEligible.gotIt"
    onOkAction={dismiss}
    buttonsRowPosition={ButtonsRowPosition.RIGHT}
    hideClose
    minHeight="44rem"
  />
);
