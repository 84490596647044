import { ButtonVariant } from 'src/utils/consts';

export enum ButtonVariants {
  tertiaryNaked = 'tertiaryNaked',
  secondary = 'secondary',
  primary = 'primary',
  error = 'error',
  success = 'success',
  tertiary = 'tertiary',
  unstyled = 'unstyled',
  quickbooks = 'quickbooks',
  intuit = 'intuit',
  freshbooks = 'freshbooks',
}

export enum ButtonSizes {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export const MAP_LOADER_COLOR_TO_VARIANT = {
  [ButtonVariants.tertiaryNaked]: 'primary',
  [ButtonVariants.secondary]: 'primary',
  [ButtonVariants.primary]: 'white',
  [ButtonVariants.error]: 'white',
  [ButtonVariants.success]: 'white',
  [ButtonVariants.tertiary]: 'dark',
  [ButtonVariants.unstyled]: undefined,
};

export const oldToNewButtonVariantMap: Record<ButtonVariant, ButtonVariants> = {
  primary: ButtonVariants.primary,
  pay: ButtonVariants.success,
  secondary: ButtonVariants.secondary,
  tertiary: ButtonVariants.tertiary,
  destructive: ButtonVariants.tertiary,
  undo: ButtonVariants.tertiary,
  intuit: ButtonVariants.success,
  remove: ButtonVariants.tertiaryNaked,
  cancel: ButtonVariants.tertiaryNaked,
  empty: ButtonVariants.tertiaryNaked,
};
