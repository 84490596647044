import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { EmptyList } from 'src/components/list/EmptyList';
import { useApi } from 'src/hoc/useApi';
import errorScreen from 'src/images/general/error-screen.svg';
import { timelineApi } from 'src/modules/get-pro/api';
import { InvoiceType } from 'src/modules/invoices/types';
import { EventEntity } from 'src/pages/get-pro/consts';
import { TimelineEventType } from 'src/pages/get-pro/types';
import { getOrgId } from 'src/redux/user/selectors';
import { useGetAccountingSoftwareName } from '../../../hooks/useGetAccountingSoftwareName';
import { filterEvents, getEventAdditionalInfo, getEventTitle } from '../utils';
import { TimelineContainer } from './timeline/TimelineContainer';
import { TimelineItemType } from './timeline/TimelineItem';

type Props = {
  entity: InvoiceType;
  entityType: EventEntity;
  entityId: string;
};

export const TimelineDrawer = ({ entity, entityType, entityId }: Props) => {
  const orgId = useSelector(getOrgId);
  const [timelineItems, setTimelineItems] = useState<TimelineItemType[]>([]);
  const accountingSoftwareName = useGetAccountingSoftwareName(entity.createOrigin);

  const {
    onApiCall: getTimelineEvents,
    result: timelineEventsResult,
    loading: isTimelineEventsLoading,
    error,
  } = useApi({
    api: timelineApi.getTimeline,
    throwError: false,
    initialLoading: true,
  });

  useEffect(() => {
    getTimelineEvents({
      orgId,
      entityId,
      entityType,
    });
  }, [getTimelineEvents, entity, entityType, orgId]);

  useEffect(() => {
    if (timelineEventsResult) {
      const { timelineEvents } = timelineEventsResult;
      const filteredTimelineEvents = timelineEvents.filter((event: TimelineEventType) => filterEvents(event, entity));
      const items = filteredTimelineEvents.map(({ eventName, timestamp, metadata }, index) => ({
        title: getEventTitle({
          eventName,
          entity,
          metadata,
          accountingSoftwareName,
        }),
        date: timestamp,
        additionalInfo: getEventAdditionalInfo(eventName, entity),
        isActive: index === 0,
      }));
      setTimelineItems(items);
    }
  }, [timelineEventsResult]);

  if (isTimelineEventsLoading) {
    return <AreaLoader />;
  }

  if (error) {
    return (
      <EmptyList
        textTitle="getPro.drawerTabs.timeline.emptyState.error.title"
        text="getPro.drawerTabs.timeline.emptyState.error.subTitle"
        logo={errorScreen}
      />
    );
  }

  return <TimelineContainer items={timelineItems} />;
};
