import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStaleAndLoad } from 'src/helpers/redux/useStaleAndLoad';
import customersStore from 'src/modules/customers/customers-store';
import { getOrgId } from 'src/redux/user/selectors';

const useGetCustomersList = () => {
  const orgId = useSelector(getOrgId);
  const customerListParams = useMemo(() => ({ orgId }), [orgId]);
  const [customers] = useStaleAndLoad(customersStore, 'list', customerListParams);

  return [customers];
};

export default useGetCustomersList;
