import { useCallback, useState } from 'react';
import { MIAddressAutocompleteSmartyStreets } from 'src/components/common/MIAddressAutocompleteSmartyStreets';
import Flex from 'src/core/ds/flex';
import { VendorAddressType } from 'src/modules/vendors/types';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { ModelView } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { NotificationVariant } from 'src/utils/consts';
import { AddressType } from 'src/utils/types';
import { AmexVendorAddressExtraFields } from './AmexVendorAddressExtraFields';

const eventPage = 'amex-address';

type Props = {
  formModelView: ModelView<VendorAddressType>;
  isAllFieldsVisible: boolean;
  showAllFields: () => void;
  analyticsProps: {
    billId?: string | null;
    vendorId: string;
    isBatch: boolean;
  };
};

export const AmexVendorAddressForm = ({ formModelView, isAllFieldsVisible, showAllFields, analyticsProps }: Props) => {
  const onChangeSuggestedAddress = async (value: AddressType | null) => {
    formModelView.setModelState((prevState) => ({
      ...prevState,
      ...value,
    }));
    formModelView.setValidationErrors({});
    showAllFields();
  };
  const [areAddressSuggestionsFailedToLoad, setAreAddressSuggestionsFailedToLoad] = useState(false);
  const [addressFieldHintVisible, setAddressFieldHintVisible] = useState(true);

  const onSuggestionsLoadError = useCallback(() => {
    if (!areAddressSuggestionsFailedToLoad) {
      setAreAddressSuggestionsFailedToLoad(true);
      pushNotification({
        type: NotificationVariant.INFO,
        msg: 'bills.pay.fundingSource.amexVendorAddressModal.suggestionsLoadFailedWarning',
      });
      setAddressFieldHintVisible(false);
    }

    showAllFields();
  }, [areAddressSuggestionsFailedToLoad, showAllFields]);

  const onCantFindSuggestionsOptionClick = () => {
    setAddressFieldHintVisible(false);
    showAllFields();
    analytics.trackAction('amex-vendor-address-no-suggestions', analyticsProps);
  };

  return (
    <Flex direction="column" gap={10}>
      <FormRow>
        <MIAddressAutocompleteSmartyStreets
          id="addressLine1"
          label="bills.pay.fundingSource.amexVendorAddressModal.addressLine1.label"
          placeholder="bills.pay.fundingSource.amexVendorAddressModal.addressLine1.placeholder"
          model={formModelView.addressLine1}
          required
          autoFocus
          autocomplete="nope"
          onSelected={onChangeSuggestedAddress}
          eventPage={eventPage}
          privateData
          hint={
            addressFieldHintVisible ? 'bills.pay.fundingSource.amexVendorAddressModal.addressLine1.hint' : undefined
          }
          leftSideIcon="search-magnifier-icon"
          top="5.5rem"
          onSuggestionsLoadError={onSuggestionsLoadError}
          cantFindSuggestionsText="form.addressAutocomplete.cantFindVendorAddress"
          onCantFindSuggestionsOptionClick={onCantFindSuggestionsOptionClick}
        />
      </FormRow>
      {isAllFieldsVisible ? <AmexVendorAddressExtraFields formModelView={formModelView} /> : null}
    </Flex>
  );
};
