import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { authApi } from 'src/modules/auth/api';
import { analytics } from 'src/services/analytics';
import { withNavigator } from '../../../hoc';
import { initUserAction } from '../../../redux/user/actions';
import { CompanyInfoType, UserContextType } from '../../../utils/types';
import { ErrorPage } from '../../general/components/ErrorPage';

const STATUS = {
  PENDING: 0,
  GO: 1,
  FAILED: 2,
};

type MapDispatchToProps = {
  initUser: (user: UserContextType, isLoggedInAs: boolean) => Promise<void | { companyInfo: CompanyInfoType }>;
};

type paramKeys = 'token';

type Props = {
  params: Record<paramKeys, string>;
} & MapDispatchToProps;

type State = {
  status: number;
};

class LoginTokenRedirect extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      status: STATUS.PENDING,
    };
  }

  componentDidMount() {
    const { token } = this.props.params;

    authApi
      .loginWithToken(token)
      .then(({ user }) => {
        this.props
          .initUser(user, true)
          .then(() => {
            analytics.identify(user, undefined, { isLoggedInAs: true });
            this.setState({ status: STATUS.GO });
          })
          .catch(() => this.setState({ status: STATUS.FAILED }));
      })
      .catch(() => this.setState({ status: STATUS.FAILED }));
  }

  render() {
    const { status } = this.state;

    if (status === STATUS.GO) {
      return <Redirect to="/" />;
    } else if (status === STATUS.FAILED) {
      return <ErrorPage />;
    }

    return <div>Please wait...</div>;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): MapDispatchToProps => ({
  initUser(user: UserContextType, isLoggedInAs: boolean) {
    return new Promise((resolve, reject) => {
      dispatch(initUserAction(user, isLoggedInAs, resolve, reject));
    });
  },
});

const LoginTokenRedirectConnected = compose(withNavigator(), connect(null, mapDispatchToProps))(LoginTokenRedirect);

export { LoginTokenRedirectConnected as LoginTokenRedirect };
