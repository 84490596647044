import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { joinOrganizationRequestsApi } from 'src/modules/join-organization-requests/api';
import { name } from './../consts';

export const validateRequestSlice = createApiCallSlice({
  name: generateSliceName(name, 'validate_request'),
  api: joinOrganizationRequestsApi.validate,
  initialState: {
    validation: {},
    byId: {},
    byToken: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.validation[action.payload.token] = {
        loading: true,
        error: null,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      delete state.validation[action.meta.identifier.token];
      state.byToken[action.meta.identifier.token] = action.payload.joinOrganizationRequest.id;
      state.byId[action.payload.joinOrganizationRequest.id] = action.payload.joinOrganizationRequest;
      state.byId[action.payload.joinOrganizationRequest.id].organizationsToJoin = action.payload.organizationsToJoin;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation[action.meta.identifier.token] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    status: (token) => (state) => state[name].validation[token],
    byToken: (token) => (state) => state[name].byId[state[name].byToken[token]],
  },
});
