import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { MIEnhancedDropDown } from 'src/components/common/DropDown/MIEnhancedDropDown';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore from 'src/modules/organizations/organizations-store';
import { AccountingSoftwareCards } from 'src/pages/get-paid/create/connectAccountingSoftware/components/AccountingSoftwareCards';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { FormRow } from 'src/ui/form/FormElements';
import { AccountingSoftware } from 'src/utils/consts';
import { PageProps } from '../CreateInvoiceRouter';
import { useGetAccountingSoftwareIntegrationOptions } from './hooks/useGetAccountingSoftwareIntegrationOptions';

type Props = {
  onPrev: () => void;
} & PageProps;

export const ConnectUnsupportedAccountingSoftwarePage = ({ onExit, onPrev }: Props) => {
  const orgId = useSelector(getOrgId);
  const [historyPush] = useHistoryWithOrgId();
  const organizationActions = useStoreActions(organizationStore);
  const [selectedAccountingSoftwareCard, setSelectedAccountingSoftwareCard] = useState<
    AccountingSoftware | undefined
  >();
  const [otherAccountingSoftware, setOtherAccountingSoftware] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { accountingSoftwareUnsupportedOptions } = useGetAccountingSoftwareIntegrationOptions();
  const {
    cardOptions: accountingSoftwareCardOptions,
    dropdownOptions: accountingSoftwareDropdownOptions,
  } = accountingSoftwareUnsupportedOptions;

  const onAccountingSoftwareInputChange = (res) => {
    const { value: accountingSoftware } = res;
    setOtherAccountingSoftware(accountingSoftware);

    if (accountingSoftware) {
      setSelectedAccountingSoftwareCard(undefined);
    }
  };

  const onSelectAccountingSoftware = (accountingSoftware: AccountingSoftware) => {
    setSelectedAccountingSoftwareCard(accountingSoftware);
    setOtherAccountingSoftware('');
  };

  const onSuccess = (accountingSoftware: string) => {
    historyPush({
      path: getPaidLocations.create.connectAccountingSoftware.unsupportedAccountingSoftware.success,
      state: {
        accountingSoftware,
      },
    });
  };

  const onSubmit = async () => {
    const accountingSoftware = selectedAccountingSoftwareCard || otherAccountingSoftware;
    try {
      setIsLoading(true);
      await organizationActions.updateOrganizationPreference({
        orgId,
        key: 'selectedAccountingSoftware',
        value: accountingSoftware,
      });
      onSuccess(accountingSoftware);
    } catch {
      setIsLoading(false);
    }
  };

  return (
    <StepLayoutPage
      relativeStep={0}
      goExit={onExit}
      onPrev={onPrev}
      title="getPaid.new.create.connect.unsupportedAccountingPlatform.title"
      innerSize={75}
      onSubmit={onSubmit}
      onSecondaryActionButtonClick={onExit}
      secondaryActionButtonLabel="general.cancel"
      isNextDisabled={!(otherAccountingSoftware || selectedAccountingSoftwareCard)}
      isLoading={isLoading}
    >
      <Flex direction="column" alignItems="center">
        <AccountingSoftwareCards
          options={accountingSoftwareCardOptions}
          selectedAccountingSoftware={selectedAccountingSoftwareCard}
          onSelectAccountingSoftware={onSelectAccountingSoftware}
        />
        <Box mt={10} maxWidth="45.8rem" as={FormRow} mb="-3rem">
          <MIEnhancedDropDown
            id="unsupportedAccountingSoftware"
            label="getPaid.new.create.connect.unsupportedAccountingPlatform.form.label"
            placeholder="getPaid.new.create.connect.unsupportedAccountingPlatform.form.placeholder"
            createLabel="getPaid.new.create.connect.unsupportedAccountingPlatform.form.addInSelect"
            allowCustom
            value={otherAccountingSoftware}
            clearSelectedValue={!otherAccountingSoftware}
            onChange={onAccountingSoftwareInputChange}
            options={accountingSoftwareDropdownOptions}
            required
          />
        </Box>
      </Flex>
    </StepLayoutPage>
  );
};
