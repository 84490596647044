import { deleteRequest, fetchRequest, postRequest } from 'src/services/api/api';
import { FileUrlsPayloadType, VendorType } from 'src/utils/types';
import config from '../../config';

const apiBaseUrl = config.server.baseUrl;

export function baseURL(url) {
  if (!url) {
    return '';
  }

  return url.startsWith('/') ? `${apiBaseUrl}/api/${url}` : url;
}

export const filesApi = {
  uploadFile(orgId: number, file: File, conf = {}) {
    const url = `/orgs/${orgId}/files`;

    const data = new FormData();
    data.append('file', file);

    return postRequest(url, data, conf);
  },

  uploadCompanyLogoFile(orgId: number, file, conf = {}) {
    const url = `/orgs/${orgId}/files/company-logo`;

    const data = new FormData();
    data.append('file', file);

    return postRequest(url, data, conf);
  },

  uploadProfilePicture(orgId: number, file, conf = {}) {
    const url = `/orgs/${orgId}/files/user-logo`;

    const data = new FormData();
    data.append('file', file);

    return postRequest(url, data, conf);
  },

  deleteProfilePicture(orgId: number, fileId: number) {
    const url = `/orgs/${orgId}/files/${fileId}`;

    return deleteRequest(url);
  },

  billDetails(
    orgId: number,
    id: number,
    conf = {}
  ): Promise<{
    extract: {
      dueDate?: string;
      totalAmount?: string;
      invoiceNumber?: string;
      vendorMatchedName?: string;
      matchedVendor?: VendorType;
      vendorName?: string;
    };
  }> {
    const url = `/orgs/${orgId}/files/${id}/bill-details`;

    return fetchRequest(url, null, { isBypassThrowError: true }, conf);
  },

  fetchFileUrls(orgId: number, id: string | number): Promise<FileUrlsPayloadType> {
    const url = `/orgs/${orgId}/files/${id}`;

    return fetchRequest(url).then((result) => {
      const fileStorageUrl = baseURL(result.fileStorageUrl);
      const filePreviewUrls = result.filePreviewUrls.map((filePreviewUrl) => baseURL(filePreviewUrl));

      return {
        fileStorageUrl,
        filePreviewUrls,
      };
    });
  },

  fetchFileUrlsByPaymentRequest(orgId: number, fileId: string, requestId?: string): Promise<FileUrlsPayloadType> {
    const url = `/orgs/${orgId}/files/${fileId}/by-request/${requestId}`;

    return fetchRequest(url).then((result) => {
      const fileStorageUrl = baseURL(result.fileStorageUrl);
      const filePreviewUrls = result.filePreviewUrls.map((filePreviewUrl) => baseURL(filePreviewUrl));

      return {
        fileStorageUrl,
        filePreviewUrls,
      };
    });
  },

  getLogoByVendorHandle(link) {
    const url = `/me/${link}/logo`;

    return fetchRequest(url, null, { isBypassThrowError: true })
      .then((result) => {
        const fileStorageUrl = baseURL(result.filePreviewUrls[0]);

        return {
          fileStorageUrl,
        };
      })
      .catch(() => ({ fileStorageUrl: '' }));
  },
};
