import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import { AmountCell } from 'src/components/shared/tables/PaymentsTable/components/AmountCell';
import { CustomerCell } from 'src/components/shared/tables/PaymentsTable/components/CustomerCell';
import { DeliveryDateCell } from 'src/components/shared/tables/PaymentsTable/components/DeliveryDateCell';
import { HeaderCell } from 'src/components/shared/tables/PaymentsTable/components/HeaderCell';
import { InvoiceNumberCell } from 'src/components/shared/tables/PaymentsTable/components/InvoiceNumberCell';
import { StatusCell } from 'src/components/shared/tables/PaymentsTable/components/StatusCell';
import { useApi } from 'src/hoc/useApi';
import { paymentsInApi } from 'src/modules/msn-portal-payments-in/api';
import { MSNDataTableRowType } from 'src/pages/msn-portal/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { EVENT_PAGE } from '../consts';
import { mapPaymentsInListToTableRows } from '../utils';
import { DataTable } from './data-table/DataTable';
import { ReceivingMethodCell } from './ReceivingMethodCell';

const columns: Column<MSNDataTableRowType>[] = [
  {
    Header: <HeaderCell label="incomingPayments.table.columns.customer.label" />,
    accessor: 'customer',
    Cell: CustomerCell,
    width: 0.15,
  },
  {
    Header: <HeaderCell label="incomingPayments.table.columns.invoiceNumber.label" />,
    accessor: 'invoiceNumber',
    Cell: InvoiceNumberCell,
    width: 0.15,
  },
  {
    Header: <HeaderCell label="incomingPayments.table.columns.status.label" />,
    accessor: 'status',
    Cell: StatusCell,
    width: 0.2,
  },
  {
    Header: <HeaderCell label="incomingPayments.table.columns.deliveryDate.label" />,
    accessor: 'deliveryDate',
    Cell: DeliveryDateCell,
    width: 0.15,
  },
  {
    Header: <HeaderCell label="incomingPayments.table.columns.receivingMethod.label" />,
    accessor: 'deliveryMethod',
    Cell: ReceivingMethodCell,
    width: 0.2,
  },
  {
    Header: <HeaderCell label="incomingPayments.table.columns.amount.label" />,
    accessor: 'amount',
    Cell: AmountCell,
    width: 0.15,
    isNumeric: true,
  },
];

export const MSNTable = ({ onOpenDrawer }) => {
  const orgId = useSelector(getOrgId);

  const { onApiCall: onFetchPaymentsInList, loading, result } = useApi({ api: paymentsInApi.fetch });

  const { items, totalItems } = result || {};

  const getPaymentsInList = useCallback(
    async (filters) => {
      try {
        await onFetchPaymentsInList({ orgId, filters }).then(({ totalItems }) => {
          analytics.track(EVENT_PAGE, 'incoming-payments-loaded', { totalPaymentsAmount: totalItems });
        });
      } catch (error: any) {
        capture(error);
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'incomingPayments.errors.paymentsListLoadingError',
        });
      }
    },
    [onFetchPaymentsInList, orgId]
  );

  const data = useMemo(() => (items || []).map(mapPaymentsInListToTableRows), [items]);

  return (
    <DataTable<MSNDataTableRowType>
      data={data}
      columns={columns}
      loading={loading}
      totalItems={totalItems}
      onOpenDrawer={onOpenDrawer}
      getPaymentsInList={getPaymentsInList}
    />
  );
};
