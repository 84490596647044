/* eslint-disable no-console */
import { featureFlags } from '@melio/shared-web';
import cookies from 'js-cookie';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import last from 'lodash/last';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import omitBy from 'lodash/omitBy';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import slice from 'lodash/slice';
import template from 'lodash/template';
import uniq from 'lodash/uniq';
import { userPrivacyApi } from 'src/analytics/api/user-privacy-api';
import { appLayout } from 'src/hoc';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { organizationsApi } from 'src/modules/organizations/api';
import clientApi from 'src/services/api/clientService';
import { clientEvents } from 'src/services/clientEvents';
import { EngagementService } from 'src/services/engagement/types';
import { intercomService } from 'src/services/intercom';
import {
  CompanyType,
  Country,
  DbAnalyticsTraits,
  EventMappingName,
  MqlType,
  RegistrationFlow,
  Role,
} from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { getContextFromURI, parseQueryString } from 'src/utils/query-utils';
import { CompanyInfoType } from 'src/utils/types';
import { getFullName, isFreeEmailDomain, isLoginFromForest, isMelioUser } from 'src/utils/user';
import MAPPINGS from './event-mappings';
import { EventMappingType } from './types';

type AnalyticsJS = SegmentAnalytics.AnalyticsJS;

/**
 A wrapper service for Segment event tracking aggregation
 Docs: https://segment.com/docs/sources/website/analytics.js/
 */

const TRAITS_KEY_TO_DB_FIELDS = {
  first_time_pay: 'firstTimePay',
  added_funding: 'addedFunding',
  create_a_bill: 'createdBill',
  link_accounting_software: 'linkedAccountingSoftware',
  Email_Verified: 'emailVerified',
  is_business_email: 'isBusinessEmail',
  added_delivery_method: 'addedDeliveryMethod',
};

type NavigatorWithGlobalPrivacyControl = Navigator & { globalPrivacyControl: boolean };

// content of the path * will be stored in {*} as sibling of all other */subpaths
const PARENT = '{*}';
const appendParentPathIfNeeded = (arrPath: string[]) => (last(arrPath) === '*' ? concat(arrPath, PARENT) : arrPath);
const getParentContentIfExist = (treeResult: Record<string, any>): Record<string, EventMappingType> =>
  treeResult?.[PARENT] ? treeResult?.[PARENT] : treeResult;
const getArrayPath = (path: string) => appendParentPathIfNeeded(slice(path?.split('/'), 1));
const MAPPING_TREE = reduce(
  MAPPINGS,
  (acc, value, key) => {
    const mergedValue = { ...get(acc, getArrayPath(key), {}), ...value };

    return set(acc, getArrayPath(key), mergedValue);
  },
  {}
);
const findEvent = (path: string): Record<string, EventMappingType> =>
  getParentContentIfExist(
    reduce(getArrayPath(path), (tree, currPath) => get(tree, [currPath], tree?.['*']), MAPPING_TREE)
  );

const buildEventData = (event: any, properties?: Record<string, any>): EventMappingType =>
  event.map((eventArguments) => {
    if (isObject(eventArguments)) {
      return mapValues(eventArguments, (path) => get(properties, path));
    }

    try {
      return template(eventArguments)(properties);
    } catch (e) {
      return eventArguments;
    }
  });

function getEventMapping(path: string, actionName: string, properties?: Record<string, any>): EventMappingType | null {
  const event = findEvent(path)?.[actionName] || MAPPINGS[EventMappingName.DEFAULT][actionName];

  if (event) {
    return buildEventData(event, properties);
  }

  return null;
}

export const excludedIntegrations = [
  'Facebook Pixel',
  'Google Tag Manager',
  'AdWords',
  'Google Ads (Classic)',
  'Twitter Ads',
  'LinkedIn Insight Tag',
  'Google AdWords New',
  'Google Analytics',
];

const excludeIntegrationsForCcpa = (callOptions) => {
  forEach(excludedIntegrations, (integration) => set(callOptions, `integrations[${integration}]`, false));
};

export class Analytics {
  #getAnalyticsService: () => AnalyticsJS | null;
  shouldReport: boolean;
  shouldPrintEvents: boolean;
  isLoggedInAs: boolean;
  currIdentifyObject: Record<string, any>;
  hasMqlEligibleEventCalled: boolean;
  siteConfig: string;
  userId?: string;
  email?: string;
  orgId?: number;
  userRole?: Role;
  companyName?: string;
  mql?: MqlType;
  createOrigin?: string;
  companyType?: string;
  isUserInFirm?: boolean;
  extraPropertiesByTags: Record<string, Record<string, any>> = {};
  registrationFlow?: RegistrationFlow;
  disableFacebookAnalytics?: boolean;
  userFirstName?: string;
  userLastName?: string;
  companyPhone?: string;
  guestPayor?: boolean;
  isGetPaid?: boolean;
  shouldLoadFeatureFlags: boolean;
  shouldIdentify: boolean;
  trackPrefix: string;
  isUserOptedOutOfSale: boolean;
  #engagementService: EngagementService | null = null;
  constructor(
    getAnalyticsService: () => AnalyticsJS | null,
    config,
    engagementService: EngagementService | null = null
  ) {
    this.#getAnalyticsService = getAnalyticsService;
    this.#engagementService = engagementService;
    this.shouldReport = config.analytics.shouldTrackEvents;
    this.shouldPrintEvents = config.analytics.shouldPrintEvents;
    this.isLoggedInAs = false;
    this.currIdentifyObject = {};
    this.hasMqlEligibleEventCalled = false;
    this.siteConfig = getContextFromURI();
    this.shouldLoadFeatureFlags = config.services.featureFlagProvider.shouldLoad;
    this.shouldIdentify = config.analytics.shouldIdentify;
    this.trackPrefix = config.trackPrefix || 'webapp';
    this.isUserOptedOutOfSale = false;

    if (this.shouldLoadFeatureFlags) {
      featureFlags.defaultClient.initialize({
        clientId: config.services.featureFlagProvider.clientId,
        trackVariant: this.trackVariant,
        environmentName: (window as any).APP_ENV,
      });
    }
  }

  get analyticsService(): AnalyticsJS | null {
    return this.#getAnalyticsService();
  }

  get engagementService(): EngagementService | null {
    return this.#engagementService;
  }

  isShouldReport() {
    return this.shouldReport && !this.isLoggedInAs && !isLoginFromForest();
  }

  isShouldPrintEvents() {
    return this.shouldPrintEvents;
  }

  async initializeIsUserOptedOutOfSale(user) {
    if ((navigator as NavigatorWithGlobalPrivacyControl).globalPrivacyControl) {
      this.isUserOptedOutOfSale = true;

      return;
    }

    if (!isEmpty(user.email)) {
      this.isUserOptedOutOfSale = user.isUserOptedOutOfSale;

      return;
    }

    try {
      const userPrivacy = await userPrivacyApi.getUserPrivacy();

      this.isUserOptedOutOfSale = userPrivacy.isUserOptedOutOfSale;
    } catch (e) {
      capture(e as Error, { message: 'error getting user privacy settings. set isUserOptedOutOfSale to false' });
      this.isUserOptedOutOfSale = false;
    }
  }

  async identifyFeatureFlagClient(orgId: number, userFields: { id: number; email: string; createdAt?: string }) {
    const identifyCalledBeforeInitDone = !featureFlags.defaultClient.clientInitialized;
    await featureFlags.defaultClient.identify(
      { id: `${userFields.id}`, email: userFields.email, createdAt: userFields.createdAt },
      { id: `${orgId}`, companyType: this.companyType },
      'meliocom',
      (window as any).APP_ENV
    );

    const identifyDoneBeforeInitDone = !featureFlags.defaultClient.clientInitialized;

    if (identifyDoneBeforeInitDone || identifyCalledBeforeInitDone) {
      this.track('test-variants', 'multiple-variants-issue', {
        identifyDoneBeforeInitDone,
        identifyCalledBeforeInitDone,
      });
    }
  }

  async identify(
    user: Record<string, any>,
    options?: Record<string, any>,
    innerOptions: {
      isLoggedInAs?: boolean;
      companyInfo?: CompanyInfoType;
    } = {}
  ) {
    const companyName = user.orgName ? user.orgName : user.orgId;
    const param = parseQueryString(window.location.search);

    this.siteConfig = getContextFromURI();
    this.userId = user.id;
    this.userFirstName = user.firstName;
    this.userLastName = user.lastName;
    this.email = user.email;
    this.orgId = user.orgId;
    const currentOrg = (user.organizations || []).find((org) => org.id === user.orgId);
    this.userRole = currentOrg?.userOrganization?.role;
    this.companyPhone = innerOptions.companyInfo?.phone ?? undefined;
    this.companyName = companyName;
    this.guestPayor = user.isGuest;
    this.mql = innerOptions.companyInfo?.mql ?? undefined;
    this.createOrigin = currentOrg?.createOrigin;
    this.companyType = innerOptions.companyInfo?.companyType ?? undefined;
    this.isUserInFirm =
      this.companyType === CompanyType.ACCOUNTING_FIRM ||
      (user.organizations || []).some((org) => org.companyType === CompanyType.ACCOUNTING_FIRM);
    this.isGetPaid = !!innerOptions.companyInfo?.ownedVendorId;

    await this.initializeIsUserOptedOutOfSale(user);

    if (user.id) {
      this.registrationFlow = user.registrationFlow;
    }

    if (user.id && user.orgId && this.shouldLoadFeatureFlags) {
      this.identifyFeatureFlagClient(user.orgId, { id: user.id, email: user.email, createdAt: user.createdAt });
    }

    if (!this.shouldIdentify) {
      return;
    }

    let traits: Record<string, any> = {
      name: user.email,
      email: user.email,
      Company: companyName,
      organization: companyName,
      Forest_Org_ID: parseInt(user.orgId, 10) || undefined,
      registrationFlow: this.registrationFlow,
      isGetPaid: this.isGetPaid,
      guestPayor: user.isGuest,
      userRole: this.userRole,
      companyType: this.companyType,
      createOrigin: this.createOrigin,
      userInFirm: this.isUserInFirm,
      siteConfig: this.siteConfig,
      ccpa_opt_out: this.isUserOptedOutOfSale,
    };

    if (innerOptions.companyInfo?.id) {
      let firstName = innerOptions.companyInfo.contactFirstName;
      let lastName = innerOptions.companyInfo.contactLastName;
      const creditCardFee = innerOptions.companyInfo.billingSetting.fee.credit?.value;
      firstName = isEmpty(firstName) ? '' : firstName;
      lastName = isEmpty(lastName) ? '' : lastName;

      traits.name = getFullName(firstName, lastName);

      traits.firstName = firstName;
      traits.lastName = lastName;

      if (creditCardFee) {
        traits.creditCardFee = creditCardFee;
      }
    }

    if (this.isShouldReport()) {
      clientEvents.identify();
    }

    const callOptions = options ? { ...options } : {};

    if (user.id && !innerOptions.isLoggedInAs && !isEqual(traits, this.currIdentifyObject) && this.mql == null) {
      this.currIdentifyObject = traits;
    }

    if (this.isUserOptedOutOfSale) {
      excludeIntegrationsForCcpa(callOptions);
    }

    if (!user.id && param?.email) {
      this.userId = param.email;
      traits.email = param.email;
      this.email = param.email;
      traits.organizations = [];
    }

    if (innerOptions.isLoggedInAs) {
      this.isLoggedInAs = true;
    }

    traits = omitBy(traits, isNil);

    if (this.isShouldReport() && this.analyticsService) {
      this.analyticsService.identify(user.id || '', traits, callOptions);
    }

    if (this.isShouldPrintEvents()) {
      console.log('analytics.identify', this.userId, traits, callOptions);
    }

    if (this.engagementService && user.id) {
      const { firstName, lastName, email } = traits;
      this.engagementService.identify(user.id, { firstName, lastName, email });
    }

    this.identifyOrg(this.orgId);
  }

  trackToServer(eventName: string, properties: Record<string, any> = {}, includeCommon = true) {
    let propsToSend = properties;

    if (includeCommon) {
      propsToSend = merge({}, properties, this.getCommonTrackProperties());
    }

    clientApi.sendAnalyticsEvent(eventName, this.userId, propsToSend);
  }

  setExtraProperties(tagName: string, properties: Record<string, any>) {
    this.extraPropertiesByTags[tagName] = properties;
  }

  removeExtraProperties(tagName: string) {
    delete this.extraPropertiesByTags[tagName];
  }

  getExtraProperties(): Record<string, unknown> {
    const extraPropertiesObjects = Object.values(this.extraPropertiesByTags);

    return Object.assign({}, ...extraPropertiesObjects);
  }

  getCommonTrackProperties() {
    const defaultProp = { siteConfig: this.siteConfig, deviceType: appLayout.current(), ...this.getExtraProperties() };

    return this.userId
      ? {
          organizationId: this.orgId,
          email: this.email,
          userRole: this.userRole,
          companyType: this.companyType,
          createOrigin: this.createOrigin,
          userInFirm: this.isUserInFirm,
          registrationFlow: this.registrationFlow,
          isGetPaid: this.isGetPaid,
          ccpa_opt_out: this.isUserOptedOutOfSale,
          userId: this.userId,
          ...defaultProp,
        }
      : {
          ...defaultProp,
        };
  }

  track(
    page: string,
    event: string,
    properties?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) {
    const eventName = `${this.trackPrefix}_${page}_${event}`;
    const callOptions = options ? { ...options } : {};

    if (this.isUserOptedOutOfSale) {
      excludeIntegrationsForCcpa(callOptions);
    }

    const trackProperties = merge({}, properties, this.getCommonTrackProperties());

    if (this.isShouldReport() && this.analyticsService) {
      this.analyticsService.track(
        eventName,
        trackProperties,
        set(callOptions, 'integrations.Intercom', false),
        callback
      );
    } else {
      callback?.();
    }

    if (this.engagementService && callOptions.engagementService) {
      this.engagementService.logCustomEvent(eventName, trackProperties);
    }

    if (this.isShouldPrintEvents()) {
      console.log('[E]', `${page}_${event}`, trackProperties);
    }
  }

  trackVariant = (flagName, variant) => {
    this.track('feature-variant', flagName, { variant });
  };

  trackMqlEligibleEvent(page: string, event: string) {
    if (!this.hasMqlEligibleEventCalled) {
      this.track(page, event);
      this.hasMqlEligibleEventCalled = true;
    }
  }

  trackAction(actionName: string, properties?: Record<string, any>) {
    const trackActionProperties = merge({}, properties, {
      siteConfig: this.siteConfig,
    });
    const event = getEventMapping(window.location.pathname, actionName, trackActionProperties);

    if (event) {
      const [page, category, props] = event;
      this.track(page, category, props);
    }
  }

  trackRoute(properties?: Record<string, any>) {
    const event = getEventMapping(window.location.pathname, 'page.view', properties);

    if (event) {
      const [category, page, props] = event;
      this.page(category, page, props);
    }
  }

  trackMqlEvent(
    page: string,
    event: string,
    properties?: Record<string, any>,
    options: Record<string, any> = {},
    callback?: () => void
  ) {
    if (this.mql === MqlType.QUALIFIED) {
      set(options, 'integrations.Salesforce', true);
      this.track(page, event, properties, options, callback);
    }
  }

  trackMarketingEvent(page: string, event: string, properties?: Record<string, any>, options?: Record<string, any>) {
    const expandedProperties = {
      ...properties,
      email: this.email,
      userId: this.userId,
      organizationId: this.orgId,
      companyName: this.companyName,
      phone: this.companyPhone,
      firstName: this.userFirstName,
      lastName: this.userLastName,
      isGuest: this.guestPayor,
      companyType: this.companyType,
      registrationFlow: this.registrationFlow,
      isGetPaid: this.isGetPaid,
      createOrigin: this.createOrigin,
    };

    this.track(page, event, expandedProperties, options);
  }

  identifyOrg(orgId?: number) {
    const groupId = `org:${orgId}`;
    const props = {
      group_type: 'organization',
      group_value: orgId,
    };

    if (this.isShouldPrintEvents()) {
      console.log('analytics.group', groupId, props);
    }

    if (this.isShouldReport() && this.analyticsService) {
      this.analyticsService.group(groupId, props);
    }
  }

  page(
    category: string,
    pageName: string,
    properties?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) {
    const categoryName = `${this.trackPrefix}_${category}`;
    let screenSize = '';
    try {
      screenSize = `${window.parent.innerWidth}_${window.parent.innerHeight}`;
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const callOptions = options ? { ...options } : {};

    if (this.isUserOptedOutOfSale) {
      excludeIntegrationsForCcpa(callOptions);
    }

    const commonPageProperties = {
      siteConfig: this.siteConfig,
      email: this.email,
      'screen-size': screenSize,
      registrationFlow: this.registrationFlow,
      origin: window.history?.state?.state?.origin,
      orgId: this.orgId,
      userId: this.userId,
      companyType: this.companyType,
      deviceType: appLayout.current(),
      ccpa_opt_out: this.isUserOptedOutOfSale,
      isGetPaid: this.isGetPaid,
      ...this.getExtraProperties(),
    };
    const pageProperties = merge({}, properties, commonPageProperties);

    if (this.isShouldReport() && this.analyticsService) {
      this.analyticsService.page(categoryName, pageName, pageProperties, callOptions, callback);
    }

    if (this.isShouldPrintEvents()) {
      console.log('[P]', category, pageName, pageProperties);
    }

    if (this.engagementService && !isNil(pageProperties.userId)) {
      this.engagementService.logCustomEvent(`page_${categoryName}_${pageName}`, pageProperties);
    }
  }

  setTraits(traits: Record<string, any>) {
    let innerTraits = cloneDeep(traits);

    if (!isEmpty(innerTraits.contactFirstName)) {
      innerTraits.name = `${innerTraits.contactFirstName} ${innerTraits.contactLastName}`;
    }

    if (!isEmpty(innerTraits.addressLine1)) {
      const addressTraits = {
        address: {
          city: innerTraits.city,
          postalCode: innerTraits.zipCode,
          country: Country.US,
          street: innerTraits.addressLine1,
          state: innerTraits.state,
        },
      };
      innerTraits = addressTraits;
    }

    innerTraits = omitBy(innerTraits, isEmpty);

    // if received mql (org_mql_decision) form client-events - save it
    const clientEventsMqlDecision = get(traits, 'org_mql_decision', null);

    if (clientEventsMqlDecision) {
      this.mql = clientEventsMqlDecision;
    }

    if (this.isShouldReport() && this.analyticsService) {
      const options = {};

      if (this.isUserOptedOutOfSale) {
        excludeIntegrationsForCcpa(options);
      }

      set(options, 'integrations.Salesforce', false);
      this.analyticsService.identify(this.userId || '', innerTraits, options);
    }

    if (this.isShouldPrintEvents()) {
      console.log('analytics.setTraits', this.userId, innerTraits);
    }

    const UPDATEABLE_TRAITS_FIELDS = Object.values(DbAnalyticsTraits);
    Object.keys(traits)
      .filter((k) => UPDATEABLE_TRAITS_FIELDS.includes(k as DbAnalyticsTraits))
      .forEach((trait) => {
        const changedField = TRAITS_KEY_TO_DB_FIELDS[trait];
        organizationsApi.setTraitsToDB(this.orgId, { [changedField]: true });
      });
  }

  alias(userId?: string | number | null) {
    if (this.isShouldReport() && this.analyticsService) {
      this.analyticsService.alias(String(userId) || '');
    }

    if (this.isShouldPrintEvents()) {
      console.log('analytics.alias', userId);
    }
  }

  setOneTimeMarketingTraits() {
    this.setTraits({
      utm_campaign_reg: cookies.get('utm_campaign_reg'),
      utm_source_reg: cookies.get('utm_source_reg'),
      utms: cookies.get('utms'),
    });
  }

  triggerCampaignEvents(eventName = 'business-email-registered') {
    if (this.email && !isFreeEmailDomain(this.email) && !isMelioUser(this.email)) {
      this.track('user-registration', eventName);
      this.setTraits({ [DbAnalyticsTraits.IS_BUSINESS_EMAIL]: true });
    }
  }

  setFundingSourceTraits() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- `orgId` will be present if you connect a funding source
    financialAccountsApi.getFundingSources(this.orgId!, { includeDeleted: false }).then(({ fundingSources }) => {
      const sourcesTypes = uniq(map(fundingSources, 'fundingType')).join(',');
      this.setTraits({
        funding_sources_types: sourcesTypes,
      });
    });
  }

  reset(isResetIntercom: boolean) {
    clientEvents.reset();

    if (this.analyticsService) {
      this.analyticsService.reset();
    }

    if (isResetIntercom) {
      intercomService.shutdown();
    }
  }
}
