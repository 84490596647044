import { MICard, MICardField, MICardTitle } from 'src/components/common/MICard';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import Flex from 'src/core/ds/flex';
import { InboxEmailType } from 'src/modules/scanned-invoices/types';
import { CardContainer } from './CardContainer';

type Props = {
  inboxEmail?: InboxEmailType | null;
};

export const EmailDetails = ({ inboxEmail }: Props) => {
  if (!inboxEmail) {
    return null;
  }

  return (
    <MICard mode="mainSingleScreen">
      <CardContainer>
        <Flex direction="column">
          <MICardTitle label="pay.scannedInvoice.emailDetails.title" />
          <MICardField label="pay.scannedInvoice.emailDetails.vendorEmail">{inboxEmail?.sender}</MICardField>
          <MICardField label="pay.scannedInvoice.emailDetails.createdAt">
            <MIFieldOrEmpty
              value={inboxEmail?.createdAt}
              label="pay.scannedInvoice.emailDetails.createdAtEmpty"
              type="date"
              hour="numeric"
              minute="numeric"
              day="2-digit"
              weekday="short"
            />
          </MICardField>
          <MICardField label="pay.scannedInvoice.emailDetails.subject">
            <MIFieldOrEmpty
              value={inboxEmail?.subject}
              label="pay.scannedInvoice.emailDetails.subjectEmpty"
              type="text"
            />
          </MICardField>
          <MICardField label="pay.scannedInvoice.emailDetails.content">
            <MIFieldOrEmpty
              value={inboxEmail?.content}
              label="pay.scannedInvoice.emailDetails.contentEmpty"
              type="text"
            />
          </MICardField>
        </Flex>
      </CardContainer>
    </MICard>
  );
};
