import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICodeInput } from 'src/components/common/MICodeInput';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { IllustrationName } from 'src/core/ds/illustration';
import { devices } from 'src/theme/appDevices';
import { NotificationCardTypes } from 'src/utils/consts';
import { CodeVerificationPageFooter } from './components/CodeVerificationPageFooter';

type Props = {
  email: string;
  isLoading: boolean;
  errorCode: string;
  onPrev?: () => void;
  onChangeEmail?: () => void;
  sendEmailVerificationCode: () => void;
  onChange: (code: string) => void;
  codeInputKey: number;
  isCodeResend: boolean;
};
export const CodeVerificationPage = ({
  codeInputKey,
  email,
  isLoading,
  errorCode,
  onPrev,
  sendEmailVerificationCode,
  onChange,
  onChangeEmail,
  isCodeResend,
}: Props) => (
  <>
    {isLoading && <AreaLoader placement="melioMe" />}
    <StepLayoutPage
      title="guests.emailVerify.title"
      subtitle="guests.emailVerify.subTitle"
      subTitleValues={{
        email: <strong>{email}</strong>,
        showEnterCode: <MIFormattedText label="guests.emailVerify.enterCodeBelow" />,
      }}
      onPrev={onPrev}
      illustration={IllustrationName.mailOne}
      footer={
        <CodeVerificationPageFooter
          onChangeEmail={onChangeEmail}
          sendEmailVerificationCode={sendEmailVerificationCode}
        />
      }
    >
      {isCodeResend && (
        <NotificationCard
          type={NotificationCardTypes.SUCCESS}
          subtitle={{
            label: 'guests.emailVerify.resendNotification',
          }}
        />
      )}
      <CodeInputContainer>
        <MICodeInput key={codeInputKey} type="number" pattern="\d*" isValid={!errorCode} onChange={onChange} />
      </CodeInputContainer>
      {errorCode && (
        <Box mt={2} textStyle="body4Semi" textAlign="center" color="red.500">
          <MIFormattedText label={`server.${errorCode}`} />
        </Box>
      )}
    </StepLayoutPage>
  </>
);

const CodeInputContainer = styled.div`
  text-align: center;

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;

const NotificationCard = styled(MINotificationCard)`
  margin: 0 0 4rem;
  @media ${devices.mobile} {
    margin: 0 0 2rem;
  }
`;
