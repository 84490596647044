import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { analytics } from 'src/services/analytics';

const eventPage = 'link-accounting-software-success';

const SuccessLinkingPageContainer = () => {
  const { navigate } = useNavigator();
  const site = useSiteContext();

  const goNext = () => {
    analytics.track(eventPage, 'continue');
    navigate(site.onboardingEntryUrl);
  };

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.handOk}
      title="onboarding.accountingSoftware.success.title"
      text="onboarding.accountingSoftware.success.text"
      buttonLabel="onboarding.accountingSoftware.success.actions.done"
      buttonAction={goNext}
    />
  );
};

SuccessLinkingPageContainer.defaultProps = {};

export default SuccessLinkingPageContainer;
