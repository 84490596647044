import styled from 'styled-components';
import { TextField } from 'src/core/ds/form/fields';
import { spacing } from 'src/core/theme/foundations/spacing';
import { VendorAddressType } from 'src/modules/vendors/types';
import { ModelView } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { US_STATES } from 'src/utils/us-states';

type Props = {
  formModelView: ModelView<VendorAddressType>;
};

export const AmexVendorAddressExtraFields = ({ formModelView }: Props) => (
  <>
    <FormRow>
      <TextField
        testId="amex-address-form-input-city"
        label="bills.pay.fundingSource.amexVendorAddressModal.city.label"
        model={formModelView.city}
        autoComplete="nope"
        isRequired
      />
    </FormRow>
    <FormRowSideBySide>
      <WizardSelectField
        id="state"
        testId="amex-address-form-select-state"
        label="bills.pay.fundingSource.amexVendorAddressModal.state.label"
        model={formModelView.state}
        options={US_STATES}
        required
        menuPlacement="auto"
      />
      <TextField
        testId="amex-address-form-input-zipCode"
        label="bills.pay.fundingSource.amexVendorAddressModal.zipCode.label"
        model={formModelView.zipCode}
        autoComplete="nope"
        isRequired
      />
    </FormRowSideBySide>
  </>
);

const FormRowSideBySide = styled(FormRow)`
  align-items: baseline;
  > * {
    margin-right: ${spacing[5]};
    margin-left: ${spacing[5]};
  }
`;
