import { AnimationProgress } from 'src/components/payment-progress-bar/AnimationProgress';
import { StatusInfo } from 'src/components/payment-progress-bar/ProgressBar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';

type ProgressBarElementsProps = {
  paymentProcess: StatusInfo[];
  paymentCurrentStageIndex: number;
};

type CalculateElementProps = {
  isCurrentStage: boolean;
  isFinalStage: boolean;
  color: string;
};

type CalculateLineProps = {
  isCurrentStage: boolean;
  color: string;
};

type DotElement = {
  size: number;
  color: string;
};

const DotElement = ({ size, color }: DotElement): JSX.Element => (
  <Box h={size} w={size} bgColor={color} borderRadius="full" />
);

function CalculatedDot({ isCurrentStage, isFinalStage, color }: CalculateElementProps): JSX.Element {
  return !isFinalStage && isCurrentStage ? (
    <DotElement size={3} color={color} />
  ) : (
    <DotElement size={2} color={color === 'grey.300' ? 'grey.500' : color} />
  );
}

const RenderAnimationAccordingToDevice = (): JSX.Element => {
  const device = useBreak();

  if (device.isMobile) {
    return <Box bgGradient="linear(to-r, green.500 , grey.300 70%)" h="0.3rem" flex="1" m={1} borderRadius="md" />;
  }

  return <AnimationProgress />;
};

const CalculatedLine = ({ isCurrentStage, color }: CalculateLineProps): JSX.Element =>
  isCurrentStage ? (
    RenderAnimationAccordingToDevice()
  ) : (
    <Box bgColor={color} h="0.3rem" flex="1" m={1} borderRadius="md" />
  );

const CalculatedElements = ({ isCurrentStage, isFinalStage, color }: CalculateElementProps): JSX.Element => (
  <>
    <CalculatedDot isCurrentStage={isCurrentStage} isFinalStage={isFinalStage} color={color} />
    {isFinalStage ? null : <CalculatedLine isCurrentStage={isCurrentStage} color={color} />}
  </>
);

export const ProgressBarElements = ({
  paymentProcess,
  paymentCurrentStageIndex,
}: ProgressBarElementsProps): JSX.Element => (
  <Flex alignItems="center" ml={10} mr={10}>
    {paymentProcess?.map((stage, stageIndex) => {
      const color = stageIndex <= paymentCurrentStageIndex ? 'green.500' : 'grey.300';
      const isFinal = paymentProcess.length - 1 === stageIndex;
      const isCurrent = paymentCurrentStageIndex === stageIndex;

      return <CalculatedElements key={stageIndex} isCurrentStage={isCurrent} isFinalStage={isFinal} color={color} />;
    })}
  </Flex>
);
