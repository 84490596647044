import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { vendorRequestsApi } from 'src/modules/vendors/api';
import type { SubmenuProps } from 'src/pages/pay/components/filters/types';
import { VendorType } from 'src/utils/types';

export type VendorMenuOption = { id: string; vendorName: VendorType['companyName'] };

type VendorsMenuProps = {
  search: string;
  setSearch: (value: VendorsMenuProps['search']) => void;
  options: VendorMenuOption[];
  isLoading: boolean;
};

type VendorsNameList = {
  id: number;
  companyName: string;
};

export const useVendorsMenu = (selected: string[]): Omit<VendorsMenuProps, keyof SubmenuProps> => {
  const [search, setSearch] = useState('');
  const orgId = useOrgId();
  const { onApiCall: getVendorsNameList, result: rawVendors, loading: isLoading } = useApi<
    [{ orgId: number }],
    VendorsNameList[]
  >({
    api: vendorRequestsApi.getVendorsNameList,
  });

  useEffect(() => {
    getVendorsNameList({ orgId });
  }, [orgId]);

  useEffect(() => {
    if (isEmpty(selected)) {
      setSearch('');
    }
  }, [selected]);

  const vendors = useMemo(
    () =>
      rawVendors
        ? (rawVendors
            .filter((vendor) => vendor?.companyName?.toLowerCase().includes(search?.toLowerCase()))
            .map((vendor) => ({
              id: vendor.id!.toString(),
              vendorName: vendor.companyName,
            })) as VendorsMenuProps['options'])
        : [],
    [rawVendors, search]
  );

  return { options: vendors, isLoading, search, setSearch };
};
