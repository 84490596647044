export const BillersVisibilityModalMessageStyle = {
  boxShadow: '500',
  zIndex: 20,
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  bottom: 8,
  right: 10,
  bg: 'white',
  maxW: '40rem',
  borderRadius: 'lg',
};
