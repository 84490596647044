import React from 'react';
import { useDisclosure } from 'src/core/ds/hooks';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import BillDrawer from 'src/pages/regular-batch-payments/components/Drawer/BillDetails/BillDrawer';
import { ActionsCellButton } from 'src/pages/regular-batch-payments/table/components/ActionsCell/ActionCellButton';
import { BillType } from 'src/utils/types';

type Props = {
  rowHovered: boolean;
  bill: BillType;
  payBillFlowUUID: string;
};

export const BillDetailsAction = ({ rowHovered, bill, payBillFlowUUID }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ActionsCellButton
        showOnHover
        rowHovered={rowHovered}
        icon={<Icon name={IconNames.tabletSearch} size={IconSize.m} />}
        label="regularBatchPayments.cells.actions.viewBillDetails"
        analyticsProperties={{
          billId: bill.id,
          payBillFlowUUID,
        }}
        onClick={onOpen}
      />
      <BillDrawer bill={bill as BillType} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
