import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { scannedInvoicesStore } from 'src/modules/scanned-invoices/store';
import { ScannedInvoiceType } from 'src/modules/scanned-invoices/types';

export const useViewScannedInvoiceData = (scannedInvoiceId?: string) => {
  const orgId = useOrgId();

  const scannedInvoicesStoreActions = useStoreActions(scannedInvoicesStore);
  const scannedInvoice = useSelector(scannedInvoicesStore.selectors.fetch.byId(scannedInvoiceId)) as ScannedInvoiceType;
  const isLoading = useSelector(scannedInvoicesStore.selectors.fetch.status(scannedInvoiceId))?.loading as boolean;

  const fetchScannedInvoice = useCallback(async () => {
    await scannedInvoicesStoreActions.fetch({ orgId, scannedInvoiceId });
  }, [orgId, scannedInvoiceId, scannedInvoicesStoreActions]);

  useEffect(() => {
    fetchScannedInvoice();
  }, [fetchScannedInvoice, scannedInvoiceId]);

  return { scannedInvoice, isLoading };
};
