import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { UploadPicture } from 'src/components/common/UploadPicture';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { filesApi } from 'src/modules/files/api';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getFullName } from 'src/utils/user';

type UploadProfilePictureContainerProps = {
  editable?: boolean;
  firstName?: string | null;
  lastName?: string | null;
};

export function UploadProfilePictureContainer({ editable, firstName, lastName }: UploadProfilePictureContainerProps) {
  const userActions = useStoreActions(usersStore);
  const user = useSelector(profileStore.selectors.profile);
  const orgId = useSelector(getOrgId);
  const name = getFullName(firstName, lastName);

  const deleteProfilePicture = useCallback(
    async ({ orgId, fileId, userId }) => {
      await filesApi.deleteProfilePicture(orgId, fileId);
      await userActions.allUserOrganizations({
        orgId,
        userIds: [userId],
      });
    },
    [userActions]
  );

  const uploadProfilePicture = useCallback(
    async ({ orgId, file, userId }) => {
      await filesApi.uploadProfilePicture(orgId, file);
      await userActions.allUserOrganizations({
        orgId,
        userIds: [userId],
      });
    },
    [userActions]
  );

  const { onApiCall: onDeleteProfilePicture, loading: isDeleting } = useApi<
    [{ orgId: number; fileId: number; userId: number }],
    void
  >({
    api: deleteProfilePicture,
  });
  const { onApiCall: onUploadProfilePicture, loading: isUploading } = useApi<
    [{ orgId: number; file: File; userId: number }],
    void
  >({
    api: uploadProfilePicture,
  });
  const isLoading = isDeleting || isUploading;

  const onDeleteClick = async () => {
    analytics.trackAction('user-picture-deleted');

    if (user.userLogoDetails) {
      await onDeleteProfilePicture({
        orgId,
        fileId: user.userLogoDetails.fileId,
        userId: user.id,
      });
    }
  };
  const onLogoChange = async (file: File | '') => {
    if (!file) return;

    await onUploadProfilePicture({
      orgId,
      file,
      userId: user.id,
    });
  };
  const onAddPictureClick = () => {
    analytics.trackAction('user-picture-clicked');
  };

  return (
    <UploadPicture
      pictureUrl={user?.userLogoDetails?.logoUrl}
      editable={editable}
      isLoading={isLoading}
      name={name}
      onDeleteClick={onDeleteClick}
      onFileChange={onLogoChange}
      onAddClick={onAddPictureClick}
    />
  );
}
