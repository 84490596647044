import { ChangeEvent, PureComponent } from 'react';
import styled from 'styled-components';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  onRequestResetPassword: (email: string) => void;
  isDone: boolean;
  isLoading?: boolean;
  errorCode?: string | null;
  goBack: () => void;
  validationErrors: Record<string, any>;
};

type State = {
  email: string;
};

class RequestResetPage extends PureComponent<Props, State> {
  static defaultProps = {
    isLoading: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  onFieldChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    this.setState((prevState) => ({ ...prevState, [id]: value }));
  };

  handleRequestResetPassword = () => {
    this.props.onRequestResetPassword(this.state.email);
  };

  render() {
    const { isLoading, isDone, goBack, validationErrors, errorCode } = this.props;
    const { email } = this.state;

    return (
      <RegisterLayoutPage
        title="auth.requestResetPassword.title"
        buttonLabel="auth.requestResetPassword.resetPasswordButton"
        buttonAction={this.handleRequestResetPassword}
        linkHint="auth.requestResetPassword.loginHint"
        linkLabel="auth.requestResetPassword.loginLink"
        linkAction={goBack}
        isLoading={isLoading}
      >
        {isDone && (
          <NotificationCard
            type={NotificationCardTypes.SUCCESS}
            subtitle={{
              label: 'auth.requestResetPassword.successNotification',
            }}
          />
        )}
        {errorCode && (
          <NotificationCard
            type={NotificationCardTypes.ERROR}
            subtitle={{
              label: `server.${errorCode}`,
            }}
          />
        )}
        <TextField
          id="email"
          value={email}
          label="auth.requestResetPassword.email"
          autoFocus
          type="email"
          onChange={this.onFieldChanged}
          isRequired
          errorMessage={validationErrors.email}
        />
      </RegisterLayoutPage>
    );
  }
}

const NotificationCard = styled(MINotificationCard)`
  margin: 3rem 0 4rem;
`;

export default RequestResetPage;
