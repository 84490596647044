import { PAGE_EVENT } from 'src/pages/get-pro/consts';
import { EventMappingType } from 'src/services/analytics/types';

export const FILTER_MAPPING: Record<string, EventMappingType> = {
  'getPro.inProgress.statusFilter': [
    PAGE_EVENT,
    'status_filter_dropdown',
    {
      tab: 'tab',
      filter: 'filter',
      selectedStatuses: 'selectedStatuses',
      changedStatus: 'changedStatus',
      isChecked: 'isChecked',
    },
  ],

  'getPro.filter.invoices.all': [PAGE_EVENT, 'invoices_all_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.invoices.past_due': [PAGE_EVENT, 'invoices_past_due_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.invoices.due_7_days': [PAGE_EVENT, 'invoices_due_7_days_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.invoices.due_month': [PAGE_EVENT, 'invoices_due_month_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.invoices.due_next_month': [
    PAGE_EVENT,
    'invoices_due-next-month_dropdown',
    { tab: 'tab', filter: 'filter' },
  ],

  'getPro.filter.requests.all': [PAGE_EVENT, 'requests_all_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.requests.past_due': [PAGE_EVENT, 'requests_past_due_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.requests.due_7_days': [PAGE_EVENT, 'requests_due_7_days_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.requests.due_month': [PAGE_EVENT, 'requests_due_month_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.requests.due_next_month': [
    PAGE_EVENT,
    'requests_due-next-month_dropdown',
    { tab: 'tab', filter: 'filter' },
  ],

  'getPro.filter.inProgress.all': [PAGE_EVENT, 'in_progress_all_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.inProgress.next_7_days': [
    PAGE_EVENT,
    'in_progress_next_7_days_dropdown',
    { tab: 'tab', filter: 'filter' },
  ],
  'getPro.filter.inProgress.this_month': [
    PAGE_EVENT,
    'in_progress_this_month_dropdown',
    { tab: 'tab', filter: 'filter' },
  ],

  'getPro.filter.paid.all': [PAGE_EVENT, 'paid_all_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.paid.today': [PAGE_EVENT, 'paid_today_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.paid.last_7_days': [PAGE_EVENT, 'paid_last_7_days_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.paid.this_month': [PAGE_EVENT, 'paid_this_month_dropdown', { tab: 'tab', filter: 'filter' }],
  'getPro.filter.paid.last_month': [PAGE_EVENT, 'paid_last_month_dropdown', { tab: 'tab', filter: 'filter' }],
};
