import { useSelector } from 'react-redux';
import { generatePath, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { AddBankAccountFromPayBillWrapper } from 'src/flows/add-bank-account-flow/wrappers/AddBankAccountFromPayBillWrapper';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { billLocations } from 'src/pages/bill/locations';
import { PayBillDataContextProvider } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { PayBillFinancingContextProvider } from 'src/pages/bill/pay/hooks/usePayBillFinancingData';
import { InstallmentsApplicationFlow } from 'src/pages/bill/pay/installments/InstallmentsApplicationFlow';
import { InstallmentsScheduleFlow } from 'src/pages/bill/pay/installments/InstallmentsScheduleFlow';
import { AmountRejected } from 'src/pages/bill/pay/installments/pages/error-pages/AmountRejected';
import { EligibilityRejectionPage } from 'src/pages/bill/pay/installments/pages/error-pages/EligibilityRejectionPage';
import { GeneralErrorPage } from 'src/pages/bill/pay/installments/pages/error-pages/GeneralErrorPage';
import { PayBillCompleteLegalInfoPageContainer } from 'src/pages/bill/pay/PayBillCompleteLegalInfoPageContainer';
import { PayBillFundingSourcePageContainer } from 'src/pages/bill/pay/PayBillFundingSourcePageContainer';
import { PayBillSuccessPageContainer } from 'src/pages/bill/pay/PayBillSuccessPageContainer';
import { ReturnedCheckRecoveryFlowPage } from 'src/pages/bill/pay/ReturnedCheckRecoveryFlowPage';
import { UndepositedCheckResendFlowPage } from 'src/pages/bill/pay/UndepositedCheckResendFlowPage';
import { VirtualCardRecoveryFlowPage } from 'src/pages/bill/pay/VirtualCardRecoveryFlowPage';
import { AddAchFromPaymentFlowWrapper } from 'src/pages/vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromPaymentFlowWrapper';
import { getRedirectUrl } from 'src/redux/payBillWizard/selectors';
import { DeliveryType } from 'src/utils/consts';
import { EditAchDeliveryMethodPage } from './EditAchDeliveryMethodPage';
import { ExpeditePaymentPageContainer } from './ExpeditePaymentPageContainer';
import { FastPaymentOfferPage } from './FastPaymentOfferPage/FastPaymentOfferPage';
import { EligibilityPendingPage } from './installments/pages/error-pages/EligibilityPendingPage';
import { PayBillConfirmPageContainer } from './PayBillConfirmPageContainer';
import { PayBillDatePageContainer } from './PayBillDatePageContainer';
import { PayBillMemoPageContainer } from './PayBillMemoPageContainer';
import { PayBillSelectDeliveryMethodPageContainer } from './PayBillSelectDeliveryMethodPageContainer';

function getExitLink(location, billId, orgId) {
  if (location.state && location.state.exitLink) {
    return location.state.exitLink;
  }

  return generatePath(billLocations.view, { orgId, id: billId || ':billId' });
}

export function BillPayWizardRouter() {
  const match = useRouteMatch<{ billId?: string; paymentId?: string; orgId: string }>();
  const location = useLocation();
  const redirectUrl = useSelector(getRedirectUrl);
  const successUrl = redirectUrl || `${match.path}/success`;

  const orgId = +match.params.orgId;
  const { billId, paymentId } = match.params;
  const isRecurring = !billId;

  return (
    <PayBillDataContextProvider>
      <Switch>
        <SmartRoute path={`${match.path}/installments/application`} component={InstallmentsApplicationFlow} />
        <SmartRoute path={`${match.path}/installments/schedule`} component={InstallmentsScheduleFlow} />
        <SmartRoute path={`${match.path}/installments/eligibility-pending`} component={EligibilityPendingPage} />
        <SmartRoute path={`${match.path}/installments/eligibility-not-approved`} component={EligibilityRejectionPage} />
        <SmartRoute path={`${match.path}/installments/amount-exceeded`} component={AmountRejected} />
        <SmartRoute path={`${match.path}/installments/error`} component={GeneralErrorPage} />
        <SmartRoute path={`${match.path}/funding`} exact>
          <PayBillFinancingContextProvider>
            <PayBillFundingSourcePageContainer
              id={billId}
              paymentId={paymentId}
              prevStepURL={getExitLink(location, billId, orgId)}
              nextStepURL={`${match.url}/delivery-method`}
            />
          </PayBillFinancingContextProvider>
        </SmartRoute>
        <SmartRoute path={`${match.path}/delivery-method`}>
          <PayBillSelectDeliveryMethodPageContainer
            id={billId}
            paymentId={paymentId}
            prevStepURL={`${match.url}/funding`}
            nextStepURL={`${match.url}/${isRecurring ? 'memo' : 'date'}`}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/add-bank-account`}>
          <AddBankAccountFromPayBillWrapper nextStepURL={`${match.url}/delivery-method`} />
        </SmartRoute>
        <SmartRoute path={`${match.path}/add-ach`}>
          <AddAchFromPaymentFlowWrapper nextPageUrl={`${match.url}/${isRecurring ? 'memo' : 'date'}`} />
        </SmartRoute>
        <SmartRoute path={`${match.path}/edit-ach/:deliveryMethodId`}>
          <AddAchFromPaymentFlowWrapper nextPageUrl={`${match.url}/${isRecurring ? 'memo' : 'date'}`} />
        </SmartRoute>
        <SmartRoute path={`${match.path}/date`}>
          <PayBillDatePageContainer
            id={billId}
            paymentId={paymentId}
            prevStepURL={`${match.url}/delivery-method`}
            nextStepURL={`${match.url}/memo`}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/memo`}>
          <PayBillMemoPageContainer
            id={billId}
            paymentId={paymentId}
            prevStepURL={`${match.url}/${isRecurring ? 'funding' : 'date'}`}
            nextStepURL={`${match.url}/${isRecurring ? 'confirm' : 'expedite'}`}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/expedite`}>
          <ExpeditePaymentPageContainer
            id={billId}
            prevStepURL={`${match.url}/memo`}
            nextStepURL={`${match.url}/confirm`}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/confirm`}>
          <PayBillConfirmPageContainer
            id={billId}
            paymentId={paymentId}
            prevStepURL={`${match.url}/memo`}
            nextStepURL={successUrl}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/success`}>
          <PayBillSuccessPageContainer id={billId} paymentId={paymentId} />
        </SmartRoute>
        <SmartRoute path={`${match.path}/complete-legal-info`}>
          <PayBillCompleteLegalInfoPageContainer
            id={billId}
            prevStepURL={`${match.url}/confirm`}
            nextStepURL={successUrl}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/fast-payment`}>
          <FastPaymentOfferPage
            id={billId}
            paymentId={paymentId}
            prevStepURL={`${match.url}/funding`}
            nextStepURL={`${match.url}/confirm`}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/delivery-method-ach`}>
          <EditAchDeliveryMethodPage id={billId} paymentId={paymentId} />
        </SmartRoute>
        <SmartRoute path={`${match.path}/virtual-card-recovery`}>
          <VirtualCardRecoveryFlowPage
            id={billId}
            paymentId={paymentId}
            prevStepURL={getExitLink(location, billId, orgId)}
            nextStepURL={`${match.url}/confirm`}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/returned-check-recovery`}>
          <ReturnedCheckRecoveryFlowPage
            id={billId}
            paymentId={paymentId}
            prevStepURL={getExitLink(location, billId, orgId)}
            nextStepURL={`${match.url}/confirm`}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/resend-undeposited-check`}>
          <UndepositedCheckResendFlowPage
            id={billId}
            paymentId={paymentId}
            nextStepURL={`${match.url}/confirm`}
            payBillNextStepState={{ originDeliveryType: DeliveryType.CHECK }}
          />
        </SmartRoute>
        <SmartRoute path={`${match.path}/undeposited-check-fast-payment`}>
          <FastPaymentOfferPage
            id={billId}
            paymentId={paymentId}
            prevStepURL={`${match.url}/resend-undeposited-check`}
            nextStepURL={`${match.url}/confirm`}
            payBillNextStepState={{ originDeliveryType: DeliveryType.CHECK }}
          />
        </SmartRoute>
      </Switch>
    </PayBillDataContextProvider>
  );
}
