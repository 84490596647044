import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, useHistory } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore from 'src/modules/organizations/organizations-store';
import { globalLocations } from 'src/pages/locations';
import { analytics } from 'src/services/analytics';
import { CompanyType, FeatureFlags } from 'src/utils/consts';
import { QuickbookMultiOrgAddManualAccountingFirmRouter } from './add-accounting-firm-manually/QuickbookMultiOrgAddManualAccountingFirmRouter';
import { QuickbookMultiOrgOnboardingCompaniesList } from './components/QuickbookMultiOrgOnboardingCompaniesList';
import { QuickbookMultiOrgOnboardingCompanyType } from './components/QuickbookMultiOrgOnboardingCompanyType';
import { QuickbookMultiOrgOnboardingConnect } from './components/QuickbookMultiOrgOnboardingConnect';
import { QuickbookMultiOrgOnboardingConnectError } from './components/QuickbookMultiOrgOnboardingConnectError';
import { QuickbookMultiOrgOnboardingConnectSuccess } from './components/QuickbookMultiOrgOnboardingConnectSuccess';

const ANALYTICS_TAG_NAME = 'QuickbookMultiOrgOnboardingRouter';

export function QuickbookMultiOrgOnboardingRouter() {
  const history = useHistory();
  const [areQboMultiOrgOnboardingChangesEnabled] = useFeature<boolean>(
    FeatureFlags.QboMultiOrgOnboardingChangesOnMeliocom,
    false,
    { shouldTrack: false }
  );
  const [historyPush] = useHistoryWithOrgId();
  const dispatch = useDispatch();

  useEffect(() => {
    analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
      currentFlow: 'MultiOrgOnboardingFlow',
    });
    const cleanExtraProperties = () => {
      analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
    };

    return cleanExtraProperties;
  }, []);

  useEffect(
    () => () => {
      dispatch(organizationStore.actions.updateNewCompanySlice.update({}));
    },
    [dispatch]
  );

  const onNextCompanyTypeChanged = (companyType: CompanyType) => {
    if (companyType === CompanyType.ACCOUNTING_FIRM) {
      history.push(globalLocations.quickbookMultiOrgOnboarding.addAccountingFirm.details);
    } else {
      historyPush({ path: globalLocations.companies.index });
    }
  };

  const onNextCompanyType = (companyType: CompanyType) => {
    if (companyType === CompanyType.ACCOUNTING_FIRM) {
      history.push(globalLocations.quickbookMultiOrgOnboarding.connectQuickbooks);
    } else {
      history.push(globalLocations.quickbookMultiOrgOnboarding.companiesList);
    }
  };

  const onFinishFlow = () => {
    historyPush({ path: globalLocations.companies.index });
  };

  const onPrev = () => {
    history.goBack();
  };

  const onNext = (path: string) => () => {
    history.push(path);
  };

  const onFinishAddAccountingFirmManually = () => {
    if (areQboMultiOrgOnboardingChangesEnabled) {
      onFinishFlow();
    } else {
      onNext(globalLocations.quickbookMultiOrgOnboarding.companiesList);
    }
  };

  return (
    <Switch>
      <SmartRoute path={globalLocations.quickbookMultiOrgOnboarding.companyType} exact>
        <QuickbookMultiOrgOnboardingCompanyType
          onNext={areQboMultiOrgOnboardingChangesEnabled ? onNextCompanyTypeChanged : onNextCompanyType}
        />
      </SmartRoute>

      <SmartRoute path={globalLocations.quickbookMultiOrgOnboarding.connectQuickbooks} exact>
        <QuickbookMultiOrgOnboardingConnect
          onPrev={onPrev}
          onNext={onNext(globalLocations.quickbookMultiOrgOnboarding.addAccountingFirm.details)}
        />
      </SmartRoute>

      <SmartRoute path={globalLocations.quickbookMultiOrgOnboarding.quickbooksSuccess} exact>
        <QuickbookMultiOrgOnboardingConnectSuccess />
      </SmartRoute>

      <SmartRoute path={globalLocations.quickbookMultiOrgOnboarding.quickbooksError} exact>
        <QuickbookMultiOrgOnboardingConnectError />
      </SmartRoute>

      <SmartRoute path={globalLocations.quickbookMultiOrgOnboarding.companiesList} exact>
        <QuickbookMultiOrgOnboardingCompaniesList onPrev={onPrev} onNext={onFinishFlow} />
      </SmartRoute>

      <SmartRoute path={globalLocations.quickbookMultiOrgOnboarding.addAccountingFirm.base}>
        <QuickbookMultiOrgAddManualAccountingFirmRouter onFinish={onFinishAddAccountingFirmManually} />
      </SmartRoute>
    </Switch>
  );
}
