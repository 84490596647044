import Flex from 'src/core/ds/flex/Flex';
import { hasRetryInstallment, isFailedInstallment, isScheduledInstallment } from 'src/utils/financing';
import { PaymentFinancing } from 'src/utils/types';
import { InstallmentListViewRow } from './InstallmentListViewRow';
import { InstallmentsListViewFooterExpanded } from './InstallmentsListViewFooterExpanded';
import { InstallmentsListViewHeaderExpanded } from './InstallmentsListViewHeaderExpanded';
import { getConvertedStatus } from './utils';

type Props = {
  financing: PaymentFinancing;
  onToggle(): void;
  viewDetailsLink?: string;
  showBadges: boolean;
};

export const InstallmentsListExpanded = ({ financing, viewDetailsLink, onToggle, showBadges }: Props) => {
  const { installments, totalAmount, totalFees, totalFeePercent } = financing;
  const firstScheduledInstallmentIndex = installments.findIndex(isScheduledInstallment);
  const hasFailedInstallment = installments.some((installment) => isFailedInstallment(installment));
  const hasRetry = hasRetryInstallment(installments);

  const getShouldShowBadge = (installment, installmentIndex): boolean => {
    const isFirstScheduledInstallment = installmentIndex === firstScheduledInstallmentIndex;

    const shouldShowScheduledBadge =
      isScheduledInstallment(installment) && isFirstScheduledInstallment && !hasFailedInstallment && !hasRetry;

    return !isScheduledInstallment(installment) || shouldShowScheduledBadge;
  };

  return (
    <Flex w="full" direction="column" pb={1}>
      <InstallmentsListViewHeaderExpanded onToggle={onToggle} />
      {installments.map((installment, index) => (
        <InstallmentListViewRow
          key={index}
          installment={{ ...installment, status: getConvertedStatus(installment) }}
          viewDetailsLink={viewDetailsLink}
          showBadge={showBadges && getShouldShowBadge(installment, index)}
        />
      ))}
      <InstallmentsListViewFooterExpanded
        totalAmount={totalAmount}
        totalFees={totalFees}
        totalFeePercent={totalFeePercent}
      />
    </Flex>
  );
};
