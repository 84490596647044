export enum IllustrationName {
  clock = 'ClockSmall',
  gift = 'GiftSmall',
  coin = 'CoinLarge',
  send = 'PlanePaperLarge',
  checkBreak = 'CheckBreakLarge',
  gauge = 'GaugeSmall',
  calendar = 'CalendarDateSmall',
  screenHappy = 'ScreenHappyLarge',
  moneyStack = 'MoneyStackSmall',
  location = 'LocationSmall',
  moon = 'MoonSmall',
  number1 = 'Number1Small',
  number2 = 'Number2Small',
  number3 = 'Number3Small',
  invoiceAdd = 'InvoiceAddMedium',
  businessPurple = 'BusinessPurpleLarge',
  shareOcr = 'ShareOcrMedium',
  userLink = 'UserLinkMedium',
  achWallet = 'AchWalletMedium',
  sync = 'SyncMedium',
  moneyStackCheck = 'MoneyStackCheckLarge',
  moneyStackClock = 'MoneyStackClockLarge',
  creditCard = 'CardCreditLarge',
  highFivePurple = 'HighFivePurpleLarge',
  highFive = 'HighFiveLarge',
  handOk = 'HandOkLarge',
  handOkPurple = 'HandOkPurpleLarge',
  handSnap = 'HandSnapLarge',
  handSnapPurple = 'HandSnapPurpleLarge',
  thumbUpPurple = 'ThumbUpPurpleLarge',
  screenHappyPurple = 'ScreenHappyPurpleLarge',
  errorScreen = 'ErrorScreenLarge',
  kiss = 'KissLarge',
  giftMoney = 'GiftMoneyLarge',
  stopWatch = 'StopWatchLarge',
  moneyBag = 'MoneyBagLarge',
  moonPurple = 'MoonPurpleLarge',
  bankQuestion = 'BankQuestionPurpleLarge',
  bank = 'BankPurpleLarge',
  bankCheck = 'BankCheckPurpleLarge',
  mailOne = 'MailOneLarge',
  sendPurple = 'SendPurpleLarge',
  checkDeposit = 'CheckDepositLarge',
  checkDepositPurple = 'CheckDepositPurpleLarge',
  checkCircle = 'CheckGreenLarge',
  contactCheckPurple = 'ContactCheckPurpleLarge',
  contactCheck = 'ContactCheckLarge',
  sandTimer = 'SandTimerLarge',
  party = 'PartyLarge',
  billSyn = 'BillSynLarge',
  billSynPurple = 'BillSynPurpleLarge',
  billCheck = 'BillCheckLarge',
  customerAdd = 'CustomerAddLarge',
  invoiceCheck = 'InvoiceCheckLarge',
  business = 'BusinessLarge',
  phoneHand = 'PhoneHandMedium',
  thumbUp = 'ThumbUpLarge',
  moneyBagCheck = 'MoneyBagCheckLarge',
  tax = 'TaxLarge',
  siteLink = 'SiteLinkMedium',
  userSync = 'UserSyncMedium',
  declined = 'DeclinedLarge',
  phoneOne = 'PhonOneLarge',
}

export enum IllustrationSize {
  sm = 96,
  md = 120,
  lg = 144,
}
