import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import { UserExistErrorNotificationCard } from 'src/components/common/UserExistNotificationCard';
import RegistrationTermsAndConditions from 'src/components/onboarding/RegistrationTermsAndConditions';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { Link } from 'src/core/ds/link';
import { getCashBackCoupon } from 'src/helpers/coupons';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { isEnterPressed } from 'src/utils/events';
import { handleInputChange } from 'src/utils/input';
import { CouponType, FieldType } from 'src/utils/types';

type Props = {
  email: string;
  companyName: string;
  validationErrors: Record<string, any>;
  onChange: ({ id, value }: FieldType) => void;
  onNext: () => void;
  onPrev: () => void;
  isLoading: boolean;
  onLoginClick: () => void;
  onSignUpClick: () => void;
  errorCode: string;
  promotionName: string;
  coupons: CouponType[] | null;
};
const GuestSignUpPage = ({
  email,
  validationErrors,
  onChange,
  onPrev,
  onNext,
  isLoading,
  onLoginClick,
  onSignUpClick,
  errorCode,
  companyName,
  promotionName,
  coupons,
}: Props) => {
  const site = useSiteContext();
  const { StepLayout, WizardElements } = site.components;
  const coupon = getCashBackCoupon(coupons);

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (!isLoading && isEnterPressed(event)) {
      onNext();
    }
  };

  const renderButtonsDefault = () => (
    <Flex w="full" direction="column" align="center" pt={3} pb={4} __css={{ 'div:first-of-type': { my: 4 } }}>
      <Button onClick={onNext} label="guests.signUp.next" isFullWidth isLoading={isLoading} size={ButtonSizes.lg} />
      <RegistrationTermsAndConditions label="guests.signUp.termsAndConditions" />
      <WizardElements.WizardOrLine />
      <Button
        onClick={onSignUpClick}
        label="guests.signUp.signUpHint"
        variant={ButtonVariants.secondary}
        size={ButtonSizes.lg}
        isFullWidth
      />
    </Flex>
  );

  const renderButtonsPromotion = () => (
    <Flex w="full" direction="column" align="center" pt={3} pb={4} __css={{ 'div:first-of-type': { my: 4 } }}>
      <Button onClick={onSignUpClick} label="guests.signUp.signUpHintPromotion" size={ButtonSizes.lg} isFullWidth />
      <WizardElements.WizardOrLine />
      <Button
        onClick={onNext}
        label="guests.signUp.next"
        variant={ButtonVariants.secondary}
        size={ButtonSizes.lg}
        isFullWidth
        isLoading={isLoading}
      />
      <Box mt={3}>
        <RegistrationTermsAndConditions label="guests.signUp.termsAndConditions" />
      </Box>
    </Flex>
  );

  const renderButtons = () => {
    if (promotionName) {
      return renderButtonsPromotion();
    }

    return renderButtonsDefault();
  };

  const renderFooter = () => (
    <>
      {renderButtons()}
      <Box my={-4}>
        <Box textAlign="center" mt={{ base: 5, sm: 'unset' }} __css={{ 'div:first-of-type': { mt: 6 } }}>
          <WizardElements.WizardLinkHintContainer>
            <MIFormattedText label="guests.signUp.loginHint" />
          </WizardElements.WizardLinkHintContainer>
          <MILink to={onLoginClick} label="guests.signUp.loginLink" />
        </Box>
      </Box>
      {promotionName && !coupon && (
        <Box mt={5} color="grey.600" textStyle="caption1">
          <MIFormattedText
            label="guests.signUp.promotionDisclaimer"
            values={{
              companyName,
              promotionName,
              tocLink: (...chunks) => (
                <Link
                  href={site.config.agreementLinks.userAgreement}
                  target="_blank"
                  color="blue.500"
                  textDecor="none"
                  _hover={{ textDecor: 'underline' }}
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Box>
      )}
    </>
  );

  return (
    <StepLayout
      title="guests.signUp.title"
      subtitle="guests.signUp.subTitle"
      subTitleValues={{
        companyName,
        promotionName,
      }}
      onPrev={onPrev}
      isLoading={isLoading}
      footer={renderFooter()}
    >
      <Box onKeyDown={onKeyPressed}>
        {errorCode && <UserExistErrorNotificationCard errorCode={errorCode} onLogin={onLoginClick} />}
        <PrivateDataContainer>
          <TextField
            id="email"
            value={email}
            mb={0}
            label="guests.signUp.email"
            autoComplete="username email"
            type="email"
            errorMessage={validationErrors.email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
            placeholder="guests.signUp.emailPlaceholder"
            isRequired
            autoFocus
          />
        </PrivateDataContainer>
      </Box>
    </StepLayout>
  );
};

export default GuestSignUpPage;
