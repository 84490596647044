import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import organizationStore from 'src/modules/organizations/organizations-store';
import { getOrgId } from 'src/redux/user/selectors';
import { OrganizationPreferencesType } from 'src/utils/types';

type UpdateOrganizationPreferenceParams = {
  orgId?: number;
  key: keyof OrganizationPreferencesType;
  value: string;
};

export const useUpdateOrganizationPreference = () => {
  const currentOrgId = useSelector(getOrgId);
  const isUpdatingOrganizationPreference = useSelector(organizationStore.selectors.isOrganizationPreferencesUpdating);
  const organizationActions = useStoreActions(organizationStore);

  const updateOrganizationPreference = useCallback(
    async ({ key, value, orgId }: UpdateOrganizationPreferenceParams) =>
      organizationActions.updateOrganizationPreference({
        orgId: orgId || currentOrgId,
        key,
        value,
      }),
    [currentOrgId]
  );

  return { updateOrganizationPreference, isUpdatingOrganizationPreference };
};
