import { Location } from 'history';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { ButtonVariant, WizardProgressBar } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { MINotificationCardProps } from '../common/MINotificationCard';
import * as WizardElements from './MCBWizardElements';
import { StepHeader } from './StepHeader';

type Props = {
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  title?: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  exitLink?: string | Location;
  goExit?: () => void | null;
  children?: React.ReactNode;
  relativeStep?: number | null;
  onPrev?: () => void | null;
  isPrevDisabled?: boolean;
  onNext?: () => void | Promise<void> | null;
  nextLabel?: string;
  isNextDisabled?: boolean;
  onSubmit?: () => void | boolean | Promise<void> | null;
  isLoading?: boolean;
  innerSize?: number;
  isHideActions?: boolean;
  footer?: React.ReactNode | null;
  illustration?: string;
  progressBarSteps?: string[];
  minorSteps?: number[];
  progressBarType?: WizardProgressBar;
  ctaVariant?: ButtonVariant;
  fullWidthCTA?: boolean;
  hideHeader?: boolean;
  notification?: MINotificationCardProps;
  isSandboxIndicatorShown?: boolean;
  docked?: boolean;
  testId?: string | null;
};

export const MCBStepLayoutPage = ({
  title = undefined,
  titleValues = {},
  subtitle,
  exitLink,
  goExit,
  children,
  relativeStep,
  onPrev,
  isPrevDisabled,
  onNext,
  nextLabel,
  isNextDisabled,
  onSubmit,
  isLoading,
  innerSize = 50,
  isHideActions,
  footer,
  illustration = '',
  progressBarSteps = [],
  minorSteps = [],
  progressBarType = WizardProgressBar.WITHOUT_SUBSTEPS,
  subTitleValues,
  ctaVariant = ButtonVariant.PRIMARY,
  fullWidthCTA,
  testId,
}: Props) => {
  const stepTestId = testId || 'step-title';
  const subtitleTestId = testId || 'step-subtitle';
  const submitMethod = onSubmit || onNext;
  const nextActionLabel = nextLabel || 'progress.continue';
  const buttonContainerWidth = fullWidthCTA ? '45' : 'auto';
  // perform Next or Submit action on "Enter" key
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (submitMethod && !isNextDisabled && isEnterPressed(event)) {
      submitMethod();
    }
  };

  const handleNextClick = () => {
    if (submitMethod) {
      submitMethod();
    }
  };

  const handlePrevClick = () => {
    if (onPrev) {
      onPrev();
    }
  };
  const isSubtitle = !!subtitle;
  const hasIllustration = !!illustration;

  return (
    <WizardElements.WizardContainer onKeyDown={onKeyPressed}>
      <StepHeader
        onPrev={handlePrevClick}
        exitLink={exitLink}
        goExit={goExit}
        relativeStep={relativeStep}
        isPrevDisabled={isPrevDisabled}
        isPrev={!!onPrev}
        progressBarSteps={progressBarSteps}
        minorSteps={minorSteps}
        progressBarType={progressBarType}
      />

      <WizardElements.WizardInner>
        {hasIllustration && <WizardElements.WizardIllustration src={illustration} />}
        <WizardElements.WizardStepTitle isSubtitle={isSubtitle} data-testid={stepTestId}>
          {title && <MIFormattedText label={title} values={titleValues} />}
        </WizardElements.WizardStepTitle>

        {subtitle && (
          <WizardElements.WizardStepSubTitle data-testid={subtitleTestId}>
            <MIFormattedText label={subtitle} values={subTitleValues} />
          </WizardElements.WizardStepSubTitle>
        )}

        <WizardElements.WizardInputs innerSize={innerSize}>{children}</WizardElements.WizardInputs>

        <WizardElements.WizardFooterContainer>{footer}</WizardElements.WizardFooterContainer>
      </WizardElements.WizardInner>
      {!isHideActions && submitMethod && (
        <WizardElements.WizardStepActionsContainer>
          <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth}>
            <Button
              label={nextActionLabel}
              variant={ButtonVariants[ctaVariant]}
              onClick={handleNextClick}
              isLoading={isLoading}
              isDisabled={isNextDisabled}
              isFullWidth
            />
          </WizardElements.WizardButtonContainer>
        </WizardElements.WizardStepActionsContainer>
      )}
    </WizardElements.WizardContainer>
  );
};
