const baseUrl = '/orgs/:orgId/get-pro';

export const getProLocations = {
  base: baseUrl,
  dashboard: baseUrl,
  edit: `${baseUrl}/edit`,
  view: `${baseUrl}/view`,
  invoice: `${baseUrl}/invoice`,
  customer: `${baseUrl}/customer`,
  invoicePdfPreview: `${baseUrl}/invoice-pdf-preview`,
  batchSend: `${baseUrl}/batch-send`,
};
