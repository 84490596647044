import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { CloseButton } from 'src/core/ds/closeButton/CloseButton';

export type TooltipContent = {
  closeTooltip: () => void;
  showClose?: boolean;
  location: 'bill' | 'vendor';
};

export function TooltipContent({ closeTooltip, showClose, location }: TooltipContent) {
  return (
    <Box alignItems="start">
      <Box display="flex" justifyContent="space-between">
        <Box textStyle="body4Semi">
          <MIFormattedText label={`vendorDirectory.education.tooltipTitle${location}`} />
        </Box>
        {showClose && (
          <CloseButton
            onClick={closeTooltip}
            size="sm"
            w={2}
            h={2}
            fontSize="0.8rem"
            mt={1}
            color="white"
            _hover={{ color: 'grey.600' }}
          />
        )}
      </Box>
      <Box textStyle="body4" textAlign="left">
        <MIFormattedText label="vendorDirectory.education.tooltipText" />
      </Box>
    </Box>
  );
}
