import * as React from 'react';
import { FileLoader } from 'src/components/common/FileLoader';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { ButtonVariants } from 'src/core/ds/button';
import { IconNames } from 'src/core/ds/icon';
import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';

type ErrorProps = {
  onPrev: () => void;
  title?: string;
  subtitle?: string;
  buttonLabel?: string;
};

type SupportLinkProps = {
  children?: React.ReactNode;
  href: string;
};

type FileUploadingProps = {
  onPrev: () => void;
  title: string;
  subtitle: string;
  buttonLabel: string;
};

type ColumnExplainProps = {
  title: string;
  subtitle: string;
  onNext: () => void;
  onPrev: () => void;
  onExit?: () => void;
};

export const UploadError: React.FC<ErrorProps> = ({ onPrev, subtitle, buttonLabel, title }) => {
  const site = useSiteContext();

  return (
    <SuccessLayoutPage
      icon={IconNames.cancelFill}
      text={subtitle}
      textValues={{
        email: <SupportLink href={`mailto:${site.config.support.email}`}>{site.config.support.email}</SupportLink>,
      }}
      title={title}
      secondaryButtonLabel={buttonLabel}
      secondaryButtonAction={onPrev}
    />
  );
};

const SupportLink: React.FC<SupportLinkProps> = ({ children, href }) => (
  <Link href={href} textDecor="none" color="primary.500">
    {children}
  </Link>
);

export const NoCsvDataToUploadError: React.FC<ErrorProps> = ({ onPrev, title, buttonLabel }) => (
  <SuccessLayoutPage
    icon={IconNames.cancelFill}
    title={title}
    secondaryButtonLabel={buttonLabel}
    secondaryButtonAction={onPrev}
  />
);

export const FileUploading: React.FC<FileUploadingProps> = ({ onPrev, title, subtitle, buttonLabel }) => (
  <FileLoader
    title={title}
    subtitle={subtitle}
    buttonLabel={buttonLabel}
    onCancelAction={onPrev}
    buttonVariant={ButtonVariants.tertiaryNaked}
  />
);

export const ColumnExplain: React.FC<ColumnExplainProps> = ({ onNext, onPrev, onExit, title, subtitle }) => (
  <StepLayoutPage title={title} subtitle={subtitle} onNext={onNext} onPrev={onPrev} goExit={onExit} />
);
