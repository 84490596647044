import { useEffect } from 'react';
import { generatePath, Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { CheckAlreadyDepositedPage } from 'src/pages/vendor/components/CheckAlreadyDepositedPage';
import { InvalidTokenPage } from 'src/pages/vendor/components/InvalidTokenPage';
import { PaymentFailedPage } from 'src/pages/vendor/components/PaymentFailedPage';
import AddAddressDetailsPagePushToDebit from 'src/pages/vendor/shift-vendor-to-debit/AddAddressDetailsPage';
import { AddDeliveryMethodPageContainer } from 'src/pages/vendor/shift-vendor-to-debit/AddDeliveryMethodPageContainer';
import AddDeliveryMethodSuccessPagePushToDebit from 'src/pages/vendor/shift-vendor-to-debit/AddDeliveryMethodSuccessPage';
import { CheckOfferExpiredPage } from 'src/pages/vendor/shift-vendor-to-debit/CheckOfferExpiredPage';
import { useShiftToDebit } from 'src/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import { analytics } from 'src/services/analytics';
import { vendorLocations } from '../locations';

const ANALYTICS_TAG_NAME = 'ShiftToDebitRouter';

const useShiftToDebitRouterAnalytics = (state) => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  // use paymentId from token for analytics in error pages
  const { paymentId, vendorId } = getJWTPayload(token);
  const originDeliveryType = state.payment?.deliveryMethod?.deliveryType;
  const { contactEmail: vendorEmail } = state.vendor ?? {};

  const updateExtraProperties = () => {
    // we set extra properties when we return an error code instead
    // of the payment
    analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
      paymentId,
      vendorId,
      vendorEmail,
      originDeliveryType,
      siteConfig: 'melio',
    });

    // but p2d flow starts only if we have vendor and payment
    if (state.payment && state.vendor) {
      analytics.trackAction('ptd-starts');
    }

    const cleanExtraProperties = () => {
      analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
    };

    return cleanExtraProperties;
  };

  useEffect(updateExtraProperties, [state.payment, state.vendor]);
};

export const ShiftToDebitRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const location = useLocation();
  const { state, deliveryMethodMV, onValidateAddress } = useShiftToDebit(token);
  const { payment, organization } = state;

  useShiftToDebitRouterAnalytics(state);

  return (
    <Switch>
      <SmartRoute path={vendorLocations.shiftToDebit.index} exact>
        <Redirect
          to={{ pathname: generatePath(vendorLocations.shiftToDebit.addMethod, { token }), state: location.state }}
        />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.addMethod} exact>
        <AddDeliveryMethodPageContainer token={token} state={state} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.addAddress} exact>
        <AddAddressDetailsPagePushToDebit
          token={token}
          state={state}
          deliveryMethodMV={deliveryMethodMV}
          onValidateAddress={onValidateAddress}
        />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.success} exact>
        <AddDeliveryMethodSuccessPagePushToDebit state={state} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.checkOfferExpired} exact>
        <CheckOfferExpiredPage state={state} token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.checkDeposited} exact>
        <CheckAlreadyDepositedPage payment={payment} organization={organization} token={token} hideLogin />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.paymentFailed} exact>
        <PaymentFailedPage organization={organization} hideLogin />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.paymentBlocked} exact>
        <PaymentFailedPage organization={organization} hideLogin />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.invalidToken} exact>
        <InvalidTokenPage token={token} hideLogin />
      </SmartRoute>
    </Switch>
  );
};
