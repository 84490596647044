import React, { useMemo } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import Tooltip from 'src/core/ds/tooltip';

export const StatusBadge = ({ status }: { status: string }) => {
  const TXN_STATUS_COLORS = {
    ACTIVE: BadgeColors.SUCCESS,
  };
  const badgeColor = TXN_STATUS_COLORS[`${status}`] || BadgeColors.NEUTRAL;

  return useMemo(
    () => (
      <Tooltip
        placement="top"
        label={<MIFormattedText label={`spendManagement.pages.dashboard.cardStatus.${status}.description`} />}
        shouldWrapChildren
      >
        <Badge color={badgeColor} label={`spendManagement.pages.dashboard.cardStatus.${status}.label`} />
      </Tooltip>
    ),
    [status, badgeColor]
  );
};
