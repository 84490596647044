import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Header } from 'src/pages/get-paid/list/components/batchActions/BatchElements';
import DeleteFailedInvoicesButton from 'src/pages/get-paid/list/components/batchActions/DeleteFailedInvoicesButton';
import DeleteSentInvoicesButton from 'src/pages/get-paid/list/components/batchActions/DeleteSentInvoicesButton';
import DeleteUnsentInvoicesButton from 'src/pages/get-paid/list/components/batchActions/DeleteUnsentInvoicesButton';
import LeftHeaderText from 'src/pages/get-paid/list/components/LeftHeaderText';
import { getSelectedTab } from 'src/pages/get-paid/utils';
import { PaymentRequestTabs } from 'src/utils/consts';
import { useQueryState, useQueryString } from 'src/utils/hooks';

type Props = {
  numOfInvoices: number;
  totalAmount: number;
};

const MobileBottomInfoCard = ({ numOfInvoices, totalAmount }: Props) => {
  const query = useQueryString();
  const currentTab = getSelectedTab(query);
  const [, setQuerySinglePageView] = useQueryState('singlePageView', undefined, true);
  const { UNSENT, SENT } = PaymentRequestTabs;

  const getMobileDeleteButton = () => {
    switch (currentTab) {
      case UNSENT:
        return <DeleteUnsentInvoicesButton numOfInvoices={numOfInvoices} totalAmount={totalAmount} />;
      case SENT:
        return <DeleteSentInvoicesButton numOfInvoices={numOfInvoices} totalAmount={totalAmount} />;
      default:
        return <DeleteFailedInvoicesButton />;
    }
  };

  const getHeaderLabel = () => {
    switch (currentTab) {
      case UNSENT:
        return 'getPaid.view.batch.title.unsent';
      case SENT:
        return 'getPaid.view.batch.title.sent';
      default:
        return '';
    }
  };

  const onClickSendButton = () => {
    setQuerySinglePageView('true');
  };

  return (
    <Header>
      <LeftHeaderText
        numOfInvoices={numOfInvoices}
        totalAmount={totalAmount}
        headerLabel={getHeaderLabel()}
        descriptionLabel="getPaid.view.batch.description"
      />
      <Flex direction="column" mt={5}>
        {getMobileDeleteButton()}
        {currentTab === UNSENT && (
          <Button
            label="getPaid.view.batch.send"
            variant={ButtonVariants.success}
            onClick={onClickSendButton}
            px={2}
            mt={2}
          />
        )}
      </Flex>
    </Header>
  );
};

export default MobileBottomInfoCard;
