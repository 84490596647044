import { CompanyType, DeliveryType, FundingSourceOrigin, FundingType } from 'src/utils/consts';
import { GlobalState } from '../types';

export const getBankAccounts = (state: GlobalState) =>
  state.user.fundingSources.filter(
    (fs) => fs.fundingType === FundingType.ACH && fs.origin !== FundingSourceOrigin.QBCASH
  );
export const getFundingSources = (state: GlobalState) => state.user.fundingSources;
export const getIsLoading = (state: GlobalState) => state.user.isLoading;
export const getProfile = (state: GlobalState) => state.user.profile;
export const getUserProfileById = (state: GlobalState) => (userId: number) => state.users.byId[userId];
export const getOrgId = (state: GlobalState) => state.user.profile?.orgId;
export const getIsIntuitLinked = (state: GlobalState) =>
  state.user.profile && state.organizations.byId[state.user.profile.orgId]?.isIntuitLinked;
export const getIsFundingSourceDeleting = (state: GlobalState) => state.user.isFundingSourceDeleting;
export const getIsRemovingFundingSourceLabel = (state: GlobalState) => state.user.isRemovingFundingSourceLabel;
export const getIsFundingSourceVerifying = (state: GlobalState) => state.user.isFundingSourceVerifying;
export const getDeliveryMethods = (state: GlobalState) =>
  state.user.deliveryMethods.filter((dm) => dm.deliveryType === DeliveryType.ACH);
export const getCompanyInfo = (state: GlobalState) => state.user.companyInfo;
export const getCompanyInfoLoadingStatus = (state: GlobalState) => state.user.isLoading;
export const getBillingDetails = (state: GlobalState) => state.user.companyInfo.billingSetting;
export const getOwnedVendorId = (state: GlobalState) => state.user.companyInfo.ownedVendorId;
export const getOwnedVendorHandle = (state: GlobalState) => state.user.companyInfo.ownedVendorHandle || '';
export const getUserPreferences = (state: GlobalState) => state.user.userPreferences;
export const getCanContactSupport = (state: GlobalState) => state.user.companyInfo.allowedActions.canContactSupport;
export const getIsUserChecked = (state: GlobalState) => state.user.isUserChecked;
export const getIsLoggedIn = (state: GlobalState) => state.user.isLoggedIn;
export const getIsLoggedInAs = (state: GlobalState) => state.user.isLoggedInAs;
export const getOriginPlaidItemId = (state: GlobalState) => state.user.originPlaidItemId;
export const getIsAccountantFirm = (state: GlobalState) =>
  getCompanyInfo(state).companyType === CompanyType.ACCOUNTING_FIRM;
