import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { PaymentFinancing } from 'src/utils/types';
import { AmountCell } from '../AmountCell';

type InstallmentsViewFooterExpandedPropsType = Pick<PaymentFinancing, 'totalAmount' | 'totalFees' | 'totalFeePercent'>;

export const InstallmentsListViewFooterExpanded = ({
  totalAmount,
  totalFees,
  totalFeePercent,
}: InstallmentsViewFooterExpandedPropsType) => (
  <>
    <Flex w="full" textStyle="body4Semi" color="black" pt={3}>
      <Box flexGrow={1}>
        <MIFormattedText label="financing.installmentsList.totalAmount" />
      </Box>
      <AmountCell amount={totalAmount} />
    </Flex>
    <Flex w="full" textStyle="body4" color="grey.700" pt={1}>
      <Box flexGrow={1}>
        <MIFormattedText
          label="financing.installmentsList.totalFees"
          values={{
            totalFeePercent,
          }}
        />
      </Box>
      <AmountCell amount={totalFees} />
    </Flex>
  </>
);
