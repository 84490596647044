import { useFeature } from '@melio/shared-web/dist/feature-flags';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { DeliveryMethodOptionsList } from 'src/components/list/DeliveryMethodOptionsList';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { useInternationalDetails } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalDetails';
import {
  getInternationalDescription,
  isFundingSourceAllowedForInternational,
} from 'src/pages/vendor/international-delivery-method/utils';
import { getDeliveryMethodByType } from 'src/pages/vendor/records';
import { getOrgId } from 'src/redux/user/selectors';
import { DeliveryMethodOrigin, DeliveryType, FeatureFlags } from 'src/utils/consts';
import { getAddDeliveryMethodUrlMap } from 'src/utils/delivery-methods';
import { useLocationState } from 'src/utils/hooks';

export const AddNewDeliveryMethodPage = () => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const { billsList: batchItems, getSingleBatchItem } = useBatchBillsList();
  const [itemId] = useLocationState<string>('itemId');
  const batchActions = useStoreActions(regularBatchPaymentsStore);
  const fetchSelector = vendorsStore.selectors.fetch;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const batchItem = getSingleBatchItem(itemId!);
  const vendor = useSelector(fetchSelector.byId(batchItem.payment.vendorId));
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(batchItem.payment.fundingSourceId));
  const showVirtualDeliveryMethodOption = isEmpty(vendor?.deliveryMethods) && fundingSource?.isVerified;
  const { blockEmailForInternationalPayment } = useInternationalDetails();
  const deliveryMethodOptions = useMemo(() => {
    const ach = getDeliveryMethodByType(DeliveryType.ACH, vendor?.deliveryMethods);
    const check = getDeliveryMethodByType(DeliveryType.CHECK, vendor?.deliveryMethods);
    const international = getDeliveryMethodByType(DeliveryType.INTERNATIONAL, vendor?.deliveryMethods);
    const virtual = getDeliveryMethodByType(DeliveryType.VIRTUAL, vendor?.deliveryMethods);
    const options = { domestic: [ach, check], international: [international] };

    if (showVirtualDeliveryMethodOption) {
      options.domestic.push(virtual);
    }

    return options;
  }, [showVirtualDeliveryMethodOption, vendor?.deliveryMethods]);
  const [isInternationalMastercardEnabled] = useFeature(FeatureFlags.EnableMastercardFSForInternational, false);

  const isLocalVendor = vendor?.deliveryMethods.some((dm) => dm.deliveryType !== DeliveryType.INTERNATIONAL);
  const isInternationalDeliveryMethodDisabled = !isFundingSourceAllowedForInternational({
    fundingSource,
    isInternationalMastercardEnabled,
  });
  const isInternationalDescription = getInternationalDescription({
    fundingSource,
    isInternationalMastercardEnabled,
  });

  const goAddDeliveryMethod = async (type: DeliveryType) => {
    const deliveryMethodUrlMap = getAddDeliveryMethodUrlMap(orgId, vendor?.id);
    const isDeliveryMethodTypeExist = vendor?.deliveryMethods?.find((dm) => dm.deliveryType === type);
    const url = isDeliveryMethodTypeExist
      ? batchPaymentsLocations.base
      : deliveryMethodUrlMap[type || DeliveryType.ACH];
    const billsToUpdate =
      vendor?.deliveryMethods?.length > 0
        ? [itemId]
        : batchItems.filter((batchItem) => batchItem.vendor.id === vendor.id).map((batchItem) => batchItem.id);

    await batchActions.updatePayments.setBillsToUpdate({ billsToUpdate });
    history.push({
      pathname: url,
      state: {
        redirectUrl: batchPaymentsLocations.base,
        exitUrl: batchPaymentsLocations.base,
        origin: DeliveryMethodOrigin.BATCH_PAY_BILLS,
        batchPaymentItemId: itemId,
      },
    });
  };

  const onExit = () => {
    history.push(generatePath(batchPaymentsLocations.base, { orgId }), {
      batchActionMerge: true,
    });
  };

  return (
    <StepLayoutPage
      title="bills.pay.deliveryMethods.title"
      titleValues={{ vendor: vendor?.companyName }}
      goExit={onExit}
      onPrev={onExit}
    >
      <DeliveryMethodOptionsList
        deliveryMethodOptions={deliveryMethodOptions}
        showFullDeliveryMethodsList={!isLocalVendor && !blockEmailForInternationalPayment}
        companyName={vendor?.companyName}
        goAddDeliveryMethod={goAddDeliveryMethod}
        isInternationalDeliveryMethodDisabled={isInternationalDeliveryMethodDisabled}
        internationalMethodDesc={isInternationalDescription}
      />
    </StepLayoutPage>
  );
};
