import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  dismiss: () => void;
};

const FailedCreatePaymentModal = ({ dismiss }: Props) => (
  <Dialog
    type={DialogType.CONFIRM}
    variant={DialogVariants.WARNING}
    title="regularBatchPayments.timeoutError.title"
    subtitle="regularBatchPayments.timeoutError.subtitle"
    onCancelAction={dismiss}
    showCancel={false}
  >
    <Flex justify="flex-end" w="100%">
      <Button
        textTransform="uppercase"
        variant={ButtonVariants.tertiaryNaked}
        onClick={dismiss}
        label="dialogs.buttons.close"
      />
    </Flex>
  </Dialog>
);

export default FailedCreatePaymentModal;
