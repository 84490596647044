import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import { internationalCountries } from 'src/pages/vendor/international-delivery-method/vendor-details/internationalCountries';
import { Currency, DeliveryMethodOrigin, DeliveryType, FundingType } from 'src/utils/consts';
import { isCreditCard, isDebitCard, isMastercard } from 'src/utils/funding-sources';
import { AccountType, DeliveryMethodType, VendorType } from 'src/utils/types';
import { MakeCompulsory } from 'src/utils/utility-types';
import { InternationalWizardState } from './hooks/useInternationalWizard';

/* eslint-disable camelcase */
export type BankDetailsType = {
  bank_name: string;
  account_number: string;
  bic_swift: string;
  bank_address: string;
};

export const requiredFields = {
  fullName: `^.{1,255}`,
  companyName: `^.{1,255}`,
  addressLine1: `^.{1,255}`,
  city: `^.{1,255}`,
  country: `^.{1,255}`,
  state: `^.{1,255}`,
  zipCode: `^.{1,255}`,
  swift: `^[0-9A-Z]{8}$|^[0-9A-Z]{8,11}$`,
  iban: `([A-Z0-9]\\s*){15,34}`,
};

export type RequiredFieldsType = {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  addressLine1?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  iban?: string;
  swift?: string;
  accountNumber?: string;
  bankName?: string;
};

export const bankAccountRequiredFields = {
  accountNumber: `^.{1,255}`,
  bankName: `^.{1,255}`,
};

type IsFundingSourceAllowedForInternational = {
  fundingSource?: AccountType;
  isInternationalMastercardEnabled?: boolean;
};
export const isFundingSourceAllowedForInternational = ({
  fundingSource,
  isInternationalMastercardEnabled,
}: IsFundingSourceAllowedForInternational): boolean => {
  const isDebit = isDebitCard(fundingSource);
  const isCredit = isCreditCard(fundingSource);
  const isMastercardType = isMastercard(fundingSource);

  if (isCredit && !isInternationalMastercardEnabled) return false;

  if (isDebit || (isCredit && !isMastercardType)) return false;

  return true;
};

type GetInternationalDescriptionType = {
  fundingSource?: AccountType;
  isInternationalMastercardEnabled?: boolean;
};
export const getInternationalDescription = ({
  fundingSource,
  isInternationalMastercardEnabled,
}: GetInternationalDescriptionType): string => {
  const isDebit = isDebitCard(fundingSource);
  const isCredit = isCreditCard(fundingSource);
  const isMastercardType = isMastercard(fundingSource);

  if (isDebit) return 'deliveryMethods.notDefinedInfo.international.disabledDebitCards';

  if (isCredit && !isInternationalMastercardEnabled)
    return 'deliveryMethods.notDefinedInfo.international.disabledDescCard';

  if (isCredit && !isMastercardType) return 'deliveryMethods.notDefinedInfo.international.disabledRestCards';

  return '';
};

export const isInternationalDeliveryMethod = (deliveryMethodType: DeliveryType) =>
  deliveryMethodType === DeliveryType.INTERNATIONAL;

export const isInternationalVendor = (vendor: VendorType) =>
  vendor?.deliveryMethods?.some((dm) => isInternationalDeliveryMethod(dm.deliveryType));

export const getPayeeBankCountryLabel = (countryCode: string) => internationalCountries[countryCode];

export const blockBatchSelectionForInternational = (
  deliveryMethods: readonly DeliveryMethodType[],
  fundingSources: readonly AccountType[]
) =>
  deliveryMethods?.some((dm) => dm.deliveryType === DeliveryType.INTERNATIONAL) &&
  fundingSources?.every((fs) => fs.fundingType === FundingType.CARD);

export type InternationalAccountDataType = {
  internationalAccount: {
    payeeAccount: {
      name: string;
      companyName: string;
      bankAccountHolderName: string;
      bankCountry: string;
      currency: Currency;
      address?: string;
      country: string;
      bicSwift?: string;
      bankAddress?: string;
      bankName?: string;
      city: string;
      accountNumber?: string;
      postcode?: string;
      state?: string;
      iban?: string;
    };
  };
};

export const mapDataToInternationalAccount = ({
  wizardState,
}: {
  wizardState: MakeCompulsory<
    InternationalWizardState,
    'fullName' | 'companyName' | 'bankCountry' | 'country' | 'city'
  >;
}): InternationalAccountDataType => {
  const {
    fullName,
    iban,
    details,
    bankCountry,
    country,
    googleCombinedAddress,
    zipCode,
    city,
    state,
    accountNumber,
    bankName,
    companyName,
  } = wizardState;

  return {
    internationalAccount: {
      payeeAccount: {
        name: fullName,
        companyName,
        bankAccountHolderName: fullName,
        bankCountry,
        currency: Currency.USD,
        address: googleCombinedAddress?.formattedAddress ?? undefined,
        country,
        bicSwift: details?.bic_swift,
        bankAddress: details?.bank_address || '6456456546',
        bankName,
        city,
        accountNumber,
        postcode: zipCode,
        state,
        ...(iban && { iban }),
      },
    },
  };
};

export const getInternationalCountryCode = (code) => {
  switch (code.toUpperCase()) {
    case 'UK':
    case 'GB':
      return 'GB-UKM';

    default:
      return code;
  }
};

export const getIsComingFromPayBillFlow = (origin: string) => origin === DeliveryMethodOrigin.PAY_BILL;
export const isFromOnboarding = (origin?: string) => origin === DeliveryMethodOrigin.NEW_VENDOR_FROM_ONBOARDING;
export const getIsComingFromVendorDetails = (origin: string) =>
  origin === DeliveryMethodOrigin.VENDOR_DETAILS || origin === DeliveryMethodOrigin.NEW_VENDOR;
export const isEmailBlockedForInternational = (email: string) => email.includes('+') && !email.includes('melio');

export const separateWordsAndCapitalize = (text = '') => capitalize(startCase(text));
