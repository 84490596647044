import { formAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction } from '@chakra-ui/theme-tools';

const calcMb = (mb) => {
  switch (mb) {
    case 0:
      return 0;
    case 'sm':
      return 0;
    default:
      return 10;
  }
};

const baseStyleRequiredIndicator = () => ({
  display: 'none',
});

const baseStyleHelperText = (props) => ({
  mt: props.size === 'sm' ? 0 : 1,
  cursor: 'default',
  userSelect: 'none',
  color: props.isDisabled ? 'grey.400' : 'grey.600',
  apply: props.size === 'sm' ? 'textStyles.body4' : 'textStyles.body3',
  textStyle: props.size === 'sm' ? 'caption1' : undefined,
});

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  container: { width: '100%', position: 'relative', mb: calcMb(props?.mb || props?.size) },
  requiredIndicator: baseStyleRequiredIndicator(),
  helperText: baseStyleHelperText(props),
});

export default {
  parts: parts.keys,
  baseStyle,
};
