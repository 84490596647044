import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { GetProTabsSummary, GetProTabsSummaryParams } from 'src/pages/get-pro/types';
import { GetProTabs } from 'src/utils/consts';
import { getProApi } from './api';
import { name } from './consts';

type PayloadType = Record<GetProTabs, GetProTabsSummary>;

type StateType = {
  proTabsSummary: {
    loading: boolean;
    error?: Error;
    tabs: PayloadType | undefined;
  };
};

export const getProTabsSummarySlice = createApiCallSlice<GetProTabsSummaryParams, StateType, PayloadType>({
  api: getProApi.summary,
  initialState: {
    proTabsSummary: {
      loading: false,
      error: undefined,
      tabs: undefined,
    },
  },
  name: generateSliceName(name, 'get_summary_tabs'),
  reducers: {
    [ON_REQUEST]: (state) => {
      state.proTabsSummary = {
        ...state.proTabsSummary,
        loading: true,
        error: undefined,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      state.proTabsSummary = {
        loading: false,
        tabs: action?.payload,
      };
    },
    [ON_FAILURE]: (state, action) => {
      state.proTabsSummary = {
        loading: false,
        tabs: undefined,
        error: action?.error,
      };
    },
  },
  selectors: {
    tabsSummary: (state) => state[name]?.proTabsSummary.tabs,
    loading: (state) => state[name]?.proTabsSummary.loading,
  },
});
