import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  DebitFeeNotificationModal,
  PaymentFlow,
} from 'src/components/debit-fee-notification-modal/DebitFeeNotificationModal';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box/Box';
import { useModal } from 'src/helpers/react/useModal';
import { useBreak } from 'src/hoc';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { trackLoadBillsAction } from 'src/pages/regular-batch-payments/analytics/BatchPaymentsAnalytics';
import FailedCreatePayment from 'src/pages/regular-batch-payments/components/dialog/FailedCreatePaymentModal';
import { Footer } from 'src/pages/regular-batch-payments/components/footer/Footer';
import { Header } from 'src/pages/regular-batch-payments/components/header/Header';
import PageLayout from 'src/pages/regular-batch-payments/components/layout/PageLayout';
import UnsupportedResolutionLayout from 'src/pages/regular-batch-payments/components/layout/UnsupportedResolutionLayout';
import { NotificationCardChecksDelays } from 'src/pages/regular-batch-payments/components/notificationCards/NotificationCardChecksDelays';
import { NotificationCardUnreadyBills } from 'src/pages/regular-batch-payments/components/notificationCards/NotificationCardUnreadyBills';
import { FailedBillsListModal } from 'src/pages/regular-batch-payments/components/wizard/BatchPaymentsSuccess/FailedBillsListModal/FailedBillsListModal';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import { useCreateBatchPayments } from 'src/pages/regular-batch-payments/hooks/useCreateBatchPayments';
import { BatchPaymentsTable } from 'src/pages/regular-batch-payments/table/BatchPaymentsTable';
import { DeliveryType, FeatureFlags } from 'src/utils/consts';
import { parseQueryString } from 'src/utils/query-utils';
import { useBatchDebitFee } from './hooks/useBatchDebitFee';

const sxForCard: CSSObject = {
  mb: 6,
  mt: 6,
};

export const BatchPaymentsPage = () => {
  const { isBillListLoading, billsList } = useBatchBillsList();
  const { isInternationalAllowed } = useIsInternationalAllowed();
  const history = useHistory();
  const { isDesktop } = useBreak();
  const [isAmexAddressFeatureEnabled] = featureFlags.useFeature(FeatureFlags.AmexVendorAddressBatchFlow, false, {
    shouldTrack: false,
  });
  const { billIds, exitLocation } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const allBillsReady = useSelector(regularBatchPaymentsStore.selectors.allBillsReady(isAmexAddressFeatureEnabled));
  featureFlags.useFeature(FeatureFlags.ShouldUseEtaApiServiceForDeliveryDate, false); // called to report the variant event
  const {
    createBatchPayment,
    createBatchPaymentData: { scheduledPaymentsCount, failedPaymentsCount, isCreating, error },
    vendorsAddressesAreUpdating,
  } = useCreateBatchPayments();
  const {
    isDebitFeeNotificationOpen,
    setIsDebitFeeNotificationOpen,
    shouldShowDebitFeeNotificationModal,
    handleCloseDebitFeeNotificationModal,
    handleConfirmDebitFeeNotificationModal,
  } = useBatchDebitFee(createBatchPayment);
  const hasPaymentWithCheckDM = billsList.find(
    (bill) => bill.payment.deliveryMethod?.deliveryType === DeliveryType.CHECK
  );
  const hasPaymentWithInternationalDM = billsList.find(
    (bill) => bill.payment.deliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL
  );
  const isInternationalDisabled = !isInternationalAllowed && !!hasPaymentWithInternationalDM;
  const createBatchPaymentTooltip = isInternationalDisabled
    ? {
        label: 'regularBatchPayments.internationalDisabledTooltip',
      }
    : null;
  const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.USHolidaysChecks, false);
  const showUnreadyBillsNotification = !allBillsReady && error?.validationErrors?.payments;
  const showChecksDelaysNotification = isChecksDelaysEnabled && !showUnreadyBillsNotification && hasPaymentWithCheckDM;

  const [FailedCreatePaymentModal, showFailedCreatePaymentModal] = useModal(FailedCreatePayment, {
    modalName: 'FailedCreatePaymentModal',
  });

  const [AllFailedBillsListModal, showAllFailedBillsListModal] = useModal(FailedBillsListModal, {
    modalName: 'AllFailedBillsListModal',
  });

  const goBack = useCallback(() => {
    history.push(exitLocation);
  }, [history, exitLocation]);

  const cancel = () => {
    const query = parseQueryString(exitLocation.search);
    history.push({
      ...exitLocation,
      search: qs.stringify(omit(query, 'selectedIds')),
    });
  };

  const handleCreateBatchPayment = async () => {
    if (shouldShowDebitFeeNotificationModal) {
      setIsDebitFeeNotificationOpen(true);
    } else {
      await createBatchPayment();
    }
  };

  useEffect(() => {
    if (billsList.length && !isBillListLoading) {
      trackLoadBillsAction(billsList);
    }
  }, [isBillListLoading]);
  useEffect(() => {
    if (!scheduledPaymentsCount && failedPaymentsCount) {
      showAllFailedBillsListModal();
    }
  }, [scheduledPaymentsCount, failedPaymentsCount, showAllFailedBillsListModal]);

  useEffect(() => {
    if (isEmpty(billIds)) {
      goBack();
    }
  }, [billIds, goBack]);

  useEffect(() => {
    if (error && isNil(error.code) && isNil(error.validationErrors)) {
      showFailedCreatePaymentModal();
    }
  }, [error, showFailedCreatePaymentModal]);

  if (!isDesktop) {
    return <UnsupportedResolutionLayout />;
  }

  return (
    <>
      {AllFailedBillsListModal}
      {FailedCreatePaymentModal}
      <DebitFeeNotificationModal
        isOpen={isDebitFeeNotificationOpen}
        onClose={handleCloseDebitFeeNotificationModal}
        onConfirm={handleConfirmDebitFeeNotificationModal}
        paymentFlowType={PaymentFlow.Batch}
      />
      <PageLayout goBack={goBack}>
        <Box mt={6} textStyle="h2Semi">
          <MIFormattedText label="regularBatchPayments.title" />
        </Box>
        <Header isLoadingBills={isBillListLoading} />
        <>
          {showUnreadyBillsNotification && <NotificationCardUnreadyBills />}
          {showChecksDelaysNotification && <NotificationCardChecksDelays />}
          <CustomFeed feedType="batchbills_above" sxForCard={sxForCard} />
          <BatchPaymentsTable />
        </>
      </PageLayout>
      <Footer
        createBatchPayment={handleCreateBatchPayment}
        isBatchPaymentCreating={isCreating || vendorsAddressesAreUpdating}
        goBack={cancel}
        isDisabled={isInternationalDisabled}
        createBatchPaymentTooltip={createBatchPaymentTooltip}
      />
    </>
  );
};
