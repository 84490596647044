import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { MouseEvent, ReactNode, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { SearchIcon } from 'src/components/list/SortSearchFilter/SearchIcon';
import { SearchInputContainer } from 'src/components/list/SortSearchFilter/SearchInputContainer';
import { searchInputFadeInAnimation, searchInputFadeOutAnimation } from 'src/components/list/styles';
import Tabs from 'src/components/list/Tabs';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import { useListSearchBarContext } from 'src/hoc/withListSearchBarContext';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { billLocations } from 'src/pages/bill/locations';
import { SortMenu } from 'src/pay/components/SortMenu';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { TabSettingsType } from 'src/utils/types';

type Props = {
  tabs: TabSettingsType[];
  children?: ReactNode;
};

export const iconGroupPortalId = 'icon-group-portal';

const getAnimationStyles = (isSearchOpen: boolean | null) =>
  isNil(isSearchOpen)
    ? {
        animation: searchInputFadeInAnimation,
        animationPlayState: 'paused',
      }
    : { animation: isSearchOpen ? searchInputFadeInAnimation : searchInputFadeOutAnimation };

export const DesktopListHeader = ({ children, tabs }: Props) => {
  const orgId = useSelector(getOrgId);
  const { isDesktop, isMobile } = useBreak();
  const [{ contextSearchInputValue }] = useListSearchBarContext();
  const boxRef = useRef<HTMLDivElement>(null);
  const [isSearchOpen, setIsSearchOpen] = useState<boolean | null>(null);
  const isBillLocation = !isEmpty(useRouteMatch(billLocations.index));

  useOutsideClick(boxRef, (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;

    if (!contextSearchInputValue && !target.closest('.icon-group')) {
      isSearchOpen && setIsSearchOpen(false);
    }
  });

  const showIconsGroup = orgId && isDesktop;
  const onSearchIconClick = () => {
    analytics.trackAction('toggle-search-input', { isOpen: !isSearchOpen });
    setIsSearchOpen(!isSearchOpen);
  };

  const hideSearchInput = () => setIsSearchOpen(false);

  return (
    <Flex mt={{ base: 0, md: 4 }} direction="column" w="full">
      <Flex mb={2} align="flex-end" w="full">
        <>
          <Tabs tabs={tabs} />
          {isMobile && isBillLocation ? (
            <Box position="absolute" right={5} top={5} pt={14} zIndex="dropdown">
              <SortMenu />
            </Box>
          ) : null}
        </>
        {showIconsGroup && (
          <Flex mb={0.5}>
            <Flex className="icon-group" alignItems="center" gridGap="3">
              <Flex className="icon-group" alignItems="center" gridGap="3" h="1.6rem" id={iconGroupPortalId} />
              <Box mt={1}>
                <SearchIcon isActive={!!contextSearchInputValue || !!isSearchOpen} onClick={onSearchIconClick} />
              </Box>
            </Flex>
            {children}
          </Flex>
        )}
      </Flex>
      <Box ref={boxRef} sx={getAnimationStyles(isSearchOpen)} boxSizing="border-box">
        {isSearchOpen && (
          <Box pt={4}>
            <SearchInputContainer hideSearchInput={hideSearchInput} />
          </Box>
        )}
      </Box>
    </Flex>
  );
};
