import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useSelector } from 'react-redux';
import { getFundingSources } from 'src/redux/user/selectors';
import { isCreditAmexNetwork } from 'src/utils/funding-sources';
import { VendorType } from 'src/utils/types';
import { shouldCollectAmexAddressForVendor } from 'src/utils/vendors';

type Props = {
  selectedFundingSourceId?: number;
  vendor?: VendorType;
  featureFlagName: string;
};

export const useAmexVendorAddress = ({ selectedFundingSourceId, vendor, featureFlagName }: Props) => {
  const [amexAddressFeatureFlagValue] = useFeature(featureFlagName, false, {
    shouldTrack: false,
  });
  const fundingSources = useSelector(getFundingSources);

  const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);

  const isAmexCard = isCreditAmexNetwork(selectedFundingSource);
  const vendorHasAddress = !shouldCollectAmexAddressForVendor(vendor);
  const shouldCollectAmexAddress = isAmexCard && !vendorHasAddress;

  const amexAddressExperimentVariant = shouldCollectAmexAddress ? amexAddressFeatureFlagValue : null;

  return {
    amexAddressExperimentVariant,
    vendorHasAddress,
  };
};
