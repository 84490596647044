import { FC } from 'react';
import Box from 'src/core/ds/box/Box';

type Props = {
  translucent: boolean;
};

export const BrandLogoContainer: FC<Props> = ({ translucent, children }) => (
  <Box opacity={translucent ? 0.2 : 1} borderColor="white">
    {children}
  </Box>
);
