import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { LoginFlow, RegistrationFlow, UserAuthType } from 'src/utils/consts';
import { authLocations } from './locations';
import { LoginPageContainer } from './login';
import RegisterPageVendor from './RegisterPageVendor';
import { AuthRegisterRouter } from './registration/AuthRegisterRouter';
import { RequestResetPageContainer } from './RequestResetPageContainer';
import { ResetPasswordPageContainer } from './ResetPasswordPageContainer';

export const AuthRouter = () => (
  <Switch>
    <SmartRoute path={authLocations.login} exact>
      <LoginPageContainer />
    </SmartRoute>
    <SmartRoute path={authLocations.loginSpendManagement} exact>
      <LoginPageContainer loginFlow={LoginFlow.SPEND_MANAGEMENT} />
    </SmartRoute>
    <SmartRoute path={authLocations.register.base}>
      <AuthRegisterRouter />
    </SmartRoute>
    <SmartRoute path={authLocations.resetPassword} exact>
      <ResetPasswordPageContainer />
    </SmartRoute>
    <SmartRoute
      path={authLocations.requestResetPassword}
      exact
      component={RequestResetPageContainer}
      allowFor={[UserAuthType.UNAUTHENTICATED, UserAuthType.GUEST]}
    />
    <SmartRoute
      path={authLocations.registerVendor}
      exact
      component={(props) => <RegisterPageVendor registrationFlow={RegistrationFlow.VENDOR} {...props} />}
      allowFor={[UserAuthType.UNAUTHENTICATED, UserAuthType.GUEST]}
    />
  </Switch>
);
