import pickBy from 'lodash/pickBy';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MINotices } from 'src/components/common/MINotices';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { IconNames } from 'src/core/ds/icon';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import SettingItemDetails from 'src/pages/settings/components/SettingItemDetails';
import { vendorLocations } from 'src/pages/vendor/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { getDeliveryMethodName } from 'src/utils/delivery-methods';
import { DeliveryMethodType, PaymentType } from 'src/utils/types';

type Props = {
  deliveryMethod: DeliveryMethodType;
  token: string;
  payment: PaymentType;
};

export const LinkDeliveryMethod = ({ token, deliveryMethod, payment }: Props) => {
  const history = useHistory();
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const isCopying = useSelector(
    deliveryMethodsStore.selectors.copyFromOwnedVendorWithUnilateralToken.status(deliveryMethod.id)
  )?.loading;
  const currentOrgId = useSelector(getOrgId);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onClick = () => {
    if (currentOrgId === payment?.organizationId) {
      setShowErrorMessage(true);
    } else {
      deliveryMethodActions
        .copyFromOwnedVendorWithUnilateralToken(
          pickBy({
            token,
            deliveryMethodId: deliveryMethod.id,
            paymentId: payment?.id || null,
            orgId: currentOrgId,
          })
        )
        .then(() => {
          history.push(generatePath(vendorLocations.unilateral.success, { token }));
        });
    }
  };

  const deliveryMethodName = getDeliveryMethodName(deliveryMethod);
  const imageObj = {
    logo: deliveryMethod.logo,
  };

  return (
    <>
      <DeliveryMethodItemContainer>
        <SettingItemDetails
          key={deliveryMethod.id}
          iconName={IconNames.bank}
          imageObj={imageObj}
          descriptionLabel="settings.receivingMethods.achDescription"
          descriptionValues={{
            accountName: deliveryMethodName,
          }}
          nameLabel="settings.receivingMethods.ach"
          moduleName="settings-receiving-methods"
          hideAddItemLink
        />
      </DeliveryMethodItemContainer>
      {showErrorMessage && (
        <ErrorMessageContainer>
          <MINotices errorMessage="inputErrors.guest.totalAmount.custom.nopay" />
        </ErrorMessageContainer>
      )}
      <Button
        label={
          payment
            ? 'vendors.addDeliveryMethodByLink.linkExistingMethod.submit'
            : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.linkExistingMethod.submit'
        }
        onClick={onClick}
        isLoading={isCopying}
        size={ButtonSizes.lg}
        isFullWidth
      />
    </>
  );
};

const ErrorMessageContainer = styled.div`
  margin: -2rem 0 1rem;
`;

const DeliveryMethodItemContainer = styled.div`
  margin: 2rem 0 2rem;
  width: 100%;

  > div {
    text-align: left;
  }
`;
