import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Card } from 'src/core/ds/card';
import { Stack } from 'src/core/ds/stack';
import { MsnBusinessType, MsnBusinessTypeEnum } from 'src/utils/types';

const businessTypeOptions = [
  {
    id: MsnBusinessTypeEnum.STANDALONE_BUSINESS,
    label: 'onboarding.msnCompanyInfo.selectBusinessType.standaloneBusiness',
  },
  {
    id: MsnBusinessTypeEnum.PART_OF_A_LARGER_ORGANIZATION,
    label: 'onboarding.msnCompanyInfo.selectBusinessType.partOfALargerOrganization',
  },
  {
    id: MsnBusinessTypeEnum.NOT_SURE,
    label: 'onboarding.msnCompanyInfo.selectBusinessType.notSure',
  },
];

type BusinessTypeSelectorProps = {
  selectedType?: MsnBusinessType | null;
  onTypeClick: (type: MsnBusinessType) => void;
};

export const BusinessTypeSelector = ({ selectedType, onTypeClick }: BusinessTypeSelectorProps) => (
  <Stack spacing={4} textAlign="center" textStyle="body2Semi">
    {businessTypeOptions.map(({ id, label }) => (
      <Card
        key={id}
        id={id}
        isSelected={id === selectedType}
        onClick={() => onTypeClick(id)}
        selectedColor="primary.500"
      >
        <MIFormattedText label={label} />
      </Card>
    ))}
  </Stack>
);
