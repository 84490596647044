import copy from 'copy-to-clipboard';
import React from 'react';
import styled from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { TableDate } from 'src/components/shared/tables/PaymentsTable/components/TableDate';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon as DSIcon, IconNames } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';
import { DetailsSectionValue, Icon as StyledIcon } from 'src/pages/spend-management/components/table/components/styles';
import { StatusBadge } from 'src/pages/spend-management/components/table/components/transactions/StatusCell';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';

type Props = {
  label: string;
  details?: string;
  fallback?: string;
  isCurrency?: boolean;
  isDate?: boolean;
  isStatus?: boolean;
  withCopy?: boolean;
  testId?: string;
};

export const DetailsCell = ({
  label = '',
  details = '',
  isCurrency,
  isDate,
  fallback,
  isStatus,
  withCopy,
  testId,
}: Props) => (
  <TransactionDetail gap={2}>
    <Box textStyle="body4Semi" color="grey.600">
      <MIFormattedText label={label} />
    </Box>
    <Flex gap={4}>
      <DetailsSectionValue data-testId={testId}>
        <Box textStyle="body2" wordBreak="break-word">
          {getCellFormat({
            details,
            isCurrency,
            isDate,
            fallback,
            isStatus,
          })}
        </Box>
        {withCopy && (
          <Tooltip
            placement="top"
            label={<MIFormattedText label="spendManagement.pages.dashboard.transactionDetails.copyToClipboard" />}
            shouldWrapChildren
          >
            <StyledIcon
              icon={<DSIcon name={IconNames.duplicate} />}
              aria-label="copy"
              onClick={() => handleCopy(label, details)}
            />
          </Tooltip>
        )}
      </DetailsSectionValue>
    </Flex>
  </TransactionDetail>
);

const getCellFormat = ({ details, isCurrency, isDate, fallback, isStatus }) => {
  if (isCurrency) {
    return <MIFormattedCurrency value={Number(details / 100)} currency="$" />;
  }

  if (isDate) {
    return <TableDate date={details} />;
  }

  if (fallback) {
    return fallback;
  }

  if (isStatus) {
    return <StatusBadge status={details} />;
  }

  return details;
};

const handleCopy = (label: string, value: string) => {
  if (value) {
    copy(value);
    pushNotification({
      type: NotificationVariant.INFO,
      msg: `${label}CopyToast`,
    });
  }
};

const TransactionDetail = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;
