export const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  h: 4,
  w: 4,
  borderRadius: 'full',
  border: '1px',
  borderColor: 'grey.200',
  bgColor: 'grey.200',
  apply: 'textStyles.caption1Uppercase',
};
