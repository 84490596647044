import React from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues, SourceInfo } from 'react-number-format';
import { Input, InputProps } from 'src/core/ds/input';

export type MaskInputProps = Omit<NumberFormatProps, 'size' | 'onChange'> &
  Omit<InputProps, 'onChange'> & {
    onChange: (args: {
      value: string;
      floatValue: number | undefined;
      formattedValue: string;
      event: React.SyntheticEvent<HTMLInputElement>;
    }) => void;
  };

export const MaskInput = ({ id, testId, onChange, ...rest }: MaskInputProps) => {
  const handleChange = (values: NumberFormatValues, sourceInfo: SourceInfo) => {
    onChange({
      value: values.value,
      floatValue: values.floatValue,
      formattedValue: values.formattedValue,
      event: sourceInfo.event,
    });
  };

  return (
    <NumberFormat
      customInput={Input}
      id={id}
      testId={testId}
      onValueChange={handleChange}
      {...(rest as any)}
      isRequired={false}
    />
  );
};
