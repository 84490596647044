import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import config from 'src/config';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { settingsLocations } from 'src/pages/settings/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { AccountingSoftware } from 'src/utils/consts';

type Props = {
  eventPage: string;
  redirect?: string;
};

export const useConnectAccountingPlatform = ({ eventPage, redirect }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const apActions = useStoreActions(accountingPlatformsStore);
  const connect = useCallback(
    async (platformName: AccountingSoftware, platformId: number, overideOrgId?: number) => {
      if (config.thirdPartyDisabled) {
        historyPush({ path: settingsLocations.syncQBDLocations.disabled });

        return Promise.reject();
      }

      analytics.track(eventPage, 'connect-confirm', { type: platformName });
      const orgIdToConnect = overideOrgId || orgId;

      return apActions.connect({
        orgId: orgIdToConnect,
        id: platformId,
        redirect,
      });
    },
    [eventPage, orgId, apActions, redirect, historyPush]
  );

  return [connect];
};
