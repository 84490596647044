import { PayListItemType } from 'src/modules/bills/types';
import { PayCardContainer } from 'src/pay/PayCardContainer';

type Props = {
  items: PayListItemType[];
  listItemBaseSearch?: string;
  markedIds: readonly string[];
  setMarked: (id: string, value: boolean) => void;
  enabledSelectionItems: readonly PayListItemType[];
  enableBatchSelection: boolean;
};

export const PayListItemsContainer = ({
  items,
  listItemBaseSearch,
  markedIds,
  setMarked,
  enableBatchSelection,
  enabledSelectionItems,
}: Props) => {
  if (!items) {
    return <></>;
  }

  return (
    <>
      {items.map((item) => (
        <PayCardContainer
          key={item.id}
          item={item}
          markedIds={markedIds}
          setMarked={setMarked}
          listItemBaseSearch={listItemBaseSearch}
          enableBatchSelection={enableBatchSelection}
          enabledSelectionItems={enabledSelectionItems}
        />
      ))}
    </>
  );
};
