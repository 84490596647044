import { Switch } from 'react-router';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { MicroDepositErrorPage } from './components/MicroDepositErrorPage';
import { MicroDepositIndex } from './components/MicroDepositIndex';
import { MicroDepositTooManyAttemptsPage } from './components/MicroDepositTooManyAttemptsPage';
import { MicroDepositVerification } from './components/MicroDepositVerification';
import { MicroDepositsVerificationSuccessPage } from './components/verification-success/Page';
import { microDepositLocations } from './locations';

export const MicroDepositVerificationRouter = () => (
  <Switch>
    <SmartRoute exact path={microDepositLocations.index}>
      <MicroDepositIndex />
    </SmartRoute>
    <SmartRoute exact path={microDepositLocations.success}>
      <MicroDepositsVerificationSuccessPage />
    </SmartRoute>
    <SmartRoute exact path={microDepositLocations.alreadyVerified}>
      <SuccessLayoutPage
        illustration={IllustrationName.checkCircle}
        title="microDepositVerification.alreadyVerified.title"
        text="microDepositVerification.alreadyVerified.subtitle"
        testId="already-verified-page"
      />
    </SmartRoute>
    <SmartRoute exact path={microDepositLocations.verification}>
      <MicroDepositVerification />
    </SmartRoute>
    <SmartRoute exact path={microDepositLocations.tooManyAttempts}>
      <MicroDepositTooManyAttemptsPage />
    </SmartRoute>
    <SmartRoute exact path={microDepositLocations.error}>
      <MicroDepositErrorPage />
    </SmartRoute>
  </Switch>
);
