import { convertPayFilters } from 'src/modules/pay/utils';
import { PayListFilters } from 'src/pages/pay/components/filters/types';
import { mapBackwardComaptibleSortingToQueryString } from 'src/pay/components/SortMenu/backwards-compatibility';
import { analytics } from 'src/services/analytics';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { DbAnalyticsTraits } from 'src/utils/consts';
import { convertLimit, convertStart } from 'src/utils/pagination-utils';
import {
  BillsCountResponse,
  BillType,
  DeliveryMethodType,
  DeliveryOptionType,
  PaginationQueryParams,
  QueryParams,
} from 'src/utils/types';
import { FilterType, MarkAsPaidParams, PayListNormalizedType } from './types';

export const billsApi = {
  getBills({ orgId, filters = {}, scope = undefined }: { orgId: number; filters: FilterType; scope?: string }) {
    const url = `/orgs/${orgId}/bills`;
    const params = {
      ...filters,
      scope,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  getBillList({
    orgId,
    filters = {},
    scope = undefined,
  }: {
    orgId: number;
    filters: FilterType;
    scope?: string;
  }): Promise<{ objects: BillType[]; totalCount: number }> {
    const url = `/orgs/${orgId}/bills/billList`;
    const params = {
      ...filters,
      scope,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },
  getPaidTabPayItemList({
    orgId,
    filters = {},
  }: {
    orgId: number;
    filters: Omit<QueryParams, 'vendorId'> & PaginationQueryParams & PayListFilters;
  }): Promise<PayListNormalizedType> {
    const url = `/orgs/${orgId}/bills/pay-normalized`;

    const sorting = mapBackwardComaptibleSortingToQueryString(filters.sorting) ?? filters.sorting;
    const params = {
      ...filters,
      ...convertPayFilters(filters),
      sorting,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },
  getBillListInbox({ orgId, filters }) {
    const url = `/orgs/${orgId}/bills/inbox`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },
  getInboxTabPayItemList({
    orgId,
    filters,
  }: {
    orgId: number;
    filters: Omit<QueryParams, 'vendorId'> & PaginationQueryParams & PayListFilters;
  }): Promise<PayListNormalizedType> {
    const url = `/orgs/${orgId}/bills/inbox-pay-normalized`;

    const sorting = mapBackwardComaptibleSortingToQueryString(filters.sorting) ?? filters.sorting;
    const params = {
      ...filters,
      ...convertPayFilters(filters),
      sorting,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },
  getInternalBills({ orgId, filters = {} }: { orgId: number; filters: FilterType }) {
    const url = `/orgs/${orgId}/bills/internal`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  getBillById({ orgId, id }) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return fetchRequest(url);
  },

  getVendorBillById({ orgId, id }: { orgId: number; id: ToDo }) {
    const url = `/orgs/${orgId}/bills/vendor/${id}`;

    return fetchRequest(url);
  },

  getBillAttachments({ orgId, billId }: { orgId: number; billId: number }) {
    const url = `/orgs/${orgId}/accounting-platforms/bill/${billId}/attachments`;

    return fetchRequest(url);
  },

  generatePdfInvoice(orgId: number, billId) {
    const url = `/orgs/${orgId}/bills/${billId}/pdf-invoice`;

    return fetchRequest(url);
  },

  generateVendorFeePdfInvoice(orgId: number, internalBillId) {
    const url = `/orgs/${orgId}/bills/${internalBillId}/vendor-fee-pdf-invoice`;

    return fetchRequest(url);
  },

  createBill(orgId: number, params) {
    const url = `/orgs/${orgId}/bills`;

    analytics.setTraits({ [DbAnalyticsTraits.CREATE_A_BILL]: true });

    return postRequest(url, params);
  },

  editBillById(orgId: number, id, params, scope) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return putRequest(url, { ...params, scope });
  },

  deleteBillById(orgId: number, id) {
    const url = `/orgs/${orgId}/bills/${id}`;

    return deleteRequest(url);
  },

  markAsPaid({ orgId, id, accountingPlatformAccountId, createOrigin, amount }: MarkAsPaidParams) {
    const url = `/orgs/${orgId}/bills/${id}/mark-as-paid`;

    return postRequest(url, {
      isPaid: true,
      intuitAccountId: accountingPlatformAccountId || null,
      amount: amount || null,
      createOrigin,
    });
  },

  batchMarkAsPaid({ orgId, items, intuitAccountId, createOrigin }) {
    const url = `/orgs/${orgId}/bills/batch-mark-as-paid`;

    return postRequest(url, { items, intuitAccountId, createOrigin });
  },

  getBillsStatusCount({ orgId, vendorId }: { orgId: number; vendorId?: string }): Promise<BillsCountResponse> {
    const url = `/orgs/${orgId}/bills/bills-status-summary`;

    return fetchRequest(url, { vendorId });
  },
  setClassifications({ orgId, billIds, classification }) {
    const url = `/orgs/${orgId}/bills/classification`;

    return putRequest(url, { bills: billIds, classification });
  },
  getVendorBills({ orgId, filters = {} }: { orgId: number; filters: FilterType }) {
    const url = `/orgs/${orgId}/bills/vendor-bills`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  // TODO: consider changing it to payment request approval/revoke actions instead of "create bill from request"
  createBillFromRequest(orgId, params) {
    const url = `/orgs/${orgId}/bills/from-request`;

    return postRequest(url, params);
  },
};
export type DefaultDateType = {
  billId: number;
  defaultFundingSourceId?: number;
  defaultDates?: {
    actualDeliveryDays: number;
    deliveryDate: string;
    maxDeliveryDate: string;
    suggestedScheduledDate: string;
  };
  defaultDeliveryMethod?: DeliveryMethodType;
};

export type BatchFetchBillResult = {
  billList: Record<
    string,
    {
      defaultDate: DefaultDateType;
      deliveryOptions: DeliveryOptionType[];
      bill: BillType;
    }
  >;
  totalCount: number;
};

export type BatchFetchBillResponse = {
  payload: BatchFetchBillResult;
};
