import { MouseEvent as ReactMouseEvent } from 'react';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';

type Props = {
  onCancel: (event: ReactMouseEvent) => void;
  onConfirm: (event: ReactMouseEvent) => void;
  isLoading: boolean;
};

export const UnhideClientModalMessageButtons = ({ onConfirm, onCancel, isLoading }: Props) => (
  <ModalButtonsWrapper>
    <Button
      label="bookkeeper.company.unhideClientDialog.cancel"
      variant={ButtonVariants.tertiaryNaked}
      size={ButtonSizes.lg}
      onClick={onCancel}
    />
    <Button
      label="bookkeeper.company.unhideClientDialog.confirm"
      testId="button-user.unhideClientDialog.confirm"
      size={ButtonSizes.lg}
      isLoading={isLoading}
      onClick={onConfirm}
    />
  </ModalButtonsWrapper>
);
