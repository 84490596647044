import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { VendorType } from 'src/utils/types';

export const vendorsApi = {
  getVendors({
    orgId,
    start = 0,
    limit = 0,
    filters = {},
    shouldMelioMeVendorHasDeliveryMethod = false,
  }): Promise<{
    totalCount: number;
    objects: {
      id: number;
      companyName: string;
    }[];
  }> {
    const url = `/orgs/${orgId}/vendors`;

    return fetchRequest(url, {
      start,
      limit,
      shouldMelioMeVendorHasDeliveryMethod,
      ...filters,
    });
  },

  validateVendorName(orgId: number, params) {
    const url = `/orgs/${orgId}/vendors/validateVendorName`;

    return postRequest(url, params);
  },

  createVendor(orgId: number, params) {
    const url = `/orgs/${orgId}/vendors`;

    return postRequest(url, params);
  },

  createOwnedVendor(orgId: number) {
    const url = `/orgs/${orgId}/vendors/create-owned`;

    return postRequest(url);
  },

  createOwnedVendorWithHandle(orgId: number) {
    const url = `/orgs/${orgId}/vendors/create-owned-with-handle`;

    return postRequest(url);
  },

  getVendorById({ orgId, id }: { orgId: number; id: number }) {
    const url = `/orgs/${orgId}/vendors/${id}`;

    return fetchRequest(url);
  },

  // eslint-disable-next-line @typescript-eslint/ban-types -- we want to narrow `unknown` down to an `object` here, but keep a ToDo notion for future readers
  editVendorById({ orgId, id, params }: { orgId: number; id: number; params: ToDo & object }) {
    const url = `/orgs/${orgId}/vendors/${id}`;

    return putRequest(url, params);
  },

  deleteVendorById(orgId: number, id: number) {
    const url = `/orgs/${orgId}/vendors/${id}`;

    return deleteRequest(url);
  },

  updateVendors({
    orgId,
    updateVendorsData,
  }: {
    orgId: number;
    updateVendorsData: Record<string, VendorType>;
  }): Promise<Record<string, VendorType>> {
    const url = `/orgs/${orgId}/vendors/update-batch`;

    return putRequest(url, updateVendorsData);
  },

  getHandleSuggestion(orgId: number, id: number): Promise<{ suggestedHandle: string }> {
    const url = `/orgs/${orgId}/vendors/${id}/handle-suggestion`;

    return fetchRequest(url);
  },

  setHandle(orgId: number, id: number, params) {
    const url = `/orgs/${orgId}/vendors/${id}/handle`;

    return postRequest(url, params);
  },

  sendFillDeliveryMethodEmail(orgId: number, id: number) {
    const url = `/orgs/${orgId}/vendors/${id}/send-fill-delivery-method-email`;

    return postRequest(url);
  },

  batchSendFillDeliveryMethodEmail(orgId: number, params) {
    const url = `/orgs/${orgId}/vendors/batch-send-fill-delivery-method-email`;

    return postRequest(url, params);
  },

  getVendorsDeliveryMethods(orgId: number) {
    const url = `/orgs/${orgId}/vendors/delivery-methods`;

    return fetchRequest(url);
  },

  // eslint-disable-next-line @typescript-eslint/ban-types -- we want to narrow `unknown` down to an `object` here, but keep a ToDo notion for future readers
  createVendorsBatch({ orgId, data }: { orgId: number; data: ToDo & object }) {
    const url = `/orgs/${orgId}/vendors/create-batch`;

    return postRequest(url, data).then((res) => res.vendors);
  },
  getOwnedVendorExists({ orgId, id }: { orgId: number; id: number }) {
    const url = `/orgs/${orgId}/vendors/${id}/ownedVendor`;

    return fetchRequest(url).then((res) => res.result);
  },

  checkVendorPaymentPreferences({ orgId, id }) {
    const url = `/orgs/${orgId}/vendors/${id}/payment-preferences`;

    return fetchRequest(url);
  },

  convertLocalVendorToOwned({ orgId, id }) {
    const url = `/orgs/${orgId}/vendors/convert-local-vendor-to-owned`;

    return postRequest(url, { id }).then((res) => res.ownedVendor);
  },

  getIntuitAcctNum(orgId, id) {
    const url = `/orgs/${orgId}/vendors/${id}/vendor-intuit-acct-num`;

    return fetchRequest(url);
  },

  getIsFirstPayment(orgId, id) {
    const url = `/orgs/${orgId}/vendors/${id}/is-first-payment`;

    return fetchRequest(url);
  },
};
