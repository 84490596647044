import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { authApi } from 'src/modules/auth/api';
import { AuthCodeVerificationStatus } from 'src/pages/auth/consts';
import { intercomService } from 'src/services/intercom';
import { UserContextType } from 'src/utils/types';
import { useAuthCodeVerificationAnalytics } from './useAuthCodeVerificationAnalytics';

type Props = {
  onSuccess: VoidFunction;
  user: Partial<UserContextType>;
};

type ReturnValue = {
  isVerifying: boolean;
  email: string | null;
  authVerificationCodeStatus: AuthCodeVerificationStatus;
  resetVerificationCodeStatus: VoidFunction;
  verifyCode: (code: string) => void;
  codeResendResult: boolean;
  handleResendCodeClick: () => void;
  handleContactSupportClick: () => void;
  isMaxResendAttempts?: boolean;
};

const useAuthCodeVerification = ({ onSuccess, user }: Props): ReturnValue => {
  const [isMaxResendAttempts, setIsMaxResendAttempts] = useState(false);
  const [email, setEmail] = useState<string | null>(null);
  const [authVerificationCodeStatus, setAuthVerificationCodeStatus] = useState<AuthCodeVerificationStatus>(
    AuthCodeVerificationStatus.PENDING
  );
  const [codeResendResult, setCodeResendResult] = useState<boolean>(false);

  const { onApiCall: request2FACode, loading: requesting2FACode } = useApi<[], Record<string, any>>({
    api: authApi.request2FACode,
  });
  const { onApiCall: verify2FACode, loading: verifying2FACode } = useApi<[code: number], Record<string, any>>({
    api: authApi.verify2FACode,
  });

  const analytics = useAuthCodeVerificationAnalytics({ user });

  const requestCode = useCallback(async () => {
    const { maskedEmail, isAttemptsLimitExceeded, codeVerificationStatus } = await request2FACode();
    setEmail(maskedEmail);
    setIsMaxResendAttempts(isAttemptsLimitExceeded);

    if (
      isAttemptsLimitExceeded &&
      [AuthCodeVerificationStatus.MAX_ATTEMPTS, AuthCodeVerificationStatus.INVALID].includes(codeVerificationStatus)
    ) {
      setAuthVerificationCodeStatus(AuthCodeVerificationStatus.BLOCKED);
    }
  }, []);

  useEffect(() => {
    !user?.mfa && requestCode();
  }, []);

  const onFailure = ({ codeVerificationStatus, isAttemptsLimitExceeded }) => {
    analytics.trackCodeEntered(false, isAttemptsLimitExceeded);

    if (
      isAttemptsLimitExceeded &&
      [AuthCodeVerificationStatus.MAX_ATTEMPTS, AuthCodeVerificationStatus.INVALID].includes(codeVerificationStatus)
    ) {
      setAuthVerificationCodeStatus(AuthCodeVerificationStatus.BLOCKED);

      return;
    }

    if (codeVerificationStatus === AuthCodeVerificationStatus.MAX_ATTEMPTS) {
      setAuthVerificationCodeStatus(AuthCodeVerificationStatus.MAX_ATTEMPTS);
    } else {
      setAuthVerificationCodeStatus(AuthCodeVerificationStatus.INVALID);
    }
  };

  const verifyCode = useCallback(async (code: string) => {
    setCodeResendResult(false);
    try {
      const { succeded, codeVerificationStatus, isAttemptsLimitExceeded } = await verify2FACode(Number(code));

      if (succeded) {
        setAuthVerificationCodeStatus(AuthCodeVerificationStatus.VERIFIED);
        analytics.trackCodeEntered(true);
        onSuccess();

        return;
      }

      onFailure({
        codeVerificationStatus,
        isAttemptsLimitExceeded,
      });
    } catch {
      onFailure({
        codeVerificationStatus: AuthCodeVerificationStatus.INVALID,
        isAttemptsLimitExceeded: false,
      });
    }
  }, []);

  const resetVerificationCodeStatus = () =>
    authVerificationCodeStatus !== AuthCodeVerificationStatus.BLOCKED &&
    setAuthVerificationCodeStatus(AuthCodeVerificationStatus.PENDING);

  const handleResendCodeClick = useCallback(async () => {
    resetVerificationCodeStatus();
    await requestCode();
    setCodeResendResult(true);
    analytics.trackResendClicked(authVerificationCodeStatus === AuthCodeVerificationStatus.BLOCKED);
  }, []);

  const handleContactSupportClick = () => {
    analytics.trackSupportClicked(authVerificationCodeStatus === AuthCodeVerificationStatus.BLOCKED);
    intercomService.show();
  };

  return {
    isVerifying: requesting2FACode || verifying2FACode,
    email,
    authVerificationCodeStatus,
    resetVerificationCodeStatus,
    verifyCode,
    codeResendResult,
    handleResendCodeClick,
    handleContactSupportClick,
    isMaxResendAttempts,
  };
};

export { useAuthCodeVerification };
