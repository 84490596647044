import { ReactNode } from 'react';
import Box from 'src/core/ds/box';

type Props = {
  children?: ReactNode;
};
export const MenuItemsSeparator = ({ children }: Props) => (
  <Box borderBottom={1} borderBottomStyle="solid" my={4} borderColor="grey.400" mx="auto" w="75%">
    {children}
  </Box>
);
