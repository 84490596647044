import { Role } from 'src/utils/consts';
import { getAccountingFirmSyntheticRole } from '../utils/getAccountingFirmSyntheticRole';
import { useIsAccountingFirmSelected } from './useIsAccountingFirmSelected';

export const useAccountingFirmSyntheticRole = (role?: Role) => {
  const { isAccountingFirmSelected } = useIsAccountingFirmSelected();

  if (isAccountingFirmSelected) {
    return getAccountingFirmSyntheticRole(role);
  }

  return role;
};
