import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import RegistrationTermsAndConditions from 'src/components/onboarding/RegistrationTermsAndConditions';
import Box from 'src/core/ds/box';
import { Image } from 'src/core/ds/image';
import { Logo } from 'src/images/Logo';

interface CardLayoutProps {
  children?: React.ReactNode;
  textValues?: Record<string, any>;
  title?: string;
  subtitle?: string;
  footerLabel?: string;
  iconSrc?: string;
  testId?: string;
}

export function CardLayout({
  children,
  textValues,
  title,
  subtitle,
  footerLabel = 'auth.signUpCondensed.termsAndConditions',
  iconSrc,
  testId,
}: CardLayoutProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="safe center"
      direction="column"
      overflow="auto"
      width="100%"
      height="100%"
      data-testid={testId}
      sx={{
        '@media(min-width:600px)': {
          backgroundColor: '#EEF1F6',
        },
        backgroundColor: '#FFFFFF',
      }}
    >
      <Flex
        backgroundColor="#FFFFFF"
        boxSizing="border-box"
        direction="column"
        alignItems="center"
        sx={{
          paddingTop: '60px',
          padding: '16px',
          borderRadius: 0,
          margin: '16px',
          height: '100%',
          minHeight: 'inherit',
          width: '100%',
          '@media(min-width:600px)': {
            paddingTop: '32px',
            padding: '32px',
            borderRadius: '24px',
            height: 'auto',
            width: '488px',
            '.terms-condensed': {
              marginTop: subtitle ? '72px' : '32px',
            },
          },
          '.terms-condensed': {
            marginBottom: 0,
          },
        }}
      >
        {iconSrc ? <Image w="14rem" h="14rem" mb={3} src={iconSrc} data-testid="session-expire-image" /> : <Logo />}
        <Box margin={subtitle ? '40px 0' : '24px 0'}>
          <Box textStyle="h1Semi" textAlign="center" marginBottom="8px" color="black">
            <MIFormattedText label={title} values={textValues} />
          </Box>
          {subtitle ? (
            <Box textStyle="body2" textAlign="center" color="#4A505B">
              <MIFormattedText label={subtitle} values={textValues} />
            </Box>
          ) : null}
        </Box>
        <Box width="100%">{children}</Box>
        {footerLabel !== '' ? (
          <Box textStyle="body4" color="#6A7281" marginTop="auto">
            <RegistrationTermsAndConditions label={footerLabel} className="terms-condensed" textValues={textValues} />
          </Box>
        ) : null}
      </Flex>
    </Flex>
  );
}
