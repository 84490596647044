export default {
  server: {
    baseUrl: 'https://localhost:8090',
  },
  web: {
    baseUrl: 'https://localhost:3031',
  },
  platformApp: {
    baseUrl: 'http://localhost:3005/melio',
  },
  analytics: {
    shouldTrackEvents: false,
    shouldPrintEvents: true,
    shouldIdentify: false,
  },
  services: {
    segment: {
      shouldLoad: false,
    },
    intercom: {
      isShow: false,
    },
  },
  meliome: {
    baseUrl: 'https://localhost:3031/meliome/pay/',
  },
  qbo: {
    web: {
      baseUrl: 'https://localhost:3031',
    },
  },
};
