import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

type Props = {
  onClick: () => void;
};

const SyncQBDPageFooterMobile = ({ onClick }: Props) => (
  <FooterContainer onClick={onClick}>
    <MIFormattedText label="settings.newAccountingSoftware.QuickbooksDesktopSync.mobile.footer" />
  </FooterContainer>
);

export default SyncQBDPageFooterMobile;

const FooterContainer = styled.div`
  margin-top: 4.4rem;
  ${(props) => props.theme.text.fontType.regular};
`;
