import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalContainer, ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import Colors from 'src/core/theme/foundations/colors';
import { UseModal } from 'src/helpers/react/useModal';
import WhyPayMelioImage from 'src/images/vendors/why-pay-melio.jpg';

type Props = {
  dismiss: (status?: string) => void;
  vendorName: string;
};

export const WhyMelioModal: React.FC<UseModal<Props>> = ({ dismiss, vendorName }) => {
  const onPayClick = () => dismiss('pay');

  return (
    <StyledModalMessage
      onCloseClick={dismiss}
      id="why-pay-melio-modal"
      titleComponent={
        <StyledModalTitle textStyle="h2Semi" data-testid="why-pay-melio-modal-title">
          <StyledModalImage src={WhyPayMelioImage} />
          <MIFormattedText label="guests.paymentRequest.modal.title" />
        </StyledModalTitle>
      }
      contentComponent={
        <Flex direction="column" gap={6} data-testid="why-pay-melio-modal-content">
          <StyledModalContentItem name="choose" icon={IconNames.pay} />
          <StyledModalContentItem name="hold" icon={IconNames.moneyReceive} />
          <StyledModalContentItem name="secure" icon={IconNames.shield} />
        </Flex>
      }
      buttonComponent={
        <Flex justifyContent="right" gap={4}>
          <BorderedButton
            variant={ButtonVariants.tertiaryNaked}
            onClick={dismiss}
            data-testid="why-pay-melio-modal-close-btn"
          >
            <MIFormattedText label="guests.paymentRequest.modal.buttons.close" />
          </BorderedButton>
          <Button variant={ButtonVariants.primary} onClick={onPayClick} data-testid="why-pay-melio-modal-pay-btn">
            <MIFormattedText label="guests.paymentRequest.modal.buttons.pay" values={{ vendorName }} />
          </Button>
        </Flex>
      }
    />
  );
};

const StyledModalContentItem = ({ name, icon }: { name: string; icon: IconNames }) => (
  <Flex direction="row" gap={3} data-testid={`why-pay-melio-modal-content-${name}`}>
    <Box>
      <Icon name={icon} size={IconSize.m} color={Colors.primary[500]} />
    </Box>
    <Flex direction="column">
      <Box textStyle="body3Semi">
        <MIFormattedText label={`guests.paymentRequest.modal.content.${name}.title`} />
      </Box>
      <Box textStyle="body4">
        <MIFormattedText label={`guests.paymentRequest.modal.content.${name}.content`} />
      </Box>
    </Flex>
  </Flex>
);

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow-y: scroll;
  }
`;

const StyledModalImage = styled(Image)`
  border-radius: 2rem;
  height: 24rem;
  object-fit: cover;
  margin-bottom: 2.3rem;
`;

const StyledModalTitle = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: ${(props) => props.theme.text.weight.semibold};
  font-size: 2.8rem;
  font-family: ${(props) => props.theme.fontFamily};
`;

const BorderedButton = styled(Button)`
  border: 0.1rem solid ${(props) => props.theme.colors.border.grey} !important;
`;
