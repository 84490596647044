import { ChangeEvent, SyntheticEvent } from 'react';
import { MIFormTitleWithText } from 'src/components/common/MIFormTitleWithText';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { MISelectSlider } from 'src/components/common/MISelectSlider';
import MISingleSelect from 'src/components/common/MISingleSelect';
import Upload10KInvoiceFileLayout from 'src/components/layout/Upload10KInvoiceFileLayout';
import { WizardFormRow } from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import {
  AmountCurrencyField,
  DateField,
  MaskField,
  PasswordField,
  RadioGroupField,
  TextField,
} from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { Radio } from 'src/core/ds/radio';
import { AddressFormControlMode, FormControlType, FormType } from 'src/utils/consts';
import { MIEnhancedDropDown } from './DropDown/MIEnhancedDropDown';
import MIAddressAutocomplete from './MIAddressAutocomplete';
import { MICardEditableField } from './MICard';

type Props = {
  formControl: any;
  formType: FormType;
};

const inputWrappersMap = {
  [FormType.WIZARD]: WizardFormRow,
  [FormType.DETAILS]: MICardEditableField,
};

export const MIEditFormControl = ({ formControl, formType = FormType.WIZARD }: Props) => {
  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    formControl.onChange({ id, value });
  };

  const handleFormattedFieldChange = ({ event }: { event: SyntheticEvent<HTMLInputElement> }) => {
    const { id, value } = event.currentTarget;
    formControl.onChange({ id, value });
  };

  const InputWrapper = inputWrappersMap[formType];
  const inputSize = formType === FormType.WIZARD ? 'md' : 'sm';
  const addressMode = formType === FormType.WIZARD ? AddressFormControlMode.INLINE : AddressFormControlMode.FORM;
  const { controlType, privateData, emptyLabel, ...textFieldControl } = formControl;

  let formControlComponent: React.ReactElement;

  switch (controlType) {
    case FormControlType.ADDRESS:
      formControlComponent = <MIAddressAutocomplete {...formControl} mode={addressMode} />;
      break;
    case FormControlType.SELECT:
      formControlComponent = <MISingleSelect {...formControl} />;
      break;
    case FormControlType.DROP_DOWN:
      formControlComponent = <MIEnhancedDropDown {...formControl} />;
      break;
    case FormControlType.DATE:
      formControlComponent = <DateField {...formControl} size={inputSize} />;
      break;
    case FormControlType.RADIO:
      formControlComponent = (
        <RadioGroupField {...formControl}>
          {formControl.options.map((item) => (
            <Radio key={item.id} value={item.id} {...item} />
          ))}
        </RadioGroupField>
      );
      break;
    case FormControlType.TEL:
      formControl.type = controlType;
      formControlComponent = <TextField {...textFieldControl} size={inputSize} onChange={handleFieldChange} />;
      break;
    case FormControlType.SELECT_SLIDER:
      formControlComponent = <MISelectSlider {...formControl} />;
      break;
    case FormControlType.FILE:
      formControlComponent = <Upload10KInvoiceFileLayout {...formControl} />;
      break;
    case FormControlType.CURRENCY:
      formControlComponent = <AmountCurrencyField {...formControl} />;

      break;
    case FormControlType.PASSWORD:
      formControlComponent = (
        <PasswordField {...formControl} onChange={handleFieldChange} size={inputSize} autoComplete="new-password" />
      );
      break;
    case FormControlType.BLOCK:
      formControlComponent = formControl.renderBlock();
      break;
    case FormControlType.TEXT:
    default:
      formControlComponent = <TextField {...textFieldControl} size={inputSize} onChange={handleFieldChange} />;
      break;
  }

  if (controlType === FormControlType.SELECT_SLIDER) {
    return (
      <>
        <MIInputLabel inputId={formControl.inputId} label={formControl.label} required />
        {formControlComponent}
      </>
    );
  }

  if (controlType === FormControlType.FILE) {
    return (
      <>
        <MIFormTitleWithText {...formControl} />
        {formControlComponent}
      </>
    );
  }

  if (formControl.format) {
    return (
      <InputWrapper>
        <MaskField {...textFieldControl} size={inputSize} onChange={handleFormattedFieldChange} />
      </InputWrapper>
    );
  }

  return (
    <InputWrapper>
      {privateData ? (
        <Box w="full">
          <PrivateDataContainer>{formControlComponent}</PrivateDataContainer>
        </Box>
      ) : (
        formControlComponent
      )}
    </InputWrapper>
  );
};
