import styled from 'styled-components';
import { MICard } from 'src/components/common/MICard';
import { devices } from 'src/theme/appDevices';

const Card = styled(MICard)`
  bottom-border: 1px black;
  > :not(:last-child) {
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.border.darkGrey}`};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    margin: 0;
    margin-bottom: 12rem;
  }
`;

const Header = styled.div`
  @media ${devices.desktop}, ${devices.tablet} {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: white;
    padding: 2rem;
    z-index: 1;
  }
`;

export { Card, Header };
