import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';

type Props = {
  onClick: () => void;
};

export const TaxForm = ({ onClick }: Props) => (
  <MICard>
    <MICardForm>
      <MICardTitle label="settings.company.texFormsTitle" />
      <Box textStyle="body2" mb={4} mt={6}>
        <MIFormattedText label="settings.company.taxFormsSubTitle" />
      </Box>
      <Button
        size={ButtonSizes.md}
        variant={ButtonVariants.tertiary}
        onClick={onClick}
        label="settings.company.taxFormsButton"
      />
    </MICardForm>
  </MICard>
);
