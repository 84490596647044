import { useSelector } from 'react-redux';
import { generatePath, Switch, useRouteMatch } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { useWizard } from 'src/hooks/useWizard/useWizard';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { navigationMap, stepToPageUrlSuffix } from 'src/pages/bill/pay/installments/consts';
import { BusinessApplicationContextProvider } from 'src/pages/bill/pay/installments/context/business-context/BusinessApplicationContextProvider';
import { BusinessDetailsPage } from 'src/pages/bill/pay/installments/pages/application-details/business-details/BusinessDetailsPage';
import { BusinessOwnershipDetailsPage } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/BusinessOwnershipDetailsPage';
import { OwnerDecisionPage } from 'src/pages/bill/pay/installments/pages/owner-decision/OwnerDecisionPage';
import { RepaymentTermsPage } from 'src/pages/bill/pay/installments/pages/repayment-terms/RepaymentTermsPage';
import { ReviewingDetails } from 'src/pages/bill/pay/installments/pages/ReviewingDetails';
import { ApplicationErrorType, Steps } from 'src/pages/bill/pay/installments/types';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { billLocations } from '../../locations';

type Props = {
  firstStep: Steps;
};

const getErrorPagePath = (errorType: ApplicationErrorType, orgId: number, billId?: string) => {
  switch (errorType) {
    case ApplicationErrorType.Pending:
      return generatePath(billLocations.pay.installments.eligibilityPending, {
        orgId,
        billId,
      });
    case ApplicationErrorType.Ineligible:
      return generatePath(billLocations.pay.installments.eligibilityRejected, {
        orgId,
        billId,
      });
    case ApplicationErrorType.AmountIneligible:
      return generatePath(billLocations.pay.installments.amountRejected, {
        orgId,
        billId,
      });
    case ApplicationErrorType.General:
    default:
      return generatePath(billLocations.pay.installments.generalError, {
        orgId,
        billId,
      });
  }
};

export const InstallmentsWizard = ({ firstStep }: Props) => {
  const { path } = useRouteMatch();
  const { goNextMap, cancelFlow, completeFlow } = useWizard<Steps, typeof navigationMap>({
    firstStep,
    flowName: 'installments',
    locationsMap: stepToPageUrlSuffix,
    navigationMap,
    cancelUrlFallback: '/',
  });
  const payment = useSelector(getPayment);
  const orgId = useOrgId();

  const sendToErrorPage = (errorType: ApplicationErrorType) => {
    const path = getErrorPagePath(errorType, orgId, payment.billId ? payment.billId : undefined);
    completeFlow(path);
  };

  return (
    <BusinessApplicationContextProvider>
      <Switch>
        <SmartRoute path={`${path}/${stepToPageUrlSuffix.ownerDecision}`}>
          <OwnerDecisionPage goNext={goNextMap.ownerDecision} cancelFlow={cancelFlow} />
        </SmartRoute>
        <SmartRoute path={`${path}/${stepToPageUrlSuffix.businessDetails}`}>
          <BusinessDetailsPage goNext={goNextMap.businessDetails} cancelFlow={cancelFlow} />
        </SmartRoute>
        <SmartRoute path={`${path}/${stepToPageUrlSuffix.businessOwnershipDetails}`}>
          <BusinessOwnershipDetailsPage goNext={goNextMap.businessOwnershipDetails} cancelFlow={cancelFlow} />
        </SmartRoute>
        <SmartRoute path={`${path}/${stepToPageUrlSuffix.reviewingDetails}`}>
          <ReviewingDetails goNext={goNextMap.reviewingDetails} onFailure={sendToErrorPage} />
        </SmartRoute>
        <SmartRoute path={`${path}/${stepToPageUrlSuffix.repaymentTerms}`}>
          <RepaymentTermsPage
            goNext={() =>
              completeFlow(
                generatePath(billLocations.pay.memo, {
                  orgId,
                  billId: payment.billId ? payment.billId : undefined,
                })
              )
            }
            cancelFlow={cancelFlow}
          />
        </SmartRoute>
      </Switch>
    </BusinessApplicationContextProvider>
  );
};
