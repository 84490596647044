import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { VendorGroupType } from 'src/pages/vendor-directory/select-vendor/types';
import { SelectVendorType } from 'src/utils/consts';

export type Props = {
  group: VendorGroupType;
};

export const OptionGroupLabel = ({ group }: Props) => (
  <Flex pl={4} h="5.6rem" align="center">
    <Box textStyle="body4Uppercase" color="grey.600">
      <MIFormattedText label={`vendorDirectory.select.${group.value}`} />
    </Box>
    {group.value === SelectVendorType.DIRECTORY && (
      <Flex pl={2}>
        <Icon
          name={IconNames.verifiedSolid}
          size={IconSize.s}
          color="primary.500"
          data-testid="directory-vendor-sign"
          borderRadius="full"
        />
      </Flex>
    )}
  </Flex>
);
