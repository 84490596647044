import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useIsAccountingFirmAdmin } from 'src/pages/team/team-management/hooks/useIsAccountingFirmAdmin';
import { TeamPage } from './index';
import { teamLocations } from './locations';

export const TeamRouter = () => {
  const { isAccountingFirmAdmin } = useIsAccountingFirmAdmin();

  const allowCallback = () => isAccountingFirmAdmin;

  return (
    <Switch>
      <SmartRoute path={teamLocations.base} exact allowCallbackFn={allowCallback}>
        <TeamPage />
      </SmartRoute>
    </Switch>
  );
};
