import { featureFlags } from '@melio/shared-web';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICard } from 'src/components/common/MICard';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useModal } from 'src/helpers/react/useModal';
import { PageContainerProps } from 'src/hoc/withListContainer';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useOrgId, usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { accountingPlatformBankAccountsStore } from 'src/modules/accounting-platform-bank-accounts/accounting-platform-bank-accounts-store';
import { useFetchAccountingPlatformBankAccounts } from 'src/modules/accounting-platform-bank-accounts/hooks/useFetchAccountingPlatformBankAccounts';
import { billsApi } from 'src/modules/bills/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { paymentsApi } from 'src/modules/payments/api';
import { getPaymentsActions } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { accountingSoftwareNameToIntlPath } from 'src/pages/bill/components/BillDetailsForm/utils/accountingSoftwareNameToIntlPath';
import { BillPaymentsActivity } from 'src/pages/bill/components/BillPaymentsActivity/BillPaymentsActivity';
import { BillStatus } from 'src/pages/bill/components/BillStatus';
import { ViewBillBlockRefundModal } from 'src/pages/bill/components/modals/ViewBillBlockRefundModal';
import { ViewBillMarkAsPaidModal } from 'src/pages/bill/components/modals/ViewBillMarkAsPaidModal';
import { PaymentProcessingBlockedNotificationCard } from 'src/pages/bill/components/PaymentProcessingBlockedNotificationCard';
import { BillPaymentProgressBar } from 'src/pages/bill/components/ViewBill/BillPaymentProgressBar/BillPaymentProgressBar';
import { RenderStatusMessage } from 'src/pages/bill/components/ViewBill/components/RenderStatusMessage/RenderStatusMessage';
import { MarkAsPaidModalStatus } from 'src/pages/bill/components/ViewBill/types';
import { getUrlByBill, getUrlByPayment } from 'src/pages/bill/components/ViewBill/utils';
import { billLocations, FilteredViewLocation, FilteredViewQueryParams } from 'src/pages/bill/locations';
import { filterMenuKeys } from 'src/pages/pay/components/filters/consts';
import { settingsLocations } from 'src/pages/settings/locations';
import { recurringBillsApi } from 'src/redux/payBillWizard/api';
import { analytics } from 'src/services/analytics';
import accountingSoftwareSync from 'src/services/api/accountingSoftwareSync';
import { ThemeType } from 'src/theme/global-theme';
import { getStatusInfo } from 'src/utils/bill-details';
import {
  getActionOptions,
  getBillPaymentTag,
  getBillsDefaultFilters,
  getVendorCompanyName,
  isPaymentRequest,
  serializePaymentId,
} from 'src/utils/bills';
import {
  ButtonsDirections,
  ButtonsRowPosition,
  DeletePaymentAction,
  DialogType,
  DialogVariants,
  FeatureFlags,
  PaymentCreateFlowOrigin,
  PaymentStatus,
  PaymentTypes,
  ScreenMode,
} from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { isPaymentCompletedRefund, isRefundPaymentFlow } from 'src/utils/payments';
import { stringifyQs } from 'src/utils/query-utils';
import { BillType, PaymentType } from 'src/utils/types';
import { scrollToElement } from '../utils/scrollToElement';
import { ViewPaymentMultipleBills } from './ViewPaymentBills';
import { ViewPaymentHeader } from './ViewPaymentHeader';

const eventPage = 'view-payment';
export const scrollToGalleryEvent = 'scroll-to-gallery';

type Props = {
  payment: PaymentType & { bills: BillType[] };
  bill: BillType;
  reloadBills: () => void;
  theme: ThemeType;
  filters: PageContainerProps['filters'];
  backPath: string;
  loadPayment: (paymentId: number) => void;
  shouldScrollToGallery: boolean;
  afterScrollToGallery: () => void;
};

export const ViewPaymentBase = ({
  payment,
  bill,
  reloadBills,
  theme,
  filters,
  backPath,
  loadPayment,
  shouldScrollToGallery,
  afterScrollToGallery,
}: Props) => {
  const [isSelfServeRefundEnabled] = featureFlags.useFeature(FeatureFlags.selfServeRefund, false);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const currentUser = useSelector(profileStore.selectors.profile);
  const isAdmin = useSelector(profileStore.selectors.isAdmin);

  const orgId = useOrgId();
  const site = useSiteContext();
  const query = useQueryString();
  const dispatch = useDispatch();
  const { navigate } = useNavigator();
  const {
    connectedAccountingPlatform,
    isConnected: isConnectedToAccountingPlatform,
  } = usePayablesConnectedAccountingPlatform();
  useFetchAccountingPlatformBankAccounts({ forceReload: true });

  const accountingPlatformsBankAccounts = useSelector((state) =>
    accountingPlatformBankAccountsStore.selectors.list.value(state, { orgId })
  );

  const [refundBlockModal, showRefundBlockModal] = useModal(ViewBillBlockRefundModal, {
    id: 'refund-block-modal',
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recurringBill, setRecurringBill] = useState<BillType | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [markAsPaidModalStatus, setMarkAsPaidModalStatus] = useState<MarkAsPaidModalStatus>(
    MarkAsPaidModalStatus.CLOSE
  );

  const accountingPlatformName = accountingSoftwareNameToIntlPath(connectedAccountingPlatform?.name);
  const status = getBillPaymentTag(bill, payment);
  const manuallyPaid = payment.manual === true;
  const isRequestAndHasNotDeliveryMethods = isPaymentRequest(bill.id) && isEmpty(bill.vendor?.deliveryMethods);
  const showNotificationCard = payment.metadata?.isRetryByVirtual;
  const defaultBillsSearchPath = stringifyQs(omit(query, 'id'));
  const [pathname, search = defaultBillsSearchPath] = backPath.split('?');
  const backPathUrl = `${pathname}?${search}`;

  const showPaymentProgressBar =
    (payment.status !== PaymentStatus.FAILED || isRefundPaymentFlow(payment)) &&
    payment.status !== PaymentStatus.BLOCKED &&
    payment.status !== PaymentStatus.SCHEDULED &&
    !manuallyPaid &&
    !!payment?.deliveryMethod;

  const { markAsPaid: markPaymentAsPaid, markAsUnpaid: markPaymentAsUnpaid } = getPaymentsActions(dispatch);
  const billsGalleryRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldScrollToGallery) {
      scrollToElement(billsGalleryRef.current);
      afterScrollToGallery();
    }
  }, [shouldScrollToGallery]);

  const loadRecurringBill = async () => {
    const result = await recurringBillsApi.getOrgRecurringBills(orgId, {
      params: [bill.recurringBillId],
    });

    const recurringBill = result.recurringBills.pop();
    setRecurringBill(recurringBill);
  };

  useEffect(() => {
    bill?.recurringBillId && loadRecurringBill();
  }, [bill?.recurringBillId]);

  const statusInfo = getStatusInfo(
    { status },
    theme,
    payment,
    manuallyPaid,
    isRequestAndHasNotDeliveryMethods,
    getVendorCompanyName(bill)
  );

  const onRefundPayment = (paymentId: number, isSelfServeRefund?: boolean) => {
    if (isSelfServeRefund) {
      analytics.track(eventPage, 'refund-payment');
      navigate(
        generatePath(billLocations.refund, {
          paymentId,
          orgId,
          billId: bill.id,
        })
      );
    } else {
      showRefundBlockModal();
    }
  };

  const goEditPayment = (payment: PaymentType) => {
    analytics.track(eventPage, 'edit-bill');
    navigate(
      generatePath(billLocations.pay.edit.funding, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      })
    );
  };

  const generateBillPath = (pattern: FilteredViewLocation, extraParams?: Parameters<typeof generatePath>[1]) => {
    const extraFilters = stringifyQs(pick(filters, filterMenuKeys));
    const { start, limit, status, sorting } = {
      ...getBillsDefaultFilters(filters.status),
      ...(filters || query),
    };
    const params: FilteredViewQueryParams = {
      orgId,
      start,
      limit,
      status,
      sorting,
      ...extraParams,
    };

    return `${generatePath(pattern, params)}&${extraFilters}`;
  };

  const redirectDefaultScheduledBills = () => {
    navigate(generateBillPath(billLocations.filteredViewWithoutId));
  };

  const deleteCurrentRecurringPaymentById = async (paymentId) => {
    setIsLoading(true);

    try {
      await recurringBillsApi.deleteCurrentRecurringPaymentById(orgId, paymentId);
      analytics.track(eventPage, 'delete-current-recurring-bill-payment-success');

      const nextRecurringBillIndex = bill.recurringBillIndex + 1;

      if (recurringBill && nextRecurringBillIndex > recurringBill.occurrences) {
        setIsLoading(false);
        redirectDefaultScheduledBills();
        reloadBills();
      } else {
        const { objects: bills } = await billsApi.getBills({
          orgId,
          filters: {
            recurringBillId: bill.recurringBillId,
            recurringBillIndex: nextRecurringBillIndex,
          },
        });

        setIsLoading(false);
        reloadBills();

        if (bills.length > 0) {
          const id = serializePaymentId(bills[0].id, bills[0].payments[0].id);
          navigate(generateBillPath(billLocations.filteredView, { id }));
        } else {
          navigate(generateBillPath(billLocations.filteredViewWithoutId));
        }
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const deleteAllRecurringPayments = async () => {
    setIsLoading(true);

    try {
      await recurringBillsApi.deleteAllRecurringPayments(orgId, bill.recurringBillId);
      analytics.track(eventPage, 'delete-all-recurring-bill-payments-success');
      setIsLoading(false);

      redirectDefaultScheduledBills();
      reloadBills();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const syncBill = () => {
    if (isConnectedToAccountingPlatform && bill.originId && bill.originId.toString() !== '-1') {
      try {
        accountingSoftwareSync.syncBillByOriginId(orgId, bill.originId);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  };

  const deletePayment = async (paymentId) => {
    setIsLoading(true);

    try {
      await paymentsApi.deletePaymentById(orgId, paymentId);
      analytics.track(eventPage, 'delete-payment-success');
      setIsLoading(false);
      syncBill();
      reloadBills();
      redirectDefaultScheduledBills();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onDeletePayment = (payment: PaymentType, type: DeletePaymentAction) => {
    analytics.track(eventPage, 'delete-payment-confirmed');

    switch (type) {
      case DeletePaymentAction.RECURRING_CURRENT:
        deleteCurrentRecurringPaymentById(payment.id);
        break;
      case DeletePaymentAction.RECURRING_ALL:
        deleteAllRecurringPayments();
        break;
      default:
        deletePayment(payment.id);
    }
  };

  const onDeleteClicked = () => {
    analytics.track('bills', 'delete-bill');
    setIsDeleting(true);
  };

  const handleMarkPaymentAsPaid = async ({
    accountingPlatformAccountId,
  }: {
    accountingPlatformAccountId?: string | null;
  }) => {
    setIsLoading(true);
    analytics.track(eventPage, `mark-as-paid`);
    const { payload: markAsPaidPayment } = await markPaymentAsPaid({
      orgId,
      id: payment.id,
      createOrigin: PaymentCreateFlowOrigin.PAY,
      accountingPlatformAccountId,
    });
    analytics.track(eventPage, `mark-as-paid-success`);
    reloadBills();
    await loadPayment(markAsPaidPayment.id);
    navigate(getUrlByPayment(bill!, markAsPaidPayment, isAdmin, orgId));
  };

  const openMarkAsPaidModal = (markAsPaidModalStatus: MarkAsPaidModalStatus) => {
    analytics.trackAction('mark-as-paid-modal', { toggle: 'open' });
    setMarkAsPaidModalStatus(markAsPaidModalStatus);
  };

  const onMarkPaymentAsPaid = async () => {
    if (isConnectedToAccountingPlatform && accountingPlatformsBankAccounts.length !== 1) {
      openMarkAsPaidModal(MarkAsPaidModalStatus.OPEN_MARK_PAYMENT_AS_PAID);
    } else {
      handleMarkPaymentAsPaid({});
    }
  };

  const onMarkPaymentAsUnpaid = async (paymentId: number) => {
    setIsLoading(true);
    analytics.track(eventPage, `mark-as-unpaid`);
    const { payload: markAsUnpaidBill } = await markPaymentAsUnpaid({
      orgId,
      id: paymentId,
      billId: bill.id,
    });
    analytics.track(eventPage, `mark-as-unpaid-success`);
    navigate(getUrlByBill(markAsUnpaidBill, orgId));
  };

  const onDeleteBill = () => {
    analytics.trackAction('delete-bill-confirmed', {
      paymentId: payment.id,
      billId: bill.id,
      paymentStatus: payment?.status === PaymentStatus.FAILED ? PaymentTypes.REFUND : payment?.status,
    });
    setIsLoading(true);
    billsApi
      .deleteBillById(orgId, bill.id)
      .then(() => {
        analytics.trackAction('delete-bill-success', {
          paymentId: payment.id,
          billId: bill.id,
          paymentStatus: payment?.status === PaymentStatus.FAILED ? PaymentTypes.REFUND : payment?.status,
        });

        navigate(backPathUrl);
        reloadBills();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onDeleteBillCanceled = () => {
    analytics.trackAction('delete-bill-canceled', {
      paymentId: payment.id,
      billId: bill.id,
      paymentStatus: payment?.status === PaymentStatus.FAILED ? PaymentTypes.REFUND : payment?.status,
    });
    setIsDeleting(false);
  };

  const onHideNonDeletableModal = () => {
    analytics.track('bills', 'hide-non-deletable-modal');
    setIsDeleting(false);
  };

  const renderDeleteModalDialog = () => {
    const isDeletable = get(payment, 'metadata.isDeletable', true);
    const paymentsReportLinkPath = `${generatePath(settingsLocations.company, { orgId })}#PaymentsReport`;

    if (isDeletable) {
      if (isPaymentCompletedRefund(bill.payments[0])) {
        return (
          <Dialog
            type={DialogType.CONFIRM}
            variant={DialogVariants.ERROR}
            title="bills.form.deleteDialog.refund.title"
            subtitle="bills.form.deleteDialog.refund.subtitle"
            subtitleValues={{
              paymentsReportLink: (
                <Link to={paymentsReportLinkPath}>
                  <MIInlineLink label="bills.form.deleteDialog.refund.paymentsReportLink" />
                </Link>
              ),
            }}
            okButtonText="bills.form.deleteDialog.refund.confirm"
            cancelButtonText="bills.form.deleteDialog.refund.cancel"
            onOkAction={onDeleteBill}
            onCancelAction={onDeleteBillCanceled}
            buttonsDirection={ButtonsDirections.HORIZONTAL}
            buttonsRowPosition={ButtonsRowPosition.RIGHT}
          />
        );
      }

      return (
        <Dialog
          type={DialogType.CONFIRM}
          variant={DialogVariants.ERROR}
          title="bills.form.deleteDialog.title"
          titleValues={{
            invoiceNumber: bill.invoiceNumber,
            companyName: getVendorCompanyName(bill),
          }}
          subtitle="bills.form.deleteDialog.subtitle"
          subtitleValues={{
            totalAmount: (
              <strong>
                <MIFormattedCurrency value={bill.totalAmount} />
              </strong>
            ),
            companyName: <strong>{getVendorCompanyName(bill)}</strong>,
          }}
          okButtonText="bills.form.deleteDialog.confirm"
          onOkAction={onDeleteBill}
          onCancelAction={onDeleteBillCanceled}
        />
      );
    }

    return (
      <Dialog
        type={DialogType.ALERT}
        variant={DialogVariants.ERROR}
        title="bills.form.nonDeletableDialog.title"
        subtitle="bills.form.nonDeletableDialog.subtitle"
        subtitleValues={{
          supportEmail: (
            <StyledMIInlineLink
              target="_self"
              text={site.config.support.email}
              to={`mailto:${site.config.support.email}`}
            />
          ),
        }}
        cancelButtonText="bills.form.nonDeletableDialog.ok"
        onCancelAction={onHideNonDeletableModal}
      />
    );
  };

  const renderMarkAsPaidModalDialog = () => {
    const options = accountingPlatformsBankAccounts.map((account) => ({
      value: account.id,
      label: account.name,
    }));

    const onSubmit = async ({ accountingPlatformAccountId }: { accountingPlatformAccountId?: string | null }) => {
      analytics.trackAction('mark-as-paid-modal', { toggle: 'close' });
      setMarkAsPaidModalStatus(MarkAsPaidModalStatus.CLOSE);

      await handleMarkPaymentAsPaid({ accountingPlatformAccountId });
    };

    return (
      <ViewBillMarkAsPaidModal
        dismiss={() => {
          analytics.trackAction('mark-as-paid-modal', { toggle: 'close' });
          setMarkAsPaidModalStatus(MarkAsPaidModalStatus.CLOSE);
        }}
        isConnectedToAccountingPlatform={isConnectedToAccountingPlatform}
        showPaidAmount={markAsPaidModalStatus === MarkAsPaidModalStatus.OPEN_MARK_BILL_AS_PAID}
        balance={bill.balance}
        invoiceNumber={bill.invoiceNumber}
        options={options}
        submit={onSubmit}
        accountingPlatformName={accountingPlatformName}
      />
    );
  };

  const actions = {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMarkBillAsPaid: () => {},
    onMarkPaymentAsUnpaid: () => onMarkPaymentAsUnpaid(payment.id),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onToggleMode: () => {},
    onDeleteClicked,
  };

  const actionOptions =
    bill &&
    payment &&
    getActionOptions({
      bill,
      payment,
      mode: ScreenMode.VIEW,
      permissions,
      currentUser,
      isUnpaidInboxBill: false,
      actions,
      connectedAccountingPlatform,
    });

  return (
    <>
      {isDeleting && renderDeleteModalDialog()}
      {refundBlockModal}
      {markAsPaidModalStatus !== MarkAsPaidModalStatus.CLOSE && renderMarkAsPaidModalDialog()}

      {isLoading ? (
        <AreaLoader />
      ) : (
        <SingleViewLoadingContainer>
          <ViewPaymentHeader
            payment={payment}
            bill={bill}
            actionOptions={actionOptions}
            status={status}
            recurringBill={recurringBill}
            billsGalleryRef={billsGalleryRef}
            pathname={pathname}
            search={search}
            generateBillPath={generateBillPath}
          />
          <Flex direction="column" h="full" mb={{ sm: 10 }} data-testid="payment-info-container">
            <MICard mode="mainSingleScreen">
              {statusInfo && <BillStatus statusInfo={statusInfo} />}
              {showNotificationCard && (
                <Box mx={8} mt={5}>
                  <PaymentProcessingBlockedNotificationCard payment={payment} bill={bill} />
                </Box>
              )}
              {showPaymentProgressBar && <BillPaymentProgressBar payment={payment} />}
              <Box>
                <RenderStatusMessage
                  payment={payment}
                  bill={bill}
                  onRefundPayment={onRefundPayment}
                  isEmbeddedMode={site.embeddedMode}
                  status={status}
                  isSelfServeRefundEnabled={isSelfServeRefundEnabled}
                />
              </Box>
              <BillPaymentsActivity
                bill={bill}
                goEditPayment={goEditPayment}
                onDeletePayment={onDeletePayment}
                onDeleteBill={onDeleteClicked}
                onMarkPaymentAsPaid={onMarkPaymentAsPaid}
                actionOptions={actionOptions}
                payment={payment}
              />
            </MICard>
            <Box data-testid="payment-bills-gallery" ref={billsGalleryRef} mb={{ lg: '10' }}>
              <ViewPaymentMultipleBills payment={payment} />
            </Box>
          </Flex>
        </SingleViewLoadingContainer>
      )}
    </>
  );
};

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: ${(props) => props.theme.text.size.regular};
`;
