import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  EditElement,
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import { InternationalPayeeCountryHint } from 'src/pages/bill/components/InternationalPayeeCountryHint';
import { DeliveryMethodHint } from 'src/pages/bill/pay/components/PayBillPaymentActivity/components/DeliveryMethodHint';
import { DeliveryMethodIcon } from 'src/pages/vendor/components/DeliveryMethodIcon';
import { DeliveryMethodInfoIcon } from 'src/pages/vendor/components/DeliveryMethodInfoIcon';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { isVirtualCardExpired } from 'src/utils/bills';
import { DeliveryType, FailedPaymentType, VendorManagedByEnum } from 'src/utils/consts';
import { getLatestPayment, isReturnedCheck, isUndepositedCheck } from 'src/utils/payments';
import { BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import Tag from './Tag';

type Props = {
  bill: BillType;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  onEdit?: () => void;
  isEditDisabled: boolean;
};

export const PayBillPaymentActivityDeliveryMethodInfo = ({
  deliveryMethod,
  onEdit,
  bill,
  payment,
  isEditDisabled,
}: Props) => {
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.RPPS;
  const isCheckDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.CHECK;
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isMsnManagedDeliveryMethod = deliveryMethod.managedBy === VendorManagedByEnum.MSN;
  const isInternationalMethod =
    deliveryMethod.deliveryType && isInternationalDeliveryMethod(deliveryMethod?.deliveryType);
  const originPayment = getLatestPayment(bill?.payments);
  const isUndepositedCheckPayment = originPayment ? isUndepositedCheck(originPayment) : false;
  const isReturnedCheckPayment = originPayment ? isReturnedCheck(originPayment) : false;
  const isFailedOrUndepositedCheckPayment = payment?.metadata?.failedType || isUndepositedCheckPayment;
  const isExpiredVirtualCard = isVirtualCardExpired(payment);

  const canEdit =
    !(payment.isFinanced && (isUndepositedCheckPayment || isReturnedCheckPayment)) &&
    !isRPPSDeliveryMethod &&
    (isExpiredVirtualCard || !isVirtualCardDeliveryMethod) &&
    !isInternationalMethod &&
    isFailedOrUndepositedCheckPayment !== FailedPaymentType.FAILED_TO_COLLECT &&
    !bill?.vendor?.managedBy;

  const isUpdated =
    originPayment && isFailedOrUndepositedCheckPayment && deliveryMethod?.id !== originPayment?.deliveryMethodId;
  const deliveryMethodInfo = getDeliveryMethodDisplay({
    deliveryMethod,
    vendorName: bill?.vendor?.companyName,
    managedBy: bill?.vendor?.managedBy,
    shorten: true,
  });

  const getDeliveryMethodLabel = () => {
    if (isRPPSDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.rppsLabel';
    }

    if (isVirtualCardDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.virtualCardLabel';
    }

    if (isCheckDeliveryMethod) {
      return 'payBillPaymentActivity.deliveryMethod.paperCheckLabel';
    }

    if (isInternationalMethod) {
      return 'payBillPaymentActivity.deliveryMethod.internationalLabel';
    }

    return 'payBillPaymentActivity.deliveryMethod.achLabel';
  };

  const getDeliveryMethodTooltip = () => {
    if (isVirtualCardDeliveryMethod) {
      return <DeliveryMethodInfoIcon tooltipLabel="payBillPaymentActivity.deliveryMethod.virtualCardTooltip" />;
    }

    if (isMsnManagedDeliveryMethod) {
      return <DeliveryMethodInfoIcon tooltipLabel="deliveryMethods.managed.tooltip" />;
    }

    return null;
  };

  const getDeliveryMethodHint = () => {
    if (isInternationalMethod) {
      return (
        <InternationalPayeeCountryHint
          payeeBankCountryCode={deliveryMethod.internationalAccount?.payeeBankCountry || ''}
        />
      );
    }

    if (isMsnManagedDeliveryMethod) {
      return <DeliveryMethodHint tooltipLabel="deliveryMethods.managed.hint" />;
    }

    return null;
  };

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <DeliveryMethodIcon deliveryMethod={deliveryMethod} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText data-testid="bill-payment-method">
                <MIFormattedText label={getDeliveryMethodLabel()} />
                {getDeliveryMethodTooltip()}
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText data-testid="bill-pay-delivery-method">
              {deliveryMethodInfo}
              {isUpdated && <Tag label="payBillPaymentActivity.updated" />}
              {getDeliveryMethodHint()}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {canEdit && <EditElement onEdit={onEdit} testId="edit-delivery-method" isDisabled={isEditDisabled} />}
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};
