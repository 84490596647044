import { DeleteFundingSourceErrorData } from '../../PaymentMethodsSettingsPageContainer';
import { DELETE_FUNDING_SOURCE_ERROR_CODE, DELETE_FUNDING_SOURCE_WITH_BILLING_FEE_CODE } from './consts';

export const getErrorDialogConfig = (errorCode: string, errorData: DeleteFundingSourceErrorData) => {
  let title = 'payments.form.deleteDialog.removalFailedTitle';
  let subtitle = `server.${errorCode}`;

  if (errorCode === DELETE_FUNDING_SOURCE_ERROR_CODE && errorData) {
    const hasFailedPayments = (errorData?.failedPayments || []).length > 0;

    title = 'payments.form.deleteDialog.failed.title';
    subtitle = hasFailedPayments
      ? 'payments.form.deleteDialog.failed.failedPaymentsSubtitle'
      : 'payments.form.deleteDialog.failed.scheduledPaymentsSubtitle';
  }

  if (errorCode === DELETE_FUNDING_SOURCE_WITH_BILLING_FEE_CODE) {
    title = 'payments.form.deleteDialog.failedBillingFee.title';
  }

  return { title, subtitle };
};
