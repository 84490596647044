import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { Header } from 'src/pages/vendor/components/VendorLayoutElements';
import { getOrgId } from 'src/redux/user/selectors';
import { DeliveryType } from 'src/utils/consts';
import { ContentWrapper } from '../../components/ContentWrapper';
import { Footer } from '../../components/Footer';
import { eventType } from '../../consts';
import { useSuvcToAchAnalytics } from '../../hooks/useSuvcToAchAnalytics';
import { DeliveryMethodStateType, TokenValuesType } from '../../types';

type Props = {
  tokenValues: TokenValuesType;
  dmState: DeliveryMethodStateType;
  goPrev: () => void;
  goNext: () => void;
};

const eventPage = 'confirmation';

export const ConfirmationPage = ({ goPrev, goNext, dmState, tokenValues }: Props) => {
  const orgId = useSelector(getOrgId);
  const { paymentId } = tokenValues;
  const { pageEvent, trackEvent } = useSuvcToAchAnalytics(tokenValues);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { submitBankAccount, deliveryMethodMV, isLoading } = dmState;
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id || orgId));
  const invoiceNumber = payment?.bill?.invoiceNumber;

  const isEditButtonVisible = !payment?.vendor?.deliveryMethods?.some(
    (dm) => dm.managedBy && dm.deliveryType === DeliveryType.ACH
  );

  useEffect(() => {
    pageEvent(eventType, eventPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    await submitBankAccount();
    goNext();
  };

  const goPrevPage = () => {
    trackEvent(eventType, 'editBankAccount-click');
    goPrev();
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout isExperimentalView>
      <ContentWrapper>
        <Header hideInvoice showFullHeader organization={organization}>
          <Box textStyle="body3" mt="2" color="grey.700" fontSize="1.6rem" textAlign={{ base: 'center', md: 'left' }}>
            <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.infoSubtitle" />
          </Box>
          <Box mt="4" textStyle="display2">
            <MIFormattedCurrency value={(payment?.amount || 0).toString()} currency="$" />
          </Box>
          {invoiceNumber && (
            <Box textStyle="body3" mt="4" color="grey.700">
              <MIFormattedText
                label="vendors.deliveryMethods.shiftSuvcToAch.invoiceNumber"
                values={{
                  invoiceNumber: payment?.bill?.invoiceNumber,
                }}
              />
            </Box>
          )}
        </Header>

        <Box bg="white" p={{ md: '3.2rem 4rem 5rem 4rem', base: '3rem 1.6rem' }}>
          <Box
            data-testid="suvc-ach.funds.title"
            textStyle="body2Semi"
            color="black"
            textAlign={{ base: 'center', md: 'left' }}
          >
            <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.depositFunds" />
          </Box>
          <Flex mt="8" mb="8" align="center">
            <Box minW={10}>
              <Icon name={IconNames.bank} size={IconSize.lg} />
            </Box>
            <Box w="full">
              <Flex
                align="center"
                justifyContent="space-between"
                letterSpacing="0.2px"
                color="grey.600"
                textStyle="body4Semi"
              >
                <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.bankAccount" />
                {isEditButtonVisible && (
                  <Box color="primary.500" ml="auto">
                    <Button
                      textStyle="body3"
                      height="auto"
                      minWidth="auto"
                      data-testid="suvc-ach.editButton"
                      variant={ButtonVariants.unstyled}
                      size={ButtonSizes.sm}
                      onClick={goPrevPage}
                    >
                      <MIFormattedText label="general.edit" />
                    </Button>
                  </Box>
                )}
              </Flex>
              <Box textStyle="body2">
                <MIFormattedText
                  label="vendors.deliveryMethods.shiftSuvcToAch.bankAccountTitle"
                  values={{
                    last4Digits: deliveryMethodMV?.bankAccount?.accountNumber?.value?.slice(-4),
                  }}
                />
              </Box>
            </Box>
          </Flex>
          <Button
            label="vendors.deliveryMethods.shiftSuvcToAch.acceptPayment"
            onClick={onSubmit}
            data-testid="suvc-ach.confirmButton"
            isLoading={isLoading}
            size={ButtonSizes.lg}
            isFullWidth
          />
          <Footer companyName={organization?.companyName} />
        </Box>
      </ContentWrapper>
    </OutsideLayout>
  );
};
