export enum BadgeVariants {
  FILLED = 'filled',
  OUTLINED = 'outlined',
}

export enum BadgeColors {
  PRIMARY = 'primary',
  PRIMARY_LIGHT = 'primaryLight',
  NEUTRAL_DARK = 'neutralDark',
  NEUTRAL = 'neutral',
  NEUTRAL_LIGHT = 'neutralLight',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success',
  INFORMATIVE = 'informative',
}
