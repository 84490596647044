import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';

export const SupportContainer = ({ onSupportTrigger }) => (
  <Flex onClick={onSupportTrigger} align="center" color="black" cursor="pointer" pr={8} textStyle="body4Uppercase">
    <Box mr={2}>
      <Icon name={IconNames.chatEmpty} />
    </Box>
    <MIFormattedText label="wizard.support" />
  </Flex>
);
