import sumBy from 'lodash/sumBy';
import { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import { usePaperCheckFee } from 'src/hooks/usePaperCheckFee';
import { useGetOrganizationBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetOrganizationBillingFees';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import { isAchToRegularCheck } from 'src/pages/regular-batch-payments/utils';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getBillingDetails, getFundingSources } from 'src/redux/user/selectors';
import { BillingFeeType } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { MainTableRowType } from '../../table/types';
import { FeeItem } from '../FeeItem';

export type FeeDataType = {
  amount: number;
  label: string;
  style: string;
  plus?: boolean;
  tooltipLabel?: string;
  key: string;
  extraFeeItemProps?: Partial<ComponentProps<typeof FeeItem>>;
};

type FeeParamsType = {
  feesData: Record<string, FeeDataType>;
};

export const useFeesParams = (): FeeParamsType => {
  const { billsList } = useBatchBillsList();
  const { organizationBillingFees } = useGetOrganizationBillingFees();
  const hasOrgBillingFee = organizationBillingFees?.some(
    (billingFee) => billingFee.billingFeeType === BillingFeeType.AchToCheck
  );
  const readyBills = billsList.filter((bill) => bill.isBillReady);
  const fundingSources = useSelector(getFundingSources);
  const amountOfPaymentsWithPaperCheckFee = getAmountOfPaymentsWithPaperCheckFee(fundingSources, readyBills);
  const { fee } = useSelector(getBillingDetails);
  const { paperCheckFee, availableFreeChecks } = usePaperCheckFee({});
  const internationalFee = fee?.international?.value;
  const creditCardFee = sumBy(readyBills, (bill) => bill.fee?.credit?.amount || 0);
  const debitCardFee = sumBy(readyBills, (bill) => bill.fee?.debit?.amount || 0);
  const sumOfDebitAndCreditFees = creditCardFee + debitCardFee;
  const amountOfChecksToChargeFee = amountOfPaymentsWithPaperCheckFee - (availableFreeChecks || 0);
  const totalPaperCheckFeesAmount = paperCheckFee * (amountOfChecksToChargeFee < 0 ? 0 : amountOfChecksToChargeFee);
  const totalCardFeesAmount = sumOfDebitAndCreditFees;
  const totalFastFeesAmount = sumBy(readyBills, (bill) =>
    bill.payment.deliveryPreference ? bill.fee?.[bill.payment.deliveryPreference]?.amount || 0 : 0
  );
  const readyInternationalBills = readyBills.filter(
    (bill) => bill.payment.deliveryMethod && isInternationalDeliveryMethod(bill.payment.deliveryMethod?.deliveryType)
  );
  const internationalFeesAmount = internationalFee ? readyInternationalBills.length * internationalFee : 0;
  const totalFeesAmount =
    totalCardFeesAmount + totalFastFeesAmount + internationalFeesAmount + totalPaperCheckFeesAmount;
  const cardsLabel = 'regularBatchPayments.fees.cardFees';

  function getAmountOfPaymentsWithPaperCheckFee(
    fundingSources: readonly AccountType[],
    readyBills: MainTableRowType[]
  ) {
    return readyBills.filter(({ payment }) => {
      const fundingSource = fundingSources.find((fs) => fs.id === payment.fundingSourceId);

      return isAchToRegularCheck(
        payment?.deliveryMethod?.deliveryType,
        payment?.deliveryPreference,
        fundingSource?.fundingType
      );
    }).length;
  }

  const feesData = {
    cards: {
      key: 'cards',
      amount: totalCardFeesAmount,
      label: cardsLabel,
      style: 'body4',
      plus: true,
    },
    fast: {
      key: 'fast',
      amount: totalFastFeesAmount + internationalFeesAmount,
      label: 'regularBatchPayments.fees.fastPaymentFees',
      style: 'body4',
      plus: true,
      tooltipLabel: 'regularBatchPayments.tooltips.fastFee',
    },
    paperCheck: {
      key: 'paperCheck',
      amount: totalPaperCheckFeesAmount,
      label: 'regularBatchPayments.fees.paperCheckFees',
      style: 'body4',
      plus: true,
      tooltipLabel: hasOrgBillingFee ? 'regularBatchPayments.tooltips.paperCheckWithBillingMethod' : '',
      extraFeeItemProps: {
        'data-testid': 'paper-check-fee',
      },
    },
    total: {
      key: 'total',
      amount: totalFeesAmount,
      label: 'regularBatchPayments.fees.totalFees',
      style: 'body4Semi',
    },
    transaction: {
      key: 'transaction',
      amount: totalFeesAmount,
      label: 'regularBatchPayments.fees.transactionFees',
      style: 'body4Semi',
    },
  };

  return {
    feesData,
  };
};
