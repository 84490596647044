import { useStyleConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Box from 'src/core/ds/box';

type Props = {
  children: ReactNode;
};

export const Alert = ({ children }: Props) => {
  const style = useStyleConfig('WizardContentAlert');

  return <Box sx={style}>{children}</Box>;
};
