import * as React from 'react';
import Flex from 'src/core/ds/flex';
import useEmptyStateMessage from 'src/pages/get-pro/components/empty/hooks/useEmptyStateMessage';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import useLoadGetProList from 'src/pages/get-pro/hooks/useLoadGetProList';
import { DownloadCsvReportButton } from '../buttons/DownloadCsvReportButton';
import ContactTag from './components/ContactTag';
import { GetProFab } from './components/GetProFab';
import SearchBar from './components/SearchBar';
import Title from './components/Title';

export const Header: React.FC = () => {
  const { listParams } = useGetProListParams();
  const { data: items } = useLoadGetProList(listParams);
  const emptyStateParams = useEmptyStateMessage();
  const showFab = (!items.length && !emptyStateParams.ctaAction) || !!items.length;

  return (
    <Flex justify="space-between">
      <Title />
      <ContactTag />
      <Flex flexGrow={2} />
      {showFab && <GetProFab />}
      <DownloadCsvReportButton />
      <SearchBar />
    </Flex>
  );
};

export default Header;
