import { memo } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Tooltip from 'src/core/ds/tooltip';

type InvoiceNumberCellProps = { invoiceNumber: string | null; onClick?: () => void };

export const InvoiceNumberCell: React.FC<InvoiceNumberCellProps> = memo(({ invoiceNumber, onClick }) => (
  <Tooltip placement="top-start" label={<MIFormattedText label="regularBatchPayments.cells.actions.viewBillDetails" />}>
    <Box onClick={onClick}>
      <Box
        textStyle="body4"
        _hover={{ textDecoration: 'underline' }}
        data-testid="regularBatchPayments.cells.bills.invoiceNumber"
      >
        {invoiceNumber ? (
          <MIFormattedText label="regularBatchPayments.cells.bills.invoiceNumber" values={{ invoiceNumber }} />
        ) : (
          <MIFormattedText label="regularBatchPayments.cells.bills.noInvoiceNumber" />
        )}
      </Box>
    </Box>
  </Tooltip>
));
