import { useState } from 'react';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { analytics } from 'src/services/analytics';
import { OrgPreferencesTypeKeys } from 'src/utils/consts';

type ShowVerifyMessageResponseType = {
  title: string;
  description: string;
  action: string;
  onClick: () => void;
  onClose: () => void;
  openMessage: boolean;
  shouldShowAccountingSoftwareMigration?: boolean;
};

const useShowAccountingSoftwareMigrationMessage = (): ShowVerifyMessageResponseType => {
  const [openMessage, setOpenMessage] = useState(true);
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();
  const organizationPreferences = useOrganizationPreferences();
  const shouldShowAccountingSoftwareMigration = organizationPreferences?.migrateASMessage === 'true';

  const onCloseMessage = async () => {
    analytics.trackAction('close-accounting-software-migration-message');
    setOpenMessage(false);
    await updateOrganizationPreference({
      key: OrgPreferencesTypeKeys.migrateASMessage,
      value: 'false',
    });
  };

  return {
    title: 'getPro.message.accountingSoftwareMigration.title',
    description: 'getPro.message.accountingSoftwareMigration.description',
    action: 'getPro.message.accountingSoftwareMigration.action',
    onClick: onCloseMessage,
    onClose: onCloseMessage,
    openMessage,
    shouldShowAccountingSoftwareMigration,
  };
};

export { useShowAccountingSoftwareMigrationMessage };
