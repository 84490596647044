import { FinancingEligibilityStatus, InstallmentOption } from 'src/pages/bill/pay/installments/types';

export type GetFinancingPreEligibilityActionType = {
  type: string;
  orgId: number;
  vendorId: number;
  resolve: (
    preEligibilityResult: GetFinancingPreEligibilityResult | PromiseLike<GetFinancingPreEligibilityResult>
  ) => void;
  reject: () => void;
};
export type GetFinancingPreEligibilityResult = {
  decision: FinancingEligibilityStatus;
  reason?: string;
};

export enum FinancingPreEligibilityDecision {
  Eligible = 'eligible',
  Pending = 'pending',
  Ineligible = 'ineligible',
  Unknown = 'unknown',
}

export enum IneligibilityReason {
  AmountTooHigh = 'amountTooHigh',
  IneligibleVendor = 'ineligibleVendor',
  IneligibleFundingSource = 'IneligibleFundingSource',
  RiskReasons = 'riskReasons',
  FailedPreEligibility = 'failedPreEligibility',
  GeneralError = 'generalError',
  FailedRepayments = 'failedRepayments',
}

export type FinancingPreEligibility = {
  decision: FinancingEligibilityStatus;
  reason: IneligibilityReason;
  isLoading: boolean;
  errorCode: string | null;
};

export type Financing = Readonly<{
  chosenInstallmentOption: InstallmentOption | null;
  intentId: string | null;
}>;
