import first from 'lodash/first';
import merge from 'lodash/merge';
import values from 'lodash/values';
import { createRestfulSlice, hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { selectCreateIsLoading } from 'src/helpers/redux/restCreateSlice';
import {
  INVITATIONS_BATCH_CREATE_SUCCESS,
  INVITATIONS_CREATE_SUCCESS,
  INVITATIONS_INVITEE_ORGANIZATIONS_SUCCESS,
  INVITATIONS_VALIDATE_SUCCESS,
} from 'src/modules/invitations/invitations-actions';
import { ORGANIZATIONS_CREATE_NOT_OWNED_SUCCESS } from 'src/modules/organizations/organizations-actions';
import { invitationsApi } from './api';
import { name } from './consts';
import {
  batchCreateInvitationsSlice,
  inviteeOrganizationsSlice,
  relatedInvitationsSlice,
  validateCreateInvitationSlice,
  validateInvitationSlice,
} from './slices';

export type UserOrgType = {
  id: number | null;
  firstName: string;
  lastName: string;
};

export type UserOrganizationType = {
  user?: UserOrgType;
};

const createInvitationIsLoading = selectCreateIsLoading('default');

const invitationStore = createRestfulSlice({
  name,
  schemaName: 'invitation',
  api: invitationsApi,
  selectors: {
    org(orgId) {
      const orgSelectors = {
        invitations(state) {
          const hashKey = hashListKey({ orgId });

          return (state[name].lists[hashKey]?.order || []).map((id) => state[name].byId[id]);
        },
        isLoading(state) {
          const hashKey = hashListKey({ orgId });

          return state[name].lists[hashKey]?.loading;
        },
      };

      return orgSelectors;
    },
    validation: (state) => state[name].validation,
    validationInvitation: (state) => first(values(state[name].byId)),
    isCreating: (state) => createInvitationIsLoading(null)(state[name]),
  },
  extraReducers: {
    [INVITATIONS_CREATE_SUCCESS](state, action) {
      addToList({
        invitation: action.payload,
        orgId: action.payload.organizationId,
        state,
      });
    },
    [INVITATIONS_VALIDATE_SUCCESS](state, action) {
      state.byId[action.payload.invitation.id] = merge(
        {},
        state.byId[action.payload.invitation.id],
        action.payload.invitation
      );
    },
    [ORGANIZATIONS_CREATE_NOT_OWNED_SUCCESS](state, { payload }) {
      if (payload.invitation) {
        state.byId[payload.invitation.id] = payload.invitation;
      }
    },
    [INVITATIONS_BATCH_CREATE_SUCCESS](state, action) {
      (action.payload || []).forEach((invitation) => {
        state.byId[invitation.id] = invitation;
        addToList({
          invitation,
          orgId: invitation.organizationId,
          state,
        });
      });
    },
    [INVITATIONS_INVITEE_ORGANIZATIONS_SUCCESS](state, { payload }) {
      state.byId[payload.invitation.id] = merge({}, state.byId[payload.invitation.id], {
        ...payload.invitation,
        inviteeOrganizations: payload.organizations,
      });
    },
  },
  slices: {
    validateInvitation: validateInvitationSlice,
    batchCreate: batchCreateInvitationsSlice,
    relatedInvitations: relatedInvitationsSlice,
    validateCreate: validateCreateInvitationSlice,
    inviteeOrganizations: inviteeOrganizationsSlice,
  },
});

function addToList({ orgId, invitation, state }) {
  const hashKey = hashListKey({ orgId });

  if (state.lists[hashKey]?.order) {
    (state.lists[hashKey].order || []).push(invitation.id);
  }
}

export function getInvitationActions(dispatch) {
  return {
    async list(params) {
      return dispatch(invitationStore.actions.list(params));
    },
    async create(params: any, meta?: any) {
      return dispatch(invitationStore.actions.create(params, meta));
    },
    async delete(params) {
      return dispatch(invitationStore.actions.delete(params));
    },
    async resend(params) {
      return dispatch(invitationStore.actions.update(params));
    },
    async validate(params) {
      return dispatch(invitationStore.actions.validateInvitation(params));
    },
  };
}

export default invitationStore;
