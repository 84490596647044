import merge from 'lodash/merge';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { joinOrganizationRequestsApi } from 'src/modules/join-organization-requests/api';
import { name } from './../consts';

export const acceptRequestSlice = createApiCallSlice({
  name: generateSliceName(name, 'accept_request'),
  api: joinOrganizationRequestsApi.accept,
  initialState: {
    accept: {},
    byId: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.accept[action.payload.id] = {
        loading: true,
        error: null,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      delete state.accept[action.meta.identifier.id];
      state.byId[action.meta.identifier.id] = merge(
        state.byId[action.meta.identifier.id] || {},
        action.payload.joinOrganizationRequest
      );
      state.byId[action.meta.identifier.id].organizationId = action.meta.identifier.organizationId;
    },
    [ON_FAILURE]: (state, action) => {
      state.accept[action.meta.identifier.id] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    status: (id) => (state) => state[name].accept[id],
  },
});
