import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import config from 'src/config';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import {
  isFailedEnrollment,
  isSuccessfulEnrollment,
  useAccounts,
} from 'src/modules/spend-management/accounts/hooks/useAccounts';
import { useEnrollAccount } from 'src/modules/spend-management/accounts/hooks/useEnrollAccount';
import { useBuyers } from 'src/modules/spend-management/buyers/hooks/useBuyers';
import { analytics, Context, Event, Page, pathToPageNames } from 'src/pages/spend-management/SpendManagementAnalytics';
import { getOrgId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant, OrgPreferencesTypeKeys } from 'src/utils/consts';
import { uuid } from 'src/utils/uuid';

const POLLING_INTERVAL = 5000;
const MAX_WAIT_TIME_AFTER_WINDOW_CLOSE = 60000;

const buildWidgetUrl = (buyerId: string, clientRequestId: string) =>
  `${config.spendManagement.amexUrls.login}?target=${encodeURIComponent(
    `${config.spendManagement.amexUrls.enrollment}?${qs.stringify({
      partner_id: config.spendManagement.amexPartnerId,
      client_request_id: clientRequestId,
      buyer_id: buyerId,
    })}`
  )}`;

export const useEnrollment = ({ onEnrolled }: { onEnrolled: () => void }) => {
  const [enrolling, setEnrolling] = useState(false);
  const [enrollmentWindow, setEnrollmentWindow] = useState<Window | null>(null);
  const [winCloseTime, setWinCloseTime] = useState<number | null>(null);
  const { create: createBuyer, error: createBuyerError } = useBuyers();
  const { accounts, loading: loadingAccounts, refetch: refetchAccounts, error: getAccountsError } = useAccounts({
    requestOnInit: false,
  });
  const { enrollAccount, loading: loadingEnrollAccount } = useEnrollAccount();
  const [buyerId, setBuyerId] = useState<string | undefined>(undefined);
  const [clientRequestId, setClientRequestId] = useState<string | undefined>(undefined);

  const orgId = useSelector(getOrgId);
  const { updateOrganizationPreference, isUpdatingOrganizationPreference } = useUpdateOrganizationPreference();
  const organizationPreferences = useOrganizationPreferences();

  const [currentPage, setCurrentPage] = useState<Page>(Page.ORG_SETUP_DONE);
  const { path } = useRouteMatch();
  useEffect(() => {
    setCurrentPage(pathToPageNames[path]);
  }, [path]);

  const onEnrollClick = () => {
    setEnrolling(true);
    setWinCloseTime(null);
    setEnrollmentWindow(null);
  };

  const setOrgSpendManagementPref = useCallback(async () => {
    if (
      orgId &&
      organizationPreferences &&
      !organizationPreferences.isSpendManagementEnabled &&
      !isUpdatingOrganizationPreference
    ) {
      await updateOrganizationPreference({
        orgId,
        key: OrgPreferencesTypeKeys.isSpendManagementEnabled,
        value: 'true',
      });
    }
  }, [isUpdatingOrganizationPreference, orgId, organizationPreferences, updateOrganizationPreference]);

  useEffect(() => {
    const onLoad = async () => {
      try {
        setBuyerId(await createBuyer());
      } catch (e) {
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'spendManagement.pages.enroll.toasts.failedCreateBuyer',
          autoClose: false,
        });
      }
    };

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOrgSpendManagementPref();
  }, [setOrgSpendManagementPref]);

  useEffect(() => {
    if (enrolling && buyerId) {
      const requestId = uuid();
      setClientRequestId(requestId);
      enrollAccount({ clientRequestId: requestId, buyerId });
      const win = window.open(buildWidgetUrl(buyerId, requestId), '_blank');

      if (!win) {
        setEnrolling(false);
        analytics.track(currentPage, Context.ENROLL_PROGRESS, Event.FAILED);
        // todo: error toast

        return;
      }

      setEnrollmentWindow(win);
      refetchAccounts({ clientRequestId: requestId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrolling, buyerId]);

  useEffect(() => {
    if (enrolling && !loadingAccounts && !loadingEnrollAccount) {
      // Enrolling successful
      if (accounts?.some(isSuccessfulEnrollment)) {
        analytics.track(currentPage, Context.ENROLL_PROGRESS, Event.SUCCESS);
        pushNotification({
          type: NotificationVariant.SUCCESS,
          msg: 'spendManagement.pages.enroll.toasts.successEnrollment',
          autoClose: false,
        });
        onEnrolled();

        return;
      }

      // Enrolling error

      const enrollmentFailed = accounts?.some((acc) => isFailedEnrollment(acc));

      if (
        enrollmentFailed ||
        (winCloseTime && new Date().getTime() - winCloseTime > MAX_WAIT_TIME_AFTER_WINDOW_CLOSE)
      ) {
        if (enrollmentFailed) {
          analytics.track(currentPage, Context.ENROLL_PROGRESS, Event.FAILED);
        } else {
          analytics.track(currentPage, Context.ENROLL_PROGRESS, Event.FAILED_TIMEOUT);
        }

        setEnrolling(false);
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'spendManagement.pages.enroll.toasts.failedEnrollment',
        });

        return;
      }

      // Window closed - limit waiting time
      if (enrollmentWindow?.closed && !winCloseTime) {
        setWinCloseTime(new Date().getTime());
      }

      if (accounts || getAccountsError) {
        setTimeout(() => refetchAccounts({ clientRequestId }), POLLING_INTERVAL);
      }
    }
    // eslint-disable-next-line
  }, [
    enrolling,
    loadingAccounts,
    accounts,
    winCloseTime,
    enrollmentWindow,
    clientRequestId,
    getAccountsError,
    loadingEnrollAccount,
  ]);

  return {
    onEnrollClick,
    loading: !buyerId,
    enrolling,
    error: createBuyerError,
  };
};
