import * as React from 'react';
import styled, { css } from 'styled-components';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { BrandFullLogo } from 'src/core/ds/brandFullLogo';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { brandNameByCardNetwork } from 'src/utils/card';
import { CardNetworkTypes, CardTypes } from 'src/utils/consts';
import { hasFundingLogo } from 'src/utils/funding-sources';

type Props = {
  goAddItem?: (cardType?: CardTypes) => void;
  iconName?: IconNames;
  nameLabel: string;
  nameLabelValues?: Record<string, string>;
  descriptionLabel?: string;
  descriptionValues?: Record<string, string | React.ReactElement>;
  itemName?: string;
  itemNameValues?: Record<string, string>;
  cardType?: CardTypes;
  network?: keyof typeof CardNetworkTypes;
  imageObj?: Record<string, string>;
  actionOptions?: ActionOption[];
  hideAddItemLink?: boolean;
  disabled?: boolean;
  moduleName?: string;
  testId?: string;
  isManualBankAccountNotVerified?: boolean;
  isPending?: boolean;
};

const SettingItemDetails = ({
  goAddItem,
  iconName,
  nameLabel,
  nameLabelValues,
  descriptionLabel,
  descriptionValues,
  itemName,
  cardType,
  network,
  imageObj,
  actionOptions,
  hideAddItemLink,
  disabled,
  testId,
  isManualBankAccountNotVerified,
  isPending,
}: Props) => {
  const isDeliveryMethodPendingForVerification = isManualBankAccountNotVerified || isPending;
  let settingItemProps = {};

  if (!itemName && goAddItem) {
    settingItemProps = { actionable: 'actionable' };
  }

  const hasLogo = !!(imageObj && hasFundingLogo(imageObj));
  const renderCreditCardIcon = () => {
    if (!imageObj && iconName) {
      return <Icon name={iconName} size={IconSize.xl} />;
    }

    return network ? (
      <BrandFullLogo name={brandNameByCardNetwork[CardNetworkTypes[network]]} />
    ) : (
      <Icon name={IconNames[`${cardType}Card`]} />
    );
  };

  const onClick = () => {
    goAddItem && goAddItem(cardType);
  };

  return (
    <SettingItemDetailsDiv data-testid={testId} {...settingItemProps}>
      <Flex flex={1} alignItems="center">
        {cardType === CardTypes.CREDIT && renderCreditCardIcon()}
        {imageObj && hasLogo && <Image src={`data:image/jpeg;base64,${imageObj.logo}`} alt={imageObj.name} />}
        {imageObj && !hasLogo && <Icon name={IconNames.bank} size={IconSize.xl} color="black" me={5} />}
        <SettingItemDetailsTextContainer>
          <SettingItemDetailsName disabled={disabled}>
            <Box textStyle="body2Semi">
              <MIFormattedText label={nameLabel} values={nameLabelValues} />
            </Box>
            {isDeliveryMethodPendingForVerification && (
              <Box ms={2}>
                <Badge
                  label="settings.receivingMethods.pendingVerificationIndicator"
                  variant={BadgeVariants.FILLED}
                  color={BadgeColors.NEUTRAL}
                  testId={`${testId}-pending-indicator`}
                />
              </Box>
            )}
          </SettingItemDetailsName>
          <SettingItemDetailsDescription disabled={disabled}>
            {descriptionLabel && (
              <Box textStyle="body3">
                <MIFormattedText label={descriptionLabel} values={descriptionValues} />
              </Box>
            )}
          </SettingItemDetailsDescription>
        </SettingItemDetailsTextContainer>
      </Flex>
      {!actionOptions && goAddItem && !hideAddItemLink && (
        <StyledMIInlineLink
          label="settings.paymentMethods.hint"
          testId="settings.paymentMethods.hint"
          onClick={onClick}
        />
      )}
      {actionOptions && !!actionOptions.length && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Icon name={IconNames.more} />}
            variant="action"
            placement="top"
            data-testid="delivery-method-actions"
          />
          <MenuList>
            {actionOptions.map(({ label, action }) => (
              <MenuItem label={label} onClick={action} />
            ))}
          </MenuList>
        </Menu>
      )}
    </SettingItemDetailsDiv>
  );
};

export default SettingItemDetails;

const SettingItemDetailsDiv = styled.div<{
  disabled?: boolean;
  actionable?: string;
}>`
  width: 100%;
  border-radius: 0.8rem;
  border: solid 0.1rem #e3e3e3;
  background-color: ${(props) => props.theme.colors.white.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 2.4rem;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  position: relative;
  ${(p) =>
    p.actionable &&
    !p.disabled &&
    css`
      &:hover {
        box-shadow: none;
      }
    `}
`;

const Image = styled.img`
  height: 3.4rem;
  width: 3.4rem;
  margin-right: 2rem;
`;

const SettingItemDetailsTextContainer = styled.div`
  flex: 1;
`;

const SettingItemDetailsName = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 2.2rem;
  color: ${(props) => (props.disabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.main)};
`;

const SettingItemDetailsDescription = styled.div<{ disabled?: boolean }>`
  min-height: 1.6rem;
  color: ${(props) => (props.disabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.subtitle)};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.hint};
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
