import { featureFlags } from '@melio/shared-web';
import { useIsAccountingFirm } from 'src/hooks/accountants/useIsAccountingFirm';
import { FeatureFlags } from 'src/utils/consts';

export const useAccountingFirmSkipsStep = () => {
  const [isFirmAsVendorFlagOpen] = featureFlags.useFeature<boolean>(FeatureFlags.FirmAsVendor, false);
  const isCurrentOrgAnAccountingFirm = useIsAccountingFirm();

  return isFirmAsVendorFlagOpen && isCurrentOrgAnAccountingFirm;
};
