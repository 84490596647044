import { BillingType, InternationalCountryGroup } from 'src/utils/ServerTypes';

export enum InternationalPaymentPurpose {
  GOODS = 'Goods',
  SERVICES = 'Services',
  CHARITABLE_DONATIONS = 'Charitable donations',
  OTHER = 'Other',
}

export type CountriesGroup = {
  groupLabel: InternationalCountryGroup;
  options: CountryType[];
};

export type CountryType = {
  region: InternationalCountryGroup;
  value: string;
  label: string;
  isSupported: boolean;
  billingType: BillingType;
  risk: string;
};
