export const double = {
  _after: {
    order: 2,
    content: '""',
    display: 'block',
    borderRadius: '0px 0px 0.9rem 0.9rem',
    backgroundColor: 'white',
    border: '0 solid rgba(231, 231, 231, 1)',
    borderWidth: '0px 1px 1px 1px',
    width: 'calc(100% - 16px)',
    height: '7px',
  },
};

export const multiple = {
  _before: {
    order: 3,
    content: '""',
    display: 'block',
    borderRadius: '0px 0px 0.9rem 0.9rem',
    backgroundColor: 'white',
    border: '0 solid rgba(231, 231, 231, 1)',
    borderWidth: '0px 1px 1px 1px',
    width: 'calc(100% - 32px)',
    height: '7px',
  },
};
