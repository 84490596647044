import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

type Props = {
  searchText?: string | null;
  platformLabel: string;
};

const AddOption = ({ searchText, platformLabel }: Props) => {
  if (searchText) {
    return null;
  }

  return (
    <AddOptionComponentWrapper>
      <PlusIcon />
      <MIFormattedText label="linkBankAccountReceivingMethod.link.bankAccount.newOption" values={{ platformLabel }} />
    </AddOptionComponentWrapper>
  );
};

export default AddOption;

const AddOptionComponentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled.i.attrs({ className: 'icon-plus-icon' })`
  vertical-align: text-bottom;
  font-size: 2rem;
  margin-right: 1rem;
  margin-left: 0.2rem;
`;
