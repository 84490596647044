/* eslint-disable camelcase */
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import head from 'lodash/head';
import { useEffect, useMemo, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { analytics } from 'src/services/analytics';
import clientServiceApi from 'src/services/api/clientService';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { convertWhitePageAddress, getFullAddress, whitePagesAddressKeys } from 'src/utils/address';
import { ManualAddress } from 'src/utils/consts';
import { AddressType } from 'src/utils/types';

type Props = {
  selectedAddressId: any;
  onSuccess: (data) => void;
};

type ManualAddressType = {
  street_line_1?: string;
  street_line_2?: string;
  city?: string;
  state_code?: string;
  postal_code?: string;
  country_code?: string;
  is_valid?: boolean;
  diff?: any[];
};

export function useManualAddressState(props: Props) {
  const { selectedAddressId, onSuccess } = props;
  const [whitePageAddressView, setWhitePageAddressView] = useState(false);

  const { onApiCall, result: whitePageAddress, loading: isAddressLoading } = useApi<[AddressType], ManualAddressType>({
    api: clientServiceApi.getAddressValidation,
  });

  const model: Partial<AddressType> = useMemo(
    () => ({
      addressLine1: '',
      addressLine2: '',
      state: '',
      city: '',
      zipCode: '',
    }),
    []
  );
  const submitAction = async (value) => {
    const validationErrors = getValidationErrors('companyInfo', value, ['addressLine1', 'city', 'state', 'zipCode']);

    if (!isValidationOk(validationErrors)) {
      analytics.track('manual-address', 'validation-error', validationErrors);
      throw new ValidationError({ validationErrors });
    }

    await onApiCall(value).then(() => setWhitePageAddressView(true));
  };

  const [addressMethodMV, { submit }] = useForm(model, {
    submit: submitAction,
  });
  const originalAddress = useMemo(() => convertWhitePageAddress(addressMethodMV), [addressMethodMV]);
  const fullAddress = getFullAddress(originalAddress);
  const isValidAddress = !!whitePageAddress?.is_valid;
  const whitePageValidationErrors = isValidAddress ? (head(whitePageAddress?.diff) as Record<string, any>) : undefined;

  useEffect(() => {
    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSuccess(fullAddress);
      setWhitePageAddressView(false);
    }
  }, [whitePageAddress, fullAddress, onSuccess]);

  const data = {
    whitePageAddress,
    whitePageAddressView,
    isAddressLoading,
    originalAddress,
    isValidAddress,
    whitePageValidationErrors,
  };

  async function confirmAddress() {
    let address = fullAddress;
    let option = '';

    if (isValidAddress && selectedAddressId === ManualAddress.SUGGESTED) {
      option = 'suggested-address-confirmed';
      const updatedAddress = Object.keys(whitePagesAddressKeys).reduce((obj, key) => {
        const newKey = whitePagesAddressKeys[key];
        obj[newKey] = whitePageAddress ? whitePageAddress[key] : null;

        return obj;
      }, {});

      address = getFullAddress(updatedAddress);
    } else {
      option = address && !isValidAddress ? 'no-suggestion-address-confirmed' : 'suggested-address-declined';
    }

    analytics.trackAction('submit-address', { option });
    onSuccess(address);
  }

  async function editAddress() {
    setWhitePageAddressView(false);
  }

  return [
    data,
    {
      confirmAddress,
      submit,
      editAddress,
    },
    addressMethodMV,
  ] as [typeof data, any, typeof addressMethodMV];
}
