import { InstallmentStatus } from 'src/utils/consts';
import { isCompletedInstallment, isFailedInstallment, isScheduledInstallment } from 'src/utils/financing';
import { Installment } from 'src/utils/types';

export const getRowTextColorByPaymentStatue = (showBadges: boolean, status?: InstallmentStatus) => {
  if (showBadges && status === InstallmentStatus.Completed) {
    return 'grey.700';
  } else if ((showBadges && status === InstallmentStatus.Scheduled) || status === InstallmentStatus.Retry) {
    return 'black';
  }

  return 'grey.500';
};

export const getConvertedStatus = (installment: Installment): InstallmentStatus => {
  if (isScheduledInstallment(installment)) {
    return InstallmentStatus.Scheduled;
  }

  if (isCompletedInstallment(installment)) {
    return InstallmentStatus.Completed;
  }

  if (isFailedInstallment(installment)) {
    return InstallmentStatus.Failed;
  }

  return installment.status;
};
