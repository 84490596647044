import { Box, Flex } from '@chakra-ui/react';
import Highlighter from 'react-highlight-words';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { ListItemContainer } from 'src/components/list/ListItemContainer';
import { Badge } from 'src/core/ds/badge';
import { Icon, IconNames } from 'src/core/ds/icon';
import { InstallmentsTitle } from 'src/pay/components/InstallmentsTitle';
import { PayCardBadge, tags } from 'src/pay/consts';

type Props = {
  disabled: boolean;
  isSelected: boolean;
  vendorName: string;
  currentInstallmentNumber: number;
  totalNumberOfInstallments: number;
  onClick: () => void;
  scheduledDate: string | Date;
  installmentAmount: number;
  search: string;
  deliveryStatusFormattedMessage: string;
  tag?: PayCardBadge;
};

export const InstallmentListItem = ({
  disabled,
  isSelected,
  vendorName,
  currentInstallmentNumber,
  totalNumberOfInstallments,
  onClick,
  tag,
  scheduledDate,
  installmentAmount,
  search,
  deliveryStatusFormattedMessage,
}: Props) => (
  <ListItemContainer
    disabled={disabled}
    isSelected={isSelected}
    onClick={onClick}
    testId="installment-list-item"
    isCheckboxDisabled
  >
    <Flex justifyContent={(vendorName || '').length > 0 ? 'space-between' : 'flex-end'} alignItems="center" mb={3}>
      <Flex align="center" overflow="hidden" grow={1} h={6}>
        <Icon name={IconNames.installments} />
        <Box pl="3" textStyle="body2Semi" textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
          <InstallmentsTitle
            currentInstallmentNumber={currentInstallmentNumber}
            totalInstallmentAmount={totalNumberOfInstallments}
          />
        </Box>
      </Flex>
      {tag && (
        <Box ml={2}>
          <Badge {...tags[tag]} testId="list-item-tag-payment" />
        </Box>
      )}
    </Flex>
    <Flex justifyContent="space-between" alignItems="center">
      <Box display={{ base: 'inline-block' }}>
        <Box textStyle="body4Semi" data-testid="list-item-scheduled-date" color="grey.700">
          <MIFormattedText
            label="list.group.installment.scheduledLabel"
            values={{ date: <MIFormattedDate date={scheduledDate} /> }}
          />
        </Box>
      </Box>
      <Box ml={2}>
        <MIMoney
          amount={installmentAmount}
          search={search}
          showAmountArrowIcon={false}
          testId="list-item-amount"
          privateData
        />
      </Box>
    </Flex>
    <Box borderTop="1px" borderColor="grey.400" mt={4} mr={-4} pr={4}>
      <Flex
        pt={4}
        justifyContent="space-between"
        textStyle="body4"
        color="grey.700"
        data-testid="installment-list-item-delivery-status"
      >
        <Highlighter searchWords={[search]} autoEscape textToHighlight={deliveryStatusFormattedMessage} />
      </Flex>
    </Box>
  </ListItemContainer>
);
