import styled from 'styled-components';
import { MIMoney } from 'src/components/common/MIMoney';
import { MITableBodyCell, MITableRow } from 'src/components/layout/TableElements';
import { FailedDMSvgIcon } from 'src/images/icons/FailedDMSvgIcon';
import { SelectedDMSvgIcon } from 'src/images/icons/SelectedDMSvgIcon';
import { BillStatus } from 'src/utils/consts';

type Props = {
  billStatus: BillStatus;
  invoiceDate: Date;
  totalAmount: number;
  onPdfIconClick: () => void;
};

const InternalBillsTableBodyRow = ({ billStatus, invoiceDate, totalAmount, onPdfIconClick }: Props) => (
  <MITableRow>
    <StatusTableBodyCell>
      {billStatus === BillStatus.PAID ? <SelectedDMSvgIcon width="16" /> : <FailedDMSvgIcon width="16" />}
    </StatusTableBodyCell>
    <MITableBodyCell>{invoiceDate}</MITableBodyCell>
    <AmountTableBodyCell>
      <TableMIMoney size="extraSmall" amount={totalAmount} />
    </AmountTableBodyCell>
    <PdfIconTableBodyCell>
      <PdfIcon className="icon-download-file" onClick={() => onPdfIconClick()} />
    </PdfIconTableBodyCell>
  </MITableRow>
);

const StatusTableBodyCell = styled(MITableBodyCell)`
  text-align: center;
`;

const AmountTableBodyCell = styled(MITableBodyCell)`
  text-align: right;
`;

const TableMIMoney = styled(MIMoney)`
  display: flex;
  justify-content: flex-end;
`;

const PdfIconTableBodyCell = styled(MITableBodyCell)`
  text-align: right;
  padding-right: 3.3rem;
`;

const PdfIcon = styled.i`
  font-size: 2.4rem;
  cursor: pointer;
`;

export default InternalBillsTableBodyRow;
