import { createReducer } from '@reduxjs/toolkit';
import isObjectLike from 'lodash/isObjectLike';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import reduce from 'lodash/reduce';
import set from 'lodash/set';
import { persistReducer } from 'redux-persist';
import { all } from 'redux-saga/effects';
import { composeReducers } from './composeReducers';

export function composeSagas(list) {
  return function* () {
    yield all(map(list, (saga) => saga && saga()));
  };
}

const mergeSliceProp = (prop, slices, extra) => ({
  ...reduce(slices, (acc, s) => ({ ...acc, ...(s[prop] || {}) }), {}),
  ...(extra || {}),
});
const mapSliceProp = (prop, slices, extra) => ({
  ...reduce(slices, (acc, value, key) => (value?.[prop] ? set(acc, [key], value[prop]) : acc), {}),
  ...extra,
});
export function composeSlices(rawSlices, extra) {
  const slices = pickBy(rawSlices, isObjectLike);
  const { extraReducers, selectors, initialState, dispatchers, persistConfig, ...rest } = extra;
  const allInitialState = mergeSliceProp('initialState', slices, initialState || {});
  const extraReducer = createReducer(undefined, extraReducers || {});
  const reducer = composeReducers(allInitialState, ...map(slices, (s) => s.reducer), extraReducer);

  return {
    initialState: allInitialState,
    reducer: persistConfig ? persistReducer(persistConfig, reducer) : reducer,
    *saga() {
      yield all(map(slices, (s) => s.saga && s.saga()).concat(map(extra.extraSagas || {}, (s) => s)));
    },
    actions: mapValues(slices, (s) => s.actions),
    selectors: mapSliceProp('selectors', slices, selectors),
    dispatchers: mapSliceProp('dispatchers', slices, dispatchers),
    ...rest,
  };
}
