import { featureFlags } from '@melio/shared-web';
import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { isDirectoryVendor } from 'src/pages/vendor-directory/utils';
import { updatePaymentMemoAction } from 'src/redux/payBillWizard/actions';
import { getBill, getBillAmount, getCompanyName, getContactEmail, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { getBillPaymentIndex } from 'src/utils/bills';
import { FeatureFlags } from 'src/utils/consts';
import { MemoPageForm, PayBillMemoPage } from './components/PayBillMemoPage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type Props = PayBillProps;

const PlainPayBillMemoPageContainer = ({ onNextMemo, onPrevMemo, goExit }: Props) => {
  const screenName = 'memo';
  const dispatch = useDispatch();
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const amount = useSelector(getBillAmount);
  const payment = useSelector(getPayment);
  const companyName = useSelector(getCompanyName);
  const vendorContactEmail = useSelector(getContactEmail);
  const directoryVendor = isDirectoryVendor({ ...bill.vendor, deliveryMethods: bill.vendor?.deliveryMethods ?? [] });
  const isOwnedVendor = !!bill.vendor?.ownedById;
  const [isEmailInputInMemoPageEnabled] = featureFlags.useFeature(FeatureFlags.EmailInMemo, false);
  const { vendorId } = bill;
  const analyticsProps = {
    vendorId,
    partialBillId: getBillPaymentIndex(bill),
  };

  const shouldShowEmailInput =
    !vendorContactEmail && !directoryVendor && !isOwnedVendor && isEmailInputInMemoPageEnabled;
  const vendorActions = useStoreActions(vendorsStore);

  const onEditVendor = (editedContact) => vendorActions.update({ orgId, ...editedContact });

  const onSubmit = async ({ memo, contactEmail }) => {
    dispatch(updatePaymentMemoAction(memo));

    if (!shouldShowEmailInput || isEmpty(contactEmail)) {
      onNextMemo();
    } else {
      await onEditVendor({ ...bill.vendor, contactEmail }).then(() => {
        analytics.trackAction('vendor-email-save-success', {
          screenName,
          vendorEmail: contactEmail,
          ...analyticsProps,
        });
        onNextMemo();
      });
    }
  };

  const model = useMemo(
    () => ({
      memo: payment.note || '',
      contactEmail: '',
    }),
    [payment.note]
  );

  const [memoMV, { submit }, validationErrors, loading] = useForm<MemoPageForm>(model, {
    submit: onSubmit,
    validateOnChange: false,
    validator: (key: string, value: string, modalState) => {
      if (key === 'contactEmail' && modalState.contactEmail && shouldShowEmailInput) {
        const contactEmailValidationError = getValidationErrors('vendor', { contactEmail: value }, ['contactEmail'])
          .contactEmail;

        return contactEmailValidationError || undefined;
      }

      return undefined;
    },
  });

  useEffect(() => {
    shouldShowEmailInput &&
      analytics.trackAction('exposed-to-add-vendor-email-line', {
        screenName,
        ...analyticsProps,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowEmailInput]);

  return (
    <PayBillMemoPage
      onNext={submit}
      onPrev={onPrevMemo}
      goExit={goExit}
      model={memoMV}
      companyName={companyName ?? ''}
      amount={amount}
      billId={bill.id}
      headerLabel="qbo.header.title"
      relativeStep={4 / 5}
      title="bills.pay.memo.title"
      label="bills.pay.memo.inputTitle"
      hint="bills.pay.memo.hint"
      isProcessing={loading}
      validationErrors={validationErrors}
      shouldShowEmailInput={shouldShowEmailInput}
    />
  );
};

export const PayBillMemoPageContainer = withPayBillData()(PlainPayBillMemoPageContainer);
