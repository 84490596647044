import { Badge as ChakraBadge, BadgeProps as ChakraBadgeProps } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { BadgeColors, BadgeVariants } from './consts';

export type OurBadgeProps = {
  variant?: BadgeVariants;
  color?: BadgeColors;
  label?: string;
  values?: Record<string, any>;
  testId?: string;
};

export type BadgeProps = Omit<ChakraBadgeProps, 'colorScheme' | 'variant' | 'size'> & OurBadgeProps;

export const Badge = ({ variant, color, label, values, testId, ...rest }: BadgeProps) => (
  <ChakraBadge data-testid={testId} variant={variant} colorScheme={color} {...rest}>
    <MIFormattedText label={label} values={values} />
  </ChakraBadge>
);
