import styled from 'styled-components';
import { MIEnhancedDropDown } from 'src/components/common/DropDown/MIEnhancedDropDown';
import { TextField } from 'src/core/ds/form/fields';
import { Expandable } from 'src/utils/types';
import { InlineFieldGroup } from './InlineFieldGroup';
import { ModelViewField } from './use-form';

export type InlineSelectFieldProps<T> = {
  model?: ModelViewField<T>;
  value?: string;
  label: string;
  id?: string;
  testId?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (change: Expandable<{ value: T }>) => void;
  options: { label: string; value: string }[];
  errorMessage?: string;
  viewOnly?: boolean;
  createLabel?: string;
  placeholder?: string;
  allowCustom?: boolean;
};

export function InlineSelectField<T>(props: InlineSelectFieldProps<T>) {
  const { model, id, label, options, value, viewOnly, disabled, onChange, required, ...rest } = props;
  const innerId = id || model?.id;
  const innerOnChange = onChange || model?.onChange;

  return (
    <InlineFieldGroup>
      <FormSelect>
        {viewOnly ? (
          <TextField
            label={label}
            model={model as ModelViewField<any>}
            id={innerId}
            size="sm"
            isRequired={required}
            isDisabled={disabled}
            isViewOnly
          />
        ) : (
          <MIEnhancedDropDown
            {...rest}
            label={label}
            id={innerId}
            onChange={innerOnChange}
            size="sm"
            options={options}
            value={model?.value || value}
            required={required}
            errorMessage={model?.error}
          />
        )}
      </FormSelect>
    </InlineFieldGroup>
  );
}

const FormSelect = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
