import { EventMappingsTree } from 'src/services/analytics/types';

const msnSalesForm = 'msn-sales-form';

export const msnSalesFormEventMapping: EventMappingsTree = {
  '/vendors/candidates/*/details': {
    'page.view': [msnSalesForm, 'join-network'],
    'msnSalesForm.submit.clicked': [msnSalesForm, 'submit-click', { email: 'email', businessType: 'businessType' }],
    'msnSalesForm.submit.error': [msnSalesForm, 'submit-error', { error: 'error' }],
  },
  '/vendors/candidates/*/success': {
    'page.view': [msnSalesForm, 'completed'],
  },
};
