import { ConnectedRouter } from 'connected-react-router';
import { SessionExpired } from 'src/components/layout/SessionExpired/SesstionExpired';
import { AppRibbons } from 'src/components/AppRibbons';
import { SiteTitle } from 'src/components/layout/SiteTitle';
import { ToastContainer, Bounce } from 'react-toastify';
import { SpriteComponent } from 'src/icons/SpriteComponent';
import { AppProviders, history } from './providers/AppProviders';
import ErrorBoundary from './pages/general/ErrorBoundary';
import Router from './pages/Router';
import ListSearchBarContextProvider from './context/ListSearchBarContext';
import Segment from './components/common/Segment';

export const App = () => (
  <AppProviders url={window.location.href}>
    <AppRibbons />
    <SpriteComponent />
    <SiteTitle />
    <ErrorBoundary>
      <Segment />
      <ListSearchBarContextProvider>
        <ConnectedRouter history={history}>
          <Router history={history} />
        </ConnectedRouter>
      </ListSearchBarContextProvider>
    </ErrorBoundary>
    <ToastContainer
      transition={Bounce}
      closeButton={false}
      className="MIToastContainer"
      toastClassName="MIToastInner"
    />
    <SessionExpired />
  </AppProviders>
);
