import { useReducer } from 'react';
import { analytics } from 'src/services/analytics';

type ReturnValue = {
  isBillDetailsOpen: boolean;
  onBillDetailsOpen: () => void;
};

export const useBillDetailsToggle = (): ReturnValue => {
  const [isBillDetailsOpen, toggleIsBillDetailsOpen] = useReducer((state) => !state, false);

  const onBillDetailsOpen = () => {
    analytics.track('bills', 'toggle-bill-details', { isBillDetailsOpen: !isBillDetailsOpen });
    toggleIsBillDetailsOpen();
  };

  return { isBillDetailsOpen, onBillDetailsOpen };
};
