import { PureComponent } from 'react';
import { compose } from 'recompose';
import { withPreservedStateNavigator } from 'src/hoc';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { convertToGoogleAddress, convertToServerAddress } from 'src/utils/address';
import { CompanyInfoOnboardingOrigin } from 'src/utils/consts';
import { GoogleCombinedAddressType } from 'src/utils/types';
import CompanyAddressForm from './components/CompanyAddressForm';
import { CompanyInfoProps, withCompanyInfoUpdates } from './hoc/withCompanyInfoUpdates';

type State = {
  address: GoogleCombinedAddressType;
};

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  locationState?: Record<string, any>;
} & CompanyInfoProps;

class CompanyAddressPageContainer1 extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    this.state = { address: convertToGoogleAddress(this.props.companyInfo) };
  }

  onNext = () => {
    const { companyInfo, companyAddress } = this.generateServerAddress();

    if (companyAddress) {
      this.props.onNext(companyInfo, companyAddress);
    }
  };

  onChange = (address: GoogleCombinedAddressType) => {
    this.setState({ address });
  };

  onPrev = () => {
    const { companyInfo } = this.generateServerAddress();

    if (companyInfo) {
      this.props.onPrev(companyInfo);
    }
  };

  goToManualAddress = () => {
    this.props.navigate(onboardingLocations.companyInfo.addressManual);
  };

  generateServerAddress = () => {
    const { address } = this.state;

    if (address) {
      const companyAddress = convertToServerAddress(address);

      return {
        companyInfo: { ...this.props.companyInfo, ...companyAddress },
        companyAddress,
      };
    }

    return { companyInfo: this.props.companyInfo, companyAddress: null };
  };

  render() {
    const { address } = this.state;
    const { isLoading, validationErrors, companyInfo, locationState } = this.props;

    return (
      <CompanyAddressForm
        isAccountantAddCompanyFlow={locationState?.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY}
        onNext={this.onNext}
        onPrev={locationState?.origin === CompanyInfoOnboardingOrigin.GUEST_PAYMENT_REQUEST ? undefined : this.onPrev}
        onChange={this.onChange}
        goToManualAddress={this.goToManualAddress}
        address={address}
        isLoading={isLoading}
        validationErrors={validationErrors}
        companyName={companyInfo.companyName ?? ''}
      />
    );
  }
}

export default compose(withPreservedStateNavigator(), withCompanyInfoUpdates())(CompanyAddressPageContainer1);
