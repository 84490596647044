import get from 'lodash/get';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { GlobalState } from 'src/redux/types';
import { updateUserPreferenceAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { AccountingSoftware, UserPreferencesKey } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import locations from 'src/utils/locations';
import { AccountingSoftwarePage } from './components/AccountingSoftwarePage';

type MapDispatchToProps = {
  updateUserPreference: (id: string, value: any) => Promise<void>;
};

type MapStateToProps = {
  orgId: number;
};

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean) => void;
  locationState: {
    origin: string;
  };
} & MapDispatchToProps &
  MapStateToProps;

type State = {
  selectedAccountingSoftware: AccountingSoftware | null | undefined;
};

const eventPage = 'link-accounting-software';

class AccountingSoftwarePageContainer extends PureComponent<Props, State> {
  static defaultProps = {
    locationState: {},
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedAccountingSoftware: null,
    };
  }

  onCancelClicked = (cancelRoute: string) => {
    const { navigate, updateUserPreference } = this.props;

    analytics.track(eventPage, 'select-accounting-software', {
      type: AccountingSoftware.NONE,
    });
    analytics.track(eventPage, 'dont-use-accounting-software');

    updateUserPreference(UserPreferencesKey.AccountingSoftwareType, AccountingSoftware.NONE).then(() => {
      navigate(cancelRoute);
    });
  };

  onSelectAccountingSoftware = (selectedAccountingSoftware) => {
    analytics.track(eventPage, 'select-accounting-software', {
      type: selectedAccountingSoftware,
    });
    this.setState({ selectedAccountingSoftware });
    this.goNext(selectedAccountingSoftware);
  };

  goLinkQuickbooks = () => {
    const { orgId } = this.props;
    intuit.goConnectToIntuit({
      intuitReturnUrl: generatePath(onboardingLocations.accountingSoftware.quickbooks.index, { orgId }),
    });
  };

  goNext = (selectedAccountingSoftware) => {
    const { orgId } = this.props;

    switch (selectedAccountingSoftware) {
      case AccountingSoftware.QUICKBOOKS:
        this.goLinkQuickbooks();
        break;
      default:
        this.props.navigate(
          generatePath(onboardingLocations.accountingSoftware.comingSoon.index, {
            orgId,
            type: selectedAccountingSoftware,
          })
        );
        break;
    }
  };

  goExit = () => {
    const origin = get(this.props, 'locationState.origin');
    const originPage = origin || 'settings';
    let exitRoute = locations.MainApp.dashboard.url();

    if (originPage === 'settings') {
      exitRoute = settingsLocations.index;
    }

    if (originPage === 'welcome') {
      exitRoute = locations.MainApp.dashboard.url();
    }

    analytics.track(eventPage, 'exit');
    this.props.navigate(exitRoute);
  };

  render() {
    const { selectedAccountingSoftware } = this.state;
    const origin = get(this.props, 'locationState.origin');
    const originPage = origin || 'settings';

    return (
      <AccountingSoftwarePage
        selectedAccountingSoftware={selectedAccountingSoftware}
        onSelectAccountingSoftware={this.onSelectAccountingSoftware}
        goExit={this.goExit}
        originPage={originPage}
        onCancelClicked={this.onCancelClicked}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  updateUserPreference(id: string, value: any) {
    return new Promise((resolve, reject) => {
      dispatch(updateUserPreferenceAction(id, value, resolve, reject));
    });
  },
});

export default compose(withNavigator(), connect(mapStateToProps, mapDispatchToProps))(AccountingSoftwarePageContainer);
