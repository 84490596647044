import { featureFlags } from '@melio/shared-web';
import { ChangeEvent, useEffect, useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Switch } from 'src/core/ds/switch';
import Tooltip from 'src/core/ds/tooltip';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { useUserPreference } from 'src/modules/users/hooks/useUserPreference';
import { sendBulkPaymentToggleToastNotification } from 'src/pages/regular-batch-payments/components/header/components/utils';
import { FeatureFlags, OrgPreferencesTypeKeys, UserPreferencesKey } from 'src/utils/consts';
import { CombineBillsTooltipBody } from './CombineBillsTooltipBody';

type Props = {
  bulkPaymentToggleOn: boolean;
  onChange: (event: boolean) => void;
  isDisabled: boolean;
  invalidBatchPaymentListGrouping: boolean;
  invalidBatchPaymentRiskTotalAmount: boolean;
};

export const BulkPaymentToggle = ({
  bulkPaymentToggleOn,
  onChange,
  isDisabled,
  invalidBatchPaymentListGrouping,
  invalidBatchPaymentRiskTotalAmount,
}: Props) => {
  const { updateOrganizationPreference, isUpdatingOrganizationPreference } = useUpdateOrganizationPreference();
  const {
    userPreferenceValue: batchBulkUncombinedSeen,
    setUserPreferenceValue: setBatchBulkUncombinedSeen,
  } = useUserPreference({
    userPreferenceKey: UserPreferencesKey.batchBulkUncombinedSeen,
  });
  const preferences = useOrganizationPreferences();
  const [isBatchUncombinedEnbled] = featureFlags.useFeature(FeatureFlags.BatchUncombined, false);
  const shownCombineBillsTooltip = preferences?.[OrgPreferencesTypeKeys.shownCombineBillsTooltip] !== 'true';
  const showUncombinedBillsTooltip = isBatchUncombinedEnbled && String(batchBulkUncombinedSeen) !== 'true';
  const [isCombineBillsTooltipOpen, setIsCombineBillsTooltipOpen] = useState(
    shownCombineBillsTooltip || showUncombinedBillsTooltip
  );
  const isChecked = bulkPaymentToggleOn && !isDisabled;
  const onToggle = (event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked);
  const onCloseCombineBillsTooltip = () => {
    setIsCombineBillsTooltipOpen(false);
  };
  const onOpenCombineBillsTooltip = async () => {
    if (!isUpdatingOrganizationPreference) {
      await updateOrganizationPreference({
        key: OrgPreferencesTypeKeys.shownCombineBillsTooltip,
        value: 'true',
      });
    }

    await setBatchBulkUncombinedSeen('true');
  };

  const bulkPaymentToggleDisableTooltipMessage = () => {
    if (invalidBatchPaymentRiskTotalAmount) {
      return <MIFormattedText label="regularBatchPayments.Settings.bulkPaymentToggleDisabledTotalAmountLimit" />;
    }

    if (invalidBatchPaymentListGrouping) {
      return <MIFormattedText label="regularBatchPayments.Settings.bulkPaymentToggleDisabledNoCombinedPayments" />;
    }

    return '';
  };

  useEffect(() => {
    !isDisabled && sendBulkPaymentToggleToastNotification(bulkPaymentToggleOn);
  }, [isDisabled, bulkPaymentToggleOn]);

  return (
    <Box>
      <Flex>
        <Box textStyle="body4Semi" color="grey.600" pb={3}>
          <MIFormattedText label="regularBatchPayments.Settings.bulkPaymentToggleLabel" />
        </Box>
        <Box ms={2} color="grey.600">
          <Tooltip
            data-testid="bulk-payment-toggle-tooltip"
            placement="top"
            label={<MIFormattedText label="regularBatchPayments.Settings.bulkPaymentToggleTooltip" />}
          >
            <Icon name={IconNames.infoCircle} size={IconSize.s} />
          </Tooltip>
        </Box>
      </Flex>
      <Tooltip
        placement="top-start"
        isOpen={isCombineBillsTooltipOpen && !isDisabled}
        label={
          <CombineBillsTooltipBody
            onClose={onCloseCombineBillsTooltip}
            onFirsTimeOpen={onOpenCombineBillsTooltip}
            isBatchUncombinedEnbled={isBatchUncombinedEnbled}
          />
        }
        pointerEvents="auto"
        shouldWrapChildren
      >
        <Flex alignItems="center">
          <Tooltip
            data-testid="bulk-payment-toggle-tooltip"
            label={bulkPaymentToggleDisableTooltipMessage()}
            placement="top-start"
            shouldWrapChildren
            isDisabled={isCombineBillsTooltipOpen || !isDisabled}
          >
            <Switch
              isDisabled={isDisabled}
              isChecked={isChecked}
              onChange={onToggle}
              testId="bulk-payment-switch"
              analyticsProperties={{ newToggleState: !bulkPaymentToggleOn }}
            />
          </Tooltip>
          <Box textStyle="body4" ms={2} color={isDisabled ? 'grey.600' : 'black'}>
            <MIFormattedText label="regularBatchPayments.Settings.bulkPaymentToggle" />
          </Box>
        </Flex>
      </Tooltip>
    </Box>
  );
};
