import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';
import Colors from 'src/core/theme/foundations/colors';

type Props = {
  dismiss: () => void;
};

export const CheckProtectionModal = ({ dismiss }: Props) => (
  <Modal closeOnOverlayClick={false} isOpen onClose={dismiss} closeOnEsc>
    <Flex data-testid="check-protection-modal" direction="column" gap={5} marginBlockEnd={6}>
      <Modal.CloseButton />
      <Flex alignItems="center" justifyContent="flex-start" w="full" gap={3}>
        <Icon name={IconNames.shield} size={IconSize.xl} />
        <Modal.HeaderTitle>
          <Box textStyle="h2Semi">
            <MIFormattedText label="pay.checkProtectionModal.title" />
          </Box>
        </Modal.HeaderTitle>
      </Flex>
      <Box textStyle="body2">
        <MIFormattedText label="pay.checkProtectionModal.description" />
      </Box>
    </Flex>
    <Flex direction="column" gap={3} marginBlockEnd={10}>
      <Flex gap={3}>
        <Box>
          <Icon name={IconNames.check} size={IconSize.lg} color={Colors.primary[500]} />
        </Box>
        <Flex direction="column" gap={0.5}>
          <Box textStyle="body2Semi">
            <MIFormattedText label="pay.checkProtectionModal.secureCategory.title" />
          </Box>
          <Box textStyle="body3">
            <MIFormattedText label="pay.checkProtectionModal.secureCategory.description" />
          </Box>
        </Flex>
      </Flex>
      <Flex gap={3}>
        <Box>
          <Icon name={IconNames.check} size={IconSize.lg} color={Colors.primary[500]} />
        </Box>
        <Flex direction="column" gap={0.5}>
          <Box textStyle="body2Semi">
            <MIFormattedText label="pay.checkProtectionModal.monitoringCategory.title" />
          </Box>
          <Box textStyle="body3">
            <MIFormattedText label="pay.checkProtectionModal.monitoringCategory.description" />
          </Box>
        </Flex>
      </Flex>
      <Flex gap={3}>
        <Box>
          <Icon name={IconNames.check} size={IconSize.lg} color={Colors.primary[500]} />
        </Box>
        <Flex direction="column" gap={0.5}>
          <Box textStyle="body2Semi">
            <MIFormattedText label="pay.checkProtectionModal.handlingCategory.title" />
          </Box>
          <Box textStyle="body3">
            <MIFormattedText label="pay.checkProtectionModal.handlingCategory.description" />
          </Box>
        </Flex>
      </Flex>
    </Flex>
    <Modal.Footer>
      <Flex w="full" justifyContent="flex-end">
        <Button
          onClick={dismiss}
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
          label="pay.checkProtectionModal.submit"
        />
      </Flex>
    </Modal.Footer>
  </Modal>
);
