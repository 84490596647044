import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';
import { analytics, Page } from 'src/pages/spend-management/SpendManagementAnalytics';

export const TransactionsEmptyState = () => {
  useEffect(() => {
    analytics.page(Page.TRANSACTION_LIST, { isEmptyState: true });
  }, []);

  return (
    <VStack justifyContent="center" alignItems="center" w="full">
      <Flex direction="column" alignItems="center" justifyContent="center" maxW="50rem">
        <Box mb={4} textAlign="center">
          <video
            data-component="Video"
            autoPlay
            src="https://platform-static.meliopayments.com/assets/melio/illustrations/no-items.webm"
            loop
            width="144px"
          >
            <track kind="captions" />
          </video>
        </Box>
        <Box textStyle="body2Semi">
          <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.noTransactions.title" />
        </Box>
        <Box mb={4} textAlign="center" textStyle="body3">
          <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.noTransactions.subtitle" />
        </Box>
      </Flex>
    </VStack>
  );
};
