import * as React from 'react';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { trackExpandRow } from '../../analytics/BatchPaymentsAnalytics';
import { MainRowType } from '../columns';

type ExpanderCellType = {
  row: MainRowType;
};

export const ExpanderCell: React.FC<ExpanderCellType> = ({ row }) => {
  const onClick = () => {
    const newState = !row.isExpanded;
    row.toggleRowExpanded(newState);
    trackExpandRow({
      groupingState: newState,
      billIds: row.original.payment.bills.map((bill) => bill.id),
      vendorId: row.original.vendor.id,
      payBillFlowUUID: row.original.payBillFlowUUID,
    });
  };

  return (
    <Box pl={3} onClick={onClick} data-testid="expander-cell">
      <Icon name={row.isExpanded ? IconNames.shevronDown : IconNames.shevronRight} size={IconSize.s} />
    </Box>
  );
};
