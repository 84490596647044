import {
  ComponentWithAs,
  forwardRef,
  MenuGroup as ChakraMenuGroup,
  MenuGroupProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';
import { AnalyticsPropsType } from 'src/core/ds/types';

type Props = Omit<MenuGroupProps, 'colorScheme' | 'variant' | 'size'> &
  AnalyticsPropsType & {
    title: string;
    values?: Record<string, any>;
  };

const MenuGroup: ComponentWithAs<'button', Props> = forwardRef(({ title, values, children, ...rest }, ref) => {
  const styles = useStyleConfig('MenuGroup');
  const intl = useIntl();

  return (
    <ChakraMenuGroup
      sx={styles}
      ref={ref}
      title={intl.formatMessage({ id: title }, values)}
      style={{ textTransform: 'uppercase' }}
      {...rest}
    >
      {children}
    </ChakraMenuGroup>
  );
});

export { MenuGroup };
