export enum RedirectOriginPages {
  SETTINGS = 'settings',
  NEW_REQUEST = 'newRequest',
  NEW_BILL = 'newBill',
  PAY_DASHBOARD = 'payDashboard',
  PAID_DASHBOARD = 'paidDashboard',
  RECEIVING_METHOD = 'receivingMethod',
  PAY_SUCCESS = 'paySuccess',
  ACCOUNTANT_NEW_CLIENT = 'accountantNewClient',
}

export const QBO_PLATFORM_LABEL = 'QuickBooks';
