import { History, Location } from 'history';
import { generatePath } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getPaidLocations } from 'src/pages/get-paid/locations';

export type ExpandedButtonType = {
  handleClick: () => void;
  page: string;
};

export type GetFabProps = {
  path: string;
  orgId: number;
  history: History;
  location?: Location;
  isMSNDashboardDisplay?: boolean;
  shouldSplitContactsTab?: boolean;
};

export enum LayoutPageType {
  BILL = 'bills',
  GET_PAID = 'get-paid',
  GET_PRO = 'get-pro',
  CONTACTS = 'contacts',
  VENDORS = 'vendors',
  CUSTOMERS = 'customers',
}

const getPageName = (path: string, shouldSplitContactsTab: boolean) => {
  if (path.includes(LayoutPageType.GET_PAID) || path.includes(LayoutPageType.GET_PRO)) {
    return LayoutPageType.GET_PAID;
  }

  if (!shouldSplitContactsTab && path.includes(LayoutPageType.CONTACTS)) {
    return LayoutPageType.CONTACTS;
  }

  if (path.includes(LayoutPageType.VENDORS)) {
    return LayoutPageType.VENDORS;
  }

  if (path.includes(LayoutPageType.CUSTOMERS)) {
    return LayoutPageType.CUSTOMERS;
  }

  return LayoutPageType.BILL;
};

export const getFabProps = ({
  path,
  orgId,
  history,
  location,
  isMSNDashboardDisplay,
  shouldSplitContactsTab = false,
}: GetFabProps): ExpandedButtonType => {
  let url;
  let state = {};
  const page = getPageName(path, shouldSplitContactsTab);

  switch (page) {
    case LayoutPageType.GET_PAID:
      url = getPaidLocations.create.options;
      break;
    case LayoutPageType.CONTACTS:
      url = isMSNDashboardDisplay ? contactsLocations.create.vendorsSingle : contactsLocations.create.type;
      state = { returnLocations: location };
      break;
    case LayoutPageType.VENDORS:
      url = contactsLocations.create.vendorsSingle;
      state = { returnLocations: location };
      break;
    case LayoutPageType.CUSTOMERS:
      url = contactsLocations.create.customersSingle;
      state = { returnLocations: location };
      break;
    default:
      url = billLocations.create.options;
      break;
  }

  const handleClick = () => history.push(generatePath(url, { orgId }), state);

  return {
    handleClick,
    page,
  };
};
