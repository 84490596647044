import keys from 'lodash/keys';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { encodeQuery, parseQueryString } from 'src/utils/query-utils';
import { useQueryString } from './useQueryString';

export function useQueryStateMultiValues<T extends Record<string | number, unknown>>(
  defaultValue: T,
  replace = true,
  copyState = true
): [T, (arg0: T) => void] {
  const query = useQueryString();
  const location = useLocation();
  const history = useHistory();
  const currentKeys = keys(defaultValue);
  const currentValue = { ...defaultValue, ...pick(query, currentKeys) };
  const setValue = useCallback(
    async (values: Partial<T>) => {
      const currQuery = parseQueryString(history.location.search);
      const newQuery = pickBy({ ...currQuery, ...values });
      const search = encodeQuery(newQuery, [], '');
      const { state, ...restLocation } = location;

      if (replace) {
        await history.replace({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      } else {
        await history.push({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      }
    },
    [replace, history, copyState]
  );

  return [currentValue, setValue];
}
