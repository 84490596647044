import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { accountsApi } from '../api';
import { SetDefaultAccountParams, UseSetDefaultAccountOptions } from '../types';

export const useSetDefaultAccount = () => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: setDefaultAccount, loading, error } = useApi<[SetDefaultAccountParams], string>({
    api: accountsApi.default,
  });

  return {
    loading,
    error,
    setDefaultAccount: ({ accountId }: UseSetDefaultAccountOptions) => setDefaultAccount({ orgId, accountId }),
  };
};
