import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { scannedInvoiceApi } from '../api';
import { storeName } from '../const';

export const markAsPaidSlice = createApiCallSlice({
  name: generateSliceName(storeName, 'MARK_AS_PAID'),
  api: scannedInvoiceApi.markAsPaid,
  initialState: {
    byId: {},
    markAsPaid: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      state.markAsPaid[action.payload.scannedInvoiceId] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const id = action.payload.scannedInvoiceId || action.meta?.identifier?.id;
      delete state.markAsPaid[id];
      delete state.byId[id];
    },
    [ON_FAILURE](state, action) {
      state.markAsPaid[action.payload.scannedInvoiceId] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    status: (id) => (state) => state[storeName].markAsPaid?.[id],
  },
});
