import * as React from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import Box from 'src/core/ds/box';
import { EmptyBlockState } from 'src/pages/get-pro/components/empty/EmptyBlockState';
import { EmptyFilterOrSearchState } from 'src/pages/get-pro/components/empty/EmptyFilterOrSearchState';
import EmptyState from 'src/pages/get-pro/components/empty/EmptyState';
import ErrorState from 'src/pages/get-pro/components/error/ErrorState';
import { TableContentState } from '../consts';
import { emptyStateStyle } from '../tableStyles';
import { getContentState } from './utils';

const MAP_CONTENT_BY_STATE = {
  [TableContentState.BLOCKED]: (
    <Box __css={emptyStateStyle}>
      <EmptyBlockState />
    </Box>
  ),
  [TableContentState.LOADING]: (
    <Box __css={emptyStateStyle} position="relative">
      <AreaLoader placement="single" />
    </Box>
  ),
  [TableContentState.ERROR]: (
    <Box __css={emptyStateStyle}>
      <ErrorState />
    </Box>
  ),
  [TableContentState.EMPTY]: (
    <Box __css={emptyStateStyle}>
      <EmptyState />
    </Box>
  ),
  [TableContentState.EMPTY_FILTER_OR_SEARCH_RESULT]: (
    <Box __css={emptyStateStyle}>
      <EmptyFilterOrSearchState />
    </Box>
  ),
  [TableContentState.CONTENT]: null,
};

export type TableContentStateProps = {
  loading?: boolean;
  error?: boolean;
  isFinishedOnboarding?: boolean;
  isAllResultsEmpty?: boolean;
  isFilterOrSearchResultsEmpty?: boolean;
};

export const useGetTableContentState = (
  params: TableContentStateProps
): [state: TableContentState, content: React.ReactNode] => {
  const state = getContentState(params);

  return [state, MAP_CONTENT_BY_STATE[state]];
};
