import { addBusinessDays } from 'date-fns';
import first from 'lodash/first';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { IconNames } from 'src/core/ds/icon';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { Separator } from 'src/core/ds/separator';
import { OnComplete } from 'src/hooks/useWizard/useWizard';
import { getPaymentsActions, paymentsStore } from 'src/modules/payments/payment-store';
import { FundingSourceDescription } from 'src/pages/bill/components/ViewBillPaymentActivity/components/FundingSourceDescription';
import { billLocations } from 'src/pages/bill/locations';
import { AccountRecord } from 'src/pages/settings/records';
import { DeliveryMethodIcon } from 'src/pages/vendor/components/DeliveryMethodIcon';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant, RefundReason } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { PaymentType } from 'src/utils/types';
import { RefundReviewBaseContainer } from './components/RefundReviewBaseContainer';
import { RefundReviewBaseContainerWithIcon } from './components/RefundReviewBaseContainerWithIcon';

type Props = {
  payment: PaymentType;
  cancelFlow: () => void;
  selectedReason: RefundReason;
  goBack: () => void;
  goNext: () => void;
  completeFlow: (onComplete: OnComplete) => void;
};

export const RequestRefundReview = ({ payment, cancelFlow, selectedReason, goBack, completeFlow }: Props) => {
  const dispatch = useDispatch();
  const deliveryMethod = deliveryMethodFactory(payment.deliveryMethod);
  const refundDueDate = addBusinessDays(new Date(), 5).toISOString();
  const fundingSource = AccountRecord(payment.fundingSource);
  const loading = useSelector(paymentsStore.selectors.refundPayment.loading);
  const billIds = payment.bills?.map((bill) => bill.id);

  const { refund } = getPaymentsActions(dispatch);

  const onSubmit = async () => {
    try {
      analytics.trackAction('button.click', {
        paymentId: payment.id,
        billIds,
        chosenReason: selectedReason,
      });
      await refund({
        orgId: payment.organizationId,
        paymentId: payment.id,
        reason: selectedReason,
      });
      analytics.trackAction('button.click.success', {
        paymentId: payment.id,
        billIds,
        chosenReason: selectedReason,
      });
      completeFlow(
        generatePath(billLocations.successScheduledRefund, {
          orgId: payment.organizationId,
          billId: first(billIds),
          paymentId: payment.id,
        })
      );
    } catch (error: any) {
      analytics.trackAction('button.click.failure', {
        paymentId: payment.id,
        billIds,
        chosenReason: selectedReason,
      });
      capture(new Error('failure in creating a refund'), {
        paymentId: payment.id,
        billIds,
        errorMessage: error?.message,
      });
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'serverErrors.ERR',
      });
    }
  };

  const editReason = () => {
    analytics.trackAction('edit.reason', {
      paymentId: payment.id,
      billIds,
    });
    goBack();
  };

  return (
    <WizardLayout>
      <WizardLayout.TopBar onBackClick={goBack} progressBarValue={100} onCancelClick={cancelFlow} />
      <WizardLayout.Content>
        <WizardLayout.Header>
          <Box textStyle="h1Semi">
            <MIFormattedText label="bills.pay.requestRefund.review.title" />
          </Box>
        </WizardLayout.Header>
        <WizardLayout.Body>
          <Box
            bgColor="white"
            textAlign="left"
            width="full"
            borderRadius="lg"
            boxShadow={600}
            data-testid="review-refund-container"
          >
            <RefundReviewBaseContainer title="bills.pay.requestRefund.review.amount" testId="amount-label">
              <MIMoney size="large" amount={payment.amount} fontWeight="regular" />
            </RefundReviewBaseContainer>
            <Separator />
            <Box>
              <Box margin={6} textStyle="body4Semi" color="grey.700">
                <MIFormattedText label="bills.pay.requestRefund.review.to" />
              </Box>
              <RefundReviewBaseContainerWithIcon
                IconComponent={<DeliveryMethodIcon deliveryMethod={deliveryMethod} />}
                title="bills.pay.requestRefund.review.bankAccount"
                testId="funding-source"
              >
                <FundingSourceDescription fundingSource={fundingSource} />
              </RefundReviewBaseContainerWithIcon>
              <Separator />
              <RefundReviewBaseContainerWithIcon
                title="bills.pay.requestRefund.review.eta"
                subtitle="bills.pay.requestRefund.review.etaSubtitle"
                icon={IconNames.calendarMove}
              >
                <MIFormattedDate date={refundDueDate} />
              </RefundReviewBaseContainerWithIcon>
            </Box>
            <Separator />
            <RefundReviewBaseContainer
              title="bills.pay.requestRefund.review.reason"
              textStyle="body2"
              editText={editReason}
              testId="reason-label"
            >
              <MIFormattedText label={`bills.pay.requestRefund.reason.reasons.${selectedReason}`} />
            </RefundReviewBaseContainer>
          </Box>
        </WizardLayout.Body>
        <Button
          label="bills.pay.requestRefund.review.refundButton"
          size={ButtonSizes.lg}
          onClick={onSubmit}
          isLoading={loading}
        />
      </WizardLayout.Content>
    </WizardLayout>
  );
};
