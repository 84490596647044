import { MICardField } from 'src/components/common/MICard';
import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { UserSummary } from 'src/modules/users/components/UserSummary';
import { ApprovalDetailType } from 'src/pages/bill/components/BillPaymentsActivity/components/PaymentApprovalLogs/types';
import { getContainerStyle } from './utils';

type Props = {
  testId?: string;
  actors: ApprovalDetailType;
  title: string;
  reasonLabel?: string;
  underline: boolean;
};

const ApprovalLog = ({ actors, title, underline, reasonLabel, testId }: Props) => (
  <Box data-testid={testId} mb={5} __css={getContainerStyle(underline)}>
    <Box textStyle="caption1Uppercase" color="grey.700" mb={5} mt={0}>
      <MIFormattedText label={title} />
    </Box>
    {actors.map((actor) => (
      <Box mb={5} key={actor.user.id}>
        <UserSummary
          user={actor.user}
          userRole={actor.role}
          details={
            <MIFormattedText
              label="bills.form.paymentActivity.logs.actionDate"
              values={{
                date: <MIFormattedDateTime date={actor.actionDate} />,
              }}
            />
          }
        />
        {reasonLabel && <MICardField label={reasonLabel}>{actor.reason}</MICardField>}
      </Box>
    ))}
  </Box>
);

export default ApprovalLog;
