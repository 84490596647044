import toString from 'lodash/toString';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from './createApiCallSlice';
import { removeIdsFromState } from './listsActions';
import { batchHashFunc } from './restBatchUpdateSlice';
import { RestfulSliceParams, RestfulState } from './types';

export function batchDeleteSliceReducer(actionName, hashFunc) {
  return {
    [ON_REQUEST](state, action) {
      const key = hashFunc(action.payload.ids);
      state[actionName][key] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const { ids } = action.payload;
      const key = hashFunc(ids);
      delete state[actionName][key];
      removeIdsFromState(state, ids.map(toString));
    },
    [ON_FAILURE](state, action) {
      const key = hashFunc(action.meta.identifier.ids);
      state[actionName][key] = {
        loading: false,
        error: action.error,
      };
    },
  };
}

export type BatchDeleteActionPayload = {
  orgId: number;
  ids: string[];
};

export type BatchDeleteActionResponse = {
  ids: string[];
};

type BatchDeleteRestfulSliceParams<T> = RestfulSliceParams<T> & {
  api: (params: BatchDeleteActionPayload) => Promise<BatchDeleteActionResponse>;
  hashFunc?: (ids: string[]) => string;
};

export function createBatchDeleteSlice<T>({
  storeName,
  api,
  sagas,
  actionName = 'batchDelete',
  hashFunc = batchHashFunc,
}: BatchDeleteRestfulSliceParams<T>) {
  return createApiCallSlice<BatchDeleteActionPayload, Partial<RestfulState<T>>, BatchDeleteActionResponse>({
    name: generateSliceName(storeName, actionName),
    initialState: {
      [actionName]: {},
    },
    api,
    reducers: batchDeleteSliceReducer(actionName, hashFunc),
    selectors: {
      status: (ids) => (state) => state[storeName][actionName]?.[hashFunc(ids)],
    },
    sagas,
  });
}
