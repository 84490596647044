import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { useDrawerFooter } from 'src/components/shared/drawers/MSNPortalAndGetPaid/useDrawerFooter';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { HStack, VStack } from 'src/core/ds/stack';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { InvoiceType } from 'src/modules/invoices/types';
import useCardActionsAndState from 'src/pages/get-pro/components/cards/hooks/useCardActionsAndState';
import { InvoiceInfo } from 'src/pages/get-pro/components/cards/payment/InvoiceInfo';
import { PaymentInfo } from 'src/pages/get-pro/components/cards/payment/PaymentInfo';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { analytics } from 'src/services/analytics';
import { GetProTabs, InvoiceStatus } from 'src/utils/consts';

const CLOSE_ICON_SIZE = IconSize.lg;

type Props = {
  invoice: InvoiceType;
  onClose: () => void;
  eventPage?: string;
};

export const MSNMobileDrawer = ({ invoice, onClose, eventPage }: Props) => {
  const tab = invoice.status === InvoiceStatus.PAID ? GetProTabs.PAID : GetProTabs.IN_PROGRESS;

  const {
    deleteFile,
    onEditToggle,
    markAsUnpaid,
    openPdfPreview,
    cancelPending,
    vendorHandle,
    customers,
    uploadFile,
    fileUrls,
    uploading,
    invoiceMV,
    loadingFileUrls,
    loading,
    inEditMode,
    isMarkedAsPaid,
  } = useCardActionsAndState({ invoice, tab });

  const { listParams } = useGetProListParams();

  const actions = useStoreActions(MAP_STORE_BY_TAB[tab]);

  useEffect(() => {
    if (eventPage) {
      analytics.track(eventPage, 'sidebar-expose', { paymentId: invoice.id, paymentStatus: invoice.status });
    }
    // useEffect should be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = async () => {
    await actions.proDelete({
      orgId: listParams.orgId,
      id: invoice?.id,
    });
    onClose();
  };

  const handleMarkInvoiceAsUnpaid = async () => {
    onClose();
    await markAsUnpaid();
  };

  const { onAction, actionLabel, shouldShowDrawerFooter } = useDrawerFooter({
    invoice,
    tab,
    eventPage,
    remove: handleRemove,
    cancelPending,
  });

  return (
    <>
      <HStack justifyContent="space-between" p={4} borderBottom="1px" borderColor="grey.400" h="4.8rem">
        <Icon name={IconNames.shevronLeft} size={CLOSE_ICON_SIZE} onClick={onClose} />
        <VStack minWidth={0} spacing={0} flex={1}>
          <Box isTruncated textStyle="body2Semi" maxWidth="full">
            {invoice.customer?.contactName}
          </Box>
          {invoice.invoiceNumber && (
            <Box isTruncated textStyle="body3" color="grey.700" maxWidth="full">
              <MIFormattedText label="getPaid.view.header.subtitle" values={{ invoiceNumber: invoice.invoiceNumber }} />
            </Box>
          )}
        </VStack>
        <Box visibility="hidden" w={CLOSE_ICON_SIZE} />
      </HStack>
      <Box overflow="auto" bg="grey.300" h="100%">
        <Box backgroundColor="white" borderRadius="lg" m={2} border="1px" borderColor="grey.400">
          <PaymentInfo
            isMSNPortal
            invoice={invoice}
            invoiceMV={invoiceMV}
            inEditMode={inEditMode}
            vendorHandle={vendorHandle}
            isMarkedAsPaid={isMarkedAsPaid}
            remove={handleRemove}
            onMarkInvoiceAsPaid={handleMarkInvoiceAsUnpaid}
            uploading={uploading}
            onEditToggle={onEditToggle}
          />
        </Box>
        <Box backgroundColor="white" borderRadius="lg" m={2} border="1px" borderColor="grey.400">
          <InvoiceInfo
            invoice={invoice}
            invoiceMV={invoiceMV}
            inEditMode={inEditMode}
            uploading={uploading}
            loading={loading}
            onEditToggle={onEditToggle}
            remove={handleRemove}
            onMarkInvoiceAsPaid={handleMarkInvoiceAsUnpaid}
            loadingFileUrls={loadingFileUrls}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            customers={customers}
            fileUrls={fileUrls}
            openPdfPreview={openPdfPreview}
          />
        </Box>
      </Box>
      {shouldShowDrawerFooter && (
        <Box p={4} borderTop="1px" borderColor="grey.400" h="4.8rem">
          <Button w="full" isLoading={loading} variant={ButtonVariants.error} onClick={onAction} label={actionLabel} />
        </Box>
      )}
    </>
  );
};
