import { EmptyList } from 'src/components/list/EmptyList';
import createRequestImage from 'src/images/general/request-empty-2-state-icon.svg';

export const EmptyState = () => (
  <EmptyList
    showVerifyMdIndicator
    logo={createRequestImage}
    textTitle="incomingPayments.emptyState.title"
    text="incomingPayments.emptyState.description"
  />
);
