import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { useConnectAccountingPlatform } from 'src/pages/settings/hooks/useConnectAccountingPlatform';
import { analytics } from 'src/services/analytics';
import { AccountingSoftware } from 'src/utils/consts';

export const useConnectSSOAccountingPlatform = (
  accountingSoftwareType: AccountingSoftware,
  eventPage: string,
  redirect?: string
) => {
  const accountPlatform = useSelector(accountingPlatformsStore.selectors.getByName(accountingSoftwareType));
  const [connectAccountingPlatform] = useConnectAccountingPlatform({ eventPage, redirect });

  const onConnectError = () => {
    analytics.track(eventPage, 'connect-error', {
      type: accountingSoftwareType,
    });
  };

  const connect = useCallback(
    async (overideOrgId?: number) => {
      try {
        let authLink = accountPlatform?.authLink;

        if (!authLink) {
          const response = await connectAccountingPlatform(accountingSoftwareType, accountPlatform.id, overideOrgId);
          authLink = response?.payload?.accountingPlatform?.authLink;
        }

        if (authLink !== null) {
          window.location.href = authLink;
        } else {
          onConnectError();
        }
      } catch (ex) {
        onConnectError();
      }
    },
    [accountPlatform]
  );

  return [connect];
};
