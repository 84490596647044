import { MobileAppPromotionPageContainer } from 'src/pages/mobile-app/mobileAppPromotionPageContainer';
import { onboardingLocations } from 'src/pages/onboarding/locations';

export const mobileAppRoutes = [
  {
    path: onboardingLocations.mobileApp.promotion,
    exact: true,
    component: () => <MobileAppPromotionPageContainer />,
  },
];
