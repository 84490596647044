import { UpsellType } from 'src/modules/payments/api-types';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';

export const ERR_ALREADY_EXPEDITED_CODE_P2FACH = 'P2FACH5';
export const ERR_ALREADY_EXPEDITED_CODE_P2RTP = 'P2RTP5';

const UpgradePaymentByPayeeTypeToErrorMessageMap = {
  [UpsellType.PUSH_TO_FAST_ACH]: {
    [ERR_ALREADY_EXPEDITED_CODE_P2FACH]: 'vendors.upgradePayment.toast.errorAlreadyExpeditedFast',
    default: 'vendors.upgradePayment.toast.errorFast',
  },
  [UpsellType.PUSH_TO_RTP]: {
    [ERR_ALREADY_EXPEDITED_CODE_P2RTP]: 'vendors.upgradePayment.toast.errorAlreadyExpeditedRtp',
    default: 'vendors.upgradePayment.toast.errorRtp',
  },
};

type Params = {
  error: any;
  upsellType: UpsellType;
};

export const handleUpsellConvertError = ({ error, upsellType }: Params) => {
  const UpgradeTypeMessages = UpgradePaymentByPayeeTypeToErrorMessageMap[upsellType];
  const message = UpgradeTypeMessages[error?.code] || UpgradeTypeMessages.default;

  pushNotification({
    type: NotificationVariant.ERROR,
    msg: message,
    testId: 'vendors.upgradePayment.toast.error',
  });
};
