import * as React from 'react';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { ButtonsDirections, DialogType, DialogVariants } from 'src/utils/consts';

export type CommonDialogProps = {
  title: string;
  description?: string;
  textValues?: Record<string, any>;
  confirmText?: string;
  cancelText?: string;
  variant?: DialogVariants;
  dismiss: () => any;
  confirm: () => Promise<any>;
  showCancel?: boolean;
  hideIcon?: boolean;
  maxWidth?: string;
  minHeight?: string;
  fullWidth?: boolean;
  onOkDisabled?: boolean;
  children?: React.ReactNode;
  buttonsDirection?: ButtonsDirections;
  close?: () => any;
  hideClose?: boolean;
};
const CommonDialog = ({
  title,
  description,
  textValues,
  variant,
  confirm,
  confirmText,
  cancelText,
  dismiss,
  hideIcon,
  showCancel,
  maxWidth,
  minHeight,
  fullWidth,
  children,
  buttonsDirection,
  onOkDisabled,
  hideClose,
}: CommonDialogProps) => (
  <Dialog
    type={DialogType.CONFIRM}
    variant={variant || DialogVariants.ERROR}
    title={title}
    titleValues={textValues}
    subtitle={description}
    subtitleValues={textValues}
    okButtonText={confirmText}
    cancelButtonText={cancelText}
    onOkAction={() => confirm().then(dismiss)}
    onOkDisabled={onOkDisabled}
    onCancelAction={dismiss}
    hideIcon={hideIcon}
    showCancel={showCancel}
    maxWidth={maxWidth}
    minHeight={minHeight}
    fullWidth={fullWidth}
    buttonsDirection={buttonsDirection}
    hideClose={hideClose}
  >
    {children}
  </Dialog>
);

export { CommonDialog };
