import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { analytics } from 'src/services/analytics';
import { CopyLinkSize } from 'src/utils/consts';

type Props = {
  link: string;
  clipboardText: string;
  size?: CopyLinkSize;
  onCopy?: () => void;
};

export const CopyEmailLink = ({ link, clipboardText, size, onCopy }: Props) => {
  const onCopyEmail = () => {
    analytics.trackAction('copy-email-link');
    onCopy && onCopy();
  };

  const onGoToLink = () => {
    analytics.trackAction('copy-email-link-action');
  };

  return (
    <Flex
      direction="row"
      bgColor={size === CopyLinkSize.NORMAL ? 'white' : 'grey.300'}
      borderRadius="lg"
      justify="space-between"
      align="center"
      pl={size === CopyLinkSize.NORMAL ? 0 : 4}
      gap={2}
    >
      <Link
        href={link}
        onClick={onGoToLink}
        textStyle={{ base: 'body1', sm: size === CopyLinkSize.NORMAL ? 'body2' : 'h2' }}
        color="primary.500"
        mt={2}
        textOverflow="ellipsis"
        textDecor="none"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {clipboardText}
      </Link>
      <Button
        onClick={onCopyEmail}
        variant={ButtonVariants.tertiary}
        label="general.copy"
        size={size === CopyLinkSize.BIG ? ButtonSizes.lg : ButtonSizes.sm}
      />
    </Flex>
  );
};
