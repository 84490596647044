import { generatePath, Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { billLocations } from 'src/pages/bill/locations';
import { companiesLocations } from 'src/pages/companies/locations';
import AddFundingSource from 'src/pages/funding-sources/components/AddFundingSource';
import { analytics } from 'src/services/analytics';
import locations from 'src/utils/locations';
import { AcceptInvitationSuccess } from './AcceptInvitationSuccess';
import { ChangePasswordPage } from './ChangePasswordPage';
import { PAGE_EVENT } from './consts';
import ChangeEmailPageContainer from './email-verification/ChangeEmailPageContainer';
import { authLocations } from './locations';
import { GoogleLoginSuccessRedirect, LoginTokenRedirect } from './login';
import { AuthorizeIntuitSuccessPage } from './quickbooks/AuthorizeIntuitSuccessPage';
import AuthorizeQuickbooksSuccessPage from './quickbooks/AuthorizeQuickbooksSuccessPage';
import { IntuitDisconnect } from './quickbooks/IntuitDisconnect';
import QuickbooksLaunchPageContainer from './quickbooks/QuickbooksLaunchPageContainer';
import { GoogleRegisterSuccessRedirect } from './register';
import { RegisterFromInvitationAccountantType } from './RegisterFromInvitationAccountantType';
import { RegisterSetAmount } from './RegisterSetAmount';
import { RegistrationFromInvitationDob } from './RegistrationFromInvitationDob';
import { RegistrationFromInvitationSuccess } from './RegistrationFromInvitationSuccess';
import { RegistrationInvalidInvitation } from './RegistrationInvalidInvitation';
import { AddProfilePictureContainer } from './route-containers/AddProfilePictureContainer';
import { RegisterFromInvitationContainer } from './route-containers/RegisterFromInvitationContainer';

export const AuthAgnosticRouter = () => (
  <Switch>
    <SmartRoute path={authLocations.googleLoginSuccess} exact component={GoogleLoginSuccessRedirect} />
    <SmartRoute path={authLocations.register.authorizeGoogleSuccess} exact component={GoogleRegisterSuccessRedirect} />
    <SmartRoute
      path={authLocations.changePassword}
      exact
      component={(cProps) => <ChangePasswordPage token={cProps.match.params.token} />}
    />
    <SmartRoute path={authLocations.loginWithToken} exact>
      <LoginTokenRedirect />
    </SmartRoute>
    <SmartRoute path={authLocations.register.fromInvitation} exact component={RegisterFromInvitationContainer} />
    <SmartRoute path={authLocations.register.addProfilePicture} exact component={AddProfilePictureContainer} />
    <SmartRoute path={authLocations.register.setAccountantAmount} exact>
      {(cProps) => (
        <RegisterSetAmount
          onNext={(hasFundingSource) => {
            if (!hasFundingSource) {
              return cProps.history.push(authLocations.register.fundingSource);
            }

            return cProps.history.push(authLocations.register.fromInvitationSuccess);
          }}
        />
      )}
    </SmartRoute>
    <SmartRoute path={authLocations.register.fundingSource} exact>
      <AddFundingSource
        redirectUrl={authLocations.register.fromInvitationSuccess}
        relativeStep={2 / 3}
        exitUrl={authLocations.register.fundingSource}
      />
    </SmartRoute>
    <SmartRoute path={authLocations.register.fromInvitationDob} exact>
      {(cProps) => (
        <RegistrationFromInvitationDob
          onNext={() => {
            analytics.track(PAGE_EVENT, 'register-from-invitation-dob');
            cProps.history.push(authLocations.register.fromInvitationSuccess);
          }}
        />
      )}
    </SmartRoute>
    <SmartRoute path={authLocations.register.fromInvitationSuccess} exact>
      {(cProps) => (
        <RegistrationFromInvitationSuccess
          onNext={(orgId) => {
            analytics.track(PAGE_EVENT, 'register-from-invitation-start');
            cProps.history.push(locations.MainApp.dashboard.url({ orgId }));
          }}
        />
      )}
    </SmartRoute>
    <SmartRoute path={authLocations.register.acceptInvitationSuccess} exact>
      {(cProps) => (
        <AcceptInvitationSuccess
          onNext={(orgId, isAccountant) => {
            analytics.track(PAGE_EVENT, 'register-from-invitation-start');

            return isAccountant
              ? cProps.history.push(generatePath(companiesLocations.index, { orgId }))
              : cProps.history.push(generatePath(billLocations.index, { orgId }));
          }}
        />
      )}
    </SmartRoute>
    <SmartRoute
      path={authLocations.register.chooseAccountantType}
      exact
      component={RegisterFromInvitationAccountantType}
    />
    <SmartRoute path={authLocations.register.invalidInvitation} exact>
      <RegistrationInvalidInvitation />
    </SmartRoute>
    <SmartRoute path={authLocations.intuit.disconnect} exact>
      <IntuitDisconnect />
    </SmartRoute>
    <SmartRoute
      path={authLocations.intuit.launch}
      exact
      component={(cProps) => <QuickbooksLaunchPageContainer realmId={cProps.match.params.realmId} />}
    />
    <SmartRoute path={authLocations.intuit.launchNoRealmId} exact component={() => <QuickbooksLaunchPageContainer />} />
    <SmartRoute
      path={authLocations.register.changeEmail}
      exact
      component={(cProps) => <ChangeEmailPageContainer {...cProps} />}
    />
    <SmartRoute
      path={authLocations.register.authorizeIntuitSuccess}
      exact
      component={(cProps) => <AuthorizeIntuitSuccessPage {...cProps} />}
    />
    <SmartRoute path={authLocations.register.authorizeQuickbooksSuccess} exact>
      <AuthorizeQuickbooksSuccessPage />
    </SmartRoute>
  </Switch>
);
