import { Box } from '@chakra-ui/layout';
import { ReactNode } from 'react';
import { BatchUploadReviewPage } from 'src/components/common/BatchUpload/BatchUploadReviewPage';
import { MIDataTable } from 'src/components/common/MIDataTable';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { ContactsTab, NotificationCardTypes } from 'src/utils/consts';
import { headers } from '../constants';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit?: () => void;
  contacts: Record<string, ReactNode>[];
  contactType: ContactsTab;
  isLoading: boolean;
  invalidRows?: number;
  isNextDisabled?: boolean;
  hideLayout?: boolean;
};

const BatchUploadContactsReviewPage = ({
  onNext,
  onPrev,
  onExit,
  contactType,
  contacts,
  isLoading,
  invalidRows,
  isNextDisabled,
  hideLayout,
}: Props) => (
  <BatchUploadReviewPage
    title={`contacts.batch.review.${contactType}.title`}
    label="contacts.batch.review.confirmButton"
    onNext={onNext}
    onPrev={onPrev}
    onExit={onExit}
    isLoading={isLoading}
    isNextDisabled={isNextDisabled}
    hideLayout={hideLayout}
  >
    {invalidRows && invalidRows > 0 ? (
      <Box pb={10}>
        <MINotificationCard
          title={{ label: 'contacts.batch.review.vendors.missingNotification.title' }}
          subtitle={{ label: 'contacts.batch.review.vendors.missingNotification.subtitle', values: { invalidRows } }}
          type={NotificationCardTypes.INFO}
        />
      </Box>
    ) : null}
    <MIDataTable data={contacts} headers={headers} />
  </BatchUploadReviewPage>
);

export default BatchUploadContactsReviewPage;
