import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { Icon, IconNames } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';
import { RemoveFromListButton } from 'src/pages/contacts/list/components/BatchSettings/RemoveFromListButton';
import { EmailValidationType } from 'src/pages/contacts/list/components/BatchSettings/useBatchChangeVendor';
import { DeliveryType } from 'src/utils/consts';
import { handleInputChange } from 'src/utils/input';
import { DeliveryMethodType } from 'src/utils/types';
import { BatchContactsScreenMode } from './consts';

type Props = {
  mode: BatchContactsScreenMode;
  vendorId: number;
  companyName: string;
  contactEmail: string;
  changedContactEmail?: string;
  withSeparator: boolean;
  deliveryMethod?: DeliveryMethodType;
  isEligibleVendor: boolean;
  isDirectoryVendor: boolean;
  validationError?: EmailValidationType;
  removeFromList: (vendorId: number) => void;
  onEmailChange: (changeField: { id: number; value: string }) => void;
};

export const BatchListItem = ({
  mode,
  vendorId,
  companyName,
  contactEmail,
  changedContactEmail,
  withSeparator,
  deliveryMethod,
  isEligibleVendor,
  isDirectoryVendor,
  validationError,
  removeFromList,
  onEmailChange,
}: Props) => {
  const isACHDeliveryMethod = deliveryMethod?.deliveryType === DeliveryType.ACH;
  const isVirtualDeliveryMethod = deliveryMethod?.deliveryType === DeliveryType.VIRTUAL;
  const isUnilateralMode = mode === BatchContactsScreenMode.UNILATERAL;
  const isViewMode = mode === BatchContactsScreenMode.VIEW;
  const isDisabled = isUnilateralMode && !isEligibleVendor;
  const isEmailDoesNotExist = isEmpty(contactEmail);
  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const renderDeliveryMethodInfo = () => {
    if (isEligibleVendor) {
      if (deliveryMethod && !isVirtualDeliveryMethod) {
        return isACHDeliveryMethod ? (
          <MIFormattedText label="vendors.batchSettings.deliveryMethod.ach" />
        ) : (
          <MIFormattedText label="vendors.batchSettings.deliveryMethod.check" />
        );
      }

      return (
        <Box color="grey.400">
          <MIFormattedText label="vendors.batchSettings.deliveryMethod.notExist" />
        </Box>
      );
    }

    return <MIFormattedText label="vendors.batchSettings.deliveryMethod.rpps" />;
  };

  const calculateIndicationColor = () => {
    if (isEligibleVendor && isEmailDoesNotExist && changedContactEmail) {
      return 'green.500';
    }

    if (isEligibleVendor && !isEmailDoesNotExist) {
      return 'green.500';
    }

    return 'grey.400';
  };

  return (
    <Flex
      data-testid="vendor-batch-list-item"
      align={isUnilateralMode && isEmailDoesNotExist ? 'flex-start' : 'center'}
      overflow="hidden"
      flexGrow={1}
      pt={5}
      pb={5}
      pl={isUnilateralMode ? 0 : 8}
      justify="space-between"
      borderTop={withSeparator ? '.1rem solid rgba(231,231,231,1)' : 'none'}
      _hover={{ cursor: 'pointer', bg: 'grey.200' }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Tooltip
        key="vendors.batchSettings.notEditableTooltip"
        placement="top"
        label={<MIFormattedText label="vendors.batchSettings.notEditableTooltip" />}
        isDisabled={isViewMode || isEligibleVendor}
      >
        <Flex align={isEmailDoesNotExist && isEligibleVendor && isUnilateralMode ? 'flex-start' : 'center'} w="85%">
          <Flex align="center">
            {isUnilateralMode && (
              <Box mr={3} ml={3} h="0.8rem" w="0.8rem" borderRadius="full" bgColor={calculateIndicationColor()} />
            )}
            <Box pos="relative">
              <Avatar size={Size.lg} name={companyName} textColor="white" bgColor={isDisabled ? 'grey.400' : ''} />
              {isDirectoryVendor ? (
                <Icon
                  width={3}
                  height={3}
                  borderRadius="full"
                  top="2.8rem"
                  left="2.8rem"
                  position="absolute"
                  border="2px"
                  borderColor="white"
                  name={IconNames.verifiedSolid}
                  color="primary.500"
                  data-testid="directory-vendor-sign"
                  background="white"
                />
              ) : null}
            </Box>
          </Flex>
          <Flex w="100%" direction="column" ml={4} textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden">
            <Box textStyle="body4Uppercase" color={isDisabled ? 'grey.500' : 'black'}>
              {companyName}
            </Box>
            {isUnilateralMode && (
              <>
                <Box textStyle="body4" color={isEligibleVendor ? 'grey.700' : 'grey.400'}>
                  {renderDeliveryMethodInfo()}
                </Box>
                {isEmailDoesNotExist && isEligibleVendor && (
                  <Box pt={6}>
                    <TextField
                      id={vendorId.toString()}
                      size="sm"
                      label="vendors.batchSettings.form.email.label"
                      placeholder="vendors.batchSettings.form.email.placeholder"
                      value={changedContactEmail}
                      autoFocus
                      isRequired
                      type="email"
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        handleInputChange({ event, onChange: onEmailChange, idToNumber: true })
                      }
                      errorMessage={validationError?.contactEmail}
                    />
                  </Box>
                )}
              </>
            )}
          </Flex>
        </Flex>
      </Tooltip>
      {hovered && (
        <Box mt={isUnilateralMode && isEmailDoesNotExist ? 1 : 0}>
          <RemoveFromListButton vendorId={vendorId} removeFromList={removeFromList} />
        </Box>
      )}
    </Flex>
  );
};
