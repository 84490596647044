import { useEffect } from 'react';
import { analytics } from 'src/services/analytics';

export const useExtraAnalyticsProps = (tagName: string, properties: Record<string, unknown>, deps?: number) => {
  useEffect(() => {
    analytics.setExtraProperties(tagName, properties);

    return () => analytics.removeExtraProperties(tagName);
  }, [deps]);
};
