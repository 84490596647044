import React from 'react';
import { generatePath, useLocation } from 'react-router';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useDisclosure } from 'src/core/ds/hooks';
import Tooltip from 'src/core/ds/tooltip';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { deserializePaymentId } from 'src/utils/bills';
import { useBatchSelectionFilter } from '../hooks/useBatchSelectionFilter';
import { useDefaultFundingSourceId } from '../hooks/useDefaultFundingSourceId';
import { Footer } from './Footer';
import { InternationalBillsFilteredModal } from './InternationalBillsFilteredModal';
import { MoreBatchActions } from './MoreBatchActions';

export const BillsBatchActionsFooter = () => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useOrgId();
  const {
    getDefaultFundingSourceId,
    defaultFundingSourceId,
    isLoading: defaultFSLoading,
  } = useDefaultFundingSourceId();

  const {
    isFiltered: isInternationalBillsFiltered,
    isEmpty: isInternationalBillsEmpty,
    filteredIds,
    billsCount,
  } = useBatchSelectionFilter();

  const {
    isOpen: isInternationalBillsFilteredModalOpen,
    onOpen: openInternationalBillsFilteredModal,
    onClose: closeInternationalBillsFilteredModal,
  } = useDisclosure();

  const [historyPush] = useHistoryWithOrgId();
  const location = useLocation();
  const showInternationalBillsTooltip = isInternationalBillsEmpty && isInternationalBillsFiltered;

  const onRender = getDefaultFundingSourceId;

  const linkFundingSource = () => {
    const deserializedIds = filteredIds.map((id) => deserializePaymentId(id).billId);
    actions.settings.update({
      exitLocation: location,
      billIds: deserializedIds,
    });
    const preservedState = {
      redirectUrl: generatePath(batchPaymentsLocations.base, { orgId }),
      exitUrl: location.pathname + location.search,
    };
    historyPush({
      path: onboardingLocations.fundingSources.index,
      state: {
        ...preservedState,
        isNewPlaidMDRecoveryFlow: true,
      },
    });
  };

  const scheduleBatchPayment = () => {
    const deserializedIds = filteredIds.map((id) => deserializePaymentId(id.toString()).billId);
    actions.settings.startBatchPaymentsFlow({
      orgId,
      billIds: deserializedIds,
      exitLocation: location,
    });
  };

  const startBatchFlow = () => {
    if (isInternationalBillsFiltered && !isInternationalBillsFilteredModalOpen) {
      openInternationalBillsFilteredModal();
    } else if (defaultFundingSourceId) {
      scheduleBatchPayment();
    } else {
      linkFundingSource();
    }
  };

  return (
    <Box ref={onRender}>
      <Footer>
        <InternationalBillsFilteredModal
          isOpen={isInternationalBillsFilteredModalOpen}
          onClose={closeInternationalBillsFilteredModal}
          onContinue={startBatchFlow}
        />
        <MoreBatchActions />
        <Tooltip
          data-testid="submit-batch-disabled-tooltip"
          placement="top"
          maxWidth="max"
          isDisabled={!showInternationalBillsTooltip}
          shouldWrapChildren
          label={
            <Box textAlign="start" px={2} py={1}>
              <MIFormattedText label="regularBatchPayments.BillsGallery.Footer.internationalDisabledTooltip" />
            </Box>
          }
        >
          <Button
            label="regularBatchPayments.BillsGallery.Footer.submit"
            values={{ billsCount }}
            variant={ButtonVariants.primary}
            size={ButtonSizes.md}
            onClick={startBatchFlow}
            isLoading={defaultFSLoading}
            isDisabled={isInternationalBillsEmpty}
          />
        </Tooltip>
      </Footer>
    </Box>
  );
};
