import { createFetchSlice } from 'src/helpers/redux/restFetchSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from '../constants';

const proInvoicesFetchSlice = createFetchSlice({
  storeName: name,
  actionName: 'proFetch',
  api: getProApi.invoices.fetch,
});

export default proInvoicesFetchSlice;
