import { CSSObject } from 'src/core/ds';

export const NavIconContainerStyle: CSSObject = {
  a: {
    textDecoration: 'none',
    color: 'grey.600',
    '&:hover': {
      color: 'black',
    },
  },
};
