import { RadioSelectOptionContainer } from 'src/components/common/SelectMethods/components/RadioSelectMethodOption';
import { Skeleton } from 'src/core/ds/skeleton';
import { HStack, VStack } from 'src/core/ds/stack';

type Props = {
  id: string;
};

export const LoadingSelectMethod = ({ id }: Props) => (
  <RadioSelectOptionContainer data-testid={`loading-radio-select-method-${id}`} isLoading>
    <HStack spacing={4} alignItems="center">
      <Skeleton variant="medium" width={8} height={8} />
      <VStack spacing={4} alignItems="flex-start">
        <Skeleton variant="medium" width="16rem" height={4} />
        <Skeleton variant="light" width="12rem" height={4} />
      </VStack>
    </HStack>
  </RadioSelectOptionContainer>
);
