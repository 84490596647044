import { createSlice as toolkitCreateSlice, CreateSliceOptions, SliceCaseReducers } from '@reduxjs/toolkit';
import mapValues from 'lodash/mapValues';
import { Dispatch } from 'redux';

// this function created to use regular createSlice of redux toolkit with
// integration with our slices implementation that allows to use useStoreActions and useSelector
export const createSlice = <State, CaseReducers extends SliceCaseReducers<State>, Name extends string = string>(
  options: CreateSliceOptions<State, CaseReducers, Name> & {
    selectors?: { [key: string]: (state: any, props?: any) => any };
  }
) => {
  const { initialState, selectors } = options;
  const slice = toolkitCreateSlice<State, CaseReducers, Name>(options);

  return {
    ...slice,
    initialState,
    dispatchers: mapValues(slice.actions, (action) => (dispatch: Dispatch) => (params) =>
      action && dispatch(action(params))
    ),
    selectors,
  };
};
