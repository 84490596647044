import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useStaleAndLoad(store, sliceName, params?) {
  const status = useSelector(store.selectors[sliceName].status(params));
  const value = useSelector((state) => store.selectors[sliceName].value(state, params));
  const dispatch = useDispatch();
  const load = useMemo(() => store.dispatchers[sliceName](dispatch), [store, sliceName, dispatch]);
  useEffect(() => {
    load(params);
  }, [load, params]);

  return [value, status];
}
