import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { LOAD_FUNDING_SOURCES_SUCCESS } from 'src/redux/user/actionTypes';
import { fundingSourcesApi } from './api';
import { name } from './consts';

export const fundingSourcesStore = createRestfulSlice({
  name,
  api: fundingSourcesApi,
  selectors: {
    all: (state) => Object.values(state[name]?.byId || {}),
    byId: (id) => (state) => state[name]?.byId[id],
  },
  extraReducers: {
    [LOAD_FUNDING_SOURCES_SUCCESS]: (state, { fundingSources }) => {
      state.byId = fundingSources.reduce((acc, fs) => ({ ...acc, [fs.id]: fs }), {});
    },
  },
});
