import * as React from 'react';
import { Button, ButtonVariants } from 'src/core/ds/button';
import CardEditButtons from '../CardEditButtons';

type Props = {
  inEditMode: boolean;
  loading: boolean;
  uploading: boolean;
  notValid: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  onClose: () => void;
  onSend: () => void;
};

const InvoiceCardActions: React.FC<Props> = ({
  inEditMode,
  loading,
  uploading,
  notValid,
  onSubmit,
  onCancel,
  onClose,
  onSend,
}) =>
  inEditMode ? (
    <CardEditButtons
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDisabled={loading || uploading || notValid}
      isProcessing={loading}
    />
  ) : (
    <>
      <Button mr={4} variant={ButtonVariants.tertiary} onClick={onClose} label="getPro.cards.actions.close" />

      <Button isLoading={loading} variant={ButtonVariants.success} onClick={onSend} label="getPro.cards.actions.send" />
    </>
  );

export default InvoiceCardActions;
