import { useEffect } from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import usersStore from 'src/modules/users/users-store';

type UseFetchUserOrgsProps = {
  orgId: number;
  userIds: number[];
};
export function useFetchUserOrgs({ userIds, orgId }: UseFetchUserOrgsProps) {
  const userActions = useStoreActions(usersStore);

  // optimization for useEffect, compare strings, not arrays
  const stringifiedUserIds = JSON.stringify(userIds);
  useEffect(() => {
    if (orgId && userIds.length) {
      userActions.allUserOrganizations({
        orgId,
        userIds: JSON.parse(stringifiedUserIds),
      });
    }
  }, [orgId, stringifiedUserIds, userActions, userIds.length]);
}
