import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { SelectItemCard } from 'src/components/common/SelectItemCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box/Box';
import { FeatureFlags, NotificationCardTypes } from 'src/utils/consts';
import { Option } from './SelectVerificationTypePageContainer';

type Props = {
  options: Array<Option>;
  goExit: () => void;
  goPrev: () => void;
  isDeliveryMethod?: boolean;
  showUnverifiedAchLimitNotification: boolean;
};

export const SelectVerificationTypePage = ({
  options,
  goExit,
  goPrev,
  isDeliveryMethod,
  showUnverifiedAchLimitNotification,
}: Props) => {
  const footer = isDeliveryMethod ? <MISecurityDetails eventPage="onboarding-vendor-delivery-method" /> : undefined;
  const [isEnabledGetPaidUnifiedOnboarding] = useFeature<boolean>(FeatureFlags.EnableGetPaidUnifiedOnboarding, false);
  const getProgressBarRelativeStep = () => {
    if (isEnabledGetPaidUnifiedOnboarding && isDeliveryMethod) {
      return 3 / 4;
    }

    return isDeliveryMethod ? 1 : undefined;
  };

  return (
    <StepLayoutPage
      title="flows.addBankAccount.verification.title"
      subtitle="flows.addBankAccount.verification.subtitle"
      goExit={goExit}
      onPrev={goPrev}
      innerSize={50}
      relativeStep={getProgressBarRelativeStep()}
      footer={footer}
      hideHeader
    >
      <Box>
        {showUnverifiedAchLimitNotification && (
          <Box mb={5}>
            <MINotificationCard
              type={NotificationCardTypes.ERROR}
              subtitle={{
                label: 'flows.addBankAccount.verification.limit',
              }}
            />
          </Box>
        )}
        {options.map(({ id, label, description, onClick, recommended }) => (
          <Box mb={4} key={id}>
            <SelectItemCard
              id={id}
              testId={`verification-type-${id}`}
              onClick={onClick}
              label={label}
              description={description}
              recommended={recommended}
            />
          </Box>
        ))}
      </Box>
    </StepLayoutPage>
  );
};
