import { FAST_DELIVERY_EVENT_NAMES } from 'src/pages/vendor/upgrade-payment/consts';
import { EventMappingsTree } from 'src/services/analytics/types';

const FAST_DELIVERY_EVENT_PAGE = 'push-to-fast-delivery-options';

const defaultAnalyticsProps = {
  paymentId: 'paymentId',
  vendorId: 'vendorId',
  vendorEmail: 'vendorEmail',
};

export const vendorUpgradePaymentEventMapping: EventMappingsTree = {
  '/vendors/upgrade-payment/*/select': {
    [FAST_DELIVERY_EVENT_NAMES.OPTIONS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.OPTIONS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        shownOptions: 'shownOptions',
      },
    ],
    'vendors.upgradePayment.landing.cta': [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.SELECT_OPTION,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
    [FAST_DELIVERY_EVENT_NAMES.SELECT_OPTION]: [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.SELECT_OPTION,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
  },
  '/vendors/upgrade-payment/*/push-to-fast-ach': {
    [FAST_DELIVERY_EVENT_NAMES.OPTIONS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.OPTIONS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        shownOptions: 'shownOptions',
      },
    ],
    'vendors.upgradePayment.landing.cta': [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.SELECT_OPTION,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
  },
  '/vendors/upgrade-payment/*/push-to-rtp': {
    [FAST_DELIVERY_EVENT_NAMES.OPTIONS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.OPTIONS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        shownOptions: 'shownOptions',
      },
    ],
    'vendors.upgradePayment.landing.cta': [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.SELECT_OPTION,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
  },
  '/vendors/upgrade-payment/*/success': {
    [FAST_DELIVERY_EVENT_NAMES.SUCCESS_PAGE_VIEW]: [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.SUCCESS_PAGE_VIEW,
      {
        ...defaultAnalyticsProps,
        selectedOption: 'selectedOption',
      },
    ],
    'vendors.upgradePayment.success.cta': [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.JOIN_MELIO,
      {
        ...defaultAnalyticsProps,
        flow: 'flow',
      },
    ],
  },
  '/vendors/upgrade-payment/*/offer-expired': {
    [FAST_DELIVERY_EVENT_NAMES.OFFER_EXPIRED]: [
      FAST_DELIVERY_EVENT_PAGE,
      FAST_DELIVERY_EVENT_NAMES.OFFER_EXPIRED,
      {
        ...defaultAnalyticsProps,
        reason: 'reason',
      },
    ],
  },
};
