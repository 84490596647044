import { ChangeEvent } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { PayWithInfo } from 'src/pages/meliome/guest/components/PayWithInfo';
import { LocalFileProps } from 'src/pages/meliome/guest/hoc/withGuestData';
import { analytics } from 'src/services/analytics';
import { isEnterPressed } from 'src/utils/events';
import { handleInputChange } from 'src/utils/input';
import { FieldType } from 'src/utils/types';
import { FileUpload } from './FileUpload/FileUpload';
import { MelioMeCardVendorInvoiceInfo } from './MelioMeCardVendorInvoiceInfo';

type Props = {
  vendorId: number;
  companyName: string;
  logoUrl: string;
  totalAmount: string;
  invoiceNumber: string;
  invoiceFile?: LocalFileProps;
  validationErrors: Record<string, any>;
  onChange: ({ id, value }: FieldType) => void;
  onInvoiceFileChange: (file?: File) => LocalFileProps;
  onInvoiceFileClear(): void;
  onNext(): void;
  isNextDisabled: boolean;
  showMelioMeCard: boolean;
  goDashboard(): void;
  editDisabled: boolean;
  blockCCMoneyIn: boolean;
  promotionName: string;
  isVendorAbsorbedFee: boolean;
};

const GuestRequestInfoPage = ({
  vendorId,
  companyName,
  logoUrl,
  isNextDisabled,
  goDashboard,
  totalAmount,
  invoiceNumber,
  invoiceFile,
  validationErrors,
  onNext,
  onChange,
  onInvoiceFileChange,
  onInvoiceFileClear,
  showMelioMeCard,
  blockCCMoneyIn,
  editDisabled,
  promotionName,
  isVendorAbsorbedFee,
}: Props) => {
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      onNext();
    }
  };
  const { components, embeddedMode } = useSiteContext();
  const { GuestCardLayout } = components;
  const showNotFoundCard = !vendorId || !showMelioMeCard;
  const showVendorInfo = !!vendorId && showMelioMeCard;
  const eventPage = 'payor';

  if (showNotFoundCard) {
    analytics.track(eventPage, 'vendor-not-found');
  }

  return (
    <Box>
      <GuestCardLayout
        onKeyDown={onKeyPressed}
        goDashboard={goDashboard}
        showNotFoundCard={showNotFoundCard}
        shadow={!embeddedMode}
        blockCCMoneyIn={blockCCMoneyIn}
        promotionName={promotionName}
        companyName={companyName}
      >
        {showVendorInfo && (
          <>
            <MelioMeCardVendorInvoiceInfo
              logoUrl={logoUrl}
              companyName={companyName}
              hint="guests.register.paymentHint"
              totalAmount={totalAmount}
              onChange={onChange}
              validationErrors={validationErrors}
              viewOnly={editDisabled}
            />
            <PrivateDataContainer>
              <TextField
                id="invoiceNumber"
                label="guests.register.invoiceNumber"
                value={invoiceNumber}
                type="text"
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
                errorMessage={validationErrors.invoiceNumber}
                autoComplete="off"
                isDisabled={editDisabled}
                marginBottom="0"
                isRequired
              />
            </PrivateDataContainer>
            <FileUpload file={invoiceFile} onFileChange={onInvoiceFileChange} onFileClear={onInvoiceFileClear} />
            <Button
              onClick={onNext}
              label="guests.register.buttonText"
              values={{ companyName }}
              size={ButtonSizes.lg}
              isFullWidth
            />
            <Flex mt={6}>
              <PayWithInfo isVendorAbsorbedFee={isVendorAbsorbedFee} vendorHasCCPaymentsBlocked={blockCCMoneyIn} />
            </Flex>
            {isNextDisabled && (
              <Box as="p" textStyle="body4" mt={5} opacity="0.5">
                <MIFormattedText label="guests.register.disabledHint" />
                <br />
              </Box>
            )}
          </>
        )}
        {showNotFoundCard && (
          <Box as="p" textStyle="h1Semi" color="primary.500">
            <MIFormattedText label="guests.notFound.title" />
          </Box>
        )}
      </GuestCardLayout>
    </Box>
  );
};

export default GuestRequestInfoPage;
