import { useHistory, useLocation } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';

export const Success = () => {
  const history = useHistory();
  const { search } = useLocation();
  const amount = new URLSearchParams(search).get('amount');

  const handleBackToDashboardClick = () => {
    history.push('/');
  };

  return (
    <WizardLayout>
      <WizardLayout.Content>
        <Flex
          w="full"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginBlockStart={14}
          data-testid="import-clients-qbo-success"
        >
          <Illustration name={IllustrationName.party} size={IllustrationSize.lg} />
          <Box fontWeight="bold" textStyle="display2Semi" marginBlockEnd={4} marginBlockStart={14} textAlign="center">
            <MIFormattedText label="companies.importQboa.success.title" values={{ amount }} />
          </Box>
          <Box textStyle="body2" textAlign="center" marginBottom={10}>
            <MIFormattedText label="companies.importQboa.success.message" values={{ amount }} />
          </Box>
          <Button
            w="full"
            size={ButtonSizes.lg}
            variant={ButtonVariants.primary}
            onClick={handleBackToDashboardClick}
            label="companies.importQboa.success.submit"
          />
        </Flex>
      </WizardLayout.Content>
    </WizardLayout>
  );
};
