import { EventMappingsTreeChild } from 'src/services/analytics/types';

const PAGE_EVENT = 'resend-undeposited-check';

const properties = {
  vendorId: 'vendorId',
  partialBillId: 'partialBillId',
  fundingSourceId: 'fundingSourceId',
  fundingSourceType: 'fundingSourceType',
  originDeliveryId: 'originDeliveryId',
  originDeliveryType: 'originDeliveryType',
};

export const MAPPING: EventMappingsTreeChild = {
  'page.view': [PAGE_EVENT, 'edit-check-type'],
  'selected-check': [
    PAGE_EVENT,
    'selected-check',
    {
      ...properties,
    },
  ],
  'selected-express-check': [
    PAGE_EVENT,
    'selected-express-check',
    {
      ...properties,
    },
  ],
  'set-check-type-continue': [
    PAGE_EVENT,
    'set-check-type-continue',
    {
      ...properties,
    },
  ],
};
