import { addBusinessDays } from 'date-fns';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PaymentInfoContainer } from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box';
import { useOrgId } from 'src/hooks';
import { profileStore } from 'src/modules/profile/profile-store';
import { getPaymentURLInTab } from 'src/pages/bill/components/ViewBill/utils';
import { FailedFinancingPaymentActivity } from 'src/pages/bill/components/ViewBillPaymentActivity/components/FailedFinancingPaymentActivity/FailedFinancingPaymentActivity';
import { ViewBillPaymentActivityRefund } from 'src/pages/bill/components/ViewBillPaymentActivity/components/ViewBillPaymentActivityRefund';
import { RefundReviewBaseContainer } from 'src/pages/bill/pay/components/RequestRefundWizard/components/RefundReviewBaseContainer';
import { getPayFromLabel, getReceiveLabel } from 'src/pages/bill/utils';
import { isBillBelongsToSharedVendor } from 'src/pages/bill/utils/billGetters';
import { isVirtualCardExpired } from 'src/utils/bills';
import { BillStatus, DashboardTabs, DeliveryType, PaymentStatus } from 'src/utils/consts';
import { getFailedPaymentDate, isPaymentFailedRefund, isRefundPaymentFlow, isReturnedCheck } from 'src/utils/payments';
import { AccountType, BillType, DeliveryMethodType, FinancePayment, PaymentType } from 'src/utils/types';
import { InstallmentsFundingType } from '../../pay/components/PayBillPaymentActivity/components/InstallmentsFundingType';
import { RepaymentFundingSourceInfo } from '../../pay/components/PayBillPaymentActivity/components/RepaymentFundingSourceInfo';
import { InstallmentsDeductionDatesInfo } from './components/InstallmentsDeductionDatesInfo';
import { ViewBillPaymentActivityDeductionDateInfo } from './components/ViewBillPaymentActivityDeductionDateInfo';
import { ViewBillPaymentActivityDeliveryDateInfo } from './components/ViewBillPaymentActivityDeliveryDateInfo';
import { ViewBillPaymentActivityDeliveryMethodInfo } from './components/ViewBillPaymentActivityDeliveryMethodInfo';
import { ViewBillPaymentActivityFundingSourceInfo } from './components/ViewBillPaymentActivityFundingSourceInfo';
import { ViewBillPaymentActivityMemoInfo } from './components/ViewBillPaymentActivityMemoInfo';

type Props = {
  bill: BillType;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  fundingSource: AccountType;
};

export enum CardThemes {
  Black,
  Grey,
}

export const ViewBillPaymentActivity = ({ bill, payment, deliveryMethod, fundingSource }: Props) => {
  const orgId = useOrgId();
  const isAdmin = useSelector(profileStore.selectors.isAdmin);
  const isBillPaid = bill.status === BillStatus.PAID;
  const isPaymentFailed = payment.status === PaymentStatus.FAILED;
  const isScheduledPayment = payment.status === PaymentStatus.SCHEDULED;
  const isVirtualDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.VIRTUAL;
  const isDirectPayment = deliveryMethod.deliveryType === DeliveryType.RPPS;
  const receiveLabel = getReceiveLabel(deliveryMethod, isBillPaid, payment);
  const isSharedVendor = isBillBelongsToSharedVendor(bill);
  const { financing, isFinanced } = payment;
  const installments = financing ? financing.installments : [];
  const isFinancing = !!isFinanced;
  const payFromLabel = getPayFromLabel(isBillPaid, isFinancing);

  if (isPaymentFailed) {
    return isFinancing ? (
      <FailedFinancingPaymentActivity
        payment={payment as FinancePayment}
        bill={bill}
        fundingSource={fundingSource}
        deliveryMethod={deliveryMethod}
      />
    ) : (
      <FailedPaymentActivity
        payment={payment}
        fundingSource={fundingSource}
        deliveryMethod={deliveryMethod}
        bill={bill}
      />
    );
  }

  let paidFromContent;

  if (isFinancing) {
    const viewDetailsLink = isBillPaid
      ? { viewDetailsLink: getPaymentURLInTab({ bill, payment, isAdmin, orgId, tabName: DashboardTabs.Scheduled }) }
      : {};

    paidFromContent = (
      <>
        <InstallmentsFundingType installments={installments} isEditDisabled />
        <InstallmentsDeductionDatesInfo
          financing={financing}
          theme={isBillPaid ? CardThemes.Grey : CardThemes.Black}
          showInstallmentsBadges={!isScheduledPayment}
          {...viewDetailsLink}
        />
      </>
    );
  } else {
    paidFromContent = (
      <>
        <ViewBillPaymentActivityFundingSourceInfo fundingSource={fundingSource} payment={payment} bill={bill} />
        <ViewBillPaymentActivityDeductionDateInfo bill={bill} payment={payment} deliveryMethod={deliveryMethod} />
      </>
    );
  }

  return (
    <>
      <BlockTitleContainer data-testid="billpay-view-title-container">
        <MIFormattedText label={payFromLabel} />
      </BlockTitleContainer>
      <MainPaymentActivityContainer data-testid="billpay-view-activity-container">
        {paidFromContent}
        {isSharedVendor && (
          <>
            <ViewBillPaymentActivityDeliveryDateInfo
              bill={bill}
              payment={payment}
              deliveryMethod={deliveryMethod}
              fundingSource={fundingSource}
            />
          </>
        )}
        {isFinancing && <RepaymentFundingSourceInfo bill={bill} fundingSource={fundingSource} />}
      </MainPaymentActivityContainer>

      {!isSharedVendor && (
        <>
          <BlockTitleContainer>
            <MIFormattedText label={receiveLabel} />
          </BlockTitleContainer>
          <MainPaymentActivityContainer>
            {!isVirtualDeliveryMethod && (
              <ViewBillPaymentActivityDeliveryMethodInfo
                deliveryMethod={deliveryMethod}
                bill={bill}
                payment={payment}
              />
            )}
            <ViewBillPaymentActivityDeliveryDateInfo
              bill={bill}
              payment={payment}
              deliveryMethod={deliveryMethod}
              fundingSource={fundingSource}
            />
          </MainPaymentActivityContainer>
        </>
      )}

      {!isDirectPayment && <ViewBillPaymentActivityMemoInfo payment={payment} />}
    </>
  );
};

type FailedPaymentActivityProps = {
  bill: BillType;
  payment: PaymentType;
  fundingSource: AccountType;
  deliveryMethod: DeliveryMethodType;
};

const FailedPaymentActivity = ({ bill, payment, fundingSource, deliveryMethod }: FailedPaymentActivityProps) => {
  const paymentFailedDate = getFailedPaymentDate(payment);
  const isDirectPayment = deliveryMethod.deliveryType === DeliveryType.RPPS;
  const isExpiredVirtualCard = isVirtualCardExpired(payment);
  const isReturnedCheckPayment = isReturnedCheck(payment);
  const isFailedRefund = isPaymentFailedRefund(payment);
  const refundPayment = isRefundPaymentFlow(payment);
  const refundRequestedDate = payment.metadata?.paymentStatusDates?.refundInitiated;
  const refundDueDate = refundRequestedDate ? addBusinessDays(new Date(refundRequestedDate), 5).toISOString() : null;
  const shouldShowMemoToVendor = !isDirectPayment && !refundPayment && !isFailedRefund;

  const payFromLabel = (): string => {
    if (refundPayment) return 'bills.pay.requestRefund.review.to';

    if (isReturnedCheckPayment) return 'viewBillPaymentActivity.deductedTitle';

    return 'viewBillPaymentActivity.payFromTitle';
  };

  const failedLabel = (): string => {
    if (isExpiredVirtualCard) return 'bills.form.paymentActivity.expiredPayment';

    if (isFailedRefund) return 'bills.status.paymentRefundFailed.note';

    return 'bills.form.paymentActivity.failedPayment';
  };

  return (
    <>
      <BlockTitleContainer>
        <MIFormattedText label={payFromLabel()} />
      </BlockTitleContainer>
      <MainPaymentActivityContainer>
        <ViewBillPaymentActivityFundingSourceInfo fundingSource={fundingSource} payment={payment} bill={bill} />
        {refundPayment && (
          <ViewBillPaymentActivityRefund
            payment={payment}
            refundRequestedDate={refundRequestedDate!}
            refundDueDate={refundDueDate!}
          />
        )}
        {isReturnedCheckPayment && (
          <Box mt={6}>
            <BlockTitleContainer>
              <MIFormattedText label="viewBillPaymentActivity.deliveredByTitle" />
            </BlockTitleContainer>
            <MainPaymentActivityContainer>
              <ViewBillPaymentActivityDeliveryMethodInfo
                deliveryMethod={deliveryMethod}
                bill={bill}
                payment={payment}
              />
            </MainPaymentActivityContainer>
          </Box>
        )}
        {paymentFailedDate && (
          <PaymentInfoContainer>
            <PaymentFailedContainer>
              <Box data-testid="payment-failed-note">
                <MIFormattedText
                  label={failedLabel()}
                  values={{
                    date: <MIFormattedDate date={paymentFailedDate} />,
                  }}
                />
              </Box>
              {isFailedRefund && (
                <Box ml={-6} mb={-6} data-testid="payment-refund-reason">
                  <RefundReviewBaseContainer
                    title="bills.pay.requestRefund.review.reason"
                    textStyle="body2"
                    testId="reason-label"
                  >
                    <MIFormattedText
                      label={`bills.pay.requestRefund.reason.reasons.${payment.metadata?.refundReason}`}
                    />
                  </RefundReviewBaseContainer>
                </Box>
              )}
            </PaymentFailedContainer>
          </PaymentInfoContainer>
        )}
      </MainPaymentActivityContainer>
      {shouldShowMemoToVendor && <ViewBillPaymentActivityMemoInfo payment={payment} />}
    </>
  );
};

const MainPaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.2rem;
  ${(props) => props.theme?.components?.PaymentActivity?.MainPaymentActivityContainer}
`;

export const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.subtitle};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.BlockTitleContainer}
`;

const PaymentFailedContainer = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.colors.failure.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 2rem;
`;
