import map from 'lodash/map';
import { all, call, put, select } from 'redux-saga/effects';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import regularBatchPaymentStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { regularBatchUpdateDeliveryOptionsSlice } from 'src/modules/regular-batch-payments/regular-batch-update-delivery-options-slice';
import { BatchItemType } from 'src/modules/regular-batch-payments/types';
import { getOrgId } from 'src/redux/user/selectors';

const DELIVERY_METHOD_SELECTED = '[PAY_BILL_FLOW] SELECT_NEW_DELIVERY_METHOD';
const DELIVERY_METHOD_CREATED = '[DELIVERYMETHODS] CREATE_SUCCESS';
const INTERNATIONAL_DELIVERY_METHOD_CREATED = '[DELIVERYMETHODS] CREATE_INTERNATIONAL_ACCOUNT_SUCCESS';

function* updateRegularBatchPayment({ deliveryMethodId, billToUpdate }) {
  const orgId = yield select(getOrgId);
  const deliveryMethod = yield select(deliveryMethodsStore.selectors.byId(deliveryMethodId));

  yield put(
    regularBatchUpdateDeliveryOptionsSlice.actions({
      orgId,
      ...billToUpdate.payment,
      itemId: billToUpdate.id,
      deductionDate: billToUpdate.payment.scheduledDate,
      deliveryMethodId: deliveryMethod.id,
      deliveryMethod,
      // we want to reset delivery speed cell on delivery method change.
      deliveryPreference: null,
    })
  );
}

export const updateRegularBatchPaymentSaga = {
  *[DELIVERY_METHOD_SELECTED](action) {
    const deliveryMethodId = action?.deliveryMethod?.id;
    const billsToUpdate: BatchItemType[] = yield select(
      regularBatchPaymentStore.selectors.updatePayments.billsToUpdate
    );
    yield all(
      map(billsToUpdate, (billToUpdate) => call(updateRegularBatchPayment, { deliveryMethodId, billToUpdate }))
    );
  },
  *[DELIVERY_METHOD_CREATED](action) {
    const deliveryMethodId = action?.payload?.id;
    const billsToUpdate: BatchItemType[] = yield select(
      regularBatchPaymentStore.selectors.updatePayments.billsToUpdate
    );
    yield all(
      map(billsToUpdate, (billToUpdate) => call(updateRegularBatchPayment, { deliveryMethodId, billToUpdate }))
    );
  },
  *[INTERNATIONAL_DELIVERY_METHOD_CREATED](action) {
    const deliveryMethodId = action?.payload?.id;
    const billsToUpdate: BatchItemType[] = yield select(
      regularBatchPaymentStore.selectors.updatePayments.billsToUpdate
    );
    yield all(
      map(billsToUpdate, (billToUpdate) => call(updateRegularBatchPayment, { deliveryMethodId, billToUpdate }))
    );
  },
};
