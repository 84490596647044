import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { isValid } from 'date-fns';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import values from 'lodash/values';
import zipObject from 'lodash/zipObject';
import memoize from 'memoize-one';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ColumnExplain, FileUploading, UploadError } from 'src/components/common/BatchUpload/BatchUploadPages';
import { SelectColumnPage } from 'src/components/common/BatchUpload/SelectColumnPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import invoiceStore from 'src/modules/invoices/invoices-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { InvoicesReviewPage } from 'src/pages/get-paid/create/batch/InvoicesReviewPage';
import { getInvoicesSelector, getSampleColumns, resetScroll } from 'src/pages/get-paid/create/utils';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications/notificationService';
import { Currency, NotificationVariant } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { useLoadCsv, useLocationState } from 'src/utils/hooks';
import { COLUMNS_KEYS } from '../constants';
import { InvoiceFromCsv, Navigation } from './types';

type Props = Navigation;

export const UploadInvoicesPage = ({ onExit, onPrev, onNext }: Props) => {
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const [file] = useLocationState<File>('file', null);
  const invoicesSelectors = getInvoicesSelector();
  const isUploading = useSelector(invoicesSelectors.createBatch.status)?.loading;
  const orgId = useSelector(getOrgId);
  const [fileContent, isLoading, isError] = useLoadCsv(file);
  const [currentColumnIndex, updateCurrentColumnIndex] = useLocationState('columnIndex', -1);
  const [selectedColumns, setSelectedColumns] = useState(zipObject(COLUMNS_KEYS, [] as string[]));
  const [shouldShowNoInvoicesMessage, setShouldShowNoInvoicesMessage] = useState(false);
  const invoiceActions = useStoreActions(invoiceStore);
  const onPrevColumn = () => (currentColumnIndex > -1 ? history.goBack() : onPrev());
  const onErrorPrev = () => historyPush({ path: getPaidLocations.create.options });
  const setCurrentColumnIndex = (index: number) => updateCurrentColumnIndex(index);

  const onSelectNextColumn = (columnName) => {
    setSelectedColumns({
      ...selectedColumns,
      [COLUMNS_KEYS[currentColumnIndex]]: columnName,
    });
    resetScroll();

    if (currentColumnIndex <= COLUMNS_KEYS.length) {
      setCurrentColumnIndex(currentColumnIndex + 1);
    }
  };

  const convertDataToInvoices = (selectedColumns: { [key: string]: string }, data: any) =>
    data.reduce((acc, row) => {
      const invoiceData = reduce(selectedColumns, (res, value, key) => ({ ...res, [key]: row[value] }), {});

      if (values(invoiceData).every((val) => !isEmpty(val))) {
        acc.push(invoiceData);
      }

      return acc;
    }, []);

  const validateInvoices = (invoices) =>
    invoices.filter((invoice) => isValidationOk(getValidationErrors('paymentRequest', invoice, COLUMNS_KEYS)));

  const removeDuplicates = (invoices: InvoiceFromCsv[]) =>
    uniqBy(invoices, (invoice) => JSON.stringify([invoice.invoiceNumber, invoice.companyName]));

  const dataToInvoices = memoize(convertDataToInvoices);

  const createInvoicesToUpload = memoize((invoices) => flow([validateInvoices, removeDuplicates])(invoices));

  if (isError || (!isLoading && getSampleColumns(fileContent).length < COLUMNS_KEYS.length)) {
    return (
      <UploadError
        onPrev={onPrev}
        subtitle="getPaid.new.create.batch.error.subtitle"
        buttonLabel="getPaid.new.create.batch.error.cancelButton"
      />
    );
  }

  if (isLoading) {
    return (
      <FileUploading
        onPrev={onPrev}
        title="getPaid.new.create.batch.loading.title"
        subtitle="getPaid.new.create.batch.loading.subtitle"
        buttonLabel="getPaid.new.create.batch.loading.button"
      />
    );
  }

  if (currentColumnIndex === -1) {
    return (
      <ColumnExplain
        title="getPaid.new.create.batch.columnExplain.title"
        subtitle="getPaid.new.create.batch.columnExplain.subtitle"
        onPrev={onPrevColumn}
        onExit={onExit}
        onNext={() => setCurrentColumnIndex(0)}
      />
    );
  }

  if (currentColumnIndex < COLUMNS_KEYS.length) {
    const columnsToFilter = COLUMNS_KEYS.slice(0, currentColumnIndex).map((col) => selectedColumns[col]);
    const columnOptions = getSampleColumns(fileContent).filter(
      (column) => columnsToFilter.indexOf(column.name) === -1 && !isEmpty(column.name)
    );
    const selectedColumn = selectedColumns[COLUMNS_KEYS[currentColumnIndex]];
    const selected = columnOptions.find((column) => column.name === selectedColumn) ? selectedColumn : undefined;

    return (
      <SelectColumnPage
        selected={selected}
        options={columnOptions}
        onNext={onSelectNextColumn}
        onPrev={onPrevColumn}
        onExit={onExit}
        title="getPaid.new.create.batch.column.title"
        label={`getPaid.new.create.batch.columnNames.${COLUMNS_KEYS[currentColumnIndex]}`}
      />
    );
  }

  const invoices = dataToInvoices(selectedColumns, fileContent);

  if (isEmpty(invoices)) {
    return (
      <UploadError
        onPrev={onErrorPrev}
        title="getPaid.new.create.batch.noInvoicesToUpload.title"
        subtitle="getPaid.new.create.batch.noInvoicesToUpload.subtitle"
        buttonLabel="getPaid.new.create.batch.noInvoicesToUpload.button"
      />
    );
  }

  const isDateInvalid = invoices.some((invoice) => !isValid(new Date(invoice.dueDate)));

  if (isDateInvalid) {
    return (
      <UploadError
        onPrev={onErrorPrev}
        title="getPaid.new.create.batch.invalidDate.title"
        subtitle="getPaid.new.create.batch.invalidDate.subtitle"
        buttonLabel="getPaid.new.create.batch.invalidDate.cancelButton"
      />
    );
  }

  const invoicesToUpload = createInvoicesToUpload(invoices);

  if (shouldShowNoInvoicesMessage) {
    return (
      <UploadError
        onPrev={onErrorPrev}
        title="getPaid.new.create.batch.noNewInvoicesToCreate.title"
        subtitle="getPaid.new.create.batch.noNewInvoicesToCreate.subtitle"
        buttonLabel="getPaid.new.create.batch.noNewInvoicesToCreate.button"
      />
    );
  }

  const onConfirmAndSave = async () => {
    const data = invoicesToUpload.map((invoice) => {
      const { companyName, amount, invoiceNumber, dueDate } = invoice;
      const totalAmount = convertCurrencyToNumber(amount);

      return {
        totalAmount,
        dueDate: new Date(dueDate).toISOString(),
        invoiceNumber,
        customerName: companyName,
        customerNote: '',
        currency: Currency.USD,
      };
    });

    const res = await invoiceActions.createBatch({ orgId, data });
    const numOfInvoices = res.payload.length;

    if (!numOfInvoices) {
      setShouldShowNoInvoicesMessage(true);
    } else {
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'getPaid.new.create.batch.successNotification',
        textValues: {
          numOfInvoices,
        },
      });
      onNext && onNext();
    }
  };

  return (
    <InvoicesReviewPage
      onExit={onExit}
      onPrev={onPrevColumn}
      onNext={onConfirmAndSave}
      invoices={invoices}
      isLoading={isUploading}
    />
  );
};
