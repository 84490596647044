import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useCollectVendorsEmailModal } from 'src/pages/regular-batch-payments/components/dialog/useCollectVendorsEmailModal';
import { VendorType } from 'src/utils/types';

type Props = {
  onSuccess: () => void;
  dismiss: () => void;
  vendors: VendorType[];
  eventPage: string;
};

export const CollectVendorsEmailModal = ({ dismiss, onSuccess, vendors, eventPage }: Props) => {
  const { submit, emailsMV, loading, disabled } = useCollectVendorsEmailModal(onSuccess, dismiss, vendors, eventPage);

  return (
    <ModalMessage
      alignLeft
      titleComponent={
        <>
          <MIFormattedText label="regularBatchPayments.success.collectVendorEmailModal.title" />
          <Box pt={4} textStyle="body2">
            <MIFormattedText label="regularBatchPayments.success.collectVendorEmailModal.subTitle" />
          </Box>
        </>
      }
      contentComponent={
        <Box mb={5} maxHeight={['auto', null, '26rem']} overflowY={['initial', null, 'auto']}>
          {vendors.map(
            (vendor) =>
              vendor.id && (
                <Box key={vendor.id} pt={3}>
                  <PrivateDataContainer>
                    <TextField
                      testId={`input-${vendor.id}`}
                      label={vendor.companyName}
                      placeholder="regularBatchPayments.success.collectVendorEmailModal.placeholder"
                      model={emailsMV[vendor.id]}
                      type="email"
                      size="sm"
                      mb={0}
                      isRequired
                    />
                  </PrivateDataContainer>
                </Box>
              )
          )}
        </Box>
      }
      buttonComponent={
        <Box mb={4} textAlign="center">
          <Button
            onClick={submit}
            label="regularBatchPayments.success.collectVendorEmailModal.confirm"
            isLoading={loading}
            isDisabled={disabled}
            isFullWidth
          />
        </Box>
      }
      onCloseClick={dismiss}
    />
  );
};
