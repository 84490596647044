import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore from 'src/modules/organizations/organizations-store';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { companiesLocations } from '../locations';
import AddAccountingFirmAddress from './AddAccountingFirmAddress';
import AddAccountingFirmClientsAmount from './AddAccountingFirmClientsAmount';
import AddAccountingFirmDetails from './AddAccountingFirmDetails';
import AddAccountingFirmFromEmail from './AddAccountingFirmFromEmail';
import AddAccountingFirmManualAddress from './AddAccountingFirmManualAddress';
import AddAccountingFirmName from './AddAccountingFirmName';
import { AddAccountingFirmQuickbooksConnect } from './AddAccountingFirmQuickbooksConnect';
import AddAccountingFirmQuickbooksConnectError from './AddAccountingFirmQuickbooksConnectError';
import AddAccountingFirmQuickbooksConnectSuccess from './AddAccountingFirmQuickbooksConnectSuccess';

const ANALYTICS_TAG_NAME = 'AddAccountingFirmRouter';

export function AddAccountingFirmRouter() {
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const onFinishFlow = useCallback(
    (createdOrgId?: number) => {
      historyPush({ path: companiesLocations.index, params: { orgId: createdOrgId || orgId } });
    },
    [orgId, historyPush]
  );

  const updateExtraProperties = () => {
    analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
      currentFlow: 'ChangeAccountingFirmFlow',
    });
    const cleanExtraProperties = () => {
      analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
    };

    return cleanExtraProperties;
  };
  useEffect(updateExtraProperties, []);

  useEffect(
    () => () => {
      dispatch(organizationStore.actions.updateNewCompanySlice.update({}));
    },
    [dispatch]
  );

  const onNext = (path: string) => () => {
    historyPush({ path });
  };

  return (
    <Switch>
      <SmartRoute path={companiesLocations.addAccountingFirm.connectQuickbooks} exact>
        <AddAccountingFirmQuickbooksConnect
          onNext={onNext(companiesLocations.addAccountingFirm.details)}
          onExit={onFinishFlow}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.quickbooksError} exact>
        <AddAccountingFirmQuickbooksConnectError />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.quickbooksSuccess} exact>
        <AddAccountingFirmQuickbooksConnectSuccess />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.details} exact>
        <AddAccountingFirmDetails
          onNext={onNext(companiesLocations.addAccountingFirm.name)}
          onExit={onFinishFlow}
          onPrev={history.goBack}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.name} exact>
        <AddAccountingFirmName
          onPrev={history.goBack}
          onNext={onNext(companiesLocations.addAccountingFirm.address)}
          onExit={onFinishFlow}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.address} exact>
        <AddAccountingFirmAddress
          onPrev={history.goBack}
          onNext={onNext(companiesLocations.addAccountingFirm.clientsAmount)}
          goToManual={onNext(companiesLocations.addAccountingFirm.manualAddress)}
          onExit={onFinishFlow}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.manualAddress} exact>
        <AddAccountingFirmManualAddress
          onPrev={history.goBack}
          onNext={onNext(companiesLocations.addAccountingFirm.clientsAmount)}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.clientsAmount} exact>
        <AddAccountingFirmClientsAmount onPrev={history.goBack} onNext={onFinishFlow} onExit={onFinishFlow} />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.fromEmail} exact>
        <AddAccountingFirmFromEmail />
      </SmartRoute>
    </Switch>
  );
}
