import { all, put } from 'redux-saga/effects';
import { userPreferencesFactory } from 'src/context/records';
import { ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { createInnerStateSlice } from 'src/helpers/redux/innerStateSlice';
import { authApi } from 'src/modules/auth/api';
import { setProfileAction, setUserPreferencesAction } from 'src/redux/user/actions';
import { UserContextType, UserOrganization } from 'src/utils/types';

export type StateType = {
  loading: boolean;
  error?: any;
};
type PayloadType = { token: string };
type ResultType = {
  user: UserContextType;
  userOrganizations: readonly UserOrganization[];
};

export const acceptUserInvitationSlice = createInnerStateSlice<PayloadType, ResultType>({
  storeName: 'auth',
  actionName: 'accept_user_invitation',
  api: authApi.acceptUserInvitation,
  innerStateName: 'acceptUserInvitation',
  sagas: {
    *[ON_SUCCESS](action) {
      const { user } = action.payload;
      yield all([
        put(setProfileAction(user, user.organizations)),
        put(setUserPreferencesAction(userPreferencesFactory(user.userPreferences))),
      ]);
    },
  },
});
