import compact from 'lodash/compact';
import head from 'lodash/head';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useSiteContext } from 'src/hoc/withSiteContext';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { billLocations } from 'src/pages/bill/locations';
import { contactsLocations } from 'src/pages/contacts/locations';
import { vendorLocations } from 'src/pages/vendor/locations';
import { selectNewDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import {
  ContactsTab,
  DbAnalyticsTraits,
  DeliveryMethodCheckFlow,
  DeliveryMethodOrigin,
  DeliveryType,
} from 'src/utils/consts';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { convertStringToUrlObject, generatePathWithSearch } from 'src/utils/query-utils';
import { CheckType, DeliveryMethodType, EditableDeliveryMethodType } from 'src/utils/types';
import { EditCheckAddressWithAutocompleteDeliveryMethodPage } from './components/EditCheckAddressWithAutocompleteDeliveryMethodPage';
import { getEventPageName } from './utils';

export const EditCheckAddressWithAutocompleteDeliveryMethodPageContainer = () => {
  const location = useLocation<{
    check?: CheckType;
    exitUrl?: string;
    redirectUrl?: string;
    origin: DeliveryMethodOrigin;
    preservedState?: any;
    fundingSource: any;
  }>();
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const dispatch = useDispatch();
  const match = useRouteMatch<{
    orgId: string;
    id?: string;
    deliveryMethodId: string;
  }>();
  const site = useSiteContext();
  const vendorId = match.params?.id ? Number(match.params.id) : undefined;
  const deliveryMethodId = match.params?.deliveryMethodId;
  const check = location.state?.check;
  const deliveryMethodActions = getDeliveryMethodActions(dispatch);
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const isUpdating = useSelector(deliveryMethodsStore.selectors.update.status(deliveryMethodId))?.loading || false;
  const isCreating = useSelector(deliveryMethodsStore.selectors.create.status())?.loading;
  const { redirectUrl, exitUrl, fundingSource } = location.state;
  const vendorEditUrl = generatePathWithSearch(
    contactsLocations.index,
    {
      orgId,
      type: ContactsTab.VENDORS,
    },
    { id: vendorId }
  );
  useFetchVendor(vendorId);
  const eventPage = useMemo(() => getEventPageName(ownedVendorId!, vendorId!), [ownedVendorId, vendorId]);

  const goNext = (newDeliveryMethod: DeliveryMethodType) => {
    const nextStepPath = head(compact([redirectUrl, vendorEditUrl])) || '';
    const parsedNextUrl = convertStringToUrlObject(generatePath(nextStepPath, { orgId }));
    history.push({
      ...parsedNextUrl,
      state: {
        ...location.state?.preservedState,
        ...(location.state?.origin === DeliveryMethodOrigin.BATCH_PAY_BILLS ? { batchActionMerge: true } : {}),
        newDeliveryMethod,
      },
    });
  };
  const goUnilateralFlow = () => {
    const nextStepPath = generatePath(vendorLocations.deliveryMethods.virtual.create, {
      orgId,
      id: vendorId,
    });
    history.push({
      pathname: nextStepPath,
      state: {
        ...location.state?.preservedState,
        redirectUrl,
        exitUrl,
        origin: location.state?.origin,
        changeDeliveryMethodType: true,
        shouldSendUnilateralRequest: true,
      },
    });
  };

  const goExit = () => {
    analytics.track(eventPage, 'exit');
    const nextStepPath = head(compact([exitUrl, redirectUrl, vendorEditUrl]));

    if (nextStepPath) {
      const parsedNextUrl = convertStringToUrlObject(generatePath(nextStepPath, { orgId }));
      history.push({
        ...parsedNextUrl,
        state: {
          ...location.state?.preservedState,
        },
      });
    }
  };
  // TODO: replace analytics events with events-mapping (Yotam/Dima).
  const onSuccess = async (paperCheck: CheckType, isVerified: boolean) => {
    const deliveryMethod: EditableDeliveryMethodType = {
      paperCheck,
      isVerified,
      deliveryType: DeliveryType.CHECK,
    };
    let response;

    if (deliveryMethodId && vendorId) {
      analytics.track(eventPage, 'edit-delivery-method', {
        type: DeliveryType.CHECK,
      });
      response = await deliveryMethodActions.edit({
        orgId,
        vendorId,
        id: deliveryMethodId,
        deliveryMethod,
      });
      analytics.track(eventPage, 'edit-delivery-method-success', {
        type: DeliveryType.CHECK,
      });
    } else {
      if (ownedVendorId && ownedVendorId?.toString() === vendorId?.toString()) {
        analytics.setTraits({
          [DbAnalyticsTraits.ADDED_DELIVERY_METHOD]: true,
        });
      }

      analytics.track(eventPage, 'add-delivery-method', {
        type: DeliveryType.CHECK,
      });
      response = await deliveryMethodActions.create({
        orgId,
        vendorId,
        params: deliveryMethod,
      });
      analytics.track(eventPage, 'add-delivery-method-success', {
        type: DeliveryType.CHECK,
        vendorId,
      });
    }

    const newDeliveryMethod = response.payload;

    const isVendorOrigin = [
      DeliveryMethodOrigin.VENDOR_DETAILS,
      DeliveryMethodOrigin.NEW_VENDOR,
      DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS,
    ].includes(location.state?.origin);

    if (location.state?.origin === DeliveryMethodOrigin.PAY_BILL) {
      dispatch(selectNewDeliveryMethodAction(newDeliveryMethod));
    }

    if (isVendorOrigin && vendorId) {
      analytics.track(eventPage, `save-details-and-add-bill`, {
        type: DeliveryType.CHECK,
        vendorId,
      });
      historyPush({ path: billLocations.create.index, query: { vendorId, manually: 'true' } });
    } else {
      goNext(newDeliveryMethod);
    }
  };

  const shouldGoUnilateral = site.goUnilateralInvalidAddress && !isManualBankAccountNotVerified(fundingSource);

  const flowType = shouldGoUnilateral ? DeliveryMethodCheckFlow.UNILATERAL : DeliveryMethodCheckFlow.CONFIRM;

  return (
    <EditCheckAddressWithAutocompleteDeliveryMethodPage
      check={check}
      onSuccess={onSuccess}
      goExit={goExit}
      vendorId={vendorId!}
      isLoading={isUpdating || isCreating}
      goUnilateralFlow={goUnilateralFlow}
      flowType={flowType}
    />
  );
};
