import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { IllustrationName } from 'src/core/ds/illustration';
import { ListItem, OrderedList } from 'src/core/ds/list/inedx';
import { useApi } from 'src/hoc/useApi';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { msnPortalLocations } from 'src/pages/msn-portal/locations';
import { analytics } from 'src/services/analytics';
import { BusinessVerificationStatus, VerifiedStatus } from 'src/utils/consts';
import { DescriptionStyle } from './styles';

export const MicroDepositsVerificationSuccessPage = () => {
  const [historyPush] = useHistoryWithOrgId();
  const match = useRouteMatch<{ token: string }>();
  const { token } = match.params;

  const { onApiCall: getMSNVerificationStatusWithToken, loading: isLoading, result } = useApi<
    [{ token: string }],
    { status: BusinessVerificationStatus }
  >({
    api: msnPortalApi.getMSNVerificationStatusWithToken,
    initialLoading: true,
  });

  useEffect(() => {
    getMSNVerificationStatusWithToken({ token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToDashboard = () => {
    historyPush({
      path: msnPortalLocations.base,
    });
  };
  const isBusinessVerified = result && result.status === BusinessVerificationStatus.VERIFIED;

  if (isBusinessVerified) {
    analytics.trackAction('msnClaimAccountMicroDeposit.registration-completed', {
      networkRegistrationFlow: 'salesForm',
      accountVerificationMethod: VerifiedStatus.MICRO_DEPOSIT_VERIFIED,
    });
  }

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <SuccessLayoutPage
      testId="success-page"
      illustration={IllustrationName.bankCheck}
      title={
        isBusinessVerified ? 'microDepositVerification.businessSuccess.title' : 'microDepositVerification.success.title'
      }
      text={
        isBusinessVerified
          ? 'microDepositVerification.businessSuccess.subtitle'
          : 'microDepositVerification.success.subtitle'
      }
    >
      {isBusinessVerified ? (
        <Button
          label="microDepositVerification.businessSuccess.buttonLabel"
          onClick={goToDashboard}
          size={ButtonSizes.lg}
          w="full"
        />
      ) : (
        <Box sx={DescriptionStyle}>
          <Box mb={2} textStyle="body2Semi" textAlign="left">
            <MIFormattedText label="microDepositVerification.success.descriptions.title" />
          </Box>
          <Box textStyle="body2" textAlign="left">
            <OrderedList ps={1}>
              <ListItem mb={5}>
                <MIFormattedText label="microDepositVerification.success.descriptions.info1" />
              </ListItem>
              <ListItem>
                <MIFormattedText label="microDepositVerification.success.descriptions.info2" />
              </ListItem>
            </OrderedList>
          </Box>
        </Box>
      )}
    </SuccessLayoutPage>
  );
};
