import { BoxProps, useStyleConfig } from '@chakra-ui/react';
import Box from 'src/core/ds/box';

type Props = BoxProps & {
  src?: string;
  size?: 'sm' | 'md' | 'lg';
};

export const ModalCover = ({ src, size, ...rest }: Props) => {
  const style = useStyleConfig('ModalCover', { size });

  return (
    <Box __css={style} {...rest}>
      <Box as="img" w="inherit" h="inherit" borderTopRadius="lg" src={src} />
    </Box>
  );
};
