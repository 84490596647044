import { useSelector } from 'react-redux';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { isInternationalVendor } from 'src/pages/vendor/international-delivery-method/utils';
import { getCompanyInfo } from 'src/redux/user/selectors';

type useIsInternationalAllowedResult = {
  isInternationalVendor: boolean;
  isInternationalAllowed: boolean;
};

export const useIsInternationalAllowed = (vendorId?: number | null): useIsInternationalAllowedResult => {
  const NOT_ALLOWED_STATES = ['TX', 'VT'];
  const companyInfo = useSelector(getCompanyInfo);
  const vendor = useSelector(vendorsStore.selectors.byId(vendorId));
  const isAllowedState = !companyInfo.state || !NOT_ALLOWED_STATES.includes(companyInfo.state);
  const isAllowedLegalState = !companyInfo.legalState || !NOT_ALLOWED_STATES.includes(companyInfo.legalState);

  return {
    isInternationalVendor: vendor && isInternationalVendor(vendor),
    isInternationalAllowed: isAllowedState && isAllowedLegalState,
  };
};
