import * as React from 'react';
import Flex from 'src/core/ds/flex';
import { BillSummaryInfo } from 'src/pages/regular-batch-payments/components/header/components/BillSummaryInfo';
import { AmountSummaryInfo } from './components/AmountSummaryInfo';

export interface BatchPaymentsHeaderProps {
  isLoadingBills: boolean;
}

export const Header: React.FC<BatchPaymentsHeaderProps> = ({ isLoadingBills }) => (
  <Flex
    marginTop={6}
    paddingX={24}
    paddingY={24}
    justify="space-between"
    bgColor="white"
    borderRadius={10}
    border="1px"
    borderColor="grey.400"
  >
    <BillSummaryInfo isLoadingBills={isLoadingBills} />
    <AmountSummaryInfo />
  </Flex>
);
