import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type MICardTitleProps = {
  label: string;
  className?: string;
  testId?: string;
  values?: Record<string, any>;
};

type MICardProps = {
  mode?: 'mainSingleScreen' | 'regular' | 'natural';
  children: React.ReactNode;
  className?: string;
  flex?: boolean;
  testId?: string;
  id?: string;
};

type MICardFormProps = {
  children: React.ReactNode;
  onKeyDown?: (event: any) => void;
  className?: string;
  testId?: string;
  noHorizontalPadding?: boolean;
};

type MICardSectionProps = {
  children: React.ReactNode;
  className?: string;
};

type FieldProps = {
  label: string;
  labelValues?: Record<string, any>;
  height?: 'normal' | 'small';
  children: React.ReactNode;
};

type EditableFieldProps = {
  children: React.ReactNode;
  testId?: string;
};

type MICardHeaderProps = {
  children?: React.ReactNode;
};

type ContainerProps = {
  children?: React.ReactNode;
  mode?: 'mainSingleScreen' | 'regular' | 'natural';
  className?: string;
  flex?: boolean;
  'data-testid'?: string;
  id?: string;
};

type TitleProps = {
  children?: React.ReactNode;
  className?: string;
  'data-testid'?: string;
};

type SectionContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

type FormContainerProps = {
  children?: React.ReactNode;
  noHorizontalPadding?: boolean;
  className?: string;
  onKeyDown?: (event: any) => void;
  'data-testid'?: string;
};

type FieldContainerProps = {
  children?: React.ReactNode;
  height?: 'normal' | 'small';
};

type EditableFieldContainerProps = {
  children?: React.ReactNode;
  'data-testid'?: string;
};

type LabelProps = {
  children?: React.ReactNode;
};

type ValueProps = {
  children?: React.ReactNode;
  'data-testid'?: string;
};

const MICardTitle = ({ label, className, testId, values }: MICardTitleProps) => (
  <Title className={className} data-testid={testId}>
    <MIFormattedText label={label} values={values} />
  </Title>
);

const MICard = ({ className, mode = 'mainSingleScreen', children, flex, testId, id }: MICardProps) => (
  <Container flex={flex} mode={mode} className={className} data-testid={testId} id={id}>
    {children}
  </Container>
);

const MICardForm = React.forwardRef(
  (
    {
      children, // eslint-disable-next-line @typescript-eslint/no-empty-function
      onKeyDown = () => {},
      className = '',
      testId,
      noHorizontalPadding,
    }: MICardFormProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => (
    <FormContainer
      className={className}
      onKeyDown={onKeyDown}
      data-testid={testId}
      noHorizontalPadding={noHorizontalPadding}
      ref={ref}
    >
      {children}
    </FormContainer>
  )
);

const MICardSection = ({ children, className }: MICardSectionProps) => (
  <SectionContainer className={className}>{children}</SectionContainer>
);

const MICardField = ({ label, labelValues, height = 'normal', children }: FieldProps) => (
  <FieldContainer height={height}>
    <Label>
      <MIFormattedText label={label} values={labelValues} />
    </Label>
    <Value data-testid={`${label}-value`}>{children}</Value>
  </FieldContainer>
);

const MICardEditableField = ({ children, testId }: EditableFieldProps) => (
  <EditableFieldContainer data-testid={testId}>
    <Value>{children}</Value>
  </EditableFieldContainer>
);

const MICardHeader = ({ children }: MICardHeaderProps) => (
  <Flex align="center" justify="space-between" minH="4.2rem">
    {children}
  </Flex>
);

const Container = ({ children, flex, mode, id, ...rest }: ContainerProps) => (
  <Box sx={ContainerStyle(mode, flex)} id={id} {...rest}>
    {children}
  </Box>
);

const ContainerStyle = (mode?: 'mainSingleScreen' | 'regular' | 'natural', flex?: boolean): CSSObject => {
  const isMainSingleScreen = mode === 'mainSingleScreen';

  return {
    border: '1px',
    borderColor: 'grey.300',
    borderRadius: 'lg',
    boxShadow: { base: 400, sm: mode === 'regular' ? 400 : 'none' },
    boxSizing: 'border-box',
    pb: 0,
    display: flex ? 'flex' : 'block',
    w: { base: 'auto', md: 'full' },
    bgColor: { base: 'white', md: 'transparent' },
    mt: { base: isMainSingleScreen ? 2 : 0, sm: isMainSingleScreen ? 10 : 0, md: 0 },
    mb: { base: isMainSingleScreen ? 5 : 0, md: isMainSingleScreen ? 10 : 0 },
    mx: { base: isMainSingleScreen ? 2 : 0, sm: isMainSingleScreen ? 5 : 0, md: 0 },
  };
};

const Title = ({ children, ...rest }: TitleProps) => (
  <Box color="black" textStyle="h2Semi" {...rest}>
    {children}
  </Box>
);

const SectionContainer = ({ children, className }: SectionContainerProps) => (
  <Box pt={2} pb={5} px={{ base: 4, sm: 8 }} className={className}>
    {children}
  </Box>
);

const FormContainer = React.forwardRef<HTMLDivElement, FormContainerProps>(
  ({ children, noHorizontalPadding, ...rest }, ref) => (
    <Box py={8} px={{ base: 4, sm: noHorizontalPadding ? 0 : 8 }} {...rest} ref={ref}>
      {children}
    </Box>
  )
);

const FieldContainer = ({ children, height }: FieldContainerProps) => (
  <Box sx={FieldContainerStyle(height)}>{children}</Box>
);

const FieldContainerStyle = (height?: 'normal' | 'small'): CSSObject => ({
  mt: 5,
  mb: 0,
  mx: 0,
  _first: {
    mt: height === 'small' ? 0 : 5,
  },
});

const EditableFieldContainer = ({ children, ...rest }: EditableFieldContainerProps) => (
  <Box sx={EditableFieldContainerStyle} {...rest}>
    {children}
  </Box>
);

const EditableFieldContainerStyle: CSSObject = {
  mt: 4,
  mx: 0,
  mb: 0,
  _first: {
    mt: 5,
  },
};

const Label = ({ children }: LabelProps) => (
  <Box color="grey.600" textStyle="body4Semi">
    {children}
  </Box>
);

const Value = ({ children, ...rest }: ValueProps) => (
  <Box sx={ValueStyle} {...rest}>
    {children}
  </Box>
);

const ValueStyle: CSSObject = {
  color: 'black',
  textStyle: 'body2',
  wordBreak: 'break-word',
  svg: {
    textStyle: 'body2',
    mr: 1,
  },
};

export { MICard, MICardEditableField, MICardField, MICardForm, MICardHeader, MICardSection, MICardTitle };
