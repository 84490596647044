import { deleteRequest, fetchRequest, postRequest } from 'src/services/api/api';

export const joinOrganizationRequestsApi = {
  resend(params) {
    const url = `/join-organization-requests/${params.id}/resend`;

    return postRequest(url, params).then((res) => res.joinOrganizationRequest);
  },
  list() {
    const url = '/join-organization-requests';

    return fetchRequest(url).then((res) => ({
      items: res.joinOrganizationRequests,
    }));
  },
  create(params) {
    const url = '/join-organization-requests';

    return postRequest(url, params);
  },

  validate(params) {
    const url = '/join-organization-requests/validate';

    return postRequest(url, params);
  },
  accept(
    params
  ): Promise<{
    joinOrganizationRequest: ToDo;
    userOrganization: ToDo;
  }> {
    const url = '/join-organization-requests/accept';

    return postRequest(url, params);
  },
  delete(params) {
    const url = `/join-organization-requests/${params.id}`;

    return deleteRequest(url);
  },
};
