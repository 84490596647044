import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { createSelector } from '@reduxjs/toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore, { getUsersOfOrg } from 'src/modules/users/users-store';
import { GlobalState } from 'src/redux/types';
import { analytics } from 'src/services/analytics';
import { CompanyType } from 'src/utils/consts';
import { sortOrganizations } from 'src/utils/organizations';
import { CompanyInfoType, UserOrganization, UserSummaryType } from 'src/utils/types';

type FILTER_MODE = 'active' | 'hidden';

export const useDisplayOrganizations = () => {
  const currentUser = useSelector(profileStore.selectors.profile);
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);
  const { activeOrgs, hiddenOrgs } = useSelector((state: GlobalState) => selectOrganizations(state, currentOrg));
  const hasCompanyWithUsers = useMemo(() => activeOrgs.some((org) => org.users?.length > 0), [activeOrgs]);
  const currentUserRole = useSelector(profileStore.selectors.getUserRole);
  const { userManagementHideFirmClientSlice, userManagementUnhideFirmClientSlice } = useStoreActions(usersStore);
  const [filterMode, setFilterMode] = useState<FILTER_MODE>('active');
  const [isHideClientsEnabled] = useFeature<boolean>('hide-clients', false);
  const currentUserId = currentUser.id;
  const orgId = currentOrg.id;

  useEffect(() => {
    if (!hiddenOrgs.length) {
      setFilterMode('active');
    }
  }, [hiddenOrgs]);

  const handleActiveClientsFilterClick = useCallback(() => {
    analytics.trackAction('active-filter-click', { clientsCount: activeOrgs.length });
    setFilterMode('active');
  }, [activeOrgs]);

  const handleHiddenClientsFilterClick = useCallback(() => {
    analytics.trackAction('hidden-filter-click', { clientsCount: hiddenOrgs.length });
    setFilterMode('hidden');
  }, [hiddenOrgs]);

  const activeClientsFilterAction = {
    label: 'bookkeeper.company.filter.active',
    onClick: handleActiveClientsFilterClick,
    testId: 'active-clients-filter',
  };
  const hiddenClientsFilterAction = {
    label: 'bookkeeper.company.filter.hidden',
    onClick: handleHiddenClientsFilterClick,
    testId: 'hidden-clients-filter',
  };
  const filterActions = [activeClientsFilterAction, hiddenClientsFilterAction];

  const handleHideClientClick = useCallback(
    (targetOrgId: number) => {
      analytics.trackAction('hide-client', {
        hiddenOrganizationId: targetOrgId,
      });
      userManagementHideFirmClientSlice({
        targetOrgId,
        orgId,
        userId: currentUserId,
      });
    },
    [currentUserId, orgId, userManagementHideFirmClientSlice]
  );

  const handleUnhideClientClick = useCallback(
    (targetOrgId: number) => {
      analytics.trackAction('unhide-client', {
        hiddenOrganizationId: targetOrgId,
      });
      userManagementUnhideFirmClientSlice({
        targetOrgId,
        orgId,
        userId: currentUserId,
      });
    },
    [currentUserId, orgId, userManagementUnhideFirmClientSlice]
  );

  return {
    filterMode,
    currentOrg,
    currentUser,
    filterActions,
    currentUserRole,
    hasCompanyWithUsers,
    handleHideClientClick,
    handleUnhideClientClick,
    hasHiddenOrgs: !!hiddenOrgs.length,
    shouldDisplayFilterActions:
      !!hiddenOrgs?.length && isHideClientsEnabled && currentOrg.companyType === CompanyType.ACCOUNTING_FIRM,
    organizations: filterMode === 'hidden' ? hiddenOrgs : activeOrgs,
  };
};

const selectOrganizations = createSelector(
  [
    (_state: GlobalState, currentOrg) => currentOrg,
    (state: GlobalState, currentOrg) => getUsersOfOrg(currentOrg.id)(state),
    profileStore.selectors.getOrganizations,
    profileStore.selectors.getHiddenUserOrganizations,
  ],
  (currentOrg, organizationUsers, organizations, hiddenOrganizations) => {
    const activeOrgs = parseOrganizations({ orgs: organizations, organizationUsers, currentOrg });
    const hiddenOrgs = parseOrganizations({ orgs: hiddenOrganizations, organizationUsers, currentOrg });

    return { activeOrgs, hiddenOrgs };
  }
);

const parseOrganizations = ({ orgs, currentOrg, organizationUsers }) => {
  const isAccountingFirm = currentOrg.companyType === CompanyType.ACCOUNTING_FIRM;

  return sortOrganizations(orgs)
    .filter(withoutAccountingFirm(isAccountingFirm, currentOrg.id))
    .map((org) => {
      const orgWithUsers = {
        ...org,
        users: !isAccountingFirm
          ? []
          : organizationUsers.filter((user: UserSummaryType & { userOrganizations: UserOrganization[] }) =>
              user.userOrganizations?.some((userOrg) => userOrg.organizationId === org.id)
            ),
      };

      return orgWithUsers;
    });
};

const withoutAccountingFirm = (isAccountingFirm: boolean, firmOrgId?: number) =>
  isAccountingFirm ? (org: CompanyInfoType) => org.id !== firmOrgId : () => true;
