import { AmexNotificationDetails } from '@melio/spend-management-api-axios-client';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import Spacer from 'src/core/ds/spacer';
import { Tag } from 'src/core/ds/tag';

export type AccountActionsType = {
  action: (id?: string) => void | Promise<void>;
  label: string;
  negative?: boolean;
  hasMultipleAccounts?: boolean;
};

type Props = {
  account: AmexNotificationDetails;
  actions?: AccountActionsType[];
};

export const AccountItem = ({ account, actions }: Props) => {
  const filteredActions = account.isDefault
    ? actions?.filter((action) => action.label !== 'settings.spendManagement.connectLayout.item.actions.default')
    : actions;

  return (
    <Flex direction="row" alignItems="center">
      <Icon name={IconNames.creditCard} />
      <Flex justifyContent="center" direction="column" overflow="hidden" ml={3} isTruncated>
        <Flex alignItems="center" gap={2}>
          <Box textStyle="body2Semi">
            <MIFormattedText
              label="spendManagement.header.connectedAccount.items.account"
              values={{ last5: account.lastFive, nickname: account.accountNickname || account.productDescription }}
            />
          </Box>
          {account.isDefault ? <Tag size="sm" label="spendManagement.header.connectedAccount.items.default" /> : null}
        </Flex>
        <Flex alignItems="center">
          <Box
            data-testid="card-indicator"
            as="span"
            borderRadius="50%"
            bg="green.700"
            p={1}
            justifyContent="center"
            alignItems="center"
            mr={1}
          />
          <Box textStyle="body4">
            <MIFormattedText label="settings.spendManagement.connectLayout.item.connected" />
          </Box>
        </Flex>
      </Flex>
      <Spacer />
      {filteredActions && (
        <Menu autoSelect={false} isLazy>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Icon name={IconNames.more} />}
            variant="action"
            placement="top"
            data-testid={`dots-action-menu-${account.accountId}`}
          />
          <MenuList>
            {filteredActions.map(({ label, action, negative, hasMultipleAccounts }) => (
              <MenuItem
                key={label}
                label={label}
                onClick={() => action(account?.accountId)}
                color={negative ? 'red.500' : 'black'}
                disabled={
                  account.isDefault &&
                  hasMultipleAccounts &&
                  label === 'settings.spendManagement.connectLayout.item.actions.disconnect'
                }
              />
            ))}
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};
