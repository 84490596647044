import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import NotFound from '../../../images/general/404-illustration.svg';
import { devices } from '../../../theme/appDevices';
import locations from '../../../utils/locations';

const NotFoundPage = () => {
  const { navigate } = useNavigator();
  useEffect(() => {
    const eventPage = 'not-found-404';
    analytics.page(eventPage, eventPage);
  }, []);
  const orgId = useSelector(getOrgId);

  return (
    <SuccessLayoutPage
      title="notFound.title"
      text="notFound.subtitle"
      buttonLabel="notFound.button"
      buttonAction={() => navigate(locations.MainApp.dashboard.url({ orgId }))}
      hasLogo
    >
      <NotFoundContainer src={NotFound} />
    </SuccessLayoutPage>
  );
};

NotFoundPage.defaultProps = {};

const NotFoundContainer = styled.img`
  margin-top: -5rem;
  height: 34rem;

  @media ${devices.mobile} {
    margin: -3rem 0 2rem;
    height: 24rem;
  }
`;

export default NotFoundPage;
