import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import Tooltip from 'src/core/ds/tooltip';

export const StatusBadge = ({ status }: { status: string }) => {
  const prefixLocaleContent = `spendManagement.pages.dashboard.txnStatuses.${status}`;

  const TXN_STATUS_COLORS = {
    APPROVED: BadgeColors.SUCCESS,
    DECLINED: BadgeColors.DANGER,
    IN_PROGRESS: BadgeColors.NEUTRAL,
  };
  const badgeColor = TXN_STATUS_COLORS[`${status}`];

  return useMemo(
    () => (
      <Box isTruncated>
        <Tooltip
          placement="top"
          label={<MIFormattedText label={`${prefixLocaleContent}.description`} />}
          shouldWrapChildren
        >
          <Badge color={badgeColor} label={`${prefixLocaleContent}.label`} />
        </Tooltip>
      </Box>
    ),
    [prefixLocaleContent, badgeColor]
  );
};

export const StatusCell: React.FC<CellProps<any>> = ({ cell: { value } }) => <StatusBadge status={value} />;
