import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { CopyLinkSize, NotificationVariant } from 'src/utils/consts';
import { removeProtocol } from 'src/utils/string-utils';

type Props = {
  link: string;
  clipboardText: string;
  size?: CopyLinkSize;
};

export const CopyLinkToClipboard = ({ link, clipboardText, size }: Props) => {
  const onCopy = () => {
    analytics.trackAction('copy-link');
    pushNotification({
      type: NotificationVariant.INFO,
      msg: 'requests.melioMeLinkCard.toastMessage',
    });
  };

  const onGotolink = () => {
    analytics.trackAction('copy-link-action');
  };

  return (
    <Flex
      direction="row"
      bgColor={size === CopyLinkSize.NORMAL ? 'white' : 'grey.300'}
      borderRadius="lg"
      justify="space-between"
      align="center"
      pl={size === CopyLinkSize.NORMAL ? 0 : 4}
    >
      <Link
        href={link}
        target="_open"
        onClick={onGotolink}
        textStyle={{ base: 'body1', sm: size === CopyLinkSize.NORMAL ? 'body2' : 'h2' }}
        color="primary.500"
        mt={2}
        textOverflow="ellipsis"
        textDecor="none"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {removeProtocol(link)}
      </Link>
      <CopyToClipboard onCopy={onCopy} text={clipboardText} data-testid="copy-link-to-clipboard">
        <Button
          variant={ButtonVariants.tertiary}
          label="general.copyLink"
          size={size === CopyLinkSize.BIG ? ButtonSizes.lg : ButtonSizes.sm}
        />
      </CopyToClipboard>
    </Flex>
  );
};
