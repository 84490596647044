import { ButtonProps, CloseButton as ChakraCloseButton, forwardRef } from '@chakra-ui/react';
import { useCallback } from 'react';
import * as React from 'react';
import { analytics } from 'src/services/analytics';

type Props = Omit<ButtonProps, 'colorScheme' | 'variant' | 'size'> & {
  testId?: string | null;
  analyticsProperties?: Record<string, any>;
  id?: string | null;
  size?: 'sm' | 'md' | 'lg';
};

export const CloseButton = forwardRef<Props, 'button'>((props: Props, ref) => {
  const { onClick, children, testId, id, analyticsProperties, ...rest } = props;
  const dataTestId = testId || `button-${id}`;

  const onClickWithEvent = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      id && analytics.trackAction(id, analyticsProperties);

      return onClick && onClick(event);
    },
    [onClick, id, analyticsProperties]
  );

  return (
    <ChakraCloseButton onClick={onClickWithEvent} data-testid={dataTestId} ref={ref} {...rest}>
      {children}
    </ChakraCloseButton>
  );
});
