import { featureFlags } from '@melio/shared-web';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useCountryRisk } from 'src/pages/vendor/international-delivery-method/hooks/useCountryRisk';
import { useFirstVendorPayment } from 'src/pages/vendor/international-delivery-method/hooks/useFirstVendorPayment';
import { getDeliveryMethodByType } from 'src/pages/vendor/records';
import { DeliveryType, FeatureFlags, RiskTypesEnum } from 'src/utils/consts';

export const useInvoiceAttachmentRequired = (orgId, vendorId, bill) => {
  const [isInternationalHighRiskCountriesEnabled] = featureFlags.useFeature(
    FeatureFlags.InternationalHighRiskCountries,
    false
  );
  const isBillHasFiles = bill?.files?.length > 0;
  const vendorActions = useStoreActions(vendorsStore);
  const vendor = useSelector(vendorsStore.selectors.byId(vendorId));
  const vendorDeliveryMethod = getDeliveryMethodByType(DeliveryType.INTERNATIONAL, vendor?.deliveryMethods);
  const [countryRisk] = useCountryRisk(vendorId, vendorDeliveryMethod?.internationalAccount?.payeeBankCountry);
  const [isFirstVendorPayment] = useFirstVendorPayment(orgId, vendorId);

  const fetchVendor = useCallback(async () => {
    await vendorActions.fetch({ orgId, id: vendorId });
  }, [orgId, vendorId]);

  useEffect(() => {
    fetchVendor();
  }, []);

  const isMediumRiskCountryFirstPayment = countryRisk === RiskTypesEnum.Medium && isFirstVendorPayment;
  const isHighRiskCountry = countryRisk === RiskTypesEnum.High;
  const isInvoiceAttachmentRequired =
    (isMediumRiskCountryFirstPayment || isHighRiskCountry) && isInternationalHighRiskCountriesEnabled;

  if (isBillHasFiles) {
    return [false];
  }

  return [isInvoiceAttachmentRequired];
};
