import cookies from 'js-cookie';
import { baseURL } from 'src/modules/files/api';
import { CouponType, PaymentRequestOverrides, PaymentRequestType } from 'src/utils/types';
import { fetchRequest, postRequest } from './api';

type FetchPaymentRequestResponse = {
  fileStorageUrl: string;
  filePreviewUrls: string[];
  overrides?: PaymentRequestOverrides;
  paymentRequest: PaymentRequestType;
  showRegisterCTA: string;
  coupons: CouponType[];
};

export type SendEmailVerificationCodeWithTokenResponse = {
  email: string;
};

export type ResolveEmailVerificationRequestData = {
  email: string;
  code: number;
  fallbackUtms: string;
  fallbackTams: string;
  registrationFlow?: string;
};

type ResolveEmailVerificationWithTokenRequestData = {
  code: number;
  token: string;
};

export default {
  getPublicVendorInfo(link) {
    const url = `/me/${link}/vendor`;

    return fetchRequest(url);
  },

  sendEmailVerificationCode(email: string) {
    const url = '/auth/guest/send-email-verification';

    return postRequest(url, { email });
  },

  sendEmailVerificationCodeWithToken(params: { token: string }): Promise<SendEmailVerificationCodeWithTokenResponse> {
    const url = '/auth/guest/send-email-verification-with-token';

    return postRequest(url, { token: params.token });
  },

  createPasswordWithToken(token: string, password: string) {
    const url = '/auth/guest/create-password-with-token';

    return postRequest(url, { token, password });
  },

  resolveEmailVerification(data: ResolveEmailVerificationRequestData) {
    const { email, code, fallbackTams, fallbackUtms, registrationFlow } = data;
    const url = '/auth/guest/resolve-email-verification';
    const cookiesUtms = cookies.get('utms');
    const cookiesTam = cookies.get('trackingTam');

    return postRequest(url, {
      email,
      code,
      registrationFlow,
      marketingTam: cookiesTam || fallbackTams,
      marketingUtms: cookiesUtms || fallbackUtms,
    });
  },

  resolveEmailVerificationWithToken({ token, code }: ResolveEmailVerificationWithTokenRequestData) {
    const url = '/auth/guest/resolve-email-verification-with-token';

    return postRequest(url, {
      token,
      code,
    });
  },

  getPaymentRequest(handle: string, link: string): Promise<FetchPaymentRequestResponse> {
    const url = `/me/${handle}/${link}`;

    return fetchRequest(url).then((result) => {
      const { paymentRequest, coupons, overrides, showRegisterCTA } = result;
      const fileStorageUrl = baseURL(result.fileStorageUrl);
      const filePreviewUrls = result.filePreviewUrls.map((filePreviewUrl) => baseURL(filePreviewUrl));

      return {
        fileStorageUrl,
        filePreviewUrls,
        paymentRequest,
        coupons,
        overrides,
        showRegisterCTA,
      };
    });
  },

  getPaymentRequestStatus(handle, link) {
    const url = `/me/${handle}/${link}/status`;

    return fetchRequest(url);
  },
  getInvoiceStatus({ handle, invoiceNumber, totalAmount }) {
    const url = `/me/${handle}/invoice/${invoiceNumber}/status/${totalAmount}`;

    return fetchRequest(url);
  },
};
