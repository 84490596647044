import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { VALIDATIONS } from 'src/pages/utils/validations';
import { analytics } from 'src/services/analytics';
import { ValidationError } from 'src/ui/ValidationError';
import { CandidatesDetailsModelType } from '../../types';

const TAX_ID_REGEX = /^[0-9]{9}$|^[0-9]{3}-[0-9]{2}-[0-9]{4}$|^[0-9]{2}-[0-9]{7}$/;

const validateTaxFields = ({ taxId, taxIdType }: Partial<CandidatesDetailsModelType>) => {
  const emptyTaxId = isEmpty(taxId);
  const emptyTaxIdType = isEmpty(taxIdType);
  const validateErrors: { taxId?: string; taxIdType?: string } = {};

  if (!emptyTaxId) {
    if (!TAX_ID_REGEX.test(taxId as string)) {
      validateErrors.taxId = 'vendors.candidates.form.validation.taxId.invalid';
    }

    if (emptyTaxIdType) {
      validateErrors.taxIdType = 'vendors.candidates.form.validation.taxIdType.empty';
    }
  }

  return validateErrors;
};

export const validateCandidatesDetails = ({
  phone,
  phoneExt,
  googlePlaceId,
  contactFirstName,
  contactLastName,
  companyName,
  email,
  emailConfirmation,
  routingNumber,
  accountNumber,
  taxId,
  taxIdType,
  msnBusinessType,
}: CandidatesDetailsModelType) => {
  const companyInfo = {
    phone,
    googlePlaceId,
    contactFirstName,
    contactLastName,
    companyName,
  };

  const fieldToMessagesMap = {
    // email: 'vendors.candidates.form.validation.email',
    phone: 'vendors.candidates.form.validation.phone',
    googlePlaceId: 'vendors.candidates.form.validation.googlePlaceId',
    contactFirstName: 'vendors.candidates.form.validation.firstName',
    contactLastName: 'vendors.candidates.form.validation.lastName',
    companyName: 'vendors.candidates.form.validation.companyName',
  };

  const bankInfoValidationsError = getValidationErrors('deliveryMethodAch', {
    routingNumber,
    accountNumber,
  });

  if (isEmpty(routingNumber)) {
    bankInfoValidationsError.routingNumber = 'vendors.candidates.form.validation.routingNumber.empty';
  } else if (bankInfoValidationsError.routingNumber) {
    bankInfoValidationsError.routingNumber = 'vendors.candidates.form.validation.routingNumber.invalid';
  }

  if (isEmpty(accountNumber)) {
    bankInfoValidationsError.accountNumber = 'vendors.candidates.form.validation.accountNumber.empty';
  } else if (bankInfoValidationsError.accountNumber) {
    bankInfoValidationsError.accountNumber = 'vendors.candidates.form.validation.accountNumber.invalid';
  }

  let emailValidationErrors = {};

  if (isEmpty(email)) {
    emailValidationErrors = { email: 'vendors.candidates.form.validation.email.empty' };
  } else if (!isEmpty(getValidationErrors('userRegistration', { email }))) {
    emailValidationErrors = { email: 'vendors.candidates.form.validation.email.invalid' };
  }

  let emailConfirmationErrors = {};

  if (isEmpty(emailConfirmation)) {
    emailConfirmationErrors = { emailConfirmation: 'vendors.candidates.form.validation.emailConfirmation.empty' };
  } else if (!isEmpty(email) && emailConfirmation !== email) {
    emailConfirmationErrors = { emailConfirmation: 'vendors.candidates.form.validation.emailConfirmation.invalid' };
  }

  const companyInfoValidationErrors = getValidationErrors('companyInfo', companyInfo, [
    'phone',
    'googlePlaceId',
    'contactFirstName',
    'contactLastName',
    'companyName',
  ]);

  let phoneExtValidationErrors;

  if (Number(phoneExt?.length) > VALIDATIONS.SHORT_STRING_LENGTH) {
    phoneExtValidationErrors = {
      phoneExt: 'vendors.candidates.form.validation.phoneExt',
    };
  }

  const msnBusinessTypeValidationErrors = msnBusinessType
    ? {}
    : { msnBusinessType: 'vendors.candidates.form.validation.msnBusinessType' };

  const taxFieldsErrors = validateTaxFields({ taxId, taxIdType });

  const validationErrors = {
    ...emailValidationErrors,
    ...emailConfirmationErrors,
    ...companyInfoValidationErrors,
    ...phoneExtValidationErrors,
    ...bankInfoValidationsError,
    ...msnBusinessTypeValidationErrors,
    ...taxFieldsErrors,
  };

  const errorsWithCorrectMessages = Object.fromEntries(
    Object.entries(validationErrors).map(([field, message]) => [field, fieldToMessagesMap[field] ?? message])
  );

  if (!isValidationOk(errorsWithCorrectMessages)) {
    analytics.trackAction('msnSalesForm.submit.error', { error: errorsWithCorrectMessages });
    throw new ValidationError({
      validationErrors: errorsWithCorrectMessages,
    });
  }

  return true;
};

export const displayExistingEmailError = () => {
  const validationErrors = { email: 'vendors.candidates.form.validation.emailExistsAlready' };
  throw new ValidationError({ validationErrors });
};
