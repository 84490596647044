import { MICard, MICardEditableField, MICardForm, MICardHeader, MICardTitle } from 'src/components/common/MICard';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { PasswordField } from 'src/core/ds/form/fields';
import { ModelView } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';
import { ChangePasswordParams } from '../profile-store';

export type PasswordChangeViewProps = {
  model: ModelView<ChangePasswordParams>;
  viewOnly?: boolean;
  editable?: boolean;
  toggleViewOnly?: () => void;
};
export function PasswordChangeView({ model, viewOnly, toggleViewOnly, editable }: PasswordChangeViewProps) {
  return (
    <MICard>
      <MICardForm>
        <MICardHeader>
          <MICardTitle label="profile.changePassword.title" />
          {editable && viewOnly && (
            <Button
              onClick={toggleViewOnly}
              label="profile.changePassword.edit"
              size={ButtonSizes.md}
              variant={ButtonVariants.tertiary}
            />
          )}
        </MICardHeader>
        <MICardEditableField>
          <PasswordField
            label="profile.changePassword.currentPassword"
            isViewOnly={viewOnly}
            model={model.currentPassword}
            value={viewOnly ? 'placeholder' : ''}
            errorMessage={model.currentPassword.error}
            size="sm"
            isRequired
          />
        </MICardEditableField>
        {!viewOnly && (
          <MICardEditableField>
            <WizardPasswordValidationInputField
              label="profile.changePassword.password"
              isViewOnly={viewOnly}
              model={model.password}
              dotSize={1}
              marginBottomOverride="0"
              passwordStrengthSize="sm"
              size="sm"
              isRequired
            />
          </MICardEditableField>
        )}
        {!viewOnly && (
          <MICardEditableField>
            <PasswordField
              label="profile.changePassword.confirmPassword"
              isViewOnly={viewOnly}
              model={model.confirmedPassword}
              size="sm"
              isRequired
            />
          </MICardEditableField>
        )}
      </MICardForm>
    </MICard>
  );
}
