/* eslint-disable no-template-curly-in-string, max-len */
import { GUEST_MAPPING as amexGuestEventMapping } from 'src/components/funding-source/amex/event-mapping-amex-industry';
import { EventMappingsTree } from 'src/services/analytics/types';

const MAPPING: EventMappingsTree = {
  '/orgs/*/welcome/funding-sources/bank/account': {
    'page.view': ['qbo-account', 'select'],
    'go.exit': ['qbo-account', 'go-exit'],
    'option-changed-intuitAccountId': ['qbo-account', 'changed-to', { qboAccountId: 'option.value' }],
    'qbo-account-connected': [
      'qbo-account',
      'payment-account-connected',
      { accountType: 'type', fundingSourceId: 'fundingSourceId' },
    ],
    'form-validation-error': ['qbo-account', 'connect-error', { error: 'validationErrors' }],
  },
  '/orgs/*/welcome/funding-sources/bank/complete': {
    'page.view': ['add-funding-source', 'success'],
    'onboarding.fundingSources.bank.manually.buttonCTA': ['add-funding-source', 'continue'],
    'learn-more': ['add-funding-source-success', 'go-learn-more'],
  },
  '/orgs/*/welcome/funding-sources/bank/manually': {
    'page.view': ['add-funding-source', 'bank-manually'],
    'form-validation-error': [
      'bank-add-microdeposit',
      'bank-add-microdeposit-validation-error',
      { validationErrors: 'validationErrors' },
    ],
    'go.exit': ['bank-add-microdeposit', 'funding-source-added-manually-close'],
    'funding-source-added-manually': ['bank-add-microdeposit', 'funding-source-added-manually'],
    'funding-source-added-manually-success': ['bank-add-microdeposit', 'funding-source-added-manually-success'],
  },
  '/orgs/*/welcome/funding-source/card/*': {
    ...amexGuestEventMapping,
  },
};

export default MAPPING;
