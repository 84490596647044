import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { checkBankAccount } from 'src/pages/vendor/delivery-methods/utils';
import { AccountType, BankType } from 'src/utils/types';

export const getValidationsError = (
  bankAccount: BankType | null,
  isGetPaidVendor: boolean,
  userFundingSources: readonly AccountType[] = []
) => {
  let validationErrors = bankAccount ? getValidationErrors('deliveryMethodAch', bankAccount) : {};

  if (bankAccount && isValidationOk(validationErrors) && !isGetPaidVendor) {
    validationErrors = checkBankAccount(bankAccount, userFundingSources);
  }

  return validationErrors;
};
