import { createBatchDeleteSlice } from 'src/helpers/redux/restBatchDeleteSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { PaymentRequestType } from 'src/utils/types';
import { name } from '../consts';

export const proRequestsBatchCancelSlice = createBatchDeleteSlice<PaymentRequestType>({
  storeName: name,
  api: getProApi.requests.batchCancel,
  actionName: 'proBatchCancel',
});
