import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { InvoiceType } from 'src/modules/invoices/types';
import { InvoicesActions, RequestsActions } from 'src/pages/get-pro/consts';
import useGetProMultiItemsActions from 'src/pages/get-pro/hooks/useGetProMultiItemsActions';
import { isAccountingSoftwareItem, MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';
import { ActionButtonColor, ActionOptionType } from '../components/types';

const RED_ACTIONS = [InvoicesActions.REMOVE, RequestsActions.CANCEL];

const getColorByAction = (action: InvoicesActions | RequestsActions): ActionButtonColor => {
  if (RED_ACTIONS.includes(action)) {
    return 'red.500';
  }

  return 'black';
};

const buildOptions = (action: InvoicesActions | RequestsActions, labelPrefix: string) => ({
  option: <MIFormattedText label={`${labelPrefix}.${action}`} />,
  value: action,
  label: `${labelPrefix}.${action}`,
  color: getColorByAction(action),
});

function hasSyncedItems(items: InvoiceType[]) {
  return items.some((entity) => entity.createOrigin && isAccountingSoftwareItem(entity.createOrigin));
}

function arrayIdsToObjectKeys(acc: Record<number, boolean>, ids: string) {
  acc[+ids] = true;

  return acc;
}

type ActionOptionsByTypeProps = {
  type: GetProTabs;
  ids: string[];
  clearSelectedIds: () => void;
};

export const useActionOptionsByType = ({ type, ids, clearSelectedIds }: ActionOptionsByTypeProps) => {
  const { markAsPaidInvoices, removeInvoices, markAsPaidRequests, cancelRequests } = useGetProMultiItemsActions();
  const store = MAP_STORE_BY_TAB[type];
  const batchEntities: InvoiceType[] = useSelector(store.selectors.batchItems(ids.reduce(arrayIdsToObjectKeys, {})));
  const shouldFilterMarkAsPaid = hasSyncedItems(batchEntities);
  const ActionsOptionsByType = {
    [GetProTabs.INVOICES]: [
      ...(shouldFilterMarkAsPaid
        ? []
        : [
            {
              ...buildOptions(InvoicesActions.MARK_AS_PAID, 'getPro.table.actions.invoices'),
              action: () => markAsPaidInvoices(ids, clearSelectedIds),
            },
          ]),
      {
        ...buildOptions(InvoicesActions.REMOVE, 'getPro.table.actions.invoices'),
        action: () => removeInvoices(ids),
      },
    ],
    [GetProTabs.REQUESTS]: [
      ...(shouldFilterMarkAsPaid
        ? []
        : [
            {
              ...buildOptions(RequestsActions.MARK_AS_PAID, 'getPro.table.actions.requests'),
              action: () => markAsPaidRequests(ids, clearSelectedIds),
            },
          ]),
      {
        ...buildOptions(RequestsActions.CANCEL, 'getPro.table.actions.requests'),
        action: () => cancelRequests(ids),
      },
    ],
    [GetProTabs.IN_PROGRESS]: [],
    [GetProTabs.PAID]: [],
  };

  return useMemo<ActionOptionType[]>(() => ActionsOptionsByType[type], [type, ids, shouldFilterMarkAsPaid]);
};
