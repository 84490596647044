import { useEffect, useState } from 'react';
import { InProgressStatusFilters } from 'src/pages/get-pro/consts';
import { GetProDateFilters } from 'src/pages/get-pro/types';
import { GetProTabs } from 'src/utils/consts';
import { useQueryState } from 'src/utils/hooks';
import { INITIAL_DATE_FILTER_STATE, INITIAL_STATUS_FILTER_STATE } from './utils';

type FiltersState = {
  selectedDateFilter: GetProDateFilters;
  selectedStatusesFilter: Array<InProgressStatusFilters>;
};

export const useFiltersState = (tab = GetProTabs.INVOICES): FiltersState => {
  const [queryFilter] = useQueryState('filter', undefined);
  const [queryStatuses] = useQueryState('statuses', undefined);
  const [querySearch] = useQueryState('search', undefined);
  const [selectedDateFilterByTab, setSelectedDateFilterByTab] = useState(INITIAL_DATE_FILTER_STATE);
  const [selectedStatusesFilterByTab, setSelectedStatusesFilterByTab] = useState(INITIAL_STATUS_FILTER_STATE);
  const selectedDateFilter = selectedDateFilterByTab[tab];
  const selectedStatusesFilter = selectedStatusesFilterByTab[tab];

  useEffect(() => {
    setSelectedDateFilterByTab((prevState) => ({
      ...prevState,
      [tab]: queryFilter,
    }));
  }, [queryFilter]);

  useEffect(() => {
    const queryStatusesArray = queryStatuses?.split(',') ?? [];

    setSelectedStatusesFilterByTab((prevState) => ({
      ...prevState,
      [tab]: queryStatusesArray,
    }));
  }, [queryStatuses]);

  useEffect(() => {
    if (querySearch) {
      setSelectedDateFilterByTab(INITIAL_DATE_FILTER_STATE);
      setSelectedStatusesFilterByTab(INITIAL_STATUS_FILTER_STATE);
    }
  }, [querySearch]);

  return {
    selectedDateFilter,
    selectedStatusesFilter,
  };
};
