import styled, { css } from 'styled-components';
import { devices } from 'src/theme/appDevices';

export const List = styled.div`
  @media ${devices.nonMobile} {
    min-width: 440px;
  }
  max-height: 25rem;
  overflow: scroll;
`;

export const InputContainer = styled.div`
  input[type='search']::-webkit-search-cancel-button {
    /* Remove default */
    -webkit-appearance: none;
  }
  input[type='search'] {
    -webkit-border-radius: 0;
    border-radius: 0;
    box-shadow: 0;
    -webkit-appearance: none;
  }
`;

export const iconStyles = css`
  position: absolute;
  z-index: 2;
  display: block;
  line-height: 3.8rem;
  text-align: center;
  color: ${(props) => props.theme.colors.dark.translucent3};
  ${(props) => props.theme?.components?.MIAutocomplete?.IconStyles}
`;

export const Sublabel = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
`;

export const Highlight = styled.span`
  background-color: ${(props) => props.theme.colors.primary.translucent2};
`;
