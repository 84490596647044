import { ReactNode } from 'react';
import { Avatar } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { InitialsBadgeFirm } from 'src/pages/team/team-management/InitialsBadgeFirm';

export type ExtraDropdownOptionType = {
  id: string;
  label: string;
  color: string;
  link?: string;
  companyType?: 'accounting-firm' | 'smb';
};

type Props = {
  item: ExtraDropdownOptionType;
  selected: boolean;
  currentItem?: boolean;
  className?: string;
};

type RowRootProps = {
  children?: ReactNode;
  currentItem?: boolean;
  className?: string;
};

type RowLogoProps = {
  children?: ReactNode;
  selected?: boolean;
};

type RowNameProps = {
  children?: ReactNode;
};

export const MIExtraDropdownItem = ({ item, selected, currentItem, className }: Props) => (
  <RowRoot currentItem={currentItem} className={className}>
    <RowLogo selected={selected}>
      {item.companyType === 'accounting-firm' ? (
        <InitialsBadgeFirm name={item.label} link={item.link} />
      ) : (
        <Avatar name={item.label} bg={item.color} link={item.link} />
      )}
    </RowLogo>
    <RowName>{item.label}</RowName>
  </RowRoot>
);

const RowRoot = ({ currentItem, className, children }: RowRootProps) => (
  <Flex
    className={className}
    bgColor="transparent"
    _hover={{
      bgColor: currentItem ? 'grey.200' : undefined,
    }}
    alignItems="center"
    boxSizing="border-box"
    py={1}
    px={0}
  >
    {children}
  </Flex>
);

const RowLogo = ({ selected, children }: RowLogoProps) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    boxSizing="border-box"
    w="3.2rem"
    h="3.2rem"
    borderRadius="full"
    boxShadow={selected ? '0 0 0 0.2rem #7849FF inset, 0 0 0 0.4rem #FFF inset' : 'none'}
  >
    {children}
  </Flex>
);

const RowName = ({ children }: RowNameProps) => (
  <Box maxW="17.8rem" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" pl={2} textStyle="body4">
    {children}
  </Box>
);
