import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useLocationState<T>(name: string): [T | undefined, (value: T) => void];
export function useLocationState<T>(name: string, defaultValue: null): [T | null, (value: T | null) => void];
export function useLocationState<T>(name: string, defaultValue: T): [T, (value: T) => void];
export function useLocationState<T>(name: string, defaultValue?: T) {
  const location = useLocation<T>();
  const history = useHistory();
  const locationState = (location.state as unknown) as Record<string, T> | undefined;
  const value: T | undefined = (locationState?.[name] === undefined ? defaultValue : locationState?.[name]) as
    | T
    | undefined;
  const setValue = useCallback(
    (value) => {
      const state = { ...location.state, [name]: value };
      history.push({ ...location, state });
    },
    [location, history, name]
  );

  return [value, setValue];
}
