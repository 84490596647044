import { MouseEvent as ReactMouseEvent, useMemo } from 'react';
import { useForm } from 'src/ui/form';
import { ApprovalWorkflowType } from 'src/utils/types';
import { ApprovalWorkflowModalMessage } from './components/ApprovalWorkflowModalMessage';
import { ApprovalWorkflowUserType, WorkflowModelType } from './types';
import {
  calcMaxApprovers,
  dbWorkflowToWorkflowModel,
  filterRelevantPaymentCreatorsAndSort,
  newWorkflowModel,
  toPaymentCreatorsOptions,
  workflowModelToDBWorkflow,
} from './utils';
import { getApprovalWorkflowFormValidator } from './validator';

type Props = {
  companyName: string;
  onSubmit: (value: ApprovalWorkflowType) => void;
  dismiss: (event: ReactMouseEvent | null) => void;
  orgUsers: ApprovalWorkflowUserType[];
  orgId: number;
  dbWorkflow?: ApprovalWorkflowType;
  maxThreshold?: number;
};

export const ApprovalWorkflowModal = ({
  dismiss,
  onSubmit,
  dbWorkflow,
  companyName,
  orgUsers,
  orgId,
  maxThreshold,
}: Props) => {
  const orgUsersPaymentCreators = toPaymentCreatorsOptions(orgUsers, orgId, dbWorkflow);
  const sortedPaymentCreatorsOptions = filterRelevantPaymentCreatorsAndSort(orgUsersPaymentCreators);
  const maxApprovers = calcMaxApprovers(orgUsers, orgId);
  const isEditingWorkflow = dbWorkflow;

  const workflowModel = useMemo<WorkflowModelType>(
    () =>
      dbWorkflow
        ? dbWorkflowToWorkflowModel(dbWorkflow, sortedPaymentCreatorsOptions)
        : newWorkflowModel(sortedPaymentCreatorsOptions, maxApprovers),
    [dbWorkflow]
  );

  const [workflowModelView, { submit }] = useForm<WorkflowModelType>(workflowModel, {
    submit: async (workflow) => {
      const newWorkflow = workflowModelToDBWorkflow(workflow);
      await onSubmit(newWorkflow);
      !isEditingWorkflow && dismiss(null);
    },
    validator: getApprovalWorkflowFormValidator(maxThreshold),
  });

  return (
    <ApprovalWorkflowModalMessage
      modalMode={dbWorkflow ? 'edit' : 'create'}
      workflowModelView={workflowModelView}
      companyName={companyName}
      dismiss={dismiss}
      onSubmit={submit}
      maxApprovers={maxApprovers}
    />
  );
};
