import { IneligibilityReason } from 'src/modules/financing/types';

type ApplicationSteps = 'ownerDecision' | 'businessDetails' | 'businessOwnershipDetails' | 'reviewingDetails';
type ScheduleSteps = 'repaymentTerms';

export type Steps = ApplicationSteps | ScheduleSteps;

export enum FinancingEligibilityStatus {
  Eligible = 'eligible',
  Ineligible = 'ineligible',
  Pending = 'pending',
  Blocked = 'blocked',
  Unknown = 'unknown',
}

export type InstallmentOption = {
  paymentAmount: number;
  feeAmount: number;
  feePercent: number;
  installmentAmount: number;
  numberOfInstallments: number;
};

export type GetInstallmentsOptionsResponse = {
  decision:
    | FinancingEligibilityStatus.Eligible
    | FinancingEligibilityStatus.Ineligible
    | FinancingEligibilityStatus.Pending
    | FinancingEligibilityStatus.Blocked;
  reason?: IneligibilityReason;
  intentId?: string;
  options?: InstallmentOption[];
};

export type ApplicationDecisionStatus =
  | FinancingEligibilityStatus.Eligible
  | FinancingEligibilityStatus.Ineligible
  | FinancingEligibilityStatus.Pending;
export type ApplicationResponse = {
  decision: ApplicationDecisionStatus;
  reason?: string;
};

export type SetInstallmentsOptionsResponse = {
  totalAmount: number;
  totalFees: number;
  totalFeePercent: number;
  installments: {
    scheduledDate: string;
    amount: number;
    status: 'draft';
  }[];
  agreement: string;
};

export enum ApplicationErrorType {
  Ineligible = 'Ineligible',
  Pending = 'Pending',
  AmountIneligible = 'AmountIneligible',
  General = 'General',
}
