import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { billLocations } from 'src/pages/bill/locations';
import { fireInternationalAnalyticsOnSubmit } from 'src/pages/vendor/international-delivery-method/event-mapping';
import { selectNewDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { DeliveryType, NotificationVariant } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { MakeCompulsory } from 'src/utils/utility-types';
import { getIsComingFromPayBillFlow, mapDataToInternationalAccount, separateWordsAndCapitalize } from '../utils';
import { InternationalWizardState } from './useInternationalWizard';

export type Props = {
  vendorId: number;
};

export const useInternationalMethodCreation = ({ vendorId }: Props) => {
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const dispatch = useDispatch();
  const [redirectUrl] = useLocationState<string>('redirectUrl');
  const [billId] = useLocationState<string>('billId');
  const [origin] = useLocationState<string>('origin');
  const isComingFromPayBillFlow = origin && getIsComingFromPayBillFlow(origin);
  const { createInternationalDeliveryMethodSlice } = useStoreActions(deliveryMethodsStore);

  const getErrorMsgArray = (error: any) => {
    let msgArray = [];

    if (error?.responseData) {
      msgArray = Array.isArray(error.responseData) ? error.responseData : [error.responseData];
    }

    if (error?.error?.errorData) {
      msgArray = Array.isArray(error.error.errorData) ? error.error.errorData : [error.error.errorData];
    }

    if (msgArray.length) {
      return msgArray.map((msg) =>
        msg ? separateWordsAndCapitalize(msg) : 'vendors.deliveryMethods.international.generalError'
      );
    }

    return ['vendors.deliveryMethods.international.generalError'];
  };

  const { onApiCall: createMethod, loading: isCreating } = useApi({
    api: useCallback(
      async ({
        shouldRedirectToAddBill,
        ...wizardState
      }: InternationalWizardState & {
        shouldRedirectToAddBill?: boolean;
      }) => {
        const redirectUrlAfterSubmit = shouldRedirectToAddBill
          ? `${generatePath(billLocations.create.index, {
              orgId,
            })}?vendorId=${vendorId}&manually=true`
          : redirectUrl;
        try {
          const internationalAccount = mapDataToInternationalAccount({
            wizardState: wizardState as MakeCompulsory<InternationalWizardState, keyof InternationalWizardState>,
          });
          const internationalDeliveryMethod = await createInternationalDeliveryMethodSlice({
            orgId,
            vendorId,
            params: internationalAccount,
          });

          if (isComingFromPayBillFlow) {
            await dispatch(
              selectNewDeliveryMethodAction({
                ...internationalDeliveryMethod.payload,
                deliveryType: DeliveryType.INTERNATIONAL,
              })
            );
          }

          fireInternationalAnalyticsOnSubmit();
          history.push(redirectUrlAfterSubmit!, {
            billId,
          });

          return null;
        } catch (error: any) {
          const msgArray = getErrorMsgArray(error);
          analytics.trackAction('international-delivery-method-failure', {
            errors: msgArray,
          });
          msgArray.forEach((errorText) => {
            pushNotification({
              type: NotificationVariant.ERROR,
              msg: errorText,
            });
          });

          return error;
        }
      },
      []
    ),
  });

  return [createMethod, isCreating] as const;
};
