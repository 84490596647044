import noop from 'lodash/noop';
import { RefObject } from 'react';
import * as React from 'react';
import { ListSearchBarContextType } from 'src/utils/types';

type Props = {
  children: React.ReactNode;
};

type State = {
  contextSearchInputValue: string;
  contextSearchFilterValue: string;
};

export const ListSearchBarContext = React.createContext<ListSearchBarContextType>({
  contextSearchInputValue: '',
  contextSearchFilterValue: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setContextSearchInputValue: (value?: string) => noop(),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setContextSearchFilterValue: (value?: string) => noop(),
  clearSearchContext: () => noop(),
  focusAndSelectSearch: () => noop(),
  searchInputRef: undefined,
});

class ListSearchBarContextProvider extends React.Component<Props, State> {
  static defaultProps = {};

  searchInputRef: RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      contextSearchInputValue: '',
      contextSearchFilterValue: '',
    };
    this.searchInputRef = React.createRef();
  }

  setContextSearchInputValue = (contextSearchInputValue = '') => {
    this.setState({ contextSearchInputValue });
  };

  setContextSearchFilterValue = (contextSearchFilterValue = '') => {
    this.setState({
      contextSearchFilterValue,
      contextSearchInputValue: contextSearchFilterValue,
    });
  };

  clearSearchContext = () => {
    this.setState({
      contextSearchInputValue: '',
      contextSearchFilterValue: '',
    });
  };

  focusAndSelectSearch = () => {
    if (this?.searchInputRef?.current) {
      this.searchInputRef.current.focus();
      this.searchInputRef.current.select();
    }
  };

  render() {
    const { contextSearchInputValue, contextSearchFilterValue } = this.state;

    return (
      <ListSearchBarContext.Provider
        value={{
          contextSearchInputValue,
          contextSearchFilterValue,
          setContextSearchInputValue: this.setContextSearchInputValue,
          setContextSearchFilterValue: this.setContextSearchFilterValue,
          clearSearchContext: this.clearSearchContext,
          focusAndSelectSearch: this.focusAndSelectSearch,
          searchInputRef: this.searchInputRef,
        }}
      >
        {this.props.children}
      </ListSearchBarContext.Provider>
    );
  }
}

export default ListSearchBarContextProvider;
