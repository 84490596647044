import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  BlockTitleContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

export const RepaymentCreditKey = () => (
  <>
    <BlockTitleContainer>
      <MIFormattedText label="bills.pay.confirm.repayment.deliveryTitle" />
    </BlockTitleContainer>
    <Box py={5} data-testid="repayment-credit-key-container">
      <PaymentInfoContainer>
        <PaymentIcon>
          <Icon name={IconNames.bankOut} size={IconSize.lg} />
        </PaymentIcon>
        <PaymentTextContainer>
          <PaymentMethodContainer>
            <PaymentMethodText>
              <MIFormattedText label="bills.pay.confirm.repayment.deliverySubtitle" />
            </PaymentMethodText>
          </PaymentMethodContainer>
          <PaymentInfoText>
            <MIFormattedText label="bills.pay.confirm.repayment.deliveryDescription" />
          </PaymentInfoText>
        </PaymentTextContainer>
      </PaymentInfoContainer>
    </Box>
  </>
);
