import { memo } from 'react';
import { EmptyList } from 'src/components/list/EmptyList';
import NewEmptyList from 'src/components/list/NewEmptyList';
import { InvoiceType } from 'src/modules/invoices/types';
import { useQueryString } from 'src/utils/hooks';
import { getSelectedTab } from '../utils';
import useEmptyStateParams from './hooks/useEmptyStateParams';

type Props = {
  showVerifyMdIndicator?: boolean;
  totalInvoices: InvoiceType[];
};

const EmptyState = memo(({ showVerifyMdIndicator, totalInvoices }: Props) => {
  const query = useQueryString();
  const selectedTab = getSelectedTab(query);
  const first = totalInvoices.length === 0 ? 'first' : 'notFirst';
  const { text, title, ctaLabel, ctaAction, icon } = useEmptyStateParams({
    selectedTab,
    first,
  });

  if (ctaAction && ctaLabel && icon) {
    return <NewEmptyList iconSrc={icon} title={title} text={text} ctaLabel={ctaLabel} ctaAction={ctaAction} />;
  }

  return <EmptyList text={text} showVerifyMdIndicator={showVerifyMdIndicator} />;
});

export default EmptyState;
