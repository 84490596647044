import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useModal } from 'src/helpers/react/useModal';
import { Status } from 'src/modules/payments/batch-set-approval-decision.slice';
import { usePayListItems } from 'src/pages/pay/hooks/usePayListItems';
import { useQueryParams } from 'src/pages/pay/hooks/useQueryParams';
import { useSelectedIds } from 'src/pages/regular-batch-payments/BatchSettings/useBatchSelection';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { deserializePaymentId } from 'src/utils/bills';
import { NotificationVariant } from 'src/utils/consts';
import { PAGE_EVENT } from '../SelectedItemsGallery';
import { DeclineReasonModalContent } from './DeclineReasonModalContent';
import { Footer } from './Footer';
import { useBatchSetApprovalDecision } from './useBatchSetApprovalDecision';

function showApprovalSuccessMessage(numOfPayments: number) {
  pushNotification({
    type: NotificationVariant.SUCCESS,
    msg: 'paymentBatchActions.notification.batchApprovedSuccess',
    textValues: { numOfPayments },
  });
}

function showDeclineSuccessMessage(numOfPayments: number) {
  pushNotification({
    type: NotificationVariant.SUCCESS,
    msg: 'paymentBatchActions.notification.batchDeclinedSuccess',
    textValues: { numOfPayments },
  });
}

function useClearSelectedIdsAndCurrentPayment() {
  const { replace } = useQueryParams<{ id: undefined; selectedIds: [] }>();

  return () =>
    replace({
      id: undefined,
      selectedIds: [],
    });
}

export const PaymentBatchActionsFooter: React.FC = () => {
  const { selectedIds } = useSelectedIds();
  const clearSelections = useClearSelectedIdsAndCurrentPayment();

  const paymentIds = selectedIds.map((id) => deserializePaymentId(id).paymentId);
  const { loadItems: reloadItemsList } = usePayListItems();
  const { approve, decline, status } = useBatchSetApprovalDecision(paymentIds);
  const billsCount = selectedIds.length;
  const [DeclineReasonModal, openDeclineReasonModal] = useModal(DeclineReasonModalContent, {
    modalName: 'batch-decline-modal',
    numOfPayments: paymentIds.length,
    async onDismiss(reason) {
      if (reason !== null) {
        const statuses = await decline(reason);

        if (statuses) {
          clearSelections();
          await reloadItemsList();
          showDeclineSuccessMessage(statuses.length ?? 0);
        }
      }
    },
  });

  const isLoading = status === Status.LOADING;

  async function onApproveClick() {
    analytics.track(PAGE_EVENT, 'batch-actions_approve', {
      'number-of-approved-payments': billsCount,
    });
    const statuses = await approve();

    if (statuses) {
      showApprovalSuccessMessage(statuses.length);
      clearSelections();
      await reloadItemsList();
    }
  }

  function onDeclineClick() {
    analytics.track(PAGE_EVENT, 'batch-actions_approve', {
      'batch-actions_decline': billsCount,
    });
    openDeclineReasonModal();
  }

  return (
    <>
      <Footer>
        <Flex gap="15px">
          <Button
            isDisabled={isLoading}
            data-testid="batch-decline-cta"
            label="regularBatchPayments.BillsGallery.Footer.decline"
            values={{ billsCount }}
            variant={ButtonVariants.secondary}
            size={ButtonSizes.md}
            onClick={onDeclineClick}
          />
          <Button
            isDisabled={isLoading}
            data-testid="batch-approve-cta"
            label="regularBatchPayments.BillsGallery.Footer.approve"
            values={{ billsCount }}
            variant={ButtonVariants.primary}
            size={ButtonSizes.md}
            onClick={onApproveClick}
          />
        </Flex>
      </Footer>
      {DeclineReasonModal}
    </>
  );
};
