import { CSSObject } from '@chakra-ui/react';

const backgroundColors = {
  list: 'transparent',
  single: 'transparent',
  wizard: 'grey.200',
  melioMe: 'grey.200',
};

export const getAreaLoaderStyle = (placement: string): CSSObject => ({
  w: 'full',
  h: 'full',
  pos: 'absolute',
  left: 0,
  top: placement === 'list' ? '30vh' : 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
  opacity: placement === 'melioMe' ? '0.9' : 1,
  bg: backgroundColors[placement],
});
