import { CandidateDetails, SUVCCandidateDetails } from 'src/pages/vendor/msn-candidates/types';
import { fetchRequest, postRequest } from 'src/services/api/api';

const buildURL = (path: string): string => `/msn-vendor${path}`;

export const msnPortalApi = {
  registerOrganizationToMSN({ orgId }: { orgId: number }) {
    const url = `/orgs/${orgId}/msn-vendor/register`;

    return postRequest(url);
  },
  createOrganization(userId) {
    const url = buildURL('/create');

    return postRequest(url, { userId });
  },
  getMSNVerificationStatus({ orgId }: { orgId: number }) {
    const url = `/orgs/${orgId}/msn-vendor/verification-status`;

    return fetchRequest(url);
  },

  getMSNVerificationStatusWithToken({ token }: { token: string }) {
    const url = buildURL(`/verification-status-with-token/${token}`);

    return fetchRequest(url);
  },

  submitCandidateForm: (token: string, params: CandidateDetails | SUVCCandidateDetails) => {
    const url = buildURL(`/submit-candidate-with-token?token=${token}`);

    return postRequest(url, params);
  },

  getMSNCandidateByToken: (token: string) => {
    const url = buildURL('/get-candidate');

    return fetchRequest(url, { token });
  },

  createMicroDeposits: (vendorId: number, orgId: number): Promise<void> => {
    const url = `/orgs/${orgId}/vendors/${vendorId}/create-micro-deposits`;

    return postRequest(url);
  },
  getClaimAccountTokenValidationStatus({
    token,
  }: {
    token: string;
  }): Promise<{ isTokenValid: boolean; isGuest: boolean; email?: string; organizationId?: string }> {
    const url = buildURL(`/claim-account-token-validation/${token}`);

    return fetchRequest(url);
  },
};
