import * as Sentry from '@sentry/browser';
import { Record } from 'immutable';
import get from 'lodash/get';
import { userContextFactory, userPreferencesFactory } from 'src/context/records';
import { switchAccountingFirmSlice } from 'src/modules/organizations/organizations-slice';
import convertLocalVendorToOwned from 'src/modules/vendors/convert-local-vendor-to-owned';
import { companyInfoFactory } from 'src/pages/settings/records';
import { VerifyFundingSourceMicroDepositsErrorCodes } from 'src/utils/consts';
import { AccountType, BankType } from 'src/utils/types';
import {
  CHECK_AND_INIT_USER_FINISH,
  CLEAR_STATE,
  CLEAR_USER_INFO_FINISH,
  DELETE_DELIVERY_METHOD,
  DELETE_DELIVERY_METHOD_FAILED,
  DELETE_DELIVERY_METHOD_SUCCESS,
  DELETE_FUNDING_SOURCE,
  DELETE_FUNDING_SOURCE_FAILED,
  DELETE_FUNDING_SOURCE_SUCCESS,
  INIT_USER_SUCCESS,
  LOAD_COMPANY_INFO,
  LOAD_COMPANY_INFO_FAILED,
  LOAD_COMPANY_INFO_SUCCESS,
  LOAD_COMPANY_LOGO_FAILED,
  LOAD_COMPANY_LOGO_SUCCESS,
  LOAD_DELIVERY_METHODS,
  LOAD_DELIVERY_METHODS_FAILED,
  LOAD_DELIVERY_METHODS_SUCCESS,
  LOAD_FUNDING_SOURCES,
  LOAD_FUNDING_SOURCES_FAILED,
  LOAD_FUNDING_SOURCES_SUCCESS,
  LOAD_PROFILE_SUCCESS,
  REMOVE_FUNDING_SOURCE_LABEL,
  REMOVE_FUNDING_SOURCE_LABEL_FAILED,
  REMOVE_FUNDING_SOURCE_LABEL_SUCCESS,
  SET_COMPANY_INFO,
  SET_PROFILE,
  SET_USER_PREFERENCES,
  UPDATE_COMPANY_INFO_SUCCESS,
  UPDATE_FUNDING_SOURCE_LABEL,
  UPDATE_FUNDING_SOURCE_LABEL_FAILED,
  UPDATE_FUNDING_SOURCE_LABEL_SUCCESS,
  UPDATE_ORIGIN_PLAID_ITEM_ID,
  UPDATE_USER_PREFERENCE_SUCCESS,
  VERIFY_FUNDING_SOURCE,
  VERIFY_FUNDING_SOURCE_FAILED,
  VERIFY_FUNDING_SOURCE_SUCCESS,
} from './actionTypes';
import { UserState } from './types';

export const initialState: UserState = {
  fundingSources: [],
  // This is _fine_. Making `profile` nullable causes more headache than it's worth, and the profile will be non-nullable most of the time, since
  // it's set after the login/signup.
  profile: userContextFactory(),
  deliveryMethods: [],
  companyInfo: companyInfoFactory(),
  userPreferences: userPreferencesFactory(),
  isLoading: false,
  isLoggedIn: false,
  isLoggedInAs: false,
  isUserChecked: false,
  isFundingSourceVerifying: false,
  isFundingSourceDeleting: false,
  isSavingFundingSourceLabel: false,
  isRemovingFundingSourceLabel: false,
  originPlaidItemId: null,
};

const userReducer = (state: UserState = initialState, action: any): UserState => {
  switch (action.type) {
    case LOAD_FUNDING_SOURCES:
      return { ...state, isLoading: true };
    case LOAD_FUNDING_SOURCES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        fundingSources: action.fundingSources,
      };
    case LOAD_FUNDING_SOURCES_FAILED:
      return { ...state, isLoading: false, fundingSources: [] };
    case DELETE_FUNDING_SOURCE:
      return { ...state, isFundingSourceDeleting: true };
    case DELETE_FUNDING_SOURCE_FAILED:
      return { ...state, isFundingSourceDeleting: false };
    case DELETE_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        isFundingSourceDeleting: false,
        fundingSources: state.fundingSources.filter(({ id }) => id !== action.deletedFundingSourceId),
      };
    case LOAD_PROFILE_SUCCESS:
    case SET_PROFILE:
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: get(action.profile, 'email', ''),
          id: get(action.profile, 'orgId', ''),
        });
      });

      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.profile,
        },
      };
    case CLEAR_USER_INFO_FINISH:
      return { ...initialState, isUserChecked: true };
    case VERIFY_FUNDING_SOURCE:
      return { ...state, isFundingSourceVerifying: true };
    case VERIFY_FUNDING_SOURCE_FAILED:
      // eslint-disable-next-line no-case-declarations
      let updatedFundingSources = state.fundingSources;

      if (action.errorCode === VerifyFundingSourceMicroDepositsErrorCodes.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS) {
        updatedFundingSources = state.fundingSources.map((fundingSource) => {
          if (fundingSource.id === action.verifiedFundingSourceId && fundingSource.bankAccount) {
            const bankAccount: BankType = {
              ...fundingSource.bankAccount,
              isBlocked: true,
            };

            return { ...fundingSource, bankAccount };
          }

          return fundingSource;
        });
      }

      return {
        ...state,
        isFundingSourceVerifying: false,
        fundingSources: updatedFundingSources,
      };
    case VERIFY_FUNDING_SOURCE_SUCCESS:
      return {
        ...state,
        isFundingSourceVerifying: false,
        fundingSources: state.fundingSources.map((fundingSource) => {
          const fs = Record.isRecord(fundingSource) ? (fundingSource.toJS() as AccountType) : fundingSource;

          if (fs.id === action.verifiedFundingSourceId) {
            return { ...fs, isVerified: true };
          }

          return fs;
        }),
      };
    case LOAD_DELIVERY_METHODS:
      return { ...state, isLoading: true };
    case LOAD_DELIVERY_METHODS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliveryMethods: action.deliveryMethods,
      };
    case LOAD_DELIVERY_METHODS_FAILED:
      return { ...state, isLoading: false, deliveryMethods: [] };
    case DELETE_DELIVERY_METHOD:
      return { ...state, isLoading: true };
    case DELETE_DELIVERY_METHOD_FAILED:
      return { ...state, isLoading: false };
    case DELETE_DELIVERY_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliveryMethods: state.deliveryMethods.filter(({ id }) => id !== action.deletedDeliveryMethodId),
      };
    case UPDATE_FUNDING_SOURCE_LABEL:
      return { ...state, isSavingFundingSourceLabel: true };
    case UPDATE_FUNDING_SOURCE_LABEL_FAILED:
      return { ...state, isSavingFundingSourceLabel: false };
    case UPDATE_FUNDING_SOURCE_LABEL_SUCCESS:
      return {
        ...state,
        isSavingFundingSourceLabel: false,
        fundingSources: [
          ...state.fundingSources.map((fundingSource) => {
            if (fundingSource.id === action.updatedLabelFundingSource?.id) {
              return { ...fundingSource, nickname: action.updatedLabelFundingSource.nickname };
            }

            return fundingSource;
          }),
        ],
      };
    case REMOVE_FUNDING_SOURCE_LABEL:
      return { ...state, isRemovingFundingSourceLabel: true };
    case REMOVE_FUNDING_SOURCE_LABEL_FAILED:
      return { ...state, isRemovingFundingSourceLabel: false };
    case REMOVE_FUNDING_SOURCE_LABEL_SUCCESS:
      return {
        ...state,
        isRemovingFundingSourceLabel: false,
        fundingSources: [
          ...state.fundingSources.map((fundingSource) => {
            if (fundingSource.id === action.removedLabelFundingSource?.id) {
              return { ...fundingSource, nickname: null };
            }

            return fundingSource;
          }),
        ],
      };
    case LOAD_COMPANY_INFO:
      return { ...state, isLoading: true };
    case LOAD_COMPANY_INFO_SUCCESS:
    case SET_COMPANY_INFO:
      return { ...state, isLoading: false, companyInfo: action.companyInfo };
    case LOAD_COMPANY_INFO_FAILED:
      return { ...state, isLoading: false, companyInfo: companyInfoFactory() };
    case LOAD_COMPANY_LOGO_SUCCESS:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          logoUrl: action.logoUrl,
        },
      };
    case LOAD_COMPANY_LOGO_FAILED:
      return {
        ...state,
        companyInfo: {
          ...state.companyInfo,
          logoUrl: null,
        },
      };
    case UPDATE_USER_PREFERENCE_SUCCESS:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          [action.id]: action.value,
        },
        profile: {
          ...state.profile,
          userPreferences: {
            ...state.profile?.userPreferences,
            [action.id]: action.value,
          },
        },
      };
    case SET_USER_PREFERENCES:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          ...action.userPreferences,
        },
      };
    case INIT_USER_SUCCESS:
      return { ...state, isLoggedIn: true, isLoggedInAs: action.isLoggedInAs };
    case CHECK_AND_INIT_USER_FINISH:
      return { ...state, isUserChecked: true };
    case UPDATE_ORIGIN_PLAID_ITEM_ID:
      return { ...state, originPlaidItemId: action.id };
    case UPDATE_COMPANY_INFO_SUCCESS:
      if (state.companyInfo.id === action.payload.id) {
        return {
          ...state,
          companyInfo: { ...state.companyInfo, ...action.payload },
        };
      }

      return state;
    case convertLocalVendorToOwned.actions.success.type:
      return {
        ...state,
        companyInfo: { ...state.companyInfo, ownedVendorId: action.payload?.id },
      };
    case switchAccountingFirmSlice.actions.success.type: {
      const changedOrgs = [...action.payload.previousAccountingFirms, action.payload.newAccountingFirm];
      const currentOrgInChangedOrgs = changedOrgs.find((org) => org.id === state.companyInfo.id);

      if (currentOrgInChangedOrgs) {
        return {
          ...state,
          companyInfo: { ...state.companyInfo, companyType: currentOrgInChangedOrgs.companyType },
        };
      }

      return state;
    }
    case CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
};

export default userReducer;
