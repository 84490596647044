import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { IllustrationName } from 'src/core/ds/illustration';

export const VendorCandidatesSuccessPage = () => (
  <Box data-testid="vendors-candidates-success-page" h="full">
    <SuccessLayoutPage
      illustration={IllustrationName.checkCircle}
      title="vendors.candidates.success.title"
      text="vendors.candidates.success.details"
    />
  </Box>
);
