import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { financingStore } from 'src/modules/financing/financing-store';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import joinOrganizationRequests from 'src/modules/join-organization-requests/join-organization-requests-store';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';
import { accountingPlatformBankAccountsStore } from '../modules/accounting-platform-bank-accounts/accounting-platform-bank-accounts-store';
import { accountingPlatformsStore } from '../modules/accounting-platforms/accounting-platforms-store';
import { approvalWorkflowsStore } from '../modules/approval-workflows/approval-workflows-store';
import auth from '../modules/auth/auth-store';
import { billsStore } from '../modules/bills/bills-store';
import customers from '../modules/customers/customers-store';
import deliveryMethods from '../modules/delivery-methods/delivery-methods-store';
import error from '../modules/error/error-store';
import { getProStore } from '../modules/get-pro/get-pro-store';
import { getProInProgressPaymentsStore } from '../modules/get-pro-in-progress-payments/get-pro-in-progress-payments-store';
import { getProPaidPaymentsStore } from '../modules/get-pro-paid-payments/get-pro-paid-payments-store';
import invitations from '../modules/invitations/invitations-store';
import invoices from '../modules/invoices/invoices-store';
import { notificationPreferencesStore } from '../modules/notification-preferences/notification-preferences-store';
import organizations from '../modules/organizations/organizations-store';
import { paymentRequestsStore } from '../modules/payment-requests/payment-requests-store';
import { paymentsStore } from '../modules/payments/payment-store';
import { profileStore } from '../modules/profile/profile-store';
import regularBatchPaymentsStore from '../modules/regular-batch-payments/regular-batch-payments-store';
import { scannedInvoicesStore } from '../modules/scanned-invoices/store';
import users from '../modules/users/users-store';
import vendors from '../modules/vendors/vendors-store';
import payBillWizard from './payBillWizard/reducer';
import user from './user/reducer';

export default function rootReducer(history) {
  const reducers = combineReducers({
    payBillWizard,
    user,
    error: error.reducer,
    bills: billsStore.reducer,
    auth: auth.reducer,
    users: users.reducer,
    profile: profileStore.reducer,
    organizations: organizations.reducer,
    invitations: invitations.reducer,
    vendors: vendors.reducer,
    payments: paymentsStore.reducer,
    customers: customers.reducer,
    paymentRequests: paymentRequestsStore.reducer,
    deliveryMethods: deliveryMethods.reducer,
    joinOrganizationRequests: joinOrganizationRequests.reducer,
    invoices: invoices.reducer,
    fundingSources: fundingSourcesStore.reducer,
    accountingPlatforms: accountingPlatformsStore.reducer,
    getProInProgressPayments: getProInProgressPaymentsStore.reducer,
    getProPaidPayments: getProPaidPaymentsStore.reducer,
    regularBatchPayments: regularBatchPaymentsStore.reducer,
    router: connectRouter(history),
    accountingPlatformBankAccounts: accountingPlatformBankAccountsStore.reducer,
    getPro: getProStore.reducer,
    notificationPreferences: notificationPreferencesStore.reducer,
    approvalWorkflows: approvalWorkflowsStore.reducer,
    payListItems: payListItemsStore.reducer,
    scannedInvoices: scannedInvoicesStore.reducer,
    financing: financingStore.reducer,
  });

  return (state, action) => {
    try {
      return reducers(state, action);
    } catch (e) {
      throw e;
    }
  };
}
