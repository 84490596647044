/* eslint-disable no-template-curly-in-string, max-len */
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';
import addAccountingFirmMappings from './add-accounting-firm/events-mapping';

const pageEvent = 'companies';

const MAPPING: EventMappingsTree = {
  '/orgs/*/companies': {
    'page.view': [pageEvent, 'companies-dashboard'],
    'companies-dashboard-switch-org': [
      'companies-dashboard',
      'company-changed',
      { toOrgId: 'toOrgId', isHidden: 'isHidden' },
    ],
    'add-new-company': ['companies-dashboard', 'add-new-company'],
    resendDialog: ['companies-dashboard', 'resend-join-request-modal', { toggle: 'toggle' }],
    deleteDialog: ['companies-dashboard', 'delete-join-request-modal', { toggle: 'toggle' }],
    accountingFirmDialog: ['companies-dashboard', 'change-accounting-firm-modal', { toggle: 'toggle' }],
    sendSwitchAccountigFirmRequestDialog: [
      'companies-dashboard',
      'send-switch-accountig-firm-request-dialog',
      { toggle: 'toggle' },
    ],
    'form-validation-error': [pageEvent, 'validation-error', { validationsErrors: 'validationErrors' }],
    'companies.sendSwitchAccountigFirmRequestDialog.confirm': ['send-switch-accountig-firm-request-dialog', 'continue'],
    ...mapApiSlice('[JOINORGANIZATIONREQUESTS] DELETE', 'join-organization-request', 'delete'),
    ...mapApiSlice('[JOINORGANIZATIONREQUESTS] RESEND', 'join-organization-request', 'resend'),
    ...mapApiSlice('[ORGANIZATIONS] SWITCH_ACCOUNTING_FIRM', 'add-accounting-firm', 'switch-organization', {
      success: { newOrgId: 'payload.orgId' },
      failure: { newOrgId: 'payload.orgId' },
    }),
    'select-company-to-switch': ['accountant-firm-modal', 'select-company-to-switch', { newOrgId: 'newOrgId' }],
    'bookkeeper.company.accountingFirmDialog.dropDown.buttonLabel': [
      'accountant-firm-modal',
      'add-new-accounting-firm',
    ],
    'change-my-accounting-firm': ['it-is-not-my-accounting-firm', 'change'],
    'hide-client': ['clients-client_actions-hide_client', 'clicked', { hiddenOrganizationId: 'hiddenOrganizationId' }],
    'unhide-client': [
      'hidden_clients-client_actions-unhide_client',
      'clicked',
      { hiddenOrganizationId: 'hiddenOrganizationId' },
    ],
    'hide-client-modal-opened': [
      'clients-hide_client_modal',
      'opened',
      { hiddenOrganizationId: 'hiddenOrganizationId' },
    ],
    'hide-client-modal-dismissed': [
      'clients-hide_client_modal',
      'dismissed',
      { hiddenOrganizationId: 'hiddenOrganizationId' },
    ],
    'unhide-client-modal-opened': [
      'hidden_clients-unhide_client_modal',
      'opened',
      { hiddenOrganizationId: 'hiddenOrganizationId' },
    ],
    'unhide-client-modal-dismissed': [
      'hidden_clients-unhide_client_modal',
      'dismissed',
      { hiddenOrganizationId: 'hiddenOrganizationId' },
    ],
    'filter-button-click': ['clients-clients_toggle', 'clicked'],
    'active-filter-click': ['clients-active_clients_visibility_toggle', 'clicked', { clientsCount: 'clientsCount' }],
    'hidden-filter-click': ['clients-hidden_clients_visibility_toggle', 'clicked', { clientsCount: 'clientsCount' }],
    ...mapApiSlice('[USERS] USER_MANAGEMENT_HIDE_USER_ORG', 'clients-hide_client_modal', 'hide', {
      success: { hiddenOrganizationId: 'meta.identifier.targetOrgId' },
    }),
    ...mapApiSlice('[USERS] USER_MANAGEMENT_UNHIDE_USER_ORG', 'hidden_clients-unhide_client_modal', 'hide', {
      success: { hiddenOrganizationId: 'meta.identifier.targetOrgId' },
    }),
    'launch-playground': ['launch-playground', 'clicked'],
  },
  '/orgs/*/companies/accept-request/*': {
    'page.view': [pageEvent, 'accept-request'],
    ...mapApiSlice('[JOINORGANIZATIONREQUESTS] VALIDATE_REQUEST', pageEvent, 'validate-request'),
    ...mapApiSlice('[JOINORGANIZATIONREQUESTS] ACCEPT_REQUEST', pageEvent, 'accept-request'),
    'option-selected-organization': [pageEvent, 'accept-request-select-organization', { 'org-option': 'option.id' }],
    'companies.acceptRequestSuccess.submit': [pageEvent, 'set-user-permissions'],
  },
  '/orgs/*/companies/new/details': {
    'page.view': [pageEvent, 'new-company-details'],
    'progress.continue': ['new-company-details', 'continue'],
    'go.exit': ['new-company-details', 'exit'],
    'submit-address': ['new-company-details', 'confirm-manual-address', { option: 'option' }],
    'email-already-exist-modal': [pageEvent, 'email-already-exist-modal', { toggle: 'toggle' }],
    'form-validation-error': [pageEvent, 'validation-error', { validationsErrors: 'validationErrors' }],
  },
  '/orgs/*/companies/new/role-details': {
    'page.view': [pageEvent, 'new-company-role-details'],
    'progress.continue': ['new-company', 'continue'],
    'select-category': ['new-company', 'select-is-owner-${value}'],
    'option-selected-invite-role': ['new-company', 'role-option-${option.id}'],
    'go.exit': ['new-company-role-details', 'exit'],
    'go.back': ['new-company-role-details', 'back'],
  },
  '/orgs/*/companies/new/contacts': {
    'page.view': [pageEvent, 'new-company-owner-details'],
    'go.exit': ['new-company-contacts', 'exit'],
    'go.back': ['new-company-contacts', 'back'],
    ...mapApiSlice('[ORGANIZATIONS] CREATE', 'new-company-is-owner', 'continue'),
    ...mapApiSlice('[ORGANIZATIONS] CREATE NOT OWNED', 'new-not-owned-company', 'continue'),
    ...mapApiSlice('[JOINORGANIZATIONREQUESTS] CREATE', 'new-existing-not-owned-company', 'continue'),
  },
  '/orgs/*/companies/new/connect-accounting-software': {
    'page.view': [pageEvent, 'connect-accounting-software'],
    'inviteNewCompany.connectAccountingSoftware.connectQuickbooks': [pageEvent, 'connect-quickbooks'],
    'inviteNewCompany.connectAccountingSoftware.connectXero': [pageEvent, 'connect-xero'],
    'inviteNewCompany.connectAccountingSoftware.no': [pageEvent, 'no-connect-accounting-software'],
    ...mapApiSlice('[ORGANIZATIONS] CREATE NOT OWNED', 'new-not-owned-company', 'continue'),
  },
  ...addAccountingFirmMappings,
};

export default MAPPING;
