import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';

type Props = {
  numberOfInstallments: number;
};

export const NumberOfInstallments = ({ numberOfInstallments }: Props) => {
  const label =
    numberOfInstallments === 1
      ? 'financing.installmentsOptions.content.oneInstallment'
      : 'financing.installmentsOptions.content.numberOfInstallments';

  return (
    <Box textStyle="body2Semi" data-testid="number-of-installments">
      <MIFormattedText label={label} values={{ numberOfInstallments }} />
    </Box>
  );
};
