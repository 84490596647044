import styled from 'styled-components';
import { MIAddressAutocompleteSmartyStreets } from 'src/components/common/MIAddressAutocompleteSmartyStreets';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { ModelView } from 'src/ui/form';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { AddressType, CheckType } from 'src/utils/types';
import { US_STATES } from 'src/utils/us-states';

export type CheckDeliveryMethodModelType = ModelView<CheckType>;

type Props = {
  submit: () => Promise<void>;
  model: CheckDeliveryMethodModelType;
  eventPage: string;
};

export const CheckAddressDeliveryMethodForm = ({ submit, model, eventPage }: Props) => {
  const onChangeSuggestedAddress = async (value: AddressType | null) => {
    model.setModelState((prevState) => ({
      ...prevState,
      ...value,
    }));
    model.setValidationErrors({});
  };

  return (
    <FormStyle>
      <FormContainer onSubmit={submit}>
        <FormRow>
          <MIAddressAutocompleteSmartyStreets
            id="addressLine1"
            label="manualAddress.line1Label"
            placeholder="manualAddress.line1Placeholder"
            model={model.addressLine1}
            required
            autoFocus
            autocomplete="nope"
            onSelected={onChangeSuggestedAddress}
            eventPage={eventPage}
            privateData
          />
        </FormRow>
        <FormRow>
          <PrivateDataContainer>
            <TextField
              label="manualAddress.line2Label"
              placeholder="manualAddress.line2Placeholder"
              model={model.addressLine2}
              autoComplete="nope"
              isRequired
            />
          </PrivateDataContainer>
        </FormRow>
        <FormRow>
          <TextField
            testId="input-city"
            label="manualAddress.cityLabel"
            model={model.city}
            autoComplete="nope"
            isRequired
          />
        </FormRow>
        <FormRowSideBySide>
          <WizardSelectField
            id="state"
            label="manualAddress.stateLabel"
            model={model.state}
            options={US_STATES}
            required
          />
          <TextField
            testId="input-zipCode"
            label="manualAddress.zipCodeLabel"
            model={model.zipCode}
            autoComplete="nope"
            isRequired
          />
        </FormRowSideBySide>
      </FormContainer>
    </FormStyle>
  );
};

const FormStyle = styled.div`
  width: 100%;
  .input-container {
    margin-bottom: 0;
  }
  .input-wrapper > div {
    margin-bottom: 0;
  }
`;

const FormRowSideBySide = styled(FormRow)`
  align-items: baseline;
  margin-top: 2rem;
`;
