import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import { DeleteAdminModalMessage } from 'src/components/modals/DeleteAdminModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import usersStore, { getUserActions } from 'src/modules/users/users-store';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { getFullName } from 'src/utils/user';
import { ClientType } from '../../team-management/types';

type Props = {
  dismiss: (event: React.MouseEvent) => void;
  userId: number;
  orgId: number;
  clients: ClientType[];
};

export const RemoveTeamMemberModal = ({ dismiss, userId, orgId, clients }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const user = useSelector(usersStore.selectors.byId(userId));
  const hasAdminRoleForOneOfClients = React.useMemo(() => clients.some(({ role }) => isAdminRole(role)), [clients]);

  const { userOrg } = useStructuredSelectors(usersStore.selectors.userOrganization(userId, orgId));
  const actions = getUserActions(useDispatch());

  const displayFirstName = user.firstName || user.lastName;
  const fullName = getFullName(user.firstName, user.lastName);

  const titleLabel = fullName ? 'team.modals.remove.title' : 'team.modals.remove.titleNoName';
  const textLabel = fullName ? 'team.modals.remove.text' : 'team.modals.remove.textNoName';

  const handleRemoveClick = async (event) => {
    setIsLoading(true);
    analytics.trackAction('remove-member-submit', {
      targetUserId: userId,
      userOrganization: userOrg,
    });
    await actions.deleteUserOrganization(userOrg);
    dismiss(event);
  };

  const handleCancelClick = (event: React.MouseEvent) => {
    dismiss(event);
  };

  return hasAdminRoleForOneOfClients ? (
    <DeleteAdminModalMessage onCancel={dismiss} onRemove={handleRemoveClick} userFullName={fullName} />
  ) : (
    <ModalMessage
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label={titleLabel} values={{ fullName }} />
        </ModalTitle>
      }
      contentComponent={
        <Box textStyle="body2">
          <MIFormattedText label={textLabel} values={{ firstName: displayFirstName }} />
        </Box>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <ButtonsContainer>
            <Button
              onClick={handleCancelClick}
              variant={ButtonVariants.tertiaryNaked}
              size={ButtonSizes.md}
              label="team.modals.remove.buttons.cancel"
            />
            <Button
              boxSizing="border-box"
              onClick={handleRemoveClick}
              isLoading={isLoading}
              size={ButtonSizes.md}
              width={['100%', null, 'auto']}
              label="team.modals.remove.buttons.remove"
            />
          </ButtonsContainer>
        </ModalButtonsWrapper>
      }
    />
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
  }
`;
