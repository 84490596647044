import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { getApproveButtonLabelByFlowType } from 'src/components/debit-fee-notification-modal/utils';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { Modal } from 'src/core/ds/modal';
import { HStack } from 'src/core/ds/stack';
import { analytics } from 'src/services/analytics';

export enum PaymentFlow {
  Single = 'Single payment',
  Batch = 'Batch payment',
}

enum AnalyticsEvent {
  Viewed = 'view',
  Approved = 'approved',
  Denied = 'changed-pm',
}

type Props = {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  paymentFlowType: PaymentFlow;
};

const PAGE_NAME = 'debit-fee-notification';

export const DebitFeeNotificationModal = memo<Props>(({ isOpen, onClose, onConfirm, paymentFlowType }) => {
  const analyticsProperties = useMemo(
    () => ({
      paymentFlow: paymentFlowType,
    }),
    [paymentFlowType]
  );

  const sendEvent = useCallback(
    (event: AnalyticsEvent) => {
      analytics.track(PAGE_NAME, event, { ...analyticsProperties });
    },
    [analyticsProperties]
  );

  useEffect(() => {
    if (isOpen) {
      sendEvent(AnalyticsEvent.Viewed);
    }
  }, [isOpen, sendEvent]);

  const handleClose = useCallback(() => {
    sendEvent(AnalyticsEvent.Denied);
    onClose();
  }, [onClose, sendEvent]);

  const handleApprove = useCallback(() => {
    sendEvent(AnalyticsEvent.Approved);
    onConfirm();
  }, [onConfirm, sendEvent]);

  const handleDeny = useCallback(() => {
    sendEvent(AnalyticsEvent.Denied);
    onClose();
  }, [onClose, sendEvent]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnEsc={false} closeOnOverlayClick={false} preserveScrollBarGap>
      <Modal.Header>
        <Modal.CloseButton />
        <Modal.HeaderTitle>
          <MIFormattedText label="fundingSources.debit.feeNotificationModal.title" />
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.Body>
        <Box textStyle="body2">
          <MIFormattedText label="fundingSources.debit.feeNotificationModal.content" />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Box mt={2}>
          <HStack spacing={2}>
            <Flex wrap="wrap-reverse" justifyContent="center">
              <Button
                mb={{ sm: 0, base: 10 }}
                onClick={handleDeny}
                label="fundingSources.debit.feeNotificationModal.buttons.deny"
                variant={ButtonVariants.tertiaryNaked}
                size={ButtonSizes.lg}
              />
              <Button
                mb={{ sm: 0, base: 5 }}
                onClick={handleApprove}
                label={getApproveButtonLabelByFlowType(paymentFlowType)}
                size={ButtonSizes.lg}
              />
            </Flex>
          </HStack>
        </Box>
      </Modal.Footer>
    </Modal>
  );
});
