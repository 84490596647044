export const linkStyle = { textDecoration: 'none', color: 'inherit' };

export const unreadBadgeStyle = {
  position: 'absolute',
  right: '-0.3rem',
  bg: 'red.500',
  borderRadius: 'full',
  w: '0.4rem',
  h: '0.4rem',
  alignSelf: 'baseline',
};
