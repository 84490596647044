export const modalHeaderTitleStyle = {
  textStyle: 'body1Semi',
  alignSelf: 'flex-start',
};

export const modalHeaderSubTitleStyle = {
  textStyle: 'body3',
  alignSelf: 'flex-start',
};

export const modalImageStyle = {
  display: 'flex',
  alignItems: 'center',
  mb: 6,
};
