import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Link } from 'src/core/ds/link';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from 'src/core/ds/popover';
import { VStack } from 'src/core/ds/stack';

const contactCreditKey = 'https://melio.zendesk.com/hc/en-us/articles/6817651109660';

export const TerminatedWarningIcon = () => (
  <Popover trigger="hover" placement="top">
    <PopoverTrigger>
      <Icon
        name={IconNames.warning}
        size={IconSize.s}
        color="yellow.500"
        testId="terminated-warning-icon"
        flex="none"
      />
    </PopoverTrigger>
    <PopoverContent>
      <PopoverBody bg="black" color="white" borderRadius="lg">
        <VStack>
          <Box mr={10} mb={4}>
            <MIFormattedText label="financing.installmentsList.terminatedTooltip.description" />
          </Box>
          <Link
            data-testid="terminated-warning-tooltip-button"
            alignSelf="flex-end"
            textStyle="body4Semi"
            color="white"
            cursor="pointer"
            href={contactCreditKey}
            isExternal
          >
            <MIFormattedText label="financing.installmentsList.terminatedTooltip.contactCreditKey" />
          </Link>
        </VStack>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);
