import { IconSize, OldToNewIconNames } from 'src/core/ds/icon';
import { Icon } from 'src/core/ds/icon/icon';

type MethodOptionIconProps = {
  name: string;
  disabled?: boolean;
  size: IconSize;
};
export const BankAccountIcon = ({ name, disabled, size }: MethodOptionIconProps) => (
  <Icon name={OldToNewIconNames[name]} size={size} opacity={disabled ? 0.3 : 1} mr={2} mb={0.5} />
);
