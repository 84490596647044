import { Flex } from '@chakra-ui/react';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Separator } from 'src/core/ds/separator';
import { getVirtualCardProcessedDate } from 'src/utils/vendors';
import { VirtualCardPaymentDataProps } from '../types';

export const ValidVirtualCardPayment = ({ virtualCardPaymentData }: VirtualCardPaymentDataProps) => {
  const {
    blockIcon,
    paymentSourceIcon,
    deliveryIcon,
    title,
    subtitle,
    payment,
    deliveryMethod,
    blockLabel,
    paymentSource,
    paymentDetails,
    dateTitle,
    additionalDescription,
    dateToDisplayInBlockTitle,
    deliveryDate,
    deliveryMethodRecord,
    virtualCardState,
  } = virtualCardPaymentData;

  return (
    <Box>
      <Box mb="2">{blockIcon}</Box>
      <Box data-testId="suvc-ach.info-title" mb="0.5" textStyle="body2Semi">
        <MIFormattedText label={title} />
      </Box>
      <Box mt="2" textStyle="body2">
        <MIFormattedText
          label={subtitle}
          values={{
            paymentAmount: <MIFormattedCurrency value={payment?.amount} />,
            companyName: payment?.organization?.companyName,
            digits: deliveryMethod?.bankAccount?.accountNumber?.slice(-4),
            date: <MIFormattedDate date={dateToDisplayInBlockTitle} />,
          }}
        />
      </Box>

      <Box mt="4" textStyle="body4Uppercase" color="grey.700">
        <MIFormattedText
          label={blockLabel}
          values={{
            date: getVirtualCardProcessedDate(virtualCardState.virtualCard),
          }}
        />
      </Box>

      <Box pt="5">
        <Flex pr="5" mb="5" alignItems="center">
          <Box minW="10">{paymentSourceIcon}</Box>
          <Box>
            <Box textStyle="body4Semi" color="grey.600" data-testId="suvc-ach.paymentStatusTitle">
              <MIFormattedText
                label={paymentSource}
                values={{
                  last4CardDigits: virtualCardState.virtualCard?.accountNumber4digits,
                }}
              />
            </Box>
            <Box textStyle="body2" data-testId="suvc-ach.paymentStatusSource">
              <MIFormattedText
                label={paymentDetails}
                values={{
                  last4Digits: deliveryMethodRecord?.bankAccount?.accountNumber?.slice(-4),
                }}
              />
            </Box>
          </Box>
        </Flex>

        <Separator color="grey.400" />

        <Flex mt="5" pr="5" alignItems="center">
          <Box minW="10">{deliveryIcon}</Box>
          <Box>
            <Box data-testId="suvc-ach.paymentDateTitle" textStyle="body4Semi" color="grey.600">
              <MIFormattedText label={dateTitle} />
            </Box>

            <Box textStyle="body2">
              <MIFormattedDate date={deliveryDate} />
              <Box textStyle="body4" color="grey.700">
                <MIFormattedText label={additionalDescription} />
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
