import { useEffect } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DeliveryDate } from 'src/components/common/DeliveryDate';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { useApi } from 'src/hoc/useApi';
import { useMicroDepositOptimizationsEnabled } from 'src/hooks/useMicroDepositOptimizationsEnabled';
import { useOrgMicroDepositEligible } from 'src/hooks/useOrgMicroDepositEligible';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { PayFundingSourceDescriptionWarningIcon } from 'src/pages/bill/pay/components/PayFundingSourceDescriptionWarningIcon';
import { PaymentDateDescription } from 'src/pages/bill/pay/components/PaymentDateDescription';
import { SelectProps } from 'src/pages/bill/pay/FastPaymentOfferPage/FastPaymentOfferPage';
import { getOrgId } from 'src/redux/user/selectors';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryType, PaymentApprovalStatus } from 'src/utils/consts';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType, DeliveryOptionType } from 'src/utils/types';
import { PaymentDeliveryOptions } from './PaymentDeliveryOptions';

type Props = {
  fundingSource?: AccountType | null;
  scheduledDate: Date;
  totalAmount: string;
  deliveryDate: Date;
  maxDeliveryDate: Date | null;
  deliveryMethodType?: DeliveryType;
  deliveryOptions: DeliveryOptionType[];
  fundingSourceType?: string;
  onSelectDeliveryOption?: (props: SelectProps) => void;
  isBillOverdueNotificationShown?: boolean;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const PaymentDatePickerInfo = ({
  fundingSource,
  scheduledDate,
  totalAmount,
  deliveryDate,
  maxDeliveryDate,
  deliveryMethodType,
  deliveryOptions,
  fundingSourceType,
  onSelectDeliveryOption,
  isBillOverdueNotificationShown,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const { isOrgMicroDepositEligible } = useOrgMicroDepositEligible();
  const { isMicroDepositOptimizationsEnabled } = useMicroDepositOptimizationsEnabled();
  const { onApiCall: getApprovalDecisionStatus, result: approvalDecisionStatusResult } = useApi({
    api: deliveryApi.getApprovalDecisionStatus,
  });
  const isApprovalRequired = approvalDecisionStatusResult?.approvalDecisionStatus === PaymentApprovalStatus.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethodType === DeliveryType.VIRTUAL;
  const isInternational = deliveryMethodType === DeliveryType.INTERNATIONAL;
  const descriptionIcon =
    isMicroDepositOptimizationsEnabled && isOrgMicroDepositEligible && isMicrodeposit ? (
      <PayFundingSourceDescriptionWarningIcon />
    ) : null;
  useEffect(() => {
    getApprovalDecisionStatus(orgId, totalAmount);
  }, [orgId, totalAmount, getApprovalDecisionStatus]);

  const getTextValues = () => {
    const defaultLabels = {
      [DeliveryType.ACH]: 'bills.pay.date.ach.deliveryMethod',
      [DeliveryType.CHECK]: 'bills.pay.date.check.deliveryMethod',
    };
    let label = (deliveryMethodType && defaultLabels[deliveryMethodType]) || defaultLabels[DeliveryType.ACH];
    let notificationDate = scheduledDate;

    if (isMicrodeposit && isApprovalRequired) {
      label = isMicroDepositOptimizationsEnabled
        ? 'bills.pay.date.paymentDatePickerDescription.unverifiedBankAccountApprovalRequiredNew'
        : 'bills.pay.date.paymentDatePickerDescription.unverifiedBankAccountApprovalRequired';
    } else if (isUnilateral && isApprovalRequired) {
      label = 'bills.pay.date.paymentDatePickerDescription.unilateralApprovalRequired';
    } else if (isMicrodeposit) {
      notificationDate = isMicroDepositOptimizationsEnabled ? deliveryDate : notificationDate;
      label = isMicroDepositOptimizationsEnabled
        ? 'bills.pay.date.paymentDatePickerDescription.unverifiedBankAccountNew'
        : 'bills.pay.date.paymentDatePickerDescription.unverifiedBankAccount';
    } else if (isApprovalRequired) {
      label = 'bills.pay.date.paymentDatePickerDescription.approvalRequired';
    } else if (isUnilateral) {
      label = 'bills.pay.date.paymentDatePickerDescription.unilateral';
    } else if (isInternational) {
      label = 'bills.pay.date.paymentDatePickerDescription.international';
    }

    const identifier = getAccountNumber4digits(fundingSource?.bankAccount);
    const values = {
      date: <MIFormattedDate date={notificationDate} year={undefined} />,
      bankAccount: `(...${identifier})`,
    };

    return {
      label,
      values,
    };
  };

  const { label, values } = getTextValues();

  if (deliveryOptions?.length > 1) {
    return (
      <FastOptionsPaymentInfo
        isApprovalRequired={isApprovalRequired}
        fundingSourceType={fundingSourceType}
        deliveryOptions={deliveryOptions}
        onSelectDeliveryOption={onSelectDeliveryOption}
        label={label}
        values={values}
        isMicrodeposit={isMicrodeposit}
        isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
        isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
        dateDescriptionIcon={descriptionIcon}
        isBillOverdueNotificationShown={isBillOverdueNotificationShown}
      />
    );
  }

  return (
    <RegularPaymentInfo
      deliveryOptions={deliveryOptions}
      deliveryDate={deliveryDate}
      maxDeliveryDate={maxDeliveryDate}
      label={label}
      values={values}
      dateDescriptionIcon={descriptionIcon}
    />
  );
};

type FastOptionsPaymentInfoProps = {
  isApprovalRequired: boolean;
  isMicrodeposit: boolean;
  label: string;
  values: Record<string, any>;
  deliveryOptions: DeliveryOptionType[];
  fundingSourceType?: string;
  onSelectDeliveryOption?: (props: SelectProps) => void;
  dateDescriptionIcon: React.ReactNode | null;
  isBillOverdueNotificationShown?: boolean;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

const FastOptionsPaymentInfo = ({
  isApprovalRequired,
  isMicrodeposit,
  fundingSourceType,
  deliveryOptions,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
  onSelectDeliveryOption,
  label,
  values,
  dateDescriptionIcon,
  isBillOverdueNotificationShown,
}: FastOptionsPaymentInfoProps) => (
  <PaymentDateInfoWrapper
    deliveryOptions={deliveryOptions}
    isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
    isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
    isBillOverdueNotificationShown={isBillOverdueNotificationShown}
  >
    {isApprovalRequired || isMicrodeposit ? (
      <>
        <Box mt={1} textStyle="body2Semi">
          <PaymentDeliveryOptions
            fundingSourceType={fundingSourceType}
            deliveryOptions={deliveryOptions}
            isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
            isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
            onSelectDeliveryOption={onSelectDeliveryOption}
          />
        </Box>
        <Separator />
        <PaymentDateDescription dateDescriptionIcon={dateDescriptionIcon} label={label} values={values} />
      </>
    ) : (
      <Box mt={1} textStyle="body2Semi">
        <PaymentDeliveryOptions
          fundingSourceType={fundingSourceType}
          deliveryOptions={deliveryOptions}
          isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
          isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
          onSelectDeliveryOption={onSelectDeliveryOption}
        />
      </Box>
    )}
  </PaymentDateInfoWrapper>
);

type RegularPaymentInfoProps = {
  deliveryDate: Date;
  maxDeliveryDate: Date | null;
  deliveryOptions: DeliveryOptionType[];
  label: string;
  values: Record<string, any>;
  dateDescriptionIcon: React.ReactNode | null;
};

const RegularPaymentInfo = ({
  deliveryOptions,
  deliveryDate,
  maxDeliveryDate,
  label,
  values,
  dateDescriptionIcon,
}: RegularPaymentInfoProps) => (
  <PaymentDateInfoWrapper deliveryOptions={deliveryOptions}>
    <InfoItemText>
      <InfoItemDate>
        <DeliveryDate date={deliveryDate} maxDate={maxDeliveryDate} />
      </InfoItemDate>
    </InfoItemText>
    <PaymentDateDescription dateDescriptionIcon={dateDescriptionIcon} label={label} values={values} />
  </PaymentDateInfoWrapper>
);

type PaymentDateInfoWrapperProps = {
  deliveryOptions: DeliveryOptionType[];
  children: React.ReactNode;
  isBillOverdueNotificationShown?: boolean;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

const PaymentDateInfoWrapper = ({
  deliveryOptions,
  children,
  isBillOverdueNotificationShown,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: PaymentDateInfoWrapperProps) => {
  const getLabel = () => {
    if (isEligibleForFastAchAdoption) {
      return isFastAchBetterExposeEtaEnabled
        ? 'bills.pay.date.deliverySpeedBetterExposeETA'
        : 'bills.pay.date.paymentArrives';
    }

    return `bills.pay.date.${
      deliveryOptions?.length > 1 || isBillOverdueNotificationShown ? 'deliverySpeed' : 'estimatedDelivery'
    }`;
  };

  return (
    <InfoContainer>
      <InfoItemContainer>
        <InfoItemText data-testid="delivery-options-group-title">
          <MIFormattedText label={getLabel()} />
        </InfoItemText>
        {children}
      </InfoItemContainer>
    </InfoContainer>
  );
};

const InfoContainer = styled.div`
  display: flex;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  border-top: 1px solid ${(props) => props.theme.colors.border.grey};
  padding: 2rem;

  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoContainer}
`;

const InfoItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoItemContainer}
`;

const InfoItemText = styled.div`
  color: ${(props) => props.theme.colors.light.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoItemText}
`;

const InfoItemDate = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin: 0.8rem 0 0;

  > span {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }

  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentDatePicker?.InfoItemDate}
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.border.darkGrey};
  height: 0.1rem;
  margin: 2rem -2rem 2rem -2rem;
`;
