import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { ActionsDropdown } from 'src/components/common/ActionsDropDown';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useModal } from 'src/helpers/react/useModal';
import { profileStore } from 'src/modules/profile/profile-store';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import {
  isBillHasPartialPaymentsByBillPayments,
  isManuallyPaid,
  isPaymentRequest,
  showMarkAsPaidBills,
  showMarkAsPaidPartialPayment,
} from 'src/utils/bills';
import { BillStatus, DeletePaymentAction } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { isVoidAndRetryInProgress } from 'src/utils/payments';
import { BillType, PaymentType } from 'src/utils/types';
import { PaymentDetails } from '../PaymentDetails';
import { PaymentProcessingBlockedNotificationCard } from '../PaymentProcessingBlockedNotificationCard';
import { DeletePaymentModal } from './components/DeletePaymentModal';
import MarkAsPaidPaymentDetails from './components/MarkAsPaidPaymentDetails';
import { NotificationCardVoidedCheck } from './components/notifications/NotificationCardVoidedCheck';
import { useEnhancedPaymentCancellationOptions } from './hooks/useEnhancedPaymentCancellationOptions';

const eventPage = 'bills';

type Props = {
  bill: BillType;
  goEditPayment: (payment: PaymentType) => void;
  onDeletePayment: (payment: PaymentType, type: DeletePaymentAction) => void;
  actionOptions: ActionOption[];
  onMarkPaymentAsPaid: () => void;
  onDeleteBill: () => void;
  payment: PaymentType;
};

export const BillPaymentsActivity = ({
  bill,
  goEditPayment,
  onDeletePayment,
  actionOptions,
  onDeleteBill,
  onMarkPaymentAsPaid,
  payment,
}: Props) => {
  const currentUser = useSelector(profileStore.selectors.profile);
  const permissions = useSelector(profileStore.selectors.getPermissions);

  const [deletePaymentModal, showDeletePaymentModal] = useModal(DeletePaymentModal, {
    id: 'delete-payment-modal',
    onDeletePayment,
    payment,
    bill,
  });

  const onCancelPaymentClicked = (paymentForDeleteType: DeletePaymentAction) => {
    analytics.track(eventPage, `delete-${paymentForDeleteType}-payment`);
    showDeletePaymentModal({ paymentForDeleteType });
  };

  const cardTitle = isPaymentRequest(bill.id)
    ? 'requests.form.paymentActivity.title'
    : 'bills.form.paymentActivity.title';
  const query = useQueryString();
  const isPartialPayment = isBillHasPartialPaymentsByBillPayments(bill, bill.billPayments || []);
  const showMarkAsPaid = isPartialPayment
    ? showMarkAsPaidPartialPayment(bill, currentUser, permissions, query.status === BillStatus.UNPAID)
    : showMarkAsPaidBills(bill, currentUser, permissions);
  const showVoidedCheckNotification = isVoidAndRetryInProgress(payment);
  const isMarkedAsPaid = isPartialPayment ? payment?.manual : isManuallyPaid(bill.status, payment?.manual);

  const { enhancedActionOptions } = useEnhancedPaymentCancellationOptions({
    actionOptions,
    payment,
    bill,
    showMarkAsPaid,
    onCancelPaymentClicked,
    goEditPayment,
    onDeleteBill,
    onMarkPaymentAsPaid,
    isMarkedAsPaid,
  });

  const showActionsMenu = enhancedActionOptions.length > 0;

  return (
    <Container>
      {deletePaymentModal}
      {bill.status === BillStatus.SCHEDULED && (
        <WrappedPaymentProcessingBlockedNotificationCard payment={payment} bill={bill} />
      )}
      {showVoidedCheckNotification ? <NotificationCardVoidedCheck payment={payment} /> : null}
      <CustomFeed feedType="scheduledpayment_abovepaymentactivity" />
      <Box data-testid="view-payment-details" mt="1.2rem">
        <Box
          data-testid="view-payment-details-header"
          textStyle={{
            base: 'body1Semi',
            md: 'h2Semi',
          }}
          verticalAlign="center"
          mb={2}
        >
          <Flex justifyContent="space-between" alignItems="center">
            <MIFormattedText label={cardTitle} />
            {showActionsMenu && <ActionsDropdown testId="view-payment-actions" actionOptions={enhancedActionOptions} />}
          </Flex>
          <Box textStyle="body4" color="grey.700" mt={1}>
            <MIFormattedText label="bills.form.paymentActivity.paymentIdLabel" values={{ paymentId: payment.id }} />
          </Box>
        </Box>

        <Box mt={6}>
          {isMarkedAsPaid ? (
            <MarkAsPaidPaymentDetails bill={bill} payment={payment} />
          ) : (
            <PaymentDetails bill={bill} payment={payment} />
          )}
        </Box>
      </Box>
    </Container>
  );
};

const WrappedPaymentProcessingBlockedNotificationCard = styled(PaymentProcessingBlockedNotificationCard)`
  margin-bottom: 3rem;
`;

const Container = styled.div`
  padding: 3rem 3.6rem 0;
  @media ${devices.mobile} {
    padding: 3rem 1.6rem 0;
  }
`;
