import { ReactNode } from 'react';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { Logo } from 'src/images/Logo';
import { LogoFillType, WizardProgressBar } from 'src/utils/consts';
import { StepHeader } from './StepHeader';

type Props = {
  icon?: IconNames;
  iconSize?: IconSize;
  illustration?: IllustrationName | string;
  animation?: unknown;
  title?: string;
  titleValues?: Record<string, any>;
  text?: string | null;
  textAlign?: string;
  hasLogo?: boolean;
  textValues?: Record<string, any>;
  buttonLabel?: string;
  buttonAction?: (params?: any) => void;
  buttonValues?: Record<string, any>;
  secondaryButtonLabel?: string;
  secondaryButtonAction?: () => void;
  linkLabel?: string | null;
  linkAction?: () => void;
  linkButtonLabel?: string | null;
  linkButtonLabelAction?: () => void;
  isLoading?: boolean;
  hideHeader?: boolean | null;
  children?: ReactNode;
  goExit?: () => void | null;
  notes?: string[];
  notesTitle?: string;
  footer?: ReactNode | null;
  seperatorComponent?: ReactNode;
  testId?: string;
  engagementFeed?: boolean;
};

export const SuccessLayoutPage = ({
  illustration,
  animation,
  icon,
  iconSize,
  title,
  titleValues,
  text,
  textValues,
  buttonLabel,
  buttonAction,
  buttonValues,
  secondaryButtonLabel,
  secondaryButtonAction,
  linkLabel,
  linkAction,
  linkButtonLabel,
  linkButtonLabelAction,
  isLoading,
  children,
  hasLogo,
  goExit,
  notes,
  notesTitle,
  footer,
  seperatorComponent = <WizardElements.WizardOrLine />,
  testId,
  engagementFeed = false,
}: Props) => {
  const hasControlButtons =
    (buttonLabel && linkLabel) || (secondaryButtonLabel && linkLabel) || (linkButtonLabel && linkLabel);

  return (
    <WizardElements.WizardContainer data-testid={testId}>
      <StepHeader goExit={goExit} progressBarType={WizardProgressBar.WITHOUT_SUBSTEPS} isPrev={false} />

      <WizardElements.WizardInner isCenterVert>
        {engagementFeed ? (
          <Box w={{ base: 'full', md: '50%' }}>
            <CustomFeed feedType="paymentscheduledsuccess_above" />
          </Box>
        ) : null}
        {icon && (
          <Box mb={8} color="red.500" textStyle="display1">
            <Icon name={icon} size={iconSize} />
          </Box>
        )}
        {illustration &&
          ((Object.values(IllustrationName) as string[]).includes(illustration) ? (
            <Box mb={4}>
              <Illustration name={illustration as IllustrationName} size={IllustrationSize.lg} />
            </Box>
          ) : (
            <WizardElements.WizardIllustration src={illustration as string} />
          ))}
        {hasLogo && (
          <WizardElements.LogoContainer>
            <Logo fill={LogoFillType.LIGHT} size="regular" isFull />
          </WizardElements.LogoContainer>
        )}
        {animation && <WizardElements.WizardAnimatedIllustration animationData={animation} />}

        <WizardElements.WizardTitle>
          <MIFormattedText label={title} values={titleValues} />
        </WizardElements.WizardTitle>

        {text && (
          <WizardElements.WizardText data-testid="success-page-info">
            <MIFormattedText label={text} values={textValues} />
          </WizardElements.WizardText>
        )}

        {children && <WizardElements.WizardActionsContainer>{children}</WizardElements.WizardActionsContainer>}

        {notes && notes.length > 0 && (
          <WizardElements.WizardNotesContainer label={notesTitle || ''}>
            {notes.map((note, index) => (
              <Box key={index} mb={2}>
                <MIFormattedText key={index} label={note} values={textValues} />
              </Box>
            ))}
          </WizardElements.WizardNotesContainer>
        )}

        <WizardElements.WizardActionsContainer>
          {buttonLabel && buttonAction && (
            <WizardElements.WizardButtonContainer>
              <Button
                label={buttonLabel}
                size={ButtonSizes.lg}
                onClick={buttonAction}
                isLoading={isLoading}
                values={buttonValues}
              />
            </WizardElements.WizardButtonContainer>
          )}

          {secondaryButtonLabel && secondaryButtonAction && (
            <WizardElements.WizardButtonContainer>
              <Button
                label={secondaryButtonLabel}
                variant={ButtonVariants.secondary}
                size={ButtonSizes.lg}
                onClick={secondaryButtonAction}
              />
            </WizardElements.WizardButtonContainer>
          )}

          {linkButtonLabel && linkButtonLabelAction ? (
            <WizardElements.WizardLinkButtonContainer>
              <MILink to={linkButtonLabelAction} label={linkButtonLabel} />
            </WizardElements.WizardLinkButtonContainer>
          ) : null}

          {seperatorComponent && hasControlButtons ? seperatorComponent : null}

          {linkLabel && linkAction ? (
            <WizardElements.WizardLinkContainer>
              <MILink to={linkAction} label={linkLabel} />
            </WizardElements.WizardLinkContainer>
          ) : null}
        </WizardElements.WizardActionsContainer>

        {engagementFeed ? (
          <Box w={{ base: 'full', md: '50%' }} mt={4}>
            <CustomFeed feedType="paymentscheduledsuccess_below" />
          </Box>
        ) : null}
        <WizardElements.WizardFooterContainer>{footer}</WizardElements.WizardFooterContainer>
      </WizardElements.WizardInner>
    </WizardElements.WizardContainer>
  );
};
