import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { useGoToPage } from 'src/pages/bill/pay/hooks/useGoToPage';
import { payBillEventPage } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { useSetInstallmentOption } from 'src/pages/bill/pay/hooks/useSetInstallmentOption';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, getBillTag } from 'src/utils/bills';
import { DeliveryType, FeatureFlags, PayEditLocationName, PaymentStatus } from 'src/utils/consts';
import { isDirectPayment } from 'src/utils/payments';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { NavigateType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
};

export const useSelectDateNavigate = ({ navigate, nextStepURL, prevStepURL }: Params) => {
  const location = useLocation();
  const orgId = useSelector(getOrgId);

  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);

  const { getUrl, onPrev, onNext } = usePayBillNavigateCommon({ navigate, nextStepURL, prevStepURL });
  const isInternationalPayment = payment?.deliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL;
  const { goToPaymentPurposePage } = useGoToPage({ navigate });

  const { setInstallmentOption, intentId, numberOfInstallments } = useSetInstallmentOption();
  const [isFinancingEnabled] = featureFlags.useFeature(FeatureFlags.MelioComAPFinancingEnabled, false);

  const onNextSelectDate = () => {
    const status = getBillTag(bill);

    if (status === PaymentStatus.FAILED && payment && payment.id) {
      const eventName = `set-${getEventNameFromLocation(location)}`;
      analytics.track(payBillEventPage, `${eventName}-continue`, {
        billPaymentIndex: getBillPaymentIndex(bill),
      });

      return navigate(
        generatePath(billLocations.pay.edit.confirm, {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        })
      );
    }

    if (isFinancingEnabled && intentId && numberOfInstallments > 0) {
      setInstallmentOption();
    }

    if (isDirectPayment(payment)) {
      const url = getUrl(PayEditLocationName.CONFIRM);

      return navigate(url);
    }

    return onNext();
  };

  const onPrevDate = () => {
    if (payment.deliveryMethodId) {
      if (isInternationalPayment) {
        goToPaymentPurposePage();
      } else {
        const url = getUrl(PayEditLocationName.FUNDING);
        navigate(url);
      }
    } else {
      onPrev();
    }
  };

  return { onNextSelectDate, onPrevDate };
};
