import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { analytics, Context, Event, Page, pathToPageNames } from 'src/pages/spend-management/SpendManagementAnalytics';
import { spendManagementLocations } from './locations';

export const EnrollInProgressPage = ({ enableGoToDashboard = true }: { enableGoToDashboard?: boolean }) => {
  const [historyPush] = useHistoryWithOrgId();

  const [currentPage, setCurrentPage] = useState<Page>(Page.ORG_SETUP_DONE);
  const { path } = useRouteMatch();
  useEffect(() => {
    setCurrentPage(pathToPageNames[path]);
  }, [path]);

  return (
    <VStack justifyContent="center" alignItems="center" w="full" h="full">
      <Flex direction="column" alignItems="center" justifyContent="center" maxW="40rem">
        <Box mb={4} textAlign="center">
          {/* TODO: Should change to the right format */}
          <video
            data-component="Video"
            autoPlay
            src="https://platform-static.meliopayments.com/assets/melio/illustrations/processing.webm"
            loop
            width="144px"
          >
            <track kind="captions" />
          </video>
        </Box>
        <Box textAlign="center" maxW="48.8rem" mb={2} textStyle="body2Semi">
          <MIFormattedText label="spendManagement.pages.enrolling.title" />
        </Box>
        <Box textAlign="center" maxW="48.8rem" mb={12} textStyle="body3">
          <MIFormattedText label="spendManagement.pages.enrolling.subtitle" />
        </Box>
        {enableGoToDashboard && (
          <Box marginTop={12}>
            <Flex maxW="48.8rem" justifyContent="center" w="full">
              <Button
                label="spendManagement.pages.enrolling.gotoDashboard"
                variant={ButtonVariants.tertiaryNaked}
                onClick={() => {
                  analytics.track(currentPage, Context.ENROLL_PROGRESS, Event.CLICK_DASHBOARD);
                  historyPush({ path: spendManagementLocations.index });
                }}
                isFullWidth
              />
            </Flex>
          </Box>
        )}
      </Flex>
    </VStack>
  );
};
