import Box from 'src/core/ds/box/Box';

type AfterLabelTextComponentProps = {
  children?: React.ReactNode;
};

export const AfterLabelTextComponent = ({ children }: AfterLabelTextComponentProps) => (
  <Box as="span" textStyle="body4" textTransform="none">
    {children}
  </Box>
);
