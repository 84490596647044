import { RegistrationFlow, RegistrationOrigin } from 'src/utils/consts';
import { UserContextType, UserPreferencesType } from 'src/utils/types';

export function userContextFactory(defaultValues?: Partial<UserContextType>): UserContextType {
  return {
    email: '',
    isIntuitLinked: false,
    isEmailVerified: false,
    hasValid2Factor: null,
    id: 0,
    isGuest: false,
    dateOfBirth: '',
    name: '',
    orgId: NaN,
    orgName: '',
    userPreferences: null,
    organizations: [],
    registrationOrigin: RegistrationOrigin.APP,
    registrationFlow: RegistrationFlow.REGULAR,
    firstName: '',
    lastName: '',
    userLogoDetails: undefined,
    userOrganizations: [],
    isUserOptedOutOfSale: false,
    ...defaultValues,
  };
}

export function userPreferencesFactory(initialValues?: Partial<UserPreferencesType>): UserPreferencesType {
  return {
    accountingSoftwareType: null,
    isClosedBillsZeroState: false,
    isClosedRequestsZeroState: false,
    allowCompaniesSwitcher: false,
    getPaidProLayout: true,
    qbDashboardSeen: true,
    showQbDashboardInternalEntrypoints: false,
    getPaidProWelcomeModalShown: true,
    shownBillerVisibilityTooltipBill: null,
    shownBillerVisibilityTooltipVendor: null,
    shownBillerVisibilityModal: null,
    billInboxAnnouncementShown: null,
    ...initialValues,
  };
}
