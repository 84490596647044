import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { HStack } from 'src/core/ds/stack';
import { ExportCSVButton } from './ExportCSVButton';
import { SharePaymentLinkButton } from './SharePaymentLinkButton';

export const Header = () => (
  <HStack w="full" spacing={4}>
    <Box textStyle={{ base: 'h1Semi' }} textAlign="center">
      <MIFormattedText label="incomingPayments.title" />
    </Box>
    <Flex flexGrow={2} />
    <SharePaymentLinkButton />
    <ExportCSVButton />
  </HStack>
);
