import { composeSlices } from 'src/helpers/redux/composeSlice';
import { createBillScannedInvoiceSlice } from './slices/create';
import { deleteScannedInvoicesSlice } from './slices/delete';
import { fetchScannedInvoiceSlice } from './slices/fetch';
import { markAsPaidSlice } from './slices/mark-as-paid';

export const scannedInvoicesStore = composeSlices(
  {
    fetch: fetchScannedInvoiceSlice,
    delete: deleteScannedInvoicesSlice,
    create: createBillScannedInvoiceSlice,
    markAsPaid: markAsPaidSlice,
  },
  {}
);
