import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { customersApi } from './api';
import { name } from './constants';
import { customerPaymentRequestSummary } from './customers-payment-request-summary-slice';
import { customersUploadBatchPayments } from './customers-upload-batch';

const customersStore = createRestfulSlice({
  name,
  schemaName: 'vendor',
  api: customersApi,
  slices: {
    createBatch: customersUploadBatchPayments,
    paymentRequestsSummary: customerPaymentRequestSummary,
  },
});

export default customersStore;
