import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common/src/index';
import { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc/index';
import { organizationsApi } from 'src/modules/organizations/api';
import { profileStore } from 'src/modules/profile/profile-store';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { companyInfoFactory } from 'src/pages/settings/records';
import { GlobalState } from 'src/redux/types';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOrgId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CompanyInfoType, NavigateType } from 'src/utils/types';
import GuestInfoDetailsPage from './components/GuestInfoDetailsPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type MapStateToProps = {
  orgId: number;
  companyInfo: CompanyInfoType;
};

type MapDispatchToProps = {
  setCompanyInfo: (companyInfo: CompanyInfoType) => void;
};

type Props = GuestDataProps & {
  locationState: Record<string, any>;
  navigate: NavigateType;
} & MapStateToProps &
  MapDispatchToProps;

const eventPage = 'payor';
const eventName = 'info-details';

const PlainGuestInfoDetailsPageContainer = (props: Props) => {
  const { businessName, companyInfo } = props;
  const { contactFirstName, contactLastName, phone, companyName } = companyInfo;
  const profile = useSelector(getProfile);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const [state, setState] = useState({
    contactFirstName,
    contactLastName,
    phone,
    companyName: companyName || businessName,
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setLoading] = useState(false);
  const skipSelectOrgStep = profile.isGuest || (organizations && organizations.length === 1);
  const onNext = async () => {
    const { orgId, navigateToGuestPage, link, setCompanyInfo, companyInfo } = props;
    const newCompanyInfo = {
      ...companyInfo,
      phone: state.phone,
      contactFirstName: state.contactFirstName,
      contactLastName: state.contactLastName,
      companyName: state.companyName,
    };

    const errors = getValidationErrors('companyInfo', newCompanyInfo, [
      'phone',
      'contactFirstName',
      'contactLastName',
      'companyName',
    ]);

    setValidationErrors(errors);
    setLoading(true);

    if (isValidationOk(errors)) {
      try {
        analytics.track(eventPage, `${eventName}-continue`);
        const { companyInfo: updatedCompanyInfo } = await organizationsApi.updateCompanyInfo(orgId, newCompanyInfo);
        const companyInfoRecord = companyInfoFactory(updatedCompanyInfo);
        setCompanyInfo(companyInfoRecord);
        setLoading(false);

        analytics.track(eventPage, `${eventName}-continue-success`);

        if (skipSelectOrgStep) {
          navigateToGuestPage(generatePath(melioMeLocations.wizard.editFundingSource, { link }));
        } else {
          navigateToGuestPage(generatePath(melioMeLocations.wizard.selectOrg, { link }));
        }
      } catch (err) {
        setLoading(false);
      }
    } else {
      analytics.track(eventPage, `${eventName}-validation-error`, validationErrors);
      setLoading(false);
    }
  };

  const onChange = ({ id, value }: { id: string; value: any }) =>
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

  return (
    <GuestInfoDetailsPage
      contactFirstName={state.contactFirstName}
      contactLastName={state.contactLastName}
      contactPhone={state.phone}
      companyName={state.companyName}
      onNext={onNext}
      onChange={onChange}
      validationErrors={validationErrors}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
  companyInfo: getCompanyInfo(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  setCompanyInfo(companyInfo: CompanyInfoType) {
    dispatch(setCompanyInfoAction(companyInfo));
  },
});

export const GuestInfoDetailsPageContainer = compose(
  withNavigator(),
  withGuestData(),
  connect(mapStateToProps, mapDispatchToProps)
)(PlainGuestInfoDetailsPageContainer);
