export const headers = [
  {
    key: 'companyName',
    label: 'contacts.batch.reviewColumnNames.companyName',
    width: '27.4rem',
  },
  {
    key: 'contactName',
    label: 'contacts.batch.reviewColumnNames.contactName',
    width: '19.1rem',
  },
  {
    key: 'contactEmail',
    label: 'contacts.batch.reviewColumnNames.contactEmail',
    width: '27.3rem',
  },
  {
    key: 'contactPhone',
    label: 'contacts.batch.reviewColumnNames.contactPhone',
    width: '16.2rem',
  },
];

export enum CSVVendorsColumnName {
  COMPANY_NAME = 'Company Name',
  CONTACT_NAME = 'Contact Name',
  CONTACT_EMAIL = 'Email Address',
  CONTACT_PHONE = 'Phone Number',
}
