import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIRadioGroup, Option } from 'src/components/form/MIRadioGroup';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import joinOrgRequestsStore from 'src/modules/join-organization-requests/join-organization-requests-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { Role } from 'src/utils/consts';

type Props = {
  goExit: () => any;
  onContactSupport: () => any;
  onSuccess: (invitingUser?: any, invitingOrgId?: number) => any;
};

const AcceptRequest: React.FC<Props> = ({ goExit, onContactSupport, onSuccess }) => {
  const { token } = useRouteMatch<{ token: string }>().params;
  const dispatch = useDispatch();
  const actions = useMemo(() => getStoreActions(joinOrgRequestsStore)(dispatch), [dispatch]);
  const validationStatus = useSelector(joinOrgRequestsStore.selectors.validateRequest.status(token));
  const joinOrganizationRequest = useSelector(joinOrgRequestsStore.selectors.validateRequest.byToken(token));
  const acceptStatus = useSelector(joinOrgRequestsStore.selectors.acceptRequest.status(joinOrganizationRequest?.id));
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);

  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number | null>(null);

  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const options = useMemo(
    () =>
      organizations
        .filter((o) => (joinOrganizationRequest?.organizationsToJoin || []).includes(o.id))
        .map<Option<number>>((o) => ({
          id: o.id,
          label: 'companies.acceptRequest.companyOption',
          labelValues: { companyName: o.companyName },
        })),
    [organizations, joinOrganizationRequest]
  );

  const initUserContext = (orgId: number) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  const acceptRequest = useCallback(
    async (organizationId) => {
      const data = await actions.acceptRequest({
        token,
        id: joinOrganizationRequest?.id,
        organizationId,
      });
      const joinOrganizationRequestData = data?.payload?.joinOrganizationRequest;

      if (joinOrganizationRequestData?.role === Role.ACCOUNTANT) {
        if (currentOrg.id !== organizationId) {
          await initUserContext(organizationId);
        }

        onSuccess(joinOrganizationRequestData?.user, organizationId);
      }
      // eslint-disable-next-line
    },
    [actions, token, joinOrganizationRequest]
  );

  const onSuccessAction = () => {
    if (currentOrg.id !== joinOrganizationRequest?.organizationId) {
      dispatch(
        checkAndInitUserAction({
          orgId: joinOrganizationRequest?.organizationId,
        })
      );
    }

    onSuccess();
  };

  useEffect(() => {
    actions.validateRequest({ token });
  }, [token, actions]);

  useEffect(() => {
    const isAcceptRequestNeverCalled =
      !acceptStatus?.loading && !acceptStatus?.error && joinOrganizationRequest?.status !== 'approved';

    if (options.length === 1 && isAcceptRequestNeverCalled) {
      acceptRequest(head(options)?.id || null);
    }
  }, [options, acceptRequest, joinOrganizationRequest, acceptStatus]);

  useEffect(() => {
    if (!selectedOrganizationId) {
      setSelectedOrganizationId(head(options)?.id || null);
    }
  }, [options, selectedOrganizationId, setSelectedOrganizationId]);

  const isAcceptRequestDone =
    (!acceptStatus?.loading && joinOrganizationRequest?.status === 'approved') || acceptStatus?.error;
  const isSingleOptionAcceptRequestProcessing = options.length === 1 && !isAcceptRequestDone;

  if (validationStatus?.loading || isSingleOptionAcceptRequestProcessing) {
    return <AreaLoader />;
  }

  const user = `${joinOrganizationRequest?.user?.firstName || ''} ${joinOrganizationRequest?.user?.lastName || ''}`;
  const role = joinOrganizationRequest?.role;

  if (validationStatus?.error) {
    return (
      <SuccessLayoutPage
        illustration={IllustrationName.errorScreen}
        title="companies.acceptExpiredRequest.title"
        text="companies.acceptExpiredRequest.subtitle"
        goExit={goExit}
      />
    );
  }

  if (acceptStatus?.error || isEmpty(options)) {
    return (
      <SuccessLayoutPage
        illustration={IllustrationName.errorScreen}
        title="companies.acceptRequestError.title"
        titleValues={{ user }}
        text="companies.acceptRequestError.subtitle"
        textValues={{ user }}
        buttonLabel="companies.acceptRequestError.buttonLabel"
        buttonAction={() => onContactSupport()}
        goExit={goExit}
      />
    );
  }

  if (joinOrganizationRequest?.status === 'approved' && role !== Role.ACCOUNTANT) {
    const selectedOrganization = organizations.find((o) => o.id === joinOrganizationRequest?.organizationId);

    return (
      <SuccessLayoutPage
        illustration={IllustrationName.checkCircle}
        title="companies.acceptRequestSuccess.title"
        text="companies.acceptRequestSuccess.subtitle"
        textValues={{
          companyName: selectedOrganization?.companyName,
          user,
          role,
        }}
        buttonLabel="companies.acceptRequestSuccess.submit"
        buttonAction={onSuccessAction}
      />
    );
  }

  if (!(joinOrganizationRequest?.status === 'approved')) {
    return (
      <StepLayoutPage
        title="companies.acceptRequest.title"
        subtitle="companies.acceptRequest.subtitle"
        titleValues={{ user: <Title>{user}</Title> }}
        subTitleValues={{
          user,
          role,
        }}
        onNext={() => acceptRequest(selectedOrganizationId)}
        goExit={goExit}
        isLoading={acceptStatus?.loading}
        isNextDisabled={!selectedOrganizationId}
      >
        <SelectOrganizationsWrapper>
          <MIRadioGroup
            id="organization"
            selected={selectedOrganizationId}
            colDirection
            group="organization"
            options={options}
            onSelect={setSelectedOrganizationId}
          />
        </SelectOrganizationsWrapper>
      </StepLayoutPage>
    );
  }

  return <AreaLoader />;
};

const SelectOrganizationsWrapper = styled.div`
  border-radius: 8px;
  padding: 2rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.dark.translucent2};
  margin-bottom: 1rem;
  overflow: auto;
  max-height: 40rem;
`;

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default AcceptRequest;
