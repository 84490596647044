import { lazy, Suspense } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { AppLayout } from 'src/components/layout/AppLayout';
import { paymentClassificationLocations } from 'src/pages/payment-classification/locations';
import { analytics } from 'src/services/analytics';

const PaymentClassificationPageContainer = lazy(() => import('./PaymentClassificationPageContainer'));

const eventPage = 'payment-classification';

export default [
  {
    path: paymentClassificationLocations.index,
    component: (props) => {
      analytics.page(eventPage, 'list');

      return (
        <AppLayout>
          <Suspense fallback={<AreaLoader />}>
            <PaymentClassificationPageContainer {...props} />
          </Suspense>
        </AppLayout>
      );
    },
  },
];
