import { createContext, ReactNode, useContext } from 'react';

type StepLayoutContextConfig = {
  title: string;
  subtitle: string;
  subTitleValues?: Record<string, number>;
  nextLabel: string;
};

export type ReceivingMethodManualBankAccountPageConfig = StepLayoutContextConfig;
export type ReceivingMethodSelectVerificationTypePageConfig = Partial<StepLayoutContextConfig> & {
  plaid: {
    label: string;
    description: string;
  };
  manual: {
    label: string;
    description: string;
  };
};

export type ReceivingMethodConfig = {
  ReceivingMethodSelectVerificationTypePage?: ReceivingMethodSelectVerificationTypePageConfig;
  ReceivingMethodManualBankAccountPage?: ReceivingMethodManualBankAccountPageConfig;
};

type Props = {
  children: ReactNode;
  value: ReceivingMethodConfig;
};

export const ReceivingMethodConfigContext = createContext<ReceivingMethodConfig | null>(null);

export const ReceivingMethodConfigProvider = ({ children, value }: Props) => (
  <ReceivingMethodConfigContext.Provider value={value}>{children}</ReceivingMethodConfigContext.Provider>
);

export const useReceivingMethodConfig = <T extends keyof ReceivingMethodConfig>({
  page,
}: {
  page: T;
}): ReceivingMethodConfig[T] => {
  const context = useContext(ReceivingMethodConfigContext);

  return context ? context[page] : undefined;
};
