import {
  CardExpirationDateElement as CardExpirationDateElementType,
  CardNumberElement as CardNumberElementType,
  CardVerificationCodeElement as CardVerificationCodeElementType,
} from '@basis-theory/basis-theory-react/types';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { BrandsLogos } from 'src/components/basis-theory/brands-logos/BrandsLogos';
import { BasisTheoryCardsBrands } from 'src/components/basis-theory/brands-logos/consts';
import { BasisTheoryCardBrandType } from 'src/components/basis-theory/brands-logos/types';
import { CardDetails } from 'src/components/basis-theory/CardDetails';
import { LinkCardViewLoader } from 'src/components/basis-theory/link-card-view/LinkCardViewLoader';
import Box from 'src/core/ds/box/Box';
import { ButtonSizes } from 'src/core/ds/button';
import Button from 'src/core/ds/button/Button';
import Flex from 'src/core/ds/flex/Flex';
import { useBreak } from 'src/hoc';

type Props = {
  cardNumberFieldId: string;
  cardExpirationDateFieldId: string;
  cardVerificationCodeFieldId: string;
  onLinkCard(): void;
  refs: {
    cardNumberRef: MutableRefObject<CardNumberElementType | undefined>;
    cardExpirationRef: MutableRefObject<CardExpirationDateElementType | undefined>;
    cardVerificationRef: MutableRefObject<CardVerificationCodeElementType | undefined>;
  };
  showLinkCardButtonSpinner?: boolean;
  onReady?(): void;
};

export const LinkCardView = ({
  cardNumberFieldId,
  cardExpirationDateFieldId,
  cardVerificationCodeFieldId,
  onLinkCard,
  showLinkCardButtonSpinner,
  onReady,
  refs,
}: Props) => {
  const { isMobile, isPhablet } = useBreak();

  const [loading, setLoading] = useState(true);
  const [cardBrand, setCardBrand] = useState<BasisTheoryCardBrandType>(BasisTheoryCardsBrands.unknown);
  const [attemptedToLink, setAttemptedToLink] = useState(false);

  const errorRef = useRef(true);

  const handleLinkCard = useCallback(() => {
    if (!attemptedToLink) setAttemptedToLink(true);

    if (!errorRef.current) onLinkCard();
  }, [attemptedToLink, onLinkCard]);

  useEffect(() => {
    const keyPressEventListener = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleLinkCard();
      }
    };

    window.addEventListener('keypress', keyPressEventListener);

    return () => {
      window.removeEventListener('keypress', keyPressEventListener);
    };
  }, [handleLinkCard]);

  const handleReady = useCallback(() => {
    setLoading(false);
    onReady?.();
  }, [onReady]);

  const handleCardBrandChange = useCallback((cardBrand: BasisTheoryCardBrandType) => {
    setCardBrand(cardBrand);
  }, []);

  const handleErrorStateChange = useCallback((hasError: boolean) => {
    errorRef.current = hasError;
  }, []);

  return (
    <>
      {loading ? <LinkCardViewLoader /> : null}
      <Flex width={isMobile || isPhablet ? '32rem' : '45rem'} minHeight="31.35rem" flexDirection="column">
        <Box display={loading ? 'none' : 'initial'}>
          <Box mb={10}>
            <BrandsLogos selectedBrand={cardBrand} />
          </Box>
          <CardDetails
            cardNumberFieldId={cardNumberFieldId}
            cardExpirationDateFieldId={cardExpirationDateFieldId}
            cardVerificationCodeFieldId={cardVerificationCodeFieldId}
            onCardBrandChange={handleCardBrandChange}
            onReady={handleReady}
            onError={handleErrorStateChange}
            attemptedToLink={attemptedToLink}
            refs={refs}
          />
          <Button
            testId="link-card"
            label="linkCard.button"
            width="full"
            size={ButtonSizes.lg}
            isLoading={showLinkCardButtonSpinner}
            onClick={handleLinkCard}
          />
        </Box>
      </Flex>
    </>
  );
};
