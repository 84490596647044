import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  KeyboardEventHandler,
  MutableRefObject,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { Icon, IconNames } from 'src/core/ds/icon';
import { InputGroup, InputRightElement, PrivateDataContainer } from 'src/core/ds/input';
import withOutsideClickHandler from 'src/hoc/withOutsideClickHandler';
import { analytics } from 'src/services/analytics';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { Expandable } from 'src/utils/types';

export type OptionType = {
  label: string;
  value: any;
  isDisabled?: boolean;
};

export type FirstCustomOptionProps = {
  createOption: (text) => void;
  text: string;
  options: OptionType[];
};

export type BottomListComponentProps = {
  filteredOptions?: OptionType[];
};

export type NoOptionsProps = {
  text?: string;
};

export type MIDropDownProps<T> = {
  id?: string;
  testId?: string;
  options: OptionType[];
  label?: string;
  labelValues?: Record<string, any>;
  optionalLabelText?: string;
  value?: any;
  inputLeftElement?: ReactNode;
  inputRightElement?: ReactNode;
  placeholder?: string;
  onChange?: (change: Expandable<{ value: T }>) => void;
  errorMessage?: string;
  required?: boolean;
  clearSelectedValue?: boolean;
  size?: 'sm' | 'md';
  allowCustom?: boolean;
  firstCustomOption?: (FirstCustomOptionProps) => ReactNode;
  bottomListComponent?: (BottomListComponentProps) => ReactNode;
  noOptionsComponent?: (NoOptionsProps) => ReactNode;
  hideOptions?: boolean;
  viewOnly?: boolean;
  disableSearch?: boolean;
  renderOption?: (option: OptionType, searchText?: string | null) => ReactNode | null;
  privateData?: boolean;
  disableEdit?: boolean;
};

type SingleSelectContainerProps = {
  children?: ReactNode;
  handleClickOutside: () => void;
  onKeyDown: KeyboardEventHandler<HTMLDivElement>;
};

type ListProps = {
  children?: ReactNode;
};

type DropDownOptionProps = {
  children?: ReactNode;
  onClick: () => void;
  ref?: MutableRefObject<HTMLDivElement>;
  'data-testid': string;
};

type DropDownContainerProps = {
  children?: ReactNode;
  hidden?: boolean;
  className?: string;
  'data-testid'?: string;
  top?: string;
};

export const defaultOptionRender = (option: OptionType, searchText?: string | null) => {
  const { label, value } = option;

  if (
    label.toLowerCase().includes(searchText?.toLowerCase() || '') ||
    value?.toString().toLowerCase().includes(searchText?.toLowerCase())
  ) {
    return label;
  }

  return null;
};

export const MIDropDown = <T,>({
  id,
  testId,
  label,
  labelValues = {},
  optionalLabelText,
  options,
  placeholder,
  errorMessage,
  size = 'md',
  required,
  firstCustomOption,
  bottomListComponent,
  onChange,
  noOptionsComponent,
  hideOptions,
  value,
  inputLeftElement,
  allowCustom = true,
  viewOnly = false,
  clearSelectedValue = false,
  disableSearch = false,
  renderOption = defaultOptionRender,
  privateData = false,
  disableEdit = false,
}: MIDropDownProps<T>) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<string | undefined>(undefined);
  const trimmedText = trim(text);

  const selectedOption = find(
    options,
    (option) =>
      (typeof value === 'string' &&
        typeof option.value === 'string' &&
        option.value.toLowerCase() === value?.toLowerCase()) ||
      option.value === value
  );
  const filteredOptions =
    isEmpty(trimmedText) || trimmedText === selectedOption?.label
      ? options
      : options.filter((option) => !!renderOption(option, trimmedText));

  useEffect(() => {
    if (selectedOption) {
      setText(selectedOption?.label);
    } else if (clearSelectedValue) {
      setText(undefined);
    }
  }, [selectedOption, clearSelectedValue]);

  const onChangeWithEvent = (newOption) => {
    if (onChange) {
      analytics.trackAction(`option-changed-${id}`, { option: newOption });
      onChange(newOption);
    }
  };

  const onSelected = (option) => () => {
    setOpen(false);

    if (value !== option.value) {
      setText(undefined);
      onChangeWithEvent({
        id,
        value: option.value,
        meta: { action: 'select-option' },
      });
    }
  };
  const createOption = (text) => {
    const trimmedText = trim(text);
    const optionExists = find(options, (option) => option.label === trimmedText);

    if (optionExists) {
      onSelected(optionExists)();
    } else if (allowCustom) {
      setOpen(false);
      onChangeWithEvent({
        id,
        value: trimmedText,
        meta: { action: 'create-option' },
      });
    }
  };

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (disableEdit) return;

    const { value } = event.currentTarget;
    setText(value);
  };

  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Tab') {
      createOption(trimmedText);
    }
  };

  const dropDownIndicator = !viewOnly && (
    <Flex onClick={() => setOpen(!open)}>
      <Icon name={IconNames.caretDown} data-testid="toggle-dropdown-icon" />
    </Flex>
  );

  const onTextInputClicked = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnFocus = () => setOpen(true);

  const handleInputGroupClick = () => {
    if (disableSearch) {
      onTextInputClicked();
    }
  };

  return (
    <SingleSelectContainer handleClickOutside={() => createOption(trimmedText)} onKeyDown={onKeyDown}>
      <PrivateDataContainer>
        <InputGroup onClick={handleInputGroupClick}>
          <TextField
            id={id}
            testId={testId}
            label={label}
            labelValues={labelValues}
            onChange={onTextChange}
            value={text}
            inputLeftElement={inputLeftElement}
            inputRightElement={<InputRightElement>{dropDownIndicator}</InputRightElement>}
            size={size}
            placeholder={placeholder}
            errorMessage={errorMessage}
            isRequired={required}
            autoComplete="off"
            onClick={onTextInputClicked}
            onFocus={handleOnFocus}
            isViewOnly={viewOnly}
            isDisabled={disableSearch}
            disableSearch
            mb={size}
            optionalLabelText={optionalLabelText}
          />
        </InputGroup>
      </PrivateDataContainer>
      <DropDownContainer hidden={!open || hideOptions} className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined}>
        <List>
          {firstCustomOption && firstCustomOption({ createOption, text, options })}
          {isEmpty(options) && noOptionsComponent && noOptionsComponent({ text })}
          {filteredOptions.map((option) => (
            <DropDownOption
              key={option.value}
              onClick={onSelected(option)}
              data-testid={`dropdown-option-${option.value}`}
            >
              {renderOption(option)}
            </DropDownOption>
          ))}
        </List>
        {bottomListComponent ? filteredOptions && bottomListComponent({ filteredOptions }) : bottomListComponent}
      </DropDownContainer>
    </SingleSelectContainer>
  );
};

const SingleSelectContainer = withOutsideClickHandler(({ onKeyDown, children }: SingleSelectContainerProps) => (
  <Box onKeyDownCapture={onKeyDown} position="relative" boxSizing="border-box" w="full">
    {children}
  </Box>
));

const listHeight = 27.6;
const optionHeight = 4.8;

const List = ({ children }: ListProps) => (
  <Box maxH={`${listHeight}rem`} overflow="scroll">
    {children}
  </Box>
);

export const DropDownOption = forwardRef(({ children, ...rest }: DropDownOptionProps, ref) => (
  <Box
    {...rest}
    h={`${optionHeight}rem`}
    bgColor="white"
    color="black"
    textStyle="body3"
    boxSizing="border-box"
    py={3}
    px={5}
    whiteSpace="nowrap"
    overflow="hidden"
    textOverflow="ellipsis"
    cursor="pointer"
    _hover={{
      bgColor: 'grey.200',
    }}
    ref={ref as RefObject<HTMLDivElement>}
  >
    {children}
  </Box>
));

export const DropDownContainer = ({ children, hidden, className, top = '100%', ...rest }: DropDownContainerProps) => (
  <Box
    className={className}
    hidden={hidden}
    pos="absolute"
    top={top}
    bgColor="white"
    borderRadius="md"
    boxShadow={400}
    mt={1}
    p={0.5}
    w="full"
    zIndex={3}
    boxSizing="border-box"
    {...rest}
  >
    {children}
  </Box>
);

export { listHeight, optionHeight, SingleSelectContainer };
