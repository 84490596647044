import { featureFlags } from '@melio/shared-web';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useNewTabapayUrl } from 'src/hooks/useNewTabapayUrl';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { ShiftToDebitDeliveryMethodType } from 'src/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import { shiftToDebitLocations } from 'src/pages/vendor/shift-vendor-to-debit/locations';
import { extractFirstAndLastNameFromFullName } from 'src/pages/vendor/shift-vendor-to-debit/utils';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { ModelView } from 'src/ui/form';
import { convertPaperCheck } from 'src/utils/address';
import { FeatureFlags } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { AddressType, CardAccountType, PaymentType } from 'src/utils/types';

type Card = {
  digits: string;
  cardToken: string;
  cardBin: string;
  expiration: string;
};

const useAddCardAccount = (
  token: string,
  payment: PaymentType,
  deliveryMethodMV: ModelView<ShiftToDebitDeliveryMethodType>
) => {
  const [isCardVaultingPushEnabled] = featureFlags.useAnonymousFeature(FeatureFlags.CardVaultingPush, true);

  const history = useHistory();
  const { paymentId } = getJWTPayload(token);

  const [errorCode, setErrorCode] = useState('');
  const [{ digits, cardToken, cardBin, expiration }] = useLocationState<Partial<Card>>('card', {});

  const paymentActions = useStoreActions(paymentsStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const { clearWhitePageAddress } = getDeliveryMethodActions(useDispatch());

  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation);

  const { clientIdType } = useNewTabapayUrl();

  const cardAccount = useMemo(() => convertPaperCheck(deliveryMethodMV.cardAccount as ModelView<AddressType>), [
    deliveryMethodMV.cardAccount,
  ]);

  const addCardAccount = useCallback(async () => {
    const { addressLine1, state, city, zipCode, printName } = cardAccount as CardAccountType;
    const { firstName, lastName } = extractFirstAndLastNameFromFullName(printName);

    return isCardVaultingPushEnabled
      ? deliveryMethodActions.createCardAccountWithToken({
          token,
          card4digits: digits,
          cardToken,
          cardBin,
          expiration,
          line1: addressLine1,
          zipcode: zipCode,
          state,
          city,
          firstName,
          lastName,
        })
      : deliveryMethodActions.addCardAccountWithToken({
          token,
          card4digits: digits,
          cardToken,
          cardBin,
          expiration,
          line1: addressLine1,
          zipcode: zipCode,
          state,
          city,
          firstName,
          lastName,
          clientIdType,
        });
  }, [
    cardAccount,
    cardBin,
    cardToken,
    clientIdType,
    deliveryMethodActions,
    digits,
    expiration,
    isCardVaultingPushEnabled,
    token,
  ]);

  const [errorModal, showErrorModal] = useModal(CommonDialog, {
    title: 'vendors.deliveryMethods.shiftToDebit.errorTitle',
    description: `server.${errorCode}`,
    showCancel: true,
  });

  const onSubmit = useCallback(async () => {
    try {
      const {
        payload: { deliveryMethod },
      } = await addCardAccount();
      await paymentActions.updatePaymentWithToken({
        token,
        id: paymentId,
        deliveryMethodId: deliveryMethod.id,
      });
      history.push(generatePath(shiftToDebitLocations.success, { token }));
    } catch (e: any) {
      setErrorCode(e.error.code);

      if (e.error.code) showErrorModal();
    }
  }, [addCardAccount, history, paymentActions, paymentId, showErrorModal, token]);

  const onClearAddressState = async () => {
    await clearWhitePageAddress({
      deliveryMethodId: payment?.deliveryMethodId,
    });
  };

  return {
    onSubmit,
    onClearAddressState,
    isLoading: isLoading || isPaymentLoading,
    errorModal,
  };
};

export default useAddCardAccount;
