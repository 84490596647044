import { MicroDepositStatusResponse, MicroDepositVerificationResponse } from 'src/modules/delivery-methods/types';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { DeliveryMethodType } from 'src/utils/types';

export const deliveryMethodsApi = {
  getDeliveryMethods(orgId, vendorId) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods`;

    return fetchRequest(url);
  },

  getDeliveryMethodById({ orgId, vendorId, id }): Promise<{ deliveryMethod: DeliveryMethodType }> {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;

    return fetchRequest(url);
  },

  getDeliveryMethodStatus({ orgId, vendorId, id }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}/status`;

    return fetchRequest(url);
  },

  editDeliveryMethodById(orgId, vendorId, id, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;

    return putRequest(url, params);
  },

  addDeliveryMethod({ orgId, vendorId, params }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods`;

    return postRequest(url, params);
  },

  replaceDeliveryMethodWithToken(params) {
    const url = '/delivery-method/create-delivery-method';

    return postRequest(url, params);
  },

  getDeliveryMethodACHWithToken(params) {
    const url = '/delivery-method/delivery-method-ach';

    return fetchRequest(url, params);
  },

  updateDeliveryMethodACHWithToken(params) {
    const url = '/delivery-method/delivery-method-ach';

    return putRequest(url, params);
  },

  addPlaidAccount(orgId, vendorId, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/plaid/add-accounts`;

    return postRequest(url, params);
  },

  updatePlaidAccount(orgId, vendorId, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/plaid/update-accounts`;

    return putRequest(url, params);
  },

  deleteDeliveryMethodById(orgId, vendorId, id, params) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${id}`;

    return deleteRequest(url, params);
  },

  copyFromOwnedVendor({ orgId, vendorId, token }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/copy-from-owned-vendor`;

    return postRequest(url, { token });
  },

  verifyMicroDeposits({ orgId, vendorId, params }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/verify-micro-deposit`;

    return postRequest(url, params);
  },

  getVerificationStatus({ orgId, vendorId, deliveryMethodId }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${deliveryMethodId}/verification-status`;

    return fetchRequest(url);
  },

  getDeliveryMethodRemindersEmailsStatus(orgId, vendorId, deliveryMethodId) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/${deliveryMethodId}/reminders-emails-status`;

    return fetchRequest(url).then((res) => res.status);
  },

  checkCardWithToken(params) {
    const url = '/delivery-method/check-card';

    return postRequest(url, params);
  },

  /**
   * @deprecated
   */
  checkP2DCardWithToken(params) {
    const url = '/delivery-method/push-to-debit/check-card';

    return postRequest(url, params);
  },

  validateP2DCardWithToken(params) {
    const url = '/delivery-method/push-to-debit/validate-card';

    return postRequest(url, params);
  },

  /**
   * @deprecated
   */
  addCardAccountWithToken(params) {
    const url = '/delivery-method/add-card-account';

    return postRequest(url, params);
  },

  createCardAccountWithToken(params) {
    const url = '/delivery-method/create-card-account';

    return postRequest(url, params);
  },
  getMicroDepositsStatusWithToken(token: string): Promise<MicroDepositStatusResponse> {
    const url = '/delivery-method/micro-deposit-verification-status';

    return fetchRequest(url, { token });
  },
  verifyMicroDepositsWithToken(params): Promise<MicroDepositVerificationResponse> {
    const url = '/delivery-method/verify-micro-deposit';

    return postRequest(url, params);
  },
  shiftVirtualCardToAch(params) {
    const url = '/delivery-method/shift-virtual-card-to-ach-payment-status-collected';

    return postRequest(url, params);
  },
};
