import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { profileStore } from 'src/modules/profile/profile-store';
import { usersApi } from 'src/modules/users/api';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { companiesLocations } from '../locations';

const AddAccountingFirmQuickbooksConnectError = () => {
  const { search } = useLocation();
  const [, historyReplace] = useHistoryWithOrgId();
  const currentUser = useSelector(profileStore.selectors.profile);
  const searchParams = new URLSearchParams(search);
  const error = searchParams.get('error');
  const failedOrgId = searchParams.get('orgId');

  useEffect(() => {
    const handleRegularError = () => {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'serverErrors.ERR',
      });

      return historyReplace({ path: companiesLocations.addAccountingFirm.connectQuickbooks });
    };

    async function isNotOwnerError({ error, failedOrgId }) {
      if (error === 'user_not_owner' && failedOrgId) {
        const { owners } = await usersApi.getOrganizationsOwners(currentUser.id);
        const owner = owners.find((o) => o.userOrganization?.organizationId === Number(failedOrgId));

        return { isNotOwnerErrorResult: !!owner, owner };
      }

      return { isNotOwnerErrorResult: false };
    }

    function handleNotOwnerError({ owner, failedOrgId }) {
      return historyReplace({
        path: companiesLocations.index,
        state: {
          userNotOwnerError: {
            orgId: failedOrgId,
            email: owner.email,
            name: `${owner.firstName} ${owner.lastName}`,
          },
        },
      });
    }

    const handleError = async () => {
      try {
        analytics.track('companies', 'quickbooks-error', { error });
        const { isNotOwnerErrorResult, owner } = await isNotOwnerError({
          failedOrgId,
          error,
        });

        if (isNotOwnerErrorResult) {
          handleNotOwnerError({ failedOrgId, owner });
        } else {
          handleRegularError();
        }
      } catch (e) {
        handleRegularError();
      }
    };

    handleError();
  }, [error, historyReplace, failedOrgId, currentUser]);

  return <AreaLoader />;
};

export default AddAccountingFirmQuickbooksConnectError;
