import { AreaLoader } from 'src/components/common/AreaLoader';
import { PaymentFailedPage } from 'src/pages/vendor/components/PaymentFailedPage';
import { usePaymentDataFromToken } from 'src/pages/vendor/hooks/usePaymentDataFromToken';

type Props = {
  token: string;
};

export const EditBlockedCheckAddressPage = ({ token }: Props) => {
  const { isPaymentLoading, organization } = usePaymentDataFromToken({
    token,
  });

  if (isPaymentLoading) return <AreaLoader />;

  return <PaymentFailedPage organization={organization} />;
};
