import { featureFlags } from '@melio/shared-web';
import { generatePath, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { FeatureFlags } from 'src/utils/consts';

type Props = {
  key: string;
  to: string;
  label: string;
  icon: IconNames;
  eventName: string;
  id?: string;
  isSideMenuOpen?: boolean;
};

export const SideMenuItem = ({ to, label, icon, eventName, isSideMenuOpen, id }: Props) => {
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const match = useRouteMatch();
  const generatedPath = generatePath(to, match.params);
  const fontStyle = shouldSplitContactsTab ? 'tabLink' : 'leftMenu';
  const isActive = (match, location) => {
    const toPathname = generatedPath.split('?')[0];

    if (match) {
      return match.url.split('/')[1] === location.pathname.split('/')[1];
    }

    return toPathname === location.pathname;
  };

  const handleClick = () => {
    analytics.track('side-menu', eventName);
  };

  return (
    <StyledNavLink
      data-testid={label}
      to={generatedPath}
      id={id}
      activeClassName="active"
      isActive={isActive}
      onClick={() => handleClick()}
      splitContactsTab={shouldSplitContactsTab}
    >
      <Icon name={icon} />
      <Box
        textStyle={['body3Semi', null, null, fontStyle]}
        ml={[6, null, null, 0]}
        mt={[0, null, null, 1]}
        className={isSideMenuOpen ? 'open' : ''}
      >
        <MIFormattedText label={label} />
      </Box>
    </StyledNavLink>
  );
};

const StyledNavLink = styled(SmartLink)`
  color: ${(props) => props.theme.text.color.label};
  display: flex;
  flex-direction: row;
  padding: 1.5rem 0.9rem 1.5rem 3.3rem;
  position: relative;
  text-decoration: none;
  text-align: center;
  &.active {
    color: ${(props) => props.theme.colors.brand};
  }
  &:hover:not(.active) {
    background-color: ${({ theme, splitContactsTab }) => !splitContactsTab && theme.colors.white.veryLightGrey};
    color: ${({ theme, splitContactsTab }) => (splitContactsTab ? theme.text.color.subtitle : theme.text.color.label)};
  }

  @media ${devices.desktop} {
    flex-direction: column;
    ${({ splitContactsTab }) => splitContactsTab && `height: 5.6rem;`}
    padding: ${({ splitContactsTab }) => (splitContactsTab ? '0 0.8rem' : '1.4rem 0.8rem')};
    align-items: center;
    justify-content: center;
  }
`;
