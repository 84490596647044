/* eslint-disable camelcase */
import { ModelView } from 'src/ui/form';
import { ManualAddress } from 'src/utils/consts';
import { AddressType } from 'src/utils/types';
import { ManualAddressForm } from './components/ManualAddressForm';
import { WhitePageAddressOptions } from './components/WhitePageAddressOptions';

export type ManualAddressType = {
  diff: Record<string, any>[];
  is_valid: boolean;
  street_line_1?: string;
  street_line_2?: string;
  city?: string;
  state_code?: string;
  postal_code?: string;
  country_code?: string;
  error?: Record<string, any>;
  isPoBox: boolean;
  isPoBoxInvalid: boolean;
};

type Props = {
  setSelectedAddressId: (id) => void;
  selectedAddressId: ManualAddress;
  addressModel: ModelView<AddressType>;
  submitAddressForm: () => void;
  editAddress: () => void;
  isValidAddress: boolean;
  whitePageValidationErrors?: Record<string, any>;
  whitePageAddressView: boolean;
  whitePageAddress: ManualAddressType;
  originalAddress: Record<string, any>;
};

export const ManualAddressOptionsContainer = ({
  setSelectedAddressId,
  selectedAddressId,
  addressModel,
  editAddress,
  submitAddressForm,
  isValidAddress,
  whitePageValidationErrors,
  whitePageAddressView,
  whitePageAddress,
  originalAddress,
}: Props) => (
  <>
    {whitePageAddressView ? (
      <WhitePageAddressOptions
        onAddressSelect={(id) => setSelectedAddressId(id)}
        onEditAddress={editAddress}
        selectedAddressId={selectedAddressId}
        originalAddress={originalAddress}
        whitePageAddress={whitePageAddress}
        isValidAddress={isValidAddress}
        whitePageValidationErrors={whitePageValidationErrors}
      />
    ) : (
      <ManualAddressForm onSubmit={submitAddressForm} model={addressModel} />
    )}
  </>
);
