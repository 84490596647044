import sortBy from 'lodash/fp/sortBy';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { ApprovalWorkflowCreatorsGroup, Role } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { isNonNullable } from 'src/utils/isNonNullable';
import { ApprovalWorkflowType } from 'src/utils/types';
import { getUserRole } from 'src/utils/user';
import { MakeCompulsory } from 'src/utils/utility-types';
import { MAX_APPROVERS, NEW_WORKFLOW_ID, PAYMENT_CREATORS_ROLES } from './consts';
import { ApprovalWorkflowUserType, PaymentCreatorOptionType, WorkflowModelType } from './types';

export const toPaymentCreatorsOptions = (
  users: readonly ApprovalWorkflowUserType[],
  orgId: number,
  dbWorkflow?: ApprovalWorkflowType
) =>
  users.map<PaymentCreatorOptionType>((user) => ({
    isChecked: alreadyPaymentCreatorInDB(user.id, dbWorkflow),
    userLogoDetails: user.userLogoDetails || undefined,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    role: getUserRole(user, orgId),
  }));

export const dbWorkflowToWorkflowModel = (
  dbWorkflow: ApprovalWorkflowType,
  paymentCreatorsOptions: PaymentCreatorOptionType[]
): WorkflowModelType => ({
  ...dbWorkflow,
  approvalDecisionsCount: dbWorkflow.approvalDecisionsCount.toString(),
  threshold: dbWorkflow.threshold.toString(),
  paymentCreatorsOptions,
});

export const workflowModelToDBWorkflow = (workflow: WorkflowModelType): ApprovalWorkflowType => {
  const paymentCreatorIds = workflow.paymentCreatorsOptions
    .filter((creator) => creator.isChecked)
    .map((creator) => creator.id);

  return {
    id: workflow.id || NEW_WORKFLOW_ID,
    creatorsGroup: workflow.creatorsGroup!,
    threshold: parseFloat(convertCurrencyToNumber(workflow.threshold!)),
    approvalDecisionsCount: parseInt(workflow.approvalDecisionsCount!, 10),
    ...(workflow.creatorsGroup === ApprovalWorkflowCreatorsGroup.SOME && { paymentCreatorIds }),
  };
};

export const hasPaymentCreatorRole = (user: { role?: Role }) =>
  user.role ? PAYMENT_CREATORS_ROLES.includes(user.role) : false;

export const alreadyPaymentCreatorInDB = (userId: number, dbWorkflow?: ApprovalWorkflowType): boolean =>
  dbWorkflow?.paymentCreatorIds?.includes(userId) || false;

export const calcMaxApprovers = (users: ApprovalWorkflowUserType[], orgId: number): number => {
  const possibleApproversCount = users
    .map((user) => getUserRole(user, orgId))
    .filter(isNonNullable)
    .filter(isAdminRole).length;

  return Math.min(MAX_APPROVERS, possibleApproversCount);
};

export const filterRelevantPaymentCreatorsAndSort = (
  orgUsersPaymentCreators: PaymentCreatorOptionType[]
): PaymentCreatorOptionType[] => {
  const relevantPaymentCreatorsOptions = orgUsersPaymentCreators
    .filter((creator): creator is MakeCompulsory<typeof creator, 'role'> => isNonNullable(creator.role))
    .filter((creator) => PAYMENT_CREATORS_ROLES.includes(creator.role));

  return sortBy(['firstName', 'lastName'], relevantPaymentCreatorsOptions);
};
export const newWorkflowModel = (
  paymentCreatorsOptions: PaymentCreatorOptionType[],
  maxApprovers: number
): WorkflowModelType => ({
  paymentCreatorsOptions,
  id: null,
  creatorsGroup: paymentCreatorsOptions.length === 0 ? ApprovalWorkflowCreatorsGroup.ALL : null,
  threshold: null,
  approvalDecisionsCount: maxApprovers === 1 ? '1' : null,
});
