import * as React from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { withBreak } from 'src/hoc';
import { Logo } from 'src/images/Logo';
import { getIsLoggedIn, getProfile } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import { LogoFillType } from 'src/utils/consts';

type MelioPageCardProps = {
  device: {
    isMobile: boolean;
    isPhablet: boolean;
  };
  goDashboard?: () => void;
  goToLogin?: () => void;
  children: React.ReactNode;
};

const MelioMePageCardWrapper = ({ goDashboard, goToLogin, device, children }: MelioPageCardProps) => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const profile = useSelector(getProfile);

  return (
    <BackgroundContainer>
      <TopElementsContainer id="top-elements">
        <LogoContainer id="logo-container">
          <Logo fill={LogoFillType.WHITE} size={device.isMobile || device.isPhablet ? 'mobile' : 'regular'} isFull />
        </LogoContainer>
        {isLoggedIn && profile.orgName && (
          <UserNameContainer onClick={goDashboard} data-testid="user-name-container">
            <UserName>{profile.orgName}</UserName>
            <Icon name={IconNames.user} size={IconSize.s} />
          </UserNameContainer>
        )}
        {!isLoggedIn && goToLogin && (
          <LogInContainer>
            <MIFormattedText label="vendors.addDeliveryMethodByLink.loginHint" />
            <Button
              label="vendors.addDeliveryMethodByLink.loginSubmit"
              variant={ButtonVariants.secondary}
              onClick={goToLogin}
              ml={{ base: 3, sm: 5 }}
              color="white"
              borderColor="white"
            />
          </LogInContainer>
        )}
      </TopElementsContainer>
      <MICardContainerWrapper>{children}</MICardContainerWrapper>
    </BackgroundContainer>
  );
};

export default compose(withBreak())(MelioMePageCardWrapper);

const LogInContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.white.opaque};
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: 500;
`;

const BackgroundContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary.opaque};
  height: 32.8rem;

  @media ${devices.mobile}, ${devices.phablet} {
    height: 26.4rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 11.2rem;
  height: 4.2rem;
  margin-left: -1rem;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-left: -0.5rem;
    margin-top: 0.5rem;
  }
`;

const UserNameContainer = styled.a`
  color: ${(props) => props.theme.colors.white.opaque};
  font-size: ${(props) => props.theme.text.size.wizardText};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  cursor: pointer;
  display: flex;
  min-width: 0;
  margin-top: 0.6rem;
  margin-left: 0.6rem;
`;

const UserName = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 1rem;
`;

const TopElementsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 3.5rem 0;
  margin: 0 auto;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 1.6rem 1.6rem 0;
  }
`;

const MICardContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
