import { featureFlags } from '@melio/shared-web';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { ChecksDelaysTooltip } from 'src/pages/bill/pay/components/ChecksDelays/ChecksDelaysTooltip';
import { ChecksDelaysTooltipTypes, DeliveryType, EXPEDITED_DELIVERY_TYPES, FeatureFlags } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';
import { DeliveryOptionPrice } from './components/DeliveryOptionPrice';
import { PaymentDeliveryOptionExplanation } from './components/PaymentDeliveryOptionExplanation';
import { PaymentDeliveryOptionTitle } from './components/PaymentDeliveryOptionTitle';
import {
  getDeliveryExplanationLabel,
  getDeliveryOptionFee,
  getDollarLabel,
  getPaperCheckLabel,
  getSubtitle,
} from './utils';

type Props = {
  isFast: boolean;
  totalAmount: number;
  hasFastCheck: boolean;
  deliveryOption: DeliveryOptionType;
  fundingSourceType?: string;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const PaymentDeliveryOption = ({
  isFast,
  totalAmount,
  hasFastCheck,
  deliveryOption,
  fundingSourceType,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: Props) => {
  const isCheck = deliveryOption.type.includes('check');
  const isACH = deliveryOption.type === DeliveryType.ACH;
  const isFastACH = deliveryOption.type === EXPEDITED_DELIVERY_TYPES[0];
  const isBasicCheck = deliveryOption.type === 'check';
  const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.USHolidaysChecks, false);
  const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);
  const showChecksDelaysTooltip = isChecksDelaysEnabled && isBasicCheck && hasFastCheck;
  const subtitle = getSubtitle({
    isFast,
    totalAmount,
    hasFastCheck,
    deliveryOption,
    fundingSourceType,
    isEligibleForFastAchAdoption,
    isFastAchBetterExposeEtaEnabled,
  });
  const paperCheckLabel = getPaperCheckLabel({
    isBasicCheck,
    deliveryOption,
    fundingSourceType,
    isFastAchBetterExposeEtaEnabled,
  });
  const dollarLabel = getDollarLabel(isCheck, isFastAchBetterExposeEtaEnabled);
  const deliveryExplanationLabel = getDeliveryExplanationLabel({
    isCheck,
    deliveryOption,
    isChecksDelaysEnabled,
    isEligibleForFastAchAdoption,
    isFastAchBetterExposeEtaEnabled,
  });
  const deliveryOptionFee = getDeliveryOptionFee(deliveryOption, fundingSourceType);
  const checkDelaysTooltipLabel = isGeneralCheckDelays
    ? 'bills.pay.date.checksDelays.general.tooltip'
    : 'bills.pay.date.checksDelays.tooltip';

  const getSubtitleValues = (deliveryOption: DeliveryOptionType) => ({
    fee: deliveryOptionFee ? (
      <>
        <MIFormattedCurrency value={deliveryOption?.fee?.[0]?.amount?.toFixed(2)} />
        <MIFormattedText label="bills.pay.date.deliveryOptions.fee" />
      </>
    ) : (
      <MIFormattedText label="bills.pay.date.deliveryOptions.default" />
    ),
  });

  const subtitleValues = getSubtitleValues(deliveryOption);

  return (
    <>
      <Box color="black" mt={0.5} textStyle="body2Semi" lineHeight="2rem">
        <PaymentDeliveryOptionTitle
          subtitle={subtitle}
          subtitleValues={subtitleValues}
          deliveryOption={deliveryOption}
          deliveryExplanationLabel={deliveryExplanationLabel}
          isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
          isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
        />

        {showChecksDelaysTooltip ? (
          <ChecksDelaysTooltip tooltipLabel={checkDelaysTooltipLabel} type={ChecksDelaysTooltipTypes.WARNING} />
        ) : null}
        {!isFastAchBetterExposeEtaEnabled && isFast ? (
          <Box as="span" ml={1}>
            <Badge label="bills.pay.date.deliveryOptions.price.fastTag" />
          </Box>
        ) : null}
      </Box>

      <Box display="inline-block" textStyle="body4" maxWidth="27.8rem" color="grey.700">
        {isFastAchBetterExposeEtaEnabled ? (
          <Box textStyle="body4" display="inline-block" color="grey.700">
            <PaymentDeliveryOptionExplanation
              subtitle={subtitle}
              subtitleValues={subtitleValues}
              deliveryOption={deliveryOption}
              deliveryExplanationLabel={deliveryExplanationLabel}
              isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
              isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
            />
          </Box>
        ) : (
          <PaymentDeliveryOptionExplanation
            subtitle={subtitle}
            subtitleValues={subtitleValues}
            deliveryOption={deliveryOption}
            deliveryExplanationLabel={deliveryExplanationLabel}
            isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
            isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
          />
        )}
        {isFastAchBetterExposeEtaEnabled && (isACH || isFastACH) ? null : (
          <DeliveryOptionPrice
            isCheck={isCheck}
            dollarLabel={dollarLabel}
            deliveryOption={deliveryOption}
            paperCheckLabel={paperCheckLabel}
          />
        )}
      </Box>
    </>
  );
};
