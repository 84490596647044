import { useState } from 'react';
import { getDrawerTabs } from 'src/pages/get-pro/components/drawer/components/tabs/utils';

export const useDrawerTabs = (pageTab: string) => {
  const tabs = getDrawerTabs(pageTab);

  const [activeTab, setActiveTab] = useState(tabs.details.name);

  const onTabClick = (name: string) => {
    setActiveTab(name);
  };

  return {
    tabs,
    activeTab,
    onTabClick,
  };
};
