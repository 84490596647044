import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MICreateOption } from 'src/components/common/MICreateOption';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { CSSObject } from 'src/core/ds';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import syncQuickbooks from 'src/images/onboarding/green-to-purple.svg';
import organizationStore from 'src/modules/organizations/organizations-store';
import { getOrgId } from 'src/redux/user/selectors';
import { CompanyType, RegistrationOrigin } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import { companiesLocations } from '../locations';

type Props = {
  onNext: () => void;
  onExit: () => void;
};

export const AddAccountingFirmQuickbooksConnect = ({ onExit, onNext }: Props) => {
  const orgId = useSelector(getOrgId);
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);

  const onNoConnect = () => {
    onNext();
  };

  const onConnect = () => {
    intuit.goConnectToIntuit({
      registrationOrigin: RegistrationOrigin.ADD_COMPANY,
      intuitReturnUrl: generatePath(companiesLocations.addAccountingFirm.quickbooksSuccess, { orgId }),
      intuitErrorReturnUrl: generatePath(companiesLocations.addAccountingFirm.quickbooksError, { orgId }),
      companyInfo: {
        companyType: CompanyType.ACCOUNTING_FIRM,
      },
      switchAccountingFirm: true,
    });
  };

  return (
    <StepLayoutPage
      title="addAccountingFirm.connectQuickbooks.title"
      titleValues={{ companyName: newCompanyInfo.companyName }}
      subtitle="addAccountingFirm.connectQuickbooks.subtitle"
      goExit={onExit}
    >
      <Flex direction="column" justify="center">
        <QuickbookOption
          imgSrc={syncQuickbooks}
          label="addAccountingFirm.connectQuickbooks.connect"
          onClick={onConnect}
          eventPage="sync-your-firm"
        />
        <Button
          label="addAccountingFirm.connectQuickbooks.no"
          onClick={onNoConnect}
          variant={ButtonVariants.tertiaryNaked}
        />
      </Flex>
    </StepLayoutPage>
  );
};

type QuickbookOptionProps = {
  imgSrc: string;
  label: string;
  onClick: () => void;
  eventPage: string;
};

const QuickbookOption = (props: QuickbookOptionProps) => (
  <MICreateOption
    {...props}
    sxForCreateOptionTextWrapper={sxForCreateOptionTextWrapper}
    sxForCreateOptionWrapper={sxForCreateOptionWrapper}
  />
);

const sxForCreateOptionWrapper: CSSObject = {
  flexDir: 'column',
  py: 6,
  mb: 1,
  '> img': {
    h: 5,
    w: 16,
    mb: 4,
  },
};

const sxForCreateOptionTextWrapper: CSSObject = {
  '> div': {
    textStyle: 'body3Semi',
  },
  ml: 0,
};
