import { useEffect, useState } from 'react';
import { useRefsArray } from 'src/hooks/useRefsArray';
import { ClientModelViewType } from './types';

export function useScrollToValidationError(items: ClientModelViewType[]) {
  const refs = useRefsArray<HTMLDivElement>(items.length);
  const [firstItemIndexWithError, setFirstItemIndexWithError] = useState(-1);

  useEffect(() => {
    setFirstItemIndexWithError(items.findIndex((item) => item.approvalAmountThreshold.error));
  }, [items]);

  useEffect(() => {
    if (firstItemIndexWithError > -1) {
      const el = refs[firstItemIndexWithError];

      if (el && el.current && el.current.scrollIntoView) {
        el.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [firstItemIndexWithError, refs]);

  const resetScrollState = () => setFirstItemIndexWithError(-1);

  return { refs, resetScrollState };
}
