import * as React from 'react';
import styled from 'styled-components';
import { BillFileLoader } from 'src/components/common/BillFileLoader';
import MIFileUpload from 'src/components/common/MIFileUpload';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { InvoiceFilePreview } from 'src/components/layout/InvoiceFilePreview';
import { useBreak } from 'src/hoc';
import dragBillImage from 'src/images/bills/drag-bill.svg';
import UploadFileError from 'src/pages/bill/components/UploadFileError';
import { getLabelAndValues } from 'src/pages/get-paid/create/utils';
import { devices } from 'src/theme/appDevices';
import { ACCEPT_FILES } from 'src/utils/consts';

type PrimitiveType = string | number | boolean | null | undefined | Date;

type FormatXMLElementFn<T, R = string | Array<string | T>> = (parts: Array<string | T>) => R;

export type LabelValuesType = Record<string, PrimitiveType | React.ReactNode | FormatXMLElementFn<React.ReactNode>>;

type Props = {
  isUploading?: boolean;
  isUploadError?: boolean;
  fileStorageUrl?: string | null;
  filePreviewUrls?: string[] | null;
  onCancelUploadFile: () => void;
  onRemoveFile: () => void;
  onSelectFile: (file: File) => void;
  goManual: () => void;
};
export const InvoiceFileUpload: React.FC<Props> = ({
  isUploading,
  isUploadError,
  fileStorageUrl,
  filePreviewUrls,
  onCancelUploadFile,
  onRemoveFile,
  onSelectFile,
  goManual,
}) => {
  const { isMobile } = useBreak();
  const initialLabelValues: LabelValuesType = {
    a: (...browse) => <BrowseWrapper>{browse}</BrowseWrapper>,
  };
  const { label, labelValues } = getLabelAndValues(isMobile, initialLabelValues);

  if (isUploading) {
    return <BillFileLoader onCancelAction={onCancelUploadFile} />;
  }

  if (isUploadError) {
    return <UploadFileError handleUploadCancel={onCancelUploadFile} goManual={goManual} />;
  }

  if (fileStorageUrl) {
    return (
      <InvoiceFilePreview
        onUploadRemove={onRemoveFile}
        filePreviewUrls={filePreviewUrls}
        fileStorageUrl={fileStorageUrl}
      />
    );
  }

  return (
    <MIFileUpload
      onSelectFile={onSelectFile}
      allowedTypes={[ACCEPT_FILES.BILL_ATTACHMENT]}
      eventPage="invoice-file-upload"
    >
      <FileUploadContainer>
        <IconWrapper>
          <img src={dragBillImage} alt="Upload file" />
        </IconWrapper>
        <LabelWrapper>
          <MIFormattedText label={label} values={labelValues} />
        </LabelWrapper>
      </FileUploadContainer>
    </MIFileUpload>
  );
};

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 4.6rem 4.6rem 6rem 4.6rem;
  text-align: center;
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.regular};
  height: 100%;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0;
  }
`;

const IconWrapper = styled.div`
  margin: 0 auto;

  img {
    width: 3.5rem;
    height: 3.9rem;
  }
`;

const LabelWrapper = styled.span`
  margin-top: 1rem;

  @media ${devices.desktop}, ${devices.tablet} {
    font-size: ${(props) => props.theme.text.size.sectionTitleM};
    line-height: 2.5rem;
    margin-top: 2.2rem;
  }
`;

const BrowseWrapper = styled.span`
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primary.opaque};
`;
