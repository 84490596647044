import { Column, Row } from 'react-table';
import { HeaderCell } from 'src/components/shared/tables/PaymentsTable/components/HeaderCell';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { DataTableRowType, MainTableRowType } from 'src/pages/regular-batch-payments/components/table/types';
import { ActionsCell } from 'src/pages/regular-batch-payments/table/components/ActionsCell/ActionsCell';
import { AmountCell } from 'src/pages/regular-batch-payments/table/components/AmountCell';
import { DeductionDateCell } from 'src/pages/regular-batch-payments/table/components/DeductionDateCell';
import { DeliveryDateCell } from 'src/pages/regular-batch-payments/table/components/DeliveryDateCell';
import { DeliveryMethodCell } from 'src/pages/regular-batch-payments/table/components/DeliveryMethodCell/DeliveryMethodCell';
import { DeliverySpeedCell } from 'src/pages/regular-batch-payments/table/components/DeliverySpeedCell/DeliverySpeedCell';
import { PaymentMethodCell } from 'src/pages/regular-batch-payments/table/components/PaymentMethodCell';
import { StatusCell } from 'src/pages/regular-batch-payments/table/components/StatusCell';
import { VendorCell } from 'src/pages/regular-batch-payments/table/components/VendorCell';
import { VendorCellWithoutEmail } from 'src/pages/regular-batch-payments/table/components/VendorCellWithoutEmail';
import { Override } from 'src/utils/types';
import { BillsCell } from './components/BillsCell';
import { DueDateCell } from './components/SubCells/DueDateCell';
import { InternationalPaymentBillSubCell } from './components/SubCells/internationalPaymentCell';
import { InvoiceNumberCell } from './components/SubCells/InvoiceNumberCell';
import { SubActionsCell } from './components/SubCells/SubActionsCell/SubActionsCell';
import { SubAmountCell } from './components/SubCells/SubAmountCell';

const COLUMNS = [
  {
    id: 'payment-status-cell',
    accessor: 'isBillReady',
    Cell: StatusCell,
    disableSortBy: true,
    maxWidth: 24,
    minWidth: 24,
  },
  {
    id: 'vendor',
    Header: <HeaderCell label="regularBatchPayments.table.columns.vendor" />,
    accessor: 'vendor.name',
    Cell: ({ row }) => <VendorCell vendorName={row.original.vendor.name} />,
    maxWidth: 'auto',
    minWidth: 48,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.paymentMethod" />,
    accessor: 'payment',
    Cell: ({ row: { original } }) => (
      <PaymentMethodCell itemId={original.id} payment={original.payment} payBillFlowUUID={original.payBillFlowUUID} />
    ),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 120,
  },
  {
    Header: <HeaderCell testId="header-deductionDate" label="regularBatchPayments.table.columns.deductionDate" />,
    id: 'deductionDate',
    accessor: 'payment.scheduledDate',
    Cell: ({ row: { original } }) => (
      <DeductionDateCell
        itemId={original.id}
        minScheduledDate={original?.minScheduledDate}
        dueDate={original.dueDate}
        payment={original.payment}
        payBillFlowUUID={original.payBillFlowUUID}
      />
    ),
    maxWidth: 200,
    minWidth: 120,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.deliveryMethod" />,
    id: 'deliveryMethod',
    Cell: ({ row: { original } }) => (
      <DeliveryMethodCell itemId={original.id} payment={original.payment} payBillFlowUUID={original.payBillFlowUUID} />
    ),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 140,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.deliverySpeed" />,
    id: 'deliverySpeed',
    Cell: ({ row: { original } }) => (
      <DeliverySpeedCell
        itemId={original.id}
        amount={original.totalAmount}
        payment={original.payment}
        deliveryOptions={original.deliveryOptions}
      />
    ),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 140,
  },
  {
    id: 'deliveryDate',
    Header: <HeaderCell testId="header-deliveryDate" label="regularBatchPayments.table.columns.deliveryDate" />,
    accessor: 'payment.deliveryEta',
    Cell: ({
      row: {
        original: { payment, id, dueDate },
      },
    }) => (
      <DeliveryDateCell
        deliveryEta={payment.deliveryEta}
        maxDeliveryEta={payment.maxDeliveryEta}
        itemId={id}
        deliveryMethod={payment.deliveryMethod}
        deliveryPreference={payment.deliveryPreference}
        dueDate={dueDate}
      />
    ),
    maxWidth: 170,
    minWidth: 142,
    px: '0.6rem',
  },
  {
    id: 'amount',
    Header: <HeaderCell testId="header-amount" label="regularBatchPayments.table.columns.amount" />,
    accessor: 'payment.amount',
    Cell: ({
      rowHovered,
      row: {
        original: { id, payment, fee, internationalFee, payBillFlowUUID },
      },
    }) => (
      <AmountCell
        itemId={id}
        rowHovered={rowHovered}
        isDisabled={!payment.deliveryMethodId}
        payment={payment}
        achToCheckFeeAmount={fee!['ach-to-check']?.amount}
        fastFeeAmount={fee[payment.deliveryPreference]?.amount || internationalFee}
        payBillFlowUUID={payBillFlowUUID}
      />
    ),
    maxWidth: 174,
    minWidth: 114,
  },
  {
    id: 'payment-actions-cell',
    Cell: ({ rowHovered, row: { original } }) => <ActionsCell rowHovered={rowHovered} row={original} />,
    disableSortBy: true,
    maxWidth: 132,
    minWidth: 132,
  },
];

const isSubRow = (depth) => depth > 0;

export type MainRowType = Override<Row<DataTableRowType>, { original: MainTableRowType }>;
export type SubRowType = Override<Row<DataTableRowType>, { original: BatchBillType }>;

// react-table expect to get same type for main rows and sub rows, so we need to override the types.
const withCellWrapper = (
  Component: ({
    row,
    rowHovered,
  }: {
    row: MainRowType;
    rowHovered: boolean;
  }) => React.ReactElement<{ row: MainRowType; rowHovered: boolean }>,
  SubComponent?: ({
    row,
    rowHovered,
  }: {
    row: SubRowType;
    rowHovered: boolean;
  }) => React.ReactElement<{ row: SubRowType; rowHovered: boolean }>
) => ({ row, rowHovered }: { row: Row<DataTableRowType>; rowHovered: boolean }) =>
  !isSubRow(row.depth) ? (
    <Component row={row as MainRowType} rowHovered={rowHovered} />
  ) : (
    (SubComponent && <SubComponent row={row as SubRowType} rowHovered={rowHovered} />) || null
  );

export const BULK_PAYMENT_COLUMNS: Column<DataTableRowType>[] = [
  {
    id: 'vendor',
    Header: <HeaderCell label="regularBatchPayments.table.columns.vendor" />,
    accessor: () => 'vendor',
    Cell: withCellWrapper(
      ({ row }) => (
        <VendorCellWithoutEmail vendorName={row.original.vendor.name} vendorId={row.original.vendor.id} row={row} />
      ),
      ({ row }) => <VendorCell vendorName={row.original.vendor.companyName} />
    ),
    width: 'auto',
    minWidth: 48,
  },
  {
    id: 'bill',
    Header: <HeaderCell label="regularBatchPayments.table.columns.bill" />,
    Cell: withCellWrapper(
      ({ row }) => <BillsCell bills={row.original.payment.bills} row={row} />,
      ({ row }) => <InvoiceNumberCell invoiceNumber={row.original.invoiceNumber} />
    ),
    width: 'auto',
    minWidth: 48,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.paymentMethod" />,
    id: 'paymentMethod',
    accessor: () => 'payment',
    Cell: withCellWrapper(({ row: { original } }) => (
      <PaymentMethodCell itemId={original.id} payment={original.payment} payBillFlowUUID={original.payBillFlowUUID} />
    )),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 120,
  },
  {
    Header: <HeaderCell testId="header-deductionDate" label="regularBatchPayments.table.columns.deductionDate" />,
    id: 'deductionDate',
    accessor: () => 'payment.scheduledDate',
    Cell: withCellWrapper(({ row: { original } }) => (
      <DeductionDateCell
        itemId={original.id}
        minScheduledDate={original?.minScheduledDate}
        dueDate={original.dueDate}
        payment={original.payment}
        payBillFlowUUID={original.payBillFlowUUID}
      />
    )),
    maxWidth: 200,
    minWidth: 120,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.deliveryMethod" />,
    id: 'deliveryMethod',
    Cell: withCellWrapper(
      ({ row }) => (
        <DeliveryMethodCell
          itemId={row.original.id}
          payment={row.original.payment}
          payBillFlowUUID={row.original.payBillFlowUUID}
          toggleRowExpanded={row.toggleRowExpanded}
        />
      ),
      ({ row: { original } }) => <InternationalPaymentBillSubCell bill={original} />
    ),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 140,
  },
  {
    Header: <HeaderCell label="regularBatchPayments.table.columns.deliverySpeed" />,
    id: 'deliverySpeed',
    Cell: withCellWrapper(({ row: { original } }) => (
      <DeliverySpeedCell
        itemId={original.id}
        amount={original.totalAmount}
        payment={original.payment}
        deliveryOptions={original.deliveryOptions}
      />
    )),
    disableSortBy: true,
    maxWidth: 200,
    minWidth: 140,
  },
  {
    id: 'deliveryDate',
    Header: <HeaderCell testId="header-deliveryDate" label="regularBatchPayments.table.columns.deliveryDate" />,
    accessor: () => 'payment.deliveryEta',
    Cell: withCellWrapper(
      ({
        row: {
          original: { payment, id, dueDate },
        },
      }) => (
        <DeliveryDateCell
          deliveryEta={payment.deliveryEta}
          maxDeliveryEta={payment.maxDeliveryEta}
          itemId={id}
          deliveryMethod={payment.deliveryMethod}
          deliveryPreference={payment.deliveryPreference}
          dueDate={dueDate}
        />
      ),
      ({
        row: {
          original: { dueDate },
        },
      }) => <DueDateCell dueDate={dueDate} />
    ),
    maxWidth: 170,
    minWidth: 142,
  },
  {
    id: 'amount',
    Header: <HeaderCell testId="header-amount" label="regularBatchPayments.table.columns.amount" />,
    accessor: () => 'payment.amount',
    Cell: withCellWrapper(
      ({
        rowHovered,
        row: {
          original: { id, payment, fee, internationalFee, payBillFlowUUID },
        },
      }) => (
        <AmountCell
          itemId={id}
          rowHovered={rowHovered}
          isDisabled={!payment.deliveryMethodId}
          payment={payment}
          achToCheckFeeAmount={fee!['ach-to-check']?.amount}
          fastFeeAmount={fee![payment.deliveryPreference!]?.amount || internationalFee}
          payBillFlowUUID={payBillFlowUUID}
        />
      ),

      ({
        row: {
          original: { balance },
        },
      }) => <SubAmountCell amount={balance} />
    ),
    maxWidth: 174,
    minWidth: 114,
  },
  {
    id: 'payment-actions-cell',
    Cell: withCellWrapper(
      ({ row: { original }, rowHovered }) => <ActionsCell rowHovered={rowHovered} row={original} />,
      ({ row: { original }, rowHovered }) => <SubActionsCell rowHovered={rowHovered} bill={original} />
    ),
    disableSortBy: true,
    maxWidth: 88,
    minWidth: 88,
  },
];

export default COLUMNS as Column<DataTableRowType>[];
