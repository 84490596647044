import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { getFundingSources } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { isBillAmountRequiresGoodsConfirmation } from 'src/utils/bills';
import { FundingType } from 'src/utils/consts';
import GuestExpeditePaymentPage from './components/GuestExpeditePaymentPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

const eventPage = 'payor';

const GuestExpeditePageContainer = ({
  nextRoute,
  prevRoute,
  navigateToGuestPage,
  goodsReceived,
  link,
  selectedFundingSourceId,
  totalAmount,
}: GuestDataProps) => {
  const [goodsReceivedId, setGoodsReceivedId] = useState<string | null>(null);
  const fundingSources = useSelector(getFundingSources);

  useEffect(() => {
    const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId) || fundingSources[0];

    if (!isBillAmountRequiresGoodsConfirmation(totalAmount) || selectedFundingSource.fundingType !== FundingType.CARD) {
      navigateToGuestPage(nextRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGoodsReceivedId(goodsReceived || goodsReceivedId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goodsReceived]);

  const onNext = () => {
    navigateToGuestPage(
      generatePath(melioMeLocations.wizard.expedite, { link }),
      { goodsReceived: goodsReceivedId },
      false,
      true
    );
    navigateToGuestPage(nextRoute, { goodsReceived: goodsReceivedId });
  };

  const onPrev = () => {
    analytics.track(eventPage, 'expedite-payment-back');
    navigateToGuestPage(prevRoute, { goodsReceived: goodsReceivedId });
  };

  return (
    <GuestExpeditePaymentPage
      onNext={onNext}
      onPrev={onPrev}
      setGoodsReceived={setGoodsReceivedId}
      goodsReceivedId={goodsReceivedId}
    />
  );
};

export const GuestExpeditePaymentPageContainer = compose(withNavigator(), withGuestData())(GuestExpeditePageContainer);
