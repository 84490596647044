import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import { withNavigator } from 'src/hoc';
import { devices } from 'src/theme/appDevices';
import { LinkListItemContainer } from './LinkListItemContainer';

type Props = {
  to: string;
  title: string;
  description: string;
  search?: string;
  showNewSettingTag?: boolean;
  icon: IconNames;
};

const PAGE_NAME_IDX_IN_PATH = 4;

export const SettingsListItem = ({ search = '', ...props }: Props) => {
  const { to, title, description, icon, showNewSettingTag } = props;

  const location = useLocation();
  const locationSettingName = location.pathname.split('/')[PAGE_NAME_IDX_IN_PATH];
  const toSettingName = to.split('/')[PAGE_NAME_IDX_IN_PATH];
  const isSelected = locationSettingName === toSettingName;

  return (
    <LinkListItemContainer
      data-testid={title}
      to={{ pathname: to, search: `${search ? `&${search}` : ''}` }}
      isSelected={isSelected}
    >
      <DetailsContainer>
        <Box mr={4}>
          <Icon name={icon} color="black" />
        </Box>
        <DetailsInfoContainer>
          <Title>
            <MIFormattedText label={title} />
            {showNewSettingTag && (
              <Box as="span" ml={2}>
                <Badge color={BadgeColors.PRIMARY} label="general.new" />
              </Box>
            )}
          </Title>
          <Description>
            <MIFormattedText label={description} />
          </Description>
        </DetailsInfoContainer>
      </DetailsContainer>
    </LinkListItemContainer>
  );
};

export default compose(withNavigator())(SettingsListItem);

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

const Title = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => props.theme.text.fontType.regular};

  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 30rem;
  }
`;

const Description = styled.div`
  display: inline-block;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
`;

const DetailsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
