import keyBy from 'lodash/keyBy';
import qs from 'qs';
import { BatchDeleteActionPayload, BatchDeleteActionResponse } from 'src/helpers/redux/restBatchDeleteSlice';
import { BatchUpdateActionPayload, BatchUpdateActionResponse } from 'src/helpers/redux/restBatchUpdateSlice';
import { InvoiceType } from 'src/modules/invoices/types';
import {
  mapPaymentAndInvoice,
  mapPaymentRequestForGetProTable,
  mapPaymentRequestToInvoice,
  mapPaymentsAndInvoices,
} from 'src/modules/invoices/utils';
import { GetProItemParams, GetProListParams } from 'src/pages/get-pro/types';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { PaymentRequestType } from 'src/utils/types';

export type SingleActionParams = {
  orgId: number;
  id: string;
};

type SendInvoiceParams = {
  orgId: number;
  id: string;
  customerEmail: string;
  customerNote?: string;
};

export const getProApi = {
  invoices: {
    update(params: GetProItemParams & Record<string, any>) {
      const { id, orgId } = params;
      const url = `/orgs/${orgId}/pro/invoices/${id}`;

      return putRequest(url, params).then((res) => mapPaymentRequestToInvoice(res.invoice));
    },
    list({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/invoices?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        items: res.invoices.map((inv) => mapPaymentRequestToInvoice(inv)),
        totalCount: res.totalCount,
        totalSum: res.totalSum,
        lastUpdatedAtDate: res.lastUpdatedAtDate,
        hasPaymentRequests: Boolean(res.hasPaymentRequests),
      }));
    },
    fetch({ id, orgId }: SingleActionParams) {
      const url = `/orgs/${orgId}/pro/invoices/${id}`;

      return fetchRequest(url).then((res) => mapPaymentRequestToInvoice(res.invoice));
    },
    checkNewDataExist({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/invoices/check-last-update?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        lastUpdatedAtDate: res.lastUpdatedAtDate,
      }));
    },
    delete(params: SingleActionParams) {
      const { orgId, id } = params;
      const url = `/orgs/${orgId}/pro/invoices/${id}`;

      return deleteRequest(url, params);
    },
    batchDelete(params: BatchDeleteActionPayload): Promise<BatchDeleteActionResponse> {
      const { orgId } = params;
      const url = `/orgs/${orgId}/pro/invoices/batch-delete`;

      return postRequest(url, params);
    },
    markAsPaid(params: SingleActionParams) {
      const { orgId, id } = params;
      const url = `/orgs/${orgId}/pro/invoices/${id}/mark-as-paid`;

      return postRequest(url, {}).then(({ invoice }) => invoice);
    },
    batchMarkAsPaid(params: BatchUpdateActionPayload<InvoiceType>): Promise<BatchUpdateActionResponse<InvoiceType>> {
      const { orgId, items } = params;
      const ids = items.map((i) => i.id);
      const url = `/orgs/${orgId}/pro/invoices/batch-mark-as-paid`;

      return postRequest(url, { ids }).then(({ invoices, ids }) => ({
        items: invoices,
        ids,
      }));
    },
    send(params: SendInvoiceParams) {
      const { orgId } = params;
      const url = `/orgs/${orgId}/pro/invoices/send`;

      return postRequest(url, params).then(({ invoice }) => invoice);
    },
    batchSend(params: BatchUpdateActionPayload<InvoiceType>): Promise<BatchUpdateActionResponse<InvoiceType>> {
      const { orgId, items, shouldAttachInvoice } = params;
      const body = {
        invoices: keyBy(items, 'id'),
        shouldAttachInvoice,
      };
      const url = `/orgs/${orgId}/pro/invoices/batch-send`;

      return postRequest(url, body).then(({ invoices, ids }) => ({
        items: invoices,
        ids,
      }));
    },
  },
  requests: {
    update(params: GetProItemParams & Record<string, any>) {
      const { id, orgId } = params;
      const url = `/orgs/${orgId}/pro/requests/${id}`;

      return putRequest(url, params).then((res) => mapPaymentRequestToInvoice(res.paymentRequest));
    },
    delete(params: SingleActionParams) {
      const { orgId, id } = params;
      const url = `/orgs/${orgId}/pro/requests/${id}`;

      return deleteRequest(url, params);
    },
    list({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/requests?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        items: mapPaymentRequestForGetProTable(res.requests),
        totalCount: res.totalCount,
        totalSum: res.totalSum,
        lastUpdatedAtDate: res.lastUpdatedAtDate,
        hasPaymentRequests: Boolean(res.hasPaymentRequests),
      }));
    },
    fetch({ id, orgId }: SingleActionParams) {
      const url = `/orgs/${orgId}/pro/requests/${id}`;

      return fetchRequest(url).then((res) => mapPaymentRequestToInvoice(res.paymentRequest));
    },
    checkNewDataExist({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/requests/check-last-update?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        lastUpdatedAtDate: res.lastUpdatedAtDate,
      }));
    },
    cancel(params: SingleActionParams) {
      const { orgId, id } = params;
      const url = `/orgs/${orgId}/pro/requests/${id}/cancel`;

      return putRequest(url, {}).then(({ paymentRequest }) => paymentRequest);
    },
    batchCancel(params: BatchDeleteActionPayload): Promise<BatchDeleteActionResponse> {
      const { orgId, ids } = params;
      const url = `/orgs/${orgId}/pro/requests/batch-cancel`;

      return postRequest(url, { ids }).then(({ paymentRequests, ids }) => ({
        items: paymentRequests,
        ids,
      }));
    },
    markAsPaid(params: SingleActionParams) {
      const { orgId, id } = params;
      const url = `/orgs/${orgId}/pro/requests/${id}/mark-as-paid`;

      return postRequest(url, {}).then(({ paymentRequest }) => paymentRequest);
    },
    resend(params: SingleActionParams) {
      const { orgId, id } = params;
      const url = `/orgs/${orgId}/pro/requests/${id}/resend`;

      return postRequest(url).then(() => ({ id }));
    },
    batchMarkAsPaid(
      params: BatchUpdateActionPayload<PaymentRequestType>
    ): Promise<BatchUpdateActionResponse<PaymentRequestType>> {
      const { orgId, items } = params;
      const ids = items.map((i) => i.id);
      const url = `/orgs/${orgId}/pro/requests/batch-mark-as-paid`;

      return postRequest(url, { ids }).then(({ paymentRequests, ids }) => ({
        items: paymentRequests,
        ids,
      }));
    },
    batchDelete(params: BatchDeleteActionPayload): Promise<BatchDeleteActionResponse> {
      const { orgId } = params;
      const url = `/orgs/${orgId}/pro/requests/batch-delete`;

      return postRequest(url, params);
    },
  },
  inProgress: {
    list({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/in-progress?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        items: mapPaymentsAndInvoices(res.payments),
        totalCount: res.totalCount,
        totalSum: res.totalSum,
        lastUpdatedAtDate: res.lastUpdatedAtDate,
        hasPaymentRequests: Boolean(res.hasPaymentRequests),
      }));
    },
    checkNewDataExist({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/in-progress/check-last-update?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        lastUpdatedAtDate: res.lastUpdatedAtDate,
      }));
    },
    fetch({ orgId, id }: { orgId?: string; id: string }) {
      const url = `/orgs/${orgId}/pro/in-progress/${id}`;

      return fetchRequest(url).then((res) => mapPaymentAndInvoice(res.payment));
    },
    delete(params: SingleActionParams) {
      const { orgId, id } = params;
      const url = `/orgs/${orgId}/pro/in-progress/${id}`;

      return deleteRequest(url, params);
    },
    alertsSummary({ orgId, filters }) {
      const url = `/orgs/${orgId}/pro/alerts-summary?${qs.stringify(filters)}`;

      return fetchRequest(url).then(({ code, message, ...tabs }) => tabs);
    },
    cancelPending({ orgId, id, shouldDelete }: SingleActionParams & { shouldDelete?: boolean }) {
      const url = `/orgs/${orgId}/pro/in-progress/${id}/cancel`;

      return postRequest(url, { shouldDelete });
    },
  },
  paid: {
    list({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/paid?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        items: mapPaymentsAndInvoices(res.payments),
        totalCount: res.totalCount,
        totalSum: res.totalSum,
        lastUpdatedAtDate: res.lastUpdatedAtDate,
        hasPaymentRequests: Boolean(res.hasPaymentRequests),
      }));
    },
    checkNewDataExist({ filters, orgId }: GetProListParams) {
      const url = `/orgs/${orgId}/pro/paid/check-last-update?${qs.stringify(filters)}`;

      return fetchRequest(url).then((res) => ({
        lastUpdatedAtDate: res.lastUpdatedAtDate,
      }));
    },
    fetch({ orgId, id }: { orgId?: string; id: string }) {
      const url = `/orgs/${orgId}/pro/paid/${id}`;

      return fetchRequest(url).then((res) => mapPaymentAndInvoice(res.payment));
    },
    markAsUnpaid({ orgId, id }) {
      const url = `/orgs/${orgId}/pro/paid/${id}/mark-as-unpaid`;

      return postRequest(url);
    },
  },
  summary({ orgId, filters }) {
    const url = `/orgs/${orgId}/pro/summary?${qs.stringify(filters)}`;

    return fetchRequest(url).then(({ code, message, ...tabs }) => tabs);
  },
  alertsSummary({ orgId, filters }) {
    const url = `/orgs/${orgId}/pro/alerts-summary?${qs.stringify(filters)}`;

    return fetchRequest(url).then(({ code, message, ...alertsSummary }) => alertsSummary);
  },
  report({ filters, orgId }: GetProListParams) {
    const url = `/orgs/${orgId}/pro/report?${qs.stringify(filters)}`;

    return fetchRequest(url).then((res) => ({
      items: res.payments,
    }));
  },
};
