import { getValidationErrors } from '@melio/sizzers-js-common';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { InvoiceType } from 'src/modules/invoices/types';
import useIsSinglePage from 'src/pages/get-paid/hooks/useIsSinglePage';
import { useLegalInfoPage } from 'src/pages/get-paid/hooks/useLegalInfoPage';
import { Card, Header } from 'src/pages/get-paid/list/components/batchActions/BatchElements';
import DeleteUnsentInvoicesButton from 'src/pages/get-paid/list/components/batchActions/DeleteUnsentInvoicesButton';
import UnsentInvoiceCard from 'src/pages/get-paid/list/components/batchActions/UnsentInvoiceCard';
import LeftHeaderText from 'src/pages/get-paid/list/components/LeftHeaderText';
import { getTotalAmount } from 'src/pages/get-paid/list/components/utils';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { companyInfoFactory, isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications/notificationService';
import { NotificationVariant, PaymentRequestFilter, PaymentRequestTabs } from 'src/utils/consts';
import { useQueryIds, useQueryString } from 'src/utils/hooks';
import { encodeQuery } from 'src/utils/query-utils';
import { errorToastStyles, legalInfoContainerStyles } from './styles';

const validateInvoice = (invoice, fromCustomer = true) => {
  const validationErrors = getValidationErrors('paymentRequest', invoice, ['customerEmail', 'customerNote']);
  const email = fromCustomer ? invoice?.customer?.contactEmail : invoice?.customerEmail;

  if (isEmpty(email)) {
    validationErrors.customerEmail = 'inputErrors.paymentRequest.customerEmail.any.required';
  }

  return validationErrors;
};

const getSelectedInvoicesDataById = (invoices) => {
  const selectedInvoicesData =
    invoices &&
    invoices.map((invoice) => {
      const validationErrors = validateInvoice(invoice);

      return {
        invoiceId: invoice.id,
        customerEmail: invoice.customer?.contactEmail,
        customerNote: invoice.customerNote,
        validationErrors,
      };
    });

  return keyBy(selectedInvoicesData, 'invoiceId');
};

type Props = {
  invoices: InvoiceType[];
};

const UnsentBatchView = ({ invoices }: Props) => {
  const [isSingleView] = useIsSinglePage();
  const numOfInvoicesSelected = invoices.length;
  const selectedInvoicesTotalAmount = getTotalAmount(invoices);
  const orgId = useSelector(getOrgId);
  const invoicesActions = useStoreActions(invoicesStore);
  const history = useHistory();
  const query = useQueryString();
  const [selectedInvoicesIds] = useQueryIds();
  const countersLoader = useSelector(invoicesStore.selectors.totalCounters.loading);
  const shareLoader = useSelector(invoicesStore.selectors.batchShare.loading);
  const [updateCompanyLegalInfo, setUpdateCompanyLegalInfo] = useState(false);
  const isLoading = countersLoader || shareLoader;
  const companyInfo = companyInfoFactory(useSelector(getCompanyInfo));

  const onBatchShare = async () => {
    if (isCompanyLegalInfoDone(companyInfo)) {
      batchShare();
    } else {
      setUpdateCompanyLegalInfo(true);
    }
  };

  const batchShare = async () => {
    try {
      await invoicesActions.batchShare({
        orgId,
        data: pick(selectedInvoicesDataById, selectedInvoicesIds),
      });
      await invoicesActions.totalCounters({ orgId });
      history.push({
        pathname: generatePath(getPaidLocations.dashboard, { orgId }),
        search: encodeQuery(
          {
            ...query,
            requestStatus: PaymentRequestFilter.SENT,
            status: PaymentRequestTabs.SENT,
          },
          ['id', 'ids', 'limit', 'start', 'singlePageView'],
          ''
        ),
      });
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'getPaid.view.batch.sendMessage.success',
        textValues: { count: selectedInvoicesIds?.length },
      });
    } catch (e) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'getPaid.view.batch.sendMessage.error',
      });
    }
  };
  const invoicesById = useMemo(() => getSelectedInvoicesDataById(invoices), [invoices]);
  const [selectedInvoicesDataById, setSelectedInvoicesDataById] = useState(invoicesById);
  useEffect(() => {
    setSelectedInvoicesDataById((selectedInvoicesDataById) => ({
      ...invoicesById,
      ...selectedInvoicesDataById,
    }));
  }, [invoicesById]);
  const onChangeInvoiceData = (invoiceId, data) => {
    const customerEmail = data?.customerEmail;
    const customerNote = data?.customerNote;
    const updatedInvoice = {
      ...selectedInvoicesDataById[invoiceId],
      ...(customerEmail !== undefined && { customerEmail }),
      ...(customerNote !== undefined && { customerNote }),
    };

    setSelectedInvoicesDataById({
      ...selectedInvoicesDataById,
      [invoiceId]: {
        ...updatedInvoice,
        validationErrors: validateInvoice(updatedInvoice, false),
      },
    });
  };

  const canShareBatch = every(
    mapValues(pick(selectedInvoicesDataById, selectedInvoicesIds), 'validationErrors'),
    (validationError) => isEmpty(validationError)
  );

  const numOfUnvalidateInvoices = reduce(
    mapValues(selectedInvoicesDataById, 'validationErrors'),
    (count, value) => (!isEmpty(value) ? count + 1 : count),
    0
  );

  const { LegalInfoPage } = useLegalInfoPage({
    title: 'getPaid.view.batch.completeLegalInfo.title',
    subtitle: 'getPaid.view.batch.completeLegalInfo.subtitle',
    nextLabel: 'getPaid.view.batch.completeLegalInfo.action',
    isLoading,
    onNext: batchShare,
    onPrev: () => setUpdateCompanyLegalInfo(false),
    goExit: () => setUpdateCompanyLegalInfo(false),
  });

  if (updateCompanyLegalInfo) {
    return <Box __css={legalInfoContainerStyles}>{LegalInfoPage}</Box>;
  }

  const mobileErrorToast = (
    <Box __css={errorToastStyles} textStyle="body4">
      {numOfUnvalidateInvoices > 1 ? (
        <MIFormattedText label="getPaid.view.batch.mobile.errorToast" values={{ count: numOfUnvalidateInvoices }} />
      ) : (
        <MIFormattedText label="getPaid.view.batch.mobile.errorToastSingle" />
      )}
    </Box>
  );

  return (
    <>
      <SingleViewLoadingContainer>
        <Box as={Header} flexWrap="wrap">
          <LeftHeaderText
            numOfInvoices={numOfInvoicesSelected}
            totalAmount={selectedInvoicesTotalAmount}
            headerLabel="getPaid.view.batch.title.unsent"
            descriptionLabel="getPaid.view.batch.description"
          />
          <Flex mt={{ base: 5, xl: 0 }}>
            {!isSingleView && (
              <DeleteUnsentInvoicesButton
                numOfInvoices={numOfInvoicesSelected}
                totalAmount={selectedInvoicesTotalAmount}
              />
            )}
            <Button
              label="getPaid.view.batch.send"
              variant={ButtonVariants.success}
              onClick={onBatchShare}
              disabled={!canShareBatch}
              isLoading={countersLoader || shareLoader}
              boxSizing="border-box"
              w={isSingleView ? 'full' : 'auto'}
              ml={{ md: 5 }}
              analyticsProperties={{
                selectedIds: selectedInvoicesIds,
              }}
            />
          </Flex>
        </Box>
        <Box as={Card} borderRadius={{ base: 0, md: 'lg' }} mode="mainSingleScreen">
          {isSingleView && !canShareBatch && mobileErrorToast}
          {invoices.map((invoice) => (
            <UnsentInvoiceCard
              invoice={invoice}
              key={invoice.id}
              onChangeInvoiceData={onChangeInvoiceData}
              selectedInvoiceData={selectedInvoicesDataById[invoice.id] || {}}
            />
          ))}
        </Box>
      </SingleViewLoadingContainer>
    </>
  );
};

export default UnsentBatchView;
