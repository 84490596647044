export const companiesLocations = {
  base: ['/orgs/:orgId/companies'],
  index: '/orgs/:orgId/companies',
  acceptRequest: {
    base: ['/orgs/:orgId/companies/accept-request'],
    index: '/orgs/:orgId/companies/accept-request/:token',
    setAmount: '/orgs/:orgId/companies/accept-request/set-amount',
    fundingSource: '/orgs/:orgId/companies/accept-request/funding-source',
    successPage: '/orgs/:orgId/companies/accept-request/success',
  },
  create: {
    base: ['/orgs/:orgId/companies/new'],
    details: '/orgs/:orgId/companies/new/details',
    connectAccountingSoftware: '/orgs/:orgId/companies/new/connect-accounting-software',
    connectAccountingSoftwareReturn: '/orgs/:orgId/companies/new/connect-accounting-software-return',
    error: '/orgs/:orgId/companies/new/error',
    roleDetails: '/orgs/:orgId/companies/new/role-details',
    contactInfo: '/orgs/:orgId/companies/new/contacts',
  },
  addAccountingFirm: {
    base: ['/orgs/:orgId/companies/add-accounting-firm'],
    fromEmail: '/orgs/:orgId/companies/add-accounting-firm/from-email',
    connectQuickbooks: '/orgs/:orgId/companies/add-accounting-firm/connect-quickbooks',
    quickbooksSuccess: '/orgs/:orgId/companies/add-accounting-firm/connect-quickbooks-success',
    quickbooksError: '/orgs/:orgId/companies/add-accounting-firm/connect-quickbooks-error',
    details: '/orgs/:orgId/companies/add-accounting-firm/details',
    name: '/orgs/:orgId/companies/add-accounting-firm/company-name',
    address: '/orgs/:orgId/companies/add-accounting-firm/address',
    manualAddress: '/orgs/:orgId/companies/add-accounting-firm/manual-address',
    clientsAmount: '/orgs/:orgId/companies/add-accounting-firm/clients-amount',
  },
  importClients: {
    verify: '/orgs/:orgId/companies/import-clients/verify',
    success: '/orgs/:orgId/companies/import-clients/success',
    instructions: '/orgs/:orgId/companies/import-clients/instructions',
    companyTypeError: '/orgs/:orgId/companies/import-clients/companyTypeError',
    connectionError: '/orgs/:orgId/companies/import-clients/connectionError',
    noClientsError: '/orgs/:orgId/companies/import-clients/noClientsError',
  },
} as const;
