import styled from 'styled-components';
import {
  AmountInputContainer,
  DefaultLogoContainer,
  Header,
  HeaderProps,
  VendorLogo,
  VendorSubTitle,
} from 'src/pages/vendor/components/VendorLayoutElements';
import { devices } from 'src/theme/appDevices';

export const ContentHeader = (props: HeaderProps) => <StyledHeader {...props} />;

const StyledHeader = styled(Header)`
  padding: 3.2rem 4rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 3.2rem 2rem;
  }

  ${DefaultLogoContainer} {
    width: 4rem;
    height: 4rem;
    margin: 0 auto 2rem;
    border: none;
    ${(props) => props.theme.text.fontType.hint};
  }

  ${VendorLogo} {
    width: 4rem;
    height: 4rem;
    margin: 0 auto 2rem;
    border: none;
  }

  ${VendorSubTitle} {
    margin-top: 0.8rem;
    text-transform: none;
  }

  ${AmountInputContainer} {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: -1.2rem;
  }
`;
