import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import {
  CompanyIndustryAutocomplete,
  OnSelectNaicsCode,
} from 'src/pages/onboarding/company-info/components/CompanyIndustryAutocomplete';
import { useVendorCompanyInfoUpdates } from 'src/pages/onboarding/vendor-company-info/hooks/useVendorCompanyInfoUpdates';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { industryApi, UpdateIndustryRequestData } from 'src/services/api/industry';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyIndustryPageContainer = ({ nextStepURL, prevStepURL }: Props) => {
  const [selectedIndustry, setSelectedIndustry] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();

  const relativeStep = isUnifiedOnboardingOpen ? 11 / 15 : 3 / 10;

  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');
  const { navigate } = useNavigator();

  const handleNextClick = async () => {
    if (!selectedIndustry) return;

    setIsLoading(true);
    try {
      analytics.track('onboarding-company-info-industry', 'continue', {
        selectedIndustry: naicsCode,
      });
      await industryApi.updateIndustry(orgId, { naicsCode, industryText });
      navigate(nextStepURL);
    } catch (err) {
      setIsLoading(false);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevClick = () => {
    navigate(prevStepURL as string, false, { origin });
  };

  const [{ naicsCode, industryText }, setNaicsData] = useState<UpdateIndustryRequestData>({
    naicsCode: null,
    industryText: '',
  });
  const [isFreeTextIndustry, setIsFreeTextIndustry] = useState(false);
  const onReset = () => {
    setNaicsData({ naicsCode: null, industryText: undefined });
    setIsFreeTextIndustry(false);
    setSelectedIndustry(false);
  };
  const onSelectNaicsCode: OnSelectNaicsCode = (naicsData) => {
    setNaicsData(naicsData);
    setSelectedIndustry(true);

    if (naicsData.industryText && naicsData.naicsCode === null) setIsFreeTextIndustry(true);
  };

  return (
    <StepLayoutPage
      title="onboarding.companyInfo.industry.title"
      titleValues={{
        companyName: (
          <>
            <br />
            <CompanyName>{companyInfo.companyName}</CompanyName>
          </>
        ),
      }}
      isNextDisabled={!(naicsCode || isFreeTextIndustry)}
      onPrev={handlePrevClick}
      onNext={handleNextClick}
      relativeStep={relativeStep}
      isLoading={isLoading}
    >
      <CompanyIndustryAutocomplete onSelectNaicsCode={onSelectNaicsCode} onReset={onReset} />
    </StepLayoutPage>
  );
};

const CompanyName = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;
