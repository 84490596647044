import { FC } from 'react';
import Box from 'src/core/ds/box/Box';

type Props = {
  error: boolean;
  focus: boolean;
};

const getBorderColor = (error: boolean, focus: boolean) => {
  if (error) return 'red.500';

  if (focus) return 'black';

  return 'grey.500';
};

export const FieldInputWrapper: FC<Props> = ({ error, focus, children }) => (
  <Box borderBottom="2px" color={getBorderColor(error, focus)} w="full">
    {children}
  </Box>
);
