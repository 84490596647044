import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { getOrgId } from 'src/redux/user/selectors';
import { companiesLocations } from '../../locations';

export const ImportClientsMenu = () => {
  const history = useHistory();
  const currentOrgId = useSelector(getOrgId);

  const handleImportQboClick = () => {
    history.push(generatePath(companiesLocations.importClients.instructions, { orgId: currentOrgId }));
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<Icon name={IconNames.caretDown} />}
        variant={ButtonVariants.tertiary}
        size={ButtonSizes.md}
        label="companies.importClientsMenu.menuButton"
        fullWidth
      />
      <MenuList>
        <MenuItem label="companies.importClientsMenu.fromQuickbooks" onClick={handleImportQboClick} />
      </MenuList>
    </Menu>
  );
};
