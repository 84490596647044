import { Loader } from 'src/components/common/Loader';
import Box from 'src/core/ds/box';
import { useBreakpointValue } from 'src/core/ds/hooks';
import { Wrap, WrapItem } from 'src/core/ds/wrap';
import { theme } from 'src/theme/defaultTheme';
import { SuggestedBillerType } from 'src/utils/types';
import SuggestedBillersOptionGroupLabel from './SuggestedBillersOptionGroupLabel';
import SuggestedListItem from './SuggestedListItem';

type SuggestedBillersProps = {
  data: Array<SuggestedBillerType>;
  onChangeSuggestedBillerValue: (value: string) => void;
  optionGroupLabel: string;
  isLoading?: boolean;
};

const breakpointsValues = { base: '100%', sm: '100%', md: '46%', lg: '46%', xl: '46%', '2xl': '46%' };

const SuggestedBillers = ({
  data,
  onChangeSuggestedBillerValue,
  optionGroupLabel,
  isLoading,
}: SuggestedBillersProps) => {
  const value = useBreakpointValue(breakpointsValues);

  return (
    <Box {...suggestedBillersContainer} position="relative">
      {isLoading ? (
        <Box p="12.8rem">
          <Loader color="primary" context="page" />
        </Box>
      ) : (
        <>
          <SuggestedBillersOptionGroupLabel label={optionGroupLabel} />
          <Wrap px={4} pb={4} spacing={4}>
            {data &&
              data.map((item, index) => (
                <WrapItem key={index} flexBasis={value} onClick={() => onChangeSuggestedBillerValue(item.companyName)}>
                  <SuggestedListItem title={item.companyName} category={item.note} iconLink={item.icon} />
                </WrapItem>
              ))}
          </Wrap>
        </>
      )}
    </Box>
  );
};

const suggestedBillersContainer = {
  bg: 'white',
  rounded: 'lg',
  mt: '-21px',
  boxShadow: `0 0 1rem 0 ${theme.colors.dark.translucent2}`,
  zIndex: 0,
  p: 2,
};

export default SuggestedBillers;
