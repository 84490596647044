import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { OrganizationCreateOrigin } from 'src/utils/consts';
import { useIsMsnDashboardDisplay } from './useIsMsnDashboardDisplay';

export const useIsOrganizationInMsnOnly = () => {
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);
  const isMsnDashboardDisplay = useIsMsnDashboardDisplay();

  return currentOrg?.createOrigin === OrganizationCreateOrigin.MSN && isMsnDashboardDisplay;
};
