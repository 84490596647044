import { IconNames } from 'src/core/ds/icon';

export enum NotificationVariant {
  success = 'success',
  error = 'error',
  info = 'info',
}

export const TOAST_BG_COLOR = {
  [NotificationVariant.success]: 'green.500',
  [NotificationVariant.error]: 'red.500',
  [NotificationVariant.info]: 'black',
};

export const DEFAULT_ICON_NAME = {
  [NotificationVariant.success]: IconNames.check,
  [NotificationVariant.error]: IconNames.alertCircle,
  [NotificationVariant.info]: null,
};
