import { getMaxLength } from '@melio/sizzers-js-common';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import customersStore from 'src/modules/customers/customers-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { useForm } from 'src/ui/form';
import { NavigationCallbacks } from 'src/utils/types';

export const NewCustomerPage = ({ onNext, onPrev, goExit }: NavigationCallbacks) => {
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const { selectors } = customersStore;
  const createStatus = useSelector(selectors.create.status());
  const isCreating = createStatus?.loading;
  const error = createStatus?.error || {};
  const validationErrors = error?.validationErrors;

  const contact = useMemo(
    () => ({
      companyName: '',
      contactEmail: '',
      contactName: '',
      contactPhone: '',
    }),
    []
  );

  const customersActions = getStoreActions(customersStore)(useDispatch());
  const onCreate = async (values) => {
    const {
      payload: { id },
    } = await customersActions.create({ orgId, ...values });
    onNext && onNext({ id });
  };
  const [contactDataMV, newContactActions] = useForm(contact, {
    submit: onCreate,
  });

  return (
    <StepLayoutPage
      title="contacts.create.customers.title"
      nextLabel="contacts.create.customers.addAction"
      fullWidthCTA
      onSubmit={newContactActions.submit}
      onPrev={onPrev}
      goExit={goExit}
      isLoading={isCreating}
      testId="new-customer"
    >
      <FormContainer>
        <TextField
          id="companyName"
          label="contacts.create.customers.companyName"
          helperText="contacts.create.customers.companyNameSubtitle"
          errorMessage={validationErrors?.companyName}
          model={contactDataMV.companyName}
          autoFocus
          isRequired
        />
        <TextField
          id="contactName"
          label="contacts.create.customers.contactName"
          model={contactDataMV.contactName}
          errorMessage={validationErrors?.contactName}
        />
        <PrivateDataContainer>
          <TextField
            id="contactEmail"
            label="contacts.create.email"
            model={contactDataMV.contactEmail}
            type="email"
            errorMessage={validationErrors?.contactEmail}
            maxLength={getMaxLength('vendor', 'contactEmail')}
          />
        </PrivateDataContainer>
        <PrivateDataContainer>
          <MaskField
            id="contactPhone"
            label="contacts.create.phone"
            model={contactDataMV.contactPhone}
            type="tel"
            format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
            errorMessage={validationErrors?.contactPhone}
            autoComplete="off"
            isModelOnChangeFormatted
          />
        </PrivateDataContainer>
      </FormContainer>
    </StepLayoutPage>
  );
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;
