import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useBreak } from 'src/hoc';
import { profileStore } from 'src/modules/profile/profile-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { analytics } from 'src/services/analytics';
import { ContactsTab } from 'src/utils/consts';
import { isNonNullable } from 'src/utils/isNonNullable';
import { ContactType, VendorType } from 'src/utils/types';

const isBatchSelectionTab = (tab: ContactsTab) => tab === ContactsTab.VENDORS;

export const useBatchContactSelection = (
  tab: ContactsTab,
  contacts: ReadonlySet<VendorType> | ReadonlySet<ContactType>
) => {
  const { isDesktop } = useBreak();
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const currentContactsIds = [...contacts]
    .map((contact) => contact.id)
    .filter(isNonNullable)
    .map((id) => Number(id));
  // REVIEWER: I didn't find a place where this is actually being used
  // const history = useHistory<{ selectedIds?: readonly string[] }>();
  const [selectedIds, setSelectedIds] = useState(new Set<number>());
  const selectedVendors = useSelector<any, VendorType[]>(vendorsStore.selectors.getByIds([...selectedIds]));
  const selectedIdsWithEmail = filter(selectedVendors, (vendor) => !isEmpty(vendor.contactEmail)).map(
    (vendor) => vendor.id
  );
  const selectedIdsWithoutEmail = filter(selectedVendors, (vendor) => isEmpty(vendor.contactEmail)).map(
    (vendor) => vendor.id
  );

  const analyticsProps = {
    userId,
    vendorIds: [...selectedIds],
    vendorIdsWithEmail: selectedIdsWithEmail,
    vendorIdsWithoutEmail: selectedIdsWithoutEmail,
    vendorIdsCount: [...selectedIds].length,
    vendorIdsWithEmailCount: selectedIdsWithEmail.length,
    vendorIdsWithoutEmailCount: selectedIdsWithoutEmail.length,
  };

  const batchSelectionEnabled = isDesktop && isBatchSelectionTab(tab);

  const setSelected = (id: number, isSelected: boolean) => {
    setSelectedIds((prevSelectedIds) => {
      const newSelectedIds = new Set(prevSelectedIds);

      if (isSelected) {
        newSelectedIds.add(id);
      } else {
        newSelectedIds.delete(id);
      }

      return newSelectedIds;
    });
  };

  const isAllSelected = currentContactsIds.every((id) => selectedIds.has(id));

  const selectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    const newSelectedIds = value ? currentContactsIds : [];

    analytics.track('contacts', 'batch-unilateral-select-all', {
      userId,
      selectedVendorsCount: newSelectedIds.length,
    });

    setSelectedIds(new Set(newSelectedIds));
  };

  const removeFromList = (vendorId: number) => {
    const removedVendor = selectedVendors.find((v) => v.id === vendorId);
    analytics.track('contacts', 'batch-unilateral-remove-from-list', {
      userId,
      vendorId,
      doesRemovedVendorHasEmail: removedVendor && !isEmpty(removedVendor.contactEmail),
    });
    const newSelectedIds = new Set(selectedIds);
    newSelectedIds.delete(vendorId);
    setSelectedIds(newSelectedIds);
  };

  const resetSelection = () => {
    setSelectedIds(new Set());
  };

  return {
    batchSelectionEnabled,
    selectedIds: batchSelectionEnabled ? [...selectedIds] : [],
    setSelected,
    selectAll,
    removeFromList,
    resetSelection,
    isAllSelected,
    analyticsProps,
  };
};
