import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';

type Props = {
  label: string;
  className?: string;
};

export const MIChip = ({ label, className }: Props): React.ReactElement => (
  <TagContainer className={className}>
    <MIFormattedText label={label} />
  </TagContainer>
);

type TagContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

const TagContainer = ({ children, className }: TagContainerProps) => (
  <Box as="span" className={className} sx={TagContainerStyle}>
    {children}
  </Box>
);

const TagContainerStyle: CSSObject = {
  textStyle: 'caption1',
  px: 1,
  borderRadius: 'md',
  textAlign: 'center',
  bgClip: 'padding-box',
  bgColor: 'grey.300',
  color: 'black',
};
