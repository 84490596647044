import { useCallback, useEffect, useMemo, useRef } from 'react';
import * as React from 'react';
import { IdType, Row, SortingRule } from 'react-table';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { DateTableRowType } from 'src/components/shared/tables/PaymentsTable/types';
import { usePagination } from 'src/core/components/pagination/hooks/usePagination';
import Box from 'src/core/ds/box';
import { RestListStatus } from 'src/helpers/redux/restListSlice';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { DataTable } from 'src/pages/get-pro/components/table/DataTable';
import { useColumnsByType } from 'src/pages/get-pro/components/table/hooks/useColumnsByType';
import { InvoicesQueryActions, PAGE_ITEM_COUNT } from 'src/pages/get-pro/consts';
import { useCheckListRefreshAvailability } from 'src/pages/get-pro/hooks/useCheckListRefreshAvailability';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import { getProLocations } from 'src/pages/get-pro/locations';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { GetProTabs, NotificationVariant } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { getInitialSortBy, MAP_ACTIONS, shouldPresentNewDataAvailableNotification } from '../../utils';
import { TableContentState } from './consts';
import { tableWrapperStyle } from './tableStyles';

type Props = {
  selectedIds: Record<IdType<string>, boolean>;
  setSelectedIds: (ids: Record<IdType<string>, boolean>) => void;
  clearSelected: boolean;
  toggleClearSelected: () => void;
  contentState?: TableContentState;
  content?: React.ReactNode;
  loadStatus: RestListStatus | undefined;
  loadGetProList: () => Promise<Record<string, any>>;
  items: any;
  currentDataLastUpdated: string;
  onChangeSort: (sort: SortingRule<DateTableRowType>[]) => void;
};

const GetProTable = ({
  selectedIds,
  setSelectedIds,
  clearSelected,
  toggleClearSelected,
  contentState,
  content,
  loadStatus,
  loadGetProList,
  currentDataLastUpdated,
  items,
  onChangeSort,
}: Props) => {
  const toastId = useRef<string | number | null>(null);
  const currentQueryParams = useQueryString();
  const [historyPush] = useHistoryWithOrgId();
  const { listParams, setPage: goToPage, filter, setAction, setActionParams } = useGetProListParams();
  const totalItems = loadStatus?.totalCount || 0;
  const { showPagination, pageCount, pageIndex } = usePagination({
    totalItems,
    limit: PAGE_ITEM_COUNT,
    setPage: goToPage,
    start: parseInt(listParams.filters.start, 10),
  });

  const {
    filters: { status: tab, sort },
  } = listParams;

  const [availableDataLastUpdated, isReFetched] = useCheckListRefreshAvailability(
    listParams,
    loadGetProList,
    currentDataLastUpdated
  );

  const newDataAvailable = useMemo(
    () => isReFetched && shouldPresentNewDataAvailableNotification(currentDataLastUpdated, availableDataLastUpdated),
    [availableDataLastUpdated, currentDataLastUpdated, isReFetched]
  );

  const disableCheckBoxes = [GetProTabs.IN_PROGRESS, GetProTabs.PAID].includes(tab);
  const [columns] = useColumnsByType(tab);

  const onRefreshClick = useCallback(() => {
    toastId.current = null;

    if (pageIndex > 0) {
      goToPage(0);
    } else {
      loadGetProList();
    }
  }, [loadGetProList, pageIndex, goToPage]);

  useEffect(() => {
    if (newDataAvailable && !checkIfToastIsDisplayedById(toastId.current)) {
      toastId.current = pushNotification({
        type: NotificationVariant.INFO,
        msg: 'getPro.pushNotifications.newDataAvailable',
        textValues: {
          refresh: (
            <Box as="a" textStyle="toastAction" ml={4} onClick={onRefreshClick}>
              <MIFormattedText label="getPro.pushNotifications.refresh" />
            </Box>
          ),
        },
        autoClose: false,
      });
    }
  }, [newDataAvailable, onRefreshClick, toastId]);

  const onSelectedRow = (row: Row<DateTableRowType>) => {
    const selectedId = row.original.id;

    if (selectedId) {
      historyPush({
        path: getProLocations.view,
        query: {
          ...currentQueryParams,
          id: selectedId,
        },
      });
    }
  };
  const getRowId = useCallback((row) => row.id, []);

  const filterRowsByQueryAction = ({
    rows,
    action,
    actionParams,
  }: {
    rows: Array<Row<DateTableRowType>>;
    action: InvoicesQueryActions;
    actionParams?: string;
  }) => MAP_ACTIONS[action]?.(rows, actionParams);

  const initialSortBy = getInitialSortBy(tab, sort);

  const initialState = useMemo(
    () => ({
      pageSize: items.length,
      pageIndex,
      selectedRowIds: selectedIds,
      sortBy: initialSortBy,
    }),
    [items.length, pageIndex, selectedIds, initialSortBy]
  );

  return (
    <Box __css={tableWrapperStyle}>
      <DataTable<DateTableRowType>
        name="GetPaidProTable"
        disableCheckBoxes={disableCheckBoxes}
        setSelectedIds={setSelectedIds}
        selectedIds={selectedIds}
        columns={columns}
        data={items}
        manualSortBy
        onClick={onSelectedRow}
        autoResetPage={false}
        onChangeSort={onChangeSort}
        manualPagination
        disableSortRemove
        autoResetSortBy={false}
        initialState={initialState}
        totalItems={totalItems}
        pageCount={pageCount}
        onPageIndexChange={goToPage}
        onSelectionChanged={setSelectedIds}
        clearSelected={clearSelected}
        toggleClearSelected={toggleClearSelected}
        getRowId={getRowId}
        initialSortBy={initialSortBy}
        contentState={contentState}
        content={content}
        showPagination={showPagination}
        filter={filter}
        action={listParams.filters.action}
        setAction={setAction}
        filterRowsByQueryAction={filterRowsByQueryAction}
        setActionParams={setActionParams}
        actionParams={listParams.filters.actionParams}
        tab={tab}
      />
    </Box>
  );
};

export default GetProTable;
