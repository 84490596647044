import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { UserErrorModal } from 'src/components/modals/UserErrorModal/UserErrorModal';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { useModal } from 'src/helpers/react/useModal';
import { useSelectedIds } from 'src/pages/regular-batch-payments/BatchSettings/useBatchSelection';
import { useMarkAsPaidBatchOption } from './useMarkAsPaidBatchOption';

export const MoreBatchActions = () => {
  const { selectedIds } = useSelectedIds();
  const [ErrorModalComponent, showErrorModal] = useModal(UserErrorModal, {
    id: 'batch-mark-as-paid-error-modal',
  });

  const {
    isDisabled: isMarkAsPaidDisabled,
    openMarkBillsModal,
    MarkBillsAsPaidModalComponent,
  } = useMarkAsPaidBatchOption({
    onError: showErrorModal,
  });

  return (
    <>
      <Menu autoSelect={false}>
        {({ isOpen }) => (
          <Tooltip
            isDisabled={!isMarkAsPaidDisabled}
            shouldWrapChildren
            placement="top"
            label={
              isMarkAsPaidDisabled ? (
                <MIFormattedText label="regularBatchPayments.BillsGallery.Footer.disabledTooltip" />
              ) : null
            }
          >
            <MenuButton
              isDisabled={isMarkAsPaidDisabled}
              data-testid="more-batch-actions-button"
              as={Button}
              label="regularBatchPayments.BillsGallery.Footer.moreActions"
              variant={ButtonVariants.secondary}
              rightIcon={<Icon name={isOpen ? IconNames.caretUp : IconNames.caretDown} />}
              w={{ base: 'full', md: 'inherit' }}
              mr="5"
            />
            <MenuList data-testid="footer-action-menu-list">
              <MenuItem
                onClick={openMarkBillsModal}
                label="regularBatchPayments.BillsGallery.Footer.batchMarkAsPaid"
                values={{ billsCount: selectedIds.length }}
                isDisabled={isMarkAsPaidDisabled}
                testId="batch-mark-as-paid-menu-item"
              />
            </MenuList>
          </Tooltip>
        )}
      </Menu>
      {MarkBillsAsPaidModalComponent}
      {ErrorModalComponent}
    </>
  );
};
