import toInteger from 'lodash/toInteger';
import { PAGINATION } from './consts';

const getVisiblePages = (currentPage: number, totalPagesCount: number, visiblePagesCount: number): number[] => {
  const visiblePages = [currentPage];
  const pagesCount = visiblePagesCount > totalPagesCount ? totalPagesCount : visiblePagesCount;

  while (visiblePages.length < pagesCount) {
    const prevPageValue = visiblePages[0] - 1;

    if (prevPageValue > 0) {
      visiblePages.unshift(prevPageValue);
    }

    const nextPageValue = visiblePages[visiblePages.length - 1] + 1;

    if (visiblePages.length < pagesCount && nextPageValue <= totalPagesCount) {
      visiblePages.push(nextPageValue);
    }
  }

  return visiblePages;
};

const calculateTotalPagesCount = (total: number, limit: number) => Math.floor((total - 1) / limit) + 1;

const calculateCurrentPage = (start: number, limit: number, total: number) => {
  const newCurrentPage = Math.floor(start / limit) + 1;
  const totalPagesCount = calculateTotalPagesCount(total, limit);

  return newCurrentPage > totalPagesCount ? totalPagesCount : newCurrentPage;
};

const calculateNewStart = (page: number, limit: number) => (page - 1) * limit;

const calculateMorePrevPage = (
  currentPage: number,
  visiblePagesCount: number = PAGINATION.DEFAULT_VISIBLE_PAGES_COUNT
) => {
  const newCurrentPage = currentPage - visiblePagesCount;

  return newCurrentPage > 0 ? newCurrentPage : 1;
};

const calculateMoreNextPage = (
  currentPage: number,
  totalPagesCount: number,
  visiblePagesCount: number = PAGINATION.DEFAULT_VISIBLE_PAGES_COUNT
) => {
  const newCurrentPage = currentPage + visiblePagesCount;

  return newCurrentPage < totalPagesCount ? newCurrentPage : totalPagesCount;
};

const convertStart = (start: any): number => {
  const convertedStart = toInteger(start);

  return convertedStart >= 0 ? convertedStart : PAGINATION.DEFAULT_START;
};

const convertLimit = (limit: any): number => {
  const convertedLimit = toInteger(limit);

  return convertedLimit > 0 ? convertedLimit : PAGINATION.DEFAULT_LIMIT;
};

export {
  calculateCurrentPage,
  calculateMoreNextPage,
  calculateMorePrevPage,
  calculateNewStart,
  calculateTotalPagesCount,
  convertLimit,
  convertStart,
  getVisiblePages,
};
