import React from 'react';
import { ButtonVariants } from 'src/core/ds/button';
import { FullWidthButton } from 'src/pages/spend-management/components/table/components/styles';
import { FooterContainer } from './FooterContainer';

type CreateFooterProps = {
  isLoading: boolean;
  onPrimary: () => void;
};

export const CreateFooter = ({ isLoading, onPrimary }: CreateFooterProps) => (
  <FooterContainer>
    <FullWidthButton
      label="spendManagement.pages.dashboard.createCard.actions.createCard"
      variant={ButtonVariants.primary}
      onClick={onPrimary}
      isLoading={isLoading}
    />
  </FooterContainer>
);
