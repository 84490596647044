import forEach from 'lodash/forEach';
import isMatch from 'lodash/isMatch';
import map from 'lodash/map';

export function asyncPromiseMiddleware() {
  const waitingActions = new Map();

  return (next: any) => (action: any) => {
    if (action?.meta?.$async) {
      next(action);

      return new Promise((resolve, reject) => {
        forEach(action.meta.$async, ({ resolution, ...info }) => {
          waitingActions.set(info.type, {
            identifier: info,
            types: map(action.meta.$async, (a) => a.type),
            callback: resolution === 'resolve' ? resolve : reject,
          });
        });
      });
    } else if (waitingActions.has(action.type)) {
      const { callback, identifier, types } = waitingActions.get(action.type);

      if (isMatch(action, identifier)) {
        types.forEach((type) => waitingActions.delete(type));
        const res = next(action);
        callback(res);

        return res;
      }
    }

    return next(action);
  };
}
