import reduce from 'lodash/reduce';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { getProStore } from 'src/modules/get-pro/get-pro-store';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import { GetProTabsSummary } from 'src/pages/get-pro/types';
import { getOrgId } from 'src/redux/user/selectors';
import { GetProTabs } from 'src/utils/consts';

const getTabId = (tab: GetProTabs) => `list.tab.getPro.${tab}`;

export const useTabsData = (): Record<string, { numberOfItems: number; amount: string }> | null => {
  const orgId = useSelector(getOrgId);
  const proActions = useStoreActions(getProStore);
  const tabsSummaryData = useSelector<Record<GetProTabs, GetProTabsSummary>>(
    getProStore.selectors.getProTabsSummary.tabsSummary
  );
  const { search, listParams } = useGetProListParams();
  const { contactId } = listParams.filters;

  useEffect(() => {
    proActions.getProTabsSummary({
      orgId,
      filters: {
        search,
        contactId,
      },
    });
  }, [orgId, search, contactId]);

  if (!tabsSummaryData) {
    return null;
  }

  return reduce(
    tabsSummaryData,
    (result, { totalCount, totalAmount }, tab) => {
      result[getTabId(tab as GetProTabs)] = {
        numberOfItems: totalCount,
        amount: totalAmount.toString(),
      };

      return result;
    },
    {}
  );
};
