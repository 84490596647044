import { featureFlags } from '@melio/shared-web';
import get from 'lodash/get';
import { supportApi } from 'src/modules/support/api';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/consts';
import { isLoginFromForest } from 'src/utils/user';
import config from '../config';

const eventPage = 'melio';
const zendeskOpeningSucceeded = 'zendesk-open-success';
const zendeskOpeningFailed = 'zendesk-open-failed';
const zendeskGetTokenError = 'zendesk-get-token-error';

enum ChatOption {
  Intercom,
  Zendesk,
  ZendeskMessaging,
}

export const intercomService = {
  attempts: 8,
  enabled: true,
  forethoughtInjected: false,
  zendeskInjected: false,
  zendeskMessagingInjected: false,
  chatOption: ChatOption.Intercom,

  resolveChatOption() {
    const shouldDisplayZendesk = featureFlags.defaultClient.getVariant(FeatureFlags.SwitchToZendeskChat, false);

    if (shouldDisplayZendesk) {
      const shouldDisplayZendeskMessaging = featureFlags.defaultClient.getVariant(FeatureFlags.ZendeskMessaging, false);

      if (shouldDisplayZendeskMessaging) {
        return ChatOption.ZendeskMessaging;
      }

      return ChatOption.Zendesk;
    }

    return ChatOption.Intercom;
  },
  setChat({ isHideDefault, isLoggedInAs, user, intercomSettings, canContactSupport }) {
    this.chatOption = this.resolveChatOption();

    switch (this.chatOption) {
      case ChatOption.Zendesk:
        this.injectZendesk({ user, isLoggedInAs, canContactSupport });
        break;
      case ChatOption.ZendeskMessaging:
        this.injectZendeskMessaging({ user, isLoggedInAs, canContactSupport });
        break;
      case ChatOption.Intercom:
      default:
        this.initIntercom({ isHideDefault, isLoggedInAs, user, intercomSettings, canContactSupport });
    }
  },
  injectZendeskMessaging(params) {
    // script already loaded
    if (this.zendeskMessagingInjected) return;

    const apiKey = config.services.zendesk.messagingKey;

    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
    script.id = 'ze-snippet';

    // handle zendesk init
    script.onload = () => this.initZendeskMessaging(params);

    // avoid reinject the script in the future
    this.zendeskMessagingInjected = true;

    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);
    document.body.appendChild(script);
  },
  injectZendesk(params) {
    // script already loaded
    if (this.zendeskInjected) return;

    const apiKey = config.services.zendesk.webWidgetKey;

    const script = document.createElement('script');
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
    script.id = 'ze-snippet';

    // handle zendesk init
    script.onload = () => this.initZendesk(params);

    // avoid reinject the script in the future
    this.zendeskInjected = true;

    document.head.insertAdjacentHTML('beforeend', `<style>iframe#launcher { display: none; }</style>`);
    document.body.appendChild(script);
  },
  initZendeskMessaging({ user, isLoggedInAs, canContactSupport }) {
    const isDisabled = isLoginFromForest() || isLoggedInAs || !canContactSupport;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const zendesk = window.zE;

    if (!zendesk && this.attempts > 0) {
      // waiting for zendesk object
      this.attempts -= 1;
      setTimeout(() => this.initZendeskMessaging({ user, isLoggedInAs, canContactSupport }), 500);

      return;
    }

    if (!zendesk || isDisabled) {
      // chat is not available
      this.enabled = false;

      return;
    }

    const shouldAuthenticateUser = featureFlags.defaultClient.getVariant(
      FeatureFlags.ZendeskMessagingAuthentication,
      false
    );

    if (shouldAuthenticateUser) {
      // chat can be opened:
      // from the show() method;
      // from the Feedback widget;
      zendesk('messenger:on', 'open', () => {
        this.logUserIntoZendesk();
      });
    }

    // always start with closed state
    zendesk('messenger', 'close');
    this.enabled = true;
  },
  initZendesk({ user, isLoggedInAs, canContactSupport }) {
    const isDisabled = isLoginFromForest() || isLoggedInAs || !canContactSupport;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const zendesk = window.$zopim;

    if (!zendesk && this.attempts > 0) {
      // waiting for zendesk object
      this.attempts -= 1;
      setTimeout(() => this.initZendesk({ user, isLoggedInAs, canContactSupport }), 500);

      return;
    }

    if (!zendesk || isDisabled) {
      // chat is not available
      this.enabled = false;

      // hide widget floating button (for isDisabled case)
      if (zendesk) zendesk?.livechat?.button?.hide();

      return;
    }

    this.enabled = true;

    // save user data for chat session
    this.setData(user);
    // inject forethought script to extend chat
    this.injectForethought(user);
  },
  initIntercom({ isHideDefault, isLoggedInAs, user, intercomSettings, canContactSupport }) {
    // will be removed after switching to zendesk
    if (
      !config.services.intercom ||
      !config.services.intercom.isShow ||
      isLoginFromForest() ||
      isLoggedInAs ||
      !canContactSupport
    ) {
      return;
    }

    if (intercomSettings) {
      window.intercomSettings = intercomSettings;
    }

    const icConfig = {
      app_id: config.services.intercom.app_id,
      email: get(user, 'email', null),
      user_id: get(user, 'id', null),
      hide_default_launcher: isHideDefault,
    };

    if (window.Intercom) {
      if (window.Intercom.booted) {
        window.Intercom('update', icConfig);
      } else {
        window.Intercom('boot', icConfig);
      }
    } else {
      setTimeout(() => {
        if (window.Intercom) {
          window.Intercom('boot', icConfig);
        }
      }, 4000);
    }
  },
  setData(user) {
    try {
      // sending user data to support
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const zendesk = window.$zopim;
      const { email, firstName, lastName, orgId, id } = user;
      zendesk(() => {
        const { livechat } = zendesk;
        livechat.setName(`${firstName} ${lastName}`);
        livechat.setEmail(email);
        // custom tags
        livechat.addTags(`orgId-${orgId}`);
        livechat.addTags(`userId-${id}`);
      });
    } catch (error) {
      // disable chat if cannot send user data
      this.enabled = false;
    }
  },
  injectForethought(user) {
    const { email, firstName, lastName } = user;

    // script already loaded
    if (this.forethoughtInjected) return;

    const script = document.createElement('script');
    script.src = 'https://solve-widget.forethought.ai/embed.js';
    script.type = 'application/javascript';
    script.setAttribute('data-api-key', '74ba8f52-1c1e-4b60-8b1e-bc74bcdd4a0d');
    script.setAttribute('data-ft-current_page_url', 'window.location.href');
    script.setAttribute('data-ft-verbose', 'false');
    script.setAttribute('data-ft-name', `${firstName} ${lastName}`);
    script.setAttribute('data-ft-email', email);
    script.setAttribute('data-ft-workflow', 'Melio Routing');
    script.setAttribute('data-ft-tag', '');
    script.setAttribute('data-ft-tag_2', '');
    script.setAttribute('data-ft-dummy', '');
    // hide forethought UI
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    script.onload = () => window?.Forethought?.('widget', 'hide');
    // hiding forethougt button after closing its window
    window.addEventListener('message', (e) => {
      if (e.data.event === 'forethoughtWidgetClosed') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.Forethought('widget', 'hide');
      }
    });
    // avoid reinject the script in the future
    this.forethoughtInjected = true;
    document.body.appendChild(script);
  },
  show() {
    switch (this.chatOption) {
      case ChatOption.Zendesk:
        this.showZendesk();
        break;
      case ChatOption.ZendeskMessaging:
        this.showZendeskMessaging();
        break;
      case ChatOption.Intercom:
      default:
        this.showIntercom();
    }
  },
  async getZendeskToken() {
    const { token } = await supportApi.getZendeskToken();

    return token;
  },
  logUserIntoZendesk() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const zendesk = window.zE;

    zendesk('messenger', 'loginUser', async (callback: (token: string) => void) => {
      try {
        // zendesk reruns this function on 401 (e.g. when the token expires during chatting),
        // so adding the new token getting here to handle the token expiration
        const zendeskMessagingToken = await this.getZendeskToken();

        callback(zendeskMessagingToken);
      } catch (e) {
        analytics.track(eventPage, zendeskGetTokenError);
      }
    });
  },
  showZendeskMessaging() {
    if (!this.enabled) {
      // chat is not allowed for this user or loading failed
      analytics.track(eventPage, zendeskOpeningFailed);

      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const zendesk = window.zE;

    if (zendesk) {
      zendesk('messenger', 'open');
      analytics.track(eventPage, zendeskOpeningSucceeded);
    } else {
      analytics.track(eventPage, zendeskOpeningFailed);
    }
  },
  showZendesk() {
    if (!this.enabled) {
      // chat is not allowed for this user or loading failed
      analytics.track(eventPage, zendeskOpeningFailed);

      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { livechat } = window.$zopim;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forethought = window.Forethought;

    if (livechat.isChatting()) {
      // qbm android shows zendesk right away - forethought is not working there
      // show zendesk chat if there is an active session
      livechat.window.show();
    } else {
      // showing forethought at start - they will open zendesk in their flow
      // we first open it and then show to avoid floating button in the screen
      forethought('widget', 'open');
      forethought('widget', 'show');
    }

    analytics.track(eventPage, zendeskOpeningSucceeded);
  },
  showIntercom() {
    // will be removed after switching to zendesk
    if (window.Intercom) {
      window.Intercom('show');
    }
  },
  shutdown() {
    switch (this.chatOption) {
      case ChatOption.Zendesk:
        this.shutdownZendesk();
        break;
      case ChatOption.ZendeskMessaging:
        this.shutdownZendeskMessaging();
        break;
      case ChatOption.Intercom:
      default:
        this.shutdownIntercom();
    }
  },
  shutdownZendeskMessaging() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const zendesk = window.zE;

    if (zendesk) zendesk('messenger', 'hide');
  },
  shutdownZendesk() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const zendesk = window?.$zopim;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const forethought = window?.Forethought;

    if (zendesk) zendesk.livechat.window.hide();

    if (forethought) forethought('widget', 'hide');
  },
  shutdownIntercom() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }
  },
  clearChat() {
    switch (this.chatOption) {
      case ChatOption.Zendesk:
      case ChatOption.ZendeskMessaging:
        this.enabled = false;
        this.shutdown();
        break;
      case ChatOption.Intercom:
      default:
        this.initIntercom({
          isHideDefault: true,
          isLoggedInAs: false,
          user: false,
          intercomSettings: null,
          canContactSupport: true,
        });
    }
  },
};
