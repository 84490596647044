import constant from 'lodash/constant';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { MIAutocomplete, RenderSuggestionsProps } from 'src/components/common/MIAutocomplete';
import {
  MIAutocompleteOnSelected,
  MIAutocompleteSuggestion,
  MIAutocompleteSuggestionType,
} from 'src/components/common/MIAutocompleteExtras';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { industryApi, UpdateIndustryRequestData } from 'src/services/api/industry';

export type OnSelectNaicsCode = (data: UpdateIndustryRequestData) => void;

interface Props {
  onReset: () => void;
  onSelectNaicsCode: OnSelectNaicsCode;
  defaultValue?: string;
}

type NaicsSuggestion = MIAutocompleteSuggestionType<number>;
type OnSelected = MIAutocompleteOnSelected<number>;

const returnTrue = constant(true);
const renderSuggestions = () => ({ suggestions, onSelectedOption, text }: RenderSuggestionsProps<number>) => (
  <>
    {suggestions.map((option) => (
      <MIAutocompleteSuggestion.ExpandedSuggestion
        isExpanded
        onSelectedOption={onSelectedOption}
        option={option}
        key={option.label}
        render={(option) =>
          MIAutocompleteSuggestion.renderBasicHighlight({
            option,
            text,
          })
        }
      />
    ))}
  </>
);

export const CompanyIndustryAutocomplete = ({ onReset, onSelectNaicsCode, defaultValue }: Props) => {
  const orgId = Number(useSelector(getOrgId));
  const [industryText, setIndustryText] = useState(defaultValue);
  const [suggestionsCount, setCount] = useState<number>(0);

  const onClickCreateOption = () => {
    onSelectNaicsCode({ naicsCode: null, industryText });
  };

  const onChangeIndustry = useCallback(
    (value: string) => {
      onReset();
      setIndustryText(value);
    },
    [onReset]
  );

  const onSelectedIndustry: OnSelected = useCallback(
    (suggestion) => {
      if (suggestion) {
        const { value: naicsCode, label } = suggestion;
        analytics.track('onboarding-company-info-industry', 'select-industry', {
          searchTerm: industryText,
          suggestionsCount,
          selectedIndustry: naicsCode,
        });
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        onSelectNaicsCode({ naicsCode: naicsCode!, industryText: label });
        setIndustryText(label);
      } else {
        onReset();
      }
    },
    [industryText, suggestionsCount, onSelectNaicsCode, onReset]
  );

  const onSearchSuggestions = useCallback(
    async (q: string): Promise<NaicsSuggestion[]> => {
      const req = await industryApi.getAutocomplete(orgId, q);

      setCount(req.suggestions.length);

      return req.suggestions.map(({ title, naicsCode, subtitle }) => ({
        label: title,
        value: naicsCode,
        sublabel: subtitle,
      }));
    },
    [orgId]
  );

  return (
    <MIAutocomplete
      id="companyIndustry"
      onType={onChangeIndustry}
      text={industryText}
      placeholder="onboarding.companyInfo.industry.placeholder"
      getSuggestions={onSearchSuggestions}
      onSelected={onSelectedIndustry}
      leftSideIcon="search-magnifier-icon"
      renderSuggestionsFromLength={2}
      shouldShowSuggestions={returnTrue}
      renderSuggestions={renderSuggestions()}
      onClickCreateOption={onClickCreateOption}
      stickyLabel="onboarding.companyInfo.industry.useTextAsIndustry"
    />
  );
};
