import isNil from 'lodash/isNil';
import { useEffect } from 'react';

export const useSyncSkipAmountToRequireApproval = (
  requireApproval: boolean | null,
  setSkipAmount: (skip: boolean) => void
) => {
  useEffect(() => {
    if (!isNil(requireApproval)) {
      setSkipAmount(!requireApproval);
    }
  }, [requireApproval, setSkipAmount]);
};
