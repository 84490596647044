import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import styled, { css } from 'styled-components';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { VendorCardTitle } from 'src/components/common/VendorCardTitle';
import { ListItemContainer } from 'src/components/list/ListItemContainer';
import ListItemLabel from 'src/components/list/ListItemLabel';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import { Checkbox } from 'src/core/ds/checkbox';
import { InvoiceType } from 'src/modules/invoices/types';
import getInvoiceTab from 'src/pages/get-paid/utils';
import { analytics } from 'src/services/analytics';
import { InvoiceStatus, MiMoneyFlavor, PaymentRequestCreateOrigin, PaymentRequestTabs } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { getLatestPayment } from 'src/utils/payments';
import { shouldShowCheckBox } from './utils';

const tagsByStatus = {
  [InvoiceStatus.FAILED]: {
    label: 'list.group.invoice.listTagStatus.failed',
    color: BadgeColors.DANGER,
  },
};

const getInvoiceDate = (invoice) => {
  const latestPayment = getLatestPayment(invoice.payments || []);
  const invoiceDateByTab = {
    [PaymentRequestTabs.UNSENT]: {
      displayDate: invoice.dueDate,
      dateLabel: 'requests.view.dueDate',
    },
    [PaymentRequestTabs.SENT]: {
      displayDate: invoice.dueDate,
      dateLabel: 'requests.view.dueDate',
    },
    [PaymentRequestTabs.SCHEDULED]: {
      displayDate: undefined,
      dateLabel: 'requests.view.paymentScheduled',
    },
    [PaymentRequestTabs.PAID]: {
      displayDate: latestPayment?.paidDate ?? latestPayment?.deliveryEta,
      dateLabel: 'requests.view.deliveryEtaPaid',
    },
  };

  return invoiceDateByTab[getInvoiceTab(invoice)];
};

const getLabels = (invoice: InvoiceType): string[] => {
  const requestedOrigins = [PaymentRequestCreateOrigin.EXTERNAL_API, PaymentRequestCreateOrigin.REQUEST];
  const userCreateOrigins = [
    PaymentRequestCreateOrigin.MELIOME,
    PaymentRequestCreateOrigin.MCB,
    PaymentRequestCreateOrigin.MELIOME_GENERIC_LINK,
  ];
  const tabsWithLabels = [InvoiceStatus.SCHEDULED, InvoiceStatus.PAID];

  if (!tabsWithLabels.includes(invoice.status)) {
    return [];
  }

  // Empty invoice.createOrigin actually means bill matched from Pay product here
  if (!invoice.createOrigin || userCreateOrigins.includes(invoice.createOrigin)) {
    return ['list.group.bill.createdByCustomer'];
  }

  if (requestedOrigins.includes(invoice.createOrigin)) {
    return ['list.group.bill.paymentRequested'];
  }

  // Prefer to return empty value if we can't assign the label confidently. Mostly relevant for future cases
  return [];
};

type Props = {
  invoice: InvoiceType;
  search?: string;
  isSelected: boolean;
  isMarked: boolean;
  setMarked: (id: string, makred: boolean) => void;
  setSelected: (id: string) => void;
  showCheckbox: boolean;
  enableMultiSelect: boolean;
  selectedId?: string;
  disabled?: boolean;
};

const InvoiceListItem = ({
  invoice,
  search,
  isSelected,
  setSelected,
  isMarked,
  setMarked,
  showCheckbox,
  enableMultiSelect,
  selectedId,
  disabled,
}: Props) => {
  const [hovered, setHovered] = useState(false);
  const { invoiceNumber, totalAmount, status, id } = invoice;
  const customerName = invoice?.customer?.contactName;
  const tagStatus = tagsByStatus[invoice.status];
  const isPaid = status === InvoiceStatus.PAID;
  const isMarkedAsPaid = !isEmpty(invoice.markedAsPaidAt);
  const flavor = isPaid || isMarkedAsPaid ? MiMoneyFlavor.POSITIVE : MiMoneyFlavor.DEFAULT;
  const { displayDate, dateLabel } = getInvoiceDate(invoice);
  const query = useQueryString();
  const deliveryEta = getLatestPayment(invoice?.payments || [])?.deliveryEta;
  const handleClick = () => {
    if (query.ids) return;

    analytics.track('payment-request', 'nav-to-single', { status });

    if (invoice.id !== selectedId) {
      setSelected(invoice.id);
    } else {
      setSelected('');
    }
  };

  const onMouseEnter = () => {
    if (!disabled) {
      setHovered(true);
    }
  };

  const onMouseLeave = () => {
    if (!disabled) {
      setHovered(false);
    }
  };

  const onCheckInvoiceCard = (isSelected) => {
    if (!disabled) {
      analytics.track('payment-request', 'checkbox-select', {
        isSelected,
        invoiceId: id,
      });
      setMarked(id, isSelected);
    }
  };

  const invoiceNumberComp = (
    <MIFieldOrEmpty
      color="grey.700"
      value={
        <Highlighter searchWords={[search]} autoEscape textToHighlight={invoiceNumber ? `#${invoiceNumber}` : ''} />
      }
      label="bills.form.invoiceNumberEmpty"
    />
  );

  const labels = getLabels(invoice);

  const shouldDisplayCheckBox = useMemo(
    () => shouldShowCheckBox(enableMultiSelect, showCheckbox, hovered, invoice.status === InvoiceStatus.FAILED),
    [enableMultiSelect, showCheckbox, hovered, invoice]
  );

  return (
    <ListItemContainer
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isSelected={isSelected && !isMarked}
      onClick={handleClick}
      testId="getPaid.list-item"
    >
      <ItemHeader>
        {shouldDisplayCheckBox && (
          <Checkbox isChecked={isMarked} onChange={(e) => onCheckInvoiceCard(e.target.checked)} />
        )}
        {customerName &&
          VendorCardTitle({
            name: customerName,
            search,
            showBadge: !shouldDisplayCheckBox,
          })}
        {tagStatus && !isMarkedAsPaid && <Badge {...tagStatus} />}
      </ItemHeader>
      <ItemData>
        <TextualData>
          <RelDate data-testid="list-item-scheduled-date">
            <MIFormattedText label={dateLabel} values={{ date: <MIFormattedDate date={displayDate} /> }} />
          </RelDate>
          <InvoiceNumber>{invoiceNumberComp}</InvoiceNumber>
        </TextualData>
        <BalanceWrapper>
          <MIMoney
            amount={totalAmount}
            search={search}
            flavor={flavor}
            showAmountArrowIcon={isPaid || isMarkedAsPaid}
            privateData
          />
        </BalanceWrapper>
      </ItemData>
      {labels.map((label) => (
        <ListItemLabel key={label} label={label} />
      ))}
      {deliveryEta && invoice.status === InvoiceStatus.SCHEDULED && (
        <DeliveryEta>
          <MIFormattedText label="bills.view.deliveryEta" values={{ date: <MIFormattedDate date={deliveryEta} /> }} />
        </DeliveryEta>
      )}
    </ListItemContainer>
  );
};

export default InvoiceListItem;

const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
`;

const ItemData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TextualData = styled.div`
  display: inline-block;
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const RelDate = styled.div`
  ${baseTextStyles}
  line-height: 1.8rem;
`;

const InvoiceNumber = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
`;

const BalanceWrapper = styled.div`
  margin-left: 1rem;
`;

const DeliveryEta = styled.div`
  ${(props) => props.theme.text.fontType.hint};
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  color: ${(props) => props.theme.text.color.subtitle};
  margin-top: 1.4rem;
  margin-right: -2rem;
  padding-right: 2rem;
  padding-top: 2rem;
`;
