import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box';
import { BillStatus, MiMoneyFlavor, PaymentStatus } from 'src/utils/consts';
import { isPaymentFailedRefund, isRefundPaymentFlow } from 'src/utils/payments';
import { PaymentType } from 'src/utils/types';
import { SectionHeader } from './BillsGallery/PaymentBatchView';

export const PaymentTransactionStatus: React.FC<{ payment: PaymentType }> = ({ payment }) => {
  const isFailedRefund = isPaymentFailedRefund(payment);
  const refundPayment = isRefundPaymentFlow(payment);

  const getPaymentActivityTitle = () => {
    if (payment.status === PaymentStatus.FAILED) {
      if (isFailedRefund || refundPayment) {
        return 'bills.form.paymentActivity.refundAmount';
      }

      return 'bills.form.paymentActivity.failedPaymentTitle';
    }

    return 'bills.form.paymentActivity.fullPaymentTitle';
  };

  const paymentActivityTitle = getPaymentActivityTitle();

  return (
    <SectionHeader>
      <Header>
        <Box color="main" textStyle="body2Semi">
          <MIFormattedText label={paymentActivityTitle} />
        </Box>
      </Header>
      <MIMoney
        testId="payment-amount"
        amount={payment.amount || 0}
        flavor={MiMoneyFlavor.DEFAULT}
        showAmountArrowIcon={payment.status === BillStatus.PAID}
        privateData
      />
    </SectionHeader>
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
