import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { convertNumberToCurrency } from 'src/utils/currency-utils';

type Props = {
  amountPerMonth: number;
  totalFees: number;
  totalFeesPercent: number;
  paymentAmount: number;
};

export const InstallmentPlanDetails = ({ amountPerMonth, totalFees, totalFeesPercent, paymentAmount }: Props) => (
  <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
    <Flex flexDirection="column" alignItems="flex-end">
      <Box textStyle="body2Semi" marginBottom={1} data-testid="amount-per-month">
        <MIFormattedText
          label="financing.installmentsOptions.content.amountPerMonth"
          values={{
            amountPerMonth: convertNumberToCurrency(amountPerMonth),
          }}
          privateData
        />
      </Box>
      <Box textStyle="body4" color="grey.700" marginBottom={0.5} data-testid="total-fees">
        <MIFormattedText
          label="financing.installmentsOptions.content.totalFees"
          values={{
            totalFees: convertNumberToCurrency(totalFees),
            totalFeesPercent,
          }}
          privateData
        />
      </Box>
      <Box textStyle="body4" color="grey.700" data-testid="payment-amount">
        <MIFormattedText
          label="financing.installmentsOptions.content.paymentAmount"
          values={{
            paymentAmount: convertNumberToCurrency(paymentAmount),
          }}
          privateData
        />
      </Box>
    </Flex>
  </Flex>
);
