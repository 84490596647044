import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { internationalLocations } from 'src/pages/vendor/international-delivery-method/locations';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { NavigateType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
};

export const useGoToPage = ({ navigate }: Params) => {
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);

  const goToPaymentPurposePage = () => {
    navigate(
      generatePath(internationalLocations.paymentPurpose, {
        orgId,
        id: bill.vendorId ?? undefined,
      }),
      false,
      {
        billId: bill.id,
      }
    );
  };

  return { goToPaymentPurposePage };
};
