import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks/usePayablesConnectedAccountingPlatform';
import { billLocations } from 'src/pages/bill/locations';
import { useGoToPage } from 'src/pages/bill/pay/hooks/useGoToPage';
import { payBillEventPage } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import {
  getBill,
  getIsRecurring,
  getPayment,
  getRecurringBill,
  getScannedInvoiceId,
} from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, getBillTag } from 'src/utils/bills';
import {
  AddFundingSourceWizardOrigin,
  DeliveryType,
  FeatureFlags,
  PayEditLocationName,
  PaymentApprovalStatus,
  PaymentStatus,
} from 'src/utils/consts';
import { isOptedInToVirtualCardVendor } from 'src/utils/delivery-methods';
import { hasFailedInstallment } from 'src/utils/financing';
import { isDirectPayment, isVirtualCardPayment } from 'src/utils/payments';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { AccountType, NavigateType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
};

export const useFundingSourcesNavigate = ({ navigate, nextStepURL, prevStepURL }: Params) => {
  const location = useLocation();
  const locationState = location.state as Record<string, string>;

  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const bill = useSelector(getBill);
  const isRecurring = useSelector(getIsRecurring);
  const scannedInvoiceId = useSelector(getScannedInvoiceId);
  const recurringBill = useSelector(getRecurringBill);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);

  const { getUrl } = usePayBillNavigateCommon({
    navigate,
    nextStepURL,
    prevStepURL,
  });
  const isInternationalPayment = payment?.deliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL;
  const { goToPaymentPurposePage } = useGoToPage({ navigate });
  const [isBusinessDirectorySearchEnabled] = featureFlags.useFeature(FeatureFlags.BusinessDirectorySearch, false);

  const onNextFundingSources = (selectedFundingSource?: AccountType) => {
    analytics.track(payBillEventPage, 'funding-source-continue', {
      fundingSourceId: selectedFundingSource?.id,
      billPaymentIndex: getBillPaymentIndex(bill),
    });
    const url = getUrlAfterFundingSource();

    if (isConnectedAccountingPlatform && !selectedFundingSource?.intuitAccountId) {
      navigate(onboardingLocations.fundingSources.bank.account, false, {
        fundingSourceId: selectedFundingSource?.id,
        redirectUrl: url,
        origin: AddFundingSourceWizardOrigin.PAY_BILL,
        exitUrl: getUrl(PayEditLocationName.FUNDING),
      });
    } else if (isInternationalPayment) {
      goToPaymentPurposePage();
    } else {
      navigate(url);
    }
  };

  const onPrevFundingSources = () => {
    if (isRecurring) {
      const eventName = `set-${getEventNameFromLocation(location)}`;
      analytics.track(payBillEventPage, `${eventName}-back`, {
        billPaymentIndex: getBillPaymentIndex(bill),
      });

      if (scannedInvoiceId) {
        navigate(`${billLocations.createScannedInvoice}?id=${scannedInvoiceId}`, false, {
          scannedInvoiceId,
          origin: AddFundingSourceWizardOrigin.PAY_BILL,
        });
      } else {
        navigate(billLocations.create.index, false, {
          manually: true,
          ...locationState,
          bill,
          recurringBill,
        });
      }
    }
  };

  const getUrlAfterFundingSource = () => {
    const status = getBillTag(bill);

    if (status === PaymentStatus.FAILED && payment?.id) {
      return generatePath(billLocations.pay.edit.fastPayment, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      });
    } else if (isRecurring) {
      if (isDirectPayment(payment)) {
        return billLocations.pay.recurring.confirm;
      } else if (payment.deliveryMethodId) {
        return billLocations.pay.recurring.memo;
      }

      return billLocations.pay.recurring.deliveryMethod;
    } else if (payment.deliveryMethodId) {
      if (payment.id) {
        const isVendorOptedInToVC = isOptedInToVirtualCardVendor(bill.vendor?.deliveryMethods || []);
        const isDeclinedPayment = payment?.approvalDecisionStatus === PaymentApprovalStatus.DECLINED;
        const isManagedVendor = Boolean(bill?.vendor?.managedBy);
        const shouldSkipDeliveryMethodPage =
          isVirtualCardPayment(payment) ||
          isDirectPayment(payment) ||
          (isBusinessDirectorySearchEnabled && isManagedVendor) ||
          isVendorOptedInToVC ||
          isDeclinedPayment;
        const isFailedInstallment = hasFailedInstallment(payment);

        if (isFailedInstallment) {
          return generatePath(billLocations.pay.edit.confirm, {
            orgId,
            billId: bill.id,
            paymentId: payment.id,
          });
        }

        return shouldSkipDeliveryMethodPage
          ? generatePath(billLocations.pay.edit.date, {
              orgId,
              billId: bill.id,
              paymentId: payment.id,
            })
          : nextStepURL;
      }

      return generatePath(billLocations.pay.date, {
        orgId,
        billId: bill.id,
      });
    }

    return nextStepURL;
  };

  return {
    onNextFundingSources,
    onPrevFundingSources,
    getUrlAfterFundingSource,
  };
};
