import * as React from 'react';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { deliveryMethodFactory, getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { devices } from 'src/theme/appDevices';
import { DeliveryType } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  deliveryMethod?: DeliveryMethodType;
};

export const ConvertedUnilateralRequestActivity: React.FC<Props> = ({ deliveryMethod }) => {
  const deliveryMethodRecord = deliveryMethodFactory(deliveryMethod);
  const getBankAccountInfo = () => (
    <>
      <MIFormattedText label="vendors.addDeliveryMethodByLink.bankAccount" />
      {getDeliveryMethodDisplay({ deliveryMethod: deliveryMethodRecord, vendorName: '' })}
    </>
  );
  const isACH = deliveryMethod?.deliveryType === DeliveryType.ACH;
  const method = isACH
    ? 'bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod'
    : 'bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod';
  const info = isACH
    ? getBankAccountInfo()
    : getDeliveryMethodDisplay({ deliveryMethod: deliveryMethodRecord, vendorName: '' });
  const icon = isACH ? <div className="icon-bank-icon" /> : <div className="icon-check-icon" />;

  return (
    <OutsideReviewWrapper>
      <RequestActivityWrapper>
        <BlockTitleContainer>
          <MIFormattedText label="requests.form.paymentActivity.vendorReceive" />
        </BlockTitleContainer>
        <MainRequestActivityContainer>
          <RequestActivitySource method={method} info={info} icon={icon} />
        </MainRequestActivityContainer>
      </RequestActivityWrapper>
    </OutsideReviewWrapper>
  );
};
type RequestActivitySourceProps = {
  method: string;
  info: string | JSX.Element;
  icon: Record<string, any>;
};

const RequestActivitySource: React.FC<RequestActivitySourceProps> = ({ icon, method, info }) => (
  <RequestActivityContainer>
    <RequestActivityMainContainer>
      <RequestInfoContainer>
        <RequestIcon>{icon}</RequestIcon>
        <RequestTextContainer>
          <MethodContainer>
            <MethodText>
              <MIFormattedText label={method} />
            </MethodText>
          </MethodContainer>
          <InfoText>{info}</InfoText>
        </RequestTextContainer>
      </RequestInfoContainer>
    </RequestActivityMainContainer>
  </RequestActivityContainer>
);

const baseContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

const OutsideReviewWrapper = styled.div`
  ${baseContainerStyles}
  align-items: center;
  background: ${(props) => props.theme.background.default};
  box-sizing: border-box;
  text-align: left;
  border-radius: 0.9rem;
  box-shadow: none;
  @media ${devices.mobile} {
    align-items: flex-start;
  }
  @media ${devices.mobile}, ${devices.phablet} {
    border: none;
  }
`;

const RequestActivityWrapper = styled.div`
  ${baseContainerStyles}
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0;
`;

const MainRequestActivityContainer = styled.div`
  ${baseContainerStyles}
  margin-bottom: 0;
  padding-top: 2.2rem;
`;

const RequestActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RequestActivityMainContainer = styled.div`
  display: flex;
  padding-right: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.subtitle};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
`;

const RequestInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RequestTextContainer = styled.div`
  ${baseContainerStyles}
`;

const MethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const MethodText = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.2px;
  ${(props) => props.theme.text.fontType.hint};
`;

const InfoText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
  margin-right: 0.4rem;
`;

const RequestIcon = styled.i`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) => props.theme.text.color.main};
  > img {
    height: 2.4rem;
  }
  > i {
    font-size: 2.4rem;
    color: ${(props) => props.theme.text.color.main};
  }
`;
