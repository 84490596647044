import pick from 'lodash/pick';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { useLoadInvoicesList } from 'src/pages/get-paid/hooks/useLoadInvoicesList';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getSelectedTab } from 'src/pages/get-paid/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications/notificationService';
import { NotificationVariant, PaymentRequestTabs } from 'src/utils/consts';
import { useQueryIds, useQueryString } from 'src/utils/hooks';
import { encodeQuery } from 'src/utils/query-utils';

const useRemoveBatch = (successLabel, failedLabel) => {
  const orgId = useSelector(getOrgId);
  const invoicesActions = useStoreActions(invoicesStore);
  const query = useQueryString();
  const currentTab = getSelectedTab(query);
  const history = useHistory();
  const [data, , setSelected] = useQueryIds();
  const [reloadInvoicesList] = useLoadInvoicesList();
  const cancelLoading = useSelector(invoicesStore.selectors.batchCancel.loading);
  const deleteLoading = useSelector(invoicesStore.selectors.batchDelete.loading);
  const loading = cancelLoading || deleteLoading;
  const removeBatchApi = useCallback(
    async (removeInvoice = true) => {
      try {
        if (removeInvoice) {
          await invoicesActions.batchDelete({ orgId, data });
        } else {
          await invoicesActions.batchCancel({ orgId, data });
        }

        pushNotification({
          type: NotificationVariant.INFO,
          msg: successLabel,
          textValues: { count: data.length },
        });
        const { payload: totalCounters } = await invoicesActions.totalCounters({
          orgId,
        });
        const counters = pick(totalCounters, ['unsent', 'sent', 'failed']);

        if (counters[currentTab] === 0 || counters.failed === 0) {
          history.push({
            pathname: generatePath(getPaidLocations.dashboard, { orgId }),
            search: encodeQuery(
              {
                ...query,
                status: PaymentRequestTabs.UNSENT,
              },
              ['id', 'ids'],
              ''
            ),
          });
        } else {
          setSelected([]);
          reloadInvoicesList();
        }
      } catch (e) {
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: failedLabel,
        });
      }
    },
    [
      data,
      orgId,
      reloadInvoicesList,
      setSelected,
      successLabel,
      failedLabel,
      history,
      invoicesActions,
      currentTab,
      query,
    ]
  );

  return [removeBatchApi, loading];
};

export default useRemoveBatch;
