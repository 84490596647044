import { useEffect } from 'react';
import { useApi } from 'src/hoc/useApi';
import { useMounted } from 'src/hooks/useMounted';
import { getMinThresholds } from 'src/modules/approval-workflows/api';
import { ApprovalWorkflowsMinThresholds } from 'src/utils/types';

type Props = {
  orgId: number;
  onFetchDone: () => void;
  onError: () => void;
};

export const useFetchThresholds = ({ onFetchDone, onError, orgId }: Props) => {
  const { isMounted } = useMounted();

  const { onApiCall: fetchMinThresholds, result: minThresholds } = useApi<
    [{ orgId: number }],
    ApprovalWorkflowsMinThresholds
  >({
    api: getMinThresholds,
  });

  useEffect(() => {
    fetchMinThresholds({ orgId })
      .then(() => {
        if (isMounted()) {
          onFetchDone();
        }
      })
      .catch(() => {
        if (isMounted()) {
          onError();
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMinThresholds, isMounted, orgId]);

  return {
    minThresholds,
  };
};
