import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { DockedButtonMobile } from 'src/components/mobile/DockedButtonMobile';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useBreak } from 'src/hoc';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import NewDeliveryMethodForm from 'src/pages/vendor/components/NewDeliveryMethodForm';
import { BackButton, ContentWrapper, Footer } from 'src/pages/vendor/components/VendorLayoutElements';
import { useShiftVendorToACHState } from 'src/pages/vendor/shift-vendor-to-ach/hoc/shift-vendor-to-ach-hooks';
import { getDeliveryMethods, getIsLoggedIn, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import { DeliveryType, PaymentStatus } from 'src/utils/consts';
import { shiftVendorToAchLocations } from './locations';

type Props = {
  token: string;
  vendorId: string;
};

export const AddDeliveryMethodPage = ({ vendorId, token }: Props) => {
  const history = useHistory();
  const { isMobile } = useBreak();
  const orgId = useSelector(getOrgId);
  const { payerCompanyName, paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const [submit, deliveryMethodMV, isLoading] = useShiftVendorToACHState(vendorId, orgId, token);
  const isCurrentPaymentToAch = paymentId && payment?.status === PaymentStatus.SCHEDULED;
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const isMultiCompany = organizations.length > 1;
  const isLoggedIn = useSelector(getIsLoggedIn);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const ownedVendorId = useSelector(getOwnedVendorId);

  useEffect(() => {
    if (isLoggedIn && ownedVendorId && !isEmpty(orgDeliveryMethods)) {
      history.push(generatePath(shiftVendorToAchLocations.linkMethod, { id: vendorId, token }));
    }
  }, [isLoggedIn, ownedVendorId, orgDeliveryMethods, vendorId, token, history]);

  const onPrev = () => {
    history.push(generatePath(shiftVendorToAchLocations.switchCompany, { id: vendorId, token }));
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <>
      <OutsideLayout redirectUrl={generatePath(shiftVendorToAchLocations.addMethod, { id: vendorId, token })}>
        <HeaderContentContainer>
          {isMultiCompany && (
            <ButtonContainer>
              <BackButton onPrev={onPrev} />
            </ButtonContainer>
          )}
          <HeaderTitle>
            <MIFormattedText
              label={
                isCurrentPaymentToAch
                  ? 'vendors.editCheckAddress.shiftAchTitle'
                  : 'vendors.deliveryMethods.shiftVendorToAch.addAchTitle'
              }
              values={{
                companyName: <Label>{payerCompanyName}</Label>,
                totalAmount: (
                  <Label>
                    <MIFormattedCurrency value={payment?.amount} />
                  </Label>
                ),
              }}
            />
          </HeaderTitle>
          <HeaderSubtitle>
            <MIFormattedText
              label={
                isCurrentPaymentToAch
                  ? 'vendors.editCheckAddress.shiftAchSubtitle'
                  : 'vendors.deliveryMethods.shiftVendorToAch.addAchSubtitle'
              }
              values={{ companyName: payerCompanyName }}
            />
          </HeaderSubtitle>
        </HeaderContentContainer>
        <ContentWrapper title="vendors.deliveryMethods.shiftVendorToAch.achTitle">
          <NewDeliveryMethodForm
            submit={submit}
            selectedDeliveryMethod={DeliveryType.ACH}
            achModel={deliveryMethodMV.bankAccount}
          />
          {!isMobile && (
            <Button
              label="vendors.deliveryMethods.shiftVendorToAch.submitCTA"
              onClick={submit}
              isLoading={isLoading}
              size={ButtonSizes.lg}
              isFullWidth
            />
          )}
          <Footer companyName={payerCompanyName} />
        </ContentWrapper>
      </OutsideLayout>
      {isMobile && (
        <DockedButtonMobile
          label="vendors.deliveryMethods.shiftVendorToAch.submitCTA"
          onClick={submit}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const HeaderTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin-bottom: 1rem;
`;

const HeaderSubtitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
`;

const Label = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const ButtonContainer = styled.div`
  margin-bottom: 4rem;
`;
