import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import organizationStore from 'src/modules/organizations/organizations-store';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';

type Props = {
  onNext: () => void;
  onExit: () => void;
  onPrev: () => void;
};

const AddAccountingFirmDetails = ({ onNext, onExit, onPrev }: Props) => {
  const dispatch = useDispatch();
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);

  const model = useMemo(
    () => ({
      contactFirstName: newCompanyInfo.contactFirstName || '',
      contactLastName: newCompanyInfo.contactLastName || '',
      phone: newCompanyInfo.phone || '',
    }),
    [newCompanyInfo]
  );

  const [companyInfoMV, { submit }] = useForm(model, {
    submit: async (value) => {
      const validationErrors = getValidationErrors('companyInfo', value);

      if (!isEmpty(validationErrors)) {
        analytics.trackAction('form-validation-error', { validationErrors });
        throw new ValidationError({ validationErrors });
      }

      dispatch(
        organizationStore.actions.updateNewCompanySlice.update({
          ...newCompanyInfo,
          ...value,
        })
      );
      onNext();
    },
  });

  return (
    <StepLayoutPage
      title="addAccountingFirm.details.title"
      relativeStep={1 / 5}
      onNext={submit}
      onPrev={onPrev}
      goExit={onExit}
    >
      <WizardFormContainer>
        <RowContainer>
          <PrivateDataContainer>
            <TextField
              model={companyInfoMV.contactFirstName}
              label="addAccountingFirm.details.firstNameLabel"
              isRequired
              autoFocus
            />
          </PrivateDataContainer>

          <PrivateDataContainer>
            <TextField
              model={companyInfoMV.contactLastName}
              label="addAccountingFirm.details.lastNameLabel"
              isRequired
            />
          </PrivateDataContainer>
        </RowContainer>

        <MaskField
          model={companyInfoMV.phone}
          label="addAccountingFirm.details.phoneLabel"
          type="tel"
          format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
          isRequired
          isModelOnChangeFormatted
        />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};

const RowContainer = styled.div`
  display: flex;
  margin-bottom: 0;
  > div:first-child {
    margin-right: 2rem;
  }
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export default AddAccountingFirmDetails;
