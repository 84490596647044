import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Option } from 'src/components/form/MIRadioGroup';
import { RadioGroupField } from 'src/core/ds/form/fields';
import { Radio } from 'src/core/ds/radio';
import { VStack } from 'src/core/ds/stack';
import { SelectProps } from 'src/pages/bill/pay/FastPaymentOfferPage/FastPaymentOfferPage';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { EXPEDITED_DELIVERY_TYPES, FAST_DELIVERY_TYPES, FastCheckDeliveryType } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';
import { PaymentDeliveryOption } from './PaymentDeliveryOption/PaymentDeliveryOption';

type Props = {
  deliveryOptions: DeliveryOptionType[];
  fundingSourceType?: string;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
  onSelectDeliveryOption?: (props: SelectProps) => void;
};

export const PaymentDeliveryOptions = ({
  deliveryOptions,
  onSelectDeliveryOption,
  fundingSourceType,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: Props) => {
  const hasFastCheck = deliveryOptions?.some((el) => el.type === FastCheckDeliveryType.EXPRESS);
  const freeDeliveryOptionIndex = findIndex(deliveryOptions, (option) => !FAST_DELIVERY_TYPES.includes(option.type));
  const bill = useSelector(getBill);
  const options: Option<string>[] = useMemo(
    () =>
      deliveryOptions.map((option, index) => ({
        id: index.toString(),
        component: (
          <PaymentDeliveryOption
            fundingSourceType={fundingSourceType}
            isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
            isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
            isFast={
              EXPEDITED_DELIVERY_TYPES.includes(option.type) ||
              (option.type === FastCheckDeliveryType.EXPRESS &&
                !deliveryOptions.find((item) => item.type === FastCheckDeliveryType.OVERNIGHT))
            }
            deliveryOption={option}
            totalAmount={bill.totalAmount}
            hasFastCheck={hasFastCheck}
          />
        ),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryOptions, fundingSourceType]
  );

  const selectDeliveryOption = (selectedId: any) => {
    const { scheduledDate, deliveryDate, maxDeliveryDate, type } = deliveryOptions[selectedId];
    onSelectDeliveryOption && onSelectDeliveryOption({ scheduledDate, deliveryDate, maxDeliveryDate, type });
  };

  useEffect(() => {
    if (!isEmpty(deliveryOptions)) {
      selectDeliveryOption(freeDeliveryOptionIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionType = deliveryOptions[0]?.type;
  const group = optionType ? `deliveryDate-${optionType}` : 'deliveryDate';
  const defaultDeliveryPreference = freeDeliveryOptionIndex.toString();

  return (
    <RadioGroupField
      marginBottom={0}
      mt={isFastAchBetterExposeEtaEnabled ? 1 : undefined}
      id="PaymentDeliveryOption"
      name={group}
      onChange={selectDeliveryOption}
      defaultValue={defaultDeliveryPreference}
      isRequired
      testId="radio-group-payment-delivery-option"
    >
      <VStack spacing={isFastAchBetterExposeEtaEnabled ? 4 : 3} w="full" alignItems="flex-start">
        {options.map((item) => (
          <Radio key={item.id} value={item.id}>
            {item.component}
          </Radio>
        ))}
      </VStack>
    </RadioGroupField>
  );
};
