import { ChangeEvent, useEffect, useState } from 'react';
import { MIDropDown } from 'src/components/common/DropDown/MIDropDown';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { MaskField, RadioGroupField, TextField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { Radio } from 'src/core/ds/radio';
import { useLegalAddress } from 'src/pages/bill/pay/hooks/useLegalAddress';
import { useBusinessTaxRelatedOptions } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/business-tax-related/useBusinessTaxRelatedOptions';
import { TooltipLabel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/TooltipLabel';
import { BusinessType } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { useVendorCompanyInfoConfig } from 'src/pages/onboarding/vendor-company-info/context/VendorCompanyInfoContext';
import {
  convertCompanyAddressToGoogleAddress,
  convertLegalAddressToGoogleAddress,
  convertToServerLegalAddress,
} from 'src/utils/address';
import { getTaxIdTypesOptions } from 'src/utils/company';
import { AddressFormControlMode, FORM_AUTOCOMPLETE, TaxIdType } from 'src/utils/consts';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';
import { CompanyInfoType } from 'src/utils/types';

type Field = {
  value: string | null;
  fieldName: string;
};

type VendorCompanyLegalInfoPageContainerProps = {
  onPrev: () => void;
  onNext: (companyInfo: CompanyInfoType, dataToUpdate: Record<string, any>) => void;
  onChange: (name: string, id: string) => void;
  companyInfo: CompanyInfoType;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
  onChangeFields: (fields: Field[]) => void;
};
export const VendorCompanyLegalInfoForm = ({
  onPrev,
  onNext,
  isLoading,
  companyInfo,
  onChange,
  validationErrors,
  onChangeFields,
}: VendorCompanyLegalInfoPageContainerProps) => {
  const { title, subTitle, nextLabel } = useVendorCompanyInfoConfig<'VendorCompanyLegalInfo'>({
    page: 'VendorCompanyLegalInfo',
  });
  const { companyName, legalCompanyName, businessType, taxIdType, taxId } = companyInfo;
  const { businessTypeOptions } = useBusinessTaxRelatedOptions();
  const legalAddress = useLegalAddress(companyInfo);
  const defaultLegalAddress = legalAddress.legalGooglePlaceId
    ? convertLegalAddressToGoogleAddress(legalAddress)
    : convertCompanyAddressToGoogleAddress(companyInfo);

  const taxIdOptions = getTaxIdTypesOptions();
  const [address, setAddress] = useState(defaultLegalAddress);

  const onAddressChange = (address) => {
    setAddress(address);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    onChange(value, id);
  };
  const handleBusinessTypeChange = (value: string) => {
    if (!value) return;

    onChange(value, 'businessType');
  };
  const isTaxIdTypeShouldSelectByUser = [BusinessType.Trust, BusinessType.SoleProprietorship].includes(
    businessType as BusinessType
  );

  const getTaxIdType = () => (isTaxIdTypeShouldSelectByUser ? taxIdType : TaxIdType.EIN);

  const handleChangeTaxIdType = (value: string) => {
    onChangeFields([
      { value, fieldName: 'taxIdType' },
      { value: '', fieldName: 'taxId' },
    ]);
  };
  const onNextHandler = () => {
    onNext(companyInfo, {
      legalCompanyName,
      businessType,
      taxIdType: getTaxIdType(),
      taxId,
      ...convertToServerLegalAddress(address),
    });
  };

  const taxIdProps = () => {
    const getHelpersText = (taxType: TaxIdType) => ({
      label: `bills.pay.confirm.completeLegalInfo.taxId.inputTitle${taxType}`,
      errorMessage: `inputErrors.companyInfo.taxId.string.${taxType}`,
      placeholder: `financing.businessDetails.fields.taxIdNumber.placeholder${taxType}`,
      mask: Consts.MASK_FORMAT_OPTIONS[`taxId${taxType}`],
    });

    if (!isTaxIdTypeShouldSelectByUser) {
      return {
        ...getHelpersText(TaxIdType.EIN),
      };
    }

    switch (taxIdType) {
      case TaxIdType.ITIN:
        return {
          ...getHelpersText(TaxIdType.ITIN),
        };
      case TaxIdType.SSN:
        return {
          ...getHelpersText(TaxIdType.SSN),
        };
      default:
        return {
          ...getHelpersText(TaxIdType.EIN),
        };
    }
  };

  useEffect(() => {
    const initialValues: Field[] = [];

    if (!businessType) {
      initialValues.push({ value: BusinessType.LLC, fieldName: 'businessType' });
    }

    if (!legalCompanyName && companyName) {
      initialValues.push({ value: companyName, fieldName: 'legalCompanyName' });
    }

    onChangeFields(initialValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StepLayoutPage
      title={title}
      titleValues={{
        companyName: (
          <Box as="span" color="grey.700">
            {checkApostropheForPluralPossessive(companyInfo.companyName ?? '')}
          </Box>
        ),
      }}
      subtitle={subTitle}
      subTitleValues={{
        companyName,
      }}
      onPrev={onPrev}
      onNext={onNextHandler}
      isLoading={isLoading}
      testId="legal-info-page"
      nextLabel={nextLabel}
    >
      <TextField
        id="legalCompanyName"
        label="bills.pay.confirm.completeLegalInfo.legalName.inputTitle"
        value={legalCompanyName || ''}
        onChange={onInputChange}
        isRequired
        errorMessage={validationErrors.legalCompanyName}
        helperText="bills.pay.confirm.completeLegalInfo.legalName.inputHint"
        autoFocus
      />

      <MIAddressAutocomplete
        id="legalAddress"
        addressLabel="bills.pay.confirm.completeLegalInfo.legalAddress.inputTitle"
        aptNumberLabel="form.addressAutocomplete.aptNumberLabel"
        emptyAddressLabel="bills.pay.confirm.completeLegalInfo.legalAddress.emptyAddressLabel"
        emptyNumberLabel="form.addressAutocomplete.emptyNumberLabel"
        address={address}
        mode={AddressFormControlMode.INLINE}
        onChange={onAddressChange}
        errorMessage={validationErrors.legalGooglePlaceId || validationErrors.legalZipCode}
        required
        hideSuite
        autoComplete={FORM_AUTOCOMPLETE.OFF}
      />
      <MIDropDown
        id="businessType"
        testId="input-type"
        label="financing.businessDetails.fields.businessType.title"
        labelValues={{
          tooltip: (
            <TooltipLabel label="financing.businessDetails.fields.businessType.tooltip" testId="business-type" />
          ),
        }}
        disableEdit
        placeholder="financing.businessDetails.fields.businessType.placeholder"
        errorMessage={validationErrors.businessType}
        required
        value={businessType}
        options={businessTypeOptions}
        onChange={(e) => handleBusinessTypeChange(String(e.value))}
      />
      {isTaxIdTypeShouldSelectByUser && (
        <Box mt={30}>
          <RadioGroupField
            id="taxIdType"
            label="onboarding.companyInfo.taxIdType.inputTitle"
            value={taxIdType || ''}
            onChange={handleChangeTaxIdType}
            isRequired
            errorMessage={validationErrors.taxIdType}
            helperText="bills.pay.confirm.completeLegalInfo.taxIdType.inputHint"
          >
            {taxIdOptions.map((option, index) => (
              <Radio label={option.label} value={option.id} key={index} />
            ))}
          </RadioGroupField>
        </Box>
      )}

      <Box mt={!isTaxIdTypeShouldSelectByUser ? 30 : 0}>
        <MaskField
          data-private
          id="taxId"
          label={taxIdProps().label}
          value={taxId}
          onChange={(e) => onChange(e.formattedValue, 'taxId')}
          isRequired
          type="tel"
          format={taxIdProps().mask}
          placeholder={taxIdProps().placeholder}
          errorMessage={validationErrors.taxId && taxIdProps().errorMessage}
          autoComplete={FORM_AUTOCOMPLETE.OFF}
        />
      </Box>
    </StepLayoutPage>
  );
};
