import isEmpty from 'lodash/isEmpty';
import clientServiceApi from 'src/services/api/clientService';

export const emailValidation = async (
  email,
  password
): Promise<null | Partial<{ email: string; password: string }>> => {
  const { validationErrors } = await clientServiceApi.getPasswordValidationErrors('userRegistration', {
    email,
    password,
  });

  return isEmpty(validationErrors) ? null : validationErrors;
};
