import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useExtension } from 'src/pages/spend-management/hooks/useExtension';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { spendManagementLocations } from './locations';

export function InstallExtensionPage() {
  const [historyPush] = useHistoryWithOrgId();
  const { webStoreUrl, webStoreUrlParams } = useExtension();

  const installChromeExtension = () => {
    analytics.track(Page.ACCOUNT_ENROLLED, Context.EXTENSION, Event.CLICK_INSTALL, undefined, undefined, () => {
      window.open(`${webStoreUrl}${webStoreUrlParams('signup_flow')}`, '_blank');
    });
  };

  const goToDashboard = () => {
    analytics.track(Page.ACCOUNT_ENROLLED, Context.EXTENSION, Event.CLICK_DASHBOARD);
    historyPush({ path: spendManagementLocations.index });
  };

  return (
    <WizardElements.WizardContainer>
      <WizardElements.WizardInner isCenterVert>
        <Box mb={4} textAlign="center">
          {/* TODO: Should change to the right format */}
          <video
            data-component="Video"
            autoPlay
            muted
            src="https://platform-static.meliopayments.com/assets/melio/illustrations/success.webm"
            width="144px"
          >
            <track kind="captions" />
          </video>
        </Box>
        <Box textAlign="center" maxW="48.8rem" mb={2} textStyle="h1Semi">
          <MIFormattedText label="spendManagement.pages.installExtension.title" />
        </Box>
        <Box textAlign="center" maxW="48.8rem" mb={12} textStyle="body2">
          <MIFormattedText label="spendManagement.pages.installExtension.subtitle" />
        </Box>
        <Box maxW="48.8rem" w="full" mb={6}>
          <Button
            label="spendManagement.pages.installExtension.buttonLabel"
            variant={ButtonVariants.primary}
            onClick={installChromeExtension}
            isFullWidth
          />
        </Box>
        <Flex maxW="48.8rem" justifyContent="center" w="full">
          <Button
            label="spendManagement.pages.installExtension.gotoDashboard"
            variant={ButtonVariants.tertiaryNaked}
            onClick={goToDashboard}
            isFullWidth
          />
        </Flex>
      </WizardElements.WizardInner>
    </WizardElements.WizardContainer>
  );
}
