import { useCallback, useEffect, useRef } from 'react';

export const useUrlSaver = () => {
  const input = useRef<HTMLAnchorElement>();
  useEffect(() => {
    input.current = document.createElement('a');
  }, []);

  return useCallback((url: string) => {
    if (input.current) {
      input.current.download = '';
      input.current.href = url;
      input.current.click();
    }
  }, []);
};
