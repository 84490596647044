import difference from 'lodash/difference';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { IdType } from 'react-table';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import Spacer from 'src/core/ds/spacer';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import useGetProMultiItemsActions from 'src/pages/get-pro/hooks/useGetProMultiItemsActions';
import { analytics } from 'src/services/analytics';
import { GetProTabs } from 'src/utils/consts';
import { InProgressStatusFilters, STATUS_FILTERS_BY_TAB } from '../../consts';
import useGetProListParams from '../../hooks/useGetProListParams';
import useLoadGetProList from '../../hooks/useLoadGetProList';
import { GetProDateFilters } from '../../types';
import { AlertsFilter } from '../alertsFilter/AlertsFilter';
import ActionsMenu from './components/ActionsMenu';
import { CheckedFilterMenu } from './components/CheckedFilterMenu/Menu';
import FilterMenu from './components/FilterMenu';
import { LastSyncStatus } from './components/LastSyncStatus';
import TotalLabel from './components/TotalLabel';
import { useActionOptionsByType } from './hooks/useActionOptionsByType';
import { useFilterOptionsByType } from './hooks/useFilterOptionsByType';

type Props = {
  selectedIds: Record<IdType<string>, boolean>;
  setSelectedIds: (ids: Record<IdType<string>, boolean>) => void;
  showFilters: boolean;
  lastSelectedStatusesFilter: Array<InProgressStatusFilters>;
};

export const TableSummary: React.FC<Props> = ({
  selectedIds,
  setSelectedIds,
  showFilters,
  lastSelectedStatusesFilter,
}) => {
  const {
    listParams,
    setFilter,
    filter: dateFilter,
    setPage,
    setStatuses,
    statuses: statusesFilter,
  } = useGetProListParams();

  const {
    connectedAccountingPlatform,
    isConnected,
    notConnectedDeliveryMethods,
  } = useFetchAccountingPlatformsAndData();
  const lastSyncAt =
    isConnected && notConnectedDeliveryMethods.length === 0 && connectedAccountingPlatform?.lastGetPaidSyncAt
      ? connectedAccountingPlatform?.lastGetPaidSyncAt
      : null;
  const { loadStatus, hasAlertsInLast7Days } = useLoadGetProList(listParams);
  const totalItems = loadStatus?.totalCount || 0;
  const totalSum = loadStatus?.totalSum || 0;
  const { status } = listParams.filters;
  const { dateFilterOptions } = useFilterOptionsByType(status);
  const statusFilterOptions = useMemo(() => STATUS_FILTERS_BY_TAB[status], [status]);
  const selectedStatusesArray = statusesFilter?.split(',') ?? [];
  const selectedItems = Object.keys(selectedIds);
  const actionOptions = useActionOptionsByType({
    type: status,
    ids: selectedItems,
    clearSelectedIds: () => setSelectedIds({}),
  });
  const isItemsSelected = selectedItems.length > 0;
  const showActionMenu = isItemsSelected && actionOptions.length > 0;
  const showSendPaymentRequestsButton = status === GetProTabs.INVOICES && isItemsSelected;
  const isInProgressTab = status === GetProTabs.IN_PROGRESS;
  const { sendRequests } = useGetProMultiItemsActions();
  const [isPressedAlertsFilter, toggleAlertsFilter] = useState(false);
  const alertsFilterStatuses = [
    InProgressStatusFilters.Failed,
    InProgressStatusFilters.Cancelled,
    InProgressStatusFilters.Pending,
  ];

  useEffect(() => {
    const selectedStatusesArray = statusesFilter?.split(',');
    const statusesDiff = difference(selectedStatusesArray, alertsFilterStatuses);

    if (isPressedAlertsFilter && (!statusesFilter || statusesDiff.length !== 0)) {
      toggleAlertsFilter(false);
    }
  }, [statusesFilter]);

  const handleDateFilterChange = (value: GetProDateFilters) => {
    if (value !== dateFilter) {
      setFilter(value);
      setPage(0);
      setSelectedIds({});
    }
  };

  const handleStatusesFilterChange = (value: string, isChecked: boolean) => {
    const updatedStatuses = isChecked
      ? [...lastSelectedStatusesFilter, value]
      : lastSelectedStatusesFilter.filter((status) => status !== value);

    analytics.trackAction(`getPro.${status}.statusFilter`, {
      tab: status,
      filter: dateFilter,
      selectedStatuses: updatedStatuses,
      changedStatus: value,
      isChecked,
    });

    setStatuses(updatedStatuses.join(','));
    setPage(0);
    setSelectedIds({});
  };

  const handleClearStatusesFilter = () => {
    setStatuses();
    setPage(0);
    setSelectedIds({});
  };

  const sendPaymentRequests = () => sendRequests(selectedItems);

  const onClickAlerts = () => {
    const updatesIsPressedAlertsFilter = !isPressedAlertsFilter;
    toggleAlertsFilter(updatesIsPressedAlertsFilter);

    if (updatesIsPressedAlertsFilter) {
      setStatuses(alertsFilterStatuses.join(','));
    } else {
      setStatuses('');
    }

    analytics.trackAction(`getPro.${status}.alertsButton`, {
      selectedStatuses: alertsFilterStatuses,
    });
    setPage(0);
    setSelectedIds({});
  };

  return (
    <Flex align="center" h="6.4rem" bg="white" px={4} borderBottom="1px" borderBottomColor="grey.300">
      <TotalLabel totalNumberOfItems={totalItems} totalSum={totalSum} selectedIds={selectedIds} tab={status} />
      <LastSyncStatus lastSyncAt={lastSyncAt} />
      <Spacer />
      <Flex>
        {showSendPaymentRequestsButton && (
          <Button
            onClick={sendPaymentRequests}
            label="getPro.table.actions.invoices.sendRequests"
            variant={ButtonVariants.primary}
            mr={3}
            size={ButtonSizes.sm}
            analyticsProperties={{ selectedIds: Object.keys(selectedIds) }}
          />
        )}
        {showActionMenu && <ActionsMenu options={actionOptions} />}
        {showFilters && (
          <>
            {isInProgressTab && (
              <>
                <AlertsFilter
                  showAlertsIndication={hasAlertsInLast7Days}
                  onClick={onClickAlerts}
                  isPressed={isPressedAlertsFilter}
                />
                <CheckedFilterMenu
                  tab={status}
                  options={statusFilterOptions}
                  selectedOptions={selectedStatusesArray}
                  onChange={handleStatusesFilterChange}
                  onClearAll={handleClearStatusesFilter}
                />
              </>
            )}
            <FilterMenu
              type={status}
              options={dateFilterOptions}
              selectedValue={dateFilter}
              onChange={handleDateFilterChange}
            />
          </>
        )}
      </Flex>
    </Flex>
  );
};
