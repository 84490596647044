import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { globalLocations } from 'src/pages/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { getIsDeliveryMethodVerifiedByVerificationMethod } from 'src/utils/delivery-methods';

export const DeliveryMethodStatusCheck = () => {
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const isDeliveryMethodVerified = getIsDeliveryMethodVerifiedByVerificationMethod(deliveryMethod);
  const { navigate } = useNavigator();

  const navigateToSelectDeliveryMethod = () => {
    if (vendorId) {
      navigate(generatePath(globalLocations.receivingMethod.ach.select, { orgId, vendorId }), false, {
        preservedState: {
          origin: DeliveryMethodOrigin.MSN_PORTAL_ONBOARDING,
        },
        redirectUrl: globalLocations.onboarding.msnRegistration.confirmDetails,
        exitUrl: globalLocations.onboarding.msnRegistration.companyDetails,
        progressBarProps: {
          relativeStep: 3 / 4,
        },
      });
    }
  };

  if (!deliveryMethod) {
    navigateToSelectDeliveryMethod();
  } else if (isDeliveryMethodVerified) {
    navigate(generatePath(onboardingLocations.msnRegistration.confirmDetails, { orgId }), true, {
      prevUrl: onboardingLocations.msnRegistration.selectBusinessType,
      nextUrl: onboardingLocations.msnRegistration.deliveryMethodStatus,
      isGetPaidOrg: true,
    });
  } else {
    navigate(generatePath(onboardingLocations.msnRegistration.verifyBankAccount, { orgId }));
  }

  return <AreaLoader />;
};
