import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

type InstallmentsListViewHeaderExpandedPropsType = {
  onToggle(): void;
};

export const InstallmentsListViewHeaderExpanded = ({ onToggle }: InstallmentsListViewHeaderExpandedPropsType) => (
  <Flex w="full" textStyle="body4Semi" color="grey.700" data-testid="installments-list-header-expanded" pb={1}>
    <Flex alignItems="center" flexGrow={1}>
      <Flex cursor="pointer" onClick={onToggle} data-testid="installments-list-collapse">
        <Box mr={2}>
          <MIFormattedText label="financing.installmentsList.title" />
        </Box>
        <Icon name={IconNames.shevronUp} size={IconSize.s} />
      </Flex>
    </Flex>
    <Box textAlign="right">
      <MIFormattedText label="financing.installmentsList.amount" />
    </Box>
  </Flex>
);
