import { useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { globalLocations } from 'src/pages/locations';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';

export const QuickbookMultiOrgOnboardingConnectError = () => {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const error = searchParams.get('error');

  useEffect(() => {
    analytics.track('companies', 'quickbooks-error', { error });
    pushNotification({
      type: NotificationVariant.ERROR,
      msg: 'serverErrors.ERR',
    });

    return history.replace(generatePath(globalLocations.quickbookMultiOrgOnboarding.connectQuickbooks));
  }, [error, history]);

  return <AreaLoader />;
};
