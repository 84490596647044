import { EmptyList } from 'src/components/list/EmptyList';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TimelineItem, TimelineItemType } from './TimelineItem';

type Props = {
  items: TimelineItemType[];
};

export const TimelineContainer = ({ items = [] }: Props) => {
  if (items.length === 0) {
    return (
      <Flex justify="center" data-testid="timeline-empty-state">
        <Box maxWidth="40rem">
          <EmptyList
            textTitle="getPro.drawerTabs.timeline.emptyState.noRecentActivity.title"
            text="getPro.drawerTabs.timeline.emptyState.noRecentActivity.subTitle"
          />
        </Box>
      </Flex>
    );
  }

  return (
    <Box mt={8} maxW="63rem" data-testid="timeline-container">
      {items.map((item, index) => (
        <TimelineItem key={index} item={item} last={index === items.length - 1} />
      ))}
    </Box>
  );
};
