import { useCallback, useEffect, useRef } from 'react';

/**
 * A hook to determine if a component is mounted
 */
export function useMounted() {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;

    return () => {
      ref.current = false;
    };
  }, []);

  const isMounted = useCallback(() => ref.current, []);

  return { isMounted };
}
