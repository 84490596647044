/* eslint-disable max-len */
import { scannedInvoicesMapping } from 'src/pages/bill/pay/action-notification-mapping';
import { ACTION_NOTIFICATION_MAPPING as companiesNotificationSettings } from 'src/pages/companies/action-notification-mappings';
import getPaidMapping from 'src/pages/get-paid/action-notification-mapping';
import getProMapping from 'src/pages/get-pro/action-notification-mapping';
import { MAPPINGS as getRegularBatchPaymentsMapping } from 'src/pages/regular-batch-payments/action-notification-mapping';
import { ACTION_NOTIFICATION_MAPPING as approvalWorkflowsNotificationsSettings } from 'src/pages/settings/components/ApprovalWorkflows/action-notification-mappings';
import { ACTION_NOTIFICATION_MAPPING as emailNotificationsSettings } from 'src/pages/settings/components/EmailNotificationsPage/action-notification-mappings';
import { teamPageActionNotificationMapping } from 'src/pages/team/action-notification-mappings';

const MAPPINGS = {
  '[INVITATIONS] CREATE_SUCCESS': {
    type: 'success',
    msg: 'invitation.create.success',
  },
  '[INVITATIONS] CREATE_FAILURE': {
    type: 'error',
    msg: 'invitation.create.error',
  },
  '[INVITATIONS] DELETE_SUCCESS': {
    type: 'info',
    msg: 'invitation.delete.success',
  },
  '[INVITATIONS] DELETE_FAILURE': {
    type: 'error',
    msg: 'invitation.delete.error',
  },
  '[INVITATIONS] UPDATE_SUCCESS': {
    type: 'success',
    msg: 'invitation.resend.success',
  },
  '[INVITATIONS] UPDATE_FAILURE': {
    type: 'error',
    msg: 'invitation.resend.error',
  },
  '[USERS] DELETE_SUCCESS': {
    type: 'info',
    msg: 'userManagement.delete.success',
  },
  '[USERS] DELETE_FAILURE': {
    type: 'error',
    msg: 'userManagement.delete.error',
  },
  '[USERS] USER_MANAGEMENT_UPDATE_SUCCESS': {
    type: 'success',
    msg: 'userManagement.update.success',
  },
  '[USERS] USER_MANAGEMENT_UPDATE_FAILURE': {
    type: 'error',
    msg: 'userManagement.update.error',
  },
  '[USER] UPDATE_FUNDING_SOURCE_LABEL_SUCCESS': {
    type: 'success',
    msg: 'settings.paymentMethods.editLabelDialog.savedToast',
  },
  '[USER] REMOVE_FUNDING_SOURCE_LABEL_SUCCESS': {
    type: 'success',
    msg: 'settings.paymentMethods.editLabelDialog.removedToast',
  },
  '[PAYMENTS] PAYMENT_APPROVED_SUCCESS': {
    type: 'success',
    msg: 'bills.approval.approveSuccess',
  },
  '[PAYMENTS] PAYMENT_DECLINED_SUCCESS': {
    type: 'info',
    msg: 'bills.approval.declineSuccess',
  },
  '[CUSTOMERS] CREATE_BATCH_SUCCESS': {
    type: 'success',
    msg: 'contacts.batch.success.customers.notification',
  },
  '[VENDORS] CREATE_SUCCESS': {
    type: 'success',
    msg: 'contacts.create.successNotification',
  },
  '[CUSTOMERS] CREATE_SUCCESS': {
    type: 'success',
    msg: 'contacts.create.successNotification',
  },
  '[JOINORGANIZATIONREQUESTS] RESEND_SUCCESS': {
    type: 'success',
    msg: 'bookkeeper.request.notification.resendSuccess',
  },
  '[ORGANIZATIONS] CREATE NOT OWNED_SUCCESS': {
    type: 'success',
    msg: 'invitation.create.success',
  },
  '[JOINORGANIZATIONREQUESTS] CREATE_SUCCESS': {
    type: 'success',
    msg: 'bookkeeper.request.notification.sentSuccess',
  },
  '[USERS] USER_MANAGEMENT_CHANGE_OWNER_SUCCESS': {
    type: 'success',
    msg: 'userManagement.changeOwner.success',
  },
  '[USERS] USER_MANAGEMENT_CHANGE_OWNER_FAILURE': {
    type: 'error',
    msg: 'userManagement.changeOwner.error',
  },
  '[ORGANIZATIONS] REQUEST_SWITCH_ACCOUNTING_FIRM_SUCCESS': {
    type: 'success',
    msg: 'bookkeeper.company.accountingFirmDialog.requestSwitchSuccess',
  },
  ...getPaidMapping,
  ...getProMapping,
  ...getRegularBatchPaymentsMapping,
  ...teamPageActionNotificationMapping,
  ...emailNotificationsSettings,
  ...approvalWorkflowsNotificationsSettings,
  ...companiesNotificationSettings,
  ...scannedInvoicesMapping,
};

export default MAPPINGS;
