import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { VStack } from 'src/core/ds/stack';
import { ReactComponent as CardEmptyStateIcon } from 'src/images/spend-management/add-card.svg';
import { analytics, Page } from 'src/pages/spend-management/SpendManagementAnalytics';

export const CardsEmptyState = ({ onAddCardClick }: { onAddCardClick: () => void }) => {
  useEffect(() => {
    analytics.page(Page.CARD_LIST, { isEmptyState: true });
  }, []);

  return (
    <VStack justifyContent="center" alignItems="center" w="full" data-testid="spend-management-cards-empty-state">
      <Flex direction="column" alignItems="center" justifyContent="center" maxW="46.8rem">
        <Box mb={4}>
          <CardEmptyStateIcon />
        </Box>

        <Box textAlign="center" textStyle="body2Semi" pb=".5rem">
          <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.addCard.title" />
        </Box>
        <Box mb={4} textAlign="center" textStyle="body3" pb=".8rem">
          <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.addCard.subtitle" />
        </Box>

        <Flex justifyContent="center" w="full" maxW="22.6rem">
          <Button
            size={ButtonSizes.lg}
            leftIcon={<Icon name={IconNames.plus} size={IconSize.s} />}
            label="spendManagement.pages.dashboard.emptyStates.addCard.buttonLabel"
            variant={ButtonVariants.primary}
            onClick={onAddCardClick}
            isFullWidth
          />
        </Flex>
      </Flex>
    </VStack>
  );
};
