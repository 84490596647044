import { isToday, isTomorrow } from 'date-fns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { ButtonSizes } from 'src/core/ds/button';
import Button from 'src/core/ds/button/Button';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useApi } from 'src/hoc/useApi';
import { paymentsApi } from 'src/modules/payments/api';
import { UpgradePaymentFromPayeeType, UpsellType } from 'src/modules/payments/api-types';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { shiftToDebitLocations } from 'src/pages/vendor/shift-vendor-to-debit/locations';
import {
  FAST_DELIVERY_EVENT_NAMES,
  FAST_DELIVERY_SERVER_EVENT_NAMES,
  upsellTypeToAnalyticsOptionName,
} from 'src/pages/vendor/upgrade-payment/consts';
import { useVendorUpgradePaymentAnalytics } from 'src/pages/vendor/upgrade-payment/hooks';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryMethodType } from 'src/utils/types';
import { AgreementLabel, ContentFooter, ContentHeader, DebitCardPromotion, PageWrapper } from '../../components';
import { upgradePaymentLocations as locations } from '../../locations';
import { handleUpsellConvertError } from '../../utils/handleUpsellConvertError';

type Props = {
  token: string;
};

const getSubtitle = (deliveryMethod: Partial<DeliveryMethodType>, deliveryDate: string) => {
  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);
  const expectedDeliveryDate = new Date(deliveryDate);

  let label;
  const values = {
    accountNumber4digits,
    deliveryDate: <MIFormattedDate date={expectedDeliveryDate} />,
  };

  if (isToday(expectedDeliveryDate)) {
    label = 'vendors.upgradePayment.landing.subtitle.fast.today';
  } else if (isTomorrow(expectedDeliveryDate)) {
    label = 'vendors.upgradePayment.landing.subtitle.fast.tomorrow';
  } else {
    label = 'vendors.upgradePayment.landing.subtitle.fast.date';
  }

  return { label, values };
};

export const SelectMethodPage = ({ token }: Props) => {
  const history = useHistory();

  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { upsellItems, filesUrls } = useStructuredSelectors(paymentsStore.selectors.payment(paymentId));
  const { trackServerAction, trackAction, staticAnalyticsProps } = useVendorUpgradePaymentAnalytics(token);

  const { onApiCall: upgradePayment, loading: isSubmitting } = useApi({
    api: paymentsApi.upgradePaymentFromPayee,
  });

  useEffect(() => {
    if (upsellItems) {
      const shownOptions = [UpsellType.PUSH_TO_FAST_ACH, UpsellType.PUSH_TO_DEBIT]
        .map((type) => upsellTypeToAnalyticsOptionName[type])
        .sort((a, b) => a.localeCompare(b))
        .join('_');

      trackServerAction(FAST_DELIVERY_SERVER_EVENT_NAMES.OPTIONS_PAGE_VIEW, {
        shownOptions,
      });

      trackAction(FAST_DELIVERY_EVENT_NAMES.OPTIONS_PAGE_VIEW, { shownOptions });
    }
  }, [upsellItems, trackAction, trackServerAction]);

  if (!upsellItems) {
    return <AreaLoader />;
  }

  const { organization, vendor } = payment;
  const deliveryMethod = vendor?.deliveryMethod;
  const fastAchUpsellItem = upsellItems.find(({ type }) => type === UpsellType.PUSH_TO_FAST_ACH);
  const { label: subtitleLabel, values: subtitleValues } = getSubtitle(deliveryMethod, fastAchUpsellItem?.deliveryDate);

  const handleConfirmClick = async () => {
    try {
      trackServerAction(FAST_DELIVERY_SERVER_EVENT_NAMES.SELECT_OPTION, {
        selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_FAST_ACH],
      });

      await upgradePayment({ paymentId, token, type: UpgradePaymentFromPayeeType.FAST_ACH });

      redirectToSuccessPage();
    } catch (error: any) {
      handleUpsellConvertError({
        error,
        upsellType: UpsellType.PUSH_TO_FAST_ACH,
      });
    }
  };

  const handleDebitCardPromotionClick = () => {
    trackAction(FAST_DELIVERY_EVENT_NAMES.SELECT_OPTION, {
      selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_DEBIT],
    });

    trackServerAction(FAST_DELIVERY_SERVER_EVENT_NAMES.SELECT_OPTION, {
      selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_DEBIT],
    });

    redirectToDebitMethodPage();
  };

  const redirectToSuccessPage = () => {
    history.replace(generatePath(locations.success, { token }), {
      selectedUpsellType: UpsellType.PUSH_TO_FAST_ACH,
    });
  };

  const redirectToDebitMethodPage = () => {
    history.push(generatePath(shiftToDebitLocations.index, { token }), {
      parentFlow: 'Upgrade Payment',
    });
  };

  return (
    <PageWrapper>
      <ContentHeader
        payment={payment}
        organization={organization}
        filesUrls={filesUrls}
        showFullHeader
        subTitle="vendors.upgradePayment.header.subtitle"
      />
      <ContentWrapper>
        <Box color="black" textStyle="body1Semi">
          <MIFormattedText label="vendors.upgradePayment.landing.title.fast" />
        </Box>
        <Box mt={4} color="black" textStyle="body2">
          <MIFormattedText label={subtitleLabel} values={subtitleValues} />
        </Box>
        <Box mt={1} color="grey.700" textStyle="body3">
          <MIFormattedText label="vendors.upgradePayment.landing.fees" />
        </Box>
        <Button
          label="vendors.upgradePayment.landing.cta"
          width="full"
          size={ButtonSizes.lg}
          isLoading={isSubmitting}
          onClick={handleConfirmClick}
          mt={10}
          mb={4}
          textStyle="body2Semi"
          analyticsProperties={{
            ...staticAnalyticsProps,
            selectedOption: upsellTypeToAnalyticsOptionName[UpsellType.PUSH_TO_FAST_ACH],
          }}
        />
        <AgreementLabel />
        <DebitCardPromotion isEnabled={!isSubmitting} onClick={handleDebitCardPromotionClick} />
        <ContentFooter companyName={organization?.companyName} />
      </ContentWrapper>
    </PageWrapper>
  );
};
