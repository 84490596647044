import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ManualAddressConfirmPage } from 'src/components/layout/ManualAddressConfirmPage';
import { ManualAddressSubtitle } from 'src/components/layout/ManualAddressSubtitle';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { IconNames } from 'src/core/ds/icon';
import { CheckDeliveryMethodState } from 'src/pages/vendor/delivery-methods/CheckDeliveryMethod';
import { useManualAddress } from 'src/pages/vendor/delivery-methods/hooks/useManualAddress';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { getOwnedVendorId } from 'src/redux/user/selectors';
import { ButtonVariant, DeliveryMethodCheckFlow, DeliveryMethodOrigin, ManualAddress } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { isReturnedCheck, isUndepositedCheck, isUndepositedOverdueCheck } from 'src/utils/payments';
import { CheckType } from 'src/utils/types';
import { CheckAddressDeliveryMethodForm } from './CheckAddressDeliveryMethodForm';
import { EditCheckAddressModal } from './editCheckAddressModal/EditCheckAddressModal';
import { NotificationCardReturnedCheck } from './notificationCards/NotificationCardReturnedCheck';
import { NotificationCardUndepositedCheck } from './notificationCards/NotificationCardUndepositedCheck';
import { getCheckAddressNextLabelForNewState } from './utils';

type Props = {
  vendorId: number;
  check?: Partial<CheckType>;
  goExit: () => void;
  onSuccess: (paperCheck: CheckType, isVerified: boolean, checkDeliveryMethodId?: number | null) => Promise<void>;
  isLoading: boolean;
  goUnilateralFlow: () => void;
  flowType: string;
};

export const EditCheckAddressWithAutocompleteDeliveryMethodPage = ({
  onSuccess,
  vendorId,
  check,
  goExit,
  isLoading,
  goUnilateralFlow,
  flowType,
}: Props) => {
  const payment = useSelector(getPayment);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const { actions, state } = useManualAddress({
    vendorId,
    onSuccess,
    checkModelOverwrite: check,
    ownedVendorId: ownedVendorId!,
  });
  const history = useHistory();
  const [origin] = useLocationState<DeliveryMethodOrigin | null>('origin', null);
  const isReturnedCheckPayment = isReturnedCheck(payment);
  const isUndepositedCheckPayment = isUndepositedCheck(payment);
  const isUndepositedOverdueCheckPayment = isUndepositedOverdueCheck(payment);
  const isVendorOrigin = origin
    ? [
        DeliveryMethodOrigin.VENDOR_DETAILS,
        DeliveryMethodOrigin.NEW_VENDOR,
        DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS,
      ].includes(origin)
    : false;

  const {
    address,
    selectedAddressId,
    whitePageValidationErrors,
    isAddressLoading,
    validatedAddressObj,
    checkDeliveryMethodMV,
    currentState,
    eventPage,
  } = state;

  const {
    onSelectedAddressId,
    onConfirmAddress,
    onConfirmInvalidAddress,
    submit,
    onEditAddress,
    onCloseModal,
  } = actions;

  const onSecondaryActionButtonClick = isVendorOrigin
    ? async () => {
        if (currentState === CheckDeliveryMethodState.CONFIRM) {
          await onConfirmInvalidAddress();

          if (selectedAddressId === ManualAddress.SUGGESTED) {
            goExit();
          }

          return;
        }

        const addressState = await submit();

        if (
          addressState !== CheckDeliveryMethodState.CONFIRM &&
          addressState !== CheckDeliveryMethodState.INVALID_ADDRESS &&
          addressState !== CheckDeliveryMethodState.INVALID_PO_BOX &&
          addressState !== CheckDeliveryMethodState.NEW_UNVERIFIED
        ) {
          goExit();
        }
      }
    : undefined;

  const TITLE = {
    [CheckDeliveryMethodState.CONFIRM]: 'manualAddress.confirmPage.title',
    [CheckDeliveryMethodState.NEW]: 'vendors.deliveryMethods.check.address.title',
    [CheckDeliveryMethodState.INVALID_ADDRESS]: 'manualAddress.invalidTitle',
    [CheckDeliveryMethodState.INVALID_PO_BOX]: 'manualAddress.invalidTitle',
  };

  const nextLabelForNewState = getCheckAddressNextLabelForNewState(
    isReturnedCheckPayment,
    isUndepositedCheckPayment,
    isVendorOrigin
  );

  const ACTIONS = {
    [CheckDeliveryMethodState.NEW]: {
      onNext: submit,
      nextLabel: nextLabelForNewState,
    },
    [CheckDeliveryMethodState.CONFIRM]: {
      onNext: onConfirmInvalidAddress,
      nextLabel: 'vendors.deliveryMethods.check.address.confirm',
    },
    [CheckDeliveryMethodState.INVALID_ADDRESS]: {
      onNext: onConfirmAddress,
      nextLabel: 'vendors.deliveryMethods.check.submit',
    },
    [CheckDeliveryMethodState.INVALID_PO_BOX]: {
      onNext: onConfirmAddress,
      nextLabel: 'manualAddress.edit',
    },
  };

  const PopUpFlow = {
    [DeliveryMethodCheckFlow.CONFIRM]: {
      onSuccessModal: onConfirmAddress,
      secondaryButtonLabel: 'manualAddress.correctInvalid',
      secondaryButtonVariant: ButtonVariant.CANCEL,
    },
    [DeliveryMethodCheckFlow.UNILATERAL]: {
      onSuccessModal: goUnilateralFlow,
      secondaryButtonLabel: 'manualAddress.goUnilateral',
      secondaryButtonVariant: ButtonVariant.SECONDARY,
      invalidSubtitle: 'manualAddress.invalidUnilateralSubtitle',
    },
  };

  const CHECK_INVALID_POPUP = {
    [CheckDeliveryMethodState.INVALID_ADDRESS]: (
      <EditCheckAddressModal
        address={address}
        printName={checkDeliveryMethodMV.printName.value}
        onCloseModal={onCloseModal}
        icon={IconNames.alertFill}
        subtitle="manualAddress.invalidSubtitle"
        PopUpFlow={PopUpFlow[flowType]}
      />
    ),
    [CheckDeliveryMethodState.INVALID_PO_BOX]: (
      <EditCheckAddressModal
        address={address}
        printName={checkDeliveryMethodMV.printName.value}
        onCloseModal={onCloseModal}
        icon={IconNames.alertFill}
        subtitle="manualAddress.invalidPOBoxSubtitle"
        PopUpFlow={PopUpFlow[flowType]}
      />
    ),
  };

  const CHECK_DELIVERY_CONTENT = {
    [CheckDeliveryMethodState.NEW]: (
      <CheckAddressDeliveryMethodForm submit={submit} model={checkDeliveryMethodMV} eventPage={eventPage} />
    ),
    [CheckDeliveryMethodState.CONFIRM]: (
      <>
        <ManualAddressSubtitle label="manualAddress.confirmPage.subtitle" />
        <ManualAddressConfirmPage
          printName={checkDeliveryMethodMV.printName.value}
          addressToConfirm={validatedAddressObj}
          addressValidationErrors={whitePageValidationErrors}
          originalAddress={address}
          onAddressSelect={onSelectedAddressId}
          onEditAddress={onEditAddress}
          selectedAddressId={selectedAddressId}
        />
      </>
    ),
  };

  const onPrev = () => {
    const { CONFIRM, INVALID_ADDRESS } = CheckDeliveryMethodState;

    if (currentState === CONFIRM || currentState === INVALID_ADDRESS) {
      onEditAddress();
    } else {
      history.goBack();
    }
  };

  return (
    <StepLayoutPage
      title={TITLE[currentState]}
      onNext={ACTIONS[currentState].onNext}
      goExit={goExit}
      onPrev={onPrev}
      isLoading={isLoading || isAddressLoading}
      nextLabel={ACTIONS[currentState].nextLabel}
      fullWidthCTA={isVendorOrigin}
      onSecondaryActionButtonClick={onSecondaryActionButtonClick}
      secondaryActionButtonLabel="vendors.deliveryMethods.check.saveAndClose"
    >
      {isReturnedCheckPayment && <NotificationCardReturnedCheck />}
      {isUndepositedOverdueCheckPayment && <NotificationCardUndepositedCheck />}
      {CHECK_DELIVERY_CONTENT[currentState]}
      {CHECK_INVALID_POPUP[currentState]}
    </StepLayoutPage>
  );
};
