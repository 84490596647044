type CardHolderNavigationStateParams = {
  orgId: number;
  tokenId: string;
  last4Digits: string;
  expirationMonth: string;
  expirationYear: string;
  redirectUrl: string;
  cardBin: string;
};
export const getCardHolderNavigationState = ({
  orgId,
  tokenId,
  last4Digits,
  expirationMonth,
  expirationYear,
  redirectUrl,
  cardBin,
}: CardHolderNavigationStateParams) => ({
  orgId,
  token: tokenId,
  digits: last4Digits,
  expiration: `${expirationMonth}/${expirationYear.toString().slice(-2)}`,
  redirectUrl,
  cardBin,
});
