import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { BillType, PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
  bill: BillType;
  recurringBill: BillType | null;
};

export const ViewPaymentHeaderSubtitle = ({ payment, bill, recurringBill }: Props) => {
  if (bill?.recurringBillId && recurringBill) {
    const { frequency, occurrences } = recurringBill;
    const index = bill.recurringBillIndex || null;

    return (
      <MIFormattedText
        label="bills.view.recurringBillInfo"
        values={{
          frequency,
          index,
          occurrences,
        }}
      />
    );
  }

  if (payment.bills && payment.bills.length > 1) {
    return (
      <MIFormattedText
        label="bills.view.bulkPaymentBillsCount"
        values={{
          billsCount: payment.bills.length,
        }}
      />
    );
  }

  return bill?.invoiceNumber ? (
    <MIFormattedText label="bills.view.invoiceNumber" values={{ invoiceNumber: bill.invoiceNumber }} />
  ) : (
    <MIFieldOrEmpty value={bill?.invoiceNumber} label="bills.form.invoiceNumberEmpty" />
  );
};
