import DownloadCsvReportModal from 'src/components/modals/DownloadCsvReportModal';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useModal } from 'src/helpers/react/useModal';
import { analytics } from 'src/services/analytics';

export default function DownloadCsvReportButton() {
  const [DownloadCsvReportModalActual, showDownloadCsvReportModalActual] = useModal(DownloadCsvReportModal, {
    id: 'downloadCsvReportModal',
  });

  const handleClick = () => {
    analytics.trackAction('payments-csv-report.clicked');
    showDownloadCsvReportModalActual();
  };

  return (
    <>
      <Button
        size={ButtonSizes.md}
        variant={ButtonVariants.tertiary}
        onClick={handleClick}
        label="settings.company.paymentsReport.button"
      />
      {DownloadCsvReportModalActual}
    </>
  );
}
