import { RecordOf } from 'immutable';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import {
  all,
  call,
  CallEffect,
  getContext,
  put,
  PutEffect,
  SagaReturnType,
  select,
  StrictEffect,
  takeEvery,
} from 'redux-saga/effects';
import { getPayBillFlowUUID, startTrackBillPayFlow, stopTrackingBillPayFlow } from 'src/analytics/trackPayBillFlow';
import { billsApi } from 'src/modules/bills/api';
import { financingStore } from 'src/modules/financing/financing-store';
import { organizationsApi } from 'src/modules/organizations/api';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentRequestsApi } from 'src/modules/payment-requests/api';
import { paymentsApi } from 'src/modules/payments/api';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { vendorsApi } from 'src/modules/vendors/api';
import { billFactory } from 'src/pages/bill/records';
import { getPaymentRequestId } from 'src/pages/bill/utils/billGetters';
import { paymentFactory } from 'src/pages/payment/records';
import { isInternationalVendor } from 'src/pages/vendor/international-delivery-method/utils';
import { getDefaultFundingSource, getDeliveryMethodForPayment } from 'src/redux/utils';
import { Site } from 'src/sites/site';
import {
  convertPaymentRequestToBill,
  getDefaultMemo,
  getPaymentRequestIdById,
  isPaymentRequest,
} from 'src/utils/bills';
import {
  DataOrigin,
  DeliveryType,
  FastCheckDeliveryType,
  PaymentCreateFlowOrigin,
  PaymentRequestApprovalStatus,
} from 'src/utils/consts';
import { melioClose, paymentSuccess } from 'src/utils/external-events';
import { isBankAccountBlocked } from 'src/utils/funding-sources';
import { buildFinancingPayment, buildInternationalPayment } from 'src/utils/payments';
import {
  AccountType,
  BillType,
  DeliveryMethodType,
  OrganizationPreferencesType,
  PaymentType,
  VendorType,
} from 'src/utils/types';
import { getFundingSources, getOrgId, getProfile } from '../user/selectors';
import {
  addNewDeliveryMethodAction,
  beginRecurringPayBillFlowFailedAction,
  beginRecurringPayBillFlowSuccessAction,
  beginRegularPayBillFlowFailedAction,
  beginRegularPayBillFlowSuccessAction,
  cancelAndRetryPaymentErrorAction,
  cancelAndRetryPaymentSuccessAction,
  createBillAndPaymentFromPaymentRequestAction,
  createPaymentErrorAction,
  createPaymentSuccessAction,
  createRecurringBillErrorAction,
  createRecurringBillSuccessAction,
  endPayBillFlowAction,
  fetchBillAction,
  fetchBillFailedAction,
  fetchBillSuccessAction,
  retryFailedPaymentErrorAction,
  retryFailedPaymentSuccessAction,
  selectDeliveryMethodAction,
  setBillIdAction,
  updatePaymentErrorAction,
  updatePaymentSuccessAction,
} from './actions';
import {
  BEGIN_RECURRING_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW,
  CANCEL_AND_RETRY_PAYMENT,
  CREATE_BILL_AND_PAYMENT_FROM_PAYMENT_REQUEST,
  CREATE_FINANCING_PAYMENT,
  CREATE_INTERNATIONAL_PAYMENT,
  CREATE_PAYMENT,
  CREATE_RECURRING_BILL,
  END_PAY_BILL_FLOW,
  FETCH_BILL,
  RESCHEDULE_PAYMENT,
  RETRY_FAILED_TO_COLLECT_PAYMENT,
  RETRY_FAILED_TO_DELIVER_PAYMENT,
  SELECT_FUNDING_SOURCE,
  SELECT_NEW_DELIVERY_METHOD,
  SELECT_REPAYMENT_METHOD,
  UPDATE_PAYMENT,
} from './actionTypes';
import { recurringBillsApi } from './api';
import { getBill, getIsRecurring, getPayment, getRecurringBill, getScannedInvoiceId } from './selectors';
import {
  BeginRecurringPayBillFlowType,
  BeginRegularPayBillFlowSagaReturnType,
  BeginRegularPayBillFlowType,
  BuildPaymentSagaReturnType,
  CancelAndRetryPaymentType,
  CreatePaymentAllSelectsReturnType,
  CreatePaymentType,
  CreateRecurringBillAllSelectsReturnType,
  CreateRecurringBillType,
  RetryFailedPaymentType,
  RunPaymentSyncApiCallReturnType,
  SelectFundingSourceType,
  SelectNewDeliveryMethodType,
  UpdatePaymentType,
} from './types';

function* buildPayment(bill: BillType, orgId: number, paymentId?: string): BuildPaymentSagaReturnType {
  if (paymentId) {
    const paymentFromBill = bill?.payments?.find((p) => p.id === +paymentId);

    if (paymentFromBill) {
      return paymentFactory(paymentFromBill);
    }
  }

  const site: Site = yield getContext('site');
  const fundingSources = yield select(getFundingSources);
  const deliveryMethods = bill?.vendor?.deliveryMethods || [];
  const isInternational = isInternationalVendor(bill?.vendor as VendorType);
  const isFinancingPayment = yield select(financingStore.selectors.isFinancingPayment);
  const organizationPreferences: OrganizationPreferencesType = yield select(
    organizationStore.selectors.organizationPreferences
  );
  const defaultFundingSourceId = organizationPreferences?.defaultFundingSourceId
    ? Number(organizationPreferences.defaultFundingSourceId)
    : null;

  let preSelectedFundingSource: AccountType | null = yield call(
    getDefaultFundingSource,
    orgId,
    fundingSources,
    defaultFundingSourceId,
    isInternational
  );

  if (isBankAccountBlocked(preSelectedFundingSource)) {
    preSelectedFundingSource = null;
  }

  const fundingSourceId: number | undefined = preSelectedFundingSource?.id;
  const deliveryMethod = getDeliveryMethodForPayment({
    deliveryMethods,
    fundingSource: preSelectedFundingSource ?? undefined,
    isFinancing: !!isFinancingPayment,
  });

  const deliveryMethodId = deliveryMethod?.id || null;
  const isDirectPayment = deliveryMethod?.deliveryType === DeliveryType.RPPS;

  const createdFromRequest = bill.paymentRequest?.id;
  const requestCreateOrigin = PaymentCreateFlowOrigin.REQUEST;
  const createOrigin = createdFromRequest ? requestCreateOrigin : site.createOrigin.pay.payment;

  let note: string | null = null;

  if (!isDirectPayment) {
    const isIntuitVendor = bill?.vendor?.origin === DataOrigin.INTUIT;
    const { intuitAcctNum }: { intuitAcctNum: string } = isIntuitVendor
      ? yield call(vendorsApi.getIntuitAcctNum, orgId, bill.vendor?.id)
      : { intuitAcctNum: '' };
    note = getDefaultMemo(bill.invoiceNumber, intuitAcctNum);
  }

  const isRecurring = yield select(getIsRecurring);

  return paymentFactory({
    billId: bill.id,
    vendorId: bill?.vendorId ?? undefined,
    amount: !isRecurring ? bill.balance : bill.totalAmount,
    currency: bill.currency,
    createOrigin,
    note,
    fundingSourceId,
    deliveryMethod: deliveryMethod || undefined,
    deliveryMethodId: deliveryMethodId || undefined,
  });
}

function* beginRegularPayBillFlow({
  id,
  paymentId,
}: BeginRegularPayBillFlowType): BeginRegularPayBillFlowSagaReturnType {
  // @ts-expect-error -- type of `select` is wrong
  const orgId: number = yield select(getOrgId);
  let bill;
  let billRecord: BillType;
  let payment;
  startTrackBillPayFlow();
  try {
    if (isPaymentRequest(id)) {
      const requestId = getPaymentRequestIdById(id);
      ({ paymentRequest: bill } = yield call(paymentRequestsApi.getPaymentRequestById, {
        orgId,
        id: requestId,
      }));
      billRecord = convertPaymentRequestToBill(bill);
      payment = yield call(buildPayment, billRecord, orgId, paymentId);
    } else if (paymentId) {
      ({ object: payment } = yield call(paymentsApi.getPaymentById, orgId, paymentId));
      bill = payment?.bills?.find((bill) => bill.id === +id);
      billRecord = billFactory({ ...bill, vendor: payment.vendor });
      payment.billIds = payment.bills.map((bill) => bill.id);
    } else {
      ({ object: bill } = yield call(billsApi.getBillById, {
        orgId,
        id,
      }));
      billRecord = billFactory(bill);
      payment = yield call(buildPayment, billRecord, orgId, paymentId);
    }

    const payBillFlowUUID = getPayBillFlowUUID();

    yield put(beginRegularPayBillFlowSuccessAction(billRecord, { ...payment, payBillFlowUUID }));
  } catch (e) {
    yield put(beginRegularPayBillFlowFailedAction());
  }
}

function* beginRecurringPayBillFlow({
  bill,
  recurringBill,
  scannedInvoiceId,
}: BeginRecurringPayBillFlowType): Generator<StrictEffect, void, string & RecordOf<PaymentType>> {
  try {
    startTrackBillPayFlow();
    // @ts-expect-error -- return type of `select` is wrong
    const orgId: number = yield select(getOrgId);
    const billRecord = billFactory(bill);
    const payment: PaymentType = yield call(buildPayment, billRecord, orgId);
    const payBillFlowUUID = getPayBillFlowUUID() as string;

    yield put(
      beginRecurringPayBillFlowSuccessAction({
        bill: billRecord,
        payment: { ...payment, payBillFlowUUID },
        recurringBill,
        scannedInvoiceId,
      })
    );
  } catch (e) {
    yield put(beginRecurringPayBillFlowFailedAction());
  }
}

function* selectNewDeliveryMethod({ deliveryMethod }: SelectNewDeliveryMethodType): Generator<PutEffect, void> {
  yield put(addNewDeliveryMethodAction(deliveryMethod));
  yield put(selectDeliveryMethodAction(deliveryMethod));
}

function* selectFundingSource({
  id,
}: SelectFundingSourceType): Generator<StrictEffect, void, [RecordOf<AccountType>[], PaymentType, BillType, boolean]> {
  const [fundingSources, payment, bill, isFinancingPayment] = yield all([
    select(getFundingSources),
    select(getPayment),
    select(getBill),
    select(financingStore.selectors.isFinancingPayment),
  ]);

  const fundingSource = fundingSources.find((fs) => fs.id === id) as RecordOf<AccountType>;
  const deliveryMethods = bill?.vendor?.deliveryMethods as DeliveryMethodType[];
  const deliveryMethod = getDeliveryMethodForPayment({
    deliveryMethods,
    fundingSource,
    currentDeliveryMethodId: payment.deliveryMethodId,
    isFinancing: !!isFinancingPayment,
  });

  if (deliveryMethod?.id !== payment.deliveryMethodId) {
    yield put(selectDeliveryMethodAction(deliveryMethod));
  }
}

function* createRecurringBill({
  resolve,
  reject,
}: CreateRecurringBillType): Generator<
  StrictEffect,
  void,
  CreateRecurringBillAllSelectsReturnType & { bills: RecordOf<BillType>[] }
> {
  const [payment, bill, recurringBill, orgId, scannedInvoiceId]: CreateRecurringBillAllSelectsReturnType = yield all([
    select(getPayment),
    select(getBill),
    select(getRecurringBill),
    select(getOrgId),
    select(getScannedInvoiceId),
  ]);

  const payBillFlowUUID = getPayBillFlowUUID();
  try {
    const data = {
      recurringFields: recurringBill,
      vendorId: bill.vendor?.id,
      amount: bill.totalAmount,
      dueDate: deliveryApi.dateInCentral(new Date(bill.dueDate)),
      invoiceNumber: bill.invoiceNumber,
      billNote: bill.note,
      paymentNote: payment.note,
      intuitAccountId: bill.intuitAccountId,
      fundingSourceId: payment.fundingSourceId,
      deliveryMethodId: payment.deliveryMethodId,
      purpose: payment.purpose,
      payBillFlowUUID,
      createOrigin: payment.createOrigin,
      scannedInvoiceId,
    };

    const { bills }: { bills: BillType[] } = yield call(recurringBillsApi.createRecurringBill, orgId, data);
    const firstBillIdWithRecurringBill: string = bills.find((b) => b.recurringBillIndex === 1)?.id || '';

    const firstPayment = paymentFactory(first(first(bills)?.payments));
    yield put(
      createRecurringBillSuccessAction(firstBillIdWithRecurringBill, {
        ...firstPayment,
        payBillFlowUUID,
      })
    );
    resolve();
  } catch (e: any) {
    yield put(createRecurringBillErrorAction(e.code));
    reject(e);
  }
}

function* syncQBOPayment(paymentObj, orgId) {
  if (
    paymentObj.createOrigin &&
    [
      PaymentCreateFlowOrigin.QBO_INTEGRATION,
      PaymentCreateFlowOrigin.QBM_IOS,
      PaymentCreateFlowOrigin.QBM_ANDROID,
    ].includes(paymentObj.createOrigin)
  ) {
    const resSyncData: RunPaymentSyncApiCallReturnType = yield call(
      organizationsApi.runPaymentSync,
      orgId,
      paymentObj.id
    );
    paymentObj.originId = resSyncData?.payment?.originId;
  }
}

function* createPayment({
  resolve,
  reject,
}: CreatePaymentType): Generator<
  StrictEffect,
  void,
  CreatePaymentAllSelectsReturnType & PaymentType & RunPaymentSyncApiCallReturnType
> {
  const payBillFlowUUID = getPayBillFlowUUID();

  const [payment, orgId]: CreatePaymentAllSelectsReturnType = yield all([select(getPayment), select(getOrgId)]);

  try {
    const paymentObj: PaymentType = yield call(paymentsApi.createPayment, orgId, { ...payment, payBillFlowUUID });

    yield call(syncQBOPayment, paymentObj, orgId);

    const value = paymentFactory({ ...paymentObj, payBillFlowUUID });
    yield put(createPaymentSuccessAction(value));
    resolve(value);
  } catch (e: any) {
    yield put(createPaymentErrorAction(e.code));
    reject(e);
  }
}

function* createInternationalPayment({
  resolve,
  reject,
}: CreatePaymentType): Generator<
  StrictEffect,
  void,
  CreatePaymentAllSelectsReturnType & PaymentType & RunPaymentSyncApiCallReturnType
> {
  const payBillFlowUUID = getPayBillFlowUUID();

  const [payment, orgId, profile]: CreatePaymentAllSelectsReturnType = yield all([
    select(getPayment),
    select(getOrgId),
    select(getProfile),
  ]);
  const params = buildInternationalPayment({
    payment,
    organizationId: orgId,
    userId: profile.id,
    idempotentKey: payBillFlowUUID,
  });

  try {
    const paymentObj: PaymentType = yield call(paymentsApi.createInternationalPayment, orgId, {
      ...params,
      payBillFlowUUID,
    });

    yield call(syncQBOPayment, paymentObj, orgId);

    const value = paymentFactory({ ...paymentObj, payBillFlowUUID });
    yield put(createPaymentSuccessAction(value));
    resolve(value);
  } catch (e: any) {
    yield put(createPaymentErrorAction(e.code));
    reject(e);
  }
}

function* createFinancingPayment({
  resolve,
  reject,
}: CreatePaymentType): Generator<
  StrictEffect,
  void,
  CreatePaymentAllSelectsReturnType & PaymentType & RunPaymentSyncApiCallReturnType
> {
  const payBillFlowUUID = getPayBillFlowUUID();
  const [payment, orgId, profile, intentId, selectedInstallmentOption]: CreatePaymentAllSelectsReturnType = yield all([
    select(getPayment),
    select(getOrgId),
    select(getProfile),
    select(financingStore.selectors.intentIdWithInstallmentOption.intentId),
    select(financingStore.selectors.intentIdWithInstallmentOption.selectedInstallmentOption),
  ]);

  try {
    if (!selectedInstallmentOption) {
      throw new Error('No installment option selected');
    }

    const params = buildFinancingPayment({
      payment,
      organizationId: orgId,
      userId: profile.id,
      idempotentKey: payBillFlowUUID,
      financingDetails: {
        intentId,
        ...pick(selectedInstallmentOption, ['numberOfInstallments']),
      },
    });
    const paymentObj: PaymentType = yield call(paymentsApi.createFinancingPayment, orgId, {
      ...params,
      payBillFlowUUID,
    });

    yield call(syncQBOPayment, paymentObj, orgId);

    const value = paymentFactory({ ...paymentObj, payBillFlowUUID });
    yield put(createPaymentSuccessAction(value));
    resolve(value);
  } catch (e: any) {
    yield put(createPaymentErrorAction(e.code));
    reject(e);
  }
}

function* createBillAndPaymentFromPaymentRequest({
  resolve,
  reject,
}: SagaReturnType<typeof createBillAndPaymentFromPaymentRequestAction>): SagaReturnType<
  typeof paymentsApi.createPayment
> {
  const payBillFlowUUID = getPayBillFlowUUID();
  const orgId: number = yield select(getOrgId);
  const paymentRequestAsBillObject: BillType = yield select(getBill);
  const paymentRequestId = getPaymentRequestId(paymentRequestAsBillObject);

  let isBillCreated = false;

  try {
    const { newBill: bill } = yield call(billsApi.createBillFromRequest, orgId, {
      approvalStatus: PaymentRequestApprovalStatus.APPROVED,
      paymentRequestId,
      payBillFlowUUID,
    });
    isBillCreated = Boolean(bill);

    const { goodsReceived } = paymentRequestAsBillObject;

    if (!isNil(goodsReceived)) {
      yield call(billsApi.editBillById, orgId, bill.id, { goodsReceived }, 'all');
    }

    yield put(setBillIdAction(bill.id));
    const payment: PaymentType = yield select(getPayment);
    const paymentObj = yield call(paymentsApi.createPayment, orgId, payment);
    yield put(createPaymentSuccessAction(paymentFactory({ ...paymentObj, payBillFlowUUID })));
    resolve(payment);
  } catch (e: any) {
    if (isBillCreated) {
      yield call(paymentRequestsApi.revokeApprovalStatus, orgId, paymentRequestId);
    }

    yield put(createPaymentErrorAction(e.code));
    reject(e);
  }
}

function* reschedulePayment({
  resolve,
  reject,
}: RetryFailedPaymentType): SagaReturnType<
  typeof paymentsApi.retryFailedToDeliver | typeof paymentsApi.retryFailedToCollect
> {
  const [payment, orgId]: [PaymentType, string] = yield all([select(getPayment), select(getOrgId)]);
  const payBillFlowUUID = getPayBillFlowUUID();
  try {
    const paymentData = { ...payment, createOrigin: PaymentCreateFlowOrigin.PAY, payBillFlowUUID };
    const paymentObj = yield call(paymentsApi.reschedule, orgId, paymentData);

    yield put(retryFailedPaymentSuccessAction(paymentFactory({ ...paymentObj.payment, payBillFlowUUID })));
    resolve(paymentObj.payment);
  } catch (e: any) {
    yield put(retryFailedPaymentErrorAction(e.code));
    reject(e);
  }
}

function* retryFailedToDeliverPayment({
  resolve,
  reject,
}: RetryFailedPaymentType): SagaReturnType<
  typeof paymentsApi.retryFailedToDeliver | typeof paymentsApi.retryFailedToCollect
> {
  const [payment, orgId]: [PaymentType, string] = yield all([select(getPayment), select(getOrgId)]);
  const payBillFlowUUID = getPayBillFlowUUID();
  try {
    const paymentData = { ...payment, createOrigin: PaymentCreateFlowOrigin.PAY, payBillFlowUUID };
    const paymentObj = yield call(paymentsApi.retryFailedToDeliver, orgId, paymentData);

    yield put(retryFailedPaymentSuccessAction(paymentFactory({ ...paymentObj.payment, payBillFlowUUID })));
    resolve(paymentObj.payment);
  } catch (e: any) {
    yield put(retryFailedPaymentErrorAction(e.code));
    reject(e);
  }
}

function* retryFailedToCollectPayment({
  resolve,
  reject,
}: RetryFailedPaymentType): SagaReturnType<
  typeof paymentsApi.retryFailedToDeliver | typeof paymentsApi.retryFailedToCollect
> {
  const [payment, orgId]: [PaymentType, string] = yield all([select(getPayment), select(getOrgId)]);
  const payBillFlowUUID = getPayBillFlowUUID();
  try {
    const paymentData = { ...payment, createOrigin: PaymentCreateFlowOrigin.PAY, payBillFlowUUID };
    const paymentObj = yield call(paymentsApi.retryFailedToCollect, orgId, paymentData);

    yield put(retryFailedPaymentSuccessAction(paymentFactory({ ...paymentObj.payment, payBillFlowUUID })));
    resolve(paymentObj.payment);
  } catch (e: any) {
    yield put(retryFailedPaymentErrorAction(e.code));
    reject(e);
  }
}

function* cancelAndRetryPayment({
  resolve,
  reject,
}: CancelAndRetryPaymentType): SagaReturnType<typeof paymentsApi.cancelAndRetry> {
  const [payment, orgId]: [PaymentType, string] = yield all([select(getPayment), select(getOrgId)]);
  const shouldUpdateDeliveryPreferense =
    payment.deliveryMethod.deliveryType === DeliveryType.CHECK &&
    payment.deliveryPreference === FastCheckDeliveryType.EXPRESS;
  try {
    if (payment) {
      yield call(paymentsApi.cancelAndRetry, orgId, {
        id: payment.id,
        newDeliveryMethodId: payment.deliveryMethodId,
        deliveryPreference: shouldUpdateDeliveryPreferense ? payment.deliveryPreference : null,
      });
    }

    yield put(cancelAndRetryPaymentSuccessAction(payment));
    resolve(payment);
  } catch (e: any) {
    yield put(cancelAndRetryPaymentErrorAction(e.code));
    reject(e);
  }
}

function* updatePayment({ resolve, reject }: UpdatePaymentType): SagaReturnType<typeof paymentsApi.editPaymentById> {
  const [payment, orgId]: [RecordOf<PaymentType>, string] = yield all([select(getPayment), select(getOrgId)]);
  const payBillFlowUUID = getPayBillFlowUUID();
  try {
    const paymentObj = yield call(paymentsApi.editPaymentById, orgId, { ...payment, payBillFlowUUID });

    yield put(updatePaymentSuccessAction(paymentFactory({ ...paymentObj.object, payBillFlowUUID })));
    resolve();
  } catch (e: any) {
    yield put(updatePaymentErrorAction(e.code));
    reject(e);
  }
}

function* fetchBill({ id }: ReturnType<typeof fetchBillAction>): SagaReturnType<typeof billsApi.getBillById> {
  const orgId: number = yield select(getOrgId);

  try {
    const { object: bill } = yield call(billsApi.getBillById, {
      orgId,
      id,
    });
    const billRecord = billFactory(bill);
    yield put(fetchBillSuccessAction(billRecord));
  } catch (e) {
    yield put(fetchBillFailedAction());
  }
}

function* endPayBillFlow({
  isCloseEvent,
  preventCloseIframe,
}: ReturnType<typeof endPayBillFlowAction>): Generator<CallEffect, void> {
  stopTrackingBillPayFlow();

  if (!preventCloseIframe) {
    if (isCloseEvent) {
      yield call(melioClose);
    } else {
      yield call(paymentSuccess);
    }
  }
}

function* watchBeginRegularPayBillFlow() {
  yield takeEvery(BEGIN_REGULAR_PAY_BILL_FLOW, beginRegularPayBillFlow);
}

function* watchBeginRecurringPayBillFlow() {
  yield takeEvery(BEGIN_RECURRING_PAY_BILL_FLOW, beginRecurringPayBillFlow);
}

function* watchSelectNewDeliveryMethod() {
  yield takeEvery(SELECT_NEW_DELIVERY_METHOD, selectNewDeliveryMethod);
}

function* watchSelectFundingSource() {
  yield takeEvery(SELECT_FUNDING_SOURCE, selectFundingSource);
}

function* watchSelectRepaymentMethod() {
  yield takeEvery(SELECT_REPAYMENT_METHOD, selectFundingSource);
}

function* watchCreateRecurringBill() {
  yield takeEvery(CREATE_RECURRING_BILL, createRecurringBill);
}

function* watchCreatePayment() {
  yield takeEvery(CREATE_PAYMENT, createPayment);
}

function* watchUpdatePayment() {
  yield takeEvery(UPDATE_PAYMENT, updatePayment);
}

function* watchReschedulePayment() {
  yield takeEvery(RESCHEDULE_PAYMENT, reschedulePayment);
}

function* watchRetryFailedToDeliverPayment() {
  yield takeEvery(RETRY_FAILED_TO_DELIVER_PAYMENT, retryFailedToDeliverPayment);
}

function* watchRetryFailedToCollectPayment() {
  yield takeEvery(RETRY_FAILED_TO_COLLECT_PAYMENT, retryFailedToCollectPayment);
}

function* watchCancelAndRetryPayment() {
  yield takeEvery(CANCEL_AND_RETRY_PAYMENT, cancelAndRetryPayment);
}

function* watchEndPayBillFlow() {
  yield takeEvery(END_PAY_BILL_FLOW, endPayBillFlow);
}

function* watchFetchBill() {
  yield takeEvery(FETCH_BILL, fetchBill);
}

function* watchCreateBillAndPaymentFromPaymentRequest() {
  yield takeEvery(CREATE_BILL_AND_PAYMENT_FROM_PAYMENT_REQUEST, createBillAndPaymentFromPaymentRequest);
}

function* watchCreateInternationalPayment() {
  yield takeEvery(CREATE_INTERNATIONAL_PAYMENT, createInternationalPayment);
}

function* watchCreateFinancingPayment() {
  yield takeEvery(CREATE_FINANCING_PAYMENT, createFinancingPayment);
}

function* watchFinancingSetInstallmentOptionWithIntentId() {
  yield takeEvery('[FINANCING] INTENT_ID_WITH_INSTALLMENT_OPTION/set', selectFundingSource);
}

// eslint-disable-next-line
export default function* payBillFlowSagas() {
  yield all([
    watchBeginRegularPayBillFlow(),
    watchBeginRecurringPayBillFlow(),
    watchSelectNewDeliveryMethod(),
    watchSelectFundingSource(),
    watchSelectRepaymentMethod(),
    watchCreateRecurringBill(),
    watchCreatePayment(),
    watchUpdatePayment(),
    watchEndPayBillFlow(),
    watchFetchBill(),
    watchReschedulePayment(),
    watchRetryFailedToDeliverPayment(),
    watchRetryFailedToCollectPayment(),
    watchCancelAndRetryPayment(),
    watchCreateBillAndPaymentFromPaymentRequest(),
    watchCreateInternationalPayment(),
    watchCreateFinancingPayment(),
    watchFinancingSetInstallmentOptionWithIntentId(),
  ]);
}
