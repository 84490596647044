import { featureFlags } from '@melio/shared-web';
import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { FeatureFlags } from 'src/utils/consts';
import { ModalButtonsWrapper } from '../ModalButtonsWrapper';
import { RolePermissionItem } from './RolePermissionItem';

type RolesAndPermissionsModalMessageProps = {
  onOkClick: () => void;
  onCloseClick: (event?: React.MouseEvent) => void;
};

export const RolesAndPermissionsModalMessage = ({ onCloseClick, onOkClick }: RolesAndPermissionsModalMessageProps) => {
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const url = shouldDisplayZendesk
    ? 'https://melio.zendesk.com/hc/en-us/articles/4418593083932-The-different-user-roles-in-Melio'
    : 'https://help.meliopayments.com/en/articles/3804400-the-different-user-roles-in-melio';

  return (
    <ModalMessage
      id="roles-and-permissions-modal"
      onCloseClick={onCloseClick}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label="team.modals.rolesAndPermissions.title" />
        </ModalTitle>
      }
      contentComponent={
        <RolesAndPermissionsModalMessageContent>
          <RolePermissionItem titleLabel="user.role.admin" permissionsLabel="user.permissions.accountingFirmAdmin" />
          <RolePermissionItem titleLabel="user.role.accountant" permissionsLabel="user.permissions.accountant" />
          <RolePermissionItem titleLabel="user.role.contributor" permissionsLabel="user.permissions.contributor" />
          <ReadMoreWrapper>
            <MIInlineLink to={url} label="team.modals.rolesAndPermissions.readMore" />
          </ReadMoreWrapper>
        </RolesAndPermissionsModalMessageContent>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <ButtonsContainer>
            <Button boxSizing="border-box" onClick={onOkClick} size={ButtonSizes.md} width={['100%', null, 'auto']}>
              <MIFormattedText label="team.modals.rolesAndPermissions.buttons.back" />
            </Button>
          </ButtonsContainer>
        </ModalButtonsWrapper>
      }
    />
  );
};
const ReadMoreWrapper = styled.div`
  a {
    font-size: inherit;
    font-weight: inherit;
    color: ${(props) => props.theme.colors.brand};
    padding-left: 0.4rem;
  }
`;

const RolesAndPermissionsModalMessageContent = styled.div`
  text-align: left;
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
