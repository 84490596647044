import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore from 'src/modules/organizations/organizations-store';
import { companiesLocations } from 'src/pages/companies/locations';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';

const AddAccountingFirmFromEmail = () => {
  const { search } = useLocation();
  const [historyPush] = useHistoryWithOrgId();
  const orgId = new URLSearchParams(search).get('orgId') as string;
  const companyInfo = useSelector(getCompanyInfo);
  const organizationActions = useStoreActions(organizationStore);

  useEffect(() => {
    organizationActions
      .switchAccountingFirm({ orgId })
      .then(() => {
        pushNotification({
          type: NotificationVariant.SUCCESS,
          msg: 'addAccountingFirm.fromEmail.success',
          textValues: { companyName: companyInfo.companyName },
        });
        historyPush({ path: companiesLocations.index });
      })
      .catch(() => {
        historyPush({ path: companiesLocations.index });
      });
  }, [orgId, historyPush, companyInfo.companyName, organizationActions]);

  return <AreaLoader />;
};

export default AddAccountingFirmFromEmail;
