import {
  ValidatableBeneficialOwnerKeys,
  validator as beneficialOwnerValidator,
} from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/beneficial-owners/validator';
import {
  ValidatableMainOwnerKeys,
  validator as mainOwnerValidator,
} from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/main-owner/validator';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';

export const validator = (key: string, value: unknown, modelState: InternalBusinessOwnershipDetailsModel) => {
  if (
    [
      'firstName',
      'lastName',
      'email',
      'dateOfBirth',
      'ssn',
      'phone',
      'ownershipPercentage',
      'addressLine1',
      'city',
      'state',
      'zipCode',
    ].includes(key)
  ) {
    return mainOwnerValidator(key as ValidatableMainOwnerKeys, modelState);
  }

  if (key.startsWith('beneficialOwner'))
    return beneficialOwnerValidator(key as ValidatableBeneficialOwnerKeys, modelState);

  return undefined;
};
