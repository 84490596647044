import { useFeature } from '@melio/shared-web/dist/feature-flags';
import head from 'lodash/head';
import * as React from 'react';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { PAGE_EVENT } from 'src/pages/regular-batch-payments/analytics/event-mapping';
import { FundingSourceCell } from 'src/pages/regular-batch-payments/table/components/FundingSourceCell';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';
import {
  isFundingSourceAllowedForInternational,
  isInternationalDeliveryMethod,
} from 'src/pages/vendor/international-delivery-method/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { getDeliveryMethodForPayment } from 'src/redux/utils';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { AccountType } from 'src/utils/types';

type Props = {
  itemId: string;
  payment: BatchPaymentType;
  payBillFlowUUID: string;
};

export const PaymentMethodCell: React.FC<Props> = memo(({ itemId, payment, payBillFlowUUID }) => {
  const [currentFundingSourceId, setCurrentFundingSourceId] = React.useState<number | undefined>(
    payment?.fundingSourceId
  );
  const actions = useStoreActions(regularBatchPaymentsStore);
  const [isInternationalMastercardEnabled] = useFeature(FeatureFlags.EnableMastercardFSForInternational, false);
  const orgId = useSelector(getOrgId);
  const { vendorId, deliveryMethodId, scheduledDate, amount, fundingSourceId } = payment;
  const { deliveryMethods } = useSelector(vendorsStore.selectors.fetch.byId(vendorId));
  const isInternationalVendor =
    payment.deliveryMethod && isInternationalDeliveryMethod(payment.deliveryMethod?.deliveryType);

  const onChange = async (fundingSource?: AccountType) => {
    analytics.track(PAGE_EVENT, 'update_payment-method', {
      payBillFlowUUID,
      billIds: payment.bills.map((bill) => bill.id),
    });

    const newFundingSourceId = fundingSource?.id;

    if (fundingSourceId === newFundingSourceId) return;

    setCurrentFundingSourceId(newFundingSourceId);

    const newDeliveryMethod = getDeliveryMethodForPayment({
      deliveryMethods,
      fundingSource,
      currentDeliveryMethodId: deliveryMethodId,
      isFinancing: false,
    });

    if (newDeliveryMethod) {
      await actions.updatePaymentsDeliveryOptions({
        itemId,
        orgId,
        deductionDate: scheduledDate,
        fundingSourceId: newFundingSourceId,
        amount,
        payBillFlowUUID,
        // we want to reset delivery speed cell on funding source change.
        deliveryPreference: null,
        deliveryMethod: newDeliveryMethod,
        deliveryMethodId: newDeliveryMethod.id,
      });
    } else {
      if (deliveryMethodId) {
        await actions.updatePayments.resetDeliveryMethod({
          itemId,
        });
      }

      await actions.updatePaymentsDeductionDates({
        itemId,
        orgId,
        scheduledDate: new Date(),
        fundingSourceId: newFundingSourceId,
      });
    }
  };

  const [validFundingSources] = useGetValidFundingSources();
  const currentFundingSource = validFundingSources.find((fs) => fs.id === currentFundingSourceId);

  useEffect(() => {
    const isFundingSourceAllowed = isFundingSourceAllowedForInternational({
      fundingSource: currentFundingSource,
      isInternationalMastercardEnabled,
    });

    if (isInternationalVendor && !isFundingSourceAllowed) {
      const validFundingSource = head(
        validFundingSources.filter((fs) =>
          isFundingSourceAllowedForInternational({
            fundingSource: fs,
            isInternationalMastercardEnabled,
          })
        )
      );

      if (validFundingSource) {
        const validFundingSourceId = validFundingSource.id;

        setCurrentFundingSourceId(validFundingSourceId);
        onChange(validFundingSource);
      } else {
        capture(new Error(`Batch payments contain international bill without valid funding source. BillId: ${itemId}`));
        onChange(undefined);
      }
    }
  }, [isFundingSourceAllowedForInternational, onChange, validFundingSources, currentFundingSource]);

  return (
    <FundingSourceCell
      payment={payment}
      fundingSources={validFundingSources}
      currentFundingSourceId={currentFundingSourceId}
      setCurrentFundingSource={onChange}
      isInternationalVendor={isInternationalVendor}
    />
  );
});
