import { TransactionResponse } from '@melio/spend-management-api-axios-client';
import { fetchRequest } from 'src/services/api/api';
import { TransactionsListParams } from './types';

export const transactionsApi = {
  list({ orgId, accountId, cardId }: TransactionsListParams) {
    const url = `/orgs/${orgId}/spend/transactions`;

    return fetchRequest(url, { accountId, cardId }).then(
      (res: { transactions: TransactionResponse[] }) => res.transactions
    );
  },
};
