import { History } from 'history';
import curry from 'lodash/curry';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { vendorLocations } from 'src/pages/vendor/locations';
import { BankAccountType, DeliveryType, PaymentStatus } from 'src/utils/consts';
import { deliveryTypePredicate } from 'src/utils/delivery-methods';
import {
  BankType,
  CheckType,
  EditableDeliveryMethodType,
  FetchFileResultType,
  FieldType,
  PaymentType,
} from 'src/utils/types';

type State = {
  deliveryType?: DeliveryType;
};

export type UnilateralPaymentState = {
  payment: PaymentType;
  organization: any;
  filesUrls: FetchFileResultType;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
} & State;

export type UnilateralPaymentProps = {
  token: string;
};

export type UnilateralPaymentActions = {
  onChange: (updateField: FieldType) => void;
  onSubmit: (value: EditableDeliveryMethodType, isAddressVerified: boolean) => Promise<void>;
};

export type DeliveryMethodActionsType = {
  updateWithUnilateralToken: (params) => void;
  fetchUnilateralRequestDetails: (params) => void;
  replaceVirtualDeliveryMethod: (params) => void;
  validateAddress: (address) => void;
};

const onChange = curry(
  (state: UnilateralPaymentState, setState: (state: UnilateralPaymentState) => void, { id, value }: FieldType) => {
    setState({
      ...state,
      [id]: value,
    });
  }
);

const onSubmit = curry(
  async (
    props: UnilateralPaymentProps,
    state: UnilateralPaymentState,
    actions: DeliveryMethodActionsType,
    history: History,
    value: UnilateralDeliveryMethodType,
    isAddressVerified: boolean
  ) => {
    const { token } = props;
    const { deliveryMethodId } = getJWTPayload(token);
    const { payment, updatingDeliveryMethodId } = state;

    if (
      [DeliveryType.ACH, DeliveryType.VIRTUAL_CARD].includes(value.deliveryType as DeliveryType) ||
      isAddressVerified
    ) {
      if (updatingDeliveryMethodId || value.deliveryType === DeliveryType.VIRTUAL_CARD) {
        await actions.updateWithUnilateralToken(
          pickBy({
            token,
            deliveryMethod: value,
            paymentId: payment?.id || null,
            vendorId: payment?.vendorId,
            id: updatingDeliveryMethodId || value.id,
          })
        );
      } else {
        await actions.replaceVirtualDeliveryMethod(
          pickBy({
            token,
            deliveryMethod: {
              ...value,
              id: payment?.deliveryMethodId || deliveryMethodId,
            },
            paymentId: payment?.id || null,
          })
        );
      }

      history.push(generatePath(vendorLocations.unilateral.success, { token }));
    } else {
      const paperCheck = {
        ...value.paperCheck,
        deliveryType: DeliveryType.CHECK,
        deliveryMethodId: payment?.deliveryMethodId || deliveryMethodId,
      };
      await actions.validateAddress(paperCheck);
    }
  }
);

export type UnilateralDeliveryMethodType = {
  id?: string | null;
  deliveryType?: DeliveryType;
  paperCheck: CheckType;
  bankAccount: BankType;
};

export function useUnilateralPaymentState(
  props: UnilateralPaymentProps
): [UnilateralPaymentState, UnilateralPaymentActions, UnilateralDeliveryMethodType, EditableDeliveryMethodType] {
  const { token } = props;
  const { paymentId, vendorId, orgId, deliveryMethodId } = getJWTPayload(token);
  const history = useHistory();
  const paymentActions = useStoreActions(paymentsStore);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const [unilateralState, setState] = useState<State>({});
  const isUnilateralWithPayment = !vendorId && !orgId && !deliveryMethodId && paymentId;

  useEffect(() => {
    isUnilateralWithPayment &&
      paymentActions.fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' }).catch(() => {
        history.push(generatePath(vendorLocations.unilateral.invalidToken, { token }));
      });
  }, [isUnilateralWithPayment, paymentActions, token, history]);

  useEffect(() => {
    !isUnilateralWithPayment &&
      deliveryMethodActions
        .fetchUnilateralRequestDetails({
          token,
          action: 'unilateralRequest',
        })
        .catch(() => {
          history.push(generatePath(vendorLocations.unilateral.invalidToken, { token }));
        });
  }, [isUnilateralWithPayment, deliveryMethodActions, token, history]);

  const payment = useSelector(paymentsStore.selectors.byId(paymentId));

  useEffect(() => {
    if (payment?.status === PaymentStatus.COMPLETED) {
      history.push(generatePath(vendorLocations.unilateral.invalidToken, { token }));
    }
  }, [payment, history, token]);
  const deliveryMethod = useSelector(
    deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId || deliveryMethodId)
  );
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id || orgId));
  const { filesUrls } = useStructuredSelectors(paymentsStore.selectors.payment(payment?.id));
  const { invalidTokenData, loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation);
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor = useSelector(fetchSelector.byId(payment?.vendor?.id || vendorId));
  const vendorDeliveryMethods = vendor?.deliveryMethods;
  const updatingDeliveryMethodId = vendor?.deliveryMethods?.find(deliveryTypePredicate(unilateralState.deliveryType))
    ?.id;
  const isDeliveryMethodUpdating = useSelector(deliveryMethodsStore.selectors.update.status(updatingDeliveryMethodId))
    ?.loading;

  useEffect(() => {
    if (deliveryMethod && deliveryMethod.deliveryType !== DeliveryType.VIRTUAL) {
      history.push(generatePath(vendorLocations.unilateral.success, { token }));
    }
  }, [deliveryMethod, token, history]);

  const deliveryMethodModel = useMemo(() => {
    let bankAccountModel = {
      accountType: BankAccountType.CHECKING,
      routingNumber: '',
      accountNumber: '',
    };
    const achDeliveryMethod = vendorDeliveryMethods?.find(deliveryTypePredicate(DeliveryType.ACH));

    if (achDeliveryMethod?.bankAccount) {
      bankAccountModel = merge(bankAccountModel, pick(achDeliveryMethod?.bankAccount, keys(bankAccountModel)));
    }

    let paperCheckModel = {
      printName: '',
      addressLine1: '',
      addressLine2: '',
      state: '',
      city: '',
      zipCode: '',
    };
    const checkDeliveryMethod = vendorDeliveryMethods?.find(deliveryTypePredicate(DeliveryType.CHECK));

    if (checkDeliveryMethod?.paperCheck) {
      paperCheckModel = merge(paperCheckModel, pick(checkDeliveryMethod?.paperCheck, keys(paperCheckModel)));
    }

    return {
      deliveryType: unilateralState.deliveryType,
      bankAccount: bankAccountModel,
      paperCheck: paperCheckModel,
    };
  }, [unilateralState.deliveryType, vendorDeliveryMethods]);

  const enhancedState: UnilateralPaymentState = {
    ...unilateralState,
    payment,
    organization,
    filesUrls,
    isPaymentLoading,
    updatingDeliveryMethodId,
    isDeliveryMethodProcessing: isLoading || isDeliveryMethodUpdating,
  };

  useEffect(() => {
    if (!isEmpty(invalidTokenData)) {
      history.push(generatePath(vendorLocations.unilateral.invalidToken, { token }));
    }
  }, [invalidTokenData, token, history]);

  const actions = {
    onChange: onChange(enhancedState, setState),
    onSubmit: onSubmit(props, enhancedState, deliveryMethodActions, history),
  };

  return [enhancedState, actions, deliveryMethodModel, deliveryMethod];
}
