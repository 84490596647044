import { isValid } from 'date-fns';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import dateFromModelValue from 'src/components/modals/DownloadCsvReportModal/modules/dateFromModelValue';
import { ModelView } from 'src/ui/form';
import { AddressType } from 'src/utils/types';

export const datOfBirthMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

export const uboTaxIdMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const requiredFields = {
  firstName: `^.{1,255}`,
  lastName: `^.{1,255}`,
  dateOfBirth: `^.{10}`,
  taxId: `^.{11}`,
  address: `^.{1,255}`,
};

export const uboFormValidator = (fieldName: string, fieldValue: string | undefined) => {
  const commonFieldName = fieldName.slice(0, -1);

  if (isNil(fieldValue)) return `vendors.deliveryMethods.international.ubo.validation.${commonFieldName}`;

  if (commonFieldName === 'dateOfBirth') {
    const dateOfBirthValue = fieldValue.toString();

    if (
      (dateOfBirthValue?.length >= 1 && dateOfBirthValue?.length < 10) ||
      !isValid(dateFromModelValue(dateOfBirthValue))
    ) {
      return 'vendors.deliveryMethods.international.ubo.validation.errorDateOfBirth';
    }
  }

  if (commonFieldName === 'address') {
    const addressPartsCount = fieldValue?.split(',').length;

    if (addressPartsCount < 4) {
      return 'vendors.deliveryMethods.international.ubo.validation.errorAddress';
    }
  }

  const regex = new RegExp(requiredFields[commonFieldName]);

  if (!regex.test(fieldValue.toString())) {
    return `vendors.deliveryMethods.international.ubo.validation.${commonFieldName}`;
  }

  return undefined;
};

export type UBOModelType = {
  firstName: string;
  lastName: string;
  taxId: string;
  dateOfBirth: string;
  address: string;
  state: string;
  zipCode: string;
  city: string;
  country: string;
};

/**
 * @deprecated please do not use this - this type was only added to support existing code, but it's very hard to maintain
 * and understand the code.
 */
export type ObjectWithIndexSuffixedKeys<T extends Record<string, unknown>, TIndex extends number = number> = {
  [P in keyof T & string as `${P}${TIndex}`]: T[P];
};

export const getModelFieldsList = (index: number): ObjectWithIndexSuffixedKeys<UBOModelType> => ({
  [`firstName${index}`]: '',
  [`lastName${index}`]: '',
  [`dateOfBirth${index}`]: '',
  [`taxId${index}`]: '',
  [`address${index}`]: '',
  [`state${index}`]: '',
  [`city${index}`]: '',
  [`zipCode${index}`]: '',
});

export const updateAddressModel = (
  address: AddressType,
  model: ModelView<ObjectWithIndexSuffixedKeys<UBOModelType>>,
  index: number
) => {
  const { city, state, zipCode, formattedAddress } = address;

  model[`address${index}`].onChange({ value: formattedAddress || '' });
  model[`city${index}`].onChange({ value: city || '' });
  model[`state${index}`].onChange({ value: state || '' });
  model[`zipCode${index}`].onChange({ value: zipCode || '' });
};

export const getModelGroups = (
  uboMV: ModelView<ObjectWithIndexSuffixedKeys<UBOModelType>> | ObjectWithIndexSuffixedKeys<UBOModelType>
): UBOModelType[] => {
  const modelGroups = reduce(
    Object.keys(uboMV),
    (models, key) => {
      if (typeof uboMV[key] !== 'function') {
        const modelIndex = key.substr(-1);
        const modelValue = { [key]: uboMV[key] };

        models[modelIndex] = { ...models[modelIndex], ...modelValue };
      }

      return models;
    },
    {}
  );

  return Object.values(modelGroups);
};

const getModelValue = (
  result,
  model: ObjectWithIndexSuffixedKeys<UBOModelType>,
  formIndex: number,
  fieldName
): ObjectWithIndexSuffixedKeys<UBOModelType> => {
  const currentIndex = Object.keys(model)[0].substr(-1);

  result[`firstName${formIndex}`] = model[`firstName${currentIndex}`][fieldName];
  result[`lastName${formIndex}`] = model[`lastName${currentIndex}`][fieldName];
  result[`dateOfBirth${formIndex}`] = model[`dateOfBirth${currentIndex}`][fieldName];
  result[`address${formIndex}`] = model[`address${currentIndex}`][fieldName];
  result[`state${formIndex}`] = model[`state${currentIndex}`][fieldName];
  result[`zipCode${formIndex}`] = model[`zipCode${currentIndex}`][fieldName];
  result[`city${formIndex}`] = model[`city${currentIndex}`][fieldName];
  result[`taxId${formIndex}`] = model[`taxId${currentIndex}`][fieldName];

  return result;
};

export const getUpdatedModelAndErrors = (models: readonly UBOModelType[], indexForm: string) => {
  const updatedModel = Object.values(models).filter((model) => indexForm !== Object.keys(model)[0].substr(-1));

  const newModel = updatedModel.reduce(
    (result, model, index) => getModelValue(result, model, index + 1, 'value'),
    {} as ObjectWithIndexSuffixedKeys<UBOModelType>
  );

  const errors = reduce(
    updatedModel,
    (result, model, index) =>
      flow(
        () => getModelValue(result, model, index + 1, 'error'),
        Object.entries,
        (arr) => arr.filter(([, value]) => !!value),
        Object.fromEntries
      )(),
    {}
  );

  return { newModel, errors };
};

export const getUBOFormsErrors = (model: ModelView<ObjectWithIndexSuffixedKeys<UBOModelType>>) => {
  const formsErrors = Object.keys(model).reduce((errors, key) => {
    const name = key as keyof UBOModelType;

    if (typeof model[name] === 'object') {
      const errorText = uboFormValidator(name, model[name].value);

      if (errorText) errors[name] = errorText;

      model.setValidationErrors(errors);
    }

    return errors;
  }, {} as Partial<Record<keyof UBOModelType, string>>);

  return isEmpty(formsErrors);
};
