import isNil from 'lodash/isNil';
import qs, { IStringifyOptions } from 'qs';
import { generatePath } from 'react-router-dom';

export enum SortDirection {
  DESC = 'desc',
  ASC = 'asc',
}

type QueryToEncodeType = Record<string, string | number | null | undefined>;
type QueryParamsType = { [paramName: string]: string | number | boolean | undefined };
type SearchParamsType = Record<string, string | number | null | undefined>;
type UrlObjectType = { search: string; pathname: string };

function constructFilters(filters?: QueryParamsType) {
  if (!filters) {
    return null;
  }

  return encodeURI(
    Object.entries(filters)
      .filter(([, value]) => !isNil(value))
      .reduce((acc, [field, value]) => `${acc}&${field}=${value}`, '')
  );
}

function shouldShowSinglePageView(query: { id?: string; singlePageView?: string }) {
  return query.id || query.singlePageView;
}

function convertStringToUrlObject(url: string | UrlObjectType): UrlObjectType {
  if (typeof url !== 'string') {
    return url;
  }

  const parsedUrl = {
    search: '',
    pathname: url,
  };
  const index = url.indexOf('?');

  if (index !== -1) {
    parsedUrl.search = url.substring(index, url.length);
    parsedUrl.pathname = url.substring(0, index);
  }

  return parsedUrl;
}

const LEADING_QUESTION_MARK_REGEX = /[^?](\S)*/g;

// decoder is rewritten to support preserve + sign in emails that we pass as query param
// I've just removed plus sign replacement on empty string from native
// lib implementation https://github.com/ljharb/qs/blob/master/lib/utils.js#L108-L120
function parseQueryString(locationSearch: string): { [key: string]: any } {
  const [queryString] = locationSearch.match(LEADING_QUESTION_MARK_REGEX) || [];

  return qs.parse(queryString, {
    decoder: (str, decoder, charset): string => {
      if (charset === 'iso-8859-1') {
        // unescape never throws, no try...catch needed:
        return str.replace(/%[0-9a-f]{2}/gi, unescape);
      }

      // utf-8
      try {
        return decodeURIComponent(str);
      } catch (e) {
        return str;
      }
    },
  });
}

/**
 * deprecated, please use stringifyQs instead.
 */
function encodeQuery(query: QueryToEncodeType, excludeFields: string[] = [], baseSearch = ''): string {
  return encodeURI(
    Object.keys(query)
      .filter((filterKey) => !excludeFields.some((field) => field === filterKey))
      .reduce((result, filterKey) => {
        if (query[filterKey] === undefined) {
          return result;
        }

        const filterSearchParam = `${filterKey}=${query[filterKey]}`;

        return result ? `${result}&${filterSearchParam}` : filterSearchParam;
      }, baseSearch)
  );
}

function removeQueryString(url: string): string {
  return url?.split('?')[0];
}

function getContextFromURI(): string {
  const uri = new URL(window.location.href);
  const { host } = uri;
  const siteConfigParam = uri.searchParams.get('siteConfig');

  if (siteConfigParam) return siteConfigParam;

  if (host.substring(0, 7) === 'intuit-') return 'qbo';

  if (process.env.REACT_APP_SITE) return process.env.REACT_APP_SITE;

  return 'melio';
}

function convertToRecordString(rec: Record<string, string | number | null | undefined>): Record<string, string> {
  const stringRec: Record<string, string> = {};

  Object.keys(rec).forEach((a) => {
    if (rec[a] !== null && rec[a] !== undefined) {
      stringRec[a] = String(rec[a]);
    }
  });

  return stringRec;
}

function generatePathWithSearch(path: string, params: QueryParamsType, searchParams: SearchParamsType): string {
  const urlSearchParams = new URLSearchParams(convertToRecordString(searchParams));

  return `${generatePath(path, params)}?${urlSearchParams.toString()}`;
}

export function parseSortingQueryString(sortingQueryString = ''): [string, SortDirection | undefined] {
  const [sorting, direction] = sortingQueryString.split(':');

  return [sorting, direction as SortDirection];
}

const stringifyQs: typeof qs.stringify = (obj: any, options?: IStringifyOptions) =>
  qs.stringify(obj, {
    encodeValuesOnly: true,
    ...options,
  });

export {
  constructFilters,
  convertStringToUrlObject,
  encodeQuery,
  generatePathWithSearch,
  getContextFromURI,
  parseQueryString,
  removeQueryString,
  shouldShowSinglePageView,
  stringifyQs,
};
