import head from 'lodash/head';
import { createQueryDefaultSortBy } from 'src/pages/get-pro/utils';
import { GetProTabs, PAGINATION } from 'src/utils/consts';
import { encodeQuery } from 'src/utils/query-utils';
import { getDefaultTabSetting } from 'src/utils/tabs-utils';
import { TabSettingsType } from 'src/utils/types';
import { DATE_FILTERS, PAGE_ITEM_COUNT, PaidDateFilters, TABS } from '../../consts';

const TAB_TYPE = 'getPro';

export const getSelectedTab = (query: Record<string, any>) =>
  TABS.includes(query.status) ? query.status : GetProTabs.INVOICES;

export const getTabs = ({ filter, sort, ...rest }: Record<string, any>): Array<TabSettingsType> =>
  TABS.map((tab) => {
    const initialSort = createQueryDefaultSortBy(tab);

    return getDefaultTabSetting({
      tab,
      type: TAB_TYPE,
      isActive: getSelectedTab(rest) === tab,
      baseQueryParams: encodeQuery(
        {
          ...rest,
          start: PAGINATION.DEFAULT_START,
          limit: PAGE_ITEM_COUNT,
          sort: initialSort,
        },
        ['status', 'id', 'ids'],
        ''
      ),
    });
  });

export const getSelectedDateFilter = (status, filter, defaultValueForDateFilter = false) => {
  const filters = Object.values(DATE_FILTERS[status]);

  if (defaultValueForDateFilter && status === GetProTabs.PAID && !filter) {
    return filters.find((filter) => filter === PaidDateFilters.LAST_7_DAYS);
  }

  return filters.includes(filter) ? filter : head(filters);
};

export const getDefaultDateFilterByTab = (status) => getSelectedDateFilter(status, '');
