import get from 'lodash/get';
import config from 'src/config';

export enum ClientIdType {
  NEW = 'new',
}

export type UseNewTabapayUrlReturnType = {
  iframeSrc: string;
  clientIdType: ClientIdType;
  shouldSkipReceivedMessage: (event) => boolean;
};

export const useNewTabapayUrl = (): UseNewTabapayUrlReturnType => {
  const iframeSrc = config.services.tabapay.url;
  const clientIdType = ClientIdType.NEW;

  const shouldSkipReceivedMessage = (event) => {
    const eventOrigin = get(event, 'origin', '');
    const eventData = get(event, 'data', undefined);
    const isOriginMatchIframeSrc = iframeSrc.startsWith(eventOrigin);
    const isEventDataTypeString = typeof eventData === 'string';

    return !isOriginMatchIframeSrc || !isEventDataTypeString;
  };

  return {
    iframeSrc,
    clientIdType,
    shouldSkipReceivedMessage,
  };
};
