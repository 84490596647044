import { MouseEvent, useState } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OnInputChange, Option } from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Tooltip from 'src/core/ds/tooltip';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import { BillDetailsForm } from 'src/pages/bill/components/BillDetailsForm';
import { devices } from 'src/theme/appDevices';
import { FormSubtitle } from 'src/ui/form/FormElements';
import { BillFrequency, BillPageType } from 'src/utils/consts';
import type { BillType, FieldType } from 'src/utils/types';

type Props = {
  occurrences: string;
  frequency: BillFrequency;
  isUploading: boolean;
  filteredVendors?: Array<Option>;
  onVendorsInputChange?: OnInputChange;
  vendorAccountIdentifier?: string;
  onChange: (field: FieldType) => void;
  bill: BillType;
  onFieldChange: (field: FieldType) => void;
  isRecurring: boolean;
  validationErrors: Record<string, any>;
  onSubmitBill: (shouldReturnToBillsDashboard: boolean) => void;
  isProcessing: boolean;
  manually?: boolean;
  onDeleteAttachment: () => void;
  fileName: string;
  isUploadError: boolean;
  handleRetry: () => void;
  onChangeAttachment: (file: File, loadBillFromAttachment?: boolean) => void;
};

export const NewBillPageForm = ({
  onSubmitBill,
  onChangeAttachment,
  isUploading,
  filteredVendors,
  onVendorsInputChange,
  onFieldChange,
  bill,
  manually,
  isRecurring,
  validationErrors,
  isProcessing,
  vendorAccountIdentifier,
  onChange,
  occurrences,
  frequency,
  handleRetry,
  onDeleteAttachment,
  fileName,
  isUploadError,
}: Props) => {
  const [isSaveAndCloseClicked, setIsSaveAndCloseClicked] = useState<boolean>(false);
  const { isInternationalVendor, isInternationalAllowed } = useIsInternationalAllowed(bill?.vendorId);
  const isCreateDisabled = isInternationalVendor && !isInternationalAllowed;

  const handleSubmit = async (event: MouseEvent) => {
    setIsSaveAndCloseClicked(false);
    event.preventDefault();
    await onSubmitBill(false);
  };

  const handleSubmitAndClose = async (event: MouseEvent) => {
    setIsSaveAndCloseClicked(true);
    event.preventDefault();
    await onSubmitBill(true);
  };

  const handleSelectFile = async (file: File) => {
    await onChangeAttachment(file, false);
  };

  const defaultVendorId = bill.vendorId ? bill.vendorId.toString() : null;
  const isAutofocus = !!defaultVendorId;
  const dueDate = bill.dueDate ? new Date(bill.dueDate) : null;

  return (
    <BillDetailsContainer manually={manually} data-testid="new-bill-form">
      <FormWrapper>
        <FormSubtitle>
          <FormSubtitleWrapper>
            <MIFormattedText label="bills.view.title" />
          </FormSubtitleWrapper>
        </FormSubtitle>
        <BillDetailsForm
          isDisabled={isUploading}
          isAutofocus={isAutofocus}
          filteredVendors={filteredVendors}
          onVendorsInputChange={onVendorsInputChange}
          onFieldChange={onFieldChange}
          onChange={onChange}
          occurrences={occurrences}
          frequency={frequency}
          isRecurringBill={isRecurring}
          vendorId={defaultVendorId}
          totalAmount={bill.totalAmount}
          invoiceNumber={bill.invoiceNumber}
          dueDate={dueDate}
          note={bill.note}
          validationErrors={validationErrors}
          vendorAccountIdentifier={vendorAccountIdentifier}
          billPageType={BillPageType.CREATE}
          intuitAccountId={bill.intuitAccountId}
          onChangeInvoice={handleSelectFile}
          onDeleteAttachment={onDeleteAttachment}
          fileName={fileName}
          isUploadError={isUploadError}
          isUploading={isUploading}
          handleSelectFile={handleSelectFile}
          handleRetry={handleRetry}
          manually={Boolean(manually)}
        />
      </FormWrapper>
      <ButtonsContainer>
        <Tooltip
          data-testid="save-and-add-disabled-tooltip"
          isDisabled={!isCreateDisabled}
          placement="top"
          maxW="max"
          label={
            <Box textAlign="start" px={2} py={1}>
              <MIFormattedText label="bills.new.internationalDisabledTooltip" />
            </Box>
          }
        >
          <Box>
            <Button
              onClick={handleSubmit}
              label="bills.new.saveAndAdd"
              size={ButtonSizes.lg}
              isFullWidth
              isDisabled={isUploading || isCreateDisabled}
              isLoading={isProcessing && !isSaveAndCloseClicked}
            />
          </Box>
        </Tooltip>
        {!isRecurring && (
          <Tooltip
            data-testid="save-and-close-disabled-tooltip"
            isDisabled={!isCreateDisabled}
            placement="top"
            maxW="max"
            label={
              <Box textAlign="start" px={2} py={1}>
                <MIFormattedText label="bills.new.internationalDisabledTooltip" />
              </Box>
            }
          >
            <Box>
              <Button
                onClick={handleSubmitAndClose}
                label="bills.new.saveAndClose"
                variant={ButtonVariants.secondary}
                size={ButtonSizes.lg}
                isFullWidth
                isDisabled={isCreateDisabled}
                isLoading={isProcessing && isSaveAndCloseClicked}
                mt={3}
              />
            </Box>
          </Tooltip>
        )}
      </ButtonsContainer>
    </BillDetailsContainer>
  );
};

const BillDetailsContainer = styled.form`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin: 0 auto;
  padding: 0 6rem 64px;
  max-width: ${(props) => (props.manually ? '45rem' : '40rem')};

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2rem 0 10rem;
  }

  ${(props) => props.theme?.pages?.NewBillPage?.BillDetailsContainer};
`;

const ButtonsContainer = styled.div`
  text-align: center;
  margin: 1rem 0 0;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.colors.white.opaque};
    margin: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    box-shadow: 0 -0.4rem 1.5rem 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
  }
  ${(props) => props.theme?.pages?.NewBillPage?.NewBillButtonsContainer};
`;

const FormWrapper = styled.div`
  @media ${devices.mobile}, ${devices.phablet} {
    position: relative;
  }
`;

const FormSubtitleWrapper = styled.div`
  ${(props) => props.theme?.pages?.NewBillPage?.FormSubtitleWrapper};
`;
