import { useEffect } from 'react';
import { DrawerBody, DrawerCloseButton, DrawerHeader } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { InvoiceType } from 'src/modules/invoices/types';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { CardHeader } from 'src/pages/get-pro/components/cards/CardHeader';
import useCardActionsAndState from 'src/pages/get-pro/components/cards/hooks/useCardActionsAndState';
import { PaymentCard } from 'src/pages/get-pro/components/cards/payment/PaymentCard';
import { SyncedItemIcon } from 'src/pages/get-pro/components/drawer/components/SyncedItemIcon';
import { DrawerTabs } from 'src/pages/get-pro/components/drawer/components/tabs/DrawerTabs';
import { useDrawerTabs } from 'src/pages/get-pro/components/drawer/components/tabs/hooks/useDrawerTabs';
import { TimelineDrawer } from 'src/pages/get-pro/components/drawer/components/TimelineDrawer';
import { EventEntity } from 'src/pages/get-pro/consts';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { analytics } from 'src/services/analytics';
import { GetProTabs } from 'src/utils/consts';

type Props = {
  invoice: InvoiceType;
  onHeaderClick?: () => void;
  onClose: () => void;
  editMode?: boolean;
  eventPage?: string;
};

export const PaidDrawer = ({ invoice, onClose, onHeaderClick, editMode, eventPage }: Props) => {
  const store = MAP_STORE_BY_TAB[GetProTabs.PAID];
  const { listParams } = useGetProListParams();
  const actions = useStoreActions(store);
  const isMSNDashboardDisplay = useIsMsnDashboardDisplay();
  const accountingSoftwareName = useGetAccountingSoftwareName(invoice?.createOrigin);

  useEffect(() => {
    if (eventPage) {
      analytics.track(eventPage, 'sidebar-expose', { paymentId: invoice.id, paymentStatus: invoice.status });
    }
    // useEffect should be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const remove = async () => {
    await actions.proDelete({
      orgId: listParams.orgId,
      id: invoice?.id,
    });
    onClose();
  };

  const {
    deleteFile,
    onEditToggle,
    markAsUnpaid,
    openPdfPreview,
    vendorHandle,
    customers,
    uploadFile,
    fileUrls,
    uploading,
    invoiceMV,
    loadingFileUrls,
    loading,
    inEditMode,
    isMarkedAsPaid,
  } = useCardActionsAndState({
    invoice,
    editMode,
    tab: GetProTabs.PAID,
  });

  const onMarkInvoiceAsUnpaid = async () => {
    onClose();
    await markAsUnpaid();
  };

  const getEntityId = (entityId) => {
    if (entityType === EventEntity.PAYMENT && invoice.payments?.length) {
      const [latestPayment] = invoice.payments;

      return String(latestPayment.id);
    }

    return entityId;
  };

  const { tabs, activeTab, onTabClick } = useDrawerTabs(GetProTabs.PAID);
  const isTimelineActiveTab = activeTab === tabs.timeline.name;

  // In paid drawer invoice.id can be: 'xxx-payment' or 'xxx-invoice';
  const [entityInvoiceId, entityInvoiceType] = invoice.id.split('-');
  const entityType = entityInvoiceType === 'invoice' ? EventEntity.PAYMENT_REQUEST : EventEntity.PAYMENT;
  const entityId = getEntityId(entityInvoiceId);

  return (
    <>
      <DrawerHeader flexDirection="column" minH="auto" {...(!isMSNDashboardDisplay && { pb: 0 })}>
        <Flex justify="space-between" w="full">
          <CardHeader
            companyName={invoice?.customer?.companyName || invoice?.customer?.contactName || ''}
            description={invoice?.invoiceNumber ? `#${invoice?.invoiceNumber}` : ''}
            Icon={
              accountingSoftwareName ? (
                <SyncedItemIcon placement="bottom" accountingSoftwareName={accountingSoftwareName} />
              ) : undefined
            }
            onClick={onHeaderClick}
          />
          <DrawerCloseButton size="md" />
        </Flex>
        {!isMSNDashboardDisplay && <DrawerTabs tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />}
      </DrawerHeader>
      <DrawerBody pb={10} pt={activeTab === tabs.timeline.name ? 4 : 10}>
        {activeTab === tabs.details.name && (
          <PaymentCard
            invoiceMV={invoiceMV}
            invoice={invoice}
            vendorHandle={vendorHandle}
            inEditMode={inEditMode}
            uploading={uploading}
            loading={loading}
            loadingFileUrls={loadingFileUrls}
            fileUrls={fileUrls}
            customers={customers}
            remove={remove}
            onEditToggle={onEditToggle}
            onMarkInvoiceAsPaid={onMarkInvoiceAsUnpaid}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            isMarkedAsPaid={isMarkedAsPaid}
            openPdfPreview={openPdfPreview}
            isMSNPortal={isMSNDashboardDisplay}
          />
        )}
        {isTimelineActiveTab && <TimelineDrawer entity={invoice} entityType={entityType} entityId={entityId} />}
      </DrawerBody>
    </>
  );
};
