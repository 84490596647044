import noop from 'lodash/noop';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getCompanyInfoLoadingStatus } from 'src/redux/user/selectors';

export const useFetchCompanySettings = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getCompanyInfoLoadingStatus);
  const companyInfo = useSelector(getCompanyInfo);
  const fetch = useCallback(async () => dispatch(loadCompanyInfoAction(noop, noop)), [dispatch, loadCompanyInfoAction]);

  return { isLoading, companyInfo, fetch };
};
