import { useSelector } from 'react-redux';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { PaymentType, VirtualCardType } from 'src/utils/types';
import { getTitleAndSubtitle } from '../steps/info/utils';
import { CanceledVirtualCardPayment } from './CanceledVirtualCardPayment';
import { convertedPaymentUtils } from './convertedPaymentUtils';
import { ValidVirtualCardPayment } from './ValidVirtualCardPayment';

type ConvertedPaymentActivityProps = {
  payment: PaymentType;
  virtualCardState: { virtualCard: VirtualCardType };
};

export const ConvertedPaymentActivity = ({ virtualCardState, payment }: ConvertedPaymentActivityProps) => {
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));
  const deliveryMethodRecord = deliveryMethodFactory(deliveryMethod);
  const paymentUtils = convertedPaymentUtils(payment, virtualCardState);
  const { paymentStates } = paymentUtils;
  const { title, subtitle } = getTitleAndSubtitle(
    paymentStates.isPaymentVirtualCardCleared,
    paymentStates.isPaymentCompleted,
    paymentStates.isVirtualCardCanceled
  );

  const additionalDescription = paymentUtils.getAdditionalDescription();

  const blockLabel = paymentUtils.getBlockLabel();
  const paymentSource = paymentUtils.getPaymentSource();
  const paymentDetails = paymentUtils.getPaymentDetails();
  const dateTitle = paymentUtils.getDateTitle();
  const dateToDisplayInBlockTitle = paymentUtils.getDateToDisplayInBlockTitle();
  const deliveryDate = paymentUtils.getDeliveryDate();

  const paymentSourceIcon = paymentUtils.getPaymentSourceIcon();
  const deliveryIcon = paymentUtils.getDeliveryIcon();
  const blockIcon = paymentUtils.getBlockIcon();

  return paymentStates.isVirtualCardCanceled ? (
    <CanceledVirtualCardPayment virtualCardPaymentData={{ blockIcon, title, subtitle, payment }} />
  ) : (
    <ValidVirtualCardPayment
      virtualCardPaymentData={{
        blockIcon,
        paymentSourceIcon,
        deliveryIcon,
        title,
        subtitle,
        additionalDescription,
        payment,
        deliveryMethod,
        blockLabel,
        paymentSource,
        paymentDetails,
        dateTitle,
        dateToDisplayInBlockTitle,
        deliveryDate,
        deliveryMethodRecord,
        virtualCardState,
      }}
    />
  );
};
