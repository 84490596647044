import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { AmountCurrencyField } from 'src/core/ds/form/fields';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useFetchApprovalWorkflows } from 'src/hooks/accountants/approval-workflows/useFetchApprovalWorkflows';
import { useReplaceApprovalThreshold } from 'src/hooks/accountants/approval-workflows/useReplaceApprovalThreshold';
import { UserOrgType } from 'src/modules/invitations/invitations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { UserOrganizationFormType } from 'src/pages/auth/RegisterSetAmount/RegisterSetAmountContainer';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { InlineCheckboxField } from 'src/ui/form/InlineCheckboxField';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  onNext: (user: any, hasFundingSource?: boolean) => void;
};

export const AcceptRequestSetAmount = ({ onNext }: Props) => {
  const [invitingUser] = useLocationState<UserOrgType>('invitingUser');
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const [skipSetAmount, setSkipAmount] = useState<boolean>(false);
  const userFundingSources = useSelector(paymentsStore.selectors.getUserFundingSources);
  const { approvalWorkflowsLoading } = useFetchApprovalWorkflows(orgId);
  const { userOrg, isUpdating } = useStructuredSelectors(
    usersStore.selectors.userOrganization(invitingUser?.id, orgId)
  );
  const { replaceApprovalThreshold } = useReplaceApprovalThreshold({
    orgId,
    skipSetAmount,
    userId: userOrg?.userId,
    replace: false,
  });

  const [userOrgMV, { submit }] = useForm<UserOrganizationFormType>(userOrg, {
    submit: async (value) => {
      await replaceApprovalThreshold(value);

      onNext(invitingUser, userFundingSources?.length > 0);
    },
  });

  const isLoading = approvalWorkflowsLoading || !orgId || !userOrg || !invitingUser;

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <StepLayoutPage
      relativeStep={1 / 2}
      isLoading={isUpdating}
      nextLabel="companies.accountantRequest.setAmount.btnText"
      onNext={submit}
      title="companies.accountantRequest.setAmount.title"
      subtitle="companies.accountantRequest.setAmount.subtitle"
      titleValues={{
        name: (
          <Title>
            {invitingUser?.firstName} {invitingUser?.lastName}
            <br />
          </Title>
        ),
      }}
    >
      <FormRow>
        <AmountCurrencyField
          label="companies.accountantRequest.setAmount.enterAmountLabel"
          model={userOrgMV.approvalAmountThreshold}
          id="accept-request-set-amount"
          placeholder="companies.accountantRequest.setAmount.placeholder"
          isDisabled={skipSetAmount}
          isRequired
        />
      </FormRow>
      <FormRow>
        <Comment>
          <MIFormattedText label="companies.accountantRequest.setAmount.comment" />
        </Comment>
      </FormRow>
      <FormRow>
        <Checkbox>
          <InlineCheckboxField
            label="companies.accountantRequest.setAmount.checkboxText"
            value={skipSetAmount}
            onChange={({ value }) => setSkipAmount(value)}
          />
        </Checkbox>
      </FormRow>
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const Checkbox = styled.span`
  margin-top: 5rem;
  margin-bottom: 2.5rem;
`;

const Comment = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.medium};
`;
