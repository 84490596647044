import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { getOrgId } from 'src/redux/user/selectors';
import { OrgPreferencesTypeKeys } from 'src/utils/consts';

export const useOrgDefaultFundingSource = (): {
  orgDefaultFundingSourceId: number | null;
  setOrgDefaultFundingSource: (newDefaultFundingSourceId: number) => void;
} => {
  const orgId = useSelector(getOrgId);
  const organizationPreferences = useOrganizationPreferences();
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();

  const defaultFundingSourceIdFromPreferences = organizationPreferences?.defaultFundingSourceId
    ? Number(organizationPreferences.defaultFundingSourceId)
    : null;

  const [defaultFundingSourceId, setDefaultFundingSourceId] = useState(defaultFundingSourceIdFromPreferences);

  const onDefaultFundingSourceIdChange = async (newDefaultFundingSourceId: number) => {
    setDefaultFundingSourceId(newDefaultFundingSourceId);
    await updateOrganizationPreference({
      orgId,
      key: OrgPreferencesTypeKeys.defaultFundingSourceId,
      value: newDefaultFundingSourceId.toString(),
    });
  };

  return {
    orgDefaultFundingSourceId: defaultFundingSourceId,
    setOrgDefaultFundingSource: onDefaultFundingSourceIdChange,
  };
};
