import { useCallback } from 'react';
import { DownloadCsvReportModal } from 'src/components/shared/modals/DownloadCsvReportModal';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames } from 'src/core/ds/icon';
import { useModal } from 'src/helpers/react/useModal';
import { analytics } from 'src/services/analytics';
import { EVENT_PAGE } from '../consts';

export const ExportCSVButton = () => {
  const [modal, openModal] = useModal(DownloadCsvReportModal, {
    eventPage: EVENT_PAGE,
    modalName: `msnPaymentsInExportPaymentReportButtonModal`,
  });

  const handleOpen = useCallback(() => {
    analytics.track(EVENT_PAGE, 'export-csv-click');
    openModal();
  }, [openModal]);

  return (
    <>
      <Button
        variant={ButtonVariants.tertiary}
        label="incomingPayments.exportCSVFile.label"
        testId="incoming-payments-export-csv-file-button"
        leftIcon={<Icon name={IconNames.fileMove} />}
        onClick={handleOpen}
      />
      {modal}
    </>
  );
};
