import omit from 'lodash/omit';
import set from 'lodash/set';
import { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { Form } from 'src/core/ds/form';
import {
  financingAnalyticsEvents,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { Agreement } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/Agreement';
import { BeneficialOwnerSubForm } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/beneficial-owners/BeneficialOwnerSubForm';
import { useBeneficialOwners } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/beneficial-owners/useBeneficialOwners';
import { MainOwnerSubForm } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/main-owner/MainOwnerSubForm';
import { validator } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/validator';
import {
  BeneficialOwner,
  BusinessOwnershipDetailsModel,
  ConstructedBeneficialOwner,
  InternalBusinessOwnershipDetailsModel,
} from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';

type SimplifiedBeneficialOwner = {
  beneficialOwner: { [key: string]: BeneficialOwner };
};
const extractBeneficialOwnersFromDynamicConstructedForm = (constructedBeneficialOwner: ConstructedBeneficialOwner) => {
  const { beneficialOwner } = Object.entries(constructedBeneficialOwner).reduce<SimplifiedBeneficialOwner>(
    (acc, [key, value]) => set(acc, key, value),
    {} as SimplifiedBeneficialOwner
  );

  return beneficialOwner ? Object.values(beneficialOwner) : [];
};

const convertInternalModelToSimplifiedExternalModel = ({
  firstName,
  lastName,
  email,
  ownershipPercentage,
  dateOfBirth,
  ssn,
  phone,
  addressLine1,
  city,
  state,
  zipCode,
  ...rest
}: InternalBusinessOwnershipDetailsModel): BusinessOwnershipDetailsModel => ({
  owner: {
    firstName,
    lastName,
    email,
    ownershipPercentage,
    dateOfBirth,
    ssn,
    phone,
    addressLine1,
    city,
    state,
    zipCode,
  },
  beneficialOwners: [...extractBeneficialOwnersFromDynamicConstructedForm({ ...rest })],
});

const initialOwnershipDetailsModel: InternalBusinessOwnershipDetailsModel = {
  firstName: '',
  lastName: '',
  ownershipPercentage: '',
  email: '',
  dateOfBirth: '',
  ssn: '',
  phone: '',
  addressLine1: '',
  city: '',
  state: '',
  zipCode: '',
};

type Props = {
  onSubmit(ownershipDetailsModel: BusinessOwnershipDetailsModel): void;
  payBillFlowUUID?: string;
};

export const BusinessOwnershipDetailsForm = ({ onSubmit, payBillFlowUUID }: Props) => {
  const [ownersCount, setOwnersCount] = useState(1);
  const [model, setModel] = useState<InternalBusinessOwnershipDetailsModel>(initialOwnershipDetailsModel);
  const {
    beneficialOwnersUUIDs,
    getBeneficialOwnerModelToAdd,
    getBeneficialOwnerModelFieldsToRemove,
  } = useBeneficialOwners();

  const [
    businessOwnershipDetailsModelView,
    { submit },
    validationErrors,
  ] = useForm<InternalBusinessOwnershipDetailsModel>(model, {
    submit: async (model: InternalBusinessOwnershipDetailsModel) => {
      analytics.track(financingPage.ownershipDetails, financingAnalyticsEvents.checkMyRateClick, {
        numOfOwners: ownersCount,
      });
      const externalModel = convertInternalModelToSimplifiedExternalModel(model);
      onSubmit(externalModel);
    },
    validateOnChange: false,
    validator,
    onChange: ({ modelState }) => {
      setModel(modelState);

      return modelState;
    },
  });

  const handleAddAnotherOwner = () => {
    setModel((model) => ({
      ...model,
      ...getBeneficialOwnerModelToAdd(),
    }));
    setOwnersCount((prevCount) => prevCount + 1);
  };

  const handleRemoveOwner = (uuid: string) => {
    const fieldsToRemove = getBeneficialOwnerModelFieldsToRemove(uuid);
    setModel((prevModel) => omit(prevModel, fieldsToRemove) as InternalBusinessOwnershipDetailsModel);
    setOwnersCount((prevCount) => prevCount - 1);
  };

  const handlePrivacyPolicyClick = () => {
    analytics.trackAction(financingAnalyticsEvents.privacyPolicyClick, payBillFlowUUID ? { payBillFlowUUID } : {});
  };

  const handleTermsOfServiceClick = () => {
    analytics.trackAction(financingAnalyticsEvents.termsOfServiceClick, payBillFlowUUID ? { payBillFlowUUID } : {});
  };

  const showAddAnotherOwner = ownersCount < 4;

  return (
    <Form onSubmit={submit}>
      <MainOwnerSubForm modelView={businessOwnershipDetailsModelView} validationErrors={validationErrors} />
      {beneficialOwnersUUIDs.map((uuid, index) => (
        <Box key={uuid}>
          <BeneficialOwnerSubForm
            modelView={businessOwnershipDetailsModelView}
            index={index}
            uuid={uuid}
            onRemoveBeneficialOwner={handleRemoveOwner}
            validationErrors={validationErrors}
          />
        </Box>
      ))}
      {showAddAnotherOwner ? (
        <Box
          textStyle="body3"
          color="primary.500"
          cursor="pointer"
          onClick={handleAddAnotherOwner}
          data-testid="add-another-beneficialOwner"
        >
          <MIFormattedText label="financing.businessOwnershipDetails.applicant.addAnotherOwner" />
        </Box>
      ) : null}
      <Agreement
        onPrivacyPolicyClick={handlePrivacyPolicyClick}
        onTermsOfServiceClick={handleTermsOfServiceClick}
        payBillFlowUUID={payBillFlowUUID}
      />
      <Flex justifyContent="center" w="full">
        <Button
          testId="submit-form"
          size={ButtonSizes.lg}
          label="financing.businessOwnershipDetails.submit"
          type="submit"
        />
      </Flex>
    </Form>
  );
};
