import { TransactionResponse } from '@melio/spend-management-api-axios-client';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { transactionsApi } from '../api';
import { TransactionsListParams, UseTransactionOptions } from '../types';

export const useTransactions = ({ cardId, accountId }: UseTransactionOptions) => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: getTransactions, result, loading, error } = useApi<
    [TransactionsListParams],
    TransactionResponse[]
  >({
    api: transactionsApi.list,
  });

  useEffect(() => {
    getTransactions({ orgId, cardId, accountId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    transactions: result,
    refetch: ({ cardId, accountId }: UseTransactionOptions) => getTransactions({ orgId, cardId, accountId }),
  };
};
