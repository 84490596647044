import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex/Flex';

type Props = {
  label: string;
};

export const FieldErrorMessage = ({ label }: Props) => (
  <Flex alignItems="center" color="red.500" marginTop={1} textStyle="body3" display="block" position="absolute">
    <MIFormattedText label={label} />
  </Flex>
);
