import compact from 'lodash/compact';
import { useLayoutEffect } from 'react';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { useModal } from 'src/helpers/react/useModal';
import { Role } from 'src/utils/consts';
import { ApprovalWorkflowType, CompanyInfoType } from 'src/utils/types';
import { EditApprovalWorkflowModal } from '../../ManageUsersSettingsPage/modals/EditApprovalWorkflowModal';
import { ApprovalWorkflowCard } from './ApprovalWorkflowCard/ApprovalWorkflowCard';
import { ApprovalWorkflowUserType, PaymentCreatorOptionType } from './ApprovalWorkflowModal/types';
import { DeleteApprovalWorkflowModal } from './DeleteWorkflowModal';
import { isWorkflowActionsAllowed } from './utils';

type Props = {
  orgId: number;
  companyName: CompanyInfoType['companyName'];
  workflow: ApprovalWorkflowType;
  allPaymentCreators: PaymentCreatorOptionType[];
  userId: number;
  role: Role;
  orgUsers: readonly ApprovalWorkflowUserType[];
  onDelete: (id: number) => void;
  onUpdate: (workflow: ApprovalWorkflowType) => void;
  onModalShow: () => void;
  onModalClose: () => void;
  maxThreshold?: number;
};
export function ApprovalWorkflowCardContainer({
  workflow,
  allPaymentCreators,
  role,
  userId,
  orgId,
  companyName,
  orgUsers,
  onDelete,
  onUpdate,
  onModalShow,
  onModalClose,
  maxThreshold,
}: Props) {
  const cardPaymentCreators = compact(
    workflow.paymentCreatorIds?.map((creatorId) => allPaymentCreators.find(({ id }) => creatorId === id))
  );
  const isActionsAllowed = isWorkflowActionsAllowed(userId, workflow, role);

  const handleDelete = () => {
    onDelete(workflow.id);
  };

  const [DeleteModal, showDeleteModal, isDeleteModalShowing] = useModal(DeleteApprovalWorkflowModal, {
    onSubmit: handleDelete,
    companyName,
    orgId,
    modalName: 'deleteApprovalWorkflowModal',
  });

  const [EditModal, showEditModal, isEditModalShowing] = useModal(EditApprovalWorkflowModal, {
    onSubmit: onUpdate,
    companyName,
    orgId,
    orgUsers,
    dbWorkflow: workflow,
    modalName: 'editApprovalWorkflowModal',
    maxThreshold,
  });

  const handleModalShowStateChange = () => {
    if (isDeleteModalShowing || isEditModalShowing) {
      onModalShow();
    } else {
      onModalClose();
    }
  };

  useLayoutEffect(handleModalShowStateChange, [isDeleteModalShowing, isEditModalShowing]);

  return (
    <>
      {EditModal}
      {DeleteModal}
      <ApprovalWorkflowCard
        creatorsGroup={workflow.creatorsGroup}
        paymentCreators={cardPaymentCreators}
        threshold={workflow.threshold}
        approvalDecisionsCount={workflow.approvalDecisionsCount}
        data-testid={`approval-workflow-card-${workflow.id}`}
        renderRight={
          isActionsAllowed ? (
            <Menu isLazy>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<Icon name={IconNames.more} />}
                variant="action"
                placement="top"
                data-testid="dots-actions-menu"
              />
              <MenuList>
                <MenuItem label="settings.approvalWorkflows.card.menu.edit" onClick={showEditModal} />
                <MenuItem
                  label="settings.approvalWorkflows.card.menu.delete"
                  onClick={showDeleteModal}
                  color="red.500"
                />
              </MenuList>
            </Menu>
          ) : null
        }
      />
    </>
  );
}
