import {
  FINANCING_CATEGORY,
  financingAnalyticsEvents,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { EventMappingsTree } from 'src/services/analytics/types';

export const financingEventMapping: EventMappingsTree = {
  '/orgs/*/bills/*/pay/installments/application/business-details': {
    'page.view': [FINANCING_CATEGORY, financingPage.businessDetails],
    'form-validation-error': [
      financingPage.businessDetails,
      financingAnalyticsEvents.formValidationError,
      { validationsErrors: 'validationErrors' },
    ],
    'privacy-policy-click': [
      financingPage.businessDetails,
      financingAnalyticsEvents.privacyPolicyClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
    'terms-of-service-click': [
      financingPage.businessDetails,
      financingAnalyticsEvents.termsOfServiceClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
  },
  '/orgs/*/bills/*/pay/installments/application/business-ownership-details': {
    'page.view': [FINANCING_CATEGORY, financingPage.businessOwnership],
    'form-validation-error': [
      financingPage.businessOwnership,
      financingAnalyticsEvents.formValidationError,
      { validationsErrors: 'validationErrors' },
    ],
    'privacy-policy-click': [
      financingPage.businessOwnership,
      financingAnalyticsEvents.privacyPolicyClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
    'terms-of-service-click': [
      financingPage.businessOwnership,
      financingAnalyticsEvents.termsOfServiceClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
  },
  '/orgs/*/bills/*/pay/installments/application/owner-decision': {
    'dialogs.buttons.cancel': [
      financingPage.ownerDecision,
      financingAnalyticsEvents.proofOfOwnershipModalCancelClicked,
    ],
    'privacy-policy-click': [
      financingPage.ownerDecision,
      financingAnalyticsEvents.privacyPolicyClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
    'terms-of-service-click': [
      financingPage.ownerDecision,
      financingAnalyticsEvents.termsOfServiceClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
  },
  '/orgs/*/bills/*/pay/installments/schedule/repayment-terms': {
    'privacy-policy-click': [
      financingPage.repaymentTerms,
      financingAnalyticsEvents.privacyPolicyClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
    'terms-of-service-click': [
      financingPage.repaymentTerms,
      financingAnalyticsEvents.termsOfServiceClick,
      { payBillFlowUUID: 'payBillFlowUUID' },
    ],
  },
  '/orgs/*/bills/*/pay/installments/application/reviewing-details': {
    'page.view': [FINANCING_CATEGORY, financingPage.reviewingDetails],
  },
  '/orgs/*/bills/*/pay/installments/application/installments-options': {
    'page.view': [FINANCING_CATEGORY, financingPage.installmentsOptions],
  },
  '/orgs/*/bills/*/pay/installments/eligibility-pending': {
    'page.view': [FINANCING_CATEGORY, financingPage.eligibilityPending],
    'financing.errors.pending.cta': [financingPage.eligibilityPending, 'pay-another-way-click'],
    'financing-error-page-link-clicked': [financingPage.eligibilityPending, 'learn-more-click'],
  },
  '/orgs/*/bills/*/pay/installments/eligibility-not-approved': {
    'page.view': [FINANCING_CATEGORY, financingPage.eligibilityRejected],
    'financing.errors.rejected.cta': [financingPage.eligibilityRejected, 'pay-another-way-click'],
  },
  '/orgs/*/bills/*/pay/installments/amount-exceeded': {
    'page.view': [FINANCING_CATEGORY, financingPage.amountExceeded],
    'financing.errors.amountRejected.cta': [financingPage.amountExceeded, 'pay-another-way-click'],
    'financing-error-page-link-clicked': [financingPage.amountExceeded, 'learn-more-click'],
  },
  '/orgs/*/bills/*/pay/installments/error': {
    'page.view': [FINANCING_CATEGORY, financingPage.eligibilityGeneralError],
    'financing.errors.generalError.cta': [financingPage.eligibilityGeneralError, 'pay-another-way-click'],
  },
  '/orgs/*/bills/*/pay/installments/schedule/installments-options': {
    'page.view': [FINANCING_CATEGORY, financingPage.installmentsSplit],
  },
};
