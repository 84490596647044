import { PAGE_EVENT } from 'src/pages/auth/consts';
import { authLocations } from 'src/pages/auth/locations';
import { EventMappingsTree } from 'src/services/analytics/types';

export const authEventsMapping: EventMappingsTree = {
  [authLocations.register.authorizeQuickbooksSuccess]: {
    'page.view': [PAGE_EVENT, 'register-quickbooks'],
  },
  [authLocations.intuit.login]: {
    'page.view': [PAGE_EVENT, 'login-intuit'],
  },
  [authLocations.googleLoginSuccess]: {
    'page.view': [PAGE_EVENT, 'login-with-google-return-success'],
  },
  [authLocations.loginWithToken]: {
    'page.view': [PAGE_EVENT, 'login-with-token'],
  },
  [authLocations.register.fromInvitationSuccess]: {
    'page.view': [PAGE_EVENT, 'register-from-invitation-success'],
  },
  [authLocations.register.fromInvitationDob]: {
    'page.view': [PAGE_EVENT, 'register-from-invitation-dob'],
  },
  [authLocations.register.acceptInvitationSuccess]: {
    'page.view': [PAGE_EVENT, 'register-from-invitation-success'],
  },
  [authLocations.register.invalidInvitation]: {
    'page.view': [PAGE_EVENT, 'register-invalid-invitation'],
  },
  [authLocations.intuit.disconnect]: {
    'page.view': [PAGE_EVENT, 'intuit-disconnect'],
  },
  [authLocations.intuit.launch]: {
    'page.view': [PAGE_EVENT, 'intuit-launch'],
  },
  [authLocations.intuit.launchNoRealmId]: {
    'page.view': [PAGE_EVENT, 'intuit-launch-no-realm-id'],
  },
  [authLocations.register.changeEmail]: {
    'page.view': [PAGE_EVENT, 'change-email'],
  },
  [authLocations.register.authorizeIntuitSuccess]: {
    'page.view': [PAGE_EVENT, 'authorize-intuit'],
  },
  [authLocations.requestResetPassword]: {
    'page.view': [PAGE_EVENT, 'request-reset-password'],
  },
  [authLocations.registerVendor]: {
    'page.view': [PAGE_EVENT, 'vendor-registration'],
  },
};
