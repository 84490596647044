import { format } from 'date-fns';
import { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { renderDayContents } from 'src/components/common/datepicker/PaymentDatePicker';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { DatePicker } from 'src/components/form/DatePicker';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Popover, PopoverBody, PopoverContent, PopoverFooter, PopoverTrigger } from 'src/core/ds/popover';
import { Portal } from 'src/core/ds/portal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { ReactComponent as ChevronDownIcon } from 'src/images/regular-batch-payments/menu-arrow-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/images/regular-batch-payments/menu-arrow-up.svg';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import { disabledMenuStyle } from 'src/pages/regular-batch-payments/table/tableStyles';
import { getOrgId } from 'src/redux/user/selectors';
import { TextInputSize } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import { isBusinessDay } from 'src/utils/dates';

type Props = {
  itemId: string;
  dueDate: Date;
  payment: BatchPaymentType;
  minScheduledDate: string;
  payBillFlowUUID: string;
};

export const DeductionDateCell: React.FC<Props> = ({ itemId, dueDate, payment, minScheduledDate, payBillFlowUUID }) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const deductionDate = payment?.scheduledDate;

  const onChange = useCallback(
    (onClose) => async ({ date }) => {
      const { deliveryMethodId, fundingSourceId, amount } = payment;
      onClose();

      if (deliveryMethodId) {
        await actions.updatePaymentsDeliveryOptions({
          itemId,
          orgId,
          deductionDate: date,
          deliveryMethodId,
          fundingSourceId,
          amount,
          payBillFlowUUID,
        });
      } else {
        await actions.updatePayments.setPaymentScheduledDate({
          itemId,
          scheduledDate: date,
        });
      }
    },
    [actions, orgId, payment]
  );

  const getFilteredDate = (date: Moment) => isBusinessDay(date);

  return useMemo(
    () => (
      <Box __css={!payment ? disabledMenuStyle : undefined} h="full">
        <Popover gutter={12} isLazy placement="bottom-start">
          {({ isOpen, onClose }) => (
            <>
              <PopoverTrigger>
                <Flex h="full" align="center" justify="space-between" pointerEvents={isOpen ? 'none' : 'auto'}>
                  <Box isTruncated>
                    <DateLabel data-testid="deduction-date-cell">
                      {format(new Date(deductionDate), DateFormats.monthShortWithLongDateAndYear)}
                    </DateLabel>
                  </Box>
                  <ArrowIcon>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</ArrowIcon>
                </Flex>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  border="1px"
                  borderColor="grey.300"
                  borderRadius="lg"
                  as="div"
                  zIndex={50}
                  outline="none"
                  boxShadow="400"
                  display={isOpen ? 'block' : 'none'}
                >
                  <PopoverBody>
                    <DatePickerWrapper>
                      <DatePicker
                        id="deductionDate"
                        date={deductionDate}
                        dueDate={new Date(dueDate)}
                        required
                        size={TextInputSize.INLINE}
                        inline
                        withBottomElement
                        min={new Date(minScheduledDate)}
                        onChange={onChange(onClose)}
                        overrideMobile
                        filterDate={getFilteredDate}
                        renderDayContents={renderDayContents}
                        calendarContainerClass="small-calendar with-bottom-element"
                      />
                    </DatePickerWrapper>
                  </PopoverBody>
                  <PopoverFooter bg="white" borderBottomRadius="lg">
                    <Info>
                      <Dot />
                      <MIFormattedText label="regularBatchPayments.cells.deductionDate.billDue" />
                      <Dot isScheduledDate />
                      <MIFormattedText label="regularBatchPayments.cells.deductionDate.deducted" />
                    </Info>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </>
          )}
        </Popover>
      </Box>
    ),
    [payment, deductionDate, dueDate, minScheduledDate, onChange]
  );
};

const DateLabel = styled.p`
  ${({ theme }) => theme.text.fontType.medium};
  margin: 0;
  font-size: 1.2rem;
`;

const ArrowIcon = styled.div`
  display: inherit;
  padding-left: 0.3rem;
  margin-left: auto;
`;

const DatePickerWrapper = styled.div<{ disabled?: boolean }>`
  ${(props) =>
    props.disabled
      ? `
    opacity: 0.5;
    pointer-events: none;
  `
      : ''};
  .react-datepicker__day {
    color: ${(props) => props.theme.text.color.main};
  }
  .react-datepicker__day--disabled {
    color: ${(props) => props.theme.text.color.light};
  }
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;
  ${({ theme }) => theme.text.fontType.hint};
`;

const Dot = styled.div<{ isScheduledDate?: boolean }>`
  width: 1rem;
  height: 1rem;
  margin: 0 0.8rem 0 2rem;
  border-radius: 1rem;
  border: 0.2rem solid ${(props) => (props.isScheduledDate ? props.theme.colors.brand : props.theme.colors.border.dark)};
  background-color: ${(props) => props.isScheduledDate && props.theme.colors.brand};
`;
