import { AmexCardEnum, CardResponse } from '@melio/spend-management-api-axios-client';
import { format } from 'date-fns';
import { DateFormats } from 'src/utils/date-fns';

export const getCardData = (card: CardResponse) => ({
  id: card.cardId,
  cardRecord: card,
  card: {
    nickname: card.nickname,
    last5: card.cardDetails.cardLastFive,
    expiryDate: card.cardDetails.expiryDate,
    validDate:
      card.createRequest?.spendControls?.validToDate && card.status === AmexCardEnum.Active
        ? format(new Date(card.createRequest.spendControls.validToDate), DateFormats.monthShortWithDateAndYear)
        : '',
  },
  status: card.status,
  amount: {
    amount: `${card.spendControls?.originalAmount}`,
    createRequestAmount:
      (card.createRequest?.spendControls.currentAmount && `${card.createRequest?.spendControls.currentAmount}`) || null,
  },
  createdAt: card.cardDetails.cardCreationDate,
  account: {
    nickname: card.account.accountNickname || card.account.productDescription,
    last5: card.account.lastFive,
  },
  sort: {
    created: card.createdAt,
  },
});
