import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { updateItemsInState } from 'src/helpers/redux/listsActions';
import { InvoiceType } from 'src/modules/invoices/types';
import { invoicesApi } from './api';
import { name } from './constants';

type State = {
  byId: {
    [key: string]: InvoiceType;
  };
  lists: {
    [key: string]: {
      order: string[];
    };
  };
  batchCancel: {
    loading: boolean;
    error?: Error;
  };
};

const batchCancelShare = createApiCallSlice<any, State>({
  name: generateSliceName(name, 'batch_cancel'),
  api: invoicesApi.batchCancel,
  initialState: {
    byId: {},
    lists: {},
    batchCancel: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.batchCancel.loading = true;
      delete state.batchCancel.error;
    },
    [ON_SUCCESS]: (state, action) => {
      state.batchCancel.loading = false;
      const { invoices } = action.payload;
      updateItemsInState(state, invoices);
    },
    [ON_FAILURE]: (state, action) => {
      state.batchCancel.loading = false;
      state.batchCancel.error = action.error;
    },
  },
  selectors: {
    status: (state) => state[name]?.batchDelete,
    loading: (state) => state[name].batchDelete.loading,
    error: (state) => state[name].batchDelete.error,
  },
});

export default batchCancelShare;
