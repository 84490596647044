import * as React from 'react';
import { useEffect, useRef } from 'react';
import { ListItem } from 'src/core/ds/list';
import { isEnterPressed } from 'src/utils/events';
import { ExtraDropdownOptionType, MIExtraDropdownItem } from './MIExtraDropdownItem';
import { baseContainerStyles, baseItemStyles } from './MIExtraDropdownStyles';

type Props = {
  item: ExtraDropdownOptionType;
  isSelected: boolean;
  isFocused: boolean;
  onClick: () => void;
};

type ItemContainerProps = {
  children?: React.ReactNode;
  selected: boolean;
  tabIndex: number;
  onClick: () => void;
  onKeyPress: (e: React.KeyboardEvent<HTMLLIElement>) => void;
};

/**
 * The standard MIExtraDropdownItem component, with the addition of focus behavior.
 * @param isFocused - weather to focus this dropdown item, injected from useRoveFocusUsingKeyword hook.
 */
export const MIExtraDropdownItemWithFocus = ({ item, isSelected, isFocused, onClick }: Props) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (ref.current && isFocused) {
      // Move element into view when it is focused
      ref.current.focus();
    }
  }, [isFocused]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (isEnterPressed(e)) onClick();
  };

  return (
    <ItemContainer
      ref={ref}
      selected={isSelected}
      tabIndex={isFocused ? 0 : -1}
      onClick={onClick}
      onKeyPress={onKeyPress}
    >
      <MIExtraDropdownItem item={item} selected={isSelected} />
    </ItemContainer>
  );
};

const ItemContainer = React.forwardRef(({ children, ...rest }: ItemContainerProps, ref) => (
  <ListItem
    {...rest}
    ref={ref as React.RefObject<HTMLLIElement>}
    sx={{
      ...baseContainerStyles,
      ...baseItemStyles,
      py: 2,
      px: 4,
      justifyContent: 'space-between',
      _focus: {
        bgColor: 'grey.200',
        outline: 'none',
      },
    }}
  >
    {children}
  </ListItem>
));
