export const batchListStyle = {
  '&:first-of-type': {
    borderTopLeftRadius: 'lg',
    borderTopRightRadius: 'lg',
    overflow: 'hidden',
  },

  '&:last-of-type': {
    borderBottomLeftRadius: 'lg',
    borderBottomRightRadius: 'lg',
    overflow: 'hidden',
  },
};
