export const shiftToDebitLocations = {
  base: [
    '/vendors/debit-card-details/:token',
    '/vendors/add-debit-card/:token',
    '/vendors/add-debit-card/:token/invalid-link',
  ],
  index: '/vendors/debit-card-details/:token',
  addMethod: '/vendors/add-debit-card/:token',
  invalidToken: '/vendors/add-debit-card/:token/invalid-link',
  success: '/vendors/add-debit-card/:token/success',
  addAddress: '/vendors/add-debit-card/:token/address',
  checkOfferExpired: '/vendors/add-debit-card/:token/offer-expired',
  checkDeposited: '/vendors/add-debit-card/:token/check-deposited',
  paymentFailed: '/vendors/add-debit-card/:token/payment-failed',
  paymentBlocked: '/vendors/add-debit-card/:token/payment-blocked',
};
