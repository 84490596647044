import { Button, ButtonVariants } from 'src/core/ds/button';
import useRemoveBatch from 'src/pages/get-paid/hooks/useRemoveBatch';

const DeleteFailedInvoicesButton = () => {
  const [removeBatch, loading] = useRemoveBatch(
    'getPaid.deleteFailedPaymentRequests.success',
    'getPaid.deleteFailedPaymentRequests.error'
  );

  const onDelete = () => removeBatch(true);

  return (
    <Button
      label="getPaid.view.batch.delete.failed"
      onClick={onDelete}
      isLoading={loading}
      variant={ButtonVariants.tertiary}
    />
  );
};

export default DeleteFailedInvoicesButton;
