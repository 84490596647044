import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { Icon, IconNames } from 'src/core/ds/icon';
import { PrivateDataContainer } from 'src/core/ds/input';
import { LoginWithGoogleButton } from 'src/pages/auth/components/login-with-google-button';
import { InputContainer } from 'src/pages/auth/components/penny-like/InputContainer';
import { TertiaryButton } from 'src/pages/auth/components/penny-like/TertiaryButton';
import { CardLayout } from 'src/pages/auth/components/register-page-experiment/CardLayout';
import { RegisterServerErrors, useRegisterPageLogic } from 'src/pages/auth/RegisterPage';
import { analytics } from 'src/services/analytics';
import { ModelView, UseFormResult } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';
import { RegistrationFlow } from 'src/utils/consts';
import { LoginWithIntuitButton } from './components/login-with-intuit-button';

type Props = {
  registrationFlow: RegistrationFlow;
};

type SignUpFooterProps = {
  loginWithQuickbooks?: () => void;
  loginWithGoogle?: () => void;

  loginWithUserPassword?: () => void;
  textValues?: Record<string, any>;
};

export const AlternativeLoginButtons = ({
  loginWithGoogle,
  loginWithQuickbooks,
  loginWithUserPassword,
  textValues,
}: SignUpFooterProps) => {
  const wizardButtonContainerStyles = {
    margin: 0,
    button: {
      marginBottom: 0,
    },
  };

  const google = loginWithGoogle ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithGoogleButton onClick={loginWithGoogle} />
    </WizardElements.WizardButtonContainer>
  ) : null;

  const intuit = loginWithQuickbooks ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithIntuitButton onClick={loginWithQuickbooks} />
      <Box textStyle="body4" color="#6A7281" py={3} textAlign="center">
        <MIFormattedText label="auth.signUpCondensed.signInWithIntuitHint" />
      </Box>
    </WizardElements.WizardButtonContainer>
  ) : null;

  return (
    <>
      <Box mb={3}>
        <TertiaryButton
          label="auth.signUpCondensed.signInWithEmail"
          size={ButtonSizes.lg}
          onClick={loginWithUserPassword}
          leftIcon={<Icon name={IconNames.email} />}
          isFullWidth
        />
      </Box>
      <Box mb={3}>{google}</Box>
      <Box mb={0}>{intuit}</Box>
      <Box fontSize="14px" lineHeight="20px" marginTop="60px" textAlign="center" color="#4A505B">
        <MIFormattedText label="auth.signUpCondensed.alreadyHaveAnAccount" values={textValues} />
      </Box>
    </>
  );
};

export function useShowEmailPassword() {
  const history = useHistory();
  const location = useLocation<{ showEmailPassword: boolean }>();
  const showEmailPassword = location.state?.showEmailPassword;
  const setShowUserPassword = useCallback(
    (value: boolean) => {
      analytics.track('register', 'show-email-password');
      history.push({
        state: {
          ...location.state,
          showEmailPassword: value,
        },
      });
    },
    [history, location.state]
  );
  useEffect(
    () =>
      history.listen((loc, action) => {
        if (action === 'POP' && showEmailPassword) {
          analytics.track('register', 'show-email-password-back');
        }
      }),
    [history, showEmailPassword]
  );

  return { showEmailPassword, setShowUserPassword };
}
export const RegisterPageCondensed: React.VFC<Props> = (props) => {
  const {
    goLinkQuickbooks,
    goLogin,
    handleRequestPassword,
    registerMV,
    submit,
    openGoogleOAuth,
    isLoading,
    isPasswordRequestSucceeded,
    combinedError,
    isEmailPrefilled,
    isSpendManagementRegistrationFlow,
    errorRequestPassword,
  } = useRegisterPageLogic(props);
  const { showEmailPassword, setShowUserPassword } = useShowEmailPassword();

  const alternativeLoginButtons = (
    <AlternativeLoginButtons
      loginWithQuickbooks={isSpendManagementRegistrationFlow ? undefined : goLinkQuickbooks}
      loginWithGoogle={() => {
        analytics.track('register', 'login-with-google');
        openGoogleOAuth();
      }}
      textValues={{
        link: (label) => <StyledMIInlineLink onClick={goLogin} label={label} testId={`link-${label}`} />,
      }}
      loginWithUserPassword={() => setShowUserPassword(true)}
    />
  );

  return (
    <CardLayout title="auth.signUpCondensed.title" subtitle="auth.signUpCondensed.subtitle">
      <RegisterServerErrors
        errorRequestPassword={errorRequestPassword}
        isPasswordRequestSucceeded={isPasswordRequestSucceeded}
        handleRequestPassword={handleRequestPassword}
        combinedError={combinedError}
        goLogin={goLogin}
      />
      <>
        {showEmailPassword ? (
          <UserPasswordForm
            registerMV={registerMV}
            isEmailPrefilled={isEmailPrefilled}
            submit={submit}
            isLoading={isLoading}
          />
        ) : (
          alternativeLoginButtons
        )}
      </>
    </CardLayout>
  );
};

interface UserPasswordFormProps {
  registerMV: ModelView<{ password: string; email: string }>;
  isEmailPrefilled?: boolean;
  submit: UseFormResult<{ password: string; email: string }>[1]['submit'];
  isLoading?: boolean;
}

export function UserPasswordForm({ registerMV, isEmailPrefilled, submit, isLoading }: UserPasswordFormProps) {
  return (
    <InputContainer onSubmit={submit}>
      <TextField
        label="auth.vendorRegister.workEmail"
        model={registerMV.email}
        isRequired
        autoFocus={!isEmailPrefilled}
        type="email"
        privateData
      />
      <PrivateDataContainer>
        <WizardPasswordValidationInputField
          testId="input-password"
          label="auth.vendorRegister.password"
          model={registerMV.password}
          placeholder=" "
          isRequired
          autoFocus={isEmailPrefilled}
        />
      </PrivateDataContainer>
      <Button
        variant={ButtonVariants.primary}
        size={ButtonSizes.lg}
        isLoading={isLoading}
        isFullWidth
        onClick={submit}
        label="auth.signUpCondensed.buttonLabel"
      />
    </InputContainer>
  );
}

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: inherit;
  line-height: inherit;
  height: auto;
`;
