import { Box } from '@chakra-ui/layout';
import head from 'lodash/head';
import { CSVLink } from 'react-csv';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { CSVVendorsColumnName } from 'src/pages/contacts/create/constants';
import { analytics } from 'src/services/analytics';
import { ACCEPT_FILES } from 'src/utils/consts';
import { NavigationCallbacks } from 'src/utils/types';
import { useFileInput } from 'src/utils/useFileInput';

export const BatchUploadVendorsExplain = ({ onPrev, goExit, onNext }: NavigationCallbacks) => {
  const exampleFileData = [
    [
      CSVVendorsColumnName.COMPANY_NAME,
      CSVVendorsColumnName.CONTACT_EMAIL,
      CSVVendorsColumnName.CONTACT_NAME,
      CSVVendorsColumnName.CONTACT_PHONE,
    ],
  ];
  const exampleFileName = 'Vendors list - template.csv';
  const handleLoadBatchVendors = useFileInput(
    (files) => {
      analytics.track('contacts_create', 'batch-vendors-file-uploaded');
      onNext && onNext(head(files));
    },
    { accept: ACCEPT_FILES.TEXT_LISTS, multiple: false, testId: 'upload-vendor-file' }
  );

  return (
    <StepLayoutPage
      title="contacts.batchUploadVendorExplain.title"
      nextLabel="contacts.batchCsvExplain.action"
      onNext={handleLoadBatchVendors}
      onPrev={onPrev}
      goExit={goExit}
      fullWidthCTA
    >
      <Box bg="white" p={6} mb={5}>
        <Box color="black" mb={5} textStyle="body1Semi">
          <MIFormattedText label="contacts.batchUploadVendorExplain.subtitle" />
        </Box>
        <Box textStyle="body2">
          <Box color="primary.500" textStyle="body2Semi" mr={2} as="span" display="inline-block">
            01
          </Box>
          <MIFormattedText
            label="contacts.batchUploadVendorExplain.explain.line1"
            values={{
              download: (
                <CSVLink
                  data={exampleFileData}
                  target="_blank"
                  filename={exampleFileName}
                  onClick={() => analytics.track('contacts_create', 'batch-vendors-download-template')}
                >
                  <Box textStyle="body2" color="primary.500" display="inline-block">
                    <MIFormattedText label="contacts.batchUploadVendorExplain.explain.download" />
                  </Box>
                </CSVLink>
              ),
            }}
          />
        </Box>
        <Box textStyle="body2">
          <Box color="primary.500" textStyle="body2Semi" mr={2} as="span" display="inline-block">
            02
          </Box>
          <MIFormattedText label="contacts.batchUploadVendorExplain.explain.line2" />
        </Box>
      </Box>
      <Flex textStyle="body3" px={6}>
        <Box mr={4} mt={1}>
          <Icon name={IconNames.alertCircle} />
        </Box>
        <Box>
          <MIFormattedText label="contacts.batchUploadVendorExplain.note" />
        </Box>
      </Flex>
    </StepLayoutPage>
  );
};
