import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import { MouseEvent as ReactMouseEvent } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { CheckProtectionModal } from 'src/components/common/CheckProtectionModal';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Image } from 'src/core/ds/image';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { useModal } from 'src/helpers/react/useModal';
import mastercard from 'src/images/icons/mastercard.svg';
import { ChecksDelaysTooltip } from 'src/pages/bill/pay/components/ChecksDelays/ChecksDelaysTooltip';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { getCompanyInfo } from 'src/redux/user/selectors';
import {
  ChecksDelaysTooltipTypes,
  DeliveryType,
  FeatureFlags,
  NON_EDITABLE_DELIVERY_METHOD_TYPES,
} from 'src/utils/consts';
import { getTranslationValue } from 'src/utils/translations';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  deliveryMethod: DeliveryMethodType;
  companyName: string;
  onItemClick: () => void;
  isSelected: boolean;
  isRecommended?: boolean;
  isDisabled?: boolean;
  label?: string;
  description?: string;
  actionOptions?: ActionOption[];
};

const DeliveryMethodsListItem = ({
  deliveryMethod,
  onItemClick,
  actionOptions,
  companyName,
  isSelected,
  isRecommended,
  isDisabled,
  label,
  description,
}: Props) => {
  const isInternationalMethod = isInternationalDeliveryMethod(deliveryMethod.deliveryType);
  const companyInfo = useSelector(getCompanyInfo);
  const isCheckDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.CHECK;
  const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.USHolidaysChecks, false);
  const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);
  const showChecksDelaysTooltip = isChecksDelaysEnabled && isCheckDeliveryMethod;
  const isPaperCheckDataExist = deliveryMethod.paperCheck;
  const [CheckProtection, showCheckProtectionModal] = useModal(CheckProtectionModal, {});
  const checkDelaysTooltipLabel = isGeneralCheckDelays
    ? 'bills.pay.date.checksDelays.general.tooltip'
    : 'bills.pay.date.checksDelays.tooltipGeneral';

  const renderDeliveryMethodIcon = () => {
    switch (deliveryMethod?.deliveryType) {
      case DeliveryType.CHECK:
        return <DeliveryMethodIcon className="icon-check-icon" isDisabled={isDisabled} />;
      case DeliveryType.VIRTUAL:
        return <DeliveryMethodIcon className="icon-email-icon" />;
      case DeliveryType.INTERNATIONAL:
        return <DeliveryMethodIcon className="icon-globe" isDisabled={isDisabled} />;
      case DeliveryType.VIRTUAL_CARD:
        return <Image w={6} src={mastercard} />;
      default:
        return <DeliveryMethodIcon className="icon-bank-transfer" isDisabled={isDisabled} />;
    }
  };

  const getMethodDescription = () => {
    const translatedDescription = description && getTranslationValue(description);

    if (isInternationalMethod) return translatedDescription;

    const isDmExist = Boolean(deliveryMethod.id);
    const shouldDisplayCheckWithFeeDescription = deliveryMethod.deliveryType === DeliveryType.CHECK && !isDmExist;

    if (shouldDisplayCheckWithFeeDescription) {
      const feeAmount = companyInfo?.billingSetting?.fee['ach-to-check']?.value ?? 1.5;
      const totalFreeChecks = companyInfo?.totalFreeChecksPerMonth;

      return (
        <>
          {CheckProtection}
          <CheckWithFeeDescription
            amount={feeAmount}
            totalFreeChecks={totalFreeChecks}
            onMoreInfoClick={showCheckProtectionModal}
          />
        </>
      );
    }

    if (!deliveryMethod.id) {
      return getTranslationValue(`deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.placeholder`);
    }

    return (
      translatedDescription ||
      getDeliveryMethodDisplay({
        deliveryMethod,
        vendorName: companyName,
      })
    );
  };

  const showActionsMenu =
    !isEmpty(actionOptions) &&
    deliveryMethod.id &&
    !NON_EDITABLE_DELIVERY_METHOD_TYPES.includes(deliveryMethod.deliveryType);

  return (
    <DeliveryMethod
      data-testid={`delivery-method-${deliveryMethod.deliveryType}`}
      isSelected={isSelected}
      onClick={onItemClick}
      isDisabled={isDisabled}
    >
      <Flex justify="space-between">
        <Flex align="center">
          <Flex pl={1} pr={5} alignItems="center">
            {renderDeliveryMethodIcon()}
          </Flex>
          <div>
            <DeliveryMethodName isDisabled={isDisabled}>
              <MIFormattedText label={label || `deliveryMethods.notDefinedInfo.${deliveryMethod.deliveryType}.label`} />
              {isRecommended && (
                <TagContainer>
                  <Badge
                    testId="delivery-method-recommended-badge"
                    label="deliveryMethods.recommendedTag"
                    color={isDisabled ? BadgeColors.NEUTRAL : BadgeColors.PRIMARY}
                  />
                </TagContainer>
              )}
              {showChecksDelaysTooltip && (
                <ChecksDelaysTooltip
                  tooltipLabel={checkDelaysTooltipLabel}
                  type={
                    isPaperCheckDataExist ? ChecksDelaysTooltipTypes.WARNING : ChecksDelaysTooltipTypes.INFO_DEFAULT
                  }
                />
              )}
            </DeliveryMethodName>
            <DeliveryMethodDescription isDisabled={isDisabled}>{getMethodDescription()}</DeliveryMethodDescription>
          </div>
        </Flex>
        {actionOptions && showActionsMenu && (
          <Menu autoSelect={false} isLazy>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon name={IconNames.more} />}
              variant="action"
              placement="top"
              data-testid={`deliveryMethod-dots-menu-${deliveryMethod.id}`}
            />
            <MenuList>
              {actionOptions.map(({ label, action, negative }) => (
                <MenuItem key={label} label={label} onClick={action} color={negative ? 'red.500' : 'black'} />
              ))}
            </MenuList>
          </Menu>
        )}
      </Flex>
    </DeliveryMethod>
  );
};

// eslint-disable-next-line import/no-default-export
export default DeliveryMethodsListItem;

const getBorder = ({ selected, disabled, colors }) => {
  if (disabled) {
    return `0.1rem solid ${colors.dark.translucent2}`;
  } else if (selected) {
    return `0.2rem solid ${colors.primary.opaque}`;
  }

  return 'none';
};

const CheckWithFeeDescription = ({
  amount,
  totalFreeChecks,
  onMoreInfoClick,
}: {
  amount: number;
  totalFreeChecks?: number;
  onMoreInfoClick: () => void;
}) => {
  const handleMoreInfoClick = (e: ReactMouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onMoreInfoClick();
  };

  return (
    <>
      <MIFormattedText
        label="bills.pay.deliveryMethods.check.feeDescription"
        values={{ amount: amount?.toFixed(2), freeChecks: totalFreeChecks }}
      />
      <Box data-testid="check-dm-placeholder-more-info" as="span" textStyle="link3" onClick={handleMoreInfoClick}>
        <MIFormattedText label="bills.pay.deliveryMethods.check.feeDescriptionAction" />
      </Box>
    </>
  );
};

const DeliveryMethod = styled.div`
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  max-width: 45rem;
  width: 100%;
  border-radius: 0.8rem;
  border: ${(props) =>
    getBorder({
      selected: props.isSelected,
      disabled: props.isDisabled,
      colors: props.theme.colors,
    })};
  background-color: ${(props) =>
    props.isDisabled ? props.theme.colors.white.veryLightGrey : props.theme.colors.white.opaque};
  padding: 2.4rem;
  box-sizing: border-box;
  box-shadow: ${(props) => (props.isDisabled ? 'none' : `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}`)};
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  transition: ${(props) => props.theme.animation.transition.default};
  transition-property: box-shadow;

  &:hover:not(:active) {
    ${(props) =>
      !(props.isDisabled || props.isSelected) &&
      css`
        box-shadow: 0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2};
      `}
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const TagContainer = styled.div`
  margin-left: 0.8rem;
  display: inline-flex;
`;

export const DeliveryMethodName = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => (props.isDisabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.main)};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.regular};
`;

const DeliveryMethodDescription = styled.div<{ isDisabled?: boolean }>`
  color: ${(props) => (props.isDisabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.subtitle)};
  ${(props) => props.theme.text.fontType.hint};
`;

const DeliveryMethodIcon = styled.i<{ isDisabled?: boolean }>`
  font-size: 2.4rem;
  color: ${(props) => props.theme.colors.icons.card};
  color: ${(props) => (props.isDisabled ? props.theme.colors.dark.translucent3 : props.theme.colors.icons.card)};
`;
