import React, { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { UseModal } from 'src/helpers/react/useModal';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';

type ViewBillBlockRefundModalProps = {
  paymentId: string;
  billId: string;
};

export const ViewBillBlockRefundModal: React.FC<UseModal<ViewBillBlockRefundModalProps>> = ({
  paymentId,
  billId,
  dismiss,
}) => {
  useEffect(() => {
    analytics.trackAction('talk-to-support', { paymentId, billId });
  }, [paymentId, billId]);

  const openChat = () => {
    dismiss();
    intercomService.show();
    analytics.trackAction('chat-with-support', { paymentId, billId });
  };

  return (
    <ModalMessage
      id="refund-block"
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText label="bills.pay.requestRefund.modalMessage.title" />
        </Box>
      }
      contentComponent={<MIFormattedText label="bills.pay.requestRefund.modalMessage.subtitle" />}
      buttonLabel="bills.pay.requestRefund.modalMessage.buttonLabel"
      onButtonClick={openChat}
      onCloseClick={dismiss}
    />
  );
};
