import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { DateField, TextField } from 'src/core/ds/form/fields';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useBillDetailsToggle } from 'src/pages/onboarding/just-pay/hooks/useBillDetailsToggle';
import { ModelView } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { CreateBillType } from 'src/utils/types';

type Props = {
  model: ModelView<CreateBillType>;
};

export const BillDetailsForm = ({ model }: Props) => {
  const { isBillDetailsOpen, onBillDetailsOpen } = useBillDetailsToggle();

  return (
    <>
      <Flex
        w="inherit"
        onClick={onBillDetailsOpen}
        justify="space-between"
        cursor="pointer"
        mb={12}
        mt={2}
        textAlign="center"
        h={5}
      >
        <Box color="grey.700" textStyle="body2Semi">
          <MIFormattedText label="bills.justPay.billDetails" />

          <Box as="span" color="grey.600" textStyle="body4">
            <MIFormattedText label="bills.justPay.optional" />
          </Box>
        </Box>
        <Icon
          name={isBillDetailsOpen ? IconNames.shevronUp : IconNames.shevronDown}
          size={IconSize.m}
          testId="bills.justPay.billDetails"
          color="grey.700"
        />
      </Flex>
      {isBillDetailsOpen ? (
        <Box alignSelf="flex-start" textStyle="body3" color="grey.700" mb={10} mt={-5}>
          <MIFormattedText label="bills.justPay.billDetailsDesc" />
        </Box>
      ) : null}
      {isBillDetailsOpen ? (
        <>
          <Box as={FormRow} mbZero>
            <PrivateDataContainer>
              <TextField
                label="bills.justPay.invoiceLabel"
                placeholder="bills.justPay.invoiceNumberPlaceholder"
                model={model.invoiceNumber}
              />
            </PrivateDataContainer>
            <DateField
              label="bills.justPay.dueDateLabel"
              placeholder="bills.justPay.dueDatePlaceholder"
              model={model.dueDate}
              isRequired
            />
          </Box>
          <Box as={FormRow}>
            <TextField
              label="bills.justPay.billNoteLabel"
              placeholder="bills.justPay.billNotePlaceholder"
              model={model.note}
            />
          </Box>
        </>
      ) : null}
    </>
  );
};
