import { memo } from 'react';
import Box from 'src/core/ds/box';

type VendorCellProps = {
  vendorName: string;
};

export const VendorCell: React.FC<VendorCellProps> = memo(({ vendorName }) => (
  <Box isTruncated data-testid="regularBatchPayments-cells-vendor">
    {vendorName}
  </Box>
));
