import flatMap from 'lodash/flatMap';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import pickBy from 'lodash/pickBy';
import * as React from 'react';
import { ForwardedRef } from 'react';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { MINotices } from 'src/components/common/MINotices';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { analytics } from 'src/services/analytics';
import { Site } from 'src/sites/site';
import { FULL_STORY_MASK_RULE_CLASS, SingleSelectFlavor, TextInputSize } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';

export type Option = {
  value: string;
  label?: string;
  options?: Option[];
  [key: string]: any;
};

type MetaType = {
  action: 'select-option' | 'deselect-option' | 'remove-value' | 'pop-value' | 'set-value' | 'clear' | 'create-option';
};
// FIXME meta flow definition is not correct.
// It should have ActionMeta type https://github.com/JedWatson/react-select/blob/master/src/types.js#L83
export type SelectFieldType = {
  id: string;
  value: any;
  meta: MetaType;
};
type OnChange = (changeValue: SelectFieldType) => void;

// FIXME meta flow definition is not correct.
// It should have InputActionMeta type https://github.com/JedWatson/react-select/blob/master/src/types.js#L93
export type OnInputChange = (value: {
  id: string;
  value: string;
  meta: 'set-value' | 'input-change' | 'input-blur' | 'menu-close';
}) => void;

export type FormatOptionContext = {
  context: 'menu' | 'value';
  inputValue: string;
  selectValue?: Option | Option[] | null;
};

export type overrideStyles = {
  option?: any;
  optionIsNew?: React.CSSProperties;
  group?: any;
  groupHeading?: any;
  menuList?: any;
};

type Props = {
  id: string;
  value: any;
  label: string;
  labelValues?: Record<string, any>;
  placeholder?: string;
  noOptionsLabel?: string | null;
  options: Option[];
  isClearable?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  required?: boolean;
  createLabel?: string;
  allowCustom?: boolean;
  flavor?: SingleSelectFlavor;
  notices?: Array<string>;
  errorMessage?: string | null;
  isLoading?: boolean;
  site: Site;
  onChange?: OnChange;
  onInputChange?: OnInputChange;
  formatOptionLabel?: (option: Option, formatOptions: FormatOptionContext) => any;
  formatGroupLabel?: (group: Option) => any;
  formatCreateLabel?: (inputValue: string) => any;
  filterOption?: () => void;
  testId?: string | null;
  inputMaxLength?: number;
  isAutoFocus?: boolean;
  overrideStyles?: overrideStyles;
  isValidNewOption?: (inputValue: string, selectValue: Option, selectOptions: Option[]) => boolean;
  showDropdownIndicator?: boolean;
  openMenuOnFocus?: boolean;
  onCreateOption?: () => void;
  innerRef?: ForwardedRef<HTMLDivElement>;
  privateData?: boolean;
  afterLabelComponent?: React.ReactNode;
  menuPortalTarget?: HTMLElement;
  menuPlacement?: 'auto' | 'bottom' | 'top';
};

type State = {
  inputValue: string;
};

function fontSizeByFlavor(flavor: SingleSelectFlavor) {
  switch (flavor) {
    case SingleSelectFlavor.INLINE:
      return '1.6rem';
    case SingleSelectFlavor.TABLE:
      return '1.4rem';
    default:
      return '2.3rem';
  }
}

function borderWidthByFlavor(flavor: SingleSelectFlavor) {
  switch (flavor) {
    case SingleSelectFlavor.INLINE:
      return '0.1rem';
    case SingleSelectFlavor.TABLE:
      return '0';
    default:
      return '0.2rem';
  }
}

function optionColorsByState(state, colors, text) {
  if (state.isDisabled)
    return {
      background: colors.white.veryLightGrey,
      color: text.color.light,
      '&:hover': {},
      '&:active': { background: colors.white.whiteSmoke },
    };

  return {
    background: state.isSelected ? colors.white.whiteSmoke : 'white',
    color: 'black',
    '&:hover': {
      background: colors.white.whiteSmoke,
    },
    '&:active': state.isSelected ? { background: colors.white.whiteSmoke } : {},
  };
}

function heightByFlavor(flavor: SingleSelectFlavor) {
  switch (flavor) {
    case SingleSelectFlavor.INLINE:
      return '3.5rem';
    case SingleSelectFlavor.TABLE:
      return '3.5rem';
    case SingleSelectFlavor.DS:
      return '3.6rem';
    default:
      return '3.6rem';
  }
}

// Configure the Select styles here since we pass them to
// the react-select component
const selectStyleTemplate = (
  flavor: SingleSelectFlavor,
  isSelectInvalid: boolean,
  theme: any,
  overrideStyles?: { [key: string]: React.CSSProperties }
) => {
  const { text, colors } = theme;
  const fontSize = fontSizeByFlavor(flavor);
  const lineHeight = 'normal';
  const fontWeight = text.weight.regular;
  const height = heightByFlavor(flavor);

  const minHeight = flavor === SingleSelectFlavor.DEFAULT ? '3.6rem' : 'unset';
  const maxHeight = flavor === SingleSelectFlavor.INLINE ? '3.2rem' : 'unset';

  return {
    // The main control component
    control: (base, state) => {
      const borderWidth = borderWidthByFlavor(flavor);
      const errorBottomBorder = `${borderWidth} solid ${text.color.red}`;
      const grayBottomBorder = isSelectInvalid
        ? errorBottomBorder
        : `${borderWidth} solid ${state.isDisabled ? text.color.readonly : text.color.light}`;
      const blackBottomBorder = `${borderWidth} solid black`;
      const borderBottom = state.selectProps.menuIsOpen ? blackBottomBorder : grayBottomBorder;
      const themeTemplate: any = theme?.components?.MISingleSelect?.controlStyleTemplate || (() => ({}));

      return {
        ...base,
        '&:hover': {
          grayBottomBorder,
        },
        border: 'none',
        borderBottom,
        borderRadius: '0',
        height,
        minHeight,
        maxHeight,
        maxWidth: 'unset',
        background: 'transparent',
        boxShadow: 'none',
        ...themeTemplate({ ...state, isSelectInvalid, theme }),
      };
    },

    // Text placeholder when nothing is selected
    placeholder: (base) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.placeholderStyleTemplate || (() => ({}));

      return {
        ...base,
        color: text.color.readonly,
        fontSize,
        fontWeight,
        marginLeft: 0,
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...themeTemplate({ isSelectInvalid, theme }),
      };
    },

    // The dropdown indicator (down arrow)

    dropdownIndicator: (base, state) => {
      const { hasValue, selectProps } = state;

      return {
        ...base,
        transform: selectProps.menuIsOpen ? 'rotate(-180deg)' : '',
        fontSize: '1.8rem',
        display: hasValue && selectProps.isClearable ? 'none' : 'flex',
        color:
          (selectProps.menuIsOpen || hasValue || selectProps.placeholder) && !state.isDisabled
            ? 'black'
            : text.color.label,

        '&:hover': {
          color: 'black',
        },
      };
    },

    // A separator for the indicators when something is selected
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),

    // The X button to clear the selection
    clearIndicator: (base) => ({
      ...base,
      color: '#99999c',
    }),

    // The input box (for the user to type)
    input: (base) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.inputStyleTemplate || (() => ({}));

      return {
        ...base,
        fontSize,
        fontWeight,
        marginLeft: 0,
        input: {
          fontWeight,
          lineHeight,
        },
        ...themeTemplate({
          isSelectInvalid,
          theme,
        }),
      };
    },

    // The group header label.
    groupHeading: (base) => ({
      ...base,
      padding: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      cursor: 'default',
      ...overrideStyles?.groupHeading,
    }),
    // The group container.
    group: (base) => ({
      ...base,
      padding: 0,
      ...overrideStyles?.group,
    }),

    // List item, the options when the menu is open
    option: (base, state) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.optionStyleTemplate || (() => ({}));

      return {
        ...base,
        ...optionColorsByState(state, colors, text),
        minHeight: '5rem',
        padding: '1.6rem 2rem',
        fontSize: '1.4rem',
        fontWeight: 'normal',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        cursor: 'default',
        ...themeTemplate({
          isSelectInvalid,
          theme,
        }),
        ...overrideStyles?.option,
        // eslint-disable-next-line no-underscore-dangle
        ...(state?.data?.__isNew__ && overrideStyles?.optionIsNew),
      };
    },

    // The list of items
    menuList: (base) => ({
      ...base,
      padding: '0.8rem 0',
      border: 'none',
      borderRadius: '0.8rem',
      boxShadow: `0 0 1rem 0 ${colors.dark.translucent2}`,
      ...overrideStyles?.menuList,
    }),

    // When a single value is selected (how it appears in the control)
    singleValue: (base, state) => {
      const themeTemplate: any = theme?.components?.MISingleSelect?.singleValueStyleTemplate || (() => ({}));

      return {
        ...base,
        fontSize,
        fontWeight,
        lineHeight,
        margin: 0,
        color: state.isDisabled ? text.color.readonly : 'black',
        ...themeTemplate({
          ...state,
          isSelectInvalid,
          theme,
        }),
      };
    },

    valueContainer: (base) => ({
      ...base,
      cursor: 'text',
      padding: 0,
      height: 'inherit',
    }),

    menuPortal: (base) => ({
      ...base,
      zIndex: theme.zIndex.overlay,
    }),
  };
};

// Add spec arrow to dropdown indicator
const DropdownIndicator = () => (props) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Icon name={props.menuIsOpen ? IconNames.caretUp : IconNames.caretDown} />
    </components.DropdownIndicator>
  );

const CustomMenuList = ({ children, ...otherProps }) =>
  components.MenuList && (
    <components.MenuList {...otherProps}>
      <div data-testid={otherProps.selectProps?.menuListTestId}>{children}</div>
    </components.MenuList>
  );

function getSelectFlattenOptions(options: Option[]): Option[] {
  return flatMap(options, (option) => (option?.options ? option.options : option)) as Option[];
}

export function getSelectOptionObject(options: Option[], value: number): Option | null {
  if (!value) return null;

  const flattenOptions = getSelectFlattenOptions(options);

  return flattenOptions.find((option: Option) => option.value === value.toString()) || null;
}

const getFilteredOptions = (options: Option[], searchTerm = '') =>
  options.filter((option) => (option.label?.toLowerCase() || '')?.indexOf(searchTerm.toLowerCase()) !== -1);

class MISingleSelect extends React.PureComponent<Props, State> {
  static defaultProps = {
    placeholder: 'select.defaultPlaceholder',
    noOptionsLabel: 'select.noOptions',
    flavor: SingleSelectFlavor.DEFAULT,
    isDisabled: false,
    isSearchable: true,
    required: false,
    isClearable: false,
    allowCustom: false,
    notices: [],
    errorMessage: null,
    createLabel: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onInputChange: () => {},
    formatOptionLabel: null,
    formatGroupLabel: null,
    formatCreateLabel: (inputValue: string) => inputValue,
    filterOption: undefined,
    testId: null,
    inputMaxLength: undefined,
    overrideStyles: {},
    isValidNewOption: null,
    showDropdownIndicator: true,
    openMenuOnFocus: undefined,
    onCreateOption: undefined,
    isLoading: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      inputValue: '',
    };
  }

  selectRef = React.createRef<HTMLSelectElement>();

  handleChange = (newValue: Option, actionMeta: MetaType) => {
    if (this.props.onChange) {
      this.props.onChange({
        id: this.props.id,
        meta: actionMeta,
        ...newValue,
      });

      analytics.trackAction(`option-changed-${this.props.id}`, {
        option: newValue,
        searchTerm: this.state.inputValue,
        optionsCount: getFilteredOptions(this.props.options, this.state.inputValue).length,
      });

      this.setState({ inputValue: '' }, () => this.selectRef.current?.blur());
    }
  };

  handleInputChange = (newInputValue: any, actionMeta: any) => {
    const { id, onInputChange } = this.props;

    // only set the input when the action that caused the
    // change equals to "input-change" and ignore the other
    // ones like: "set-value", "input-blur", and "menu-close"
    if (actionMeta.action === 'input-change') {
      if (onInputChange) {
        onInputChange({
          id,
          value: newInputValue,
          meta: actionMeta,
        });
      }

      this.setState({ inputValue: newInputValue });
    }
  };

  noOptionsMessage = () => (this.props.noOptionsLabel ? <MIFormattedText label={this.props.noOptionsLabel} /> : null);

  focus = () => {
    this.selectRef.current?.focus();
  };

  formatCreateLabel = (inputValue: string) => {
    const { createLabel } = this.props;

    return (
      <CreateLabel data-testid={createLabel}>
        <Icon name={IconNames.plus} />
        {createLabel ? <MIFormattedText label={createLabel} values={{ value: inputValue }} /> : <>{inputValue}</>}
      </CreateLabel>
    );
  };

  handleOnBlur = () => {
    const { options, allowCustom } = this.props;
    const { inputValue } = this.state;
    const flattenOptions = getSelectFlattenOptions(options);

    if (allowCustom && inputValue) {
      const optionIndex = flattenOptions.map((option: Option) => option.label).indexOf(inputValue);

      if (optionIndex === -1) {
        const newValue = {
          __isNew__: true,
          value: inputValue,
        };

        this.handleChange(newValue, { action: 'create-option' });
      } else {
        this.handleChange(flattenOptions[optionIndex], { action: 'select-option' });
      }

      this.setState({ inputValue: '' });
    }
  };

  isValidNewOption = (inputValue: string, selectValue: Option[], selectOptions: Option[]): boolean => {
    const flatOptions = getSelectFlattenOptions(selectOptions);

    return !(
      !inputValue ||
      selectValue.some((option) => option.label === inputValue) ||
      flatOptions.some((option) => option.label === inputValue)
    );
  };

  onKeyPressed = (event: React.KeyboardEvent<any>) => {
    const { inputValue } = this.state;
    const { onChange } = this.props;

    if (isEnterPressed(event) && !!inputValue.length && onChange) {
      const createdValue = { __isNew__: true, value: inputValue };
      onChange({ id: this.props.id, meta: { action: 'create-option' }, ...createdValue });
    }
  };

  render() {
    const {
      id,
      label,
      value,
      flavor,
      placeholder,
      isClearable,
      isDisabled,
      isSearchable,
      required,
      allowCustom,
      options,
      notices,
      errorMessage,
      site,
      formatOptionLabel,
      filterOption,
      testId,
      inputMaxLength,
      isAutoFocus,
      formatGroupLabel,
      formatCreateLabel,
      createLabel,
      overrideStyles,
      isValidNewOption,
      showDropdownIndicator,
      openMenuOnFocus,
      onCreateOption,
      labelValues,
      isLoading,
      privateData,
      afterLabelComponent,
      menuPortalTarget,
      menuPlacement,
    } = this.props;
    const { inputValue } = this.state;

    // We conditionally render CreateableSelect or Select
    // so we build the props here
    const selectProps = pickBy(
      {
        options,
        value: isObject(value) ? value : getSelectOptionObject(options, value),
        styles: selectStyleTemplate(
          flavor || MISingleSelect.defaultProps.flavor,
          !!errorMessage,
          site.theme,
          overrideStyles
        ),
        placeholder: placeholder ? <MIFormattedText label={placeholder} /> : '',
        components: {
          DropdownIndicator: showDropdownIndicator ? DropdownIndicator() : null,
          MenuList: CustomMenuList,
        },
        isClearable,
        isSearchable,
        isDisabled,
        isLoading,
        required,
        inputValue:
          !inputMaxLength || inputValue.length <= inputMaxLength ? inputValue : inputValue.substr(0, inputMaxLength),
        // We need to controll close and blur events manually to make the feature above works properly on mobile devices.
        // eslint-disable-next-line max-len
        // "entering a new vendor, the value should stay in the field (and later be the new vendor name) - after the focus change, not only on click on "+""
        // Without it we have race condition for select and blur events
        closeMenuOnSelect: false,
        blurInputOnSelect: false,
        noOptionsMessage: this.noOptionsMessage,
        formatCreateLabel: createLabel ? this.formatCreateLabel : formatCreateLabel,
        onChange: this.handleChange,
        onInputChange: this.handleInputChange,
        formatOptionLabel,
        formatGroupLabel,
        filterOption,
        isValidNewOption: isValidNewOption || this.isValidNewOption,
        classNamePrefix: 'select',
        onBlur: this.handleOnBlur,
        tabSelectsValue: false,
        openMenuOnFocus,
        onCreateOption,
        menuPortalTarget,
        menuPlacement,
        menuListTestId: testId ? `menu-list-${testId}` : undefined,
      },
      (prop) => !isNil(prop)
    );
    const size = [SingleSelectFlavor.DEFAULT, SingleSelectFlavor.DS].includes(flavor as SingleSelectFlavor)
      ? TextInputSize.WIZARD
      : TextInputSize.INLINE;

    const selectTestId = testId || `input-${id}`;

    return (
      <SelectWrapper
        flavor={flavor}
        data-testid={selectTestId}
        onKeyDown={this.onKeyPressed}
        ref={this.props.innerRef}
        className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined}
      >
        <MIInputLabel
          inputId={id}
          label={label}
          errorMessage={errorMessage}
          size={size}
          required={required}
          labelValues={labelValues}
          afterLabelComponent={afterLabelComponent}
        />
        {allowCustom ? (
          <CreatableSelect ref={this.selectRef} autoFocus={isAutoFocus} {...selectProps} />
        ) : (
          <Select ref={this.selectRef} {...selectProps} />
        )}

        <MINotices testId={`${selectTestId}-notices`} size={size} notices={notices} errorMessage={errorMessage} />
      </SelectWrapper>
    );
  }
}

// This wraps the "Create custom" option
// Adjust the icon's font to look on par with the select
// I do this by changing the i element, and I use "em" for size
// because it may come in different sizes

type CreateLabelProps = {
  children?: React.ReactNode;
  'data-testid'?: string;
};

const CreateLabel = ({ children, ...rest }: CreateLabelProps) => (
  <Box
    as="span"
    sx={{
      svg: {
        verticalAlign: 'text-bottom',
        textStyle: 'body3',
        mr: 3,
      },
    }}
    {...rest}
  >
    {children}
  </Box>
);

type SelectWrapperProps = {
  children?: React.ReactNode;
  flavor?: SingleSelectFlavor;
  'data-testid'?: string;
  onKeyDown: (event: React.KeyboardEvent<any>) => void;
  className?: string;
};

const SelectWrapper = React.forwardRef<HTMLDivElement, SelectWrapperProps>(({ children, flavor, ...rest }, ref) => (
  <Box ref={ref} {...rest} maxW="full" flexGrow={1} mb={flavor === SingleSelectFlavor.DEFAULT ? 8 : 0}>
    {children}
  </Box>
));

const SelectWithSite = withSiteContext()(MISingleSelect);
const SelectWithRef = React.forwardRef<any, any>((props, ref) => <SelectWithSite innerRef={ref} {...props} />);

export default SelectWithRef;
