import { featureFlags } from '@melio/shared-web';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import { Menu, MenuButton, MenuDivider, MenuGroup, MenuList } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { ActiveIndicator } from 'src/pages/pay/components/filters/components/ActiveIndicator';
import { ActiveIndicatorOverlay } from 'src/pages/pay/components/filters/components/ActiveIndicatorOverlay';
import { ClearAll } from 'src/pages/pay/components/filters/components/ClearAll';
import { FilterSubmenu } from 'src/pages/pay/components/filters/components/FilterSubmenu';
import { FilterMenuKey, filterMenuOptions } from 'src/pages/pay/components/filters/consts';
import { FilterMenuOption, FilterMenuProps } from 'src/pages/pay/components/filters/types';
import { isFilterMenuActive } from 'src/pages/pay/components/filters/utils';
import { menuPlacement } from 'src/pages/pay/components/menu-consts';
import { useTooltipWithMenu } from 'src/pay/components/SortMenu/hooks';
import { FeatureFlags } from 'src/utils/consts';

export const FilterMenu = (props: FilterMenuProps) => {
  const {
    activeFilters = {},
    onSetFilter,
    currentTab,
    onClearAll,
    onClose: onCloseParent,
    onOpen: onOpenParent,
  } = props;

  const [isVendorMenuEnabled] = featureFlags.useFeature(FeatureFlags.PayVendorsMenu, false);
  const options = filterMenuOptions[currentTab].filter((option) => {
    if (option.key === FilterMenuKey.VendorId && !isVendorMenuEnabled) {
      return false;
    }

    return true;
  });

  const isActive = isFilterMenuActive(activeFilters);

  const onChange = useCallback(
    (filterKey: FilterMenuKey, value: string[]) => {
      onSetFilter({
        ...activeFilters,
        [filterKey]: value,
      });
    },
    [activeFilters, onSetFilter]
  );

  const { onCloseMenu: tellTooltipMenuClosed, allowTooltip } = useTooltipWithMenu();
  const intl = useIntl();
  const ariaLabel = useMemo(() => intl.formatMessage({ id: 'pay.filter.ariaLabel' }), []);

  const onCloseMenu = useCallback(() => {
    tellTooltipMenuClosed();
    onCloseParent(activeFilters);
  }, [activeFilters, onCloseParent]);

  const onOpenMenu = useCallback(() => {
    onOpenParent(activeFilters);
  }, [activeFilters, onOpenParent]);

  const getActiveFilters = useCallback((filterKey: FilterMenuKey) => activeFilters[filterKey] || [], [activeFilters]);

  return (
    <Menu placement={menuPlacement} closeOnSelect={false} onClose={onCloseMenu} onOpen={onOpenMenu}>
      <Tooltip
        placement="top"
        label={<MIFormattedText label="pay.filter.btnTooltipLabel" />}
        isDisabled={!allowTooltip}
      >
        <MenuButton
          as={IconButton}
          aria-label={ariaLabel}
          position="relative"
          placement="top"
          icon={
            <>
              <Icon name={IconNames.filter} />
              {isActive && (
                <ActiveIndicatorOverlay>
                  <ActiveIndicator />
                </ActiveIndicatorOverlay>
              )}
            </>
          }
        />
      </Tooltip>
      <MenuList zIndex="dropdown">
        <MenuGroup title="pay.filter.menu.header">
          {options.map(({ key: filterKey, label, render }: FilterMenuOption) => (
            <FilterSubmenu isActive={!!activeFilters[filterKey]?.length} label={label} key={label}>
              {render(
                {
                  onChange: (value) => onChange(filterKey, value),
                  selected: getActiveFilters(filterKey),
                },
                props
              )}
            </FilterSubmenu>
          ))}
          <MenuDivider />
          <ClearAll isDisabled={!isActive} onClick={onClearAll} />
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
