export const ZIP_CODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
export const US_PHONE_NUMBER_REGEX = /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/;
export const TAX_ID_EIN_REGEX = /^\d{2}-\d{7}$/;
export const TAX_ID_SSN_REGEX = /^\d{3}-\d{2}-\d{4}$/;
export const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

const extractNumbersFromString = (value: string) => value.replace(/\D/g, '');

export const convertPhoneToRequestFormat = (phone: string) =>
  phone.replace(/\s/g, '').replace('(', '').replace(')', '-');

export const convertToFormattedTaxIdNumber = (taxIdNumber: string): string => {
  if (!taxIdNumber) {
    return '';
  }

  const numbers = extractNumbersFromString(taxIdNumber);

  return `${numbers.substring(0, 2)}-${numbers.substring(2)}`;
};

export const convertToFormattedPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) {
    return '';
  }

  const numbers = extractNumbersFromString(phoneNumber);

  return `(${numbers.substring(0, 3)}) ${numbers.substring(3, 6)}-${numbers.substring(6)}`;
};
