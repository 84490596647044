import isNil from 'lodash/isNil';
import { getBillsSearchPath } from './bills';
import { BillStatus } from './consts';

const pattern = /\{([0-9a-zA-Z_]+)}/g;

export class Route {
  private _url: string;

  constructor(url: string) {
    this._url = url;
  }

  url(params = {}) {
    return this._url.replace(pattern, (_match, i) => (isNil(params[i]) ? `:${i}` : params[i]));
  }
}
// TODO: move location to be a module with functions and remove class
export class MainNavigator {
  index: Route;
  MainApp: {
    dashboardSingle: Route;
    dashboard: Route;
  };

  constructor() {
    this.index = new Route('/');
    this.MainApp = {
      dashboardSingle: new Route(
        `/orgs/{orgId}/bills?${getBillsSearchPath({ status: BillStatus.UNPAID })}&section=dashboard`
      ),
      dashboard: new Route(`/orgs/{orgId}/bills?${getBillsSearchPath({ status: BillStatus.UNPAID })}`),
    };
  }
}

export default new MainNavigator();
