import keyBy from 'lodash/keyBy';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { defaultOptionRender, OptionType } from 'src/components/common/DropDown/MIDropDown';
import { MIEnhancedDropDown } from 'src/components/common/DropDown/MIEnhancedDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import type { UseModalReturn } from 'src/helpers/react/useModal';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import useUpdateOrganization from 'src/modules/organizations/hooks/useUpdateOrganization';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { usersApi } from 'src/modules/users/api';
import { companiesLocations } from 'src/pages/companies/locations';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { CompanyType, DialogVariants, Role } from 'src/utils/consts';
import { getUserRole } from 'src/utils/user';

type OwnerItem = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  userOrganization: {
    organizationId: string;
  };
};

type OwnersResponseType = {
  owners: OwnerItem[];
};

export function useChangeAccountingFirmModal(organizationId: string): UseModalReturn {
  const [selectedOrgId, setSelectedOrgId] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, any> | null>(null);
  const [isCtaDisabled, setIsCtaDisabled] = useState<boolean>(false);
  const [historyPush] = useHistoryWithOrgId();
  const organizations = useSelector(profileStore.selectors.getOrganizations) || [];
  const currentUser = useSelector(profileStore.selectors.profile);
  const organizationActions = useStoreActions(organizationStore);
  const { updateOrganization } = useUpdateOrganization(organizationId);
  const { onApiCall: getOrganizationsOwners, result: ownersResponse, loading: isOrganizationOwnersLoading } = useApi<
    [string],
    OwnersResponseType
  >({
    api: usersApi.getOrganizationsOwners,
  });

  const roleInSelectedOrg = useMemo(() => {
    const selectedOrg = organizations.find((org) => org.id.toString() === selectedOrgId?.toString())!;

    return getUserRole(currentUser, selectedOrg?.id);
  }, [organizations, currentUser, selectedOrgId]);
  const ownersMap = useMemo(() => keyBy(ownersResponse?.owners, 'userOrganization.organizationId'), [ownersResponse]);
  const accountingFirmOptions = useMemo(
    () =>
      organizations
        .filter((org) => org.companyType === CompanyType.SMB && !!ownersMap[org.id])
        .map((org) => ({
          label: org.companyName ?? '',
          value: org.id,
        })),
    [organizations, ownersMap]
  );

  const onSelectAccountingFirm = (result) => {
    const { value, meta } = result;
    setValidationErrors(null);
    setSelectedOrgId(value);

    if (value && meta?.action === 'create-option') {
      setIsCtaDisabled(true);
    } else {
      analytics.trackAction('select-company-to-switch', { newOrgId: value });
      setIsCtaDisabled(false);
    }
  };

  const confirm = async () => {
    if (!selectedOrgId) {
      setValidationErrors({
        accountingFirm: 'bookkeeper.company.accountingFirmDialog.dropDown.errorMessage',
      });

      return Promise.reject();
    }

    if (roleInSelectedOrg === Role.OWNER) {
      return organizationActions.switchAccountingFirm({ orgId: selectedOrgId });
    }

    await updateOrganization({ companyType: CompanyType.SMB });

    return organizationActions.requestSwitchAccountingFirm({
      orgId: selectedOrgId,
    });
  };

  const goAddNewFirm = () => {
    historyPush({ path: companiesLocations.addAccountingFirm.connectQuickbooks });
  };

  const setInitialState = () => {
    setValidationErrors(null);
    setSelectedOrgId(null);
    setIsCtaDisabled(false);
  };

  const getConfirmText = () =>
    roleInSelectedOrg === Role.OWNER || !roleInSelectedOrg
      ? 'bookkeeper.company.accountingFirmDialog.confirmOwner'
      : 'bookkeeper.company.accountingFirmDialog.confirm';

  const renderOption = (option: OptionType, searchText?: string | null): ReactNode | null => {
    const currentUserRoleInOrg = getUserRole(currentUser, option.value);

    if (currentUserRoleInOrg === Role.OWNER) {
      return defaultOptionRender(option, searchText);
    }

    const org = organizations.find((org) => org.id === option.value)!;
    const ownerUser = ownersMap[org.id];
    const ownerName = `${ownerUser.firstName} ${ownerUser.lastName}`;
    const labelToSearch = `${org.companyName} ${ownerName}`;

    if (labelToSearch.toLowerCase().includes(searchText?.toLowerCase() || '')) {
      return <NotOwnerLabel companyName={org.companyName ?? ''} ownerName={ownerName} />;
    }

    return null;
  };

  const [ChangeAccountingFirm, showChangeAccountingFirm, showing] = useModal(CommonDialog, {
    confirm,
    title: 'bookkeeper.company.accountingFirmDialog.title',
    description: 'bookkeeper.company.accountingFirmDialog.description',
    confirmText: getConfirmText(),
    variant: DialogVariants.SUCCESS,
    hideIcon: true,
    maxWidth: '56rem',
    minHeight: '39.6rem',
    showCancel: false,
    fullWidth: true,
    onOkDisabled: isCtaDisabled,
    modalName: 'accountingFirmDialog',
    onDismiss: setInitialState,
    children: (
      <MIEnhancedDropDown
        id="companyId"
        label="bookkeeper.company.accountingFirmDialog.dropDown.label"
        placeholder="bookkeeper.company.accountingFirmDialog.dropDown.placeholder"
        noOptionsLabel="bookkeeper.company.accountingFirmDialog.dropDown.noOptions"
        value={selectedOrgId}
        onChange={onSelectAccountingFirm}
        options={accountingFirmOptions}
        buttonAction={goAddNewFirm}
        buttonLabel="bookkeeper.company.accountingFirmDialog.dropDown.buttonLabel"
        errorMessage={validationErrors?.accountingFirm}
        viewOnly={isOrganizationOwnersLoading}
        renderOption={renderOption}
        required
      />
    ),
  });

  useEffect(() => {
    if (showing) {
      getOrganizationsOwners(currentUser.id);
    }
  }, [showing, currentUser, getOrganizationsOwners]);

  return [ChangeAccountingFirm, showChangeAccountingFirm, showing];
}

const NotOwnerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NotOwnerHint = styled.span`
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  margin-left: 0.5rem;
`;

const NoOwnerCompanyName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {
  companyName: string;
  ownerName: string;
};

const NotOwnerLabel = ({ companyName, ownerName }: Props) => (
  <NotOwnerContainer>
    <NoOwnerCompanyName>{companyName}</NoOwnerCompanyName>
    <NotOwnerHint>
      <MIFormattedText label="bookkeeper.company.accountingFirmDialog.dropDown.ownerHint" values={{ ownerName }} />
    </NotOwnerHint>
  </NotOwnerContainer>
);
