import { BillFrequency, BillStatus, Currency } from 'src/utils/consts';
import { BillType } from 'src/utils/types';

export function billFactory(initialValues?: Partial<BillType>): BillType {
  return {
    id: '',
    createdAt: '',
    balance: undefined,
    totalAmount: null,
    currency: Currency.USD,
    organization: {
      id: NaN,
      companyName: '',
    },
    createdById: undefined,
    metadata: {
      isDeletable: true,
    },
    origin: '',
    originId: '',
    organizationId: NaN,
    status: BillStatus.UNPAID,
    vendorId: null,
    payments: [],
    files: [],
    dueDate: '',
    originMetaUrl: '',
    lastUpdater: '',
    invoiceDate: new Date(),
    terms: NaN,
    creator: '',
    invoiceNumber: null,
    note: '',
    intuitAccountId: '',
    internalBill: false,
    goodsReceived: null,
    vendor: {
      id: NaN,
      companyName: '',
      deliveryMethods: [],
      contactEmail: '',
      contactPhone: '',
    },
    recurringBill: {
      id: '',
      occurrences: NaN,
      frequency: BillFrequency.MONTHLY,
      dueDate: '',
    },
    occurrences: NaN,
    frequency: BillFrequency.MONTHLY,
    recurringBillId: '',
    recurringBillIndex: NaN,
    partner: undefined,
    externallyPaid: NaN,
    isPaymentRequest: false,

    paymentRequest: null,
    qboSyncVersion: 2,
    scannedInvoiceId: undefined,
    ...initialValues,
  };
}
