import dynamics365SuccessAnimation from 'src/animations/accountingSoftware/sync-dynamics365/success.json';
import dynamics365SyncAnimation from 'src/animations/accountingSoftware/sync-dynamics365/sync.json';
import freshbooksSuccessAnimation from 'src/animations/accountingSoftware/sync-freshbooks/success.json';
import freshbooksSyncAnimation from 'src/animations/accountingSoftware/sync-freshbooks/sync.json';
import qbdSyncSuccessAnimation from 'src/animations/accountingSoftware/sync-qbd/success.json';
import qbdSyncAnimation from 'src/animations/accountingSoftware/sync-qbd/sync.json';
import xeroSyncSuccessAnimation from 'src/animations/accountingSoftware/sync-xero/success.json';
import xeroSyncAnimation from 'src/animations/accountingSoftware/sync-xero/sync.json';
import { AccountingSoftware } from 'src/utils/consts';

export const ANIMATIONS_MAP = {
  [AccountingSoftware.FRESHBOOKS]: {
    syncInProgress: freshbooksSyncAnimation,
    success: freshbooksSuccessAnimation,
  },
  [AccountingSoftware.MICROSOFT_DYNAMICS_365]: {
    syncInProgress: dynamics365SyncAnimation,
    success: dynamics365SuccessAnimation,
  },
  [AccountingSoftware.QUICKBOOKS_DESKTOP]: {
    syncInProgress: qbdSyncAnimation,
    success: qbdSyncSuccessAnimation,
  },
  [AccountingSoftware.XERO]: {
    syncInProgress: xeroSyncAnimation,
    success: xeroSyncSuccessAnimation,
  },
};

export function getSyncAnimation(name: AccountingSoftware, shouldLoop: boolean) {
  const animationData = ANIMATIONS_MAP[name]?.syncInProgress;

  return {
    animationData,
    loop: shouldLoop,
  };
}
