export const formSectionTitleStyle = {
  d: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  textStyle: 'body4Uppercase',
  w: 'full',
  textAlign: 'left',
  color: 'grey.600',
};

export const titleLinkStyle = {
  textDecoration: 'underline',
  cursor: 'pointer',
  color: 'primary.500',
};

export const limitDisclaimerStyle = {
  borderRadius: 8,
  px: 4,
  py: 3,
  backgroundColor: 'grey.300',
  textStyle: 'body4',
  d: 'flex',
  alignItems: 'center',
  columnGap: 2,
  boxSizing: 'border-box',
  justifyContent: 'start',
  w: 'full',
};
