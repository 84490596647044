import { useEffect } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { useTimer } from 'src/hooks/useTimer';
import { AuthCodeVerificationStatus } from 'src/pages/auth/consts';

export const RESEND_TIMER = 30;

type Props = {
  handleContactSupportClick: () => void;
  handleResendCodeClick: () => void;
  shouldHideResendLink: boolean | undefined;
  authVerificationCodeStatus: AuthCodeVerificationStatus;
};

export const AuthCodePageFooter = ({
  handleResendCodeClick,
  handleContactSupportClick,
  shouldHideResendLink = false,
  authVerificationCodeStatus,
}: Props) => {
  const { secondsLeft, setSecondsLeft, startTimer } = useTimer(RESEND_TIMER);

  useEffect(() => {
    checkMaxAttemptsStatus();
  }, [authVerificationCodeStatus]);

  const checkMaxAttemptsStatus = () => {
    if (authVerificationCodeStatus === AuthCodeVerificationStatus.MAX_ATTEMPTS) setSecondsLeft(0);
  };

  return (
    <Box my={4} textStyle="body3">
      {!shouldHideResendLink && (
        <>
          <WizardElements.WizardLinkContainer>
            <MIFormattedText
              label="guests.emailVerify.2FA.sendCodeAgain"
              values={{
                resendLink: (
                  <Box as="span" onClick={startTimer}>
                    <Link
                      disabled={secondsLeft > 0}
                      to={handleResendCodeClick}
                      label="guests.emailVerify.2FA.sendCodeAgainLink"
                    />
                  </Box>
                ),
                counter: secondsLeft > 0 ? `in ${secondsLeft} seconds` : null,
              }}
            />
          </WizardElements.WizardLinkContainer>
          <WizardElements.WizardOrLine />
        </>
      )}
      <WizardElements.WizardLinkContainer>
        <MIFormattedText
          label="guests.emailVerify.2FA.contactSupport"
          values={{
            contactSupportLink: (
              <Link to={handleContactSupportClick} label="guests.emailVerify.2FA.contactSupportLink" />
            ),
          }}
        />
      </WizardElements.WizardLinkContainer>
    </Box>
  );
};

const Link = styled(MILink)`
  color: ${(props) => (props.disabled ? props.theme.colors.primary.grey : props.theme.colors.primary.opaque)};
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-decoration: none;
`;
