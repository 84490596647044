import { FlexProps, forwardRef } from '@chakra-ui/react';
import * as React from 'react';
import styled, { css } from 'styled-components';
import Flex from 'src/core/ds/flex';
import { FONT_WEIGHT } from 'src/core/theme/foundations/textStyles';
import { BadgeSize } from 'src/utils/consts';
import { getNameInitials } from 'src/utils/string-utils';

type Props = {
  size: BadgeSize;
  name?: string | null;
  link?: string | null;
  selected?: boolean | null;
  disabled?: boolean | null;
  color?: string;
  textColor?: string;
  className?: string;
};

export const MIInitialsBadge = ({ name, link, ...props }: Props) => {
  const { size, selected, color, disabled } = props;

  return (
    <Flex
      data-testid="initials-badge"
      {...props}
      __css={{ ...circleStyles(size, selected, color, disabled), ...textStyles(size) }}
    >
      {getNameInitials(name)}
      <BgImage $link={link} />
    </Flex>
  );
};

type MIBadgePlaceholderProps = FlexProps & { size: BadgeSize };

export const MIInitialsBadgePlaceholder = forwardRef<MIBadgePlaceholderProps, 'div'>((props, ref) => {
  const { size } = props;

  return <Flex ref={ref} __css={StyledPlaceholderStyle(size)} {...props} />;
});

const sizesMap = {
  [BadgeSize.X_LARGE]: {
    size: '12rem',
    fontSize: '3.4rem',
  },
  [BadgeSize.LARGE]: {
    size: '4.8rem',
    fontSize: '1.4rem',
  },
  [BadgeSize.MEDIUM]: {
    size: '4.2rem',
    fontSize: '1.4rem',
  },
  [BadgeSize.SMALL]: {
    size: '2.4rem',
    fontSize: '1rem',
  },
  [BadgeSize.X_SMALL]: {
    size: '1.6rem',
    fontSize: '0.8rem',
  },
};

const getBackgroundColor = (color, disabled) => {
  if (color) return color;

  if (disabled) return 'grey.400';

  return 'grey.600';
};

const getTextColor = (color, main) => {
  if (color) return color;

  if (main) return 'black';

  return 'white';
};

const circleStyles = (
  size: BadgeSize,
  selected?: boolean | null,
  color?: string,
  disabled?: boolean | null,
  textColor?: string
) => ({
  height: sizesMap[size].size,
  width: sizesMap[size].size,
  minWidth: sizesMap[size].size,
  lineHeight: 1,
  borderRadius: 'full',
  boxSizing: 'border-box',
  border: selected ? '0.2rem solid ' : '',
  borderColor: selected ? 'green.500' : '',
  bgColor: getBackgroundColor(color, disabled),
  color: getTextColor(textColor, color),
});

const textStyles = (size: BadgeSize) => ({
  fontSize: sizesMap[size].fontSize,
  fontWeight: FONT_WEIGHT.SEMI,
  letterSpacing: 0,
  textTransform: 'uppercase',
  justify: 'center',
  align: 'center',
  textSlign: 'center',
});

const BgImage = styled.div<{
  link?: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  ${(p) =>
    p.$link
      ? css`
          background-image: url(${p.$link});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        `
      : ''};
`;

const StyledPlaceholderStyle = (size: BadgeSize) => ({
  ...circleStyles(size),
  ...textStyles(size),
  ':before': {
    content: size === BadgeSize.X_SMALL ? "'C'" : "'CF'",
  },
});
