import { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { getInvoiceListInfo } from 'src/pages/bill/pay/components/PayBillPaymentReview/components/PayBillDetails/components/InvoiceList/utils';
import { PaymentType } from 'src/utils/types';
import { FieldValue } from '../FieldValue';

type Props = {
  payment: PaymentType;
};

export const InvoiceList = ({ payment }: Props) => {
  const [isBillsCollapsed, setIsBillsCollapsed] = useState(true);
  const { billsToShow, hasInvoiceNumbers } = getInvoiceListInfo(payment, isBillsCollapsed);

  const toggleBillListView = (): void => {
    setIsBillsCollapsed(!isBillsCollapsed);
  };

  return (
    <>
      {billsToShow.map((bill) => {
        const { invoiceNumber } = bill;

        return (
          <FieldValue testId="bill-invoice-number">
            <MIFormattedText
              values={{
                invoiceNumber,
                hasInvoiceNumbers,
              }}
              label="bills.view.bulkInvoiceNumber"
            />
          </FieldValue>
        );
      })}
      {hasInvoiceNumbers ? (
        <Box textStyle="link2" onClick={toggleBillListView} data-testid="invoice-list-collapse">
          <MIFormattedText label="bills.view.collapseBillsListButtonLabel" values={{ isBillsCollapsed }} />
        </Box>
      ) : null}
    </>
  );
};
