import { useRouteMatch } from 'react-router-dom';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import SuccessLink from 'src/images/vendors/success-link-page.svg';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { billLocations } from 'src/pages/bill/locations';
import { CompanyFormPage } from 'src/utils/consts';
import locations from 'src/utils/locations';

const VendorAddedPage = () => {
  const { navigate } = usePreservedStateNavigator();
  const {
    params: { vendorId },
  } = useRouteMatch<{ orgId: string; vendorId: string }>();

  const title = 'onboarding.addVendor.added.title';
  const text = 'onboarding.addVendor.added.text';
  const doneLabel = 'onboarding.addVendor.added.addBill';
  const doneLinkLabel = 'onboarding.addVendor.added.notNow';

  const goToDashboard = () => {
    navigate(locations.MainApp.dashboard.url(), false, {
      origin: CompanyFormPage.ONBOARDING,
    });
  };

  const onNext = () => {
    navigate(billLocations.create.options, false, {
      origin: CompanyFormPage.ONBOARDING,
      id: vendorId,
    });
  };

  return (
    <SuccessLayoutPage
      illustration={SuccessLink}
      title={title}
      text={text}
      buttonLabel={doneLabel}
      buttonAction={onNext}
      linkLabel={doneLinkLabel}
      linkAction={goToDashboard}
    />
  );
};

export default VendorAddedPage;
