import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MICard, MICardForm, MICardHeader, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { getProfileActions, profileStore } from 'src/modules/profile/profile-store';
import { useForm } from 'src/ui/form';
import { InlineSwitchField } from 'src/ui/form/InlineSwitchField';

export const MultipleCompaniesSwitcher: React.FC = () => {
  const { preferences, getOrganizations: organizations } = useStructuredSelectors(profileStore.selectors);
  const actions = getProfileActions(useDispatch());
  const [preferencesMV] = useForm(preferences, {
    submit: actions.updatePreferences,
  });

  const getSwitchLabel = () => {
    if (preferences.allowCompaniesSwitcher) {
      return organizations.length > 1
        ? 'settings.account.multipleCompanies.switchOn.canNotDisable'
        : 'settings.account.multipleCompanies.switchOn.allowDisable';
    }

    return 'settings.account.multipleCompanies.switchOff';
  };

  return (
    <MICard>
      <MICardForm testId="multiple-companies">
        <Container>
          <MICardTitle label="settings.account.multipleCompanies.title" />
          <SwitcherContainer>
            <InlineSwitchField
              disabled={preferencesMV.allowCompaniesSwitcher && organizations.length > 1}
              value={preferencesMV.allowCompaniesSwitcher?.value || organizations.length > 1}
              onChange={preferencesMV.allowCompaniesSwitcher?.changeAndUpdate}
            />
          </SwitcherContainer>
        </Container>
        <Description>
          <MIFormattedText label={getSwitchLabel()} />
        </Description>
      </MICardForm>
    </MICard>
  );
};

export default MultipleCompaniesSwitcher;

const Description = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.subtitle};
  margin-top: 1rem;
`;

const Container = styled(MICardHeader)`
  align-items: baseline;
  margin-top: 0.8rem;
  min-height: unset;
`;

const SwitcherContainer = styled.div`
  line-height: 3.4rem;
  align-self: start;
`;
