import { useMemo } from 'react';
import { CellProps, HeaderProps, Hooks } from 'react-table';
import { HeaderCheckboxCell, RowCheckboxCell } from '../components/CheckboxCell';

export const useSelection = (hooks: Hooks<any>) => {
  const checkBoxColum = useMemo(
    () => ({
      id: 'select_all',
      width: 0.019,
      disableSortBy: true,
      Header: ({ getToggleAllRowsSelectedProps, disableCheckBoxes }: HeaderProps<any>) => (
        <HeaderCheckboxCell isDisabled={disableCheckBoxes} {...getToggleAllRowsSelectedProps()} />
      ),
      Cell: ({ row, disableCheckBoxes }: CellProps<any>) => (
        <RowCheckboxCell isDisabled={disableCheckBoxes} {...row.getToggleRowSelectedProps()} />
      ),
    }),
    []
  );

  return hooks.allColumns.push((columns) => [checkBoxColum, ...columns]);
};
