import map from 'lodash/map';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Table from 'src/core/ds/table';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

export type Header = {
  key: string;
  label: string;
  width: string;
};

export type TableHeaders = Header[];

export type DataTableProps = {
  data: Record<string, any>[];
  headers: TableHeaders;
};

export const MIDataTable = ({ data, headers }: DataTableProps) => (
  <Container>
    <HeaderRow>
      {map(headers, (header) => (
        <HeaderData key={header.key} width={header.width}>
          <MIFormattedText label={header.label} />
        </HeaderData>
      ))}
    </HeaderRow>
    {map(data, (row, idx) => (
      <Row key={idx}>
        {map(headers, (header) => (
          <Data key={header.key} width={header.width} className={FULL_STORY_MASK_RULE_CLASS}>
            {row[header.key]}
          </Data>
        ))}
      </Row>
    ))}
  </Container>
);

type ContainerProps = {
  children?: React.ReactNode;
};

const ContainerStyle: CSSObject = {
  w: 'full',
  tableLayout: 'fixed',
  borderSpacing: 0,
  borderCollapse: 'collapse',
  borderRadius: 0,
  tr: {
    td: {
      color: undefined,
      borderColor: 'grey.400',
    },
  },
};

const Container = ({ children }: ContainerProps) => <Table sx={ContainerStyle}>{children}</Table>;

type HeaderRowProps = {
  children?: React.ReactNode;
};

const HeaderRow = ({ children }: HeaderRowProps) => (
  <Table.Row bgColor="grey.400" borderRight="1px" borderRightColor="grey.400" h="4.4rem" maxW="90rem">
    {children}
  </Table.Row>
);

type HeaderDataProps = {
  width?: string;
  children?: React.ReactNode;
};

const HeaderData = ({ children, width }: HeaderDataProps) => (
  <Table.Cell
    textStyle="body3Semi"
    color="black"
    py={2}
    px={4}
    boxSizing="border-box"
    maxW={width || undefined}
    borderBottom="none"
  >
    {children}
  </Table.Cell>
);

type RowProps = {
  children?: React.ReactNode;
};

const Row = ({ children }: RowProps) => (
  <Table.Row h="4.4rem" maxW="90rem">
    {children}
  </Table.Row>
);

type DataProps = {
  children?: React.ReactNode;
  width?: string;
  className?: string;
};

const Data = ({ children, className, width }: DataProps) => (
  <Table.Cell
    className={className}
    py={2}
    px={4}
    border="1px"
    color="grey.700"
    textStyle="body3"
    overflow="hidden"
    whiteSpace="nowrap"
    textOverflow="ellipsis"
    boxSizing="border-box"
    maxW={width || undefined}
  >
    {children}
  </Table.Cell>
);
