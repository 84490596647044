import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

export const PAGE_EVENTS = {
  paymentRequest: 'payment-request',
  paymentRequestCreateOptions: 'payment-request-create-options',
  paymentRequestManuallyOptions: 'payment-request-create-manually-options',
  paymentRequestConnectAccountingSoftware: 'payment-request-connect-accounting-software',
  paymentRequestDetails: 'payment-request-details',
  paymentRequestSuccess: 'payment-request-success',
};

const MAPPING: EventMappingsTree = {
  '/orgs/*/get-paid/new/options': {
    'page.view': [PAGE_EVENTS.paymentRequestCreateOptions, 'new-request-options'],
    'go.exit': [PAGE_EVENTS.paymentRequestCreateOptions, 'exit'],
    'getPaid.new.create.importInvoicesManualOptions': [
      PAGE_EVENTS.paymentRequestCreateOptions,
      'import-invoices-manually-options',
    ],
    'getPaid.new.create.connectAccountingSoftware': [
      PAGE_EVENTS.paymentRequestCreateOptions,
      'connect-accounting-software',
    ],
    'getPaid.new.create.shareYourCompanyURL': [PAGE_EVENTS.paymentRequestCreateOptions, 'share-company-url'],
    'getPaid.new.create.shareModal.goDashboard': [PAGE_EVENTS.paymentRequestCreateOptions, 'back-to-dashboard'],
    'general.copy': [PAGE_EVENTS.paymentRequestCreateOptions, 'company-url-copied'],
    'general.cancel': [PAGE_EVENTS.paymentRequestCreateOptions, 'cancel'],
  },
  '/orgs/*/get-paid/new/manually': {
    ...mapApiSlice('[INVOICES] UPLOAD_INVOICE_FILE', PAGE_EVENTS.paymentRequestManuallyOptions, 'import-from-file'),
    ...mapApiSlice(
      '[INVOICES] CLEAR_FILE_UPLOAD',
      PAGE_EVENTS.paymentRequestManuallyOptions,
      'cancel-import-from-file'
    ),
    'getPaid.new.create.importInvoicesList': [PAGE_EVENTS.paymentRequestManuallyOptions, 'import-from-csv-clicked'],
    'getPaid.new.create.importInvoiceFile': [PAGE_EVENTS.paymentRequestManuallyOptions, 'import-from-file-clicked'],
    'getPaid.new.create.addInvoiceManually': [PAGE_EVENTS.paymentRequestManuallyOptions, 'enter-manually'],
    'go.exit': [PAGE_EVENTS.paymentRequestManuallyOptions, 'exit'],
    'go.back': [PAGE_EVENTS.paymentRequestManuallyOptions, 'back'],
    'general.cancel': [PAGE_EVENTS.paymentRequestCreateOptions, 'cancel'],
    'page.view': [PAGE_EVENTS.paymentRequest, 'upload-invoice-manually'],
    'bills.new.enterManually': [PAGE_EVENTS.paymentRequestManuallyOptions, 'auto-reader-error-enter-manually'],
  },
  '/orgs/*/get-paid/new/connect-accounting-software': {
    'page-view': [PAGE_EVENTS.paymentRequestConnectAccountingSoftware, 'connect-software'],
    'getPaid.new.create.connect.quickbooksLabel': [PAGE_EVENTS.paymentRequestConnectAccountingSoftware, 'click-qbo'],
    'getPaid.new.create.connect.qbdLabel': [PAGE_EVENTS.paymentRequestConnectAccountingSoftware, 'click-qbd'],
    'go.exit': [PAGE_EVENTS.paymentRequestConnectAccountingSoftware, 'exit'],
    'go.back': [PAGE_EVENTS.paymentRequestConnectAccountingSoftware, 'back'],
    connectQBDModal: [PAGE_EVENTS.paymentRequestConnectAccountingSoftware, 'connect-qbd-modal', { toggle: 'toggle' }],
  },
  '/orgs/*/get-paid/new/invoice': {
    'page.view': [PAGE_EVENTS.paymentRequest, 'new-request'],
    ...mapApiSlice('[INVOICES] CREATE', PAGE_EVENTS.paymentRequestDetails, 'create-payment-request'),
    'go.exit': [PAGE_EVENTS.paymentRequestDetails, 'exit'],
  },
  '/orgs/*/get-paid/new/file-invoice': {
    'page.view': [PAGE_EVENTS.paymentRequest, 'new-request-file'],
    ...mapApiSlice('[INVOICES] UPLOAD_INVOICE_FILE', PAGE_EVENTS.paymentRequestDetails, 'import-from-file'),
    ...mapApiSlice('[INVOICES] CLEAR_FILE_UPLOAD', PAGE_EVENTS.paymentRequestDetails, 'cancel-import-from-file'),
    ...mapApiSlice('[INVOICES] CREATE', PAGE_EVENTS.paymentRequestDetails, 'create-payment-request'),
    'go.exit': [PAGE_EVENTS.paymentRequestDetails, 'exit'],
    'bills.new.enterManually': [PAGE_EVENTS.paymentRequestDetails, 'auto-reader-error-enter-manually'],
  },
  '/orgs/*/get-paid/new/success': {
    'page.view': [PAGE_EVENTS.paymentRequest, 'new-request-success'],
    ...mapApiSlice('[INVOICES] SHARE', PAGE_EVENTS.paymentRequestSuccess, 'share-payment-request'),
    ...mapApiSlice('[INVOICES] MARK_AS_SEND', PAGE_EVENTS.paymentRequestSuccess, 'done-without-sending-customer-email'),
    'go.exit': [PAGE_EVENTS.paymentRequestSuccess, 'exit'],
  },
  '/orgs/*/get-paid/new/share': {
    'page.view': [PAGE_EVENTS.paymentRequest, 'new-request-share'],
    'copy-link': [PAGE_EVENTS.paymentRequest, 'link-copied'],
    'copy-link-action': [PAGE_EVENTS.paymentRequest, 'meliome-link-clicked'],
    ...mapApiSlice('[INVOICES] SHARE', PAGE_EVENTS.paymentRequest, 'share-payment-request'),
    ...mapApiSlice('[INVOICES] MARK_AS_SEND', PAGE_EVENTS.paymentRequest, 'done-without-sending-customer-email'),
  },
};
export default MAPPING;
