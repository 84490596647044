import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { AccountingPlatformAccountId } from 'src/modules/funding-sources/types';
import { PaymentCreateFlowOrigin } from 'src/utils/consts';
import { billsApi } from './api';
import { name } from './consts';

type Params = {
  orgId: number;
  id: string;
  accountingPlatformAccountId?: AccountingPlatformAccountId;
  createOrigin?: PaymentCreateFlowOrigin;
  amount?: number | null;
};

const markAsPaidApi = (params: Params) =>
  billsApi
    .markAsPaid({
      orgId: params.orgId,
      id: params.id,
      accountingPlatformAccountId: params.accountingPlatformAccountId,
      createOrigin: params.createOrigin,
      amount: params.amount,
    })
    .then((response) => response.object);

export const markAsPaidSlice = createUpdateSlice({
  storeName: name,
  api: markAsPaidApi,
  actionName: 'MARK_AS_PAID',
});
