const searchInputStyle = {
  'input[type=search]::-webkit-search-cancel-button': {
    display: 'none',
  },
};

const inputStyle = (hideIcon?: boolean) => ({ ps: hideIcon ? 4 : 12, h: 'fit-content' });

const inputRightElementStyle = { w: 'fit-content', pe: 4, h: 'full' };

const inputLeftElementStyle = { ps: 4, w: 'fit-content', h: 'full' };

export { inputLeftElementStyle, inputRightElementStyle, inputStyle, searchInputStyle };
