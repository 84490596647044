import { PageHeader } from 'src/components/common/PageHeader';
import { InvoiceType } from 'src/modules/invoices/types';
import useIsSinglePage from 'src/pages/get-paid/hooks/useIsSinglePage';
import FailedBatchView from 'src/pages/get-paid/list/components/batchActions/FailedBatchView';
import SentBatchView from 'src/pages/get-paid/list/components/batchActions/SentBatchView';
import UnsentBatchView from 'src/pages/get-paid/list/components/batchActions/UnsentBatchView';
import { getSelectedTab } from 'src/pages/get-paid/utils';
import { PaymentRequestTabs } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { ToNavigationType } from 'src/utils/types';

type Props = {
  invoices: Array<InvoiceType>;
  backLink: ToNavigationType;
};

const ViewBatchInvoices = ({ invoices, backLink }: Props) => {
  let batchView;
  const query = useQueryString();
  const currentTab = getSelectedTab(query);
  const [isSingleView] = useIsSinglePage();
  const { SENT, UNSENT } = PaymentRequestTabs;

  switch (currentTab) {
    case UNSENT:
      batchView = <UnsentBatchView invoices={invoices} />;
      break;
    case SENT:
      batchView = <SentBatchView invoices={invoices} />;
      break;
    default:
      batchView = <FailedBatchView invoices={invoices} />;
  }

  return (
    <>
      {isSingleView && <PageHeader backNav={backLink} label="getPaid.view.batch.mobile.title" />}
      {batchView}
    </>
  );
};

export default ViewBatchInvoices;
