import styled from 'styled-components';
import { devices } from '../../theme/appDevices';
import * as DefaultWizardElements from './WizardElements';

const WizardContainer = styled(DefaultWizardElements.WizardContainer)`
  height: 100%;
  @media ${devices.phablet}, ${devices.mobile} {
    padding: 0 !important;
    align-items: normal !important;
  }
`;

const WizardInner = styled(DefaultWizardElements.WizardInner)`
  overflow-y: auto;
  @media ${devices.phablet} {
    padding: 0rem 4rem 4rem;
    box-sizing: border-box;
  }
  @media ${devices.mobile} {
    padding: 2rem;
    box-sizing: border-box;
  }
`;

const WizardActionsContainer = styled(DefaultWizardElements.WizardActionsContainer)`
  flex-grow: 1;
`;

const WizardStepActionsContainer = styled(DefaultWizardElements.WizardStepActionsContainer)`
  @media ${devices.phablet}, ${devices.mobile} {
    width: 100%;
    padding: 2rem 2rem 0 2rem;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.1);
    width: unset;
    margin: 0;
    background-color: ${(props) => props.theme.colors.white.veryLightGrey};
    bottom: 0px;
    left: 0px;
    right: 0px;
    align-self: auto;
  }
`;

const WizardStepTitle = styled(DefaultWizardElements.WizardStepTitle)`
  @media ${devices.phablet} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitle};
  }
  @media ${devices.mobile} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitleMobile};
    margin-bottom: ${(props) => (props.isSubtitle ? '0.8rem' : '4rem')};
  }
`;

const WizardStepSubTitle = styled(DefaultWizardElements.WizardStepSubTitle)`
  @media ${devices.mobile} {
    font-size: ${(props) => props.theme.text.size.sectionTitle};
    margin-bottom: 4rem;
  }
`;
export {
  InnerWizardContentWrapper,
  LogoContainer,
  SimpleTextFooter,
  SimpleTextFooterSlim,
  WizardButtonContainer,
  WizardFooterContainer,
  WizardFormColumn,
  WizardFormRow,
  WizardIllustration,
  WizardInputs,
  WizardLinkButtonContainer,
  WizardLinkContainer,
  WizardLinkHintContainer,
  WizardOptionLine,
  WizardOrLine,
  WizardRegisterLinkContainer,
  WizardText,
  WizardTitle,
} from './WizardElements';

export {
  WizardActionsContainer,
  WizardContainer,
  WizardInner,
  WizardStepActionsContainer,
  WizardStepSubTitle,
  WizardStepTitle,
};
