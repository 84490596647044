import { useCallback } from 'react';
import * as React from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { MaskField } from 'src/core/ds/form/fields';
import { MicroDepositActions, MicroDepositState } from 'src/pages/settings/hooks/microDepositsCommon';
import { isEnterPressed } from 'src/utils/events';
import { handleInputChange } from 'src/utils/input';

type Props = MicroDepositState & MicroDepositActions;

function onKeyPressed(onSubmit, event: React.KeyboardEvent<any>) {
  if (isEnterPressed(event)) {
    onSubmit();
  }
}

export const MicroDepositsAmountFields = ({
  amount1,
  amount2,
  onChange,
  validationErrors,
  serverError,
  onSubmit,
}: Props) => {
  const amountFormat = '$0.##';

  const keyPressed = useCallback((event) => onKeyPressed(onSubmit, event), [onSubmit]);

  return (
    <Flex onKeyDown={keyPressed}>
      <Box mr={6}>
        <MaskField
          mb={0}
          id="amount1"
          label="settings.microDeposits.verifyDialog.sum1Label"
          placeholder="settings.microDeposits.verifyDialog.placeholder"
          value={amount1}
          autoFocus
          isRequired
          onChange={({ event }: { event: React.SyntheticEvent<HTMLInputElement> }) =>
            handleInputChange({ event, onChange })
          }
          errorMessage={serverError ? ' ' : validationErrors.amount1}
          format={amountFormat}
          allowEmptyFormatting
        />
      </Box>
      <Box>
        <MaskField
          mb={0}
          id="amount2"
          label="settings.microDeposits.verifyDialog.sum2Label"
          placeholder="settings.microDeposits.verifyDialog.placeholder"
          value={amount2}
          isRequired
          onChange={({ event }: { event: React.SyntheticEvent<HTMLInputElement> }) =>
            handleInputChange({ event, onChange })
          }
          errorMessage={serverError ? ' ' : validationErrors.amount2}
          format={amountFormat}
          allowEmptyFormatting
        />
      </Box>
    </Flex>
  );
};
