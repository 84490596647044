import styled, { css } from 'styled-components';
import Flex from 'src/core/ds/flex';

export type DigitsSplitArray = [string[], string[], string[], string[]] | [];

export const AnimatedCardDigits = ({ digitsSplitArray }: { digitsSplitArray: DigitsSplitArray }) => (
  <Flex textStyle="body2" justifyContent="space-between" mt={5} mb={5} letterSpacing="1px">
    {digitsSplitArray.map((digitsGroup, groupIndex) => (
      <DigitsGroup key={`digits-group-${groupIndex}`}>
        <CirclesContainer>
          {digitsGroup.map((_, index) => (
            <Circle key={index} groupIndex={groupIndex} index={index} />
          ))}
        </CirclesContainer>
        {digitsGroup.map((digit, index) => (
          <Digit key={`digit-${groupIndex}-${index}`} groupIndex={groupIndex} index={index}>
            {digit}
          </Digit>
        ))}
      </DigitsGroup>
    ))}
  </Flex>
);

const DIGIT_DELAY_MS = 82;
const INITIAL_DELAY_MS = 416;

type IndicesProps = { groupIndex: number; index: number };

const createDigitAnimation = (props: IndicesProps) => css`
  animation-duration: ${DIGIT_DELAY_MS}ms;
  animation-fill-mode: forwards;
  animation-delay: ${INITIAL_DELAY_MS + props.groupIndex * 4 * DIGIT_DELAY_MS + props.index * DIGIT_DELAY_MS}ms;
`;

const Circle = styled.span<IndicesProps>`
  @keyframes disappear {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  border-radius: 50%;
  background-color: white;
  display: inline-flex;
  width: 6px;
  height: 6px;

  opacity: 1;
  animation-name: disappear;
  ${createDigitAnimation}
`;

const CirclesContainer = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
`;

const DigitsGroup = styled.div`
  position: relative;
`;

const Digit = styled.span<IndicesProps>`
  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  opacity: 0;
  animation-name: appear;

  ${createDigitAnimation}
`;
