import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useSignUpOffer, UseSignUpOfferProps } from '../hooks/useSignUpOffer';

export const SignUpOffer = (props: UseSignUpOfferProps) => {
  const {
    titleLabel,
    subTitleLabel,
    learnMoreLabel,
    shouldShowLearnMore,
    handleLearnMoreClick,
    handleSignUpClick,
    signUpButtonLabel,
  } = useSignUpOffer(props);

  return (
    <Flex
      direction="column"
      bgColor="white"
      py={{ base: 10, sm: 10 }}
      px={{ base: 5, sm: 10 }}
      borderBottomRadius="lg"
      data-testid="sign-up"
    >
      <Box textStyle="h2Semi">
        <MIFormattedText label={titleLabel} />
      </Box>
      <Box textStyle="body2" mt={3} mb={10}>
        <MIFormattedText label={subTitleLabel} />
        {shouldShowLearnMore && (
          <Box as="span" onClick={handleLearnMoreClick} cursor="pointer" ml={1} color="primary.500">
            <MIFormattedText label={learnMoreLabel} />
          </Box>
        )}
      </Box>
      <Button onClick={handleSignUpClick} label={signUpButtonLabel} isFullWidth size={ButtonSizes.lg} />
    </Flex>
  );
};
