import head from 'lodash/head';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import csvExample from 'src/images/vendors/csv-example.png';
import { ACCEPT_FILES } from 'src/utils/consts';
import { NavigationCallbacks } from 'src/utils/types';
import { useFileInput } from 'src/utils/useFileInput';

const BatchUploadExplain = ({ onPrev, goExit, onNext }: NavigationCallbacks) => {
  const handleLoadBatchCustomers = useFileInput(
    (files) => {
      onNext && onNext(head(files));
    },
    { accept: ACCEPT_FILES.TEXT_LISTS, multiple: false }
  );

  return (
    <StepLayoutPage
      title="contacts.batchCsvExplain.title"
      subtitle="contacts.batchCsvExplain.subtitle"
      nextLabel="contacts.batchCsvExplain.action"
      onNext={handleLoadBatchCustomers}
      onPrev={onPrev}
      goExit={goExit}
      fullWidthCTA
    >
      <Image src={csvExample} alt="csv-example" />
    </StepLayoutPage>
  );
};

const Image = styled.img`
  width: 100%;
`;

export default BatchUploadExplain;
