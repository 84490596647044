import { PureComponent } from 'react';
import { MICreateOption } from 'src/components/common/MICreateOption';
import MIFileUpload from 'src/components/common/MIFileUpload';
import Flex from 'src/core/ds/flex';
import { ACCEPT_FILES } from 'src/utils/consts';

type Props = {
  icon?: string | null;
  imgSrc?: string | null;
  label: string;
  description: string;
  accept?: string;
  eventPage: string;
  onSelectFile: (file: File, eventName: string) => void;
  testId?: string;
  linkLabel?: string | null;
  linkAction?: () => void;
};

class MICreateOptionWithFileInput extends PureComponent<Props> {
  static defaultProps = {
    icon: null,
    imgSrc: null,
    accept: ACCEPT_FILES.ALL,
    eventPage: '',
  };

  onChangeInputFile = (file: File) => {
    this.props.onSelectFile(file, this.props.eventPage);
  };

  render() {
    const { accept, eventPage, ...rest } = this.props;
    const allowedTypes = (accept || '').split(',').map((type) => type.trim()) || [];

    return (
      <CreateWithFileWrapper>
        <MIFileUpload onSelectFile={this.onChangeInputFile} allowedTypes={allowedTypes} eventPage={eventPage}>
          <MICreateOption {...rest} />
        </MIFileUpload>
      </CreateWithFileWrapper>
    );
  }
}

type CreateWithFileWrapperProps = {
  children?: React.ReactNode;
};

const CreateWithFileWrapper = ({ children }: CreateWithFileWrapperProps) => (
  <Flex alignItems="center" w="full" maxW="45rem" cursor="pointer">
    {children}
  </Flex>
);

export { MICreateOptionWithFileInput };
