import { useCallback, useEffect } from 'react';
import { usePagination } from 'src/core/components/pagination/hooks/usePagination';
import { Pagination } from 'src/core/components/pagination/Pagination';
import Box from 'src/core/ds/box';
import { BillStatus, PAGINATION } from 'src/utils/consts';
import { Filters } from 'src/utils/types';
import { useQueryParams } from './hooks/useQueryParams';

type Props = {
  itemsCount: number;
  selectedTabBillStatus: BillStatus;
  pathname: string;
  filters: Filters;
};

export const PayListPagination = ({ filters, selectedTabBillStatus, itemsCount }: Props) => {
  const { navigate: navigateToPage } = useQueryParams();

  const setPage = useCallback(
    (pageIndex: number) => {
      navigateToPage({
        start: pageIndex * PAGINATION.DEFAULT_LIMIT,
        limit: PAGINATION.DEFAULT_LIMIT,
        status: selectedTabBillStatus,
      });
    },
    [navigateToPage, selectedTabBillStatus]
  );

  const {
    canNextPage,
    canPreviousPage,
    previousPage,
    nextPage,
    showPagination,
    pageIndex,
    setPaginationState,
  } = usePagination({
    totalItems: itemsCount,
    setPage,
    start: filters.start,
    limit: filters.limit,
  });

  useEffect(() => {
    if (selectedTabBillStatus) {
      setPaginationState(pageIndex);
    }
  }, [pageIndex, selectedTabBillStatus, setPaginationState]);

  return (
    <>
      {showPagination && (
        <Box
          ml="auto"
          float={{
            base: 'left',
            md: 'right',
          }}
          py={4}
          h="4.4rem"
        >
          <Pagination
            pageIndex={pageIndex}
            totalItems={itemsCount}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageItemCount={PAGINATION.DEFAULT_LIMIT}
          />
        </Box>
      )}
    </>
  );
};
