import { EventMappingsTree } from 'src/services/analytics/types';

const pageEvent = 'onboarding-add-vendor';
const successPageEvent = 'onboarding-add-vendor-success-page';

const MAPPING: EventMappingsTree = {
  '/orgs/*/welcome/add-vendor/name': {
    'page.view': [pageEvent, 'biller-suggestions'],
    'go.back': [pageEvent, 'back'],
  },
  '/orgs/*/welcome/add-vendor/account-number/*': {
    'page.view': [pageEvent, 'biller-account-number'],
    'go.back': [pageEvent, 'back'],
  },
  '/orgs/*/welcome/add-vendor/*/added': {
    'page.view': [successPageEvent, 'add-first-vendor-succeeded'],
    'onboarding.addVendor.added.addBill': [successPageEvent, 'add-your-first-bill'],
    'onboarding.addVendor.added.notNow': [successPageEvent, 'skip-first-bill'],
  },
};
export default MAPPING;
