import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getNameParts } from 'src/utils/delivery-methods';
import { DeliveryMethodType } from 'src/utils/types';

const useGetDeliveryMethodDisplayName = (deliveryMethod: DeliveryMethodType | null, noDotsLabel = false): string => {
  const intl = useIntl();
  const displayName = useMemo(() => {
    if (!deliveryMethod) {
      return '';
    }

    const label = noDotsLabel ? 'deliveryMethod.achLabelNoDots' : 'deliveryMethod.achLabel';
    const nameParts = getNameParts(deliveryMethod);

    return intl.formatMessage({ id: label }, nameParts);
  }, [deliveryMethod, intl]);

  return displayName;
};

export default useGetDeliveryMethodDisplayName;
