export enum BrandName {
  quickBooksSymbol = 'Quickbooks',
  freshbooksSymbol = 'Freshbooks',
  masterCardSymbol = 'Mastercard',
  dinersSymbol = 'Diners',
  visaSymbol = 'Visa',
  amexSymbol = 'Amex',
  discoverSymbol = 'Discover',
  xeroSymbol = 'Xero',
  wellsFargoSymbol = 'WellsFargo',
  bankOfAmericaSymbol = 'BankOfAmerica',
  chaseSymbol = 'Chase',
  citiBankSymbol = 'Citi-Bank',
  googleSymbol = 'Google',
  melioSymbol = 'Melio',
  privacySymbol = 'Privacy',
  svbBankSymbol = 'Svb-Bank',
  usBank = 'UsBank',
  creditKey = 'Credit-Key',
  default = 'Default',
}
