import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { IconNames } from 'src/core/ds/icon';
import { Separator } from 'src/core/ds/separator';
import { RefundReviewBaseContainer } from 'src/pages/bill/pay/components/RequestRefundWizard/components/RefundReviewBaseContainer';
import { RefundReviewBaseContainerWithIcon } from 'src/pages/bill/pay/components/RequestRefundWizard/components/RefundReviewBaseContainerWithIcon';
import { PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
  refundRequestedDate: string;
  refundDueDate: string;
};

export const ViewBillPaymentActivityRefund = ({ payment, refundRequestedDate, refundDueDate }: Props) => (
  <Box ml={-6} mb={-6}>
    <RefundReviewBaseContainerWithIcon
      testId="refund-requested-date"
      title="bills.pay.requestRefund.review.requestedDate"
      icon={IconNames.scheduled}
    >
      <MIFormattedDate date={refundRequestedDate} />
    </RefundReviewBaseContainerWithIcon>
    <Box ml={6}>
      <Separator />
    </Box>
    <RefundReviewBaseContainerWithIcon
      testId="refund-eta-date"
      title="bills.pay.requestRefund.review.eta"
      subtitle="bills.pay.requestRefund.review.by8PM"
      icon={IconNames.calendarMove}
    >
      <MIFormattedDate date={refundDueDate} />
    </RefundReviewBaseContainerWithIcon>
    {payment.metadata?.refundReason ? (
      <>
        <Box ml={6}>
          <Separator />
        </Box>
        <RefundReviewBaseContainer
          title="bills.pay.requestRefund.review.reason"
          textStyle="body2"
          testId="payment-refund-reason"
        >
          <MIFormattedText label={`bills.pay.requestRefund.reason.reasons.${payment.metadata?.refundReason}`} />
        </RefundReviewBaseContainer>
      </>
    ) : null}
  </Box>
);
