import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import useUpdateOrganization from 'src/modules/organizations/hooks/useUpdateOrganization';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import { convertToGoogleAddress, convertToServerAddress } from 'src/utils/address';
import { getMissedCompanyInfoFields } from 'src/utils/company';
import { AddressFormControlMode } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { masking } from 'src/utils/masking';
import { GoogleCombinedAddressType } from 'src/utils/types';

const NewCompanyInfoCombinedPage = () => {
  const companyInfo = useSelector(getCompanyInfo);
  const [, historyReplace] = useHistoryWithOrgId();
  const [returnUrl] = useLocationState<string>('returnUrl');
  const missedFields = getMissedCompanyInfoFields(companyInfo);
  const [validationErrors, setValidationErrors] = useState<Record<string, any> | null>(null);
  const [address, setAddress] = useState(convertToGoogleAddress(companyInfo));
  const { updateOrganization, isOrganizationUpdating } = useUpdateOrganization();
  const model = useMemo(
    () => ({
      companyName: companyInfo.companyName || '',
      contactFirstName: companyInfo.contactFirstName || '',
      contactLastName: companyInfo.contactLastName || '',
      phone: companyInfo.phone || '',
    }),
    [companyInfo]
  );

  const onChangeAddress = (address: GoogleCombinedAddressType) => {
    setAddress(address);
    setValidationErrors(null);
  };

  const [companyInfoMV, { submit }] = useForm(model, {
    submit: async (value) => {
      const companyAddress = convertToServerAddress(address);
      const newCompanyInfo = { ...value, ...companyAddress };
      const validationErrors = getValidationErrors('companyInfo', newCompanyInfo);

      if (!isEmpty(validationErrors)) {
        analytics.trackAction('form-validation-error', { validationErrors });
        setValidationErrors(validationErrors);
        throw new ValidationError({ validationErrors });
      } else if (returnUrl) {
        await updateOrganization(newCompanyInfo);
        historyReplace({ path: returnUrl });
      }
    },
  });

  return (
    <StepLayoutPage
      title="combinedCompanyInfo.title"
      subtitle="combinedCompanyInfo.subtitle"
      nextLabel="combinedCompanyInfo.done"
      onNext={submit}
      isLoading={isOrganizationUpdating}
    >
      <StyledWizardFormContainer>
        {(missedFields.includes('contactFirstName') || missedFields.includes('contactLastName')) && (
          <RowContainer>
            <WizardTextInputField
              model={companyInfoMV.contactFirstName}
              label="combinedCompanyInfo.firstNameLabel"
              required
            />
            <WizardTextInputField
              model={companyInfoMV.contactLastName}
              label="combinedCompanyInfo.lastNameLabel"
              required
            />
          </RowContainer>
        )}
        {missedFields.includes('phone') && (
          <WizardTextInputField
            model={companyInfoMV.phone}
            label="combinedCompanyInfo.phoneLabel"
            required
            type="tel"
            mask={masking.USA_PHONE}
          />
        )}
        {missedFields.includes('companyName') && (
          <WizardTextInputField
            model={companyInfoMV.companyName}
            label="combinedCompanyInfo.nameLabel"
            hint="combinedCompanyInfo.nameHint"
            required
          />
        )}
        {missedFields.includes('address') && (
          <MIAddressAutocomplete
            id="address"
            addressLabel="combinedCompanyInfo.addressLabel"
            aptNumberLabel="combinedCompanyInfo.aptNumberLabel"
            address={address}
            onChange={onChangeAddress}
            required
            errorMessage={validationErrors?.googlePlaceId || validationErrors?.zipCode}
            mode={AddressFormControlMode.INLINE}
          />
        )}
      </StyledWizardFormContainer>
    </StepLayoutPage>
  );
};

const StyledWizardFormContainer = styled(WizardFormContainer)`
  margin-top: 2.5rem;
  > div:last-child {
    margin-bottom: 0;
  }
`;

const RowContainer = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 2rem;
  }
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

export default NewCompanyInfoCombinedPage;
