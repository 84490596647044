import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useIsOrganizationInMsnOnly } from 'src/modules/msn-portal/hooks/useIsOrganizationInMsnOnly';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/utils/locations';

export const useSideMenuLogo = () => {
  const orgId = useSelector(getOrgId);
  const isMsnOnlyOrg = useIsOrganizationInMsnOnly();
  const logoNavigationPath = isMsnOnlyOrg
    ? generatePath(msnPortalLocations.base, { orgId })
    : locations.MainApp.dashboard.url({ orgId });

  return { logoNavigationPath };
};
