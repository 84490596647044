import noop from 'lodash/noop';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useEffect, useRef, useState } from 'react';
import dottAnimation from 'src/animations/pay-list/dott.json';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import Tooltip from 'src/core/ds/tooltip';
import { useInboxEmailAddress } from 'src/modules/organizations/hooks/useInboxEmailAddress';

export const CopyEmailAddress = ({
  setAfterCloseMessage,
  shouldShowAnnouncement,
}: {
  setAfterCloseMessage: (afterCloseMessage: () => void) => void;
  shouldShowAnnouncement: boolean;
}) => {
  const animName = 'copy-email-dott';
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<AnimationItem>();
  const [showTooltip, setShowTooltip] = useState<boolean | undefined>(undefined);
  const { onCopyEmail, inboxEmailAddress } = useInboxEmailAddress({ eventPage: 'bills' });
  const [renderAnimationIcon, setRenderAnimationIcon] = useState<boolean>(false);
  const SHOW_TOOLTIP_AFTER_SECONDS = 2;
  const HIDE_TOOLTIP_AFTER_SECONDS = SHOW_TOOLTIP_AFTER_SECONDS + 4;
  const afterCloseMessage = () => {
    try {
      setTimeout(() => setShowTooltip(true), SHOW_TOOLTIP_AFTER_SECONDS * 1000);
      setTimeout(() => setShowTooltip(false), HIDE_TOOLTIP_AFTER_SECONDS * 1000);
      lottieInstance.current?.play();
      // eslint-disable-next-line no-empty
    } catch (_e) {}
  };
  useEffect(() => {
    if (shouldShowAnnouncement) {
      setRenderAnimationIcon(true);
    }
  }, [shouldShowAnnouncement]);
  useEffect(() => {
    setAfterCloseMessage(() => afterCloseMessage);
  }, [setAfterCloseMessage]);
  useEffect(() => {
    if (element.current && dottAnimation && renderAnimationIcon && !lottieInstance.current) {
      lottieInstance.current = lottie.loadAnimation({
        name: animName,
        renderer: 'svg',
        animationData: dottAnimation,
        container: element.current,
        loop: false,
        autoplay: false,
      });

      return () => {
        lottie.destroy(animName);
      };
    }

    return noop;
  }, [dottAnimation, renderAnimationIcon]);

  if (!inboxEmailAddress) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      isOpen={showTooltip}
      maxW="41.4rem"
      label={
        <Box textAlign="left" maxW="min-content">
          <Box>
            <MIFormattedText label="list.tooltips.copyInboxAddress" />
          </Box>
          <Box>{inboxEmailAddress}</Box>
        </Box>
      }
    >
      <IconButton
        aria-label="action"
        variant="actionNaked"
        size="sm"
        minWidth={0}
        icon={
          <>
            <Icon name={IconNames.mailInbox} />
            <Box height="3.2rem" width="3.2rem" margin={-2} ref={element} position="absolute" />
          </>
        }
        onClick={onCopyEmail}
      />
    </Tooltip>
  );
};
