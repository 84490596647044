import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'src/components/common/Loader';
import { MICard, MICardField } from 'src/components/common/MICard';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import { useOrgId } from 'src/hooks';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { BillDetailsWithOpenBalance } from 'src/pages/bill/components/BillDetailsWithOpenBalance';
import { Gallery, GalleryItemsContainer } from 'src/pages/bill/components/BillsGallery/components/Gallery';
import { getUrlByPayment } from 'src/pages/bill/components/ViewBill/utils';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { getPaymentById, isPaymentBulkPayment } from 'src/utils/payments';
import { BillType, PaymentType } from 'src/utils/types';

const eventPage = 'view-payment';

type Props = {
  payment: PaymentType & { bills: BillType[] };
};

export const ViewPaymentMultipleBills = ({ payment }: Props) => {
  const device = useBreak();
  const [currentBillId, setCurrentBillId] = useState<string>('');
  const billIds = payment.bills.map((bill) => bill.id.toString());
  const currentBillIndex = billIds.indexOf(currentBillId);
  const currentIndex = currentBillIndex < 0 ? 0 : currentBillIndex;
  const bill = payment.bills[currentIndex];
  const { navigate } = useNavigator();
  const orgId = useOrgId();
  const isAdmin = useSelector(profileStore.selectors.isAdmin);

  const onLabelClick = async (paymentId) => {
    const payment = getPaymentById(bill.payments, paymentId)!;
    const url = getUrlByPayment(bill, payment, isAdmin, orgId);
    navigate(url);
  };

  const totalAmountField = ({ value }: { value: number | string | null }): React.ReactElement => (
    <MICardField label="bills.form.totalAmount">
      <MIFormattedCurrency value={convertCurrencyToNumber(value)} privateData />
    </MICardField>
  );

  return (
    <>
      {isPaymentBulkPayment(payment) && (
        <Box textStyle="body1Semi" mb={4} mx={{ base: 4, md: 0 }} mt={{ base: 4, md: 0 }}>
          <MIFormattedText
            label="bills.view.bulkPaymentBillsGalleryHeader"
            values={{
              billsCount: payment.bills.length,
            }}
          />
        </Box>
      )}
      {!(device.isMobile || device.isPhablet) ? (
        <Gallery items={billIds} currentIndex={currentIndex} onChange={setCurrentBillId} isFloatingNavigation>
          {bill.id ? (
            <GalleryItemsContainer itemsAmount={payment.bills.length}>
              <BillDetailsWithOpenBalance
                bill={bill}
                paymentId={payment.id.toString()}
                eventPage={eventPage}
                totalAmountField={totalAmountField({ value: bill.totalAmount })}
                onLabelClick={onLabelClick}
              />
            </GalleryItemsContainer>
          ) : (
            <GalleryItemsContainer>
              <Flex h="40rem" alignItems="center">
                <Loader color="primary" context="page" />
              </Flex>
            </GalleryItemsContainer>
          )}
        </Gallery>
      ) : (
        payment.bills.map((bill) => (
          <MICard>
            <BillDetailsWithOpenBalance
              bill={bill}
              paymentId={payment.id.toString()}
              eventPage={eventPage}
              totalAmountField={totalAmountField({ value: bill.totalAmount })}
              onLabelClick={onLabelClick}
            />
          </MICard>
        ))
      )}
    </>
  );
};
