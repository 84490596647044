import { BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import { OurBadgeProps } from 'src/core/ds/badge/Badge';

export enum PayCardBadge {
  Declined = 'declined',
  PartiallyPaid = 'partiallyPaid',
  Pending = 'pending',
  Failed = 'failed',
  Completed = 'completed',
  Refund = 'refund',
}

export const tags: Record<PayCardBadge, OurBadgeProps> = {
  [PayCardBadge.Declined]: {
    label: 'list.group.bill.listItemStatus.declined',
    color: BadgeColors.DANGER,
    variant: BadgeVariants.OUTLINED,
  },
  [PayCardBadge.PartiallyPaid]: {
    label: 'bills.form.paymentActivity.partiallyPaid',
    color: BadgeColors.NEUTRAL,
  },
  [PayCardBadge.Pending]: {
    label: 'list.group.bill.listItemStatus.pendingApproval',
    color: BadgeColors.WARNING,
  },
  [PayCardBadge.Failed]: {
    label: 'list.group.bill.listItemStatus.failed',
    color: BadgeColors.DANGER,
  },
  [PayCardBadge.Completed]: {
    label: 'bills.form.paymentActivity.completedTagLabel',
    color: BadgeColors.SUCCESS,
  },
  [PayCardBadge.Refund]: {
    label: 'list.group.bill.listItemStatus.refund',
    color: BadgeColors.SUCCESS,
    variant: BadgeVariants.OUTLINED,
  },
};
