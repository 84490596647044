import { useEffect } from 'react';
import { useApi } from 'src/hoc/useApi';
import { vendorsApi } from 'src/modules/vendors/api';

export const useFirstVendorPayment = (orgId, vendorId) => {
  const { onApiCall: getIsFirstVendorPayment, result: vendorPayment } = useApi({ api: vendorsApi.getIsFirstPayment });
  const isFirstVendorPayment = vendorPayment?.isFirstPayment;

  useEffect(() => {
    getIsFirstVendorPayment(orgId, vendorId);
  }, [getIsFirstVendorPayment, orgId, vendorId]);

  return [isFirstVendorPayment];
};
