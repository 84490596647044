import { IntlProps, PaymentReportType } from './types';
import {
  calcApprovers,
  calcCheckDepositStatus,
  calcDeliveryMethod,
  calcDisplayName,
  calcFundingSource,
  formatDate,
} from './utils';

const csvHeader = [
  'bills.csvReport.report.title.paymentId',
  'bills.csvReport.report.title.vendorCompanyName',
  'bills.csvReport.report.title.billNumber',
  'bills.csvReport.report.title.paymentAmount',
  'bills.csvReport.report.title.billNote',
  'bills.csvReport.report.title.scheduledDate',
  'bills.csvReport.report.title.deliveryDate',
  'bills.csvReport.report.title.paymentMethod',
  'bills.csvReport.report.title.deliveryMethod',
  'bills.csvReport.report.title.checkSerialNumber',
  'bills.csvReport.report.title.checkStatus',
  'bills.csvReport.report.title.scheduledBy',
  'bills.csvReport.report.title.approvedBy',
  'bills.csvReport.report.title.paymentNote',
  'bills.csvReport.report.title.status',
  'bills.csvReport.report.title.failureNotes',
  'bills.csvReport.report.title.associatedFees',
];
export function buildReport(
  intl: IntlProps,
  payments: PaymentReportType[],
  options: { hasDebitInBankAccounts?: boolean }
) {
  const rows = [csvHeader.map((id) => intl.formatMessage({ id }))];

  payments.forEach((payment) => {
    rows.push([
      payment.id.toString(),
      payment.vendor?.companyName || '',
      payment.bill?.invoiceNumber,
      intl.formatNumber(payment.amount || 0, { minimumFractionDigits: 2 }),
      payment.bill?.note,
      formatDate(payment.scheduledDate),
      formatDate(payment.paidDate || payment.deliveryEta),
      calcFundingSource(intl, payment, options.hasDebitInBankAccounts),
      calcDeliveryMethod(intl, payment.deliveryMethod),
      payment.checkSerialNumber,
      calcCheckDepositStatus(intl, payment),
      calcDisplayName(payment.createdBy),
      calcApprovers({
        approvalDecisionStatus: payment.approvalDecisionStatus,
        paymentApprovalDecisions: payment.paymentApprovalDecisions,
        approvalDecisionBy: payment.approvalDecisionBy,
      }),
      payment.note || '',
      payment.status,
      payment.failureMessage,
      intl.formatNumber(parseFloat(payment.fees || '0'), {
        minimumFractionDigits: 2,
      }),
    ]);
  });

  return rows;
}
