import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { DateTableRowType } from 'src/components/shared/tables/PaymentsTable/types';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { RestListStatus } from 'src/helpers/redux/restListSlice';
import { getProStore } from 'src/modules/get-pro/get-pro-store';
import { getProInProgressPaymentsStore } from 'src/modules/get-pro-in-progress-payments/get-pro-in-progress-payments-store';
import { getProPaidPaymentsStore } from 'src/modules/get-pro-paid-payments/get-pro-paid-payments-store';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import { mapGetProListItemToDateTableRowType } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';
import { GetProListParams, GetProTabsSummary } from '../types';
import { useGetProList } from './useGetProList';

type UseLoadGetProType = {
  loadGetProList: () => Promise<Record<string, any>>;
  data: DateTableRowType[];
  loadStatus: (RestListStatus & { totalSum?: number }) | undefined;
  tabsSummary: Record<GetProTabs, GetProTabsSummary>;
  currentDataLastUpdated: string;
  hasPaymentRequests?: boolean;
  hasAlertsInLast7Days: boolean;
};

const useLoadGetProList = (listParams: GetProListParams): UseLoadGetProType => {
  const invoicesActions = useStoreActions(invoicesStore);
  const paymentRequestsActions = useStoreActions(paymentRequestsStore);
  const inProgressPaymentActions = useStoreActions(getProInProgressPaymentsStore);
  const paidPaymentActions = useStoreActions(getProPaidPaymentsStore);
  const getProActions = useStoreActions(getProStore);
  const {
    items,
    loadStatus,
    tabsSummary,
    currentDataLastUpdated,
    hasPaymentRequests,
    hasAlertsInLast7Days,
  } = useGetProList(listParams);
  const data = useMemo(() => items.map(mapGetProListItemToDateTableRowType), [items]);
  const loadGetProList = useCallback(async (): Promise<Record<string, any>> => {
    const { status, contactId, search } = listParams.filters;
    const today = startOfDay(new Date());

    await Promise.allSettled([
      getProActions.getProTabsSummary({
        orgId: listParams.orgId,
        filters: { search, contactId },
      }),
      getProActions.getAlertsSummary({
        orgId: listParams.orgId,
        filters: {
          search,
          contactId,
          startDate: subDays(today, 7).toISOString(),
          endDate: endOfDay(today).toISOString(),
        },
      }),
    ]);

    if (status === GetProTabs.INVOICES) {
      return invoicesActions.proList(listParams);
    } else if (status === GetProTabs.REQUESTS) {
      return paymentRequestsActions.proList(listParams);
    } else if (status === GetProTabs.IN_PROGRESS) {
      return inProgressPaymentActions.list(listParams);
    }

    return paidPaymentActions.list(listParams);
  }, [invoicesActions, inProgressPaymentActions, paidPaymentActions, paymentRequestsActions, listParams]);

  return {
    loadGetProList,
    data,
    loadStatus,
    tabsSummary,
    currentDataLastUpdated,
    hasPaymentRequests,
    hasAlertsInLast7Days,
  };
};

export default useLoadGetProList;
