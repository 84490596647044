import Box from 'src/core/ds/box';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryType } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { getTranslationValue } from 'src/utils/translations';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  cell: { value: DeliveryMethodType };
};

const getDeliveryLabelMapping = (deliveryMethod: DeliveryMethodType) => ({
  [DeliveryType.ACH]: 'deliveryMethods.notDefinedInfo.ach.label',
  [DeliveryType.CHECK]: 'deliveryMethods.notDefinedInfo.check.label',
  [DeliveryType.VIRTUAL_CARD]: 'deliveryMethods.notDefinedInfo.virtual-card.label',
  [DeliveryType.CARD]: `deliveryMethods.notDefinedInfo.${deliveryMethod?.cardAccount?.cardType}.label`,
});

const getDeliveryDetailsMapping = (deliveryMethod: DeliveryMethodType) => ({
  [DeliveryType.ACH]: deliveryMethod?.bankAccount
    ? ` (...${getAccountNumber4digits(deliveryMethod?.bankAccount)})`
    : '',
  [DeliveryType.VIRTUAL_CARD]: deliveryMethod?.virtualCardAccount?.accountEmail
    ? ` ${deliveryMethod?.virtualCardAccount?.accountEmail}`
    : '',
  [DeliveryType.CARD]: ` (...${deliveryMethod?.cardAccount?.card4digits})`,
  [DeliveryType.CHECK]: '',
});

export const ReceivingMethodCell = ({ cell: { value: deliveryMethod } }: Props) => {
  const deliveryType = deliveryMethod?.deliveryType;

  if (![DeliveryType.ACH, DeliveryType.CARD, DeliveryType.CHECK, DeliveryType.VIRTUAL_CARD].includes(deliveryType)) {
    capture(new Error(`Payments-in table: unknown delivery method ${deliveryType}`));

    return null;
  }

  const methodLabel = deliveryType ? getTranslationValue(getDeliveryLabelMapping(deliveryMethod)[deliveryType]) : '';
  const methodDetails = deliveryType ? getDeliveryDetailsMapping(deliveryMethod)[deliveryType] : '';

  return (
    <Box isTruncated>
      {methodLabel}
      {methodDetails && (
        <Box display="inline" color="grey.600">
          {methodDetails}
        </Box>
      )}
    </Box>
  );
};
