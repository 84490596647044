type Props = {
  showFeeDescription: boolean;
  isVendorAbsorbedCardFee?: boolean;
  orgHasBillingFeeMethod?: boolean;
  textValues?: Record<string, unknown> & { finalAmount?: number };
};

export function getRegularFeeFormattedText({
  showFeeDescription,
  isVendorAbsorbedCardFee,
  orgHasBillingFeeMethod,
  textValues = {},
}: Props) {
  let label = '';

  if (showFeeDescription) {
    label = orgHasBillingFeeMethod
      ? 'bills.pay.confirm.ach-to-check.billingMethodAvailable'
      : 'bills.pay.confirm.feeTerm';
  } else if (isVendorAbsorbedCardFee) {
    label = 'bills.pay.confirm.absorbedFee';
  } else if (textValues?.finalAmount === 0) {
    label = 'bills.pay.confirm.noAdditionalCharges';
  }

  return {
    label,
    values: label ? textValues : {},
  };
}
