import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import { Link } from 'src/core/ds/link';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import partnersIcon from 'src/images/outside/trusted.png';
import partnersIconMobile from 'src/images/outside/trustedMobile.png';
import { BROWSE_ALL_TOPICS_LINK } from 'src/pages/settings/constants';

export const PageFooter = () => {
  const site = useSiteContext();
  const device = useBreak();

  return (
    <Box
      as="span"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pt="4rem"
      pb="2rem"
      px={0}
      color="grey.700"
      textStyle="body4"
      mb={{ base: '5rem', sm: 0 }}
    >
      <MIFormattedText label="vendors.upgradePayment.legacy.partners" />
      <Image
        src={device.isMobile || device.isPhablet ? partnersIconMobile : partnersIcon}
        alt="partners"
        my={4}
        mx={0}
        w={{ base: '32.5rem', md: '53rem' }}
      />
      <Flex>
        <Link
          href={site.config.agreementLinks.privacyPolicy}
          target="_blank"
          rel="noopener noreferrer"
          color="black"
          textDecor="underline"
        >
          <MIFormattedText label="vendors.upgradePayment.legacy.privacyPolicy" />
        </Link>
        <Box as="span" my={0} mx={2}>
          |
        </Box>
        <Link
          href={site.config.agreementLinks.userAgreement}
          target="_blank"
          rel="noopener noreferrer"
          color="black"
          textDecor="underline"
        >
          <MIFormattedText label="vendors.upgradePayment.legacy.termsOfService" />
        </Link>
      </Flex>
      <Box as="span" mt={2}>
        <MIFormattedText
          label="vendors.upgradePayment.legacy.help"
          values={{
            helpCenter: (
              <Link
                href={BROWSE_ALL_TOPICS_LINK}
                target="_blank"
                rel="noopener noreferrer"
                color="grey.700"
                textDecor="underline"
              >
                <MIFormattedText label="vendors.upgradePayment.legacy.helpCenter" />
              </Link>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
