import { AmountVariant } from './consts';

export const getAmountVariant = (numberOfItems: number, isAlert?: boolean) => {
  if (numberOfItems === 0) {
    return AmountVariant.IDLE;
  } else if (isAlert) {
    return AmountVariant.NEGATIVE;
  }

  return AmountVariant.DEFAULT;
};
