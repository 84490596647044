import React from 'react';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ChevronUpIcon } from 'src/images/regular-batch-payments/menu-arrow-up.svg';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { FooterContainer } from './FooterContainer';

type DetailsFooterProps = {
  isLoading: boolean;
  onPrimary: () => void;
  onSecondary: () => void;
};

export const DetailsFooter = ({ isLoading, onPrimary, onSecondary }: DetailsFooterProps) => (
  <FooterContainer>
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            label="spendManagement.pages.dashboard.cardDetails.actions.title"
            variant={ButtonVariants.tertiary}
            rightIcon={<ChevronUpIcon />}
            isLoading={isLoading}
            onClick={() =>
              analytics.track(
                Page.CARD_LIST,
                Context.CARD_DETAILS_DRAWER,
                Event[`MORE_ACTIONS_${isOpen ? 'CLOSED' : 'OPENED'}`]
              )
            }
          />
          <MenuList>
            <MenuItem
              as={Button}
              color="red.500"
              label="spendManagement.pages.dashboard.cardDetails.actions.deleteCard"
              onClick={() => {
                analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.DEACTIVATE_CARD);
                onSecondary();
              }}
              variant={ButtonVariants.error}
            />
            <MenuItem
              as={Button}
              label="spendManagement.pages.dashboard.cardDetails.actions.viewTransactions"
              onClick={() => {
                analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.VIEW_TRANSACTIONS);
                onPrimary();
              }}
            />
          </MenuList>
        </>
      )}
    </Menu>
  </FooterContainer>
);
