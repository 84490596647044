import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import { invoiceWrapper, itemNavLink, paginationContainer } from '../styles';

type Props = {
  numPages: number;
  currentPage: number;
  onNextPageClick: () => void;
  onPrevPageClick: () => void;
};

export const Pagination = ({ numPages, currentPage, onNextPageClick, onPrevPageClick }: Props) => (
  <Box __css={paginationContainer}>
    <Box __css={invoiceWrapper}>
      <Box __css={itemNavLink(currentPage === 1)} onClick={onPrevPageClick}>
        <Icon name={IconNames.caretLeft} />
      </Box>
      <Box textStyle="body3Semi" color="black">
        <MIFormattedText
          label="bills.new.pdfPagination"
          values={{ current: currentPage, total: numPages, page: 'Page' }}
        />
      </Box>
      <Box onClick={onNextPageClick} __css={itemNavLink(currentPage === numPages)}>
        <Icon name={IconNames.caretRight} />
      </Box>
    </Box>
  </Box>
);
