import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { analytics } from 'src/services/analytics';
import { CardLabelTextStyle, CardStyle, DescriptionTextStyle } from './SelectItemCard.styles';

type Props = {
  label: string;
  description: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  id?: string | null;
  testId?: string | null;
  imgSource?: string;
  disabled?: boolean;
  recommended?: boolean;
};

export const SelectItemCard = ({
  label,
  description,
  id,
  testId,
  onClick,
  imgSource,
  disabled,
  recommended,
}: Props) => (
  <Box
    id={id as string}
    data-testid={testId}
    onClick={(event) => {
      if (!disabled) {
        analytics.trackAction(label);
        onClick(event);
      }
    }}
    __css={CardStyle}
  >
    {imgSource && <Image w="4.4rem" mr={5} src={imgSource} />}
    <Flex w="full">
      <Flex w="full" direction="column">
        {recommended && !disabled ? (
          <Box pb={1}>
            <Badge label="general.recommended" variant={BadgeVariants.FILLED} color={BadgeColors.PRIMARY} />
          </Box>
        ) : null}
        <Box __css={CardLabelTextStyle(disabled)}>{label && <MIFormattedText label={label} />}</Box>
        {description && (
          <Box __css={DescriptionTextStyle(disabled)}>
            <MIFormattedText label={description} />
          </Box>
        )}
      </Flex>
    </Flex>
    <Flex opacity={disabled ? 0.3 : 1} align="center" ml={5}>
      <Icon name={IconNames.shevronRight} size={IconSize.lg} color="black" />
    </Flex>
  </Box>
);
