import { featureFlags } from '@melio/shared-web';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ListItemContainer, ListItemContainerProps } from 'src/components/list/ListItemContainer';
import Tooltip from 'src/core/ds/tooltip';
import { PayCardBody } from 'src/pay/components/PayCardBody';
import { PayCardFooter } from 'src/pay/components/PayCardFooter';
import { PayCardHeader } from 'src/pay/components/PayCardHeader';
import { PayCardBadge } from 'src/pay/consts';
import { PayCardFlag, PayItemPaymentType } from 'src/pay/types';
import { getLabels, getTag } from 'src/pay/utils';
import {
  BillFrequency,
  FeatureFlags,
  MiMoneyFlavor,
  PaymentRequestCreateOrigin,
  PaymentRequestTabs,
} from 'src/utils/consts';
import { BillType, PaymentType } from 'src/utils/types';
import { PayCardMissingDetailsBody } from './PayCardMissingDetailsBody';

export interface Props {
  id: string;
  billsCount: number;
  balance: number;
  status: PaymentType['status'];
  dateLabel?: string;
  vendorName?: string | null;
  baseSearch?: string;
  invoiceNumber?: string | null;
  isSelected?: boolean;
  deliveryEta?: string | Date | null;
  query?: Record<string, any>;
  start?: number;
  limit?: number;
  payment?: PayItemPaymentType;
  isRecurringBill?: boolean;
  search?: string;
  displayDate?: string | null;
  frequency?: BillFrequency | null;
  occurrences?: number | null;
  index?: number | null;
  isRequest?: boolean | null;
  isScannedInvoice?: boolean | null;
  isMissingBillDetails?: boolean;
  createOrigin?: PaymentRequestCreateOrigin;
  hasPaymentRequest?: boolean;
  showCheckbox?: boolean;
  setCheckboxMarked?: (id: string, value: boolean) => void;
  isCheckboxMarked?: boolean;
  isCheckboxDisabled?: boolean;
  enableBatchSelection?: boolean;
  disabled?: boolean;
  totalAmount?: number;
  isPartiallyPaidItemOnInboxTab?: boolean;
  blockInternationalVendor?: boolean;
  onClick?: ListItemContainerProps['onClick'];
  billStatus?: BillType['status'];
  flags?: PayCardFlag[];
  labels?: string[];
}

export interface UIProps extends Props {
  tag?: PayCardBadge | null;
  moneyFlavor: MiMoneyFlavor;
}

function PayCardComponent(props: UIProps) {
  const {
    isSelected = false,
    disabled = false,
    onClick,
    blockInternationalVendor,
    createOrigin,
    status,
    isRequest,
    hasPaymentRequest,
    deliveryEta,
    flags,
    isPartiallyPaidItemOnInboxTab,
    totalAmount,
    isMissingBillDetails,
    balance,
    moneyFlavor,
    search,
    isCheckboxDisabled,
  } = props;
  const [hovered, setHovered] = useState(false);
  const labels = getLabels(createOrigin, status, isRequest, hasPaymentRequest);

  const onMouseEnter = () => {
    if (!disabled) {
      setHovered(true);
    }
  };

  const onMouseLeave = () => {
    if (!disabled) {
      setHovered(false);
    }
  };

  return (
    <ListItemContainer
      disabled={disabled}
      isSelected={isSelected}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      testId="bill-list-item-container"
      isCheckboxDisabled={isCheckboxDisabled}
    >
      <Tooltip
        label={<MIFormattedText label="vendors.deliveryMethods.international.billCardTooltip" />}
        shouldWrapChildren
        placement="top"
        isDisabled={!(blockInternationalVendor && disabled)}
      >
        <PayCardHeader {...props} hovered={hovered} />
        {isMissingBillDetails ? (
          <PayCardMissingDetailsBody search={search} balance={balance} moneyFlavor={moneyFlavor} />
        ) : (
          <PayCardBody {...props} />
        )}
      </Tooltip>
      <PayCardFooter
        labels={labels}
        isPartiallyPaidItemOnInboxTab={isPartiallyPaidItemOnInboxTab}
        totalAmount={totalAmount}
        status={status}
        flags={flags}
        deliveryEta={deliveryEta}
      />
    </ListItemContainer>
  );
}

export function PayCard(props: Props) {
  const { payment, flags, isRequest, status, isPartiallyPaidItemOnInboxTab } = props;
  const [isSelfServeRefundEnabled] = featureFlags.useFeature(FeatureFlags.selfServeRefund, false);
  const tag = useMemo(
    () =>
      getTag({
        flags,
        isPartiallyPaidItemOnInboxTab,
        isSelfServeRefundEnabled,
        payment,
      }),
    [payment, flags, isPartiallyPaidItemOnInboxTab, isSelfServeRefundEnabled]
  );
  const flavor = isRequest && status === PaymentRequestTabs.PAID ? MiMoneyFlavor.POSITIVE : MiMoneyFlavor.DEFAULT;

  return <PayCardComponent {...props} tag={tag} moneyFlavor={flavor} />;
}
