import { featureFlags } from '@melio/shared-web';
import { PaymentDatePicker } from 'src/components/common/datepicker/PaymentDatePicker';
import {
  renderDayContentsCustomTodayCutoffOver,
  renderDayContentsCustomWithLabel,
} from 'src/components/common/datepicker/utils';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { DeliveryType, FeatureFlags } from 'src/utils/consts';
import { AccountType, FieldDateType, PaymentRequestType } from 'src/utils/types';

type Props = {
  deliveryDate: Date;
  maxDeliveryDate: Date | null;
  deliveryMethodType: DeliveryType;
  scheduledDate: Date;
  totalAmount: string;
  minDate: Date;
  onNext: () => void;
  onPrev: () => void;
  onChange: ({ id, value }: FieldDateType) => Promise<void>;
  isLoading: boolean;
  paymentRequest: PaymentRequestType;
  selectedFundingSource?: AccountType;
};

export const GuestPaymentDatePage = ({
  deliveryDate,
  deliveryMethodType,
  scheduledDate,
  totalAmount,
  minDate,
  onNext,
  onPrev,
  onChange,
  isLoading,
  paymentRequest,
  maxDeliveryDate,
  selectedFundingSource,
}: Props) => {
  const [isBankCutoffTimesEnabled] = featureFlags.useFeature(FeatureFlags.BanksCutoffTimes, false);
  const [isFastAchBetterExposeEtaEnabled] = featureFlags.useFeature(FeatureFlags.FastAchBetterExposeEta, false);

  const { StepLayout } = useSiteContext().components;

  const dueDate = paymentRequest?.dueDate;

  let renderDayContentsCustom = isBankCutoffTimesEnabled
    ? (day, date) =>
        renderDayContentsCustomTodayCutoffOver(
          day,
          date,
          minDate,
          scheduledDate,
          dueDate,
          deliveryDate,
          isFastAchBetterExposeEtaEnabled
        )
    : undefined;

  if (isFastAchBetterExposeEtaEnabled) {
    renderDayContentsCustom = (day, date) =>
      renderDayContentsCustomWithLabel({
        day,
        date,
        scheduledDate,
        dueDate,
        deliveryDate,
      });
  }

  return (
    <StepLayout
      title="guests.paymentDate.title"
      subtitle={
        isFastAchBetterExposeEtaEnabled ? 'bills.pay.date.subtitleFachVariant' : 'guests.paymentDate.description'
      }
      relativeStep={4 / 6}
      onNext={onNext}
      onPrev={onPrev}
      isLoading={isLoading}
    >
      <PaymentDatePicker
        fundingSource={selectedFundingSource}
        deliveryDate={deliveryDate}
        maxDeliveryDate={maxDeliveryDate}
        deliveryMethodType={deliveryMethodType}
        scheduledDate={scheduledDate}
        totalAmount={totalAmount}
        minDate={minDate}
        dueDate={paymentRequest.dueDate ? new Date(paymentRequest.dueDate) : null}
        onChange={onChange}
        renderDayContentsCustom={renderDayContentsCustom}
        isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
      />
    </StepLayout>
  );
};
