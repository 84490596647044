import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import { ROLE_TAGS } from 'src/pages/team/consts';

export type RoleTag = typeof ROLE_TAGS[keyof typeof ROLE_TAGS];

function mapStatusToTag(role: RoleTag | undefined) {
  switch (role) {
    case ROLE_TAGS.ACCOUNTING_FIRM_OWNER:
    case ROLE_TAGS.ACCOUNTING_FIRM_ADMIN:
      return { color: BadgeColors.NEUTRAL_DARK };
    case ROLE_TAGS.PENDING:
      return { color: BadgeColors.NEUTRAL };
    case ROLE_TAGS.OWNER:
    case ROLE_TAGS.ADMIN:
      return null;
    case ROLE_TAGS.DELETED:
      return { variant: BadgeVariants.OUTLINED, color: BadgeColors.DANGER };
    case ROLE_TAGS.CONTRIBUTOR:
      return { color: BadgeColors.PRIMARY_LIGHT };
    case ROLE_TAGS.ACCOUNTANT:
    default:
      return { variant: BadgeVariants.OUTLINED, color: BadgeColors.PRIMARY_LIGHT };
  }
}

interface Props {
  userRole?: RoleTag;
  testId?: string;
}

export const UserRoleTag = ({ userRole, testId }: Props) => (
  <Badge testId={testId} label={`user.role.${userRole}`} {...mapStatusToTag(userRole)} />
);
