import { generatePath } from 'react-router';
import { useOrgId } from 'src/hooks';
import { useIsMsnOrganizationAccessBlocked } from 'src/modules/msn-portal/hooks/useIsMsnOrganizationAccessBlocked';
import { onboardingLocations } from 'src/pages/onboarding/locations';

type OrgsRouterAccess = {
  isOrgsRouterAllowed: boolean;
  pathToRedirect?: string;
};

export const useOrgsRouterAccess = (): OrgsRouterAccess => {
  const orgId = useOrgId();
  const isMsnOrganizationAccessBlocked = useIsMsnOrganizationAccessBlocked();

  if (isMsnOrganizationAccessBlocked) {
    return {
      isOrgsRouterAllowed: false,
      pathToRedirect: generatePath(onboardingLocations.msnRegistration.claimAccount.deliveryMethodStatusCheck, {
        orgId,
      }),
    };
  }

  return { isOrgsRouterAllowed: true };
};
