import noop from 'lodash/noop';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useForm } from 'src/ui/form';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';

type Props = {
  onExit: () => void;
  onNext: () => void;
};

export const ReceivingMethodManualBankAccountViewPage = ({ onExit, onNext }: Props) => {
  const params = useParams<{ deliveryMethodId: string }>();
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(params.deliveryMethodId));
  const model = useMemo(() => pick(deliveryMethod?.bankAccount, ['routingNumber', 'accountNumber']), [deliveryMethod]);
  const [bankAccountVM] = useForm(model, { submit: async () => noop });

  return (
    <StepLayoutPage
      title="onboarding.deliveryMethods.bank.view.title"
      subtitle="onboarding.deliveryMethods.bank.view.subtitle"
      goExit={onExit}
      onSubmit={onNext}
      nextLabel="progress.prev"
      hideHeader
      fullWidthCTA
    >
      <WizardFormContainer>
        <TextField
          data-private
          model={bankAccountVM.routingNumber}
          label="onboarding.deliveryMethods.bank.routingNumber"
          isRequired
          isReadOnly
        />

        <PasswordField
          data-private
          model={bankAccountVM.accountNumber}
          label="onboarding.deliveryMethods.bank.accountNumber"
          isRequired
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};

const HiddenInput = styled.input`
  display: none;
`;
