import { memo, SyntheticEvent } from 'react';
import { IconButton } from 'src/components/common/IconButton';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { IconNames } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';

type Props = {
  iconSrc: string;
  ctaLabel: string;
  ctaAction: (e: SyntheticEvent<HTMLInputElement>) => void;
  text?: string | null;
  title?: string;
  textValues?: Record<string, any>;
  withPlus?: boolean;
  withoutHeight?: boolean;
  isCtaLoading?: boolean;
  testId?: string | null;
};

const NewEmptyList = memo(
  ({
    iconSrc,
    title,
    text,
    textValues = {},
    ctaAction,
    ctaLabel,
    withPlus,
    withoutHeight,
    isCtaLoading,
    testId,
  }: Props) => (
    <Flex
      data-testid={testId || 'new-empty-list-container'}
      direction="column"
      align="center"
      justify="center"
      h={{
        base: withoutHeight ? 'auto' : 'calc(100vh - 28rem)',
        md: withoutHeight ? 'auto' : 'calc(100vh - 44rem)',
      }}
      minH={{ md: '30rem' }}
    >
      <Image src={iconSrc} w="fit-content" />
      <Flex direction="column" align="center" justify="center" mt={5} mx={0} mb={6}>
        {title && (
          <Box as="p" textAlign="center" mb={2} textStyle="body2Semi">
            <MIFormattedText label={title} />
          </Box>
        )}
        {text && (
          <Box as="p" m={0} color="grey.700" textAlign="center" textStyle="body4">
            <MIFormattedText label={text} values={textValues} />
          </Box>
        )}
      </Flex>
      <Box w="full" mb={6}>
        {isCtaLoading ? (
          <Button isLoading variant={ButtonVariants.primary} size={ButtonSizes.lg} isFullWidth />
        ) : (
          <IconButton
            icon={withPlus ? IconNames.plus : undefined}
            testId={`button-${ctaLabel}`}
            label={ctaLabel}
            onClick={ctaAction}
            variant="inlineIconAndTextPrimary"
            fullWidth
          />
        )}
      </Box>
    </Flex>
  )
);

export default NewEmptyList;
