import { BankDetailsType, InternationalAccountDataType } from 'src/pages/vendor/international-delivery-method/utils';
import { Country, Currency } from 'src/utils/consts';
import { CountryOptionRes } from 'src/utils/ServerTypes';
import { DeliveryMethodType } from 'src/utils/types';
import { fetchRequest, postRequest } from './api';

export const getSupportedCountries = ({
  orgId,
  vendorId,
}: {
  orgId: number;
  vendorId: string;
}): Promise<{ countries: CountryOptionRes[] }> => {
  const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/countriesList`;

  return fetchRequest(url);
};

export const getRequiredFields = ({
  orgId,
  vendorId,
  countryCode,
  currency,
}: {
  orgId: number;
  vendorId: string;
  countryCode: Country;
  currency: Currency;
}): Promise<{ fields: Record<string, string> }> => {
  const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/required-fields/${countryCode.toLowerCase()}/${currency}`;

  return fetchRequest(url);
};

export const getBankDetails = ({
  orgId,
  vendorId,
  type,
  value,
}: {
  orgId: number;
  vendorId: string;
  type: string;
  value: string;
}): Promise<Record<string, BankDetailsType>> => {
  const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international/bank-details`;

  return postRequest(url, { billingType: type, billingValue: value });
};

export const createInternationalDeliveryMethod = ({
  orgId,
  vendorId,
  params,
}: {
  orgId: number;
  vendorId: string;
  params: InternationalAccountDataType;
}): Promise<DeliveryMethodType> => {
  const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-methods/international`;

  return postRequest(url, params);
};
