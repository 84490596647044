import * as React from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

type SingleViewLoadingContainerProps = {
  children: React.ReactNode;
  isEditMode?: boolean;
  className?: string;
};

const SingleViewLoadingContainer = ({ children, isEditMode }: SingleViewLoadingContainerProps) => (
  <Flex
    flexDir="column"
    opacity={1}
    pb={{ base: 0, md: isEditMode ? '7rem' : 0 }}
    pt={{ base: isEditMode ? '7rem' : 0, md: 0 }}
    sx={{ '&.loading': { opacity: 0 } }}
  >
    {children}
  </Flex>
);

type EmptyListButtonContainerProps = {
  children: React.ReactNode;
};

const EmptyListButtonContainer = ({ children }: EmptyListButtonContainerProps) => (
  <Box maxH="5rem" w="full">
    {children}
  </Box>
);

SingleViewLoadingContainer.defaultProps = {};

type CloseButtonContainerProps = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: CloseButtonContainerProps) => (
  <Box onClick={onClick} pos="absolute" top="2rem" right="2rem" w="2.4rem" h="2.4rem" textStyle="h2" cursor="pointer">
    <Icon name={IconNames.close} size={IconSize.lg} />
  </Box>
);

CloseButton.defaultProps = {};

export { CloseButton, EmptyListButtonContainer, SingleViewLoadingContainer };
