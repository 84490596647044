import { featureFlags } from '@melio/shared-web';
import Flex from 'src/core/ds/flex';
import { DataTableRowType, MainTableRowType } from 'src/pages/regular-batch-payments/components/table/types';
import { AddMemoAction } from 'src/pages/regular-batch-payments/table/components/ActionsCell/AddMemoAction';
import { BillDetailsAction } from 'src/pages/regular-batch-payments/table/components/ActionsCell/BillDetailsAction';
import { RemoveFromListAction } from 'src/pages/regular-batch-payments/table/components/ActionsCell/RemoveFromListAction';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { FeatureFlags } from 'src/utils/featureFlags';

type Props = {
  rowHovered: boolean;
  row: DataTableRowType;
};

export const ActionsCell = ({ rowHovered, row }: Props) => {
  const [isBulkPaymentEnabled] = featureFlags.useFeature(FeatureFlags.BulkPayment, false);
  const stopPropagation = (event) => event.stopPropagation();
  const { id, vendor } = row;
  const isDirectPayment = isRppsVendor(vendor);
  const vendorId = vendor.id;

  return (
    <Flex onClick={stopPropagation} justifyContent="flex-end" mr={4}>
      {!isBulkPaymentEnabled && (
        <BillDetailsAction
          bill={(row as MainTableRowType).payment.bills[0]}
          rowHovered={rowHovered}
          payBillFlowUUID={(row as MainTableRowType).payBillFlowUUID}
        />
      )}
      <RemoveFromListAction itemId={id} rowHovered={rowHovered} />
      {!isDirectPayment && (
        <AddMemoAction
          itemId={id}
          rowHovered={rowHovered}
          vendorId={vendorId}
          payBillFlowUUID={(row as MainTableRowType).payBillFlowUUID}
        />
      )}
    </Flex>
  );
};
