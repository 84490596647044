import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type SizeType = 'inline' | 'wizard' | 'none';
type TextAlignType = React.ComponentProps<typeof Box>['textAlign'];

type Props = {
  size?: SizeType;
  errorMessage?: string | null;
  errorMessageValues?: Record<string, any>;
  errorMessageIcon?: React.ReactNode;
  notices?: Array<any>;
  testId?: string | null;
  align?: TextAlignType;
  noticeValues?: Record<string, React.ReactNode>;
};

export const MINotices = ({
  size = 'wizard',
  errorMessage,
  notices = [],
  errorMessageValues = {},
  testId,
  align = 'left',
  errorMessageIcon = null,
  noticeValues = {},
}: Props) => (
  <Box mt={size === 'inline' ? 0 : 3} data-testid={testId}>
    {errorMessage && (
      <ErrorNotice size={size}>
        {errorMessageIcon}
        <MIFormattedText label={errorMessage} values={errorMessageValues} />
      </ErrorNotice>
    )}
    {!errorMessage &&
      notices &&
      notices.map((notice, index) => (
        <Notice key={index} align={align} size={size}>
          {typeof notice === 'string' && <MIFormattedText label={notice} values={{ ...noticeValues }} />}
          {typeof notice !== 'string' && notice}
        </Notice>
      ))}
  </Box>
);

type ErrorNoticeProps = {
  size: SizeType;
  children: React.ReactNode;
};

const ErrorNotice = ({ children, size }: ErrorNoticeProps) => (
  <Flex as="label" color="red.500" textStyle={size === 'inline' ? 'caption1' : 'body3'} alignItems="center">
    {children}
  </Flex>
);

type NoticeProps = {
  size: SizeType;
  align: TextAlignType;
  children: React.ReactNode;
};

const Notice = ({ children, size, align }: NoticeProps) => (
  <Box
    as="label"
    display="block"
    color="grey.600"
    textStyle={size === 'inline' ? 'caption1' : 'body3'}
    textAlign={align}
  >
    {children}
  </Box>
);
