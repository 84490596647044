import { BuyerResponse } from '@melio/spend-management-api-axios-client';
import { fetchRequest, postRequest } from 'src/services/api/api';
import { BuyerCreateParams, BuyerGetParams } from './types';

export const buyersApi = {
  create({ orgId }: BuyerCreateParams) {
    const url = `/orgs/${orgId}/spend/buyers`;

    return postRequest(url).then((res: { buyerId: string }) => res.buyerId);
  },
  get({ orgId }: BuyerGetParams) {
    const url = `/orgs/${orgId}/spend/buyers`;

    return fetchRequest(url).then((res: { buyers: BuyerResponse[] }) => res.buyers);
  },
};
