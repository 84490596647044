import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

export const InstallmentsTitle = ({
  currentInstallmentNumber,
  totalInstallmentAmount,
}: {
  currentInstallmentNumber: number | undefined;
  totalInstallmentAmount: number;
}) => {
  if (!currentInstallmentNumber) return null;

  return (
    <Box
      textStyle="body2Semi"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
      overflow="hidden"
      data-testid="installment-list-card-title"
    >
      <MIFormattedText
        label="list.group.installment.title"
        values={{ index: currentInstallmentNumber, total: totalInstallmentAmount }}
      />
    </Box>
  );
};
