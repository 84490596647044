import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { GetProListParams } from 'src/pages/get-pro/types';
import { name } from './../consts';

type StateType = {
  proIsListRefreshable: {
    loading: boolean;
    error?: Error;
    lastUpdatedAtDate?: string;
  };
};

type PayloadType = {
  lastUpdatedAtDate?: string;
};

export const proRequestsIsListRefreshableSlice = createApiCallSlice<GetProListParams, StateType, PayloadType>({
  initialState: {
    proIsListRefreshable: {
      loading: false,
      error: undefined,
      lastUpdatedAtDate: undefined,
    },
  },
  name: generateSliceName(name, 'get_is_list_refreshable'),
  api: getProApi.requests.checkNewDataExist,
  reducers: {
    [ON_REQUEST](state) {
      state.proIsListRefreshable = {
        loading: false,
        error: undefined,
        lastUpdatedAtDate: undefined,
      };
    },
    [ON_SUCCESS](state, action) {
      state.proIsListRefreshable = {
        loading: false,
        lastUpdatedAtDate: action.payload?.lastUpdatedAtDate,
      };
    },
    [ON_FAILURE](state, action) {
      state.proIsListRefreshable = {
        loading: false,
        error: action.error,
        lastUpdatedAtDate: undefined,
      };
    },
  },
  selectors: {
    lastUpdatedAtDate: (state) => state[name]?.proIsListRefreshable.lastUpdatedAtDate,
  },
});
