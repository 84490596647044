import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { filesApi } from 'src/modules/files/api';
import { getOrgId } from 'src/redux/user/selectors';

export const useUploadFile = (onChanged: ({ file: { id: string } }) => void) => {
  const orgId = useSelector(getOrgId);
  const uploadFile = (file) =>
    filesApi.uploadFile(orgId, file).then((result) => {
      onChanged && onChanged(result);

      return result;
    });
  // FIXME get rid of any
  const { onApiCall: onUploadFile, result: uploadedFileResult, loading } = useApi<[File], any>({ api: uploadFile });

  return [onUploadFile, uploadedFileResult, loading];
};
