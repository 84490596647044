import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useListSearchBarContext, useQuerySearchUpdater } from 'src/hoc/withListSearchBarContext';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { getSelectedTab } from 'src/pages/get-paid/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { useQueryString } from 'src/utils/hooks';

export const useInvoicesListParams = (): [Record<string, any>] => {
  const query = useQueryString();
  const orgId = useSelector(getOrgId);
  const [{ contextSearchFilterValue: search }] = useListSearchBarContext();
  const tab = getSelectedTab(query);
  const { start, limit } = query;
  const filters = { tab, start, limit, search };
  const listParams = useMemo(
    () => ({
      orgId,
      filters,
    }),
    [orgId, tab, start, limit, search]
  );

  return [listParams];
};

export const useLoadInvoicesList = (): [() => void, Record<string, any>] => {
  useQuerySearchUpdater();
  const invoicesActions = useStoreActions(invoicesStore);
  const [listParams] = useInvoicesListParams();
  const refreshInvoicesList = useCallback(() => invoicesActions.list(listParams), [listParams, invoicesActions]);

  return [refreshInvoicesList, listParams];
};
