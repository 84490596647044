import { accountingSoftwareDisplayName } from 'src/pages/settings/components/accounting-software/consts';
import { AccountingSoftware } from './consts';

export type AccountingSoftwareType = {
  code: AccountingSoftware;
  isActive: boolean;
};

type AccountingPlatformDisplayName = {
  displayName: string;
  displayShortName: string;
};

export const accountingSoftwareOptions: AccountingSoftwareType[] = [
  { code: AccountingSoftware.QUICKBOOKS, isActive: true },
];

export const getAccountingSoftwareDisplayName = (accountingSoftwareName: string): AccountingPlatformDisplayName =>
  accountingSoftwareDisplayName[accountingSoftwareName];
