import { generatePath } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import {
  convertPartialPaymentStatusToBillStatus,
  convertTabNameToBillStatus,
  getBillsDefaultFilters,
  serializePaymentId,
} from 'src/utils/bills';
import { BillStatus, DashboardTabs, PaymentApprovalStatus } from 'src/utils/consts';
import { BillType, PaymentType } from 'src/utils/types';

/** link the type, but normalize the weird thing where id can sometimes be string or number */
export type GetUrlBillProperties = Omit<Partial<BillType>, 'id'> & { id: string | number };
export type GetUrlPaymentProperties = Pick<PaymentType, 'id' | 'status' | 'approvalDecisionStatus'>;

export const getUrlByPayment = (
  bill: GetUrlBillProperties,
  payment: GetUrlPaymentProperties,
  isAdmin: boolean,
  orgId: number
) => getPaymentURLInTab({ bill, payment, isAdmin, orgId });

export const getPaymentURLInTab = (params: {
  bill: GetUrlBillProperties;
  payment: GetUrlPaymentProperties;
  isAdmin: boolean;
  orgId: number;
  tabName?: DashboardTabs;
}) => {
  const { bill, payment, isAdmin, orgId, tabName } = params;
  const id = serializePaymentId(bill.id, payment.id);
  const billStatus = tabName
    ? convertTabNameToBillStatus(tabName)
    : convertPartialPaymentStatusToBillStatus(payment.status);

  if (isAdmin && payment?.approvalDecisionStatus === PaymentApprovalStatus.PENDING) {
    return generatePath(billLocations.view, { orgId, id });
  }

  return generatePath(billLocations.filteredView, {
    orgId,
    id,
    ...getBillsDefaultFilters(billStatus),
  });
};

export const getIdAndStatusByPayment = (
  bill: GetUrlBillProperties,
  payment: GetUrlPaymentProperties,
  isAdmin: boolean
) => {
  const status =
    isAdmin && payment?.approvalDecisionStatus === PaymentApprovalStatus.PENDING
      ? BillStatus.UNPAID
      : convertPartialPaymentStatusToBillStatus(payment.status);
  const { start, limit, sorting } = getBillsDefaultFilters(status);

  return {
    id: serializePaymentId(bill.id, payment.id),
    status,
    sorting,
    start,
    limit,
  };
};

export const getUrlByBill = (bill: Pick<BillType, 'id' | 'status'>, orgId: number) => {
  const defaultFilters = getBillsDefaultFilters(bill.status);

  return generatePath(billLocations.filteredView, {
    orgId,
    id: bill.id,
    ...defaultFilters,
  });
};
