import Box from 'src/core/ds/box';
import { coverContainerStyle } from '../../styles';
import { Header } from '../header/Header';
import { CandidatesDetailsFormCard } from './CandidatesDetailsFormCard';

export const CandidatesFormDetailsPage = () => (
  <Box data-testid="vendor-candidates-form-page" h="full">
    <Box __css={coverContainerStyle} />
    <Box position="absolute" w="full" top={0}>
      <Header />
      <CandidatesDetailsFormCard />
    </Box>
  </Box>
);
