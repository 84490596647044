import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { LoadBankAccountResponse } from 'src/pages/receiving-method/ach/types';
import { accountingPlatformsApi } from './api';
import { name } from './consts';

type BankAccountsRequest = {
  orgId: number;
};

type BankAccountsState = {
  accountingPlatformsBankAccounts: {
    bankAccounts?: LoadBankAccountResponse[];
    loading?: boolean;
    error?: Error;
  };
};

type BankAccountsResponse = {
  bankAccounts: LoadBankAccountResponse[];
};

export const BankAccountsListSlice = createApiCallSlice<BankAccountsRequest, BankAccountsState, BankAccountsResponse>({
  initialState: {
    accountingPlatformsBankAccounts: {
      bankAccounts: undefined,
      loading: false,
      error: undefined,
    },
  },
  name: `[${name}] bank accounts`.toUpperCase(),
  api: accountingPlatformsApi.listBankAccounts,
  reducers: {
    [ON_REQUEST](state) {
      state.accountingPlatformsBankAccounts = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      state.accountingPlatformsBankAccounts = {
        loading: false,
        bankAccounts: action.payload.bankAccounts,
      };
    },
    [ON_FAILURE](state, action) {
      state.accountingPlatformsBankAccounts = { loading: false, error: action.error };
    },
  },
  selectors: {
    loading: (state) => state[name].accountingPlatformsBankAccounts?.loading,
    bankAccounts: (state) => state[name].accountingPlatformsBankAccounts?.bankAccounts,
    error: (state) => state[name].accountingPlatformsBankAccounts?.error,
  },
});
