import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getContactActions } from 'src/pages/contacts/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { ModelView, useForm } from 'src/ui/form';
import { ContactsTab } from 'src/utils/consts';
import { VendorType } from 'src/utils/types';

type EmailModelType = {
  id: { email: string };
};

export const useCollectVendorsEmailModal = (
  onSuccess,
  dismiss,
  vendors,
  eventPage
): {
  emailsMV: ModelView<EmailModelType>;
  submit: () => void;
  loading: boolean;
  disabled: boolean;
} => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const contactActions = getContactActions(ContactsTab.VENDORS)(dispatch);

  const onEditVendor = (editedVendor: VendorType) => contactActions.update({ orgId, ...editedVendor });

  const model = useMemo(() => {
    const emails = vendors.reduce((res, item) => {
      res[item.id] = item.contactEmail;

      return res;
    }, {});

    return { ...emails };
  }, [vendors]);

  const [emailsMV, { submit }, , loading] = useForm<EmailModelType>(model, {
    submit: async () => {
      analytics.track(eventPage, 'vendors-email-modal-save');
      const changedVendors = vendors
        .filter((v) => emailsMV[v.id])
        .map((v) => ({ ...v, contactEmail: emailsMV[v.id].value }));

      const changedVendorsWithEmail = changedVendors.filter((vendor) => !isEmpty(vendor.contactEmail));

      if (!isEmpty(changedVendorsWithEmail)) {
        Promise.all(
          changedVendorsWithEmail.map(async (vendor) => {
            await onEditVendor(vendor);
          })
        );

        analytics.track(eventPage, 'vendors-email-modal-save-success', {
          countSavedEmails: changedVendorsWithEmail.length,
        });

        dismiss();
        onSuccess();
      }
    },
    validateOnChange: false,
    validator: (key: string, value: EmailModelType) => {
      if (isEmpty(value)) {
        return undefined;
      }

      const contactEmailValidationError = getValidationErrors('vendor', { contactEmail: value }, ['contactEmail'])
        .contactEmail;

      return contactEmailValidationError || undefined;
    },
  });

  const disabled = useMemo(() => vendors.every((v) => isEmpty(emailsMV[v.id].value)), [vendors, emailsMV]);

  return {
    emailsMV,
    submit,
    loading,
    disabled,
  };
};
