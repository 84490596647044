import head from 'lodash/head';
import { CreateAndLinkBankAccountSlice } from 'src//modules/accounting-platforms/create-and-link-bank-account-slice';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { BankAccountsListSlice } from 'src/modules/accounting-platforms/bank-accounts-slice';
import { ConnectSlice } from 'src/modules/accounting-platforms/connect-slice';
import { syncSummaryFetchSlice } from 'src/modules/accounting-platforms/connected-accounting-platform-slice';
import { syncActivationSlice } from 'src/modules/accounting-platforms/sync-activation-slice';
import { triggerSyncSlice } from 'src/modules/accounting-platforms/trigger-sync-slice';
import { SyncStatus } from 'src/utils/consts';
import { AccountingPlatform } from 'src/utils/types';
import { accountingPlatformsApi } from './api';

const name = 'accountingPlatforms';

const activePlatform = (platform: AccountingPlatform) => platform.isActive;
const connectedStatuses: Array<SyncStatus | undefined> = [
  SyncStatus.STARTED,
  SyncStatus.SYNCED,
  SyncStatus.PENDING,
  SyncStatus.FAILURE,
];

const connectedPlatform = (platform: AccountingPlatform) => connectedStatuses.includes(platform.syncStatus);

const validateAccountingPlatform = async (accountingPlatform: AccountingPlatform) => {
  // TODO: separate isActive and syncStatus once the api is updated
  if (accountingPlatform.isActive === undefined || accountingPlatform.isActive === null) {
    return ['isActive'];
  }

  return null;
};

export const accountingPlatformsStore = createRestfulSlice<AccountingPlatform>({
  name,
  api: {
    list: accountingPlatformsApi.list,
    fetch: accountingPlatformsApi.getSyncStatus,
  },
  persistConfig: {
    whitelist: ['connect'],
  },
  initialState: {
    byId: {},
    connect: {},
  },
  selectors: {
    activePlatform: (state) => head(Object.values<AccountingPlatform>(state[name].byId).filter(activePlatform)),
    connectedPlatform: (state) => head(Object.values<AccountingPlatform>(state[name].byId).filter(connectedPlatform)),
    getByName: (platformName) => (state) =>
      head(
        Object.values<AccountingPlatform>(state[name].byId).filter(
          (ap) => ap.name?.toLowerCase() === platformName?.toLowerCase()
        )
      ),
  },

  validateFunc: validateAccountingPlatform,
  slices: {
    connect: ConnectSlice,
    listBankAccounts: BankAccountsListSlice,
    createAndLinkBankAccount: CreateAndLinkBankAccountSlice,
    fetchSyncSummary: syncSummaryFetchSlice,
    triggerSync: triggerSyncSlice,
    updateSyncActivation: syncActivationSlice,
  },
});
