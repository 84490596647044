import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { accountingSoftwareDisplayName } from 'src/pages/settings/components/accounting-software/consts';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import {
  AccountingPlatformLogo,
  ButtonsContainer,
  CardHeaderWrapper,
  ChatLink,
  DescriptionWrapper,
  SupportLink,
} from './AccountingSoftwareCardElements';

type DisconnectedViewProps = {
  onLink: () => Promise<void>;
  companyName: string;
  accountingSoftwareType: string;
  logo: string;
  shouldShowNotification?: boolean;
};

export const DisconnectedView = ({
  companyName,
  onLink,
  accountingSoftwareType,
  logo,
  shouldShowNotification,
}: DisconnectedViewProps) => {
  const companyInfo = useSelector(getCompanyInfo);
  const [isProcessing, setIsProcessing] = useState(false);
  const { displayName, displayShortName } = accountingSoftwareDisplayName[accountingSoftwareType];

  const onLinkAccountingSoftware = async () => {
    setIsProcessing(true);
    await onLink();
    setIsProcessing(false);

    if (shouldShowNotification) {
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'settings.newAccountingSoftware.SSO.successSync',
        textValues: { accountingSoftwareType: displayShortName },
      });
    }
  };

  return (
    <MICard>
      <MICardForm>
        <AccountingPlatformLogo src={logo} alt="logo" />
        <CardHeaderWrapper>
          <MICardTitle
            label="settings.newAccountingSoftware.SSO.cardTitle"
            values={{ accountingSoftwareType: displayName }}
          />
        </CardHeaderWrapper>
        <Box mt={3}>
          <DescriptionWrapper>
            <MIFormattedText
              label="settings.newAccountingSoftware.SSO.disconnected"
              values={{
                accountingSoftwareAccount: companyName,
                melioAccount: companyInfo.companyName,
                supportEmail: <SupportLink label="settings.newAccountingSoftware.email" />,
                chatWithUs: <ChatLink />,
                accountingSoftwareType: displayShortName,
              }}
            />
          </DescriptionWrapper>
          <ButtonsContainer>
            <Button
              onClick={onLinkAccountingSoftware}
              label="settings.newAccountingSoftware.relink"
              isLoading={isProcessing}
              size={ButtonSizes.lg}
              variant={ButtonVariants.tertiary}
            />
          </ButtonsContainer>
        </Box>
      </MICardForm>
    </MICard>
  );
};
