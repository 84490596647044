import colors from 'src/core/theme/foundations/colors';

export const headerStyle = {
  px: 16,
  zIndex: 20,
  backgroundColor: 'grey.200',
  mt: '4rem',
};

export const pageContentStyle = {
  mt: 6,
  px: 16,
  filter: 'drop-shadow(0px 5px 10px rgba(33, 33, 36, 0.1))',
  zIndex: 19,
  borderTopLeftRadius: 'lg',
  borderTopRightRadius: 'lg',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
};

export const tabsStyle = {
  borderTopLeftRadius: 'lg',
  borderTopRightRadius: 'lg',
};

export const summaryStyle = {
  position: 'sticky',
  top: '4rem',
  zIndex: 19,
  borderTopLeftRadius: 'lg',
  borderTopRightRadius: 'lg',
};

export const scrollHiderStyle = {
  h: 10,
  px: 16,
  bg: 'grey.200',
  zIndex: 19,
  position: 'sticky',
  top: 0,
};

export const noContentStateStyle = {
  width: 'full',
  pt: 0,
  backgroundColor: colors.white,
  marginTop: 0,
  minHeight: '60vh',
};

export const sxForDashboardCard = {
  mt: 4,
  mb: 4,
};
