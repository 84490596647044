import print from 'print-js';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { DrawerBody, DrawerCloseButton, DrawerHeader } from 'src/core/ds/drawer';
import IconButton from 'src/core/ds/iconButton';
import { ReactComponent as NewTabIcon } from 'src/images/get-pro/new-tab.svg';
import { ReactComponent as RightArrow } from 'src/images/get-pro/right-arrow-24.svg';
import { useGetFileUrls } from 'src/modules/files/hooks/useGetFileUrls';
import { InvoiceType } from 'src/modules/invoices/types';
import { CardHeader } from 'src/pages/get-pro/components/cards/CardHeader';
import PdfPreviewCard from 'src/pages/get-pro/components/cards/pdfPreview/PdfPreviewCard';
import { pushNotification } from 'src/services/notifications';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  invoice?: InvoiceType;
  onHeaderClick?: () => void;
  onBackDrawer?: () => void;
};

const InvoicePDFPreviewDrawer = ({ invoice, onHeaderClick, onBackDrawer }: Props) => {
  const location = useLocation<{ showBackButton: boolean }>();
  const showBackButton = location.state?.showBackButton;
  const fileId = invoice?.files?.[0]?.id || '';
  const [fileUrls] = useGetFileUrls(fileId);
  const fileStorageUrl = fileUrls?.fileStorageUrl || '';
  const invoiceNumber = invoice?.invoiceNumber || '';

  // TODO: bring back after file url will be in the same domain
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onPrint = useCallback(
    () =>
      print({
        printable: fileStorageUrl,
        type: 'pdf',
        onError: () =>
          pushNotification({
            type: NotificationCardTypes.ERROR,
            msg: 'getPro.cards.pdfPreview.onPrintError',
          }),
      }),
    [fileStorageUrl]
  );

  return (
    <>
      <DrawerHeader>
        <CardHeader
          companyName={invoice?.customer?.contactName || ''}
          description={`#${invoiceNumber}`}
          onClick={onHeaderClick}
        />

        {fileUrls?.fileStorageUrl && (
          <>
            <IconButton
              size="md"
              icon={<NewTabIcon />}
              as="a"
              href={fileUrls.fileStorageUrl}
              download={`Invoice Number ${invoiceNumber}`}
              ml="auto"
              target="_blank"
              rel="noopener noreferrer"
              mr={5}
              aria-label="open-in-new-tab"
            />
          </>
        )}
        {showBackButton ? (
          <IconButton
            aria-label="right-arrow"
            icon={<RightArrow />}
            onClick={onBackDrawer}
            justifyContent="flex-end"
            size="md"
          />
        ) : (
          <DrawerCloseButton size="md" />
        )}
      </DrawerHeader>
      <DrawerBody pb={10}>
        <PdfPreviewCard fileUrls={fileUrls} />
      </DrawerBody>
    </>
  );
};

export default InvoicePDFPreviewDrawer;
