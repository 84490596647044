import { CommonDialog } from 'src/ui/dialog/CommonDialog';

type Props = {
  userFullName: string;
  onCancel: () => void;
  onConfirm: () => Promise<any>;
};

export const DeleteContributorModalMessage = ({ userFullName, onCancel, onConfirm }: Props) => (
  <CommonDialog
    confirm={onConfirm}
    title="user.deleteDialog.title"
    description="user.deleteDialog.description"
    confirmText="user.deleteDialog.confirm"
    dismiss={onCancel}
    textValues={{
      fullName: userFullName,
    }}
  />
);
