import { PureComponent } from 'react';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  onPrev: () => void;
  onNext: () => void;
  onChange: (address: GoogleCombinedAddressType) => void;
  address?: GoogleCombinedAddressType;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
  goToManualAddress: () => void;
};

class VendorCompanyAddressForm extends PureComponent<Props> {
  static defaultProps = {
    address: null,
    isLoading: false,
    validationErrors: {},
  };

  render() {
    const { address, onChange, onNext, onPrev, isLoading, validationErrors, goToManualAddress } = this.props;

    return (
      <StepLayoutPage
        title="onboarding.vendorCompanyInfo.address.title"
        relativeStep={2 / 10}
        onPrev={onPrev}
        onNext={onNext}
        isLoading={isLoading}
      >
        <MIAddressAutocomplete
          id="address"
          addressLabel="onboarding.vendorCompanyInfo.address.addressInputLabel"
          aptNumberLabel="onboarding.vendorCompanyInfo.address.aptNumberInputLabel"
          address={address}
          onChange={onChange}
          goToManualAddress={goToManualAddress}
          required
          autoFocus
          errorMessage={validationErrors.googlePlaceId || validationErrors.zipCode}
          privateData
          showAddManualAddress
        />
      </StepLayoutPage>
    );
  }
}

export default VendorCompanyAddressForm;
