import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { Form } from 'src/core/ds/form';
import { TextField } from 'src/core/ds/form/fields';
import { Stack } from 'src/core/ds/stack';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { Header } from 'src/pages/vendor/components/VendorLayoutElements';
import { ContentWrapper } from '../../components/ContentWrapper';
import { Footer } from '../../components/Footer';
import { eventType } from '../../consts';
import { useSuvcToAchAnalytics } from '../../hooks/useSuvcToAchAnalytics';
import { DeliveryMethodStateType, TokenValuesType } from '../../types';

type Props = {
  tokenValues: TokenValuesType;
  dmState: DeliveryMethodStateType;
  onFillBankDetails: () => void;
};

const eventPage = 'bank-account-details';

export const AddAchMethodFormPage = ({ dmState, onFillBankDetails, tokenValues }: Props) => {
  const { paymentId, orgId } = tokenValues;
  const { pageEvent } = useSuvcToAchAnalytics(tokenValues);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { validateBankAccount, deliveryMethodMV, isLoading } = dmState;
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id || orgId));
  const invoiceNumber = payment?.bill?.invoiceNumber;

  const onSubmit = async () => {
    const isOk = await validateBankAccount();
    isOk && onFillBankDetails();
  };

  useEffect(() => {
    if (payment) {
      pageEvent(eventType, eventPage);
    }
  }, [payment, pageEvent]);

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout isExperimentalView>
      <ContentWrapper>
        <Header hideInvoice showFullHeader organization={organization}>
          <Box textStyle="body3" mt="2" color="grey.700" fontSize="1.6rem" textAlign={{ base: 'center', md: 'left' }}>
            <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.infoSubtitle" />
          </Box>
          <Box mt="4" textStyle="display2">
            <MIFormattedCurrency value={(payment?.amount || 0).toString()} currency="$" />
          </Box>
          {invoiceNumber && (
            <Box textStyle="body3" mt="4" color="grey.700">
              <MIFormattedText
                label="vendors.deliveryMethods.shiftSuvcToAch.invoiceNumber"
                values={{
                  invoiceNumber: payment?.bill?.invoiceNumber,
                }}
              />
            </Box>
          )}
        </Header>
        <Box bg="white" p={{ md: '3.2rem 4rem 3rem 4rem', base: '3rem 1.6rem' }}>
          <Box
            textStyle="body2Semi"
            color="black"
            textAlign={{ base: 'center', md: 'left' }}
            data-testid="suvc.form.subtitle"
          >
            <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.fillAccountDetails" />
          </Box>
          <Form onSubmit={onSubmit}>
            <Stack
              data-testid="suvc-ach.form"
              mt="8"
              mb={{ base: '10', sm: '2' }}
              direction={{ base: 'column', sm: 'row' }}
              spacing={{ base: '1', sm: '6' }}
            >
              <TextField
                label="vendors.addDeliveryMethodByLink.routingNumber"
                model={deliveryMethodMV.bankAccount.routingNumber}
                type="tel"
                size="md"
                autoFocus
                isRequired
                data-testid="suvc-ach.vendorRoutingNumber"
              />

              <TextField
                label="vendors.addDeliveryMethodByLink.accountNumber"
                model={deliveryMethodMV.bankAccount.accountNumber}
                type="tel"
                size="md"
                isRequired
                data-testid="suvc-ach.vendorAccountNumber"
              />
            </Stack>
            <button type="submit" hidden />
          </Form>

          <Button
            label="vendors.deliveryMethods.shiftSuvcToAch.continue"
            onClick={onSubmit}
            data-testid="suvc-ach.continueButton"
            isLoading={isLoading}
            size={ButtonSizes.lg}
            isFullWidth
          />
          <Footer companyName={organization?.companyName} />
        </Box>
      </ContentWrapper>
    </OutsideLayout>
  );
};
