import * as React from 'react';
import BillOpenBalanceInfo from 'src/components/list/BillOpenBalanceInfo/BillOpenBalanceInfo';
import { InvoiceType } from 'src/modules/invoices/types';
import { InvoiceModelDataType } from 'src/pages/get-pro/types';
import { ModelView } from 'src/ui/form';
import { isBillHasPartialPayments } from 'src/utils/bills';
import { ContactType, FileUrlsPayloadType } from 'src/utils/types';
import CardBody from '../CardBody';
import { InvoiceInfo } from './InvoiceInfo';
import { PaymentInfo } from './PaymentInfo';

type Props = {
  invoice?: InvoiceType;
  isMarkedAsPaid: boolean;
  vendorHandle: string;
  invoiceMV: ModelView<InvoiceModelDataType>;
  inEditMode: boolean;
  uploading: boolean;
  loading: boolean;
  loadingFileUrls: boolean;
  fileUrls?: FileUrlsPayloadType | null;
  customers: ContactType[];
  remove: (removeInvoice?: boolean) => Promise<void>;
  onEditToggle: () => void;
  onMarkInvoiceAsPaid: (value: boolean) => void;
  deleteFile: () => void;
  uploadFile: (file: File) => Promise<void>;
  openPdfPreview?: () => void;
  isMSNPortal: boolean;
};

export const PaymentCard: React.FC<Props> = ({
  invoice,
  isMarkedAsPaid,
  vendorHandle,
  invoiceMV,
  inEditMode,
  uploading,
  loading,
  loadingFileUrls,
  fileUrls,
  customers,
  remove,
  onEditToggle,
  onMarkInvoiceAsPaid,
  deleteFile,
  uploadFile,
  openPdfPreview,
  isMSNPortal,
}) => (
  <>
    <CardBody withoutBorder data-testid="bill-details">
      <PaymentInfo
        invoice={invoice}
        isMSNPortal={isMSNPortal}
        remove={remove}
        onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
        isMarkedAsPaid={isMarkedAsPaid}
        vendorHandle={vendorHandle}
        invoiceMV={invoiceMV}
        inEditMode={inEditMode}
        uploading={uploading}
        onEditToggle={onEditToggle}
      />
      <InvoiceInfo
        invoice={invoice}
        invoiceMV={invoiceMV}
        inEditMode={inEditMode}
        uploading={uploading}
        loading={loading}
        onEditToggle={onEditToggle}
        onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
        remove={remove}
        loadingFileUrls={loadingFileUrls}
        deleteFile={deleteFile}
        uploadFile={uploadFile}
        customers={customers}
        fileUrls={fileUrls}
        openPdfPreview={openPdfPreview}
      />
    </CardBody>
    {invoice?.bill && isBillHasPartialPayments(invoice.bill) && (
      <BillOpenBalanceInfo isPartialPayments bill={invoice.bill} showLabel={false} fullWidth />
    )}
  </>
);
