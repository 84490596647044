import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import compact from 'lodash/compact';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { useModal } from 'src/helpers/react/useModal';
import { useToggle } from 'src/helpers/react/useToggle';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import useGetCustomersList from 'src/modules/customers/hooks/useGetCustomersList';
import { useGetFileUrls } from 'src/modules/files/hooks/useGetFileUrls';
import { useUploadFile } from 'src/modules/files/hooks/useUploadFile';
import { InvoiceType } from 'src/modules/invoices/types';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import GetProModalContext from 'src/pages/get-pro/components/table/GetProModalContext';
import { INITIATED_BY } from 'src/pages/get-pro/consts';
import { getProLocations } from 'src/pages/get-pro/locations';
import { InvoiceModelDataType } from 'src/pages/get-pro/types';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { getOrgId, getOwnedVendorHandle } from 'src/redux/user/selectors';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { useForm } from 'src/ui/form';
import { ButtonsDirections, DialogVariants, GetProTabs, InvoiceStatus } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { encodeQuery } from 'src/utils/query-utils';

type Props = {
  invoice?: InvoiceType;
  editMode?: boolean;
  tab: GetProTabs;
};

const useCardActionsAndState = ({ invoice, tab, editMode = false }: Props) => {
  const query = useQueryString();
  const location = useLocation();
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const [inEditMode, onEditToggle] = useToggle(editMode);
  const isMarkedAsPaid = !isEmpty(invoice?.markedAsPaidAt);
  const [customers] = useGetCustomersList();
  const vendorHandle = useSelector(getOwnedVendorHandle);
  const actions = useStoreActions(MAP_STORE_BY_TAB[tab]);
  const { showModal } = useContext(GetProModalContext);
  const isMsnDashboardDisplay = useIsMsnDashboardDisplay();
  const invoiceId = invoice?.id || '';
  const customerName = invoice?.customer?.contactName || '';
  const totalAmount = invoice?.totalAmount?.toString() || '0';
  const isInitiatedByCustomer = invoice?.initiatedBy === INITIATED_BY.CUSTOMER && !invoice?.paymentRequest;

  const modelData = useMemo<InvoiceModelDataType>(
    () => ({
      id: invoiceId,
      totalAmount: invoice?.totalAmount?.toString() || '0',
      invoiceNumber: invoice?.invoiceNumber,
      dueDate: invoice?.dueDate,
      customerName: invoice?.customer?.contactName,
      customerEmail: invoice?.customer?.contactEmail,
      customerNote: invoice?.customerNote,
      files: (invoice?.files || []).map((file) => file.id),
    }),
    [invoice, invoiceId]
  );

  const onUpdate = async (data: Partial<InvoiceModelDataType>) => {
    const { payload } = await actions.proUpdate({
      orgId,
      id: invoice?.id,
      ...data,
    });
    await actions.proFetch({ orgId, id: payload?.id });

    historyPush({
      path: getProLocations.view,
      query: {
        ...query,
        id: payload?.id,
      },
    });
  };

  const openPdfPreview = useCallback(() => {
    historyPush({
      path: getProLocations.invoicePdfPreview,
      query: {
        ...query,
        id: invoiceId,
      },
      state: {
        returnLocations: location,
        showBackButton: true,
      },
    });
  }, [historyPush, query, invoiceId, location]);

  const onRemove = async () => {
    await actions.proDelete({
      orgId,
      id: invoice?.id,
    });
  };

  const [invoiceMV, { submit, cancel: onCancel }, , loading] = useForm(modelData, {
    submit: async (value) => onUpdate(value),
    onClear: onEditToggle,
  });

  const fileId = first(invoiceMV.files.value);
  const [fileUrls, loadingFileUrls] = useGetFileUrls(fileId);
  const [uploadFile, , uploading] = useUploadFile((fileResults) => {
    invoiceMV.files.onChange({
      value: compact([fileResults?.file?.id]),
    });
  });

  const onSend = () => {
    const search = encodeQuery(query, ['id', 'statuses', 'filter', 'sort']);
    const returnLocations = `${generatePath(getProLocations.base, { orgId })}?${search}`;

    historyPush({
      path: getPaidLocations.create.share,
      query: { id: invoiceId || '', status: tab },
      state: { returnLocations },
    });
  };

  const markAsPaidRequest = useCallback(async () => {
    const onMarkAsPaid = async () => {
      await actions.proMarkAsPaid({
        orgId,
        id: invoiceId,
      });
      await historyPush({
        path: getProLocations.dashboard,
        query: {
          status: tab,
        },
      });
    };
    showModal &&
      showModal({
        confirm: onMarkAsPaid,
        title: 'getPro.confirmationModals.markedAsPaidRequest.title',
        description: 'getPro.confirmationModals.markedAsPaidRequest.description',
        confirmText: 'getPro.confirmationModals.markedAsPaidRequest.confirm',
        cancelText: 'getPro.confirmationModals.markedAsPaidRequest.cancel',
        hideIcon: true,
        minHeight: '31.2rem',
        buttonsDirection: ButtonsDirections.HORIZONTAL,
        variant: DialogVariants.SUCCESS,
        textValues: {
          amount: totalAmount,
          customerName,
        },
      });
  }, [actions, historyPush, totalAmount, customerName, invoiceId, orgId, showModal]);

  const markAsPaid = useCallback(async () => {
    await actions.proMarkAsPaid({
      orgId,
      id: invoiceId,
    });
    await historyPush({
      path: getProLocations.dashboard,
      query: {
        status: tab,
      },
    });
  }, [actions, historyPush, invoiceId, orgId]);

  const markAsUnpaid = useCallback(async () => {
    await actions.markAsUnpaid({
      orgId,
      id: invoiceId,
    });
    historyPush({
      path: getProLocations.dashboard,
      query: {
        status: GetProTabs.INVOICES,
      },
    });
  }, [actions, historyPush, invoiceId, orgId]);

  const deleteFile = () => {
    invoiceMV.files.onChange({ value: [] });
  };

  const [EditConfirmMessage, onEditConfirm] = useModal(CommonDialog, {
    confirm: submit,
    variant: DialogVariants.SUCCESS,
    title: 'getPaid.edit.changeNotificationMessage.title',
    description: 'getPaid.edit.changeNotificationMessage.description',
    confirmText: 'getPaid.edit.changeNotificationMessage.confirm',
    cancelText: 'getPaid.edit.changeNotificationMessage.discard',
    textValues: { customerName: invoiceMV?.customerName?.value },
    hideIcon: true,
  });

  const onSubmit = () => {
    const validationErrors = getValidationErrors('paymentRequest', mapValues(invoiceMV, 'value'));

    if (
      invoice?.status === InvoiceStatus.PENDING &&
      !isEmpty(invoiceMV?.customerEmail?.value) &&
      isValidationOk(validationErrors)
    ) {
      onEditConfirm();
    } else {
      submit();
    }
  };

  const onCancelPending = useCallback(
    async (shouldDelete: boolean) => {
      await actions.proCancelPending({ orgId, id: invoiceId, shouldDelete });
      const { id: _, ...rest } = query;
      await historyPush({
        path: isMsnDashboardDisplay ? msnPortalLocations.base : getProLocations.dashboard,
        query: rest,
      });
    },
    [actions, historyPush, invoiceId, isMsnDashboardDisplay, orgId, query]
  );

  const onCancelPendingConfirmation = useCallback(async () => {
    if (isInitiatedByCustomer) {
      onCancelPending(false);
    } else {
      showModal &&
        showModal({
          confirm: onCancelPending,
          title: 'getPro.confirmationModals.removePendingPayment.title',
          description: 'getPro.confirmationModals.removePendingPayment.description',
          confirmText: 'getPro.confirmationModals.removePendingPayment.confirm',
          cancelText: 'getPro.confirmationModals.removePendingPayment.cancel',
          checkbox: {
            value: false,
            label: 'getPro.confirmationModals.removePendingPayment.checkboxLabel',
          },
        });
    }
  }, [isInitiatedByCustomer, onCancelPending, showModal]);

  return {
    onCancel,
    cancelPending: onCancelPendingConfirmation,
    onSend,
    onRemove,
    onSubmit,
    deleteFile,
    onEditToggle,
    markAsPaid,
    markAsPaidRequest,
    markAsUnpaid,
    openPdfPreview,
    vendorHandle,
    isMarkedAsPaid,
    customers,
    uploadFile,
    fileUrls,
    uploading,
    invoiceMV,
    loadingFileUrls,
    loading,
    inEditMode,
    EditConfirmMessage,
  };
};

export default useCardActionsAndState;
