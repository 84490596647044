import Highlighter from 'react-highlight-words';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useQueryParams } from 'src/pages/pay/hooks/useQueryParams';
import { scrollToGalleryEvent } from 'src/pages/payment/components/ViewPayment';
import { BillStatus } from 'src/utils/consts';
import { UIProps } from './PayCard';

export function PayCardBody({
  id,
  search,
  dateLabel,
  displayDate,
  isRecurringBill,
  frequency,
  index,
  occurrences,
  invoiceNumber,
  moneyFlavor,
  balance,
  status,
  billsCount,
}: UIProps) {
  const { navigate } = useQueryParams();

  const scrollToGallery = (event) => {
    event.stopPropagation();
    navigate({ id });

    requestAnimationFrame(() => {
      const event = new Event(scrollToGalleryEvent);
      document.dispatchEvent(event);
    });
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box display={{ base: 'inline-block' }} minW="0">
        <Box textStyle="body4Semi" data-testid="list-item-scheduled-date" color="grey.700">
          <MIFormattedText label={dateLabel} values={{ date: <MIFormattedDate date={displayDate} /> }} />
        </Box>
        {!isRecurringBill && (
          <Box>
            {billsCount > 1 ? (
              <Box
                as="a"
                textStyle="link3"
                _hover={{ textDecoration: 'underline' }}
                data-testid="list-item-bills-count"
                onClick={scrollToGallery}
              >
                <MIFormattedText label="bills.view.bulkPaymentBillsCount" values={{ billsCount }} />
              </Box>
            ) : (
              <Box textStyle="body4" data-testid="list-item-invoice-number" w="full">
                <MIFieldOrEmpty
                  color="grey.700"
                  value={
                    <Highlighter
                      searchWords={[search]}
                      autoEscape
                      textToHighlight={invoiceNumber ? `#${invoiceNumber}` : ''}
                    />
                  }
                  label="bills.form.invoiceNumberEmpty"
                />
              </Box>
            )}
          </Box>
        )}
        {isRecurringBill && (
          <Box textStyle="body4Semi" color="grey.700">
            <Box as="span" fontWeight="400">
              <MIFormattedText label="list.group.bill.frequency" values={{ frequency }} />
            </Box>
            <MIFormattedText
              label="list.group.bill.occurrences"
              values={{
                index,
                occurrences,
              }}
            />
          </Box>
        )}
      </Box>

      <Box ml={2}>
        <MIMoney
          amount={balance}
          search={search}
          flavor={moneyFlavor}
          showAmountArrowIcon={status === BillStatus.PAID}
          testId="list-item-recurring-bill-money"
          privateData
        />
      </Box>
    </Flex>
  );
}
