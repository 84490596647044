import { useMemo } from 'react';
import { getJWTPayload } from 'src/helpers/jwt';

export const useCandidateFormMessages = (token: string) => {
  const { actions = [] } = getJWTPayload<Partial<{ actions: string[] }>>(token);
  const [action] = actions;
  const messages = useMemo(() => {
    const formattedMessagesPrefix = `vendors.candidates.form${action === 'verifyFastPaymentCandidate' ? '.fast' : ''}`;

    const formattedMessagesIds = {
      title: `${formattedMessagesPrefix}.title`,
      subTitle: `${formattedMessagesPrefix}.subTitle`,
      bankDetailsSectionTooltip: `${formattedMessagesPrefix}.bankDetailsSectionTooltip`,
      disclaimer: `${formattedMessagesPrefix}.disclaimer`,
    };

    return formattedMessagesIds;
  }, [action]);

  return { messages };
};
