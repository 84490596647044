import once from 'lodash/once';
import * as React from 'react';
import Flex from 'src/core/ds/flex';
import { FileInput } from 'src/core/ds/input';
import { analytics } from 'src/services/analytics';

type Props = {
  onSelectFile: (file: File) => void;
  allowedTypes?: string[];
  eventPage: string;
  children: React.ReactNode;
  testId?: string;
};

type DragAndDropWrapperProps = {
  children?: React.ReactNode;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  onClick: () => void;
};

class MIFileUpload extends React.PureComponent<Props> {
  static defaultProps = {
    allowedTypes: [],
  };

  constructor(props: Props) {
    super(props);

    this.fileInputRef = React.createRef();
  }

  onDrop = (event: any) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
        const item = event.dataTransfer.items[i];

        if (item.kind === 'file') {
          this.handleFile(item.getAsFile());
        }
      }
    }
  };

  onDragEnter = (event: any) => {
    event.preventDefault();
  };

  onDragOver = (event: any) => {
    event.preventDefault();
  };

  onChangeInputFile = ({ currentTarget: target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.files) {
      analytics.track(this.props.eventPage, 'file-selected');

      for (let i = 0; i < target.files.length; i += 1) {
        this.handleFile(target.files[i]);
      }
    }
  };

  handleFile = (file: File) => {
    const { allowedTypes, onSelectFile } = this.props;

    if (allowedTypes && allowedTypes.length > 0) {
      if (allowedTypes.some((type) => type === file.type || type.indexOf('*') >= 0)) {
        onSelectFile(file);
      }
    } else {
      onSelectFile(file);
    }
  };

  // TODO: this is a workaround, because, for some reason, each click on Camera or File button
  // generated two click events
  doOnceEvent = once(() => {
    analytics.track(this.props.eventPage, 'import-from-file-button');
  });

  triggerFileInput = () => {
    this.doOnceEvent();

    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };

  fileInputRef: { current: HTMLInputElement | null };

  render() {
    const { allowedTypes, children } = this.props;

    return (
      <>
        <DragAndDropWrapper
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragOver={this.onDragOver}
          onClick={this.triggerFileInput}
        >
          {children}
        </DragAndDropWrapper>
        <FileInput
          ref={this.fileInputRef}
          onChange={this.onChangeInputFile}
          type="file"
          testId={this.props.testId}
          accept={(allowedTypes || []).join(', ')}
        />
      </>
    );
  }
}

const DragAndDropWrapper = ({ children, ...rest }: DragAndDropWrapperProps) => (
  <Flex
    pos="relative"
    w="full"
    h="full"
    flexDir="column"
    boxSizing="border-box"
    bgColor="transparent"
    cursor="pointer"
    {...rest}
  >
    {children}
  </Flex>
);

export default MIFileUpload;
