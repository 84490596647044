import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { getUserPreferences } from 'src/redux/user/selectors';
import { canSwitchCompanies } from 'src/utils/user';

export function useCanSwitchCompanies() {
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const userPreferences = useSelector(getUserPreferences);
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);

  return canSwitchCompanies(userPreferences, organizations, isMemberOfAccountingFirm);
}
