import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';

export const useRefreshDeliveryMethods = () => {
  const dispatch = useDispatch();
  const [isDeliveryMethodsRefreshing, setIsDeliveryMethodsRefreshing] = useState(false);
  const refreshDeliveryMethods = useCallback(async () => {
    setIsDeliveryMethodsRefreshing(true);

    return new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    }).finally(() => {
      setIsDeliveryMethodsRefreshing(false);
    });
  }, [dispatch]);

  return { refreshDeliveryMethods, isDeliveryMethodsRefreshing };
};
