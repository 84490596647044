import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import { BillStatus } from 'src/utils/consts';
import { BillsCountByPaymentStatusResponse, VendorType } from 'src/utils/types';
import {
  getStatusViewUrl,
  getUnpaidBillsCount,
  getUnpaidBillsLabel,
  getUnpaidBillsSum,
  getViewBillsLinkLabel,
} from './utils';

type Props = {
  vendor: VendorType<'created'>;
  vendorBillsByPaymentStatus: BillsCountByPaymentStatusResponse;
  paymentsCountLabel: string;
};

export const PaymentsOverview = ({ vendor, vendorBillsByPaymentStatus, paymentsCountLabel }: Props) => {
  const { isDesktop } = useBreak();
  const { id, companyName } = vendor;
  const unpaidBillsCount = getUnpaidBillsCount(vendorBillsByPaymentStatus);
  const unpaidBillsSum = getUnpaidBillsSum(vendorBillsByPaymentStatus);
  const showUnpaidBillsEmptyState = unpaidBillsCount === 0;
  const unpaidBillsLabel = getUnpaidBillsLabel(isDesktop, showUnpaidBillsEmptyState);

  const getBillsViewLink = (status: BillStatus) => {
    const url = getStatusViewUrl(id, status);
    const linkLabel = getViewBillsLinkLabel(status);

    return <MILink label={linkLabel} to={url} analyticsProperties={{ vendorId: id }} plain underlined />;
  };

  return (
    <>
      <Box borderBottom="1px" borderColor="grey.400" data-testid="summary-unpaid">
        <Box pb={4} textStyle="body4Semi" textTransform="uppercase" color="grey.700">
          <MIFormattedText label="vendors.paymentsOverview.openBalance" />
        </Box>
        <Box pb={1} textStyle="h2Semi">
          <MIFormattedCurrency value={unpaidBillsSum} />
        </Box>
        <Box pb={5} textStyle="body3" color="grey.600">
          <MIFormattedText
            label={unpaidBillsLabel}
            values={{
              count: unpaidBillsCount,
              link: getBillsViewLink(BillStatus.UNPAID),
              vendor: companyName,
            }}
          />
        </Box>
      </Box>
      <Flex mt={5} justify="space-between">
        <Box w="full" borderRight="1px" borderColor="grey.400" data-testid="summary-scheduled">
          <Box pb={4} textStyle="body4Semi" textTransform="uppercase" color="grey.700">
            <MIFormattedText label="vendors.paymentsOverview.scheduled" />
          </Box>
          <Box pb={1} textStyle="body1">
            <MIFormattedCurrency value={vendorBillsByPaymentStatus.scheduled.sum} />
          </Box>
          <Box textStyle="body3" color="grey.600">
            <MIFormattedText
              label={paymentsCountLabel}
              values={{
                count: vendorBillsByPaymentStatus.scheduled.count,
                link: vendorBillsByPaymentStatus.scheduled.count ? getBillsViewLink(BillStatus.SCHEDULED) : '',
              }}
            />
          </Box>
        </Box>
        <Box pl={10} w="full">
          <Box pb={4} textStyle="body4Semi" textTransform="uppercase" color="grey.700" data-testid="summary-paid">
            <MIFormattedText label="vendors.paymentsOverview.paid" />
          </Box>
          <Box pb={1} textStyle="body1">
            <MIFormattedCurrency value={vendorBillsByPaymentStatus.paid.sum} />
          </Box>
          <Box textStyle="body3" color="grey.600">
            <MIFormattedText
              label={paymentsCountLabel}
              values={{
                count: vendorBillsByPaymentStatus.paid.count,
                link: vendorBillsByPaymentStatus.paid.count ? getBillsViewLink(BillStatus.PAID) : '',
              }}
            />
          </Box>
        </Box>
      </Flex>
    </>
  );
};
