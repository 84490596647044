import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import mapValues from 'lodash/mapValues';
import { useCallback, useMemo } from 'react';
import { DrawerBody, DrawerCloseButton, DrawerFooter, DrawerHeader } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { InvoiceType } from 'src/modules/invoices/types';
import { CardHeader } from 'src/pages/get-pro/components/cards/CardHeader';
import useCardActionsAndState from 'src/pages/get-pro/components/cards/hooks/useCardActionsAndState';
import RequestCard from 'src/pages/get-pro/components/cards/request/RequestCard';
import RequestCardActions from 'src/pages/get-pro/components/cards/request/RequestCardActions';
import { EventEntity } from 'src/pages/get-pro/consts';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import useLoadGetProList from 'src/pages/get-pro/hooks/useLoadGetProList';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';
import { SyncedItemIcon } from './SyncedItemIcon';
import { DrawerTabs } from './tabs/DrawerTabs';
import { useDrawerTabs } from './tabs/hooks/useDrawerTabs';
import { TimelineDrawer } from './TimelineDrawer';

type Props = {
  invoice: InvoiceType;
  onClose: () => void;
  onHeaderClick?: () => void;
  editMode?: boolean;
};

export const RequestDrawer = ({ invoice, onClose, onHeaderClick, editMode }: Props) => {
  const store = MAP_STORE_BY_TAB[GetProTabs.REQUESTS];
  const { listParams } = useGetProListParams();
  const { loadGetProList } = useLoadGetProList(listParams);
  const actions = useStoreActions(store);
  const accountingSoftwareName = useGetAccountingSoftwareName(invoice?.createOrigin);
  const remove = async (removeInvoice?: boolean) => {
    const { orgId } = listParams;

    if (removeInvoice) {
      await actions.proDelete({
        orgId,
        id: invoice?.id,
      });
    } else {
      await actions.proCancel({
        orgId,
        id: invoice?.id,
      });
    }

    onClose();
  };

  const {
    onSubmit,
    onCancel,
    onSend,
    deleteFile,
    onEditToggle,
    markAsPaidRequest,
    openPdfPreview,
    vendorHandle,
    customers,
    uploadFile,
    fileUrls,
    uploading,
    invoiceMV,
    loadingFileUrls,
    loading,
    inEditMode,
    EditConfirmMessage,
  } = useCardActionsAndState({
    invoice,
    editMode,
    tab: GetProTabs.REQUESTS,
  });

  const notValid = useMemo(
    () => !isValidationOk(getValidationErrors('paymentRequest', mapValues(invoiceMV, 'value'))),
    [invoiceMV]
  );

  const onMarkRequestAsPaid = useCallback(async () => {
    await markAsPaidRequest();
    onClose();
  }, [markAsPaidRequest, onClose]);

  const onReSendPaymentRequest = useCallback(() => {
    onClose();
    onSend();
  }, [onClose, onSend]);

  const onSubmitEdit = useCallback(async () => {
    await onSubmit();
    await loadGetProList();
  }, [loadGetProList, onSubmit]);

  const { tabs, activeTab, onTabClick } = useDrawerTabs(GetProTabs.REQUESTS);
  const isTimelineActiveTab = activeTab === tabs.timeline.name;

  return (
    <>
      {EditConfirmMessage}
      <>
        <DrawerHeader flexDirection="column" minH="auto" pb={0}>
          <Flex justify="space-between" w="full">
            <CardHeader
              companyName={invoice?.customer?.contactName || ''}
              description={invoice?.invoiceNumber ? `#${invoice?.invoiceNumber}` : ''}
              Icon={
                accountingSoftwareName ? (
                  <SyncedItemIcon placement="bottom" accountingSoftwareName={accountingSoftwareName} />
                ) : undefined
              }
              onClick={onHeaderClick}
            />
            <DrawerCloseButton size="md" />
          </Flex>
          <DrawerTabs tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
        </DrawerHeader>
        <DrawerBody pb={10} pt={4}>
          {activeTab === tabs.details.name && (
            <RequestCard
              invoiceMV={invoiceMV}
              onMarkRequestAsPaid={onMarkRequestAsPaid}
              invoice={invoice}
              vendorHandle={vendorHandle}
              inEditMode={inEditMode}
              uploading={uploading}
              loading={loading}
              loadingFileUrls={loadingFileUrls}
              fileUrls={fileUrls}
              customers={customers}
              remove={remove}
              onEditToggle={onEditToggle}
              deleteFile={deleteFile}
              uploadFile={uploadFile}
              openPdfPreview={openPdfPreview}
            />
          )}
          {isTimelineActiveTab && (
            <TimelineDrawer entity={invoice} entityType={EventEntity.PAYMENT_REQUEST} entityId={invoice.id} />
          )}
        </DrawerBody>
        {!isTimelineActiveTab && (
          <DrawerFooter>
            <RequestCardActions
              loading={loading}
              onClose={onClose}
              inEditMode={inEditMode}
              uploading={uploading}
              notValid={notValid}
              onSubmit={onSubmitEdit}
              onCancel={onCancel}
              onReSend={onReSendPaymentRequest}
            />
          </DrawerFooter>
        )}
      </>
    </>
  );
};
