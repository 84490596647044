import { generatePath } from 'react-router-dom';
import { authApi } from 'src/modules/auth/api';
import { companiesLocations } from 'src/pages/companies/locations';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { msnPortalLocations } from 'src/pages/msn-portal/locations';
import { UserType } from 'src/pages/team/types';
import { spendManagementLocations } from '../pages/spend-management/locations';
import { CompanyType, RegistrationFlow, UserPreferencesKey } from './consts';
import locations from './locations';
import { CompanyInfoType, UserContextType, UserPreferencesType } from './types';

export function isMelioUser(email = '') {
  return email.includes('meliopayments.com');
}

export function isLoginFromForest() {
  const referrer = document.referrer || '';

  return referrer.includes('forestadmin.com');
}

export function isFreeEmailDomain(email: string) {
  const domain = email.substring(email.lastIndexOf('@') + 1);
  const FREE_EMAIL_DOMAINS = [
    'aol.com',
    'att.net',
    'charter.net',
    'comcast.net',
    'facebook.com',
    'gamil.com',
    'gmail.com',
    'googlemail.com',
    'hotmail.com',
    'icloud.com',
    'live.com',
    'mac.com',
    'me.com',
    'msn.com',
    'outlook.com',
    'usa.com',
    'yahoo.com',
    'yandex.com',
    'ymail.com',
  ];

  if (!domain) {
    return true;
  }

  return FREE_EMAIL_DOMAINS.indexOf(domain) !== -1;
}

export function canSwitchCompanies(
  userPreferences: Pick<UserPreferencesType, UserPreferencesKey.AllowCompaniesSwitcher>,
  organizations: readonly ToDo[],
  isMemberOfAccountingFirm?: boolean
) {
  return (userPreferences.allowCompaniesSwitcher || organizations.length > 1 || isMemberOfAccountingFirm) ?? false;
}

export function getRedirectLoginUrl({
  orgId,
  registrationFlow,
  userPreferences,
  organizations,
}: {
  orgId: number;
  registrationFlow: RegistrationFlow;
  userPreferences: Pick<UserPreferencesType, UserPreferencesKey.AllowCompaniesSwitcher>;
  organizations: ReadonlyArray<Pick<CompanyInfoType, 'companyType'>>;
}) {
  const isMemberOfAccountingFirm = organizations.some(
    (org: Pick<CompanyInfoType, 'companyType'>) => org.companyType === CompanyType.ACCOUNTING_FIRM
  );

  if (canSwitchCompanies(userPreferences, organizations, isMemberOfAccountingFirm)) {
    return generatePath(companiesLocations.index, { orgId });
  }

  if (registrationFlow === RegistrationFlow.VENDOR) {
    return generatePath(getPaidLocations.dashboard, { orgId });
  }

  if ([RegistrationFlow.MSN_PORTAL_SALES_FORM, RegistrationFlow.MSN_PORTAL_PUBLIC_FORM].includes(registrationFlow)) {
    return generatePath(msnPortalLocations.base, { orgId });
  }

  if (registrationFlow === RegistrationFlow.SPEND_MANAGEMENT) {
    return generatePath(spendManagementLocations.index, { orgId });
  }

  return locations.MainApp.dashboard.url({ orgId });
}

export function getUserOrganization(user: Pick<UserContextType, 'userOrganizations'>, orgId: number) {
  return user?.userOrganizations?.find((o) => o.organizationId === orgId);
}

export function getUserRole(user: Pick<UserContextType, 'userOrganizations'>, orgId: number) {
  const userOrganization = getUserOrganization(user, orgId);

  return userOrganization?.role;
}

export const isUserInOrganization = (user: UserType, orgId: number): boolean =>
  Boolean(user.userOrganizations?.some((userOrg) => userOrg.organizationId === orgId));

export const getFullName = (firstName?: string | null, lastName?: string | null) =>
  [firstName, lastName].join(' ').trim();

export const checkEmailInUse = async (email: string) => {
  try {
    await authApi.checkEmailUniqueness({ email });

    return false;
  } catch (e: any) {
    if (e.code === 'ATH01') {
      return true;
    }

    throw e;
  }
};
