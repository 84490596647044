import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import errorAnimation from 'src/animations/accountingSoftware/sync-qbd/error.json';
import syncAnimation from 'src/animations/accountingSoftware/sync-qbd/sync.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { getOrgId } from 'src/redux/user/selectors';
import { AccountingSoftware, NotificationCardTypes, SyncStatus } from 'src/utils/consts';
import { useInterval } from 'src/utils/useInterval';
import { getQBDRelativeStep } from './utils';

type Props = {
  onNext: () => void;
};
const CHECK_SYNC_STATUS_TIMEOUT = 5 * 1000;
const SyncQBDInProgressPage = ({ onNext }: Props) => {
  const orgId = useSelector(getOrgId);
  const apActions = useStoreActions(accountingPlatformsStore);
  const connectionStatus = useSelector(
    accountingPlatformsStore.selectors.getByName(AccountingSoftware.QUICKBOOKS_DESKTOP)
  );
  const connectionFailed = connectionStatus?.syncStatus === SyncStatus.FAILURE;

  const notification = connectionFailed
    ? {
        type: NotificationCardTypes.ERROR,
        title: {
          label: 'settings.newAccountingSoftware.QuickbooksDesktopSync.inProgress.errorTitle',
        },
        subtitle: {
          label: 'settings.newAccountingSoftware.QuickbooksDesktopSync.inProgress.errorSubtitle',
        },
      }
    : undefined;
  const animatedIllustration = {
    animationData: connectionFailed ? errorAnimation : syncAnimation,
    loop: !connectionFailed,
  };

  const getSyncStatus = useCallback(async () => {
    if (!connectionFailed) {
      await apActions.fetch({ orgId });
    }
  }, [connectionFailed, orgId, apActions]);

  useEffect(() => {
    getSyncStatus();
  }, []);
  useInterval(getSyncStatus, CHECK_SYNC_STATUS_TIMEOUT);

  useEffect(() => {
    if (connectionStatus?.syncStatus === SyncStatus.SYNCED) {
      onNext();
    }
  }, [connectionStatus]);

  return (
    <StepLayoutPage
      title="settings.newAccountingSoftware.QuickbooksDesktopSync.inProgress.title"
      subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.inProgress.subtitle"
      nextLabel="settings.newAccountingSoftware.QuickbooksDesktopSync.inProgress.action"
      relativeStep={getQBDRelativeStep(6)}
      displaySupportLink
      animatedIllustration={animatedIllustration}
      notification={notification}
    />
  );
};

export default SyncQBDInProgressPage;
