import { InvoiceStatus } from 'src/utils/consts';

export const getInvoiceStatusInfo = (status, theme) => {
  const statuses = {
    [InvoiceStatus.CREATED]: null,
    [InvoiceStatus.PENDING]: null,
    [InvoiceStatus.FAILED]: {
      color: theme.colors.failure.opaque,
      colorOpacity: theme.colors.failure.translucent1,
      label: 'getPaid.view.statuses.failed',
      icon: 'icon-notification-icon',
    },
    [InvoiceStatus.SCHEDULED]: {
      color: theme.text.color.blue,
      colorOpacity: theme.colors.progress.scheduled,
      label: 'getPaid.view.statuses.scheduled',
      icon: 'icon-scheduled-bill',
    },
    [InvoiceStatus.PAID]: {
      color: theme.colors.success.opaque,
      colorOpacity: theme.colors.success.translucent1,
      label: 'getPaid.view.statuses.paid',
      icon: 'icon-full-checkmark-icon',
    },
    [InvoiceStatus.MARKED_AS_PAID]: {
      color: theme.colors.success.opaque,
      colorOpacity: theme.colors.success.translucent1,
      label: 'getPaid.view.statuses.markedAsPaid',
      icon: 'icon-full-checkmark-icon',
    },
  };

  return statuses[status];
};

export const getTotalAmount = (selectedInvoices) =>
  selectedInvoices.reduce((totalAmount, invoice) => totalAmount + Number(invoice.totalAmount), 0);

export const shouldShowCheckBox = (
  enableMultiSelect: boolean,
  showCheckbox: boolean,
  hovered: boolean,
  isStatusFailed: boolean
) => {
  if (isStatusFailed) {
    return false;
  } else if (!enableMultiSelect) {
    return false;
  } else if (showCheckbox || hovered) {
    return true;
  }

  return false;
};

export const shouldShowBadge = (shouldDisplayCheckBox: boolean, status?: InvoiceStatus): boolean => {
  if (status === InvoiceStatus.FAILED || !shouldDisplayCheckBox) {
    return true;
  }

  return false;
};
