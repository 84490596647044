import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { ProgressBarFlavor } from 'src/utils/consts';

type FlavorType = ProgressBarFlavor.WIZARD | ProgressBarFlavor.MELIO_ME;

type Props = {
  relativeStep: number;
  flavor: FlavorType;
};

export const ProgressBar = ({ relativeStep, flavor }: Props) => {
  const width = Math.round(relativeStep * 100) / 100;

  return (
    <Flex justifyContent="center" alignItems="center" flexGrow={3}>
      <Box __css={TotalBarStyle(flavor)}>
        <Box __css={ProgressStyle(relativeStep >= 1)} w={`${width * 100}%`} />
      </Box>
    </Flex>
  );
};

ProgressBar.defaultProps = {};

const TotalBarStyle = (flavor: FlavorType) => {
  const isWizard = flavor === ProgressBarFlavor.WIZARD;

  return {
    backgroundColor: 'grey.400',
    borderRadius: 'md',
    height: { base: isWizard ? '0.8rem' : '0.4rem', md: isWizard ? '0.8rem' : '0.6rem' },
    width: { base: isWizard ? '12.5rem' : 'full', md: flavor === ProgressBarFlavor.WIZARD ? '25%' : 'full' },
    maxWidth: { md: isWizard ? '22.5rem' : 'full' },
  };
};

const ProgressStyle = (isComplete?: boolean) => ({
  backgroundColor: 'primary.500',
  height: 'full',
  maxWidth: 'full',
  borderBottomLeftRadius: 'md',
  borderBottomRightRadius: isComplete ? 'md' : '0',
  borderTopLeftRadius: 'md',
  borderTopRightRadius: isComplete ? 'md' : '0',
  transition: 'width 0.5s',
});
