import { ReactComponent as RightArrow } from 'src/images/core/right-arrow.svg';
import { PaginationIconButton } from './PaginationIconButton';

type Props = {
  nextPage: () => void;
  canNextPage?: boolean;
};

export const Next = ({ canNextPage, nextPage }: Props) => (
  <PaginationIconButton
    ariaLabel="next-page"
    onClick={nextPage}
    icon={<RightArrow />}
    disabled={!canNextPage}
    testId="next-page"
  />
);
