import Box from 'src/core/ds/box';
import { IllustrationName, IllustrationSize } from './consts';

export type IllustrationProps = {
  name: IllustrationName;
  size?: IllustrationSize;
};

const Illustration = ({ name, size = IllustrationSize.sm }: IllustrationProps) =>
  name && (
    <Box as="svg" role="img" width={size} height={size}>
      <use href={`#${name}`} />
    </Box>
  );

export { Illustration };
