import { keyframes } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PopupItemContainer } from 'src/components/common/PopupItemContainer';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import Spacer from 'src/core/ds/spacer';
import Tooltip from 'src/core/ds/tooltip';
import { useInboxEmailAddress } from 'src/modules/organizations/hooks/useInboxEmailAddress';

const disappearKeyframe = keyframes`
  0% {
    opacity: 1;
    max-height: 200px;
  }
  50% {
    opacity: 0;
    max-height: 200px;
  }
  100% {
    opacity: 0;
    max-height: 0;
  }
`;

export const Message = ({ onCloseBillInboxAnnouncement }: { onCloseBillInboxAnnouncement: () => void }) => {
  const { inboxEmailAddress, onCopyEmail } = useInboxEmailAddress({ eventPage: 'bills' });
  const DISAPPEAR_SECONDS = 1;
  const [animating, setAnimating] = useState(false);
  const anim = `${disappearKeyframe} 1 ${DISAPPEAR_SECONDS}s linear forwards`;
  const onCloseClicked = () => {
    setAnimating(true);
    setTimeout(onCloseBillInboxAnnouncement, DISAPPEAR_SECONDS * 1000);
  };

  return (
    <Box animation={animating ? anim : undefined}>
      <PopupItemContainer testId="pay-inbox-message">
        <Flex
          alignItems={{
            lg: 'center',
            base: 'start',
          }}
          mb={2}
          textStyle={{ lg: 'body2Semi', base: 'body3Semi' }}
        >
          <MIFormattedText
            label="pay.scannedInvoice.message.title"
            values={{
              icon: <Icon mr={2} name={IconNames.email} />,
            }}
          />
          <Spacer />
          <Flex
            data-testid="close-action"
            alignItems="center"
            onClick={onCloseClicked}
            cursor="pointer"
            ml={2}
            color="grey.600"
            _hover={{ color: 'black' }}
          >
            <Icon name={IconNames.close} size={IconSize.s} />
          </Flex>
        </Flex>
        <Box mb={4} color="grey.700" textStyle="body3">
          <MIFormattedText label="pay.scannedInvoice.message.description" />
        </Box>
        <Flex color="grey.700" textStyle="body3">
          <Box isTruncated>{inboxEmailAddress}</Box>
          <Tooltip placement="top" label={<MIFormattedText label="pay.scannedInvoice.message.action" />}>
            <Box minWidth={5} ml={3}>
              <IconButton
                aria-label="action"
                variant="actionNaked"
                size="sm"
                icon={<Icon name={IconNames.duplicateFlip} />}
                onClick={onCopyEmail}
              />
            </Box>
          </Tooltip>
        </Flex>
      </PopupItemContainer>
    </Box>
  );
};
