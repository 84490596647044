import { useSelector } from 'react-redux';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import { PayListItemType } from 'src/modules/bills/types';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';
import { useSelectedIds } from 'src/pages/regular-batch-payments/BatchSettings/useBatchSelection';

const getNonInternationalPayListItems = (payListItems: PayListItemType[]): PayListItemType[] =>
  payListItems.filter(({ vendor }) => !vendor?.isInternationalVendor);

export const useBatchSelectionFilter = () => {
  const { isInternationalAllowed } = useIsInternationalAllowed();
  const { selectedIds } = useSelectedIds();
  const payListItems = useSelector(payListItemsStore.selectors.getByIds(selectedIds));
  const filteredItems = !isInternationalAllowed ? getNonInternationalPayListItems(payListItems) : payListItems;
  const filteredIds = filteredItems.map((item) => item.id);
  const billsCount = selectedIds.length;
  const isFiltered = filteredIds.length < billsCount;
  const isEmpty = filteredIds.length === 0;

  return {
    isFiltered,
    isEmpty,
    filteredIds,
    billsCount,
  };
};
