import { useEffect, useState } from 'react';
import { Lottie } from 'src/components/common/Lottie';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { CloseButton } from 'src/core/ds/closeButton/CloseButton';
import Flex from 'src/core/ds/flex';
import animation from './biller-visibility-suggestions-animation.json';
import { BillersVisibilityModalMessageStyle } from './BillersVisibilityModalMessage.styles';

type MessageProps = {
  onClose: () => void;
  onNewBill: () => void;
};
export const BillersVisibilityModalMessage = ({ onClose, onNewBill }: MessageProps) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setTimeout(() => setShow(true), 2000);
  }, []);

  return show ? (
    <Box __css={BillersVisibilityModalMessageStyle}>
      <Flex>
        <Flex direction="column">
          <Box pb={6} pt={12}>
            <Lottie animationData={animation} loop autoplay />
          </Box>
          <Box px={6} pt={4} pb={6}>
            <Box textStyle="body3Semi" mb={4}>
              <MIFormattedText label="vendorDirectory.education.modalTitle" />
            </Box>
            <Box textStyle="body3" color="grey.700" mb={4}>
              <MIFormattedText label="vendorDirectory.education.modalDescription" />
            </Box>
            <Button
              w="fit-content"
              size={ButtonSizes.sm}
              onClick={onNewBill}
              label="vendorDirectory.education.modalButton"
            />
          </Box>
        </Flex>
        <CloseButton
          pos="absolute"
          top={4}
          right={4}
          size="md"
          onClick={onClose}
          id="vendorDirectory.education.modalClose"
        />
      </Flex>
    </Box>
  ) : null;
};
