import { IconNames } from 'src/core/ds/icon';
import { mapBackwardComaptibleSorting } from 'src/pay/components/SortMenu/backwards-compatibility';
import { PayTabs } from 'src/utils/consts';
import { sortOptionsDefaults } from 'src/utils/payListConsts';
import { parseSortingQueryString, SortDirection } from 'src/utils/query-utils';
import { SortMenuValue } from './types';

export const getDirectionIcon = (direction: SortDirection) =>
  direction === SortDirection.DESC ? IconNames.arrowDown : IconNames.arrowUp;
export const xorDirection = (direction: SortDirection) =>
  direction === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
export const generateSortQueryString = ({ selected, direction }: SortMenuValue) => `${selected}:${direction}`;

export function getSortMenuValue(tab: PayTabs, sortingQueryString: string): SortMenuValue {
  const backwardsCompatibleValue = mapBackwardComaptibleSorting(sortingQueryString);

  if (backwardsCompatibleValue) {
    return backwardsCompatibleValue;
  }

  const [parsedSorting, parsedDirection] = parseSortingQueryString(sortingQueryString);

  if (parsedSorting) {
    return {
      selected: parsedSorting,
      direction: parsedDirection || SortDirection.DESC,
    };
  }

  return {
    selected: sortOptionsDefaults[tab],
    direction: SortDirection.DESC,
  };
}
