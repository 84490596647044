import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { VendorCompanyPhoneForm } from './components/VendorCompanyPhoneForm';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const CompanyPhonePageContainer = ({ companyInfoField, nextStepURL, prevStepURL, inputFields }: Props) => {
  const { onNext, onChange, onPrev, isLoading, validationErrors } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
  });

  const companyInfo = useSelector(getCompanyInfo);

  return (
    <VendorCompanyPhoneForm
      onNext={onNext}
      onPrev={onPrev}
      onChange={onChange}
      phone={companyInfo.phone}
      isLoading={isLoading}
      validationErrors={validationErrors}
    />
  );
};
