import { isBefore, parseISO, startOfDay } from 'date-fns';
import { Children, useState } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import Box from 'src/core/ds/box';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { useFundingSourceMicroDepositState } from 'src/pages/settings/hooks/useFundingSourceMicroDeposits';
import { AccountRecord } from 'src/pages/settings/records';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryType, NotificationCardTypes, PaymentApprovalStatus } from 'src/utils/consts';
import { canVerifyManualBankAccount, isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { isReturnedCheck } from 'src/utils/payments';
import { BillType, PaymentType } from 'src/utils/types';
import { VerifyAccountLink } from './VerifyAccountLink';

type Props = {
  bill: BillType;
  payment: PaymentType;
  className?: string;
};

export const PaymentProcessingBlockedNotificationCard = ({ payment, bill, className }: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const fundingSource = AccountRecord(
    useSelector(fundingSourcesStore.selectors.byId(payment.fundingSourceId)) || payment.fundingSource
  );
  const deliveryMethod = deliveryMethodFactory(payment.deliveryMethod);
  const isApprovalRequired = payment.approvalDecisionStatus === PaymentApprovalStatus.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnitaleral = deliveryMethod.deliveryType === DeliveryType.VIRTUAL;
  const date = <MIFormattedDate year={undefined} date={payment.scheduledDate} />;
  const microDepositEventPage = 'bill-payment_verify-manual-account';
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [microDepositState, microDepositActions] = useFundingSourceMicroDepositState(microDepositEventPage, {
    fundingSourceId: fundingSource.id,
  });
  const isReturnedCheckPayment = isReturnedCheck(payment);
  const isAccountCanBeVerified = canVerifyManualBankAccount(fundingSource);
  const showVerifyDialogAction = () => setShowVerifyDialog(true);
  let subtitle;
  let subtitleValues = {};

  if (isMicrodeposit && isApprovalRequired) {
    subtitle = 'unverifiedBankAccountApprovalRequiredMessage';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    subtitleValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
      verifyBankAccount: isAccountCanBeVerified ? (
        <VerifyAccountLink
          showVerifyDialog={showVerifyDialogAction}
          label="viewBillWarningNotificationCard.verifyBankAccount"
        />
      ) : (
        ''
      ),
    };
  } else if (isApprovalRequired && isUnitaleral) {
    subtitle = 'unilateralApprovalRequiredMessage';
    subtitleValues = {
      date,
      vendor: bill.vendor?.companyName || '',
    };
  } else if (isMicrodeposit && isAccountCanBeVerified) {
    const isPassedDue = isBefore(parseISO(bill.dueDate.toString()), parseISO(startOfDay(new Date()).toISOString()));
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    subtitle = isPassedDue ? 'unverifiedBankAccountPastDueWithActionMessage' : 'unverifiedBankAccountWithActionMessage';
    subtitleValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
      verifyLink: (
        <VerifyAccountLink
          showVerifyDialog={showVerifyDialogAction}
          label="viewBillWarningNotificationCard.verifyAccount"
        />
      ),
    };
  } else if (isMicrodeposit) {
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    subtitle = 'unverifiedBankAccountMessage';
    subtitleValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isApprovalRequired) {
    subtitle = 'approvalRequiredMessage';
    subtitleValues = {
      date,
    };
  } else if (isUnitaleral) {
    if (isReturnedCheckPayment) {
      subtitle = 'unilateralFailedToDeliver';
    } else {
      subtitle = 'unilateralMessage';
    }

    subtitleValues = {
      date,
      vendor: bill.vendor?.companyName || '',
    };
  }

  subtitleValues = {
    ...subtitleValues,
    li: (...chunks) => (
      <Box as="li" pl={6}>
        {Children.toArray(chunks)}
      </Box>
    ),
    ul: (...chunks) => (
      <Box as="ul" p={0} margin={0} paddingInlineStart={5}>
        {Children.toArray(chunks)}
      </Box>
    ),
  };

  const userType = permissions.bills.approve() ? 'admin' : 'contributor';

  return (
    <>
      {showVerifyDialog && (
        <VerifyMicroDeposits
          {...microDepositState}
          {...microDepositActions}
          key={fundingSource.id}
          verifyingId={fundingSource.id}
          onVerifyFinished={() => {
            setShowVerifyDialog(false);
          }}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}
      {subtitle && (
        <div className={className}>
          <MINotificationCard
            type={NotificationCardTypes.WARNING}
            title={{
              label: 'viewBillWarningNotificationCard.actionRequiredTitle',
            }}
            subtitle={{
              label: `viewBillWarningNotificationCard.${userType}.${subtitle}`,
              values: subtitleValues,
            }}
          />
        </div>
      )}
    </>
  );
};
