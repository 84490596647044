import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { IconNames, IconSize } from 'src/core/ds/icon';
import { Link } from 'src/core/ds/link';
import { LEARN_MORE_LINK } from '../consts';
import { openSupportChat } from '../utils';

export const MicroDepositErrorPage = () => (
  <SuccessLayoutPage
    testId="error-page"
    icon={IconNames.cancelFill}
    iconSize={IconSize['2xl']}
    title="microDepositVerification.error.title"
    text="microDepositVerification.tooManyFailedAttempts.subtitle"
    textValues={{
      learnMore: (
        <Link href={LEARN_MORE_LINK} target="_blank" isExternal>
          <MIFormattedText label="settings.microDeposits.verifyDialogLimitReached.learnMoreLink" />
        </Link>
      ),
      liveChat: (
        <Box as="a" cursor="pointer" onClick={openSupportChat} textStyle="link1">
          <MIFormattedText label="settings.microDeposits.verifyDialogLimitReached.liveChat" />
        </Box>
      ),
    }}
  />
);
