import { Flex, FormLabel, Switch as SwitchChakra, SwitchProps } from '@chakra-ui/react';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { AnalyticsPropsType } from 'src/core/ds/types';
import { analytics } from 'src/services/analytics';
import { SwitchSize } from './consts';

type Props = Omit<SwitchProps, 'variant'> &
  AnalyticsPropsType & {
    /** Specifies the label of the Switch */
    label?: string | null;
    /** Specifies the  dynamic values of the label  */
    values?: Record<string, any>;
    /** Specifies the testId  */
    testId?: string | null;
  };

export const Switch = ({
  testId,
  label,
  values,
  onChange,
  skipAnalyticsEvent,
  analyticsProperties,
  ...rest
}: Props) => {
  const switchId = testId || `switch-${label}`;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    !skipAnalyticsEvent && analytics.trackAction(label || switchId, analyticsProperties);
    onChange && onChange(event);
  };

  return (
    <Flex alignItems="center">
      {label && (
        <FormLabel htmlFor={switchId} textStyle="body2" me={2}>
          <MIFormattedText label={label} values={values} />
        </FormLabel>
      )}
      <SwitchChakra id={switchId} data-testid={switchId} onChange={handleChange} size={SwitchSize.large} {...rest} />
    </Flex>
  );
};
