import { featureFlags } from '@melio/shared-web';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOrgId } from 'src/hooks';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { getPlatformPath } from 'src/modules/platform-app/platform-path-mapping';
import { useIsAccountingFirmSelected } from 'src/pages/team/team-management/hooks/useIsAccountingFirmSelected';
import { analytics } from 'src/services/analytics';
import { OrgPreferencesTypeKeys, UserAuthType } from 'src/utils/consts';
import { FeatureFlags } from 'src/utils/featureFlags';
import authStore from '../auth/auth-store';
import { getPlatformRedirectUrl } from './utils';

export const usePlatformRedirection = ({
  isEnabled = true,
  featureFlagsConfig,
}: {
  isEnabled?: boolean;
  featureFlagsConfig?: { [key: string]: boolean };
}): { isRedirecting: boolean; isOrgMigratedToPlatform: boolean } => {
  const authType = useSelector(authStore.selectors.userAuthType);
  const orgId = useOrgId();
  const orgPreferences = useOrganizationPreferences();
  const isOrgMigratedToPlatformPref = orgPreferences?.[OrgPreferencesTypeKeys.isMigratedToPlatform] === 'true';
  const isOrgRevertedToLegacyPref = orgPreferences?.[OrgPreferencesTypeKeys.isRevertedToLegacy] === 'true';
  const [isMelioToPlatformEnabled] = featureFlags.useFeature(FeatureFlags.MelioToPlatform, true);
  const [isMelioMigrationWhitelistToBlacklistEnabled] = featureFlags.useFeature(
    FeatureFlags.melioMigrationWhitelistToBlacklist,
    false
  );
  const route = window.location.pathname + window.location.search;

  const { isAccountingFirmSelected } = useIsAccountingFirmSelected();
  const platformPath = getPlatformPath(route, isAccountingFirmSelected, featureFlagsConfig);
  const isNeedVerification = authType === UserAuthType.EMAIL_NOT_VERIFIED || authType === UserAuthType.TWO_FA_REQUIRED;

  const isOrgMigratedToPlatform = useMemo(() => {
    if (isNeedVerification) {
      return false;
    }

    if (isMelioMigrationWhitelistToBlacklistEnabled) {
      const shouldRedirectToPlatform =
        orgPreferences && orgPreferences?.[OrgPreferencesTypeKeys.isRevertedToLegacy] !== 'true';

      return shouldRedirectToPlatform && isEnabled;
    }

    return isMelioToPlatformEnabled && isOrgMigratedToPlatformPref && isEnabled;
  }, [
    isEnabled,
    isMelioMigrationWhitelistToBlacklistEnabled,
    isMelioToPlatformEnabled,
    isNeedVerification,
    isOrgMigratedToPlatformPref,
    orgPreferences,
  ]);

  const shouldRedirect = !!platformPath && isOrgMigratedToPlatform;

  useEffect(() => {
    analytics.track('meliocom-migration', 'use-platform-redirection', {
      orgPreferences,
      isOrgMigratedToPlatform: isOrgMigratedToPlatformPref,
      isOrgRevertedToLegacy: isOrgRevertedToLegacyPref,
      isMelioToPlatformEnabled,
      isMelioMigrationWhitelistToBlacklistEnabled,
      platformPath,
      shouldRedirect,
      route,
    });
  }, [
    orgPreferences,
    isOrgMigratedToPlatformPref,
    isMelioToPlatformEnabled,
    platformPath,
    shouldRedirect,
    route,
    isOrgRevertedToLegacyPref,
    isMelioMigrationWhitelistToBlacklistEnabled,
  ]);

  if (shouldRedirect) {
    window.location.replace(getPlatformRedirectUrl(platformPath, orgId));
  }

  return { isRedirecting: shouldRedirect, isOrgMigratedToPlatform };
};
