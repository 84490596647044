import { AreaLoader } from 'src/components/common/AreaLoader';
import { CheckAlreadyDepositedPage } from 'src/pages/vendor/components/CheckAlreadyDepositedPage';
import { usePaymentDataFromToken } from 'src/pages/vendor/hooks/usePaymentDataFromToken';
import { PaymentStatus } from 'src/utils/consts';

type Props = {
  token: string;
};

export const CheckDepositedSuccessPage = ({ token }: Props) => {
  const { payment, isPaymentLoading, organization } = usePaymentDataFromToken({ token });

  if (isPaymentLoading || payment?.status !== PaymentStatus.COMPLETED) {
    return <AreaLoader />;
  }

  return <CheckAlreadyDepositedPage payment={payment} organization={organization} token={token} />;
};
