import { Loader } from 'src/components/common/Loader';
import Box from 'src/core/ds/box';
import { getAreaLoaderStyle } from './styles';

type Props = {
  placement?: 'list' | 'single' | 'wizard' | 'melioMe';
};

export const AreaLoader = ({ placement = 'single' }: Props) => (
  <Box __css={getAreaLoaderStyle(placement)} data-testId="area-loader">
    <Box>
      <Loader color="primary" context="page" />
    </Box>
  </Box>
);
