import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { CardTypes } from 'src/utils/consts';
import { getInstitutionName } from 'src/utils/funding-sources';
import { AccountType, BillType } from 'src/utils/types';

type FundingSourceDescriptionProps = {
  fundingSource: AccountType;
  bill: BillType;
};

export const FundingSourceDescription = ({ fundingSource }: FundingSourceDescriptionProps) => {
  const site = useSiteContext();
  let description;

  if (fundingSource?.bankAccount) {
    const displayName = fundingSource.displayName || '';
    const institutionName = fundingSource ? getInstitutionName(fundingSource) : '';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);

    if (institutionName) {
      description = `${displayName} (${institutionName}, ...${cutAccountNumber})`;
    } else {
      description = `${displayName} (...${cutAccountNumber})`;
    }
  } else if (fundingSource?.cardAccount) {
    const cardType = fundingSource?.cardAccount?.cardType || '';
    description = `${fundingSource.cardAccount.network} (...${fundingSource.cardAccount.card4digits})`;

    if (cardType === CardTypes.DEBIT && site?.hasDebitInBankAccounts) {
      description = (
        <MIFormattedText
          label="payBillPaymentActivity.fundingSource.debitCardDescription"
          values={{
            cardNetwork: fundingSource.cardAccount.network,
            card4digits: fundingSource.cardAccount.card4digits,
          }}
        />
      );
    }
  }

  return description || <></>;
};
