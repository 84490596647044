const parts = ['container', 'control', 'label', 'icon'];

function baseStyleControl(props: Record<string, any>) {
  const { colorScheme: c } = props;

  const disabled = {
    borderColor: 'grey.400',
    outlineColor: 'transparent',
    cursor: 'not-allowed',
  };

  const checked = {
    bg: `${c}.500`,
    borderColor: `${c}.500`,
    color: 'white',
  };

  const hover = {
    bg: `${c}.500`,
    borderColor: `${c}.500`,
    outlineColor: 'transparent',
  };

  const focus = {
    boxShadow: 'none',
    outlineColor: 'transparent',
  };

  return {
    w: '100%',
    transition: 'none',
    border: '2px',
    borderRadius: 'md',
    borderColor: 'grey.600',
    color: 'white',
    _checked: {
      ...checked,
      _focus: {
        ...focus,
      },
      _hover: {
        ...hover,
      },
      _disabled: {
        ...disabled,
        bg: 'grey.400',
      },
    },
    _hover: {
      bg: 'inherit',
    },

    _indeterminate: {
      ...checked,
      _hover: {
        ...hover,
      },
    },

    _disabled: {
      ...disabled,
      bg: 'transparent',
    },

    _focus: {
      ...focus,
    },
  };
}

const baseStyleLabel = {
  userSelect: 'none',
  _disabled: { color: 'grey.400' },
};

const baseStyleContainer = {
  _disabled: { cursor: 'not-allowed' },
};

const baseStyle = (props: Record<string, any>) => ({
  control: baseStyleControl(props),
  label: baseStyleLabel,
  container: baseStyleContainer,
});

const sizes = {
  sm: {
    control: { w: 3, h: 3 },
    label: { apply: 'textStyles.body4' },
    icon: { fontSize: 8 },
  },
  md: {
    control: { w: 4, h: 4, mt: 0.5 },
    label: { apply: 'textStyles.body2' },
    icon: { fontSize: 10 },
  },
};

const defaultProps = {
  size: 'md',
  colorScheme: 'primary',
};

export default {
  parts,
  baseStyle,
  sizes,
  defaultProps,
};
