import { cloneElement, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { ReactComponent as WarningIcon } from 'src/images/general/warning-icon.svg';
import { SelectedDMSvgIcon } from 'src/images/icons/SelectedDMSvgIcon';
import { ButtonsDirections, ButtonsRowPosition, DialogType, DialogVariants } from 'src/utils/consts';
import { ModalDialogSettings } from 'src/utils/types';

const directions = {
  [ButtonsDirections.VERTICAL]: 'column',
  [ButtonsDirections.HORIZONTAL]: 'row-reverse',
};

const rowPosition = {
  [ButtonsRowPosition.RIGHT]: 'flex-end',
  [ButtonsRowPosition.LEFT]: 'flex-start',
  [ButtonsRowPosition.CENTER]: 'center',
};

export const MIDialog = ({
  type,
  variant,
  title,
  titleValues,
  subtitle,
  subtitleValues,
  okButtonText,
  onOkAction,
  cancelButtonText,
  onCancelAction,
  hideIcon,
  showCancel = true,
  hideClose,
  onOkDisabled,
  maxWidth,
  minHeight,
  fullWidth,
  children,
  buttonsDirection = ButtonsDirections.VERTICAL,
  buttonsRowPosition = ButtonsRowPosition.CENTER,
}: ModalDialogSettings) => {
  const [loading, setLoading] = useState(false);
  const renderIcon = (dialogType: DialogType, dialogVariant: DialogVariants) => {
    if (hideIcon) {
      return null;
    } else if (dialogVariant === DialogVariants.WARNING) {
      return (
        <Flex justify="center" mb={8}>
          <WarningIcon />
        </Flex>
      );
    } else if (dialogVariant === DialogVariants.SUCCESS) {
      return (
        <Flex justify="center" mb={8}>
          <SelectedDMSvgIcon width="34" />
        </Flex>
      );
    }

    return <ErrorIcon />;
  };

  const renderCancelButtonText = (buttonText: string, dialogType: DialogType, dialogVariant: DialogVariants) => {
    if (!buttonText) {
      return dialogType === DialogType.ALERT && dialogVariant === DialogVariants.SUCCESS
        ? 'dialogs.buttons.done'
        : 'dialogs.buttons.cancel';
    }

    return buttonText;
  };

  const onOk = () => {
    const res: any = onOkAction && onOkAction();

    if (res?.finally) {
      setLoading(true);
      res.finally(() => setLoading(false));
    }
  };

  return ReactDOM.createPortal(
    <>
      <Flex
        zIndex="10000"
        pos="fixed"
        top={0}
        left={0}
        h="full"
        w="full"
        bgColor="black"
        opacity={0.5}
        align="center"
        justify="center"
      />
      <Flex
        data-testid="dialog-container"
        pos="absolute"
        left="50%"
        top=" 50%"
        transform="translate(-50%,-50%)"
        direction="column"
        align="center"
        justify="center"
        boxSizing="border-box"
        minHeight={minHeight}
        py={12}
        px={{ base: 5, sm: 12 }}
        maxW={{ base: '33.5rem', sm: maxWidth ?? '50rem' }}
        w={{ base: 'calc(100% - 4rem)', sm: 'calc(100% - 6rem)' }}
        bgColor="white"
        border="1px"
        borderColor="grey.400"
        borderRadius="lg"
        boxShadow="200"
        zIndex="10001"
      >
        {onCancelAction && !hideClose && (
          <Box textStyle="h2" position="absolute" top="2rem" right="2rem">
            <Box onClick={onCancelAction} color="grey.600" cursor="pointer" _hover={{ color: 'black' }}>
              <Icon name={IconNames.close} size={IconSize.lg} />
            </Box>
          </Box>
        )}
        <Box mb={type === DialogType.ALERT ? 6 : 8}>
          {renderIcon(type, variant)}
          <Box data-testid="dialog-title" alignItems="center" textAlign="left" textStyle="h2Semi" color="black" mb={3}>
            <MIFormattedText label={title} values={titleValues} />
          </Box>
          {subtitle && (
            <Box data-testid="dialog-subtitle" alignItems="center" textAlign="left" color="black" textStyle="body2">
              <MIFormattedText label={subtitle} values={subtitleValues} />
            </Box>
          )}
        </Box>
        {children && cloneElement(children as any, { onCancelAction })}
        <Flex w="full" direction={directions[buttonsDirection] as any} align={rowPosition[buttonsRowPosition]}>
          {type === DialogType.CONFIRM && okButtonText && onOkAction && (
            <Box mb={0.5} w={fullWidth ? 'full' : 'auto'}>
              <Button
                isDisabled={onOkDisabled}
                variant={
                  type === DialogType.CONFIRM &&
                  (variant === DialogVariants.SUCCESS || variant === DialogVariants.WARNING) &&
                  okButtonText
                    ? ButtonVariants.primary
                    : ButtonVariants.error
                }
                onClick={onOk}
                label={okButtonText || ''}
                isLoading={loading}
                isFullWidth={fullWidth}
              />
            </Box>
          )}
          {onCancelAction && showCancel && (
            <Button
              variant={ButtonVariants.tertiaryNaked}
              onClick={onCancelAction}
              label={renderCancelButtonText(cancelButtonText || '', type, variant)}
            />
          )}
        </Flex>
      </Flex>
    </>,
    document.body
  );
};

const ErrorIcon = styled.i.attrs({ className: 'icon-notification-icon' })`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.9rem;
  color: ${(props) => props.theme.colors.primary.destructive};
  font-size: 4.8rem;
`;
