import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

const LOADER_ILLUSTRATION = 'https://platform-static.meliopayments.com/assets/melio/illustrations/processing.webm';

export const CreateCardLoader = () => (
  <Flex w="full" h="full" direction="column" alignItems="center" justifyContent="center">
    <video data-component="Video" autoPlay src={LOADER_ILLUSTRATION} loop width="144px">
      <track kind="captions" />
    </video>
    <Box textStyle="body2Semi">
      <MIFormattedText label="spendManagement.pages.dashboard.createCard.process" />
    </Box>
  </Flex>
);
