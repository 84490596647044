import { useCallback } from 'react';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { DialogVariants } from 'src/utils/consts';

type Props = {
  dismiss: (isFromCta: boolean) => void;
  countryLabel: string;
  ctaLabel: string;
};

export const UnsupportedCountryModal = ({ dismiss, countryLabel, ctaLabel }: Props) => {
  const onDismiss = useCallback(() => dismiss(false), [dismiss]);
  const onSubmit = useCallback(async () => dismiss(true), [dismiss]);

  return (
    <CommonDialog
      title="vendors.deliveryMethods.international.unsupportedCountry.title"
      textValues={{ countryLabel }}
      confirmText={ctaLabel}
      dismiss={onDismiss}
      showCancel={false}
      confirm={onSubmit}
      description="vendors.deliveryMethods.international.unsupportedCountry.subtitle"
      fullWidth
      hideIcon
      variant={DialogVariants.SUCCESS}
    />
  );
};
