import { useSelector } from 'react-redux';
import Box from 'src/core/ds/box/Box';
import { Checkbox } from 'src/core/ds/checkbox/Checkbox';
import { Skeleton } from 'src/core/ds/skeleton';
import { VStack } from 'src/core/ds/stack';
import { financingAnalyticsEvents } from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { TermsAndConditions } from 'src/pages/bill/pay/installments/pages/components/TermsAndConditions';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { analytics } from 'src/services/analytics';

type Props = {
  isChecked: boolean;
  disabled?: boolean;
  isLoading: boolean;
  agreementContent?: string;
  setIsChecked: () => void;
};

export const FinancingAgreement = ({
  isChecked,
  setIsChecked,
  disabled = false,
  isLoading,
  agreementContent,
}: Props) => {
  const { payBillFlowUUID } = useSelector(getPayment);

  const onCheck = (event) => {
    analytics.track('confirm', financingAnalyticsEvents.loanAgreementCheck, { isChecked: event.target.checked });
    setIsChecked();
  };

  return (
    <>
      <Box border="1px" borderColor="grey.400" borderRadius="md">
        {isLoading ? (
          <VStack spacing={3} alignItems="flex-start" height="144px" margin={4}>
            <Skeleton variant="light" w="376px" />
            <Skeleton variant="light" w="400px" />
            <Skeleton variant="light" w="360px" />
            <Skeleton variant="light" w="344px" />
            <Skeleton variant="light" w="322px" />
            <Skeleton variant="light" w="284px" />
          </VStack>
        ) : (
          <Box
            marginTop={4}
            marginBottom={4}
            marginLeft={4}
            height="144px"
            marginRight={2}
            paddingRight={2}
            textStyle="body4"
            color="grey.700"
            overflowY="scroll"
            data-testId="financing-agreement"
          >
            {agreementContent}
          </Box>
        )}
        <Box borderTop="1px" borderTopColor="grey.400">
          <Box margin={4} alignContent="center">
            <Checkbox
              size="sm"
              isChecked={isChecked}
              isDisabled={isLoading || disabled}
              onChange={onCheck}
              label="bills.pay.confirm.financingAgreementApproval"
              data-testid="financing-approve-agreement"
            />
          </Box>
        </Box>
      </Box>
      <Box marginTop={6}>
        <TermsAndConditions payBillFlowUUID={payBillFlowUUID} />
      </Box>
    </>
  );
};
