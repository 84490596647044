import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { InvoiceStatus } from 'src/utils/consts';

const mapStatusToColorAndLabel = {
  [InvoiceStatus.SCHEDULED]: { color: 'blue.500', label: 'getPro.status.scheduled' },
  [InvoiceStatus.PENDING]: { color: 'yellow.500', label: 'getPro.status.pending' },
  [InvoiceStatus.IN_PROGRESS]: { color: 'black', label: 'getPro.status.processing' },
  [InvoiceStatus.FAILED]: { color: 'red.500', label: 'getPro.status.failed' },
  [InvoiceStatus.CANCELLED_BY_CUSTOMER]: { color: 'red.500', label: 'getPro.status.cancelled' },
  [InvoiceStatus.PAID]: { color: 'green.500', label: 'getPro.status.paid' },
};

export const StatusLabel = ({ status }: { status: InvoiceStatus }) => {
  if ([InvoiceStatus.CREATED, InvoiceStatus.MARKED_AS_PAID].includes(status)) {
    return null;
  }

  const { color, label } = mapStatusToColorAndLabel[status];

  return (
    <Box backgroundColor={color} borderRadius="2px" px={1} py="1px">
      <Box textTransform="uppercase" color="white" fontWeight="600">
        <MIFormattedText label={label} />
      </Box>
    </Box>
  );
};
