import { ModalHeader as ChakraModalHeader, ModalHeaderProps } from '@chakra-ui/modal';
import { VStack } from 'src/core/ds/stack';

export const ModalHeader = ({ children, ...rest }: ModalHeaderProps) => (
  <ChakraModalHeader {...rest}>
    <VStack spacing={4} w="full">
      {children}
    </VStack>
  </ChakraModalHeader>
);
