import * as React from 'react';
import { compose } from 'recompose';
import { Option } from 'src/components/common/MISingleSelect';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { BillStatus } from 'src/utils/consts';
import { stringifyQs } from 'src/utils/query-utils';
import {
  BillType,
  FieldType,
  Filters,
  ListSearchBarContextType,
  NavigateType,
  ValidationErrors,
} from 'src/utils/types';
import { withListNavigator } from './withNavigator';

type SetFilters = (filters: Filters) => void;

export type PageProps = {
  filters: Filters & { status: BillStatus };
  setFilters: SetFilters;
};

export type PageContainerProps = {
  id: string;
  navigate: NavigateType;
  children: React.ReactElement<any>;
  basePath: string;
  query: any;
  orgId: number;
  ownedVendorId: string;
  locationState: Record<string, unknown>;
  bill: BillType | null | undefined;
  fileStorageUrl: string | null | undefined;
  filePreviewUrls: string[] | null | undefined;
  isUploading: boolean;
  onChangeAttachment: (file: File, loadBillFromAttachment?: boolean) => void;
  onDeleteAttachment: () => void;
  onFieldChange: (value: FieldType) => void;
  validationErrors: ValidationErrors<any>;
  vendors: Array<Option>;
  setSelected: (arg0: any) => void;
} & PageProps &
  ListSearchBarContextType;

/**
 * @note Should be the last HoC in the composing chain
 */
function withListContainer() {
  return function (WrappedListContainer) {
    return function (props) {
      const { navigate } = useNavigator();

      const setFilters: SetFilters = (filters: Filters) => {
        const queryString = stringifyQs({ ...{ id: props.id }, ...filters });

        navigate(`${props.basePath}?${queryString}`, true);
      };

      return <WrappedListContainer {...props} setFilters={setFilters} />;
    };
  };
}

export default () => compose(withListNavigator(), withListContainer());
