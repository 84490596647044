import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import accountingSoftwareSync from 'src/services/api/accountingSoftwareSync';
import { pushNotification } from 'src/services/notifications/notificationService';
import { NotificationVariant } from 'src/utils/consts';
import SyncQBDPageFooterMobile from './components/SyncQBDPageFooterMobile';

type Props = {
  onExit: () => void;
};

const SyncQBDMobileBlockedPage = ({ onExit }: Props) => {
  const orgId = useSelector(getOrgId);

  const onFooterClick = () => {
    analytics.trackAction('send-email');
    accountingSoftwareSync.requestCodatQbdLink({ orgId }).then(() => {
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'settings.newAccountingSoftware.QuickbooksDesktopSync.mobile.toastMessage',
      });
      onExit();
    });
  };

  return (
    <StepLayoutPage
      title="settings.newAccountingSoftware.QuickbooksDesktopSync.mobile.title"
      subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.mobile.subtitle"
      nextLabel="settings.newAccountingSoftware.QuickbooksDesktopSync.mobile.action"
      onNext={onExit}
      footer={<SyncQBDPageFooterMobile onClick={onFooterClick} />}
      illustration={IllustrationName.phoneHand}
    />
  );
};

export default SyncQBDMobileBlockedPage;
