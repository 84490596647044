import { createContext, FC, useState } from 'react';
import { BusinessApplicationContextType } from 'src/pages/bill/pay/installments/context/business-context/useBusinessApplicationContext';
import {
  BusinessDetailsModel,
  BusinessType,
} from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { BusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';

export const BusinessApplicationContext = createContext<BusinessApplicationContextType | null>(null);

const calculateTaxId = (type: BusinessDetailsModel['type'], taxIdNumber: string): string =>
  type !== BusinessType.SoleProprietorship ? taxIdNumber : '';

export const BusinessApplicationContextProvider: FC = ({ children }) => {
  const [businessDetailsApplication, setBusinessDetailsApplication] = useState<
    BusinessApplicationContextType['business'] | null
  >(null);

  const [ownershipDetailsApplication, setOwnershipDetailsApplication] = useState<
    BusinessApplicationContextType['ownership'] | null
  >(null);

  const setBusinessDetails = (businessDetails: BusinessDetailsModel) => {
    setBusinessDetailsApplication({
      name: businessDetails.companyName,
      legalName: businessDetails.legalCompanyName,
      legalAddressLine1: businessDetails.addressLine1,
      legalCity: businessDetails.city,
      legalState: businessDetails.state,
      legalZipCode: businessDetails.zipCode,
      totalAnnualRevenue: +businessDetails.annualRevenue,
      type: businessDetails.type as BusinessType,
      phone: businessDetails.ownerPhone,
      taxId: calculateTaxId(businessDetails.type, businessDetails.taxIdNumber),
      industry: businessDetails.industry,
    });
  };

  const setOwnershipDetails = ({ owner, beneficialOwners }: BusinessOwnershipDetailsModel) => {
    setOwnershipDetailsApplication({
      owner: {
        firstName: owner.firstName,
        lastName: owner.lastName,
        email: owner.email,
        dateOfBirth: owner.dateOfBirth,
        phone: owner.phone,
        addressLine1: owner.addressLine1,
        city: owner.city,
        state: owner.state,
        zipCode: owner.zipCode,
        ssn: owner.ssn,
        ownershipPercentage: owner.ownershipPercentage,
      },
      beneficialOwners: beneficialOwners.map((beneficialOwner) => ({
        firstName: beneficialOwner.firstName,
        lastName: beneficialOwner.lastName,
        email: beneficialOwner.email,
        ownershipPercentage: beneficialOwner.ownershipPercentage,
        dateOfBirth: beneficialOwner.dateOfBirth,
        ssn: beneficialOwner.ssn,
      })),
    });
  };

  return (
    <BusinessApplicationContext.Provider
      value={{
        business: businessDetailsApplication,
        ownership: ownershipDetailsApplication,
        setBusinessDetails,
        setOwnershipDetails,
      }}
    >
      {children}
    </BusinessApplicationContext.Provider>
  );
};
