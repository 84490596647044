import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type Props = {
  isError?: boolean;
  optionalLabelText?: string;
};

export const OptionalLabel = ({ isError, optionalLabelText }: Props) => (
  <Box textStyle="body4" color={isError ? 'red.500' : 'grey.600'} ms={1}>
    <MIFormattedText label={optionalLabelText || 'input.optional'} />
  </Box>
);
