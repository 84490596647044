import colors from 'src/core/theme/foundations/colors';

export const iconStyle = {
  svg: {
    display: 'inline',
    height: '1.4rem',
    position: 'relative',
    top: 0.5,
  },
};

export const tableWrapperStyle = {
  pb: 6,
  table: {
    tr: {
      th: {
        top: 0,
      },
    },
  },
};

export const containerStyle = {
  width: 'full',
  pt: 0,
  table: {
    position: 'relative',
    overflow: 'auto',
    zIndex: 1,
  },
  tbody: {
    minH: '50vh',
    overflow: 'overlay',
  },
  caption: {
    backgroundColor: colors.white,
    marginTop: 0,
    h: '100%',
  },
  tr: {
    pointer: 'cursor',
    _hover: {
      cursor: 'pointer',
    },
    td: {
      alignSelf: 'center',
      borderBottom: 'none',
      '&:last-of-type': {
        padding: 0,
        borderRight: 'none !important',
      },
      '&:first-of-type': {
        borderLeft: 'none !important',
      },
    },
    th: {
      backgroundColor: 'grey.200',
      display: 'table-cell',
      alignSelf: 'center',
      borderBottom: 'none',
      position: 'sticky',
      top: '10.5rem',
      zIndex: 10,
      '>div': {
        display: 'flex',
        height: '4.9rem',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: 'grey.300',
        borderTop: 'none',
        borderBottom: '1px',
        backgroundColor: colors.white,
        '>div': {
          border: 'none',
          color: 'black',
        },
      },
      '&:last-of-type': {
        padding: 0,
      },
      '&:first-of-type': {
        width: '5.9rem',
      },
    },
  },
};

export const noContentStateStyle = {
  width: 'full',
  pt: 0,
  backgroundColor: colors.white,
  marginTop: 0,
  h: '60vh',
};

export const emptyStateStyle = {
  display: 'flex',
  minHeight: '50vh',
  py: 8,
  justifyContent: 'center',
  maxW: '40rem',
  m: '0 auto',
};
