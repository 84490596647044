import styled from 'styled-components';
import { ShadowContainer } from 'src/components/common/ShadowContainer';
import Box from 'src/core/ds/box';
import { useClientBgColor } from '../hooks/useClientBgColor';
import { ClientType } from '../types';
import { ClientRow } from './ClientRow';

type ClientsPreviewProps = {
  clients: readonly ClientType[];
  firmOrgId: number;
};

export const ClientsPreview = ({ clients, firmOrgId }: ClientsPreviewProps) => {
  const { getColorProps } = useClientBgColor();

  return (
    <ClientPreviewRoot>
      {clients.map((item, index) => (
        <Box key={index} mb={index === clients.length - 1 ? 0 : 4}>
          <ClientRow color={getColorProps(item.companyName).color} isFirm={item.id === firmOrgId} {...item} />
        </Box>
      ))}
    </ClientPreviewRoot>
  );
};

const ClientPreviewRoot = styled(ShadowContainer)`
  border: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  font-family: ${(props) => props.theme.fontFamily};
`;
