export const headers = [
  {
    key: 'companyName',
    label: 'getPaid.new.create.batch.reviewColumnNames.companyName',
    width: '27.4rem',
  },
  {
    key: 'amount',
    label: 'getPaid.new.create.batch.reviewColumnNames.amount',
    width: '19.1rem',
  },
  {
    key: 'invoiceNumber',
    label: 'getPaid.new.create.batch.reviewColumnNames.invoiceNumber',
    width: '27.3rem',
  },
  {
    key: 'dueDate',
    label: 'getPaid.new.create.batch.reviewColumnNames.dueDate',
    width: '16.2rem',
  },
];
