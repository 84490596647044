import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { spinAnimation } from 'src/components/accounting-software/SyncNow/syncNowStyle';
import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import Tooltip from 'src/core/ds/tooltip';
import { useTriggerSync } from 'src/hooks/accounting-software/useTriggerSync';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { SyncNowEventsOrigin } from 'src/modules/accounting-platforms/consts';
import { analytics } from 'src/services/analytics';

export const SyncNowConnectedView = () => {
  const accountingPlatformSummary = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.syncSummary);
  const { triggerSync, startPollingStatus } = useTriggerSync();
  const isSyncing = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.isSyncing);
  const isPolling = useSelector(accountingPlatformsStore.selectors.triggerSync.isPolling);

  useEffect(() => {
    if (isSyncing && !isPolling) {
      startPollingStatus();
    }
  }, [isSyncing, isPolling, startPollingStatus]);

  const onSyncNowButtonClick = () => {
    analytics.track('top-bar', 'accountingSoftwareManualSync-clicked', {
      entryPointName: SyncNowEventsOrigin.TOP_BAR,
      AccountingSoftwareType: accountingPlatformSummary.accountingPlatformName,
    });
    triggerSync();
  };

  return (
    <Flex alignItems="center">
      <Tooltip
        data-testid="top-bar-sync-now-button-tooltip"
        placement="top"
        label={
          <MIFormattedText
            label="settings.newAccountingSoftware.syncNowToAccountingSoftware"
            values={{
              accountingSoftwareName: accountingPlatformSummary.accountingPlatformName,
              date: <MIFormattedDateTime date={accountingPlatformSummary.lastUpdateDate} />,
            }}
          />
        }
      >
        <IconButton
          testId="top-bar-sync-now-button"
          aria-label="Options"
          onClick={onSyncNowButtonClick}
          disabled={isSyncing}
          animation={isSyncing ? spinAnimation : ''}
          icon={<Icon name={IconNames.refresh} />}
        />
      </Tooltip>
      {isSyncing && (
        <Box data-testid="top-bar-sync-now-syncing" as="span" ml={2} color="grey.600" textStyle="body4Semi">
          <MIFormattedText label="settings.newAccountingSoftware.syncing" />
        </Box>
      )}
    </Flex>
  );
};
