import { PendingDeliveryMethodType } from 'src/utils/types';
import { fetchRequest } from './api';

export const pendingDeliveryMethodsApi = {
  getPendingDeliveryMethods: (
    orgId: number,
    vendorId: number | null
  ): Promise<{ pendingDeliveryMethods: PendingDeliveryMethodType[] }> => {
    const url = `/orgs/${orgId}/vendors/${vendorId}/pending-delivery-methods`;

    return fetchRequest(url);
  },
};
