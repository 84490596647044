import { generatePath, Link as RouteLink } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { useOrgId } from 'src/hooks';
import { SectionHeader } from 'src/pages/bill/components/BillsGallery/PaymentBatchView';
import { billLocations } from 'src/pages/bill/locations';
import { getBillsDefaultFilters, serializePaymentId } from 'src/utils/bills';
import { BillStatus, MiMoneyFlavor } from 'src/utils/consts';
import { convertNumberToCurrency } from 'src/utils/currency-utils';
import { PaymentType } from 'src/utils/types';

type Props = {
  amount: number;
  payment: PaymentType;
  linkLabel: string;
};

export const InstallmentAmountSubheader = ({ amount, payment, linkLabel }: Props) => {
  const orgId = useOrgId();

  if (!payment.billId) return null;

  const vendorName = payment.vendor?.companyName || '';

  const id = serializePaymentId(payment.billId, payment.id);
  const fullPaymentURL = generatePath(billLocations.filteredView, {
    orgId,
    id,
    ...getBillsDefaultFilters(BillStatus.PAID),
  });

  const viewPaymentLink = (
    <Link to={fullPaymentURL} as={RouteLink} data-testId="installment-view-payment">
      <MIFormattedText label="financing.installmentAmountSubheader.viewPaymentCTA" />
    </Link>
  );

  return (
    <SectionHeader>
      <Flex justify="space-between" align-items="center" direction="column">
        <Box color="main" textStyle="body2Semi" data-testId="payment-activity-title">
          <MIFormattedText label="bills.form.paymentActivity.installmentAmount" />
        </Box>
        <Box textStyle="body3" data-testid="installment-view-delivery-status">
          <MIFormattedText
            label={linkLabel}
            values={{
              vendorName,
              viewPayment: viewPaymentLink,
              paymentAmount: convertNumberToCurrency(payment.amount),
            }}
          />
        </Box>
      </Flex>
      <MIMoney
        testId="payment-amount"
        amount={amount || 0}
        flavor={MiMoneyFlavor.DEFAULT}
        showAmountArrowIcon={false}
        privateData
      />
    </SectionHeader>
  );
};
