import Breakjs from 'breakjs';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreakpointListener } from 'src/hoc';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';
import { AmountSize } from 'src/pages/get-pro/components/summary/components/consts';
import SummaryAmount from 'src/pages/get-pro/components/summary/components/SummaryAmount';
import { useSelectedIds } from 'src/pages/regular-batch-payments/BatchSettings/useBatchSelection';

const componentLayout = Breakjs({
  short: 0,
  long: 1082,
});

function useShouldShowLongLabel() {
  const breakpint = useBreakpointListener(componentLayout);

  return breakpint === 'long';
}

export const Footer: React.FC = ({ children }) => {
  const { selectedIds } = useSelectedIds();
  const billIds = selectedIds.map((id) => id.toString());
  const totalBalance = useSelector(payListItemsStore.selectors?.totalAmount(billIds));
  const shouldShowLongLabel = useShouldShowLongLabel();

  return (
    <Box shadow="300" pos="absolute" bottom="0" left="0" w="full" bg="white" h="8.2rem">
      <Flex justify="space-between" p={5}>
        <Flex direction="column" flex={1}>
          <Box textStyle="body4Semi" color="grey.700" textTransform="uppercase">
            <MIFormattedText
              label={
                shouldShowLongLabel
                  ? 'regularBatchPayments.BillsGallery.Footer.totalAmount'
                  : 'regularBatchPayments.BillsGallery.Footer.totalAmountShort'
              }
            />
          </Box>
          <Box mb={1}>
            <SummaryAmount amount={totalBalance.toString()} size={AmountSize.LARGE} />
          </Box>
        </Flex>
        <Flex direction="row">{children}</Flex>
      </Flex>
    </Box>
  );
};
