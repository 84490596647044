import { AddOptionComponent } from 'src/components/common/DropDown/components/AddOptionComponent';
import { BottomButtonComponent } from 'src/components/common/DropDown/components/BottomButtonComponent';
import {
  BottomLinkComponent,
  BottomLinkComponentProps,
} from 'src/components/common/DropDown/components/BottomLinkComponent';
import { NoOptions } from 'src/components/common/DropDown/components/NoOptions';
import {
  BottomListComponentProps,
  listHeight,
  MIDropDown,
  MIDropDownProps,
  optionHeight,
} from 'src/components/common/DropDown/MIDropDown';

type Props<T> = {
  buttonAction?: () => void;
  buttonLabel?: string;
  createLabel?: string;
  noOptionsLabel?: string;
  placeholder?: string;
  link?: BottomLinkComponentProps;
} & MIDropDownProps<T>;

export const MIEnhancedDropDown = <T,>({ buttonAction, buttonLabel, link, ...props }: Props<T>) => {
  /* eslint-disable react/jsx-indent */
  const bottomButton =
    buttonAction && buttonLabel
      ? ({ filteredOptions }: BottomListComponentProps) =>
          filteredOptions && (
            <BottomButtonComponent
              label={buttonLabel}
              action={buttonAction}
              shadow={filteredOptions.length > optionHeight / listHeight}
            />
          )
      : undefined;
  const bottomLink = link && (() => <BottomLinkComponent {...link} />);
  const bottomListComponent = bottomButton || bottomLink;
  const firstCustomOption =
    props.allowCustom && props.createLabel
      ? (firstCustomOptionProp) => <AddOptionComponent {...firstCustomOptionProp} label={props.createLabel} />
      : undefined;
  /* eslint-enable react/jsx-indent */
  const emptyStateComponent = (noOptionProps) => <NoOptions {...noOptionProps} noOptionsLabel={props.noOptionsLabel} />;

  return (
    <MIDropDown
      {...props}
      bottomListComponent={bottomListComponent}
      firstCustomOption={firstCustomOption}
      noOptionsComponent={emptyStateComponent}
    />
  );
};
