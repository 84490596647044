import isNil from 'lodash/isNil';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { AmountCurrencyField } from 'src/core/ds/form/fields';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useReplaceApprovalThreshold } from 'src/hooks/accountants/approval-workflows/useReplaceApprovalThreshold';
import invitationStore, { getInvitationActions } from 'src/modules/invitations/invitations-store';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { loadCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { FormContainer } from 'src/ui/form/FormElements';
import { SelectCategory, useForm } from 'src/ui/form/index';
import { InlineCheckboxField } from 'src/ui/form/InlineCheckboxField';
import { Role } from 'src/utils/consts';
import { useSyncSkipAmountToRequireApproval } from './hooks';

type Props = {
  onPrev: () => void;
  onExit: () => void;
  onNext: () => void;
  newEmailToInvite?: string;
};

const requireApprovalOptions = [
  {
    id: 1,
    label: 'categoryList.yes',
  },
  {
    id: 0,
    label: 'categoryList.no',
  },
];
type RequireApprovalForm = {
  approvalAmountThreshold: string | null;
  requireApproval: boolean | null | undefined | string;
  amountNotKnown: boolean;
};
export const NewCompanyRequireApprovalPage = ({ onPrev, onExit, onNext }: Props) => {
  const dispatch = useDispatch();
  const { companyName, contactFirstName, contactLastName } = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const organizationPreferences = useOrganizationPreferences();
  const { userOrg, isUpdating } = useStructuredSelectors(usersStore.selectors.userOrganization(userId, orgId));
  const invitationActions = getInvitationActions(dispatch);
  const isInviting = useSelector(invitationStore.selectors.isCreating);
  const [skipSetAmount, setSkipAmount] = useState<boolean>(false);
  const { replaceApprovalThreshold } = useReplaceApprovalThreshold({ orgId, skipSetAmount, userId });

  const loadCompanyInfo = () =>
    new Promise((resolve, reject) => {
      dispatch(loadCompanyInfoAction(resolve, reject));
    });

  const submitAction = async (value) => {
    await replaceApprovalThreshold({ ...userOrg, ...value });

    if (organizationPreferences.uninvitedOwnerEmail) {
      const params = {
        firstName: contactFirstName,
        lastName: contactLastName,
        role: Role.OWNER,
        email: organizationPreferences.uninvitedOwnerEmail,
        orgId,
      };
      await invitationActions.create(params, {
        disableNotification: true,
      });
    }

    loadCompanyInfo();
    onNext();
  };
  const [requireApprovalVM, { submit }] = useForm<RequireApprovalForm>(
    {
      approvalAmountThreshold: null,
      requireApproval: null,
      amountNotKnown: false,
    },
    {
      submit: submitAction,
      onetimeModel: true,
    }
  );
  useSyncSkipAmountToRequireApproval(requireApprovalVM.requireApproval.value, setSkipAmount);

  const isApprovalRequired = !!requireApprovalVM.requireApproval.value;

  return (
    <StepLayoutPage
      testId="new-company-require-approval"
      title="inviteNewCompany.accountantFlow.requireApprovalTitle"
      titleValues={{
        companyName: (
          <Box as="span" key={companyName} color="grey.700">
            {companyName}
          </Box>
        ),
      }}
      relativeStep={7 / 7}
      onNext={submit}
      onPrev={onPrev}
      goExit={onExit}
      nextLabel="progress.continue"
      isNextDisabled={isNil(requireApprovalVM.requireApproval.value)}
      isLoading={isInviting || isUpdating}
    >
      <FormContainer onSubmit={submit} mb="0">
        <SelectCategory options={requireApprovalOptions} model={requireApprovalVM.requireApproval} />
        {isApprovalRequired && (
          <>
            <AmountCurrencyField
              label="inviteNewCompany.accountantFlow.enterAmount"
              model={requireApprovalVM.approvalAmountThreshold}
              hint="inviteNewCompany.accountantFlow.notice"
              isViewOnly={requireApprovalVM.amountNotKnown.value}
              placeholder="inviteNewCompany.accountantFlow.amountPlaceholder"
              decimalScale={2}
              fixedDecimalScale
              autoFocus
              isRequired
            />

            <InlineCheckboxField
              label="inviteNewCompany.accountantFlow.isAmountKnown"
              labelValues={{
                ownerName: `${contactFirstName} ${contactLastName}`,
              }}
              model={requireApprovalVM.amountNotKnown}
            />
          </>
        )}
      </FormContainer>
      {!isApprovalRequired && (
        <Flex align="center" mb={2}>
          <Box mr={2}>
            <Icon name={IconNames.helpCircle} size={IconSize.lg} color="grey.700" />
          </Box>
          <Box color="grey.700" textAlign="left" textStyle="body4">
            <MIFormattedText label="inviteNewCompany.accountantFlow.notSureRequireApprovalDescription" />
          </Box>
        </Flex>
      )}
    </StepLayoutPage>
  );
};
