import { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { MICategoryList } from 'src/components/common/MICategoryList';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { withNavigator } from 'src/hoc';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CompanyInfoOnboardingOrigin, OrganizationCreateOrigin } from 'src/utils/consts';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';
import { CompanyInfoProps, withCompanyInfoUpdates } from './hoc/withCompanyInfoUpdates';

const paymentVolumeOptions = [
  {
    id: '10000',
    label: 'onboarding.companyInfo.monthlyPaymentVolume.values.first',
  },
  {
    id: '10000-50000',
    label: 'onboarding.companyInfo.monthlyPaymentVolume.values.second',
  },
  {
    id: '50000 - 150000',
    label: 'onboarding.companyInfo.monthlyPaymentVolume.values.third',
  },
  {
    id: '150000+',
    label: 'onboarding.companyInfo.monthlyPaymentVolume.values.fourth',
  },
];
type Props = {
  nextStepURL: string;
  prevStepURL: string;
  locationState: Record<string, any>;
} & CompanyInfoProps;

const CompanyMonthlyPaymentVolumePage = ({
  navigateToCompanyOnboardingPage,
  nextStepURL,
  prevStepURL,
  locationState,
}: Props) => {
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isOrganizationPreferencesUpdating = useSelector(organizationStore.selectors.isOrganizationPreferencesUpdating);
  const organizationPreferences = useSelector(organizationStore.selectors.isOrganizationPreferencesUpdating);
  const organizationActions = useStoreActions(organizationStore);
  const [monthlyPaymentVolume, setMonthlyPaymentVolume] = useState<null | string>(
    organizationPreferences.onboardingCompanyTPV
  );
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();

  const onSubmit = async () => {
    await organizationActions.updateOrganizationPreference({
      orgId,
      key: 'onboardingCompanyTPV',
      value: monthlyPaymentVolume,
    });
    onSkip();
  };
  const isAccountantAddCompanyFlow =
    locationState.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY ||
    organizationPreferences.uninvitedOwnerEmail;

  const onSkip = () => {
    // to allow the next page to send the origin flow of accountants client creation
    const overrideState: { origin: string } | undefined =
      companyInfo.createOrigin === OrganizationCreateOrigin.ADD_COMPANY
        ? { origin: CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY }
        : undefined;
    navigateToCompanyOnboardingPage(nextStepURL, undefined, overrideState);
  };

  const onPrev = () => {
    navigateToCompanyOnboardingPage(prevStepURL);
  };

  const renderFooter = () => (
    <WizardElements.WizardButtonContainer>
      <Button label="progress.skip" variant={ButtonVariants.tertiaryNaked} onClick={onSkip} />
    </WizardElements.WizardButtonContainer>
  );

  const getProgressBarRelativeStep = () => {
    if (isAccountantAddCompanyFlow) {
      return 3 / 6;
    }

    if (isUnifiedOnboardingOpen) {
      return 6 / 13;
    }

    return 6 / 7;
  };

  return (
    <StepLayoutPage
      title="onboarding.companyInfo.monthlyPaymentVolume.title"
      titleValues={{
        companyName: <Title>{checkApostropheForPluralPossessive(companyInfo.companyName ?? '')}</Title>,
      }}
      relativeStep={getProgressBarRelativeStep()}
      onNext={onSubmit}
      isNextDisabled={!monthlyPaymentVolume}
      onPrev={onPrev}
      footer={renderFooter()}
      isLoading={isOrganizationPreferencesUpdating}
    >
      <MICategoryList
        selectedId={monthlyPaymentVolume}
        categories={paymentVolumeOptions}
        onSelect={(id) => setMonthlyPaymentVolume(id)}
      />
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default compose(withNavigator(), withCompanyInfoUpdates())(CompanyMonthlyPaymentVolumePage);
