import { useMemo } from 'react';
import * as React from 'react';
import { TableToggleAllRowsSelectedProps } from 'react-table';
import { Checkbox } from 'src/core/ds/checkbox';

type Props = Partial<TableToggleAllRowsSelectedProps> & {
  isDisabled?: boolean;
};

export const HeaderCheckboxCell: React.FC<Props> = ({ checked, indeterminate, ...rest }: Props) =>
  useMemo(
    () => <Checkbox data-testid="header-checkbox-cell" isIndeterminate={indeterminate} isChecked={checked} {...rest} />,
    [checked, indeterminate, rest]
  );

export const RowCheckboxCell: React.FC<Props> = ({ checked, indeterminate, ...rest }: Props) =>
  useMemo(
    () => <Checkbox data-testid="row-checkbox-cell" isIndeterminate={indeterminate} isChecked={checked} {...rest} />,
    [checked, indeterminate, rest]
  );
