import * as FullStory from '@fullstory/browser';
import SentryFullStory from '@sentry/fullstory';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import get from 'lodash/get';
import config from 'src/config';
import { ServerError } from 'src/services/api/ServerError';
import { version } from 'src/version';

const absPathBlock = [
  'analytics.tiktok.com',
  'h.clarity.ms',
  'js.intercomcdn.com',
  'maps.googleapis.com',
  'launchdarkly-js-sdk',
  'googlertagmanager.com',
  'safari-extension',
  'safari-web-extension',
  'chrome-extension',
  'cdn.nmgassets.com',
] as const;
const verifyAbsPaths = (stackTrace: Sentry.Stacktrace | undefined) =>
  !stackTrace ? true : !stackTrace.frames?.some((frame) => absPathBlock.some((path) => frame.filename?.includes(path)));

export const capture = (error: Error, errorInfo?: any) =>
  Sentry.withScope((scope) => {
    scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });

const convertErrorNetwork = (error: Error) => {
  const url = get(error, 'config.url', null) as string | null;
  const method = get(error, 'config.method', null) as string | null;
  const code = get(error, 'response.status', null) as number | string | null;
  const data = get(error, 'response.data', null) as any;
  const params = get(error, 'config.data', null);
  const message = get(error, 'response.statusText', null) as string | null;
  const internalError = get(error, 'response.data.error') as string | undefined;

  return new ServerError({ error: internalError, url, method, code, message, params, data });
};

export const configTracking = () => {
  if (config.sentry.enable && config.sentry.dsn) {
    FullStory.init({ orgId: config.fullstory.orgId });
    Sentry.init({
      dsn: config.sentry.dsn,
      release: version,
      environment: process.env.REACT_APP_ENV || 'N/A',
      attachStacktrace: true,
      normalizeDepth: 3,
      ignoreErrors: ['Non-Error promise rejection captured'],
      integrations: [
        new TracingIntegrations.BrowserTracing({
          beforeNavigate: (context) => ({
            ...context,
            // eslint-disable-next-line no-restricted-globals
            name: location.pathname
              .replace(/\/meliome\/pay\/[a-z,A-Z,0-9]+/g, '/meliome/pay/:vendor')
              .replace(/\/[a-f0-9]{32}/g, '/<hash>')
              .replace(/\/\d+/g, '/<digits>'),
          }),
        }),
        new SentryFullStory(config.sentry.org),
      ],
      tracesSampleRate: 0.1,
      beforeSend(event: Sentry.Event) {
        const stackTrace = event.exception?.values?.[0].stacktrace;

        if (!verifyAbsPaths(stackTrace)) {
          return null;
        }

        return event;
      },
    });
  }
};

export const report = () => {
  const lastEventId = Sentry.lastEventId();

  if (lastEventId) {
    Sentry.showReportDialog();
  }
};

export const setSiteContext = (context) => {
  Sentry.setTag('site_context', context);
};

export const captureNetworkError = (error: Error) => {
  const networkError = error instanceof ServerError ? error : convertErrorNetwork(error);
  const { message, code, url, method, requestData, responseData } = networkError;
  const extra = {
    message,
    code,
    url,
    method,
    requestData,
    responseData,
  };

  return Sentry.withScope((scope) => {
    scope.setExtras(extra);
    Sentry.captureException(networkError);
  });
};
