import { MIFormattedText, TextProps } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { ListItem, OrderedList } from 'src/core/ds/list/inedx';
import { Stack } from 'src/core/ds/stack';

const microDepositInfoStyle = {
  textStyle: 'body2',
  px: 6,
  pt: 6,
  pb: 2,
  border: '1px',
  borderColor: 'grey.500',
  borderRadius: 'lg',
  background: 'white',
  textAlign: 'left',
};

type Props = {
  infoOptions: TextProps[];
};

export const MicroDepositInfo = ({ infoOptions }: Props) => (
  <Box sx={microDepositInfoStyle}>
    <Box textStyle="body2Semi">
      <MIFormattedText label="onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.subtitle" />
    </Box>
    <Box textStyle="body2" textAlign="left">
      <OrderedList pb={0} ps={1}>
        <Stack spacing={5}>
          {infoOptions.map(({ label, values }) => (
            <ListItem>
              <MIFormattedText label={label} values={values} />
            </ListItem>
          ))}
        </Stack>
      </OrderedList>
    </Box>
  </Box>
);
