import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { BatchActionType } from 'src/hooks/accountants/useCheckboxOptions';

type Props = {
  enableBatchSelection: boolean;
  markedIds: { id: number; [key: string]: any }[];
  batchAction: BatchActionType;
  markAll: () => void;
  clearAll: () => void;
};

export const CheckboxSelectionMenu = ({ markAll, batchAction, clearAll }: Props) => (
  <Flex
    alignItems="center"
    paddingInline={4}
    paddingBlockEnd={5}
    width="full"
    data-testid="import-qboa-clients-checkbox-container"
  >
    {batchAction === 'clearAll' ? (
      <Box textStyle="link1" onClick={clearAll} data-testid="unselect-all">
        <MIFormattedText label="companies.importQboa.verifyImports.unselectAll" />
      </Box>
    ) : (
      <Box textStyle="link1" onClick={markAll} data-testid="select-all">
        <MIFormattedText label="companies.importQboa.verifyImports.selectAll" />
      </Box>
    )}
  </Flex>
);
