import { FormatDateOptions, FormattedDate } from 'react-intl';

type Props = {
  date?: Date | string | null;
  placeholder?: string;
} & FormatDateOptions;

export const MIFormattedDateTime = ({ date, ...props }: Props) => {
  const value = date && new Date(date);

  return value ? (
    <FormattedDate
      value={value}
      day="numeric"
      month="short"
      year="numeric"
      hour="numeric"
      minute="numeric"
      {...props}
    />
  ) : null;
};
