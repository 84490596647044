import { featureFlags } from '@melio/shared-web';
import compact from 'lodash/compact';
import { FeatureFlags, PaymentApprovalDecisionStatus, PaymentApprovalStatus } from 'src/utils/consts';
import { PaymentType, UserContextType, UserSummaryType } from 'src/utils/types';
import { getUserOrganization } from 'src/utils/user';

const getApprovalDecisionsByStatus = (
  payment: PaymentType,
  status: PaymentApprovalDecisionStatus,
  orgUsers: ReadonlyArray<UserSummaryType & Pick<UserContextType, 'userOrganizations'>>
) => {
  const approvalDetail = payment.paymentApprovalDecisions
    .filter((decision) => decision.status === status)
    .map((decision) => {
      const user = orgUsers.find((user) => user.id === decision.userId);

      return (
        user && {
          actionDate: decision.createdAt,
          reason: decision.reason,
          role: getUserOrganization(user, payment.organizationId)?.role,
          user,
        }
      );
    });

  return compact(approvalDetail);
};

export const useApprovalDecisions = (
  payment: PaymentType,
  orgUsers: ReadonlyArray<UserSummaryType & Pick<UserContextType, 'userOrganizations'>>
) => {
  const [enableApprovalWorkflows] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);

  const updatedPayment = enableApprovalWorkflows
    ? payment
    : {
        ...payment,
        paymentApprovalDecisions: [
          {
            status: payment.approvalDecisionStatus,
            createdAt: payment.approvalDecisionDate,
            reason:
              payment.approvalDecisionStatus === PaymentApprovalStatus.DECLINED
                ? payment.approvalDecisionReason
                : undefined,
            userId: payment.approvalDecisionById,
          },
        ],
      };

  return {
    approvedBy: getApprovalDecisionsByStatus(updatedPayment, PaymentApprovalDecisionStatus.APPROVED, orgUsers),
    declinedBy: getApprovalDecisionsByStatus(updatedPayment, PaymentApprovalDecisionStatus.DECLINED, orgUsers),
  };
};
