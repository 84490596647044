import { Flex } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { BrandFullLogo, BrandName } from 'src/core/ds/brandFullLogo';
import { Link } from 'src/core/ds/link';
import { financingAnalyticsEvents } from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { analytics } from 'src/services/analytics';

type TermsAndConditionsProps = {
  payBillFlowUUID?: string;
};

export const TermsOfService = ({
  onClick,
  label = 'financing.common.termsOfService',
}: {
  label?: string;
  onClick: () => void;
}) => (
  <Link
    color="grey.700"
    textStyle="caption1"
    href="https://www.creditkey.com/legal/terms-of-service-and-electronic-disclosures"
    textDecorationLine="underline"
    isExternal
    onClick={onClick}
  >
    <MIFormattedText label={label} />
  </Link>
);

export const PrivacyPolicy = ({ onClick }: { onClick: () => void }) => (
  <Link
    color="grey.700"
    textStyle="caption1"
    href="https://www.creditkey.com/legal/privacy-policy"
    textDecorationLine="underline"
    isExternal
    onClick={onClick}
  >
    <MIFormattedText label="financing.common.privacyPolicy" />
  </Link>
);

export const CreditKey = ({ onClick }: { onClick: () => void }) => (
  <Link
    color="grey.700"
    textStyle="caption1"
    href="https://www.creditkey.com/"
    textDecorationLine="underline"
    isExternal
    onClick={onClick}
  >
    <MIFormattedText label="financing.common.creditKey" />
  </Link>
);

export const TermsAndConditions = ({ payBillFlowUUID }: TermsAndConditionsProps) => {
  const handlePrivacyPolicyClick = () => {
    analytics.trackAction(financingAnalyticsEvents.privacyPolicyClick, payBillFlowUUID ? { payBillFlowUUID } : {});
  };

  const handleTermsOfServiceClick = () => {
    analytics.trackAction(financingAnalyticsEvents.termsOfServiceClick, payBillFlowUUID ? { payBillFlowUUID } : {});
  };

  const handleCreditKeyClick = () => {
    analytics.trackAction(financingAnalyticsEvents.creditKeyClick, payBillFlowUUID ? { payBillFlowUUID } : {});
  };

  const labelValues = {
    privacyPolicy: <PrivacyPolicy onClick={handlePrivacyPolicyClick} />,
    termsOfService: <TermsOfService onClick={handleTermsOfServiceClick} />,
    creditKey: <CreditKey onClick={handleCreditKeyClick} />,
  };

  return (
    <Flex>
      <Box mr={3}>
        <BrandFullLogo name={BrandName.creditKeyGrey} />
      </Box>
      <Box w="full" color="grey.700" textStyle="caption1">
        <MIFormattedText label="financing.common.termsAndConditions" values={labelValues} />
      </Box>
    </Flex>
  );
};
