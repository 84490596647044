import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { companiesLocations } from '../../locations';

type ErrorLayoutProps = {
  title: string;
  message: string;
};

export const CompanyTypeError = () => (
  <ErrorLayout
    title="companies.importQboa.errors.companyType.title"
    message="companies.importQboa.errors.companyType.message"
  />
);

export const ConnectionError = () => (
  <ErrorLayout
    title="companies.importQboa.errors.connection.title"
    message="companies.importQboa.errors.connection.message"
  />
);

export const NoClientsError = () => (
  <ErrorLayout
    title="companies.importQboa.errors.noClients.title"
    message="companies.importQboa.errors.noClients.message"
  />
);

const ErrorLayout = ({ title, message }: ErrorLayoutProps) => {
  const [historyPush] = useHistoryWithOrgId();

  const handleSubmit = () => {
    historyPush({
      path: companiesLocations.index,
    });
  };

  return (
    <WizardLayout>
      <WizardLayout.Content>
        <Flex flexDirection="column" alignItems="center" h="full" w="full" textAlign="center" marginBlockStart={20}>
          <Illustration name={IllustrationName.errorScreen} size={IllustrationSize.lg} />
          <Box textStyle="display2" marginBlockStart={14} fontWeight="bold">
            <MIFormattedText label={title} />
          </Box>
          <Box textStyle="body2" marginBlockStart={5}>
            <MIFormattedText label={message} />
          </Box>
          <Button
            onClick={handleSubmit}
            marginBlockStart={12}
            label="companies.importQboa.errors.submit"
            w="460px"
            size={ButtonSizes.lg}
          />
        </Flex>
      </WizardLayout.Content>
    </WizardLayout>
  );
};
