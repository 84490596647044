import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';
import { useFeesParams } from 'src/pages/regular-batch-payments/components/fees/hooks/useFeesParams';
import { FeeItem } from './FeeItem';

export const Fees: React.FC = () => {
  const { feesData } = useFeesParams();
  const orderedFees = [feesData.cards, feesData.fast, feesData.paperCheck];

  return (
    <Box data-testid="batch-payments-total-fees-wrapper">
      {feesData.total.amount === 0 ? (
        <FeeItem fee={feesData.transaction} />
      ) : (
        <Box cursor="pointer">
          {orderedFees.map((feeData) =>
            feeData.amount > 0 ? (
              <FeeItem
                key={feeData.key}
                fee={feeData}
                icon={feeData.tooltipLabel ? <FeeInfoToolTip label={feeData.tooltipLabel} /> : undefined}
                {...feeData.extraFeeItemProps}
              />
            ) : null
          )}
          <Tooltip placement="bottom" label={<MIFormattedText label="regularBatchPayments.tooltips.fee" />}>
            <Box data-testid="total-fee" mt={1}>
              <FeeItem fee={feesData.total} />
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

type FeeInfoToolTipProps = {
  label: string;
};
const FeeInfoToolTip = ({ label }: FeeInfoToolTipProps) => (
  <Tooltip placement="top" label={<MIFormattedText label={label} />}>
    <Flex mr={1} mt={-0.5} data-testid="fee-info-tooltip-icon">
      <Icon name={IconNames.infoCircle} size={IconSize.s} color="grey.700" />
    </Flex>
  </Tooltip>
);
