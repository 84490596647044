import type { PartsStyleFunction, PartsStyleObject } from '@chakra-ui/theme-tools';
import { calc, cssVar } from '@chakra-ui/theme-tools';

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

const $diff = cssVar('switch-track-diff');
const diffValue = calc.subtract($width, $height);

const $translateX = cssVar('switch-thumb-x');

const trackDisabledBg = {
  bg: 'grey.400',
};

const thumbDisabled = {
  bg: 'grey.300',
};

const baseStyleTrack = {
  borderRadius: '10px',
  p: '2px',
  width: [$width.reference],
  height: [$height.reference],
  transitionProperty: 'common',
  transitionDuration: 'fast',
  bg: 'grey.500',
  _disabled: {
    cursor: 'not-allowed',
    ...trackDisabledBg,
    _checked: {
      ...trackDisabledBg,
    },
  },
  _checked: {
    bg: 'green.500',
  },
};

const baseStyleThumb = {
  bg: 'white',
  transitionProperty: 'transform',
  transitionDuration: 'normal',
  borderRadius: 'inherit',
  width: [$height.reference],
  height: [$height.reference],
  _checked: {
    transform: `translateX(${$translateX.reference})`,
  },
};

const baseStyle: PartsStyleFunction = (props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
  },
  track: baseStyleTrack,
  thumb: props.isDisabled ? { ...baseStyleThumb, ...thumbDisabled } : baseStyleThumb,
});

const sizes: Record<string, PartsStyleObject> = {
  lg: {
    container: {
      [$width.variable]: '3.2rem',
      [$height.variable]: '1.6rem',
    },
  },
};

const defaultProps = {
  size: 'lg',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
