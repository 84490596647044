import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import Flex from 'src/core/ds/flex';
import { useIsOrganizationInMsnOnly } from 'src/modules/msn-portal/hooks/useIsOrganizationInMsnOnly';
import { profileStore } from 'src/modules/profile/profile-store';
import { FeatureFlags } from 'src/utils/consts';
import { BillType, Filters } from 'src/utils/types';
import { BillingFeeHistoryTable } from './BillingFeeHistoryTable';
import { BillingMethodCard } from './BillingMethodCard';

type Props = {
  internalBills: readonly BillType[];
  internalBillsTotal: number;
  onGeneratePdfInvoice: (billId: string, invoiceDate: Date) => void;
  filters: Filters;
};

export const BillingSettingsPage = (props: Props) => {
  const isPureMSNOrganization = useIsOrganizationInMsnOnly();
  const isUserOwnerOrAdmin = useSelector(profileStore.selectors.isAdmin);
  const [isBillingMethodCheckFeeEnabled] = featureFlags.useFeature<boolean>(FeatureFlags.BillingMethodCheckFee, false);
  const isBillingMethodCardVisible = isBillingMethodCheckFeeEnabled && isUserOwnerOrAdmin && !isPureMSNOrganization;

  return (
    <Flex direction="column" gap={10}>
      {isBillingMethodCardVisible && <BillingMethodCard />}
      <BillingFeeHistoryTable {...props} />
    </Flex>
  );
};
