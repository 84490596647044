import { BusinessContactInfo } from './types';

export const getOrderedBusinessContactInfo = (businessContactInfo: BusinessContactInfo) => {
  if (businessContactInfo) {
    const fieldsOrder: (keyof BusinessContactInfo)[] = ['fullName', 'email', 'phone', 'deliveryMethodLabel'];

    return fieldsOrder.map((sortKey) => ({ key: sortKey, value: businessContactInfo[sortKey] }));
  }

  return [];
};
