import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  tooltipLabel: string;
};

export const DeliveryMethodInfoIcon = ({ tooltipLabel }: Props) => (
  <Tooltip data-testid="deliveryMethods-tooltip" placement="top" label={<MIFormattedText label={tooltipLabel} />}>
    <Box data-testid="deliveryMethods-info-icon" as="i" fontSize="1.6rem" pl={1}>
      <Icon name={IconNames.infoCircle} size={IconSize.s} />
    </Box>
  </Tooltip>
);
