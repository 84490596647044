import { featureFlags } from '@melio/shared-web';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { removeUnsupportedDeliveryOptionsByBill } from 'src/utils/delivery-methods';
import { FeatureFlags } from 'src/utils/featureFlags';
import { AccountType, BillType, DeliveryOptionType, PaymentType } from 'src/utils/types';

type Params = {
  bill: BillType;
  payment: PaymentType;
  fundingSource: AccountType;
  isFinancingPayment?: boolean;
};

export const useDeliveryOptionForTime = ({ bill, payment, fundingSource, isFinancingPayment }: Params) => {
  const [isFastCheckEnabledForAllStates] = featureFlags.useFeature<boolean>(
    FeatureFlags.FastCheckEnabledForAllStates,
    false
  );
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptionType[]>([]);
  const [loading, setLoading] = useState(false);
  const companyInfo = useSelector(getCompanyInfo);
  const site = useSiteContext();

  useEffect(() => {
    setLoading(true);
    deliveryApi
      .getDeliveryTime(
        bill.organizationId,
        null,
        payment.deliveryMethodId,
        payment.fundingSourceId,
        payment.amount,
        payment.id,
        payment.payBillFlowUUID
      )
      .then(({ deliveryOptions }) => {
        const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
          site,
          deliveryOptions,
          companyInfo,
          bill,
          payment,
          fundingSource,
          isFastCheckEnabledForAllStates,
          isFinancingPayment,
        });

        setDeliveryOptions(possibleDeliveryOptions);
        setLoading(false);
      })
      .catch(() => {
        setDeliveryOptions([]);
        setLoading(false);
      });
  }, [bill, companyInfo, payment, fundingSource, site, isFinancingPayment, isFastCheckEnabledForAllStates]);

  return { deliveryOptions, loading };
};
