import orderBy from 'lodash/orderBy';
import { DirectoryDetailsType, VendorOptionWithScoreType } from 'src/pages/vendor-directory/select-vendor/types';
import { createNetworkVendorOption } from 'src/pages/vendor-directory/select-vendor/utils';
import { DirectoryType } from 'src/utils/consts';

export const getDirectoryVendors = ({
  billers,
  businesses,
  isBusinessDirectorySearchEnabled,
}: {
  billers: DirectoryDetailsType[];
  businesses: DirectoryDetailsType[];
  isBusinessDirectorySearchEnabled: boolean;
}) => {
  const billerVendors = billers.map((biller) =>
    createNetworkVendorOption({
      networkVendor: biller,
      directoryType: DirectoryType.Biller,
    })
  );

  const businessVendors = businesses.map((business) =>
    createNetworkVendorOption({
      networkVendor: business,
      directoryType: DirectoryType.Business,
    })
  );

  return isBusinessDirectorySearchEnabled
    ? orderBy<VendorOptionWithScoreType>([...billerVendors, ...businessVendors], 'score', 'desc')
    : billerVendors;
};
