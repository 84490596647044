import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

export type BottomLinkComponentProps = {
  label: string;
  action: () => void;
  linkLabel: string;
};

export const BottomLinkComponent = ({ label, action, linkLabel }: BottomLinkComponentProps) => {
  const link = (
    <Box data-testid="dropdown-bottom-link" as="a" cursor="pointer" onClick={action} textStyle="link3">
      <MIFormattedText label={linkLabel} />
    </Box>
  );

  return (
    <Box textStyle="body4" shadow="500">
      <Box py={4} px={4} borderTop=".1rem solid" borderColor="grey.300">
        <MIFormattedText label={label} values={{ link }} />
      </Box>
    </Box>
  );
};
