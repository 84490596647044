import { useSelector } from 'react-redux';
import { getUsersOfOrg } from 'src/modules/users/users-store';
import { ApprovalDetailsType } from 'src/pages/bill/components/BillPaymentsActivity/components/PaymentApprovalLogs/types';
import { useApprovalDecisions } from 'src/pages/bill/components/BillPaymentsActivity/components/utils';
import { PaymentType } from 'src/utils/types';
import { getUserOrganization } from 'src/utils/user';
import { PaymentApprovalLogs as PaymentApprovalLogsView } from '../BillPaymentsActivity/components/PaymentApprovalLogs/PaymentApprovalLogs';

export const PaymentApprovalLogs: React.FC<{ payment: PaymentType }> = ({ payment }) => {
  const orgUsers = useSelector(getUsersOfOrg(payment.organizationId));
  const creator = orgUsers.find((user) => user.id === payment.createdById);

  const approvalDetails: ApprovalDetailsType = {
    ...useApprovalDecisions(payment, orgUsers),
    scheduledBy: {
      actionDate: payment.createdAt,
      role: creator ? getUserOrganization(creator, payment.organizationId)?.role : undefined,
      user: creator,
    },
  } as ApprovalDetailsType;

  return (
    <>
      <PaymentApprovalLogsView
        approvalDecisionStatus={payment.approvalDecisionStatus}
        approvalDetails={approvalDetails}
      />
    </>
  );
};
