import { ConnectionStatus } from 'src/utils/consts';
import { ConnectionErrorType } from './enums';

export const getConnectionErrorType = (
  connectionStatus?: ConnectionStatus,
  queryStringError?: string
): ConnectionErrorType | undefined => {
  if (queryStringError === 'access_denied') {
    return ConnectionErrorType.INVALID_CREDENTIALS;
  }

  if (queryStringError === 'diff_realmid') {
    return ConnectionErrorType.DIFFERENT_REALM_ID;
  }

  switch (connectionStatus) {
    case ConnectionStatus.INVALID_CREDENTIALS:
      return ConnectionErrorType.INVALID_CREDENTIALS;
    case ConnectionStatus.INVALID_SUBSCRIPTION:
      return ConnectionErrorType.INVALID_SUBSCRIPTION;
    case ConnectionStatus.UNKNOWN_ERROR:
      return ConnectionErrorType.GENERIC_ERROR;
    case ConnectionStatus.CONNECTED:
    case ConnectionStatus.DISCONNECTED:
    default:
      return undefined;
  }
};
