import { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { MaskField } from 'src/core/ds/form/fields';
import { Colors, MelioLogo } from 'src/core/ds/melioLogo';
import { Stack } from 'src/core/ds/stack';
import { useBreak } from 'src/hoc';
import { NotificationCardTypes } from 'src/utils/consts';
import { VerificationPageProps } from './types';

export const VerificationPage = ({
  title,
  titleValues,
  subtitle,
  subtitleValues,
  onClick,
  alertText,
  isLoading = false,
}: VerificationPageProps) => {
  const [amount1State, setAmount1] = useState(0.0);
  const [amount2State, setAmount2] = useState(0.0);
  const { isMobile } = useBreak();

  return (
    <Flex pt={35} textAlign="center" alignItems="center" flexDirection="column" data-testid="verification-page">
      <Stack direction="column" alignItems="center" spacing={12}>
        <MelioLogo color={Colors.grey} />
        <Box maxWidth={isMobile ? '32rem' : '42rem'}>
          <Stack spacing={4}>
            <Box textStyle="h1Semi">
              <MIFormattedText label={title} values={titleValues} />
            </Box>
            <Box textStyle="body2">
              <MIFormattedText label={subtitle} values={subtitleValues} />
            </Box>
          </Stack>
          {alertText ? (
            <Box data-testid="verification-alert" width="full" mt={6}>
              <MINotificationCard
                subtitle={{
                  label: alertText,
                }}
                type={NotificationCardTypes.ERROR}
              />
            </Box>
          ) : null}
          <Stack spacing={6} direction="row" mt={10}>
            <MaskField
              onChange={(event) => setAmount1(parseFloat(`0.${event.value}`))}
              label="settings.microDeposits.verifyDialog.sum1Label"
              prefix="currency.usd"
              isRequired
              format="$0.##"
              allowEmptyFormatting
            />
            <MaskField
              onChange={(event) => setAmount2(parseFloat(`0.${event.value}`))}
              label="settings.microDeposits.verifyDialog.sum2Label"
              prefix="currency.usd"
              isRequired
              format="$0.##"
              allowEmptyFormatting
            />
          </Stack>
        </Box>
      </Stack>
      <Button
        size={ButtonSizes.lg}
        variant={ButtonVariants.primary}
        onClick={() => {
          onClick({
            amount1: amount1State,
            amount2: amount2State,
          });
        }}
        label="settings.microDeposits.depositsWasSentDialog.submit"
        isLoading={isLoading}
      />
    </Flex>
  );
};
