import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { useModal } from 'src/helpers/react/useModal';
import { useBreak } from 'src/hoc';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { ContactsPlaceholder } from 'src/pages/contacts/list/components/ContactsPlaceholder';
import { ContactViewHeader, ContactViewHeaderMobile } from 'src/pages/contacts/list/components/ContactViewHeader';
import { contactsLocations } from 'src/pages/contacts/locations';
import { TransactionSummary } from 'src/pages/contacts/types';
import { getContactActions } from 'src/pages/contacts/utils';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { useForm } from 'src/ui/form';
import { ContactsTab, PaymentRequestTabs, ScreenMode } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { encodeQuery } from 'src/utils/query-utils';
import { ContactType } from 'src/utils/types';
import { CustomerForm } from './CustomerForm';

const useMode = (initialState = ScreenMode.VIEW) => {
  const [editMode, setEditMode] = useState(ScreenMode.EDIT === initialState);

  return [
    editMode ? ScreenMode.EDIT : ScreenMode.VIEW,
    () => setEditMode(!editMode),
    () => setEditMode(false),
  ] as const;
};

type GetMessageDataParams = {
  contact?: ContactType;
  contactPaymentRequests: TransactionSummary[];
  onDeleteContact: () => void;
  onViewPendingPaymentsRequests: () => void;
  onViewInProgressPaymentsRequests: () => void;
};

const getMessageData = ({
  contact,
  contactPaymentRequests,
  onDeleteContact,
  onViewPendingPaymentsRequests,
  onViewInProgressPaymentsRequests,
}: GetMessageDataParams) => {
  const sentPaymentRequestsCount = find(contactPaymentRequests, ['name', 'requests'])?.count || 0;
  const scheduledPaymentRequests = find(contactPaymentRequests, ['name', 'scheduled'])?.count || 0;

  if (sentPaymentRequestsCount > 0) {
    return {
      confirm: onViewPendingPaymentsRequests,
      title: 'contacts.delete.customers.noDeletePendingPaymentRequests.title',
      textValues: {
        companyName: contact?.companyName,
        count: sentPaymentRequestsCount,
      },
      description: 'contacts.delete.customers.noDeletePendingPaymentRequests.subtitle',
      confirmText: 'contacts.delete.customers.noDeletePendingPaymentRequests.button',
    };
  } else if (scheduledPaymentRequests > 0) {
    return {
      confirm: onViewInProgressPaymentsRequests,
      title: 'contacts.delete.customers.noDeletePaymentRequestsInProgress.title',
      textValues: {
        companyName: contact?.companyName,
        count: scheduledPaymentRequests,
      },
      description: 'contacts.delete.customers.noDeletePaymentRequestsInProgress.subtitle',
      confirmText: 'contacts.delete.customers.noDeletePaymentRequestsInProgress.button',
    };
  }

  return {
    confirm: onDeleteContact,
    title: 'contacts.delete.customers.deleteMsg.title',
    textValues: { companyName: contact?.companyName },
    description: 'contacts.delete.customers.deleteMsg.subtitle',
    confirmText: 'contacts.delete.customers.deleteMsg.button',
  };
};

type CustomerViewProps = {
  contact: ContactType;
  listBaseSearch: string;
  isSingleLoading: boolean;
  isLoading: boolean;
  contactPaymentRequests: TransactionSummary[];
  goPaymentRequests: (tab: PaymentRequestTabs) => void;
};

const eventPage = 'contacts';

export const CustomerView = ({
  contact,
  listBaseSearch,
  isSingleLoading,
  isLoading,
  contactPaymentRequests,
  goPaymentRequests,
}: CustomerViewProps) => {
  const device = useBreak();
  const dispatch = useDispatch();
  const { navigate } = usePreservedStateNavigator();
  const [historyReplace] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const contactActions = getContactActions(ContactsTab.CUSTOMERS)(dispatch);
  const [editMode, onToggleEdit, cancelEdit] = useMode();
  const { canCreatePaymentRequests } = useCanCreatePaymentRequest();
  const contactMV = useMemo(() => ({ ...contact }), [contact]);
  const [contactsData, { submit, cancel }] = useForm<ContactType>(contactMV, {
    submit: async (contacts) => contactActions.update({ orgId, ...contacts }),
    validator: (key, value): string | undefined => {
      const validationErrors = getValidationErrors('vendor', { [key]: value });

      return validationErrors[key];
    },
  });

  const goToPaymentRequestTab = (tab) => () => {
    setTimeout(() => goPaymentRequests(tab), 1);

    return Promise.resolve();
  };

  const onDeleteContact = async () => {
    if (contact) {
      await contactActions.delete({ orgId, id: contact.id });

      return historyReplace({
        path: contactsLocations.index,
        params: { type: ContactsTab.CUSTOMERS },
      });
    }

    return null;
  };

  const [DeleteContact, showDeleteVendor] = useModal(
    CommonDialog,
    getMessageData({
      contact,
      contactPaymentRequests,
      onDeleteContact,
      onViewPendingPaymentsRequests: goToPaymentRequestTab(PaymentRequestTabs.UNSENT),
      onViewInProgressPaymentsRequests: goToPaymentRequestTab(PaymentRequestTabs.SCHEDULED),
    })
  );

  useEffect(() => {
    cancelEdit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  const match = useRouteMatch();

  const onCancelEditing = () => {
    cancel();
    onToggleEdit();
  };

  const onDoneEditing = async () => {
    const validationErrors = getValidationErrors('vendor', mapValues(contactsData, 'value'));

    if (isValidationOk(validationErrors)) {
      try {
        await submit();
        onToggleEdit();
      } catch (error: any) {
        contactsData?.setValidationErrors({ companyName: `server.${error.code}` });
      }
    } else {
      analytics.track(eventPage, 'edit-vendor-validation-error', validationErrors);
    }
  };

  const onKeyPressed = (event: React.KeyboardEvent) => {
    if (editMode === ScreenMode.EDIT && isEnterPressed(event)) {
      onDoneEditing();
    }
  };

  const goCreatePaymentRequest = () => {
    if (contact) {
      analytics.track(eventPage, 'go-create-payment-request');
      navigate({
        pathname: generatePath(getPaidLocations.create.invoice, { orgId }),
        search: encodeQuery({ customerId: contact.id }, [], ''),
      });
    }
  };

  if (!contactsData?.id) {
    return <ContactsPlaceholder isLoading={isSingleLoading} label="contacts.emptyState.customers.placeholder" />;
  }

  const isMobileOrPhablet = device.isMobile || device.isPhablet;
  const showPageHeader = !(editMode === ScreenMode.EDIT && isMobileOrPhablet);

  const actionOptions = [
    ...(canCreatePaymentRequests
      ? [
          {
            label: 'vendors.actions.newRequest',
            action: goCreatePaymentRequest,
          },
        ]
      : []),
    {
      label: 'vendors.actions.edit',
      action: onToggleEdit,
    },
    {
      label: 'vendors.actions.delete',
      action: showDeleteVendor,
      negative: true,
    },
  ];

  return (
    <>
      {DeleteContact}
      {contact && !isSingleLoading && (
        <SingleViewLoadingContainer
          isEditMode={editMode === ScreenMode.EDIT}
          className={isSingleLoading ? 'loading' : ''}
        >
          {showPageHeader && (
            <ContactViewHeaderMobile
              contact={contact}
              backUrl={generatePath(contactsLocations.index, {
                ...match.params,
                type: ContactsTab.CUSTOMERS,
              })}
              backVendorsSearch={listBaseSearch}
              contactType={ContactsTab.CUSTOMERS}
            />
          )}
          {!isMobileOrPhablet && <ContactViewHeader contact={contact} contactType={ContactsTab.CUSTOMERS} />}
          <MICard>
            <MICardForm onKeyDown={(event) => onKeyPressed(event)} testId="vendor-info">
              <Flex justify="space-between" align="center">
                <MICardTitle label="contacts.view.customers.title" />
                <Menu>
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<Icon name={IconNames.more} />}
                    variant="action"
                    placement="top"
                    data-testid="dots-action-menu"
                  />
                  <MenuList>
                    {actionOptions.map(({ label, action, negative }) => (
                      <MenuItem label={label} onClick={action} color={negative ? 'red.500' : 'black'} />
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <CustomerForm mode={editMode} customer={contactsData} />
              {editMode === ScreenMode.EDIT && (
                <MIFloatedEditDoneButtons
                  onDone={onDoneEditing}
                  onCancel={onCancelEditing}
                  isProcessing={isLoading}
                  doneLabel="vendors.edit.done"
                  cancelLabel="vendors.edit.cancel"
                />
              )}
            </MICardForm>
          </MICard>
        </SingleViewLoadingContainer>
      )}
    </>
  );
};
