import { VendorManagedByEnum } from 'src/utils/consts';
import { DeliveryMethodType, PaymentType } from 'src/utils/types';

export const getCategoriesSelectTitle = (payment: PaymentType, isVirtualCardUnilateral: boolean) => {
  if (isVirtualCardUnilateral) {
    return 'vendors.addDeliveryMethodByLink.virtualCard.selectTitle';
  }

  return payment
    ? 'vendors.addDeliveryMethodByLink.selectTitle'
    : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.selectTitle';
};

type Category = {
  id: 'virtual-card' | 'ach' | 'check';
  label: string;
  icon: string;
  hint: string;
  tag?: string;
  info?: string;
};

type GetCategories = (params: {
  payment: PaymentType;
  isVirtualCardUnilateral: boolean;
  isChecksDelaysEnabled: boolean;
  isGeneralCheckDelays: boolean;
  isCardFundingSource: boolean;
}) => Category[];

export const getCategories: GetCategories = ({
  payment,
  isVirtualCardUnilateral,
  isChecksDelaysEnabled,
  isGeneralCheckDelays,
  isCardFundingSource,
}) => {
  if (isVirtualCardUnilateral) {
    return [
      {
        id: 'virtual-card',
        label: 'vendors.addDeliveryMethodByLink.virtualCard.virtualCardCategoryLabel',
        icon: 'icon-credit-card-icon',
        hint: 'vendors.addDeliveryMethodByLink.virtualCard.virtualCardHint',
      },
      {
        id: 'ach',
        label: 'vendors.addDeliveryMethodByLink.virtualCard.achCategoryLabel',
        icon: 'icon-bank-icon',
        hint: 'vendors.addDeliveryMethodByLink.virtualCard.achHint',
      },
    ];
  }

  const checkDelaysLabel = isGeneralCheckDelays
    ? 'bills.pay.date.checksDelays.general.tooltip'
    : 'vendors.addDeliveryMethodByLink.holidayDelays';

  const achLabelWithPayment = isCardFundingSource
    ? 'vendors.addDeliveryMethodByLink.cardToAchCategoryLabel'
    : 'vendors.addDeliveryMethodByLink.achCategoryLabel';

  const achLabelWithoutPayment = 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.achCategoryLabel';

  return [
    {
      id: 'ach',
      label: payment ? achLabelWithPayment : achLabelWithoutPayment,
      icon: 'icon-fast-delivery-icon',
      hint: payment
        ? 'vendors.addDeliveryMethodByLink.achHint'
        : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.achHint',
      tag: isChecksDelaysEnabled ? 'general.recommended' : undefined,
    },
    {
      id: 'check',
      label: payment
        ? 'vendors.addDeliveryMethodByLink.checkCategoryLabel'
        : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.checkCategoryLabel',
      icon: 'icon-slow-delivery-icon',
      hint: payment
        ? 'vendors.addDeliveryMethodByLink.checkHint'
        : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.checkHint',
      info: isChecksDelaysEnabled ? checkDelaysLabel : undefined,
    },
  ];
};

export const getSuccessTitle = (
  paymentId: number,
  isAchMethod: boolean,
  isVirtualCardMethod: boolean,
  isVirtualCardDeliveryMethodExist: boolean
) => {
  if (isVirtualCardMethod) {
    return 'vendors.addDeliveryMethodByLink.success.titleVirtualCard';
  }

  if (isAchMethod && isVirtualCardDeliveryMethodExist) {
    return 'vendors.addDeliveryMethodByLink.success.titleAchFromVirtualCard';
  }

  if (paymentId) {
    return 'vendors.addDeliveryMethodByLink.success.title';
  }

  return 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.success.title';
};

export const getSuccessSubtitle = (
  paymentId: number,
  isAchMethod: boolean,
  isVirtualCardMethod: boolean,
  isVirtualCardDeliveryMethodExist: boolean
) => {
  if (isVirtualCardMethod) {
    return 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.success.subtitleVirtualCard';
  }

  if (isAchMethod && isVirtualCardDeliveryMethodExist) {
    return 'vendors.addDeliveryMethodByLink.success.subtitleAchFromVirtualCard';
  }

  if (paymentId) {
    return 'vendors.addDeliveryMethodByLink.success.subtitle';
  }

  if (isAchMethod) {
    return 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.success.subtitleAch';
  }

  return 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.success.subtitleCheck';
};

export const getSignUpTitle = ({ paymentId, isAchMethod }: { paymentId?: number; isAchMethod: boolean }) => {
  if (paymentId) {
    if (isAchMethod) {
      return 'vendors.addDeliveryMethodByLink.vendorSignUpTitle';
    }

    return 'vendors.addDeliveryMethodByLink.signUpTitle';
  }

  return 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.signUpTitle';
};

export const getSignUpSubtitle = ({ paymentId, isAchMethod }: { paymentId?: number; isAchMethod: boolean }) => {
  if (paymentId) {
    if (isAchMethod) {
      return 'vendors.addDeliveryMethodByLink.vendorSignUpSubtitle';
    }

    return 'vendors.addDeliveryMethodByLink.signUpSubtitle';
  }

  return 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.signUpSubtitle';
};

export const getSignUpCTA = ({ paymentId, isAchMethod }: { paymentId?: number; isAchMethod: boolean }) => {
  if (paymentId) {
    if (isAchMethod) {
      return 'vendors.addDeliveryMethodByLink.vendorSignUpCTA';
    }

    return 'vendors.addDeliveryMethodByLink.signUpCTA';
  }

  return 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.signUpCTA';
};

export const shouldOfferSignUpToVendor = (
  isVirtualCardDeliveryMethodExist: boolean,
  isLoggedIn: boolean,
  deliveryMethod: DeliveryMethodType
): boolean =>
  !isVirtualCardDeliveryMethodExist &&
  !isLoggedIn &&
  deliveryMethod?.managedBy !== VendorManagedByEnum.MSN &&
  deliveryMethod?.managedBy !== VendorManagedByEnum.SHARED;
