import flatMap from 'lodash/flatMap';
import * as React from 'react';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { SingleSelectFlavor } from 'src/utils/consts';
import { ModelViewField } from './use-form';

export type SelectFieldOption<V> = {
  label?: string;
  value?: V;
  [key: string]: any;
  options?: SelectFieldOption<V>[];
};
export type WizardSelectFieldProps<V> = {
  model: ModelViewField<V>;
  label: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  noOptionsLabel?: string;
  createLabel?: string;
  onCreate?: (newLabel: string) => Promise<V>;
  options: SelectFieldOption<V>[];
  formatGroupLabel?: (group: SelectFieldOption<V>) => any;
  privateData?: boolean;
  hint?: string;
  flavor?: SingleSelectFlavor;
  afterLabelComponent?: React.ReactNode;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  testId?: string;
};

export function WizardSelectField<V>(props: WizardSelectFieldProps<V>) {
  const { model, options, onCreate, disabled, hint, flavor = SingleSelectFlavor.DEFAULT, ...rest } = props;
  const id = props.id || model.id;
  const flattenedOptions = flatMap(options, (option) => (option?.options ? option.options : option));
  const value = flattenedOptions.find((o) => o.value === model.value) || null;

  async function onChange({ id, value, meta }) {
    let newValue = value;

    if (onCreate && meta.action === 'create-option') {
      newValue = await onCreate(value);
    }

    model.onChange({ id, value: newValue });
  }

  return (
    <MISingleSelect
      {...rest}
      notices={hint && [hint]}
      id={id}
      errorMessage={model?.error}
      onChange={onChange}
      allowCustom={!!onCreate}
      options={options}
      value={value}
      isDisabled={disabled}
      flavor={flavor}
    />
  );
}
