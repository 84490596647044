import { ComponentProps } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { UseModal } from 'src/helpers/react/useModal';
import addBusinessDetailsImage from 'src/images/onboarding/add-business-details.svg';

type Props = Omit<ComponentProps<typeof ModalMessage>, 'titleComponent' | 'contentComponent' | 'buttonLabel'>;

export const BlockCreateLinkModal = (props: Props) => (
  <ModalMessage
    image={addBusinessDetailsImage}
    titleComponent={<MIFormattedText label="requests.blockCreateLinkDialog.title" />}
    contentComponent={<MIFormattedText label="requests.blockCreateLinkDialog.content" />}
    buttonLabel="requests.blockCreateLinkDialog.buttonLabel"
    {...props}
  />
);

type WrapperProps = {
  dismiss: () => void;
};
export const BlockCreateLinkModalWithDismiss: React.FC<UseModal<WrapperProps>> = (props) => (
  <BlockCreateLinkModal onButtonClick={props.dismiss} onCloseClick={props.dismiss} {...props} />
);
