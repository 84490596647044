import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, useState } from 'react';
import { useExtraAnalyticsProps } from 'src/analytics/useExtraAnalyticsProps';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { organizationsApi } from 'src/modules/organizations/api';
import { useInternationalMethodCreation } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalMethodCreation';
import { useInternationalWizard } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalWizard';
import { UBOForm } from 'src/pages/vendor/international-delivery-method/ultimate-beneficial-owners/components/UBOForm';
import {
  getModelFieldsList,
  getModelGroups,
  getUBOFormsErrors,
  getUpdatedModelAndErrors,
  ObjectWithIndexSuffixedKeys,
  uboFormValidator,
  UBOModelType,
  updateAddressModel,
} from 'src/pages/vendor/international-delivery-method/ultimate-beneficial-owners/utils';
import { getIsComingFromVendorDetails } from 'src/pages/vendor/international-delivery-method/utils';
import { useForm } from 'src/ui/form';
import { NotificationCardTypes } from 'src/utils/consts';
import { getFormattedAddress } from 'src/utils/formatting';
import { useLocationState } from 'src/utils/hooks';
import { AddressType, UBORequestType } from 'src/utils/types';

export const useUBOForm = (vendorId: number) => {
  const orgId = useOrgId();
  const [origin] = useLocationState<string>('origin');
  const [formCount, setFormCount] = useState(1);
  const { wizardState } = useInternationalWizard();
  const [createInternationalDeliveryMethod, isCreatingInternational] = useInternationalMethodCreation({ vendorId });
  const { onApiCall: createUltimateBeneficialOwner, loading: isCreatingUBO } = useApi({
    api: organizationsApi.createUltimateBeneficialOwner,
  });
  const isComingFromVendorDetails = useMemo(() => (origin ? getIsComingFromVendorDetails(origin) : false), [origin]);
  const showFooterProps = {
    shouldShowAddBillButton: isComingFromVendorDetails,
    isComingFromVendorDetails,
  };
  const [model, setModel] = useState(getModelFieldsList(1));
  const showAddAnotherOwnerButton = formCount < 4;

  useExtraAnalyticsProps('internationalUBO', { numberOfOwners: formCount }, formCount);

  const [uboMV, { submit }, validationErrors] = useForm<
    ObjectWithIndexSuffixedKeys<UBOModelType> & { shouldRedirectToAddBill?: boolean }
  >(model, {
    submit: async (value, changes) => {
      const modelGroups = getModelGroups(value);
      const params = modelGroups.map<UBORequestType>((value, index) => {
        const formIndex = index + 1;

        return {
          firstName: value[`firstName${formIndex}`],
          lastName: value[`lastName${formIndex}`],
          taxId: value[`taxId${formIndex}`],
          dateOfBirth: value[`dateOfBirth${formIndex}`],
          address: value[`address${formIndex}`],
          state: value[`state${formIndex}`],
          zipCode: value[`zipCode${formIndex}`],
          city: value[`city${formIndex}`],
          country: 'United States',
        };
      });

      await createUltimateBeneficialOwner(orgId, params);
      await createInternationalDeliveryMethod({
        ...wizardState,
        shouldRedirectToAddBill: changes.shouldRedirectToAddBill || false,
      });
    },
    validateOnChange: false,
    validator: uboFormValidator,
    onChange: ({ key, value, modelState }) => {
      model[key] = value;
      setModel({
        ...model,
        [key]: value,
      });

      return modelState;
    },
  });

  const onChangeAddress = (address: AddressType | null, index: number) => {
    if (!address) {
      updateAddressModel({}, uboMV, index);

      return;
    }

    const formattedAddress = getFormattedAddress(address);
    updateAddressModel({ formattedAddress, ...address }, uboMV, index);
  };

  const onAddUBOForm = useCallback(() => {
    const isValidForms = getUBOFormsErrors(uboMV);

    if (isValidForms && showAddAnotherOwnerButton) {
      const newModel = getModelFieldsList(formCount + 1);
      setModel({ ...model, ...newModel });
      setFormCount((prevFormCount) => prevFormCount + 1);
    }
  }, [uboMV, showAddAnotherOwnerButton, getUBOFormsErrors]);

  const onRemoveUBOForm = useCallback(
    (modelGroups: readonly UBOModelType[], indexForm: string) => {
      setFormCount((prevFormCount) => prevFormCount - 1);
      const { newModel, errors } = getUpdatedModelAndErrors(modelGroups, indexForm);
      uboMV.setValidationErrors(errors);
      setModel(newModel);
    },
    [model, formCount, setFormCount, getUpdatedModelAndErrors]
  );
  const showNotification = formCount > 1 && !isEmpty(validationErrors);

  const renderUBOForms = useCallback(() => {
    const modelGroups = getModelGroups(uboMV);
    const showRemoveButton = modelGroups.length > 1;

    return (
      <>
        {showNotification && (
          <Box mb={10}>
            <MINotificationCard
              type={NotificationCardTypes.ERROR}
              subtitle={{ label: 'vendors.deliveryMethods.international.ubo.notification' }}
            />
          </Box>
        )}
        {modelGroups.map((model: any, index) => {
          const formIndex = Object.keys(model)[0].substr(-1);
          const addressError = validationErrors ? validationErrors[`address${index + 1}`] : '';

          return (
            <Box key={index} data-testid={`form${index + 1}`}>
              <UBOForm model={model} onChangeAddress={onChangeAddress} index={index + 1} addressError={addressError} />
              {showRemoveButton && (
                <Box mb={6} key={index} onClick={() => onRemoveUBOForm(modelGroups, formIndex)}>
                  <Button
                    label="vendors.deliveryMethods.international.ubo.removeOwner"
                    size={ButtonSizes.md}
                    variant={ButtonVariants.tertiary}
                    data-testid="vendors.deliveryMethods.international.ubo.removeOwner"
                  />
                </Box>
              )}
            </Box>
          );
        })}
        {showAddAnotherOwnerButton && (
          <Box
            mt={2}
            textStyle="body3"
            color="primary.500"
            cursor="pointer"
            onClick={onAddUBOForm}
            data-testid="vendors.deliveryMethods.international.ubo.addOwner"
          >
            <MIFormattedText label="vendors.deliveryMethods.international.ubo.addOwner" />
          </Box>
        )}
      </>
    );
  }, [uboMV, onAddUBOForm, onRemoveUBOForm]);

  return {
    renderUBOForms,
    submit,
    isLoading: isCreatingInternational || isCreatingUBO,
    showFooterProps,
  };
};
