import qs from 'qs';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Switch, useHistory, useLocation } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getOrganizationStoreActions } from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { CompanyInfoOnboardingOrigin } from 'src/utils/consts';
import { companiesLocations } from '../locations';
import { AccountantNewCompanyConnectAccountingSoftwarePage } from './AccountantNewCompanyConnectAccountingSoftwarePage';
import { AccountantNewCompanyDetailsPage } from './AccountantNewCompanyDetailsPage';
import { NewCompanyConnectAccountingSoftwareReturnPage } from './NewCompanyConnectAccountingSoftwareReturnPage';
import { NewCompanyContactsInfoPageContainer } from './NewCompanyContactsInfoPageContainer';
import { NewCompanyDetailsPageContainer } from './NewCompanyDetailsPageContainer';
import { NewCompanyErrorPage } from './NewCompanyErrorPage';
import NewCompanyRoleDetailsPage from './NewCompanyRoleDetailsPage';

const ANALYTICS_TAG_NAME = 'NewCompanyRouter';

export function NewCompanyRouter() {
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const location = useLocation();
  const { emailInUse } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;
  const dispatch = useDispatch();
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const { updateNewCompanyInfo } = useMemo(() => getOrganizationStoreActions(dispatch), [dispatch]);
  const onFinishFlow = useCallback(() => {
    historyPush({ path: companiesLocations.index });
  }, [historyPush]);
  useEffect(() => () => updateNewCompanyInfo({}), [updateNewCompanyInfo]);

  const updateExtraProperties = () => {
    if (isMemberOfAccountingFirm) {
      const addClientFlowId = `${Math.random().toString(36).substr(2, 9)}`;
      analytics.setExtraProperties(ANALYTICS_TAG_NAME, { addClientFlowId });
    }

    const cleanExtraProperties = () => {
      analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
    };

    return cleanExtraProperties;
  };
  useEffect(updateExtraProperties, []);

  function onAccountantOrganizationCreated(org) {
    history.push(
      generatePath(onboardingLocations.companyInfo.contacts, {
        orgId: org.id,
      }),
      { origin: CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY }
    );
  }
  const onPrev = () => {
    history.goBack();
  };

  return (
    <Switch>
      <SmartRoute path={companiesLocations.create.details} exact>
        {isMemberOfAccountingFirm ? (
          <AccountantNewCompanyDetailsPage
            onExit={onFinishFlow}
            onNext={() =>
              historyPush({
                path: companiesLocations.create.connectAccountingSoftware,
              })
            }
            emailInUse={!!emailInUse}
          />
        ) : (
          <NewCompanyDetailsPageContainer
            onNext={() => historyPush({ path: companiesLocations.create.roleDetails })}
            onExit={onFinishFlow}
          />
        )}
      </SmartRoute>
      <SmartRoute path={companiesLocations.create.connectAccountingSoftware} exact>
        <AccountantNewCompanyConnectAccountingSoftwarePage
          onPrev={() => historyPush({ path: companiesLocations.create.details })}
          onNext={onAccountantOrganizationCreated}
          onExit={onFinishFlow}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.create.roleDetails} exact>
        <NewCompanyRoleDetailsPage
          onPrev={onPrev}
          onNext={() => historyPush({ path: companiesLocations.create.contactInfo })}
          onExit={onFinishFlow}
        />
      </SmartRoute>
      <SmartRoute path={companiesLocations.create.contactInfo} exact>
        <NewCompanyContactsInfoPageContainer onPrev={onPrev} onExit={onFinishFlow} onSuccess={onFinishFlow} />
      </SmartRoute>
      <SmartRoute path={companiesLocations.create.connectAccountingSoftwareReturn} exact>
        <NewCompanyConnectAccountingSoftwareReturnPage />
      </SmartRoute>
      <SmartRoute path={companiesLocations.create.error} exact>
        <NewCompanyErrorPage />
      </SmartRoute>
    </Switch>
  );
}
