import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { UPDATE_IS_SYNCING } from 'src/modules/accounting-platforms/actions-types';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { SyncStatus } from 'src/utils/consts';
import { name } from './consts';

type SyncSummary = {
  isActive: boolean;
  lastUpdateDate: string | null;
  accountingPlatformName: string;
  syncStatus: SyncStatus;
};
type SyncSummaryRequest = { orgId: number };

type SyncSummaryState = {
  SyncSummary: {
    summary?: SyncSummary;
    isSyncing?: boolean;
    loading?: boolean;
    error?: Error;
  };
};

export const syncSummaryFetchSlice = createApiCallSlice<SyncSummaryRequest, SyncSummaryState, SyncSummary>({
  initialState: {
    SyncSummary: {
      summary: undefined,
      loading: false,
      error: undefined,
      isSyncing: false,
    },
  },
  api: accountingPlatformsApi.getSyncStatusSummary,
  reducers: {
    [ON_REQUEST](state) {
      state.SyncSummary.loading = true;
    },
    [ON_SUCCESS](state, action) {
      state.SyncSummary.loading = false;
      state.SyncSummary.summary = action.payload;
      state.SyncSummary.isSyncing = action.payload.syncStatus === SyncStatus.STARTED;
    },
    [ON_FAILURE](state, action) {
      const previousSummary = state.SyncSummary.summary;

      state.SyncSummary = {
        loading: false,
        error: action.error,
        summary: {
          syncStatus: SyncStatus.FAILURE,
          isActive: true,
          lastUpdateDate: previousSummary?.lastUpdateDate || null,
          accountingPlatformName: action.payload.accountingPlatformName || previousSummary?.accountingPlatformName,
        },
        isSyncing: false,
      };
    },

    [UPDATE_IS_SYNCING](state, action) {
      state.SyncSummary.isSyncing = action.payload.isSyncing;
    },
  },

  name: `[${name}] sync summary`.toUpperCase(),
  selectors: {
    loading: (state) => state[name].SyncSummary?.loading,
    syncSummary: (state) => state[name].SyncSummary?.summary,
    isSyncing: (state) => state[name].SyncSummary?.isSyncing,
  },
});
