import accessAnimation from 'src/animations/accountingSoftware/sync-qbd/access.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { getQBDRelativeStep } from './utils';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const SyncQBDAccessPage = ({ onNext, onPrev, onExit }: Props) => (
  <StepLayoutPage
    title="settings.newAccountingSoftware.QuickbooksDesktopSync.access.title"
    subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.access.subtitle"
    nextLabel="progress.next"
    goExit={onExit}
    onNext={onNext}
    onPrev={onPrev}
    relativeStep={getQBDRelativeStep(5)}
    displaySupportLink
    animatedIllustration={{
      animationData: accessAnimation,
    }}
  />
);

export default SyncQBDAccessPage;
