import { InputLeftElement } from '@chakra-ui/react';
import Box from 'src/core/ds/box';
import { IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { getFundingSourceIcon } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';
import { BankAccountIcon } from '../../components/BankAccountIcon';

type Props = {
  account: AccountType;
};

export const DropdownFundingSourceIcon = ({ account }: Props) => {
  const { icon: selectedIcon, imageSrc: selectedImageSrc } = getFundingSourceIcon(account);

  return (
    <InputLeftElement w={8}>
      {selectedImageSrc ? (
        <Box pb={1}>
          <Image src={selectedImageSrc} w={IconSize.m} h={IconSize.m} mr={2} />
        </Box>
      ) : (
        <Box pb={1}>
          <BankAccountIcon name={selectedIcon} size={IconSize.s} disabled={false} />
        </Box>
      )}
    </InputLeftElement>
  );
};
