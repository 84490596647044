import Box from 'src/core/ds/box';

type OrganizationInfoCardProps = {
  companyDetails: string;
  address: string;
  mb?: number;
};

export const OrganizationInfoCard = ({ companyDetails, address, mb }: OrganizationInfoCardProps) => (
  <Box mb={mb}>
    <Box textStyle="body2"> {companyDetails}</Box>
    <Box textStyle="body4" color="grey.700">
      {address}
    </Box>
  </Box>
);
