import { useDisclosure } from '@chakra-ui/react';
import { ComponentProps, useCallback } from 'react';
import Flex from 'src/core/ds/flex';
import { IconNames } from 'src/core/ds/icon';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import { DeliveryMethodLabel } from 'src/pages/regular-batch-payments/components/deliveryMethod/DeliveryMethodLabel';
import { AddPaymentPurposeModal } from 'src/pages/regular-batch-payments/components/dialog/AddPaymentPurposeModal';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodType } from 'src/utils/types';
import { ActionPopover } from '../ActionPopover';

type DisabledSelectionProps = {
  payment: BatchPaymentType;
  isInternationalVendor: boolean;
  itemId: string;
  totalAmount: number;
  vendor: BatchPaymentType['vendor'];
  deliveryMethod: DeliveryMethodType;
  purpose?: string;
  toggleRowExpanded?: (value?: boolean) => void;
};

export const DisabledSelection = ({
  payment,
  isInternationalVendor,
  itemId,
  totalAmount,
  vendor,
  deliveryMethod,
  purpose,
  toggleRowExpanded,
}: DisabledSelectionProps) => {
  const disclosure = useDisclosure();
  const showTooltip = isInternationalVendor && !purpose;

  const openPurposeModal = useCallback(() => {
    analytics.track('regular-batch-payments', 'international-purpose-opened', { itemId });
    toggleRowExpanded && toggleRowExpanded(true);
    disclosure.onOpen();
  }, [disclosure, itemId, toggleRowExpanded]);

  const tooltipProps: ComponentProps<typeof ActionPopover> = {
    label: 'regularBatchPayments.tooltips.international',
    iconName: IconNames.warning,
    iconColor: 'yellow.500',
    action: openPurposeModal,
    actionLabel: 'regularBatchPayments.tooltips.internationalCtaLabel',
    popoverTestId: 'missing-purpose-popover',
    iconTestId: 'missing-purpose-icon',
  };

  return (
    <>
      {disclosure.isOpen && (
        <AddPaymentPurposeModal
          itemId={itemId}
          totalAmount={totalAmount}
          vendor={vendor}
          onClose={disclosure.onClose}
          payment={payment}
        />
      )}
      <Flex align="center" justify="space-between" h="full">
        <DeliveryMethodLabel deliveryMethod={deliveryMethod} />
        {showTooltip ? <ActionPopover {...tooltipProps} /> : null}
      </Flex>
    </>
  );
};
