import { useEffect } from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import invitationsStore from 'src/modules/invitations/invitations-store';

type UseFetchRelatedInvitationsProps = {
  invitationIds: number[];
  orgId: number;
};
export function useFetchRelatedInvitations({ invitationIds, orgId }: UseFetchRelatedInvitationsProps) {
  const invitationActions = useStoreActions(invitationsStore);

  // optimization for useEffect, compare strings, not arrays
  const stringifiedInvitationIds = JSON.stringify(invitationIds);
  useEffect(() => {
    if (orgId && invitationIds.length) {
      invitationActions.relatedInvitations({
        orgId,
        invitationIds: JSON.parse(stringifiedInvitationIds),
      });
    }
  }, [invitationActions, invitationIds.length, orgId, stringifiedInvitationIds]);
}
