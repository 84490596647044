import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { withNavigator } from 'src/hoc';
import { useIsOrganizationInMsnOnly } from 'src/modules/msn-portal/hooks/useIsOrganizationInMsnOnly';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { billLocations } from 'src/pages/bill/locations';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { getOrgId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillsDefaultFilters } from 'src/utils/bills';
import { BillStatus } from 'src/utils/consts';
import { melioClose, paymentSuccess } from 'src/utils/external-events';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type Props = {
  scheduledDate: Date;
  deliveryDate: Date;
} & GuestDataProps;

const eventPage = 'payor';
// todo: remove this when isGuestPayorFlowCombineSuccessScreenFF completes
const GuestSuccessPageContainer = ({
  scheduledDate,
  totalAmount,
  companyName,
  navigateToGuestPage,
  billId,
  link,
}: Props) => {
  const { navigate } = useNavigator();
  const profile = useSelector(getProfile);
  const orgId = useSelector(getOrgId);
  const isSuccessButtonHidden = useIsOrganizationInMsnOnly();

  const goNext = () => {
    if (profile.isGuest) {
      analytics.track(eventPage, 'success-continue-registration');
      navigateToGuestPage(generatePath(melioMeLocations.wizard.successSignUp, { link }));
    } else {
      analytics.track(eventPage, 'success-continue-new-bill');
      const defaultFilters = getBillsDefaultFilters(BillStatus.SCHEDULED);
      melioClose();
      navigate(generatePath(billLocations.filteredView, { orgId, id: billId, ...defaultFilters }));
    }
  };

  useEffect(() => {
    paymentSuccess();
  }, []);

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.checkCircle}
      title="bills.pay.payBillSuccess.titles.regular"
      text="bills.pay.payBillSuccess.subtitles.regularWithNotify"
      textValues={{
        amount: <MIFormattedCurrency key="amount" value={totalAmount ? totalAmount.toString() : ''} />,
        processDate: <MIFormattedDate key="processDate" date={scheduledDate} />,
        vendorName: companyName,
      }}
      {...(!isSuccessButtonHidden && {
        buttonLabel: 'bills.pay.payBillSuccess.buttonLabel.done',
        buttonAction: goNext,
      })}
    />
  );
};

export default compose(withNavigator(), withGuestData())(GuestSuccessPageContainer);
