const baseStyle = (props) => ({
  color: props.isFocus ? 'black' : 'grey.700',
  _disabled: {
    color: 'grey.400',
  },
});

export default {
  baseStyle,
};
