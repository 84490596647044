import { useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import UploadAttachment from 'src/components/common/UploadAttachment';
import invoicePlaceholder from 'src/images/bills/invoice.jpg';
import { devices } from 'src/theme/appDevices';
import { ACCEPT_FILES, ScreenMode } from 'src/utils/consts';

type Props = {
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  mode: ScreenMode;
  onOpenPreview?: () => void;
  onChangeAttachment?: (file: File) => Promise<void>;
  onDeleteAttachment?: () => void | null;
};

const InvoiceAttachment = ({
  fileStorageUrl,
  filePreviewUrls = [],
  mode,
  onOpenPreview,
  onChangeAttachment,
  onDeleteAttachment,
}: Props) => {
  const isEditMode = mode === ScreenMode.EDIT;
  const attachment = useMemo(() => (filePreviewUrls.length ? filePreviewUrls[0] : undefined), [filePreviewUrls]);
  const uploadLabel = useMemo(
    () => `getPro.cards.actions.edit.invoice.attachment.${fileStorageUrl ? 'replace' : 'upload'}`,
    [fileStorageUrl]
  );

  const [isAttachmentLoaded, setIsAttachmentLoaded] = useState(false);
  const onAttachmentLoaded = useCallback(() => setIsAttachmentLoaded(true), []);

  const onOpenPdfPreview = () => {
    if (onOpenPreview) {
      onOpenPreview();
    } else {
      window.open(fileStorageUrl, '_blank');
    }
  };

  const onChangeAttachmentClicked = useCallback(
    ({ currentTarget: target }) => {
      if (target.files && target.files.length > 0 && onChangeAttachment) {
        onChangeAttachment(target.files[0]);
        target.value = '';
      }
    },
    [onChangeAttachment]
  );

  return (
    <Container isEditMode={isEditMode} isNoAttachment={!attachment}>
      {(!attachment || !isAttachmentLoaded) && (
        <AttachmentPlaceholder isEditMode={isEditMode} src={invoicePlaceholder} />
      )}
      {attachment && (
        <AttachmentWrapper isVisible={isAttachmentLoaded} isEditMode={isEditMode}>
          <Invoice
            data-testid="invoice-attachment-img"
            onLoad={onAttachmentLoaded}
            src={attachment}
            alt="invoice"
            onClick={onOpenPdfPreview}
          />
        </AttachmentWrapper>
      )}
      {isEditMode && (
        <UploadAttachment
          uploadLabel={uploadLabel}
          deleteLabel="getPro.cards.actions.edit.invoice.attachment.delete"
          onChangeAttachment={onChangeAttachmentClicked}
          onDeleteAttachment={onDeleteAttachment}
          isShowDeleteButton={!!fileStorageUrl}
          accept={ACCEPT_FILES.BILL_ATTACHMENT}
        />
      )}
    </Container>
  );
};

export default InvoiceAttachment;

const Container = styled.div<{
  isEditMode?: boolean;
  isNoAttachment?: boolean;
}>`
  width: ${(props) => props.theme.sizes.attachmentLarge};
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${devices.mobile} {
    align-items: flex-start;
    justify-content: ${(props) => (props.isEditMode ? 'space-between' : 'flex-start')};
    margin: ${(props) => (props.isNoAttachment ? 0 : '2rem 0 5rem')};
    width: ${(props) => (props.isEditMode ? '100%' : '40%')};
  }
`;

const attachmentStyles = css`
  max-width: 100%;
  max-height: 22.8rem;
`;

const attachmentWrappersStyles = css`
  height: auto;
  border-radius: 0.6rem;
  width: ${(props) => props.theme.sizes.attachmentLarge};
`;

const AttachmentPlaceholder = styled.img<{ isEditMode?: boolean }>`
  ${attachmentStyles};
  ${attachmentWrappersStyles};
  background-color: white;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6rem;

  @media ${devices.mobile} {
    display: ${(props) => (props.fileStorageUrl && !props.isEditMode ? 'flex' : 'none')};
    margin: ${(props) => (props.isEditMode ? '2rem 0 1.6rem' : '2rem 0 0')};
  }
`;

const AttachmentWrapper = styled.div<{
  isVisible?: boolean;
  isEditMode?: boolean;
}>`
  ${attachmentWrappersStyles};
  position: relative;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  pointer-events: ${(props) => (props.isEditMode ? 'none' : 'auto')};
  border: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0.4rem 1rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6rem;

  &:hover {
    box-shadow: ${(props) => `0 0.5rem 1.5rem ${props.theme.colors.dark.translucent2}`};
  }

  &:hover .counter-icon-container {
    background-color: ${(props) => props.theme.text.color.label};
  }

  @media ${devices.mobile} {
    margin-bottom: ${(props) => (props.isEditMode ? '0' : '1.6rem')};
    margin: ${(props) => (props.isEditMode ? '0 0 2rem' : '0')};
    box-shadow: none;
  }
`;

const Invoice = styled.img`
  ${attachmentStyles};
  border-radius: 0.6rem;
  cursor: pointer;
`;
