import { DeliveryDateFormat } from 'src/components/common/DeliveryDateFormat';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { DeliveryOptionType } from 'src/utils/types';
import { BetterExposteEtaDeliveryOptionSubtitle } from './BetterExposteEtaDeliveryOptionSubtitle';

type Props = {
  subtitle: string;
  subtitleValues: Record<string, React.ReactNode>;
  deliveryOption: DeliveryOptionType;
  deliveryExplanationLabel: string;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const PaymentDeliveryOptionSubtitle = ({
  subtitle,
  subtitleValues,
  deliveryOption,
  deliveryExplanationLabel,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: Props) => {
  if (isEligibleForFastAchAdoption) {
    return isFastAchBetterExposeEtaEnabled ? (
      <BetterExposteEtaDeliveryOptionSubtitle deliveryOption={deliveryOption} label={deliveryExplanationLabel} />
    ) : (
      <MIFormattedText label={subtitle} values={subtitleValues} />
    );
  }

  return isFastAchBetterExposeEtaEnabled ? (
    <BetterExposteEtaDeliveryOptionSubtitle deliveryOption={deliveryOption} label={deliveryExplanationLabel} />
  ) : (
    <MIFormattedText
      label={deliveryExplanationLabel}
      values={{
        date: <DeliveryDateFormat date={deliveryOption.deliveryDate} maxDate={deliveryOption.maxDeliveryDate} />,
      }}
    />
  );
};
