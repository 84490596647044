import { hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { createListSlice } from 'src/helpers/redux/restListSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from '../consts';

export const proRequestsListSlice = createListSlice({
  storeName: name,
  api: getProApi.requests.list,
  actionName: 'proList',
  listHashFunc: hashListKey,
});
