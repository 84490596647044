import { MouseEvent, useMemo } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuDivider, MenuItem, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { ReactComponent as FilterIcon } from 'src/images/get-pro/menu-filter.svg';
import { GetProTabs } from 'src/utils/consts';
import { InProgressStatusFilters } from '../../../../consts';
import { filterMenuIconStyle } from '../styles';
import { CheckedFilterMenuItem } from './Item';

type FilterMenuProps = {
  tab: GetProTabs;
  options: InProgressStatusFilters;
  selectedOptions: Array<string>;
  onChange(value: string, isChecked: boolean): void;
  onClearAll(): void;
};

export const CheckedFilterMenu = ({ tab, options, selectedOptions, onChange, onClearAll }: FilterMenuProps) => {
  const isClearAllDisabled = selectedOptions?.length === 0;

  const getIsChecked = (option: string) => selectedOptions.includes(option);

  const { filterLabel, filterLabelValues } = useMemo((): {
    filterLabel: string;
    filterLabelValues?: Record<string, number>;
  } => {
    if (selectedOptions?.length > 0) {
      const [firstSelectedOption] = selectedOptions;
      const selectedOptionsNum = selectedOptions.length;
      const filterLabel = `getPro.statusFilter.${tab}.${firstSelectedOption}`;
      const numOfOtherStatuses = selectedOptionsNum - 1;

      return {
        filterLabel,
        filterLabelValues: { numOfOtherStatuses },
      };
    }

    return { filterLabel: `getPro.statusFilter.all` };
  }, [selectedOptions]);

  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton
        as={Button}
        mr={3}
        variant={ButtonVariants.tertiary}
        rightIcon={<ChevronDownIcon />}
        leftIcon={<FilterIcon />}
        size={ButtonSizes.sm}
        __css={filterMenuIconStyle}
        data-testid={`getPro-${tab}-status-filter-menu-button`}
        skipAnalyticsEvent
      >
        <MIFormattedText label={filterLabel} values={filterLabelValues} />
      </MenuButton>
      <MenuList>
        {Object.values(options).map((option) => {
          const isChecked = getIsChecked(option);
          const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            onChange(option, !isChecked);
          };

          return (
            <MenuItem key={option} onClick={handleClick} as={Button}>
              <CheckedFilterMenuItem value={option} isChecked={isChecked} />
            </MenuItem>
          );
        })}
        <MenuDivider />
        <MenuItem onClick={onClearAll} isDisabled={isClearAllDisabled} testId={`getPro.${tab}.statusFilter.clearAll`}>
          <MIFormattedText label="getPro.statusFilter.clearAll" />
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
