import { FunctionComponent, useCallback } from 'react';
import { OnComplete, UseWizardArgs } from 'src/hooks/useWizard/useWizard';
import { useWizardSimple } from 'src/hooks/useWizard/useWizardSimple';
import { AddBankDetailsPageContainer } from 'src/pages/vendor/delivery-methods/add-ach-flow/steps/first/AddBankDetailsPageContainer';
import { FlowComponentPropsType, FlowCustomizationsType, Steps } from './types';

type Props = {
  vendorId: number;
  deliveryMethodId?: string;
  flowCustomizations: FlowCustomizationsType;
  saveAndClose?: OnComplete;
  saveAndContinue: OnComplete;
};

const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  'bank-details': 'bank-details',
};

const stepToComponent: Record<Steps, FunctionComponent<FlowComponentPropsType>> = {
  'bank-details': AddBankDetailsPageContainer,
};

export const AddAchFlow = ({
  vendorId,
  deliveryMethodId,
  flowCustomizations,
  saveAndContinue,
  saveAndClose,
}: Props) => {
  const { cancelFlow, completeFlow } = useWizardSimple<Steps>({
    firstStep: 'bank-details',
    flowName: 'add-ach-dm-flow',
    locationsMap,
    navigationSequence: ['bank-details'],
    cancelUrlFallback: '/',
  });

  const ComponentToRender = stepToComponent['bank-details'];
  const saveAndCloseCallback = useCallback(() => {
    saveAndClose && completeFlow(saveAndClose);
  }, [completeFlow, saveAndClose]);

  const saveAndContinueCallback = useCallback(() => {
    completeFlow(saveAndContinue);
  }, [completeFlow, saveAndContinue]);

  return (
    <>
      {ComponentToRender ? (
        <ComponentToRender
          vendorId={vendorId}
          deliveryMethodId={deliveryMethodId}
          flowCustomizations={flowCustomizations}
          cancelFlow={cancelFlow}
          saveAndClose={saveAndCloseCallback}
          saveAndContinue={saveAndContinueCallback}
        />
      ) : null}
    </>
  );
};
