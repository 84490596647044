import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { CompanyInfoType, UserOrganization } from 'src/utils/types';
import { userManagementApi } from './api';

type PayloadType = {
  orgId: number;
  userIds: number[];
};

export type AllUserOrganizationsApiResponseType = {
  allUserOrganizations: {
    userId: number;
    userOrganizations: UserOrganization[];
  }[];
  orgsOfLoggedInUser: CompanyInfoType[];
};

export const allUserOrganizationsSlice = createApiCallSlice<PayloadType, any, AllUserOrganizationsApiResponseType>({
  name: '[USERS] RELATED_USER_ORGANIZATIONS',
  api: userManagementApi.allUserOrganizations,
  initialState: {
    allUserOrganizations: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.allUserOrganizations.loading = true;
      state.allUserOrganizations.error = undefined;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.allUserOrganizations.loading = false;
      state.allUserOrganizations.error = undefined;
      payload.allUserOrganizations.forEach((item) => {
        const curUser = state.byId[item.userId];

        if (curUser) {
          curUser.userOrganizations = item.userOrganizations;
        }
      });
    },
    [ON_FAILURE]: (state, action) => {
      state.allUserOrganizations.loading = false;
      state.allUserOrganizations.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state.users.allUserOrganizations.loading,
  },
});
