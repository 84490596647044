import { lazy, Suspense } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { AppLayout } from 'src/components/layout/AppLayout';
import { billLocations } from 'src/pages/bill/locations';
import { NewBillCreateOptionsPageContainer } from 'src/pages/bill/NewBillCreateOptionsPageContainer';
import { analytics } from 'src/services/analytics';

const PayListPageContainerAsync = lazy(() =>
  import('../pay/PayListPageContainer').then((module) => ({ default: module.PayListPageContainer }))
);
const NewBillPageContainerAsync = lazy(() =>
  import('./NewBillPageContainer').then((module) => ({ default: module.NewBillPageContainer }))
);

const CreateBillScannedInvoicePage = lazy(() =>
  import('./CreateBillScannedInvoicePage').then((module) => ({ default: module.CreateBillScannedInvoicePage }))
);

const eventPage = 'bills';

export const billsRoutes = [
  {
    path: billLocations.index,
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'bills-list');

      return (
        <AppLayout>
          <Suspense fallback={<AreaLoader />}>
            <PayListPageContainerAsync {...props} />
          </Suspense>
        </AppLayout>
      );
    },
  },
  {
    path: billLocations.create.index,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'new-bill');

      return (
        <Suspense fallback={<AreaLoader />}>
          <NewBillPageContainerAsync {...cProps} />
        </Suspense>
      );
    },
  },
  {
    path: billLocations.createScannedInvoice,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'new-bill-scanned-invoice');

      return (
        <Suspense fallback={<AreaLoader />}>
          <CreateBillScannedInvoicePage {...cProps} />
        </Suspense>
      );
    },
  },
  {
    path: billLocations.create.options,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'new-bill-create-options');

      return <NewBillCreateOptionsPageContainer {...cProps} />;
    },
  },
];
