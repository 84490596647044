import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { receivingMethodLocations } from 'src/pages/receiving-method/locations';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import accountingSoftwareApi from 'src/services/api/accountingSoftwareSync';
import { useQueryString } from 'src/utils/hooks';
import locations from 'src/utils/locations';
import { LinkAccountingSoftwarePage } from '../components/LinkAccountingSoftwarePage';
import { CONTINUE, eventPage } from './consts';

const LinkQuickbooksPageContainer = () => {
  const query = useQueryString();
  const [historyPush] = useHistoryWithOrgId();
  const [isLoading, setIsLoading] = useState(true);
  const [errorCode, setErrorCode] = useState('');
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);

  const goByRedirect = useCallback(
    (type: RedirectOriginPages, eventName: string) => {
      analytics.track(eventPage, eventName);
      historyPush({
        path: receivingMethodLocations.redirect,
        params: { vendorId: ownedVendorId! },
        query: { ...query, redirect: type },
      });
    },
    [historyPush, ownedVendorId, query]
  );

  const runFullSync = useCallback(async () => {
    let continueAction = CONTINUE.SUCCESS;

    if (query?.action === CONTINUE.BILLS) {
      continueAction = CONTINUE.BILLS;
    }

    try {
      await accountingSoftwareApi.syncQuickBooksData(orgId);

      if (continueAction === CONTINUE.BILLS) {
        goByRedirect(RedirectOriginPages.PAY_DASHBOARD, 'continue-to-bills');
      } else {
        goByRedirect(RedirectOriginPages.PAY_SUCCESS, 'success');
      }
    } catch (e: any) {
      setIsLoading(false);
      setErrorCode(e?.code || '');
    }
  }, [goByRedirect, orgId, query.action]);

  useEffect(() => {
    runFullSync();
  }, [runFullSync]);

  const goTryAgain = () => {
    analytics.track(eventPage, 'try-again');
    historyPush({
      path: onboardingLocations.accountingSoftware.index,
      query,
    });
  };

  const goCancel = () => {
    analytics.track(eventPage, 'cancel');
    historyPush({ path: locations.MainApp.dashboard.url(), query });
  };

  return (
    <LinkAccountingSoftwarePage
      errorCode={errorCode}
      goTryAgain={goTryAgain}
      goCancel={goCancel}
      isLoading={isLoading}
    />
  );
};

export { LinkQuickbooksPageContainer };
