import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { BillFileLoader } from 'src/components/common/BillFileLoader';
import { MILink } from 'src/components/common/MILink';
import { MINotificationCardProps } from 'src/components/common/MINotificationCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import syncQuickbooks from 'src/images/accounting-software/quickbooks-logo.svg';
import { profileStore } from 'src/modules/profile/profile-store';
import { BillCreateOptions } from 'src/pages/bill/components/BillCreateOptions';
import { analytics } from 'src/services/analytics';
import { BillCreateOptionsType } from 'src/utils/types';
import UploadFileError from './UploadFileError';

type Props = {
  onChangeAttachment: (file: File, loadBillFromAttachment?: boolean) => void;
  goExit: () => void;
  goManual: () => void;
  handleUploadCancel: () => void;
  handleManualClick: () => void;
  handleQuickbooksClick: () => void;
  handleSkipCreateBillClick: () => void;
  handleCancelCreateBillClick: () => void;
  isUploading: boolean;
  isOnboarding: boolean;
  isUploadError: boolean;
  errorNotification?: MINotificationCardProps;
};

const eventPage = 'new_vendor_add-bill-options';

const NewBillCreateOptionsPage = ({
  handleUploadCancel,
  isUploading,
  goExit,
  goManual,
  isOnboarding,
  handleManualClick,
  handleQuickbooksClick,
  handleSkipCreateBillClick,
  handleCancelCreateBillClick,
  isUploadError,
  onChangeAttachment,
  errorNotification,
}: Props) => {
  const site = useSiteContext();
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const { isDesktop } = useBreak();
  const { connectedAccountingPlatform, status } = useFetchAccountingPlatformsAndData();

  const options: Array<BillCreateOptionsType> = [
    {
      id: 'add-bill-manually',
      icon: 'tabletEdit',
      label: 'bills.new.createOptions.addBillManually',
      description: 'bills.new.createOptions.addBillManuallyDescription',
      click: handleManualClick,
    },
    {
      id: 'scan-bill',
      icon: 'camera',
      label: 'bills.new.createOptions.scanBill',
      description: 'bills.new.createOptions.scanBillDescription',
      hideOption: isDesktop,
    },
    {
      id: 'import-bill-file',
      icon: 'upload',
      label: 'bills.new.createOptions.importBillFile',
      description: 'bills.new.createOptions.importBillFileDescription',
    },
    {
      id: 'sync-with-quickbooks',
      imgSrc: syncQuickbooks,
      label: 'bills.new.createOptions.syncWithQuickbooks',
      description: 'bills.new.createOptions.syncWithQuickbooksDescription',
      hideOption: !permissions.settings.editAccountingSoftware() || !!connectedAccountingPlatform,
      click: handleQuickbooksClick,
    },
  ];

  const filteredOptions = options.filter((option) => !option.hideOption);

  if (status?.loading) {
    return <AreaLoader />;
  }

  const handleSkipButtonClick = () => {
    analytics.track(eventPage, `skip-add-bill-flow`);
    handleSkipCreateBillClick();
  };

  return (
    <>
      {isUploading && !isUploadError && <BillFileLoader onCancelAction={handleUploadCancel} />}
      {!isUploading && isUploadError && <UploadFileError handleUploadCancel={handleUploadCancel} goManual={goManual} />}
      {!isUploading && !isUploadError && (
        <StepLayoutPage
          relativeStep={0}
          headerLabel="bills.new.createOptions.header.title"
          goExit={goExit}
          title="bills.new.createOptions.title"
          subtitle="bills.new.createOptions.subTitle"
          onPrev={site.embeddedMode ? handleCancelCreateBillClick : undefined}
          innerSize={100}
          isHideActions
          notification={errorNotification}
        >
          <BillCreateOptions flavor="block" options={filteredOptions} onChangeAttachment={onChangeAttachment} />
          {!isOnboarding && (
            <CancelButtonContainer>
              <MILink label="bills.new.createOptions.cancel" to={handleCancelCreateBillClick} />
            </CancelButtonContainer>
          )}
          {isOnboarding && (
            <WizardOnboardingElementsContainer>
              <WizardElements.WizardOrLine />
              <WizardElements.WizardLinkContainer>
                <MILink label="bills.new.createOptions.skipCreation" to={handleSkipButtonClick} />
              </WizardElements.WizardLinkContainer>
            </WizardOnboardingElementsContainer>
          )}
        </StepLayoutPage>
      )}
    </>
  );
};

export default NewBillCreateOptionsPage;

const CancelButtonContainer = styled.div`
  margin-top: 3.8rem;
  display: flex;
  justify-content: center;

  ${(props) => props.theme?.pages?.NewBillCreateOptionsPage?.BillCreateOptions?.CancelButtonContainer}
`;

const WizardOnboardingElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
