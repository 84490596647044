import { useApi } from 'src/hoc/useApi';
import { organizationsApi } from 'src/modules/organizations/api';

export const useFetchFreeChecks = () => {
  const { onApiCall: fetchFreeChecks, loading: isFetchingFreeChecks, result: freeChecks } = useApi({
    api: organizationsApi.getFreeChecks,
  });

  return {
    fetchFreeChecks,
    isFetchingFreeChecks,
    total: freeChecks?.total,
    available: freeChecks?.available,
  };
};
