import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { qbo } from 'src/config';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { analytics } from 'src/services/analytics';
import { melioError } from 'src/utils/external-events';

type Props = {
  illustration: string;
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonAction: () => void;
};

export const ErrorPage = ({ illustration, title, subtitle, buttonLabel, buttonAction }: Props) => {
  const goExit = () => {
    analytics.track('bill-error', 'close-integration');
    melioError();
  };

  return (
    <Flex __css={LayoutContainerStyle}>
      <Box onClick={goExit} pos="fixed" top={3} right={5} textStyle="body1" cursor="pointer">
        <Icon name={IconNames.close} />
      </Box>
      <Flex __css={ErrorContainerStyle}>
        <Image src={illustration} w="auto" mb={10} h="9.6rem" />

        <Box color="black" alignSelf="center" textAlign="center" mb={3} textStyle="h1Semi">
          <MIFormattedText label={title} />
        </Box>

        <Box color="black" mb={10} textAlign="center" textStyle="body2">
          <MIFormattedText
            label={subtitle}
            values={{
              email: <a href={`mailto:${qbo.support.email}`}>{qbo.support.email}</a>,
            }}
          />
        </Box>

        <Box>
          <Button label={buttonLabel} onClick={buttonAction} />
        </Box>
      </Flex>
    </Flex>
  );
};

const LayoutContainerStyle = {
  display: 'flex',
  flexGrow: 2,
  flexDir: 'column',
  justify: 'space-between',
  align: 'center',
  minH: '100vh',
  pos: 'relative',
};

const ErrorContainerStyle = {
  display: 'flex',
  flexDir: 'column',
  flexGrow: 1,
  align: 'center',
  justify: 'center',
  w: { base: 'full', md: '50rem' },
  mb: { md: 8 },
};
