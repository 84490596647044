import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { useGetFundingSourceDisplayName } from 'src/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import { getCardIcon, getCardImageUrl } from 'src/utils/card';
import { FundingType } from 'src/utils/consts';
import { hasFundingLogo } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

type Props = {
  method: AccountType;
  className?: string;
};

export function TruncatedMethodCard({ method, className }: Props) {
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  let icon: typeof IconNames | string;
  let imageSrc = '';

  if (method.fundingType === FundingType.ACH) {
    const hasLogo = hasFundingLogo(method);
    imageSrc = hasLogo ? `data:image/jpeg;base64,${method.logo}` : '';
    icon = imageSrc ? '' : IconNames.bank;
  } else {
    icon = getCardIcon(method);
    imageSrc = getCardImageUrl(method);
  }

  return (
    <Flex className={className} p={4} border="1px" borderColor="grey.400" borderRadius="lg">
      <Box color="black" me={5} display="flex">
        {icon && <Icon name={icon as IconNames} size={IconSize.xl} />}
        {imageSrc && <Image src={imageSrc} alt="method-icon" h={8} />}
      </Box>
      <Flex align="center" isTruncated textStyle="body2Semi">
        {getFundingSourceDisplayName({ fundingSource: method, useShortName: false })}
      </Flex>
    </Flex>
  );
}
