import Box from 'src/core/ds/box';

type Props = {
  children: React.ReactNode;
};

export const CardContainer = ({ children }: Props) => (
  <Box px={{ base: 4, md: 8 }} py={8}>
    {children}
  </Box>
);
