import { AmexNotificationDetails } from '@melio/spend-management-api-axios-client';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Option } from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import Spacer from 'src/core/ds/spacer';
import { Tag } from 'src/core/ds/tag';

type Props = {
  context: 'value' | 'menu';
  account: AmexNotificationDetails;
  isSelectedAccount: boolean;
};

export type AccountOptionType = Option & {
  account: AmexNotificationDetails;
  label: string;
  isSelectedAccount: boolean;
};

export const AccountOption = ({ context, account, isSelectedAccount }: Props) => {
  const isSelected = context === 'value';

  if (!account) {
    return null;
  }

  return !isSelected ? (
    <Flex direction="row" alignItems="center">
      <Flex gap={2} alignItems="center">
        <Icon name={IconNames.creditCard} />
        <Box isTruncated>
          <MIFormattedText
            label="spendManagement.header.connectedAccount.items.account"
            values={{ last5: account.lastFive, nickname: account.accountNickname || account.productDescription }}
          />
        </Box>
      </Flex>
      <Spacer />
      <Flex ml={2} gap={2} alignItems="center">
        <>
          {account.isDefault ? <Tag label="spendManagement.header.connectedAccount.items.default" /> : null}
          {isSelectedAccount ? <Icon name={IconNames.check} color="primary.500" /> : null}
        </>
      </Flex>
    </Flex>
  ) : (
    <Flex>
      <MIFormattedText
        label="spendManagement.header.connectedAccount.items.account"
        values={{ last5: account.lastFive, nickname: account.accountNickname || account.productDescription }}
      />
    </Flex>
  );
};
