export enum InvoiceGenerationOption {
  OptIn = 'optIn',
  NoOptIn = 'noOptIn',
}

type Option = { label: string; subLabel: string; value: string };

export const invoiceGenerationOptions: Record<InvoiceGenerationOption, Option> = {
  [InvoiceGenerationOption.OptIn]: {
    label: 'settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.optInRadio.title',
    subLabel: 'settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.optInRadio.subtitle',
    value: InvoiceGenerationOption.OptIn,
  },
  [InvoiceGenerationOption.NoOptIn]: {
    label: 'settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInRadio.title',
    subLabel: 'settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInRadio.subtitle',
    value: InvoiceGenerationOption.NoOptIn,
  },
};

export const INVOICE_GENERATION_GROUP = 'invoice-generation';
