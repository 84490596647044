import { getValidationErrors } from '@melio/sizzers-js-common';
import merge from 'lodash/merge';
import { DirectoryVendorType } from 'src/pages/bill/hooks/types';
import { getAccountIdentifierValidationError } from 'src/pages/vendor-directory/select-vendor/utils';
import { DirectoryType } from 'src/utils/consts';
import { CreateBillType } from 'src/utils/types';

type ReturnValue = {
  getBillValidationErrors: (
    value: CreateBillType,
    fields: (keyof CreateBillType)[],
    context?: Record<string, unknown>
  ) => Promise<Record<string, string>>;
};

export const useValidateBillForm = (
  orgId: number,
  getDirectoryVendorFromVendorOption: (vendorId: number) => DirectoryVendorType | undefined
): ReturnValue => {
  const getBillValidationErrors = async (
    value: CreateBillType,
    fields: (keyof CreateBillType)[],
    context?: Record<string, unknown>
  ) => {
    const { vendorId, accountIdentifier } = value;

    let validationErrors = getValidationErrors('bill', value, fields, context) as Record<string, string>;
    const directoryVendor = getDirectoryVendorFromVendorOption(vendorId as number);

    if (directoryVendor?.directoryId && directoryVendor?.directoryType === DirectoryType.Biller) {
      const accountIdentifierValidationError = await getAccountIdentifierValidationError(
        orgId,
        directoryVendor.directoryId,
        accountIdentifier
      );

      validationErrors = merge(validationErrors, accountIdentifierValidationError);
    }

    return validationErrors;
  };

  return { getBillValidationErrors };
};
