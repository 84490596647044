import { ReactNode } from 'react';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import Box from 'src/core/ds/box';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { ModelViewField } from 'src/ui/form';
import { AddressFormControlMode, ScreenMode } from 'src/utils/consts';
import { GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  companyName: ModelViewField<string | null>;
  address: GoogleCombinedAddressType;
  phone: ModelViewField<string>;
  contactFirstName: ModelViewField<string>;
  contactLastName: ModelViewField<string>;
  validationErrors: Record<string, any>;
  mode?: ScreenMode;
  goToManualAddress?: () => void;
  onAddressChange: (address: GoogleCombinedAddressType) => void;
  email?: ModelViewField<string>;
};

type FormRowProps = {
  children: ReactNode;
};

const FormRow = ({ children }: FormRowProps) => <Box mt={4}>{children}</Box>;

export const CompanyInfoDetailsForm = ({
  mode = ScreenMode.EDIT,
  companyName,
  address,
  phone,
  contactFirstName,
  contactLastName,
  validationErrors,
  onAddressChange,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  goToManualAddress = () => {},
  email,
}: Props) => {
  const isViewOnly = mode === ScreenMode.VIEW;

  return (
    <Box>
      <FormRow>
        <TextField
          id="companyName"
          label="companyInfo.name.inputTitle"
          model={companyName}
          errorMessage={validationErrors.companyName}
          size="sm"
          isRequired
          isViewOnly={isViewOnly}
        />
      </FormRow>
      <PrivateDataContainer>
        <FormRow>
          <TextField
            id="email"
            label="companyInfo.email"
            model={email}
            size="sm"
            isRequired
            isDisabled
            isViewOnly
            data-testId="owned-vendor-email"
          />
        </FormRow>
      </PrivateDataContainer>
      <FormRow>
        <PrivateDataContainer>
          <MIAddressAutocomplete
            id="address"
            addressLabel="form.addressAutocomplete.addressLabel"
            aptNumberLabel="form.addressAutocomplete.aptNumberLabel"
            aptNumberPlaceholder={isViewOnly ? 'form.addressAutocomplete.emptyNumberLabel' : ''}
            address={address}
            mode={AddressFormControlMode.FORM}
            onChange={onAddressChange}
            errorMessage={validationErrors.googlePlaceId || validationErrors.zipCode}
            goToManualAddress={goToManualAddress}
            isViewOnly={isViewOnly}
            showAddManualAddress
            required
          />
        </PrivateDataContainer>
      </FormRow>
      <FormRow>
        <PrivateDataContainer>
          <MaskField
            id="phone"
            label="onboarding.companyInfo.phone.inputTitle"
            placeholder="onboarding.companyInfo.phone.emptyLabel"
            model={phone}
            type="tel"
            format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
            errorMessage={validationErrors.phone}
            size="sm"
            isViewOnly={isViewOnly}
            isModelOnChangeFormatted
            isRequired
          />
        </PrivateDataContainer>
      </FormRow>
      <FormRow>
        <TextField
          id="contactFirstName"
          label="onboarding.companyInfo.contactName.inputTitleFirstName"
          model={contactFirstName}
          errorMessage={validationErrors.contactFirstName}
          isRequired
          size="sm"
          isViewOnly={isViewOnly}
        />
      </FormRow>
      <FormRow>
        <TextField
          id="contactLastName"
          label="onboarding.companyInfo.contactName.inputTitleLastName"
          model={contactLastName}
          errorMessage={validationErrors.contactLastName}
          isRequired
          size="sm"
          isViewOnly={isViewOnly}
        />
      </FormRow>
    </Box>
  );
};
