import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { generatePath } from 'react-router-dom';
import { ContactSupportLink } from 'src/components/common/ContactSupportLink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { NotificationVariant } from 'src/core/ds/toast';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getPasswordValidation } from 'src/pages/auth/utils';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getDeliveryMethods } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { TextInputSize } from 'src/utils/consts';
import { getIsDeliveryMethodVerifiedByVerificationMethod } from 'src/utils/delivery-methods';
import { capture } from 'src/utils/error-tracking';
import { useCreatePassword } from './hooks/useCreatePassword';

type Props = {
  email: string;
  orgId: string;
};

export const CreatePasswordPage = ({ email, orgId }: Props) => {
  const analyticsInfo = {
    flowEntryPoint: 'msn-registration',
    email,
    orgId,
  };
  const { navigate } = useNavigator();
  const { token } = useRouteMatch<{ token: string }>().params;
  const { createPasswordAndInitUser, isLoading } = useCreatePassword(Number(orgId));
  const passwordModel = useMemo(() => ({ password: '' }), []);
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const isDeliveryMethodVerified = getIsDeliveryMethodVerifiedByVerificationMethod(deliveryMethod);
  const [passwordMV, { submit }, validationErrors] = useForm(passwordModel, {
    submit: ({ password }) => handleSubmitPassword(password),
    validateOnChange: false,
    validator: getPasswordValidation,
  });
  const errorToastRef = useRef(null);

  useEffect(() => {
    if (validationErrors && validationErrors.password) {
      analytics.trackAction('msnClaimAccountCreatePassword.continue', analyticsInfo);
      analytics.trackAction('msnClaimAccountCreatePassword.validation-error', analyticsInfo);
    }
  }, [validationErrors]);

  const handleSubmitPassword = async (password: string) => {
    analytics.trackAction('msnClaimAccountCreatePassword.continue', analyticsInfo);
    try {
      await createPasswordAndInitUser({ token, password });
      analytics.trackAction('msnClaimAccountCreatePassword.success', analyticsInfo);

      if (isDeliveryMethodVerified) {
        analytics.trackAction('msnClaimAccountCreatePassword.registration-completed', {
          networkRegistrationFlow: 'publicForm',
          accountVerificationMethod: deliveryMethod?.verifiedStatus,
        });
      }

      navigate(generatePath(onboardingLocations.msnRegistration.claimAccount.deliveryMethodStatusCheck, { orgId }));
    } catch (err) {
      analytics.trackAction('msnClaimAccountCreatePassword.error', analyticsInfo);

      if (!checkIfToastIsDisplayedById(errorToastRef.current)) {
        errorToastRef.current = pushNotification({
          type: NotificationVariant.error,
          msg: 'onboarding.msnCompanyInfo.generalError.text',
          textValues: {
            contactSupport: <ContactSupportLink />,
          },
          autoClose: 10000,
        });
      }

      capture(err as Error);
    }
  };

  return (
    <StepLayoutPage
      title="auth.msnClaimAccount.createPassword"
      subtitle="auth.msnClaimAccount.subtitle"
      onNext={submit}
      fullWidthCTA
      isLoading={isLoading}
      nextButtonAnalyticsProperties={analyticsInfo}
    >
      <WizardTextInputField
        id="email"
        label="auth.msnClaimAccount.businessEmail"
        size={TextInputSize.WIZARD}
        value={email}
        type="email"
        disabled
        required
      />
      <WizardPasswordValidationInputField
        testId="input-password"
        label="auth.vendorRegister.password"
        model={passwordMV.password}
        isRequired
        helperText="auth.vendorRegister.passwordNotice"
      />
    </StepLayoutPage>
  );
};
