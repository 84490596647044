import isBoolean from 'lodash/isBoolean';
import { clearStateReducer } from 'src/helpers/redux/clearStateReducer';
import { composeSlices } from 'src/helpers/redux/composeSlice';
import { profileStore } from 'src/modules/profile/profile-store';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { UserAuthType } from 'src/utils/consts';
import {
  acceptInvitationSlice,
  acceptUserInvitationSlice,
  regularRegistrationSlice,
  StateType as AcceptUserInvitationState,
  vendorRegistrationSlice,
} from './slices';

const name = 'auth';

type AcceptInvitation = {
  loading?: boolean;
  error?: any | null;
  success?: boolean;
};

type Registration = {
  loading?: boolean;
  error?: any | null;
  user?: any;
};

type AuthState = {
  acceptInvitation: AcceptInvitation;
  registration: Registration;
  acceptUserInvitation: AcceptUserInvitationState;
};

const initialState: AuthState = {
  acceptInvitation: {
    loading: false,
    error: null,
    success: false,
  },
  registration: {
    loading: false,
    error: null,
    user: null,
  },
  acceptUserInvitation: {
    loading: false,
    error: null,
  },
};

const selectors = {
  isRegisteringFromInvitation: (state: any) => state[name].acceptInvitation?.loading,
  registrationError: (state: any) => state[name].acceptInvitation?.error,
  isRegistrationSuccess: (state: any) => state[name].acceptInvitation?.success,
  authenticated: (state: any) => getIsLoggedIn(state),
  userAuthType: (state: any) => {
    const profile = profileStore.selectors.profile(state);
    const isLoggedIn = selectors.authenticated(state);
    let userAuthType = UserAuthType.UNAUTHENTICATED;

    if (isLoggedIn) {
      if (profile.isGuest) {
        userAuthType = UserAuthType.GUEST;
      } else if (isBoolean(profile.hasValid2Factor) && !profile.hasValid2Factor) {
        userAuthType = UserAuthType.TWO_FA_REQUIRED;
      } else if (!profile.isEmailVerified) {
        userAuthType = UserAuthType.EMAIL_NOT_VERIFIED;
      } else {
        userAuthType = UserAuthType.AUTHENTICATED;
      }
    }

    return userAuthType;
  },
  registration() {
    const registerSelectors = {
      isLoading(state) {
        return state[name].registration?.loading;
      },
      error(state) {
        return state[name].registration?.error;
      },
      user(state) {
        return state[name].registration?.user;
      },
      isFirstTimeVisitedRegularMelio(state) {
        return state[name].registration?.isFirstTimeVisitedRegularMelio;
      },
    };

    return registerSelectors;
  },
};

type AcceptInvitationParams = {
  token: string;
  password?: string;
};

export function getAuthActions(dispatch: any) {
  return {
    acceptInvitation: (params: AcceptInvitationParams) => dispatch(acceptInvitationSlice.actions(params)),
  };
}

const authenticationStore = composeSlices(
  {
    acceptInvitation: acceptInvitationSlice,
    vendorRegistration: vendorRegistrationSlice,
    regularRegistration: regularRegistrationSlice,
    acceptUserInvitation: acceptUserInvitationSlice,
  },
  {
    selectors,
    extraReducers: { ...clearStateReducer(initialState) },
  }
);

export default authenticationStore;
