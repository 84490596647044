import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import DeliveryMethodListItem from 'src/pages/bill/pay/components/DeliveryMethodListItem';
import { sectionHeaderStyles } from 'src/pages/regular-batch-payments/components/deliveryMethod/styles';
import { DeliveryMethodOptions, DeliveryMethodType } from 'src/utils/types';

type Props = {
  showFullDeliveryMethodsList: boolean;
  deliveryMethodOptions: DeliveryMethodOptions;
  companyName: string;
  goAddDeliveryMethod: (dm) => void;
  isInternationalDeliveryMethodDisabled?: boolean;
  internationalMethodDesc?: string;
  selectedDeliveryMethod?: string;
  getActionOptions?: (dm: DeliveryMethodType) => ActionOption[];
};

export const DeliveryMethodOptionsList = ({
  showFullDeliveryMethodsList,
  deliveryMethodOptions,
  companyName,
  goAddDeliveryMethod,
  isInternationalDeliveryMethodDisabled,
  internationalMethodDesc,
  selectedDeliveryMethod,
  getActionOptions,
}: Props) => {
  const { isInternationalAllowed } = useIsInternationalAllowed();
  const shouldShowFullDeliveryMethodsList = isInternationalAllowed && showFullDeliveryMethodsList;
  const internationalMethodDescription = isInternationalDeliveryMethodDisabled
    ? internationalMethodDesc
    : 'deliveryMethods.notDefinedInfo.international.methodDesc';

  const renderDomesticDeliveryMethodsList = () => (
    <>
      <Flex direction="column" data-testid="delivery-method-domestic-list">
        {deliveryMethodOptions.domestic.map((dm) => (
          <DeliveryMethodListItem
            key={dm.deliveryType}
            deliveryMethod={dm}
            companyName={companyName}
            isSelected={selectedDeliveryMethod ? selectedDeliveryMethod === dm.deliveryType : false}
            onItemClick={() => goAddDeliveryMethod(dm.deliveryType)}
            actionOptions={getActionOptions && getActionOptions(dm)}
          />
        ))}
      </Flex>
    </>
  );

  return (
    <>
      {shouldShowFullDeliveryMethodsList ? (
        <>
          <Box __css={sectionHeaderStyles}>
            <MIFormattedText label="deliveryMethods.titles.domestic" />
          </Box>
          {renderDomesticDeliveryMethodsList()}
          <Box mt={10} data-testid="delivery-method-international-list">
            <Box __css={sectionHeaderStyles}>
              <MIFormattedText label="deliveryMethods.titles.international" />
            </Box>
            {deliveryMethodOptions.international.map((dm) => (
              <DeliveryMethodListItem
                key={dm.deliveryType}
                deliveryMethod={dm}
                companyName={companyName}
                isSelected={selectedDeliveryMethod ? selectedDeliveryMethod === dm.deliveryType : false}
                onItemClick={() => !isInternationalDeliveryMethodDisabled && goAddDeliveryMethod(dm.deliveryType)}
                isDisabled={isInternationalDeliveryMethodDisabled}
                description={internationalMethodDescription}
                actionOptions={getActionOptions && getActionOptions(dm)}
              />
            ))}
          </Box>
        </>
      ) : (
        <>{renderDomesticDeliveryMethodsList()}</>
      )}
    </>
  );
};
