import {
  LinkCardViewContainer,
  RetrievedTokenizedData,
} from 'src/components/basis-theory/link-card-view/LinkCardViewContainer';
import Box from 'src/core/ds/box/Box';

type Props = {
  onLinkCard(tokenizedData: RetrievedTokenizedData): void;
  onError(error: unknown): void;
  showLinkCardButtonSpinner?: boolean;
  transparent?: boolean;
};

export const CardDetailsView = ({ onLinkCard, onError, showLinkCardButtonSpinner, transparent }: Props) => (
  <Box
    position="relative"
    mt={5}
    mb={10}
    opacity={transparent ? 0.2 : 1}
    w={{ base: 'full', lg: '65rem' }}
    h={{ base: '40rem', lg: '37rem' }}
    ml={{ base: 0, lg: '-10rem' }}
  >
    <LinkCardViewContainer
      onLinkCard={onLinkCard}
      onError={onError}
      showLinkCardButtonSpinner={showLinkCardButtonSpinner}
    />
  </Box>
);
