import { featureFlags } from '@melio/shared-web';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IconButton } from 'src/components/common/IconButton';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { IconNames } from 'src/core/ds/icon';
import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { profileStore } from 'src/modules/profile/profile-store';
import { FaqAccordion } from 'src/pages/settings/faq/FaqAccordion';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';
import { devices } from 'src/theme/appDevices';
import { CompanyType, FeatureFlags, Role } from 'src/utils/consts';
import { BROWSE_ALL_TOPICS_LINK } from './constants';

const onHelpTopicsClick = () => {
  const actionName = 'all-help-topics-clicked';
  analytics.trackAction(actionName);
};

export const SupportSettingsPageContainer = () => {
  const intl = useIntl();
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const isMSNPortal = useIsMsnDashboardDisplay();
  const url = shouldDisplayZendesk ? 'https://melio.zendesk.com/hc/en-us' : BROWSE_ALL_TOPICS_LINK;
  const site = useSiteContext();
  const { companyType } = useSelector(getCompanyInfo);
  const userRole = useSelector(profileStore.selectors.getUserRole);
  const isUserRoleAccountant = companyType === CompanyType.ACCOUNTING_FIRM || userRole === Role.ACCOUNTANT;

  const supportEmail = isUserRoleAccountant ? site.config.support.accountantsEmail : site.config.support.email;
  const helpCenterUrl = intl.formatMessage({ id: 'settings.support.helpCenterUrl' });
  const supportRequestUrl = intl.formatMessage({ id: 'settings.support.supportRequestUrl' });

  const links = {
    helpCenterLink: (
      <Link href={helpCenterUrl} isExternal>
        <MIFormattedText label="settings.support.helpCenterLabel" />
      </Link>
    ),
    supportLink: (
      <Link href={supportRequestUrl} isExternal>
        <MIFormattedText label="settings.support.supportRequestLabel" />
      </Link>
    ),
  };

  const onStartChatClick = () => {
    intercomService.show();
  };

  return (
    <MICard>
      <MICardForm>
        {!isMSNPortal ? (
          <>
            <MICardTitle label="settings.support.cardTitle" testId="support-title" />
            <SubTitle first>
              <MIFormattedText label="settings.support.cardSubtitle" />
            </SubTitle>
            <MICardTitle label="settings.support.popularTitle" testId="support-popular-title" />
            <FaqAccordion supportEmail={supportEmail} />
            <BrowseAllLink>
              <a onClick={onHelpTopicsClick} href={url} target="_open">
                <MIFormattedText label="settings.support.allHelpTopics" />
              </a>
            </BrowseAllLink>
          </>
        ) : null}
        <MICardTitle label="settings.support.contactTitle" testId="support-contact-title" />
        <SubTitle>
          <MIFormattedText label="settings.support.contactSubtitle" values={links} />
        </SubTitle>
        <ButtonContainer>
          <IconButton
            icon={IconNames.chatEmpty}
            testId="button-support-chat"
            label="settings.support.startChat"
            onClick={onStartChatClick}
            variant="inlineIconAndText"
            size="normal"
          />
        </ButtonContainer>
      </MICardForm>
    </MICard>
  );
};

const SubTitle = styled.div<{ first?: boolean }>`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  color: ${(props) => props.theme.colors.text};
  margin-top: 0.8rem;
  margin-bottom: 2rem;

  ${(props) =>
    props.first &&
    css`
      margin-bottom: 4rem;
    `}
`;

export const SupportLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const BrowseAllLink = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.colors.primary.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 4rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  a,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: none;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 4rem;
  width: 30%;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }

  & > :first-child {
    box-shadow: none;
    border: 2px solid ${(props) => props.theme.colors.primary.opaque};
    border-radius: 0.8rem;
    min-height: 4rem;
    height: 4rem;
    color: ${(props) => props.theme.colors.primary.opaque};
  }
`;
