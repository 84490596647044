import storageSession from 'redux-persist/lib/storage/session';
import { composeSlices } from 'src/helpers/redux/composeSlice';
import {
  businessOwnerDecisionSlice,
  getInstallmentOptionsSlice,
  intentIdWithInstallmentOptionSlice,
  setInstallmentOptionSlice,
} from 'src/modules/financing/financing-slice';
import {
  BEGIN_RECURRING_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW,
  END_PAY_BILL_FLOW,
  SELECT_FUNDING_SOURCE,
} from 'src/redux/payBillWizard/actionTypes';

const persistConfig = {
  key: 'payBillWizard-financing',
  storage: storageSession,
  whitelist: ['chosenInstallmentOption', 'setInstallmentOption', 'intentId'],
};

export const initialState = {
  ...intentIdWithInstallmentOptionSlice.initialState,
  ...setInstallmentOptionSlice.initialState,
  ...businessOwnerDecisionSlice.initialState,
  ...getInstallmentOptionsSlice.initialState,
};

export const financingStore = composeSlices(
  {
    intentIdWithInstallmentOption: intentIdWithInstallmentOptionSlice,
    setInstallmentOption: setInstallmentOptionSlice,
    getInstallmentOptions: getInstallmentOptionsSlice,
    businessOwnerDecision: businessOwnerDecisionSlice,
  },
  {
    initialState,
    extraReducers: {
      [BEGIN_REGULAR_PAY_BILL_FLOW]: () => initialState,
      [BEGIN_RECURRING_PAY_BILL_FLOW]: () => initialState,
      [END_PAY_BILL_FLOW]: () => initialState,
      [SELECT_FUNDING_SOURCE]: () => initialState,
    },
    selectors: {
      isFinancingPayment: (state) => !!financingStore.selectors.intentIdWithInstallmentOption.intentId(state),
    },
    extraSagas: {},
    dispatchers: {},
    persistConfig,
  }
);
