import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import config from 'src/config';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { globalLocations } from 'src/pages/locations';
import { CountryType } from 'src/pages/vendor/international-delivery-method/consts';
import { fireInternationalAnalyticsOnSubmit } from 'src/pages/vendor/international-delivery-method/event-mapping';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { getBankDetails as bankDetailsApi } from 'src/services/api/international';
import { ModelView, useForm } from 'src/ui/form';
import { InternationalIdentifierType } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { useLocationState } from 'src/utils/hooks';
import { BillingType } from 'src/utils/ServerTypes';
import { validateBillingDetails } from '../billing-details/utils';
import { bankAccountRequiredFields, BankDetailsType, getIsComingFromPayBillFlow, requiredFields } from '../utils';
import { InternationalWizardState, useInternationalWizard } from './useInternationalWizard';

export type BillingDetailsModel = Pick<
  InternationalWizardState,
  'bankCountry' | 'iban' | 'swift' | 'accountNumber' | 'bankName'
> & {
  amount: string;
};

type ReturnValue = [
  ModelView<BillingDetailsModel>,
  { isBankDetailsLoading: boolean; submit: () => Promise<void>; isValidBankDetails: boolean },
  Record<string, string>
];

export const useBillingDetailsForm = (vendorId, countriesByCode: Record<string, CountryType>): ReturnValue => {
  const [redirectUrl] = useLocationState<string>('redirectUrl');
  const [batchPaymentItemId] = useLocationState<string>('batchPaymentItemId');
  const [billId] = useLocationState<string>('billId');
  const [exitUrl] = useLocationState<string>('exitUrl');
  const [origin] = useLocationState<string>('origin');
  const orgId = useOrgId();
  const history = useHistory();
  const payment = useSelector(getPayment);
  const batchPaymentItem = useSelector(regularBatchPaymentsStore.selectors.byId(batchPaymentItemId));
  const amount = getIsComingFromPayBillFlow(origin!) ? payment?.amount : batchPaymentItem?.payment?.amount?.toString();

  const { wizardState, setWizardState } = useInternationalWizard();
  const [countryCode, setCountryCode] = useState<string | null>(wizardState.bankCountry || null);
  const [bankDetails, setBankDetails] = useState<BankDetailsType | null | undefined>(wizardState.details);
  const [validationErrors, setValidationErrors] = useState({});

  const { onApiCall: getBankDetails, loading: isBankDetailsLoading } = useApi({ api: bankDetailsApi });

  const billingDetailsModel = useMemo<BillingDetailsModel>(() => {
    const isSwift = countriesByCode[wizardState.bankCountry || '']?.billingType === BillingType.BIC_SWIFT;

    return {
      bankCountry: wizardState.bankCountry || null,
      ...(isSwift ? { swift: wizardState.swift || '' } : { iban: wizardState.iban || '' }),
      accountNumber: wizardState.accountNumber || '',
      bankName: wizardState.bankName || '',
      countryRiskLevel: wizardState.countryRiskLevel || undefined,
      amount,
    };
  }, [wizardState, amount]);

  const onChangeBankDetails = useCallback(
    debounce(async (key, value, modelState) => {
      if (
        [InternationalIdentifierType.IBAN, InternationalIdentifierType.SWIFT].includes(
          key as InternationalIdentifierType
        )
      ) {
        const type =
          key === InternationalIdentifierType.IBAN
            ? InternationalIdentifierType.IBAN
            : InternationalIdentifierType.BIC_SWIFT;
        try {
          const { details } = await getBankDetails({
            orgId,
            vendorId,
            type,
            value,
          });
          setBankDetails(details);
          setWizardState({
            ...modelState,
            details,
            accountNumber: details?.account_number,
            bankName: details?.bank_name,
          });
          clearValidationErrors();
        } catch (e: any) {
          capture(e);
          setBankDetails(null);
          setValidationErrors({ [key]: `vendors.deliveryMethods.international.billing.validation.invalid_${key}` });
        }
      }
    }, config.debounceDelay),
    []
  );
  const getValidationErrorForField = validateBillingDetails(
    { ...requiredFields, ...bankAccountRequiredFields },
    countryCode
  );
  const [model, { submit }] = useForm<BillingDetailsModel>(billingDetailsModel, {
    submit: async () => {
      fireInternationalAnalyticsOnSubmit();
      history.push(
        generatePath(globalLocations.vendor.international.vendorDetails, {
          orgId,
          id: vendorId,
        }),
        {
          redirectUrl,
          exitUrl,
          billId,
          origin,
        }
      );
    },
    onChange: ({ key, value, modelState }) => {
      if (key === 'bankCountry') {
        setCountryCode(value);
        setBankDetails(null);
        setWizardState({
          ...modelState,
          bankCountry: modelState.bankCountry,
          accountNumber: '',
          bankName: '',
          swift: '',
          iban: '',
        });
        clearValidationErrors();
      }

      if (Object.keys(bankAccountRequiredFields).includes(key)) {
        setWizardState({
          ...modelState,
          details: bankDetails,
        });
      }

      if (
        (key === InternationalIdentifierType.SWIFT ||
          (key === InternationalIdentifierType.IBAN && countryCode && value.startsWith(countryCode))) &&
        !getValidationErrorForField(key, value, modelState)
      ) {
        onChangeBankDetails(key, value, modelState);
      }

      return modelState;
    },
    validator: getValidationErrorForField,
    validateOnChange: false,
  });

  const clearValidationErrors = () => {
    setValidationErrors({});
    model.setValidationErrors({});
  };

  return [
    model,
    {
      isBankDetailsLoading,
      submit,
      isValidBankDetails: !isNil(bankDetails),
    },
    validationErrors,
  ];
};
