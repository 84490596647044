import styled from 'styled-components';

export const InputContainer = styled.form`
  .input-wrapper {
    border: 0;
  }
  label {
    color: #6a7281;
  }
  [role='group'] {
    margin-bottom: 20px;
  }
  input {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    height: 48px;
    outline: transparent solid 2px;
    outline-offset: 2px;
    position: relative;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 200ms;
    color: ${(props) => props.theme.text.color.main};
    padding-inline-start: 0;
    padding-inline-end: 0;
    background: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #d9dee8;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 0;
    padding-bottom: 0;

    &:focus {
      border: 1px solid #7849ff;
    }
  }
  .chakra-form__error-message,
  .chakra-form__helper-text {
    font-size: 12px;
    line-height: 16px;
  }
  .chakra-form__helper-text {
    color: #666a79;
  }
  [data-testid='password-score-notice'] {
    margin-top: -20px;
    margin-bottom: 24px;
    div {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  [data-testid='input-password-notices'] {
    a {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;
