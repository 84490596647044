import isString from 'lodash/isString';
import { MouseEvent, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { analytics } from 'src/services/analytics';
import {
  Backdrop,
  ButtonContainer,
  CloseButtonWrapper,
  ContentContainer,
  DescriptionContainer,
  DockContainer,
  FooterContainer,
  Illustration,
  ModalContainer,
  ModalContentContainer,
  ModalWrapper,
  SubTitleContainer,
  TitleContainer,
} from './ModalMessage.styles';

export interface ModalMessageProps {
  id?: string;
  image?: string | ReactNode;
  icon?: IconNames;
  titleComponent?: ReactNode;
  contentComponent?: ReactNode;
  buttonComponent?: ReactNode;
  buttonLabel?: string;
  analyticsProperties?: Record<string, any>;
  onButtonClick?: () => any;
  secondaryButtonComponent?: ReactNode;
  secondaryButtonLabel?: string;
  onSecondaryButtonClick?: () => void;
  onCloseClick?: (event: MouseEvent) => void;
  isLoading?: boolean;
  alignLeft?: boolean;
  descriptionComponent?: ReactNode;
  footerSubTitle?: ReactNode;
  contentSubTitle?: ReactNode;
  footerComponent?: ReactNode;
  isMobileFullSize?: boolean;
  largeSize?: boolean;
  dockedFooter?: boolean;
  className?: string;
  fullWidthImage?: boolean;
}

export const ModalMessage = ({
  id,
  image = '',
  titleComponent,
  contentComponent,
  buttonLabel,
  analyticsProperties,
  isMobileFullSize = true,
  onButtonClick,
  onCloseClick,
  isLoading,
  descriptionComponent,
  footerSubTitle,
  contentSubTitle = '',
  footerComponent,
  buttonComponent,
  alignLeft,
  secondaryButtonComponent,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  largeSize,
  icon,
  dockedFooter,
  className,
  fullWidthImage,
}: ModalMessageProps) => {
  const isSecondaryButtonExist = secondaryButtonComponent || (secondaryButtonLabel && onSecondaryButtonClick);

  const doOnClose = (event) => {
    onCloseClick && onCloseClick(event);
    analytics.trackAction(`modal-close${id ? `-${id}` : ''}`);
  };

  return ReactDOM.createPortal(
    <ModalWrapper data-testid={id} className={className}>
      <Backdrop onClick={doOnClose} />
      <ModalContainer largeSize={largeSize} isMobileFullSize={isMobileFullSize} data-testid="modal-message-container">
        <ModalContentContainer fullWidthImage={fullWidthImage}>
          {onCloseClick && (
            <CloseButtonWrapper onClick={doOnClose} data-testid="modal-close">
              <Icon name={IconNames.close} size={IconSize.lg} />
            </CloseButtonWrapper>
          )}

          {image && (isString(image) ? <Illustration src={image} /> : image)}

          {icon && <Icon name={icon} size={IconSize['2xl']} color="yellow.500" mb={4} alignSelf="center" />}

          {titleComponent && <TitleContainer alignLeft={alignLeft}>{titleComponent}</TitleContainer>}

          {descriptionComponent && <DescriptionContainer>{descriptionComponent}</DescriptionContainer>}

          {contentSubTitle && <SubTitleContainer>{contentSubTitle}</SubTitleContainer>}

          {contentComponent && <ContentContainer fullWidthImage={fullWidthImage}>{contentComponent}</ContentContainer>}

          {footerSubTitle && <SubTitleContainer>{footerSubTitle}</SubTitleContainer>}

          {!dockedFooter && footerComponent && <FooterContainer>{footerComponent}</FooterContainer>}

          {buttonComponent ||
            (buttonLabel && onButtonClick && (
              <ButtonContainer isSecondaryButtonExist={isSecondaryButtonExist}>
                <Button
                  onClick={onButtonClick}
                  label={buttonLabel}
                  analyticsProperties={analyticsProperties}
                  isLoading={isLoading}
                  isFullWidth
                />
              </ButtonContainer>
            ))}

          {secondaryButtonComponent ||
            (secondaryButtonLabel && onSecondaryButtonClick && (
              <ButtonContainer>
                <Button
                  onClick={onSecondaryButtonClick}
                  label={secondaryButtonLabel}
                  variant={ButtonVariants.secondary}
                  analyticsProperties={analyticsProperties}
                  isLoading={isLoading}
                  isFullWidth
                />
              </ButtonContainer>
            ))}
        </ModalContentContainer>
        {dockedFooter && footerComponent && (
          <DockContainer isMobileFullSize={isMobileFullSize}>{footerComponent}</DockContainer>
        )}
      </ModalContainer>
    </ModalWrapper>,
    document.body
  );
};
