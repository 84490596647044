import React from 'react';
import { useSelector } from 'react-redux';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { accountingSoftwareDisplayName } from 'src/pages/settings/components/accounting-software/consts';
import { AccountingSoftware } from 'src/utils/consts';
import {
  AccountingPlatformLogo,
  CardHeaderWrapper,
  InitialViewDescriptionWrapper,
} from './AccountingSoftwareCardElements';

type InitialViewProps = {
  onLink: () => void;
  accountingSoftwareType: AccountingSoftware;
  logo: string;
  title?: string;
  subtitle?: string;
  connecting?: boolean;
};

export const InitialView = ({
  onLink,
  accountingSoftwareType,
  connecting,
  logo,
  title,
  subtitle,
}: InitialViewProps) => {
  const { isDesktop } = useBreak();
  const accountPlatform = useSelector(accountingPlatformsStore.selectors.getByName(accountingSoftwareType));
  const accountPayablesDisabled = !accountPlatform?.accountPayablesEnabled;
  const shouldShowReceivableTag: boolean = isDesktop && accountPayablesDisabled;
  const cardTitle = title || 'settings.newAccountingSoftware.SSO.cardTitle';
  const cardSubtitle =
    subtitle ||
    (accountPayablesDisabled
      ? 'settings.newAccountingSoftware.SSO.contentAccountReceiveable'
      : 'settings.newAccountingSoftware.SSO.content');
  const { displayName, displayShortName } = accountingSoftwareDisplayName[accountingSoftwareType];

  return (
    <MICard>
      <MICardForm testId="connect-container">
        <Flex justify="space-between">
          <AccountingPlatformLogo src={logo} alt="logo" />
          {shouldShowReceivableTag && (
            <Box>
              <Badge
                variant={BadgeVariants.OUTLINED}
                color={BadgeColors.SUCCESS}
                label="settings.newAccountingSoftware.onlyReceivable"
              />
            </Box>
          )}
        </Flex>
        <CardHeaderWrapper>
          <MICardTitle label={cardTitle} values={{ accountingSoftwareType: displayName }} />
        </CardHeaderWrapper>
        <InitialViewDescriptionWrapper>
          <MIFormattedText label={cardSubtitle} values={{ accountingSoftwareType: displayShortName }} />
        </InitialViewDescriptionWrapper>
        <Button
          size={ButtonSizes.lg}
          onClick={onLink}
          label="settings.newAccountingSoftware.SSO.connect"
          variant={ButtonVariants.tertiary}
          isLoading={connecting}
          testId="connect-to-accounting-software"
        />
      </MICardForm>
    </MICard>
  );
};
