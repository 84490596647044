import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import DeliveryMethodListItem from 'src/pages/bill/pay/components/DeliveryMethodListItem';
import { PayBillProps, withPayBillData } from 'src/pages/bill/pay/hoc/withPayBillData';
import { deliveryMethodsLocations } from 'src/pages/vendor/delivery-methods/locations';
import { getDeliveryMethodByType } from 'src/pages/vendor/records';
import { selectDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { DeliveryMethodOrigin, DeliveryType } from 'src/utils/consts';
import { billLocations } from '../locations';

const eventPage = 'returned-check-recovery';

const PlainReturnedCheckRecoveryFlowPage = ({ onPrev, goExit, onNext }: PayBillProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const { isFinanced } = payment;
  const { companyName = '' } = bill.vendor ?? {};

  const achDM = getDeliveryMethodByType(DeliveryType.ACH, bill.vendor?.deliveryMethods || []);
  const checkDM = getDeliveryMethodByType(DeliveryType.CHECK, bill.vendor?.deliveryMethods || []);
  const virtualDM = getDeliveryMethodByType(DeliveryType.VIRTUAL, bill.vendor?.deliveryMethods || []);
  const shouldShowVirtualDMOption = !achDM.id && !isFinanced;

  const analyticsProps = {
    paymentId: payment?.id,
    partialBillId: getBillPaymentIndex(bill),
    originDeliveryId: payment?.deliveryMethod?.id,
    originDeliveryType: payment?.deliveryMethod?.deliveryType,
  };

  const selectDeliveryMethod = async (deliveryMethod) => {
    await dispatch(selectDeliveryMethodAction(deliveryMethod));
    onNext();
  };

  const navigateOnSelectDeliveryMethod = (url: string) => {
    const redirectUrl = generatePath(billLocations.pay.edit.confirm, { orgId, billId: bill.id, paymentId: payment.id });
    const exitUrl = generatePath(billLocations.pay.edit.returnedCheckRecovery, {
      orgId,
      billId: bill.id,
      paymentId: payment.id,
    });

    history.push({
      pathname: generatePath(url, {
        orgId,
        id: bill.vendorId ?? undefined,
        deliveryMethodId: checkDM?.id || undefined,
      }),
      state: {
        origin: DeliveryMethodOrigin.PAY_BILL,
        redirectUrl,
        exitUrl,
        check: checkDM.paperCheck ?? undefined,
      },
    });
  };

  const onAchSelected = () => {
    analytics.track(eventPage, 'delivery-change-from-check-to-ach', {
      newDeliveryType: DeliveryType.ACH,
      newDeliveryId: achDM?.id || null,
      ...analyticsProps,
    });

    if (achDM.id) {
      selectDeliveryMethod(achDM);
    } else {
      navigateOnSelectDeliveryMethod(deliveryMethodsLocations.ach.createFromReturnedCheckRecoveryFlow);
    }
  };

  const onCheckSelected = () => {
    analytics.track(eventPage, 'delivery-change-from-check-to-check', {
      newDeliveryType: DeliveryType.CHECK,
      newDeliveryId: checkDM?.id || null,
      ...analyticsProps,
    });
    navigateOnSelectDeliveryMethod(deliveryMethodsLocations.check.edit);
  };

  const onUnilateralSelected = () => {
    analytics.track(eventPage, 'delivery-change-from-check-to-unilateral', {
      newDeliveryType: DeliveryType.VIRTUAL,
      newDeliveryId: virtualDM?.id || null,
      ...analyticsProps,
    });
    navigateOnSelectDeliveryMethod(deliveryMethodsLocations.virtual.create);
  };

  if (!companyName) {
    return <AreaLoader />;
  }

  return (
    <StepLayoutPage
      relativeStep={1 / 2}
      title="bills.pay.resendReturnedCheck.title"
      testId="resend-returned-check"
      titleValues={{ vendor: companyName }}
      goExit={goExit}
      onPrev={onPrev}
      isHideActions
    >
      <DeliveryMethodListItem
        key={achDM.deliveryType}
        deliveryMethod={achDM}
        label="deliveryMethods.resend.ach.label"
        companyName={companyName}
        onItemClick={onAchSelected}
        isRecommended={!isFinanced}
        isSelected={false}
      />
      {shouldShowVirtualDMOption && (
        <DeliveryMethodListItem
          key={virtualDM.deliveryType}
          deliveryMethod={virtualDM}
          label="deliveryMethods.resend.unilateral.achLabel"
          companyName={companyName}
          onItemClick={onUnilateralSelected}
          isSelected={false}
        />
      )}
      {!isFinanced && (
        <DeliveryMethodListItem
          key={checkDM.deliveryType}
          deliveryMethod={checkDM}
          label="deliveryMethods.resend.check.label"
          companyName={companyName}
          onItemClick={onCheckSelected}
          isSelected={false}
        />
      )}
    </StepLayoutPage>
  );
};

export const ReturnedCheckRecoveryFlowPage = withPayBillData()(PlainReturnedCheckRecoveryFlowPage);
