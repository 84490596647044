import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { IconNames } from 'src/core/ds/icon';
import { ButtonVariant } from 'src/utils/consts';
import { AddressType } from 'src/utils/types';
import { AddressRowCard } from './AddressRowCard';
import { EditCheckAddressModalButtonRow } from './EditCheckAddressModalButtonRow';

type Props = {
  icon?: IconNames;
  onCloseModal: () => void;
  loading?: boolean;
  printName?: string;
  address?: AddressType;
  subtitle: string;
  PopUpFlow: {
    secondaryButtonLabel: string;
    onSuccessModal: () => void;
    secondaryButtonVariant: ButtonVariant;
    invalidSubtitle?: string;
  };
};

export const EditCheckAddressModal = ({
  icon,
  onCloseModal,
  loading,
  printName,
  address,
  subtitle,
  PopUpFlow,
}: Props) => (
  <ModalMessage
    icon={icon}
    titleComponent={<MIFormattedText label="manualAddress.invalidTitle" />}
    contentSubTitle={<MIFormattedText label={PopUpFlow.invalidSubtitle || subtitle} />}
    contentComponent={address && printName && <AddressRowCard printName={printName} address={address} />}
    buttonComponent={
      <EditCheckAddressModalButtonRow
        buttonLabel="manualAddress.edit"
        secondaryButtonLabel={PopUpFlow.secondaryButtonLabel}
        secondaryButtonVariant={PopUpFlow.secondaryButtonVariant}
        onButtonClick={onCloseModal}
        onSecondaryButtonClick={PopUpFlow.onSuccessModal}
        isLoading={loading}
      />
    }
    onCloseClick={onCloseModal}
    isLoading={loading}
  />
);
