import contactCreateLocations from 'src/pages/contacts/create/locations';

export const contactsLocations = {
  base: ['/orgs/:orgId/contacts', '/orgs/:orgId/contacts/vendors', '/orgs/:orgId/contacts/customers'],
  index: '/orgs/:orgId/contacts/:type',
  dashboard: '/orgs/:orgId/contacts',
  vendors: '/orgs/:orgId/contacts/vendors',
  customers: '/orgs/:orgId/contacts/customers',
  view: '/orgs/:orgId/contacts/:type',
  create: contactCreateLocations,
};
