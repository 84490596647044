import * as React from 'react';
import { useIntl } from 'react-intl';
import { InstallmentListItem } from 'src/pay/components/InstallmentListItem';
import { convertNumberToCurrency } from 'src/utils/currency-utils';
import { getCurrentScheduledInstallment } from 'src/utils/financing';
import { PayItemPaymentType } from '../types';

export const ScheduledInstallmentListItem: React.FC<{
  payment: PayItemPaymentType;
  disabled: boolean;
  onClick: () => void;
  vendorName: string;
  search: string;
  isSelected: boolean;
}> = ({ disabled, onClick, vendorName, search, payment, isSelected }) => {
  const allInstallments = payment.financing?.installments;
  const scheduledInstallment = getCurrentScheduledInstallment(allInstallments || []);
  const { formatMessage } = useIntl();
  const paymentAmount = convertNumberToCurrency(payment.amount!);

  if (!scheduledInstallment || !allInstallments) return null;

  return (
    <InstallmentListItem
      totalNumberOfInstallments={allInstallments.length}
      onClick={onClick}
      isSelected={isSelected}
      search={search}
      vendorName={vendorName}
      disabled={disabled}
      currentInstallmentNumber={scheduledInstallment.installmentNumber}
      installmentAmount={scheduledInstallment.amount}
      scheduledDate={scheduledInstallment.scheduledDate}
      deliveryStatusFormattedMessage={formatMessage(
        { id: 'list.group.installment.status.sent' },
        { vendorName, paymentAmount }
      )}
    />
  );
};
