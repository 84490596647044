import { useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import { CellProps } from 'react-table';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { HStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import { useSearchValue } from 'src/hoc/withListSearchBarContext';
import { ReactComponent as MelioUser } from 'src/images/get-pro/melio-user.svg';

export const CustomerCell = ({ cell: { value } }: CellProps<any>) => {
  const search = useSearchValue();
  const { name, isMelioUser } = value;

  return useMemo(
    () => (
      <HStack spacing={2}>
        <Box isTruncated>
          <Highlighter searchWords={[search]} textToHighlight={name} />
        </Box>
        {isMelioUser && (
          <Tooltip placement="top" label={<MIFormattedText label="getPro.tooltip.melioUser" />}>
            <Box cursor="pointer">
              <MelioUser />
            </Box>
          </Tooltip>
        )}
      </HStack>
    ),
    [isMelioUser, name, search]
  );
};
