import first from 'lodash/first';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useExtraAnalyticsProps } from 'src/analytics/useExtraAnalyticsProps';
import { BlockedForPaymentDialog } from 'src/components/common/BlockedForPaymentDialog';
import { OnInputChange, Option } from 'src/components/common/MISingleSelect';
import { StepHeader } from 'src/components/layout/StepHeader';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { useGetFileUrlsByFileId } from 'src/pages/bill/hooks/useGetFileUrlsByFileId';
import { billLocations } from 'src/pages/bill/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { AddFundingSourceWizardOrigin, BillFrequency } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { melioClose } from 'src/utils/external-events';
import { useLocationState, useQueryState } from 'src/utils/hooks';
import { BillType, FieldType } from 'src/utils/types';
import { NewBillPage } from './components/NewBillPage';
import { withNewBillData } from './hoc/withNewBillData';

type Props = {
  files: number[];
  bill: BillType;
  validationErrors: Record<string, any>;
  vendors: Array<Option>;
  filteredVendors?: Array<Option>;
  onVendorsInputChange?: OnInputChange;
  isUploading: boolean;
  isRecurring: boolean;
  onFieldChange: (field: FieldType) => void;
  onChange: (field: FieldType) => void;
  onChangeAttachment: (file: File, loadBillFromAttachment?: boolean) => void;
  onUploadRemove: () => void;
  goExit: () => void;
  goExitManually: () => void;
  onSubmitBill: (cb: (isPassedValidation: boolean) => void, shouldReturnToBillsDashboard: boolean) => void;
  handleUploadCancel: () => void;
  vendorAccountIdentifier?: string;
  handleRetry: () => void;
  occurrences: string;
  frequency: BillFrequency;
  onDeleteAttachment: () => void;
  fileName: string;
  isUploadError: boolean;
  isVendorBlockedForPayment: boolean;
};

const PlainNewBillPageContainer = ({
  // All are withNewBillData props.
  onSubmitBill,
  goExit,
  bill,
  handleUploadCancel,
  onChangeAttachment,
  onFieldChange,
  onUploadRemove,
  goExitManually,
  vendorAccountIdentifier,
  isUploading,
  files,
  validationErrors,
  isRecurring,
  onChange,
  occurrences,
  frequency,
  onDeleteAttachment,
  fileName,
  isUploadError,
  handleRetry,
  onVendorsInputChange,
  filteredVendors,
  isVendorBlockedForPayment,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const site = useSiteContext();
  const { navigate } = useNavigator();
  const [manuallyState] = useLocationState('manually', false);
  const [manuallyQuery] = useQueryState('manually');
  const manually = manuallyState || manuallyQuery;
  const orgId = useSelector(getOrgId);
  const fileId = first(files)?.toString();
  const { fileStorageUrl, filePreviewUrls } = useGetFileUrlsByFileId(fileId, bill.id);
  useExtraAnalyticsProps('new-bill', { origin: AddFundingSourceWizardOrigin.PAY_BILL });

  const onCreateBill = async (shouldReturnToBillsDashboard) => {
    setIsLoading(true);
    try {
      await onSubmitBill(() => {
        setIsLoading(false);
      }, shouldReturnToBillsDashboard);
    } catch (error: any) {
      setIsLoading(false);
      capture(error, 'bill-create-error');
    }
  };

  const goExitHandler = () => {
    if (site?.embeddedMode) {
      melioClose();
    } else {
      goExit();
    }
  };

  const onCloseBlockPaymentDialog = () => {
    navigate(generatePath(billLocations.view, { orgId, id: bill.id }));
  };

  return (
    <>
      {isVendorBlockedForPayment && <BlockedForPaymentDialog onCancelAction={onCloseBlockPaymentDialog} />}
      <Box className="new-bill-page-controls" bgColor="grey.200" p={5}>
        <StepHeader onPrev={goExitManually} goExit={manually ? goExitManually : goExit} />
      </Box>

      <Flex h={{ md: 'calc(100% - 6.4rem)', base: 'unset' }} bgColor="grey.200">
        <NewBillPage
          occurrences={occurrences}
          frequency={frequency}
          filteredVendors={filteredVendors}
          onVendorsInputChange={onVendorsInputChange}
          vendorAccountIdentifier={vendorAccountIdentifier}
          orgId={orgId}
          handleUploadCancel={handleUploadCancel}
          onUploadRemove={onUploadRemove}
          onChangeAttachment={onChangeAttachment}
          isUploading={isUploading}
          onFieldChange={onFieldChange}
          onChange={onChange}
          isRecurring={isRecurring}
          fileStorageUrl={fileStorageUrl}
          filePreviewUrls={filePreviewUrls}
          bill={bill}
          validationErrors={validationErrors}
          goExit={goExitHandler}
          goExitManually={goExitManually}
          onSubmitBill={onCreateBill}
          isProcessing={isLoading}
          manually={!!manually}
          onDeleteAttachment={onDeleteAttachment}
          fileName={fileName}
          isUploadError={isUploadError}
          handleRetry={handleRetry}
        />
      </Flex>
    </>
  );
};

export const NewBillPageContainer = withNewBillData()(PlainNewBillPageContainer);
