import get from 'lodash/get';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { TabapayMigrationModal } from 'src/components/funding-source/tabapay/components/TabapayMigrationModal';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { useApi } from 'src/hoc/useApi';
import { useNewTabapayUrl } from 'src/hooks/useNewTabapayUrl';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { ContentWrapper, Footer, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import { ShiftToDebitState } from 'src/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { DialogType, DialogVariants } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { shiftToDebitLocations } from '../locations';

type Props = {
  token: string;
  state: ShiftToDebitState;
  parentFlow?: string;
};

const AddDeliveryMethodPage = ({ token, state, parentFlow }: Props) => {
  const history = useHistory();
  const [errorCode, setErrorCode] = useState('');
  const [cardData, setCardData] = useState({});
  const { payment, organization, filesUrls, isPaymentLoading, fee } = state;
  const { onApiCall: checkCard, result, loading: isChecking, error } = useApi<
    [{ token: string; cardToken: string; cardBin: string }],
    any
  >({
    api: deliveryMethodsApi.checkP2DCardWithToken,
  });
  const transactionFee = fee?.amount;
  const isCap = transactionFee === fee?.cap;
  const iframeResponse = 'iframe-response';
  const iframeErrorResponse = 'iframe-error-response';
  const iframeSuccessResponse = 'iframe-success-response';
  const tabaPay = 'taba-pay';
  const { iframeSrc, shouldSkipReceivedMessage } = useNewTabapayUrl();
  const pfReceivedMessage = useCallback(
    async (event) => {
      try {
        // If event origin is not from TabaPay's iframe, skip this part
        if (shouldSkipReceivedMessage(event)) return;

        if (event.data === 'Close') setErrorCode(iframeErrorResponse);

        analytics.track(tabaPay, iframeResponse);

        if (event.data.slice(0, 7) === 'Error: ') {
          analytics.track(tabaPay, iframeErrorResponse);
          setErrorCode(iframeErrorResponse);
        }

        const [digitsAndBin, expiration, cardToken] = event.data.split('|');
        analytics.track(tabaPay, `${iframeResponse}-data`);

        if (!token) setErrorCode(iframeErrorResponse);

        const digits = (digitsAndBin || '0000').slice(-4);
        const cardBin = digitsAndBin.length === 10 ? digitsAndBin.substring(0, 6) : null;
        analytics.track('shift-to-debit', 'check-card-details', { vaulting: tabaPay });
        setCardData({
          card: {
            digits,
            cardToken,
            cardBin,
            expiration,
          },
        });
        await checkCard({
          token,
          cardToken,
          cardBin,
        });
      } catch (err: any) {
        analytics.track(tabaPay, iframeErrorResponse);
        capture(err, {
          message: `${tabaPay}${iframeErrorResponse}`,
        });
        setErrorCode(iframeErrorResponse);
        setCardData({});
      }
    },
    [checkCard, token]
  );

  const loadIframeError = () => {
    analytics.track(tabaPay, iframeErrorResponse);
    setErrorCode(iframeErrorResponse);
    const error = new Error(`${iframeErrorResponse}-test`);
    capture(error, `${tabaPay}${iframeErrorResponse}`);
  };

  const clearError = () => setErrorCode('');

  useEffect(() => {
    window.addEventListener('message', pfReceivedMessage, false);

    return () => window.removeEventListener('message', pfReceivedMessage, false);
  }, [pfReceivedMessage]);

  useEffect(() => {
    if (result) {
      analytics.track(tabaPay, iframeSuccessResponse);
      history.push(generatePath(shiftToDebitLocations.addAddress, { token }), cardData);
    }
  }, [result, cardData, token, history]);

  useEffect(() => {
    if (error) {
      setErrorCode(get(error, 'code') || get(error, 'response.status'));
    }
  }, [error]);

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  const feeDescriptionLabel = isCap ? 'descriptionNoFeeValue' : 'description';

  const handleOnPrevClick = () => {
    history.goBack();
  };

  const analyticsProps = parentFlow ? { flow: `${parentFlow}: Push to Debit` } : {};

  return (
    <OutsideLayout hideLogin>
      <Header
        payment={payment}
        organization={organization}
        filesUrls={filesUrls}
        showFullHeader
        description={`vendors.deliveryMethods.shiftToDebit.${feeDescriptionLabel}`}
        subTitle="vendors.deliveryMethods.shiftToDebit.subtitle"
        descriptionValues={{
          fee: <MIFormattedCurrency value={transactionFee?.toString()} />,
        }}
        onPrev={parentFlow ? handleOnPrevClick : undefined}
        analyticsProps={analyticsProps}
      />
      <ContentWrapper
        title="vendors.deliveryMethods.shiftToDebit.title"
        subTitle="vendors.deliveryMethods.shiftToDebit.contentSubtitle"
      >
        <TabapayMigrationModal />
        {errorCode && (
          <Dialog
            type={DialogType.ALERT}
            variant={DialogVariants.ERROR}
            title={
              errorCode === iframeErrorResponse
                ? 'vendors.deliveryMethods.shiftToDebit.errorTitle'
                : 'onboarding.fundingSources.card.notSupportedTitle'
            }
            subtitle={
              errorCode === iframeErrorResponse
                ? 'vendors.deliveryMethods.shiftToDebit.errorCard'
                : 'vendors.deliveryMethods.shiftToDebit.notSupportedSubtitle'
            }
            okButtonText="dialogs.buttons.close"
            cancelButtonText="dialogs.buttons.close"
            onOkAction={clearError}
            onCancelAction={clearError}
          />
        )}
        <IframeContainer>
          {isChecking && (
            <LoaderContainer>
              <AreaLoader />
            </LoaderContainer>
          )}
          <TabapayIframe
            isLoading={isChecking}
            title="taba pay SSO"
            id="sso"
            onError={loadIframeError}
            src={iframeSrc}
            frameBorder="0"
            scrolling="no"
          />
        </IframeContainer>
        <Footer companyName={organization?.companyName} />
      </ContentWrapper>
    </OutsideLayout>
  );
};

const IframeContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  margin-bottom: -1rem;
`;

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
`;

const TabapayIframe = styled.iframe<{ isLoading?: boolean }>`
  opacity: ${(props) => (props.isLoading ? '0.2' : '1')};
  width: 65rem;
  height: 37rem;
  margin-left: -10rem;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    margin-left: 0rem;
    height: 40rem;
  }
`;

export default AddDeliveryMethodPage;
