import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { ModelViewField, useForm } from 'src/ui/form';

const useAddMemoModal = (
  onClose,
  itemId
): {
  memoModel: ModelViewField<string>;
  submit: () => void;
} => {
  const actions = useStoreActions(regularBatchPaymentsStore);

  const paymentMemo = useSelector(regularBatchPaymentsStore.selectors.getMemo(itemId));

  const model = useMemo(
    () => ({
      memo: paymentMemo,
    }),
    [paymentMemo]
  );

  const [{ memo }, { submit }] = useForm(model, {
    submit: async ({ memo }) => {
      if (!isEmpty(memo)) {
        await actions.updatePayments.setMemo({ memo, itemId });
      }

      onClose();
    },
  });

  return {
    memoModel: memo,
    submit,
  };
};

export default useAddMemoModal;
