import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';

const createApiCallReducer = (actionName: string) => ({
  [ON_REQUEST]: (state) => {
    state[actionName].loading = true;
    state[actionName].error = null;
    state[actionName].message = null;
    state[actionName].code = null;
  },
  [ON_SUCCESS]: (state, action) => {
    const { message, code, ...data } = action.payload;
    state[actionName].loading = false;
    state[actionName].error = null;
    state[actionName].data = data;
    state[actionName].message = message;
    state[actionName].code = code;
  },
  [ON_FAILURE]: (state, action) => {
    state[actionName].loading = false;
    state[actionName].error = action.error || null;
  },
});

type createSimpleApiCallSliceOptions<P, R> = {
  api: (payload: P) => R;
  storeName: string;
  actionName: string;
  initialDataState: any;
  selectors?: { [key: string]: (state: any, props?: any) => any };
};

export const createSimpleApiCallSlice = <P, R extends Promise<unknown>>({
  api,
  initialDataState,
  actionName,
  storeName,
  selectors,
}: createSimpleApiCallSliceOptions<P, R>) => {
  const initialState = { [actionName]: { loading: false, error: null, data: initialDataState } };

  return createApiCallSlice({
    initialState,
    name: generateSliceName(storeName, actionName),
    api,
    reducers: createApiCallReducer(actionName),
    selectors: {
      status: (state) => state[storeName][actionName],
      ...selectors,
    },
  });
};
