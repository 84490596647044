export const containerStyle = {
  borderRadius: 8,
  pt: 5,
  px: 5,
  pb: 3,
  backgroundColor: 'white',
  boxShadow: '0px 5px 15px rgba(33, 33, 36, 0.1)',
  overflow: 'auto',
  maxHeight: '40rem',
  maxWidth: '45rem',
  width: '100%',
  boxSizing: 'border-box',
};
