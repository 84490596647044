import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import { InvoiceType } from 'src/modules/invoices/types';
import { Card, Header } from 'src/pages/get-paid/list/components/batchActions/BatchElements';
import BatchInvoiceCard from 'src/pages/get-paid/list/components/batchActions/BatchInvoiceCard';
import DeleteFailedInvoicesButton from 'src/pages/get-paid/list/components/batchActions/DeleteFailedInvoicesButton';
import LeftHeaderText from 'src/pages/get-paid/list/components/LeftHeaderText';
import { getTotalAmount } from 'src/pages/get-paid/list/components/utils';

type Props = {
  invoices: Array<InvoiceType>;
};

const FailedBatchView = ({ invoices }: Props) => {
  const numOfInvoicesSelected = invoices.length;
  const selectedInvoicesTotalAmount = getTotalAmount(invoices);

  return (
    <SingleViewLoadingContainer>
      <Header>
        <LeftHeaderText
          numOfInvoices={numOfInvoicesSelected}
          totalAmount={selectedInvoicesTotalAmount}
          headerLabel="getPaid.view.batch.title.failed"
          descriptionLabel="getPaid.view.batch.description"
        />
        <DeleteFailedInvoicesButton />
      </Header>
      <Card mode="mainSingleScreen">
        {invoices.map((invoice) => (
          <BatchInvoiceCard invoice={invoice} key={invoice.id} />
        ))}
      </Card>
    </SingleViewLoadingContainer>
  );
};

export default FailedBatchView;
