import { getMaxLength } from '@melio/sizzers-js-common';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { renderDayContents } from 'src/components/common/datepicker/PaymentDatePicker';
import { MIForm } from 'src/components/common/MIForm';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { getSelectOptionObject, OnInputChange, Option } from 'src/components/common/MISingleSelect';
import { BillersVisibilityTooltip } from 'src/components/education/BillersVisibilityTooltip';
import { reviewVendorDetailsNotification } from 'src/components/msn/VerifiedVendor/consts';
import { useBusinessDetailsBoxInForm } from 'src/components/msn/VerifiedVendor/hooks/useBusinessDetailsBoxInForm';
import { VerifiedVendorDetails } from 'src/components/msn/VerifiedVendor/VerifiedVendorDetails';
import Box from 'src/core/ds/box/Box';
import { useModal } from 'src/helpers/react/useModal';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { AccountCategoryOption, AccountingSoftwareAccountCategoryOption } from 'src/pages/shared';
import { DuplicateVendorNameModal } from 'src/pages/vendor-directory/DuplicateVendorNameModal/DuplicateVendorNameModal';
import {
  getSelectOverrideStyle,
  isValidNewOption,
  onVendorChange,
} from 'src/pages/vendor-directory/select-vendor/utils';
import { VendorOptionLabel } from 'src/pages/vendor-directory/select-vendor/VendorOptionLabel';
import { OptionGroupLabel } from 'src/pages/vendor-directory/select-vendor/VendorsGroupLabel';
import { getOrgId } from 'src/redux/user/selectors';
import { convertToDisplayAddress } from 'src/utils/address';
import { isBillAmountRequiresInvoiceFile } from 'src/utils/bills';
import {
  BillFrequency,
  BillPageType,
  DirectoryType,
  FORM_AUTOCOMPLETE,
  FormControlType,
  FormType,
  ScreenMode,
  SelectVendorType,
  SingleSelectFlavor,
} from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { DateFormats } from 'src/utils/date-fns';
import { isBusinessDay } from 'src/utils/dates';
import { EditableBillType, FieldType, VendorType } from 'src/utils/types';
import { getClosestBusinessDay } from '../utils';
import { useFetchBillAccountCategoryOptions } from './hooks/useFetchAccountCategoryOptions';
import { accountingSoftwareNameToIntlPath } from './utils/accountingSoftwareNameToIntlPath';

type Props = {
  isDisabled: boolean;
  isAutofocus?: boolean;
  filteredVendors?: Array<Option>;
  onVendorsInputChange?: OnInputChange;
  onFieldChange: (Object) => void;
  onChange?: (field: FieldType) => void;
  validationErrors?: Record<string, any>;
  isRecurringBill: boolean;
  isViewRecurring?: boolean;
  manually?: boolean;
  vendorAccountIdentifier?: string;
  billPageType: BillPageType;
  occurrences: string | null;
  frequency: BillFrequency | null;
  mode?: any;
  onChangeInvoice?: (file: File) => void;
  onDeleteAttachment?: () => void;
  handleRetry?: () => void;
  fileName?: string;
  isUploadError?: boolean;
  isUploading?: boolean;
  handleSelectFile?: (file: File) => void;
  isPaymentRequest?: boolean;
  allowEditVendor?: boolean;
  isBillCreatedFromPaymentRequest?: boolean;
} & EditableBillType;

export const BillDetailsForm = ({
  isDisabled,
  isAutofocus,
  vendorId,
  totalAmount,
  invoiceNumber,
  dueDate,
  note,
  filteredVendors,
  onVendorsInputChange,
  onFieldChange,
  validationErrors,
  mode,
  vendorAccountIdentifier,
  intuitAccountId,
  billPageType,
  onChange,
  isRecurringBill,
  isViewRecurring,
  manually,
  occurrences,
  frequency,
  onChangeInvoice,
  onDeleteAttachment,
  fileName,
  isUploading,
  isUploadError,
  handleSelectFile,
  handleRetry,
  isPaymentRequest = false,
  allowEditVendor = true,
  isBillCreatedFromPaymentRequest = false,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const showUpload10KInvoiceFileInput =
    !isRecurringBill && manually && isBillAmountRequiresInvoiceFile(convertCurrencyToNumber(totalAmount));
  const site = useSiteContext();
  const vendors = useSelector((state) =>
    vendorsStore.selectors.list.value(state, {
      orgId,
      shouldMelioMeVendorHasDeliveryMethod: true,
    })
  ) as VendorType[];
  const { theme } = site;
  const {
    isConnected: isConnectedAccountingPlatform,
    connectedAccountingPlatform,
  } = usePayablesConnectedAccountingPlatform();
  const accountCategoriesOptions = useFetchBillAccountCategoryOptions();
  const {
    businessDetails,
    shouldShowBusinessDetailsBox,
    deliveryMethodDefaultValue,
    shouldDisplayReviewVendorDetailsNotification,
  } = useBusinessDetailsBoxInForm({
    vendorOptions: filteredVendors,
    selectedVendorId: vendorId ? +vendorId : undefined,
    mode,
  });

  const [duplicateVendorNameModalView, showDuplicateVendorNameModal] = useModal(DuplicateVendorNameModal, {
    modalName: 'duplicate-vendor-name',
    cancelLabel: 'useExistingVendorButton',
    saveLabel: 'continueButton',
  });

  const isEditPageType = billPageType === BillPageType.EDIT;
  const formType = billPageType === BillPageType.CREATE ? FormType.WIZARD : FormType.DETAILS;
  const selectFlavor = billPageType === BillPageType.CREATE ? SingleSelectFlavor.DEFAULT : SingleSelectFlavor.INLINE;
  const inputSize = billPageType === BillPageType.CREATE ? 'md' : 'sm';
  let dueDateNotices = {
    label: '',
    specificDay: '',
  };
  let label;
  let specificDay;

  if (isRecurringBill && dueDate) {
    if (frequency === BillFrequency.WEEKLY) {
      specificDay = dueDate ? format(dueDate, DateFormats.dayLong) : '';
      label = 'bills.form.recurring.recurringWeeklyHint';
    } else {
      specificDay = dueDate ? format(dueDate, DateFormats.dayOfMonth) : '';
      label = 'bills.form.recurring.recurringMonthlyHint';
    }

    dueDateNotices = {
      label,
      specificDay,
    };
  }

  const onVendorOptionChange = (obj: FieldType & Record<string, any>) => {
    onVendorChange(obj, vendors, onFieldChange, showDuplicateVendorNameModal);
  };

  const isShowAccountIdentifier = (
    currentVendorId,
    groupedVendors?: Option[],
    isBillView?: boolean,
    screenMode?: ScreenMode
  ): boolean => {
    if (!groupedVendors) return false;

    const selectedVendor = getSelectOptionObject(groupedVendors, currentVendorId);

    const isViewMode = screenMode === ScreenMode.VIEW;

    if (isBillView && isViewMode && selectedVendor?.isRppsVendor) {
      return true;
    }

    return selectedVendor?.type === SelectVendorType.DIRECTORY && selectedVendor.directoryType === DirectoryType.Biller;
  };

  const vendorIdFormControl = {
    id: 'vendorId',
    placeholder: 'bills.form.vendorPlaceholder',
    noOptionsLabel: 'bills.form.vendorNoOptions',
    value: vendorId,
    label: isEditPageType ? 'bills.details.vendor' : 'bills.form.vendor',
    allowCustom: true,
    isDisabled: isDisabled || !allowEditVendor,
    errorMessage: validationErrors?.vendorId,
    required: true,
    flavor: selectFlavor,
    controlType: FormControlType.SELECT,
    inputMaxLength: getMaxLength('vendor', 'companyName'),
    labelValues: {
      newTag: <BillersVisibilityTooltip location="bill" />,
    },
    options: filteredVendors,
    onChange: onVendorOptionChange,
    onInputChange: onVendorsInputChange,
    overrideStyles: getSelectOverrideStyle(filteredVendors as Option[], theme, isEditPageType),
    formatOptionLabel: (option, formatOptions) => <VendorOptionLabel option={option} formatOptions={formatOptions} />,
    formatGroupLabel: (group) => <OptionGroupLabel group={group} />,
    isValidNewOption: (inputValue: string, selectValue: Option[], selectOptions: Option[]): boolean =>
      !allowEditVendor || isValidNewOption(inputValue, selectValue, selectOptions),
    // We do not want to search in case of vendor directory, cause we have a backend search
    filterOption: (option) => option,
  };

  const businessDetailsCardFormControl = {
    id: 'businessDetailsCard',
    controlType: FormControlType.BLOCK,
    renderBlock: () => {
      if (!businessDetails) {
        return null;
      }

      return (
        <Box flex={1} mb={10} mt={billPageType === BillPageType.CREATE ? -6 : undefined}>
          <VerifiedVendorDetails
            businessName={businessDetails.label!}
            businessAddress={convertToDisplayAddress(businessDetails.address ?? {})}
            businessContactInfo={{
              email: businessDetails.contactEmail,
              fullName: businessDetails.contactName,
              phone: businessDetails.contactPhone,
              deliveryMethodLabel: deliveryMethodDefaultValue,
            }}
            notification={shouldDisplayReviewVendorDetailsNotification ? reviewVendorDetailsNotification : undefined}
          />
        </Box>
      );
    },
    isHide: !shouldShowBusinessDetailsBox,
  };

  const vendorAccountIdentifierFormControl = {
    id: 'vendorAccountIdentifier',
    onChange: onFieldChange,
    value: vendorAccountIdentifier,
    label: 'bills.form.vendorAccNum',
    placeholder: 'bills.form.vendorAccNumPlaceholder',
    emptyLabel: 'bills.form.vendorAccNumEmpty',
    notices: ['bills.form.vendorAccNumHint'],
    type: 'text',
    isHide: !isShowAccountIdentifier(vendorId, filteredVendors, isEditPageType, mode),
    isDisabled,
    isRequired: true,
    size: inputSize,
    errorMessage: validationErrors?.accountIdentifier,
    controlType: FormControlType.TEXT,
    autoComplete: FORM_AUTOCOMPLETE.OFF,
    maxLength: getMaxLength('vendor', 'accountNumber'),
  };

  const accountIdFormControl = {
    id: 'intuitAccountId',
    value: intuitAccountId,
    placeholder: 'bills.form.externalAccountPlaceholder',
    label: 'bills.form.externalAccount',
    labelValues: {
      accountingPlatform: (
        <MIFormattedText label={accountingSoftwareNameToIntlPath(connectedAccountingPlatform?.name)} />
      ),
    },
    emptyLabel: 'bills.form.externalAccountEmpty',
    options: accountCategoriesOptions,
    isDisabled,
    onChange: onFieldChange,
    errorMessage: validationErrors?.intuitAccountId,
    flavor: selectFlavor,
    formatOptionLabel: ({ account }: AccountingSoftwareAccountCategoryOption, { context }) => (
      <AccountCategoryOption context={context} account={account} />
    ),
    controlType: FormControlType.SELECT,
    isHide: !isConnectedAccountingPlatform,
    formatSelectViewValue: (selectValue) => (selectValue && selectValue.Name ? selectValue.Name : selectValue),
    filterOption: ({ data: { account } }, term: string | number) =>
      account.Name.toLowerCase().indexOf(term.toString().toLowerCase()) > -1,
  };

  const totalAmountFormControl = {
    id: 'totalAmount',
    value: totalAmount,
    label: billPageType === BillPageType.CREATE ? 'bills.form.billAmount' : 'bills.form.totalAmount',
    placeholder: 'bills.form.totalAmountPlaceholder',
    onChange: onFieldChange,
    isDisabled,
    autoFocus: isAutofocus,
    isRequired: true,
    errorMessage: validationErrors?.totalAmount,
    controlType: FormControlType.CURRENCY,
    autoComplete: FORM_AUTOCOMPLETE.OFF,
    decimalScale: 2,
    privateData: true,
    size: inputSize,
  };

  const invoiceNumberFormControl = {
    id: 'invoiceNumber',
    value: invoiceNumber,
    label: isPaymentRequest ? 'bills.form.paymentRequestInvoiceNumber' : 'bills.form.invoiceNumber',
    emptyLabel: 'bills.form.invoiceNumberEmpty',
    placeholder: 'bills.form.invoiceNumberPlaceholder',
    type: 'text',
    onChange: onFieldChange,
    disabled: isDisabled,
    size: inputSize,
    errorMessage: validationErrors?.invoiceNumber,
    controlType: FormControlType.TEXT,
    autoComplete: FORM_AUTOCOMPLETE.OFF,
    maxLength: getMaxLength('bill', 'invoiceNumber'),
  };
  const dueDateFormControl = {
    id: 'dueDate',
    date: dueDate,
    label: isRecurringBill ? 'bills.form.recurring.dueDate' : 'bills.form.dueDate',
    emptyLabel: 'bills.form.dueDateEmpty',
    placeholder: isRecurringBill ? 'bills.form.recurring.dueDatePlaceholder' : 'bills.form.dueDatePlaceholder',
    onChange: onFieldChange,
    isRequired: true,
    size: inputSize,
    errorMessage: validationErrors?.dueDate,
    controlType: FormControlType.DATE,
    disabled: isDisabled,
    min: isRecurringBill ? new Date() : undefined,
    filterDate: isRecurringBill ? (day) => isBusinessDay(day) : undefined,
    renderDayContents: isRecurringBill ? renderDayContents : undefined,
    openToDate: isRecurringBill ? getClosestBusinessDay().toDate() : undefined,
    helperText: dueDateNotices.label,
    helperTextValues: { specificDay: dueDateNotices.specificDay },
    dateFormat: isRecurringBill ? 'EEEE, MMM d, y' : undefined,
  };

  const noteFormControl = {
    id: 'note',
    value: note,
    label: isPaymentRequest || isBillCreatedFromPaymentRequest ? 'bills.form.noteFromVendor' : 'bills.form.description',
    emptyLabel: 'bills.form.descriptionEmpty',
    placeholder: 'bills.form.descriptionPlaceholder',
    onChange: onFieldChange,
    disabled: isDisabled,
    privateData: true,
    size: inputSize,
    errorMessage: validationErrors?.note,
    controlType: FormControlType.TEXT,
    autoComplete: FORM_AUTOCOMPLETE.OFF,
    maxLength: getMaxLength('bill', 'note'),
  };

  const frequencyFormControl = {
    value: frequency,
    defaultValue: frequency,
    label: 'bills.form.frequency',
    model: 'paymentRepeat',
    name: 'frequency',
    options: [
      {
        label: 'bills.form.paymentRepeat.oneTime',
        id: BillFrequency.ONE_TIME,
      },
      {
        label: 'bills.form.paymentRepeat.monthly',
        id: BillFrequency.MONTHLY,
      },
      {
        label: 'bills.form.paymentRepeat.weekly',
        id: BillFrequency.WEEKLY,
      },
    ],
    isRequired: true,
    controlType: FormControlType.RADIO,
    onChange: (value: string) =>
      onChange &&
      onChange({
        id: 'frequency',
        value,
      }),
  };

  const upload10KInvoiceFormControl = {
    id: 'invoiceFile',
    fileName,
    text: 'bills.form.receivedProduct.instruction',
    isFileUploading: isUploading,
    isFileUploadError: isUploadError,
    onSelectFile: handleSelectFile,
    onRetry: handleRetry,
    onChangeInvoiceFile: onChangeInvoice,
    onDeleteInvoiceFile: onDeleteAttachment,
    controlType: FormControlType.FILE,
    pageType: billPageType,
  };

  const occurrencesFormControl = {
    label: 'bills.form.recurring.occurrences',
    inputId: 'occurrences',
    id: 'occurrences',
    value: occurrences,
    isRequired: true,
    size: inputSize,
    controlType: FormControlType.TEXT,
    onChange: (field: FieldType) =>
      onChange &&
      onChange({
        id: field.id,
        value: field.value,
      }),
    errorMessage: validationErrors?.occurrences,
    autoComplete: FORM_AUTOCOMPLETE.OFF,
    type: 'number',
    min: 0,
    placeholder: 'bills.form.recurring.occurrencesPlaceHolder',
    helperText:
      frequency && frequency === BillFrequency.MONTHLY
        ? 'bills.form.recurring.occurrencesHintForMonthly'
        : 'bills.form.recurring.occurrencesHintForWeekly',
  };

  const formControls: Array<Record<string, any> | Array<Record<string, any>>> = [
    vendorIdFormControl,
    businessDetailsCardFormControl,
    vendorAccountIdentifierFormControl,
    accountIdFormControl,
    totalAmountFormControl,
  ];

  if (billPageType === BillPageType.CREATE) {
    if (showUpload10KInvoiceFileInput) formControls.push(upload10KInvoiceFormControl);

    if (!site?.disableRecurring) {
      formControls.push(frequencyFormControl);
    }

    if (isRecurringBill) {
      formControls.push([invoiceNumberFormControl, occurrencesFormControl], dueDateFormControl, noteFormControl);
    } else {
      formControls.push([invoiceNumberFormControl, dueDateFormControl], noteFormControl);
    }
  } else {
    if (isViewRecurring) {
      formControls.push(occurrencesFormControl);
    }

    formControls.push(dueDateFormControl, invoiceNumberFormControl, noteFormControl);
  }

  return (
    <Box>
      {duplicateVendorNameModalView}
      <MIForm formControls={formControls} mode={mode} formType={formType} />
    </Box>
  );
};
