import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFetchFreeChecks } from 'src/pages/pay/hooks/useFetchFreeChecks';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { DeliveryType, FundingType } from 'src/utils/consts';

type Props = {
  fundingSourceType?: FundingType | null;
  deliveryMethodType?: DeliveryType;
};

export const usePaperCheckFee = ({ fundingSourceType, deliveryMethodType }: Props) => {
  const { total, available, fetchFreeChecks } = useFetchFreeChecks();
  const companyInfo = useSelector(getCompanyInfo);
  const paperCheckFee = companyInfo.billingSetting?.fee['ach-to-check']?.value;
  const isAchFundingSource = fundingSourceType === FundingType.ACH;
  const isCheckDeliveryMethod = deliveryMethodType === DeliveryType.CHECK;

  useEffect(() => {
    fetchFreeChecks(companyInfo.id);
  }, []);

  return {
    paperCheckFee,
    shouldDisplayPaperCheckFee: isCheckDeliveryMethod && isAchFundingSource,
    availableFreeChecks: available,
    totalFreeChecks: total,
  };
};
