import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';

type Props = {
  testId: string;
  actionOptions: ActionOption[];
};

export const ActionsDropdown = ({ testId, actionOptions }: Props) => (
  <Menu autoSelect={false}>
    <MenuButton
      as={Button}
      rightIcon={<ChevronDownIcon width="1rem" height="1rem" />}
      size={ButtonSizes.sm}
      variant={ButtonVariants.tertiary}
      data-testid={testId}
    >
      Actions
    </MenuButton>
    <MenuList>
      {actionOptions.map(({ label, action, negative, disabled }) => {
        const hideTooltip = !(typeof disabled !== 'boolean' && disabled?.label);

        return (
          <Tooltip
            key={label}
            isDisabled={hideTooltip}
            shouldWrapChildren
            placement="top"
            label={
              typeof disabled !== 'boolean' && disabled?.label ? (
                <MIFormattedText label={disabled.label} values={disabled.values} />
              ) : null
            }
          >
            <MenuItem
              key={label}
              label={label}
              onClick={action}
              color={negative ? 'red.500' : 'black'}
              isDisabled={Boolean(disabled)}
            />
          </Tooltip>
        );
      })}
    </MenuList>
  </Menu>
);
