export const generateFormattedCurrencySearchWords = (searchText: string) => {
  const separatorStepSize = 3;
  const separator = ',';

  const initialSeparatorIndexes = Array.from({ length: separatorStepSize }, (element, index) => index + 1);
  const separatorIndexesVariants = initialSeparatorIndexes
    .map((initialSeparatorIndex) => {
      let separatorIndex: number = initialSeparatorIndex;
      const separatorIndexes: number[] = [];

      while (separatorIndex < searchText.length) {
        separatorIndexes.push(separatorIndex);
        separatorIndex += separatorStepSize;
      }

      return separatorIndexes;
    })
    .filter((separatorIndexes) => separatorIndexes.length);

  const results = separatorIndexesVariants.map((separatorIndexes) => {
    let formattedSearchText = searchText;

    separatorIndexes.reverse().forEach((separatorIndex) => {
      formattedSearchText = `${formattedSearchText.substr(0, separatorIndex)}${separator}${formattedSearchText.substr(
        separatorIndex
      )}`;
    });

    return formattedSearchText;
  });

  return [searchText, ...results];
};
