import { addUsersSlice } from 'src/modules/users/add-users-slice';
import { allUserOrganizationsSlice } from 'src/modules/users/all-user-organizations-slice';
import { editUserOrganizationsSlice } from 'src/modules/users/edit-user-organizations-slice';
import {
  userManagementChangeOwnerSlice,
  userManagementDeleteSlice,
  userManagementHideFirmClientSlice,
  userManagementListSlice,
  userManagementUnhideFirmClientSlice,
  userManagementUpdateSlice,
} from 'src/modules/users/user-management-slice';

export const USERS_ADD_SUCCESS = addUsersSlice.actions.success;
export const USERS_FETCH_ORGANIZATION_USERS_SUCCESS = allUserOrganizationsSlice.actions.success;
export const USERS_EDIT_SUCCESS = editUserOrganizationsSlice.actions.success;
export const USERS_CHANGE_OWNER_SUCCESS = userManagementChangeOwnerSlice.actions.success;
export const USERS_DELETE_SUCCESS = userManagementDeleteSlice.actions.success;
export const USERS_LIST_SUCCESS = userManagementListSlice.actions.success;
export const USERS_UPDATE_SUCCESS = userManagementUpdateSlice.actions.success;
export const USERS_HIDE_FIRM_CLIENT_SUCCESS = userManagementHideFirmClientSlice.actions.success;
export const USERS_UNHIDE_FIRM_CLIENT_SUCCESS = userManagementUnhideFirmClientSlice.actions.success;
