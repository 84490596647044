export enum STEPS {
  CONFIRMATION = 'confirmation',
  FORM = 'form',
  INFO = 'info',
  SUCCESS = 'success',
}

export enum FLOW_ENTRY_POINT {
  GUEST = 'guest',
}

export const eventType = 'SUVC-change-delivery-method';

export enum EDGE_CASES {
  DEPOSITED = 'ACH-deposited',
  CLEARED = 'VC-already-cleared',
  REQUESTED = 'ACH-requested',
  CANCELED = 'VC-canceled',
}

export const flowNameIdentifier = 'shift-suvc-to-ach-flow';
