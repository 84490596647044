import { featureFlags } from '@melio/shared-web';
import { FeatureFlags } from 'src/utils/consts';
import { ApprovalWorkflowsMinThresholds, UserOrganization } from 'src/utils/types';
import { calcActorUserOrgsThresholds } from './calcActorUserOrgsThresholds';

type Props = {
  actorUserOrgs: readonly UserOrganization[];
  minThresholds?: ApprovalWorkflowsMinThresholds | null;
};

export const useActorUserOrgs = ({ actorUserOrgs, minThresholds }: Props) => {
  const [approvalWorkflowsEnabled] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);

  return approvalWorkflowsEnabled ? calcActorUserOrgsThresholds(actorUserOrgs, minThresholds) : actorUserOrgs;
};
