import { TransactionResponseStatusEnum } from '@melio/spend-management-api-axios-client';

export enum NotificationTypeEnum {
  SUCCESSFUL_AUTH = 'spendManagement.pages.dashboard.transactionDetails.notificationTypes.authSuccess',
  DECLINED_AUTH = 'spendManagement.pages.dashboard.transactionDetails.notificationTypes.authDeclined',
  TRANSACTION = 'spendManagement.pages.dashboard.transactionDetails.notificationTypes.transaction',
}

export const getTransactionStatus = (transaction): TransactionResponseStatusEnum => {
  let { status } = transaction;

  if (!status) {
    if (transaction.authorization) {
      status =
        transaction.authorization.notificationType === 'DECLINED_AUTH'
          ? TransactionResponseStatusEnum.Declined
          : TransactionResponseStatusEnum.InProgress;
    }

    if (transaction.transaction?.transactionDetails || transaction.transactionDetails) {
      status = TransactionResponseStatusEnum.Approved;
    } else {
      status = TransactionResponseStatusEnum.InProgress;
    }
  }

  return status;
};

export const getTransactionAmount = (transaction): string =>
  String(
    transaction.authorization?.authorizationDetails?.authorizationAmount ||
      transaction.transaction?.transactionDetails?.transactionAmount.value ||
      transaction?.transactionDetails?.transactionAmount.value
  );

export const getTransactionDate = (transaction): string =>
  transaction.authorization?.authorizationDetails?.authorizationTimestamp.replace(' ', '') ||
  transaction.transaction?.transactionDetails?.transactionDate ||
  transaction?.transactionDetails?.transactionDate;

export const getEventDescription = (transaction): string =>
  transaction?.transaction
    ? NotificationTypeEnum.TRANSACTION
    : NotificationTypeEnum[transaction.authorization?.notificationType || transaction.notificationType];

export const getTransactionReferenceNumber = (transaction): string =>
  transaction.transaction?.transactionDetails?.referenceNumber || transaction?.transactionDetails?.referenceNumber;
