import { MouseEvent as ReactMouseEvent } from 'react';
import styled from 'styled-components';
import { ModalButtonsDocked } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';

type Props = {
  onCancel: (event: ReactMouseEvent) => void;
  onDisconnect: (event: ReactMouseEvent) => void;
  loading: boolean;
};

const ModalButtonsWrapper = styled(ModalButtonsDocked)`
  margin: 0;
  display: flex;
  flex-direction: row-reverse;
  gap: 1rem;
`;

export const AmexDisconnectButtons = ({ onDisconnect, onCancel, loading }: Props) => (
  <ModalButtonsWrapper>
    <Button
      label="settings.spendManagement.disconnectModal.disconnect"
      testId="button-user.diconnectDialog.confirm"
      variant={ButtonVariants.error}
      size={ButtonSizes.md}
      isLoading={loading}
      onClick={onDisconnect}
    />
    <Button
      label="settings.spendManagement.disconnectModal.cancel"
      variant={ButtonVariants.tertiary}
      size={ButtonSizes.md}
      onClick={onCancel}
    />
  </ModalButtonsWrapper>
);
