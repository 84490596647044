import { isValidationOk } from '@melio/sizzers-js-common';
import { useMemo } from 'react';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import Box from 'src/core/ds/box';
import { PasswordField } from 'src/core/ds/form/fields';
import { useApi } from 'src/hoc/useApi';
import { authApi } from 'src/modules/auth/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { authLocations } from 'src/pages/auth/locations';
import { analytics } from 'src/services/analytics';
import clientServiceApi from 'src/services/api/clientService';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { ValidationError } from 'src/ui/ValidationError';

const eventPage = 'change-password';

type Props = {
  token: string;
};

type ChangePasswordType = {
  currentPassword: string;
  password: string;
  confirmedPassword: string;
};

export const ChangePasswordPage = ({ token }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const changePassword = useMemo<ChangePasswordType>(
    () => ({
      currentPassword: '',
      password: '',
      confirmedPassword: '',
    }),
    []
  );
  const { onApiCall: onChangePassword, loading: isChanging } = useApi<
    [{ token: string; oldPassword: string; newPassword: string }],
    void
  >({
    api: authApi.changePasswordWithToken,
  });

  const trackAnalyticsOnFailure = (validationErrors) => {
    analytics.track(eventPage, 'validation-error', validationErrors);
  };

  const onSubmit = async (value) => {
    const { currentPassword, password, confirmedPassword } = value;
    const { validationErrors } = await clientServiceApi.getValidationErrors(
      'userRegistration',
      {
        currentPassword,
        password,
        confirmedPassword,
      },
      Object.keys(value) as (keyof ChangePasswordType)[]
    );

    if (isValidationOk(validationErrors)) {
      if (currentPassword.trim() === password.trim()) {
        validationErrors.password = 'profile.changePassword.error.currentPasswordDiff';
        trackAnalyticsOnFailure(validationErrors);
        throw new ValidationError({ validationErrors });
      }
    } else {
      trackAnalyticsOnFailure(validationErrors);
      throw new ValidationError({ validationErrors });
    }

    await onChangePassword({
      token,
      oldPassword: currentPassword,
      newPassword: password,
    })
      .then(() => {
        historyPush({ path: authLocations.login });
        analytics.track(eventPage, 'success');
      })
      .catch(() => {
        validationErrors.currentPassword = 'profile.changePassword.error.wrongCurrentPassword';
        trackAnalyticsOnFailure(validationErrors);
        throw new ValidationError({ validationErrors });
      });
  };

  const [changePasswordMV, { submit }] = useForm<ChangePasswordType>(changePassword, {
    submit: onSubmit,
  });

  const goResetPassword = () => {
    analytics.track(eventPage, 'go-reset-password');
    historyPush({ path: authLocations.requestResetPassword });
  };

  return (
    <RegisterLayoutPage
      title="profile.changePassword.title"
      buttonLabel="profile.changePassword.save"
      buttonAction={submit}
      secondaryLinkAction={goResetPassword}
      secondaryLinkLabel="profile.changePassword.forgotPassword"
      isLoading={isChanging}
    >
      <Box mb={10}>
        <Box as={FormRow} mb={10}>
          <PasswordField
            testId="input-currentPassword"
            label="profile.changePassword.currentPassword"
            model={changePasswordMV.currentPassword}
            isRequired
            autoFocus
          />
        </Box>
        <Box as={FormRow} mb={10}>
          <PasswordField
            testId="input-password"
            label="profile.changePassword.password"
            model={changePasswordMV.password}
            isRequired
          />
        </Box>
        <Box as={FormRow} mb={10}>
          <PasswordField
            label="profile.changePassword.confirmPassword"
            model={changePasswordMV.confirmedPassword}
            isRequired
          />
        </Box>
      </Box>
    </RegisterLayoutPage>
  );
};
