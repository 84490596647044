import { format, parseISO } from 'date-fns';
import colors from 'src/core/theme/foundations/colors';
import { PaymentsRequestStatus } from 'src/pages/get-pro/consts';
import { InvoiceStatus } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';

export const getAmountColorByStatus = (status?: PaymentsRequestStatus) => {
  const { FULLY_PAID, MARKED_AS_PAID, FAILED, CANCELLED, CANCELLED_BY_CUSTOMER } = PaymentsRequestStatus;
  const { PAID } = InvoiceStatus;

  if (status) {
    if ([FULLY_PAID, MARKED_AS_PAID, PAID].includes(status)) {
      const green500 = colors.green['500'];

      return `${green500} !important`;
    } else if ([FAILED, CANCELLED, CANCELLED_BY_CUSTOMER].includes(status)) {
      const red500 = colors.red['500'];

      return `${red500} !important`;
    }
  }

  return 'black';
};

export const getFormatTableDate = (date?: string | null) =>
  date ? format(parseISO(date), DateFormats.monthShortWithLongDateAndYear) : '-';
