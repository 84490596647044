import { ModelViewField } from 'src/ui/form';
import { InlineRoleSelectGroup, Option } from './InlineRoleSelectGroup';

export type InlineRoleSelectProps<T> = {
  model?: ModelViewField<T>;
  group?: string;
  value?: T;
  label?: string;
  id?: string;
  options: Option<T>[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (arg0: { value: T }) => any;
  colDirection?: boolean;
};

export function InlineRoleSelect<T extends string>(props: InlineRoleSelectProps<T>) {
  const { model, label, onChange, options, ...rest } = props;
  const value = (props.value === undefined ? model?.value : props.value) as T;
  const realOnChange = props.onChange || model?.onChange;
  const id = props.id || (model?.id as string);

  return (
    <InlineRoleSelectGroup<T>
      group="group"
      {...rest}
      options={options}
      selected={value}
      onSelect={(value) => realOnChange && realOnChange({ value })}
      id={id}
    />
  );
}
