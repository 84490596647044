import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Switch, useLocation } from 'react-router-dom';
import { AppLayout } from 'src/components/layout/AppLayout';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { EnrollPage } from 'src/pages/spend-management/EnrollPage';
import { ExtensionSuccessPage } from 'src/pages/spend-management/ExtensionSuccessPage';
import { InstallExtensionPage } from 'src/pages/spend-management/InstallExtensionPage';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { Onboarding } from 'src/pages/spend-management/Onboarding';
import { analytics } from 'src/pages/spend-management/SpendManagementAnalytics';
import { getOrgId } from 'src/redux/user/selectors';
import { useIsOnboard } from './hooks/useIsOnboard';
import { SpendManagementPage } from './SpendManagementPage';

export function SpendManagementRouter() {
  const orgId = useSelector(getOrgId);
  const redirectToOnboarding = !useIsOnboard();
  const [historyPush] = useHistoryWithOrgId();
  const { pathname } = useLocation();

  useEffect(() => {
    analytics.pageByPath(pathname.replace(/orgs\/[0-9]+\//g, 'orgs/:orgId/'));
  }, [pathname]);

  const onEnrolled = async () => {
    historyPush({ path: spendManagementLocations.installExtension });
  };

  return (
    <Switch>
      <SmartRoute path={spendManagementLocations.onboarding} exact allowFor="authenticated">
        <Onboarding />
      </SmartRoute>
      {redirectToOnboarding && (
        <SmartRoute
          allowFor="authenticated"
          component={() => (
            <Redirect
              to={generatePath(spendManagementLocations.onboarding, {
                orgId,
              })}
            />
          )}
        />
      )}
      <SmartRoute path={spendManagementLocations.enroll} exact allowFor="authenticated">
        <EnrollPage onEnrolled={onEnrolled} />
      </SmartRoute>
      <SmartRoute path={spendManagementLocations.installExtension} exact allowFor="authenticated">
        <InstallExtensionPage />
      </SmartRoute>
      <SmartRoute path={spendManagementLocations.extensionSuccess} exact allowFor="authenticated">
        <ExtensionSuccessPage />
      </SmartRoute>
      <SmartRoute
        path={[spendManagementLocations.cardsList, spendManagementLocations.transactionsList]}
        exact
        allowFor="authenticated"
      >
        <AppLayout>
          <SpendManagementPage />
        </AppLayout>
      </SmartRoute>
      <SmartRoute
        path={spendManagementLocations.index}
        allowFor="authenticated"
        component={() => (
          <Redirect
            to={generatePath(spendManagementLocations.cardsList, {
              orgId,
            })}
          />
        )}
      />
    </Switch>
  );
}
