import { MicroDepositsNotEligibleDialog } from 'src/components/micro-deposits/modals/MicroDepositsNotEligibleDialog';
import { MicroDepositsWasSentDialog } from 'src/components/micro-deposits/modals/MicroDepositsWasSentDialog';
import { useMicroDepositOptimizationsEnabled } from 'src/hooks/useMicroDepositOptimizationsEnabled';
import { useOrgMicroDepositEligible } from 'src/hooks/useOrgMicroDepositEligible';
import { MicroDepositActions, MicroDepositState } from 'src/pages/settings/hooks/microDepositsCommon';
import { getIsRecentlySentMicroDeposits } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';
import { MicroDepositsAlreadyVerifiedDialog } from './modals/MicroDepositsAlreadyVerifiedDialog';
import { MicroDepositsNotSentDialog } from './modals/MicroDepositsNotSentDialog';
import { VerifyMicroDepositsDialog } from './modals/VerifyMicroDepositsDialog';

type Props = {
  fundingSource: AccountType;
  dismissEvents: Record<string, () => void>;
  digits: string;
  companyName: string;
  onContinueWithoutVerifying?: () => void;
} & MicroDepositState &
  MicroDepositActions;

export const VerifyFundingSourceMicroDeposits = (props: Props) => {
  const { fundingSource, dismissEvents, digits, onContinueWithoutVerifying, companyName } = props;
  const { isMicroDepositOptimizationsEnabled } = useMicroDepositOptimizationsEnabled();
  const { isOrgMicroDepositEligible } = useOrgMicroDepositEligible();
  const isDepositsNotSent = !!fundingSource?.bankAccount?.depositsNotSent;
  const depositsDeliverDate = fundingSource?.bankAccount?.depositsDeliverDate;
  const isRecentlySentMicroDeposits = depositsDeliverDate ? getIsRecentlySentMicroDeposits(depositsDeliverDate) : null;
  const isVerified = !!fundingSource?.isVerified;

  if (isVerified) {
    return <MicroDepositsAlreadyVerifiedDialog onOkAction={dismissEvents.dismissSuccess} />;
  }

  if (isMicroDepositOptimizationsEnabled && !isOrgMicroDepositEligible) {
    return <MicroDepositsNotEligibleDialog dismiss={dismissEvents.cancel} digits={digits} />;
  }

  if (isDepositsNotSent) {
    return <MicroDepositsNotSentDialog digits={digits} onOkAction={dismissEvents.dismissSuccess} />;
  } else if (isMicroDepositOptimizationsEnabled && isOrgMicroDepositEligible) {
    return (
      <MicroDepositsWasSentDialog
        {...props}
        dismiss={dismissEvents.cancel}
        digits={digits}
        onGoNext={dismissEvents.continueWithoutVerifying}
        showContinueWithoutVerifyingButton={!!onContinueWithoutVerifying}
        isRecentlySentMicroDeposits={isRecentlySentMicroDeposits}
      />
    );
  }

  return (
    <VerifyMicroDepositsDialog
      {...props}
      companyName={companyName ?? ''}
      dismiss={dismissEvents.cancel}
      digits={digits}
    />
  );
};
