import { useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router-dom';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { billsStore } from 'src/modules/bills/bills-store';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { billLocations } from 'src/pages/bill/locations';
import { AccountRecord } from 'src/pages/settings/records';
import { analytics } from 'src/services/analytics';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { getBillsDefaultFilters, serializePaymentId } from 'src/utils/bills';
import { BillStatus } from 'src/utils/consts';
import { getPaymentById } from 'src/utils/payments';
import { PaymentType } from 'src/utils/types';

export const RequestRefundStatus = () => {
  const { navigate } = useNavigator();
  const match = useRouteMatch<{ billId: string; paymentId: string; orgId: string }>();
  const { billId, paymentId, orgId } = match.params;
  const bill = useSelector(billsStore.selectors.fetch.byId(billId));
  const payment = getPaymentById(bill.payments, paymentId) as PaymentType;

  const fundingSource = AccountRecord(payment.fundingSource);

  const accountNumber = getAccountNumber4digits(fundingSource.bankAccount);

  const onSubmit = () => {
    const defaultFilters = getBillsDefaultFilters(BillStatus.UNPAID);
    const id = serializePaymentId(billId, paymentId);

    analytics.trackAction('button.click', { paymentId: payment.id, billId: payment.billId });

    navigate(
      generatePath(billLocations.filteredView, {
        orgId,
        id,
        ...defaultFilters,
      })
    );
  };

  return (
    <WizardLayout>
      <WizardLayout.TopBar />
      <WizardLayout.Content>
        <WizardLayout.Body>
          <Box textAlign="center" data-testid="successful-scheduled-refund-text">
            <Box mb={4} mt={20}>
              <Illustration name={IllustrationName.sendPurple} size={IllustrationSize.lg} />
            </Box>
            <Box textStyle="h1Semi" mb={4}>
              <MIFormattedText label="bills.pay.requestRefund.scheduledStatus.successTitle" />
            </Box>
            <Box textStyle="body2">
              <MIFormattedText
                label="bills.pay.requestRefund.scheduledStatus.successText"
                values={{
                  amount: <MIFormattedCurrency value={payment.amount} />,
                  bankAccountNumber: accountNumber,
                }}
              />
            </Box>
          </Box>
        </WizardLayout.Body>
        <Button
          label="bills.pay.requestRefund.scheduledStatus.successButton"
          size={ButtonSizes.lg}
          onClick={onSubmit}
        />
      </WizardLayout.Content>
    </WizardLayout>
  );
};
