import { featureFlags } from '@melio/shared-web';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { Loader } from 'src/components/common/Loader';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { billsStore } from 'src/modules/bills/bills-store';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import { BillDetails } from 'src/pages/bill/components/BillDetails';
import { getPaymentRequestIdById, isPaymentRequest } from 'src/utils/bills';
import { FeatureFlags, PaymentApprovalStatus } from 'src/utils/consts';
import { getLatestPayment } from 'src/utils/payments';
import { useViewBillData } from '../ViewBill/useViewBillData';
import { BillsBatchActionsFooter } from './components/BillsBatchActionsFooter';
import { Gallery, GalleryItemsContainer } from './components/Gallery';
import { PaymentBatchActionsFooter } from './components/PaymentBatchActionsFooter';
import { usePrevious } from './hooks/usePrevious';
import { PaymentBatchView } from './PaymentBatchView';

export const PAGE_EVENT = 'bills-gallery';

type Props = {
  selectedIds: string[];
};

export const SelectedItemsGallery = ({ selectedIds }: Props) => {
  const [isBatchApproveEnabled] = featureFlags.useFeature(FeatureFlags.BatchApprove, false);
  const orgId = useOrgId();
  const [displayBillId, setDisplayBillId] = useState('');
  const paymentRequestsActions = useStoreActions(paymentRequestsStore);
  const { fetch: getBillById } = useStoreActions(billsStore);
  const billIds = selectedIds.map((id) => id.toString());
  const { bill, billId, paymentId } = useViewBillData(displayBillId);
  const prevSelectedIds = usePrevious(billIds) || [];

  useEffect(() => {
    displayBillId && loadBill();
  }, [displayBillId]);

  useEffect(() => {
    if (!isEqual(billIds, prevSelectedIds)) {
      handleSelectedIdsChanges();
    }
  }, [billIds.length]);

  const handleSelectedIdsChanges = () => {
    const removeItem = difference(prevSelectedIds, billIds);

    if (removeItem.length && displayBillId === removeItem[0]) {
      const currentIndex = prevSelectedIds.indexOf(displayBillId);
      const next = prevSelectedIds[currentIndex + 1];
      setDisplayBillId(next || billIds[0]);
    }

    const addItem = difference(billIds, prevSelectedIds || []);

    if (addItem?.length && billIds.includes(addItem[0])) {
      setDisplayBillId(addItem[0]);
    }
  };

  const loadBill = async () => {
    if (isPaymentRequest(displayBillId)) {
      const requestId = getPaymentRequestIdById(displayBillId);
      await paymentRequestsActions.fetch({
        organizationId: orgId,
        id: requestId,
      });
    } else {
      await getBillById({
        orgId,
        id: billId,
      });
    }
  };
  const currentBillIndex = billIds.indexOf(displayBillId);
  const latestBillPayment = getLatestPayment(bill.payments);
  const isPaymentPendingApproval =
    latestBillPayment?.approvalDecisionStatus === PaymentApprovalStatus.PENDING && paymentId && isBatchApproveEnabled;

  const detailsView = isPaymentPendingApproval ? (
    <PaymentBatchView bill={bill} paymentId={paymentId} itemsAmount={selectedIds.length} />
  ) : (
    <GalleryItemsContainer itemsAmount={selectedIds.length}>
      <Box py={10} px={8}>
        <BillDetails bill={bill} eventPage={PAGE_EVENT} />
      </Box>
    </GalleryItemsContainer>
  );

  const footer = isPaymentPendingApproval ? <PaymentBatchActionsFooter /> : <BillsBatchActionsFooter />;
  const isLoading = !bill.id;

  return (
    <Flex direction="column" px={2} h="full" justify="center">
      <Gallery items={billIds} currentIndex={currentBillIndex} onChange={setDisplayBillId}>
        <Flex w="full" maxW="63rem" direction="column" h="full" alignItems="stretch">
          {bill.id ? (
            detailsView
          ) : (
            <GalleryItemsContainer>
              <Flex h="35rem" alignItems="center">
                <Loader color="primary" context="page" />
              </Flex>
            </GalleryItemsContainer>
          )}
        </Flex>
      </Gallery>
      {isLoading ? null : footer}
    </Flex>
  );
};
