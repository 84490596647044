import { MIFormattedText } from 'src/components/common/MIFormattedText';

type Props = {
  userFullName: string;
};

export const DeleteAdminModalMessageContent = ({ userFullName }: Props) => (
  <MIFormattedText
    label="user.deleteAdminModal.content"
    // Once the "Learn More" article is published, we would need to replace the existing values prop with this one
    // values={{ userFullName, link: <MIInlineLink label="user.deleteAdminModal.learnMore" /> }}
    values={{ userFullName }}
  />
);
