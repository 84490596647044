import { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { TabSettingsType } from 'src/utils/types';

/**
 * Tab - in use only with Tabs component
 */
class Tab extends PureComponent<TabSettingsType> {
  handleClick = () => {
    const { name, page, properties } = this.props.trackEvent;
    analytics.track(`${name}-list`, page, properties);
  };

  isActive = () => this.props.active;

  render() {
    const { label, to, unreadBadge } = this.props;

    return (
      <StyledTab
        activeClassName="active"
        isActive={this.isActive}
        role="tab"
        to={to}
        onClick={() => this.handleClick()}
        unreadBadge={unreadBadge || false}
        data-testid={label}
      >
        <MIFormattedText label={label} />
        <Underline isActive={this.props.active} />
      </StyledTab>
    );
  }
}

export { Tab };

/**
 * Tabs
 */
type Props = {
  tabs: TabSettingsType[];
};

const Tabs = ({ tabs }: Props) => (
  <TabsWrapper>
    <div role="tablist">
      {tabs.map((tab, idx) => (
        <Tab key={idx} {...tab} />
      ))}
    </div>
  </TabsWrapper>
);

export default Tabs;

/**
 * Styles
 */
const TabsWrapper = styled.div`
  width: 100%;
  font-size: 1.6rem;

  & > div[role='tablist'] {
    > *:last-child {
      margin-right: 0;
    }
  }
  @media ${devices.mobile}, ${devices.phablet} {
    background-color: ${(props) => props.theme.colors.white.opaque};
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
    margin-top: 4rem;
    position: absolute;
    z-index: ${(props) => props.theme.zIndex.mobileTabs};
    box-sizing: border-box;
    height: ${(props) => props.theme.sizes.mobileHeaderTabsHeight};
    left: 0;
    padding-left: 2rem;
    top: 2.3rem;
    display: flex;
    align-items: flex-end;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin-top: 1.6rem;
  }
  ${(props) => props.theme?.components?.Tabs?.TabsWrapper}
`;

const StyledTab = styled(({ unreadBadge, ...rest }: TabSettingsType) => <NavLink {...rest} />)`
  position: relative;
  line-height: 2.7rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.label};
  margin-right: 1.6rem;
  user-select: none;
  cursor: pointer;
  text-transform: capitalize;
  display: inline-block;
  text-decoration: none;

  &.active {
    color: ${(props) => props.theme.text.color.main};
  }

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }

  &:focus {
    outline: none;
  }
  ${(props) => props.theme?.components?.Tabs?.StyledTab}

  ${(props) =>
    props.unreadBadge
      ? `
    &:after {
      content: ' ';
      position: absolute;
      top: 0;
      right: -1rem;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      background-color: ${props.theme.colors.failure.opaque};
    }
  `
      : ''}
`;

const Underline = styled.div`
  display: none;
  width: 100%;
  height: 2px;
  background: ${(props) => props.theme.text.color.main};
  ${(props) =>
    props.isActive &&
    css`
      display: block;
    `}

  ${(props) => props.theme?.components?.Tabs?.Underline}
`;
