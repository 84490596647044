import debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';
import * as React from 'react';
import Box from 'src/core/ds/box';
import { CloseButton } from 'src/core/ds/closeButton/CloseButton';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { Grid } from 'src/core/ds/grid';
import { PrivateDataContainer } from 'src/core/ds/input';
import { InvoiceType } from 'src/modules/invoices/types';
import CardAmount from '../components/CardAmount';
import CardField from '../components/CardField';

const DEBOUNCE_DELAY = 300;
type Props = {
  invoice: InvoiceType;
  onRemove: (id: string) => void;
  onChangeInvoiceData: (id: string, customerEmail?: string) => void;
  validationError?: {
    customerEmail?: string;
  };
};

const BatchPaymentRequestItem: React.FC<Props> = ({ invoice, validationError, onRemove, onChangeInvoiceData }) => {
  const { customer, id, totalAmount } = invoice;
  const [customerEmail, setCustomerEmail] = useState(customer?.contactEmail);

  const changeInvoiceData = useCallback(
    debounce((value) => onChangeInvoiceData(id, value), DEBOUNCE_DELAY),
    []
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setCustomerEmail(value);
    changeInvoiceData(value);
  };

  const onRemoveClick = () => onRemove(id);

  return (
    <Box border="1px" borderRadius="lg" borderColor="grey.400" p={6} mb={4}>
      <Grid justifyContent="center" templateColumns="2fr 1fr 1fr 0.1fr" gap={4}>
        <Flex direction="column">
          <PrivateDataContainer>
            <TextField
              label="getPro.cards.batchPaymentRequests.customerEmail"
              value={customerEmail}
              errorMessage={validationError?.customerEmail}
              onChange={onChange}
              id="getPro.cards.batchPaymentRequests.customerEmail"
              size="sm"
              isRequired
            />
          </PrivateDataContainer>
        </Flex>
        <CardField
          value={customer?.companyName || customer?.contactName}
          titleLabel="getPro.cards.batchPaymentRequests.customerName"
          emptyLabel="getPro.cards.batchPaymentRequests.customerNameEmpty"
          isTruncated
        />
        <CardField
          titleLabel="getPro.cards.batchPaymentRequests.amount"
          value={<CardAmount amount={totalAmount} privateData />}
        />
        <CloseButton onClick={onRemoveClick} />
      </Grid>
    </Box>
  );
};

export default BatchPaymentRequestItem;
