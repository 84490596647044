import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/redux/user/selectors';

type ReturnType = {
  isOrgMicroDepositEligible: boolean;
};

export const useOrgMicroDepositEligible = (): ReturnType => {
  const companyInfo = useSelector(getCompanyInfo);

  return {
    isOrgMicroDepositEligible: !!companyInfo?.isMicroDepositEligible,
  };
};
