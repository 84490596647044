import some from 'lodash/some';
import { PayItemPaymentType } from 'src/pay/types';
import { InstallmentStatus, PaymentStatus, PayTabs } from 'src/utils/consts';
import { BillType, Installment, PaymentType } from 'src/utils/types';

export function isInProgressFinancedPayment(payment: PayItemPaymentType) {
  const scheduledInstallment = payment?.financing?.installments.find(isScheduledInstallment);
  const isPaymentInProgress = payment?.status === PaymentStatus.IN_PROGRESS;

  return Boolean(scheduledInstallment) && isPaymentInProgress;
}

export const hasRetryInstallment = (installments: Installment[]): boolean =>
  installments.some((installment) => installment.status === InstallmentStatus.Retry);

export const isFailedInstallment = (installment: Installment): boolean =>
  installment.status === InstallmentStatus.Failed;

export const isScheduledInstallment = (installment: Installment): boolean =>
  installment.status === InstallmentStatus.Scheduled;

export const isCompletedInstallment = (installment: Installment): boolean =>
  [
    InstallmentStatus.Committed,
    InstallmentStatus.Sent,
    InstallmentStatus.Completed,
    InstallmentStatus.Acknowledged,
    InstallmentStatus.Cleared,
    InstallmentStatus.Settled,
  ].includes(installment.status);

export const getCurrentScheduledInstallment = (installments: Installment[]): Installment | undefined => {
  const installment = installments.find((installments) => isScheduledInstallment(installments));

  return installment;
};

export const getCurrentFailedInstallment = (installments: Installment[]): Installment | undefined => {
  const installment = installments.find((installments) => isFailedInstallment(installments));

  return installment;
};

export const hasFailedInstallment = (payment: PaymentType) =>
  Boolean(
    payment.isFinanced &&
      payment.status === PaymentStatus.IN_PROGRESS &&
      payment.financing?.installments &&
      some(payment.financing.installments, isFailedInstallment)
  );

export const getIsViewInstallment = (
  tab: string | undefined,
  payment: PaymentType & { bills: BillType[] }
): { isViewInstallment: boolean; installment?: Installment } => {
  if (!payment.financing) {
    return { isViewInstallment: false };
  }

  if (tab === PayTabs.INBOX && hasFailedInstallment(payment)) {
    const installment = getCurrentFailedInstallment(payment.financing?.installments) as Installment;

    return { isViewInstallment: true, installment };
  }

  if (tab === PayTabs.SCHEDULED && isInProgressFinancedPayment(payment)) {
    const installment = getCurrentScheduledInstallment(payment.financing?.installments) as Installment;

    return { isViewInstallment: true, installment };
  }

  return { isViewInstallment: false };
};
