import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { getOrgId } from 'src/redux/user/selectors';

export const useAccountingSoftwareSyncActions = (accountingSoftwareType?: string) => {
  const apActions = useStoreActions(accountingPlatformsStore);
  const orgId = useSelector(getOrgId);
  const accountPlatform = useSelector(accountingPlatformsStore.selectors.getByName(accountingSoftwareType));
  const platformId = accountPlatform?.id;

  const onDisconnect = async () => {
    await apActions.updateSyncActivation({ orgId, id: platformId, isActive: false });
    await refresh();
  };
  const onReconnect = async () => {
    await apActions.updateSyncActivation({ orgId, id: platformId, isActive: true });
    await refresh();
  };
  const refresh = async () => {
    apActions.list({ orgId });
    await apActions.fetchSyncSummary({ orgId });
  };

  const fetch = async () => apActions.fetch({ orgId, id: platformId });

  return { onDisconnect, onReconnect, refresh, fetch };
};
