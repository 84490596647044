import { AreaLoader } from 'src/components/common/AreaLoader';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { useVirtualCardState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { useVirtualCardView } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardView';

type Props = {
  token: string;
  virtualCardId: string;
};

export const VirtualCardDetailsPage = ({ token, virtualCardId }: Props) => {
  const [state] = useVirtualCardState({ token, virtualCardId });
  const { payment, virtualCard, isPaymentLoading } = state;
  const view = useVirtualCardView({ state, payment, virtualCard });

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return <OutsideLayout>{view}</OutsideLayout>;
};
