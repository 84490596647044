import { isValidationOk } from '@melio/sizzers-js-common';
import { useState } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc/index';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { authApi } from 'src/modules/auth/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { authLocations } from 'src/pages/auth/locations';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { analytics } from 'src/services/analytics';
import clientServiceApi from 'src/services/api/clientService';
import guestApi from 'src/services/api/guests';
import { Site } from 'src/sites/site';
import { RegistrationFlow } from 'src/utils/consts';
import GuestSignUpPage from './components/GuestSignUpPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type Props = GuestDataProps & {
  site: Site;
};

const eventPage = 'payor';
const eventName = 'signup';

const GuestSignUpPageContainer = ({
  email,
  nextRoute,
  navigateToGuestPage,
  site,
  link,
  paymentRequest,
  totalAmount,
  invoiceNumber,
  onChange,
  companyName,
  promotionName,
  coupons,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const { navigate } = useNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();

  const onNext = () => {
    const formattedEmail = email.trim().toLowerCase();

    setIsLoading(true);
    clientServiceApi.getValidationErrors('guest', { email: formattedEmail }, ['email']).then(({ validationErrors }) => {
      setValidationErrors(validationErrors);

      if (isValidationOk(validationErrors)) {
        authApi
          .checkEmailUniqueness({ email: formattedEmail })
          .then(() => guestApi.sendEmailVerificationCode(formattedEmail))
          .then(() => {
            setIsLoading(false);
            analytics.track(eventPage, `${eventName}-continue-success`);
            navigateToGuestPage(nextRoute, undefined, !site.embeddedMode);
          })
          .catch((e) => {
            analytics.track(eventPage, 'register-email-not-unique');
            setIsLoading(false);
            setErrorCode(e.code);
          });
      } else {
        setIsLoading(false);
        analytics.track(eventPage, `${eventName}-validation-error`, validationErrors);
      }
    });
  };

  const onPrev = () => {
    analytics.track(eventPage, `${eventName}-back`);

    if (paymentRequest && paymentRequest.link) {
      navigateToGuestPage(generatePath(melioMeLocations.wizard.requestPayment, { link, hash: paymentRequest.link }));
    } else {
      navigateToGuestPage(generatePath(melioMeLocations.wizard.index, { link }));
    }
  };

  const onLoginClick = () => {
    analytics.track(eventPage, `${eventName}-go-login`);
    onRedirectClick(authLocations.login);
  };

  const onSignUpClick = () => {
    analytics.track(eventPage, `${eventName}-go-register`);
    onRedirectClick(authLocations.register.index);
  };

  const onRedirectClick = (url: string) => {
    // We need to use paymentDateWithParams to make possible share totalAmount and invoiceNumber for the case
    // if user will logged in via intuit. For intuit we can't use navigateWithPreservedState
    navigate(
      url,
      false,
      {
        redirectUrl: generatePath(melioMeLocations.wizard.editFundingSourceWithParams, {
          link,
          totalAmount,
          invoiceNumber,
        }),
        registrationFlow: RegistrationFlow.GUEST,
        preservedState: {
          ...locationState,
          link,
        },
      },
      !site.embeddedMode
    );
  };

  return (
    <GuestSignUpPage
      email={email}
      validationErrors={validationErrors}
      isLoading={isLoading}
      onNext={onNext}
      onPrev={onPrev}
      onLoginClick={onLoginClick}
      onSignUpClick={onSignUpClick}
      onChange={onChange}
      errorCode={errorCode}
      companyName={companyName}
      promotionName={promotionName}
      coupons={coupons}
    />
  );
};

export default compose(withNavigator(), withGuestData(), withSiteContext())(GuestSignUpPageContainer);
