import { CopyLinkSize } from 'src/utils/consts';
import { CopyLinkToClipboard } from './CopyLinkToClipboard';
import { CreatePaymentLink } from './CreatePaymentLink';
import { MICardField } from './MICard';

type Props = {
  link: string;
  clipboardText: string;
  size?: CopyLinkSize;
  origin?: string;
};

export const CopyOrCreatePaymentLink = ({ link, clipboardText, size, origin }: Props) =>
  link ? (
    <MICardField label="onboarding.companyInfo.link.inputTitleLink">
      <CopyLinkToClipboard link={link} clipboardText={clipboardText} size={size} />
    </MICardField>
  ) : (
    <CreatePaymentLink size={size} origin={origin} />
  );
