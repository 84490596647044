import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { DialogType, DialogVariants } from 'src/utils/consts';

export type PlaidMaintenanceModalProps = {
  goExit(): void;
};

export const PlaidUpgradeModal = ({ goExit }: PlaidMaintenanceModalProps) => (
  <Dialog
    type={DialogType.ALERT}
    variant={DialogVariants.ERROR}
    title="onboarding.fundingSources.bank.plaid.upgradeErrorTitle"
    subtitle="onboarding.fundingSources.bank.plaid.upgradeErrorSubtitle"
    cancelButtonText="onboarding.fundingSources.bank.plaid.action"
    onOkAction={goExit}
    onCancelAction={goExit}
  />
);
