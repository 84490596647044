import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SelectionCard } from 'src/core/components/cards/SelectionCard';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { useDisclosure } from 'src/core/ds/hooks';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { Stack } from 'src/core/ds/stack';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { financingStore } from 'src/modules/financing/financing-store';
import { profileStore } from 'src/modules/profile/profile-store';
import {
  FINANCING_CATEGORY,
  financingAnalyticsEvents,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { TermsAndConditions } from 'src/pages/bill/pay/installments/pages/components/TermsAndConditions';
import { OwnershipRequiredModal } from 'src/pages/bill/pay/installments/pages/owner-decision/OwnershipRequiredModal';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { analytics } from 'src/services/analytics';

type Props = {
  goNext: () => void;
  cancelFlow: () => void;
};

enum OwnerDecision {
  Unknown = 'Unknown',
  Owner = 'Owner',
  NotOwner = 'NotOwner',
}

export const OwnerDecisionPage = ({ goNext, cancelFlow }: Props) => {
  const [ownerDecision, setOwnerDecision] = useState<OwnerDecision>(OwnerDecision.Unknown);
  const { isOpen, onOpen: onOpenModal, onClose } = useDisclosure();
  const { companyName } = useSelector(profileStore.selectors.getCurrentOrg);
  const financingActions = useStoreActions(financingStore);
  const payment = useSelector(getPayment);
  const { payBillFlowUUID } = payment;

  useEffect(() => {
    analytics.page(FINANCING_CATEGORY, financingPage.ownerDecision, { amount: payment.amount });
  }, []);

  const handlePayAnotherWay = () => {
    analytics.track(financingPage.ownerDecision, financingAnalyticsEvents.proofOfOwnershipModalPayAnotherWayClicked);
    financingActions.businessOwnerDecision.set(false);
    cancelFlow();
  };

  const handleSelection = (id: string) => {
    analytics.track(financingPage.ownerDecision, financingAnalyticsEvents.ownershipChange, {
      businessOwner: id === OwnerDecision.Owner,
    });

    if (id === OwnerDecision.Owner || id === OwnerDecision.NotOwner) {
      setOwnerDecision(id);
    } else {
      setOwnerDecision(OwnerDecision.Unknown);
    }
  };

  const handleSubmit = () => {
    analytics.track(financingPage.ownerDecision, financingAnalyticsEvents.ownershipContinue, {
      businessOwner: ownerDecision === OwnerDecision.Owner,
    });

    if (ownerDecision === OwnerDecision.NotOwner) {
      onOpenModal();
    } else if (ownerDecision === OwnerDecision.Owner) {
      financingActions.businessOwnerDecision.set(true);
      goNext();
    }
  };

  return (
    <>
      <WizardLayout>
        <WizardLayout.TopBar onCancelClick={cancelFlow} />
        <WizardLayout.Content>
          <WizardLayout.Header>
            <Box textStyle="h1Semi" textAlign="center" marginBottom={4} data-testid="financing-owner-decision-title">
              <MIFormattedText
                label="financing.ownerDecision.title"
                values={{
                  companyName,
                }}
              />
            </Box>
            <Box textStyle="body2" textAlign="center">
              <MIFormattedText label="financing.ownerDecision.subTitle" />
            </Box>
          </WizardLayout.Header>
          <WizardLayout.Body>
            <Stack direction="row" textAlign="center" spacing={5}>
              <SelectionCard
                id={OwnerDecision.Owner}
                title="financing.ownerDecision.ownerOption"
                analyticAction="select-owner"
                isSelected={ownerDecision === OwnerDecision.Owner}
                onSelect={handleSelection}
              />
              <SelectionCard
                id={OwnerDecision.NotOwner}
                title="financing.ownerDecision.notOwnerOption"
                analyticAction="select-not-owner"
                isSelected={ownerDecision === OwnerDecision.NotOwner}
                onSelect={handleSelection}
              />
            </Stack>
          </WizardLayout.Body>
          <WizardLayout.Footer>
            <Box alignItems="left" w="full" marginBottom={6}>
              <TermsAndConditions payBillFlowUUID={payBillFlowUUID} />
            </Box>
            <Button
              label="general.continue"
              size={ButtonSizes.lg}
              onClick={handleSubmit}
              isDisabled={ownerDecision === OwnerDecision.Unknown}
            />
          </WizardLayout.Footer>
        </WizardLayout.Content>
      </WizardLayout>
      <OwnershipRequiredModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handlePayAnotherWay}
        companyName={companyName}
      />
    </>
  );
};
