import { featureFlags } from '@melio/shared-web';
import { useAnonymousFeature } from '@melio/shared-web/dist/feature-flags';
import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { UpsellType } from 'src/modules/payments/api-types';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { shiftToDebitLocations } from 'src/pages/vendor/shift-vendor-to-debit/locations';
import { DeliveryPreferenceType, DeliveryType, FeatureFlags, PaymentStatus } from 'src/utils/consts';
import { DeliveryMethodType, PaymentType } from 'src/utils/types';
import { upgradePaymentLocations as locations } from '../locations';

export function useUpgradePaymentEntry(token: string) {
  const [isUpgradePaymentToFACHAvailable, isLDClientLoading] = useAnonymousFeature(
    FeatureFlags.VendorUpgradePaymentToFACH,
    false,
    {
      shouldTrack: false,
    }
  );

  const history = useHistory();
  const paymentActions = useStoreActions(paymentsStore);

  useEffect(() => {
    if (token && !isLDClientLoading) {
      fetchPaymentUpsellDetails();
    }
  }, [token, isLDClientLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchPaymentUpsellDetails = async () => {
    try {
      const {
        payload: { upsellItems, payment },
      } = await paymentActions.fetchPaymentUpsellDetails({
        token,
      });

      if (isWrongPaymentStatus(payment)) {
        redirectToOfferExpiredPage();

        return;
      }

      if (isPaymentAlreadyUpgradedToDebit(payment.deliveryMethod)) {
        redirectToDebitMethodPage();

        return;
      }

      if (isPaymentAlreadyUpgradedToExpeditedAch(payment)) {
        redirectToSuccessPage(UpsellType.PUSH_TO_FAST_ACH);

        return;
      }

      if (isPaymentAlreadyUpgradedToRTP(payment)) {
        redirectToSuccessPage(UpsellType.PUSH_TO_RTP);

        return;
      }

      const isP2DEligible = upsellItems.find(({ type }) => type === UpsellType.PUSH_TO_DEBIT)?.isEligible;
      const isP2FACHEligible = upsellItems.find(({ type }) => type === UpsellType.PUSH_TO_FAST_ACH)?.isEligible;
      const isP2RTPEligible = upsellItems.find(({ type }) => type === UpsellType.PUSH_TO_RTP)?.isEligible;

      const isUpgradePaymentToRTPAvailable = featureFlags.defaultClient.getVariantNoTrack<boolean>(
        FeatureFlags.VendorUpgradePaymentToRTP,
        false
      );

      if (isUpgradePaymentToRTPAvailable && isP2RTPEligible) {
        redirectToRtpMethodPage();

        return;
      }

      if (isUpgradePaymentToFACHAvailable && isP2DEligible && isP2FACHEligible) {
        redirectToSelectMethodPage();

        return;
      }

      if (isUpgradePaymentToFACHAvailable && isP2FACHEligible) {
        redirectToFastAchMethodPage();

        return;
      }

      if (isUpgradePaymentToFACHAvailable && isP2DEligible) {
        redirectToDebitMethodPage();

        return;
      }

      if (isP2DEligible) {
        redirectToDebitMethodPage();

        return;
      }

      redirectToOfferExpiredPage();
    } catch (error: any) {
      // Possible reasons: invalid/expired token; canceled payment; payment not found; internal server error;
      redirectToOfferExpiredPage(error?.code);
    }
  };

  const isWrongPaymentStatus = (payment: Partial<PaymentType>) =>
    [PaymentStatus.FAILED, PaymentStatus.COMPLETED].includes(payment.status as PaymentStatus);

  const isPaymentAlreadyUpgradedToDebit = (deliveryMethod: Partial<DeliveryMethodType>) =>
    deliveryMethod?.deliveryType === DeliveryType.CARD;

  const isPaymentAlreadyUpgradedToExpeditedAch = (payment: Partial<PaymentType>) =>
    payment.deliveryMethod?.deliveryType === DeliveryType.ACH &&
    payment.deliveryPreference === DeliveryPreferenceType.EXPEDITED_ACH;

  const isPaymentAlreadyUpgradedToRTP = (payment: Partial<PaymentType>) =>
    payment.deliveryMethod?.deliveryType === DeliveryType.ACH &&
    payment.deliveryPreference === DeliveryPreferenceType.RTP;

  const redirectToSelectMethodPage = () => {
    history.replace(generatePath(locations.selectMethod, { token }));
  };

  const redirectToFastAchMethodPage = () => {
    history.replace(generatePath(locations.fastAchMethod, { token }));
  };

  const redirectToRtpMethodPage = () => {
    history.replace(generatePath(locations.rtpMethod, { token }));
  };

  const redirectToDebitMethodPage = () => {
    history.replace(generatePath(shiftToDebitLocations.index, { token }));
  };

  const redirectToSuccessPage = (selectedUpsellType: UpsellType) => {
    history.replace(generatePath(locations.success, { token }), {
      selectedUpsellType,
    });
  };

  const redirectToOfferExpiredPage = (reason?: string) => {
    history.replace(generatePath(locations.offerExpired, { token }), {
      expirationReason: reason,
    });
  };
}
