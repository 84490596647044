import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { MicroDepositsAmountFields } from 'src/components/micro-deposits/modals/components/MicroDepositsAmountFields';
import Box from 'src/core/ds/box';
import { MicroDepositActions, MicroDepositState } from 'src/pages/settings/hooks/microDepositsCommon';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  digits?: string;
} & MicroDepositState &
  MicroDepositActions;

export const VerifyMicroDepositsDialog = ({
  amount1,
  amount2,
  onChange,
  validationErrors,
  serverError,
  onSubmit,
  dismiss,
  isLoading,
  companyName,
  digits,
}: Props) => (
  <ModalMessage
    titleComponent={
      <>
        <Box mb={4} textStyle="h2Semi" textAlign="left">
          <MIFormattedText label="settings.microDeposits.verifyDialog.title" />
        </Box>
        {serverError && (
          <Box mb={4}>
            <MINotificationCard
              subtitle={{
                label: serverError,
              }}
              type={NotificationCardTypes.ERROR}
            />
          </Box>
        )}
        <Box textStyle="body2" textAlign="left">
          <MIFormattedText
            label="settings.microDeposits.verifyDialog.subtitle"
            values={{
              companyName,
              digits,
            }}
          />
        </Box>
      </>
    }
    contentComponent={
      <MicroDepositsAmountFields
        amount1={amount1}
        amount2={amount2}
        onChange={onChange}
        validationErrors={validationErrors}
        onSubmit={onSubmit}
        serverError={serverError}
        dismiss={dismiss}
      />
    }
    buttonLabel="settings.microDeposits.verifyDialog.confirm"
    onButtonClick={onSubmit}
    onCloseClick={dismiss}
    isLoading={isLoading}
  />
);
