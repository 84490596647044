import { TabList, Tabs as ChakraTabs } from '@chakra-ui/react';
import { Props as TabProps, Tab } from './Tab';

type Props = {
  tabsData: TabProps[];
  activeTabIndex?: number;
};

export const Tabs = ({ tabsData, activeTabIndex = 0, ...rest }: Props) => (
  <ChakraTabs orientation="horizontal" defaultIndex={activeTabIndex} {...rest}>
    <TabList role="tablist">
      {tabsData.map((tabData, index) => (
        <Tab key={index} {...tabData} />
      ))}
    </TabList>
  </ChakraTabs>
);
