import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Separator } from 'src/core/ds/separator';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { ContentWrapper } from '../../components/ContentWrapper';
import { eventType } from '../../consts';
import { useSuvcToAchAnalytics } from '../../hooks/useSuvcToAchAnalytics';
import { TokenValuesType } from '../../types';

type Props = {
  tokenValues: TokenValuesType;
};

const eventPage = 'success';

export const SuccessPage = ({ tokenValues }: Props) => {
  const { paymentId } = tokenValues;
  const { pageEvent } = useSuvcToAchAnalytics(tokenValues);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));

  useEffect(() => {
    pageEvent(eventType, eventPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout isExperimentalView>
      <ContentWrapper>
        <Flex
          background="white"
          position="relative"
          alignItems="left"
          borderRadius="lg"
          padding={{ md: 10, base: '4rem 2rem' }}
          flexDirection="column"
        >
          <Box mb="2">
            <Icon color="green.500" size={IconSize.xl} name={IconNames.checkFill} />
          </Box>
          <Box data-testid="suvc-ach.successPageTitle" mb="0.5" textStyle="body2Semi">
            <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.success.title" />
          </Box>
          <Box mt="2" textStyle="body2">
            <MIFormattedText
              label="vendors.deliveryMethods.shiftSuvcToAch.success.subtitle"
              values={{
                paymentAmount: <MIFormattedCurrency value={payment?.amount} />,
                companyName: payment?.organization.companyName,
              }}
            />
          </Box>
          <Box pt="5">
            <Flex pr="5" mb="5" alignItems="center">
              <Box minW="10">
                <Icon name={IconNames.bank} size={IconSize.lg} />
              </Box>
              <Box>
                <Box textStyle="body4Semi" color="grey.600">
                  <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.bankTransfer" />
                </Box>
                <Box textStyle="body2">
                  <MIFormattedText
                    label="vendors.deliveryMethods.shiftSuvcToAch.bankAccountWithDigits"
                    values={{
                      last4Digits: payment?.deliveryMethod?.bankAccount?.accountNumber?.slice(-4),
                    }}
                  />
                </Box>
              </Box>
            </Flex>

            <Separator color="grey.400" />

            <Flex mt="5" pr="5" alignItems="center">
              <Box minW="10">
                <Icon name={IconNames.scheduled} size={IconSize.lg} />
              </Box>
              <Box>
                <Box textStyle="body4Semi" color="grey.600">
                  <MIFormattedText label="vendors.deliveryMethods.shiftSuvcToAch.requested.dateTitle" />
                </Box>

                <Box textStyle="body2">
                  <MIFormattedDate date={payment?.deliveryEta} />
                  <Box textStyle="body4" color="grey.700">
                    <MIFormattedText label="bills.form.paymentActivity.deliveryMethodAdditionalDescriptionPayee" />
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </ContentWrapper>
    </OutsideLayout>
  );
};
