import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import { getJWTPayload } from 'src/helpers/jwt';
import SuccessImage from 'src/images/core/success-icon.svg';
import CalendarImage from 'src/images/icons/calendar-icon.svg';
import Mastercard from 'src/images/icons/mastercard.svg';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';

type Props = {
  token: string;
};

export const NewDeliveryMethodVirtualCardPage = ({ token }: Props) => {
  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));

  if (!payment) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout>
      <ContentWrapper>
        <Image width={8} height={8} src={SuccessImage} />
        <Box textStyle="body2Semi" mt={4} mb={2}>
          <MIFormattedText label="vendors.deliveryMethods.deliveryMethodChangedToVirtualCard.title" />
        </Box>
        <Box textStyle="body2">
          <MIFormattedText
            label="vendors.deliveryMethods.deliveryMethodChangedToVirtualCard.description"
            values={{
              date: '',
            }}
          />
        </Box>
        <Box textStyle="body4Uppercase" color="grey.700" mt={5} mb={4}>
          <MIFormattedText label="vendors.deliveryMethods.deliveryMethodChangedToVirtualCard.youWillReceive" />
        </Box>
        <Flex align="center" borderBottom="1px" borderColor="grey.400" pb={5}>
          <Image width={6} height={5} src={Mastercard} />
          <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
            <Box textStyle="body4Semi" color="grey.600">
              <MIFormattedText label="vendors.deliveryMethods.deliveryMethodChangedToVirtualCard.singleUse" />
            </Box>
            <Box textStyle="body2" isTruncated>
              {payment.deliveryMethod?.virtualCardAccount?.accountEmail}
            </Box>
          </Box>
        </Flex>
        <Flex align="center" pt={5}>
          <Image width={6} height={6} src={CalendarImage} />
          <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
            <Box textStyle="body4Semi" color="grey.600">
              <MIFormattedText label="vendors.deliveryMethods.deliveryMethodChangedToVirtualCard.deliveryOn" />
            </Box>
            <Box textStyle="body2" isTruncated>
              <MIFormattedDate date={payment.deliveryEta} />
            </Box>
          </Box>
        </Flex>
      </ContentWrapper>
    </OutsideLayout>
  );
};
