import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { DownloadCsvReportModal } from 'src/components/shared/modals/DownloadCsvReportModal';
import Box from 'src/core/ds/box';
import IconButton from 'src/core/ds/iconButton';
import { IconButtonVariants } from 'src/core/ds/iconButton/consts';
import Tooltip from 'src/core/ds/tooltip';
import { useModal } from 'src/helpers/react/useModal';
import { ReactComponent as ExportIcon } from 'src/images/general/export.svg';

export const DownloadCsvReportButton = () => {
  const [DownloadCsvReportModalActual, showDownloadCsvReportModalActual] = useModal(DownloadCsvReportModal, {
    id: 'downloadCsvReportModal',
    modalName: 'getPro.downloadCsvReportModal',
  });

  return (
    <Box mr={3}>
      <Tooltip placement="top" label={<MIFormattedText label="getPro.downloadCsvReport.tooltip" />}>
        <IconButton
          variant={IconButtonVariants.action}
          onClick={showDownloadCsvReportModalActual}
          icon={<ExportIcon />}
          testId="downloadCsvReport"
          size="sm"
          aria-label="action"
        />
      </Tooltip>
      {DownloadCsvReportModalActual}
    </Box>
  );
};
