import noop from 'lodash/noop';
import { useCallback } from 'react';
import { useWizard, UseWizardArgs } from 'src/hooks/useWizard/useWizard';
import { flowNameIdentifier, STEPS } from './consts';
import { ConfirmationPage } from './steps/confirmation/ConfirmationPage';
import { AddAchMethodFormPage } from './steps/form/AddAchMethodFormPage';
import { InfoPage } from './steps/info/InfoPage';
import { SuccessPage } from './steps/success/SuccessPage';
import { StateAndActionsReturnsProps, Steps, TokenValuesType } from './types';

type Props = {
  tokenValues: TokenValuesType;
  firstStep: STEPS;
  suvcState: StateAndActionsReturnsProps;
};

const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  form: 'form',
  confirmation: 'confirmation',
  info: 'info',
  success: 'success',
};

export const navigationMap = {
  form: () => STEPS.CONFIRMATION,
  confirmation: (isBackToEdit: boolean) => (isBackToEdit ? STEPS.FORM : STEPS.SUCCESS),
  info: () => STEPS.INFO,
  success: noop,
};

export const ShiftVendorSuvcToAchFlow = ({ firstStep, tokenValues, suvcState }: Props) => {
  const { state, actions } = suvcState;
  const { goNextMap, currentStep } = useWizard<Steps, typeof navigationMap>({
    firstStep,
    flowName: flowNameIdentifier,
    locationsMap,
    navigationMap,
    cancelUrlFallback: '/',
  });

  const onSuccess = useCallback(() => {
    goNextMap.confirmation({ navArgs: [false] });
  }, [goNextMap]);

  const onFillBankDetails = useCallback(() => {
    goNextMap.form();
  }, [goNextMap]);

  const goEditForm = useCallback(() => {
    actions.resetFormValues();
    goNextMap.confirmation({ navArgs: [true] });
  }, [goNextMap, actions]);

  const renderStep = () => {
    switch (currentStep) {
      case STEPS.CONFIRMATION:
        return (
          <ConfirmationPage
            goNext={onSuccess}
            goPrev={goEditForm}
            tokenValues={tokenValues}
            dmState={{
              isLoading: state.isLoading,
              submitBankAccount: actions.submitBankAccount,
              deliveryMethodMV: state.deliveryMethodMV,
              validateBankAccount: actions.validateBankAccount,
            }}
          />
        );
      case STEPS.FORM:
        return (
          <AddAchMethodFormPage
            onFillBankDetails={onFillBankDetails}
            tokenValues={tokenValues}
            dmState={{
              validateBankAccount: actions.validateBankAccount,
              submitBankAccount: actions.submitBankAccount,
              deliveryMethodMV: state.deliveryMethodMV,
            }}
          />
        );
      case STEPS.INFO:
        return <InfoPage virtualCardState={state.virtualCardState} tokenValues={tokenValues} />;
      case STEPS.SUCCESS:
        return <SuccessPage tokenValues={tokenValues} />;
      default:
        return null;
    }
  };

  return renderStep();
};
