/* eslint-disable dot-notation */
import { Component } from 'react';
import { analytics } from 'src/services/analytics';
import { isLoginFromForest } from 'src/utils/user';
import config from '../../config';

class Segment extends Component {
  constructor(props) {
    super(props);

    if (this.isSegmentAllowed && window['analytics'] && window['analytics'].load) {
      window['analytics'].load(config.services.segment.key);
    }
  }

  componentDidUpdate() {
    if (!this.isSegmentAllowed) {
      analytics.reset(false);
    }
  }

  get isSegmentAllowed() {
    return !isLoginFromForest() && config.services.segment.shouldLoad;
  }

  render() {
    return null;
  }
}

export default Segment;
