import { Button, ButtonVariants } from 'src/core/ds/button';
import { billsApi } from 'src/modules/bills/api';
import { paymentRequestsApi } from 'src/modules/payment-requests/api';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import {
  BillStatus,
  NotificationVariant,
  PAY_DEFAULT_TAB,
  PaymentRequestApprovalStatus,
  PayTabs,
} from 'src/utils/consts';

const showPushNotification = (isApproved: boolean, onUndo: () => Promise<void>) => {
  const notificationParams = {
    type: isApproved ? NotificationVariant.SUCCESS : NotificationVariant.INFO,
    msg: isApproved ? 'bills.request.addNotification' : 'bills.request.removeNotification',
    textValues: {
      undo: (
        <Button
          label="toasts.undo"
          onClick={onUndo}
          variant={ButtonVariants.unstyled}
          pl={5}
          height="inherit"
          _active={{ bgColor: 'transparent' }}
        />
      ),
    },
  };
  pushNotification(notificationParams);
};

export const setPaymentRequestApprovalStatus = async (
  paymentRequestId: number,
  isApproved: boolean,
  isTrusted: boolean,
  orgId: number,
  loadBillsAndPayments: () => void,
  setSelected: (number) => void
) => {
  const eventPage = 'bills';

  if (isApproved) {
    analytics.track(eventPage, 'trust-vendor-future-from-pop-up', {
      isTrusted,
    });
  } else {
    analytics.track(eventPage, 'payment-request-to-bill-denied');
  }

  const status = isApproved ? PaymentRequestApprovalStatus.APPROVED : PaymentRequestApprovalStatus.DENIED;

  const result = await billsApi.createBillFromRequest(orgId, {
    approvalStatus: status,
    paymentRequestId,
    trusted: isTrusted,
  });

  loadBillsAndPayments();
  setSelected(result?.newBill?.data?.object?.id || result?.newBill?.id);

  showPushNotification(isApproved, async () => {
    await paymentRequestsApi.revokeApprovalStatus(orgId, paymentRequestId);
    loadBillsAndPayments();
  });
};

export const billStatusToPayTabs = (status: BillStatus): PayTabs =>
  ({
    [BillStatus.UNPAID]: PayTabs.INBOX,
    [BillStatus.SCHEDULED]: PayTabs.SCHEDULED,
    [BillStatus.PAID]: PayTabs.PAID,
  }[status] ?? PAY_DEFAULT_TAB);
