import get from 'lodash/get';
import head from 'lodash/head';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { withNavigator } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { filesApi } from 'src/modules/files/api';
import { melioMeLocations } from 'src/pages/meliome/locations';
import guestApi from 'src/services/api/guests';
import { encodeQuery } from 'src/utils/query-utils';
import { MelioMeCardVendorInvoiceInfo } from './components/MelioMeCardVendorInvoiceInfo';

type Props = {
  query: {
    vendorOptions: string;
    totalAmount?: string;
    invoiceNumber?: string;
  };
};

const GuestSelectLinkPage = ({ query: { vendorOptions, totalAmount, invoiceNumber } }: Props) => {
  const [companyInfo, setCompanyInfo] = useState({ logoUrl: null, name: '' });
  const [isLoading, setIsLoading] = useState(false);
  const options = useMemo(() => {
    try {
      return JSON.parse(vendorOptions);
    } catch (e) {
      return [];
    }
  }, [vendorOptions]);
  const { EmbeddedCloseButton } = useSiteContext().components;
  useEffect(() => {
    async function fetchCompanyInfo(link) {
      try {
        setIsLoading(true);
        const [{ fileStorageUrl }, { companyName }] = await Promise.all([
          filesApi.getLogoByVendorHandle(link),
          guestApi.getPublicVendorInfo(link),
        ]);

        setCompanyInfo({
          logoUrl: fileStorageUrl,
          name: companyName,
        });
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }

    const link = get(head(options), 'link', '');

    if (link) {
      fetchCompanyInfo(link);
    }
  }, [options]);

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <Container>
      <SelectLinkContainer>
        <CloseAction>
          <EmbeddedCloseButton />
        </CloseAction>
        <MelioMeCardVendorInvoiceInfo logoUrl={companyInfo.logoUrl} companyName={companyInfo.name} hideAmountInput />
        <Title>
          <MIFormattedText label="guests.select.title" />
        </Title>
        <LinkOptionsContainer>
          {options.map(({ link, label }) => {
            const url =
              totalAmount && invoiceNumber
                ? `${generatePath(melioMeLocations.wizard.requestPaymentNoEditing, {
                    link,
                  })}?${encodeQuery({ totalAmount, invoiceNumber }, [])}`
                : generatePath(melioMeLocations.wizard.index, { link });

            return (
              <LinkOption to={url} key={link}>
                {label}
                <ArrowIcon />
              </LinkOption>
            );
          })}
        </LinkOptionsContainer>
      </SelectLinkContainer>
    </Container>
  );
};

export default withNavigator()(GuestSelectLinkPage);

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.background.wizard};
`;

const SelectLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 45rem;
  padding-top: 6.7rem;
`;

const Title = styled.h2`
  font-size: 2.3rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  line-height: 3rem;
  text-align: center;
`;

const LinkOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

const LinkOption = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 9rem;
  border-radius: 8px;
  box-shadow: ${(props) => `0 5px 10px 0 ${props.theme.colors.dark.translucent1}`};
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 2rem;
  text-decoration: none;
  box-sizing: border-box;
  margin: 1rem;
  width: calc(49.9% - 2rem);
  ${(props) => props.theme.text.fontType.regular};

  &:hover {
    box-shadow: ${(props) => `0 8px 15px 0 ${props.theme.colors.dark.translucent2}`};
  }
`;

const ArrowIcon = styled.i.attrs({ className: 'icon-next-icon-2' })`
  font-size: 2.4rem;
`;

const CloseAction = styled.div.attrs({ displayName: 'CloseAction' })`
  position: fixed;
  top: 2rem;
  right: 2rem;
`;
