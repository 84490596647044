import Box from 'src/core/ds/box';
import { BatchListItem } from 'src/pages/contacts/list/components/BatchSettings/BatchListItem';
import { EmailValidationType } from 'src/pages/contacts/list/components/BatchSettings/useBatchChangeVendor';
import { isBatchUnilateralEligilibleVendor } from 'src/pages/contacts/utils';
import { isDirectoryVendor } from 'src/pages/vendor-directory/utils';
import { DeliveryType } from 'src/utils/consts';
import { VendorType } from 'src/utils/types';
import { BatchContactsScreenMode } from './consts';
import { batchListStyle } from './style';

type Props = {
  mode: BatchContactsScreenMode;
  vendors: readonly VendorType<'created'>[];
  changedVendors: readonly VendorType<'created'>[];
  validationErrors: readonly EmailValidationType[];
  removeFromList: (vendorId: number) => void;
  onEmailChange: (changeField: { id: number; value: string }) => void;
};

export const BatchList = ({
  mode,
  vendors,
  changedVendors,
  validationErrors,
  removeFromList,
  onEmailChange,
}: Props) => (
  <Box __css={batchListStyle}>
    {vendors.map(({ id, isEditable, companyName, contactEmail, deliveryMethods, managedBy }, index) => {
      const isEligibleVendor = isBatchUnilateralEligilibleVendor(isEditable, deliveryMethods);
      isEditable && deliveryMethods.every((dm) => dm.deliveryType !== DeliveryType.RPPS);
      const changedVendor = changedVendors.find((v) => v.id === id);
      const validationError = validationErrors.find((e) => e.id === id);
      const defaultDeliveryMethod = deliveryMethods[0];

      return (
        <BatchListItem
          key={id}
          mode={mode}
          vendorId={id}
          companyName={companyName}
          contactEmail={contactEmail}
          changedContactEmail={changedVendor?.contactEmail}
          withSeparator={index !== 0}
          isDirectoryVendor={isDirectoryVendor({ deliveryMethods, managedBy })}
          deliveryMethod={defaultDeliveryMethod}
          isEligibleVendor={isEligibleVendor}
          validationError={validationError}
          removeFromList={removeFromList}
          onEmailChange={onEmailChange}
        />
      );
    })}
  </Box>
);
