import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { InternationalCountryGroup } from 'src/utils/ServerTypes';

type Props = {
  groupLabel: InternationalCountryGroup;
};

export const CountryGroupLabel = ({ groupLabel }: Props) => (
  <Flex pl={5} h="3rem" align="center">
    <Box textStyle="body4Uppercase" color="grey.500">
      <MIFormattedText label={`vendors.deliveryMethods.international.countryGroup.${groupLabel}`} />
    </Box>
  </Flex>
);
