import { CardTypes, DeliveryType, FundingType, RISK_BILL_AMOUNT } from 'src/utils/consts';
import { isSameDay, isTomorrow } from 'src/utils/dates';
import { DeliveryOptionType } from 'src/utils/types';

type SubtitleProps = {
  isFast: boolean;
  totalAmount: number;
  hasFastCheck: boolean;
  deliveryOption: DeliveryOptionType;
  fundingSourceType?: string;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const getSubtitle = ({
  isFast,
  totalAmount,
  hasFastCheck,
  deliveryOption,
  fundingSourceType,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: SubtitleProps) => {
  if (isEligibleForFastAchAdoption) {
    if (isFastAchBetterExposeEtaEnabled) {
      return isFast
        ? 'bills.pay.date.deliveryOptions.subTitle.fast'
        : 'bills.pay.date.deliveryOptions.subTitle.standard';
    }

    if (isSameDay(deliveryOption.deliveryDate)) {
      return `bills.pay.date.deliveryOptions.fastAchExplanation.today`;
    }

    if (isTomorrow(deliveryOption.deliveryDate)) {
      return `bills.pay.date.deliveryOptions.fastAchExplanation.tomorrow`;
    }

    if (deliveryOption.type === 'expedited-ach') {
      return `bills.pay.date.deliveryOptions.subTitle.expeditedAchSameDay`;
    }
  }

  if (deliveryOption.type === DeliveryType.ACH) {
    if (isFastAchBetterExposeEtaEnabled) {
      return 'bills.pay.date.deliveryOptions.subTitle.standard';
    }

    if (fundingSourceType === FundingType.ACH) {
      return totalAmount >= RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD
        ? 'bills.pay.date.deliveryOptions.subTitle.achOver100K'
        : 'bills.pay.date.deliveryOptions.subTitle.achBelow100K';
    } else if (fundingSourceType === CardTypes.DEBIT) {
      return 'bills.pay.date.deliveryOptions.subTitle.achDebit';
    }
  }

  if (isFastAchBetterExposeEtaEnabled) {
    if (hasFastCheck) {
      if (deliveryOption.type === DeliveryType.CHECK) return 'bills.pay.date.deliveryOptions.subTitle.standard';
    }

    return 'bills.pay.date.deliveryOptions.subTitle.fast';
  }

  return `bills.pay.date.deliveryOptions.subTitle.${deliveryOption.type}`;
};

type PaperCheckLabelProps = {
  isBasicCheck: boolean;
  deliveryOption: DeliveryOptionType;
  fundingSourceType?: string;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const getPaperCheckLabel = ({
  isBasicCheck,
  deliveryOption,
  fundingSourceType,
  isFastAchBetterExposeEtaEnabled,
}: PaperCheckLabelProps) => {
  if (isBasicCheck && fundingSourceType === FundingType.ACH && deliveryOption?.fee[0]?.amount) {
    return isFastAchBetterExposeEtaEnabled
      ? 'bills.pay.date.deliveryOptions.price.paperCheckWithFeeDeliveryCompany'
      : 'bills.pay.date.deliveryOptions.price.paperCheckWithFee';
  }

  return isFastAchBetterExposeEtaEnabled
    ? 'bills.pay.date.deliveryOptions.price.defaultCheckDeliveryCompany'
    : 'bills.pay.date.deliveryOptions.price.defaultCheck';
};

export const getDollarLabel = (isCheck: boolean, isFastAchBetterExposeEtaEnabled?: boolean) => {
  if (isCheck) {
    return isFastAchBetterExposeEtaEnabled
      ? 'bills.pay.date.deliveryOptions.price.dollarCheckDeliveryCompany'
      : 'bills.pay.date.deliveryOptions.price.dollarCheck';
  }

  return 'bills.pay.date.deliveryOptions.price.dollar';
};

type DeliveryExplanationLabelProps = {
  isCheck: boolean;
  deliveryOption: DeliveryOptionType;
  isChecksDelaysEnabled: boolean;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const getDeliveryExplanationLabel = ({
  isCheck,
  deliveryOption,
  isChecksDelaysEnabled,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: DeliveryExplanationLabelProps) => {
  if (isEligibleForFastAchAdoption) {
    if (isFastAchBetterExposeEtaEnabled) {
      return 'bills.pay.date.deliveryOptions.deliveryExplanationExperiment';
    }

    if (isSameDay(deliveryOption.deliveryDate)) {
      return 'bills.pay.date.deliveryOptions.sameDayDelivery';
    }

    if (isTomorrow(deliveryOption.deliveryDate)) {
      return 'bills.pay.date.deliveryOptions.nextDayDelivery';
    }

    return 'bills.pay.date.deliveryOptions.deliveryExplanationCheckPlain';
  }

  if (!isCheck) {
    return isFastAchBetterExposeEtaEnabled
      ? 'bills.pay.date.deliveryOptions.deliveryExplanationExperiment'
      : 'bills.pay.date.deliveryOptions.deliveryExplanation';
  }

  if (isChecksDelaysEnabled) {
    return isFastAchBetterExposeEtaEnabled
      ? 'bills.pay.date.deliveryOptions.deliveryExplanationExperiment'
      : 'bills.pay.date.deliveryOptions.deliveryExplanationCheckWithoutIndication';
  }

  return isFastAchBetterExposeEtaEnabled
    ? 'bills.pay.date.deliveryOptions.deliveryExplanationExperiment'
    : 'bills.pay.date.deliveryOptions.deliveryExplanationCheck';
};

export const getDeliveryOptionFee = (deliveryOption: DeliveryOptionType, fundingSourceType?: string) => {
  const isCheckOrAchDeliveryType =
    deliveryOption.type === DeliveryType.CHECK || deliveryOption.type === DeliveryType.ACH;
  const isCreditOrDebitCardFundingType =
    fundingSourceType === CardTypes.CREDIT || fundingSourceType === CardTypes.DEBIT;

  if (isCheckOrAchDeliveryType && isCreditOrDebitCardFundingType) {
    return null;
  }

  return deliveryOption?.fee?.[0]?.amount;
};
