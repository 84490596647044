import React from 'react';
import { CardComponentProps } from 'src/components/braze/types';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { Link } from 'src/core/ds/link';

export const TopBannerCard = ({ card, onClick, onClose }: CardComponentProps) => {
  const { title, description, imageUrl, linkText, url, extras } = card;
  const hasLink = url && linkText;
  const bgColor = extras?.color ? extras?.color : 'primary.400';

  const handleClick = () => {
    onClick(card);
  };
  const handleClose = () => {
    onClose(card);
  };

  return (
    <Flex onClick={handleClick} bgColor={bgColor} p={4} textStyle="body3" w="full">
      <Flex w="full" justify="center" alignItems="center" gap="1">
        {imageUrl && <Image src={imageUrl} w={24} h={24} marginRight={3} />}
        {title ? <Box as="span">{title}</Box> : null}
        {description ? <Box as="span">{description}</Box> : null}
        {hasLink ? (
          <Link href={url} target="_blank" color="black" textStyle="body3Semi" textDecorationLine="underline">
            {linkText}
          </Link>
        ) : null}
      </Flex>
      <Flex color="grey.700" _hover={{ color: 'black' }} justify="center" alignItems="center" cursor="pointer">
        <Icon name={IconNames.close} size={IconSize.s} onClick={handleClose} />
      </Flex>
    </Flex>
  );
};
