import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { NavigationProperties, withNavigator } from 'src/hoc';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { companiesLocations } from 'src/pages/companies/locations';
import { defineRelationshipLocations } from 'src/pages/onboarding/define-relationship/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getFundingSources, getIsAccountantFirm, getOrgId } from 'src/redux/user/selectors';
import { CompanyInfoOnboardingOrigin, CompanyType, FeatureFlags } from 'src/utils/consts';
import { CompanyAddedPageContainer } from './CompanyAddedPageContainer';
import CompanyAddressPageContainer from './CompanyAddressPageContainer';
import CompanyClientsAmountPage from './CompanyClientsAmountPage';
import { CompanyConnectedFromAccountingSoftware } from './CompanyConnectedFromAccountingSoftware';
import CompanyContactsPageContainer from './CompanyContactsPageContainer';
import { CompanyIndustryPage } from './CompanyIndustryPage';
import { CompanyIntentPage } from './CompanyIntentPage';
import { CompanyLegalInfoPage } from './CompanyLegalInfoPage';
import CompanyManualAddressPageContainer from './CompanyManualAddressPageContainer';
import CompanyMonthlyPaymentVolumePage from './CompanyMonthlyPaymentVolumePage';
import CompanyNamePageContainer from './CompanyNamePageContainer';
import CompanyTypePage from './CompanyTypePage';
import { withCompanyInfo } from './hoc/withCompanyInfoUpdates';
import NewCompanyInfoCombinedPage from './NewCompanyInfoCombinedPage';

const OnboardingEntryPoint = withNavigator()(({ locationState }: NavigationProperties<never, never>) => {
  const orgId = useSelector(getOrgId);
  const url = generatePath(onboardingLocations.companyInfo.type, { orgId });

  return <Redirect to={{ pathname: url, state: locationState }} />;
});

const useIndustryPageFeatureFlag = (isAccountantAddCompanyFlow: boolean) => {
  const [isIndustryOpenForAddClient] = featureFlags.useFeature<boolean>(
    'accountants-client-onboarding-select-industry-page',
    false
  );
  const isAccountantFirm = useSelector(getIsAccountantFirm);

  if (isAccountantFirm) {
    return false;
  }

  return (isAccountantAddCompanyFlow && isIndustryOpenForAddClient) || !isAccountantAddCompanyFlow;
};

const useLegalInfoPageFeatureFlag = (isAccountantAddCompanyFlow: boolean) => {
  const [isLegalInfoOpenForAddClient] = featureFlags.useFeature<boolean>(
    FeatureFlags.AccountantAddClientLegalInfo,
    false
  );

  return isAccountantAddCompanyFlow && isLegalInfoOpenForAddClient;
};

const useIndustryPrevStepUrl = (isAccountantAddCompanyFlow: boolean) => {
  const selectLegalInfoPageFeatureFlag = useLegalInfoPageFeatureFlag(isAccountantAddCompanyFlow);

  return selectLegalInfoPageFeatureFlag
    ? onboardingLocations.companyInfo.legalInfo
    : onboardingLocations.companyInfo.address;
};

const useLegalInfoNextStepUrl = (isAccountantAddCompanyFlow: boolean) => {
  const selectIndustryPageFeatureFlag = useIndustryPageFeatureFlag(isAccountantAddCompanyFlow);

  return selectIndustryPageFeatureFlag
    ? onboardingLocations.companyInfo.industry
    : onboardingLocations.companyInfo.monthlyPaymentsVolume;
};

const useAddressNextStepUrl = (companyType: CompanyType, isAccountantAddCompanyFlow: boolean) => {
  const selectLegalInfoPageFeatureFlag = useLegalInfoPageFeatureFlag(isAccountantAddCompanyFlow);
  const selectIndustryPageFeatureFlag = useIndustryPageFeatureFlag(isAccountantAddCompanyFlow);

  if (selectLegalInfoPageFeatureFlag) return onboardingLocations.companyInfo.legalInfo;

  if (selectIndustryPageFeatureFlag) return onboardingLocations.companyInfo.industry;

  return companyType === CompanyType.ACCOUNTING_FIRM
    ? onboardingLocations.companyInfo.clientsAmount
    : onboardingLocations.companyInfo.monthlyPaymentsVolume;
};

const useMonthlyPaymentVolumePrevStepUrl = (isAccountantAddCompanyFlow: boolean) => {
  const selectIndustryPageFeatureFlag = useIndustryPageFeatureFlag(isAccountantAddCompanyFlow);
  const selectLegalInfoPageFeatureFlag = useLegalInfoPageFeatureFlag(isAccountantAddCompanyFlow);

  if (selectIndustryPageFeatureFlag) return onboardingLocations.companyInfo.industry;

  return selectLegalInfoPageFeatureFlag
    ? onboardingLocations.companyInfo.legalInfo
    : onboardingLocations.companyInfo.address;
};

const DefineRelationshipEntryPoint = withNavigator()(({ locationState }: NavigationProperties<never, never>) => {
  const fundingSources = useSelector(getFundingSources);
  const organizationPreferences = useOrganizationPreferences();
  const isAccountantAddCompanyFlow =
    locationState.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY ||
    !!organizationPreferences.uninvitedOwnerEmail;
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();

  let url = isUnifiedOnboardingOpen ? onboardingLocations.companyInfo.intent : onboardingLocations.companyInfo.added;

  if (isAccountantAddCompanyFlow) {
    url = isEmpty(fundingSources)
      ? defineRelationshipLocations.addFundingSource
      : defineRelationshipLocations.requireApproval;
  }

  const prevStepURL = useMonthlyPaymentVolumePrevStepUrl(!!isAccountantAddCompanyFlow);

  return <CompanyMonthlyPaymentVolumePage prevStepURL={prevStepURL} nextStepURL={url} />;
});

export const companyInfoRoutes: {
  path: string;
  exact: boolean;
  render?: (any) => JSX.Element;
  component?: any;
}[] = [
  {
    path: onboardingLocations.companyInfo.index,
    exact: true,
    component: OnboardingEntryPoint,
  },
  {
    path: onboardingLocations.companyInfo.type,
    exact: true,
    render: (props) => <CompanyTypePage {...props} nextStepURL={onboardingLocations.companyInfo.contacts} />,
  },
  {
    path: onboardingLocations.companyInfo.intent,
    exact: true,
    render: (props) => (
      <CompanyIntentPage
        {...props}
        nextStepURL={onboardingLocations.vendorCompanyInfo.link}
        prevStepURL={onboardingLocations.companyInfo.monthlyPaymentsVolume}
      />
    ),
  },
  {
    path: onboardingLocations.companyInfo.contacts,
    exact: true,
    component: withCompanyInfo()(({ organizationPreferences, ...props }) => {
      let prevUrl = onboardingLocations.companyInfo.type;
      let nextUrl = onboardingLocations.companyInfo.name;
      const isAccountantAddCompanyFlow =
        props.location.state?.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY ||
        !!organizationPreferences.uninvitedOwnerEmail;

      if (isAccountantAddCompanyFlow) {
        prevUrl = companiesLocations.create.connectAccountingSoftware;
        nextUrl = onboardingLocations.companyInfo.address;
      }

      return (
        <CompanyContactsPageContainer
          {...props}
          companyInfoField="contacts"
          prevStepURL={prevUrl}
          nextStepURL={nextUrl}
          inputFields={['phone', 'contactFirstName', 'contactLastName']}
        />
      );
    }),
  },
  {
    path: onboardingLocations.companyInfo.name,
    exact: true,
    component: (props) => (
      <CompanyNamePageContainer
        {...props}
        companyInfoField="companyName"
        prevStepURL={onboardingLocations.companyInfo.contacts}
        nextStepURL={onboardingLocations.companyInfo.address}
        inputFields={['companyName']}
      />
    ),
  },
  {
    path: onboardingLocations.companyInfo.address,
    exact: true,
    component: withCompanyInfo()(({ companyType, organizationPreferences, ...props }) => {
      const isAccountantAddCompanyFlow =
        props.location.state?.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY ||
        !!organizationPreferences.uninvitedOwnerEmail;
      const nextStepURL = useAddressNextStepUrl(companyType, isAccountantAddCompanyFlow);
      const prevUrl = isAccountantAddCompanyFlow
        ? onboardingLocations.companyInfo.contacts
        : onboardingLocations.companyInfo.name;

      return (
        <CompanyAddressPageContainer
          {...props}
          companyInfoField="address"
          prevStepURL={prevUrl}
          nextStepURL={nextStepURL}
          inputFields={['googlePlaceId', 'zipCode']}
        />
      );
    }),
  },
  {
    path: onboardingLocations.companyInfo.addressManual,
    exact: true,
    component: withCompanyInfo()(({ companyType, organizationPreferences, ...props }) => {
      const isAccountantAddCompanyFlow =
        props.location.state?.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY ||
        !!organizationPreferences.uninvitedOwnerEmail;
      const nextStepURL = useAddressNextStepUrl(companyType, isAccountantAddCompanyFlow);

      return (
        <CompanyManualAddressPageContainer
          {...props}
          companyInfoField="address"
          prevStepURL={onboardingLocations.companyInfo.address}
          nextStepURL={nextStepURL}
          inputFields={['addressLine1', 'city', 'state', 'zipCode']}
        />
      );
    }),
  },
  {
    path: onboardingLocations.companyInfo.legalInfo,
    exact: true,
    component: withCompanyInfo()(({ organizationPreferences, ...props }) => {
      const isAccountantAddCompanyFlow =
        props.location.state?.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY ||
        !!organizationPreferences.uninvitedOwnerEmail;
      const nextStepURL = useLegalInfoNextStepUrl(isAccountantAddCompanyFlow);

      return (
        <CompanyLegalInfoPage
          {...props}
          nextStepURL={nextStepURL}
          prevStepURL={onboardingLocations.companyInfo.address}
        />
      );
    }),
  },
  {
    path: onboardingLocations.companyInfo.industry,
    exact: true,
    component: withCompanyInfo()(({ organizationPreferences, ...props }) => {
      const isAccountantAddCompanyFlow =
        props.location.state?.origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY ||
        !!organizationPreferences.uninvitedOwnerEmail;
      const prevNextUrl = useIndustryPrevStepUrl(isAccountantAddCompanyFlow);

      return (
        <CompanyIndustryPage
          {...props}
          prevStepURL={prevNextUrl}
          nextStepURL={onboardingLocations.companyInfo.monthlyPaymentsVolume}
        />
      );
    }),
  },
  {
    path: onboardingLocations.companyInfo.monthlyPaymentsVolume,
    exact: true,
    component: DefineRelationshipEntryPoint,
  },
  {
    path: onboardingLocations.companyInfo.clientsAmount,
    exact: true,
    render: (props) => (
      <CompanyClientsAmountPage
        {...props}
        prevStepURL={onboardingLocations.companyInfo.address}
        nextStepURL={onboardingLocations.companyInfo.added}
      />
    ),
  },
  {
    path: onboardingLocations.companyInfo.newCombined,
    exact: true,
    component: NewCompanyInfoCombinedPage,
  },
  {
    path: onboardingLocations.companyInfo.added,
    exact: true,
    render: (props) => <CompanyAddedPageContainer {...props} />,
  },
  {
    path: onboardingLocations.companyInfo.connectedFromAccountingSoftware,
    exact: true,
    render: (props) => <CompanyConnectedFromAccountingSoftware {...props} />,
  },
];
