export const CARD_NUMBER_CONFIGURATION = {
  placeholder: '13-19 digits',
};

export const CARD_EXPIRATION_DATE_CONFIGURATION = {
  placeholder: 'MM/YY',
};

export const CARD_VERIFICATION_CODE_CONFIGURATION = {
  placeholder: '3-4 digits',
};

export const ELEMENT_COMMON_STYLE = {
  base: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 'normal',
    backgroundColor: 'transparent',
    fontFamily: 'Poppins, sans-serif',
    color: '#212124',
    '::placeholder': {
      color: '#C2C2C3',
    },
  },
  invalid: {
    color: '#212124',
  },
};
