import 'url-polyfill';
import ReactDOM from 'react-dom';
import { App } from './App';
import { unregister } from './registerServiceWorker';
import { configTracking } from './utils/error-tracking';

configTracking();

ReactDOM.render(<App />, document.getElementById('root'));
unregister();
