import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FullPageLayout } from 'src/components/layout/FullPageLayout';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import { useApi } from 'src/hoc/useApi';
import invitationsStore from 'src/modules/invitations/invitations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { useFetchRelatedInvitations } from 'src/pages/team/team-management/hooks/useFetchRelatedInvitations';
import { PlaceholderTeamMemberCard } from 'src/pages/team/team-management/TeamMemberCard';
import { devices } from 'src/theme/appDevices';
import { InvitationType } from 'src/utils/types';
import { InvitationContainer } from './components/InvitationContainer';
import { InviteTeamMemberModal } from './components/modals/InviteTeamMemberModal';
import { TeamMemberContainer } from './components/TeamMemberContainer';
import { useUsersWithClients } from './hooks/useUsersWithClients';

export const TeamPage = () => {
  const { isDesktop } = useBreak();
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);
  const orgId = currentOrg?.id;
  const userActions = useStoreActions(usersStore);
  const invitationActions = useStoreActions(invitationsStore);
  const { users } = useUsersWithClients(orgId);
  const invitations: InvitationType[] = useSelector(invitationsStore.selectors.org(orgId).invitations);

  const isZeroState = users.length === 1 && invitations.length === 0;

  const [CreateInvitation, showCreateInvitation] = useModal(InviteTeamMemberModal, {
    orgId,
    modalName: 'inviteTeamMemberModal',
  });

  useFetchRelatedInvitations({
    invitationIds: (invitations || []).map((i) => i.id),
    orgId,
  });

  const allFetches = useCallback(
    async (orgId: number) =>
      Promise.all([
        invitationActions.list({ orgId }),
        userActions.userManagementListSlice({ orgId }).then((res) => {
          const userIds = res.payload?.map((user) => user.id);

          if (userIds.length) {
            userActions.allUserOrganizations({
              orgId,
              userIds,
            });
          }
        }),
      ]),
    [invitationActions, userActions]
  );

  const { onApiCall: fetchAll, loading: isLoading } = useApi({
    api: allFetches,
    initialLoading: true,
  });

  useEffect(() => {
    fetchAll(orgId);
  }, [fetchAll, orgId]);

  if (isLoading) {
    return (
      <FullPageLayout>
        <AreaLoader />
      </FullPageLayout>
    );
  }

  return (
    <FullPageLayout>
      <TeamPageContainer>
        {CreateInvitation}
        {isDesktop && (
          <HeaderContainer>
            <div>
              <Title>
                <MIFormattedText label="team.title" />
              </Title>
              <Subtitle>{currentOrg?.companyName}</Subtitle>
            </div>
            <NewMemberButton onClick={showCreateInvitation} />
          </HeaderContainer>
        )}
        <TeamMembersWrapper>
          {users.map((user) => (
            <TeamMemberContainer canManageClients={organizations?.length > 1} orgId={orgId} user={user} key={user.id} />
          ))}
          {invitations.map((invitation) => (
            <InvitationContainer
              organizations={organizations}
              invitation={invitation}
              orgId={orgId}
              key={invitation.id}
            />
          ))}
          {isZeroState && <PlaceholderTeamMemberCard />}
        </TeamMembersWrapper>
      </TeamPageContainer>
      {!isDesktop && (
        <ButtonContainer>
          <NewMemberButton onClick={showCreateInvitation} />
        </ButtonContainer>
      )}
    </FullPageLayout>
  );
};

function NewMemberButton({ onClick }) {
  return (
    <Button
      leftIcon={<Icon name={IconNames.plus} size={IconSize.s} />}
      onClick={onClick}
      size={ButtonSizes.md}
      width={['100%', null, null, 'auto']}
      label="team.actions.newMember"
    />
  );
}

const TeamPageContainer = styled.div`
  padding: 0 7% 7% 7%;
  max-width: 90rem;
  margin: 0 auto;

  @media ${devices.nonDesktop} {
    margin-bottom: 5rem;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 4rem;
`;

const Title = styled.div`
  font-size: 3.4rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  color: ${(props) => props.theme.text.color.main};
  line-height: 4.2rem;
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.medium}
`;

const TeamMembersWrapper = styled.div`
  display: block;

  > div {
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 1.6rem;
  box-shadow: 0 -1px 0 0 ${(props) => props.theme.text.color.readonly};
  background-color: ${(props) => props.theme.colors.white.opaque};
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  > a {
    justify-content: center;
    width: 100%;
  }
`;
