import React from 'react';
import { Button } from 'src/core/ds/button';
import { Image } from 'src/core/ds/image';
import signInWithIntuitSVG from 'src/images/accounting-software/sign-in-with-intuit.svg';

export type LoginWithIntuitProps = {
  onClick?: () => void;
};
export function LoginWithIntuitButton({ onClick }: LoginWithIntuitProps) {
  const intuitStyles = {
    backgroundColor: 'transparent',
    border: '2px',
    borderColor: 'intuit.500',
    color: 'intuit.500',
    height: '48px',
    _hover: {
      backgroundColor: 'blue.300',
    },
    _active: {
      backgroundColor: 'blue.400',
    },
  };

  return (
    <Button onClick={onClick} sx={intuitStyles}>
      <Image src={signInWithIntuitSVG} />
    </Button>
  );
}
