import React from 'react';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';
import { StatusBadge } from './CardStatusBadge';

export const StatusCell: React.FC<CellProps<any>> = ({ cell: { value } }) => (
  <Box isTruncated>
    <StatusBadge status={value} />
  </Box>
);
