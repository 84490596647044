import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';
import CreateBatchMapping from './create/batch/events-mapping';
import CreateMapping from './create/event-mapping';

const pageEvent = 'payment-request';

const MAPPING: EventMappingsTree = {
  '/orgs/*/get-paid': {
    'page.view': [pageEvent, 'requests-list'],
    'getPaid.emptyState.createLink.action': [pageEvent, 'go-link-set-up'],
    'getPaid.emptyState.requests.all.first.action': [pageEvent, 'go-create-payment-request'],
    'getPaid.emptyState.requests.all.notFirst.action': [pageEvent, 'go-create-payment-request'],
    'block-create-link': [pageEvent, 'show-block-create-link-modal', { toggle: 'toggle' }],
    'toggle-actions-menu': ['requests', 'toggle-actions-menu', { isOpen: 'isOpen' }],
    'getPaid.view.payment.delete': [pageEvent, 'delete-failed-payment'],
    'getPaid.view.paymentRequest.delete': [pageEvent, 'delete-payment-request'],
    'getPaid.view.paymentRequest.deleteMessage.confirm': [pageEvent, 'delete-payment-request-confirmed'],
    'getPaid.view.paymentRequest.deleteMessage.cancel': [pageEvent, 'delete-payment-request-canceled'],
    'getPaid.view.paymentRequest.deleteMessage.removeInvoice': [
      pageEvent,
      'delete-payment-request-with-invoice-toggle',
      { checked: 'checked' },
    ],
    'getPaid.view.invoice.delete': [pageEvent, 'delete-invoice'],
    'getPaid.view.invoice.deleteMessage.confirm': [pageEvent, 'delete-invoice-confirm'],
    'getPaid.view.invoice.deleteMessage.cancel': [pageEvent, 'delete-invoice-cancel'],
    'getPaid.view.paymentRequest.edit': [pageEvent, 'edit-payment-request'],
    'getPaid.view.invoice.edit': [pageEvent, 'edit-invoice'],
    'getPaid.edit.cancel': [pageEvent, 'edit-payment-request-cancel'],
    'getPaid.edit.save': [pageEvent, 'edit-payment-request-save'],
    'getPaid.edit.changeNotificationMessage.confirm': [pageEvent, 'edit-payment-request-confirm-notification'],
    'getPaid.edit.changeNotificationMessage.discard': [pageEvent, 'edit-payment-request-discard-notification'],
    ...mapApiSlice('[INVOICES] UPDATE', pageEvent, 'edit-payment-request'),
    ...mapApiSlice('[INVOICES] DELETE', pageEvent, 'delete-invoice'),
    ...mapApiSlice('[INVOICES] CANCEL', pageEvent, 'cancel-payment-request'),
    'bills.form.attachment.delete': [pageEvent, 'delete-invoice-file'],
    'bills.form.attachment.replace': [pageEvent, 'replace-invoice-file'],
    'bills.form.attachment.upload': [pageEvent, 'upload-invoice-file'],
    'getPaid.filter-selected': [pageEvent, 'filter-selected'],
    'getPaid.filter-toggle': [pageEvent, 'filter-toggle'],
    'requests.melioMeLinkCard.resendRequest': [pageEvent, 'resend-request'],
    ...mapApiSlice('[INVOICES] BATCH_SHARE', pageEvent, 'create-batch-unsent'),
    ...mapApiSlice('[INVOICES] BATCH_DELETE', pageEvent, 'delete-batch'),
    ...mapApiSlice('[INVOICES] BATCH_CANCEL', pageEvent, 'cancel-batch'),
    'getPaid.view.batch.delete.unsent': [pageEvent, 'remove-batch-unsnet-modal'],
    'getPaid.deleteInvoices.success': [pageEvent, 'remove-batch-unsnet-success'],
    'getPaid.deleteInvoices.error': [pageEvent, 'remove-batch-unsnet-failed'],
    'getPaid.view.batch.deleteMessage.unsent.cancel': [pageEvent, 'cancel-remove-batch-unsent'],
    'getPaid.view.batch.deleteMessage.sent.cancel': [pageEvent, 'cancel-delete-batch-sent'],
    'copy-link': [pageEvent, 'link-copied'],
    'copy-link-action': [pageEvent, 'meliome-link-clicked'],
    'fab.newFab.get-paid': ['fab', 'new-invoice-button'],
    'toggle-search-input': ['invoices', 'toggle-search-input', { isOpen: 'isOpen' }],
    'search-in-get-paid': ['search', '-in-invoice-list', { searchValue: 'searchValue' }],
  },
  ...CreateMapping,
  ...CreateBatchMapping,
};

export default MAPPING;
