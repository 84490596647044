const baseStyle = {
  top: 0,
  left: 0,
  width: 'full',
  height: '10.4rem',
  position: 'fixed',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  paddingInline: 10,
  background: 'grey.200',
};

export default {
  baseStyle,
};
