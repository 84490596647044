export const ROLE_TAGS = {
  OWNER: 'owner',
  ADMIN: 'admin',
  CONTRIBUTOR: 'contributor',
  ACCOUNTANT: 'accountant',
  DELETED: 'deleted',
  ACCOUNTING_FIRM_OWNER: 'accountingFirmOwner',
  ACCOUNTING_FIRM_ADMIN: 'accountingFirmAdmin',
  PENDING: 'pending',
} as const;
