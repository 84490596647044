import * as React from 'react';
import { ModalButtonsAlwaysDocked, ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';

export const InnerScrollModalMessage = ({
  titleComponent,
  contentComponent,
  buttonComponent,
  ...props
}: React.ComponentProps<typeof ModalMessage>) => (
  <ModalMessage
    titleComponent={titleComponent}
    contentComponent={
      <Box
        mb={5}
        minHeight={['auto', null, '26rem']}
        maxHeight={['auto', null, '34rem']}
        overflowY={['initial', null, 'auto']}
      >
        {contentComponent}
      </Box>
    }
    buttonComponent={<ModalButtonsAlwaysDocked>{buttonComponent}</ModalButtonsAlwaysDocked>}
    {...props}
  />
);
