import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { RepaymentTermsDateLoading } from 'src/pages/bill/pay/installments/pages/repayment-terms/RepaymentTermsDateLoading';

type Props = {
  isLoading: boolean;
  firstInstallmentDate: Date | null;
};
export const FirstInstallmentDeductionDate = ({ isLoading, firstInstallmentDate }: Props) => (
  <>
    <Box textStyle="body4Semi" textColor="grey.600">
      <MIFormattedText label="financing.repaymentTerms.date.firstInstallmentLabel" />
    </Box>
    {isLoading ? (
      <Box mt={1}>
        <RepaymentTermsDateLoading />
      </Box>
    ) : (
      <>
        <Box textStyle="body2" textColor="black" mt={1} data-testid="repayment-terms-first-installment-deduction-date">
          <MIFormattedDate date={firstInstallmentDate} />
        </Box>
        <Box textStyle="caption1" textColor="grey.700" mt={2}>
          <MIFormattedText label="financing.repaymentTerms.date.firstInstallmentDescription" />
        </Box>
      </>
    )}
  </>
);
