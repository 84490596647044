/* eslint-disable no-restricted-globals, no-console */
import get from 'lodash/get';

const postMessage = (data: Record<string, any>, origin?: string) => {
  if (window.opener) {
    window.opener.postMessage({ ...data, source: 'melio-popup' }, origin || '*');
  } else if (parent !== window && get(window, 'parent.postMessage')) {
    parent.postMessage({ ...data, source: 'melio-iframe' }, origin || '*');
  } else {
    console.error('Could not post message', { ...data, origin });
  }
};
const melioLoaded = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-loaded',
  });
};
const melioClose = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-close',
  });
};
const paymentSuccess = (originId?: string) => {
  postMessage({
    instanceId: 1,
    type: 'payment-success',
    originId,
  });
};
const paymentListSuccess = () => {
  // TODO check if we need a new message or use paymentSuccess
  postMessage({
    instanceId: 1,
    type: 'payment-list-success',
  });
};

const newPayment = () => {
  postMessage({
    instanceId: 1,
    type: 'new-payment',
  });
};
const paymentDeleted = () => {
  postMessage({
    instanceId: 1,
    type: 'payment-deleted',
  });
};
const melioError = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-error',
  });
};
const blockedUser = () => {
  postMessage({
    instanceId: 1,
    type: 'blocked-user',
  });
};
const billNotFound = () => {
  postMessage({
    instanceId: 1,
    type: 'bill-not-found',
  });
};
function matchDomain(url, allowedFor) {
  const { host, origin } = new URL(url);
  for (const match of allowedFor) {
    if (host.endsWith(match)) {
      return origin;
    }
  }

  return null;
}
function getParentUrl() {
  let url: string | null = null;
  try {
    url = window.parent?.location?.href || window.opener?.location?.href;
    // eslint-disable-next-line no-empty
  } catch (e) {}

  if (!url) {
    url = document.referrer;
  }

  return url;
}
const setAuthToken = (token: string, allowedFor: string[]) => {
  const host = matchDomain(getParentUrl(), allowedFor);

  if (host) {
    postMessage(
      {
        instanceId: 1,
        type: 'melio-auth-token',
        token,
        secure: true,
      },
      host
    );
  } else {
    console.error(
      `referrer does not match domain. referrer- ${document.referrer}. allowed for - ${allowedFor.join(',')}`
    );
  }
};
const replaceFrameSrc = (src: string) => {
  postMessage({
    instanceId: 1,
    type: 'replace-frame-src',
    src,
  });
};
const qboRefuseConnect = () => {
  postMessage({
    instanceId: 1,
    type: 'refuse-connect',
  });
};
const logOut = () => {
  postMessage({
    instanceId: 1,
    type: 'logout',
  });
};

const isFirstQBOPage = () => {
  const currentState = window.history.state;

  return currentState?.state?.name === 'initialState';
};

const redirectToContractors = () => {
  postMessage({
    instanceId: 1,
    type: 'melio-redirect-to-contractors',
  });
};

export {
  billNotFound,
  blockedUser,
  isFirstQBOPage,
  logOut,
  melioClose,
  melioError,
  melioLoaded,
  newPayment,
  paymentDeleted,
  paymentListSuccess,
  paymentSuccess,
  qboRefuseConnect,
  redirectToContractors,
  replaceFrameSrc,
  setAuthToken,
};
