import * as dateFns from 'date-fns';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { CardTypes, DeliveryType, PaymentApprovalStatus } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import { DeliveryMethodType } from 'src/utils/types';
import { getFullName } from 'src/utils/user';
import dateFromModelValue from '../dateFromModelValue';
import type { IntlProps, PaymentReportType } from './types';

export function calcCheckDepositStatus(intl: IntlProps, payment: PaymentReportType): string {
  let id = '';

  if (payment.checkDeposited) {
    id = 'bills.csvReport.report.checkDeposited';
  } else if (payment.deliveryMethod?.deliveryType === 'check' && payment.status === 'completed') {
    id = 'bills.csvReport.report.checkSent';
  }

  return id ? intl.formatMessage({ id }) : '';
}
const virtualDeliveryTypeReplacement = 'pending vendor';
const deliveryTypes = [DeliveryType.ACH, DeliveryType.CHECK, virtualDeliveryTypeReplacement, DeliveryType.CARD];
export function calcDeliveryMethod(intl: IntlProps, deliveryMethod?: DeliveryMethodType | null) {
  if (!deliveryMethod?.deliveryType || !deliveryTypes.includes(deliveryMethod.deliveryType)) {
    return '';
  }

  return intl.formatMessage({
    id: `bills.csvReport.report.deliveryMethods.${deliveryMethod.deliveryType}`,
  });
}

export function calcDisplayName(item?: { firstName?: string; lastName?: string } | null): string {
  return getFullName(item?.firstName, item?.lastName);
}

export function calcFundingSource(intl: IntlProps, payment, hasDebitInBankAccounts?: boolean) {
  const { fundingSource } = payment;
  let description = '';

  if (fundingSource?.bankAccount) {
    const displayName = fundingSource?.displayName || '';
    const institutionName = fundingSource?.plaidAccount?.plaidItem?.institutionName || '';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);

    if (institutionName) {
      description = `${displayName} (${institutionName}, ...${cutAccountNumber})`;
    } else {
      description = `${displayName} (...${cutAccountNumber})`;
    }
  } else if (fundingSource?.cardAccount) {
    const cardType = fundingSource.cardAccount.cardType || '';
    description = `${fundingSource.cardAccount.network} (...${fundingSource.cardAccount.card4digits})`;

    if (cardType === CardTypes.DEBIT && hasDebitInBankAccounts) {
      description = intl.formatMessage(
        { id: 'viewBillPaymentActivity.fundingSource.debitCardDescription' },
        { card4digits: fundingSource.cardAccount.card4digits }
      );
    }
  }

  return description;
}

export function formatDate(value?: string | null): string {
  return value ? dateFns.format(dateFromModelValue(value), DateFormats.isoDate) : '';
}

export function calcApprovers({
  approvalDecisionStatus,
  approvalDecisionBy,
  paymentApprovalDecisions,
}: Pick<PaymentReportType, 'approvalDecisionStatus' | 'approvalDecisionBy' | 'paymentApprovalDecisions'>) {
  if (approvalDecisionStatus === PaymentApprovalStatus.APPROVED) {
    return (
      paymentApprovalDecisions.map((decision) => calcDisplayName(decision.user)).join(', ') ||
      calcDisplayName(approvalDecisionBy)
    );
  }

  return '';
}
