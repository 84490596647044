export const teamPageActionNotificationMapping = {
  '[INVITATIONS] BATCH_CREATE_SUCCESS': {
    type: 'success',
    msg: 'team.notifications.invite.success',
  },
  '[INVITATIONS] BATCH_CREATE_FAILURE': {
    type: 'error',
    msg: 'team.notifications.invite.failure',
  },
  '[USERS] UPDATE_USER_ORGANIZATIONS_FAILURE': {
    type: 'error',
    msg: 'team.notifications.manageClients.failure',
  },
  '[USERS] UPDATE_USER_ORGANIZATIONS_SUCCESS': {
    type: 'success',
    msg: 'team.notifications.manageClients.success',
  },
};
