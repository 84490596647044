import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { companyContactParams } from 'src/pages/companies/invite-new-company/NewCompanyContactsInfoPageContainer';
import { ModelView } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';
import { EmailAlreadyExistModal } from './EmailAlreadyExistModal';

type Props = {
  onPrev: () => void;
  onSubmit: () => void;
  model: ModelView<companyContactParams>;
  companyInfo: any;
  companyName: string;
  goExit: () => void;
  isLoading: boolean;
  showExistEmailModal: boolean;
  onModalDismiss: (res: any) => any;
  createJoinRequest: () => void;
  isOwner: boolean;
};

export const NewCompanyContactsInfoPage = ({
  onPrev,
  onSubmit,
  model,
  goExit,
  companyName,
  isLoading,
  isOwner,
  showExistEmailModal,
  onModalDismiss,
  companyInfo,
}: Props) => (
  <>
    {showExistEmailModal && <EmailAlreadyExistModal joinOrgRequest={companyInfo} dismiss={onModalDismiss} />}
    <StepLayoutPage
      title={isOwner ? 'inviteNewCompany.contactInfo.title' : 'inviteNewCompany.contactInfo.notOwned.title'}
      titleValues={{
        companyName: <Title key={companyName}>{checkApostropheForPluralPossessive(companyName)}</Title>,
      }}
      subtitle={isOwner ? 'inviteNewCompany.contactInfo.subtitle' : 'inviteNewCompany.contactInfo.notOwned.subtitle'}
      relativeStep={2 / 2}
      onNext={onSubmit}
      onPrev={onPrev}
      goExit={goExit}
      fullWidthCTA
      isLoading={isLoading}
      nextLabel={isOwner ? 'inviteNewCompany.contactInfo.cta' : 'inviteNewCompany.contactInfo.notOwned.cta'}
    >
      <>
        <FormContainer>
          {!isOwner && (
            <RowContainer>
              <TextField
                label="inviteNewCompany.contactInfo.notOwned.emailLabel"
                model={model.email}
                type="email"
                isRequired
                autoFocus
              />
            </RowContainer>
          )}
          <RowContainer>
            <TextField
              label={
                isOwner
                  ? 'inviteNewCompany.contactInfo.contactFirstNameLabel'
                  : 'inviteNewCompany.contactInfo.notOwned.ownerContactFirstNameLabel'
              }
              model={model.contactFirstName}
              isRequired
            />
            <TextField
              label={
                isOwner
                  ? 'inviteNewCompany.contactInfo.contactLastNameLabel'
                  : 'inviteNewCompany.contactInfo.notOwned.ownerContactLastNameLabel'
              }
              model={model.contactLastName}
              isRequired
            />
          </RowContainer>
          <RowContainer>
            <MaskField
              label={
                isOwner
                  ? 'inviteNewCompany.contactInfo.phoneLabel'
                  : 'inviteNewCompany.contactInfo.notOwned.ownerPhoneLabel'
              }
              model={model.phone}
              format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
              isRequired
              isModelOnChangeFormatted
            />
          </RowContainer>
        </FormContainer>
      </>
    </StepLayoutPage>
  </>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 4rem;
  }
`;

const RowContainer = styled(FormRow)``;

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;
