import React from 'react';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { unsupportedAccountingSoftwareOptions } from 'src/pages/get-paid/create/connectAccountingSoftware/consts';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { accountingSoftwareDisplayName } from 'src/pages/settings/components/accounting-software/consts';
import { AccountingSoftware } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  onPrev: () => void;
  onExit: () => void;
};

export const ConnectUnsupportedAccountingSoftwareSuccessPage = ({ onExit, onPrev }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const [selectedAccountingSoftware] = useLocationState('accountingSoftware');
  const unsupportedAccountingSoftware = unsupportedAccountingSoftwareOptions.find(
    ({ accountingSoftware }) => selectedAccountingSoftware === accountingSoftware
  );

  const accountingSoftwareType = selectedAccountingSoftware as AccountingSoftware;
  const displayName = accountingSoftwareDisplayName[accountingSoftwareType]?.displayName || selectedAccountingSoftware;
  const displayShortName =
    accountingSoftwareDisplayName[accountingSoftwareType]?.displayShortName || selectedAccountingSoftware;
  const isSupportedSoon = unsupportedAccountingSoftware?.isSupportedSoon;

  const title = isSupportedSoon
    ? 'getPaid.new.create.connect.unsupportedAccountingPlatform.success.supportedSoon.title'
    : 'getPaid.new.create.connect.unsupportedAccountingPlatform.success.title';

  const subtitle = isSupportedSoon
    ? 'getPaid.new.create.connect.unsupportedAccountingPlatform.success.supportedSoon.subTitle'
    : 'getPaid.new.create.connect.unsupportedAccountingPlatform.success.subTitle';

  const onNext = () => {
    historyPush({
      path: getPaidLocations.create.manually,
    });
  };

  return (
    <StepLayoutPage
      relativeStep={0}
      goExit={onExit}
      onPrev={onPrev}
      title={title}
      titleValues={{ accountingSoftware: displayName }}
      subtitle={subtitle}
      subTitleValues={{ accountingSoftware: displayShortName }}
      innerSize={75}
      onNext={onNext}
      nextLabel="getPaid.new.create.connect.unsupportedAccountingPlatform.success.addInvoices"
      onSecondaryActionButtonClick={onExit}
      secondaryActionButtonLabel="getPaid.new.create.connect.unsupportedAccountingPlatform.success.goToMainPage"
      illustration={IllustrationName.thumbUp}
    />
  );
};
