import { useSelector } from 'react-redux';
import invitationStore from 'src/modules/invitations/invitations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { OrganizationWithPermission } from 'src/pages/team/types';
import { CompanyInfoType, UserContextType, UserOrganization } from 'src/utils/types';

export const useStoreData = () => {
  const currentOrg: CompanyInfoType = useSelector(profileStore.selectors.getCurrentOrg);
  const actor: UserContextType = useSelector(profileStore.selectors.profile);
  const actorUserOrg: UserOrganization = useSelector(profileStore.selectors.getUserOrganization);
  const organizations: OrganizationWithPermission[] = useSelector(profileStore.selectors.getOrganizations);
  const isBatchCreating: boolean = useSelector(invitationStore.selectors.batchCreate.loading);
  const isValidating: boolean = useSelector(invitationStore.selectors.validateCreate.loading);

  return {
    currentOrg,
    actor,
    actorUserOrg,
    organizations,
    isBatchCreating,
    isValidating,
  };
};
