import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { useGetFundingSourceDisplayName } from 'src/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import { CardTypes, FundingType } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSource: AccountType;
  accountingPlatformName: string | undefined;
  goNext: () => void;
};

export const FirstAccountingPlatformAccountAddedSuccessPage = ({
  fundingSource,
  goNext,
  accountingPlatformName,
}: Props) => {
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const fundingSourceType =
    fundingSource?.fundingType === FundingType.ACH ? fundingSource?.fundingType : fundingSource?.cardAccount?.cardType;
  let fundingTypeText = 'onboarding.fundingSources.bank.accountingPlatformAccounts.ach';

  if (fundingSourceType === CardTypes.DEBIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.accountingPlatformAccounts.debitAccount';
  } else if (fundingSourceType === CardTypes.CREDIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.accountingPlatformAccounts.creditAccount';
  }

  return (
    <SuccessLayoutPage
      titleValues={{
        type: <MIFormattedText label={fundingTypeText} />,
        accountingPlatformName: <MIFormattedText label={accountingPlatformName} />,
      }}
      title="onboarding.fundingSources.bank.accountingPlatformAccounts.success.title"
      text="onboarding.fundingSources.bank.accountingPlatformAccounts.success.subTitle"
      textValues={{
        displayName: getFundingSourceDisplayName({ fundingSource, useShortName: false }),
        accountingPlatformName: <MIFormattedText label={accountingPlatformName} />,
      }}
      buttonLabel="onboarding.fundingSources.bank.accountingPlatformAccounts.success.cta"
      buttonValues={{ type: <MIFormattedText label={fundingTypeText} /> }}
      buttonAction={goNext}
      hideHeader
    />
  );
};
