import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { billLocations } from 'src/pages/bill/locations';
import { usePayBillActions } from 'src/pages/bill/pay/hooks/usePayBillActions';
import { payBillEventPage } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { getBill, getExitUrl, getIsRecurring, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, getBillsDefaultFilters, serializePaymentId } from 'src/utils/bills';
import { BillStatus } from 'src/utils/consts';
import { hasFailedInstallment } from 'src/utils/financing';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { NavigateType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
};

export const useGoExitPage = ({ navigate }: Params) => {
  const location = useLocation();
  const site = useSiteContext();
  const device = useBreak();

  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const payment = useSelector(getPayment);
  const isRecurring = useSelector(getIsRecurring);
  const exitUrl = useSelector(getExitUrl);

  const { endPayBillFlow } = usePayBillActions();

  const goExit = () => {
    const { id, status } = bill;
    const eventName = `set-${getEventNameFromLocation(location)}`;
    const defaultFilters = getBillsDefaultFilters(status);

    if (site.embeddedMode && device.isMobile) {
      navigate(generatePath(billLocations.filteredViewWithoutId, { orgId, ...defaultFilters }));

      return;
    } else if (site.embeddedMode && exitUrl) {
      endPayBillFlow(false, true);
      navigate(exitUrl);

      return;
    }

    endPayBillFlow(true);

    if (!site.embeddedMode) {
      if (isRecurring) {
        analytics.track(payBillEventPage, `recurring-${eventName}-exit`, {
          billPaymentIndex: getBillPaymentIndex(bill),
        });
        navigate(billLocations.index);
      } else {
        analytics.track(payBillEventPage, `${eventName}-exit`, {
          billPaymentIndex: getBillPaymentIndex(bill),
        });
        let url = generatePath(billLocations.view, { orgId, id });

        if (payment?.id) {
          const filters = {
            ...defaultFilters,
            ...(hasFailedInstallment(payment) ? { status: BillStatus.UNPAID, sorting: 'payment.status,dueDate' } : {}),
          };

          url = generatePath(billLocations.filteredView, {
            orgId,
            id: serializePaymentId(bill.id, payment.id),
            ...filters,
          });
        }

        navigate(url);
      }
    }
  };

  return [goExit];
};
