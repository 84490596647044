import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import customersStore from 'src/modules/customers/customers-store';
import { getOrgId } from 'src/redux/user/selectors';

const useCustomer = (customerId) => {
  const customerActions = useStoreActions(customersStore);
  const orgId = useSelector(getOrgId);
  const customer = useSelector(customersStore.selectors.fetch.byId(customerId));
  useEffect(() => {
    if (customerId) {
      customerActions.fetch({ orgId, id: customerId });
    }
  }, [customerActions, customerId, orgId]);

  return [customer];
};

export default useCustomer;
