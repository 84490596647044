import { isValidationOk } from '@melio/sizzers-js-common';
import { ChangeEvent, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserExistErrorNotificationCard } from 'src/components/common/UserExistNotificationCard';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { useApi } from 'src/hoc/useApi';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { authLocations } from 'src/pages/auth/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import clientServiceApi from 'src/services/api/clientService';
import guestApi from 'src/services/api/guests';
import { useForm } from 'src/ui/form';
import { isEnterPressed } from 'src/utils/events';
import { useQueryString } from 'src/utils/hooks';

export const MSNPortalRegisterPage = () => {
  const queryParams = useQueryString();
  const location = useLocation<{ email: string }>();
  const [errorCode, setErrorCode] = useState('');
  const [email, setEmail] = useState(queryParams?.email || location.state?.email || '');
  const analyticsData = { msnOptinFlow: true, flows: 'public' };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const { navigate } = usePreservedStateNavigator();

  const model = useMemo(() => ({ email }), [email]);

  const [formModel, { submit }] = useForm(model, {
    submit: ({ email }) => onRegister(email.trim().toLocaleLowerCase()),
  });
  const onKeyPress = (event: KeyboardEvent) => {
    if (isEnterPressed(event)) {
      event.stopPropagation();
      submit();
    }
  };

  const { onApiCall: onSendVerificationCodeCall, loading: isVerificationCodeLoading } = useApi<
    [string],
    { code: string; message: string }
  >({
    api: guestApi.sendEmailVerificationCode,
  });

  const { onApiCall: onEmailValidationCall, loading: isEmailValidationLoading, result: emailValidationResult } = useApi<
    [string, { [key: string]: string }, string[]],
    Partial<{ validationErrors: any }>
  >({
    api: clientServiceApi.getValidationErrors,
  });
  const isLoading = isEmailValidationLoading || isVerificationCodeLoading;

  useEffect(() => {
    if (emailValidationResult) {
      const { validationErrors } = emailValidationResult;
      formModel.setValidationErrors(validationErrors);
    }
  }, [emailValidationResult, formModel]);

  const onLogin = () => {
    navigate(authLocations.login, false);
  };

  const onRegister = async (email: string): Promise<void> => {
    setErrorCode('');
    const { validationErrors } = await onEmailValidationCall('guest', { email }, ['email']);

    if (isValidationOk(validationErrors)) {
      const { code } = await onSendVerificationCodeCall(email);
      const {
        email: { value },
      } = formModel;

      if (code === 'OK') {
        navigate(onboardingLocations.msnRegistration.emailVerification, false, { email: value });
      }
    } else {
      analytics.trackAction('msnPortalRegister.signup.validation.error', analyticsData);
    }
  };

  return (
    <RegisterLayoutPage
      title="auth.msnPortalRegistration.title"
      text="auth.msnPortalRegistration.subtitle"
      testId="msn-register"
      isLoading={isLoading}
    >
      {errorCode ? <UserExistErrorNotificationCard errorCode={errorCode} onLogin={onLogin} preserveState /> : null}

      <TextField
        testId="msn-portal-register-email"
        label="auth.msnPortalRegistration.emailLabel"
        model={formModel.email}
        onKeyPress={onKeyPress}
        type="email"
        onChange={handleEmailChange}
        value={email}
        isRequired
        privateData
        autoFocus
      />

      <Box textAlign="center" pb="5">
        <Button
          label="auth.msnPortalRegistration.continue"
          analyticsProperties={analyticsData}
          onClick={submit}
          size={ButtonSizes.lg}
          width={{ base: '100%', sm: 'auto' }}
          isLoading={isLoading}
        />
      </Box>
    </RegisterLayoutPage>
  );
};
