import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { TableOptions, usePagination, useSortBy, useTable } from 'react-table';
import { Loader } from 'src/components/common/Loader';
import { TableHeaderGroup } from 'src/components/shared/tables/PaymentsTable/components/TableHeaderGroup';
import { TableRow } from 'src/components/shared/tables/PaymentsTable/components/TableRow';
import { usePagination as useCustomPagination } from 'src/core/components/pagination/hooks/usePagination';
import { Pagination } from 'src/core/components/pagination/Pagination';
import Box from 'src/core/ds/box';
import Table from 'src/core/ds/table';
import { RestListStatus } from 'src/helpers/redux/restListSlice';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { msnPortalLocations } from 'src/pages/msn-portal/locations';
import { useQueryString } from 'src/utils/hooks';
import { DEFAULT_FILTERS_VALUES } from './consts';
import { EmptyState } from './EmptyState';
import { emptyStateStyle, getContainerStyle, paginationStyle } from './styles';
import { convertQueryStringToSortBy, convertSortByToQueryString, getSortBy } from './utils';

export const DataTable = <T extends Record<string, unknown>>({
  data,
  columns,
  loading,
  totalItems,
  onOpenDrawer,
  getPaymentsInList,
}: TableOptions<T> & RestListStatus): ReactElement => {
  const currentQueryParams = useQueryString();
  const [historyPush] = useHistoryWithOrgId();

  const { start, limit, sort } = useMemo(
    () => ({
      sort: currentQueryParams.sort || DEFAULT_FILTERS_VALUES.sort,
      start: Number(currentQueryParams.start) || DEFAULT_FILTERS_VALUES.start,
      limit: Number(currentQueryParams.limit) || DEFAULT_FILTERS_VALUES.limit,
    }),
    [currentQueryParams]
  );

  const { rows, headerGroups, getTableProps, getTableBodyProps, prepareRow, setSortBy } = useTable<T>(
    {
      data,
      columns,
      autoResetSortBy: false,
      disableSortBy: true,
      disableMultiSort: true,
      manualSortBy: true,
      initialState: {
        sortBy: convertQueryStringToSortBy(sort),
      },
    },
    useSortBy,
    usePagination
  );

  const setFilters = useCallback(
    (filters) =>
      historyPush({
        path: msnPortalLocations.base,
        query: { ...DEFAULT_FILTERS_VALUES, ...currentQueryParams, ...filters },
      }),
    [currentQueryParams, historyPush]
  );

  const { canNextPage, canPreviousPage, previousPage, nextPage, showPagination, pageIndex } = useCustomPagination({
    start,
    limit,
    totalItems,
    setPage: (page) => setFilters({ start: page * limit }),
  });

  const tableProps = useMemo(() => getTableProps(), [getTableProps]);
  const tableBodyProps = useMemo(() => getTableBodyProps(), [getTableBodyProps]);
  const containerStyle = useMemo(() => getContainerStyle(showPagination), [showPagination]);

  const handleOpenDrawer = (rowData) => onOpenDrawer(rowData.original.invoice);

  const handleManualSort = useCallback(
    (column) => {
      const newSortBy = getSortBy({ column, sortBy: convertQueryStringToSortBy(sort) });

      setSortBy(newSortBy);
      setFilters({ sort: convertSortByToQueryString(newSortBy) });
    },
    [setFilters, setSortBy, sort]
  );

  useEffect(() => {
    getPaymentsInList({ sort, limit, start });
  }, [getPaymentsInList, limit, sort, start]);

  return (
    <Box __css={containerStyle}>
      {showPagination && (
        <Box __css={paginationStyle}>
          <Pagination
            pageIndex={pageIndex}
            totalItems={totalItems}
            pageItemCount={limit}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
          />
        </Box>
      )}
      <Table {...tableProps} cellPadding="0" cellSpacing="0">
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <TableHeaderGroup disableCheckBoxes headerGroup={headerGroup} onClick={handleManualSort} />
          ))}
        </Table.Header>
        {totalItems > 0 && !loading ? (
          <Table.Body {...tableBodyProps}>
            {rows.map((row) => (
              <TableRow disableCheckBoxes row={row} prepareRow={prepareRow} onClick={handleOpenDrawer} />
            ))}
          </Table.Body>
        ) : (
          <Table.Caption>
            <Box __css={emptyStateStyle}>{loading ? <Loader color="primary" context="page" /> : <EmptyState />}</Box>
          </Table.Caption>
        )}
      </Table>
    </Box>
  );
};
