import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';

export const invitationsApi = {
  fetch(params) {
    const url = `/orgs/${params.orgId}/invitations/${params.invitationId}`;

    return fetchRequest(url);
  },
  update(params) {
    const url = `/orgs/${params.orgId}/invitations/${params.id}`;

    return putRequest(url, params).then((res) => res.invitation);
  },
  list(params) {
    const url = `/orgs/${params.orgId}/invitations`;

    return fetchRequest(url).then((res) => ({ items: res.invitations }));
  },
  create(params) {
    const { orgId, ...payload } = params;
    const url = `/orgs/${orgId}/invitations`;

    return postRequest(url, payload).then((res) => res.invitation);
  },
  delete(params) {
    const url = `/orgs/${params.orgId}/invitations/${params.id}`;

    return deleteRequest(url).then((res) => res.invitations);
  },
  validateInvitation(params) {
    return fetchRequest('/auth/invitation/validate', params);
  },
  relatedInvitations({ orgId, ...params }) {
    return fetchRequest(`/orgs/${orgId}/invitations/related-invitations`, params).then((res) => res.relatedInvitations);
  },
  validateCreate(params) {
    return fetchRequest(`/orgs/${params.orgId}/invitations/validate-create`, params);
  },
  batchCreate(params) {
    const { orgId, ...payload } = params;
    const url = `/orgs/${orgId}/invitations/batch`;

    return postRequest(url, payload).then((res) => res.invitations);
  },
  inviteeOrganizations({ token }: { token: string }) {
    return fetchRequest('/auth/invitation/get-invitee-orgs', { token });
  },
};
