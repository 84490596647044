import noop from 'lodash/noop';
import { MouseEvent, useEffect, useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Tooltip from 'src/core/ds/tooltip';
import { useBreak } from 'src/hoc';
import { ChecksDelaysTooltipTypes } from 'src/utils/consts';

type Props = {
  type: ChecksDelaysTooltipTypes;
  tooltipLabel: string;
  disabled?: boolean;
  sx?: CSSObject;
};

const getIcon = (type: ChecksDelaysTooltipTypes) => {
  switch (type) {
    case ChecksDelaysTooltipTypes.INFO:
      return 'icon-info-hollow-icon';
    case ChecksDelaysTooltipTypes.INFO_DEFAULT:
      return 'icon-info-hollow-icon';
    default:
      return 'icon-warning-tri-icon';
  }
};

const getColor = (type: ChecksDelaysTooltipTypes) => {
  switch (type) {
    case ChecksDelaysTooltipTypes.INFO:
      return 'blue.500';
    case ChecksDelaysTooltipTypes.INFO_DEFAULT:
      return 'black';
    default:
      return 'yellow.500';
  }
};

const TOOLTIP_OPEN_DURATION = 3000;

export const ChecksDelaysTooltip = ({ type, tooltipLabel, disabled, sx }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const device = useBreak();
  const shouldOpenOnTap = device.isMobile || device.isPhablet;

  const openTooltip = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isOpen) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, TOOLTIP_OPEN_DURATION);

      return () => clearTimeout(timer);
    }

    return noop;
  }, [isOpen]);

  return (
    <Tooltip
      isDisabled={disabled}
      placement="top"
      maxWidth="max"
      textAlign="start"
      label={<MIFormattedText label={tooltipLabel} />}
      isOpen={shouldOpenOnTap ? isOpen : undefined}
      data-testid="checks-delays-tooltip"
    >
      <Box
        as="i"
        fontSize="1.6rem"
        px={1}
        color={getColor(type)}
        data-testid="checks-delays-tooltip-trigger"
        onClick={shouldOpenOnTap ? openTooltip : noop}
        sx={sx}
      >
        <i className={getIcon(type)} />
      </Box>
    </Tooltip>
  );
};
