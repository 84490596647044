import { endOfDay, formatISO, startOfDay, subDays } from 'date-fns';
import { InvoiceType } from 'src/modules/invoices/types';
import { pushNotification } from 'src/services/notifications/notificationService';
import {
  BillStatus,
  InvoiceStatus,
  NotificationVariant,
  PAGINATION,
  PaymentRequestActions,
  PaymentRequestStatus,
  PaymentRequestTabs,
  PaymentStatus,
} from 'src/utils/consts';
import { convertLimit, convertStart } from 'src/utils/pagination-utils';
import { encodeQuery } from 'src/utils/query-utils';
import { getDefaultTabSetting } from 'src/utils/tabs-utils';

const getInvoiceTab = (invoice: InvoiceType) => {
  switch (invoice.status) {
    case InvoiceStatus.SCHEDULED:
      return PaymentRequestTabs.SCHEDULED;
    case InvoiceStatus.PAID:
      return PaymentRequestTabs.PAID;
    case InvoiceStatus.CREATED:
      return PaymentRequestTabs.UNSENT;
    case InvoiceStatus.PENDING:
      return PaymentRequestTabs.SENT;
    case InvoiceStatus.FAILED:
    default:
      return PaymentRequestTabs.UNSENT;
  }
};

const REQUEST_TABS = [
  PaymentRequestTabs.UNSENT,
  PaymentRequestTabs.SENT,
  PaymentRequestTabs.SCHEDULED,
  PaymentRequestTabs.PAID,
];

export const getSelectedTab = (query) =>
  REQUEST_TABS.includes(query.status) ? query.status : PaymentRequestTabs.UNSENT;

export const getTabs = (query: Record<string, any>) =>
  REQUEST_TABS.map((tab) =>
    getDefaultTabSetting({
      tab,
      type: 'requests',
      isActive: getSelectedTab(query) === tab,
      baseQueryParams: encodeQuery(
        {
          ...query,
          start: PAGINATION.DEFAULT_START,
          limit: PAGINATION.DEFAULT_LIMIT,
        },
        ['status', 'id', 'ids'],
        ''
      ),
    })
  );

export default getInvoiceTab;

type TabsType = typeof PaymentRequestTabs[keyof typeof PaymentRequestTabs];

export const isSentOrUnSent = (tab: TabsType): boolean =>
  tab === PaymentRequestTabs.SENT || tab === PaymentRequestTabs.UNSENT;

const isSentTab = (tab: TabsType): boolean => tab === PaymentRequestTabs.SENT;

const isUnsentTab = (tab: TabsType): boolean => tab === PaymentRequestTabs.UNSENT;

export const callSinglePushNotification = (currentTab: TabsType, removeInvoice: boolean) => {
  let successLabel = '';

  if (isUnsentTab(currentTab) && removeInvoice) {
    successLabel = 'getPaid.deleteInvoices.success.single';
  } else if (isSentTab(currentTab) && removeInvoice) {
    successLabel = 'getPaid.deletePaymentRequests.success.singleCancelAndRemove';
  } else if (isSentTab(currentTab) && !removeInvoice) {
    successLabel = 'getPaid.deletePaymentRequests.success.singleCancel';
  }

  pushNotification({
    type: NotificationVariant.INFO,
    msg: successLabel,
  });
};

const getFiltersByAction = (action: PaymentRequestActions, actionParams: string) => {
  const date = startOfDay(new Date());

  const actions = {
    [PaymentRequestActions.MARK_CREATED_LAST_7_DAYS]: {
      status: [PaymentRequestStatus.CREATED],
      sorting: 'createdAt:desc',
      limit: 50,
      filter: {
        type: 'created_last_7_days',
        startDate: formatISO(subDays(date, 7)),
        endDate: formatISO(endOfDay(date)),
      },
    },
    [PaymentRequestActions.MARK_CREATED_SINCE]: {
      status: [PaymentRequestStatus.CREATED],
      sorting: 'createdAt:desc',
      limit: 50,
      ...(actionParams
        ? {
            filter: {
              type: 'created_since',
              startDate: formatISO(new Date(actionParams)),
            },
          }
        : {}),
    },
  };

  return actions[action] || {};
};

export const getInvoicesFilter = ({
  tab,
  start,
  limit,
  search,
  action,
  actionParams,
}: {
  tab?: PaymentRequestTabs;
  start?: number;
  limit?: number;
  search?: string;
  action?: PaymentRequestActions;
  actionParams: string;
}) => {
  const filterByTab = {
    [PaymentRequestTabs.UNSENT]: {
      status: [PaymentRequestStatus.CREATED],
      paymentStatus: PaymentStatus.FAILED,
      sorting: 'payment.status,dueDate',
    },
    [PaymentRequestTabs.SENT]: {
      status: [PaymentRequestStatus.PENDING],
      sorting: 'payment.status,dueDate',
    },
    [PaymentRequestTabs.SCHEDULED]: {
      status: [BillStatus.SCHEDULED, BillStatus.PAID],
      paymentStatus: PaymentStatus.SCHEDULED,
      sorting: 'payment.createdAt:desc',
    },
    [PaymentRequestTabs.PAID]: {
      status: BillStatus.PAID,
      paymentStatus: PaymentStatus.COMPLETED,
      sorting: 'payment.scheduledDate:desc',
    },
  };

  let filters = {};

  if (action) {
    filters = getFiltersByAction(action, actionParams);
  } else if (tab) {
    filters = filterByTab[tab];
  }

  return {
    ...filters,
    ...(action
      ? {}
      : {
          start: convertStart(start),
          limit: convertLimit(limit),
        }),
    search,
  };
};
