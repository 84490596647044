import { useDispatch, useSelector } from 'react-redux';
import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useModal } from 'src/helpers/react/useModal';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useTriggerSync } from 'src/hooks/accounting-software/useTriggerSync';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { SyncNowEventsOrigin } from 'src/modules/accounting-platforms/consts';
import organizationStore from 'src/modules/organizations/organizations-store';
import { LastSyncedWrapper } from 'src/pages/settings/components/accounting-software/components/AccountingSoftwareCardElements';
import { useAccountingSoftwareSyncActions } from 'src/pages/settings/hooks/useAccountingSoftwareSyncActions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { AccountingSoftware, DialogVariants } from 'src/utils/consts';
import { eventPage } from '../../consts';

export const QBOSyncNowButtons = () => {
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const { refresh } = useAccountingSoftwareSyncActions(AccountingSoftware.QUICKBOOKS);
  const unlinkQuickbook = getStoreActions(organizationStore)(dispatch).unlinkQuickbookSlice;

  const isSyncingFromStore = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.isSyncing);
  const accountingPlatformSummary = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.syncSummary);

  const { triggerSync } = useTriggerSync();

  const onSyncNowClick = async () => {
    analytics.track('settings', 'accountingSoftwareManualSync-clicked', {
      entryPointName: SyncNowEventsOrigin.SETTINGS_PAGE,
      AccountingSoftwareType: AccountingSoftware.QUICKBOOKS,
    });

    await triggerSync();
  };
  const onUnlinkConfirmed = () => {
    analytics.track(eventPage, 'unlink-confirm', {
      type: AccountingSoftware.QUICKBOOKS,
    });

    return unlinkQuickbook(orgId).finally(refresh);
  };
  const onUnlinkCanceled = () => {
    analytics.track(eventPage, 'unlink-cancel', {
      type: AccountingSoftware.QUICKBOOKS,
    });
  };
  const [unLinkQB, onUnLinkQB] = useModal(CommonDialog, {
    title: 'settings.newAccountingSoftware.Quickbooks.unlinkDialog.title',
    confirm: onUnlinkConfirmed,
    confirmText: 'settings.newAccountingSoftware.Quickbooks.unlinkDialog.confirm',
    variant: DialogVariants.ERROR,
    onDismiss: onUnlinkCanceled,
  });

  return (
    <>
      {unLinkQB}
      <Flex direction={{ sm: 'row', base: 'column' }}>
        <Button
          onClick={onSyncNowClick}
          label={`settings.newAccountingSoftware.${isSyncingFromStore ? 'syncingNow' : 'syncNow'}`}
          disabled={isSyncingFromStore}
          size={ButtonSizes.lg}
          variant={ButtonVariants.tertiary}
          mt={{ sm: 5, base: 3 }}
          mr={{ sm: 5, base: 0 }}
          mb={{ sm: 0, base: 3 }}
          w={{ sm: 'initial', base: 'full' }}
          data-testid="button-settings.newAccountingSoftware.syncNow"
        />
        <Button
          onClick={onUnLinkQB}
          label="settings.newAccountingSoftware.unlink"
          disabled={isSyncingFromStore}
          size={ButtonSizes.lg}
          variant={ButtonVariants.tertiary}
          mt={{ sm: 5, base: 3 }}
          mr={{ sm: 5, base: 0 }}
          mb={{ sm: 0, base: 3 }}
          w={{ sm: 'initial', base: 'full' }}
        />
      </Flex>
      <LastSyncedWrapper data-testid="last-synced-date">
        <MIFormattedText
          label="settings.newAccountingSoftware.lastSynced"
          values={{
            date: <MIFormattedDateTime date={accountingPlatformSummary?.lastUpdateDate} />,
          }}
        />
      </LastSyncedWrapper>
    </>
  );
};
