import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import type {
  PartsStyleFunction,
  PartsStyleObject,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';

const baseStyleOverlay: SystemStyleObject = {
  bg: 'overlay',
  zIndex: 'modal',
};

const baseStyleDialogContainer: SystemStyleFunction = (props) => {
  const { isCentered, scrollBehavior } = props;
  const marginY = { md: 20, base: 16 };
  const marginX = { md: 0, base: 4 };
  const width = { md: '100vw', base: 'auto' };

  return {
    display: 'flex',
    zIndex: 'modal',
    justifyContent: 'center',
    alignItems: isCentered ? 'center' : 'flex-start',
    overflow: scrollBehavior === 'inside' ? 'hidden' : 'auto',
    my: marginY,
    mx: marginX,
    w: width,
  };
};

const baseStyleDialog: SystemStyleFunction = (props) => {
  const { isMarketingModal } = props;
  const padding = { md: 12, base: 6 };

  return {
    borderRadius: 'lg',
    bg: 'white',
    color: 'inherit',
    maxH: '100%',
    p: isMarketingModal ? 0 : padding,
    zIndex: 'modal',
    boxSizing: 'border-box',
    boxShadow: 500,
  };
};

const baseStyleHeader: SystemStyleObject = {
  p: 0,
  w: 'full',
  mb: { md: 10, base: 8 },
};

const baseStyleCloseButton: SystemStyleObject = {
  position: 'absolute',
  top: 6,
  insetEnd: 6,
  w: '2.4rem',
  h: '2.4rem',
};

const baseStyleBody: SystemStyleFunction = (props) => {
  const { isMarketingModal, scrollBehavior } = props;

  return {
    flex: 1,
    overflow: scrollBehavior === 'inside' ? 'auto' : undefined,
    mb: { md: 10, base: 8 },
    px: isMarketingModal ? 12 : 0,
  };
};

const baseStyleFooter: SystemStyleFunction = (props) => {
  const { isMarketingModal } = props;

  return {
    px: isMarketingModal ? 12 : 0,
    paddingBottom: isMarketingModal ? 12 : 0,
  };
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  overlay: baseStyleOverlay,
  dialogContainer: baseStyleDialogContainer(props),
  dialog: baseStyleDialog(props),
  header: baseStyleHeader,
  closeButton: baseStyleCloseButton,
  body: baseStyleBody(props),
  footer: baseStyleFooter(props),
});

function getSize(value: string): PartsStyleObject<typeof parts> {
  if (value === 'full') {
    return {
      dialog: {
        maxW: '100vw',
        minH: 'fit-content',
        '@supports(min-height: -webkit-fill-available)': {
          minH: '-webkit-fill-available',
        },
        my: 0,
        borderRadius: 'none',
        boxShadow: 'none',
      },
      dialogContainer: {
        my: 0,
        mx: 0,
        maxHeight: '100vh',
      },
    };
  }

  if (value === 'sm') {
    return {
      dialog: {
        maxW: '49.6rem',
        my: 0,
      },
    };
  }

  return {
    dialog: { maxW: '56rem' },
  };
}

const sizes = {
  sm: getSize('sm'),
  md: getSize('md'),
  full: getSize('full'),
};

const defaultProps = {
  size: 'md',
};

export default {
  parts: parts.keys,
  baseStyle,
  sizes,
  defaultProps,
};
