import { useFeature } from '@melio/shared-web/dist/feature-flags';
import curry from 'lodash/curry';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation, useRouteMatch } from 'react-router-dom';
import config from 'src/config';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import { useListSearchBarContext, useQuerySearchUpdater } from 'src/hoc/withListSearchBarContext';
import { useListNavigator } from 'src/hooks/useListNavigator';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import usersStore, { getUserActions } from 'src/modules/users/users-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { ContactsListPage } from 'src/pages/contacts/list/components/ContactsListPage';
import { contactsLocations } from 'src/pages/contacts/locations';
import { TransactionSummary } from 'src/pages/contacts/types';
import {
  calculateSummaryByStatus,
  filterContacts,
  getContactActions,
  getContactSelector,
  getContactType,
  getCustomerPaymentRequestSearchTab,
  getPaymentRequestTab,
} from 'src/pages/contacts/utils';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getOrgId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { ContactsTab, FeatureFlags, PaymentRequestTabs } from 'src/utils/consts';
import { JsonObject } from 'src/utils/utility-types';

const eventPage = 'contacts';

const ContactsListPageContainer = () => {
  useQuerySearchUpdater();
  const match = useRouteMatch<{ type: ContactsTab }>();
  const { id, filters, navigate, setSelected, setFilters } = useListNavigator();
  const dispatch = useDispatch();
  const location = useLocation<JsonObject>();
  const { isDesktop } = useBreak();
  const [{ contextSearchFilterValue }] = useListSearchBarContext();
  const [shouldSplitContactsTab] = useFeature(FeatureFlags.SplitContactsTab, false);
  const orgId = useSelector(getOrgId);
  const profile = useSelector(getProfile);
  const contactType = getContactType(match?.params?.type);
  const contactSelector = getContactSelector(match?.params?.type);
  const users = useSelector(usersStore.selectors.org(orgId).users);
  const paymentRequests = useSelector((state) =>
    paymentRequestsStore.selectors.list.value(state, {
      orgId,
      filters: { id },
    })
  );
  const contacts = useSelector((state) => contactSelector.list.value(state, { orgId }));
  const selectedContact = useSelector(contactSelector.fetch.byId(id));
  const isListLoading = useSelector(
    (state) =>
      contactSelector.list.status({ orgId })(state)?.loading &&
      contactSelector.list.value(state, { orgId })?.length === 0
  );
  const isSingleUpdating = useSelector((state) => contactSelector.update.status(id)(state)?.loading);
  const isSingleLoading = useSelector((state) => contactSelector.fetch.status(id)(state)?.loading);

  const contactActions = getContactActions(match?.params?.type)(dispatch);
  const paymentRequestActions = useStoreActions(paymentRequestsStore);
  const userActions = getUserActions(dispatch);
  const vendorActions = useStoreActions(vendorsStore);

  const getFilteredContacts = () => filterContacts(contextSearchFilterValue, contacts);

  const loadSelectedContact = async () => {
    if (contactType === ContactsTab.VENDORS && selectedContact) {
      await contactActions.fetch({
        orgId,
        id,
      });
      await contactActions.countBillsByType({
        orgId,
        id,
      });
      await vendorActions.checkVendorPaymentPreferences({
        orgId,
        id,
      });
    } else {
      paymentRequestActions
        .list({
          orgId,
          filters: { contactId: id },
        })
        .catch((error) => {
          analytics.track(eventPage, 'load-payment-requests-failed', error);
        });
    }
  };

  const getContactSummary = (): TransactionSummary[] => {
    if (contactType === ContactsTab.CUSTOMERS) {
      const contactPr = paymentRequests.filter((pr) => pr.contactId === selectedContact?.id);

      return calculateSummaryByStatus(
        getPaymentRequestTab,
        (tab) => ({
          pathname: getPaidLocations.index,
          search: getCustomerPaymentRequestSearchTab(tab, id),
        }),
        PaymentRequestTabs,
        contactPr as any
      );
    }

    return [];
  };

  const goPaymentRequests = curry((eventName: string, tab: PaymentRequestTabs) => {
    analytics.track(eventPage, `${eventName}-${tab}`);
    navigate({
      pathname: getPaidLocations.index,
      search: getCustomerPaymentRequestSearchTab(tab, id),
    });
  });

  const goCreateContact = (type: ContactsTab) => {
    analytics.track(eventPage, `add-first-${type.slice(0, -1)}`);
    const createUrl = (type) => {
      if (config.featureFlags[`${type}BatchUpload`] === false) {
        return contactsLocations.create.single;
      }

      return contactsLocations.create.options;
    };

    const createPath = shouldSplitContactsTab ? contactsLocations.create.single : createUrl(type);

    navigate(
      generatePath(createPath, {
        type,
        orgId,
      }),
      false,
      {
        returnLocations: { ...location },
      }
    );
  };

  useEffect(() => {
    contactActions.list({ orgId });
    userActions.list({ orgId });
  }, []);

  useEffect(() => {
    contextSearchFilterValue &&
      setFilters({
        ...filters,
        search: contextSearchFilterValue,
      });
  }, [contextSearchFilterValue]);

  useEffect(() => {
    contactActions.list({ orgId });
  }, [contactType]);

  useEffect(() => {
    if (id) {
      loadSelectedContact();
    }
  }, [id, selectedContact?.id]);

  useEffect(() => {
    const shouldSelectFirstItem = !id && isDesktop && !filters.search && contacts.length;

    if (shouldSelectFirstItem) {
      setSelected(contacts[0].id);
    }
  }, [contacts, id, isDesktop, filters.search]);

  return (
    <ContactsListPage
      filters={filters}
      contacts={getFilteredContacts()}
      profile={profile}
      users={users}
      selectedContact={selectedContact}
      loadSelectedContact={loadSelectedContact}
      contactTransactionsSummary={getContactSummary()}
      isLoading={isSingleUpdating}
      isListLoading={isListLoading}
      isSingleLoading={isSingleLoading}
      selectedTab={contactType}
      goCreateContact={goCreateContact}
      goPaymentRequests={goPaymentRequests('go-payment-request')}
      registrationFlow={profile?.registrationFlow}
      eventPage={eventPage}
    />
  );
};

export default ContactsListPageContainer;
