import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { financingStore } from 'src/modules/financing/financing-store';
import { GetInstallmentsOptionsResponse } from 'src/pages/bill/pay/installments/types';

type GetInstallmentsOptionsFunction = (params: {
  orgId: number;
  vendorId: number;
  paymentAmount: number;
}) => Promise<{ payload: GetInstallmentsOptionsResponse }>;

export const useInstallmentsOptionsRequest = () => {
  const { data: result, loading, error } = useSelector(financingStore.selectors.getInstallmentOptions.status);
  const { getInstallmentOptions }: { getInstallmentOptions: GetInstallmentsOptionsFunction } = useStoreActions(
    financingStore
  );

  return {
    installmentsOptions: result,
    getInstallmentOptions,
    loading,
    error,
  };
};
