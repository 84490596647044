import { vendorsApi } from 'src/modules/vendors/api';

export const findOrCreateOwnedVendorWithHandle = async (
  orgId: number,
  { ownedVendorHandle, ownedVendorId }: { ownedVendorHandle?: string; ownedVendorId?: number | null }
) => {
  if (ownedVendorHandle) {
    return { handle: ownedVendorHandle, vendorId: ownedVendorId };
  }

  const { vendor } = await vendorsApi.createOwnedVendorWithHandle(orgId);

  return { handle: vendor.handle, vendorId: parseInt(vendor.id, 10) };
};
