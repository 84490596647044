/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import type { TransactionResponse, TransactionResponseStatusEnum } from '@melio/spend-management-api-axios-client';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Column } from 'react-table';
import { HeaderCell } from 'src/components/shared/tables/PaymentsTable/components/HeaderCell';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { DataTable } from 'src/pages/get-pro/components/table/DataTable';
import { TransactionsEmptyState } from 'src/pages/spend-management/components/empty-states/TransactionsEmptyState';
import { AccountCell } from 'src/pages/spend-management/components/table/components/AccountCell';
import { AmountCell } from 'src/pages/spend-management/components/table/components/AmountCell';
import { CardCell } from 'src/pages/spend-management/components/table/components/CardCell';
import { DateCell } from 'src/pages/spend-management/components/table/components/DateCell';
import { StatusCell } from 'src/pages/spend-management/components/table/components/transactions/StatusCell';
import { VendorCell } from 'src/pages/spend-management/components/table/components/transactions/VendorCell';
import { analytics, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import {
  getEventDescription,
  getTransactionAmount,
  getTransactionDate,
  getTransactionReferenceNumber,
  getTransactionStatus,
} from 'src/pages/spend-management/utils/transactions';
import { useQueryString } from 'src/utils/hooks';
import { tableWrapperStyle } from './tableStyles';

export type DateTableRowType = {
  id: string;
  vendor?: {
    name?: string;
    category?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
  amount: {
    amount: string;
  };
  txnDate?: string;
  account: {
    nickname?: string;
    last5: string;
    productDescription?: string;
  };
  card: {
    nickname?: string;
    last5?: string;
    status: TransactionResponse['card']['status'];
  };
  referenceId: string | undefined;
  authorizationId?: string;
  eventDescription: string;
  status: TransactionResponseStatusEnum;
};

export const TransactionsTable = ({
  transactions,
  onTransactionClicked,
}: {
  transactions: TransactionResponse[];
  onTransactionClicked: (transaction: DateTableRowType) => void;
}) => {
  const query = useQueryString();
  const filteredTransactions = query.cardId
    ? transactions.filter((trx) => trx.card.cardId === query.cardId)
    : transactions;

  const columns: Column<DateTableRowType>[] = [
    {
      id: 'vendor',
      Header: <HeaderCell label="spendManagement.pages.dashboard.txnList.columns.vendor" />,
      accessor: 'vendor',
      Cell: VendorCell,
      width: 0.3,
      disableSortBy: true,
    },
    {
      id: 'amount',
      Header: <HeaderCell label="spendManagement.pages.dashboard.txnList.columns.amount" />,
      accessor: 'amount',
      Cell: AmountCell,
      width: 0.2,
      isNumeric: true,
      disableSortBy: true,
    },
    {
      id: 'txnDate',
      Header: <HeaderCell label="spendManagement.pages.dashboard.txnList.columns.txnDate" />,
      accessor: 'txnDate',
      Cell: DateCell,
      width: 0.2,
      disableSortBy: true,
    },
    {
      id: 'card',
      Header: <HeaderCell label="spendManagement.pages.dashboard.txnList.columns.card" />,
      accessor: 'card',
      Cell: CardCell,
      width: 0.3,
      disableSortBy: true,
    },
    {
      id: 'account',
      Header: <HeaderCell label="spendManagement.pages.dashboard.txnList.columns.account" />,
      accessor: 'account',
      Cell: AccountCell,
      width: 0.2,
      disableSortBy: true,
    },
    {
      id: 'status',
      Header: <HeaderCell label="spendManagement.pages.dashboard.txnList.columns.status" />,
      accessor: 'status',
      Cell: StatusCell,
      width: 0.2,
      disableSortBy: true,
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const fooBar = () => {};
  const { formatMessage } = useIntl();

  useEffect(() => {
    analytics.page(Page.TRANSACTION_LIST, { isEmptyState: true });
  }, []);

  if (!filteredTransactions || filteredTransactions.length === 0)
    return (
      <Flex flexGrow={1} w="full">
        <TransactionsEmptyState />;
      </Flex>
    );

  const txnData: DateTableRowType[] = filteredTransactions.map((t, index) => ({
    id: t.authorizationId || String(index),
    key: t.authorizationId,
    vendor: {
      name: t.vendor?.name,
      city: t.vendor?.city,
      state: t.vendor?.state,
      zipCode: t.vendor?.zipCode,
    },
    amount: {
      amount: getTransactionAmount(t),
    },
    txnDate: getTransactionDate(t),
    card: {
      nickname: t.card.nickname,
      last5: t.card.cardDetails.cardLastFive,
      status: t.card.status,
    },
    account: {
      nickname: t.account.accountNickname || t.account.productDescription,
      last5: t.account.lastFive,
      productDescription: t.account.productDescription,
    },
    referenceId: getTransactionReferenceNumber(t),
    authorizationId: t.authorizationId,
    eventDescription: formatMessage({ id: getEventDescription(t) }),
    status: getTransactionStatus(t), // TODO: To be removed once all old transactions will be migrated to new structure
  }));

  return (
    <Box __css={tableWrapperStyle}>
      <DataTable<DateTableRowType>
        name="SpendManagementTable"
        data-testid="spend-management-transactions-table"
        columns={columns}
        data={txnData}
        manualSortBy
        disableSortRemove
        onChangeSort={fooBar}
        autoResetSortBy={false}
        initialState={{}}
        hideCheckBoxes
        setSelectedIds={fooBar}
        selectedIds={[]}
        onClick={(row) => onTransactionClicked(row.original)}
        autoResetPage={false}
        manualPagination
        showPagination={false}
        initialSortBy={[{ id: 'txnDate', desc: true }]}
        totalItems={txnData.length}
        pageCount={1}
        onPageIndexChange={fooBar}
        getRowId={(row) => row.id}
        onSelectionChanged={fooBar}
        // clearSelected={fooBar}
        toggleClearSelected={fooBar}
        // contentState={null}
        content=""
        // filter={null}
        action={fooBar}
        setAction={fooBar}
        setActionParams={fooBar}
        // actionParams={null}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filterRowsByQueryAction={fooBar}
        tab="paid"
      />
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default TransactionsTable;
