import { featureFlags } from '@melio/shared-web';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { WizardIllustration, WizardStepSubTitle, WizardStepTitle } from 'src/components/layout/WizardElements';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { IllustrationName } from 'src/core/ds/illustration';
import { useModal } from 'src/helpers/react/useModal';
import successImage from 'src/images/bills/success.svg';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { billLocations } from 'src/pages/bill/locations';
import { CollectVendorsEmailModal as CollectVendorsEmail } from 'src/pages/regular-batch-payments/components/dialog/CollectVendorsEmailModal';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import { useCreateBatchPayments } from 'src/pages/regular-batch-payments/hooks/useCreateBatchPayments';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { getBillsDefaultFilters, serializePaymentId } from 'src/utils/bills';
import { BillStatus, DialogType, DialogVariants, FeatureFlags, NotificationVariant } from 'src/utils/consts';
import { VendorType } from 'src/utils/types';
import FailedBillsView from './FailedBillsView';

const eventPage = 'regular-batch-payments';

const getSuccessPageLabels = (
  isBulkPayment: boolean,
  scheduledPaymentsAmount: number,
  scheduledPaymentsCount: number
) => {
  const titleValues = { scheduledPaymentsCount };
  let title = 'regularBatchPayments.success.title';
  let subtitle = 'regularBatchPayments.success.subTitle';
  let subTitleValues;

  if (isBulkPayment) {
    title = 'regularBatchPayments.bulkSuccess.title';
    subtitle = 'regularBatchPayments.bulkSuccess.subTitle';
    subTitleValues = {
      scheduledPaymentsCount,
      scheduledPaymentsAmount: <MIFormattedCurrency value={scheduledPaymentsAmount} />,
    };
  }

  return {
    title,
    titleValues,
    subtitle,
    subTitleValues,
  };
};

export const BatchPaymentsSuccessPage: React.FC = () => {
  const [historyPush] = useHistoryWithOrgId();
  const [isBulkPaymentEnabled] = featureFlags.useFeature(FeatureFlags.BulkPayment, false);
  const {
    createBatchPaymentData: { scheduledPayments, scheduledPaymentsCount, failedPaymentsCount, scheduledPaymentsAmount },
  } = useCreateBatchPayments();
  const { billsList } = useBatchBillsList();
  const vendorIds = billsList.map((bill) => bill.vendor.id);
  const vendors = useSelector<any, VendorType[]>(vendorsStore.selectors.getByIds(vendorIds));
  const vendorsWithoutEmail = vendors.filter((vendor) => isEmpty(vendor.contactEmail));
  const uniqueVendorsWithoutEmail = uniqBy(vendorsWithoutEmail, 'id');
  const firstScheduledPayment = head(scheduledPayments);
  const id = firstScheduledPayment
    ? serializePaymentId(firstScheduledPayment.billId || '', firstScheduledPayment.id)
    : '';
  const defaultFilters = getBillsDefaultFilters(BillStatus.SCHEDULED);
  const { subTitleValues, subtitle, title, titleValues } = getSuccessPageLabels(
    isBulkPaymentEnabled,
    scheduledPaymentsAmount,
    scheduledPaymentsCount
  );

  const handleCollectVendorsEmailModalClose = () => {
    analytics.track(eventPage, 'vendors-email-modal-closed');
  };

  const goToBillList = () => {
    historyPush({
      path: billLocations.index,
      query: {
        id,
        ...defaultFilters,
      },
    });
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'regularBatchPayments.messages.scheduled',
      textValues: { scheduledPaymentsCount },
    });
  };

  const [
    CollectVendorsEmailSuccessModal,
    showCollectVendorsEmailSuccessModal,
    isCollectVendorsEmailSuccessModalOpen,
  ] = useModal(Dialog, {
    type: DialogType.CONFIRM,
    variant: DialogVariants.SUCCESS,
    title: 'regularBatchPayments.success.collectVendorEmailModal.successTitle',
    subtitle: 'regularBatchPayments.success.collectVendorEmailModal.successSubTitle',
    image: successImage,
    okButtonText: 'regularBatchPayments.success.collectVendorEmailModal.goDashboard',
    onOkAction: goToBillList,
    showCancel: false,
    modalName: 'successCollectEmail',
  });

  const [CollectVendorsEmailModal, showCollectVendorsEmailModal, isCollectVendorsEmailModalOpen] = useModal(
    CollectVendorsEmail,
    {
      onSuccess: showCollectVendorsEmailSuccessModal,
      onDismiss: handleCollectVendorsEmailModalClose,
      vendors: uniqueVendorsWithoutEmail,
      modalName: 'collectEmails',
      eventPage,
    }
  );

  const goToNotifyVendors = () => {
    analytics.track(eventPage, 'notify-my-vendors', {
      countMissingEmails: uniqueVendorsWithoutEmail.length,
    });
    showCollectVendorsEmailModal();
  };

  const renderFooter = () => {
    if (failedPaymentsCount) {
      return <FailedBillsView goToBillList={goToBillList} />;
    }

    return (
      <>
        {shouldShowNotifyVendorButton && (
          <Button
            w="full"
            mb={3}
            variant={ButtonVariants.primary}
            onClick={goToNotifyVendors}
            label="regularBatchPayments.success.notifyVendor"
          />
        )}
        <Button
          w="full"
          variant={shouldShowNotifyVendorButton ? ButtonVariants.tertiaryNaked : ButtonVariants.primary}
          onClick={goToBillList}
          label="regularBatchPayments.success.goDashboard"
        />
      </>
    );
  };

  const shouldShowNotifyVendorButton =
    !isEmpty(uniqueVendorsWithoutEmail) && !isCollectVendorsEmailModalOpen && !isCollectVendorsEmailSuccessModalOpen;

  useEffect(() => {
    if (shouldShowNotifyVendorButton) {
      analytics.track(eventPage, 'exposed-to-notify-my-vendors-button', {
        countMissingEmails: uniqueVendorsWithoutEmail.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowNotifyVendorButton]);

  return (
    <StyledContainer>
      {CollectVendorsEmailModal}
      {CollectVendorsEmailSuccessModal}
      <StepLayoutPage
        illustration={IllustrationName.checkCircle}
        title={title}
        titleValues={titleValues}
        subtitle={subtitle}
        subTitleValues={subTitleValues}
        goExit={goToBillList}
        footer={renderFooter()}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100%;

  ${WizardIllustration} {
    width: 10rem;
    height: 10rem;
    padding: 2rem;
    margin-bottom: 0;
  }

  ${WizardStepTitle} {
    margin-top: 0.1rem;
    font-size: 4rem;
    line-height: 5.2rem;
  }

  ${WizardStepSubTitle} {
    margin-bottom: 4rem;
    ${(props) => props.theme.text.fontType.medium};
  }
`;
