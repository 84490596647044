import { isValidationOk } from '@melio/sizzers-js-common';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { authApi } from 'src/modules/auth/api';
import { analytics } from 'src/services/analytics';
import clientServiceApi from '../../services/api/clientService';
import RequestResetPage from './components/RequestResetPage';

export const RequestResetPageContainer = () => {
  const history = useHistory();
  const [isDone, setIsDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const onRequestResetPassword = (email: string) => {
    setIsLoading(true);
    setErrorCode(null);
    analytics.track('auth', 'request-reset-password');

    clientServiceApi
      .getValidationErrors('userRegistration', { email })
      .then(({ validationErrors }) => {
        setValidationErrors(validationErrors);

        if (isValidationOk(validationErrors)) {
          authApi
            .requestResetPassword({ email })
            .then(() => {
              setIsDone(true);
              setIsLoading(false);
            })
            .catch((e) => {
              setIsLoading(false);
              setErrorCode(e.code);
            });
        } else {
          analytics.track('auth', 'request-reset-password-validation-error', validationErrors);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const goBack = () => history.goBack();

  return (
    <RequestResetPage
      onRequestResetPassword={onRequestResetPassword}
      isLoading={isLoading}
      isDone={isDone}
      goBack={goBack}
      errorCode={errorCode}
      validationErrors={validationErrors}
    />
  );
};
