import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Modal } from 'src/core/ds/modal';
import { HStack, VStack } from 'src/core/ds/stack';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  deliveryMethod: DeliveryMethodType;
};

export const UpdateReceivingMethodConfirmationModal = ({ onClose, onConfirm, deliveryMethod, isOpen }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <Modal.Header>
      <Modal.HeaderTitle>
        <MIFormattedText label="settings.receivingMethods.dialog.update.title" />
      </Modal.HeaderTitle>
      <Modal.HeaderSubTitle>
        <VStack spacing={5} align="start" textStyle="body2">
          <Box>
            <MIFormattedText
              label="settings.receivingMethods.dialog.update.subTitle"
              values={{ activeBankAccount: getAccountNumber4digits(deliveryMethod?.bankAccount) }}
            />
          </Box>
          <Box>
            <MIFormattedText label="settings.receivingMethods.dialog.update.content" />
          </Box>
        </VStack>
      </Modal.HeaderSubTitle>
    </Modal.Header>
    <Modal.CloseButton />
    <Modal.Footer>
      <HStack spacing={4}>
        <Button
          onClick={onClose}
          variant={ButtonVariants.tertiaryNaked}
          size={ButtonSizes.lg}
          label="settings.receivingMethods.dialog.update.cancel"
        />
        <Button onClick={onConfirm} size={ButtonSizes.lg} label="settings.receivingMethods.dialog.update.confirm" />
      </HStack>
    </Modal.Footer>
  </Modal>
);
