import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box/Box';
import { TextField } from 'src/core/ds/form/fields';
import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { profileStore } from 'src/modules/profile/profile-store';
import { analytics } from 'src/services/analytics';
import { convertToGoogleAddress } from 'src/utils/address';
import { AddressFormControlMode } from 'src/utils/consts';
import { CompanyInfoType, GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  onPrev: () => void;
  onNext: (address: GoogleCombinedAddressType, companyName: string) => Promise<void>;
  onChange: (value: string | null, fieldName?: string) => void;
  companyInfo: CompanyInfoType;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
};

export const CompanyDetailsForm = ({ companyInfo, onNext, onPrev, validationErrors, onChange, isLoading }: Props) => {
  const [companyName, setCompanyName] = useState(companyInfo.companyName || '');
  const [address, setAddress] = useState<GoogleCombinedAddressType>(convertToGoogleAddress(companyInfo));
  const user = useSelector(profileStore.selectors.profile);
  const analyticsData = { msnOptinFlow: true, isGuest: user.isGuest, flows: 'public' };

  if (!isEmpty(validationErrors)) {
    analytics.trackAction('msnPortalCompanyDetails.error', {
      ...analyticsData,
      errorReason: 'missing-details',
      validationErrors,
    });
  }

  const site = useSiteContext();
  const title = useRef(companyInfo.companyName ? 'existingInfoTitle' : 'title');

  const handleCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value);
    onChange(companyName, 'companyName');
  };

  const handleCompanyAddressChange = (address: GoogleCombinedAddressType) => {
    setAddress(address);
    onChange(address.formattedAddress || '', 'address');
  };

  const onSubmit = () => {
    onNext(address, companyName);
  };

  const termsAndConditions = (
    <Box textStyle="body4" color="grey.700" mt="5" textAlign="left">
      <MIFormattedText
        label="onboarding.msnCompanyInfo.companyDetails.disclaimer"
        values={{
          termsOfServiceLink: (...chunks) => (
            <Link target="_blank" href={site.config.agreementLinks.userAgreement}>
              {chunks}
            </Link>
          ),
          privacyPolicyLink: (...chunks) => (
            <Link target="_blank" href={site.config.agreementLinks.privacyPolicy}>
              {chunks}
            </Link>
          ),
        }}
      />
    </Box>
  );

  return (
    <StepLayoutPage
      title={`onboarding.msnCompanyInfo.companyDetails.${title.current}`}
      subtitle="onboarding.msnCompanyInfo.companyDetails.subtitle"
      onPrev={onPrev}
      relativeStep={2 / 6}
      isLoading={isLoading}
      onNext={onSubmit}
      testId="contact-details-page"
      footer={termsAndConditions}
      nextButtonAnalyticsProperties={analyticsData}
    >
      <TextField
        id="companyName"
        testId="contact-details-company-name"
        label="onboarding.msnCompanyInfo.companyDetails.inputTitleCompanyName"
        value={companyName}
        onSubmit={onSubmit}
        onChange={handleCompanyNameChange}
        errorMessage={validationErrors.companyName}
        isRequired
        autoFocus
      />
      <MIAddressAutocomplete
        id="address"
        addressLabel="onboarding.msnCompanyInfo.companyDetails.inputTitleAddress"
        address={address}
        onChange={handleCompanyAddressChange}
        mode={AddressFormControlMode.INLINE}
        hideSuite
        required
        errorMessage={validationErrors.googlePlaceId || validationErrors.zipCode}
        privateData
      />
    </StepLayoutPage>
  );
};
