import React, { useCallback } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import { Menu, MenuButton, MenuGroup, MenuItem, MenuList } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { menuPlacement } from 'src/pages/pay/components/menu-consts';
import {
  useLocationBasedOptions,
  useLocationOnChange,
  useSendAnalyticOnClose,
  useTooltipWithMenu,
} from 'src/pay/components/SortMenu/hooks';
import { getDirectionIcon, xorDirection } from 'src/pay/components/SortMenu/SortMenuUtils';
import { SortMenuOnChange, SortMenuOption, SortMenuValue } from 'src/pay/components/SortMenu/types';
import { DEFAULT_DIRECTION } from 'src/utils/payListConsts';
import { SortDirection } from 'src/utils/query-utils';

interface Props {
  options: SortMenuOption[];
  selected: string;
  direction: SortDirection;
  onChange: SortMenuOnChange;
}

export function SortMenuComponent({ selected, direction, options, onChange }: Props) {
  const { onCloseMenu: tellTooltipMenuClosed, allowTooltip } = useTooltipWithMenu();
  const onClick = useCallback(
    ({ selected: value, direction }: SortMenuValue) => {
      const shouldXor = value === selected;
      const newDirection = shouldXor ? xorDirection(direction) : DEFAULT_DIRECTION;
      const newValue = { selected: value, direction: newDirection };
      onChange(newValue);
    },
    [selected, onChange]
  );

  const directionIconName = getDirectionIcon(direction);
  const { onCloseMenu: sendAnalytic, onOpenMenu } = useSendAnalyticOnClose();

  const onCloseMenu = () => {
    tellTooltipMenuClosed();
    sendAnalytic();
  };

  return (
    <Menu
      placement={menuPlacement}
      autoSelect={false}
      closeOnSelect={false}
      onClose={onCloseMenu}
      onOpen={onOpenMenu}
      isLazy
    >
      <Tooltip
        placement="top"
        label={<MIFormattedText label="pay.sortModule.btnTooltipLabel" />}
        isDisabled={!allowTooltip}
      >
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<Icon name={IconNames.sort} />}
          data-testid="sortMenu-btn"
        >
          <MIFormattedText label="pay.sortModule.actions" />
        </MenuButton>
      </Tooltip>
      <MenuList data-testid="menu-list" zIndex="dropdown">
        <MenuGroup title="pay.sortModule.menu.header">
          {options.map(({ label, value }) => (
            <MenuItem
              testId="sortMenu-option"
              justifyContent="space-between"
              onClick={() => {
                onClick({ selected: value, direction });
              }}
              key={value}
            >
              <MIFormattedText label={label} />
              {selected === value ? <Icon size={IconSize.s} name={directionIconName} /> : null}
            </MenuItem>
          ))}
        </MenuGroup>
      </MenuList>
    </Menu>
  );
}

export function SortMenu() {
  const {
    options,
    value: { selected, direction },
  } = useLocationBasedOptions();
  const onChange = useLocationOnChange();

  return <SortMenuComponent onChange={onChange} options={options} selected={selected} direction={direction} />;
}
