const bills = '/orgs/:orgId/bills';
const vendors = '/orgs/:orgId/vendors';
const requests = '/orgs/:orgId/requests';
const companies = '/orgs/:orgId/companies';
const getPaid = '/orgs/:orgId/get-paid';
const team = '/orgs/:orgId/team';

export const dashboardLocations = {
  base: [bills, vendors, requests, companies, getPaid, team],
  bills,
  vendors,
  companies,
  getPaid,
  team,
};
