import Highlighter from 'react-highlight-words';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useSearchValue } from 'src/hoc/withListSearchBarContext';
import { SyncedItemIcon } from 'src/pages/get-pro/components/drawer/components/SyncedItemIcon';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { DateTableRowType } from '../types';

export function InvoiceNumberCell({ cell, row }: CellProps<DateTableRowType>) {
  const search = useSearchValue();
  const { original } = row;
  const accountingSoftwareName = useGetAccountingSoftwareName(original.createOrigin);

  return (
    <Flex isTruncated alignItems="center" className={FULL_STORY_MASK_RULE_CLASS}>
      <Highlighter searchWords={[search]} textToHighlight={cell.value} />
      {accountingSoftwareName && (
        <Box ml={2} display="inline-block">
          <SyncedItemIcon accountingSoftwareName={accountingSoftwareName} />
        </Box>
      )}
    </Flex>
  );
}
