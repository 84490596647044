import { isValidationOk } from '@melio/sizzers-js-common';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { authApi } from 'src/modules/auth/api';
import { authLocations } from 'src/pages/auth/locations';
import { analytics } from 'src/services/analytics';
import { UserContextType } from 'src/utils/types';
import { withPreservedStateNavigator } from '../../../hoc/index';
import { GlobalState } from '../../../redux/types';
import { clearUserInfoAction, setProfileAction } from '../../../redux/user/actions';
import { getProfile } from '../../../redux/user/selectors';
import clientServiceApi from '../../../services/api/clientService';
import ChangeEmailPage from './components/ChangeEmailPage';

type MapStateToProps = {
  profile: UserContextType;
};

type MapDispatchToProps = {
  setProfile: (profile: UserContextType) => void;
  clearUserInfo: () => void;
};

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  email: string;
  validationErrors: Record<string, any>;
  isLoading: boolean;
  errorCode?: string;
};

const eventPage = 'change-email';

class ChangeEmailPageContainer extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);

    const { email } = this.props.profile;
    this.state = {
      email,
      validationErrors: {},
      isLoading: false,
    };
  }

  onNext = () => {
    const formattedEmail = this.state.email.trim().toLowerCase();
    analytics.track(eventPage, 'email-continue');

    if (formattedEmail === this.props.profile.email) {
      if (this.state.errorCode !== 'ATH01') {
        this.props.navigate(authLocations.register.codeVerification);
      }
    } else {
      this.setState({ isLoading: true });
      clientServiceApi
        .getValidationErrors('userRegistration', { email: formattedEmail })
        .then(({ validationErrors }) => {
          this.setState({ validationErrors }, () => {
            if (isValidationOk(this.state.validationErrors)) {
              authApi
                .changeEmail({ email: formattedEmail })
                .then(() => {
                  analytics.track(eventPage, 'email-continue-success');
                  this.setState({ isLoading: false });
                  this.props.setProfile({ ...this.props.profile, email: formattedEmail });
                  this.props.navigate(authLocations.register.codeVerification);
                })
                .catch((e) => {
                  analytics.track(eventPage, 'register-email-not-unique');
                  this.setState({ isLoading: false, errorCode: e.code });
                });
            } else {
              this.setState({ isLoading: false });
              analytics.track(eventPage, 'email-validation-error', validationErrors);
            }
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  };

  onPrev = () => {
    this.props.navigate(authLocations.register.codeVerification);
  };

  onChange = (email: string) => {
    this.setState({ email });
  };

  redirectToLogin = async () => {
    this.props.clearUserInfo();
    this.props.navigate(authLocations.login);
  };
  render() {
    const { email, validationErrors, isLoading, errorCode } = this.state;

    return (
      <ChangeEmailPage
        onNext={this.onNext}
        onPrev={this.onPrev}
        onChange={this.onChange}
        email={email}
        redirectToLogin={this.redirectToLogin}
        validationErrors={validationErrors}
        isLoading={isLoading}
        errorCode={errorCode}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
});

const mapDispatchToProps = (dispatch: Dispatch): MapDispatchToProps => ({
  setProfile(profile: UserContextType) {
    dispatch(setProfileAction(profile));
  },
  clearUserInfo() {
    dispatch(clearUserInfoAction());
  },
});

export default compose(
  withPreservedStateNavigator(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangeEmailPageContainer);
