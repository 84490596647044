import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { organizationsApi } from 'src/modules/organizations/api';
import { getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { getDebitCardFundingSourceId } from 'src/utils/funding-sources';
import { useBatchBillsList } from './useBatchBillsList';

export const useBatchDebitFee = (createBatchPayment: () => void) => {
  const orgId = useSelector(getOrgId);
  const { billsList } = useBatchBillsList();
  const fundingSources = useSelector(getFundingSources);

  const [isDebitFeeNotificationOpen, setIsDebitFeeNotificationOpen] = useState(false);
  const {
    onApiCall: fetchShouldDisplayDebitFeeNotification,
    result: shouldDisplayDebitFeeNotificationResponse,
  } = useApi({
    api: organizationsApi.shouldDisplayDebitFeeNotification,
  });

  useEffect(() => {
    fetchShouldDisplayDebitFeeNotification(orgId);
  }, [fetchShouldDisplayDebitFeeNotification, orgId]);

  const onClose = () => setIsDebitFeeNotificationOpen(false);

  const onConfirm = () => {
    if (isDebitFeeNotificationOpen) {
      setIsDebitFeeNotificationOpen(false);
      createBatchPayment();
    }
  };

  const isDebitCardSelected: boolean = useMemo(() => {
    const debitCardFundingSourceIds = getDebitCardFundingSourceId(fundingSources);
    const billsWithDebitCardFundingSource = billsList.filter((bill) =>
      debitCardFundingSourceIds.includes(bill.payment.fundingSourceId)
    );

    return !isEmpty(billsWithDebitCardFundingSource);
  }, [fundingSources, billsList]);

  const shouldShowDebitFeeNotificationModal = isDebitCardSelected && shouldDisplayDebitFeeNotificationResponse?.result;

  return {
    isDebitFeeNotificationOpen,
    setIsDebitFeeNotificationOpen,
    shouldShowDebitFeeNotificationModal,
    handleCloseDebitFeeNotificationModal: onClose,
    handleConfirmDebitFeeNotificationModal: onConfirm,
  };
};
