import { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { MICategoryList } from 'src/components/common/MICategoryList';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { withNavigator } from 'src/hoc';
import { clientsAmountOptions } from 'src/modules/organizations/constants';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CompanyInfoProps, withCompanyInfoUpdates } from './hoc/withCompanyInfoUpdates';

type Props = {
  nextStepURL: string;
  prevStepURL: string;
} & CompanyInfoProps;

const CompanyClientsAmountPage = ({ navigateToCompanyOnboardingPage, nextStepURL, prevStepURL }: Props) => {
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isOrganizationPreferencesUpdating = useSelector(organizationStore.selectors.isOrganizationPreferencesUpdating);
  const organizationPreferences = useOrganizationPreferences();
  const organizationActions = useStoreActions(organizationStore);
  const [clientsAmount, setClientsAmount] = useState<string | null | undefined>(
    organizationPreferences.onboardingBookkeeperNumOfCompanies
  );

  const onSubmit = async () => {
    await organizationActions.updateOrganizationPreference({
      orgId,
      key: 'onboardingBookkeeperNumOfCompanies',
      value: clientsAmount,
    });
    onSkip();
  };

  const onSkip = () => {
    navigateToCompanyOnboardingPage(nextStepURL);
  };

  const onPrev = () => {
    navigateToCompanyOnboardingPage(prevStepURL);
  };

  const renderFooter = () => (
    <WizardElements.WizardButtonContainer>
      <Button label="progress.skip" variant={ButtonVariants.tertiaryNaked} onClick={onSkip} />
    </WizardElements.WizardButtonContainer>
  );

  return (
    <StepLayoutPage
      title="onboarding.companyInfo.clientsAmount.title"
      titleValues={{ companyName: <Title>{companyInfo.companyName}</Title> }}
      relativeStep={6 / 7}
      onNext={onSubmit}
      isNextDisabled={!clientsAmount}
      onPrev={onPrev}
      footer={renderFooter()}
      isLoading={isOrganizationPreferencesUpdating}
    >
      <MICategoryList
        selectedId={clientsAmount}
        categories={clientsAmountOptions}
        onSelect={(id) => setClientsAmount(id)}
      />
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default compose(withNavigator(), withCompanyInfoUpdates())(CompanyClientsAmountPage);
