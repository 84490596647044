import { Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { analytics } from 'src/services/analytics';
import { CompanyFormPage } from 'src/utils/consts';
import locations from 'src/utils/locations';
import { MobileAppPromotionPage } from './components/mobile-app-promotion-page';

export const MobileAppPromotionPageContainer = () => {
  const { navigate } = usePreservedStateNavigator();
  const eventPage = 'mobile-app-promotion';
  const STORE_LINK = 'https://melio.sng.link/D11mo/ruqn/pfq4';
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  analytics.track(eventPage, 'page-view', { userId, orgId });

  const goToDashboard = () => {
    analytics.track(eventPage, 'click-continue-on-the-website', { userId, orgId });

    navigate(locations.MainApp.dashboard.url(), false, {
      origin: CompanyFormPage.ONBOARDING,
    });
  };

  const handleSubmit = () => {
    analytics.track(eventPage, 'click-download-app', { userId, orgId });

    window.location.href = STORE_LINK;
  };

  return (
    <Flex data-testid="mobile-app-promotion-page" h={{ md: 'calc(100% - 6.4rem)', base: 'unset' }}>
      <MobileAppPromotionPage handleSubmit={handleSubmit} goToDashboard={goToDashboard} />
    </Flex>
  );
};
