import { PaymentRequestStatus } from 'src/pages/meliome/guest/components/GuestPaymentRequestInfoPage';
import { TrackingStatuses } from 'src/utils/consts';
import { PaymentTrackerStage } from 'src/utils/types';

export const useMapRequestStatusToTracker = (
  stages: PaymentRequestStatus,
  additionalStrings: { companyName: string }
): PaymentTrackerStage[] => {
  const trackerStages: PaymentTrackerStage[] = [];
  const { companyName } = additionalStrings;

  if (!stages.scheduled) {
    return trackerStages;
  }

  // Request payment was Scheduled
  if (stages.scheduled) {
    trackerStages.push({
      title: 'requests.tracking.title.scheduled',
      label: 'requests.tracking.statusDescription.scheduled',
      values: { companyName },
      status: !stages.processed ? TrackingStatuses.CURRENT : TrackingStatuses.COMPLETED,
      timestamp: stages.created,
    });

    // Request payment was processed
    if (stages.processed) {
      trackerStages.push({
        title: !stages.delivered ? 'requests.tracking.title.processed' : 'requests.tracking.title.processing',
        label: 'requests.tracking.statusDescription.fundsDeducted',
        status: !stages.delivered ? TrackingStatuses.CURRENT : TrackingStatuses.COMPLETED,
        timestamp: stages.processed,
      }); // Request payment is not yet processed
    } else if (stages.failed && stages.failedStage === 'collect') {
      // Request failed in Collect stage
      trackerStages.push({
        title: 'requests.tracking.title.processing',
        label: 'requests.tracking.statusDescription.fundsNotDeducted',
        status: TrackingStatuses.FAILED,
        timestamp: stages.failed,
      });
    } else {
      trackerStages.push({
        title: 'requests.tracking.title.processing',
        label: 'requests.tracking.statusDescription.fundsWillBeDeducted',
        status: TrackingStatuses.PENDING,
        timestamp: stages.scheduled,
      });
    }

    // Request payment was delivered
    if (stages.delivered) {
      trackerStages.push({
        title: 'requests.tracking.title.delivered',
        label: 'requests.tracking.statusDescription.wasTransfer',
        values: { companyName },
        status: TrackingStatuses.CURRENT,
        timestamp: stages.deliveryETA,
      }); // Request payment is not yet delivered
    } else if (stages.failed && stages.failedStage === 'delivery') {
      // Request failed in delivery stage
      trackerStages.push({
        title: 'requests.tracking.title.delivery',
        label: 'requests.tracking.statusDescription.willNotTransfer',
        values: { companyName },
        status: TrackingStatuses.FAILED,
        timestamp: stages.failed,
      });
    } else {
      trackerStages.push({
        title: 'requests.tracking.title.delivery',
        label: 'requests.tracking.statusDescription.willTransfer',
        values: { companyName },
        status: TrackingStatuses.PENDING,
        timestamp: stages.deliveryETA,
      });
    }
  }

  return trackerStages;
};
