import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { forwardRef } from 'src/core/ds';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from 'src/core/ds/accordion';
import Box from 'src/core/ds/box';
import Flex, { FlexProps } from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Separator } from 'src/core/ds/separator';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { getPaymentById } from 'src/utils/payments';
import { BillType } from 'src/utils/types';
import { BillDetails } from '../BillDetails';
import { PaymentActivityContainer } from '../PaymentDetails';
import { PaymentTransactionStatus } from '../PaymentTransactionStatus';
import { GalleryItemsContainer } from './components/Gallery';
import { PaymentActivity } from './PaymentActivity';
import { PaymentApprovalLogs } from './PaymentApprovalLogs';
import { PaymentStatus } from './PaymentStatus';
import { PAGE_EVENT } from './SelectedItemsGallery';

type Props = {
  bill: BillType;
  paymentId: string;
  itemsAmount: number;
};

export const SectionHeader = forwardRef<FlexProps, 'div'>((props, ref) => (
  <Flex
    marginTop={5}
    marginBottom={5}
    justifyContent="space-between"
    width="100%"
    verticalAlign="center"
    {...props}
    ref={ref}
  />
));

export const PaymentBatchView: React.FC<Props> = ({ bill, paymentId, itemsAmount }) => {
  const { showPaymentAuditLog } = useOrganizationPreferences();
  const [isOpen, toggleOpen] = React.useReducer((state) => !state, false);
  const payment = getPaymentById(bill.payments, paymentId);

  if (payment) {
    return (
      <Box my={isOpen ? 12 : 'auto'}>
        <GalleryItemsContainer justify="flex-start" itemsAmount={itemsAmount}>
          <PaymentStatus bill={bill} payment={payment} />
          <Box pb={10} px={8} data-testid="pending-approval-payment-batch-view">
            <PaymentTransactionStatus payment={payment} />
            <Separator color="grey.400" width="111.5%" ml={-8} />
            <Accordion allowToggle onChange={() => toggleOpen()}>
              <AccordionItem>
                {({ isExpanded }) => (
                  <>
                    <AccordionButton
                      as={SectionHeader}
                      textStyle={{
                        base: 'body1Semi',
                        md: 'h2Semi',
                      }}
                    >
                      <Flex
                        justify="space-between"
                        alignItems="center"
                        width="full"
                        data-testid="payment-details-title"
                      >
                        <MIFormattedText label="bills.form.paymentActivity.title" />
                        {isExpanded ? (
                          <Icon
                            cursor="pointer"
                            name={IconNames.shevronUp}
                            size={IconSize.s}
                            color="grey.700"
                            _hover={{ fill: 'black' }}
                          />
                        ) : (
                          <Icon
                            cursor="pointer"
                            name={IconNames.shevronDown}
                            size={IconSize.s}
                            color="grey.700"
                            _hover={{ fill: 'black' }}
                          />
                        )}
                      </Flex>
                    </AccordionButton>
                    <AccordionPanel>
                      <PaymentActivityContainer data-testid="payment-details-content">
                        {showPaymentAuditLog && (
                          <>
                            <PaymentApprovalLogs payment={payment} />
                            <Separator mb={4} />
                          </>
                        )}
                        <PaymentActivity payment={payment} bill={bill} />
                      </PaymentActivityContainer>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
            <Separator color="grey.400" width="111.5%" ml={-8} />
            <Box paddingTop={8}>
              <BillDetails bill={bill} eventPage={PAGE_EVENT} />
            </Box>
          </Box>
        </GalleryItemsContainer>
      </Box>
    );
  }

  return null;
};
