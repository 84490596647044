import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  onClick: () => void;
  isActive: boolean;
  isDisabled?: boolean;
};

export const SearchIcon = ({ onClick, isActive, isDisabled }: Props) => {
  const intl = useIntl();
  const ariaLabel = intl.formatMessage({ id: 'list.tooltips.searchAriaLabel' });

  return (
    <Tooltip placement="top" label={<MIFormattedText label="list.tooltips.search" />}>
      <IconButton
        testId="search-icon"
        aria-label={ariaLabel}
        isDisabled={isDisabled}
        onClick={onClick}
        isActive={isActive}
        __css={{
          _active: {
            color: 'black',
          },
        }}
        icon={<Icon name={IconNames.search} />}
      />
    </Tooltip>
  );
};
