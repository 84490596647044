import { Location } from 'history';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteChildrenProps, RouteComponentProps } from 'react-router-dom';
import authStore from 'src/modules/auth/auth-store';
import { authLocations } from 'src/pages/auth/locations';
import { LoginPageContainer } from 'src/pages/auth/login';
import NotFoundPage from 'src/pages/general/components/NotFoundPage';
import { UserAuthType } from 'src/utils/consts';

function getNotAllowedRoutingAction(authType, notAllowedComponent) {
  if (notAllowedComponent) {
    return notAllowedComponent;
  }

  switch (authType) {
    case UserAuthType.EMAIL_NOT_VERIFIED:
      return <Redirect to={authLocations.register.codeVerification} />;
    case UserAuthType.TWO_FA_REQUIRED:
      return <Redirect to={authLocations.register.authCodeVerification} />;
    case UserAuthType.UNAUTHENTICATED:
    case UserAuthType.GUEST:
      return <LoginPageContainer />;
    default:
      return <NotFoundPage />;
  }
}

export type GuardedRouteType = {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  render?: (router: RouteComponentProps<any>) => React.ReactNode;
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode;
  path?: string | ReadonlyArray<string>;
  exact?: boolean;
  strict?: boolean;
  location?: Location;
  sensitive?: boolean;
  allowFor?: string | string[];
  notAllowedComponent?: React.ReactElement<any>;
  allowCallbackFn?: () => boolean;
};
export const SmartRoute = memo((props: GuardedRouteType) => {
  const authType = useSelector(authStore.selectors.userAuthType);
  const { allowFor, notAllowedComponent, allowCallbackFn, path, ...rest } = props;
  let allowed = true;

  if (allowFor) {
    let allowedResult: string[] = [];
    allowedResult = allowedResult.concat(allowFor);
    allowed = allowedResult.includes(authType);
  }

  if (allowCallbackFn) {
    allowed = allowed && allowCallbackFn();
  }

  return allowed ? (
    <Route {...rest} path={path instanceof Array ? [...path] : path} />
  ) : (
    getNotAllowedRoutingAction(authType, notAllowedComponent)
  );
});
