const baseStyle = {
  borderRadius: 'lg',
  borderColor: 'transparent',
  boxShadow: 'none',
  cursor: 'pointer',
  _disabled: {
    cursor: 'not-allowed',
  },
  _hover: {
    color: 'black',
    _disabled: {
      bg: 'initial',
    },
  },
};

function variantActionNaked() {
  const disabled = {
    color: 'grey.500',
  };

  return {
    color: 'grey.700',
    bg: 'transparent',
    border: 'none',
    _hover: {
      _disabled: disabled,
    },
    _active: {
      bg: 'transparent',
      color: 'black',
    },
    _disabled: disabled,
  };
}

function variantAction() {
  const disabled = {
    svg: {
      color: 'grey.500',
    },
    bg: 'grey.400',
  };

  return {
    color: 'grey.400',
    svg: {
      color: 'black',
    },
    w: '4rem',
    h: '4rem',
    bg: 'grey.300',
    borderRadius: 'lg',
    borderColor: 'transparent',
    _hover: {
      bg: 'grey.400',
      _disabled: disabled,
    },
    _active: {
      bg: 'grey.400',
      svg: {
        color: 'grey.700',
      },
    },
    _disabled: disabled,
  };
}

const variants = {
  actionNaked: variantActionNaked,
  action: variantAction,
};

const sizes = {
  lg: {
    apply: 'textStyles.body3Semi',
    w: 10,
    h: 10,
    minW: 'auto',
  },
  md: {
    apply: 'textStyles.body4Semi',
    w: 6,
    h: 6,
    minW: 'auto',
  },
  sm: {
    apply: 'textStyles.body4',
    w: 5,
    h: 5,
    minW: 'auto',
  },
};

const defaultProps = {
  variant: 'actionNaked',
  size: 'md',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
