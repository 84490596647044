import { MINotificationCard, MINotificationCardProps } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';

type Props = Pick<MINotificationCardProps, 'type' | 'title' | 'subtitle'>;

export const RenderMessage = (props: Props) => (
  <Box
    mx={{
      base: 4,
      lg: 8,
    }}
    mt={8}
  >
    <MINotificationCard {...props} />
  </Box>
);
