import noop from 'lodash/noop';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import config from 'src/config';

type Props = {
  label: string;
  onClickUserAgreement?: () => void;
  onClickPrivacyPolicy?: () => void;
  textValues?: Record<string, any>;
  className?: string;
};

const RegistrationTermsAndConditions = (props: Props) => (
  <TermsAndConditionsContainer className={props.className}>
    <MIFormattedText
      label={props.label}
      values={{
        userAgreement: (
          <StyledMIInlineLink
            label="auth.regularRegister.userAgreement"
            to={config.agreementLinks.userAgreement}
            onClick={props.onClickUserAgreement ? props.onClickUserAgreement : noop}
          />
        ),
        privacyPolicy: (
          <StyledMIInlineLink
            label="auth.regularRegister.privacyPolicy"
            to={config.agreementLinks.privacyPolicy}
            onClick={props.onClickPrivacyPolicy ? props.onClickPrivacyPolicy : noop}
          />
        ),
        ...props.textValues,
      }}
    />
  </TermsAndConditionsContainer>
);

const TermsAndConditionsContainer = styled.div`
  text-align: center;
  font-size: ${(props) => props.theme.text.size.hint};
  margin-top: 4rem;
  margin-bottom: 2.2rem;
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: inherit;
  text-decoration: underline;
`;
export default RegistrationTermsAndConditions;
