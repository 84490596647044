import { isAfter, subDays } from 'date-fns';

const getIsDateWithinLast7Days = (date) => isAfter(new Date(date), subDays(new Date(), 7));

export const splitPaymentsByUpdatedAt = (payments) =>
  payments.reduce(
    (acc, payment) => {
      if (getIsDateWithinLast7Days(payment.invoice.updatedAt)) {
        acc.statusUpdatedWithinLast7DaysPayments = [...acc.statusUpdatedWithinLast7DaysPayments, payment];
      } else {
        acc.statusUpdatedEarlierPayments = [...acc.statusUpdatedEarlierPayments, payment];
      }

      return acc;
    },
    {
      statusUpdatedWithinLast7DaysPayments: [],
      statusUpdatedEarlierPayments: [],
    }
  );
