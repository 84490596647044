import { useCallback } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { analytics } from 'src/services/analytics';

type Props = {
  onClick: () => void;
  isDisabled: boolean;
};

export const ClearAll = ({ onClick, isDisabled }: Props) => {
  const hoverProperties = { color: isDisabled ? 'grey.600' : 'primary.500' };

  const onClickWithEvent = useCallback(() => {
    if (!isDisabled) {
      analytics.track('filter-menu', 'clear-all-filters');
      onClick();
    }
  }, [isDisabled, onClick]);

  return (
    <Box
      onClick={onClickWithEvent}
      _hover={hoverProperties}
      color={isDisabled ? 'grey.600' : 'black'}
      cursor="pointer"
      textStyle="body4Semi"
      mt={{ md: 5 }}
      mb={{ md: 3 }}
      ml={{ md: 5 }}
    >
      <MIFormattedText label="pay.filter.clearAll" />
    </Box>
  );
};
