import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { financingStore } from 'src/modules/financing/financing-store';
import { usePayBillFinancingData } from 'src/pages/bill/pay/hooks/usePayBillFinancingData';

export const useSetPreSelectedInstallment = () => {
  const { setInstallments } = usePayBillFinancingData();

  const currentIntentId = useSelector(financingStore.selectors.intentIdWithInstallmentOption.intentId);
  const currentNumberOfInstallments = useSelector(
    financingStore.selectors.intentIdWithInstallmentOption.numberOfInstallments
  );

  useEffect(() => {
    if (currentIntentId !== undefined && currentNumberOfInstallments !== undefined)
      setInstallments(currentIntentId, currentNumberOfInstallments);
  }, [currentIntentId, currentNumberOfInstallments, setInstallments]);
};
