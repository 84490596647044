import { featureFlags } from '@melio/shared-web';
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { EmailLink } from 'src/components/common/EmailLink';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import { Image } from 'src/core/ds/image';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import ErrorImage from 'src/images/core/error-icon.svg';
import { paymentsApi } from 'src/modules/payments/api';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { VirtualCardRecoverSuccessView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardRecoverSuccessView';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { eventPage } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardView';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/featureFlags';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardExpiredView = ({ state, analyticsProperties }: Props) => {
  const site = useSiteContext();
  const match = useRouteMatch<{ token?: string }>();
  const token = match.params?.token || '';
  const [showSuccessView, setShowSuccessView] = useState(false);
  const { organization, virtualCard, payment } = state;
  const { onApiCall: retryFailedToDeliverWithToken, loading } = useApi<[string], Record<string, any>>({
    api: paymentsApi.retryFailedToDeliverWithToken,
  });
  const [disableSelfRecoveryFlows] = featureFlags.useAnonymousFeature(FeatureFlags.DisableSelfRecoveryFlows, false);

  useEffect(() => {
    analytics.track(eventPage, 'expired', analyticsProperties);
  }, [analyticsProperties]);

  const handleClick = async () => {
    try {
      await retryFailedToDeliverWithToken(token);
      analytics.track(eventPage, 'expired_click-cta', analyticsProperties);
      setShowSuccessView(true);
    } catch (ex: any) {
      analytics.track(eventPage, 'expired_click-cta-error', analyticsProperties);
    }
  };

  if (showSuccessView) {
    return <VirtualCardRecoverSuccessView state={state} analyticsProperties={analyticsProperties} />;
  }

  return (
    <ContentWrapper>
      <Image width={8} height={8} src={ErrorImage} />
      <Box textStyle="body2Semi" mt={5} mb={2}>
        <MIFormattedText
          label="vendors.virtualCard.expired.title"
          values={{
            expDate: <MIFormattedDate date={virtualCard?.expirationDate} />,
          }}
        />
      </Box>
      <Box textStyle="body2">
        <MIFormattedText
          label="vendors.virtualCard.expired.description"
          values={{
            payorName: organization?.companyName,
            amount: <MIFormattedCurrency value={payment?.amount} />,
          }}
        />
      </Box>
      {disableSelfRecoveryFlows ? (
        <Box textStyle="body3" my={5}>
          <MIFormattedText
            label="vendors.virtualCard.expired.contactSupport"
            values={{
              supportEmail: <EmailLink email={site.config.support.email} />,
            }}
          />
        </Box>
      ) : (
        <>
          <Box my={5}>
            <Button label="vendors.virtualCard.expired.ctaLabel" w="full" onClick={handleClick} isLoading={loading} />
          </Box>
          <Box textStyle="body3">
            <MIFormattedText
              label="vendors.virtualCard.expired.notice"
              values={{
                supportEmail: <EmailLink email={site.config.support.email} />,
              }}
            />
          </Box>
        </>
      )}
    </ContentWrapper>
  );
};
