import React from 'react';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoHint,
  PaymentInfoText,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { BillStatus, DeliveryType, PaymentApprovalStatus } from 'src/utils/consts';
import { BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
  bill: BillType;
  deliveryMethod: DeliveryMethodType;
};

export const ViewBillPaymentActivityDeductionDateInfo = ({ payment, bill, deliveryMethod }: Props) => {
  const isBillPaid = bill.status === BillStatus.PAID;
  const deductionDateLabel = isBillPaid
    ? 'viewBillPaymentActivity.deductionDate.paidLabel'
    : 'viewBillPaymentActivity.deductionDate.unpaidLabel';

  const isApprovalRequired = payment.approvalDecisionStatus === PaymentApprovalStatus.PENDING;
  const isUnitaleral = deliveryMethod.deliveryType === DeliveryType.VIRTUAL;
  const hint = isApprovalRequired && !isUnitaleral ? 'viewBillPaymentActivity.deductionDate.approvalRequiredHint' : '';

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon isBillPaid={isBillPaid}>
            <Icon name={IconNames.scheduled} size={IconSize.lg} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={deductionDateLabel} />
            </PaymentMethodText>
            <PaymentInfoText isBillPaid={isBillPaid}>
              <MIFormattedDate date={payment.scheduledDate} />
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
      </PaymentActivityMainContainer>
      {!isBillPaid && hint && (
        <PaymentInfoHint>
          <MIFormattedText label={hint} />
        </PaymentInfoHint>
      )}
    </PaymentActivityContainer>
  );
};
