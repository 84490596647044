import { VStack } from '@chakra-ui/layout';
import { featureFlags } from '@melio/shared-web';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { MIRadioGroup } from 'src/components/form/MIRadioGroup';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import Box from 'src/core/ds/box';
import { useModal } from 'src/helpers/react/useModal';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { BANK_ACCOUNT_VERIFIED_ABSORBED_FEES_HELP_LINK } from 'src/pages/settings/constants';
import { useVendorAbsorbedFeeSetting } from 'src/pages/settings/hooks/useVendorAbsorbedFeeSetting';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import {
  ButtonsDirections,
  CreditCardFeePayment as CreditCardFeePaymentEnum,
  DialogType,
  DialogVariants,
  FeatureFlags,
  NotificationCardTypes,
} from 'src/utils/consts';
import { DeliveryMethodType, PendingDeliveryMethodType } from 'src/utils/types';
import { useDeliveryMethodMicroDepositState } from '../hooks/useDeliveryMethodMicroDeposits';
import {
  getActiveACHDeliveryMethodByVerifiedStatus,
  getPendingACHDeliveryMethodByVerifiedStatus,
} from '../utils/deliveryMethodsFilter';
import DeliveryMethodsList from './DeliveryMethodsList';
import { UpdateReceivingMethodConfirmationModal } from './UpdateReceivingMethodConfirmationModal';

const EVENT_PAGE = 'receiving-methods-settings';

interface DeliveryMethodsProps {
  goAddDeliveryMethods: () => void;
  deliveryMethods: readonly DeliveryMethodType[];
  tryDeleteDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  tryEditDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  onVerifyClicked?: (id: number) => void;
  goViewDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  goEditBankAccountLink: (deliveryMethod: DeliveryMethodType) => void;
  pendingDeliveryMethods: readonly PendingDeliveryMethodType[];
  pendingACHDeliveryMethod?: PendingDeliveryMethodType;
  activeACHDeliveryMethod?: DeliveryMethodType;
  onUpdateDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
}

interface EditDialogProps {
  hasScheduledPayments: boolean;
  goEditDeliveryMethod: () => void;
  cancelEditDeliveryMethod: () => void;
}

interface DeleteDialogProps {
  hasScheduledPayments: boolean;
  cancelDeleteDeliveryMethod: () => void;
  onDeleteDeliveryMethod: () => void;
}

interface MicroDepositsDialogProps {
  verifyingId: number;
  onVerifyFinished: (isSuccess?: boolean) => void;
}

interface UpdateReceivingMethodConfirmationModalProps {
  onCancelUpdateDeliveryMethod: () => void;
}

interface CreditCardFeePayment {
  deliveryMethods: readonly DeliveryMethodType[];
}

interface ReceivingMethodsProps
  extends DeliveryMethodsProps,
    EditDialogProps,
    DeleteDialogProps,
    UpdateReceivingMethodConfirmationModalProps,
    Omit<MicroDepositsDialogProps, 'verifyingId'> {
  showDeleteDialog: boolean;
  showEditDialog: boolean;
  deliveryMethodToDelete: DeliveryMethodType;
  deliveryMethodToEdit: DeliveryMethodType;
  verifyingId: number | null;
  showUpdateDeliveryMethodConfirmationDialog?: boolean;
}

const CreditCardFeePayment = ({ deliveryMethods }: CreditCardFeePayment) => {
  const hasVerifiedDeliveryMethod = !every(deliveryMethods, ['verifiedStatus', 'not-verified']);
  const [vendorAbsorbsFeesSetting, setVendorAbsorbsFeesSetting] = useVendorAbsorbedFeeSetting();
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const url = shouldDisplayZendesk
    ? 'https://melio.zendesk.com/hc/en-us/articles/4416227178908-How-can-I-pay-the-credit-card-fee-on-behalf-of-my-customers'
    : BANK_ACCOUNT_VERIFIED_ABSORBED_FEES_HELP_LINK;

  const [FeesConfirmationModal, showDialog] = useModal(CommonDialog, {
    variant: DialogVariants.SUCCESS,
    type: DialogType.CONFIRM,
    title: 'settings.creditCardFeePayment.feesApprovalDialog.customerFees.title',
    description: 'settings.creditCardFeePayment.feesApprovalDialog.customerFees.description',
    showCancel: false,
    fullWidth: true,
    confirmText: 'settings.creditCardFeePayment.feesApprovalDialog.confirm',
    buttonsDirection: ButtonsDirections.HORIZONTAL,
    hideIcon: true,
  });

  if (isEmpty(deliveryMethods)) return null;

  const disabled = !hasVerifiedDeliveryMethod;

  if (disabled && vendorAbsorbsFeesSetting) {
    setVendorAbsorbsFeesSetting({
      value: false,
    });
  }

  const selected = vendorAbsorbsFeesSetting ? CreditCardFeePaymentEnum.VENDOR : CreditCardFeePaymentEnum.PAYOR;

  const onSelect = (selection: CreditCardFeePaymentEnum) => {
    const isVendorAbsorbedFees = selection === CreditCardFeePaymentEnum.VENDOR;

    if (selected !== selection) {
      showDialog({
        title: isVendorAbsorbedFees
          ? 'settings.creditCardFeePayment.feesApprovalDialog.vendor.title'
          : 'settings.creditCardFeePayment.feesApprovalDialog.payor.title',
        description: isVendorAbsorbedFees
          ? 'settings.creditCardFeePayment.feesApprovalDialog.vendor.description'
          : 'settings.creditCardFeePayment.feesApprovalDialog.payor.description',
        confirm: async () => {
          setVendorAbsorbsFeesSetting({
            value: isVendorAbsorbedFees,
          });
        },
      });
    }
  };
  const options = [
    {
      id: CreditCardFeePaymentEnum.PAYOR,
      label: 'settings.creditCardFeePayment.options.payor.title',
      component: (
        <OptionContainer>
          <MIFormattedText label="settings.creditCardFeePayment.options.payor.subtitle" />
        </OptionContainer>
      ),
    },
    {
      id: CreditCardFeePaymentEnum.VENDOR,
      label: 'settings.creditCardFeePayment.options.vendor.title',
      component: (
        <OptionContainer>
          <MIFormattedText label="settings.creditCardFeePayment.options.vendor.subtitle" />
        </OptionContainer>
      ),
    },
  ];

  return (
    <MICard>
      <MICardForm>
        <MICardTitle label="settings.creditCardFeePayment.title" />
        {disabled && (
          <Subtitle>
            <MIFormattedText
              label="settings.creditCardFeePayment.subtitle"
              values={{
                link: (
                  <PlainLink href={url} target="_blank" rel="noopener noreferrer">
                    <MIFormattedText label="settings.creditCardFeePayment.link" />
                  </PlainLink>
                ),
              }}
            />
          </Subtitle>
        )}
        <OptionsContainer>
          <MIRadioGroup
            id="creditCardFeePayment"
            group="creditCardFeePayment"
            options={options}
            selected={selected}
            disabled={disabled}
            onSelect={onSelect}
            colDirection
          />
        </OptionsContainer>
      </MICardForm>
      {FeesConfirmationModal}
    </MICard>
  );
};

const DeliveryMethods = ({
  goAddDeliveryMethods,
  deliveryMethods,
  tryDeleteDeliveryMethod,
  tryEditDeliveryMethod,
  onVerifyClicked,
  goViewDeliveryMethod,
  goEditBankAccountLink,
  pendingDeliveryMethods,
  onUpdateDeliveryMethod,
}: DeliveryMethodsProps) => {
  const [shouldDisplayMsnMVPContent] = featureFlags.useFeature(FeatureFlags.MsnMvp1);
  const pendingACHDeliveryMethod = getPendingACHDeliveryMethodByVerifiedStatus(pendingDeliveryMethods);
  const activeACHDeliveryMethod = getActiveACHDeliveryMethodByVerifiedStatus(deliveryMethods);

  return (
    <DeliveryMethodsBox data-testid="cc-fee-payment-setting">
      <MICardForm>
        <VStack spacing={5}>
          <Box textStyle="h2Semi" color="black" w="full">
            <MIFormattedText label="settings.receivingMethods.title" />
          </Box>
          {shouldDisplayMsnMVPContent && pendingACHDeliveryMethod && activeACHDeliveryMethod ? (
            <Box w="full">
              <MINotificationCard
                type={NotificationCardTypes.INFO}
                subtitle={{
                  label: 'settings.receivingMethods.pendingAlert',
                  values: {
                    activeBankAccount: getAccountNumber4digits(activeACHDeliveryMethod.bankAccount),
                    pendingBankAccount: getAccountNumber4digits(pendingACHDeliveryMethod.bankAccount),
                  },
                }}
              />
            </Box>
          ) : (
            <></>
          )}
        </VStack>
        <DeliveryMethodsList
          deliveryMethods={deliveryMethods}
          goAddDeliveryMethods={goAddDeliveryMethods}
          goEditDeliveryMethod={tryEditDeliveryMethod}
          onDeleteDeliveryMethod={tryDeleteDeliveryMethod}
          onVerifyClicked={onVerifyClicked}
          goEditBankAccountLink={goEditBankAccountLink}
          goViewDeliveryMethod={goViewDeliveryMethod}
          pendingDeliveryMethods={pendingDeliveryMethods}
          onUpdateDeliveryMethod={onUpdateDeliveryMethod}
        />
      </MICardForm>
    </DeliveryMethodsBox>
  );
};

const MicroDepositsDialog = ({ verifyingId, onVerifyFinished }: MicroDepositsDialogProps) => {
  const microDepositEventPage = 'settings-verify-manual-account';
  const dialogSuccessTitle = 'settings.microDeposits.verifyDialogSuccess.receivingMethods.title';
  const dialogSuccessSubtitle = 'settings.microDeposits.verifyDialogSuccess.receivingMethods.subtitle';
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);

  const [state, actions] = useDeliveryMethodMicroDepositState(microDepositEventPage, {
    deliveryMethodId: verifyingId,
    onSuccess: onVerifyFinished,
    orgId,
    vendorId: vendorId!,
  });

  return (
    <VerifyMicroDeposits
      {...state}
      {...actions}
      key={verifyingId}
      verifyingId={verifyingId}
      onVerifyFinished={onVerifyFinished}
      eventPage={microDepositEventPage}
      dialogSuccessTitle={dialogSuccessTitle}
      dialogSuccessSubtitle={dialogSuccessSubtitle}
    />
  );
};

const EditDialog = ({ hasScheduledPayments, goEditDeliveryMethod, cancelEditDeliveryMethod }: EditDialogProps) => {
  if (hasScheduledPayments) {
    analytics.track(EVENT_PAGE, 'edit-receiving-method-dialog');

    return (
      <Dialog
        type={DialogType.CONFIRM}
        variant={DialogVariants.ERROR}
        title="settings.receivingMethods.dialog.edit.title"
        subtitle="settings.receivingMethods.dialog.edit.subtitle"
        okButtonText="settings.receivingMethods.dialog.edit.confirm"
        onOkAction={goEditDeliveryMethod}
        onCancelAction={cancelEditDeliveryMethod}
      />
    );
  }

  goEditDeliveryMethod();

  return null;
};

const DeleteDialog = ({
  hasScheduledPayments,
  cancelDeleteDeliveryMethod,
  onDeleteDeliveryMethod,
}: DeleteDialogProps) => {
  if (hasScheduledPayments) {
    analytics.track(EVENT_PAGE, 'cannot-delete-receiving-method-dialog');

    return (
      <Dialog
        type={DialogType.ALERT}
        variant={DialogVariants.ERROR}
        title="settings.receivingMethods.dialog.delete.titleUndeletable"
        subtitle="settings.receivingMethods.dialog.delete.subtitleUndeletable"
        onCancelAction={cancelDeleteDeliveryMethod}
      />
    );
  }

  analytics.track(EVENT_PAGE, 'delete-receiving-method-dialog');

  return (
    <Dialog
      type={DialogType.CONFIRM}
      variant={DialogVariants.ERROR}
      title="settings.receivingMethods.dialog.delete.titleDeletable"
      subtitle="settings.receivingMethods.dialog.delete.subtitleDeletable"
      okButtonText="settings.receivingMethods.dialog.delete.confirm"
      onOkAction={onDeleteDeliveryMethod}
      onCancelAction={cancelDeleteDeliveryMethod}
    />
  );
};

export const ReceivingMethodsSettingsPage = (props: ReceivingMethodsProps) => {
  const {
    showDeleteDialog,
    showEditDialog,
    deliveryMethodToDelete,
    deliveryMethodToEdit,
    verifyingId,
    onCancelUpdateDeliveryMethod,
    goEditDeliveryMethod,
    showUpdateDeliveryMethodConfirmationDialog,
  } = props;

  const isMSNPortalVendor = useIsMsnDashboardDisplay();

  const isUpdateReceivingMethodConfirmationDialogOpen =
    deliveryMethodToEdit && showUpdateDeliveryMethodConfirmationDialog;

  return (
    <>
      {showDeleteDialog && deliveryMethodToDelete && <DeleteDialog {...props} />}
      {showEditDialog && deliveryMethodToEdit && <EditDialog {...props} />}
      <UpdateReceivingMethodConfirmationModal
        onClose={onCancelUpdateDeliveryMethod}
        deliveryMethod={deliveryMethodToEdit}
        onConfirm={goEditDeliveryMethod}
        isOpen={Boolean(isUpdateReceivingMethodConfirmationDialogOpen)}
      />
      <DeliveryMethods {...props} />
      {!isMSNPortalVendor && <CreditCardFeePayment {...props} />}
      {verifyingId && <MicroDepositsDialog {...(props as MicroDepositsDialogProps)} />}
    </>
  );
};

const Subtitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  color: ${(props) => props.theme.colors.text};
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const OptionsContainer = styled.div`
  margin-top: 3.2rem;
`;

const OptionContainer = styled.div`
  color: ${(props) => props.theme.text.color.label};
  ${(props) => props.theme.text.fontType.medium}
`;

const PlainLink = styled.a`
  text-decoration: none;

  color: ${(props) => props.theme.colors.brand};
`;

const DeliveryMethodsBox = styled(MICard)`
  overflow: visible;
`;
