import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import sum from 'lodash/fp/sum';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { CopyOrCreatePaymentLink } from 'src/components/common/CopyOrCreatePaymentLink';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FONT_WEIGHT } from 'src/core/theme/foundations/textStyles';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import { billsStore } from 'src/modules/bills/bills-store';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { profileStore } from 'src/modules/profile/profile-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { billLocations } from 'src/pages/bill/locations';
import {
  BusinessProfileModal,
  BusinessProfileModalSizeType,
} from 'src/pages/contacts/list/components/BusinessProfileModal';
import { ContactsPlaceholder } from 'src/pages/contacts/list/components/ContactsPlaceholder';
import { ContactViewHeader, ContactViewHeaderMobile } from 'src/pages/contacts/list/components/ContactViewHeader';
import { PaymentsOverviewContainer } from 'src/pages/contacts/list/components/PaymentsOverview/PaymentsOverviewContainer';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getContactActions } from 'src/pages/contacts/utils';
import { globalLocations } from 'src/pages/locations';
import { VendorDeliveryMethodsList } from 'src/pages/vendor/components/VendorDeliveryMethodsList';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { vendorLocations } from 'src/pages/vendor/locations';
import { getVendorBillerId, isDirectoryVendor, isRppsVendor } from 'src/pages/vendor-directory/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { convertToDisplayAddress } from 'src/utils/address';
import {
  BillStatus,
  ContactsTab,
  CopyLinkSize,
  DeliveryMethodOrigin,
  DeliveryType,
  DialogType,
  DialogVariants,
  NotificationVariant,
  ScreenMode,
  VendorManagedByEnum,
} from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { getVendorHandleURL } from 'src/utils/organizations';
import { AddressType, DeliveryMethodType, UserContextType, VendorFormType, VendorType } from 'src/utils/types';
import { sendVendorDetailsRequest } from 'src/utils/vendors';
import { CollectVendorEmailModalMessage } from './CollectVendorEmailModalMessage';
import { VendorForm } from './VendorForm';

type Props = {
  vendor: VendorType<'created'> | null;
  profile: UserContextType;
  users: readonly UserContextType[];
  isSingleLoading?: boolean;
  isLoading?: boolean;
  listBaseSearch: string;
  loadSelectedContact: () => void;
};

const eventPage = 'contacts';

export const VendorView = ({
  vendor,
  profile,
  users,
  isSingleLoading,
  isLoading,
  listBaseSearch,
  loadSelectedContact,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const dispatch = useDispatch();
  const history = useHistory();
  const [historyPush, historyReplace] = useHistoryWithOrgId();
  const { isMobile, isPhablet, isDesktop } = useBreak();
  const contactDetailsRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<ScreenMode>(ScreenMode.VIEW);
  const contactActions = getContactActions(ContactsTab.VENDORS)(dispatch);
  const allowedCountersNames = [BillStatus.UNPAID, BillStatus.PAID, BillStatus.SCHEDULED];
  const vendorBillsCount = pick(
    useSelector(vendorsStore.selectors.countBillsByType.getVendorBillsCount(vendor?.id)),
    allowedCountersNames
  );
  const totalBillsCount = flow(Object.values, map('count'), sum)(vendorBillsCount);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const [isDeleting, setIsDeliting] = useState<boolean>(false);
  const [deleteErrorCode, setDeleteErrorCode] = useState<string>('');
  const isMobileOrPhablet = isMobile || isPhablet;
  const isVendorNotOwnedByUser = !vendor?.ownedById;
  const isEditable = vendor?.isEditable ?? true;
  const showDeliveryMethodsList = isEditable && isVendorNotOwnedByUser;
  const canCreateNewBill = isVendorNotOwnedByUser || (!isVendorNotOwnedByUser && vendor?.hasDeliveryMethods);
  const actionOptions: ActionOption[] = [];
  const billsFilters = {
    start: 0,
    limit: Number.MAX_SAFE_INTEGER,
    vendorId: vendor?.id,
  };
  const bills = useSelector((state) =>
    billsStore.selectors.list.value(state, {
      orgId,
      filters: billsFilters,
    })
  );
  const existingVirtualDeliveryMethod = vendor?.deliveryMethods?.find((dm) => dm.deliveryType === DeliveryType.VIRTUAL);
  const shouldPresentAsDirectoryVendor = Boolean(vendor && isDirectoryVendor(vendor));
  const isRPPSVendor = vendor && isRppsVendor(vendor);
  const isMsnVendor = vendor?.managedBy === VendorManagedByEnum.MSN;
  const showBusinessProfileLink = shouldPresentAsDirectoryVendor && !isRPPSVendor;
  const [showBusinessProfileModal, setShowBusinessProfileModal] = useState<boolean>(false);

  const openBusinessProfileModalHandler = () => {
    setShowBusinessProfileModal(true);
  };

  const closeBusinessProfileModalHandler = () => {
    setShowBusinessProfileModal(false);
  };

  const vendorMV = useMemo(
    () => ({
      id: vendor?.id,
      companyName: vendor?.companyName ?? '',
      accountIdentifier: vendor?.accountIdentifier,
      address: vendor?.address && convertToDisplayAddress(vendor?.address as AddressType),
      contactName: vendor?.contactName ?? '',
      contactEmail: vendor?.contactEmail ?? '',
      contactPhone: vendor?.contactPhone ?? '',
    }),
    [vendor]
  );
  const [vendorData, { submit: submitForm, cancel: cancelForm }] = useForm<VendorFormType>(vendorMV, {
    submit: async (vendor) => {
      contactActions.update({ billerId: getBillerId(), orgId, ...vendor });
    },
    validator: (key: string, value: string): string | undefined => {
      const validationErrors = getValidationErrors('vendor', { [key]: value });

      return validationErrors[key];
    },
  });
  const getBillerId = () => getVendorBillerId({ ...vendor, deliveryMethods: vendor?.deliveryMethods ?? [] });

  const resetState = useCallback(() => {
    setMode(ScreenMode.VIEW);
    setIsDeliting(false);
    cancelForm();
    setDeleteErrorCode('');
  }, [vendor]);

  useEffect(() => {
    resetState();
  }, [vendor]);

  const onToggleMode = () => {
    setMode(mode === ScreenMode.VIEW ? ScreenMode.EDIT : ScreenMode.VIEW);
  };

  useEffect(() => {
    const goViewContactDetails = () => {
      if (isDesktop) {
        contactDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    };

    if (mode === ScreenMode.EDIT) {
      goViewContactDetails();
    }
  }, [mode, isDesktop]);

  const onEdit = () => {
    onToggleMode();
    analytics.trackAction('edit-vendor', {
      vendorId: vendor?.id,
      billerId: getBillerId(),
    });
  };

  const onCancel = () => {
    onToggleMode();
    cancelForm();
    analytics.trackAction('cancel-vendor', {
      vendorId: vendor?.id,
      billerId: getBillerId(),
    });
  };

  const onDeleteVendorClicked = () => setIsDeliting(true);
  const onDeleteVendorCanceled = () => {
    setIsDeliting(false);
    analytics.track(eventPage, 'delete-vendor-canceled');
  };

  const onEditContactClick = async () => {
    const validationErrors = getValidationErrors('vendor', mapValues(vendorData, 'value'));

    if (isValidationOk(validationErrors)) {
      try {
        await submitForm();
        setMode(ScreenMode.VIEW);
      } catch (error: any) {
        console.error({ error });
        vendorData?.setValidationErrors({ companyName: `server.${error?.error?.code}` });
      }
    }
  };

  const onKeyPressed = (event: React.KeyboardEvent) => {
    if (mode === ScreenMode.EDIT && isEnterPressed(event)) {
      submitForm();
    }
  };

  const goCreateBill = () => {
    analytics.track(eventPage, 'add-bill');
    const { id: vendorId } = vendor!;
    historyPush({
      path: billLocations.create.index,
      query: {
        vendorId,
        manually: 'true',
      },
    });
  };

  const goEditDeliveryMethod = (deliveryMethod: DeliveryMethodType) => {
    const isACHDeliveryMethod = deliveryMethod?.deliveryType === DeliveryType.ACH;
    const deliveryMethodId = deliveryMethod.id;
    const { id } = vendor!;
    const locationPrefix = vendorLocations.deliveryMethods[deliveryMethod.deliveryType];
    const redirectUrl = generatePath(vendorLocations.view, {
      orgId,
      id,
      type: ContactsTab.VENDORS,
    });
    let url = deliveryMethodId
      ? generatePath(locationPrefix?.edit, {
          orgId,
          id,
          deliveryMethodId,
        })
      : generatePath(locationPrefix?.create, {
          id,
          orgId,
        });

    if (isACHDeliveryMethod && !deliveryMethodId) {
      url = generatePath(globalLocations.vendor.deliveryMethods.ach.createFlow, {
        orgId,
        id,
      });
    }

    if (isACHDeliveryMethod && deliveryMethodId) {
      url = generatePath(globalLocations.vendor.deliveryMethods.ach.editBankDetails, {
        orgId,
        id,
        deliveryMethodId,
      });
    }

    if (isInternationalDeliveryMethod(deliveryMethod.deliveryType)) {
      url = generatePath(globalLocations.vendor.international.billing, {
        orgId,
        id,
      });
    }

    if (deliveryMethod.id) {
      analytics.track(eventPage, 'edit-delivery-method', {
        type: deliveryMethod.deliveryType,
      });
    } else {
      analytics.track(eventPage, 'add-delivery-method', {
        type: deliveryMethod.deliveryType,
      });
    }

    history.push(url, {
      preservedState: {
        bills,
        totalBillsCount,
      },
      origin: DeliveryMethodOrigin.VENDOR_DETAILS,
      redirectUrl,
      exitUrl: redirectUrl,
    });
  };

  const virtualDeliveryMethod = useMemo(
    () => ({
      deliveryType: DeliveryType.VIRTUAL,
      virtualAccount: {
        email: vendorData?.contactEmail?.value,
        phone: existingVirtualDeliveryMethod?.virtualAccount?.phone || vendorData?.contactPhone?.value,
      },
    }),
    [vendorData]
  );

  const createOrUpdateVirtualDeliveryMethod = async (deliveryMethod) => {
    const vendorId = vendor?.id;

    if (existingVirtualDeliveryMethod?.id) {
      const shouldUpdateVirtualAccount = existingVirtualDeliveryMethod.virtualAccount?.email !== vendor?.contactEmail;

      if (shouldUpdateVirtualAccount) {
        await deliveryMethodActions.update({
          orgId,
          vendorId,
          id: existingVirtualDeliveryMethod.id,
          deliveryMethod,
        });
      }
    } else {
      await deliveryMethodActions.create({
        orgId,
        vendorId,
        ...deliveryMethod,
      });
    }
  };

  const sendUnilateralRequest = () => {
    if (vendor?.contactEmail) {
      createOrUpdateVirtualDeliveryMethod(virtualDeliveryMethod).then(() => {
        sendVendorDetailsRequest(orgId, vendor?.id);
        onUnilateralRequestSent();
      });
    } else {
      analytics.track(eventPage, 'vendor-email-modal ', {
        userId,
        vendorId: vendor?.id,
      });
      showCollectVendorEmailModal();
    }
  };

  const onUnilateralRequestSent = () => {
    loadSelectedContact();
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'vendors.unilateralWithoutPayment.successNotification',
    });
  };

  const [CollectVendorEmailModal, showCollectVendorEmailModal] = useModal(CollectVendorEmailModalMessage, {
    onSuccess: onUnilateralRequestSent,
    onEditContact: onEditContactClick,
    createOrUpdateVirtualDeliveryMethod,
    vendor,
    contactEmail: vendorData.contactEmail,
    eventPage,
    modalName: 'collectEmail',
  });

  const renderAlertDialog = (vendor) => {
    if (!isDeleting) {
      return null;
    }

    let deleteSubText =
      totalBillsCount > 0 ? 'vendors.form.deleteDialog.subtitleWarning' : 'vendors.form.deleteDialog.subtitle';

    if (deleteErrorCode) {
      analytics.track(eventPage, 'cannot-delete-vendor-dialog');
      deleteSubText =
        deleteErrorCode === 'VDR04'
          ? 'vendors.form.deleteDialog.subtitleWarningHasPayments'
          : 'vendors.form.deleteDialog.subtitleWarningHasBills';

      return (
        <Dialog
          type={DialogType.CONFIRM}
          variant={DialogVariants.ERROR}
          title={`server.${deleteErrorCode}`}
          subtitle={deleteSubText}
          onCancelAction={onDeleteVendorCanceled}
        />
      );
    }

    const onDeleteVendor = async () => {
      if (vendor) {
        await contactActions.delete({
          orgId,
          id: vendor.id,
        });

        return historyReplace({
          path: contactsLocations.index,
          params: { type: ContactsTab.VENDORS },
        });
      }

      return null;
    };

    const onDelete = () =>
      onDeleteVendor()
        .then(() => {
          setIsDeliting(false);
        })
        .catch((e) => {
          setDeleteErrorCode(e?.error?.code);
        });
    analytics.track(eventPage, 'delete-vendor-dialog');

    return (
      <Dialog
        type={DialogType.CONFIRM}
        variant={DialogVariants.ERROR}
        title="vendors.form.deleteDialog.title"
        titleValues={{ vendorName: vendor?.companyName }}
        subtitle={deleteSubText}
        subtitleValues={{
          number: totalBillsCount,
          vendorName: vendor?.companyName,
        }}
        okButtonText="vendors.form.deleteDialog.confirm"
        onOkAction={onDelete}
        onCancelAction={onDeleteVendorCanceled}
      />
    );
  };

  if (canCreateNewBill) {
    actionOptions.push({
      label: 'vendors.actions.newBill',
      action: goCreateBill,
    });
  }

  if (isVendorNotOwnedByUser && isEditable) {
    actionOptions.push(
      {
        label: 'vendors.actions.edit',
        action: onEdit,
      },
      {
        label: 'vendors.actions.delete',
        action: onDeleteVendorClicked,
        negative: true,
      }
    );
  }

  const showMenuItems = mode === ScreenMode.VIEW && actionOptions.length > 0;
  const showPageHeader = !(mode === ScreenMode.EDIT && isMobileOrPhablet);
  const businessProfileModalSize: BusinessProfileModalSizeType = isMobileOrPhablet ? 'full' : 'md';

  if ((!vendor?.id && !vendor?.paymentRequestId) || !vendorData?.companyName) {
    return <ContactsPlaceholder isLoading={isSingleLoading} label="contacts.emptyState.vendors.placeholder" />;
  }

  return (
    <>
      <BusinessProfileModal
        isOpen={showBusinessProfileModal}
        onClose={closeBusinessProfileModalHandler}
        size={businessProfileModalSize}
        vendorId={vendor?.id.toString()}
      />
      {CollectVendorEmailModal}
      {renderAlertDialog(vendor)}
      {vendor && !isSingleLoading && (
        <SingleViewLoadingContainer isEditMode={mode === ScreenMode.EDIT} className={isSingleLoading ? 'loading' : ''}>
          <>
            {showPageHeader && (
              <ContactViewHeaderMobile
                contact={vendor}
                backUrl={generatePath(contactsLocations.index, {
                  type: ContactsTab.VENDORS,
                  orgId,
                })}
                backVendorsSearch={listBaseSearch}
                contactType={ContactsTab.VENDORS}
                showMenuItems={showMenuItems}
                actionOptions={actionOptions}
                isMsnVendor={isMsnVendor}
                onBusinessProfileClick={showBusinessProfileLink ? openBusinessProfileModalHandler : undefined}
              />
            )}
            <Box data-testid="vendor-info-container">
              {!isMobileOrPhablet && (
                <ContactViewHeader
                  contact={vendor}
                  showDirectoryVendorLogo={shouldPresentAsDirectoryVendor}
                  actionOptions={actionOptions}
                  showMenuItems={showMenuItems}
                  contactType={ContactsTab.VENDORS}
                  onBusinessProfileClick={showBusinessProfileLink ? openBusinessProfileModalHandler : undefined}
                />
              )}
              <MICard>
                <PaymentsOverviewContainer vendor={vendor} goCreateBill={goCreateBill} />
                <Box ref={contactDetailsRef} />
              </MICard>
              <MICard>
                <MICardForm onKeyDown={(event) => onKeyPressed(event)} testId="vendor-info">
                  <Flex justify="space-between" align="center">
                    <MICardTitle label="vendors.view.title" />
                  </Flex>
                  <VendorForm mode={mode} vendor={vendorData} isRPPSVendor={isRPPSVendor} />
                  {vendor?.handle && (
                    <Flex direction="column" mt={5}>
                      <Box textStyle="body4" fontWeight={FONT_WEIGHT.BOLD} textTransform="capitalize">
                        <MIFormattedText label="vendors.form.handle" />
                      </Box>
                      <CopyOrCreatePaymentLink
                        clipboardText={getVendorHandleURL(vendor.handle)}
                        link={getVendorHandleURL(vendor.handle)}
                        size={CopyLinkSize.NORMAL}
                      />
                    </Flex>
                  )}
                </MICardForm>
                {mode === ScreenMode.EDIT && (
                  <MIFloatedEditDoneButtons
                    onDone={onEditContactClick}
                    onCancel={onCancel}
                    isProcessing={isLoading}
                    doneLabel="vendors.edit.done"
                    cancelLabel="vendors.edit.cancel"
                  />
                )}
              </MICard>
              {showDeliveryMethodsList && (
                <MICard>
                  <VendorDeliveryMethodsList
                    profile={profile}
                    users={users}
                    vendor={vendor}
                    goEditDeliveryMethod={goEditDeliveryMethod}
                    sendUnilateralRequest={sendUnilateralRequest}
                  />
                </MICard>
              )}
            </Box>
          </>
        </SingleViewLoadingContainer>
      )}
    </>
  );
};
