import debounce from 'lodash/debounce';
import set from 'lodash/set';
import io from 'socket.io-client';
import { analytics } from 'src/services/analytics';
import config from '../config';

// TODO: Bump "socket.io-client" package version
let socket: any = null;

export const clientEvents = {
  reset: () => {
    if (socket) {
      socket.close();
    }
  },

  identify: debounce(() => {
    clientEvents.reset();
    socket = io(config.server.baseUrl, {
      path: '/client-events/socket.io',
      forceNew: true,
    });

    socket.on('traits', (data) => {
      analytics.setTraits(data);
    });

    socket.on('track', (data) => {
      if (data.page === 'marketing') {
        set(data, 'options.integrations.Salesforce', true);
      }

      analytics.trackMarketingEvent(data.page, data.event, data.properties, data.options);
    });
  }, 2500),
};
