import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { useBreak } from 'src/hoc';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { globalLocations } from 'src/pages/locations';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { SyncInvoiceGenerationPage } from './InvoiceGenerationStep/SyncInvoiceGenerationPage';
import { syncQBDLocations } from './locations';
import SyncQBDAccessPage from './SyncQBDAccessPage';
import SyncQBDInProgressPage from './SyncQBDInProgressPage';
import SyncQBDLicensePage from './SyncQBDLicensePage';
import SyncQBDMobileBlockedPage from './SyncQBDMobileBlockedPage';
import SyncQBDOpenPage from './SyncQBDOpenPage';
import SyncQBDPage from './SyncQBDPage';
import SyncQBDRegionPage from './SyncQBDRegionPage';
import SyncQBDSuccessPage from './SyncQBDSuccessPage';

type LocationState = {
  exitUrl: string;
  redirectUrl: string;
  redirectUrlOnFinish: string;
};

export const SyncQBDRouter = () => {
  const [isInvoiceGenerationFeatureFlagEnabled] = featureFlags.useFeature(FeatureFlags.InvoiceGeneration, false);
  const history = useHistory();
  const { isDesktop } = useBreak();
  const orgId = useSelector(getOrgId);
  const receivingMethods = useSelector(getDeliveryMethods);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const locationState = useLocation<{ state: LocationState }>().state;
  const [redirectUrl] = useLocationState<string>('redirectUrl');
  const [redirectUrlOnFinish] = useLocationState<string>('redirectUrlOnFinish');
  const [exitUrl] = useLocationState<string>('exitUrl');
  const [historyPush] = useHistoryWithOrgId();
  const onNext = (path: string) => () => {
    historyPush({
      path,
      state: locationState,
    });
  };
  const onPrev = () => history.goBack();

  const goToReconciliationFlow = () => {
    const receivingMethod = receivingMethods[0];

    if (receivingMethod?.id) {
      historyPush({
        path: globalLocations.receivingMethod.ach.linkBankAccount,
        params: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          vendorId: ownedVendorId!,
          deliveryMethodId: receivingMethod.id,
        },
        state: {
          ...locationState,
          redirectUrl: generatePath(
            isInvoiceGenerationFeatureFlagEnabled ? syncQBDLocations.invoiceGeneration : syncQBDLocations.inProgress,
            { orgId }
          ),
        },
      });
    } else {
      redirectUrl && onNext(redirectUrl)();
    }
  };

  const onFinishFlow = () => {
    const url = redirectUrlOnFinish || getPaidLocations.create.connectAccountingSoftware.connect;
    onNext(url);
  };

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const onExit = () => historyPush({ path: exitUrl! });

  return (
    <Switch>
      <SmartRoute path={syncQBDLocations.entry} exact>
        {isDesktop ? (
          <SyncQBDPage onNext={onNext(syncQBDLocations.licenseKey)} onExit={onExit} />
        ) : (
          <Redirect
            to={{
              pathname: generatePath(syncQBDLocations.mobile, { orgId }),
              state: locationState,
            }}
          />
        )}
      </SmartRoute>
      <SmartRoute path={syncQBDLocations.mobile} exact>
        <SyncQBDMobileBlockedPage onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={syncQBDLocations.licenseKey} exact>
        <SyncQBDLicensePage onNext={onNext(syncQBDLocations.open)} onPrev={onPrev} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={syncQBDLocations.open} exact>
        <SyncQBDOpenPage onNext={onNext(syncQBDLocations.region)} onPrev={onPrev} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={syncQBDLocations.region} exact>
        <SyncQBDRegionPage onNext={onNext(syncQBDLocations.access)} onPrev={onPrev} onExit={onExit} />
      </SmartRoute>
      <SmartRoute path={syncQBDLocations.access} exact>
        <SyncQBDAccessPage onNext={goToReconciliationFlow} onPrev={onPrev} onExit={onExit} />
      </SmartRoute>
      {isInvoiceGenerationFeatureFlagEnabled && (
        <SmartRoute path={syncQBDLocations.invoiceGeneration} exact>
          <SyncInvoiceGenerationPage onNext={onNext(syncQBDLocations.inProgress)} />
        </SmartRoute>
      )}
      <SmartRoute path={syncQBDLocations.inProgress} exact>
        <SyncQBDInProgressPage onNext={onNext(syncQBDLocations.success)} />
      </SmartRoute>
      <SmartRoute path={syncQBDLocations.success} exact>
        <SyncQBDSuccessPage onNext={onFinishFlow} />
      </SmartRoute>
    </Switch>
  );
};
