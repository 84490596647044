import styled from 'styled-components';
import quickbooksLogo from 'src/images/accounting-software/quickbooks-connect.png';
import { AccountingSoftware } from 'src/utils/consts';

type Props = {
  id: AccountingSoftware;
  disabled?: boolean;
  selected?: boolean;
  onClick?: (type: AccountingSoftware) => void;
  isLogoOnly?: boolean;
};

export const MILogoButton = ({ id, disabled, selected, onClick, isLogoOnly }: Props) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  const getLogo = () => {
    switch (id) {
      case AccountingSoftware.QUICKBOOKS:
        return quickbooksLogo;
      default:
        return '';
    }
  };

  const src = getLogo();
  const className = disabled ? 'disabled' : '';

  return (
    <>
      {isLogoOnly ? (
        <ImageIcon src={src} />
      ) : (
        <LogoButton disabled={disabled} selected={selected} onClick={handleClick} className={className}>
          {src && <ImageIcon src={src} />}
        </LogoButton>
      )}
    </>
  );
};

const LogoButton = styled.div<{ disabled?: boolean; selected?: boolean }>`
  height: 9.7rem;
  border-radius: 0.6rem;
  border: solid 0.1rem;
  border-color: ${(props) => (props.selected ? props.theme.colors.primary.opaque : '#e3e3e3')};
  justify-content: center;
  align-items: center;
  outline: none;
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.colors.white.opaque};
  cursor: pointer;
  width: 100%;

  &:hover {
    border-color: ${(props) => props.theme.colors.primary.opaque};
    box-shadow: none;
  }

  &:active {
    border-color: ${(props) => props.theme.colors.primary.opaque};
    box-shadow: none;
  }

  &.disabled {
    filter: grayscale(1);
    cursor: default;
  }
`;

const ImageIcon = styled.img`
  height: 4.5rem;
  user-select: none;
  max-width: 100%;
`;
