import { DeliveryDateFormat } from 'src/components/common/DeliveryDateFormat';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { isSameDay, isTomorrow } from 'src/utils/dates';
import { DeliveryOptionType } from 'src/utils/types';

type Props = {
  deliveryOption: DeliveryOptionType;
  label?: string;
};

export const BetterExposteEtaDeliveryOptionSubtitle = ({ deliveryOption, label }: Props) => (
  <MIFormattedText
    label={label}
    values={{
      today: (() => {
        if (isSameDay(deliveryOption.deliveryDate)) {
          return <MIFormattedText label="bills.pay.date.deliveryOptions.sameDayDelivery" />;
        }

        if (isTomorrow(deliveryOption.deliveryDate)) {
          return <MIFormattedText label="bills.pay.date.deliveryOptions.nextDayDelivery" />;
        }

        return undefined;
      })(),
      date: (
        <DeliveryDateFormat
          date={deliveryOption.deliveryDate}
          maxDate={deliveryOption.maxDeliveryDate}
          weekday={
            isSameDay(deliveryOption.deliveryDate) || isTomorrow(deliveryOption.deliveryDate) ? undefined : 'long'
          }
        />
      ),
    }}
  />
);
