import * as React from 'react';
import styled from 'styled-components';
import { Lottie, LottieProps } from 'src/components/common/Lottie';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard, MINotificationCardProps } from 'src/components/common/MINotificationCard';
import { spacing } from 'src/core/theme/foundations/spacing';
import { devices } from '../../theme/appDevices';

const WizardContainer = styled.div`
  background-color: ${(props) => props.theme.background.wizard};
  display: flex;
  justify-content: center;
  min-height: 100%;
  box-sizing: border-box;
  flex-direction: column;

  @media ${devices.desktop}, ${devices.tablet} {
    align-items: center;
    padding: 6rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    align-items: start;
  }

  @media ${devices.phablet} {
    padding: 4rem;
  }

  @media ${devices.mobile} {
    padding: 2rem;
  }
`;

const WizardInner = styled.div<{ isCenterVert?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  @media ${devices.desktop}, ${devices.tablet} {
    justify-content: ${(props) => (props.isCenterVert ? 'center' : 'start')};
    width: 90rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    justify-content: ${(props) => (props.isCenterVert ? 'center' : 'start')};
    width: 100%;
  }
`;

const WizardIllustration = styled.img`
  margin-bottom: 3rem;
  align-self: center;

  @media ${devices.desktop}, ${devices.tablet} {
    height: 18rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    height: 14rem;
  }
`;

const WizardTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.8rem;
  align-self: center;
  text-align: center;

  @media ${devices.desktop}, ${devices.tablet} {
    font-size: ${(props) => props.theme.text.size.wizardTitle};
    line-height: 5.4rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: ${(props) => props.theme.text.size.wizardTitleMobile};
  }
`;

const WizardStepTitle = styled.div<{ isSubtitle?: boolean }>`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  align-self: center;
  text-align: center;
  margin-bottom: ${(props) => (props.isSubtitle ? '1.5rem' : '4.5rem')};

  em {
    font-style: normal;
    color: ${(props) => props.theme.text.color.subtitle};
  }

  @media ${devices.desktop}, ${devices.tablet} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitle};
    line-height: 4rem;
    margin-top: 2rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    font-size: ${(props) => props.theme.text.size.wizardStepTitleMobile};
    margin-top: 1.6rem;
  }
`;

const WizardStepSubTitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-align: center;
  margin-bottom: 4rem;
  ${(props) => props.theme.text.fontType.regular};
  max-width: ${(props) => `${props.innerSize}%` || '45rem'};

  @media ${devices.mobile}, ${devices.phablet} {
    max-width: 45rem;
    margin-bottom: 2.4rem;
  }
`;

const WizardText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  margin-bottom: 2.4rem;
  align-self: center;
  text-align: center;
  ${(props) => props.theme.text.fontType.regular};

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const WizardActionsContainer = styled.div`
  align-self: center;
  flex-direction: column;
  display: flex;
  text-align: center;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const WizardButtonContainer = styled.div<{
  width?: string;
  fullWidthCTA: boolean;
  isProgressBar?: boolean;
}>`
  align-items: center;

  button {
    width: ${(props) => props.width || '100%'};
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin-bottom: ${spacing[4]};
    width: ${(props) => props.width};
    align-self: ${(props) => (props.width && props.width !== 'auto' ? 'center' : 'auto')};
  }

  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0;
    button {
      margin-bottom: 2rem;
      ${({ fullWidthCTA }) => fullWidthCTA && 'width: 100%'}
    }
    width: auto;
    background: ${(props) => props.theme.background.default};
  }
`;

const WizardLinkButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const WizardLinkContainer = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  ${(props) => props.theme?.components?.WizardElements?.WizardLinkContainer}
`;

const WizardRegisterLinkContainer = styled.div`
  margin-bottom: 3rem;
  text-align: center;
`;

const WizardLinkHintContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  text-align: center;
  font-size: ${(props) => props.theme.text.size.linkHint};
`;

const WizardOptionContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1.5rem;
  max-width: 45rem;
  align-self: center;
  ${(props) => props.theme?.components?.WizardElements?.WizardOrLine?.WizardOptionContainer}
`;

const WizardOptionLine = styled.div`
  height: 0.1rem;
  border-top: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  flex-grow: 1;
`;

const WizardOptionText = styled.div`
  font-size: 1.4rem;
  flex-grow: none;
  color: ${(props) => props.theme.text.color.main};
  margin: 0 1.7rem 0 1.7rem;
`;

const WizardOrLine = ({ className }: { className?: string }) => (
  <WizardOptionContainer className={className}>
    <WizardOptionLine />
    <WizardOptionText>
      <MIFormattedText label="general.or" />
    </WizardOptionText>
    <WizardOptionLine />
  </WizardOptionContainer>
);

WizardOrLine.defaultProps = {};

type WizardInputsProps = {
  innerSize?: number;
  testId?: string | null;
  children: React.ReactNode;
};

const WizardInputs = ({ innerSize = 50, children, testId }: WizardInputsProps) => (
  <WizardInputsContainer size={innerSize} data-testid={testId}>
    {children}
  </WizardInputsContainer>
);

const WizardInputsContainer = styled.div<{ size: number }>`
  align-self: center;
  margin-bottom: 3rem;
  @media ${devices.desktop}, ${devices.tablet} {
    width: ${(props) => props.size}%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const WizardStepActionsContainer = styled.div`
  align-self: center;
  flex-direction: column;
  display: flex;
  text-align: center;

  @media ${devices.desktop}, ${devices.tablet} {
    min-width: 25%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    margin-bottom: -2rem;
    background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  }
`;

const DockerSpacer = styled.div`
  height: 5rem;
`;

const ButtonSpacer = styled.div`
  height: 2rem;
`;

const WizardStepActionsContainerDocked = styled(WizardStepActionsContainer)`
  position: fixed;
  bottom: 0;
  padding-top: 1.4rem;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-sizing: border-box;
  box-shadow: 0 -0.4rem 1.5rem 0 ${(props) => props.theme.colors.dark.translucent1};
  @media ${devices.mobile}, ${devices.phablet} {
    position: fixed;
    margin-bottom: -0.5rem;
    padding: 1.4rem 2rem 0;
    background-color: ${(props) => props.theme.colors.white.opaque};
  }
`;

const WizardFooterContainer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;

  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 3rem;
  width: 11.2rem;
  height: 4.2rem;
  text-align: center;
  @media ${devices.mobile}, ${devices.phablet} {
    svg {
      width: 8.8rem;
      height: 3.5rem;
    }
  }
`;

const SimpleTextFooter = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  letter-spacing: 0.037rem;
  text-align: center;
  ${(props) => props.theme.text.fontType.hint};

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 3.8rem;
  }

  @media ${devices.desktop}, ${devices.tablet} {
    margin-top: 1.8rem;
  }
`;
const SimpleTextFooterSlim = SimpleTextFooter;

type WizardFormRowProps = {
  testId?: string | null;
  children: React.ReactNode;
  marginBottom?: string;
};

const WizardFormRow = ({ children, testId, marginBottom }: WizardFormRowProps) => (
  <WizardFormRowWrapper data-testid={testId} marginBottom={marginBottom}>
    {children}
  </WizardFormRowWrapper>
);

const WizardFormRowWrapper = styled.div`
  display: flex;
  margin-bottom: ${(props) => props.marginBottom ?? ''};
`;

type WizardFormColumnProps = {
  size?: number;
  testId?: string | null;
  children: React.ReactNode;
};

const WizardFormColumn = ({ size = 50, testId, children }: WizardFormColumnProps) => (
  <WizardFormColumnWrapper size={size} data-testid={testId}>
    {children}
  </WizardFormColumnWrapper>
);

const WizardFormColumnWrapper = styled.div<{ size: number }>`
  width: ${(props) => props.size}%;

  &:first-child {
    margin-right: 1.5rem;
  }
  &:nth-child(2) {
    margin-left: 1.5rem;
  }
`;

const InnerWizardContentWrapper = React.Fragment;

type WizardNotesContainerProps = {
  label: string;
  children?: React.ReactNode;
};

const WizardNotesContainer = ({ label, children }: WizardNotesContainerProps) => (
  <WizardNotesContainerWrapper>
    <WizardNotesHeaderContainer>
      <WizardNotesTitle>
        <MIFormattedText label={label} />
      </WizardNotesTitle>
    </WizardNotesHeaderContainer>
    <WizardNotesSubTitle>{children}</WizardNotesSubTitle>
  </WizardNotesContainerWrapper>
);

const WizardNotesContainerWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.8rem;
  background: ${(props) => props.theme.colors.white.opaque};
  padding: 2rem;
  box-sizing: border-box;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  line-height: 2rem;
  margin-bottom: 4rem;
  text-align: left;
  span {
    font-weight: 600 !important;
  }
  @media ${devices.desktop}, ${devices.tablet} {
    width: 50%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
`;

const WizardNotesTitle = styled.div`
  margin-bottom: 0.8rem;
`;

const WizardNotesSubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const WizardNotesHeaderContainer = styled.div`
  display: flex;
`;

type WizardAnimatedIllustrationProps = LottieProps;

const WizardAnimatedIllustration: React.FC<WizardAnimatedIllustrationProps> = ({
  height = '20rem',
  ...restAnimatedIllustrationProps
}) => (
  <WizardAnimatedIllustrationContainer>
    <Lottie height={height} {...restAnimatedIllustrationProps} />
  </WizardAnimatedIllustrationContainer>
);

const WizardAnimatedIllustrationContainer = styled.div`
  width: 100%;
`;

type WizardNotificationCardProps = MINotificationCardProps;

const WizardNotificationCard: React.FC<WizardNotificationCardProps> = (props) => (
  <WizardStyledNotificationCard {...props} />
);

const WizardStyledNotificationCard = styled(MINotificationCard)`
  max-width: 45rem;
  @media ${devices.desktop}, ${devices.tablet} {
    margin-bottom: 6rem;
  }
`;

export {
  ButtonSpacer,
  DockerSpacer,
  InnerWizardContentWrapper,
  LogoContainer,
  SimpleTextFooter,
  SimpleTextFooterSlim,
  WizardActionsContainer,
  WizardAnimatedIllustration,
  WizardButtonContainer,
  WizardContainer,
  WizardFooterContainer,
  WizardFormColumn,
  WizardFormRow,
  WizardIllustration,
  WizardInner,
  WizardInputs,
  WizardLinkButtonContainer,
  WizardLinkContainer,
  WizardLinkHintContainer,
  WizardNotesContainer,
  WizardNotificationCard,
  WizardOptionLine,
  WizardOrLine,
  WizardRegisterLinkContainer,
  WizardStepActionsContainer,
  WizardStepActionsContainerDocked,
  WizardStepSubTitle,
  WizardStepTitle,
  WizardText,
  WizardTitle,
};
