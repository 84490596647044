import { Switch, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useUpgradePaymentEntry } from './hooks';
import { upgradePaymentLocations as locations } from './locations';
import { FastAchMethodPage, OfferExpiredPage, RtpMethodPage, SelectMethodPage, SuccessPage } from './pages';

export const UpgradePaymentRouter = () => {
  const match = useRouteMatch<{ token: string }>();
  const token = match.params?.token || '';
  useUpgradePaymentEntry(token);

  return (
    <Switch>
      <SmartRoute path={locations.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={locations.selectMethod} exact>
        <SelectMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.fastAchMethod} exact>
        <FastAchMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.rtpMethod} exact>
        <RtpMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.success} exact>
        <SuccessPage token={token} />
      </SmartRoute>
      <SmartRoute path={locations.offerExpired} exact>
        <OfferExpiredPage token={token} />
      </SmartRoute>
    </Switch>
  );
};
