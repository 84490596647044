import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SyncNowConnectedView } from 'src/components/accounting-software/SyncNow/SyncNowConnectedView';
import { SyncNowErrorView } from 'src/components/accounting-software/SyncNow/SyncNowErrorView';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { useIsSyncErrorState } from 'src/hooks/accounting-software/useIsSyncErrorState';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';

export const SyncNowButton = () => {
  const syncSummary = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.syncSummary);

  const orgId = useOrgId();
  const apActions = useStoreActions(accountingPlatformsStore);
  const { isActive, isConnected } = useFetchAccountingPlatformsAndData();
  const { isErrorState } = useIsSyncErrorState();

  useEffect(() => {
    if (isActive && !syncSummary) {
      apActions.fetchSyncSummary({ orgId });
    }
  }, [apActions, orgId, isActive, isConnected, syncSummary]);

  if (!syncSummary) {
    return null;
  }

  return isErrorState ? <SyncNowErrorView /> : <SyncNowConnectedView />;
};
