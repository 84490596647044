import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { BatchActionsMenu } from 'src/pages/contacts/list/components/BatchSettings/BatchActionsMenu';
import { BatchContactsScreenMode } from './consts';
import { ActionOptionType } from './types';

type Props = {
  mode: BatchContactsScreenMode;
  count: number;
  actions: readonly ActionOptionType[];
  analyticsProps: Record<string, any>;
  showMenuItems?: boolean;
};

export const BatchSettingsHeader = ({ mode, count, actions, analyticsProps, showMenuItems = true }: Props) => {
  const isViewMode = mode === BatchContactsScreenMode.VIEW;
  const isUnilateralMode = mode === BatchContactsScreenMode.UNILATERAL;

  return (
    <Flex textStyle="h2Semi" mb={8} h={10} justify="space-between">
      <Box data-testid="vendor-batch-list-title">
        <MIFormattedText
          label={isUnilateralMode ? 'vendors.batchSettings.unilateralTitle' : 'vendors.batchSettings.title'}
          values={{ count }}
        />
        <Box textStyle="body4" pt={1} color="grey.600">
          <MIFormattedText
            label={isUnilateralMode ? 'vendors.batchSettings.unilateralSubtitle' : ''}
            values={{ count }}
          />
        </Box>
      </Box>
      {showMenuItems && isViewMode && <BatchActionsMenu analyticsProps={analyticsProps} actions={actions} />}
    </Flex>
  );
};
