import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

type Props = {
  title?: string;
  options?: Array<Record<string, any>>;
  ctaZeroState?: string;
  zeroStateAction?: () => void;
  border?: string;
  padding?: string;
  black?: boolean;
};

const ZeroState = ({ title, options, ctaZeroState, zeroStateAction, border, black }: Props) => (
  <Box
    display="block"
    py={6}
    px={5}
    border={border || '1px'}
    borderColor="grey.400"
    borderRadius="lg"
    w="full"
    mt={4}
    mb={{ base: 20, sm: 12 }}
  >
    {title && (
      <Box textStyle="body2Semi" color="grey.700">
        <MIFormattedText label={title} />
      </Box>
    )}

    {options &&
      options.map((step, index) => (
        <React.Fragment key={index}>
          <Flex direction="row" my="5">
            <Flex
              align="center"
              justify="center"
              boxSizing="border-box"
              borderRadius="full"
              border="2px"
              borderColor="grey.700"
              color={black ? 'black' : 'grey.700'}
              mr={3}
              h="2.6rem"
              minW="2.6rem"
              textStyle="toastAction"
            >
              {index + 1}
            </Flex>
            <Flex direction="column" align="flex-start" w="full">
              <Box textStyle="body4Semi" color={black ? 'black' : 'grey.700'}>
                <MIFormattedText label={step.title} />
              </Box>
              <Box textStyle="body4" color={black ? 'black' : 'grey.700'} w="full">
                <MIFormattedText label={step.description} />
              </Box>
            </Flex>
          </Flex>
        </React.Fragment>
      ))}

    {ctaZeroState && zeroStateAction && (
      <Button
        variant={ButtonVariants.tertiaryNaked}
        onClick={zeroStateAction}
        label={ctaZeroState}
        size={ButtonSizes.md}
        testId={`button-${ctaZeroState}`}
      />
    )}
  </Box>
);

export default ZeroState;
