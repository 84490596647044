import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { organizationsApi } from 'src/modules/organizations/api';
import { getOrgId } from 'src/redux/user/selectors';

export const useDebitFeeNotification = () => {
  const orgId = useSelector(getOrgId);
  const {
    onApiCall: fetchShouldDisplayDebitFeeNotification,
    result: shouldDisplayDebitFeeNotificationResponse,
  } = useApi({
    api: organizationsApi.shouldDisplayDebitFeeNotification,
  });

  useEffect(() => {
    fetchShouldDisplayDebitFeeNotification(orgId);
  }, [fetchShouldDisplayDebitFeeNotification, orgId]);

  const shouldDisplayDebitFeeNotification = !!shouldDisplayDebitFeeNotificationResponse?.result;

  return {
    shouldDisplayDebitFeeNotification,
  };
};
