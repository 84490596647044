import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { globalLocations } from 'src/pages/locations';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { receivingMethodLocations } from 'src/pages/receiving-method/locations';
import { accountingSoftwareDisplayName } from 'src/pages/settings/components/accounting-software/consts';
import { useConnectAccountingPlatform } from 'src/pages/settings/hooks/useConnectAccountingPlatform';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { AccountingSoftware, NotificationCardTypes } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import { AccountingSoftwareCards } from './components/AccountingSoftwareCards';
import { useGetAccountingSoftwareIntegrationOptions } from './hooks/useGetAccountingSoftwareIntegrationOptions';

const eventPage = 'payment-request';
type Props = {
  onPrev: () => void;
  onExit: () => void;
};

export const ConnectAccountingSoftwarePage = ({ onExit, onPrev }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const [selectedAccountingSoftware, setSelectedAccountingSoftware] = useState<AccountingSoftware | undefined>();
  const [error, setIsError] = useState(false);
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const accountPlatform = useSelector(accountingPlatformsStore.selectors.getByName(selectedAccountingSoftware));
  const [connectAccountingPlatform] = useConnectAccountingPlatform({
    eventPage,
    redirect: RedirectOriginPages.PAID_DASHBOARD,
  });
  const connectStatus = useSelector(accountingPlatformsStore.selectors.connect.status);
  const connectionError = connectStatus?.error;
  const bankAccountsListError = useSelector(accountingPlatformsStore.selectors.listBankAccounts.error);
  const displayShortName =
    selectedAccountingSoftware && accountingSoftwareDisplayName[selectedAccountingSoftware]?.displayShortName;
  const { accountingSoftwareSupportedOptions } = useGetAccountingSoftwareIntegrationOptions();

  useEffect(() => {
    if (connectionError || bankAccountsListError) {
      setIsError(true);
    }
  }, [connectionError, bankAccountsListError]);

  const handleConnectQBD = async () => {
    const redirectUrl = globalLocations.getPaid.dashboard;
    const exitUrl = globalLocations.getPaid.create.connectAccountingSoftware.connect;
    await connectAccountingPlatform(AccountingSoftware.QUICKBOOKS_DESKTOP, accountPlatform.id);
    historyPush({
      path: globalLocations.settings.syncQBDLocations.entry,
      state: {
        redirectUrl,
        exitUrl,
      },
    });
  };

  const handleConnectQBO = async () => {
    const returnUrl = `${generatePath(receivingMethodLocations.redirect, {
      orgId,
      vendorId: ownedVendorId!,
    })}?redirect=${RedirectOriginPages.PAID_DASHBOARD}`;
    analytics.track('connect-accounting-software-from-payment-request', 'link', {
      type: AccountingSoftware.QUICKBOOKS,
    });

    await intuit.goConnectToIntuit({
      intuitReturnUrl: returnUrl,
      intuitErrorReturnUrl: returnUrl,
      linkOrgId: orgId,
    });
  };

  const handleConnectWithCodat = async (accountingSoftwareType: AccountingSoftware) => {
    let authLink = accountPlatform?.authLink;

    if (!authLink) {
      const response = await connectAccountingPlatform(accountingSoftwareType, accountPlatform.id);
      authLink = response?.payload?.accountingPlatform?.authLink;
    }

    if (authLink !== null) {
      window.location.href = authLink;
    }
  };

  const accountingSoftwareConnectAction: { [key: string]: () => Promise<void> } = {
    [AccountingSoftware.QUICKBOOKS]: handleConnectQBO,
    [AccountingSoftware.QUICKBOOKS_DESKTOP]: handleConnectQBD,
    [AccountingSoftware.MICROSOFT_DYNAMICS_365]: () => handleConnectWithCodat(AccountingSoftware.MICROSOFT_DYNAMICS),
    [AccountingSoftware.XERO]: () => handleConnectWithCodat(AccountingSoftware.XERO),
  };

  const connectAccountingSoftware = () => {
    if (selectedAccountingSoftware) {
      const connect = accountingSoftwareConnectAction[selectedAccountingSoftware];
      connect();
    }
  };

  const goToUnsupportedAccountingPlatform = () => {
    historyPush({
      path: getPaidLocations.create.connectAccountingSoftware.unsupportedAccountingSoftware.unsupported,
    });
  };

  const onSelectAccountingSoftware = (accountingSoftware: AccountingSoftware) => {
    setSelectedAccountingSoftware(accountingSoftware);

    if (error) {
      setIsError(false);
    }
  };

  const notificationError = {
    type: NotificationCardTypes.ERROR,
    subtitle: {
      label: 'settings.newAccountingSoftware.SSO.linkError.generic',
      values: {
        accountingSoftwareType: displayShortName,
      },
    },
  };

  return (
    <StepLayoutPage
      relativeStep={0}
      goExit={onExit}
      onPrev={onPrev}
      title="getPaid.new.create.connect.title"
      subtitle="getPaid.new.create.connect.subTitle"
      innerSize={75}
      onNext={connectAccountingSoftware}
      nextLabel="getPaid.new.create.connect.buttonLabel"
      onSecondaryActionButtonClick={goToUnsupportedAccountingPlatform}
      secondaryActionButtonLabel="getPaid.new.create.connect.unsupportedAccountingPlatform.buttonLabel"
      isNextDisabled={!selectedAccountingSoftware}
      isLoading={connectStatus?.loading}
      notification={error ? notificationError : undefined}
    >
      <AccountingSoftwareCards
        options={accountingSoftwareSupportedOptions}
        selectedAccountingSoftware={selectedAccountingSoftware}
        onSelectAccountingSoftware={onSelectAccountingSoftware}
      />
    </StepLayoutPage>
  );
};
