import * as React from 'react';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import ListItemLabel from 'src/components/list/ListItemLabel';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { PayCardFlag } from 'src/pay/types';
import { BillStatus } from 'src/utils/consts';
import { Props } from './PayCard';

export const PayCardFooter = ({
  isPartiallyPaidItemOnInboxTab,
  totalAmount,
  status,
  flags,
  deliveryEta,
  labels,
}: Partial<Props>) => (
  <>
    {labels?.map((label) => (
      <ListItemLabel key={label} label={label} />
    ))}
    {isPartiallyPaidItemOnInboxTab ? (
      <Box borderTop="1px" borderColor="grey.400" mt={4} mr={-4} pr={4}>
        <Flex pt={4} justifyContent="space-between" textStyle="body4">
          <MIFormattedText label="bills.view.partiallyPaid" />
          <Box textStyle="body4Semi">
            <MIFormattedCurrency key="amount" value={totalAmount || ''} />
          </Box>
        </Flex>
      </Box>
    ) : (
      status === BillStatus.SCHEDULED && (
        <Box borderTop="1px" borderColor="grey.400" mt={4} mr={-4} pr={4}>
          <Box
            display={{ base: 'inline-block' }}
            pt={4}
            textStyle="body4"
            color="grey.700"
            data-testid="payment-delivery-date"
          >
            {flags?.includes(PayCardFlag.PENDING_VENDOR_INFO) ? (
              <MIFormattedText label="bills.view.pendingInformation" />
            ) : (
              deliveryEta && (
                <MIFormattedText
                  label="bills.view.deliveryEta"
                  values={{ date: <MIFormattedDate date={deliveryEta} /> }}
                />
              )
            )}
          </Box>
        </Box>
      )
    )}
  </>
);
