import { useMemo } from 'react';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type Props = {
  label: string;
  testId?: string;
};

export const HeaderCell: React.FC<Props> = ({ label, testId }) =>
  useMemo(
    () => (
      <Box isTruncated data-testid={testId}>
        <MIFormattedText label={label} />
      </Box>
    ),
    [label, testId]
  );
