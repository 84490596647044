export const ACTION_NOTIFICATION_MAPPING = {
  '[NOTIFICATIONPREFERENCES] LIST_FAILURE': {
    type: 'error',
    msg: 'settings.emailNotifications.messages.failure.list',
  },
  '[NOTIFICATIONPREFERENCES] UPDATE_FAILURE': {
    type: 'error',
    msg: 'settings.emailNotifications.messages.failure.update',
  },
};
