import compact from 'lodash/fp/compact';
import { useSelector } from 'react-redux';
import { Loader, LoaderColorType } from 'src/components/common/Loader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OrganizationBadgeList } from 'src/components/common/OrganizationBadgeList';
import Box from 'src/core/ds/box';
import { ButtonVariants } from 'src/core/ds/button';
import { MAP_LOADER_COLOR_TO_VARIANT } from 'src/core/ds/button/consts';
import Flex from 'src/core/ds/flex';
import { Separator } from 'src/core/ds/separator';
import { useModal } from 'src/helpers/react/useModal';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useGetFirmBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetFirmBillingFees';
import { profileStore } from 'src/modules/profile/profile-store';
import { billingLocations } from 'src/pages/billing/locations';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';
import { useBillingAnalytics } from 'src/pages/settings/components/Billing/useBillingAnalytics';
import { DeleteMethodModal } from '../common/DeleteMethodModal';
import { EmptyMethodCard } from '../common/EmptyMethodCard';
import { SelectedMethodCard } from '../common/SelectedMethodCard';

type Props = {
  hasClients: boolean;
};
export const FirmSelectedMethodCard = ({ hasClients }: Props) => {
  const billingAnalytics = useBillingAnalytics();
  const [historyPush] = useHistoryWithOrgId();
  const firmOrganizations = useSelector(profileStore.selectors.getOrganizations);
  const { currentFirmBillingMethod, assignedClients, firmFundingSource, loading, refetch } = useGetFirmBillingFees();
  const fundingSourceId = currentFirmBillingMethod?.fundingSourceId;
  const [filteredFundingSources] = useGetValidFundingSources();
  const appliedClientsToCurrentFundingSource = firmOrganizations.filter((org) => assignedClients?.[org.id.toString()]);

  const currentFundingSource = filteredFundingSources.find((fs) => fs.id === fundingSourceId) || firmFundingSource;

  const [DeleteBillingMethod, showDeleteMethodModal] = useModal(DeleteMethodModal, {
    description: appliedClientsToCurrentFundingSource.length
      ? 'settings.billing.paymentMethod.removeModal.clientsAssignedDescription'
      : 'settings.billing.paymentMethod.removeModal.description',
    billingFeeId: currentFirmBillingMethod?.id,
    isAccountingFirm: true,
    onDismiss: (canceled: boolean) => {
      billingAnalytics.track('settings-billing', canceled ? 'remove-payment-method-cancel' : 'remove-payment-method', {
        paymentMethodType: currentFundingSource?.fundingType,
        paymentMethodID: currentFundingSource?.id,
      });

      if (!canceled) {
        refetch();
      }
    },
  });

  const createActionHandler = (
    actionName: 'edit-method' | 'edit-clients' | 'remove-method',
    action: () => void
  ) => () => {
    billingAnalytics.track('settings-billing', 'payment-method-action', {
      paymentMethodType: currentFundingSource?.fundingType,
      paymentMethodID: currentFundingSource?.id,
      action: actionName,
    });
    action();
  };

  const handleChangeBillingMethod = createActionHandler('edit-method', () =>
    historyPush({ path: billingLocations.add })
  );

  const handleEditClients = createActionHandler('edit-clients', () =>
    historyPush({ path: billingLocations.accountingFirm.assignClients })
  );

  const handleShowDeleteMethodModal = createActionHandler('remove-method', () => showDeleteMethodModal());

  const actions = compact([
    {
      label: 'settings.billing.paymentMethod.actions.firm.change',
      action: handleChangeBillingMethod,
    },
    hasClients && {
      label: 'settings.billing.paymentMethod.actions.firm.editClients',
      action: handleEditClients,
    },
    {
      label: 'settings.billing.paymentMethod.actions.firm.remove',
      action: handleShowDeleteMethodModal,
      color: 'red.500',
    },
  ]);

  if (loading) {
    return (
      <Box h={94}>
        <Loader context="page" color={MAP_LOADER_COLOR_TO_VARIANT[ButtonVariants.tertiary] as LoaderColorType} />
      </Box>
    );
  }

  return fundingSourceId ? (
    <>
      {DeleteBillingMethod}
      <Flex direction="column" border="1px" borderColor="grey.400" borderRadius="lg" p={6}>
        <SelectedMethodCard menuActions={actions} fundingSource={currentFundingSource} />
        <Separator color="grey.400" mt={5} mb={4} />
        <Flex>
          <Box textStyle="body4" color="grey.600" paddingInlineEnd={1}>
            {appliedClientsToCurrentFundingSource.length ? (
              <MIFormattedText
                label="settings.billing.paymentMethod.firmClientsApplied"
                values={{ numOfAppliedClient: appliedClientsToCurrentFundingSource.length }}
              />
            ) : (
              <MIFormattedText label="settings.billing.paymentMethod.noClientsApplied" />
            )}
          </Box>
          <OrganizationBadgeList organizations={appliedClientsToCurrentFundingSource} />
        </Flex>
      </Flex>
    </>
  ) : (
    <EmptyMethodCard />
  );
};
