import { Pathname } from 'history';
import { useLocation } from 'react-router-dom';
import config from 'src/config';
import { useLatestRef } from 'src/core/ds/hooks';
import { authApi } from 'src/modules/auth/api';

type NavigationPath =
  | Pathname
  | {
      pathname: Pathname;
      search: Record<
        string,
        string /* this can be any primitive really, but we don't need other use-cases at the moment */
      >;
    };

export function useLoginWithGoogle({
  redirectFailureTo,
  redirectSuccessTo,
}: {
  redirectFailureTo: NavigationPath;
  redirectSuccessTo: NavigationPath;
}) {
  const redirectFailurePathRef = useLatestRef(redirectFailureTo);
  const redirectSuccessPathRef = useLatestRef(redirectSuccessTo);
  const location = useLocation();

  return {
    openGoogleOAuth: () => {
      const buildRedirectUrl = (navigationPath: NavigationPath) => {
        const url = new URL(
          `${config.web.baseUrl}${typeof navigationPath === 'string' ? navigationPath : navigationPath.pathname}`
        );

        if (typeof navigationPath === 'object' && navigationPath.search) {
          url.search = new URLSearchParams(navigationPath.search).toString();
        }

        if (location.state) {
          url.searchParams.set('state', JSON.stringify(location.state));
        }

        return url.toString();
      };

      const googleAuthUrl = authApi.getLoginWithGoogleRedirectUrl({
        failureUrl: buildRedirectUrl(redirectFailurePathRef.current),
        successUrl: buildRedirectUrl(redirectSuccessPathRef.current),
      });
      window.location.href = googleAuthUrl;
    },
  };
}
