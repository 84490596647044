import isEmpty from 'lodash/isEmpty';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { TextField } from 'src/core/ds/form/fields';
import { profileStore } from 'src/modules/profile/profile-store';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { ModelViewField } from 'src/ui/form';
import { DeliveryType } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { VendorType } from 'src/utils/types';
import { sendVendorDetailsRequest } from 'src/utils/vendors';

type Props = {
  dismiss: () => void;
  onSuccess: () => void;
  onEditContact: () => Promise<void>;
  createOrUpdateVirtualDeliveryMethod: (deliveryMethod) => Promise<void>;
  vendor: VendorType<'created'>;
  contactEmail: ModelViewField<string>;
  eventPage: string;
};

export const CollectVendorEmailModalMessage = ({
  dismiss,
  onSuccess,
  onEditContact,
  createOrUpdateVirtualDeliveryMethod,
  vendor,
  contactEmail,
  eventPage,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const [isProcessing, setIsProcessing] = useState(false);
  const existingVirtualDeliveryMethod = vendor?.deliveryMethods?.find((dm) => dm.deliveryType === DeliveryType.VIRTUAL);
  const vendorName = vendor.companyName;
  const virtualDeliveryMethod = useMemo(
    () => ({
      deliveryType: DeliveryType.VIRTUAL,
      virtualAccount: {
        email: contactEmail?.value,
        phone: existingVirtualDeliveryMethod?.virtualAccount?.phone || vendor?.contactPhone,
      },
    }),
    [vendor, contactEmail]
  );

  const handleCollectVendorEmailModalCancel = () => {
    analytics.track(eventPage, 'vendor-email-modal-closed', { userId });
    dismiss();
  };

  const handleCollectVendorEmailModalSubmit = () => {
    analytics.track(eventPage, 'vendor-email-modal-save', {
      userId,
      vendorId: vendor?.id,
    });

    if (isEmpty(contactEmail)) return;

    if (!contactEmail?.value) {
      const error = 'inputErrors.vendor.contactEmail.any.empty';
      analytics.track(eventPage, 'vendor-email-modal-save-validation-error', { contactEmail: error });
      contactEmail?.setError?.(error);
    } else {
      setIsProcessing(true);

      if (!contactEmail.error) {
        onEditContact().then(() => {
          createOrUpdateVirtualDeliveryMethod(virtualDeliveryMethod).then(() => {
            sendVendorDetailsRequest(orgId, vendor.id);
            setIsProcessing(false);
            dismiss();
            onSuccess();
            analytics.track(eventPage, 'vendor-email-modal-save-success', {
              vendorId: vendor?.id,
              vendorEmail: contactEmail,
            });
          });
        });
      } else {
        setIsProcessing(false);
        analytics.track(eventPage, 'vendor-email-modal-save-validation-error', { contactEmail: contactEmail.error });
      }
    }
  };

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      handleCollectVendorEmailModalSubmit();
    }
  };

  return (
    <ModalMessage
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText
              label="vendors.unilateralWithoutPayment.collectVendorEmailModal.title"
              values={{ vendorName }}
            />
          </div>
          <div className="text">
            <MIFormattedText label="vendors.unilateralWithoutPayment.collectVendorEmailModal.subTitle" />
          </div>
        </>
      }
      contentComponent={
        <Box w="full" mb={-8} onKeyDown={onKeyPressed}>
          <TextField
            id="contactEmail"
            label="vendors.unilateralWithoutPayment.collectVendorEmailModal.label"
            model={contactEmail}
            placeholder="vendors.unilateralWithoutPayment.collectVendorEmailModal.placeholder"
            type="email"
            isRequired
            autoFocus
          />
        </Box>
      }
      buttonLabel="vendors.unilateralWithoutPayment.collectVendorEmailModal.buttonLabel"
      onButtonClick={handleCollectVendorEmailModalSubmit}
      onCloseClick={handleCollectVendorEmailModalCancel}
      isLoading={isProcessing}
    />
  );
};
