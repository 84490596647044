import { keyframes } from 'src/core/ds';
import Box from 'src/core/ds/box';

export const AnimationProgress = () => (
  <Box h="0.3rem" flex="1" m={1} borderRadius={2}>
    <Box h="0.3rem" borderRadius="md" bgGradient="linear(to-r,green.500 5%, grey.300)">
      <Box h="full" borderRadius="md" w="full" bgGradient={gradient} bgSize="200%" animation={animation} />
    </Box>
  </Box>
);

const spin = keyframes`
  from { background-position: 100%; }
  to { background-position:0%; }
`;

const animation = `${spin} 2s linear infinite `;
const gradient = 'linear(to-r,grey.300 0%,transparent 25%,grey.300 50%,transparent 75%,grey.300 100%)';
