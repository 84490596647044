import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { ModalTitle } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { analytics } from 'src/services/analytics';
import { UserOrganization } from 'src/utils/types';
import { getFullName } from 'src/utils/user';
import { InnerScrollModalMessage } from '../InnerScrollModalMessage';
import { RolesAndPermissionsModalMessage } from '../RolesAndPermissionsModalMessage';
import { ClientItem } from './ClientItem';
import { ClientModelViewType } from './types';
import { useScrollToValidationError } from './useScrollToValidationError';

type Props = {
  titleLabel: string;
  isLoading?: boolean;
  firstName?: string;
  lastName?: string;
  actorUserOrgs: readonly UserOrganization[];
  clients: ClientModelViewType[];
  showApprovalFields: boolean;
  onSave: () => void;
  onCloseClick: (event?: React.MouseEvent) => void;
};

export const AssignClientsModalMessage = ({
  titleLabel,
  isLoading,
  firstName,
  lastName,
  actorUserOrgs,
  clients,
  showApprovalFields,
  onCloseClick,
  onSave,
}: Props) => {
  const [status, setStatus] = React.useState<'assign' | 'learn-more'>('assign');
  const displayFirstName = firstName || lastName;
  const name = getFullName(firstName, lastName);
  const { refs, resetScrollState } = useScrollToValidationError(clients);

  const gotoLearnMore = () => {
    analytics.trackAction('team.modals.assignClients.learnMore');
    setStatus('learn-more');
  };
  const gotoAssign = () => {
    setStatus('assign');
  };
  const handleSave = () => {
    resetScrollState();
    onSave();
  };

  if (status === 'assign') {
    return (
      <InnerScrollModalMessage
        id="assign-clients-modal"
        onCloseClick={onCloseClick}
        titleComponent={
          <>
            <ModalTitle>
              <MIFormattedText label={titleLabel} values={{ name }} />
            </ModalTitle>
            <AssignClientsModalSubTitle>
              <MIFormattedText label="team.modals.assignClients.subTitle" values={{ firstName: displayFirstName }} />
              <MIInlineLink onClick={gotoLearnMore} label="team.modals.assignClients.learnMore" />
            </AssignClientsModalSubTitle>
          </>
        }
        contentComponent={clients.map((client, index) => (
          <ClientItem
            ref={refs[index]}
            key={index}
            client={client}
            actorUserOrgs={actorUserOrgs}
            showApprovalFields={showApprovalFields}
          />
        ))}
        buttonComponent={
          <Button
            boxSizing="border-box"
            onClick={handleSave}
            isLoading={isLoading}
            size={ButtonSizes.md}
            width="100%"
            label="team.modals.assignClients.buttons.save"
          />
        }
      />
    );
  }

  if (status === 'learn-more') {
    return <RolesAndPermissionsModalMessage onCloseClick={onCloseClick} onOkClick={gotoAssign} />;
  }

  throw new Error(`Status ${status} is not supported`);
};

const AssignClientsModalSubTitle = styled.div`
  text-align: left;
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: ${(props) => props.theme.text.lineHeight.medium};
  color: ${(props) => props.theme.text.color.subtitle};

  button {
    font-size: inherit;
    font-weight: inherit;
    color: ${(props) => props.theme.colors.brand};
    padding-left: 0.4rem;
  }
`;
