import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ManualAddressConfirmPage } from 'src/components/layout/ManualAddressConfirmPage';
import { ManualAddressForm } from 'src/components/layout/ManualAddressForm';
import { ManualAddressInvalidPage } from 'src/components/layout/ManualAddressInvalidPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { ManualAddress } from 'src/utils/consts';
import { AddressType, FieldType, LegalAddressType } from 'src/utils/types';

type Props = {
  confirmMode: boolean;
  invalidAddress: boolean;
  printName?: string;
  onManualAddressChange: (field: FieldType) => void;
  validationErrors: Record<string, any>;
  validManualAddress: ManualAddressType;
  manualAddressValidationErrors: Record<string, any>;
  manualAddress: AddressType | LegalAddressType;
  onManualAddressSelected: (arg0: string) => void;
  onEditAddress: () => void;
  selectedAddressId: ManualAddress;
  subTitle: string;
};

export const ManualAddressPage = ({
  confirmMode,
  invalidAddress,
  manualAddress,
  validationErrors,
  onEditAddress,
  onManualAddressChange,
  printName,
  subTitle,
  validManualAddress,
  manualAddressValidationErrors,
  selectedAddressId,
  onManualAddressSelected,
}: Props) => (
  <>
    {!confirmMode && !invalidAddress && (
      <ManualAddressForm
        manualAddress={manualAddress}
        onChange={onManualAddressChange}
        validationErrors={validationErrors}
      />
    )}
    {invalidAddress && (
      <WizardElements.InnerWizardContentWrapper>
        <WizardElements.WizardFormRow>
          <WizardElements.WizardStepSubTitle>
            <MIFormattedText label="manualAddress.invalidSubtitle" />
          </WizardElements.WizardStepSubTitle>
        </WizardElements.WizardFormRow>
        <ManualAddressInvalidPage originalAddress={manualAddress} printName={printName} onEditAddress={onEditAddress} />
      </WizardElements.InnerWizardContentWrapper>
    )}
    {confirmMode && (
      <WizardElements.InnerWizardContentWrapper>
        <WizardElements.WizardFormRow>
          <WizardElements.WizardStepSubTitle>
            <MIFormattedText label={subTitle} />
          </WizardElements.WizardStepSubTitle>
        </WizardElements.WizardFormRow>
        <ManualAddressConfirmPage
          addressToConfirm={validManualAddress}
          addressValidationErrors={manualAddressValidationErrors}
          originalAddress={manualAddress}
          printName={printName}
          onAddressSelect={(id) => onManualAddressSelected(id)}
          onEditAddress={onEditAddress}
          selectedAddressId={selectedAddressId}
        />
      </WizardElements.InnerWizardContentWrapper>
    )}
  </>
);
