import filter from 'lodash/filter';
import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import { createRestfulSlice, hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { BILLS_FETCH_SUCCESS } from 'src/modules/bills/bills-actions';
import { INVITATIONS_VALIDATE_SUCCESS } from 'src/modules/invitations/invitations-actions';
import { JOIN_ORGANIZATION_REQUEST_ACCEPT_REQUEST_SUCCESS } from 'src/modules/join-organization-requests/join-organization-requests-actions';
import {
  ORGANIZATIONS_CREATE_NOT_OWNED_SUCCESS,
  ORGANIZATIONS_CREATE_SUCCESS,
} from 'src/modules/organizations/organizations-actions';
import { IMPORT_QBO_CLIENTS, SET_PROFILE } from 'src/redux/user/actionTypes';
import { UserContextType, UserOrganization, UserSummaryType } from 'src/utils/types';
import { getUserOrganization } from 'src/utils/user';
import { PAYMENTS_FETCH_SUCCESS } from '../payments/payment-actions';
import { addUsersSlice } from './add-users-slice';
import { allUserOrganizationsSlice } from './all-user-organizations-slice';
import { usersApi } from './api';
import { editUserOrganizationsSlice } from './edit-user-organizations-slice';
import {
  mergeUserOrganizationsCustomizer,
  userManagementChangeOwnerSlice,
  userManagementDeleteSlice,
  userManagementHideFirmClientSlice,
  userManagementListSlice,
  userManagementUnhideFirmClientSlice,
  userManagementUpdateSlice,
} from './user-management-slice';

const name = 'users';

type UpdateUserOrganizationMetaInfoType =
  | {
      disableNotification?: boolean;
    }
  | Partial<UserOrganization>;

export function getUsersOfOrg(orgId: number) {
  return (state): ReadonlyArray<UserSummaryType & Pick<UserContextType, 'userOrganizations'>> =>
    filter(state[name].byId, (user) => user.userOrganizations.some((userOrg) => userOrg.organizationId === orgId));
}
const usersStore = createRestfulSlice({
  name,
  schemaName: 'user',
  api: usersApi,
  extraReducers: {
    [SET_PROFILE](state, payload) {
      const { userPreferences, ...profile } = payload.profile;
      const { organizations } = payload;
      state.byId[profile.id] = merge(state.byId[profile.id] || {}, {
        ...profile,
        userOrganizations: (organizations || []).filter((o) => !!o.userOrganization).map((o) => o.userOrganization),
      });
    },
    [BILLS_FETCH_SUCCESS](state, { payload }) {
      (payload.payments || []).forEach((payment) => {
        (payment.metadata?.audit || []).forEach((auditLog) => {
          const user = {
            ...auditLog.actor,
            userOrganizations: [
              {
                userId: auditLog.actor.id,
                organizationId: payload.organizationId,
                role: auditLog.actor.role,
              },
            ],
          };
          state.byId[user.id] = mergeWith(state.byId[user.id] || {}, user, mergeUserOrganizationsCustomizer);
        });
      });
    },
    [PAYMENTS_FETCH_SUCCESS](state, { payload }) {
      const { organizationId, metadata } = payload;
      (metadata?.audit || []).forEach((auditLog) => {
        const user = {
          ...auditLog.actor,
          userOrganizations: [
            {
              userId: auditLog.actor.id,
              organizationId,
              role: auditLog.actor.role,
            },
          ],
        };
        state.byId[user.id] = mergeWith(state.byId[user.id] || {}, user, mergeUserOrganizationsCustomizer);
      });
    },
    [ORGANIZATIONS_CREATE_SUCCESS](state, { payload }) {
      const { userId } = payload.userOrganization;
      const { userOrganizations } = state.byId[userId];
      userOrganizations.push(payload.userOrganization);
    },
    [INVITATIONS_VALIDATE_SUCCESS](state, { payload }) {
      const user = { ...payload.userOrganization.user, userOrganizations: [payload.userOrganization] };
      state.byId[user.id] = mergeWith(state.byId[user.id] || {}, user, mergeUserOrganizationsCustomizer);
    },
    [JOIN_ORGANIZATION_REQUEST_ACCEPT_REQUEST_SUCCESS](state, { payload }) {
      const user = { ...payload.userOrganization.user, userOrganizations: [payload.userOrganization] };
      state.byId[user.id] = mergeWith(state.byId[user.id] || {}, user, mergeUserOrganizationsCustomizer);
    },
    [ORGANIZATIONS_CREATE_NOT_OWNED_SUCCESS](state, { payload }) {
      const { userId } = payload.organization.userOrganization;
      const { userOrganizations } = state.byId[userId];
      userOrganizations.push(payload.organization.userOrganization);
    },
    [IMPORT_QBO_CLIENTS](state, { payload }) {
      const newUserOrganizations = payload.importedClients.map(({ userOrganization }) => userOrganization);
      (newUserOrganizations || []).forEach((userOrganization) => {
        const { userOrganizations } = state.byId[payload.userId];
        userOrganizations.push(userOrganization);
      });
    },
  },
  selectors: {
    org(orgId) {
      const orgSelectors = {
        users(state) {
          return userManagementListSlice.select?.({
            orgId,
          })(state[name]);
        },
        isMultiUser(state) {
          return (
            userManagementListSlice.select?.({
              orgId,
            })(state[name]).length > 1
          );
        },
        isLoading(state) {
          const hashKey = hashListKey({
            orgId,
          });

          return state[name].lists[hashKey]?.loading;
        },
      };

      return orgSelectors;
    },
    byId(userId) {
      return (state) => state[name].byId[userId];
    },
    userOrganization(userId, orgId) {
      const selectors = {
        userOrg(state) {
          const user = state[name].byId[userId];

          return getUserOrganization(user, orgId);
        },
        isLoading(state) {
          return state[name].meta[userId]?.loading;
        },
        isUpdating(state) {
          return state[name].update[userId]?.loading;
        },
      };

      return selectors;
    },
    changeOwner(orgId) {
      const selectors = {
        isChanging(state) {
          return state[name].changeOwner[orgId]?.isChanging;
        },
      };

      return selectors;
    },
  },
  slices: {
    userManagementDeleteSlice,
    userManagementUpdateSlice,
    userManagementListSlice,
    userManagementChangeOwnerSlice,
    userManagementHideFirmClientSlice,
    userManagementUnhideFirmClientSlice,
    editUserOrganizations: editUserOrganizationsSlice,
    allUserOrganizations: allUserOrganizationsSlice,
    addUsers: addUsersSlice,
  },
});

export function getUserActions(dispatch) {
  return {
    async list(params) {
      return dispatch(userManagementListSlice.actions(params));
    },
    async updateUserOrganization(
      userOrganization: Partial<UserOrganization>,
      meta?: UpdateUserOrganizationMetaInfoType
    ) {
      return dispatch(
        userManagementUpdateSlice.actions(
          {
            orgId: userOrganization.organizationId,
            id: userOrganization.userId,
            role: userOrganization.role,
            requireApproval: userOrganization.requireApproval,
            approvalAmountThreshold: userOrganization.approvalAmountThreshold,
          },
          meta
        )
      );
    },
    async deleteUserOrganization(userOrganization) {
      return dispatch(
        userManagementDeleteSlice.actions({
          orgId: userOrganization.organizationId,
          id: userOrganization.userId,
        })
      );
    },
  };
}

export default usersStore;
