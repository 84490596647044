import { MicroDepositsAlreadyVerifiedDialog } from 'src/components/micro-deposits/modals/MicroDepositsAlreadyVerifiedDialog';
import { MicroDepositsWasSentDialog } from 'src/components/micro-deposits/modals/MicroDepositsWasSentDialog';
import { Resolutions } from 'src/pages/settings/hooks/microDepositsCommon';
import { useFundingSourceMicroDepositState } from 'src/pages/settings/hooks/useFundingSourceMicroDeposits';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { getIsRecentlySentMicroDeposits } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSource: AccountType;
  setShowMicroDepositsWasSentDialog: (boolean) => void;
};

const eventPage = 'review-confirm-verify-manual-account';

export const PayBillPaymentMicroDepositDialog = ({ fundingSource, setShowMicroDepositsWasSentDialog }: Props) => {
  const [microDepositState, microDepositActions] = useFundingSourceMicroDepositState(eventPage, {
    fundingSourceId: fundingSource.id,
  });
  const depositsDeliverDate = fundingSource?.bankAccount?.depositsDeliverDate;
  const isRecentlySentMicroDeposits = depositsDeliverDate ? getIsRecentlySentMicroDeposits(depositsDeliverDate) : null;
  const isResolutionVerified = microDepositState.resolution === Resolutions.SUCCESS;
  const digits = getAccountNumber4digits(fundingSource.bankAccount);
  const onCloseDialog = () => setShowMicroDepositsWasSentDialog(false);

  return (
    <>
      {isResolutionVerified ? (
        <MicroDepositsAlreadyVerifiedDialog onOkAction={onCloseDialog} />
      ) : (
        <MicroDepositsWasSentDialog
          {...microDepositState}
          {...microDepositActions}
          digits={digits}
          dismiss={onCloseDialog}
          isRecentlySentMicroDeposits={isRecentlySentMicroDeposits}
          showContinueWithoutVerifyingButton={false}
        />
      )}
    </>
  );
};
