import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { ModalContainer, ModalMessage } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { UseModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { VendorAddressType } from 'src/modules/vendors/types';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { analytics } from 'src/services/analytics';
import clientServiceApi from 'src/services/api/clientService';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { Country, NotificationVariant } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { AddressType, VendorType } from 'src/utils/types';
import { AmexModalTitle } from '../../components/AmexModalTitle';
import { AmexVendorAddressForm } from './AmexVendorAddressForm';
import { AmexVendorAddressVerificationConfirmModal } from './AmexVendorAddressVerificationConfirmModal';
import { AmexVendorAddressVerificationFailedModal } from './AmexVendorAddressVerificationFailedModal';

enum AmexVendorAddressModalStatus {
  EditAddress = 'editAddress',
  VerificationFailed = 'verificationFailed',
  VerificationConfirm = 'verificationConfirm',
}

type Props = {
  dismiss: () => void;
  onConfirm?: () => void;
  orgId: number;
  vendorName: string;
  vendor: VendorType;
  analyticsProps: {
    billId?: string | null;
    vendorId: string;
    isBatch: boolean;
  };
};

export const AmexVendorAddressModal: React.FC<UseModal<Props>> = ({
  dismiss,
  onConfirm,
  orgId,
  vendor,
  vendorName,
  analyticsProps,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isAllAddressFieldsVisible, setAddressFieldsVisible] = useState<boolean>(false);
  const [amexVendorAddressModalStatus, setAmexVendorAddressModalStatus] = useState<AmexVendorAddressModalStatus>(
    AmexVendorAddressModalStatus.EditAddress
  );
  const showAllAddressFields = useCallback(() => {
    setAddressFieldsVisible(true);
  }, [setAddressFieldsVisible]);
  const vendorActions = useStoreActions(vendorsStore);
  const vendorAddress = vendor?.address;
  const vendorId = vendor?.id;

  const { onApiCall: verifyAddressApiCall, result: verifyAddressResult } = useApi<[AddressType], ManualAddressType>({
    api: clientServiceApi.getAddressValidationSmartyStreets,
  });

  const model = useMemo(
    () => ({
      addressLine1: vendorAddress?.addressLine1 || '',
      addressLine2: '',
      city: vendorAddress?.city || '',
      state: vendorAddress?.state || '',
      zipCode: vendorAddress?.zipCode || '',
      countryCode: Country.US,
    }),
    [vendorAddress]
  );

  const updateVendorAddress = async (vendorAddress: AddressType) => {
    try {
      await vendorActions.update({
        orgId,
        id: vendorId,
        address: vendorAddress,
      });

      await onConfirm?.();
      dismiss();
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'bills.pay.fundingSource.amexVendorAddressModal.notification.success',
        textValues: {
          vendorName,
        },
      });
    } catch (err: any) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'serverErrors.ERR',
      });
      analytics.trackAction('amex-vendor-address-error', analyticsProps);
      capture(err, {
        message: `Can not update vendor's (AMEX) address`,
      });
    }
  };

  const [vendorAddressMV, { submit }] = useForm<VendorAddressType>(model, {
    submit: async (formData) => {
      setIsSubmitting(true);
      analytics.trackAction('amex-vendor-address-save', analyticsProps);

      const validationErrors = getValidationErrors('vendorAddress', formData);

      if (!isValidationOk(validationErrors)) {
        setIsSubmitting(false);
        throw new ValidationError({ validationErrors });
      }

      try {
        const verifiedAddress = await verifyAddressApiCall(formData);

        if (!verifiedAddress.is_valid) {
          setIsSubmitting(false);
          setAmexVendorAddressModalStatus(AmexVendorAddressModalStatus.VerificationFailed);

          return;
        }

        if (verifiedAddress.diff.length) {
          setIsSubmitting(false);
          setAmexVendorAddressModalStatus(AmexVendorAddressModalStatus.VerificationConfirm);

          return;
        }
      } catch (err: any) {
        setIsSubmitting(false);
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'serverErrors.ERR',
        });
        analytics.trackAction('amex-vendor-address-error', analyticsProps);
        capture(err, {
          message: `Can not verify vendor's (AMEX) address`,
        });

        return;
      }

      await updateVendorAddress(formData);
      setIsSubmitting(false);
    },
  });

  const onCloseClick = () => {
    analytics.trackAction('amex-vendor-address-close', analyticsProps);
    dismiss();
  };

  const originalAddress = {
    addressLine1: vendorAddressMV.addressLine1?.value,
    addressLine2: vendorAddressMV.addressLine2?.value,
    city: vendorAddressMV.city?.value,
    state: vendorAddressMV.state?.value,
    zipCode: vendorAddressMV.zipCode?.value,
    countryCode: vendorAddressMV.countryCode?.value,
  };

  if (amexVendorAddressModalStatus === AmexVendorAddressModalStatus.VerificationConfirm) {
    return (
      <AmexVendorAddressVerificationConfirmModal
        originalAddress={originalAddress}
        verifyAddressResult={verifyAddressResult}
        vendorName={vendor?.companyName}
        onClose={onCloseClick}
        onEditSuggestedAddress={(suggestedAddress) => {
          setAmexVendorAddressModalStatus(AmexVendorAddressModalStatus.EditAddress);
          vendorAddressMV.setModelState((prevState) => ({
            ...prevState,
            ...suggestedAddress,
          }));
          vendorAddressMV.setValidationErrors({});
        }}
        onConfirmOriginalAddress={() => {
          setAmexVendorAddressModalStatus(AmexVendorAddressModalStatus.VerificationFailed);
        }}
        onConfirmSuggestedAddress={async (suggestedAddress) => {
          setIsSubmitting(true);
          await updateVendorAddress(suggestedAddress);
          setIsSubmitting(false);
        }}
        isSubmitting={isSubmitting}
      />
    );
  }

  if (amexVendorAddressModalStatus === AmexVendorAddressModalStatus.VerificationFailed) {
    return (
      <AmexVendorAddressVerificationFailedModal
        onClose={onCloseClick}
        vendorName={vendor?.companyName}
        address={originalAddress}
        onEditClick={() => {
          setAmexVendorAddressModalStatus(AmexVendorAddressModalStatus.EditAddress);
        }}
      />
    );
  }

  return (
    <StyledModalMessage
      id="amex-address-modal"
      onCloseClick={onCloseClick}
      titleComponent={
        <AmexModalTitle
          testId="amex-address-modal-title"
          titleLabel="bills.pay.fundingSource.amexVendorAddressModal.title"
          subtitleLabel="bills.pay.fundingSource.amexVendorAddressModal.description"
          vendorName={vendorName}
        />
      }
      contentComponent={
        <AmexVendorAddressForm
          isAllFieldsVisible={isAllAddressFieldsVisible}
          showAllFields={showAllAddressFields}
          formModelView={vendorAddressMV}
          analyticsProps={analyticsProps}
        />
      }
      buttonComponent={
        <Button
          isFullWidth
          onClick={submit}
          label="bills.pay.fundingSource.amexVendorAddressModal.cta"
          variant={ButtonVariants.primary}
          isLoading={isSubmitting}
          size={ButtonSizes.lg}
        />
      }
    />
  );
};

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow-y: visible;
  }
`;
