import * as React from 'react';
import { StyleProps } from 'src/core/ds';
import Box from 'src/core/ds/box';

type Props = {
  withoutBorder?: boolean | null;
  children: React.ReactNode;
} & StyleProps;

const containerStyle = {
  border: '1px',
  borderColor: 'grey.400',
  borderRadius: 'lg',
  '& > :not(:last-child)': {
    borderBottom: '1px',
    borderColor: 'grey.400',
    '&:first-of-type': {
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
    },
  },
  '& > :last-child': {
    borderBottomLeftRadius: 'lg',
    borderBottomRightRadius: 'lg',
  },
};

const CardBody: React.FC<Props> = ({ withoutBorder, children, ...rest }) => {
  if (withoutBorder) return <Box {...rest}>{children}</Box>;

  return (
    <Box __css={containerStyle} {...rest}>
      {children}
    </Box>
  );
};

export default CardBody;
