import { forwardRef, Input as ChakraInput, InputProps } from '@chakra-ui/react';

export type Props = InputProps & {
  id?: string;
  testId?: string | null;
  isViewOnly?: boolean;
};

export const FileInput = forwardRef<Props, 'input'>(
  ({ id, testId, value, isViewOnly = false, ...rest }: Props, ref) => {
    const inputTestId = testId || `input-${id}`;

    return (
      <ChakraInput
        {...rest}
        id={id}
        data-testid={inputTestId}
        hidden={isViewOnly}
        value={value}
        ref={ref}
        visibility="hidden"
        position="absolute"
        width="1px"
        height="1px"
      />
    );
  }
);
