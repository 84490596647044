import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { getFundingSourceEventData } from 'src/analytics/utils/fundingSource';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useOrgId } from 'src/hooks';
import { useRequestDashboardPath } from 'src/hooks/useRequestDashboardPath';
import { useRefreshFundingSources } from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getDeliveryMethods, getFundingSources } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { AddFundingSourceWizardOrigin, DeliveryMethodOrigin } from 'src/utils/consts';
import { isUnverifiedAchLimitExceeded } from 'src/utils/funding-sources';
import locations from 'src/utils/locations';
import SelectBankAccountVerificationTypePage from './components/SelectBankAccountVerificationTypePage';

export type Option = {
  id: string;
  label: string;
  description: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
};

type Props = {
  vendorId: string;
};

const eventPage = 'bank-add-variants';

/**
 * @deprecated please do not use this - use src/flows/add-bank-account-flow/steps/select/SelectVerificationTypePageContainer.tsx
 */
export const SelectBankAccountVerificationTypePageContainer = ({ vendorId }: Props) => {
  const { navigateToExitWithPreservedState, navigate } = usePreservedStateNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();
  const orgId = useOrgId();
  const [dashboardPath] = useRequestDashboardPath();
  const origin = get(locationState, 'preservedState.origin', '');
  const userDeliveryMethods = useSelector(getDeliveryMethods);
  const userFundingSources = useSelector(getFundingSources);
  const currentUser = useSelector(profileStore.selectors.profile);
  const currentBill = useSelector(getBill);
  const [showUnverifiedAchLimitNotification, setShowUnverifiedAchLimitNotification] = useState<boolean>(false);
  const isComingFromFundingSourceWizard = [
    AddFundingSourceWizardOrigin.SETTINGS,
    AddFundingSourceWizardOrigin.GUEST_ONBOARDING,
    AddFundingSourceWizardOrigin.PAY_BILL,
    AddFundingSourceWizardOrigin.BATCH_PAY_BILLS,
    AddFundingSourceWizardOrigin.BILLING,
  ].includes(origin);
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  useEffect(() => {
    refreshFundingSources();
  }, [refreshFundingSources]);

  const onPlaidClick = () => {
    analytics.track(eventPage, 'add-plaid', getFundingSourceEventData(currentUser, userFundingSources, currentBill));

    if (vendorId) {
      navigate(generatePath(onboardingLocations.deliveryMethods.bank.plaid, { orgId, id: vendorId }), false, {
        preservedState: {
          ...locationState,
        },
        redirectUrl: onboardingLocations.vendorCompanyInfo.added,
        exitUrl: dashboardPath,
      });
    } else {
      navigate(onboardingLocations.fundingSources.bank.plaid);
    }
  };

  const onManualClick = () => {
    if (isUnverifiedAchLimitExceeded(userFundingSources) && isComingFromFundingSourceWizard) {
      analytics.track(eventPage, 'add-manual-limit');
      setShowUnverifiedAchLimitNotification(true);
    } else {
      analytics.track(eventPage, 'add-manual', getFundingSourceEventData(currentUser, userFundingSources, currentBill));

      if (vendorId) {
        navigate(
          generatePath(onboardingLocations.deliveryMethods.bank.create, {
            orgId,
            id: vendorId,
          }),
          false,
          {
            origin,
            redirectUrl: onboardingLocations.vendorCompanyInfo.added,
          }
        );
      } else {
        navigate(onboardingLocations.fundingSources.bank.manually);
      }
    }
  };

  const goDashboard = () => {
    navigate(getPaidLocations.dashboard);
  };

  const goExit = () => {
    analytics.track(eventPage, 'exit');

    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState();
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const goPrev = () => {
    navigate(onboardingLocations.vendorCompanyInfo.logo);
  };

  const bankType = isEmpty(vendorId) ? 'fundingSources' : 'deliveryMethods';
  const verificationOptions: Array<Option> = [
    {
      id: 'plaid',
      label: `onboarding.${bankType}.bank.verification.plaid.label`,
      description: `onboarding.${bankType}.bank.verification.plaid.description`,
      onClick: onPlaidClick,
    },
    {
      id: 'manual',
      label: `onboarding.${bankType}.bank.verification.manual.label`,
      description: `onboarding.${bankType}.bank.verification.manual.description`,
      onClick: onManualClick,
    },
  ];

  const isOnPrevAndStepsShown = !!vendorId && origin !== DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS;

  if (isFundingSourcesRefreshing) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <SelectBankAccountVerificationTypePage
      options={verificationOptions}
      goExit={goExit}
      goPrev={goPrev}
      vendorId={vendorId}
      isOnPrevAndStepsShown={isOnPrevAndStepsShown}
      deliveryMethods={userDeliveryMethods}
      goDashboard={goDashboard}
      showUnverifiedAchLimitNotification={showUnverifiedAchLimitNotification}
    />
  );
};
