import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { EmailLink } from 'src/components/common/EmailLink';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { Image } from 'src/core/ds/image';
import { useSiteContext } from 'src/hoc/withSiteContext';
import InfoImage from 'src/images/core/info-icon.svg';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { eventPage } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardView';
import { virtualCardLocations } from 'src/pages/vendor/virtual-card/delivery-method/locations';
import { analytics } from 'src/services/analytics';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardRecoveredView = ({ state, analyticsProperties }: Props) => {
  const site = useSiteContext();
  const history = useHistory();
  const { organization, virtualCard, payment, token } = state;
  const lastCreatedVirtualCard = payment?.lastCreatedVirtualCard;
  useEffect(() => {
    analytics.track(eventPage, 'recovered', analyticsProperties);
  }, [analyticsProperties]);

  const handleClick = () => {
    analytics.track(eventPage, 'recovered_click-cta', analyticsProperties);
    history.push(generatePath(virtualCardLocations.index, { token, id: lastCreatedVirtualCard?.id }));
  };

  return (
    <ContentWrapper>
      <Image width={8} height={8} src={InfoImage} />
      <Box textStyle="body2Semi" mt={5} mb={2}>
        <MIFormattedText
          label="vendors.virtualCard.recovered.title"
          values={{
            expDate: <MIFormattedDate date={virtualCard?.expirationDate} />,
          }}
        />
      </Box>
      <Box textStyle="body2">
        <MIFormattedText
          label="vendors.virtualCard.recovered.description"
          values={{
            payorName: organization?.companyName,
            date: <MIFormattedDate date={lastCreatedVirtualCard?.createdAt} />,
            amount: <MIFormattedCurrency value={payment?.amount} />,
          }}
        />
      </Box>
      <Box my={5}>
        <Button
          label="vendors.virtualCard.recovered.ctaLabel"
          size={ButtonSizes.lg}
          isFullWidth
          onClick={handleClick}
        />
      </Box>
      <Box textStyle="body3">
        <MIFormattedText
          label="vendors.virtualCard.recovered.notice"
          values={{
            supportEmail: <EmailLink email={site.config.support.email} />,
          }}
        />
      </Box>
    </ContentWrapper>
  );
};
