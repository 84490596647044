import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { IconNames } from 'src/core/ds/icon';
import colors from 'src/core/theme/foundations/colors';
import { Filters } from 'src/utils/types';
import fullMoonImage from '../../images/general/full-moon.svg';
import { devices } from '../../theme/appDevices';
import { IconButton } from '../common/IconButton';
import { EmptyListButtonContainer } from '../layout/Containers';
import ZeroState from './ZeroState';

type Props = {
  zeroStateTitle?: string;
  ctaLabel?: string;
  ctaAction?: (event: React.SyntheticEvent<HTMLInputElement>) => void | string | Promise<void>;
  textTitle?: string;
  textTitleValues?: Record<string, any>;
  text: string;
  textValues?: Record<string, any>;
  textAction?: (filters: Filters) => void;
  textActionLabel?: string;
  isHideActionButton?: boolean;
  showZeroState?: boolean;
  // TODO - fix empty list height calculation
  zeroStateOptions?: Array<Record<string, any>>;
  ctaZeroState?: string;
  zeroStateAction?: () => void;
  showVerifyMdIndicator?: boolean;
  logo?: string;
};

export const EmptyList = ({
  zeroStateTitle = '',
  ctaLabel = '',
  ctaAction,
  text,
  textValues,
  isHideActionButton = true,
  showZeroState = false,
  zeroStateOptions = [],
  ctaZeroState = '',
  zeroStateAction,
  showVerifyMdIndicator = false,
  textTitle = '',
  textTitleValues,
  textAction,
  textActionLabel,
  logo = fullMoonImage,
}: Props) => (
  <EmptyListContainer data-testid="empty-list-container">
    {!isHideActionButton && (
      <EmptyListButtonContainer>
        {ctaAction && ctaLabel && (
          <IconButton
            icon={IconNames.plus}
            label={ctaLabel}
            onClick={ctaAction}
            variant="inlineIconAndTextPrimary"
            size="normal"
            testId={`button-${ctaLabel}`}
          />
        )}
      </EmptyListButtonContainer>
    )}

    {showZeroState && (
      <ZeroState
        title={zeroStateTitle}
        options={zeroStateOptions}
        ctaZeroState={ctaZeroState}
        zeroStateAction={zeroStateAction}
      />
    )}

    <MiddleContainer showZeroState={showZeroState} showVerifyMdIndicator={showVerifyMdIndicator}>
      <EmptyListImage src={logo} />
      {textTitle && (
        <TextTitle>
          <MIFormattedText label={textTitle} values={textTitleValues} />
        </TextTitle>
      )}
      <SubTitle hasTextTitle={textTitle} data-testid="empty-list-subtitle">
        <MIFormattedText label={text} values={textValues} />
      </SubTitle>
      {textAction ? (
        <Box textStyle="body4Semi" mt={5} onClick={textAction} cursor="pointer">
          <MIFormattedText label={textActionLabel} />
        </Box>
      ) : null}
    </MiddleContainer>
  </EmptyListContainer>
);

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${devices.mobile}, ${devices.phablet} {
    height: calc(100vh - 46rem);
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${(props) => (props.showZeroState ? 'auto' : '100%')};
  justify-content: ${(props) => (props.showZeroState ? '' : 'center')};
  padding-bottom: ${(props) => (props.showZeroState ? '10rem' : 0)};
  margin-top: ${(props) => (props.showVerifyMdIndicator ? '2.3rem' : '20rem')};

  @media ${devices.tablet}, ${devices.desktop} {
    height: auto;
  }

  @media ${devices.desktop} {
    top: 48.5rem;
  }

  @media ${devices.tablet} {
    top: 51.5rem;
  }
`;

const EmptyListImage = styled.img`
  width: 10rem;
  height: 10rem;
`;

const SubTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => (props.hasTextTitle ? props.theme.text.weight.regular : props.theme.text.weight.semiBold)};
  color: ${(props) => props.theme.text.color.label};
  margin-top: ${(props) => (props.hasTextTitle ? '0.8rem' : '2rem')};
  text-align: center;
  line-height: ${(props) => props.theme.text.lineHeight.hint};
`;

const TextTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${colors.black};
  margin-top: 2rem;
  text-align: center;
`;
