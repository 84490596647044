import concat from 'lodash/concat';
import { lazy, Suspense } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { JustPayPage } from 'src/pages/onboarding/just-pay/JustPayPage';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { AddAchFromOnboardingFlowWrapper } from '../vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromOnboardingFlowWrapper';
import accountingSoftwareRoutes from './accounting-software/route';
import { CantFindBankPageContainer } from './funding-sources/bank/CantFindBankPageContainer';
import { SelectBankAccountVerificationTypePageContainer } from './funding-sources/bank/SelectBankAccountVerificationTypePageContainer';
import { SetBankPlaidPageContainer } from './funding-sources/bank/SetBankPlaidPageContainer';
import fundingSourcesRoutes from './funding-sources/route';
import { vendorCompanyInfoRoutes } from './vendor-company-info/route';

const SuccessLinkingPageContainerAsync = lazy(
  () => import('./accounting-software/quickbooks/SuccessLinkingPageContainer')
);

const eventPage = 'onboarding-done';

const welcomeRoutes = [
  {
    path: onboardingLocations.quickbooks,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'software-linked');

      return (
        <Suspense fallback={<AreaLoader />}>
          <SuccessLinkingPageContainerAsync {...cProps} />
        </Suspense>
      );
    },
  },
];

const vendorDeliveryMethodsRoutes = [
  {
    path: onboardingLocations.deliveryMethods.bank.select,
    exact: true,
    component: (cProps) => {
      analytics.page('onboarding-vendor-delivery-methods', 'bank-verification-type-select');

      return <SelectBankAccountVerificationTypePageContainer {...cProps} vendorId={cProps.match.params.id} />;
    },
  },
  {
    path: onboardingLocations.deliveryMethods.bank.plaid,
    exact: true,
    component: (cProps) => {
      analytics.page('onboarding-vendor-delivery-methods', 'plaid-bank-delivery-method');

      return <SetBankPlaidPageContainer {...cProps} vendorId={cProps.match.params.id} />;
    },
  },
  {
    path: onboardingLocations.deliveryMethods.bank.cantFind,
    exact: true,
    component: (cProps) => {
      analytics.page('onboarding-vendor-delivery-methods', 'bank-cant-find');

      return <CantFindBankPageContainer {...cProps} vendorId={cProps.match.params.id} />;
    },
  },
  {
    path: onboardingLocations.deliveryMethods.bank.create,
    exact: true,
    component: (cProps) => {
      analytics.page('onboarding-vendor-delivery-methods', 'add-bank-delivery-method');

      return (
        <AddAchFromOnboardingFlowWrapper
          {...cProps}
          orgId={cProps.match.params.orgId}
          vendorId={cProps.match.params.id}
          inputFields={['routingNumber', 'accountNumber']}
        />
      );
    },
  },
  {
    path: onboardingLocations.deliveryMethods.bank.edit,
    exact: true,
    component: (cProps) => {
      analytics.page('onboarding-vendor-delivery-methods', 'edit-bank-delivery-method');

      return (
        <AddAchFromOnboardingFlowWrapper
          {...cProps}
          orgId={cProps.match.params.orgId}
          vendorId={cProps.match.params.id}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
          inputFields={['routingNumber', 'accountNumber']}
        />
      );
    },
  },
];

const justPayRoutes = [
  {
    path: onboardingLocations.justPay.index,
    exact: true,
    component: () => {
      analytics.page('bills', 'payment_details', { origin: 'just_pay' });

      return <JustPayPage />;
    },
  },
];

export default concat(
  welcomeRoutes,
  fundingSourcesRoutes,
  accountingSoftwareRoutes,
  vendorCompanyInfoRoutes,
  vendorDeliveryMethodsRoutes,
  justPayRoutes
);
