import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { AnalyticPropsInState } from 'src/pages/vendor/components/InvalidTokenPage';
import { usePaymentDataFromToken } from 'src/pages/vendor/hooks/usePaymentDataFromToken';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';

type Props = {
  token: string;
  hideLogin?: boolean;
};
const ANALYTICS_TAG_NAME = 'PaymentCancelledPage';

export const PaymentCancelledPage = ({ token, hideLogin = false }: Props) => {
  const site = useSiteContext();
  const { paymentId, isPaymentLoading } = usePaymentDataFromToken({ token });
  const { errorData } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const location = useLocation<AnalyticPropsInState>();
  const { analyticsProps } = location.state ?? {};

  useEffect(() => {
    if (!analytics.extraPropertiesByTags[ANALYTICS_TAG_NAME]?.origin) {
      analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
        origin: 'payment-cancelled',
      });
    }

    if (analyticsProps?.category) {
      analytics.track(analyticsProps.category, 'payment-cancelled');
    }

    return () => analytics.removeExtraProperties(ANALYTICS_TAG_NAME);
  }, []);

  const onEmailClick = (email) => () => {
    analytics.trackAction('support-email');
    window.open(`mailto:${email}`, '_blank');
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout hideLogin={hideLogin}>
      <Flex direction="column">
        <BillInfoContainer>
          <Box mb={5}>
            <Icon name={IconNames.alertFill} color="red.500" size={IconSize.xl} />
          </Box>
          <Box textStyle="body2">
            <MIFormattedText
              label="vendors.editCheckAddress.checkCancelled.description"
              values={{
                companyName: errorData?.data?.companyName,
                companyEmail: (
                  <StyledEmailLink href={`mailto:${errorData?.data?.email}`}>{errorData?.data?.email}</StyledEmailLink>
                ),
                supportMail: (
                  <Box
                    as="a"
                    color="primary.500"
                    textDecoration="none"
                    href="#"
                    onClick={onEmailClick(site.config.support.email)}
                  >
                    {site.config.support.email}
                  </Box>
                ),
              }}
            />
          </Box>
        </BillInfoContainer>
      </Flex>
    </OutsideLayout>
  );
};

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const StyledEmailLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${(props) => props.theme.text.color.highlight};
  text-decoration: none;
`;
