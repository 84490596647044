import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useFirmClients } from 'src/hooks/accountants/useFirmClients';
import { useIsAccountingFirm } from 'src/hooks/accountants/useIsAccountingFirm';
import { FirmSelectedMethodCard } from './FirmSelectedMethodCard';
import { SmbSelectedMethodCard } from './SmbSelectedMethodCard';

export const BillingMethodCard = () => {
  const isAccountingFirm = useIsAccountingFirm();
  const firmClients = useFirmClients();

  return (
    <Flex
      direction="column"
      p={8}
      pb={10}
      border="1px"
      borderColor="grey.400"
      borderRadius="lg"
      data-testid="billing-method-card"
    >
      <Box textStyle="h2Semi" mb={6}>
        <MIFormattedText label="settings.billing.paymentMethod.title" />
      </Box>
      {isAccountingFirm ? (
        <>
          <Box textStyle="body2" mb={4} data-testid="billing-method-firm-sub-title">
            <MIFormattedText label="settings.billing.paymentMethod.subTitle" />
          </Box>
          <FirmSelectedMethodCard hasClients={firmClients.length > 0} />
        </>
      ) : (
        <SmbSelectedMethodCard />
      )}
    </Flex>
  );
};
