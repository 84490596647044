import * as dateFns from 'date-fns';
import * as React from 'react';
import { useCallback } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Checkbox } from 'src/core/ds/checkbox';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { InlineDatePickerField } from 'src/ui/form/InlineDatePickerField';
import { DateFormats } from 'src/utils/date-fns';
import dateFromModelValue from './modules/dateFromModelValue';
import { PaymentsCSVReportData, useCsvReport } from './modules/useCsvReport';

const MAX_DATE_DURATION = { years: 1 };

type Props = {
  dismiss: (event?: React.MouseEvent) => void;
};

const DownloadCsvReportModal = ({ dismiss }: Props) => {
  const hiddenCsvLinkRef = React.useRef<CSVLink>();
  const formModelData = React.useMemo(() => {
    const today = dateFns.set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    return {
      startDate: dateFns.subDays(today, 7).toISOString(),
      endDate: today.toISOString(),
      includeScheduledPayments: false,
    };
  }, []);
  const { csv, clearCsv, loading, downloadAndProcess } = useCsvReport();
  const [{ endDate, startDate, includeScheduledPayments }, { submit }] = useForm(formModelData, {
    submit: async (data: PaymentsCSVReportData) => {
      await downloadAndProcess(data);

      return true;
    },
    onChange: ({ key, modelState }) => {
      let newState = modelState;
      const newStart = new Date(modelState.startDate);
      const newEnd = new Date(modelState.endDate);

      if (dateFns.isSameDay(newStart, newEnd) || dateFns.isAfter(newStart, newEnd)) {
        newState = {
          ...newState,
          endDate: dateFns.addDays(newStart, 1).toISOString(),
        };
      } else if (dateFns.isBefore(dateFns.add(newStart, MAX_DATE_DURATION), newEnd)) {
        // more than max, change the parameter which was not changed
        if (key === 'endDate') {
          newState = {
            ...newState,
            startDate: dateFns.sub(newEnd, MAX_DATE_DURATION).toISOString(),
          };
        } else {
          newState = {
            ...newState,
            endDate: dateFns.add(newStart, MAX_DATE_DURATION).toISOString(),
          };
        }
      }

      return newState;
    },
  });

  const startDateVal = dateFromModelValue(startDate.value);
  const endDateVal = dateFromModelValue(endDate.value);

  const minEndDate = dateFns.addDays(startDateVal, 1);
  const filename = `payments_${dateFns.format(startDateVal, DateFormats.isoDate)}_${dateFns.format(
    endDateVal,
    DateFormats.isoDate
  )}.csv`;

  // reset the csv data on date change
  React.useEffect(() => {
    clearCsv();
  }, [startDate.value, endDate.value, clearCsv]);

  // download the file immediately after the fetch is done
  React.useEffect(() => {
    if (hiddenCsvLinkRef.current && csv) {
      // make sure react-csv has processed the data and ready
      setTimeout(() => {
        hiddenCsvLinkRef.current.link.click();
        dismiss();
      }, 0);
    }
  }, [csv, dismiss]);

  const onChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => includeScheduledPayments.onChange({ value: event.target.checked }),
    [includeScheduledPayments]
  );

  return (
    <StyledModalMessage
      isLoading={loading}
      titleComponent={
        <TitleWrapper>
          <MIFormattedText label="bills.csvReport.title" />
        </TitleWrapper>
      }
      descriptionComponent={
        <DescriptionWrapper>
          <Box mb={8}>
            <Checkbox
              isChecked={includeScheduledPayments.value ?? false}
              onChange={onChecked}
              label="bills.csvReport.scheduledPaymentsCheckbox"
            />
          </Box>
          <MIFormattedText label="bills.csvReport.description" />
        </DescriptionWrapper>
      }
      contentComponent={
        <FormContainer onSubmit={submit}>
          <StyledDateRow>
            <InlineDatePickerField
              id="DownloadCsvReportForm-start-date"
              label="bills.csvReport.form.startDate.label"
              required
              model={startDate}
              isClearable={false}
              disabled={loading}
            />
            <InlineDatePickerField
              id="DownloadCsvReportForm-end-date"
              label="bills.csvReport.form.endDate.label"
              required
              model={endDate}
              min={minEndDate}
              isClearable={false}
              disabled={loading}
            />
          </StyledDateRow>
          <button type="submit" hidden />
        </FormContainer>
      }
      onCloseClick={dismiss}
      buttonComponent={
        <>
          <HiddenCSVLink ref={hiddenCsvLinkRef} data={csv || []} target="_blank" filename={filename}>
            download
          </HiddenCSVLink>
          <Button
            label="bills.csvReport.form.submitText"
            type="submit"
            variant={ButtonVariants.primary}
            onClick={submit}
            isLoading={loading}
            isFullWidth
          />
        </>
      }
    />
  );
};

export default DownloadCsvReportModal;

const StyledModalMessage = styled(ModalMessage)`
  > div {
    overflow-y: visible;
  }
`;
const TitleWrapper = styled.div`
  text-align: left;
`;
const DescriptionWrapper = styled.div`
  text-align: left;
`;
const StyledDateRow = styled(FormRow)`
  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;

    > * {
      margin-right: 0;
      margin-left: 0;
    }

    > *:first-child {
      margin-bottom: 1rem;
    }
  }
`;
const HiddenCSVLink = styled(CSVLink)`
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
`;
