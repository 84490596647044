import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Checkbox } from 'src/core/ds/checkbox';
import type { MenuItemOptionProps, MenuItemProps } from 'src/core/ds/menu';
import { MenuItem } from 'src/core/ds/menu';
import { ValueLabelPair } from 'src/pages/pay/components/filters/types';

type MenuItemCheckboxProps = ValueLabelPair & Pick<MenuItemOptionProps, 'isChecked'> & MenuItemProps;

// see chakra's useMenuOptionGroup
type UseMenuOptionGroupOnClick = (event: unknown) => void;

export const MenuItemCheckbox = ({
  label,
  value,
  onClick,
  ...props
}: MenuItemCheckboxProps & { onClick?: UseMenuOptionGroupOnClick }) => (
  <MenuItem onClick={onClick}>
    <Checkbox isChecked={props.isChecked} value={value} onClick={onClick} style={{ pointerEvents: 'none' }} />
    <MIFormattedText label={label} />
  </MenuItem>
);
// this allows us to use it with useMenuOptionGroup, it bypasses a chakra check.
MenuItemCheckbox.id = 'MenuItemOption';
