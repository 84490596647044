import { createBatchUpdateSlice } from 'src/helpers/redux/restBatchUpdateSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { PaymentRequestType } from 'src/utils/types';
import { name } from '../consts';

export const proRequestsBatchMarkAsPaidSlice = createBatchUpdateSlice<PaymentRequestType>({
  storeName: name,
  api: getProApi.requests.batchMarkAsPaid,
  actionName: 'proBatchMarkAsPaid',
});
