import * as React from 'react';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { ActionOptionType } from './types';

type Props = {
  options: ActionOptionType[];
};

const ActionsMenu: React.FC<Props> = ({ options }) => {
  const onActionClick = (action) => {
    action && action();
  };

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        mr={3}
        variant={ButtonVariants.secondary}
        rightIcon={<ChevronDownIcon />}
        size={ButtonSizes.sm}
        label="getPro.table.actions.name"
      />
      <MenuList data-testid="action-menu-list">
        {options.map(({ value, option, action, label, color }) => (
          <MenuItem color={color} key={value} onClick={() => onActionClick(action)} as={Button} label={label}>
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default ActionsMenu;
