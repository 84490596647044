import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { MicroDepositsAmountFields } from 'src/components/micro-deposits/modals/components/MicroDepositsAmountFields';
import { MicroDepositsWasSentButtons } from 'src/components/micro-deposits/modals/components/MicroDepositsWasSentButtons';
import Box from 'src/core/ds/box';
import { MicroDepositActions, MicroDepositState } from 'src/pages/settings/hooks/microDepositsCommon';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  digits: string;
  onGoNext?: () => void;
  showContinueWithoutVerifyingButton: boolean;
  isRecentlySentMicroDeposits: boolean | null;
} & MicroDepositState &
  MicroDepositActions;

export const MicroDepositsWasSentDialog = ({
  amount1,
  amount2,
  onChange,
  validationErrors,
  serverError,
  onSubmit,
  onGoNext,
  dismiss,
  isLoading,
  digits,
  showContinueWithoutVerifyingButton,
  isRecentlySentMicroDeposits,
}: Props) => {
  const openSupportChat = () => {
    analytics.track('micro-deposits-were-sent', 'support-open-start-chat');
    intercomService.show();
  };

  const onGoNextWithEvent = () => {
    analytics.track('micro-deposits-were-sent', 'continue-without-verifying');
    onGoNext && onGoNext();
  };

  return (
    <ModalMessage
      id="micro-deposits-were-sent-dialog"
      alignLeft
      onCloseClick={dismiss}
      titleComponent={
        <>
          <Box textStyle="h2Semi">
            <MIFormattedText label="settings.microDeposits.depositsWasSentDialog.title" />
          </Box>
          {serverError && (
            <Box mb={4} mt={4}>
              <MINotificationCard
                subtitle={{
                  label: serverError,
                }}
                type={NotificationCardTypes.ERROR}
              />
            </Box>
          )}
          <Box textStyle="body2" mt={4}>
            <MIFormattedText
              label="settings.microDeposits.depositsWasSentDialog.subtitle"
              values={{
                digits,
              }}
            />
          </Box>
          {isRecentlySentMicroDeposits ? null : (
            <Box textStyle="body2" mt={4} data-testid="support-link">
              <MIFormattedText
                label="settings.microDeposits.depositsWasSentDialog.link"
                values={{
                  letUsKnow: (
                    <Box as="a" cursor="pointer" onClick={openSupportChat} textStyle="link1">
                      <MIFormattedText label="settings.microDeposits.depositsWasSentDialog.letUsKnow" />
                    </Box>
                  ),
                }}
              />
            </Box>
          )}
        </>
      }
      contentComponent={
        <MicroDepositsAmountFields
          amount1={amount1}
          amount2={amount2}
          onChange={onChange}
          validationErrors={validationErrors}
          onSubmit={onSubmit}
          serverError={serverError}
          dismiss={dismiss}
        />
      }
      buttonComponent={
        <MicroDepositsWasSentButtons
          onSubmit={onSubmit}
          showContinueWithoutVerifyingButton={showContinueWithoutVerifyingButton}
          onGoNext={onGoNextWithEvent}
        />
      }
      isLoading={isLoading}
    />
  );
};
