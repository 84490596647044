import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import Box from 'src/core/ds/box';
import { useSearchValue } from 'src/hoc/withListSearchBarContext';
import { getAmountColorByStatus } from '../utils';

export const AmountCell: React.FC<CellProps<any>> = ({ cell: { value } }) => {
  const color = getAmountColorByStatus(value.status);
  const search = useSearchValue();

  return useMemo(
    () => (
      <Box isTruncated textStyle="body4Semi" color={color} data-testid="getPro.cell.amountBox">
        <MIFormattedCurrency value={Number(value.value)} currency="$" search={search} privateData />
      </Box>
    ),
    [color, search, value.value]
  );
};
