import Box from 'src/core/ds/box';
import { PageFooter } from './PageFooter';
import { PageHeader } from './PageHeader';

export const PageWrapper = ({ children }) => (
  <Box minH="100%" bgColor="grey.200">
    <PageHeader />
    <Box
      w={{ base: 'auto', sm: 'auto', md: '53rem' }}
      bgColor="white"
      borderRadius={{ base: 'none', md: 'lg' }}
      boxShadow={{ base: 'none', md: 500 }}
      mx={{ base: 0, sm: 'auto' }}
      mb={{ base: 0 }}
      mt={{ base: 0, md: '-16rem' }}
      overflow="hidden"
    >
      {children}
    </Box>
    <PageFooter />
  </Box>
);
