import { Cell, Row } from 'react-table';
import Table from 'src/core/ds/table';
import { DATA_TABLE_RENDER } from 'src/pages/get-pro/components/table/consts';

export const TableRow = ({
  disableCheckBoxes,
  onClick,
  row,
  prepareRow,
}: {
  disableCheckBoxes?: boolean;
  onClick?: (column) => void;
  row: Row<any>;
  prepareRow: (row) => void;
}) => {
  const handleCellClick = (cell: Cell) => {
    onClick && cell.column.id !== 'select_all' && onClick?.(cell.row);
  };

  prepareRow(row);

  return (
    <Table.Row data-is-selected={row.isSelected} {...row.getRowProps()}>
      {row.cells.map((cell) => (
        <Table.Cell
          {...cell.getCellProps()}
          onClick={() => handleCellClick(cell)}
          w={cell.column.width}
          minW={cell.column.minWidth}
          isNumeric={cell.column.isNumeric}
          data-testid={`getPro.cell.${cell.column.id}`}
        >
          {cell.render(DATA_TABLE_RENDER.Cell, {
            disableCheckBoxes,
          })}
        </Table.Cell>
      ))}
    </Table.Row>
  );
};
