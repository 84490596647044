import { featureFlags } from '@melio/shared-web';
import { RecordOf } from 'immutable';
import get from 'lodash/get';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { SandboxIndicator } from 'src/components/common/SandboxIndicator';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { WizardFormRow } from 'src/components/layout/WizardElements';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useNewTabapayUrl } from 'src/hooks/useNewTabapayUrl';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks/usePayablesConnectedAccountingPlatform';
import { useRefreshFundingSources } from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { billingLocations } from 'src/pages/billing/locations';
import { useBasisTheoryAddCardAccount } from 'src/pages/onboarding/funding-sources/card/add-card/basis-theory/useBasisTheoryAddCardAccount';
import { useTabapayAddCardAccount } from 'src/pages/onboarding/funding-sources/card/add-card/tabapay/useTabapayAddCardAccount';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { analytics } from 'src/services/analytics';
import {
  AddFundingSourceWizardOrigin,
  CardNetworkTypes,
  CardTypes,
  DialogType,
  DialogVariants,
  FeatureFlags,
  ServerResponseCodes,
  SingleSelectFlavor,
  TIMEOUTS,
} from 'src/utils/consts';
import { isSandboxIndicator } from 'src/utils/funding-sources';
import locations from 'src/utils/locations';
import { AccountType, CardAccountType, PaymentRequestType } from 'src/utils/types';
import { US_STATES } from 'src/utils/us-states';
import CardFormInput from './components/CardFormInput';

export type AddCardAccountSuccessParams = {
  responseCode: any;
  cardAccount: CardAccountType;
  id?: number | null;
};

export type LocationState = {
  token: string;
  digits: string;
  cardBin: string;
  expiration: string;
  goExit: () => void;
  fundingSource?: RecordOf<AccountType>;
  preservedState?: Record<string, any>;
  origin: string;
  newFundingSourceId?: boolean;
  vendorId?: string;
  companyName?: string;
  paymentRequest?: PaymentRequestType;
};

type State = {
  id?: number | null;
  cardAccount?: Partial<CardAccountType> | null;
  firstName?: string | null;
  lastName?: string | null;
  address?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
};

const fieldsValidationFlag = {
  firstName: '',
  lastName: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
};

const eventPage = 'add-card';

const SetCardAccountsHolderPageContainer = () => {
  const site = useSiteContext();
  const dispatch = useDispatch();
  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const { refreshFundingSources } = useRefreshFundingSources();
  const locationState = useLocation<LocationState>().state;
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [formState, setFormState] = useState<State>({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    cardAccount: null,
    id: null,
  });
  const [submitValidation, setSubmitValidation] = useState<Record<string, any>>(fieldsValidationFlag);
  const { navigateToExitWithPreservedState, navigateWithPreservedState, navigate } = usePreservedStateNavigator();
  const { clientIdType } = useNewTabapayUrl();
  const [isCardVaultingPullEnabled] = featureFlags.useFeature(FeatureFlags.CardVaultingPull, false);

  const onSuccess = ({ id, responseCode, cardAccount }: AddCardAccountSuccessParams) => {
    setFormState({
      id,
      cardAccount,
      firstName: null,
      lastName: null,
      address: null,
      city: null,
      state: null,
      zipCode: null,
    });
    setErrorCode(responseCode);
    setIsLoading(false);
    setSubmitValidation(fieldsValidationFlag);

    if (responseCode === ServerResponseCodes.OK) {
      setTimeout(() => goNext(cardAccount, id), TIMEOUTS.CLOSE_MODAL);
    }

    refreshFundingSources();
  };

  const onError = (error: any) => {
    setErrorCode(error);
    setIsLoading(false);
  };

  const { addCardAccountApiCall: addTabapayCardAccountApiCall } = useTabapayAddCardAccount({
    eventPage,
    onSuccess,
    onError,
  });
  const { addCardAccountApiCall: addBasisTheoryCardAccountApiCall } = useBasisTheoryAddCardAccount({
    eventPage,
    onSuccess,
    onError,
  });

  const onDone = () => {
    const { firstName, lastName, address, city, state, zipCode } = formState;
    const validationObj = ['firstName', 'lastName', 'address', 'city', 'state', 'zipCode'].reduce((obj, s) => {
      if (Object.prototype.hasOwnProperty.call(formState, s) && !formState[s]) obj[s] = true;

      return obj;
    }, {});

    if (Object.keys(validationObj).length) {
      setSubmitValidation({ ...fieldsValidationFlag, ...validationObj });
      analytics.track(eventPage, 'add-card-account-submit-validation-error', submitValidation);

      return false;
    }

    analytics.track(eventPage, 'add-card-account-submit');
    setIsLoading(true);

    return isCardVaultingPullEnabled
      ? addBasisTheoryCardAccountApiCall({
          firstName,
          lastName,
          address,
          city,
          state,
          zipCode,
        })
      : addTabapayCardAccountApiCall({
          firstName,
          lastName,
          address,
          city,
          state,
          zipCode,
          clientIdType,
        });
  };

  const onCloseErrorModal = () => {
    setErrorCode(null);
  };

  const getCardLabel = (type: string) =>
    type === CardTypes.CREDIT
      ? 'onboarding.fundingSources.card.successNoteCredit'
      : 'onboarding.fundingSources.card.successNoteDebit';

  const goPrev = () => {
    navigate(onboardingLocations.fundingSources.card.index);
  };

  const goExit = () => {
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const selectFundingSourceForPayBillFlow = (id: number) => {
    dispatch(selectFundingSourceAction(id));
  };

  const updateBatchPaymentsSettings = (params: {
    scheduledDate: null | Date;
    fundingSourceId: number | null;
    billIds: string[];
  }) => {
    const batchActions = getStoreActions(regularBatchPaymentsStore)(dispatch);

    return batchActions.settings.update(params);
  };

  const goNext = async (cardAccount = formState.cardAccount, id = formState.id) => {
    const origin = get(locationState, 'preservedState.origin', '');
    const isFromPayBillFlow = origin === AddFundingSourceWizardOrigin.PAY_BILL;
    const isFromGuestFlow = origin === AddFundingSourceWizardOrigin.GUEST_ONBOARDING;

    if (isFromPayBillFlow && id) {
      selectFundingSourceForPayBillFlow(id);
    }

    if (origin === AddFundingSourceWizardOrigin.BATCH_PAY_BILLS && id) {
      const selectedIds = get(locationState, 'preservedState.selectedIds', []);
      await updateBatchPaymentsSettings({
        scheduledDate: null,
        fundingSourceId: id,
        billIds: selectedIds,
      });
    }

    if (isConnectedAccountingPlatform) {
      navigate(onboardingLocations.fundingSources.bank.account, false, {
        ...locationState,
        fundingSourceId: id,
        origin,
      });
    } else if (origin === AddFundingSourceWizardOrigin.BILLING) {
      navigate(billingLocations.add, false, { newFundingSourceId: id });
    } else if (navigateWithPreservedState) {
      if (cardAccount?.network === CardNetworkTypes.AMEX && (isFromPayBillFlow || isFromGuestFlow)) {
        goExit();
      } else {
        const state = origin === AddFundingSourceWizardOrigin.GUEST_ONBOARDING ? { selectedFundingSourceId: id } : {};
        navigateWithPreservedState(state);
      }
    }
  };

  const returnVal = ({ value, id }: { value: string; id: string }) => {
    if (!id) return;

    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }));

    setSubmitValidation((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const origin = get(locationState, 'preservedState.origin', '');
  const { cardAccount } = formState;

  const currentGoExit = origin === AddFundingSourceWizardOrigin.GUEST_ONBOARDING ? undefined : goExit;
  const successSubtitle = cardAccount
    ? `${cardAccount.network}
     (${cardAccount.card4digits})\n${cardAccount.firstName} ${cardAccount.lastName}`
    : '';
  const successTitle = cardAccount?.cardType ? getCardLabel(cardAccount.cardType.toLowerCase()) : '';
  const isSandboxIndicatorShown = isSandboxIndicator(site);

  return (
    <>
      <StepLayoutPage
        title="onboarding.fundingSources.card.title"
        onPrev={goPrev}
        goExit={currentGoExit}
        onSubmit={onDone}
        nextLabel="onboarding.fundingSources.card.save"
        isLoading={isLoading}
        hideHeader
        isSandboxIndicatorShown={isSandboxIndicatorShown}
      >
        {errorCode === ServerResponseCodes.OK && (
          <Dialog
            type={DialogType.ALERT}
            variant={DialogVariants.SUCCESS}
            title={successTitle}
            subtitle={successSubtitle}
            onCancelAction={goNext}
          />
        )}
        {errorCode && errorCode !== ServerResponseCodes.OK && (
          <Dialog
            type={DialogType.ALERT}
            variant={DialogVariants.ERROR}
            title="onboarding.fundingSources.card.notAddedTitle"
            subtitle={`server.${errorCode}`}
            onCancelAction={onCloseErrorModal}
            cancelButtonText="onboarding.fundingSources.card.errorButton"
          />
        )}
        <>
          <WizardFormRow>
            <CardFormInput
              value={formState.firstName}
              label="onboarding.fundingSources.card.firstName.label"
              required
              id="firstName"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.firstName.errorMessage"
              validationTest="string"
              returnVal={returnVal}
              submitValidation={submitValidation.firstName}
              autoFocus
            />
            <CardFormInput
              value={formState.lastName}
              label="onboarding.fundingSources.card.lastName.label"
              required
              id="lastName"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.lastName.errorMessage"
              validationTest="string"
              returnVal={returnVal}
              submitValidation={submitValidation.lastName}
            />
          </WizardFormRow>
          <CardFormInput
            value={formState.address}
            label="onboarding.fundingSources.card.address.label"
            id="address"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.address.errorMessage"
            validationTest="string"
            wizardColumn={false}
            returnVal={returnVal}
            required
            submitValidation={submitValidation.address}
          />
          <WizardFormRow>
            <CardFormInput
              value={formState.city}
              label="onboarding.fundingSources.card.city.label"
              id="city"
              placeHolder=""
              errorMessage="onboarding.fundingSources.card.city.errorMessage"
              validationTest="string"
              required
              returnVal={returnVal}
              submitValidation={submitValidation.city}
            />
            <MISingleSelect
              id="state"
              value={formState.state}
              label="onboarding.fundingSources.card.state.label"
              options={US_STATES}
              onChange={returnVal}
              placeholder=""
              errorMessage={submitValidation.state ? 'onboarding.fundingSources.card.state.errorMessage' : ''}
              required
              flavor={SingleSelectFlavor.DEFAULT}
            />
          </WizardFormRow>
          <CardFormInput
            value={formState.zipCode}
            label="onboarding.fundingSources.card.zipcode.label"
            required
            id="zipCode"
            placeHolder=""
            errorMessage="onboarding.fundingSources.card.zipcode.errorMessage"
            validationTest="string"
            wizardColumn={false}
            returnVal={returnVal}
            submitValidation={submitValidation.zipCode}
          />
        </>
      </StepLayoutPage>
      {isSandboxIndicatorShown && !site.embeddedMode && <SandboxIndicator />}
    </>
  );
};

export { SetCardAccountsHolderPageContainer };
