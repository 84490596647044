import { forwardRef, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { CurrencySignLabel } from 'src/components/common/Amount/CurrencySignLabel';
import { formatTotalAmount, formatWithCommas, getInputValues } from 'src/components/common/Amount/utils';
import { useAmountInputHandlers } from 'src/components/common/hooks/useAmountInputHandlers';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { MaskInput, PrivateDataContainer } from 'src/core/ds/input';
import { FieldType } from 'src/utils/types';
import { MINotices } from '../MINotices';

type Props = {
  totalAmount?: string;
  validationErrors?: Record<string, any>;
  onChange?: (data: FieldType) => void;
  onBlur?: (value: string) => void;
  toggleFocus?: () => void;
  viewOnly?: boolean;
  autoFocus?: boolean;
  focus?: boolean;
  maxValue?: number;
};

const Input = forwardRef<{ focus: () => void }, Props>(
  (
    { totalAmount, validationErrors = {}, onChange, onBlur, toggleFocus, viewOnly, autoFocus = true, focus, maxValue },
    ref
  ) => {
    const hiddenAmountRef = useRef<HTMLSpanElement>(null);
    const amountContainerRef = useRef<HTMLDivElement>(null);
    const amountInputRef = useRef<HTMLInputElement>(null);
    const viewOnlyAmountRef = useRef(formatWithCommas(formatTotalAmount(totalAmount)));

    const [totalAmountValue, setTotalAmountValue] = useState(formatTotalAmount(totalAmount));
    const inputValues = useMemo(() => getInputValues(maxValue), [maxValue]);

    const onBlurHandler = ({ value }: FieldType) => {
      onBlur?.(formatTotalAmount(value));
      focus && toggleFocus?.();
    };

    const { handleChange, handleBlur, handleKeyPressed } = useAmountInputHandlers({
      totalAmount: totalAmountValue,
      setTotalAmount: setTotalAmountValue,
      onChange,
      onBlur: onBlurHandler,
      maxValue,
    });

    // Considering the fact that amount input should be centered horizontally,
    // to show $ sign right before the input we need to change the width of the input dynamically,
    // based on inputted data or placeholder. For this purpose was introduced hidden span element,
    // which has the same font and text as input and is used to get the inputted text width
    const recalculateInputWidth = useCallback(
      (value?: string) => {
        const valueToRecalculateFrom = !value && maxValue ? `${maxValue}` : value;
        const inputElement = amountInputRef?.current;

        if (inputElement && amountContainerRef.current && hiddenAmountRef.current) {
          hiddenAmountRef.current.innerHTML =
            formatWithCommas(formatTotalAmount(valueToRecalculateFrom)) ||
            (inputElement.getAttribute('placeholder') as string);
          const hiddenElementWidth = hiddenAmountRef.current.offsetWidth;
          inputElement.style.width = valueToRecalculateFrom ? `${hiddenElementWidth}px` : '90px';
        }
      },
      [maxValue]
    );

    useImperativeHandle(
      ref,
      () => ({
        focus() {
          amountInputRef?.current?.focus();
        },
      }),
      []
    );

    useEffect(() => {
      recalculateInputWidth(totalAmount);
    }, []);

    useEffect(() => {
      if (totalAmount !== undefined) {
        setTotalAmountValue(formatTotalAmount(totalAmount));
        recalculateInputWidth(totalAmount);
      }
    }, [totalAmount, setTotalAmountValue, recalculateInputWidth]);

    useEffect(() => {
      if (focus) {
        amountInputRef?.current?.focus();
      }
    }, [focus]);

    const handleFocus = () => {
      !focus && toggleFocus?.();
    };

    return (
      <PrivateDataContainer>
        <Flex flexDirection="column" align="center" ref={amountContainerRef}>
          <AmountInputContainer>
            <CurrencySignLabel placeholder={!totalAmount && !!maxValue} />
            <HiddenAmountElement ref={hiddenAmountRef} />
            {viewOnly ? (
              <AmountViewOnly data-testid="request-total-amount">{viewOnlyAmountRef.current}</AmountViewOnly>
            ) : (
              <MaskInput
                id={inputValues.id}
                value={totalAmountValue}
                placeholder={inputValues.placeholder}
                placeholderValues={inputValues.placeholderValues}
                getInputRef={amountInputRef}
                onKeyDown={handleKeyPressed}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                autoComplete="off"
                autoFocus={autoFocus}
                thousandSeparator
                inputMode="decimal"
              />
            )}
          </AmountInputContainer>
          {validationErrors && (
            <AmountInputNotices data-testid="error-message">
              <MINotices errorMessage={validationErrors.totalAmount} />
            </AmountInputNotices>
          )}
        </Flex>
      </PrivateDataContainer>
    );
  }
);

type AmountInputContainerProps = {
  children?: React.ReactNode;
};

const AmountInputContainer = ({ children }: AmountInputContainerProps) => (
  <Flex id="amount-input-container" sx={AmountInputContainerStyle}>
    {children}
  </Flex>
);

const amountStyle: CSSObject = {
  textStyle: 'display2',
  textAlign: 'left',
  border: 'none',
  h: '6.5rem',
  display: 'flex',
  alignItems: 'center',
  maxW: '40rem',
};

const AmountInputContainerStyle: CSSObject = {
  justifyContent: 'center',
  '#totalAmount': {
    ...amountStyle,
    _placeholder: {
      textStyle: 'display2',
      color: 'black',
    },
  },
  '#boundedTotalAmount': {
    ...amountStyle,
    _placeholder: {
      textStyle: 'display2',
    },
  },
};

const HiddenAmountElement = forwardRef(({ children }, ref) => (
  <Box as="span" textStyle="display2" visibility="hidden" pos="fixed" ref={ref as RefObject<HTMLDivElement>}>
    {children}
  </Box>
));

type AmountViewOnlyProps = {
  children?: React.ReactNode;
  'data-testid'?: string;
};

const AmountViewOnly = ({ children, ...rest }: AmountViewOnlyProps) => (
  <Box
    as="span"
    color="black"
    textStyle="display2"
    border="none"
    maxW="40rem"
    h="6.5rem"
    display="flex"
    alignItems="center"
    {...rest}
  >
    {children}
  </Box>
);

type AmountInputNoticesProps = {
  children?: React.ReactNode;
  'data-testid'?: string;
};

const AmountInputNotices = ({ children, ...rest }: AmountInputNoticesProps) => (
  <Box as="span" mx={0} mt={-2} mb={2} {...rest}>
    {children}
  </Box>
);

export { Input };
