import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { IconNames } from 'src/core/ds/icon';
import { Modal, ModalHeader } from 'src/core/ds/modal';

type Props = {
  isOpen: boolean;
  iframeErrorCode: string;
  errorCode: string;
  onClose(): void;
};

export const CardDetailsViewErrorModal = ({ isOpen, iframeErrorCode, errorCode, onClose }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    size="sm"
    closeOnOverlayClick={false}
    closeOnEsc={false}
    preserveScrollBarGap
  >
    <ModalHeader>
      <Modal.Icon name={IconNames.cancelFill} color="red.500" />
      <Modal.HeaderTitle>
        <MIFormattedText
          label={
            errorCode === iframeErrorCode
              ? 'vendors.deliveryMethods.shiftToDebit.errorTitle'
              : 'onboarding.fundingSources.card.notSupportedTitle'
          }
        />
      </Modal.HeaderTitle>
    </ModalHeader>
    <Modal.CloseButton />
    <Modal.Body>
      <Box textStyle="body2">
        <MIFormattedText
          label={
            errorCode === iframeErrorCode
              ? 'vendors.deliveryMethods.shiftToDebit.errorCard'
              : 'vendors.deliveryMethods.shiftToDebit.notSupportedSubtitle'
          }
        />
      </Box>
    </Modal.Body>
    <Modal.Footer>
      <Flex w="full" justifyContent="center">
        <Button
          onClick={onClose}
          size={ButtonSizes.md}
          variant={ButtonVariants.tertiaryNaked}
          label="dialogs.buttons.close"
        />
      </Flex>
    </Modal.Footer>
  </Modal>
);
