import every from 'lodash/every';
import reverse from 'lodash/fp/reverse';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import reduce from 'lodash/reduce';
import memoize from 'memoize-one';
import { generatePath } from 'react-router-dom';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import customersStore from 'src/modules/customers/customers-store';
import { vendorsApi } from 'src/modules/vendors/api';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { contactsLocations } from 'src/pages/contacts/locations';
import { TransactionSummary } from 'src/pages/contacts/types';
import {
  BillStatus,
  ContactsTab,
  NON_EDITABLE_DELIVERY_METHOD_TYPES,
  PaymentRequestStatus,
  PaymentRequestTabs,
  PaymentStatus,
  RegistrationFlow,
} from 'src/utils/consts';
import { getPaymentRequestsSearchPath } from 'src/utils/payment-requests';
import { getLatestPayment } from 'src/utils/payments';
import { encodeQuery } from 'src/utils/query-utils';
import { getDefaultTabSetting } from 'src/utils/tabs-utils';
import { DeliveryMethodType, PaymentRequestType, TabSettingsType } from 'src/utils/types';

const TABS = [ContactsTab.VENDORS, ContactsTab.CUSTOMERS];

export const getContactType = (type) => (TABS.includes(type) ? type : ContactsTab.VENDORS);

const STORE_MAP = {
  [ContactsTab.CUSTOMERS]: customersStore,
  [ContactsTab.VENDORS]: vendorsStore,
};

export const getContactSelector = (type: ContactsTab) => STORE_MAP[getContactType(type)].selectors;

export const getContactActions = (type: ContactsTab) => getStoreActions(STORE_MAP[getContactType(type)]);

export const getContactsTabs = (registrationFlow: RegistrationFlow, isMSNDashboardDisplay?: boolean) => {
  if (isMSNDashboardDisplay) return [ContactsTab.VENDORS];

  return registrationFlow === RegistrationFlow.VENDOR ? reverse(TABS) : TABS;
};

export const filterContacts = memoize((filter, contacts) => {
  if (isEmpty(filter)) {
    return contacts;
  }

  const filterLower = filter.toLowerCase();

  return contacts.filter(
    (contact) =>
      includes(contact?.companyName?.toLowerCase(), filterLower) ||
      includes(contact?.contactName?.toLowerCase(), filterLower) ||
      includes(contact?.contactEmail?.toLowerCase(), filterLower) ||
      includes(contact?.contactPhone?.toLowerCase(), filterLower)
  );
});

export const calculateSummaryByStatus = memoize(
  (
    getStatus,
    getUrl: (status) => any,
    statuses: { [key: string]: string } | string[],
    items: { totalAmount: number }[]
  ): TransactionSummary[] => {
    const grouped = groupBy(items, getStatus);
    const midSum = mapValues(grouped, (status) =>
      reduce(
        status,
        (acc, { totalAmount }) => ({
          count: acc.count + 1,
          total: acc.total + totalAmount,
        }),
        { count: 0, total: 0 }
      )
    );

    return reduce(
      statuses as string[],
      (sum, status) =>
        sum.concat([
          {
            name: status,
            url: getUrl(status),
            count: midSum[status]?.count || 0,
            total: midSum[status]?.total || 0,
          },
        ]),
      [] as TransactionSummary[]
    );
  }
);

export const isBatchUnilateralEligilibleVendor = (
  isEditable: boolean,
  deliveryMethods: DeliveryMethodType[]
): boolean =>
  isEditable &&
  every(deliveryMethods, (dm: DeliveryMethodType) => !NON_EDITABLE_DELIVERY_METHOD_TYPES.includes(dm.deliveryType));

export const getVendorTabs = (
  typesList: ContactsTab[],
  selectedTabVendorType: ContactsTab,
  baseSearch = '',
  params: Record<string, string>
): TabSettingsType[] =>
  typesList.map((type) => {
    const defaultTabSetting = getDefaultTabSetting({
      tab: type,
      type: 'contacts',
      isActive: type === selectedTabVendorType,
      baseQueryParams: baseSearch,
    });
    const vendorsListSearchPath = encodeURI(baseSearch || '');

    return {
      ...defaultTabSetting,
      to: {
        search: vendorsListSearchPath,
        pathname: generatePath(contactsLocations.index, { ...params, type }),
      },
    };
  });

export const batchSendVendorDetailsRequest = (orgId: number, vendorIds: readonly number[]) => {
  const params = {
    vendorIds: vendorIds?.join(),
  };
  vendorsApi.batchSendFillDeliveryMethodEmail(orgId, params);
};

export const getCustomerPaymentRequestSearchTab = (tab: PaymentRequestTabs, contactId: string) =>
  encodeQuery(
    {
      contactId,
    },
    [],
    getPaymentRequestsSearchPath(tab)
  );

export const getPaymentRequestTab = (paymentRequest: PaymentRequestType) => {
  const latestPayment = getLatestPayment(paymentRequest.bill?.payments || []);
  const failedPayment = latestPayment?.status === PaymentStatus.FAILED;

  // Payment requests with failed payments shouldn't be displayed in tabs summaries
  if (failedPayment) {
    return null;
  }

  if (paymentRequest.status === PaymentRequestStatus.CREATED) {
    return PaymentRequestTabs.UNSENT;
  }

  if (paymentRequest.status === PaymentRequestStatus.PENDING) {
    return PaymentRequestTabs.SENT;
  }

  if (paymentRequest?.bill?.status === BillStatus.PAID) {
    return PaymentRequestTabs.PAID;
  }

  return PaymentRequestTabs.SCHEDULED;
};
