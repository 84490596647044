import * as React from 'react';
import { UserRoleTag } from 'src/components/user/UserRoleTag';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { getFullName } from 'src/utils/user';
import { PaymentCreatorOptionType } from '../../ApprovalWorkflowModal/types';

type Props = {
  creator: PaymentCreatorOptionType;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export function PaymentCreatorRow({ creator, onChange }: Props) {
  const fullName = getFullName(creator.firstName, creator.lastName);

  return (
    <Flex alignItems="center" py={6} px={4}>
      <Box data-testid={`paymentCreatorsDropdown-${creator.id}-checkbox`}>
        <Checkbox size="md" isChecked={creator.isChecked} onChange={onChange} />
      </Box>
      <Box
        pl={4}
        pr={2}
        key={creator.id}
        textStyle="body4Semi"
        color="black"
        data-testid="paymentCreatorsDropdown-creatorName"
      >
        {fullName}
      </Box>
      <UserRoleTag userRole={creator.role} />
    </Flex>
  );
}
