import { Image } from 'src/core/ds/image';
import syncXero from 'src/images/accounting-software/cards-logo/xero_logo.svg';
import syncFreshbooks from 'src/images/accounting-software/freshbooks-logo.svg';
import syncMsDynamics from 'src/images/accounting-software/microsoft-dynamics-logo.svg';
import syncQuickbooks from 'src/images/accounting-software/quickbooks-logo.svg';
import { AccountingSoftware } from 'src/utils/consts';
import { AccountingPlatform } from 'src/utils/types';

type Props = {
  accountingPlatform: AccountingPlatform;
};

const imageAltMapping = {
  [AccountingSoftware.QUICKBOOKS]: 'qboLogo',
  [AccountingSoftware.FRESHBOOKS]: 'freshbooksLogo',
  [AccountingSoftware.MICROSOFT_DYNAMICS_365]: 'microsoftDynamicsLogo',
  [AccountingSoftware.XERO]: 'xeroLogo',
};

const imageSrcMapping = {
  [AccountingSoftware.QUICKBOOKS]: syncQuickbooks,
  [AccountingSoftware.FRESHBOOKS]: syncFreshbooks,
  [AccountingSoftware.MICROSOFT_DYNAMICS_365]: syncMsDynamics,
  [AccountingSoftware.XERO]: syncXero,
};

export const AccountingPlatformLogo = ({ accountingPlatform }: Props) => {
  const dataTestId = `${accountingPlatform.name}-logo`;
  const imageAlt = imageAltMapping[accountingPlatform.name];
  const imageSrc = imageSrcMapping[accountingPlatform.name];

  return (
    <Image
      position="absolute"
      width="1.8rem"
      height="1.8rem"
      border="0.2rem"
      borderColor="white"
      borderRadius="full"
      borderStyle="solid"
      top="60%"
      right="5%"
      data-testid={dataTestId}
      src={imageSrc}
      alt={imageAlt}
    />
  );
};
