import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { AccountType, BillType } from 'src/utils/types';
import { FundingSourceDescription } from './FundingSourceDescription';

type Props = {
  fundingSource: AccountType;
  bill: BillType;
  failureDate?: string | Date;
};

export const RepaymentFundingSourceInfo = ({ fundingSource, bill, failureDate }: Props) => (
  <PaymentActivityContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <PaymentIcon>
          <Icon name={IconNames.bank} size={IconSize.lg} />
        </PaymentIcon>
        <PaymentTextContainer>
          <PaymentMethodContainer>
            <PaymentMethodText>
              <MIFormattedText label="payBillPaymentActivity.fundingSource.financingRepaymentFundingSourceLabel" />
            </PaymentMethodText>
          </PaymentMethodContainer>
          <PaymentMethodContainer>
            <PaymentMethodText>
              <Box textStyle="body2" color="black" data-testid="repayment-funding-source-account-number">
                <FundingSourceDescription bill={bill} fundingSource={fundingSource} />
              </Box>
            </PaymentMethodText>
          </PaymentMethodContainer>
          {failureDate && (
            <PaymentMethodContainer>
              <PaymentMethodText>
                <Box textStyle="body4" color="red.500" data-testid="repayment-funding-source-failure-date">
                  <MIFormattedText
                    label="viewBillPaymentActivity.status.failed"
                    values={{
                      date: <MIFormattedDate date={failureDate} />,
                    }}
                  />
                </Box>
              </PaymentMethodText>
            </PaymentMethodContainer>
          )}
        </PaymentTextContainer>
      </PaymentInfoContainer>
    </PaymentActivityMainContainer>
  </PaymentActivityContainer>
);
