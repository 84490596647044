import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Separator } from 'src/core/ds/separator';

type Props = {
  color?: 'grey.300' | 'grey.400' | 'grey.500';
  size?: 'sm' | 'md';
};

export const OrSeparator = ({ ...rest }: Props) => (
  <Flex alignItems="center">
    <Separator {...rest} />
    <Box ms={3} me={3} textStyle="body3" color="grey.700">
      <MIFormattedText label="general.or" />
    </Box>
    <Separator {...rest} />
  </Flex>
);
