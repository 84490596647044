import { isBefore, parseISO, startOfDay } from 'date-fns';
import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { TableDate } from 'src/components/shared/tables/PaymentsTable/components/TableDate';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import { HStack } from 'src/core/ds/stack';

export const DueDateCell: React.FC<CellProps<any>> = ({ cell: { value } }) => {
  const isPassedDue = isBefore(parseISO(value), parseISO(startOfDay(new Date()).toISOString()));

  return useMemo(
    () => (
      <HStack spacing={2}>
        <TableDate date={value} />
        {isPassedDue && <Badge color={BadgeColors.WARNING} label="getPro.table.columns.pastDue" />}
      </HStack>
    ),
    [isPassedDue, value]
  );
};
