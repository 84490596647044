import { analytics } from 'src/services/analytics';
import { capture } from 'src/utils/error-tracking';

const localStorageKey = 'loginFlowType';

export enum LoginFlowType {
  EmailPassword = '1',
  Intuit = '2',
  Google = '3',
}

type ReturnValue = {
  setLoginFlowType: (type: LoginFlowType) => void;
  isEmailPasswordLoginFlow: boolean;
  clearLoginFlowType: () => void;
};

export const useLoginFlowType = (): ReturnValue => {
  let loginFlowType;

  try {
    loginFlowType = localStorage.getItem(localStorageKey);
  } catch (e) {
    analytics.track('useLoginFlowType', 'error-getting-loginFlowType', e as Error);
    capture(e as Error, {
      message: `Can not get useLoginFlowType from localStorage`,
    });
  }

  const setLoginFlowType = async (type: LoginFlowType) => {
    try {
      localStorage.setItem(localStorageKey, type);
    } catch (e) {
      analytics.track('useLoginFlowType', 'error-setting-flow-type', e as Error);
      capture(e as Error, {
        message: `Can not set useLoginFlowType = ${type} to localStorage`,
      });
    }
  };

  const clearLoginFlowType = async () => {
    try {
      localStorage.removeItem(localStorageKey);
    } catch (e) {
      analytics.track('useLoginFlowType', 'error-clearing-flow-type', e as Error);
      capture(e as Error, {
        message: `Can not clear loginFlowType key from localStorage`,
      });
    }
  };

  return {
    setLoginFlowType,
    clearLoginFlowType,
    isEmailPasswordLoginFlow: loginFlowType === LoginFlowType.EmailPassword,
  };
};
