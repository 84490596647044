import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { MIFormattedText } from './MIFormattedText';

export const ContactSupportLink = () => {
  const site = useSiteContext();

  return (
    <Link borderBottom="1px" color="white" href={`mailto:${site.config.support.email}`}>
      <MIFormattedText label="onboarding.msnCompanyInfo.generalError.contactSupport" />
    </Link>
  );
};
