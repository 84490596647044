import { MenuItem } from 'src/core/ds/menu';
import { DeliveryMethodType } from 'src/utils/types';
import { DeliveryMethodLabel } from './DeliveryMethodLabel';

type Props = {
  deliveryMethod: DeliveryMethodType;
  onClick?: (method: DeliveryMethodType) => void;
  isSelected?: boolean;
};

const DeliveryMethodMenuItem = ({ deliveryMethod, onClick, isSelected }: Props) => {
  const handleClick = () => {
    if (onClick) {
      onClick(deliveryMethod);
    }
  };

  return (
    <MenuItem
      testId={`delivery-method-menu-item-${deliveryMethod.id}`}
      onClick={handleClick}
      bg={isSelected ? 'grey.300' : 'white'}
    >
      <DeliveryMethodLabel deliveryMethod={deliveryMethod} isExtended />
    </MenuItem>
  );
};

export default DeliveryMethodMenuItem;
