import * as React from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import MIFileUpload from 'src/components/common/MIFileUpload';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import UploadAttachment from 'src/components/common/UploadAttachment';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import addFile from 'src/images/general/add-file.svg';
import { BillPageType } from 'src/utils/consts';
import { FileNameStyle, FileUploadErrorStyle, UploadAreaStyle } from './styles';

type Props = {
  onSelectFile: (file: File) => void;
  onRetry: () => void;
  fileName: string;
  isFileUploading: boolean;
  isFileUploadError: boolean;
  onChangeInvoiceFile: (file: File) => void;
  onDeleteInvoiceFile: () => void;
  pageType?: BillPageType;
  fullWidth?: boolean;
  testId?: string;
};

const Upload10KInvoiceFileLayout = ({
  onSelectFile,
  fileName,
  isFileUploadError,
  isFileUploading,
  onDeleteInvoiceFile,
  onChangeInvoiceFile,
  onRetry,
  pageType,
  fullWidth = false,
  testId,
}: Props) => {
  const decideUploadAreaStyle = (isFileUploadError: boolean, isPageTypeCreate: boolean, fullWidth: boolean) => ({
    ...UploadAreaStyle,
    borderColor: isFileUploadError ? 'red.500' : 'grey.600',
    m: isPageTypeCreate ? '2rem auto 4rem' : '2rem auto',
    '&:hover': {
      borderColor: !isFileUploadError && 'primary.500',
    },
    maxW: fullWidth ? 'none' : UploadAreaStyle.maxW,
  });

  const onChangeInvoiceFileClicked = ({ currentTarget: target }: React.SyntheticEvent<HTMLInputElement>) => {
    if (target.files && target.files.length > 0) {
      onChangeInvoiceFile(target.files[0]);
      target.value = '';
    }
  };

  const renderFileActionButtons = () => {
    if (isFileUploadError) {
      return (
        <Flex onClick={onRetry} __css={FileUploadErrorStyle}>
          <MIFormattedText label="bills.form.attachment.retry" />
        </Flex>
      );
    }

    return (
      <UploadAttachment
        uploadLabel="bills.form.attachment.replace"
        deleteLabel="bills.form.attachment.delete"
        onChangeAttachment={onChangeInvoiceFileClicked}
        onDeleteAttachment={onDeleteInvoiceFile}
        isShowDeleteButton
      />
    );
  };

  const renderUploadInvoiceFile = () => {
    const icon = <img src={addFile} alt="Upload file" />;

    if (isFileUploading) {
      return <AreaLoader />;
    }

    if (!fileName && !isFileUploadError) {
      return (
        <MIFileUpload
          onSelectFile={onSelectFile}
          eventPage="10k-invoice-file-upload"
          testId={testId}
          allowedTypes={['image/png', 'image/jpeg', 'application/pdf']}
        >
          <Flex flexDir="row" justify="flex-start" align="center" h="full">
            <Box __css={{ img: { my: 0, mx: 5, w: 6, h: 6 } }}>{icon}</Box>

            <Box as="span" m={0} color="grey.700" textStyle="body2">
              <MIFormattedText label="bills.pay.expedite.dragAndDrop" />
            </Box>
          </Flex>
        </MIFileUpload>
      );
    }

    return (
      <Flex flexDir="row" justify="space-between" py={3} px={{ base: 2, sm: 5 }}>
        <Flex color={isFileUploadError ? 'red' : 'primary.500'} __css={FileNameStyle}>
          {isFileUploadError && <MIFormattedText label="bills.form.attachment.error" />}
          {fileName}
        </Flex>
        <Box cursor="pointer" mr={isFileUploadError ? 0 : 4}>
          {renderFileActionButtons()}
        </Box>
      </Flex>
    );
  };

  return (
    <Box __css={decideUploadAreaStyle(isFileUploadError, pageType === BillPageType.CREATE, fullWidth)}>
      {renderUploadInvoiceFile()}
    </Box>
  );
};

export default Upload10KInvoiceFileLayout;
