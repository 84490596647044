import styled from 'styled-components';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  onPrev?: () => void;
  onNext: () => void;
  onChange: (address: GoogleCombinedAddressType) => void;
  goToManualAddress: () => void;
  address?: GoogleCombinedAddressType | null;
  isLoading?: boolean;
  validationErrors?: Record<string, any>;
  companyName: string;
  isAccountantAddCompanyFlow: boolean;
};

const CompanyAddressForm = ({
  address,
  onChange,
  onNext,
  onPrev,
  isAccountantAddCompanyFlow,
  isLoading = false,
  validationErrors = {},
  goToManualAddress,
  companyName,
}: Props) => {
  const organizationPreferences = useOrganizationPreferences();
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const getRelativeStep = () => {
    let relativeStep = 4 / 7;

    if (isAccountantAddCompanyFlow || organizationPreferences.uninvitedOwnerEmail) {
      relativeStep = 2 / 6;
    }

    if (isUnifiedOnboardingOpen) {
      relativeStep = 4 / 13;
    }

    return relativeStep;
  };

  const getAddressLabel = () => {
    let addressLabel = 'onboarding.companyInfo.address.addressInputLabel';

    if (isAccountantAddCompanyFlow || organizationPreferences.uninvitedOwnerEmail) {
      addressLabel = 'inviteNewCompany.accountantDetails.addressLabel';
    }

    return addressLabel;
  };

  return (
    <StepLayoutPage
      title="onboarding.companyInfo.address.title"
      titleValues={{ companyName: <Title>{companyName}</Title> }}
      relativeStep={getRelativeStep()}
      onPrev={onPrev}
      onNext={onNext}
      isLoading={isLoading}
    >
      <MIAddressAutocomplete
        id="address"
        addressLabel={getAddressLabel()}
        aptNumberLabel="onboarding.companyInfo.address.aptNumberLabel"
        address={address}
        onChange={onChange}
        goToManualAddress={goToManualAddress}
        required
        autoFocus
        errorMessage={validationErrors.googlePlaceId || validationErrors.zipCode || validationErrors.addressLine1}
        showAddManualAddress
        privateData
      />
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default CompanyAddressForm;
