import { useSelector } from 'react-redux';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useVendorCompanyInfoUpdates } from 'src/pages/onboarding/vendor-company-info/hooks/useVendorCompanyInfoUpdates';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CompanyContactDetailsForm } from '../components/CompanyContactDetailsForm';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];
const eventPage = 'msn-onboarding-vendor-company-info';

export const CompanyContactDetailsPage = ({ companyInfoField, nextStepURL, prevStepURL, inputFields }: Props) => {
  const { navigate } = useNavigator();
  const user = useSelector(profileStore.selectors.profile);

  const { onNext, isLoading, validationErrors } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
    eventPage,
  });

  const companyInfo = useSelector(getCompanyInfo);

  return (
    <CompanyContactDetailsForm
      companyInfo={companyInfo}
      onNext={onNext}
      onPrev={
        user.isGuest
          ? undefined
          : () => {
              navigate(onboardingLocations.msnRegistration.existingOrganizations);
            }
      }
      isLoading={isLoading}
      validationErrors={validationErrors}
    />
  );
};
