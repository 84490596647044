import * as React from 'react';
import { OutsideFooter } from 'src/components/common/OutsideLayout/OutsideFooter';
import { OutsideHeader } from 'src/components/common/OutsideLayout/OutsideHeader';
import Box from 'src/core/ds/box';

type OutsideLayoutProps = {
  children: React.ReactNode;
  redirectUrl?: string;
  hideLogin?: boolean;
  analyticsProps?: Record<string, any>;
  isExperimentalView?: boolean;
};

export const OutsideLayout: React.FC<OutsideLayoutProps> = ({
  children,
  redirectUrl,
  hideLogin,
  analyticsProps,
  isExperimentalView = false,
}) => (
  <Box h="full" bgColor={{ base: 'grey.200', sm: 'unset' }}>
    <OutsideHeader redirectUrl={redirectUrl} hideLogin={hideLogin} analyticsProps={analyticsProps} />
    <Box __css={isExperimentalView ? OutsideCardStyleExperimentalView : OutsideCardStyle}>{children}</Box>
    <OutsideFooter />
  </Box>
);

const OutsideCardStyleExperimentalView = {
  bgColor: 'white',
  background: { base: 'linear-gradient(180deg, var(--chakra-colors-primary-500) 50%, #fff 50%)', md: '' },
  borderRadius: { base: 'none', md: 'lg' },
  w: { base: 'auto', sm: 'auto', md: '53rem' },
  boxShadow: { base: 'none', md: 500 },
  mx: { base: 0, sm: 'auto' },
  mb: { base: '2rem', md: 0 },
  mt: { base: '0rem', md: '-9rem' },
  overflow: 'hidden',
};

const OutsideCardStyle = {
  bgColor: 'white',
  borderRadius: { base: 0, sm: 'lg' },
  w: { base: 'auto', sm: '53rem' },
  boxShadow: { base: 'none', sm: 600 },
  mx: { base: 0, sm: 'auto' },
  mb: { base: 0, sm: 'auto' },
  mt: { base: 0, sm: -3 },
};
