import { useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { MICardField } from 'src/components/common/MICard';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Link } from 'src/core/ds/link';
import { HStack } from 'src/core/ds/stack';
import { getOwnedVendorHandle } from 'src/redux/user/selectors';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { getVendorHandleURL } from 'src/utils/organizations';
import { removeProtocol } from 'src/utils/string-utils';

export const VendorCompanyAddedPageMSNContent = () => {
  const toastId = useRef<null | number>(null);

  const ownedVendorHandle = useSelector(getOwnedVendorHandle);

  const link = ownedVendorHandle ? getVendorHandleURL(ownedVendorHandle) : '';

  const handleCopyLink = () => {
    if (!checkIfToastIsDisplayedById(toastId.current)) {
      toastId.current = pushNotification({
        type: NotificationVariant.INFO,
        msg: 'requests.melioMeLinkCard.toastMessage',
      });
    }
  };

  return (
    <HStack justifyContent="space-between">
      <MICardField label="incomingPayments.sharePaymentLinkModal.description" height="small">
        <Link isExternal href={link}>
          {removeProtocol(link)}
        </Link>
      </MICardField>
      <CopyToClipboard onCopy={handleCopyLink} text={link}>
        <Button variant={ButtonVariants.tertiary} label="general.copyLink" size={ButtonSizes.sm} />
      </CopyToClipboard>
    </HStack>
  );
};
