import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { IconNames } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';

type Props = {
  isOpen: boolean;
  onClose(): void;
  bodyLabel: string;
};

export const AddCardFundingSourceErrorModal = ({ isOpen, onClose, bodyLabel }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    size="sm"
    closeOnOverlayClick={false}
    closeOnEsc={false}
    preserveScrollBarGap
  >
    <Modal.Header>
      <Modal.Icon name={IconNames.cancelFill} color="red.500" />
      <Modal.HeaderTitle>
        <MIFormattedText label="onboarding.fundingSources.card.notSupportedTitle" />
      </Modal.HeaderTitle>
    </Modal.Header>
    <Modal.CloseButton />
    <Modal.Body>
      <Box textStyle="body2">
        <MIFormattedText label={bodyLabel} />
      </Box>
    </Modal.Body>
    <Modal.Footer>
      <Flex w="full" justifyContent="center">
        <Button
          onClick={onClose}
          size={ButtonSizes.md}
          variant={ButtonVariants.tertiaryNaked}
          label="dialogs.buttons.close"
        />
      </Flex>
    </Modal.Footer>
  </Modal>
);
