import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { NotificationPreferenceType } from 'src/utils/types';
import { notificationPreferencesApi } from './api';
import { name } from './consts';

const validate = async (obj: NotificationPreferenceType) => {
  const errors: string[] = [];

  if (!obj.id || Number.isNaN(obj.id)) {
    errors.push('id');
  }

  if (typeof obj.enabled !== 'boolean') {
    errors.push('enabled');
  }

  return errors.length ? errors : null;
};

const listHashFunc = () => 'all';

type InitialStateAddition = {
  temp: { [key: string]: NotificationPreferenceType };
};

export const notificationPreferencesStore = createRestfulSlice<NotificationPreferenceType, InitialStateAddition>({
  initialState: { temp: {} },
  listHashFunc,
  api: {
    list: notificationPreferencesApi.list,
    update: notificationPreferencesApi.update,
  },
  name,
  validateFunc: validate,
  extraReducers: {
    [`[${name.toUpperCase()}] UPDATE_REQUEST`](state, action) {
      state.temp[action.payload.id] = { ...state.byId[action.payload.id] }; // clone to prevent side effects
      state.byId[action.payload.id].enabled = action.payload.enabled;
    },
    [`[${name.toUpperCase()}] UPDATE_SUCCESS`](state, action) {
      delete state.temp[action.payload.id];
    },
    [`[${name.toUpperCase()}] UPDATE_FAILURE`](state, action) {
      state.byId[action.payload.id] = state.temp[action.payload.id];
      delete state.temp[action.payload.id];
    },
  },
  selectors: {
    loading: (state) => {
      const hashKey = listHashFunc();

      return state[name].lists[hashKey]?.loading;
    },
    updating: (id) => (state) => state[name].update[id]?.loading || false,
    byType: (type) => (state) =>
      (state[name].lists[listHashFunc()]?.order || []).reduce((all, id) => {
        const pref = state[name].byId[id];

        if (pref.type === type) {
          all.push(pref);
        }

        return all;
      }, []),
    isListError: (state) => !!state[name].lists?.[listHashFunc()]?.error,
  },
});
