import Box from 'src/core/ds/box';

type Props = {
  children: React.ReactNode;
};

export const ContentWrapper = ({ children }: Props) => <Box __css={styles}>{children}</Box>;

const styles = {
  mx: { base: '1.6rem', md: 0 },
  mb: { base: '2rem', md: 0 },
  overflow: 'hidden',
  borderBottomRadius: 'lg',
  boxShadow: { base: 600, md: 'none' },
};
