import { EventMappingsTree } from 'src/services/analytics/types';

const PAGE_EVENT = 'self-serve-refund';
const REFUND_PAYMENT = 'refund-payment';

export const selfServeRefundEventMapping: EventMappingsTree = {
  '/orgs/*/bills/*/payment/*/refund/reason': {
    'page.view': [PAGE_EVENT, 'reason'],
    'button.click.failed': [REFUND_PAYMENT, 'no-reason-picked', { paymentId: 'paymentId', billId: 'billId' }],
    'button.click.success': [
      REFUND_PAYMENT,
      'reason-continue',
      { paymentId: 'paymentId', billId: 'billId', chosenReason: 'chosenReason' },
    ],
  },
  '/orgs/*/bills/*/payment/*/refund/review': {
    'page.view': [PAGE_EVENT, 'review'],
    'edit.reason': [REFUND_PAYMENT, 'review&confirm-edit-reason', { paymentId: 'paymentId', billId: 'billId' }],
    'button.click': [
      REFUND_PAYMENT,
      'refund-request-sent',
      { paymentId: 'paymentId', billIds: 'billIds', chosenReason: 'chosenReason' },
    ],
    'button.click.success': [
      REFUND_PAYMENT,
      'refund-request-success',
      { paymentId: 'paymentId', billIds: 'billIds', chosenReason: 'chosenReason' },
    ],
    'button.click.failure': [
      REFUND_PAYMENT,
      'refund-request-failed',
      { paymentId: 'paymentId', billIds: 'billIds', chosenReason: 'chosenReason' },
    ],
  },
  '/orgs/*/bills/*/payment/*/refund-success': {
    'page.view': [PAGE_EVENT, 'success'],
    'button.click': ['refund-payment-succeeded', 'track-refund', { paymentId: 'paymentId', billId: 'billId' }],
  },
};
