import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';

export const ClientOrgBillingFeeRow = ({
  id,
  name,
  onChange,
  isChecked,
}: {
  id: number;
  name: string;
  onChange: (id: number) => void;
  isChecked: boolean | undefined;
}) => {
  const handleClick = () => {
    onChange(id);
  };

  return (
    <Flex
      w="full"
      justifyContent="flex-start"
      alignItems="center"
      h={14}
      data-testid={`billing-client-${isChecked ? 'selected' : 'not-selected'}`}
    >
      <Checkbox
        data-testid={`billing-client-checkbox-${id}`}
        aria-label="selection-checkbox"
        spacing={0}
        isChecked={isChecked}
        onChange={handleClick}
        marginInline={4}
      />
      <Box textStyle="body3">
        <MIFormattedText label={name} />
      </Box>
    </Flex>
  );
};
