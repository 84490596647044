import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { IconNames } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';
import { NotificationVariant } from 'src/core/ds/toast';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { accountingFirmsApi } from 'src/modules/accounting-firms/api';
import { organizationBillingFeeApi } from 'src/modules/organization-billing-fee/api';
import { pushNotification } from 'src/services/notifications';

type Props = {
  description: string;
  dismiss: (canceled: boolean) => void;
  billingFeeId: number;
  isAccountingFirm?: boolean;
};

export const DeleteMethodModal = ({ description, dismiss, billingFeeId, isAccountingFirm = false }: Props) => {
  const currentOrgId = useOrgId();
  const { onApiCall: deleteOrgBillingFee, loading: orgLoading } = useApi({
    api: organizationBillingFeeApi.deleteBillingFee,
  });
  const { onApiCall: deleteFirmBillingFee, loading: firmLoading } = useApi({
    api: accountingFirmsApi.deleteBillingFee,
  });

  const handleClose = () => {
    dismiss(true);
  };

  const handleDeleteClick = async () => {
    const deleteParams = { orgId: currentOrgId, billingFeeId };
    isAccountingFirm ? await deleteFirmBillingFee(deleteParams) : await deleteOrgBillingFee(deleteParams);
    dismiss(false);
    pushNotification({
      type: NotificationVariant.info,
      msg: 'settings.billing.paymentMethod.removedToast',
    });
  };

  return (
    <Modal closeOnOverlayClick={false} isOpen onClose={handleClose} closeOnEsc>
      <Flex data-testid="remove-billing-method-modal" direction="column">
        <Modal.CloseButton />
        <Flex align="center" direction="column" marginBlockStart={4}>
          <Modal.Icon name={IconNames.alertFill} color="red.500" marginBlockEnd={4} />
          <Modal.HeaderTitle>
            <Box textStyle="h2Semi">
              <MIFormattedText label="settings.billing.paymentMethod.removeModal.title" />
            </Box>
          </Modal.HeaderTitle>
          <Box textStyle="body2" marginBlockStart={4} marginBlockEnd={10}>
            <MIFormattedText label={description} />
          </Box>
        </Flex>
        <Modal.Footer>
          <Flex w="full" justifyContent="flex-end">
            <Button
              testId="cancel-remove-method-button"
              onClick={handleClose}
              variant={ButtonVariants.tertiaryNaked}
              size={ButtonSizes.lg}
              label="general.cancel"
            />
            <Button
              testId="confirm-remove-method-button"
              onClick={handleDeleteClick}
              variant={ButtonVariants.error}
              size={ButtonSizes.lg}
              isLoading={orgLoading || firmLoading}
              label="settings.billing.paymentMethod.removeModal.confirm"
            />
          </Flex>
        </Modal.Footer>
      </Flex>
    </Modal>
  );
};
