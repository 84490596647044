import { allProperties } from 'src/pages/regular-batch-payments/analytics/event-mapping';
import { EventMappingsTreeChild } from 'src/services/analytics/types';

const PAGE_EVENT = 'regular-batch-payments-inbox-selection';

export const MAPPING: EventMappingsTreeChild = {
  'BatchSettings.button.schedule': [
    PAGE_EVENT,
    'schedule_batch_payments_click',
    {
      ...allProperties,
      optionalBillsIds: 'optionalBillsIds',
      optionalPartialBillIds: 'optionalPartialBillIds',
      optionalBillsCount: 'optionalBillsCount',
      optionalBillsAmount: 'optionalBillsAmount',
    },
  ],
  'regularBatchPayments.selection.selectAll': [PAGE_EVENT, 'batch_payments_select_all'],
  'regularBatchPayments.selection.clearSelected': [PAGE_EVENT, 'batch_payments_clear_all'],
  'regularBatchPayments.BillsGallery.Footer.submit': [PAGE_EVENT, 'schedule_batch_payments_click'],
};
