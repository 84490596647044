import { keyframes } from 'src/core/ds';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const spinAnimation = `${spin}  0.9s linear infinite `;

export const errorRedDotStyle = {
  width: '8px',
  height: '8px',
  bg: 'red.500',
  borderRadius: '50%',
  position: 'absolute',
  right: '0',
  top: '0',
  border: '2px solid white',
};

export const syncErrorPopoverStyle = {
  position: 'relative',
};
