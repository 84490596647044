import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getOrgId } from 'src/redux/user/selectors';

const useGetVendorPaymentPreferences = (vendorId: number | null) => {
  const orgId = useSelector(getOrgId);
  const { checkVendorPaymentPreferences } = useStoreActions(vendorsStore);
  const vendorPaymentPreferences = useSelector((state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.item(state, vendorId)
  );
  const isCheckingVendorEnableCCPayments = useSelector((state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.loading(state, vendorId)
  );

  useEffect(() => {
    if (isNil(vendorPaymentPreferences) && orgId && vendorId) {
      checkVendorPaymentPreferences({
        orgId,
        id: vendorId,
      });
    }
  }, [vendorId, vendorPaymentPreferences, checkVendorPaymentPreferences, orgId]);

  return {
    vendorPaymentPreferences,
    isCheckingVendorEnableCCPayments,
  };
};

export default useGetVendorPaymentPreferences;
