import compact from 'lodash/compact';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';
import reduce from 'lodash/reduce';
import stubFalse from 'lodash/stubFalse';
import stubTrue from 'lodash/stubTrue';
import { useSelector } from 'react-redux';
import organizationsStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { Role } from 'src/utils/consts';
import { CompanyTypeLiteral } from './types';

export type PermissionsCheck = (...args: Array<any>) => boolean;

export type BillPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  approve: PermissionsCheck;
  reschedule: PermissionsCheck;
  markAsPaid: PermissionsCheck;
};

export type RequestsPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  createMelioLink: PermissionsCheck;
};

export type FundingSourcesPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  verify: PermissionsCheck;
};

export type DeliveryMethodPermissions = {
  update: PermissionsCheck;
  delete: PermissionsCheck;
  verify: PermissionsCheck;
};

export type SettingsPermissions = {
  editCompanySettings: PermissionsCheck;
  editAccountingSoftware: PermissionsCheck;
};

export type UserManagement = {
  read: PermissionsCheck;
  update: PermissionsCheck;
  delete: PermissionsCheck;
  transferOwnership: PermissionsCheck;
  manageUserOrganizations: PermissionsCheck;
};

export type PermissionsType = {
  bills: BillPermissions;
  requests: RequestsPermissions;
  fundingSources: FundingSourcesPermissions;
  deliveryMethod: DeliveryMethodPermissions;
  settings: SettingsPermissions;
  userManagement: UserManagement;
};

export type PermissionsPerRole = {
  owner: PermissionsType;
  admin: PermissionsType;
  contributor: PermissionsType;
  accountant: PermissionsType;
};

const defaultPermissions: PermissionsType = {
  bills: {
    update: stubFalse,
    delete: stubFalse,
    approve: stubFalse,
    markAsPaid: stubFalse,
    reschedule: stubFalse,
  },
  requests: {
    update: stubFalse,
    delete: stubFalse,
    createMelioLink: stubFalse,
  },
  settings: {
    editCompanySettings: stubFalse,
    editAccountingSoftware: stubFalse,
  },
  fundingSources: {
    update: stubFalse,
    delete: stubFalse,
    verify: stubFalse,
  },
  deliveryMethod: {
    update: stubFalse,
    delete: stubFalse,
    verify: stubFalse,
  },
  userManagement: {
    read: stubFalse,
    update: stubFalse,
    delete: stubFalse,
    transferOwnership: stubFalse,
    manageUserOrganizations: stubFalse,
  },
};

const setValueRec = (obj, val) =>
  reduce(
    obj,
    (result, currVal, key) => {
      result[key] = isFunction(currVal) ? val : setValueRec(currVal, val);

      return result;
    },
    {}
  );

const createdByTheSameUserId = (user, object) =>
  isArray(object) ? object.every((obj) => obj.createdById === user.id) : object.createdById === user.id;

const allPermissions = setValueRec(defaultPermissions, stubTrue) as PermissionsType;
const contributor = merge(defaultPermissions, {
  bills: {
    update: createdByTheSameUserId,
    delete: createdByTheSameUserId,
    reschedule: stubTrue,
    retryPay: stubTrue,
    markAsPaid: createdByTheSameUserId,
  },
  requests: {
    update: stubTrue,
    delete: stubTrue,
  },
});

const canAccountantManageUser = (userToUpdateRole: Role) =>
  [Role.ACCOUNTANT, Role.CONTRIBUTOR].includes(userToUpdateRole);
const accountant = merge({}, allPermissions, {
  bills: {
    approve: stubFalse,
  },
  userManagement: {
    update: canAccountantManageUser,
    delete: canAccountantManageUser,
    transferOwnership: stubFalse,
  },
});

const canAdminManageUser = (userToUpdateRole: Role) => userToUpdateRole !== Role.OWNER;
const admin = merge({}, allPermissions, {
  userManagement: {
    update: canAdminManageUser,
    delete: canAdminManageUser,
    transferOwnership: stubFalse,
    manageUserOrganizations: canAdminManageUser,
  },
});

export const permissions: PermissionsPerRole = {
  owner: allPermissions,
  admin,
  contributor,
  accountant,
};

export function getRoleOptions(companyType?: CompanyTypeLiteral) {
  const adminLabel = companyType === 'accounting-firm' ? 'user.role.accountingFirmAdmin' : 'user.role.admin';

  return compact([
    { label: adminLabel, id: Role.ADMIN },
    { label: 'user.role.accountant', id: Role.ACCOUNTANT },
    { label: 'user.role.contributor', id: Role.CONTRIBUTOR },
  ]);
}

type UsePermittedRoleOptionsProps = {
  orgId?: number;
  showFirmAdminRole?: boolean;
};

export function usePermittedRoleOptions({ orgId, showFirmAdminRole }: UsePermittedRoleOptionsProps = {}) {
  const orgSelector = orgId ? organizationsStore.selectors.byId(orgId) : profileStore.selectors.getCurrentOrg;
  const org = useSelector(orgSelector);

  const permissionsSelector = orgId
    ? profileStore.selectors.org(orgId).getPermissions
    : profileStore.selectors.getPermissions;
  const permissions = useSelector(permissionsSelector);

  const roleOptions = getRoleOptions(showFirmAdminRole && org?.companyType).filter((role) =>
    permissions.userManagement.update(role.id)
  );

  return { roleOptions };
}
