import { featureFlags } from '@melio/shared-web';
import noop from 'lodash/noop';
import { useRef } from 'react';
import { MICard } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Separator } from 'src/core/ds/separator';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useFetchAccountingPlatformBankAccounts } from 'src/modules/accounting-platform-bank-accounts/hooks/useFetchAccountingPlatformBankAccounts';
import { PaymentApprovalLogs } from 'src/pages/bill/components/BillsGallery/PaymentApprovalLogs';
import { BillStatus } from 'src/pages/bill/components/BillStatus';
import { RenderStatusMessage } from 'src/pages/bill/components/ViewBill/components/RenderStatusMessage/RenderStatusMessage';
import { InstallmentsDeductionDatesInfo } from 'src/pages/bill/components/ViewBillPaymentActivity/components/InstallmentsDeductionDatesInfo';
import { CardThemes } from 'src/pages/bill/components/ViewBillPaymentActivity/ViewBillPaymentActivity';
import { RepaymentCreditKey } from 'src/pages/bill/pay/components/PayBillPaymentActivity/components/RepaymentCreditKey';
import { RepaymentFundingSourceInfo } from 'src/pages/bill/pay/components/PayBillPaymentActivity/components/RepaymentFundingSourceInfo';
import { getPayFromLabel } from 'src/pages/bill/utils';
import { AccountRecord } from 'src/pages/settings/records';
import { ThemeType } from 'src/theme/global-theme';
import { getScheduledStyling } from 'src/utils/bill-details';
import { BillStatus as BillStatuses, FeatureFlags, PaymentStatus as PaymentStatuses } from 'src/utils/consts';
import { BillType, FinancePayment, Installment, StatusInfoType } from 'src/utils/types';
import { ViewPaymentMultipleBills } from '../ViewPaymentBills';
import { InstallmentAmountSubheader } from './InstallmentAmountSubheader';
import { InstallmentPaymentActivityHeader } from './InstallmentPaymentActivityHeader';
import { InstallmentStatus } from './InstallmentStatus';

type Props = {
  payment: FinancePayment;
  bill: BillType;
  theme: ThemeType;
  backPath: string;
  installment: Installment;
};

export const ViewScheduledInstallments = ({ payment, bill, theme, backPath, installment }: Props) => {
  const [isSelfServeRefundEnabled] = featureFlags.useFeature(FeatureFlags.selfServeRefund, false);
  const fundingSource = AccountRecord(payment.fundingSource);
  const site = useSiteContext();
  useFetchAccountingPlatformBankAccounts({ forceReload: true });
  const isBillPaid = bill.status === BillStatuses.PAID;
  const payFromLabel = getPayFromLabel(isBillPaid, true);
  const billsGalleryRef = useRef<HTMLDivElement>(null);
  const totalInstallmentAmount = payment.financing.installments.length;

  const statusInfo: StatusInfoType = {
    status: BillStatuses.SCHEDULED,
    ...getScheduledStyling(theme),
  };

  return (
    <SingleViewLoadingContainer>
      <InstallmentStatus
        currentInstallmentNumber={installment.installmentNumber}
        totalInstallmentAmount={totalInstallmentAmount}
        backPath={backPath}
      />
      <Flex direction="column" h="full" mb={{ sm: 10 }} data-testid="payment-info-container">
        <MICard mode="mainSingleScreen">
          {statusInfo && <BillStatus statusInfo={statusInfo} />}
          <Box>
            <RenderStatusMessage
              payment={payment}
              bill={bill}
              onRefundPayment={noop}
              isEmbeddedMode={site.embeddedMode}
              status={PaymentStatuses.IN_PROGRESS}
              isSelfServeRefundEnabled={isSelfServeRefundEnabled}
            />
          </Box>

          <Box data-testid="view-payment-details" pt={3}>
            <Item>
              <InstallmentPaymentActivityHeader payment={payment} />
            </Item>
            <Item>
              <InstallmentAmountSubheader
                amount={installment.amount}
                payment={payment}
                linkLabel="financing.scheduledInstallment.status.sent"
              />
            </Item>
            <Separator color="grey.300" width="100%" />
            <Item>
              <PaymentApprovalLogs payment={payment} />
            </Item>
            <Separator color="grey.300" width="100%" />
            <Item>
              <BlockTitleContainer data-testid="billpay-view-title-container">
                <MIFormattedText label={payFromLabel} />
              </BlockTitleContainer>
              <Box data-testid="billpay-view-activity-container">
                <RepaymentFundingSourceInfo bill={bill} fundingSource={fundingSource} />
                <InstallmentsDeductionDatesInfo
                  theme={CardThemes.Black}
                  financing={payment.financing}
                  showInstallmentsBadges
                />
              </Box>
            </Item>
            <Item>
              <Box pb={1}>
                <RepaymentCreditKey />
              </Box>
            </Item>
          </Box>
        </MICard>
        <Box data-testid="payment-bills-gallery" ref={billsGalleryRef} mb={{ lg: '10' }}>
          <ViewPaymentMultipleBills payment={payment} />
        </Box>
      </Flex>
    </SingleViewLoadingContainer>
  );
};

const Item: React.FC = ({ children }) => (
  <Box mt={6} mx={8}>
    {children}
  </Box>
);

export const BlockTitleContainer: React.FC = ({ children }) => (
  <Box color="grey.700" textTransform="uppercase" mb={0.5} textStyle="body4Uppercase">
    {children}
  </Box>
);
