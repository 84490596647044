import { MouseEvent as ReactMouseEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { GlobalState } from 'src/redux/types';
import { analytics } from 'src/services/analytics';
import { HideClientModalMessageButtons } from './components/HideClientModalMessageButtons';
import { HideClientModalMessageContent } from './components/HideClientModalMessageContent';
import { HideClientModalMessageTitle } from './components/HideClientModalMessageTitle';

type Props = {
  companyName: string;
  dismiss: (event: ReactMouseEvent) => void;
  onConfirm: (event: ReactMouseEvent) => void;
  orgId: number;
};

export const HideClientModalMessage = ({ companyName, dismiss, onConfirm, orgId }: Props) => {
  const isLoading = useSelector((state: GlobalState) => state.users.hideFirmClient.loading);
  useEffect(() => {
    if (orgId) {
      analytics.trackAction('hide-client-modal-opened', { hiddenOrganizationId: orgId });
    }
  }, [orgId]);

  const handleDismissClick = (e: ReactMouseEvent) => {
    analytics.trackAction('hide-client-modal-dismissed', { hiddenOrganizationId: orgId });
    dismiss(e);
  };

  return (
    <ModalMessage
      id="hide-client-modal"
      titleComponent={<HideClientModalMessageTitle companyName={companyName} />}
      contentComponent={<HideClientModalMessageContent />}
      buttonComponent={
        <HideClientModalMessageButtons onConfirm={onConfirm} onCancel={handleDismissClick} isLoading={isLoading} />
      }
      onCloseClick={handleDismissClick}
    />
  );
};
