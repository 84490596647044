import { MaskField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { ModelViewField } from 'src/ui/form';

type Props = {
  modelViewField: ModelViewField<InternalBusinessOwnershipDetailsModel['dateOfBirth']>;
  testId?: string;
  errorMessage?: string;
};

export const DateOfBirthField = ({ modelViewField, testId, errorMessage }: Props) => (
  <MaskField
    testId={testId || 'input-dateOfBirth'}
    label="financing.businessOwnershipDetails.fields.dateOfBirth.title"
    placeholder="financing.businessOwnershipDetails.fields.dateOfBirth.placeholder"
    errorMessage={errorMessage}
    isRequired
    isModelOnChangeFormatted
    model={modelViewField}
    format={Consts.MASK_FORMAT_OPTIONS.dateOfBirth}
    mb={0}
  />
);
