import { InvoiceFilePreview } from 'src/components/layout/InvoiceFilePreview';
import { BillCreateOptions } from 'src/pages/bill/components/BillCreateOptions';

type Props = {
  onUploadRemove: () => void;
  onChangeAttachment: (file: File, loadBillFromAttachment?: boolean) => void;
  fileStorageUrl?: string | null;
  filePreviewUrls?: string[] | null;
};

export const BillCreateOptionsContainer = ({
  onUploadRemove,
  fileStorageUrl,
  filePreviewUrls,
  onChangeAttachment,
}: Props) => (
  <>
    {fileStorageUrl ? (
      <InvoiceFilePreview
        onUploadRemove={onUploadRemove}
        fileStorageUrl={fileStorageUrl}
        filePreviewUrls={filePreviewUrls}
      />
    ) : (
      <BillCreateOptions flavor="block" onChangeAttachment={onChangeAttachment} />
    )}
  </>
);
