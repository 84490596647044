import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

export type LoaderColorType = 'white' | 'primary' | 'dark';

type Props = {
  color?: LoaderColorType;
  context?: 'button' | 'page';
};

export const Loader = ({ color = 'white', context = 'button' }: Props) => (
  <Flex sx={ProcessingStyle(context)}>
    <Flex sx={ContainerStyle}>
      <Box sx={CircleGeneralStyle(color)} />
      <Box sx={CircleGeneralStyle(color)} />
      <Box sx={CircleGeneralStyle(color)} />
      <Box sx={CircleGeneralStyle(color)} />
    </Flex>
  </Flex>
);

const ProcessingStyle = (context: string) => ({
  position: context === 'button' ? 'absolute' : 'relative',
  t: 0,
  l: 0,
  h: 'inherit',
  w: 'full',
  justifyContent: 'center',
  alignItems: 'center',
});

const ContainerStyle = {
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '1rem',
  width: '6rem',
  '> div:nth-child(1)': {
    left: '0.6rem',
    animation: 'lds-ellipsis1 0.6s infinite',
  },
  '> div:nth-child(2)': {
    left: '0.6rem',
    animation: 'lds-ellipsis2 0.6s infinite',
  },
  '> div:nth-child(3)': {
    left: '2.6rem',
    animation: 'lds-ellipsis2 0.6s infinite',
  },
  '> div:nth-child(4)': {
    left: '4.5rem',
    animation: 'lds-ellipsis3 0.6s infinite',
  },
  '@keyframes lds-ellipsis1': {
    '0%': {
      transform: 'scale(0)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  '@keyframes lds-ellipsis3': {
    '0%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0)',
    },
  },
  '@keyframes lds-ellipsis2': {
    '0%': {
      transform: 'translate(0, 0)',
    },
    '100%': {
      transform: 'translate(1.9rem, 0)',
    },
  },
};

const CircleGeneralStyle = (color: LoaderColorType) => {
  let dotColor = 'white';

  if (color === 'primary') {
    dotColor = 'primary.500';
  } else if (color === 'dark') {
    dotColor = 'black';
  }

  return {
    position: 'absolute',
    w: '1rem',
    h: '1rem',
    borderRadius: 'full',
    animationTimingFunction: 'cubic-bezier(0, 1, 1, 0);',
    background: dotColor,
  };
};
