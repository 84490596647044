import memoize from 'lodash/memoize';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { actionName } from './consts';

const getEffectiveScheduleDate = memoize(deliveryApi.getEffectiveScheduleDate, hashListKey);

export const BATCH_UPDATE_DEDUCTION_DATE = generateSliceName(actionName, 'update payment deduction dates');

export const regularBatchUpdateDeductionDatesSlice = createApiCallSlice<any, any>({
  name: BATCH_UPDATE_DEDUCTION_DATE,
  initialState: {
    byId: {},
  },
  api: getEffectiveScheduleDate,
  reducers: {
    [ON_REQUEST](state, action) {
      state.byId[action.payload.itemId] = {
        ...state.byId[action.payload.itemId],
        loading: true,
        error: undefined,
      };
    },
    [ON_SUCCESS](state, action) {
      const { itemId, fundingSourceId } = action.meta.identifier;
      const { scheduleDate } = action.payload;
      state.byId[itemId].minScheduledDate = scheduleDate;
      state.byId[itemId].payment.scheduledDate = scheduleDate;
      state.byId[itemId].payment.fundingSourceId = fundingSourceId;
      state.byId[itemId] = {
        ...state.byId[itemId],
        loading: false,
      };
    },
    [ON_FAILURE](state, action) {
      state.byId[action.meta.identifier.itemId] = {
        ...state.byId[action.meta.identifier.itemId],
        loading: false,
        error: action.error,
      };
    },
  },
});
