import * as React from 'react';
import styled from 'styled-components';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { CardWithSideImageLayout } from 'src/pages/auth/components/register-page-experiment/CardWisthSideImageLayout';
import { RegisterServerErrors, useRegisterPageLogic } from 'src/pages/auth/RegisterPage';
import { UserPasswordForm, useShowEmailPassword } from 'src/pages/auth/RegisterPageCondensed';
import { analytics } from 'src/services/analytics';
import { RegistrationFlow } from 'src/utils/consts';
import SideImage from './assets/signup-sideimage-B.png';
import { AlternativeLoginButtons } from './RegisterPageSideImageA';

type Props = {
  registrationFlow: RegistrationFlow;
};

export const RegisterPageSideImageB: React.VFC<Props> = (props) => {
  const {
    goLinkQuickbooks,
    goLogin,
    handleRequestPassword,
    registerMV,
    submit,
    openGoogleOAuth,
    isLoading,
    isPasswordRequestSucceeded,
    combinedError,
    isEmailPrefilled,
    isSpendManagementRegistrationFlow,
    errorRequestPassword,
  } = useRegisterPageLogic(props);
  const { showEmailPassword, setShowUserPassword } = useShowEmailPassword();

  const alternativeLoginButtons = (
    <AlternativeLoginButtons
      loginWithQuickbooks={isSpendManagementRegistrationFlow ? undefined : goLinkQuickbooks}
      loginWithGoogle={() => {
        analytics.track('register', 'login-with-google');
        openGoogleOAuth();
      }}
      textValues={{
        link: (label) => <StyledMIInlineLink onClick={goLogin} label={label} testId={`link-${label}`} />,
      }}
      loginWithUserPassword={() => setShowUserPassword(true)}
    />
  );

  return (
    <CardWithSideImageLayout
      image={SideImage}
      imageCaption="auth.signUpSideImageB.imageDescription"
      title="auth.signUpCondensed.title"
      subtitle="auth.signUpCondensed.subtitle"
    >
      <RegisterServerErrors
        errorRequestPassword={errorRequestPassword}
        isPasswordRequestSucceeded={isPasswordRequestSucceeded}
        handleRequestPassword={handleRequestPassword}
        combinedError={combinedError}
        goLogin={goLogin}
      />
      <>
        {showEmailPassword ? (
          <UserPasswordForm
            registerMV={registerMV}
            isEmailPrefilled={isEmailPrefilled}
            submit={submit}
            isLoading={isLoading}
          />
        ) : (
          alternativeLoginButtons
        )}
      </>
    </CardWithSideImageLayout>
  );
};

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: inherit;
  line-height: inherit;
  height: auto;
`;
