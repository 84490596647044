import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { DEFAULT_ICON_NAME, NotificationVariant, TOAST_BG_COLOR } from './consts';

type Props = {
  type: NotificationVariant;
  iconName?: IconNames;
  text?: string;
  textValues?: Record<string, any>;
  secondaryText?: string;
  secondaryTextValues?: Record<string, any>;
  testId?: string;
};

export const Toast = ({ type, iconName, text, textValues, secondaryText, secondaryTextValues, testId }: Props) => {
  const toastIconName = iconName || DEFAULT_ICON_NAME[type];
  const dataTestId = testId ?? `toast-${type}`;

  return (
    <Box bgColor={TOAST_BG_COLOR[type]} borderRadius="lg" shadow="500" w="auto" data-testid={dataTestId}>
      <Flex py={4} px={4} alignItems="center">
        {toastIconName && (
          <Box me={2} display="flex">
            <Icon color="white" size={IconSize.lg} name={toastIconName} />
          </Box>
        )}
        <Box color="white" textStyle="body4Semi">
          <MIFormattedText label={text || ''} values={textValues} />
        </Box>
        {secondaryText && (
          <Box ms={2} color="white" textStyle="body4Uppercase">
            <MIFormattedText label={secondaryText} values={secondaryTextValues} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};
