export const notificationContainer = {
  display: 'flex',
  px: 5,
  py: 4,
  alignItems: 'center',
  flexDirection: 'row',
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'grey.700',
  borderRadius: 'lg',
  mb: 10,
  textStyle: 'body3',
  color: 'grey.700',
};
