import { featureFlags } from '@melio/shared-web';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { DialogType, DialogVariants, FeatureFlags } from 'src/utils/consts';

export const TabapayMigrationModal = ({ goExit }: { goExit?: () => void }) => {
  const [isTabapayInMigrations] = featureFlags.useFeature(FeatureFlags.TabapayMigration, false);

  if (isTabapayInMigrations) {
    return (
      <Dialog
        type={DialogType.ALERT}
        variant={DialogVariants.ERROR}
        title="onboarding.fundingSources.card.tabaPayMigrationErrorTitle"
        subtitle="onboarding.fundingSources.card.tabaPayMigrationErrorSubtitle"
        okButtonText="dialogs.buttons.close"
        cancelButtonText="dialogs.buttons.close"
        showCancel={!!goExit}
        onOkAction={goExit}
        onCancelAction={goExit}
      />
    );
  }

  return null;
};
