import { IntentType } from 'src/utils/consts';

export const ACCOUNTING_FIRM_NAICS_CODE = 54121;

export const INTENT_OPTIONS = [
  {
    id: IntentType.PAY,
    label: 'onboarding.companyInfo.intent.options.pay.title',
    icon: 'icon-wallet-icon',
  },
  {
    id: IntentType.GET_PAID,
    label: 'onboarding.companyInfo.intent.options.getting-paid.title',
    icon: 'icon-receiving-method-icon',
  },
];

export const OperatingSystem = {
  IOS: 'iOS',
  ANDROID: 'android',
};
