import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type Props = {
  goViewDetails?: () => void;
};

export const ViewDetailsLink = ({ goViewDetails }: Props) => (
  <Box border="none" textStyle="link2" onClick={goViewDetails}>
    <MIFormattedText label="bills.form.partialPayments.viewDetails" />
  </Box>
);
