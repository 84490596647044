import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { MICreateOption } from 'src/components/common/MICreateOption';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import config from 'src/config';
import { useBreak } from 'src/hoc';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { ContactsTab } from 'src/utils/consts';

type Props = {
  onPrev: () => void;
  onExit: () => void;
};

const SelectContactType = ({ onPrev, onExit }: Props) => {
  const { isMobile } = useBreak();
  const history = useHistory();
  const orgId = useSelector(getOrgId);

  const createUrl = (type) =>
    config.featureFlags[`${type}BatchUpload`] === false
      ? contactsLocations.create.single
      : contactsLocations.create.options;

  const goCreate = (type) => () => {
    const path = generatePath(createUrl(type), {
      type,
      orgId,
    });

    history.push(path);
  };

  const createVendorDescription = isMobile
    ? 'contacts.contactType.options.vendors.subtitleSingle'
    : 'contacts.contactType.options.vendors.subtitle';

  return (
    <StepLayoutPage
      title="contacts.contactType.title"
      subtitle="contacts.contactType.subtitle"
      onPrev={onPrev}
      goExit={onExit}
      isLoading={false}
      testId="new-contact"
    >
      <MICreateOption
        icon="icon-vendor-icon"
        label="contacts.contactType.options.vendors.title"
        description={createVendorDescription}
        onClick={goCreate(ContactsTab.VENDORS)}
      />
      <MICreateOption
        icon="icon-customer-icon"
        label="contacts.contactType.options.customers.title"
        description="contacts.contactType.options.customers.subtitle"
        onClick={goCreate(ContactsTab.CUSTOMERS)}
      />
    </StepLayoutPage>
  );
};

export default SelectContactType;
