import some from 'lodash/some';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { getPaymentRequestId, isBillUnpaid } from 'src/utils/bills';
import {
  ButtonVariant,
  FailedPaymentMessage,
  FailedPaymentType,
  PaymentApprovalStatus,
  PaymentDeliverStatus,
  PaymentStatus,
  RiskStatus,
} from 'src/utils/consts';
import { isFinancedPayment, isPaymentBulkPayment, isReturnedCheck, isUndepositedCheck } from 'src/utils/payments';
import { PermissionsType } from 'src/utils/permissions';
import { BillType, HeaderAction, PaymentType } from 'src/utils/types';

type Params = {
  bill: BillType;
  currentUserId: string;
  permissions: PermissionsType;
  onRetryPayment: (paymentId: number) => void;
  onRetryPaymentDelivery: (paymentId: number, deliveryMethodId: number) => void;
  onClickSupport(): void;
  onDecline(): void;
  onApprove(): void;
  goPayBill(): void;
  onTrackDelivery: () => void;
  isLoading: boolean;
  isEditMode: boolean;
  canSchedulePayment: boolean;
  isApproving: boolean;
  payment?: PaymentType;
  onSchedulePaymentRequest?(): void;
  onRejectPaymentRequest?(): void;
  onChangeDeliveryMethod: (paymentId: number) => void;
  onResendVirtualCard: (paymentId: number) => void;
  onRetryReturnedCheck: (paymentId: number) => void;
  onResendUndepositedCheck: (paymentId: number) => void;
  enableApprovalWorkflows: boolean;
  enableRescheduleDeclinedApprovalBulkPayment: boolean;
  isInternationalDisabled?: boolean;
  financingRetryFailedToDeliver?: boolean;
  financingRecoveryFlowsQa?: boolean;
};

export const getBillHeaderActionsOptions = (params: Params): HeaderAction[] => {
  const {
    bill,
    payment,
    currentUserId,
    onClickSupport,
    isLoading,
    isEditMode,
    canSchedulePayment,
    onRetryPayment,
    onDecline,
    isApproving,
    onApprove,
    goPayBill,
    onTrackDelivery,
    permissions,
    onRetryPaymentDelivery,
    onChangeDeliveryMethod,
    onRejectPaymentRequest,
    onSchedulePaymentRequest,
    onResendVirtualCard,
    onRetryReturnedCheck,
    onResendUndepositedCheck,
    enableApprovalWorkflows,
    enableRescheduleDeclinedApprovalBulkPayment,
    isInternationalDisabled,
    financingRetryFailedToDeliver,
    financingRecoveryFlowsQa,
  } = params;

  const { vendor, organization, vendorId, isPaymentRequest } = bill;
  const { FAILED_PAYMENT, FAILED_TO_DELIVER, FAILED_TO_COLLECT } = FailedPaymentType;
  const internationalDisabledTooltip = {
    disabledTooltip: {
      label: 'bills.actions.internationalDisabledTooltip',
    },
  };

  const actions = {
    payBill: {
      action: goPayBill,
      label: 'bills.actions.pay',
      variant: ButtonVariant.PAY,
      disabled: isLoading || isEditMode || !canSchedulePayment || isInternationalDisabled,
      ...(isInternationalDisabled && internationalDisabledTooltip),
    },
    support: {
      action: onClickSupport,
      label: 'bills.actions.contactSupport',
      variant: ButtonVariant.TERTIARY,
      disabled: isLoading || isEditMode || !canSchedulePayment,
    },
    trackDelivery: {
      action: onTrackDelivery,
      label: 'bills.actions.trackDelivery',
      variant: ButtonVariant.PRIMARY,
    },
    approve: {
      action: onApprove,
      label: 'bills.actions.approve',
      variant: ButtonVariant.PRIMARY,
      iconClass: 'icon-notification-checkmark-icon',
      isProcessing: isApproving,
    },
    decline: {
      action: onDecline,
      label: 'bills.actions.decline',
      variant: ButtonVariant.SECONDARY,
      iconClass: 'icon-close-icon',
      disabled: isApproving,
    },
  };

  if (isPaymentRequest) {
    const paymentRequestActions = {
      reject: {
        action: onRejectPaymentRequest,
        label: 'bills.actions.rejectPaymentRequest',
        variant: ButtonVariant.TERTIARY,
        analyticsProperties: {
          organizationId: organization.id,
          vendorId,
          paymentRequestId: getPaymentRequestId(bill),
        },
      },
      schedule: {
        action: onSchedulePaymentRequest,
        label: 'bills.actions.schedulePaymentRequest',
        variant: ButtonVariant.PAY,
        analyticsProperties: {
          organizationId: organization.id,
          vendorId,
          paymentRequestId: getPaymentRequestId(bill),
        },
      },
    };

    return [paymentRequestActions.reject, paymentRequestActions.schedule];
  }

  if (payment) {
    const {
      id,
      approvalDecisionStatus,
      paymentApprovalDecisions,
      riskStatus,
      metadata,
      status,
      deliveryMethodId,
      deliveryMethod,
    } = payment;

    const isRPPSVendor = isRppsVendor({
      ...vendor,
      deliveryMethods: vendor?.deliveryMethods ?? [],
    });
    const isInternationalPayment = isInternationalDeliveryMethod(deliveryMethod?.deliveryType);
    const isFailedPayment = status === PaymentStatus.FAILED;
    const isFailedPaymentWithAbilityToRetry = isFailedPayment && metadata?.canUserRetry && !isInternationalPayment;
    const isDeclinedByRiskPayment = riskStatus === RiskStatus.DECLINED;
    const isPendingApprovalPaymentWithAbilityToApprove =
      approvalDecisionStatus === PaymentApprovalStatus.PENDING && permissions.bills.approve();
    const isDeclinedApprovalPaymentWithAbilityToReschedule =
      approvalDecisionStatus === PaymentApprovalStatus.DECLINED && permissions.bills.reschedule();
    const isUnpaidBillWithoutPaymentId = isBillUnpaid(bill) && !payment && !!status;
    const isPaymentWithCheckTracks = payment.checkTracks && payment.checkTracks[0]?.trackingUrl;
    const isPaymentAlreadyRetried = payment.deliverStatus === PaymentDeliverStatus.RETRY;
    const isBasicFailedPayment = metadata?.failedType === FAILED_PAYMENT;
    const isFailedToDeliverPayment = metadata?.failedType === FAILED_TO_DELIVER;
    const isFailedToCollectPayment = metadata?.failedType === FAILED_TO_COLLECT;
    const isBulkPayment = isPaymentBulkPayment(payment);
    const isFinancingPayment = isFinancedPayment(payment);
    const shouldShowVoidAndRetryFlow =
      (!isFinancingPayment || financingRetryFailedToDeliver) && !isPaymentAlreadyRetried && isUndepositedCheck(payment);
    const isRescheduleDeclinedApprovalPaymentDisabled = isBulkPayment && !enableRescheduleDeclinedApprovalBulkPayment;
    const isFinancePaymentRetryAllowed =
      (financingRetryFailedToDeliver && payment.metadata?.allowedActions?.isFailedToDeliverRetryEnabled) ||
      financingRecoveryFlowsQa;
    const isFinancingFailedToDeliverEnable = isFinancingPayment && isFinancePaymentRetryAllowed;
    const isFinancingFailedToDeliverDisabled = isFinancingPayment && !isFinancePaymentRetryAllowed;
    const isReturnedCheckPayment =
      isReturnedCheck(payment) && (!isFinancingPayment || isFinancingFailedToDeliverEnable);

    const collectMessagesToRetry = [
      'accountFrozen',
      'uncollectedFunds',
      'invalidTransaction',
      'bankAccountUnauthorized',
      'userBlockedMelioAccount',
      'exceedsWithdrawalAmountLimit',
    ];

    if (isFailedPaymentWithAbilityToRetry) {
      const isDeliveryMethodMissed = !deliveryMethod || (deliveryMethod && Boolean(deliveryMethod.deletedAt));

      const failedPaymentActions = {
        retry: {
          action: () => onRetryPayment(id),
          label: 'bills.actions.retryPayment',
          variant: ButtonVariant.PRIMARY,
          disabled: isLoading || isEditMode,
        },
        retryFailedToDeliver: {
          action:
            isDeliveryMethodMissed && onChangeDeliveryMethod
              ? () => onChangeDeliveryMethod(id)
              : () => onRetryPaymentDelivery(id, deliveryMethodId),
          label: 'bills.actions.retryPaymentDelivery',
          variant: ButtonVariant.PRIMARY,
          disabled: isLoading || isEditMode,
        },
        retryDelivery: {
          action: () => onRetryPaymentDelivery(id, deliveryMethodId),
          label: 'bills.actions.resendPayment',
          variant: ButtonVariant.PRIMARY,
          disabled: isLoading || isEditMode,
        },
        resendVirtualCard: {
          action: () => onResendVirtualCard(id),
          label: 'bills.actions.resendPayment',
          variant: ButtonVariant.PRIMARY,
          disabled: isLoading || isEditMode,
        },
        retryReturnedCheck: {
          action: () => onRetryReturnedCheck(id),
          label: 'bills.actions.resendPayment',
          variant: ButtonVariant.PRIMARY,
          disabled: isLoading || isEditMode,
        },
      };

      if (metadata?.failureMessage === FailedPaymentMessage.RECEIVER_REFUSED_CREDIT_ENTRY) {
        return [failedPaymentActions.retryDelivery];
      }

      if (isFailedToDeliverPayment) {
        if (isFinancingFailedToDeliverDisabled) {
          return [actions.support];
        }

        if (isRPPSVendor) {
          return [actions.support];
        }

        if (metadata?.failureMessage === FailedPaymentMessage.VIRTUAL_CARD_EXPIRED) {
          return [failedPaymentActions.resendVirtualCard];
        }

        if (isReturnedCheckPayment) {
          return [failedPaymentActions.retryReturnedCheck];
        }

        return [failedPaymentActions.retryFailedToDeliver];
      }

      if (isBasicFailedPayment && !isFinancingPayment) {
        return [failedPaymentActions.retry];
      }

      if (
        !isFinancingPayment &&
        isFailedToCollectPayment &&
        metadata?.failureMessage &&
        collectMessagesToRetry.includes(metadata?.failureMessage)
      ) {
        return [failedPaymentActions.retry];
      }

      return [actions.support];
    }

    if (isDeclinedByRiskPayment) {
      return [actions.support];
    }

    if (isPendingApprovalPaymentWithAbilityToApprove) {
      const alreadyApprovedByCurrentUser =
        enableApprovalWorkflows && some(paymentApprovalDecisions, (decision) => decision.userId === currentUserId);

      if (!alreadyApprovedByCurrentUser) {
        return [actions.decline, actions.approve];
      }
    }

    if (isDeclinedApprovalPaymentWithAbilityToReschedule) {
      const declinedApprovalPaymentActions = {
        retry: {
          action: () => onRetryPayment(id),
          label: 'bills.actions.reschedule',
          variant: ButtonVariant.PAY,
          disabled: isLoading || isRescheduleDeclinedApprovalPaymentDisabled,
        },
      };

      return [declinedApprovalPaymentActions.retry];
    }

    if (isUnpaidBillWithoutPaymentId) {
      if (isFailedPayment) {
        return [actions.support];
      }

      return [actions.payBill];
    }

    if (isPaymentWithCheckTracks) {
      return [actions.trackDelivery];
    }

    if (shouldShowVoidAndRetryFlow) {
      const resendActions = {
        resendUndepositedCheck: {
          action: () => onResendUndepositedCheck(id),
          label: 'bills.actions.resendUndepositedCheckPayment',
          variant: ButtonVariant.PRIMARY,
          disabled: isLoading || isEditMode,
        },
      };

      return [resendActions.resendUndepositedCheck];
    }

    return [];
  }

  return [actions.payBill];
};
