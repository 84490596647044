const baseStyle = {
  px: 2,
  py: 1,
  bg: 'black',
  color: 'white',
  borderRadius: 'lg',
  apply: 'textStyles.body4',
  maxW: '228px',
  zIndex: 'tooltip',
  textAlign: 'center',
};

export default {
  baseStyle,
};
