import { DeleteAdminModalMessage } from 'src/components/modals/DeleteAdminModalMessage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import usersStore from 'src/modules/users/users-store';
import { DeleteContributorModalMessage } from 'src/pages/settings/ManageUsersSettingsPage/modals/DeleteCollaboratorModal/components/DeleteContributorModalMessage';
import { Role } from 'src/utils/consts';

type Props = {
  orgId: number;
  userId: number;
  userRole: Role;
  userFullName: string;
  dismiss: () => void;
};

export const DeleteCollaboratorModal = ({ orgId, userId, userRole, userFullName, dismiss }: Props) => {
  const userActions = useStoreActions(usersStore);

  const handleConfirm = () =>
    userActions.userManagementDeleteSlice({
      id: userId,
      orgId,
    });

  return userRole === Role.ADMIN ? (
    <DeleteAdminModalMessage onCancel={dismiss} onRemove={handleConfirm} userFullName={userFullName} />
  ) : (
    <DeleteContributorModalMessage onCancel={dismiss} onConfirm={handleConfirm} userFullName={userFullName} />
  );
};
