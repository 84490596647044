import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

export const ViewBillPaymentActivityFailureDate = ({ failureDate }: { failureDate: Date | string }) => (
  <Box textStyle="body4" color="red.500" mt={1} data-testid="bill-pay-delivery-method-failure-date">
    <MIFormattedText
      label="viewBillPaymentActivity.status.failed"
      values={{
        date: <MIFormattedDate date={failureDate} />,
      }}
    />
  </Box>
);
