import { featureFlags } from '@melio/shared-web';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { FeatureFlags, NotificationCardTypes } from 'src/utils/consts';

export const NotificationCardUnreadyBills: React.FC = () => {
  const [isAmexAddressFeatureEnabled] = featureFlags.useFeature(FeatureFlags.AmexVendorAddressBatchFlow, false, {
    shouldTrack: false,
  });
  const readyBillsCount = useSelector(regularBatchPaymentsStore.selectors.readyBillsCount(isAmexAddressFeatureEnabled));
  const totalCount = useSelector(regularBatchPaymentsStore.selectors.totalCount);
  const numberUnreadyPayments = totalCount - readyBillsCount;

  return (
    <Box pt={24}>
      <MINotificationCard
        type={NotificationCardTypes.WARNING}
        title={{
          label: 'regularBatchPayments.notifications.unreadyPaymentsTitle',
          values: { numberUnreadyPayments },
        }}
        subtitle={{
          label: 'regularBatchPayments.notifications.unreadyPaymentsSubtitle',
          values: { numberUnreadyPayments },
        }}
      />
    </Box>
  );
};
