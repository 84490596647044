import { CandidatesDetailsModelType } from '../../types';

export const model: CandidatesDetailsModelType = {
  contactFirstName: '',
  contactLastName: '',
  phone: '',
  phoneExt: '',
  googlePlaceId: '',
  formattedAddress: '',
  addressLine1: '',
  addressLine2: '',
  aptNumber: '',
  state: '',
  city: '',
  zipCode: '',
  countryCode: 'US',
  email: '',
  emailConfirmation: '',
  companyName: '',
  routingNumber: '',
  accountNumber: '',
  msnBusinessType: '',
  taxId: undefined,
  taxIdType: undefined,
};
