import { featureFlags } from '@melio/shared-web';
import { format } from 'date-fns';
import startCase from 'lodash/startCase';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { useMounted } from 'src/hooks/useMounted';
import { getProApi } from 'src/modules/get-pro/api';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { FeatureFlags, GetProTabs } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import { getInvoiceStatusByPayment } from 'src/utils/payments';
import dateFromModelValue from './dateFromModelValue';

type ReportDownloadParametersType = {
  startDate: string;
  endDate: string;
  includeInProgress: boolean;
};

export type UseCsvReportReturnType = {
  csv: any[] | null;
  clearCsv: () => void;
  loading: boolean;
  downloadAndProcess: ({ startDate, endDate }: ReportDownloadParametersType) => Promise<void>;
};

export const useCsvReport = (): UseCsvReportReturnType => {
  const { isMounted } = useMounted();
  const [csv, setCsv] = useState<any[] | null>(null);
  const orgId = useSelector(getOrgId);
  const { onApiCall, loading } = useApi({
    api: getProApi.report,
  });
  const [isIncludeInProgressEnabled] = featureFlags.useFeature(FeatureFlags.IncludeInProgress, false);

  const clearCsv = useCallback(() => {
    setCsv(null);
  }, []);

  const downloadAndProcess = async ({ startDate, endDate, includeInProgress }: ReportDownloadParametersType) => {
    setCsv(null);

    const { items } = await onApiCall({
      orgId,
      // include the full end day
      filters: {
        start: '0',
        limit: '25000',
        sort: 'paidDate:desc',
        status: GetProTabs.PAID,
        filter: {
          type: isIncludeInProgressEnabled ? 'payments_between_dates_report' : 'paid_between_dates_report',
          startDate: format(dateFromModelValue(startDate), DateFormats.isoDate),
          endDate: format(dateFromModelValue(endDate), DateFormats.isoDate),
          includeInProgress: includeInProgress.toString(),
        },
      },
    });

    analytics.trackAction('getPro.downloadCsvReportModal.download', {
      startDate,
      endDate,
      includeInProgress,
      countPayments: items?.length || 0,
      tpv: items.reduce((totalVolume, { totalAmount: currVolume }) => totalVolume + currVolume, 0),
    });

    if (isMounted()) {
      setCsv([
        ['Date', 'Status', 'Invoice Number', 'Amount', 'Customer', 'Initiated by'],
        ...items.map((item) => [
          item.deliveryDate && format(new Date(item.deliveryDate), DateFormats.isoDate),
          startCase(getInvoiceStatusByPayment(item)),
          item.invoiceNumber,
          item.totalAmount,
          item.customer,
          item.initiatedBy,
        ]),
      ]);
    }
  };

  return {
    csv,
    clearCsv,
    loading,
    downloadAndProcess,
  };
};
