import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { getProfile } from 'src/redux/user/selectors';
import { useOrganizationPreferences } from '../../../modules/organizations/hooks/useOrganizationPreferences';
import { FeatureFlags, RegistrationFlow, Role } from '../../../utils/consts';

export const useIsSpendManagementEnabled = () => {
  const profile = useSelector(getProfile);
  const organizationPreferences = useOrganizationPreferences();
  const currentUserOrg = useSelector(profileStore.selectors.getUserOrganization);
  const [isSpendManagementEnabledFF] = featureFlags.useFeature(FeatureFlags.IsSpendManagementEnabled, false);
  const isRoleEnabledForSpendManagement = [Role.OWNER, Role.ACCOUNTANT].includes(currentUserOrg?.role);

  return {
    isSpendManagementEnabled:
      isSpendManagementEnabledFF || organizationPreferences?.isSpendManagementEnabled
        ? isRoleEnabledForSpendManagement || profile.registrationFlow === RegistrationFlow.SPEND_MANAGEMENT
        : false,
  };
};
