import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BlockCreateLinkModalWithDismiss } from 'src/components/common/BlockCreateLinkModal';
import { MICard, MICardForm } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useDisclosure } from 'src/core/ds/hooks';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';
import { useModal } from 'src/helpers/react/useModal';
import { useStartVendorCompany } from 'src/hooks/useStartVendorCompany';
import { VendorCompanyAddedPageMSNContent } from 'src/pages/onboarding/vendor-company-info/components/VendorCompanyAddedPageMSNContent';
import { getOwnedVendorHandle } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { EVENT_PAGE } from '../consts';

export const SharePaymentLinkButton = () => {
  const ownedVendorHandle = useSelector(getOwnedVendorHandle);

  const [BlockCreatePaymentLink, onShowBlockCreatePaymentLink] = useModal(BlockCreateLinkModalWithDismiss, {
    modalName: 'block-create-payment-link',
  });

  const {
    isOpen: isShareLinkModalOpen,
    onOpen: onShareLinkModalOpen,
    onClose: onShareLinkModalClose,
  } = useDisclosure();

  const { startVendorOnboarding } = useStartVendorCompany({ onBlockCreateLink: onShowBlockCreatePaymentLink });

  const handleShareLinkModalOpen = useCallback(() => {
    onShareLinkModalOpen();
    analytics.track(EVENT_PAGE, 'payment-link-modal-expose');
  }, [onShareLinkModalOpen]);

  const handleShareLink = useCallback(() => {
    analytics.track(EVENT_PAGE, 'share-payment-link-click');

    ownedVendorHandle
      ? handleShareLinkModalOpen()
      : startVendorOnboarding({
          origin: DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION,
        });
  }, [handleShareLinkModalOpen, ownedVendorHandle, startVendorOnboarding]);

  return (
    <>
      {BlockCreatePaymentLink}
      <Button
        leftIcon={<Icon name={IconNames.send} />}
        variant={ButtonVariants.tertiary}
        label="onboarding.vendorCompanyInfo.addedMsnPortal.title"
        onClick={handleShareLink}
      />
      <Modal isOpen={isShareLinkModalOpen} onClose={onShareLinkModalClose}>
        <Modal.Header>
          <Modal.HeaderTitle>
            <MIFormattedText label="onboarding.vendorCompanyInfo.addedMsnPortal.title" />
          </Modal.HeaderTitle>
          <Modal.HeaderSubTitle>
            <MIFormattedText label="incomingPayments.sharePaymentLinkModal.subHeader" />
          </Modal.HeaderSubTitle>
        </Modal.Header>
        <Modal.Body>
          <MICard mode="regular">
            <MICardForm>
              <VendorCompanyAddedPageMSNContent />
            </MICardForm>
          </MICard>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onShareLinkModalClose} variant={ButtonVariants.tertiaryNaked}>
            <MIFormattedText label="general.close" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
