export const invoiceAttachment = {
  display: 'flex',
  borderColor: 'grey.300',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 'lg',
  cursor: 'pointer',
  alignItems: 'center',
  px: 5,
  py: 4,
  textAlign: 'left',
};

export const invoiceDescription = {
  ml: 4,
  textStyle: 'body4Semi',
  color: 'grey.600',
  flexGrow: 1,
  wordBreak: 'break-all',
  overflow: 'hidden',
};

export const invoiceFileName = {
  display: 'flex',
  overflow: 'hidden',
};
