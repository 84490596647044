import { EventMappingsTreeChild } from 'src/services/analytics/types';

const PAGE_EVENT = 'resend-undeposited-check';

const properties = {
  origin: 'origin',
  vendorId: 'vendorId',
  partialBillId: 'partialBillId',
  newDeliveryId: 'newDeliveryId',
  newDeliveryType: 'newDeliveryType',
  fundingSourceId: 'fundingSourceId',
  fundingSourceType: 'fundingSourceType',
  originDeliveryId: 'originDeliveryId',
  originDeliveryType: 'originDeliveryType',
};

export const MAPPING: EventMappingsTreeChild = {
  'page.view': [PAGE_EVENT, 'select-delivery-method'],
  'check-to-ach': [
    PAGE_EVENT,
    'delivery-change-from-check-to-ach',
    {
      ...properties,
    },
  ],
  'check-to-check': [
    PAGE_EVENT,
    'delivery-change-from-check-to-check',
    {
      ...properties,
    },
  ],
};
