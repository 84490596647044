import { AccountingPlatformLogo } from 'src/components/common/MICompanyInitialsCircle/AccountingPlatformLogo';
import { Avatar, Consts } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import { CompanyInfoType } from 'src/utils/types';

type Props = {
  organization: CompanyInfoType;
  color?: string;
  isSelected?: boolean;
  disabled?: boolean;
};

export const MICompanyInitialsCircle = ({ organization, isSelected, color, disabled }: Props) => (
  <Box
    pos="relative"
    borderRadius="full"
    border={isSelected ? '2px' : ''}
    borderColor={isSelected ? 'green.500' : ''}
    bgColor={disabled ? 'grey.400' : ''}
  >
    <Avatar
      name={organization.companyName as string}
      size={Consts.Size.lg}
      bg={color}
      link={organization.logoUrl as string}
    />
    {organization.accountingPlatform ? (
      <AccountingPlatformLogo accountingPlatform={organization.accountingPlatform} />
    ) : null}
  </Box>
);
