export const drawerTabStyles = {
  mr: 5,
  py: 1,
  color: 'grey.600',
  cursor: 'pointer',
  _hover: {
    color: 'black',
  },
  '&[data-active=true]': {
    color: 'black',
    borderBottom: '2px',
    borderBottomColor: 'black',
  },
};
