import isString from 'lodash/isString';
import { useMemo, useState } from 'react';

export function useSwitch(
  initial?: string | null,
  values?: string[],
  notifyChange?: (newValue: string | null) => any
): any {
  const [state, setState] = useState(initial);

  function toggle(value: string | null) {
    let newValue = value;

    if (!isString(value) || state === newValue) {
      newValue = null;
    }

    setState(newValue);

    if (notifyChange) {
      notifyChange(newValue);
    }
  }
  const toggles = useMemo(
    () =>
      values
        ? values.concat('').reduce((obj, name) => {
            obj[`toggle${name}`] = () => toggle(name || null);

            return obj;
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, {})
        : null,
    values || []
  );

  return [state, toggles || toggle];
}
