import { isToday, isTomorrow } from 'date-fns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { ButtonSizes } from 'src/core/ds/button';
import Button from 'src/core/ds/button/Button';
import { Link } from 'src/core/ds/link';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { ReactComponent as CheckmarkIcon } from 'src/images/general/check-circle.svg';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { UpsellType } from 'src/modules/payments/api-types';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import {
  FAST_DELIVERY_EVENT_NAMES,
  FAST_DELIVERY_SERVER_EVENT_NAMES,
  upsellTypeToAnalyticsOptionName,
} from 'src/pages/vendor/upgrade-payment/consts';
import { useVendorUpgradePaymentAnalytics } from 'src/pages/vendor/upgrade-payment/hooks';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { RegistrationFlow } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { DeliveryMethodType } from 'src/utils/types';
import { PageWrapper } from '../../components';

type Props = {
  token: string;
};

const getSubtitle = ({
  deliveryMethod,
  upsellType,
  deliveryDate,
  paymentAmount,
  companyName,
}: {
  deliveryMethod: Partial<DeliveryMethodType>;
  upsellType: UpsellType;
  deliveryDate: string;
  paymentAmount: number;
  companyName: string;
}) => {
  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);
  const expectedDeliveryDate = new Date(deliveryDate);

  let label;
  const values = {
    amount: <MIFormattedCurrency value={paymentAmount} />,
    companyName,
    accountNumber4digits,
    deliveryDate: <MIFormattedDate date={expectedDeliveryDate} />,
  };

  if (upsellType === UpsellType.PUSH_TO_RTP) {
    label = 'vendors.upgradePayment.success.subtitle.now';
  } else if (isToday(expectedDeliveryDate)) {
    label = 'vendors.upgradePayment.success.subtitle.today';
  } else if (isTomorrow(expectedDeliveryDate)) {
    label = 'vendors.upgradePayment.success.subtitle.tomorrow';
  } else {
    label = 'vendors.upgradePayment.success.subtitle.date';
  }

  return { label, values };
};

const getRegistrationFlow = (selectedUpsellType?: UpsellType) => {
  switch (selectedUpsellType) {
    case UpsellType.PUSH_TO_FAST_ACH:
      return RegistrationFlow.PUSH_TO_FAST_ACH;
    case UpsellType.PUSH_TO_RTP:
      return RegistrationFlow.PUSH_TO_RTP;
    default:
      return RegistrationFlow.VENDOR;
  }
};

export const SuccessPage = ({ token }: Props) => {
  const [historyPush] = useHistoryWithOrgId();

  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { upsellItems } = useStructuredSelectors(paymentsStore.selectors.payment(paymentId));

  const [selectedUpsellType] = useLocationState<UpsellType>('selectedUpsellType');
  const selectedUpsellItem = upsellItems?.find(({ type }) => type === selectedUpsellType);

  const { staticAnalyticsProps, trackAction, trackServerAction } = useVendorUpgradePaymentAnalytics(token);

  useEffect(() => {
    if (upsellItems) {
      const selectedOption = upsellTypeToAnalyticsOptionName[selectedUpsellItem?.type];

      trackAction(FAST_DELIVERY_EVENT_NAMES.SUCCESS_PAGE_VIEW, {
        selectedOption,
      });
      trackServerAction(FAST_DELIVERY_SERVER_EVENT_NAMES.SUCCESS_PAGE_VIEW, {
        selectedOption,
      });
    }
  }, [upsellItems, trackAction, trackServerAction, selectedUpsellItem?.type]);

  const registrationFlow = getRegistrationFlow(selectedUpsellType);

  if (!upsellItems) {
    return <AreaLoader />;
  }

  const { organization, vendor } = payment;
  const deliveryMethod = vendor?.deliveryMethod;

  const { label: subtitleLabel, values: subtitleValues } = getSubtitle({
    deliveryMethod,
    upsellType: selectedUpsellType || UpsellType.PUSH_TO_FAST_ACH,
    deliveryDate: selectedUpsellItem?.deliveryDate || payment.deliveryEta,
    paymentAmount: payment?.amount,
    companyName: organization?.companyName,
  });

  const handleJoinMelioClick = () => {
    historyPush({
      path: registrationLocations.vendorRegister,
      state: {
        email: vendor?.contactEmail,
        registrationFlow,
      },
    });
  };

  return (
    <PageWrapper>
      <Box w="full" px={[5, 5, 10]} py={10} boxSizing="border-box">
        <Box display="flex" w="4rem" h="4rem" alignItems="center" justifyContent="center">
          <CheckmarkIcon width="3.33rem" height="3.33rem" />
        </Box>
        <Box mt={6} color="black" textStyle="h2Semi">
          <MIFormattedText label="vendors.upgradePayment.success.title" />
        </Box>
        <Box mt={4} color="black" textStyle="body2" data-testid="upgrade-payment-success-subtitle">
          <MIFormattedText label={subtitleLabel} values={subtitleValues} />
        </Box>
      </Box>
      <Box w="full" px={[5, 5, 10]} py={10} bgColor="grey.200" boxSizing="border-box">
        <Box color="black" textStyle="body1Semi">
          <MIFormattedText label="vendors.upgradePayment.success.contentTitle" />
        </Box>
        <Box mt={4} color="black" textStyle="body2">
          <MIFormattedText
            label="vendors.upgradePayment.success.contentLabel"
            values={{
              learnMore: (
                <Link
                  href="https://www.meliopayments.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="primary.500"
                  textDecor="none"
                >
                  <MIFormattedText label="vendors.upgradePayment.success.learnMore" />
                </Link>
              ),
            }}
          />
        </Box>
        <Button
          label="vendors.upgradePayment.success.cta"
          width="full"
          size={ButtonSizes.lg}
          isLoading={false}
          onClick={handleJoinMelioClick}
          mt={10}
          textStyle="body2Semi"
          analyticsProperties={{
            ...staticAnalyticsProps,
            flow: registrationFlow,
          }}
        />
      </Box>
    </PageWrapper>
  );
};
