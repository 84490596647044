import { useEffect, useState } from 'react';
import * as React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { VStack } from 'src/core/ds/stack';
import { useEnrollment } from 'src/pages/spend-management/hooks/useEnrollment';
import { analytics, Context, Event, Page, pathToPageNames } from 'src/pages/spend-management/SpendManagementAnalytics';
import { EnrollInProgressPage } from '../../EnrollInProgressPage';

export const EnrollEmptyState = ({ onEnrolled }: { onEnrolled: () => void }) => {
  const { onEnrollClick, loading, error, enrolling } = useEnrollment({ onEnrolled });

  const [currentPage, setCurrentPage] = useState<Page>(Page.ORG_SETUP_DONE);
  const { path } = useRouteMatch();
  useEffect(() => {
    setCurrentPage(pathToPageNames[path]);
  }, [path]);

  if (loading || error) return <AreaLoader />;

  if (enrolling) return <EnrollInProgressPage enableGoToDashboard={false} />;

  return (
    <VStack justifyContent="center" alignItems="center" w="full" data-testid="spend-management-enroll-empty-state">
      <Flex direction="column" alignItems="center" justifyContent="center" maxW="46rem">
        <Box mb={4} textAlign="center">
          <video
            data-component="Video"
            autoPlay
            src="https://platform-static.meliopayments.com/assets/melio/illustrations/sync-accounts.webm"
            loop
            width="144px"
          >
            <track kind="captions" />
          </video>
        </Box>
        <Box textStyle="body2Semi" textAlign="center">
          <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.enroll.title" />
        </Box>
        <Box mb={4} textAlign="center" textStyle="body3">
          <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.enroll.subtitle" />
        </Box>
        <Flex justifyContent="center" w="full">
          <Button
            label="spendManagement.pages.dashboard.emptyStates.enroll.buttonLabel"
            variant={ButtonVariants.primary}
            onClick={() => {
              analytics.track(currentPage, Context.EMPTY_STATE, Event.CLICK_ENROLL);
              onEnrollClick();
            }}
            size={ButtonSizes.lg}
            rightIcon={<Icon name={IconNames.linkOpen} size={IconSize.s} />}
          />
        </Flex>
      </Flex>
    </VStack>
  );
};
