import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { getOrgId } from 'src/redux/user/selectors';
import { useLinkAccountingPlatformAccount } from './useLinkAccountingPlatformAccount';

type CreateAndLinkAccountingPlatformAccountParams = {
  name: string;
  type: string;
  fundingSourceId: number;
  orgId?: string;
};

export const useCreateAndLinkAccountingPlatformAccount = () => {
  const currentOrgId = useSelector(getOrgId);

  const { onApiCall: createAccountingPlatformAccount, loading: isCreating } = useApi({
    api: accountingPlatformsApi.createAccountingPlatformAccount,
  });

  const { linkAccountingPlatformAccount, isAccountingPlatformAccountLinking } = useLinkAccountingPlatformAccount();

  const createAndLinkAccountingPlatformAccount = useCallback(
    async ({ name, type, fundingSourceId, orgId }: CreateAndLinkAccountingPlatformAccountParams) => {
      const newAccountingPlatformAccount = await createAccountingPlatformAccount(currentOrgId ?? orgId, {
        name,
        type,
      });

      await linkAccountingPlatformAccount({
        accountingPlatformAccountId: newAccountingPlatformAccount.remoteAccountId,
        fundingSourceId,
      });

      return newAccountingPlatformAccount;
    },
    [createAccountingPlatformAccount, linkAccountingPlatformAccount, currentOrgId]
  );

  const isAccountingPlatformAccountCreatingAndLinking = isAccountingPlatformAccountLinking || isCreating;

  return {
    createAndLinkAccountingPlatformAccount,
    isAccountingPlatformAccountCreatingAndLinking,
  };
};
