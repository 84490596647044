import { useState } from 'react';

const useBatchCustomerUpload = (onNext: () => void) => {
  const [customerCsvFile, setCustomerCsvFile] = useState<File | null>(null);
  const onExitBatchCustomer = (): void => {
    setCustomerCsvFile(null);
  };

  const onDoneBatchCustomer = (): void => {
    onNext();
    onExitBatchCustomer();
  };

  const onUploadBatchCustomer = (file: File): void => {
    setCustomerCsvFile(file);
  };

  return {
    customerCsvFile,
    onUploadBatchCustomer,
    onDoneBatchCustomer,
    onExitBatchCustomer,
  };
};

export default useBatchCustomerUpload;
