import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc';
import { profileStore } from 'src/modules/profile/profile-store';
import { getProfile, getUserPreferences } from 'src/redux/user/selectors';
import { LocalStorageKeys } from 'src/utils/consts';
import { getRedirectLoginUrl } from 'src/utils/user';

const IntuitConnectPageContainer = () => {
  const profile = useSelector(getProfile);
  const userPreferences = useSelector(getUserPreferences);
  const organizations = useSelector(profileStore.selectors.getOrganizations);

  const redirectUrl = getRedirectLoginUrl({
    orgId: profile.orgId,
    registrationFlow: profile.registrationFlow,
    userPreferences,
    organizations,
  });

  return <Redirect to={localStorage.getItem(LocalStorageKeys.INTUIT_RETURN_URL) || redirectUrl} />;
};

export default compose(withNavigator())(IntuitConnectPageContainer);
