import { ClientDeviceDataHeaderName, getDeviceDataHeader } from '@melio/risk-data-collection';
import request, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import curry from 'lodash/curry';
import config from 'src/config';
import { ServerError } from 'src/services/api/ServerError';
import { captureNetworkError } from 'src/utils/error-tracking';
import type { APIResponse } from 'src/utils/types';

let siteContextName = '';

// Setup axios-retry with default configuration
axiosRetry(request);

let authToken = null;

export function setAuthToken(token) {
  authToken = token;
}

type Params = Record<string, any> | null;
type Options = {
  isBypassThrowError?: boolean;
  catchCall?: boolean;
};
type Request = { url: string; params: Params; options: Options; method: 'get' | 'post' | 'delete' | 'put' | string };

const handleResponse = <Data>(request: Request) => (
  response: APIResponse<Data> & { json?: Record<string, unknown> }
) => {
  const { success, data, message, code, json } = response;

  if (!success) {
    throw new ServerError({ ...request, ...response });
  }

  return { ...data, ...json, message, code };
};

const handleException = curry((request, error) => {
  if (error?.response?.status >= 500) {
    captureNetworkError(error);
  }

  if (!request.options.isBypassThrowError) {
    if (error.response) {
      if (error.response.status === 403 && error.response?.data?.code === 'ATH28') {
        return;
      }

      throw new ServerError({ ...request, ...error.response.data });
    }

    throw error;
  }
});

const handleNoContent = (response) => {
  if (response.status === 204) {
    return {
      success: true,
      code: 'OK',
    };
  }

  return response.data;
};

type Headers = {
  Pragma: string;
  'x-site-context': string;
  Authorization?: string;
  [ClientDeviceDataHeaderName]: string;
};

function getHeaders(): Headers {
  const headers: Headers = {
    Pragma: 'no-cache',
    'x-site-context': siteContextName,
    ...getDeviceDataHeader(),
  };

  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  return headers;
}

export function initContextHeader(site: string) {
  siteContextName = site;
}

export function fetchRequest(
  url: string,
  params: Params = {},
  options: Options = { isBypassThrowError: false },
  conf: AxiosRequestConfig = {}
): Promise<any> {
  const req = { url, params, options, method: 'get' };

  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .get(`${config.server.baseUrl}/api${url}`, { params, ...conf })
    .then((response) => response.data)
    .then(handleResponse(req))
    .catch(handleException(req));
}

export function postRequest<Data>(
  url: string,
  params: Params = {},
  conf: AxiosRequestConfig = {},
  options: Options = { isBypassThrowError: false }
) {
  const req = { url, params, options, method: 'post' };

  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .post<APIResponse<Data>>(`${config.server.baseUrl}/api${url}`, params, conf)
    .then((response) => response.data)
    .then((response) =>
      options.catchCall && !response.success
        ? ({ message: response.message, success: true, code: response.code } as APIResponse<any>)
        : response
    )
    .then(handleResponse(req))
    .catch(handleException(req));
}

export function putRequest(url: string, params: Params = {}, conf: AxiosRequestConfig = {}): Promise<any> {
  const req = { url, params, options: {}, method: 'put' };

  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .put(`${config.server.baseUrl}/api${url}`, params, conf)
    .then((response) => response.data)
    .then(handleResponse(req))
    .catch(handleException(req));
}

export function patchRequest(url: string, params: Params = {}, conf: AxiosRequestConfig = {}): Promise<any> {
  const req = { url, params, options: {}, method: 'patch' };

  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .patch(`${config.server.baseUrl}/api${url}`, params, conf)
    .then((response) => response.data)
    .then(handleResponse(req))
    .catch(handleException(req));
}

export function deleteRequest(url: string, params: Params = {}): Promise<any> {
  const req = { url, params, options: {}, method: 'delete' };

  return request
    .create({
      headers: getHeaders(),
      withCredentials: true,
    })
    .delete(`${config.server.baseUrl}/api${url}`, { data: params })
    .then(handleNoContent)
    .then(handleResponse(req))
    .catch(handleException(req));
}
