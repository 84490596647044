import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { filesApi } from 'src/modules/files/api';
import { organizationsApi } from 'src/modules/organizations/api';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { CompanyInfoType } from 'src/utils/types';
import { withNavigator } from '../../hoc';
import { GlobalState } from '../../redux/types';
import { loadCompanyLogoAction, setCompanyInfoAction } from '../../redux/user/actions';
import { getCompanyInfo, getOrgId } from '../../redux/user/selectors';
import { UploadPicture } from '../common/UploadPicture';

// TODO: move to uploadCompanyLogoFile api
type UploadCompanyLogoFileResult = {
  file: {
    id: number;
  };
};

type MapStateToProps = {
  orgId: number;
  companyInfo: CompanyInfoType;
};

type MapDispatchToProps = {
  setCompanyInfo: (companyInfo: CompanyInfoType) => void;
  refreshCompanyLogo: (fileId: number) => void;
};

type Props = {
  eventPage: string;
  editable: boolean;
} & MapStateToProps &
  MapDispatchToProps;

type State = {
  isLoading: boolean;
};

class UploadCompanyLogo extends Component<Props, State> {
  readonly state: Readonly<State> = { isLoading: false };

  onLogoClick = () => {
    analytics.track(this.props.eventPage, 'clicked');
  };

  deleteCompanyLogo = () => {
    const { orgId, companyInfo, eventPage, setCompanyInfo } = this.props;
    organizationsApi
      .updateCompanyInfo(orgId, { logoId: null })
      .then(() => {
        analytics.track(eventPage, 'deleted');
        setCompanyInfo({ ...companyInfo, logoUrl: '', logoId: null });
      })
      .catch((error) => {
        capture(error);
      });
  };

  changeCompanyLogo = (file: File | '') => {
    const { orgId, companyInfo, setCompanyInfo, refreshCompanyLogo } = this.props;

    if (!file) return;

    this.setState({ isLoading: true });
    filesApi
      .uploadCompanyLogoFile(orgId, file)
      .then((result: UploadCompanyLogoFileResult) => {
        const fileId = result.file.id;

        organizationsApi
          .updateCompanyInfo(orgId, { logoId: fileId })
          .then(() => {
            setCompanyInfo({ ...companyInfo, logoId: fileId });
            refreshCompanyLogo(fileId);
            this.setState({ isLoading: false });
          })
          .catch(() => {
            this.setState({ isLoading: false });
          });
      })
      .catch(() => {
        this.setState({ isLoading: false });
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'serverErrors.ERR',
        });
      });
  };

  render() {
    const { logoUrl, companyName } = this.props.companyInfo;
    const { editable } = this.props;
    const { isLoading } = this.state;

    return (
      <UploadPicture
        onDeleteClick={this.deleteCompanyLogo}
        onFileChange={this.changeCompanyLogo}
        onAddClick={this.onLogoClick}
        editable={editable}
        isLoading={isLoading}
        pictureUrl={logoUrl}
        name={companyName ?? undefined}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
  companyInfo: getCompanyInfo(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  setCompanyInfo(companyInfo: CompanyInfoType) {
    dispatch(setCompanyInfoAction(companyInfo));
  },
  refreshCompanyLogo(fileId: number) {
    dispatch(loadCompanyLogoAction(fileId));
  },
});

export default compose(withNavigator(), connect(mapStateToProps, mapDispatchToProps))(UploadCompanyLogo);
