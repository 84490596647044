import styled from 'styled-components';
import { MILink } from 'src/components/common/MILink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import UploadCompanyLogo from 'src/components/layout/UploadCompanyLogo';
import * as WizardElements from 'src/components/layout/WizardElements';
import { devices } from 'src/theme/appDevices';
import { useVendorCompanyInfoConfig } from '../context/VendorCompanyInfoContext';

type Props = {
  onPrev?: () => void;
  goExit?: () => void;
  onNext: () => void;
  onSkip: () => void;
  isLoading?: boolean;
  editable: boolean;
  relativeStep: number | null | undefined;
};

export const VendorCompanyLogo = ({
  onNext,
  onPrev,
  onSkip,
  goExit,
  editable,
  relativeStep,
  isLoading = false,
}: Props) => {
  const { title, subTitle, nextLabel, skipLabel } = useVendorCompanyInfoConfig<'VendorCompanyLogo'>({
    page: 'VendorCompanyLogo',
  });

  return (
    <StepLayoutPage
      title={title}
      subtitle={subTitle}
      relativeStep={relativeStep}
      onPrev={onPrev}
      onNext={onNext}
      goExit={goExit}
      isLoading={isLoading}
      nextLabel={nextLabel}
      footer={
        <Footer>
          <WizardElements.WizardLinkContainer>
            <MILink to={onSkip} label={skipLabel} />
          </WizardElements.WizardLinkContainer>
        </Footer>
      }
    >
      <VendorCompanyLogoContent>
        <UploadCompanyLogo eventPage="vendor-company-logo" editable={editable} />
      </VendorCompanyLogoContent>
    </StepLayoutPage>
  );
};

const VendorCompanyLogoContent = styled.div``;

const Footer = styled.div`
  width: 100%;
  margin: 1.2rem 0;

  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 5.2rem;
  }
`;
