import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree, EventMappingType } from 'src/services/analytics/types';

const pageEvent = 'batch-invoices';

const createBatchExplainEventMapping = (): Record<string, EventMappingType> => ({
  'page.view': [pageEvent, 'batch-csv-explain'],
  'invoices.batchCsvExplain.action': [pageEvent, 'batch-explain-invoices-upload-csv'],
});
const createBatchEventMapping = (): Record<string, EventMappingType> => ({
  'page.view': [pageEvent, pageEvent],
  'progress.continue': [pageEvent, 'next-column'],
  'go.back': [pageEvent, 'go-back'],
  'go.exit': [pageEvent, 'go-exit'],
  'column.selected': [pageEvent, 'column-selected', { column: 'name' }],
  ...mapApiSlice('[INVOICES] CREATE_BATCH', pageEvent, 'create-batch-invoices'),
});

const MAPPING: EventMappingsTree = {
  '/orgs/*/get-paid/new/batch': createBatchEventMapping(),
  '/orgs/*/get-paid/new/batch-csv-explain': createBatchExplainEventMapping(),
};

export default MAPPING;
