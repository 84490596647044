/* eslint-disable dot-notation */
import config from 'src/config';
import { Braze } from 'src/services/engagement';
import { Analytics } from './analytics';

const engagementService = config.services.braze.enable ? new Braze(config) : null;

const analytics = new Analytics(() => window['analytics'], config, engagementService);

export { analytics };
