import { ManualAddressConfirmPage } from 'src/components/layout/ManualAddressConfirmPage';
import { ManualAddressInvalidPage } from 'src/components/layout/ManualAddressInvalidPage';
import { ManualAddress } from 'src/utils/consts';
import { ManualAddressType } from '../ManualAddressOptionsContainer';

type Props = {
  onAddressSelect: any;
  whitePageAddress: ManualAddressType;
  onEditAddress: () => void;
  selectedAddressId: ManualAddress;
  originalAddress: Record<string, any>;
  isValidAddress: boolean;
  whitePageValidationErrors?: Record<string, any>;
};

export const WhitePageAddressOptions = ({
  onAddressSelect,
  whitePageAddress,
  originalAddress,
  onEditAddress,
  selectedAddressId,
  isValidAddress,
  whitePageValidationErrors,
}: Props) => (
  <>
    {isValidAddress ? (
      <ManualAddressConfirmPage
        addressToConfirm={whitePageAddress}
        addressValidationErrors={whitePageValidationErrors || {}}
        originalAddress={originalAddress}
        onAddressSelect={onAddressSelect}
        onEditAddress={onEditAddress}
        selectedAddressId={selectedAddressId}
      />
    ) : (
      <ManualAddressInvalidPage originalAddress={originalAddress} onEditAddress={onEditAddress} />
    )}
  </>
);
