import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CompanyType, Role } from 'src/utils/consts';

export function useCanViewTeamScreen(): boolean {
  const currentUserOrg = useSelector(profileStore.selectors.getUserOrganization);
  const companyInfo = useSelector(getCompanyInfo);

  return (
    [Role.ADMIN, Role.OWNER].includes(currentUserOrg?.role) && companyInfo?.companyType === CompanyType.ACCOUNTING_FIRM
  );
}
