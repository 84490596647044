import { useBreak } from 'src/hoc';

const useIsSinglePage = () => {
  const device = useBreak();
  const isSingleView = device.isMobile || device.isPhablet;

  return [isSingleView] as const;
};

export default useIsSinglePage;
