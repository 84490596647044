import last from 'lodash/last';
import { ChangeEvent, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchInput } from 'src/core/ds/input/searchInput/SearchInput';
import { useListSearchBarContext } from 'src/hoc/withListSearchBarContext';
import { analytics } from 'src/services/analytics';
import { isEnterPressed, isEscPressed } from 'src/utils/events';
import { useQueryString } from 'src/utils/hooks';

type Props = {
  hideSearchInput?: () => void;
  shouldDisabledSearch?: boolean;
};

export const SearchInputContainer = ({ hideSearchInput, shouldDisabledSearch }: Props) => {
  const location = useLocation();
  const eventPage = last(location.pathname.split('/'));
  const { status } = useQueryString<{ status: string }>();
  const [
    { contextSearchInputValue, setContextSearchFilterValue, setContextSearchInputValue, clearSearchContext },
  ] = useListSearchBarContext();

  const onSubmit = () => {
    analytics.trackAction(`search-in-${eventPage}`, { searchValue: contextSearchInputValue, tabName: status });
    setContextSearchFilterValue(contextSearchInputValue);
  };

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setContextSearchInputValue(searchValue);

    if (searchValue) {
      setContextSearchInputValue(searchValue);
    } else {
      onClear();
    }
  }, []);

  const onKeyDown = (event: React.KeyboardEvent) => {
    const element = event.target as HTMLElement;

    if (isEscPressed(event)) {
      event.preventDefault();

      if (contextSearchInputValue) {
        element.blur();
      } else {
        hideSearchInput && hideSearchInput();
      }
    }

    if (isEnterPressed(event)) {
      element.blur();
    }
  };

  const onClear = () => {
    clearSearchContext();
  };

  return (
    <SearchInput
      placeholder="list.search"
      onSubmit={onSubmit}
      onChange={onChange}
      onClear={onClear}
      value={contextSearchInputValue}
      onKeyDown={onKeyDown}
      isDisabled={shouldDisabledSearch}
      autoFocus
      hideIcon
    />
  );
};
