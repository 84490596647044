import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';

const SuggestedBillersOptionGroupLabel = ({ label }: { label: string }) => (
  <Flex pl={4} h="5.6rem" align="center">
    <Box textStyle="body4Uppercase" color="grey.600">
      <MIFormattedText label={label} />
    </Box>
  </Flex>
);

export default SuggestedBillersOptionGroupLabel;
