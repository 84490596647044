import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { CheckProtectionModal } from 'src/components/common/CheckProtectionModal';
import { MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Link } from 'src/core/ds/link';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { useModal } from 'src/helpers/react/useModal';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import { profileStore } from 'src/modules/profile/profile-store';
import { ChecksDelaysTooltip } from 'src/pages/bill/pay/components/ChecksDelays/ChecksDelaysTooltip';
import { useInternationalDetails } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalDetails';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getDeliveryMethodByType, getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { getDeliveryMethodIcon, getDeliveryMethodOptions, getUpdatedByLabel } from 'src/pages/vendor/utils';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import {
  ButtonsDirections,
  ChecksDelaysTooltipTypes,
  DeliveryType,
  DialogVariants,
  FeatureFlags,
  FULL_STORY_MASK_RULE_CLASS,
  NON_EDITABLE_DELIVERY_METHOD_TYPES,
} from 'src/utils/consts';
import { DeliveryMethodType, UserContextType, VendorType } from 'src/utils/types';

type Props = {
  vendor: VendorType;
  profile: UserContextType;
  users: readonly UserContextType[];
  goEditDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  sendUnilateralRequest: () => void;
};

const eventPage = 'contacts';

export const VendorDeliveryMethodsList = ({
  vendor,
  profile,
  users,
  goEditDeliveryMethod,
  sendUnilateralRequest,
}: Props) => {
  const ach = getDeliveryMethodByType(DeliveryType.ACH, vendor.deliveryMethods);
  const companyInfo = useSelector(getCompanyInfo);
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const { isInternationalAllowed } = useIsInternationalAllowed(vendor.id);
  const { blockEmailForInternationalPayment } = useInternationalDetails();
  const isInternationalEnabled =
    isInternationalAllowed && !blockEmailForInternationalPayment && isEmpty(vendor.deliveryMethods);
  const deliveryMethodOptions = getDeliveryMethodOptions({
    deliveryMethods: vendor.deliveryMethods,
    isInternationalEnabled,
    vendor,
  });
  const isSingleDeliveryMethod = deliveryMethodOptions.length === 1;
  const deliveryMethodLinkMapping = {
    'virtual-card': 'https://melio.zendesk.com/hc/en-us/sections/5735799380636-Single-use-virtual-cards',
  };
  const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.USHolidaysChecks, false);
  const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);
  const [CheckProtection, showCheckProtectionModal] = useModal(CheckProtectionModal, {});
  const checkDelaysTooltipLabel = isGeneralCheckDelays
    ? 'bills.pay.date.checksDelays.general.tooltip'
    : 'bills.pay.date.checksDelays.tooltipGeneral';

  const sendUnilateralRequestFromDeliveryMethods = () => {
    analytics.track(eventPage, 'request-payment-details', {
      userId,
      vendorId: vendor?.id,
      vendorEmail: vendor?.contactEmail,
      buttonType: 'delivery-methods',
    });
    sendUnilateralRequest();
  };

  const getActionOptions = (dm) => {
    if (dm.deliveryType === DeliveryType.VIRTUAL) {
      return [
        {
          label: 'deliveryMethods.actions.resendRequest',
          action: () => sendUnilateralRequestFromDeliveryMethods(),
        },
      ];
    }

    if (dm.deliveryType === DeliveryType.ACH && dm.isFilledByVendor) {
      return [
        {
          label: 'deliveryMethods.actions.replace',
          action: () => showReplaceDetailsModal(),
        },
      ];
    }

    return [
      {
        label: 'deliveryMethods.actions.edit',
        action: () => goEditDeliveryMethod(dm),
      },
    ];
  };

  const [ReplaceDetailsModal, showReplaceDetailsModal] = useModal(CommonDialog, {
    confirm: async () => goEditDeliveryMethod(ach),
    title: 'deliveryMethods.replaceDetailsModal.title',
    description: 'deliveryMethods.replaceDetailsModal.description',
    confirmText: 'deliveryMethods.replaceDetailsModal.confirm',
    variant: DialogVariants.WARNING,
    buttonsDirection: ButtonsDirections.HORIZONTAL,
    textValues: {
      companyName: vendor.companyName,
      styledCompanyName: (
        <Box as="span" color="grey.700">
          {vendor.companyName}
        </Box>
      ),
    },
    modalName: 'replaceDetailsModal',
  });

  const getUpdatedByText = (dm: DeliveryMethodType) => {
    if (dm.isFilledByVendor || dm.deliveryType === DeliveryType.VIRTUAL_CARD) {
      return vendor.companyName;
    }

    if (dm.createdById === profile.id) {
      return 'you';
    }

    const foundUser = users?.find((u) => u.id === dm.createdById);

    if (foundUser) {
      return `${foundUser.firstName} ${foundUser.lastName}`;
    }

    return '';
  };

  const renderEmptyState = (dm: DeliveryMethodType) => {
    if (!dm.id) {
      if (dm.deliveryType === DeliveryType.VIRTUAL) {
        return (
          <Button
            size={ButtonSizes.sm}
            onClick={sendUnilateralRequestFromDeliveryMethods}
            label="deliveryMethods.actions.sendRequest"
            textStyle="caption1"
            px={4}
          />
        );
      }

      return (
        <MIInlineLink
          onClick={() => goEditDeliveryMethod(dm)}
          testId={`deliveryMethod-add-${dm.deliveryType}`}
          label="deliveryMethods.actions.addMethod"
        />
      );
    }

    return null;
  };

  const getNoDeliveryMethodText = (
    deliveryType: DeliveryType
  ): { description: string; placeholder: string; feeAmount?: string } => {
    const defaultUnsetDmTextContent = {
      description: `deliveryMethods.notDefinedInfo.${deliveryType}.desc`,
      placeholder: `deliveryMethods.notDefinedInfo.${deliveryType}.placeholder`,
    };

    if (deliveryType === DeliveryType.CHECK) {
      const achToCheckFee = companyInfo?.billingSetting?.fee['ach-to-check']?.value.toFixed(2) || undefined;

      return {
        description: achToCheckFee
          ? 'deliveryMethods.notDefinedInfo.check.feeDesc'
          : defaultUnsetDmTextContent.description,
        placeholder: achToCheckFee
          ? 'deliveryMethods.notDefinedInfo.check.feePlaceholder'
          : defaultUnsetDmTextContent.placeholder,
        feeAmount: achToCheckFee,
      };
    }

    return defaultUnsetDmTextContent;
  };

  const totalFreeChecks = companyInfo?.totalFreeChecksPerMonth;

  return (
    <MICardForm>
      {ReplaceDetailsModal}
      {CheckProtection}
      <MICardTitle
        label={isSingleDeliveryMethod ? 'vendors.deliveryMethods.singleTitle' : 'vendors.deliveryMethods.title'}
      />
      <Flex data-testid="delivery-methods-list" direction="column" mt={5}>
        {deliveryMethodOptions.map((dm) => {
          const isVirtualDM = dm.deliveryType === DeliveryType.VIRTUAL;
          const isCheckDM = dm.deliveryType === DeliveryType.CHECK;
          const isPaperCheckDataExist = !!dm.paperCheck;
          const showChecksDelaysTooltip = isChecksDelaysEnabled && isCheckDM;
          const isRPPSDeliveryMethod = dm.deliveryType === DeliveryType.RPPS;
          const isInternationalDM = isInternationalDeliveryMethod(dm.deliveryType);
          const isVirtualCardDM = dm.deliveryType === DeliveryType.VIRTUAL_CARD;
          const showActionsMenu =
            dm.id && !NON_EDITABLE_DELIVERY_METHOD_TYPES.includes(dm.deliveryType) && !vendor.managedBy;
          const showPlaceHolder = !dm.id || (dm.id && isRPPSDeliveryMethod);
          const showUpdatedBy = dm.id && !isInternationalDM && !isRPPSDeliveryMethod && !vendor.managedBy;
          const actionOptions = getActionOptions(dm);
          const { description, placeholder, feeAmount } = getNoDeliveryMethodText(dm.deliveryType);

          return (
            <Flex
              key={dm.deliveryType}
              data-testid={`deliveryMethod-container-${dm.deliveryType}`}
              w="full"
              borderRadius="lg"
              border="1px"
              borderColor="grey.400"
              bgColor="white"
              p={5}
              boxSizing="border-box"
              direction="column"
              mt={3}
              transition="300ms"
            >
              <Flex justify="space-between">
                <Flex align="center">
                  {isVirtualCardDM ? (
                    <Mastercard width="2.4rem" style={{ padding: '0 2rem 0 0.4rem' }} />
                  ) : (
                    <Box
                      as="i"
                      className={getDeliveryMethodIcon(dm)}
                      display={getDeliveryMethodIcon(dm) ? 'block' : 'none'}
                      pl={1}
                      pr={5}
                      textStyle="h2"
                    />
                  )}
                  <div>
                    <Box textStyle="body2Semi" color="black" mb={isVirtualDM ? 0 : 1}>
                      <span data-testid={`deliveryMethods-notDefinedInfo-${dm.deliveryType}-title`}>
                        <MIFormattedText label={`deliveryMethods.notDefinedInfo.${dm.deliveryType}.label`} />
                      </span>
                      {showChecksDelaysTooltip && (
                        <ChecksDelaysTooltip
                          tooltipLabel={checkDelaysTooltipLabel}
                          type={
                            isPaperCheckDataExist
                              ? ChecksDelaysTooltipTypes.WARNING
                              : ChecksDelaysTooltipTypes.INFO_DEFAULT
                          }
                        />
                      )}
                    </Box>
                    {!isVirtualDM && (
                      <Box color="grey.600" textStyle="body4">
                        <MIFormattedText
                          label={description}
                          values={{
                            amount: feeAmount,
                            freeChecks: totalFreeChecks,
                          }}
                        />
                      </Box>
                    )}
                  </div>
                </Flex>
                {showActionsMenu && (
                  <Box mt={2}>
                    <Menu autoSelect={false}>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<Icon name={IconNames.more} />}
                        variant="action"
                        placement="top"
                        data-testid={`deliveryMethod-dots-menu-${dm.deliveryType}`}
                      />
                      <MenuList>
                        {actionOptions.map(({ label, action }) => (
                          <MenuItem key={label} label={label} onClick={action} />
                        ))}
                      </MenuList>
                    </Menu>
                  </Box>
                )}
                {renderEmptyState(dm)}
              </Flex>
              {!isVirtualDM && <Box h="0.1rem" bgColor="grey.400" mt={5} mb={4} />}
              <Box
                as="p"
                data-testid={`deliveryMethod-type-${dm.deliveryType}`}
                className={FULL_STORY_MASK_RULE_CLASS}
                m={0}
                color={showPlaceHolder ? 'grey.600' : 'black'}
                textStyle="caption1"
                isTruncated
              >
                {dm.id &&
                  (isVirtualDM ? (
                    <MIFormattedText
                      label={`deliveryMethods.definedInfo.${dm.deliveryType}.desc`}
                      values={{
                        date: <MIFormattedDate date={dm.updatedAt} />,
                      }}
                    />
                  ) : (
                    getDeliveryMethodDisplay({
                      deliveryMethod: dm,
                      vendorName: vendor.companyName,
                      managedBy: vendor?.managedBy,
                    })
                  ))}
                {showPlaceHolder && (
                  <>
                    <MIFormattedText label={placeholder} values={{ email: dm?.virtualCardAccount?.accountEmail }} />
                    {isCheckDM && (
                      <Box
                        data-testid="check-dm-placeholder-more-info"
                        as="span"
                        textStyle="link4"
                        marginInline={0.5}
                        onClick={showCheckProtectionModal}
                      >
                        <MIFormattedText label="deliveryMethods.notDefinedInfo.check.feePlaceholderAction" />
                      </Box>
                    )}
                  </>
                )}
              </Box>
              {showUpdatedBy && (
                <Box color="grey.600" textStyle="caption1" mt={0.5}>
                  <MIFormattedText
                    label={getUpdatedByLabel(dm)}
                    values={{
                      date: <MIFormattedDateTime date={dm.updatedAt} />,
                      displayName: getUpdatedByText(dm),
                      learnMore: (
                        <Link href={deliveryMethodLinkMapping[dm.deliveryType]} target="blank">
                          <MIFormattedText label={`deliveryMethods.notDefinedInfo.${dm.deliveryType}.learnMore`} />
                        </Link>
                      ),
                    }}
                  />
                </Box>
              )}
            </Flex>
          );
        })}
      </Flex>
    </MICardForm>
  );
};
