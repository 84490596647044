import styled from 'styled-components';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import { DrawerBody, DrawerHeader } from 'src/core/ds/drawer';
import { AmountCurrencyField, TextField } from 'src/core/ds/form/fields';
import IconButton from 'src/core/ds/iconButton';
import { HStack, VStack } from 'src/core/ds/stack';
import { Switch } from 'src/core/ds/switch';
import amexCardBlank from 'src/images/spend-management/amex-card-blank.png';

export const CardNameEyeContainer = styled(HStack)`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  margin-top: 8.5rem;
`;

export const CardNameContainer = styled(Box)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
`;

export const VirtualCard = styled(VStack)<{ disabled?: boolean }>`
  background: ${() => `url(${amexCardBlank})`} no-repeat;
  background-size: cover;
  width: 338px;
  min-height: 200px;
  padding: 16px;
  color: #ffffff;
  justify-content: flex-end;
  align-items: flex-start;
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const EyeIconContainer = styled.div`
  width: 40px;
  height: 40px;
  background: #ffffff;
  display: flex;
  align-content: center;
  justify-content: center;
  border: 1px solid #dedee3;
  border-radius: 8px;
  button {
    height: 100%;
    width: 100%;
    color: #666a79;
  }
`;

export const Icon = styled(IconButton)<{ disabled?: boolean }>`
  color: #fff;
  opacity: 0;
  cursor: ${({ disabled }) => (disabled ? 'hand' : 'pointer')};
  &:hover {
    color: #fff;
  }
`;

export const FieldWithCopy = styled(HStack)`
  width: 100%;
  button {
    opacity: 1;
  }
`;

export const CardDetailsContainer = styled(HStack)`
  width: 100%;
  display: grid;
  column-gap: 22px;
`;

export const DetailsSection = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: 2.4rem;
  &:last-of-type {
    padding-bottom: 2.4rem;
  }
`;

export const DetailsSectionTitle = styled.div`
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding-bottom: 0.5rem;
`;

export const CardDetailsSwitch = styled(Switch)`
  margin: 1rem 0 1.5rem;
`;

export const DetailsSectionValue = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.text.fontType.regular};
  color: ${(props) => props.theme.colors.text};
  font-weight: ${(props) => props.theme.text.weight.regular};
  button {
    opacity: 1;
    margin-left: 0.5rem;
  }
`;

export const CardTextInputField = styled(TextField)`
  margin-bottom: 0;
  input {
    ${(props) => props.theme.text.fontType.regular};
    color: ${(props) => props.theme.text.color.main};
    border-bottom: 1px solid ${(props) => props.theme.colors.dark.opaque};
  }
  div {
    ${(props) => props.theme.text.fontType.hint};
  }
`;

export const CardLimitInputField = styled(AmountCurrencyField)`
  margin-bottom: 0;
  input {
    ${(props) => props.theme.text.fontType.regular};
    color: ${(props) => props.theme.text.color.main};
    border-bottom: 1px solid ${(props) => props.theme.colors.dark.opaque};
  }
  div {
    ${(props) => props.theme.text.fontType.hint};
  }
`;

export const Body = styled(DrawerBody)`
  && {
    padding: 40px;
    max-width: 50rem;
    margin: 0;
  }
`;

export const Header = styled(DrawerHeader)`
  && {
    padding: 20px;
  }
`;

export const FullWidthButton = styled(Button)`
  flex-grow: 1;
`;
