const BASE_LOCATION = '/vendors/candidates/:token';

export const vendorCandidatesLocations = {
  base: [BASE_LOCATION],
  index: BASE_LOCATION,
  details: `${BASE_LOCATION}/details`,
  success: `${BASE_LOCATION}/success`,
  alreadyFilled: `${BASE_LOCATION}/already-filled`,
  invalid: `${BASE_LOCATION}/invalid`,
};
