import get from 'lodash/get';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ScrollToTop } from 'src/components/layout/ScrollToTop';
import { withPreservedStateNavigator } from 'src/hoc';
import { useNewTabapayUrl, UseNewTabapayUrlReturnType } from 'src/hooks/useNewTabapayUrl';
import { withHook } from 'src/hooks/withHook';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { extractDataFromTabapayEvent } from 'src/pages/onboarding/funding-sources/card/add-card/tabapay/utils';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { GlobalState } from 'src/redux/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { capture } from 'src/utils/error-tracking';
import locations from 'src/utils/locations';
import AddCardAccountsPage from './AddCardAccountsPage';

type MapStateToProps = {
  orgId: number;
};

type Props = {
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateToExitWithPreservedState: (dataToAdd?: Record<string, any>) => void | null | undefined;
  locationState: Record<string, any>;
} & MapStateToProps &
  UseNewTabapayUrlReturnType;

type State = {
  isLoading: boolean;
  errorCode: string | null | undefined;
};

const eventPage = 'payment-method-credit-card';

/**
 * @note not in use yet.
 * once we have a step for selecting a bank, we should move here some of the logic of FundingSourcePageContainer
 */

class AddCardAccountsPageContainer extends PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
      errorCode: null,
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.pfReceivedMessage, false);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.pfReceivedMessage, false);
  }

  onHideErrorMessageAlert = () => {
    this.setState({ errorCode: null });
  };

  shouldSkipReceivedMessage = (event) => {
    const eventOrigin = get(event, 'origin', '');
    const eventData = get(event, 'data', undefined);
    const isOriginMatchIframeSrc = this.props.iframeSrc.startsWith(eventOrigin);
    const isEventDataTypeString = typeof eventData === 'string';

    return !isOriginMatchIframeSrc || !isEventDataTypeString;
  };

  pfReceivedMessage = (event) => {
    try {
      // If event origin is not from TabaPay's iframe or event or data is not a string, skip this part
      if (this.shouldSkipReceivedMessage(event)) return;

      if (event.data === 'Close') this.navigateOnError();

      if (event.data.slice(0, 7) === 'Error: ') {
        analytics.track('taba-pay', 'iframe-error-response');
        this.navigateOnError();
      }

      analytics.track('taba-pay', 'iframe-response');

      const [digitsAndBin, expiration, token] = event.data.split('|');
      analytics.track('taba-pay', 'iframe-response-data');

      if (!token) this.navigateOnError();

      const { digits, cardBin, expirationMonth, expirationYear } = extractDataFromTabapayEvent(
        digitsAndBin,
        expiration
      );
      this.setState({ isLoading: true });
      financialAccountsApi
        .checkCard(this.props.orgId, { token, cardBin })
        .then(() => {
          this.setState({ isLoading: false });
          analytics.track('taba-pay', 'iframe-response-success');

          this.props.navigate(onboardingLocations.fundingSources.card.cardholder, false, {
            orgId: this.props.orgId,
            token,
            digits,
            expiration: `${expirationMonth}/${expirationYear}`,
            redirectUrl: get(this.props, 'location.state.redirectUrl'),
            cardBin,
          });
        })
        .catch((err) => {
          this.setState({
            errorCode: get(err, 'code') || get(err, 'response.status'),
            isLoading: false,
          });
        });
    } catch (error: any) {
      analytics.track('taba-pay', 'iframe-error-response');
      capture(error, {
        event: JSON.stringify(event.data),
        message: 'taba-pay iframe-error-response',
      });
      this.navigateOnError();
    }
  };

  navigateOnError = () => {
    if (this.props.navigateToExitWithPreservedState) {
      this.props.navigateToExitWithPreservedState();
    } else {
      this.props.navigate(onboardingLocations.fundingSources.index);
    }
  };

  goNext = () => {
    const { locationState } = this.props;

    analytics.track(eventPage, 'link-credit-card', { vaulting: 'taba-pay' });
    this.props.navigate(onboardingLocations.fundingSources.card.cardholder, false, {
      ...locationState.preservedState,
    });
  };

  goExit = () => {
    analytics.track(eventPage, 'exit', { vaulting: 'taba-pay' });

    if (this.props.navigateToExitWithPreservedState) {
      this.props.navigateToExitWithPreservedState(this.props.locationState);
    } else {
      this.props.navigate(locations.MainApp.dashboard.url());
    }
  };

  loadIframe = () => this.setState({ isLoading: false });

  loadIframeError = () => {
    analytics.track('taba-pay', 'iframe-error-response');
    this.navigateOnError();
    const error = new Error('iframe-error-response-test');
    capture(error, 'taba-pay iframe-error-response');
  };

  render() {
    const { isLoading, errorCode } = this.state;

    return (
      <ScrollToTop>
        <AddCardAccountsPage
          loadIframe={this.loadIframe}
          goNext={this.goNext}
          goExit={this.goExit}
          isLoading={isLoading}
          errorCode={errorCode}
          onError={this.loadIframeError}
          onHideErrorMessageAlert={this.onHideErrorMessageAlert}
        />
      </ScrollToTop>
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

export default compose(
  withHook()(useNewTabapayUrl),
  withPreservedStateNavigator(),
  connect(mapStateToProps)
)(AddCardAccountsPageContainer);
