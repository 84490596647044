import { featureFlags } from '@melio/shared-web';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { FeatureFlags, SyncStatus } from 'src/utils/consts';

type InvoiceGenerationEnabled = {
  isInvoiceGenerationEnabled: boolean;
  shouldGenerateInvoiceFile: boolean;
};

export const useInvoiceGenerationEnabled = (): InvoiceGenerationEnabled => {
  const [isInvoiceGenerationFeatureFlagEnabled] = featureFlags.useFeature(FeatureFlags.InvoiceGeneration, false);
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();

  const isInvoiceGenerationEnabled = Boolean(
    isInvoiceGenerationFeatureFlagEnabled &&
      connectedAccountingPlatform?.invoiceGenerationEnabled &&
      connectedAccountingPlatform?.syncStatus === SyncStatus.SYNCED
  );

  const shouldGenerateInvoiceFile = Boolean(connectedAccountingPlatform?.shouldGenerateInvoiceFile);

  return {
    isInvoiceGenerationEnabled,
    shouldGenerateInvoiceFile,
  };
};
