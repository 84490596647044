import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import { ApprovalWorkflowType } from 'src/utils/types';

export function useFetchApprovalWorkflows(orgId?: number) {
  const approvalWorkflowActions = useStoreActions(approvalWorkflowsStore);
  const approvalWorkflows: ApprovalWorkflowType[] = useSelector(approvalWorkflowsStore.selectors.list.value);
  const approvalWorkflowsLoading = !!useSelector(approvalWorkflowsStore.selectors.list.status())?.loading;

  useEffect(() => {
    if (orgId) {
      approvalWorkflowActions.list({ orgId });
    }
  }, [orgId, approvalWorkflowActions]);

  return { approvalWorkflows, approvalWorkflowsLoading };
}
