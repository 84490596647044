import { Flex, Image } from '@chakra-ui/react';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import RegistrationTermsAndConditions from 'src/components/onboarding/RegistrationTermsAndConditions';
import Box from 'src/core/ds/box';
import { Logo } from 'src/images/Logo';

interface CardWithSideImageLayoutProps {
  children?: React.ReactNode;
  textValues?: Record<string, any>;
  title?: string;
  subtitle?: string;
  image?: string;
  imageCaption?: string;
}

export function CardWithSideImageLayout({
  children,
  textValues,
  title,
  subtitle,
  image,
  imageCaption,
}: CardWithSideImageLayoutProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="safe center"
      direction="column"
      width="100%"
      height="100%"
      overflow="auto"
      sx={{
        '@media(min-width:600px)': {
          backgroundColor: '#EEF1F6',
        },
        backgroundColor: '#FFFFFF',
      }}
    >
      <Flex
        backgroundColor="#FFFFFF"
        boxSizing="border-box"
        maxWidth="1174px"
        direction="row"
        sx={{
          padding: '16px',
          width: '100%',
          borderRadius: '0',
          height: '100%',
          margin: '16px',
          '@media(min-width:600px)': {
            width: '488px',
            height: 'auto',
            marginTop: '60px',
            padding: '32px',
            borderRadius: '24px',
            '.terms-condensed': {
              marginTop: '72px',
            },
          },
          '@media(min-width:905px)': {
            margin: '60px 48px',
            padding: '12px',
            height: 'inherit',
            width: 'calc(100% - 96px)',
            maxHeight: '700px',
          },
          '.terms-condensed': {
            marginBottom: 0,
          },
        }}
      >
        <Flex
          sx={{
            width: '100%',
            '@media(min-width:905px)': {
              width: '50%',
              padding: '14px 40px',
              boxSizing: 'border-box',
            },
          }}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Logo />
          <Flex
            direction="column"
            width="100%"
            sx={{
              '@media(min-width:905px)': {
                maxWidth: '380px',
                marginTop: 'auto',
              },
              maxWidth: '488px',
            }}
            alignItems="center"
          >
            <Box marginBottom="40px" marginTop="40px">
              <Box
                textAlign="center"
                marginBottom="8px"
                color="black"
                sx={{
                  textStyle: 'h1Semi',
                  '@media(min-width:905px)': {
                    textStyle: 'h2Semi',
                  },
                }}
              >
                <MIFormattedText label={title} values={textValues} />
              </Box>
              <Box textStyle="body2" textAlign="center" color="#4A505B">
                <MIFormattedText label={subtitle} values={textValues} />
              </Box>
            </Box>
            <Box width="100%">{children}</Box>
          </Flex>
          <Box
            textStyle="body4"
            color="#6A7281"
            sx={{
              marginTop: 'auto',
            }}
          >
            <RegistrationTermsAndConditions
              label="auth.signUpCondensed.termsAndConditions"
              className="terms-condensed"
            />
          </Box>
        </Flex>
        <Flex
          sx={{
            '@media(min-width:905px)': {
              width: '50%',
              display: 'inherit',
            },
            width: 0,
            display: 'none',
          }}
          direction="column"
          backgroundColor="#EEF1F6"
          height="100%"
          borderRadius="16px"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={image} width="380px" />
          <Box
            textStyle="body3"
            color="#6A7281"
            marginTop="48px"
            width="380px"
            padding="0 30px"
            boxSizing="border-box"
            textAlign="center"
          >
            <MIFormattedText label={imageCaption} />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
