import { featureFlags } from '@melio/shared-web';
import * as React from 'react';
import { withTheme } from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { getCashBackCoupon, getCouponAmount } from 'src/helpers/coupons';
import { useModal } from 'src/helpers/react/useModal';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useGuestPaymentAnalytics } from 'src/pages/meliome/guest/components/hooks/useGuestPaymentAnalytics';
import { useMapRequestStatusToTracker } from 'src/pages/meliome/guest/components/hooks/useMapRequestStatusToTracker';
import { WhyMelioModal } from 'src/pages/meliome/guest/components/modals/WhyMelioModal';
import { PayWithInfo } from 'src/pages/meliome/guest/components/PayWithInfo';
import { ThemeType } from 'src/theme/global-theme';
import { getStatusInfo } from 'src/utils/bill-details';
import { CreditCardFeePayment, FeatureFlags, PaymentStatus } from 'src/utils/consts';
import { CouponType, PaymentRequestOverrides, PaymentRequestType } from 'src/utils/types';
import GuestInvalidPaymentRequest from './GuestInvalidPaymentRequest';
import GuestPaymentRequestTracker from './GuestPaymentRequestTrackerPage';
import { InvoiceAttachmentPreview } from './InvoiceAttachmentPreview';
import { InvoicePreview } from './InvoicePreview';
import { MelioMeCardVendorInvoiceInfo } from './MelioMeCardVendorInvoiceInfo';
import { PaymentRequestDetails } from './PaymentRequestDetails';
import { newLandingPage, whyPayLink } from './styles';

export type PaymentRequestStatus = {
  created?: Date;
  scheduled?: Date;
  failed?: Date;
  delivered?: Date;
  processed?: Date;
  deliveryETA?: Date;
  failedStage?: string;
};

type Props = {
  companyName: string;
  contactName: string;
  note: string;
  logoUrl: string;
  totalAmount: string;
  invoiceNumber: string;
  onNext: () => void;
  dueDate: Date;
  goDashboard: () => void;
  onSignUpClick: () => void;
  goToAccount: () => void;
  showMelioMeCard: boolean;
  isNextDisabled: boolean;
  validationErrors: Record<string, any>;
  invoiceName: string;
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  paymentRequestStatus: PaymentRequestStatus;
  isActionRegister: boolean;
  theme: ThemeType;
  blockCCMoneyIn: boolean;
  promotionName: string;
  payeeEmail?: string;
  coupons: CouponType[] | null;
  paymentRequest: PaymentRequestType;
  overrides?: PaymentRequestOverrides;
};

const PAGE_NAME = 'payor_payment';
const EVENT_NAME = 'request-more-info';

const GuestPaymentRequestInfoPage = ({
  companyName,
  contactName,
  logoUrl,
  dueDate,
  note,
  goDashboard,
  totalAmount,
  invoiceNumber,
  onNext,
  showMelioMeCard,
  isNextDisabled,
  validationErrors,
  fileStorageUrl,
  filePreviewUrls,
  invoiceName,
  paymentRequestStatus,
  isActionRegister,
  theme,
  onSignUpClick,
  goToAccount,
  blockCCMoneyIn,
  promotionName,
  payeeEmail,
  coupons,
  paymentRequest,
  overrides,
}: Props) => {
  const [isLandingPageWhyMelio] = featureFlags.useAnonymousFeature(
    FeatureFlags.GuestPayorFlowLandingPageWhyMelio,
    false
  );
  const [WhyPayMelioModal, showWhyPayMelioModal] = useModal(WhyMelioModal, {
    vendorName: companyName,
    onDismiss: (result) => {
      if (result === 'pay') {
        analytics.trackEvent(PAGE_NAME, `${EVENT_NAME}-continue-pay-success`);
        onNext();
      } else {
        analytics.trackEvent(PAGE_NAME, `${EVENT_NAME}-close-success`);
      }
    },
  });
  const analytics = useGuestPaymentAnalytics(paymentRequest);
  const stages = useMapRequestStatusToTracker(paymentRequestStatus, { companyName });
  const { GuestCardLayout } = useSiteContext().components;
  let currentPaymentStatus = PaymentStatus.SCHEDULED;
  let statusTextLabel = 'guests.progressPayment.scheduled';
  let statusInfoTextLabel = 'guests.progressPayment.statusText';

  if (paymentRequestStatus.failed) {
    currentPaymentStatus = PaymentStatus.FAILED;
    statusInfoTextLabel = 'guests.progressPayment.statusFailed';
  } else if (paymentRequestStatus.delivered) {
    currentPaymentStatus = PaymentStatus.COMPLETED;
    statusTextLabel = 'guests.progressPayment.delivered';
  } else if (paymentRequestStatus.processed) {
    currentPaymentStatus = PaymentStatus.PROCESSED;
    statusTextLabel = 'guests.progressPayment.processed';
  }

  const isVendorAbsorbedFee = paymentRequest?.feesPaidBy === CreditCardFeePayment.VENDOR;
  const coupon = getCashBackCoupon(coupons);
  const couponAmount = getCouponAmount(coupon);
  const paymentHint = overrides ? 'guests.paymentRequest.overrides.paymentHint' : 'guests.paymentRequest.paymentHint';
  const noteValue = overrides ? overrides.message : note;
  const noteLabel = overrides ? 'guests.paymentRequest.overrides.note' : 'guests.paymentRequest.note';
  const statusText = <MIFormattedText label={statusTextLabel} />;
  const statusInfoText = <MIFormattedText label={statusInfoTextLabel} values={{ statusText }} />;
  const statusInfo = paymentRequestStatus.scheduled
    ? getStatusInfo({ status: currentPaymentStatus }, theme, undefined, false, true)
    : null;

  if (statusInfo) {
    if (currentPaymentStatus === PaymentStatus.FAILED) {
      statusInfo.description = 'guests.progressPayment.descriptionToFailedPayment';
    }

    if (currentPaymentStatus === PaymentStatus.COMPLETED) {
      statusInfo.label = 'guests.progressPayment.labelPaid';
    }

    if (currentPaymentStatus === PaymentStatus.PROCESSED) {
      statusInfo.label = 'guests.progressPayment.labelProcessed';
    }
  }

  const onWhyPayLinkClick = () => {
    analytics.trackEvent(PAGE_NAME, `${EVENT_NAME}-click-success`);
    showWhyPayMelioModal();
  };

  return (
    <Box __css={isLandingPageWhyMelio ? newLandingPage({ noteValue }) : undefined}>
      {WhyPayMelioModal}
      {coupon && (
        <Flex w="full" h="4rem" justify="center" align="center" bgColor="green.400" textStyle="body3">
          <Box as="span">
            <MIFormattedText
              label="coupons.banner.title"
              values={{ amount: <MIFormattedCurrency value={couponAmount} /> }}
            />
          </Box>
        </Flex>
      )}
      <GuestCardLayout
        goDashboard={goDashboard}
        showNotFoundCard={!showMelioMeCard}
        showPaymentProgressStatus={Boolean(paymentRequestStatus.scheduled)}
        isActionRegister={isActionRegister}
        statusInfo={statusInfo}
        onSignUpClick={onSignUpClick}
        goToAccount={goToAccount}
        blockCCMoneyIn={blockCCMoneyIn}
        promotionName={promotionName}
        companyName={companyName}
      >
        {showMelioMeCard && !paymentRequestStatus.scheduled && (
          <>
            <Box>
              <MelioMeCardVendorInvoiceInfo
                logoUrl={logoUrl}
                companyName={companyName}
                hint={paymentHint}
                totalAmount={totalAmount}
                contactName={contactName}
                validationErrors={validationErrors}
                viewOnly
              />
            </Box>
            <Box textAlign="left" mb={8} mt={fileStorageUrl ? 0 : -4} id="payment-request-details-container">
              <PaymentRequestDetails
                noteValue={noteValue}
                noteLabel={noteLabel}
                invoiceNumber={invoiceNumber}
                dueDate={dueDate}
              />
              <Box>
                <InvoicePreview
                  invoiceName={invoiceName}
                  invoiceUrl={fileStorageUrl}
                  invoicePreviews={filePreviewUrls}
                />
              </Box>
            </Box>
            {fileStorageUrl && (
              <Box mb={6}>
                <InvoiceAttachmentPreview invoiceUrl={fileStorageUrl} invoiceName={invoiceName} />
              </Box>
            )}
            <Button
              onClick={onNext}
              label={`guests.register.${isLandingPageWhyMelio ? 'buttonText' : 'paymentRequestButtonText'}`}
              values={{ companyName }}
              size={ButtonSizes.lg}
              isFullWidth
            />
            <Flex mt={6} id="pay-with-info">
              <PayWithInfo isVendorAbsorbedFee={isVendorAbsorbedFee} vendorHasCCPaymentsBlocked={blockCCMoneyIn} />
            </Flex>
          </>
        )}
        {showMelioMeCard && paymentRequestStatus.scheduled && (
          <GuestPaymentRequestTracker
            statusInfoText={statusInfoText}
            stages={stages}
            totalAmount={totalAmount}
            validationErrors={validationErrors}
            companyName={companyName}
            invoiceNumber={invoiceNumber}
          />
        )}
        <Box as="p" __css={whyPayLink} onClick={onWhyPayLinkClick} data-testId="why-pay-link">
          <Icon name={IconNames.getStarted} size={IconSize.m} mr={0.5} />
          <MIFormattedText label="guests.paymentRequest.whyPay" values={{ companyName }} />
        </Box>
        {isNextDisabled && (
          <Box as="p" opacity="0.5" textStyle="body4" mt={paymentRequestStatus ? 12 : 5}>
            <MIFormattedText label="guests.register.disabledHint" />
            <br />
          </Box>
        )}
        {!showMelioMeCard && <GuestInvalidPaymentRequest companyName={companyName} email={payeeEmail} />}
      </GuestCardLayout>
    </Box>
  );
};

export default withTheme(GuestPaymentRequestInfoPage);
