import styled, { css } from 'styled-components';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PaymentStatus } from 'src/utils/consts';

type Props = {
  date: string;
  statusLabel: string;
  paymentStatus: string;
  isRefund: boolean | undefined;
};

export const PartialPaymentStatus = ({ date, statusLabel, paymentStatus, isRefund }: Props) => (
  <TextualData>
    <MIFormattedText
      label="bills.form.partialPayments.partialPaymentStatus"
      values={{
        date: <MIFormattedDate date={date} />,
        preposition: (
          <MIFormattedText
            label={
              paymentStatus === PaymentStatus.SCHEDULED
                ? 'bills.form.partialPayments.preposition.for'
                : 'bills.form.partialPayments.preposition.on'
            }
          />
        ),
        status: (
          <StatusLabel failed={paymentStatus === PaymentStatus.FAILED && !isRefund}>
            <MIFormattedText label={statusLabel} />
          </StatusLabel>
        ),
      }}
    />
  </TextualData>
);

const TextualData = styled.div`
  display: inline-block;
`;

const StatusLabel = styled.span<{ failed?: boolean }>`
  color: ${({ theme }) => theme.text.color.subtitle};

  ${({ failed, theme }) =>
    failed &&
    css`
      color: ${theme.text.color.error};
    `};

  ${({ theme }) => theme?.components?.BillOpenBalance?.StatusLabel};
`;
