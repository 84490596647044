import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { billsStore } from 'src/modules/bills/bills-store';
import { PayListItemType } from 'src/modules/bills/types';
import { PaymentCreateFlowOrigin } from 'src/utils/consts';

export const useBatchMarkAsPaid = () => {
  const orgId = useOrgId();
  const { batchMarkAsPaid: markAsPaidAction } = useStoreActions(billsStore);

  const isLoading = useSelector<any, boolean>(billsStore.selectors.batchMarkAsPaid.loading);
  const isError = useSelector<any, boolean>(billsStore.selectors.batchMarkAsPaid.error);

  const batchMarkAsPaid = (bills: PayListItemType[], intuitAccountId?: string) => {
    const items = bills.map((bill) => ({ id: bill.id, amount: bill.balance }));
    const createOrigin = PaymentCreateFlowOrigin.PAY;

    return markAsPaidAction({ orgId, items, intuitAccountId, createOrigin });
  };

  return { batchMarkAsPaid, isLoading, isError };
};
