import { Link } from 'src/core/ds/link';

type Props = {
  email: string;
};

export const EmailLink = ({ email }: Props) => (
  <Link href={`mailto:${email}`} target="_blank" color="primary.500" textDecoration="none">
    {email}
  </Link>
);
