import { featureFlags } from '@melio/shared-web';
import { Children } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import vendorReceivedDeposit from 'src/images/settings/vendor-received-deposit.png';
import { FeatureFlags } from 'src/utils/consts';
import {
  ADD_A_BANK_ACCOUNT_LINK,
  EXPEDITE_YOU_CHECK_PAYMENT_LINK,
  INTERNATIONAL_PAYMENTS_LINK,
  PARTIAL_PAYMENTS_LINK,
  RECURRING_PAYMENTS_LINK,
  WHAT_CAN_YOU_PAY_LINK,
} from '../constants';
import { SupportLink } from '../SupportSettingsPageContainer';

type Props = {
  answer: string;
  question: string;
  id: string;
  onToggle: (id: string) => void;
  isOpen: boolean;
  supportEmail: string;
};

export const AccordionItem = ({ answer, question, id, onToggle, isOpen, supportEmail }: Props) => {
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const url = shouldDisplayZendesk
    ? 'https://melio.zendesk.com/hc/en-us/articles/4447485821586-How-to-add-a-bank-account-automatically'
    : ADD_A_BANK_ACCOUNT_LINK;
  const onClickItem = () => {
    onToggle(id);
  };

  return (
    <Container>
      <AccordionItemLine onClick={onClickItem}>
        <AccordionItemTitle>
          <MIFormattedText label={question} />
        </AccordionItemTitle>
        <AccordionItemIcon className={isOpen ? 'icon-minus-icon' : 'icon-plus-icon'} />
      </AccordionItemLine>
      <AccordionItemInner className={isOpen ? 'open' : ''}>
        <AccordionItemContent>
          <MIFormattedText
            label={answer}
            values={{
              br: <br />,
              sp: <span style={{ margin: '0.5rem' }} />,
              image: <img src={vendorReceivedDeposit} style={{ width: '100%' }} alt="Paper check" />,
              supportEmail: (
                <SupportLink key={question + id} href={`mailto:${supportEmail}`} data-testid="support-email">
                  {supportEmail}
                </SupportLink>
              ),
              a: (...text) => (
                <SupportLink key={question + id} target="_open" href={url}>
                  {text}
                </SupportLink>
              ),
              pd: (...text) => <div style={{ padding: '0.8rem' }}>{Children.toArray(text)}</div>,
              internationalPaymentsLink: (...text) => (
                <SupportLink href={INTERNATIONAL_PAYMENTS_LINK}>{text}</SupportLink>
              ),
              whatCanYouPayLink: (...text) => <SupportLink href={WHAT_CAN_YOU_PAY_LINK}>{text}</SupportLink>,
              recurringPaymentsLink: (...text) => <SupportLink href={RECURRING_PAYMENTS_LINK}>{text}</SupportLink>,
              expediteYourCheckPaymentLink: (...text) => (
                <SupportLink href={EXPEDITE_YOU_CHECK_PAYMENT_LINK}>{text}</SupportLink>
              ),
              partialPaymentsLink: (...text) => <SupportLink href={PARTIAL_PAYMENTS_LINK}>{text}</SupportLink>,
            }}
          />
        </AccordionItemContent>
      </AccordionItemInner>
    </Container>
  );
};

const Container = styled.div`
  border-top: 0.1rem solid rgba(227, 229, 232, 1);
  color: rgba(37, 37, 37, 1);

  &:last-child {
    border-bottom: 0.1rem solid rgba(227, 229, 232, 1);
  }
`;

const AccordionItemLine = styled.div`
  display: block;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white.opaque};
  z-index: 2;
  padding: 1.9rem 0;
`;

const AccordionItemTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.normal};
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
`;

const AccordionItemIcon = styled.i`
  font-size: 2rem;
  color: ${(props) => props.theme.text.color.highlight};
  margin-right: 1rem;
  cursor: pointer;
`;

const AccordionItemInner = styled.div`
  max-height: 0;
  overflow: hidden;
  transition-property: max-height;
  transition: max-height 0.1s ease-in-out;
  z-index: 1;

  &.open {
    max-height: 100rem;
    transition-property: max-height;
    transition: max-height 1s ease-in-out;
  }
`;

const AccordionItemContent = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding-bottom: 2rem;
`;
