import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MISlider } from 'src/components/common/MISlider';
import { Expandable } from 'src/utils/types';
import { InlineFieldGroup } from './InlineFieldGroup';
import { ModelViewField } from './use-form';

const POSITIVE_LABEL_DEFAULT_VALUE = 'general.on';
const NEGATIVE_LABEL_DEFAULT_VALUE = 'general.off';
export type InlineSwitchFieldProps = {
  model?: ModelViewField<boolean>;
  value?: boolean;
  positiveLabel?: string;
  negativeLabel?: string;
  hidePositiveNegativeLabels?: boolean;
  label?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (change: Expandable<{ value: boolean }>) => any;
};
export function InlineSwitchField(props: InlineSwitchFieldProps) {
  const {
    model,
    label,
    value,
    id,
    positiveLabel,
    negativeLabel,
    onChange,
    hidePositiveNegativeLabels,
    ...rest
  } = props;
  const innerValue = !!(value === undefined ? model?.value : value);
  const innerOnChange = onChange || model?.onChange;
  const innerId = id || model?.id;
  const innerPositiveLabel = hidePositiveNegativeLabels ? '' : positiveLabel || POSITIVE_LABEL_DEFAULT_VALUE;
  const innerNegativeLabel = hidePositiveNegativeLabels ? '' : negativeLabel || NEGATIVE_LABEL_DEFAULT_VALUE;

  return (
    <InlineFieldGroup withoutLabel={!label}>
      {label && <MIFormattedText label={label} />}
      <MISlider
        {...rest}
        value={innerValue}
        onChange={(value) => innerOnChange && innerOnChange({ value })}
        id={innerId}
        negativeLabel={innerNegativeLabel}
        positiveLabel={innerPositiveLabel}
      />
    </InlineFieldGroup>
  );
}
