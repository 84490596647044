import React from 'react';
import { CardComponentProps } from 'src/components/braze/types';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { Link } from 'src/core/ds/link';

export const BannerInboxCard = ({ card, onClick, onClose, sxForCard }: CardComponentProps) => {
  const { title, description, imageUrl, linkText, url } = card;
  const hasLink = url && linkText;

  const handleClick = () => {
    onClick(card);
  };
  const handleClose = () => {
    onClose(card);
  };

  return (
    <Flex
      sx={sxForCard}
      onClick={handleClick}
      border="2px"
      bgColor="white"
      p={6}
      cursor="pointer"
      borderRadius="lg"
      width="full"
      boxSizing="border-box"
      borderColor="white"
      boxShadow="500"
      flexDirection="column"
      textAlign="left"
    >
      <Flex textStyle="body2Semi">
        <Box marginRight={2}>{imageUrl && <Image src={imageUrl} w={24} h={24} />}</Box>
        <Box w="full">{title}</Box>
        <Box color="grey.700" _hover={{ color: 'black' }}>
          <Icon name={IconNames.close} size={IconSize.s} onClick={handleClose} />
        </Box>
      </Flex>
      <Box textStyle="body3" color="grey.700">
        {description}
      </Box>
      {hasLink ? (
        <Link
          href={url}
          target="_blank"
          color="primary.500"
          textStyle="body3"
          textDecorationLine="underline"
          onClick={handleClose}
        >
          {linkText}
        </Link>
      ) : null}
    </Flex>
  );
};
