const ACTION_PREFIX = '[USER]';
export const CLEAR_STATE = '[APP] CLEAR_STATE';
export const LOAD_FUNDING_SOURCES = `${ACTION_PREFIX} LOAD_FUNDING_SOURCES`;
export const LOAD_FUNDING_SOURCES_SUCCESS = `${ACTION_PREFIX} LOAD_FUNDING_SOURCES_SUCCESS`;
export const LOAD_FUNDING_SOURCES_FAILED = `${ACTION_PREFIX} LOAD_FUNDING_SOURCES_FAILED`;
export const DELETE_FUNDING_SOURCE = `${ACTION_PREFIX} DELETE_FUNDING_SOURCE`;
export const DELETE_FUNDING_SOURCE_SUCCESS = `${ACTION_PREFIX} DELETE_FUNDING_SOURCE_SUCCESS`;
export const DELETE_FUNDING_SOURCE_FAILED = `${ACTION_PREFIX} DELETE_FUNDING_SOURCE_FAILED`;
export const UPDATE_FUNDING_SOURCE_LABEL = `${ACTION_PREFIX} UPDATE_FUNDING_SOURCE_LABEL`;
export const UPDATE_FUNDING_SOURCE_LABEL_SUCCESS = `${ACTION_PREFIX} UPDATE_FUNDING_SOURCE_LABEL_SUCCESS`;
export const UPDATE_FUNDING_SOURCE_LABEL_FAILED = `${ACTION_PREFIX} UPDATE_FUNDING_SOURCE_LABEL_FAILED`;
export const REMOVE_FUNDING_SOURCE_LABEL = `${ACTION_PREFIX} REMOVE_FUNDING_SOURCE_LABEL`;
export const REMOVE_FUNDING_SOURCE_LABEL_SUCCESS = `${ACTION_PREFIX} REMOVE_FUNDING_SOURCE_LABEL_SUCCESS`;
export const REMOVE_FUNDING_SOURCE_LABEL_FAILED = `${ACTION_PREFIX} REMOVE_FUNDING_SOURCE_LABEL_FAILED`;
export const LOAD_PROFILE = `${ACTION_PREFIX} LOAD_PROFILE`;
export const LOAD_PROFILE_SUCCESS = `${ACTION_PREFIX} LOAD_PROFILE_SUCCESS`;
export const LOAD_PROFILE_FAILED = `${ACTION_PREFIX} LOAD_PROFILE_FAILED`;
export const SET_PROFILE = `${ACTION_PREFIX} SET_PROFILE`;
export const IMPORT_QBO_CLIENTS = `${ACTION_PREFIX} IMPORT_QBO_CLIENTS`;
export const CLEAR_USER_INFO = `${ACTION_PREFIX} CLEAR_USER_INFO`;
export const CLEAR_USER_INFO_FINISH = `${ACTION_PREFIX} CLEAR_USER_INFO_FINISH`;
export const VERIFY_FUNDING_SOURCE = `${ACTION_PREFIX} VERIFY_FUNDING_SOURCE`;
export const VERIFY_FUNDING_SOURCE_SUCCESS = `${ACTION_PREFIX} VERIFY_FUNDING_SOURCE_SUCCESS`;
export const VERIFY_FUNDING_SOURCE_FAILED = `${ACTION_PREFIX} VERIFY_FUNDING_SOURCE_FAILED`;
export const LOAD_DELIVERY_METHODS = `${ACTION_PREFIX} LOAD_DELIVERY_METHODS`;
export const LOAD_DELIVERY_METHODS_SUCCESS = `${ACTION_PREFIX} LOAD_DELIVERY_METHODS_SUCCESS`;
export const LOAD_DELIVERY_METHODS_FAILED = `${ACTION_PREFIX} LOAD_DELIVERY_METHODS_FAILED`;
export const DELETE_DELIVERY_METHOD = `${ACTION_PREFIX} DELETE_DELIVERY_METHOD`;
export const DELETE_DELIVERY_METHOD_SUCCESS = `${ACTION_PREFIX} DELETE_DELIVERY_METHOD_SUCCESS`;
export const DELETE_DELIVERY_METHOD_FAILED = `${ACTION_PREFIX} DELETE_DELIVERY_METHOD_FAILED`;
export const LOAD_COMPANY_INFO = `${ACTION_PREFIX} LOAD_COMPANY_INFO`;
export const LOAD_COMPANY_INFO_SUCCESS = `${ACTION_PREFIX} LOAD_COMPANY_INFO_SUCCESS`;
export const LOAD_COMPANY_INFO_FAILED = `${ACTION_PREFIX} LOAD_COMPANY_INFO_FAILED`;
export const LOAD_COMPANY_LOGO = `${ACTION_PREFIX} LOAD_COMPANY_LOGO`;
export const LOAD_COMPANY_LOGO_SUCCESS = `${ACTION_PREFIX} LOAD_COMPANY_LOGO_SUCCESS`;
export const LOAD_COMPANY_LOGO_FAILED = `${ACTION_PREFIX} LOAD_COMPANY_LOGO_FAILED`;
export const SET_COMPANY_INFO = `${ACTION_PREFIX} SET_COMPANY_INFO`;
export const SET_ORGANIZATION_PREFERENCES = `${ACTION_PREFIX} SET_ORGANIZATION_PREFERENCES`;
export const UPDATE_USER_PREFERENCE = `${ACTION_PREFIX} UPDATE_USER_PREFERENCE`;
export const UPDATE_USER_PREFERENCE_SUCCESS = `${ACTION_PREFIX} UPDATE_USER_PREFERENCE_SUCCESS`;
export const UPDATE_USER_PREFERENCE_FAILED = `${ACTION_PREFIX} UPDATE_USER_PREFERENCE_FAILED`;
export const SET_USER_PREFERENCES = `${ACTION_PREFIX} SET_USER_PREFERENCES`;
export const INIT_INTERCOM_USER = `${ACTION_PREFIX} INIT_INTERCOM_USER`;
export const INIT_USER = `${ACTION_PREFIX} INIT_USER`;
export const INIT_USER_SUCCESS = `${ACTION_PREFIX} INIT_USER_SUCCESS`;
export const CHECK_AND_INIT_USER = `${ACTION_PREFIX} CHECK_AND_INIT_USER`;
export const CHECK_AND_INIT_USER_FINISH = `${ACTION_PREFIX} CHECK_AND_INIT_USER_FINISH`;
export const UPDATE_COMPANY_INFO_SUCCESS = '[ORGANIZATIONS] UPDATE_SUCCESS';
export const UPDATE_ORIGIN_PLAID_ITEM_ID = `${ACTION_PREFIX} UPDATE_ORIGIN_PLAID_ITEM_ID`;
