import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getFabProps, LayoutPageType } from 'src/core/components/expandedButton/utils';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import { Icon, IconNames } from 'src/core/ds/icon';
import { getOrgId } from 'src/redux/user/selectors';

export const GetProFab: React.FC = () => {
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const { handleClick } = getFabProps({
    path: LayoutPageType.GET_PAID,
    orgId,
    history,
  });

  return (
    <Box mr={3}>
      <Button
        iconSpacing={2}
        leftIcon={<Icon name={IconNames.plus} />}
        label="fab.newFab.get-paid"
        onClick={handleClick}
        testId="fab-get-paid"
      />
    </Box>
  );
};
