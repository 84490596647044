import first from 'lodash/first';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { DeliveryDate } from 'src/components/common/DeliveryDate';
import { MICardField } from 'src/components/common/MICard';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { Badge } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { billsApi } from 'src/modules/bills/api';
import { getOrgId } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import { BillStatus, CreditCardFeePayment, DeliveryType, FAST_DELIVERY_TYPES, PaymentStatus } from 'src/utils/consts';
import { isSameDay } from 'src/utils/dates';
import { isAbsorbFeeDeliveryMethodType } from 'src/utils/delivery-methods';
import { getCheckDepositedDate, getFailedPaymentDate, getLatestPayment, getNotePlaceholder } from 'src/utils/payments';
import { createPdfFile } from 'src/utils/pdf';
import { BillType, NotePlaceholderType, PaymentType } from 'src/utils/types';

type PaymentActivityInfoBlockType = {
  title: string;
  type: string;
  date: any;
  maxDate?: Date;
  method: string;
  info: string;
  icon: string;
  dateIcon: string;
  secondDate?: any;
  secondDateIcon?: string;
  secondDateDescription?: string;
  description: string;
  additionalDescription?: string;
  additionalHint?: Record<string, any>;
  isPaymentFailed?: boolean;
  paymentFailedDate?: Date;
  isEditable?: boolean;
  deliveryPreference?: string;
  isBillPaid?: boolean;
  onEdit?: () => void;
  onEditDate?: (() => void) | null;
  formattedCheckSerial?: string;
  hint?: string | null;
  isRecurringBill?: boolean;
  checkDepositedDate?: Date | null;
  deliveryDate?: Date | null;
};

type Props = {
  bill: BillType;
  isRequest?: boolean | null;
  scheduledData?: PaymentActivityInfoBlockType | null;
  deliveryData?: PaymentActivityInfoBlockType | null;
  onEditNote?: (() => void) | null;
  memo?: string | null;
  noteFromCustomer?: string | null;
  isRecurringBill?: boolean;
  site: any;
  isPayeeMode?: boolean;
  notePlaceholder?: NotePlaceholderType;
};

const PaymentActivity = ({
  bill,
  isRequest,
  scheduledData,
  deliveryData,
  memo,
  onEditNote,
  isRecurringBill,
  noteFromCustomer,
  notePlaceholder,
  site,
  isPayeeMode,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const isBillPaid = bill?.status === BillStatus.PAID;
  const billPayments = bill?.payments;
  const payment = useMemo<PaymentType | undefined>(() => getLatestPayment(billPayments), [billPayments]);
  const isReviewMode = !!onEditNote;
  const isPaymentFailed = payment?.status === PaymentStatus.FAILED && !isReviewMode;
  const checkDepositedDate = useMemo(() => {
    if (isBillPaid && payment?.deliveryMethod?.deliveryType === DeliveryType.CHECK) {
      return getCheckDepositedDate(payment.transactions);
    }

    return null;
  }, [isBillPaid, payment]);
  const paymentFailedDate = useMemo(() => (isPaymentFailed ? getFailedPaymentDate(payment) : null), [
    isPaymentFailed,
    payment,
  ]);

  const isVendorAbsorbedFee = bill?.paymentRequest?.feesPaidBy === CreditCardFeePayment.VENDOR;
  const showVendorAbsorbFeeSection =
    isAbsorbFeeDeliveryMethodType(payment?.deliveryMethod?.deliveryType) && isVendorAbsorbedFee;

  const onClickDownloadReceipt = async () => {
    const { billId: internalBillId } = first(payment?.billingItems) || {};

    billsApi.generateVendorFeePdfInvoice(orgId, internalBillId).then(({ base64 }) => {
      const fileName = `Melio-Receipt`;
      createPdfFile(base64, fileName);
    });
  };

  return (
    <>
      {scheduledData && (
        <PaymentActivityInfoBlock
          title={scheduledData.title}
          type={scheduledData.type}
          date={<MIFormattedDate date={scheduledData.date} />}
          method={scheduledData.method}
          info={scheduledData.info}
          icon={scheduledData.icon}
          dateIcon={scheduledData.dateIcon}
          isPaymentFailed={isPaymentFailed}
          paymentFailedDate={paymentFailedDate}
          isBillPaid={isBillPaid}
          description={scheduledData.description}
          hint={scheduledData.hint}
          isEditable={scheduledData.isEditable}
          onEdit={scheduledData.onEdit}
          onEditDate={scheduledData.onEditDate}
          formattedCheckSerial={scheduledData.formattedCheckSerial}
          secondDate={scheduledData.secondDate}
          secondDateIcon={scheduledData.secondDateIcon}
          secondDateDescription={scheduledData.secondDateDescription}
          isRecurringBill={isRecurringBill}
        />
      )}
      {deliveryData && (
        <PaymentActivityInfoBlock
          title={deliveryData.title}
          type={deliveryData.type}
          date={<DeliveryDate date={deliveryData.date} maxDate={deliveryData.maxDate} />}
          deliveryDate={deliveryData.date}
          method={deliveryData.method}
          info={deliveryData.info}
          icon={deliveryData.icon}
          dateIcon={deliveryData.dateIcon}
          isBillPaid={isBillPaid}
          description={deliveryData.description}
          additionalDescription={deliveryData.additionalDescription}
          deliveryPreference={deliveryData.deliveryPreference}
          additionalHint={deliveryData.additionalHint}
          isEditable={deliveryData.isEditable}
          onEdit={deliveryData.onEdit}
          onEditDate={deliveryData.onEditDate}
          formattedCheckSerial={deliveryData.formattedCheckSerial}
          checkDepositedDate={checkDepositedDate}
        />
      )}
      {!isPayeeMode && (
        <NoteBoxRow>
          <NoteBoxColumn>
            <FieldName>
              <MIFormattedText
                label={isRequest ? 'requests.form.paymentActivity.noteToCustomer' : 'bills.pay.confirm.addMemo'}
              />
            </FieldName>
            <FieldValue>
              <MIFieldOrEmpty
                value={isRequest ? noteFromCustomer : memo}
                label={getNotePlaceholder(notePlaceholder, deliveryData?.isEditable, isRequest)}
              />
            </FieldValue>
          </NoteBoxColumn>
          {onEditNote &&
            (site.theme.components?.PaymentActivity?.showEditIcon ? (
              <EditLink onClick={onEditNote} />
            ) : (
              <StyledMIInlineLink label="bills.form.paymentActivity.editLink" onClick={onEditNote} />
            ))}
        </NoteBoxRow>
      )}

      {(isRequest || isPayeeMode) && (
        <NoteBoxRow>
          <NoteBoxColumn>
            <FieldName>
              <MIFormattedText label="requests.form.paymentActivity.noteFromCustomer" />
            </FieldName>
            <FieldValue>
              <MIFieldOrEmpty
                value={memo}
                label="requests.form.paymentActivity.emptyNoteFromCustomerLabel"
                privateData
              />
            </FieldValue>
          </NoteBoxColumn>
        </NoteBoxRow>
      )}
      {showVendorAbsorbFeeSection && (
        <FeeRow>
          <MICardField label="getPaid.view.paymentRequest.fee">
            <MIFormattedText
              label={
                isBillPaid
                  ? 'getPaid.view.paymentRequest.paidFeeDescription'
                  : 'getPaid.view.paymentRequest.feeDescription'
              }
              values={{
                downloadReceiptLink: (
                  <DownloadReceiptLink onClick={onClickDownloadReceipt}>
                    <MIFormattedText label="getPaid.view.paymentRequest.downloadReceiptLink" />
                  </DownloadReceiptLink>
                ),
              }}
            />
          </MICardField>
        </FeeRow>
      )}
    </>
  );
};

PaymentActivity.defaultProps = {
  isRequest: false,
  scheduledData: null,
  deliveryData: null,
  onEditNote: null,
  memo: '',
  noteFromCustomer: '',
  isRecurringBill: false,
};

type PaymentActivityInfoBlockProps = {
  description: string;
} & PaymentActivityInfoBlockType;

const PaymentActivityInfoBlock = ({
  title,
  type,
  date,
  method,
  info,
  icon,
  dateIcon,
  hint,
  secondDate,
  secondDateIcon,
  secondDateDescription,
  isPaymentFailed,
  paymentFailedDate,
  isBillPaid,
  description,
  additionalDescription,
  additionalHint,
  deliveryPreference,
  isEditable = false,
  onEdit = () => undefined,
  onEditDate,
  formattedCheckSerial = '',
  isRecurringBill,
  checkDepositedDate,
  deliveryDate,
}: PaymentActivityInfoBlockProps) => (
  <>
    <BlockTitleContainer data-testid="bill-delivery-info-title">
      <MIFormattedText label={title} />
    </BlockTitleContainer>
    <MainPaymentActivityContainer>
      {info && method && (
        <PaymentActivitySource
          method={method}
          type={type}
          info={info}
          icon={icon}
          isEditable={isEditable}
          isBillPaid={isBillPaid}
          hint={hint}
          onEdit={onEdit}
          formattedCheckSerial={formattedCheckSerial}
        />
      )}
      {isPaymentFailed && paymentFailedDate && (
        <PaymentInfoContainer>
          <PaymentFailedContainer>
            <MIFormattedText
              label="bills.form.paymentActivity.failedPayment"
              values={{
                date: <MIFormattedDate date={paymentFailedDate} />,
              }}
            />
          </PaymentFailedContainer>
        </PaymentInfoContainer>
      )}
      {!isPaymentFailed && (
        <>
          <PaymentActivityDate
            isBillPaid={isBillPaid}
            date={date}
            description={description}
            additionalDescription={additionalDescription}
            deliveryPreference={deliveryPreference}
            deliveryDate={deliveryDate}
            additionalHint={additionalHint}
            icon={dateIcon}
            onEditDate={onEditDate}
            isRecurringBill={isRecurringBill}
          />
          {secondDate && (
            <PaymentActivityDate
              isBillPaid={isBillPaid}
              date={secondDate}
              description={secondDateDescription}
              icon={secondDateIcon}
            />
          )}
          {checkDepositedDate && <CheckDeposited date={checkDepositedDate} />}
        </>
      )}
    </MainPaymentActivityContainer>
  </>
);

PaymentActivityInfoBlock.defaultProps = {
  isEditable: false,
  secondDate: '',
  secondDateIcon: '',
  secondDateDescription: '',
  onEdit: () => undefined,
  onEditDate: null,
};

type PaymentActivitySourceProps = {
  method: string;
  type: string;
  info: string;
  hint?: string | null;
  icon: string;
  isEditable: boolean;
  isBillPaid?: boolean | null;
  onEdit: () => void;
  formattedCheckSerial: string;
  site: any;
};

const PaymentActivitySource = withSiteContext()(
  ({
    icon,
    type,
    method,
    formattedCheckSerial,
    info,
    isEditable,
    onEdit,
    isBillPaid,
    hint,
    site,
  }: PaymentActivitySourceProps) => (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer data-testid={`get-pro-payment-drawer-delivery-method-${type}`}>
          <Box minW="4.5rem">
            <Icon name={IconNames[icon]} size={IconSize.lg} color={isBillPaid ? 'grey.600' : 'black'} />
          </Box>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText>
                <MIFormattedText label={method} values={{ formattedCheckSerial }} />
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText isBillPaid={isBillPaid}>{info}</PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {isEditable &&
          (site.theme?.components?.PaymentActivity?.showEditIcon ? (
            <EditLink onClick={onEdit} />
          ) : (
            <StyledMIInlineLink label="bills.form.paymentActivity.editLink" onClick={onEdit} />
          ))}
      </PaymentActivityMainContainer>

      {hint && (
        <PaymentInfoHint>
          <MIFormattedText label={hint} />
        </PaymentInfoHint>
      )}
    </PaymentActivityContainer>
  )
);

PaymentActivitySource.defaultProps = {
  hint: null,
};

type PaymentActivityDateProps = {
  date: string | Record<string, any> | Date;
  icon?: string;
  description?: string | null;
  additionalDescription?: string;
  additionalHint?: Record<string, any>;
  isBillPaid?: boolean | null;
  isRecurringBill?: boolean;
  deliveryPreference?: string;
  deliveryDate?: Date | null;
  onEditDate?: (() => void) | null;
  site: any;
};

const PaymentActivityDate = withSiteContext()(
  ({
    date,
    icon,
    description,
    additionalDescription,
    isBillPaid,
    onEditDate,
    additionalHint,
    isRecurringBill,
    site,
    deliveryDate,
    deliveryPreference,
  }: PaymentActivityDateProps) => {
    const convertedIcon = icon === 'icon-eta-cal' && 'calendarMove';

    return (
      <PaymentActivityContainer>
        <PaymentActivityMainContainer>
          <PaymentInfoContainer>
            <Box minW="4.5rem">
              <Icon
                name={IconNames[convertedIcon as string]}
                size={IconSize.lg}
                color={isBillPaid ? 'grey.600' : 'black'}
              />
            </Box>
            <PaymentTextContainer>
              <PaymentMethodText>
                <MIFormattedText label={description || ''} />
              </PaymentMethodText>
              <PaymentInfoText isBillPaid={isBillPaid}>
                {deliveryDate && isSameDay(deliveryDate) && (
                  <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />
                )}
                <MIFormattedText
                  label={
                    deliveryDate
                      ? 'bills.pay.date.deliveryOptions.fastAchDates.free'
                      : 'bills.pay.date.deliveryOptions.fastAchDates.date'
                  }
                  values={{ date }}
                />
                {deliveryPreference && FAST_DELIVERY_TYPES.includes(deliveryPreference) && (
                  <Box as="span" ml={1} pos="relative" top="-2px">
                    <Badge label="bills.pay.date.deliveryOptions.price.fastTag" />
                  </Box>
                )}
                {additionalDescription && (
                  <AdditionalDescription>
                    <MIFormattedText label={additionalDescription || ''} />
                  </AdditionalDescription>
                )}
              </PaymentInfoText>
            </PaymentTextContainer>
          </PaymentInfoContainer>
          {onEditDate &&
            !isRecurringBill &&
            (site.theme?.components?.PaymentActivity?.showEditIcon ? (
              <EditLink onClick={onEditDate} />
            ) : (
              <StyledMIInlineLink label="bills.form.paymentActivity.editLink" onClick={onEditDate} />
            ))}
        </PaymentActivityMainContainer>
        {additionalHint && additionalHint.label && (
          <AdditionalHint>
            <MIFormattedText label={additionalHint.label} values={additionalHint.values} />
          </AdditionalHint>
        )}
      </PaymentActivityContainer>
    );
  }
);

type CheckDepositedProps = {
  date: Date;
};

const CheckDeposited = ({ date }: CheckDepositedProps) => (
  <CheckDepositedContainer>
    <CheckDepositedLabel>
      <MIFormattedText label="bills.form.paymentActivity.checkDeposited" />
    </CheckDepositedLabel>
    <CheckDepositedDate>
      <MIFormattedText
        label="bills.form.paymentActivity.checkDepositedDate"
        values={{
          date: <MIFormattedDate date={date} timeZone="UTC" />,
        }}
      />
    </CheckDepositedDate>
  </CheckDepositedContainer>
);

PaymentActivityDate.defaultProps = {
  onEditDate: null,
  additionalDescription: '',
  additionalHint: {},
  isRecurringBill: false,
};

const baseContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

const EditLink = styled.i.attrs({ className: 'icon-edit-icon' })`
  font-size: 2.4rem;
  color: rgba(227, 229, 232, 1);
  margin-right: 0.4rem;
  cursor: pointer;
  ${(props) => props.theme?.components?.PaymentActivity?.EditLink}
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  margin: 0 2rem 0 1rem;
  ${(props) => props.theme?.components?.PaymentActivity?.StyledMIInlineLink}
`;

const MainPaymentActivityContainer = styled.div`
  margin-bottom: 2.2rem;
  ${baseContainerStyles}
  ${(props) => props.theme?.components?.PaymentActivity?.MainPaymentActivityContainer}
`;

export const PaymentIcon = styled.i`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  > img {
    height: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;

const PaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};

  ${(props) => props.theme?.components?.PaymentActivity?.PaymentActivityContainer}
`;

const PaymentActivityMainContainer = styled.div`
  display: flex;
  padding-right: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentActivityMainContainer}
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoContainer}
`;

const PaymentTextContainer = styled.div`
  ${baseContainerStyles}
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentTextContainer}
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile} {
    flex-direction: column;
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodContainer}
`;

export const PaymentMethodText = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.2px;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodText}
`;

export const PaymentInfoText = styled.div`
  color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoText}
`;

export const ErrorMessage = styled.div`
  color: ${(props) => props.theme.text.color.error};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: start;
`;

export const PaymentInfoHint = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  margin-left: 4.5rem;
  margin-right: 0.5rem;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoHint}
`;

export const AdditionalHint = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  margin: 0.8rem 0.5rem 0 4.5rem;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.AdditionalHint}
`;

const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.subtitle};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.BlockTitleContainer}
`;

const PaymentFailedContainer = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.colors.failure.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-top: 2rem;
`;

const NoteBoxRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.2rem;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxRow}

  &:last-of-type {
    margin-bottom: 1.2rem;
  }
`;

const BoxColumn = styled.div`
  flex: 1;
  width: 100%;
  margin: 0.6rem 0 0rem;
  ${(props) => props.theme?.components?.PaymentActivity?.BoxColumn}
`;

const NoteBoxColumn = styled(BoxColumn)`
  margin-top: 0;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.PaymentActivity?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.PaymentActivity?.FieldName}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.FieldValue};
`;

const AdditionalDescription = styled.div`
  ${(props) => props.theme?.components?.PaymentActivity?.AdditionalDescription}
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.label};
`;

const CheckDepositedContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0 0.2rem;
`;

const CheckDepositedLabel = styled.div`
  color: ${(props) => props.theme.colors.white.opaque};
  background-color: ${(props) => props.theme.colors.success.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  border-radius: 0.4rem;
  padding: 0 0.8rem;
  text-transform: uppercase;
  ${(props) => props.theme.text.fontType.small};
`;

const CheckDepositedDate = styled.div`
  ${baseTextStyles}
  line-height: ${(props) => props.theme.text.lineHeight.hint};
  margin-left: 1rem;
`;

const FeeRow = styled.div`
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  margin-bottom: 0.6rem;
  margin-top: 1.6rem;
`;

const DownloadReceiptLink = styled.span`
  text-decoration: none;
  cursor: pointer;

  color: ${(props) => props.theme.colors.brand};
`;

export default withSiteContext()(PaymentActivity);
