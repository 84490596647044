import Box from 'src/core/ds/box';
import { useBreak } from 'src/hoc';
import { Logo } from 'src/images/Logo';
import { analytics } from 'src/services/analytics';
import { LogoFillType } from 'src/utils/consts';
import { msnVendorCandidateEventPage } from '../../consts';
import { headerStyle } from './styles';

export const Header = () => {
  const device = useBreak();
  const isMobile = device.isMobile || device.isPhablet;

  const onLogoClick = () => {
    analytics.track(msnVendorCandidateEventPage, 'homepage-link');
    window.open('https://www.meliopayments.com');
  };

  return (
    <Box __css={headerStyle}>
      <Box onClick={onLogoClick}>
        <Logo fill={LogoFillType.WHITE} size={isMobile ? 'mobileSmall' : 'regular'} isFull />
      </Box>
    </Box>
  );
};
