import { featureFlags } from '@melio/shared-web';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BusinessTypeSelector } from 'src/components/common/BusinessTypeSelector';
import { ContactSupportLink } from 'src/components/common/ContactSupportLink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { NotificationVariant } from 'src/core/ds/toast';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { useIsVendorAbsorbedFeeSet } from 'src/pages/settings/hooks/useIsVendorAbsorbedFeeSet';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { DeliveryMethodOrigin, OrgPreferencesTypeKeys } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { FeatureFlags } from 'src/utils/featureFlags';
import { useLocationState } from 'src/utils/hooks';
import { onboardingLocations } from '../locations';
import { goToAddReceivingMethod } from './hooks/utils';

export const VendorCompanyBusinessTypePageContainer = () => {
  const { msnBusinessType } = useOrganizationPreferences();
  const [selectedType, setSelectedType] = useState(msnBusinessType);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaidMicroDepositsGetPaidEnabled] = featureFlags.useFeature(
    FeatureFlags.PlaidMicroDepositsGetPaidEnabled,
    false
  );
  const errorToastRef = useRef(null);
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();

  const relativeStep = isUnifiedOnboardingOpen ? 11 / 15 : 8 / 10;

  const orgId = useSelector(getOrgId);
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');
  const { navigate } = useNavigator();
  const ownedVendorId = useSelector(getOwnedVendorId);
  const isVendorAbsorbedFeeSet = useIsVendorAbsorbedFeeSet();

  const analyticsData = { orgId, vendorId: ownedVendorId, businessType: selectedType };

  const handleNextClick = async () => {
    if (!selectedType) return;

    setIsLoading(true);
    try {
      await updateOrganizationPreference({
        key: OrgPreferencesTypeKeys.msnBusinessType,
        value: selectedType,
        orgId,
      });
      goToAddReceivingMethod({
        navigate,
        origin,
        orgId,
        ownedVendorId,
        shouldSkipAbsorbFeesStep: isVendorAbsorbedFeeSet,
      });
    } catch (err) {
      if (!checkIfToastIsDisplayedById(errorToastRef.current)) {
        errorToastRef.current = pushNotification({
          type: NotificationVariant.error,
          msg: 'onboarding.msnCompanyInfo.generalError.text',
          textValues: {
            contactSupport: <ContactSupportLink />,
          },
          autoClose: false,
        });
      }

      analytics.trackAction('businessType_error', analyticsData);
      capture(err as Error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrevClick = () => navigate(onboardingLocations.vendorCompanyInfo.legal, false, { origin });

  return (
    <StepLayoutPage
      title="onboarding.msnCompanyInfo.selectBusinessType.title"
      subtitle="onboarding.msnCompanyInfo.selectBusinessType.subtitle"
      isNextDisabled={!selectedType}
      onPrev={handlePrevClick}
      onNext={handleNextClick}
      relativeStep={relativeStep}
      isLoading={isLoading}
      nextButtonAnalyticsProperties={analyticsData}
      {...(isPlaidMicroDepositsGetPaidEnabled && {
        nextLabel: 'onboarding.msnCompanyInfo.selectBusinessType.nextLabel',
      })}
    >
      <BusinessTypeSelector selectedType={selectedType} onTypeClick={setSelectedType} />
    </StepLayoutPage>
  );
};
