import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function getCurrencyMask(prefix = '$') {
  const suffix = '';
  const includeThousandsSeparator = true;
  const thousandsSeparatorSymbol = ',';
  const allowDecimal = true;
  const decimalSymbol = '.';
  const decimalLimit = 2;

  return createNumberMask({
    prefix,
    suffix,
    includeThousandsSeparator,
    thousandsSeparatorSymbol,
    allowDecimal,
    decimalSymbol,
    decimalLimit,
  });
}

export function convertCurrencyToNumber(amount) {
  return amount ? amount.toString().replace(/[$,]/g, '') : null;
}

export const convertNumberToCurrency = (amount: number, options: Intl.NumberFormatOptions = {}): string =>
  amount?.toLocaleString('en-US', { style: 'currency', currency: 'USD', ...options }) || '';

export const formatToAmountStandard = (amount: number | string): string =>
  convertCurrencyToNumber(amount) ? parseFloat(convertCurrencyToNumber(amount)).toFixed(2) : '';

export function getMicrodepositMask() {
  return ['$', '0', '.', /\d/, /\d/];
}

export const isMicroDepositValueAllowed = ({ value }) =>
  !!((Number(value) <= 99 && Number(value) >= 0) || value === '');
