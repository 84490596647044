import pickBy from 'lodash/pickBy';
import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { encodeQuery, parseQueryString } from '../query-utils';
import { useQueryString } from './useQueryString';

export function useQueryState(
  name: string,
  defaultValue: string,
  replace?: boolean,
  copyState?: boolean
): [string, (value: string | undefined) => void];
export function useQueryState(
  name: string,
  defaultValue?: undefined,
  replace?: boolean,
  copyState?: boolean
): [string | undefined, (value: string | undefined) => void];
export function useQueryState(
  name: string,
  defaultValue: string | undefined = undefined,
  replace = true,
  copyState = true
): [string | undefined, (value: string | undefined) => void] {
  const query = useQueryString();
  const location = useLocation();
  const history = useHistory();
  const value = query?.[name] === undefined ? defaultValue : query?.[name];
  const setValue = useCallback<(value: string | undefined) => void>(
    async (value) => {
      const currQuery = parseQueryString(history.location.search);
      const newQuery = pickBy({ ...currQuery, [name]: value });
      const search = encodeQuery(newQuery, [], '');
      const { state, ...restLocation } = location;

      if (replace) {
        await history.replace({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      } else {
        await history.push({
          ...restLocation,
          search,
          state: copyState ? state : null,
        });
      }
    },
    [replace, location, history, name, copyState]
  );

  return [value, setValue];
}
