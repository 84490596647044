import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getOrgId } from 'src/redux/user/selectors';
import { VendorType } from 'src/utils/types';

const useFetchVendor = (vendorId?: number | string | null, loadDeliveryMethodSuggestions = false) => {
  const vendor = useSelector<any, VendorType<'created'>>(vendorsStore.selectors.fetch.byId(vendorId));
  const isVendorLoading = useSelector<any, { loading: boolean } | undefined>(
    vendorsStore.selectors.fetch.status(vendorId)
  )?.loading;
  const vendorsActions = useStoreActions(vendorsStore);
  const orgId = useSelector(getOrgId);

  const fetchVendor = useCallback(async () => {
    if (!vendor && vendorId) {
      await vendorsActions.fetch({ orgId, id: vendorId });
    }

    if (!vendor?.printName && loadDeliveryMethodSuggestions) {
      await vendorsActions.qboVendorInfo({ orgId, vendorId });
    }
  }, [vendor, vendorId, orgId, vendorsActions]);

  useEffect(() => {
    fetchVendor();
  }, [fetchVendor, vendorId]);

  return {
    vendor,
    isVendorLoading,
    fetchVendor: () => vendorsActions.fetch({ orgId, id: vendorId }),
  };
};

export default useFetchVendor;
