import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from 'src/core/ds/popover';
import { Portal } from 'src/core/ds/portal';

type Props = {
  label: string;
  iconName: IconNames;
  iconColor: string;
  action?: (e?: React.MouseEvent<HTMLElement>) => void;
  actionLabel?: string;
  popoverTestId: string;
  iconTestId: string;
};

export const ActionPopover = ({
  iconColor,
  iconName,
  label,
  action,
  actionLabel,
  popoverTestId,
  iconTestId,
}: Props) => (
  <Popover trigger="hover" placement="top">
    <PopoverTrigger>
      <Box data-testid={iconTestId}>
        <Icon name={iconName} size={IconSize.s} color={iconColor} />
      </Box>
    </PopoverTrigger>
    <Portal>
      <PopoverContent>
        <PopoverBody
          backgroundColor="black"
          color="white"
          px={4}
          pt={4}
          pb={2}
          borderRadius="lg"
          cursor="default"
          data-testid={popoverTestId}
        >
          <Box mb={2} maxW="24rem" textStyle="body4">
            <MIFormattedText label={label} />
          </Box>
          {action && actionLabel ? (
            <Flex justify="end">
              <Box
                py={2}
                px={4}
                display="inline-block"
                textStyle="body4Semi"
                _hover={{ color: '#9470FF' }}
                cursor="pointer"
                onClick={action}
                data-testid="popover-action"
              >
                <MIFormattedText label={actionLabel} />
              </Box>
            </Flex>
          ) : null}
        </PopoverBody>
      </PopoverContent>
    </Portal>
  </Popover>
);
