import { useSelector } from 'react-redux';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { contactsLocations } from 'src/pages/contacts/locations';
import { globalLocations } from 'src/pages/locations';

export const BatchUploadVendorsSuccess = () => {
  const uploadedCount = useSelector(vendorsStore.selectors.createBatch.status)?.count;

  const [historyPush] = useHistoryWithOrgId();
  const goToContacts = () => historyPush({ path: contactsLocations.index, params: { type: 'vendors' } });
  const goToDashboard = () => historyPush({ path: globalLocations.dashboard.bills });

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.checkCircle}
      title="contacts.batch.success.vendors.title"
      text="contacts.batch.success.vendors.subtitle"
      textValues={{ uploadedCount }}
      buttonAction={goToContacts}
      buttonLabel="contacts.batch.success.vendors.primaryAction"
      secondaryButtonAction={goToDashboard}
      secondaryButtonLabel="contacts.batch.success.vendors.secondaryAction"
    />
  );
};
