import { useDispatch, useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import authStore from 'src/modules/auth/auth-store';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import guestApi from 'src/services/api/guests';
import { UserAuthType } from 'src/utils/consts';

export const useCreatePassword = (orgId: number) => {
  const dispatch = useDispatch();
  const userAuthType = useSelector(authStore.selectors.userAuthType);
  const { onApiCall: createPasswordWithToken, loading: isCreatePasswordLoading, result: createPasswordResult } = useApi(
    {
      api: guestApi.createPasswordWithToken,
    }
  );
  const isUserAuthenticated = userAuthType === UserAuthType.AUTHENTICATED;
  const isLoading = isCreatePasswordLoading || (createPasswordResult && !isUserAuthenticated);

  const createPasswordAndInitUser = async ({ token, password }: { token: string; password: string }) => {
    await createPasswordWithToken(token, password);
    // The promise wrapper is needed to avoid the redirect jump to the login page after the user created password and logged in, but the auth store hasn't updated yet
    await new Promise((resolve, reject) => dispatch(checkAndInitUserAction({ orgId }, resolve, reject)));
  };

  return {
    createPasswordAndInitUser,
    isLoading,
  };
};
