import { featureFlags } from '@melio/shared-web';
import compact from 'lodash/compact';
import startsWith from 'lodash/startsWith';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { withNavigator } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { filesApi } from 'src/modules/files/api';
import { paymentsApi } from 'src/modules/payments/api';
import { vendorRequestsApi } from 'src/modules/vendors/api';
import { ActivityActionsType } from 'src/pages/bill/pay/components/PayBillPaymentActivity/PayBillPaymentActivity';
import { PayBillPaymentReview } from 'src/pages/bill/pay/components/PayBillPaymentReview/PayBillPaymentReview';
import { billFactory } from 'src/pages/bill/records';
import { getPaymentRequestCreateOrigin } from 'src/pages/meliome/guest/utils';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { paymentFactory } from 'src/pages/payment/records';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { isBillAmountRequiresGoodsConfirmation } from 'src/utils/bills';
import {
  CreditCardFeePayment,
  FeatureFlags,
  FundingType,
  PaymentCreateFlowOrigin,
  VendorManagedByEnum,
} from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { getInitialProcessingDates } from 'src/utils/dates';
import { CreateBillRequestType } from 'src/utils/types';
import { GuestDataProps, LocalFileProps, withGuestData } from './hoc/withGuestData';

type Props = {
  scheduledDate: Date;
  deliveryDate: Date;
  maxDeliveryDate: Date;
} & GuestDataProps;

const eventPage = 'payor';
const eventName = 'confirm-payment';

const getPartner = (utmContent) => {
  const PARTNER_PREFIX = 'partnerme-';

  if (!utmContent) {
    return undefined;
  }

  const trimmedContent = utmContent.trim().toLowerCase();

  return startsWith(trimmedContent, PARTNER_PREFIX) ? trimmedContent.slice(PARTNER_PREFIX.length) : undefined;
};

const GuestConfirmPageContainer = (props: Props) => {
  const {
    deliveryDate,
    scheduledDate,
    selectedFundingSourceId,
    invoiceNumber,
    invoiceFile,
    deliveryMethodType,
    ownedById,
    totalAmount,
    memo,
    vendorId,
    navigateToGuestPage,
    link,
    deliveryMethodId,
    paymentRequest,
    goodsReceived,
    maxDeliveryDate,
    utmContent,
    prevRoute,
    billId,
    companyName,
    isVendorAbsorbedFee,
    onChange,
  } = props;
  const site = useSiteContext();
  const { StepLayout } = site.components;
  const [skipDeductionDateStep] = featureFlags.useFeature(FeatureFlags.GuestPayorFlowRemoveScreensPhase1, false);
  const [isLoading, setLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [fee, setFee] = useState(null);
  const orgId = useSelector(getOrgId);
  const fundingSources = useSelector(getFundingSources);
  const { VENDOR, PAYOR } = CreditCardFeePayment;

  const populateDates = useCallback(async () => {
    setShowLoader(true);

    if (skipDeductionDateStep) {
      const dates = await getInitialProcessingDates({
        orgId,
        deliveryMethodId,
        fundingSourceId: selectedFundingSourceId,
        scheduledDate,
        amount: convertCurrencyToNumber(totalAmount),
        dueDate: paymentRequest.dueDate,
      });

      onChange({ id: 'scheduledDate', value: dates.suggestedScheduledDate });
      onChange({ id: 'deliveryDate', value: dates.deliveryDate });
      onChange({ id: 'maxDeliveryDate', value: dates.maxDeliveryDate });
    }

    setShowLoader(false);
  }, [
    skipDeductionDateStep,
    orgId,
    deliveryMethodId,
    selectedFundingSourceId,
    scheduledDate,
    totalAmount,
    paymentRequest.dueDate,
    onChange,
  ]);

  useEffect(() => {
    populateDates();
  }, []);

  useEffect(() => {
    if (billId) {
      navigateToGuestPage(generatePath(melioMeLocations.wizard.success, { link }), {});

      return;
    }

    setShowLoader(true);
    paymentsApi
      .getPaymentFee({
        orgId,
        fundingSourceId: selectedFundingSourceId,
        amount: totalAmount,
        scheduledDate,
        deliveryPreference: null,
        deliveryMethodId,
        cardFeePaidBy: isVendorAbsorbedFee ? VENDOR : PAYOR,
        allowDoubleFee: true,
        isFinancingPayment: false,
      })
      .then((res) => {
        setFee(res.fee);
        setShowLoader(false);
      });
  }, [
    billId,
    link,
    navigateToGuestPage,
    orgId,
    scheduledDate,
    selectedFundingSourceId,
    totalAmount,
    isVendorAbsorbedFee,
    VENDOR,
    PAYOR,
    deliveryMethodId,
  ]);

  const uploadGuestInvoiceFile = async (invoiceFile?: LocalFileProps) =>
    invoiceFile?.file && filesApi.uploadFile(orgId, invoiceFile.file);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const uploadedGuestInvoiceFile = await uploadGuestInvoiceFile(invoiceFile);

      if (uploadedGuestInvoiceFile) {
        const invoiceFileUploadStatus = uploadedGuestInvoiceFile.code === 'OK' ? 'success' : 'error';
        analytics.track(eventPage, `${eventName}-upload-invoice-file-${invoiceFileUploadStatus}`, {
          fileName: invoiceFile?.fileName,
          vendorId,
        });
      }

      const files = [uploadedGuestInvoiceFile?.file, ...(paymentRequest?.files || [])];
      const filesIds: number[] = compact(files.map((file) => (file ? +file.id : 0)));
      const convertedTotalAmount = parseFloat(convertCurrencyToNumber(totalAmount));
      const partner = getPartner(utmContent);
      const billData: CreateBillRequestType = {
        ...billFactory({
          totalAmount: convertedTotalAmount,
          balance: convertedTotalAmount,
          vendorId,
          dueDate: paymentRequest.dueDate || scheduledDate,
          invoiceDate: new Date(),
          invoiceNumber,
          note: '',
          goodsReceived,
          partner,
        }),
        files: filesIds,
      };
      const siteOrigin = site.createOrigin.meliome.paymentRequest;

      const paymentData = paymentFactory({
        vendorId,
        amount: convertedTotalAmount,
        currency: billData.currency,
        deliveryMethodId,
        fundingSourceId: selectedFundingSourceId,
        scheduledDate,
        deliveryEta: deliveryDate,
        maxDeliveryEta: maxDeliveryDate,
        note: memo || '',
        createOrigin: PaymentCreateFlowOrigin.REQUEST,
      });

      const data = {
        billData,
        paymentData,
        paymentRequestId: paymentRequest.id,
        paymentRequestCreateOrigin: getPaymentRequestCreateOrigin(siteOrigin, paymentRequest),
      };

      const { bill, payment } = await vendorRequestsApi.createRequest({
        orgId,
        ownedVendorId: vendorId,
        data,
      });
      const navigateData = {
        billId: bill.id,
        approvalDecisionStatus: payment.approvalDecisionStatus,
      };

      setLoading(false);
      analytics.track(eventPage, `${eventName}-continue-success`);

      // This is needed to fix the issue https://trello.com/c/ztVz15nX/1832-payor-flow-review-done-back-done-2-payments
      // We need it to replace locationState of the confirm page in the history, because when user presses back
      // on success page confirm page is rendered with initial location state
      // without created billId and changed memo values
      navigateToGuestPage(
        generatePath(melioMeLocations.wizard.confirm, { link }),
        navigateData,
        false,
        !site.embeddedMode
      );
      navigateToGuestPage(generatePath(melioMeLocations.wizard.success, { link }), navigateData);
    } catch (err) {
      setLoading(false);
    }
  };

  const onPrev = () => {
    analytics.track(eventPage, `${eventName}-back`);
    const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId) || fundingSources[0];

    if (!isBillAmountRequiresGoodsConfirmation(totalAmount) || selectedFundingSource.fundingType !== FundingType.CARD) {
      navigateToGuestPage(generatePath(melioMeLocations.wizard.memo, { link }));
    } else {
      navigateToGuestPage(prevRoute);
    }
  };

  const goEditFundingSource = () => {
    navigateToGuestPage(generatePath(melioMeLocations.wizard.editFundingSource, { link }));
  };

  const goEditNote = () => {
    navigateToGuestPage(generatePath(melioMeLocations.wizard.memo, { link }));
  };

  const goEditDate = () => {
    navigateToGuestPage(generatePath(melioMeLocations.wizard.paymentDate, { link }));
  };

  const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId) || fundingSources[0];
  const convertedTotalAmount = convertCurrencyToNumber(totalAmount);
  const bill = billFactory({
    totalAmount: convertedTotalAmount,
    balance: convertedTotalAmount,
    vendorId,
    dueDate: paymentRequest.dueDate || scheduledDate,
    invoiceDate: new Date(),
    invoiceNumber,
    note: '',
    vendor: {
      id: null,
      companyName,
      contactEmail: '',
      contactPhone: '',
      ownedById: ownedById || null,
      // TODO @shaked this is a temp solution if we will have msn vendor we need to pass the right managed by
      managedBy: paymentRequest ? VendorManagedByEnum.SHARED : undefined,
    },
    recurringBill: null,
  });

  const payment = paymentFactory({
    vendorId,
    amount: convertedTotalAmount,
    deliveryMethodId,
    fundingSourceId: selectedFundingSourceId,
    scheduledDate,
    deliveryEta: deliveryDate,
    maxDeliveryEta: maxDeliveryDate,
    note: memo || '',
    createOrigin: PaymentCreateFlowOrigin.REQUEST,
  });
  const deliveryMethod = deliveryMethodFactory({
    id: deliveryMethodId,
    deliveryType: deliveryMethodType,
  });

  const activityActions = {
    onEditDate: goEditDate,
    onEditFundingSource: goEditFundingSource,
    onEditNote: goEditNote,
  } as ActivityActionsType;

  if (showLoader) {
    return <AreaLoader />;
  }

  return (
    <StepLayout
      title="bills.pay.confirm.title"
      relativeStep={6 / 6}
      onPrev={onPrev}
      isLoading={isLoading}
      innerSize={50}
      fullWidthCTA
      footer={
        <Box w="full" mt={5}>
          <Button
            label="bills.pay.confirm.action"
            variant={ButtonVariants.success}
            size={ButtonSizes.lg}
            onClick={onSubmit}
            isLoading={isLoading}
            isFullWidth
          />
        </Box>
      }
    >
      <PayBillPaymentReview
        bill={bill}
        fundingSource={selectedFundingSource}
        activityActions={activityActions}
        fee={fee}
        payment={payment}
        deliveryMethod={deliveryMethod}
        isVendorAbsorbedFee={isVendorAbsorbedFee}
        isEditDisabled={isLoading}
      />
    </StepLayout>
  );
};

export default compose(withNavigator(), withGuestData())(GuestConfirmPageContainer);
