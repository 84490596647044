import { createBatchUpdateSlice } from 'src/helpers/redux/restBatchUpdateSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { InvoiceType } from 'src/modules/invoices/types';
import { name } from '../constants';

const proInvoicesBatchSendSlice = createBatchUpdateSlice<InvoiceType>({
  storeName: name,
  api: getProApi.invoices.batchSend,
  actionName: 'proBatchSend',
});

export default proInvoicesBatchSendSlice;
