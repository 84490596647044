import { SelectCategoryListItem } from './SelectCategoryListItem';

type SelectCategoryListOption = {
  id: string;
  label: string;
  description: string;
};

type Props = {
  onSelect: (id: string) => void;
  categories: SelectCategoryListOption[];
  selectedId?: string | null;
  analyticsProps?: Record<string, any>;
};

export function SelectCategoryList({ onSelect, categories, selectedId }: Props) {
  return (
    <>
      {categories.map(({ id, label, description }) => (
        <SelectCategoryListItem
          key={id}
          testId={label}
          isSelected={selectedId === id}
          onClick={() => onSelect(id)}
          label={label}
          description={description}
        />
      ))}
    </>
  );
}
