import styled from 'styled-components';
import { TextField } from 'src/core/ds/form/fields';
import { FormRow } from 'src/ui/form/FormElements';
import { InlineSelectField } from 'src/ui/form/InlineSelectField';
import { US_STATES } from 'src/utils/us-states';

type Props = {
  submit: any;
  printNameLabel: string;
  model: any;
};

const NewCheckOrCardDeliveryMethodForm = ({ submit, printNameLabel, model }: Props) => (
  <FormContainer onSubmit={submit}>
    <RowContainer>
      <TextField label={printNameLabel} model={model?.printName} size="sm" isRequired autoFocus />
    </RowContainer>
    <RowContainer>
      <TextField
        label="vendors.addDeliveryMethodByLink.addressLine1"
        model={model?.addressLine1}
        size="sm"
        isRequired
      />
    </RowContainer>
    <RowContainer>
      <TextField label="vendors.addDeliveryMethodByLink.addressLine2" model={model?.addressLine2} size="sm" />
    </RowContainer>
    <RowContainer>
      <TextField label="vendors.addDeliveryMethodByLink.city" model={model?.city} size="sm" isRequired />
      <InlineSelectField
        id="state"
        label="vendors.addDeliveryMethodByLink.state"
        options={US_STATES}
        model={model?.state}
        required
      />
    </RowContainer>
    <RowContainer>
      <TextField
        label="vendors.addDeliveryMethodByLink.zipCode"
        model={model?.zipCode}
        type="tel"
        size="sm"
        isRequired
      />
    </RowContainer>
    <button type="submit" hidden />
  </FormContainer>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0rem 0 4rem;
`;

const RowContainer = styled(FormRow)`
  margin-top: 2rem;
  align-items: flex-start;
`;

export default NewCheckOrCardDeliveryMethodForm;
