import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { settingsLocations } from 'src/pages/settings/locations';
import { SettingsListPageContainer } from './SettingsListPageContainer';

export const SettingsRouter = () => (
  <Switch>
    <SmartRoute path={settingsLocations.index}>
      <SettingsListPageContainer />
    </SmartRoute>
  </Switch>
);
