import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import { paymentsApi } from 'src/modules/payments/api';
import { GetPaymentsApiCallResponseType } from 'src/redux/payBillWizard/types';
import { DeliveryType, FundingType } from 'src/utils/consts';
import { isEligibleToReceiveVirtualCard } from 'src/utils/payments';
import { AccountType, DeliveryMethodType, PaymentType } from 'src/utils/types';

const getDefaultDeliveryMethod = (deliveryMethods) => {
  const deliveryTypesPriority = [
    DeliveryType.RPPS,
    DeliveryType.VIRTUAL,
    DeliveryType.ACH,
    DeliveryType.CHECK,
    DeliveryType.INTERNATIONAL,
  ];
  const supportedDeliveryMethods = deliveryMethods.filter(
    (dm: DeliveryMethodType) => deliveryTypesPriority.indexOf(dm.deliveryType) !== -1
  );

  return head(sortBy(supportedDeliveryMethods, (dm) => deliveryTypesPriority.indexOf(dm.deliveryType)));
};

export const getDefaultFundingSource = async (
  orgId: number,
  fundingSources: readonly AccountType[],
  defaultFundingSourceId?: number | null,
  isInternational?: boolean
): Promise<AccountType | null> => {
  let filteredFundingSource = fundingSources;

  if (isInternational) {
    filteredFundingSource = fundingSources.filter((fs) => fs.fundingType !== FundingType.CARD);
  }

  const defaultFundingSource = filteredFundingSource.find((fs) => fs.id === defaultFundingSourceId);

  if (defaultFundingSource) return defaultFundingSource;

  const { objects: payments }: GetPaymentsApiCallResponseType = await paymentsApi.getPayments({
    orgId,
    filters: {
      limit: 1,
    },
    sorting: 'createdAt',
  });

  const lastCreatedPayment: PaymentType | undefined = payments?.[0];
  const lastCreatedPaymentFundingSource = filteredFundingSource.find(
    (fs) => fs.id === lastCreatedPayment?.fundingSourceId
  );

  if (lastCreatedPaymentFundingSource) return lastCreatedPaymentFundingSource;

  return filteredFundingSource?.[0] || null;
};

type GetDefaultDeliveryMethodOptions = {
  deliveryMethods: readonly DeliveryMethodType[];
  fundingSource?: AccountType;
  currentDeliveryMethodId?: number;
  isFinancing?: boolean;
};

export const getDeliveryMethodForPayment = ({
  deliveryMethods,
  fundingSource,
  currentDeliveryMethodId,
  isFinancing,
}: GetDefaultDeliveryMethodOptions): DeliveryMethodType | undefined => {
  const currentDeliveryMethod = deliveryMethods.find((dm) => dm.id === currentDeliveryMethodId);
  const isVirtualCardSupported = fundingSource && !isFinancing && isEligibleToReceiveVirtualCard(fundingSource);
  const virtualCardDeliveryMethod = deliveryMethods.find((dm) => dm.deliveryType === DeliveryType.VIRTUAL_CARD);
  const isCurrentDeliveryMethodInvalid =
    !isVirtualCardSupported && currentDeliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD;

  if (isVirtualCardSupported && virtualCardDeliveryMethod) {
    return virtualCardDeliveryMethod;
  }

  if (isCurrentDeliveryMethodInvalid || !currentDeliveryMethod) {
    return getDefaultDeliveryMethod(deliveryMethods);
  }

  return currentDeliveryMethod;
};
