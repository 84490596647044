import qs from 'qs';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { VStack } from 'src/core/ds/stack';
import { useApi } from 'src/hoc/useApi';
import { useCheckboxOptions } from 'src/hooks/accountants/useCheckboxOptions';
import { accountingFirmsApi } from 'src/modules/accounting-firms/api';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { IMPORT_QBO_CLIENTS } from 'src/redux/user/actionTypes';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { companiesLocations } from '../../locations';
import { CheckboxSelectionMenu } from './CheckboxSelectionMenu';
import { ImportedClientVerificationRow } from './ImportedClientVerificationRow';

type Customers = {
  id: number;
  companyName: string;
  billAddress: { [key: string]: unknown };
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  industry: string;
};

export const VerifyImportedClients = () => {
  const dispatch = useDispatch();
  const currentOrg = useSelector(getCompanyInfo);
  const currentOrgId = useSelector(getOrgId);

  const { search } = useLocation();
  const customersFileRef = useMemo(() => new URLSearchParams(search).get('customersFileRef'), [search]);
  const { onApiCall: bulkCreateOrganizations, loading: isImportingClients } = useApi({
    api: accountingFirmsApi.bulkCreate,
  });
  const { onApiCall: getUnsyncedQuickbooksCients, loading: isLoading, result } = useApi<
    [{ orgId: number; customersFileRef: string }],
    { customers: Customers[] }
  >({
    api: accountingPlatformsApi.getUnsyncedQuickbooksClients,
  });

  const fetchCustomers = async () => {
    if (customersFileRef) {
      await getUnsyncedQuickbooksCients({ orgId: currentOrgId, customersFileRef });
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const history = useHistory();
  const {
    checkboxes: clients,
    markedCheckboxes: markedClients,
    toggleCheckbox,
    toggleAll,
    clearAll,
    batchAction,
  } = useCheckboxOptions({
    options: result?.customers,
    isAllSelected: true,
  });

  const handleImportClick = async () => {
    const clientsToImport = [...markedClients]
      .map((client) => ({ ...client }))
      .map((client) => {
        delete client.isChecked;

        return client;
      });

    try {
      const res = await bulkCreateOrganizations({ clients: clientsToImport, orgId: currentOrgId });
      dispatch({ type: IMPORT_QBO_CLIENTS, payload: { importedClients: res.importedClients, userId: res.userId } });
      history.push(
        generatePath(
          `${companiesLocations.importClients.success}?${qs.stringify({ amount: res.importedClients?.length })}`,
          {
            orgId: currentOrg.id,
          }
        )
      );
    } catch (ex) {
      history.push(
        generatePath(`${companiesLocations.importClients.connectionError}`, {
          orgId: currentOrg.id,
        })
      );
    }
  };

  const handleBackClick = () => {
    history.push(generatePath(companiesLocations.index, { orgId: currentOrg.id }));
  };

  return (
    <WizardLayout>
      <WizardLayout.TopBar onBackClick={handleBackClick} progressBarValue={50} />
      <WizardLayout.Content>
        <VStack justifyContent="center" spacing={4}>
          <WizardLayout.Header label="companies.importQboa.verifyImports.title" />
          <Box textStyle="body2">
            <MIFormattedText label="companies.importQboa.verifyImports.subTitle" />
          </Box>
        </VStack>
        {isLoading ? (
          <Box paddingTop="20rem">
            <Loader color="primary" context="page" />
          </Box>
        ) : (
          <>
            <WizardLayout.Body>
              {clients.length ? (
                <CheckboxSelectionMenu
                  clearAll={clearAll}
                  enableBatchSelection
                  batchAction={batchAction}
                  markAll={toggleAll}
                  markedIds={markedClients}
                />
              ) : null}
              <Box w="full" maxHeight="calc(100vh - 436px)" overflowY="auto">
                {clients.map(({ id, companyName, isChecked }) => (
                  <ImportedClientVerificationRow
                    id={id}
                    key={id}
                    isChecked={isChecked}
                    name={companyName}
                    onChange={toggleCheckbox}
                  />
                ))}
              </Box>
            </WizardLayout.Body>
            <WizardLayout.Footer>
              <Button
                disabled={markedClients.length === 0}
                size={ButtonSizes.lg}
                variant={ButtonVariants.primary}
                w="full"
                label="companies.importQboa.verifyImports.submit"
                isLoading={isImportingClients}
                onClick={handleImportClick}
              />
            </WizardLayout.Footer>
          </>
        )}
      </WizardLayout.Content>
    </WizardLayout>
  );
};
