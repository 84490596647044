import { memo } from 'react';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type DueDateCellProps = {
  dueDate: Date;
};

export const DueDateCell: React.FC<DueDateCellProps> = memo(({ dueDate }) => (
  <Flex align="center" justify="space-between">
    {!dueDate ? (
      <Box color="grey.600">
        <MIFormattedText label="regularBatchPayments.cells.deliveryDate.notAvailable" />
      </Box>
    ) : (
      <Flex data-testid="due-date-cell">
        <Box me={1}>
          <MIFormattedText label="regularBatchPayments.cells.deliveryDate.dueDatePrefix" />
        </Box>
        <MIFormattedDate date={dueDate} />
      </Flex>
    )}
  </Flex>
));
