import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { vendorLocations } from 'src/pages/vendor/locations';
import { selectNewDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { ContactsTab, DbAnalyticsTraits, DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { DeliveryMethodType, EditableDeliveryMethodType } from 'src/utils/types';

type DeliveryMethodDataType = {
  isSubmitting: boolean;
  submitErrorCode: string;
  isVendorBlockedForPayment: boolean;
  submitDeliveryMethod: (deliveryMethod: EditableDeliveryMethodType, nextLocationUrl?: string) => void;
  navigateOut: (dataToAdd?: Record<string, any>) => void;
};

export const useDeliveryMethodData = (
  vendorId: number,
  deliveryMethodId?: string,
  useNavigationFromUseWizard?: boolean
): DeliveryMethodDataType => {
  const dispatch = useDispatch();
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const orgId = useSelector(getOrgId);
  const [origin] = useLocationState('origin', '');
  const { checkVendorPaymentPreferences } = useStoreActions(vendorsStore);
  const [isVendorBlockedForPayment, setIsVendorBlockedForPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const { navigateWithPreservedState, navigate } = usePreservedStateNavigator();

  const eventPage = ownedVendorId === vendorId ? 'vendor-company-delivery-method' : 'vendor-delivery-method';

  const upsertDeliveryMethod = async (deliveryMethod: EditableDeliveryMethodType): Promise<DeliveryMethodType> => {
    if (deliveryMethodId) {
      const { payload } = await deliveryMethodActions.update({
        orgId,
        vendorId,
        id: deliveryMethodId,
        deliveryMethod,
      });

      return payload;
    }

    if (ownedVendorId && ownedVendorId?.toString() === vendorId?.toString()) {
      deliveryMethod.isFilledByVendor = true;
      analytics.setTraits({
        [DbAnalyticsTraits.ADDED_DELIVERY_METHOD]: true,
      });
    }

    const { payload } = await deliveryMethodActions.create({
      orgId,
      vendorId,
      deliveryType: deliveryMethod?.deliveryType,
      bankAccount: deliveryMethod?.bankAccount,
    });

    return payload;
  };

  const navigateOut = (dataToAdd?: Record<string, any>, nextLocationUrl?: string) => {
    if (isVendorBlockedForPayment) {
      return;
    }

    if (nextLocationUrl) {
      navigate(nextLocationUrl, false, { manually: true });

      return;
    }

    if (navigateWithPreservedState) {
      navigateWithPreservedState(dataToAdd);
    } else {
      navigate(
        generatePath(vendorLocations.view, {
          orgId,
          id: vendorId,
          type: ContactsTab.VENDORS,
        })
      );
    }
  };

  const refreshDeliveryMethods = () =>
    new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });

  const submitDeliveryMethod = async (deliveryMethod: EditableDeliveryMethodType, nextLocationUrl?: string) => {
    setIsLoading(true);

    try {
      const data = await upsertDeliveryMethod(deliveryMethod);

      if (origin === DeliveryMethodOrigin.PAY_BILL) {
        await dispatch(selectNewDeliveryMethodAction(data));
        const {
          payload: { blockPayments },
        } = await checkVendorPaymentPreferences({ orgId, id: vendorId });
        setIsVendorBlockedForPayment(blockPayments);
      }

      if (vendorId === ownedVendorId) {
        await refreshDeliveryMethods();
      }

      const eventType = deliveryMethodId ? 'edit-delivery-method-success' : 'add-delivery-method-success';
      analytics.track(eventPage, eventType, {
        type: deliveryMethod.deliveryType,
        deliveryMethodId,
      });

      const dataToAdd = {
        newDeliveryMethod: data,
        batchActionMerge: origin === DeliveryMethodOrigin.BATCH_PAY_BILLS,
      };

      setIsLoading(false);
      !useNavigationFromUseWizard && navigateOut(dataToAdd, nextLocationUrl);
    } catch (error: any) {
      setIsLoading(false);
      setErrorCode(error.code);
    }
  };

  return {
    isSubmitting: isLoading,
    submitErrorCode: errorCode,
    isVendorBlockedForPayment,
    submitDeliveryMethod,
    navigateOut,
  };
};
