import { CSSProperties } from 'react';

type Props = {
  width?: string;
  color?: string;
};

export const FailedDMSvgIcon = ({ width = '25', color = '#D0021B' }: Props) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      width={width}
      height={width}
      fill={color}
      viewBox="0 0 46.182 46.182"
      style={
        {
          enableBackground: 'new 0 0 46.182 46.182',
        } as CSSProperties
      }
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M37.734,2.655H8.438C3.786,2.655,0,6.447,0,11.099v17.14c0,4.652,3.786,8.439,8.438,8.439h18.376l3.935,5.634   c0.53,0.762,1.399,
        1.212,2.328,1.214c0.928,0.002,1.799-0.45,2.334-1.21l4.126-5.845c3.792-0.82,6.645-4.195,6.645-8.232v-17.14   C46.182,6.447,42.387,2.655,
        37.734,2.655z M23.091,25.826c1.359,0,2.462,1.126,2.462,2.515s-1.103,2.513-2.462,2.513   c-1.359,0-2.462-1.124-2.462-2.513S21.731,25.826,
        23.091,25.826z M20.636,21.602V11.234c0-1.389,1.066-2.515,2.455-2.515   c1.39,0,2.455,1.126,2.455,2.515v10.368c0,1.389-1.065,2.515-2.455,
        2.515C21.702,24.117,20.636,22.99,20.636,21.602z"
        />
      </g>
    </svg>
  </>
);
