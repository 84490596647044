import * as React from 'react';
import Box from 'src/core/ds/box';
import { BillStatus } from 'src/pages/bill/components/BillStatus';
import { InvoiceStatus } from 'src/utils/consts';
import useGetCardStatus from './hooks/useGetCardStatus';

type Props = {
  status?: InvoiceStatus;
};

const containerStyle = {
  '&:first-of-type': {
    borderBottom: 'none',
  },
};

const CardStatus: React.FC<Props> = ({ status }) => {
  const [statusInfo] = useGetCardStatus(status);

  return statusInfo ? (
    <Box __css={containerStyle}>
      <BillStatus statusInfo={statusInfo} />
    </Box>
  ) : null;
};

export default CardStatus;
