import { FormatOptionContext, Option } from 'src/components/common/MISingleSelect';
import { AddVendorOptionLabel } from 'src/pages/vendor-directory/select-vendor/AddVendorOptionLabel';
import { LocalVendorOptionLabel } from 'src/pages/vendor-directory/select-vendor/LocalVendorOptionLabel';
import { NetworkVendorOptionLabel } from 'src/pages/vendor-directory/select-vendor/NetworkVendorOptionLabel';
import { SelectVendorType } from 'src/utils/consts';

type Props = {
  option: Option;
  formatOptions: FormatOptionContext;
};

export const VendorOptionLabel = ({ option, formatOptions: { context } }: Props) => {
  const isSelected = context === 'value';

  // eslint-disable-next-line no-underscore-dangle
  if (option.__isNew__) return <AddVendorOptionLabel option={option} />;

  if (option.type === SelectVendorType.DIRECTORY)
    return <NetworkVendorOptionLabel isSelected={isSelected} option={option} />;

  return <LocalVendorOptionLabel isSelected={isSelected} option={option} />;
};
