import { withSiteContext } from 'src/hoc/withSiteContext';
import { AddAchFromBatchPaymentsFlowWrapper } from 'src/pages/vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromBatchPaymentsFlowWrapper';
import { AddAchFromResendUndepositedCheckFlowWrapper } from 'src/pages/vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromResendUndepositedCheckFlowWrapper';
import { AddAchFromRetryReturnedCheckFlowWrapper } from 'src/pages/vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromRetryReturnedCheckFlowWrapper';
import { AddAchFromRetryVirtualCardFlowWrapper } from 'src/pages/vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromRetryVirtualCardFlowWrapper';
import { AddAchFromVendorsTabFlowWrapper } from 'src/pages/vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromVendorsTabFlowWrapper';
import VirtualDeliveryMethodPageContainer from 'src/pages/vendor/delivery-methods/VirtualDeliveryMethodPageContainer';
import { vendorLocations } from 'src/pages/vendor/locations';
import { analytics } from 'src/services/analytics';
// eslint-disable-next-line max-len
import { EditCheckAddressWithAutocompleteDeliveryMethodPageContainer } from './EditCheckAddressWithAutocompleteDeliveryMethodPageContainer';
import EditCheckNameDeliveryMethodPageContainer from './EditCheckNameDeliveryMethodPageContainer';

const eventPage = 'vendors';

export default [
  {
    path: vendorLocations.deliveryMethods.ach.createFlow,
    exact: true,
    component: (cProps) => (
      <AddAchFromVendorsTabFlowWrapper
        {...cProps}
        orgId={cProps.match.params.orgId}
        vendorId={cProps.match.params.id}
      />
    ),
  },

  {
    path: vendorLocations.deliveryMethods.ach.createFromReturnedCheckRecoveryFlow,
    exact: true,
    component: (cProps) => (
      <AddAchFromRetryReturnedCheckFlowWrapper
        {...cProps}
        orgId={cProps.match.params.orgId}
        vendorId={cProps.match.params.id}
      />
    ),
  },

  {
    path: vendorLocations.deliveryMethods.ach.createFromVirtualCardRecoveryFlow,
    exact: true,
    component: (cProps) => (
      <AddAchFromRetryVirtualCardFlowWrapper
        {...cProps}
        orgId={cProps.match.params.orgId}
        vendorId={cProps.match.params.id}
      />
    ),
  },

  {
    path: vendorLocations.deliveryMethods.ach.createFromUndepositedCheckResendFlow,
    exact: true,
    component: (cProps) => (
      <AddAchFromResendUndepositedCheckFlowWrapper
        {...cProps}
        orgId={cProps.match.params.orgId}
        vendorId={cProps.match.params.id}
      />
    ),
  },

  {
    path: vendorLocations.deliveryMethods.ach.createFromBatchPaymentsFlow,
    exact: true,
    component: (cProps) => (
      <AddAchFromBatchPaymentsFlowWrapper
        {...cProps}
        orgId={cProps.match.params.orgId}
        vendorId={cProps.match.params.id}
      />
    ),
  },

  {
    path: vendorLocations.deliveryMethods.virtual.create,
    exact: true,
    component: withSiteContext()((cProps) => {
      analytics.page(eventPage, 'add-virtual-delivery-method');

      return (
        <VirtualDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          orgId={cProps.match.params.orgId}
        />
      );
    }),
  },

  {
    path: vendorLocations.deliveryMethods.ach.editBankDetails,
    exact: true,
    component: (cProps) => (
      <AddAchFromVendorsTabFlowWrapper
        {...cProps}
        orgId={cProps.match.params.orgId}
        vendorId={cProps.match.params.id}
        deliveryMethodId={cProps.match.params.deliveryMethodId}
      />
    ),
  },

  {
    path: vendorLocations.deliveryMethods.check.create,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'add-check-delivery-method-name');

      return (
        <EditCheckNameDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['printName']}
        />
      );
    },
  },

  {
    path: vendorLocations.deliveryMethods.check.edit,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'edit-check-delivery-method-name');

      return (
        <EditCheckNameDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
          inputFields={['printName']}
        />
      );
    },
  },

  {
    path: vendorLocations.deliveryMethods.check.address.create,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'add-check-delivery-method-address');

      return (
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['googlePlaceId']}
        />
      );
    },
  },

  {
    path: vendorLocations.deliveryMethods.check.address.edit,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'edit-check-delivery-method-address');

      return (
        <EditCheckAddressWithAutocompleteDeliveryMethodPageContainer
          {...cProps}
          vendorId={cProps.match.params.id}
          inputFields={['googlePlaceId']}
          deliveryMethodId={cProps.match.params.deliveryMethodId}
        />
      );
    },
  },
];
