export enum AmountVariant {
  DEFAULT = 'default',
  IDLE = 'idle',
  NEGATIVE = 'negative',
}

export const AMOUNT_COLOR_BY_VARIANT = {
  [AmountVariant.DEFAULT]: 'black',
  [AmountVariant.IDLE]: 'grey.600',
  [AmountVariant.NEGATIVE]: 'red.500',
};

export enum AmountSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export const AMOUNT_STYLE_BY_SIZE = {
  [AmountSize.SMALL]: {
    whole: 'body2Semi',
    fraction: 'body4Semi',
  },
  [AmountSize.MEDIUM]: {
    whole: 'body1Semi',
    fraction: 'body4Semi',
  },
  [AmountSize.LARGE]: {
    whole: 'h2Semi',
    fraction: 'body4Semi',
  },
};
