import * as React from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

export type Props = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  leftWidth?: number;
  rightWidth?: number;
  onExit: () => void;
};

type OnlyChildrenProps = {
  children: React.ReactNode;
};

type CloseActionProps = OnlyChildrenProps & { onClick: () => void };

export const SideBySideLayoutPage = ({ leftContent, rightContent, onExit }: Props) => (
  <Flex
    h={{
      base: 'full',
      md: 'unset',
      lg: 'full',
    }}
    bgColor="grey.200"
  >
    <InnerPageContainer>
      <LeftContainer>{leftContent}</LeftContainer>
      <RightContainer>
        <CloseAction onClick={onExit}>
          <Icon name={IconNames.close} size={IconSize.lg} />
        </CloseAction>
        {rightContent}
      </RightContainer>
    </InnerPageContainer>
  </Flex>
);

const InnerPageContainer = ({ children }: OnlyChildrenProps) => (
  <Box
    __css={{
      w: 'full',
      overflow: { base: 'auto', sm: 'hidden' },
      gap: { base: 8, sm: 'unset' },
      p: { base: 5, sm: 'unset' },
      flexDir: { base: 'column', lg: 'row' },
      display: 'flex',
    }}
  >
    {children}
  </Box>
);

const LeftContainer = ({ children }: OnlyChildrenProps) => (
  <Box
    __css={{
      boxSizing: 'border-box',
      pos: 'relative',
      w: { base: 'full', lg: '60%' },
      p: { base: 0, sm: 20, md: 12 },
      pt: { base: 0, sm: 'inherit', md: 0 },
      borderRight: { base: 'none', lg: '1px' },
      borderBottom: 'none',
      borderRightColor: { base: 'grey.400', lg: 'grey.400' },
      h: { base: '63vh', sm: 'full' },
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    {children}
  </Box>
);

const RightContainer = ({ children }: OnlyChildrenProps) => (
  <Box
    __css={{
      m: 0,
      pt: { base: 10, sm: 20 },
      pb: 0,
      boxSizing: 'border-box',
      overflow: { lg: 'auto' },
      pos: { lg: 'relative' },
      w: { base: 'full', lg: '40%' },
      borderTop: { base: '1px', lg: 'none' },
      borderTopColor: { base: 'grey.400', lg: 'grey.400' },
      display: { base: 'flex', sm: 'block' },
      px: 'unset',
    }}
  >
    {children}
  </Box>
);

const CloseAction = ({ children, onClick }: CloseActionProps) => (
  <Box
    __css={{
      pos: 'absolute',
      top: 5,
      right: 5,
      cursor: 'pointer',
      color: 'grey.600',
      zIndex: 1,
    }}
    _hover={{ color: 'black' }}
    onClick={onClick}
  >
    {children}
  </Box>
);
