import Flex from 'src/core/ds/flex';
import { DrawerTab } from './DrawerTab';
import { DrawerTabsType } from './types';

type DrawerTabsProps = {
  onTabClick: (name: string) => void;
  tabs: DrawerTabsType;
  activeTab: string;
};

export const DrawerTabs = ({ onTabClick, tabs, activeTab }: DrawerTabsProps) => (
  <Flex mt={6} w="full">
    {Object.values(tabs).map((tab, index) => (
      <DrawerTab key={index} tab={tab} activeTab={activeTab} onTabClick={onTabClick} />
    ))}
  </Flex>
);
