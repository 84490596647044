/* eslint-disable max-len */
// eslint-disable-next-line import/no-default-export

export default {
  server: {
    baseUrl: 'https://localhost:8090',
  },
  web: {
    baseUrl: 'https://localhost:3031',
  },
  platformApp: {
    baseUrl: 'http://localhost:3005/melio',
  },
  sentry: {
    enable: false,
    dsn: 'https://b284345d360941c2938babe97be0d3f8@o181640.ingest.sentry.io/5817119',
    org: 'melio-6r',
  },
  fullstory: {
    orgId: '10BZN1',
  },
  logger: {
    shouldLog: false,
    shouldPrintLogs: false,
  },
  analytics: {
    shouldTrackEvents: false,
    shouldPrintEvents: false,
    shouldIdentify: false,
  },
  services: {
    braze: {
      enable: false,
      apiKey: '2241af2b-0711-4852-bd8d-cb013a85175c',
      options: {
        baseUrl: 'sdk.iad-05.braze.com',
        enableLogging: false,
        localization: 'en',
        allowUserSuppliedJavascript: true,
      },
    },
    intuit: {
      clientId: 'L0zQWUXvELmSFuBEywbazKeTTqsoUNIN8P7DEC7ybulhtlE68n',
      openid_url: 'https://intuit-oidgw.meliopayments.com/',
    },
    plaid: {
      clientName: 'Melio',
      env: 'sandbox',
    },
    segment: {
      key: 'HHGcPnPXtwBScXC7UAmy75QPLHNfJFMs',
      shouldLoad: false,
    },
    googleMaps: {
      url: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCy2BbnUakgGy08r_gmL8Qlo0VikreL7WA&v=3.exp&language=en',
      key: 'AIzaSyCy2BbnUakgGy08r_gmL8Qlo0VikreL7WA',
    },
    tabapay: {
      url_legacy: 'https://sso.sandbox.tabapay.com:8443/SSOMelioPayments.html?1593332294',
      url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioPayments1.html',
    },
    basisTheory: {
      key: 'key_GZFc6KNn6zbN6Y54aFZfpV',
    },
    smartystreets: {
      key: '27409250903411363',
    },
    featureFlagProvider: {
      shouldLoad: true,
      clientId: '6062c9171a6a080f1a6b1528',
    },
    feedbackProvider: {
      userSnapSpaceId: '39ab2947-23d9-4768-a96f-9eff5f508557',
      userSnapProjectId: '786dcee3-f5f6-42da-93f7-15db525c1e1b',
    },
    // Sandbox V.2
    zendesk: {
      webWidgetKey: '60d5191e-6ed5-4cc2-9a54-e510bba5cac3',
      messagingKey: '6cf823a4-5854-401a-8e1f-c92a3b5d0f49',
    },
  },
  jwt: {
    session: 'meliosession',
    expirationDays: 30,
  },
  meliome: {
    baseUrl: 'https://localhost:3031/meliome/pay/',
  },
  demoEnv: {
    baseUrl: 'https://app.demo.meliopayments.com',
  },
  support: {
    phone: '(646) 568-9735',
    email: 'support@melio.com',
    accountantsEmail: 'accountanthelp@meliopayments.com',
    site: 'https://help.meliopayments.com/',
  },
  agreementLinks: {
    userAgreement: 'https://www.meliopayments.com/legal/terms-of-service',
    privacyPolicy: 'https://www.meliopayments.com/legal/privacy-policy',
  },
  promo: {
    accountant: 'https://www.meliopayments.com/accountants',
  },
  quickbooks: {
    baseUrl: 'https://qbo.intuit.com',
  },
  qbo: {
    web: {
      baseUrl: 'https://localhost:3031',
    },
    support: {
      phone: '(612) 688-4189',
      email: 'support@qbousers.meliopayments.com',
    },
    compliance: {
      email: 'compliance@qbousers.meliopayments.com',
    },
    agreementLinks: {
      privacyPolicy: 'https://qbousers.meliopayments.com/privacy.html',
      userAgreement: 'https://qbousers.meliopayments.com/terms.html',
      termsOfService: 'https://qbousers.meliopayments.com/terms.html',
      QBOnlineData:
        'https://appcenter.intuit.com/cmsimages/promo/intuit_app_data_sharing/datasharingpolicy_accounting_payroll.html',
      intuitPrivacyPolicy: 'https://www.intuit.com/privacy/',
    },
    services: {
      tabapay: {
        url: 'https://sso.sandbox.tabapay.com:8443/SSOMelioIntuit.html?1571897387',
      },
    },
  },
  debounceDelay: 300,
  inDemoEnv: false,
  thirdPartyDisabled: false,
  featureFlags: {
    vendorsBatchUpload: false,
    customersBatchUpload: true,
    getPaidLabels: true,
    invoices: false,
    codatQBO: false,
    allowMarkAsPaidQbDashboard: true,
    partialPayments: false,
    qboPartialPayments: true,
  },
  productionAppUrl: 'https://app.meliopayments.com',
  bookAccountantUrl: 'https://hello.melio.com/calendar/team/t/17#/select-time',
  sessionExpired: {
    timeout: 1000 * 60 * 30,
  },
  spendManagement: {
    amexPartnerId: 'Arcade-Melio-63304382',
    amexUrls: {
      login: 'https://qwww.americanexpress.com/en-us/account/light/login/',
      enrollment: 'https://account-enrollment-qa.americanexpress.com/card-selector',
    },
  },
};
