import syncManagerAnimation from 'src/animations/accountingSoftware/sync-qbd/sync-manager.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { useUrlSaver } from 'src/utils/useUrlSaver';
import { getQBDRelativeStep } from './utils';

type Props = {
  onNext: () => void;
  onExit: () => void;
};

const translationPath = `settings.newAccountingSoftware.QuickbooksDesktopSync.entry`;

const SyncQBDPage = ({ onNext, onExit }: Props) => {
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData(true);
  const downloadSyncManager = useUrlSaver();
  const onDownload = () => {
    if (connectedAccountingPlatform?.authLink) {
      downloadSyncManager(connectedAccountingPlatform.authLink);
      onNext();
    }
  };

  return (
    <StepLayoutPage
      title={`${translationPath}.title`}
      subtitle={`${translationPath}.subtitle`}
      nextLabel={`${translationPath}.action`}
      goExit={onExit}
      isLoading={!connectedAccountingPlatform?.authLink}
      isNextDisabled={!connectedAccountingPlatform?.authLink}
      onNext={onDownload}
      relativeStep={getQBDRelativeStep(1)}
      animatedIllustration={{
        animationData: syncManagerAnimation,
        height: 'auto',
      }}
    />
  );
};

export default SyncQBDPage;
