import { forwardRef, useStyleConfig } from '@chakra-ui/react';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

type IconInputProps = {
  name: IconNames;
  size?: string;
  isDisabled?: boolean;
  isFocus?: boolean;
  testId?: string | null;
};

export const IconInput = forwardRef<IconInputProps, 'div'>(({ name, testId, size, isDisabled, isFocus }, ref) => {
  const styles = useStyleConfig('IconInput', { isFocus });
  const iconSize = size === 'sm' ? IconSize.m : IconSize.lg;

  return (
    <Box __css={styles} aria-disabled={isDisabled}>
      <Icon name={name} size={iconSize} testId={testId} ref={ref} />
    </Box>
  );
});
