import { FloatedButtons } from './components/FloatedButtons';

type Props = {
  onDone: () => any;
  onCancel: () => any;
  doneLabel: string;
  doneValues?: Record<string, any>;
  cancelLabel: string;
  isDisabled?: boolean;
  isDoneDisabled?: boolean;
  className?: string;
  isProcessing?: boolean;
  titleLabel?: string;
};

export const MIFloatedEditDoneButtons = ({
  className,
  onDone,
  onCancel,
  doneLabel,
  doneValues,
  cancelLabel,
  isDisabled,
  isDoneDisabled,
  isProcessing,
  titleLabel,
}: Props) => (
  <FloatedButtons
    className={className}
    onDone={onDone}
    onCancel={onCancel}
    doneLabel={doneLabel}
    doneValues={doneValues}
    cancelLabel={cancelLabel}
    isDisabled={isDisabled}
    isDoneDisabled={isDoneDisabled}
    isProcessing={isProcessing}
    titleLabel={titleLabel}
  />
);
