import { featureFlags } from '@melio/shared-web';
import set from 'lodash/fp/set';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { analyticsApi } from 'src/analytics/api/guest-analytics-api';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICategoryList } from 'src/components/common/MICategoryList';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { profileStore } from 'src/modules/profile/profile-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { ContentWrapper, Footer, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import WhitePageAddressContainer from 'src/pages/vendor/components/WhitePageAddressContainer';
import { getDeliveryMethods, getIsLoggedIn, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { ModelView, useForm } from 'src/ui/form';
import { convertPaperCheck } from 'src/utils/address';
import { DeliveryType, FeatureFlags, FundingType, REGEX } from 'src/utils/consts';
import { formatCheckPrintName } from 'src/utils/delivery-methods';
import { CheckType } from 'src/utils/types';
import NewDeliveryMethodForm from '../components/NewDeliveryMethodForm';
import { vendorLocations } from '../locations';
import { LinkDeliveryMethod } from './components/LinkDeliveryMethod';
import {
  UnilateralDeliveryMethodType,
  UnilateralPaymentActions,
  UnilateralPaymentProps,
  UnilateralPaymentState,
} from './hoc/unilateral-payment-hooks';
import { getCategories, getCategoriesSelectTitle } from './utils';

type Props = {
  state: UnilateralPaymentState;
  actions: UnilateralPaymentActions;
  deliveryMethod: UnilateralDeliveryMethodType;
} & UnilateralPaymentProps;

export const VendorSelectDeliveryMethodPage = (props: Props) => {
  const history = useHistory();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const isMultiCompany = organizations.length > 1;
  const ownedVendorId = useSelector(getOwnedVendorId);
  const [isChecksDelaysEnabled] = featureFlags.useAnonymousFeature(FeatureFlags.USHolidaysChecks, false);
  const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);
  const {
    actions,
    deliveryMethod,
    state: { isPaymentLoading, isDeliveryMethodProcessing, deliveryType, payment, organization, filesUrls },
    token,
  } = props;
  const { vendorId, deliveryMethodId, unilateralRequestId, paymentId } = getJWTPayload(token);
  const vendor = useSelector(vendorsStore.selectors.fetch.byId(payment?.vendor?.id || vendorId));
  const virtualCardDeliveryMethod = vendor?.deliveryMethods?.find(
    (dm) => dm.deliveryType === DeliveryType.VIRTUAL_CARD
  );

  const isVirtualCardUnilateral = !!virtualCardDeliveryMethod && !!payment?.isEligibleToVirtualCard;
  const isVirtualCardDMSelected = deliveryMethod.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isCardFundingSource = payment?.fundingSource?.fundingType === FundingType.CARD;

  const categories = getCategories({
    payment,
    isVirtualCardUnilateral,
    isChecksDelaysEnabled,
    isGeneralCheckDelays,
    isCardFundingSource,
  });

  const analyticsProps = {
    flow: payment ? 'Unilateral with payment' : 'Unilateral without payment',
    vendorId,
    paymentId: payment?.id,
    vendorEmail: vendor?.contactEmail,
    payorOrgId: organization?.id,
    unilateralRequestId,
  };
  const { onChange, onSubmit } = actions;
  const [deliveryMethodMV, deliveryMethodMVActions] = useForm(deliveryMethod, {
    submit: (value) => {
      analytics.trackAction('save-delivery-method', analyticsProps);
      analyticsApi.track({ token, eventName: 'RequestDM-VendorCompleted', properties: { ...analyticsProps } });
      const { deliveryType, ...rest } = value;

      if (deliveryType) {
        if (deliveryType === 'check') {
          value.paperCheck.printName = formatCheckPrintName(value.paperCheck.printName);
        }

        return onSubmit({ deliveryType, ...rest }, false);
      }

      return Promise.reject(new Error('Delivery type not selected'));
    },
    onChange: ({ key, value, modelState }) => {
      let newState = modelState;

      if (key === 'paperCheck.printName' && value) {
        const isInvalid = REGEX.CHECK_PRINT_NAME.test(value);
        const formattedValue = value.replace(REGEX.CHECK_PRINT_NAME, '');

        if (isInvalid) {
          const { setError } = deliveryMethodMV.paperCheck.printName;
          setError && setError('inputErrors.deliveryMethodCheck.printName.any.invalidChar');
        }

        newState = set('paperCheck.printName', formattedValue, modelState);
      }

      return newState;
    },
  });
  const paperCheck = useMemo<CheckType>(() => convertPaperCheck(deliveryMethodMV.paperCheck as ModelView<CheckType>), [
    deliveryMethodMV.paperCheck,
  ]);
  const { isAddressLoading, whitePageAddress } = useStructuredSelectors(
    deliveryMethodsStore.selectors.manualAddress(payment?.deliveryMethodId || deliveryMethodId)
  );
  const [whitePageAddressView, setWhitePageAddressView] = useState(false);

  useEffect(() => {
    if (!categories) return;

    const displayedOptionsAnalyticsProperties = {
      organizationId: organization?.id,
      vendorId,
      deliveryMethodOptions: categories.map((c) => c.id),
      paymentId,
      unilateralRequestId,
    };
    analyticsApi.track({
      token,
      eventName: 'RequestDM-VendorOpened',
      properties: { ...displayedOptionsAnalyticsProperties },
    });
  }, []);

  useEffect(() => {
    setWhitePageAddressView(
      whitePageAddress &&
        !isEmpty(whitePageAddress) &&
        ((whitePageAddress.is_valid && whitePageAddress.diff) || !whitePageAddress.is_valid)
    );

    if (whitePageAddress && whitePageAddress.is_valid && !whitePageAddress.diff) {
      onSubmit({ deliveryType: DeliveryType.CHECK, paperCheck }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitePageAddress]);

  useEffect(() => {
    if (isVirtualCardDMSelected) {
      onSubmit(
        {
          deliveryType: DeliveryType.VIRTUAL_CARD,
          virtualCardAccount: virtualCardDeliveryMethod.virtualCardAccount,
        },
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVirtualCardDMSelected]);

  const selectDeliveryMethod = (type) => {
    onChange({ id: 'deliveryType', value: type });
  };

  const goSwitchCompany = () => {
    history.push(generatePath(vendorLocations.unilateral.switchCompany, { token }));
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  if (isLoggedIn && ownedVendorId && !isEmpty(orgDeliveryMethods)) {
    return (
      <OutsideLayout analyticsProps={analyticsProps}>
        <Header
          payment={payment}
          organization={organization}
          filesUrls={filesUrls}
          onPrev={isMultiCompany ? goSwitchCompany : null}
        />
        <ContentWrapper
          title={
            payment
              ? 'vendors.addDeliveryMethodByLink.linkExistingMethod.title'
              : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.linkExistingMethod.title'
          }
        >
          <LinkDeliveryMethod deliveryMethod={orgDeliveryMethods[0]} token={token} payment={payment} />
          <Footer companyName={organization?.companyName} />
        </ContentWrapper>
      </OutsideLayout>
    );
  }

  if (deliveryMethod.deliveryType && !isVirtualCardDMSelected) {
    if (whitePageAddressView) {
      return (
        <WhitePageAddressContainer
          setWhitePageAddressView={setWhitePageAddressView}
          isLoading={isDeliveryMethodProcessing || isAddressLoading}
          whitePageAddress={whitePageAddress}
          submit={onSubmit}
          model={deliveryMethodMV.paperCheck}
        />
      );
    }

    return (
      <OutsideLayout analyticsProps={analyticsProps}>
        <Header
          payment={payment}
          organization={organization}
          vendor={vendor}
          filesUrls={filesUrls}
          onPrev={() => selectDeliveryMethod('')}
          showFullHeader={!payment}
          subTitle={payment ? '' : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.subTitle'}
        />
        <ContentWrapper
          title={
            deliveryType === DeliveryType.ACH
              ? 'vendors.addDeliveryMethodByLink.achTitle'
              : 'vendors.addDeliveryMethodByLink.checkTitle'
          }
        >
          <NewDeliveryMethodForm
            submit={deliveryMethodMVActions.submit}
            selectedDeliveryMethod={deliveryType}
            achModel={deliveryMethodMV.bankAccount}
            checkModel={deliveryMethodMV.paperCheck}
          />
          <Button
            label={
              payment
                ? 'vendors.addDeliveryMethodByLink.submitCTA'
                : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.submitCTA'
            }
            size={ButtonSizes.lg}
            onClick={deliveryMethodMVActions.submit}
            isLoading={isDeliveryMethodProcessing || isAddressLoading}
            isFullWidth
          />
          <Footer companyName={organization?.companyName} />
        </ContentWrapper>
      </OutsideLayout>
    );
  }

  return (
    <OutsideLayout analyticsProps={analyticsProps}>
      <Header
        payment={payment}
        organization={organization}
        filesUrls={filesUrls}
        onPrev={isMultiCompany ? goSwitchCompany : null}
        showFullHeader
        note={payment?.note}
        subTitle={
          payment
            ? 'vendors.addDeliveryMethodByLink.subTitle'
            : 'vendors.addDeliveryMethodByLink.unilateralWithoutPayment.subTitle'
        }
      />
      <Box display={{ base: 'flex', sm: 'block' }} justifyContent="center">
        <ContentWrapper title={getCategoriesSelectTitle(payment, isVirtualCardUnilateral)}>
          <Flex mt={5} mb={-4}>
            <MICategoryList
              categories={categories}
              selectedId={deliveryType}
              onSelect={(type) => selectDeliveryMethod(type)}
              analyticsProps={analyticsProps}
            />
          </Flex>
        </ContentWrapper>
      </Box>
    </OutsideLayout>
  );
};
