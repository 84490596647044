import Box from 'src/core/ds/box';
import { ReactComponent as IllustrationSvg } from 'src/icons/illustrations.svg';
import { ReactComponent as SpriteSvg } from 'src/icons/sprite.svg';

const SpriteComponent = () => (
  <Box display="none">
    <SpriteSvg />
    <IllustrationSvg />
  </Box>
);

export { SpriteComponent };
