import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { FULL_STORY_MASK_RULE_CLASS, MiMoneyFlavor } from 'src/utils/consts';

const getFlavorColor = (props) => {
  const mapFlavorToColor = {
    [MiMoneyFlavor.POSITIVE_WHITE]: props.theme.text.color.white,
    [MiMoneyFlavor.POSITIVE]: props.theme.text.color.green,
    [MiMoneyFlavor.NEGATIVE]: props.theme.text.color.red,
  };

  return mapFlavorToColor[props.flavor] || props.theme.text.color.main;
};

type SizeType = 'huge' | 'extraLarge' | 'large' | 'normal' | 'small' | 'extraSmall';

type FontWeightType = 'regular' | 'semiBold';

type Props = {
  amount: number | string | null;
  currency?: string;
  size?: SizeType;
  fontWeight?: FontWeightType;
  flavor?: MiMoneyFlavor;
  className?: string;
  search?: string;
  showAmountArrowIcon?: boolean;
  testId?: string;
  privateData?: boolean;
};

export const MIMoney = ({
  currency = '$',
  amount,
  size = 'normal',
  fontWeight,
  flavor = MiMoneyFlavor.DEFAULT,
  className,
  search,
  showAmountArrowIcon,
  testId,
  privateData,
}: Props) => {
  const formattedAmount = Number(amount || 0).toFixed(2);
  const fraction = formattedAmount.split('.')[1];
  const isPositive = flavor === MiMoneyFlavor.POSITIVE;
  let roundAmountSearch = search;
  let fractionAmountSearch = search;

  if (search) {
    const searchParts = search.split('.');

    if (searchParts.length === 2 && searchParts.every((searchPart) => !Number.isNaN(searchPart as any))) {
      [roundAmountSearch, fractionAmountSearch] = searchParts;
    }
  }

  return (
    <AmountContainer data-testid={testId} className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined}>
      {showAmountArrowIcon && (
        <Flex transform={isPositive ? 'rotate(180deg)' : 'rotate(0deg)'}>
          <Icon name={IconNames.caretDown} size={IconSize.lg} color={isPositive ? 'green.500' : 'black'} />
        </Flex>
      )}
      <Balance className={className} size={size} fontWeight={fontWeight}>
        <IntegerBalance size={size} flavor={flavor}>
          <MIFormattedCurrency value={(amount || 0).toString()} currency={currency} search={roundAmountSearch} round />
        </IntegerBalance>
        <FractionBalance size={size} flavor={flavor}>
          <Dot>.</Dot>
          <Highlighter searchWords={[fractionAmountSearch]} autoEscape textToHighlight={fraction} />
        </FractionBalance>
      </Balance>
    </AmountContainer>
  );
};

const Balance = styled.div<{ size?: SizeType; fontWeight?: FontWeightType }>`
  font-weight: ${(props) =>
    props.size === 'huge' || props.size === 'extraLarge' || props.fontWeight === 'regular'
      ? props.theme.text.weight.regular
      : props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.MIMoney?.Balance}
`;

const IntegerBalance = styled.span<{ size?: SizeType; flavor?: MiMoneyFlavor }>`
  color: ${getFlavorColor};
  display: table-cell;
  vertical-align: top;
  line-height: 1;
  font-size: ${(props) => {
    if (props.size === 'huge') {
      return '9rem';
    } else if (props.size === 'extraLarge') {
      return '6.4rem';
    } else if (props.size === 'large') {
      return '3.4rem';
    } else if (props.size === 'normal') {
      return '2.3rem';
    } else if (props.size === 'extraSmall') {
      return '1.2rem';
    }

    return '1.6rem';
  }};

  .negative-marker {
    color: ${(props) => props.theme.text.color.error};
  }
  ${(props) => props.theme?.components?.MIMoney?.IntegerBalance}
`;

const Dot = styled.span`
  display: none;
  ${(props) => props.theme?.components?.MIMoney?.Dot}
`;

const FractionBalance = styled.span<{
  size?: SizeType;
  flavor?: MiMoneyFlavor;
}>`
  color: ${getFlavorColor};
  font-weight: ${(props) =>
    props.size === 'small' || props.size === 'extraSmall'
      ? props.theme.text.weight.regular
      : props.theme.text.weight.semiBold};
  display: table-cell;
  vertical-align: top;
  font-size: ${(props) => {
    if (props.size === 'huge') {
      return '5rem';
    } else if (props.size === 'extraLarge') {
      return '3rem';
    } else if (props.size === 'large') {
      return '1.6rem';
    } else if (props.size === 'normal') {
      return '1.2rem';
    } else if (props.size === 'extraSmall') {
      // For some reason `rem` size is ignored
      return '7px';
    }

    return '1rem';
  }};

  padding-left: ${(props) => {
    if (props.size === 'huge') {
      return '1rem';
    } else if (props.size === 'extraLarge') {
      return '0.7rem';
    } else if (props.size === 'large') {
      return '0.6rem';
    } else if (props.size === 'normal') {
      return '0.2rem';
    } else if (props.size === 'extraSmall') {
      return '0.1rem';
    }

    return '0.15rem';
  }};
  padding-top: ${(props) => {
    if (props.size === 'large') {
      return '0.2rem';
    }

    return '0';
  }};
  ${(props) => props.theme?.components?.MIMoney?.FractionBalance}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.MIMoney?.AmountContainer}
`;
