import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { VendorOptionType } from 'src/pages/vendor-directory/select-vendor/types';
import { getVendorBillerId } from 'src/pages/vendor-directory/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { FORM_MAX_LENGTH } from 'src/utils/consts';
import { VendorType } from 'src/utils/types';

export type MissingAccountNumberModalProps = {
  vendorId: number;
  onSubmit: () => void;
  dismiss: () => void;
  isLoading: boolean;
};

type MissingAccountNumberModelType = Partial<VendorType> & {
  currentOption?: VendorOptionType;
};

const eventPage = 'missing-account-number-modal';

export const MissingAccountNumberModal = ({
  vendorId,
  onSubmit,
  dismiss,
  isLoading = false,
}: MissingAccountNumberModalProps) => {
  const orgId = useSelector(getOrgId);
  const vendorActions = useStoreActions(vendorsStore);
  const { vendor, isVendorLoading } = useFetchVendor(vendorId);
  const billerId = vendor && getVendorBillerId(vendor);

  useEffect(() => {
    if (billerId && vendorId) {
      analytics.track(eventPage, 'show-modal', {
        billerId,
        vendorId,
      });
    }
  }, [billerId, vendorId]);

  const model = useMemo<MissingAccountNumberModelType>(
    () => ({
      accountIdentifier: '',
    }),
    []
  );

  const [mv, { submit }, , loading] = useForm(model, {
    submit: async ({ accountIdentifier }) => {
      const newVendor = {
        ...vendor,
        billerId,
        accountIdentifier,
      };
      await vendorActions.update({ orgId, ...newVendor });
      onSubmit();
    },
  });

  return (
    <ModalMessage
      id={eventPage}
      isLoading={isVendorLoading}
      onCloseClick={dismiss}
      alignLeft
      titleComponent={
        <>
          <Box textStyle="h2Semi">
            <MIFormattedText
              label="vendorDirectory.missingAccountNumberModal.title"
              values={{ vendorName: vendor?.companyName }}
            />
          </Box>
          <Box color="grey.700" mt={3} maxW="43rem" textStyle="body4">
            <MIFormattedText
              label="vendorDirectory.missingAccountNumberModal.subTitle"
              values={{ vendorName: vendor?.companyName }}
            />
          </Box>
        </>
      }
      contentComponent={
        <TextField
          id="accountIdentifier"
          label="vendorDirectory.missingAccountNumberModal.inputField.title"
          placeholder="vendorDirectory.missingAccountNumberModal.inputField.placeholder"
          maxLength={FORM_MAX_LENGTH.NOTE}
          model={mv.accountIdentifier}
          size="sm"
          mb={0}
          autoFocus
          isRequired
        />
      }
      buttonComponent={
        <Flex justify="flex-end">
          <Button
            size={ButtonSizes.md}
            variant={ButtonVariants.primary}
            onClick={submit}
            isLoading={isLoading || loading}
            label="vendorDirectory.missingAccountNumberModal.saveAndContinueButton"
          />
        </Flex>
      }
    />
  );
};
