import { CardResponse } from '@melio/spend-management-api-axios-client';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { cardsApi } from '../api';
import { CardsListParams, UseCardsOptions } from '../types';

export const useCards = ({ status = [], accountId }: UseCardsOptions) => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: getCards, result, loading, error } = useApi<[CardsListParams], CardResponse[]>({
    api: cardsApi.list,
  });

  useEffect(() => {
    getCards({ orgId, status, accountId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    cards: result,
    refetch: ({ status = [], accountId }: UseCardsOptions) => getCards({ orgId, status, accountId }),
  };
};
