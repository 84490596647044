import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';

type CheckedFilterMenuItemProps = {
  isChecked: boolean;
  value: string;
};

export const CheckedFilterMenuItem = ({ isChecked, value }: CheckedFilterMenuItemProps) => (
  <Flex alignItems="center" width="100%">
    <Checkbox isChecked={isChecked} value={value} mr={2} data-testid={`getPro.statusFilter.${value}`} />
    <MIFormattedText label={`getPro.status.${value}`} />
  </Flex>
);
