import { createReducer } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import { generatePath } from 'react-router-dom';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { clearStateReducer } from 'src/helpers/redux/clearStateReducer';
import { composeReducers } from 'src/helpers/redux/composeReducers';
import { composeSagas } from 'src/helpers/redux/composeSlice';
import { billLocations } from 'src/pages/bill/locations';
import { companiesLocations } from 'src/pages/companies/locations';
import { GlobalState } from 'src/redux/types';
import { updateUserPreferenceAction } from 'src/redux/user/actions';
import { CLEAR_STATE, SET_PROFILE, UPDATE_USER_PREFERENCE_SUCCESS } from 'src/redux/user/actionTypes';
import { checkAndInitUserInfo } from 'src/redux/user/sagas';
import { analytics } from 'src/services/analytics';
import { AccessLevel, CompanyType, Role } from 'src/utils/consts';
import { permissions, PermissionsType } from 'src/utils/permissions';
import { CompanyInfoType, UserContextType, UserOrganization, UserPreferencesType } from 'src/utils/types';
import usersStore from '../users/users-store';
import changePasswordStore from './change-password-store';

export type ChangePasswordParams = {
  currentPassword: string;
  password: string;
  confirmedPassword: string;
};

export const SWITCH_ORGANIZATION = '[PROFILE] SWITCH_ORGANIZATION';
export const SWITCH_ORGANIZATION_FINISH = '[PROFILE] SWITCH_ORGANIZATION_FINISH';

const eventPage = 'change-password';

const initialState = {
  currentUserId: null,
  changingPassword: false,
  userPreferences: {},
  currentOrganizationId: null,
};

const profileReducer = createReducer(initialState, {
  [SET_PROFILE]: (state, action) => {
    state.currentUserId = action.profile.id;
    state.currentOrganizationId = action.profile.orgId;
    state.userPreferences = action.profile.userPreferences;
  },
  [UPDATE_USER_PREFERENCE_SUCCESS]: (state, action) => {
    state.userPreferences[action.id] = action.value;
  },
});

type SwitchOrganizationSagaPayloadType = {
  type: typeof SWITCH_ORGANIZATION;
  organizationId: number;
  isSwitchToAccountingFirm?: boolean;
};

function* onSwitchOrganization({ organizationId, isSwitchToAccountingFirm }: SwitchOrganizationSagaPayloadType) {
  yield put({ type: CLEAR_STATE });
  yield all([
    call(checkAndInitUserInfo, { orgId: organizationId }),
    put(
      push(
        isSwitchToAccountingFirm
          ? generatePath(companiesLocations.index, { orgId: organizationId })
          : generatePath(billLocations.index, { orgId: organizationId })
      )
    ),
  ]);
  yield put({ type: SWITCH_ORGANIZATION_FINISH });
}

function* switchOrganizationSaga() {
  yield takeEvery(SWITCH_ORGANIZATION, onSwitchOrganization);
}

const saga = composeSagas([changePasswordStore.saga, switchOrganizationSaga]);
const reducer = composeReducers(
  initialState,
  profileReducer,
  changePasswordStore.reducer,
  clearStateReducer(initialState)
);

export const profileStore = {
  reducer,
  saga,
  selectors: {
    profile(state: GlobalState) {
      return state.users.byId[state.profile.currentUserId];
    },
    preferences(state: GlobalState) {
      return state.profile.userPreferences;
    },
    userLoading(state: GlobalState) {
      return state.users.meta[state.profile.currentUserId]?.loading;
    },
    changingPassword(state: GlobalState) {
      return state.profile.changingPassword;
    },
    getCurrentOrgId(state: GlobalState) {
      return state.profile.currentOrganizationId;
    },
    getCurrentUserId(state: GlobalState) {
      return state.profile.currentUserId;
    },
    getCurrentOrg(state: GlobalState) {
      return state.organizations.byId[state.profile.currentOrganizationId];
    },
    getOrganizations(state: GlobalState): CompanyInfoType[] {
      const currentUser = profileStore.selectors.profile(state);

      return sortBy<UserOrganization>(currentUser?.userOrganizations || [], 'createdAt')
        .filter(({ isHidden }) => !isHidden)
        .map((userOrg) => state.organizations.byId[userOrg.organizationId])
        .filter((org) => !!org);
    },
    getHiddenUserOrganizations(state: GlobalState): CompanyInfoType[] {
      const currentUser = profileStore.selectors.profile(state);

      return sortBy<UserOrganization>(currentUser?.userOrganizations || [], 'createdAt')
        .filter(({ isHidden }) => isHidden)
        .map((userOrg) => state.organizations.byId[userOrg.organizationId])
        .filter((org) => !!org);
    },
    getFirstAccountingFirm(state: GlobalState) {
      const organizations = profileStore.selectors.getOrganizations(state);

      return organizations.find((org) => org.companyType === CompanyType.ACCOUNTING_FIRM);
    },
    getUserOrganization(state: GlobalState) {
      const currentUser = profileStore.selectors.profile(state);

      return (
        currentUser &&
        currentUser.userOrganizations.find((o) => o.organizationId === state.profile.currentOrganizationId)
      );
    },
    getUserRole(state: GlobalState): Role {
      return profileStore.selectors.getUserOrganization(state)?.role || Role.OWNER;
    },
    accessLevel(state: GlobalState) {
      return profileStore.selectors.getUserOrganization(state)?.accessLevel || AccessLevel.FULL;
    },
    isAdmin(state: GlobalState) {
      const role = profileStore.selectors.getUserRole(state);

      return [Role.ADMIN, Role.OWNER].includes(role);
    },
    isMemberOfAccountingFirm(state: GlobalState) {
      const organizations = profileStore.selectors.getOrganizations(state);

      return organizations.some((org) => org.companyType === CompanyType.ACCOUNTING_FIRM);
    },
    getPermissions(state: GlobalState): PermissionsType {
      return permissions[profileStore.selectors.getUserRole(state)];
    },
    org(orgId: number) {
      return {
        getPermissions(state: GlobalState): PermissionsType {
          const userId = profileStore.selectors.getCurrentUserId(state);
          const role = usersStore.selectors.userOrganization(userId, orgId).userOrg(state)?.role || Role.OWNER;

          return permissions[role];
        },
      };
    },
  },
  actions: {
    update(payload: Partial<UserContextType>) {
      return usersStore.actions.update(payload);
    },
  },
  validate: usersStore?.validate,
};

export function getProfileActions(dispatch: any) {
  return {
    async update(profile: UserContextType, changes: Partial<UserContextType>) {
      return dispatch(
        profileStore.actions.update({
          id: profile.id,
          ...changes,
        })
      );
    },
    changePassword(credentials: ChangePasswordParams) {
      return dispatch(changePasswordStore.actions(credentials)).catch((error) => {
        if (error.error.message === 'INVALID_CREDENTIALS') {
          error.error.validationErrors = {
            currentPassword: 'profile.changePassword.error.wrongCurrentPassword',
          };
        }

        analytics.track(eventPage, 'password-validation-error', error?.error);

        throw error;
      });
    },
    async updatePreferences(preferences: UserPreferencesType, changes: Partial<UserPreferencesType>) {
      forEach(changes, (value, key) => {
        dispatch(updateUserPreferenceAction(key, value ?? null));
      });
    },
    switchOrganization(organizationId: number, isSwitchToAccountingFirm?: boolean) {
      dispatch({
        type: SWITCH_ORGANIZATION,
        organizationId,
        isSwitchToAccountingFirm,
      });
    },
  };
}
