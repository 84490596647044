import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useConnectedAccountingPlatform } from 'src/hooks/useConnectedAccountingPlatform';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { getDeliveryMethods, getOrgId } from 'src/redux/user/selectors';
import { SyncStatus } from 'src/utils/consts';
import { AccountingPlatform, DeliveryMethodType } from 'src/utils/types';

type UseFetchAccountingPlatformsAndDataType = {
  shouldShowMatchBankAccount: boolean;
  notConnectedDeliveryMethods: readonly DeliveryMethodType[];
  isConnected: boolean;
  status: { loading: boolean; error: unknown };
  accountingPlatforms: readonly AccountingPlatform[];
  connectedAccountingPlatform?: AccountingPlatform;
  isActive?: boolean;
};

export const useFetchAccountingPlatformsAndData = (forceReload = false): UseFetchAccountingPlatformsAndDataType => {
  const orgId = useSelector(getOrgId);
  const deliveryMethods = useSelector(getDeliveryMethods);
  const { isConnected, connectedAccountingPlatform } = useConnectedAccountingPlatform();
  const notConnectedDeliveryMethods =
    connectedAccountingPlatform?.syncStatus !== SyncStatus.SYNCED
      ? []
      : deliveryMethods.filter((dm) => !dm.intuitAccountId);
  const status = useSelector(accountingPlatformsStore.selectors.list.status({ orgId }));
  const accountingPlatformsActions = useStoreActions(accountingPlatformsStore);
  const [reloaded, setReloaded] = useState(forceReload);

  useEffect(() => {
    if (isEmpty(status) || reloaded) {
      setReloaded(false);
      accountingPlatformsActions.list({ orgId });
    }
  }, [accountingPlatformsActions, orgId, reloaded, status]);

  const shouldShowMatchBankAccount =
    isConnected &&
    notConnectedDeliveryMethods.length > 0 &&
    connectedAccountingPlatform?.paymentAccountReconciliationEnabled === true;

  const accountingPlatforms = useSelector((state) => accountingPlatformsStore.selectors.list.value(state, { orgId }));

  const isActive = connectedAccountingPlatform?.isActive;

  return {
    shouldShowMatchBankAccount,
    notConnectedDeliveryMethods,
    isConnected,
    connectedAccountingPlatform,
    status,
    accountingPlatforms,
    isActive,
  };
};
