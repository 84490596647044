import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { UserOrganization } from 'src/utils/types';
import { getUserOrganization } from 'src/utils/user';

export type ActionType = 'transfer-ownership' | 'edit' | 'delete' | 'manage-user-organizations';

type Props = {
  user: { id?: number; userOrganizations: UserOrganization[] };
  orgId: number;
};

type CallbackFn = (actionType: ActionType) => any;

export function useGetUserActions<T>({ user, orgId }: Props, callbackFn: CallbackFn) {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const currentUser = useSelector(profileStore.selectors.profile);

  const userOrgToEdit = getUserOrganization(user, orgId);

  const isCurrentUser = currentUser.id === user.id;
  const actions: T[] = [];

  if (!isCurrentUser) {
    if (permissions.userManagement.manageUserOrganizations(userOrgToEdit?.role)) {
      callAndPush(actions, 'manage-user-organizations', callbackFn);
    }

    if (permissions.userManagement.transferOwnership()) {
      callAndPush(actions, 'transfer-ownership', callbackFn);
    }

    if (permissions.userManagement.update(userOrgToEdit?.role)) {
      callAndPush(actions, 'edit', callbackFn);
    }

    if (permissions.userManagement.delete(userOrgToEdit?.role)) {
      callAndPush(actions, 'delete', callbackFn);
    }
  }

  return actions;
}

function callAndPush(actions: any[], actionType: ActionType, callbackFn) {
  const callbackResult = callbackFn(actionType);

  if (callbackResult) {
    actions.push(callbackResult);
  }
}
