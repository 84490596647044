import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { ApprovalDecision, BatchApprovalDecisionUpdateResponse, BatchItemStatus, paymentsApi } from './api';

const name = 'payments';

export enum Status {
  ERROR,
  LOADING,
  IDLE,
}

type State = {
  batchSetApprovalDecision: {
    status?: Status;
    results?: BatchItemStatus[];
  };
};

type Payload = {
  orgId: number;
  decisions: Array<ApprovalDecision>;
};

export const batchSetApprovalDecisionSlice = createApiCallSlice<Payload, State, BatchApprovalDecisionUpdateResponse>({
  name: generateSliceName(name, 'batch_set_approval_decision'),
  api: paymentsApi.batchSetApprovalDecision,
  initialState: {
    batchSetApprovalDecision: {},
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.batchSetApprovalDecision.status = Status.LOADING;
      state.batchSetApprovalDecision.results = undefined;
    },
    [ON_SUCCESS]: (state, action) => {
      state.batchSetApprovalDecision.status = Status.IDLE;
      state.batchSetApprovalDecision.results = action.payload.statuses;
    },
    [ON_FAILURE]: (state) => {
      state.batchSetApprovalDecision.status = Status.ERROR;
    },
  },
  selectors: {
    status: (state: any) => state[name].batchSetApprovalDecision.status,
    results: (state) => state[name].batchSetApprovalDecision.results,
  },
});
