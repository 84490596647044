import { ChangeEvent, ReactNode } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { FormControl, FormControlProps, FormLabel } from 'src/core/ds/form';
import { Input, InputGroup, InputProps } from 'src/core/ds/input';
import { ModelViewField } from 'src/ui/form';
import { AfterLabelTextComponent, ErrorMessage, HelperText, OptionalLabel } from './components';

export type Props = FormControlProps &
  InputProps & {
    id?: string;
    model?: ModelViewField<string>;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    size?: 'sm' | 'md';
    label?: string;
    labelValues?: Record<string, unknown>;
    helperText?: string;
    helperTextValues?: Record<string, unknown>;
    errorMessage?: string | null;
    errorMessageValues?: Record<string, unknown>;
    disableSearch?: boolean;
    privateData?: boolean;
    inputLeftElement?: ReactNode;
    optionalLabelText?: string;
    afterLabelComponent?: ReactNode;
    inputRightElement?: ReactNode;
    wrapText?: boolean;
  };

export const TextField = ({
  id,
  testId,
  model,
  isRequired = false,
  isViewOnly,
  size = 'md',
  helperText,
  helperTextValues,
  label,
  labelValues,
  errorMessage,
  errorMessageValues,
  onChange,
  value,
  disableSearch,
  privateData = false,
  inputLeftElement,
  optionalLabelText,
  afterLabelComponent,
  inputRightElement,
  wrapText = false,
  ...rest
}: Props) => {
  const isError = Boolean(errorMessage) || Boolean(model?.error);
  const inputId = id || model?.id;
  const inputValue = value || model?.value || '';
  const noticesTestId = `input-${inputId}-notices`;
  const errorMessageText = errorMessage || model?.error;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange ? onChange(event) : model?.onChange({ value: event?.target.value });
  };

  return (
    <FormControl isInvalid={isError} size={size} {...rest}>
      <FormLabel htmlFor={inputId} hidden={isViewOnly} disabled-search={disableSearch ? 'true' : undefined}>
        <MIFormattedText label={label} values={labelValues} />
        {!isRequired && <OptionalLabel optionalLabelText={optionalLabelText} isError={isError} />}
        {afterLabelComponent && <AfterLabelTextComponent>{afterLabelComponent}</AfterLabelTextComponent>}
      </FormLabel>

      <InputGroup>
        {!!inputLeftElement && inputLeftElement}
        {wrapText && isViewOnly ? (
          <Box textStyle="body2" color={inputValue ? 'black' : 'grey.500'} data-testid={`input-${id}`}>
            {inputValue || <MIFormattedText label={rest.placeholder} />}
          </Box>
        ) : (
          <Input
            id={inputId}
            testId={testId}
            pl={inputLeftElement ? 8 : 0}
            onChange={handleChange}
            value={inputValue}
            size={size}
            privateData={privateData}
            isViewOnly={isViewOnly}
            disabled-search={disableSearch ? 'true' : undefined}
            {...rest}
          />
        )}
        {!!inputRightElement && inputRightElement}
      </InputGroup>
      {!isError ? (
        <HelperText testId={noticesTestId} label={helperText} labelValues={helperTextValues} />
      ) : (
        errorMessageText && (
          <ErrorMessage size={size} testId={noticesTestId} label={errorMessageText} labelValues={errorMessageValues} />
        )
      )}
    </FormControl>
  );
};
