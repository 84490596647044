import { differenceInCalendarDays } from 'date-fns';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/fp/filter';
import flow from 'lodash/fp/flow';
import maxBy from 'lodash/fp/maxBy';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import moment from 'moment';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import { AccountRecord } from 'src/pages/settings/records';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { PayItemPaymentType } from 'src/pay/types';
import { getVendorCompanyName, isVirtualCardExpired } from 'src/utils/bills';
import {
  AccountType,
  BillType,
  DeliveryOptionType,
  NotePlaceholderType,
  PaymentApprovalActionType,
  PaymentType,
  TransactionType,
  VirtualCardType,
} from 'src/utils/types';
import { uuid } from 'src/utils/uuid';
import {
  CardTypes,
  DeliveryType,
  FailedFinancingPaymentType,
  FailedPaymentMessage,
  FailedPaymentType,
  FAST_DELIVERY_TYPES,
  InvoiceStatus,
  PaymentApprovalStatus,
  PaymentCollectStatus,
  PaymentDeliverStatus,
  PaymentStatus,
  PaymentTypes,
  REFUND_COLLECT_STATUSES,
  RETURNED_CHECK_REASONS,
  RiskStatus,
  TransactionStatuses,
  UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD,
} from './consts';
import { getFundingSourceName } from './funding-sources';

export const getNotePlaceholder = (
  notePlaceholder?: NotePlaceholderType | null,
  isEditable?: boolean | null,
  isRequest?: boolean | null
) => {
  if (notePlaceholder) {
    return isEditable && notePlaceholder?.edit ? notePlaceholder.edit : notePlaceholder.view;
  }

  return isRequest ? 'requests.form.paymentActivity.emptyNoteToCustomerLabel' : 'bills.pay.confirm.emptyMemoLabel';
};

const getFailedFinancingPaymentType = (payment: PaymentType): FailedFinancingPaymentType => {
  const paymentCanceled = isPaymentCanceled(payment);
  const isCheckDeliveryMethod = payment.deliveryMethod.deliveryType === DeliveryType.CHECK;

  if (isCheckDeliveryMethod) {
    return paymentCanceled
      ? FailedFinancingPaymentType.canceledInstallmentsCheck
      : FailedFinancingPaymentType.failedToDeliverCheck;
  }

  return paymentCanceled
    ? FailedFinancingPaymentType.canceledInstallmentsAch
    : FailedFinancingPaymentType.failedToDeliverAch;
};

export const getFailedPaymentDescription = (payment: PaymentType, bill: BillType, isEmbeddedMode: boolean) => {
  const { metadata, deliveryMethod, voidChecks } = payment;
  const isInternationalPayment = isInternationalDeliveryMethod(deliveryMethod?.deliveryType);
  const isReturnedCheckPayment = isReturnedCheck(payment);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const returnedCheckReason = isReturnedCheckPayment ? voidChecks[0].reason : '';
  const formattedReturnedCheckReason = isReturnedCheckPayment && getFormattedReturnedCheckReason(returnedCheckReason);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const fundingSource = AccountRecord(payment?.fundingSource);
  const showDisplayName = false;
  const isFailedRefund = isPaymentFailedRefund(payment);
  const isFinancing = isFinancedPayment(payment);
  let key = metadata?.failureMessage;

  const defaultFailureKey =
    metadata?.failedType === FailedPaymentType.FAILED_TO_DELIVER ? 'defaultFailedToDeliver' : 'defaultFailedToCollect';

  if (isInternationalPayment) key = defaultFailureKey;

  if (!key) {
    if (isEmbeddedMode) {
      key = 'default';
    } else {
      key = defaultFailureKey;
    }
  }

  if (isFailedRefund) {
    key = 'failedRefundPayment';
  }

  if (isFinancing) {
    key = getFailedFinancingPaymentType(payment);
  }

  return {
    label: `bills.form.paymentActivity.failedPaymentDescription.${key}`,
    values: {
      fundingSource: getFundingSourceName(fundingSource, showDisplayName),
      vendorCompanyName: getVendorCompanyName(bill),
      returnedCheckReason,
      formattedReturnedCheckReason,
    },
  };
};

export const getFailedPaymentTitle = (payment: PaymentType): string => {
  const { metadata } = payment;
  const isFailedRefund = isPaymentFailedRefund(payment);
  const isReturnedCheckPayment = isReturnedCheck(payment);
  const isFinancing = isFinancedPayment(payment);

  if (isFinancing) {
    const key = getFailedFinancingPaymentType(payment);

    return `bills.status.${key}`;
  }

  if (isReturnedCheckPayment) {
    return 'bills.status.paymentWithCheckDeliveryUnsuccessful';
  }

  if (isFailedRefund) {
    return 'bills.status.paymentRefundFailed.messageTitle';
  }

  return metadata?.failureMessage === FailedPaymentMessage.VIRTUAL_CARD_EXPIRED
    ? 'bills.status.paymentFailedActionResend'
    : 'bills.status.paymentFailedActionRequired';
};

const getFormattedReturnedCheckReason = (reason: string) => {
  if (!isEmpty(reason)) {
    return RETURNED_CHECK_REASONS[reason];
  }

  return '';
};

export const getLatestPayment = <P extends PaymentType | PayItemPaymentType>(payments: P[]): P | undefined =>
  maxBy<P>((payment) => moment(payment.createdAt).unix())(payments);

export const getCheckDepositedDate = (transactions: TransactionType[]) => {
  const transaction = (transactions || []).find(
    (t) => t.status === 'SYSTEM' && t.rawData && t.rawData.description === 'Posted Check'
  );

  return get(transaction, 'rawData.line.DateTxn');
};

export const getLastCreatedVirtualCard = (payment: PaymentType): VirtualCardType | undefined =>
  maxBy<VirtualCardType>((virtualCard) => moment(virtualCard.createdAt).unix())(payment?.virtualCards || []);

export const getFailedPaymentDate = (payment?: PaymentType) => {
  if (payment && isVirtualCardExpired(payment)) {
    const virtualCard = getLastCreatedVirtualCard(payment);

    return virtualCard?.canceledDate;
  }

  if (payment?.metadata?.paymentType === PaymentTypes.REFUND) {
    return payment?.metadata?.paymentStatusDates?.refundFailureDate;
  }

  if (payment?.riskStatus === RiskStatus.DECLINED) {
    const lastDeclinedApprovalAction = flow(
      filter<PaymentApprovalActionType>((action) => action.result === 'declined'),
      maxBy((action) => moment(action.createdAt).unix())
    )(payment.paymentApprovalActions);

    return lastDeclinedApprovalAction?.createdAt;
  }

  const lastFailedTransaction = flow(
    filter<TransactionType>((t) => t.status === TransactionStatuses.FAILED),
    maxBy((t) => moment(t.createdAt).unix())
  )(payment?.transactions);

  return lastFailedTransaction?.createdAt;
};

export const getDeliveryPreference = (deliveryOptions: any, deliveryPreference?: string) => {
  const id = findIndex(deliveryOptions, ['type', deliveryPreference]);
  const selectedId = id < 0 ? 0 : id;

  return selectedId.toString();
};

type ShouldGoBackToFastPaymetOfferPageParams = {
  deliveryOptions?: DeliveryOptionType[];
  deliveryType: DeliveryType;
  deliveryPreference?: string;
};

export const shouldGoBackToFastPaymetOfferPage = ({
  deliveryOptions,
  deliveryType,
  deliveryPreference = '',
}: ShouldGoBackToFastPaymetOfferPageParams) => {
  const isRegularPayment = !FAST_DELIVERY_TYPES.includes(deliveryPreference);
  const isDeliveryTypeLegit = deliveryType === DeliveryType.CHECK || deliveryType === DeliveryType.ACH;
  const areSeveralDeliveryOptionsPresent = deliveryOptions && deliveryOptions.length > 1;

  return isRegularPayment && isDeliveryTypeLegit && areSeveralDeliveryOptionsPresent;
};

type PaymentTagProps = {
  label: string;
  statusLabel: string;
  date: string | Date | null | undefined;
};

const getCompletedPaymentTag = (payment: PaymentType): PaymentTagProps => {
  if (payment.manual) {
    return {
      label: 'bills.form.partialPayments.review',
      statusLabel: 'bills.form.partialPayments.status.manuallyPaid',
      date: payment.createdAt,
    };
  }

  return {
    label: 'bills.form.partialPayments.review',
    statusLabel: 'bills.form.partialPayments.status.paid',
    date: payment.paidDate,
  };
};

const getFailedPaymentTag = (payment: PaymentType): PaymentTagProps => {
  if (payment.approvalDecisionStatus === PaymentApprovalStatus.DECLINED) {
    const approvalWorkflowsDecisionDate = payment.paymentApprovalDecisions?.find(
      (decision) => decision.status === PaymentApprovalStatus.DECLINED
    )?.createdAt;

    return {
      label: 'bills.form.partialPayments.review',
      statusLabel: 'bills.form.partialPayments.status.declined',
      date: approvalWorkflowsDecisionDate || payment.approvalDecisionDate,
    };
  }

  if (payment.metadata?.paymentType === PaymentTypes.REFUND) {
    return getRefundPaymentTag(payment);
  }

  return {
    statusLabel: 'bills.form.partialPayments.status.failed',
    label: 'bills.form.partialPayments.reviewAndResolve',
    date: getFailedPaymentDate(payment),
  };
};

const getRefundPaymentTag = (payment: PaymentType): PaymentTagProps => {
  if (isPaymentFailedRefund(payment)) {
    return {
      statusLabel: 'bills.form.partialPayments.status.refundFailed',
      label: 'bills.form.partialPayments.review',
      date: payment.metadata?.paymentStatusDates?.refundFailureDate,
    };
  }

  if (isPaymentCompletedRefund(payment)) {
    return {
      statusLabel: 'bills.form.partialPayments.status.refundCompleted',
      label: 'bills.form.partialPayments.trackRefund',
      date: payment.metadata?.paymentStatusDates?.refundCompleted,
    };
  }

  return {
    statusLabel: 'bills.form.partialPayments.status.refundScheduled',
    label: 'bills.form.partialPayments.trackRefund',
    date: payment.metadata?.paymentStatusDates?.refundInitiated,
  };
};

export const getPaymentTag = (payment: PaymentType) => {
  if (payment.status === PaymentStatus.COMPLETED) {
    return getCompletedPaymentTag(payment);
  } else if (payment.status === PaymentStatus.FAILED) {
    return getFailedPaymentTag(payment);
  }

  return {
    label: 'bills.form.partialPayments.review',
    statusLabel: 'bills.form.partialPayments.status.scheduled',
    date: payment.scheduledDate,
  };
};

export const isPaymentCancellationInProgress = (payment: PaymentType): boolean => {
  if (payment.status !== PaymentStatus.FAILED) {
    return false;
  }

  const isCollectionStatusCancellationInProgress =
    payment.collectStatus &&
    [PaymentCollectStatus.CANCEL_SCHEDULED, PaymentCollectStatus.CANCEL_IN_PROGRESS].includes(payment.collectStatus);

  const isDeliveryStatusCancellationInProgress =
    !!payment.deliverStatus &&
    [PaymentDeliverStatus.CANCEL_SCHEDULED, PaymentDeliverStatus.CANCEL_IN_PROGRESS].includes(payment.deliverStatus);

  return isCollectionStatusCancellationInProgress || isDeliveryStatusCancellationInProgress;
};

export const getPaymentById = <P extends PaymentType | PayItemPaymentType>(
  payments: readonly P[],
  paymentId?: string
) => payments.find((payment) => payment.id.toString() === paymentId?.toString());

export const isDirectPayment = (payment: PaymentType) => payment.deliveryMethod?.deliveryType === DeliveryType.RPPS;

export const isVirtualCardPayment = (payment: PaymentType) =>
  payment.deliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD;

export const isEligibleToReceiveVirtualCard = (fundingSource: AccountType): boolean => {
  const blockedToVirtualCard =
    fundingSource?.cardAccount?.cardType === CardTypes.CREDIT &&
    fundingSource?.cardAccount?.network &&
    UNSUPPORTED_CARD_NETWORK_TYPES_FOR_VIRTUAL_CARD.includes(fundingSource?.cardAccount?.network);

  return !blockedToVirtualCard;
};

export const buildInternationalPayment = ({
  payment,
  organizationId,
  userId,
  idempotentKey = uuid(),
}: {
  payment: PaymentType;
  organizationId: number;
  userId: number | null | undefined;
  idempotentKey?: string;
}) => ({
  ...pick(payment, [
    'amount',
    'currency',
    'billId',
    'originId',
    'note',
    'fundingSourceId',
    'deliveryMethodId',
    'deliveryPreference',
    'createOrigin',
    'createOrigin',
    'purpose',
  ]),
  userId,
  organizationId,
  scheduledDate: new Date(payment.scheduledDate).valueOf(),
  origin: 'local',
  vendorId: payment.vendorId,
  idempotentKey,
});

export const buildFinancingPayment = ({
  payment,
  organizationId,
  userId,
  idempotentKey = uuid(),
  financingDetails,
}: {
  payment: PaymentType;
  organizationId: number;
  userId: number | null | undefined;
  idempotentKey?: string;
  financingDetails: {
    intentId: string;
    numberOfInstallments: number;
  };
}) => ({
  ...pick(payment, [
    'amount',
    'currency',
    'billId',
    'originId',
    'note',
    'fundingSourceId',
    'deliveryMethodId',
    'deliveryPreference',
    'createOrigin',
    'createOrigin',
    'purpose',
  ]),
  userId,
  organizationId,
  scheduledDate: new Date(payment.scheduledDate).valueOf(),
  origin: 'local',
  vendorId: payment.vendorId,
  idempotentKey,
  financingCreationDetails: {
    provider: 'credit_key',
    eligibilityToken: financingDetails.intentId,
    numberOfInstallments: financingDetails.numberOfInstallments,
    scheduleDate: new Date(payment.scheduledDate).toISOString(),
  },
});

export const isReturnedCheck = (payment: PaymentType): boolean => {
  const { voidChecks, balance } = payment;

  return !isEmpty(voidChecks) && !isNil(balance) && balance > 0;
};

export const isUndepositedPayment = (payment: PaymentType): boolean => {
  const paymentStatusDates = payment?.metadata?.paymentStatusDates;

  return !paymentStatusDates?.depositedDate && Boolean(paymentStatusDates?.inTransitDate);
};

export const isUndepositedCheck = (payment: PaymentType): boolean => {
  const isVoidAndRetryEnabled = !!payment?.metadata?.isVoidAndRetryEnabled;
  const isUndeposited = isUndepositedPayment(payment);
  const isCheckDeliveryMethod = payment.deliveryMethod?.deliveryType === DeliveryType.CHECK;

  return isVoidAndRetryEnabled && isUndeposited && isCheckDeliveryMethod;
};

const isUndepositedPeriodExpired = (payment) => {
  const isFinancingPayment = isFinancedPayment(payment);
  const paymentStatusDates = payment?.metadata?.paymentStatusDates;
  const isUndeposited = isUndepositedPayment(payment);

  if (!isUndeposited) {
    return false;
  }

  const expirationPeriod = isFinancingPayment ? 10 : 30;
  const isPeriodExpired = paymentStatusDates?.inTransitDate
    ? differenceInCalendarDays(new Date(), new Date(paymentStatusDates?.inTransitDate)) > expirationPeriod
    : false;

  return isPeriodExpired;
};

export const isUndepositedOverdueCheck = (payment: PaymentType): boolean => {
  const isVoidAndRetryEnabled = !!payment?.metadata?.isVoidAndRetryEnabled;
  const isCheckDeliveryMethod = payment.deliveryMethod?.deliveryType === DeliveryType.CHECK;

  return isVoidAndRetryEnabled && isCheckDeliveryMethod && isUndepositedPeriodExpired(payment);
};

export const isVoidAndRetryInProgress = (payment: PaymentType): boolean => {
  const isVoidAndRetryEnabled = !!payment?.metadata?.isVoidAndRetryEnabled;
  const isVoidAndRetryInProgress = !!payment?.metadata?.voidedCheckData;

  return isVoidAndRetryEnabled && isVoidAndRetryInProgress;
};

export const isPaymentFailedRefund = (payment: PaymentType) =>
  !isEmpty(payment?.metadata?.paymentStatusDates?.refundFailureDate) &&
  payment?.metadata?.paymentType === PaymentTypes.REFUND;

export const isPaymentScheduleRefund = (payment: PaymentType): boolean =>
  payment?.metadata?.paymentType === PaymentTypes.REFUND &&
  !isEmpty(payment?.metadata?.paymentStatusDates?.refundInitiated) &&
  isEmpty(payment?.metadata?.paymentStatusDates?.refundCompleted) &&
  isEmpty(payment?.metadata?.paymentStatusDates?.refundFailureDate);

export const isPaymentCompletedRefund = (payment: PaymentType): boolean =>
  payment?.metadata?.paymentType === PaymentTypes.REFUND &&
  !isEmpty(payment?.metadata?.paymentStatusDates?.refundCompleted) &&
  isEmpty(payment?.metadata?.paymentStatusDates?.refundFailureDate);

export const isRefundPaymentFlow = (payment: PaymentType): boolean =>
  isPaymentScheduleRefund(payment) || isPaymentCompletedRefund(payment);

export const isPaymentBulkPayment = ({ bills }: PaymentType | BatchPaymentType): boolean => !!bills && bills.length > 1;

export const isFinancedPayment = ({ isFinanced }: PaymentType): boolean => isFinanced;

export const isPaymentCanceled = (payment: PaymentType) =>
  payment.collectStatus && REFUND_COLLECT_STATUSES.includes(payment.collectStatus);

export const getInvoiceStatusByPayment = (data) => {
  const lastPayment = getLatestPayment(data?.payments);
  const cancelledByCustomer = data?.cancelledAt && lastPayment?.status !== PaymentStatus.COMPLETED;
  const isPending = data?.isPending;
  const isMarkedAsPaid = data.paymentRequest?.markedAsPaidAt;

  if (cancelledByCustomer) {
    return InvoiceStatus.CANCELLED_BY_CUSTOMER;
  }

  if (isPending) {
    return InvoiceStatus.PENDING;
  }

  if (isMarkedAsPaid) {
    return InvoiceStatus.MARKED_AS_PAID;
  }

  if (lastPayment?.status === PaymentStatus.FAILED) {
    return InvoiceStatus.FAILED;
  }

  if (lastPayment?.status === PaymentStatus.COMPLETED) {
    return InvoiceStatus.PAID;
  }

  if (lastPayment?.status === PaymentStatus.IN_PROGRESS) {
    return InvoiceStatus.IN_PROGRESS;
  }

  return InvoiceStatus.SCHEDULED;
};

export const isTerminatedPayment = (payment: PaymentType): boolean => !!payment?.financing?.isTerminated;
