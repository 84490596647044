import styled from 'styled-components';
import useGetDeliveryMethodDisplayName from 'src/modules/delivery-methods/hooks/useGetDeliveryMethodDisplayName';
import { DeliveryMethodType } from 'src/utils/types';
import BankAccountIcon from './BankAccountIcon';

type Props = {
  method: DeliveryMethodType | null;
  className?: string;
};

function DeliveryMethodCard({ method, className }: Props) {
  const displayName = useGetDeliveryMethodDisplayName(method);

  return (
    <MethodInfoContainer className={className}>
      <WrappedBankAccountIcon logo={method?.logo} />
      <MethodText>{displayName}</MethodText>
    </MethodInfoContainer>
  );
}

export default DeliveryMethodCard;

const MethodInfoContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 1.8rem 2rem;
  border: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  border-radius: 0.8rem;
`;

const MethodText = styled.span`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => props.theme.text.fontType.regular};
`;

const WrappedBankAccountIcon = styled(BankAccountIcon)`
  margin-right: 2rem;
`;
