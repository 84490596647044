import { CompanyContact } from 'src/components/common/CompanyContact';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

type Props = {
  isMobileView?: boolean;
  vendorName: string;
  avatarName?: string | null;
  onClick: () => void;
  analyticsProperties?: Record<string, unknown>;
  description?: string | React.ReactNode;
};

export const ScannedInvoiceHeaderDetails = ({
  isMobileView,
  vendorName,
  avatarName,
  onClick,
  analyticsProperties,
  description,
}: Props) => (
  <Flex direction="column" mb={{ base: 0, md: 10 }} w="full">
    <Flex justify="space-between" mb={0}>
      {!isMobileView && (
        <CompanyContact
          description={description}
          avatarName={avatarName as string | undefined}
          companyName={vendorName}
        />
      )}
      <Flex direction={{ base: 'column', md: 'row' }} w={{ base: 'full', md: 'inherit' }}>
        <Button
          onClick={onClick}
          label="pay.scannedInvoice.action"
          size={ButtonSizes.lg}
          w={{ base: 'full', md: 'inherit' }}
          analyticsProperties={analyticsProperties}
          ml={{ base: 0, md: 3 }}
        />
      </Flex>
    </Flex>
  </Flex>
);
