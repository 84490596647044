import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';

export const VirtualCardCandidatesAlreadyFilledPage = () => (
  <SuccessLayoutPage
    illustration={IllustrationName.checkCircle}
    title="vendors.virtualCard.candidates.alreadyFilled.title"
    text="vendors.virtualCard.candidates.alreadyFilled.details"
  />
);
