import {
  acceptInvitationSlice,
  acceptUserInvitationSlice,
  regularRegistrationSlice,
  vendorRegistrationSlice,
} from 'src/modules/auth/slices';

export const AUTH_ACCEPT_INVITATION_SUCCESS = acceptInvitationSlice.actions.success;
export const AUTH_ACCEPT_USER_INVITATION_SUCCESS = acceptUserInvitationSlice.actions.success;
export const AUTH_REGULAR_REGISTRATION_SUCCESS = regularRegistrationSlice.actions.success;
export const AUTH_VENDOR_REGISTRATION_SUCCESS = vendorRegistrationSlice.actions.success;
