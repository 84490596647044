import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { PayListItemType } from 'src/modules/bills/types';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';

export const usePayListItems = (): {
  loadItems: () => void;
  payListItems: PayListItemType[];
  isLoading: boolean;
  totalCount: number;
} => {
  const orgId = useOrgId();
  const { list: fetchItems } = useStoreActions(payListItemsStore);

  const payListItemsSelectors = payListItemsStore.selectors?.list;
  const payListItemsParams = {
    orgId,
  };
  const loadItems = useCallback(() => fetchItems(payListItemsParams), [orgId]);
  const payListItems = useSelector((state) => payListItemsSelectors.value(state, payListItemsParams));
  const totalCount = useSelector(payListItemsSelectors.status(payListItemsParams))?.totalCount;
  const isLoading = useSelector(payListItemsSelectors.status(payListItemsParams))?.loading;

  return {
    loadItems,
    payListItems: payListItems || [],
    totalCount,
    isLoading,
  };
};
