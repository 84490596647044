import styled from 'styled-components';
import { FUNDING_LOGO } from 'src/utils/consts';

type Props = {
  logo: string | null;
  className?: string;
};

function BankAccountIcon({ logo, className }: Props) {
  if (logo && logo !== FUNDING_LOGO.DEFAULT) {
    return (
      <Icon className={className}>
        <BankAccountLogo src={`data:image/jpeg;base64,${logo}`} alt="method-icon" />
      </Icon>
    );
  }

  return (
    <Icon className={className}>
      <DefaultBankAccountIcon />
    </Icon>
  );
}

export default BankAccountIcon;

const Icon = styled.div`
  color: ${(props) => props.theme.text.color.main};
`;

const BankAccountLogo = styled.img`
  height: 3.2rem;
`;

const DefaultBankAccountIcon = styled.i.attrs({ className: 'icon-bank-icon' })`
  font-size: 3.2rem;
`;
