import { useSelector } from 'react-redux';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { PaymentType, VendorType } from 'src/utils/types';

export type UsePaymentDataFromTokenProps = {
  token: string;
};

export type UsePaymentDataFromTokenState = {
  paymentId: string;
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  vendor: VendorType;
};

export function usePaymentDataFromToken(props: UsePaymentDataFromTokenProps): UsePaymentDataFromTokenState {
  const { token } = props;
  const { paymentId } = getJWTPayload(token);

  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id));
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor = useSelector(fetchSelector.byId(payment?.vendor?.id));

  return {
    paymentId,
    payment,
    isPaymentLoading,
    organization,
    vendor,
  };
}
