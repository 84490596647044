import isEmpty from 'lodash/isEmpty';
import { useTheme } from 'styled-components';
import { ThemeType } from 'src/theme/global-theme';
import { getStatusInfo } from 'src/utils/bill-details';
import { getBillPaymentTag, getVendorCompanyName, isPaymentRequest } from 'src/utils/bills';
import { BillType, PaymentType } from 'src/utils/types';
import { BillStatus } from '../BillStatus';

export type Props = {
  payment: PaymentType;
  bill: BillType;
};
export const PaymentStatus: React.FC<Props> = ({ bill, payment }: Props) => {
  const theme = useTheme() as ThemeType;

  const manuallyPaid = payment.manual === true;

  const status = getBillPaymentTag(bill, payment);

  const isRequestAndHasNotDeliveryMethods = isPaymentRequest(bill.id) && isEmpty(bill.vendor?.deliveryMethods);
  const statusInfo = getStatusInfo(
    { status },
    theme,
    payment,
    manuallyPaid,
    isRequestAndHasNotDeliveryMethods,
    getVendorCompanyName(bill)
  );

  return statusInfo ? <BillStatus statusInfo={statusInfo} /> : null;
};
