import { MouseEvent as ReactMouseEvent } from 'react';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { DeleteAdminModalMessageButtons } from './components/DeleteAdminModalMessageButtons';
import { DeleteAdminModalMessageContent } from './components/DeleteAdminModalMessageContent';
import { DeleteAdminModalMessageTitle } from './components/DeleteAdminModalMessageTitle';

type Props = {
  userFullName: string;
  onCancel: (event: ReactMouseEvent) => void;
  onRemove: (event: ReactMouseEvent) => void;
};

export const DeleteAdminModalMessage = ({ userFullName, onCancel, onRemove }: Props) => (
  <ModalMessage
    id="delete-admin-confirmation-modal"
    titleComponent={<DeleteAdminModalMessageTitle />}
    contentComponent={<DeleteAdminModalMessageContent userFullName={userFullName} />}
    buttonComponent={<DeleteAdminModalMessageButtons onRemove={onRemove} onCancel={onCancel} />}
    onCloseClick={onCancel}
  />
);
