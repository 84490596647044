import map from 'lodash/map';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import usersStore from 'src/modules/users/users-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { Footer } from 'src/pages/vendor/international-delivery-method/components/Footer';
import { fireInternationalAnalyticsOnSubmit } from 'src/pages/vendor/international-delivery-method/event-mapping';
import { useUBO } from 'src/pages/vendor/international-delivery-method/ultimate-beneficial-owners/hooks/useUBO';
import { getIsComingFromVendorDetails, requiredFields } from 'src/pages/vendor/international-delivery-method/utils';
import { internationalCountries } from 'src/pages/vendor/international-delivery-method/vendor-details/internationalCountries';
import { getCompanyInfo, getProfile } from 'src/redux/user/selectors';
import { useForm } from 'src/ui/form';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { convertToServerAddress } from 'src/utils/address';
import { AddressFormControlMode, Country, TaxIdType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { AddressType, GoogleCombinedAddressType } from 'src/utils/types';
import { useInternationalMethodCreation } from '../hooks/useInternationalMethodCreation';
import { InternationalWizardState, useInternationalWizard } from '../hooks/useInternationalWizard';
import { internationalLocations } from '../locations';
import { forbiddenCountries, vendorDetailsFormValidator } from './utils';

type Props = {
  onExit: () => void;
  vendorId: number;
};

export type VendorDetailsPageModel = Pick<InternationalWizardState, 'fullName' | 'companyName' | 'country'> &
  AddressType;

export const VendorDetailsPage = ({ onExit, vendorId }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const profile = useSelector(getProfile);
  const companyInfo = useSelector(getCompanyInfo);
  const user = useSelector(usersStore.selectors.byId(profile.id));
  const isCompanyLegalInfoDoneForInternationals =
    (!!user.dateOfBirth || companyInfo.taxIdType !== TaxIdType.SSN) && isCompanyLegalInfoDone(companyInfo);
  const { wizardState, setWizardState } = useInternationalWizard();
  const [redirectUrl] = useLocationState<string>('redirectUrl');
  const [billId] = useLocationState<string>('billId');
  const [exitUrl] = useLocationState<string>('exitUrl');
  const [origin] = useLocationState<string>('origin');
  const { UBOInfoDoesNotExists, getUBOInfo } = useUBO();
  const vendor = useSelector(vendorsStore.selectors.fetch.byId(vendorId));
  const isUBOInfoRequired = UBOInfoDoesNotExists && companyInfo.taxIdType === TaxIdType.EIN;
  const isComingFromVendorDetails = useMemo(() => (origin ? getIsComingFromVendorDetails(origin) : false), [origin]);
  const shouldShowAddBillButton = useMemo(() => isCompanyLegalInfoDoneForInternationals && !isUBOInfoRequired, [
    isCompanyLegalInfoDoneForInternationals,
    isUBOInfoRequired,
  ]);
  const [createInternationalDeliveryMethod, isCreating] = useInternationalMethodCreation({ vendorId });
  const [address, setAddress] = useState<GoogleCombinedAddressType | null>(wizardState?.googleCombinedAddress || null);
  const internationalCountriesOptions = map(internationalCountries, (value, key) => ({ value: key, label: value }));

  useEffect(() => {
    if (UBOInfoDoesNotExists) {
      getUBOInfo();
    }
  }, [UBOInfoDoesNotExists]);

  const vendorDetailsModel = useMemo<VendorDetailsPageModel>(
    () => ({
      fullName: wizardState.fullName || '',
      companyName: wizardState.companyName || '',
      addressLine1: wizardState?.addressLine1 || '',
      addressLine2: wizardState.addressLine2 || null,
      city: wizardState.city || null,
      country: wizardState.country || null,
      state: wizardState.state || null,
      zipCode: wizardState.zipCode || null,
      countryCode: wizardState.country || Country.US,
    }),
    [wizardState]
  );

  const submitVendorDetails = async (value, changes) => {
    const { addressLine1, addressLine2, country, state, city, zipCode, fullName, companyName } = value;
    const updatedAddress = {
      addressLine1: addressLine1 || '',
      addressLine2: addressLine2 || '',
      city: city || '',
      country: country || '',
      state: state || '',
      zipCode: zipCode || '',
      fullName: fullName || '',
      companyName: companyName || '',
      googleCombinedAddress: address || undefined,
    };
    setWizardState((prevState) => ({
      ...prevState,
      ...updatedAddress,
    }));

    if (isCompanyLegalInfoDoneForInternationals) {
      if (UBOInfoDoesNotExists && companyInfo.taxIdType === TaxIdType.EIN) {
        fireInternationalAnalyticsOnSubmit();
        historyPush({
          path: internationalLocations.ubo,
          params: { id: vendorId },
          state: { redirectUrl, exitUrl, billId, origin },
        });
      } else {
        await createInternationalDeliveryMethod({
          ...wizardState,
          ...updatedAddress,
          ...{ shouldRedirectToAddBill: changes.shouldRedirectToAddBill || false },
        });
      }
    } else {
      fireInternationalAnalyticsOnSubmit();
      historyPush({
        path: internationalLocations.businessDetails,
        params: { id: vendorId },
        state: { redirectUrl, exitUrl, billId, origin },
      });
    }
  };

  const [vendorDetailsMV, { submit }, validationErrors] = useForm<VendorDetailsPageModel>(vendorDetailsModel, {
    submit: submitVendorDetails,
    validator: vendorDetailsFormValidator(requiredFields),
    validateOnChange: false,
  });

  const onChangeAddress = (address: GoogleCombinedAddressType) => {
    if (address && address?.addressComponents?.length > 0) {
      const convertedAddress = convertToServerAddress(address);
      const { addressLine2, city, countryCode, state, zipCode, addressLine1 } = convertedAddress;
      const selectedCountry =
        countryCode && internationalCountries[countryCode] && !forbiddenCountries.includes(countryCode)
          ? countryCode
          : '';
      vendorDetailsMV.addressLine1.onChange({ value: addressLine1 });
      vendorDetailsMV.addressLine2.onChange({ value: addressLine2 });
      vendorDetailsMV.city.onChange({ value: city });
      vendorDetailsMV.country.onChange({ value: selectedCountry });
      vendorDetailsMV.state.onChange({ value: state });
      vendorDetailsMV.zipCode.onChange({ value: zipCode });
      setAddress(address);
    } else {
      vendorDetailsMV.addressLine1.onChange({ value: null });
      setAddress(null);
    }
  };

  return (
    <StepLayoutPage
      title="vendors.deliveryMethods.international.vendorDetails.title"
      titleValues={{ companyName: vendor?.companyName }}
      isLoading={isCreating}
      goExit={onExit}
      {...{
        footer: (
          <Footer
            showFooterProps={{ shouldShowAddBillButton, isComingFromVendorDetails }}
            submit={submit}
            isLoading={isCreating}
          />
        ),
      }}
    >
      <FormContainer>
        <RowBox>
          <TextField
            label="vendors.deliveryMethods.international.vendorDetails.businessName"
            helperText="vendors.deliveryMethods.international.vendorDetails.businessNameDescription"
            model={vendorDetailsMV.companyName}
            isRequired
          />
        </RowBox>
        <RowBox>
          <TextField
            label="vendors.deliveryMethods.international.vendorDetails.fullName"
            placeholder="vendors.deliveryMethods.international.vendorDetails.fullNamePlaceholder"
            model={vendorDetailsMV.fullName}
            isRequired
          />
        </RowBox>
        <FormRow>
          <MIAddressAutocomplete
            id="address"
            addressLabel="vendors.deliveryMethods.international.vendorDetails.companyAddress"
            addressPlaceholder="vendors.deliveryMethods.international.vendorDetails.companyAddressPlaceholder"
            address={address}
            onChange={onChangeAddress}
            errorMessage={validationErrors?.addressLine1}
            required
            hideSuite
            mode={AddressFormControlMode.INLINE}
            marginBottomOverride="0"
            allowAllCountries
            showAddManualAddress
          />
        </FormRow>
        {!!vendorDetailsMV.addressLine1.value && (
          <>
            <RowBox>
              <TextField
                label="vendors.deliveryMethods.international.vendorDetails.addressLine2"
                placeholder="vendors.deliveryMethods.international.vendorDetails.addressLine2Placeholder"
                model={vendorDetailsMV.addressLine2}
                autoFocus
              />
            </RowBox>
            <RowBox>
              <TextField
                label="vendors.deliveryMethods.international.vendorDetails.city"
                placeholder="vendors.deliveryMethods.international.vendorDetails.cityPlaceholder"
                model={vendorDetailsMV.city}
                isRequired
                autoComplete="off"
              />
              <TextField
                label="vendors.deliveryMethods.international.vendorDetails.state"
                placeholder="vendors.deliveryMethods.international.vendorDetails.statePlaceholder"
                model={vendorDetailsMV.state}
                isRequired
                autoComplete="off"
              />
            </RowBox>
            <RowBox>
              <WizardSelectField
                label="vendors.deliveryMethods.international.vendorDetails.country"
                placeholder="vendors.deliveryMethods.international.vendorDetails.countryPlaceholder"
                model={vendorDetailsMV.country}
                options={internationalCountriesOptions}
                required
              />
              <TextField
                label="vendors.deliveryMethods.international.vendorDetails.postal"
                placeholder="vendors.deliveryMethods.international.vendorDetails.postalPlaceholder"
                model={vendorDetailsMV.zipCode}
                autoComplete="off"
                isRequired
              />
            </RowBox>
          </>
        )}
      </FormContainer>
    </StepLayoutPage>
  );
};

const RowBox = styled(FormRow)`
  margin-bottom: 4rem;
`;
