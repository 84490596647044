import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { ContactType } from 'src/utils/types';
import { customersApi } from './api';
import { name } from './constants';

type CreatedContactType = ContactType & { id: string };
type BatchUploadState = {
  byId: { [key: string]: CreatedContactType };
  createBatch: {
    loading: boolean;
    error: any;
  };
};
type BatchCustomersUploadPayload = {
  orgId: number;
  data: ContactType[];
};
type BatchCustomersUploadResponse = CreatedContactType[];

export const customersUploadBatchPayments = createApiCallSlice<
  BatchCustomersUploadPayload,
  BatchUploadState,
  BatchCustomersUploadResponse
>({
  name: '[CUSTOMERS] CREATE_BATCH',
  api: customersApi.createBatch,
  initialState: {
    byId: {},
    createBatch: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.createBatch.loading = true;
      state.createBatch.error = undefined;
    },
    [ON_SUCCESS]: (state, action) => {
      state.createBatch.loading = false;
      state.createBatch.error = undefined;
      action.payload.forEach((customer) => {
        state.byId[customer.id] = customer;
      });
    },
    [ON_FAILURE]: (state, action) => {
      state.createBatch.loading = false;
      state.createBatch.error = action.error;
    },
  },
  selectors: {
    status: (state) => state[name]?.createBatch,
    loading: (state) => state[name].createBatch.loading,
  },
});
