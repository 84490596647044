import { FinancingPreEligibilityDecision } from 'src/modules/financing/types';
import { InstallmentOption } from 'src/pages/bill/pay/installments/types';

export enum FinancingExperience {
  FTX = 'FTX',
  STX = 'STX',
}

export const FINANCING_CATEGORY = 'financing';

export enum financingPage {
  addFunding = 'add-funding',
  editFunding = 'edit-funding',
  ownerDecision = 'owner-decision',
  businessDetails = 'business-details',
  businessOwnership = 'business-ownership',
  ownershipDetails = 'ownership-details',
  reviewingDetails = 'reviewing-details',
  installmentsOptions = 'installments-options',
  repaymentTerms = 'repayment-terms',
  repaymentMethod = 'repayment-method',
  installmentsSplit = 'installments-split',
  eligibilityPending = 'eligibility-pending',
  eligibilityRejected = 'eligibility-not-approved',
  amountExceeded = 'amount-exceeded',
  eligibilityGeneralError = 'eligibility-general-error',
}

export enum financingAnalyticsEvents {
  financingExposed = 'financing-exposed',
  installmentsClicked = 'installments-clicked',
  installmentsLearnMoreClicked = 'installments-learn-more-click',
  installmentsOptionsExposed = 'installments-options-exposed',
  financingContinue = 'financing-continue',
  ownershipChange = 'ownership-change',
  ownershipContinue = 'ownership-continue',
  proofOfOwnershipModal = 'proof-of-ownership-modal',
  proofOfOwnershipModalPayAnotherWayClicked = 'proof-of-ownership-modal-pay-another-way-clicked',
  proofOfOwnershipModalCancelClicked = 'proof-of-ownership-modal-cancel-clicked',
  businessDetailsContinue = 'business-details-continue',
  checkMyRateClick = 'check-my-rate-click',
  detailsReview = 'details-review',
  detailsReviewCompleted = 'details-review-completed',
  installmentOptionChosen = 'option-click',
  repaymentMethodChosen = 'repayment-continue',
  optionChosen = 'option-chosen',
  termsOfServiceClick = 'terms-of-service-click',
  privacyPolicyClick = 'privacy-policy-click',
  creditKeyClick = 'credit-key-click',
  optionClicked = 'option-clicked',
  formValidationError = 'form-validation-error',
  loanAgreementCheck = 'loan-agreement-check',
  installmentsOptionContinue = 'installments-option-continue',
  installmentsErrorModal = 'installments-error-modal',
  installmentsErrorModalPayAnotherWayClick = 'installments-error-modal-pay-another-way-click',
  installmentsErrorModalTryAgainClick = 'installments-error-modal-try-again-click',
}

const financingFtxStatuses = [FinancingPreEligibilityDecision.Unknown, FinancingPreEligibilityDecision.Pending];

export const getFinancingExperience = (financingPreEligibilityDecision: FinancingPreEligibilityDecision) =>
  financingFtxStatuses.includes(financingPreEligibilityDecision) ? FinancingExperience.FTX : FinancingExperience.STX;

export const mapOffers = (installmentOptions: InstallmentOption[]) =>
  installmentOptions.map((option) => ({
    numOfInstallments: option.numberOfInstallments,
    repayment: option.installmentAmount,
    installmentFee: option.feeAmount,
  }));
