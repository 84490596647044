import styled from 'styled-components';
import { Separator } from 'src/core/ds/separator';
import { RefundPaymentStatus } from 'src/pages/bill/components/ViewBill/types';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { isPaymentFailedRefund, isPaymentScheduleRefund, isRefundPaymentFlow } from 'src/utils/payments';
import { BillType, PaymentType } from 'src/utils/types';
import { PaymentActivity } from './BillsGallery/PaymentActivity';
import { PaymentApprovalLogs } from './BillsGallery/PaymentApprovalLogs';
import { PaymentTransactionStatus } from './PaymentTransactionStatus';

export type PaymentDetailsProps = {
  payment: PaymentType;
  bill: BillType;
};

export const PaymentDetails = ({ payment, bill }: PaymentDetailsProps) => {
  const isRefundFlow = isPaymentFailedRefund(payment) || isRefundPaymentFlow(payment);

  const refundStatus = () => {
    if (isPaymentFailedRefund(payment)) return RefundPaymentStatus.failed;

    if (isPaymentScheduleRefund(payment)) return RefundPaymentStatus.sent;

    return RefundPaymentStatus.refunded;
  };

  if (isRefundFlow) {
    analytics.trackAction('refund-status', {
      paymentId: payment.id,
      billId: bill.id,
      status: refundStatus(),
    });
  }

  return (
    <>
      <PaymentTransactionStatus payment={payment} />
      <PaymentActivityContainer>
        <Separator color="grey.300" width="113%" margin="0 -3.6rem 2.4rem -3.6rem" />
        {!isRefundFlow && (
          <>
            <PaymentApprovalLogs payment={payment} />
            <Separator color="grey.300" mb={4} />
          </>
        )}
        <PaymentActivity payment={payment} bill={bill} />
      </PaymentActivityContainer>
    </>
  );
};

export const FullPaymentSeparator = styled.div`
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.border.darkGrey};
  margin: 0 -3.6rem 2rem;

  @media ${devices.mobile} {
    margin: 0 -1.6rem 2rem;
  }
`;

export const PaymentActivityContainer = styled.div`
  padding: 0.6rem 0 1.1rem;
`;
