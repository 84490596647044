import { ChangeEvent, PureComponent } from 'react';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';

type Props = {
  onNext: () => void;
  onChange: (companyName: string) => void;
  companyName: string;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
};

export class VendorCompanyNameForm extends PureComponent<Props> {
  static defaultProps = {
    isLoading: false,
    validationErrors: {},
  };

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    this.props.onChange(value);
  };

  render() {
    const { companyName, onNext, isLoading, validationErrors } = this.props;

    return (
      <StepLayoutPage
        title="onboarding.vendorCompanyInfo.name.title"
        isPrevDisabled
        onNext={onNext}
        isLoading={isLoading}
        relativeStep={1 / 10}
      >
        <TextField
          id="companyNameInput"
          label="onboarding.vendorCompanyInfo.name.inputTitle"
          value={companyName}
          onSubmit={onNext}
          onChange={this.onChange}
          errorMessage={validationErrors.companyName}
          autoFocus
          isRequired
        />
      </StepLayoutPage>
    );
  }
}
