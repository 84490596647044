import { Location } from 'history';
import isArray from 'lodash/isArray';
import merge from 'lodash/merge';
import { useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { authLocations } from 'src/pages/auth/locations';
import { LoginPageContainer } from 'src/pages/auth/login';
import { guestPrivateRoutes, guestPublicRoutes } from 'src/pages/meliome/guest/route';
import { mobileAppRoutes } from 'src/pages/mobile-app/route';
import { getOrgId } from 'src/redux/user/selectors';
import locations from 'src/utils/locations';
import { withSiteContext } from '../hoc/withSiteContext';
import { billsRoutes } from './bill/route';
import fundingSourceRoutes from './onboarding/funding-sources/route';
import { orgLocations } from './orgs/locations';
import paymentClassificationRoutes from './payment-classification/route';
import deliveryMethodRoutes from './vendor/delivery-methods/route';

const indexRedirectUnauthed = {
  path: locations.index.url(),
  exact: true,
  component: () => <Redirect to={authLocations.register.index} />,
};

const indexRedirectAuth = {
  path: '/',
  exact: true,
  component: withSiteContext()(({ site }) => {
    const orgId = useSelector(getOrgId);

    return <Redirect to={generatePath(site.authenticatedEntryRedirect, { orgId })} />;
  }),
};

const DefaultPublicComponent = (props: { location: Location }) => {
  const orgId = useSelector(getOrgId);

  return (
    <Redirect
      to={
        generatePath(orgLocations.index + props.location.pathname, {
          orgId,
        }) + props.location.search
      }
    />
  );
};

const defaultPublic = {
  component: DefaultPublicComponent,
};

const routes = {
  public: {
    guestPublicRoutes,
  },
  unauthenticated: {
    index: indexRedirectUnauthed,
    default: { render: () => <LoginPageContainer /> },
  },
  authenticated: {
    guestPrivateRoutes,

    orgs: {
      mobileApp: mobileAppRoutes,
      index: indexRedirectAuth,
      bills: billsRoutes,
      deliveryMethods: deliveryMethodRoutes,
      paymentClassification: paymentClassificationRoutes,
    },
  },
  guest: {
    orgs: {
      fundingSourceRoutes,
    },
    index: indexRedirectUnauthed,
    guestPrivateRoutes,
    default: { render: () => <LoginPageContainer /> },
  },
  emailNotVerified: {
    default: {
      render: () => <Redirect to={authLocations.register.codeVerification} />,
    },
  },
  TWO_FA_REQUIRED: {
    default: {
      render: () => <Redirect to={authLocations.register.authCodeVerification} />,
    },
  },
};

function isRoute(obj) {
  return obj && (obj.render || obj.component);
}
function flattenRoutes(def: any) {
  let defaultRoute = null;
  const flattened: Record<string, any> = [];
  Object.keys(def || {}).forEach((key) => {
    const route = def[key];

    if (key !== 'orgs') {
      if (key === 'default') {
        defaultRoute = route;
      } else if (isRoute(route)) {
        flattened.push(route);
      } else if (isArray(route)) {
        route.forEach((r) => flattened.push(r));
      } else {
        flattenRoutes(def[key]).forEach((r) => flattened.push(r));
      }
    }
  });

  return flattened.concat(defaultRoute).filter((route) => !!route);
}
export function buildRoutes(site: any, userAuthType: any): any {
  const mergedRoutes = merge(routes, site.routes);

  return {
    publicRoutes: flattenRoutes(mergedRoutes.public)
      .concat(flattenRoutes(mergedRoutes[userAuthType]))
      .concat(defaultPublic),
    orgsRoutes: flattenRoutes(mergedRoutes[userAuthType]?.orgs),
  };
}
