import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { Option, overrideStyles } from 'src/components/common/MISingleSelect';
import { PreferredDuplicateVendorAction } from 'src/pages/vendor-directory/DuplicateVendorNameModal/DuplicateVendorNameModal';
import {
  BIG_VENDOR_SELECT_OPTIONS_COUNT,
  SMALL_VENDOR_SELECT_OPTIONS_COUNT,
  VENDOR_SELECT_OPTION_HEIGHT,
  VENDOR_SELECT_OPTION_PADDING,
} from 'src/pages/vendor-directory/select-vendor/consts';
import {
  DirectoryDetailsType,
  GroupedVendorsType,
  VendorOptionType,
  VendorOptionWithScoreType,
} from 'src/pages/vendor-directory/select-vendor/types';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { analytics } from 'src/services/analytics';
import { directoryApi } from 'src/services/api/directory';
import { DirectoryType, SelectVendorType, VendorManagedByEnum } from 'src/utils/consts';
import { FieldType, VendorType } from 'src/utils/types';

const DUPLICATE_MODAL_EVENT_PAGE = 'duplicate-vendor-name-modal';

function getDirectoryType(localVendor: VendorType): DirectoryType | undefined {
  if (localVendor.managedBy === VendorManagedByEnum.MSN) {
    return DirectoryType.Business;
  }

  if (isRppsVendor(localVendor)) {
    return DirectoryType.Biller;
  }

  return undefined;
}

export const groupLocalAndDirectoryVendors = (
  localVendors: VendorOptionType[],
  directoryVendors: VendorOptionType[] = []
): GroupedVendorsType => [
  {
    value: SelectVendorType.LOCAL,
    options: localVendors,
  },
  {
    value: SelectVendorType.DIRECTORY,
    options: directoryVendors,
  },
];

export const createLocalVendorOption = (localVendor: VendorType): VendorOptionType => ({
  type: SelectVendorType.LOCAL,
  value: String(localVendor.id),
  label: localVendor.companyName,
  isRppsVendor: isRppsVendor(localVendor),
  directoryType: getDirectoryType(localVendor),
});

export const generateNewVendorId = () => String(Math.floor(Math.random() * Math.floor(10000)) * -1);

export const createNewLocalVendorOption = (option): VendorOptionType => {
  const { value, directoryId, directoryType, address, contactName, contactEmail, contactPhone } = option;

  return {
    type: SelectVendorType.LOCAL,
    value: generateNewVendorId(),
    label: value,
    directoryId,
    directoryType,
    address,
    contactName,
    contactEmail,
    contactPhone,
  };
};

export const createNewLocalVendorOptionFromName = (vendorName: string): VendorOptionType => ({
  type: SelectVendorType.LOCAL,
  value: generateNewVendorId(),
  label: vendorName,
});

export const createNetworkVendorOption = ({
  networkVendor,
  directoryType,
}: {
  networkVendor: DirectoryDetailsType;
  directoryType: DirectoryType;
}): VendorOptionWithScoreType => ({
  type: SelectVendorType.DIRECTORY,
  directoryType,
  value: generateNewVendorId(),
  label: networkVendor.companyName,
  address: networkVendor.address,
  directoryId: networkVendor.id,
  contactName: networkVendor.contactName,
  contactEmail: networkVendor.contactEmail,
  contactPhone: networkVendor.contactPhone,
  score: networkVendor.score,
  isFcb: networkVendor.isFcb,
  isMutualCompanyName: networkVendor.isMutualCompanyName,
});

export const filterLocalVendors = (vendors: VendorOptionType[], value: string): VendorOptionType[] =>
  vendors.filter((vendor) => vendor.label.toLowerCase().includes(value.toLowerCase()));

export const getSelectOverrideStyle = (options: Array<Option>, theme: any, isSmallSize: boolean): overrideStyles => ({
  option: {
    padding: '0',
  },
  optionIsNew: {
    position: 'sticky',
    bottom: 0,
    borderTop: `0.1rem solid ${theme.colors.border.grey}`,
  },
  groupHeading: {
    // The group headers should stick to the top.
    position: 'sticky',
    paddingTop: '0.8rem',
    top: 0,
    background: 'white',
  },
  menuList: {
    padding: 0,
    backgroundColor: 'white',
    zIndex: '1000',
    maxHeight: isSmallSize
      ? `${VENDOR_SELECT_OPTION_HEIGHT * SMALL_VENDOR_SELECT_OPTIONS_COUNT + VENDOR_SELECT_OPTION_PADDING * 2}rem`
      : `${VENDOR_SELECT_OPTION_HEIGHT * BIG_VENDOR_SELECT_OPTIONS_COUNT + VENDOR_SELECT_OPTION_PADDING * 2}rem`,
    // The creatable option should stick to the bottom.
    '& > .select__option': {
      position: 'sticky',
      borderTop: `0.1rem solid ${theme.colors.border.grey}`,
      paddingBottom: '0.8rem',
      bottom: 0,
      '&:only-child': {
        borderTop: 'none',
      },
    },
  },
  group: {
    borderBottom: `0.1rem solid ${theme.colors.border.grey}`,
    '&:only-of-type': {
      borderBottom: `none`,
    },
    '&:last-of-type': {
      borderBottom: `none`,
    },
  },
});

export const isValidNewOption = (inputValue: string, selectValue: Option[], selectOptions: Option[]): boolean => {
  const localVendorOptions = selectOptions?.[0]?.options || [];

  return !(
    !inputValue ||
    selectValue.some((option) => option.label === inputValue) ||
    localVendorOptions.some((option) => option.label === inputValue)
  );
};

export const addNewVendorToGroupedVendors = (
  groupedVendors: GroupedVendorsType,
  newVendor: VendorOptionType
): GroupedVendorsType => {
  const localVendors = [newVendor, ...groupedVendors[0].options];
  const directoryVendors = groupedVendors[1].options;

  return groupLocalAndDirectoryVendors(localVendors, directoryVendors);
};

export const validateVendorAccountIdentifier = async (orgId, billerId, accountIdentifier) => {
  let validationErrors = {};

  if (!isEmpty(billerId)) {
    const { valid } = await directoryApi.validate({
      orgId,
      billerId,
      accountNumber: accountIdentifier,
    });

    if (!valid) {
      validationErrors = { accountIdentifier: 'inputErrors.vendor.accountIdentifier.custom.mask' };
    }
  }

  return validationErrors;
};

export const getAccountIdentifierValidationError = async (
  orgId: number,
  billerId?: string,
  accountIdentifier?: string
): Promise<{ accountIdentifier?: string }> => {
  let accountIdentifierValidationError = getValidationErrors(
    'vendor',
    {
      accountIdentifier,
      billerId,
    },
    ['accountIdentifier']
  );

  if (isEmpty(accountIdentifierValidationError)) {
    accountIdentifierValidationError = await validateVendorAccountIdentifier(orgId, billerId, accountIdentifier);
  }

  return accountIdentifierValidationError;
};

export const onVendorChange = (
  obj: FieldType & Record<string, any>,
  vendors: VendorType[],
  onVendorIdChange: (obj: FieldType & Record<string, any>) => number | null | void,
  showDuplicateVendorNameModal: (o) => void
) => {
  const getDuplicateNameVendor = (name: string) => vendors.find((v) => v.companyName === name);
  const sameNameVendor = getDuplicateNameVendor(obj.label);
  let vendorId;

  if (!!sameNameVendor && obj.type === SelectVendorType.DIRECTORY) {
    const onClickExisting = () => {
      analytics.track(DUPLICATE_MODAL_EVENT_PAGE, 'click-use-existing', {
        vendorId: sameNameVendor.id,
        billerId: obj?.directoryId,
      });

      vendorId = onVendorIdChange({ ...obj, ...createLocalVendorOption(sameNameVendor) });
    };
    const onClickContinue = (newVendorName) => {
      analytics.track(DUPLICATE_MODAL_EVENT_PAGE, 'click-continue', {
        vendorId: sameNameVendor.id,
        billerId: obj?.directoryId,
        oldVendorName: obj.label,
        newVendorName,
      });
      vendorId = onVendorIdChange({
        ...obj,
        value: newVendorName,
        __isNew__: true,
      });
    };

    analytics.track(DUPLICATE_MODAL_EVENT_PAGE, 'show-modal', {
      vendorId: sameNameVendor.id,
      billerId: obj?.directoryId,
    });
    showDuplicateVendorNameModal({
      vendorName: obj.label,
      isDuplicateName: (vendor) => !!getDuplicateNameVendor(vendor),
      onClickCancel: onClickExisting,
      onClickSave: onClickContinue,
      preferredAction:
        obj.directoryType === DirectoryType.Business
          ? PreferredDuplicateVendorAction.Existing
          : PreferredDuplicateVendorAction.New,
    });

    return vendorId;
  }

  return onVendorIdChange(obj);
};
