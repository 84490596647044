import { DrawerTabsType } from './types';

const TAB_TYPE = 'getPro';

export const getDrawerTabs = (pageTab: string): DrawerTabsType => {
  const trackEvents = {
    name: TAB_TYPE,
    properties: { pageTab },
  };

  return {
    details: {
      label: 'getPro.drawerTabs.details.title',
      name: 'details-tab',
      trackEvent: {
        ...trackEvents,
        page: 'details',
      },
    },
    timeline: {
      label: 'getPro.drawerTabs.timeline.title',
      name: 'timeline-tab',
      trackEvent: {
        ...trackEvents,
        page: 'timeline',
      },
    },
  };
};
