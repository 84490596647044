import { useStyleConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { VStack } from 'src/core/ds/stack';

type Props = {
  children: ReactNode;
};

export const Footer = ({ children }: Props) => {
  const style = useStyleConfig('WizardContentFooter');

  return <VStack sx={style}>{children}</VStack>;
};
