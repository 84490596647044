import { useMemo } from 'react';
import { Icon, IconSize } from 'src/core/ds/icon';
import { IconNames } from 'src/core/ds/icon/consts';
import { ReactComponent as PauseIcon } from 'src/images/get-pro/pause.svg';
import { InvoiceStatus } from 'src/utils/consts';
import { useStyledTheme } from 'src/utils/hooks';
import { StatusInfoType } from 'src/utils/types';

const useGetCardStatus = (status?: InvoiceStatus) => {
  const theme = useStyledTheme();

  const DETAILS_BY_STATUS = {
    [InvoiceStatus.CREATED]: null,
    [InvoiceStatus.PENDING]: {
      color: theme.colors.warning.opaque,
      colorOpacity: theme.colors.warning.translucent1,
      label: 'getPaid.view.statuses.pending',
      icon: <PauseIcon />,
    },
    [InvoiceStatus.FAILED]: {
      color: theme.colors.failure.opaque,
      colorOpacity: theme.colors.failure.translucent1,
      label: 'getPaid.view.statuses.failed',
      icon: 'icon-notification-icon', // no equivalent in ds icons
    },
    [InvoiceStatus.SCHEDULED]: {
      color: theme.text.color.blue,
      colorOpacity: theme.colors.progress.scheduled,
      label: 'getPaid.view.statuses.scheduled',
      icon: <Icon name={IconNames.stopWatchFill} size={IconSize.xl} />,
    },
    [InvoiceStatus.PAID]: {
      color: theme.colors.success.opaque,
      colorOpacity: theme.colors.success.translucent1,
      label: 'getPaid.view.statuses.paid',
      icon: <Icon name={IconNames.checkFill} size={IconSize.xl} />,
    },
    [InvoiceStatus.MARKED_AS_PAID]: {
      color: theme.colors.success.opaque,
      colorOpacity: theme.colors.success.translucent1,
      label: 'getPaid.view.statuses.markedAsPaid',
      icon: <Icon name={IconNames.checkFill} size={IconSize.xl} />,
    },
    [InvoiceStatus.IN_PROGRESS]: {
      color: theme.colors.text,
      colorOpacity: theme.colors.white.veryLightGrey,
      label: 'getPaid.view.statuses.inProgress',
      icon: <Icon name={IconNames.processFill} color="black" size={IconSize.xl} />,
    },
    [InvoiceStatus.CANCELLED_BY_CUSTOMER]: {
      color: theme.colors.failure.opaque,
      colorOpacity: theme.colors.failure.translucent1,
      label: 'getPaid.view.statuses.cancelled',
      icon: 'icon-notification-icon', // no equivalent in ds icons
    },
  };

  const cardStatus = useMemo<StatusInfoType | null>(() => (status ? DETAILS_BY_STATUS[status] : null), [
    DETAILS_BY_STATUS,
    status,
  ]);

  return [cardStatus];
};

export default useGetCardStatus;
