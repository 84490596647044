import { featureFlags } from '@melio/shared-web';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { FeatureFlags, NotificationCardTypes } from 'src/utils/consts';

export const NotificationCardChecksDelays = () => {
  const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);

  return (
    <Box pt={30}>
      {isGeneralCheckDelays ? (
        <MINotificationCard
          type={NotificationCardTypes.INFO}
          title={{
            label: 'regularBatchPayments.notifications.generalChecksDelaysTitle',
          }}
          subtitle={{
            label: 'regularBatchPayments.notifications.generalChecksDelaysSubtitle',
          }}
        />
      ) : (
        <MINotificationCard
          type={NotificationCardTypes.INFO}
          title={{
            label: 'regularBatchPayments.notifications.checksDelaysTitle',
          }}
          subtitle={{
            label: 'regularBatchPayments.notifications.checksDelaysSubtitle',
          }}
          link={{
            label: 'bills.pay.date.checksDelays.learnMore',
            path:
              'https://melio.zendesk.com/hc/en-us/articles/6548872564508-USPS-potential-check-delivery-delays-during-the-holidays',
          }}
        />
      )}
    </Box>
  );
};
