import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { DockedButtonMobile } from 'src/components/mobile/DockedButtonMobile';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { getJWTPayload } from 'src/helpers/jwt';
import { useBreak } from 'src/hoc';
import doneImage from 'src/images/general/check-circle.svg';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';

type Props = {
  token: string;
};

const steps = [
  {
    icon: 'rocket',
    label: 'vendors.deliveryMethods.shiftVendorToAch.success.upsale.getPaidLabel',
    description: 'vendors.deliveryMethods.shiftVendorToAch.success.upsale.getPaidDescription',
  },
  {
    icon: 'getPaid',
    label: 'vendors.deliveryMethods.shiftVendorToAch.success.upsale.acceptPaymentLabel',
    description: 'vendors.deliveryMethods.shiftVendorToAch.success.upsale.acceptPaymentDescription',
  },
];

const DeliveryMethodSuccessUpsaleVendorPage = ({ token }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const { isMobile } = useBreak();
  const { payerCompanyName, contactEmail, vendorId } = getJWTPayload(token);
  const { deliveryMethodId } = useSelector(deliveryMethodsStore.selectors.validation);
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId));

  const goSignUp = () => {
    historyPush({
      path: registrationLocations.vendorRegister,
      state: {
        email: contactEmail,
        vendorIdToConvertToOwned: vendorId,
      },
    });
  };

  const goLearnMore = () => {
    analytics.track('shift-vendor-to-ach', 'learn-more');
    window.open('https://www.meliopayments.com/request');
  };

  return (
    <>
      <OutsideLayout>
        <HeaderContentContainer>
          <DoneIcon src={doneImage} />
          <Title>
            <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.success.upsale.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftVendorToAch.success.upsale.subtitle"
              values={{
                companyName: payerCompanyName,
                digits: deliveryMethod?.bankAccount?.accountNumber.slice(-4),
              }}
            />
          </Subtitle>
        </HeaderContentContainer>
        <ContentWrapper>
          <ContentTitle>
            <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.success.contentTitle" />
          </ContentTitle>
          {!isMobile && (
            <Button
              onClick={goSignUp}
              label="vendors.deliveryMethods.shiftVendorToAch.success.upsale.cta"
              size={ButtonSizes.lg}
              isFullWidth
            />
          )}
          <DescriptionContainer>
            {steps.map((step) => (
              <StepsContainer key={step.label}>
                <IconContainer>
                  <Circle />
                  <Box pos="absolute" h={6} mt={1}>
                    <Icon name={IconNames[step.icon]} size={IconSize.lg} />
                  </Box>
                </IconContainer>
                <StepDescriptionContainer>
                  <StepTitle>
                    <MIFormattedText label={step.label} />
                  </StepTitle>
                  <StepDescription>
                    <MIFormattedText label={step.description} />
                  </StepDescription>
                </StepDescriptionContainer>
              </StepsContainer>
            ))}
          </DescriptionContainer>
          <LearnMoreLink onClick={goLearnMore}>
            <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.success.learnMore" />
          </LearnMoreLink>
        </ContentWrapper>
      </OutsideLayout>
      {isMobile && (
        <DockedButtonMobile label="vendors.deliveryMethods.shiftVendorToAch.success.upsale.cta" onClick={goSignUp} />
      )}
    </>
  );
};

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: 2rem;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const ContentTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 4rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
  @media ${devices.mobile} {
    margin-bottom: 0rem;
  }
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
`;

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  align-items: left;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
`;

const StepDescriptionContainer = styled(DescriptionContainer)`
  margin-top: 0rem;
`;

const LearnMoreLink = styled.span`
  color: ${(props) => props.theme.colors.brand};
  cursor: pointer;
  ${(props) => props.theme.text.fontType.medium};
  margin-left: 4rem;
`;

const StepTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  ${(props) => props.theme.text.fontType.regular};
`;

const StepDescription = styled.div`
  ${(props) => props.theme.text.fontType.regular};
  margin-bottom: 2rem;
`;

const StepsContainer = styled.div`
  display: flex;
`;

const IconContainer = styled.div`
  position: relative;
  margin-right: 4.1rem;
`;

const Circle = styled.div`
  background: rgba(189, 184, 255, 1);
  border-radius: 50%;
  position: absolute;
  height: 2rem;
  width: 2rem;
  top: 0.9rem;
  left: 0.5rem;
`;

export default DeliveryMethodSuccessUpsaleVendorPage;
