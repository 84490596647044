import { SystemStyleObject } from '@chakra-ui/styled-system';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ViewDetailsLink } from 'src/components/common/ViewDetailsLink';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Link } from 'src/core/ds/link';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { ToNavigationType } from 'src/utils/types';

const MENU_ITEM_SPACING_WIDTH = 10;
const BACK_BUTTON_SPACING_WIDTH = 6;

type Props = {
  backNav?: ToNavigationType | null;
  label?: string | null;
  text?: string | null;
  subTitle?: string | React.ReactNode | null;
  children?: React.ReactNode;
  eventPage?: string;
  showMenuItems?: boolean;
  actionOptions?: ActionOption[];
  isPartialPayments?: boolean;
  goViewDetails?: () => void;
};

export const PageHeader = ({
  backNav,
  label,
  text,
  subTitle,
  children,
  showMenuItems,
  actionOptions,
  isPartialPayments,
  goViewDetails,
}: Props) => {
  if (!text && !label) {
    return <></>;
  }

  const testId = 'page-header';
  const showMenuItemsButton = showMenuItems && actionOptions;
  const sxOverrideSubtitleCenteredToTitle = showMenuItemsButton
    ? {
        mr: MENU_ITEM_SPACING_WIDTH,
        ml: BACK_BUTTON_SPACING_WIDTH,
      }
    : undefined;

  return (
    <PageHeaderContainer testId={testId}>
      <PageHeaderTop>
        {backNav ? (
          <StyledHeaderLink to={backNav} testId={testId}>
            <Icon name={IconNames.shevronLeft} size={IconSize.lg} />
          </StyledHeaderLink>
        ) : (
          <StyledHeaderLinkEmpty />
        )}
        <PageHeaderTitle>
          {label && (
            <div>
              <MIFormattedText label={label} />
            </div>
          )}
          {text && <span>{text}</span>}
        </PageHeaderTitle>
        {showMenuItemsButton && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon name={IconNames.more} />}
              variant="action"
              placement="top"
            >
              Actions
            </MenuButton>
            <MenuList data-testid="header-dots-action-menu">
              {actionOptions.map(({ label, action, negative }) => (
                <MenuItem
                  label={label}
                  onClick={action}
                  color={negative ? 'red.500' : 'black'}
                  testId={`menu-item-${label}`}
                  key={label}
                />
              ))}
            </MenuList>
          </Menu>
        )}
        {!showMenuItemsButton && <StyledHeaderLinkEmpty />}
      </PageHeaderTop>

      <PageHeaderCenter>
        {subTitle && <PageHeaderSubTitle sxOverride={sxOverrideSubtitleCenteredToTitle}>{subTitle}</PageHeaderSubTitle>}
        {isPartialPayments && <ViewDetailsLink goViewDetails={goViewDetails} />}
        {children && <PageHeaderCenterChildren>{children}</PageHeaderCenterChildren>}
      </PageHeaderCenter>
    </PageHeaderContainer>
  );
};

type PageHeaderContainerProps = {
  children?: React.ReactNode;
  testId?: string;
};

const PageHeaderContainer = ({ children, testId }: PageHeaderContainerProps) => (
  <Box data-testid={testId} sx={PageHeaderContainerStyle}>
    {children}
  </Box>
);

type PageHeaderTopProps = PageHeaderContainerProps;

const PageHeaderTop = ({ children }: PageHeaderTopProps) => <Flex sx={PageHeaderTopStyle}>{children}</Flex>;

type StyledHeaderLinkProps = {
  children?: React.ReactNode;
  to: ToNavigationType;
  testId?: string;
};

const StyledHeaderLink = ({ children, to, testId }: StyledHeaderLinkProps) => (
  <Link data-testid={`${testId}-link`} as={NavLink} to={to} sx={StyledHeaderLinkStyle}>
    {children}
  </Link>
);

const StyledHeaderLinkEmpty = () => <Box w="2.4rem" />;

type PageHeaderTitleProps = PageHeaderContainerProps;

const PageHeaderTitle = ({ children }: PageHeaderTitleProps) => <Box sx={PageHeaderTitleStyle}>{children}</Box>;

type PageHeaderCenterProps = PageHeaderContainerProps;

const PageHeaderCenter = ({ children }: PageHeaderCenterProps) => <Box textAlign="center">{children}</Box>;

type PageHeaderSubTitleProps = PageHeaderContainerProps & { sxOverride?: SystemStyleObject };

const PageHeaderSubTitle = ({ children, sxOverride = { mr: 1 } }: PageHeaderSubTitleProps) => (
  <Box sx={{ ...PageHeaderSubTitleStyle, ...sxOverride }}>{children}</Box>
);

type PageHeaderCenterChildrenProps = PageHeaderContainerProps;

const PageHeaderCenterChildren = ({ children }: PageHeaderCenterChildrenProps) => <Box mt={3}>{children}</Box>;

const PageHeaderContainerStyle = {
  minHeight: '6rem',
  boxSizing: 'border-box',
  backgroundColor: 'white',
  flexDirection: 'column',
  boxShadow: 200,
  top: 0,
  position: {
    base: 'sticky',
    md: 'inherit',
  },
  width: {
    base: 'full',
    md: 'auto',
  },
  maxWidth: {
    base: '63rem',
    md: 'none',
  },
  zIndex: {
    base: 1011,
    md: 'auto',
  },
  display: {
    base: 'flex',
    md: 'none',
  },
  padding: 5,
  paddingY: { lg: '4rem' },
};

const PageHeaderTopStyle = {
  boxSizing: 'border-box',
  flexDirection: 'row',
  minHeight: '2.3rem',
};

const PageHeaderTitleStyle = {
  textStyle: 'body2Semi',
  color: 'black',
  minHeight: '2.3rem',
  flexGrow: 1,
  textAlign: 'center',
  display: {
    base: 'block',
    md: 'none',
  },
  paddingX: {
    base: 4,
    md: 0,
  },
};

const PageHeaderSubTitleStyle = {
  textStyle: 'body3',
  color: 'grey.600',
  height: '2rem',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const StyledHeaderLinkStyle = {
  w: '2.3rem',
  h: '2.3rem',
  textStyle: 'h2',
  textDecoration: 'none',
  color: 'black',
};
