import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { UserAuthType } from 'src/utils/consts';
import { ClaimAccountTokenValidationPublicRouter } from './ClaimAccountTokenValidationPublicRouter';
import { DeliveryMethodStatusCheckRouter } from './DeliveryMethodStatusCheckRouter';
import { GeneralErrorPage } from './GeneralErrorPage';
import { VerifyWithDepositsPage } from './VerifyWithDepositsPage';
import { VerifyWithDepositsStatusPage } from './VerifyWithDepositsStatusPage';

export const ClaimAccountRouter = () => (
  <Switch>
    <SmartRoute path={onboardingLocations.msnRegistration.claimAccount.public}>
      <ClaimAccountTokenValidationPublicRouter />
    </SmartRoute>
    <SmartRoute exact path={onboardingLocations.msnRegistration.claimAccount.generalError}>
      <GeneralErrorPage />
    </SmartRoute>
    <SmartRoute
      path={onboardingLocations.msnRegistration.claimAccount.authenticated}
      allowFor={[UserAuthType.AUTHENTICATED]}
    >
      <SmartRoute exact path={onboardingLocations.msnRegistration.claimAccount.deliveryMethodStatusCheck}>
        <DeliveryMethodStatusCheckRouter />
      </SmartRoute>
      <SmartRoute exact path={onboardingLocations.msnRegistration.claimAccount.verifyWithDeposits}>
        <VerifyWithDepositsPage />
      </SmartRoute>
      <SmartRoute exact path={onboardingLocations.msnRegistration.claimAccount.verifyWithDepositsStatus}>
        <VerifyWithDepositsStatusPage />
      </SmartRoute>
    </SmartRoute>
  </Switch>
);
