export const expandedButtonStyle = {
  justifyContent: 'flex-start',
  w: '4rem',
  minW: '4rem',
  h: '4rem',
  px: 2,
  py: 2,
  transition: '0.2s ease-out',
  boxSizing: 'border-box',
  overflow: 'hidden',
  _hover: {
    span: {
      animation: 'indent 0.2s ease-out',
    },
    svg: {
      transform: 'rotateZ(-180deg)',
      transition: '0.2s ease-out 0.1s',
    },
    borderRightWidth: '1rem',
    minW: '16rem',
    width: '16rem',
    bg: 'primary.600',
  },
  '@keyframes indent': {
    from: { paddingRight: 12, paddingLeft: 0 },
    to: { paddingRight: 0, paddingLeft: 0 },
  },
};
