import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type Props = {
  tooltipLabel: string;
};

export const DeliveryMethodHint = ({ tooltipLabel }: Props) => (
  <Box textStyle="body4" color="grey.600">
    <MIFormattedText label={tooltipLabel} />
  </Box>
);
