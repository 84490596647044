import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MITableHeaderCell, MITableRow } from 'src/components/layout/TableElements';

const InternalBillsTableHeaderRow = () => (
  <MITableRow>
    <StatusTableHeaderCell />
    <MITableHeaderCell>
      <MIFormattedText label="settings.billing.paymentsTable.date" />
    </MITableHeaderCell>
    <AmountTableHeaderCell>
      <MIFormattedText label="settings.billing.paymentsTable.amount" />
    </AmountTableHeaderCell>
    <PdfIconTableHeaderCell>
      <MIFormattedText label="settings.billing.paymentsTable.receipt" />
    </PdfIconTableHeaderCell>
  </MITableRow>
);

const StatusTableHeaderCell = styled(MITableHeaderCell)`
  width: 4rem;
`;

const AmountTableHeaderCell = styled(MITableHeaderCell)`
  text-align: left;
`;

const PdfIconTableHeaderCell = styled(MITableHeaderCell)`
  text-align: right;
  padding-right: 2rem;
`;

export default InternalBillsTableHeaderRow;
