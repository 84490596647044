import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { analytics } from 'src/services/analytics';
import { drawerTabStyles } from './styles';
import { DrawerTabType } from './types';

type DrawerTabProps = {
  onTabClick: (name: string) => void;
  tab: DrawerTabType;
  activeTab: string;
};

export const DrawerTab = ({ onTabClick, tab, activeTab }: DrawerTabProps) => {
  const handleClick = () => {
    onTabClick(tab.name);
    const { name, page, properties } = tab.trackEvent;
    analytics.track(`${name}-drawer-tab`, page, properties);
  };

  return (
    <Box
      __css={drawerTabStyles}
      data-active={activeTab === tab.name}
      data-testid={`drawer-${tab.name}`}
      textStyle="body2Semi"
      onClick={handleClick}
    >
      <MIFormattedText label={tab.label} />
    </Box>
  );
};
