import { featureFlags } from '@melio/shared-web';
import findIndex from 'lodash/findIndex';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { DeliveryDateFormat } from 'src/components/common/DeliveryDateFormat';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { billLocations } from 'src/pages/bill/locations';
import { selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import {
  getBill,
  getIsCancelAndRetryPaymentFlow,
  getPayment,
  getSelectedFundingSource,
} from 'src/redux/payBillWizard/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { FAST_DELIVERY_TYPES, FastCheckDeliveryType } from 'src/utils/consts';
import { FeatureFlags } from 'src/utils/featureFlags';
import { getFundingSourceType } from 'src/utils/funding-sources';
import { getDeliveryPreference } from 'src/utils/payments';
import { PaymentDeliveryOptions } from '../components/PaymentDeliveryOptions';
import { PayBillProps, withPayBillData } from '../hoc/withPayBillData';
import { getSortedDeliveryOptions, getTitleAndSubtitle, removeRegularCheckFee } from './utils';

export type SelectProps = { scheduledDate: Date; deliveryDate: Date; maxDeliveryDate: Date; type?: string };

const PlainFastPaymentOfferPage = ({
  onNext,
  onPrev,
  goExit,
  deliveryOptionsDates,
  isLoading,
  getDeliveryOptionsDates,
}: PayBillProps) => {
  const dispatch = useDispatch();
  const [historyReplace] = useHistoryWithOrgId();

  const [areDeliveryOptionsDatesReloaded, setDeliveryOptionsDatesReloaded] = useState(false);

  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const fundingSourceType = getFundingSourceType(selectedFundingSource);
  const isCancelAndRetryFlow = useSelector(getIsCancelAndRetryPaymentFlow);
  const deliveryOptions = deliveryOptionsDates?.deliveryOptions;
  const [isFastAchBetterExposeEtaEnabled] = featureFlags.useFeature(FeatureFlags.FastAchBetterExposeEta, false);
  const freeDeliveryOptionIndex = findIndex(deliveryOptions, (option) => !FAST_DELIVERY_TYPES.includes(option.type));
  const hasFastCheck = deliveryOptions?.some((el) => el.type === FastCheckDeliveryType.EXPRESS);
  const hasFastOptions = deliveryOptions && deliveryOptions.length > 1;
  const isEligibleForFastAchAdoption = (hasFastCheck || hasFastOptions) && !isFastAchBetterExposeEtaEnabled;
  const sortedDeliveryOptions = getSortedDeliveryOptions({
    deliveryOptions,
    freeDeliveryOptionIndex,
    isEligibleForFastAchAdoption,
  });
  const deliveryPreference = payment?.deliveryPreference;
  const selectedDeliveryOptionIndex = getDeliveryPreference(sortedDeliveryOptions, deliveryPreference);
  const deliveryDate = sortedDeliveryOptions ? sortedDeliveryOptions[freeDeliveryOptionIndex].deliveryDate : undefined;
  const maxRegularDeliveryDate = sortedDeliveryOptions
    ? sortedDeliveryOptions[freeDeliveryOptionIndex].maxDeliveryDate
    : undefined;
  const maxDeliveryDate = sortedDeliveryOptions
    ? sortedDeliveryOptions[selectedDeliveryOptionIndex].maxDeliveryDate
    : undefined;
  isCancelAndRetryFlow && removeRegularCheckFee(sortedDeliveryOptions);
  const { dueDate, vendorId } = bill;
  const { deliveryMethod } = payment;
  const { deliveryType } = deliveryMethod;

  const analyticsProps = {
    vendorId,
    fundingSourceId: selectedFundingSource?.id,
    fundingSourceType,
    billId: bill.id,
    paymentId: payment.id,
    originDeliveryId: deliveryMethod.id,
    originDeliveryType: deliveryType,
    partialBillId: getBillPaymentIndex(bill),
  };

  useEffect(() => {
    const loadDeliveryOptions = async () => {
      const deliveryOptionsDates = await getDeliveryOptionsDates();

      if (deliveryOptionsDates?.deliveryOptions && deliveryOptionsDates.deliveryOptions.length <= 1) {
        const deliveryOption = deliveryOptionsDates?.deliveryOptions?.[0];
        deliveryOption && onSelectDeliveryOption(deliveryOption);
        redirectToConfirm();
      } else {
        trackPageEvent();
      }

      setDeliveryOptionsDatesReloaded(true);
    };

    loadDeliveryOptions();
  }, []);

  const trackPageEvent = () => {
    analytics.page('failed-payment', 'edit-delivery-type', {
      fundingSourceId: selectedFundingSource?.id,
      fundingSourceType,
      billId: bill.id,
      paymentId: payment.id,
    });
  };

  const redirectToConfirm = () => {
    historyReplace({
      path: billLocations.pay.edit.confirm,
      params: {
        billId: bill.id,
        paymentId: payment.id,
      },
    });
  };

  const onSelectDeliveryOption = ({ scheduledDate, deliveryDate, maxDeliveryDate, type }: SelectProps) => {
    analytics.trackAction(`selected-${type}`, {
      ...analyticsProps,
    });
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryDate, maxDeliveryDate, type));
  };

  const { title, subtitle } = getTitleAndSubtitle({
    dueDate,
    deliveryType,
    deliveryDate,
    maxRegularDeliveryDate,
  });

  const subTitleValues = {
    dueDate: <MIFormattedDate date={dueDate} />,
    deliveryDate: <DeliveryDateFormat date={deliveryDate} maxDate={maxDeliveryDate} />,
  };

  const deliveryOptionsTitle = isFastAchBetterExposeEtaEnabled
    ? 'bills.pay.date.deliverySpeedBetterExposeETA'
    : 'bills.pay.fastPayment.deliverySpeed';

  if (isLoading || !areDeliveryOptionsDatesReloaded) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <StepLayoutPage
      title={title}
      subtitle={subtitle}
      subTitleValues={subTitleValues}
      goExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      relativeStep={4 / 5}
    >
      <Flex p={6} w="30rem" mx="auto" boxShadow="500" bg="white" borderRadius="lg">
        <Flex flex={1} direction="column">
          <Box textStyle="body4Semi" color="grey.600">
            <MIFormattedText label={deliveryOptionsTitle} />
          </Box>
          <Box mt={2} textStyle="body3Semi">
            {sortedDeliveryOptions ? (
              <PaymentDeliveryOptions
                fundingSourceType={fundingSourceType}
                deliveryOptions={sortedDeliveryOptions}
                onSelectDeliveryOption={onSelectDeliveryOption}
                isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
                isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
              />
            ) : null}
          </Box>
        </Flex>
      </Flex>
    </StepLayoutPage>
  );
};

export const FastPaymentOfferPage = withPayBillData()(PlainFastPaymentOfferPage);
