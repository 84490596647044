import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { financingStore } from 'src/modules/financing/financing-store';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getBill, getIsCancelAndRetryPaymentFlow, getIsRecurring, getPayment } from 'src/redux/payBillWizard/selectors';
import { FailedPaymentType, FeatureFlags, PaymentApprovalStatus, PaymentStatus } from 'src/utils/consts';
import { hasFailedInstallment } from 'src/utils/financing';
import { BillType, PaymentType } from 'src/utils/types';

export enum PayBillFlowType {
  RescheduleDeclinedPayment = 'RescheduleDeclinedPayment',
  CancelAndRetryPaymentFlow = 'CancelAndRetryPaymentFlow',
  RetryFailedToDeliver = 'RetryFailedToDeliver',
  RetryFailedToCollect = 'RetryFailedToCollect',
  UpdatePayment = 'UpdatePayment',
  CreateRecurringPayment = 'CreateRecurringPayment',
  CreateFromPaymentRequest = 'CreateFromPaymentRequest',
  CreateInternationalPayment = 'CreateInternationalPayment',
  CreateFinancingPayment = 'CreateFinancingPayment',
  CreatePayment = 'CreatePayment',
  RetryFailedToCollectInstallment = 'RetryFailedToCollectInstallment',
}

const getPayBillFlowType = ({
  bill,
  payment,
  isRecurring,
  isFinancingPayment,
  isCancelAndRetryFlow,
  embeddedMode,
  isFailedInstallment,
}: {
  bill: BillType;
  payment: PaymentType;
  isRecurring: boolean;
  isFinancingPayment: boolean;
  isCancelAndRetryFlow: boolean;
  embeddedMode: boolean;
  isFailedInstallment: boolean;
}) => {
  const isInternationalMethod = isInternationalDeliveryMethod(payment?.deliveryMethod?.deliveryType);
  const createPaymentFlow = () => {
    if (isRecurring) {
      return PayBillFlowType.CreateRecurringPayment;
    }

    if (isFinancingPayment) {
      return PayBillFlowType.CreateFinancingPayment;
    }

    if (bill.isPaymentRequest) {
      return PayBillFlowType.CreateFromPaymentRequest;
    }

    if (isInternationalMethod) {
      return PayBillFlowType.CreateInternationalPayment;
    }

    return PayBillFlowType.CreatePayment;
  };
  const existPaymentFlow = () => {
    if (isCancelAndRetryFlow) {
      return PayBillFlowType.CancelAndRetryPaymentFlow;
    }

    if (isFailedInstallment) {
      return PayBillFlowType.RetryFailedToCollectInstallment;
    }

    if (payment.status === PaymentStatus.FAILED) {
      if (embeddedMode) {
        return createPaymentFlow();
      }

      if (payment?.approvalDecisionStatus === PaymentApprovalStatus.DECLINED) {
        return PayBillFlowType.RescheduleDeclinedPayment;
      }

      if (payment?.metadata?.failedType === FailedPaymentType.FAILED_TO_DELIVER) {
        return PayBillFlowType.RetryFailedToDeliver;
      }

      return PayBillFlowType.RetryFailedToCollect;
    }

    return PayBillFlowType.UpdatePayment;
  };

  if (payment.id) {
    return existPaymentFlow();
  }

  return createPaymentFlow();
};

export const usePayBillFlowType = (): PayBillFlowType => {
  const { embeddedMode } = useSiteContext();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const isRecurring = useSelector(getIsRecurring);
  const intentId = useSelector(financingStore.selectors.intentIdWithInstallmentOption.intentId);
  const isCancelAndRetryFlow = useSelector(getIsCancelAndRetryPaymentFlow);
  const [isFinancingEnabled] = featureFlags.useFeature(FeatureFlags.MelioComAPFinancingEnabled, false);
  const isFinancingPayment = isFinancingEnabled && !!intentId;
  const isFailedInstallment = payment && hasFailedInstallment(payment);

  return getPayBillFlowType({
    payment,
    bill,
    isRecurring,
    isCancelAndRetryFlow,
    embeddedMode,
    isFinancingPayment,
    isFailedInstallment,
  });
};
