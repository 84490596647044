import { Fragment } from 'react';
import styled from 'styled-components';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { SelectItemCard } from 'src/components/common/SelectItemCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { Button } from 'src/core/ds/button';
import DeliveryMethodsList from 'src/pages/settings/components/DeliveryMethodsList';
import {
  DeliveryType,
  NotificationCardTypes,
  VENDOR_ONBOARDING_PROGRESS_BAR_STEPS,
  WizardProgressBar,
} from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { Option } from '../SelectBankAccountVerificationTypePageContainer';

type Props = {
  options: Array<Option>;
  goExit: () => void;
  goPrev: () => void;
  goDashboard: () => void;
  vendorId: string;
  isOnPrevAndStepsShown: boolean;
  deliveryMethods: readonly DeliveryMethodType[];
  showUnverifiedAchLimitNotification: boolean;
};

const SelectBankAccountVerificationTypePage = ({
  options,
  goExit,
  goPrev,
  goDashboard,
  vendorId,
  isOnPrevAndStepsShown,
  deliveryMethods,
  showUnverifiedAchLimitNotification,
}: Props) => {
  const isACHDeliveryMethodExists = vendorId && deliveryMethods.some((dm) => dm.deliveryType === DeliveryType.ACH);
  const getSubTitle = () => {
    let subTitle = 'onboarding.fundingSources.bank.verification.paymentSubtitle';

    if (vendorId) {
      subTitle = isACHDeliveryMethodExists
        ? 'onboarding.deliveryMethods.bank.verifySubtitle'
        : 'onboarding.fundingSources.bank.verification.deliverySubtitle';
    }

    return subTitle;
  };

  const renderFooter = () => (
    <>
      <MISecurityDetails eventPage="onboarding-vendor-delivery-method" />
      {isACHDeliveryMethodExists && (
        <ButtonContainer>
          <Button label="onboarding.deliveryMethods.bank.verifyCta" onClick={goDashboard} isFullWidth />
        </ButtonContainer>
      )}
    </>
  );

  return (
    <StepLayoutPage
      title={
        isACHDeliveryMethodExists
          ? 'onboarding.deliveryMethods.bank.verifyTitle'
          : 'onboarding.fundingSources.bank.verification.title'
      }
      subtitle={getSubTitle()}
      goExit={goExit}
      onPrev={isOnPrevAndStepsShown ? goPrev : undefined}
      innerSize={50}
      progressBarType={isOnPrevAndStepsShown ? WizardProgressBar.WITH_SUBSTEPS : undefined}
      progressBarSteps={isOnPrevAndStepsShown ? VENDOR_ONBOARDING_PROGRESS_BAR_STEPS : []}
      minorSteps={vendorId ? [1, 1] : undefined}
      footer={vendorId && renderFooter()}
      hideHeader
    >
      {isACHDeliveryMethodExists ? (
        <DeliveryMethodsListContainer>
          <DeliveryMethodsList deliveryMethods={deliveryMethods} />
        </DeliveryMethodsListContainer>
      ) : (
        <Container>
          {showUnverifiedAchLimitNotification && (
            <NotificationCard
              type={NotificationCardTypes.ERROR}
              subtitle={{
                label: 'onboarding.fundingSources.bank.verification.manual.limit',
              }}
            />
          )}
          {options.map(({ id, label, description, onClick, disabled }, index) => (
            <Fragment key={id}>
              <SelectItemCard
                id={id}
                testId={`verification-type-${id}`}
                onClick={onClick}
                label={label}
                description={description}
                disabled={disabled}
              />
              {options.length === 2 && index === 0 && (
                <OrLineWrapper>
                  <WizardElements.WizardOrLine />
                </OrLineWrapper>
              )}
            </Fragment>
          ))}
        </Container>
      )}
    </StepLayoutPage>
  );
};

export default SelectBankAccountVerificationTypePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrLineWrapper = styled.div`
  ${(props) => props.theme?.pages?.SelectBankAccountVerificationTypePage?.OrLineWrapper}
`;

const DeliveryMethodsListContainer = styled.div`
  margin: -1rem 0 4rem;
`;

const ButtonContainer = styled.div`
  margin-top: 4rem;
  width: 100%;
`;

const NotificationCard = styled(MINotificationCard)`
  margin-bottom: 2rem;
`;
