export enum InternationalCountryGroup {
  ALL = 'all',
  POPULAR = 'popular',
}

export enum BillingType {
  'IBAN' = 'iban',
  'BIC_SWIFT' = 'bic_swift',
}

export type CountryOptionRes = {
  code: string;
  label: string;
  region: InternationalCountryGroup;
  billingType: BillingType;
  isSupported: boolean;
  risk: string;
};
