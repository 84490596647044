import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import { DeliveryMethodLabel } from 'src/pages/regular-batch-payments/components/deliveryMethod/DeliveryMethodLabel';
import { ArrowMenuButtonCell } from 'src/pages/regular-batch-payments/table/components/ArrowMenuButtonCell';
import { DeliveryMethodMenuList } from 'src/pages/regular-batch-payments/table/components/DeliveryMethodCell/DeliveryMethodMenuList';
import { EmptyDeliveryMethodCell } from 'src/pages/regular-batch-payments/table/components/DeliveryMethodCell/EmptyDeliveryMethodCell';
import { DeliveryType, VendorManagedByEnum } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { DisabledSelection } from './DisabledSelection';

type Props = {
  payment: BatchPaymentType;
  deliveryMethods: DeliveryMethodType[];
  currentDeliveryMethodId?: number;
  onChangeDeliveryMethod: (deliveryMethod: DeliveryMethodType) => void;
  isOpen: boolean;
  onAddDeliveryMethod: () => void;
  isOwnedVendor?: boolean;
  listTestId?: string;
  itemId: string;
  totalAmount: number;
  vendor: BatchPaymentType['vendor'];
  purpose?: string;
  managedBy?: VendorManagedByEnum;
  toggleRowExpanded?: (value?: boolean) => void;
};

export const DeliveryMethodMenu = ({
  payment,
  deliveryMethods,
  currentDeliveryMethodId,
  onChangeDeliveryMethod,
  isOpen,
  onAddDeliveryMethod,
  isOwnedVendor,
  listTestId,
  itemId,
  totalAmount,
  vendor,
  purpose,
  managedBy,
  toggleRowExpanded,
}: Props) => {
  const relevantDeliveryMethods = useMemo(
    () => deliveryMethods.filter((dm) => DeliveryType.VIRTUAL_CARD !== dm.deliveryType),
    [deliveryMethods]
  );

  const currentDeliveryMethod = useMemo(
    () => deliveryMethods?.find((dm) => dm.id === currentDeliveryMethodId) || relevantDeliveryMethods[0],
    [deliveryMethods, relevantDeliveryMethods, currentDeliveryMethodId]
  );
  const isInternationalVendor = currentDeliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL;

  const disableSelection =
    isOwnedVendor ||
    currentDeliveryMethod?.deliveryType === DeliveryType.RPPS ||
    isInternationalVendor ||
    currentDeliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD ||
    managedBy;

  if (disableSelection) {
    return (
      <DisabledSelection
        payment={payment}
        isInternationalVendor={isInternationalVendor}
        itemId={itemId}
        totalAmount={totalAmount}
        vendor={vendor}
        deliveryMethod={currentDeliveryMethod}
        purpose={purpose}
        toggleRowExpanded={toggleRowExpanded}
      />
    );
  }

  if (isEmpty(relevantDeliveryMethods)) {
    return <EmptyDeliveryMethodCell onAddDeliveryMethod={onAddDeliveryMethod} />;
  }

  return (
    <>
      <ArrowMenuButtonCell isOpen={isOpen} testId={`arrow-menu-button-delivery-method-${currentDeliveryMethod.id}`}>
        <DeliveryMethodLabel deliveryMethod={currentDeliveryMethod} />
      </ArrowMenuButtonCell>
      <DeliveryMethodMenuList
        deliveryMethods={relevantDeliveryMethods}
        setCurrentDeliveryMethod={onChangeDeliveryMethod}
        onAddDeliveryMethod={onAddDeliveryMethod}
        selectedDeliveryMethodId={currentDeliveryMethodId}
        isOpen={isOpen}
        testId={listTestId}
      />
    </>
  );
};
