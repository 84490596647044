import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { analytics } from 'src/services/analytics';
import { ModelViewField } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';
import { isEnterPressed } from 'src/utils/events';
import { melioClose } from 'src/utils/external-events';

type Props = {
  passwordModel: ModelViewField<string>;
  goNext: () => void;
  isLoading: boolean;
};

const GuestSuccessSignUpPage = ({ passwordModel, goNext, isLoading }: Props) => {
  const goLearnMore = () => {
    analytics.track('payor-password', 'learn-more-link');
    window.open('https://www.meliopayments.com');
  };
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      goNext();
    }
  };

  const site = useSiteContext();

  return (
    <SuccessLayoutPage
      hasLogo
      title="guests.success.secondTitle"
      buttonLabel="guests.success.signUp"
      buttonAction={goNext}
      secondaryButtonLabel="guests.success.close"
      secondaryButtonAction={site.embeddedMode ? melioClose : undefined}
      linkLabel="guests.success.more"
      linkAction={goLearnMore}
      isLoading={isLoading}
    >
      <RegistrationContainer onKeyDown={onKeyPressed}>
        <RegistrationSubText>
          <MIFormattedText label="guests.success.subText" />
        </RegistrationSubText>
        <WizardPasswordValidationInputField
          id="password"
          label="guests.registrationReview.password"
          model={passwordModel}
          autoFocus
          isRequired
          autoComplete="password"
          textAlign="left"
        />
      </RegistrationContainer>
    </SuccessLayoutPage>
  );
};

export default GuestSuccessSignUpPage;

const RegistrationSubText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  text-align: center;
  margin-bottom: 4.8rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -1rem 0 2rem;

  > div {
    width: 100%;
  }
`;
