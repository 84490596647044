import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree, EventMappingType } from 'src/services/analytics/types';

export const PAGE_EVENT = 'settings-approval-workflows';
const APPROVAL_WORKFLOW_CREATE_MODAL = 'create-modal';
const APPROVAL_WORKFLOW_EDIT_MODAL = 'edit-modal';
const DELETE_WORKFLOW_MODAL = 'delete-modal';

const addBtnEventMapping: EventMappingType = [
  PAGE_EVENT,
  'add-button-clicked',
  {
    numberOfExistingWorkflows: 'numberOfExistingWorkflows',
    numberOfUsersInClient: 'numberOfUsersInClient',
  },
];
const createSliceActionPropMapping = {
  createdById: 'payload.createdById',
  creatorsGroup: 'payload.creatorsGroup',
  threshold: 'payload.threshold',
  approvalDecisionsCount: 'payload.approvalDecisionsCount',
  paymentCreatorIds: 'payload.paymentCreatorIds',
};

export const MAPPING: EventMappingsTree = {
  '/orgs/*/settings/approval-workflows': {
    'page.view': [PAGE_EVENT, 'page-load'],
    'settings.approvalWorkflows.page.addWorkflow': addBtnEventMapping,
    'settings.approvalWorkflows.page.addWorkflowMobile': addBtnEventMapping,
    'settings.approvalWorkflows.card.menu.delete': [PAGE_EVENT, 'delete-clicked'],
    'settings.approvalWorkflows.card.menu.edit': [PAGE_EVENT, 'edit-clicked'],
    approvalWorkflowCreateModal: [PAGE_EVENT, `${APPROVAL_WORKFLOW_CREATE_MODAL}_toggled`, { toggle: 'toggle' }],
    deleteApprovalWorkflowModal: [PAGE_EVENT, `${DELETE_WORKFLOW_MODAL}_toggled`, { toggle: 'toggle' }],
    editApprovalWorkflowModal: [PAGE_EVENT, `${APPROVAL_WORKFLOW_EDIT_MODAL}_toggled`, { toggle: 'toggle' }],
    'settings.approvalWorkflows.modal.create.button': [PAGE_EVENT, `${APPROVAL_WORKFLOW_CREATE_MODAL}_submit-clicked`],
    'settings.approvalWorkflows.modal.edit.button': [PAGE_EVENT, `${APPROVAL_WORKFLOW_EDIT_MODAL}_submit-clicked`],
    'settings.approvalWorkflows.modal.edit.confirmCancel': [
      PAGE_EVENT,
      `${APPROVAL_WORKFLOW_EDIT_MODAL}_cancel-clicked`,
    ],
    'settings.approvalWorkflows.modal.edit.confirmSubmit': [
      PAGE_EVENT,
      `${APPROVAL_WORKFLOW_EDIT_MODAL}_confirm-clicked`,
    ],
    'settings.approvalWorkflows.deleteModal.buttons.submit': [PAGE_EVENT, `${DELETE_WORKFLOW_MODAL}_submit-clicked`],
    'settings.approvalWorkflows.deleteModal.buttons.cancel': [PAGE_EVENT, `${DELETE_WORKFLOW_MODAL}_cancel-clicked`],
    ...mapApiSlice('[APPROVALWORKFLOWS] CREATE', PAGE_EVENT, 'create', {
      request: createSliceActionPropMapping,
      success: createSliceActionPropMapping,
      failure: createSliceActionPropMapping,
    }),
    ...mapApiSlice('[APPROVALWORKFLOWS] UPDATE', PAGE_EVENT, 'update', {
      request: createSliceActionPropMapping,
      success: createSliceActionPropMapping,
      failure: createSliceActionPropMapping,
    }),
    ...mapApiSlice('[APPROVALWORKFLOWS] DELETE', PAGE_EVENT, 'delete', {
      request: { approvalWorkflowId: 'payload.id' },
      success: { approvalWorkflowId: 'meta.identifier.id' },
      failure: { approvalWorkflowId: 'meta.identifier.id' },
    }),
  },
};
