import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { cardsApi } from '../api';
import { DeleteCardParams, UseDeleteCardOptions } from '../types';

export const useDeleteCard = () => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: deleteCard, loading, error } = useApi<[DeleteCardParams], string>({
    api: cardsApi.delete,
  });

  return {
    loading,
    error,
    deleteCard: ({ cardId }: UseDeleteCardOptions) => deleteCard({ orgId, cardId }),
  };
};
