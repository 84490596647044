import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { billsStore } from 'src/modules/bills/bills-store';
import { RequestRefund } from 'src/pages/bill/pay/components/RequestRefundWizard/RequestRefund';
import { getPaymentById } from 'src/utils/payments';
import { PaymentType } from 'src/utils/types';

export const RequestRefundWrapper = () => {
  const match = useRouteMatch<{ billId: string; paymentId: string; orgId: string }>();
  const { billId, paymentId, orgId } = match.params;

  const billActions = useStoreActions(billsStore);
  const bill = useSelector(billsStore.selectors.byId(billId));

  const payment = bill ? getPaymentById<PaymentType>(bill.payments, paymentId) : null;

  useEffect(() => {
    if (!bill) {
      billActions.fetch({
        orgId,
        id: billId,
      });
    }
  }, [paymentId, orgId, billId]);

  return !payment ? <AreaLoader /> : <RequestRefund payment={payment} />;
};
