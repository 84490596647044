import { deleteRequest, fetchRequest, postRequest } from 'src/services/api/api';
import {
  ScannedInvoiceCreateParams,
  ScannedInvoiceDeleteParams,
  ScannedInvoiceFetchParams,
  ScannedInvoiceMarkAsPaidParams,
} from './types';

export const scannedInvoiceApi = {
  fetch({ scannedInvoiceId, orgId }: ScannedInvoiceFetchParams) {
    const url = `/orgs/${orgId}/scanned-invoices/${scannedInvoiceId}`;

    return fetchRequest(url).then((res) => ({
      ...res.scannedInvoice,
      isMissingBillDetails: res.isMissingBillDetails,
      suggestedVendorId: res.suggestedVendorId,
    }));
  },
  delete({ id, orgId }: ScannedInvoiceDeleteParams) {
    const url = `/orgs/${orgId}/scanned-invoices/${id}`;

    return deleteRequest(url).then((res) => res.deletedScannedInvoiceId);
  },
  create({
    orgId,
    scannedInvoiceId,
    totalAmount,
    vendorId,
    dueDate,
    invoiceNumber,
    note,
    currency,
    intuitAccountId,
  }: ScannedInvoiceCreateParams) {
    const url = `/orgs/${orgId}/scanned-invoices/create-bill`;

    return postRequest(url, {
      scannedInvoiceId,
      totalAmount,
      vendorId,
      dueDate,
      invoiceNumber,
      note,
      currency,
      invoiceDate: new Date(),
      intuitAccountId,
    });
  },
  markAsPaid({
    orgId,
    scannedInvoiceId,
    totalAmount,
    vendorId,
    dueDate,
    invoiceNumber,
    note,
    currency,
    amount,
    accountingPlatformAccountId,
    createOrigin,
  }: ScannedInvoiceMarkAsPaidParams) {
    const url = `/orgs/${orgId}/scanned-invoices/mark-as-paid`;

    return postRequest(url, {
      scannedInvoiceId,
      totalAmount,
      vendorId,
      dueDate,
      invoiceNumber,
      note,
      currency,
      invoiceDate: new Date(),
      markAsPaidAmount: amount,
      intuitAccountId: accountingPlatformAccountId,
      createOrigin,
    });
  },
};
