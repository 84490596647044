import signInAnimation from 'src/animations/accountingSoftware/sync-qbd/sign-in.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { getQBDRelativeStep } from './utils';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const SyncQBDOpenPage = ({ onNext, onPrev, onExit }: Props) => (
  <StepLayoutPage
    title="settings.newAccountingSoftware.QuickbooksDesktopSync.open.title"
    subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.open.subtitle"
    nextLabel="progress.next"
    goExit={onExit}
    onNext={onNext}
    onPrev={onPrev}
    relativeStep={getQBDRelativeStep(3)}
    displaySupportLink
    animatedIllustration={{
      animationData: signInAnimation,
      loop: false,
    }}
  />
);

export default SyncQBDOpenPage;
