import { UserRoleTag } from 'src/components/user/UserRoleTag';
import { Avatar } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { ClientType } from '../types';

type ClientRowProps = ClientType & {
  color: string;
  isFirm: boolean;
};

export const ClientRow = ({ logoUrl, companyName, role, color, isFirm }: ClientRowProps) => (
  <Flex alignItems="center">
    <Avatar link={logoUrl as string} textColor="white" name={companyName} bg={isFirm ? 'black' : color} />
    <Box
      as="span"
      textStyle="body4Semi"
      mr={3}
      ml={2}
      __css={{
        maxWidth: '15rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {companyName}
    </Box>
    <UserRoleTag userRole={role} />
  </Flex>
);
