import { createContext, ReactNode, useContext } from 'react';

type StepLayoutContextConfig = {
  title: string;
  subTitle: string;
  nextLabel: string;
};

export type VendorCompanyLogoConfig = StepLayoutContextConfig & { skipLabel: string };
export type VendorCompanyLinkConfig = StepLayoutContextConfig & { inputTitle: string; inputHint: string };
export type VendorCompanyLegalInfoConfig = StepLayoutContextConfig & { onNext: () => void; onPrev: () => void };
export type VendorCompanyAddedConfig = StepLayoutContextConfig & {
  secondaryActionButtonLabel: string;
  content: ReactNode;
};
export type VendorCompanyInfoConfig = {
  VendorCompanyLink: VendorCompanyLinkConfig;
  VendorCompanyLogo: VendorCompanyLogoConfig;
  VendorCompanyLegalInfo: VendorCompanyLegalInfoConfig;
  VendorCompanyAdded: VendorCompanyAddedConfig;
};

type Props = {
  children: ReactNode;
  value: VendorCompanyInfoConfig;
};

export const VendorCompanyInfoConfigContext = createContext<VendorCompanyInfoConfig | null>(null);

export const VendorCompanyInfoConfigProvider = ({ children, value }: Props) => (
  <VendorCompanyInfoConfigContext.Provider value={value}>{children}</VendorCompanyInfoConfigContext.Provider>
);

export const useVendorCompanyInfoConfig = <T extends keyof VendorCompanyInfoConfig>({
  page,
}: {
  page: T;
}): VendorCompanyInfoConfig[T] => {
  const context = useContext(VendorCompanyInfoConfigContext);

  if (!context || !context[page]) {
    throw new Error('failed to load useVendorCompanyInfoConfig context');
  }

  return context[page];
};
