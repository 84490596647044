import head from 'lodash/head';
import min from 'lodash/min';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { withFeatureFlags } from 'src/hoc';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { getErrorStoreActions } from 'src/modules/error/error-store';
import { financingStore } from 'src/modules/financing/financing-store';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { billLocations } from 'src/pages/bill/locations';
import { isNumber } from 'src/pages/bill/utils';
import { fetchBillAction, selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import { getBill, getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { GlobalState } from 'src/redux/types';
import { getCompanyInfo, getOrgId, getUserPreferences } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { FeatureFlags, PaymentApprovalStatus } from 'src/utils/consts';
import {
  convertDateToCalendarDate,
  getClosestBusinessDateLessThanOrEqualTo,
  getInitialProcessingDates,
} from 'src/utils/dates';
import { removeUnsupportedDeliveryOptionsByBill } from 'src/utils/delivery-methods';
import { getFundingSourceType } from 'src/utils/funding-sources';
import {
  AccountType,
  BillType,
  CompanyInfoType,
  DeliveryOptionType,
  PaymentType,
  UserPreferencesType,
} from 'src/utils/types';
import { getDeliveryMethodById } from '../utils/billGetters';
import { PayBillDatePage } from './components/PayBillDatePage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type State = {
  isInnerLoading: boolean;
  deliveryDate?: Date;
  maxDeliveryDate?: Date;
  minScheduledDate?: Date;
  maxScheduledDate?: Date;
  scheduledDate?: Date;
  deliveryOptions?: DeliveryOptionType[];
};

type MapStateToProps = {
  bill: BillType;
  payment: PaymentType;
  orgId: number;
  selectedFundingSource?: AccountType | null;
  companyInfo: CompanyInfoType;
  userPreferences: UserPreferencesType;
  isFinancingPayment: boolean;
};

type MapDispatchToProps = {
  selectPaymentDates: (
    scheduledDate: Date,
    deliveryEta: Date,
    maxDeliveryEta: Date,
    deliveryPreference?: string
  ) => void;
  globalErrorOccurred: (title: string | undefined, subtitle: string | undefined) => void;
  fetchBill: (id: string) => void;
};

type Props = {
  site: any;
  isFastCheckEnabledForAllStates: boolean;
} & PayBillProps &
  MapStateToProps &
  MapDispatchToProps;
const eventPage = 'pay-bill';

class PlainPayBillDatePageContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isInnerLoading: false,
      minScheduledDate: undefined,
      maxScheduledDate: undefined,
      deliveryDate: undefined,
      maxDeliveryDate: undefined,
      scheduledDate: undefined,
      deliveryOptions: undefined,
    };
  }

  componentDidMount() {
    const { payment, navigate, bill, orgId } = this.props;

    if (!isNumber(payment.deliveryMethodId)) {
      return navigate(generatePath(billLocations.pay.deliveryMethod, { orgId, billId: bill.id }));
    }

    return this.loadSuggestedProcessingDate();
  }

  getSelectedDeliveryPreference = (options: DeliveryOptionType[]) => {
    const { bill, payment } = this.props;
    const { deliveryType } = getDeliveryMethodById(bill, payment.deliveryMethodId) || {};
    const isDeclinedPayment = payment?.approvalDecisionStatus === PaymentApprovalStatus.DECLINED;
    const deliveryPreference = payment?.deliveryPreference;
    const currentSelected = options.filter((opt) => opt.type === deliveryPreference);

    if (currentSelected.length > 0 && !isDeclinedPayment) {
      return currentSelected[0]?.type;
    }

    return head(options)?.type || deliveryType;
  };

  async loadSuggestedProcessingDate() {
    const {
      payment,
      bill,
      orgId,
      site,
      companyInfo,
      isFinancingPayment,
      isFastCheckEnabledForAllStates,
      selectedFundingSource,
    } = this.props;
    const { deliveryMethodId, fundingSourceId, scheduledDate, amount, id, payBillFlowUUID } = payment;
    this.setState({ isInnerLoading: true });

    try {
      const {
        minScheduledDate,
        maxScheduledDate,
        suggestedScheduledDate,
        deliveryDate,
        maxDeliveryDate,
        deliveryOptions,
      } = await getInitialProcessingDates({
        orgId,
        deliveryMethodId,
        fundingSourceId,
        scheduledDate,
        amount,
        dueDate: bill.dueDate,
        paymentId: id,
        payBillFlowUUID,
        isFinancingPayment,
      });
      const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
        site,
        deliveryOptions,
        companyInfo,
        bill,
        payment,
        fundingSource: selectedFundingSource,
        isFastCheckEnabledForAllStates,
        isFinancingPayment,
      });

      if (possibleDeliveryOptions?.length > 1) {
        const { deliveryType } = getDeliveryMethodById(bill, payment.deliveryMethodId) || {};

        if (deliveryType === 'check') {
          analytics.track(
            eventPage,
            possibleDeliveryOptions?.length === 2 ? 'express-check-shown' : 'express-fast-shown',
            {
              partialBillId: getBillPaymentIndex(bill),
            }
          );
        } else if (deliveryType === 'ach') {
          const { selectedFundingSource } = this.props;
          const fundingSourceType = getFundingSourceType(selectedFundingSource);
          analytics.track(eventPage, 'fast-ach-shown', {
            fundingSourceType,
            partialBillId: getBillPaymentIndex(bill),
          });
        }
      }

      this.setState({
        isInnerLoading: false,
        scheduledDate: new Date(suggestedScheduledDate),
        deliveryDate,
        maxDeliveryDate: new Date(maxDeliveryDate),
        minScheduledDate: new Date(minScheduledDate),
        deliveryOptions: possibleDeliveryOptions,
        ...(maxScheduledDate && { maxScheduledDate }),
      });
      const selectedDeliveryPreference = this.getSelectedDeliveryPreference(possibleDeliveryOptions);
      const paymentDate = getPaymentDate(
        suggestedScheduledDate,
        deliveryDate,
        maxDeliveryDate,
        possibleDeliveryOptions,
        selectedDeliveryPreference
      );

      this.props.selectPaymentDates(
        paymentDate.scheduledDate,
        paymentDate.deliveryDate,
        paymentDate.maxDeliveryDate,
        selectedDeliveryPreference
      );

      this.props.fetchBill(bill.id);
    } catch (e) {
      this.setState({ isInnerLoading: false });
    }
  }

  isLoading = () => this.props.isLoading || this.state.isInnerLoading;

  onOptionSelected = (scheduledDate, deliveryDate, maxDeliveryDate, type) => {
    const suggestedDate =
      this.props.isFinancingPayment && this.state.maxScheduledDate
        ? this.getSuggestedDate(this.state.maxScheduledDate, scheduledDate)
        : scheduledDate;

    this.setState({
      scheduledDate: suggestedDate,
      deliveryDate,
      maxDeliveryDate,
    });

    const { selectedFundingSource, bill } = this.props;
    const properties = {
      fundingSourceType: getFundingSourceType(selectedFundingSource),
      fundingSourceId: selectedFundingSource?.id,
      partialBillId: getBillPaymentIndex(bill),
    };
    analytics.track(eventPage, `delivery-selected-${type || 'regular'}`, properties);

    this.props.selectPaymentDates(scheduledDate, deliveryDate, maxDeliveryDate, type);
  };

  getSuggestedDate(maxScheduleDate: Date, date: Date | string) {
    return min([
      getClosestBusinessDateLessThanOrEqualTo(convertDateToCalendarDate(maxScheduleDate).toDate()),
      new Date(date),
    ])?.toISOString();
  }

  handleDateSelected = (selectedScheduledDate: Date) => {
    const {
      orgId,
      bill,
      payment,
      site,
      companyInfo,
      isFinancingPayment,
      isFastCheckEnabledForAllStates,
      selectedFundingSource,
    } = this.props;

    this.setState({ isInnerLoading: true });
    deliveryApi
      .getDeliveryTime(
        orgId,
        selectedScheduledDate,
        payment.deliveryMethodId,
        payment.fundingSourceId,
        payment.amount,
        payment.id,
        payment.payBillFlowUUID,
        isFinancingPayment
      )
      .then(({ deliveryDate, suggestedScheduledDate, maxDeliveryDate, deliveryOptions }) => {
        const possibleDeliveryOptions = removeUnsupportedDeliveryOptionsByBill({
          site,
          deliveryOptions,
          companyInfo,
          bill,
          payment,
          fundingSource: selectedFundingSource,
          isFastCheckEnabledForAllStates,
          isFinancingPayment,
        });
        const selectedDeliveryPreference = this.getSelectedDeliveryPreference(possibleDeliveryOptions);

        const paymentDate = getPaymentDate(
          suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate,
          possibleDeliveryOptions,
          selectedDeliveryPreference
        );

        this.setState({
          isInnerLoading: false,
          scheduledDate: paymentDate.scheduledDate,
          deliveryDate: paymentDate.deliveryDate,
          maxDeliveryDate: paymentDate.maxDeliveryDate,
          deliveryOptions: possibleDeliveryOptions,
        });

        this.props.selectPaymentDates(
          paymentDate.scheduledDate,
          paymentDate.deliveryDate,
          paymentDate.maxDeliveryDate,
          selectedDeliveryPreference
        );

        analytics.track(eventPage, 'select-date', {
          billId: bill.id,
          dueDate: bill.dueDate,
          suggestedScheduledDate,
          deliveryDate,
          maxDeliveryDate,
          partialBillId: getBillPaymentIndex(bill),
        });
      })
      .catch(() => {
        this.setState({ isInnerLoading: false });
      });
  };

  render() {
    const {
      bill,
      payment,
      goExit,
      onNextSelectDate,
      onPrevDate,
      selectedFundingSource,
      isFinancingPayment,
    } = this.props;
    const {
      scheduledDate,
      deliveryDate,
      maxDeliveryDate,
      minScheduledDate,
      maxScheduledDate,
      deliveryOptions,
    } = this.state;
    const selectedDeliveryMethod = getDeliveryMethodById(bill, payment.deliveryMethodId);

    const title = isFinancingPayment ? 'bills.pay.date.financing.title' : 'bills.pay.date.title';
    const subtitle = isFinancingPayment ? 'bills.pay.date.financing.subtitle' : 'bills.pay.date.subtitle';

    return (
      <>
        {scheduledDate && deliveryDate && maxDeliveryDate ? (
          <PayBillDatePage
            payment={payment}
            bill={bill}
            selectedFundingSource={selectedFundingSource}
            scheduledDate={scheduledDate}
            deliveryDate={deliveryDate}
            maxDeliveryDate={maxDeliveryDate}
            deliveryOptions={deliveryOptions}
            deliveryMethodType={selectedDeliveryMethod?.deliveryType}
            onSelectDeliveryOption={this.onOptionSelected}
            minScheduledDate={minScheduledDate}
            maxScheduledDate={maxScheduledDate}
            onNext={onNextSelectDate}
            onPrev={onPrevDate}
            onChange={this.handleDateSelected}
            isLoading={this.isLoading()}
            goExit={goExit}
            title={title}
            headerLabel="qbo.header.title"
            subtitle={subtitle}
            relativeStep={3 / 5}
            fundingSourceType={getFundingSourceType(selectedFundingSource)}
            isFinancingPayment={isFinancingPayment}
          />
        ) : (
          <AreaLoader placement="wizard" />
        )}
      </>
    );
  }
}

const getPaymentDate = (
  defaultScheduledDate: Date,
  defaultDeliveryDate: Date,
  defaultMaxDeliveryDate: Date,
  deliveryOptions: DeliveryOptionType[],
  type?: string
) => {
  const fastAchOption = deliveryOptions?.find((option) => option.type === type);

  return fastAchOption
    ? {
        scheduledDate: fastAchOption.scheduledDate,
        deliveryDate: fastAchOption.deliveryDate,
        maxDeliveryDate: fastAchOption.maxDeliveryDate,
      }
    : {
        scheduledDate: defaultScheduledDate,
        deliveryDate: defaultDeliveryDate,
        maxDeliveryDate: defaultMaxDeliveryDate,
      };
};

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  payment: getPayment(state),
  bill: getBill(state),
  orgId: getOrgId(state),
  selectedFundingSource: getSelectedFundingSource(state),
  companyInfo: getCompanyInfo(state),
  userPreferences: getUserPreferences(state),
  isFinancingPayment: financingStore.selectors.isFinancingPayment(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  selectPaymentDates(scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, deliveryPreference?: string) {
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryEta, maxDeliveryEta, deliveryPreference));
  },
  globalErrorOccurred: (title?: string, subtitle?: string) =>
    getErrorStoreActions(dispatch).globalErrorOccurred(title, subtitle),
  fetchBill(id: string) {
    dispatch(fetchBillAction(id));
  },
});

export const PayBillDatePageContainer = compose(
  withPayBillData(),
  withSiteContext(),
  withFeatureFlags(FeatureFlags.FastCheckEnabledForAllStates, 'isFastCheckEnabledForAllStates', false),
  connect(mapStateToProps, mapDispatchToProps)
)(PlainPayBillDatePageContainer);
