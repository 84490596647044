import { useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import PayBillCompleteLegalInfoPage from 'src/pages/bill/pay/components/PayBillCompleteLegalInfoPage';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { convertCompanyAddressToGoogleAddress, convertLegalAddressToGoogleAddress } from 'src/utils/address';
import { TaxIdType } from 'src/utils/consts';
import { GoogleCombinedAddressType } from 'src/utils/types';
import { AddressNameType } from '../consts';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';
import { useLegalAddress } from './hooks/useLegalAddress';

type Props = PayBillProps;

type State = {
  legalAddress: GoogleCombinedAddressType;
  legalCompanyName: string | null;
  taxId?: string;
  taxIdType?: TaxIdType;
  contactFirstName: string;
  contactLastName: string;
  phone?: string;
  companyAddress: GoogleCombinedAddressType;
};

const PlainPayBillCompleteLegalInfoPageContainer = ({
  onLegalInfoSubmit,
  onPrev,
  goExit,
  validationErrors,
  isLoading,
}: Props) => {
  const companyInfo = useSelector(getCompanyInfo);
  const computedLegalAddress = useLegalAddress(companyInfo);
  // TODO: This is far from ideal - ideally all of these would be their own state. However, with the way `<LegalInfoForm>` (and by extension, `<PayBillCompleteLegalInfoPage>`)
  // are set up - there's a _very_ tight coupling between the components, knowing about each other's fields' names in a kind of dynamic way. So we must use an object really...
  // At least until proper SoC is implemented.
  const [
    { legalAddress, legalCompanyName, taxId, taxIdType, contactFirstName, contactLastName, companyAddress, phone },
    setState,
  ] = useState<State>(() => ({
    legalAddress: convertLegalAddressToGoogleAddress(computedLegalAddress),
    legalCompanyName: companyInfo.legalCompanyName,
    taxId: companyInfo.taxId,
    taxIdType: companyInfo.taxIdType ?? undefined,
    contactFirstName: companyInfo.contactFirstName,
    contactLastName: companyInfo.contactLastName,
    phone: companyInfo.phone,
    companyAddress: convertCompanyAddressToGoogleAddress(companyInfo),
  }));

  return (
    <PayBillCompleteLegalInfoPage
      legalCompanyName={legalCompanyName}
      companyName={companyInfo.companyName}
      taxId={taxId}
      contactFirstName={contactFirstName}
      contactLastName={contactLastName}
      companyAddress={companyAddress}
      phone={phone}
      onLegalInfoChange={({ id, value }) => {
        setState((prevState) => ({
          ...prevState,
          [id]: value,
        }));
      }}
      companyInfo={companyInfo}
      taxIdType={taxIdType}
      address={legalAddress}
      onPrev={onPrev}
      goExit={goExit}
      onAddressChange={(address, type = AddressNameType.LEGAL_ADDRESS) => {
        setState((prevState) => ({
          ...prevState,
          [type]: address,
        }));
      }}
      validationErrors={validationErrors}
      handleOnSubmit={() => {
        onLegalInfoSubmit(
          legalAddress,
          companyAddress,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- `handleOnSubmit` is called only when the form is valid, meaning `legalCompanyName` is not null
          legalCompanyName!,
          taxId,
          taxIdType,
          contactFirstName,
          contactLastName,
          phone
        );
      }}
      isLoading={isLoading}
    />
  );
};

export const PayBillCompleteLegalInfoPageContainer = compose(
  (Comp) => (props) => (
    <Comp
      {...props}
      inputFields={[
        'legalCompanyName',
        'legalGooglePlaceId',
        'taxId',
        'taxIdType',
        'legalZipCode',
        'contactFirstName',
        'contactLastName',
        'phone',
      ]}
    />
  ),
  withPayBillData()
)(PlainPayBillCompleteLegalInfoPageContainer);
