import { CSSObject } from 'src/core/ds';
import { Button } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { analytics } from 'src/services/analytics';
import { ReactComponent as MelioLogo } from './icons/melio-logo.svg';

export const PageHeader = () => {
  const handleMelioLogoClick = () => {
    analytics.track('push-to-fast-ach', 'learn-more');
    window.open('https://www.meliopayments.com/request');
  };

  return (
    <Flex
      boxSizing="border-box"
      bgColor="primary.500"
      justifyContent="space-between"
      alignItems={{ base: 'center', md: 'initial' }}
      h={{ base: '7.7rem', md: '24rem' }}
      pb={{ base: 0, md: 24 }}
      px={{ base: 5, md: 24 }}
      pt={{ base: 0, md: 24 }}
    >
      <Button onClick={handleMelioLogoClick} cursor="pointer" w="8.1rem" minW="8.1rem" h="3.3rem" p={0} sx={buttonSx}>
        <MelioLogo />
      </Button>
    </Flex>
  );
};

const buttonSx: CSSObject = {
  _hover: {
    bgColor: 'transparent',
  },
  _active: {
    bgColor: 'transparent',
  },
};
