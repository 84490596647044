import { Tooltip } from '@chakra-ui/react';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

type Props = {
  goBack: () => void;
  createBatchPayment: () => void;
  isBatchPaymentCreating: boolean;
  isDisabled: boolean;
  createBatchPaymentTooltip?: Record<string, string> | null;
};

export const Footer = ({
  createBatchPayment,
  isBatchPaymentCreating,
  goBack,
  isDisabled,
  createBatchPaymentTooltip,
}: Props) => (
  <Box shadow="500" pos="fixed" bottom="0" w="100%" bg="white" h={80}>
    <Flex justify="flex-end" p={5}>
      <Button
        w="fit-content"
        size={ButtonSizes.md}
        label="regularBatchPayments.cancel"
        variant={ButtonVariants.tertiaryNaked}
        onClick={goBack}
      >
        <MIFormattedText label="regularBatchPayments.cancel" />
      </Button>
      <Tooltip
        data-testid="schedule-batch-payment-disabled-tooltip"
        isDisabled={!createBatchPaymentTooltip}
        placement="top"
        maxW="max"
        shouldWrapChildren
        label={
          <Box textAlign="start" px={2} py={1}>
            <MIFormattedText label={createBatchPaymentTooltip?.label} />
          </Box>
        }
      >
        <Button
          label="regularBatchPayments.scheduleBatchPayments"
          variant={ButtonVariants.primary}
          size={ButtonSizes.md}
          onClick={createBatchPayment}
          isLoading={isBatchPaymentCreating}
          isDisabled={isDisabled}
        />
      </Tooltip>
    </Flex>
  </Box>
);
