import { useState } from 'react';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { UseModal } from 'src/helpers/react/useModal';
import { AmexDisconnectButtons } from './AmexDisconnectButtons';
import { AmexDisconnectContent } from './AmexDisconnectContent';
import { AmexDisconnectTitle } from './AmexDisconnectTitle';

type Props = {
  dismiss: () => void;
  onConfirm: () => void;
  lastFiveDigits?: string;
};

export const AmexDisconnectModal: React.FC<UseModal<Props>> = ({ dismiss, onConfirm, lastFiveDigits }: Props) => {
  const [loading, setLoading] = useState(false);

  const onCloseClick = () => {
    dismiss();
  };

  const handleOnDisconnect = async () => {
    setLoading(true);
    await onConfirm();
    dismiss();
  };

  return (
    <ModalMessage
      id="amex-disconnect-modal"
      onCloseClick={onCloseClick}
      titleComponent={<AmexDisconnectTitle />}
      contentComponent={<AmexDisconnectContent lastFiveDigits={lastFiveDigits || ''} />}
      buttonComponent={
        <AmexDisconnectButtons loading={loading} onCancel={onCloseClick} onDisconnect={handleOnDisconnect} />
      }
    />
  );
};
