import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import IconButton from 'src/core/ds/iconButton';
import { Image } from 'src/core/ds/image';
import { ReactComponent as RightArrowIcon } from 'src/images/get-pro/pagination-next.svg';
import { ReactComponent as LeftArrowIcon } from 'src/images/get-pro/pagination-prev.svg';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { FileUrlsPayloadType } from 'src/utils/types';
import { imagePreviewStyle } from './pdfPreviewCardStyle';

type Props = {
  fileUrls?: FileUrlsPayloadType | null;
};

const PdfPreviewCard: React.FC<Props> = ({ fileUrls }) => {
  const [page, setPage] = useState<number>(0);
  const [, setImageLoading] = useState<boolean>(false);
  const pagesNumber = useMemo(() => fileUrls?.filePreviewUrls?.length || 0, [fileUrls]);
  const pageLabel = useMemo(() => `${page + 1}/${pagesNumber}`, [page, pagesNumber]);
  const isNextDisabled = page + 1 >= pagesNumber;
  const isPreviousDisabled = page === 0;
  const onImageLoaded = useCallback(() => setImageLoading(false), []);
  const onNextPage = useCallback(() => setPage(page + 1), [page]);
  const onPrevPage = useCallback(() => setPage(page - 1), [page]);
  useEffect(() => setImageLoading(true), [fileUrls, page]);

  return (
    <Flex
      position="relative"
      h="calc(100% - 2px)"
      justify="center"
      border="1px"
      borderColor="grey.400"
      borderRadius="lg"
    >
      {pagesNumber > 0 && (
        <>
          <Image
            src={fileUrls?.filePreviewUrls[page]}
            alt={`invoice-page-${page}`}
            __css={imagePreviewStyle}
            onLoad={onImageLoaded}
            fallback={<AreaLoader placement="single" />}
            className={FULL_STORY_MASK_RULE_CLASS}
          />
          {pagesNumber > 1 && (
            <Flex
              position="absolute"
              bottom={3}
              w="40%"
              mx="auto"
              align="center"
              justify="space-between"
              backgroundColor="grey.300"
              borderRadius="lg"
              zIndex={1401}
            >
              <IconButton
                size="lg"
                icon={<LeftArrowIcon />}
                onClick={onPrevPage}
                isDisabled={isPreviousDisabled}
                aria-label="prev-page"
              />
              <Box textStyle="caption1Semi">
                <MIFormattedText label="getPro.cards.pdfPreview.page" /> {pageLabel}
              </Box>
              <IconButton
                size="lg"
                icon={<RightArrowIcon />}
                onClick={onNextPage}
                isDisabled={isNextDisabled}
                aria-label="next-page"
              />
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};

export default PdfPreviewCard;
