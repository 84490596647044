import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useListSearchBarContext } from 'src/hoc/withListSearchBarContext';
import createRequestImage from 'src/images/general/request-empty-2-state-icon.svg';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { isSentOrUnSent } from 'src/pages/get-paid/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { PaymentRequestTabs } from 'src/utils/consts';

type Props = {
  selectedTab: PaymentRequestTabs;
  first: string;
};
const useEmptyStateParams = ({ selectedTab, first }: Props) => {
  const history = useHistory();

  const [{ contextSearchFilterValue: search }] = useListSearchBarContext();
  const orgId = useSelector(getOrgId);

  const createInvoiceUrl = generatePath(getPaidLocations.create.options, {
    orgId,
  });

  const onCreateInvoice = useCallback(() => {
    history.push(createInvoiceUrl);
  }, [createInvoiceUrl, history]);

  if (search) {
    return {
      text: 'getPaid.emptyState.search',
    };
  }

  if (isSentOrUnSent(selectedTab)) {
    return {
      text: `getPaid.emptyState.requests.${first}.text`,
      title: `getPaid.emptyState.requests.${first}.title`,
      ctaLabel: `getPaid.emptyState.requests.${first}.action`,
      ctaAction: onCreateInvoice,
      icon: createRequestImage,
    };
  }

  return {
    text: `getPaid.emptyState.${selectedTab}.text`,
  };
};

export default useEmptyStateParams;
