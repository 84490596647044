import Highlighter from 'react-highlight-words';
import { FormattedNumber } from 'react-intl';
import { generateFormattedCurrencySearchWords } from 'src/components/common/MiFormattedCurrency/utils';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type MIFormattedCurrencyProps = {
  value: string | number | null;
  currency?: string | null;
  round?: boolean;
  search?: string;
  privateData?: boolean;
  emptyLabel?: string;
};

export const MIFormattedCurrency = ({
  value,
  currency = '$',
  round = false,
  search = '',
  privateData,
  emptyLabel,
}: MIFormattedCurrencyProps) => {
  let strValue: string | null = null;

  if (value) {
    strValue = typeof value === 'string' ? value : String(value);
  }

  const val = strValue ? Math.abs(round ? parseInt(strValue, 10) : parseFloat(strValue)) : 0;
  const minDigits = round ? 0 : 2;
  const searchWords = search ? generateFormattedCurrencySearchWords(search) : [];
  const isEmpty = (value === null || value === undefined) && emptyLabel;

  return !isEmpty ? (
    <Box as="span" className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined}>
      {strValue && parseInt(strValue, 10) < 0 ? '-' : ''}
      {currency}
      <FormattedNumber minimumFractionDigits={minDigits} value={val}>
        {(formattedNumber) => <Highlighter searchWords={searchWords} autoEscape textToHighlight={formattedNumber} />}
      </FormattedNumber>
    </Box>
  ) : (
    <Box color="grey.400">
      <MIFormattedText label={emptyLabel} privateData={privateData} />
    </Box>
  );
};
