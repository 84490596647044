import type { SystemStyleFunction } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleFunction = () => ({
  h: '1.4rem',
  w: 'full',
  borderRadius: 'lg',
});

const darkVariant: SystemStyleFunction = () => ({
  background: 'grey.500',
});

const mediumVariant: SystemStyleFunction = () => ({
  background: 'grey.400',
});

const lightVariant: SystemStyleFunction = () => ({
  background: 'grey.300',
});

const variants = {
  dark: darkVariant,
  medium: mediumVariant,
  light: lightVariant,
};

const defaultProps = {
  variant: 'light',
};

export default {
  baseStyle,
  variants,
  defaultProps,
};
