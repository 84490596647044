import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

export const SandboxIndicator = () => (
  <Box __css={SandboxIndicatorStyle}>
    <MIFormattedText label="onboarding.fundingSources.card.sandboxIndication" />
  </Box>
);

const SandboxIndicatorStyle = {
  background: 'grey.600',
  color: 'white',
  textStyle: 'body2',
  h: 8,
  w: 'full',
  textAlign: 'center',
  pos: 'fixed',
  bottom: 0,
  mt: { base: 10, sm: 'unset' },
};
