import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { getNameParts } from 'src/utils/delivery-methods';
import { getFundingSourceIcon } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';
import { BankAccountIcon } from '../../components/BankAccountIcon';

export const RepaymentMethodSelectedOption = ({ bankAccount }: { bankAccount: AccountType }) => {
  const { icon, imageSrc } = getFundingSourceIcon(bankAccount);

  return (
    <Flex alignItems="center" color="black" data-testid="repayment-method-selected-option">
      {imageSrc && <Image src={imageSrc} w={IconSize.m} h={IconSize.m} mr={1} />}
      {icon && <BankAccountIcon name={icon} size={IconSize.s} disabled={false} />}
      <Box textStyle="body2" ml={1} data-testid="repayment-terms-selected-funding-source">
        <MIFormattedText label="bills.pay.fundingSource.achLabel" values={getNameParts(bankAccount)} />
      </Box>
    </Flex>
  );
};
