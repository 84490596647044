import { generatePath } from 'react-router-dom';
import { NotificationVariant } from 'src/core/ds/toast';
import { useModal } from 'src/helpers/react/useModal';
import { useOrgId } from 'src/hooks';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { MarkBillsAsPaidModal } from 'src/pages/bill/components/modals/MarkBillsAsPaidModal';
import { billLocations } from 'src/pages/bill/locations';
import { usePayListItems } from 'src/pages/pay/hooks/usePayListItems';
import { useSelectedIds } from 'src/pages/regular-batch-payments/BatchSettings/useBatchSelection';
import { pushNotification } from 'src/services/notifications';
import { getBillsDefaultFilters } from 'src/utils/bills';
import { BillStatus } from 'src/utils/consts';
import { BillType } from 'src/utils/types';
import { useMarkableBills } from './useMarkableBills';

export const useMarkAsPaidBatchOption = ({ onError }: { onError: () => void }) => {
  const orgId = useOrgId();
  const { loadItems: reloadItemsList } = usePayListItems();
  const { navigate } = useNavigator();
  const { selectedIds, clear } = useSelectedIds();
  const billIds = selectedIds.map((id) => id.toString());
  const [MarkBillsAsPaidModalComponent, showMarkBillsAsPaidModal] = useModal(MarkBillsAsPaidModal, {
    id: 'mark-bills-as-paid-modal',
  });
  const { markableBills } = useMarkableBills(billIds);
  const isDisabled = markableBills.length === 0;

  const onMarkAsPaidSuccess = (bills: BillType[]) => {
    clear();
    pushNotification({
      type: NotificationVariant.info,
      msg: 'regularBatchPayments.BillsGallery.Footer.batchMarkAsPaidToast',
      textValues: { billsCount: bills.length },
      testId: 'toast-message-mark-as-paid',
    });

    const path = generatePath(billLocations.filteredViewWithoutId, {
      orgId,
      ...getBillsDefaultFilters(BillStatus.PAID),
    });
    navigate(path);
    reloadItemsList();
  };

  const openMarkBillsModal = () => {
    showMarkBillsAsPaidModal({ billIds, onSuccess: onMarkAsPaidSuccess, onError });
  };

  return {
    isDisabled,
    openMarkBillsModal,
    MarkBillsAsPaidModalComponent,
  };
};
