export const iconContainerStyle = {
  mr: 3,
  backgroundColor: 'grey.300',
  display: 'flex',
  alignItems: 'center',
  paddingRight: '0.6rem',
  paddingLeft: '0.6rem',
  borderRadius: 'lg',
  cursor: 'pointer',

  _hover: {
    bg: 'grey.400',
  },
  _active: {
    bg: 'grey.400',
    svg: {
      color: 'grey.700',
    },
  },
};

export const alertDotStyle = {
  position: 'relative',
  left: '2.8rem',
  top: '0.4rem',
  w: 2,
  h: 2,
  borderRadius: 'lg',
  backgroundColor: 'red.500',
};
