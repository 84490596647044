import { forwardRef } from '@chakra-ui/react';
import { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Consts, Input, InputGroup, InputLeftElement, InputProps, InputRightElement } from 'src/core/ds/input';
import { analytics } from 'src/services/analytics';
import { isEnterPressed } from 'src/utils/events';
import { inputLeftElementStyle, inputRightElementStyle, inputStyle, searchInputStyle } from './styles';

export type SearchInputProps = InputProps &
  Omit<InputProps, 'onSubmit' | 'onClear'> & {
    onSubmit?: () => void;
    onClear?: () => void;
    eventPage?: string;
    hideIcon?: boolean;
    onKeyDown?: (KeyboardEvent) => void;
    variant?: Consts.Variant;
  };

export const SearchInput = forwardRef<SearchInputProps, 'input'>(
  (
    {
      value,
      eventPage,
      onKeyDown,
      onSubmit,
      onClear,
      autoFocus,
      isDisabled,
      hideIcon,
      variant,
      size,
      ...rest
    }: SearchInputProps,
    forwardedRef
  ) => {
    const [isFocus, setIsFocus] = useState(autoFocus || false);
    const isSearchable = !!value && !isDisabled;
    const iconColor = isSearchable || isFocus ? 'black' : 'grey.600';
    const iconCursorStyle = isSearchable ? 'pointer' : 'default';
    const defaultRef = useRef<HTMLInputElement>(null);
    const ref = forwardedRef || defaultRef;
    const searchIconSize = size === Consts.Size.sm ? IconSize.s : IconSize.lg;

    const handleSubmit = () => {
      onSubmit && onSubmit();
    };

    const handleKeyDown = useCallback(
      (event: KeyboardEvent) => {
        onKeyDown && onKeyDown(event);

        if (isEnterPressed(event) && value) {
          eventPage && analytics.track(eventPage, 'search-enter', { searchTerm: value });
          handleSubmit();
        }
      },
      [onKeyDown, handleSubmit]
    );

    const handleSearchIconClick = () => {
      if (value) {
        eventPage && analytics.track(eventPage, 'search-icon', { searchTerm: value });
        handleSubmit();
      }
    };

    const handleFocus = () => {
      setIsFocus(true);
    };

    const handleBlur = () => {
      setIsFocus(false);
    };

    const handleClear = () => {
      eventPage && analytics.track(eventPage, 'search-clear');
      onClear && onClear();
      handleFocusAfterClear();
    };

    const handleFocusAfterClear = () => {
      if (forwardedRef && typeof forwardedRef === 'object') {
        // if forwardedRef is a ref object and not a callback ref
        forwardedRef.current.focus();
      } else defaultRef?.current && defaultRef.current.focus();
    };

    return (
      <InputGroup sx={searchInputStyle}>
        {!hideIcon && (
          <InputLeftElement sx={inputLeftElementStyle} onClick={handleSearchIconClick} cursor={iconCursorStyle}>
            <Icon size={searchIconSize} name={IconNames.search} color={iconColor} />
          </InputLeftElement>
        )}
        <Input
          testId="search-input"
          type="search"
          variant={variant || Consts.Variant.filled}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus={autoFocus}
          isDisabled={isDisabled}
          ref={ref}
          sx={inputStyle(hideIcon)}
          {...rest}
        />
        {value && (
          <InputRightElement data-testid="clear-search" cursor={iconCursorStyle} sx={inputRightElementStyle}>
            <IconButton
              aria-label="Clear search input"
              onClick={handleClear}
              color={iconColor}
              icon={<Icon name={IconNames.closeMini} />}
              isDisabled={isDisabled}
            />
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);
