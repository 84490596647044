import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFundingSources } from 'src/redux/user/selectors';
import { isCardExpired } from 'src/utils/card';
import { FundingType } from 'src/utils/consts';
import { isBankAccountBlocked } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

type GetValidFundingSourcesType = [validFundingSources: AccountType[]];

export const useGetValidFundingSources = (): GetValidFundingSourcesType => {
  const fundingSources = useSelector(getFundingSources);
  const validFundingSources = useMemo(
    () =>
      fundingSources
        .filter((fs) => !(fs.fundingType === FundingType.CARD && isCardExpired(fs.cardAccount)))
        .filter((fs) => !(fs.fundingType === FundingType.ACH && isBankAccountBlocked(fs))),
    [fundingSources]
  );

  return [validFundingSources];
};
