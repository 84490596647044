import { createInnerStateSlice } from 'src/helpers/redux/innerStateSlice';
import { invitationsApi } from 'src/modules/invitations/api';
import { InvitationType } from 'src/utils/types';

type PayloadType = { token: string };
type ResultType = {
  invitation: InvitationType;
  organizations: {
    id: number;
    companyName: string;
    companyType: string;
    logoUrl: string;
  }[];
};

export const inviteeOrganizationsSlice = createInnerStateSlice<PayloadType, ResultType>({
  storeName: 'invitations',
  innerStateName: 'inviteeOrganizations',
  actionName: 'invitee_organizations',
  api: invitationsApi.inviteeOrganizations,
});
