import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import Box from 'src/core/ds/box';
import Tooltip from 'src/core/ds/tooltip';
import { UserSummaryType } from 'src/utils/types';
import { itemStyle } from './UserBadgeList.styles';

const DEFAULT_MAX = 4;

type Props = {
  users: readonly UserSummaryType[];
  max?: number;
};

export function UserBadgeList({ users, max = DEFAULT_MAX }: Props) {
  if (!users.length) return null;

  return (
    <Box position="relative">
      {users.slice(0, max).map((user, index) => (
        <Box
          __css={itemStyle}
          key={user.id}
          left={index * 15}
          zIndex={max + 2 - index}
          data-testid={`user-badge-wrapper-${user.id}`}
        >
          <Tooltip placement="top" label={`${user.firstName} ${user.lastName}`} textStyle="body4" shouldWrapChildren>
            <Avatar
              name={`${user.firstName} ${user.lastName}`}
              link={user.userLogoDetails?.logoUrl}
              size={Size.xs}
              textColor="white"
            />
          </Tooltip>
        </Box>
      ))}
      {users.length > max && (
        <Box __css={itemStyle} left={max * 15} zIndex={2}>
          <span>{`+${users.length - max}`}</span>
        </Box>
      )}
    </Box>
  );
}
