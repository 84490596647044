import { defaultCreateHashFunction } from 'src/helpers/redux/createRestfulSlice';
import { createCreateSlice } from 'src/helpers/redux/restCreateSlice';
import { scannedInvoiceApi } from '../api';
import { storeName } from '../const';

export const createBillScannedInvoiceSlice = createCreateSlice({
  storeName,
  api: scannedInvoiceApi.create,
  createHashFunc: defaultCreateHashFunction,
});
