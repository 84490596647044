import filter from 'lodash/filter';
import { createRestfulSlice, hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { BILLS_LIST_INDEX_SUCCESS } from 'src/modules/bills/bills-actions';
import { paymentRequestsApi } from 'src/modules/payment-requests/api';
import { PaymentRequestType } from 'src/utils/types';
import { name } from './consts';
import { proRequestsBatchCancelSlice } from './pro-slices/pro-requests-batch-cancel';
import { proRequestsBatchDeleteSlice } from './pro-slices/pro-requests-batch-delete';
import { proRequestsBatchMarkAsPaidSlice } from './pro-slices/pro-requests-batch-mark-as-paid';
import { proRequestsCancelSlice } from './pro-slices/pro-requests-cancel';
import { proRequestsDeleteSlice } from './pro-slices/pro-requests-delete';
import { proRequestsFetchSlice } from './pro-slices/pro-requests-fetch-slice';
import { proRequestsIsListRefreshableSlice } from './pro-slices/pro-requests-is-list-refreshable';
import { proRequestsListSlice } from './pro-slices/pro-requests-list';
import { proRequestsMarkAsPaidSlice } from './pro-slices/pro-requests-mark-as-paid';
import { proRequestsResendSlice } from './pro-slices/pro-requests-resend';
import { proRequestsUpdateSlice } from './pro-slices/pro-requests-update';

export const paymentRequestsStore = createRestfulSlice<PaymentRequestType>({
  name,
  api: {
    fetch: ({ organizationId, id }) =>
      paymentRequestsApi
        .getPaymentRequestById({ orgId: organizationId, id })
        .then(({ paymentRequest }) => paymentRequest),
    // @ts-expect-error -- I don't think this is ever called, so I'm not sure what's passed here at runtime
    update: paymentRequestsApi.editPaymentRequest,
    // @ts-expect-error -- I don't think this is ever called, so I'm not sure what's passed here at runtime
    create: paymentRequestsApi.createPaymentRequest,
    list: ({ orgId, filters }) =>
      paymentRequestsApi
        .getPaymentRequests({
          orgId,
          filters: { limit: Number.MAX_SAFE_INTEGER, ...filters },
        })
        .then((res) => ({ items: res.requests })),
    delete: paymentRequestsApi.deletePaymentRequestById as any,
  },
  slices: {
    proList: proRequestsListSlice,
    proFetch: proRequestsFetchSlice,
    proCancel: proRequestsCancelSlice,
    proBatchCancel: proRequestsBatchCancelSlice,
    proMarkAsPaid: proRequestsMarkAsPaidSlice,
    proBatchMarkAsPaid: proRequestsBatchMarkAsPaidSlice,
    proResend: proRequestsResendSlice,
    proIsListRefreshable: proRequestsIsListRefreshableSlice,
    proUpdate: proRequestsUpdateSlice,
    proDelete: proRequestsDeleteSlice,
    proBatchDelete: proRequestsBatchDeleteSlice,
  },
  selectors: {
    batchItems: (ids: Record<number, boolean>) => (state) =>
      filter(state[name].byId, (paymentRequest) => ids[paymentRequest.id]),
    byId: (state) => (id) => state[name]?.byId[id],
    getByIds: (ids) => (state) => filter(state[name].byId, (request) => ids.includes(request.id)),
  },
  extraReducers: {
    [BILLS_LIST_INDEX_SUCCESS]: (state, action) => {
      const { paymentRequests, paymentRequestsTotalCount } = action.payload;
      const key = hashListKey(action.meta.identifier);
      state.lists[key] = {
        loading: false,
        order: paymentRequests.map((e) => e.id),
        totalCount: paymentRequestsTotalCount,
      };
      paymentRequests.forEach((item) => {
        state.byId[item.id] = item;
      });
    },
  },
});
