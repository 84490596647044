import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import organizationStore from 'src/modules/organizations/organizations-store';
import { OrgPreferencesTypeKeys } from 'src/utils/consts';

type UpdateServiceFeesPreferenceParams = {
  orgId?: number;
  value: boolean;
};

export const useUpdateServiceFeesPreference = () => {
  const isUpdatingOrganizationPreference = useSelector(organizationStore.selectors.isOrganizationPreferencesUpdating);
  const organizationActions = useStoreActions(organizationStore);

  const updateServiceFeesPreference = useCallback(
    async ({ value, orgId }: UpdateServiceFeesPreferenceParams) =>
      organizationActions.updateOrganizationServiceFees({
        orgId,
        key: OrgPreferencesTypeKeys.isVendorAbsorbedFee,
        value,
      }),
    []
  );

  return {
    updateServiceFeesPreference,
    isUpdatingOrganizationPreference,
  };
};
