import { useSelector } from 'react-redux';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import DeliveryMethodsList from 'src/pages/settings/components/DeliveryMethodsList';
import { getDeliveryMethods } from 'src/redux/user/selectors';

type Props = {
  onNext: () => void;
  progressBarProps: Record<string, any>;
};

const ReceivingMethodAchExistsPage = ({ onNext, progressBarProps }: Props) => {
  const userDeliveryMethods = useSelector(getDeliveryMethods);

  const Footer = () => (
    <>
      <Box mt={4} w="full">
        <MISecurityDetails eventPage="onboarding-vendor-delivery-method" />
      </Box>
      <Box mt={10} w="full">
        <Button label="onboarding.deliveryMethods.bank.verifyCta" onClick={onNext} isFullWidth size={ButtonSizes.lg} />
      </Box>
    </>
  );

  return (
    <StepLayoutPage
      title="onboarding.deliveryMethods.bank.verifyTitle"
      subtitle="onboarding.deliveryMethods.bank.verifySubtitle"
      innerSize={50}
      footer={<Footer />}
      hideHeader
      {...progressBarProps}
    >
      <Box m="-2 0 10">
        <DeliveryMethodsList deliveryMethods={userDeliveryMethods} />
      </Box>
    </StepLayoutPage>
  );
};

export default ReceivingMethodAchExistsPage;
