import get from 'lodash/get';
import { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { RetrievedTokenizedData } from 'src/components/basis-theory/link-card-view/LinkCardViewContainer';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { ContentWrapper, Footer, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import { CardDetailsViewErrorModal } from 'src/pages/vendor/shift-vendor-to-debit/basis-theory/CardDetailsViewErrorModal';
import { ShiftToDebitState } from 'src/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import { analytics } from 'src/services/analytics';
import { capture } from 'src/utils/error-tracking';
import { shiftToDebitLocations } from '../locations';
import { CardDetailsView } from './CardDetailsView';

const eventPage = 'basis-theory';
const iframeErrorResponse = 'iframe-error-response';

type Props = {
  token: string;
  state: ShiftToDebitState;
  parentFlow?: string;
};

export const AddDeliveryMethodPage = ({
  token,
  state: { payment, organization, filesUrls, isPaymentLoading, fee },
  parentFlow,
}: Props) => {
  const history = useHistory();

  const [errorCode, setErrorCode] = useState('');
  const [cardData, setCardData] = useState({});

  const { onApiCall: validateCard, result, loading: isChecking, error } = useApi<
    [{ token: string; cardToken: string; cardBin: string }],
    any
  >({
    api: deliveryMethodsApi.validateP2DCardWithToken,
  });

  const getHeaderDescription = () =>
    `vendors.deliveryMethods.shiftToDebit.${fee?.amount === fee?.cap ? 'descriptionNoFeeValue' : 'description'}`;

  const handleLinkCard = async ({
    id,
    expirationMonth,
    expirationYear,
    last4Digits,
    cardBin,
  }: RetrievedTokenizedData) => {
    analytics.track(eventPage, 'iframe-response');
    analytics.track(eventPage, 'iframe-response-data');

    if (!id) setErrorCode(iframeErrorResponse);

    analytics.track('shift-to-debit', 'check-card-details', { vaulting: eventPage });
    setCardData({
      card: {
        digits: last4Digits,
        cardToken: id,
        cardBin,
        expiration: `${expirationMonth}/${expirationYear.toString().slice(-2)}`,
      },
    });

    try {
      await validateCard({
        token,
        cardToken: id,
        cardBin,
      });
    } catch (e: any) {
      analytics.track(eventPage, iframeErrorResponse);
      capture(e, {
        message: `${eventPage}${iframeErrorResponse}`,
      });
      setCardData({});
    }
  };

  const handleError = () => {
    analytics.track(eventPage, iframeErrorResponse);
    setErrorCode(iframeErrorResponse);
    const error = new Error(`${iframeErrorResponse}-test`);
    capture(error, `${eventPage}${iframeErrorResponse}`);
  };

  const handleCloseErrorModal = () => {
    setErrorCode('');
  };

  useEffect(() => {
    if (result) {
      analytics.track(eventPage, 'iframe-success-response');
      history.push(generatePath(shiftToDebitLocations.addAddress, { token }), cardData);
    }
  }, [result, cardData, token, history]);

  useEffect(() => {
    if (error) {
      setErrorCode(get(error, 'code') || get(error, 'response.status'));
    }
  }, [error]);

  const handleOnPrevClick = () => {
    history.goBack();
  };

  const analyticsProps = parentFlow ? { flow: `${parentFlow}: Push to Debit` } : {};

  return isPaymentLoading ? (
    <AreaLoader />
  ) : (
    <OutsideLayout hideLogin>
      <Header
        payment={payment}
        organization={organization}
        filesUrls={filesUrls}
        showFullHeader
        description={getHeaderDescription()}
        subTitle="vendors.deliveryMethods.shiftToDebit.subtitle"
        descriptionValues={{
          fee: <MIFormattedCurrency value={fee?.amount?.toString()} />,
        }}
        onPrev={parentFlow ? handleOnPrevClick : undefined}
        analyticsProps={analyticsProps}
      />
      <ContentWrapper
        title="vendors.deliveryMethods.shiftToDebit.title"
        subTitle="vendors.deliveryMethods.shiftToDebit.contentSubtitle"
      >
        <CardDetailsViewErrorModal
          isOpen={!!errorCode}
          iframeErrorCode={iframeErrorResponse}
          errorCode={errorCode}
          onClose={handleCloseErrorModal}
        />
        <CardDetailsView
          onLinkCard={handleLinkCard}
          onError={handleError}
          transparent={isChecking}
          showLinkCardButtonSpinner={isChecking}
        />
        <Footer companyName={organization?.companyName} />
      </ContentWrapper>
    </OutsideLayout>
  );
};
