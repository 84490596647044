import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const MOBILE_APP_ID = 1600209586;

export const useSmartBanner = () => {
  const location = useLocation();

  useEffect(() => {
    const mobileBannerMetaTag = document.querySelector('meta[name="apple-itunes-app"]');

    if (mobileBannerMetaTag) {
      mobileBannerMetaTag.setAttribute('content', `app-id=${MOBILE_APP_ID}, app-argument=${window.location.href}`);
    }
  }, [location.pathname]);
};
