import styled, { css } from 'styled-components';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import Flex from 'src/core/ds/flex';
import { Icon, IconSize, OldToNewIconNames } from 'src/core/ds/icon';

const AddAnotherMethodOption = styled(MIInlineLink)`
  font-size: 1.4rem;
  line-height: 2.1rem;
  width: 100%;
  padding: 1rem 2rem;
  text-align: left;
  height: inherit;
`;

const AddAnotherRadioMethodOption = styled(AddAnotherMethodOption)`
  padding: 0;
  ${(props) => props.theme.components?.MethodsList?.AddAnotherRadioMethodOption}
`;

type MethodOptionIconProps = {
  name: string;
  disabled: boolean | undefined;
};

const MethodOptionIcon = ({ name, disabled }: MethodOptionIconProps) => (
  <Flex opacity={disabled ? 0.3 : 1} mr={4}>
    <Icon name={OldToNewIconNames[name]} size={IconSize.lg} />
  </Flex>
);

const MethodOptionImage = styled.img`
  height: 2.4rem;
  width: 2.4rem;
  margin-right: 1.6rem;
  ${(props) =>
    props.isExpired &&
    css`
      filter: grayscale(100%);
      opacity: 0.3;
    `}
  ${(props) => props.theme.components?.MethodElements?.MethodImage}
`;

const MethodOptionDescription = styled.div<{
  disabled?: boolean;
  isExpired?: boolean;
}>`
  color: ${(props) => {
    if (props.disabled && !props.isExpired) {
      return props.theme.colors.dark.translucent3;
    }

    return props.theme.text.color.subtitle;
  }};
  font-weight: ${(props) => props.theme.text.weight.regular};
  text-overflow: ellipsis;
  text-align: left;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme.components?.MethodElements?.MethodOptionDescription}
`;

const MethodOptionOnTopDescription = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  text-overflow: ellipsis;
  text-align: left;
  ${(props) => props.theme.text.fontType.hint};
`;

const MethodOptionLabel = styled.div<{ disabled?: boolean }>`
  line-height: 2.4rem;
  color: ${(props) => (props.disabled ? props.theme.colors.dark.translucent3 : props.theme.text.color.main)};
  font-size: 1.6rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.components?.MethodElements?.MethodOptionLabel}
`;

export {
  AddAnotherMethodOption,
  AddAnotherRadioMethodOption,
  MethodOptionDescription,
  MethodOptionIcon,
  MethodOptionImage,
  MethodOptionLabel,
  MethodOptionOnTopDescription,
};
