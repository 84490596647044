import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { isBillBelongsToSharedVendor } from 'src/pages/bill/utils/billGetters';
import { DeliveryType, PaymentStatus, VendorManagedByEnum } from 'src/utils/consts';
import {
  AccountType,
  BillType,
  DeliveryMethodType,
  DeliveryOptionType,
  PaymentFinancing,
  PaymentType,
} from 'src/utils/types';
import { FinancingPaymentMethodSection } from './components/FinancingPaymentMethodSection';
import { PayBillPaymentActivityDeductionDateInfo } from './components/PayBillPaymentActivityDeductionDateInfo';
import { PayBillPaymentActivityDeliveryDateInfo } from './components/PayBillPaymentActivityDeliveryDateInfo';
import { PayBillPaymentActivityDeliveryMethodInfo } from './components/PayBillPaymentActivityDeliveryMethodInfo';
import { PayBillPaymentActivityFundingSourceInfo } from './components/PayBillPaymentActivityFundingSourceInfo';
import { PayBillPaymentActivityMemoInfo } from './components/PayBillPaymentActivityMemoInfo';

export type ActivityActionsType = {
  onEditFundingSource: () => void;
  onEditPartialAmount: () => void;
  onEditDate: () => void;
  onEditNote: () => void;
  onEditDeliveryMethod?: () => void;
};

type Props = {
  bill: BillType;
  fundingSource: AccountType;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  activityActions: ActivityActionsType;
  financing: PaymentFinancing | null;
  isFinancingPayment: boolean;
  isFinancingLoading: boolean;
  deliveryOptions?: DeliveryOptionType[];
  isEditDisabled: boolean;
};

export const PayBillPaymentActivity = ({
  bill,
  activityActions,
  fundingSource,
  payment,
  deliveryMethod,
  isFinancingPayment,
  financing,
  isFinancingLoading,
  deliveryOptions,
  isEditDisabled,
}: Props) => {
  const { onEditFundingSource, onEditDate, onEditDeliveryMethod, onEditNote } = activityActions;
  const isSharedVendor = isBillBelongsToSharedVendor(bill);
  const isVendorMangedByMSN = bill?.vendor?.managedBy === VendorManagedByEnum.MSN;
  const isVirtualDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.VIRTUAL;
  const isACHDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.ACH;
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.RPPS;
  const showMemo = !isRPPSDeliveryMethod;
  const isAllowEditDeliveryMethod = isVirtualDeliveryMethod && payment?.status !== PaymentStatus.FAILED;
  const fundingSourceTitle = isFinancingPayment
    ? 'payBillPaymentActivity.payByTitle'
    : 'payBillPaymentActivity.payFromTitle';

  let editDateGeneral: (() => void) | undefined;

  if (isAllowEditDeliveryMethod) {
    editDateGeneral = onEditDeliveryMethod;
  } else if (isACHDeliveryMethod) {
    editDateGeneral = onEditDate;
  }

  return (
    <>
      <BlockTitleContainer>
        <MIFormattedText label={fundingSourceTitle} />
      </BlockTitleContainer>
      <MainPaymentActivityContainer>
        {isFinancingPayment && financing ? (
          <FinancingPaymentMethodSection
            bill={bill}
            financing={financing}
            fundingSource={fundingSource}
            isEditDisabled={isEditDisabled}
            isFinancingLoading={isFinancingLoading}
            onEditDate={onEditDate}
            onEditFundingSource={onEditFundingSource}
          />
        ) : (
          <>
            <PayBillPaymentActivityFundingSourceInfo
              bill={bill}
              payment={payment}
              fundingSource={fundingSource}
              onEdit={onEditFundingSource}
              isEditDisabled={isEditDisabled}
            />
            <PayBillPaymentActivityDeductionDateInfo
              bill={bill}
              payment={payment}
              onEdit={onEditDate}
              fundingSource={fundingSource}
              isEditDisabled={isEditDisabled}
            />
          </>
        )}
        {isSharedVendor && (
          <PayBillPaymentActivityDeliveryDateInfo
            bill={bill}
            payment={payment}
            deliveryMethod={deliveryMethod}
            fundingSource={fundingSource}
            isEditDisabled={isEditDisabled}
          />
        )}
      </MainPaymentActivityContainer>

      {(!isSharedVendor || isVendorMangedByMSN) && (
        <>
          <BlockTitleContainer>
            <MIFormattedText label="payBillPaymentActivity.receiveTitle" />
          </BlockTitleContainer>
          <MainPaymentActivityContainer>
            {!isVirtualDeliveryMethod && (
              <PayBillPaymentActivityDeliveryMethodInfo
                deliveryMethod={deliveryMethod}
                onEdit={onEditDeliveryMethod}
                bill={bill}
                payment={payment}
                isEditDisabled={isEditDisabled}
              />
            )}
            <PayBillPaymentActivityDeliveryDateInfo
              bill={bill}
              payment={payment}
              deliveryMethod={deliveryMethod}
              fundingSource={fundingSource}
              onEdit={editDateGeneral}
              deliveryOptions={deliveryOptions}
              isEditDisabled={isEditDisabled}
            />
          </MainPaymentActivityContainer>
        </>
      )}
      {showMemo && (
        <PayBillPaymentActivityMemoInfo payment={payment} onEdit={onEditNote} isEditDisabled={isEditDisabled} />
      )}
    </>
  );
};

const MainPaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.2rem;
  ${(props) => props.theme?.components?.PaymentActivity?.MainPaymentActivityContainer}
`;

const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.subtitle};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.BlockTitleContainer}
`;
