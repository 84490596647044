import { differenceInSeconds } from 'date-fns';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import loader from 'src/animations/financing/io-loader.json';
import { Lottie } from 'src/components/common/Lottie';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { Stack } from 'src/core/ds/stack';
import { profileStore } from 'src/modules/profile/profile-store';
import {
  financingAnalyticsEvents,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { useBusinessApplicationContext } from 'src/pages/bill/pay/installments/context/business-context/useBusinessApplicationContext';
import { useApplyForEligibility } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/useApplyForEligibility';
import { useInstallmentsOptionsRequest } from 'src/pages/bill/pay/installments/pages/installments-options/useInstallmentsOptionsRequest';
import { ApplicationErrorType, FinancingEligibilityStatus } from 'src/pages/bill/pay/installments/types';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';

type Props = {
  goNext: () => void;
  onFailure: (errorType: ApplicationErrorType) => void;
};

export const ReviewingDetails = ({ goNext, onFailure }: Props) => {
  const { business, ownership } = useBusinessApplicationContext();
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);

  const { applyForEligibility } = useApplyForEligibility();
  const { getInstallmentOptions } = useInstallmentsOptionsRequest();

  useEffect(() => {
    const sendApplication = async () => {
      if (business && ownership) {
        const start = Date.now();
        const eligibilityResponse = await applyForEligibility(currentOrg.id, business, ownership);
        const end = Date.now();
        analytics.track(financingPage.reviewingDetails, financingAnalyticsEvents.detailsReviewCompleted, {
          reviewDuration: differenceInSeconds(end, start),
          decision: eligibilityResponse.decision,
        });

        if (eligibilityResponse.decision === FinancingEligibilityStatus.Pending) {
          return onFailure(ApplicationErrorType.Pending);
        }

        if (eligibilityResponse.decision === FinancingEligibilityStatus.Ineligible) {
          return onFailure(ApplicationErrorType.Ineligible);
        }

        const installmentOptionsResults = await getInstallmentOptions({
          orgId,
          vendorId: payment.vendorId,
          paymentAmount: payment.amount,
        });

        if (installmentOptionsResults.payload.decision === FinancingEligibilityStatus.Ineligible) {
          return onFailure(ApplicationErrorType.AmountIneligible);
        }

        return goNext();
      }

      return onFailure(ApplicationErrorType.General);
    };
    const start = Date.now();
    sendApplication().catch(() => {
      const end = Date.now();
      analytics.track(financingPage.reviewingDetails, financingAnalyticsEvents.detailsReviewCompleted, {
        reviewDuration: differenceInSeconds(end, start),
        decision: 'failure',
      });
      onFailure(ApplicationErrorType.General);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex height="full" width="full" justifyContent="center" alignItems="center">
      <Stack direction="column" align="center" textAlign="center" spacing={4}>
        <Box mb={12}>
          <Lottie animationData={loader} height="10rem" width="10rem" />
        </Box>
        <Box textStyle="h1Semi">
          <MIFormattedText label="financing.businessOwnershipDetails.reviewingDetails.title" />
        </Box>
        <Box textStyle="body3">
          <MIFormattedText label="financing.businessOwnershipDetails.reviewingDetails.subtitle" />
        </Box>
      </Stack>
    </Flex>
  );
};
