import { registrationLocations } from './registration/locations';

export const authLocations = {
  base: [
    '/login',
    '/spend-management/login',
    '/auth/google/success',
    '/auth/google/error',
    ...registrationLocations.base,
    '/reset-password',
    '/request-reset-password',
    '/orgs/:orgId/meliome/register',
  ],
  authPrivateBase: ['/login/intuit'],
  authAgnosticBase: [
    '/login-with-google-success',
    '/change-password/:token',
    '/login-with-token/:token',
    '/login/intuit/launch/:realmId',
    '/login/intuit/launch',
    '/register',
  ],
  login: '/login',
  loginWithToken: '/login-with-token/:token',
  loginSpendManagement: '/spend-management/login',
  googleLoginSuccess: '/login-with-google-success',
  register: registrationLocations,
  intuit: {
    login: '/login/intuit',
    launchNoRealmId: '/login/intuit/launch',
    launch: '/login/intuit/launch/:realmId',
    disconnect: '/login/intuit/disconnect',
    quickbooks: '/login/intuit/quickbooks',
  },
  changePassword: '/change-password/:token',
  requestResetPassword: '/request-reset-password',
  resetPassword: '/reset-password',
  registerVendor: '/orgs/:orgId/meliome/register',
};
