import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { SliceSagas } from 'src/helpers/redux/types';

type Params<Payload, Result> = {
  storeName: string;
  actionName: string;
  mainStateName?: string;
  innerStateName: string;
  api: (payload: Payload) => Promise<Result>;
  sagas?: SliceSagas;
};
type InnerState = Record<string, { loading: boolean; error: any }>;

export function createInnerStateSlice<Payload, Result = Payload>({
  innerStateName,
  storeName,
  mainStateName = storeName,
  actionName,
  api,
  sagas,
}: Params<Payload, Result>) {
  return createApiCallSlice<Payload, InnerState, Result>({
    name: generateSliceName(storeName, actionName),
    api,
    initialState: {
      [innerStateName]: {
        loading: false,
        error: undefined,
      },
    },
    reducers: {
      [ON_REQUEST]: (state) => {
        state[innerStateName].loading = true;
        state[innerStateName].error = undefined;
      },
      [ON_SUCCESS]: (state) => {
        state[innerStateName].loading = false;
        state[innerStateName].error = undefined;
      },
      [ON_FAILURE]: (state, action) => {
        state[innerStateName].loading = false;
        state[innerStateName].error = action.error;
      },
    },
    selectors: {
      loading: (state) => state[mainStateName][innerStateName].loading,
      error: (state) => state[mainStateName][innerStateName].error,
    },
    sagas,
  });
}
