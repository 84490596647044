import { CustomFeed } from 'src/components/braze/CustomFeed';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';

const sxForCard: CSSObject = {
  mb: 8,
  mt: -4,
};

export const PayBillDateCustomFeed = () => (
  <Flex justify="center" w="full" data-testid="pay-bill-date-custom-feed">
    <Box maxWidth="50rem" w="full">
      <CustomFeed feedType="calendar_above" sxForCard={sxForCard} />
    </Box>
  </Flex>
);
