import * as React from 'react';

type ValidationErrorType = React.ReactElement | string;
type ValidationErrorsType = Partial<Record<string, ValidationErrorType>>;

export class ValidationError extends Error {
  error: {
    validationErrors?: ValidationErrorsType;
  };

  constructor({ message, validationErrors }: { message?: string; validationErrors?: ValidationErrorsType }) {
    super(message);
    this.error = {
      validationErrors,
    };
  }
}
