import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useBreak } from 'src/hoc';
import { billLocations } from 'src/pages/bill/locations';
import { usePayBillActions } from 'src/pages/bill/pay/hooks/usePayBillActions';
import { payBillEventPage, usePayBillDataContext } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { emailVendorOnPaymentSchedule } from 'src/pages/bill/utils';
import { getBill, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { getBillPaymentIndex, getBillsDefaultFilters, serializePaymentId } from 'src/utils/bills';
import { BillStatus, KybStatus, NotificationVariant } from 'src/utils/consts';
import { NavigateType, PaymentType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
};

export const usePayBillPaymentOperations = ({ navigate, nextStepURL, prevStepURL }: Params) => {
  const { setIsLoading } = usePayBillDataContext();
  const device = useBreak();

  const bill = useSelector(getBill);
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const selectedFundingSource = useSelector(getSelectedFundingSource);

  const { onNext } = usePayBillNavigateCommon({ navigate, nextStepURL, prevStepURL });

  const {
    createPayment: createPaymentAction,
    createInternationalPayment: createInternationalPaymentAction,
    createBillAndPaymentFromPaymentRequest,
    createRecurringBill: createRecurringBillAction,
    createFinancingPayment: createFinancingPaymentAction,
    updatePayment: updatePaymentAction,
    reschedulePayment: reschedulePaymentAction,
    retryFailedToDeliverPayment: retryFailedToDeliverPaymentAction,
    retryFailedToCollectPayment: retryFailedToCollectPaymentAction,
    endPayBillFlow,
    setPaymentAmount: setPaymentAmountAction,
    cancelAndRetryPayment: cancelAndRetryPaymentAction,
  } = usePayBillActions();

  const createTrackedPayment = (createCallback: () => Promise<PaymentType>) => async () => {
    analytics.track(payBillEventPage, 'confirm', {
      fundingSourceId: selectedFundingSource?.id,
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    try {
      const newPayment = await createCallback();
      analytics.track(payBillEventPage, 'confirm-success', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      analytics.trackMqlEvent('created-payment', 'mql');

      if (companyInfo.kybStatus === KybStatus.ACCEPTED) {
        analytics.track(payBillEventPage, 'kybaccepted-confirm-success', {
          billPaymentIndex: getBillPaymentIndex(bill),
        });
      }

      emailVendorOnPaymentSchedule(newPayment, orgId, bill.vendor?.contactEmail);
      onNext();
    } catch (e) {
      setIsLoading(false);
      analytics.track(payBillEventPage, 'confirm-failed', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
    }
  };

  const createPayment = createTrackedPayment(createPaymentAction);
  const createPaymentFromPaymentRequest = createTrackedPayment(createBillAndPaymentFromPaymentRequest);
  const createInternationalPayment = createTrackedPayment(createInternationalPaymentAction);
  const createFinancingPayment = createTrackedPayment(createFinancingPaymentAction);

  const createRecurringBill = async () => {
    analytics.track(payBillEventPage, 'recurring-confirm', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    try {
      await createRecurringBillAction();
      analytics.track(payBillEventPage, 'recurring-confirm-success', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      analytics.trackMqlEvent('created-payment', 'mql');

      if (companyInfo.kybStatus === KybStatus.ACCEPTED) {
        analytics.track(payBillEventPage, 'kybaccepted-confirm-success', {
          billPaymentIndex: getBillPaymentIndex(bill),
        });
      }

      // Also set traits from bill flow
      analytics.setTraits({
        last_bill_added_date: new Date().toISOString(),
      });

      navigate(billLocations.pay.recurring.success);
    } catch (e) {
      setIsLoading(false);
      analytics.track(payBillEventPage, 'recurring-confirm-failed', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
    }
  };

  const updatePayment = async () => {
    analytics.track(payBillEventPage, 'update-confirm', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    try {
      await updatePaymentAction();
      analytics.track(payBillEventPage, 'update-confirm-success', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      onNext();
    } catch (e) {
      setIsLoading(false);
      analytics.track(payBillEventPage, 'update-confirm-failed', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
    }
  };

  const retryFailedPayment = (retryCallback: () => Promise<PaymentType>, billStatus: BillStatus) => async () => {
    analytics.track(payBillEventPage, 'reschedule-confirm', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    try {
      const newPayment = await retryCallback();
      analytics.track(payBillEventPage, 'reschedule-confirm-success', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      analytics.trackMqlEvent('reschedule-payment', 'mql');

      const defaultFilters = getBillsDefaultFilters(billStatus);
      endPayBillFlow(false);

      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'bills.status.paymentScheduled',
      });

      if (device.isMobile) {
        navigate(generatePath(billLocations.filteredViewWithoutId, { orgId, ...defaultFilters }));
      } else {
        const { id } = bill;
        const navigateId = serializePaymentId(id, newPayment.id);
        navigate(generatePath(billLocations.filteredView, { orgId, id: navigateId, ...defaultFilters }));
      }
    } catch (e) {
      setIsLoading(false);
      analytics.track(payBillEventPage, 'confirm-failed', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
    }
  };

  const reschedulePayment = retryFailedPayment(reschedulePaymentAction, BillStatus.SCHEDULED);
  const retryFailedToDeliver = retryFailedPayment(retryFailedToDeliverPaymentAction, BillStatus.PAID);
  const retryFailedToCollect = retryFailedPayment(retryFailedToCollectPaymentAction, BillStatus.SCHEDULED);

  const setPaymentAmount = (amount: number) => {
    if (bill.balance !== amount) {
      analytics.track(payBillEventPage, `payment-amount-changed`, {
        billPaymentIndex: getBillPaymentIndex(bill),
        amount,
        balance: bill.balance,
      });
    }

    setPaymentAmountAction(amount);
  };

  const cancelAndRetryPayment = async () => {
    analytics.track(payBillEventPage, 'cancel-and-retry-confirm', {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    try {
      await cancelAndRetryPaymentAction();
      analytics.track(payBillEventPage, 'cancel-and-retry-success', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      onNext();
    } catch (e) {
      setIsLoading(false);
      analytics.track(payBillEventPage, 'cancel-and-retry-failed', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
    }
  };

  return {
    createPayment,
    createRecurringBill,
    createPaymentFromPaymentRequest,
    createInternationalPayment,
    createFinancingPayment,
    reschedulePayment,
    retryFailedToDeliver,
    retryFailedToCollect,
    updatePayment,
    setPaymentAmount,
    cancelAndRetryPayment,
  };
};
