export const melioMeLocations = {
  base: [
    '/meliome/pay/:link',
    '/meliome/vendor-options',
    '/meliome/register/email-verification',
    '/meliome/guest',
    '/meliome/register-success',
  ],
  wizard: {
    index: '/meliome/pay/:link',
    signUp: '/meliome/pay/:link/signUp',
    emailVerify: '/meliome/pay/:link/email-verification',
    infoDetails: '/meliome/pay/:link/info-details',
    selectOrg: '/meliome/pay/:link/select-org',
    editFundingSource: '/meliome/pay/:link/edit-funding-source',
    editFundingSourceWithParams:
      '/meliome/pay/:link/edit-funding-source?totalAmount=:totalAmount&invoiceNumber=:invoiceNumber',
    paymentDate: '/meliome/pay/:link/payment-date',
    memo: '/meliome/pay/:link/memo',
    confirm: '/meliome/pay/:link/confirm',
    expedite: '/meliome/pay/:link/expedite',
    success: '/meliome/pay/:link/success',
    successSignUp: '/meliome/pay/:link/success/signUp',
    requestPayment: '/meliome/pay/:link/r/:hash',
    requestPaymentNoEditing: '/meliome/pay/:link/t',
  },
  guestPaymentRedirect: '/meliome/guest',
  requestPaymentWithOptions: '/meliome/vendor-options',
  registerEmailVerify: '/meliome/register/email-verification',
  registerSuccess: '/meliome/register-success',
};
