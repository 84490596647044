import unionWith from 'lodash/unionWith';
import { createTransform, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { CLEAR_STATE } from 'src/redux/user/actionTypes';
import { BillType, PaymentType } from 'src/utils/types';
import { billFactory } from '../../pages/bill/records';
import { paymentFactory } from '../../pages/payment/records';
import {
  ADD_NEW_DELIVERY_METHOD,
  BEGIN_RECURRING_PAY_BILL_FLOW,
  BEGIN_RECURRING_PAY_BILL_FLOW_FAILED,
  BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS,
  BEGIN_REGULAR_PAY_BILL_FLOW,
  BEGIN_REGULAR_PAY_BILL_FLOW_FAILED,
  BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS,
  CANCEL_AND_RETRY_PAYMENT,
  CANCEL_AND_RETRY_PAYMENT_ERROR,
  CANCEL_AND_RETRY_PAYMENT_SUCCESS,
  CREATE_BILL_AND_PAYMENT_FROM_PAYMENT_REQUEST,
  CREATE_FINANCING_PAYMENT,
  CREATE_INTERNATIONAL_PAYMENT,
  CREATE_PAYMENT,
  CREATE_PAYMENT_ERROR,
  CREATE_PAYMENT_SUCCESS,
  CREATE_RECURRING_BILL,
  CREATE_RECURRING_BILL_ERROR,
  CREATE_RECURRING_BILL_SUCCESS,
  END_PAY_BILL_FLOW,
  FETCH_BILL,
  FETCH_BILL_FAILED,
  FETCH_BILL_SUCCESS,
  RESCHEDULE_PAYMENT,
  RESET_ERROR,
  RETRY_FAILED_PAYMENT_ERROR,
  RETRY_FAILED_PAYMENT_SUCCESS,
  RETRY_FAILED_TO_COLLECT_PAYMENT,
  RETRY_FAILED_TO_DELIVER_PAYMENT,
  SELECT_DELIVERY_METHOD,
  SELECT_FUNDING_SOURCE,
  SELECT_PAYMENT_DATES,
  SELECT_REPAYMENT_METHOD,
  SET_BILL_ID,
  SET_FEE,
  SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW,
  SET_PAYMENT_AMOUNT,
  SET_PAYMENT_PURPOSE,
  SET_URL_TO_BACK,
  UPDATE_BILL,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_ERROR,
  UPDATE_PAYMENT_MEMO,
  UPDATE_PAYMENT_SUCCESS,
} from './actionTypes';
import { PayBillWizardState } from './types';

export const initialState: PayBillWizardState = {
  isLoading: false,
  bill: billFactory(),
  payment: paymentFactory() as PayBillWizardState['payment'],
  isRecurring: false,
  recurringBill: undefined,
  firstBillIdWithRecurringBill: null,
  errorCode: null,
  urlToBack: '',
  redirectUrl: null,
  exitUrl: null,
  fee: undefined,
  isCancelAndRetryPaymentFlow: false,
};

const recordsTransform = createTransform(
  (inboundState: BillType | PaymentType) => inboundState,
  (outboundState: BillType | PaymentType, key) => {
    if (key === 'bill') {
      return billFactory(outboundState as BillType);
    }

    if (key === 'payment') {
      return paymentFactory(outboundState as PaymentType);
    }

    return outboundState;
  }
);
const persistConfig = {
  key: 'payBillWizard',
  storage: storageSession,
  transforms: [recordsTransform],
};

const payBillWizardReducer = (state: PayBillWizardState = initialState, action) => {
  switch (action.type) {
    case BEGIN_REGULAR_PAY_BILL_FLOW:
      return {
        ...state,
        isLoading: true,
        redirectUrl: action.redirectUrl,
        exitUrl: action.exitUrl,
      };

    case BEGIN_REGULAR_PAY_BILL_FLOW_SUCCESS:
      return {
        ...state,
        bill: action.bill,
        payment: action.payment,
        isLoading: false,
      };

    case BEGIN_REGULAR_PAY_BILL_FLOW_FAILED:
      return { ...state, isLoading: false };

    case BEGIN_RECURRING_PAY_BILL_FLOW:
      return { ...initialState, isLoading: true, isRecurring: true };

    case BEGIN_RECURRING_PAY_BILL_FLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bill: action.bill,
        payment: action.payment,
        recurringBill: action.recurringBill,
        scannedInvoiceId: action.scannedInvoiceId,
      };

    case BEGIN_RECURRING_PAY_BILL_FLOW_FAILED:
      return { ...state, isLoading: false };

    case SET_PAYMENT_AMOUNT:
      return {
        ...state,
        payment: { ...state.payment, amount: action.amount },
      };
    case SET_PAYMENT_PURPOSE:
      return {
        ...state,
        payment: { ...state.payment, purpose: action.purpose },
      };

    case SELECT_FUNDING_SOURCE:
      return {
        ...state,
        payment: {
          ...state.payment,
          fundingSourceId: action.id,
        },
      };

    case SELECT_REPAYMENT_METHOD:
      return {
        ...state,
        payment: {
          ...state.payment,
          fundingSourceId: action.id,
        },
      };

    case SELECT_PAYMENT_DATES:
      return {
        ...state,
        payment: {
          ...state.payment,
          scheduledDate: action.scheduledDate,
          deliveryEta: action.deliveryEta,
          maxDeliveryEta: action.maxDeliveryEta,
          deliveryPreference: action.deliveryPreference,
        },
      };

    case ADD_NEW_DELIVERY_METHOD:
      return {
        ...state,
        bill: {
          ...state.bill,
          vendor: {
            ...state.bill.vendor,
            deliveryMethods: unionWith(
              [action.deliveryMethod],
              state.bill.vendor?.deliveryMethods || [],
              (a, b) => a.id === b.id
            ),
          },
        },
      };

    case SELECT_DELIVERY_METHOD:
      return {
        ...state,
        payment: {
          ...state.payment,
          deliveryMethodId: action.deliveryMethod?.id,
          deliveryMethod: action.deliveryMethod,
        },
      };

    case UPDATE_PAYMENT_MEMO:
      return {
        ...state,
        payment: { ...state.payment, note: action.memo },
      };

    case UPDATE_BILL:
      return {
        ...state,
        bill: { ...state.bill, goodsReceived: action.goodsReceived },
      };

    case FETCH_BILL:
      return { ...state, isLoading: true };

    case FETCH_BILL_SUCCESS:
      return { ...state, bill: action.bill, isLoading: false };

    case FETCH_BILL_FAILED:
      return { ...state, isLoading: false };

    case CREATE_RECURRING_BILL:
      return { ...state, isLoading: true, errorCode: null };

    case CREATE_RECURRING_BILL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firstBillIdWithRecurringBill: action.firstBillIdWithRecurringBill,
        payment: action.payment,
      };

    case CREATE_RECURRING_BILL_ERROR:
      return { ...state, isLoading: false, errorCode: action.errorCode };

    case CREATE_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case CREATE_PAYMENT_SUCCESS:
      return { ...state, payment: action.payment, isLoading: false };

    case CREATE_PAYMENT_ERROR:
      return { ...state, isLoading: false, errorCode: action.errorCode };

    case UPDATE_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case UPDATE_PAYMENT_SUCCESS:
      return { ...state, payment: action.payment, isLoading: false };

    case UPDATE_PAYMENT_ERROR:
      return { ...state, isLoading: false, errorCode: action.errorCode };

    case RESCHEDULE_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case RETRY_FAILED_TO_DELIVER_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case RETRY_FAILED_TO_COLLECT_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case RETRY_FAILED_PAYMENT_SUCCESS:
      return { ...state, payment: action.payment, isLoading: false };

    case RETRY_FAILED_PAYMENT_ERROR:
      return { ...state, isLoading: false, errorCode: action.errorCode };

    case END_PAY_BILL_FLOW:
      return { ...initialState };

    case SET_URL_TO_BACK:
      return { ...state, urlToBack: action.urlToBack };

    case RESET_ERROR:
      return { ...state, errorCode: null };

    case SET_FEE:
      return { ...state, fee: action.payload };

    case SET_BILL_ID:
      return {
        ...state,
        payment: { ...state.payment, billId: action.billId },
      };

    case CLEAR_STATE:
      return { ...initialState };

    case CREATE_BILL_AND_PAYMENT_FROM_PAYMENT_REQUEST:
      return { ...state, isLoading: true, errorCode: null };

    case CREATE_INTERNATIONAL_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case CREATE_FINANCING_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case CANCEL_AND_RETRY_PAYMENT:
      return { ...state, isLoading: true, errorCode: null };

    case CANCEL_AND_RETRY_PAYMENT_SUCCESS:
      return { ...state, payment: action.payment, isLoading: false };

    case CANCEL_AND_RETRY_PAYMENT_ERROR:
      return { ...state, isLoading: false, errorCode: action.errorCode };

    case SET_IS_CANCEL_AND_RETRY_PAYMENT_FLOW:
      return {
        ...state,
        payment: { ...state.payment, deliveryPreference: '' },
        isCancelAndRetryPaymentFlow: action.payload,
      };

    default:
      return state;
  }
};

export default persistReducer(persistConfig, payBillWizardReducer);
