import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { AccountingPlatformBankAccountType } from 'src/utils/types';
import { accountingPlatformBankAccountsApi } from './api';
import { name } from './consts';

export const accountingPlatformBankAccountsStore = createRestfulSlice<AccountingPlatformBankAccountType>({
  name,
  api: accountingPlatformBankAccountsApi,
  selectors: {
    byId: (id) => (state) => state[name]?.byId[id],
  },
});
