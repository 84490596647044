import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { DockedButtonMobile } from 'src/components/mobile/DockedButtonMobile';
import { Button } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useModal } from 'src/helpers/react/useModal';
import { useBreak } from 'src/hoc';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { getUsersOfOrg } from 'src/modules/users/users-store';
import { Section } from 'src/pages/settings/components/Section';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { ApprovalWorkflowType } from 'src/utils/types';
import { ApprovalWorkflowCardContainer } from './ApprovalWorkflowCardContainer';
import { ApprovalWorkflowModal } from './ApprovalWorkflowModal';
import { ApprovalWorkflowUserType } from './ApprovalWorkflowModal/types';
import { hasPaymentCreatorRole, toPaymentCreatorsOptions } from './ApprovalWorkflowModal/utils';
import { EmptyState } from './EmptyState';
import { useCanCreateWorkflow, useFetchApprovalWorkflowsAndUsers } from './hooks';

export const ApprovalWorkflowsPage = () => {
  const dispatch = useDispatch();
  const { isMobile } = useBreak();
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const currentUserId = useSelector(profileStore.selectors.getCurrentUserId);
  const currentUserRole = useSelector(profileStore.selectors.getUserRole);
  const canCreateWorkflow = useCanCreateWorkflow();
  const { companyName } = useSelector(getCompanyInfo);
  const approvalWorkflowsOrdered: ApprovalWorkflowType[] = useSelector(
    approvalWorkflowsStore.selectors.orderedListValue
  );
  const [deletedWorkflowId, setDeletedWorkflowId] = useState(0);
  const approvalWorkflowsListLoading = useSelector(approvalWorkflowsStore.selectors.list.status())?.loading;
  const approvalWorkflowsCreateLoading = useSelector(approvalWorkflowsStore.selectors.create.status())?.loading;
  const approvalWorkflowsDeleteLoading = useSelector(approvalWorkflowsStore.selectors.delete.status(deletedWorkflowId))
    ?.loading;
  const orgUsers = useSelector(getUsersOfOrg(orgId)) as ReadonlyArray<ApprovalWorkflowUserType>;
  const allPaymentCreators = toPaymentCreatorsOptions(orgUsers, orgId).filter(hasPaymentCreatorRole);
  const showDesktopCreateBtn = !isMobile && canCreateWorkflow;
  const minUserWorkflowThreshold: number | undefined = useSelector(
    approvalWorkflowsStore.selectors.minUserWorkflowThreshold(currentUserId)
  );
  const maxThresholdForActor = !isAdminRole(currentUserRole) ? minUserWorkflowThreshold : undefined;

  const handleCreate = async (dbWorkflow: ApprovalWorkflowType) => {
    await dispatch(approvalWorkflowsStore.actions.create({ orgId, ...dbWorkflow }));
  };
  const handleDelete = async (id: number) => {
    setDeletedWorkflowId(id);
    await dispatch(approvalWorkflowsStore.actions.delete({ orgId, id }));
  };

  const handleUpdate = async (dbWorkflow: ApprovalWorkflowType) => {
    await dispatch(approvalWorkflowsStore.actions.update({ orgId, ...dbWorkflow }));
  };

  const [ApprovalWorkflowModalElement, showApprovalWorkflowModal, isCreateModalShowing] = useModal(
    ApprovalWorkflowModal,
    {
      onSubmit: handleCreate,
      companyName,
      orgUsers,
      orgId,
      modalName: 'approvalWorkflowModal',
      maxThreshold: maxThresholdForActor,
    }
  );

  const [isApprovalCardModalOpen, setIsApprovalCardModalOpen] = useState(false);
  const showMobileCreateBtn = isMobile && canCreateWorkflow && !isApprovalCardModalOpen && !isCreateModalShowing;

  useFetchApprovalWorkflowsAndUsers({ orgId });

  const handleApprovalCardModalShow = () => {
    setIsApprovalCardModalOpen(true);
  };

  const handleApprovalCardModalClose = () => {
    setIsApprovalCardModalOpen(false);
  };

  const addBtnAnalyticsProps = {
    numberOfExistingWorkflows: approvalWorkflowsOrdered.length,
    numberOfUsersInClient: orgUsers.length,
  };

  if (approvalWorkflowsListLoading || approvalWorkflowsCreateLoading || approvalWorkflowsDeleteLoading) {
    return <AreaLoader />;
  }

  return (
    <>
      <Section
        title="settings.approvalWorkflows.page.title"
        rightToTitleElement={
          showDesktopCreateBtn ? (
            <Button
              label="settings.approvalWorkflows.page.addWorkflow"
              leftIcon={<Icon name={IconNames.plus} color="white" size={IconSize.s} />}
              testId="new-approval-workflow-button"
              onClick={showApprovalWorkflowModal}
              analyticsProperties={addBtnAnalyticsProps}
            />
          ) : null
        }
      >
        {approvalWorkflowsOrdered.length === 0 && <EmptyState />}
        {approvalWorkflowsOrdered.map((workflow) => (
          <ApprovalWorkflowCardContainer
            key={workflow.id}
            allPaymentCreators={allPaymentCreators}
            companyName={companyName}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            orgId={orgId}
            role={currentUserRole}
            userId={currentUserId}
            workflow={workflow}
            orgUsers={orgUsers}
            onModalShow={handleApprovalCardModalShow}
            onModalClose={handleApprovalCardModalClose}
            maxThreshold={maxThresholdForActor}
          />
        ))}
      </Section>
      {showMobileCreateBtn ? (
        <DockedButtonMobile
          label="settings.approvalWorkflows.page.addWorkflowMobile"
          testId="new-approval-workflow-button-mobile"
          onClick={showApprovalWorkflowModal}
          analyticsProperties={addBtnAnalyticsProps}
        />
      ) : null}
      {ApprovalWorkflowModalElement}
    </>
  );
};
