import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import { PaymentType, VirtualCardType } from 'src/utils/types';
import { getVirtualCardProcessedDate } from 'src/utils/vendors';
import { getPaymentStatuses } from '../utils';

export const convertedPaymentUtils = (payment: PaymentType, virtualCardState: { virtualCard: VirtualCardType }) => {
  const {
    isShiftToAchRequested,
    isPaymentVirtualCardCleared,
    isPaymentCompleted,
    isVirtualCardCanceled,
  } = getPaymentStatuses(payment);

  const getBlockLabel = () => {
    if (isPaymentCompleted) return 'vendors.deliveryMethods.shiftSuvcToAch.deposited.receive';

    if (isPaymentVirtualCardCleared) return 'vendors.deliveryMethods.shiftSuvcToAch.cleared.receive';

    return 'vendors.deliveryMethods.shiftSuvcToAch.requested.receive';
  };

  const getPaymentSource = () => {
    if (isPaymentVirtualCardCleared && !isPaymentCompleted)
      return 'vendors.deliveryMethods.shiftSuvcToAch.cardTransfer';

    return 'vendors.deliveryMethods.shiftSuvcToAch.bankTransfer';
  };

  const getPaymentDetails = () => {
    if (isPaymentVirtualCardCleared && !isPaymentCompleted) return payment?.vendor?.contactEmail;

    return 'vendors.deliveryMethods.shiftSuvcToAch.bankAccountWithDigits';
  };

  const getDateTitle = () => {
    if (isPaymentCompleted) return 'vendors.deliveryMethods.shiftSuvcToAch.deposited.dateTitle';

    if (isPaymentVirtualCardCleared && !isPaymentCompleted)
      return 'vendors.deliveryMethods.shiftSuvcToAch.cleared.dateTitle';

    if (isShiftToAchRequested) return 'vendors.deliveryMethods.shiftSuvcToAch.requested.dateTitle';

    return 'vendors.deliveryMethods.shiftSuvcToAch.deposited.dateTitle';
  };

  const getDateToDisplayInBlockTitle = () => {
    if (isShiftToAchRequested) {
      return payment?.deliveryMethod?.updatedAt;
    }

    if (isPaymentVirtualCardCleared && !isPaymentCompleted) {
      return getVirtualCardProcessedDate(virtualCardState.virtualCard);
    }

    return payment?.bill?.invoiceDate;
  };

  const getDeliveryDate = () => {
    if (isPaymentCompleted) return payment?.paidDate;

    if (isShiftToAchRequested) {
      return payment?.deliveryEta;
    }

    if (isPaymentVirtualCardCleared && !isPaymentCompleted) {
      return getVirtualCardProcessedDate(virtualCardState.virtualCard);
    }

    return payment?.bill?.invoiceDate;
  };

  const getPaymentSourceIcon = () => {
    if (isPaymentVirtualCardCleared && !isPaymentCompleted) return <Mastercard width="2.4rem" height="2.4rem" />;

    return <Icon name={IconNames.bankOut} size={IconSize.lg} />;
  };

  const getDeliveryIcon = () => {
    if (isPaymentVirtualCardCleared && !isPaymentCompleted)
      return <Icon name={IconNames.calendar} size={IconSize.lg} />;

    return <Icon name={IconNames.calendarMove} size={IconSize.lg} />;
  };

  const getBlockIcon = () => {
    if (isVirtualCardCanceled) {
      return <Icon color="red.500" size={IconSize.xl} name={IconNames.alertFill} />;
    }

    if (isPaymentVirtualCardCleared || isPaymentCompleted) {
      return <Icon color="green.500" size={IconSize.xl} name={IconNames.checkFill} />;
    }

    return <Icon color="yellow.500" size={IconSize.xl} name={IconNames.alertFill} />;
  };

  const getAdditionalDescription = () =>
    isShiftToAchRequested && !isPaymentCompleted
      ? 'bills.form.paymentActivity.deliveryMethodAdditionalDescriptionPayee'
      : '';

  return {
    getBlockLabel,
    getPaymentSource,
    getPaymentDetails,
    getDateTitle,
    getDateToDisplayInBlockTitle,
    getDeliveryDate,
    getPaymentSourceIcon,
    getDeliveryIcon,
    getBlockIcon,
    getAdditionalDescription,
    paymentStates: {
      isShiftToAchRequested,
      isPaymentVirtualCardCleared,
      isPaymentCompleted,
      isVirtualCardCanceled,
    },
  };
};
