import styled from 'styled-components';
import { Option } from 'src/components/common/MISingleSelect';
import { AccountingSoftwareAccountCategory } from 'src/utils/types';

type Props = {
  context: 'value' | 'menu';
  account: AccountingSoftwareAccountCategory;
};

export type AccountingSoftwareAccountCategoryOption = Option & {
  account: AccountingSoftwareAccountCategory;
  label: string;
};

export const AccountCategoryOption = ({ context, account }: Props) => (
  <AccountOption
    selected={context === 'value'}
    indent={(account.FullyQualifiedName.match(/:/g) || []).length}
    data-testid={`dropdown-option-${account.Id}`}
  >
    <div className="account-name">{account.Name}</div>
    <div className="account-type" title={account.AccountType}>
      {account.AccountType}
    </div>
  </AccountOption>
);

export const accountCategoryToAccountCategoryOption = (
  account: AccountingSoftwareAccountCategory
): AccountingSoftwareAccountCategoryOption => ({
  value: account.Id,
  label: account.Name,
  account,
});

type AccountOptionProps = {
  selected: boolean;
  indent: number;
};

const AccountOption = styled.div<AccountOptionProps>`
  display: flex;

  .account-name {
    padding-left: ${(props) => (props.selected ? 0 : props.indent)}em;
    margin-right: 0.5em;
    flex-grow: 1;
  }

  .account-type {
    display: ${(props) => (props.selected ? 'none' : 'block')};
    color: ${(props) => props.theme.text.color.light};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
