import Box from 'src/core/ds/box/Box';
import { DeliveryDateFormat } from './DeliveryDateFormat';

type DeliveryDatePropsType = {
  date: Date;
  maxDate?: Date | null;
};

export const DeliveryDate = ({ date, maxDate }: DeliveryDatePropsType) => {
  const formattedDate = <DeliveryDateFormat date={date} maxDate={maxDate} />;

  return (
    <Box as="span" data-testid="delivery-date" whiteSpace="nowrap" textStyle="body2">
      {formattedDate}
    </Box>
  );
};
