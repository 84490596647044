import { useSelector } from 'react-redux';
import { IconNames } from 'src/core/ds/icon';
import { profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { MenuItemPropsType } from '../../types/type';
import { useCanViewCompaniesScreen } from '../useCanViewCompaniesScreen';
import { useCanViewTeamScreen } from '../useCanViewTeamScreen';

export const useAccountantsGroupItems = (): Array<MenuItemPropsType> => {
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const canViewCompaniesScreen = useCanViewCompaniesScreen();
  const canViewTeamScreen = useCanViewTeamScreen();
  const links: Array<MenuItemPropsType> = [];

  if (canViewCompaniesScreen) {
    links.push({
      key: isMemberOfAccountingFirm ? 'menu-item-clients' : 'menu-item-companies',
      to: globalLocations.companies.index,
      label: isMemberOfAccountingFirm ? 'nav.clients' : 'nav.companies',
      icon: IconNames.gallery,
      eventName: 'companies-dashboard',
    });
  }

  if (canViewTeamScreen) {
    links.push({
      key: 'menu-item-team',
      to: globalLocations.team.index,
      label: 'nav.team',
      icon: IconNames.team,
      eventName: 'team-management',
    });
  }

  return links;
};
