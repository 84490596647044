import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { NewBillCreateOptionsPageContainer } from 'src/pages/bill/NewBillCreateOptionsPageContainer';
import { NewBillPageContainer } from 'src/pages/bill/NewBillPageContainer';
import { billLocations } from './locations';
import { BillPayWizardRouter } from './pay/BillPayWizardRouter';

export function BillWizardRouter() {
  return (
    <Switch>
      <SmartRoute path={billLocations.create.index} exact>
        <NewBillPageContainer />
      </SmartRoute>
      <SmartRoute path={billLocations.create.options} exact>
        <NewBillCreateOptionsPageContainer />
      </SmartRoute>
      <SmartRoute path={billLocations.pay.base}>
        <BillPayWizardRouter />
      </SmartRoute>
    </Switch>
  );
}
