import { featureFlags } from '@melio/shared-web';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import { useOrgId } from 'src/hooks';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { organizationsApi } from 'src/modules/organizations/api';
import { useInboxEmailAddress } from 'src/modules/organizations/hooks/useInboxEmailAddress';
import { profileStore } from 'src/modules/profile/profile-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { paymentClassificationLocations } from 'src/pages/payment-classification/locations';
import { useFetchCompanySettings } from 'src/pages/settings/hooks/useFetchCompanySettings';
import { settingsLocations } from 'src/pages/settings/locations';
import { updateCompanyInfoAction } from 'src/redux/user/actions';
import { getDeliveryMethods, getOwnedVendorHandle, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import {
  convertLegalAddressToGoogleAddress,
  convertToGoogleAddress,
  convertToServerAddress,
  convertToServerLegalAddress,
} from 'src/utils/address';
import { CompanyInfoOnboardingOrigin, FeatureFlags, ScreenMode } from 'src/utils/consts';
import { CompanyInfoType, FieldType, GoogleCombinedAddressType } from 'src/utils/types';
import { CompanySettingsPage } from './components/CompanySettingsPage';
import { companyInfoFactory } from './records';

const eventPage = 'settings-company-info';

export const CompanySettingsPageContainer = () => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const orgId = useOrgId();
  const isMSNPortal = useIsMsnDashboardDisplay();
  const deliveryMethods = useSelector(getDeliveryMethods);
  const ownedVendorHandle = useSelector(getOwnedVendorHandle);
  const { isBillInboxEnabled, inboxEmailAddress, onCopyEmail } = useInboxEmailAddress({ eventPage });
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const { fetch: fetchCompanyInfo, companyInfo } = useFetchCompanySettings();
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const { navigate } = useNavigator();
  const model = useMemo<Partial<CompanyInfoType>>(
    () => ({
      companyName: companyInfo.companyName,
      legalCompanyName: companyInfo.legalCompanyName,
      phone: companyInfo.phone,
      contactFirstName: companyInfo.contactFirstName,
      contactLastName: companyInfo.contactLastName,
      taxId: companyInfo.taxId,
      taxIdType: companyInfo.taxIdType,
      isLoading: false,
      mode: ScreenMode.VIEW,
      ownedVendorEmail: companyInfo.ownedVendorEmail,
    }),
    [companyInfo]
  );

  const initialState = useMemo(
    () => ({
      ...model,
      address: convertToGoogleAddress(companyInfo),
      legalAddress: convertLegalAddressToGoogleAddress(companyInfo),
    }),
    [companyInfo, model]
  );

  const [address, setAddress] = useState<GoogleCombinedAddressType>(initialState.address);
  const [legalAddress, setLegalAddress] = useState<GoogleCombinedAddressType>(initialState.legalAddress);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const [mode, setMode] = useState<ScreenMode>(ScreenMode.VIEW);

  const [companyInfoMV, { submit }, , isLoading] = useForm(model, {
    submit: (value) => onUpdateCompanyInfo(value),
  });
  useEffect(() => {
    fetchCompanyInfo();
  }, []);

  const onUpdateCompanyInfo = async (value: Partial<CompanyInfoType>) => {
    const { companyName, phone, contactFirstName, contactLastName, taxId, taxIdType, legalCompanyName } = value;
    analytics.track('settings-company-info', 'save');
    const newCompanyInfo = {
      ...companyInfo,
      companyName,
      legalCompanyName,
      phone,
      contactFirstName,
      contactLastName,
      taxId,
      taxIdType,
      ...convertToServerAddress(address),
      ...convertToServerLegalAddress(legalAddress),
    } as Partial<CompanyInfoType>;

    const validationErrors = getValidationErrors('companyInfo', newCompanyInfo, [
      'companyName',
      'phone',
      'contactFirstName',
      'contactLastName',
      'googlePlaceId',
      'zipCode',
      'taxId',
      'taxIdType',
      'legalCompanyName',
      'legalGooglePlaceId',
      'legalZipCode',
    ]);
    setValidationErrors(validationErrors);

    if (!isValidationOk(validationErrors)) {
      analytics.track('settings-company-info', 'save-validation-error', validationErrors);
      throw new ValidationError({ validationErrors });
    } else {
      analytics.track('settings-company-info', 'save-success');
      const { companyInfo: updatedCompanyInfo } = await organizationsApi.updateCompanyInfo(orgId, newCompanyInfo);
      const companyInfoRecord = companyInfoFactory({
        ...updatedCompanyInfo,
        logoUrl: companyInfo.logoUrl,
        inboxEmailAddress,
      });

      updateCompanyInfoAction(dispatch, companyInfoRecord, profile);
      analytics.setTraits({
        companyName: updatedCompanyInfo.companyName,
        phone: updatedCompanyInfo.phone,
        contactName: `${updatedCompanyInfo.contactFirstName} ${updatedCompanyInfo.contactLastName}`,
        contactFirstName: updatedCompanyInfo.contactFirstName,
        contactLastName: updatedCompanyInfo.contactLastName,
      });

      setMode(ScreenMode.VIEW);
    }
  };

  const onCancel = () => {
    analytics.track(eventPage, 'cancel-edit');
    companyInfoMV.setModelState(initialState);
    setAddress(initialState.address);
    setLegalAddress(initialState.legalAddress);
    setMode(ScreenMode.VIEW);
  };

  const onToggleMode = async () => {
    if (mode === ScreenMode.EDIT) {
      await submit();
    } else {
      analytics.track(eventPage, 'go-to-edit-mode');
      setMode(ScreenMode.EDIT);
    }
  };

  const onChangeModel = ({ id, value }: FieldType) => {
    companyInfoMV[id]?.onChange({ value });
  };

  const onGoToPaymentClassificationForm = () => {
    analytics.track('settings-company-info', 'go-to-payment-classification');
    navigate(generatePath(paymentClassificationLocations.index, { orgId }));
  };

  const goToManualAddress = () => {
    const redirectUrl = settingsLocations.company;
    navigate(onboardingLocations.companyInfo.addressManual, false, {
      origin: CompanyInfoOnboardingOrigin.SETTINGS,
      redirectUrl,
    });
  };

  const onLegalAddressChange = (address: GoogleCombinedAddressType) => {
    setLegalAddress(address);
  };

  const onAddressChange = (address: GoogleCombinedAddressType) => {
    setAddress(address);
  };

  return (
    <>
      <CompanySettingsPage
        mode={mode}
        onChangeModel={onChangeModel}
        validationErrors={validationErrors}
        isMSNPortal={isMSNPortal}
        isLoading={isLoading}
        handle={ownedVendorHandle}
        model={companyInfoMV}
        address={address}
        legalAddress={legalAddress}
        onAddressChange={onAddressChange}
        onLegalAddressChange={onLegalAddressChange}
        onToggleMode={onToggleMode}
        deliveryMethods={deliveryMethods}
        onGoToPaymentClassificationForm={onGoToPaymentClassificationForm}
        editable={permissions.settings.editCompanySettings() && !isMSNPortal}
        goToManualAddress={goToManualAddress}
        inboxEmailAddress={inboxEmailAddress}
        onCopyEmail={onCopyEmail}
        isBillInboxEnabled={isBillInboxEnabled}
        shouldDisplayZendesk={shouldDisplayZendesk}
      />
      {mode === ScreenMode.EDIT && (
        <MIFloatedEditDoneButtons
          onDone={onToggleMode}
          onCancel={onCancel}
          doneLabel="bills.edit.save"
          cancelLabel="bills.edit.cancel"
          isProcessing={isLoading}
        />
      )}
    </>
  );
};
