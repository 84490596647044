import { History } from 'history';
import { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useSmartBanner } from 'src/hooks/useSmartBanner';
import authStore from 'src/modules/auth/auth-store';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { FundingSourcesRouter } from 'src/pages/funding-sources/FundingSourcesRouter';
import { fundingSourcesLocations } from 'src/pages/funding-sources/locations';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { MicroDepositVerificationRouter } from 'src/pages/micro-deposit-veification/Router';
import { useOrgsRouterAccess } from 'src/pages/useOrgsRouterAccess';
import { GlobalState } from 'src/redux/types';
import {
  getCompanyInfo,
  getIsLoggedInAs,
  getIsUserChecked,
  getOrgId,
  getProfile,
  getUserPreferences,
} from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CompanyType, UserAuthType } from 'src/utils/consts';
import { CompanyInfoType, UserContextType } from 'src/utils/types';
import { canSwitchCompanies, isLoginFromForest } from 'src/utils/user';
import { AuthAgnosticRouter } from './auth/AuthAgnosticRouter';
import { AuthPrivateRouter } from './auth/AuthPrivateRouter';
import { AuthRouter } from './auth/AuthRouter';
import { CodeVerificationPageContainer } from './auth/email-verification/CodeVerificationPageContainer';
import { VerificationWrapper } from './auth/multi-factor-authentication/VerificationWrapper';
import { globalLocations } from './locations';
import { GuestRoute } from './meliome/GuestRoute';
import { MSNRegistrationRouter } from './onboarding/msn-registration/MSNRegistrationRouter';
import { OrgsRouter } from './orgs/OrgsRouter';
import { QuickbookMultiOrgOnboardingRouter } from './quickbook-multi-org-onboarding/QuickbookMultiOrgOnboardingRouter';
import { buildRoutes } from './route';
import { VendorsRouter } from './vendor/VendorsRouter';

type MapStateToProps = {
  profile: UserContextType | null;
  isLoggedInAs: boolean;
  isUserChecked: boolean;
  companyInfo: CompanyInfoType;
  orgId: number | undefined;
};

type Props = MapStateToProps & {
  history: History;
};

const Router = ({ isLoggedInAs, companyInfo, isUserChecked, profile, history, orgId }: Props) => {
  const site = useSiteContext();
  const userAuthType = useSelector(authStore.selectors.userAuthType);
  const userPreferences = useSelector(getUserPreferences);
  const { isOrgsRouterAllowed, pathToRedirect } = useOrgsRouterAccess();
  const isMemberOfAccountingFirm = profile?.organizations.some(
    (org) => org.companyType === CompanyType.ACCOUNTING_FIRM
  );

  useSmartBanner();

  useEffect(
    () =>
      history.listen(() => {
        if (history.action === 'POP') {
          analytics.trackAction('history-pop');
        }

        if (history.action !== 'REPLACE' && analytics.engagementService) {
          analytics.engagementService.closeInAppMessages();
        }
      }),
    [history]
  );

  useEffect(() => {
    const onUnload = () => {
      analytics.trackAction('page-left');
    };

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  useEffect(() => {
    if (!isLoginFromForest() && profile) {
      analytics.identify(profile, undefined, {
        isLoggedInAs,
        companyInfo,
      });
    }
  }, [profile, isLoggedInAs, companyInfo]);

  if (site.authOnEntry && !isUserChecked) {
    return <AreaLoader />;
  }

  const routes = buildRoutes(site, userAuthType);
  let redirectUrl = orgId
    ? generatePath(site.redirectAuthUserOnNonAuthRoutes, { orgId })
    : site.redirectAuthUserOnNonAuthRoutes;

  if (orgId && canSwitchCompanies(userPreferences, profile?.organizations ?? [], isMemberOfAccountingFirm)) {
    redirectUrl = generatePath(globalLocations.dashboard.companies, { orgId });
  }

  return (
    <Switch>
      <SmartRoute path={globalLocations.auth.register.codeVerification} exact>
        <CodeVerificationPageContainer />
      </SmartRoute>
      <SmartRoute path={globalLocations.auth.register.authCodeVerification} exact>
        <VerificationWrapper profile={profile} />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.auth.base}
        allowFor={[
          UserAuthType.UNAUTHENTICATED,
          UserAuthType.GUEST,
          UserAuthType.EMAIL_NOT_VERIFIED,
          UserAuthType.TWO_FA_REQUIRED,
        ]}
        exact
        notAllowedComponent={<Redirect to={redirectUrl} />}
      >
        <AuthRouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.auth.authAgnosticBase}>
        <AuthAgnosticRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.auth.authPrivateBase}
        allowFor={[UserAuthType.AUTHENTICATED, UserAuthType.GUEST]}
      >
        <AuthPrivateRouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.onboarding.msnRegistration.base}>
        <MSNRegistrationRouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.microDepositVerification.index}>
        <MicroDepositVerificationRouter />
      </SmartRoute>
      <SmartRoute path={globalLocations.quickbookMultiOrgOnboarding.base}>
        <QuickbookMultiOrgOnboardingRouter />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.orgs.index}
        allowCallbackFn={() => isOrgsRouterAllowed}
        notAllowedComponent={pathToRedirect ? <Redirect to={{ pathname: pathToRedirect }} /> : undefined}
      >
        <OrgsRouter orgsRoutes={routes.orgsRoutes} />
      </SmartRoute>
      <SmartRoute
        path={globalLocations.vendor.base}
        allowFor={[UserAuthType.UNAUTHENTICATED, UserAuthType.AUTHENTICATED]}
      >
        <VendorsRouter />
      </SmartRoute>
      <SmartRoute
        path={melioMeLocations.base}
        allowFor={[
          UserAuthType.UNAUTHENTICATED,
          UserAuthType.GUEST,
          UserAuthType.EMAIL_NOT_VERIFIED,
          UserAuthType.AUTHENTICATED,
        ]}
      >
        <GuestRoute />
      </SmartRoute>
      <SmartRoute path={fundingSourcesLocations.base}>
        <FundingSourcesRouter />
      </SmartRoute>
      {routes.publicRoutes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Switch>
  );
};

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
  companyInfo: getCompanyInfo(state),
  isUserChecked: getIsUserChecked(state),
  isLoggedInAs: getIsLoggedInAs(state),
  orgId: getOrgId(state),
});

export default compose(connect(mapStateToProps))(Router);
