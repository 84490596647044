import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type Props = {
  onClick: () => void;
  label: string;
};

export const AddDeliveryMethodMenuItem = ({ onClick, label }: Props) => (
  <Box as="a" onClick={onClick} textStyle="link3" _hover={{ textDecoration: 'underline' }} data-testid={label}>
    <MIFormattedText label={label} />
  </Box>
);
