import Box from 'src/core/ds/box';
import { Illustration, IllustrationProps, IllustrationSize } from 'src/core/ds/illustration';
import { modalImageStyle } from './style';

type Props = IllustrationProps;

export const ModalIllustration = ({ size = IllustrationSize.sm, ...rest }: Props) => (
  <Box __css={modalImageStyle}>
    <Illustration size={size} {...rest} />
  </Box>
);
