import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import loaderAnimation from 'src/images/melio-logo-loader.gif';

type Props = {
  onCancelAction: () => void;
  title: string;
  subtitle: string;
  buttonLabel: string;
  buttonVariant: ButtonVariants;
};

export const FileLoader = ({ onCancelAction, title, subtitle, buttonLabel, buttonVariant }: Props) => (
  <Flex h="full" direction="column" justify="center" align="center">
    <Image src={loaderAnimation} mb={8} mt={5} w="7rem" h="7rem" />
    <Box color="black" textStyle="body2Semi" textAlign="center">
      <MIFormattedText label={title} />
    </Box>
    <Box color="grey.600">
      <MIFormattedText label={subtitle} />
    </Box>
    <Box
      display={{ base: 'flex', md: 'block' }}
      mt={8}
      w={{ base: '90%', md: 'unset' }}
      justifyContent={{ base: 'center', md: 'unset' }}
      textAlign={{ base: 'center', md: 'unset' }}
    >
      <Button variant={ButtonVariants[buttonVariant]} onClick={onCancelAction} label={buttonLabel} />
    </Box>
  </Flex>
);
