import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

type Props = {
  onGoNext?: () => void;
  onSubmit: () => void;
  showContinueWithoutVerifyingButton: boolean;
};

export const MicroDepositsWasSentButtons = ({ onSubmit, onGoNext, showContinueWithoutVerifyingButton }: Props) => (
  <>
    {showContinueWithoutVerifyingButton ? (
      <Flex
        justify="flex-end"
        direction={{ base: 'column-reverse', md: 'row' }}
        position={{ base: 'fixed', md: 'initial' }}
        bottom={{ base: '0' }}
        right={{ base: '0' }}
        left={{ base: '0' }}
        boxShadow={{
          base: '0 -0.4rem 1.5rem 0 rgba(0, 0, 0, 0.05)',
          md: 'none',
        }}
        p={{ base: '2rem', md: '0' }}
      >
        <Button
          size={ButtonSizes.md}
          variant={ButtonVariants.tertiaryNaked}
          onClick={onGoNext}
          label="settings.microDeposits.depositsWasSentDialog.continue"
        />
        <Button
          size={ButtonSizes.md}
          variant={ButtonVariants.primary}
          onClick={onSubmit}
          label="settings.microDeposits.depositsWasSentDialog.verify"
        />
      </Flex>
    ) : (
      <Button
        size={ButtonSizes.lg}
        variant={ButtonVariants.primary}
        onClick={onSubmit}
        label="settings.microDeposits.depositsWasSentDialog.submit"
      />
    )}
  </>
);
