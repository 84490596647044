import { isValidationOk } from '@melio/sizzers-js-common';
import { Dispatch } from '@reduxjs/toolkit';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import once from 'lodash/once';
import * as React from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { withBreak, withNavigator } from 'src/hoc';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { withHook } from 'src/hooks/withHook';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { billsApi } from 'src/modules/bills/api';
import { billsStore } from 'src/modules/bills/bills-store';
import { vendorsApi } from 'src/modules/vendors/api';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getBillValidationErrors } from 'src/pages/bill/components/billValidationErrors';
import { useBillFiles } from 'src/pages/bill/hooks/useBillFiles';
import { useBillVendorId, UseBillVendorIdReturnType } from 'src/pages/bill/hooks/useBillVendorId';
import { billLocations } from 'src/pages/bill/locations';
import { billFactory } from 'src/pages/bill/records';
import { vendorLocations } from 'src/pages/vendor/locations';
import { getAccountIdentifierValidationError } from 'src/pages/vendor-directory/select-vendor/utils';
import { beginRecurringPayBillFlowAction } from 'src/redux/payBillWizard/actions';
import { GlobalState } from 'src/redux/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillsDefaultFilters, getBillsSearchPath, isPaymentRequest } from 'src/utils/bills';
import { BillFrequency, BillStatus, ContactsTab, Currency, DirectoryType } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { isFirstQBOPage, melioClose } from 'src/utils/external-events';
import { isNonNullable } from 'src/utils/isNonNullable';
import locations from 'src/utils/locations';
import { BillType, FieldType, RecurringBillType } from 'src/utils/types';
import { recalculateDueDate } from '../utils';

type MapStateToProps = {
  orgId: number;
  loadAccountingPlatformStatus: {
    loading: boolean;
    error?: Error;
  };
};

type MapDispatchToProps = {
  beginRecurringPayBillFlow: (bill: BillType, recurringBill: RecurringBillType) => void;
  listAccountingPlatforms: (arg0: { orgId: number }) => void;
  checkVendorPaymentPreferences: (arg0: { orgId: number; id: string }) => Promise<any>;
  createNewBillAction: (arg0: { orgId: number; params: BillType }) => Promise<any>;
};

type Props = {
  locationState?: Record<string, any>;
  site: any;
  device?: {
    isMobile: boolean;
  };
  navigate: (arg0: string, arg1?: boolean | null, arg2?: Record<string, any> | null) => void;
  query: {
    vendorId: string;
    start: number;
    limit: number;
    status: BillStatus;
  };
} & MapStateToProps &
  MapDispatchToProps &
  ReturnType<typeof useBillFiles> &
  UseBillVendorIdReturnType;

type StateBillType = Omit<BillType, 'totalAmount'> & { totalAmount: string };

type State = {
  bill: StateBillType;
  vendorAccountIdentifier?: string;
  validationErrors: Record<string, any>;
  isRecurring: boolean;
  frequency: BillFrequency;
  occurrences: number;
  prevBill: BillType | null;
  isVendorBlockedForPayment: boolean;
};

const eventPage = 'bill-create';

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
  loadAccountingPlatformStatus: accountingPlatformsStore.selectors.list.status({
    orgId: getOrgId(state),
  })(state),
});

const mapDispatchToProps = (dispatch: Dispatch): MapDispatchToProps => ({
  beginRecurringPayBillFlow(bill: BillType, recurringBill: RecurringBillType) {
    dispatch(beginRecurringPayBillFlowAction(bill, recurringBill));
  },
  listAccountingPlatforms({ orgId }) {
    dispatch(accountingPlatformsStore.actions.list({ orgId }));
  },
  checkVendorPaymentPreferences({ orgId, id }) {
    const vendorActions = getStoreActions(vendorsStore)(dispatch);

    return vendorActions.checkVendorPaymentPreferences({ orgId, id });
  },
  createNewBillAction({ orgId, params }) {
    const billActions = getStoreActions(billsStore)(dispatch);

    return billActions.create({ orgId, params });
  },
});

export function withNewBillData() {
  return function (Component: any) {
    return compose(
      withNavigator(),
      withSiteContext(),
      withBreak(),
      withHook()(useBillFiles),
      withHook()(useBillVendorId),
      connect(mapStateToProps, mapDispatchToProps)
    )(
      class ComponentWithNewBillData extends React.Component<Props, State> {
        formTextFieldsAnalytics = {
          totalAmount: null,
          invoiceNumber: null,
          note: null,
        };

        constructor(props: Props) {
          super(props);

          const { locationState } = this.props;

          const defaultVendorId = this.props.query.vendorId ? Number(this.props.query.vendorId) : undefined;
          const bill =
            locationState && locationState.bill
              ? billFactory(locationState.bill)
              : billFactory({
                  vendorId: defaultVendorId,
                });
          const occurrences =
            locationState && locationState.recurringBill ? locationState.recurringBill.occurrences : '';
          const frequency =
            locationState && locationState.recurringBill
              ? locationState.recurringBill.frequency
              : BillFrequency.ONE_TIME;

          this.state = {
            vendorAccountIdentifier: '',
            bill,
            validationErrors: {},
            isRecurring: frequency !== BillFrequency.ONE_TIME,
            occurrences,
            frequency,
            prevBill: null,
            isVendorBlockedForPayment: false,
          };

          // we want the analytics for onChange to fire only once
          forOwn(this.formTextFieldsAnalytics, (val, key) => {
            this.formTextFieldsAnalytics[key] = once(() => {
              analytics.track(eventPage, `${key}-create`);
            });
          });
        }

        componentDidMount() {
          const { loadAccountingPlatformStatus, listAccountingPlatforms, orgId } = this.props;

          if (isEmpty(loadAccountingPlatformStatus)) {
            listAccountingPlatforms({ orgId });
          }
        }

        handleFieldChangeAnalytics = (obj: Record<string, any>, properties) => {
          const { id } = obj;
          const filedMeta = get(obj, 'meta', { action: '' });
          const { action } = filedMeta;
          const DROP_DOWNS = ['vendorId', 'terms'];
          let eventAction = 'select';

          if (action === 'create-option' && indexOf(DROP_DOWNS, id) > -1) {
            eventAction = 'create';
          }

          // we want to track event if 1 char was entered in the text fields (but only one time, not for every char)
          if (this.formTextFieldsAnalytics[id]) {
            this.formTextFieldsAnalytics[id]();
          } else {
            analytics.track(eventPage, `${id}-${eventAction}`, properties);
          }
        };

        onChange = ({ id, value }: Record<string, any>) => {
          this.setState({ [id]: value } as State);

          if (value === BillFrequency.MONTHLY || value === BillFrequency.WEEKLY || value === BillFrequency.ONE_TIME) {
            analytics.track('bill-create', 'frequency-changed', {
              frequency: value,
            });
            this.setState({
              isRecurring: value === BillFrequency.MONTHLY || value === BillFrequency.WEEKLY,
            });
          }
        };

        onFieldChange = (obj: FieldType & Record<string, any>) => {
          // eslint-disable-line react/sort-comp
          const { bill } = this.state;
          const { onVendorIdChange } = this.props;
          const valueKey = Object.prototype.hasOwnProperty.call(obj, 'value') ? 'value' : 'date';
          let changedField: string | number = obj[valueKey];
          const idType: string | string[] = obj.id.includes('.') ? obj.id.split('.') : obj.id;
          const analyticsProperties = {};

          if (idType === 'vendorId') {
            changedField = onVendorIdChange(obj);
          } else {
            this.handleFieldChangeAnalytics(obj, analyticsProperties);
          }

          const newDueDate = recalculateDueDate(idType, changedField, bill);

          const newBill = billFactory(
            Object.assign(
              {},
              bill,
              this.getNewChangedField(bill, idType, changedField),
              newDueDate ? { dueDate: newDueDate } : {},
              { invoiceDate: new Date() }
            )
          );

          let newState: Partial<State> = { bill: newBill };

          if (idType === 'vendorAccountIdentifier') {
            newState = {
              ...newState,
              vendorAccountIdentifier: changedField as string,
            };
          }

          this.setState(newState as State);
        };

        onCancelForm = (prevBill) => {
          this.setState({
            bill: {
              ...prevBill,
            },
          });
        };

        onLoadBill = async (bill: BillType) => {
          const { setBillFiles } = this.props;
          this.setState({
            bill: { ...bill },
            validationErrors: {},
          });
          await setBillFiles(bill);
        };

        onUpdateBill = async (bill: BillType, cb: (isPassedValidation: boolean) => void) => {
          const { orgId, query, navigate } = this.props;

          analytics.track(eventPage, 'edit');
          try {
            const { object: savedBill } = await billsApi.editBillById(orgId, bill.id, bill, 'all');
            const updatedBill = billFactory(savedBill);
            analytics.track(eventPage, 'edit-bill-success');
            this.setState({ bill: updatedBill });
            const billsSearchPath = `/bills?${getBillsSearchPath({
              status: query.status,
              baseSearch: `id=${updatedBill.id}`,
              start: query.start,
              limit: query.limit,
            })}`;
            navigate(billsSearchPath);

            cb(true);
          } catch {
            analytics.track(eventPage, 'edit-bill-fail');
            cb(false);
          }
        };

        onCreateBill = async (
          bill: BillType,
          cb: (isPassedValidation: boolean) => void = () => undefined,
          shouldReturnToBillsDashboard: boolean
        ) => {
          const { orgId, site, checkVendorPaymentPreferences, createNewBillAction, navigate } = this.props;
          try {
            const billToCreate = Object.assign({}, bill, {
              waitForSync: site.waitForSync,
              balance: bill.totalAmount,
              status: BillStatus.UNPAID,
              currency: Currency.USD,
            });
            const { payload } = await createNewBillAction({ orgId, params: billToCreate });
            analytics.track(eventPage, 'save-success', {
              vendorId: payload?.vendor?.id,
              vendorManagedBy: payload?.vendor?.managedBy,
            });

            if (eventPage === 'bill-create') {
              analytics.trackMqlEvent('added-bill', 'mql');
            }

            const newBill = billFactory(payload);
            const {
              payload: { blockPayments },
            } = await checkVendorPaymentPreferences({
              orgId,
              // @ts-expect-error -- is bill and vendorId always defined?
              id: bill?.vendorId,
            });
            this.setState({
              isVendorBlockedForPayment: blockPayments,
              bill: newBill,
            });

            analytics.setTraits({
              last_bill_added_date: new Date().toISOString(),
            });
            cb(true);

            const isRequestAndHasNotDeliveryMethods =
              isPaymentRequest(newBill.id) && isEmpty(newBill.vendor?.deliveryMethods);

            if (blockPayments) {
              return;
            }

            if (
              site.redirectToDashboardOnCloseCreateBill &&
              (isRequestAndHasNotDeliveryMethods || shouldReturnToBillsDashboard)
            ) {
              const defaultFilters = getBillsDefaultFilters(BillStatus.UNPAID);
              const redirectPath = generatePath(billLocations.filteredViewWithoutId, { orgId, ...defaultFilters });
              navigate(redirectPath, true, {
                name: 'initialState',
              });
            } else if (isRequestAndHasNotDeliveryMethods || shouldReturnToBillsDashboard) {
              navigate(generatePath(billLocations.view, { orgId, id: newBill.id }));
            } else {
              navigate(generatePath(billLocations.pay.funding, { orgId, billId: newBill.id }));
            }
          } catch (e) {
            analytics.track(eventPage, 'save-fail');
            cb(false);
          }
        };

        getNewChangedField = (bill: Record<string, any>, id: any, value: any) =>
          Array.isArray(id)
            ? {
                [id[0]]: bill[id[0]].reduce((arr, item, i) => {
                  arr.push(Object.assign({}, item, i === +id[2] ? { [id[1]]: value } : {}));

                  return arr;
                }, []),
              }
            : { [id]: value };

        onSubmitBill = async (cb: (isPassedValidation: boolean) => void, shouldReturnToBillsDashboard: boolean) => {
          const { bill, isRecurring, occurrences, frequency, vendorAccountIdentifier } = this.state;
          const {
            orgId,
            checkVendorPaymentPreferences,
            files,
            getDirectoryVendorFromVendorOption,
            isNewVendor,
            createNewVendor,
          } = this.props;
          const totalAmount = convertCurrencyToNumber(bill.totalAmount);

          let totalBill: BillType = Object.assign(
            {},
            bill,
            bill.totalAmount ? { totalAmount, balance: totalAmount } : {},
            { files },
            { occurrences },
            { frequency }
          );

          let validationErrors = getBillValidationErrors(totalBill, isRecurring);

          const directoryVendor = bill.vendorId ? getDirectoryVendorFromVendorOption(bill.vendorId) : undefined;

          if (directoryVendor?.directoryId && directoryVendor?.directoryType === DirectoryType.Biller) {
            const accountIdentifierValidationError = await getAccountIdentifierValidationError(
              orgId,
              directoryVendor.directoryId,
              vendorAccountIdentifier
            );

            validationErrors = merge(validationErrors, accountIdentifierValidationError);
          }

          this.setState({ validationErrors }, async () => {
            if (isValidationOk(this.state.validationErrors)) {
              let { vendorId } = bill;

              if (isNonNullable(vendorId) && !isNewVendor(vendorId)) {
                vendorId = await createNewVendor(vendorId, vendorAccountIdentifier);

                if (vendorId) {
                  totalBill = { ...totalBill, vendorId };
                }
              }

              const vendor = await vendorsApi.getVendorById({
                orgId,
                id: vendorId!,
              });

              if (!isRecurring) {
                if (totalBill.id && Number(totalBill.id) !== -1) {
                  await this.onUpdateBill(totalBill, cb);
                } else {
                  await this.onCreateBill(totalBill, cb, shouldReturnToBillsDashboard);
                }
              } else {
                const {
                  payload: { blockPayments },
                } = await checkVendorPaymentPreferences({
                  orgId,
                  id: vendor.object.id,
                });
                this.setState({
                  isVendorBlockedForPayment: blockPayments,
                });

                if (!blockPayments) {
                  this.props.beginRecurringPayBillFlow(
                    {
                      totalAmount,
                      vendor: vendor ? vendor.object : bill.vendor,
                      vendorId: totalBill.vendorId,
                      id: '0',
                      dueDate: bill.dueDate,
                      note: bill.note ? bill.note : '',
                      invoiceNumber: bill.invoiceNumber,
                      intuitAccountId: bill.intuitAccountId || '',
                      organizationId: orgId,
                    } as BillType,
                    {
                      frequency,
                      occurrences,
                      dueDate: bill.dueDate,
                    } as RecurringBillType
                  );
                  analytics.track(`${eventPage}-recurring`, 'save-success');

                  if (eventPage === 'bill-create') {
                    analytics.trackMqlEvent('added-bill', 'mql');
                  }

                  this.props.navigate(billLocations.pay.recurring.funding);
                }
              }
            } else {
              cb(false);
              analytics.track(eventPage, 'save-validation-error', {
                ...validationErrors,
                vendorId: bill?.vendorId,
                vendorDirectoryType: directoryVendor?.directoryType,
              });
            }
          });
        };

        onUploadRemove = () => {
          this.props.handleUploadRemove();
          this.setState({
            bill: billFactory(),
            validationErrors: {},
          });
        };

        onChangeAttachment = async (file: File, loadBillFromAttachment = false) => {
          const { bill } = this.state;
          const { uploadAttachment, addNewVendor } = this.props;

          if (bill) {
            const uploadResult = await uploadAttachment(file, bill, loadBillFromAttachment);

            if (uploadResult) {
              const { extractedBill, extractedVendor } = uploadResult;

              if (extractedVendor) addNewVendor(extractedVendor);

              if (extractedBill) this.setState({ bill: extractedBill });
            }
          }
        };

        goExit = () => {
          const { locationState, orgId } = this.props;
          const { vendorId } = this.props.query;
          analytics.track(eventPage, 'exit');

          if (vendorId) {
            analytics.track(eventPage, 'exit-to-vendor');
            this.props.navigate(
              generatePath(vendorLocations.view, {
                orgId,
                id: vendorId,
                type: ContactsTab.VENDORS,
              })
            );
          } else {
            analytics.track(eventPage, 'exit-to-dashboard');
            this.props.navigate(locations.MainApp.dashboard.url(), false, locationState);
          }
        };

        goExitManually = () => {
          analytics.track(eventPage, 'exit-to-bill-create-options');
          const { site, device, orgId } = this.props;

          // TODO temporary changes
          if (site?.redirectToCreateManually && device?.isMobile) {
            const defaultFilters = getBillsDefaultFilters(BillStatus.SCHEDULED);

            if (isFirstQBOPage()) {
              melioClose();
            }

            this.props.navigate(generatePath(billLocations.filteredViewWithoutId, { orgId, ...defaultFilters }));
          } else {
            this.props.navigate(billLocations.create.options);
          }
        };

        render() {
          const {
            bill,
            validationErrors,
            vendorAccountIdentifier,
            occurrences,
            frequency,
            isRecurring,
            isVendorBlockedForPayment,
          } = this.state;
          const {
            filteredVendors,
            isUploading,
            files,
            isUploadError,
            fileName,
            handleUploadCancel,
            removeAttachment,
            handleRetry,
            onVendorsInputChange,
          } = this.props;

          return (
            <>
              <Component
                {...this.props}
                filteredVendors={filteredVendors}
                onVendorsInputChange={onVendorsInputChange}
                vendorAccountIdentifier={vendorAccountIdentifier}
                onChange={this.onChange}
                occurrences={occurrences}
                frequency={frequency}
                handleUploadCancel={handleUploadCancel}
                onChangeAttachment={this.onChangeAttachment}
                onDeleteAttachment={removeAttachment}
                bill={bill}
                editBill={bill}
                files={files}
                fileName={fileName}
                onFieldChange={this.onFieldChange}
                onCancelForm={this.onCancelForm}
                isRecurring={isRecurring}
                onLoadBill={this.onLoadBill}
                onUploadRemove={this.onUploadRemove}
                onSubmitBill={this.onSubmitBill}
                validationErrors={validationErrors}
                isUploading={isUploading}
                goExit={this.goExit}
                goExitManually={this.goExitManually}
                isUploadError={isUploadError}
                handleRetry={handleRetry}
                isVendorBlockedForPayment={isVendorBlockedForPayment}
              />
            </>
          );
        }
      }
    );
  };
}
