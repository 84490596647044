import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { IconSize } from 'src/core/ds/icon';
import FundingSourceIcon from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceIcon';
import {
  getPaymentMethodIcon,
  getShortPaymentMethodLabel,
} from 'src/pages/regular-batch-payments/components/menu/fundingSource/utils';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSource: AccountType;
};

export const FundingSourceCellLabel = ({ fundingSource }: Props) => {
  const { icon, imageSrc } = getPaymentMethodIcon(fundingSource);
  const { label, values, nickname } = getShortPaymentMethodLabel(fundingSource);

  return (
    <Container data-testid={`funding-source-cell-label-${fundingSource.id}`}>
      <FundingSourceIcon imageSrc={imageSrc} icon={icon} iconSize={IconSize.s} />
      <Label>{nickname || <MIFormattedText label={label} values={values} />}</Label>
    </Container>
  );
};

const Label = styled.div`
  padding-left: 0.8rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.hint};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 1.6rem);
`;
