import isEmpty from 'lodash/isEmpty';
import { BusinessDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { ZIP_CODE_REGEX } from 'src/pages/bill/pay/installments/utils';

export type ValidatableLegalBusinessAddressKeys = keyof Pick<
  BusinessDetailsModel,
  'addressLine1' | 'city' | 'state' | 'zipCode'
>;

export const useLegalBusinessAddressValidator = () => {
  const validate = (key: ValidatableLegalBusinessAddressKeys, modelState: BusinessDetailsModel) => {
    switch (key) {
      case 'addressLine1':
        if (isEmpty(modelState.addressLine1))
          return 'financing.businessDetails.fields.legalBusinessAddress.errors.empty';

        break;
      case 'city':
        if (isEmpty(modelState.city)) return 'financing.businessDetails.fields.city.errors.empty';

        break;
      case 'state':
        if (isEmpty(modelState.state)) return 'financing.businessDetails.fields.state.errors.empty';

        break;
      case 'zipCode':
        if (isEmpty(modelState.zipCode)) return 'financing.businessDetails.fields.zipCode.errors.empty';

        if (!ZIP_CODE_REGEX.test(modelState.zipCode)) return 'financing.businessDetails.fields.zipCode.errors.invalid';

        break;
      default:
        return undefined;
    }

    return undefined;
  };

  return {
    validator: validate,
  };
};
