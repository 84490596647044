import pick from 'lodash/pick';
import without from 'lodash/without';
import { ValueLabelPair } from 'src/pages/pay/components/filters/types';
import { useFilterMenu } from 'src/pages/pay/components/filters/useFilterMenu';
import { PayTabs } from 'src/utils/consts';
import { InboxStatusMenuKey } from 'src/utils/payListConsts';
import { Filters } from 'src/utils/types';
import {
  filterMenuKeys,
  inboxDueDateOptionsArray,
  inboxStatusMenuKeys,
  inboxStatusMenuOptionsMap,
  paidStatusOptionsArray,
  scheduledStatusOptionsArray,
} from './consts';

export const tabToItemStatusOptions = {
  [PayTabs.INBOX]: (options: {
    isAdminRole: ReturnType<typeof useFilterMenu>['isAdminRole'];
    isBillInboxEnabled?: boolean;
  }): ValueLabelPair[] => {
    let keys = options.isAdminRole
      ? inboxStatusMenuKeys
      : without(inboxStatusMenuKeys, InboxStatusMenuKey.PendingApproval);

    keys = options.isBillInboxEnabled ? keys : without(keys, InboxStatusMenuKey.PayInbox);

    return keys.map((key) => inboxStatusMenuOptionsMap[key]);
  },
  [PayTabs.SCHEDULED]: () => scheduledStatusOptionsArray,
  [PayTabs.PAID]: () => paidStatusOptionsArray,
};
export const tabToDueDateOptions = {
  [PayTabs.INBOX]: () => inboxDueDateOptionsArray,
  [PayTabs.SCHEDULED]: () => scheduledStatusOptionsArray,
  [PayTabs.PAID]: () => paidStatusOptionsArray,
};

export const isFilterMenuActive = (filters: Partial<Filters>) =>
  Object.values(pick(filters, filterMenuKeys)).some((activeFilters) => activeFilters.length);
