import { featureFlags } from '@melio/shared-web';
import * as dateFns from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import { ReactDatePicker } from 'react-datepicker';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { FormContainer } from 'src/ui/form/FormElements';
import { InlineDatePickerField } from 'src/ui/form/InlineDatePickerField';
import { FeatureFlags } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import dateFromModelValue from './modules/dateFromModelValue';
import { useCsvReport } from './modules/useCsvReport';

type Props = {
  dismiss: (event?: React.MouseEvent) => void;
  eventPage?: string;
};

export const DownloadCsvReportModal = ({ dismiss, eventPage }: Props) => {
  const initStartDate = useMemo(() => dateFns.startOfDay(new Date()), []);
  const [isIncludeInProgressEnabled] = featureFlags.useFeature(FeatureFlags.IncludeInProgress, false);

  const hiddenCsvLinkRef = useRef<CSVLink>();
  const formModelData = useMemo(
    () => ({
      startDate: dateFns
        .set(initStartDate, {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .toISOString(),
      endDate: dateFns
        .set(dateFns.endOfDay(new Date()), {
          hours: 0,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        })
        .toISOString(),
    }),
    [initStartDate]
  );

  // states
  const [startDateIsOpen, setStartDateIsOpen] = useState(false);
  const [endDateIsOpen, setEndDateIsOpen] = useState(false);
  const [lastChanged, setLastChanged] = useState(null);
  const [changed, setChanged] = useState(false);
  const [includeInProgress, setIncludeInProgress] = useState(false);

  // refs
  const startDateRef = useRef<ReactDatePicker & { setOpen: (open: boolean) => void }>(null);
  const endDateRef = useRef<ReactDatePicker & { setOpen: (open: boolean) => void }>(null);

  const { csv, clearCsv, loading, downloadAndProcess } = useCsvReport();

  const [{ endDate, startDate }, { submit }] = useForm(formModelData, {
    submit: async ({ endDate, startDate }) => {
      eventPage && analytics.track(eventPage, 'export-csv-modal-CTA-click');

      await downloadAndProcess({ startDate, endDate, includeInProgress });

      return true;
    },
    onChange: ({ key, modelState }) => {
      let newState = modelState;
      const newStart = new Date(modelState.startDate);
      const newEnd = new Date(modelState.endDate);
      const sameDatePermitted = true;
      const maxDateDuration = { years: 2 };

      if ((!sameDatePermitted && dateFns.isSameDay(newStart, newEnd)) || dateFns.isAfter(newStart, newEnd)) {
        newState = {
          ...newState,
          endDate: (sameDatePermitted ? newStart : dateFns.addDays(newStart, 1)).toISOString(),
        };
      } else if (dateFns.isBefore(dateFns.add(newStart, maxDateDuration), newEnd)) {
        // more than max, change the parameter which was not changed
        if (key === 'endDate') {
          newState = {
            ...newState,
            startDate: dateFns.sub(newEnd, maxDateDuration).toISOString(),
          };
        } else {
          newState = {
            ...newState,
            endDate: dateFns.add(newStart, maxDateDuration).toISOString(),
          };
        }
      }

      if (!lastChanged || lastChanged === key) {
        setEndDateIsOpen(key === 'startDate');
        setStartDateIsOpen(key !== 'startDate');
        setLastChanged(key);
        setChanged(!changed);
      } else if (key !== lastChanged) {
        setLastChanged(null);
      }

      return newState;
    },
  });

  const startDateVal = dateFromModelValue(startDate.value);
  const endDateVal = dateFromModelValue(endDate.value);
  const maxDateVal = includeInProgress ? {} : { max: initStartDate };

  const filename = `${dateFns.format(startDateVal, DateFormats.isoDate)}_${dateFns.format(
    endDateVal,
    DateFormats.isoDate
  )}.csv`;

  useEffect(() => {
    if (eventPage) {
      analytics.track(eventPage, 'export-csv-modal-expose');
    }
    // useEffect should be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset the csv data on date change
  useEffect(() => {
    clearCsv();
  }, [startDate.value, endDate.value, clearCsv]);

  // download the file immediately after the fetch is done
  useEffect(() => {
    let timeout;

    if (hiddenCsvLinkRef.current && csv) {
      // make sure react-csv has processed the data and ready
      timeout = setTimeout(() => {
        hiddenCsvLinkRef.current.link.click();
        dismiss();
      }, 0);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [csv, dismiss]);

  useEffect(() => {
    if (startDateRef.current && endDateRef.current) {
      startDateRef.current.setOpen(startDateIsOpen);
      endDateRef.current.setOpen(endDateIsOpen);
    }
  }, [changed, startDateIsOpen, endDateIsOpen]);

  return (
    <Box
      __css={{ '& > div': { overflowY: 'visible' } }}
      as={ModalMessage}
      isLoading={loading}
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText label="getPro.downloadCsvReport.modal.title" />
        </Box>
      }
      contentComponent={
        <FormContainer onSubmit={submit}>
          {isIncludeInProgressEnabled && (
            <Box mb={8}>
              <Checkbox
                isChecked={includeInProgress}
                data-testid="include-future-payments"
                label="getPro.downloadCsvReport.modal.form.includeInProgress"
                onChange={(event) => setIncludeInProgress(event.target.checked)}
              />
            </Box>
          )}
          <Flex direction={{ base: 'column', xl: 'row' }}>
            <Box w={{ base: 'full', xl: '50%' }} mb={{ base: 3, xl: 0 }} mr={{ base: 0, xl: 3 }}>
              <InlineDatePickerField
                ref={startDateRef}
                id="DownloadCsvReportForm-start-date"
                label="getPro.downloadCsvReport.modal.form.startDate"
                required
                model={startDate}
                isClearable={false}
                disabled={loading}
                {...maxDateVal}
              />
            </Box>
            <Box w={{ base: 'full', xl: '50%' }} ml={{ base: 0, xl: 3 }}>
              <InlineDatePickerField
                ref={endDateRef}
                id="DownloadCsvReportForm-end-date"
                label="getPro.downloadCsvReport.modal.form.endDate"
                required
                model={endDate}
                min={startDateVal}
                isClearable={false}
                disabled={loading}
                {...maxDateVal}
              />
            </Box>
          </Flex>
          <button type="submit" hidden />
        </FormContainer>
      }
      onCloseClick={dismiss}
      buttonComponent={
        <>
          <Box position="absolute" left="-999" visibility="hidden">
            <CSVLink ref={hiddenCsvLinkRef} data={csv || []} target="_blank" filename={filename}>
              download
            </CSVLink>
          </Box>
          <Button
            label="getPro.downloadCsvReport.modal.form.submitText"
            type="submit"
            variant={ButtonVariants.primary}
            onClick={submit}
            isLoading={loading}
            w="full"
            boxSizing="border-box"
          />
        </>
      }
    />
  );
};
