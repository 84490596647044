import findLastIndex from 'lodash/findLastIndex';
import { ProgressBar, StatusInfo } from 'src/components/payment-progress-bar/ProgressBar';
import { ProgressWarningTooltip } from 'src/components/payment-progress-bar/ProgressWarningTooltip';
import Box from 'src/core/ds/box';
import { DeliveryPreferenceType, DeliveryType } from 'src/utils/consts';
import moment from 'src/utils/moment-business-days';
import { isRefundPaymentFlow, isUndepositedOverdueCheck } from 'src/utils/payments';
import { getTranslationValue } from 'src/utils/translations';
import { PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
};

const getDeliveryTypeStatusByDate = (payment: PaymentType): StatusInfo[] => {
  const { deliveryType } = payment.deliveryMethod;
  const isRefund = isRefundPaymentFlow(payment);
  const paymentStatusDates = payment.metadata?.paymentStatusDates;
  const isUndepositedOverdueCheckPayment = isUndepositedOverdueCheck(payment);
  const isRTP = payment.deliveryPreference === DeliveryPreferenceType.RTP;

  if (isRefund) {
    return [
      {
        label: getTranslationValue('requests.tracking.title.initiated'),
        date: paymentStatusDates?.refundInitiated,
      },
      {
        label: getTranslationValue('requests.tracking.title.sent'),
        date: paymentStatusDates?.refundInitiated,
      },
      {
        label: getTranslationValue('requests.tracking.title.refunded'),
        date: paymentStatusDates?.refundCompleted,
        comment: 'bills.form.by8PM',
      },
    ];
  }

  switch (deliveryType) {
    case DeliveryType.CHECK:
      return [
        {
          label: getTranslationValue('requests.tracking.title.collected'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.sent'),
          date: paymentStatusDates?.inTransitDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.deposited'),
          date: paymentStatusDates?.depositedDate,
          icon: isUndepositedOverdueCheckPayment && (
            <ProgressWarningTooltip
              testId="undeposited-check-tooltip"
              tooltipLabel="bills.form.paymentActivity.tooltips.undepositedOverdueCheck"
            />
          ),
        },
      ];

    case DeliveryType.ACH:
      return [
        {
          label: getTranslationValue('requests.tracking.title.collected'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.inTransit'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.deposited'),
          date: paymentStatusDates?.depositedDate,
          comment: isRTP ? undefined : 'bills.form.by8PM',
        },
      ];

    case DeliveryType.RPPS:
    case DeliveryType.INTERNATIONAL:
      return [
        {
          label: getTranslationValue('requests.tracking.title.collected'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.inTransit'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.deposited'),
          date: paymentStatusDates?.inTransitDate
            ? moment(paymentStatusDates?.inTransitDate).nextBusinessDay().toString()
            : undefined,
        },
      ];

    case DeliveryType.PUSH_TO_DEBIT:
      return [
        {
          label: getTranslationValue('requests.tracking.title.collected'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.inTransit'),
          date: paymentStatusDates?.depositedDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.deposited'),
          date: paymentStatusDates?.depositedDate,
        },
      ];

    case DeliveryType.CARD:
      return [
        {
          label: getTranslationValue('requests.tracking.title.collected'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.inTransit'),
          date: paymentStatusDates?.inTransitDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.deposited'),
          date: paymentStatusDates?.depositedDate,
        },
      ];

    case DeliveryType.VIRTUAL_CARD:
      return [
        {
          label: getTranslationValue('requests.tracking.title.collected'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.sent'),
          date: paymentStatusDates?.inTransitDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.cleared'),
          date: paymentStatusDates?.depositedDate,
        },
      ];

    case DeliveryType.VIRTUAL:
      return [
        {
          label: getTranslationValue('requests.tracking.title.collected'),
          date: paymentStatusDates?.collectDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.inTransit'),
          date: paymentStatusDates?.inTransitDate,
        },
        {
          label: getTranslationValue('requests.tracking.title.deposited'),
          date: paymentStatusDates?.depositedDate,
        },
      ];
    default:
      return [];
  }
};

export function findLastStageWithDate(payment: PaymentType): number {
  const paymentProgressStatuses = getDeliveryTypeStatusByDate(payment);

  return findLastIndex(paymentProgressStatuses, 'date');
}

export const BillPaymentProgressBar = ({ payment }: Props) => (
  <Box>
    <ProgressBar paymentProcess={getDeliveryTypeStatusByDate(payment)} currentStage={findLastStageWithDate(payment)} />
  </Box>
);
