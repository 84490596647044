import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { intercomService } from 'src/services/intercom';
import { devices } from 'src/theme/appDevices';

type SupportLinkProps = {
  label?: string;
};

export const CardHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorNotificationCard = styled(MINotificationCard)`
  margin-top: 2rem;
  > div > div {
    font-weight: ${(props) => props.theme.text.weight.regular};
  }
`;

export const AccountingPlatformLogo = styled.img`
  margin: 0 0 1rem 0;
  width: 4rem;
  height: 4rem;
`;

export const ConnectErrorDescriptionWrapper = styled.div`
  ${(props) => props.theme.text.fontType.regular};
  color: ${(props) => props.theme.text.color.main};
  margin: 2.5rem 0 1rem 0;
`;

export const SupportLink = ({ label }: SupportLinkProps) => {
  const site = useSiteContext();

  return (
    <SupportLinkWrapper href={`mailto:${site.config.support.email}`}>
      {label ? <MIFormattedText label={label} /> : site.config.support.email}
    </SupportLinkWrapper>
  );
};

const SupportLinkWrapper = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

export const ChatLink = () => (
  <ChatLinkWrapper
    onClick={() => {
      intercomService.show();
    }}
  >
    <MIFormattedText label="settings.newAccountingSoftware.chat" />
  </ChatLinkWrapper>
);

const ChatLinkWrapper = styled.span`
  text-decoration: none;
  cursor: pointer;
  color: ${(props) => props.theme.colors.brand};
`;

export const InitialViewDescriptionWrapper = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  margin: 2rem 0;
  ${(props) => props.theme.text.fontType.regular};
`;

export const DescriptionWrapper = styled.div`
  color: ${(props) => props.theme.text.color.main};
  margin: 2rem 0;
  ${(props) => props.theme.text.fontType.regular};
`;

export const LastSyncedWrapper = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export const ButtonsContainer = styled.div`
  margin-top: 2rem;

  @media ${devices.mobile}, ${devices.phablet} {
    > button {
      width: 100%;
    }
  }
`;
