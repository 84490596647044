import { MILink } from 'src/components/common/MILink';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  errorCode?: string;
  onRequestPassword: () => void;
};

export const UserHasNoPasswordNotificationCard = ({ errorCode, onRequestPassword }: Props) => (
  <Box mb={10}>
    <MINotificationCard
      type={NotificationCardTypes.ERROR}
      subtitle={{
        label: `server.${errorCode}`,
        values: {
          continue: (
            <MILink testId="no-password-continue" to={onRequestPassword} label="server.NO_PASSWORD_CONTINUE" plain />
          ),
        },
      }}
    />
  </Box>
);
