import { SubmitRawBusinessRequestPayloadType } from 'src/utils/types';
import { fetchRequest, postRequest } from './api';

export const candidatesApi = {
  getCandidateByToken: (token: string) => {
    const url = '/candidates';

    return fetchRequest(url, { token });
  },
  submitRawBusiness: (token: string, params: SubmitRawBusinessRequestPayloadType) => {
    const url = `/candidates/submit?token=${token}`;

    return postRequest(url, params);
  },
};
