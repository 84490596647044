import { featureFlags } from '@melio/shared-web';
import noop from 'lodash/noop';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { AddBankAccountFromGetPaidOnboardingWrapper } from 'src/flows/add-bank-account-flow/wrappers/AddBankAccountFromGetPaidOnboardingWrapper';
import { AddBankAccountFromGetPaidSettingsWrapper } from 'src/flows/add-bank-account-flow/wrappers/AddBankAccountFromGetPaidSettingsWrapper';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { VendorDashboardDisplay } from 'src/modules/msn-portal';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getDeliveryMethods } from 'src/redux/user/selectors';
import { DeliveryMethodOrigin, DeliveryType, FeatureFlags } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { AddAchFromReceivingMethodFlowWrapper } from '../vendor/delivery-methods/add-ach-flow/wrappers/AddAchFromReceivingMethodFlowWrapper';
import ReceivingMethodAchExistsPage from './ach/ReceivingMethodAchExistsPage';
import { ReceivingMethodCantFindBankAccountPage } from './ach/ReceivingMethodCantFindBankAccountPage';
import { ReceivingMethodLinkBankAccountPage } from './ach/ReceivingMethodLinkBankAccountPage';
import { ReceivingMethodManualBankAccountPage } from './ach/ReceivingMethodManualBankAccountPage';
import { ReceivingMethodManualBankAccountViewPage } from './ach/ReceivingMethodManualBankAccountViewPage';
import { ReceivingMethodPlaidBankAccountPage } from './ach/ReceivingMethodPlaidBankAccountPage';
import ReceivingMethodSelectVerificationTypePage from './ach/ReceivingMethodSelectVerificationTypePage';
import { ReceivingMethodConfigProvider } from './context/ReceivingMethodContext';
import { useCreateReceivingMethodConfig } from './hooks/useCreateReceivingMethodConfig';
import { receivingMethodLocations } from './locations';
import { QBOReceivingMethodRedirectPage } from './QBOReceivingMethodRedirectPage';

export type LocationState = {
  exitUrl: string;
  redirectUrl: string;
  isPrevDisabled?: boolean;
  progressBarProps?: Record<string, any>;
  queryParams?: Record<string, any>;
  preservedState: Record<string, any>;
};

export const ReceivingMethodRouter = () => {
  const params = useParams<{ orgId: string; vendorId: string }>();
  const { state: locationState } = useLocation<LocationState>();
  const [redirectUrl] = useLocationState<string>('redirectUrl');
  const [exitUrl] = useLocationState<string>('exitUrl');
  const [isPrevDisabled] = useLocationState<boolean>('isPrevDisabled');
  const [isExistDisable] = useLocationState<boolean>('isExistDisable');
  const [progressBarProps] = useLocationState('progressBarProps', {});
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const orgId = params?.orgId ? Number(params?.orgId) : NaN;
  const vendorId = params?.vendorId ? Number(params?.vendorId) : NaN;
  const userDeliveryMethods = useSelector(getDeliveryMethods);
  const isACHDeliveryMethodExists = userDeliveryMethods.some((dm) => dm.deliveryType === DeliveryType.ACH);
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const receivingMethodConfig = useCreateReceivingMethodConfig(locationState?.preservedState?.origin);
  const organizationPreferences = useOrganizationPreferences();
  const isMSNOrganization = organizationPreferences?.vendorDashboardDisplay === VendorDashboardDisplay.MSNPortal;
  const isMSNClaimAccountFlow = locationState?.preservedState?.origin === DeliveryMethodOrigin.MSN_CLAIM_ACCOUNT;
  const isEditDeliveryMethodOriginFromSettings =
    locationState?.preservedState?.origin === DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS;
  const [isMsnUpdateDeliveryMethodFeatureFlagEnabled] = featureFlags.useFeature(
    FeatureFlags.MsnUpdateDeliveryMethod,
    false
  );
  const shouldEnableDMVerificationTypeSelection =
    !isACHDeliveryMethodExists ||
    isMSNOrganization ||
    (isEditDeliveryMethodOriginFromSettings && isMsnUpdateDeliveryMethodFeatureFlagEnabled);
  const onNext = (path: string) => () => {
    historyPush({
      path: generatePath(path, params),
      state: locationState,
    });
  };

  const onExit = () => (exitUrl ? historyPush({ path: exitUrl }) : noop);

  const onFinishFlow = redirectUrl ? onNext(redirectUrl) : noop;

  const onPrev = () => history.goBack();

  const onNextAddMethod = (deliveryMethodId: number) => {
    if (
      connectedAccountingPlatform?.isActive &&
      connectedAccountingPlatform?.accountReceivablesEnabled &&
      connectedAccountingPlatform?.paymentAccountReconciliationEnabled
    ) {
      onNext(
        generatePath(receivingMethodLocations.ach.linkBankAccount, {
          deliveryMethodId,
          ...params,
        })
      )();
    } else {
      onFinishFlow();
    }
  };

  const onNextPlaid = (deliveryMethodId?: string) => {
    deliveryMethodId
      ? onNext(
          generatePath(receivingMethodLocations.ach.plaid, {
            deliveryMethodId,
            ...params,
          })
        )()
      : onNext(receivingMethodLocations.ach.plaid)();
  };

  const onNextManual = (deliveryMethodId?: string) => {
    if (isMSNClaimAccountFlow) {
      onNext(generatePath(onboardingLocations.msnRegistration.claimAccount.verifyWithDeposits, { orgId }))();
    } else {
      deliveryMethodId
        ? onNext(
            generatePath(receivingMethodLocations.ach.edit, {
              deliveryMethodId,
              ...params,
            })
          )()
        : onNext(receivingMethodLocations.ach.manual)();
    }
  };

  const onExitAddPlaid = (deliveryMethodId?: string) => {
    if (isMSNClaimAccountFlow) {
      onNext(generatePath(receivingMethodLocations.ach.select, { deliveryMethodId, ...params }))();
    } else {
      deliveryMethodId
        ? onNext(
            generatePath(receivingMethodLocations.ach.cantFind, {
              deliveryMethodId,
              ...params,
            })
          )()
        : onNext(receivingMethodLocations.ach.cantFind)();
    }
  };

  const onNextCantFindPage = (deliveryMethodId?: string) => {
    deliveryMethodId
      ? onNext(
          generatePath(receivingMethodLocations.ach.edit, {
            deliveryMethodId,
            ...params,
          })
        )()
      : onNext(receivingMethodLocations.ach.manual)();
  };

  return (
    <ReceivingMethodConfigProvider value={receivingMethodConfig}>
      <Switch>
        <SmartRoute path={receivingMethodLocations.ach.select} exact>
          {!shouldEnableDMVerificationTypeSelection ? (
            <Redirect
              to={{
                pathname: generatePath(receivingMethodLocations.ach.exists, params),
                state: locationState,
              }}
            />
          ) : (
            <ReceivingMethodSelectVerificationTypePage
              onNextPlaid={onNextPlaid}
              onNextManual={onNextManual}
              onExit={isExistDisable ? undefined : onExit}
              onPrev={isPrevDisabled ? undefined : onPrev}
              progressBarProps={progressBarProps}
            />
          )}
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.addFromGetPaidOnboardingWrapper.index} exact>
          <AddBankAccountFromGetPaidOnboardingWrapper
            vendorId={vendorId}
            saveAndContinuePath={generatePath(redirectUrl ?? '', params)}
            shouldEnableDMVerificationTypeSelection={shouldEnableDMVerificationTypeSelection}
          />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.addFromGetPaidSettingsWrapper.index} exact>
          <AddBankAccountFromGetPaidSettingsWrapper
            vendorId={vendorId}
            saveAndContinuePath={generatePath(redirectUrl ?? '', params)}
            shouldEnableDMVerificationTypeSelection={shouldEnableDMVerificationTypeSelection}
          />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.redirect} exact>
          <QBOReceivingMethodRedirectPage />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.exists} exact>
          <ReceivingMethodAchExistsPage progressBarProps={progressBarProps} onNext={onFinishFlow} />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.manual} exact>
          <ReceivingMethodManualBankAccountPage
            vendorId={vendorId}
            onNext={onNextAddMethod}
            onExit={onExit}
            onPrev={onPrev}
            progressBarProps={progressBarProps}
          />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.edit} exact>
          <AddAchFromReceivingMethodFlowWrapper orgId={orgId} vendorId={vendorId} />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.plaid} exact>
          <ReceivingMethodPlaidBankAccountPage onNext={onNextAddMethod} onExit={onExitAddPlaid} vendorId={vendorId} />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.cantFind} exact>
          <ReceivingMethodCantFindBankAccountPage onNext={onNextCantFindPage} progressBarProps={progressBarProps} />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.linkBankAccount} exact>
          <ReceivingMethodLinkBankAccountPage
            onNext={onFinishFlow}
            onExit={onExit}
            progressBarProps={progressBarProps}
          />
        </SmartRoute>
        <SmartRoute path={receivingMethodLocations.ach.manualView} exact>
          <ReceivingMethodManualBankAccountViewPage onNext={onFinishFlow} onExit={onExit} />
        </SmartRoute>
      </Switch>
    </ReceivingMethodConfigProvider>
  );
};
