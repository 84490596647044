import { fetchRequest, putRequest } from 'src/services/api/api';

type ListParams = {
  orgId: number;
  type?: 'email' | 'sms';
};

export const notificationPreferencesApi = {
  list(params: ListParams) {
    const { orgId, ...paramsToSend } = params;
    const url = `/orgs/${orgId}/notification-preferences`;

    return fetchRequest(url, paramsToSend).then((res) => ({ items: res.notificationPreferences }));
  },

  update(params) {
    const { orgId, ...paramsToSend } = params;
    const url = `/orgs/${orgId}/notification-preferences`;

    return putRequest(url, paramsToSend).then((res) => res.notificationPreference);
  },
};
