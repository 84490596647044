import { Big } from 'big.js';
import first from 'lodash/first';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import trim from 'lodash/trim';
import values from 'lodash/values';
import { parse, stringify } from 'qs';
import { PayListItemType, PreferredSortByTabType } from 'src/modules/bills/types';
import { InvoiceType } from 'src/modules/invoices/types';
import { PAYMENT_PREFIX, PR_AS_BILL_ID_PREFIX, SCANNED_INVOICE_PREFIX } from 'src/modules/pay/consts';
import { billFactory } from 'src/pages/bill/records';
import { getPaymentRequestId } from 'src/pages/bill/utils/billGetters';
import { getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { PayItemPaymentType } from 'src/pay/types';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { stringifyQs } from 'src/utils/query-utils';
import { convertToDisplayAddress } from './address';
import {
  AccountingSoftware,
  BillStatus,
  CardTypes,
  DashboardTabs,
  DeliveryType,
  FailedPaymentMessage,
  FailedPaymentType,
  FundingType,
  InvoiceStatus,
  PAGINATION,
  PaymentApprovalStatus,
  PaymentDeliverStatus,
  PaymentStatus,
  RISK_BILL_AMOUNT,
  ScreenMode,
  TagVariant,
  TransactionDirections,
  TransactionStatuses,
  TransactionTypes,
} from './consts';
import { getCheckDepositedDate, getLatestPayment } from './payments';
import { PermissionsType } from './permissions';
import { getDefaultTabSetting } from './tabs-utils';
import {
  AccountingPlatform,
  AccountType,
  AddressType,
  BillPaymentType,
  BillType,
  DeliveryDataType,
  DeliveryMethodType,
  PaymentRequestType,
  PaymentType,
  TabSettingsType,
} from './types';

const serializePaymentId = (billId: string | number, paymentId: number | string) =>
  `${PAYMENT_PREFIX}-${billId}-${paymentId}`;

const deserializePaymentId = <T extends BillType | PayListItemType>(
  id: T['id']
): { billId: string; paymentId: string } => {
  if (!id.toString().includes(PAYMENT_PREFIX)) {
    return {
      billId: id,
      paymentId: '',
    };
  }

  const ids = id.toString().split('-');

  if (ids.length === 3) {
    return {
      billId: ids[1],
      paymentId: ids[2],
    };
  }

  return {
    billId: '',
    paymentId: '',
  };
};

function getBillTag<T extends BillType | PayListItemType, P extends PaymentType | PayItemPaymentType>(bill: T) {
  const latestPayment = getLatestPayment(bill.payments as P[]);

  return getBillPaymentTag(bill, latestPayment);
}

export const inProgressDeliveryStatuses = [
  PaymentDeliverStatus.COLLECTED,
  PaymentDeliverStatus.SENT,
  PaymentDeliverStatus.IN_TRANSIT,
  PaymentDeliverStatus.IN_PROGRESS,
  PaymentDeliverStatus.ACKNOWLEDGED,
];

function getBillPaymentTag<T extends BillType | PayListItemType, P extends PaymentType | PayItemPaymentType>(
  bill: T,
  payment?: P
): TagVariant | BillStatus | PaymentStatus | PaymentApprovalStatus | undefined {
  const paymentStatus = get(payment, 'status');
  const approvalStatus = get(payment, 'approvalDecisionStatus');
  const deliverStatus = get(payment, 'deliverStatus');
  const isBillPaid = bill.status === BillStatus.PAID;

  if (
    paymentStatus === PaymentStatus.COMPLETED ||
    paymentStatus === PaymentStatus.IN_PROGRESS ||
    payment?.metadata?.paymentStatusDates?.depositedDate ||
    payment?.manual === true
  ) {
    return BillStatus.PAID;
  }

  if (paymentStatus === PaymentStatus.FAILED) {
    if (approvalStatus === PaymentApprovalStatus.DECLINED) {
      return PaymentApprovalStatus.DECLINED;
    }

    return PaymentStatus.FAILED;
  }

  if (
    (deliverStatus && inProgressDeliveryStatuses.includes(deliverStatus)) ||
    paymentStatus === PaymentStatus.IN_PROGRESS ||
    paymentStatus === PaymentStatus.PROCESSED
  ) {
    return PaymentStatus.IN_PROGRESS;
  }

  if (approvalStatus === PaymentApprovalStatus.PENDING) {
    return PaymentApprovalStatus.PENDING;
  } else if (isBillPaid) {
    if (payment?.deliveryMethod?.deliveryType === DeliveryType.CHECK) {
      const checkDepositedDate = payment.transactions && getCheckDepositedDate(payment.transactions);

      return checkDepositedDate && TagVariant.DEPOSITED;
    } else if (payment?.metadata?.achDeposited) {
      return TagVariant.ACH_DEPOSITED;
    }
  }

  return bill.status;
}

const isPaymentRequest = (id: string | number): boolean => String(id)?.includes(PR_AS_BILL_ID_PREFIX);

const isScannedInvoice = (id: string | number): boolean => String(id)?.includes(SCANNED_INVOICE_PREFIX);

const serializeScannedInvoiceId = (scannedInvoiceId: string | number) =>
  `${SCANNED_INVOICE_PREFIX}-${scannedInvoiceId}`;

const deserializeScannedInvoiceId = (id: string): { scannedInvoiceId: string } => {
  if (!id.toString().includes(SCANNED_INVOICE_PREFIX)) {
    return {
      scannedInvoiceId: id,
    };
  }

  const ids = id.toString().split('-');

  if (ids.length === 2) {
    return {
      scannedInvoiceId: ids[1],
    };
  }

  return {
    scannedInvoiceId: '',
  };
};

const getPaymentRequestIdById = (id: string): string => {
  const [, requestId] = id.split('-');

  return requestId;
};

const isVirtualCardExpired = (payment: PaymentType) =>
  payment.status === PaymentStatus.FAILED &&
  payment.id &&
  payment.metadata?.failedType === FailedPaymentType.FAILED_TO_DELIVER &&
  payment.metadata?.failureMessage === FailedPaymentMessage.VIRTUAL_CARD_EXPIRED;

const getCardLabel = (type) =>
  type === CardTypes.CREDIT
    ? 'bills.form.paymentActivity.scheduledBill.scheduleMethodCreditCard'
    : 'bills.form.paymentActivity.scheduledBill.scheduleMethodDebitCard';

function getFundingSourceLabel(fundingSource: AccountType | null) {
  return get(fundingSource, 'fundingType') === FundingType.ACH
    ? 'bills.form.paymentActivity.scheduledBill.scheduleMethodAch'
    : getCardLabel(get(fundingSource, 'cardAccount.cardType', CardTypes.CREDIT));
}

const paymentMethodLabels = {
  [BillStatus.SCHEDULED]: {
    scheduleMethod: getFundingSourceLabel,
    deliveryMethod: {
      [DeliveryType.ACH]: 'bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod',
      [DeliveryType.CHECK]: 'bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod',
      [DeliveryType.VIRTUAL_CARD]: 'bills.form.paymentActivity.scheduledBill.deliveryMethodVirtualCard',
      [DeliveryType.CARD]: 'bills.form.paymentActivity.scheduledBill.deliveryMethodCreditCard',
    },
  },
  [BillStatus.UNPAID]: {
    scheduleMethod: getFundingSourceLabel,
    deliveryMethod: {
      [DeliveryType.ACH]: 'bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod',
      [DeliveryType.CHECK]: 'bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod',
      [DeliveryType.VIRTUAL_CARD]: 'bills.form.paymentActivity.scheduledBill.deliveryMethodVirtualCard',
      [DeliveryType.CARD]: 'bills.form.paymentActivity.scheduledBill.deliveryMethodCreditCard',
    },
  },
  [BillStatus.PAID]: {
    scheduleMethod: getFundingSourceLabel,
    deliveryMethod: {
      [DeliveryType.ACH]: 'bills.form.paymentActivity.paidBill.deliveryBankTransferMethod',
      [DeliveryType.CHECK]: 'bills.form.paymentActivity.paidBill.deliveryPaperCheckMethod',
      [DeliveryType.VIRTUAL_CARD]: 'bills.form.paymentActivity.paidBill.deliveryMethodVirtualCard',
      [DeliveryType.CARD]: 'bills.form.paymentActivity.paidBill.deliveryMethodCreditCard',
    },
  },
};

export function getFormattedCheckSerial(payment: PaymentType) {
  const checkTransactions = (payment?.transactions || []).filter(
    (transaction) =>
      transaction.transactionType === TransactionTypes.CHECK &&
      transaction.transactionDirection === TransactionDirections.CREDIT &&
      transaction.status === TransactionStatuses.COMPLETED
  );

  if (checkTransactions.length === 0) {
    return '';
  }

  // Assuming there is 1 or more check credit transaction per payment, showing the last
  const rawTransactionData = checkTransactions[checkTransactions.length - 1].rawData || {};

  if (!rawTransactionData.checkSerialNumber) {
    return '';
  }

  return ` #${rawTransactionData.checkSerialNumber}`;
}

function getAdditionalDescription(isRecurring, isRequest, deliveryType) {
  if (isRecurring || deliveryType !== DeliveryType.ACH) {
    return '';
  } else if (isRequest) {
    return 'bills.form.paymentActivity.deliveryMethodAdditionalDescriptionPayee';
  }

  return 'bills.form.paymentActivity.deliveryMethodAdditionalDescription';
}

const mapDeliveryTypeToIcon = {
  [DeliveryType.ACH]: 'bank',
  [DeliveryType.CHECK]: 'paperCheck',
  [DeliveryType.VIRTUAL_CARD]: 'creditCard',
  [DeliveryType.CARD]: 'creditCard',
};

function getPaymentActivityDeliveryData(
  payment: PaymentType,
  billStatus: BillStatus | undefined,
  isRecurring: boolean,
  isFirstPaymentLate: boolean,
  vendorName: string,
  deliveryEta: string,
  deliveryMethod: DeliveryMethodType | null,
  isEditable = false,
  onEdit?: () => void,
  onEditDate?: () => void,
  isRequest?: boolean | null,
  invoiceStatus?: InvoiceStatus,
  isVendorView?: boolean
): DeliveryDataType {
  const billPaymentMethodLabels = paymentMethodLabels[billStatus || BillStatus.SCHEDULED];
  const deliveryType = get(deliveryMethod, 'deliveryType');

  let method =
    billPaymentMethodLabels && deliveryMethod && deliveryType
      ? billPaymentMethodLabels.deliveryMethod[deliveryType]
      : '';
  const info = deliveryMethod
    ? getDeliveryMethodDisplay({ deliveryMethod, vendorName, isVendorView: Boolean(isVendorView) })
    : '';
  const icon = deliveryType && mapDeliveryTypeToIcon[deliveryType];
  let title = '';
  let description =
    billStatus === BillStatus.PAID
      ? 'bills.form.paymentActivity.deliveryTitleAt'
      : 'bills.form.paymentActivity.deliveryTitleETA';
  let date = deliveryEta;
  let maxDate = payment.maxDeliveryEta;
  let additionalHint = {};

  if (deliveryType === DeliveryType.VIRTUAL) {
    description = 'bills.form.paymentActivity.deliveryVirtualDescription';
    additionalHint = {
      label: isRecurring
        ? 'bills.form.paymentActivity.deliveryVirtualRecurringAdditionalHint'
        : 'bills.form.paymentActivity.deliveryVirtualAdditionalHint',
    };
  } else if (isRecurring) {
    description = 'requests.form.paymentActivity.paymentETA';

    if (isFirstPaymentLate) {
      additionalHint = { label: 'settings.paymentMethods.recurringHint' };
    }
  }

  if (invoiceStatus === InvoiceStatus.CANCELLED_BY_CUSTOMER || invoiceStatus === InvoiceStatus.FAILED) {
    title = 'requests.form.paymentActivity.failedDeliveryTo';
  } else if (isRequest) {
    const isRequestPaymentPaid = billStatus === BillStatus.PAID;
    title = isRequestPaymentPaid
      ? 'requests.form.paymentActivity.vendorReceived'
      : 'requests.form.paymentActivity.vendorReceive';
    description = isRequestPaymentPaid
      ? 'requests.form.paymentActivity.deliveredOn'
      : 'bills.form.paymentActivity.deliveryTitleETA';
    method = isRequestPaymentPaid ? 'requests.form.paymentActivity.deliveryBankTransferMethod' : method;

    if (isRequestPaymentPaid) {
      date = payment.paidDate ?? date;
      maxDate = null;
    }
  } else {
    title =
      billStatus === BillStatus.PAID
        ? 'bills.form.paymentActivity.vendorReceived'
        : 'bills.form.paymentActivity.vendorReceive';
  }

  return {
    title,
    date,
    maxDate,
    method,
    info,
    icon,
    dateIcon: 'icon-eta-cal',
    description,
    additionalDescription: getAdditionalDescription(isRecurring, isRequest, deliveryType),
    deliveryPreference: payment.deliveryPreference,
    isEditable,
    onEdit,
    onEditDate,
    formattedCheckSerial: getFormattedCheckSerial(payment),
    origin: null,
    type: deliveryMethod && deliveryMethod.deliveryType,
    paperCheck: deliveryMethod && deliveryMethod.paperCheck,
    additionalHint,
  };
}

function isManuallyPaid(billStatus: BillStatus, hasManualPayment: boolean) {
  return billStatus === BillStatus.PAID && hasManualPayment;
}

const getBillsDefaultFilters = (status: BillStatus) => {
  const statusFilterParamsMap = {
    [BillStatus.UNPAID]: {
      status: BillStatus.UNPAID,
      sorting: 'payment.status,dueDate',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [BillStatus.PARTIALLY_PAID]: {
      status: BillStatus.UNPAID,
      sorting: 'payment.status,dueDate',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [BillStatus.SCHEDULED]: {
      status: BillStatus.SCHEDULED,
      sorting: 'payment.scheduledDate',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [BillStatus.PAID]: {
      status: BillStatus.PAID,
      sorting: 'payment.scheduledDate:desc',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
  };

  return statusFilterParamsMap[status];
};

type BillSearchPathParams = {
  status: BillStatus;
  baseSearch?: string;
  excludeFields?: string[];
  start?: number;
  limit?: number;
  sorting?: string;
};

const getBillsSearchPath = ({
  status,
  baseSearch = '',
  excludeFields = [],
  start = PAGINATION.DEFAULT_START,
  limit = PAGINATION.DEFAULT_LIMIT,
  sorting,
}: BillSearchPathParams) => {
  const queryString = stringifyQs(
    omit(
      {
        ...getBillsDefaultFilters(status),
        ...(sorting ? { sorting } : {}),
        start,
        limit,
      },
      excludeFields
    )
  );

  return baseSearch ? stringify({ ...parse(baseSearch), ...parse(queryString) }) : queryString;
};

const getVendorBillSearchTab = (tab: BillStatus, vendorId: number) =>
  stringifyQs({ vendorId, ...getBillsDefaultFilters(tab) });

const getBillsTabs = (
  statusesList: BillStatus[],
  selectedTabBillStatus: BillStatus,
  type: string,
  baseSearch = '',
  preferredSort: PreferredSortByTabType = {}
): TabSettingsType[] =>
  statusesList.map((status: BillStatus) => {
    const defaultTabSetting = getDefaultTabSetting({
      tab: status,
      type,
      isActive: status === selectedTabBillStatus,
      baseQueryParams: baseSearch,
    });
    const sorting: string = preferredSort[status];
    const billsListSearchPath = getBillsSearchPath({
      status,
      baseSearch,
      sorting,
    });

    return {
      ...defaultTabSetting,
      to: {
        search: billsListSearchPath,
      },
    };
  });

const getDisplayAddress = (bill: BillType): string | null => {
  const deliveryMethods = get(bill, 'vendor.deliveryMethods');

  if (!deliveryMethods) {
    return null;
  }

  const checkDeliveryMethod = first<DeliveryMethodType>(deliveryMethods.filter((dm) => dm.deliveryType === 'check'));

  if (!checkDeliveryMethod || !checkDeliveryMethod.paperCheck) {
    return null;
  }

  return convertToDisplayAddress(checkDeliveryMethod.paperCheck as AddressType);
};

const productReceivedOptions = [
  {
    id: '1',
    label: 'categoryList.yes',
  },
  {
    id: '0',
    label: 'bills.form.receivedProduct.no',
  },
];

const isBillAmountRequiresInvoiceFile = (amount: string) => Number(amount) >= RISK_BILL_AMOUNT.REQUIRES_INVOICE_FILE;
const isBillAmountRequiresGoodsConfirmation = (amount: string, isRiskBillAmount10k?: boolean) =>
  Number(amount) >=
  (isRiskBillAmount10k
    ? RISK_BILL_AMOUNT.REQUIRES_GOODS_CONFIRMATION_10K
    : RISK_BILL_AMOUNT.REQUIRES_GOODS_CONFIRMATION);

const getDefaultMemo = (invoiceNumber: string | null, intuitAcctNum: string) => {
  const isEmptyAcctNumber = isEmpty(trim(intuitAcctNum));

  if (invoiceNumber && !isEmptyAcctNumber) {
    return `Acct #${intuitAcctNum} | Inv #${invoiceNumber}`;
  } else if (!invoiceNumber && !isEmptyAcctNumber) {
    return `Acct #${intuitAcctNum}`;
  } else if (invoiceNumber && isEmptyAcctNumber) {
    return `Inv #${invoiceNumber}`;
  }

  return '';
};

const getDefaultMemoFromBills = (bills: BillType[], intuitAcctNum = '') => {
  const isEmptyAcctNumber = isEmpty(trim(intuitAcctNum));
  const texts: string[] = [];

  if (!isEmptyAcctNumber) {
    texts.push(`Acct #${intuitAcctNum}`);
  }

  if (bills.length > 1) texts.push('Combined payment');
  else if (bills?.[0]?.invoiceNumber) texts.push(`Inv #${bills[0].invoiceNumber}`);

  return isEmpty(texts) ? '' : texts.join(' | ');
};

const isBillUnpaid = (bill: BillType) =>
  bill.status === BillStatus.UNPAID || (!isNil(bill.balance) && bill.balance > 0);

const showMarkAsPaidBills = (bill: BillType, user: any, permissions: PermissionsType) => {
  const hasManualPayment = Boolean(bill.payments?.some((p) => p.manual));
  const manuallyPaid = isManuallyPaid(bill.status, hasManualPayment);

  return (
    ((isBillUnpaid(bill) && !isPaymentRequest(bill.id)) || manuallyPaid) && permissions.bills.markAsPaid(bill, user)
  );
};

const showMarkAsPaidPartialPayment = (bill: BillType, user: any, permissions: PermissionsType, isInboxTab: boolean) =>
  isInboxTab && !isPaymentRequest(bill.id) && permissions.bills.markAsPaid(bill, user);

const isBillHasPartialPaymentsByBillPayments = <T extends BillType | PayListItemType>(
  bill: T,
  billPayments: BillPaymentType[]
): boolean => {
  if (isNil(bill)) {
    return false;
  }

  const billAmount = convertCurrencyToNumber(bill.totalAmount);
  const isEveryPaymentCoversPartialAmount =
    billPayments &&
    !isEmpty(billPayments) &&
    billPayments.every((billPayment) => billPayment && billPayment?.amount && billPayment.amount < billAmount);
  const isExternallyPaidCoversPartialAmount =
    !isNil(bill.externallyPaid) &&
    !Number.isNaN(bill.externallyPaid) &&
    bill.externallyPaid !== 0 &&
    bill.externallyPaid !== bill.totalAmount;

  return isEveryPaymentCoversPartialAmount || isExternallyPaidCoversPartialAmount;
};

const isBillHasPartialPayments = <T extends BillType | PayListItemType>(bill: T): boolean => {
  const billPayments = bill?.payments?.map((p) => p.billPayment) || [];

  return isBillHasPartialPaymentsByBillPayments(bill, billPayments);
};

export const getBillItemAmount = <T extends BillType | PayListItemType, P extends PaymentType | PayItemPaymentType>({
  bill,
  payment,
  isBillObject,
  isInboxBillPartiallyPaid,
}: {
  bill: T;
  payment: P | undefined;
  isBillObject: boolean;
  isInboxBillPartiallyPaid: boolean;
}) => {
  if (!isBillObject && payment) {
    return payment.amount;
  }

  return isInboxBillPartiallyPaid ? bill.balance : bill.totalAmount;
};

const canPartiallyPayBill = (bill: BillType, isRecurring: boolean, paymentExist: boolean): boolean =>
  !paymentExist &&
  !bill.internalBill &&
  !bill.recurringBillId &&
  !bill.paymentRequestId &&
  !isRecurring &&
  !bill.isPaymentRequest &&
  !bill.paymentRequest;

const convertPaymentRequestToBill = ({
  id,
  createdAt,
  totalAmount,
  invoiceNumber,
  dueDate,
  customerNote,
  organizationId,
  vendor,
  companyName,
  vendorId,
  files,
}: PaymentRequestType & { companyName: string }): BillType =>
  billFactory({
    id: `${PR_AS_BILL_ID_PREFIX}-${id}`,
    status: BillStatus.UNPAID,
    createdAt,
    currency: 'USD',
    totalAmount,
    invoiceNumber,
    dueDate,
    balance: Number(totalAmount),
    origin: 'local',
    originId: '-1',
    organizationId,
    organization: {
      id: organizationId,
      companyName,
    },
    paymentRequest: { id },
    vendor: {
      companyName: '',
      ...vendor,
      id: vendorId,
    },
    note: customerNote,
    vendorId,
    isPaymentRequest: true,
    files,
  });

const convertPartialPaymentStatusToBillStatus = (paymentStatus: string) => {
  switch (paymentStatus) {
    case PaymentStatus.COMPLETED:
    case PaymentStatus.IN_PROGRESS:
      return BillStatus.PAID;
    case PaymentStatus.SCHEDULED:
    case PaymentStatus.BLOCKED:
      return BillStatus.SCHEDULED;
    default:
      return BillStatus.UNPAID;
  }
};

const convertTabNameToBillStatus = (tabName: DashboardTabs) => {
  switch (tabName) {
    case DashboardTabs.Paid:
      return BillStatus.PAID;
    case DashboardTabs.Scheduled:
      return BillStatus.SCHEDULED;
    default:
      return BillStatus.UNPAID;
  }
};

const getActionOptions = ({
  bill,
  payment,
  mode,
  permissions,
  currentUser,
  isUnpaidInboxBill,
  actions,
  connectedAccountingPlatform,
}: {
  bill: BillType;
  payment?: PaymentType;
  mode: string;
  permissions: PermissionsType;
  currentUser: any;
  isUnpaidInboxBill: boolean;
  actions: {
    onMarkBillAsPaid: () => void;
    onMarkPaymentAsUnpaid: () => void;
    onToggleMode: () => void;
    onDeleteClicked: () => void;
  };
  connectedAccountingPlatform?: AccountingPlatform;
}) => {
  const opts: {
    label: string;
    action: () => void;
    mobile?: boolean;
    disabled?: boolean;
    negative?: boolean;
  }[] = [];

  const editDisabled =
    bill.paymentRequest !== null ||
    (connectedAccountingPlatform?.name === AccountingSoftware.XERO && bill.totalAmount !== bill.balance);
  const paymentsBlockedForDeletion = bill?.payments?.some(
    (p) => p.status === PaymentStatus.COMPLETED || p.status === PaymentStatus.IN_PROGRESS
  );
  const validBalance = bill?.payments?.every((p) => !p.balance);
  const isBillDeletionAvailable = !bill.internalBill && isEmpty(paymentsBlockedForDeletion) && validBalance;

  if (!bill.isPaymentRequest) {
    if (payment?.status === PaymentStatus.COMPLETED && payment?.manual) {
      opts.push({
        label: 'bills.actions.undoMarkAsPaidMobile',
        action: actions.onMarkPaymentAsUnpaid,
      });
    }

    if (isUnpaidInboxBill) {
      if (!isPaymentRequest(bill.id) && permissions.bills.markAsPaid(currentUser, bill)) {
        opts.push({
          label: 'bills.actions.markAsPaid',
          action: actions.onMarkBillAsPaid,
        });
      }

      if (mode === ScreenMode.VIEW && permissions.bills.update(currentUser, bill)) {
        opts.push({
          label: 'bills.actions.edit',
          action: actions.onToggleMode,
          disabled: editDisabled,
        });
      }

      if (isBillDeletionAvailable && permissions.bills.delete(currentUser, bill)) {
        opts.push({
          label: 'bills.actions.delete',
          action: actions.onDeleteClicked,
          negative: true,
        });
      }
    }
  }

  return opts;
};

function groupPartialPaymentsByBill<T extends BillType | InvoiceType>(bills: T[]): T[][] {
  return values(groupBy(bills, (bill) => deserializePaymentId(bill.id)?.billId));
}

const getBillBalance = (bill?: BillType, excludePaymentIds: number[] = []) => {
  if (!bill || isNil(bill.totalAmount)) {
    return 0;
  }

  const paymentsAmount = bill.payments
    .filter((payment) => !excludePaymentIds.includes(payment.id))
    .reduce((sum, payment) => sum.add(payment.billPayment.amount), new Big(0));
  const balance = new Big(bill.totalAmount).minus(bill.externallyPaid || 0).minus(paymentsAmount);

  return balance.toNumber();
};

const getBillPaymentIndex = <T extends BillType | PayListItemType>(bill: T) =>
  `${bill?.id || 0}-${bill?.payments?.length || 0}`;

const hasBillPayments = (bill?: BillType) => bill?.payments?.length && bill.payments.length > 0;

const getVendorCompanyName = (bill: BillType) => get(bill, 'vendor.companyName') as string;

export {
  canPartiallyPayBill,
  convertPartialPaymentStatusToBillStatus,
  convertPaymentRequestToBill,
  convertTabNameToBillStatus,
  deserializePaymentId,
  deserializeScannedInvoiceId,
  getActionOptions,
  getBillBalance,
  getBillPaymentIndex,
  getBillPaymentTag,
  getBillsDefaultFilters,
  getBillsSearchPath,
  getBillsTabs,
  getBillTag,
  getDefaultMemo,
  getDefaultMemoFromBills,
  getDisplayAddress,
  getFundingSourceLabel,
  getPaymentActivityDeliveryData,
  getPaymentRequestId,
  getPaymentRequestIdById,
  getVendorBillSearchTab,
  getVendorCompanyName,
  groupPartialPaymentsByBill,
  hasBillPayments,
  isBillAmountRequiresGoodsConfirmation,
  isBillAmountRequiresInvoiceFile,
  isBillHasPartialPayments,
  isBillHasPartialPaymentsByBillPayments,
  isBillUnpaid,
  isManuallyPaid,
  isPaymentRequest,
  isScannedInvoice,
  isVirtualCardExpired,
  productReceivedOptions,
  serializePaymentId,
  serializeScannedInvoiceId,
  showMarkAsPaidBills,
  showMarkAsPaidPartialPayment,
};
