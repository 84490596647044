import { useEffect } from 'react';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import SuccessImage from 'src/images/core/success-icon.svg';
import CalendarImage from 'src/images/icons/calendar-icon.svg';
import Mastercard from 'src/images/icons/mastercard.svg';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { eventPage } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardView';
import { analytics } from 'src/services/analytics';
import { getVirtualCardProcessedDate } from 'src/utils/vendors';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardClearedView = ({ state, analyticsProperties }: Props) => {
  const { virtualCard, payment } = state;
  useEffect(() => {
    analytics.track(eventPage, 'cleared', analyticsProperties);
  }, [analyticsProperties]);

  const processedDate = virtualCard && getVirtualCardProcessedDate(virtualCard);

  return (
    <ContentWrapper>
      <Image width={8} height={8} src={SuccessImage} />
      <Box textStyle="body2Semi" mt={4} mb={2} data-testid="title">
        <MIFormattedText label="vendors.virtualCard.cleared.title" />
      </Box>
      <Box textStyle="body2">
        <MIFormattedText
          label="vendors.virtualCard.cleared.description"
          values={{
            date: <MIFormattedDate date={processedDate} />,
          }}
        />
      </Box>
      <Box textStyle="body4Uppercase" color="grey.700" mt={5} mb={4}>
        <MIFormattedText label="vendors.virtualCard.cleared.youReceived" />
      </Box>
      <Flex align="center" borderBottom="1px" borderColor="grey.400" pb={5}>
        <Image width={6} height={5} src={Mastercard} />
        <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
          <Box textStyle="body4Semi" color="grey.600">
            <MIFormattedText
              label="vendors.virtualCard.cleared.singleUse"
              values={{ card4digits: virtualCard?.accountNumber4digits }}
            />
          </Box>
          <Box textStyle="body2" isTruncated data-testid="account-email">
            {payment?.deliveryMethod?.virtualCardAccount?.accountEmail}
          </Box>
        </Box>
      </Flex>
      <Flex align="center" pt={5}>
        <Image width={6} height={6} src={CalendarImage} />
        <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
          <Box textStyle="body4Semi" color="grey.600">
            <MIFormattedText label="vendors.virtualCard.cleared.processedOn" />
          </Box>
          <Box textStyle="body2" isTruncated data-testid="processed-date">
            <MIFormattedDate date={processedDate} />
          </Box>
        </Box>
      </Flex>
    </ContentWrapper>
  );
};
