import Box from 'src/core/ds/box';
import { Collapse } from 'src/core/ds/transition';
import { InstallmentsListCollapsed } from 'src/pages/bill/components/InstallmentsList/InstallmentsListCollapsed';
import { InstallmentsListExpanded } from 'src/pages/bill/components/InstallmentsList/installmentsListExpanded/InstallmentsListExpanded';
import { PaymentFinancing } from 'src/utils/types';

type Props = {
  financing: PaymentFinancing;
  isCollapsed: boolean;
  onToggle(): void;
  viewDetailsLink?: string;
  showBadges: boolean;
};

export const InstallmentsList = ({ isCollapsed, onToggle, ...rest }: Props) => (
  <Box data-testid="installments-list-container">
    <Collapse unmountOnExit in={isCollapsed}>
      <InstallmentsListCollapsed onToggle={onToggle} />
    </Collapse>
    <Collapse animateOpacity unmountOnExit in={!isCollapsed}>
      <InstallmentsListExpanded onToggle={onToggle} {...rest} />
    </Collapse>
  </Box>
);
