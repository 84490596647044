import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { MINotices } from 'src/components/common/MINotices';
import Upload10KInvoiceFileLayout from 'src/components/layout/Upload10KInvoiceFileLayout';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { VStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import { BillPageType } from 'src/utils/consts';

const InvoiceAttachmentLabel = ({ errorMessage }: { errorMessage?: string }) => (
  <MIInputLabel
    label="vendors.deliveryMethods.international.paymentPurpose.invoiceAttachmentLabel"
    required
    errorMessage={errorMessage}
    afterLabelComponent={
      <Tooltip
        shouldWrapChildren
        data-testid="invoice-attachment-after-label-component"
        label={
          <MIFormattedText label="vendors.deliveryMethods.international.paymentPurpose.invoiceAttachmentTooltip" />
        }
        placement="top"
      >
        <Box display="inline-block" position="absolute" ml={2}>
          <Icon name={IconNames.infoCircle} size={IconSize.s} />
        </Box>
      </Tooltip>
    }
  />
);

export const InvoiceAttachmentField = (props) => {
  const {
    onSelectFile,
    onRetry,
    isFileUploading,
    isFileUploadError,
    onDeleteInvoiceFile,
    fileName,
    files,
    model,
    testId,
  } = props;

  useEffect(() => {
    model.onChange({
      id: model.id,
      value: Boolean(files.length),
    });
  }, [files.length]);

  return (
    <VStack alignItems="flex-start">
      <InvoiceAttachmentLabel errorMessage={model?.error} />
      <Upload10KInvoiceFileLayout
        onSelectFile={onSelectFile}
        onRetry={onRetry}
        fileName={fileName}
        testId={testId}
        isFileUploading={isFileUploading}
        isFileUploadError={isFileUploadError}
        onChangeInvoiceFile={onSelectFile}
        onDeleteInvoiceFile={onDeleteInvoiceFile}
        pageType={BillPageType.CREATE}
        fullWidth
      />
      <Box>
        <MINotices errorMessage={model?.error} size="wizard" testId="input-invoiceAttachment-notices" />
      </Box>
    </VStack>
  );
};
