import { Row } from 'react-table';
import { useBoolean } from 'src/core/ds/hooks';
import Table from 'src/core/ds/table';
import { DATA_TABLE_RENDER } from 'src/pages/regular-batch-payments/table/consts';

export function DataTableRow<T extends Record<string, unknown>>({
  row,
  onClickRow,
}: {
  row: Row<T>;
  onClickRow: (row: Row<T>) => void;
}) {
  const [hovered, setHovered] = useBoolean();

  const onClick = () => {
    onClickRow(row);
  };

  return (
    <Table.Row
      data-testid={`batch-payments-table-row-${row.id}`}
      data-is-selected={row.isSelected}
      onMouseEnter={setHovered.on}
      onMouseLeave={setHovered.off}
      onBlur={setHovered.off}
      onClick={onClick}
      {...row.getRowProps()}
      className={row.depth === 1 ? 'isChildRow' : ''}
    >
      {row.cells.map((cell) => (
        <Table.Cell
          {...cell.getCellProps()}
          isNumeric={cell.column.isNumeric}
          style={{
            ...cell.getCellProps().style,
            minWidth: cell.column.minWidth,
            maxWidth: cell.column.maxWidth,
            flex: cell.column.minWidth,
          }}
        >
          {cell.render(DATA_TABLE_RENDER.Cell, {
            rowHovered: hovered,
          })}
        </Table.Cell>
      ))}
    </Table.Row>
  );
}
