import { EventMappingsTree } from 'src/services/analytics/types';

export const msnOnboardingEvent = 'msn-onboarding';

const optinInfo = { msnOptinFlow: 'msnOptinFlow', flows: 'flows' };
const msnOnboardingUserInfo = { ...optinInfo, isGuest: 'isGuest' };

export const msnPublicFormOnboardingEventMapping: EventMappingsTree = {
  '/msn-portal/register': {
    'page.view': [msnOnboardingEvent, 'business-email'],
    'auth.msnPortalRegistration.continue': [msnOnboardingEvent, 'business-email-continue', optinInfo],
    'msnPortalRegister.signup.validation.error': [
      msnOnboardingEvent,
      'business-email-signup-validation-error',
      optinInfo,
    ],
  },
  '/msn-portal/email-verification': {
    'page.view': [msnOnboardingEvent, 'code-verification'],
    'msnPortalEmailVerification.back': [msnOnboardingEvent, 'code-verification-back', optinInfo],
    'msnPortalEmailVerification.email.change': [msnOnboardingEvent, 'code-verification-change-email', optinInfo],
    'msnPortalEmailVerification.code.correct': [
      msnOnboardingEvent,
      'code-verification-correct-code',
      msnOnboardingUserInfo,
    ],
    'msnPortalEmailVerification.code.incorrect': [msnOnboardingEvent, 'code-verification-incorrect-code', optinInfo],
    'msnPortalEmailVerification.code.resend': [msnOnboardingEvent, 'code-verification-resend-code', optinInfo],
    'msnPortalEmailVerification.continue.success': [
      msnOnboardingEvent,
      'code-verification-continue-success',
      msnOnboardingUserInfo,
    ],
  },
  '/orgs/*/msn-portal/business/contact-details': {
    'page.view': [msnOnboardingEvent, 'contact-details'],
    'progress.continue': [msnOnboardingEvent, 'contact-details-continue', msnOnboardingUserInfo],
    'msnPortalContactDetails.error': [
      msnOnboardingEvent,
      'contact-details-error',
      {
        ...msnOnboardingUserInfo,
        errorReason: 'errorReason',
        validationErrors: 'validationErrors',
      },
    ],
  },
  '/orgs/*/msn-portal/business/company-details': {
    'page.view': [msnOnboardingEvent, 'business-details'],
    'progress.continue': [msnOnboardingEvent, 'business-details-continue', msnOnboardingUserInfo],
    'msnPortalCompanyDetails.error': [
      msnOnboardingEvent,
      'business-details-error',
      {
        ...msnOnboardingUserInfo,
        errorReason: 'errorReason',
        validationErrors: 'validationErrors',
      },
    ],
  },
  '/orgs/*/msn-portal/business/verify-bank-account': {
    'page.view': [msnOnboardingEvent, 'verified-bank-account'],
    'progress.continue': [msnOnboardingEvent, 'verify-bank-account-continue', msnOnboardingUserInfo],
    'msnPortalVerifyBankAccount.error': [
      msnOnboardingEvent,
      'verify-bank-account-error',
      { ...msnOnboardingUserInfo, errorReason: 'create-micro-deposit-failed' },
    ],
  },
  '/orgs/*/msn-portal/business/delivery-method-status': {
    'page.view': [msnOnboardingEvent, 'delivery-method-status'],
    'onboarding.msnCompanyInfo.deliveryMethodStatus.continueButton': [
      msnOnboardingEvent,
      'delivery-method-status-continue',
      { ...msnOnboardingUserInfo, msnVerified: 'msnVerified', dmVerified: 'dmVerified' },
    ],
    'msnPortalDeliveryMethodStatus.learnHowToVerifyMicroDeposits': [
      msnOnboardingEvent,
      'delivery-method-status-learn-how-to-verify-micro-deposits',
      { ...msnOnboardingUserInfo, msnVerified: 'msnVerified', dmVerified: 'dmVerified' },
    ],
  },
  '/orgs/*/msn-portal/business/verified': {
    'page.view': [msnOnboardingEvent, 'business-already-verified'],
  },
  '/orgs/*/msn-portal/business/rejected': {
    'page.view': [msnOnboardingEvent, 'business-rejected'],
  },
  '/orgs/*/msn-portal/business/confirm-details': {
    'page.view': [msnOnboardingEvent, 'confirm-details'],
    'progress.continue': [msnOnboardingEvent, 'confirm-details-continue', msnOnboardingUserInfo],
    'msnPortalConfirmDetails.prevButton': [msnOnboardingEvent, 'confirm-details-prev', msnOnboardingUserInfo],
    'msnPortalConfirmDetails.editDetailsButton': [msnOnboardingEvent, 'confirm-details-edit', msnOnboardingUserInfo],
    'msnPortalConfirmDetails.registerError': [
      msnOnboardingEvent,
      'confirm-details-error',
      { ...msnOnboardingUserInfo, error: 'error' },
    ],
  },
  '/orgs/*/msn-portal/business/verify-bank-account-status': {
    'page.view': [msnOnboardingEvent, 'verify-bank-account-status'],
    'progress.continue': [
      msnOnboardingEvent,
      'verify-bank-account-status-continue',
      { ...msnOnboardingUserInfo, dmVerified: 'dmVerified', msnVerified: 'msnVerified' },
    ],
  },
  '/orgs/*/msn-portal/existing-organizations': {
    'page.view': [msnOnboardingEvent, 'business-confirmation'],
    'progress.continue': [msnOnboardingEvent, 'business-confirmation-continue', msnOnboardingUserInfo],
    'msnPortalExistingOrganizations.addNewOrganization': [
      msnOnboardingEvent,
      'business-confirmation-add-new-business',
      msnOnboardingUserInfo,
    ],
    'msnPortalExistingOrganizations.error': [
      msnOnboardingEvent,
      'business-confirmation-error',
      { ...msnOnboardingUserInfo, errorReason: 'add-new-business-failed' },
    ],
  },
  '/orgs/*/msn-portal/business/select-business-type': {
    'page.view': [msnOnboardingEvent, 'business-type'],
    'progress.continue': [msnOnboardingEvent, 'business-type-continue', msnOnboardingUserInfo],
    'msnPortalSelectBusinessType.error': [
      msnOnboardingEvent,
      'business-confirmation-error',
      { ...msnOnboardingUserInfo, errorReason: 'update-organization-preferences-failed' },
    ],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/select': {
    'onboarding.deliveryMethods.bank.verification.msnPortal.plaid.label': [
      msnOnboardingEvent,
      'connect-bank-click',
      optinInfo,
    ],
    'onboarding.deliveryMethods.bank.verification.msnPortal.manual.label': [
      msnOnboardingEvent,
      'connect-bank-click',
      optinInfo,
    ],
  },
};
