import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { authLocations } from 'src/pages/auth/locations';
import { globalLocations } from 'src/pages/locations';
import { getIsIntuitLinked, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import accountingSoftwareApi from 'src/services/api/accountingSoftwareSync';
import { IntuitUserType, LocalStorageKeys, RegistrationOrigin } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import intuit from 'src/utils/intuit';
import locations from 'src/utils/locations';
import { LoginFlowType, useLoginFlowType } from '../multi-factor-authentication/hooks/useLoginFlowType';

export const AuthorizeIntuitSuccessPage = () => {
  const { isAfterRegister, userType, isFirstTimeVisitedRegularMelio } = useQueryString();
  const { setLoginFlowType } = useLoginFlowType();
  const orgId = useSelector(getOrgId);
  const isIntuitLinked = useSelector(getIsIntuitLinked);
  const history = useHistory();
  const isFirstTimeVisitedRegularMelioValue = isFirstTimeVisitedRegularMelio === 'true';

  useEffect(() => {
    analytics.track('authorize-intuit', 'autorize-intuit-success', {
      userType,
      isFirstTimeVisitedRegularMelioValue,
    });
    setLoginFlowType(LoginFlowType.Intuit);

    if (userType === IntuitUserType.NEW) {
      intuit.goConnectToIntuit({
        registrationOrigin: RegistrationOrigin.APP,
        intuitReturnUrl: authLocations.register.authorizeQuickbooksSuccess,
        isAfterRegister: isAfterRegister === 'true',
      });
    } else if (userType === IntuitUserType.QBO_MULTI_ORG && isFirstTimeVisitedRegularMelioValue) {
      history.push(globalLocations.quickbookMultiOrgOnboarding.companyType);
    } else {
      if (isIntuitLinked) {
        accountingSoftwareApi.syncQuickBooksData(orgId);
      }

      let redirectUrl: string = locations.MainApp.dashboard.url({ orgId });
      const appUrl = localStorage.getItem(LocalStorageKeys.INTUIT_RETURN_APP_URL);

      if (appUrl) {
        redirectUrl = appUrl;
        localStorage.removeItem(LocalStorageKeys.INTUIT_RETURN_APP_URL);
      }

      history.push(redirectUrl);
    }
  }, [isIntuitLinked, history, orgId, isAfterRegister, userType, isFirstTimeVisitedRegularMelioValue]);

  return <AreaLoader />;
};
