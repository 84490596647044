import { Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { RadioGroupField } from 'src/core/ds/form/fields';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Radio } from 'src/core/ds/radio';
import { whitePagesAddressKeys } from 'src/utils/address';
import { ManualAddress } from 'src/utils/consts';
import { AddressType } from 'src/utils/types';
import { AmexVendorAddressOption } from '../../components/AmexVendorAddressOption';

type Props = {
  onClose: () => void;
  onEditSuggestedAddress: (suggeestedAddress: AddressType) => void;
  onConfirmSuggestedAddress: (suggeestedAddress: AddressType) => Promise<void>;
  onConfirmOriginalAddress: () => void;
  originalAddress: AddressType;
  vendorName?: string;
  verifyAddressResult: ManualAddressType | null;
  isSubmitting: boolean;
};

export const AmexVendorAddressVerificationConfirmModal = ({
  onClose,
  onEditSuggestedAddress,
  onConfirmOriginalAddress,
  onConfirmSuggestedAddress,
  originalAddress,
  verifyAddressResult,
  isSubmitting,
  vendorName,
}: Props) => {
  const [selectedAddressOptionId, setSelectedAddressOptionId] = useState(ManualAddress.SUGGESTED);

  const onSelect = (id: ManualAddress) => {
    setSelectedAddressOptionId(id);
  };

  const suggestedAddress = useMemo(
    () =>
      Object.keys(whitePagesAddressKeys).reduce((acc, key) => {
        const newKey = whitePagesAddressKeys[key];

        return {
          ...acc,
          [newKey]: verifyAddressResult?.[key],
        };
      }, {} as AddressType),
    [verifyAddressResult]
  );

  const addressVerificationErrors = useMemo(
    () => verifyAddressResult?.diff.reduce((acc, curr) => ({ ...acc, ...curr })),
    [verifyAddressResult?.diff]
  );

  const getSuggestedAddressContent = () => {
    const suggestedAddressStringified = Object.values(suggestedAddress)
      .filter((fieldValue) => !!fieldValue)
      .map((fieldValue) => fieldValue)
      .join(', ');

    return (
      <AmexVendorAddressOption
        vendorName={vendorName}
        address={suggestedAddressStringified}
        onEditAddress={() => onEditSuggestedAddress(suggestedAddress)}
      />
    );
  };

  const getOriginalAddressContent = () => {
    const addressFields = Object.values(whitePagesAddressKeys);
    const addressFiltered = addressFields.filter((field) => !!originalAddress?.[field]);
    const addressWithVerificationErrors = addressFiltered.map((field, index) => {
      const color =
        addressVerificationErrors && Object.keys(addressVerificationErrors).includes(field) ? 'red.500' : undefined;

      return (
        <Fragment key={field}>
          <Box as="span" color={color}>
            {originalAddress?.[field]}
          </Box>
          {index !== addressFiltered.length - 1 && <Box as="span" key={field} _after={{ content: "','", me: 1 }} />}
        </Fragment>
      );
    });

    return (
      <AmexVendorAddressOption
        vendorName={vendorName}
        icon={<Icon size={IconSize.s} name={IconNames.alertCircle} color="red.500" />}
        address={addressWithVerificationErrors}
      />
    );
  };

  const addressOptions = [
    {
      value: ManualAddress.SUGGESTED,
      testId: 'amexAddressVerificationOption.suggested',
      label: (
        <MIFormattedText label="bills.pay.fundingSource.amexVendorAddressVerificationConfirmModal.suggestedOptionTitle" />
      ),
      component: getSuggestedAddressContent(),
    },
    {
      value: ManualAddress.ORIGINAL,
      testId: 'amexAddressVerificationOption.original',
      label: (
        <MIFormattedText label="bills.pay.fundingSource.amexVendorAddressVerificationConfirmModal.originalOptionTitle" />
      ),
      component: getOriginalAddressContent(),
    },
  ];

  const onConfirm = async () => {
    if (selectedAddressOptionId === ManualAddress.ORIGINAL) {
      onConfirmOriginalAddress();

      return;
    }

    await onConfirmSuggestedAddress(suggestedAddress);
  };

  return (
    <ModalMessage
      id="amex-address-verification-confirm-modal"
      titleComponent={
        <>
          <Box textStyle="h2Semi" textAlign="center" mb={4}>
            <MIFormattedText label="bills.pay.fundingSource.amexVendorAddressVerificationConfirmModal.title" />
          </Box>
          <Box textStyle="body2" textAlign="center">
            <MIFormattedText label="bills.pay.fundingSource.amexVendorAddressVerificationConfirmModal.subtitle" />
          </Box>
        </>
      }
      contentComponent={
        <Container>
          <RadioGroupField
            marginBottom={0}
            value={selectedAddressOptionId}
            id="addressOptions"
            direction="column"
            name="addressOptions"
            onChange={(id) => onSelect(id as ManualAddress)}
            isRequired
            gap={6}
          >
            {addressOptions.map((option) => (
              <Flex key={option.value} gap={4} direction="column" alignItems="flex-start">
                <Box color="grey.700" textStyle="body3">
                  {option.label}
                </Box>
                <Flex key={option.value} alignItems="flex-start" color="grey.600">
                  <Radio value={option.value} testId={option.testId}>
                    {option.component}
                  </Radio>
                </Flex>
              </Flex>
            ))}
          </RadioGroupField>
        </Container>
      }
      buttonComponent={
        <Button
          isFullWidth
          onClick={onConfirm}
          isLoading={isSubmitting}
          label="bills.pay.fundingSource.amexVendorAddressVerificationConfirmModal.cta"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
        />
      }
      onCloseClick={onClose}
    />
  );
};

const Container = styled.div`
  .chakra-form__label {
    display: none;
  }

  .chakra-radio-group .chakra-stack {
    margin-bottom: 0;
  }
`;
