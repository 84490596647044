import { createSelector } from '@reduxjs/toolkit';
import minBy from 'lodash/fp/minBy';
import sortBy from 'lodash/fp/sortBy';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import {
  APPROVAL_WORKFLOWS_CREATE_SUCCESS,
  APPROVAL_WORKFLOWS_DELETE_SUCCESS,
  APPROVAL_WORKFLOWS_UPDATE_SUCCESS,
} from 'src/modules/approval-workflows/approval-workflows-actions';
import { ApprovalWorkflowCreatorsGroup } from 'src/utils/consts';
import { ApprovalWorkflowType } from 'src/utils/types';
import * as api from './api';

const storeName = 'approvalWorkflows';

const validate = async (obj: ApprovalWorkflowType) => {
  const errors: string[] = [];

  if (obj.approvalDecisionsCount < 1) {
    errors.push('approvalDecisionsCount');
  }

  if (obj.threshold < 0) {
    errors.push('threshold');
  }

  return errors.length ? errors : null;
};

const listHashFunc = () => 'all';
const order = (state) => state[storeName].lists?.[listHashFunc()]?.order;
const byId = (state) => state[storeName].byId;
const sortWorkflows = sortBy(['threshold', 'approvalDecisionsCount', 'id']);
const getAllListObjects = (state): ApprovalWorkflowType[] | undefined => order(state)?.map((id) => byId(state)[id]);
const workflowBelongsToUser = (userId: number) => (workflow: ApprovalWorkflowType) =>
  workflow.creatorsGroup === ApprovalWorkflowCreatorsGroup.ALL || workflow.paymentCreatorIds?.includes(userId);

export const approvalWorkflowsStore = createRestfulSlice<ApprovalWorkflowType>({
  initialState: {
    byId: {},
  },
  listHashFunc,
  api: {
    list: api.list,
    update: api.update,
    create: api.create,
    delete: api.deleteWorkflow,
  },
  name: storeName,
  validateFunc: validate,
  selectors: {
    orderedListValue: createSelector([order, byId], (order, byId) => {
      const values = (order || []).map((id) => byId[id]);

      return sortWorkflows(values);
    }),
    userWorkflows: (userId: number) => (state): ApprovalWorkflowType[] =>
      getAllListObjects(state)?.filter(workflowBelongsToUser(userId)) || [],
    minUserWorkflowThreshold: (userId: number) => (state): number | undefined => {
      const userWorkflows = getAllListObjects(state)?.filter(workflowBelongsToUser(userId));
      const min = minBy('threshold', userWorkflows);

      return min?.threshold;
    },
    userHasWorkflows: (userId: number) => (state): boolean =>
      getAllListObjects(state)?.some(workflowBelongsToUser(userId)) || false,
    findMatching1X1Workflow: (paymentCreatorId?: number) => (state): ApprovalWorkflowType | undefined =>
      paymentCreatorId
        ? getAllListObjects(state)?.find(
            (workflow: ApprovalWorkflowType) =>
              workflow.creatorsGroup === ApprovalWorkflowCreatorsGroup.SOME &&
              workflow.paymentCreatorIds?.length === 1 &&
              workflow.approvalDecisionsCount === 1 &&
              workflow.paymentCreatorIds.includes(paymentCreatorId)
          )
        : undefined,
  },
  extraReducers: {
    [APPROVAL_WORKFLOWS_CREATE_SUCCESS](state, action) {
      state.lists[listHashFunc()]?.order.push(action.payload.id);
    },
    [APPROVAL_WORKFLOWS_DELETE_SUCCESS](state, action) {
      state.lists[listHashFunc()].order =
        state.lists?.[listHashFunc()]?.order.filter((id) => id !== action.payload.id) || [];
    },
    [APPROVAL_WORKFLOWS_UPDATE_SUCCESS](state, action) {
      state.byId[action.payload.id] = action.payload;
    },
  },
});
