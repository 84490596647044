import { EventMappingsTreeChild } from 'src/services/analytics/types';

export const PAGE_EVENT = 'amex-industry-modal';

const baseProperties = {
  billId: 'billId',
  paymentRequestId: 'paymentRequestId',
  vendorId: 'vendorId',
  selectedCategory: 'selectedCategory',
  selectedSubCategory: 'selectedSubCategory',
  partialBillId: 'partialBillId',
  isBatch: 'isBatch',
};

const GUEST_MAPPING: EventMappingsTreeChild = {
  'amex-industry-open': [
    PAGE_EVENT,
    'open',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-save': [
    PAGE_EVENT,
    'click-save',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-close': [
    PAGE_EVENT,
    'close',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-error-category': [
    PAGE_EVENT,
    'error-category-not-selected',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-error': [
    PAGE_EVENT,
    'error-not-selected',
    {
      errorType: 'errorType',
      ...baseProperties,
    },
  ],
  'amex-industry-change-payment-method': [
    PAGE_EVENT,
    'change-payment-method-link-clicked',
    {
      ...baseProperties,
    },
  ],
};

const BILL_MAPPING: EventMappingsTreeChild = {
  'amex-industry-open': [
    PAGE_EVENT,
    'open',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-save': [
    PAGE_EVENT,
    'click-save',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-close': [
    PAGE_EVENT,
    'close',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-error': [
    PAGE_EVENT,
    'error-not-selected',
    {
      errorType: 'errorType',
      ...baseProperties,
    },
  ],
  'amex-industry-learn-more': [
    PAGE_EVENT,
    'learn-more',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-change-payment-method': [
    PAGE_EVENT,
    'change-payment-method-link-clicked',
    {
      ...baseProperties,
    },
  ],
};

const BATCH_PAYMENTS_MAPPING: EventMappingsTreeChild = {
  'amex-industry-open': [
    PAGE_EVENT,
    'open',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-save': [
    PAGE_EVENT,
    'click-save',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-close': [
    PAGE_EVENT,
    'close',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-error': [
    PAGE_EVENT,
    'error-not-selected',
    {
      errorType: 'errorType',
      ...baseProperties,
    },
  ],
  'amex-industry-learn-more': [
    PAGE_EVENT,
    'learn-more',
    {
      ...baseProperties,
    },
  ],
  'amex-industry-change-payment-method': [
    PAGE_EVENT,
    'change-payment-method-link-clicked',
    {
      ...baseProperties,
    },
  ],
};

export { BATCH_PAYMENTS_MAPPING, BILL_MAPPING, GUEST_MAPPING };
