import { useEffect } from 'react';
import { useModal } from 'src/helpers/react/useModal';
import { EditPaymentMethodLabelDialog } from 'src/pages/settings/components/EditPaymentMethodLabelDialog';
import { EditFundingSourceLabelState } from 'src/pages/settings/PaymentMethodsSettingsPageContainer';

export const useFundingSourceEditLabel = (
  editingFundingSourceLabel: EditFundingSourceLabelState,
  resetFundingSourceLabelEdit: () => void
) => {
  useEffect(() => {
    if (editingFundingSourceLabel?.id) {
      showEditPaymentMethodLabelModal();
    }
  }, [editingFundingSourceLabel]);

  const [EditPaymentMethodLabelModal, showEditPaymentMethodLabelModal] = useModal(EditPaymentMethodLabelDialog, {
    id: 'editPaymentMethodLabelModal',
    editingFundingSourceLabel,
    onDismiss: resetFundingSourceLabelEdit,
  });

  return [EditPaymentMethodLabelModal];
};
