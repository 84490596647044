import { getValidationErrors } from '@melio/sizzers-js-common';
import { isValid } from 'date-fns';
import head from 'lodash/head';
import dateFromModelValue from 'src/components/modals/DownloadCsvReportModal/modules/dateFromModelValue';
import { convertToServerLegalAddress } from 'src/utils/address';
import { TaxIdType } from 'src/utils/consts';
import { GoogleCombinedAddressType } from 'src/utils/types';

export type LegalInfoType = {
  legalCompanyName: string | null;
  taxId?: string;
  taxIdType?: TaxIdType;
  dateOfBirth: string;
  legalGooglePlaceId?: string | null;
};

export const isDateOfBirthRequired = (taxType: TaxIdType | null | undefined, showDateOfBirth?: boolean) =>
  showDateOfBirth && taxType === TaxIdType.SSN;

export const getValidationErrorForField = (
  address: GoogleCombinedAddressType,
  isCompanyLegalInfoDone: boolean,
  showDateOfBirth?: boolean
) => (key: string, value: string, modelState: LegalInfoType): string | undefined => {
  if (key === 'dateOfBirth' && modelState.taxIdType && isDateOfBirthRequired(modelState.taxIdType, showDateOfBirth)) {
    if (!value) {
      return 'vendors.deliveryMethods.international.businessDetails.errorEmpty';
    } else if ((value?.length >= 1 && value?.length < 10) || !isValid(dateFromModelValue(value))) {
      return 'vendors.deliveryMethods.international.businessDetails.errorValid';
    }
  }

  if (!isCompanyLegalInfoDone) {
    if (key === 'legalGooglePlaceId') {
      const legalGooglePlaceIdValue = {
        legalGooglePlaceId: address.placeId === 'synced-from-qbo' ? null : address.placeId,
      };
      const addressValue = { ...convertToServerLegalAddress(address), ...legalGooglePlaceIdValue };
      const addressValidationErrors = getValidationErrors('companyInfo', addressValue, [
        'legalGooglePlaceId',
        'legalZipCode',
        'legalAddressLine1',
      ]);

      return addressValidationErrors.legalGooglePlaceId || head(Object.values(addressValidationErrors));
    }

    const validationErrors = getValidationErrors('companyInfo', { [key]: value }, [
      'taxId',
      'taxIdType',
      'legalCompanyName',
    ]);

    return validationErrors[key];
  }

  return undefined;
};
