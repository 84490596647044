import isNil from 'lodash/isNil';
import { RequiredFieldsType } from 'src/pages/vendor/international-delivery-method/utils';

export const vendorDetailsFormValidator = (requiredFields: Partial<RequiredFieldsType>) => (
  key: string,
  value: string | number
) => {
  if (!(key in requiredFields)) return undefined;

  if (isNil(value)) return `vendors.deliveryMethods.international.vendorDetails.validation.${key}`;

  const regex = new RegExp(requiredFields[key]);

  if (!regex.test(value.toString())) {
    return `vendors.deliveryMethods.international.vendorDetails.validation.${key}`;
  }

  return undefined;
};

export const forbiddenCountries = ['VE', 'SY', 'SS', 'KP', 'MM', 'LY', 'IR', 'CU'];
