import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { ModelView } from 'src/ui/form';
import { FORM_MAX_LENGTH } from 'src/utils/consts';

export type MemoPageForm = {
  memo: string;
  contactEmail: string;
};

type MemoPageModelType = ModelView<MemoPageForm>;

type Props = {
  onPrev?: () => void;
  onNext: () => void;
  goExit: () => void;
  model: MemoPageModelType;
  amount: string;
  companyName: string;
  billId: string | null;
  headerLabel: string;
  relativeStep?: number;
  title: string;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  titleValues?: Record<string, any>;
  label: string;
  labelValues?: Record<string, any>;
  hint: string;
  maxLength?: number;
  hintValues?: Record<string, any>;
  isProcessing: boolean;
  validationErrors: Partial<{ memo: string; contactEmail: string }>;
  shouldShowEmailInput: boolean;
};
export const PayBillMemoPage = ({
  onNext,
  onPrev,
  goExit,
  model,
  amount,
  companyName,
  billId,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  subtitle,
  subTitleValues,
  labelValues,
  label,
  hint,
  maxLength = FORM_MAX_LENGTH.NOTE,
  hintValues,
  isProcessing,
  validationErrors,
  shouldShowEmailInput,
}: Props) => {
  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={amount} privateData />
      </strong>
    ),
    companyName: <strong>{companyName}</strong>,
  };

  if (!billId) {
    return <AreaLoader />;
  }

  return (
    <StepLayoutPage
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={title}
      titleValues={titleValues}
      subtitle={subtitle}
      subTitleValues={subTitleValues}
      goExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      relativeStep={relativeStep}
      isLoading={isProcessing}
    >
      <PrivateDataContainer>
        <TextField
          id="memo"
          label={label}
          labelValues={labelValues}
          placeholder="bills.pay.memo.placeholder"
          helperText={hint}
          helperTextValues={hintValues}
          model={model.memo}
          maxLength={maxLength}
          autoFocus
        />
      </PrivateDataContainer>
      {shouldShowEmailInput && (
        <Box mt={10}>
          <PrivateDataContainer>
            <TextField
              id="contactEmail"
              testId="input-contactEmail"
              label="bills.pay.collectVendorEmailModal.emailLabel"
              placeholder="bills.pay.collectVendorEmailModal.emailPlaceholder"
              model={model.contactEmail}
              type="email"
              errorMessage={validationErrors.contactEmail}
            />
          </PrivateDataContainer>
        </Box>
      )}
    </StepLayoutPage>
  );
};
