import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import Colors from 'src/core/theme/foundations/colors';
import { CardThemes } from '../ViewBillPaymentActivity';

type Props = {
  theme: CardThemes;
};

export const NoInstallmentsInfo = ({ theme = CardThemes.Black }: Props) => {
  const refresh = () => {
    window.location.reload();
  };

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon isBillPaid={theme === CardThemes.Grey}>
            <Icon name={IconNames.scheduled} size={IconSize.lg} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label="financing.firstInstallmentDeductsLabel" />
            </PaymentMethodText>
            <Flex
              alignItems="center"
              textStyle="body4"
              color={theme === CardThemes.Grey ? Colors.grey[700] : Colors.black}
            >
              <MIFormattedText label="financing.noInstallmentsTextLabel" />
              <IconButton
                testId="refresh-financing-installments"
                aria-label="Options"
                onClick={refresh}
                icon={<Icon pl={0.5} name={IconNames.refresh} size={IconSize.s} color={Colors.primary[500]} />}
              />
            </Flex>
          </PaymentTextContainer>
        </PaymentInfoContainer>
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};
