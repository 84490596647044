import isFunction from 'lodash/isFunction';
import merge from 'lodash/merge';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from './createApiCallSlice';
import { RestfulSliceParams } from './types';

export function createSliceReducer(hashKey) {
  const hashFunc = isFunction(hashKey) ? hashKey : () => hashKey;

  return {
    [ON_REQUEST](state, action) {
      const key = hashFunc(action.payload);
      state.create[key] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const key = hashFunc(action.meta.identifier);
      state.create[key] = { loading: false, done: true, id: action.payload.id };
      state.byId[action.payload.id] = merge(state.byId[action.payload.id] || {}, action.payload);
    },
    [ON_FAILURE](state, action) {
      const key = hashFunc(action.meta.identifier);
      state.create[key] = { loading: false, error: action.error };
    },
  };
}
export function selectCreateIsLoading(hashKey) {
  const hashFunc = isFunction(hashKey) ? hashKey : () => hashKey;

  return (payload?: any) => (state) => {
    const key = hashFunc(payload);

    return state.create[key]?.loading;
  };
}

export const createError = (storeName) => (state) => state[storeName]?.create?.create?.error;

type CreateSliceParams<T> = {
  createHashFunc: (T?) => string;
} & RestfulSliceParams<T>;

export function createCreateSlice<T>({
  storeName,
  api,
  createHashFunc,
  validate,
  sagas,
  actionName = 'create',
}: CreateSliceParams<T>) {
  return createApiCallSlice({
    initialState: {
      create: {
        loading: false,
      },
      byId: {},
    },
    name: generateSliceName(storeName, actionName),
    api,
    reducers: createSliceReducer(createHashFunc),
    validate,
    selectors: {
      status: (obj) => (state) => state[storeName].create[createHashFunc(obj)],
    },
    sagas,
  });
}
