import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { Link } from 'src/core/ds/link';
import { profileStore } from 'src/modules/profile/profile-store';
import { learnMoreListOfOwnersRequired } from 'src/pages/bill/pay/installments/consts';
import { useBusinessApplicationContext } from 'src/pages/bill/pay/installments/context/business-context/useBusinessApplicationContext';
import { BusinessOwnershipDetailsForm } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/BusinessOwnershipDetailsForm';
import { BusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { getPayment } from 'src/redux/payBillWizard/selectors';

type Props = {
  goNext: () => void;
  cancelFlow: () => void;
};

export const BusinessOwnershipDetailsPage = ({ goNext, cancelFlow }: Props) => {
  const { setOwnershipDetails } = useBusinessApplicationContext();
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);
  const { payBillFlowUUID } = useSelector(getPayment);

  const handleSubmit = (businessOwnershipDetailsModel: BusinessOwnershipDetailsModel) => {
    setOwnershipDetails?.(businessOwnershipDetailsModel);
    goNext();
  };

  return (
    <WizardLayout>
      <WizardLayout.TopBar onCancelClick={cancelFlow} />
      <WizardLayout.Content>
        <WizardLayout.Header>
          <Box textStyle="h1Semi" textAlign="center" mb={4} data-testid="financing-business-ownership-details-title">
            <MIFormattedText
              label="financing.businessOwnershipDetails.header.title"
              values={{ companyName: currentOrg.companyName }}
            />
          </Box>
          <Box textStyle="body2" textAlign="center" mb={10}>
            <MIFormattedText
              label="financing.businessOwnershipDetails.header.subtitle"
              values={{
                link: (text) => (
                  <Link
                    href={learnMoreListOfOwnersRequired}
                    target="_blank"
                    data-testid="financing-business-ownership-details-learn-more"
                  >
                    {text}
                  </Link>
                ),
              }}
            />
          </Box>
        </WizardLayout.Header>
        <WizardLayout.Body>
          <BusinessOwnershipDetailsForm payBillFlowUUID={payBillFlowUUID} onSubmit={handleSubmit} />
        </WizardLayout.Body>
      </WizardLayout.Content>
    </WizardLayout>
  );
};
