import type { PartsStyleFunction, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { randomColor } from '@chakra-ui/theme-tools';
import { Consts } from 'src/core/ds/avatar';

const baseStyleBadge: SystemStyleFunction = () => ({
  transform: 'translate(25%, 25%)',
});

const baseStyleExcessLabel: SystemStyleFunction = () => ({
  bg: 'transparent',
  apply: 'textStyles.body4Semi',
  color: 'grey.700',
  ms: 0.5,
});

const baseStyleContainer: SystemStyleFunction = (props) => {
  const { name, bg, color } = props;
  const backgroundColor = name ? randomColor({ string: name }) : 'grey.600';

  return {
    bg: bg || backgroundColor,
    color,
    verticalAlign: 'top',
    userSelect: 'none',
    cursor: 'pointer',
  };
};

const baseStyle: PartsStyleFunction = (props) => ({
  badge: baseStyleBadge(props),
  excessLabel: baseStyleExcessLabel(props),
  container: baseStyleContainer(props),
});

function getStyleBySize(size: Consts.Size) {
  return {
    container: {
      width: sizesMap[size],
      height: sizesMap[size],
      apply: fontStyle[size],
    },
    excessLabel: {
      width: 'fit-content',
      height: sizesMap[size],
      textTransform: 'uppercase',
    },
    label: {
      apply: fontStyle[size],
      textTransform: 'uppercase',
    },
  };
}

const fontStyle = {
  [Consts.Size.xs]: 'textStyles.caption1Semi',
  [Consts.Size.sm]: 'textStyles.caption1Semi',
  [Consts.Size.md]: 'textStyles.caption1Semi',
  [Consts.Size.lg]: 'textStyles.body3Semi',
  [Consts.Size.xl]: 'textStyles.body3Semi',
  [Consts.Size['2xl']]: 'textStyles.h1Semi',
  [Consts.Size['3xl']]: 'textStyles.display2Semi',
};

const sizesMap = {
  [Consts.Size.xs]: '1.6rem',
  [Consts.Size.sm]: '2rem',
  [Consts.Size.md]: '2.4rem',
  [Consts.Size.lg]: '4rem',
  [Consts.Size.xl]: '4.8rem',
  [Consts.Size['2xl']]: '7.2rem',
  [Consts.Size['3xl']]: '12rem',
};

const styleMap = {
  [Consts.Size.xs]: getStyleBySize(Consts.Size.xs),
  [Consts.Size.sm]: getStyleBySize(Consts.Size.sm),
  [Consts.Size.md]: getStyleBySize(Consts.Size.md),
  [Consts.Size.lg]: getStyleBySize(Consts.Size.lg),
  [Consts.Size.xl]: getStyleBySize(Consts.Size.xl),
  [Consts.Size['2xl']]: getStyleBySize(Consts.Size['2xl']),
  [Consts.Size['3xl']]: getStyleBySize(Consts.Size['3xl']),
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes: styleMap,
  defaultProps,
};
