import isEmpty from 'lodash/isEmpty';
import { ReactNode } from 'react';
import { NoOptionsProps } from 'src/components/common/DropDown/MIDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex';

type Props = {
  noOptionsLabel?: string;
} & NoOptionsProps;

export const NoOptions = (props: Props) =>
  isEmpty(props.text) && props.noOptionsLabel ? (
    <NoOptionsWrapper>
      <MIFormattedText label={props.noOptionsLabel} />
    </NoOptionsWrapper>
  ) : null;

const NoOptionsWrapper = ({ children }: { children?: ReactNode }) => (
  <Flex h={12} align="center" justify="center" textStyle="body4" color="black">
    {children}
  </Flex>
);
