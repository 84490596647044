/* eslint-disable import/first -- the ESLint rule isn't configured smartly enough to understand that relative side-effect imports should be before library imports
configuring it in a smarter way would probably involved a negative lookahead, which is (relatively) slow and isn't useful for 99% of the cases.
If this issue is encountered again, we should consider configuring the rule to account for these. */
import '../DateField.css';

import { forwardRef, ReactNode, SyntheticEvent } from 'react';
import ReactDatePicker from 'react-datepicker';
import { FormControlProps } from 'src/core/ds/form';
import { ModelViewField } from 'src/ui/form';
import { DateFormats } from 'src/utils/date-fns';
import { DateFieldInput } from './DateFieldInput';

type Props = {
  id?: string;
  date?: Date | null;
  model?: ModelViewField<string>;
  dueDate?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  label?: string;
  labelValues?: Record<string, unknown>;
  openToDate?: Date;
  renderDayContents?: (day: number, date: Date) => ReactNode;
  dateFormat?: DateFormats;
  placeholder?: string;
  errorMessage?: string;
  helperText?: string;
  helperTextValues?: Record<string, unknown>;
  isDisabled?: boolean;
  isRequired?: boolean;
  isViewOnly?: boolean;
  size?: 'sm' | 'md';
  onChange: (date: Date | null, event: SyntheticEvent<HTMLInputElement, Event>) => void;
  inline?: boolean;
  testId?: string | null;
  onInputClick?: () => void;
  customInput?: ReactNode;
  onBlur?: () => void;
  onMonthChange?: (month: Date) => void;
  isClearable?: boolean;
  calendarClassName?: string;
  withIcon?: boolean;
  filterDate?: (date: Date) => boolean;
  onGetDayClassName?: (date: Date) => string | null;
  mb?: FormControlProps['mb'];
};

export const DesktopDateField = forwardRef(
  (
    {
      id,
      customInput,
      date,
      model,
      onChange,
      dateFormat,
      placeholder,
      isRequired,
      isDisabled,
      isViewOnly,
      isClearable,
      inline,
      calendarClassName,
      minDate,
      maxDate,
      filterDate,
      openToDate,
      testId,
      onInputClick,
      onBlur,
      onMonthChange,
      renderDayContents,
      onGetDayClassName,
      errorMessage,
      size,
      label,
      labelValues,
      helperText,
      helperTextValues,
      withIcon,
      mb,
    }: Props,
    ref: React.LegacyRef<ReactDatePicker<never, undefined>> | undefined
  ) => {
    const selectedDate = date ? new Date(date) : null;
    const ignoreChanges = () => undefined;
    // ReactDatePicker overrides some customInput params with its own params.
    // Therefore, these params have to be passed as props for ReactDatePicker and not DateInputProps:
    // id, name, placeholder (as placeholderText), disabled, autoComplete, title, readonly, required, tabIndex

    return (
      <ReactDatePicker
        id={id}
        ref={ref}
        customInput={
          customInput || (
            <DateFieldInput
              id={id}
              model={model}
              label={label}
              labelValues={labelValues}
              size={size}
              helperText={helperText}
              helperTextValues={helperTextValues}
              errorMessage={errorMessage}
              isRequired={isRequired}
              isDisabled={isDisabled}
              isViewOnly={isViewOnly}
              onChange={ignoreChanges}
              dateFormat={dateFormat}
              placeholder={placeholder}
              withIcon={withIcon}
              date={date}
              readOnlyValue
              mb={mb}
            />
          )
        }
        selected={selectedDate}
        onChange={onChange}
        dateFormat={dateFormat}
        dropdownMode="select"
        placeholderText={placeholder}
        required={isRequired}
        disabled={isDisabled}
        disabledKeyboardNavigation
        popperPlacement="bottom"
        isClearable={isClearable}
        inline={inline}
        calendarClassName={calendarClassName}
        minDate={minDate}
        maxDate={maxDate}
        filterDate={filterDate}
        openToDate={openToDate}
        data-testid={testId}
        dayClassName={onGetDayClassName}
        onInputClick={onInputClick}
        onBlur={onBlur}
        onMonthChange={onMonthChange}
        renderDayContents={renderDayContents}
      />
    );
  }
);
