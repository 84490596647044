import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { InstallmentsTitle } from 'src/pay/components/InstallmentsTitle';

type Props = {
  currentInstallmentNumber: number | undefined;
  totalInstallmentAmount: number;
  providerDisclaimer: React.ReactNode;
};

export const InstallmentStatusDesktop = ({
  currentInstallmentNumber,
  totalInstallmentAmount,
  providerDisclaimer,
}: Props) => (
  <Flex mb={10} h="4.5rem" flexDirection="row" alignItems="center">
    <Icon name={IconNames.installments} size={IconSize.xl} />
    <Flex direction="column" pl={3}>
      <Box mb={1} data-testid="installments-status">
        <InstallmentsTitle
          currentInstallmentNumber={currentInstallmentNumber}
          totalInstallmentAmount={totalInstallmentAmount}
        />
      </Box>
      <Box textStyle="body4" color="grey.600">
        {providerDisclaimer}
      </Box>
    </Flex>
  </Flex>
);
