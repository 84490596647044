import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { IconNames } from 'src/core/ds/icon';
import { Modal } from 'src/core/ds/modal';
import { HStack } from 'src/core/ds/stack';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
};

export const InternationalBillsFilteredModal = ({ isOpen, onClose, onContinue }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
    <Modal.Header>
      <Modal.Icon name={IconNames.alertFill} color="yellow.500" />
      <Modal.HeaderTitle>
        <Box textStyle="h2Semi">
          <MIFormattedText label="regularBatchPayments.internationalBillsFilteredModal.title" />
        </Box>
      </Modal.HeaderTitle>
    </Modal.Header>
    <Modal.Body>
      <Box textStyle="body2">
        <MIFormattedText label="regularBatchPayments.internationalBillsFilteredModal.body" />
      </Box>
    </Modal.Body>
    <Modal.Footer>
      <HStack spacing={4}>
        <Button
          size={ButtonSizes.md}
          onClick={onClose}
          label="regularBatchPayments.internationalBillsFilteredModal.cancel"
          variant={ButtonVariants.tertiary}
        />
        <Button
          size={ButtonSizes.md}
          onClick={onContinue}
          label="regularBatchPayments.internationalBillsFilteredModal.continue"
        />
      </HStack>
    </Modal.Footer>
  </Modal>
);
