import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { GetPaymentDeliveryTimeResponse, paymentsApi } from 'src/modules/payments/api';
import { updatePayment } from 'src/modules/regular-batch-payments/utils';
import { actionName } from './consts';

type BillOptionUpdatePayload = {
  itemId: number;
  orgId: number;
  deductionDate: Date;
  deliveryMethodId: number;
  fundingSourceId: number;
  amount: number;
};

type BillOptionUpdateState = {
  byId: Record<
    number,
    GetPaymentDeliveryTimeResponse & {
      loading: boolean;
      error?: Error;
    }
  >;
};

export const BATCH_UPDATE_DELIVERY_OPTIONS = generateSliceName(actionName, 'update payment delivery options');
export const regularBatchUpdateDeliveryOptionsSlice = createApiCallSlice<
  BillOptionUpdatePayload,
  BillOptionUpdateState,
  GetPaymentDeliveryTimeResponse
>({
  name: BATCH_UPDATE_DELIVERY_OPTIONS,
  initialState: {
    byId: {},
  },
  api: paymentsApi.getDeliveryTime,
  reducers: {
    [ON_REQUEST](state, action) {
      state.byId[action.payload.itemId] = {
        ...state.byId[action.payload.itemId],
        loading: true,
        error: undefined,
      };
    },
    [ON_SUCCESS](state, action) {
      state.byId[action.meta.identifier.itemId] = {
        ...state.byId[action.meta.identifier.itemId],
        loading: false,
      };
      updatePayment(state, action);
    },
    [ON_FAILURE](state, action) {
      state.byId[action.meta.identifier.itemId] = {
        ...state.byId[action.meta.identifier.itemId],
        loading: false,
        error: action.error,
      };
    },
  },
});
