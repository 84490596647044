import { MINotificationCardProps } from 'src/components/common/MINotificationCard';
import { NotificationCardTypes } from 'src/utils/consts';

export const VERIFIED_VENDOR_DETAILS_CONST = {
  MODAL_HEIGHT: 256,
};

export const DirectoryVendorLogoStyle = {
  w: 4,
  h: 4,
  border: '2px',
  borderColor: 'white',
  borderRadius: 'full',
  top: 14,
  left: 14,
  pos: 'absolute',
};

export const reviewVendorDetailsNotification: MINotificationCardProps = {
  type: NotificationCardTypes.INFO,
  title: { label: 'bills.form.verifiedVendorDetailsInfo.reviewVendorDetailsNotification.title' },
  subtitle: { label: 'bills.form.verifiedVendorDetailsInfo.reviewVendorDetailsNotification.subtitle' },
};
