import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Checkbox } from 'src/core/ds/checkbox';
import { useToggle } from 'src/helpers/react/useToggle';
import { CommonDialog, CommonDialogProps } from 'src/ui/dialog/CommonDialog';

type Props = CommonDialogProps & {
  checkbox?: {
    label: string;
    value: boolean;
    color: 'primary';
  };
  confirm: (checkboxValue: boolean) => Promise<any>;
};

const ProCommonDialog = (props: Props) => {
  const [toggle, setToggle] = useToggle(props.checkbox?.value);
  const { checkbox } = props;

  const checkBox = checkbox && (
    <Checkbox onChange={(event) => setToggle(event.target.checked)} isChecked={toggle} colorScheme={checkbox?.color}>
      <MIFormattedText label={checkbox?.label} />
    </Checkbox>
  );
  const confirm = async () => {
    await props.confirm(toggle);
  };
  const newProps = {
    ...props,
    confirm,
    textValues: {
      checkBox,
      ...props?.textValues,
    },
  };

  return <CommonDialog {...newProps} />;
};

export default ProCommonDialog;
