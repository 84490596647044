import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { FeatureFlags } from 'src/utils/featureFlags';
import { UserContextType } from 'src/utils/types';
import { AuthCodeVerificationPage } from '../email-verification/AuthCodeVerificationPage';
import { useLoginFlowType } from './hooks/useLoginFlowType';
import { useMfaDetails } from './hooks/useMfaDetails';
import { MfaCodeVerificationPage } from './MfaCodeVerificationPage';

type Props = {
  profile: UserContextType | null;
};

export const VerificationWrapper = ({ profile }: Props) => {
  const { mfaToken } = useMfaDetails();
  const { isEmailPasswordLoginFlow } = useLoginFlowType();
  const [isSignInMfaEnabled] = useFeature<boolean>(FeatureFlags.SignInMfa, false);
  const isSignInMfaEnabledForFlow = isSignInMfaEnabled && mfaToken && (!!profile?.mfa || isEmailPasswordLoginFlow);

  return isSignInMfaEnabledForFlow ? <MfaCodeVerificationPage /> : <AuthCodeVerificationPage />;
};
