import { progressAnatomy as parts } from '@chakra-ui/anatomy';
import type { SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';
import { PartsStyleFunction, PartsStyleObject, StyleFunctionProps } from '@chakra-ui/theme-tools';
import colors from 'src/core/theme/foundations/colors';

function filledStyle(props: StyleFunctionProps): SystemStyleObject {
  const { isIndeterminate } = props;

  function getColorHex(color) {
    const colorName = color.split('.')[0];
    const colorValue = color.split('.')[1];

    return colors[colorName][colorValue];
  }

  const bgColor = 'primary.500';
  const gradient = `linear-gradient(
    to right,
    transparent 0%,
    ${getColorHex(bgColor)} 50%,
    transparent 100%
  )`;

  return {
    ...(isIndeterminate ? { bgImage: gradient } : { bgColor }),
  };
}

const baseStyleTrack: SystemStyleFunction = () => ({
  bg: 'grey.400',
  borderRadius: 'md',
});

const baseStyleFilledTrack: SystemStyleFunction = (props) => ({
  transitionProperty: 'common',
  transitionDuration: 'slow',
  ...filledStyle(props),
});

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  filledTrack: baseStyleFilledTrack(props),
  track: baseStyleTrack(props),
});

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    track: { h: '0.8rem', w: '12rem' },
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  parts: parts.keys,
  sizes,
  baseStyle,
  defaultProps,
};
