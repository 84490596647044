import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';
import { ContactType } from 'src/utils/types';
import CardField from '../components/CardField';

type Props = Partial<ContactType>;

const CustomerDetails: React.FC<Props> = ({ companyName, contactEmail, contactName, contactPhone }) => (
  <Box>
    <Box textStyle="h2Semi" mb={5}>
      <MIFormattedText label="getPro.cards.customerDetails.title" />
    </Box>
    <Flex mb={10}>
      <VStack align="flex-start" flex="1" mr={6}>
        <CardField
          titleLabel="getPro.cards.customerDetails.businessName"
          emptyLabel="getPro.cards.customerDetails.businessNameEmpty"
          value={companyName}
          isTruncated
        />
        <CardField
          titleLabel="getPro.cards.customerDetails.contact"
          emptyLabel="getPro.cards.customerDetails.contactEmpty"
          value={contactName}
          isTruncated
        />
      </VStack>
      <VStack align="flex-start" flex="1">
        <CardField
          titleLabel="getPro.cards.customerDetails.email"
          emptyLabel="getPro.cards.customerDetails.emailEmpty"
          value={contactEmail}
          isTruncated
        />
        <CardField
          titleLabel="getPro.cards.customerDetails.phone"
          emptyLabel="getPro.cards.customerDetails.phoneEmpty"
          value={contactPhone}
          isTruncated
        />
      </VStack>
    </Flex>
  </Box>
);

export default CustomerDetails;
