import { useSelector } from 'react-redux';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { ConnectionStatus, SyncStatus } from 'src/utils/consts';

export const useIsSyncErrorState = () => {
  const bankAccountsListError = useSelector(accountingPlatformsStore.selectors.listBankAccounts.error);
  const createAndLinkBankAccountError = useSelector(accountingPlatformsStore.selectors.createAndLinkBankAccount.error);
  const connectedAccountingPlatform = useSelector(accountingPlatformsStore.selectors.connectedPlatform);
  const connectStatus = useSelector(accountingPlatformsStore.selectors.connect.status);
  const syncSummary = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.syncSummary);
  const accountingSoftwareBankAccountError = bankAccountsListError || createAndLinkBankAccountError;
  const activePlatformNoActiveConnection =
    connectedAccountingPlatform?.isActive &&
    connectedAccountingPlatform?.connectionStatus === ConnectionStatus.DISCONNECTED;

  const isErrorState =
    connectStatus?.error ||
    activePlatformNoActiveConnection ||
    accountingSoftwareBankAccountError ||
    syncSummary?.syncStatus === SyncStatus.FAILURE;

  return { isErrorState };
};
