import { ThemeType } from 'src/theme/global-theme';
import { InstallmentStatus } from 'src/utils/consts';
import { BillType, FinancePayment, Installment } from 'src/utils/types';
import { ViewFailedInstallment } from './ViewFailedInstallment';
import { ViewScheduledInstallments } from './ViewScheduledInstallments';

type Props = {
  payment: FinancePayment;
  bill: BillType;
  theme: ThemeType;
  backPath: string;
  installment: Installment;
};

const viewInstallmentComponent = {
  [InstallmentStatus.Scheduled]: ViewScheduledInstallments,
  [InstallmentStatus.Failed]: ViewFailedInstallment,
};

export const ViewInstallmentBase = ({ payment, bill, theme, backPath, installment }: Props) => {
  const Component = viewInstallmentComponent[installment.status];

  return <Component payment={payment} theme={theme} backPath={backPath} bill={bill} installment={installment} />;
};
