import { PropsWithChildren, ReactElement, useEffect, useRef } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import { TableHeaderGroup } from 'src/components/shared/tables/PaymentsTable/components/TableHeaderGroup';
import { TableRow } from 'src/components/shared/tables/PaymentsTable/components/TableRow';
import { Pagination } from 'src/core/components/pagination/Pagination';
import Box from 'src/core/ds/box';
import Table from 'src/core/ds/table';
import { getInitialSortBy } from 'src/pages/get-pro/utils';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { InvoicesDateFilters, PAGE_ITEM_COUNT } from '../../consts';
import { TableContentState } from './consts';
import { useSelection } from './hooks/useSelection';
import { containerStyle } from './tableStyles';
import { DataTableProperties } from './types';

export function DataTable<T extends Record<string, unknown>>(
  props: PropsWithChildren<DataTableProperties<T>>
): ReactElement {
  const {
    columns,
    data,
    onClick,
    onChangeSort,
    totalItems,
    onPageIndexChange,
    hideCheckBoxes = false,
    disableCheckBoxes,
    onSelectionChanged,
    clearSelected,
    toggleClearSelected,
    contentState,
    content,
    showPagination,
    filter,
    action,
    filterRowsByQueryAction,
    setAction,
    actionParams,
    setActionParams,
    tab,
  } = props;
  const hooks = hideCheckBoxes
    ? [useSortBy, usePagination, useRowSelect]
    : [useSortBy, usePagination, useRowSelect, useSelection];

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    toggleAllRowsSelected,
    state: { sortBy, pageIndex, selectedRowIds },
    setSortBy,
    gotoPage,
  } = useTable<T>(
    {
      ...props,
      data,
      columns,
    },
    ...hooks
  );

  const toastId = useRef<string | number | null>(null);
  const initialSortBy = getInitialSortBy(tab);

  useEffect(() => {
    if (clearSelected) {
      toggleAllRowsSelected(false);
      toggleClearSelected && toggleClearSelected();
    }
  }, [clearSelected, toggleAllRowsSelected, toggleClearSelected]);

  useEffect(() => {
    if (initialSortBy) {
      setSortBy(initialSortBy);
    }

    gotoPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (onChangeSort) {
      onChangeSort(sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  useEffect(() => {
    if (onPageIndexChange) {
      onPageIndexChange(pageIndex);
    }
  }, [onPageIndexChange, pageIndex]);

  useEffect(() => {
    if (onSelectionChanged) {
      if (Object.keys(selectedRowIds).length >= PAGE_ITEM_COUNT) {
        pushNotification({
          type: NotificationVariant.INFO,
          msg: 'getPro.pushNotifications.selectLimitExceeded',
        });
      }

      onSelectionChanged(selectedRowIds);
    }
  }, [selectedRowIds, onSelectionChanged]);

  useEffect(() => {
    if (action && contentState !== TableContentState.LOADING) {
      const rowsToSelect = filterRowsByQueryAction({
        rows,
        action,
        actionParams,
      });

      rowsToSelect?.forEach((row) => row.toggleRowSelected(true));
      setAction(undefined);
      setActionParams?.(undefined);

      if (
        rowsToSelect?.length === 0 &&
        filter === InvoicesDateFilters.ALL &&
        !checkIfToastIsDisplayedById(toastId.current)
      ) {
        toastId.current = pushNotification({
          type: NotificationVariant.INFO,
          msg: 'getPro.pushNotifications.invoicesAlreadySent',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, actionParams, rows, contentState]);

  return (
    <Box __css={containerStyle(hideCheckBoxes)}>
      <Table {...getTableProps()} cellPadding="0" cellSpacing="0" data-testid={props['data-testid']}>
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <TableHeaderGroup headerGroup={headerGroup} disableCheckBoxes={disableCheckBoxes} />
          ))}
        </Table.Header>
        {contentState && contentState !== TableContentState.CONTENT ? (
          <Table.Caption>{content}</Table.Caption>
        ) : (
          <Table.Body {...getTableBodyProps()}>
            {rows.map((row) => (
              <TableRow row={row} disableCheckBoxes={disableCheckBoxes} onClick={onClick} prepareRow={prepareRow} />
            ))}
          </Table.Body>
        )}
      </Table>
      {showPagination && (
        <Box ml="auto" my={6} mr={6} float="right">
          <Pagination
            pageIndex={pageIndex}
            totalItems={totalItems}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageItemCount={PAGE_ITEM_COUNT}
          />
        </Box>
      )}
    </Box>
  );
}
