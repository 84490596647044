import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { ButtonVariant } from 'src/utils/consts';

type Props = {
  onButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  buttonLabel: string;
  secondaryButtonLabel: string;
  isLoading?: boolean;
  secondaryButtonVariant: ButtonVariant;
};

export const EditCheckAddressModalButtonRow = ({
  onButtonClick,
  onSecondaryButtonClick,
  buttonLabel,
  secondaryButtonLabel,
  isLoading,
  secondaryButtonVariant,
}: Props) => (
  <Flex textAlign="center" justify="flex-end">
    <Button
      onClick={onSecondaryButtonClick}
      label={secondaryButtonLabel}
      variant={ButtonVariants[secondaryButtonVariant]}
      size={ButtonSizes.lg}
      isLoading={isLoading}
      mr={ButtonVariants[secondaryButtonVariant] === ButtonVariants.tertiaryNaked ? '0' : '5'}
    />
    <Button onClick={onButtonClick} label={buttonLabel} size={ButtonSizes.lg} isLoading={isLoading} />
  </Flex>
);
