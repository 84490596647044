import { AccountType, BillType, PaymentFinancing } from 'src/utils/types';
import { useFinancedPaymentRecoveryFlows } from '../../../hooks/useFinancedPaymentRecoveryFlows';
import { InstallmentDeductionDateInfo } from './InstallmentDeductionDateInfo';
import { InstallmentsFundingType } from './InstallmentsFundingType';
import { RepaymentFundingSourceInfo } from './RepaymentFundingSourceInfo';

type Props = {
  isFinancingLoading: boolean;
  isEditDisabled: boolean;
  bill: BillType;
  financing: PaymentFinancing;
  fundingSource: AccountType;
  onEditFundingSource: () => void;
  onEditDate: () => void;
};

export const FinancingPaymentMethodSection = ({
  onEditFundingSource,
  onEditDate,
  isFinancingLoading,
  isEditDisabled,
  bill,
  financing,
  fundingSource,
}: Props) => {
  const { isFinancedPaymentRecoveryFlow } = useFinancedPaymentRecoveryFlows();
  const canEdit = !isFinancedPaymentRecoveryFlow;

  return (
    <>
      <InstallmentsFundingType
        onEdit={onEditFundingSource}
        isEditDisabled={isEditDisabled}
        installments={financing.installments}
        canEdit={canEdit}
      />
      <InstallmentDeductionDateInfo
        onEdit={onEditDate}
        canEdit={canEdit}
        financing={financing}
        isLoading={isFinancingLoading}
        isEditDisabled={isEditDisabled}
      />
      <RepaymentFundingSourceInfo bill={bill} fundingSource={fundingSource} />
    </>
  );
};
