import { UpsellType } from 'src/modules/payments/api-types';

export const FAST_DELIVERY_EVENT_NAMES = {
  OPTIONS_PAGE_VIEW: 'view',
  SELECT_OPTION: 'selected',
  SUCCESS_PAGE_VIEW: 'payment-success',
  JOIN_MELIO: 'join_melio_click',
  OFFER_EXPIRED: 'offer_expired',
} as const;

export const FAST_DELIVERY_SERVER_EVENT_NAMES = {
  OPTIONS_PAGE_VIEW: 'P2FAST-VendorOpened',
  OFFER_EXPIRED: 'P2FAST-VendorOfferExpired',
  SELECT_OPTION: 'P2FAST-VendorOptionSelected',
  SUCCESS_PAGE_VIEW: 'P2FAST-VendorPaymentSuccess',
} as const;

type AnalyticsOptionName = 'fast-ach' | 'debit-card' | 'rtp';

export const upsellTypeToAnalyticsOptionName: Record<UpsellType, AnalyticsOptionName> = {
  [UpsellType.PUSH_TO_FAST_ACH]: 'fast-ach',
  [UpsellType.PUSH_TO_DEBIT]: 'debit-card',
  [UpsellType.PUSH_TO_RTP]: 'rtp',
};
