import keyAnimation from 'src/animations/accountingSoftware/sync-qbd/license-key.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import CopyQBDLicenseKey from './components/CopyQBDLicenseKey';
import { getQBDRelativeStep } from './utils';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onExit: () => void;
};

const SyncQBDLicensePage = ({ onNext, onPrev, onExit }: Props) => {
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const externalId = connectedAccountingPlatform?.externalId || '';

  return (
    <StepLayoutPage
      title="settings.newAccountingSoftware.QuickbooksDesktopSync.license.title"
      subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.license.subtitle"
      nextLabel="progress.next"
      goExit={onExit}
      onNext={onNext}
      onPrev={onPrev}
      relativeStep={getQBDRelativeStep(2)}
      displaySupportLink
      animatedIllustration={{
        animationData: keyAnimation,
        loop: false,
      }}
    >
      <CopyQBDLicenseKey licenseKey={externalId} />
    </StepLayoutPage>
  );
};

export default SyncQBDLicensePage;
