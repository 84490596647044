import { createContext, useContext, useState } from 'react';
import * as React from 'react';
import { InternationalPaymentPurpose } from 'src/pages/vendor/international-delivery-method/consts';
import { BankDetailsType } from 'src/pages/vendor/international-delivery-method/utils';
import { Currency } from 'src/utils/consts';
import { GoogleCombinedAddressType } from 'src/utils/types';

export type InternationalWizardState = {
  bankCountry?: string | null;
  currency?: Currency | null;
  iban?: string;
  swift?: string;
  accountNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string | null;
  fullName?: string;
  state?: string;
  zipCode?: string;
  companyName?: string;
  purpose?: InternationalPaymentPurpose | null;
  purposeDescription?: string;
  googleCombinedAddress?: GoogleCombinedAddressType;
  details?: BankDetailsType | null;
  bankName?: string;
  countryRiskLevel?: string | null;
  invoiceAttachment?: boolean | null;
};

type InternationalWizardContextType = {
  wizardState: InternationalWizardState;
  setWizardState: React.Dispatch<React.SetStateAction<InternationalWizardState>>;
};

const InternationalWizardContext = createContext<InternationalWizardContextType | undefined>(undefined);

export const useInternationalWizard = () => {
  const context = useContext(InternationalWizardContext);

  if (context === undefined) {
    throw new Error('useInternationalWizard must be used within a InternationalWizardContextProvider');
  }

  return context;
};

export const InternationalWizardProvider = ({ children }) => {
  const [wizardState, setWizardState] = useState<InternationalWizardState>({
    currency: Currency.USD,
  });

  return (
    <InternationalWizardContext.Provider value={{ wizardState, setWizardState }}>
      {children}
    </InternationalWizardContext.Provider>
  );
};
