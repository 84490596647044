import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { filesApi } from 'src/modules/files/api';
import { getOrgId } from 'src/redux/user/selectors';
import { getPaymentRequestIdById, isPaymentRequest } from 'src/utils/bills';
import { capture } from 'src/utils/error-tracking';
import { FileUrlsPayloadType } from 'src/utils/types';

export const useGetFileUrls = (
  fileId?: string | null,
  objectId?: string
): [FileUrlsPayloadType | null | undefined, boolean] => {
  const orgId = useSelector(getOrgId);
  const isPaymentRequestObject = objectId && isPaymentRequest(objectId);

  const { onApiCall: loadFile, result: fileUrls, loading } = useApi({
    api: isPaymentRequestObject ? filesApi.fetchFileUrlsByPaymentRequest : filesApi.fetchFileUrls,
  });

  useEffect(() => {
    if (fileId && loadFile && orgId) {
      loadFile(orgId, fileId, isPaymentRequestObject && objectId ? getPaymentRequestIdById(objectId) : undefined).catch(
        (error) => {
          capture(error, { fileId });
        }
      );
    }
  }, [fileId, orgId, loadFile]);

  return [fileUrls, loading];
};
