import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { ShiftToAchUpsale } from 'src/pages/vendor/shift-vendor-to-ach/ShiftToAchUpsale';
import { ShiftToDebitState } from 'src/pages/vendor/shift-vendor-to-debit/hoc/shift-to-debit-hooks';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';

type Props = {
  state: ShiftToDebitState;
  token: string;
};

export const CheckOfferExpiredPage = ({ state, token }: Props) => {
  const { payment, organization } = state;

  return (
    <OutsideLayout hideLogin>
      <Box p={10}>
        <Icon name={IconNames.declineFill} color="red.500" size={IconSize.xl} />
        <Box textStyle="body2Semi" mt={5} mb={1}>
          <MIFormattedText label="vendors.editCheckAddress.checkOfferExpired.title" />
        </Box>
        <Box textStyle="body2">
          <MIFormattedText
            label="vendors.editCheckAddress.checkOfferExpired.subtitle"
            values={{
              totalAmount: <MIFormattedCurrency value={payment?.amount} />,
              companyName: organization?.companyName,
              invoiceNumber: payment?.bill?.invoiceNumber,
            }}
          />
        </Box>
        <ConvertedUnilateralPaymentActivity payment={payment} />
      </Box>
      <ShiftToAchUpsale
        payment={payment}
        token={token}
        organization={organization}
        labelPrefix="vendors.editCheckAddress.checkOfferExpired"
      />
    </OutsideLayout>
  );
};
