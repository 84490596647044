import { useSelector } from 'react-redux';
import { useCanSwitchCompanies } from 'src/hooks/useCanSwitchCompanies';
import { profileStore } from 'src/modules/profile/profile-store';
import { useIsAccountingFirmSelected } from 'src/pages/team/team-management/hooks/useIsAccountingFirmSelected';

export function useCanViewCompaniesScreen(): boolean {
  const { isAccountingFirmSelected } = useIsAccountingFirmSelected();
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const canSwitchCompanies = useCanSwitchCompanies();

  return (!isMemberOfAccountingFirm && canSwitchCompanies) || (isMemberOfAccountingFirm && isAccountingFirmSelected);
}
