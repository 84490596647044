import { generatePath } from 'react-router-dom';
import { MICreateOption } from 'src/components/common/MICreateOption';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { CSSObject } from 'src/core/ds';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import syncQuickbooks from 'src/images/onboarding/green-to-purple.svg';
import { globalLocations } from 'src/pages/locations';
import { CompanyType, RegistrationOrigin } from 'src/utils/consts';
import intuit from 'src/utils/intuit';

type Props = {
  onNext: () => void;
  onPrev: () => void;
};

export const QuickbookMultiOrgOnboardingConnect = ({ onNext, onPrev }: Props) => {
  const onNoConnect = () => {
    onNext();
  };

  const onConnect = () => {
    intuit.goConnectToIntuit({
      registrationOrigin: RegistrationOrigin.ADD_COMPANY,
      intuitReturnUrl: generatePath(globalLocations.quickbookMultiOrgOnboarding.quickbooksSuccess),
      intuitErrorReturnUrl: generatePath(globalLocations.quickbookMultiOrgOnboarding.quickbooksError),
      companyInfo: {
        companyType: CompanyType.ACCOUNTING_FIRM,
      },
      switchAccountingFirm: true,
    });
  };

  return (
    <StepLayoutPage
      title="multiOrgOnboarding.connectQuickbooks.title"
      subtitle="multiOrgOnboarding.connectQuickbooks.subtitle"
      relativeStep={2 / 5}
      onPrev={onPrev}
    >
      <Flex direction="column" justify="center">
        <QuickbookOption
          imgSrc={syncQuickbooks}
          label="multiOrgOnboarding.connectQuickbooks.connect"
          onClick={onConnect}
          eventPage="sync-your-firm"
        />
        <Button
          label="multiOrgOnboarding.connectQuickbooks.no"
          onClick={onNoConnect}
          variant={ButtonVariants.tertiaryNaked}
          size={ButtonSizes.lg}
        />
      </Flex>
    </StepLayoutPage>
  );
};

type QuickbookOptionProps = {
  imgSrc?: string;
  label: string;
  onClick: () => void;
  eventPage: string;
};

const QuickbookOption = (props: QuickbookOptionProps) => (
  <MICreateOption
    {...props}
    sxForCreateOptionTextWrapper={sxForCreateOptionTextWrapper}
    sxForCreateOptionWrapper={sxForCreateOptionWrapper}
  />
);

const sxForCreateOptionWrapper: CSSObject = {
  flexDir: 'column',
  py: 6,
  mb: 1,
  '> img': {
    h: 5,
    w: 16,
    mb: 4,
  },
};

const sxForCreateOptionTextWrapper: CSSObject = {
  '> div': {
    textStyle: 'body3Semi',
  },
  ml: 0,
};
