import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  borderColor: 'grey.500',
  borderStyle: 'solid',
};

const sizes = {
  sm: {
    borderWidth: '0.1rem',
  },
  md: {
    borderWidth: '0.2rem',
  },
};

const defaultProps = {
  size: 'sm',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
