import styled from 'styled-components';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { melioClose } from 'src/utils/external-events';

export const MCBClose = () => (
  <CloseAction onClick={melioClose}>
    <Icon name={IconNames.close} size={IconSize.lg} />
  </CloseAction>
);

const CloseAction = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-size: 2.4rem;
  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }
`;
