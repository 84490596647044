import { Radio as ChakraRadio, RadioProps } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { VStack } from 'src/core/ds/stack';
import { analytics } from 'src/services/analytics';

export type Props = Omit<RadioProps, 'variant'> & {
  /** Specifies the value of the Radio */
  value: string | number;
  /** Specifies the label of the Radio */
  label?: string | undefined;
  /** Specifies the dynamic values of the label  */
  values?: Record<string, any>;
  /** Specifies the name of the group that the radio belongs to */
  groupName?: string;
  secondaryLabel?: string;
  secondaryValues?: Record<string, any>;
  testId?: string;
};

export const Radio = ({
  label,
  value,
  values,
  isDisabled,
  groupName,
  secondaryLabel,
  secondaryValues,
  children,
  testId,
  ...rest
}: Props) => {
  const dataTestId = `radio-button-${testId || label || value}`;

  const handleChange = () => {
    !isDisabled && analytics.trackAction(`option-selected-${groupName}`, { label, value });
  };

  return (
    <Box data-testid={dataTestId}>
      <ChakraRadio value={value} isDisabled={isDisabled} {...rest} spacing={2} onChange={handleChange}>
        <VStack spacing={1} alignItems="start">
          {label ? (
            <Box>
              <MIFormattedText label={label} values={values} />
            </Box>
          ) : null}
          {children}
          {secondaryLabel && (
            <Box textStyle="body4" color="grey.700">
              <MIFormattedText label={secondaryLabel} values={secondaryValues} />
            </Box>
          )}
        </VStack>
      </ChakraRadio>
    </Box>
  );
};
