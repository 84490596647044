import React, { useEffect, useRef } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames } from 'src/core/ds/icon';
import { HStack, VStack } from 'src/core/ds/stack';
import { useApi } from 'src/hoc/useApi';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { useAccountingSoftwareSyncActions } from 'src/pages/settings/hooks/useAccountingSoftwareSyncActions';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';

type Props = {
  dismiss: (event?: React.MouseEvent) => void;
  accountingSoftwareType: string;
  orgId: number;
};

export const OptInModal = ({ dismiss, accountingSoftwareType, orgId }: Props) => {
  const toastId = useRef<null | number>(null);
  const { fetch: fetchAccountingSoftware } = useAccountingSoftwareSyncActions(accountingSoftwareType);
  const { onApiCall: handleOptIn, result: results, loading: isLoading, error } = useApi({
    api: async () => accountingPlatformsApi.optInInvoiceGeneration({ orgId }).then(fetchAccountingSoftware),
    throwError: false,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (results) {
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'settings.accountingSoftware.invoiceGeneration.updateSuccess',
      });
      dismiss();
    } else if (error && !checkIfToastIsDisplayedById(toastId.current)) {
      toastId.current = pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'settings.accountingSoftware.invoiceGeneration.updateFailed',
      });
    }
  }, [results, isLoading, error]);

  return (
    <ModalMessage
      alignLeft
      id="opt-in-modal"
      titleComponent={<MIFormattedText label="settings.accountingSoftware.invoiceGeneration.optInModal.title" />}
      contentComponent={
        <VStack spacing={4}>
          <Box textStyle="body2">
            <MIFormattedText label="settings.accountingSoftware.invoiceGeneration.optInModal.body" />
          </Box>
          <HStack spacing={3} alignItems="start">
            <Box>
              <Icon name={IconNames.infoCircle} color="black" />
            </Box>
            <Box textStyle="caption1Semi" color="grey.700">
              <MIFormattedText label="settings.accountingSoftware.invoiceGeneration.optInModal.disclaimer" />
            </Box>
          </HStack>
        </VStack>
      }
      footerSubTitle={
        <HStack spacing={3} justify="flex-end" width="100%">
          <Button
            variant={ButtonVariants.tertiaryNaked}
            label="settings.accountingSoftware.invoiceGeneration.optInModal.cancelAction"
            onClick={dismiss}
            disabled={isLoading}
          />
          <Button
            variant={ButtonVariants.primary}
            label="settings.accountingSoftware.invoiceGeneration.optInModal.acceptAction"
            onClick={handleOptIn}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </HStack>
      }
    />
  );
};
