import pick from 'lodash/pick';
import { FormRow } from 'src/core/ds/form';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { ApplicantHeader } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/ApplicantHeader';
import { DateOfBirthField } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/common-fields/DateOfBirthField';
import { OwnershipPercentageField } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/common-fields/OwnershipPercentageField';
import { SSNField } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/common-fields/SSNField';
import { OwnerLegalAddressFormRows } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/main-owner/legal-address/OwnerLegalAddressFormRows';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { ModelView, ValidationErrors } from 'src/ui/form';

type Props = {
  modelView: ModelView<InternalBusinessOwnershipDetailsModel>;
  validationErrors: ValidationErrors<InternalBusinessOwnershipDetailsModel>;
};

export const MainOwnerSubForm = ({ modelView, validationErrors }: Props) => (
  <>
    <FormRow>
      <ApplicantHeader
        headerLabel="financing.businessOwnershipDetails.applicant.mainOwner.title"
        tooltipLabel="financing.businessOwnershipDetails.applicant.mainOwner.tooltip"
      />
    </FormRow>
    <FormRow>
      <TextField
        testId="input-mainOwner-firstName"
        label="financing.businessOwnershipDetails.fields.firstName.title"
        errorMessage={validationErrors.firstName}
        isRequired
        mb={0}
        model={modelView.firstName}
      />
      <TextField
        testId="input-mainOwner-lastName"
        label="financing.businessOwnershipDetails.fields.lastName.title"
        errorMessage={validationErrors.lastName}
        isRequired
        mb={0}
        model={modelView.lastName}
      />
    </FormRow>
    <FormRow>
      <TextField
        testId="input-mainOwner-email"
        label="financing.businessOwnershipDetails.fields.email.title"
        errorMessage={validationErrors.email}
        isRequired
        mb={0}
        model={modelView.email}
      />
    </FormRow>
    <FormRow>
      <OwnershipPercentageField
        testId="input-mainOwner-ownershipPercentage"
        modelViewField={modelView.ownershipPercentage}
        errorMessage={validationErrors.ownershipPercentage}
      />
    </FormRow>
    <FormRow>
      <DateOfBirthField
        testId="input-mainOwner-dateOfBirth"
        modelViewField={modelView.dateOfBirth}
        errorMessage={validationErrors.dateOfBirth}
      />
      <SSNField modelViewField={modelView.ssn} testId="input-mainOwner-ssn" errorMessage={validationErrors.ssn} />
    </FormRow>
    <FormRow>
      <MaskField
        testId="input-mainOwner-phone"
        label="financing.businessOwnershipDetails.fields.phone.title"
        placeholder="financing.businessOwnershipDetails.fields.phone.placeholder"
        errorMessage={validationErrors.phone}
        isRequired
        format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
        mb={0}
        model={modelView.phone}
        isModelOnChangeFormatted
        type="tel"
      />
    </FormRow>
    <OwnerLegalAddressFormRows
      businessOwnershipDetailsModelView={modelView}
      errorMessages={pick(validationErrors, ['addressLine1', 'city', 'state', 'zipCode'])}
    />
  </>
);
