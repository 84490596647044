import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useBillSubRowAnalytics } from 'src/pages/regular-batch-payments/analytics/BatchPaymentsAnalytics';
import { ActionsCellButton } from 'src/pages/regular-batch-payments/table/components/ActionsCell/ActionCellButton';
import { useRemoveBillSubRow } from '../../../hooks/removeBillSubRow';

type Props = {
  rowHovered: boolean;
  itemId: string;
  balance: number;
};

export const RemoveSubRow = ({ rowHovered, itemId, balance }: Props) => {
  const { removeSubRow, updateDeliveryOptions, isLoading: isUpdateDeliveryOptionsLoading } = useRemoveBillSubRow(
    itemId,
    balance
  );
  const { trackRemoveFromListAction } = useBillSubRowAnalytics(itemId);

  const removeFromList = async () => {
    await removeSubRow();
    await updateDeliveryOptions();
    trackRemoveFromListAction();
  };

  return (
    <ActionsCellButton
      showOnHover
      rowHovered={rowHovered}
      icon={<Icon name={IconNames.close} size={IconSize.m} />}
      label="regularBatchPayments.cells.actions.removeFromList"
      onClick={removeFromList}
      isDisabled={isUpdateDeliveryOptionsLoading}
    />
  );
};
