import { AmexNotificationDetails, AmexStatusEnum } from '@melio/spend-management-api-axios-client';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import {
  AccountsListParams,
  UseAccountsActionOptions,
  UseAccountsOptions,
} from 'src/modules/spend-management/accounts/types';
import { getOrgId } from 'src/redux/user/selectors';
import { accountsApi } from '../api';

export const isSuccessfulEnrollment = (acc: AmexNotificationDetails) =>
  !acc.errorCode && acc.currentStatus === AmexStatusEnum.Active;
export const isFailedEnrollment = (acc: AmexNotificationDetails) => !!acc.errorCode;

export const useAccounts = ({ requestOnInit = true }: UseAccountsOptions) => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: getAccounts, result, loading, error } = useApi<[AccountsListParams], AmexNotificationDetails[]>({
    api: accountsApi.list,
  });

  useEffect(() => {
    if (requestOnInit) getAccounts({ orgId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    error,
    accounts: result,
    refetch: ({ clientRequestId }: UseAccountsActionOptions) => getAccounts({ orgId, clientRequestId }),
  };
};
