import capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import invitationsStore from 'src/modules/invitations/invitations-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';

type Props = {
  onNext: (orgId: number, isMemberOfAccountingFirm: boolean) => void;
};

const VOWELS_REGEX = /[aeiou]/;

export const AcceptInvitationSuccess = ({ onNext }: Props) => {
  const invitation = useSelector(invitationsStore.selectors.validationInvitation);
  const organization = useSelector(organizationStore.selectors.byId(invitation?.organizationId));
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const role = invitation?.role || '';

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.checkCircle}
      title="auth.acceptInvitationSuccess.title"
      text="auth.acceptInvitationSuccess.subtitle"
      textValues={{
        companyName: organization?.companyName,
        role: `${VOWELS_REGEX.test(role[0]) ? 'an' : 'a'} ${capitalize(role)}`,
      }}
      buttonLabel="auth.acceptInvitationSuccess.buttonLabel"
      buttonAction={() => onNext(organization.id, isMemberOfAccountingFirm)}
    />
  );
};
