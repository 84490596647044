import { Image } from 'src/core/ds/image';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  currentPage: number;
  fileStorageUrl?: string | null;
  filePreviewUrls?: string[] | null;
};

export const ImageView = ({ currentPage, filePreviewUrls, fileStorageUrl }: Props) => {
  const src = filePreviewUrls && filePreviewUrls.length ? filePreviewUrls[currentPage - 1] : fileStorageUrl;

  return (
    <>
      <Image
        src={src as string}
        className={FULL_STORY_MASK_RULE_CLASS}
        borderRadius="lg"
        maxHeight="full"
        maxWidth="full"
        objectFit="scale-down"
      />
    </>
  );
};
