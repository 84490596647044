import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OrSeparator } from 'src/core/components/orSeparator';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';

export const SuccessfullyEnrolledEmptyState = ({
  onInstallExtensionClick,
  onAddCardClick,
}: {
  onInstallExtensionClick: () => void;
  onAddCardClick: () => void;
}) => (
  <VStack justifyContent="center" alignItems="center" w="full">
    <Flex direction="column" alignItems="center" justifyContent="center" maxW="50rem">
      <Box mb={4} textAlign="center">
        <video
          data-component="Video"
          autoPlay
          muted
          src="https://platform-static.meliopayments.com/assets/melio/illustrations/success.webm"
          width="144px"
        >
          <track kind="captions" />
        </video>
      </Box>
      <Box textStyle="body2Semi" textAlign="center">
        <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.successfullyEnrolledEmptyState.title" />
      </Box>
      <Box mb={4} textAlign="center" textStyle="body3">
        <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.successfullyEnrolledEmptyState.subtitle" />
      </Box>
      <Flex justifyContent="center" w="full">
        <Button
          label="spendManagement.pages.dashboard.emptyStates.successfullyEnrolledEmptyState.buttonLabel"
          variant={ButtonVariants.primary}
          onClick={onAddCardClick}
          isFullWidth
        />
      </Flex>
      <Box my={4} w="full">
        <OrSeparator size="sm" color="grey.400" />
      </Box>
      <Flex justifyContent="center" w="full">
        <Button
          label="spendManagement.pages.dashboard.emptyStates.successfullyEnrolledEmptyState.secondaryButtonLabel"
          variant={ButtonVariants.tertiaryNaked}
          onClick={onInstallExtensionClick}
          isFullWidth
        />
      </Flex>
    </Flex>
  </VStack>
);
