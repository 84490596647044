import { useState } from 'react';
import { UseWizardArgs } from 'src/hooks/useWizard/useWizard';
import { useWizardSimple } from 'src/hooks/useWizard/useWizardSimple';
import { RefundReason } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';
import { RequestRefundReason } from './RequestRefundReason';
import { RequestRefundReview } from './RequestRefundReview';
import { RequestRefundStepPropsType, Steps } from './types';

export const locationsMap: UseWizardArgs<Steps>['locationsMap'] = {
  'request-refund-reason': 'reason',
  'request-refund-review': 'review',
};

type RenderCurrentStepProps = {
  currentStep: Steps;
  props: RequestRefundStepPropsType;
};

const renderCurrentStep = ({ currentStep, props }: RenderCurrentStepProps) => {
  switch (currentStep) {
    case 'request-refund-reason':
      return <RequestRefundReason {...props} />;
    case 'request-refund-review':
      return <RequestRefundReview {...props} selectedReason={props.selectedReason!} />;
    default:
      return null;
  }
};

type props = {
  payment: PaymentType;
};

export const RequestRefund = ({ payment }: props) => {
  const [selectedReason, setSelectedReason] = useState<RefundReason | null>(null);

  const { cancelFlow, goBack, goNext, currentStep, completeFlow } = useWizardSimple<Steps>({
    firstStep: 'request-refund-reason',
    flowName: 'request-refund',
    locationsMap,
    navigationSequence: ['request-refund-reason', 'request-refund-review'],
    cancelUrlFallback: '/',
  });

  return renderCurrentStep({
    currentStep,
    props: { payment, cancelFlow, goBack, goNext, selectedReason, setSelectedReason, completeFlow },
  });
};
