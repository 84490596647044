import { forwardRef } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { IconNames } from 'src/core/ds/icon';
import { IconInput } from 'src/core/ds/iconInput';
import { Input, InputGroup, InputProps, InputRightElement } from 'src/core/ds/input';
import { useBreak } from 'src/hoc';
import { DateFormats } from 'src/utils/date-fns';
import { dateInputStyle } from './styles';
import { getFormattedDate } from './utils';

export type DateInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  value?: Date | null;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  dateFormat?: DateFormats;
  minDate?: string;
  maxDate?: string;
  withIcon?: boolean;
};

export const DateInput = forwardRef<DateInputProps, 'input'>(
  (
    {
      value,
      isDisabled,
      dateFormat = DateFormats.monthShortWithDateAndYear,
      onChange,
      minDate,
      maxDate,
      withIcon,
      size,
      ...rest
    }: DateInputProps,
    ref
  ) => {
    const device = useBreak();
    const isMobile = device?.isMobile;
    const format = isMobile ? DateFormats.isoDate : dateFormat;
    const inputValue = getFormattedDate(value, format) || '';
    const inputType = isMobile ? 'date' : 'text';

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      !isDisabled && onChange && onChange(e);
    };

    return (
      <InputGroup>
        <Input
          {...rest}
          ref={ref}
          size={size}
          type={inputType}
          isDisabled={isDisabled}
          onChange={handleChange}
          value={inputValue}
          min={minDate}
          max={maxDate}
          sx={dateInputStyle}
          data-date-input
        />
        {withIcon && !isMobile && (
          <InputRightElement bottom="px" pointerEvents="none" top={0} h="inherit">
            <IconInput size={size} name={IconNames.calendar} isDisabled={isDisabled} />
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);
