export const ACTION_NOTIFICATION_MAPPING = {
  '[APPROVALWORKFLOWS] CREATE_SUCCESS': {
    type: 'success',
    msg: 'settings.approvalWorkflows.page.notifications.create.success',
  },
  '[APPROVALWORKFLOWS] CREATE_FAILURE': {
    type: 'error',
    msg: 'settings.approvalWorkflows.page.notifications.create.failure',
  },
  '[APPROVALWORKFLOWS] UPDATE_SUCCESS': {
    type: 'success',
    msg: 'settings.approvalWorkflows.page.notifications.update.success',
  },
  '[APPROVALWORKFLOWS] UPDATE_FAILURE': {
    type: 'error',
    msg: 'settings.approvalWorkflows.page.notifications.update.failure',
  },
  '[APPROVALWORKFLOWS] DELETE_SUCCESS': {
    type: 'info',
    msg: 'settings.approvalWorkflows.page.notifications.delete.success',
  },
  '[APPROVALWORKFLOWS] DELETE_FAILURE': {
    type: 'error',
    msg: 'settings.approvalWorkflows.page.notifications.delete.failure',
  },
};
