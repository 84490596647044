import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import UploadCompanyLogo from 'src/components/layout/UploadCompanyLogo';
import { UploadPictureLayout } from 'src/components/layout/UploadPictureLayout';
import Box from 'src/core/ds/box';

type Props = {
  editable: boolean;
};

export const CompanyLogo = ({ editable }: Props) => (
  <MICard>
    <MICardForm>
      <Box mb={6}>
        <MICardTitle label="settings.company.logo.title" />
      </Box>
      <UploadPictureLayout helpTextLabel="settings.company.logo.helpText">
        <UploadCompanyLogo eventPage="company-logo" editable={editable} />
      </UploadPictureLayout>
    </MICardForm>
  </MICard>
);
