import { useMatchAccountMessage } from 'src/hooks/get-paid/useMatchAccountMessage';
import { useShowVerifyMessage } from 'src/hooks/get-paid/useShowVerifyMessage';
import { useShowMtlOrganizationMigrationMessage } from 'src/pages/get-pro/hooks/useMtlOrganizationValidation';
import { useShowAccountingSoftwareMigrationMessage } from 'src/pages/get-pro/hooks/useShowAccountingSoftwareMigrationMessage';
import { useShowInvoiceGenerationMessage } from 'src/pages/get-pro/hooks/useShowInvoiceGenerationMessage';
import Message from './components/Message';

const Messages = () => {
  const {
    title: accountingSoftwareMigrationTitle,
    description: accountingSoftwareMigrationDescription,
    action: accountingSoftwareMigrationAction,
    onClick: onClickAccountingSoftwareMigration,
    onClose: onCloseAccountingSoftwareMigration,
    openMessage: accountingSoftwareMigrationOpenMessage,
    shouldShowAccountingSoftwareMigration,
  } = useShowAccountingSoftwareMigrationMessage();

  const {
    title: verifyTitle,
    description: verifyDescription,
    action: verifyAction,
    onClick: verifyOnClick,
    onClose: verifyOnClose,
    openMessage: verifyOpenMessage,
    shouldShowVerifyBankAccount,
    dialog,
  } = useShowVerifyMessage({});

  const {
    title: matchAccountMessageTitle,
    description: matchAccountMessageDescription,
    action: matchAccountMessageAction,
    onClick: onClickMatchAccountMessage,
    onClose: onCloseMatchAccountMessage,
    openMessage: matchAccountOpenMessage,
    shouldShowMatchBankAccount,
    accountingSoftwareName: accountingSoftware,
    testId: matchAccountTestId,
  } = useMatchAccountMessage();

  const {
    title: invoiceGenerationMessageTitle,
    description: invoiceGenerationMessageDescription,
    action: invoiceGenerationMessageAction,
    badge: invoiceGenerationMessageBadge,
    onClick: invoiceGenerationOnClick,
    onClose: invoiceGenerationOnClose,
    openMessage: invoiceGenerationOpenMessage,
    shouldShowInvoiceGenerationMessage,
    testId: invoiceGenerationTestId,
  } = useShowInvoiceGenerationMessage();

  const {
    title: mtlOrganizationMigrationTitle,
    description: mtlOrganizationMigrationDescription,
    action: mtlOrganizationMigrationAction,
    onClick: mtlOrganizationMigrationOnClick,
    onClose: mtlOrganizationMigrationOnClose,
    shouldShowMtlOrganizationMigrationMessage,
    testId: mtlOrganizationMigrationTestId,
  } = useShowMtlOrganizationMigrationMessage();

  if (shouldShowAccountingSoftwareMigration && accountingSoftwareMigrationOpenMessage) {
    return (
      <Message
        title={accountingSoftwareMigrationTitle}
        description={accountingSoftwareMigrationDescription}
        action={accountingSoftwareMigrationAction}
        onClick={onClickAccountingSoftwareMigration}
        onClose={onCloseAccountingSoftwareMigration}
      />
    );
  }

  if (shouldShowVerifyBankAccount && verifyOpenMessage) {
    return (
      <>
        {dialog}
        <Message
          title={verifyTitle}
          description={verifyDescription}
          action={verifyAction}
          onClick={verifyOnClick}
          onClose={verifyOnClose}
        />
      </>
    );
  }

  if (shouldShowMatchBankAccount && matchAccountOpenMessage) {
    return (
      <Message
        title={matchAccountMessageTitle}
        titleValues={{ accountingSoftware }}
        description={matchAccountMessageDescription}
        descriptionValues={{ accountingSoftware }}
        action={matchAccountMessageAction}
        actionValues={{ accountingSoftware }}
        onClick={onClickMatchAccountMessage}
        onClose={onCloseMatchAccountMessage}
        testId={matchAccountTestId}
      />
    );
  }

  if (shouldShowInvoiceGenerationMessage && invoiceGenerationOpenMessage) {
    return (
      <Message
        title={invoiceGenerationMessageTitle}
        description={invoiceGenerationMessageDescription}
        action={invoiceGenerationMessageAction}
        testId={invoiceGenerationTestId}
        badge={invoiceGenerationMessageBadge}
        onClick={invoiceGenerationOnClick}
        onClose={invoiceGenerationOnClose}
      />
    );
  }

  if (shouldShowMtlOrganizationMigrationMessage) {
    return (
      <Message
        title={mtlOrganizationMigrationTitle}
        description={mtlOrganizationMigrationDescription}
        action={mtlOrganizationMigrationAction}
        testId={mtlOrganizationMigrationTestId}
        onClick={mtlOrganizationMigrationOnClick}
        onClose={mtlOrganizationMigrationOnClose}
      />
    );
  }

  return null;
};

export { Messages };
