import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { defaultFormatters } from './defaultFormatters';

export type TextProps = {
  label?: string;
  values?: Record<string, string | ReactNode | unknown>;
};

type MIFormattedTextProps = React.PropsWithChildren<
  TextProps & {
    privateData?: boolean;
  }
>;

export const MIFormattedText = ({ label, values = {}, children, privateData }: MIFormattedTextProps) => {
  if (!label) {
    return null;
  }

  const text = privateData ? <span className={FULL_STORY_MASK_RULE_CLASS}>{children}</span> : children;

  return (
    <FormattedMessage id={label} values={{ ...values, ...defaultFormatters }}>
      {text as any}
    </FormattedMessage>
  );
};
