import * as React from 'react';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { AddDeliveryMethodMenuItem } from 'src/pages/regular-batch-payments/components/deliveryMethod/AddDeliveryMethodMenuItem';
import { useCreateBatchPayments } from 'src/pages/regular-batch-payments/hooks/useCreateBatchPayments';

type Props = {
  onAddDeliveryMethod: () => void;
};

export const EmptyDeliveryMethodCell: React.FC<Props> = ({ onAddDeliveryMethod }) => {
  const {
    createBatchPaymentData: { error: showWarningIcon },
  } = useCreateBatchPayments();

  return (
    <Flex align="center" justify="space-between" h="full">
      <AddDeliveryMethodMenuItem onClick={onAddDeliveryMethod} label="regularBatchPayments.addDeliveryMethod" />
      {showWarningIcon && <Icon color="yellow.500" name={IconNames.warning} size={IconSize.s} />}
    </Flex>
  );
};
