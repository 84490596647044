import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MIDropDown } from 'src/components/common/DropDown/MIDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { BankAccountOption } from 'src/pages/bill/pay/installments/pages/components/BankAccountOption';
import { useBankAccountDropDownOptions } from 'src/pages/bill/pay/installments/pages/repayment-terms/hooks/useBankAccountDropDownOptions';
import { selectRepaymentAction } from 'src/redux/payBillWizard/actions';
import { getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { getBankAccounts } from 'src/redux/user/selectors';
import { FundingType } from 'src/utils/consts';
import { AccountType, Expandable } from 'src/utils/types';
import { DropdownFundingSourceIcon } from './components/DropdownFundingSourceIcon';
import { RepaymentDeductionTooltip } from './components/RepaymentDeductionTooltip';
import { RepaymentMethodSelectedOption } from './components/RepaymentMethodSelectedOption';

type Props = {
  isDisabled: boolean;
};

export const RepaymentTermsFundingSource = ({ isDisabled }: Props) => {
  const dispatch = useDispatch();
  const repaymentMethodOptions = useBankAccountDropDownOptions();
  const bankAccounts = useSelector(getBankAccounts).filter((acc) => acc.isVerified);
  const repaymentMethod = useSelector(getSelectedFundingSource) as AccountType;
  const [isEdit, setIsEdit] = useState(false);
  const isEditEnabled = repaymentMethodOptions.length > 1 && !isDisabled;
  const getBankAccountById = (id: number): number => {
    const bankAccount = bankAccounts.find((acc) => acc.id === id);

    return bankAccount ? bankAccount.id : -1;
  };
  const onRepaymentMethodChange = (id) => {
    dispatch(selectRepaymentAction(getBankAccountById(id)));
  };
  const renderOption = (option) => {
    if (option.repaymentMethod.fundingType !== FundingType.ACH) return null;

    return <BankAccountOption account={option.repaymentMethod} />;
  };

  const repaymentMethodChangeHandler = (option: Expandable<{ value: number }>) => {
    onRepaymentMethodChange(option.value);
    setIsEdit(false);
  };

  return (
    <Box width="full">
      <Flex w="full" flexDirection="row" justifyContent="space-between">
        <Box textStyle="body4Semi">
          <MIFormattedText
            label="financing.repaymentTerms.repaymentMethod.label"
            values={{
              tooltip: (
                <RepaymentDeductionTooltip
                  label="financing.repaymentTerms.repaymentMethod.tooltip"
                  testId="repayment-deduction"
                />
              ),
            }}
          />
        </Box>
        {isEditEnabled && (
          <Box textStyle="link2" onClick={() => setIsEdit(true)} data-testid="repayment-method-edit">
            Edit
          </Box>
        )}
      </Flex>
      <Box>
        {isEdit ? (
          <MIDropDown
            required
            size="sm"
            value={repaymentMethod.id}
            options={repaymentMethodOptions}
            onChange={repaymentMethodChangeHandler}
            renderOption={renderOption}
            inputLeftElement={<DropdownFundingSourceIcon account={repaymentMethod} />}
            testId="repayment-bank-account-dropdown"
            disableSearch
          />
        ) : (
          <Box mt={2}>
            <RepaymentMethodSelectedOption bankAccount={repaymentMethod} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
