import * as React from 'react';
import { VendorCardTitle } from 'src/components/common/VendorCardTitle';
import { Badge } from 'src/core/ds/badge/Badge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import BillListItemCheckbox from 'src/pages/bill/components/BillsList/BillListItemCheckbox';
import { tags } from 'src/pay/consts';
import { UIProps } from './PayCard';
import { PayCardEmptyTitle } from './PayCardEmptyTitle';

export function PayCardHeader({
  vendorName,
  id,
  isCheckboxDisabled,
  isCheckboxMarked,
  search,
  isRecurringBill,
  showCheckbox,
  enableBatchSelection,
  hovered,
  tag,
  disabled,
  setCheckboxMarked,
  isScannedInvoice,
}: UIProps & { hovered: boolean }) {
  const shouldDisplayCheckBox = (showCheckbox || hovered) && enableBatchSelection;
  const onCheckListCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (!disabled && setCheckboxMarked) {
      setCheckboxMarked(id, event.target.checked);
    }
  };

  return (
    <Flex
      justifyContent={(vendorName || '').length > 0 ? 'space-between' : 'flex-end'}
      data-testid="list-item-vendor-name"
      alignItems="center"
      mb={3}
    >
      {shouldDisplayCheckBox && (
        <BillListItemCheckbox
          id={id}
          onCheck={onCheckListCard}
          isCheckboxDisabled={isCheckboxDisabled}
          isCheckboxMarked={isCheckboxMarked}
          shouldShowError={hovered && isCheckboxDisabled}
        />
      )}
      {vendorName ? (
        VendorCardTitle({
          name: vendorName,
          search,
          showBadge: !shouldDisplayCheckBox,
        })
      ) : (
        <PayCardEmptyTitle isScannedInvoice={isScannedInvoice} search={search} />
      )}
      {isRecurringBill && <Icon size={4} alignSelf="flex-start" ml={4} name={IconNames.repeat} />}
      {tag && (
        <Box ml={2}>
          <Badge {...tags[tag]} testId="list-item-tag-payment" />
        </Box>
      )}
    </Flex>
  );
}
