import head from 'lodash/head';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { receivingMethodLocations } from 'src/pages/receiving-method/locations';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { DeliveryMethodType } from 'src/utils/types';

type MatchAccountMessageResponseType = {
  title: string;
  description: string;
  action: string;
  onClick: () => void;
  onClose: () => void;
  openMessage: boolean;
  isConnected?: boolean;
  accountingSoftwareName?: string;
  notConnectedDeliveryMethods?: readonly DeliveryMethodType[];
  shouldShowMatchBankAccount: boolean;
  testId?: string;
};

type LocationState = {
  exitUrl: string;
  redirectUrl: string;
};

const useMatchAccountMessage = (): MatchAccountMessageResponseType => {
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const locationState = useLocation<{ state: LocationState }>().state;
  const [openMessage, setOpenMessage] = useState(true);
  const vendorId = useSelector(getOwnedVendorId);
  const {
    isConnected,
    shouldShowMatchBankAccount,
    notConnectedDeliveryMethods,
    connectedAccountingPlatform,
  } = useFetchAccountingPlatformsAndData();
  const platformName =
    connectedAccountingPlatform && getAccountingSoftwareDisplayName(connectedAccountingPlatform.name);
  const platformDisplayShortName = platformName?.displayShortName;

  const onMatchAccount = () => {
    const redirectUrl = generatePath(getPaidLocations.dashboard, {
      orgId,
    });
    historyPush({
      path: receivingMethodLocations.ach.linkBankAccount,
      params: {
        ...(vendorId && { vendorId }),
        deliveryMethodId: head(notConnectedDeliveryMethods)?.id || '',
      },
      state: {
        ...locationState,
        redirectUrl,
        exitUrl: redirectUrl,
      },
    });
  };

  const onCloseMessage = () => {
    analytics.trackAction('click-close-match-account-message');
    setOpenMessage(false);
  };

  return {
    title: 'getPro.message.matchAccount.title',
    description: 'getPro.message.matchAccount.description',
    action: 'getPro.message.matchAccount.action',
    accountingSoftwareName: platformDisplayShortName,
    onClick: onMatchAccount,
    onClose: onCloseMessage,
    openMessage,
    isConnected,
    notConnectedDeliveryMethods,
    shouldShowMatchBankAccount,
    testId: 'match-account-message',
  };
};

export { useMatchAccountMessage };
