import qs from 'qs';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { billLocations } from 'src/pages/bill/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { pushNotification } from 'src/services/notifications';
import { AccountingSoftware, CompanyInfoOnboardingOrigin, NotificationVariant } from 'src/utils/consts';

export function NewCompanyConnectAccountingSoftwareReturnPage() {
  const { search } = useLocation();
  const history = useHistory();
  const { orgId, createdOrganization, userAlreadyConnected } = qs.parse(search, {
    ignoreQueryPrefix: true,
  }) as Record<string, string>;

  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const accountingSoftwareName = connectedAccountingPlatform?.name || AccountingSoftware.QUICKBOOKS;

  const dispatch = useDispatch();

  const updateUser = useCallback(
    (orgId) =>
      new Promise((resolve, reject) => {
        dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
      }),
    [dispatch]
  );

  let newUrl = '';

  if (createdOrganization === 'true' || accountingSoftwareName !== AccountingSoftware.QUICKBOOKS) {
    newUrl = onboardingLocations.companyInfo.contacts;
  } else if (userAlreadyConnected === 'true') {
    newUrl = billLocations.index;
  } else {
    newUrl = onboardingLocations.companyInfo.connectedFromAccountingSoftware;
  }

  useEffect(() => {
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'inviteNewCompany.connectAccountingSoftware.connectSuccess',
      textValues: { accountingSoftwareName },
    });
    updateUser(Number(orgId)).then(() => {
      history.push(generatePath(newUrl, { orgId }), {
        origin: CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY,
      });
    });
  }, [newUrl, orgId, history, updateUser]);

  return <AreaLoader />;
}
