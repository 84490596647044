import type { ComponentProps } from 'react';
import styled, { css } from 'styled-components';
import { BillFileLoader } from 'src/components/common/BillFileLoader';
import { BillCreateOptionsContainer } from 'src/pages/bill/components/BillCreateOptionsContainer';
import { devices } from 'src/theme/appDevices';
import { NewBillPageForm } from './NewBillPageForm';

type Props = ComponentProps<typeof NewBillPageForm> & {
  orgId: number;
  handleUploadCancel: () => void;
  onUploadRemove: () => void;
  fileStorageUrl?: string;
  filePreviewUrls?: string[];
  goExit: () => void;
  goExitManually: () => void;
};

export const NewBillPage = ({
  handleUploadCancel,
  isUploading,
  onUploadRemove,
  onChangeAttachment,
  fileStorageUrl,
  filePreviewUrls,
  manually,
  ...props
}: Props) => (
  <NewBillMainPageContainer>
    {!manually && (
      <LeftContainer isUploading={isUploading}>
        <UploadArea isUploading={isUploading} fileStorageUrl={fileStorageUrl}>
          {isUploading ? (
            <BillFileLoader onCancelAction={handleUploadCancel} />
          ) : (
            <BillCreateOptionsContainer
              fileStorageUrl={fileStorageUrl}
              filePreviewUrls={filePreviewUrls}
              onChangeAttachment={onChangeAttachment}
              onUploadRemove={onUploadRemove}
            />
          )}
        </UploadArea>
      </LeftContainer>
    )}
    <RightContainer isUploading={isUploading} manually={manually}>
      <NewBillPageForm
        isUploading={isUploading}
        manually={manually}
        onChangeAttachment={onChangeAttachment}
        {...props}
      />
    </RightContainer>
  </NewBillMainPageContainer>
);

NewBillPage.defaultProps = {
  manually: false,
};

const NewBillMainPageContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;

  @media ${devices.nonDesktop} {
    flex-direction: column;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 7rem;
    overflow: inherit;
  }

  ${(props) => props.theme?.pages?.NewBillMainPage?.NewBillMainPageContainer}
`;

const LeftContainer = styled.div`
  display: flex;
  width: 60%;
  padding: 4.6rem;
  border-right: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  box-sizing: border-box;
  position: relative;

  @media ${devices.nonDesktop} {
    width: 100%;
    height: 63rem;
    border-right: none;
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  }

  @media ${devices.tablet} {
    height: 63rem;
    padding: 7.3rem;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 2rem;
    ${(props) =>
      props.isUploading &&
      css`
        border: none;
      `}

    ${(props) =>
      !props.isUploading &&
      css`
        height: 24rem;
        padding-top: 1.5rem;
      `}
  }
  ${(props) => props.theme?.pages?.NewBillMainPage?.LeftContainer}
`;

const RightContainer = styled.div`
  width: ${(props) => (props.manually ? '100%' : '40%')};
  margin: 0;
  height: unset;
  box-sizing: border-box;

  @media ${devices.desktop} {
    overflow: auto;
    position: relative;
  }

  @media ${devices.tablet} {
    height: ${(props) => (props.manually ? '100vh' : 'unset')};
    overflow: ${(props) => (props.manually ? 'auto' : 'unset')};
  }

  @media ${devices.nonDesktop} {
    width: 100%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 0 2rem;
    display: ${(props) => (props.isUploading && !props.manually ? 'none' : 'flex')};
  }

  ${(props) => props.theme?.pages?.NewBillMainPage?.RightContainer}
`;

const UploadArea = styled.div`
  height: 80vh;
  border: ${(props) => (props.fileStorageUrl ? '0.2rem dashed' : '0.1rem solid')};
  border-color: ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.6rem;
  max-width: 60rem;
  width: 100%;
  margin: auto;
  position: relative;

  @media ${devices.nonDesktop} {
    height: 100%;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    ${(props) =>
      props.isUploading &&
      css`
        border: none;
      `}

    ${(props) =>
      !props.isUploading &&
      css`
        height: unset;
        margin: 2.2rem 2.2rem 3.2rem 2.2rem;
        overflow: hidden;
      `}
  }
`;
