import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICreateOption } from 'src/components/common/MICreateOption';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { CSSObject } from 'src/core/ds';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import QuickbooksLogo from 'src/images/accounting-software/quickbooks-logo.svg';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore, { getOrganizationStoreActions } from 'src/modules/organizations/organizations-store';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { AccountingSoftware, CompanyType, RegistrationOrigin, Role } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import { checkEmailInUse } from 'src/utils/user';
import { companiesLocations } from '../locations';

type Props = {
  onPrev: () => void;
  onNext: (organization: any) => void;
  onExit: () => void;
};

export const AccountantNewCompanyConnectAccountingSoftwarePage = ({ onExit, onNext, onPrev }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const [checkingEmail, setCheckingEmail] = useState(false);
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const dispatch = useDispatch();
  const { createNotOwnedOrganization } = useMemo(() => getOrganizationStoreActions(dispatch), [dispatch]);
  const isOwnedCompanyCreating = useSelector(organizationStore.selectors.create.status())?.loading;
  const orgId = useSelector(getOrgId);
  const returnUrl = generatePath(companiesLocations.create.connectAccountingSoftwareReturn, { orgId });
  useFetchAccountingPlatformsAndData();

  useEffect(() => {
    if (!newCompanyInfo.email) {
      historyPush({ path: companiesLocations.create.details });
    }
  }, [newCompanyInfo, historyPush, orgId]);

  const updateUser = (orgId) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  async function onNoConnect() {
    const res = await checkEmailAndCreateOrganization();
    await updateUser(res.payload.organization.id);
    onNext(res.payload.organization);
  }

  async function checkEmailAndCreateOrganization(accountingPlatform?: AccountingSoftware) {
    setCheckingEmail(true);
    const emailInUse = await checkEmailInUse(newCompanyInfo.email);

    if (emailInUse) {
      historyPush({
        path: companiesLocations.create.error,
        query: { error: 'create_company_email_exists' },
      });
    } else {
      const res = await createNotOwnedOrganization(
        {
          ...newCompanyInfo,
          role: Role.ACCOUNTANT,
          noInvitation: true,
          ...(accountingPlatform && { accountingPlatform }),
        },
        { disableNotification: true }
      );

      return res;
    }

    return undefined;
  }

  async function connectQuickbooks() {
    intuit.goConnectToIntuit({
      registrationOrigin: RegistrationOrigin.ADD_COMPANY,
      intuitReturnUrl: returnUrl,
      intuitErrorReturnUrl: generatePath(companiesLocations.create.error, { orgId }),
      companyInfo: {
        ...newCompanyInfo,
        role: Role.ACCOUNTANT,
        companyType: CompanyType.SMB,
      },
      createCompany: true,
    });
  }

  return (
    <>
      {isOwnedCompanyCreating || checkingEmail ? (
        <AreaLoader />
      ) : (
        <StepLayoutPage
          title="inviteNewCompany.connectAccountingSoftware.title"
          titleValues={{ companyName: newCompanyInfo.companyName }}
          subtitle="inviteNewCompany.connectAccountingSoftware.subtitle"
          onPrev={onPrev}
          goExit={onExit}
        >
          <AccountingSoftwareOption
            imgSrc={QuickbooksLogo}
            label="inviteNewCompany.connectAccountingSoftware.connectQuickbooks"
            onClick={connectQuickbooks}
          />
          <AccountingSoftwareOption label="inviteNewCompany.connectAccountingSoftware.no" onClick={onNoConnect} />
        </StepLayoutPage>
      )}
    </>
  );
};

type AccountingSoftwareOptionProps = {
  imgSrc?: string;
  label: string;
  onClick: () => void;
};

const AccountingSoftwareOption = (props: AccountingSoftwareOptionProps) => (
  <MICreateOption
    {...props}
    sxForCreateOptionTextWrapper={sxForCreateOptionTextWrapper}
    sxForCreateOptionWrapper={sxForCreateOptionWrapper}
  />
);

const sxForCreateOptionWrapper: CSSObject = {
  py: 5,
  justifyContent: 'center',
  '> img': {
    h: 8,
    w: 8,
    mr: 4,
  },
};

const sxForCreateOptionTextWrapper: CSSObject = {
  '> div': {
    textStyle: 'body3Semi',
  },
  ml: 0,
};
