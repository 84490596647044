import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { Link } from 'src/core/ds/link';
import { NotificationVariant } from 'src/core/ds/toast';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';

export const VerifyWithDepositsPage = () => {
  const toastId = useRef<null | number>(null);
  const { navigate } = useNavigator();
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const { bankAccount } = deliveryMethod;
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);
  const site = useSiteContext();
  const analyticsInfo = {
    flowEntryPoint: 'msn-registration',
  };

  const { onApiCall: createMicroDeposits, loading: isLoading } = useApi<
    [vendorId: number, organizationId: number],
    void
  >({
    api: msnPortalApi.createMicroDeposits,
  });

  const onNext = async () => {
    analytics.trackAction('msnClaimAccountVerifyWithDeposits.continue', analyticsInfo);
    try {
      await createMicroDeposits(vendorId!, orgId);
      analytics.trackAction('msnClaimAccountVerifyWithDeposits.success', analyticsInfo);
      navigate(generatePath(onboardingLocations.msnRegistration.claimAccount.verifyWithDepositsStatus, { orgId }));
    } catch (e) {
      // display error only if it's not already shown on screen
      analytics.trackAction('msnClaimAccountVerifyWithDeposits.error', analyticsInfo);

      if (!checkIfToastIsDisplayedById(toastId.current)) {
        toastId.current = pushNotification({
          testId: 'claim-account-verify-with-deposits-error',
          type: NotificationVariant.error,
          msg: 'onboarding.msnCompanyInfo.generalError.text',
          textValues: {
            contactSupport: (
              <Link borderBottom="1px" color="white" href={`mailto:${site.config.support.email}`}>
                <MIFormattedText label="onboarding.msnCompanyInfo.generalError.contactSupport" />
              </Link>
            ),
          },
          autoClose: 10000,
        });
      }
    }
  };

  const onPrev = () => navigate(onboardingLocations.msnRegistration.claimAccount.deliveryMethodStatusCheck);

  return (
    <StepLayoutPage
      title="onboarding.msnCompanyInfo.verifyBankAccount.title"
      subtitle="onboarding.msnCompanyInfo.verifyBankAccount.subtitle"
      onNext={onNext}
      onPrev={onPrev}
      isLoading={isLoading}
      relativeStep={2 / 2}
      nextButtonAnalyticsProperties={analyticsInfo}
    >
      <TextField
        testId="msn-claim-account-verify-bank-account-routingNumber"
        label="onboarding.msnCompanyInfo.verifyBankAccount.routingNumber"
        value={bankAccount?.routingNumber}
        isRequired
        isViewOnly
      />
      <TextField
        testId="msn-claim-account-verify-bank-account-accountNumber"
        label="onboarding.msnCompanyInfo.verifyBankAccount.accountNumber"
        value={bankAccount?.accountNumber}
        isRequired
        isViewOnly
      />

      <MISecurityDetails eventPage="msn-verify-bank-account" />
    </StepLayoutPage>
  );
};
