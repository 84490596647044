import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MICategoryList } from 'src/components/common/MICategoryList';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore, { getUserActions } from 'src/modules/users/users-store';
import { loadCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { Role } from 'src/utils/consts';

type Props = {
  onExit: () => void;
  onNext: () => void;
  onPrev: () => void;
  onNoOwnerNext: () => void;
};

const inviteOwnerOptions = [
  {
    id: 'yes',
    label: 'categoryList.yes',
  },
  {
    id: 'no',
    label: 'inviteNewCompany.accountantFlow.notNow',
  },
];

const NewCompanyInviteOwnerPage = ({ onExit, onNext, onPrev, onNoOwnerNext }: Props) => {
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const { contactFirstName } = useSelector(getCompanyInfo);
  const userId = useSelector(profileStore.selectors.getCurrentUserId);
  const [isOwnerInvited, setIsOwnerInvited] = useState('');
  const userActions = getUserActions(dispatch);
  const { isUpdating } = useStructuredSelectors(usersStore.selectors.userOrganization(userId, orgId));

  const loadCompanyInfo = () =>
    new Promise((resolve, reject) => {
      dispatch(loadCompanyInfoAction(resolve, reject));
    });

  const goNoOwnerSuccessPage = async () => {
    await userActions.updateUserOrganization(
      { organizationId: orgId, userId, role: Role.OWNER },
      { disableNotification: true }
    );
    loadCompanyInfo();
    onNoOwnerNext();
  };

  return (
    <StepLayoutPage
      title="inviteNewCompany.accountantFlow.inviteOwnerTitle"
      titleValues={{ firstName: <Title>{contactFirstName}</Title> }}
      relativeStep={5 / 6}
      onNext={isOwnerInvited === 'yes' ? onNext : goNoOwnerSuccessPage}
      goExit={onExit}
      onPrev={onPrev}
      nextLabel="progress.continue"
      isNextDisabled={!isOwnerInvited}
      isLoading={isUpdating}
    >
      <MICategoryList categories={inviteOwnerOptions} selectedId={isOwnerInvited} onSelect={setIsOwnerInvited} />
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default NewCompanyInviteOwnerPage;
