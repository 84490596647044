import { AmexNotificationDetails } from '@melio/spend-management-api-axios-client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import config from 'src/config';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Menu, MenuButton, MenuDivider, MenuItem, MenuList } from 'src/core/ds/menu';
import Spacer from 'src/core/ds/spacer';
import { Tag } from 'src/core/ds/tag';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { settingsLocations } from 'src/pages/settings/locations';
import { useEnrollment } from 'src/pages/spend-management/hooks/useEnrollment';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';

interface AccountIndicatorProps {
  accounts: AmexNotificationDetails[];
  pageName: Page;
  onEnrolled: () => void;
}

const AccountIndicator = ({ accounts, pageName, onEnrolled }: AccountIndicatorProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [historyPush] = useHistoryWithOrgId();
  const { onEnrollClick } = useEnrollment({ onEnrolled });

  const defaultAccount = accounts.find((account) => account.isDefault);

  useEffect(() => {
    analytics.track(pageName, Context.ACCOUNT_INDICATOR, isOpen ? Event.MENU_OPEN : Event.MENU_CLOSE);
  }, [isOpen, pageName]);

  const accountNickname = defaultAccount?.accountNickname || defaultAccount?.productDescription;

  const handleAmexClicked = () => {
    analytics.track(pageName, Context.ACCOUNT_INDICATOR, Event.CLICK_AMEX);
    window.open(config.spendManagement.amexUrls.login, '_blank');
  };

  const handleSettingsClicked = () => {
    analytics.track(pageName, Context.ACCOUNT_INDICATOR, Event.CLICK_SETTINGS);
    historyPush({ path: settingsLocations.spendManagement });
  };

  return (
    <Menu autoSelect={false} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
      <MenuTrigger>
        <Box textStyle="body4Semi">
          <Flex direction="row" gap={2} alignItems="center" h="100%" pr="5">
            <Box
              data-testid="card-indicator"
              as="span"
              borderRadius="50%"
              bg="green.700"
              p={1}
              justifyContent="center"
              alignItems="center"
              ml={4}
            />
            <Icon name={IconNames.creditCard} />
            <MIFormattedText
              label="spendManagement.header.connectedAccount.label"
              values={{ numOfAccounts: accounts.length, last5: defaultAccount?.lastFive, nickname: accountNickname }}
            />
            <Icon name={isOpen ? IconNames.caretUp : IconNames.caretDown} />
          </Flex>
        </Box>
      </MenuTrigger>
      <MenuList>
        <Box __css={{ overflowY: 'auto', maxH: '20rem' }} data-testId="spend-management-account-list">
          {accounts.map((account) => (
            <MenuItem
              sx={{ cursor: 'default' }}
              key={account.accountId}
              justifyContent="space-between"
              onClick={() => {
                analytics.track(pageName, Context.ACCOUNT_INDICATOR, Event.CLICK_CARD);
              }}
              data-testId={`account-${account.accountId}`}
            >
              <Flex direction="row" gap={2} alignItems="center">
                <Icon name={IconNames.creditCard} />
                <MIFormattedText
                  label="spendManagement.header.connectedAccount.items.account"
                  values={{ last5: account.lastFive, nickname: account.accountNickname || account.productDescription }}
                />
                {account.isDefault && (
                  <>
                    <Spacer />
                    <Spacer />
                    <Spacer />
                    <Spacer />
                    <Tag label="spendManagement.header.connectedAccount.items.default" />
                  </>
                )}
              </Flex>
            </MenuItem>
          ))}
        </Box>
        <MenuDivider />
        <MenuItem justifyContent="space-between" onClick={handleAmexClicked}>
          <MIFormattedText label="spendManagement.header.connectedAccount.items.amex" />
        </MenuItem>
        <MenuItem justifyContent="space-between" onClick={handleSettingsClicked}>
          <MIFormattedText label="spendManagement.header.connectedAccount.items.settings" />
        </MenuItem>
        <MenuDivider />
        <MenuItem justifyContent="space-between">
          <Box
            display="inline"
            textStyle="link3"
            _hover={{
              textDecoration: 'none',
            }}
            onClick={onEnrollClick}
          >
            <MIFormattedText label="spendManagement.header.connectedAccount.items.enroll" />
          </Box>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

// eslint-disable-next-line import/no-default-export
export default AccountIndicator;

const MenuTrigger = styled(MenuButton)`
  background-color: white;
  border: 1px solid #dedee3;
  border-radius: 8px;
  margin-right: 12px;
  cursor: pointer;
`;
