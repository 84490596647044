import { AmexNotificationDetails } from '@melio/spend-management-api-axios-client';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Spacer from 'src/core/ds/spacer';
import AccountIndicator from 'src/pages/spend-management/components/header/components/AccountIndicator';
import { Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import Title from './components/Title';

export const Header = ({
  onAddCardClicked,
  accounts,
  pageName,
  onEnrolled,
}: {
  onAddCardClicked: () => void;
  accounts: AmexNotificationDetails[];
  pageName: Page;
  onEnrolled: () => void;
}) => (
  <Flex justify="space-between" data-testId="spend-management-header">
    <Title />
    <Spacer />
    {!!accounts.length && (
      <>
        <AccountIndicator accounts={accounts} pageName={pageName} onEnrolled={onEnrolled} />
        <Button
          leftIcon={<Icon name={IconNames.plus} size={IconSize.s} />}
          label="spendManagement.header.actions.addCard.buttonLabel"
          variant={ButtonVariants.primary}
          onClick={onAddCardClicked}
        />
      </>
    )}
  </Flex>
);

// eslint-disable-next-line import/no-default-export
export default Header;
