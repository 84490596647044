import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Separator } from 'src/core/ds/separator';
import { usePaperCheckFee } from 'src/hooks/usePaperCheckFee';
import { getIsCancelAndRetryPaymentFlow } from 'src/redux/payBillWizard/selectors';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';
import {
  BillingFeeType,
  CardTypes,
  DeliveryType,
  FEES,
  FundingType,
  MiMoneyFlavor,
  NotificationCardTypes,
} from 'src/utils/consts';
import { AccountType, OrganizationBillingFee } from 'src/utils/types';
import { InternationalFee } from '../Fees/InternationalFee';
import { PaymentFeeHeader } from '../PaymentFeeHeader';
import { getAchToCheckFeeDescription } from './utils/getAchToCheckFeeDescription';
import { getRegularFeeFormattedText } from './utils/getRegularFeeFormattedText';

type PaymentFeeProps = {
  fee: PaymentFeeInfo;
  fundingSource: AccountType;
  isVendorAbsorbedCardFee?: boolean;
  isInternationalPayment?: boolean;
  deliveryMethodType: DeliveryType;
  isRecurring: boolean;
  organizationBillingFees?: OrganizationBillingFee[];
};

export const PaymentFee = ({
  fee,
  fundingSource,
  isVendorAbsorbedCardFee,
  isInternationalPayment,
  deliveryMethodType,
  isRecurring,
  organizationBillingFees,
}: PaymentFeeProps) => {
  const getFee = (fee: PaymentFeeInfo) => {
    const feeReduced = fee.finalAmount < fee.baseAmount;
    const showAmount = feeReduced || fee.finalAmount === 0;
    const showFeeDescription = fee.baseAmount > 0;

    return {
      feeText: showAmount ? (
        <StyledMIMoney amount={fee.finalAmount} privateData />
      ) : (
        <FormattedNumber value={fee.feeStructure?.value} format="percent" />
      ),
      showFeeDescription,
      textValues: {
        showAmount,
        baseAmount: fee.baseAmount,
        basePercent: fee.feeStructure?.value,
        finalAmount: fee.finalAmount,
        savingAmount: fee.promotion?.reduction,
        expiration: fee.promotion?.expiration && new Date(fee.promotion?.expiration),
        promotionName: fee.promotion?.promotionName,
      },
      baseAmount: fee.baseAmount,
      savingAmount: fee.promotion?.reduction,
      finalAmount: fee.finalAmount,
      showFeeHint: feeReduced,
    };
  };

  const isDebitCard = fundingSource.cardAccount?.cardType === CardTypes.DEBIT;
  const isCreditCard = fundingSource.cardAccount?.cardType === CardTypes.CREDIT;
  const {
    availableFreeChecks,
    totalFreeChecks,
    shouldDisplayPaperCheckFee,
    paperCheckFee: basePaperCheckFee,
  } = usePaperCheckFee({
    fundingSourceType: fundingSource.fundingType,
    deliveryMethodType,
  });
  const shouldDisplayAchToAchFee =
    fundingSource.fundingType === FundingType.ACH && deliveryMethodType === DeliveryType.ACH;
  const isCancelAndRetryFlow = useSelector(getIsCancelAndRetryPaymentFlow);

  const { feeText, showFeeDescription, showFeeHint, textValues, savingAmount, baseAmount, finalAmount } =
    (fee && getFee(fee)) || {};

  if (fee && fee.fastFee) {
    return <FastFee fee={fee} isCreditCard={isCreditCard} />;
  }

  if (isInternationalPayment && fee?.internationalFee) {
    return <InternationalFee fee={fee} isCreditCard={isCreditCard} />;
  }

  if (savingAmount && savingAmount > 0) {
    return (
      <SavingAmountFee
        textValues={textValues}
        baseAmount={baseAmount}
        finalAmount={finalAmount}
        savingAmount={savingAmount}
      />
    );
  }

  return (
    <RegularFee
      textValues={textValues}
      isDebitCard={isDebitCard}
      isCreditCard={isCreditCard}
      feeText={feeText}
      finalAmount={fee.finalAmount}
      showFeeDescription={showFeeDescription}
      showFeeHint={showFeeHint}
      isVendorAbsorbedCardFee={isVendorAbsorbedCardFee}
      shouldDisplayPaperCheckFee={shouldDisplayPaperCheckFee}
      shouldDisplayAchToAchFee={shouldDisplayAchToAchFee}
      isCancelAndRetryFlow={isCancelAndRetryFlow}
      paperCheckFee={fee.feeStructure.value}
      basePaperCheckFee={basePaperCheckFee}
      availableFreeChecks={availableFreeChecks}
      totalFreeChecks={totalFreeChecks}
      deliveryMethodType={deliveryMethodType}
      isRecurring={isRecurring}
      organizationBillingFees={organizationBillingFees}
    />
  );
};

type FastFeeProps = {
  fee: PaymentFeeInfo;
  isCreditCard: boolean;
};

const FastFee = ({ fee, isCreditCard }: FastFeeProps) => {
  const { feeStructure, fastFee, finalAmount } = fee;
  const { cap } = feeStructure;
  const feeDetailsLabel = cap && Number(fastFee.totalAmount) === Number(cap) ? 'feeDetailsNoPercentage' : 'feeDetails';
  const isCardFeeExist = feeStructure?.type === 'percent' && finalAmount > 0;
  const totalAmount = isCardFeeExist ? fastFee.totalAmount + finalAmount : fastFee.totalAmount;
  const cardFeeLabel = isCreditCard ? 'bills.pay.confirm.creditCardFee' : 'bills.pay.confirm.debitCardFee';

  return (
    <FeeContainer data-testid="fast-fee-container">
      <PaymentFeeHeader />
      <FeeItem>
        <MIFormattedText label={`bills.pay.confirm.${fastFee.type}.${feeDetailsLabel}`} values={{ fee: fastFee.fee }} />
        <MIFormattedCurrency value={fastFee.totalAmount.toString() || ''} />
      </FeeItem>
      {isCardFeeExist && (
        <FeeItem>
          <MIFormattedText
            label={cardFeeLabel}
            values={{
              fee: <FormattedNumber value={feeStructure.value} format="percent" />,
            }}
          />
          <MIFormattedCurrency value={fee.finalAmount.toString() || ''} />
        </FeeItem>
      )}
      <TotalFeeContainer>
        <FeeItem>
          <MIFormattedText label="bills.pay.confirm.totalFee" />
          <MIFormattedCurrency value={totalAmount.toString() || ''} />
        </FeeItem>
        <TotalFeeDescription>
          <MIFormattedText label="bills.pay.confirm.expedited-ach.totalFeeDescription" />
        </TotalFeeDescription>
      </TotalFeeContainer>
    </FeeContainer>
  );
};

type SavingAmountProps = {
  textValues: Record<string, any>;
  baseAmount: number;
  savingAmount: number;
  finalAmount: number;
};

const SavingAmountFee = ({ textValues, baseAmount, savingAmount, finalAmount }: SavingAmountProps) => (
  <FeeContainer>
    <PaymentFeeHeader />
    <FeeItem>
      <MIFormattedText label="bills.pay.confirm.feePromoBase" values={textValues} />
      <StyledMIMoney size="small" amount={baseAmount} />
    </FeeItem>
    <FeeItem>
      <div>
        <MIFormattedText label="bills.pay.confirm.feePromoReduction" values={textValues} />
        <FeeDescription>
          <MIFormattedText label="bills.pay.confirm.feePromoReductionHint" values={textValues} />
        </FeeDescription>
      </div>
      <StyledMIMoney size="small" flavor={MiMoneyFlavor.POSITIVE} amount={-savingAmount} />
    </FeeItem>
    <TotalFeeContainer>
      <FeeItem>
        <MIFormattedText label="bills.pay.confirm.totalFee" values={textValues} />
        <StyledMIMoney size="normal" amount={finalAmount} />
      </FeeItem>
    </TotalFeeContainer>
  </FeeContainer>
);

type RegularFeeProps = {
  textValues: Record<string, any>;
  isDebitCard: boolean;
  isCreditCard: boolean;
  feeText: React.ReactNode;
  finalAmount?: number;
  showFeeHint: boolean;
  showFeeDescription: boolean;
  shouldDisplayPaperCheckFee: boolean;
  shouldDisplayAchToAchFee: boolean;
  isVendorAbsorbedCardFee?: boolean;
  paperCheckFee: number;
  basePaperCheckFee: number;
  totalFreeChecks?: number;
  availableFreeChecks?: number;
  deliveryMethodType?: DeliveryType;
  isRecurring: boolean;
  organizationBillingFees?: OrganizationBillingFee[];
  isCancelAndRetryFlow?: boolean;
};

const RegularFee = ({
  textValues,
  isDebitCard,
  isCreditCard,
  feeText,
  finalAmount,
  showFeeHint,
  showFeeDescription,
  shouldDisplayPaperCheckFee,
  shouldDisplayAchToAchFee,
  isVendorAbsorbedCardFee,
  paperCheckFee,
  availableFreeChecks,
  totalFreeChecks,
  deliveryMethodType,
  basePaperCheckFee,
  isRecurring,
  organizationBillingFees,
  isCancelAndRetryFlow,
}: RegularFeeProps) => {
  const getFeeDisplayType = () => {
    if (isDebitCard || isCreditCard) {
      return 'card-fee';
    }

    if (shouldDisplayPaperCheckFee) {
      return 'paper-check-fee';
    }

    if (shouldDisplayAchToAchFee) {
      return 'ach-to-ach-fee';
    }

    if (deliveryMethodType === DeliveryType.VIRTUAL) {
      return 'virtual-dm-with-notification-fee';
    }

    return 'ach-fee';
  };
  const feeDisplayType = getFeeDisplayType();
  const isPaperCheckReset = feeDisplayType === 'paper-check-fee' && isCancelAndRetryFlow;

  const feeFormattedText = getRegularFeeFormattedText({
    showFeeDescription: showFeeDescription && !isPaperCheckReset,
    isVendorAbsorbedCardFee,
    textValues: {
      ...textValues,
      finalAmount: isPaperCheckReset ? FEES.FREE : textValues.finalAmount,
    },
    orgHasBillingFeeMethod: organizationBillingFees?.some(
      (billingFee) => billingFee.billingFeeType === BillingFeeType.AchToCheck
    ),
  });

  if (feeDisplayType === 'card-fee') {
    return (
      <FeeContainer>
        <PaymentFeeHeader />
        <Box>
          <TotalFee data-testid="total-fee">
            {feeText}
            {showFeeHint && (
              <FeeHint>
                <MIFormattedText label="bills.pay.confirm.feeHint" values={textValues} />
              </FeeHint>
            )}
          </TotalFee>
          {feeFormattedText && (
            <FeeDescription>
              <MIFormattedText {...feeFormattedText} />
            </FeeDescription>
          )}
        </Box>
      </FeeContainer>
    );
  }

  if (feeDisplayType === 'paper-check-fee' || feeDisplayType === 'ach-to-ach-fee') {
    return (
      <FeeContainer>
        <PaymentFeeHeader />
        <TotalFeeWithPaperCheck
          paperCheckFee={paperCheckFee}
          basePaperCheckFee={basePaperCheckFee}
          feeFormattedText={feeFormattedText}
          finalAmount={finalAmount}
          showFeeHint={showFeeHint}
          feeHintTextValues={textValues}
          availableFreeChecks={availableFreeChecks}
          totalFreeChecks={totalFreeChecks}
          isRecurring={isRecurring}
          shouldResetFee={isPaperCheckReset}
        />
      </FeeContainer>
    );
  }

  if (feeDisplayType === 'virtual-dm-with-notification-fee') {
    return (
      <FeeContainer>
        <PaymentFeeHeader />
        <Box
          boxSizing="border-box"
          paddingInlineEnd={5}
          marginBlockStart={4}
          data-testid="virtual-dm-paper-check-fee-notification"
        >
          <MINotificationCard
            type={NotificationCardTypes.INFO}
            subtitle={{
              label: 'bills.pay.confirm.virtual-dm.description',
              values: { amount: basePaperCheckFee?.toFixed(2) },
            }}
          />
        </Box>
      </FeeContainer>
    );
  }

  if (feeDisplayType === 'ach-fee') {
    return (
      <FeeContainer>
        <PaymentFeeHeader />
        <Flex justify="space-between">
          <Box>
            <Box data-testid="total-fee-label" color="black" textStyle="body2">
              <MIFormattedText label="bills.pay.confirm.totalFee" />
            </Box>
            {feeFormattedText && (
              <FeeDescription>
                <MIFormattedText {...feeFormattedText} />
              </FeeDescription>
            )}
          </Box>
          <Box mr="5">
            <TotalFee data-testid="total-fee">
              {feeText}
              {showFeeHint && (
                <FeeHint>
                  <MIFormattedText label="bills.pay.confirm.feeHint" values={textValues} />
                </FeeHint>
              )}
            </TotalFee>
          </Box>
        </Flex>
      </FeeContainer>
    );
  }

  return null;
};

type TotalFeeWithPaperCheckProps = {
  paperCheckFee: number;
  basePaperCheckFee: number;
  feeFormattedText: { label: string; values: Record<string, any> };
  finalAmount?: number;
  showFeeHint: boolean;
  feeHintTextValues: Record<string, any>;
  availableFreeChecks?: number;
  totalFreeChecks?: number;
  isRecurring: boolean;
  shouldResetFee?: boolean;
};

const TotalFeeWithPaperCheck = ({
  paperCheckFee,
  basePaperCheckFee,
  feeFormattedText,
  finalAmount,
  showFeeHint,
  feeHintTextValues,
  availableFreeChecks,
  totalFreeChecks,
  isRecurring,
  shouldResetFee,
}: TotalFeeWithPaperCheckProps) => {
  const descriptionData = getAchToCheckFeeDescription({
    availableFreeChecks: availableFreeChecks || 0,
    isRecurring,
    basePaperCheckFee,
    paperCheckFee,
    totalFreeChecks: totalFreeChecks || 2,
  });

  const isFree = (isRecurring && availableFreeChecks) || (!isRecurring && paperCheckFee === 0);

  return (
    <Flex flexDirection="column" w="full" paddingInlineEnd={5} boxSizing="border-box">
      {!shouldResetFee ? (
        <>
          <Flex
            justifyContent="space-between"
            marginBlockStart={4}
            textStyle="body2"
            color="black"
            data-testid={`paper-check-fee-${isFree ? 'free' : 'amount'}`}
          >
            <Flex direction="column">
              <MIFormattedText label="bills.pay.confirm.ach-to-check.title" data-testid="paper-check-fee-title" />
              {!!descriptionData.label && (
                <Box textStyle="body4" color="grey.600">
                  <MIFormattedText label={descriptionData.label} values={descriptionData.values} />
                </Box>
              )}
            </Flex>
            <MIFormattedCurrency value={isFree ? FEES.FREE : paperCheckFee} data-testid="paper-check-fee-value" />
          </Flex>
          <Separator color="grey.400" marginBlock={4} />
        </>
      ) : null}
      <Flex justifyContent="space-between" textStyle="body2" color="black">
        <Box>
          <MIFormattedText label="bills.pay.confirm.totalFee" data-testid="total-fee-label" />
          {feeFormattedText && (
            <FeeDescription>
              <MIFormattedText {...feeFormattedText} />
            </FeeDescription>
          )}
        </Box>
        <Box data-testid="total-fee" textStyle="body2" color="black">
          <MIFormattedCurrency
            value={`${shouldResetFee ? FEES.FREE : finalAmount}`}
            data-testid="paper-check-fee-value"
          />
          {showFeeHint && <MIFormattedText label="bills.pay.confirm.feeHint" values={feeHintTextValues} />}
        </Box>
      </Flex>
    </Flex>
  );
};

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const FeeContainer = styled(BaseContainer)`
  border-bottom: 0;
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeContainer}
`;

const TotalFee = styled.div`
  display: flex;
  font-size: ${(props) => props.theme.text.size.subTitle};
  height: 3rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFee}
`;
const FeeHint = styled.span`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.label};
  align-self: center;
  margin-left: 1rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeHint}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FeeDescription = styled.div`
  ${baseTextStyles}
  ${(props) => props.theme?.components?.BillPaymentReview?.FeeDescription}
`;

const FeeItem = styled.div<{ total?: number }>`
  padding-right: 2rem;
  margin: 2rem 0;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => (props.total ? props.theme.text.size.subTitle : props.theme.text.size.regular)};
  font-weight: ${(props) => props.theme.text.weight.regular};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TotalFeeContainer = styled.div`
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  ${FeeItem} {
    margin-bottom: 0.5rem;
  }
`;

const TotalFeeDescription = styled.div`
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.hint};
  ${(props) => props.theme?.components?.BillPaymentReview?.TotalFeeDescription}
`;
