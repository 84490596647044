import { Tag as ChakraTag, TagLabel, TagProps as ChakraTagProps } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

export type OurTagProps = {
  label?: string;
  values?: Record<string, any>;
  testId?: string;
  size?: 'sm' | 'md';
};

export type TagProps = Omit<ChakraTagProps, 'colorScheme' | 'variant' | 'size'> & OurTagProps;

export const Tag = ({ label, values, testId, ...rest }: TagProps) => (
  <ChakraTag data-testid={testId} {...rest}>
    <TagLabel>
      <MIFormattedText label={label} values={values} />
    </TagLabel>
  </ChakraTag>
);
