import { featureFlags } from '@melio/shared-web';
import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import authStore from 'src/modules/auth/auth-store';
import { useClientBgColor } from 'src/pages/team/team-management/hooks/useClientBgColor';
import { CompanyType, FeatureFlags } from 'src/utils/consts';
import { InvitationType } from 'src/utils/types';

type Props = {
  onNext: (orgId: number) => void;
  firmCompanyName: string;
  invitation: InvitationType & { inviteeOrganizations?: { id: number; companyName: string; companyType: string }[] };
};
export const ConfirmMergeExistingFirm = ({ onNext, firmCompanyName, invitation }: Props) => {
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const isLoading = useSelector(authStore.selectors.acceptUserInvitation.loading);
  const { getColorProps } = useClientBgColor();

  const allOrgs = (invitation?.inviteeOrganizations || []).filter(
    (org) => org.companyType !== CompanyType.ACCOUNTING_FIRM
  );

  const organizations = sortBy(allOrgs, ['companyName']);

  const handleSubmit = () => {
    onNext(invitation.organizationId);
  };

  const handleCancel = () => {
    const url = shouldDisplayZendesk
      ? 'https://melio.zendesk.com/hc/en-us/articles/4418525840540-How-to-exclude-clients-from-a-merged-account'
      : 'https://help.meliopayments.com/en/articles/5459950-how-to-exclude-clients-from-a-merged-account';
    window.open(url, '_blank');
  };

  return (
    <RegisterLayoutPage
      hideLogo
      title="teamManagement.beforeMergeExistingFirm.title"
      text="teamManagement.beforeMergeExistingFirm.subtitle"
      textValues={{ companyName: firmCompanyName }}
      buttonAction={handleSubmit}
      buttonLabel="teamManagement.beforeMergeExistingFirm.buttons.submit"
      secondaryLinkAction={handleCancel}
      secondaryLinkLabel="teamManagement.beforeMergeExistingFirm.buttons.cancel"
      isLoading={isLoading}
    >
      <ListContainer>
        {organizations.map(({ id, companyName }) => (
          <Company key={id}>
            <Avatar name={companyName} size={Size.lg} bg={getColorProps(companyName).color} />
            <CompanyNameContainer>{companyName}</CompanyNameContainer>
          </Company>
        ))}
      </ListContainer>
    </RegisterLayoutPage>
  );
};

const Company = styled.div`
  display: flex;
  align-items: center;
  letter-spacing: 0;
  padding: 2.5rem 2.5rem 2.5rem 3.2rem;
  border-bottom: 1px solid ${(props) => props.theme.text.color.readonly};
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  ${(props) => props.theme.text.fontType.regular};

  &:last-of-type {
    border-bottom: none;
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${(props) => props.theme.text.color.readonly};
  border-radius: 0.8rem;
  width: 100%;
  max-height: 31.8rem;
  overflow-y: auto;
  margin: 2.5rem 0 4rem 0;
`;

const CompanyNameContainer = styled.div`
  margin: 0 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;
