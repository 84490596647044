import { ApiSliceActionName, generateActionName } from 'src/helpers/redux/actionNames';
import {
  batchCreateInvitationsSlice,
  inviteeOrganizationsSlice,
  relatedInvitationsSlice,
  validateCreateInvitationSlice,
  validateInvitationSlice,
} from 'src/modules/invitations/slices';
import { name } from './consts';

export const INVITATIONS_FETCH_SUCCESS = generateActionName(name, 'fetch', ApiSliceActionName.SUCCESS);
export const INVITATIONS_LIST_SUCCESS = generateActionName(name, 'list', ApiSliceActionName.SUCCESS);
export const INVITATIONS_UPDATE_SUCCESS = generateActionName(name, 'update', ApiSliceActionName.SUCCESS);
export const INVITATIONS_DELETE_SUCCESS = generateActionName(name, 'delete', ApiSliceActionName.SUCCESS);
export const INVITATIONS_CREATE_SUCCESS = generateActionName(name, 'create', ApiSliceActionName.SUCCESS);

export const INVITATIONS_BATCH_CREATE_SUCCESS = batchCreateInvitationsSlice.actions.success;
export const INVITATIONS_INVITEE_ORGANIZATIONS_SUCCESS = inviteeOrganizationsSlice.actions.success;
export const INVITATIONS_FETCH_RELATED_SUCCESS = relatedInvitationsSlice.actions.success;
export const INVITATIONS_VALIDATE_CREATE_SUCCESS = validateCreateInvitationSlice.actions.success;
export const INVITATIONS_VALIDATE_SUCCESS = validateInvitationSlice.actions.success;
