import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { useModal } from 'src/helpers/react/useModal';
import { useToggle } from 'src/helpers/react/useToggle';
import useRemoveBatch from 'src/pages/get-paid/hooks/useRemoveBatch';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';

type Props = {
  numOfInvoices: number;
  totalAmount: number;
};

const DeleteSentInvoicesButton = ({ numOfInvoices, totalAmount }: Props) => {
  const [removeInvoices, removeInvoicesToggle] = useToggle(true);
  let successLabel;

  if (numOfInvoices > 1 && removeInvoices) {
    successLabel = 'getPaid.deletePaymentRequests.success.multiCancelAndRemove';
  } else if (numOfInvoices > 1 && !removeInvoices) {
    successLabel = 'getPaid.deletePaymentRequests.success.multiCancel';
  } else if (numOfInvoices === 1 && removeInvoices) {
    successLabel = 'getPaid.deletePaymentRequests.success.singleCancelAndRemove';
  } else if (numOfInvoices === 1 && !removeInvoices) {
    successLabel = 'getPaid.deletePaymentRequests.success.singleCancel';
  }

  const [removeBatch] = useRemoveBatch(successLabel, 'getPaid.deletePaymentRequests.error');
  const removeInvoicesCheckbox = (
    <Flex align="center">
      <Checkbox onChange={(event) => removeInvoicesToggle(event.target.checked)} isChecked={removeInvoices}>
        <MIFormattedText label="getPaid.view.batch.deleteMessage.sent.removeInvoices" />
      </Checkbox>
    </Flex>
  );

  const [DeleteSentInvoicesModal, onDeleteSentInvoices] = useModal(CommonDialog, {
    confirm: () => removeBatch(removeInvoices),
    title: 'getPaid.view.batch.deleteMessage.sent.title',
    textValues: {
      totalAmount,
      numOfInvoices,
      removeInvoices: removeInvoicesCheckbox,
    },
    description: 'getPaid.view.batch.deleteMessage.sent.description',
    confirmText: 'getPaid.view.batch.deleteMessage.sent.confirm',
    cancelText: 'getPaid.view.batch.deleteMessage.sent.cancel',
  });

  return (
    <>
      {DeleteSentInvoicesModal}
      <Button label="getPaid.view.batch.delete.sent" onClick={onDeleteSentInvoices} variant={ButtonVariants.tertiary} />
    </>
  );
};

export default DeleteSentInvoicesButton;
