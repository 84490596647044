import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  attributeName: string;
  testId?: string;
};

export const ApprovalWorkflowTooltip = ({ attributeName, testId }: Props) => (
  <Tooltip
    shouldWrapChildren
    data-testid={testId}
    label={<MIFormattedText label={`settings.approvalWorkflows.modal.${attributeName}.tooltip`} />}
    placement="top"
  >
    <Box display="inline-block" position="absolute" ml={2}>
      <Icon name={IconNames.infoCircle} size={IconSize.s} testId={`input-${attributeName}-tooltipIcon`} />
    </Box>
  </Tooltip>
);
