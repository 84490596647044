/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';
import { MAPPING as internationalDeliveryMethodEventMapping } from './international-delivery-method/event-mapping';

const editAddressEvent = 'edit-address';
const unilateralPageEvent = 'unilateral-payment';
const shiftVendorToAchPageEvent = 'shift-vendor-to-ach';
/** was shift-to-debit, but changed to represent either ach or check */
const shiftToDebitPageEvent = 'push-to-debit';
const virtualCardCandidates = 'virtual-card-candidates';

const MAPPING: EventMappingsTree = {
  '/vendors/*/add-delivery-method/*': {
    'page.view': [shiftVendorToAchPageEvent, 'add-delivery-method'],
    'go.back': [shiftVendorToAchPageEvent, 'go-back'],
    ...mapApiSlice('[DELIVERYMETHODS] REPLACE_METHOD', 'vendors-ach-details', 'add-ach'),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE_ACH_METHOD', 'vendors-ach-details', 'edit-ach'),
  },
  '/vendors/*/add-delivery-method/*/switch-company': {
    'page.view': [shiftVendorToAchPageEvent, 'switch-company'],
    'option-selected-organization': [shiftVendorToAchPageEvent, 'select-organization', { selectedOrgId: 'option.id' }],
    'vendors.addDeliveryMethodByLink.switchCompanyCTA': [shiftVendorToAchPageEvent, 'switch-organization'],
  },
  '/vendors/*/link-delivery-method/*': {
    'page.view': [shiftVendorToAchPageEvent, 'link-delivery-method'],
    'go.back': [shiftVendorToAchPageEvent, 'go-back'],
    '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR_FAILURE': [
      shiftVendorToAchPageEvent,
      'validation-error',
      { validation: 'error.message' },
    ],
    '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR_SUCCESS': [shiftVendorToAchPageEvent, 'delivery-method-linked'],
  },
  '/vendors/*/add-delivery-method/*/shifted-current-payment': {
    'page.view': [shiftVendorToAchPageEvent, 'shifted-current-payment'],
    'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.upsaleCTA': [
      shiftVendorToAchPageEvent,
      'join-melio',
    ],
  },
  '/vendors/*/add-delivery-method/*/success': {
    'page.view': [shiftVendorToAchPageEvent, 'success'],
    'vendors.deliveryMethods.ach.changeSuccess.button': ['vendors-ach-details', 'quickbooks-signup'],
    'vendors.deliveryMethods.shiftVendorToAch.success.learnMore': [shiftVendorToAchPageEvent, 'learnMore'],
    'vendors.deliveryMethods.shiftVendorToAch.success.upsale.cta': ['vendors-ach-details', 'melio-signup'],
  },
  '/vendors/*/add-delivery-method/*/invalid-link': {
    'page.view': [shiftVendorToAchPageEvent, 'invalid-token'],
    'vendors.deliveryMethods.ach.errors.supportLink': ['invalid-token', 'support-open-start-chat'],
  },
  '/vendors/*/edit-address/*/check-sent': {
    'page.view': [shiftVendorToAchPageEvent, 'check-sent'],
    'vendors.editCheckAddress.checkSent.upsaleCTA': ['vendors-ach', 'get-paid-by-bank'],
  },
  '/vendors/*/edit-address/*/check-deposited': {
    'page.view': [shiftVendorToAchPageEvent, 'check-deposited'],
    'vendors.editCheckAddress.checkDeposited.upsaleCTA': ['vendors-ach', 'get-paid-by-bank'],
  },
  '/vendors/*/edit-address/*/change-address': {
    'page.view': [editAddressEvent, 'change-address'],
    'suggest-alt-address': [editAddressEvent, 'suggest-alternative-address', { flow: 'flow' }],
    'submit-address': [editAddressEvent, 'choose-address', { flow: 'option' }],
    'vendors.addDeliveryMethodByLink.manualAddressCTA': [editAddressEvent, 'confirm-address'],
    'vendors.editCheckAddress.checkNotSent.form.saveCTA': [editAddressEvent, 'save-address'],
  },
  '/vendors/*/edit-address/*/address-saved': {
    'page.view': [editAddressEvent, 'address-saved'],
    'vendors.editCheckAddress.checkNotSent.success.upsaleCTA': [editAddressEvent, 'enable-bank-deposits'],
  },
  '/vendors/*/edit-address/*/switched-to-ach': {
    'page.view': [shiftVendorToAchPageEvent, 'switched-to-ach'],
    'vendors.editCheckAddress.switchedToACHAlready.upsaleCTA': [shiftVendorToAchPageEvent, 'click-join-melio'],
  },
  '/vendors/*/edit-address/*/check-cancelled': {
    'page.view': [shiftVendorToAchPageEvent, 'check-cancelled'],
    'support-email': [shiftVendorToAchPageEvent, 'support-email'],
  },
  '/vendors/*/edit-address/*/invalid-link': {
    'page.view': [shiftVendorToAchPageEvent, 'invalid-link'],
  },
  '/vendors/unilateral/add-delivery-method/*': {
    'page.view': [unilateralPageEvent, 'add-delivery-method'],
    'select-category': [
      unilateralPageEvent,
      'select-delivery-method-${value}',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        paymentId: 'paymentId',
        payorOrgId: 'payorOrgId',
      },
    ],
    'go.back': [
      unilateralPageEvent,
      'go-back',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        paymentId: 'paymentId',
        payorOrgId: 'payorOrgId',
      },
    ],
    'vendors.addDeliveryMethodByLink.submitCTA': [unilateralPageEvent, 'add-new-delivery-method'],
    'log-in': [
      unilateralPageEvent,
      'go-log-in',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        paymentId: 'paymentId',
        payorOrgId: 'payorOrgId',
      },
    ],
    'save-delivery-method': [
      unilateralPageEvent,
      'add-delivery-method',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        organizationId: 'organizationId',
        unilateralRequestId: 'unilateralRequestId',
      },
    ],
    'option-selected-manualAddress': [unilateralPageEvent, 'select-manual-address', { address: 'option.label' }],
    'manualAddress.edit': [unilateralPageEvent, 'edit-manual-address'],
    'submit-address': [unilateralPageEvent, 'confirm-manual-address', { option: 'option' }],
    'vendors.addDeliveryMethodByLink.linkExistingMethod.submit': [unilateralPageEvent, 'link-meliome-delivery-method'],
    ...mapApiSlice('[DELIVERYMETHODS] ADDRESS VALIDATION', unilateralPageEvent, 'validate-manual-address'),
    ...mapApiSlice('[DELIVERYMETHODS] REPLACE VIRTUAL METHOD', unilateralPageEvent, 'submit-new-delivery-method', {
      request: { deliveryType: 'payload.deliveryMethod.deliveryType' },
      success: { deliveryType: 'payload.deliveryMethod.deliveryType' },
    }),
    ...mapApiSlice(
      '[DELIVERYMETHODS] COPY_WITH_UNILATERAL_TOKEN',
      unilateralPageEvent,
      'copy-delivery-method-from-owned-vendor'
    ),
    ...mapApiSlice('[DELIVERYMETHODS] UPDATE_WITH_UNILATERAL_TOKEN', unilateralPageEvent, 'update-delivery-method', {
      request: { vendorId: 'payload.vendorId', paymentId: 'payload.paymentId' },
    }),
  },
  '/vendors/unilateral/add-delivery-method/*/switch-company': {
    'page.view': [unilateralPageEvent, 'switch-company'],
    'option-selected-organization': [unilateralPageEvent, 'select-organization', { selectedOrgId: 'option.id' }],
    'vendors.addDeliveryMethodByLink.switchCompanyCTA': [unilateralPageEvent, 'switch-organization'],
  },
  '/vendors/unilateral/add-delivery-method/*/shifted-current-payment': {
    'page.view': [unilateralPageEvent, 'current-payment-shifted-to-ach'],
  },
  '/vendors/unilateral/add-delivery-method/*/success': {
    'page.view': [unilateralPageEvent, 'delivery-method-success'],
    'vendors.addDeliveryMethodByLink.signUpCTA': [unilateralPageEvent, 'sign-up'],
    'log-in': [
      unilateralPageEvent,
      'go-log-in',
      {
        flow: 'flow',
        vendorId: 'vendorId',
        vendorEmail: 'vendorEmail',
        paymentId: 'paymentId',
        payorOrgId: 'payorOrgId',
      },
    ],
    'vendors.addDeliveryMethodByLink.success.joinService.button': [unilateralPageEvent, 'quickbooks-signup'],
  },
  '/vendors/unilateral/add-delivery-method/*/invalid-link': {
    'page.view': [unilateralPageEvent, 'invalid-token'],
  },
  '/vendors/add-debit-card/*': {
    'ptd-starts': [shiftToDebitPageEvent, 'starts'],
    'go.back': [shiftToDebitPageEvent, 'go-back'],
  },
  '/vendors/add-debit-card/*/address': {
    'page.view': [shiftToDebitPageEvent, 'add-address-details'],
    'go.back': [shiftToDebitPageEvent, 'go-back'],
    ...mapApiSlice('[DELIVERYMETHODS] ADDRESS VALIDATION', shiftToDebitPageEvent, 'validate-address'),
    ...mapApiSlice('[DELIVERYMETHODS] ADD_CARD_ACCOUNT', shiftToDebitPageEvent, 'add-debit-card'),
    ...mapApiSlice('[DELIVERYMETHODS] CREATE_CARD_ACCOUNT', shiftToDebitPageEvent, 'add-debit-card'),
    ...mapApiSlice('[PAYMENTS] UPDATE', shiftToDebitPageEvent, 'update-payment'),
    'option-selected-manualAddress': [shiftToDebitPageEvent, 'select-manual-address', { address: 'option.label' }],
    'manualAddress.edit': [shiftToDebitPageEvent, 'edit-manual-address'],
  },
  '/vendors/add-debit-card/*/invalid-link': {
    'page.view': [shiftToDebitPageEvent, 'error-page'],
    'support-email': [shiftToDebitPageEvent, 'payment-cancelled-support-email'],
  },
  '/vendors/add-debit-card/*/payment-blocked': {
    'page.view': [shiftToDebitPageEvent, 'payment-blocked-by-risk'],
    'support-email': [shiftToDebitPageEvent, 'payment-blocked-support-email'],
  },
  '/vendors/add-debit-card/*/payment-failed': {
    'page.view': [shiftToDebitPageEvent, 'payment-failed'],
    'support-email': [shiftToDebitPageEvent, 'payment-failed-support-email'],
  },
  '/vendors/add-debit-card/*/offer-expired': {
    'page.view': [shiftToDebitPageEvent, 'expired-offer'],
    'vendors.editCheckAddress.checkOfferExpired.upsaleCTA': [shiftToDebitPageEvent, 'expired-offer-get-paid-by-bank'],
  },
  '/vendors/add-debit-card/*/check-deposited': {
    'page.view': [shiftToDebitPageEvent, 'check-already-deposit'],
    'vendors.editCheckAddress.checkDeposited.upsaleCTA': [shiftToDebitPageEvent, 'check-deposited-get-paid-by-bank'],
  },
  '/vendors/add-debit-card/*/success': {
    'page.view': [shiftToDebitPageEvent, 'debit-card-added'],
    'vendors.deliveryMethods.shiftToDebit.success.upSaleCTA': [
      shiftToDebitPageEvent,
      'debit-card-added-meliome-register',
    ],
  },
  '/vendors/virtual-card-candidates/*/details': {
    'page.view': [virtualCardCandidates, 'virtual-card-candidate-details'],
    'vendors.virtualCard.candidates.form.action': [virtualCardCandidates, 'click-cta'],
  },
  '/vendors/virtual-card-candidates/*/success': {
    'page.view': [virtualCardCandidates, 'virtual-card-candidate-success'],
  },
  '/vendors/virtual-card-candidates/*/already-filled': {
    'page.view': [virtualCardCandidates, 'virtual-card-candidate-already-filled'],
  },
  '/vendors/virtual-card-candidates/*/invalid': {
    'page.view': [virtualCardCandidates, 'virtual-card-candidate-invalid'],
  },
  ...internationalDeliveryMethodEventMapping,
};

export default MAPPING;
