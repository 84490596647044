import { useCallback, useState } from 'react';
import { validateUserOrgData } from 'src/modules/users/utils/validateUserOrgData';
import { OrganizationWithPermission } from 'src/pages/team/types';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { Role, UserOrganizationPermissionEnum } from 'src/utils/consts';
import { CompanyInfoType, UserOrganization } from 'src/utils/types';
import { ClientModelViewType, UserType } from './types';

type Props = {
  assignToUser: UserType;
  firmOrgId: number;
  organizations: OrganizationWithPermission[];
  actorUserOrgs: readonly UserOrganization[];
};

type ClientItemType = Pick<CompanyInfoType, 'id' | 'companyName'> & {
  assigned: boolean;
  userOrg: UserOrganization;
};

type ValidationError<T> = {
  type: 'validation';
  errors: {
    userOrganization: UserOrganization;
    validationErrors: Partial<{ [F in keyof T]: string }>;
  }[];
};

export function useAssignClientsState({ assignToUser, organizations, firmOrgId, actorUserOrgs }: Props) {
  const [modelState, setModelState] = useState(() =>
    organizations
      .filter(
        (org) => org.id !== firmOrgId && org?.userOrganization?.permission !== UserOrganizationPermissionEnum.BLOCKED
      )
      .map<ClientItemType>((org) => {
        const actorUserOrg = org.id ? getActorUserOrg(actorUserOrgs, org.id) : undefined;
        const userOrg = assignToUser.userOrganizations.find(
          (userOrg) => userOrg.organizationId === org.id // already assigned
        );
        const finalUserOrg =
          userOrg ||
          getEmptyUserOrg({
            userId: assignToUser.id,
            organizationId: org.id,
          });

        return {
          id: org.id,
          companyName: org.companyName || '',
          assigned: !!userOrg,
          userOrg: {
            ...finalUserOrg,
            requireApproval: actorUserOrg?.requireApproval ? true : finalUserOrg.requireApproval,
          },
        };
      })
      .sort((a, b) => (a.companyName ?? '').toUpperCase().localeCompare((b.companyName ?? '').toUpperCase()))
  );
  // an object for each orgId
  const [validationErrors, setValidationErrors] = useState(() =>
    modelState.reduce((all, cur) => {
      if (cur.id) {
        all[cur.id] = {};
      }

      return all;
    }, {})
  );
  const setValidationErrorsPerOrg = useCallback(({ orgId, value }: { orgId: number; value: any }) => {
    setValidationErrors((prevVal) => ({
      ...prevVal,
      [orgId]: value,
    }));
  }, []);
  const setValidationErrorsPerOrgPerKey = useCallback(
    ({ key, orgId, value }: { key: string; orgId: number; value: any }) => {
      setValidationErrors((prevVal) => ({
        ...prevVal,
        [orgId]: {
          ...prevVal[orgId],
          [key]: value,
        },
      }));
    },
    []
  );
  function getModelViewField({ key, state, orgId }) {
    return {
      value: state[key],
      id: key + orgId,
      onChange({ value }) {
        if (validationErrors[orgId][key]) {
          setValidationErrorsPerOrgPerKey({ key, orgId, value: undefined });
        }

        setModelState((prevState) => {
          const newState = [...prevState];
          const index = newState.findIndex((clientItem) => clientItem.id === orgId);

          if (key === 'assigned') {
            newState[index] = {
              ...newState[index],
              [key]: value,
            };
          } else {
            const actorUserOrg = getActorUserOrg(actorUserOrgs, orgId);

            const getOverriddenValue = () => {
              if (key === 'requireApproval' && actorUserOrg?.requireApproval) return true;

              if (key === 'approvalAmountThreshold' && value) return Number(value);

              return value;
            };

            newState[index] = {
              ...newState[index],
              userOrg: {
                ...newState[index].userOrg,
                [key]: getOverriddenValue(),
              },
            };
          }

          return newState;
        });
      },
      changeAndUpdate: () => Promise.resolve(),
      setError(errMessage?: string) {
        setValidationErrorsPerOrgPerKey({ key, orgId, value: errMessage });
      },
      error: validationErrors[orgId][key],
    };
  }
  const clientItems = modelState.map<ClientModelViewType>((client) => {
    const { id: orgId, userOrg } = client;

    return {
      companyName: getModelViewField({
        key: 'companyName',
        orgId,
        state: client,
      }),
      assigned: getModelViewField({
        key: 'assigned',
        orgId,
        state: client,
      }),
      role: getModelViewField({
        key: 'role',
        orgId,
        state: userOrg,
      }),
      requireApproval: getModelViewField({
        key: 'requireApproval',
        orgId,
        state: userOrg,
      }),
      approvalAmountThreshold: getModelViewField({
        key: 'approvalAmountThreshold',
        orgId,
        state: userOrg,
      }),
      organizationId: getModelViewField({
        key: 'organizationId',
        orgId,
        state: userOrg,
      }),
      userId: getModelViewField({
        key: 'userId',
        orgId,
        state: userOrg,
      }),
    };
  });

  function getAssignedUserOrgs() {
    return modelState
      .filter((model) => model.assigned)
      .map<UserOrganization>((model) => {
        const requireApproval = isAdminRole(model.userOrg.role) ? false : model.userOrg.requireApproval;
        const approvalAmountThreshold = requireApproval ? model.userOrg.approvalAmountThreshold : null;

        return {
          ...model.userOrg,
          requireApproval,
          approvalAmountThreshold,
        };
      });
  }

  function validate(actorUserOrgs: readonly UserOrganization[], schema: 'userOrganization' | 'invitation') {
    const assignedUserOrgs = getAssignedUserOrgs();

    const errors: {
      userOrganization: UserOrganization;
      validationErrors: Record<string, string>;
    }[] = [];
    assignedUserOrgs.forEach((userOrg) => {
      const actorUserOrg = actorUserOrgs.find((uo) => uo.organizationId === userOrg.organizationId);
      try {
        validateUserOrgData(schema, userOrg, actorUserOrg);
      } catch (e: any) {
        if (e.error?.validationErrors) {
          errors.push({
            userOrganization: userOrg,
            validationErrors: e.error?.validationErrors,
          });
          setValidationErrorsPerOrg({
            orgId: userOrg.organizationId,
            value: e.error?.validationErrors,
          });
        } else {
          throw e;
        }
      }
    });

    if (errors.length) {
      const err: ValidationError<UserOrganization> = {
        type: 'validation',
        errors,
      };
      throw err;
    }
  }

  return {
    clients: clientItems,
    getAssignedUserOrgs,
    validate,
  };
}

function getEmptyUserOrg({ userId, organizationId }): UserOrganization {
  return {
    id: 0,
    userId,
    organizationId,
    requireApproval: true,
    approvalAmountThreshold: null,
    role: Role.ACCOUNTANT,
    accessLevel: 'full',
    isHidden: false,
  };
}

function getActorUserOrg(actorUserOrgs: readonly UserOrganization[], orgId: number): UserOrganization | undefined {
  return actorUserOrgs.find((uo) => uo.organizationId === orgId);
}
