/* eslint-disable no-bitwise */
import _get from 'lodash/get';
import { useCallback } from 'react';
import { useStyledTheme } from 'src/utils/hooks';

const colorPaths = [
  'colors.warning.translucent2',
  'colors.expired.translucent2',
  'colors.failure.translucent2',
  'colors.primary.translucent2',
  'colors.accented.translucent2',
];

type ReturnType = {
  getColorProps: (companyName: string) => { color: string; pathInTheme: string };
};
export const useClientBgColor = (): ReturnType => {
  const theme = useStyledTheme();

  const getColorProps = useCallback(
    (companyName: string) => {
      const index = hashCode(companyName) % colorPaths.length;
      const path = colorPaths[index];

      return {
        color: _get(theme, path),
        pathInTheme: path,
      };
    },
    [theme]
  );

  return {
    getColorProps,
  };
};

const cache = {};
function hashCode(str) {
  if (!str || str.length === 0) return 0;

  if (cache[str]) return cache[str];

  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  hash = Math.abs(hash);

  cache[str] = hash;

  return hash;
}
