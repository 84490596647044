import { featureFlags } from '@melio/shared-web';
import { Location } from 'history';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { FeatureFlags } from 'src/utils/featureFlags';
import { ToNavigationType } from 'src/utils/types';

export type MenuItemType = {
  key: string;
  to: ToNavigationType;
  label: string;
};

export const useFabMenuItems = (orgId: number, location: Location): MenuItemType[] => {
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);

  const contactCreateLinks = useMemo<MenuItemType[]>(() => {
    if (shouldSplitContactsTab) {
      return [
        {
          key: 'fab-vendor',
          to: {
            pathname: contactsLocations.create.vendorsSingle,
            state: {
              returnLocations: location,
            },
          },
          label: 'fab.vendor',
        },
        {
          key: 'fab-customer',
          to: {
            pathname: contactsLocations.create.customersSingle,
            state: {
              returnLocations: location,
            },
          },
          label: 'fab.customer',
        },
      ];
    }

    return [
      {
        key: 'fab-contact',
        to: {
          pathname: contactsLocations.create.type,
          state: {
            returnLocations: location,
          },
        },
        label: 'fab.contact',
      },
    ];
  }, [location, shouldSplitContactsTab]);

  return useMemo<MenuItemType[]>(
    () => [
      {
        key: 'fab-bill',
        to: billLocations.create.options,
        label: 'fab.bill',
      },
      {
        key: 'fab-request',
        to: {
          pathname: generatePath(getPaidLocations.create.options, { orgId }),
          state: {
            returnLocations: location,
          },
        },
        label: 'fab.paymentRequest',
      },
      ...contactCreateLinks,
    ],
    [orgId, location, contactCreateLinks]
  );
};
