import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  testId?: string;
  tooltipLabel: string;
};

export const ProgressWarningTooltip = ({ testId, tooltipLabel }: Props) => (
  <Tooltip placement="top" label={<MIFormattedText label={tooltipLabel} />}>
    <Flex data-testid={testId} as="i" fontSize={4} pt={1} justify="center">
      <Icon name={IconNames.warning} size={IconSize.s} color="yellow.500" />
    </Flex>
  </Tooltip>
);
