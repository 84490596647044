import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { withNavigator } from '../../../hoc';
import CompanyNameForm from './components/CompanyNameForm';
import { CompanyInfoProps, withCompanyInfoUpdates } from './hoc/withCompanyInfoUpdates';

const CompanyNamePageContainer = ({
  onNext,
  onPrev,
  onChange,
  companyInfo,
  isLoading,
  validationErrors,
}: CompanyInfoProps) => {
  const { companyType } = useSelector(getCompanyInfo);

  return (
    <CompanyNameForm
      onNext={onNext}
      onPrev={onPrev}
      onChange={onChange}
      companyName={companyInfo.companyName ?? ''}
      companyType={companyType}
      isLoading={isLoading}
      validationErrors={validationErrors}
    />
  );
};

export default compose(withNavigator(), withCompanyInfoUpdates())(CompanyNamePageContainer);
