import { MouseEvent as ReactMouseEvent } from 'react';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';

type Props = {
  onCancel: (event: ReactMouseEvent) => void;
  onRemove: (event: ReactMouseEvent) => void;
};

export const DeleteAdminModalMessageButtons = ({ onRemove, onCancel }: Props) => (
  <ModalButtonsWrapper>
    <Button
      label="user.deleteAdminModal.cancel"
      variant={ButtonVariants.tertiaryNaked}
      size={ButtonSizes.lg}
      onClick={onCancel}
    />
    <Button
      label="user.deleteAdminModal.submit"
      testId="button-user.deleteDialog.confirm"
      variant={ButtonVariants.error}
      size={ButtonSizes.lg}
      onClick={onRemove}
    />
  </ModalButtonsWrapper>
);
