import * as React from 'react';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { CompanyType } from 'src/utils/consts';
import { handleInputChange } from 'src/utils/input';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  onChange: (companyName: string) => void;
  companyName: string;
  companyType: CompanyType | null | undefined;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
};

const CompanyNameForm: React.FC<Props> = ({
  companyName,
  companyType,
  onNext,
  onPrev,
  onChange,
  isLoading,
  validationErrors,
}: Props) => {
  const isAccountingFirm = companyType === CompanyType.ACCOUNTING_FIRM;
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const title = isAccountingFirm
    ? 'onboarding.companyInfo.name.accountingFirm.title'
    : 'onboarding.companyInfo.name.title';
  const inputLabel = isAccountingFirm
    ? 'onboarding.companyInfo.name.accountingFirm.inputTitle'
    : 'onboarding.companyInfo.name.inputTitle';

  const getProgressBarRelativeStep = () => (isUnifiedOnboardingOpen ? 3 / 13 : 3 / 7);

  return (
    <StepLayoutPage
      title={title}
      relativeStep={getProgressBarRelativeStep()}
      onNext={onNext}
      onPrev={onPrev}
      isLoading={isLoading}
    >
      <TextField
        id="companyNameInput"
        label={inputLabel}
        helperText="onboarding.companyInfo.name.inputHint"
        value={companyName}
        onSubmit={onNext}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          handleInputChange({ event, onChange, valueOnly: true })
        }
        errorMessage={validationErrors.companyName}
        autoFocus
        isRequired
      />
    </StepLayoutPage>
  );
};

export default CompanyNameForm;
