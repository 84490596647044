import { useEffect } from 'react';
import { analytics } from 'src/services/analytics';
import { MfaAuthenticatorMethodType, UserContextType } from 'src/utils/types';
import { MfaAuthenticatorMethod, MfaCodeVerificationStatusType } from '../consts';

const eventPage = 'verify-its-you';

type Props = {
  user: Partial<UserContextType>;
  authenticatorType: MfaAuthenticatorMethodType;
  isMoreThanOneAuthenticator: boolean;
};

type ReturnValue = {
  trackCodeEntered: (
    success: boolean,
    errorType?: string,
    errorTypeApi?: MfaCodeVerificationStatusType,
    remainingAttempts?: number | null,
    remainingCodeChallengeAttempts?: number | null,
    remainingCodeVerificationAttempts?: number | null
  ) => void;
  trackOnPrev: VoidFunction;
  trackStartOver: VoidFunction;
  trackSupportClicked: VoidFunction;
  trackResendClicked: VoidFunction;
  trackSendToPhoneClicked: VoidFunction;
  trackSendToEmailClicked: VoidFunction;
};

export const useMfaCodeVerificationAnalytics = ({
  user,
  authenticatorType,
  isMoreThanOneAuthenticator,
}: Props): ReturnValue => {
  const mapEligabilityType = {
    [MfaAuthenticatorMethod.SMS]: 'switch-to-email',
    [MfaAuthenticatorMethod.EMAIL]: 'switch-to-phone',
  };
  const eligabilityType = isMoreThanOneAuthenticator ? mapEligabilityType[authenticatorType] : null;
  const analyticsProperties = {
    UserEmail: user?.email,
    PageName: eventPage,
    Flow: 'sign-in',
    Intent: 'verification',
    EntryPoint: 'home-page',
    EligabilityType: eligabilityType,
    AuthenticatorType: authenticatorType,
  };

  useEffect(() => {
    analytics.track('Connection', 'View', analyticsProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackOnPrev = () => {
    analytics.track('Connection', 'Click', {
      ...analyticsProperties,
      Cta: 'back',
    });
  };

  const trackStartOver = () => {
    analytics.track('Connection', 'Click', {
      ...analyticsProperties,
      Cta: 'start-over',
    });
  };

  const trackCodeEntered = (
    success: boolean,
    errorType?: string,
    errorTypeApi?: MfaCodeVerificationStatusType,
    remainingAttempts?: number | null,
    remainingCodeChallengeAttempts?: number | null,
    remainingCodeVerificationAttempts?: number | null
    // eslint-disable-next-line max-params
  ) => {
    analytics.track('Connection', 'Status', {
      Status: success ? 'success' : 'failure',
      ErrorType: errorType,
      ErrorTypeApi: errorTypeApi,
      RemainingAttempts: remainingAttempts || null,
      RemainingCodeChallengeAttempts: remainingCodeChallengeAttempts || null,
      RemainingCodeVerificationAttempts: remainingCodeVerificationAttempts || null,
    });
  };

  const trackSupportClicked = () => {
    analytics.track('Connection', 'Click', {
      ...analyticsProperties,
      Cta: 'contact-support',
    });
  };

  const trackResendClicked = () => {
    analytics.track('Connection', 'Click', {
      ...analyticsProperties,
      Cta: 'resend-code',
    });
  };

  const trackSendToPhoneClicked = () => {
    analytics.track('Connection', 'Click', {
      ...analyticsProperties,
      Cta: 'send-to-my-phone',
    });
  };

  const trackSendToEmailClicked = () => {
    analytics.track('Connection', 'Click', {
      ...analyticsProperties,
      Cta: 'send-to-my-email',
    });
  };

  return {
    trackOnPrev,
    trackStartOver,
    trackCodeEntered,
    trackSupportClicked,
    trackResendClicked,
    trackSendToPhoneClicked,
    trackSendToEmailClicked,
  };
};
