import concat from 'lodash/concat';
import isObject from 'lodash/isObject';
import last from 'lodash/last';
import reduce from 'lodash/reduce';
import toLower from 'lodash/toLower';
import { EventMappingType } from './types';

type MappingsType = {
  request?: Record<string, number | string | boolean>;
  failure?: Record<string, number | string | boolean>;
  validationError?: Record<string, number | string | boolean>;
  success?: Record<string, number | string | boolean>;
};

export function mapApiSlice(
  sliceName: string,
  page: string,
  eventType: string,
  mappings?: MappingsType
): Record<string, EventMappingType> {
  return {
    [`${sliceName}_REQUEST`]: [page, `${eventType}-request`, { id: 'payload.id', ...(mappings?.request || {}) }],
    [`${sliceName}_FAILURE`]: [page, `${eventType}-error`, { error: 'error', ...(mappings?.failure || {}) }],
    [`${sliceName}_VALIDATION_FAILURE`]: [
      page,
      `${eventType}-validation-error`,
      {
        validation: 'error.validationErrors',
        ...(mappings?.validationError || {}),
      },
    ],
    [`${sliceName}_SUCCESS`]: [page, `${eventType}-success`, { ...(mappings?.success || {}) }],
  };
}

const getFlatListOfUrlsFromLocation = (locations: Record<string, any>): string[] =>
  reduce(
    locations,
    (acc: string[], value: Record<string, any> | string, key: string) => {
      if (key === 'base') return acc;

      if (isObject(value)) {
        return concat(acc, getFlatListOfUrlsFromLocation(value));
      }

      return concat(acc, value.replace(/:[\da-zA-Z]*/g, '*'));
    },
    []
  );

export function createPageViewEventsFromLocations(eventPage: string, locations: Record<string, any>) {
  const urls = getFlatListOfUrlsFromLocation(locations).filter((url) => url.slice(-1) !== '*');
  const getEventName = (url) => toLower(last(url.split('/')));

  return reduce(
    urls,
    (acc, url) => ({
      ...acc,
      [url]: { 'page.view': [eventPage, getEventName(url)] },
    }),
    {}
  );
}
