import { FileLoader } from 'src/components/common/FileLoader';
import { ButtonVariants } from 'src/core/ds/button';

type Props = {
  onCancelAction: () => void;
};

export const BillFileLoader = ({ onCancelAction }: Props) => (
  <FileLoader
    title="bills.new.uploading.title"
    subtitle="bills.new.uploading.subtitle"
    buttonLabel="bills.new.cancel"
    onCancelAction={onCancelAction}
    buttonVariant={ButtonVariants.tertiaryNaked}
  />
);
