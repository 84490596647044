import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { Stack } from 'src/core/ds/stack';

type Props = {
  illustration: IllustrationName;
  title: string;
  description: string;
  footer: React.ReactNode;
  cancelButtonLabel: string;
  actionButtonLabel?: string;
  onCancel: () => void;
  onAction?: () => void;
};

export const FinancingErrorStatus = ({
  illustration,
  title,
  description,
  footer,
  actionButtonLabel,
  cancelButtonLabel,
  onAction,
  onCancel,
}: Props) => {
  const buttons = actionButtonLabel ? (
    <>
      <Button
        w="full"
        variant={ButtonVariants.primary}
        size={ButtonSizes.lg}
        onClick={onAction}
        data-testid="financing-error-action-cta"
      >
        <MIFormattedText label={actionButtonLabel} />
      </Button>
      <Button
        w="full"
        mt={2}
        variant={ButtonVariants.secondary}
        size={ButtonSizes.lg}
        onClick={onCancel}
        data-testid="financing-error-cancel-cta"
      >
        <MIFormattedText label={cancelButtonLabel} />
      </Button>
    </>
  ) : (
    <Button
      w="full"
      mt={2}
      variant={ButtonVariants.primary}
      size={ButtonSizes.lg}
      onClick={onCancel}
      data-testid="financing-error-cancel-cta"
    >
      <MIFormattedText label={cancelButtonLabel} />
    </Button>
  );

  return (
    <WizardLayout>
      <WizardLayout.Content>
        <WizardLayout.Header>
          <Stack direction="column" spacing={3}>
            <Box textAlign="center" textStyle="h1Semi" data-testid="financing-error-status-title">
              <Box mb={8}>
                <Illustration name={illustration} size={IllustrationSize.lg} />
              </Box>
              <MIFormattedText label={title} />
            </Box>
            <Box textAlign="center" textStyle="body2">
              <MIFormattedText label={description} />
            </Box>
          </Stack>
        </WizardLayout.Header>
        <WizardLayout.Body>
          <Stack w="full" direction="column" spacing={4}>
            {buttons}
          </Stack>
        </WizardLayout.Body>
        <WizardLayout.Footer>
          <Box textAlign="center" textStyle="body3">
            {footer}
          </Box>
        </WizardLayout.Footer>
      </WizardLayout.Content>
    </WizardLayout>
  );
};
