const baseUrl = '/orgs/:orgId/get-paid';

export const getPaidLocations = {
  base: baseUrl,
  dashboard: baseUrl,
  index: baseUrl,
  create: {
    base: `${baseUrl}/new`,
    batchInvoices: {
      batch: `${baseUrl}/new/batch`,
      batchExplain: `${baseUrl}/new/batch-csv-explain`,
    },
    index: `${baseUrl}/new`,
    options: `${baseUrl}/new/options`,
    manually: `${baseUrl}/new/manually`,
    invoice: `${baseUrl}/new/invoice`,
    success: `${baseUrl}/new/success`,
    connectAccountingSoftware: {
      connect: `${baseUrl}/new/connect-accounting-software`,
      unsupportedAccountingSoftware: {
        unsupported: `${baseUrl}/new/connect-accounting-software/unsupported`,
        success: `${baseUrl}/new/connect-accounting-software/unsupported/success`,
      },
    },
    completeLegalInfo: `${baseUrl}/new/complete-legal-info`,
    fileInvoice: `${baseUrl}/new/file-invoice`,
    share: `${baseUrl}/new/share`,
    'complete-legal-info': `${baseUrl}/new/complete-legal-info`,
  },
  verifyMicroDeposit: '/orgs/:orgId/settings/receiving-methods',
  view: `${baseUrl}?id=:id`,
};
