import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { BillingFeeType } from 'src/utils/consts';

type CreateOrganizationBillingFeeParams = {
  fundingSourceId: number;
  managedByOrganizationId?: number | null;
  isActive?: boolean;
  billingFeeType?: BillingFeeType;
};

type UpdateOrganizationBillingFeeParams = {
  fundingSourceId: number;
  managedByOrganizationId?: number | null;
  isActive?: boolean;
};

export const organizationBillingFeeApi = {
  getBillingFees({ orgId }) {
    const url = `/orgs/${orgId}/billing-fee`;

    return fetchRequest(url);
  },

  createBillingFee({ orgId, billingFee }: { orgId: number; billingFee: CreateOrganizationBillingFeeParams }) {
    const url = `/orgs/${orgId}/billing-fee`;

    return postRequest(url, { ...billingFee });
  },

  updateBillingFee({
    orgId,
    billingFeeId,
    billingFee,
  }: {
    orgId: number;
    billingFeeId: number;
    billingFee: UpdateOrganizationBillingFeeParams;
  }) {
    const url = `/orgs/${orgId}/billing-fee/${billingFeeId}`;

    return putRequest(url, { ...billingFee });
  },

  deleteBillingFee({ orgId, billingFeeId }) {
    const url = `/orgs/${orgId}/billing-fee/${billingFeeId}`;

    return deleteRequest(url);
  },
};
