import { featureFlags } from '@melio/shared-web';
import welcomeAnimation from 'src/animations/get-pro/welcome.json';
import { Lottie } from 'src/components/common/Lottie';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Link } from 'src/core/ds/link';
import { HStack, VStack } from 'src/core/ds/stack';
import { FeatureFlags } from 'src/utils/consts';
import { LEARN_MORE_LINK, WELCOME_MODAL_PHRASES_NUMBERS } from './consts';

type WelcomeModalProps = {
  dismiss(): void;
};

export const WelcomeModal = ({ dismiss }: WelcomeModalProps) => {
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const url = shouldDisplayZendesk
    ? 'https://melio.zendesk.com/hc/en-us/articles/4447570874642-Get-to-know-the-Get-Paid-dashboard'
    : LEARN_MORE_LINK;

  return (
    <ModalMessage
      onCloseClick={dismiss}
      largeSize
      titleComponent={
        <Box mb={2} borderRadius="md" overflow="hidden">
          <Lottie height="28rem" width="56rem" animationData={welcomeAnimation} loop={false} />
        </Box>
      }
      contentComponent={
        <VStack spacing={3} alignItems="flex-start" mb={2}>
          <Box textAlign="left" textStyle="h2Semi">
            <MIFormattedText label="getPro.modals.welcomeModal.title" />
          </Box>
          <Box textAlign="left" textStyle="body2">
            <VStack spacing={2} alignItems="flex-start">
              <Box>
                <MIFormattedText label="getPro.modals.welcomeModal.content.title" />
              </Box>
              {WELCOME_MODAL_PHRASES_NUMBERS.map((phraseNumber) => (
                <HStack key={phraseNumber}>
                  <Box mr={3}>
                    <Icon name={IconNames.check} size={IconSize.lg} color="primary.500" />
                  </Box>
                  <MIFormattedText label={`getPro.modals.welcomeModal.content.phrase${phraseNumber}`} />
                </HStack>
              ))}
              <Link href={url} isExternal>
                <MIFormattedText label="getPro.modals.welcomeModal.learnMoreLink" />
              </Link>
            </VStack>
          </Box>
        </VStack>
      }
      buttonComponent={
        <Flex justifyContent="center">
          <Button
            onClick={dismiss}
            label="getPro.modals.welcomeModal.confirmButton"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            testId="getPro.welcomeModal.gotItButton"
          />
        </Flex>
      }
    />
  );
};
