import * as React from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Spinner } from '../common/Spinner';

type Props = {
  isLoading: boolean;
  children: React.ReactNode;
};

export const LoadingOverlay = ({ isLoading, children }: Props) => (
  <>
    <Box h="full" w="full" bgColor="white" zIndex={1000}>
      {isLoading && (
        <Flex justify="center" align="center" pos="absolute" top={0} left={0} right={0} bottom={0} zIndex={10}>
          <Spinner />
        </Flex>
      )}
      {children}
    </Box>
  </>
);

LoadingOverlay.defaultProps = {};
