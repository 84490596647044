import isEmpty from 'lodash/isEmpty';
import { generatePath } from 'react-router-dom';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { AddAchFlow } from 'src/pages/vendor/delivery-methods/add-ach-flow/AddAchFlow';
import { FlowCustomizationsType } from '../types';

type Props = {
  orgId: number;
  vendorId: number;
  deliveryMethodId?: string;
};

export const AddAchFromOnboardingFlowWrapper = ({ orgId, vendorId, deliveryMethodId }: Props) => {
  const isEdit = !isEmpty(deliveryMethodId);

  const { vendor } = useFetchVendor(vendorId);
  const vendorName = vendor?.companyName;

  const receivingMethodsUrl = generatePath(settingsLocations.receivingMethods, { orgId });
  const vendorCompanyInfoUrl = generatePath(onboardingLocations.vendorCompanyInfo.added, { orgId });
  const saveAndContinue = isEdit ? receivingMethodsUrl : vendorCompanyInfoUrl;

  const getTitle = () => {
    if (isEdit) {
      return 'onboarding.deliveryMethods.bank.title';
    }

    return isEmpty(vendorName)
      ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
      : 'vendors.deliveryMethods.bank.title';
  };

  const flowCustomizations: FlowCustomizationsType = {
    stepLayout: {
      hideHeader: true,
      title: getTitle(),
      titleValues: { vendor: vendorName },
      subtitle: isEdit ? 'onboarding.deliveryMethods.bank.melioMe.subtitle' : undefined,
      nextLabel: isEdit ? 'onboarding.deliveryMethods.bank.save' : 'vendors.deliveryMethods.bank.save',
      fullWidthCTA: true,
    },
    firstStep: {
      inputLabels: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
    },
  };

  return (
    <AddAchFlow
      vendorId={vendorId}
      deliveryMethodId={deliveryMethodId}
      flowCustomizations={flowCustomizations}
      saveAndContinue={saveAndContinue}
    />
  );
};
