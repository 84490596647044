import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { AUTH_ACCEPT_INVITATION_SUCCESS, AUTH_ACCEPT_USER_INVITATION_SUCCESS } from 'src/modules/auth/auth-actions';
import { DELIVERY_METHOD_FETCH_UNILATERAL_REQUEST_DETAILS_SUCCESS } from 'src/modules/delivery-methods/delivery-method-actions';
import { INVITATIONS_VALIDATE_SUCCESS } from 'src/modules/invitations/invitations-actions';
import { name } from 'src/modules/organizations/constants';
import {
  assignPaymentRequestToOrgSlice,
  getOrgAssignedToPaymentRequestSlice,
} from 'src/modules/organizations/multi-orgs';
import {
  createNotOwnedOrganizationSlice,
  getUBOInfoSlice,
  requestSwitchAccountingFirmSlice,
  switchAccountingFirmSlice,
  unlinkQuickbookSlice,
  updateNewCompanySlice,
  updateOrganizationPreferenceSlice,
  updateOrganizationServiceFeesSlice,
} from 'src/modules/organizations/organizations-slice';
import {
  PAYMENTS_FETCH_DETAILS_WITH_TOKEN_SUCCESS,
  PAYMENTS_FETCH_EMAIL_TO_VENDOR_DETAILS_SUCCESS,
  PAYMENTS_FETCH_PAYMENT_UPSELL_DETAILS_SUCCESS,
} from 'src/modules/payments/payment-actions';
import {
  USERS_FETCH_ORGANIZATION_USERS_SUCCESS,
  USERS_HIDE_FIRM_CLIENT_SUCCESS,
  USERS_UNHIDE_FIRM_CLIENT_SUCCESS,
} from 'src/modules/users/users-actions';
import {
  IMPORT_QBO_CLIENTS,
  LOAD_COMPANY_LOGO_SUCCESS,
  SET_COMPANY_INFO,
  SET_PROFILE,
} from 'src/redux/user/actionTypes';
import { isQbdtOrganization } from 'src/utils/organizations';
import { CompanyInfoType, OrganizationPreferencesType } from 'src/utils/types';
import { OrganizationRestApi } from './api';

const persistConfig = {
  whitelist: ['create'],
};

const setOrganization = (state, shouldMerge = false) => (org: CompanyInfoType) => {
  if (!isQbdtOrganization(org.createOrigin)) {
    state.byId[org.id] = shouldMerge ? { ...state.byId[org.id], ...org } : org;
  }
};

const organizationStore = createRestfulSlice({
  name,
  schemaName: 'companyInfo',
  persistConfig,
  api: OrganizationRestApi,
  extraReducers: {
    [IMPORT_QBO_CLIENTS](state, { payload }) {
      const organizations = payload.importedClients.map(({ organization }) => organization);
      (organizations || []).forEach((organization) => {
        state.byId[organization.id] = organization;
      });
    },
    [SET_PROFILE](state, payload) {
      const { organizations } = payload;
      (organizations || []).forEach(setOrganization(state));
    },
    [SET_COMPANY_INFO](state, payload) {
      state.byId[payload.companyInfo.id] = {
        ...state.byId[payload.companyInfo.id],
        ...payload.companyInfo,
      };
    },
    [LOAD_COMPANY_LOGO_SUCCESS](state, payload) {
      state.byId[payload.orgId] = {
        ...state.byId[payload.orgId],
        logoUrl: payload.logoUrl,
      } as CompanyInfoType;
    },
    [INVITATIONS_VALIDATE_SUCCESS](state, action) {
      setOrganization(state)(action.payload.organization);
    },
    [AUTH_ACCEPT_INVITATION_SUCCESS](state, action) {
      const { organizations } = action.payload.user;
      (organizations || []).forEach(setOrganization(state));
    },
    [AUTH_ACCEPT_USER_INVITATION_SUCCESS](state, action) {
      const { organizations } = action.payload.user;
      (organizations || []).forEach(setOrganization(state));
    },
    [PAYMENTS_FETCH_DETAILS_WITH_TOKEN_SUCCESS](state, { payload }) {
      const { organization } = payload.payment;
      setOrganization(state)(organization);
    },
    [PAYMENTS_FETCH_PAYMENT_UPSELL_DETAILS_SUCCESS](state, { payload }) {
      const { organization } = payload.payment;
      setOrganization(state)(organization);
    },
    [PAYMENTS_FETCH_EMAIL_TO_VENDOR_DETAILS_SUCCESS](state, { payload }) {
      const { organization } = payload.payment;
      setOrganization(state)(organization);
    },
    [DELIVERY_METHOD_FETCH_UNILATERAL_REQUEST_DETAILS_SUCCESS](state, { payload }) {
      const { organization } = payload;
      setOrganization(state)(organization);
    },
    [USERS_FETCH_ORGANIZATION_USERS_SUCCESS](state, { payload }) {
      payload.orgsOfLoggedInUser.forEach(setOrganization(state, true));
    },
    [USERS_HIDE_FIRM_CLIENT_SUCCESS](state, { meta }) {
      const { userOrganization } = state.byId[meta.identifier.targetOrgId];

      if (userOrganization) {
        userOrganization.isHidden = true;
      }
    },
    [USERS_UNHIDE_FIRM_CLIENT_SUCCESS](state, { meta }) {
      const { userOrganization } = state.byId[meta.identifier.targetOrgId];

      if (userOrganization) {
        userOrganization.isHidden = false;
      }
    },
  },
  selectors: {
    byId: (id) => (state) => state[name].byId[id],
    getUserOrganization: (id) => (state) => state[name].byId[id]?.userOrganization,
    getNewCompanyInfo: (state) =>
      isEmpty(state[name].create?.company) ? { email: '', companyName: '' } : state[name].create?.company,
    isUBOInfoExists: (state) => state[name].ubo.isUBOInfoExists,
    organizationPreferences: (state) => {
      let preferences: OrganizationPreferencesType = state[name]?.organizationPreferences;

      const orgId = state.profile.currentOrganizationId;
      const users = filter(state.users.byId, (user) =>
        user.userOrganizations.some((userOrg) => userOrg.organizationId === orgId)
      );
      const shouldShowPaymentAuditLog = users.length > 1;

      if (preferences.showPaymentAuditLog == null && shouldShowPaymentAuditLog) {
        preferences = { ...preferences, showPaymentAuditLog: 'true' };
      }

      return preferences;
    },
    isOrganizationPreferencesUpdating: (state) => state[name]?.isOrganizationPreferencesUpdating,
  },
  slices: {
    unlinkQuickbookSlice,
    updateNewCompanySlice,
    createNotOwnedOrganizationSlice,
    assignPaymentRequestToOrg: assignPaymentRequestToOrgSlice,
    getOrgAssignedToPaymentRequest: getOrgAssignedToPaymentRequestSlice,
    switchAccountingFirm: switchAccountingFirmSlice,
    requestSwitchAccountingFirm: requestSwitchAccountingFirmSlice,
    getUBOInfo: getUBOInfoSlice,
    updateOrganizationPreference: updateOrganizationPreferenceSlice,
    updateOrganizationServiceFees: updateOrganizationServiceFeesSlice,
  },
});

export function getOrganizationStoreActions(dispatch: any) {
  return {
    updateNewCompanyInfo: (params: any) => dispatch(updateNewCompanySlice.actions.update(params)),
    createNotOwnedOrganization: (params: any, meta?: any) =>
      dispatch(createNotOwnedOrganizationSlice.actions(params, meta)),
    create: (params: any) => dispatch(organizationStore.actions.create(params)),
    updateOrganizationPreference: (params: any) =>
      dispatch(organizationStore.actions.updateOrganizationPreference(params)),
    updateOrganizationServiceFees: (params: any) =>
      dispatch(organizationStore.actions.updateOrganizationServiceFees(params)),
  };
}

export default organizationStore;
