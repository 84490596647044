import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { IconNames } from 'src/core/ds/icon';
import { useBreak } from 'src/hoc';
import { profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { useHasAccessToCurrentOrg } from 'src/pages/team/team-management/hooks/useHasAccessToCurrentOrg';
import { getCanContactSupport } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/consts';
import { notEmpty } from 'src/utils/notEmpty';
import { useIsSpendManagementEnabled } from '../../../../pages/spend-management/hooks/useIsSpendManagementEnabled';
import { MenuGroupType, MenuItemPropsType, SideMenuItemsType } from '../types/type';
import { useAccountantsGroupItems } from './side-menu/useAccountantsGroupItems';
import { useGetPaidGroupItems } from './side-menu/useGetPaidGroupItems';
import { useMsnItems } from './side-menu/useMsnItems';
import { usePayGroupItems } from './side-menu/usePayGroupItems';
import { useSpendManagementItems } from './side-menu/useSpendManagementItems';
import { useCanViewCompaniesScreen } from './useCanViewCompaniesScreen';
import { useCanViewTeamScreen } from './useCanViewTeamScreen';
import { orderMainMenuItems } from './utils/orderMainMenuItems';

export function useSideMenuItems(): SideMenuItemsType {
  const [shouldDisplayZendesk] = featureFlags.useFeature(FeatureFlags.SwitchToZendeskChat, false);
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const { hasAccessToCurrentOrg } = useHasAccessToCurrentOrg();
  const profile = useSelector(profileStore.selectors.profile);
  const { isDesktop } = useBreak();
  const canContactSupport = useSelector(getCanContactSupport);
  const canViewTeamScreen = useCanViewTeamScreen();
  const canViewCompaniesScreen = useCanViewCompaniesScreen();
  const { showMsnMenuItemsOnly, msnMenuItems } = useMsnItems();
  const { isSpendManagementEnabled: shouldDisplaySpendManagement } = useIsSpendManagementEnabled();

  const showingAccountantsItems = canViewCompaniesScreen || canViewTeamScreen;

  const items: { [key in MenuGroupType]: Array<MenuItemPropsType> } = {
    accountantsGroup: useAccountantsGroupItems(),
    payGroup: usePayGroupItems(),
    getPaidGroup: useGetPaidGroupItems(),
    spendManagementGroup: useSpendManagementItems(),
    contacts: [
      {
        key: 'menu-item-contacts',
        to: globalLocations.contacts.dashboard,
        label: 'nav.contacts',
        icon: IconNames.contacts,
        eventName: 'contacts-list',
      },
    ],
    settings: [
      {
        key: 'menu-item-settings',
        to: globalLocations.settings.index,
        label: 'nav.settings',
        icon: IconNames.settings,
        eventName: 'settings',
      },
    ],
    support: [
      {
        key: 'menu-item-support',
        to: globalLocations.settings.support,
        label: 'nav.support',
        icon: IconNames.chatEmpty,
        eventName: 'support',
        ...(shouldDisplayZendesk ? {} : { id: 'MelioIntercomLauncher' }),
      },
    ],
  };

  const bottomMenu = isDesktop
    ? [...(shouldSplitContactsTab ? [items.settings] : []), canContactSupport ? items.support : []]
    : [];

  const getMainItems = () => {
    if (showMsnMenuItemsOnly) {
      return [msnMenuItems];
    }

    return [
      ...orderMainMenuItems(items.getPaidGroup, items.payGroup, profile?.registrationFlow),
      ...(shouldDisplaySpendManagement ? [items.spendManagementGroup] : []),
      ...(!shouldSplitContactsTab ? [items.contacts] : []),
      ...(!shouldSplitContactsTab || !isDesktop ? [items.settings] : []),
    ];
  };

  const menuItems = [
    ...(showingAccountantsItems ? [items.accountantsGroup] : []),
    ...(hasAccessToCurrentOrg ? getMainItems() : []),
  ].filter(notEmpty);

  return {
    topMenu: menuItems,
    bottomMenu,
  };
}
