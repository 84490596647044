import { useStyleConfig } from '@chakra-ui/react';
import { CloseButton } from 'src/core/ds/closeButton/CloseButton';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import { ProgressBar } from 'src/core/ds/progress';
import { EmptyPlaceholder } from './EmptyPlaceholder';

type Props = {
  onBackClick?: () => void;
  onCancelClick?: () => void;
  progressBarValue?: number;
};

export const TopBar = ({ onBackClick, onCancelClick, progressBarValue }: Props) => {
  const style = useStyleConfig('TopBar');
  const shouldDisplayProgressBar = progressBarValue !== undefined;

  return (
    <Flex sx={style}>
      {onBackClick ? (
        <IconButton
          icon={<Icon name={IconNames.shevronLeft} size={IconSize.xl} />}
          onClick={onBackClick}
          aria-label="back"
        />
      ) : (
        <EmptyPlaceholder />
      )}
      {shouldDisplayProgressBar ? <ProgressBar value={progressBarValue} /> : <EmptyPlaceholder />}
      {onCancelClick ? (
        <CloseButton onClick={onCancelClick} size="lg" testId="wizard-topbar-cancel" />
      ) : (
        <EmptyPlaceholder />
      )}
    </Flex>
  );
};
