export enum Variant {
  filled = 'filled',
  flushed = 'flushed',
}

export enum Size {
  sm = 'sm',
  md = 'md',
}

export const MASK_FORMAT_OPTIONS = {
  USAPhone: '(###) ###-####',
  creditCard: '#### #### #### ####',
  microDeposit: '#.##',
  dateOfBirth: '##/##/####',
  // TODO: Replace all instances of 'taxId' with 'taxIdSSN' in the codebase to clarify the type of tax ID being used (SSN). https://meliorisk.atlassian.net/browse/ME-61909
  taxId: '###-##-####',
  taxIdSSN: '###-##-####',
  taxIdEIN: '##-#######',
  taxIdITIN: '9##-##-####',
};

export enum InputType {
  phone = 'phone',
  creditCard = 'creditCard',
  amount = 'amount',
  text = 'text',
}
