import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Link } from 'src/core/ds/link';
import { analytics } from 'src/services/analytics';
import { ButtonsRowPosition, DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  digits: string;
  onOkAction: () => void;
};

export const MicroDepositsNotSentDialog = ({ digits, onOkAction }: Props) => {
  const LEARN_HO_TO_VERIFY =
    'https://melio.zendesk.com/hc/en-us/articles/4447505319314-How-to-verify-a-bank-account-with-micro-deposits';

  const goLearnHowToVerify = () => {
    analytics.track('micro-deposits-unable-to-verify', 'click-learn-how-to-verify');
  };

  return (
    <Dialog
      type={DialogType.CONFIRM}
      variant={DialogVariants.SUCCESS}
      hideIcon
      title="settings.microDeposits.cantVerifyYetDialog.title"
      subtitle="settings.microDeposits.cantVerifyYetDialog.subtitle"
      subtitleValues={{
        learnMore: (
          <Link href={LEARN_HO_TO_VERIFY} target="_blank" isExternal onClick={goLearnHowToVerify}>
            <MIFormattedText label="settings.microDeposits.cantVerifyYetDialog.learnMore" />
          </Link>
        ),
        digits,
      }}
      okButtonText="settings.microDeposits.cantVerifyYetDialog.ok"
      onOkAction={onOkAction}
      buttonsRowPosition={ButtonsRowPosition.RIGHT}
      minHeight="36.8rem"
      hideClose
    />
  );
};
