import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Tooltip from 'src/core/ds/tooltip';
import { REGULAR_BATCH_PAYMENTS_BILLS_LIMIT } from 'src/pages/regular-batch-payments/BatchSettings/consts';

type Props = {
  id: string;
  onCheck: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isCheckboxMarked: boolean | undefined;
  isCheckboxDisabled: boolean | undefined;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
  shouldShowError?: boolean;
};

const BillListItemCheckbox = ({ id, onCheck, isCheckboxDisabled, isCheckboxMarked, shouldShowError }: Props) => (
  <Tooltip
    isDisabled={!isCheckboxDisabled}
    placement="top"
    label={
      <MIFormattedText
        label="regularBatchPayments.selection.tooltip"
        values={{ maxSelected: REGULAR_BATCH_PAYMENTS_BILLS_LIMIT }}
      />
    }
    isOpen={shouldShowError}
  >
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Checkbox
        data-testid={`bill-list-item-checkbox-${id}`}
        isChecked={isCheckboxMarked}
        colorScheme="primary"
        isDisabled={isCheckboxDisabled}
        onChange={onCheck}
        w={6}
        h={6}
      />
    </Box>
  </Tooltip>
);

export default BillListItemCheckbox;
