import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { cardsApi } from '../api';
import { CreateCardParams, UseCreateCardOptions } from '../types';

export const useCreateCard = () => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: createCard, loading, error } = useApi<[CreateCardParams], string>({
    api: cardsApi.create,
  });

  return {
    loading,
    error,
    createCard: ({ nickname, accountId, expiryDate, amount }: UseCreateCardOptions) =>
      createCard({ orgId, nickname, accountId, amount, expiryDate }),
  };
};
