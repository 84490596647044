const MAPPINGS = {
  '[INVOICES] UPDATE_SUCCESS': {
    type: 'success',
    msg: 'getPaid.update.success',
  },
  '[INVOICES] UPDATE_FAILURE': { type: 'error', msg: 'getPaid.update.error' },
  '[INVOICES] DELETE_FAILURE': { type: 'error', msg: 'getPaid.delete.error' },
  '[INVOICES] CANCEL_FAILURE': {
    type: 'error',
    msg: 'getPaid.cancelPaymentRequest.error',
  },
  '[INVOICES] CREATE_BATCH_FAILURE': {
    type: 'error',
    msg: 'getPaid.new.create.batch.errorNotification',
  },
  '[INVOICES] SHARE_SUCCESS': { type: 'success', msg: 'getPaid.share.success' },
  '[INVOICES] SHARE_FAILURE': { type: 'error', msg: 'getPaid.share.error' },
};

export default MAPPINGS;
