import React from 'react';
import { SyncWithAccountingSoftwareCardProps } from 'src/pages/settings/types';
import { SSOLogoMap } from 'src/pages/settings/utils/accounting-software/SSOLogoMap';
import { AccountingSoftware } from 'src/utils/consts';
import { SyncWithSSOCard } from './components/SyncWithSSOCard';

export const SyncWithMicrosoftDynamicsCard = ({
  model,
  viewMode,
  onEdit,
  isLoading,
}: SyncWithAccountingSoftwareCardProps) => (
  <SyncWithSSOCard
    isLoading={isLoading}
    model={model}
    viewMode={viewMode}
    onEdit={onEdit}
    accountingSoftwareType={AccountingSoftware.MICROSOFT_DYNAMICS_365}
    accountingSoftwareLogo={SSOLogoMap[AccountingSoftware.MICROSOFT_DYNAMICS_365]}
  />
);
