import { BoxProps, forwardRef } from '@chakra-ui/react';
import Box from 'src/core/ds/box';
import { IconNames, IconSize } from './consts';

export type IconProps = BoxProps & {
  name: IconNames;
  color?: string;
  size?: IconSize;
  testId?: string | null;
};

const Icon = forwardRef<IconProps, 'div'>(
  ({ name, color = 'currentColor', size = IconSize.m, testId, ...rest }, ref) =>
    name && (
      <Box as="svg" role="img" width={size} height={size} fill={color} data-testid={testId} ref={ref} {...rest}>
        <use href={`#${name}`} />
      </Box>
    )
);

export { Icon };
