import { featureFlags } from '@melio/shared-web';
import moment from 'moment';
import styled from 'styled-components';
import { DeliveryDate } from 'src/components/common/DeliveryDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import {
  EditElement,
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { ReactComponent as WarningIcon } from 'src/images/icons/warning-tri-icon.svg';
import { ChecksDelaysTooltip } from 'src/pages/bill/pay/components/ChecksDelays/ChecksDelaysTooltip';
import { PayBillFlowType, usePayBillFlowType } from 'src/pages/bill/pay/hooks/usePayBillFlowType';
import { analytics } from 'src/services/analytics';
import {
  ChecksDelaysTooltipTypes,
  DeliveryType,
  FastACHReviewAndConfirmEntryPoint,
  FeatureFlags,
  PaymentApprovalStatus,
  PaymentCollectStatus,
  PaymentDeliverStatus,
} from 'src/utils/consts';
import { isSameDay } from 'src/utils/dates';
import { isFastDeliveryType, isLateDeliveryType } from 'src/utils/delivery-methods';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { getLatestPayment, isUndepositedCheck } from 'src/utils/payments';
import { AccountType, BillType, DeliveryMethodType, DeliveryOptionType, PaymentType } from 'src/utils/types';
import { DeliveryDateInfoHint } from './DeliveryDateInfoHint';
import Tag from './Tag';

type Props = {
  payment: PaymentType;
  bill: BillType;
  deliveryMethod: DeliveryMethodType;
  fundingSource: AccountType;
  onEdit?: ((hasFastOptions?: boolean) => void) | null;
  deliveryOptions?: DeliveryOptionType[];
  isEditDisabled: boolean;
};

export const PayBillPaymentActivityDeliveryDateInfo = ({
  payment,
  bill,
  deliveryMethod,
  onEdit,
  fundingSource,
  deliveryOptions,
  isEditDisabled,
}: Props) => {
  const [experimentGroup] = featureFlags.useFeature<FastACHReviewAndConfirmEntryPoint>(
    FeatureFlags.FastACHReviewAndConfirmEntryPoint,
    FastACHReviewAndConfirmEntryPoint.ControlGroup
  );

  const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.USHolidaysChecks, false);
  const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);
  const isRecurringBill = !!bill.recurringBill;
  const isApprovalRequired = payment.approvalDecisionStatus === PaymentApprovalStatus.PENDING;
  const isCheck = deliveryMethod.deliveryType === DeliveryType.CHECK;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethod.deliveryType === DeliveryType.VIRTUAL;
  const isRPPSDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.RPPS;
  const isSameDayDelivery = isSameDay(payment.deliveryEta);
  const isFast = isFastDeliveryType(payment.deliveryPreference);
  const isLate = isLateDeliveryType(payment, bill);
  const showChecksDelaysTooltip = isChecksDelaysEnabled && isCheck && !isFast;
  const originPayment = getLatestPayment(bill?.payments);
  const isUndepositedCheckPayment = originPayment ? isUndepositedCheck(originPayment) : false;
  const isControlGroup = experimentGroup === FastACHReviewAndConfirmEntryPoint.ControlGroup;
  const isFailedOrUndepositedCheckPayment = payment?.metadata?.failedType || isUndepositedCheckPayment;
  const hasFastOptions = deliveryOptions && deliveryOptions.length > 1 && !!onEdit;
  const isCollectOrDeliveryNotFailed =
    payment.collectStatus !== PaymentCollectStatus.FAILED && payment.deliverStatus !== PaymentDeliverStatus.FAILED;
  const isShowExperimentalCtaAndNotice = isLate && isCollectOrDeliveryNotFailed && hasFastOptions && !isFast;
  const checkDelaysTooltipLabel = isGeneralCheckDelays
    ? 'bills.pay.date.checksDelays.general.tooltip'
    : 'bills.pay.date.checksDelays.tooltipGeneral';
  const checkDelaysDescriptionLabel = isGeneralCheckDelays
    ? 'bills.pay.date.checksDelays.general.title'
    : 'payBillPaymentActivity.deliveryDate.checksDelays';

  const isUpdated =
    originPayment &&
    isFailedOrUndepositedCheckPayment &&
    moment(payment?.deliveryEta).diff(moment(originPayment?.deliveryEta), 'days') !== 0;

  let label = 'payBillPaymentActivity.deliveryDate.deliveryEtaLabel';
  const payBillFlowType = usePayBillFlowType();
  const isFinanced = payment.isFinanced || payBillFlowType === PayBillFlowType.CreateFinancingPayment;

  if (isMicrodeposit || isUnilateral || isApprovalRequired) {
    label = 'payBillPaymentActivity.deliveryDate.estimatedDeliveryEtaLabel';
  } else if (isRecurringBill) {
    label = 'payBillPaymentActivity.deliveryDate.recurringLabel';
  }

  const editWithTrackEvent = () => {
    analytics.track('pay-bill-confirm', 'experimental-edit-cta-click', {
      billId: bill.id,
      fundingSourceId: fundingSource.id,
      isFast,
      variant: experimentGroup,
      deliveryEta: payment.deliveryEta,
      isLate,
    });

    onEdit && onEdit(true);
  };

  const isShowEditLinkWithTrackEvent =
    (((experimentGroup === FastACHReviewAndConfirmEntryPoint.ControlGroup ||
      experimentGroup === FastACHReviewAndConfirmEntryPoint.LateBadgeNextToDeliveryDate) &&
      hasFastOptions) ||
      (experimentGroup === FastACHReviewAndConfirmEntryPoint.LinkAsEPLocatedInisdeTheH2Row && isFast)) &&
    (isLate || isFast) &&
    isCollectOrDeliveryNotFailed &&
    !isControlGroup &&
    onEdit;

  let onEditGeneral: (() => void) | null | undefined;

  if (isUnilateral) {
    onEditGeneral = onEdit;
  } else if (isShowEditLinkWithTrackEvent) {
    onEditGeneral = editWithTrackEvent;
  }

  return (
    <StyledPaymentActivityContainer isRPPSDeliveryMethod={isRPPSDeliveryMethod}>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <i className="icon-eta-cal" />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={label} />
            </PaymentMethodText>
            <PaymentInfoText>
              {isSameDayDelivery ? (
                <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />
              ) : (
                <DeliveryDate date={payment.deliveryEta} maxDate={payment.maxDeliveryEta} />
              )}
              {isFast ? (
                <Box as="span" ml={1} pos="relative" top="-2px">
                  <Badge testId="fast-badge" label="bills.pay.date.deliveryOptions.price.fastTag" />
                </Box>
              ) : null}
              {experimentGroup === FastACHReviewAndConfirmEntryPoint.LateBadgeNextToDeliveryDate &&
                isShowExperimentalCtaAndNotice &&
                !isFinanced && (
                  <Box as="span" ml={1} pos="relative" top="-2px">
                    <Badge
                      label="regularBatchPayments.cells.deliveryDate.late"
                      variant={BadgeVariants.FILLED}
                      color={BadgeColors.WARNING}
                      textColor="black"
                    />
                  </Box>
                )}
              {showChecksDelaysTooltip && (
                <ChecksDelaysTooltip
                  tooltipLabel={checkDelaysTooltipLabel}
                  type={ChecksDelaysTooltipTypes.WARNING}
                  disabled
                />
              )}
              {isUpdated && <Tag label="payBillPaymentActivity.updated" />}
            </PaymentInfoText>
            {showChecksDelaysTooltip && (
              <Box textStyle="body.10" color="grey.600" data-testid="checks-delays-description">
                <MIFormattedText label={checkDelaysDescriptionLabel} />
              </Box>
            )}
          </PaymentTextContainer>
        </PaymentInfoContainer>
        <EditElement testId="edit-delivery-date" onEdit={onEditGeneral} isDisabled={isEditDisabled} />
      </PaymentActivityMainContainer>
      <Box ml={12}>
        <DeliveryDateInfoHint
          bill={bill}
          payment={payment}
          deliveryMethod={deliveryMethod}
          fundingSource={fundingSource}
        />
        {experimentGroup === FastACHReviewAndConfirmEntryPoint.LinkAsEPLocatedInisdeTheH2Row &&
          isShowExperimentalCtaAndNotice &&
          !isFinanced && (
            <Box textStyle="body4" color="grey.600" display="inline-flex" mt={1}>
              <Box mr={1}>
                <WarningIcon />
              </Box>
              <Box textStyle="body4" color="grey.600">
                <MIFormattedText label="requests.form.paymentActivity.paymentLate" />
              </Box>

              <StyledMIInlineLink
                label="requests.form.paymentActivity.sendFaster"
                testId="edit-delivery-date"
                onClick={editWithTrackEvent}
                disabled={isEditDisabled}
              />
            </Box>
          )}
        {experimentGroup === FastACHReviewAndConfirmEntryPoint.LateBadgeNextToDeliveryDate &&
          isShowExperimentalCtaAndNotice &&
          !isFinanced && (
            <Box textStyle="body4" color="grey.600" mt={1}>
              <MIFormattedText label="requests.form.paymentActivity.editDeliverySpeed" />
            </Box>
          )}
      </Box>
    </StyledPaymentActivityContainer>
  );
};

const StyledPaymentActivityContainer = styled(PaymentActivityContainer)`
  padding-bottom: ${(props) => props.isRPPSDeliveryMethod && '0'};
  border-bottom: ${(props) => props.isRPPSDeliveryMethod && 'none'};
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-left: 0.4rem;
`;
