import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { AccountingPlatform } from 'src/utils/types';
import { ANIMATIONS_MAP } from './utils';

type Props = {
  onNext: () => void;
};

export const SyncSSOSuccessPage = ({ onNext }: Props) => {
  const connectedAccountingSoftware: AccountingPlatform | undefined = useSelector(
    accountingPlatformsStore.selectors.connectedPlatform
  );
  const accountingSoftwareName = connectedAccountingSoftware?.name;
  const successAnimation = accountingSoftwareName && ANIMATIONS_MAP[accountingSoftwareName]?.success;
  const subtitle = connectedAccountingSoftware?.accountPayablesEnabled
    ? 'settings.newAccountingSoftware.SSOSync.success.subtitle'
    : 'settings.newAccountingSoftware.SSOSync.success.subtitleAccountReceiveable';

  return (
    <StepLayoutPage
      title="settings.newAccountingSoftware.SSOSync.success.title"
      subtitle={subtitle}
      subTitleValues={{
        accountingSoftwareAccount: accountingSoftwareName,
      }}
      onNext={onNext}
      fullWidthCTA
      animatedIllustration={{
        animationData: successAnimation,
      }}
    />
  );
};
