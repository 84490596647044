import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';

export const AccountCell: React.FC<CellProps<any>> = ({ cell: { value } }) =>
  useMemo(
    () => (
      <Box isTruncated textStyle="body3">
        {value.nickname && <Box paddingBottom="4px">{value.nickname}</Box>}
        <Box textStyle="body4">{`•••• ${value.last5}`}</Box>
      </Box>
    ),
    [value]
  );
