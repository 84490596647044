import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { format, parse, subYears } from 'date-fns';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { MaskField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { getFormattedDate } from 'src/core/ds/input/dateInput/utils';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import invitationsStore from 'src/modules/invitations/invitations-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { getProfileActions, profileStore } from 'src/modules/profile/profile-store';
import { DOB_DEFAULTS } from 'src/pages/auth/consts';
import { validateDateOfBirth } from 'src/pages/utils/validation-date';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';

type Props = {
  onNext: (orgId: number) => void;
};

export const RegistrationFromInvitationDob = ({ onNext }: Props) => {
  const { profile } = useStructuredSelectors(profileStore.selectors);

  const actions = getProfileActions(useDispatch());
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);
  const [birthDayError, setBirthDayValidationError] = useState<Record<string, any> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const invitation = useSelector(invitationsStore.selectors.validationInvitation);
  const organization = useSelector(organizationStore.selectors.byId(invitation?.organizationId));

  const onNextCallback = () => {
    const validationErrors = getValidationErrors('user', { dateOfBirth }, ['dateOfBirth']);
    setBirthDayValidationError({ ...validationErrors, birthDayError });

    analytics.track('invited-user-dob', 'continue');

    if (isValidationOk(validationErrors)) {
      setIsLoading(true);
      actions
        .update(profile, { dateOfBirth: getFormattedDate(dateOfBirth) })
        .then(() => {
          setIsLoading(false);
          analytics.track('invited-user-dob', 'continue-success');
          onNext(organization.id);
        })
        .catch((e) => {
          setIsLoading(false);
          analytics.track('invited-user-dob', 'continue-error', e);
        });
    } else {
      analytics.track('invited-user-dob', 'validation-error', validationErrors);
    }
  };

  const onDateChange = (value: string) => {
    if (value.length !== 8) {
      setDateOfBirth(null);

      return;
    }

    try {
      setBirthDayValidationError({});
      const parsedDate = parse(value, 'MMddyyyy', new Date());
      const validationErrors = validateDateOfBirth(parsedDate);

      if (Object.keys(validationErrors).length > 0) {
        setBirthDayValidationError(validationErrors);

        return;
      }

      setDateOfBirth(parsedDate);
      setBirthDayValidationError({});
    } catch (error) {
      setBirthDayValidationError({
        dateOfBirth: 'inputErrors.user.dateOfBirth.date.notCorrectDate',
      });
    }
  };

  return (
    <StepLayoutPage
      title="auth.registerFromInvitationDob.title"
      subtitle="auth.registerFromInvitationDob.subtitle"
      onNext={onNextCallback}
      isPrevDisabled
      isLoading={isLoading}
    >
      <RowContainer>
        <MaskField
          id="contactDateOfBirth"
          label="onboarding.companyInfo.contacts.inputTitleDateOfBirth"
          value={dateOfBirth ? format(dateOfBirth, 'MMddyyyy') : ''}
          helperText="onboarding.companyInfo.contacts.inputHintDateOfBirth"
          placeholder="MM/DD/YYYY"
          type="text"
          format={Consts.MASK_FORMAT_OPTIONS.dateOfBirth}
          onSubmit={onNextCallback}
          onChange={({ value }) => onDateChange(value)}
          errorMessage={birthDayError?.dateOfBirth}
          min={subYears(new Date(), DOB_DEFAULTS.MAX_AGE)}
          max={subYears(new Date(), DOB_DEFAULTS.MIN_AGE)}
          isRequired
        />
      </RowContainer>
    </StepLayoutPage>
  );
};

const RowContainer = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 2rem;
  }

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;
