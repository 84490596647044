import { useSelector } from 'react-redux';
import { getProfile } from 'src/redux/user/selectors';
import { isEmailBlockedForInternational } from '../utils';

type ReturnValue = {
  blockEmailForInternationalPayment: boolean;
};

export const useInternationalDetails = (): ReturnValue => {
  const profile = useSelector(getProfile);
  const blockEmailForInternationalPayment = isEmailBlockedForInternational(profile.email);

  return {
    blockEmailForInternationalPayment,
  };
};
