import { ErrorLinkingPage } from './ErrorLinkingPage';
import { LoadingLinkingPage } from './LoadingLinkingPage';

type Props = {
  goTryAgain: () => void;
  goCancel: () => void;
  isLoading: boolean;
  errorCode?: string;
};

export const LinkAccountingSoftwarePage = ({ goTryAgain, goCancel, isLoading, errorCode = '' }: Props) => {
  if (!isLoading) {
    return <ErrorLinkingPage errorCode={errorCode} goTryAgain={goTryAgain} goCancel={goCancel} />;
  }

  return <LoadingLinkingPage />;
};
