import 'react-toastify/dist/ReactToastify.css';
import 'src/theme/Toast.css';

import { featureFlags } from '@melio/shared-web';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import MobileHeader from 'src/components/layout/MobileHeader';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import { useCanSwitchCompanies } from 'src/hooks/useCanSwitchCompanies';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { profileStore } from 'src/modules/profile/profile-store';
import { topBannerPortalId } from 'src/pages/pay/consts';
import { FeatureFlags } from 'src/utils/featureFlags';
import { SyncNowButton } from '../accounting-software/SyncNow/SyncNowButton';
import { OrganizationSwitcher } from '../common/organization-switcher';
import { SideMenu } from './SideMenu';

type Props = {
  children: React.ReactNode;
};

export const AppLayout = ({ children }: Props) => {
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const { isDesktop } = useBreak();
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const canSwitchCompanies = useCanSwitchCompanies();
  const showTopBar = canSwitchCompanies || isDesktop;
  const { isActive } = useFetchAccountingPlatformsAndData();
  const { params } = useRouteMatch<{ type?: string }>();

  return (
    <Container>
      <>
        {isDesktop ? <Flex id={topBannerPortalId} /> : null}
        {isDesktop ? (
          <SideMenu />
        ) : (
          <MobileHeader
            canSwitchCompanies={canSwitchCompanies}
            isMemberOfAccountingFirm={isMemberOfAccountingFirm}
            shouldSplitContactsTab={shouldSplitContactsTab}
            pageType={params?.type}
          >
            <SideMenu />
          </MobileHeader>
        )}
        {showTopBar && (
          <TopBar>
            <Flex gap={6} align="center">
              <OrganizationSwitcher hasAddNewCompanyFooter canSwitchCompanies={canSwitchCompanies} />
              {isDesktop && isActive ? <SyncNowButton /> : null}
            </Flex>
          </TopBar>
        )}
      </>
      <Main>{children}</Main>
    </Container>
  );
};

type ContainerProps = {
  children?: React.ReactNode;
};

const Container = ({ children }: ContainerProps) => (
  <Flex flexDir="column" h="full" className="AppLayout">
    {children}
  </Flex>
);

type TopBarProps = {
  children?: React.ReactNode;
};

const TopBar = ({ children }: TopBarProps) => <Flex __css={TopBarStyle}>{children}</Flex>;

type MainProps = {
  children?: React.ReactNode;
};

const Main = ({ children }: MainProps) => (
  <Box pos="relative" flex={1}>
    {children}
  </Box>
);

const TopBarStyle = {
  display: 'flex',
  pos: 'relative',
  boxSizing: 'border-box',
  pl: { base: 0, lg: 20 },
  h: { base: '4rem', lg: '7.2rem' },
  zIndex: { base: 1010, lg: 21 },
  borderBottom: '1px',
  borderBottomColor: 'grey.400',
};
