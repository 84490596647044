import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { notificationPreferencesStore } from 'src/modules/notification-preferences/notification-preferences-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { getIsIntuitLinked } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { NotificationPreferenceType } from 'src/utils/types';
import { Section } from '../Section';
import { ToggleRow } from './components/ToggleRow';
import { adminGroups, nonAdminGroups } from './consts';

export const EmailNotificationsPage = () => {
  const dispatch = useDispatch();
  const isIntuitLinked = useSelector(getIsIntuitLinked);
  const notificationPrefsLoading = useSelector(notificationPreferencesStore.selectors.loading);
  const isListError = useSelector(notificationPreferencesStore.selectors.isListError);
  const notificationPrefs: NotificationPreferenceType[] = useSelector(
    notificationPreferencesStore.selectors.byType('email')
  );
  const notificationPrefsMap = (notificationPrefs || []).reduce(
    (acc, cur) => ({ ...acc, [cur.name]: cur.enabled }),
    {}
  );
  const isAdmin = useSelector(profileStore.selectors.isAdmin);
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const groups = isAdmin ? adminGroups : nonAdminGroups;

  useFetchNotificationPrefs({ dispatch, orgId });

  const getChangeHandler = (notificationGroup: string) => ({ value }: { value: boolean }) => {
    analytics.trackAction('notification-preference-toggle-clicked', { notificationGroup, enabled: value });

    const notificationPref = notificationPrefs?.find((n) => n.name === notificationGroup);

    if (notificationPref && notificationPref.enabled !== value) {
      dispatch(notificationPreferencesStore.actions.update({ orgId, id: notificationPref.id, enabled: value }));
    }
  };

  if (notificationPrefsLoading) {
    return <AreaLoader />;
  }

  return (
    <>
      {groups.map((group) => (
        <Section key={group.name} title={`settings.emailNotifications.sections.${group.name}.title`}>
          {group.items.map((item) => {
            if (item === 'billQbSynced' && !isIntuitLinked) {
              return null;
            }

            return (
              <ToggleRow
                key={item}
                id={item}
                label={`settings.emailNotifications.notificationGroups.${item}`}
                value={notificationPrefsMap[item]}
                disabled={isListError}
                onChange={getChangeHandler(item)}
              />
            );
          })}
        </Section>
      ))}
    </>
  );
};

function useFetchNotificationPrefs({ dispatch, orgId }) {
  useEffect(() => {
    dispatch(notificationPreferencesStore.actions.list({ orgId }));
  }, [dispatch, orgId]);
}
