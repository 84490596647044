import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Link } from 'src/core/ds/link';
import { NotificationVariant } from 'src/core/ds/toast';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { vendorsApi } from 'src/modules/vendors/api';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useVendorCompanyInfoUpdates } from 'src/pages/onboarding/vendor-company-info/hooks/useVendorCompanyInfoUpdates';
import { getCompanyInfo, getOrgId, getOwnedVendorId, getUserPreferences } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { convertToServerAddress } from 'src/utils/address';
import { RegistrationFlow } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { CompanyDetailsForm } from '../components/CompanyDetailsForm';
import { msnOnboardingEvent as eventPage } from '../events-mapping';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const CompanyDetailsPage = ({ companyInfoField, nextStepURL, prevStepURL, inputFields }: Props) => {
  const [isInputFieldsFull, setIsInputFieldsFull] = useState(false);
  const [isClickContinue, setIsClickContinue] = useState(false);
  const { navigate } = useNavigator();
  const companyInfo = useSelector(getCompanyInfo);
  const site = useSiteContext();
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);
  const user = useSelector(profileStore.selectors.profile);
  const { msnEmptyOrganizationCreated } = useSelector(getUserPreferences);

  const createOwnedVendor = async () => {
    try {
      await vendorsApi.createOwnedVendor(orgId);
    } catch (err) {
      pushNotification({
        type: NotificationVariant.error,
        msg: 'onboarding.msnCompanyInfo.generalError.text',
        textValues: {
          contactSupport: (
            <Link borderBottom="1px" color="white" href={`mailto:${site.config.support.email}`}>
              <MIFormattedText label="onboarding.msnCompanyInfo.generalError.contactSupport" />
            </Link>
          ),
        },
        autoClose: false,
      });
      capture(err as Error);
    }
  };

  const { onNext, onPrev, onChange, isLoading, validationErrors } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
    eventPage,
  });

  useEffect(() => {
    const isUpdateSuccess = companyInfo.companyName && companyInfo.addressLine1;
    const isInputDataValidAndUpdated = isInputFieldsFull && isClickContinue && isUpdateSuccess;

    if (isInputDataValidAndUpdated && vendorId) {
      navigate(generatePath(onboardingLocations.msnRegistration.selectBusinessType, { orgId }));
    }
  }, [
    companyInfo.addressLine1,
    companyInfo.companyName,
    isClickContinue,
    isInputFieldsFull,
    msnEmptyOrganizationCreated,
    navigate,
    orgId,
    user.isGuest,
    vendorId,
  ]);

  const onSubmit = async (address, companyName) => {
    setIsClickContinue(true);

    if (!vendorId) {
      await createOwnedVendor();
    }

    try {
      await onNext(companyInfo, {
        ...convertToServerAddress(address),
        companyName,
        registrationFlow: RegistrationFlow.MSN_PORTAL_PUBLIC_FORM,
      });
    } catch (err) {
      setIsClickContinue(false);
      capture(err as Error);
    }

    setIsInputFieldsFull(companyName && address.formattedAddress);
  };

  return (
    <CompanyDetailsForm
      onPrev={onPrev}
      onNext={onSubmit}
      onChange={onChange}
      companyInfo={companyInfo}
      validationErrors={validationErrors}
      isLoading={isLoading}
    />
  );
};
