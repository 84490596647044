import { format } from 'date-fns';
import { Moment } from 'moment/moment';
import { useRef } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { DatePickerCircle } from 'src/components/common/datepicker/DatePickerCircle';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { DatePicker } from 'src/components/form/DatePicker';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { Popover, PopoverBody, PopoverContent, PopoverFooter, PopoverTrigger } from 'src/core/ds/popover';
import { TextInputSize } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';

type Props = {
  maxScheduledDate?: Date;
  minScheduledDate?: Date;
  scheduledDate: Date;
  dueDate: Date;
  onDateChange: (Date) => void;
  filterDate?: (value: Moment) => boolean;
  renderDayContents?: ReactDatePickerProps['renderDayContents'];
  isDisabled?: boolean;
};

export const PopoverDatePicker = ({
  maxScheduledDate,
  minScheduledDate,
  scheduledDate,
  dueDate,
  onDateChange,
  renderDayContents,
  filterDate,
  isDisabled,
}: Props) => {
  const datePickerRef = useRef<HTMLElement>(null);

  const onChanged = (onClose) => ({ date }) => {
    onDateChange(date);
    onClose();
  };

  const onMonthChange = () => {
    if (datePickerRef.current) {
      datePickerRef.current.focus();
    }
  };

  return (
    <Popover
      gutter={12}
      isLazy
      placement="bottom-start"
      isOpen={isDisabled ? false : undefined}
      initialFocusRef={datePickerRef}
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <Flex
              h="full"
              align="center"
              justify="space-between"
              pointerEvents={isOpen ? 'none' : 'auto'}
              textColor={isDisabled ? 'grey.400' : 'black'}
              cursor="pointer"
            >
              <Box>
                <Box data-testid="deduction-date-cell">
                  {format(new Date(scheduledDate), DateFormats.monthShortWithLongDateAndYear)}
                </Box>
              </Box>
              <Icon name={IconNames.calendar} />
            </Flex>
          </PopoverTrigger>
          <PopoverContent
            sx={{ padding: 0 }}
            border="1px"
            borderColor="grey.400"
            borderRadius="lg"
            boxShadow="500"
            ref={datePickerRef}
          >
            <PopoverBody sx={{ padding: 0 }}>
              <DatePicker
                id="repayment-terms-deduction-date"
                date={scheduledDate}
                dueDate={dueDate}
                required
                size={TextInputSize.INLINE}
                inline
                withBottomElement
                min={minScheduledDate}
                max={maxScheduledDate}
                onChange={onChanged(onClose)}
                onMonthChange={onMonthChange}
                overrideMobile
                filterDate={filterDate}
                renderDayContents={renderDayContents}
                calendarContainerClass="small-calendar with-bottom-element"
                testId="repayment-terms-popover-date-picker"
              />
            </PopoverBody>
            <PopoverFooter bg="white" sx={{ paddingBottom: 4, paddingTop: 0, paddingX: 0 }} borderBottomRadius="lg">
              <Flex justify="flex-start" bgColor="white" px={5}>
                <DateDescription isScheduledDate label="financing.repaymentTerms.datePicker.sent" />
                <DateDescription isScheduledDate={false} label="financing.repaymentTerms.datePicker.due" />
              </Flex>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

type DateDescriptionProps = {
  label: string;
  isScheduledDate: boolean;
};

const DateDescription = ({ label, isScheduledDate }: DateDescriptionProps) => (
  <Flex align="center" pr={4}>
    <DatePickerCircle isScheduledDate={isScheduledDate} circleDiameter={2} />
    <Flex textStyle="body4" color="grey.700" whiteSpace="nowrap">
      <MIFormattedText label={label} />
    </Flex>
  </Flex>
);
