import Box from 'src/core/ds/box';
import { PaymentApprovalStatus } from 'src/utils/consts';
import ApprovalLog from './ApprovalLog';
import { ApprovalDetailsType } from './types';

type Props = {
  approvalDecisionStatus: PaymentApprovalStatus;
  approvalDetails: ApprovalDetailsType;
};

export const PaymentApprovalLogs = ({ approvalDecisionStatus, approvalDetails }: Props) => {
  const { scheduledBy, approvedBy, declinedBy } = approvalDetails;

  const showDeclinedActorsInfo = declinedBy.length > 0 && approvalDecisionStatus === PaymentApprovalStatus.DECLINED;
  const showApprovedActorsInfo = approvedBy.length > 0 && approvalDecisionStatus !== PaymentApprovalStatus.SKIP;

  return (
    <Box>
      {showApprovedActorsInfo ? (
        <ApprovalLog
          testId="PaymentApprovalLogs-Approved"
          actors={approvedBy}
          title="bills.form.paymentActivity.logs.approved.title"
          underline={false}
        />
      ) : null}
      {showDeclinedActorsInfo ? (
        <ApprovalLog
          testId="PaymentApprovalLogs-Declined"
          actors={declinedBy}
          title="bills.form.paymentActivity.logs.declined.title"
          reasonLabel="bills.form.paymentActivity.logs.declined.reasonTitle"
          underline
        />
      ) : null}
      {scheduledBy.user ? (
        <ApprovalLog
          testId="PaymentApprovalLogs-Scheduled"
          actors={[scheduledBy]}
          title="bills.form.paymentActivity.logs.scheduled.title"
          underline={false}
        />
      ) : null}
    </Box>
  );
};
