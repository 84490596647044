import { ApiSliceActionName, generateActionName } from 'src/helpers/redux/actionNames';
import { name } from './payment-store';
import { markPaymentAsPaidSlice, markPaymentAsUnpaidSlice } from './payments-mark-as-paid-slice';
import {
  approvePaymentSlice,
  declinePaymentSlice,
  fetchEmailToVendorDetails,
  fetchPaymentDetailsWithToken,
  fetchPaymentUpsellDetailsSlice,
  getDeliveryTimeSlice,
  updatePaymentWithToken,
} from './payments-slice';

export const PAYMENTS_FETCH_SUCCESS = generateActionName(name, 'FETCH', ApiSliceActionName.SUCCESS);

export const PAYMENTS_MARK_AS_PAID_SUCCESS = markPaymentAsPaidSlice.actions.success;
export const PAYMENTS_MARK_AS_UNPAID_SUCCESS = markPaymentAsUnpaidSlice.actions.success;
export const PAYMENTS_APPROVE_SUCCESS = approvePaymentSlice.actions.success;
export const PAYMENTS_DECLINE_SUCCESS = declinePaymentSlice.actions.success;
export const PAYMENTS_FETCH_DETAILS_WITH_TOKEN_SUCCESS = fetchPaymentDetailsWithToken.actions.success;
export const PAYMENTS_FETCH_EMAIL_TO_VENDOR_DETAILS_SUCCESS = fetchEmailToVendorDetails.actions.success;
export const PAYMENTS_GET_DELIVERY_TIME_SUCCESS = getDeliveryTimeSlice.actions.success;
export const PAYMENTS_UPDATE_WITH_TOKEN = updatePaymentWithToken.actions.success;
export const PAYMENTS_FETCH_PAYMENT_UPSELL_DETAILS_SUCCESS = fetchPaymentUpsellDetailsSlice.actions.success;
