import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';
import { CanceledVirtualCardPaymentDataProps } from '../types';

export const CanceledVirtualCardPayment = ({ virtualCardPaymentData }: CanceledVirtualCardPaymentDataProps) => {
  const { blockIcon, title, subtitle, payment } = virtualCardPaymentData;

  return (
    <Box>
      <Box mb="2">{blockIcon}</Box>
      <Box data-testId="suvc-ach.info-title" mb="0.5" textStyle="body2Semi">
        <MIFormattedText label={title} />
      </Box>
      <Box mt="2" textStyle="body2">
        <MIFormattedText
          label={subtitle}
          values={{
            companyName: payment?.organization?.companyName,
            supportEmail: (
              <Link href="mailto:support@melio.com" target="_blank" color="primary.500" textDecoration="none">
                support@melio.com
              </Link>
            ),
            companyEmail: (
              <Link
                href={`mailto:${payment?.organization?.inboxEmailAddress}`}
                target="_blank"
                color="primary.500"
                textDecoration="none"
              >
                {payment?.organization?.inboxEmailAddress}
              </Link>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
