import { useEffect, useMemo, useRef, useState } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import Box from 'src/core/ds/box/Box';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { DEFAULT_FEES_ACCOUNT_OPTION_ID } from 'src/pages/settings/components/accounting-software/consts';
import { SyncWithQBOCard } from 'src/pages/settings/components/accounting-software/SyncWithQBOCard';
import { AccountingSoftwareModel } from 'src/pages/settings/types';
import { analytics } from 'src/services/analytics';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { AccountingSoftware, NotificationVariant, ScreenMode } from 'src/utils/consts';
import { SyncWithFreshbooksCard } from './components/accounting-software/SyncWithFreshbooksCard';
import { SyncWithMicrosoftDynamicsCard } from './components/accounting-software/SyncWithMicrosoftDynamicsCard';
import { SyncWithQBDCard } from './components/accounting-software/SyncWithQBDCard';
import { SyncWithXeroCard } from './components/accounting-software/SyncWithXeroCard';

const eventPage = 'settings-accounting-software-sync';

export const AccountingSoftwareSettingsPageContainer = () => {
  const {
    connectedAccountingPlatform,
    isConnected,
    status: accountingPlatformStatus,
    accountingPlatforms,
  } = useFetchAccountingPlatformsAndData(true);
  const orgId = useOrgId();
  const toastId = useRef<string | number | null>(null);
  const [mode, setMode] = useState<ScreenMode>(ScreenMode.VIEW);
  const isEditMode = mode === ScreenMode.EDIT;
  const { onApiCall: updateMelioFeesAccountId, error: updateMelioFeesAccountIdSubmitError } = useApi<
    [
      {
        orgId: number;
        melioFeesAccountId: string | null;
      }
    ],
    unknown
  >({
    api: accountingPlatformsApi.updateMelioFeesAccountId,
  });

  const model = useMemo(
    () => ({
      melioFeesAccountId: connectedAccountingPlatform?.melioFeesAccountId || DEFAULT_FEES_ACCOUNT_OPTION_ID,
    }),
    [connectedAccountingPlatform]
  );
  const [accountingSoftwareMV, { submit }, , isProcessing] = useForm<AccountingSoftwareModel>(model, {
    submit: async ({ melioFeesAccountId }) => {
      analytics.track(eventPage, 'save', {
        type: 'save-melio-fees-account-id',
        melioFeesAccountId,
      });

      // we create 'Melio Service Fees' account when we sync the first payment that includes a fee,
      // if it was not created, and the user select it, we need to set melioFeesAccountId to be null
      const id = melioFeesAccountId === DEFAULT_FEES_ACCOUNT_OPTION_ID ? null : melioFeesAccountId;
      const result =
        connectedAccountingPlatform &&
        (await updateMelioFeesAccountId({
          orgId,
          melioFeesAccountId: id,
        }));

      if (!result && !checkIfToastIsDisplayedById(toastId.current)) {
        displayErrorNotification();
      } else {
        setMode(ScreenMode.VIEW);
      }
    },
  });

  const displayErrorNotification = () => {
    toastId.current = pushNotification({
      type: NotificationVariant.ERROR,
      msg: 'settings.accountingSoftware.melioFeesAccount.updateError',
    });
  };

  useEffect(() => {
    if (updateMelioFeesAccountIdSubmitError && !checkIfToastIsDisplayedById(toastId.current)) {
      displayErrorNotification();
    }
  }, [updateMelioFeesAccountIdSubmitError]);

  const { canCreatePaymentRequests: showReceivables } = useCanCreatePaymentRequest();

  const showAccountingPlatformCard = (accountingPlatformName) => {
    const accountingPlatform = accountingPlatforms.find(({ name }) => accountingPlatformName === name);

    const connectedToOtherAccountingPlatform =
      isConnected && connectedAccountingPlatform?.name !== accountingPlatformName;

    return (
      accountingPlatform &&
      !connectedToOtherAccountingPlatform &&
      (accountingPlatform.accountPayablesEnabled || (accountingPlatform.accountReceivablesEnabled && showReceivables))
    );
  };

  const handleToggleViewMode = async () => {
    if (isEditMode) {
      await submit();
    } else {
      analytics.track(eventPage, 'go-to-edit-mode');
      setMode(ScreenMode.EDIT);
    }
  };

  const handleCancel = () => {
    analytics.track(eventPage, 'cancel');
    accountingSoftwareMV.setModelState({
      melioFeesAccountId: connectedAccountingPlatform?.melioFeesAccountId || DEFAULT_FEES_ACCOUNT_OPTION_ID,
    });
    setMode(ScreenMode.VIEW);
  };

  if (accountingPlatformStatus?.loading) {
    return <AreaLoader />;
  }

  return (
    <Box mb={isEditMode ? '30rem' : 0}>
      {showAccountingPlatformCard(AccountingSoftware.QUICKBOOKS) && (
        <SyncWithQBOCard
          model={accountingSoftwareMV}
          viewMode={mode}
          isLoading={isProcessing}
          onEdit={handleToggleViewMode}
        />
      )}
      {showAccountingPlatformCard(AccountingSoftware.XERO) && (
        <SyncWithXeroCard
          model={accountingSoftwareMV}
          viewMode={mode}
          onEdit={handleToggleViewMode}
          isLoading={isProcessing}
        />
      )}
      {showAccountingPlatformCard(AccountingSoftware.QUICKBOOKS_DESKTOP) && <SyncWithQBDCard />}
      {showAccountingPlatformCard(AccountingSoftware.MICROSOFT_DYNAMICS_365) && (
        <SyncWithMicrosoftDynamicsCard
          model={accountingSoftwareMV}
          viewMode={mode}
          onEdit={handleToggleViewMode}
          isLoading={isProcessing}
        />
      )}
      {showAccountingPlatformCard(AccountingSoftware.FRESHBOOKS) && (
        <SyncWithFreshbooksCard
          model={accountingSoftwareMV}
          viewMode={mode}
          onEdit={handleToggleViewMode}
          isLoading={isProcessing}
        />
      )}
      {isEditMode && (
        <MIFloatedEditDoneButtons
          onDone={handleToggleViewMode}
          onCancel={handleCancel}
          doneLabel="general.save"
          cancelLabel="general.cancel"
          isProcessing={isProcessing}
        />
      )}
    </Box>
  );
};
