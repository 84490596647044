import { featureFlags } from '@melio/shared-web';
import { generatePath } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { billLocations } from 'src/pages/bill/locations';
import { analytics } from 'src/services/analytics';
import { ButtonVariant, FeatureFlags } from 'src/utils/consts';
import { hasFailedInstallment } from 'src/utils/financing';
import { BillType, FinancePayment, HeaderAction } from 'src/utils/types';

const eventPage = 'failedInstallment';

export const useInstallmentHeaderActions = ({
  payment,
  bill,
  currentInstallmentNumber,
}: {
  payment?: FinancePayment;
  bill?: BillType;
  currentInstallmentNumber: number | undefined;
}): HeaderAction[] => {
  const orgId = useOrgId();
  const { navigate } = useNavigator();
  const [financingRetryFlows] = featureFlags.useFeature(FeatureFlags.FinancingRetryFlows, false);

  const onRetryPayment = (paymentId: number) => {
    analytics.track(eventPage, 'retry-repayment', {
      paymentId,
      currentInstallmentNumber,
      billIds: bill?.id,
      failedReason: payment?.collectFailureData?.failureReason,
      failedType: payment?.metadata?.failedType,
      failureMessage: payment?.metadata?.failureMessage,
    });
    navigate(
      generatePath(billLocations.pay.edit.funding, {
        orgId,
        billId: bill?.id,
        paymentId,
      })
    );
  };

  if (
    financingRetryFlows &&
    payment &&
    payment.metadata?.allowedActions?.isFailedToCollectRetryEnabled &&
    hasFailedInstallment(payment)
  ) {
    return [
      {
        action: () => onRetryPayment(payment.id),
        label: 'installment.actions.retry',
        variant: ButtonVariant.PRIMARY,
      },
    ];
  }

  return [];
};
