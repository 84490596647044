import * as React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { CompanyContact } from 'src/components/common/CompanyContact';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, oldToNewButtonVariantMap } from 'src/core/ds/button';
import Tooltip from 'src/core/ds/tooltip';
import { devices } from 'src/theme/appDevices';
import { HeaderAction } from 'src/utils/types';

type Props = {
  isFullSingleView: boolean;
  status?: string;
  companyName: string;
  description?: string | React.ReactNode;
  headerActions: HeaderAction[];
  showDirectoryVendorLogo?: boolean;
  showViewDetailsLink?: boolean;
  goViewDetails?: () => void;
};

export const BillDetailsHeader: FC<Props> = ({
  isFullSingleView,
  description,
  companyName,
  headerActions,
  showDirectoryVendorLogo,
  showViewDetailsLink,
  goViewDetails,
}) => (
  <HeaderContainer>
    <HeaderContactContainer>
      {!isFullSingleView && (
        <CompanyContact
          companyName={companyName}
          avatarName={companyName}
          description={description}
          goViewDetails={goViewDetails}
          showDirectoryVendorLogo={showDirectoryVendorLogo}
          showViewDetailsLink={showViewDetailsLink}
        />
      )}
      <HeaderActions headerActions={headerActions} isFullSingleView={isFullSingleView} />
    </HeaderContactContainer>
  </HeaderContainer>
);

export const HeaderActions = ({
  headerActions,
  isFullSingleView,
}: {
  headerActions: HeaderAction[];
  isFullSingleView: boolean;
}) => (
  <ButtonsContainer>
    {headerActions.map((action) => (
      <Tooltip
        data-testid="header-action-disabled-tooltip"
        isDisabled={!action.disabledTooltip}
        placement="top"
        maxW="max"
        shouldWrapChildren
        label={
          <Box textAlign="start" px={2} py={1}>
            <MIFormattedText label={action?.disabledTooltip?.label} />
          </Box>
        }
      >
        <StyledDesignedButton
          disabled={action.disabled}
          key={action.label}
          onClick={action.action}
          label={action.label}
          size={ButtonSizes.lg}
          variant={oldToNewButtonVariantMap[action.variant]}
          w={isFullSingleView ? 'full' : undefined}
          analyticsProperties={action.analyticsProperties}
        />
      </Tooltip>
    ))}
  </ButtonsContainer>
);

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 3.5rem;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0;
  }
`;

const HeaderContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-bottom: 0;
  }
`;

const StyledDesignedButton = styled(Button)`
  min-width: 10rem;
  &:last-child {
    margin-left: 1.2rem;
  }

  @media ${devices.mobile} {
    margin-left: 0;
    &:last-child {
      margin-left: 0;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  @media ${devices.mobile}, ${devices.phablet} {
    width: 100%;
  }
  @media ${devices.mobile} {
    flex-direction: column;
    > button:nth-of-type(1) {
      margin-bottom: 1rem;
    }
  }
`;
