import { featureFlags } from '@melio/shared-web';
import axios from 'axios';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSelectOptionObject } from 'src/components/common/MISingleSelect';
import config from 'src/config';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useAbortControllerSignal } from 'src/hooks/useAbortControllerSignal';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getDirectoryVendors } from 'src/pages/bill/hooks/utils';
import { GroupedVendorsType, VendorOptionType } from 'src/pages/vendor-directory/select-vendor/types';
import {
  addNewVendorToGroupedVendors,
  createLocalVendorOption,
  createNewLocalVendorOption,
  groupLocalAndDirectoryVendors,
} from 'src/pages/vendor-directory/select-vendor/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { directoryApi } from 'src/services/api/directory';
import { DirectoryType } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { FeatureFlags } from 'src/utils/featureFlags';
import { useLocationState } from 'src/utils/hooks';
import { FieldType, VendorType } from 'src/utils/types';
import { DirectoryVendorType } from './types';

const eventPage = 'bill-create';

export type UseBillVendorIdReturnType = ReturnType<typeof useBillVendorId>;

export const useBillVendorId = () => {
  const [isBusinessDirectorySearchEnabled] = featureFlags.useFeature(FeatureFlags.BusinessDirectorySearch, false);
  const orgId = useSelector(getOrgId);
  const vendorActions = useStoreActions(vendorsStore);
  const [vendorsFromState] = useLocationState('vendors', []);
  const [vendors, setVendors] = useState<VendorOptionType[]>(vendorsFromState);
  const [newVendor, setNewVendor] = useState<VendorOptionType | null>(null);
  const [isVendorsLoaded, setIsVendorsLoaded] = useState(false);
  const [filteredVendors, setFilteredVendors] = useState<GroupedVendorsType>(
    groupLocalAndDirectoryVendors(vendorsFromState)
  );
  const [vendorInputValue, setVendorInputValue] = useState<string>('');
  const { getAbortControllerSignal, abortController } = useAbortControllerSignal();

  useEffect(() => {
    if (isEmpty(vendors)) {
      loadVendors();
    }
  }, []);

  const loadVendors = async () => {
    try {
      const {
        payload: { items },
      } = await vendorActions.list({
        orgId,
        shouldMelioMeVendorHasDeliveryMethod: true,
      });

      const vendorsOptions = generateVendorsOptions(items);
      setVendors(vendorsOptions);
      setFilteredVendors(groupLocalAndDirectoryVendors(vendorsOptions));
      setIsVendorsLoaded(true);
    } catch (e) {
      setVendors([]);
      setFilteredVendors(groupLocalAndDirectoryVendors([]));
    }
  };

  const generateVendorsOptions = (allVendors: VendorType[]): VendorOptionType[] =>
    orderBy(
      allVendors.map(createLocalVendorOption),
      [(vendor: VendorOptionType) => vendor?.label?.toLowerCase()],
      ['asc']
    );

  const onVendorsInputChange = useCallback(
    async ({ value: inputValue }) => {
      const signal = getAbortControllerSignal();
      setVendorInputValue(inputValue);

      if (isEmpty(inputValue)) {
        return;
      }

      try {
        abortController?.abort();
        const startTime = performance.now();
        const result = await directoryApi.search({
          orgId,
          name: inputValue,
          conf: { signal },
        });

        const endTime = performance.now();
        const localVendors: VendorOptionType[] = result.vendors.local.map(createLocalVendorOption);
        const { biller: billers, business: businesses } = result.vendors;
        const directoryVendors = getDirectoryVendors({
          billers,
          businesses,
          isBusinessDirectorySearchEnabled,
        });

        analytics.track(eventPage, 'search-vendors', {
          duration: endTime - startTime,
          localVendorsCount: localVendors.length,
          directoryVendorsCount: directoryVendors.length,
          billerVendorsCount: billers.length,
          businessVendorsCount: businesses.length,
          searchInput: inputValue,
        });
        setFilteredVendors(
          groupLocalAndDirectoryVendors(newVendor ? [newVendor, ...localVendors] : localVendors, directoryVendors)
        );
      } catch (error) {
        if (!axios.isCancel(error)) {
          throw error;
        }
      }
    },
    [getAbortControllerSignal, newVendor, orgId, isBusinessDirectorySearchEnabled, abortController]
  );

  const debouncedOnVendorsInputChange = useCallback(
    debounce((params: { value: string }) => onVendorsInputChange(params), config.debounceDelay),
    [onVendorsInputChange]
  );

  const addNewVendor = useCallback(
    (newVendorOption: VendorOptionType) => {
      setNewVendor(newVendorOption);
      setVendors([newVendorOption, ...vendors]);
      setFilteredVendors(addNewVendorToGroupedVendors(filteredVendors as GroupedVendorsType, newVendorOption));
    },
    [filteredVendors, vendors]
  );

  const onVendorIdChange = useCallback(
    (obj: FieldType & Record<string, any>): number => {
      // We should not search for vendors when user already select a vendor, causing race conditions.
      debouncedOnVendorsInputChange.cancel();
      abortController?.abort();

      let newVendorOption: VendorOptionType | null = null;
      const properties = {
        searchValue: vendorInputValue,
        vendorType: obj.type,
        directoryType: obj.directoryType,
        directoryId: obj.directoryId,
        businessSuggestedCount: filteredVendors?.[1]?.options?.filter((v) => v.directoryType === 'business')?.length,
        billerSuggestedCount: filteredVendors?.[1]?.options?.filter((v) => v.directoryType === 'biller')?.length,
        localVendorSuggestedCount: filteredVendors?.[0]?.options.length,
      };

      // eslint-disable-next-line no-underscore-dangle
      if (obj.__isNew__) {
        newVendorOption = createNewLocalVendorOption(obj);
      }

      if (newVendorOption) {
        analytics.track(eventPage, `${obj.id}-create`, properties);
        addNewVendor(newVendorOption);

        return +newVendorOption.value;
      }

      analytics.track(eventPage, `${obj.id}-select`, properties);

      return +obj.value;
    },
    [abortController, addNewVendor, debouncedOnVendorsInputChange, filteredVendors, vendorInputValue]
  );

  const getDirectoryVendorFromVendorOption = useCallback(
    (vendorId: number): DirectoryVendorType | undefined => {
      const currentOption = getSelectOptionObject(filteredVendors, vendorId);

      return {
        directoryId: currentOption?.directoryId,
        directoryType: currentOption?.directoryType,
      };
    },
    [filteredVendors]
  );

  const createNewVendor = useCallback(
    async (vendorId: number, vendorAccountIdentifier?: string): Promise<number | null> => {
      const vendorOption = getSelectOptionObject(filteredVendors, vendorId) as VendorOptionType | null;
      const isBiller = vendorOption?.directoryType === DirectoryType.Biller;

      const newVendor = {
        companyName: vendorOption?.label || '',
        // TODO: Remove in this task https://linear.app/meliopayments/issue/PAID-2979/web-or-cleanup-billerid-leftovers
        ...(isBiller ? { billerId: vendorOption?.directoryId } : {}),
        directoryId: vendorOption?.directoryId,
        directoryType: vendorOption?.directoryType,
        accountIdentifier: isBiller ? vendorAccountIdentifier : null,
        ...pick(vendorOption, ['contactName', 'contactPhone', 'address']),
      };
      try {
        const { payload } = await vendorActions.create({ orgId, ...newVendor });

        analytics.track(eventPage, 'create-vendor', {
          vendorId: payload.id,
          billerId: isBiller ? vendorOption?.directoryId : undefined,
          directoryId: vendorOption?.directoryId,
          directoryType: vendorOption?.directoryType,
        });
        setVendors([createLocalVendorOption(payload), ...vendors]);

        return payload.id as number;
      } catch (error: any) {
        capture(error, 'create-vendor-for-bill');

        return null;
      }
    },
    [filteredVendors, orgId, vendorActions, vendors]
  );

  const isNewVendor = useCallback((vendorId: number) => vendorId > 0, []);

  return {
    vendors,
    filteredVendors,
    isVendorsLoaded,
    onVendorsInputChange: debouncedOnVendorsInputChange,
    onVendorIdChange,
    isNewVendor,
    getDirectoryVendorFromVendorOption,
    createNewVendor,
    addNewVendor,
  };
};
