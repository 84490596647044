import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { UIProps } from './PayCard';

type Props = {
  search: UIProps['search'];
  balance: UIProps['balance'];
  moneyFlavor: UIProps['moneyFlavor'];
};

export const PayCardMissingDetailsBody = ({ balance, search, moneyFlavor }: Props) => (
  <Flex justifyContent="space-between" alignItems="center" gap={4}>
    <Box display={{ base: 'inline-block' }} textStyle="body4" color="grey.600">
      <MIFormattedText label="pay.scannedInvoice.missingInfo" />
    </Box>
    {!!balance && (
      <Box ml={2}>
        <MIMoney
          amount={balance}
          search={search}
          flavor={moneyFlavor}
          testId="list-item-recurring-bill-money"
          privateData
        />
      </Box>
    )}
  </Flex>
);
