import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { Role } from 'src/utils/consts';

type Props = {
  onNext: () => void;
};

export const NewCompanySuccessPage = ({ onNext }: Props) => {
  const userActions = useStoreActions(usersStore);
  const { companyName } = useSelector(getCompanyInfo);
  const userRole = useSelector(profileStore.selectors.getUserRole);
  const profile = useSelector(profileStore.selectors.profile);
  const noOwnerInvited = userRole === Role.OWNER;
  const accountingFirm = useSelector(profileStore.selectors.getFirstAccountingFirm);
  const accountingFirmOrgId = accountingFirm?.id;
  const accountingFirmUsers = useSelector(usersStore.selectors.org(accountingFirm?.id).users);
  const accountingFirmHasMembers = accountingFirmUsers.length > 1;
  const accountingFirmUserOrg = profile.userOrganizations.find(
    (userOrg) => userOrg.organizationId === accountingFirmOrgId
  );

  let title = 'inviteNewCompany.accountantFlow.success.title';
  let text = noOwnerInvited ? 'inviteNewCompany.accountantFlow.success.noOwnerText' : '';
  let notes = noOwnerInvited ? undefined : ['inviteNewCompany.accountantFlow.success.ownerNotes'];
  const buttonLabel = 'inviteNewCompany.accountantFlow.success.getStarted';

  const isAccountingFirmWithMembers = isAdminRole(accountingFirmUserOrg?.role) && accountingFirmHasMembers;

  if (isAccountingFirmWithMembers) {
    title = 'inviteNewCompany.accountantFlow.success.firmWithMembers.title';
    text = 'inviteNewCompany.accountantFlow.success.firmWithMembers.text';
    notes = undefined;
  }

  useEffect(() => {
    if (accountingFirmOrgId) {
      userActions.userManagementListSlice({ orgId: accountingFirmOrgId });
    }
  }, [accountingFirmOrgId, userActions]);

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.business}
      title={title}
      text={text}
      textValues={{ companyName }}
      buttonLabel={buttonLabel}
      buttonAction={onNext}
      notes={notes}
      notesTitle="inviteNewCompany.accountantFlow.success.ownerNotesTitle"
    />
  );
};
