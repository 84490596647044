import { SystemStyleInterpolation } from '@chakra-ui/theme-tools';

type Props = {
  selectedColor: string;
  onClick?: () => void;
  withSelectedColor: boolean;
};

const baseStyle = ({ selectedColor = 'green.500', onClick, withSelectedColor }: Props) =>
  ({
    boxSizing: 'border-box',
    border: '2px',
    borderColor: 'transparent',
    userSelect: 'none',
    borderRadius: 'lg',
    cursor: onClick ? 'pointer' : 'default',
    boxShadow: 500,
    _hover: {
      boxShadow: 600,
    },
    '&[data-selected=true]': {
      borderColor: withSelectedColor ? selectedColor : 'transparent',
      _hover: { boxShadow: 500 },
    },
    p: 6,
  } as SystemStyleInterpolation);

export default {
  baseStyle,
};
