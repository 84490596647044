import { ReactElement } from 'react';
import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { MICard } from 'src/components/common/MICard';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import Box from 'src/core/ds/box';
import { RadioGroupField } from 'src/core/ds/form/fields';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Radio } from 'src/core/ds/radio';
import { whitePagesAddressKeys } from 'src/utils/address';
import { ManualAddress } from 'src/utils/consts';
import { AddressType, LegalAddressType } from 'src/utils/types';
import { ManualAddressRow } from '../../components/layout/ManualAddressRow';
import { MIFormattedText } from '../common/MIFormattedText';

type Props = {
  addressToConfirm?: ManualAddressType | null;
  addressValidationErrors?: Record<string, any>;
  originalAddress?: AddressType | LegalAddressType | null;
  printName?: string;
  onAddressSelect: (id: ManualAddress) => void;
  onEditAddress: () => void;
  selectedAddressId: ManualAddress;
};

export const ManualAddressConfirmPage = ({
  addressToConfirm,
  addressValidationErrors,
  originalAddress,
  printName = '',
  onAddressSelect,
  onEditAddress,
  selectedAddressId,
}: Props) => {
  const onSelect = (id: ManualAddress) => {
    onAddressSelect(id);
  };

  const getAddressContentComponent = (address: ReactElement[][] | string, editLink?: boolean) => (
    <Box color="black">
      <ManualAddressRow printName={printName} address={address} editLink={editLink} onEditAddress={onEditAddress} />
    </Box>
  );

  const getSuggestedAddressContent = () => {
    const addressFields = Object.keys(whitePagesAddressKeys);
    const address = addressFields
      .filter((field) => !!addressToConfirm?.[field])
      .map((field) => addressToConfirm?.[field])
      .join(', ');

    return getAddressContentComponent(address, true);
  };

  const getOriginalAddress = () => {
    const addressFields = Object.values(whitePagesAddressKeys);
    const addressFiltered = addressFields.filter((field) => !!originalAddress?.[field]);
    const suggestedAddress = addressFiltered.map((field, index) => {
      const address: ReactElement[] = [];

      address.push(
        <Box key={`validatedAddress-${field}`} display="inherit">
          <MIFieldOrEmpty
            value={originalAddress?.[field]}
            color={Object.keys(addressValidationErrors || []).includes(field) ? 'red.500' : 'black'}
            label=""
          />
          {index !== addressFiltered.length - 1 && <Box as="span" key={field} _after={{ content: "','", me: 1 }} />}
        </Box>
      );

      return address;
    });

    return getAddressContentComponent(suggestedAddress);
  };

  const addressOptions = [
    {
      value: ManualAddress.SUGGESTED,
      testId: 'manualAddress.suggested',
      component: (
        <>
          <Box>
            <MIFormattedText label="manualAddress.suggested" />
          </Box>
          {getSuggestedAddressContent()}
        </>
      ),
    },
    {
      value: ManualAddress.ORIGINAL,
      testId: 'manualAddress.original',
      component: (
        <>
          <Box>
            <MIFormattedText label="manualAddress.original" />
          </Box>
          {getOriginalAddress()}
        </>
      ),
      icon: <Icon size={IconSize.lg} name={IconNames.alertFill} color="red.500" />,
    },
  ];

  return (
    <MICard mode="regular">
      <Box padding={6}>
        <RadioGroupField
          marginBottom={0}
          value={selectedAddressId}
          id="manualAddress"
          direction="column"
          name="manualAddress"
          onChange={(id) => onSelect(id as ManualAddress)}
          isRequired
        >
          {addressOptions.map((option) => (
            <Box key={option.value} display="flex" alignItems="flex-start" color="grey.600">
              <Radio value={option.value} testId={option.testId}>
                {option.component}
              </Radio>
              {option.icon ? option.icon : null}
            </Box>
          ))}
        </RadioGroupField>
      </Box>
    </MICard>
  );
};
