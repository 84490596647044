import { getValidationErrors } from '@melio/sizzers-js-common';
import get from 'lodash/fp/get';
import head from 'lodash/fp/head';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import toLower from 'lodash/fp/toLower';
import values from 'lodash/fp/values';
import mapValues from 'lodash/mapValues';
import { CSVVendorsColumnName } from 'src/pages/contacts/create/constants';
import { ContactType } from 'src/utils/types';

type UploadVendorValidationErrors = {
  companyName?: string;
  contactName?: string;
  contactPhone?: string;
  contactEmail?: string;
};

type VendorWithValidationErrors = {
  vendor: ContactType;
  validationErrors: UploadVendorValidationErrors;
};
export const isUploadVendorFileValid = (rows?: Record<string, string>[], headers?: string[]) => {
  const firstHeader = head(headers);

  return rows && rows.length > 0 && firstHeader === 'Company Name';
};

export const isVendorValidToUpload = (vendorWithErrors: VendorWithValidationErrors) =>
  !vendorWithErrors.validationErrors.companyName;

export const isVendorMissingDetails = (vendorWithErrors: VendorWithValidationErrors) =>
  !isEmpty(values(vendorWithErrors.validationErrors));

export const removeInvalidValues = (vendorWithErrors: VendorWithValidationErrors) =>
  mapValues(vendorWithErrors.vendor, (value, key) => (vendorWithErrors.validationErrors[key] ? undefined : value));

const getUploadVendorValidationErrors = (
  vendor: ContactType,
  existingCompanyNamesSet: Set<string>
): UploadVendorValidationErrors => {
  const validationErrors = getValidationErrors('vendor', vendor);
  const mappedErrors = mapValues(validationErrors, (value) =>
    (value as string).replace('inputErrors.vendor', 'contacts.batch.review.vendors.inputErrors')
  );

  if (existingCompanyNamesSet.has(toLower(vendor.companyName))) {
    mappedErrors.companyName = 'contacts.batch.review.vendors.inputErrors.companyName.any.duplicate';
  }

  return mappedErrors;
};

const companyNameLowerCase = (vendor: ContactType) => toLower(vendor.companyName);

const csvRowToUploadVendor = (row: Record<string, string>): ContactType => ({
  companyName: row[CSVVendorsColumnName.COMPANY_NAME]?.trim(),
  contactEmail: row[CSVVendorsColumnName.CONTACT_EMAIL]?.trim(),
  contactName: row[CSVVendorsColumnName.CONTACT_NAME]?.trim(),
  contactPhone: row[CSVVendorsColumnName.CONTACT_PHONE]?.trim(),
});

export const getVendorsWithValidationErrors = (rows, currentVendorsList): VendorWithValidationErrors[] => {
  const vendors: ContactType[] = rows.map(csvRowToUploadVendor);
  const existingCompanyNames = map(pipe(get('companyName'), toLower))(currentVendorsList);
  const existingCompanyNamesSet = new Set<string>(existingCompanyNames);
  type PrevValue = { existingCompanyNamesSet: Set<string>; vendorsWithErrors: VendorWithValidationErrors[] };
  const getValidationErrors = (prev: PrevValue, vendor: ContactType): PrevValue => {
    const { existingCompanyNamesSet, vendorsWithErrors } = prev;
    const validationErrors = getUploadVendorValidationErrors(vendor, existingCompanyNamesSet);
    const companyName = companyNameLowerCase(vendor);

    return {
      vendorsWithErrors: [...vendorsWithErrors, { vendor, validationErrors }],
      existingCompanyNamesSet: existingCompanyNamesSet.add(companyName),
    };
  };
  const results = vendors.reduce(getValidationErrors, {
    vendorsWithErrors: [],
    existingCompanyNamesSet,
  });

  return results.vendorsWithErrors || [];
};
