import { BrandLogoContainer } from 'src/components/basis-theory/brands-logos/BrandLogoContainer';
import { BasisTheoryCardsBrands } from 'src/components/basis-theory/brands-logos/consts';
import { BasisTheoryCardBrandType } from 'src/components/basis-theory/brands-logos/types';
import Flex from 'src/core/ds/flex/Flex';
import { Image } from 'src/core/ds/image';

type Props = {
  selectedBrand: BasisTheoryCardBrandType;
};

export const BrandsLogos = ({ selectedBrand }: Props) => {
  const isBrandSelected = (brand: BasisTheoryCardBrandType) =>
    selectedBrand === brand || selectedBrand === BasisTheoryCardsBrands.unknown;

  return (
    <Flex justifyContent="space-evenly" textAlign="center" width="full">
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.visa)}>
        <Image src="https://s3.amazonaws.com/app.meliopayments.com/images/visa-logo.svg" id="visa" alt="visa" />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.mastercard)}>
        <Image
          src="https://s3.amazonaws.com/app.meliopayments.com/images/mastercard-logo.svg"
          id="mastercard"
          alt="mastercard"
        />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.amex)}>
        <Image src="https://s3.amazonaws.com/app.meliopayments.com/images/amex-logo.svg" id="amex" alt="amex" />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.diners)}>
        <Image src="https://s3.amazonaws.com/app.meliopayments.com/images/diners-logo.svg" id="diners" alt="diners" />
      </BrandLogoContainer>
      <BrandLogoContainer translucent={!isBrandSelected(BasisTheoryCardsBrands.discover)}>
        <Image
          src="https://s3.amazonaws.com/app.meliopayments.com/images/discover-logo.svg"
          id="discover"
          alt="discover"
        />
      </BrandLogoContainer>
    </Flex>
  );
};
