import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { NotificationCardTypes } from 'src/utils/consts';

export const BillOverdueNotification = () => (
  <Flex justify="center" w="full" mb={8} mt={-4}>
    <Box maxWidth="50rem" w="full">
      <MINotificationCard
        data-testid="bill-overdue-banner"
        type={NotificationCardTypes.WARNING}
        title={{ label: 'bills.pay.date.billOverdueBanner.title' }}
        subtitle={{ label: 'bills.pay.date.billOverdueBanner.subtitle' }}
      />
    </Box>
  </Flex>
);
