import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { Separator } from 'src/core/ds/separator';
import { VStack } from 'src/core/ds/stack';
import { NotificationVariant } from 'src/core/ds/toast';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { useCheckboxOptions } from 'src/hooks/accountants/useCheckboxOptions';
import { accountingFirmsApi } from 'src/modules/accounting-firms/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useGetFirmBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetFirmBillingFees';
import { profileStore } from 'src/modules/profile/profile-store';
import { useBillingAnalytics } from 'src/pages/settings/components/Billing/useBillingAnalytics';
import { settingsLocations } from 'src/pages/settings/locations';
import { pushNotification } from 'src/services/notifications';
import { CompanyType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { AccountType } from 'src/utils/types';
import { billingLocations } from '../locations';
import { ClientsType } from '../types';
import { getBillingMethod, getFormattedAssignedClients } from '../utils';
import { BatchSelectionRow } from './helpers/BatchSelectionRow';
import { ClientOrgBillingFeeRow } from './helpers/ClientOrgBillingFeeRow';

export const SelectClientsOrgBillingFee = () => {
  const [selectedBillingPaymentMethod] = useLocationState<AccountType>('billingPaymentMethod');
  const [historyPush] = useHistoryWithOrgId();
  const firmOrganizations = useSelector(profileStore.selectors.getOrganizations);
  const currentOrgId = useOrgId();
  const [isSkipped, setIsSkipped] = useState<boolean>(false);
  const { firmBillingFees, assignedClients: firmClientsBillingFees, loading: isFetching } = useGetFirmBillingFees();
  const { onApiCall: createFirmBillingFee, loading: isCreatingBillingFee } = useApi({
    api: accountingFirmsApi.createBillingFee,
  });
  const { onApiCall: updateFirmBillingFee, loading: isUpdatingFirmBillingFee } = useApi({
    api: accountingFirmsApi.updateBillingFee,
  });

  const currentFirmBillingFee = getBillingMethod(firmBillingFees);

  const clientsOptions = useMemo(
    () =>
      firmOrganizations
        .filter((org) => org.companyType !== CompanyType.ACCOUNTING_FIRM)
        .map((org) => ({
          ...org,
          isChecked: firmClientsBillingFees?.[org.id.toString()],
        })),
    [firmClientsBillingFees, firmOrganizations]
  );

  const billingAnalytics = useBillingAnalytics({
    trackRoute: {
      category: 'settings-billing',
      page: 'clients-to-cover',
      eventProperties: {
        numOfClients: clientsOptions.length,
      },
    },
  });

  const {
    checkboxes: firmClients,
    markedCheckboxes: assignedClients,
    toggleCheckbox,
    toggleAll,
    clearAll,
    batchAction,
  } = useCheckboxOptions({
    options: clientsOptions,
    isAllSelected: !!selectedBillingPaymentMethod?.id,
  });

  const currentAssignedClientsIds = clientsOptions.filter(({ isChecked }) => isChecked).map(({ id }) => id);
  const isSaveButtonDisabled =
    assignedClients.length === currentAssignedClientsIds.length &&
    assignedClients.every((client, index) => currentAssignedClientsIds[index] === client.id);

  const handleBackClick = () => {
    historyPush({ path: billingLocations.add });
  };

  const handleExitClick = () => {
    historyPush({ path: settingsLocations.billingItems });
  };

  const handleNextClick = async () => {
    const clients = getFormattedAssignedClients(assignedClients, firmClients);
    const numOfClientsDeSelected: number =
      currentAssignedClientsIds.length > 0
        ? clients
            .filter((client) => client.isActive === false)
            .filter((client) => currentAssignedClientsIds.some((clientId) => client.id === clientId)).length
        : 0;

    billingAnalytics.track('settings-billing', 'client-to-cover-select', {
      numOfClients: firmClients.length,
      numOfClientsSelected: assignedClients.length,
      numOfClientsDeSelected,
    });
    selectedBillingPaymentMethod ? await handleCreateBillingFee(clients) : await handleUpdateBillingFee(clients);
  };

  const handleCreateBillingFee = async (clients: ClientsType[]) => {
    if (selectedBillingPaymentMethod) {
      await createFirmBillingFee({
        orgId: currentOrgId,
        clients,
        fundingSourceId: selectedBillingPaymentMethod.id,
      });
      historyPush({ path: billingLocations.success });
    }
  };

  const handleUpdateBillingFee = async (clients: ClientsType[]) => {
    if (currentFirmBillingFee) {
      await updateFirmBillingFee({
        orgId: currentOrgId,
        billingFeeId: currentFirmBillingFee.id,
        clients,
        fundingSourceId: currentFirmBillingFee.fundingSourceId,
      });
      historyPush({ path: settingsLocations.billingItems });
      pushNotification({
        type: NotificationVariant.success,
        msg: 'billing.assignClients.editSuccess',
      });
    }
  };

  const handleSkipClick = async () => {
    billingAnalytics.track('settings-billing', 'client-to-cover-skip');
    setIsSkipped(true);

    if (selectedBillingPaymentMethod) {
      await createFirmBillingFee({
        orgId: currentOrgId,
        clients: [],
        fundingSourceId: selectedBillingPaymentMethod.id,
      });
      historyPush({ path: billingLocations.success });
    }
  };

  if (isFetching) {
    return <AreaLoader />;
  }

  return (
    <WizardLayout>
      <WizardLayout.TopBar
        onBackClick={!currentFirmBillingFee ? handleBackClick : undefined}
        onCancelClick={handleExitClick}
        progressBarValue={!currentFirmBillingFee ? 100 : undefined}
      />
      <WizardLayout.Content>
        <VStack justifyContent="center" textAlign="center">
          <WizardLayout.Header label="billing.assignClients.title" />
        </VStack>

        <WizardLayout.Body>
          <Flex pt={1} pb={1} direction="column" shadow={400} borderRadius="lg" align="center" w="full">
            {firmClients.length ? (
              <BatchSelectionRow
                numOfClients={firmClients.length}
                numOfMarkedClients={assignedClients.length}
                batchAction={batchAction}
                markAll={toggleAll}
                clearAll={clearAll}
              />
            ) : null}
            <Separator color="grey.400" mt={0} mb={0} />
            <Box w="full" overflowY="auto" maxH="353px">
              {firmClients.map(({ id, companyName, isChecked }) => (
                <ClientOrgBillingFeeRow
                  id={id}
                  key={id}
                  isChecked={isChecked}
                  name={companyName || ''}
                  onChange={toggleCheckbox}
                />
              ))}
            </Box>
          </Flex>
        </WizardLayout.Body>
        <WizardLayout.Footer>
          <Button
            disabled={isSaveButtonDisabled}
            size={ButtonSizes.lg}
            variant={ButtonVariants.primary}
            maxW="full"
            label="general.save"
            isLoading={!isSkipped && (isCreatingBillingFee || isUpdatingFirmBillingFee)}
            onClick={handleNextClick}
          />
          {!currentFirmBillingFee ? (
            <Button
              size={ButtonSizes.lg}
              maxW="full"
              variant={ButtonVariants.tertiaryNaked}
              label="progress.skip"
              onClick={handleSkipClick}
              isLoading={isSkipped && isCreatingBillingFee}
            />
          ) : null}
        </WizardLayout.Footer>
      </WizardLayout.Content>
    </WizardLayout>
  );
};
