const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: 'full',
  height: 'full',
  overflow: 'hidden',
  boxSizing: 'border-box',
  background: 'grey.200',
  paddingBlockStart: '10.4rem',
};

export default {
  baseStyle,
};
