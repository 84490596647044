import { useFeature } from '@melio/shared-web/dist/feature-flags';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { BillersVisiblityModal } from 'src/components/education/BillersVisibilityModal';
import { ListLayoutPage, ListPage, ViewSinglePage } from 'src/components/layout/ListLayoutPage';
import { DesktopListHeader } from 'src/components/list/DesktopListHeader';
import { EmptyList } from 'src/components/list/EmptyList';
import NewEmptyList from 'src/components/list/NewEmptyList';
import { SearchInputContainer } from 'src/components/list/SortSearchFilter/SearchInputContainer';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import contactEmptyStateIcon from 'src/images/general/contact-empty-state.svg';
import customersEmptyStateIcon from 'src/images/general/customers-empty-state.svg';
import vendorsEmptyStateIcon from 'src/images/general/vendors-empty-state.svg';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { BatchSettings } from 'src/pages/contacts/list/components/BatchSettings/BatchSettings';
import { useBatchContactSelection } from 'src/pages/contacts/list/components/BatchSettings/useBatchContactSelection';
import { ContactsSubList } from 'src/pages/contacts/list/components/ContactsSubList';
import { TransactionSummary } from 'src/pages/contacts/types';
import { getContactsTabs, getContactType, getVendorTabs } from 'src/pages/contacts/utils';
import { ContactsTab, FeatureFlags, PaymentRequestTabs, RegistrationFlow } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { Filters, UserContextType, VendorType } from 'src/utils/types';
import { CustomerView } from './CustomerView';
import { VendorView } from './VendorView';

type Props = {
  contacts: ReadonlySet<VendorType<'created'>>;
  profile: UserContextType;
  users: readonly UserContextType[];
  selectedContact: VendorType<'created'>;
  loadSelectedContact: () => void;
  goPaymentRequests: (tab: PaymentRequestTabs) => void;
  isLoading: boolean;
  isListLoading: boolean;
  isSingleLoading: boolean;
  selectedTab: ContactsTab;
  contactTransactionsSummary: TransactionSummary[];
  goCreateContact: (type: ContactsTab) => void;
  registrationFlow: RegistrationFlow;
  eventPage?: string;
  filters: Filters;
};

const emptyStateIconMapper = {
  [ContactsTab.VENDORS]: vendorsEmptyStateIcon,
  [ContactsTab.CUSTOMERS]: customersEmptyStateIcon,
};

const sxForCard: CSSObject = {
  mb: 4,
  mt: 4,
};

export const ContactsListPage = ({
  contacts,
  profile,
  users,
  selectedContact,
  loadSelectedContact,
  goPaymentRequests,
  isLoading,
  isListLoading,
  isSingleLoading,
  selectedTab,
  contactTransactionsSummary,
  goCreateContact,
  registrationFlow,
  eventPage,
  filters,
}: Props) => {
  const [shouldSplitContactsTab] = useFeature<boolean>(FeatureFlags.SplitContactsTab, false);
  const selectedContactsTab = getContactType(selectedTab);
  const query = useQueryString();
  const params = useParams<{ orgId: string; id: string }>();
  const isMSNDashboardDisplay = useIsMsnDashboardDisplay();
  const { isDesktop } = useBreak();
  const {
    batchSelectionEnabled,
    selectedIds,
    setSelected,
    selectAll,
    removeFromList,
    resetSelection,
    isAllSelected,
    analyticsProps,
  } = useBatchContactSelection(selectedTab, contacts);

  const listBaseSearch = `${filters.search ? `&search=${filters.search}` : ''}`;
  const pageTitle = shouldSplitContactsTab ? `list.tab.contacts.${selectedContactsTab}` : 'vendors.title';
  const shouldShowSearchInput = isDesktop && (!isEmpty(contacts) || !isEmpty(filters?.search));

  const renderContacts = (contacts: ReadonlySet<VendorType<'created'>>) => {
    if (!contacts) {
      return <></>;
    }

    const sortedContacts = [...contacts]
      .sort((a, b) => a.companyName.localeCompare(b.companyName))
      .reduce((alphaSortObj, contactObj) => {
        const firstLetter = contactObj.companyName.substr(0, 1).toLowerCase();

        if (!alphaSortObj[firstLetter]) {
          alphaSortObj[firstLetter] = [];
        }

        alphaSortObj[firstLetter].push(contactObj);

        return alphaSortObj;
      }, {} as Record<string, VendorType<'created'>[]>);

    return Object.keys(sortedContacts).map((alpha) => (
      <Box key={alpha} data-testid="contact-list-alphabet-container">
        <Box textStyle="h2Semi" color="black" textTransform="uppercase">
          {alpha}
        </Box>
        <ContactsSubList
          filters={filters}
          contacts={sortedContacts[alpha]}
          selectedIds={selectedIds}
          batchSelectionEnabled={batchSelectionEnabled}
          selectedContactsTab={selectedContactsTab}
          setSelected={setSelected}
        />
      </Box>
    ));
  };

  const renderEmptyState = () => {
    if (filters.search) {
      return <EmptyList text="contacts.emptyState.search" showZeroState={false} />;
    }

    if (shouldSplitContactsTab) {
      return (
        <NewEmptyList
          iconSrc={emptyStateIconMapper[selectedTab]}
          title={`contacts.newEmptyState.${selectedTab}.title`}
          text={`contacts.newEmptyState.${selectedTab}.subtitle`}
          ctaAction={() => goCreateContact(selectedTab)}
          ctaLabel={`contacts.emptyState.${selectedTab}.newContact`}
          withPlus
        />
      );
    }

    return (
      <NewEmptyList
        iconSrc={contactEmptyStateIcon}
        title={`contacts.emptyState.${selectedTab}.title`}
        text={`contacts.emptyState.${selectedTab}.subtitle`}
        ctaAction={() => goCreateContact(selectedTab)}
        ctaLabel={`contacts.emptyState.${selectedTab}.newContact`}
      />
    );
  };

  const tabs = getVendorTabs(
    getContactsTabs(registrationFlow, isMSNDashboardDisplay),
    selectedContactsTab,
    listBaseSearch,
    params
  );

  const renderRightBlockContent = () => {
    if (batchSelectionEnabled && !isEmpty(selectedIds)) {
      return (
        <BatchSettings
          analyticsProps={analyticsProps}
          selectedIds={selectedIds}
          removeFromList={removeFromList}
          resetSelection={resetSelection}
        />
      );
    }

    if (selectedContactsTab === ContactsTab.VENDORS) {
      return (
        <VendorView
          vendor={query.id ? selectedContact : null}
          profile={profile}
          users={users}
          isSingleLoading={isSingleLoading}
          isLoading={isLoading}
          listBaseSearch={listBaseSearch}
          loadSelectedContact={loadSelectedContact}
        />
      );
    }

    return (
      <CustomerView
        contact={selectedContact}
        isSingleLoading={isSingleLoading}
        isLoading={isLoading}
        contactPaymentRequests={contactTransactionsSummary}
        listBaseSearch={listBaseSearch}
        goPaymentRequests={goPaymentRequests}
      />
    );
  };

  return (
    <ListLayoutPage
      eventPage={`${eventPage}-${selectedContactsTab}`}
      title={pageTitle}
      isLoading={isLoading}
      showNewItemButton={!isEmpty(contacts)}
    >
      <ListPage>
        {shouldSplitContactsTab ? (
          shouldShowSearchInput && (
            <Box my={10}>
              <SearchInputContainer />
            </Box>
          )
        ) : (
          <DesktopListHeader tabs={tabs} />
        )}
        {selectedTab === ContactsTab.CUSTOMERS ? (
          <CustomFeed feedType="contacts_customers_above" sxForCard={sxForCard} />
        ) : null}
        {selectedTab === ContactsTab.VENDORS ? (
          <CustomFeed feedType="contacts_vendors_above" sxForCard={sxForCard} />
        ) : null}
        <Box w="100%" pos="relative">
          {isListLoading ? (
            <AreaLoader placement="list" />
          ) : (
            <>
              {batchSelectionEnabled && !isEmpty(selectedIds) && (
                <Flex justify="space-between" align="center" pl={4} pr={4} pb={5}>
                  <Checkbox
                    data-testid="vendor-batch-list-select-all-checkbox"
                    onChange={selectAll}
                    isChecked={isAllSelected}
                    isIndeterminate={isAllSelected}
                  >
                    <Box ml={1} textStyle="body3">
                      <MIFormattedText
                        label={isAllSelected ? 'vendors.selection.clearSelected' : 'vendors.selection.selectAll'}
                      />
                    </Box>
                  </Checkbox>
                </Flex>
              )}
              {renderContacts(contacts)}
              {isEmpty(contacts) && renderEmptyState()}
            </>
          )}
        </Box>
        <BillersVisiblityModal />
      </ListPage>
      <ViewSinglePage>{renderRightBlockContent()}</ViewSinglePage>
    </ListLayoutPage>
  );
};
