/* eslint-disable global-require,@typescript-eslint/no-var-requires */
import merge from 'lodash/merge';
import defaults from './default';

let env = {};

enum EnvTypeEnum {
  ALPHA = 'staging',
  DEV = 'development',
}

const appEnv = (window as any).APP_ENV || process.env.REACT_APP_ENV;
const appEnvType = (window as any).APP_ENV_TYPE;

if (appEnv === 'production') {
  env = require('./production').default;
} else if (appEnv === 'alpha' || appEnvType === EnvTypeEnum.ALPHA) {
  env = require('./alpha').default;
} else if (appEnv === 'core-e2e') {
  env = require('./core-e2e').default;
} else if (appEnv === 'dev' || appEnvType === EnvTypeEnum.DEV) {
  env = require('./dev').default;
} else if (appEnv === 'development-e2e') {
  env = require('./development-e2e').default;
} else if (appEnv === 'integrated-local') {
  env = require('./integrated-local').config;
} else if (appEnv === 'local-tests') {
  env = require('./local-tests').config;
} else {
  try {
    /* eslint-disable-next-line import/no-unresolved */
    env = require('./local').default;
  } catch (e) {
    throw new Error('Please specify local.js file. Take a look at local.example.js');
  }
}

const { qbo, ...defaultConfig } = merge({}, defaults, env, (window as any).dynEnvConfig);

const config = merge({}, defaultConfig);

export default config;
export { qbo };
