import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { PasswordMaskField } from 'src/core/ds/form/fields/PasswordMaskField';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Consts } from 'src/core/ds/input';
import Tooltip from 'src/core/ds/tooltip';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { ModelViewField } from 'src/ui/form';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  modelViewField: ModelViewField<InternalBusinessOwnershipDetailsModel['ssn']>;
  testId?: string;
  errorMessage?: string;
};

export const SSNField = ({ modelViewField, testId, errorMessage }: Props) => (
  <Flex alignItems="center" color="grey.700" w="full">
    <PasswordMaskField
      testId={testId || 'input-ssn'}
      label="financing.businessOwnershipDetails.fields.ssn.title"
      placeholder="financing.businessOwnershipDetails.fields.ssn.placeholder"
      errorMessage={errorMessage}
      isRequired
      isModelOnChangeFormatted
      model={modelViewField}
      format={Consts.MASK_FORMAT_OPTIONS.taxId}
      mb={0}
      className={FULL_STORY_MASK_RULE_CLASS}
      labelValues={{
        description: (
          <Tooltip
            label={<MIFormattedText label="financing.businessOwnershipDetails.fields.ssn.tooltip" />}
            shouldWrapChildren
            placement="top"
            data-testid="input-mainOwner-ssn-tooltip"
          >
            <Box ml={2} h="1.6rem" data-testid="financing-application-ssn-description">
              <Icon name={IconNames.helpCircle} size={IconSize.s} />
            </Box>
          </Tooltip>
        ),
      }}
    />
  </Flex>
);
