import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { BlockCreateLinkModal } from 'src/components/common/BlockCreateLinkModal';
import { useModal } from 'src/helpers/react/useModal';
import { useOrgId } from 'src/hooks';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { vendorsApi } from 'src/modules/vendors/api';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { ConnectWithPlaidInfo } from 'src/pages/onboarding/vendor-company-info/components/connect-with-plaid-info/ConnectWithPlaidInfo';
import { goToAddReceivingMethod } from 'src/pages/onboarding/vendor-company-info/hooks/utils';
import { useIsVendorAbsorbedFeeSet } from 'src/pages/settings/hooks/useIsVendorAbsorbedFeeSet';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOwnedVendorHandle, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { ButtonsDirections, DeliveryMethodOrigin, DialogVariants, FeatureFlags } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { FieldType } from 'src/utils/types';
import { VendorCompanyLink } from './components/VendorCompanyLink';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

const eventPage = 'vendor-handle';

export const VendorCompanyLinkContainer = ({ companyInfoField, nextStepURL, prevStepURL, inputFields }: Props) => {
  const [handle, setHandle] = useState('');
  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [showBlockCreateLinkModal, setShowBlockCreateLinkModal] = useState(false);

  const ownedVendorHandle = useSelector(getOwnedVendorHandle);
  const requestsPermissions = useSelector(profileStore.selectors.getPermissions).requests;
  const companyInfo = useSelector(getCompanyInfo);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const [isDeferredOnboardingEnabled] = useFeature<boolean>(FeatureFlags.EnableGetPaidDeferredOnboarding, false);
  const dispatch = useDispatch();
  const { navigate } = useNavigator();
  const orgId = useOrgId();
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');
  const isVendorAbsorbedFeeSet = useIsVendorAbsorbedFeeSet();

  const handleOnNext = useCallback(
    () =>
      goToAddReceivingMethod({
        navigate,
        origin,
        orgId,
        ownedVendorId,
        shouldSkipAbsorbFeesStep: isDeferredOnboardingEnabled || isVendorAbsorbedFeeSet,
      }),
    [navigate, origin, orgId, ownedVendorId, isVendorAbsorbedFeeSet, isDeferredOnboardingEnabled]
  );

  const [connectWithPlaidModal, open] = useModal(CommonDialog, {
    title: 'onboarding.vendorCompanyInfo.link.connectWithPlaidModal.title',
    description: 'onboarding.vendorCompanyInfo.link.connectWithPlaidModal.description',
    buttonsDirection: ButtonsDirections.HORIZONTAL,
    confirmText: 'onboarding.vendorCompanyInfo.link.connectWithPlaidModal.confirm',
    hideIcon: true,
    hideClose: true,
    maxWidth: '62rem',
    minHeight: '28rem',
    children: <ConnectWithPlaidInfo />,
    variant: DialogVariants.SUCCESS,
    confirm: async () => {
      setCompanyInfoHandle();
    },
  });

  const { onNext, onPrev, errorMessage, isLoading } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL: isDeferredOnboardingEnabled ? onboardingLocations.vendorCompanyInfo.logo : prevStepURL,
    inputFields,
    onNextStep: isDeferredOnboardingEnabled ? handleOnNext : undefined,
  });
  const locationState = useLocation<Record<string, any>>().state;

  const getHandle = async () => {
    try {
      if (ownedVendorHandle) {
        setHandle(ownedVendorHandle);
      } else {
        setIsLocalLoading(true);
        let vendorId: number | undefined;

        if (!ownedVendorId) {
          const { vendor } = await vendorsApi.createOwnedVendor(orgId);
          vendorId = parseInt(vendor.id, 10);

          dispatch(
            setCompanyInfoAction({
              ...companyInfo,
              ownedVendorId: vendorId,
            })
          );
        }

        const vendorIdWithFallback = ownedVendorId || vendorId!;
        const { suggestedHandle } = await vendorsApi.getHandleSuggestion(orgId, vendorIdWithFallback);
        setHandle(suggestedHandle);

        setIsLocalLoading(false);
      }
    } catch (e) {
      setIsLocalLoading(false);
    }
  };

  const setCompanyInfoHandle = () => {
    dispatch(
      setCompanyInfoAction({
        ...companyInfo,
        ownedVendorHandle: handle,
      })
    );
    onNext(companyInfo, { handle });
  };

  const goToRequestsDashboard = () => {
    locationState.origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION
      ? navigate(generatePath(msnPortalLocations.base, { orgId }))
      : navigate(generatePath(getPaidLocations.dashboard, { orgId }));
  };

  const hideBlockCreateLinkModal = () => {
    analytics.track(eventPage, 'hide-block-create-link-modal');
    goToRequestsDashboard();
  };

  const onChange = ({ value }: FieldType) => {
    setHandle(value);
  };

  useEffect(() => {
    if (requestsPermissions.createMelioLink()) {
      getHandle();
    } else {
      setShowBlockCreateLinkModal(true);
    }
  }, []);

  let currentGoExit: (() => void) | undefined;
  let currentGoPrev: (() => void) | undefined = onPrev;

  if (!locationState) {
    currentGoExit = goToRequestsDashboard;
  } else if (
    locationState.origin === DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_REQUESTS_LIST ||
    locationState.origin === DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST ||
    locationState.origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION
  ) {
    currentGoExit = goToRequestsDashboard;
    currentGoPrev = undefined;
  } else if (locationState.origin === DeliveryMethodOrigin.GET_PAID) {
    currentGoPrev = goToRequestsDashboard;
  }

  return (
    <>
      {showBlockCreateLinkModal && (
        <BlockCreateLinkModal onButtonClick={hideBlockCreateLinkModal} onCloseClick={hideBlockCreateLinkModal} />
      )}
      <VendorCompanyLink
        onPrev={currentGoPrev}
        goExit={currentGoExit}
        onChange={onChange}
        handle={handle}
        errorMessage={errorMessage}
        onNext={setCompanyInfoHandle}
        isLoading={isLoading || isLocalLoading}
        showLearnMoreModal={() => open()}
      />
      {connectWithPlaidModal}
    </>
  );
};
