import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import MISingleSelect, { getSelectOptionObject, Option } from 'src/components/common/MISingleSelect';
import { reviewVendorDetailsNotification } from 'src/components/msn/VerifiedVendor/consts';
import { useBusinessDetailsBoxInForm } from 'src/components/msn/VerifiedVendor/hooks/useBusinessDetailsBoxInForm';
import { VerifiedVendorDetails } from 'src/components/msn/VerifiedVendor/VerifiedVendorDetails';
import { FormRow } from 'src/core/ds/form';
import { TextField } from 'src/core/ds/form/fields';
import { useModal } from 'src/helpers/react/useModal';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useOrgId } from 'src/hooks';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { DuplicateVendorNameModal } from 'src/pages/vendor-directory/DuplicateVendorNameModal/DuplicateVendorNameModal';
import {
  getSelectOverrideStyle,
  isValidNewOption,
  onVendorChange,
} from 'src/pages/vendor-directory/select-vendor/utils';
import { VendorOptionLabel } from 'src/pages/vendor-directory/select-vendor/VendorOptionLabel';
import { OptionGroupLabel } from 'src/pages/vendor-directory/select-vendor/VendorsGroupLabel';
import { ModelViewField } from 'src/ui/form';
import { convertToDisplayAddress } from 'src/utils/address';
import { DirectoryType, ScreenMode, SingleSelectFlavor } from 'src/utils/consts';
import { FieldType, VendorType } from 'src/utils/types';
import { VendorSelectFieldPropsType } from './types';

type Props = VendorSelectFieldPropsType & {
  vendorId: ModelViewField<number>;
  accountIdentifier: ModelViewField<string>;
  vendorLabel: string;
  vendorPlaceholder: string;
  vendorNoOptions: string;
  vendorAccNumLabel?: string;
  vendorAccNumPlaceholder?: string;
};

export const VendorSelectField = ({
  vendorId,
  accountIdentifier,
  filteredVendors,
  onVendorIdChange,
  onVendorsInputChange,
  vendorLabel,
  vendorPlaceholder,
  vendorNoOptions,
  vendorAccNumLabel,
  vendorAccNumPlaceholder,
}: Props) => {
  const orgId = useOrgId();
  const site = useSiteContext();
  const vendors = useSelector((state) =>
    vendorsStore.selectors.list.value(state, {
      orgId,
      shouldMelioMeVendorHasDeliveryMethod: true,
    })
  ) as VendorType[];
  const {
    businessDetails,
    shouldShowBusinessDetailsBox,
    deliveryMethodDefaultValue,
    shouldDisplayReviewVendorDetailsNotification,
  } = useBusinessDetailsBoxInForm({
    vendorOptions: filteredVendors,
    selectedVendorId: vendorId.value,
    mode: ScreenMode.EDIT,
  });
  const isVerifiedVendorDetailsBoxDisplayed = shouldShowBusinessDetailsBox && businessDetails;

  const isShowAccountIdentifier = (currentVendorId?: number, groupedVendors?: Option[]): boolean => {
    if (!groupedVendors || !currentVendorId) return false;

    const selectedVendor = getSelectOptionObject(groupedVendors, currentVendorId);

    if (selectedVendor?.isRppsVendor) {
      return true;
    }

    return !isEmpty(selectedVendor?.directoryId) && selectedVendor?.directoryType === DirectoryType.Biller;
  };

  const [duplicateVendorNameModalView, showDuplicateVendorNameModal] = useModal(DuplicateVendorNameModal, {
    modalName: 'duplicate-vendor-name',
    cancelLabel: 'useExistingVendorButton',
    saveLabel: 'continueButton',
  });

  const onVendorOptionChange = (obj: FieldType & Record<string, string>) => {
    const vendorIdValue = onVendorChange(obj, vendors, onVendorIdChange, showDuplicateVendorNameModal);
    vendorId.onChange({
      value: vendorIdValue,
      label: obj.label,
    });
  };

  return (
    <>
      {duplicateVendorNameModalView}
      <FormRow mb={isVerifiedVendorDetailsBoxDisplayed ? 2 : undefined}>
        <MISingleSelect
          id="vendorId"
          label={vendorLabel}
          placeholder={vendorPlaceholder}
          noOptionsLabel={vendorNoOptions}
          allowCustom
          value={vendorId.value}
          options={filteredVendors}
          onInputChange={onVendorsInputChange}
          onChange={onVendorOptionChange}
          flavor={SingleSelectFlavor.DS}
          overrideStyles={getSelectOverrideStyle(filteredVendors as Option[], site.theme, true)}
          formatOptionLabel={(option, formatOptions) => (
            <VendorOptionLabel option={option} formatOptions={formatOptions} />
          )}
          formatGroupLabel={(group) => <OptionGroupLabel group={group} />}
          isValidNewOption={(inputValue: string, selectValue: Option[], selectOptions: Option[]): boolean =>
            isValidNewOption(inputValue, selectValue, selectOptions)
          }
          filterOption={(option) => option}
          errorMessage={vendorId.error}
          required
          isClearable
        />
      </FormRow>
      {isShowAccountIdentifier(vendorId.value, filteredVendors) ? (
        <FormRow>
          <TextField
            id="accountIdentifier"
            label={vendorAccNumLabel}
            placeholder={vendorAccNumPlaceholder}
            model={accountIdentifier}
            isRequired
          />
        </FormRow>
      ) : null}
      {isVerifiedVendorDetailsBoxDisplayed ? (
        <VerifiedVendorDetails
          businessName={businessDetails.label}
          businessAddress={convertToDisplayAddress(businessDetails.address ?? {})}
          businessContactInfo={{
            email: businessDetails.contactEmail,
            fullName: businessDetails.contactName,
            phone: businessDetails.contactPhone,
            deliveryMethodLabel: deliveryMethodDefaultValue,
          }}
          notification={shouldDisplayReviewVendorDetailsNotification ? reviewVendorDetailsNotification : undefined}
        />
      ) : null}
    </>
  );
};
