import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box/Box';
import { IllustrationName } from 'src/core/ds/illustration';
import { HelpFooter } from 'src/pages/onboarding/msn-registration/public-form/containers/delivery-method-status/HelpFooter';
import { MicroDepositInfo } from 'src/pages/onboarding/msn-registration/public-form/containers/delivery-method-status/MicroDepositInfo';

const INFO_OPTIONS = [
  { label: 'onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.info1' },
  { label: 'onboarding.msnCompanyInfo.deliveryMethodStatus.microDeposits.info2' },
  { label: 'onboarding.msnCompanyInfo.claimAccount.verifyWithDeposits.info3' },
];
export const VerifyWithDepositsStatusPage = () => (
  <SuccessLayoutPage
    illustration={IllustrationName.clock}
    title="onboarding.msnCompanyInfo.claimAccount.verifyWithDeposits.title"
    footer={HelpFooter()}
    hideHeader
    testId="msn-claim-account-verify-with-deposits-status"
  >
    <Box>
      <MicroDepositInfo infoOptions={INFO_OPTIONS} />
    </Box>
  </SuccessLayoutPage>
);
