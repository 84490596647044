import { useSelector } from 'react-redux';
import { useGetPaidOnboardingMainEntryPoint } from 'src/pages/onboarding/vendor-company-info/hooks/useGetPaidOnboardingMainEntryPoint';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { VendorCompanyContactNameForm } from './components/VendorCompanyContactNameForm';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyContactNamePageContainer = ({ companyInfoField, prevStepURL, inputFields }: Props) => {
  const { getPaidOnboardingEntryPoint } = useGetPaidOnboardingMainEntryPoint();
  const { onNext, onChange, onPrev, isLoading, validationErrors } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL: getPaidOnboardingEntryPoint,
    prevStepURL,
    inputFields,
  });

  const companyInfo = useSelector(getCompanyInfo);

  return (
    <VendorCompanyContactNameForm
      onNext={onNext}
      onPrev={onPrev}
      onChange={onChange}
      companyInfo={companyInfo}
      isLoading={isLoading}
      validationErrors={validationErrors}
    />
  );
};
