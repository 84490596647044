import { useStyleConfig } from '@chakra-ui/system';
import { ReactNode } from 'react';
import Box from 'src/core/ds/box';

type Props = {
  id: string;
  testId?: string;
  children?: ReactNode;
  isSelected?: boolean;
  selectedColor?: string;
  withSelectedColor?: boolean;
  onClick?: (event: React.SyntheticEvent, id: string) => void;
};

export const Card = ({ id, children, selectedColor, isSelected, onClick, withSelectedColor = true, testId }: Props) => {
  const styles = useStyleConfig('Card', { selectedColor, onClick, withSelectedColor });
  const cardTestId = testId || `card-${id}`;

  const handleClick = (event: React.SyntheticEvent, id: string) => {
    onClick && onClick(event, id);
  };

  return (
    <Box
      id={id}
      data-testid={cardTestId}
      __css={styles}
      data-selected={isSelected}
      onClick={(event) => handleClick(event, id)}
    >
      {children}
    </Box>
  );
};
