import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { analytics } from 'src/services/analytics';
import { DeliveryType, NotificationCardTypes } from 'src/utils/consts';
import { handleInputChange } from 'src/utils/input';
import { BankType, FieldType } from 'src/utils/types';
import { FlowCustomizationsType } from '../../types';

type Props = {
  bank: BankType;
  vendorId: string;
  errorCode: string;
  isLoading?: boolean;
  flowCustomizations: FlowCustomizationsType;
  validationErrors: Record<string, string>;
  onDone: (isCompleted?: boolean) => void;
  goExit: () => void;
  onChange: (arg0: FieldType) => void;
};

const eventPage = 'vendor_add-bank-delivery-method';

export const AddBankDetailsPage = ({
  bank,
  vendorId,
  errorCode,
  isLoading,
  flowCustomizations,
  validationErrors,
  onDone,
  goExit,
  onChange,
}: Props) => {
  const { routingNumber, accountNumber } = bank;
  const { WizardFormRow } = WizardElements;

  const handleSaveDetailsAndAddBill = () => {
    analytics.track(eventPage, `save-details-and-add-bill`, {
      type: DeliveryType.ACH,
      vendorId,
    });
    onDone(true);
  };

  const handleExit = () => {
    analytics.track(eventPage, `exit`, {
      vendorId,
    });
    goExit();
  };

  const inputLabels = flowCustomizations?.firstStep?.inputLabels || {
    routingNumber: 'vendors.deliveryMethods.bank.routingNumber',
    accountNumber: 'vendors.deliveryMethods.bank.accountNumber',
  };

  const shouldShowValue = routingNumber === '';

  return (
    <StepLayoutPage
      goExit={handleExit}
      onSubmit={handleSaveDetailsAndAddBill}
      onSecondaryActionButtonClick={onDone}
      isLoading={isLoading}
      {...flowCustomizations.stepLayout}
    >
      {errorCode && (
        <NotificationCard
          type={NotificationCardTypes.ERROR}
          subtitle={{
            label: `server.${errorCode}`,
          }}
        />
      )}
      <WizardFormRow>
        <Box w="full">
          <PrivateDataContainer>
            <TextField
              id="routingNumber"
              testId="input-routingNumber"
              value={routingNumber}
              label={inputLabels.routingNumber}
              onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
              type="tel"
              autoFocus
              isRequired
              errorMessage={validationErrors.routingNumber}
              autoComplete="off"
            />
          </PrivateDataContainer>
        </Box>
      </WizardFormRow>

      <WizardFormRow>
        <Box w="full">
          <PrivateDataContainer>
            <PasswordField
              id="accountNumber"
              testId="input-accountNumber"
              value={accountNumber}
              label={inputLabels.accountNumber}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleInputChange({ event, onChange, inputMode: 'numeric' })
              }
              errorMessage={validationErrors.accountNumber}
              shouldShowValue={shouldShowValue}
              autoComplete="new-password"
              inputMode="numeric"
              isRequired
            />
          </PrivateDataContainer>
        </Box>
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormRow>
    </StepLayoutPage>
  );
};

const HiddenInput = styled.input`
  display: none;
`;

const NotificationCard = styled(MINotificationCard)`
  margin: 0 0 4rem;
`;
