import { CustomFeed } from 'src/components/braze/CustomFeed';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import Box from 'src/core/ds/box/Box';
import { useFundingSourceEditLabel } from 'src/pages/settings/hooks/useFundingSourceEditLabel';
import { useFundingSourceMicroDepositState } from 'src/pages/settings/hooks/useFundingSourceMicroDeposits';
import { useOrgDefaultFundingSource } from 'src/pages/settings/hooks/useOrgDefaultFundingSource';
import {
  DeleteFundingSourceErrorData,
  EditFundingSourceLabelState,
} from 'src/pages/settings/PaymentMethodsSettingsPageContainer';
import { sxForSettingsCard } from 'src/pages/settings/styles';
import { analytics } from 'src/services/analytics';
import { DialogType, DialogVariants } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';
import { DeletePaymentMethodDialog } from './components/DeletePaymentMethodDialog';
import { eventPage } from './consts';
import { getErrorDialogConfig } from './utils';

export type Props = {
  goAddFundingSources: (type: string) => void;
  onDeleteFailedError: () => void;
  onDeleteConfirmed: () => void;
  onDeletePaymentMethodCanceled: () => void;
  fundingSourceToDelete: AccountType;
  deletingId: number | null;
  errorCode: string;
  errorData: DeleteFundingSourceErrorData;
  isSingleLoading: boolean;
  fundingSources: readonly AccountType[];
  onVerifyClicked: (id: number) => void;
  verifyingId: number | null;
  editingFundingSourceLabel: EditFundingSourceLabelState;
  resetFundingSourceLabelEdit: () => void;
  onVerifyFinished: () => void;
  modifyActions: (accountTypes: AccountType) => Record<string, () => void>;
};

export const PaymentMethodsSettingsPage = ({
  deletingId,
  errorCode,
  errorData,
  isSingleLoading,
  onDeletePaymentMethodCanceled,
  onDeleteFailedError,
  onDeleteConfirmed,
  fundingSourceToDelete,
  fundingSources,
  verifyingId,
  editingFundingSourceLabel,
  resetFundingSourceLabelEdit,
  onVerifyFinished,
  onVerifyClicked,
  modifyActions,
  goAddFundingSources,
}: Props) => {
  const microDepositEventPage = 'settings-verify-manual-account';
  const microDepositProps = {
    key: verifyingId,
    fundingSourceId: verifyingId,
  };
  const [state, actions] = useFundingSourceMicroDepositState(microDepositEventPage, microDepositProps);
  const { orgDefaultFundingSourceId, setOrgDefaultFundingSource } = useOrgDefaultFundingSource();
  const [EditPaymentMethodLabelModal] = useFundingSourceEditLabel(
    editingFundingSourceLabel,
    resetFundingSourceLabelEdit
  );

  const allModifyActions = (fs: AccountType) => ({
    ...modifyActions(fs),
    setDefault: () => {
      analytics.track(eventPage, `set-default`, {
        fundingSourceId: fs.id,
      });
      setOrgDefaultFundingSource(fs.id);
    },
  });

  const errorDialogConfig = getErrorDialogConfig(errorCode, errorData);

  return (
    <>
      {EditPaymentMethodLabelModal}
      {isSingleLoading && <AreaLoader />}
      {!isSingleLoading && (
        <>
          <CustomFeed feedType="settingspaymentmethods_above" sxForCard={sxForSettingsCard} />
          <MICard>
            <MICardForm>
              <MICardTitle label="settings.paymentMethods.title" />
              <Box mt={4}>
                <FundingSourcesListLayout
                  fundingSources={fundingSources}
                  defaultFundingSourceId={orgDefaultFundingSourceId}
                  onAddMethod={goAddFundingSources}
                  onVerifyClicked={onVerifyClicked}
                  modifyFundingSourceActions={allModifyActions}
                />
              </Box>
            </MICardForm>
          </MICard>
          {verifyingId && (
            <VerifyMicroDeposits
              {...state}
              {...actions}
              key={verifyingId}
              verifyingId={verifyingId}
              onVerifyFinished={onVerifyFinished}
              eventPage={microDepositEventPage}
              dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
              dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
            />
          )}
          {deletingId && fundingSourceToDelete && (
            <DeletePaymentMethodDialog
              fundingSourceToDelete={fundingSourceToDelete}
              onDeleteConfirmed={onDeleteConfirmed}
              onDeletePaymentMethodCanceled={onDeletePaymentMethodCanceled}
            />
          )}

          {errorCode && (
            <Dialog
              type={DialogType.ALERT}
              variant={DialogVariants.ERROR}
              title={errorDialogConfig.title}
              subtitle={errorDialogConfig.subtitle}
              onCancelAction={onDeleteFailedError}
              cancelButtonText="dialogs.buttons.close"
            />
          )}
        </>
      )}
    </>
  );
};
