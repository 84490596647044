import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { Link } from 'src/core/ds/link';
import { UseModal } from 'src/helpers/react/useModal';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { analytics } from 'src/services/analytics';
import { DeletePaymentAction, DialogType, DialogVariants } from 'src/utils/consts';
import { BillType, PaymentType } from 'src/utils/types';
import { getDeletePaymentModalLabels } from '../utils';

type Props = {
  onDeletePayment: (payment: PaymentType, type: DeletePaymentAction) => void;
  payment: PaymentType;
  bill: BillType;
  paymentForDeleteType: DeletePaymentAction;
};

const eventPage = 'bills';

export const DeletePaymentModal = ({
  dismiss,
  onDeletePayment,
  payment,
  bill,
  paymentForDeleteType,
}: UseModal<Props>) => {
  const isDeletable = payment?.metadata?.isDeletable;
  const site = useSiteContext();

  const { cancelButtonText, okButtonText, subtitle, title } = getDeletePaymentModalLabels(
    bill,
    payment,
    paymentForDeleteType
  );

  const onDeletePaymentCanceled = () => {
    analytics.track(eventPage, 'delete-payment-canceled');
    dismiss();
  };

  const onHideNonDeletableModal = () => {
    analytics.track(eventPage, 'hide-non-deletable-modal');
    dismiss();
  };

  const onDeletePaymentAction = () => {
    if (onDeletePayment) {
      onDeletePayment(payment, paymentForDeleteType);
    }

    dismiss();
  };

  if (isDeletable) {
    return (
      <Dialog
        type={DialogType.CONFIRM}
        variant={DialogVariants.ERROR}
        title={title}
        titleValues={{
          companyName: bill.vendor?.companyName,
        }}
        subtitle={subtitle}
        okButtonText={okButtonText}
        cancelButtonText={cancelButtonText}
        onOkAction={onDeletePaymentAction}
        onCancelAction={onDeletePaymentCanceled}
      />
    );
  }

  return (
    <Dialog
      type={DialogType.ALERT}
      variant={DialogVariants.ERROR}
      title="bills.form.nonDeletablePaymentDialog.title"
      subtitle="bills.form.nonDeletablePaymentDialog.subtitle"
      subtitleValues={{
        supportEmail: (
          <Link target="_self" href={`mailto:${site.config.support.email}`}>
            {site.config.support.email}
          </Link>
        ),
      }}
      cancelButtonText="bills.form.nonDeletablePaymentDialog.ok"
      onCancelAction={onHideNonDeletableModal}
    />
  );
};
