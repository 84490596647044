import * as React from 'react';
import styled from 'styled-components';
import { MIChip } from 'src/components/common/MIChip';

const BillOriginContainer = styled.div`
  margin-top: 8px;
`;

type Props = {
  label: string;
};

const ListItemLabel = ({ label }: Props): React.ReactElement => (
  <BillOriginContainer>
    <MIChip label={label} />
  </BillOriginContainer>
);

export default ListItemLabel;
