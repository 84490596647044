import { useState } from 'react';
import { useFundingSourceMicroDepositState } from 'src/pages/settings/hooks/useFundingSourceMicroDeposits';

export const useMicroDepositParams = (microDepositEventPage: string) => {
  const [verifyingId, setVerifyingId] = useState<number | null>(null);

  const microDepositProps = {
    key: verifyingId,
    fundingSourceId: verifyingId,
  };
  const [state, actions] = useFundingSourceMicroDepositState(microDepositEventPage, microDepositProps);

  return {
    verifyingId,
    setVerifyingId,
    state,
    actions,
    microDepositEventPage,
  };
};
