import { useMemo, useRef, useState } from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import invitationStore from 'src/modules/invitations/invitations-store';
import { validateUserOrgData } from 'src/modules/users/utils/validateUserOrgData';
import {
  AssignClientsModalMessage,
  useAssignClientsState,
  UserType,
} from 'src/pages/team/team-management/AssignClientsModal';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { useForm } from 'src/ui/form';
import { NotificationVariant, Role } from 'src/utils/consts';
import { getAccessLevel } from '../../../utils';
import { InviteTeamMemberModalMessage } from './components/InviteTeamMemberModalMessage';
import { LoadingModalMessage } from './components/LoadingModalMessage';
import { useActorUserOrgs } from './modules/useActorUserOrgs';
import { useFetchThresholds } from './modules/useFetchThresholds';
import { useStoreData } from './modules/useStoreData';
import { FinalInvitationType, InvitationModelType } from './types';

type Props = {
  dismiss: () => void;
  orgId: number;
};

export function InviteTeamMemberModal({ dismiss, orgId }: Props) {
  const [status, setStatus] = useState<'loading' | 'invite' | 'assign'>('loading');
  const { actor, actorUserOrg, currentOrg, isBatchCreating, isValidating, organizations } = useStoreData();
  const invitationActions = useStoreActions(invitationStore);
  const { minThresholds } = useFetchThresholds({
    orgId,
    onFetchDone: () => setStatus('invite'),
    onError: () => {
      analytics.trackAction('invite-team-member-modal_fetch-min-thresholds-error');
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'team.notifications.invite.error.minThresholds',
      });
      dismiss();
    },
  });
  const actorUserOrgs = useActorUserOrgs({ actorUserOrgs: actor.userOrganizations || [], minThresholds });
  const showAssignClients = organizations.length > 1;

  const requestedActionRef = useRef<'invite' | 'assign' | null>(null);
  const model = useMemo<InvitationModelType>(
    () => ({
      firstName: null,
      lastName: null,
      email: null,
      role: Role.ACCOUNTANT,
      approvalAmountThreshold: null,
      requireApproval: false,
      organizationId: orgId,
      canAccessFirm: false,
    }),
    [orgId]
  );
  const [invitationMV, { submit }] = useForm<InvitationModelType>(model, {
    submit: async (value) => {
      validateUserOrgData('invitation', value, actorUserOrg);
      await invitationActions.validateCreate({ orgId, ...value });

      if (requestedActionRef.current === 'invite') {
        requestedActionRef.current = null;
        const { canAccessFirm, ...invitation } = value;

        analytics.trackAction('invite-user-submit', value);

        await invitationActions.batchCreate({
          orgId,
          items: [{ ...invitation, accessLevel: getAccessLevel(canAccessFirm) }],
        });
        dismiss();
      }

      if (requestedActionRef.current === 'assign') {
        requestedActionRef.current = null;
        setStatus('assign');
      }

      return Promise.resolve();
    },
  });

  const assignToUser: UserType = {
    id: 1, // this will be ignored as we are inviting
    firstName: invitationMV.firstName.value,
    lastName: invitationMV.lastName.value,
    userOrganizations: [],
  };

  const { clients, getAssignedUserOrgs, validate } = useAssignClientsState({
    actorUserOrgs,
    assignToUser,
    organizations,
    firmOrgId: orgId,
  });

  const handleAssignClientsSave = async () => {
    const assignedUserOrgs = getAssignedUserOrgs();

    const firmInvitation: FinalInvitationType = {
      organizationId: orgId,
      email: invitationMV.email.value,
      firstName: invitationMV.firstName.value,
      lastName: invitationMV.lastName.value,
      role: invitationMV.role.value,
      approvalAmountThreshold: invitationMV.approvalAmountThreshold.value,
      requireApproval: invitationMV.requireApproval.value,
      accessLevel: getAccessLevel(invitationMV.canAccessFirm.value || false),
    };

    let invitations = [firmInvitation];

    if (assignedUserOrgs.length) {
      invitations = assignedUserOrgs.map<FinalInvitationType>((userOrg) => ({
        ...firmInvitation,
        organizationId: userOrg.organizationId,
        approvalAmountThreshold: userOrg.approvalAmountThreshold || null,
        requireApproval: userOrg.requireApproval,
        role: userOrg.role,
        accessLevel: 'full',
      }));

      invitations = [firmInvitation, ...invitations];
    }

    try {
      validate(actorUserOrgs, 'invitation');
    } catch (error: any) {
      if (error.type === 'validation') {
        analytics.trackAction('form-validation-error', {
          validationErrors: error.errors,
        });

        return;
      }

      throw error;
    }

    analytics.trackAction('invite-and-assign-user-submit', {
      canAccessFirm: invitationMV.canAccessFirm.value,
      invitations,
    });

    await invitationActions.batchCreate({ orgId, items: invitations });
    dismiss();
  };

  const gotoAssignClients = (event) => {
    requestedActionRef.current = 'assign';
    submit(event);
  };
  const inviteOnly = async (event) => {
    requestedActionRef.current = 'invite';
    submit(event);
  };

  const inviteIsValidating = requestedActionRef.current === 'invite' && isValidating;
  const assignIsValidating = requestedActionRef.current === 'assign' && isValidating;

  if (status === 'loading') {
    return <LoadingModalMessage />;
  }

  if (status === 'invite') {
    return (
      <InviteTeamMemberModalMessage
        assignIsValidating={assignIsValidating}
        inviteIsValidating={inviteIsValidating}
        companyName={currentOrg.companyName || ''}
        isBatchCreating={isBatchCreating}
        invitationMV={invitationMV}
        showAssignClients={showAssignClients}
        dismiss={dismiss}
        onSubmit={inviteOnly}
        onGotoAssignClients={gotoAssignClients}
      />
    );
  }

  if (status === 'assign') {
    return (
      <AssignClientsModalMessage
        showApprovalFields
        actorUserOrgs={actorUserOrgs}
        titleLabel="team.modals.invite.assignClientsTitle"
        isLoading={assignIsValidating || isBatchCreating}
        onCloseClick={dismiss}
        clients={clients}
        firstName={invitationMV.firstName.value}
        lastName={invitationMV.lastName.value}
        onSave={handleAssignClientsSave}
      />
    );
  }

  return null;
}
