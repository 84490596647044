import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useModal } from 'src/helpers/react/useModal';
import qbLogo from 'src/images/accounting-software/quickbooks-logo.svg';
import organizationStore from 'src/modules/organizations/organizations-store';
import { useInvoiceGenerationEnabled } from 'src/pages/get-pro/hooks/useInvoiceGenerationEnabled';
import { globalLocations } from 'src/pages/locations';
import { accountingSoftwareDisplayName, eventPage } from 'src/pages/settings/components/accounting-software/consts';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { AccountingSoftware, DialogVariants, SyncStatus } from 'src/utils/consts';
import {
  AccountingPlatformLogo,
  ButtonsContainer,
  DescriptionWrapper,
  LastSyncedWrapper,
} from '../AccountingSoftwareCardElements';
import { InvoiceGenerationContainer } from '../invoice-generation/InvoiceGenerationContainer';

type ConnectedViewProps = {
  QBCompanyName: string;
  lastFullUpdate: Date;
  status: SyncStatus;
  onUnlink: () => Promise<void>;
};

const CTAButton = ({ status, onUnlink }) => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const { displayShortName } = accountingSoftwareDisplayName[AccountingSoftware.QUICKBOOKS_DESKTOP];

  const onUnlinkConfirmed = () => {
    analytics.track(eventPage, 'unlink-confirm', {
      type: AccountingSoftware.QUICKBOOKS_DESKTOP,
    });

    return onUnlink();
  };

  const onUnlinkCanceled = () => {
    analytics.track(eventPage, 'unlink-cancel', {
      type: AccountingSoftware.QUICKBOOKS_DESKTOP,
    });
  };

  const redirectToGuide = (pathKey) => () =>
    history.push(generatePath(globalLocations.settings.syncQBDLocations[pathKey], { orgId }));

  const [unLinkQBD, onUnLinkQBD] = useModal(CommonDialog, {
    title: 'settings.newAccountingSoftware.SSO.unlinkDialog.title',
    confirmText: 'settings.newAccountingSoftware.SSO.unlinkDialog.confirm',
    textValues: { accountingSoftwareType: displayShortName },
    variant: DialogVariants.ERROR,
    confirm: onUnlinkConfirmed,
    onDismiss: onUnlinkCanceled,
  });

  const getPropsFor = (status: SyncStatus): { label: string; onClick: () => void } => {
    switch (status) {
      case SyncStatus.STARTED:
        return {
          label: 'settings.newAccountingSoftware.Quickbooks.cta.startGuideAgain',
          onClick: redirectToGuide('entry'),
        };
      case SyncStatus.PENDING:
        return {
          label: 'settings.newAccountingSoftware.Quickbooks.cta.inProgress',
          onClick: redirectToGuide('inProgress'),
        };
      default:
        return {
          label: 'settings.newAccountingSoftware.unlink',
          onClick: onUnLinkQBD,
        };
    }
  };

  const props = getPropsFor(status);

  return (
    <>
      {unLinkQBD}
      <ButtonsContainer>
        <Button variant={ButtonVariants.tertiary} size={ButtonSizes.lg} {...props} />
      </ButtonsContainer>
    </>
  );
};

const LastUpdateIndication = ({ lastFullUpdate }) => {
  const dateValue = lastFullUpdate ? (
    <MIFormattedDateTime date={lastFullUpdate} />
  ) : (
    <MIFormattedText label="settings.newAccountingSoftware.waitingForSyncDate" />
  );

  return (
    <LastSyncedWrapper>
      <MIFormattedText label="settings.newAccountingSoftware.lastSynced" values={{ date: dateValue }} />
    </LastSyncedWrapper>
  );
};

export const QBDConnectedView = ({ lastFullUpdate, QBCompanyName, status, onUnlink }: ConnectedViewProps) => {
  const orgId = useSelector(getOrgId);
  const unlinkQuickbooksStatus = useSelector(
    organizationStore.selectors.unlinkQuickbookSlice.getUnlinkQuickbooksStatus(orgId)
  );
  const { isInvoiceGenerationEnabled, shouldGenerateInvoiceFile } = useInvoiceGenerationEnabled();
  const accountingSoftwareType = AccountingSoftware.QUICKBOOKS_DESKTOP;
  const { displayName, displayShortName } = accountingSoftwareDisplayName[accountingSoftwareType];

  const succesfulConnection = status === SyncStatus.SYNCED;
  const subtitle = succesfulConnection
    ? 'settings.newAccountingSoftware.SSO.connected'
    : 'settings.newAccountingSoftware.SSO.content';
  const title = succesfulConnection
    ? 'settings.newAccountingSoftware.SSO.cardTitleConnected'
    : 'settings.newAccountingSoftware.SSO.cardTitle';

  return (
    <>
      {unlinkQuickbooksStatus?.isUnlinking ? (
        <AreaLoader />
      ) : (
        <>
          <MICard>
            <MICardForm>
              <AccountingPlatformLogo src={qbLogo} alt="logo" />
              <MICardTitle label={title} values={{ accountingSoftwareType: displayName }} />
              <Box mt={3}>
                <DescriptionWrapper>
                  <MIFormattedText
                    label={subtitle}
                    values={{
                      accountingSoftwareType: displayShortName,
                      accountingSoftwareAccount: QBCompanyName,
                    }}
                  />
                </DescriptionWrapper>
                <CTAButton status={status} onUnlink={onUnlink} />
                <LastUpdateIndication lastFullUpdate={lastFullUpdate} />
              </Box>
            </MICardForm>
          </MICard>
          {isInvoiceGenerationEnabled && (
            <InvoiceGenerationContainer
              shouldGenerateInvoiceFile={shouldGenerateInvoiceFile}
              accountingSoftwareType={AccountingSoftware.QUICKBOOKS_DESKTOP}
            />
          )}
        </>
      )}
    </>
  );
};
