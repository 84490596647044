import { useHistory } from 'react-router-dom';
import { HStack } from 'src/core/ds/stack';
import { useQueryString } from 'src/utils/hooks';
import { TabBox } from './components/Box';
import { useTabsData } from './hooks/useTabsData';
import { tabsContainerStyle } from './styles';
import { getTabs } from './utils';

export const Tabs = () => {
  const history = useHistory();
  const query = useQueryString();
  const tabs = getTabs(query);
  const tabsData = useTabsData();

  return (
    <HStack spacing={0} __css={tabsContainerStyle}>
      {tabs.map(({ label, active, to: toPath }) => (
        <TabBox
          id={label}
          key={label}
          title={label}
          selected={active}
          onClick={() => history.push(typeof toPath === 'function' ? toPath() : toPath)}
          numberOfItems={tabsData?.[label]?.numberOfItems}
          amount={tabsData?.[label]?.amount}
        />
      ))}
    </HStack>
  );
};
