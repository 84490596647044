import { GetPaidEmptyState } from '../consts';
import { GetPaidEmptyStateProps } from './useGetPaidEmptyState';

export const getContentState = ({
  noInvoices,
  loadingInvoices,
  loadingStatus,
  canCreatePaymentRequests,
}: Partial<GetPaidEmptyStateProps> & {
  loadingStatus?: boolean;
}) => {
  if (!canCreatePaymentRequests) {
    return GetPaidEmptyState.BLOCKED;
  }

  if (noInvoices && !loadingStatus && loadingInvoices !== undefined && loadingInvoices === false) {
    return GetPaidEmptyState.EMPTY;
  }

  return GetPaidEmptyState.CONTENT;
};
