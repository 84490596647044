import * as React from 'react';
import { useIntl } from 'react-intl';
import { InstallmentListItem } from 'src/pay/components/InstallmentListItem';
import { PayCardBadge } from 'src/pay/consts';
import { Installment } from 'src/utils/types';

export const FailedInstallmentListItem = ({
  disabled,
  onClick,
  vendorName,
  search,
  installment,
  isSelected,
  totalNumberOfInstallments,
}: {
  installment: Installment;
  totalNumberOfInstallments: number;
  disabled: boolean;
  onClick: () => void;
  vendorName: string;
  search: string;
  isSelected: boolean;
}) => {
  const { amount, installmentNumber, scheduledDate } = installment;

  const { formatMessage } = useIntl();

  return (
    <InstallmentListItem
      totalNumberOfInstallments={totalNumberOfInstallments}
      onClick={onClick}
      isSelected={isSelected}
      search={search}
      vendorName={vendorName}
      disabled={disabled}
      currentInstallmentNumber={installmentNumber!}
      installmentAmount={amount}
      scheduledDate={scheduledDate}
      deliveryStatusFormattedMessage={formatMessage({ id: 'list.group.installment.status.paid' }, { vendorName })}
      tag={PayCardBadge.Failed}
    />
  );
};
