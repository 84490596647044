import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import Tooltip from 'src/core/ds/tooltip';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getPaymentsActions } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { billLocations } from 'src/pages/bill/locations';
import { PaymentsOverview } from 'src/pages/contacts/list/components/PaymentsOverview/PaymentsOverview';
import { BY_DUE_DATE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';
import { getEligibleToBatchPaymentsBills } from 'src/pages/regular-batch-payments/utils';
import { useOrgDefaultFundingSource } from 'src/pages/settings/hooks/useOrgDefaultFundingSource';
import { getBillingDetails, getOrgId } from 'src/redux/user/selectors';
import { getDefaultFundingSource } from 'src/redux/utils';
import { analytics } from 'src/services/analytics';
import { PaymentApprovalStatus } from 'src/utils/consts';
import { BillType, PaymentRequestType, VendorType } from 'src/utils/types';
import { getUnpaidBillsEligibleForPayment, getUnpaidBillsResources, shouldShowPayUnpaidBills } from './utils';

type Props = {
  vendor: VendorType<'created'>;
  goCreateBill: () => void;
};

const eventPage = 'payments-overview-widget';

export const PaymentsOverviewContainer = ({ vendor, goCreateBill }: Props) => {
  const { fee } = useSelector(getBillingDetails);
  const { isDesktop } = useBreak();
  const { navigate } = useNavigator();
  const orgId = useSelector(getOrgId);
  const vendorId = vendor.id ? +vendor.id : -1;
  const { id, companyName } = vendor;
  const location = useLocation();
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const [fundingSources] = useGetValidFundingSources();
  const [paymentRequests, setPaymentRequests] = useState<PaymentRequestType[]>([]);
  const [unpaidBills, setUnpaidBills] = useState<BillType[]>([]);
  const [pendingResources, setPendingResources] = useState<boolean>(true);
  const dispatch = useDispatch();
  const batchPaymentsActions = useStoreActions(regularBatchPaymentsStore);
  const paymentActions = getPaymentsActions(dispatch);
  const { orgDefaultFundingSourceId } = useOrgDefaultFundingSource();
  const { isInternationalVendor, isInternationalAllowed } = useIsInternationalAllowed(id);
  const isBillActionDisabled = isInternationalVendor && !isInternationalAllowed;

  const selectVendorPaymentPreferences = (vendorId: number) => (state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.item(state, vendorId);

  const getResources = useCallback(async () => {
    const { unpaidBills: unpaidBillsRes, paymentRequests } = await getUnpaidBillsResources(orgId, vendorId);
    setPaymentRequests(paymentRequests);
    setUnpaidBills(unpaidBillsRes);
    setPendingResources(false);
  }, [orgId, vendorId]);

  useEffect(() => {
    getResources();
  }, [vendorId, orgId, getResources]);

  const vendorPaymentPreferences = useSelector(selectVendorPaymentPreferences(vendorId));
  const eligibleUnpaidBills = getUnpaidBillsEligibleForPayment(unpaidBills, paymentRequests, id, companyName);
  const eligibleToBatchPaymentsBills = useMemo(
    () => getEligibleToBatchPaymentsBills(eligibleUnpaidBills, [vendor], fundingSources, {}),
    [eligibleUnpaidBills, vendor, fundingSources]
  );
  const eligibleUnpaidBillIds = map('id', eligibleUnpaidBills);
  const eligibleToBatchPaymentsBillIds = map('id', eligibleToBatchPaymentsBills)?.map((id) => id.toString());

  const isShowPayUnpaidBills = shouldShowPayUnpaidBills(
    eligibleUnpaidBillIds,
    fundingSources,
    vendorPaymentPreferences
  );
  const isSingleOrMoreUnpaidBills = eligibleUnpaidBillIds.length >= 1;
  const shouldShowPayUnpaidBillsButton =
    isDesktop && !pendingResources && isShowPayUnpaidBills && isSingleOrMoreUnpaidBills;
  const shouldShowAddNewBillButton =
    !shouldShowPayUnpaidBillsButton && !pendingResources && isEmpty(eligibleUnpaidBills);
  const vendorBillsByPaymentStatus = useSelector(
    vendorsStore.selectors.countBillsByType.getVendorBillsCountByPaymentStatus(vendor.id)
  );
  const paymentsCountLabel = isDesktop
    ? 'vendors.paymentsOverview.paymentsCount'
    : 'vendors.paymentsOverview.paymentsCountMobile';

  const onRetryPayment = (billId: string, paymentId: number) => {
    analytics.track(eventPage, 'retry-payment');
    navigate(
      generatePath(billLocations.pay.edit.funding, {
        orgId,
        billId,
        paymentId,
      })
    );
  };

  const onPayUnpaidClick = useCallback(async () => {
    const fundingSource = await getDefaultFundingSource(orgId, fundingSources, orgDefaultFundingSourceId);
    const fundingSourceId = fundingSource?.id || fundingSources[0].id;
    analytics.track(eventPage, 'vendor-pay-unpaid-bills-clicked', {
      batchBillIds: eligibleUnpaidBillIds,
      batchBillCount: eligibleUnpaidBillIds.length,
      fundingSourceId,
      id,
    });
    const multipleUnpaid = eligibleToBatchPaymentsBillIds.length > 1;

    if (multipleUnpaid) {
      batchPaymentsActions.settings.startBatchPaymentsFlow({
        orgId,
        billIds: eligibleToBatchPaymentsBillIds,
        fundingSourceId,
        dateOption: BY_DUE_DATE_OPTION_ID,
        fee,
        exitLocation: location,
      });
    } else {
      const isDeclinedPayment =
        unpaidBills[0]?.payments[0]?.approvalDecisionStatus === PaymentApprovalStatus.DECLINED &&
        permissions.bills.reschedule();
      isDeclinedPayment
        ? onRetryPayment(unpaidBills[0].id, unpaidBills[0].payments[0].id)
        : paymentActions.startSinglePaymentFlow({ billId: eligibleUnpaidBillIds[0], orgId });
    }
  }, [
    id,
    fee,
    orgId,
    navigate,
    unpaidBills,
    fundingSources,
    eligibleUnpaidBillIds,
    eligibleToBatchPaymentsBillIds,
    paymentActions,
    permissions.bills,
    batchPaymentsActions.settings,
  ]);

  return (
    <Box p={6} minHeight="28.74rem">
      <Flex mb={4} py={2} justify="space-between">
        <Flex textStyle="h2Semi" fontWeight="bold" height={10} align="center">
          <MIFormattedText label="vendors.paymentsOverview.title" />
        </Flex>
        {shouldShowPayUnpaidBillsButton && (
          <Tooltip
            data-testid="pay-unpaid-bills-disabled-tooltip"
            isDisabled={!isBillActionDisabled}
            placement="top"
            maxW="max"
            shouldWrapChildren
            label={
              <Box textAlign="start" px={2} py={1}>
                <MIFormattedText label="vendors.paymentsOverview.payUnpaidBillsInternationalDisabledTooltip" />
              </Box>
            }
          >
            <Button
              w="fit-content"
              size={ButtonSizes.md}
              variant={ButtonVariants.success}
              onClick={onPayUnpaidClick}
              label="vendors.paymentsOverview.payUnpaidBills"
              testId="pay-unpaid-bills-button"
              isDisabled={isBillActionDisabled}
            />
          </Tooltip>
        )}
        {shouldShowAddNewBillButton && (
          <Tooltip
            data-testid="add-new-bill-disabled-tooltip"
            isDisabled={!isBillActionDisabled}
            placement="top"
            maxW="max"
            shouldWrapChildren
            label={
              <Box textAlign="start" px={2} py={1}>
                <MIFormattedText label="vendors.paymentsOverview.newBillInternationalDisabledTooltip" />
              </Box>
            }
          >
            <Button
              w="fit-content"
              size={ButtonSizes.md}
              variant={ButtonVariants.primary}
              onClick={goCreateBill}
              label="vendors.paymentsOverview.new"
              testId="add-bill-button"
              isDisabled={isBillActionDisabled}
            />
          </Tooltip>
        )}
      </Flex>
      {pendingResources ? (
        <Flex h="18rem" align="center">
          <Loader color="primary" context="page" />
        </Flex>
      ) : (
        <PaymentsOverview
          vendor={vendor}
          vendorBillsByPaymentStatus={vendorBillsByPaymentStatus}
          paymentsCountLabel={paymentsCountLabel}
        />
      )}
    </Box>
  );
};
