import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import Box from 'src/core/ds/box';
import { Installment } from 'src/utils/types';

type Props = {
  amount: Installment['amount'];
};

export const AmountCell = ({ amount }: Props) => (
  <Box textAlign="right" isTruncated>
    <MIFormattedCurrency value={amount} privateData />
  </Box>
);
