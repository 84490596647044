import * as React from 'react';
import { ItemizedList } from 'src/components/common/ItemizedList';
import { MICard, MICardForm, MICardHeader, MICardTitle } from 'src/components/common/MICard';

type Props = {
  title: string;
  children: React.ReactNode;
  rightToTitleElement?: React.ReactNode;
};
export const Section = ({ title, children, rightToTitleElement }: Props) => (
  <MICard>
    <MICardForm>
      <MICardHeader>
        <MICardTitle label={title} />
        {rightToTitleElement}
      </MICardHeader>
      <ItemizedList>{children}</ItemizedList>
    </MICardForm>
  </MICard>
);
