import { format } from 'date-fns';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { ReactComponent as JPMorgan } from 'src/images/icons/jpmorgan.svg';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import {
  AnimatedCardDigits,
  DigitsSplitArray,
} from 'src/pages/vendor/virtual-card/delivery-method/components/animatedCardDigits';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { MiMoneyFlavor, NotificationVariant } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardDetailsView = ({ state, analyticsProperties }: Props) => {
  const { virtualCard, virtualCard4digitsArray, payment } = state;
  const eventPage = 'virtual-card-details';
  const formattedExpirationDate =
    virtualCard?.expirationDate &&
    format(new Date(virtualCard?.expirationDate), DateFormats.monthNumberWithSlashShortYear);

  const onCopy = () => {
    analytics.track(eventPage, 'valid_click-copy', analyticsProperties);
    pushNotification({
      type: NotificationVariant.INFO,
      msg: 'vendors.virtualCard.valid.content.copyToastMessage',
    });
  };

  const digitsSplitArray =
    (virtualCard4digitsArray?.map((digitsGroup) => digitsGroup.split('')) as DigitsSplitArray) || [];

  return (
    <Flex align="center" justify="center" direction="column" mt={8}>
      <Flex
        flexDirection="column"
        bgGradient="linear-gradient(249.05deg, #4A22BE -21.14%, #7849FF 97.31%)"
        color="white"
        borderRadius="10px"
        p={4}
        data-testid="virtual-card-preview"
      >
        <Flex justifyContent="space-between">
          <MIMoney amount={payment?.amount} flavor={MiMoneyFlavor.POSITIVE_WHITE} fontWeight="regular" />
          <Mastercard width="3.5rem" height="2.7rem" />
        </Flex>
        <AnimatedCardDigits digitsSplitArray={digitsSplitArray} />
        <Flex align="center">
          <Box fontSize="0.6rem" lineHeight="0.8rem" textTransform="uppercase" mr={0.5}>
            <MIFormattedText label="vendors.virtualCard.valid.content.cardValid" />
          </Box>
          <Box textStyle="body2" mr={3}>
            {formattedExpirationDate}
          </Box>
          <Box fontSize="0.6rem" lineHeight="0.8rem" textTransform="uppercase" mt={1} mr={0.5}>
            <MIFormattedText label="vendors.virtualCard.valid.content.cardCVC" />
          </Box>
          <Box textStyle="body2" mr={8} letterSpacing="1px">
            {virtualCard?.cvv}
          </Box>
          <Box mt={1}>
            <JPMorgan width="6.4rem" height="1.3rem" />
          </Box>
        </Flex>
      </Flex>
      <Box textStyle="body3" textAlign="center" color="grey.700" mt={{ base: 4, md: 6 }}>
        <MIFormattedText label="vendors.virtualCard.valid.content.address" />
      </Box>
      <Box textStyle="body2" textAlign="center" mb={{ base: 6, md: 8 }} mt={{ base: 6, md: 8 }}>
        <MIFormattedText
          label="vendors.virtualCard.valid.content.description"
          values={{ date: <MIFormattedDate date={virtualCard?.endDate} /> }}
        />
      </Box>
      <CopyToClipboard onCopy={onCopy} text={virtualCard?.cardNumber}>
        <Button
          width={{ base: '-webkit-fill-available', md: 'auto' }}
          label="vendors.virtualCard.valid.content.copy"
          size={ButtonSizes.md}
          variant={ButtonVariants.secondary}
          data-testid="virtual-card-copy-button"
        />
      </CopyToClipboard>
    </Flex>
  );
};
