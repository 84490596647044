import { InvoiceType } from 'src/modules/invoices/types';
import InvoiceDetails from 'src/pages/get-paid/list/components/InvoiceDetails';
import { InvoiceModelDataType } from 'src/pages/get-pro/types';
import { ModelView } from 'src/ui/form';
import { InvoiceStatus } from 'src/utils/consts';
import { ContactType, FileUrlsPayloadType } from 'src/utils/types';

type Props = {
  invoice?: InvoiceType;
  invoiceMV: ModelView<InvoiceModelDataType>;
  inEditMode: boolean;
  uploading: boolean;
  loading: boolean;
  onEditToggle: () => void;
  onMarkInvoiceAsPaid: (value: boolean) => void;
  remove: (removeInvoice?: boolean) => Promise<void>;
  fileUrls?: FileUrlsPayloadType | null;
  loadingFileUrls: boolean;
  deleteFile: () => void;
  uploadFile: (file: File) => Promise<void>;
  openPdfPreview?: () => void;
  customers: ContactType[];
};

export const InvoiceInfo = ({
  invoice,
  invoiceMV,
  inEditMode,
  uploading,
  loading,
  onEditToggle,
  onMarkInvoiceAsPaid,
  remove,
  fileUrls,
  loadingFileUrls,
  deleteFile,
  uploadFile,
  openPdfPreview,
  customers,
}: Props) => (
  <InvoiceDetails
    showGetPaidTabs
    invoice={invoice as InvoiceType}
    totalAmount={invoiceMV.totalAmount}
    customerName={invoiceMV.customerName}
    invoiceNumber={invoiceMV.invoiceNumber}
    dueDate={invoiceMV.dueDate}
    viewOnly={!inEditMode || uploading || loading}
    showMenu={invoice?.status === InvoiceStatus.CREATED || invoice?.status === InvoiceStatus.MARKED_AS_PAID}
    onEdit={onEditToggle}
    onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
    remove={remove}
    fileUrls={fileUrls || null}
    loadingAttachment={loadingFileUrls}
    deleteFile={deleteFile}
    uploadFile={uploadFile as (file: any) => Promise<void>}
    customers={customers}
    openPdfPreview={openPdfPreview}
  />
);
