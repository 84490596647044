import { isToday } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { CellProps } from 'react-table';
import { Lottie } from 'src/components/common/Lottie';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { HStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import WarningIcon from 'src/images/get-pro/warning.svg';
import { ApprovalDecision } from 'src/modules/payments/api';
import { PaymentsRequestStatus } from 'src/pages/get-pro/consts';
import { InvoiceStatus, PaymentRequestApprovalStatus } from 'src/utils/consts';
import paidDotAnimation from '../animations/paid.json';
import scheduledDotAnimation from '../animations/scheduled.json';
import { getFormatTableDate } from '../utils';

type StatusCellProps = CellProps<StatusCellValue> | { cell: { value: StatusCellValue } };

type StatusCellValue = {
  deliveryDate?: Date;
  markedAsPaidAt?: Date;
  type: string;
  updatedAt: Date;
  approvalStatus?: ApprovalDecision;
};

const STATUS_CELL_MAP = {
  [InvoiceStatus.SCHEDULED]: {
    animation: scheduledDotAnimation,
    name: 'getPro.status.scheduled',
    color: 'blue.500',
  },
  [InvoiceStatus.PAID]: {
    animation: paidDotAnimation,
    name: 'getPro.status.fullyPaid',
    color: 'green.500',
  },
  [InvoiceStatus.MARKED_AS_PAID]: {
    animation: paidDotAnimation,
    name: 'getPro.status.markedAsPaid',
    color: 'green.500',
  },
  [PaymentsRequestStatus.SENT]: {
    icon: IconNames.checkDouble,
    name: 'getPro.status.sent',
    color: 'grey.600',
  },
  [PaymentsRequestStatus.SENT_SEEN]: {
    icon: IconNames.checkDouble,
    name: 'getPro.status.sentSeen',
    color: 'black',
  },
  [PaymentsRequestStatus.REMINDER]: {
    icon: IconNames.checkDouble,
    name: 'getPro.status.reminder',
    color: 'grey.600',
  },
  [PaymentsRequestStatus.REMINDER_SEEN]: {
    icon: IconNames.checkDouble,
    name: 'getPro.status.reminderSeen',
    color: 'black',
  },
  [InvoiceStatus.FAILED]: {
    name: 'getPro.status.failed',
    color: 'red.500',
  },
  [InvoiceStatus.IN_PROGRESS]: {
    name: 'getPro.status.processing',
    color: 'black',
  },
  [InvoiceStatus.CANCELLED_BY_CUSTOMER]: {
    name: 'getPro.status.cancelled',
    color: 'red.500',
  },
  [InvoiceStatus.PENDING]: {
    name: 'getPro.status.pending',
    color: 'yellow.500',
  },
  fallback: {
    name: null,
    color: 'transparent',
  },
};

const StatusCellDot = ({ color }) => (
  <Box w={2} h={2} flexShrink={0} borderRadius={8} my={1} mx="0.6rem" backgroundColor={color} />
);

const StatusCellIcon = ({ color, icon }) => (
  <Box w={4} h={4} mx="0.2rem" color={color}>
    <Icon name={icon} size={IconSize.s} />
  </Box>
);

const StatusCellAnimation = ({ animation, autoplay }) => (
  <Box flexShrink={0}>
    <Lottie height="2rem" width="2rem" animationData={animation} loop={false} autoplay={autoplay} />
  </Box>
);

export const StatusCell: React.FC<StatusCellProps> = ({ cell: { value } }) => {
  const { deliveryDate, markedAsPaidAt, type, updatedAt, approvalStatus } = value;
  const statusDate = markedAsPaidAt || (type === InvoiceStatus.PAID ? deliveryDate : updatedAt);
  const formatDate = getFormatTableDate(statusDate);
  const autoPlayAnimation = useMemo(() => isToday(new Date(statusDate)), [statusDate]);
  const showRejectedTooltip = approvalStatus === PaymentRequestApprovalStatus.DENIED;

  const { name, color, icon, animation } = useMemo(() => STATUS_CELL_MAP[type] || STATUS_CELL_MAP.fallback, [type]);

  const renderIndicator = useCallback(() => {
    if (animation) return <StatusCellAnimation animation={animation} autoplay={autoPlayAnimation} />;

    if (icon) return <StatusCellIcon color={color} icon={icon} />;

    return <StatusCellDot color={color} />;
  }, [animation, autoPlayAnimation, color, icon]);

  return useMemo(
    () => (
      <HStack spacing={1}>
        {renderIndicator()}
        <Box isTruncated>
          <MIFormattedText label={name} />
        </Box>
        <HStack spacing={2}>
          <Box isTruncated color="grey.600">
            ({formatDate})
          </Box>
          {showRejectedTooltip && (
            <Tooltip placement="top" label={<MIFormattedText label="getPro.tooltip.approvalStatus.rejected" />}>
              <Image src={WarningIcon} height={4} width={4} data-testid="rejected-payment-request-tooltip" />
            </Tooltip>
          )}
        </HStack>
      </HStack>
    ),
    [formatDate, name, renderIndicator, showRejectedTooltip]
  );
};
