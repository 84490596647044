export const scrollToElement = (ref: HTMLDivElement | null) => {
  if (ref) {
    const appEnv = (window as any).APP_ENV || process.env.REACT_APP_ENV;

    // This condition exists because there is a problem testing scroll with scrolling behavior in cypress
    if (appEnv === 'production') {
      ref.scrollIntoView({ behavior: 'smooth' });
    } else {
      ref.scrollIntoView();
    }
  }
};
