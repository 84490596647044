import { useSelector } from 'react-redux';
import { GetProListItemType } from 'src/components/shared/tables/PaymentsTable/types';
import { RestListStatus } from 'src/helpers/redux/restListSlice';
import { getProStore } from 'src/modules/get-pro/get-pro-store';
import { getProInProgressPaymentsStore } from 'src/modules/get-pro-in-progress-payments/get-pro-in-progress-payments-store';
import { getProPaidPaymentsStore } from 'src/modules/get-pro-paid-payments/get-pro-paid-payments-store';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import { GetProTabs } from 'src/utils/consts';
import { GetProListParams, GetProTabsSummary } from '../types';

type UseGetProListType = {
  items: GetProListItemType[];
  loadStatus: RestListStatus | undefined;
  tabsSummary: Record<GetProTabs, GetProTabsSummary>;
  currentDataLastUpdated: string;
  hasPaymentRequests?: boolean;
  loadListStatus?: any;
  hasAlertsInLast7Days: boolean;
};

export const useGetProList = (listParams: GetProListParams): UseGetProListType => {
  const { status } = listParams.filters;
  let currentStore;

  if (status === GetProTabs.INVOICES) {
    currentStore = invoicesStore;
  } else if (status === GetProTabs.REQUESTS) {
    currentStore = paymentRequestsStore;
  } else if (status === GetProTabs.IN_PROGRESS) {
    currentStore = getProInProgressPaymentsStore;
  } else {
    currentStore = getProPaidPaymentsStore;
  }

  const listSelector = currentStore.selectors?.list;
  const tabsSummarySelector = getProStore.selectors?.getProTabsSummary;
  const alertsSummarySelector = getProStore.selectors?.getAlertsSummary;
  const items = useSelector((state) => listSelector?.value(state, listParams));
  const tabsSummary: Record<GetProTabs, GetProTabsSummary> = useSelector(tabsSummarySelector?.tabsSummary);
  const loadListStatus = useSelector(listSelector?.status(listParams));
  const currentDataLastUpdated = loadListStatus?.lastUpdatedAtDate;
  const hasPaymentRequests = loadListStatus?.hasPaymentRequests;
  const loadTabsSummaryStatus = useSelector(tabsSummarySelector?.loading);
  const alertsSummary = useSelector(alertsSummarySelector?.alertsSummary);
  const loadAlertsSummaryStatus = useSelector(alertsSummarySelector?.loading);
  const hasAlertsInLast7Days = alertsSummary?.totalCount > 0;
  const loadStatus = loadListStatus || loadTabsSummaryStatus || loadAlertsSummaryStatus;

  return {
    items,
    loadStatus,
    tabsSummary,
    currentDataLastUpdated,
    hasPaymentRequests,
    hasAlertsInLast7Days,
  };
};
