import { ChangeEvent, FocusEvent, forwardRef } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormControl, FormControlProps, FormLabel } from 'src/core/ds/form';
import { ErrorMessage, HelperText, OptionalLabel } from 'src/core/ds/form/fields/components';
import { DateInput, DateInputProps } from 'src/core/ds/input';
import { ModelViewField } from 'src/ui/form';
import { DateFormats } from 'src/utils/date-fns';

export type DateProps = FormControlProps &
  DateInputProps & {
    id?: string;
    model?: ModelViewField<string>;
    date?: Date | null;
    dateFormat?: DateFormats;
    minDate?: string;
    maxDate?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    size?: 'sm' | 'md';
    label?: string;
    labelValues?: Record<string, unknown>;
    helperText?: string;
    helperTextValues?: Record<string, unknown>;
    errorMessage?: string | null;
    errorMessageValues?: Record<string, unknown>;
    readOnlyValue?: boolean;
  };

export const DateFieldInput = forwardRef(
  (
    {
      id,
      testId,
      date,
      model,
      isRequired = false,
      readOnlyValue,
      size,
      helperText,
      helperTextValues,
      label,
      labelValues,
      errorMessage,
      errorMessageValues,
      onChange,
      isDisabled,
      mb,
      ...rest
    }: DateProps,
    ref: React.LegacyRef<HTMLInputElement>
  ) => {
    const isError = Boolean(errorMessage) || Boolean(model?.error);
    const noticesTestId = `input-${id}-notices`;
    const errorMessageText = errorMessage || model?.error;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange ? onChange(event) : model?.onChange({ value: event?.target.value });
    };

    return (
      <FormControl
        isInvalid={isError}
        isRequired={isRequired}
        size={size}
        isReadOnly={readOnlyValue}
        isDisabled={isDisabled}
        mb={mb}
      >
        <FormLabel htmlFor={id}>
          <MIFormattedText label={label} values={labelValues} />
          {!isRequired && <OptionalLabel isError={isError} />}
        </FormLabel>
        <DateInput
          {...rest}
          id={id}
          ref={ref}
          size={size}
          onChange={handleChange}
          value={date}
          readOnly={readOnlyValue}
        />
        {!isError ? (
          <HelperText testId={noticesTestId} label={helperText} labelValues={helperTextValues} />
        ) : (
          errorMessageText && (
            <ErrorMessage
              size={size}
              testId={noticesTestId}
              label={errorMessageText}
              labelValues={errorMessageValues}
            />
          )
        )}
      </FormControl>
    );
  }
);
