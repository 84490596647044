import { featureFlags } from '@melio/shared-web';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { billsStore } from 'src/modules/bills/bills-store';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchBillsListType, BatchItemType } from 'src/modules/regular-batch-payments/types';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useFetchFreeChecks } from 'src/pages/pay/hooks/useFetchFreeChecks';
import { ALL_AT_ONCE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { MainTableRowType } from 'src/pages/regular-batch-payments/components/table/types';
import {
  batchBillItemToAchToCheckFee,
  mapBatchBillItemToDataTableRowType,
} from 'src/pages/regular-batch-payments/utils';
import { getCompanyInfo, getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { getPaymentRequestIdById, isPaymentRequest } from 'src/utils/bills';
import { FeatureFlags } from 'src/utils/consts';
import { BillType, VendorType } from 'src/utils/types';

export const useBatchBillsList = (): {
  billsList: MainTableRowType[];
  isBillListLoading: boolean;
  loadBillsList: (updatedParams?: {
    fundingSourceId?: number;
    scheduledDate?: string | null;
    isGroupedByVendor?: boolean;
    dateOption?: string;
  }) => Promise<Record<string, BatchBillsListType>>;
  getSingleBatchItem: (id: string) => MainTableRowType;
} => {
  const site = useSiteContext();
  const actions = useStoreActions(regularBatchPaymentsStore);
  const listSelectors = regularBatchPaymentsStore.selectors?.list;
  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const fundingSources = useSelector(getFundingSources);
  const { billIds: objectIds, fundingSourceId, scheduledDate, dateOption, bulkPaymentToggleOn } = useSelector(
    regularBatchPaymentsStore.selectors.settings.settings
  );
  const [isBulkPaymentEnabled] = featureFlags.useFeature(FeatureFlags.BulkPayment, false);
  const [isFastCheckEnabledForAllStates] = featureFlags.useFeature<boolean>(
    FeatureFlags.FastCheckEnabledForAllStates,
    false
  );
  const [isAmexAddressFeatureEnabled] = featureFlags.useFeature(FeatureFlags.AmexVendorAddressBatchFlow, false, {
    shouldTrack: false,
  });
  const { available: availableFreeChecks, fetchFreeChecks } = useFetchFreeChecks();

  useEffect(() => {
    fetchFreeChecks(orgId);
  }, [fetchFreeChecks, orgId]);

  const billIds: Array<string> = [];
  const requestIds: Array<string> = [];

  objectIds.forEach((id) => (isPaymentRequest(id) ? requestIds.push(getPaymentRequestIdById(id)) : billIds.push(id)));

  const listParams = {
    orgId,
    billIds,
    requestIds,
    fundingSourceId,
    scheduledDate: dateOption === ALL_AT_ONCE_OPTION_ID ? scheduledDate : null,
    isBulkPaymentEnabled,
    isGroupedByVendor: bulkPaymentToggleOn,
  };

  const loadBatchBillsList = async (updatedParams = {}): Promise<Record<string, BatchBillsListType>> =>
    actions.list({ ...listParams, ...updatedParams });

  const isLoading = useSelector(listSelectors.status(listParams))?.loading;

  const batchItemsList = useSelector((state) => listSelectors.value(state, listParams));

  const bills = useSelector<any, BillType[]>(billsStore.selectors.getByIds(objectIds));
  const selectedObjects = keyBy(bills, 'id');
  const selectedVendorsIds = batchItemsList.map((item) => item.payment?.vendorId);
  const selectedVendors = useSelector<any, VendorType[]>(vendorsStore.selectors.getByIds(selectedVendorsIds));

  const formattedBatchedObjectsList: MainTableRowType[] = useMemo(() => {
    const billToAchToCheckFeeMapper = batchBillItemToAchToCheckFee(availableFreeChecks);

    return batchItemsList
      .map((item: BatchItemType) => billToAchToCheckFeeMapper({ item, fundingSources, companyInfo }))
      .map(
        mapBatchBillItemToDataTableRowType({
          bills: selectedObjects,
          fundingSources,
          selectedVendors,
          isFastCheckEnabledForAllStates,
          isAmexAddressFeatureEnabled,
          site,
          companyInfo,
        })
      );
  }, [
    batchItemsList,
    selectedObjects,
    companyInfo,
    site,
    availableFreeChecks,
    fundingSources,
    selectedVendors,
    isFastCheckEnabledForAllStates,
  ]);

  const getSingleBatchItem = useCallback(
    (id: string) => {
      const batchItem = formattedBatchedObjectsList.find(
        (batchItem) => batchItem.id === id || batchItem.subRows?.find((subRow) => subRow.id === id)
      );

      return batchItem!;
    },
    [formattedBatchedObjectsList]
  );

  return {
    billsList: formattedBatchedObjectsList,
    isBillListLoading: isLoading,
    loadBillsList: loadBatchBillsList,
    getSingleBatchItem,
  };
};
