import * as React from 'react';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { FormRow as RowContainer } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { US_STATES } from 'src/utils/us-states';

type Props = {
  onSubmit: React.FormEventHandler;
  model: any;
};

export const ManualAddressForm = ({ onSubmit, model }: Props) => (
  <FormContainer onSubmit={onSubmit}>
    <RowContainer>
      <PrivateDataContainer>
        <TextField label="vendors.addDeliveryMethodByLink.addressLine1" model={model.addressLine1} isRequired />
      </PrivateDataContainer>
    </RowContainer>
    <RowContainer>
      <PrivateDataContainer>
        <TextField label="vendors.addDeliveryMethodByLink.addressLine2" model={model.addressLine2} />
      </PrivateDataContainer>
    </RowContainer>
    <RowContainer>
      <PrivateDataContainer>
        <TextField testId="input-city" label="vendors.addDeliveryMethodByLink.city" model={model.city} isRequired />
      </PrivateDataContainer>
      <WizardSelectFieldWrapper>
        <WizardSelectField
          id="state"
          label="vendors.addDeliveryMethodByLink.state"
          options={US_STATES}
          model={model.state}
          required
          privateData
        />
      </WizardSelectFieldWrapper>
    </RowContainer>
    <RowContainer>
      <PrivateDataContainer>
        <TextField label="vendors.addDeliveryMethodByLink.zipCode" model={model.zipCode} type="tel" isRequired />
      </PrivateDataContainer>
    </RowContainer>
    <button type="submit" hidden />
  </FormContainer>
);

type FormContainerProps = {
  children?: React.ReactNode;
  onSubmit?: React.FormEventHandler;
};

const FormContainerStyle: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  '> *': {
    mb: 10,
  },
};

const FormContainer = ({ onSubmit, children }: FormContainerProps) => (
  <Box as="form" onSubmit={onSubmit} sx={FormContainerStyle}>
    {children}
  </Box>
);

type WizardSelectFieldWrapperProps = {
  children?: React.ReactNode;
};

const WizardSelectFieldWrapperStyle: CSSObject = {
  '> div': {
    mb: 0,
  },
};

const WizardSelectFieldWrapper = ({ children }: WizardSelectFieldWrapperProps) => (
  <Box sx={WizardSelectFieldWrapperStyle}>{children}</Box>
);
