import { featureFlags } from '@melio/shared-web';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import invitationStore, { getInvitationActions } from 'src/modules/invitations/invitations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { validateUserOrgData } from 'src/modules/users/utils/validateUserOrgData';
import { AdminApproval } from 'src/pages/team/team-management/AdminApproval';
import { useAccountingFirmSyntheticRole } from 'src/pages/team/team-management/hooks/useAccountingFirmSyntheticRole';
import { InlineRoleSelect } from 'src/pages/team/team-management/InlineRoleSelect';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { FormContainer, FormRow, FormSectionText } from 'src/ui/form/FormElements';
import { FeatureFlags, Role } from 'src/utils/consts';
import { usePermittedRoleOptions } from 'src/utils/permissions';

type InvitationModelType = {
  firstName: string | null;
  lastName: string | null;
  role: Role;
  approvalAmountThreshold: string | null;
  email: string | null;
  requireApproval: boolean;
  orgId: number | null;
};

export function CreateInvitationModal({ dismiss, orgId, selectedRole }) {
  const [approvalWorkflowsEnabled] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);
  const currentUserId = useSelector(profileStore.selectors.getCurrentUserId);
  const currentOrg = useSelector(profileStore.selectors.getCurrentOrg);
  const currentUserOrg = useSelector(profileStore.selectors.getUserOrganization);
  const isCreating = useSelector(invitationStore.selectors.isCreating);
  const minUserWorkflowThreshold: number =
    useSelector(approvalWorkflowsStore.selectors.minUserWorkflowThreshold(currentUserId)) || Infinity;

  const isAccountant = currentUserOrg.role === Role.ACCOUNTANT;
  const { roleOptions } = usePermittedRoleOptions({ showFirmAdminRole: true });
  const maxAllowedThreshold = approvalWorkflowsEnabled
    ? minUserWorkflowThreshold
    : currentUserOrg.approvalAmountThreshold;
  const model = useMemo<InvitationModelType>(
    () => ({
      firstName: null,
      lastName: null,
      role: isAccountant || selectedRole === Role.ACCOUNTANT ? Role.ACCOUNTANT : Role.ADMIN,
      approvalAmountThreshold: null,
      email: null,
      requireApproval: true,
      orgId,
    }),
    [orgId, isAccountant]
  );
  const actions = getInvitationActions(useDispatch());
  const [invitationMV, { submit }] = useForm<InvitationModelType>(model, {
    submit: async (value) => {
      if (value.role === Role.ADMIN) {
        value.approvalAmountThreshold = null;
        value.requireApproval = false;
      }

      const valueWithNumber = {
        ...value,
        approvalAmountThreshold: Number(value.approvalAmountThreshold),
      };

      validateUserOrgData('invitation', valueWithNumber, {
        ...currentUserOrg,
        approvalAmountThreshold: maxAllowedThreshold,
      });

      analytics.trackAction('invite-user-submit', {
        ...valueWithNumber,
        page: 'settings',
      });

      await actions.create(valueWithNumber);

      dismiss();
    },
    onClear: dismiss,
  });

  const showApprovalThreshold =
    invitationMV.role.value && [Role.CONTRIBUTOR, Role.ACCOUNTANT].includes(invitationMV.role.value);
  const roleDisplay = useAccountingFirmSyntheticRole(invitationMV.role.value);
  const isRequireApprovalDisabled = approvalWorkflowsEnabled
    ? isAccountant && minUserWorkflowThreshold !== Infinity
    : isAccountant && currentUserOrg?.requireApproval;

  return (
    <ModalMessage
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label="invitation.createDialog.title" values={{ companyName: currentOrg.companyName }} />
        </ModalTitle>
      }
      contentComponent={
        <FormContainer onSubmit={submit}>
          <FormSectionText>
            <MIFormattedText label="invitation.createDialog.userDetailsSection" />
          </FormSectionText>
          <FormRow>
            <TextField label="user.firstName" model={invitationMV.firstName} size="sm" isRequired />
            <TextField label="user.lastName" model={invitationMV.lastName} size="sm" isRequired />
          </FormRow>
          <FormRow>
            <PrivateDataContainer>
              <TextField label="user.email" model={invitationMV.email} type="email" size="sm" mb={0} isRequired />
            </PrivateDataContainer>
          </FormRow>
          <FormRow />
          <FormSectionText>
            <MIFormattedText label="invitation.createDialog.userRoleSection" />
          </FormSectionText>
          <FormRow>
            <InlineRoleSelect model={invitationMV.role} options={roleOptions} group="invite-role" />
          </FormRow>
          <FormRow>
            <Box textStyle="body4">
              <MIFormattedText label={`user.permissions.${roleDisplay}`} />
            </Box>
          </FormRow>
          {showApprovalThreshold && (
            <>
              <Divider />
              <AdminApproval
                approvalAmountThresholdModel={invitationMV.approvalAmountThreshold}
                requireApprovalModel={invitationMV.requireApproval}
                disabled={isRequireApprovalDisabled}
              />
            </>
          )}
          <button type="submit" hidden />
        </FormContainer>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <Button
            label="invitation.createDialog.submit"
            type="submit"
            onClick={submit}
            size={ButtonSizes.lg}
            isLoading={isCreating}
          />
        </ModalButtonsWrapper>
      }
    />
  );
}

const Divider = styled(FormRow)`
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
`;
