import omit from 'lodash/omit';
import { useIntl } from 'react-intl';
import { PageHeader } from 'src/components/common/PageHeader';
import { useBreak } from 'src/hoc';
import { ScannedInvoiceType } from 'src/modules/scanned-invoices/types';
import { useQueryString } from 'src/utils/hooks';
import { stringifyQs } from 'src/utils/query-utils';
import { ScannedInvoiceHeaderDetails } from './components/ScannedInvoiceHeaderDetails';

type Props = {
  backPath: string;
  scannedInvoice: ScannedInvoiceType;
  onClick: () => void;
};

export const ScannedInvoiceHeader = ({ backPath, scannedInvoice, onClick }: Props) => {
  // hooks
  const { isMobile, isPhablet } = useBreak();
  const query = useQueryString();
  const intl = useIntl();

  // variables
  const { vendorName, invoiceNumber } = scannedInvoice;
  const isMobileView = isMobile || isPhablet;
  const defaultBillsSearchPath = stringifyQs(omit(query, 'id'));
  const defaultVendorName = intl.formatMessage({ id: 'pay.scannedInvoice.emptyVendorName' });
  const defaultInvoiceNumber = intl.formatMessage({ id: 'pay.scannedInvoice.details.invoiceNumberEmpty' });
  const VendorNameToDisplay = vendorName || defaultVendorName;
  const invoiceNumberToDisplay = invoiceNumber || defaultInvoiceNumber;
  const [pathname, search = defaultBillsSearchPath] = backPath.split('?');

  if (isMobileView) {
    return (
      <PageHeader
        backNav={{
          pathname,
          search,
        }}
        text={VendorNameToDisplay}
        subTitle={invoiceNumberToDisplay}
      >
        <ScannedInvoiceHeaderDetails
          onClick={onClick}
          description={invoiceNumberToDisplay}
          vendorName={VendorNameToDisplay}
          avatarName={vendorName}
          isMobileView={isMobileView}
        />
      </PageHeader>
    );
  }

  return (
    <ScannedInvoiceHeaderDetails
      onClick={onClick}
      description={invoiceNumberToDisplay}
      vendorName={VendorNameToDisplay}
      avatarName={vendorName}
      isMobileView={isMobileView}
    />
  );
};
