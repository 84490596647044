import { Tooltip } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { InstallmentsDeductionDatesInfo } from 'src/pages/bill/components/ViewBillPaymentActivity/components/InstallmentsDeductionDatesInfo';
import { InstallmentsFundingSourceInfo } from 'src/pages/bill/components/ViewBillPaymentActivity/components/InstallmentsFundingSourceInfo/InstallmentsFundingSourceInfo';
import { ViewBillPaymentActivityMemoInfo } from 'src/pages/bill/components/ViewBillPaymentActivity/components/ViewBillPaymentActivityMemoInfo';
import {
  BlockTitleContainer,
  CardThemes,
} from 'src/pages/bill/components/ViewBillPaymentActivity/ViewBillPaymentActivity';
import { InstallmentsFundingType } from 'src/pages/bill/pay/components/PayBillPaymentActivity/components/InstallmentsFundingType';
import { RepaymentFundingSourceInfo } from 'src/pages/bill/pay/components/PayBillPaymentActivity/components/RepaymentFundingSourceInfo';
import { getFailedPaymentDate, isPaymentCanceled } from 'src/utils/payments';
import { AccountType, BillType, DeliveryMethodType, FinancePayment } from 'src/utils/types';
import { ViewBillPaymentActivityDeliveryMethodInfo } from '../ViewBillPaymentActivityDeliveryMethodInfo';

type Props = {
  payment: FinancePayment;
  bill: BillType;
  fundingSource: AccountType;
  deliveryMethod: DeliveryMethodType;
};

export const FailedFinancingPaymentActivity = ({ payment, bill, fundingSource, deliveryMethod }: Props) => {
  const paymentCanceled = isPaymentCanceled(payment);
  const failureDate = getFailedPaymentDate(payment);
  const { financing } = payment;

  return (
    <Box data-testid="failed-financing-payment-activity" mt={6}>
      <BlockTitleContainer>
        <MIFormattedText label="viewBillPaymentActivity.payFromTitle" />
      </BlockTitleContainer>
      <Flex direction="column" mb={6}>
        {paymentCanceled ? (
          <Tooltip
            data-testid="canceled-installments-tooltip"
            placement="bottom"
            label={<MIFormattedText label="viewBillPaymentActivity.fundingSource.installmentsCanceledTooltip" />}
            shouldWrapChildren
          >
            <InstallmentsFundingSourceInfo theme={CardThemes.Black} />
          </Tooltip>
        ) : (
          <>
            <InstallmentsFundingType isEditDisabled={false} installments={payment.financing.installments} />
            <InstallmentsDeductionDatesInfo
              financing={financing}
              theme={CardThemes.Black}
              showInstallmentsBadges={false}
              label="financing.installmentDeductsLabel"
            />
            <RepaymentFundingSourceInfo fundingSource={fundingSource} bill={bill} />
            <Box mt={6}>
              <BlockTitleContainer>
                <MIFormattedText label="viewBillPaymentActivity.receivedTitle" />
              </BlockTitleContainer>
            </Box>
            <ViewBillPaymentActivityDeliveryMethodInfo
              bill={bill}
              payment={payment}
              deliveryMethod={deliveryMethod}
              failureDate={failureDate}
            />
          </>
        )}
      </Flex>
      <ViewBillPaymentActivityMemoInfo payment={payment} />
    </Box>
  );
};
