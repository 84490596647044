import * as React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { Link } from 'src/core/ds/link';
import { InstallmentStatus } from 'src/utils/consts';
import { Installment } from 'src/utils/types';
import { TerminatedWarningIcon } from './TerminatedWarningIcon';

type BadgeProps = {
  label: string;
  variant: BadgeVariants;
  color: BadgeColors;
};

const getBadgePropsByPaymentStatus = (status?: InstallmentStatus): BadgeProps | null => {
  switch (status) {
    case InstallmentStatus.Completed:
      return {
        label: 'financing.installmentsList.badges.completed',
        variant: BadgeVariants.FILLED,
        color: BadgeColors.NEUTRAL,
      };
    case InstallmentStatus.Failed:
      return {
        label: 'financing.installmentsList.badges.failed',
        variant: BadgeVariants.FILLED,
        color: BadgeColors.DANGER,
      };
    case InstallmentStatus.Scheduled:
      return {
        label: 'financing.installmentsList.badges.scheduled',
        variant: BadgeVariants.OUTLINED,
        color: BadgeColors.INFORMATIVE,
      };
    case InstallmentStatus.Terminated:
      return {
        label: 'financing.installmentsList.badges.terminated',
        variant: BadgeVariants.FILLED,
        color: BadgeColors.WARNING,
      };
    case InstallmentStatus.Retry:
      return {
        label: 'financing.installmentsList.badges.due',
        variant: BadgeVariants.OUTLINED,
        color: BadgeColors.INFORMATIVE,
      };
    default:
      return null;
  }
};

type Props = {
  date: Installment['scheduledDate'];
  status?: Installment['status'];
  viewDetailsLink?: string;
  showBadges: boolean;
};

export const DateAndStatusCell = ({ date, status, viewDetailsLink, showBadges }: Props) => {
  const installmentStatus = showBadges && getBadgePropsByPaymentStatus(status);
  const isTerminatedInstallment = status === InstallmentStatus.Terminated;
  const isScheduledInstallment = status === InstallmentStatus.Scheduled;

  return (
    <Flex flexGrow={1} isTruncated>
      <Box>
        <MIFormattedDate date={date} />
      </Box>
      {installmentStatus ? (
        <>
          <Badge mx={2} data-testid={`installments-list-badge-${status}`} {...installmentStatus} />
          {isTerminatedInstallment ? <TerminatedWarningIcon /> : null}
        </>
      ) : null}
      {installmentStatus && viewDetailsLink && isScheduledInstallment ? (
        <Box data-testid="installments-list-view-more">
          <Link to={viewDetailsLink} as={RouteLink} data-testid="installments-list-view-more-link">
            <MIFormattedText label="financing.installmentsList.viewDetails" />
          </Link>
        </Box>
      ) : null}
    </Flex>
  );
};
