import compact from 'lodash/compact';
import { MethodListItemType } from 'src/components/common/SelectMethods/components/MethodsList';
import { METHOD_TYPE_TO_COMPONENT_TYPE } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { FinancingPreEligibilityDecision } from 'src/modules/financing/types';
import { INSTALLMENT_METHOD_DISABLED_COUNT } from 'src/pages/bill/pay/hooks/useFinancingFundingSourceDisabledViewsCount';
import { CardTypes, FundingType } from 'src/utils/consts';
import { sortFundingSourceSelectOptions, sortMethodsOfListItem } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

export type PaymentMethodType = CardTypes | FundingType.ACH | FundingType.INSTALLMENTS;

interface PaymentMethodsType {
  creditMethods?: AccountType[];
  debitMethods?: AccountType[];
  bankAccountMethods?: AccountType[];
}

interface PaymentMethodsDefinitions {
  isSettings?: boolean;
  isInternationalVendor?: boolean;
  isVendorAbsorbedFee?: boolean;
  isBillingFee?: boolean;
  onVerifyClicked?: MethodListItemType['onVerifyClicked'];
  showInstallments?: boolean;
  disabledInstallments?: boolean;
  totalAmount?: number;
  preEligibilityDecision?: FinancingPreEligibilityDecision;
  vendorId?: number;
  isInternationalMastercardEnabled?: boolean;
  showDisabledInstallmentsIfNotEligibleFF?: boolean;
  isInstallmentDisabled?: boolean;
  financingFundingSourceDisablediewsCount?: number;
}

interface PaymentMethodsItems {
  creditCardItem: MethodListItemType;
  debitCardItem: MethodListItemType;
  bankAccountItem: MethodListItemType;
  installmentsItem: MethodListItemType;
}

export const sortByInitialOrder = (
  { creditCardItem, debitCardItem, bankAccountItem, installmentsItem }: Partial<PaymentMethodsItems>,
  isInternationalVendor?: boolean
) =>
  isInternationalVendor
    ? [bankAccountItem, creditCardItem, debitCardItem, installmentsItem]
    : [creditCardItem, debitCardItem, bankAccountItem, installmentsItem];

export const sortPaymentMethods = ({
  paymentMethodsItems,
  firstFundingSourceId,
  shouldKeepInitialOrder = false,
  isFinancingFundingSourceFTX = false,
  financingFundingSourceFTXViewsCount = 0,
}: {
  paymentMethodsItems: MethodListItemType[];
  firstFundingSourceId?: number | null;
  shouldKeepInitialOrder?: boolean;
  isFinancingFundingSourceFTX?: boolean;
  financingFundingSourceFTXViewsCount?: number;
}) => {
  if (shouldKeepInitialOrder) return sortMethodsOfListItem(paymentMethodsItems, firstFundingSourceId);

  return sortFundingSourceSelectOptions({
    list: paymentMethodsItems,
    firstFundingSourceId,
    isFinancingFundingSourceFTX,
    financingFundingSourceFTXViewsCount,
  });
};

export const getCreditCardItem = (
  methods: AccountType[],
  {
    isSettings,
    isInternationalVendor,
    isVendorAbsorbedFee,
    isBillingFee,
    isInternationalMastercardEnabled = false,
  }: Omit<PaymentMethodsDefinitions, 'onVerifyClicked'>
): MethodListItemType => {
  const CreditComponent = METHOD_TYPE_TO_COMPONENT_TYPE[CardTypes.CREDIT];
  CreditComponent.showAddAnotherOption = isInternationalVendor ? isInternationalMastercardEnabled : true;

  return {
    componentType: CreditComponent,
    methods,
    onVerifyClicked: undefined,
    isSettings,
    isVendorAbsorbedFee,
    isBillingFee,
    isInternationalVendor,
  };
};

export const getDebitCardItem = (
  methods: AccountType[],
  {
    isSettings,
    isInternationalVendor,
    isBillingFee,
  }: Pick<PaymentMethodsDefinitions, 'isSettings' | 'isInternationalVendor' | 'isBillingFee'>
): MethodListItemType => {
  const DebitComponent = METHOD_TYPE_TO_COMPONENT_TYPE[CardTypes.DEBIT];
  DebitComponent.showAddAnotherOption = !isInternationalVendor;

  return {
    componentType: DebitComponent,
    methods,
    onVerifyClicked: undefined,
    isSettings,
    isInternationalVendor,
    isBillingFee,
  };
};

export const getBankAccountItem = (
  methods: AccountType[],
  {
    isSettings,
    isInternationalVendor,
    onVerifyClicked,
    isBillingFee,
  }: Omit<PaymentMethodsDefinitions, 'isVendorAbsorbedFee'>
): MethodListItemType => ({
  componentType: METHOD_TYPE_TO_COMPONENT_TYPE[FundingType.ACH],
  methods,
  onVerifyClicked,
  isSettings,
  isInternationalVendor,
  isBillingFee,
});

export const getInstallmentsItem = ({
  isInternationalVendor,
  preEligibilityDecision,
  totalAmount,
  vendorId,
  isInstallmentDisabled,
}: Pick<
  PaymentMethodsDefinitions,
  'isInternationalVendor' | 'totalAmount' | 'preEligibilityDecision' | 'vendorId' | 'isInstallmentDisabled'
>) => ({
  componentType: METHOD_TYPE_TO_COMPONENT_TYPE[FundingType.INSTALLMENTS],
  methods: [],
  preEligibilityDecision,
  isInternationalVendor,
  totalAmount,
  vendorId,
  isInstallmentDisabled,
});

export const getPaymentMethodsListItems = (
  paymentMethods: PaymentMethodsType,
  {
    isSettings,
    isInternationalVendor,
    isVendorAbsorbedFee,
    isBillingFee,
    onVerifyClicked,
    showInstallments,
    disabledInstallments,
    preEligibilityDecision,
    totalAmount,
    vendorId,
    isInternationalMastercardEnabled,
    showDisabledInstallmentsIfNotEligibleFF,
    financingFundingSourceDisablediewsCount,
  }: PaymentMethodsDefinitions
): MethodListItemType[] => {
  const { creditMethods, debitMethods, bankAccountMethods } = paymentMethods;

  const creditCardItem =
    creditMethods !== undefined
      ? getCreditCardItem(creditMethods, {
          isSettings,
          isInternationalVendor,
          isVendorAbsorbedFee,
          isBillingFee,
          isInternationalMastercardEnabled,
        })
      : undefined;

  const debitCardItem =
    debitMethods !== undefined
      ? getDebitCardItem(debitMethods, {
          isSettings,
          isInternationalVendor,
          isBillingFee,
        })
      : undefined;

  const bankAccountItem =
    bankAccountMethods !== undefined
      ? getBankAccountItem(bankAccountMethods, {
          isSettings,
          isInternationalVendor,
          onVerifyClicked,
          isBillingFee,
        })
      : undefined;

  const shouldShowDisabledInstallmentsItem = showDisabledInstallments({
    disabledInstallments,
    showDisabledInstallmentsIfNotEligibleFF,
    financingFundingSourceDisablediewsCount,
  });

  const installmentsItem =
    (showInstallments && totalAmount) || shouldShowDisabledInstallmentsItem
      ? getInstallmentsItem({
          isInternationalVendor,
          preEligibilityDecision,
          totalAmount,
          vendorId: vendorId!,
          isInstallmentDisabled: shouldShowDisabledInstallmentsItem,
        })
      : undefined;

  const listItems = sortByInitialOrder(
    {
      creditCardItem,
      debitCardItem,
      bankAccountItem,
      installmentsItem,
    },
    !!isInternationalVendor
  );

  return compact(listItems);
};

const showDisabledInstallments = ({
  disabledInstallments,
  showDisabledInstallmentsIfNotEligibleFF,
  financingFundingSourceDisablediewsCount,
}): boolean =>
  disabledInstallments &&
  showDisabledInstallmentsIfNotEligibleFF &&
  financingFundingSourceDisablediewsCount < INSTALLMENT_METHOD_DISABLED_COUNT;
