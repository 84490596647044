import isEmpty from 'lodash/isEmpty';
import { useModal } from 'src/helpers/react/useModal';
import { useBreak } from 'src/hoc';
import { ClientsPreview } from 'src/pages/team/team-management/ClientsPreview';
import { useAccountingFirmSyntheticRole } from 'src/pages/team/team-management/hooks/useAccountingFirmSyntheticRole';
import { TeamMemberCard } from 'src/pages/team/team-management/TeamMemberCard';
import { TeamMemberClientsAmount } from 'src/pages/team/team-management/TeamMemberClientsAmount';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { Role } from 'src/utils/consts';
import { AccessLevelLiteral } from 'src/utils/types';
import { getUserOrganization } from 'src/utils/user';
import { useGetUserActions } from '../../utils/user-actions';
import { UserWithClients } from '../types';
import { EditTeamMemberModal } from './modals/EditTeamMemberModal';
import { ManageClientsModal } from './modals/ManageClientsModal';
import { RemoveTeamMemberModal } from './modals/RemoveTeamMemberModal';

type Props = {
  user: UserWithClients;
  orgId: number;
  canManageClients: boolean;
};

export function TeamMemberContainer({ user, orgId, canManageClients }: Props) {
  const { isMobile, isPhablet } = useBreak();
  const isSmallScreen = isMobile || isPhablet;
  const userOrg = getUserOrganization(user, orgId);
  const isOwner = userOrg?.role === Role.OWNER;
  const syntheticRole = useAccountingFirmSyntheticRole(userOrg?.role);
  const userRole = isAdminRole(userOrg?.role) ? syntheticRole : '';
  const accessLevel: AccessLevelLiteral = userOrg?.accessLevel || 'none';
  const canAccessAccountingFirm = !!userOrg && accessLevel !== 'none';
  const clients = canAccessAccountingFirm ? user.clients : (user.clients || []).filter((client) => client.id !== orgId);

  const [EditTeamMember, showEditTeamMember] = useModal(EditTeamMemberModal, {
    userId: user.id,
    orgId,
    modalName: 'editUserModal',
  });
  const [DeleteUser, showDeleteUser] = useModal(RemoveTeamMemberModal, {
    userId: user.id,
    orgId,
    modalName: 'deleteUserModal',
    clients,
  });
  const [ManageClients, showManageClientsModal] = useModal(ManageClientsModal, {
    orgId,
    targetUserId: user.id,
    modalName: 'assignClientsModal',
  });

  const actions = useGetUserActions<Record<string, any>>({ orgId, user }, (actionType) => {
    if (canManageClients && actionType === 'manage-user-organizations') {
      return {
        label: 'team.actions.user.manageClients',
        action: showManageClientsModal,
      };
    }

    if (actionType === 'edit') {
      return {
        label: 'team.actions.user.edit',
        action: () => showEditTeamMember({ userId: user.id }),
      };
    }

    if (actionType === 'delete') {
      return {
        label: 'team.actions.user.remove',
        negative: true,
        action: showDeleteUser,
      };
    }

    return null;
  });

  return (
    <>
      {EditTeamMember}
      {DeleteUser}
      {ManageClients}
      <TeamMemberCard
        user={user}
        key={user.id}
        userRole={userRole}
        details={user.email}
        actions={isEmpty(actions) ? undefined : actions}
        rightSideRender={
          (canManageClients || canAccessAccountingFirm || user.clients.length > 1) &&
          !isSmallScreen && (
            <TeamMemberClientsAmount
              isFullAccess={isOwner}
              onClick={showManageClientsModal}
              thisFirm={canAccessAccountingFirm}
              amount={clients.length}
              hoverContent={clients?.length ? <ClientsPreview clients={clients} firmOrgId={orgId} /> : null}
            />
          )
        }
      />
    </>
  );
}
