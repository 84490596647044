import { featureFlags } from '@melio/shared-web';
import { ComponentProps, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { IconNames } from 'src/core/ds/icon';
import { useModal } from 'src/helpers/react/useModal';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { ActionPopover } from 'src/pages/regular-batch-payments/table/components/ActionPopover';
import { AddVendorEmailModal } from 'src/pages/regular-batch-payments/table/components/AddVendorEmailModal';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/consts';
import { BatchAnalyticsPropsType, trackExpandRow } from '../../analytics/BatchPaymentsAnalytics';
import { PAGE_EVENT } from '../../analytics/event-mapping';
import { MainRowType } from '../columns';

type VendorCellProps = {
  vendorName: string;
  vendorId?: number;
  row: MainRowType;
};

export const VendorCellWithoutEmail: React.FC<VendorCellProps> = ({ vendorName, vendorId, row }) => {
  const [isBulkPaymentEnabled] = featureFlags.useFeature(FeatureFlags.BulkPayment, false);
  const analyticsProps: BatchAnalyticsPropsType = {
    billIds: row.original.payment.bills.map((bill) => bill.id),
    vendorId,
    payBillFlowUUID: row.original.payBillFlowUUID,
  };
  const [addEmailModal, showAddEmailModal] = useModal(AddVendorEmailModal, {
    modalName: 'add-vendor-email-modal',
    vendorId,
    analyticsProps,
  });
  const { contactEmail } = useSelector(vendorsStore.selectors.byId(vendorId));

  const firstAnalyticsProps = useRef(analyticsProps);
  const onRender = useCallback(() => {
    analytics.track(PAGE_EVENT, 'add-email-for-bulk-reconciliation-view', firstAnalyticsProps.current);
  }, []);

  const onClickAddEmail = () => {
    analytics.track(PAGE_EVENT, 'add-email-for-bulk-reconciliation-clicked', analyticsProps);
    showAddEmailModal();
  };

  const actionPopoverProps: ComponentProps<typeof ActionPopover> = {
    label: 'regularBatchPayments.cells.vendor.missingEmailTooltip',
    iconName: IconNames.infoCircle,
    iconColor: 'blue.500',
    action: onClickAddEmail,
    actionLabel: 'regularBatchPayments.cells.vendor.addEmail',
    popoverTestId: 'missing-email-popover',
    iconTestId: 'regularBatchPayments-cells-vendor-email-icon',
  };

  const onClick = () => {
    const newState = !row.isExpanded;
    row.toggleRowExpanded(newState);
    trackExpandRow({
      groupingState: newState,
      billIds: row.original.payment.bills.map((bill) => bill.id),
      vendorId: row.original.vendor.id,
      payBillFlowUUID: row.original.payBillFlowUUID,
    });
  };

  return (
    <Flex ref={onRender} alignItems="center">
      {addEmailModal}
      <Box onClick={onClick} isTruncated data-testid="regularBatchPayments-cells-vendor">
        {vendorName}
      </Box>
      <Box ml={2}>{!contactEmail && isBulkPaymentEnabled ? <ActionPopover {...actionPopoverProps} /> : null}</Box>
    </Flex>
  );
};
