import { featureFlags } from '@melio/shared-web';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { ErrorType, FailedPaymentType } from 'src/modules/regular-batch-payments/types';
import { vendorsApi } from 'src/modules/vendors/api';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useBatchAnalytics } from 'src/pages/regular-batch-payments/analytics/BatchPaymentsAnalytics';
import { PAGE_EVENT as batchPaymentsPage } from 'src/pages/regular-batch-payments/analytics/event-mapping';
import { ALL_AT_ONCE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { MainTableRowType } from 'src/pages/regular-batch-payments/components/table/types';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { getCompanyInfo, getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryType, FeatureFlags } from 'src/utils/consts';
import { hasVendorCheckDeliveryMethod } from 'src/utils/delivery-methods';
import { capture } from 'src/utils/error-tracking';
import { getAmexCreditFundingSourcesIds, isCreditAmexNetwork } from 'src/utils/funding-sources';
import { PaymentType, VendorType } from 'src/utils/types';
import { shouldCollectAmexAddressForVendor } from 'src/utils/vendors';
import { useBatchSettingsChange } from './useBatchSettingsChange';

type CreateBatchPaymentDataType = {
  isCreating: boolean;
  error?: ErrorType;
  scheduledPayments: PaymentType[];
  scheduledPaymentsCount: number;
  scheduledPaymentsAmount: number;
  failedPayments: FailedPaymentType[];
  failedPaymentsCount: number;
};

type UseCreateBatchPaymentType = {
  createBatchPayment: () => void;
  createBatchPaymentAction: () => void;
  createBatchPaymentData: CreateBatchPaymentDataType;
  vendorsAddressesAreUpdating: boolean;
};

export const useCreateBatchPayments = (): UseCreateBatchPaymentType => {
  const history = useHistory();
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const { billsList } = useBatchBillsList();
  const { onApiCall: batchVendorsUpdate, loading: vendorsAddressesAreUpdating } = useApi({
    api: vendorsApi.updateVendors,
  });
  const { isBulkPaymentsToggleDisabled, bulkPaymentToggleOn } = useBatchSettingsChange();
  const paymentToCreatePayment = (item: MainTableRowType) => ({
    ...item.payment,
    billIds: item.payment.bills.map((bill) => bill.id),
    id: item.id,
    isInvoiceAttachmentRequired: item.isInvoiceAttachmentRequired,
    payBillFlowUUID: item.payBillFlowUUID,
  });
  const batchPaymentItemsList = billsList.map(paymentToCreatePayment);
  const batchPaymentItemsListIds = map(batchPaymentItemsList, 'id');
  const { trackScheduleBatchAction } = useBatchAnalytics(billsList);
  const isMultipleDates = useSelector(regularBatchPaymentsStore.selectors.isMultipleDeductionDate);
  const createBatchPaymentData = useSelector(
    regularBatchPaymentsStore.selectors.createBatchPayment.createBatch(batchPaymentItemsListIds)
  );
  const { dateOption, fundingSourceId } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const allBillsReady = useSelector(regularBatchPaymentsStore.selectors.allBillsReady);
  const fundingSources = useSelector(getFundingSources);
  const amexFundingSourcesIds = getAmexCreditFundingSourcesIds(fundingSources);
  const amexBills = billsList.filter((bill) => amexFundingSourcesIds.includes(bill.payment?.fundingSourceId));
  const selectedVendorsIds = amexBills.map((bill) => bill.vendor.id);
  const selectedAmexVendors = useSelector<any, VendorType[]>(vendorsStore.selectors.getByIds([...selectedVendorsIds]));
  const [isAmexBatchRestrictionsEnabled] = featureFlags.useFeature(FeatureFlags.BatchAmexMccPay, false);
  const [isAmexAddressFeatureEnabled] = featureFlags.useFeature(FeatureFlags.AmexVendorAddressBatchFlow, false, {
    shouldTrack: false,
  });
  const vendorWithoutMcc = selectedAmexVendors.filter((vendor) => isNil(vendor?.mccCode)).length > 0;
  const vendorWithoutAddress = selectedAmexVendors.some(
    (vendor) => shouldCollectAmexAddressForVendor(vendor) && !hasVendorCheckDeliveryMethod(vendor?.deliveryMethods)
  );
  const isMultipleFundingSource = useSelector(regularBatchPaymentsStore.selectors.isMultipleFundingSource);

  // TODO: remove after refactoring - remove the use effect
  const [created, setCreated] = useState(false);
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    const { scheduledPaymentsCount, failedPaymentsCount } = createBatchPaymentData;

    if (scheduledPaymentsCount) {
      if (!created) {
        setCreated(true);
        trackScheduleBatchAction();
        history.push(generatePath(batchPaymentsLocations.success, { orgId }));
      }
    } else if (isCompanyLegalInfoDone(companyInfo) && !scheduledPaymentsCount && failedPaymentsCount) {
      if (!failed) {
        setFailed(true);
        setCreated(false);
        history.push(generatePath(batchPaymentsLocations.base, { orgId }));
      }
    }
  }, [
    history,
    createBatchPaymentData.scheduledPaymentsCount,
    createBatchPaymentData.failedPaymentsCount,
    orgId,
    companyInfo,
    createBatchPaymentData,
    trackScheduleBatchAction,
  ]);

  const getBatchPaymentDateOption = () => {
    if (isMultipleDates) return 'multiple ';

    return dateOption === ALL_AT_ONCE_OPTION_ID ? 'all at once' : 'by due date';
  };

  const getUpdateVendorsAmexAddressesData = () => {
    if (!isAmexAddressFeatureEnabled) {
      return undefined;
    }

    const batchItemsWithAmexCreditCard = batchPaymentItemsList.filter(({ fundingSourceId }) => {
      if (!fundingSourceId) {
        return false;
      }

      const batchItemFundingSource = fundingSources.find((fundingSource) => fundingSource.id === fundingSourceId);

      return isCreditAmexNetwork(batchItemFundingSource);
    });

    const updateVendorsAmexAddressesData = batchItemsWithAmexCreditCard.reduce((acc, { vendor }) => {
      const checkDeliveryMethodAddress = vendor.deliveryMethods?.find(
        ({ deliveryType }) => deliveryType === DeliveryType.CHECK
      )?.paperCheck;

      if (shouldCollectAmexAddressForVendor(vendor) && checkDeliveryMethodAddress && !acc[vendor.id]) {
        return {
          ...acc,
          [vendor.id]: {
            id: vendor.id,
            address: {
              addressLine1: checkDeliveryMethodAddress.addressLine1,
              addressLine2: checkDeliveryMethodAddress.addressLine2,
              city: checkDeliveryMethodAddress.city,
              state: checkDeliveryMethodAddress.state,
              zipCode: checkDeliveryMethodAddress.zipCode,
              countryCode: checkDeliveryMethodAddress.countryCode,
            },
          },
        };
      }

      return acc;
    }, {});

    return isEmpty(updateVendorsAmexAddressesData) ? undefined : updateVendorsAmexAddressesData;
  };

  const createBatchPaymentAction = useCallback(async (): Promise<void> => {
    const analyticsProps = {
      billIds: flatMap(billsList, (item) => item.payment.bills.map((b) => b.id)),
      payBillFlowUUIDs: billsList.map((item) => item.payBillFlowUUID),
    };
    analytics.track(batchPaymentsPage, 'schedule-regular-batch-payments-request', {
      finalBulkToggleState: bulkPaymentToggleOn && !isBulkPaymentsToggleDisabled,
      bulkPaymentEligible: !isBulkPaymentsToggleDisabled,
      ...analyticsProps,
    });

    const updateVendorsAmexAddressesData = getUpdateVendorsAmexAddressesData();

    if (updateVendorsAmexAddressesData) {
      try {
        batchVendorsUpdate({
          orgId,
          updateVendorsData: updateVendorsAmexAddressesData,
        });
      } catch (err: any) {
        capture(err, {
          message: `Can not update vendors (AMEX) addresses using paper check address`,
        });
      }
    }

    try {
      await actions.createBatchPayment({
        orgId,
        payments: batchPaymentItemsList,
        vendorWithoutMcc: isAmexBatchRestrictionsEnabled ? vendorWithoutMcc : false,
        vendorWithoutAddress: isAmexAddressFeatureEnabled ? vendorWithoutAddress : false,
      });
      analytics.track(batchPaymentsPage, 'schedule-regular-batch-payments-success', {
        selected_payment_method: isMultipleFundingSource ? 'multiple funding source' : fundingSourceId,
        selected_deduction_date: getBatchPaymentDateOption(),
        total_payment_amount: sumBy(batchPaymentItemsList, 'amount'),
        num_of_payments: batchPaymentItemsList.length,
        ...analyticsProps,
      });
    } catch (error) {
      analytics.track(batchPaymentsPage, 'schedule-regular-batch-payments-failure', {
        payments: batchPaymentItemsList,
        paymentsCount: batchPaymentItemsList.length,
        error,
        ...analyticsProps,
      });
    }
  }, [actions, orgId, batchPaymentItemsList, isAmexBatchRestrictionsEnabled, vendorWithoutMcc]);

  const createBatchPayment = useCallback(async (): Promise<void> => {
    if (!allBillsReady || isCompanyLegalInfoDone(companyInfo)) {
      await createBatchPaymentAction();
    } else {
      history.push(generatePath(batchPaymentsLocations.batchLegal, { orgId }));
    }
  }, [companyInfo, orgId, createBatchPaymentAction, history, allBillsReady]);

  return {
    createBatchPayment,
    createBatchPaymentAction,
    createBatchPaymentData,
    vendorsAddressesAreUpdating,
  };
};
