import successAnimation from 'src/animations/accountingSoftware/sync-qbd/success.json';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';

type Props = {
  onNext: () => void;
};

const SyncQBDSuccessPage = ({ onNext }: Props) => (
  <StepLayoutPage
    title="settings.newAccountingSoftware.QuickbooksDesktopSync.success.title"
    subtitle="settings.newAccountingSoftware.QuickbooksDesktopSync.success.subtitle"
    onNext={onNext}
    fullWidthCTA
    animatedIllustration={{
      animationData: successAnimation,
    }}
  />
);

export default SyncQBDSuccessPage;
