import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { BillListEmptyState } from 'src/components/common/BillListEmptyState';
import { DesktopListHeader } from 'src/components/list/DesktopListHeader';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { PayListItemType } from 'src/modules/bills/types';
import { PayListPagination } from 'src/pages/pay/PayListPagination';
import { BillStatus } from 'src/utils/consts';
import { Filters, TabSettingsType } from 'src/utils/types';
import { Message as BillInboxMessage } from './components/product-announcements/bill-inbox/Message';
import { PayListItemsContainer } from './PayListItemsContainer';
import { PayListSelectionMenu } from './PayListSelectionMenu';

type Props = {
  tabs: TabSettingsType[];
  listItemBaseSearch?: string;
  pathname: string;
  isLoading: boolean;
  goCreateBill: (event: React.SyntheticEvent<HTMLInputElement>) => void | string | Promise<void>;
  onClearFilters: () => void;
  itemsCount: number;
  onZeroStateAction: () => void;
  enabledSelectionItems: readonly PayListItemType[];
  setMarked: (id: string, value: boolean) => void;
  markedIds: readonly string[];
  markAll: () => void;
  clearAll: () => void;
  isAllMarked: boolean;
  enableBatchSelection: boolean;
  items: PayListItemType[];
  filters: Filters;
  selectedTabBillStatus: BillStatus;
  isShowEmptyBillList: boolean;
  shouldShowBillInboxAnnouncement: boolean;
  onCloseBillInboxAnnouncement: () => void;
};

export const PayList = ({
  tabs,
  listItemBaseSearch = '',
  filters,
  markedIds,
  enableBatchSelection,
  items,
  isLoading,
  isAllMarked,
  itemsCount,
  goCreateBill,
  enabledSelectionItems,
  markAll,
  clearAll,
  setMarked,
  onZeroStateAction,
  pathname,
  selectedTabBillStatus,
  isShowEmptyBillList,
  onClearFilters,
  shouldShowBillInboxAnnouncement,
  onCloseBillInboxAnnouncement,
}: Props) => (
  <Flex flexDirection="column" alignItems="flex-start">
    <DesktopListHeader tabs={tabs} />
    <Box w="full" position="relative" marginTop="8">
      {isLoading ? (
        <AreaLoader placement="list" />
      ) : (
        <>
          {selectedTabBillStatus === BillStatus.UNPAID && items.length > 0 ? (
            <CustomFeed feedType="billsinbox_top" />
          ) : null}
          {enableBatchSelection && !isEmpty(markedIds) && (
            <PayListSelectionMenu
              enableBatchSelection={enableBatchSelection}
              markedIds={markedIds}
              isAllMarked={isAllMarked}
              markAll={markAll}
              clearAll={clearAll}
            />
          )}
          {isShowEmptyBillList && (
            <BillListEmptyState
              selectedTabBillStatus={selectedTabBillStatus}
              filters={filters}
              bills={items}
              billsCount={itemsCount}
              goCreateBill={goCreateBill}
              onClearFilters={onClearFilters}
              onZeroStateAction={onZeroStateAction}
            />
          )}
          {shouldShowBillInboxAnnouncement ? (
            <BillInboxMessage onCloseBillInboxAnnouncement={onCloseBillInboxAnnouncement} />
          ) : null}
          <Flex flexDirection="column" mb={8}>
            <PayListItemsContainer
              items={items}
              markedIds={markedIds}
              setMarked={setMarked}
              listItemBaseSearch={listItemBaseSearch}
              enableBatchSelection={enableBatchSelection}
              enabledSelectionItems={enabledSelectionItems}
            />
          </Flex>
          <PayListPagination
            itemsCount={itemsCount}
            selectedTabBillStatus={selectedTabBillStatus}
            pathname={pathname}
            filters={filters}
          />
        </>
      )}
    </Box>
  </Flex>
);
