export const name = 'organizations';

export const clientsAmountOptions = [
  {
    id: '1-20',
    label: 'onboarding.companyInfo.clientsAmount.values.first',
  },
  {
    id: '21-50',
    label: 'onboarding.companyInfo.clientsAmount.values.second',
  },
  {
    id: '51-100',
    label: 'onboarding.companyInfo.clientsAmount.values.third',
  },
  {
    id: '100+',
    label: 'onboarding.companyInfo.clientsAmount.values.fourth',
  },
];
