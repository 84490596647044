import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import * as WizardElements from 'src/components/layout/WizardElements';
import RegistrationTermsAndConditions from 'src/components/onboarding/RegistrationTermsAndConditionsOld';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Image } from 'src/core/ds/image';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { Logo } from 'src/images/Logo';
import { ButtonVariant, LogoFillType } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { devices } from '../../theme/appDevices';

type Props = {
  title: string;
  text?: string;
  textValues?: Record<string, any>;
  buttonLabel?: string;
  buttonAction?: () => any;
  buttonValues?: Record<string, any>;
  isButtonDisabled?: boolean;
  linkLabel?: string;
  linkAction?: () => void | string;
  linkHint?: string;
  secondaryLinkLabel?: string;
  secondaryLinkAction?: () => void | string;
  isLoading?: boolean;
  children: React.ReactNode;
  footer?: React.ReactNode;
  testId?: string | null;
  secondButtonLabel?: string;
  secondButtonAction?: () => void;
  secondButtonVariant?: ButtonVariant;
  secondButtonLogo?: string;
  hideLogo?: boolean;
  iconSrc?: string;
};

export const RegisterLayoutPage = ({
  title,
  text,
  textValues = {},
  buttonLabel,
  buttonAction,
  buttonValues,
  isButtonDisabled,
  linkLabel,
  linkAction,
  linkHint,
  isLoading,
  children,
  secondaryLinkLabel,
  secondaryLinkAction,
  footer,
  testId,
  secondButtonLabel,
  secondButtonAction,
  secondButtonVariant,
  secondButtonLogo,
  hideLogo,
  iconSrc,
}: Props) => {
  const { isDesktop, isTablet } = useBreak();
  // perform Next or Submit action on "Enter" key
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (buttonAction && isEnterPressed(event)) {
      buttonAction();
    }
  };
  const logoSize = isDesktop || isTablet ? 'regular' : 'mobile';
  const { EmbeddedHeader } = useSiteContext().components;

  return (
    <Container onKeyDown={onKeyPressed} data-testid={testId}>
      <EmbeddedHeader />
      <StyledWizardInner isCenterVert>
        {!hideLogo && (
          <WizardElements.LogoContainer>
            <Logo fill={LogoFillType.LIGHT} size={logoSize} isFull />
          </WizardElements.LogoContainer>
        )}

        {!!iconSrc && <Image w="14rem" h="14rem" mb={3} src={iconSrc} data-testid="session-expire-image" />}

        <WizardTitle>
          <Box mb={text ? 0 : 8} maxW="45rem">
            <MIFormattedText label={title} />
          </Box>
        </WizardTitle>

        {text && (
          <WizardText>
            <MIFormattedText label={text} values={textValues} />
          </WizardText>
        )}

        <Box maxW="45rem" w="full">
          {children}
        </Box>

        <WizardElements.WizardActionsContainer>
          {buttonLabel && buttonAction && (
            <WizardElements.WizardButtonContainer>
              <Button
                size={ButtonSizes.lg}
                label={buttonLabel}
                values={buttonValues}
                onClick={buttonAction}
                isLoading={isLoading}
                isDisabled={isButtonDisabled}
              />
            </WizardElements.WizardButtonContainer>
          )}

          {secondButtonAction && (
            <>
              <WizardElements.WizardOrLine />
              <WizardElements.WizardButtonContainer>
                <Button
                  label={secondButtonLabel || ''}
                  variant={secondButtonVariant ? ButtonVariants[secondButtonVariant] : ButtonVariants.primary}
                  onClick={secondButtonAction}
                  textTransform="uppercase"
                  isFullWidth
                >
                  {secondButtonLogo}
                </Button>
              </WizardElements.WizardButtonContainer>
              <RegistrationTermsAndConditions label="auth.regularRegister.termsAndConditions" />
            </>
          )}

          {linkLabel && linkAction && linkHint && (
            <WizardElements.WizardRegisterLinkContainer>
              <WizardElements.WizardLinkHintContainer>
                <MIFormattedText label={linkHint} />
              </WizardElements.WizardLinkHintContainer>
              <MILink to={linkAction} label={linkLabel} />
            </WizardElements.WizardRegisterLinkContainer>
          )}

          {secondaryLinkLabel && secondaryLinkAction && (
            <WizardElements.WizardLinkContainer>
              <MILink to={secondaryLinkAction} label={secondaryLinkLabel} />
            </WizardElements.WizardLinkContainer>
          )}

          {footer}
        </WizardElements.WizardActionsContainer>
      </StyledWizardInner>
    </Container>
  );
};

const StyledWizardInner = styled(WizardElements.WizardInner)`
  height: 715px;
  flex-grow: 0;
  justify-content: start;
  @media ${devices.mobile}, ${devices.phablet} {
    height: auto;
    flex-grow: 1;
    justify-content: center;
  }
`;

const WizardTitle = styled(WizardElements.WizardTitle)`
  margin-bottom: 0.8rem;
  font-size: ${(props) => props.theme.text.size.title};
`;
const WizardText = styled(WizardElements.WizardText)`
  margin-bottom: 3.2rem;
`;

const Container = styled(WizardElements.WizardContainer)`
  scroll-behavior: auto;
  overflow: auto;
`;
