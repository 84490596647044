import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import maxBy from 'lodash/maxBy';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import {
  DebitFeeNotificationModal,
  PaymentFlow,
} from 'src/components/debit-fee-notification-modal/DebitFeeNotificationModal';
import { useAmexIndustry } from 'src/components/funding-source/amex/hooks/useAmexIndustry';
import { useAmexVendorAddress } from 'src/components/funding-source/amex/hooks/useAmexVendorAddress';
import { AmexIndustryModal } from 'src/components/funding-source/amex/modals/AmexIndustryModal';
import { AmexVendorAddressModal } from 'src/components/funding-source/amex/modals/AmexVendorAddressModal';
import { ErrorPage } from 'src/components/layout/ErrorLayoutPage';
import { ScrollToTop } from 'src/components/layout/ScrollToTop';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useOrgMicroDepositEligible } from 'src/hooks/useOrgMicroDepositEligible';
import noPaymentCreated from 'src/images/qbo/no-payment-created-icon.png';
import { getErrorStoreActions } from 'src/modules/error/error-store';
import { financingStore } from 'src/modules/financing/financing-store';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { VendorPaymentPreferencesType } from 'src/modules/vendors/check-vendor-payment-preferences';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { billLocations } from 'src/pages/bill/locations';
import { editFundingSourcePageLoaded, fundingSourcePageLoaded } from 'src/pages/bill/pay/analytics/utils';
import PayFundingSourcePage from 'src/pages/bill/pay/components/PayFundingSourcePage';
import { PayBillProps, withPayBillData } from 'src/pages/bill/pay/hoc/withPayBillData';
import { useDebitFeeNotification } from 'src/pages/bill/pay/hooks/useDebitFeeNotification';
import { useFinancingFundingSourceDisabledViewsCount } from 'src/pages/bill/pay/hooks/useFinancingFundingSourceDisabledViewsCount';
import { useGetFinancingPreEligibility } from 'src/pages/bill/pay/hooks/useGetFinancingPreEligibility';
import { usePayBillFinancingData } from 'src/pages/bill/pay/hooks/usePayBillFinancingData';
import { useShouldShowInstallmentsFundingSource } from 'src/pages/bill/pay/hooks/useShouldShowInstallmentsFundingSource';
import {
  financingAnalyticsEvents,
  financingPage,
  getFinancingExperience,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { useInstallmentsOptionsRequest } from 'src/pages/bill/pay/installments/pages/installments-options/useInstallmentsOptionsRequest';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import {
  getBill,
  getErrorCode,
  getIsLoading,
  getIsRecurring,
  getPayment,
  getSelectedFundingSource,
} from 'src/redux/payBillWizard/selectors';
import { getFundingSources, getOrgId, getOriginPlaidItemId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillBalance, getBillPaymentIndex } from 'src/utils/bills';
import { CardTypes, DeliveryType, FeatureFlags, PaymentApprovalStatus, PaymentStatus } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { capture } from 'src/utils/error-tracking';
import {
  canVerifyManualBankAccount,
  getFundingSourceNetwork,
  getFundingSourceType,
  isCreditAmexNetwork,
  isManualBankAccountNotVerified,
} from 'src/utils/funding-sources';
import intuit from 'src/utils/intuit';
import { getLatestPayment } from 'src/utils/payments';
import { AccountType, VendorType } from 'src/utils/types';
import { getVerifiedACHFundingSources, isVerifiedACHFundingSource } from '../utils';
import { getPaymentRequestId } from '../utils/billGetters';
import { useFinancingFundingSourceViewCount } from './hooks/useFinancingFundingSourceViewCount';

type Props = PayBillProps;

const eventPage = 'pay-bill';

const selectVendorPaymentPreferences = (vendorId: number | undefined | null) => (state): VendorPaymentPreferencesType =>
  vendorsStore.selectors.checkVendorPaymentPreferences.item(state, vendorId);

const shouldShowMicroDepositDialog = (isOrgMicroDepositEligible: boolean, fundingSource?: AccountType) => {
  const canBeVerified = canVerifyManualBankAccount(fundingSource);
  const isMicroDepositWasSent = canBeVerified && !fundingSource?.bankAccount?.depositsNotSent;

  return isOrgMicroDepositEligible ? isMicroDepositWasSent : isManualBankAccountNotVerified(fundingSource);
};

const PlainPayBillFundingSourcePageContainer = ({
  goExit,
  setPaymentAmount,
  onNextFundingSources,
  onPrevFundingSources,
  goAddSelectedFundingSource,
}: Props) => {
  const [isMicroDepositOptimizationsEnabled] = featureFlags.useFeature(FeatureFlags.MicroDepositOptimizations, false);
  const { isOrgMicroDepositEligible } = useOrgMicroDepositEligible();

  const site = useSiteContext();
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const payment = useSelector(getPayment);
  const { id: paymentId, fundingSource: paymentFundingSource, payBillFlowUUID } = payment;
  const dispatch = useDispatch();
  const { navigate } = useNavigator();
  const errorCode = useSelector(getErrorCode);
  const isLoading = useSelector(getIsLoading);
  const isRecurring = useSelector(getIsRecurring);
  const fundingSources = useSelector(getFundingSources);
  const originPlaidItemId = useSelector(getOriginPlaidItemId);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const vendorPaymentPreferences = useSelector(selectVendorPaymentPreferences(bill?.vendorId));
  const [fundingSource, setFundingSource] = useState<AccountType | undefined>(selectedFundingSource);
  const [isDebitFeeNotificationOpen, setIsDebitFeeNotificationOpen] = useState(false);
  const [isUpdatingAmexAddress, setIsUpdatingAmexAddress] = useState(false);
  const { installmentsOptions } = useInstallmentsOptionsRequest();
  const [verifyingId, setVerifyingId] = useState<number | null>(null);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState<string>('');
  const [paymentAmountToSubmit, setPaymentAmountToSubmit] = useState<string>('');
  const vendorActions = useStoreActions(vendorsStore);

  const [isFinancingEnabled] = featureFlags.useFeature(FeatureFlags.MelioComAPFinancingEnabled, false);
  const vendorId = bill?.vendorId;

  const { vendor, isVendorLoading } = useFetchVendor(vendorId);
  const { getFinancingPreEligibility, preEligibilityResult, isPreEligibilityLoading } = useGetFinancingPreEligibility();

  const isFundingSourcePageLoading = isLoading || isVendorLoading || (isFinancingEnabled && isPreEligibilityLoading);

  const { showInstallments, disabledInstallments } = useShouldShowInstallmentsFundingSource({
    preEligibilityDecision: preEligibilityResult?.decision,
    bill,
  });

  const {
    preEligibilityData,
    numberOfInstallments,
    setPreEligibilityData,
    isInstallmentSelected,
    clearInstallments,
    analyticsProperties,
  } = usePayBillFinancingData();

  setPreEligibilityData(preEligibilityResult === null ? undefined : preEligibilityResult);

  useLayoutEffect(() => {
    if (isFinancingEnabled && vendorId) {
      getFinancingPreEligibility(orgId, vendorId);
    }
  }, [orgId, vendorId, isFinancingEnabled, getFinancingPreEligibility]);

  useEffect(() => {
    if (paymentId && bill?.id) {
      editFundingSourcePageLoaded({
        paymentId,
        billId: +bill.id,
        fundingSourceId: paymentFundingSource.id,
        payBillFlowUUID,
      });
    } else {
      fundingSourcePageLoaded({
        financingPreEligibility: preEligibilityResult?.decision,
        isFinancingEnabled,
        payBillFlowUUID,
      });
    }
  }, [
    paymentId,
    paymentFundingSource?.id,
    payBillFlowUUID,
    bill.id,
    preEligibilityResult?.decision,
    isFinancingEnabled,
  ]);

  const financingStoreActions = useStoreActions(financingStore);

  const { shouldDisplayDebitFeeNotification } = useDebitFeeNotification();
  const billBalance = getBillBalance(bill, [payment?.id]);
  const { increaseFinancingFundingSourceFTXViewsCount } = useFinancingFundingSourceViewCount();
  const { increaseFinancingFundingSourceDisabledViewsCount } = useFinancingFundingSourceDisabledViewsCount();
  const installmentViewCounterUpdated = useRef(false);

  useEffect(() => {
    if (payment?.amount) {
      const paymentAmount = payment?.amount?.toString();
      setPartialPaymentAmount(paymentAmount);
      setPaymentAmountToSubmit(paymentAmount);
    }
  }, [payment?.amount]);

  useEffect(() => {
    if (showInstallments) {
      setFundingSource(undefined);
    }
  }, [showInstallments]);

  useEffect(() => {
    if (showInstallments && preEligibilityData?.decision && !installmentViewCounterUpdated.current) {
      increaseFinancingFundingSourceFTXViewsCount();
      installmentViewCounterUpdated.current = true;
    }

    return () => {
      if (disabledInstallments && !!preEligibilityData && !installmentViewCounterUpdated.current) {
        increaseFinancingFundingSourceDisabledViewsCount();
        installmentViewCounterUpdated.current = true;
      }
    };
  }, [
    showInstallments,
    disabledInstallments,
    preEligibilityData,
    installmentViewCounterUpdated,
    increaseFinancingFundingSourceFTXViewsCount,
    increaseFinancingFundingSourceDisabledViewsCount,
  ]);

  useEffect(() => {
    if (isInstallmentSelected) {
      setFundingSource(undefined);
    } else {
      setFundingSource(selectedFundingSource);
    }
  }, [selectedFundingSource, isInstallmentSelected]);

  const onFundingSourceChange = (newFundingSource: AccountType) => {
    analytics.track(eventPage, 'change-funding-source', {
      fundingSourceId: newFundingSource.id,
      partialBillId: getBillPaymentIndex(bill),
    });
    clearInstallments();
    setFundingSource(newFundingSource);
  };

  const { shouldShowAmexIndustryModal, industriesList, pendingResources } = useAmexIndustry(
    bill?.vendorId,
    fundingSource?.id
  );

  const { amexAddressExperimentVariant, vendorHasAddress } = useAmexVendorAddress({
    selectedFundingSourceId: fundingSource?.id,
    vendor,
    featureFlagName: FeatureFlags.AmexVendorAddress,
  });

  const analyticsProps = {
    billId: bill.isPaymentRequest ? null : bill.id,
    paymentRequestId: bill.isPaymentRequest ? getPaymentRequestId(bill) : null,
    vendorId: bill.vendor?.id,
    partialBillId: getBillPaymentIndex(bill),
    isBatch: false,
  };

  useEffect(() => {
    if (errorCode !== null) {
      getErrorStoreActions(dispatch).globalErrorOccurred('', '');
    }
  }, [errorCode, dispatch]);

  const onVerifyFinished = () => {
    analytics.track(eventPage, 'verify-finish', {
      partialBillId: getBillPaymentIndex(bill),
    });

    setVerifyingId(null);
  };

  const onVerifyClicked = (id: number) => {
    analytics.track(eventPage, 'verify-click', {
      partialBillId: getBillPaymentIndex(bill),
    });

    setVerifyingId(id);
  };

  const submitFundingSource = () => {
    fundingSource && dispatch(selectFundingSourceAction(fundingSource.id));
    onNextFundingSources(fundingSource);
  };

  const handleCloseDebitFeeNotificationModal = useCallback(() => {
    setIsDebitFeeNotificationOpen(false);
  }, []);

  const lastPayment = getLatestPayment(bill?.payments);
  const originFundingSourceId = lastPayment?.fundingSourceId;
  const showWarning =
    lastPayment?.status === PaymentStatus.FAILED &&
    lastPayment?.approvalDecisionStatus !== PaymentApprovalStatus.DECLINED &&
    originFundingSourceId === payment?.fundingSourceId &&
    (!originPlaidItemId || originPlaidItemId === payment?.fundingSource?.plaidAccount?.plaidItemId);

  const [amexIndustryModal, showAmexModal] = useModal(AmexIndustryModal, {
    id: 'AmexIndustryModal',
    orgId,
    vendorId: bill?.vendorId,
    modalName: 'AmexIndustryModal',
    vendorName: bill.vendor?.companyName,
    industries: industriesList,
    analyticsProps,
    isDataLoading: pendingResources,
    onConfirm: async () => {
      if (amexAddressExperimentVariant) {
        await handleAmexAddress();

        return;
      }

      submitFundingSource();
    },
  });

  const [amexVendorAddressModal, showAmexVendorAddressModal] = useModal(AmexVendorAddressModal, {
    id: 'AmexVendorAddressModal',
    orgId,
    vendor: bill?.vendor,
    modalName: 'AmexVendorAddressModal',
    vendorName: bill.vendor?.companyName,
    analyticsProps: {
      vendorId: analyticsProps.vendorId,
      billId: analyticsProps.billId,
      isBatch: false,
    },
    onConfirm: async () => {
      submitFundingSource();
    },
  });

  const handleShowAmexIndustryModal = () => {
    analytics.trackAction('amex-industry-open', analyticsProps);
    showAmexModal();
  };

  const handleAmexAddress = async () => {
    const checkDeliveryMethodAddress = bill?.vendor?.deliveryMethods?.find(
      ({ deliveryType }) => deliveryType === DeliveryType.CHECK
    )?.paperCheck;

    if (checkDeliveryMethodAddress) {
      try {
        setIsUpdatingAmexAddress(true);
        await vendorActions.update({
          orgId,
          id: vendorId,
          address: {
            addressLine1: checkDeliveryMethodAddress.addressLine1,
            addressLine2: checkDeliveryMethodAddress.addressLine2,
            city: checkDeliveryMethodAddress.city,
            state: checkDeliveryMethodAddress.state,
            zipCode: checkDeliveryMethodAddress.zipCode,
            countryCode: checkDeliveryMethodAddress.countryCode,
          },
        });
        setIsUpdatingAmexAddress(false);
        submitFundingSource();

        return;
      } catch (err: any) {
        capture(err, {
          message: `Can not update vendor address with address from check DM`,
        });
      }
    }

    setIsUpdatingAmexAddress(false);

    analytics.trackAction('amex-vendor-address-open', {
      vendorId: analyticsProps.vendorId,
      billId: analyticsProps.billId,
      isBatch: false,
    });
    showAmexVendorAddressModal();
  };

  const handleFinancingNonVerifiedACHFundingSource = () => {
    const verifiedFundingSources = getVerifiedACHFundingSources([...fundingSources]);
    const fundingSource = maxBy(verifiedFundingSources, (fs) => fs.createdAt);

    if (fundingSource?.id) {
      dispatch(selectFundingSourceAction(fundingSource.id));
    }
  };

  const handleFinancing = async (paymentAmountToSubmit) => {
    if (!isVerifiedACHFundingSource(selectedFundingSource)) {
      handleFinancingNonVerifiedACHFundingSource();
    }

    await financingStoreActions.intentIdWithInstallmentOption.set({
      intentId: installmentsOptions.intentId,
      chosenInstallmentOption: { numberOfInstallments },
    });

    analytics.track(financingPage.addFunding, financingAnalyticsEvents.financingContinue, {
      isFinancing: true,
      financingExperience: preEligibilityData ? getFinancingExperience(preEligibilityData.decision) : null,
      billAmount: paymentAmountToSubmit,
      numberOfInstallments,
      ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
      ...analyticsProperties,
    });

    const url = generatePath(billLocations.pay.installments.scheduleFlow, { orgId, billId: bill.id });
    navigate(url);
  };

  const handleConfirm = async () => {
    if (isDebitFeeNotificationOpen) setIsDebitFeeNotificationOpen(false);

    if (isFinancingEnabled && isInstallmentSelected) {
      await handleFinancing(paymentAmountToSubmit);

      return;
    }

    const selectedFundingSource = fundingSources.find((fs) => fs.id === fundingSource?.id);
    const isAmexCard = isCreditAmexNetwork(selectedFundingSource);

    if (!isAmexCard) {
      submitFundingSource();

      return;
    }

    analytics.trackAction('amex-vendor-address-experiment', {
      vendorId: analyticsProps.vendorId,
      billId: analyticsProps.billId,
      vendorHasMcc: !shouldShowAmexIndustryModal,
      vendorHasAddress,
      experimentVariant: amexAddressExperimentVariant,
      isBatch: false,
    });

    if (shouldShowAmexIndustryModal) {
      handleShowAmexIndustryModal();
    } else if (amexAddressExperimentVariant) {
      await handleAmexAddress();
    } else {
      submitFundingSource();
    }
  };

  const onSetPaymentAmount = () => {
    const amount = Number(convertCurrencyToNumber(partialPaymentAmount));
    const paymentAmount = amount > billBalance || !amount ? payment?.amount : amount;
    setPaymentAmount(paymentAmount);
  };

  const onChangePartialPaymentAmount = ({ value }) => setPartialPaymentAmount(value);

  const handleBlur = (value: string) => {
    setPaymentAmountToSubmit(value);
  };

  const onSubmit = (submitWithoutVerifyingMicroDeposit?: boolean) => {
    onSetPaymentAmount();
    handleNext(submitWithoutVerifyingMicroDeposit);
  };

  const isDebitCardSelected = useMemo(() => fundingSource?.cardAccount?.cardType === CardTypes.DEBIT, [fundingSource]);

  const handleNext = async (submitWithoutVerifyingMicroDeposit?: boolean) => {
    const shouldShowMicroDepositDialogResult = shouldShowMicroDepositDialog(isOrgMicroDepositEligible, fundingSource);
    const shouldOpenDebitFeeNotification = isDebitCardSelected && shouldDisplayDebitFeeNotification;

    analytics.track(eventPage, 'confirm-funding-source-action', {
      shouldOpenDebitFeeNotification,
      fundingSourceId: fundingSource?.id,
      fundingSourceType: getFundingSourceType(fundingSource),
      network: getFundingSourceNetwork(fundingSource),
      isMDModalOpened: shouldShowMicroDepositDialogResult,
      isOrgMicroDepositEligible,
      submitWithoutVerifyingMicroDeposit,
      isMicroDepositOptimizationsEnabled,
      paymentId: payment?.id,
      billId: bill?.id,
      vendorId: bill?.vendorId,
      failedOnFSid: verifyingId,
      ...(payment.payBillFlowUUID ? { payBillFlowUUID: payment.payBillFlowUUID } : {}),
    });

    if (shouldOpenDebitFeeNotification) {
      setIsDebitFeeNotificationOpen(true);
    } else if (
      isMicroDepositOptimizationsEnabled &&
      !submitWithoutVerifyingMicroDeposit &&
      shouldShowMicroDepositDialogResult
    ) {
      if (fundingSource) {
        setVerifyingId(fundingSource.id);
      }
    } else {
      await handleConfirm();
    }
  };

  if (isFundingSourcePageLoading) {
    return <AreaLoader placement="wizard" />;
  }

  if (isRppsVendor(bill.vendor as VendorType) && !site.scheduleDirectPayment) {
    analytics.track(eventPage, 'block-schedule-payment-to-biller-directory-vendor', {
      partialBillId: getBillPaymentIndex(bill),
    });

    return (
      <ErrorPage
        illustration={noPaymentCreated}
        title="bills.view.error.notSupportedVendorTitle"
        subtitle="bills.view.error.notSupportedVendorSubTitle"
        buttonAction={goExit}
        buttonLabel="bills.view.error.notSupportedVendorSubButton"
      />
    );
  }

  intuit.endLoadingWrapper();

  return (
    <>
      {amexIndustryModal}
      {amexVendorAddressModal}
      <DebitFeeNotificationModal
        isOpen={isDebitFeeNotificationOpen}
        onClose={handleCloseDebitFeeNotificationModal}
        onConfirm={handleConfirm}
        paymentFlowType={PaymentFlow.Single}
      />
      <ScrollToTop>
        <PayFundingSourcePage
          onPrev={isRecurring ? onPrevFundingSources : null}
          isLoading={isUpdatingAmexAddress}
          goExit={goExit}
          fundingSources={fundingSources}
          fundingSource={fundingSource}
          onFundingSourceChange={onFundingSourceChange}
          goAddSelectedFundingSource={goAddSelectedFundingSource}
          setPaymentAmount={setPaymentAmount}
          vendor={bill.vendor}
          bill={bill}
          isRecurring={isRecurring}
          onVerifyClicked={onVerifyClicked}
          onVerifyFinished={onVerifyFinished}
          verifyingId={verifyingId}
          showWarning={showWarning}
          isVendorEnableCCPayments={isEmpty(vendorPaymentPreferences)}
          onSetPaymentAmount={onSetPaymentAmount}
          partialPaymentAmount={partialPaymentAmount}
          onChangePartialPaymentAmount={onChangePartialPaymentAmount}
          onBlur={handleBlur}
          paymentAmountToSubmit={paymentAmountToSubmit}
          onSubmit={onSubmit}
          showInstallments={showInstallments}
          disabledInstallments={disabledInstallments}
        />
      </ScrollToTop>
    </>
  );
};

export const PayBillFundingSourcePageContainer = withPayBillData()(PlainPayBillFundingSourcePageContainer);
