import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';

export const VendorCell: React.FC<CellProps<any>> = ({ cell: { value } }) =>
  useMemo(
    () => (
      <Box textStyle="body3">
        <Box>{value.name}</Box>
        {value.category && <Box textStyle="body4">{value.category}</Box>}
      </Box>
    ),
    [value]
  );
