import { forwardRef } from 'react';
import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withHook = <P extends unknown[]>(...params: P) => <R extends Record<string, any>>(
  useHook: (...params: P) => R
) => <Props extends R>(Component: React.FC<Props>) => {
  const WrappedComponent = forwardRef((props: Props, ref) => {
    const hookProps = useHook(...params);

    return <Component {...props} {...hookProps} ref={ref} />;
  });

  WrappedComponent.displayName = `withHook(${useHook.name})(${Component.displayName || Component.name})`;

  return WrappedComponent;
};
