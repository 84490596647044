import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { put } from 'redux-saga/effects';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { authApi } from 'src/modules/auth/api';
import { joinOrganizationRequestsApi } from 'src/modules/join-organization-requests/api';
import {
  organizationPreferencesApi,
  organizationsApi,
  UpdateOrganizationPreferenceParamsType,
  UpdateServiceFeesPreferenceParamsType,
} from 'src/modules/organizations/api';
import { name as organizationStoreName } from 'src/modules/organizations/constants';
import { CLEAR_STATE, SET_ORGANIZATION_PREFERENCES } from 'src/redux/user/actionTypes';
import { OrgPreferencesTypeKeys } from 'src/utils/consts';
import { OrganizationPreferencesType } from 'src/utils/types';

type UnlinkQuickbookState = {
  byId: { [key: string]: any };
  unlinkQuickbooks: { [key: string]: any };
};

const unlinkQuickbookSlice = createApiCallSlice<any, UnlinkQuickbookState>({
  api: authApi.unlinkQuickbooksAccount,
  name: generateSliceName(organizationStoreName, 'unlink quickbooks'),
  initialState: {
    byId: {},
    unlinkQuickbooks: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.unlinkQuickbooks[action.payload] = {
        isUnlinking: true,
        error: null,
      };
    },
    [ON_FAILURE]: (state, action) => {
      state.unlinkQuickbooks[action.meta.identifier] = {
        isUnlinking: false,
        error: action.error,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      const orgId = action.meta.identifier;
      state.byId[orgId].isIntuitLinked = false;
      state.unlinkQuickbooks[action.meta.identifier] = {
        isUnlinking: false,
        error: null,
      };
    },
  },
  selectors: {
    getUnlinkQuickbooksStatus: (orgId) => (state) => state[organizationStoreName].unlinkQuickbooks[orgId],
  },
});

const updateNewCompanySlice = createSlice({
  name: generateSliceName(organizationStoreName, 'set_new_company_info'),
  initialState: {
    create: {
      company: {},
    },
  },
  reducers: {
    update(state, { payload }) {
      state.create.company = payload;
    },
  },
});

const createNotOwnedOrganizationSlice = createApiCallSlice({
  name: generateSliceName(organizationStoreName, 'create not owned'),
  api: joinOrganizationRequestsApi.create,
  initialState: {
    byId: {},
    create: {},
  },
  reducers: {
    [ON_REQUEST](state) {
      const key = 'default';
      state.create[key] = { loading: true };
    },
    [ON_SUCCESS]: (state, action) => {
      const { organization } = action.payload;
      const key = 'default';
      state.byId[organization.id] = organization;
      state.create[key] = {
        loading: false,
        done: true,
        resultId: organization.id,
      };
    },
    [ON_FAILURE](state, action) {
      const key = 'default';
      state.create[key] = { loading: false, error: action.error };
    },
  },
});

const switchAccountingFirmSlice = createApiCallSlice<any, any>({
  name: generateSliceName(organizationStoreName, 'switch_accounting_firm'),
  api: organizationsApi.switchAccountingFirm,
  initialState: {
    switchAccountingFirm: {},
    newAccountingFirm: {},
    byId: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      state.switchAccountingFirm[action.payload.orgId] = { loading: true };
    },
    [ON_SUCCESS]: (state, action) => {
      delete state.switchAccountingFirm[action.meta.identifier.orgId];
      state.byId[action.payload.newAccountingFirm.id] = merge(
        state.byId[action.payload.newAccountingFirm.id] || {},
        action.payload.newAccountingFirm
      );
      (action.payload.previousAccountingFirms || []).forEach((org) => {
        state.byId[org.id] = merge(state.byId[org.id] || {}, org);
      });
    },
    [ON_FAILURE](state, action) {
      state.switchAccountingFirm[action.meta.identifier.orgId] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    isAccountingFirmSwitching: (orgId) => (state) => state[organizationStoreName].switchAccountingFirm[orgId]?.loading,
  },
});

const requestSwitchAccountingFirmSlice = createApiCallSlice<any, any>({
  name: generateSliceName(organizationStoreName, 'request_switch_accounting_firm'),
  api: organizationsApi.requestSwitchAccountingFirm,
  initialState: {
    requestSwitchAccountingFirm: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      state.requestSwitchAccountingFirm[action.payload.orgId] = {
        loading: true,
      };
    },
    [ON_SUCCESS](state, action) {
      delete state.requestSwitchAccountingFirm[action.meta.identifier.orgId];
    },
    [ON_FAILURE](state, action) {
      state.requestSwitchAccountingFirm[action.meta.identifier.orgId] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    isAccountingFirmSwitchRequesting: (orgId) => (state) =>
      state[organizationStoreName].requestSwitchAccountingFirm[orgId]?.loading,
  },
});

type UBOState = {
  ubo: {
    loading: boolean;
    error?: Error;
    isUBOInfoExists?: boolean;
  };
};

const getUBOInfoSlice = createApiCallSlice<any, UBOState>({
  name: generateSliceName(organizationStoreName, 'fetch_ubo_info'),
  api: organizationsApi.getUltimateBeneficialOwner,
  initialState: {
    ubo: {
      loading: false,
      error: undefined,
      isUBOInfoExists: false,
    },
  },
  reducers: {
    [ON_REQUEST](state) {
      state.ubo = {
        loading: true,
        isUBOInfoExists: false,
      };
    },
    [ON_SUCCESS](state, action) {
      state.ubo.isUBOInfoExists = !!action.payload.uboResults?.length;
    },
    [ON_FAILURE](state, action) {
      state.ubo = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    isAccountingFirmSwitchRequesting: (orgId) => (state) =>
      state[organizationStoreName].requestSwitchAccountingFirm[orgId]?.loading,
  },
});

export type OrganizationUpdatePreferencesState = {
  organizationPreferences: OrganizationPreferencesType;
  isOrganizationPreferencesUpdating: boolean;
};

const initialState: OrganizationUpdatePreferencesState = {
  organizationPreferences: {
    getPaidProLayout: 'true',
  },
  isOrganizationPreferencesUpdating: false,
};

const updateOrganizationPreferenceSlice = createApiCallSlice<
  UpdateOrganizationPreferenceParamsType,
  OrganizationUpdatePreferencesState,
  void
>({
  api: organizationPreferencesApi.updateOrganizationPreference,
  initialState,
  name: generateSliceName(organizationStoreName, 'update_organization_preference'),
  reducers: {
    [ON_REQUEST]: (state) => {
      state.isOrganizationPreferencesUpdating = true;
    },
    [ON_SUCCESS]: (state, action) => {
      state.isOrganizationPreferencesUpdating = false;
      state.organizationPreferences = {
        ...state.organizationPreferences,
        [action.meta.identifier.key]: action.meta.identifier.value,
      };
    },
    [ON_FAILURE]: (state) => {
      state.isOrganizationPreferencesUpdating = false;
    },
    [SET_ORGANIZATION_PREFERENCES]: (state, action: any) => {
      state.organizationPreferences = action.organizationPreferences;
    },
    [CLEAR_STATE]: () => initialState,
  },
});

const updateOrganizationServiceFeesSlice = createApiCallSlice<UpdateServiceFeesPreferenceParamsType, any, void>({
  api: organizationPreferencesApi.updateServiceFeesPreference,
  name: generateSliceName(organizationStoreName, 'update_service_fees_preference'),
  reducers: {
    [ON_REQUEST]: (state) => {
      state.isOrganizationPreferencesUpdating = true;
    },
    [ON_SUCCESS]: (state, action) => {
      state.isOrganizationPreferencesUpdating = false;
      state.organizationPreferences = {
        ...state.organizationPreferences,
        [action.meta.identifier.key]: action.meta.identifier.value,
      };
    },
    [ON_FAILURE]: (state) => {
      state.isOrganizationPreferencesUpdating = false;
    },
  },
  sagas: {
    *[ON_SUCCESS](action) {
      const { value } = action.meta.identifier;
      yield put(
        updateOrganizationPreferenceSlice.actions.success(null, {
          key: OrgPreferencesTypeKeys.isVendorAbsorbedFee,
          value,
        })
      );
    },
    *[ON_FAILURE]() {
      yield put(updateOrganizationPreferenceSlice.actions.failure());
    },
  },
});

export {
  createNotOwnedOrganizationSlice,
  getUBOInfoSlice,
  requestSwitchAccountingFirmSlice,
  switchAccountingFirmSlice,
  unlinkQuickbookSlice,
  updateNewCompanySlice,
  updateOrganizationPreferenceSlice,
  updateOrganizationServiceFeesSlice,
};
