import { featureFlags } from '@melio/shared-web';
import { useIntl } from 'react-intl';
import { getSelectOptionObject, Option } from 'src/components/common/MISingleSelect';
import { VendorOptionWithScoreType } from 'src/pages/vendor-directory/select-vendor/types';
import { DirectoryType, ScreenMode, SelectVendorType } from 'src/utils/consts';
import { FeatureFlags } from 'src/utils/featureFlags';

type BusinessDetailsBoxConfig = {
  businessDetails: VendorOptionWithScoreType | null;
  shouldShowBusinessDetailsBox: boolean;
  deliveryMethodDefaultValue: string;
  shouldDisplayReviewVendorDetailsNotification: boolean;
};

type Props = {
  vendorOptions?: Option[];
  selectedVendorId?: number;
  mode: ScreenMode;
};

export const useBusinessDetailsBoxInForm = ({
  vendorOptions,
  selectedVendorId,
  mode,
}: Props): BusinessDetailsBoxConfig => {
  const [isBusinessDirectorySearchEnabled] = featureFlags.useFeature(FeatureFlags.BusinessDirectorySearch, false);
  const intl = useIntl();
  const deliveryMethodDefaultValue = intl.formatMessage({
    id: 'bills.form.verifiedVendorDetailsInfo.deliveryMethodDefaultValue',
  });

  const shouldHideBusinessDetailsBox = () => {
    if (!isBusinessDirectorySearchEnabled || !vendorOptions || !selectedVendorId || mode === ScreenMode.VIEW) {
      return true;
    }

    const selectedVendor = getSelectOptionObject(vendorOptions, +selectedVendorId);

    if (!selectedVendor) {
      return true;
    }

    return !(
      selectedVendor.type === SelectVendorType.DIRECTORY && selectedVendor.directoryType === DirectoryType.Business
    );
  };

  const getBusinessDetails = (): BusinessDetailsBoxConfig['businessDetails'] => {
    if (!vendorOptions || !selectedVendorId) {
      return null;
    }

    const businessDetails = getSelectOptionObject(vendorOptions, +selectedVendorId);

    if (!businessDetails || businessDetails.directoryType !== DirectoryType.Business) {
      return null;
    }

    return businessDetails as VendorOptionWithScoreType;
  };

  const shouldDisplayReviewVendorDetailsNotification = () => {
    if (!vendorOptions || !selectedVendorId) {
      return false;
    }

    const businessDetails = getSelectOptionObject(vendorOptions, +selectedVendorId);

    return Boolean(businessDetails?.isFcb || businessDetails?.isMutualCompanyName);
  };

  return {
    shouldShowBusinessDetailsBox: !shouldHideBusinessDetailsBox(),
    businessDetails: getBusinessDetails(),
    deliveryMethodDefaultValue,
    shouldDisplayReviewVendorDetailsNotification: shouldDisplayReviewVendorDetailsNotification(),
  };
};
