import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import organizationStore from 'src/modules/organizations/organizations-store';
import { getOrgId } from 'src/redux/user/selectors';
import { CompanyInfoType } from 'src/utils/types';

const useUpdateOrganization = (orgId?: string | null) => {
  const currentOrgId = useSelector(getOrgId);
  const orgIdToUpdate = orgId || currentOrgId;
  const isOrganizationUpdating = useSelector(organizationStore.selectors.update.status(orgIdToUpdate))?.loading;
  const organizationActions = useStoreActions(organizationStore);

  const updateOrganization = useCallback(
    async (data: Partial<CompanyInfoType>) => {
      await organizationActions.update({ id: orgIdToUpdate, ...data });
    },
    [orgIdToUpdate, organizationActions]
  );

  return { updateOrganization, isOrganizationUpdating };
};

export default useUpdateOrganization;
