import { ApiSliceActionName, generateActionName } from 'src/helpers/redux/actionNames';
import { batchMarkAsPaidSlice } from './batch-mark-as-paid-slice';
import { billListIndexSlice } from './bill-list-index-slice';
import { name } from './consts';
import { markAsPaidSlice } from './mark-as-paid-slice';
import { regularListSlice } from './regular-list-slice';
import { setClassificationSlice } from './set-classification-slice';

export const BILLS_FETCH_SUCCESS = generateActionName(name, 'FETCH', ApiSliceActionName.SUCCESS);
export const BILLS_LIST_SUCCESS = generateActionName(name, 'LIST', ApiSliceActionName.SUCCESS);
export const BILLS_CREATE_SUCCESS = generateActionName(name, 'CREATE', ApiSliceActionName.SUCCESS);

export const BILLS_BATCH_MARK_AS_PAID_SUCCESS = batchMarkAsPaidSlice.actions.success;
export const BILLS_LIST_INDEX_SUCCESS = billListIndexSlice.actions.success;
export const BILLS_MARK_AS_PAID_SUCCESS = markAsPaidSlice.actions.success;
export const BILLS_REGULAR_LIST_SUCCESS = regularListSlice.actions.success;
export const BILLS_SET_CLASSIFICATION_SUCCESS = setClassificationSlice.actions.success;
