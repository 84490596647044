/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { AmexNotificationDetails } from '@melio/spend-management-api-axios-client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import MISingleSelect, { FormatOptionContext } from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box';
import { DrawerCloseButton } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';
import Spacer from 'src/core/ds/spacer';
import { useCreateCard } from 'src/modules/spend-management/cards/hooks/useCreateCard';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import {
  getValidationDate,
  getValidDateOptions,
  getValidDatesList,
  ValidationDateType,
} from 'src/pages/utils/validation-date';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { NotificationCardTypes, SingleSelectFlavor } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { CardDetailsSection, CardPlaceholder } from './cards';
import { AccountOption, AccountOptionType } from './cards/AccountOption';
import { CARD_NAME_CHAR_LIMIT } from './constants';
import { CreateCardLoader } from './CreateCardLoader';
import { CreateFooter } from './footers';
import { Body, CardDetailsSwitch, CardLimitInputField, CardTextInputField, Header } from './styles';

type CardInfoType = {
  nickname: string | null;
  amount: string | null;
  validationDate: Date | null;
  accountId: string | null;
};

const getValidationErrorForField = (limit: boolean) => (key: string, value: string): string | undefined => {
  if (key === 'nickname') {
    const d = value?.replaceAll(/[^\w -]+/g, '');

    if (!value.length) {
      return 'spendManagement.pages.dashboard.createCard.errors.nicknameEmpty';
    } else if (!d && value.length) {
      return 'spendManagement.pages.dashboard.createCard.errors.nicknameChars';
    } else if (d.length > CARD_NAME_CHAR_LIMIT) {
      return 'spendManagement.pages.dashboard.createCard.errors.nicknameLength';
    }
  }

  if (key === 'amount' && limit) {
    if (!value) {
      return 'spendManagement.pages.dashboard.createCard.errors.amountEmpty';
    }
  }

  return undefined;
};

export const CreateCard = ({ accounts, onSuccess }: { accounts: AmexNotificationDetails[]; onSuccess: () => void }) => {
  const intl = useIntl();
  const [validationDate, setValidationDate] = useState<ValidationDateType>('fiveYears');
  const defaultAccount = accounts.find((account) => account.isDefault);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    defaultAccount?.accountId || (accounts.length === 1 && accounts[0].accountId) || null
  );
  const cardInfo = useMemo<CardInfoType>(
    () => ({
      nickname: '',
      amount: null,
      validationDate: getValidDateOptions()[validationDate].value,
      accountId: selectedAccountId,
    }),
    []
  );

  const { createCard, loading } = useCreateCard();

  const submitCard = async (values: CardInfoType) => {
    try {
      await createCard({
        accountId: values.accountId!,
        nickname: values.nickname!,
        amount: values.amount!,
        expiryDate: values.validationDate!.toISOString().slice(0, 10),
      });
      analytics.track(Page.CARD_LIST, Context.NEW_CARD_DRAWER, Event.CONTINUE_SUCCESS);
      onSuccess();
    } catch (e) {
      pushNotification({
        type: 'error',
        msg: 'spendManagement.pages.dashboard.createCard.errors.createFailedTitle',
      });
      setError(true);
      analytics.track(Page.CARD_LIST, Context.NEW_CARD_DRAWER, Event.CONTINUE_FAILED);
    }
  };
  const [useLimit, setUseLimit] = useState<boolean>(false);

  const [modelView, formActions, validationErrors] = useForm<CardInfoType>(cardInfo, {
    submit: submitCard,
    validator: getValidationErrorForField(useLimit),
  });

  const [currentPage, setCurrentPage] = useState<Page>(Page.CARD_LIST);
  const [error, setError] = useState<boolean>(false);
  const { path } = useRouteMatch();

  useEffect(() => {
    setCurrentPage(path === spendManagementLocations.cardsList ? Page.CARD_LIST : Page.TRANSACTION_LIST);
  }, [path]);

  useEffect(() => {
    if (!useLimit) {
      updateModel('amount', { id: 'amount-currency-field', value: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLimit]);

  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (submitted) {
      if (Object.keys(validationErrors).some((fieldName) => !!validationErrors[fieldName])) {
        analytics.track(currentPage, Context.NEW_CARD_DRAWER, Event.VALIDATION_ERROR, validationErrors);
      }

      setSubmitted(false);
    }
  }, [currentPage, submitted, validationErrors]);

  const onKeyPressed = (event: React.KeyboardEvent) => {
    if (isEnterPressed(event)) {
      analytics.track(currentPage, Context.NEW_CARD_DRAWER, Event.CLICK_SAVE);

      setSubmitted(true);

      formActions.submit();
      event.preventDefault();
    }
  };

  const handleClose = () => {
    analytics.track(currentPage, Context.NEW_CARD_DRAWER, Event.CLICK_CLOSE_X);
  };

  const limitInputProps = {
    isDisabled: !useLimit,
    label: 'spendManagement.pages.dashboard.createCard.inputs.cardAmount',
    id: 'amount',
    size: 'md',
    placeholder: `spendManagement.pages.dashboard.createCard.inputs.${
      useLimit ? 'cardAmountPlaceholder' : 'cardNoLimit'
    }`,
    helperText: 'spendManagement.pages.dashboard.createCard.inputs.cardAmountHelp',
    isRequired: useLimit,
  };

  const updateModel = useCallback(
    (id: string, args: { value?: string | null; id?: string }) => modelView[id].onChange(args),
    [modelView]
  );

  const onValidationDateChange = useCallback(
    (id: ValidationDateType) => {
      setValidationDate(id);
      updateModel('validationDate', { value: getValidDateOptions()[id as string].value });
    },
    [setValidationDate, updateModel]
  );

  const onAccountChange = useCallback(
    ({ account }: { account?: AmexNotificationDetails }) => {
      setSelectedAccountId(account?.accountId || null);
      updateModel('accountId', { value: account?.accountId });
      analytics.track(currentPage, Context.NEW_CARD_DRAWER, Event.CLICK_CHANGE_ACCOUNT);
    },
    [currentPage, updateModel]
  );

  const generateValidationDatesList = getValidDatesList().map((option) => ({
    ...option,
    label: intl.formatMessage({ id: option.label.id }, option.label),
  }));

  const accountsList = useMemo(
    () =>
      accounts.map((account) => ({
        value: account.accountId,
        label: intl.formatMessage(
          { id: 'spendManagement.pages.dashboard.cardDetails.details.primary.value' },
          { value: account.lastFive, nickname: account.accountNickname }
        ),
        account,
        isSelectedAccount: account.accountId === selectedAccountId,
      })),
    [accounts, intl, selectedAccountId]
  );

  const createValidationDateItem = (selection) => ({
    ...getValidationDate(selection),
    label: intl.formatMessage(
      {
        id: getValidationDate(selection).label.id,
      },
      getValidationDate(selection).label
    ),
  });

  return (
    <Flex onKeyDown={onKeyPressed} direction="column" h="full">
      <Header flexDirection="column" minH="auto">
        <Flex justify="space-between" w="full">
          <Box textStyle="body1Semi">
            <MIFormattedText label="spendManagement.pages.dashboard.createCard.title" />
          </Box>
          <Spacer />
          <DrawerCloseButton onClick={handleClose} size="md" />
        </Flex>
      </Header>
      {loading ? (
        <CreateCardLoader />
      ) : (
        <>
          <Body pb={10} pt={4}>
            <Flex direction="column">
              {error && (
                <Box mb={4}>
                  <MINotificationCard
                    type={NotificationCardTypes.ERROR}
                    title={{ label: 'spendManagement.pages.dashboard.createCard.errors.createFailedTitle' }}
                    subtitle={{ label: 'spendManagement.pages.dashboard.createCard.errors.createFailedText' }}
                  />
                </Box>
              )}
              <CardPlaceholder />
              {accounts && (
                <CardDetailsSection
                  title="spendManagement.pages.dashboard.cardDetails.details.primary.title"
                  value={
                    <MISingleSelect
                      isSearchable={false}
                      flavor={SingleSelectFlavor.INLINE}
                      formatOptionLabel={(
                        { account, isSelectedAccount }: AccountOptionType,
                        { context }: FormatOptionContext
                      ) => <AccountOption account={account} isSelectedAccount={isSelectedAccount} context={context} />}
                      onChange={(value) => onAccountChange(value)}
                      options={accountsList}
                      value={selectedAccountId}
                    />
                  }
                />
              )}
              <CardDetailsSection
                title="spendManagement.pages.dashboard.createCard.inputs.cardName"
                value={
                  <CardTextInputField
                    id="nickname"
                    mb="0"
                    value={modelView.nickname.value || ''}
                    onChange={(event) => {
                      updateModel('nickname', { value: event.target.value });
                    }}
                    errorMessage={validationErrors.nickname}
                    size="md"
                    placeholder="spendManagement.pages.dashboard.createCard.inputs.cardNamePlaceholder"
                    helperText="spendManagement.pages.dashboard.createCard.inputs.cardNameHelp"
                    isRequired
                    maxlength={CARD_NAME_CHAR_LIMIT}
                  />
                }
              />
              <CardDetailsSection
                title="spendManagement.pages.dashboard.createCard.inputs.validDate.title"
                value={
                  <MISingleSelect
                    isSearchable={false}
                    flavor={SingleSelectFlavor.INLINE}
                    onChange={({ id }) => onValidationDateChange(id)}
                    options={generateValidationDatesList}
                    value={createValidationDateItem(validationDate)}
                  />
                }
              />
              <CardDetailsSection
                title="spendManagement.pages.dashboard.createCard.inputs.cardLimit"
                value={
                  <Flex direction="column">
                    <CardDetailsSwitch onChange={(event) => setUseLimit(event.target.checked)} />
                    {useLimit ? (
                      <CardLimitInputField
                        model={modelView.amount}
                        onChange={(event) => updateModel('amount', { value: event.target.value })}
                        errorMessage={validationErrors.amount}
                        {...limitInputProps}
                      />
                    ) : (
                      <CardTextInputField {...limitInputProps} />
                    )}
                  </Flex>
                }
              />
            </Flex>
          </Body>
          <CreateFooter
            isLoading={loading}
            onPrimary={() => {
              analytics.track(currentPage, Context.NEW_CARD_DRAWER, Event.CLICK_SAVE);

              setSubmitted(true);

              formActions.submit();
            }}
          />
        </>
      )}
    </Flex>
  );
};
