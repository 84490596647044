import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import DeliveryMethodListItem from 'src/pages/bill/pay/components/DeliveryMethodListItem';
import { PayBillProps, withPayBillData } from 'src/pages/bill/pay/hoc/withPayBillData';
import { deliveryMethodsLocations } from 'src/pages/vendor/delivery-methods/locations';
import { getDeliveryMethodByType } from 'src/pages/vendor/records';
import { selectDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { DeliveryMethodOrigin, DeliveryType } from 'src/utils/consts';
import { billLocations } from '../locations';

const eventPage = 'virtual-card-recovery';

const PlainVirtualCardRecoveryFlowPage = ({ onPrev, goExit, onNext }: PayBillProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const { companyName = '' } = bill.vendor ?? {};

  const virtualCardDeliveryMethod = getDeliveryMethodByType(
    DeliveryType.VIRTUAL_CARD,
    bill?.vendor?.deliveryMethods || []
  );

  const achDeliveryMethod = getDeliveryMethodByType(DeliveryType.ACH, bill?.vendor?.deliveryMethods || []);
  const virtualDeliveryMethod = getDeliveryMethodByType(DeliveryType.VIRTUAL, bill?.vendor?.deliveryMethods || []);
  const showVirtualDeliveryMethodOption = !achDeliveryMethod.id;
  const selectDeliveryMethod = async (deliveryMethod) => {
    await dispatch(selectDeliveryMethodAction(deliveryMethod));
    onNext();
  };

  const navigateCreateDeliveryMethod = (url) => {
    const redirectUrl = generatePath(billLocations.pay.edit.confirm, { orgId, billId: bill.id, paymentId: payment.id });
    const exitUrl = generatePath(billLocations.pay.edit.virtualCardRecovery, {
      orgId,
      billId: bill.id,
      paymentId: payment.id,
    });
    history.push({
      pathname: generatePath(url, {
        orgId,
        id: bill.vendorId ?? undefined,
      }),
      state: { origin: DeliveryMethodOrigin.PAY_BILL, redirectUrl, exitUrl },
    });
  };

  const onVirtualCardSelected = () => {
    analytics.track(eventPage, 'select-delivery-method', {
      type: 'virtual-card',
      vendorId: bill?.vendor?.id,
      paymentId: payment?.id,
      partialBillId: getBillPaymentIndex(bill),
    });
    selectDeliveryMethod(virtualCardDeliveryMethod);
  };

  const onAchSelected = () => {
    if (achDeliveryMethod.id) {
      analytics.track(eventPage, 'select-delivery-method', {
        type: 'ach',
        partialBillId: getBillPaymentIndex(bill),
      });
      selectDeliveryMethod(achDeliveryMethod);
    } else {
      analytics.track(eventPage, 'create-delivery-method', {
        type: 'ach',
        partialBillId: getBillPaymentIndex(bill),
      });
      navigateCreateDeliveryMethod(deliveryMethodsLocations.ach.createFromVirtualCardRecoveryFlow);
    }
  };

  const onUnilateralSelected = () => {
    analytics.track(eventPage, 'create-delivery-method', {
      type: 'virtual',
      partialBillId: getBillPaymentIndex(bill),
    });
    navigateCreateDeliveryMethod(deliveryMethodsLocations.virtual.create);
  };

  return (
    <StepLayoutPage
      relativeStep={1 / 2}
      title="bills.pay.resendVirtualCard.title"
      titleValues={{ vendor: bill?.vendor?.companyName }}
      goExit={goExit}
      onPrev={onPrev}
      isHideActions
    >
      {virtualCardDeliveryMethod?.id && (
        <DeliveryMethodListItem
          key={virtualCardDeliveryMethod.deliveryType}
          deliveryMethod={virtualCardDeliveryMethod}
          label="deliveryMethods.resend.virtual-card.label"
          companyName={companyName}
          onItemClick={onVirtualCardSelected}
          isSelected={false}
        />
      )}
      <DeliveryMethodListItem
        key={achDeliveryMethod.deliveryType}
        deliveryMethod={achDeliveryMethod}
        label="deliveryMethods.resend.ach.label"
        companyName={companyName}
        onItemClick={onAchSelected}
        isSelected={false}
      />
      {showVirtualDeliveryMethodOption && (
        <Box mt={5} w="full">
          <WizardElements.WizardOrLine />
          <DeliveryMethodListItem
            key={virtualDeliveryMethod.deliveryType}
            deliveryMethod={virtualDeliveryMethod}
            label="deliveryMethods.resend.unilateral.label"
            companyName={companyName}
            onItemClick={onUnilateralSelected}
            isSelected={false}
          />
        </Box>
      )}
    </StepLayoutPage>
  );
};

export const VirtualCardRecoveryFlowPage = withPayBillData()(PlainVirtualCardRecoveryFlowPage);
