import { MfaAuthenticatorType, MfaDetailsType } from 'src/utils/types';

const localStorageKey = 'mfa';

type ReturnValue = {
  mfaToken: string;
  authenticators: MfaAuthenticatorType[];
  isMoreThanOneAuthenticator: boolean;
  lastChallengedAuthenticator: MfaAuthenticatorType;
  setMfaDetails: (mfa: MfaDetailsType) => void;
  removeMfaDetails: () => void;
};

export const useMfaDetails = (): ReturnValue => {
  const mfa = localStorage.getItem(localStorageKey);
  let mfaDetails;

  if (mfa) {
    try {
      mfaDetails = JSON.parse(mfa);
    } catch (e) {
      mfaDetails = {};
    }
  }

  const mfaToken = mfaDetails?.mfa_token || '';
  const authenticators = mfaDetails?.authenticators || [];
  const [lastChallengedAuthenticatorId] = (mfaDetails?.challenged_authenticators || []).slice(-1);
  const lastChallengedAuthenticator = authenticators?.find((el) => el.id === lastChallengedAuthenticatorId);
  const isMoreThanOneAuthenticator = authenticators.length > 1;

  const removeMfaDetails = async () => {
    localStorage.removeItem(localStorageKey);
  };

  const setMfaDetails = async (mfa: MfaDetailsType) => {
    localStorage.setItem(localStorageKey, JSON.stringify(mfa));
  };

  return {
    mfaToken,
    authenticators,
    lastChallengedAuthenticator,
    isMoreThanOneAuthenticator,
    setMfaDetails,
    removeMfaDetails,
  };
};
