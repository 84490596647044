import isArray from 'lodash/isArray';
import { MIEditFormControl } from 'src/components/common/MIEditFormControl';
import { MIViewFormControl } from 'src/components/common/MIViewFormControl';
import { WizardFormColumn } from 'src/components/layout/WizardElements';
import Flex from 'src/core/ds/flex';
import { FormType, ScreenMode } from 'src/utils/consts';

type Props = {
  formControls: Array<Record<string, any> | Array<Record<string, any>>>;
  mode?: ScreenMode;
  formType?: FormType;
};

const renderFormControl = (formControl, mode, formType) => {
  if (formControl.isHide) {
    return null;
  }

  return mode === ScreenMode.EDIT ? (
    <MIEditFormControl key={formControl.id} formControl={formControl} formType={formType} />
  ) : (
    <MIViewFormControl key={formControl.id} formControl={formControl} />
  );
};

export const MIForm = ({ formControls, mode = ScreenMode.EDIT, formType = FormType.WIZARD }: Props) => (
  <>
    {formControls.map((formControl) => {
      if (isArray(formControl)) {
        const rowKeyId = formControl.reduce((result, nestedFormControl) => `${result} ${nestedFormControl.id}`, '');

        return (
          <RowContainer key={rowKeyId}>
            {formControl.map((nestedFormControl) => (
              <WizardFormColumn key={nestedFormControl.id}>
                {renderFormControl(nestedFormControl, mode, formType)}
              </WizardFormColumn>
            ))}
          </RowContainer>
        );
      }

      return renderFormControl(formControl, mode, formType);
    })}
  </>
);

type RowContainerProps = {
  children?: React.ReactNode;
};

const RowContainer = ({ children }: RowContainerProps) => <Flex>{children}</Flex>;
