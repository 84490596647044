import { useMemo } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ButtonSizes } from 'src/core/ds/button';
import IconButton from 'src/core/ds/iconButton';
import { Menu, MenuButton, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ThreeDotsIcon } from 'src/images/get-pro/three-dots.svg';
import { InvoiceType } from 'src/modules/invoices/types';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { GetProTabs } from 'src/utils/consts';
import { useGetItemActions } from '../../hooks/useGetItemActions';
import { MenuActionItem } from './MenuActionItem';

type Props = {
  invoice: InvoiceType;
  tab: GetProTabs;
};

export function InvoiceAndRequestMenu({ invoice, tab }: Props) {
  const accountingSoftwareName = useGetAccountingSoftwareName(invoice.createOrigin);
  const actionsList = useGetItemActions(invoice, tab);
  const integrationName = accountingSoftwareName && getAccountingSoftwareDisplayName(accountingSoftwareName);

  return useMemo(
    () => (
      <Menu isLazy placement="bottom-start">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          size={ButtonSizes.sm}
          icon={<ThreeDotsIcon />}
          label={`getPro.table.item.menu.${tab}.options`}
        />
        <MenuList>
          {actionsList.map((listItem) => (
            <MenuActionItem
              key={listItem.label}
              {...listItem}
              isDisabled={Boolean(listItem.disabledLabel)}
              disabledTooltipLabel={
                <MIFormattedText
                  label={listItem.disabledLabel}
                  values={{
                    integrationName: integrationName?.displayShortName,
                  }}
                />
              }
            />
          ))}
        </MenuList>
      </Menu>
    ),
    [actionsList, integrationName, tab]
  );
}
