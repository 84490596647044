import { forwardRef } from '@chakra-ui/react';
import { ChangeEvent, FocusEvent, SyntheticEvent } from 'react';
import { FormControlProps } from 'src/core/ds/form';
import { ModelViewField } from 'src/ui/form';
import { getFormattedDate } from '../utils';
import { DateFieldInput } from './DateFieldInput';

type Props = {
  id?: string;
  date?: Date | null;
  model?: ModelViewField<string>;
  min?: Date | null;
  max?: Date | null;
  placeholder?: string;
  isRequired?: boolean;
  size?: 'sm' | 'md';
  onChange: (date: Date | null) => void;
  testId?: string | null;
  withIcon?: boolean;
  label?: string;
  helperText?: string;
  helperTextValues?: Record<string, unknown>;
  errorMessage?: string | null;
  isDisabled?: boolean;
  mb?: FormControlProps['mb'];
};

export const MobileDateField = forwardRef(
  (
    {
      id,
      date,
      model,
      min,
      max,
      withIcon,
      isRequired,
      size,
      testId,
      onChange,
      label,
      helperText,
      helperTextValues,
      isDisabled,
      errorMessage,
      mb,
    }: Props,
    ref
  ) => {
    const minFormatted = getFormattedDate(min);
    const maxFormatted = getFormattedDate(max);
    // This logic is needed to make `Clear` button for iOS date picker works properly
    // See details https://github.com/facebook/react/issues/8938
    const resetMobileDefaultValue = (event: SyntheticEvent<HTMLInputElement>) => {
      const { target } = event.nativeEvent;

      target &&
        setTimeout(() => {
          const targetValue = target as HTMLInputElement;
          targetValue.defaultValue = '';
        }, 0);
    };

    const handleMobileFocus = (event: FocusEvent<HTMLInputElement>) => resetMobileDefaultValue(event);

    const handleMobileChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const dateValue = value ? new Date(value) : null;
      onChange && onChange(dateValue);
      resetMobileDefaultValue(event);
    };

    return (
      <DateFieldInput
        id={id}
        model={model}
        label={label}
        size={size}
        helperText={helperText}
        helperTextValues={helperTextValues}
        errorMessage={errorMessage}
        isRequired={isRequired}
        isDisabled={isDisabled}
        testId={`${testId}-passthrough`}
        onChange={handleMobileChange}
        onFocus={handleMobileFocus}
        minDate={minFormatted}
        maxDate={maxFormatted}
        withIcon={withIcon}
        date={date}
        ref={ref}
        mb={mb}
      />
    );
  }
);
