import pick from 'lodash/fp/pick';
import values from 'lodash/fp/values';
import { composeSlices } from 'src/helpers/redux/composeSlice';
import { createListSlice } from 'src/helpers/redux/restListSlice';
import { DEFAULT_PAY_LIST_FILTERS, PayListFilters } from 'src/utils/payListConsts';
import { parseQueryString } from 'src/utils/query-utils';
import { PayListItemType } from '../bills/types';
import { getPayListItems } from './api';
import { name } from './consts';
import { mapItemsResponse } from './utils';

type PayListItemsParams = {
  orgId: number;
  filters?: PayListFilters;
};

const mergeDefaultAndQueryFilters = (): PayListFilters => {
  const query = parseQueryString(window.location.search);

  const { id, ...queryFilters } = query;
  const vendorId = [].concat(queryFilters.vendorId ?? []);

  return { ...DEFAULT_PAY_LIST_FILTERS, ...queryFilters, vendorId };
};

export const payListItemsSlice = createListSlice<PayListItemType>({
  storeName: name,
  api: (params: PayListItemsParams) => {
    const filters = params.filters || mergeDefaultAndQueryFilters();

    return getPayListItems[filters.status]({ orgId: params.orgId, filters }).then((res) => ({
      items: mapItemsResponse(res.objects, filters.status),
      totalCount: res.totalCount,
    }));
  },
  listHashFunc: (params: PayListItemsParams) => `${params.orgId}`,
});

export const payListItemsStore = composeSlices(
  { list: payListItemsSlice },
  {
    selectors: {
      totalAmount: (ids) => (state) =>
        ids.reduce((sum, id) => {
          const item = state[name]?.byId[id];

          return (item?.balance || item?.totalAmount) + sum;
        }, 0),
      byId: (id) => (state) => state[name]?.byId[id],
      all: (state) => Object.values(state[name]?.byId),
      getByIds: (ids: string[]) => (state) => values(pick(ids, state[name].byId)),
    },
  }
);
