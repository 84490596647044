import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAmexIndustry } from 'src/components/funding-source/amex/hooks/useAmexIndustry';
import { useAmexVendorAddress } from 'src/components/funding-source/amex/hooks/useAmexVendorAddress';
import { AmexIndustryModal } from 'src/components/funding-source/amex/modals/AmexIndustryModal';
import { AmexVendorAddressModal } from 'src/components/funding-source/amex/modals/AmexVendorAddressModal';
import { IconNames } from 'src/core/ds/icon';
import { Menu, MenuList } from 'src/core/ds/menu';
import { Portal } from 'src/core/ds/portal';
import { useModal } from 'src/helpers/react/useModal';
import { billsStore } from 'src/modules/bills/bills-store';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { FundingSourceCellLabel } from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceCellLabel';
import { FundingSourceMenuItem } from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceMenuItem';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, getPaymentRequestId } from 'src/utils/bills';
import { FeatureFlags } from 'src/utils/consts';
import { hasVendorCheckDeliveryMethod } from 'src/utils/delivery-methods';
import { AccountType, BillType } from 'src/utils/types';
import { ArrowMenuButtonCell } from './ArrowMenuButtonCell';

type Props = {
  payment: BatchPaymentType;
  fundingSources: AccountType[];
  currentFundingSourceId?: number;
  setCurrentFundingSource?: (fundingSource: AccountType) => void;
  isInternationalVendor?: boolean;
};

export const FundingSourceCell = ({
  payment,
  fundingSources,
  currentFundingSourceId,
  setCurrentFundingSource,
  isInternationalVendor,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const currentFundingSource = fundingSources.find((fs) => fs.id === currentFundingSourceId) as AccountType;
  const { vendorId, billId, vendor } = payment;
  const { deliveryMethods } = useSelector(vendorsStore.selectors.fetch.byId(payment?.vendorId));
  const { shouldShowAmexIndustryModal, industriesList, pendingResources } = useAmexIndustry(
    vendorId,
    currentFundingSourceId,
    true
  );
  const { amexAddressExperimentVariant, vendorHasAddress } = useAmexVendorAddress({
    selectedFundingSourceId: currentFundingSourceId,
    vendor,
    featureFlagName: FeatureFlags.AmexVendorAddressBatchFlow,
  });

  const shouldShowAmexAddressModal = amexAddressExperimentVariant && !hasVendorCheckDeliveryMethod(deliveryMethods);

  const bill = useSelector<any, BillType>(billsStore.selectors.byId(billId));
  const analyticsProps = {
    billId: bill.isPaymentRequest ? null : bill.id,
    paymentRequestId: bill.isPaymentRequest ? getPaymentRequestId(bill) : null,
    vendorId,
    partialBillId: getBillPaymentIndex(bill),
    isBatch: true,
  };
  const [amexIndustryModal, showAmexIndustryModal] = useModal(AmexIndustryModal, {
    id: 'AmexIndustryModal',
    orgId,
    vendorId,
    modalName: 'AmexIndustryModal',
    vendorName: vendor?.companyName || '',
    industries: industriesList,
    isDataLoading: pendingResources,
    analyticsProps,
    onConfirm: async () => {
      if (amexAddressExperimentVariant) {
        analytics.trackAction('amex-vendor-address-open', {
          vendorId: analyticsProps.vendorId,
          billId: analyticsProps.billId,
          isBatch: true,
        });
        showAmexVendorAddressModal();
      }
    },
  });

  const [amexVendorAddressModal, showAmexVendorAddressModal] = useModal(AmexVendorAddressModal, {
    id: 'AmexVendorAddressModal',
    orgId,
    vendor: bill?.vendor,
    modalName: 'AmexVendorAddressModal',
    vendorName: bill.vendor?.companyName,
    analyticsProps: {
      vendorId: analyticsProps.vendorId,
      billId: analyticsProps.billId,
      isBatch: true,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const openAmexModal = () => {
    analytics.trackAction('amex-vendor-address-experiment', {
      vendorId: analyticsProps.vendorId,
      billId: analyticsProps.billId,
      vendorHasMcc: !shouldShowAmexIndustryModal,
      vendorHasAddress,
      experimentVariant: amexAddressExperimentVariant,
      isBatch: true,
    });

    if (shouldShowAmexIndustryModal) {
      analytics.track('amex-industry-modal', 'amex-industry-open', analyticsProps);
      showAmexIndustryModal();

      return;
    }

    if (shouldShowAmexAddressModal) {
      analytics.trackAction('amex-vendor-address-open', {
        vendorId: analyticsProps.vendorId,
        billId: analyticsProps.billId,
        isBatch: true,
      });
      showAmexVendorAddressModal();
    }
  };

  const shouldShowAmexPopover = shouldShowAmexIndustryModal || shouldShowAmexAddressModal;

  const popoverProps = useMemo(() => {
    if (!shouldShowAmexPopover) {
      return undefined;
    }

    const commonProps = {
      iconName: IconNames.warning,
      iconColor: 'yellow.500',
      iconTestId: 'amex-warning-icon',
      action: openAmexModal,
    };

    if (shouldShowAmexIndustryModal && shouldShowAmexAddressModal) {
      return {
        label: 'regularBatchPayments.cells.paymentMethod.amexIndustryAndAddressTooltip',
        actionLabel: 'regularBatchPayments.cells.paymentMethod.amexIndustryAndAddressTooltipCtaLabel',
        popoverTestId: 'amex-mcc-and-address-tooltip',
        ...commonProps,
      };
    }

    if (shouldShowAmexIndustryModal) {
      return {
        label: 'regularBatchPayments.cells.paymentMethod.amexIndustryTooltip',
        actionLabel: 'regularBatchPayments.cells.paymentMethod.amexIndustryTooltipCtaLabel',
        popoverTestId: 'amex-mcc-tooltip',
        ...commonProps,
      };
    }

    if (shouldShowAmexAddressModal) {
      return {
        label: 'regularBatchPayments.cells.paymentMethod.amexAddressTooltip',
        actionLabel: 'regularBatchPayments.cells.paymentMethod.amexAddressTooltipCtaLabel',
        popoverTestId: 'amex-address-tooltip',
        ...commonProps,
      };
    }

    return undefined;
  }, [openAmexModal, shouldShowAmexAddressModal, shouldShowAmexIndustryModal, shouldShowAmexPopover]);

  return (
    <>
      {amexIndustryModal}
      {amexVendorAddressModal}
      <Menu autoSelect={false} isLazy>
        {({ isOpen }) => (
          <>
            <ArrowMenuButtonCell isOpen={isOpen} popover={popoverProps}>
              {currentFundingSource ? <FundingSourceCellLabel fundingSource={currentFundingSource} /> : null}
            </ArrowMenuButtonCell>
            <Portal>
              <MenuList display={isOpen ? 'block' : 'none'} maxH="40rem" overflow="auto">
                {fundingSources.map((fundingSource) => (
                  <FundingSourceMenuItem
                    key={fundingSource.id}
                    fundingSource={fundingSource}
                    onClick={setCurrentFundingSource}
                    isSelected={fundingSource.id === currentFundingSource?.id}
                    isInternationalVendor={isInternationalVendor}
                  />
                ))}
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </>
  );
};
