import { PropsWithChildren, ReactElement, useRef } from 'react';
import { HeaderGroup, useExpanded, useFlexLayout, useSortBy, useTable } from 'react-table';
import { chakra } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Table from 'src/core/ds/table';
import { ReactComponent as ArrowDownIcon } from 'src/images/get-pro/arrow-down.svg';
import { ReactComponent as ArrowUpIcon } from 'src/images/get-pro/arrow-up.svg';
import { TableContentState } from 'src/pages/get-pro/components/table/consts';
import { PAGE_EVENT as batchPaymnetsPage } from 'src/pages/regular-batch-payments/analytics/event-mapping';
import { BatchPaymentsDataTableProperties } from 'src/pages/regular-batch-payments/components/table/types';
import { DataTableRow } from 'src/pages/regular-batch-payments/table/DataTableRow';
import { analytics } from 'src/services/analytics';
import { DATA_TABLE_RENDER } from './consts';
import { containerStyle, iconStyle } from './tableStyles';

export function DataTable<T extends Record<string, unknown>>(
  props: PropsWithChildren<BatchPaymentsDataTableProperties<T>>
): ReactElement {
  const { columns, data, contentState, content, onClickRow } = props;

  const instance = useTable<T>(
    {
      autoResetSortBy: false,
      autoResetExpanded: false,
      ...props,
      data,
      columns,
    },
    useFlexLayout,
    useSortBy,
    useExpanded
  );

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow, toggleAllRowsExpanded } = instance;

  const didTableRendered = useRef<boolean>(false);

  if (!didTableRendered.current && instance && data.length) {
    didTableRendered.current = true;

    if (data.length === 1) {
      toggleAllRowsExpanded();
    }
  }

  const reportHeaderClick = (columnName) => () =>
    analytics.track(batchPaymnetsPage, 'column-header-clicked', { columnName });

  return (
    <Box __css={containerStyle}>
      <Table {...getTableProps()} cellPadding="0" cellSpacing="0" data-testid="batch-payments-table">
        <Table.Header>
          {headerGroups.map((headerGroup) => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup<T> & { isNumeric?: boolean }) => {
                const columnHeaderProps = column.getHeaderProps(column.getSortByToggleProps({ title: undefined }));

                return (
                  <Table.HeaderCell
                    {...columnHeaderProps}
                    isNumeric={column.isNumeric}
                    style={{
                      ...columnHeaderProps.style,
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      flex: column.minWidth,
                    }}
                  >
                    <Box px={4} onClick={reportHeaderClick(column.id)}>
                      {column.render(DATA_TABLE_RENDER.Header)}
                      {column.canSort && (
                        <chakra.span ml={2} h={4} __css={iconStyle}>
                          {column.isSorted &&
                            (column.isSortedDesc ? (
                              <ArrowDownIcon aria-label="sorted descending" />
                            ) : (
                              <ArrowUpIcon aria-label="sorted ascending" />
                            ))}
                        </chakra.span>
                      )}
                    </Box>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          ))}
        </Table.Header>

        {contentState && contentState !== TableContentState.CONTENT ? (
          <Table.Caption bgColor="white" mt={0}>
            {content}
          </Table.Caption>
        ) : (
          <Table.Body {...getTableBodyProps()} data-testid="batch-payments-table-body">
            {rows.map((row) => {
              prepareRow(row);

              return <DataTableRow row={row} key={row.id} onClickRow={onClickRow} />;
            })}
          </Table.Body>
        )}
      </Table>
    </Box>
  );
}
