import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import Spacer from 'src/core/ds/spacer';
import { BoxItemType } from 'src/pages/get-pro/types';
import { analytics } from 'src/services/analytics';
import { boxStyle, boxTitleStyle } from '../styles';
import { Amount } from './Amount';
import { getAmountVariant } from './utils';

type Props = BoxItemType & {
  id: string;
  title: string;
  isAlert?: boolean;
  selected?: boolean;
  onClick?: () => void;
};

export const TabBox = ({ id, title, numberOfItems = 0, amount = '0', isAlert, selected, onClick }: Props) => {
  const type = getAmountVariant(numberOfItems, isAlert);
  const onClickItem = () => {
    onClick?.();
    analytics.trackAction(title, {});
  };

  return (
    <Flex
      onClick={onClickItem}
      direction="column"
      isTruncated
      __css={boxStyle}
      role="group"
      data-testid={id}
      data-selected={selected}
      alignItems="center"
      height="fitContent"
    >
      <Flex alignItems="start">
        <Flex isTruncated direction="column" textStyle="body2Semi" __css={boxTitleStyle} data-selected={selected}>
          <MIFormattedText label={title} />
          <Box textStyle="body4" color="grey.600">
            <MIFormattedText label="getPro.box.items" values={{ numberOfItems }} />
          </Box>
        </Flex>
        <Spacer />
        <Amount amount={amount} variant={type} />
      </Flex>
    </Flex>
  );
};
