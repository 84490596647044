import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex';

export type Option = {
  id: string;
  label: string;
};

type Props = {
  selected: string;
  options: Option[];
  onSelect: (value: string) => void;
};

export const MISelectSlider = ({ selected, options, onSelect }: Props) => (
  <SliderContainer>
    {options.map((o) => {
      const isSelected = o.id === selected;

      return (
        <SliderOption
          onClick={() => onSelect(o.id)}
          key={o.id}
          selected={isSelected}
          data-testid={`slider-option-${o.id}`}
        >
          <MIFormattedText label={o.label} />
        </SliderOption>
      );
    })}
  </SliderContainer>
);

type SliderContainerProps = {
  children?: React.ReactNode;
};

const SliderContainer = ({ children }: SliderContainerProps) => (
  <Flex justify="center" h="4.2rem" borderRadius="lg" pb={10}>
    {children}
  </Flex>
);

type SliderOptionProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  'data-testid'?: string;
};

const SliderOption = ({ selected, children, ...rest }: SliderOptionProps) => (
  <Flex sx={SliderOptionStyle(selected)} {...rest}>
    {children}
  </Flex>
);

const SliderOptionStyle = (selected) => ({
  flexDir: 'column',
  justifyContent: 'center',
  w: 'full',
  border: '1px',
  borderColor: 'black',
  textStyle: selected ? 'body3Semi' : 'body3',
  letterSpacing: '0.02rem',
  textAlign: 'center',
  bgColor: selected ? 'black' : 'transparent',
  borderLeft: selected ? '1px' : 'none',
  color: selected ? 'white' : 'black',
  cursor: selected ? 'default' : 'pointer',
  _first: {
    borderLeft: '1px',
    borderLeftColor: 'black',
    borderTopLeftRadius: 'lg',
    borderBottomLeftRadius: 'lg',
  },
  _last: {
    borderTopRightRadius: 'lg',
    borderBottomRightRadius: 'lg',
  },
});
