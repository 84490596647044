import { useEffect, useRef } from 'react';
import { analytics } from 'src/services/analytics';
import { UserContextType } from 'src/utils/types';

const eventPage = 'two-factor-auth';

type Props = {
  user: Partial<UserContextType>;
};

type Response = {
  trackCodeEntered: (success: boolean, isBlocked?: boolean) => void;
  trackSupportClicked: (isBlocked: boolean) => void;
  trackResendClicked: (isBlocked: boolean) => void;
};

const useAuthCodeVerificationAnalytics = ({ user }: Props): Response => {
  const analyticsProps = {
    userId: user?.id,
    userEmail: user?.email,
  };

  const countAttemptsEnterCode = useRef(0);
  const countAttemptsResendCode = useRef(0);

  useEffect(() => {
    analytics.page(eventPage, 'insert-code', analyticsProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackCodeEntered = (success: boolean, isBlocked?: boolean) => {
    countAttemptsEnterCode.current += 1;

    analytics.track(eventPage, 'code-enter', {
      ...analyticsProps,
      count: countAttemptsEnterCode.current,
      blocked: isBlocked,
      success,
    });
  };

  const trackSupportClicked = (isBlocked: boolean) => {
    analytics.track(eventPage, 'contact-support', {
      ...analyticsProps,
      count: countAttemptsEnterCode.current,
      blocked: isBlocked,
    });
  };

  const trackResendClicked = (isBlocked: boolean) => {
    countAttemptsResendCode.current += 1;

    analytics.track(eventPage, 'resend-code', {
      ...analyticsProps,
      count: countAttemptsResendCode.current,
      blocked: isBlocked,
    });
  };

  return {
    trackCodeEntered,
    trackSupportClicked,
    trackResendClicked,
  };
};

export { useAuthCodeVerificationAnalytics };
