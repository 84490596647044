import { ReactComponent as Amex } from 'src/icons/specialLogos/amex.svg';
import { ReactComponent as AmexGrey } from 'src/icons/specialLogos/amexGrey.svg';
import { ReactComponent as CreditKey } from 'src/icons/specialLogos/creditkey.svg';
import { ReactComponent as CreditKeyGrey } from 'src/icons/specialLogos/creditkeyGrey.svg';
import { ReactComponent as Diners } from 'src/icons/specialLogos/diners.svg';
import { ReactComponent as DinersGrey } from 'src/icons/specialLogos/dinersGrey.svg';
import { ReactComponent as Discover } from 'src/icons/specialLogos/discover.svg';
import { ReactComponent as DiscoverGrey } from 'src/icons/specialLogos/discoverGrey.svg';
import { ReactComponent as Evolve } from 'src/icons/specialLogos/evolve.svg';
import { ReactComponent as EvolveGrey } from 'src/icons/specialLogos/evolveGrey.svg';
import { ReactComponent as Freshbooks } from 'src/icons/specialLogos/freshbooks.svg';
import { ReactComponent as Intuit } from 'src/icons/specialLogos/intuit.svg';
import { ReactComponent as Mastercard } from 'src/icons/specialLogos/mastercard.svg';
import { ReactComponent as MastercardGrey } from 'src/icons/specialLogos/mastercardGrey.svg';
import { ReactComponent as Pci } from 'src/icons/specialLogos/pci.svg';
import { ReactComponent as PciGrey } from 'src/icons/specialLogos/pciGrey.svg';
import { ReactComponent as Quickbooks } from 'src/icons/specialLogos/quickbooks.svg';
import { ReactComponent as QuickbooksGrey } from 'src/icons/specialLogos/quickbooksGrey.svg';
import { ReactComponent as Visa } from 'src/icons/specialLogos/visa.svg';
import { ReactComponent as VisaGrey } from 'src/icons/specialLogos/visaGrey.svg';
import { BrandName } from './consts';

type BrandFullLogoProps = {
  name: BrandName;
};

const SPECIAL_LOGO_MAP = {
  [BrandName.amexGray]: <AmexGrey />,
  [BrandName.amex]: <Amex />,
  [BrandName.creditKey]: <CreditKey />,
  [BrandName.creditKeyGrey]: <CreditKeyGrey />,
  [BrandName.discover]: <Discover />,
  [BrandName.discoverGray]: <DiscoverGrey />,
  [BrandName.diners]: <Diners />,
  [BrandName.dinersGray]: <DinersGrey />,
  [BrandName.evolve]: <Evolve />,
  [BrandName.evolveGray]: <EvolveGrey />,
  [BrandName.intuit]: <Intuit />,
  [BrandName.freshbooks]: <Freshbooks />,
  [BrandName.masterCard]: <Mastercard />,
  [BrandName.masterCardGray]: <MastercardGrey />,
  [BrandName.pciDss]: <Pci />,
  [BrandName.pciGray]: <PciGrey />,
  [BrandName.quickbooks]: <Quickbooks />,
  [BrandName.quickbooksGray]: <QuickbooksGrey />,
  [BrandName.visa]: <Visa />,
  [BrandName.visaGray]: <VisaGrey />,
};

const BrandFullLogo = ({ name = BrandName.default }: BrandFullLogoProps) =>
  name === BrandName.default ? null : SPECIAL_LOGO_MAP[name];
export { BrandFullLogo };
