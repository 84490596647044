import * as brazeSDK from '@braze/web-sdk';
import {
  Card,
  EngagementCustomAttributesEnum,
  EngagementPartnerNameEnum,
  EngagementService,
  EngagementServiceContactInfo,
  UserAttribute,
} from 'src/services/engagement/types';

export class Braze implements EngagementService {
  private initialized = false;

  public constructor(config) {
    this.initialized = brazeSDK.initialize(config.services.braze.apiKey, config.services.braze.options);
    brazeSDK.automaticallyShowInAppMessages();
  }

  public identify = (userId: string, contactInfo: EngagementServiceContactInfo) => {
    if (this.initialized) {
      brazeSDK.changeUser(userId);
      this.setUserAttribute(EngagementCustomAttributesEnum.PARTNER_NAME, EngagementPartnerNameEnum.MELIO);
      this.updateContactInfo(contactInfo);
      brazeSDK.openSession();
    }
  };

  public logCustomEvent = (eventName: string, eventProperties?: Record<string, any>) => {
    if (!this.initialized) {
      return false;
    }

    const result = brazeSDK.logCustomEvent(eventName, eventProperties);
    brazeSDK.requestImmediateDataFlush();

    return result;
  };

  public showContentCards = (parentNode?: Element, filterFunction?) => {
    if (this.initialized) {
      brazeSDK.showContentCards(parentNode, filterFunction);
    }
  };

  public setUserAttribute(key: string, value: UserAttribute) {
    if (this.initialized) {
      brazeSDK.getUser()?.setCustomUserAttribute(key, value);
      brazeSDK.requestImmediateDataFlush();
    }
  }

  public getContentCards = (): Promise<Card[]> =>
    new Promise((resolve, reject) => {
      if (!this.initialized) {
        reject(new Error('Braze service was not initialized'));
      }

      brazeSDK.subscribeToContentCardsUpdates((event) => {
        const cards = event.cards as Card[];
        resolve(cards);
      });
      brazeSDK.requestContentCardsRefresh();
    });

  public logCardClick = (card) => {
    if (this.initialized) {
      brazeSDK.logCardClick(card, true);
    }
  };

  public logCardImpressions = (card) => {
    if (this.initialized) {
      brazeSDK.logCardImpressions([card], true);
    }
  };

  public dismissContentCard = (card) => {
    if (this.initialized) {
      brazeSDK.logCardDismissal(card);
    }
  };

  public closeInAppMessages = () => {
    const iframes = document.querySelectorAll<HTMLIFrameElement>('iframe.ab-start-hidden');
    iframes.forEach((iframe) => {
      iframe.contentWindow?.appboyBridge?.closeMessage();
    });
  };

  public updateContactInfo = ({ firstName, lastName, email }: EngagementServiceContactInfo) => {
    if (firstName && lastName && email) {
      const brazeUser = brazeSDK.getUser();

      brazeUser?.setFirstName(firstName);
      brazeUser?.setLastName(lastName);
      brazeUser?.setEmail(email);
    }
  };
}
