import { BatchUploadReviewPage } from 'src/components/common/BatchUpload/BatchUploadReviewPage';
import { MIDataTable } from 'src/components/common/MIDataTable';
import { headers } from './constants';
import { InvoiceFromCsv, Navigation } from './types';

type Props = {
  invoices: InvoiceFromCsv[];
  isLoading: boolean;
} & Navigation;

export const InvoicesReviewPage = ({ onNext, onPrev, onExit, invoices, isLoading }: Props) => (
  <BatchUploadReviewPage
    title="getPaid.new.create.batch.review.title"
    label="getPaid.new.create.batch.review.confirmButton"
    onNext={onNext}
    onPrev={onPrev}
    onExit={onExit}
    isLoading={isLoading}
  >
    <MIDataTable data={invoices} headers={headers} />
  </BatchUploadReviewPage>
);
