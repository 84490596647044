import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { financingStore } from 'src/modules/financing/financing-store';
import { useInstallmentsOptionsRequest } from 'src/pages/bill/pay/installments/pages/installments-options/useInstallmentsOptionsRequest';
import { useSetOptionOnRepaymentTerms } from 'src/pages/bill/pay/installments/pages/repayment-terms/hooks/useSetOptionOnRepaymentTerms';
import { selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { getInitialProcessingDates } from 'src/utils/dates';

export const useRepaymentTermsPageData = (setIsScheduledDateLoading) => {
  const orgId = useSelector(getOrgId);
  const bill = useSelector(getBill);
  const vendorId = bill?.vendorId;
  const payment = useSelector(getPayment);
  const { dueDate } = useSelector(getBill);
  const { deliveryMethodId, scheduledDate, amount, id: paymentId, payBillFlowUUID } = payment;

  const dispatch = useDispatch();
  const { setOption } = useSetOptionOnRepaymentTerms();

  const { installmentsOptions, getInstallmentOptions } = useInstallmentsOptionsRequest();
  const { data: result } = useSelector(financingStore.selectors.setInstallmentOption.status);
  const intentId = useSelector(financingStore.selectors.intentIdWithInstallmentOption.intentId);
  const areDatesFetched = useRef<boolean>(false);
  const isOptionSet = useRef<boolean>(false);

  const firstInstallmentDate = result?.installments[0]?.scheduledDate;
  const shouldFetchInstallmentsDates =
    scheduledDate && !firstInstallmentDate && !isOptionSet.current && installmentsOptions.options && intentId;
  const shouldFetchDates = !scheduledDate && !areDatesFetched.current;

  useEffect(() => {
    if (isEmpty(installmentsOptions) && vendorId) {
      getInstallmentOptions({ orgId, vendorId, paymentAmount: amount });
    }
  }, [orgId, getInstallmentOptions, installmentsOptions, vendorId, amount]);

  useEffect(() => {
    if (shouldFetchDates) {
      areDatesFetched.current = true;
      setIsScheduledDateLoading(true);
      getInitialProcessingDates({
        orgId,
        deliveryMethodId,
        fundingSourceId: payment.fundingSourceId,
        scheduledDate,
        amount,
        dueDate,
        paymentId,
        payBillFlowUUID,
        isFinancingPayment: true,
      }).then((dates) => {
        dispatch(selectPaymentDatesAction(dates.suggestedScheduledDate, dates.deliveryDate, dates.maxDeliveryDate));
        setIsScheduledDateLoading(false);
      });
    }
  }, [
    orgId,
    deliveryMethodId,
    payment.fundingSourceId,
    scheduledDate,
    shouldFetchDates,
    amount,
    dueDate,
    paymentId,
    payBillFlowUUID,
    dispatch,
    setIsScheduledDateLoading,
    setOption,
    installmentsOptions.options,
  ]);

  useEffect(() => {
    if (shouldFetchInstallmentsDates) {
      isOptionSet.current = true;
      setOption(scheduledDate, installmentsOptions.options[0].numberOfInstallments);
    }
  }, [scheduledDate, shouldFetchInstallmentsDates, installmentsOptions.options]);
};
