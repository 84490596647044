import { IconNames } from 'src/core/ds/icon';
import colors from 'src/core/theme/foundations/colors';
import { ThemeType } from 'src/theme/global-theme';
import { isVirtualCardExpired } from 'src/utils/bills';
import {
  isFinancedPayment,
  isPaymentCanceled,
  isPaymentCompletedRefund,
  isPaymentFailedRefund,
  isPaymentScheduleRefund,
} from 'src/utils/payments';
import { BillStatus, PaymentApprovalStatus, PaymentStatus, TagVariant } from './consts';
import { hasFailedInstallment } from './financing';
import { PaymentType, StatusInfoType } from './types';

type StatusVerboseType = {
  status: BillStatus | TagVariant | PaymentStatus | PaymentApprovalStatus | undefined;
};

export function getScheduledStyling(theme: ThemeType) {
  return {
    color: theme.text.color.blue,
    colorOpacity: theme.colors.progress.scheduled,
    label: 'bills.status.paymentScheduled',
    icon: 'icon-scheduled-bill',
    description: '',
  };
}

const getStatusInfo = (
  statusVerbose: StatusVerboseType,
  theme: ThemeType,
  payment?: PaymentType | null,
  isManuallyPaid = false,
  isRequestAndHasNotDeliveryMethods = false,
  vendorName = ''
): StatusInfoType | null | undefined => {
  let statusInfo;
  const { status } = statusVerbose;
  const isCanceledFinancingPayment = !!payment && isFinancedPayment(payment) && isPaymentCanceled(payment);
  const isExpiredVirtualCard = payment && isVirtualCardExpired(payment);
  const isFailedRefund = payment ? isPaymentFailedRefund(payment) : false;
  const isRefundScheduled = payment ? isPaymentScheduleRefund(payment) : false;
  const isRefundCompleted = payment ? isPaymentCompletedRefund(payment) : false;
  const isFailedInstallment = payment ? hasFailedInstallment(payment) : false;

  const getFailedPaymentLabel = () => {
    if (isCanceledFinancingPayment) return 'bills.status.paymentCanceled';

    if (isExpiredVirtualCard) return 'bills.status.cardExpired';

    if (isFailedRefund) return 'bills.status.paymentRefundFailed.title';

    if (isRefundCompleted) return 'bills.status.paymentRefundCompleted.title';

    if (isRefundScheduled) return 'bills.status.paymentRefundScheduled.refundSent';

    if (isFailedInstallment) return 'bills.status.financingFailedToCollectInstallment';

    return 'bills.status.paymentFailed';
  };

  const getFailedPaymentColorOpacity = () => {
    if (isRefundCompleted) return theme.colors.success.translucent1;

    if (isRefundScheduled) return theme.colors.white.veryLightGrey;

    return theme.colors.failure.translucent1;
  };

  const getFailedPaymentTextColor = () => {
    if (isRefundCompleted) return theme.colors.success.opaque;

    if (isRefundScheduled) return theme.colors.text;

    return colors.red['500'];
  };

  const getFailedPaymentIcon = () => {
    if (isExpiredVirtualCard) return IconNames.declineFill;

    if (isRefundCompleted) return IconNames.checkFill;

    if (isRefundScheduled) return IconNames.processFill;

    return IconNames.cancelFill;
  };

  const getPaidLabel = () => {
    if (isManuallyPaid) return 'bills.status.paymentManuallyPaid';

    if (isRefundCompleted) return 'bills.status.paymentRefundCompleted.title';

    if (isRefundScheduled) return 'bills.status.paymentRefundScheduled.refundSent';

    return 'bills.status.paymentPaid';
  };

  switch (status) {
    case BillStatus.SCHEDULED:
    case PaymentStatus.SCHEDULED:
      statusInfo = {
        status,
        ...getScheduledStyling(theme),
      };
      break;

    case PaymentStatus.IN_PROGRESS:
      statusInfo = {
        status,
        color: theme.colors.text,
        colorOpacity: theme.colors.white.veryLightGrey,
        label: 'bills.status.paymentProcessed',
        iconName: IconNames.processFill,
        description: '',
      };
      break;

    case BillStatus.UNPAID:
      if (isRequestAndHasNotDeliveryMethods) {
        statusInfo = {
          status,
          color: theme.colors.failure.opaque,
          colorOpacity: theme.colors.failure.translucent1,
          label: 'bills.status.cantSchedulePayment.title',
          icon: 'icon-notification-icon',
          description: 'bills.status.cantSchedulePayment.description',
          descriptionValues: {
            vendorName,
          },
        };
      } else {
        statusInfo = null;
      }

      break;

    case PaymentStatus.FAILED:
      statusInfo = {
        status,
        color: getFailedPaymentTextColor(),
        colorOpacity: getFailedPaymentColorOpacity(),
        label: getFailedPaymentLabel(),
        iconName: getFailedPaymentIcon(),
      };
      break;

    case PaymentApprovalStatus.PENDING:
      statusInfo = {
        status,
        color: theme.colors.warning.opaque,
        colorOpacity: theme.colors.warning.translucent1,
        label: 'bills.status.paymentPendingApproval',
        icon: 'icon-payment-pending-approval',
      };
      break;

    case PaymentApprovalStatus.DECLINED:
      statusInfo = {
        status,
        color: theme.colors.failure.opaque,
        colorOpacity: theme.colors.failure.translucent1,
        label: 'bills.status.paymentDeclined',
        icon: 'icon-decline-icon',
      };
      break;

    default:
      statusInfo = {
        status,
        color: theme.colors.success.opaque,
        colorOpacity: theme.colors.success.translucent1,
        label: getPaidLabel(),
        icon: 'icon-full-checkmark-icon',
      };
      break;
  }

  return statusInfo;
};

export { getStatusInfo };
