/* eslint-disable max-len */
import Box from 'src/core/ds/box/Box';
import { LogoFillType } from 'src/utils/consts';

const SIZES_FULL = {
  regular: {
    width: '11.2rem',
    height: '4.2rem',
    viewBox: '0 0 84 42',
  },
  mobile: {
    width: '11.2rem',
    height: '3.5rem',
    viewBox: '0 0 80 42',
  },
  mobileSmall: {
    width: '6rem',
    height: '2.4rem',
    viewBox: '0 0 92 42',
  },
  qb: {
    width: '7rem',
    height: '2.8rem',
    viewBox: '0 0 92 42',
  },
};

const SIZES_SHORT = {
  regular: {
    width: '3.4rem',
    height: '4rem',
    viewBox: '0 -2 32 42',
  },
  mobile: {
    width: '3.4rem',
    height: '4rem',
    viewBox: '0 0 34 42',
  },
  mobileSmall: {
    width: '3.4rem',
    height: '4rem',
    viewBox: '0 0 34 42',
  },
  qb: {
    width: '3.4rem',
    height: '4rem',
    viewBox: '0 0 34 42',
  },
};

const COLORS = {
  melio: 'rgba(114, 68, 246, 1)',
  dark: 'rgba(33, 33, 36, 1)',
  light: 'rgba(153, 153, 156, 1)',
  white: 'rgba(255,255,255,1)',
  qb: 'rgba(113, 113, 115)',
};

type Props = {
  isFull?: boolean;
  size?: 'regular' | 'mobile' | 'qb' | 'mobileSmall';
  fill?: LogoFillType;
};

export const Logo = ({ size, fill, isFull }: Props) => {
  const passedProps = {
    size: size || 'regular',
    fill: fill || LogoFillType.MELIO,
  };

  return isFull ? <FullLogo {...passedProps} /> : <ShortLogo {...passedProps} />;
};

Logo.defaultProps = {
  isFull: true,
  size: 'regular',
  fill: LogoFillType.MELIO,
};

type FullLogoProps = {
  size: 'regular' | 'mobile' | 'qb' | 'mobileSmall';
  fill: LogoFillType;
};

const FullLogo = ({ size, fill }: FullLogoProps) => {
  const style1 = {
    fill: COLORS[fill],
    transition: '150ms',
  };
  const svgProps = SIZES_FULL[size];

  return (
    <Box css="line-height:0;">
      <svg {...svgProps} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1373_90467)">
          <path
            style={style1}
            d="M47.6226 20.6345C47.8004 20.6345 47.95 20.5014 47.9758 20.3218C48.0518 19.7939 48.0892 19.373 48.0892 18.8236C48.0892 17.3718 47.8249 16.1174 47.3034 14.8593C46.7843 13.6037 46.2067 12.549 45.2982 11.5978C44.3897 10.6466 43.4239 9.88913 42.1951 9.34336C40.9662 8.79878 39.859 8.52173 38.443 8.52173C37.0271 8.52173 35.686 8.79878 34.4571 9.34336C33.2294 9.88913 32.1455 10.6466 31.237 11.5978C30.3297 12.5467 29.5756 13.6013 29.0541 14.8593C28.5338 16.1162 28.2695 17.4907 28.2695 18.9425C28.2695 20.3944 28.5338 21.5394 29.0541 22.8105C29.5756 24.0816 30.2993 25.2017 31.2066 26.1363C32.114 27.072 33.1967 27.8235 34.4267 28.3693C35.6556 28.9139 36.9967 29.1909 38.4126 29.1909C39.8285 29.1909 41.4035 28.9139 42.6324 28.3693C43.8624 27.8235 44.9451 27.072 45.8524 26.1363C46.2313 25.7463 46.5774 25.3241 46.8907 24.8723C47.0065 24.7058 46.9655 24.4752 46.7983 24.361L43.8133 22.3266C43.6473 22.2136 43.4251 22.2635 43.3152 22.4336C43.1281 22.7261 42.9153 22.996 42.6803 23.2433C42.1962 23.751 41.6105 24.1541 40.937 24.4443C40.2635 24.7332 39.2849 24.8794 38.4898 24.8794H38.3974C37.6035 24.8794 36.8587 24.7332 36.1852 24.4443C35.5118 24.1541 34.9248 23.751 34.4419 23.2433C33.9567 22.7344 33.5697 22.1244 33.2925 21.4324C33.192 21.1803 33.1078 20.9128 33.0435 20.6345H47.6238H47.6226ZM33.2914 16.0151C33.5685 15.3219 33.9555 14.7131 34.4407 14.203C34.9236 13.6965 35.5106 13.2922 36.1841 13.0032C36.8575 12.7143 37.6023 12.5681 38.3962 12.5681H38.2548C39.0498 12.5681 39.7946 12.7143 40.4681 13.0032C41.1416 13.2922 41.7274 13.6965 42.2114 14.203C42.6967 14.7131 43.0837 15.3219 43.3608 16.0151C43.4614 16.2672 43.5444 16.5347 43.6098 16.813H33.0423C33.1066 16.5347 33.1908 16.2672 33.2914 16.0151V16.0151Z"
            fill="#99999C"
          />
          <path
            style={style1}
            d="M54.7158 0.900024H51.0117C50.8154 0.900024 50.6562 1.06186 50.6562 1.26149V28.0519C50.6562 28.2515 50.8154 28.4134 51.0117 28.4134H54.7158C54.9121 28.4134 55.0713 28.2515 55.0713 28.0519V1.26149C55.0713 1.06186 54.9121 0.900024 54.7158 0.900024Z"
            fill="#99999C"
          />
          <path
            style={style1}
            d="M61.2353 3.06639C61.1757 2.99742 61.1067 2.9344 61.0272 2.8916C60.6858 2.70373 60.2941 2.79291 60.0626 3.05925L57.7651 5.70726C57.5581 5.94507 57.5382 6.28989 57.7323 6.56099C57.8574 6.73697 58.0667 6.82853 58.2807 6.82853H63.0126C63.2277 6.82853 63.4382 6.73459 63.5633 6.55743C63.7539 6.28632 63.7328 5.94388 63.527 5.70726L61.2365 3.06639H61.2353Z"
            fill="#99999C"
          />
          <path
            style={style1}
            d="M62.4971 9.29944H58.7929C58.5966 9.29944 58.4375 9.46127 58.4375 9.66091V28.0519C58.4375 28.2515 58.5966 28.4134 58.7929 28.4134H62.4971C62.6934 28.4134 62.8525 28.2515 62.8525 28.0519V9.66091C62.8525 9.46127 62.6934 9.29944 62.4971 9.29944Z"
            fill="#99999C"
          />
          <path
            style={style1}
            d="M18.4201 8.82617C16.3552 8.82617 14.5359 9.65613 13.01 11.2958C11.4842 9.65732 9.66486 8.82617 7.6 8.82617C5.53514 8.82617 3.58369 9.65969 2.16542 11.1745C0.769354 12.668 0 14.6727 0 16.8213V28.0519C0 28.2516 0.159015 28.4133 0.355446 28.4133H4.02449C4.22092 28.4133 4.37994 28.2516 4.37994 28.0519V16.8213C4.37994 15.8499 4.69797 14.9557 5.2744 14.3029C5.86136 13.6382 6.688 13.2708 7.6 13.2708C8.512 13.2708 9.33748 13.6371 9.9256 14.3029C10.502 14.9557 10.8201 15.8499 10.8201 16.8213V28.0519C10.8201 28.2516 10.9791 28.4133 11.1755 28.4133H14.8457C15.0422 28.4133 15.2012 28.2516 15.2012 28.0519V16.8213C15.2012 15.8511 15.5192 14.9557 16.0956 14.3029C16.6838 13.6382 17.5092 13.2708 18.4212 13.2708C19.3332 13.2708 20.1587 13.6371 20.7468 14.3029C21.3233 14.9557 21.6413 15.8499 21.6413 16.8213V28.0519C21.6413 28.2516 21.8003 28.4133 21.9967 28.4133H25.6658C25.8622 28.4133 26.0212 28.2516 26.0212 28.0519V16.8213C26.0212 14.6727 25.2519 12.668 23.8558 11.1745C22.4387 9.65969 20.5083 8.82617 18.4212 8.82617H18.4201Z"
            fill="#99999C"
          />
          <path
            style={style1}
            d="M77.8949 31.512H73.163C72.9502 31.512 72.7398 31.6047 72.6147 31.7795C72.4217 32.0506 72.4405 32.3954 72.6474 32.6332L74.9379 35.2741C74.9976 35.3431 75.0666 35.4061 75.1461 35.4489C75.4875 35.6368 75.8792 35.5476 76.1107 35.2812L78.4082 32.6332C78.614 32.3966 78.635 32.0542 78.4445 31.7831C78.3193 31.6047 78.1089 31.512 77.8938 31.512H77.8949Z"
            fill="#99999C"
          />
          <path
            style={style1}
            d="M84.8472 14.8308C84.335 13.593 83.6206 12.4896 82.725 11.5526C81.8294 10.6156 80.7619 9.86773 79.5517 9.33147C78.3404 8.79402 77.018 8.52173 75.6231 8.52173C74.2282 8.52173 72.9046 8.79402 71.6945 9.33147C70.4843 9.86892 69.4156 10.6156 68.52 11.5526C67.6256 12.4884 66.9123 13.5906 66.3979 14.8308C65.8846 16.0698 65.625 17.4241 65.625 18.8557C65.625 20.2873 65.8846 21.65 66.3979 22.9021C66.9112 24.1553 67.6256 25.2587 68.52 26.1814C69.4145 27.1041 70.482 27.8437 71.6933 28.3812C72.9046 28.9186 74.227 29.1909 75.6219 29.1909C77.0168 29.1909 78.3392 28.9186 79.5505 28.3812C80.7619 27.8437 81.8305 27.103 82.725 26.1814C83.6206 25.2576 84.3339 24.1541 84.8472 22.9021C85.3604 21.6488 85.62 20.2873 85.62 18.8557C85.62 17.4241 85.3593 16.071 84.846 14.8308H84.8472ZM70.2271 18.8557C70.2271 17.9889 70.3557 17.178 70.6083 16.4455C70.8608 15.7131 71.2209 15.0793 71.6816 14.5621C72.1411 14.0448 72.7105 13.6311 73.3758 13.3314C74.0411 13.0318 74.7976 12.8784 75.6231 12.8784C76.4486 12.8784 77.2039 13.0306 77.8704 13.3314C78.5368 13.6323 79.1062 14.046 79.5657 14.5621C80.0252 15.0781 80.3854 15.7119 80.6391 16.4455C80.8916 17.1804 81.0203 17.9913 81.0203 18.8557C81.0203 19.7202 80.8928 20.5323 80.6391 21.2659C80.3854 21.9996 80.0241 22.6333 79.5657 23.1494C79.1074 23.6666 78.5368 24.0804 77.8704 24.38C77.2062 24.6797 76.4497 24.8331 75.6231 24.8331C74.7965 24.8331 74.04 24.6809 73.3758 24.38C72.7105 24.0804 72.14 23.6654 71.6816 23.1494C71.2221 22.6321 70.8608 21.9996 70.6094 21.2659C70.3569 20.5347 70.2283 19.7237 70.2283 18.8557H70.2271Z"
            fill="#99999C"
          />
        </g>
      </svg>
    </Box>
  );
};

type ShortLogoProps = {
  size: 'regular' | 'mobile' | 'qb' | 'mobileSmall';
  fill: LogoFillType;
};

const ShortLogo = ({ size, fill }: ShortLogoProps) => {
  const style1 = {
    fill: COLORS[fill],
    transition: '150ms',
  };
  const svgProps = SIZES_SHORT[size];

  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" {...svgProps}>
        <path
          style={style1}
          d="M4.58576 2.30965C4.51494 2.23145 4.43488 2.16228 4.34251 2.11265C3.94222 1.90062 3.48342 2.00137 3.21246 2.30213L0.519734 5.28262C0.27802 5.5503 0.254927 5.93827 0.481245 6.24354C0.627504 6.44053 0.873837 6.5458 1.12325 6.5458H6.66881C6.91976 6.5458 7.16763 6.44054 7.31389 6.24053C7.53713 5.93526 7.5125 5.5503 7.27078 5.28262L4.58576 2.30965Z"
        />
        <path
          style={style1}
          d="M6.07513 9.1434H1.71772C1.48637 9.1434 1.29883 9.32512 1.29883 9.54927V30.1677C1.29883 30.3919 1.48637 30.5736 1.71772 30.5736H6.07513C6.30648 30.5736 6.49403 30.3919 6.49403 30.1677V9.54927C6.49403 9.32512 6.30648 9.1434 6.07513 9.1434Z"
        />
        <path
          style={style1}
          d="M23.8783 33.8206H18.3323C18.0829 33.8206 17.8365 33.9243 17.6902 34.1228C17.4639 34.4281 17.487 34.8161 17.7287 35.0838L20.414 38.0567C20.4848 38.1349 20.5649 38.2041 20.6573 38.2537C21.0576 38.4658 21.5164 38.365 21.7874 38.0643L24.4804 35.0838C24.7221 34.8176 24.7452 34.4326 24.5235 34.1259C24.3772 33.9259 24.1293 33.8206 23.8783 33.8206V33.8206Z"
        />
        <path
          style={style1}
          d="M31.5898 15.4318C31.0074 14.0701 30.1951 12.8582 29.1783 11.827C28.16 10.7973 26.946 9.97469 25.5708 9.38417C24.1941 8.79365 22.6905 8.49399 21.1047 8.49399C19.519 8.49399 18.0154 8.79365 16.6387 9.38417C15.262 9.97469 14.048 10.7973 13.0312 11.827C12.0143 12.8553 11.2035 14.0686 10.6197 15.4318C10.0359 16.795 9.74023 18.283 9.74023 19.8578C9.74023 21.4325 10.0359 22.9293 10.6197 24.3072C11.2035 25.6851 12.0143 26.8984 13.0312 27.9135C14.048 28.927 15.262 29.7423 16.6387 30.3328C18.0154 30.9233 19.519 31.223 21.1047 31.223C22.6905 31.223 24.1941 30.9233 25.5708 30.3328C26.9475 29.7408 28.1614 28.927 29.1783 27.9135C30.1966 26.8969 31.0074 25.6836 31.5898 24.3072C32.1736 22.9293 32.4692 21.4325 32.4692 19.8578C32.4692 18.283 32.1736 16.795 31.5898 15.4318ZM14.9708 19.8578C14.9708 18.9044 15.1171 18.0128 15.4038 17.2078C15.6905 16.4013 16.1011 15.705 16.6237 15.1366C17.1449 14.5681 17.7944 14.1127 18.5499 13.7822C19.307 13.4517 20.167 13.2842 21.1047 13.2842C22.0424 13.2842 22.9025 13.4517 23.6595 13.7822C24.4166 14.1127 25.0646 14.5681 25.5857 15.1366C26.1068 15.705 26.5175 16.4013 26.8056 17.2078C27.0923 18.0157 27.2387 18.9073 27.2387 19.8578C27.2387 20.8082 27.0938 21.7013 26.8056 22.5077C26.5175 23.3142 26.1068 24.0119 25.5857 24.5789C25.0646 25.1474 24.4166 25.6028 23.6595 25.9318C22.904 26.2624 22.0454 26.4298 21.1047 26.4298C20.164 26.4298 19.3055 26.2624 18.5499 25.9318C17.7929 25.6013 17.1449 25.146 16.6237 24.5789C16.1011 24.0105 15.6905 23.3142 15.4038 22.5077C15.1156 21.7028 14.9708 20.8111 14.9708 19.8578V19.8578Z"
        />
      </svg>
    </div>
  );
};

ShortLogo.defaultProps = {};
