import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { Input, PrivateDataContainer } from 'src/core/ds/input';
import { FlowCustomizationsType } from 'src/flows/add-bank-account-flow/types';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { useRefreshFundingSources } from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { vendorsApi } from 'src/modules/vendors/api';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import { BankAccountType, DbAnalyticsTraits, DeliveryType, NotificationVariant } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { BankType } from 'src/utils/types';
import { getLabels, validateBankAccount } from './utils';

type Props = {
  vendorId?: string;
  isDeliveryMethod: boolean;
  goNext: () => void;
  cancelFlow: () => void;
  flowCustomizations?: FlowCustomizationsType;
  isManuallyFromPlaid: boolean;
};

export const AddManuallyBankAccountPage = ({
  vendorId,
  isDeliveryMethod,
  goNext,
  isManuallyFromPlaid = false,
  cancelFlow,
  flowCustomizations,
}: Props) => {
  const eventPage = `add-${isDeliveryMethod ? 'delivery-method' : 'funding-source'}`;
  const { onFSBankAccountAdded, flowEntryPoint } = flowCustomizations || {};
  const intl = useIntl();
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const { onApiCall: requestMicroDeposit, loading: isLoading, error } = useApi({
    api: financialAccountsApi.requestMicroDeposit,
  });
  const { onApiCall: getVendorsDeliveryMethods, loading: isValidating } = useApi({
    api: vendorsApi.getVendorsDeliveryMethods,
  });
  const { onApiCall: addDeliveryMethod, loading: isSubmittingDM } = useApi({
    api: deliveryMethodsApi.addDeliveryMethod,
  });
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  const model = useMemo(
    () => ({
      routingNumber: '',
      accountNumber: '',
    }),
    []
  );

  const refreshDeliveryMethods = () =>
    new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });

  const onSubmitBankAccountFundingSource = async (value) => {
    const bank = {
      ...value,
      accountType: BankAccountType.CHECKING,
    };
    const validationErrors = await validateBankAccount(orgId, bank, getVendorsDeliveryMethods, intl);

    if (isEmpty(validationErrors)) {
      analytics.track(eventPage, 'funding-source-added-manually');
      try {
        const result = await requestMicroDeposit(orgId, { bank });
        onMicroDepositCreated(result);
      } catch (e) {
        capture(e as Error);
      }
    } else {
      throw new ValidationError({ validationErrors });
    }
  };

  const onSubmitBankAccountReceivingMethod = async (data: Partial<BankType>) => {
    const bankAccount = {
      ...data,
      accountType: BankAccountType.CHECKING,
    };
    const validationErrors = getValidationErrors('deliveryMethodAch', bankAccount, ['routingNumber', 'accountNumber']);

    if (!isValidationOk(validationErrors)) {
      throw new ValidationError({ validationErrors });
    }

    try {
      analytics.track(eventPage, 'ach-receiving-method', {
        type: DeliveryType.ACH,
      });
      analytics.setTraits({
        [DbAnalyticsTraits.ADDED_DELIVERY_METHOD]: true,
      });
      const deliveryMethod = {
        bankAccount,
        deliveryType: DeliveryType.ACH,
        isFilledByVendor: true,
      };
      await addDeliveryMethod({
        orgId,
        vendorId,
        params: deliveryMethod,
      });

      await refreshDeliveryMethods();
      analytics.track(eventPage, 'ach-receiving-method-success', {
        type: DeliveryType.ACH,
      });
      goNext();
    } catch (error: any) {
      analytics.track(eventPage, 'ach-receiving-method-failure', {
        type: DeliveryType.ACH,
        error,
      });
    }
  };

  const onMicroDepositCreated = useCallback(
    async ({ fundingSource }) => {
      await refreshFundingSources();
      analytics.setTraits({ [DbAnalyticsTraits.ADDED_FUNDING]: true });
      analytics.setFundingSourceTraits();
      analytics.track(eventPage, 'added-manually-success');
      analytics.trackMqlEvent('added-funding', 'mql');
      analytics.trackMqlEvent('added-funding-manual', 'mql');

      onFSBankAccountAdded && onFSBankAccountAdded(fundingSource.id);
      goNext();
    },
    [goNext, refreshFundingSources, onFSBankAccountAdded]
  );

  useEffect(() => {
    if (error) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: `server.${error.code}`,
      });
    }
  }, [error]);

  useEffect(() => {
    analytics.page(eventPage, 'bank-manually', { isNewPlaidMDRecoveryFlow: isManuallyFromPlaid, flowEntryPoint });
  }, []);

  const [bankAccountVM, { submit }] = useForm(model, {
    submit: isDeliveryMethod ? onSubmitBankAccountReceivingMethod : onSubmitBankAccountFundingSource,
    onChange: ({ key, value, modelState }) => {
      const regex = /(^[0-9]+$|^$)/;

      if (key === 'accountNumber' && !regex.test(value)) {
        return { ...modelState, accountNumber: value.slice(0, -1) };
      }

      return modelState;
    },
  });

  const { title, subtitle, footer } = getLabels(isManuallyFromPlaid);

  return (
    <StepLayoutPage
      title={title}
      subtitle={subtitle}
      goExit={cancelFlow}
      onSubmit={submit}
      nextLabel="flows.addBankAccount.manually.completeAndSave"
      isLoading={isLoading || isFundingSourcesRefreshing || isValidating || isSubmittingDM}
      footer={
        <Box>
          <WizardElements.SimpleTextFooterSlim>
            <MIFormattedText label={footer} />
          </WizardElements.SimpleTextFooterSlim>
          {isManuallyFromPlaid ? (
            <Box mt={6}>
              <WizardElements.WizardOrLine />
              <Button
                label="flows.addBankAccount.manually.goBack"
                onClick={cancelFlow}
                variant={ButtonVariants.tertiaryNaked}
              />
            </Box>
          ) : null}
        </Box>
      }
      hideHeader
      fullWidthCTA
    >
      <WizardFormContainer>
        <PrivateDataContainer>
          <TextField
            testId="input-routingNumber"
            model={bankAccountVM.routingNumber}
            label="flows.addBankAccount.manually.inputs.routingNumber"
            type="tel"
            autoFocus
            isRequired
          />
        </PrivateDataContainer>

        <PasswordField
          data-private
          testId="input-accountNumber"
          model={bankAccountVM.accountNumber}
          label="flows.addBankAccount.manually.inputs.accountNumber"
          isRequired
          shouldShowValue
        />
        <Input type="password" autoComplete="new-password" display="none" />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};
