import isEmpty from 'lodash/isEmpty';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';
import { useDisclosure } from 'src/core/ds/hooks';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { InstallmentsList } from 'src/pages/bill/components/InstallmentsList/InstallmentsList';
import { isScheduledInstallment } from 'src/utils/financing';
import { Installment, PaymentFinancing } from 'src/utils/types';
import { CardThemes } from '../ViewBillPaymentActivity';
import { NoInstallmentsInfo } from './NoInstallmentsInfo';

type Props = {
  financing?: PaymentFinancing;
  showInstallmentsBadges: boolean;
  viewDetailsLink?: string;
  theme?: CardThemes;
  label?: string;
};

const getDeductionDate = (installments: Installment[]) => {
  const numberOfInstallments = installments.length;
  let deductionDate;

  const scheduledInstallmentIndex = installments.findIndex(isScheduledInstallment);

  if (scheduledInstallmentIndex !== -1) {
    deductionDate = installments[scheduledInstallmentIndex].scheduledDate;
  } else if (numberOfInstallments > 0) {
    deductionDate = installments[numberOfInstallments - 1].scheduledDate;
  }

  return deductionDate;
};

export const InstallmentsDeductionDatesInfo = ({
  financing,
  viewDetailsLink,
  theme = CardThemes.Black,
  showInstallmentsBadges,
  label = 'financing.firstInstallmentDeductsLabel',
}: Props) => {
  const { isOpen: isCollapsed, onToggle } = useDisclosure();
  const hasLoadedInstallments = financing && !isEmpty(financing.installments);

  if (!hasLoadedInstallments) return <NoInstallmentsInfo theme={theme} />;

  const { isTerminated, installments } = financing;
  const deductionDate = getDeductionDate(installments);

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon isBillPaid={theme === CardThemes.Grey}>
            <Icon name={IconNames.scheduled} size={IconSize.lg} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={label} />
            </PaymentMethodText>
            {!isTerminated && (
              <PaymentInfoText isBillPaid={theme === CardThemes.Grey} data-testId="financing-deduction-date">
                <MIFormattedDate date={deductionDate} />
              </PaymentInfoText>
            )}
          </PaymentTextContainer>
        </PaymentInfoContainer>
      </PaymentActivityMainContainer>
      <PaymentActivityMainContainer>
        <PaymentIcon />
        <Box w="full" marginTop={1}>
          <InstallmentsList
            financing={financing}
            isCollapsed={isCollapsed}
            onToggle={onToggle}
            viewDetailsLink={viewDetailsLink}
            showBadges={showInstallmentsBadges}
          />
        </Box>
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};
