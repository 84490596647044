import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type BusinessProfileModalLinkProps = {
  onBusinessProfileClick: () => void;
  boxSize?: string;
  testId: string;
};

export const BusinessProfileModalLink = ({
  onBusinessProfileClick,
  boxSize,
  testId,
}: BusinessProfileModalLinkProps) => (
  <Box textStyle="link2" onClick={onBusinessProfileClick} boxSize={boxSize} data-testid={testId}>
    <MIFormattedText label="vendors.businessProfile.title" />
  </Box>
);
