import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { DockedButtonMobile } from 'src/components/mobile/DockedButtonMobile';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { IconNames } from 'src/core/ds/icon';
import { getJWTPayload } from 'src/helpers/jwt';
import { useBreak } from 'src/hoc';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import SettingItemDetails from 'src/pages/settings/components/SettingItemDetails';
import { BackButton, ContentWrapper, Footer } from 'src/pages/vendor/components/VendorLayoutElements';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import { PaymentStatus } from 'src/utils/consts';
import { getDeliveryMethodName } from 'src/utils/delivery-methods';
import { shiftVendorToAchLocations } from './locations';

type Props = {
  token: string;
  vendorId: string;
};

const LinkDeliveryMethodPage = ({ token, vendorId }: Props) => {
  const history = useHistory();
  const { isMobile } = useBreak();
  const { payerCompanyName, paymentId } = getJWTPayload(token);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const deliveryMethodActions = getDeliveryMethodActions(useDispatch());
  const deliveryMethod = orgDeliveryMethods[0];
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const isCurrentPaymentToAch = paymentId && payment?.status === PaymentStatus.SCHEDULED;
  const { invalidTokenData, loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation);
  const orgId = useSelector(getOrgId);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const organization = useSelector(organizationStore.selectors.byId(orgId));
  const isMultiCompany = organizations.length > 1;

  const onClick = async () => {
    await deliveryMethodActions.copyFromOwnedVendor({
      orgId,
      vendorId: ownedVendorId,
      token,
    });
    const path = isCurrentPaymentToAch
      ? shiftVendorToAchLocations.shiftedCurrentPayment
      : shiftVendorToAchLocations.success;
    history.push(generatePath(path, { token, id: vendorId }));
  };

  const deliveryMethodName = getDeliveryMethodName(deliveryMethod);
  const imageObj = { logo: deliveryMethod?.logo };

  const onPrev = () => {
    history.push(generatePath(shiftVendorToAchLocations.switchCompany, { id: vendorId, token }));
  };

  useEffect(() => {
    if (invalidTokenData && invalidTokenData?.code) {
      history.push(generatePath(shiftVendorToAchLocations.invalidToken, { id: vendorId, token }));
    }
  }, [invalidTokenData, history, vendorId, token]);

  return (
    <>
      <OutsideLayout>
        <HeaderContentContainer>
          {isMultiCompany && (
            <ButtonContainer>
              <BackButton onPrev={onPrev} />
            </ButtonContainer>
          )}
          <HeaderTitle>
            <MIFormattedText
              label={
                isCurrentPaymentToAch
                  ? 'vendors.editCheckAddress.shiftAchTitle'
                  : 'vendors.deliveryMethods.shiftVendorToAch.addAchTitle'
              }
              values={{
                companyName: <Label>{payerCompanyName}</Label>,
                totalAmount: (
                  <Label>
                    <MIFormattedCurrency value={payment?.amount} />
                  </Label>
                ),
              }}
            />
          </HeaderTitle>
        </HeaderContentContainer>
        <ContentWrapper
          title="vendors.deliveryMethods.shiftVendorToAch.linkDeliveryMethodTitle"
          titleValues={{
            companyName: isMultiCompany ? <CompanyName>{organization.companyName}</CompanyName> : '',
          }}
        >
          <DeliveryMethodItemContainer>
            <SettingItemDetails
              key={deliveryMethod.id}
              iconName={IconNames.bank}
              imageObj={imageObj}
              descriptionLabel="settings.receivingMethods.achDescription"
              descriptionValues={{
                accountName: deliveryMethodName,
              }}
              nameLabel="settings.receivingMethods.ach"
              moduleName="settings-receiving-methods"
              hideAddItemLink
            />
          </DeliveryMethodItemContainer>
          {!isMobile && (
            <Button
              label="vendors.addDeliveryMethodByLink.linkExistingMethod.submit"
              onClick={onClick}
              isLoading={isLoading}
              size={ButtonSizes.lg}
              isFullWidth
            />
          )}
          <Footer companyName={payerCompanyName} />
        </ContentWrapper>
      </OutsideLayout>
      {isMobile && (
        <DockedButtonMobile
          label="vendors.addDeliveryMethodByLink.linkExistingMethod.submit"
          onClick={onClick}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const HeaderTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 2.4rem;
  line-height: 3.2rem;
`;

const Label = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const CompanyName = styled.span`
  ${(props) => props.theme.text.fontType.regular};
  color: ${(props) => props.theme.text.color.label};
  display: block;
`;

const ButtonContainer = styled.div`
  margin-bottom: 4rem;
`;

const DeliveryMethodItemContainer = styled.div`
  margin: 2rem 0 4rem;
  width: 100%;

  > div {
    text-align: left;
  }
  @media ${devices.mobile} {
    margin: 2rem 0 0rem;
  }
`;

export default LinkDeliveryMethodPage;
