import { useMemo } from 'react';
import { ProgressBar } from 'src/components/payment-progress-bar/ProgressBar';
import { PaymentType } from 'src/utils/types';
import { getCurrentStage, getPaymentProcess } from './utils';

export const PaymentProgressBar = ({ payment }: { payment: PaymentType }) => {
  const paymentProcess = useMemo(() => getPaymentProcess(payment), [payment]);
  const currentStage = useMemo(() => getCurrentStage(payment), [payment]);

  return <ProgressBar paymentProcess={paymentProcess} currentStage={currentStage} />;
};
