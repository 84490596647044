import { reportSessionAnalyticsEvent } from 'src/components/layout/SessionExpired/utils';
import clientServiceApi from 'src/services/api/clientService';

export const sessionStorageUpdateAndReportEventOnLogin = (userId: number, orgId: number) => {
  sessionStorage.setItem('isSessionExpiredActiveTab', 'false');

  if (userId) {
    reportSessionAnalyticsEvent(userId, orgId, 'analytics_melio_pay_login');
  }
};

export const getPasswordValidation = async (key: string, password: string) => {
  if (key === 'password') {
    const { validationErrors } = await clientServiceApi.getPasswordValidationErrors('userRegistration', { password });

    if (validationErrors.password) {
      return validationErrors.password;
    }
  }

  return undefined;
};
