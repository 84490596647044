import { useSelector } from 'react-redux';
import { billsStore } from 'src/modules/bills/bills-store';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import { billFactory } from 'src/pages/bill/records';
import { getCompanyInfo } from 'src/redux/user/selectors';
import {
  convertPaymentRequestToBill,
  deserializePaymentId,
  getPaymentRequestIdById,
  isPaymentRequest,
} from 'src/utils/bills';
import { BillType } from 'src/utils/types';

export const useViewBillData = (
  id: string
): {
  billId: string;
  paymentId: string;
  bill: BillType;
} => {
  const { billId, paymentId } = deserializePaymentId(id);
  const requestId = getPaymentRequestIdById(id);
  const { companyName } = useSelector(getCompanyInfo);
  const paymentRequest = useSelector(paymentRequestsStore.selectors.fetch.byId(requestId));
  const bill = useSelector(billsStore.selectors.fetch.byId(billId));

  if (isPaymentRequest(id)) {
    const bill = paymentRequest
      ? convertPaymentRequestToBill({
          ...paymentRequest,
          companyName,
        })
      : billFactory();

    return {
      billId: requestId,
      paymentId: '',
      bill: billFactory(bill),
    };
  }

  return {
    billId,
    paymentId,
    bill: billFactory(bill),
  };
};
