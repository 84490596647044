import { SortMenuValue } from 'src/pay/components/SortMenu/types';
import { PayTabs } from 'src/utils/consts';
import { sortOptionsDefaults } from 'src/utils/payListConsts';
import { SortDirection } from 'src/utils/query-utils';

/**
 * pass whole sorting query string, because the :desc part
 * is whats different between scheduled and paid tab
 */
export function mapBackwardComaptibleSorting(sortingQueryString?: string): SortMenuValue | undefined {
  if (!sortingQueryString) return undefined;

  return {
    'payment.status,dueDate': { selected: sortOptionsDefaults[PayTabs.INBOX], direction: SortDirection.DESC },
    'payment.scheduledDate': { selected: sortOptionsDefaults[PayTabs.SCHEDULED], direction: SortDirection.DESC },
    'payment.scheduledDate:desc': { selected: sortOptionsDefaults[PayTabs.PAID], direction: SortDirection.DESC },
  }[sortingQueryString];
}

const sortMenuValueToQueryString = (sortMenuValue: SortMenuValue): string => {
  const { selected, direction } = sortMenuValue;

  return `${selected}:${direction}`;
};

export const mapBackwardComaptibleSortingToQueryString = (sortingQueryString?: string): string | undefined => {
  const value = mapBackwardComaptibleSorting(sortingQueryString);

  if (!value) return undefined;

  return sortMenuValueToQueryString(value);
};
