import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { PasswordField } from 'src/core/ds/form/fields';
import { usePasswordValidation } from 'src/pages/auth/hooks/usePasswordValidation';
import { PASSWORD } from 'src/ui/form/components/StrongPassword/consts';
// import { InlinePasswordField } from 'src/ui/form/InlinePasswordField';
// import { FeatureFlags } from 'src/utils/consts';
import { ShowPasswordStrengthNotice } from './components/StrongPassword';
import { ModelViewField } from './use-form';

type Props = {
  model: ModelViewField<string>;
  autoFocus?: boolean;
  label: string;
  dotSize?: number;
  passwordStrengthSize?: string;
  size?: 'sm' | 'md';
  marginBottomOverride?: string;
  errorMessage?: string | null;
  helperText?: string;
} & Partial<React.ComponentProps<typeof PasswordField>>;

export const WizardPasswordValidationInputField = ({
  model,
  placeholder,
  size,
  dotSize,
  marginBottomOverride,
  passwordStrengthSize,
  helperText,
  ...rest
}: Props) => {
  const { score, label } = usePasswordValidation(model.value || '');
  const showPasswordNotice = score === PASSWORD.INVALID_SCORE;
  const shouldRenderStrongPassword = !model.error && score > PASSWORD.INVALID_SCORE;
  const passwordNotice = showPasswordNotice
    ? helperText || 'inputErrors.userRegistration.password.string.notice'
    : undefined;
  const placeholderText = placeholder || 'inputErrors.userRegistration.password.passwordPlaceholder';
  const notOverrideMarginBottom = marginBottomOverride || !isEmpty(model.error) || showPasswordNotice;

  return (
    <>
      <PasswordField
        {...rest}
        model={model}
        size={size}
        errorMessage={model.error}
        placeholder={placeholderText}
        helperText={passwordNotice}
        marginBottom={notOverrideMarginBottom ? undefined : '0'}
      />

      {shouldRenderStrongPassword && (
        <ShowPasswordStrengthNotice
          score={score}
          label={label}
          dotSize={dotSize}
          marginBottomOverride={marginBottomOverride}
          size={passwordStrengthSize}
        />
      )}
    </>
  );
};
