export enum BusinessType {
  SoleProprietorship = 'sole-proprietorship',
  Partnership = 'partnership',
  LLC = 'llc',
  Corporation = 'corporation',
  NonProfitCorporation = 'non-profit',
  NGO = 'ngo',
  Municipal = 'municipality',
  Trust = 'trust',
}

export type BusinessDetailsModel = {
  companyName: string;
  legalCompanyName: string;
  industry: string;
  addressLine1: string;
  state: string;
  city: string;
  zipCode: string;
  formattedAddress: string;
  annualRevenue: string;
  ownerPhone: string;
  type: BusinessType | '';
  taxIdNumber: string;
};
