import * as React from 'react';
import { useSelector } from 'react-redux';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { UserOrgType } from 'src/modules/invitations/invitations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { useLocationState } from 'src/utils/hooks';
import { CompanyInfoType } from 'src/utils/types';

type Props = {
  onNext: () => void;
};

const AcceptAccountantRequestSuccess: React.FC<Props> = ({ onNext }: Props) => {
  const [invitingUser] = useLocationState<UserOrgType>('invitingUser');
  const organization = useSelector<any, CompanyInfoType | undefined>(profileStore.selectors.getCurrentOrg);

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.checkCircle}
      title="companies.accountantRequest.success.title"
      text="companies.accountantRequest.success.subtitle"
      textValues={{
        companyName: organization?.companyName,
        accountantName: `${invitingUser?.firstName} ${invitingUser?.lastName}`,
      }}
      buttonLabel="companies.acceptRequestSuccess.submit"
      notes={['companies.accountantRequest.success.note1', 'companies.accountantRequest.success.note2']}
      notesTitle="companies.accountantRequest.success.noteTitle"
      buttonAction={onNext}
    />
  );
};

export default AcceptAccountantRequestSuccess;
