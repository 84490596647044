import { MICard } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import ErrorIcon from 'src/images/general/error-icon.svg';
import { AddressType, LegalAddressType } from 'src/utils/types';
import { ManualAddressRow } from '../../components/layout/ManualAddressRow';
import { whitePagesAddressKeys } from '../../utils/address';

type Props = {
  originalAddress?: AddressType | LegalAddressType | null;
  printName?: string;
  onEditAddress: () => void;
};

export const ManualAddressInvalidPage = ({ originalAddress, printName, onEditAddress }: Props) => {
  const getOriginalAddress = () => {
    const addressFields: string[] = Object.values(whitePagesAddressKeys);
    const address = addressFields
      .filter((field) => !!originalAddress?.[field])
      .map((field) => originalAddress?.[field])
      .join(', ');

    return address;
  };

  return (
    <MICard mode="regular" flex>
      <Flex>
        <Image src={ErrorIcon} p={5} height="2.5rem" width="2.5rem" />
        <Box data-testid="invalid-manual-address" display="block" p={6} pl={0}>
          <Box textStyle="body2" color="grey.600" whiteSpace="nowrap">
            <MIFormattedText label="manualAddress.invalid" />
          </Box>
          <ManualAddressRow
            printName={printName}
            address={getOriginalAddress()}
            onEditAddress={onEditAddress}
            editLink
          />
        </Box>
      </Flex>
    </MICard>
  );
};
