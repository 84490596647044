import type { CardResponse } from '@melio/spend-management-api-axios-client';
import { useEffect } from 'react';
import { Column } from 'react-table';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { HeaderCell } from 'src/components/shared/tables/PaymentsTable/components/HeaderCell';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { DataTable } from 'src/pages/get-pro/components/table/DataTable';
import { AccountCell } from 'src/pages/spend-management/components/table/components/AccountCell';
import { AmountCell } from 'src/pages/spend-management/components/table/components/AmountCell';
import { CardCell } from 'src/pages/spend-management/components/table/components/CardCell';
import { StatusCell } from 'src/pages/spend-management/components/table/components/cards/StatusCell';
import { DateCell } from 'src/pages/spend-management/components/table/components/DateCell';
import { ExpireAtCell } from 'src/pages/spend-management/components/table/components/ExpireAtCell';
import { ValidToCell } from 'src/pages/spend-management/components/table/components/ValidToCell';
import { analytics, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { getCardData } from 'src/pages/spend-management/utils/cards-table';
import { tableWrapperStyle } from '../tableStyles';

export type TableCardRowType = {
  id: string;
  cardRecord: CardResponse;
  card: {
    nickname?: string;
    last5: string;
    expiryDate: string;
    validDate: string;
  };
  status: CardResponse['status'];
  amount: {
    amount?: string | undefined;
    createRequestAmount: string | null;
  };
  createdAt: string;
  account: {
    nickname?: string;
    last5: string;
  };
};

const PAGE_SIZE = 7;

export const CardsTable = ({
  cards,
  onCardClicked,
}: {
  cards: CardResponse[];
  onCardClicked: (card: CardResponse) => void;
}) => {
  useEffect(() => {
    analytics.page(Page.CARD_LIST, { isEmptyState: false });
  }, []);

  const columns: Column<TableCardRowType>[] = [
    {
      id: 'card',
      Header: <HeaderCell label="spendManagement.pages.dashboard.cardsList.columns.card" />,
      accessor: 'card',
      Cell: CardCell,
      width: 0.2,
      disableSortBy: true,
    },
    {
      id: 'status',
      Header: <HeaderCell label="spendManagement.pages.dashboard.cardsList.columns.status" />,
      accessor: 'status',
      Cell: StatusCell,
      width: 0.2,
      disableSortBy: true,
    },
    {
      id: 'account',
      Header: <HeaderCell label="spendManagement.pages.dashboard.cardsList.columns.account" />,
      accessor: 'account',
      Cell: AccountCell,
      width: 0.2,
      disableSortBy: true,
    },
    {
      id: 'amount',
      Header: <HeaderCell label="spendManagement.pages.dashboard.cardsList.columns.amount" />,
      accessor: 'amount',
      Cell: AmountCell,
      width: 0.2,
      disableSortBy: true,
      isNumeric: true,
    },
    {
      id: 'createdAt',
      Header: <HeaderCell label="spendManagement.pages.dashboard.cardsList.columns.createdAt" />,
      accessor: 'createdAt',
      Cell: DateCell,
      width: 0.2,
      disableSortBy: true,
    },
    {
      id: 'validTo',
      Header: <HeaderCell label="spendManagement.pages.dashboard.cardsList.columns.validTo" />,
      accessor: 'card',
      Cell: ValidToCell,
      width: 0.2,
      disableSortBy: true,
    },
    {
      id: 'expiredAt',
      Header: <HeaderCell label="spendManagement.pages.dashboard.cardsList.columns.expiredAt" />,
      accessor: 'card',
      Cell: ExpireAtCell,
      width: 0.2,
      disableSortBy: true,
    },
  ];

  if (!cards) {
    return (
      <Box h={300}>
        <AreaLoader />
      </Box>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const fooBar = () => {};

  const cardsData: TableCardRowType[] = cards.map(getCardData);

  return (
    <Flex direction="column">
      <Box __css={tableWrapperStyle}>
        <DataTable<TableCardRowType>
          name="SpendManagementTable"
          data-testid="spend-management-cards-table"
          columns={columns}
          data={cardsData}
          manualSortBy
          disableSortRemove
          onChangeSort={fooBar}
          autoResetSortBy={false}
          initialState={{}}
          hideCheckBoxes
          setSelectedIds={fooBar}
          selectedIds={[]}
          onClick={(row) => onCardClicked(row.original.cardRecord)}
          autoResetPage={false}
          manualPagination={false}
          showPagination={false}
          totalItems={cardsData.length}
          pageCount={Math.floor(cardsData.length / PAGE_SIZE) + 1}
          onPageIndexChange={fooBar}
          getRowId={(row) => row.id}
          onSelectionChanged={fooBar}
          toggleClearSelected={fooBar}
          content=""
          action={fooBar}
          setAction={fooBar}
          setActionParams={fooBar}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          filterRowsByQueryAction={fooBar}
          tab="paid"
        />
      </Box>
    </Flex>
  );
};

// eslint-disable-next-line import/no-default-export
export default CardsTable;
