import { featureFlags } from '@melio/shared-web';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  mapInProgressPaymentsFilter,
  mapInvoiceFilter,
  mapPaidPaymentsFilter,
  mapRequestsFilter,
} from 'src/pages/get-pro/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { FeatureFlags, GetProTabs } from 'src/utils/consts';
import { useQueryState, useQueryStateMultiValues, useQueryString } from 'src/utils/hooks';
import { getSelectedDateFilter, getSelectedTab } from '../components/tabs/utils';
import { PAGE_ITEM_COUNT } from '../consts';
import { GetProListParams, GetProQueryType } from '../types';

const MAP_FILTERS_BY_TAB = {
  [GetProTabs.INVOICES]: mapInvoiceFilter,
  [GetProTabs.REQUESTS]: mapRequestsFilter,
  [GetProTabs.IN_PROGRESS]: mapInProgressPaymentsFilter,
  [GetProTabs.PAID]: mapPaidPaymentsFilter,
};

const useGetProListParams = (): {
  listParams: GetProListParams;
  filter?: string;
  setSort: (sort: any) => void;
  setPage: (pageIndex: number) => void;
  setFilter: (filter?: string) => void;
  search?: string;
  setSearch: (search?: string) => void;
  setAction: (action?: string) => void;
  setActionParams: (action?: string) => void;
  setStatuses: (statuses?: string) => void;
  statuses?: string;
} => {
  const query: GetProQueryType = useQueryString();
  const [pageValues, setPageValues] = useQueryStateMultiValues<{
    start: string | number;
    limit: string | number;
  }>({
    start: '0',
    limit: PAGE_ITEM_COUNT.toString(),
  });
  const [setLast7DaysAsDefault] = featureFlags.useFeature(FeatureFlags.SetDefaultValueLastSevenDaysPaidTab, false);

  const { start, limit } = pageValues;
  const { contactId } = query;
  const [sort, setSort] = useQueryState('sort', undefined);
  const [queryFilter, setQueryFilter] = useQueryState('filter', undefined);
  const [search, setSearch] = useQueryState('search', undefined);
  const [action, setAction] = useQueryState('action', undefined);
  const [actionParams, setActionParams] = useQueryState('action-params', undefined);
  const [statuses, setQueryStatuses] = useQueryState('statuses', undefined);
  const orgId = useSelector(getOrgId);
  const status = getSelectedTab(query);
  const filter = getSelectedDateFilter(status, queryFilter, setLast7DaysAsDefault);
  const listParams = useMemo(
    () => ({
      orgId,
      filters: {
        start,
        limit,
        sort,
        filter: MAP_FILTERS_BY_TAB[status](filter, statuses?.split(',') ?? []),
        status,
        search,
        contactId,
        action,
        actionParams,
      },
    }),
    [orgId, start, limit, sort, status, filter, search, contactId, action, actionParams, statuses]
  );

  const setPage = useCallback(
    (pageIndex: number) => {
      setPageValues({
        start: pageIndex * PAGE_ITEM_COUNT,
        limit: PAGE_ITEM_COUNT,
      });
    },
    [setPageValues]
  );

  const setFilter = useCallback(
    (filter: string | undefined) => {
      setQueryFilter(filter);
      setPageValues({
        start: '0',
        limit: PAGE_ITEM_COUNT,
      });
    },
    [setPageValues, setQueryFilter]
  );

  const setStatuses = useCallback(
    (statuses: string | undefined) => {
      setQueryStatuses(statuses);
      setPageValues({
        start: '0',
        limit: PAGE_ITEM_COUNT,
      });
    },
    [setQueryStatuses]
  );

  return {
    listParams: listParams as GetProListParams,
    filter,
    setSort,
    setPage,
    setFilter,
    search,
    setSearch,
    setAction,
    setActionParams,
    setStatuses,
    statuses,
  };
};

export default useGetProListParams;
