export const candidatesDetailsContainerStyle = {
  bg: 'white',
  h: 'fit-content',
  maxW: { base: 'full', md: '53rem' },
  boxShadow: { base: 'none', md: '400' },
  border: { base: 'none', md: '1px' },
  borderColor: { base: 'transparent', md: 'grey.300' },
  borderRadius: { base: 'none', md: 'lg' },
  my: { base: 10, md: 20 },
  mx: 'auto',
};

export const formSectionTitleStyle = {
  d: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  textStyle: 'body4Uppercase',
  w: 'full',
  textAlign: 'left',
  color: 'grey.600',
};
