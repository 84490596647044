import { Box } from '@chakra-ui/react';
import { format } from 'date-fns';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PaymentInfoHint } from 'src/components/layout/PaymentActivityElements';
import { useMicroDepositOptimizationsEnabled } from 'src/hooks/useMicroDepositOptimizationsEnabled';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryType, FailedPaymentType, PaymentApprovalStatus } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
  bill: BillType;
  deliveryMethod: DeliveryMethodType;
  fundingSource: AccountType;
};

export const DeliveryDateInfoHint = ({ bill, payment, deliveryMethod, fundingSource }: Props) => {
  const { isMicroDepositOptimizationsEnabled } = useMicroDepositOptimizationsEnabled();
  const isRecurringBill = !!bill.recurringBill;
  const isApprovalRequired = payment.approvalDecisionStatus === PaymentApprovalStatus.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnilateral = deliveryMethod.deliveryType === DeliveryType.VIRTUAL;
  const paymentIsFailedToDeliver = payment?.metadata?.failedType === FailedPaymentType.FAILED_TO_DELIVER;
  const date = <MIFormattedDate year={undefined} date={payment.scheduledDate} />;
  let hint;
  let hintValues = {};

  if (isMicrodeposit && isApprovalRequired) {
    hint = isMicroDepositOptimizationsEnabled
      ? 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountApprovalRequiredHintNew'
      : 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountApprovalRequiredHint';
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    hintValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isUnilateral && paymentIsFailedToDeliver) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralFailedToDeliver';
    hintValues = {
      date: <MIFormattedDate year={undefined} date={payment.deliveryEta} />,
    };
  } else if (isApprovalRequired && isUnilateral) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralApprovalRequiredHint';
    hintValues = {
      date,
    };
  } else if (isMicrodeposit) {
    const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
    hint = isMicroDepositOptimizationsEnabled
      ? 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountHintNew'
      : 'payBillPaymentActivity.deliveryDate.unverifiedBankAccountHint';
    hintValues = {
      date,
      bankAccount: `(...${cutAccountNumber})`,
    };
  } else if (isApprovalRequired) {
    hint = 'payBillPaymentActivity.deliveryDate.approvalRequiredHint';
    hintValues = {
      date,
    };
  } else if (isUnilateral) {
    hint = 'payBillPaymentActivity.deliveryDate.unilateralHint';
    hintValues = {
      date,
    };
  } else if (isRecurringBill) {
    const dueDate = format(new Date(bill.dueDate), DateFormats.isoDate);
    const deliveryEta = format(new Date(payment.deliveryEta), DateFormats.isoDate);
    const isFirstPaymentLate = deliveryEta > dueDate;

    if (isFirstPaymentLate) {
      hint = 'payBillPaymentActivity.deliveryDate.recurringHint';
    }
  } else if (deliveryMethod.deliveryType === DeliveryType.ACH) {
    hint = 'payBillPaymentActivity.deliveryDate.achHint';
  }

  if (hint) {
    return (
      <PaymentInfoHint>
        <Box color="grey.600">
          <MIFormattedText label={hint} values={hintValues} />
        </Box>
      </PaymentInfoHint>
    );
  }

  return null;
};
