import { GetProTabs } from 'src/utils/consts';

export const DATA_TABLE_RENDER = {
  Header: 'Header',
  Cell: 'Cell',
};

export const MAP_TAB_DEFAULT_SORT = {
  [GetProTabs.INVOICES]: { id: 'created', desc: true },
  [GetProTabs.REQUESTS]: { id: 'dueDate', desc: false },
  [GetProTabs.IN_PROGRESS]: { id: 'status', desc: true },
  [GetProTabs.PAID]: { id: 'deliveryDate', desc: true },
} as const;

export enum TableContentState {
  ERROR = 'error',
  BLOCKED = 'blocked',
  EMPTY = 'empty',
  LOADING = 'loading',
  CONTENT = 'content',
  EMPTY_FILTER_OR_SEARCH_RESULT = 'emptyFilterOrSearchResult',
}
