import { Flex } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Input } from 'src/components/common/Amount/Input';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { getNameInitials } from 'src/utils/string-utils';
import { VendorType } from 'src/utils/types';

type BackButtonProps = {
  onPrev?: (() => void) | null;
  analyticsProps?: Record<string, any>;
};

const BackButton = ({ onPrev, analyticsProps }: BackButtonProps) => {
  const handlePrevClick = () => {
    if (onPrev) {
      analytics.trackAction('go.back', {
        ...analyticsProps,
      });
      onPrev();
    }
  };

  return (
    <BackButtonContainer>
      <Back onClick={handlePrevClick} data-testid="previous-icon">
        <Icon name={IconNames.shevronLeft} size={IconSize.lg} />
      </Back>
    </BackButtonContainer>
  );
};

export type HeaderProps = {
  className?: string;
  onPrev?: (() => void) | null;
  payment?: Record<string, any>;
  organization?: any;
  vendor?: VendorType;
  filesUrls?: Record<string, any>;
  showFullHeader?: boolean;
  hideLogo?: boolean;
  hideAmount?: boolean;
  note?: string | null;
  subTitle?: string;
  description?: string;
  descriptionValues?: Record<string, any>;
  hideInvoice?: boolean;
  analyticsProps?: Record<string, any>;
  children?: ReactNode;
};

const Header = ({
  className,
  onPrev,
  organization,
  vendor,
  payment,
  filesUrls,
  showFullHeader,
  hideLogo,
  hideAmount,
  note,
  subTitle,
  description,
  descriptionValues,
  hideInvoice,
  analyticsProps,
  children,
}: HeaderProps) => {
  const companyName = organization?.companyName;
  const logoUrl = filesUrls?.logoUrl;

  type BillInvoiceNumber = {
    invoiceNumber: string | null;
  };

  let invoiceNumbers: Partial<{ bill: BillInvoiceNumber }>[] = [];
  let emptyInvoiceNumbers: Partial<{ bill: BillInvoiceNumber }>[] = [];

  if (payment?.billPayments) {
    [invoiceNumbers, emptyInvoiceNumbers] = partition(payment?.billPayments, (item) => item?.bill?.invoiceNumber);
  } else {
    payment?.bill?.invoiceNumber ? invoiceNumbers.push(payment) : emptyInvoiceNumbers.push(payment?.bill);
  }

  const filePreviewUrls = filesUrls?.filePreviewUrls;
  const fileStorageUrl = filesUrls?.fileStorageUrl;
  const invoiceUrl = filePreviewUrls && !isEmpty(filePreviewUrls) ? filePreviewUrls[0] : fileStorageUrl;
  const isHandleAttachment = invoiceUrl && invoiceNumbers.length === 1;

  const handleAttachmentClick = () => {
    if (fileStorageUrl) {
      analytics.trackAction('unilateral-view-attachment');
      window.open(fileStorageUrl);
    }
  };

  return (
    <HeaderContentContainer className={className}>
      {onPrev && (
        <BackButton
          onPrev={onPrev}
          analyticsProps={{
            flow: payment ? 'Unilateral with payment' : 'Unilateral without payment',
            vendorId: vendor?.id,
            vendorEmail: vendor?.contactEmail,
            payorOrgId: organization?.id,
            ...analyticsProps,
          }}
        />
      )}
      <VendorInfoContainer>
        {!hideLogo &&
          (logoUrl ? (
            <VendorLogo src={logoUrl} alt={companyName} />
          ) : (
            <DefaultLogoContainer>{getNameInitials(companyName)}</DefaultLogoContainer>
          ))}
        <VendorTitle data-testid="title-vendor-name">{companyName}</VendorTitle>
        <VendorSubTitle>{showFullHeader && subTitle && <MIFormattedText label={subTitle} />}</VendorSubTitle>
        {!hideAmount && payment && (
          <AmountInputContainer fullView={showFullHeader}>
            <Input totalAmount={payment?.amount} viewOnly />
          </AmountInputContainer>
        )}
        {description && showFullHeader && (
          <Description>
            <MIFormattedText label={description} values={descriptionValues} />
          </Description>
        )}
        {!hideInvoice && (invoiceNumbers?.length > 0 || emptyInvoiceNumbers.length > 0) && showFullHeader && (
          <Flex justifyContent="center" alignItems="center" wrap="wrap" data-testid="payment-bills-list">
            <InvoiceLabel>
              <MIFormattedText label="vendors.addDeliveryMethodByLink.for" />
            </InvoiceLabel>
            {invoiceNumbers.map(({ bill }, idx) => (
              <InvoiceAttachmentContainer
                key={bill?.invoiceNumber}
                onClick={isHandleAttachment ? handleAttachmentClick : null}
              >
                <InvoiceName>
                  {bill?.invoiceNumber}
                  {invoiceNumbers?.length > 1 && idx < invoiceNumbers.length - 1 && ', '}
                </InvoiceName>
                {isHandleAttachment && (
                  <Box ml={0.5} mt={0.5} fontSize="1rem" transform="rotate(45deg) scale(.6,.6)">
                    <Icon name={IconNames.attachment} size={IconSize.s} color="primary.500" />
                  </Box>
                )}
              </InvoiceAttachmentContainer>
            ))}
            {emptyInvoiceNumbers.length > 0 && (
              <InvoiceName>
                <Box>
                  <MIFormattedText
                    label="vendors.addDeliveryMethodByLink.billsWithNoInvoiceNumbers"
                    values={{
                      invoiceNumbers: invoiceNumbers.length,
                      emptyInvoiceNumbers: emptyInvoiceNumbers.length,
                    }}
                  />
                </Box>
              </InvoiceName>
            )}
          </Flex>
        )}
        {note && showFullHeader && (
          <VendorInfoHint>
            <MIFormattedText
              label="vendors.addDeliveryMethodByLink.includedNote"
              values={{ note: <InvoiceNote>{note}</InvoiceNote> }}
            />
          </VendorInfoHint>
        )}
        {children}
      </VendorInfoContainer>
    </HeaderContentContainer>
  );
};

type ContentWrapperProps = {
  children: ReactNode;
  title?: string;
  subTitle?: string;
  titleValues?: Record<string, any>;
};

const ContentWrapper = ({ children, title, subTitle, titleValues }: ContentWrapperProps) => (
  <ContentContainer>
    {title && (
      <ContentTitle>
        <MIFormattedText label={title} values={titleValues} />
      </ContentTitle>
    )}
    {subTitle && (
      <ContentSubTitle>
        <MIFormattedText label={subTitle} />
      </ContentSubTitle>
    )}
    {children}
  </ContentContainer>
);

type FooterProps = {
  companyName: string;
};

const Footer = ({ companyName }: FooterProps) => {
  const site = useSiteContext();

  return (
    <>
      <FooterText>
        <MIFormattedText
          label="vendors.addDeliveryMethodByLink.confirmMelio"
          values={{
            termsOfService: (
              <Link
                href={site.config.agreementLinks.userAgreement}
                target="_blank"
                onClick={() => {
                  analytics.track('unilateral', 'terms-of-service');
                }}
              >
                <MIFormattedText label="vendors.addDeliveryMethodByLink.termsOfService" />
              </Link>
            ),
            privacyPolicy: (
              <Link
                href={site.config.agreementLinks.privacyPolicy}
                target="_blank"
                onClick={() => {
                  analytics.track('unilateral', 'privacy-policy');
                }}
              >
                <MIFormattedText label="vendors.addDeliveryMethodByLink.privacyPolicy" />
              </Link>
            ),
          }}
        />
      </FooterText>
      <MISecurityDetails
        eventPage="unilateral-payment"
        label="vendors.deliveryMethods.virtual.securityText"
        values={{ companyName }}
      />
    </>
  );
};

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.opaque};
  padding: 4rem;
  border-radius: 0.6rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ContentTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-align: left;
  ${(props) => props.theme.text.fontType.regular};
  font-size: 1.8rem;
`;

const labelStyles = css`
  color: ${(props) => props.theme.text.color.label};
  ${(props) => props.theme.text.fontType.hint};
`;

const ContentSubTitle = styled.div`
  ${labelStyles}
  line-height: 1.8rem;
  text-align: left;
`;

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  align-items: center;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const picStyles = css`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.1rem solid ${(props) => props.theme.colors.progress.undone};
`;

export const DefaultLogoContainer = styled.div`
  ${picStyles}
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.light.opaque};
  font-size: ${(props) => props.theme.text.size.subNav};
  color: white;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-transform: uppercase;
`;

export const VendorLogo = styled.img`
  ${picStyles}
  display: block;
  object-fit: contain;
`;

const VendorTitle = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${(props) => props.theme.text.fontType.regular};
`;

export const VendorSubTitle = styled.div`
  ${(props) => props.theme.text.fontType.medium};
  color: ${(props) => props.theme.text.color.subtitle};
  text-transform: lowercase;
`;

const VendorInfoHint = styled.div`
  margin-top: 0.5rem;
  text-align: center;
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.label};
`;

const VendorInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceAttachmentContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const InvoiceNote = styled.div``;

const InvoiceName = styled.span`
  margin-left: 0.3rem;
  color: ${(props) => props.theme.text.color.highlight};
  ${(props) => props.theme.text.fontType.medium};
`;

const InvoiceLabel = styled.span`
  ${labelStyles}
  display: flex;
  align-items: center;
`;

export const AmountInputContainer = styled.div<{ fullView?: boolean }>`
  margin-top: 1.5rem;
  ${(p) =>
    !p.fullView &&
    css`
      margin-bottom: -2rem;
    `};
  padding-top: 1rem;
`;

const BackButtonContainer = styled.div`
  position: absolute;
  left: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    left: 2rem;
  }
`;

const Back = styled.div`
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  color: ${(props) => props.theme.text.color.label};
  margin-top: 0;

  i {
    cursor: pointer;
  }
`;

const FooterText = styled.div`
  margin: 1rem 0 2rem;
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  text-align: center;
`;

const Link = styled.a`
  text-decoration: underline;
  text-transform: lowercase;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
`;

const Description = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
  display: inline;
`;

export { BackButton, ContentWrapper, Footer, Header };
