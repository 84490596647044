import { generatePath } from 'react-router-dom';
import { authLocations } from 'src/pages/auth/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { analytics } from 'src/services/analytics';
import config from '../config';
import { LocalStorageKeys, RegistrationOrigin } from './consts';
import { CompanyInfoType } from './types';

type ConnectToIntuitOptions = {
  intuitReturnUrl?: string;
  intuitErrorReturnUrl?: string;
  quickbooksChooseRealmId?: string;
  linkOrgId?: number;
  registrationOrigin?: string;
  createCompany?: boolean;
  switchAccountingFirm?: boolean;
  companyInfo?: Partial<CompanyInfoType>;
  embeddedAppcenter?: boolean;
  intent?: string;
  isAfterRegister?: boolean;
  site?: any;
  appUrl?: string;
};

// Initiates the Intuit OpenID and QuickBooks Connect dance
// We save two URLs to local storage
// One for when Intuit finishes to sync, and one for when quickbook is done.
//
// When intuit returns, if it's a valid user redirect to the intuit return url
// or to the dashboard. If it's not a user, it'll continue to the quickbooks
// connect, and upon successful QuickBooks connect the quickbooks url will be used.

function setUnsetLocalStorage(key, value) {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
}

function setupReturnData({ intuitReturnUrl, quickbooksChooseRealmId, appUrl }: ConnectToIntuitOptions) {
  setUnsetLocalStorage(LocalStorageKeys.INTUIT_RETURN_URL, intuitReturnUrl);
  setUnsetLocalStorage(LocalStorageKeys.QUICKBOOKS_CHOOSE_REALM_ID, quickbooksChooseRealmId);
  appUrl && setUnsetLocalStorage(LocalStorageKeys.INTUIT_RETURN_APP_URL, appUrl);
}

export default {
  buildConnectQuery(options: ConnectToIntuitOptions) {
    const {
      intuitReturnUrl,
      intuitErrorReturnUrl = '',
      quickbooksChooseRealmId,
      site,
      linkOrgId,
      registrationOrigin,
      createCompany,
      companyInfo,
      embeddedAppcenter,
      switchAccountingFirm,
      intent,
      isAfterRegister,
    } = options;
    const intuitReturn = [
      `${site?.config?.web?.baseUrl || config.web.baseUrl}`,
      `${intuitReturnUrl || authLocations.intuit.login}`,
    ].join('');

    const intuitErrorReturn = [
      `${site?.config?.web?.baseUrl || config.web.baseUrl}`,
      `${String(intuitErrorReturnUrl) || String(intuitReturnUrl)}`,
    ].join('');

    const query = [
      `intuitReturn=${encodeURIComponent(intuitReturn)}`,
      `intuitErrorReturn=${encodeURIComponent(intuitErrorReturn)}`,
      `qbRealmId=${encodeURIComponent(quickbooksChooseRealmId || '')}`,
      `registrationOrigin=${encodeURIComponent(registrationOrigin || RegistrationOrigin.APP)}`,
    ];

    if (linkOrgId) {
      query.push(`linkOrgId=${linkOrgId}`);
    }

    if (site?.isBillpay) {
      query.push('isBillpay=true');
    }

    if (embeddedAppcenter) {
      query.push('embeddedAppcenter=true');
    }

    if (createCompany) {
      query.push('createCompany=true');
    }

    if (switchAccountingFirm) {
      query.push('switchAccountingFirm=true');
    }

    if (companyInfo) {
      query.push(`companyInfo=${encodeURIComponent(JSON.stringify(companyInfo))}`);
    }

    if (intent) {
      query.push(`intent=${intent}`);
    }

    if (isAfterRegister) {
      query.push(`isAfterRegister=${isAfterRegister}`);
    }

    return query;
  },
  goConnectToIntuit(options: ConnectToIntuitOptions) {
    const { intuitReturnUrl, quickbooksChooseRealmId, appUrl } = options;
    setupReturnData({ intuitReturnUrl, quickbooksChooseRealmId, appUrl });
    this.updateLoadingStatus(75);
    const query = this.buildConnectQuery(options);
    const url = `${config.server.baseUrl}/api/auth/intuit/oauth${options.intent ? '/intent' : ''}?${query.join('&')}`;

    if (config.thirdPartyDisabled) {
      window.location.href = generatePath(settingsLocations.syncQBDLocations.disabled, {
        orgId: options.linkOrgId,
      });
    } else {
      window.location.href = url;
    }
  },
  goConnectToIntuitWithAction(options: ConnectToIntuitOptions) {
    const { intuitReturnUrl, quickbooksChooseRealmId } = options;
    setupReturnData({ intuitReturnUrl, quickbooksChooseRealmId });
    this.updateLoadingStatus(75);
    const query = this.buildConnectQuery(options);

    window.location.href = `${config.server.baseUrl}/api/auth/intuit/oauth?${query.join('&')}`;
  },
  closeIntegration() {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage('melio-quickbooks-done', '*');
  },
  getRealmId() {
    return localStorage.getItem(LocalStorageKeys.QUICKBOOKS_CHOOSE_REALM_ID);
  },

  endLoadingWrapper() {
    analytics.trackAction('melio-wrapper-loaded');
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ done: true, precentage: 100, source: 'melio-loading-iframe' }, '*');
  },
  updateLoadingStatus(precentage: number) {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({ precentage, source: 'melio-loading-iframe' }, '*');
  },
};
