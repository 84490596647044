import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import organizationStore from 'src/modules/organizations/organizations-store';
import { devices } from 'src/theme/appDevices';
import { SelectCategory, useForm } from 'src/ui/form';
import { CompanyType } from 'src/utils/consts';

const roleOptions = [
  {
    id: CompanyType.SMB,
    label: 'multiOrgOnboarding.companyType.smb.title',
    icon: 'icon-business-owner-icon',
  },
  {
    id: CompanyType.ACCOUNTING_FIRM,
    label: 'multiOrgOnboarding.companyType.accounting-firm.title',
    icon: 'icon-bookkeeper-icon',
  },
];

type Props = {
  onNext: (companyType: CompanyType) => void;
};

type SelectCompanyTypeForm = {
  companyType: string | null | undefined;
};

export const QuickbookMultiOrgOnboardingCompanyType = ({ onNext }: Props) => {
  const dispatch = useDispatch();
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const model = useMemo(() => ({ companyType: newCompanyInfo?.companyType }), [newCompanyInfo]);

  const submitAction = async (value) => {
    onNext(value.companyType);
    dispatch(organizationStore.actions.updateNewCompanySlice.update({ ...value }));
  };

  const [companyTypeMV, { submit }] = useForm<SelectCompanyTypeForm>(model, {
    submit: submitAction,
  });

  return (
    <StepLayoutPage
      title="multiOrgOnboarding.companyType.title"
      subtitle="multiOrgOnboarding.companyType.subtitle"
      relativeStep={1 / 5}
      onNext={submit}
      isNextDisabled={!companyTypeMV.companyType.value}
    >
      <CategoryContainer>
        <SelectCategory options={roleOptions} model={companyTypeMV.companyType} />
      </CategoryContainer>
      {companyTypeMV.companyType.value && (
        <TypeHintContainer>
          <CheckIcon />
          <TypeHint>
            <MIFormattedText label={`onboarding.companyInfo.type.options.${companyTypeMV.companyType.value}.hint`} />
          </TypeHint>
        </TypeHintContainer>
      )}
    </StepLayoutPage>
  );
};

const CategoryContainer = styled.div`
  margin-bottom: 0.2rem;
  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: -2rem;
  }
`;

const TypeHintContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.text.color.label};
  margin-left: 1.6rem;
`;

const TypeHint = styled.span`
  ${(props) => props.theme.text.fontType.hint};
`;

const CheckIcon = styled.i.attrs({
  className: 'icon-notification-checkmark-icon',
})`
  display: block;
  font-size: 2.4rem;
  margin-right: 0.6rem;
  color: ${(props) => props.theme.colors.primary.opaque};
`;
