import styled, { css } from 'styled-components';
import { Input } from 'src/components/common/Amount/Input';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import PaymentProgressTracker from 'src/components/layout/PaymentProgressTracker';
import { PaymentTrackerStage } from 'src/utils/types';

type Props = {
  companyName: string;
  statusInfoText: Record<string, any>;
  stages: PaymentTrackerStage[];
  validationErrors: Record<string, any>;
  invoiceNumber?: string;
  totalAmount: string;
  smallText?: boolean;
};

const GuestPaymentRequestTrackerPage = ({
  statusInfoText,
  stages,
  validationErrors,
  totalAmount,
  invoiceNumber,
  companyName,
}: Props) => (
  <>
    <ProgressCardAccost>
      <MIFormattedText label="guests.progressPayment.your" />
    </ProgressCardAccost>
    <Input totalAmount={totalAmount} validationErrors={validationErrors} viewOnly />
    <ProgressCardTextContainer data-testid="payment-container-text">
      <ProgressCardText>
        <MIFormattedText
          label="guests.progressPayment.paymentTo"
          values={{
            statusInfoText,
            companyName: <strong>{companyName}</strong>,
          }}
        />
      </ProgressCardText>
      <ProgressCardText smallText>
        <MIFormattedText label="guests.progressPayment.invoice" values={{ invoiceNumber }} />
      </ProgressCardText>
    </ProgressCardTextContainer>
    <PaymentProgressTracker
      stages={stages}
      additionalStrings={{
        trackerTitle: 'requests.tracking.widgetTitle',
        companyName,
      }}
    />
  </>
);

const commonTextStyles = css`
  font-size: ${(props: any) =>
    props.smallText ? props.theme.text.size.sectionTitleM : props.theme.text.size.sectionTitle};
  color: ${(props) => props.theme.text.color.subtitle};
  line-height: 1.8rem;
`;
const ProgressCardText = styled.div<{ smallText?: boolean }>`
  ${commonTextStyles};
  margin-top: ${(props) => (props.smallText ? '1rem' : '0')};
`;

const ProgressCardAccost = styled.div`
  ${commonTextStyles};
  margin-top: -0.3rem;
  margin-bottom: 0.5rem;
`;

const ProgressCardTextContainer = styled.div`
  margin-top: -1.5rem;
  margin-bottom: 3.5rem;
`;

export default GuestPaymentRequestTrackerPage;
