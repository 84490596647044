import { ROLE_TAGS } from 'src/pages/team/consts';
import { Role } from 'src/utils/consts';

export const getAccountingFirmSyntheticRole = (role?: Role) => {
  if (role === Role.OWNER) {
    return ROLE_TAGS.ACCOUNTING_FIRM_OWNER;
  }

  if (role === Role.ADMIN) {
    return ROLE_TAGS.ACCOUNTING_FIRM_ADMIN;
  }

  return role;
};
