import isEmpty from 'lodash/isEmpty';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { billLocations } from 'src/pages/bill/locations';
import { AddAchFlow } from 'src/pages/vendor/delivery-methods/add-ach-flow/AddAchFlow';
import { vendorLocations } from 'src/pages/vendor/locations';
import { ContactsTab } from 'src/utils/consts';
import { FlowCustomizationsType } from '../types';

type Props = {
  orgId: number;
  vendorId: number;
  deliveryMethodId?: string;
};

export const AddAchFromVendorsTabFlowWrapper = ({ orgId, vendorId, deliveryMethodId }: Props) => {
  const { SimpleTextFooter } = WizardElements;

  const { vendor } = useFetchVendor(vendorId);
  const vendorName = vendor?.companyName;

  const vendorsViewUrl = generatePath(vendorLocations.view, {
    orgId,
    id: vendorId,
    type: ContactsTab.VENDORS,
  });

  const createBillUrl = `${generatePath(billLocations.create.index, {
    orgId,
  })}?vendorId=${vendorId}&manually=true`;

  const flowCustomizations: FlowCustomizationsType = {
    stepLayout: {
      hideHeader: true,
      title: isEmpty(vendorName)
        ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
        : 'vendors.deliveryMethods.bank.title',
      titleValues: { vendor: vendorName },
      nextLabel: 'vendors.deliveryMethods.bank.saveAndAddBill',
      secondaryActionButtonLabel: 'vendors.deliveryMethods.bank.saveAndClose',
      footer: (
        <SimpleTextFooter>
          <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
        </SimpleTextFooter>
      ),
      fullWidthCTA: true,
    },
  };

  return (
    <AddAchFlow
      vendorId={vendorId}
      deliveryMethodId={deliveryMethodId}
      flowCustomizations={flowCustomizations}
      saveAndClose={vendorsViewUrl}
      saveAndContinue={createBillUrl}
    />
  );
};
