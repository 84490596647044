import * as React from 'react';
import { MICard, MICardForm, MICardHeader } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Wrap } from 'src/core/ds/wrap';
import { ApprovalWorkflowCreatorsGroup } from 'src/utils/consts';
import { PaymentCreatorOptionType } from '../ApprovalWorkflowModal/types';

type Props = {
  creatorsGroup?: ApprovalWorkflowCreatorsGroup;
  paymentCreators?: readonly PaymentCreatorOptionType[];
  approvalDecisionsCount: number;
  threshold: number;
  renderRight?: React.ReactNode;
  'data-testid'?: string;
};

const HEADER_TEXT_STYLE = 'body2Semi';

export function ApprovalWorkflowCard({
  paymentCreators,
  creatorsGroup,
  approvalDecisionsCount,
  threshold,
  renderRight,
  'data-testid': testId,
}: Props) {
  return (
    <Box data-testid={testId || 'ApprovalWorkflowCard'} borderBottom="white" pb={0} mt={4}>
      <MICard mode="regular">
        <MICardForm>
          <MICardHeader>
            <Wrap>
              <Box isTruncated textStyle={HEADER_TEXT_STYLE} data-testid="ApprovalWorkflowCard-threshold">
                <MIFormattedText
                  label="settings.approvalWorkflows.card.threshold"
                  values={{ threshold, approvalDecisionsCount }}
                />
              </Box>
            </Wrap>
            {renderRight}
          </MICardHeader>
          <Flex mt={1} textStyle="body4" color="grey.700" data-testid="ApprovalWorkflowCard-paymentCreators">
            {creatorsGroup === ApprovalWorkflowCreatorsGroup.ALL ? (
              <MIFormattedText label="settings.approvalWorkflows.card.paymentCreatorsEveryone" />
            ) : (
              <Flex>
                <MIFormattedText label="settings.approvalWorkflows.card.paymentCreators" />{' '}
                {displayCreators(paymentCreators)}
              </Flex>
            )}
          </Flex>
        </MICardForm>
      </MICard>
    </Box>
  );
}

const displayCreators = (paymentCreators?: readonly PaymentCreatorOptionType[]) =>
  paymentCreators?.map(({ firstName, lastName }) => [firstName, lastName].join(' ')).join(', ');
