import { Box } from '@chakra-ui/layout';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Spacer from 'src/core/ds/spacer';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  name: string;
  value: string;
  validationError?: string | null;
  disabled: boolean;
};

export const TableCell = ({ name, value, validationError, disabled = false }: Props) => (
  <Flex color={disabled ? 'grey.400' : 'black'}>
    <Box data-testid={`${name}-value`}>{value}</Box>
    <Spacer />
    {validationError && (
      <Tooltip
        placement="top"
        data-testid={`${name}-error-tooltip`}
        label={<MIFormattedText label={validationError} />}
      >
        <Flex data-testid={`${name}-validation-error`} align="center">
          <Icon name={IconNames.infoCircle} color="blue.500" size={IconSize.s} />
        </Flex>
      </Tooltip>
    )}
  </Flex>
);
