import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';

type Props = {
  onChangeEmail?: () => void;
  sendEmailVerificationCode: () => void;
};

export const CodeVerificationPageFooter = ({ sendEmailVerificationCode, onChangeEmail }: Props) => (
  <Box my={10} textStyle="body2">
    <WizardElements.WizardLinkContainer>
      <MIFormattedText
        label="guests.emailVerify.sendCodeAgain"
        values={{
          resendLink: <Link label="guests.emailVerify.sendCodeAgainLink" to={sendEmailVerificationCode} />,
        }}
      />
    </WizardElements.WizardLinkContainer>
    {Boolean(onChangeEmail) && (
      <>
        <WizardElements.WizardOrLine />
        <WizardElements.WizardLinkContainer>
          <MIFormattedText
            label="guests.emailVerify.changeEmail"
            values={{
              changeEmailLink: <Link label="guests.emailVerify.changeEmailLink" to={onChangeEmail} />,
            }}
          />
        </WizardElements.WizardLinkContainer>
      </>
    )}
  </Box>
);

const Link = styled(MILink)`
  color: ${({ theme }) => theme.colors.primary.opaque};
  font-size: ${({ theme }) => theme.text.size.regular};
  font-weight: ${({ theme }) => theme.text.weight.regular};
  text-decoration: none;
`;
