import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { useDisclosure } from 'src/core/ds/hooks';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import {
  trackExpandRow,
  useSinglePaymentAnalytics,
} from 'src/pages/regular-batch-payments/analytics/BatchPaymentsAnalytics';
import BillDrawer from 'src/pages/regular-batch-payments/components/Drawer/BillDetails/BillDrawer';
import { MainRowType } from '../columns';
import { InvoiceNumberCell } from './SubCells/InvoiceNumberCell';

type BillCellProps = { bills: BatchBillType[]; row: MainRowType };

export const BillsCell: React.FC<BillCellProps> = ({ bills, row }) => {
  const isBulkPayment = bills?.length > 1;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { trackViewBillDetailsByPayment } = useSinglePaymentAnalytics(row.id);

  const openSingleBillDetails = () => {
    trackViewBillDetailsByPayment();
    onOpen();
  };

  const onClick = () => {
    const newState = !row.isExpanded;
    row.toggleRowExpanded(newState);
    trackExpandRow({
      groupingState: newState,
      billIds: bills.map((bill) => bill.id),
      vendorId: row.original.vendor.id,
      payBillFlowUUID: row.original.payBillFlowUUID,
    });
  };

  if (isBulkPayment) {
    return (
      <Box
        as="a"
        textStyle="link3"
        _hover={{ textDecoration: 'underline' }}
        data-testid="regularBatchPayments.cells.bills.billsCount"
        onClick={onClick}
      >
        <MIFormattedText
          label={
            row.isExpanded
              ? 'regularBatchPayments.cells.bills.hideBills'
              : 'regularBatchPayments.cells.bills.billsCount'
          }
          values={{ billsCount: bills.length }}
        />
      </Box>
    );
  }

  return (
    <>
      <InvoiceNumberCell invoiceNumber={bills[0].invoiceNumber} onClick={openSingleBillDetails} />
      <BillDrawer bill={bills[0]} isOpen={isOpen} onClose={onClose} />
    </>
  );
};
