import { Checkbox as ChakraCheckbox, CheckboxProps } from '@chakra-ui/react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

export type Props = {
  'data-testid'?: string;
  colorScheme?: 'primary';
  label?: string;
  labelValues?: Record<string, unknown>;
} & CheckboxProps;

export const Checkbox = ({ label, labelValues, children, ...rest }: Props) => (
  <ChakraCheckbox alignItems="flex-start" spacing={2} {...rest}>
    {label && <MIFormattedText label={label} values={labelValues} />}
    {children}
  </ChakraCheckbox>
);
