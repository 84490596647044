const baseStyle = {
  px: 1,
  py: 'px',
  apply: 'textStyles.caption1Uppercase',
  borderRadius: 'sm',
  boxSizing: 'border-box',
};

const variantFilled = (props) => filledStyleByColorScheme[props.colorScheme];

const variantOutlined = (props) => {
  const style = outlinedStyleByColorScheme[props.colorScheme];

  return { ...style, py: 0 };
};

const filledStyleByColorScheme = {
  primary: {
    bg: 'primary.500',
    color: 'white',
  },
  primaryLight: {
    bg: 'primary.400',
    color: 'primary.500',
  },
  neutralDark: {
    bg: 'black',
    color: 'white',
  },
  neutral: {
    bg: 'grey.600',
    color: 'white',
  },
  neutralLight: {
    bg: 'grey.400',
    color: 'grey.700',
  },
  warning: {
    bg: 'yellow.500',
    color: 'white',
  },
  danger: {
    bg: 'red.500',
    color: 'white',
  },
  success: {
    bg: 'green.500',
    color: 'white',
  },
  informative: {
    bg: 'blue.500',
    color: 'white',
  },
};

const outlinedPrimaryStyle = {
  border: '1px',
  borderColor: 'primary.500',
  color: 'primary.500',
};

const outlinedStyleByColorScheme = {
  primary: {
    bg: 'white',
    ...outlinedPrimaryStyle,
  },
  primaryLight: {
    bg: 'primary.400',
    ...outlinedPrimaryStyle,
  },
  neutralDark: {
    border: '1px',
    borderColor: 'grey.600',
    bg: 'white',
    color: 'grey.600',
  },
  neutral: {
    border: '1px',
    borderColor: 'grey.500',
    bg: 'white',
    color: 'grey.500',
  },
  neutralLight: {
    bg: 'grey.400',
    color: 'grey.700',
    border: '1px',
    borderColor: 'grey.700',
  },
  warning: {
    border: '1px',
    borderColor: 'yellow.500',
    bg: 'yellow.300',
    color: 'yellow.500',
  },
  danger: {
    border: '1px',
    borderColor: 'red.500',
    bg: 'red.300',
    color: 'red.500',
  },
  success: {
    border: '1px',
    borderColor: 'green.500',
    bg: 'green.300',
    color: 'green.500',
  },
  informative: {
    border: '1px',
    borderColor: 'blue.500',
    bg: 'white',
    color: 'blue.500',
  },
};

const variants = {
  filled: variantFilled,
  outlined: variantOutlined,
};

const defaultProps = {
  variant: 'filled',
  colorScheme: 'primary',
};

export default {
  baseStyle,
  variants,
  defaultProps,
};
