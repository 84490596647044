import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { FeatureFlags } from 'src/utils/featureFlags';

export const useGetPaidOnboardingMainEntryPoint = () => {
  const [isDeferredOnboardingEnabled] = useFeature<boolean>(FeatureFlags.EnableGetPaidDeferredOnboarding, false);

  const getPaidOnboardingEntryPoint = isDeferredOnboardingEnabled
    ? onboardingLocations.vendorCompanyInfo.logo
    : onboardingLocations.vendorCompanyInfo.link;

  return { getPaidOnboardingEntryPoint };
};
