import { AxiosRequestConfig } from 'axios';
import { GetInstallmentsOptionsResponse, SetInstallmentsOptionsResponse } from 'src/pages/bill/pay/installments/types';
import { fetchRequest, postRequest, putRequest } from 'src/services/api/api';

export type ApplyForEligibilityApplicationData = {
  business: {
    name: string;
    legalName: string;
    legalAddressLine1: string;
    legalCity: string;
    legalState: string;
    legalZipCode: string;
    totalAnnualRevenue: number;
    type: string;
    phone: string;
    taxId: string;
    industry: string;
  };
  owner: {
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    phone: string;
    addressLine1: string;
    city: string;
    state: string;
    zipCode: string;
    ssn: string;
    ownershipPercentage: string;
  };
  beneficialOwners: {
    firstName: string;
    lastName: string;
    email: string;
    ownershipPercentage: string;
    dateOfBirth: string;
    ssn: string;
  }[];
};

const getFinancingUrlPrefix = (orgId: number) => `/orgs/${orgId}/financing`;

const getPreEligibility = (orgId: number, vendorId: number) => {
  const url = `${getFinancingUrlPrefix(orgId)}/eligibility?vendorId=${vendorId}`;

  return fetchRequest(url);
};

const getInstallmentsOptions = ({
  orgId,
  vendorId,
  paymentAmount,
}: {
  orgId: number;
  vendorId: number;
  paymentAmount: number;
}): Promise<GetInstallmentsOptionsResponse> => {
  const url = `${getFinancingUrlPrefix(orgId)}/options?vendorId=${vendorId}&paymentAmount=${paymentAmount}`;

  return fetchRequest(url);
};

const setInstallmentsOption = async ({
  orgId,
  intentId,
  paymentAmount,
  numberOfInstallments,
  scheduleDate,
  conf,
}: {
  orgId: number;
  intentId: string;
  paymentAmount: number;
  numberOfInstallments: number;
  scheduleDate: Date;
  conf?: AxiosRequestConfig;
}): Promise<SetInstallmentsOptionsResponse> => {
  const url = `${getFinancingUrlPrefix(orgId)}/option`;
  const body = {
    intentId,
    paymentAmount,
    numOfInstallments: numberOfInstallments,
    scheduleDate,
  };

  return putRequest(url, body, conf).then(({ installments, ...rest }) => ({
    ...rest,
    installments: installments.map(({ scheduledDate, ...item }) => ({
      ...item,
      scheduledDate: new Date(scheduledDate),
    })),
  }));
};

const applyForEligibility = ({ orgId, ...params }: { orgId: number } & ApplyForEligibilityApplicationData) => {
  const url = `${getFinancingUrlPrefix(orgId)}/eligibility`;

  return postRequest(url, params);
};

export const financingApi = {
  getPreEligibility,
  getInstallmentsOptions,
  setInstallmentsOption,
  applyForEligibility,
};
