import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import {
  getDeliveryMethodDescription,
  getDeliveryMethodLabel,
} from 'src/pages/regular-batch-payments/components/deliveryMethod/utils';
import { DeliveryType } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  deliveryMethod?: DeliveryMethodType;
  isExtended?: boolean;
};

export const DeliveryMethodLabel = ({ deliveryMethod, isExtended }: Props) => {
  const iconName = deliveryMethod?.deliveryType === DeliveryType.ACH ? IconNames.bank : IconNames.paperCheck;
  const { label, values } = isExtended
    ? getDeliveryMethodDescription(deliveryMethod)
    : getDeliveryMethodLabel(deliveryMethod);

  return (
    <Flex align="center" data-testid={`delivery-method-label-${deliveryMethod?.id}`} width="100%">
      {isExtended && (
        <Box>
          <Icon name={iconName} size={IconSize.lg} pr={4} />
        </Box>
      )}
      <Box as="span" textStyle="body4" isTruncated>
        <MIFormattedText label={label} values={values} />
      </Box>
    </Flex>
  );
};
