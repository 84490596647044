import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { FormRow } from 'src/core/ds/form';
import { TextField } from 'src/core/ds/form/fields';
import { BusinessDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { useFormGoogleAddress } from 'src/pages/bill/pay/installments/pages/application-details/hooks/useFormGoogleAddress';
import { useFormManualAddress } from 'src/pages/bill/pay/installments/pages/application-details/hooks/useFormManualAddress';
import { ModelView } from 'src/ui/form';
import { AddressFormControlMode } from 'src/utils/consts';

type Props = {
  businessDetailsModelView: ModelView<BusinessDetailsModel>;
  errorMessages: {
    addressLine1?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
};

export const LegalBusinessAddressFormRows = ({ businessDetailsModelView, errorMessages }: Props) => {
  const { handleAddressChange, address } = useFormGoogleAddress({
    modelView: businessDetailsModelView,
  });
  const { hasIntentionForManualAddress, handleGoToManualAddress, handleManualType } = useFormManualAddress({
    modelView: businessDetailsModelView,
  });

  return (
    <>
      <FormRow>
        <MIAddressAutocomplete
          id="legal-business-address"
          addressLabel="financing.businessDetails.fields.legalBusinessAddress.title"
          addressPlaceholder="form.addressAutocomplete.addressPlaceholder"
          address={address}
          errorMessage={errorMessages?.addressLine1}
          onChange={handleAddressChange}
          onType={handleManualType}
          marginBottomOverride="0"
          required
          hideSuite
          mode={AddressFormControlMode.INLINE}
          showAddManualAddress={!hasIntentionForManualAddress && !address}
          goToManualAddress={handleGoToManualAddress}
        />
      </FormRow>
      {address || hasIntentionForManualAddress ? (
        <>
          <FormRow>
            <TextField
              testId="input-city"
              label="financing.businessDetails.fields.city.title"
              errorMessage={errorMessages?.city}
              isRequired
              mb={0}
              model={businessDetailsModelView.city}
            />
            <TextField
              testId="input-state"
              label="financing.businessDetails.fields.state.title"
              errorMessage={errorMessages?.state}
              isRequired
              mb={0}
              model={businessDetailsModelView.state}
            />
          </FormRow>
          <FormRow>
            <TextField
              testId="input-zipCode"
              label="financing.businessDetails.fields.zipCode.title"
              errorMessage={errorMessages?.zipCode}
              isRequired
              mb={0}
              model={businessDetailsModelView.zipCode}
              type="number"
            />
          </FormRow>
        </>
      ) : null}
    </>
  );
};
