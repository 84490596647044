import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { AccessLevel } from 'src/utils/consts';
import { getUserOrganization } from 'src/utils/user';

export const useHasAccessToCurrentOrg = () => {
  const currentOrgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const currentUser = useSelector(profileStore.selectors.profile);

  const userOrg = getUserOrganization(currentUser, currentOrgId);

  return {
    hasAccessToCurrentOrg: userOrg?.accessLevel !== AccessLevel.NONE,
  };
};
