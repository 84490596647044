import styled from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { Button, ButtonSizes } from 'src/core/ds/button';
import doneImage from 'src/images/general/check-circle.svg';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { devices } from 'src/theme/appDevices';
import { DeliveryType, RegistrationFlow } from 'src/utils/consts';
import { ShiftToDebitState } from './hoc/shift-to-debit-hooks';

type Props = {
  state: ShiftToDebitState;
};

const AddDeliveryMethodSuccessPage = ({ state }: Props) => {
  const [historyPush] = useHistoryWithOrgId();
  const { deliveryMethod, payment, organization } = state;
  const { vendor } = useFetchVendor(payment?.vendor?.id);
  const isPaymentProcessed = deliveryMethod?.deliveryType === DeliveryType.CARD;
  const title = isPaymentProcessed
    ? 'vendors.deliveryMethods.shiftToDebit.processed.title'
    : 'vendors.deliveryMethods.shiftToDebit.success.title';
  const subtitle = isPaymentProcessed
    ? 'vendors.deliveryMethods.shiftToDebit.processed.subtitle'
    : 'vendors.deliveryMethods.shiftToDebit.success.subtitle';

  const goSignUp = () => {
    historyPush({
      path: registrationLocations.vendorRegister,
      state: {
        email: vendor?.contactEmail,
        registrationFlow: RegistrationFlow.PUSH_TO_DEBIT,
      },
    });
  };

  return (
    <OutsideLayout hideLogin>
      <HeaderContentContainer>
        <DoneIcon src={doneImage} />
        <Title>
          <MIFormattedText label={title} />
        </Title>
        <Subtitle>
          <MIFormattedText
            label={subtitle}
            values={{
              companyName: organization?.companyName,
              amount: <MIFormattedCurrency value={payment?.amount || ''} />,
              digits: deliveryMethod?.cardAccount?.card4digits,
            }}
          />
        </Subtitle>
        {!isPaymentProcessed && (
          <Description>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftToDebit.success.description"
              values={{
                companyName: organization?.companyName,
                learnMore: (
                  <LearnMoreLink
                    href="https://www.meliopayments.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.success.learnMore" />
                  </LearnMoreLink>
                ),
              }}
            />
          </Description>
        )}
      </HeaderContentContainer>
      <ContentWrapper>
        <ContentTitle>
          <MIFormattedText label="vendors.deliveryMethods.shiftToDebit.success.contentTitle" />
        </ContentTitle>

        <ContentSubtitle>
          <MIFormattedText
            label="vendors.deliveryMethods.shiftToDebit.success.contentSubtitle"
            values={{
              learnMore: (
                <LearnMoreLink
                  href="https://www.meliopayments.com/request"
                  target="_blank"
                  rel="noopener noreferrer"
                  size="large"
                >
                  <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.success.learnMore" />
                </LearnMoreLink>
              ),
            }}
          />
        </ContentSubtitle>
        <Button
          onClick={goSignUp}
          label="vendors.deliveryMethods.shiftToDebit.success.upSaleCTA"
          size={ButtonSizes.lg}
          isFullWidth
        />
      </ContentWrapper>
    </OutsideLayout>
  );
};

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: 2rem;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  margin-bottom: 2rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Description = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
`;

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  align-items: left;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ContentTitle = styled.div`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const ContentSubtitle = styled.div`
  margin: 1rem 0 4rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const LearnMoreLink = styled.a<{ size?: 'small' | 'large' }>`
  color: ${(props) => props.theme.colors.brand};
  cursor: pointer;
  text-decoration: none;
  ${(props) => (props.size === 'small' ? props.theme.text.fontType.hint : props.theme.text.fontType.regular)};
`;

export default AddDeliveryMethodSuccessPage;
