import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import Box from 'src/core/ds/box';
import { useDisclosure } from 'src/core/ds/hooks';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { TableContentState } from 'src/pages/get-pro/components/table/consts';
import { emptyStateStyle } from 'src/pages/get-pro/components/table/tableStyles';
import { useBillSubRowAnalytics } from 'src/pages/regular-batch-payments/analytics/BatchPaymentsAnalytics';
import { DataTableRowType } from 'src/pages/regular-batch-payments/components/table/types';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import COLUMNS, { BULK_PAYMENT_COLUMNS } from 'src/pages/regular-batch-payments/table/columns';
import { ExpanderCell } from 'src/pages/regular-batch-payments/table/components/ExpanderCell';
import { DataTable } from 'src/pages/regular-batch-payments/table/DataTable';
import { analytics } from 'src/services/analytics';
import { FeatureFlags } from 'src/utils/consts';
import { PAGE_EVENT } from '../analytics/event-mapping';
import BillDrawer from '../components/Drawer/BillDetails/BillDrawer';
import { useBatchSettingsChange } from '../hooks/useBatchSettingsChange';

export const BatchPaymentsTable: React.FC = () => {
  const { bulkPaymentToggleOn } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const [selectedBill, setSelectedBill] = useState<BatchBillType>();
  const [subRowClicked, setSubRowClicked] = useState<boolean>(false);

  const { billsList, isBillListLoading } = useBatchBillsList();
  const initialSortBy = useMemo(() => [{ id: 'vendor' }], []);
  const [isBulkPaymentEnabled] = featureFlags.useFeature(FeatureFlags.BulkPayment, false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { trackViewBillDetailsByBill } = useBillSubRowAnalytics(selectedBill?.id);
  const { isBulkPaymentsToggleDisabled } = useBatchSettingsChange();
  const firstRender = useRef<boolean>(false);

  const onRender = useCallback(() => {
    if (!firstRender.current && !isEmpty(billsList)) {
      analytics.track(PAGE_EVENT, 'table-loaded', {
        stateBulkToggle: bulkPaymentToggleOn && !isBulkPaymentsToggleDisabled,
      });
      firstRender.current = true;
    }
  }, [billsList, bulkPaymentToggleOn, isBulkPaymentsToggleDisabled]);

  const onClickRow = (row) => {
    if (row.depth > 0) {
      setSelectedBill(row.original);
      setSubRowClicked(true);
    }
  };

  useEffect(() => {
    if (subRowClicked) {
      trackViewBillDetailsByBill();
      onOpen();
      setSubRowClicked(false);
    }
  }, [onOpen, subRowClicked, trackViewBillDetailsByBill]);

  const getRowId = useCallback((row) => row.id, []);
  const content = (
    <Box __css={emptyStateStyle} position="relative">
      <AreaLoader />
    </Box>
  );

  const getColumns = () => {
    if (isBulkPaymentEnabled) {
      return [
        {
          id: 'expander',
          Cell: ({ row }) => row.canExpand && <ExpanderCell row={row} />,
          disableSortBy: true,
          maxWidth: bulkPaymentToggleOn ? 30 : 8,
          minWidth: bulkPaymentToggleOn ? 30 : 8,
        },
        ...BULK_PAYMENT_COLUMNS,
      ];
    }

    return COLUMNS;
  };

  return (
    <Box ref={onRender}>
      <DataTable<DataTableRowType>
        name="BatchPaymentsTable"
        columns={getColumns()}
        data={billsList}
        getRowId={getRowId}
        onClickRow={onClickRow}
        initialState={{ sortBy: initialSortBy }}
        content={content}
        contentState={isBillListLoading ? TableContentState.LOADING : TableContentState.CONTENT}
      />
      {selectedBill ? <BillDrawer bill={selectedBill} isOpen={isOpen} onClose={onClose} /> : null}
    </Box>
  );
};
