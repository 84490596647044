import React, { useEffect, useRef } from 'react';
import { UseModal } from 'src/helpers/react/useModal';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { PayListItemType } from 'src/modules/bills/types';
import { analytics } from 'src/services/analytics';
import { useBatchMarkAsPaid } from '../../BillsGallery/components/useBatchMarkAsPaid';
import { useMarkableBills } from '../../BillsGallery/components/useMarkableBills';
import { PAGE_EVENT } from '../../BillsGallery/SelectedItemsGallery';
import { AccountPlatformModalContent } from './AccountPlatformModalContent';
import { MarkBillsModalContent } from './MarkBillsModalContent';

export type MarkBillsAsPaidModalProps = {
  id: string;
  billIds: string[];
  onSuccess: (bills: PayListItemType[]) => void;
  onError: (error: any) => void;
};

export const MarkBillsAsPaidModal: React.FC<UseModal<MarkBillsAsPaidModalProps>> = ({
  id,
  dismiss,
  billIds = [],
  onSuccess,
  onError,
}) => {
  const { isConnected: isConnectedToAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const { batchMarkAsPaid, isLoading } = useBatchMarkAsPaid();

  const { markableBills, nonMarkableBills } = useMarkableBills(billIds);
  const isPartialBatch = nonMarkableBills.length > 0;

  const analyticsOptions = useRef({
    number_of_bills: billIds.length,
    bills: markableBills.map((bill) => ({ id: bill.id, amount: bill.balance })),
    is_partial: isPartialBatch,
    is_connected_to_accounting_platform: isConnectedToAccountingPlatform,
  });

  useEffect(() => {
    analytics.track(PAGE_EVENT, 'batch-actions-mark-as-paid-modal', analyticsOptions.current);
  }, []);

  const onCancel = () => {
    analytics.track(PAGE_EVENT, 'batch-actions-mark-as-paid-modal-cancel', analyticsOptions.current);
    dismiss();
  };

  const onConfirm = async (accountId?: string) => {
    try {
      analytics.track(PAGE_EVENT, 'batch-actions-mark-as-paid', analyticsOptions.current);
      await batchMarkAsPaid(markableBills, accountId);
      onSuccess(markableBills);
      analytics.track(PAGE_EVENT, 'batch-actions-mark-as-paid-success', analyticsOptions.current);
    } catch (err) {
      onError(err);
    } finally {
      dismiss();
    }
  };

  if (isConnectedToAccountingPlatform) {
    return (
      <AccountPlatformModalContent
        id={id}
        billIds={billIds}
        dismiss={onCancel}
        isLoading={isLoading}
        onConfirm={onConfirm}
        isPartialBatch={isPartialBatch}
      />
    );
  }

  return (
    <MarkBillsModalContent
      id={id}
      billIds={billIds}
      dismiss={onCancel}
      isLoading={isLoading}
      onConfirm={onConfirm}
      isPartialBatch={isPartialBatch}
    />
  );
};
