import { isAfter, isSameDay as isPaymentOnTime } from 'date-fns';
import clone from 'lodash/clone';
import find from 'lodash/find';
import { generatePath } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { getDeliveryMethodById } from 'src/pages/bill/utils/billGetters';
import { globalLocations } from 'src/pages/locations';
import { vendorLocations } from 'src/pages/vendor/locations';
import { getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { isReturnedCheck } from 'src/utils/payments';
import { getAccountNumber4digits } from './bank-account';
import { DeliveryType, FAST_DELIVERY_TYPES, FastCheckDeliveryType, FundingType, VerifiedStatus } from './consts';
import { AccountType, BillType, CompanyInfoType, DeliveryMethodType, DeliveryOptionType, PaymentType } from './types';

export const isPOBox = (addressLine1?: string | null): boolean => {
  // eslint-disable-next-line max-len
  const POBoxRegex = /^((unit|#)?\s?(num|number|#)?\s\d+)|(((p[\s\\.]?[o\s][\\.]?)\s?)|(post\s?office\s?))((box|bin|b\.?)?\s?(num|number|#)?\s?\d+)/;

  return addressLine1 ? POBoxRegex.test(addressLine1.toLowerCase()) : false;
};

const getDeliveryMethodName = (deliveryMethod?: DeliveryMethodType) => {
  const accountType = deliveryMethod?.bankAccount ? deliveryMethod?.bankAccount.accountType : '';
  const accountTypeCapitalize = accountType.charAt(0).toUpperCase() + accountType.slice(1);
  const deliveryInfo =
    deliveryMethod?.bankAccount && deliveryMethod ? getDeliveryMethodDisplay({ deliveryMethod }) : '';

  return `${accountTypeCapitalize} account ${deliveryInfo}`;
};

const deliveryTypePredicate = (deliveryType?: DeliveryType) => (dm: DeliveryMethodType) =>
  dm.deliveryType === deliveryType;

const formatCheckPrintName = (printName: string) =>
  (printName || '')
    .replace(/[^a-zA-Z0-9,:;/&#@‘\-.() ]/g, ' ')
    .replace(/  +/g, ' ')
    .toUpperCase();

type RemoveUnsupportedDeliveryOptionsByBillParams = {
  site: any;
  deliveryOptions: DeliveryOptionType[];
  companyInfo: CompanyInfoType;
  bill: BillType;
  payment: PaymentType;
  fundingSource?: AccountType | null;
  isFastCheckEnabledForAllStates: boolean;
  isFinancingPayment?: boolean;
};

const removeUnsupportedDeliveryOptionsByBill = ({
  site,
  deliveryOptions,
  companyInfo,
  bill,
  payment,
  fundingSource,
  isFastCheckEnabledForAllStates,
  isFinancingPayment,
}: RemoveUnsupportedDeliveryOptionsByBillParams) => {
  const deliveryMethod = getDeliveryMethodById(bill, payment?.deliveryMethodId);
  const isReturnedCheckPayment = isReturnedCheck(payment);

  return removeUnsupportedDeliveryOptions({
    site,
    deliveryOptions,
    companyInfo,
    fundingSource,
    deliveryMethod,
    isFastCheckEnabledForAllStates,
    isReturnedCheckPayment,
    isFinancingPayment,
  });
};

type RemoveUnsupportedDeliveryOptionsParams = {
  site: any;
  deliveryOptions: DeliveryOptionType[];
  companyInfo: CompanyInfoType;
  fundingSource?: AccountType | null;
  deliveryMethod?: DeliveryMethodType | null;
  isFastCheckEnabledForAllStates: boolean;
  isReturnedCheckPayment?: boolean;
  isFinancingPayment?: boolean;
};

const removeUnsupportedDeliveryOptions = ({
  site,
  deliveryOptions,
  companyInfo,
  fundingSource,
  deliveryMethod,
  isFastCheckEnabledForAllStates,
  isReturnedCheckPayment,
  isFinancingPayment,
}: RemoveUnsupportedDeliveryOptionsParams) => {
  const resultDeliveryOptions = clone(deliveryOptions);

  if (resultDeliveryOptions?.length > 1) {
    return resultDeliveryOptions.filter((deliveryOption) =>
      isDeliveryOptionSupported({
        site,
        deliveryOption,
        companyInfo,
        fundingSource,
        deliveryMethod,
        isFastCheckEnabledForAllStates,
        isReturnedCheckPayment,
        isFinancingPayment,
      })
    );
  }

  return resultDeliveryOptions;
};

type IsDeliveryOptionSupportedParams = {
  site: any;
  deliveryOption: DeliveryOptionType;
  companyInfo: CompanyInfoType;
  fundingSource?: AccountType | null;
  deliveryMethod?: DeliveryMethodType | null;
  isFastCheckEnabledForAllStates: boolean;
  isReturnedCheckPayment?: boolean;
  isFinancingPayment?: boolean;
};

const isDeliveryOptionSupported = ({
  site,
  deliveryOption,
  companyInfo,
  fundingSource,
  deliveryMethod,
  isFastCheckEnabledForAllStates,
  isReturnedCheckPayment,
  isFinancingPayment,
}: IsDeliveryOptionSupportedParams) => {
  const { hasFastAch, hasFastCheck, hasExpressCheck } = site;

  if (deliveryOption.type === 'expedited-ach' && (isFinancingPayment || !hasFastAch)) {
    return false;
  }

  if (deliveryOption.type === 'expedited-ach' && fundingSource?.fundingType === FundingType.CARD) {
    return false;
  }

  if (
    deliveryOption.type === FastCheckDeliveryType.OVERNIGHT &&
    (isFinancingPayment ||
      !hasFastCheck ||
      isDeliveryMethodAddressPOBox(deliveryMethod) ||
      !isCompanyInFastCheckState(companyInfo, isFastCheckEnabledForAllStates))
  ) {
    return false;
  }

  if (
    deliveryOption.type === FastCheckDeliveryType.EXPRESS &&
    (isFinancingPayment ||
      !hasExpressCheck ||
      isDeliveryMethodAddressPOBox(deliveryMethod) ||
      !isCompanyInFastCheckState(companyInfo, isFastCheckEnabledForAllStates) ||
      isReturnedCheckPayment)
  ) {
    return false;
  }

  return true;
};

const isCompanyInFastCheckState = (
  { allowedActions, legalState, state }: CompanyInfoType,
  fastCheckEnabledForAllStates: boolean
) => {
  if (state && legalState && allowedActions.canPayWithFastCheck) {
    const NOT_ALLOWED_STATES = ['TX', 'VT'];
    const stateAllowedForFastCheck = !NOT_ALLOWED_STATES.includes(state) && !NOT_ALLOWED_STATES.includes(legalState);

    return fastCheckEnabledForAllStates || stateAllowedForFastCheck;
  }

  return false;
};

const isDeliveryMethodAddressPOBox = (deliveryMethod?: DeliveryMethodType | null) =>
  isPOBox(deliveryMethod?.paperCheck?.addressLine1);

const getDeliveryOption = (deliveryPreference?: string, deliveryOptions?: DeliveryOptionType[]) =>
  find(deliveryOptions, (o) => o.type === deliveryPreference) || (deliveryOptions && deliveryOptions[0]) || null;

const getFastType = (deliveryMethodType) => {
  switch (deliveryMethodType) {
    case DeliveryType.ACH:
      return 'expedited-ach';

    case DeliveryType.CHECK:
      return 'express-check';

    default:
      return deliveryMethodType;
  }
};

const isFastDeliveryType = (deliveryPreference) => FAST_DELIVERY_TYPES.includes(deliveryPreference || '');

const getNameParts = (deliveryMethod: Pick<DeliveryMethodType, 'bankAccount' | 'plaidAccount'>) => {
  if (deliveryMethod?.bankAccount) {
    return {
      displayName: deliveryMethod?.plaidAccount ? '' : 'Bank account',
      institutionName: deliveryMethod?.plaidAccount?.plaidItem?.institutionName,
      identifier: getAccountNumber4digits(deliveryMethod.bankAccount),
    };
  }

  return {
    displayName: '',
    institutionName: '',
    identifier: '',
  };
};

const getAddDeliveryMethodUrlMap = (orgId: number, vendorId?: number) => ({
  [DeliveryType.ACH]: generatePath(vendorLocations.deliveryMethods.ach.createFromBatchPaymentsFlow, {
    orgId,
    id: vendorId,
  }),
  [DeliveryType.CHECK]: generatePath(vendorLocations.deliveryMethods.check.create, {
    orgId,
    id: vendorId,
  }),
  [DeliveryType.VIRTUAL]: generatePath(vendorLocations.deliveryMethods.virtual.create, {
    orgId,
    id: vendorId,
  }),
  [DeliveryType.INTERNATIONAL]: generatePath(globalLocations.vendor.international.billing, {
    orgId,
    id: vendorId,
  }),
});

const getEditDeliveryMethodUrlMap = (orgId: number, vendorId?: number, deliveryMethodId?: number, billId?: string) => ({
  [DeliveryType.ACH]: generatePath(billLocations.pay.editDM.ach, {
    orgId,
    billId,
    deliveryMethodId,
  }),
  [DeliveryType.CHECK]: generatePath(vendorLocations.deliveryMethods.check.edit, {
    orgId,
    id: vendorId,
    deliveryMethodId,
  }),
});

export const isAbsorbFeeDeliveryMethodType = (deliveryType?: DeliveryType) => deliveryType === DeliveryType.CARD;

export const isOptedInToVirtualCardVendor = (deliveryMethods: DeliveryMethodType[]): boolean =>
  deliveryMethods.some((dm: DeliveryMethodType) => dm.deliveryType === DeliveryType.VIRTUAL_CARD);

export const hasVendorCheckDeliveryMethod = (deliveryMethods: DeliveryMethodType[]): boolean =>
  deliveryMethods.some((dm?: DeliveryMethodType) => dm?.deliveryType === DeliveryType.CHECK);

export const isVerifiedStatus = (status?: VerifiedStatus): boolean => {
  const VERIFIED_METHODS = [
    VerifiedStatus.SOFT_VERIFIED,
    VerifiedStatus.PLAID_VERIFIED,
    VerifiedStatus.MICRO_DEPOSIT_VERIFIED,
  ];

  return status ? VERIFIED_METHODS.includes(status) : false;
};

export const getIsDeliveryMethodVerifiedByVerificationMethod = (deliveryMethod: DeliveryMethodType): boolean =>
  [VerifiedStatus.MICRO_DEPOSIT_VERIFIED, VerifiedStatus.PLAID_VERIFIED].includes(
    deliveryMethod?.verifiedStatus as VerifiedStatus
  );

export const isLateDeliveryType = (payment?: PaymentType, bill?: BillType): boolean =>
  payment?.deliveryEta &&
  bill?.dueDate &&
  isAfter(new Date(payment.deliveryEta), new Date(bill.dueDate)) &&
  !isPaymentOnTime(new Date(payment.deliveryEta), new Date(bill.dueDate));

export {
  deliveryTypePredicate,
  formatCheckPrintName,
  getAddDeliveryMethodUrlMap,
  getDeliveryMethodName,
  getDeliveryOption,
  getEditDeliveryMethodUrlMap,
  getFastType,
  getNameParts,
  isDeliveryMethodAddressPOBox,
  isFastDeliveryType,
  removeUnsupportedDeliveryOptions,
  removeUnsupportedDeliveryOptionsByBill,
};
