import { syncQBDLocations } from './syncQBD/locations';
import { syncSSOLocations } from './syncSSO/locations';

export const settingsLocations = {
  base: ['/orgs/:orgId/settings'],
  index: '/orgs/:orgId/settings',
  account: '/orgs/:orgId/settings/account',
  company: '/orgs/:orgId/settings/company',
  paymentMethods: '/orgs/:orgId/settings/payment-methods',
  receivingMethods: '/orgs/:orgId/settings/receiving-methods',
  accountingSoftware: '/orgs/:orgId/settings/accounting-software',
  billingItems: '/orgs/:orgId/settings/billing-items',
  support: '/orgs/:orgId/settings/support',
  vendors: '/orgs/:orgId/settings/vendors',
  notifications: '/orgs/:orgId/settings/notifications',
  users: '/orgs/:orgId/settings/users',
  emailNotifications: '/orgs/:orgId/settings/email-notifications',
  spendManagement: '/orgs/:orgId/settings/spend-management',
  syncQBDLocations,
  syncSSOLocations,
};
