import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Icon as DSIcon, IconNames } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';
import { DetailsSection, DetailsSectionTitle, DetailsSectionValue, Icon } from '../styles';

type CardDetailsSectionProps = {
  title: string;
  value: JSX.Element;
  onCopy?: () => void | undefined;
  disabled?: boolean;
};

export const CardDetailsSection = ({ title, value, onCopy, disabled, ...props }: CardDetailsSectionProps) => (
  <DetailsSection disabled={disabled}>
    <DetailsSectionTitle>
      <MIFormattedText label={title} />
    </DetailsSectionTitle>
    <DetailsSectionValue data-testid={props['data-testid']}>
      {value}
      {onCopy && (
        <Tooltip
          placement="top"
          label={<MIFormattedText label="spendManagement.pages.dashboard.cardDetails.copyToClipboard" />}
          shouldWrapChildren
        >
          <Icon icon={<DSIcon name={IconNames.duplicate} />} aria-label="copy" onClick={onCopy} />
        </Tooltip>
      )}
    </DetailsSectionValue>
  </DetailsSection>
);
