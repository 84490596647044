import { TableContentState } from '../consts';
import { TableContentStateProps } from './useGetTableContentState';

export const getContentState = ({
  loading,
  error,
  isFinishedOnboarding,
  isAllResultsEmpty,
  isFilterOrSearchResultsEmpty,
}: TableContentStateProps) => {
  if (!isFinishedOnboarding) {
    return TableContentState.BLOCKED;
  }

  if (loading) {
    return TableContentState.LOADING;
  }

  if (error) {
    return TableContentState.ERROR;
  }

  if (isFilterOrSearchResultsEmpty) {
    return TableContentState.EMPTY_FILTER_OR_SEARCH_RESULT;
  }

  if (isAllResultsEmpty) {
    return TableContentState.EMPTY;
  }

  return TableContentState.CONTENT;
};
