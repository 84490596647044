import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { useBreak } from 'src/hoc';
import { FinancingPreEligibilityDecision, IneligibilityReason } from 'src/modules/financing/types';
import { isInternationalVendor } from 'src/pages/vendor/international-delivery-method/utils';
import { getIsRecurring, getPayment } from 'src/redux/payBillWizard/selectors';
import { FeatureFlags } from 'src/utils/featureFlags';
import { BillType, VendorType } from 'src/utils/types';

type Props = {
  preEligibilityDecision?: FinancingPreEligibilityDecision;
  bill: BillType;
  ineligibilityReason?: IneligibilityReason;
};

export const useShouldShowInstallmentsFundingSource = ({ preEligibilityDecision, bill }: Props) => {
  const [isFinancingEnabled] = featureFlags.useFeature(FeatureFlags.MelioComAPFinancingEnabled, false);
  const payment = useSelector(getPayment);
  const isPaymentExists = !!payment?.id;

  const { isMobile } = useBreak();
  const isRecurringBill = useSelector(getIsRecurring);
  const isInternational = isInternationalVendor(bill.vendor as VendorType);

  const ineligible = preEligibilityDecision === FinancingPreEligibilityDecision.Ineligible || !preEligibilityDecision;
  const baseRules = !isMobile && isFinancingEnabled && !bill.isPaymentRequest && !isRecurringBill && !isPaymentExists;

  return {
    showInstallments: baseRules && !ineligible,
    disabledInstallments: baseRules && !isInternational && ineligible,
  };
};
