import { IconNames } from 'src/core/ds/icon';
import { globalLocations } from 'src/pages/locations';
import { MenuItemPropsType } from '../../types/type';

export const useSpendManagementItems = (): Array<MenuItemPropsType> => {
  const links: Array<MenuItemPropsType> = [
    {
      key: 'menu-item-spend-management',
      to: globalLocations.spendManagement.index,
      label: 'nav.spendManagement.dashboard',
      icon: IconNames.creditCard,
      eventName: 'spend-management-dashboard',
    },
  ];

  return links;
};
