import get from 'lodash/get';
import { usersApi } from 'src/modules/users/api';
import { capture } from 'src/utils/error-tracking';

export function reportSessionAnalyticsEvent(userId: number, orgId: number, eventName: string) {
  const fromStorage = window.localStorage.getItem('idle-timer');
  const idleTimerData = fromStorage && JSON.parse(fromStorage);
  const sessionId = get(idleTimerData, 'uuid', 'NA');

  usersApi.trackEvent(userId.toString(), eventName, {
    table: 'organizations',
    id: orgId.toString(),
    key: 'sessionId',
    value: sessionId,
  });

  if (sessionId === 'NA') {
    capture(new Error('Could not get sessionId form idle-timer'), {
      fromStorage: JSON.stringify(fromStorage),
      idleTimerData: JSON.stringify(idleTimerData),
    });
  }
}
