import { mapPaymentToInvoice } from 'src/modules/invoices/utils';
import { mapGetProListItemToDateTableRowType } from 'src/pages/get-pro/utils';
import { MSNDataTableRowType, PaymentsInListItemType } from './types';

export const mapPaymentsInListToTableRows = (item: PaymentsInListItemType): MSNDataTableRowType => {
  const invoice = mapPaymentToInvoice(item);

  return {
    ...mapGetProListItemToDateTableRowType(invoice),
    customer: {
      name: item.customerName,
      isMelioUser: !!item.isMelioUser,
    },
    deliveryMethod: item.deliveryMethod,
    invoice,
  };
};
