import * as React from 'react';
import { ContactType } from 'src/utils/types';
import CustomerDetails from './CustomerDetails';

type Props = {
  customer?: Partial<ContactType>;
};

const CustomerCard: React.FC<Props> = ({ customer }) => <CustomerDetails {...customer} />;

export default CustomerCard;
