import { useSelector } from 'react-redux';
import { VendorCompanyLegalInfoForm } from 'src/pages/onboarding/vendor-company-info/components/VendorCompanyLegalInfoForm';
import { useVendorCompanyInfoConfig } from 'src/pages/onboarding/vendor-company-info/context/VendorCompanyInfoContext';
import { useVendorCompanyInfoUpdates } from 'src/pages/onboarding/vendor-company-info/hooks/useVendorCompanyInfoUpdates';
import { getCompanyInfo } from 'src/redux/user/selectors';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyLegalInfoPageContainer = ({
  companyInfoField,
  nextStepURL,
  prevStepURL,
  inputFields,
  ...args
}: Props) => {
  const { onNext, onPrev } = useVendorCompanyInfoConfig<'VendorCompanyLegalInfo'>({
    page: 'VendorCompanyLegalInfo',
  });

  const props = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
    onNextStep: onNext,
    onPrevStep: onPrev,
  });

  const companyInfo = useSelector(getCompanyInfo);

  return <VendorCompanyLegalInfoForm {...args} companyInfo={companyInfo} {...props} />;
};
