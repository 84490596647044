import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';

type Props = {
  goNextFlow: () => void;
  goCancel?: () => void;
};

export const CantFindBankPage = ({ goNextFlow, goCancel }: Props) => (
  <SuccessLayoutPage
    illustration={IllustrationName.bankQuestion}
    title="onboarding.fundingSources.bank.cantFind.title"
    text="onboarding.fundingSources.bank.cantFind.subtitle"
    buttonLabel="onboarding.fundingSources.bank.cantFind.next"
    buttonAction={goNextFlow}
    linkLabel={goCancel ? 'onboarding.fundingSources.bank.cantFind.cancel' : ''}
    linkAction={goCancel}
    hideHeader
    goExit={goCancel}
  />
);
