import * as React from 'react';
import styled from 'styled-components';
import ListSearchBar from 'src/components/common/ListSearchBar';
import { useQuerySearchUpdater } from 'src/hoc/withListSearchBarContext';
import { EVENT_PAGE } from 'src/pages/get-pro/consts';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';

const SearchBard: React.FC = () => {
  useQuerySearchUpdater();
  const { listParams, setStatuses, setFilter } = useGetProListParams();
  const onClear = () => {
    setFilter(undefined);
    setStatuses(undefined);
  };

  return (
    <SearchBarContainer>
      <ListSearchBar eventPage={`${EVENT_PAGE}-${listParams.filters.status}`} onClear={onClear} />
    </SearchBarContainer>
  );
};

export default SearchBard;

const SearchBarContainer = styled.div`
  flex: 0 0 40rem;
`;
