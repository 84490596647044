import * as React from 'react';
import Box from 'src/core/ds/box';

type ItemizedListProps = {
  children?: React.ReactNode;
  showLastBorder?: boolean;
};

export const ItemizedList = (props: ItemizedListProps) => (
  <Box sx={ItemizedListStyle(props.showLastBorder)}>{props.children}</Box>
);

const ItemizedListStyle = (showLastBorder = true) => ({
  '> *': {
    pb: 4,
    mt: 4,
    borderBottom: '1px',
    borderColor: 'grey.400',
  },
  '> *:last-child': {
    ...(showLastBorder ? { borderBottom: 'none', pb: 0 } : {}),
  },
});
