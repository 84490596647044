import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { MenuItem } from 'src/core/ds/menu';
import { FundingSourceLabel } from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceLabel';
import { isFundingSourceAllowedForInternational } from 'src/pages/vendor/international-delivery-method/utils';
import { FeatureFlags } from 'src/utils/consts';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSource: AccountType;
  onClick?: (fundingSource: AccountType) => void;
  isSelected?: boolean;
  isInternationalVendor?: boolean;
  source?: string;
  testId?: string;
};

export const FundingSourceMenuItem = ({
  fundingSource,
  onClick,
  isSelected,
  isInternationalVendor,
  testId = '',
}: Props) => {
  const [isInternationalMastercardEnabled] = useFeature(FeatureFlags.EnableMastercardFSForInternational, false);

  const handleClick = () => {
    if (onClick) {
      onClick(fundingSource);
    }
  };

  return (
    <MenuItem
      bg={isSelected ? 'grey.300' : 'white'}
      onClick={handleClick}
      testId={`funding-source-menu-item-${fundingSource.id}${testId}`}
      isDisabled={
        isInternationalVendor &&
        !isFundingSourceAllowedForInternational({
          fundingSource,
          isInternationalMastercardEnabled,
        })
      }
    >
      <FundingSourceLabel fundingSource={fundingSource} />
    </MenuItem>
  );
};
