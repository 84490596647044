import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { IconSize } from 'src/core/ds/icon';
import { useMicroDepositParams } from 'src/hooks';
import { useGetFundingSourceDisplayName } from 'src/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import FundingSourceIcon from 'src/pages/regular-batch-payments/components/menu/fundingSource/FundingSourceIcon';
import {
  getPaymentMethodIcon,
  getShortPaymentMethodLabel,
} from 'src/pages/regular-batch-payments/components/menu/fundingSource/utils';
import { analytics } from 'src/services/analytics';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSource: AccountType;
  isSelectedByFirm: boolean;
};

const EVENT_PAGE = 'billing-method-setting';
const MICRO_DEPOSIT_EVENT_PAGE = 'billing-verify-manual-account';

export const ExposedSelectedMethodCard = ({ fundingSource, isSelectedByFirm }: Props) => {
  const { icon, imageSrc } = getPaymentMethodIcon(fundingSource);
  const { nickname } = getShortPaymentMethodLabel(fundingSource);
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const fundingSourceDisplayName = getFundingSourceDisplayName({ fundingSource, useShortName: false });
  const { verifyingId, setVerifyingId, state, actions } = useMicroDepositParams(MICRO_DEPOSIT_EVENT_PAGE);

  const handleVerifyClicked = () => {
    analytics.track(EVENT_PAGE, 'verify-click');
    setVerifyingId(fundingSource.id);
  };

  const handleVerifyFinished = () => {
    analytics.track(EVENT_PAGE, 'verify-finish');
    setVerifyingId(null);
  };

  return (
    <Flex align="center">
      <Box marginInlineEnd={5}>
        <FundingSourceIcon imageSrc={imageSrc} icon={icon} size="2.4rem" iconSize={IconSize.lg} />
      </Box>
      <Flex direction="column">
        <Flex align="center">
          <Box textStyle="body2Semi" data-testid="funding-source-name">
            {nickname || fundingSourceDisplayName}
          </Box>
          {isSelectedByFirm ? (
            <Badge
              marginInlineStart={2}
              testId="firm-billing-card-badge"
              color={BadgeColors.NEUTRAL_DARK}
              variant={BadgeVariants.OUTLINED}
              label="billing.addMethod.firmBadge"
            />
          ) : null}
        </Flex>
        <Box textStyle="body4" color="grey.600">
          {fundingSource.isVerified ? (
            <MIFormattedText label="settings.billing.paymentMethod.selectedMethodSubTitle" />
          ) : (
            <>
              <MIFormattedText label="settings.billing.paymentMethod.unverifiedMethodSubTitle" />
              <Box as="span" textStyle="link3" onClick={handleVerifyClicked} marginInlineStart={0.5}>
                <MIFormattedText label="settings.billing.paymentMethod.verifyAccountLink" />
              </Box>
            </>
          )}
        </Box>
      </Flex>
      {verifyingId ? (
        <VerifyMicroDeposits
          {...state}
          {...actions}
          key={verifyingId}
          verifyingId={verifyingId}
          onVerifyFinished={handleVerifyFinished}
          eventPage={MICRO_DEPOSIT_EVENT_PAGE}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      ) : null}
    </Flex>
  );
};
