import * as React from 'react';
import styled from 'styled-components';
import { devices } from 'src//theme/appDevices';
import { RoleTag, UserRoleTag } from 'src/components/user/UserRoleTag';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import type { UserSummaryType } from 'src/utils/types';
import { getFullName } from 'src/utils/user';

export type UserSummaryProps = {
  user: UserSummaryType;
  userRole?: RoleTag | '';
  details: React.ReactNode | string;
  actions?: any[];
  rightSideRender?: React.ReactNode;
};

export function UserSummary({ user, userRole, actions, details, rightSideRender }: UserSummaryProps) {
  const fullName = getFullName(user?.firstName, user?.lastName);

  return (
    <DetailsContainer data-testid={`user-${user.id}`}>
      <Avatar link={user.userLogoDetails?.logoUrl} name={fullName} size={Size.lg} textColor="white" />
      <UserData>
        <UserNameContainer>
          <UserName data-testid={`user-name-${user.id}`}>{fullName}</UserName>
          {!!userRole && <UserRoleTag testId={`user-role-${user.id}`} userRole={userRole} />}
        </UserNameContainer>
        <UserDetails>{details}</UserDetails>
      </UserData>

      {rightSideRender && <RightSide withActions={!!actions}>{rightSideRender}</RightSide>}

      <ActionContainer withRightContent={!!rightSideRender}>
        {actions && (
          <Menu autoSelect={false} isLazy>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Icon name={IconNames.more} />}
              variant="action"
              placement="top"
              data-testid={`dots-action-menu-${user.id}`}
            />
            <MenuList>
              {actions.map(({ label, action, negative }) => (
                <MenuItem key={label} label={label} onClick={action} color={negative ? 'red.500' : 'black'} />
              ))}
            </MenuList>
          </Menu>
        )}
      </ActionContainer>
    </DetailsContainer>
  );
}

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

const UserDetails = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: 1.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const UserData = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
  margin-left: 1.4rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 30rem;
  }
`;

const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.text.color.main};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 2.3rem;
  margin-right: 1rem;

  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 25rem;
  }
`;

const ActionContainer = styled.div<{ withRightContent?: boolean }>`
  align-self: center;
  flex-grow: ${(props) => (props.withRightContent ? '0' : '10')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 1rem;
`;

const RightSide = styled.div<{ withActions?: boolean }>`
  align-self: center;
  margin-left: auto;
  margin-right: ${(props) => (props.withActions ? '3.8rem' : '8rem')};
`;
