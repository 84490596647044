import React from 'react';
import styled from 'styled-components';
import Box from 'src/core/ds/box';
import { HStack } from 'src/core/ds/stack';

type MaskedSpacedNumericTextProps = {
  text: string;
  isMasked: boolean;
  lastNDigitsUnmasked: false | number;
  letterWidth: number;
  isSelectable?: boolean;
};

export const MaskedNumericText = ({
  text = '',
  isMasked,
  lastNDigitsUnmasked,
  letterWidth,
  isSelectable,
}: MaskedSpacedNumericTextProps) => (
  <HStack spacing={0}>
    {(isMasked
      ? `${(lastNDigitsUnmasked ? text.substring(0, text.length - lastNDigitsUnmasked) : text).replace(/[0-9]/g, '•')}${
          lastNDigitsUnmasked ? text.slice(-lastNDigitsUnmasked) : ''
        }`
      : text
    )
      .split('')
      .map((chr, i) => (
        <Box key={`${i}`} w={`${letterWidth}px`} textAlign="center">
          <Char selectable={isSelectable}>{chr}</Char>
        </Box>
      ))}
  </HStack>
);

const Char = styled.div<{ selectable?: boolean }>`
  flex-direction: column;
  width: 100%;
  user-select: ${({ selectable }) => (selectable ? 'auto' : 'none')};
`;
