import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { CompanyInfoDetailsForm } from 'src/pages/onboarding/company-info/components/CompanyInfoDetailsForm';
import { ModelView } from 'src/ui/form';
import { ScreenMode } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { CompanyInfoType, GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  onToggleMode: () => void;
  isEditMode: boolean;
  editable: boolean;
  isLoading: boolean;
  mode: ScreenMode;
  model: ModelView<Partial<CompanyInfoType>>;
  address: GoogleCombinedAddressType;
  onAddressChange: (address: GoogleCombinedAddressType) => void;
  validationErrors: Record<string, any>;
  goToManualAddress: () => void;
};

export const CompanyInformation = ({
  onToggleMode,
  isEditMode,
  editable,
  isLoading,
  mode,
  model,
  address,
  onAddressChange,
  validationErrors,
  goToManualAddress,
}: Props) => {
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      onToggleMode();
    }
  };

  return (
    <MICard>
      <MICardForm onKeyDown={onKeyPressed} testId="company-info">
        <Flex align="center" justify="space-between" minH="4.2rem" mb={2}>
          <MICardTitle label="settings.company.cardTitle" />
          {!isEditMode && editable && (
            <Button
              isLoading={isLoading}
              onClick={onToggleMode}
              label="settings.actions.edit"
              size={ButtonSizes.md}
              variant={ButtonVariants.tertiary}
            />
          )}
        </Flex>
        <CompanyInfoDetailsForm
          mode={mode}
          companyName={model.companyName}
          phone={model.phone}
          contactFirstName={model.contactFirstName}
          contactLastName={model.contactLastName}
          address={address}
          onAddressChange={onAddressChange}
          validationErrors={validationErrors}
          goToManualAddress={goToManualAddress}
          email={model.ownedVendorEmail}
        />
      </MICardForm>
    </MICard>
  );
};
