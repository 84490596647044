import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { billLocations } from 'src/pages/bill/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useQBORedirectQueryParams } from 'src/pages/settings/hooks/useQBORedirectQueryParams';
import { settingsLocations } from 'src/pages/settings/locations';
import { VendorOptionType } from 'src/pages/vendor-directory/select-vendor/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CompanyFormPage, LinkAccountingSoftwareError, NotificationCardTypes } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import { BillType } from 'src/utils/types';
import NewBillCreateOptionsPage from './components/NewBillCreateOptionsPage';
import { withNewBillData } from './hoc/withNewBillData';

type Props = {
  onChangeAttachment: (file: File, loadBillFromAttachment?: boolean) => void;
  goExit: () => void;
  handleUploadCancel: () => void;
  isUploading: boolean;
  bill: BillType;
  files: readonly number[];
  vendors: readonly VendorOptionType[];
  isUploadError: boolean;
};

const eventPage = 'bill-create-options';

const NewBillCreateOptionsPageContainerBase = ({
  bill,
  files,
  vendors,
  goExit,
  isUploading,
  handleUploadCancel,
  isUploadError,
  onChangeAttachment,
}: Props) => {
  const { navigate } = useNavigator();
  const orgId = useSelector(getOrgId);
  const locationState = useLocation<any>().state;
  const { QBCompany, error } = useQBORedirectQueryParams();

  useEffect(() => {
    if (error === LinkAccountingSoftwareError.DIFF_REALMID) {
      navigate(generatePath(billLocations.create.options, { orgId }), false, {
        errorNotification: {
          type: NotificationCardTypes.ERROR,
          subtitle: {
            label: 'settings.newAccountingSoftware.Quickbooks.linkError.diffRealmid',
            values: {
              companyName: QBCompany,
            },
          },
        },
      });
    }

    if (error && error !== LinkAccountingSoftwareError.ACCESS_DENIED) {
      navigate(generatePath(settingsLocations.accountingSoftware, { orgId }));
    }
  }, [QBCompany, error, navigate, orgId]);

  useEffect(() => {
    // TODO: remove location state, use redux instead
    const data = {
      bill,
      vendors,
      files,
    };

    if (!isEmpty(files) && bill) {
      navigate(billLocations.create.index, false, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bill]);

  const goManual = () => {
    analytics.track(eventPage, 'auto-reader-error-enter-manually');
    navigate(billLocations.create.index, false, {
      manually: true,
    });
  };

  const handleManualClick = () => {
    analytics.track(eventPage, 'enter-manually');
    const { id } = locationState || {};

    if (id) {
      navigate(
        `${generatePath(billLocations.create.index, {
          orgId,
        })}?vendorId=${id}&manually=true`,
        false
      );
    } else {
      navigate(billLocations.create.index, false, {
        manually: true,
      });
    }
  };

  const handleQuickbooksClick = () => {
    analytics.track(eventPage, 'sync-quickbooks');

    intuit.goConnectToIntuit({
      intuitReturnUrl: generatePath(onboardingLocations.accountingSoftware.quickbooks.backToBills, { orgId }),
      intuitErrorReturnUrl: generatePath(billLocations.create.options, { orgId }),
      linkOrgId: orgId,
    });
  };

  const navigateToBills = (eventName: string) => {
    analytics.track(eventPage, eventName);
    navigate(billLocations.index, false, {
      ...locationState,
      errorNotification: undefined,
    });
  };

  const handleSkipCreateBillClick = () => {
    navigateToBills('dont-have-a-bill');
  };

  const handleCancelCreateBillClick = () => {
    navigateToBills('cancel-create-bill');
  };

  const { errorNotification, origin } = locationState || {};
  const isOnboarding = origin === CompanyFormPage.ONBOARDING;

  return (
    <NewBillCreateOptionsPage
      goExit={goExit}
      goManual={goManual}
      onChangeAttachment={onChangeAttachment}
      handleUploadCancel={handleUploadCancel}
      handleQuickbooksClick={handleQuickbooksClick}
      handleSkipCreateBillClick={handleSkipCreateBillClick}
      handleCancelCreateBillClick={handleCancelCreateBillClick}
      handleManualClick={handleManualClick}
      isUploading={isUploading}
      isOnboarding={isOnboarding}
      isUploadError={isUploadError}
      errorNotification={errorNotification}
    />
  );
};

export const NewBillCreateOptionsPageContainer = withNewBillData()(NewBillCreateOptionsPageContainerBase);
