import get from 'lodash/get';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { vendorRequestsApi } from 'src/modules/vendors/api';
import { convertToDisplayAddressForm } from 'src/utils/address';

const name = 'vendors';
const stateName = 'vendorInfo';
const vendorInfo = createApiCallSlice<any, any>({
  api: vendorRequestsApi.getVendorInfo,
  name: '[VENDOR] VENDOR_MANAGEMENT_INFO',
  initialState: {
    [stateName]: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      const { vendorId } = action.payload;
      state[stateName][vendorId] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const vendorId = get(action, 'meta.identifier.vendorId', '');
      const firstAccountingApiSuggestion = get(action, 'payload.0');
      delete state[stateName][vendorId];
      const vendorAddressFromAccountingApi =
        firstAccountingApiSuggestion?.address && convertToDisplayAddressForm(firstAccountingApiSuggestion?.address);
      const vendor = state.byId[vendorId];
      const newVendorAddress = vendor?.address || vendorAddressFromAccountingApi;

      if (newVendorAddress) {
        state.byId[vendorId] = {
          ...vendor,
          address: newVendorAddress,
          printName: firstAccountingApiSuggestion?.title,
        };
      }
    },
    [ON_FAILURE](state, action) {
      const { vendorId } = action.payload;
      state[stateName][vendorId] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    value: (state, vendorId) => state[name].byId[vendorId]?.address,
    printName: (vendorId) => (state) => state[name].byId[vendorId]?.printName,
    address: (vendorId) => (state) => state[name].byId[vendorId]?.address,
    vendorLoading: (vendorId) => (state) => state[name][stateName]?.[vendorId]?.loading,
    loading: (state, vendorId) => state[name][stateName]?.[vendorId]?.loading,
  },
});

export { vendorInfo };
