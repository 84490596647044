import { featureFlags } from '@melio/shared-web';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { iconGroupPortalId } from 'src/components/list/DesktopListHeader';
import { Separator } from 'src/core/ds/separator';
import { useBreak } from 'src/hoc';
import withListContainer, { PageContainerProps } from 'src/hoc/withListContainer';
import { useListSearchBarContext } from 'src/hoc/withListSearchBarContext';
import { PreferredSortByTabType } from 'src/modules/bills/types';
import { paymentsStore } from 'src/modules/payments/payment-store';
import useUpdateUserPreferences from 'src/modules/users/hooks/useUpdateUserPreferences';
import { billLocations } from 'src/pages/bill/locations';
import { CopyEmailAddress } from 'src/pages/pay/components/copy-email-address/CopyEmailAddress';
import { FilterMenu } from 'src/pages/pay/components/filters';
import { filterMenuKeys } from 'src/pages/pay/components/filters/consts';
import { OnSetFilter } from 'src/pages/pay/components/filters/types';
import { useFilterMenu } from 'src/pages/pay/components/filters/useFilterMenu';
import { useAnnouncements } from 'src/pages/pay/components/product-announcements/bill-inbox/useAnnouncements';
import { topBannerPortalId } from 'src/pages/pay/consts';
import { SortMenu } from 'src/pay/components/SortMenu';
import { getOrgId } from 'src/redux/user/selectors';
import { FeatureFlags, PAGINATION, UserPreferencesKey } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { DEFAULT_PAY_LIST_FILTERS } from 'src/utils/payListConsts';
import { stringifyQs } from 'src/utils/query-utils';
import { usePayListItems } from './hooks/usePayListItems';
import { useQueryParams } from './hooks/useQueryParams';
import { PayListContainer } from './PayListContainer';
import { billStatusToPayTabs, setPaymentRequestApprovalStatus } from './utils';

const eventPage = 'bills';

const PayListPageContainerBase = ({ filters: rawFilters, setFilters, setSelected, navigate }: PageContainerProps) => {
  const node = useRef();
  const [{ contextSearchFilterValue, setContextSearchFilterValue, clearSearchContext }] = useListSearchBarContext();
  const { id } = useQueryString();
  const { updateUserPreference } = useUpdateUserPreferences();
  const orgId = useSelector(getOrgId);
  const filters = useMemo(
    () => ({
      ...rawFilters,
      itemStatus: rawFilters.itemStatus ?? [],
      dueDate: rawFilters.dueDate ?? [],
      vendorId: ([] as string[]).concat(rawFilters.vendorId ?? []),
    }),
    [rawFilters]
  );
  const { status, start, limit, search, sorting, itemStatus, dueDate, vendorId } = filters;
  const currentTab = billStatusToPayTabs(status);
  const approvalDecisionStatus = useSelector(paymentsStore.selectors.approvalDecisionStatus);
  const { isDesktop } = useBreak();
  const { replace } = useQueryParams();
  const { loadItems, payListItems, isLoading, totalCount } = usePayListItems();
  const [isBillInboxEnabled] = featureFlags.useFeature(FeatureFlags.BillInbox, false);
  const [sortPerTab, setSortPerTab] = useState<PreferredSortByTabType>({});

  const location = useLocation();

  useEffect(() => {
    if (search) {
      setContextSearchFilterValue(search);
    } else {
      clearSearchContext();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shouldSelectFirstItem = !id && isDesktop && !filters.search && payListItems.length > 0;

    if (shouldSelectFirstItem) {
      replace({ id: payListItems[0].id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payListItems]);

  useEffect(() => {
    if (contextSearchFilterValue !== (search || '')) {
      setFilters({
        ...filters,
        start: PAGINATION.DEFAULT_START,
        search: contextSearchFilterValue,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSearchFilterValue, search]);

  useEffect(() => {
    if (search && search !== contextSearchFilterValue) {
      setContextSearchFilterValue(search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const setDefaultFilters = () => {
    setFilters(DEFAULT_PAY_LIST_FILTERS);
  };

  useEffect(() => {
    if (status) {
      loadItems();
    } else {
      setDefaultFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, start, limit, search, sorting, itemStatus.length, dueDate.length, vendorId.length]);

  useEffect(() => {
    setSortPerTab((value) => ({ ...value, [status]: sorting }));
  }, [status, sorting]);

  useEffect(() => {
    if (!approvalDecisionStatus.loading) {
      if (status) {
        loadItems();
      } else {
        setDefaultFilters();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalDecisionStatus.loading]);

  const updatePaymentRequestApprovalStatus = async (
    paymentRequestId: number,
    isApproved: boolean,
    isTrusted: boolean
  ) => setPaymentRequestApprovalStatus(paymentRequestId, isApproved, isTrusted, orgId, loadItems, setSelected);

  const goCreateBill = () => {
    navigate(billLocations.create.options);
  };

  const updateIsClosedBillsZeroStatePreference = async () => {
    await updateUserPreference({ key: UserPreferencesKey.IsClosedBillsZeroState, value: true });
  };

  const onSetFilter: OnSetFilter = useCallback(
    (activeFilters) =>
      setFilters({
        ...filters,
        ...activeFilters,
        start: PAGINATION.DEFAULT_START,
      }),
    [setFilters, filters]
  );
  const { activeFilters, ...filterMenuProps } = useFilterMenu({
    activeFilters: pick(filters, filterMenuKeys),
    currentTab,
  });

  const onClearAll = useCallback(() => {
    setFilters(omit(filters, filterMenuKeys));
  }, [filters, setFilters]);

  const { onClose: onCloseBillInboxAnnouncement, shouldShowAnnouncement, setAfterCloseMessage } = useAnnouncements({
    eventPage,
    currentTab,
    search,
    sorting,
    activeFilters,
    items: payListItems,
    isLoadingItems: isLoading,
  });
  const iconGroupPortal =
    document &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById(iconGroupPortalId)! &&
    createPortal(
      <>
        {isBillInboxEnabled ? (
          <>
            <CopyEmailAddress
              setAfterCloseMessage={setAfterCloseMessage}
              shouldShowAnnouncement={shouldShowAnnouncement}
            />
            <Separator border="0px" size="sm" color="grey.500" orientation="vertical" />
          </>
        ) : null}

        <SortMenu />
        <FilterMenu
          {...filterMenuProps}
          currentTab={currentTab}
          onClearAll={onClearAll}
          activeFilters={activeFilters}
          onSetFilter={onSetFilter}
          isBillInboxEnabled={isBillInboxEnabled}
        />
      </>,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById(iconGroupPortalId)!
    );

  const topBannerPortal =
    document &&
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    document.getElementById(topBannerPortalId)! &&
    createPortal(
      <CustomFeed feedType="topbanner" />,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.getElementById(topBannerPortalId)!
    );

  return (
    <>
      {topBannerPortal}
      {iconGroupPortal}
      <PayListContainer
        itemId={id}
        eventPage={eventPage}
        filters={filters}
        onClearFilters={onClearAll}
        listViewRef={node}
        items={payListItems || []}
        isLoading={isLoading}
        goCreateBill={goCreateBill}
        updateIsClosedBillsZeroStatePreference={updateIsClosedBillsZeroStatePreference}
        itemsCount={totalCount || 0}
        reloadBills={loadItems}
        setPaymentRequestApprovalStatus={updatePaymentRequestApprovalStatus}
        backPath={`${location.pathname}?${stringifyQs(omit(filters, 'id'))}`}
        onCloseBillInboxAnnouncement={onCloseBillInboxAnnouncement}
        shouldShowBillInboxAnnouncement={shouldShowAnnouncement}
        preferredSortByTab={sortPerTab}
      />
    </>
  );
};

export default compose(withListContainer())(PayListPageContainerBase);
