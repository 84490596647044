import { createDeleteSlice } from 'src/helpers/redux/restDeleteSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from '../constants';

const proInvoicesMarkAsPaidSlice = createDeleteSlice({
  storeName: name,
  api: getProApi.invoices.markAsPaid,
  actionName: 'proMarkAsPaid',
});

export default proInvoicesMarkAsPaidSlice;
