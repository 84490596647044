// A solution for the new environments
const isDevEnv = (window as any).APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'dev';
const baseUrl = isDevEnv
  ? 'https://dev-app.meliopayments.com'
  : `https://app.${(window as any).APP_ENV}.meliopayments.com`;

export default {
  server: {
    baseUrl,
  },
  web: {
    baseUrl,
  },
  platformApp: {
    baseUrl: `https://partnerships.${(window as any).APP_ENV}.melioservices.com/melio`,
  },
  logger: {
    shouldPrintLogs: true,
  },
  analytics: {
    shouldPrintEvents: true,
  },
  services: {
    intuit: {
      clientId: 'Q0j9Z5zFOaS6t66gZM1csbnlbIFA90w8YnNRvCBbXzdcUMqzr5',
    },
  },
  qbo: {
    web: {
      baseUrl: 'https://dev-intuit-app.meliopayments.com',
    },
  },
  meliome: {
    baseUrl: `${baseUrl}/meliome/pay/`,
  },
  featureFlags: {},
};
