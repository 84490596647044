export enum DrawerTypes {
  Invoice = 'invoice',
  Request = 'request',
  Payment = 'payment',
  BatchSend = 'batchSend',
  Customer = 'customer',
}

export enum AccountingSoftwareCreateOrigin {
  QUICKBOOKS_ONLINE = 'quickbooks-online',
  CODAT_INTEGRATION = 'codat-integration',
}
