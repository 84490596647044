import zipObject from 'lodash/zipObject';
import { useState } from 'react';
import { buildDynamicField } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/form/beneficial-owners/utils';
import { BeneficialOwner } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { uuid } from 'src/utils/uuid';

const beneficialOwnerFormFields = [
  'firstName',
  'lastName',
  'ownershipPercentage',
  'dateOfBirth',
  'email',
  'ssn',
] as (keyof BeneficialOwner)[];

const createBeneficialOwnerModel = (uuid: string) =>
  zipObject(
    beneficialOwnerFormFields.map((field) => buildDynamicField(uuid, field)),
    beneficialOwnerFormFields.map(() => '')
  );

export const useBeneficialOwners = () => {
  const [beneficialOwnersUUIDs, setBeneficialOwnersUUIDs] = useState<string[]>([]);

  const getBeneficialOwnerModelToAdd = () => {
    const newBeneficialOwnerUUID = uuid();
    setBeneficialOwnersUUIDs((prevUUIDs) => [...prevUUIDs, newBeneficialOwnerUUID]);

    return createBeneficialOwnerModel(newBeneficialOwnerUUID);
  };

  const getBeneficialOwnerModelFieldsToRemove = (uuid: string) => {
    setBeneficialOwnersUUIDs((existingUUIDs) => existingUUIDs.filter((existingUUID) => existingUUID !== uuid));

    return beneficialOwnerFormFields.map((field) => buildDynamicField(uuid, field));
  };

  return {
    beneficialOwnersUUIDs,
    getBeneficialOwnerModelToAdd,
    getBeneficialOwnerModelFieldsToRemove,
  };
};
