import { generatePath } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { vendorLocations } from 'src/pages/vendor/locations';

export const showSearchBar = (currentPathname: string, orgId: number): boolean => {
  const searchablePathnames = [
    vendorLocations.index,
    billLocations.index,
    getPaidLocations.base,
    ...contactsLocations.base,
    getPaidLocations.base,
  ];

  return !!orgId && searchablePathnames.some((pathname) => generatePath(pathname, { orgId }) === currentPathname);
};
