import get from 'lodash/get';
import moment, { Moment } from 'moment';
import { BrandName } from 'src/core/ds/brandFullLogo';
import Amex from '../images/icons/amex.svg';
import MasterCard from '../images/icons/mastercard.svg';
import Visa from '../images/icons/visa.svg';
import { CardNetworkTypes, CardTypes } from './consts';
import { AccountType, CardAccountType } from './types';

export const cardIcons = {
  Visa,
  MasterCard,
  Amex,
};

export const brandNameByCardNetwork = {
  [CardNetworkTypes.VISA]: BrandName.visa,
  [CardNetworkTypes.MASTERCARD]: BrandName.masterCard,
  [CardNetworkTypes.AMEX]: BrandName.amex,
};

const getCardIconName = (fs) =>
  get(fs, 'cardAccount.cardType', CardTypes.CREDIT) === CardTypes.CREDIT
    ? 'icon-credit-card-icon'
    : 'icon-debit-card-icon';

export const getCardImageUrl = (fs?: AccountType) => {
  if (!fs) return '';

  const cardName = get(fs, 'cardAccount.network', '').toUpperCase();
  const icon = CardNetworkTypes[cardName];

  if (icon) return cardIcons[icon];

  return '';
};

export const getCardIcon = (fs: AccountType) => (getCardImageUrl(fs) !== '' ? '' : getCardIconName(fs));

export const isCardExpired = (
  card: Pick<CardAccountType, 'expiration'> | null,
  dateToCompare: Moment | Date | string = moment()
) => {
  const convertedExpirationDate = moment(card?.expiration, 'MM-YY').add(1, 'M');

  return moment(convertedExpirationDate).isBefore(moment(dateToCompare));
};

const getConvertedDate = (date?: Date | string) => moment(date, 'MM/YYYY').toDate();

export const convertDateToMonthYearFormat = (date?: Date | string) => moment(getConvertedDate(date)).format('MMM YYYY');

export const getCard4Lastdigits = (card: Partial<CardAccountType> | null | undefined): string =>
  card?.card4digits || '';

export const getExpirationDateHint = (card: CardAccountType | null) => {
  let hint = '';

  if (isCardExpired(card)) {
    hint = 'bills.pay.fundingSource.expired';
  } else if (moment(getConvertedDate(card?.expiration)).isSame(moment(), 'month')) {
    hint = 'bills.pay.fundingSource.expirationDateLabel';
  }

  return hint;
};
