import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { AcceptRequestRouter } from './accept-request/AcceptRequestRouter';
import { AddAccountingFirmRouter } from './add-accounting-firm/AddAccountingFirmRouter';
import { CompanyTypeError, ConnectionError, NoClientsError } from './import-clients/Errors';
import { Instructions } from './import-clients/Instructions';
import { Success } from './import-clients/Success';
import { VerifyImportedClients } from './import-clients/VerifyImportedClients';
import { NewCompanyRouter } from './invite-new-company/NewCompanyRouter';
import { companiesLocations } from './locations';

export function CompaniesRouter() {
  return (
    <Switch>
      <SmartRoute path={companiesLocations.acceptRequest.base}>
        <AcceptRequestRouter />
      </SmartRoute>
      <SmartRoute path={companiesLocations.create.base}>
        <NewCompanyRouter />
      </SmartRoute>
      <SmartRoute path={companiesLocations.addAccountingFirm.base}>
        <AddAccountingFirmRouter />
      </SmartRoute>
      <SmartRoute path={companiesLocations.importClients.instructions}>
        <Instructions />
      </SmartRoute>
      <SmartRoute path={companiesLocations.importClients.verify}>
        <VerifyImportedClients />
      </SmartRoute>
      <SmartRoute path={companiesLocations.importClients.success}>
        <Success />
      </SmartRoute>
      <SmartRoute path={companiesLocations.importClients.companyTypeError}>
        <CompanyTypeError />
      </SmartRoute>
      <SmartRoute path={companiesLocations.importClients.connectionError}>
        <ConnectionError />
      </SmartRoute>
      <SmartRoute path={companiesLocations.importClients.noClientsError}>
        <NoClientsError />
      </SmartRoute>
    </Switch>
  );
}
