import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { validateUserOrgData } from 'src/modules/users/utils/validateUserOrgData';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { FormContainer, FormRow, FormSectionText } from 'src/ui/form/FormElements';
import { InlineCheckboxField } from 'src/ui/form/InlineCheckboxField';
import { AccessLevel } from 'src/utils/consts';
import { getAccessLevel } from '../../utils';

export function EditTeamMemberModal({ dismiss, userId, orgId }) {
  const user = useSelector(usersStore.selectors.byId(userId));
  const organization = useSelector(organizationStore.selectors.byId(orgId));
  const currentUserOrg = useSelector(profileStore.selectors.getUserOrganization);

  const { userOrg, isUpdating } = useStructuredSelectors(usersStore.selectors.userOrganization(userId, orgId));
  const [canAccessFirm, setCanAccessFirm] = useState(userOrg.accessLevel === AccessLevel.FULL);
  const handleCanAccessFirmChange = ({ value }: { value: boolean }) => {
    setCanAccessFirm(value);
  };
  const actions = useStoreActions(usersStore);
  const [, { submit }] = useForm(userOrg, {
    submit: (value) => {
      const finalValue = {
        ...value,
        accessLevel: getAccessLevel(canAccessFirm),
      };
      validateUserOrgData('userOrganization', finalValue, currentUserOrg);

      analytics.trackAction('edit-member-submit', { canAccessFirm });

      return actions.userManagementUpdateSlice({
        orgId,
        id: finalValue.userId,
        role: finalValue.role,
        requireApproval: finalValue.requireApproval,
        approvalAmountThreshold: finalValue.approvalAmountThreshold,
        accessLevel: finalValue.accessLevel,
      });
    },
    onClear: dismiss,
  });

  return (
    <ModalMessage
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label="team.modals.edit.title" />
        </ModalTitle>
      }
      contentComponent={
        <FormContainer onSubmit={submit}>
          <FormSectionText>
            <MIFormattedText label="team.modals.edit.userDetailsSection" />
          </FormSectionText>
          <FormRow>
            <TextField label="user.firstName" value={user.firstName} size="sm" isRequired isViewOnly />
            <TextField label="user.lastName" value={user.lastName} size="sm" isRequired isViewOnly />
          </FormRow>
          <FormRow>
            <PrivateDataContainer>
              <TextField label="user.email" value={user.email} type="email" size="sm" mb={0} isRequired isViewOnly />
            </PrivateDataContainer>
          </FormRow>
          <FormRow />
          <FormRow>
            <InlineCheckboxField
              label="team.modals.edit.canAccessFirm"
              labelValues={{ companyName: organization.companyName }}
              value={canAccessFirm}
              onChange={handleCanAccessFirmChange}
            />
          </FormRow>
          <button type="submit" hidden />
        </FormContainer>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <ButtonsContainer>
            <Button
              boxSizing="border-box"
              onClick={(event: any) => submit(event)}
              isLoading={isUpdating}
              size={ButtonSizes.md}
              width={['100%', null, 'auto']}
              label="team.modals.edit.buttons.save"
            />
          </ButtonsContainer>
        </ModalButtonsWrapper>
      }
    />
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
