import { PartsStyleFunction, PartsStyleObject, SystemStyleFunction } from '@chakra-ui/theme-tools';
import Checkbox from './checkbox';

const baseLabelControl = (props) => {
  const { label = {} } = Checkbox.baseStyle(props);

  return {
    ...label,
    _disabled: {
      color: 'grey.400',
    },
  };
};

const baseStyleContainer = () => ({
  alignItems: 'start',
});

const baseStyleControl: SystemStyleFunction = (props) => {
  const { control = {} } = Checkbox.baseStyle(props);

  return {
    ...control,
    borderRadius: 'full',
    borderWidth: '2px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    bg: 'transparent',
    borderColor: 'grey.600',
    _disabled: {
      borderColor: 'grey.400',
      cursor: 'default',
    },
    _checked: {
      borderColor: 'primary.500',
      color: 'primary.500',
      _disabled: {
        bg: 'transparent',
        color: 'grey.400',
      },
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '1rem',
        h: '1rem',
        borderRadius: 'full',
        borderColor: 'primary.500',
        bg: 'currentColor',
      },
    },
  };
};

const baseStyle: PartsStyleFunction = (props) => ({
  label: baseLabelControl(props),
  control: baseStyleControl(props),
  container: baseStyleContainer(),
});

const sizes: Record<string, PartsStyleObject> = {
  lg: {
    control: {
      w: 5,
      h: 5,
    },
    label: {
      apply: 'textStyles.body2',
      lineHeight: '2rem',
      alignSelf: 'center',
    },
  },
};

const defaultProps = {
  size: 'lg',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
