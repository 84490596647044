import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { TextField } from 'src/core/ds/form/fields';
import { Link } from 'src/core/ds/link';
import useAddMemoModal from 'src/pages/regular-batch-payments/components/dialog/useAddMemoModal';
import { FORM_MAX_LENGTH } from 'src/utils/consts';
import { useBatchBillsList } from '../../hooks/useBatchBillsList';

type Props = {
  onClose: () => void;
  itemId: string;
};

export const AddMemoModal = ({ onClose, itemId }: Props) => {
  const { submit, memoModel } = useAddMemoModal(onClose, itemId);

  return (
    <ModalMessage
      alignLeft
      titleComponent={<AddMemoModalTitles itemId={itemId} />}
      contentComponent={
        <Box py={3}>
          <TextField
            id="memo"
            label="bills.pay.memo.inputTitle"
            placeholder="bills.pay.memo.placeholder"
            helperText="bills.pay.memo.hint"
            maxLength={FORM_MAX_LENGTH.NOTE}
            model={memoModel}
            size="sm"
            mb={0}
            autoFocus
            isRequired
          />
        </Box>
      }
      buttonLabel="regularBatchPayments.addMemo.button"
      analyticsProperties={{ itemId }}
      onButtonClick={submit}
      onCloseClick={onClose}
    />
  );
};

type titleProps = {
  itemId: string;
};

const AddMemoModalTitles = ({ itemId }: titleProps) => {
  const intl = useIntl();
  const { getSingleBatchItem } = useBatchBillsList();

  const billsPayment = getSingleBatchItem(itemId).payment.bills;
  const isBulkPayment = billsPayment && billsPayment.length > 1;
  const bulkLearnMoreLink = intl.formatMessage({ id: 'bills.pay.memo.learnMoreLink' });

  return (
    <Box data-testid="memo-modal-title">
      <MIFormattedText label="bills.pay.memo.title" />
      {isBulkPayment && (
        <Box textStyle="body2" mt={4}>
          <MIFormattedText
            label="bills.pay.memo.subtitle"
            values={{
              learnMore: (
                <Link href={bulkLearnMoreLink} isExternal>
                  <MIFormattedText label="bills.pay.memo.learnMore" />
                </Link>
              ),
            }}
          />
        </Box>
      )}
    </Box>
  );
};
