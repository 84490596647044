import { MouseEventHandler } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';
import { ModelView } from 'src/ui/form';
import { FormContainer, FormRow, FormSectionText } from 'src/ui/form/FormElements';
import { InlineCheckboxField } from 'src/ui/form/InlineCheckboxField';
import { InvitationModelType } from '../types';

type Props = {
  dismiss: () => void;
  companyName: string;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
  onGotoAssignClients: MouseEventHandler<HTMLButtonElement>;
  invitationMV: ModelView<InvitationModelType>;
  showAssignClients: boolean;
  inviteIsValidating: boolean;
  assignIsValidating: boolean;
  isBatchCreating: boolean;
};

export function InviteTeamMemberModalMessage({
  dismiss,
  companyName,
  onSubmit,
  onGotoAssignClients,
  invitationMV,
  showAssignClients,
  inviteIsValidating,
  assignIsValidating,
  isBatchCreating,
}: Props) {
  const submitFunction = showAssignClients ? onGotoAssignClients : onSubmit;

  return (
    <ModalMessage
      id="invite-team-member-modal"
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label="team.modals.invite.title" values={{ companyName }} />
        </ModalTitle>
      }
      contentComponent={
        <FormContainer onSubmit={submitFunction}>
          <FormSectionText>
            <MIFormattedText label="team.modals.invite.userDetailsSection" />
          </FormSectionText>
          <FormRow>
            <TextField label="user.firstName" model={invitationMV.firstName} size="sm" isRequired />
            <TextField label="user.lastName" model={invitationMV.lastName} size="sm" isRequired />
          </FormRow>
          <FormRow>
            <PrivateDataContainer>
              <TextField label="user.email" model={invitationMV.email} type="email" size="sm" mb={0} isRequired />
            </PrivateDataContainer>
          </FormRow>
          <FormRow />
          <FormRow>
            <InlineCheckboxField
              label="team.modals.invite.canAccessFirm"
              labelValues={{ companyName }}
              model={invitationMV.canAccessFirm}
            />
          </FormRow>
          <button type="submit" hidden />
        </FormContainer>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <Flex direction={['column', null, 'row']} alignItems="center" justify="flex-end">
            {showAssignClients ? (
              <>
                <Button
                  onClick={onSubmit}
                  isLoading={inviteIsValidating || isBatchCreating}
                  variant={ButtonVariants.tertiaryNaked}
                  size={ButtonSizes.md}
                  label="team.modals.invite.buttons.invite"
                />
                <Button
                  isLoading={assignIsValidating}
                  disabled={isBatchCreating}
                  boxSizing="border-box"
                  onClick={onGotoAssignClients}
                  size={ButtonSizes.md}
                  width={['100%', null, 'auto']}
                  label="team.modals.invite.buttons.continue"
                />
              </>
            ) : (
              <Button
                onClick={onSubmit}
                isLoading={inviteIsValidating || isBatchCreating}
                size={ButtonSizes.md}
                boxSizing="border-box"
                width={['100%', null, 'auto']}
                label="team.modals.invite.buttons.sendInvite"
              />
            )}
          </Flex>
        </ModalButtonsWrapper>
      }
    />
  );
}
