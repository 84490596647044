import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { useIsAccountingFirmAdmin } from 'src/pages/team/team-management/hooks/useIsAccountingFirmAdmin';
import { Role } from 'src/utils/consts';
import { InvitationType } from 'src/utils/types';

export type ActionType = 'delete' | 'resend';

type Props = {
  invitation: InvitationType;
};

type CallbackFn = (actionType: ActionType) => any;

export function useGetInvitationActions<T>({ invitation }: Props, callbackFn: CallbackFn) {
  const { isAccountingFirmAdmin } = useIsAccountingFirmAdmin();
  const curUserId = useSelector(profileStore.selectors.getCurrentUserId);
  const isOwnerInvited = invitation.role === Role.OWNER;
  const actions: T[] = [];

  if (isAccountingFirmAdmin || invitation.invitedByUserId === curUserId) {
    callAndPush(actions, 'resend', callbackFn);
  }

  if (!isOwnerInvited) {
    callAndPush(actions, 'delete', callbackFn);
  }

  return actions;
}

function callAndPush(actions: any[], actionType: ActionType, callbackFn) {
  const callbackResult = callbackFn(actionType);

  if (callbackResult) {
    actions.push(callbackResult);
  }
}
