import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';
import { useScrollHashIntoView } from 'src/hooks/useScrollHashIntoView';
import { PAYMENTS_REPORT_HELP_LINK } from 'src/pages/settings/constants';
import PaymentsReportButton from '../../PaymentsReportButton';

type Props = {
  shouldDisplayZendesk?: boolean;
};

export const PaymentsReport = ({ shouldDisplayZendesk }: Props) => {
  useScrollHashIntoView();
  const url = shouldDisplayZendesk
    ? 'https://melio.zendesk.com/hc/en-us/articles/360021871699-Can-I-export-my-payment-history-data'
    : PAYMENTS_REPORT_HELP_LINK;

  return (
    <MICard id="PaymentsReport">
      <MICardForm>
        <MICardTitle label="settings.company.paymentsReport.title" />
        <Box textStyle="body2" mb={4} mt={6}>
          <MIFormattedText
            label="settings.company.paymentsReport.subtitle"
            values={{
              link: (
                <Link href={url} target="_blank" rel="noopener noreferrer">
                  <MIFormattedText label="settings.company.paymentsReport.link" />
                </Link>
              ),
            }}
          />
        </Box>
        <PaymentsReportButton />
      </MICardForm>
    </MICard>
  );
};
