import merge from 'lodash/merge';
import { PaymentRequestCreateOrigin, RegistrationFlow } from 'src/utils/consts';
import { MCBClose } from '../components/common/MCBClose';
import { MCBEmbeddedHeader } from '../components/common/MCBEmbeddedHeader';
import { MCBStepLayoutPage } from '../components/layout/MCBStepLayoutPage';
import * as WizardElements from '../components/layout/MCBWizardElements';
import MCBGuestCardLayout from '../pages/meliome/guest/components/MCBGuestCardLayout';
import { theme } from '../theme/mcbTheme';
import defaultSite from './defaultSite';

export const mcbSite = merge({}, defaultSite, {
  name: 'mcb',
  theme,
  embeddedMode: true,
  allow3rdPartyAuth: false,
  hasFastAch: false,
  hasFastCheck: false,
  hasExpressCheck: false,
  createOrigin: {
    meliome: {
      payor: RegistrationFlow.MCB,
      paymentRequest: PaymentRequestCreateOrigin.MCB,
    },
  },
  intercomSettings: {
    hide_default_launcher: true,
  },
  components: {
    StepLayout: MCBStepLayoutPage,
    WizardElements,
    GuestCardLayout: MCBGuestCardLayout,
    EmbeddedCloseButton: MCBClose,
    EmbeddedHeader: MCBEmbeddedHeader,
  },
});
