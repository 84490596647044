import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { MINotices } from 'src/components/common/MINotices';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TextInputSize } from 'src/utils/consts';
import { Expandable } from 'src/utils/types';
import { getFullName } from 'src/utils/user';
import { PaymentCreatorOptionType } from '../ApprovalWorkflowModal/types';
import { PaymentCreatorRow } from './components/PaymentCreatorRow';
import { SimpleDropdown } from './components/SimpleDropdown';

type Props = {
  testId?: string;
  paymentCreators: PaymentCreatorOptionType[];
  onChange: (change: Expandable<{ value: PaymentCreatorOptionType[] }>) => void;
  maxDisplayNames?: number;
  label?: string;
  labelValues?: Record<string, unknown>;
  errorMessage?: string;
  forceOpen?: boolean;
};

const DEFAULT_MAX_DISPLAY_NAMES = 2;

export function PaymentCreatorsDropdown({
  testId,
  paymentCreators,
  maxDisplayNames = DEFAULT_MAX_DISPLAY_NAMES,
  label,
  labelValues,
  onChange,
  errorMessage,
  forceOpen,
}: Props) {
  const checkedCreators = paymentCreators.filter((creator) => creator.isChecked);
  const creatorsText = checkedCreators
    .slice(0, maxDisplayNames)
    .map((creator) => getFullName(creator.firstName, creator.lastName))
    .join(', ');

  const moreAmount = Math.max(0, checkedCreators.length - maxDisplayNames);

  const getChangeHandler = (id) => (e) => {
    const updatedPaymentCreators = paymentCreators.map((paymentCreator) => {
      if (paymentCreator.id === id) {
        return {
          ...paymentCreator,
          isChecked: e.target.checked,
        };
      }

      return paymentCreator;
    });

    onChange({ value: updatedPaymentCreators });
  };

  return (
    <Box position="relative" data-testid={testId}>
      {label && (
        <Box lineHeight="normal">
          <MIInputLabel
            errorMessage={errorMessage}
            label={label}
            labelValues={labelValues}
            size={TextInputSize.INLINE}
            required
          />
        </Box>
      )}
      <SimpleDropdown
        errorMessage={errorMessage}
        text={
          <Flex alignItems="center">
            <MIFormattedText
              label="settings.approvalWorkflows.dropdown.text"
              values={{ creators: creatorsText, moreAmount }}
            />
          </Flex>
        }
        forceOpen={forceOpen}
      >
        <Box pt={4} pb={4}>
          {paymentCreators.map((creator) => (
            <PaymentCreatorRow key={creator.id} creator={creator} onChange={getChangeHandler(creator.id)} />
          ))}
        </Box>
      </SimpleDropdown>
      {errorMessage && (
        <Box mb={2} lineHeight="normal">
          <MINotices errorMessage={errorMessage} size="inline" />
        </Box>
      )}
    </Box>
  );
}
