import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import { AppLayout } from 'src/components/layout/AppLayout';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { getProLocations } from 'src/pages/get-pro/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { msnPortalLocations } from './locations';
import { MSNPortalPage } from './MSNPortalPage';

export const MSNPortalRouter = () => {
  const orgId = useSelector(getOrgId);
  const isMsnDashboardDisplay = useIsMsnDashboardDisplay();

  if (!isMsnDashboardDisplay) {
    return <Redirect to={{ pathname: generatePath(getProLocations.dashboard, { orgId }) }} />;
  }

  return (
    <AppLayout>
      <Switch>
        <SmartRoute path={msnPortalLocations.base}>
          <MSNPortalPage />
        </SmartRoute>
      </Switch>
    </AppLayout>
  );
};
