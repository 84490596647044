import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { Link } from 'src/core/ds/link';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { intercomService } from 'src/services/intercom';

export const CompanyRejectedPage = () => {
  const organizationId = useSelector(getOrgId);

  if (!organizationId) return <Redirect to={onboardingLocations.msnRegistration.register} />;

  const openSupportChat = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    intercomService.show();
  };

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.errorScreen}
      title="onboarding.msnCompanyInfo.companyRejected.title"
      textValues={{
        supportLink: (...chunks) => (
          <Link href="/" onClick={openSupportChat}>
            {chunks}
          </Link>
        ),
      }}
      text="onboarding.msnCompanyInfo.companyRejected.text"
      hideHeader
    />
  );
};
