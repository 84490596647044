import { AmexCardEnum } from '@melio/spend-management-api-axios-client';
import { addMonths, addWeeks, addYears, format, isValid, subDays, subYears } from 'date-fns';
import { DOB_DEFAULTS } from 'src/pages/auth/consts';
import { DateFormats } from 'src/utils/date-fns';

export type ValidationDateType = 'week' | 'month' | 'year' | 'fiveYears';

export const getValidDateOptions = (): { [key in ValidationDateType]: { id: string; value: Date } } => ({
  week: {
    id: 'spendManagement.pages.dashboard.createCard.inputs.validDate.options.week',
    value: subDays(addWeeks(new Date(), 1), 1),
  },
  month: {
    id: 'spendManagement.pages.dashboard.createCard.inputs.validDate.options.month',
    value: subDays(addMonths(new Date(), 1), 1),
  },
  year: {
    id: 'spendManagement.pages.dashboard.createCard.inputs.validDate.options.year',
    value: subDays(addYears(new Date(), 1), 1),
  },
  fiveYears: {
    id: 'spendManagement.pages.dashboard.createCard.inputs.validDate.options.fiveYears',
    value: subDays(addYears(new Date(), 5), 1),
  },
});

export const getValidationDate = (
  selection: ValidationDateType
): {
  id: ValidationDateType;
  label: { id: string; date: string };
  value: Date;
} => {
  const { id, value } = getValidDateOptions()[selection];

  return {
    id: selection,
    label: {
      id,
      date: format(value, DateFormats.monthShortWithDateAndYear),
    },
    value,
  };
};

export const getValidDatesList = () =>
  Object.keys(getValidDateOptions()).map((exp) => getValidationDate(exp as ValidationDateType));

export const getValidationDateField = (validationDate: string | undefined, cardStatus: string) => ({
  key: 'validDate',
  value:
    validationDate && cardStatus === AmexCardEnum.Active
      ? format(new Date(validationDate), DateFormats.monthShortWithDateAndYear)
      : '',
});

export interface UserValidationErrors {
  dateOfBirth?: string;
}

export const validateDateOfBirth = (dateOfBirth) => {
  const errors: UserValidationErrors = {};

  // Age validation
  const minDate = subYears(new Date(), DOB_DEFAULTS.MAX_AGE);
  const maxDate = subYears(new Date(), DOB_DEFAULTS.MIN_AGE);

  if (dateOfBirth > maxDate) {
    errors.dateOfBirth = 'inputErrors.user.dateOfBirth.date.notCorrectAge';
  }

  if (!isValid(dateOfBirth) || dateOfBirth < minDate) {
    errors.dateOfBirth = 'inputErrors.user.dateOfBirth.date.notCorrectDate';
  }

  return errors;
};
