import { useMemo } from 'react';
import { InternationalWizardState } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalWizard';
import { ModelView, useForm } from 'src/ui/form';

export const useAddBillAttachmentModal = (
  onClose: () => void
): {
  model: ModelView<InternationalPaymentPurposeModel>;
  submit: () => void;
} => {
  const paymentPurposeModel = useMemo<InternationalPaymentPurposeModel>(
    () => ({
      invoiceAttachment: false,
    }),
    []
  );

  const [model, { submit }] = useForm<InternationalPaymentPurposeModel>(paymentPurposeModel, {
    submit: async () => {
      onClose();
    },
    validateOnChange: false,
    validator: paymentPurposeFormValidator(),
  });

  return {
    model,
    submit,
  };
};

export type InternationalPaymentPurposeModel = Partial<InternationalWizardState>;

export const paymentPurposeFormValidator = () => (key: string, value: string | number | boolean) => {
  if (key === 'invoiceAttachment' && !value) {
    return 'vendors.deliveryMethods.international.paymentPurpose.errorInvoice';
  }

  return undefined;
};
