import styled from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PaymentTypes } from 'src/utils/consts';
import { getPaymentTag } from 'src/utils/payments';
import { BillType } from 'src/utils/types';
import { PartialPaymentLabel } from './PartialPaymentLabel';
import { PartialPaymentStatus } from './PartialPaymentStatus';

type Props = {
  bill: BillType;
  currentPaymentId?: string;
  onLabelClick?: (paymentId, status) => void;
  className: string;
  showLabel?: boolean;
};

const PartialPaymentsInfo = ({ bill, currentPaymentId, onLabelClick, className, showLabel = true }: Props) => (
  <span className={className}>
    {bill?.payments?.map((payment) => {
      const { label, statusLabel, date } = getPaymentTag(payment);

      return (
        <PartialPayment key={payment.id} data-testid={`partial-payments-info-${payment.id}`}>
          <PartialPaymentInfo>
            <PartialPaymentStatus
              statusLabel={statusLabel}
              date={date}
              paymentStatus={payment.status}
              isRefund={payment.metadata?.paymentType === PaymentTypes.REFUND}
            />
            {showLabel && (
              <PartialPaymentLabel
                label={label}
                onLabelClick={() => onLabelClick && onLabelClick(payment.id, payment.status)}
                isCurrentPayment={payment.id.toString() === currentPaymentId}
                testId={`partial-payment-info-action-label-${payment.id}`}
              />
            )}
          </PartialPaymentInfo>
          <MIFormattedCurrency value={payment.billPayment?.amount || ''} />
        </PartialPayment>
      );
    })}
    {!!bill.externallyPaid && (
      <PartialPayment>
        <MIFormattedText label="bills.form.partialPayments.status.externallyPaid" />
        <MIFormattedCurrency value={bill.externallyPaid} />
      </PartialPayment>
    )}
  </span>
);

const PartialPayment = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0.4rem 0;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.regular};

  ${({ theme }) => theme?.components?.BillOpenBalance?.PartialPayment}
`;

const PartialPaymentInfo = styled.div`
  display: flex;
  align-items: center;
`;

export default PartialPaymentsInfo;
