import { UPDATE_IS_POLLING, UPDATE_IS_SYNCING } from './actions-types';

export const updateIsSyncing = (isSyncing: boolean) => ({
  type: UPDATE_IS_SYNCING,
  payload: { isSyncing },
});

export const updateIsPolling = (isPolling: boolean) => ({
  type: UPDATE_IS_POLLING,
  payload: { isPolling },
});
