import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';
import { GetProTabs } from 'src/utils/consts';
import { InProgressAndPaidMenu } from './menu';

export const InProgressActionCell: React.FC<CellProps<any>> = ({ cell: { value } }) => {
  const stopPropagation = (event) => event.stopPropagation();

  return useMemo(
    () => (
      <Box display="flex" onClick={stopPropagation} mx={4} justifyContent="flex-end">
        <InProgressAndPaidMenu id={value} tab={GetProTabs.IN_PROGRESS} />
      </Box>
    ),
    [value]
  );
};
