import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { AccountingPlatformAccountId } from 'src/modules/funding-sources/types';
import { PaymentCreateFlowOrigin } from 'src/utils/consts';
import { billsApi } from './api';
import { name } from './consts';

type Params = {
  orgId: number;
  items: {
    id?: string;
    amount?: string;
  }[];
  intuitAccountId?: AccountingPlatformAccountId;
  createOrigin?: PaymentCreateFlowOrigin;
};

const batchMarkAsPaidApi = (params: Params) =>
  billsApi.batchMarkAsPaid({
    orgId: params.orgId,
    items: params.items,
    intuitAccountId: params.intuitAccountId,
    createOrigin: params.createOrigin,
  });

export const batchMarkAsPaidSlice = createApiCallSlice({
  name: generateSliceName(name, 'batch_mark_as_paid'),
  api: batchMarkAsPaidApi,
  initialState: {
    batchMarkAsPaid: {
      loading: false,
      error: null,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.batchMarkAsPaid.loading = true;
    },
    [ON_SUCCESS]: (state) => {
      state.batchMarkAsPaid.loading = false;
    },
    [ON_FAILURE]: (state, action) => {
      state.batchMarkAsPaid.loading = false;
      state.batchMarkAsPaid.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state[name].batchMarkAsPaid.loading,
    error: (state) => state[name].batchMarkAsPaid.error,
  },
});
