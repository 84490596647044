import * as React from 'react';
import styled from 'styled-components';
import successAnimation from 'src/animations/meliome/success.json';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { Logo } from 'src/images/Logo';
import { analytics } from 'src/services/analytics';
import { ModelViewField } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';
import { LogoFillType } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { melioClose } from 'src/utils/external-events';

type Props = {
  passwordModel: ModelViewField<string>;
  goNext: () => void;
  isLoading: boolean;
  companyName?: string;
  totalAmount?: string;
  scheduledDate?: Date;
};

const eventName = 'combined-success-signup';

const GuestCombinedSuccessSignUpPage = ({
  passwordModel,
  goNext,
  isLoading,
  scheduledDate,
  totalAmount,
  companyName,
}: Props) => {
  const goLearnMore = () => {
    analytics.track('payor-password', `${eventName}-learn-more-link`);
    window.open('https://www.meliopayments.com');
  };
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      goNext();
    }
  };

  const site = useSiteContext();

  return (
    <SuccessLayoutPage
      animation={successAnimation}
      title="bills.pay.payBillCombinedSuccess.titles.regular"
      text="bills.pay.payBillCombinedSuccess.subtitles.regularWithNotify"
      textValues={{
        amount: <MIFormattedCurrency key="amount" value={totalAmount ? totalAmount.toString() : ''} />,
        processDate: <MIFormattedDate key="processDate" date={scheduledDate} />,
        vendorName: companyName,
      }}
      buttonLabel="bills.pay.payBillCombinedSuccess.signUp"
      buttonAction={goNext}
      secondaryButtonLabel="bills.pay.payBillCombinedSuccess.close"
      secondaryButtonAction={site.embeddedMode ? melioClose : undefined}
      linkLabel="bills.pay.payBillCombinedSuccess.more"
      linkAction={goLearnMore}
      isLoading={isLoading}
    >
      <RegistrationContainer onKeyDown={onKeyPressed}>
        <Logo fill={LogoFillType.LIGHT} size="mobile" isFull />
        <RegistrationSubText>
          <MIFormattedText label="bills.pay.payBillCombinedSuccess.secondTitle" />
        </RegistrationSubText>
        <WizardPasswordValidationInputField
          id="password"
          label="bills.pay.payBillCombinedSuccess.registrationReview.password"
          model={passwordModel}
          autoFocus
          isRequired
          autoComplete="password"
          textAlign="left"
        />
      </RegistrationContainer>
    </SuccessLayoutPage>
  );
};

export default GuestCombinedSuccessSignUpPage;

const RegistrationSubText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  text-align: center;
  margin-bottom: 4.8rem;
  ${(props) => props.theme.text.fontType.regular};
  display: flex;
  justify-content: center;

  &&& {
    svg {
      position: relative;
      top: 1px;
    }
  }
`;

const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -1rem 0 2rem;

  > div {
    width: 100%;
  }
`;
