import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FileUploading, UploadError } from 'src/components/common/BatchUpload/BatchUploadPages';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import vendorsStore from 'src/modules/vendors/vendors-store';
import {
  getVendorsWithValidationErrors,
  isUploadVendorFileValid,
  isVendorMissingDetails,
  isVendorValidToUpload,
  removeInvalidValues,
} from 'src/pages/contacts/create/BatchUploadVendors/batch-upload-utils';
import BatchUploadContactsReviewPage from 'src/pages/contacts/create/components/BatchUploadContactsReviewPage';
import { TableCell } from 'src/pages/contacts/create/components/TableCell';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { ContactsTab } from 'src/utils/consts';
import { useLoadCsv } from 'src/utils/hooks';
import { NavigationCallbacks } from 'src/utils/types';

type Props = {
  file: File | null;
  hideLayout?: boolean;
  onVendorsRefresh?: (data) => void;
} & NavigationCallbacks;

export const BatchUploadVendorsReview = ({
  file,
  goExit,
  onNext: onFinish,
  onPrev,
  hideLayout,
  onVendorsRefresh,
}: Props) => {
  const [rows, isLoading, isError, headers] = useLoadCsv(file);
  const orgId = useSelector(getOrgId);
  const actions = useStoreActions(vendorsStore);
  const isUploading = useSelector(vendorsStore.selectors.createBatch.status)?.loading;
  const vendorsList = useSelector((state) => vendorsStore.selectors.list.value(state, { orgId }));

  useEffect(() => {
    actions.list({ orgId });
  }, [orgId, actions]);

  const vendorsWithErrors = useMemo(
    () => (rows && rows.length > 0 ? getVendorsWithValidationErrors(rows, vendorsList) : []),
    [rows, vendorsList]
  );
  useEffect(() => {
    if (vendorsWithErrors.length === 0) return;

    analytics.track('contacts_create', 'batch-vendors-review-list', {
      totalVendors: vendorsWithErrors.length,
      missingCompanyName: vendorsWithErrors.filter((vendorsWithErrors) =>
        vendorsWithErrors.validationErrors.companyName?.includes('empty')
      ).length,
      duplicateVendors: vendorsWithErrors.filter((vendorsWithErrors) =>
        vendorsWithErrors.validationErrors.companyName?.includes('duplicate')
      ).length,
      invalidEmail: vendorsWithErrors.filter((vendorsWithErrors) =>
        vendorsWithErrors.validationErrors.contactEmail?.includes('email')
      ).length,
    });

    onVendorsRefresh && onVendorsRefresh(vendorsWithErrors);
  }, [vendorsWithErrors.length, onVendorsRefresh]);

  const contactsTableCells = useMemo(
    () =>
      vendorsWithErrors.map((vendorWithErrors) => ({
        companyName: (
          <TableCell
            name="companyName"
            value={vendorWithErrors.vendor.companyName}
            validationError={vendorWithErrors.validationErrors.companyName}
            disabled={!!vendorWithErrors.validationErrors.companyName}
          />
        ),
        contactName: (
          <TableCell
            name="contactName"
            value={vendorWithErrors.vendor.contactName}
            validationError={vendorWithErrors.validationErrors.contactName}
            disabled={
              !!vendorWithErrors.validationErrors.companyName || !!vendorWithErrors.validationErrors.contactName
            }
          />
        ),
        contactEmail: (
          <TableCell
            name="contactEmail"
            value={vendorWithErrors.vendor.contactEmail}
            validationError={vendorWithErrors.validationErrors.contactEmail}
            disabled={
              !!vendorWithErrors.validationErrors.companyName || !!vendorWithErrors.validationErrors.contactEmail
            }
          />
        ),
        contactPhone: (
          <TableCell
            name="contactPhone"
            value={vendorWithErrors.vendor.contactPhone}
            validationError={vendorWithErrors.validationErrors.contactPhone}
            disabled={
              !!vendorWithErrors.validationErrors.companyName || !!vendorWithErrors.validationErrors.contactPhone
            }
          />
        ),
      })),
    [vendorsWithErrors]
  );

  const missingDetailsRows = useMemo(() => vendorsWithErrors.filter(isVendorMissingDetails).length, [
    vendorsWithErrors,
  ]);

  const validVendorsCount = useMemo(() => vendorsWithErrors.filter(isVendorValidToUpload).length, [vendorsWithErrors]);

  if (isLoading) {
    return (
      <FileUploading
        onPrev={onPrev}
        title="contacts.batch.loading.title"
        subtitle="contacts.batch.loading.subtitle"
        buttonLabel="contacts.batch.loading.button"
      />
    );
  }

  if (isError || !isUploadVendorFileValid(rows, headers)) {
    return (
      <UploadError
        onPrev={onPrev}
        subtitle="contacts.batch.error.subtitle"
        buttonLabel="contacts.batch.error.cancelButton"
      />
    );
  }

  const onNext = async () => {
    const vendors = vendorsWithErrors.filter(isVendorValidToUpload).map(removeInvalidValues);
    const { payload } = await actions.createBatch({
      orgId,
      data: vendors,
    });
    onFinish && onFinish(payload.length);
  };

  return (
    <BatchUploadContactsReviewPage
      contactType={ContactsTab.VENDORS}
      onExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      contacts={contactsTableCells}
      isLoading={isUploading}
      invalidRows={missingDetailsRows}
      isNextDisabled={validVendorsCount === 0}
      hideLayout={hideLayout}
    />
  );
};
