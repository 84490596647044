import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { intercomService } from 'src/services/intercom';

const ErrorState = () => {
  const openSupportChat = () => {
    intercomService.show();
  };

  return (
    <Box textStyle="body4Semi" color="grey.600" w="30%" alignSelf="center">
      <MIFormattedText
        label="getPro.errorState.message"
        values={{
          contactSupport: (
            <Box as="a" cursor="pointer" onClick={openSupportChat} textStyle="link3">
              <MIFormattedText label="getPro.errorState.contactSupport" />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

export default ErrorState;
