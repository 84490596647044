import React from 'react';
import { useIntl } from 'react-intl';
import { Image, ImageProps } from 'src/core/ds/image';

type ImagePropsWithoutLegacyAlt = Omit<ImageProps, 'alt'>;

type ImageWithAltProps = {
  altLabel: string;
  width?: string;
  altLabelValues?: Record<string, any>;
  testid?: string;
} & ImagePropsWithoutLegacyAlt;

export function ImageWithAlt({
  src,
  altLabel,
  altLabelValues,
  width = '100%',
  testid,
  ...otherProps
}: ImageWithAltProps) {
  const intl = useIntl();

  return (
    <Image
      src={src}
      width={width}
      alt={intl.formatMessage({ id: altLabel }, altLabelValues)}
      data-testid={testid}
      {...otherProps}
    />
  );
}
