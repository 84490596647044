import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

const PAGE_EVENT = 'scanned-invoices';

export const scannedInvoicesEventMapping: EventMappingsTree = {
  '/orgs/*/bills/scanned-invoices/*': {
    'page.view': [PAGE_EVENT, 'scanned-invoice-create'],
    'pay.scannedInvoice.create.actions.more': [PAGE_EVENT, 'more-actions'],
    'pay.scannedInvoice.create.actions.saveAndClose': [
      PAGE_EVENT,
      'save-and-close',
      { scannedInvoiceId: 'scannedInvoiceId' },
    ],
    'pay.scannedInvoice.create.actions.markAsPaid': [
      PAGE_EVENT,
      'mark-as-paid',
      { scannedInvoiceId: 'scannedInvoiceId' },
    ],
    'pay.scannedInvoice.create.actions.delete': [PAGE_EVENT, 'delete', { scannedInvoiceId: 'scannedInvoiceId' }],
    'pay.scannedInvoice.create.actions.save': [
      PAGE_EVENT,
      'schedule-payment',
      { scannedInvoiceId: 'scannedInvoiceId' },
    ],
    'pay.scannedInvoice.create.actions.openInNewTab': [
      PAGE_EVENT,
      'open-in-new-tab',
      { scannedInvoiceId: 'scannedInvoiceId' },
    ],
    'bills.form.deleteDialog.confirm': [PAGE_EVENT, 'delete-bill-confirmed'],
    'dialogs.buttons.cancel': [PAGE_EVENT, 'delete-bill-canceled'],
    ...mapApiSlice('[SCANNEDINVOICES] DELETE', PAGE_EVENT, 'delete-bill', {
      request: { scannedInvoiceId: 'payload.id' },
      success: { scannedInvoiceId: 'payload' },
      failure: { scannedInvoiceId: 'meta.identifier.id' },
    }),
    ...mapApiSlice('[SCANNEDINVOICES] MARK_AS_PAID', PAGE_EVENT, 'mark-as-paid', {
      request: { scannedInvoiceId: 'payload.scannedInvoiceId', amount: 'payload.amount' },
      success: { scannedInvoiceId: 'meta.identifier.scannedInvoiceId', amount: 'meta.identifier.amount' },
      failure: { scannedInvoiceId: 'meta.identifier.scannedInvoiceId', amount: 'meta.identifier.amount' },
    }),
    ...mapApiSlice('[SCANNEDINVOICES] CREATE', PAGE_EVENT, 'create-bill', {
      request: { scannedInvoiceId: 'payload.scannedInvoiceId' },
      success: { scannedInvoiceId: 'meta.identifier.scannedInvoiceId' },
      failure: { scannedInvoiceId: 'meta.identifier.scannedInvoiceId' },
    }),
  },
};
