import { format, parseISO } from 'date-fns';
import get from 'lodash/get';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryType } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import { getFormattedACH, getFormattedAddress } from 'src/utils/formatting';
import { CheckType, PaymentType } from 'src/utils/types';

export const getSubtitleValuesForVoidedCheckNotification = (payment: PaymentType) => {
  let deliveryTo;
  let deliveryBy;
  const { deliveryMethod } = payment;
  const deliveryType = get(deliveryMethod, 'deliveryType');

  if (deliveryType === DeliveryType.CHECK) {
    deliveryBy = 'Paper check';
    deliveryTo = getFormattedAddress(deliveryMethod?.paperCheck as CheckType);
  }

  if (deliveryType === DeliveryType.ACH) {
    const companyName = get(payment, 'vendor.companyName');
    const managedBy = get(payment, 'vendor.managedBy');
    const digits = getAccountNumber4digits(deliveryMethod.bankAccount);
    deliveryBy = 'Bank transfer (ACH)';
    deliveryTo = getFormattedACH(companyName, managedBy, digits);
  }

  const checkSerialNumber = payment.metadata?.voidedCheckData?.checkSerialNumber;
  const date = get(payment, 'metadata.voidedCheckData.date');
  const formattedDate = date
    ? format(date instanceof Date ? date : parseISO(date), DateFormats.monthShortWithDateAndYear)
    : null;

  return {
    checkSerialNumber,
    date: formattedDate,
    deliveryBy,
    deliveryTo,
  };
};
