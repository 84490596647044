import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { ManualAddressPage } from 'src/components/layout/ManualAddressPage';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { ManualAddressProps } from '../hoc/withCompanyInfoUpdates';

export const CompanyManualAddressForm = ({
  isLoading,
  validationErrors,
  onPrev,
  validManualAddress,
  manualAddressValidationErrors,
  selectedAddressId,
  onEditAddress,
  confirmMode,
  invalidAddress,
  onManualAddressSelected,
  onManualAddressChange,
  manualAddress,
  onAddressConfirmed,
  onAddressAddRequest,
}: ManualAddressProps) => {
  const getNextLabel = () => {
    let nextLabel = '';

    if (confirmMode) {
      nextLabel = 'manualAddress.confirm';
    } else if (invalidAddress) {
      nextLabel = 'manualAddress.confirmInvalid';
    }

    return nextLabel;
  };

  return (
    <StepLayoutPage
      title={invalidAddress ? 'manualAddress.invalidTitle' : 'onboarding.companyInfo.address.manualTitle'}
      onPrev={onPrev}
      isLoading={isLoading}
      onSubmit={confirmMode || invalidAddress ? onAddressConfirmed : onAddressAddRequest}
      nextLabel={getNextLabel()}
    >
      <ManualAddressPage
        confirmMode={confirmMode}
        invalidAddress={invalidAddress}
        onManualAddressChange={onManualAddressChange}
        validationErrors={validationErrors}
        validManualAddress={validManualAddress as ManualAddressType}
        manualAddressValidationErrors={manualAddressValidationErrors}
        manualAddress={manualAddress}
        onManualAddressSelected={(id) => onManualAddressSelected(id)}
        onEditAddress={onEditAddress}
        selectedAddressId={selectedAddressId}
        subTitle="onboarding.companyInfo.address.confirmSubTitleLine1"
      />
    </StepLayoutPage>
  );
};
