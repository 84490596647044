import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useUpdateServiceFeesPreference } from 'src/modules/organizations/hooks/useUpdateServiceFeesPreference';
import useGetAchDeliveryMethod from 'src/pages/get-paid/hooks/useGetAchDeliveryMethod';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { SelectCategoryList } from './components/select-categoty-list/SelectCategoryList';
import { SELECTION_LIST_CATEGORIES } from './consts';

export function VendorCompanyAbsorbedFeeTypeSelection() {
  const orgId = useSelector(getOrgId);
  const [historyPush] = useHistoryWithOrgId();
  const [userSelection, setUserSelection] = useState<boolean | undefined>();
  const { isUpdatingOrganizationPreference, updateServiceFeesPreference } = useUpdateServiceFeesPreference();
  const achDeliveryMethods = useGetAchDeliveryMethod();
  const hasVerifiedAch = !!achDeliveryMethods;

  const shouldSkip = hasVerifiedAch;

  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const relativeStep = isUnifiedOnboardingOpen ? 12 / 15 : 9 / 10;

  useEffect(() => {
    if (shouldSkip) {
      historyPush({ path: onboardingLocations.vendorCompanyInfo.added });
    }
  }, [historyPush, shouldSkip]);

  const onSubmit = async () => {
    try {
      if (userSelection !== undefined) {
        await updateServiceFeesPreference({ orgId, value: userSelection });
        analytics.trackAction('absorb-fee-selection-submitted', {
          value: userSelection,
        });
        historyPush({ path: onboardingLocations.vendorCompanyInfo.added });
      }
    } catch (e) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'serverErrors.ERR',
      });
    }
  };

  const onSelectionChanged = (selectedId: string) => {
    setUserSelection(selectedId === 'true');
    analytics.trackAction('absorb-fee-selection-changed', {
      value: selectedId,
    });
  };

  return (
    <StepLayoutPage
      title="onboarding.fundingSources.absorbedFees.title"
      subtitle="onboarding.fundingSources.absorbedFees.description"
      innerSize={50}
      hideHeader
      isLoading={isUpdatingOrganizationPreference}
      isNextDisabled={userSelection === undefined}
      onSubmit={onSubmit}
      relativeStep={relativeStep}
    >
      <Box d="flex" alignItems="center" flexDir="column">
        <SelectCategoryList
          selectedId={String(userSelection)}
          onSelect={onSelectionChanged}
          categories={SELECTION_LIST_CATEGORIES}
        />
      </Box>
    </StepLayoutPage>
  );
}
