import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { useCreateBatchPayments } from 'src/pages/regular-batch-payments/hooks/useCreateBatchPayments';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  showFailedBillsListModal: () => void;
};

const NotificationCardFailedBills = ({ showFailedBillsListModal }: Props) => {
  const {
    createBatchPaymentData: { failedPaymentsCount },
  } = useCreateBatchPayments();

  return (
    <MINotificationCard
      type={NotificationCardTypes.ERROR}
      title={{
        label: 'regularBatchPayments.notifications.success.title',
      }}
      subtitle={{
        label: 'regularBatchPayments.notifications.success.subtitle',
        values: {
          failedPaymentsCount,
          viewDetails: (
            <Box as="a" cursor="pointer" onClick={showFailedBillsListModal} textStyle="link2">
              <MIFormattedText label="regularBatchPayments.viewErrorDetails" />
            </Box>
          ),
        },
      }}
    />
  );
};

export default NotificationCardFailedBills;
