import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { DrawerBody, DrawerCloseButton, DrawerFooter, DrawerHeader } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';
import Spacer from 'src/core/ds/spacer';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { DateTableRowType } from '../TransactionsTable';
import { DetailsCell } from './transactions/DetailsCell';
import { formatPartialAddress } from './utils/address';

const transactionLang = 'spendManagement.pages.dashboard.transactionDetails';

type Props = {
  transaction: DateTableRowType;
  onClose: VoidFunction;
};

type DetailsCell = {
  key: string;
  value?: string;
  isCurrency?: boolean;
  isDate?: boolean;
};

export const TransactionDetails = ({ transaction, onClose }: Props) => {
  const intl = useIntl();

  const accountDescription = transaction.account.nickname || transaction.account.productDescription || '';
  const vendorAddress = formatPartialAddress(transaction.vendor, ['city', 'state', 'zipCode']);
  const accountDetails = intl.formatMessage(
    {
      id: `spendManagement.pages.dashboard.transactionDetails.accountDetails`,
    },
    {
      accountName: accountDescription,
      last5: transaction.account.last5,
    }
  );
  const cardDetails = intl.formatMessage(
    {
      id: `spendManagement.pages.dashboard.transactionDetails.cardDetails`,
    },
    {
      cardName: transaction.card.nickname || '',
      last5: transaction.card.last5,
    }
  );

  const handleClose = () => {
    analytics.track(Page.TRANSACTION_LIST, Context.TRANSACTION_DETAILS_DRAWER, Event.CLICK_CLOSE_X);
  };

  const getTestId = (key) => `spend-management-transaction-details-${key}`;

  return (
    <>
      <Header flexDirection="column" minH="auto">
        <Flex justify="space-between" w="full">
          <Box textStyle="body1Semi">
            <MIFormattedText label={`${transactionLang}.title`} />
          </Box>
          <Spacer />
          <DrawerCloseButton onClick={handleClose} size="md" />
        </Flex>
      </Header>
      <Body pb={10} pt={4} data-testid="spend-management-transaction-details">
        <Row>
          <DetailsCell
            testId={getTestId('amount')}
            label={`${transactionLang}.amount`}
            details={transaction.amount.amount}
            isCurrency
          />
        </Row>{' '}
        <Row>
          <DetailsCell
            testId={getTestId('vendor')}
            label={`${transactionLang}.vendor`}
            details={transaction.vendor?.name}
          />
        </Row>
        <Row>
          <DetailsCell
            testId={getTestId('vendorAddress')}
            label={`${transactionLang}.vendorAddress`}
            details={vendorAddress}
          />
        </Row>
        {transaction.vendor?.category && (
          <Row>
            <DetailsCell
              testId={getTestId('industry')}
              label={`${transactionLang}.industry`}
              details={transaction.vendor?.category}
              fallback="N/A"
            />
          </Row>
        )}
        <Row>
          <DetailsCell
            testId={getTestId('purchaseDate')}
            label={`${transactionLang}.purchaseDate`}
            details={transaction.txnDate}
            isDate
          />
        </Row>
        <Row>
          <DetailsCell
            testId={getTestId('status')}
            label={`${transactionLang}.status`}
            details={transaction.status}
            isStatus
          />
        </Row>
        <Row>
          <DetailsCell testId={getTestId('account')} label={`${transactionLang}.account`} details={accountDetails} />
        </Row>
        <Row>
          <DetailsCell testId={getTestId('cards')} label={`${transactionLang}.card`} details={cardDetails} />
        </Row>
        {transaction.referenceId && (
          <Row>
            <DetailsCell
              testId={getTestId('referenceNum')}
              label={`${transactionLang}.referenceNum`}
              details={transaction.referenceId}
              withCopy
            />
          </Row>
        )}
        {transaction.eventDescription && (
          <Row>
            <DetailsCell
              testId={getTestId('statusDesc')}
              label={`${transactionLang}.statusDesc`}
              details={transaction.eventDescription}
            />
          </Row>
        )}
      </Body>
      <DrawerFooter>
        <Flex direction="row" gap={24}>
          <Spacer />
          <Button label={`${transactionLang}.close`} variant={ButtonVariants.tertiary} onClick={onClose} />
        </Flex>
      </DrawerFooter>
    </>
  );
};

const Body = styled(DrawerBody)`
  && {
    display: flex;
    padding: 0;
    margin: 3.5rem;
    gap: 1.5rem;
    flex-direction: column;
  }
`;

const Header = styled(DrawerHeader)`
  && {
    padding: 20px;
  }
`;

const Row = styled(Flex)`
  gap: 4rem;
`;
