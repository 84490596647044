import { fetchRequest, postRequest } from 'src/services/api/api';
import { convertPaymentRequestToBill } from 'src/utils/bills';

type BatchBillsParams = {
  isBulkPaymentEnabled?: boolean;
  isGroupedByVendor?: boolean;
  orgId: number;
  billIds: string[];
  requestIds: string[];
  fundingSourceId: number;
  scheduledDate?: string;
  paymentsInfo: {
    [billId: string]: {
      deliveryMethodId: number;
      deliveryPreference: string;
    };
  };
};

export const regularBatchPaymentsApi = {
  list({ orgId, billIds, requestIds, fundingSourceId, scheduledDate }: BatchBillsParams) {
    const url = `/orgs/${orgId}/bills/batch/list`;
    const params = {
      orgId,
      billIds: billIds?.join(),
      requestIds: requestIds?.join(),
      fundingSourceId,
      scheduledDate,
    };

    return fetchRequest(url, params).then((res) => ({
      items: res.billList.map((item) => {
        if (item.bill?.billId !== undefined) {
          item.bill = convertPaymentRequestToBill(item.bill);
        }

        return {
          ...item,
          id: item.bill.id,
          isBulkPaymentEnabled: false,
        };
      }),
    }));
  },
  async groupedList({
    orgId,
    billIds,
    requestIds,
    fundingSourceId,
    scheduledDate,
    isGroupedByVendor,
  }: BatchBillsParams) {
    const url = isGroupedByVendor ? `/orgs/${orgId}/bills/batch/grouped` : `/orgs/${orgId}/bills/batch/flatten`;
    const params = {
      orgId,
      billIds: billIds?.join(),
      requestIds: requestIds?.join(),
      fundingSourceId,
      scheduledDate,
    };
    const { batchList } = await fetchRequest(url, params);
    const items = batchList.map((item) => {
      item.payment.bills = item.payment.bills.map((bill) => {
        if (bill?.billId !== undefined) {
          return convertPaymentRequestToBill(bill);
        }

        return bill;
      });

      return {
        ...item,
        isBulkPaymentEnabled: true,
      };
    });

    return { items };
  },

  createBatchPayments({ orgId, payments }) {
    const url = `/orgs/${orgId}/payments/batch/create`;

    return postRequest(url, payments);
  },
};
