import { matchPath } from 'react-router-dom';
import { orgLocations } from '../pages/orgs/locations';

export const getOrgIdByUrl = (url: string = window.location.pathname): number | undefined => {
  let orgId: number | undefined;
  const match = matchPath<{ orgId?: string }>(url, {
    path: orgLocations.index,
    exact: false,
    strict: true,
  });

  if (match && match.params?.orgId) {
    orgId = Number(match.params.orgId);
  }

  return orgId;
};

export const getFabClass = (isSideMenuOpen: boolean, isExpanded: boolean): string => {
  let ret = 'menuClosedFabCollapsed';

  if (isSideMenuOpen) {
    if (isExpanded) {
      ret = 'menuOpenFabExpanded';
    } else {
      ret = 'menuOpenFabCollapsed';
    }
  } else if (isExpanded) {
    ret = 'menuClosedFabExpanded';
  }

  return ret;
};
