import isEmpty from 'lodash/isEmpty';
import { PaymentRequestCreateOrigin } from 'src/utils/consts';
import { PaymentRequestType } from 'src/utils/types';

export const getPaymentRequestCreateOrigin = (siteOrigin: string, paymentRequest: PaymentRequestType): string => {
  if (siteOrigin === PaymentRequestCreateOrigin.MELIOME && isEmpty(paymentRequest)) {
    return PaymentRequestCreateOrigin.MELIOME_GENERIC_LINK;
  }

  return siteOrigin;
};
