import { useSelector } from 'react-redux';
import Box from 'src/core/ds/box';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useLegalInfoPage } from 'src/pages/get-paid/hooks/useLegalInfoPage';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';

type Props = {
  nextStepURL: string;
  prevStepURL: string;
};

export const CompanyLegalInfoPage = ({ nextStepURL, prevStepURL }: Props) => {
  const companyInfo = useSelector(getCompanyInfo);
  const [historyPush] = useHistoryWithOrgId();

  const handleMoveToNextPage = () => {
    historyPush({ path: nextStepURL });
  };

  const handlePrev = () => {
    historyPush({ path: prevStepURL });
  };

  const { LegalInfoPage } = useLegalInfoPage({
    title: 'onboarding.companyInfo.legalDetails.title',
    titleValues: {
      companyName: (
        <Box as="span" color="grey.700">
          {checkApostropheForPluralPossessive(companyInfo.companyName ?? '')}
        </Box>
      ),
    },
    subtitle: 'onboarding.companyInfo.legalDetails.subTitle',
    nextLabel: 'progress.continue',
    skipLabel: 'progress.skip',
    progressProps: {
      relativeStep: 3 / 6,
    },
    onNext: handleMoveToNextPage,
    onPrev: handlePrev,
    onSkip: handleMoveToNextPage,
    forceUpdate: true,
  });

  return LegalInfoPage;
};
