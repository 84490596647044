import Highlighter from 'react-highlight-words';
import { useIntl } from 'react-intl';
import { Avatar } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { IconSize } from 'src/core/ds/icon';

type Props = {
  isScannedInvoice?: boolean | null;
  search?: string;
};

export const PayCardEmptyTitle = ({ isScannedInvoice, search }: Props) => {
  const intl = useIntl();

  if (!isScannedInvoice) {
    return null;
  }

  const text = intl.formatMessage({ id: 'pay.scannedInvoice.emptyVendorName' });

  return (
    <Flex align="center" overflow="hidden" grow={1} h="2.4rem">
      <Avatar defaultIconSize={IconSize.xs} textColor="white" />
      <Box color="grey.700" flex={1} textStyle="body2Semi" pl={3} isTruncated display="table-cell" overflow="hidden">
        <Highlighter searchWords={[search]} autoEscape textToHighlight={text} />
      </Box>
    </Flex>
  );
};
