import { AmexCardEnum, CardPciDataCardDetails, CardResponse } from '@melio/spend-management-api-axios-client';
import copy from 'copy-to-clipboard';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon as DSIcon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import { VStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import Colors from 'src/core/theme/foundations/colors';
import { useCardPci } from 'src/modules/spend-management/cards/hooks/useCardPci';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { COPY_ANALYTICS_EVENT_MAPPING } from '../constants';
import {
  CardDetailsContainer,
  CardNameContainer,
  CardNameEyeContainer,
  EyeIconContainer,
  FieldWithCopy,
  Icon,
  VirtualCard,
} from '../styles';
import { MaskedNumericText } from './MaskedNumericText';

enum COPY_TYPES {
  NUMBER = 'cardNumber',
  EXPIRY = 'expiryDate',
  CVV = 'cvv',
  ADDRESS = 'address',
}

export const CardPlaceholder = ({ card }: { card?: CardResponse }) => {
  const intl = useIntl();
  const { legalFormattedAddress } = useSelector(getCompanyInfo);
  const { refetch: getCardPciData, loading: cardPciLoading, error: cardPciError } = useCardPci();
  const [cardPciData, setCardPciData] = useState<CardPciDataCardDetails | null>();
  const [show, setShow] = useState(false);

  const isInProgress = card?.status === AmexCardEnum.InProgress;
  const isCardDisabled =
    card?.status === AmexCardEnum.Inactive ||
    card?.status === AmexCardEnum.Cancelled ||
    card?.status === AmexCardEnum.Expired ||
    card?.status === AmexCardEnum.Unsuccessful;
  const showPci = card?.status !== AmexCardEnum.InProgress && card?.status !== AmexCardEnum.Unsuccessful;

  useEffect(() => {
    analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event[`${show ? 'REVEAL' : 'HIDE'}_DETAILS`]);
  }, [show]);

  useEffect(() => {
    const doGetCardPciData = async () => {
      if (showPci) {
        const pciData = await getCardPciData({ cardId: card?.cardId || '' });
        setCardPciData(pciData);
      }
    };

    if (card && showPci) doGetCardPciData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const handleCopy = (copyType: COPY_TYPES) => {
    let value;

    if (copyType === COPY_TYPES.ADDRESS) {
      value = legalFormattedAddress;
    } else if (cardPciData) {
      value = String(cardPciData[copyType]);
    }

    analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, COPY_ANALYTICS_EVENT_MAPPING[copyType]);

    copy(value);
    pushNotification({
      type: NotificationVariant.INFO,
      msg: `spendManagement.pages.dashboard.cardDetails.card.${copyType}.toast`,
    });
  };

  const CopyFieldWrapper = ({
    copyType,
    children,
    isEnabled,
  }: PropsWithChildren<{ copyType: COPY_TYPES; isEnabled: boolean }>) => (
    <FieldWithCopy spacing={2}>
      {children}
      {cardPciData && isEnabled && (
        <Icon
          icon={<DSIcon name={IconNames.duplicate} color={Colors.white} />}
          aria-label="copy"
          onClick={() => handleCopy(copyType)}
        />
      )}
    </FieldWithCopy>
  );

  const EyeIconButton = (props) => (
    <EyeIconContainer data-testid="spend-management-virtual-card-eye-icon">
      <IconButton {...props} />
    </EyeIconContainer>
  );

  return (
    <VirtualCard disabled={isCardDisabled} data-testid="spend-management-virtual-card">
      {card && (
        <>
          <CardNameEyeContainer spacing={10}>
            <CardNameContainer textStyle="body1" data-testid="spend-management-virtual-card-name">
              {card?.nickname}
            </CardNameContainer>
            <>
              {cardPciData && !isCardDisabled && (
                <Tooltip
                  placement="top"
                  label={
                    <MIFormattedText
                      label={`spendManagement.pages.dashboard.cardDetails.${show ? 'hide' : 'show'}Tooltip`}
                    />
                  }
                  shouldWrapChildren
                >
                  <EyeIconButton
                    icon={<DSIcon name={show ? IconNames.eyeHide : IconNames.eyeShow} />}
                    onClick={() => setShow(!show)}
                    aria-label={show ? 'hide' : 'show'}
                    disabled={cardPciLoading}
                  />
                </Tooltip>
              )}
              {!isInProgress && cardPciError && (
                <Tooltip
                  placement="top"
                  label={<MIFormattedText label="spendManagement.pages.dashboard.cardDetails.showTooltip" />}
                  shouldWrapChildren
                >
                  <EyeIconButton
                    icon={<DSIcon name={IconNames.eyeShow} />}
                    onClick={() => {
                      toast({
                        type: 'error',
                        title: intl.formatMessage({ id: 'pages.extension.cardDetails.errors.fetchPci' }),
                      });
                    }}
                    aria-label="show"
                    disabled={cardPciLoading}
                  />
                </Tooltip>
              )}
            </>
          </CardNameEyeContainer>
          <CopyFieldWrapper copyType={COPY_TYPES.NUMBER} isEnabled={!isCardDisabled}>
            <Box textStyle="body1" data-testid="spend-management-virtual-card-number">
              {(cardPciData?.cardNumber || card?.cardDetails?.cardLastFive) && (
                <MaskedNumericText
                  text={(cardPciData?.cardNumber || `${'0'.repeat(10)}${card?.cardDetails?.cardLastFive}`).replace(
                    /\b(\d{4})(\d{6})(\d{5})\b/,
                    '$1 $2 $3'
                  )}
                  isMasked={!show || !cardPciData}
                  lastNDigitsUnmasked={5}
                  letterWidth={13.5}
                  isSelectable={!isCardDisabled}
                />
              )}
            </Box>
          </CopyFieldWrapper>
          <CardDetailsContainer spacing={0}>
            {card?.cardDetails.expiryDate && (
              <VStack alignItems="flex-start" data-testid="spend-management-virtual-card-expiration">
                <Box textStyle="caption1SemiUpper">
                  {intl.formatMessage({ id: 'spendManagement.pages.dashboard.cardDetails.card.expiryDate.title' })}
                </Box>
                <CopyFieldWrapper copyType={COPY_TYPES.EXPIRY} isEnabled={!isCardDisabled}>
                  <Box textStyle="body2">
                    <MaskedNumericText
                      text={card?.cardDetails.expiryDate.replace('-', '/')}
                      isMasked={!show || !cardPciData}
                      lastNDigitsUnmasked={false}
                      letterWidth={10}
                    />
                  </Box>
                </CopyFieldWrapper>
              </VStack>
            )}
            {!isInProgress && !cardPciError && (
              <VStack alignItems="flex-start" data-testid="spend-management-virtual-card-cvv">
                <Box textStyle="caption1SemiUpper">
                  {intl.formatMessage({ id: 'spendManagement.pages.dashboard.cardDetails.card.cvv.title' })}
                </Box>
                <CopyFieldWrapper copyType={COPY_TYPES.CVV} isEnabled={!isCardDisabled}>
                  <Box textStyle="body2">
                    <MaskedNumericText
                      text={cardPciData?.cvv ? cardPciData?.cvv : '0000'}
                      isMasked={!show || !cardPciData}
                      lastNDigitsUnmasked={false}
                      letterWidth={10}
                    />
                  </Box>
                </CopyFieldWrapper>
              </VStack>
            )}
          </CardDetailsContainer>
        </>
      )}
    </VirtualCard>
  );
};
