import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex';
import { IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { getNameParts } from 'src/utils/delivery-methods';
import { getFundingSourceIcon, isDebitCard } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';
import { BankAccountIcon } from './BankAccountIcon';

export const BankAccountOption: React.FC<{ account: AccountType }> = ({ account }) => {
  const { icon, imageSrc } = getFundingSourceIcon(account);
  const label = isDebitCard(account)
    ? 'bills.pay.fundingSource.debitWithBankLabel'
    : 'bills.pay.fundingSource.achLabel';

  return (
    <Flex alignItems="center" key={account.id}>
      {imageSrc && <Image src={imageSrc} w={IconSize.m} h={IconSize.m} mr={1} />}
      {icon && <BankAccountIcon name={icon} size={IconSize.s} disabled={false} />}
      <MIFormattedText label={label} values={getNameParts(account)} />
    </Flex>
  );
};
