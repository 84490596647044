import { featureFlags } from '@melio/shared-web';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FeatureFlags, NotificationCardTypes } from 'src/utils/consts';

type Props = {
  hasFastCheck?: boolean;
};

export const ChecksDelaysNotification = ({ hasFastCheck }: Props) => {
  const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);

  const titleLabel = hasFastCheck
    ? 'bills.pay.date.checksDelays.warningNotification.title'
    : 'bills.pay.date.checksDelays.infoNotification.title';
  const subtitleLabel = hasFastCheck
    ? 'bills.pay.date.checksDelays.warningNotification.subtitle'
    : 'bills.pay.date.checksDelays.infoNotification.subtitle';
  const type = hasFastCheck ? NotificationCardTypes.WARNING : NotificationCardTypes.INFO;

  return (
    <Flex mb={8} w="full" justify="center">
      <Box maxWidth="50rem">
        {isGeneralCheckDelays ? (
          <MINotificationCard
            title={{
              label: 'bills.pay.date.checksDelays.general.title',
            }}
            subtitle={{
              label: 'bills.pay.date.checksDelays.general.subtitle',
            }}
            type={type}
          />
        ) : (
          <MINotificationCard
            title={{
              label: titleLabel,
            }}
            subtitle={{
              label: subtitleLabel,
            }}
            type={type}
            link={{
              label: 'bills.pay.date.checksDelays.learnMore',
              path:
                'https://melio.zendesk.com/hc/en-us/articles/6548872564508-USPS-potential-check-delivery-delays-during-the-holidays',
            }}
          />
        )}
      </Box>
    </Flex>
  );
};
