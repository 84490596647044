import { PAGINATION, PayTabs } from 'src/utils/consts';
import { SortDirection } from 'src/utils/query-utils';

export enum DueDateMenuKey {
  Overdue = 'overdue',
  DueNextWeek = 'dueNextWeek',
  DueNextMonth = 'dueNextMonth',
}

export enum InboxStatusMenuKey {
  Failed = 'failed',
  PendingApproval = 'pending',
  Declined = 'declined',
  PaymentRequest = 'request',
  PayInbox = 'sentByEmail',
  Unpaid = 'unpaid',
}

const inboxOptions = {
  mostRecent: { label: 'pay.sortModule.menu.inboxTabOptions.mostRecent', value: 'mostRecent' },
  'bill.dueDate': { label: 'pay.sortModule.menu.inboxTabOptions.dueDate', value: 'bill.dueDate' },
  'vendor.companyName': { label: 'pay.sortModule.menu.options.vendorsName', value: 'vendor.companyName' },
} as const;
const scheduledOptions = {
  scheduledDate: { label: 'pay.sortModule.menu.scheduledTabOptions.deductionDate', value: 'scheduledDate' },
  'vendor.companyName': { label: 'pay.sortModule.menu.options.vendorsName', value: 'vendor.companyName' },
} as const;
const paidOptions = {
  scheduledDate: { label: 'pay.sortModule.menu.processedTabOptions.processedDate', value: 'scheduledDate' },
  'vendor.companyName': { label: 'pay.sortModule.menu.options.vendorsName', value: 'vendor.companyName' },
} as const;

export const sortOptions = {
  [PayTabs.INBOX]: [inboxOptions.mostRecent, inboxOptions['bill.dueDate'], inboxOptions['vendor.companyName']],
  [PayTabs.SCHEDULED]: [scheduledOptions.scheduledDate, scheduledOptions['vendor.companyName']],
  [PayTabs.PAID]: [paidOptions.scheduledDate, paidOptions['vendor.companyName']],
};

export const sortOptionsDefaults = {
  [PayTabs.INBOX]: inboxOptions.mostRecent.value,
  [PayTabs.SCHEDULED]: scheduledOptions.scheduledDate.value,
  [PayTabs.PAID]: paidOptions.scheduledDate.value,
} as const;

export const DEFAULT_DIRECTION = SortDirection.DESC;

export interface PayListFilters {
  sorting: string;
  status: PayTabs;
  start: number;
  limit: number;
  search: string;
  itemStatus: InboxStatusMenuKey[];
  dueDate: DueDateMenuKey[];
  vendorId: string[];
}

export const DEFAULT_PAY_LIST_FILTERS: PayListFilters = {
  start: PAGINATION.DEFAULT_START,
  limit: PAGINATION.DEFAULT_LIMIT,
  status: PayTabs.INBOX,
  sorting: sortOptionsDefaults[PayTabs.INBOX],
  vendorId: [],
  dueDate: [],
  itemStatus: [],
  search: '',
};
