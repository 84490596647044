type Props = {
  approvalWorkflowId?: number;
  skipSetAmount: boolean;
  replace?: boolean;
  actions: {
    createWorkflow: () => Promise<unknown>;
    updateWorkflow: () => Promise<unknown>;
    deleteWorkflow: () => Promise<unknown>;
    updateUserOrg: () => Promise<unknown>;
  };
};
export const callNeededApprovalActions = async ({
  approvalWorkflowId,
  skipSetAmount,
  actions,
  replace = true,
}: Props) => {
  const promises: Promise<unknown>[] = [];

  if (!skipSetAmount) {
    promises.push(actions.updateUserOrg());
  }

  if (approvalWorkflowId && (skipSetAmount || replace)) {
    promises.push(actions.deleteWorkflow());
  }

  if (!skipSetAmount && (replace || !approvalWorkflowId)) {
    promises.push(actions.createWorkflow());
  }

  if (approvalWorkflowId && !skipSetAmount && !replace) {
    promises.push(actions.updateWorkflow());
  }

  return Promise.all(promises);
};
