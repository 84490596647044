import { whiten } from 'src/core/ds/theme-tools';

export const errorToastStyles = {
  borderRadius: 'lg',
  bg: whiten('red.500', 90),
  color: 'red.500',
  mt: 4,
  mx: 5,
  p: 3,
};

export const legalInfoContainerStyles = {
  zIndex: 10000,
  position: 'fixed',
  top: 0,
  left: 0,
  h: 'full',
  w: 'full',
  bg: 'grey.200',
  overflowY: 'scroll',
};
