export const SELECTION_LIST_CATEGORIES = [
  {
    id: 'false',
    label: 'onboarding.fundingSources.absorbedFees.options.customer.label',
    description: 'onboarding.fundingSources.absorbedFees.options.customer.description',
  },
  {
    id: 'true',
    label: 'onboarding.fundingSources.absorbedFees.options.business.label',
    description: 'onboarding.fundingSources.absorbedFees.options.business.description',
  },
];

export const MELIO_ME_LINK_HOSTNAME = 'melio.me/';
