import { TABLE_SIZES } from 'src/core/ds/table';

const parts = ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'];

const baseStyle = {
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'separate',
    borderSpacing: 0,
    tableLayout: 'fixed',
    overflow: 'hidden',
    width: 'full',
    borderBottomLeftRadius: 'lg',
    borderBottomRightRadius: 'lg',
    color: 'grey.300',
    borderBottom: '1px',
    tr: {
      borderColor: 'grey.300',
      th: {
        apply: 'textStyles.body4Semi',
        minHeight: 4,
      },
      td: {
        color: 'grey.300',
        borderBottom: '1px',
        '>div': {
          color: 'black',
        },
      },
    },
  },
  thead: {
    borderTopLeftRadius: 'lg',
    borderTopRightRadius: 'lg',
    borderColor: 'grey.300',
    tr: {
      bg: 'white',
      height: 12,
      th: {
        color: 'grey.300',
      },
      '&:first-of-type': {
        borderTopLeftRadius: 'lg',
        borderTopRightRadius: 'lg',
        border: '1px',
        borderColor: 'grey.300',
      },
    },
  },
  th: {
    textAlign: 'left',
    textTransform: 'capitalize',
  },
  tbody: {
    bg: 'white',
    tr: {
      borderLeft: '1px',
      borderRight: '1px',
      borderColor: 'grey.300',
      bg: 'white',
      height: '4.4rem',
      '&:last-of-type': {
        td: {
          borderBottom: '1px',
          borderColor: 'grey.300',
          '&:last-of-type': {
            borderBottomRightRadius: 'lg',
          },
          '&:first-of-type': {
            borderBottomLeftRadius: 'lg',
          },
        },
      },
      td: {
        apply: 'textStyles.body4',
        '&:last-of-type': {
          borderRight: '1px',
        },
        '&:first-of-type': {
          borderLeft: '1px',
        },
      },
    },
  },
  td: {
    textAlign: 'left',
  },
  caption: {
    mt: 4,
    textAlign: 'center',
    borderBottomLeftRadius: 'lg',
    borderBottomRightRadius: 'lg',
    borderBottom: '1px',
    borderLeft: '1px',
    borderRight: '1px',
    borderColor: 'grey.300',
  },
};

const numericStyles = {
  '&[data-is-numeric=true]': {
    textAlign: 'right',
    pr: 6,
  },
};

const selectedStyle = {
  '&[data-is-selected=true]': {
    bg: 'grey.200',
  },
};

const simpleVariant = () => ({
  th: {
    borderBottom: '1px',
    borderColor: 'grey.300',
    '&[data-is-numeric=true]': {
      '> div': {
        justifyContent: 'flex-end',
      },
    },
  },
  td: {
    borderBottom: '1px',
    borderColor: 'grey.300',
    ...numericStyles,
  },
  tbody: {
    tr: {
      _hover: {
        borderColor: 'grey.300',
        bg: 'grey.200',
        '&:last-of-type': {
          td: {
            '&:last-of-type': {
              borderBottomRightRadius: 'lg',
            },
            '&:first-of-type': {
              borderBottomLeftRadius: 'lg',
            },
          },
        },
      },
      ...selectedStyle,
    },
  },
  tfoot: {
    tr: {
      '&:last-of-type': {
        th: { borderBottomWidth: 'none' },
      },
    },
  },
});

const variants = {
  simple: simpleVariant,
  unstyled: {},
};

const sizes = {
  [TABLE_SIZES.sm]: {
    td: {
      px: 4,
    },
    caption: {},
  },
  [TABLE_SIZES.md]: {
    td: {
      px: 4,
    },
    caption: {},
  },
};

const defaultProps = {
  variant: 'simple',
  size: 'sm',
};

export default {
  parts,
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
