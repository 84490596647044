import { featureFlags } from '@melio/shared-web';
import { forwardRef } from 'react';
import styled from 'styled-components';
import Box from 'src/core/ds/box';
import { FeatureFlags } from 'src/utils/consts';
import { usePermittedRoleOptions } from 'src/utils/permissions';
import { UserOrganization } from 'src/utils/types';
import { PermissionsAssignment } from '../PermissionsAssignment';
import { ClientModelViewType } from './types';

type ClientItemProps = {
  actorUserOrgs: readonly UserOrganization[];
  client: ClientModelViewType;
  showApprovalFields: boolean;
};

export const ClientItem = forwardRef<HTMLDivElement, ClientItemProps>(
  ({ actorUserOrgs, client, showApprovalFields }, ref) => {
    const { roleOptions } = usePermittedRoleOptions({
      orgId: client.organizationId.value,
    });
    const actorCurUserOrg = actorUserOrgs.find((uo) => uo.organizationId === client.organizationId.value);
    const [approvalWorkflowsEnabled] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);

    return (
      <Box mb={4} ref={ref}>
        <PermissionsAssignment
          approvalWorkflowsEnabled={approvalWorkflowsEnabled}
          showApprovalFields={showApprovalFields}
          data-testid="permissions-assignment"
          id={`permission-assignment_${client.organizationId.value}`}
          requireApprovalDisabled={actorCurUserOrg?.requireApproval}
          requireApprovalAmountThresholdPlaceholder={actorCurUserOrg?.approvalAmountThreshold || 0}
          userRoleModel={client.role}
          approvalAmountThresholdModel={client.approvalAmountThreshold}
          isChecked={client.assigned.value || false}
          requireApprovalModel={client.requireApproval}
          onCheck={(checked) => {
            client.assigned.onChange({ value: checked });
          }}
          roleOptions={roleOptions}
          renderData={
            <NameWrapper>
              <Name>{client.companyName.value}</Name>
            </NameWrapper>
          }
        />
      </Box>
    );
  }
);

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;

const Name = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  font-size: ${(props) => props.theme.text.size.regular};
`;
