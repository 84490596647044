import includes from 'lodash/includes';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import MISingleSelect from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { PayItemPaymentType } from 'src/pay/types';
import { analytics } from 'src/services/analytics';
import { RefundReason } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType | PayItemPaymentType;
  cancelFlow: () => void;
  goNext: () => void;
  setSelectedReason: (selectedReason: RefundReason | null) => void;
  selectedReason: RefundReason | null;
};

export const RequestRefundReason = ({ payment, cancelFlow, goNext, setSelectedReason, selectedReason }: Props) => {
  const intl = useIntl();
  const [validationError, setValidationError] = useState<string | undefined>(undefined);

  const options = Object.values(RefundReason).map((option) => ({
    value: option,
    label: intl.formatMessage({ id: `bills.pay.requestRefund.reason.reasons.${option}` }),
  }));

  const onSelectReason = (result) => {
    const { value } = result;
    setValidationError(undefined);
    setSelectedReason(value);
  };

  const onSubmit = () => {
    if (!includes(RefundReason, selectedReason)) {
      analytics.trackAction('button.click.failed', { paymentId: payment.id, billId: payment.billId });
      setValidationError('bills.pay.requestRefund.reason.errorMessage');
    } else {
      analytics.trackAction('button.click.success', {
        paymentId: payment.id,
        billId: payment.billId,
        chosenReason: selectedReason,
      });
      goNext();
    }
  };

  return (
    <WizardLayout>
      <WizardLayout.TopBar onBackClick={cancelFlow} progressBarValue={50} onCancelClick={cancelFlow} />
      <WizardLayout.Content>
        <WizardLayout.Header>
          <Box textStyle="h1Semi" mt="17rem" data-testid="request-reason-title">
            <MIFormattedText
              label="bills.pay.requestRefund.reason.title"
              values={{
                amount: Number(payment.amount).toFixed(2),
              }}
            />
          </Box>
        </WizardLayout.Header>
        <WizardLayout.Body>
          <Box w="50rem">
            <MISingleSelect
              id="drop-down-refund-reason"
              label="bills.pay.requestRefund.reason.dropdownTitle"
              placeholder="bills.pay.requestRefund.reason.placeholder"
              value={selectedReason}
              onChange={onSelectReason}
              errorMessage={validationError}
              required
              options={options}
              privateData
            />
          </Box>
        </WizardLayout.Body>
        <Button label="bills.pay.requestRefund.reason.continueButton" size={ButtonSizes.lg} onClick={onSubmit} />
      </WizardLayout.Content>
    </WizardLayout>
  );
};
