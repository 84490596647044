import { ReactNode } from 'react';
import Box from 'src/core/ds/box/Box';

type Props = { testId: string; children: ReactNode };

export const FieldValue = ({ testId, children }: Props) => (
  <Box textStyle="body2" mt={1} data-testid={testId}>
    {children}
  </Box>
);
