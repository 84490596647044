import Box from 'src/core/ds/box';
import { useIsOnboard } from 'src/pages/spend-management/hooks/useIsOnboard';
import { AmexConnectPage } from './AmexConnectPage';
import { AmexExtensionPage } from './AmexExtensionPage';

export const SpendManagementSettingPage = () => {
  const isOnboard = useIsOnboard();

  return (
    <Box>
      <AmexConnectPage isOnboard={isOnboard} />
      {isOnboard && <AmexExtensionPage />}
    </Box>
  );
};
