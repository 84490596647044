import { useDispatch, useSelector } from 'react-redux';
import { useBreak } from 'src/hoc';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getProfileActions, profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { useClientBgColor } from 'src/pages/team/team-management/hooks/useClientBgColor';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { capture } from 'src/utils/error-tracking';
import { sortOrganizations } from 'src/utils/organizations';
import { MIExtraDropdown } from './components/MIExtraDropdown';
import { ExtraDropdownOptionType } from './components/MIExtraDropdownItem';
import { MIExtraDropdownMobile } from './components/MIExtraDropdownMobile';

type Props = {
  onBack?: () => void;
  hasAddNewCompanyFooter?: boolean;
  canSwitchCompanies: boolean;
};

export const OrganizationSwitcher = ({ onBack, hasAddNewCompanyFooter, canSwitchCompanies }: Props) => {
  const { getColorProps } = useClientBgColor();
  const dispatch = useDispatch();
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const orgId = useSelector(getOrgId);
  const { switchOrganization } = getProfileActions(dispatch);
  const { isDesktop } = useBreak();
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const accountingFirmOrg = useSelector(profileStore.selectors.getFirstAccountingFirm);
  const [historyPush] = useHistoryWithOrgId();
  const sortedList = sortOrganizations(organizations);
  const list = sortedList.map((item) => ({
    id: item.id,
    label: item.companyName || '',
    link: item.logoUrl,
    color: getColorProps(item.companyName || '').color,
    companyType: item.companyType,
  }));
  const listNoAccountingFirm = list.filter((item) => item.id !== accountingFirmOrg?.id);
  const accountingFirm = list.find((val) => val.id === accountingFirmOrg?.id);
  const emptyState = list.length === 1;

  const currentValue = list.find((val) => val.id === orgId);

  const onClickAddNewCompanyButton = () => {
    analytics.trackAction('add-new-company');
    historyPush({ path: globalLocations.companies.create.details });
  };

  const accountingFirmButtonLabel = emptyState
    ? 'inviteNewCompany.addNewClientEmptyState'
    : 'inviteNewCompany.addNewClient';
  const mobileTitleLabel = isMemberOfAccountingFirm ? 'nav.switchClient' : 'nav.switchCompany';
  const dropdownProps = {
    canSwitchCompanies,
    accountingFirm: accountingFirm as ExtraDropdownOptionType,
    valuesList: listNoAccountingFirm as ExtraDropdownOptionType[],
    value: currentValue as ExtraDropdownOptionType,
    emptyState,
    onChange: (org) => {
      switchOrganization(org.id, org.id === accountingFirm?.id);
    },
    mobileTitleLabel,
    ...(hasAddNewCompanyFooter
      ? {
          buttonAction: onClickAddNewCompanyButton,
          buttonLabel: isMemberOfAccountingFirm ? accountingFirmButtonLabel : 'inviteNewCompany.addNewCompany',
        }
      : {}),
  };

  if (!currentValue?.id) {
    capture(new Error('switcher rendered without org id'), {
      orgId,
      organizationsIds: organizations?.map((o) => o.id),
      sortedListIds: sortedList?.map((o) => o.id),
      listIds: list?.map((o) => o.id),
      currentValueId: currentValue?.id,
    });

    return null;
  }

  return isDesktop || onBack ? (
    <MIExtraDropdown {...dropdownProps} onBack={onBack} />
  ) : (
    <MIExtraDropdownMobile {...dropdownProps} />
  );
};
