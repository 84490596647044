import { shiftSUVCToAchLocations } from 'src/flows/shift-vendor-suvc-to-ach/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { deliveryMethodsLocations } from './delivery-methods/locations';
import { editCheckAddressLocations } from './edit-check-adress/locations';
import { internationalLocations } from './international-delivery-method/locations';
import { vendorCandidatesLocations } from './msn-candidates/locations';
import { shiftVendorToAchLocations } from './shift-vendor-to-ach/locations';
import { shiftToDebitLocations } from './shift-vendor-to-debit/locations';
import { upgradePaymentLocations } from './upgrade-payment/locations';
import { virtualCardCandidatesLocations } from './virtual-card/candidates/locations';
import { virtualCardLocations } from './virtual-card/delivery-method/locations';
import { unilateralLocations } from './virtual-delivery-method/locations';

export const vendorLocations = {
  base: unilateralLocations.base.concat(
    shiftVendorToAchLocations.base,
    upgradePaymentLocations.base,
    shiftToDebitLocations.base,
    editCheckAddressLocations.base,
    internationalLocations.base,
    shiftSUVCToAchLocations.base,
    virtualCardLocations.base,
    virtualCardCandidatesLocations.base,
    vendorCandidatesLocations.base
  ),
  index: '/orgs/:orgId/contacts/vendors',
  view: '/orgs/:orgId/contacts/vendors?id=:id',
  add: '/orgs/:orgId/contacts/vendors/add/',
  addOnboarding: onboardingLocations.vendorAddition.base,
  unilateral: unilateralLocations,
  shiftVendorToAch: shiftVendorToAchLocations,
  upgradePayment: upgradePaymentLocations,
  shiftSuvcToAch: shiftSUVCToAchLocations,
  shiftToDebit: shiftToDebitLocations,
  editCheckAddress: editCheckAddressLocations,
  international: internationalLocations,
  deliveryMethods: deliveryMethodsLocations,
  virtualCard: virtualCardLocations,
  virtualCardCandidates: virtualCardCandidatesLocations,
  vendorCandidates: vendorCandidatesLocations,
};
