import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import { useSelector } from 'react-redux';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { MainTableRowType } from 'src/pages/regular-batch-payments/components/table/types';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import { analytics } from 'src/services/analytics';
import { DeliveryType, FastCheckDeliveryType } from 'src/utils/consts';

export type BatchAnalyticsPropsType = {
  billIds: string[];
  payBillFlowUUID: string;
  vendorId?: number;
} & Record<string, unknown>;

const getBatchPaymentsBillFlowUuids = (batchBills: MainTableRowType[]) =>
  batchBills.map((batchBill) => ({ bill_id: batchBill.id, uuid: batchBill.payBillFlowUUID }));

const getBasicInfo = (batchPaymentRows: MainTableRowType[]) => {
  const bills = batchPaymentRows.flatMap((row) => row.payment.bills);

  return {
    batchBillIds: map(bills, 'id'),
    batchBillCount: bills.length,
    batchBillTotalAmount: sumBy(bills, 'balance'),
    batchPaymentsBillFlowUuids: getBatchPaymentsBillFlowUuids(batchPaymentRows),
  };
};

export const trackLoadBillsAction = (batchPaymentRows: MainTableRowType[]) => {
  const noDeliveryMethodBills = batchPaymentRows.filter((bill) => isEmpty(bill.deliveryOptions));
  analytics.trackAction('regularBatchPayments.loadBills', {
    ...getBasicInfo(batchPaymentRows),
    noDeliveryMethodBillIds: map(noDeliveryMethodBills, 'id'),
    noDeliveryMethodBillCount: noDeliveryMethodBills.length,
  });
};

export const useBatchAnalytics = (bills: MainTableRowType[]) => {
  const { fundingSourceId, scheduledDate } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const { billsList } = useBatchBillsList();
  const batchItemIds = map(billsList, 'id');
  const { failedBills, scheduledPayments } = useSelector(
    regularBatchPaymentsStore.selectors.createBatchPayment.createBatch(batchItemIds)
  );

  const defaultProperties = {
    ...getBasicInfo(bills),
    fundingSourceId,
    isAllAtOnceOption: !isNull(scheduledDate),
    deductionDate: scheduledDate,
  };

  const trackScheduleBatchAction = () => {
    const exposedToFastDelivery = (bills as MainTableRowType[]).filter(
      (bill) => bill.payment?.deliveryMethod?.deliveryType && bill.deliveryOptions && bill.deliveryOptions.length > 1
    );

    const exposedToFastAch = exposedToFastDelivery.filter(
      (bill) => bill.payment.deliveryMethod?.deliveryType === DeliveryType.ACH
    );

    const exposedToExpressCheck = exposedToFastDelivery.filter(
      (bill) => bill.payment.deliveryMethod?.deliveryType === DeliveryType.CHECK
    );

    const failedBillIds = map(failedBills, 'billId');
    const scheduledBillIds = map(scheduledPayments, 'billId');

    const fastACHBills = scheduledPayments.filter((payment) => payment.deliveryPreference === 'expedited-ach');

    const expressCheckBills = scheduledPayments.filter(
      (payment) => payment.deliveryPreference === FastCheckDeliveryType.EXPRESS
    );
    analytics.trackAction('regularBatchPayments.createPayments', {
      ...defaultProperties,
      failedBillIds,
      failedBillCount: failedBillIds.length,
      scheduledBillIds,
      scheduledBillCount: scheduledBillIds.length,
      FastACHBillIds: map(fastACHBills, 'billId'),
      FastACHBillCount: fastACHBills.length,
      ExpressCheckBillsIds: map(expressCheckBills, 'billId'),
      ExpressCheckBillsCount: expressCheckBills.length,
    });
    analytics.trackAction('regularBatchPayments.createPayments.fastAchExposure', {
      ...getBasicInfo(exposedToFastAch),
    });
    analytics.trackAction('regularBatchPayments.createPayments.expressCheckExposure', {
      ...getBasicInfo(exposedToExpressCheck),
    });
  };

  return {
    trackScheduleBatchAction,
  };
};

export const useSinglePaymentAnalytics = (itemId) => {
  const { getSingleBatchItem } = useBatchBillsList();
  const listItem = getSingleBatchItem(itemId);

  const trackRemoveFromListAction = () => {
    analytics.trackAction('regularBatchPayments.removeBillId', {
      billId: listItem.payment.bills.map((bill) => bill.id),
      deliveryMethodId: listItem.payment.deliveryMethod?.id,
      payBillFlowUUID: listItem.payBillFlowUUID,
    });
  };

  const trackViewBillDetailsByPayment = () => {
    analytics.trackAction('regularBatchPayments.cells.actions.viewBillDetails', {
      billId: listItem.payment.bills[0].id,
      payBillFlowUUID: listItem.payBillFlowUUID,
    });
  };

  return { trackRemoveFromListAction, trackViewBillDetailsByPayment };
};

export const useBillSubRowAnalytics = (itemId) => {
  const { getSingleBatchItem } = useBatchBillsList();
  const listItem = getSingleBatchItem(itemId);

  const trackRemoveFromListAction = () => {
    analytics.trackAction('regularBatchPayments.removeBillSubRow', {
      billId: itemId,
      deliveryMethodId: listItem.payment.deliveryMethod?.id,
      payBillFlowUUID: listItem.payBillFlowUUID,
    });
  };

  const trackViewBillDetailsByBill = () => {
    analytics.trackAction('regularBatchPayments.cells.actions.viewBillDetails', {
      billId: itemId,
      payBillFlowUUID: listItem.payBillFlowUUID,
    });
  };

  return { trackRemoveFromListAction, trackViewBillDetailsByBill };
};

export const trackExpandRow = (analyticsProps: BatchAnalyticsPropsType) => {
  analytics.trackAction('regularBatchPayments.clickExpandRow', analyticsProps);
};
