import * as React from 'react';
import { Button, ButtonVariants } from 'src/core/ds/button';

type Props = {
  isDisabled: boolean;
  isProcessing: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const CardEditButtons: React.FC<Props> = ({ isDisabled, isProcessing, onSubmit, onCancel }) => (
  <>
    <Button mr={4} variant={ButtonVariants.tertiary} onClick={onCancel} label="getPro.cards.actions.edit.cancel" />
    <Button disabled={isDisabled} isLoading={isProcessing} onClick={onSubmit} label="getPro.cards.actions.edit.save" />
  </>
);

export default CardEditButtons;
