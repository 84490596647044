import { featureFlags } from '@melio/shared-web';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { getSiteFromUrl, SiteProvider } from 'src/context/SiteContext';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { useInitWizard } from 'src/hooks/useWizard/useWizard';
import { setupStore } from 'src/redux/store';
import { TranslationProvider } from '../services/translation-service';
import { FeedbackProvider } from './FeedbackProvider/FeedbackProvider';

const history = createBrowserHistory();

type Props = {
  url: string;
  children: React.ReactNode;
};

const SiteAwareProviders = withSiteContext()(({ site, children }) => {
  const { store, persistor, intl } = setupStore(site, history);
  useInitWizard();

  return (
    <Provider store={store}>
      <featureFlags.FeatureProvider>
        <FeedbackProvider>
          <PersistGate loading={null} persistor={persistor}>
            <TranslationProvider value={intl}>{children}</TranslationProvider>
          </PersistGate>
        </FeedbackProvider>
      </featureFlags.FeatureProvider>
    </Provider>
  );
});

export const AppProviders = ({ url, children }: Props) => (
  <SiteProvider site={getSiteFromUrl(url)}>
    <SiteAwareProviders>{children}</SiteAwareProviders>
  </SiteProvider>
);

export { history };
