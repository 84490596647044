const baseStyle = {
  borderRadius: 'lg',
  borderColor: 'transparent',
  boxShadow: 'none',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  _disabled: {
    cursor: 'not-allowed',
  },
  _hover: {
    _disabled: {
      bg: 'initial',
    },
  },
  _focus: {
    outline: 0,
  },
};

function variantTertiaryNaked() {
  const disabled = {
    color: 'grey.500',
  };

  const loading = {
    color: 'primary.500',
  };

  return {
    color: 'black',
    bg: 'transparent',
    _hover: {
      color: 'primary.500',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      color: 'primary.600',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  };
}

function variantSecondary() {
  const disabled = {
    bg: 'transparent',
    color: 'grey.500',
    borderColor: 'grey.500',
  };

  const loading = {
    bg: 'primary.300',
    color: 'primary.500',
    borderColor: 'primary.500',
  };

  return {
    border: '2px',
    borderColor: 'primary.500',
    bg: 'transparent',
    color: 'primary.500',
    _hover: {
      bg: 'primary.300',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      bg: 'primary.400',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  };
}

function variantPrimary() {
  const disabled = {
    bg: 'grey.300',
    color: 'grey.500',
  };

  const loading = {
    bg: 'primary.600',
    color: 'white',
  };

  return {
    bg: 'primary.500',
    color: 'white',
    _hover: {
      bg: 'primary.600',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      bg: 'primary.700',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  };
}

function variantError() {
  const disabled = {
    bg: 'grey.300',
    color: 'grey.500',
  };

  const loading = {
    bg: 'red.600',
    color: 'white',
  };

  return {
    bg: 'red.500',
    color: 'white',
    _hover: {
      bg: 'red.600',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      bg: 'red.700',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  };
}

function variantSuccess() {
  const disabled = {
    bg: 'grey.300',
    color: 'grey.500',
  };
  const loading = {
    bg: 'green.600',
    color: 'white',
  };

  return {
    bg: 'green.500',
    color: 'white',
    _hover: {
      bg: 'green.600',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      bg: 'green.700',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  };
}

function variantQuickbooks() {
  return {
    bg: 'qb.500',
    color: 'white',
    _hover: {
      bg: 'qb.600',
    },
    _active: {
      bg: 'qb.600',
    },
  };
}

function variantFreshbooks() {
  return {
    bg: 'fb.500',
    color: 'white',
    _hover: {
      bg: 'fb.600',
    },
    _active: {
      bg: 'fb.600',
    },
  };
}

function variantIntuit() {
  return {
    bg: 'intuit.500',
    color: 'white',
    _hover: {
      bg: 'intuit.600',
    },
    _active: {
      bg: 'intuit.700',
    },
  };
}

function variantTertiary() {
  const disabled = {
    color: 'grey.500',
    bg: 'grey.300',
  };

  const loading = {
    color: 'black',
    bg: 'grey.300',
  };

  return {
    color: 'black',
    bg: 'grey.300',
    _hover: {
      bg: 'grey.400',
      _disabled: {
        ...disabled,
        _loading: loading,
      },
    },
    _active: {
      color: 'grey.700',
    },
    _disabled: {
      ...disabled,
      _loading: loading,
    },
  };
}

const variantUnstyled = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
  _active: {
    bg: 'transparent',
  },
};

const variants = {
  tertiaryNaked: variantTertiaryNaked,
  secondary: variantSecondary,
  primary: variantPrimary,
  error: variantError,
  success: variantSuccess,
  tertiary: variantTertiary,
  quickbooks: variantQuickbooks,
  freshbooks: variantFreshbooks,
  intuit: variantIntuit,
  unstyled: variantUnstyled,
};

const sizes = {
  lg: {
    apply: 'textStyles.body3Semi',
    px: 6,
    minW: '12rem',
    h: '4.8rem',
  },
  md: {
    apply: 'textStyles.body3Semi',
    px: 5,
    minW: '12rem',
    h: '4rem',
  },
  sm: {
    apply: 'textStyles.body4Semi',
    px: 4,
    minW: '10rem',
    h: '3.2rem',
  },
};

const defaultProps = {
  variant: 'primary',
  size: 'md',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
