const baseStyle = {
  display: 'flex',
  width: 'full',
  height: '7.2rem',
  minHeight: '7.2rem',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  background: 'white',
  overflow: 'hidden',
  boxShadow: 500,
};

export default {
  baseStyle,
};
