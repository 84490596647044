import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';

type Props = {
  onDone: () => any;
  onCancel: () => any;
  doneLabel: string;
  doneValues?: Record<string, any>;
  cancelLabel: string;
  isDisabled?: boolean;
  isDoneDisabled?: boolean;
  className?: string;
  isProcessing?: boolean;
  titleLabel?: string;
};

export const FloatedButtons = ({
  className = '',
  onDone,
  onCancel,
  doneLabel,
  doneValues,
  cancelLabel,
  isDisabled = false,
  isDoneDisabled = false,
  isProcessing = false,
}: Props) => (
  <Flex
    className={className}
    h="7rem"
    w="full"
    justify={{ base: 'space-between', sm: 'flex-end' }}
    align="center"
    bgColor="white"
    pos="absolute"
    bottom="0"
    right="0"
    top={{ base: 0, sm: 'unset' }}
    boxSizing="border-box"
    zIndex="sticky"
    boxShadow="300"
    pr={{ base: 4, sm: 'unset' }}
    px={{ md: 6 }}
  >
    {onCancel && (
      <Button variant={ButtonVariants.tertiaryNaked} onClick={onCancel} label={cancelLabel} isDisabled={isDisabled} />
    )}
    <Button
      onClick={onDone}
      label={doneLabel}
      values={doneValues}
      isDisabled={isDisabled || isDoneDisabled}
      isLoading={isProcessing}
    />
  </Flex>
);
