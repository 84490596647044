import { RecordOf } from 'immutable';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc';
import { useCheckOwnedVendorExists } from 'src/pages/bill/pay/hooks/useCheckOwnedVendorExists';
import { useConfirmPaymentNavigate } from 'src/pages/bill/pay/hooks/useConfirmPaymentNavigate';
import { useFundingSourcesNavigate } from 'src/pages/bill/pay/hooks/useFundingSourcesNavigate';
import { useGoAddPage } from 'src/pages/bill/pay/hooks/useGoAddPage';
import { useGoEditPage } from 'src/pages/bill/pay/hooks/useGoEditPage';
import { useGoExitPage } from 'src/pages/bill/pay/hooks/useGoExitPage';
import { useMemoNavigate } from 'src/pages/bill/pay/hooks/useMemoNavigate';
import { PayBillDataContextType, usePayBillDataContext } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { usePayBillPaymentOperations } from 'src/pages/bill/pay/hooks/usePayBillPaymentOperations';
import { usePayBillSubmit } from 'src/pages/bill/pay/hooks/usePayBillSubmit';
import { useSelectDateNavigate } from 'src/pages/bill/pay/hooks/useSelectDateNavigate';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getProfile } from 'src/redux/user/selectors';
import { Site } from 'src/sites/site';
import { DeliveryType, TaxIdType } from 'src/utils/consts';
import {
  AccountType,
  BankType,
  GoogleCombinedAddressType,
  LegalAddressType,
  NavigateType,
  PaymentType,
} from 'src/utils/types';

type Props = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
  inputFields?: ReadonlyArray<keyof LegalAddressType>;
  payBillNextStepState?: Record<string, string>;
};

export type PayBillProps = {
  navigate: NavigateType;
  onPrev: () => void;
  onNext: () => void;
  goAddDeliveryMethod: (selectedDeliveryMethodType: DeliveryType, selectedDeliveryMethodId?: number) => void;
  onNextFundingSources: (selectedFundingSource?: AccountType) => void;
  onPrevFundingSources: () => void;
  onPrevConfirmPayment: () => void;
  onNextSelectDate: () => void;
  onPrevDate: () => void;
  onNextMemo: () => void;
  onPrevMemo: () => void;
  goExit: () => void;
  goEditFundingSource: () => void;
  goEditPartialAmount: () => void;
  goEditDate: () => void;
  goEditDeliveryMethod: () => void;
  onSubmit: (payment?: PaymentType) => void;
  goAddSelectedFundingSource: (value: string) => void;
  setPaymentAmount: (amount: number) => void;
  goEditNote: () => void;
  isLoading: boolean;
  email: string;
  id: string;
  onLegalInfoSubmit: (
    address: GoogleCombinedAddressType,
    companyAddress: GoogleCombinedAddressType,
    legalCompanyName: string,
    taxId?: string,
    taxIdType?: TaxIdType,
    contactFirstName?: string,
    contactLastName?: string,
    phone?: string,
    isValidateLegalAddress?: boolean
  ) => void;
  site: Site;
  getOwnedVendorExists: () => Promise<boolean>;
  checkBankAccount: (bankAccount: RecordOf<BankType>) => void;
} & PayBillDataContextType;

export function withPayBillData() {
  return function (Component: any) {
    return compose(withNavigator())(
      memo(({ navigate, nextStepURL, prevStepURL, inputFields = [], payBillNextStepState }: Props) => {
        const match = useRouteMatch<{ billId?: string }>();
        const { billId } = match.params;
        const bill = useSelector(getBill);
        const profile = useSelector(getProfile);

        const { isLoading, validationErrors, deliveryOptionsDates, getDeliveryOptionsDates } = usePayBillDataContext();

        const { onNext, onPrev } = usePayBillNavigateCommon({
          navigate,
          nextStepURL,
          prevStepURL,
          nextStepState: payBillNextStepState,
        });
        const { onNextFundingSources, onPrevFundingSources } = useFundingSourcesNavigate({
          navigate,
          nextStepURL,
          prevStepURL,
        });
        const { onNextMemo, onPrevMemo } = useMemoNavigate({
          navigate,
          nextStepURL,
          prevStepURL,
        });
        const { onNextSelectDate, onPrevDate } = useSelectDateNavigate({
          navigate,
          nextStepURL,
          prevStepURL,
        });
        const { onPrevConfirmPayment } = useConfirmPaymentNavigate({
          navigate,
          nextStepURL,
          prevStepURL,
        });
        const { onSubmit, onLegalInfoSubmit } = usePayBillSubmit({
          navigate,
          nextStepURL,
          prevStepURL,
          inputFields,
        });
        const { setPaymentAmount } = usePayBillPaymentOperations({
          navigate,
          nextStepURL,
          prevStepURL,
        });
        const { getOwnedVendorExists } = useCheckOwnedVendorExists({ bill });
        const { goAddSelectedFundingSource, goAddDeliveryMethod } = useGoAddPage({
          navigate,
          nextStepURL,
          prevStepURL,
        });
        const {
          goEditDeliveryMethod,
          goEditFundingSource,
          goEditPartialAmount,
          goEditNote,
          goEditDate,
        } = useGoEditPage({
          navigate,
          nextStepURL,
          prevStepURL,
        });
        const [goExit] = useGoExitPage({ navigate });

        return (
          <>
            <Component
              isLoading={isLoading}
              validationErrors={validationErrors}
              deliveryOptionsDates={deliveryOptionsDates}
              getDeliveryOptionsDates={getDeliveryOptionsDates}
              navigate={navigate}
              onPrev={onPrev}
              onNext={onNext}
              onNextFundingSources={onNextFundingSources}
              onPrevFundingSources={onPrevFundingSources}
              onNextMemo={onNextMemo}
              onPrevMemo={onPrevMemo}
              onNextSelectDate={onNextSelectDate}
              onPrevDate={onPrevDate}
              onPrevConfirmPayment={onPrevConfirmPayment}
              onSubmit={onSubmit}
              onLegalInfoSubmit={onLegalInfoSubmit}
              goAddSelectedFundingSource={goAddSelectedFundingSource}
              goAddDeliveryMethod={goAddDeliveryMethod}
              goEditDeliveryMethod={goEditDeliveryMethod}
              goEditFundingSource={goEditFundingSource}
              goEditPartialAmount={goEditPartialAmount}
              goEditNote={goEditNote}
              goEditDate={goEditDate}
              goExit={goExit}
              email={profile.email}
              setPaymentAmount={setPaymentAmount}
              getOwnedVendorExists={getOwnedVendorExists}
              id={billId}
            />
          </>
        );
      })
    );
  };
}
