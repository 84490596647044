import { createBatchDeleteSlice } from 'src/helpers/redux/restBatchDeleteSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { InvoiceType } from 'src/modules/invoices/types';
import { name } from '../consts';

export const proRequestsBatchDeleteSlice = createBatchDeleteSlice<InvoiceType>({
  actionName: 'proBatchDelete',
  api: getProApi.requests.batchDelete,
  storeName: name,
});
