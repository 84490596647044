/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import { EventMappingsTree } from 'src/services/analytics/types';

const syncQBDEventPage = 'quickboooks-desktop-sync';
const syncSSOEventPage = 'accounting-software-sso-sync';
const accountingSoftwareSyncInvoiceGeneration = 'settings-accounting-software-sync';

const MAPPING: EventMappingsTree = {
  '/orgs/*/sync-qbd/entry': {
    'page.view': [syncQBDEventPage, 'download'],
    'go.exit': [`${syncQBDEventPage}-download`, 'exit'],
    'settings.newAccountingSoftware.QuickbooksDesktopSync.entry.action': [`${syncQBDEventPage}-download`, 'continue'],
  },
  '/orgs/*/sync-qbd/open-sync-manager': {
    'page.view': [syncQBDEventPage, 'open-sync-manager'],
    'go.back': [`${syncQBDEventPage}-open-sync-manager`, 'go-back'],
    'go.exit': [`${syncQBDEventPage}-open-sync-manager`, 'exit'],
    'progress.next': [`${syncQBDEventPage}-open-sync-manager`, 'continue'],
    'copy-key': [`${syncQBDEventPage}-open-sync-manager`, 'copied'],
  },
  '/orgs/*/sync-qbd/license-key': {
    'page.view': [syncQBDEventPage, 'license-key'],
    'go.back': [`${syncQBDEventPage}-license-key`, 'go-back'],
    'go.exit': [`${syncQBDEventPage}-license-key`, 'exit'],
    'progress.next': [`${syncQBDEventPage}-license-key`, 'continue'],
    'copy-key': [`${syncQBDEventPage}-license-key`, 'copied'],
  },
  '/orgs/*/sync-qbd/open': {
    'page.view': [syncQBDEventPage, 'open-app'],
    'go.back': [`${syncQBDEventPage}-open-app`, 'go-back'],
    'go.exit': [`${syncQBDEventPage}-open-app`, 'exit'],
    'progress.next': [`${syncQBDEventPage}-open-app`, 'continue'],
  },
  '/orgs/*/sync-qbd/select-region': {
    'page.view': [syncQBDEventPage, 'select-region'],
    'go.back': [`${syncQBDEventPage}-select-region`, 'go-back'],
    'go.exit': [`${syncQBDEventPage}-select-region`, 'exit'],
    'progress.next': [`${syncQBDEventPage}-select-region`, 'continue'],
  },
  '/orgs/*/sync-qbd/access': {
    'page.view': [syncQBDEventPage, 'give-access'],
    'go.back': [`${syncQBDEventPage}-give-access`, 'go-back'],
    'go.exit': [`${syncQBDEventPage}-give-access`, 'exit'],
    'progress.next': [`${syncQBDEventPage}-give-access`, 'continue'],
  },
  '/orgs/*/sync-qbd/invoice-generation': {
    'option-selected-invoice-generation': [
      `${syncQBDEventPage}-invoice-generation`,
      'select-option',
      { label: 'label', value: 'value' },
    ],
    'progress.continue': [`${syncQBDEventPage}-invoice-generation`, 'continue', { selectedOption: 'selectedOption' }],
    'settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInModal.backButton': [
      `${syncQBDEventPage}-invoice-generation`,
      'no-opt-in-modal-back-button',
    ],
    'settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInModal.confirmButton': [
      `${syncQBDEventPage}-invoice-generation`,
      'no-opt-in-modal-confirm-button',
    ],
  },
  '/orgs/*/sync-qbd/in-progress': {
    'page.view': [syncQBDEventPage, 'in-progress'],
    'go.back': [`${syncQBDEventPage}-in-progress`, 'go-back'],
  },
  '/orgs/*/sync-qbd/success': {
    'page.view': [syncQBDEventPage, 'success'],
    'go.back': [`${syncQBDEventPage}-success`, 'go-back'],
    'progress.continue': [`${syncQBDEventPage}-success`, 'continue'],
  },
  '/orgs/*/sync-qbd/mobile': {
    'page.view': [syncQBDEventPage, 'mobile'],
    'settings.newAccountingSoftware.QuickbooksDesktopSync.mobile.action': [`${syncQBDEventPage}-mobile`, 'exit'],
    'send-email': [`${syncQBDEventPage}-mobile`, 'send-email'],
  },
  '/orgs/*/sync': {
    'page.view': [syncSSOEventPage, 'in-progress'],
  },
  '/orgs/*/sync/success': {
    'page.view': [syncSSOEventPage, 'success'],
    'progress.continue': [`${syncSSOEventPage}-success`, 'continue'],
  },
  '/orgs/*/sync/redirect': {
    'page.view': [syncSSOEventPage, 'redirect'],
  },
  '/orgs/*/settings/accounting-software': {
    'settings.accountingSoftware.invoiceGeneration.invoiceGenerationOn': [
      `${accountingSoftwareSyncInvoiceGeneration}-invoice-generation`,
      'switch-on',
      { isCurrentlyTurnedOn: 'isCurrentlyTurnedOn' },
    ],
    'settings.accountingSoftware.invoiceGeneration.invoiceGenerationOff': [
      `${accountingSoftwareSyncInvoiceGeneration}-invoice-generation`,
      'switch-off',
      { isCurrentlyTurnedOn: 'isCurrentlyTurnedOn' },
    ],
    'settings.accountingSoftware.invoiceGeneration.optInModal.cancelAction': [
      `${accountingSoftwareSyncInvoiceGeneration}-invoice-generation`,
      'optInModal-cancelAction',
    ],
    'settings.accountingSoftware.invoiceGeneration.optInModal.acceptAction': [
      `${accountingSoftwareSyncInvoiceGeneration}-invoice-generation`,
      'optInModal-acceptAction',
    ],
    'settings.accountingSoftware.invoiceGeneration.optOutModal.cancelAction': [
      `${accountingSoftwareSyncInvoiceGeneration}-invoice-generation`,
      'optOutModal-cancelAction',
    ],
    'settings.accountingSoftware.invoiceGeneration.optOutModal.approveAction': [
      `${accountingSoftwareSyncInvoiceGeneration}-invoice-generation`,
      'optOutModal-approveAction',
    ],
  },
};

export default MAPPING;
