import isEmpty from 'lodash/isEmpty';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { ZIP_CODE_REGEX } from 'src/pages/bill/pay/installments/utils';

export type ValidatableLegalAddressKeys = keyof Pick<
  InternalBusinessOwnershipDetailsModel,
  'addressLine1' | 'city' | 'state' | 'zipCode'
>;

export const validator = (key: ValidatableLegalAddressKeys, modelState: InternalBusinessOwnershipDetailsModel) => {
  switch (key) {
    case 'addressLine1':
      if (isEmpty(modelState.addressLine1)) return 'financing.businessOwnershipDetails.fields.address.errors.empty';

      break;
    case 'city':
      if (isEmpty(modelState.city)) return 'financing.businessOwnershipDetails.fields.city.errors.empty';

      break;
    case 'state':
      if (isEmpty(modelState.state)) return 'financing.businessOwnershipDetails.fields.state.errors.empty';

      break;
    case 'zipCode':
      if (isEmpty(modelState.zipCode)) return 'financing.businessOwnershipDetails.fields.zipCode.errors.empty';

      if (!ZIP_CODE_REGEX.test(modelState.zipCode))
        return 'financing.businessOwnershipDetails.fields.zipCode.errors.invalid';

      break;
    default:
      return undefined;
  }

  return undefined;
};
