import { useLocation } from 'react-router-dom';
import { DrawerBody, DrawerCloseButton, DrawerHeader } from 'src/core/ds/drawer';
import IconButton from 'src/core/ds/iconButton';
import Spacer from 'src/core/ds/spacer';
import { ReactComponent as EmailIcon } from 'src/images/get-pro/email-icon.svg';
import { ReactComponent as RightArrow } from 'src/images/get-pro/right-arrow-24.svg';
import { ContactType } from 'src/utils/types';
import { CardHeader } from '../../cards/CardHeader';
import CustomerCard from '../../cards/customer/CustomerCard';

type Props = {
  customer?: Partial<ContactType>;
  onBackDrawer?: () => void;
};

const CustomerDrawer = ({ customer, onBackDrawer }: Props) => {
  const location = useLocation<{ showBackButton: boolean }>();
  const showBackButton = location.state?.showBackButton;

  return (
    <>
      <DrawerHeader>
        <CardHeader companyName={customer?.companyName || customer?.contactName || ''} />
        <Spacer />
        {customer?.contactEmail && (
          <IconButton
            href={`mailto:${customer.contactEmail}`}
            aria-label="contact-email"
            icon={<EmailIcon />}
            as="a"
            mr={5}
            size="md"
          />
        )}
        {showBackButton ? (
          <IconButton
            aria-label="right-arrow"
            icon={<RightArrow />}
            onClick={onBackDrawer}
            justifyContent="flex-end"
            size="md"
          />
        ) : (
          <DrawerCloseButton size="md" />
        )}
      </DrawerHeader>
      <DrawerBody>
        <CustomerCard customer={customer} />
      </DrawerBody>
    </>
  );
};

export default CustomerDrawer;
