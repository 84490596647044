import { AreaLoader } from 'src/components/common/AreaLoader';
import Flex from 'src/core/ds/flex';
import { PageContainerProps } from 'src/hoc/withListContainer';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useViewScannedInvoiceData } from 'src/pages/bill/hooks/useViewScannedInvoiceData';
import { billLocations } from 'src/pages/bill/locations';
import { analytics } from 'src/services/analytics';
import { deserializeScannedInvoiceId } from 'src/utils/bills';
import { useQueryString } from 'src/utils/hooks';
import { EmailDetails } from './components/cards/EmailDetails';
import { ScannedInvoiceDetails } from './components/cards/ScannedInvoiceDetails';
import { ScannedInvoiceHeader } from './components/header/ScannedInvoiceHeader';

type Props = {
  filters: PageContainerProps['filters'];
  eventPage: string;
  backPath: string;
};

export const ViewScannedInvoice = ({ eventPage, backPath }: Props) => {
  const query = useQueryString();
  const [historyPush] = useHistoryWithOrgId();

  const { id } = query;
  const { scannedInvoiceId } = deserializeScannedInvoiceId(id);
  const { scannedInvoice, isLoading } = useViewScannedInvoiceData(scannedInvoiceId);

  const onClick = () => {
    analytics.track(eventPage, 'review-bill-action', { scannedInvoiceId });
    historyPush({ path: billLocations.createScannedInvoice, query: { id: scannedInvoiceId } });
  };

  if (!scannedInvoice || isLoading) {
    return <AreaLoader />;
  }

  return (
    <Flex direction="column" h="full">
      <ScannedInvoiceHeader onClick={onClick} scannedInvoice={scannedInvoice} backPath={backPath} />
      <ScannedInvoiceDetails onClick={onClick} eventPage={eventPage} scannedInvoice={scannedInvoice} />
      <EmailDetails inboxEmail={scannedInvoice?.inboxEmail} />
    </Flex>
  );
};
