import { useEffect } from 'react';
import { EmailLink } from 'src/components/common/EmailLink';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Image } from 'src/core/ds/image';
import { useSiteContext } from 'src/hoc/withSiteContext';
import ErrorImage from 'src/images/core/error-icon.svg';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { eventPage } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardView';
import { analytics } from 'src/services/analytics';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardCanceledView = ({ state, analyticsProperties }: Props) => {
  const site = useSiteContext();
  useEffect(() => {
    analytics.track(eventPage, 'canceled', analyticsProperties);
  }, [analyticsProperties]);
  const { organization } = state;

  return (
    <ContentWrapper>
      <Image width={8} height={8} src={ErrorImage} />
      <Box textStyle="body2Semi" mt={5} mb={2} data-testid="title">
        <MIFormattedText label="vendors.virtualCard.canceled.title" />
      </Box>
      <Box textStyle="body2" data-testid="description">
        <MIFormattedText
          label="vendors.virtualCard.canceled.description"
          values={{
            payorName: organization?.companyName,
            payorEmail: <EmailLink email={organization?.userOrganizations[0].user?.email} />,
            supportEmail: <EmailLink email={site.config.support.email} />,
          }}
        />
      </Box>
    </ContentWrapper>
  );
};
