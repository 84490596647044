import { ComponentProps, forwardRef } from 'react';
import { ReactDatePicker } from 'react-datepicker';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { DatePicker } from 'src/components/form/DatePicker';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { ModelViewField } from 'src/ui/form/use-form';
import { TextInputSize } from 'src/utils/consts';
import { InlineFieldGroup } from './InlineFieldGroup';

type DatePickerProps = ComponentProps<typeof DatePicker>;

export type InlineDatePickerFieldProps = Pick<
  DatePickerProps,
  'disabled' | 'min' | 'max' | 'isClearable' | 'filterDate' | 'renderDayContents'
> & {
  model?: ModelViewField<string>;
  value?: string;
  label?: string;
  id?: string;
  required?: boolean;
  onChange?: (any) => any;
  viewOnly?: boolean;
  className?: string;
  placeholder?: string;
  rightIcon?: IconNames;
};

export const InlineDatePickerField = forwardRef(
  (props: InlineDatePickerFieldProps, ref: React.Ref<ReactDatePicker<never, undefined>> | undefined) => {
    const { model, className, label, viewOnly, required, placeholder, rightIcon, ...rest } = props;
    const value = props.value === undefined ? model?.value : props.value;
    const dateValue = value ? new Date(value) : new Date();
    const onChange = props.onChange || model?.onChange;
    const onChangeDate = ({ id, date }) => {
      if (onChange) {
        onChange({ id, value: date });
      }
    };
    const id = props.id || model?.id;

    return (
      <InlineFieldGroup className={className}>
        <Box pos="relative" w="full" sx={{ '& .react-datepicker__close-icon': { top: 'px' } }}>
          {label && (
            <MIInputLabel
              inputId={id}
              label={label}
              errorMessage={model?.error}
              size={TextInputSize.INLINE}
              required={required}
            />
          )}
          {viewOnly ? (
            <Box color="grey.500" textStyle="body2">
              <MIFormattedDate placeholder={placeholder} date={value} />
            </Box>
          ) : (
            <DatePicker
              {...rest}
              ref={ref}
              date={dateValue}
              onChange={onChangeDate}
              id={id!}
              size={TextInputSize.INLINE}
              errorMessage={model?.error}
            />
          )}
          {rightIcon && (
            <Box pos="absolute" right={0} bottom={1} pointerEvents="none">
              <Icon name={rightIcon} size={IconSize.lg} />
            </Box>
          )}
        </Box>
      </InlineFieldGroup>
    );
  }
);
