import isEmpty from 'lodash/isEmpty';
import {
  useBusinessTaxRelatedValidator,
  ValidatableBusinessTaxRelatedKeys,
} from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/business-tax-related/useBusinessTaxRelatedValidator';
import {
  useLegalBusinessAddressValidator,
  ValidatableLegalBusinessAddressKeys,
} from 'src/pages/bill/pay/installments/pages/application-details/business-details/form/legal-business-address/useLegalBusinessAddressValidator';
import { BusinessDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { US_PHONE_NUMBER_REGEX } from '../../../../utils';

export const useValidator = () => {
  const { validator: businessTaxRelatedValidator } = useBusinessTaxRelatedValidator();
  const { validator: legalBusinessAddressValidator } = useLegalBusinessAddressValidator();

  const validate = (key: string, value: unknown, modelState: BusinessDetailsModel) => {
    switch (key as keyof BusinessDetailsModel) {
      case 'companyName':
        if (isEmpty(modelState.companyName)) return 'financing.businessDetails.fields.companyName.errors.empty';

        break;
      case 'legalCompanyName':
        if (isEmpty(modelState.legalCompanyName))
          return 'financing.businessDetails.fields.legalBusinessName.errors.empty';

        break;
      case 'addressLine1':
      case 'city':
      case 'state':
      case 'zipCode':
        return legalBusinessAddressValidator(key as ValidatableLegalBusinessAddressKeys, modelState);
      case 'annualRevenue':
        if (isEmpty(modelState.annualRevenue)) return 'financing.businessDetails.fields.annualRevenue.errors.empty';

        break;
      case 'ownerPhone':
        if (isEmpty(modelState.ownerPhone)) return 'financing.businessDetails.fields.ownerPhone.errors.empty';

        if (!US_PHONE_NUMBER_REGEX.test(modelState.ownerPhone))
          return 'financing.businessDetails.fields.ownerPhone.errors.invalid';

        break;
      case 'type':
      case 'taxIdNumber':
        return businessTaxRelatedValidator(key as ValidatableBusinessTaxRelatedKeys, modelState);
      case 'industry':
        if (isEmpty(modelState.industry)) return 'financing.businessDetails.fields.businessIndustry.errors.empty';

        break;
      default:
        return undefined;
    }

    return undefined;
  };

  return {
    validator: validate,
  };
};
