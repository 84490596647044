import { BillGroupIndicator } from 'src/components/list/BillGroupIndicator';
import { useMatchAccountMessage } from 'src/hooks/get-paid/useMatchAccountMessage';
import { useShowVerifyMessage } from 'src/hooks/get-paid/useShowVerifyMessage';

type Props = {
  onVerify?: (success?: boolean) => Promise<void> | void;
  isSentOrUnSentTab?: boolean;
};

const Messages = ({ onVerify, isSentOrUnSentTab }: Props) => {
  const {
    title: verifyTitle,
    description: verifyDescription,
    action: verifyAction,
    onClick: verifyOnClick,
    onClose: verifyOnClose,
    openMessage: verifyOpenMessage,
    shouldShowVerifyBankAccount,
    dialog,
  } = useShowVerifyMessage({ onVerify });

  const {
    title: matchAccountMessageTitle,
    description: matchAccountMessageDescription,
    action: matchAccountMessageAction,
    onClick: onClickMatchAccountMessage,
    onClose: onCloseMatchAccountMessage,
    openMessage: matchAccountOpenMessage,
    shouldShowMatchBankAccount,
    accountingSoftwareName: accountingSoftware,
  } = useMatchAccountMessage();

  const shouldShowVerifyBankAccountIndicator = isSentOrUnSentTab && shouldShowVerifyBankAccount;

  const shouldShowMatchBankAccountIndicator = isSentOrUnSentTab && shouldShowMatchBankAccount;

  if (shouldShowVerifyBankAccount && verifyOpenMessage) {
    return (
      <>
        {dialog}
        <BillGroupIndicator
          label={verifyTitle}
          description={verifyDescription}
          buttonLabel={verifyAction}
          showIndicator={shouldShowVerifyBankAccountIndicator}
          onButtonClick={verifyOnClick}
          onCloseClick={verifyOnClose}
        />
      </>
    );
  }

  if (shouldShowMatchBankAccount && matchAccountOpenMessage) {
    return (
      <BillGroupIndicator
        label={matchAccountMessageTitle}
        description={matchAccountMessageDescription}
        buttonLabel={matchAccountMessageAction}
        showIndicator={shouldShowMatchBankAccountIndicator}
        onButtonClick={onClickMatchAccountMessage}
        onCloseClick={onCloseMatchAccountMessage}
        labelValues={{ accountingSoftware }}
        descriptionValues={{ accountingSoftware }}
        buttonLabelValues={{ accountingSoftware }}
      />
    );
  }

  return null;
};

export { Messages };
