import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import Tooltip from 'src/core/ds/tooltip';
import { BatchActionType } from 'src/hooks/accountants/useCheckboxOptions';
import { getBatchSelectionRowData } from '../../utils';

type Props = {
  numOfClients: number;
  numOfMarkedClients: number;
  batchAction: BatchActionType;
  markAll: () => void;
  clearAll: () => void;
};

export const BatchSelectionRow = ({ numOfClients, numOfMarkedClients, batchAction, clearAll, markAll }: Props) => {
  const areClientsSelected = numOfMarkedClients > 0;
  const isIndeterminate = areClientsSelected && numOfMarkedClients < numOfClients;

  const handleChange = () => {
    if (areClientsSelected) {
      clearAll();
    } else {
      markAll();
    }
  };

  return (
    <Flex w="full" justifyContent="flex-start" alignItems="center" data-testid="billing-clients-batch-container" h={14}>
      {areClientsSelected ? (
        <Tooltip
          placement="top"
          label={<MIFormattedText label="billing.assignClients.clearAllTooltip" />}
          textStyle="body4"
          shouldWrapChildren
        >
          <Checkbox
            data-testid={`${isIndeterminate ? 'some-selected' : 'all-selected'}`}
            aria-label="selection-checkbox"
            spacing={0}
            isIndeterminate={isIndeterminate}
            isChecked
            onChange={handleChange}
            marginInline={4}
          />
        </Tooltip>
      ) : (
        <Checkbox
          data-testid="all-clear"
          aria-label="selection-checkbox"
          spacing={0}
          isIndeterminate={isIndeterminate}
          isChecked={false}
          onChange={handleChange}
          marginInline={4}
        />
      )}
      <BatchBoxText
        batchAction={batchAction}
        numOfClients={numOfClients}
        numOfMarkedClients={numOfMarkedClients}
        isIndeterminate={isIndeterminate}
      />
    </Flex>
  );
};

const BatchBoxText = ({
  batchAction,
  numOfClients,
  numOfMarkedClients,
  isIndeterminate,
}: Partial<Props> & { isIndeterminate: boolean }) => {
  const { testId, label, values } = getBatchSelectionRowData({
    isIndeterminate,
    batchAction,
    numOfMarkedClients,
    numOfClients,
  });

  return (
    <Box textStyle="body4" color="grey.700" data-testid={testId}>
      <MIFormattedText label={label} values={values} />
    </Box>
  );
};
