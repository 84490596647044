const shadows = {
  200: '1px 0px 0px rgba(216, 216, 216, 1);',
  300: '0px -5px 15px rgba(33, 33, 36, 0.05)',
  400: '0px 5px 10px rgba(33, 33, 36, 0.1)',
  500: '0px 5px 15px rgba(33, 33, 36, 0.1)',
  600: '0px 5px 15px rgba(33, 33, 36, 0.2)',
} as const;

export type Shadows = typeof shadows;

export default shadows;
