import { MILink } from 'src/components/common/MILink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { billLocations } from 'src/pages/bill/locations';
import { AccountingSoftwareList } from 'src/pages/onboarding/accounting-software/components/AccountingSoftwarePage/AccountingSoftwareList';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { AccountingSoftware } from 'src/utils/consts';

type Props = {
  selectedAccountingSoftware?: AccountingSoftware | null;
  onSelectAccountingSoftware: (arg0: AccountingSoftware) => void;
  originPage: string;
  goExit: () => void;
  onCancelClicked: (cancelRoute: string) => void;
};

export const AccountingSoftwarePage = ({
  selectedAccountingSoftware,
  onSelectAccountingSoftware,
  originPage,
  goExit,
  onCancelClicked,
}: Props) => {
  let cancelRoute: string = billLocations.index;
  let cancelText = 'onboarding.accountingSoftware.addLater';

  if (originPage === 'settings') {
    cancelRoute = settingsLocations.index;
    cancelText = 'onboarding.accountingSoftware.addLater';
  }

  if (originPage === 'welcome') {
    cancelRoute = onboardingLocations.companyInfo.index;
    cancelText = 'onboarding.accountingSoftware.addLater';
  }

  return (
    <StepLayoutPage
      title="onboarding.accountingSoftware.title"
      subtitle="onboarding.accountingSoftware.subtitle"
      goExit={goExit}
      innerSize={100}
    >
      <AccountingSoftwareList
        isDisableUnavailable
        selected={selectedAccountingSoftware}
        onSelect={onSelectAccountingSoftware}
      />

      <WizardElements.WizardLinkContainer>
        <MILink to={() => onCancelClicked(cancelRoute)} label={cancelText} />
      </WizardElements.WizardLinkContainer>
    </StepLayoutPage>
  );
};

AccountingSoftwarePage.defaultProps = {};
