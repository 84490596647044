import { Avatar } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';

type Props = {
  size: string;
  link?: string;
  name?: string;
};

export const UserBadgePic = ({ size, link, name }: Props) => (
  <Box>
    <Avatar name={name} link={link} size={size} textColor="white" />
  </Box>
);
