import isEmpty from 'lodash/fp/isEmpty';
import { ChangeEvent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { Form, FormRow } from 'src/core/ds/form';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { profileStore } from 'src/modules/profile/profile-store';
import { analytics } from 'src/services/analytics';
import { CompanyInfoType } from 'src/utils/types';

type Props = {
  onPrev?: () => void;
  onNext: (companyInfo: CompanyInfoType, dataToUpdate: Record<string, any>) => void;
  companyInfo: CompanyInfoType;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
};

export const CompanyContactDetailsForm = ({ companyInfo, validationErrors, onNext, isLoading, onPrev }: Props) => {
  const [contactFirstName, setContactFirstName] = useState(companyInfo.contactFirstName || '');
  const [contactLastName, setContactLastName] = useState(companyInfo.contactLastName || '');
  const [phone, setPhone] = useState(companyInfo.phone || '');
  const user = useSelector(profileStore.selectors.profile);
  const analyticsData = { msnOptinFlow: true, isGuest: user.isGuest, flows: 'public' };

  const onSubmit = () => {
    onNext(companyInfo, { contactFirstName, contactLastName, phone });
  };
  const title = useRef(companyInfo.contactFirstName ? 'existingInfoTitle' : 'title');

  const handleContactFirstNameChange = (event: ChangeEvent<HTMLInputElement>) =>
    setContactFirstName(event.target.value);
  const handleContactLastNameChange = (event: ChangeEvent<HTMLInputElement>) => setContactLastName(event.target.value);
  const handleContactPhoneChange = (phone: string) => setPhone(phone);

  if (!isEmpty(validationErrors)) {
    analytics.trackAction('msnPortalContactDetails.error', {
      ...analyticsData,
      errorReason: 'missing-details',
      validationErrors,
    });
  }

  return (
    <StepLayoutPage
      title={`onboarding.msnCompanyInfo.contactDetails.${title.current}`}
      subtitle="onboarding.msnCompanyInfo.contactDetails.subtitle"
      relativeStep={1 / 6}
      isLoading={isLoading}
      onNext={onSubmit}
      onPrev={onPrev}
      testId="contact-details-page"
      nextButtonAnalyticsProperties={analyticsData}
    >
      <Form>
        <FormRow flexWrap={{ base: 'wrap', md: 'unset' }}>
          <TextField
            id="contactFirstName"
            testId="contact-details-first-name"
            label="onboarding.msnCompanyInfo.contactDetails.inputTitleFirstName"
            value={contactFirstName}
            onSubmit={onSubmit}
            onChange={handleContactFirstNameChange}
            errorMessage={validationErrors.contactFirstName}
            isRequired
            autoFocus
          />
          <TextField
            id="contactLastName"
            testId="contact-details-last-name"
            label="onboarding.msnCompanyInfo.contactDetails.inputTitleLastName"
            value={contactLastName}
            onSubmit={onSubmit}
            onChange={handleContactLastNameChange}
            errorMessage={validationErrors.contactLastName}
            isRequired
          />
        </FormRow>

        <MaskField
          id="contactPhone"
          testId="contact-details-phone"
          label="onboarding.msnCompanyInfo.contactDetails.inputTitlePhone"
          value={phone}
          type="tel"
          format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
          onSubmit={onSubmit}
          onChange={({ value }) => handleContactPhoneChange(value)}
          errorMessage={validationErrors.phone}
          isRequired
        />
      </Form>
    </StepLayoutPage>
  );
};
