import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getBankAccounts } from 'src/redux/user/selectors';
import { getNameParts } from 'src/utils/delivery-methods';
import { isDebitCard } from 'src/utils/funding-sources';

export const useBankAccountDropDownOptions = () => {
  const intl = useIntl();
  const bankAccounts = useSelector(getBankAccounts).filter((acc) => acc.isVerified);

  const bankAccountOptions = bankAccounts.map((repaymentMethod) => {
    const label = isDebitCard(repaymentMethod)
      ? 'bills.pay.fundingSource.debitWithBankLabel'
      : 'bills.pay.fundingSource.achLabel';

    return {
      value: repaymentMethod.id,
      repaymentMethod,
      label: intl.formatMessage({ id: label }, getNameParts(repaymentMethod)),
    };
  });

  return [...bankAccountOptions];
};
