import { ComponentProps } from 'react';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';

type Props = ComponentProps<typeof Button>;

export const DockedButtonMobile = (props: Props) => (
  <Box
    position="fixed"
    bottom={0}
    px={5}
    py={4}
    width="full"
    bg="white"
    boxSizing="border-box"
    boxShadow="300"
    textAlign="center"
    zIndex={9999}
  >
    <Button {...props} isFullWidth />
  </Box>
);
