import { Loader, LoaderColorType } from 'src/components/common/Loader';
import Box from 'src/core/ds/box';
import { ButtonVariants } from 'src/core/ds/button';
import { MAP_LOADER_COLOR_TO_VARIANT } from 'src/core/ds/button/consts';
import Flex from 'src/core/ds/flex';
import { useModal } from 'src/helpers/react/useModal';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useGetFirmBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetFirmBillingFees';
import { useGetOrganizationBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetOrganizationBillingFees';
import { billingLocations } from 'src/pages/billing/locations';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';
import { useBillingAnalytics } from 'src/pages/settings/components/Billing/useBillingAnalytics';
import { DeleteMethodModal } from '../common/DeleteMethodModal';
import { EmptyMethodCard } from '../common/EmptyMethodCard';
import { SelectedMethodCard } from '../common/SelectedMethodCard';

export const SmbSelectedMethodCard = () => {
  const [historyPush] = useHistoryWithOrgId();
  const { currentOrganizationBillingMethod, loading, refetch } = useGetOrganizationBillingFees();
  const isMethodAssignedByFirm = Boolean(currentOrganizationBillingMethod?.managedByOrganizationId);
  const fundingSourceId = currentOrganizationBillingMethod?.fundingSourceId;
  const [filteredFundingSources] = useGetValidFundingSources();
  const { firmFundingSource } = useGetFirmBillingFees();
  const billingAnalytics = useBillingAnalytics();
  const currentFundingSource = filteredFundingSources.find((fs) => fs.id === fundingSourceId) || firmFundingSource;

  const [DeleteBillingMethod, showDeleteMethodModal] = useModal(DeleteMethodModal, {
    description: 'settings.billing.paymentMethod.removeModal.description',
    billingFeeId: currentOrganizationBillingMethod?.id,
    onDismiss: (canceled: boolean) => {
      billingAnalytics.track('settings-billing', canceled ? 'remove-payment-method-cancel' : 'remove-payment-method', {
        paymentMethodType: currentFundingSource?.fundingType,
        paymentMethodID: currentFundingSource?.id,
      });

      if (!canceled) {
        refetch();
      }
    },
  });

  const createActionHandler = (actionName: 'edit-method' | 'remove-method', action: () => void) => () => {
    billingAnalytics.track('settings-billing', 'payment-method-action', {
      paymentMethodType: currentFundingSource?.fundingType,
      paymentMethodID: currentFundingSource?.id,
      action: actionName,
    });
    action();
  };

  const handleOnChangeMethod = createActionHandler('edit-method', () => historyPush({ path: billingLocations.add }));

  const handleOnDeleteModal = createActionHandler('remove-method', showDeleteMethodModal);

  const actions = [
    {
      label: 'settings.billing.paymentMethod.actions.smb.change',
      action: handleOnChangeMethod,
    },
    {
      label: 'settings.billing.paymentMethod.actions.smb.remove',
      action: handleOnDeleteModal,
      color: 'red.500',
    },
  ];

  if (loading) {
    return (
      <Box h={94}>
        <Loader context="page" color={MAP_LOADER_COLOR_TO_VARIANT[ButtonVariants.tertiary] as LoaderColorType} />
      </Box>
    );
  }

  return fundingSourceId ? (
    <Flex direction="column" border="1px" borderColor="grey.400" borderRadius="lg" p={6}>
      {DeleteBillingMethod}
      <SelectedMethodCard
        menuActions={actions}
        fundingSource={currentFundingSource}
        isSelectedByFirm={isMethodAssignedByFirm}
      />
    </Flex>
  ) : (
    <EmptyMethodCard />
  );
};
