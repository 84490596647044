import { Component } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { withNavigator } from 'src/hoc';
import { authLocations } from 'src/pages/auth/locations';
import { billLocations } from 'src/pages/bill/locations';
import { GlobalState } from 'src/redux/types';
import { getIsLoggedIn, getOrgId, getProfile } from 'src/redux/user/selectors';
import { getBillsDefaultFilters } from 'src/utils/bills';
import { BillStatus } from 'src/utils/consts';
import locations from 'src/utils/locations';
import { NavigateType, UserContextType } from 'src/utils/types';

type MapStateToProps = {
  profile: UserContextType;
  isLoggedIn: boolean;
  orgId: number;
};

type Props = {
  query: {
    email: string;
    billId: string;
  };
  navigate: NavigateType;
} & MapStateToProps;

class PlainGuestPaymentRedirectPageContainer extends Component<Props> {
  componentDidMount() {
    const { query, isLoggedIn, profile, orgId, navigate } = this.props;

    if (isLoggedIn && !profile.isGuest) {
      if (profile.email !== query.email) {
        navigate(locations.MainApp.dashboard.url());
      } else {
        const defaultFilters = getBillsDefaultFilters(BillStatus.SCHEDULED);
        navigate(
          generatePath(billLocations.filteredView, {
            orgId,
            id: query.billId,
            ...defaultFilters,
          })
        );
      }
    } else {
      navigate(authLocations.register.index, false, {
        email: query.email,
      });
    }
  }

  render() {
    return <AreaLoader />;
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
  isLoggedIn: getIsLoggedIn(state),
  orgId: getOrgId(state),
});

export const GuestPaymentRedirectPageContainer = compose(
  withNavigator(),
  connect(mapStateToProps)
)(PlainGuestPaymentRedirectPageContainer);
