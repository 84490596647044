import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';

export const useDeliveryDateChange = () => {
  const payment = useSelector(getPayment);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const { deliveryMethodId, amount, id: paymentId, payBillFlowUUID } = payment;
  const [isScheduledDateLoading, setIsScheduledDateLoading] = useState<boolean>(false);

  const onDeliveryDateChange = (selectedScheduledDate: Date, callback: (suggestedDate: Date) => void) => {
    setIsScheduledDateLoading(true);
    deliveryApi
      .getDeliveryTime(
        orgId,
        selectedScheduledDate,
        deliveryMethodId,
        payment.fundingSourceId,
        amount,
        paymentId,
        payBillFlowUUID,
        true
      )
      .then((dates) => {
        dispatch(selectPaymentDatesAction(dates.suggestedScheduledDate, dates.deliveryDate, dates.maxDeliveryDate));
        callback(dates.suggestedScheduledDate);
      })
      .finally(() => {
        setIsScheduledDateLoading(false);
      });
  };

  return {
    isScheduledDateLoading,
    setIsScheduledDateLoading,
    onDeliveryDateChange,
  };
};
