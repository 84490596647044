import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import withListContainer from 'src/hoc/withListContainer';
import { billsApi } from 'src/modules/bills/api';
import { GlobalState } from 'src/redux/types';
import { getOrgId } from 'src/redux/user/selectors';
import { createPdfFile } from 'src/utils/pdf';
import { BillType, Filters } from 'src/utils/types';
import { BillingSettingsPage } from './components/Billing/BillingSettingsPage';

type MapStateToProps = {
  orgId: number;
};

type Props = {
  filters: Filters;
} & MapStateToProps;

type State = {
  isLoading: boolean;
  internalBills: readonly BillType[];
  internalBillsTotal: number;
};

class BillingSettingsPageCrl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      internalBills: [],
      internalBillsTotal: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadInternalBills();
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    if (!isEqual(filters, prevProps.filters)) {
      this.loadInternalBills();
    }
  }

  onGeneratePdfInvoice = (billId: string, invoiceDate: any) => {
    billsApi.generatePdfInvoice(this.props.orgId, billId).then(({ base64 }) => {
      const fileName = `Melio-Receipt-${moment(invoiceDate).format('YYYY-MM-DD')}`;
      createPdfFile(base64, fileName);
    });
  };

  loadInternalBills = () => {
    const { orgId, filters } = this.props;

    this.setState({ isLoading: true });
    billsApi
      .getInternalBills({ orgId, filters })
      .then(({ objects: internalBills, totalCount }) => {
        this.setState({
          isLoading: false,
          internalBills,
          internalBillsTotal: totalCount,
        });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { isLoading, internalBills, internalBillsTotal } = this.state;
    const { filters } = this.props;

    return isLoading ? (
      <AreaLoader />
    ) : (
      <BillingSettingsPage
        internalBills={internalBills}
        internalBillsTotal={internalBillsTotal}
        onGeneratePdfInvoice={this.onGeneratePdfInvoice}
        filters={filters}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

export const BillingSettingsPageContainer = compose(
  withListContainer(),
  connect(mapStateToProps)
)(BillingSettingsPageCrl);
