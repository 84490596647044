import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withNavigator } from 'src/hoc/index';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { GlobalState } from 'src/redux/types';
import { getFundingSources } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { isBillAmountRequiresGoodsConfirmation } from 'src/utils/bills';
import { FundingType } from 'src/utils/consts';
import { AccountType, NavigateType } from 'src/utils/types';
import GuestMemoPage from './components/GuestMemoPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type MapStateToProps = {
  fundingSources: readonly AccountType[];
};

type Props = GuestDataProps & {
  locationState: Record<string, any>;
  navigate: NavigateType;
} & MapStateToProps;

const eventPage = 'payor';
const eventName = 'memo';

class PlainGuestMemoPageContainer extends PureComponent<Props> {
  onNext = () => {
    const { navigateToGuestPage, nextRoute, totalAmount, link, selectedFundingSourceId, fundingSources } = this.props;
    const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId) || fundingSources[0];

    if (!isBillAmountRequiresGoodsConfirmation(totalAmount) || selectedFundingSource.fundingType !== FundingType.CARD) {
      navigateToGuestPage(generatePath(melioMeLocations.wizard.confirm, { link }));
    } else {
      navigateToGuestPage(nextRoute);
    }
  };

  onPrev = () => {
    const { prevRoute, navigateToGuestPage } = this.props;

    analytics.track(eventPage, `${eventName}-back`);
    navigateToGuestPage(prevRoute);
  };

  onMemoChange = (memo: string) => {
    const { onChange } = this.props;

    onChange({ id: 'memo', value: memo });
  };

  render() {
    const { memo } = this.props;

    return <GuestMemoPage memo={memo} onNext={this.onNext} onPrev={this.onPrev} onChange={this.onMemoChange} />;
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  fundingSources: getFundingSources(state),
});

export const GuestMemoPageContainer = compose(
  withNavigator(),
  withGuestData(),
  connect(mapStateToProps)
)(PlainGuestMemoPageContainer);
