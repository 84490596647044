import { useSelector } from 'react-redux';
import { generatePath, Redirect, Switch } from 'react-router-dom';
import { AppLayout } from 'src/components/layout/AppLayout';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { profileStore } from 'src/modules/profile/profile-store';
import { InvoicesListPage } from 'src/pages/get-paid/list/InvoicesListPage';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { PayListPageContainer } from 'src/pages/pay/PayListPageContainer';
import { useHasAccessToCurrentOrg } from 'src/pages/team/team-management/hooks/useHasAccessToCurrentOrg';
import { CompaniesDashboard } from '../companies/components/CompaniesDashboard';
import ContactsListPageContainer from '../contacts/list/ContactsListPageContainer';
import { TeamRouter } from '../team/TeamRouter';
import { dashboardLocations } from './locations';

export function DashboardRouter() {
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const isMsnDashboardDisplay = useIsMsnDashboardDisplay();
  const { hasAccessToCurrentOrg } = useHasAccessToCurrentOrg();
  const disallowOnNoAccess = () => hasAccessToCurrentOrg;

  const redirectToCompaniesOnNoAccess = isMemberOfAccountingFirm && !hasAccessToCurrentOrg;

  return (
    <AppLayout>
      <Switch>
        <SmartRoute path={dashboardLocations.bills}>
          {redirectToCompaniesOnNoAccess ? (
            <Redirect to={generatePath(dashboardLocations.companies, { orgId })} />
          ) : (
            <SmartRoute path={dashboardLocations.bills} allowCallbackFn={disallowOnNoAccess}>
              <PayListPageContainer />
            </SmartRoute>
          )}
        </SmartRoute>

        <SmartRoute path={dashboardLocations.vendors} allowCallbackFn={disallowOnNoAccess}>
          <ContactsListPageContainer />
        </SmartRoute>
        <SmartRoute path={dashboardLocations.companies}>
          <CompaniesDashboard />
        </SmartRoute>
        <SmartRoute path={dashboardLocations.team} allowCallbackFn={disallowOnNoAccess}>
          <TeamRouter />
        </SmartRoute>
        <SmartRoute path={dashboardLocations.getPaid} allowCallbackFn={disallowOnNoAccess}>
          {isMsnDashboardDisplay ? (
            <Redirect to={{ pathname: generatePath(msnPortalLocations.base, { orgId }) }} />
          ) : (
            <InvoicesListPage />
          )}
        </SmartRoute>
      </Switch>
    </AppLayout>
  );
}
