const baseStyle = {
  width: 'full',
  height: 'full',
  overflowY: 'auto',
  paddingBlockStart: 6,
  boxSizing: 'border-box',
};

export default {
  baseStyle,
};
