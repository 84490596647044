import shadows from 'src/core/theme/foundations/shadows';
import textStyles from 'src/core/theme/foundations/textStyles';

export const fundingSourcesContainer = {
  justifyContent: 'space-around',
  flexDirection: 'row',
  display: 'flex',
  bg: 'grey.200',
  borderRadius: 'lg',
  mt: 6,
};

export const paymentMethods = {
  textStyle: 'body4Semi',
  textAlign: 'left',
  color: 'grey.600',
};

export const whyPayLink = {
  display: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  textStyle: 'body3',
  color: 'black',
  mt: 5,
  mb: 0.5,
  textDecor: 'underline',
  cursor: 'pointer',
  gap: 7,
};

export const newLandingPage = (props: { [key: string]: string | number }) => ({
  '[data-testid="title-vendor-name"]': {
    ...textStyles.body1Semi,
    marginBottom: 0,
  },
  '#amount-input-container': {
    div: {
      fontSize: '2rem',
      lineHeight: '3rem',
      fontWeight: 600,
      marginTop: '.5rem',
    },
    span: {
      ...textStyles.h1Semi,
      height: 'auto',
      marginTop: '.5rem',
    },
  },
  '#payment-request-text': {
    ...textStyles.body3,
    marginBottom: '2.8rem',
    display: 'block',
  },
  '#default-user-pic-container, #vendor-logo': {
    width: '12rem',
    height: '12rem',
    margin: '.8rem auto 2.6rem',
    fontSize: '3rem',
    borderWidth: 1,
  },
  '#outside-footer-support-container, #pay-with-info, #logo-container, [data-testid="vendor-info-hint"], [data-testid="user-name-container"], [data-testid="invoice-attachment-preview"]': {
    display: 'none',
  },
  '[data-testid="invoice-preview"]': {
    display: 'block',
    boxShadow: shadows['500'],
    overflow: 'hidden',
  },
  '#powered-by-melio, [data-testid="why-pay-link"]': {
    display: 'inline-flex',
    marginTop: '1.8rem',
  },
  '[data-testid="button-guests.register.buttonText"]': textStyles.body2Semi,
  '#melio-me-card-container, #melio-me-card-container > div': {
    borderRadius: '1.2rem',
    maxWidth: '48.8rem',
  },
  '#melio-me-card-container > div > div': {
    paddingBottom: '1rem',
  },
  '#payment-request-link-security': {
    marginBottom: '3rem',
    img: {
      height: '2.4rem',
      marginTop: '0rem',
    },
  },
  '#payment-request-details, #payment-request-details div': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 0,
  },
  '#payment-request-details-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 0,
  },
  '#payment-request-details div[role="group"]': {
    marginBottom: '2.5rem',
  },
  '#payment-request-note': {
    div: {
      display: props.noteValue ? 'block' : 'none',
    },
  },
});
