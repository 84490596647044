import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import IconButton from 'src/core/ds/iconButton';
import Tooltip from 'src/core/ds/tooltip';

type Props = {
  rowHovered: boolean;
  icon: React.ReactElement;
  label: string;
  onClick: () => void;
  showOnHover?: boolean;
  analyticsProperties?: Record<string, any>;
  isDisabled?: boolean;
};

export const ActionsCellButton = ({
  rowHovered,
  showOnHover = false,
  analyticsProperties,
  label,
  onClick,
  icon,
  isDisabled,
}: Props) => (
  <Box opacity={+(!showOnHover || rowHovered)}>
    <Tooltip key={label} placement="top" label={<MIFormattedText label={label} />}>
      <IconButton
        size="sm"
        icon={icon}
        aria-label={label || 'action'}
        label={label}
        onClick={onClick}
        mr={4}
        analyticsProperties={analyticsProperties}
        isDisabled={isDisabled}
      />
    </Tooltip>
  </Box>
);
