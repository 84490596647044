import { BatchActionType } from 'src/hooks/accountants/useCheckboxOptions';
import { BillingFeeType, CardTypes, FundingType } from 'src/utils/consts';
import { AccountType, CompanyInfoType, OrganizationBillingFee } from 'src/utils/types';
import { ClientsType } from './types';

type ClientArg = {
  id: number;
  isChecked?: boolean;
} & CompanyInfoType;

export const getBillingMethod = (billingFees: OrganizationBillingFee[] | undefined) =>
  billingFees?.find(({ billingFeeType }) => billingFeeType === BillingFeeType.AchToCheck);

export const getBatchSelectionRowData = (options: {
  isIndeterminate: boolean;
  batchAction: BatchActionType;
  numOfMarkedClients?: number;
  numOfClients?: number;
}) => {
  const { isIndeterminate, numOfMarkedClients, numOfClients, batchAction } = options;

  if (isIndeterminate) {
    return {
      testId: 'selected-clients',
      label: 'billing.assignClients.clientsSelected',
      values: { amount: numOfMarkedClients },
    };
  }

  return batchAction === 'clearAll'
    ? { testId: 'all-clients', label: 'billing.assignClients.allSelected', values: { amount: numOfClients } }
    : { testId: 'select-all-clients', label: 'billing.assignClients.selectAll' };
};

export const getFormattedAssignedClients = (assignedClients: ClientArg[], firmClients: ClientArg[]): ClientsType[] => {
  const selectedClients = assignedClients.map(({ id }) => ({ id, isActive: true }));
  const unSelectedClients = firmClients
    .filter(({ isChecked }) => !isChecked)
    .map(({ id }) => ({ id, isActive: false }));

  return [...selectedClients, ...unSelectedClients];
};

export const getSuccessText = ({
  fundingSource,
  isAccountingFirm,
  isChangeMode,
  areClientsAssigned,
  isMethodAssignedByFirm,
}: {
  fundingSource: AccountType | undefined;
  isAccountingFirm: boolean;
  isChangeMode: boolean | undefined;
  areClientsAssigned: boolean;
  isMethodAssignedByFirm: boolean;
}) => {
  if (!fundingSource?.isVerified) {
    if (isAccountingFirm) {
      if (areClientsAssigned) {
        return isChangeMode
          ? 'billing.success.unverifiedSubTitle.clientsAssignedChangedMethod'
          : 'billing.success.unverifiedSubTitle.clientsAssigned';
      }

      return 'billing.success.unverifiedSubTitle.noClientsAssigned';
    }

    return 'billing.success.unverifiedSubTitle.smb';
  }

  if (isAccountingFirm) {
    if (areClientsAssigned) {
      return isChangeMode
        ? 'billing.success.clientsAssignedChangedMethodSubTitle'
        : 'billing.success.clientsAssignedSubTitle';
    }

    return isChangeMode
      ? 'billing.success.noClientsAssignedChangedMethodSubTitle'
      : 'billing.success.noClientsAssignedSubTitle';
  }

  return isMethodAssignedByFirm ? 'billing.success.firmMethodSelectBySmbSubTitle' : 'billing.success.smbSubTitle';
};

export const getPaymentMethodTypeLabel = (fundingSource: AccountType | undefined) => {
  if (fundingSource?.fundingType === FundingType.ACH) {
    return 'billing.addMethod.bankAccount.label';
  }

  return fundingSource?.cardAccount?.cardType === CardTypes.CREDIT
    ? 'billing.addMethod.creditCard.label'
    : 'billing.addMethod.debitCard.label';
};
