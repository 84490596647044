import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import loadingImage from 'src/images/melio-logo-loader.gif';
import { authLocations } from 'src/pages/auth/locations';
import { RegistrationOrigin } from 'src/utils/consts';
import intuit from 'src/utils/intuit';

type Props = {
  realmId?: string;
};

export default ({ realmId }: Props) => {
  let connectOptions;

  if (realmId) {
    // TODO: Check why `qbo: true` is placed here. This is a link that comes here from Intuit App Store
    connectOptions = { quickbooksChooseRealmId: realmId, qbo: true };
  } else {
    connectOptions = {
      registrationOrigin: RegistrationOrigin.APP,
      intuitReturnUrl: authLocations.register.authorizeIntuitSuccess,
      intent: 'melioSyncSignIn',
    };
  }

  setTimeout(() => {
    intuit.goConnectToIntuit(connectOptions);
  }, 0);

  return <SuccessLayoutPage illustration={loadingImage} title="onboarding.accountingSoftware.checking.title" text="" />;
};
