import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import { InvoiceListInfoType } from 'src/pages/bill/pay/components/PayBillPaymentReview/components/PayBillDetails/components/InvoiceList/types';
import { BillType, PaymentType } from 'src/utils/types';

export const getInvoiceListInfo = (payment: PaymentType, isBillsCollapsed: boolean): InvoiceListInfoType => {
  const [billsWithInvoiceNumber, billsWithoutInvoiceNumber] = partition(
    payment.bills,
    (bill: BillType) => bill.invoiceNumber
  );
  const bills = concat(billsWithInvoiceNumber, billsWithoutInvoiceNumber);
  const billsToShow = isBillsCollapsed ? [bills[0]] : bills;
  const hasInvoiceNumbers = !isEmpty(billsWithInvoiceNumber);

  return {
    billsToShow,
    hasInvoiceNumbers,
  };
};
