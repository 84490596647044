import { all, put } from 'redux-saga/effects';
import { userPreferencesFactory } from 'src/context/records';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { authApi } from 'src/modules/auth/api';
import { setProfileAction, setUserPreferencesAction } from 'src/redux/user/actions';

export const acceptInvitationSlice = createApiCallSlice({
  name: '[AUTH] ACCEPT_INVITATION',
  api: authApi.acceptInvitation,
  initialState: {
    acceptInvitation: {
      loading: false,
      error: null,
      success: false,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.acceptInvitation.loading = true;
      state.acceptInvitation.error = null;
    },
    [ON_SUCCESS]: (state) => {
      state.acceptInvitation.loading = false;
      state.acceptInvitation.error = null;
      state.acceptInvitation.success = true;
    },
    [ON_FAILURE]: (state, action) => {
      state.acceptInvitation.loading = false;
      state.acceptInvitation.error = action.error || null;
    },
  },
  sagas: {
    *[ON_SUCCESS](action) {
      const { user } = action.payload;
      yield all([
        put(setProfileAction(user, user.organizations)),
        put(setUserPreferencesAction(userPreferencesFactory(user.userPreferences))),
      ]);
    },
  },
});
