import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { IconNames } from 'src/core/ds/icon';
import { useBreak } from 'src/hoc';
import { profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { FeatureFlags } from 'src/utils/consts';
import { MenuItemPropsType } from '../../types/type';

export const useGetPaidGroupItems = (): Array<MenuItemPropsType> => {
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const { isDesktop } = useBreak();
  const profile = useSelector(profileStore.selectors.profile);

  const links: Array<MenuItemPropsType> = [
    {
      key: 'menu-item-requests',
      to: generatePath(isDesktop ? globalLocations.getPro.base : globalLocations.getPaid.base, {
        orgId: profile?.orgId,
      }),
      label: 'nav.request',
      icon: IconNames.getPaid,
      eventName: 'request-list',
    },
  ];

  if (shouldSplitContactsTab) {
    links.push({
      key: 'menu-item-customers',
      to: globalLocations.contacts.customers,
      label: 'nav.customers',
      icon: IconNames.customer,
      eventName: 'customers-list',
    });
  }

  return links;
};
