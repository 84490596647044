import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { organizationsApi } from 'src/modules/organizations/api';
import { getOrgId } from 'src/redux/user/selectors';

type QBCompanyInfoType = {
  originObject: {
    CompanyName: string;
  };
};

type Props = {
  shouldLoadQBCompanyInfo?: boolean;
  error?: string;
};

export const useFetchQBCompanyInfo = ({
  error,
  shouldLoadQBCompanyInfo,
}: Props): { QBOAccount: QBCompanyInfoType | null; loading?: boolean } => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: getQBCompanyInfo, result: QBOAccount, loading } = useApi<
    [organizationId: number],
    QBCompanyInfoType
  >({
    api: organizationsApi.getQBCompanyInfo,
    initialLoading: shouldLoadQBCompanyInfo,
  });

  useEffect(() => {
    if (shouldLoadQBCompanyInfo) {
      getQBCompanyInfo(orgId);
    }
  }, [error, getQBCompanyInfo, orgId, shouldLoadQBCompanyInfo]);

  return {
    QBOAccount,
    loading,
  };
};
