import isEmpty from 'lodash/isEmpty';
import { RefObject, SyntheticEvent } from 'react';
import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import MaskedInput from 'react-text-mask';
import { compose } from 'recompose';
import styled, { css } from 'styled-components';
import { MIInputLabel } from 'src/components/common/MIInputLabel';
import { MINotices } from 'src/components/common/MINotices';
import Tooltip, { TooltipPropsType } from 'src/core/ds/tooltip';
import { DefaultThemeType } from 'src/theme/defaultTheme';
import { FULL_STORY_MASK_RULE_CLASS, TextInputSize } from 'src/utils/consts';
import { withBreak } from '../../hoc';

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  TEL = 'tel',
  SEARCH = 'search',
}

export type InputModeType = 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search' | undefined;

export type MITextInputBaseProps = {
  id: string;
  value?: string | number | null;
  label: string;
  labelValues?: Record<string, any>;
  placeholder?: string;
  placeholderValues?: Record<string, any>;
  type?: InputType;
  step?: number;
  // Because of `text-mask` lib issue we need to get DOM element from inputRef.current.inputElement,
  // not just from `inputRef.current` https://github.com/text-mask/text-mask/pull/871#issue-229558396
  inputRef?: RefObject<any>;
  notices?: Array<string>;
  errorMessage?: string | null;
  errorMessageValues?: Record<string, any>;
  errorMessageIcon?: React.ReactNode;
  disabled?: boolean;
  required?: boolean;
  readOnlyValue?: boolean;
  size?: TextInputSize;
  autoFocus?: boolean;
  viewOnly?: boolean;
  disableSearch?: boolean;
  intl: IntlShape;
  mask?: Array<any>;
  autocomplete?: string;
  min?: any;
  max?: any;
  onClick?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onWrapperClick?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void;
  device?: { isMobile: boolean };
  maxlength?: number;
  pattern?: string;
  inputMode?: InputModeType;
  testId?: string | null;
  suffix?: any;
  noticeValues?: Record<string, string | number>;
  withDefaultInputArrows?: boolean;
  // TODO: remove this after form alignment
  marginBottomOverride?: string;
  afterLabelComponent?: React.ReactNode;
  leftSideIcon?: keyof DefaultThemeType['icons'];
  tooltipProps?: TooltipPropsType;
  privateData?: boolean;
  labelSuffix?: string;
};

type Props = MITextInputBaseProps & {
  onChange?: (changeField: { id: string; value: string }) => void;
};

type PropsPassthrough = MITextInputBaseProps & {
  onChange: (changeField: { id: string; value: string } | SyntheticEvent<HTMLInputElement>) => void;
};

class MITextInputBase<T extends MITextInputBaseProps> extends React.PureComponent<T, { focused: boolean }> {
  static defaultProps = {
    disabled: false,
    required: false,
    readOnlyValue: false,
    placeholder: '',
    notices: [],
    type: InputType.TEXT,
    step: undefined,
    size: TextInputSize.WIZARD,
    viewOnly: false,
    disableSearch: false,
    errorMessage: null,
    errorMessageValues: {},
    errorMessageIcon: null,
    autoFocus: false,
    mask: [],
    passthroughOnChange: false,
    min: undefined,
    max: undefined,
    inputRef: undefined,
    maxlength: undefined,
    pattern: undefined,
    inputMode: undefined,
    testId: null,
    suffix: undefined,
    labelValues: undefined,
    noticeValues: {},
    withDefaultInputArrows: false,
  };

  constructor(props) {
    super(props);

    this.state = { focused: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {};
  onFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ focused: true });
    this.props.onFocus?.(e);
  };
  onBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ focused: false });
    this.props.onBlur?.(e);
  };

  render() {
    const {
      id,
      type,
      step,
      placeholder,
      placeholderValues,
      suffix,
      intl,
      errorMessage,
      size,
      viewOnly,
      disableSearch,
      disabled,
      required,
      label,
      inputRef,
      value,
      notices,
      onClick,
      onWrapperClick,
      mask,
      readOnlyValue,
      autocomplete,
      min,
      max,
      device,
      maxlength,
      errorMessageValues,
      errorMessageIcon,
      pattern,
      inputMode,
      labelSuffix,
      labelValues,
      noticeValues,
      withDefaultInputArrows,
      marginBottomOverride,
      afterLabelComponent,
      leftSideIcon,
      tooltipProps,
      privateData,
    } = this.props as MITextInputBaseProps;
    const placeholderText = !placeholder ? '' : intl.formatMessage({ id: placeholder }, placeholderValues);
    const autoFocus = device?.isMobile ? false : this.props.autoFocus;
    const testId = this.props.testId || `input-${id}`;
    const marginBottom = size === TextInputSize.WIZARD ? '4rem' : '0';
    const inputWrapperClassName = `input-wrapper${privateData ? ` ${FULL_STORY_MASK_RULE_CLASS}` : ''}`;

    return (
      <Container
        className="input-container"
        marginBottom={marginBottomOverride || marginBottom}
        withDefaultInputArrows={withDefaultInputArrows}
      >
        <MIInputLabel
          inputId={id}
          label={label}
          labelValues={labelValues}
          errorMessage={errorMessage}
          size={size}
          labelSuffix={labelSuffix}
          required={required}
          afterLabelComponent={afterLabelComponent}
        />

        <Tooltip isDisabled={!tooltipProps} {...tooltipProps} data-testid={tooltipProps?.testId || ''}>
          <InputWrapper
            focused={this.state.focused}
            error={errorMessage}
            viewOnly={viewOnly}
            className={inputWrapperClassName}
            onClick={onWrapperClick}
            inline={size}
          >
            {leftSideIcon && <LeftSideIcon className={`icon-${leftSideIcon}`} />}
            {isEmpty(mask) ? (
              <>
                <TextInput
                  id={id}
                  name={id}
                  disabled={disabled}
                  label={label}
                  value={value == null ? '' : value}
                  placeholder={placeholderText}
                  error={errorMessage}
                  type={type}
                  step={step}
                  inline={size}
                  readOnly={readOnlyValue}
                  viewOnly={viewOnly}
                  disableSearch={disableSearch}
                  autoFocus={autoFocus}
                  onChange={this.handleChange}
                  onClick={onClick}
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  autoComplete={autocomplete}
                  min={min}
                  max={max}
                  ref={inputRef}
                  maxLength={maxlength}
                  pattern={pattern}
                  inputMode={inputMode}
                  data-testid={testId}
                />
              </>
            ) : (
              <MaskedInput
                mask={mask}
                guide={false}
                ref={inputRef}
                onChange={this.handleChange}
                onBlur={this.onBlur}
                render={(ref, props) => (
                  <TextInput
                    ref={ref}
                    {...props}
                    id={id}
                    name={id}
                    disabled={disabled}
                    label={label}
                    value={value == null ? '' : value}
                    placeholder={placeholderText}
                    error={errorMessage}
                    type={type}
                    step={step}
                    inline={size}
                    viewOnly={viewOnly}
                    readOnly={readOnlyValue}
                    autoFocus={autoFocus}
                    onClick={onClick}
                    autoComplete={autocomplete}
                    min={min}
                    max={max}
                    maxLength={maxlength}
                    pattern={pattern}
                    inputMode={inputMode}
                    data-testid={testId}
                  />
                )}
              />
            )}
            <Suffix inline={size}>{suffix}</Suffix>
          </InputWrapper>
        </Tooltip>
        <MINotices
          size={size}
          notices={notices}
          errorMessage={errorMessage}
          errorMessageValues={errorMessageValues}
          errorMessageIcon={errorMessageIcon}
          testId={`${testId}-notices`}
          noticeValues={noticeValues}
        />
      </Container>
    );
  }
}

class PlainMITextInput extends MITextInputBase<Props> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const { disabled, onChange, id, type } = this.props;

    if (!disabled && onChange) {
      onChange(
        Object.assign(
          {},
          {
            id,
            value: e.currentTarget.value,
            valueAsNumber: e.currentTarget.valueAsNumber,
          },
          type ? { type } : {}
        )
      );
    }
  };
}

export const MITextInput = compose(withBreak())(injectIntl(PlainMITextInput));

class MITextInputPassthroughBase extends MITextInputBase<PropsPassthrough> {
  handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    if (!this.props.disabled) {
      this.props.onChange(e);
    }
  };
}

export const MITextInputPassthrough = compose(withBreak())(injectIntl(MITextInputPassthroughBase));

const Container = styled.div<{ marginBottom?: string }>`
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom};

  ${(props) =>
    !props.withDefaultInputArrows &&
    `
    input[type=number] {
      -moz-appearance:textfield;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  `}
  ${(props) => props.theme?.components?.MITextInput?.TextInputContainer}
`;

const placeholderStyle = (props) => `
  color: ${props.theme.text.color.readonly};
  font-size: ${props.inline === TextInputSize.INLINE ? '1.6rem' : '2.3rem'};
  letter-spacing: -0.028rem;
  ${
    props.theme?.components?.MITextInput?.placeholderStyle && props.theme.components.MITextInput.placeholderStyle(props)
  }
`;

const fontSize = (props) => {
  if (props.type === 'password') {
    if (props.inline === TextInputSize.INLINE) {
      return '2.2rem';
    }

    return '2.6rem';
  } else if (props.inline === TextInputSize.INLINE) {
    return '1.6rem';
  }

  return '2.3rem';
};

const LeftSideIcon = styled.i`
  color: ${(props) => props.theme.text.color.light};
  font-size: ${(props) => fontSize(props)};
  position: relative;
  top: 1px;
  margin-right: 10px;
  align-self: center;
  padding-bottom: 10px;
`;

const TextInput = styled.input.attrs<{
  inline?: TextInputSize;
  error?: string | null;
  viewOnly?: boolean;
  disableSearch?: boolean;
  disabled?: boolean;
  label?: string;
  maxLength?: number;
  privateData?: boolean;
}>((props) => ({
  maxlength: props?.maxLength,
  className: props?.privateData ? FULL_STORY_MASK_RULE_CLASS : undefined,
}))`
  width: 100%;
  height: ${(props) => (props.inline === TextInputSize.INLINE ? '3rem' : '3.8rem')};
  background-color: transparent;
  padding: 0 0 ${(props) => (props.inline === TextInputSize.INLINE ? '0' : '0.5rem')} 0;
  border: none;
  outline: none;
  line-height: ${(props) => (props.inline === TextInputSize.INLINE ? '3rem' : '3.8rem')};
  color: ${(props) => props.theme.text.color.main};
  border-radius: 0;

  ${(props) =>
    props.viewOnly &&
    css`
      pointer-events: none;
      color: ${(props) => props.theme.text.color.subtitle};
    `};

  font-size: ${(props) => fontSize(props)};

  &:-webkit-autofill::first-line {
    font-size: ${(props) => fontSize(props)};
    line-height: ${(props) => (props.inline === TextInputSize.INLINE ? '3rem' : '3.8rem')};
    font-family: 'Poppins';
  }

  &::-webkit-input-placeholder {
    ${(props) => placeholderStyle(props)}
  }
  &::-moz-placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &:-ms-input-placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &::placeholder {
    ${(props) => placeholderStyle(props)}
  }

  &:disabled {
    -webkit-text-fill-color: ${(props) => !props.disableSearch && props.theme.text.color.subtitle};
    color: ${(props) => !props.disableSearch && props.theme.text.color.subtitle};
    opacity: 1;
  }
  ${(props) => props.theme?.components?.MITextInput?.TextInput};
`;
const calcBorderColor = (props) => {
  if (props.error) return props.theme.text.color.error;
  else if (props.focused) return props.theme.colors.dark.opaque;

  return props.theme.text.color.light;
};
const InputWrapper = styled.div<{ error?: string | null; viewOnly?: boolean; focused?: boolean }>`
  position: relative;
  white-space: nowrap;
  display: flex;

  ${(props) =>
    !props.viewOnly &&
    css`
      border-bottom: ${props.inline === TextInputSize.INLINE ? '0.1rem solid' : '0.2rem solid'};
      border-color: ${calcBorderColor};
      border-radius: 0;
    `}

  ${(props) => props.theme?.components?.MITextInput?.InputWrapper}
`;

const Suffix = styled.div<{ inline?: TextInputSize }>`
  position: absolute;
  bottom: ${(props) => (props.inline === TextInputSize.INLINE ? '0.5rem' : '1.3rem')};
  max-height: ${(props) => (props.inline === TextInputSize.INLINE ? '1.7rem' : '2.2rem')};
  max-width: ${(props) => (props.inline === TextInputSize.INLINE ? '1.7rem' : '8.2rem')};
  overflow: hidden;
  right: 0;
  cursor: pointer;
  font-size: ${(props) => (props.inline === TextInputSize.INLINE ? '1.7rem' : '2.2rem')};

  img {
    height: ${(props) => (props.inline === TextInputSize.INLINE ? '1.7rem' : '2.2rem')};
    width: ${(props) => (props.inline === TextInputSize.INLINE ? '1.7rem' : '2.2rem')};
  }
  ${(props) => props.theme?.components?.MITextInput?.EyeIcon}
`;

export { Container, InputWrapper, TextInput };
