import { InputGroup, InputRightElement } from 'src/core/ds/input';
import { CurrencySuffix, CurrencySuffixProps } from './CurrencySuffix';
import { MaskInput, MaskInputProps } from './MaskInput';

export type AmountCurrencyInputProps = Omit<MaskInputProps, 'onChange'> &
  CurrencySuffixProps & {
    onChange: (value: string) => void;
  };

export const AmountCurrencyInput = ({
  isDisabled,
  placeholder = 'bills.form.totalAmountPlaceholder',
  thousandSeparator = true,
  prefix = '$',
  onChange,
  ...rest
}: AmountCurrencyInputProps) => {
  const handleAmountChange = ({ value }: { value: string }) => {
    onChange(value);
  };

  return (
    <InputGroup>
      <MaskInput
        isDisabled={isDisabled}
        placeholder={placeholder}
        prefix={prefix}
        thousandSeparator={thousandSeparator}
        onChange={handleAmountChange}
        {...rest}
      />
      <InputRightElement cursor={isDisabled ? 'default' : 'pointer'} w="max-content" h="auto" zIndex={0}>
        <CurrencySuffix isDisabled={isDisabled} {...rest} />
      </InputRightElement>
    </InputGroup>
  );
};
