import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { userManagementApi } from './api';

export const editUserOrganizationsSlice = createApiCallSlice<any, any>({
  name: '[USERS] UPDATE_USER_ORGANIZATIONS',
  api: userManagementApi.editUserOrganizations,
  initialState: {
    editUserOrganizations: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.editUserOrganizations.loading = true;
      state.editUserOrganizations.error = undefined;
    },
    [ON_SUCCESS]: (state, action) => {
      state.editUserOrganizations.loading = false;
      state.editUserOrganizations.error = undefined;

      const { targetUserId } = action.meta.identifier;
      state.byId[targetUserId].userOrganizations = action.payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.editUserOrganizations.loading = false;
      state.editUserOrganizations.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state.users.editUserOrganizations.loading,
  },
});
