import { useCallback, useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Upload10KInvoiceFileLayout from 'src/components/layout/Upload10KInvoiceFileLayout';
import Box from 'src/core/ds/box';
import { useBillFiles } from 'src/pages/bill/hooks/useBillFiles';
import { billFactory } from 'src/pages/bill/records';
import { ModelView } from 'src/ui/form';
import { BillPageType } from 'src/utils/consts';
import { CreateBillType } from 'src/utils/types';

type Props = {
  model: ModelView<CreateBillType>;
};

export const JustPayUploadInvoiceLayout = ({ model }: Props) => {
  const {
    fileName,
    files,
    isUploading,
    isUploadError,
    handleRetry,
    uploadAttachment,
    removeAttachment,
  } = useBillFiles();

  useEffect(() => {
    if (files) {
      model.setModelState((prevState) => ({
        ...prevState,
        files,
      }));
    }
  }, [files]);

  const handleSelectFile = useCallback(
    async (file: File) => {
      await uploadAttachment(file, billFactory(), false);
    },
    [model]
  );

  return (
    <Box mb={0} data-testid="bills.justPay.uploadLayoutDesc">
      <Box textStyle="body2">
        <MIFormattedText label="bills.justPay.uploadLayoutDesc" />
      </Box>
      <Upload10KInvoiceFileLayout
        onSelectFile={handleSelectFile}
        onRetry={handleRetry}
        fileName={fileName}
        isFileUploading={isUploading}
        isFileUploadError={isUploadError}
        onChangeInvoiceFile={handleSelectFile}
        onDeleteInvoiceFile={removeAttachment}
        pageType={BillPageType.CREATE}
      />
    </Box>
  );
};
