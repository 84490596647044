import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { Link } from 'src/core/ds/link';
import { NotificationCardTypes } from 'src/utils/consts';

const UPDATE_BANK_ACCOUNT_LINK =
  'https://melio.zendesk.com/hc/en-us/articles/6641325720860-Changing-the-receiving-method-and-verifying-the-new-one-with-micro-deposits';

export const FooterHelp = () => (
  <MINotificationCard
    data-testid="help-update-bank-account"
    type={NotificationCardTypes.HELP}
    title={{ label: 'onboarding.msnCompanyInfo.verifyBankAccount.help.title' }}
    subtitle={{
      label: 'onboarding.msnCompanyInfo.verifyBankAccount.help.subtitle',
      values: {
        theseSteps: (
          <Link href={UPDATE_BANK_ACCOUNT_LINK} target="_blank" isExternal>
            <MIFormattedText label="onboarding.msnCompanyInfo.verifyBankAccount.help.theseSteps" />
          </Link>
        ),
      },
    }}
  />
);
