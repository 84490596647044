import { useFeature } from '@melio/shared-web/dist/feature-flags';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useBreak } from 'src/hoc';
import { PayListItemType } from 'src/modules/bills/types';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useQueryParams } from 'src/pages/pay/hooks/useQueryParams';
import { REGULAR_BATCH_PAYMENTS_BILLS_LIMIT } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { getSelectableListItems } from 'src/pages/regular-batch-payments/utils';
import { getFundingSources } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { BillStatus, FeatureFlags } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { BillType, VendorType } from 'src/utils/types';

const isBatchSelectionTab = (query) => query.status === BillStatus.UNPAID;

const PAGE_EVENT = 'pay-inbox';
const SELECTED_IDS_QUERY_PARAM = 'selectedIds';

export function useSelectedIds() {
  const { query, replace } = useQueryParams();
  const { selectedIds = [] }: { selectedIds?: Array<string> } = query;

  const selectList = useCallback(
    (list: Array<string>) => {
      replace({ [SELECTED_IDS_QUERY_PARAM]: list });
    },
    [replace]
  );

  const clear = useCallback(() => {
    selectList([]);
  }, [selectList]);

  const setSelected = useCallback(
    (id: string, value: boolean) => {
      const currentIds = new Set(selectedIds);

      if (value) {
        currentIds.add(id.toString());
      } else {
        currentIds.delete(id.toString());
      }

      selectList(Array.from(currentIds));
    },
    [selectList, selectedIds]
  );

  return {
    selectedIds,
    setSelected,
    selectList,
    clear,
  };
}

function useSelectedPayItemById(id?: string) {
  return useSelector(payListItemsStore.selectors.byId(id));
}

function useSelectableBills(bills) {
  const { selectedIds } = useSelectedIds();
  const sampleSelectedItem = useSelectedPayItemById(selectedIds[0]);
  const vendorIds = bills?.map((bill) => bill.vendorId);

  const userFundingSources = useSelector(getFundingSources);
  const userVendors = useSelector<any, VendorType[]>(vendorsStore.selectors.getByIds(uniq(vendorIds)));
  const [isBatchApproveEnabled] = useFeature<boolean>(FeatureFlags.BatchApprove, false);

  return useMemo(() => {
    const featureFlags = {
      [FeatureFlags.BatchApprove]: isBatchApproveEnabled,
    };

    return getSelectableListItems({ items: bills, userVendors, userFundingSources, featureFlags, sampleSelectedItem });
  }, [bills, userFundingSources, userVendors, isBatchApproveEnabled, sampleSelectedItem]);
}

export const useBatchSelection = <T extends BillType | PayListItemType>(
  // OrderedSet is problematic here because of immutable bug, which is described here:
  // https://github.com/immutable-js/immutable-js/issues/1643
  bills: T[]
): {
  enableBatchSelection: boolean;
  enabledSelectionBills: T[];
  selectedIds: string[];
  setSelected: (id: string, value: boolean) => void;
  selectAll: () => void;
  clearAll: () => void;
  isAllSelected: boolean;
} => {
  const { isDesktop } = useBreak();
  const enabledSelectionBills = useSelectableBills(bills) as T[];
  const currentBillIds = map(enabledSelectionBills, 'id')?.map((id) => id.toString());
  const { selectedIds, setSelected, selectList, clear } = useSelectedIds();
  const query = useQueryString();
  const enableBatchSelection = isDesktop && isBatchSelectionTab(query);

  const isAllSelected =
    selectedIds.length === REGULAR_BATCH_PAYMENTS_BILLS_LIMIT ||
    currentBillIds.every((id) => selectedIds.find((curId) => curId === id));

  const selectOne = (id: string, value: boolean) => {
    !selectedIds.length && analytics.track(PAGE_EVENT, 'start-batch-selection');

    setSelected(id, value);
  };

  const selectAll = () => {
    const selectedFromOtherPage = [...selectedIds].filter((id) => !currentBillIds.includes(id));

    const newSelectedIds = currentBillIds.slice(0, REGULAR_BATCH_PAYMENTS_BILLS_LIMIT - selectedFromOtherPage.length);
    selectList([...newSelectedIds, ...selectedFromOtherPage]);
  };

  const clearAll = () => {
    clear();
  };

  return {
    enabledSelectionBills,
    enableBatchSelection,
    selectedIds: isBatchSelectionTab(query) ? [...selectedIds] : [],
    setSelected: selectOne,
    selectAll,
    clearAll,
    isAllSelected,
  };
};
