import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useLegalInfoPage } from 'src/pages/get-paid/hooks/useLegalInfoPage';
import { isCompanyLegalInfoDone as checkIsCompanyLegalInfoDone } from 'src/pages/settings/records';
import { fireInternationalAnalyticsOnSubmit } from 'src/pages/vendor/international-delivery-method/event-mapping';
import { useInternationalMethodCreation } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalMethodCreation';
import { useInternationalWizard } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalWizard';
import { internationalLocations } from 'src/pages/vendor/international-delivery-method/locations';
import { useUBO } from 'src/pages/vendor/international-delivery-method/ultimate-beneficial-owners/hooks/useUBO';
import { getIsComingFromVendorDetails } from 'src/pages/vendor/international-delivery-method/utils';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { TaxIdType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';

type Props = {
  onExit: () => void;
};

export const InternationalBusinessPage = ({ onExit }: Props) => {
  const match = useRouteMatch<{ id?: string }>();
  const vendorId = Number(match.params?.id);
  const [historyPush] = useHistoryWithOrgId();
  const { wizardState } = useInternationalWizard();
  const [createInternationalDeliveryMethod, isCreating] = useInternationalMethodCreation({ vendorId });
  const companyInfo = useSelector(getCompanyInfo);
  const [redirectUrl] = useLocationState<string>('redirectUrl');
  const [billId] = useLocationState<number>('billId');
  const [exitUrl] = useLocationState<string>('exitUrl');
  const [origin] = useLocationState<string>('origin');
  const isComingFromVendorDetails = useMemo(() => (origin ? getIsComingFromVendorDetails(origin) : false), [origin]);
  const { UBOInfoDoesNotExists } = useUBO();
  const isCompanyLegalInfoDone = checkIsCompanyLegalInfoDone(companyInfo);
  const title = isCompanyLegalInfoDone
    ? 'vendors.deliveryMethods.international.businessDetails.dateOfBirthTitle'
    : 'vendors.deliveryMethods.international.businessDetails.title';
  const subtitle = isCompanyLegalInfoDone
    ? 'vendors.deliveryMethods.international.businessDetails.dateOfBirthSubtitle'
    : 'vendors.deliveryMethods.international.businessDetails.subtitle';

  const onNext = useCallback(
    async (values) => {
      if (UBOInfoDoesNotExists && values?.taxIdType === TaxIdType.EIN) {
        fireInternationalAnalyticsOnSubmit();
        historyPush({
          path: internationalLocations.ubo,
          params: { id: vendorId },
          state: { redirectUrl, exitUrl, billId, origin },
        });
      } else {
        const createError = await createInternationalDeliveryMethod(wizardState);

        if (createError) {
          historyPush({
            path: internationalLocations.vendorDetails,
            params: { id: vendorId },
            state: { redirectUrl, exitUrl, billId, origin },
          });
        }
      }
    },
    [UBOInfoDoesNotExists, createInternationalDeliveryMethod, wizardState, vendorId]
  );

  const { LegalInfoPage } = useLegalInfoPage({
    title,
    subtitle,
    nextLabel:
      isComingFromVendorDetails && !UBOInfoDoesNotExists
        ? 'vendors.deliveryMethods.international.businessDetails.save'
        : 'progress.continue',
    onNext,
    goExit: onExit,
    isLoading: isCreating,
    showDateOfBirth: true,
  });

  return LegalInfoPage;
};
