export const TEMP_CUSTOMER_ID = 0;
export const SHARE_ORIGIN = {
  SHARE: 'share',
  COPY_AND_CLOSE: 'copyAndClose',
};
export const MAX_CUSTOMERS_BATCH_UPLOAD = 100;
export const COLUMNS_KEYS = ['companyName', 'amount', 'invoiceNumber', 'dueDate'];

export enum PaymentRequestSentFrom {
  MELIO_ME = 'melio-me',
  MELIO_PRO = 'melio-pro',
  PARTNER = 'partner',
}
