import { composeSlices } from 'src/helpers/redux/composeSlice';
import { getAlertsSummarySlice } from './get-alerts-summary-slice';
import { getProTabsSummarySlice } from './get-pro-tabs-summary-slice';

export const getProStore = composeSlices(
  {
    getProTabsSummary: getProTabsSummarySlice,
    getAlertsSummary: getAlertsSummarySlice,
  },
  {}
);
