import { Button as ChakraButton, ButtonProps, ComponentWithAs, forwardRef, useStyleConfig } from '@chakra-ui/react';
import { SystemStyleObject } from '@chakra-ui/styled-system';
import { useCallback } from 'react';
import * as React from 'react';
import { Loader, LoaderColorType } from 'src/components/common/Loader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { AnalyticsPropsType } from 'src/core/ds/types';
import { analytics } from 'src/services/analytics';
import { ButtonSizes, ButtonVariants, MAP_LOADER_COLOR_TO_VARIANT } from './consts';

type Props = Omit<ButtonProps, 'colorScheme' | 'variant' | 'size'> &
  AnalyticsPropsType & {
    variant?: ButtonVariants;
    size?: ButtonSizes;
    label?: string;
    values?: Record<string, any>;
    testId?: string | null;
    sx?: SystemStyleObject;
  };

const Button = forwardRef<Props, 'button'>(
  (
    { size, variant, sx, label, values, onClick, testId, children, analyticsProperties, skipAnalyticsEvent, ...rest },
    ref
  ) => {
    const styles = useStyleConfig('Button', { size, variant });
    const dataTestId = testId || `button-${label}`;

    const onClickWithEvent = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!skipAnalyticsEvent && label) {
          analytics.trackAction(label, analyticsProperties);
        }

        return onClick && onClick(event);
      },
      [onClick, label, analyticsProperties, skipAnalyticsEvent]
    );

    return (
      <ChakraButton
        sx={sx ? { ...styles, ...sx } : styles}
        ref={ref}
        iconSpacing={2}
        spinner={
          <Loader
            color={MAP_LOADER_COLOR_TO_VARIANT[variant || ButtonVariants.primary] as LoaderColorType}
            context="page"
          />
        }
        onClick={onClickWithEvent}
        data-testid={dataTestId}
        {...rest}
      >
        {label ? <MIFormattedText label={label} values={values} /> : children}
      </ChakraButton>
    );
  }
);

export default Button as ComponentWithAs<'button', Props>;
