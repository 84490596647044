import { createGlobalStyle } from 'styled-components';
import { DefaultThemeType } from 'src/theme/defaultTheme';
import { MCBThemeType } from 'src/theme/mcbTheme';

export type ThemeType = DefaultThemeType | (DefaultThemeType & MCBThemeType);

const GlobalStyle = createGlobalStyle<{ theme: ThemeType }>`
  html {
    background-color: ${(props) => props.theme.background.default};
    font-family: ${(props) => props.theme.fontFamily};
  }
  body > span {
    font-family: ${(props) => props.theme.fontFamily} !important;
  }
  ${(props) =>
    Object.keys(props.theme.icons)
      .map(
        (className) => `.icon-${className}:before {
      content: "${props.theme.icons[className]}";
     }`
      )
      .join('\n')}
`;

export { GlobalStyle };
