import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { defineRelationshipLocations } from 'src/pages/onboarding/define-relationship/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getMissedCompanyInfoFields } from 'src/utils/company';
import { CompanyInfoOnboardingOrigin, OrganizationCreateOrigin } from 'src/utils/consts';

export function CompanyConnectedFromAccountingSoftware() {
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const companyInfo = useSelector(getCompanyInfo);
  const { createOrigin, companyName } = companyInfo;
  const fromBillPay = createOrigin === OrganizationCreateOrigin.QBO_INTEGRATION;
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const onNext = () => {
    let url;

    if (companyInfo.allowedActions.canDoPayments) {
      url = fromBillPay ? onboardingLocations.companyInfo.added : defineRelationshipLocations.requireApproval;
    } else {
      const missedFields = getMissedCompanyInfoFields(companyInfo);
      analytics.trackAction('redirect-to-onboarding-after-accounting-software', {
        missedFields,
      });
      url = onboardingLocations.companyInfo.contacts;
    }

    history.push(generatePath(url, { orgId }), {
      origin: CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY,
      isCompanyConnectedFromAccountingSoftware: true,
    });
  };

  return (
    <SuccessLayoutPage
      title="inviteNewCompany.accountingSoftwareAlreadyConnected.title"
      titleValues={{ companyName, accountingSoftwareName: connectedAccountingPlatform?.name }}
      text={`inviteNewCompany.accountingSoftwareAlreadyConnected.${
        fromBillPay ? 'subtitleBillPayQuickbooks' : 'subtitle'
      }`}
      textValues={{ companyName, accountingSoftwareName: connectedAccountingPlatform?.name }}
      buttonLabel="inviteNewCompany.accountingSoftwareAlreadyConnected.cta"
      buttonAction={onNext}
    />
  );
}
