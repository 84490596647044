import * as React from 'react';
import styled, { css } from 'styled-components';
import { CopyOrCreatePaymentLink } from 'src/components/common/CopyOrCreatePaymentLink';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { devices } from 'src/theme/appDevices';
import { CopyLinkSize } from 'src/utils/consts';
import { removeProtocol } from 'src/utils/string-utils';

type Props = {
  link: string;
  lastSeen?: string;
  resendButton?: React.ReactNode;
  inEditMode: boolean;
};

const RequestLink = ({ link, lastSeen, resendButton, inEditMode }: Props) => (
  <RequestLinkContainer>
    <ButtonsContainer>
      <CopyLinkContainer canResend={!!resendButton} inEditMode={inEditMode}>
        {inEditMode ? (
          <HandleLink href={link} target="_blank" rel="noopener noreferrer">
            {removeProtocol(link)}
          </HandleLink>
        ) : (
          <CopyOrCreatePaymentLink clipboardText={link} link={link} size={CopyLinkSize.NORMAL} />
        )}
      </CopyLinkContainer>
      {!inEditMode && resendButton}
    </ButtonsContainer>
    <LastSeenContainer>
      <LastSeenIcon isSeen={lastSeen} className="icon-seen-check" />
      <LastSeenText isSeen={lastSeen}>
        <MIFormattedText
          label={lastSeen ? 'getPaid.link.seen' : 'getPaid.link.unseen'}
          values={{ date: lastSeen && new Date(lastSeen) }}
        />
      </LastSeenText>
    </LastSeenContainer>
  </RequestLinkContainer>
);

export default RequestLink;

const RequestLinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LastSeenContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LastSeenIcon = styled.i<{ isSeen?: string }>`
  font-size: 1.5rem;
  color: ${(props) => (props.isSeen ? props.theme.text.color.highlight : props.theme.text.color.label)};
  align-self: flex-start;
  margin: 0.5rem 0.5rem 0.5rem 0;
`;

const LastSeenText = styled.div<{ isSeen?: string }>`
  color: ${(props) => (props.isSeen ? props.theme.text.color.main : props.theme.text.color.label)};
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.083rem;
  ${(props) => props.theme.text.fontType.small};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
  }

  @media ${devices.phablet} {
    margin-top: 1rem;
  }
`;

const CopyLinkContainer = styled.div<{
  canResend?: boolean;
  inEditMode?: boolean;
}>`
  display: block;
  width: 100%;
  ${(p) =>
    p.canResend &&
    !p.inEditMode &&
    css`
      max-width: calc(100% - 14rem);
    `};
  @media ${devices.mobile}, ${devices.phablet} {
    max-width: 100%;
  }
`;

const HandleLink = styled.a`
  display: block;
  line-height: 3.3rem;
  color: ${(props) => props.theme.text.color.highlight};
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
