import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { LIST_CHECK_UPDATE_PERIOD } from 'src/pages/get-pro/consts';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProListParams } from '../types';

type UseLoadGetProType = [string | undefined, boolean];

export const useCheckListRefreshAvailability = (
  listParams: GetProListParams,
  loadGetProList: () => Promise<Record<string, any>>,
  currentDataLastUpdated: string
): UseLoadGetProType => {
  const [isReFetched, setIsReFetched] = useState(false);
  const { status: tab } = listParams.filters;
  const currentStore = MAP_STORE_BY_TAB[tab];
  const actions = useStoreActions(currentStore);

  const refetchInterval = useRef<number | undefined>();

  const checkListRefreshAvailability = useCallback(
    async (): Promise<Record<string, any>> => actions.proIsListRefreshable(listParams),
    [actions, listParams]
  );

  useEffect(() => {
    clearInterval(refetchInterval.current);
    refetchInterval.current = setInterval(async () => {
      setIsReFetched(false);
      await checkListRefreshAvailability();
      setIsReFetched(true);
    }, LIST_CHECK_UPDATE_PERIOD);

    return () => clearInterval(refetchInterval.current);
  }, [loadGetProList, currentDataLastUpdated, checkListRefreshAvailability, refetchInterval]);

  const isListRefreshableSelector = useMemo(() => currentStore.selectors?.proIsListRefreshable, [
    currentStore,
    listParams,
  ]);

  const availableDataLastUpdated = useSelector(isListRefreshableSelector?.lastUpdatedAtDate);

  return [availableDataLastUpdated, isReFetched];
};
