import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { VendorListItem } from 'src/components/list/VendorListItem';
import { isDirectoryVendor } from 'src/pages/vendor-directory/utils';
import { DeliveryType } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { Filters, VendorType } from 'src/utils/types';

type Props = {
  filters: Filters;
  contacts: ReadonlyArray<VendorType<'created'>>;
  selectedIds: number[];
  batchSelectionEnabled: boolean;
  selectedContactsTab: string;
  setSelected?: (id: number, value: boolean) => void;
};

export const ContactsSubList = ({
  filters,
  contacts,
  selectedIds,
  batchSelectionEnabled,
  selectedContactsTab,
  setSelected,
}: Props) => {
  const query = useQueryString();

  const isContactSelected = (id: number | null | undefined, paymentRequestId: string | null | undefined) => {
    const currentContactId = query.id ?? '';
    const currentPaymentRequestId = filters.paymentRequestId ? filters.paymentRequestId : '';
    const isIdMatched = !!(id?.toString() === currentContactId.toString());
    const isPaymentRequestIdMatched = !!(paymentRequestId?.toString() === currentPaymentRequestId.toString());

    return isIdMatched || isPaymentRequestIdMatched;
  };

  return (
    <>
      {contacts.map(
        ({ id, address, companyName, contactPhone, contactEmail, paymentRequestId, deliveryMethods, managedBy }) => {
          const showPendingUnilateralTag = some(deliveryMethods, (dm) => dm.deliveryType === DeliveryType.VIRTUAL);
          const showDirectoryVendorLogo = isDirectoryVendor({
            managedBy,
            deliveryMethods,
          });
          const isItemMarked = selectedIds.includes(id);
          const showCheckbox = selectedIds.length > 0;
          const isSelected = isContactSelected(id, paymentRequestId) && isEmpty(selectedIds);

          return (
            <VendorListItem
              key={id || paymentRequestId}
              paymentRequestId={paymentRequestId}
              id={id}
              search={filters.search}
              type={selectedContactsTab}
              name={companyName}
              phone={contactPhone}
              email={contactEmail}
              address={address}
              isSelected={isSelected}
              showPendingUnilateralTag={showPendingUnilateralTag}
              showDirectoryVendorLogo={showDirectoryVendorLogo}
              batchSelectionEnabled={batchSelectionEnabled}
              showCheckbox={showCheckbox}
              isCheckboxMarked={isItemMarked}
              setCheckboxMarked={setSelected}
            />
          );
        }
      )}
    </>
  );
};
