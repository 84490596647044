import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStaleAndLoad } from 'src/helpers/redux/useStaleAndLoad';
import invoicesStore from 'src/modules/invoices/invoices-store';
import { EmptyBlockState } from 'src/pages/get-paid/empty/EmptyBlockState';
import EmptyState from 'src/pages/get-paid/empty/EmptyState';
import { getOrgId } from 'src/redux/user/selectors';
import { GetPaidEmptyState } from '../consts';
import { getContentState } from './utils';

export type GetPaidEmptyStateProps = {
  noInvoices: boolean;
  showVerifyMdIndicator?: boolean;
  loadingInvoices?: boolean;
  canCreatePaymentRequests?: boolean;
};

const useGetPaidEmptyState = ({
  noInvoices,
  showVerifyMdIndicator = false,
  loadingInvoices,
  canCreatePaymentRequests,
}: GetPaidEmptyStateProps) => {
  const orgId = useSelector(getOrgId);

  const listParams = useMemo(() => ({ orgId, filters: { getBy: 'byOrg', start: 0, limit: 1 } }), [orgId]);
  const [totalInvoices, totalStatus] = useStaleAndLoad(invoicesStore, 'list', listParams);
  const state = getContentState({
    canCreatePaymentRequests,
    noInvoices,
    loadingInvoices,
    loadingStatus: totalStatus?.loading,
  });

  const MAP_CONTENT_BY_STATE = useMemo(
    () => ({
      [GetPaidEmptyState.BLOCKED]: <EmptyBlockState />,
      [GetPaidEmptyState.EMPTY]: (
        <EmptyState totalInvoices={totalInvoices} showVerifyMdIndicator={showVerifyMdIndicator} />
      ),
      [GetPaidEmptyState.CONTENT]: null,
    }),
    [showVerifyMdIndicator, totalInvoices]
  );

  return [MAP_CONTENT_BY_STATE[state]];
};

export default useGetPaidEmptyState;
