import { createApiCallSlice, ON_FAILURE, ON_REQUEST } from 'src/helpers/redux/createApiCallSlice';
import { UPDATE_IS_POLLING } from 'src/modules/accounting-platforms/actions-types';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { name } from 'src/modules/accounting-platforms/consts';

type TriggerSyncState = {
  isPolling: boolean;
};

type TriggerSyncRequest = {
  orgId: number;
};

export const triggerSyncSlice = createApiCallSlice<TriggerSyncRequest, TriggerSyncState, void>({
  initialState: {
    isPolling: false,
  },
  api: accountingPlatformsApi.triggerSync,
  reducers: {
    [ON_REQUEST](state) {
      state.isPolling = true;
    },
    [ON_FAILURE](state) {
      state.isPolling = false;
    },

    [UPDATE_IS_POLLING](state, action) {
      state.isPolling = action.payload.isPolling;
    },
  },
  name: `[${name}] trigger sync`.toUpperCase(),
  selectors: {
    isPolling: (state) => state[name].isPolling,
  },
});
