import isEmpty from 'lodash/isEmpty';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { AddAchFlow } from 'src/pages/vendor/delivery-methods/add-ach-flow/AddAchFlow';
import { FlowCustomizationsType } from '../types';

type Props = {
  orgId: number;
  vendorId: number;
};

export const AddAchFromBatchPaymentsFlowWrapper = ({ orgId, vendorId }: Props) => {
  const { SimpleTextFooter } = WizardElements;

  const { vendor } = useFetchVendor(vendorId);
  const vendorName = vendor?.companyName;

  const baseUrl = generatePath(batchPaymentsLocations.base, {
    orgId,
  });

  const flowCustomizations: FlowCustomizationsType = {
    stepLayout: {
      hideHeader: true,
      title: isEmpty(vendorName)
        ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
        : 'vendors.deliveryMethods.bank.title',
      titleValues: { vendor: vendorName },
      nextLabel: 'vendors.deliveryMethods.bank.save',
      footer: (
        <SimpleTextFooter>
          <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
        </SimpleTextFooter>
      ),
      fullWidthCTA: true,
    },
  };

  return <AddAchFlow vendorId={vendorId} flowCustomizations={flowCustomizations} saveAndContinue={baseUrl} />;
};
