import partition from 'lodash/partition';
import { useSelector } from 'react-redux';
import { PayListItemEntityType, PayListItemType } from 'src/modules/bills/types';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { isPaymentRequest } from 'src/utils/bills';
import { PermissionsType } from 'src/utils/permissions';

export const useMarkableBills = (
  billIds: string[]
): {
  markableBills: PayListItemType[];
  nonMarkableBills: PayListItemType[];
} => {
  const inboxItems = useSelector<any, any>((state) => payListItemsStore.selectors.all(state));
  const selectedItems = inboxItems.filter((item) => billIds.includes(String(item.id)));
  const permissions = useSelector<any, PermissionsType>(profileStore.selectors.getPermissions);
  const currentUser = useSelector(profileStore.selectors.profile);

  const [markableBills, nonMarkableBills] = partition(
    selectedItems,
    (selectedItem) =>
      selectedItem.type === PayListItemEntityType.Bill &&
      !isPaymentRequest(selectedItem.id) &&
      permissions.bills.markAsPaid(currentUser, selectedItem)
  );

  return { markableBills, nonMarkableBills };
};
