import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { EXPEDITED_DELIVERY_TYPES } from 'src/utils/consts';
import { isSameDay, isTomorrow } from 'src/utils/dates';
import { DeliveryOptionType } from 'src/utils/types';
import { BetterExposteEtaDeliveryOptionSubtitle } from './BetterExposteEtaDeliveryOptionSubtitle';
import { PaymentDeliveryOptionSubtitle } from './PaymentDeliveryOptionSubtitle';

type Props = {
  subtitle: string;
  subtitleValues: Record<string, React.ReactNode>;
  deliveryOption: DeliveryOptionType;
  deliveryExplanationLabel: string;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const PaymentDeliveryOptionExplanation = ({
  subtitle,
  subtitleValues,
  deliveryOption,
  deliveryExplanationLabel,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: Props) => {
  if (isEligibleForFastAchAdoption) {
    if (EXPEDITED_DELIVERY_TYPES.includes(deliveryOption.type)) {
      if (isSameDay(deliveryOption.deliveryDate)) {
        return (
          <BetterExposteEtaDeliveryOptionSubtitle
            deliveryOption={deliveryOption}
            label={
              isFastAchBetterExposeEtaEnabled
                ? 'bills.pay.date.deliveryOptions.deliveryExplanationExperiment'
                : 'bills.pay.date.deliveryOptions.fastAchExplanation.today'
            }
          />
        );
      }

      if (isTomorrow(deliveryOption.deliveryDate)) {
        return (
          <BetterExposteEtaDeliveryOptionSubtitle
            deliveryOption={deliveryOption}
            label={
              isFastAchBetterExposeEtaEnabled
                ? 'bills.pay.date.deliveryOptions.deliveryExplanationExperiment'
                : 'bills.pay.date.deliveryOptions.fastAchExplanation.tomorrow'
            }
          />
        );
      }
    }

    return (
      <Box as="span" mr={1} color="grey.700">
        <PaymentDeliveryOptionSubtitle
          subtitle={subtitle}
          subtitleValues={subtitleValues}
          deliveryOption={deliveryOption}
          deliveryExplanationLabel={deliveryExplanationLabel}
          isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
          isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
        />
      </Box>
    );
  }

  if (
    EXPEDITED_DELIVERY_TYPES.includes(deliveryOption.type) &&
    isSameDay(deliveryOption.deliveryDate) &&
    !isFastAchBetterExposeEtaEnabled
  ) {
    return <MIFormattedText label="bills.pay.date.deliveryOptions.sameDayDeliveryExplanation" />;
  }

  return (
    <Box as="span" mr={1} color="grey.700">
      <PaymentDeliveryOptionSubtitle
        subtitle={subtitle}
        subtitleValues={subtitleValues}
        deliveryOption={deliveryOption}
        deliveryExplanationLabel={deliveryExplanationLabel}
        isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
        isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
      />
    </Box>
  );
};
