import some from 'lodash/some';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BlockedForPaymentDialog } from 'src/components/common/BlockedForPaymentDialog';
import Box from 'src/core/ds/box';
import { useModal } from 'src/helpers/react/useModal';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import useGetVendorPaymentPreferences from 'src/modules/vendors/hooks/useGetVendorPaymentPreferences';
import { billLocations } from 'src/pages/bill/locations';
import { PayBillSelectDeliveryMethodPage } from 'src/pages/bill/pay/components/PayBillSelectDeliveryMethodPage';
import { selectDeliveryMethodAction } from 'src/redux/payBillWizard/actions';
import {
  getBill,
  getDeliveryMethodType,
  getPayment,
  getSelectedFundingSource,
} from 'src/redux/payBillWizard/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { getBillPaymentIndex } from 'src/utils/bills';
import { ButtonsDirections, DeliveryType, DialogVariants, PaymentStatus } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { getDeliveryMethodByType } from '../utils/billGetters';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';
import { useIsFinancingPayFlow } from './hooks/useIsFinancingPayFlow';

type Props = PayBillProps;

const headerLabel = 'qbo.header.title';

const PlainPayBillSelectDeliveryMethodPageContainer = ({ onPrev, goExit, onNext, goAddDeliveryMethod }: Props) => {
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const [historyPush] = useHistoryWithOrgId();
  const selectedDeliveryMethodType = useSelector(getDeliveryMethodType);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const isFinancing = useIsFinancingPayFlow();
  const dispatch = useDispatch();

  const { vendorPaymentPreferences, isCheckingVendorEnableCCPayments } = useGetVendorPaymentPreferences(bill.vendorId);
  const blockDialog = () => <BlockedForPaymentDialog onCancelAction={onCloseBlockPaymentDialog} />;
  const [BlockPaymentModal, showBlockPaymentModal] = useModal(blockDialog, {});

  const onDeliveryMethodChange = (type: DeliveryType) => {
    const selectedDeliveryMethod = (bill.vendor?.deliveryMethods || []).find((dm) => dm.deliveryType === type);

    if (selectedDeliveryMethod) {
      analytics.track('pay-bill', 'delivery-method-select', {
        type,
        partialBillId: getBillPaymentIndex(bill),
      });
      dispatch(selectDeliveryMethodAction(selectedDeliveryMethod));
    } else {
      analytics.track('pay-bill', 'delivery-method-add', {
        type,
        partialBillId: getBillPaymentIndex(bill),
        billId: bill.id,
        vendorId: bill.vendorId,
      });
      goAddDeliveryMethod(type);
    }
  };

  const onEditExistingDeliveryMethod = (selectedDeliveryMethodId: number) => {
    if (!selectedDeliveryMethodId) return;

    const selectedDeliveryMethod = (bill.vendor?.deliveryMethods || []).find(
      (dm) => dm.id === selectedDeliveryMethodId
    ) as DeliveryMethodType;
    const { deliveryType } = selectedDeliveryMethod;

    analytics.track('pay-bill', 'edit-delivery-method-details', {
      type: deliveryType,
      partialBillId: getBillPaymentIndex(bill),
      billId: bill.id,
      vendorId: bill.vendorId,
    });

    dispatch(selectDeliveryMethodAction(selectedDeliveryMethod));
    goAddDeliveryMethod(deliveryType, selectedDeliveryMethodId);
  };

  const [ReplaceDetailsModal, showReplaceDetailsModal] = useModal(CommonDialog, {
    title: 'deliveryMethods.replaceDetailsModal.title',
    description: 'deliveryMethods.replaceDetailsModal.description',
    confirmText: 'deliveryMethods.replaceDetailsModal.confirm',
    variant: DialogVariants.WARNING,
    buttonsDirection: ButtonsDirections.HORIZONTAL,
    textValues: {
      companyName: bill.vendor?.companyName,
      styledCompanyName: (
        <Box as="span" color="grey.700">
          {bill.vendor?.companyName}
        </Box>
      ),
    },
    modalName: 'replaceDetailsModal',
  });

  const getActionOptions = (deliveryMethod: DeliveryMethodType) => {
    if (deliveryMethod.deliveryType === DeliveryType.VIRTUAL) {
      return [];
    }

    if (deliveryMethod.deliveryType === DeliveryType.ACH && deliveryMethod.isFilledByVendor) {
      const confirm = async () => onEditExistingDeliveryMethod(deliveryMethod.id!);

      return [
        {
          label: 'deliveryMethods.actions.replace',
          action: () => {
            analytics.track('pay-bill', 'replace-details-modal', {
              type: deliveryMethod.deliveryType,
              partialBillId: getBillPaymentIndex(bill),
              billId: bill.id,
              vendorId: bill.vendorId,
            });
            showReplaceDetailsModal({ confirm });
          },
        },
      ];
    }

    return [
      {
        label: 'deliveryMethods.actions.edit',
        action: () => onEditExistingDeliveryMethod(deliveryMethod.id!),
      },
    ];
  };

  const checkPaymentPreferences = () => {
    if (selectedDeliveryMethodType === DeliveryType.ACH && vendorPaymentPreferences?.blockPayments) {
      showBlockPaymentModal();
    } else {
      onNext();
    }
  };

  const onCloseBlockPaymentDialog = () => {
    historyPush({ path: billLocations.view, params: { id: bill.id } });
  };

  const editingFailedPayment = payment?.status === PaymentStatus.FAILED;
  const showVirtualDeliveryMethodOption =
    !isFinancing &&
    !editingFailedPayment &&
    (selectedFundingSource?.isVerified ||
      (!selectedFundingSource?.isVerified &&
        some(bill.vendor?.deliveryMethods, (dm) => dm.deliveryType === DeliveryType.VIRTUAL)));

  const deliveryMethodOptions = useMemo(() => {
    const ach = getDeliveryMethodByType(bill, DeliveryType.ACH);
    const check = getDeliveryMethodByType(bill, DeliveryType.CHECK);
    const international = getDeliveryMethodByType(bill, DeliveryType.INTERNATIONAL);
    const virtual = getDeliveryMethodByType(bill, DeliveryType.VIRTUAL);
    const options = { domestic: [ach, check], international: [international] };

    if (showVirtualDeliveryMethodOption) options.domestic.push(virtual);

    return options;
  }, [bill]);

  const titleValues = { vendor: bill.vendor?.companyName };

  return (
    <>
      {ReplaceDetailsModal}
      {BlockPaymentModal}
      <PayBillSelectDeliveryMethodPage
        onNext={checkPaymentPreferences}
        onPrev={onPrev}
        goExit={goExit}
        companyName={bill.vendor?.companyName ?? ''}
        amount={bill.totalAmount}
        deliveryMethodOptions={deliveryMethodOptions}
        selectedDeliveryMethod={selectedDeliveryMethodType ?? undefined}
        selectedFundingSource={selectedFundingSource}
        onDeliveryMethodChange={onDeliveryMethodChange}
        bill={bill}
        headerLabel={headerLabel}
        relativeStep={2 / 5}
        title="bills.pay.deliveryMethods.title"
        titleValues={titleValues}
        isLoading={isCheckingVendorEnableCCPayments}
        getActionOptions={getActionOptions}
      />
    </>
  );
};

export const PayBillSelectDeliveryMethodPageContainer = withPayBillData()(
  PlainPayBillSelectDeliveryMethodPageContainer
);
