import { useSelector } from 'react-redux';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import { getFundingSourceEventData } from 'src/analytics/utils/fundingSource';
import { billLocations } from 'src/pages/bill/locations';
import { useFundingSourcesNavigate } from 'src/pages/bill/pay/hooks/useFundingSourcesNavigate';
import { usePayBillActions } from 'src/pages/bill/pay/hooks/usePayBillActions';
import { payBillEventPage } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { usePayBillNavigateCommon } from 'src/pages/bill/pay/hooks/usePayBillNavigateCommon';
import { billPayLocations } from 'src/pages/bill/pay/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { internationalLocations } from 'src/pages/vendor/international-delivery-method/locations';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getBill, getIsRecurring, getPayment } from 'src/redux/payBillWizard/selectors';
import { getFundingSources, getOrgId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import {
  AddFundingSourceWizardOrigin,
  CardTypes,
  DeliveryMethodOrigin,
  DeliveryType,
  FundingType,
  PayEditLocationName,
} from 'src/utils/consts';
import { getAddDeliveryMethodUrlMap, getEditDeliveryMethodUrlMap } from 'src/utils/delivery-methods';
import { NavigateType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
};

export const useGoAddPage = ({ navigate, nextStepURL, prevStepURL }: Params) => {
  const match = useRouteMatch<{ billId?: string; paymentId?: string; orgId: string }>();
  const { billId } = match.params;
  const history = useHistory();
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const payment = useSelector(getPayment);
  const isRecurring = useSelector(getIsRecurring);
  const { updateOriginPlaidItemId } = usePayBillActions();
  const { getUrl } = usePayBillNavigateCommon({ navigate, nextStepURL, prevStepURL });
  const { getUrlAfterFundingSource } = useFundingSourcesNavigate({ navigate, nextStepURL, prevStepURL });
  const isInternationalPayment = payment?.deliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL;
  const fundingSources = useSelector(getFundingSources);
  const profile = useSelector(getProfile);

  const goAddSelectedFundingSource = (selectedFundingSourceType) => {
    const analyticProperty = selectedFundingSourceType === CardTypes.CREDIT ? 'add-credit-card' : 'add-debit-card';
    const isRecurring = !billId;
    const isInternational = isInternationalDeliveryMethod(payment?.deliveryMethod?.deliveryType);

    let addBankAccountUrl: string;

    if (payment.id) {
      addBankAccountUrl = onboardingLocations.fundingSources.bank.select;
    } else if (isRecurring) {
      addBankAccountUrl = billLocations.pay.recurring.addBankAccount.base;
    } else {
      addBankAccountUrl = billLocations.pay.addBankAccount.base;
    }

    let exitUrl;
    let redirectUrl = getUrlAfterFundingSource();

    if (isRecurring) {
      exitUrl = billLocations.pay.recurring.funding;
    } else {
      exitUrl = getUrl(PayEditLocationName.FUNDING);
    }

    if (payment) {
      const plaidAccount = payment.fundingSource?.plaidAccount;

      if (plaidAccount) {
        updateOriginPlaidItemId(plaidAccount.plaidItemId);
      }
    }

    switch (selectedFundingSourceType) {
      case FundingType.ACH:
        analytics.track(payBillEventPage, 'add-bank-account', {
          billPaymentIndex: getBillPaymentIndex(bill),
          ...getFundingSourceEventData(profile, fundingSources, bill),
        });

        if (isInternationalPayment) {
          redirectUrl = generatePath(internationalLocations.paymentPurpose, {
            orgId,
            id: bill.vendorId ?? undefined,
          });
        }

        navigate(addBankAccountUrl, false, {
          preservedState: {
            origin: AddFundingSourceWizardOrigin.PAY_BILL,
            isDisableInternationalVendor: isInternationalPayment,
            billId: bill.id,
          },
          redirectUrl,
          exitUrl,
          billId: bill.id,
        });
        break;
      case CardTypes.CREDIT:
      case CardTypes.DEBIT:
        // go back to funding source in case of international
        if (isInternational) redirectUrl = generatePath(billPayLocations.funding, { orgId, billId: bill.id });

        analytics.track(payBillEventPage, analyticProperty, {
          billPaymentIndex: getBillPaymentIndex(bill),
        });
        navigate(onboardingLocations.fundingSources.card.index, false, {
          preservedState: {
            origin: AddFundingSourceWizardOrigin.PAY_BILL,
          },
          redirectUrl,
          exitUrl,
        });
        break;
      case FundingType.INSTALLMENTS:
        navigate(billLocations.pay.installments.applicationFlow, false, {
          redirectUrl,
          exitUrl,
        });
        break;
      default:
        break;
    }
  };

  const calculateUrl = (type: DeliveryType, selectedDeliveryMethodId?: number, isRecurring?: boolean): string => {
    if (!selectedDeliveryMethodId && type === DeliveryType.ACH) {
      const location = isRecurring ? billLocations.pay.recurring.addDM.ach : billLocations.pay.addDM.ach;

      return generatePath(location, { orgId, billId: bill.vendorId! });
    }

    const deliveryMethodUrlMap = selectedDeliveryMethodId
      ? getEditDeliveryMethodUrlMap(orgId, bill.vendor?.id ?? undefined, selectedDeliveryMethodId, bill.id)
      : getAddDeliveryMethodUrlMap(orgId, bill.vendor?.id ?? undefined);

    return deliveryMethodUrlMap[type || DeliveryType.ACH];
  };

  const goAddDeliveryMethod = (type: DeliveryType, selectedDeliveryMethodId?: number) => {
    let redirectUrl;
    let exitUrl;

    if (isRecurring) {
      if (type === DeliveryType.INTERNATIONAL) {
        redirectUrl = generatePath(internationalLocations.paymentPurpose, {
          orgId,
          id: bill.vendorId ?? undefined,
        });
      } else {
        redirectUrl = generatePath(billLocations.pay.recurring.memo, { orgId });
      }

      exitUrl = generatePath(billLocations.pay.deliveryMethod, { orgId, billId: bill.id });
    } else if (payment.id) {
      redirectUrl = generatePath(billLocations.pay.edit.date, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      });
      exitUrl = generatePath(billLocations.pay.edit.deliveryMethod, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      });
    } else if (type === DeliveryType.INTERNATIONAL) {
      redirectUrl = generatePath(internationalLocations.paymentPurpose, {
        orgId,
        id: bill.vendorId ?? undefined,
      });
      exitUrl = generatePath(billLocations.pay.deliveryMethod, {
        orgId,
        billId: bill.id,
        paymentId: payment.id,
      });
    } else {
      redirectUrl = generatePath(billLocations.pay.date, {
        orgId,
        billId: bill.id,
      });
      exitUrl = generatePath(billLocations.pay.deliveryMethod, {
        orgId,
        billId: bill.id,
      });
    }

    const url = calculateUrl(type, selectedDeliveryMethodId, isRecurring);

    history.push({
      pathname: url,
      state: {
        origin: DeliveryMethodOrigin.PAY_BILL,
        redirectUrl,
        exitUrl,
        fundingSourceId: payment.fundingSourceId,
        billId: bill.id,
      },
    });
  };

  return { goAddSelectedFundingSource, goAddDeliveryMethod };
};
