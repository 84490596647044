import isEmpty from 'lodash/isEmpty';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { MICardForm } from 'src/components/common/MICard';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { InvoiceType } from 'src/modules/invoices/types';
import { devices } from 'src/theme/appDevices';
import { FormRow } from 'src/ui/form/FormElements';
import { MiMoneyFlavor } from 'src/utils/consts';

type DataType = {
  customerEmail?: string;
  customerNote?: boolean;
};

type Props = {
  invoice: InvoiceType;
  onChangeInvoiceData: (id, data: DataType) => void;
  selectedInvoiceData: any;
};

const UnsentInvoiceCard = ({ invoice, onChangeInvoiceData, selectedInvoiceData }: Props) => {
  const { customer, totalAmount } = invoice;
  const { customerEmail, customerNote, validationErrors } = selectedInvoiceData;
  const isValid = isEmpty(selectedInvoiceData.validationErrors);
  const flavor = MiMoneyFlavor.DEFAULT;

  const handleIvoiceInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    onChangeInvoiceData(invoice.id, { [id]: value });
  };

  return (
    <MICardForm>
      <InvoiceDetails>
        <InvoiceInfoWrraper>
          {isValid ? (
            <ValidCardIcon className="icon-full-checkmark-icon" />
          ) : (
            <InvalidCardIcon className="icon-intuit-info-icon" />
          )}
          <CustomerName>{customer?.contactName}</CustomerName>
        </InvoiceInfoWrraper>
        <MIMoney amount={totalAmount} flavor={flavor} privateData />
      </InvoiceDetails>
      <Row>
        <Box ml={{ base: 0 }} mr={{ base: 0, md: 2 }}>
          <PrivateDataContainer>
            <TextField
              id="customerEmail"
              label="getPaid.view.batch.unsentInvoiceForm.email"
              value={customerEmail}
              errorMessage={validationErrors?.customerEmail}
              onChange={handleIvoiceInputChange}
              size="sm"
              isRequired
              mb={0}
            />
          </PrivateDataContainer>
        </Box>
        <Box ml={{ base: 0, md: 2 }}>
          <PrivateDataContainer>
            <TextField
              id="customerNote"
              label="getPaid.view.batch.unsentInvoiceForm.note"
              errorMessage={validationErrors?.customerNote}
              value={customerNote}
              onChange={handleIvoiceInputChange}
              size="sm"
            />
          </PrivateDataContainer>
        </Box>
      </Row>
    </MICardForm>
  );
};

export default UnsentInvoiceCard;

const ValidCardIcon = styled.i`
  color: #00d28f;
  font-size: 2.4rem;
  margin-right: 1rem;
`;

const InvalidCardIcon = styled.i`
  color: #d0021b;
  font-size: 2.4rem;
  margin-right: 1rem;
`;

const InvoiceDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.8rem;
`;

const InvoiceInfoWrraper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomerName = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const Row = styled(FormRow)`
  @media ${devices.mobile}, ${devices.phablet} {
    display: block;
  }
`;
