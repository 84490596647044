import { EventMappingsTreeChild } from 'src/services/analytics/types';

export const PAGE_EVENT = 'amex-vendor-address-modal';
export const EXPERIMENT_PAGE_EVENT = 'amex-vendor-address';

const baseProperties = {
  billId: 'billId',
  vendorId: 'vendorId',
  isBatch: 'isBatch',
};

const experimentProperties = {
  ...baseProperties,
  vendorHasMcc: 'vendorHasMcc',
  vendorHasAddress: 'vendorHasAddress',
  experimentVariant: 'experimentVariant',
};

const PAY_BILL_MAPPING: EventMappingsTreeChild = {
  'amex-vendor-address-open': [PAGE_EVENT, 'open', baseProperties],
  'amex-vendor-address-save': [PAGE_EVENT, 'click-save', baseProperties],
  'amex-vendor-address-close': [PAGE_EVENT, 'close', baseProperties],
  'amex-vendor-address-error': [PAGE_EVENT, 'error', baseProperties],
  'amex-vendor-address-experiment': [EXPERIMENT_PAGE_EVENT, 'experiment', experimentProperties],
  'amex-vendor-address-no-suggestions': [PAGE_EVENT, 'no-suggestions', baseProperties],
};

const BATCH_PAYMENTS_MAPPING: EventMappingsTreeChild = {
  'amex-vendor-address-open': [PAGE_EVENT, 'open', baseProperties],
  'amex-vendor-address-save': [PAGE_EVENT, 'click-save', baseProperties],
  'amex-vendor-address-close': [PAGE_EVENT, 'close', baseProperties],
  'amex-vendor-address-error': [PAGE_EVENT, 'error', baseProperties],
  'amex-vendor-address-experiment': [EXPERIMENT_PAGE_EVENT, 'experiment', experimentProperties],
  'amex-vendor-address-no-suggestions': [PAGE_EVENT, 'no-suggestions', baseProperties],
};

export { BATCH_PAYMENTS_MAPPING, PAY_BILL_MAPPING };
