import { useCallback, useEffect, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { candidatesApi } from 'src/services/api/candidates';
import { TokenStateEnum } from './consts';

export const useCandidateByToken = (
  token: string
): { tokenState: TokenStateEnum; candidateId: string | undefined; capLimitAmount: number | undefined } => {
  const [candidateId, setCandidateId] = useState<string | undefined>(undefined);
  const [capLimitAmount, setCapLimitAmount] = useState<number | undefined>(undefined);
  const [tokenState, setTokenState] = useState<TokenStateEnum>(TokenStateEnum.LOADING);
  const { onApiCall: getCandidateByToken } = useApi<[string], Record<string, any>>({
    api: candidatesApi.getCandidateByToken,
  });

  const fetchCandidate = useCallback(async () => {
    try {
      const { isVendorFilledForm, candidateId, capLimitAmount } = await getCandidateByToken(token);
      setCandidateId(candidateId);
      setCapLimitAmount(capLimitAmount);

      if (isVendorFilledForm) {
        setTokenState(TokenStateEnum.VENDOR_FILLED_FORM);
      } else {
        setTokenState(TokenStateEnum.VALID);
      }
    } catch (e: any) {
      setTokenState(TokenStateEnum.INVALID);
    }
  }, [getCandidateByToken, token]);

  useEffect(() => {
    fetchCandidate();
  }, [fetchCandidate]);

  return {
    tokenState,
    candidateId,
    capLimitAmount,
  };
};
