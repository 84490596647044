import { featureFlags } from '@melio/shared-web';
import concat from 'lodash/concat';
import curry from 'lodash/curry';
import findLastIndex from 'lodash/findLastIndex';
import pipe from 'lodash/fp/pipe';
import set from 'lodash/fp/set';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Switch, useRouteMatch } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { profileStore } from 'src/modules/profile/profile-store';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import GuestCombinedSuccessSignupPageContainer from 'src/pages/meliome/guest/GuestCombinedSuccessSignupPageContainer';
import GuestConfirmPageContainer from 'src/pages/meliome/guest/GuestConfirmPageContainer';
import GuestEditFundingSourcesPageContainer from 'src/pages/meliome/guest/GuestEditFundingSourcesPageContainer';
import GuestEmailVerifyPageContainer from 'src/pages/meliome/guest/GuestEmailVerifyPageContainer';
import { GuestExpeditePaymentPageContainer } from 'src/pages/meliome/guest/GuestExpeditePaymentPageContainer';
import { GuestInfoDetailsPageContainer } from 'src/pages/meliome/guest/GuestInfoDetailsPageContainer';
import { GuestMemoPageContainer } from 'src/pages/meliome/guest/GuestMemoPageContainer';
import { GuestPaymentDatePageContainer } from 'src/pages/meliome/guest/GuestPaymentDatePageContainer';
import { GuestPaymentRedirectPageContainer } from 'src/pages/meliome/guest/GuestPaymentRedirectPageContainer';
import GuestPaymentRequestInfoPageContainer from 'src/pages/meliome/guest/GuestPaymentRequestInfoPageContainer';
import GuestRequestInfoPageContainer from 'src/pages/meliome/guest/GuestRequestInfoPageContainer';
import GuestSelectLinkPage from 'src/pages/meliome/guest/GuestSelectLink';
import GuestSelectOrgPageContainer from 'src/pages/meliome/guest/GuestSelectOrgPageContainer';
import GuestSignUpPageContainer from 'src/pages/meliome/guest/GuestSignUpPageContainer';
import GuestSuccessPageContainer from 'src/pages/meliome/guest/GuestSuccessPageContainer';
import { GuestSuccessSignUpPageContainer } from 'src/pages/meliome/guest/GuestSuccessSignUpPageContainer';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getCompanyInfo, getIsLoggedIn, getProfile } from 'src/redux/user/selectors';
import { FeatureFlags, UserAuthType } from 'src/utils/consts';
import { Route } from 'src/utils/locations';
import { melioMeLocations } from './locations';

type GuestWizardListItemType = {
  path: string;
  Component?: any;
  skip?: boolean;
  allowFor?: string[];
};

type GuestWizardSmartRouteType = {
  path: string;
  skip?: boolean;
  next?: string;
  prev?: string;
  Component?: any;
  key?: number;
  componentProps?: { editDisabled?: boolean; link?: string };
  allowFor?: string[];
};

/* eslint-disable react/prop-types */
const GuestWizardSmartRoute = ({
  key,
  Component,
  path,
  componentProps,
  next,
  prev,
  allowFor,
}: GuestWizardSmartRouteType) => (
  <SmartRoute path={path} exact key={key} allowFor={allowFor}>
    <Component
      {...componentProps}
      prevRoute={generatePath(prev || '/', componentProps)}
      nextRoute={generatePath(next || '/', componentProps)}
    />
  </SmartRoute>
);

const lastUnSkippedIndex = (list: GuestWizardSmartRouteType[]) => findLastIndex(list, (item) => !item.skip);

const setNextForAllSkipUntilUnSkip = curry((nextPath: Route | string, list: GuestWizardSmartRouteType[]) =>
  list.map((item, idx) => (idx >= lastUnSkippedIndex(list) ? set('next', nextPath, item) : item))
);

const createListWithNextAndPrev = (list: GuestWizardListItemType[]) =>
  reduce(
    list,
    (acc: GuestWizardSmartRouteType[], value: GuestWizardListItemType) => {
      if (acc.length > 0) {
        return concat(setNextForAllSkipUntilUnSkip(value.path, acc), {
          ...value,
          prev: acc[lastUnSkippedIndex(acc)].path,
        });
      }

      return [value];
    },
    []
  );

const createGuestWizardSmartRoutes = (
  list: GuestWizardSmartRouteType[],
  componentProps: ComponentProps<any>,
  firstPrev: string,
  lastNext: Route | string
) =>
  pipe(
    createListWithNextAndPrev,
    (list) => set('0.prev', firstPrev, list),
    setNextForAllSkipUntilUnSkip(lastNext),
    (list) => list.map((value, key) => GuestWizardSmartRoute({ ...value, componentProps, key }))
  )(list);

const GuestWizardRoute = () => {
  const [isGuestPayorFlowCombineSuccessScreenFF] = featureFlags.useFeature(
    FeatureFlags.GuestPayorFlowCombineSuccessScreen,
    false
  );

  const match = useRouteMatch<{ link: string }>();
  const [skipGuestPayorFlowStep] = featureFlags.useFeature(FeatureFlags.GuestPayorFlowRemoveScreensPhase1, false);
  const { link } = match.params;
  const orgId = useOrgId();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const profile = useSelector(getProfile);
  const companyInfo = useSelector(getCompanyInfo);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const skipInfoDetails =
    !isEmpty(companyInfo?.contactFirstName) &&
    !isEmpty(companyInfo?.contactLastName) &&
    !isEmpty(companyInfo?.companyName);
  const skipSelectOrgStep = profile.isGuest || (organizations && organizations.length === 1);

  const guestWizard: GuestWizardListItemType[] = [
    { path: melioMeLocations.wizard.index, Component: GuestRequestInfoPageContainer },
    {
      path: melioMeLocations.wizard.signUp,
      Component: GuestSignUpPageContainer,
      skip: isLoggedIn,
    },
    {
      path: melioMeLocations.wizard.emailVerify,
      Component: GuestEmailVerifyPageContainer,
      skip: isLoggedIn,
    },
    {
      path: melioMeLocations.wizard.infoDetails,
      Component: GuestInfoDetailsPageContainer,
      skip: skipInfoDetails,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
    },
    {
      path: melioMeLocations.wizard.selectOrg,
      Component: GuestSelectOrgPageContainer,
      skip: skipSelectOrgStep,
      allowFor: [UserAuthType.AUTHENTICATED],
    },
    {
      path: melioMeLocations.wizard.editFundingSource,
      Component: GuestEditFundingSourcesPageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
    },
    {
      path: melioMeLocations.wizard.paymentDate,
      Component: GuestPaymentDatePageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
      skip: skipGuestPayorFlowStep,
    },
    {
      path: melioMeLocations.wizard.memo,
      Component: GuestMemoPageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
      skip: skipGuestPayorFlowStep,
    },
    {
      path: melioMeLocations.wizard.expedite,
      Component: GuestExpeditePaymentPageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
    },
    {
      path: melioMeLocations.wizard.confirm,
      Component: GuestConfirmPageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
    },
    {
      path: melioMeLocations.wizard.success,
      Component: isGuestPayorFlowCombineSuccessScreenFF
        ? GuestCombinedSuccessSignupPageContainer
        : GuestSuccessPageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
    },
    {
      path: melioMeLocations.wizard.successSignUp,
      Component: GuestSuccessSignUpPageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
    },
  ];

  const nextFromIndex = createListWithNextAndPrev(guestWizard)[0]?.next;

  return (
    <Switch>
      <SmartRoute
        path={melioMeLocations.wizard.requestPayment}
        exact
        render={(routerProps) => (
          <GuestPaymentRequestInfoPageContainer
            link={routerProps.match.params.link}
            hash={routerProps.match.params.hash}
          />
        )}
      />

      <GuestWizardSmartRoute
        path={melioMeLocations.wizard.requestPaymentNoEditing}
        Component={GuestRequestInfoPageContainer}
        componentProps={{ editDisabled: true, link }}
        next={nextFromIndex}
        prev=""
      />
      {createGuestWizardSmartRoutes(
        guestWizard,
        { link },
        melioMeLocations.wizard.index,
        generatePath(onboardingLocations.companyInfo.addressManual, { orgId })
      )}
    </Switch>
  );
};

export function GuestRoute() {
  const orgId = useOrgId();
  const guestRestoreSession: GuestWizardListItemType[] = [
    {
      path: melioMeLocations.registerEmailVerify,
      Component: GuestEmailVerifyPageContainer,
    },
    {
      path: melioMeLocations.registerSuccess,
      Component: GuestSuccessSignUpPageContainer,
      allowFor: [UserAuthType.GUEST, UserAuthType.AUTHENTICATED],
    },
  ];

  return (
    <Switch>
      <SmartRoute path={melioMeLocations.guestPaymentRedirect} exact>
        <GuestPaymentRedirectPageContainer />
      </SmartRoute>
      <SmartRoute path={melioMeLocations.requestPaymentWithOptions} exact>
        <GuestSelectLinkPage />
      </SmartRoute>
      {createGuestWizardSmartRoutes(
        guestRestoreSession,
        null,
        registrationLocations.index,
        generatePath(onboardingLocations.companyInfo.addressManual, { orgId })
      )}
      <SmartRoute>
        <GuestWizardRoute />
      </SmartRoute>
    </Switch>
  );
}
