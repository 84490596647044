/* eslint-disable @typescript-eslint/no-empty-function */
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import * as React from 'react';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import { compose } from 'recompose';
import styled, { withTheme } from 'styled-components';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { ActionsDropdown } from 'src/components/common/ActionsDropDown';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { BlockedForPaymentDialog } from 'src/components/common/BlockedForPaymentDialog';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { OnInputChange, Option } from 'src/components/common/MISingleSelect';
import { PageHeader } from 'src/components/common/PageHeader';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import BillOpenBalanceInfo from 'src/components/list/BillOpenBalanceInfo/BillOpenBalanceInfo';
import { RightPanelPlaceholder } from 'src/components/onboarding/RightPanelPlaceholder';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { getStoreActions, useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import { PageContainerProps } from 'src/hoc/withListContainer';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import { accountingPlatformBankAccountsStore } from 'src/modules/accounting-platform-bank-accounts/accounting-platform-bank-accounts-store';
import { useFetchAccountingPlatformBankAccounts } from 'src/modules/accounting-platform-bank-accounts/hooks/useFetchAccountingPlatformBankAccounts';
import { billsApi } from 'src/modules/bills/api';
import { billsStore } from 'src/modules/bills/bills-store';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import { getPaymentsActions } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { BillDetailsForm } from 'src/pages/bill/components/BillDetailsForm';
import { getBillAttachments } from 'src/pages/bill/components/utils';
import { useViewBillData } from 'src/pages/bill/components/ViewBill/useViewBillData';
import { useGetFileUrlsByFileId } from 'src/pages/bill/hooks/useGetFileUrlsByFileId';
import { billLocations } from 'src/pages/bill/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { isDirectoryVendor } from 'src/pages/vendor-directory/utils';
import { recurringBillsApi } from 'src/redux/payBillWizard/api';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';
import { devices } from 'src/theme/appDevices';
import { ThemeType } from 'src/theme/global-theme';
import {
  convertPaymentRequestToBill,
  getActionOptions,
  getBillPaymentTag,
  getBillTag,
  getPaymentRequestId,
  getPaymentRequestIdById,
  getVendorCompanyName,
  isBillHasPartialPayments,
  isPaymentRequest,
  showMarkAsPaidBills,
} from 'src/utils/bills';
import {
  AddFundingSourceWizardOrigin,
  BillPageType,
  ButtonsDirections,
  ButtonsRowPosition,
  DialogType,
  DialogVariants,
  NotificationCardTypes,
  PaymentCreateFlowOrigin,
  PaymentRequestCreateOrigin,
  PaymentStatus,
  PaymentTypes,
  ScreenMode,
} from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { isEnterPressed } from 'src/utils/events';
import { useQueryString } from 'src/utils/hooks';
import { getPaymentById, isPaymentCompletedRefund } from 'src/utils/payments';
import { stringifyQs } from 'src/utils/query-utils';
import { BillType, FieldType, PaymentRequestType, QboBillAttachment } from 'src/utils/types';
import { withNewBillData } from '../../hoc/withNewBillData';
import { billFactory } from '../../records';
import { BillAttachment } from '../BillAttachment';
import { accountingSoftwareNameToIntlPath } from '../BillDetailsForm/utils/accountingSoftwareNameToIntlPath';
import { BillDetailsHeader } from '../BillDetailsHeader';
import { ViewBillMarkAsPaidModal } from '../modals/ViewBillMarkAsPaidModal';
import { MarkAsPaidModalStatus } from './types';
import { getBillHeaderActionsOptions, getUrlByPayment } from './utils';

type Props = {
  onChangeAttachment?: (file: File, loadBillFromAttachment?: boolean) => void;
  onDeleteAttachment?: () => void | null;
  onFieldChange: (field: FieldType) => void;
  validationErrors?: Record<string, any>;
  files: number[];
  isUploading?: boolean;
  reloadBills: () => void;
  backPath: string;
  setPaymentRequestApprovalStatus?: (
    paymentRequestId: string,
    isApproved: boolean,
    isTrusted: boolean
  ) => Promise<void>;
  singleViewRef: MutableRefObject<HTMLElement>;
  id: string;
  theme: ThemeType;
  onSubmitBill: (cb: (isPassedValidation: boolean) => void) => void;
  filteredVendors?: Array<Option>;
  onCancelForm: (prevBill: BillType | null) => void;
  onLoadBill: (bill?: BillType) => void;
  editBill: BillType;
  vendorAccountIdentifier?: string;
  onVendorsInputChange?: OnInputChange;
  filters: PageContainerProps['filters'];
};

const eventPage = 'bill';

const PlainViewBill = ({
  // original props
  reloadBills,
  backPath,
  setPaymentRequestApprovalStatus,
  singleViewRef,
  // withNewBillData props
  onChangeAttachment,
  onDeleteAttachment,
  onFieldChange,
  validationErrors,
  files,
  isUploading,
  onSubmitBill,
  filteredVendors,
  onCancelForm,
  onLoadBill,
  editBill,
  vendorAccountIdentifier,
  onVendorsInputChange,
}: Props) => {
  const initialState = {
    mode: ScreenMode.VIEW,
    isDeleting: false,
    markAsPaidModalStatus: MarkAsPaidModalStatus.CLOSE,
    isVendorBlockedForPayment: false,
    isLoading: false,
    recurringBill: null,
    prevBill: null,
    qboBillAttachments: null,
    billHasPartialPayments: false,
  };

  // Hooks
  const device = useBreak();
  const { navigate } = useNavigator();
  const site = useSiteContext();
  const query = useQueryString();
  const { id } = query;
  const { billId, paymentId, bill } = useViewBillData(id);
  useFetchAccountingPlatformBankAccounts({ forceReload: false });
  const fileId = first(files)?.toString();
  const { fileStorageUrl, filePreviewUrls, isLoading: loadingFileUrls } = useGetFileUrlsByFileId(fileId, bill.id);
  const {
    connectedAccountingPlatform,
    isConnected: isConnectedToAccountingPlatform,
  } = usePayablesConnectedAccountingPlatform();
  const defaultBillsSearchPath = stringifyQs(omit(query, 'id'));
  const [pathname, search = defaultBillsSearchPath] = backPath.split('?');
  const backPathUrl = `${pathname}?${search}`;
  const { isInternationalVendor, isInternationalAllowed } = useIsInternationalAllowed(bill.vendorId);
  const isInternationalDisabled = isInternationalVendor && !isInternationalAllowed;

  // Redux
  const dispatch = useDispatch();
  const { startSinglePaymentFlow } = getPaymentsActions(dispatch);
  const { fetch: getBillById, markAsPaid: markBillAsPaid } = getStoreActions(billsStore)(dispatch);
  const vendorActions = useStoreActions(vendorsStore);
  const paymentRequestsActions = useStoreActions(paymentRequestsStore);
  const orgId = useSelector(getOrgId);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const currentUser = useSelector(profileStore.selectors.profile);
  const { companyName } = useSelector(getCompanyInfo);
  const accountingPlatformsBankAccounts = useSelector((state) =>
    accountingPlatformBankAccountsStore.selectors.list.value(state, { orgId })
  );
  const vendorPaymentPreferences = useSelector((state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.item(state, bill.vendorId)
  );
  const isAdmin = useSelector(profileStore.selectors.isAdmin);
  const accountingPlatformName = accountingSoftwareNameToIntlPath(connectedAccountingPlatform?.name);

  // State
  const [mode, setMode] = useState<ScreenMode>(initialState.mode);
  const [isDeleting, setIsDeleting] = useState<boolean>(initialState.isDeleting);
  const [markAsPaidModalStatus, setMarkAsPaidModalStatus] = useState<MarkAsPaidModalStatus>(
    initialState.markAsPaidModalStatus
  );
  const [isVendorBlockedForPayment, setIsVendorBlockedForPayment] = useState<boolean>(
    initialState.isVendorBlockedForPayment
  );
  const [isLoading, setIsLoading] = useState<boolean>(initialState.isLoading);
  const [recurringBill, setRecurringBill] = useState<BillType | null>(initialState.recurringBill);
  const [prevBill, setPrevBill] = useState<BillType | null>(initialState.prevBill);
  const [qboBillAttachments, setQboBillAttachments] = useState<QboBillAttachment[] | null>(
    initialState.qboBillAttachments
  );
  const loadBill = async () => {
    let bill: BillType | undefined;
    setIsLoading(true);
    try {
      if (isPaymentRequest(id)) {
        const requestId = getPaymentRequestIdById(id);
        const { payload: paymentRequest } = (await paymentRequestsActions.fetch({
          organizationId: orgId,
          id: requestId,
        })) as { payload: PaymentRequestType };

        bill = !paymentRequest
          ? billFactory()
          : billFactory(
              convertPaymentRequestToBill({
                ...paymentRequest,
                companyName: companyName ?? '',
              })
            );
      } else {
        const response = await getBillById({
          orgId,
          id: billId,
        });

        const billData = response.payload;

        if (billData.recurringBillId) {
          const result = await recurringBillsApi.getOrgRecurringBills(orgId, {
            params: [billData.recurringBillId],
          });
          const recurringBill = result.recurringBills.pop();
          setRecurringBill(recurringBill);
        }

        const qboBillAttachments = await getBillAttachments(billData.id, orgId);

        setQboBillAttachments(qboBillAttachments);
        bill = billFactory(billData);

        await vendorActions.checkVendorPaymentPreferences({ orgId, id: billData.vendorId });
      }

      onLoadBill && onLoadBill(bill);
    } catch {
      onLoadBill && onLoadBill(billFactory());
    } finally {
      setIsLoading(false);
    }

    return bill;
  };

  const resetState = () => {
    setMode(initialState.mode);
    setIsDeleting(initialState.isDeleting);
    setMarkAsPaidModalStatus(initialState.markAsPaidModalStatus);
    setIsVendorBlockedForPayment(initialState.isVendorBlockedForPayment);
    setIsLoading(initialState.isLoading);
    setRecurringBill(initialState.recurringBill);
    setPrevBill(initialState.prevBill);
    setQboBillAttachments(initialState.qboBillAttachments);
  };

  useEffect(() => {
    loadBill();
  }, []);

  useEffect(() => {
    resetState();

    if (billId) {
      loadBill();
    }
  }, [billId]);

  const onDeleteBill = () => {
    analytics.trackAction('delete-bill-confirmed', {
      paymentId,
      billId,
      paymentStatus: payment?.status === PaymentStatus.FAILED ? PaymentTypes.REFUND : payment?.status,
    });
    setIsLoading(true);
    billsApi
      .deleteBillById(orgId, billId)
      .then(() => {
        analytics.trackAction('delete-bill-success', {
          paymentId,
          billId,
          paymentStatus: payment?.status === PaymentStatus.FAILED ? PaymentTypes.REFUND : payment?.status,
        });

        navigate(backPathUrl);
        reloadBills();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEditBill = async (cb: (isPassedValidation: boolean) => void) => {
    try {
      setIsLoading(true);
      await onSubmitBill((isPassedValidation: boolean) => {
        setIsLoading(false);
        cb(isPassedValidation);
      });
    } catch (error: any) {
      setIsLoading(false);
      capture(error, 'bill-edit-error');
    }
  };

  const onToggleMode = () => {
    if (mode === ScreenMode.VIEW) {
      setMode(ScreenMode.EDIT);
      setPrevBill(bill);
      analytics.track('bills', 'edit-bill-mode');
    } else {
      setMode(ScreenMode.VIEW);
      setPrevBill(prevBill);
      analytics.track('bills', 'cancel-edit-bill-mode');
      onCancelForm(prevBill);
    }
  };

  const onEditBillFunc = async () => {
    await onEditBill((isPassedValidation) => {
      if (isPassedValidation) {
        setMode(ScreenMode.VIEW);
      }
    });
  };

  const onKeyPressed = async (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      await onEditBillFunc();
    }
  };

  const onDeleteClicked = () => {
    analytics.trackAction('delete-bill', {
      paymentId,
      billId,
      paymentStatus: payment?.status === PaymentStatus.FAILED ? PaymentTypes.REFUND : payment?.status,
    });
    setIsDeleting(true);
  };

  const onDeleteBillCanceled = () => {
    analytics.trackAction('delete-bill-canceled', {
      paymentId,
      billId,
      paymentStatus: payment?.status === PaymentStatus.FAILED ? PaymentTypes.REFUND : payment?.status,
    });
    setIsDeleting(false);
  };

  const onHideNonDeletableModal = () => {
    analytics.track('bills', 'hide-non-deletable-modal');
    setIsDeleting(false);
  };

  const openMarkAsPaidModal = (markAsPaidModalStatus: MarkAsPaidModalStatus) => {
    analytics.trackAction('mark-as-paid-modal', { toggle: 'open' });
    setMarkAsPaidModalStatus(markAsPaidModalStatus);
  };

  const handleMarkBillAsPaid = async ({
    accountingPlatformAccountId,
    amount,
  }: {
    accountingPlatformAccountId?: string | null;
    amount?: string;
  }) => {
    if (!bill) {
      return;
    }

    analytics.track(eventPage, `mark-as-paid`);
    setIsLoading(true);
    const { payload: markedAsPaidBill } = await markBillAsPaid({
      orgId,
      id: bill.id,
      accountingPlatformAccountId,
      amount,
      createOrigin: PaymentCreateFlowOrigin.PAY,
    });

    analytics.track(eventPage, `mark-as-paid-success`);
    const updatedBill = await loadBill();
    setIsLoading(false);
    const payment = orderBy(markedAsPaidBill.payments, ['createdAt'], ['desc']).find(
      (p) => p.manual && p.amount === Number(amount)
    );

    navigate(getUrlByPayment(updatedBill!, payment, isAdmin, orgId));
  };

  const onMarkBillAsPaid = () => openMarkAsPaidModal(MarkAsPaidModalStatus.OPEN_MARK_BILL_AS_PAID);

  const onTrackDelivery = () => {
    analytics.track(eventPage, 'track-delivery');
    const payment = getPaymentById(bill.payments, paymentId);
    const trackingUrl = payment?.checkTracks?.[0]?.trackingUrl;

    if (trackingUrl) {
      window.open(trackingUrl);
    }
  };

  const getHeaderSubtitle = () => {
    if (bill.recurringBillId && recurringBill) {
      const { frequency, occurrences } = recurringBill;
      const index = bill.recurringBillIndex || null;

      return (
        <MIFormattedText
          label="bills.view.recurringBillInfo"
          values={{
            frequency,
            index,
            occurrences,
          }}
        />
      );
    }

    return bill.invoiceNumber ? (
      <MIFormattedText label="bills.view.invoiceNumber" values={{ invoiceNumber: bill.invoiceNumber }} />
    ) : (
      <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.form.invoiceNumberEmpty" />
    );
  };

  const goPayBill = async () => {
    analytics.track(eventPage, 'pay-bill');

    if (vendorPaymentPreferences?.blockPayments) {
      setIsVendorBlockedForPayment(true);
    } else {
      startSinglePaymentFlow({ billId, orgId, nextLocationState: { origin: AddFundingSourceWizardOrigin.PAY_BILL } });
    }
  };

  const onClickSupport = () => {
    intercomService.show();
    analytics.track('bills', 'payment-failed-contact-support');
  };

  const renderMarkAsPaidModalDialog = () => {
    const options = accountingPlatformsBankAccounts.map((account) => ({
      value: account.id,
      label: account.name,
    }));

    const onSubmit = async ({
      accountingPlatformAccountId,
      amount,
    }: {
      accountingPlatformAccountId?: string | null;
      amount: string;
    }) => {
      analytics.trackAction('mark-as-paid-modal', { toggle: 'close' });
      setMarkAsPaidModalStatus(MarkAsPaidModalStatus.CLOSE);
      await handleMarkBillAsPaid({
        accountingPlatformAccountId,
        amount,
      });
    };

    return (
      <ViewBillMarkAsPaidModal
        dismiss={() => {
          analytics.trackAction('mark-as-paid-modal', { toggle: 'close' });
          setMarkAsPaidModalStatus(MarkAsPaidModalStatus.CLOSE);
        }}
        isConnectedToAccountingPlatform={isConnectedToAccountingPlatform}
        showPaidAmount
        balance={bill.balance}
        invoiceNumber={bill.invoiceNumber}
        options={options}
        submit={onSubmit}
        accountingPlatformName={accountingPlatformName}
      />
    );
  };

  const renderDeleteModalDialog = () => {
    const isDeletable = get(bill, 'metadata.isDeletable', true);
    const paymentsReportLinkPath = `${generatePath(settingsLocations.company, { orgId })}#PaymentsReport`;

    if (isDeletable) {
      if (isPaymentCompletedRefund(bill.payments[0])) {
        return (
          <Dialog
            type={DialogType.CONFIRM}
            variant={DialogVariants.ERROR}
            title="bills.form.deleteDialog.refund.title"
            subtitle="bills.form.deleteDialog.refund.subtitle"
            subtitleValues={{
              paymentsReportLink: (
                <Link to={paymentsReportLinkPath}>
                  <MIInlineLink label="bills.form.deleteDialog.refund.paymentsReportLink" />
                </Link>
              ),
            }}
            okButtonText="bills.form.deleteDialog.refund.confirm"
            cancelButtonText="bills.form.deleteDialog.refund.cancel"
            onOkAction={onDeleteBill}
            onCancelAction={onDeleteBillCanceled}
            buttonsDirection={ButtonsDirections.HORIZONTAL}
            buttonsRowPosition={ButtonsRowPosition.RIGHT}
          />
        );
      }

      return (
        <Dialog
          type={DialogType.CONFIRM}
          variant={DialogVariants.ERROR}
          title="bills.form.deleteDialog.title"
          titleValues={{
            invoiceNumber: bill.invoiceNumber,
            companyName: getVendorCompanyName(bill),
          }}
          subtitle="bills.form.deleteDialog.subtitle"
          subtitleValues={{
            totalAmount: (
              <strong>
                <MIFormattedCurrency value={bill.totalAmount} />
              </strong>
            ),
            companyName: <strong>{getVendorCompanyName(bill)}</strong>,
          }}
          okButtonText="bills.form.deleteDialog.confirm"
          onOkAction={onDeleteBill}
          onCancelAction={onDeleteBillCanceled}
        />
      );
    }

    return (
      <Dialog
        type={DialogType.ALERT}
        variant={DialogVariants.ERROR}
        title="bills.form.nonDeletableDialog.title"
        subtitle="bills.form.nonDeletableDialog.subtitle"
        subtitleValues={{
          supportEmail: (
            <StyledMIInlineLink
              target="_self"
              text={site.config.support.email}
              to={`mailto:${site.config.support.email}`}
            />
          ),
        }}
        cancelButtonText="bills.form.nonDeletableDialog.ok"
        onCancelAction={onHideNonDeletableModal}
      />
    );
  };

  const billDetailsRef = useRef<HTMLSelectElement>();
  const goViewDetails = () => {
    if (billDetailsRef.current) {
      billDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleRejectPaymentRequest = async () => {
    if (bill.isPaymentRequest) {
      setIsLoading(true);

      setPaymentRequestApprovalStatus &&
        (await setPaymentRequestApprovalStatus(getPaymentRequestId(bill), false, false));
      setIsLoading(false);
    }
  };

  const handleSchedulePaymentRequest = () => {
    navigate(generatePath(billLocations.pay.funding, { billId: id, orgId }));
  };

  const onLabelClick = async (paymentId) => {
    if (singleViewRef.current) {
      singleViewRef.current.scrollTo({ behavior: 'smooth', top: 0 });
    }

    const payment = getPaymentById(bill.payments, paymentId)!;
    const url = getUrlByPayment(bill, payment, isAdmin, orgId);
    navigate(url);
  };

  const isFullSingleView = device.isMobile || device.isPhablet;
  const relevantBill = mode === ScreenMode.EDIT ? editBill : bill;
  const isBillCreatedFromPaymentRequest =
    !relevantBill.isPaymentRequest && relevantBill?.paymentRequest?.createOrigin === PaymentRequestCreateOrigin.REQUEST;

  if (!relevantBill || !relevantBill.id) {
    return <RightPanelPlaceholder isLoading={isLoading} />;
  }

  const showMarkAsPaid = showMarkAsPaidBills(relevantBill, currentUser, permissions);
  const onCloseBlockPaymentDialog = () => setIsVendorBlockedForPayment(false);
  const isBillWithPayments = !!paymentId;
  const isUnpaidInboxBill = !isBillWithPayments;
  const actions = {
    onMarkBillAsPaid,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onMarkPaymentAsUnpaid: () => {},
    onToggleMode,
    onDeleteClicked,
  };

  const showTotalAmountSection = Boolean(relevantBill?.payments?.length === 0);
  const billHasPartialPayments = isBillHasPartialPayments(relevantBill);
  const showOpenBalanceDetails = billHasPartialPayments || showTotalAmountSection;
  const relevantPayment = getPaymentById(relevantBill.payments, paymentId);
  const manuallyPaid = relevantPayment?.manual === true;
  const isPaymentFailed = relevantPayment?.status === PaymentStatus.FAILED;
  const showBillInfoActions = !isBillWithPayments;
  const status = paymentId ? getBillPaymentTag(relevantBill, relevantPayment) : getBillTag(relevantBill);
  const actionOptions = getActionOptions({
    bill: relevantBill,
    payment: relevantPayment,
    mode,
    permissions,
    currentUser,
    isUnpaidInboxBill,
    actions,
    connectedAccountingPlatform,
  });

  const isRequestAndHasNotDeliveryMethods =
    isPaymentRequest(relevantBill.id) && isEmpty(relevantBill.vendor?.deliveryMethods);

  const isEditMode = mode === ScreenMode.EDIT;
  const showPageHeader = !(isEditMode && isFullSingleView);
  const showDirectoryVendorLogo = isDirectoryVendor({
    ...relevantBill.vendor,
    deliveryMethods: relevantBill.vendor?.deliveryMethods ?? [],
  });
  const canSchedulePayment = isUnpaidInboxBill && !isRequestAndHasNotDeliveryMethods;
  const headerSubtitle = getHeaderSubtitle();

  const payment = getPaymentById(relevantBill.payments, paymentId);

  const headerActions = getBillHeaderActionsOptions({
    bill: relevantBill,
    payment: getPaymentById(bill.payments, paymentId),
    currentUserId: currentUser.id,
    permissions,
    onRetryPayment: () => {},
    onRetryPaymentDelivery: () => {},
    onChangeDeliveryMethod: () => {},
    onResendVirtualCard: () => {},
    onRetryReturnedCheck: () => {},
    onResendUndepositedCheck: () => {},
    onClickSupport,
    onDecline: () => {},
    onApprove: () => {},
    goPayBill,
    onTrackDelivery,
    onRejectPaymentRequest: handleRejectPaymentRequest,
    onSchedulePaymentRequest: handleSchedulePaymentRequest,
    isLoading,
    isEditMode,
    canSchedulePayment,
    isApproving: false,
    enableApprovalWorkflows: false,
    enableRescheduleDeclinedApprovalBulkPayment: false,
    isInternationalDisabled,
  });
  const dueDate = relevantBill.dueDate ? new Date(relevantBill.dueDate) : null;
  const occurrences = recurringBill && !isNil(recurringBill.occurrences) ? recurringBill.occurrences.toString() : null;
  const defaultVendorId = relevantBill.vendorId ? relevantBill.vendorId.toString() : null;

  return (
    <ViewBillContainer>
      {isDeleting && renderDeleteModalDialog()}
      {markAsPaidModalStatus !== MarkAsPaidModalStatus.CLOSE && renderMarkAsPaidModalDialog()}
      {isLoading && <AreaLoader />}
      {isVendorBlockedForPayment && <BlockedForPaymentDialog onCancelAction={onCloseBlockPaymentDialog} />}
      {bill && !isLoading && (
        <SingleViewLoadingContainer isEditMode={isEditMode} className={isLoading ? 'loading' : ''}>
          {showPageHeader && (
            <PageHeader
              backNav={{
                pathname,
                search,
              }}
              text={getVendorCompanyName(relevantBill)}
              subTitle={headerSubtitle}
              actionOptions={actionOptions}
              isPartialPayments
              goViewDetails={goViewDetails}
            >
              {isFullSingleView && (
                <BillDetailsHeader
                  companyName={getVendorCompanyName(relevantBill)}
                  isFullSingleView={isFullSingleView}
                  description={headerSubtitle}
                  status={status}
                  headerActions={headerActions}
                />
              )}
            </PageHeader>
          )}
          {!isFullSingleView && (
            <BillDetailsHeader
              companyName={getVendorCompanyName(relevantBill)}
              isFullSingleView={isFullSingleView}
              description={headerSubtitle}
              status={status}
              headerActions={headerActions}
              showViewDetailsLink={isBillWithPayments}
              goViewDetails={goViewDetails}
              showDirectoryVendorLogo={showDirectoryVendorLogo}
            />
          )}
          <CustomFeed feedType="billdetails_above" />
          <BillInfoContainer data-testid="bill-info-container">
            <MICard>
              {relevantBill.isPaymentRequest && (
                <StyledNotificationCard
                  type={NotificationCardTypes.INFO}
                  subtitle={{
                    label: 'bills.request.note',
                    values: { vendor: getVendorCompanyName(relevantBill) },
                  }}
                />
              )}
              <PaymentDetails onKeyDown={onKeyPressed} canSchedulePayment={canSchedulePayment} ref={billDetailsRef}>
                <FormHeader>
                  <Flex alignItems="center">
                    <MICardTitle label="bills.view.title" />
                  </Flex>
                  {!isEditMode && !relevantBill.internalBill && !isPaymentFailed && showBillInfoActions && (
                    <Actions showMarkAsPaid={showMarkAsPaid}>
                      {actionOptions.length > 0 && !manuallyPaid && (
                        <ActionsDropdown testId="view-bill-actions" actionOptions={actionOptions} />
                      )}
                    </Actions>
                  )}
                </FormHeader>
                <FormContent isEditMode={isEditMode}>
                  <BillFormContainer isEditMode={isEditMode}>
                    <BillDetailsForm
                      isViewRecurring={!!recurringBill}
                      occurrences={occurrences}
                      frequency={recurringBill && recurringBill.frequency}
                      isDisabled={Boolean(isUploading)}
                      filteredVendors={filteredVendors}
                      onVendorsInputChange={onVendorsInputChange}
                      vendorAccountIdentifier={
                        isEditMode ? vendorAccountIdentifier : relevantBill?.vendor?.accountIdentifier
                      }
                      onFieldChange={onFieldChange}
                      totalAmount={relevantBill.totalAmount}
                      vendorId={defaultVendorId}
                      intuitAccountId={relevantBill.intuitAccountId}
                      invoiceNumber={relevantBill.invoiceNumber}
                      dueDate={dueDate}
                      note={
                        isBillCreatedFromPaymentRequest ? relevantBill?.paymentRequest?.customerNote : relevantBill.note
                      }
                      validationErrors={validationErrors}
                      mode={mode}
                      billPageType={BillPageType.EDIT}
                      isPaymentRequest={Boolean(relevantBill.isPaymentRequest)}
                      isRecurringBill={Boolean(recurringBill)}
                      allowEditVendor={!isBillHasPartialPayments(relevantBill)}
                      isBillCreatedFromPaymentRequest={isBillCreatedFromPaymentRequest}
                    />
                  </BillFormContainer>

                  {!isLoading && !loadingFileUrls && (
                    <Box mt={5} mb={3}>
                      <BillAttachment
                        qboBillAttachments={qboBillAttachments}
                        isDisabled={isUploading}
                        fileStorageUrl={fileStorageUrl}
                        filePreviewUrls={filePreviewUrls}
                        mode={mode}
                        onChangeAttachment={onChangeAttachment}
                        onDeleteAttachment={onDeleteAttachment}
                        eventPage="bills"
                        withCounter
                      />
                    </Box>
                  )}
                </FormContent>
              </PaymentDetails>
              {showOpenBalanceDetails && (
                <BillOpenBalanceInfoContainer>
                  <BillOpenBalanceInfo
                    isPartialPayments={billHasPartialPayments}
                    currentPaymentId={paymentId}
                    bill={relevantBill}
                    onLabelClick={onLabelClick}
                  />
                </BillOpenBalanceInfoContainer>
              )}
            </MICard>
            {isEditMode && (
              <MIFloatedEditDoneButtons
                onDone={onEditBillFunc}
                onCancel={onToggleMode}
                doneLabel="bills.edit.save"
                cancelLabel="bills.edit.cancel"
                isDisabled={isUploading}
                titleLabel="bills.new.edit"
              />
            )}
          </BillInfoContainer>
        </SingleViewLoadingContainer>
      )}
    </ViewBillContainer>
  );
};

export const ViewBill = withTheme(compose(withNewBillData())(PlainViewBill));

const ViewBillContainer = styled.div`
  ${(props) => props.theme.components?.ViewBill?.ViewBillContainer}
`;

const PaymentDetails = styled(MICardForm)`
  ${(props) => props.theme.components?.ViewBill?.PaymentDetails}
`;

const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
    color: rgba(153, 153, 156, 1);
  }
`;

const FormContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${devices.mobile} {
    margin-left: 2.2rem;
  }

  @media ${devices.nonMobile} {
    width: 17.5rem;
  }
  ${(props) => props.theme.components?.ViewBill?.Actions}
`;

const BillFormContainer = styled.div`
  width: 60%;
  margin-bottom: 2rem;
  margin-right: 1.6rem;

  @media ${devices.mobile} {
    width: 100%;
    margin-bottom: 1.5rem;
    margin-right: 0;
  }
`;

const BillInfoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media ${devices.mobile} {
    margin-bottom: 4rem;
  }
  ${(props) => props.theme.components?.ViewBill?.BillInfoContainer}
`;

const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: ${(props) => props.theme.text.size.regular};
`;

const StyledNotificationCard = styled(MINotificationCard)`
  margin-bottom: 0.5rem;
  margin: 3rem 3.6rem 0;

  @media ${devices.mobile} {
    margin-bottom: 0.5rem;
    margin: 3rem 1.6rem 0;
  }
`;

const BillOpenBalanceInfoContainer = styled.div``;
