import { MouseEvent as ReactMouseEvent } from 'react';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';

type Props = {
  onCancel: (event: ReactMouseEvent) => void;
  onConfirm: (event: ReactMouseEvent) => void;
  isLoading: boolean;
};

export const HideClientModalMessageButtons = ({ onConfirm, onCancel, isLoading }: Props) => (
  <ModalButtonsWrapper>
    <Button
      label="bookkeeper.company.hideClientDialog.cancel"
      variant={ButtonVariants.tertiaryNaked}
      size={ButtonSizes.lg}
      onClick={onCancel}
    />
    <Button
      label="bookkeeper.company.hideClientDialog.confirm"
      testId="button-user.hideClientDialog.confirm"
      isLoading={isLoading}
      size={ButtonSizes.lg}
      onClick={onConfirm}
    />
  </ModalButtonsWrapper>
);
