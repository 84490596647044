import { FocusEvent, KeyboardEvent, SyntheticEvent, useCallback } from 'react';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { isEnterPressed, isEscPressed } from 'src/utils/events';
import { FieldType } from 'src/utils/types';

type Props = {
  totalAmount: string;
  setTotalAmount(value: string): void;
  onChange?(data: FieldType): void;
  onBlur?(data: FieldType): void;
  maxValue?: number;
};

export const useAmountInputHandlers = ({ totalAmount, setTotalAmount, onChange, onBlur, maxValue }: Props) => {
  const isAmountInvalid = useCallback((amount: number, maxValue: number) => amount > maxValue || amount <= 0, []);

  const getUserAmount = useCallback((value: string) => {
    const amount = Number(convertCurrencyToNumber(value));

    return Number.isNaN(amount) ? 0 : amount;
  }, []);

  const getBoundedAmount = useCallback(
    (value: string) => {
      const userAmount = getUserAmount(value);

      if (!maxValue) return userAmount;

      return isAmountInvalid(userAmount, maxValue) ? maxValue : userAmount;
    },
    [getUserAmount, isAmountInvalid, maxValue]
  );

  const handleChange = ({ event, value }: { event: SyntheticEvent<HTMLInputElement>; value: string }) => {
    if (event) {
      const { id } = event.currentTarget;
      setTotalAmount(value);
      onChange?.({ id, value });
    }
  };

  const handleBlur = ({ currentTarget: { id } }: FocusEvent<HTMLInputElement>) => {
    const boundedValue = maxValue ? getBoundedAmount(totalAmount) : totalAmount;

    const data = {
      id,
      value: `${boundedValue}`,
    };

    onChange?.(data);
    onBlur?.(data);
  };

  const handleKeyPressed = (e: KeyboardEvent<HTMLInputElement>) => {
    if (isEnterPressed(e) || isEscPressed(e)) {
      e.currentTarget.blur();
    }
  };

  return {
    handleChange,
    handleBlur,
    handleKeyPressed,
  };
};
