import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { VerifiedVendorDetailsWrapper } from 'src/components/msn/VerifiedVendor/VerifiedVendorDetailsWrapper';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { Modal } from 'src/core/ds/modal';

const BUSINESS_PROFILE_LEARN_MORE_URL =
  'https://melio.zendesk.com/hc/en-us/articles/6716015066780-All-the-ways-to-add-verified-network-vendors';

export type BusinessProfileModalSizeType = 'full' | 'md';

type BusinessProfileModalProps = {
  isOpen: boolean;
  onClose: () => void;
  size: BusinessProfileModalSizeType;
  vendorId: string;
};

export const BusinessProfileModal = ({ isOpen, onClose, size, vendorId }: BusinessProfileModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside" size={size}>
    <Modal.Header>
      <Modal.HeaderTitle>
        <Box textStyle="h2Semi">
          <MIFormattedText label="vendors.businessProfile.title" />
        </Box>
      </Modal.HeaderTitle>
      <Modal.HeaderSubTitle>
        <Flex direction="column" textStyle="body2">
          <MIFormattedText label="vendors.businessProfile.content" />
          <Link href={BUSINESS_PROFILE_LEARN_MORE_URL} isExternal>
            <MIFormattedText label="vendors.businessProfile.learnMore" />
          </Link>
        </Flex>
      </Modal.HeaderSubTitle>
    </Modal.Header>
    <Modal.Body>
      <VerifiedVendorDetailsWrapper vendorId={vendorId} />
    </Modal.Body>
    <Modal.Footer>
      <Button
        onClick={onClose}
        label="general.close"
        variant={ButtonVariants.unstyled}
        size={ButtonSizes.lg}
        textAlign="end"
      />
    </Modal.Footer>
  </Modal>
);
