import { Children, useState } from 'react';
import styled from 'styled-components';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoHint,
  PaymentInfoText,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import Box from 'src/core/ds/box/Box';
import { useFundingSourceMicroDepositState } from 'src/pages/settings/hooks/useFundingSourceMicroDeposits';
import { analytics } from 'src/services/analytics';
import { BillStatus, CardTypes, FundingType } from 'src/utils/consts';
import { canVerifyManualBankAccount, isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType, BillType, PaymentType } from 'src/utils/types';
import { CardThemes } from '../ViewBillPaymentActivity';
import { FundingSourceDescription } from './FundingSourceDescription';
import { FundingSourceIcon } from './FundingSourceIcon';

type Props = {
  fundingSource: AccountType;
  bill: BillType;
  theme?: CardThemes;
  payment: PaymentType;
  failureDate?: string | Date;
};

export const ViewBillPaymentActivityFundingSourceInfo = ({
  bill,
  payment,
  fundingSource,
  theme,
  failureDate,
}: Props) => {
  const isBillPaid = bill.status === BillStatus.PAID;
  const isActive = theme === CardThemes.Black || !isBillPaid;
  const microDepositEventPage = 'bill-payment_verify-manual-account';
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const [microDepositState, microDepositActions] = useFundingSourceMicroDepositState(microDepositEventPage, {
    fundingSourceId: fundingSource.id,
  });
  const achTraceNumber = payment?.metadata?.achTraceNumber;
  let label = 'viewBillPaymentActivity.fundingSource.achLabel';

  if (fundingSource.fundingType === FundingType.CARD) {
    label =
      fundingSource.cardAccount?.cardType === CardTypes.CREDIT
        ? 'viewBillPaymentActivity.fundingSource.creditCardLabel'
        : 'viewBillPaymentActivity.fundingSource.debitCardLabel';
  }

  let hintValues = {};
  let hint = '';

  if (canVerifyManualBankAccount(fundingSource)) {
    hint = 'viewBillPaymentActivity.fundingSource.unverifiedBankAccountHintWithAction';
    hintValues = {
      verifyLink: (...chunks) => (
        <VerifyAccountLink
          text={Children.toArray(chunks)}
          onClick={(event) => {
            analytics.track('bill-payment', 'verify-manual-account-click');
            event.preventDefault();
            setShowVerifyDialog(true);
          }}
        />
      ),
    };
  } else if (isManualBankAccountNotVerified(fundingSource)) {
    hint = 'viewBillPaymentActivity.fundingSource.unverifiedBankAccountHint';
  }

  const paymentMethodHeaderLabel = (
    <>
      {fundingSource.nickname && `${fundingSource.nickname} | `}
      <MIFormattedText label={label} />
    </>
  );

  return (
    <>
      {showVerifyDialog && (
        <VerifyMicroDeposits
          {...microDepositState}
          {...microDepositActions}
          key={fundingSource.id}
          verifyingId={fundingSource.id}
          onVerifyFinished={() => {
            setShowVerifyDialog(false);
          }}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}
      <PaymentActivityContainer>
        <PaymentActivityMainContainer>
          <PaymentInfoContainer>
            <PaymentIcon isBillPaid={!isActive}>
              <FundingSourceIcon fundingSource={fundingSource} />
            </PaymentIcon>
            <PaymentTextContainer>
              <PaymentMethodContainer>
                <PaymentMethodText data-testid="billpay-view-payment-method">
                  {paymentMethodHeaderLabel}
                </PaymentMethodText>
              </PaymentMethodContainer>
              <PaymentInfoText data-testid="billpay-view-payment-info-text" isBillPaid={!isActive}>
                <FundingSourceDescription fundingSource={fundingSource} />
              </PaymentInfoText>
              {achTraceNumber ? (
                <Box textStyle="body4" color="grey.600">
                  <MIFormattedText label="bills.pay.requestRefund.review.traceNumber" values={{ achTraceNumber }} />
                </Box>
              ) : null}
              {failureDate ? (
                <Box textStyle="body4" color="red.500" data-testid="billpay-view-payment-failure-date">
                  <MIFormattedText
                    label="viewBillPaymentActivity.status.failed"
                    values={{
                      date: <MIFormattedDate date={failureDate} />,
                    }}
                  />
                </Box>
              ) : null}
            </PaymentTextContainer>
          </PaymentInfoContainer>
        </PaymentActivityMainContainer>
        {!isBillPaid && hint && (
          <PaymentInfoHint data-testid="billpay-view-payment-info-hint">
            <MIFormattedText label={hint} values={hintValues} />
          </PaymentInfoHint>
        )}
      </PaymentActivityContainer>
    </>
  );
};

const VerifyAccountLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;
