import React from 'react';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import mastercard from 'src/images/icons/mastercard.svg';
import { DeliveryType } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  deliveryMethod: DeliveryMethodType | null;
};

export const DeliveryMethodIcon = ({ deliveryMethod }: Props) => {
  switch (deliveryMethod?.deliveryType) {
    case DeliveryType.RPPS:
      return <Icon name={IconNames.directoryPayment} size={IconSize.lg} />;
    case DeliveryType.CHECK:
      return <Icon name={IconNames.paperCheck} size={IconSize.lg} />;
    case DeliveryType.VIRTUAL_CARD:
      return <Image w={6} src={mastercard} />;
    case DeliveryType.INTERNATIONAL:
      return <Icon name={IconNames.international} size={IconSize.lg} />;
    case DeliveryType.VIRTUAL:
      return null;
    default:
      return <Icon name={IconNames.bank} size={IconSize.lg} />;
  }
};
