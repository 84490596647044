import { useFeature } from '@melio/shared-web/dist/feature-flags';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICodeInput } from 'src/components/common/MICodeInput';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { profileStore } from 'src/modules/profile/profile-store';
import { AuthCodeVerificationStatus, mapAuthFailedCodeVerificationErrors } from 'src/pages/auth/consts';
import { useLoginUser } from 'src/pages/auth/hooks/useLoginUser';
import { getUserPreferences } from 'src/redux/user/selectors';
import { FeatureFlags, NotificationCardTypes } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { UserContextType, UserPreferencesType } from 'src/utils/types';
import { AuthCodePageFooter } from './components/AuthCodePageFooter';
import { NotificationCard } from './components/NotificationCard';
import { useAuthCodeVerification } from './hooks/useAuthCodeVerification';

const VerificationCodeLength = 4;

export const AuthCodeVerificationPage = () => {
  const history = useHistory();
  const user = useSelector(profileStore.selectors.profile);
  const userPreferences: UserPreferencesType = useSelector(getUserPreferences);
  const { isLoading: isLoadingLogin, initUserAndNavigate } = useLoginUser();
  const [joinNewOrgFlowUrl] = useLocationState<string>('joinNewOrgFlowUrl');
  const [, isSignInMfaEnabledLoading] = useFeature<boolean>(FeatureFlags.SignInMfa, false);

  const onCodeVerificationSuccess = async () => {
    await initUserAndNavigate(
      { ...user, hasValid2Factor: true, userPreferences } as UserContextType,
      'continue-success',
      joinNewOrgFlowUrl
    );
  };

  const {
    isVerifying,
    authVerificationCodeStatus,
    email,
    resetVerificationCodeStatus,
    verifyCode,
    codeResendResult,
    handleResendCodeClick,
    handleContactSupportClick,
    isMaxResendAttempts,
  } = useAuthCodeVerification({
    onSuccess: onCodeVerificationSuccess,
    user,
  });

  const handleCodeChange = (code: string) => {
    if (code.length === VerificationCodeLength) {
      verifyCode(code);
    } else {
      resetVerificationCodeStatus();
    }
  };

  const onPrev = () => history.goBack();
  const authCodeErrors = mapAuthFailedCodeVerificationErrors[authVerificationCodeStatus];
  const isBlockedRequestCode = authVerificationCodeStatus === AuthCodeVerificationStatus.BLOCKED;
  const isCodeMaxAttempts = authVerificationCodeStatus === AuthCodeVerificationStatus.MAX_ATTEMPTS;
  const codeISInvalid = authVerificationCodeStatus === AuthCodeVerificationStatus.INVALID;
  const showAreaLoader = isVerifying || isLoadingLogin;

  if (isSignInMfaEnabledLoading) {
    return <AreaLoader />;
  }

  return (
    <>
      {showAreaLoader && <AreaLoader placement="melioMe" />}
      <StepLayoutPage
        title="guests.emailVerify.2FA.title"
        subtitle="guests.emailVerify.2FA.subTitle"
        subTitleValues={{
          email: <strong>{email}</strong>,
          br: <br />,
          showEnterCode: isCodeMaxAttempts ? null : <MIFormattedText label="guests.emailVerify.2FA.enterCodeBelow" />,
        }}
        onPrev={onPrev}
        illustration={IllustrationName.mailOne}
        footer={
          <AuthCodePageFooter
            handleContactSupportClick={handleContactSupportClick}
            handleResendCodeClick={handleResendCodeClick}
            shouldHideResendLink={isMaxResendAttempts}
            authVerificationCodeStatus={authVerificationCodeStatus}
          />
        }
      >
        {authCodeErrors && (
          <NotificationCard
            type={NotificationCardTypes.ERROR}
            label={mapAuthFailedCodeVerificationErrors[authVerificationCodeStatus]}
          />
        )}
        {!authCodeErrors && isMaxResendAttempts && !isCodeMaxAttempts && !codeISInvalid && (
          <NotificationCard type={NotificationCardTypes.SUCCESS} label="guests.emailVerify.lastAttempt" />
        )}
        {codeResendResult && !isBlockedRequestCode && !isMaxResendAttempts && (
          <NotificationCard type={NotificationCardTypes.SUCCESS} label="guests.emailVerify.resendNotification" />
        )}

        <CodeInputContainer data-testid="code-input-container">
          <MICodeInput type="number" pattern="\d*" isValid={!authCodeErrors} onChange={handleCodeChange} />
        </CodeInputContainer>
      </StepLayoutPage>
    </>
  );
};

const CodeInputContainer = styled.div`
  margin-bottom: 3rem;
  text-align: center;

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
`;
