import { useEffect, useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { VStack } from 'src/core/ds/stack';

type Props = {
  onClose: () => void;
  onFirsTimeOpen: () => void;
  isBatchUncombinedEnbled: boolean;
};

export const CombineBillsTooltipBody = ({ onClose, onFirsTimeOpen, isBatchUncombinedEnbled }: Props) => {
  const [userUpdated, setUserUpdated] = useState(false);
  const label = `regularBatchPayments.Settings.${
    isBatchUncombinedEnbled ? 'bulkPaymentUncombinedBillsTooltipLabel' : 'bulkPaymentCombineBillsTooltipLabel'
  }`;

  useEffect(() => {
    if (!userUpdated) {
      setUserUpdated(true);
      onFirsTimeOpen();
    }
  }, [userUpdated, onFirsTimeOpen]);

  return (
    <VStack px={2} py={3} gap={2}>
      <Box textStyle="body4" textAlign="start" data-testid="combine-bills-tooltip-text">
        <MIFormattedText label={label} />
      </Box>
      <Box
        textStyle="body4Semi"
        alignSelf="flex-end"
        cursor="pointer"
        p={2}
        onClick={onClose}
        data-testid="combine-bills-tooltip-close"
      >
        <MIFormattedText label="general.gotIt" />
      </Box>
    </VStack>
  );
};
