import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { useUpdateServiceFeesPreference } from 'src/modules/organizations/hooks/useUpdateServiceFeesPreference';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { NotificationVariant } from 'src/utils/consts';

export const useVendorAbsorbedFeeSetting = () => {
  const organizationPreferences = useOrganizationPreferences();
  const orgId = useSelector(getOrgId);
  const { updateServiceFeesPreference } = useUpdateServiceFeesPreference();

  const preferences = useMemo(
    () => ({
      isVendorAbsorbedFee:
        organizationPreferences?.isVendorAbsorbedFee === true ||
        organizationPreferences?.isVendorAbsorbedFee === 'true',
    }),
    [organizationPreferences]
  );

  const [preferencesMV] = useForm(preferences, {
    submit: async (newValue) => {
      try {
        await updateServiceFeesPreference({ orgId, value: newValue.isVendorAbsorbedFee });
        pushNotification({
          type: NotificationVariant.SUCCESS,
          msg: 'settings.creditCardFeePayment.updateSuccess',
        });
      } catch (e) {
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'settings.creditCardFeePayment.updateFailed',
        });
      }

      analytics.trackAction(newValue.isVendorAbsorbedFee ? 'absorb-fee-enabled' : 'absorb-fee-disabled');
    },
  });

  return [preferencesMV.isVendorAbsorbedFee.value, preferencesMV.isVendorAbsorbedFee.changeAndUpdate] as const;
};
