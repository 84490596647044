import * as React from 'react';
import { Button, ButtonVariants } from 'src/core/ds/button';

export function TertiaryButton(props: React.ComponentProps<typeof Button>) {
  return (
    <Button
      {...props}
      label="auth.signUpCondensed.signInWithEmail"
      variant={ButtonVariants.secondary}
      sx={{
        border: '2px solid #A6AEBE',
        color: '#18191B',
        _hover: {
          backgroundColor: '#EEEEEE',
        },
        _active: {
          backgroundColor: '#DDDDDD',
        },
      }}
    />
  );
}
