import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FileInput } from 'src/core/ds/input';
import { analytics } from 'src/services/analytics';
import { ACCEPT_FILES } from 'src/utils/consts';

type Props = {
  uploadLabel: string;
  deleteLabel: string;
  isShowDeleteButton: boolean;
  onChangeAttachment?: (fileInput: React.ChangeEvent<HTMLInputElement>) => void | null;
  onDeleteAttachment?: () => void | null;
  accept?: string;
  isDisabled: boolean;
  testId?: string;
};

type ModifyAttachmentActionContainerProps = {
  children?: React.ReactNode;
  isDisabled: boolean;
  isShowDeleteButton: boolean;
};

type ModifyAttachmentActionProps = {
  children?: React.ReactNode;
  isDisabled?: boolean;
  destructive?: boolean;
  onClick: () => void;
};

class UploadAttachment extends React.PureComponent<Props> {
  static defaultProps = {
    accept: ACCEPT_FILES.ALL,
    isDisabled: false,
  };
  fileInputRef: { current: HTMLInputElement | null } = React.createRef();

  triggerFileInput = () => {
    if (this.fileInputRef.current && !this.props.isDisabled) {
      this.fileInputRef.current.click();
      analytics.trackAction(this.props.uploadLabel);
    }
  };

  triggerDeleteFile = () => {
    if (!this.props.isDisabled && this.props.onDeleteAttachment) {
      this.props.onDeleteAttachment();
      analytics.trackAction(this.props.deleteLabel);
    }
  };

  render() {
    const { uploadLabel, deleteLabel, isShowDeleteButton, onChangeAttachment, accept, isDisabled } = this.props;

    return (
      <ModifyAttachmentActionContainer isDisabled={isDisabled} isShowDeleteButton={isShowDeleteButton}>
        {isShowDeleteButton && (
          <ModifyAttachmentAction isDisabled={isDisabled} onClick={this.triggerDeleteFile}>
            <MIFormattedText label={deleteLabel} />
          </ModifyAttachmentAction>
        )}
        <ModifyAttachmentAction isDisabled={isDisabled} onClick={this.triggerFileInput}>
          <MIFormattedText label={uploadLabel} />
          <FileInput
            testId={this.props.testId}
            ref={this.fileInputRef}
            onChange={onChangeAttachment}
            type="file"
            accept={accept}
          />
        </ModifyAttachmentAction>
      </ModifyAttachmentActionContainer>
    );
  }
}

const ModifyAttachmentActionContainer = ({
  children,
  isDisabled,
  isShowDeleteButton,
}: ModifyAttachmentActionContainerProps) => (
  <Flex
    id="actionContainer"
    sx={{
      justifyContent: {
        base: isDisabled ? 'flex-start' : 'space-between',
        sm: isShowDeleteButton ? 'space-between' : 'center',
      },
      maxWidth: isShowDeleteButton ? 'full' : '17.9rem',
      width: { base: '50%', sm: 'full' },
      overflow: 'hidden',
      _last: {
        ml: 3,
      },
    }}
  >
    {children}
  </Flex>
);

const ModifyAttachmentAction = ({ children, isDisabled, destructive, onClick }: ModifyAttachmentActionProps) => (
  <Box
    sx={{
      display: 'inline-flex',
      height: '2.5rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: destructive ? 'red.500' : 'black',
      textStyle: 'body4Semi',
      textTransform: 'none',
      position: 'relative',
      opacity: isDisabled ? '0.3' : '1',

      _hover: {
        cursor: 'pointer',
        color: destructive ? 'red.500' : 'primary.600',
      },
    }}
    onClick={onClick}
  >
    {children}
  </Box>
);

export default UploadAttachment;
