import { PayListItemType } from 'src/modules/bills/types';
import { isFilterMenuActive } from 'src/pages/pay/components/filters/utils';
import { BillStatus } from 'src/utils/consts';
import { BillType, Filters } from 'src/utils/types';

export const useShowZeroState = (
  bills: BillType[] | PayListItemType[],
  selectedTabBillStatus: BillStatus,
  filters: Filters
) => {
  const isSearchPerformed = !!filters.search;
  const isFilterPerformed = isFilterMenuActive(filters);
  const isHideActionButton = selectedTabBillStatus !== BillStatus.UNPAID || isSearchPerformed || isFilterPerformed;
  const showBillsZeroState =
    !isSearchPerformed && !isFilterPerformed && selectedTabBillStatus === BillStatus.UNPAID && bills.length === 0;

  return {
    showBillsZeroState,
    isHideActionButton,
    isSearchPerformed,
    isFilterPerformed,
  };
};
