import { useCallback } from 'react';
import { useQueryState } from './useQueryState';

const getIdsArrFromQueryIds = (queryIds?: string) => queryIds?.split(',') || [];

export function useQueryIds(name = 'ids', replace = true) {
  const [queryIds, setQueryIds] = useQueryState(name, undefined, replace);
  const setIdValue = useCallback(
    (id: string, value: boolean) => {
      const ids = getIdsArrFromQueryIds(queryIds);
      const found = ids.indexOf(id);

      if (found !== -1 && !value) {
        ids.splice(found, 1);
      } else if (found === -1 && value) {
        ids.push(id);
      }

      setQueryIds(ids.join(','));
    },
    [setQueryIds, queryIds]
  );
  const clear = useCallback((): void => {
    setQueryIds(undefined);
  }, [setQueryIds]);
  const setListValue = useCallback(
    (list: string[]): void => {
      setQueryIds(list.join(','));
    },
    [setQueryIds]
  );

  return [getIdsArrFromQueryIds(queryIds), setIdValue, setListValue, clear] as const;
}
