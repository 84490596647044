import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { Link } from 'src/core/ds/link';
import { Modal } from 'src/core/ds/modal';
import { HStack } from 'src/core/ds/stack';
import {
  financingAnalyticsEvents,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { learnMoreListOfOwnersRequired } from 'src/pages/bill/pay/installments/consts';
import { analytics } from 'src/services/analytics';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
  companyName: string;
};

export const OwnershipRequiredModal = ({ isOpen, onClose, onConfirm, companyName }: Props) => {
  useEffect(() => {
    if (isOpen) analytics.track(financingPage.ownerDecision, financingAnalyticsEvents.proofOfOwnershipModal);
  }, [isOpen]);
  const learnMore = (
    <Link href={learnMoreListOfOwnersRequired} isExternal>
      <MIFormattedText label="general.learnMore" />
    </Link>
  );
  const bodyLabelValues = {
    companyName,
    learnMore,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="sm"
      closeOnOverlayClick={false}
      closeOnEsc={false}
      preserveScrollBarGap
    >
      <Modal.Header>
        <Modal.HeaderTitle>
          <Box textStyle="h2semi">
            <MIFormattedText label="financing.ownershipRequiredModal.title" />
          </Box>
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton />
      <Modal.Body>
        <Box textStyle="body2">
          <MIFormattedText label="financing.ownershipRequiredModal.content" values={bodyLabelValues} />
        </Box>
      </Modal.Body>
      <Modal.Footer>
        <Box mt={2}>
          <HStack spacing={2}>
            <Flex w="full" justifyContent="right">
              <Button
                onClick={onClose}
                size={ButtonSizes.lg}
                variant={ButtonVariants.tertiaryNaked}
                label="dialogs.buttons.cancel"
              />
              <Button
                onClick={onConfirm}
                size={ButtonSizes.lg}
                variant={ButtonVariants.primary}
                label="financing.ownershipRequiredModal.buttons.payAnotherWay"
              />
            </Flex>
          </HStack>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};
