import { ReactComponent as LeftArrow } from 'src/images/core/left-arrow.svg';
import { PaginationIconButton } from './PaginationIconButton';

type Props = {
  previousPage: () => void;
  canPreviousPage?: boolean;
};

export const Previous = ({ previousPage, canPreviousPage }: Props) => (
  <>
    <PaginationIconButton
      ariaLabel="previous-page"
      onClick={previousPage}
      icon={<LeftArrow />}
      disabled={!canPreviousPage}
      testId="previous-page"
    />
  </>
);
