import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Image } from 'src/core/ds/image';
import InfoImage from 'src/images/core/info-icon.svg';
import SuccessImage from 'src/images/core/success-icon.svg';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';

type Props = {
  state: VirtualCardPaymentState;
  isDeposited?: boolean;
};

export const VirtualCardCompletedViewHeader = ({ state, isDeposited }: Props) => {
  const { payment, organization } = state;

  return (
    <>
      {isDeposited ? <Image width={8} height={8} src={SuccessImage} /> : <Image width={8} height={8} src={InfoImage} />}
      <Box textStyle="body2Semi" mt={4} mb={2} data-testid="title">
        <MIFormattedText
          label={
            isDeposited ? 'vendors.virtualCard.deposited.title' : 'vendors.virtualCard.changedDeliveryMethod.title'
          }
        />
      </Box>
      <Box textStyle="body2" data-testid="description">
        <MIFormattedText
          label={
            isDeposited
              ? 'vendors.virtualCard.deposited.description'
              : 'vendors.virtualCard.changedDeliveryMethod.description'
          }
          values={{
            payorName: organization?.companyName,
            amount: <MIFormattedCurrency value={payment?.amount} />,
            date: <MIFormattedDate date={payment?.deliveryMethod?.updatedAt} />,
          }}
        />
      </Box>
    </>
  );
};
