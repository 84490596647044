import { CardPciDataCardDetails } from '@melio/spend-management-api-axios-client';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { cardsApi } from '../api';
import { CardPciParams, UseCardPciOptions } from '../types';

export const useCardPci = () => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: getCardPciData, result, loading, error } = useApi<[CardPciParams], CardPciDataCardDetails>({
    api: cardsApi.pci,
  });

  return {
    loading,
    error,
    cardPciData: result,
    refetch: ({ cardId }: UseCardPciOptions) => getCardPciData({ orgId, cardId }),
  };
};
