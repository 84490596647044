const radii = {
  none: '0',
  sm: '0.2rem',
  md: '0.4rem',
  lg: '0.8rem',
  xl: '1.8rem',
  full: '50%',
} as const;

export type Radii = typeof radii;

export default radii;
