import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { Image } from 'src/core/ds/image';
import GoogleLogo from './btn_google_light_normal_ios.svg';

export type LoginWithGoogleProps = {
  onClick: () => any;
};
export function LoginWithGoogleButton({ onClick }: LoginWithGoogleProps) {
  const googleRequiredStyle = {
    backgroundColor: 'transparent',
    border: '2px solid #A6AEBE',
    pl: '0px',
    pr: '16px',
    color: 'black',
    height: '48px',
    _hover: {
      backgroundColor: '#EEEEEE',
    },
    _active: {
      backgroundColor: '#DDDDDD',
    },
  };

  return (
    <Button onClick={onClick} sx={googleRequiredStyle} size={ButtonSizes.lg}>
      <Image src={GoogleLogo} verticalAlign="middle" />
      <MIFormattedText label="auth.google.title" />
    </Button>
  );
}
