import { featureFlags } from '@melio/shared-web';
import { differenceInBusinessDays, isSameDay } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { PaymentDatePicker } from 'src/components/common/datepicker/PaymentDatePicker';
import {
  renderDayContentsCustomFinancing,
  renderDayContentsCustomTodayCutoffOver,
  renderDayContentsCustomWithLabel,
} from 'src/components/common/datepicker/utils';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { BillOverdueNotification } from 'src/pages/bill/pay/components/BillOverdueNotification';
import { ChecksDelaysNotification } from 'src/pages/bill/pay/components/ChecksDelays/ChecksDelaysNotification';
import { PayBillDateCustomFeed } from 'src/pages/bill/pay/components/PayBillDateCustomFeed';
import { analytics } from 'src/services/analytics';
import { DeliveryType, EXPEDITED_DELIVERY_TYPES, FastCheckDeliveryType, FundingType } from 'src/utils/consts';
import { FeatureFlags } from 'src/utils/featureFlags';
import { isPaymentBulkPayment } from 'src/utils/payments';
import { AccountType, BillType, DeliveryOptionType, PaymentType } from 'src/utils/types';
import { SelectProps } from '../FastPaymentOfferPage/FastPaymentOfferPage';

type Props = {
  onNext: () => void;
  onPrev: () => void;
  goExit: () => void;
  onSelectDeliveryOption?: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date, type?: string) => void;
  deliveryOptions?: DeliveryOptionType[];
  onChange: (date: Date) => void;
  bill: BillType;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  deliveryMethodType?: DeliveryType;
  minScheduledDate?: Date;
  maxScheduledDate?: Date;
  scheduledDate: Date;
  isLoading?: boolean;
  headerLabel: string;
  title: string;
  subtitle?: string;
  relativeStep?: number;
  titleValues?: Record<string, any>;
  selectedFundingSource?: AccountType | null;
  fundingSourceType?: string;
  isFinancingPayment?: boolean;
  payment?: PaymentType;
};

const eventPage = 'pay-bill';

export const PayBillDatePage = ({
  payment,
  bill,
  deliveryDate,
  deliveryMethodType,
  scheduledDate,
  selectedFundingSource,
  onChange,
  deliveryOptions,
  onNext,
  onPrev,
  isLoading = false,
  goExit,
  minScheduledDate,
  maxScheduledDate,
  maxDeliveryDate,
  onSelectDeliveryOption,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  subtitle,
  isFinancingPayment = false,
  fundingSourceType,
}: Props) => {
  const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.USHolidaysChecks, false);
  const [isBankCutoffTimesEnabled] = featureFlags.useFeature(FeatureFlags.BanksCutoffTimes, false);
  const [isFastACHBannerEnabled] = featureFlags.useFeature(FeatureFlags.FastAchBanner, false);
  const [isFastAchBetterExposeEtaEnabled] = featureFlags.useFeature(FeatureFlags.FastAchBetterExposeEta, false);
  featureFlags.useFeature(FeatureFlags.ShouldUseEtaApiServiceForDeliveryDate, false); // called to report the variant event
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<string | null>(null);
  const [deliveryDateValue, setDeliveryDateValue] = useState<Date>(deliveryDate);
  const [isContectCard, setIsContectCard] = useState<boolean>(false);

  const isCheckDM = deliveryMethodType === DeliveryType.CHECK;
  const isAchDM = deliveryMethodType === DeliveryType.ACH;
  const isFundingSourceACH = fundingSourceType === FundingType.ACH;
  const isBankAccountVerified = isFundingSourceACH && selectedFundingSource?.isVerified;
  const showChecksDelaysNotification = isChecksDelaysEnabled && isCheckDM && deliveryOptions;
  const hasFastCheck = deliveryOptions?.some((el) => el.type === FastCheckDeliveryType.EXPRESS);
  const hasFastOptions = deliveryOptions && deliveryOptions.length > 1;

  const isPaymentWillBeLate = differenceInBusinessDays(new Date(deliveryDateValue), new Date(bill.dueDate)) > 0;
  const isBillOverdueNotificationShown =
    isFastACHBannerEnabled && isFundingSourceACH && isPaymentWillBeLate && hasFastOptions;
  const isBulkPayment = payment && isPaymentBulkPayment(payment);
  const dueDate = isBulkPayment ? null : new Date(bill.dueDate);
  const hasCheckOrAchDM = isCheckDM || isAchDM;
  const datePickerSubtitle = isFastAchBetterExposeEtaEnabled ? 'bills.pay.date.subtitleFachVariant' : subtitle;
  const sortedDeliveryOptions = useMemo(() => (hasCheckOrAchDM ? deliveryOptions?.reverse() : deliveryOptions), [
    deliveryOptions,
    hasCheckOrAchDM,
  ]);

  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={bill.totalAmount} />
      </strong>
    ),
    companyName: <strong>{bill.vendor?.companyName}</strong>,
  };

  const trackNotificationEvent = () => {
    if (hasFastOptions && isPaymentWillBeLate) {
      analytics.track(eventPage, 'bill-overdue-notification-shown', {
        isBillOverdueNotificationShown,
        billId: bill.id,
      });
    }
  };

  const trackPageLoadEvent = () =>
    analytics.page(eventPage, 'date', {
      billId: bill.id,
      dueDate: dueDate?.toISOString(),
      scheduledDate: scheduledDate?.toISOString(),
      maxDeliveryDate: maxDeliveryDate?.toISOString(),
      isFastAchBetterExposeEtaEnabled,
      isPaymentWillBeLate,
    });

  useEffect(() => {
    trackNotificationEvent();
    trackPageLoadEvent();
  }, []);

  useEffect(() => {
    if (selectedDeliveryOption !== EXPEDITED_DELIVERY_TYPES[0]) {
      setDeliveryDateValue(deliveryDate);
    }
  }, [deliveryDate, selectedDeliveryOption]);

  const onDateChange = ({ value }) => {
    if (isSameDay(value, new Date(scheduledDate))) {
      return;
    }

    if (!isContectCard) {
      setIsContectCard(true);
    }

    onChange(value);
  };

  // eslint-disable-next-line max-params
  const onSelectDeliveryOptionChoice = ({ scheduledDate, deliveryDate, maxDeliveryDate, type }: SelectProps) => {
    if (type) {
      setSelectedDeliveryOption(type);
    }

    if (onSelectDeliveryOption) {
      onSelectDeliveryOption(scheduledDate, deliveryDate, maxDeliveryDate, type);
    }
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  let renderDayContentsCustom;

  if (isFinancingPayment) {
    renderDayContentsCustom = (day, date) =>
      renderDayContentsCustomFinancing({
        day,
        date,
        maxDate: maxScheduledDate,
        scheduledDate,
        dueDate: bill.dueDate,
        deliveryDate,
        isFastAchBetterExposeEtaEnabled,
      });
  } else if (isBankCutoffTimesEnabled && isBankAccountVerified) {
    renderDayContentsCustom = (day, date) =>
      renderDayContentsCustomTodayCutoffOver(
        day,
        date,
        minScheduledDate,
        scheduledDate,
        bill.dueDate,
        deliveryDate,
        isFastAchBetterExposeEtaEnabled
      );
  } else if (isFastAchBetterExposeEtaEnabled) {
    renderDayContentsCustom = (day, date) =>
      renderDayContentsCustomWithLabel({
        day,
        date,
        scheduledDate,
        dueDate,
        deliveryDate,
      });
  }

  return (
    <StepLayoutPage
      headerLabel={headerLabel}
      headerLabelValues={headerLabelValues}
      title={title}
      titleValues={titleValues}
      subtitle={datePickerSubtitle}
      goExit={goExit}
      onPrev={onPrev}
      onNext={onNext}
      isLoading={isLoading}
      innerSize={100}
      relativeStep={relativeStep}
      testId="date-page"
    >
      <WizardElements.InnerWizardContentWrapper>
        {showChecksDelaysNotification ? <ChecksDelaysNotification hasFastCheck={hasFastCheck} /> : null}
        {isBillOverdueNotificationShown ? <BillOverdueNotification /> : null}
        {isContectCard && <PayBillDateCustomFeed />}
        <PaymentDatePicker
          fundingSource={selectedFundingSource}
          deliveryDate={deliveryDate}
          maxDeliveryDate={maxDeliveryDate}
          deliveryMethodType={deliveryMethodType}
          scheduledDate={scheduledDate}
          totalAmount={bill.totalAmount ? bill.totalAmount.toString() : ''}
          minDate={minScheduledDate}
          maxDate={maxScheduledDate}
          dueDate={dueDate}
          onChange={onDateChange}
          deliveryOptions={sortedDeliveryOptions}
          onSelectDeliveryOption={onSelectDeliveryOptionChoice}
          renderDayContentsCustom={renderDayContentsCustom}
          fundingSourceType={fundingSourceType}
          isBillOverdueNotificationShown={isBillOverdueNotificationShown}
          isEligibleForFastAchAdoption={hasCheckOrAchDM}
          isFinancingPayment={isFinancingPayment}
          isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
        />
      </WizardElements.InnerWizardContentWrapper>
    </StepLayoutPage>
  );
};
