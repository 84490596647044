import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { HStack } from 'src/core/ds/stack';

type Props = {
  dismiss: (event?: React.MouseEvent) => void;
  onConfirm(): void;
};

export const NoOptInInvoiceGenerationModal = ({ dismiss, onConfirm }: Props) => (
  <ModalMessage
    alignLeft
    id="no-opt-in-invoice-generation-modal"
    titleComponent={
      <MIFormattedText label="settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInModal.title" />
    }
    contentComponent={
      <Box textStyle="body2">
        <MIFormattedText label="settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInModal.body" />
      </Box>
    }
    footerSubTitle={
      <HStack spacing={3} justify="flex-end" width="100%">
        <Button
          variant={ButtonVariants.tertiaryNaked}
          label="settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInModal.backButton"
          onClick={dismiss}
        />
        <Button
          variant={ButtonVariants.primary}
          label="settings.newAccountingSoftware.QuickbooksDesktopSync.invoiceGeneration.noOptInModal.confirmButton"
          onClick={onConfirm}
        />
      </HStack>
    }
  />
);
