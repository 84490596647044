import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateIsPolling } from 'src/modules/accounting-platforms/accounting-platforms-actions';

type PollingSyncStatusProps = {
  getIsSyncing: () => Promise<boolean>;
  interval: number;
  autoStartPolling?: boolean;
  isSyncingDefault?: boolean;
  onSyncCompleted?: () => Promise<void>;
};

type PollingSyncStatusReturn = { startPollingStatus: () => void; isSyncing: boolean };

// TODO: rename to generral polling function that works not only for sync status

export const usePollingSyncStatus = ({
  getIsSyncing,
  interval,
  autoStartPolling = true,
  isSyncingDefault = false,
  onSyncCompleted,
}: PollingSyncStatusProps): PollingSyncStatusReturn => {
  const dispatch = useDispatch();
  const [isSyncing, setIsSyncing] = useState(isSyncingDefault);
  const syncStatusTimeout = useRef<number | null>(null);
  const startSyncStatusTimeout = () => {
    syncStatusTimeout.current = setTimeout(updateSyncStatus, interval);
  };
  const stopSyncStatusTimeout = () => {
    if (syncStatusTimeout.current) {
      clearTimeout(syncStatusTimeout.current);
      syncStatusTimeout.current = null;
    }
  };
  const updateSyncStatus = async () => {
    try {
      const isSyncingResult = await getIsSyncing();
      setIsSyncing(isSyncingResult);

      if (isSyncingResult) {
        startSyncStatusTimeout();
      }

      if (!isSyncingResult) {
        dispatch(updateIsPolling(false));

        if (onSyncCompleted) {
          await onSyncCompleted();
        }
      }
    } catch (e) {
      setIsSyncing(false);
    }
  };

  useEffect(() => {
    if (autoStartPolling) {
      updateSyncStatus();
    }

    return stopSyncStatusTimeout;
  }, []);
  const startPollingStatus = () => {
    dispatch(updateIsPolling(true));
    setIsSyncing(true);
    startSyncStatusTimeout();
  };

  return {
    startPollingStatus,
    isSyncing,
  };
};
