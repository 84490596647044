import { MenuItemPropsType } from 'src/components/layout/SideMenu/types/type';
import { RegistrationFlow } from 'src/utils/consts';

export function orderMainMenuItems(
  getPaidMenu: Array<MenuItemPropsType>,
  payMenu: Array<MenuItemPropsType>,
  registrationFlow?: RegistrationFlow
) {
  return registrationFlow === RegistrationFlow.VENDOR ? [getPaidMenu, payMenu] : [payMenu, getPaidMenu];
}
