import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { ReactComponent as MobileAppPromotion } from 'src/images/mobile-app/promotion.svg';
import { FormSubtitle } from 'src/ui/form/FormElements';

type Props = {
  handleSubmit: () => void;
  goToDashboard: () => void;
};

export const MobileAppPromotionPage = ({ handleSubmit, goToDashboard }: Props) => (
  <MobileAppPromotionMainPageContainer>
    <FormSubtitle>
      <FormSubtitleWrapper>
        <MIFormattedText label="onboarding.mobileAppPromotion.title" />
      </FormSubtitleWrapper>
    </FormSubtitle>
    <PromotionImageContainer>
      <Box display="flex" alignItems="center" justifyContent="center">
        <MobileAppPromotion />
      </Box>
    </PromotionImageContainer>
    <ButtonsContainer>
      <Box>
        <Button
          onClick={handleSubmit}
          label="onboarding.mobileAppPromotion.download"
          size={ButtonSizes.lg}
          isFullWidth
        />
      </Box>
      <Box>
        <Button
          onClick={goToDashboard}
          label="onboarding.mobileAppPromotion.continueOnWebsite"
          variant={ButtonVariants.tertiaryNaked}
          size={ButtonSizes.lg}
          isFullWidth
          mt={3}
        />
      </Box>
    </ButtonsContainer>
  </MobileAppPromotionMainPageContainer>
);

const MobileAppPromotionMainPageContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: inherit;
  flex-direction: column;
  max-width: ${(props) => (props.manually ? '45rem' : '40rem')};
`;

const PromotionImageContainer = styled.div`
  padding: 2rem 0 2rem 0;
`;

const ButtonsContainer = styled.div`
  text-align: center;
  position: relative;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 0 2rem 0;
  box-sizing: border-box;
`;

const FormSubtitleWrapper = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin: 2rem 3rem 0;
`;
