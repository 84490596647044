import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { paymentsApi } from 'src/modules/payments/api';

const name = 'payments';

export const refundPaymentSlice = createApiCallSlice({
  name: generateSliceName(name, 'refund_payment'),
  api: paymentsApi.refundPayment,
  initialState: {
    refundPayment: {
      loading: false,
      error: null,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.refundPayment.loading = true;
      state.refundPayment.error = null;
    },
    [ON_FAILURE]: (state, action) => {
      state.refundPayment.loading = false;
      state.refundPayment.error = action.error;
    },
    [ON_SUCCESS]: (state) => {
      state.refundPayment.loading = false;
      state.refundPayment.error = null;
    },
  },
  selectors: {
    loading: (state) => state[name].refundPayment.loading,
  },
});
