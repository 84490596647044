/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import { EventMappingsTree } from 'src/services/analytics/types';

const MAPPING: EventMappingsTree = {
  '/orgs/*/vendors/*/receiving-methods/ach/select': {
    'page.view': ['onboarding-vendor-delivery-methods', 'bank-verification-type-select'],
    'onboarding.deliveryMethods.bank.verification.plaid.label': ['bank-add-variants', 'add-plaid'],
    'onboarding.deliveryMethods.bank.verification.manual.label': ['bank-add-variants', 'add-manual'],
    'go.exit': ['bank-add-variants', 'exit'],
    'go.back': ['bank-add-variants', 'back'],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/exists': {
    'page.view': ['onboarding-vendor-delivery-methods', 'bank-verification-type-select'],
    'onboarding.deliveryMethods.bank.verifyCta': ['receiving-method-exists', 'continue'],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/plaid': {
    'page.view': ['onboarding-vendor-delivery-methods', 'plaid-bank-delivery-method'],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/cant-find': {
    'page.view': ['onboarding-vendor-delivery-methods', 'bank-cant-find'],
    'onboarding.fundingSources.bank.cantFind.next': ['bank-add-cant-find', 'delivery-method-cant-find-bank'],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/manual': {
    'page.view': ['onboarding-vendor-delivery-methods', 'add-bank-delivery-method'],
    'form-validation-error': [
      'vendor-company-delivery-method',
      'add-delivery-method-validation-error',
      { validationsErrors: 'validationErrors' },
    ],
    'go.exit': ['vendor-delivery-method', 'exit'],
    'go.back': ['vendor-delivery-method', 'back'],
    'add-ach-receiving-method': ['vendor-company-delivery-method', 'add-delivery-method', { type: 'type' }],
    'add-ach-receiving-method-success': [
      'vendor-company-delivery-method',
      'add-delivery-method-success',
      { type: 'type' },
    ],
    'add-ach-receiving-method-failure': [
      'vendor-company-delivery-method',
      'add-delivery-method-failure',
      { type: 'type', error: 'error' },
    ],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/link-bank-account/*': {
    'page.view': ['onboarding-vendor-delivery-methods', 'link-bank-account'],
    'error-load-bank-accounts': ['onboarding-vendor-delivery-methods', 'error-load-and-link-bank-account'],
    'create-and-link-first-bank-account': ['onboarding-vendor-delivery-methods', 'create-and-link-first-bank-account'],
    'create-and-link-bank-account': ['vendor-company-delivery-method', 'create-and-link-bank-account'],
    'link-bank-account': ['vendor-company-delivery-method', 'link-to-existing-bank-account'],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/manual/*': {
    'page.view': ['onboarding-vendor-delivery-methods', 'view-bank-account'],
    'go.exit': ['vendor-delivery-method-view-bank-account', 'exit'],
    'go.prev': ['vendor-delivery-method-view-bank-account', 'back'],
  },
};

export default MAPPING;
