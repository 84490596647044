import { featureFlags } from '@melio/shared-web';
import { RefObject, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { BlockedForPaymentDialog } from 'src/components/common/BlockedForPaymentDialog';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { PageHeader } from 'src/components/common/PageHeader';
import { TextField } from 'src/core/ds/form/fields';
import { useBreak } from 'src/hoc';
import { useOrgId } from 'src/hooks';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getPaymentsActions, paymentsStore } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { eventPage } from 'src/pages/auth/login';
import { BillDetailsHeader } from 'src/pages/bill/components/BillDetailsHeader';
import { getBillHeaderActionsOptions } from 'src/pages/bill/components/ViewBill/utils';
import { billLocations, FilteredViewLocation } from 'src/pages/bill/locations';
import { billPayLocations } from 'src/pages/bill/pay/locations';
import { isDirectoryVendor } from 'src/pages/vendor-directory/utils';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';
import { FormContainer } from 'src/ui/form/FormElements';
import { getActionOptions, getBillPaymentIndex, getVendorCompanyName } from 'src/utils/bills';
import {
  BillStatus,
  DeliveryType,
  FeatureFlags,
  PaymentApprovalStatus,
  PaymentStatus,
  TagVariant,
} from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { BillType, PaymentType } from 'src/utils/types';
import { ViewPaymentHeaderSubtitle } from './ViewPaymentHeaderSubtitle';

type Props = {
  payment: PaymentType;
  bill: BillType;
  actionOptions?: ReturnType<typeof getActionOptions>;
  status?: TagVariant | BillStatus | PaymentStatus | PaymentApprovalStatus;
  recurringBill: BillType | null;
  billsGalleryRef: RefObject<HTMLDivElement>;
  pathname: string;
  search: string;
  generateBillPath: (pattern: FilteredViewLocation, extraParams?: Parameters<typeof generatePath>[1]) => string;
};

export const ViewPaymentHeader = ({
  payment,
  bill,
  actionOptions,
  status,
  recurringBill,
  billsGalleryRef,
  pathname,
  search,
  generateBillPath,
}: Props) => {
  const [enableApprovalWorkflows] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);
  const [enableRescheduleDeclinedApprovalBulkPayment] = featureFlags.useFeature(
    FeatureFlags.EnableRescheduleDeclinedApprovalBulkPayment,
    false
  );
  const [financingRetryFailedToDeliver] = featureFlags.useFeature(FeatureFlags.FinancingRetryFailedToDeliver, false);
  const [financingRecoveryFlowsQa] = featureFlags.useFeature(FeatureFlags.FinancingRecoveryFlowsQa, false);
  const approvalDecisionStatus = useSelector(paymentsStore.selectors.approvalDecisionStatus);
  const scheduledBy = useSelector(usersStore.selectors.byId(payment?.createdById));
  const currentUser = useSelector(profileStore.selectors.profile);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const vendorPaymentPreferences = useSelector((state) =>
    vendorsStore.selectors.checkVendorPaymentPreferences.item(state, payment?.vendorId)
  );

  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [isVendorBlockedForPayment, setIsVendorBlockedForPayment] = useState<boolean>(false);

  const { navigate } = useNavigator();
  const orgId = useOrgId();
  const dispatch = useDispatch();
  const device = useBreak();

  const { approvePayment, declinePayment, startSinglePaymentFlow } = getPaymentsActions(dispatch);
  const isFullSingleView = device.isMobile || device.isPhablet;
  const showDirectoryVendorLogo = isDirectoryVendor({
    ...bill?.vendor,
    deliveryMethods: bill?.vendor?.deliveryMethods ?? [],
  });

  const goViewDetails = () => {
    if (billsGalleryRef.current) {
      billsGalleryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onRetryPayment = (paymentId: number) => {
    analytics.track(eventPage, 'retry-payment', {
      paymentId,
      billIds: bill?.id,
      failedReason: payment?.collectFailureData?.failureReason,
      failedType: payment?.metadata?.failedType,
      failureMessage: payment?.metadata?.failureMessage,
    });
    navigate(
      generatePath(billLocations.pay.edit.funding, {
        orgId,
        billId: bill.id,
        paymentId,
      })
    );
  };

  const onRetryPaymentDelivery = (paymentId: number, deliveryMethodId?: number) => {
    analytics.track(eventPage, 'retry-payment-delivery');
    navigate(
      generatePath(billLocations.pay.edit.deliveryMethodAch, {
        orgId,
        billId: bill.id,
        paymentId,
        deliveryMethodId,
      })
    );
  };

  const onChangeDeliveryMethod = (paymentId: number) => {
    analytics.track(eventPage, 'change-delivery-method', { billId: bill.id, paymentId });
    navigate(generatePath(billLocations.pay.edit.deliveryMethod, { orgId, billId: bill.id, paymentId }));
  };

  const onResendVirtualCard = (paymentId) => {
    analytics.track(eventPage, 'resend-virtual-card', { billId: bill.id, paymentId });
    navigate(generatePath(billPayLocations.edit.virtualCardRecovery, { orgId, billId: bill.id, paymentId }));
  };

  const onRetryReturnedCheck = (paymentId: number) => {
    analytics.track(eventPage, 'resend-returned-check', {
      billId: bill.id,
      paymentId,
      deliveryType: DeliveryType.CHECK,
      partialBillId: getBillPaymentIndex(bill),
    });
    navigate(generatePath(billPayLocations.edit.returnedCheckRecovery, { orgId, billId: bill.id, paymentId }));
  };

  const onResendUndepositedCheck = (paymentId: number) => {
    analytics.track(eventPage, 'resend-undeposited-check', {
      billId: bill.id,
      paymentId,
      deliveryType: DeliveryType.CHECK,
      partialBillId: getBillPaymentIndex(bill),
    });
    navigate(generatePath(billPayLocations.edit.resendUndepositedCheck, { orgId, billId: bill.id, paymentId }));
  };

  const onDecline = () => {
    setShowDeclineModal(true);
  };

  const onClickSupport = () => {
    intercomService.show();
    analytics.track('bills', 'payment-failed-contact-support');
  };

  const onApproveDecision = async (status: string, reason?: string | null) => {
    if (status === 'approved') {
      await approvePayment({
        orgId,
        id: payment.id.toString(),
        reason,
      });
      navigate(generateBillPath(billLocations.filteredViewWithoutId));
    } else {
      await declinePayment({
        orgId,
        id: payment.id.toString(),
        reason,
      });
    }
  };

  const onApprove = () => {
    onApproveDecision(PaymentApprovalStatus.APPROVED);
  };

  const goPayBill = async () => {
    analytics.track(eventPage, 'pay-bill');

    if (vendorPaymentPreferences?.blockPayments) {
      setIsVendorBlockedForPayment(true);
    } else {
      startSinglePaymentFlow({ billId: bill.id, orgId });
    }
  };

  const onCloseBlockPaymentDialog = () => setIsVendorBlockedForPayment(false);

  const onTrackDelivery = () => {
    analytics.track(eventPage, 'track-delivery');
    const trackingUrl = payment?.checkTracks?.[0]?.trackingUrl;

    if (trackingUrl) {
      window.open(trackingUrl);
    }
  };

  const renderDeclineModalDialog = () => {
    if (!showDeclineModal) {
      return null;
    }

    const onDecline = () => {
      onApproveDecision(PaymentApprovalStatus.DECLINED, declineReason);
      onClose();
    };
    const onKeyPressed = (event: React.KeyboardEvent) => {
      if (isEnterPressed(event)) {
        onDecline();
        event.preventDefault();
      }
    };
    const onClose = () => setShowDeclineModal(false);
    const handleDeclineReason = (event: React.ChangeEvent<HTMLInputElement>) =>
      setDeclineReason(event.currentTarget.value);

    return (
      <ModalMessage
        id="decline-payment-modal"
        titleComponent={
          <>
            <div className="title">
              <MIFormattedText
                label="bills.form.declineDialog.title"
                values={{
                  userName: scheduledBy?.firstName,
                }}
              />
            </div>
          </>
        }
        contentComponent={
          <FormContainer onKeyDown={onKeyPressed}>
            <TextField
              id="declineNote"
              label="bills.form.declineDialog.noteTitle"
              placeholder="bills.form.declineDialog.placeholder"
              value={declineReason}
              autoFocus
              onChange={handleDeclineReason}
            />
          </FormContainer>
        }
        buttonLabel="bills.form.declineDialog.buttonLabel"
        onButtonClick={onDecline}
        isLoading={approvalDecisionStatus.loading}
        onCloseClick={onClose}
      />
    );
  };

  const headerSubtitle = <ViewPaymentHeaderSubtitle payment={payment} bill={bill} recurringBill={recurringBill} />;
  const isApproving = approvalDecisionStatus.loading;

  const headerActions =
    bill &&
    payment &&
    getBillHeaderActionsOptions({
      bill,
      payment,
      currentUserId: currentUser.id,
      permissions,
      onRetryPayment,
      onRetryPaymentDelivery,
      onChangeDeliveryMethod,
      onResendVirtualCard,
      onRetryReturnedCheck,
      onResendUndepositedCheck,
      onClickSupport,
      onDecline,
      onApprove,
      goPayBill,
      onTrackDelivery,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onRejectPaymentRequest: () => {},
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSchedulePaymentRequest: () => {},
      isLoading: false,
      isEditMode: false,
      canSchedulePayment: false,
      isApproving,
      enableApprovalWorkflows,
      enableRescheduleDeclinedApprovalBulkPayment,
      financingRetryFailedToDeliver,
      financingRecoveryFlowsQa,
    });

  return (
    <>
      {isVendorBlockedForPayment && <BlockedForPaymentDialog onCancelAction={onCloseBlockPaymentDialog} />}
      {renderDeclineModalDialog()}
      {isFullSingleView && (
        <PageHeader
          backNav={{
            pathname,
            search,
          }}
          text={getVendorCompanyName(bill)}
          subTitle={headerSubtitle}
          actionOptions={actionOptions}
          isPartialPayments
          goViewDetails={goViewDetails}
        >
          <BillDetailsHeader
            companyName={getVendorCompanyName(bill)}
            isFullSingleView={isFullSingleView}
            description={headerSubtitle}
            status={status}
            headerActions={headerActions}
          />
        </PageHeader>
      )}
      {!isFullSingleView && (
        <BillDetailsHeader
          companyName={getVendorCompanyName(bill)}
          isFullSingleView={isFullSingleView}
          description={headerSubtitle}
          status={status}
          headerActions={headerActions}
          showViewDetailsLink
          goViewDetails={goViewDetails}
          showDirectoryVendorLogo={showDirectoryVendorLogo}
        />
      )}
    </>
  );
};
