import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Link } from 'src/core/ds/link';
import { NotificationVariant } from 'src/core/ds/toast';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { getUserPreferences } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { capture } from 'src/utils/error-tracking';
import { MultipleOrganizationsRadioGroupList } from './MultipleOrganizationsRadioGroupList';
import { SingleOrganization } from './SingleOrganization';

export const ExistingOrganizationsPage = () => {
  const { organizations, id } = useSelector(profileStore.selectors.profile);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(organizations[0].id);
  const { navigate } = useNavigator();
  const dispatch = useDispatch();
  const site = useSiteContext();
  const analyticsData = { msnOptinFlow: true, isGuest: false, flows: 'public' };

  const { msnEmptyOrganizationCreated } = useSelector(getUserPreferences);

  const filteredOrganizations = organizations.filter(({ id }) => id.toString() !== msnEmptyOrganizationCreated);
  const isMultipleOrganizations = filteredOrganizations.length > 1;

  const { onApiCall: createOrganization } = useApi({
    api: msnPortalApi.createOrganization,
  });

  const initUserOrganization = (orgId) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  const onNext = async () => {
    setIsLoading(true);
    await initUserOrganization(selectedOrganizationId);
    navigate(
      generatePath(globalLocations.onboarding.msnRegistration.msnVerificationStatusCheck, {
        orgId: selectedOrganizationId,
      }),
      false,
      {
        exitUrl: globalLocations.onboarding.msnRegistration.existingOrganizations,
      }
    );
  };

  const handleAddNewCompany = async () => {
    setIsLoading(true);
    try {
      const { organization } = await createOrganization(id);
      await initUserOrganization(organization.id);
      analytics.trackAction('msnPortalExistingOrganizations.addNewOrganization', analyticsData);
      navigate(
        generatePath(globalLocations.onboarding.msnRegistration.contactDetails, { orgId: organization.id }),
        false,
        {
          exitUrl: globalLocations.onboarding.msnRegistration.existingOrganizations,
        }
      );
    } catch (err) {
      setIsLoading(false);
      pushNotification({
        type: NotificationVariant.error,
        msg: 'onboarding.msnCompanyInfo.generalError.text',
        textValues: {
          contactSupport: (
            <Link borderBottom="1px" color="white" href={`mailto:${site.config.support.email}`}>
              <MIFormattedText label="onboarding.msnCompanyInfo.generalError.contactSupport" />
            </Link>
          ),
        },
        autoClose: false,
      });
      capture(err as Error);
      analytics.trackAction('msnPortalExistingOrganizations.error', analyticsData);
    }
  };

  return isLoading ? (
    <AreaLoader />
  ) : (
    <StepLayoutPage
      title="onboarding.msnCompanyInfo.existingOrganizations.title"
      subtitle="onboarding.msnCompanyInfo.existingOrganizations.subtitle"
      footer={
        <Button
          onClick={handleAddNewCompany}
          variant={ButtonVariants.tertiaryNaked}
          label="onboarding.msnCompanyInfo.existingOrganizations.addCompany"
          color="black"
          textStyle="body2Semi"
          pt={6}
          textAlign="center"
        />
      }
      onNext={onNext}
      nextButtonAnalyticsProperties={analyticsData}
    >
      <Box mt={10} mb={10}>
        {isMultipleOrganizations ? (
          <MultipleOrganizationsRadioGroupList
            organizations={filteredOrganizations}
            onSelect={setSelectedOrganizationId}
            selectedOrganizationId={selectedOrganizationId}
          />
        ) : (
          <SingleOrganization
            formattedAddress={filteredOrganizations[0].formattedAddress}
            city={filteredOrganizations[0].city}
            companyName={filteredOrganizations[0].companyName}
          />
        )}
      </Box>
    </StepLayoutPage>
  );
};
