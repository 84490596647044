import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import { FormRow } from 'src/core/ds/form';
import { TextField } from 'src/core/ds/form/fields';
import { InternalBusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { useFormGoogleAddress } from 'src/pages/bill/pay/installments/pages/application-details/hooks/useFormGoogleAddress';
import { useFormManualAddress } from 'src/pages/bill/pay/installments/pages/application-details/hooks/useFormManualAddress';
import { ModelView } from 'src/ui/form';
import { AddressFormControlMode } from 'src/utils/consts';

type Props = {
  businessOwnershipDetailsModelView: ModelView<InternalBusinessOwnershipDetailsModel>;
  errorMessages: {
    addressLine1?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
};

export const OwnerLegalAddressFormRows = ({ businessOwnershipDetailsModelView, errorMessages }: Props) => {
  const { handleAddressChange, address } = useFormGoogleAddress({
    modelView: businessOwnershipDetailsModelView,
  });
  const { hasIntentionForManualAddress, handleGoToManualAddress, handleManualType } = useFormManualAddress({
    modelView: businessOwnershipDetailsModelView,
  });

  return (
    <>
      <FormRow>
        <Flex direction="column" w="full">
          <MIAddressAutocomplete
            id="mainOwner-address"
            addressLabel="financing.businessOwnershipDetails.fields.address.title"
            addressPlaceholder="form.addressAutocomplete.addressPlaceholder"
            address={address}
            errorMessage={errorMessages?.addressLine1}
            onChange={handleAddressChange}
            onType={handleManualType}
            marginBottomOverride="0"
            required
            hideSuite
            mode={AddressFormControlMode.INLINE}
            showAddManualAddress={!hasIntentionForManualAddress && !address}
            goToManualAddress={handleGoToManualAddress}
          />
          {!errorMessages?.addressLine1 ? (
            <Box textStyle="body3" color="grey.600" mt={1}>
              <MIFormattedText label="financing.businessOwnershipDetails.fields.address.hint" />
            </Box>
          ) : null}
        </Flex>
      </FormRow>
      {address || hasIntentionForManualAddress ? (
        <>
          <FormRow>
            <TextField
              testId="input-mainOwner-city"
              label="financing.businessOwnershipDetails.fields.city.title"
              errorMessage={errorMessages?.city}
              isRequired
              mb={0}
              model={businessOwnershipDetailsModelView.city}
            />
            <TextField
              testId="input-mainOwner-state"
              label="financing.businessOwnershipDetails.fields.state.title"
              errorMessage={errorMessages?.state}
              isRequired
              mb={0}
              model={businessOwnershipDetailsModelView.state}
            />
          </FormRow>
          <FormRow>
            <TextField
              testId="input-mainOwner-zipCode"
              label="financing.businessOwnershipDetails.fields.zipCode.title"
              errorMessage={errorMessages?.zipCode}
              isRequired
              mb={0}
              model={businessOwnershipDetailsModelView.zipCode}
              type="number"
            />
          </FormRow>
        </>
      ) : null}
    </>
  );
};
