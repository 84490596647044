import { Tooltip } from '@chakra-ui/react';
import { Location } from 'history';
import * as React from 'react';
import { LottieProps } from 'src/components/common/Lottie';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCardProps } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { VStack } from 'src/core/ds/stack';
import { AnalyticsPropertiesType } from 'src/core/ds/types';
import { intercomService } from 'src/services/intercom';
import { WizardProgressBar } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { StepHeader } from './StepHeader';
import * as WizardElements from './WizardElements';

type Props = {
  headerLabel?: string;
  headerLabelValues?: Record<string, any>;
  title?: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
  subTitleValues?: Record<string, any>;
  exitLink?: string | Location;
  goExit?: () => void | null;
  children?: React.ReactNode;
  relativeStep?: number | null;
  onPrev?: () => void | null;
  isPrevDisabled?: boolean;
  onNext?: () => void | Promise<void> | null;
  nextLabel?: string;
  isNextDisabled?: boolean;
  nextTooltip?: Record<string, string> | null;
  onSubmit?: () => void | boolean | Promise<void> | null;
  isLoading?: boolean;
  innerSize?: number;
  isHideActions?: boolean;
  footer?: React.ReactNode | null;
  illustration?: IllustrationName;
  progressBarSteps?: string[];
  minorSteps?: number[];
  progressBarType?: WizardProgressBar;
  ctaVariant?: ButtonVariants;
  fullWidthCTA?: boolean;
  testId?: string | null;
  docked?: boolean;
  hideHeader?: boolean;
  isSandboxIndicatorShown?: boolean;
  contentWrapperMode?: string;
  qboFooter?: React.ReactNode | null;
  animatedIllustration?: LottieProps;
  notification?: MINotificationCardProps;
  displaySupportLink?: boolean;
  onSecondaryActionButtonClick?: () => void | boolean | Promise<void> | null;
  secondaryActionButtonLabel?: string;
  secondaryActionButtonVariant?: ButtonVariants;
  isCenterVert?: boolean;
  nextButtonAnalyticsProperties?: AnalyticsPropertiesType;
};

const triggerIntercom = () => intercomService.show();

const shadowRendererProps = {
  rendererSettings: {
    filterSize: {
      width: '200%',
      height: '200%',
      x: '-50%',
      y: '-50%',
    },
  },
};

export const StepLayoutPage = ({
  title = undefined,
  titleValues,
  subtitle,
  exitLink,
  goExit,
  children,
  relativeStep,
  onPrev,
  isPrevDisabled,
  onNext,
  nextLabel,
  isNextDisabled,
  nextTooltip,
  onSubmit,
  onSecondaryActionButtonClick,
  isLoading,
  innerSize = 54,
  isHideActions,
  footer,
  illustration,
  progressBarSteps = [],
  minorSteps = [],
  progressBarType = WizardProgressBar.WITHOUT_SUBSTEPS,
  subTitleValues,
  ctaVariant = ButtonVariants.primary,
  fullWidthCTA,
  testId,
  docked,
  animatedIllustration,
  notification,
  displaySupportLink = false,
  secondaryActionButtonLabel,
  secondaryActionButtonVariant = ButtonVariants.tertiaryNaked,
  isCenterVert = true,
  nextButtonAnalyticsProperties = {},
}: Props) => {
  const submitMethod = onSubmit || onNext;
  const nextActionLabel = nextLabel || 'progress.continue';
  const buttonContainerWidth = fullWidthCTA ? '45rem' : 'auto';

  // perform Next or Submit action on "Enter" key
  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (submitMethod && !isNextDisabled && isEnterPressed(event)) {
      submitMethod();
    }
  };

  const handleNextClick = () => {
    if (submitMethod) {
      submitMethod();
    }
  };

  const handleSecondaryActionButtonClick = () => {
    if (onSecondaryActionButtonClick) {
      onSecondaryActionButtonClick();
    }
  };

  const isSubtitle = !!subtitle;
  const hasIllustration = !!illustration;
  const containerTestId = testId ? `${testId}-container` : 'step-container';
  const stepTestId = testId ? `${testId}-title` : 'step-title';
  const subtitleTestId = testId ? `${testId}-subtitle` : 'step-subtitle';
  const contentTestId = testId ? `${testId}-content` : 'step-content';
  const lottieOptions = animatedIllustration && {
    ...animatedIllustration,
    ...shadowRendererProps,
  };

  const actionButtons = (
    <WizardElements.WizardButtonContainer isProgressBar width={buttonContainerWidth} fullWidthCTA={fullWidthCTA}>
      <VStack spacing={4}>
        {submitMethod && (
          <Tooltip
            data-testid="next-step-disabled-tooltip"
            isDisabled={!nextTooltip}
            placement="top"
            maxW="max"
            shouldWrapChildren
            label={
              <Box textAlign="start" px={2} py={1}>
                <MIFormattedText label={nextTooltip?.label} />
              </Box>
            }
          >
            <Button
              label={nextActionLabel}
              maxW="full"
              variant={ButtonVariants[ctaVariant]}
              size={ButtonSizes.lg}
              onClick={handleNextClick}
              isLoading={isLoading}
              isDisabled={isNextDisabled}
              isFullWidth={!docked}
              analyticsProperties={nextButtonAnalyticsProperties}
            />
          </Tooltip>
        )}
        {onSecondaryActionButtonClick && secondaryActionButtonLabel && (
          <Button
            maxW="full"
            label={secondaryActionButtonLabel || ''}
            variant={secondaryActionButtonVariant}
            size={ButtonSizes.lg}
            onClick={handleSecondaryActionButtonClick}
            isFullWidth={!docked}
          />
        )}
      </VStack>
    </WizardElements.WizardButtonContainer>
  );

  return (
    <WizardElements.WizardContainer onKeyDown={onKeyPressed} data-testid={containerTestId}>
      <StepHeader
        onPrev={onPrev}
        exitLink={exitLink}
        goExit={goExit}
        relativeStep={relativeStep}
        isPrevDisabled={isPrevDisabled}
        isPrev={!!onPrev}
        progressBarSteps={progressBarSteps}
        minorSteps={minorSteps}
        progressBarType={progressBarType}
        displaySupportLink={displaySupportLink}
        onSupportTrigger={triggerIntercom}
        testId={testId}
      />

      <WizardElements.WizardInner isCenterVert={isCenterVert}>
        {notification && <WizardElements.WizardNotificationCard {...notification} />}
        {hasIllustration && <Illustration name={illustration} size={IllustrationSize.lg} />}
        {lottieOptions && <WizardElements.WizardAnimatedIllustration {...lottieOptions} />}
        <WizardElements.WizardStepTitle isSubtitle={isSubtitle} data-testid={stepTestId}>
          {title && <MIFormattedText label={title} values={titleValues} />}
        </WizardElements.WizardStepTitle>

        {subtitle && (
          <WizardElements.WizardStepSubTitle innerSize={innerSize} data-testid={subtitleTestId}>
            <MIFormattedText label={subtitle} values={subTitleValues} />
          </WizardElements.WizardStepSubTitle>
        )}

        <WizardElements.WizardInputs innerSize={innerSize} testId={contentTestId}>
          {children}
        </WizardElements.WizardInputs>

        {!docked && !isHideActions && (submitMethod || onSecondaryActionButtonClick) && (
          <WizardElements.WizardStepActionsContainer>{actionButtons}</WizardElements.WizardStepActionsContainer>
        )}

        <WizardElements.WizardFooterContainer>{footer}</WizardElements.WizardFooterContainer>
      </WizardElements.WizardInner>
      {docked && !isHideActions && submitMethod && (
        <>
          <WizardElements.DockerSpacer />
          <WizardElements.WizardStepActionsContainerDocked>
            {actionButtons}
          </WizardElements.WizardStepActionsContainerDocked>
        </>
      )}
    </WizardElements.WizardContainer>
  );
};
