import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import doneImage from 'src/images/general/check-circle.svg';
import { ShiftToAchUpsale } from 'src/pages/vendor/shift-vendor-to-ach/ShiftToAchUpsale';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { devices } from 'src/theme/appDevices';
import { usePaymentDataFromToken } from '../hooks/usePaymentDataFromToken';

type Props = {
  token: string;
};

const CheckAddressChangedPage = ({ token }: Props) => {
  const { payment, isPaymentLoading, organization } = usePaymentDataFromToken({ token });
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout>
      <BillPaymentBlock>
        <BillInfoContainer>
          <DoneIcon src={doneImage} />
          <Title data-testid="success-title">
            <MIFormattedText label="vendors.editCheckAddress.checkNotSent.success.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.editCheckAddress.checkNotSent.success.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
                companyName: organization?.companyName,
                invoiceNumber: bill.invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
        <ShiftToAchUpsale
          payment={payment}
          token={token}
          organization={organization}
          labelPrefix="vendors.editCheckAddress.checkNotSent.success"
        />
      </BillPaymentBlock>
    </OutsideLayout>
  );
};

export default CheckAddressChangedPage;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: 2rem;
`;
