import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { AreaLoader } from 'src/components/common/AreaLoader';
import Box from 'src/core/ds/box';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { directoryApi } from 'src/services/api/directory';
import { convertToDisplayAddress } from 'src/utils/address';
import { VERIFIED_VENDOR_DETAILS_CONST } from './consts';
import { VerifiedVendorDetails } from './VerifiedVendorDetails';

type Props = {
  vendorId: string;
};

export const VerifiedVendorDetailsWrapper = ({ vendorId }: Props) => {
  const intl = useIntl();
  const orgId = useOrgId();
  const { loading, error, onApiCall: fetchBusinessDetails, result } = useApi({
    api: directoryApi.getBusiness,
  });

  useEffect(() => {
    fetchBusinessDetails({ vendorId, orgId });
  }, [fetchBusinessDetails, vendorId, orgId]);

  if (loading) {
    return (
      <Box height={VERIFIED_VENDOR_DETAILS_CONST.MODAL_HEIGHT}>
        <AreaLoader />
      </Box>
    );
  }

  if (error) {
    return <VerifiedVendorDetails businessName="error" businessAddress="" businessContactInfo={{}} />;
  }

  const deliveryMethodDefaultValue = intl.formatMessage({
    id: 'bills.form.verifiedVendorDetailsInfo.deliveryMethodDefaultValue',
  });

  const { companyName = '', address = {}, contactName = '', contactEmail = '', contactPhone = '' } =
    result?.business ?? {};

  return (
    <VerifiedVendorDetails
      businessName={companyName}
      businessAddress={convertToDisplayAddress(address)}
      businessContactInfo={{
        fullName: contactName,
        email: contactEmail,
        phone: contactPhone,
        deliveryMethodLabel: deliveryMethodDefaultValue,
      }}
    />
  );
};
