import config from 'src/config';
import { PayListItemEntityType, PayListItemType } from 'src/modules/bills/types';
import { PayCardBadge } from 'src/pay/consts';
import { PayCardBadgeProps, PayCardFlag, PayItemPaymentType } from 'src/pay/types';
import {
  BillStatus,
  PaymentApprovalStatus,
  PaymentRequestCreateOrigin,
  PaymentRequestStatus,
  PaymentStatus,
  PayTabs,
  REFUND_COLLECT_STATUSES,
} from 'src/utils/consts';

export function getTag({
  flags = [],
  isPartiallyPaidItemOnInboxTab,
  isSelfServeRefundEnabled,
  payment,
}: PayCardBadgeProps) {
  const approvalStatus = payment?.approvalDecisionStatus;
  const status = payment?.status;

  if (isPartiallyPaidItemOnInboxTab) {
    return PayCardBadge.PartiallyPaid;
  }

  switch (status) {
    case PaymentStatus.FAILED:
      if (approvalStatus === PaymentApprovalStatus.DECLINED) {
        return PayCardBadge.Declined;
      }

      if (isSelfServeRefundEnabled && payment && isRefundPayment(payment)) {
        return PayCardBadge.Refund;
      }

      return PayCardBadge.Failed;

    case PaymentStatus.BLOCKED:
      if (approvalStatus === PaymentApprovalStatus.PENDING) {
        return PayCardBadge.Pending;
      }

      return null;

    case PaymentStatus.COMPLETED:
      if (flags.includes(PayCardFlag.DEPOSITED)) {
        return PayCardBadge.Completed;
      }

      return null;
    default:
      return null;
  }
}

export const getLabels = (
  createOrigin: PaymentRequestCreateOrigin | undefined,
  status: string,
  isRequest: boolean | null | undefined,
  hasPaymentRequest?: boolean | null
): string[] => {
  const pendingRequest = status === PaymentRequestStatus.PENDING;
  const userCreateOrigins = [PaymentRequestCreateOrigin.MCB, PaymentRequestCreateOrigin.MELIOME_GENERIC_LINK];

  if (!isRequest) {
    return hasPaymentRequest ? ['list.group.bill.paymentRequested'] : [];
  }

  if (!pendingRequest && config.featureFlags.getPaidLabels === true) {
    if (createOrigin && userCreateOrigins.includes(createOrigin)) {
      return ['list.group.bill.createdByCustomer'];
    } else if (createOrigin === PaymentRequestCreateOrigin.REQUEST) {
      return ['list.group.bill.paymentRequested'];
    }
  }

  return [];
};

export const isInboxTab = (query?: Record<string, string>) => query?.status === BillStatus.UNPAID;

export const canPartiallyPayItem = (item: PayListItemType, isRecurring: boolean, paymentExist: boolean): boolean =>
  !paymentExist && !item.internalBill && !item.recurringBillId && !item.requestId && !isRecurring;

const isRefundPayment = (payment: PayItemPaymentType) =>
  !payment.isFinanced && payment.collectStatus && REFUND_COLLECT_STATUSES.includes(payment.collectStatus);

export const shouldShowFailedInstallment = (isFinancingEnabled, tab, item) =>
  isFinancingEnabled && tab === PayTabs.INBOX && item.type === PayListItemEntityType.Installment;
