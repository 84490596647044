import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import VendorAccountNumberPage from 'src/pages/onboarding/add-vendor/VendorAccountNumberPage';
import VendorNamePage from 'src/pages/onboarding/add-vendor/VendorNamePage';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import VendorAddedPage from './components/VendorAddedPage';

export function OnboardingAddVendorRouter() {
  return (
    <Switch>
      <SmartRoute path={onboardingLocations.addVendor.name}>
        <VendorNamePage />
      </SmartRoute>
      <SmartRoute path={onboardingLocations.addVendor.accountNumber}>
        <VendorAccountNumberPage />
      </SmartRoute>
      <SmartRoute path={onboardingLocations.addVendor.added}>
        <VendorAddedPage />
      </SmartRoute>
    </Switch>
  );
}
