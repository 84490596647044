import { VStack } from 'src/core/ds/stack';
import { Header } from './Header';
import { MSNTable } from './MSNTable';

export const MSNDashboard = ({ onOpenDrawer }) => (
  <VStack px={16} py={10} spacing={10}>
    <Header />
    <MSNTable onOpenDrawer={onOpenDrawer} />
  </VStack>
);
