import { useStyleConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Box from 'src/core/ds/box';
import { VStack } from 'src/core/ds/stack';

type Props = {
  children: ReactNode;
};

export const Content = ({ children }: Props) => {
  const style = useStyleConfig('WizardContent');

  return (
    <Box sx={style} paddingBlockEnd={10}>
      <VStack spacing={10} maxWidth="48.8rem" w="full" minHeight="full" margin="0 auto">
        {children}
      </VStack>
    </Box>
  );
};
