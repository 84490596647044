import { getTranslationValue } from './translations';
import { AddressType } from './types';

const getFormattedAddress = ({ addressLine1, addressLine2, city, state, zipCode }: AddressType) => {
  const addressDetails = [addressLine1, addressLine2, city, state, zipCode].filter(Boolean);

  return addressDetails.join(', ');
};

const getFormattedACH = (vendorCompanyName, isManagedByVendor, last4Digits) =>
  `${vendorCompanyName}${isManagedByVendor ? '' : ` (account ending in ${last4Digits})`}`;

const mapCardTypeToLabel = {
  debit: 'bills.form.paymentActivity.debitInfoLabel',
  credit: 'bills.form.paymentActivity.creditInfoLabel',
};

const getFormattedCardInfo = ({ cardType, card4digits }) =>
  `${getTranslationValue(mapCardTypeToLabel[cardType])} ...(${card4digits})`;

export { getFormattedACH, getFormattedAddress, getFormattedCardInfo };
