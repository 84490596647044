import { featureFlags } from '@melio/shared-web';
import * as React from 'react';

export const withFeatureFlags = <ValueType,>(featureName: string, propName: string, defaultValue?: ValueType) => <T,>(
  Component: React.ComponentType<T>
) => (props) => {
  const [featureValue] = featureFlags.useFeature<ValueType>(featureName, defaultValue);

  const featureFlagProp = {
    [propName]: featureValue,
  };

  return (
    <Component {...props} {...featureFlagProp}>
      {props.children}
    </Component>
  );
};
