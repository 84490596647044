import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { BillPageType } from 'src/utils/consts';

type MIFormTitleWithTextProps = {
  title: string;
  text: string;
  errorMessage: string | null;
  pageType: BillPageType;
};

export const MIFormTitleWithText = ({ title, text, errorMessage, pageType }: MIFormTitleWithTextProps) => (
  <TitleWithTextContainer pageType={pageType}>
    {title && (
      <Title>
        <MIFormattedText label={title} />
      </Title>
    )}
    <Text>
      <MIFormattedText label={text} />
    </Text>
    {errorMessage && (
      <Error>
        <MIFormattedText label={errorMessage} />
      </Error>
    )}
  </TitleWithTextContainer>
);

type TitleWithTextContainerProps = {
  children?: React.ReactNode;
  pageType: BillPageType;
};

const TitleWithTextContainer = ({ pageType, children }: TitleWithTextContainerProps) => (
  <Flex sx={TitleWithTextContainerStyle(pageType)}>{children}</Flex>
);

type TitleProps = {
  children?: React.ReactNode;
};

const Title = ({ children }: TitleProps) => <Box sx={TitleStyle}>{children}</Box>;

type TextProps = {
  children?: React.ReactNode;
};

const Text = ({ children }: TextProps) => (
  <Box color="black" textStyle="body3">
    {children}
  </Box>
);

type ErrorProps = {
  children?: React.ReactNode;
};

const Error = ({ children }: ErrorProps) => <Box sx={ErrorStyle}>{children}</Box>;

const TitleStyle: CSSObject = {
  color: 'black',
  textStyle: 'body3Semi',
  mb: 5,
};

const ErrorStyle: CSSObject = {
  textStyle: 'body3',
  color: 'red.500',
};

const TitleWithTextContainerStyle = (pageType: BillPageType): CSSObject => ({
  flexDir: 'column',
  mt: pageType === BillPageType.CREATE ? -2 : 8,
  mb: 5,
});
