import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { analytics } from 'src/services/analytics';
import { useExtension } from '../../spend-management/hooks/useExtension';
import { INSTALL_EXTENSION, PAGE_NAME } from './constants';

export const AmexExtensionPage = () => {
  const { isExtensionInstalled, extensionLoading, webStoreUrl, webStoreUrlParams } = useExtension();

  if (extensionLoading) return <AreaLoader />;

  const prefixLocaleContent = `settings.spendManagement.extensionLayout.${
    isExtensionInstalled ? 'installed' : 'notInstalled'
  }`;

  const title = `${prefixLocaleContent}.title`;
  const subtitle = `${prefixLocaleContent}.subtitle`;
  const buttonLabel = `${prefixLocaleContent}.button`;

  const onInstallExtensionClick = () => {
    analytics.track(PAGE_NAME, INSTALL_EXTENSION);
    window.open(`${webStoreUrl}${webStoreUrlParams('settings')}`, '_blank');
  };

  return (
    <MICard>
      <MICardForm>
        <MICardTitle label={title} />
        <Box textStyle="body2" mb={4} mt={6}>
          <MIFormattedText label={subtitle} />
        </Box>
        <Flex direction="row">
          <Button
            variant={ButtonVariants.tertiary}
            label={buttonLabel}
            size={ButtonSizes.md}
            onClick={onInstallExtensionClick}
          />
          {isExtensionInstalled && (
            <Flex alignItems="center" direction="row">
              <Box color="green.700">
                <DoneIcon name={IconNames.checkFill} />
              </Box>
              <MIFormattedText label="settings.spendManagement.extensionLayout.installed.indication" />
            </Flex>
          )}
        </Flex>
      </MICardForm>
    </MICard>
  );
};

const DoneIcon = styled(Icon)`
  margin-left: 1.5rem;
  margin-right: 0.5rem;
`;
