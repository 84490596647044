import { isVirtualCardCleared } from 'src/pages/vendor/virtual-card/utils';
import { DeliveryType, PaymentStatus, VirtualCardStatus } from 'src/utils/consts';

type PaymentStatusesTypes = {
  isShiftToAchRequested: boolean;
  isPaymentVirtualCardCleared: boolean;
  isPaymentCompleted: boolean;
  isVirtualCardCanceled: boolean;
};

export const isQBPaymentSource = (paymentOrigin = ''): 'Melio' | 'QB' =>
  paymentOrigin.toLowerCase().startsWith('qb') ? 'QB' : 'Melio';

export const getPaymentStatuses = (payment): PaymentStatusesTypes => {
  const deliveryMethod = payment?.deliveryMethod;
  const isPaymentStatusCompleted = payment?.status === PaymentStatus.COMPLETED;
  const isPaymentDeliverStatusCleared = isVirtualCardCleared(payment);
  const isVirtualCardDM = deliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isVirtualCardCanceled = payment?.lastCreatedVirtualCard?.status === VirtualCardStatus.CANCELED;
  const isShiftToAchRequested = deliveryMethod?.deliveryType === DeliveryType.ACH && deliveryMethod?.isFilledByVendor;
  const isPaymentVirtualCardCleared = isVirtualCardDM && isPaymentStatusCompleted && isPaymentDeliverStatusCleared;
  const isPaymentCompleted = !isVirtualCardDM && isPaymentStatusCompleted;

  return {
    isShiftToAchRequested,
    isPaymentVirtualCardCleared,
    isPaymentCompleted,
    isVirtualCardCanceled,
  };
};

export const getReadableBankAccountFailedReason = (validationErrors: Record<string, any>) => {
  if (Object.keys(validationErrors).length === 2) {
    return 'inputErrors.fundingSourceBank.bothInvalid';
  }

  if (validationErrors?.routingNumber) {
    return validationErrors?.routingNumber;
  }

  if (validationErrors?.accountNumber) {
    return validationErrors?.accountNumber;
  }

  return '';
};
