import { forwardRef } from 'src/core/ds';
import Box, { BoxProps } from 'src/core/ds/box';
import { WizardProgressBar } from 'src/utils/consts';

type NavIconContainerType = BoxProps & { progressBarType?: WizardProgressBar };

export const NavIconContainer = forwardRef<NavIconContainerType, 'div'>((props, ref) => {
  const { progressBarType, ...rest } = props;

  return (
    <Box ref={ref} {...rest} {...navIconStyles} mt={progressBarType === WizardProgressBar.WITH_SUBSTEPS ? 2 : 0} />
  );
});

const navIconStyles = {
  w: 6,
  h: 6,
  cursor: 'pointer',
};
