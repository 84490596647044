import { useMemo } from 'react';
import * as React from 'react';
import { CellProps } from 'react-table';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { HStack } from 'src/core/ds/stack';
import Tooltip from 'src/core/ds/tooltip';
import { ReactComponent as InformationIcon } from 'src/images/get-pro/information.svg';
import { PaymentStatus } from 'src/utils/consts';
import { TableDate } from './TableDate';

type DeliveryDateCellValue = {
  date?: string;
  delayReason?: string;
  status?: string;
  type?: string;
};

export const DeliveryDateCell: React.FC<CellProps<DeliveryDateCellValue>> = ({ cell: { value } }) => {
  const { status, delayReason, date: deliveryDate } = value;
  const showDelayReason = delayReason && status === PaymentStatus.SCHEDULED;

  return useMemo(
    () => (
      <HStack spacing={2}>
        <TableDate date={deliveryDate} />
        {showDelayReason && (
          <Tooltip placement="top" label={<MIFormattedText label={`getPro.tooltip.delayReasons.${delayReason}`} />}>
            <InformationIcon />
          </Tooltip>
        )}
      </HStack>
    ),
    [showDelayReason, deliveryDate, delayReason]
  );
};
