import { useEffect } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useInitOrganization } from 'src/modules/organizations/hooks/useInitOrganization';
import { globalLocations } from 'src/pages/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { getMissedCompanyInfoFields } from 'src/utils/company';

export const QuickbookMultiOrgOnboardingConnectSuccess = () => {
  const { search } = useLocation();
  const history = useHistory();
  const orgId = new URLSearchParams(search).get('orgId') as string;
  const { initOrganization } = useInitOrganization();

  useEffect(() => {
    initOrganization(Number(orgId)).then(({ companyInfo }: any) => {
      const location = companyInfo.allowedActions.canDoPayments
        ? {
            pathname: globalLocations.quickbookMultiOrgOnboarding.companiesList,
          }
        : {
            pathname: generatePath(onboardingLocations.companyInfo.newCombined, { orgId }),
            state: {
              returnUrl: globalLocations.quickbookMultiOrgOnboarding.companiesList,
            },
          };

      if (!companyInfo.allowedActions.canDoPayments) {
        const missedFields = getMissedCompanyInfoFields(companyInfo);
        analytics.trackAction('redirect-to-onboarding-after-qb', {
          missedFields,
        });
      }

      history.push(location);
    });
  }, [orgId, history, initOrganization]);

  return <AreaLoader />;
};
