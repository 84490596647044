import { MICardEditableField } from 'src/components/common/MICard';
import { InlineSwitchField } from 'src/ui/form/InlineSwitchField';

export type Props = {
  id: string;
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange: ({ value }: { value: boolean }) => any;
};
export const ToggleRow = ({ id, label, value, disabled, onChange }: Props) => (
  <MICardEditableField>
    <InlineSwitchField
      label={label}
      value={value}
      onChange={onChange}
      id={id}
      hidePositiveNegativeLabels
      disabled={disabled}
    />
  </MICardEditableField>
);
