import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { editCheckAddressLocations } from 'src/pages/vendor/edit-check-adress/locations';
import { shiftVendorToAchLocations } from 'src/pages/vendor/shift-vendor-to-ach/locations';
import { analytics } from 'src/services/analytics';
import { DeliveryType, PaymentStatus } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';

export type EditCheckAddressProps = {
  token: string;
  id: string;
};

export type EditCheckAddressState = {
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
};

const tryShiftToAchFlag = 'shiftToAch';
export const paymentStatusPropsMap = {
  [PaymentStatus.COMPLETED]: {
    path: editCheckAddressLocations.checkDeposited,
    analyticProps: { flow: 'check-deposited' },
  },
  [PaymentStatus.IN_PROGRESS]: {
    path: editCheckAddressLocations.checkSent,
    analyticProps: { flow: 'in-progress' },
  },
  [PaymentStatus.SCHEDULED]: {
    path: editCheckAddressLocations.checkNotSentChangeAddress,
    analyticProps: { flow: 'payment-schedule' },
  },
  [PaymentStatus.BLOCKED]: {
    path: editCheckAddressLocations.checkBlocked,
    analyticProps: { flow: 'payment-blocked' },
  },
  fallback: {
    path: editCheckAddressLocations.invalidToken,
    analyticProps: {},
  },
};

const ANALYTICS_TAG_NAME = 'useEditCheckAddressRedirect';

export function useEditCheckAddressRedirect(props: EditCheckAddressProps) {
  const { token, id } = props;
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const isShiftToAch = searchParams.get(tryShiftToAchFlag) === 'true';
  const paymentActions = useStoreActions(paymentsStore);
  const { paymentId } = getJWTPayload(token);

  useEffect(() => {
    if (token) {
      paymentActions
        .fetchEmailToVendorDetails({ token })
        .then((res) => {
          const payment = res.payload?.payment;
          const deliveryMethod = payment?.deliveryMethod;
          const paymentStatus = payment?.status;
          const organizationId = payment?.organization?.id;
          const vendorEmail = payment?.vendor?.contactEmail;

          const paymentStatusProps = paymentStatusPropsMap[paymentStatus] || paymentStatusPropsMap.fallback;

          const commonAnalyticProps = {
            ...analytics.getExtraProperties(),
            organizationId,
            paymentId,
            vendorId: id,
            vendorEmail,
            siteConfig: analytics.siteConfig,
            ...paymentStatusProps.analyticProps,
          };

          if (deliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD) {
            history.push(generatePath(editCheckAddressLocations.deliveryMethodChangedToVirtualCard, { id, token }));
          } else if (deliveryMethod?.deliveryType === DeliveryType.ACH) {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              ...commonAnalyticProps,
              flow: 'voided-check',
            });
            history.push(generatePath(editCheckAddressLocations.switchedToACH, { token, id }));
          } else if (paymentStatus === PaymentStatus.SCHEDULED && isShiftToAch) {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              ...commonAnalyticProps,
            });
            history.push(
              generatePath(shiftVendorToAchLocations.index, {
                token,
                id: deliveryMethod.id,
              })
            );
          } else {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              ...commonAnalyticProps,
            });
            history.push(generatePath(paymentStatusProps.path, { token, id }));
          }
        })
        .catch((err) => {
          if (err?.error?.code === 'PMT17') {
            analytics.setExtraProperties(ANALYTICS_TAG_NAME, {
              origin: 'check-cancelled',
            });
          }

          history.push(generatePath(paymentStatusPropsMap.fallback.path, { token, id }));
        });
    }

    return undefined;
  }, [token, history, id, paymentActions]);

  return undefined;
}
