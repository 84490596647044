import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { BrandMiniLogo } from 'src/core/ds/brandMiniLogo';
import Flex from 'src/core/ds/flex';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { AccountingPlatform } from 'src/utils/types';
import { notificationContainer } from './styles';

type Props = {
  connectedAccountingPlatform: AccountingPlatform;
};

export function AccountingPlatformNotification({ connectedAccountingPlatform }: Props) {
  const accountingPlatformName = getAccountingSoftwareDisplayName(connectedAccountingPlatform.name);

  return (
    <Flex __css={notificationContainer} data-testid="bill-synced-to-accounting-software-container">
      <Box mr={4}>
        <BrandMiniLogo name={accountingPlatformName.displayShortName as any} />
      </Box>
      <Box textAlign="left">
        <MIFormattedText
          label="bills.pay.payBillSuccess.notes.sync"
          values={{
            accountingPlatformName: accountingPlatformName.displayShortName,
          }}
        />
      </Box>
    </Flex>
  );
}
