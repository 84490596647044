import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PaymentIcon } from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

type Props = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  testId?: string;
  icon?: IconNames;
  IconComponent?: JSX.Element;
};

export const RefundReviewBaseContainerWithIcon = ({
  children,
  title,
  subtitle,
  testId,
  icon,
  IconComponent,
}: Props) => (
  <Flex direction="column" m={6} data-testid={testId}>
    <Flex alignItems="center">
      <PaymentIcon>
        {icon ? <Icon name={icon} size={IconSize.lg} /> : null}
        {IconComponent || null}
      </PaymentIcon>
      <Box>
        <Box textStyle="body4Semi" color="grey.600">
          <MIFormattedText label={title} />
        </Box>
        <Box textStyle="body2" color="black">
          {children}
        </Box>
      </Box>
    </Flex>
    {subtitle ? (
      <Box ml="4.5rem" textStyle="body4" color="grey.600">
        <MIFormattedText label={subtitle} />
      </Box>
    ) : null}
  </Flex>
);
