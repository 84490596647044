import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';

export type FirmBillingFeeClient = {
  id: number;
  isActive: boolean;
};

export const accountingFirmsApi = {
  bulkCreate({ clients, orgId }) {
    const url = `/orgs/${orgId}/accounting-firm/bulk-create-clients`;

    return postRequest(url, { clients });
  },

  getBillingFees({ orgId }) {
    const url = `/orgs/${orgId}/accounting-firm/billing-fees`;

    return fetchRequest(url);
  },

  createBillingFee({
    orgId,
    clients,
    fundingSourceId,
  }: {
    orgId: number;
    clients: FirmBillingFeeClient[];
    fundingSourceId: number;
  }) {
    const url = `/orgs/${orgId}/accounting-firm/billing-fees`;

    return postRequest(url, { clients, fundingSourceId });
  },

  updateBillingFee({
    orgId,
    billingFeeId,
    clients,
    fundingSourceId,
  }: {
    orgId: number;
    billingFeeId: number;
    clients: FirmBillingFeeClient[];
    fundingSourceId: number;
  }) {
    const url = `/orgs/${orgId}/accounting-firm/billing-fees/${billingFeeId}`;

    return putRequest(url, { clients, fundingSourceId });
  },

  deleteBillingFee({ orgId, billingFeeId }) {
    const url = `/orgs/${orgId}/accounting-firm/billing-fees/${billingFeeId}`;

    return deleteRequest(url);
  },
};
