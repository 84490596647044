import { isAfter } from 'date-fns';
import compact from 'lodash/compact';
import fpFlow from 'lodash/fp/flow';
import fpGroupBy from 'lodash/fp/groupBy';
import fpMapValues from 'lodash/fp/mapValues';
import fpSortBy from 'lodash/fp/sortBy';
import isNil from 'lodash/isNil';
import moment from 'moment';
import { organizationsApi } from 'src/modules/organizations/api';
import { paymentsApi } from 'src/modules/payments/api';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { isPaymentPushToDebit } from 'src/pages/vendor/records';
import { getFormattedCheckSerial } from 'src/utils/bills';
import {
  BillStatus,
  ButtonVariant,
  DeletePaymentAction,
  DeliveryType,
  EXPEDITED_DELIVERY_TYPES,
  FundingType,
  PAGINATION,
  PaymentApprovalStatus,
  PaymentDeliverStatus,
  PaymentStatus,
} from 'src/utils/consts';
import { getLastCreatedVirtualCard } from 'src/utils/payments';
import { AccountType, AmexIndustryType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';

export const getTermsFromDates = (
  dueDate: any,
  invoiceDate: any // eslint-disable-line react/sort-comp
) => moment(dueDate).diff(invoiceDate, 'days');

export const recalculateTerm = (idType: string, changedField: any, bill: BillType) => {
  let newTerm: number | null = null;

  if (idType === 'dueDate' && bill.invoiceDate) {
    newTerm = getTermsFromDates(changedField, bill.invoiceDate);
  }

  if (idType === 'invoiceDate' && bill.dueDate) {
    newTerm = getTermsFromDates(bill.dueDate, changedField);
  }

  if (idType === 'terms') {
    newTerm = +changedField;
  }

  return newTerm;
};

export const recalculateDueDate = (idType: string | readonly string[], changedField: any, bill: BillType) => {
  let dueDate: Date | null = null;

  if (idType === 'terms' && bill.invoiceDate && changedField) {
    dueDate = moment(bill.invoiceDate).add(changedField, 'days').toDate();
  }

  return dueDate;
};

export const recalculateInvoiceDate = (idType: string, changedField: any, bill: BillType) => {
  let invoiceDate: Date | null = null;

  if (!bill.invoiceDate) {
    if (idType === 'terms' && bill.dueDate && changedField) {
      invoiceDate = moment(bill.dueDate).subtract(changedField, 'days').toDate();
    }

    if (idType === 'dueDate' && bill.terms && changedField) {
      invoiceDate = moment(changedField).subtract(bill.terms, 'days').toDate();
    }
  }

  return invoiceDate;
};

export const getTermsByUploadBill = (
  dueDate: Record<string, any> | string,
  invoiceDate: Record<string, any> | string
) =>
  Object.prototype.toString.call(dueDate) === '[object Date]' &&
  Object.prototype.toString.call(invoiceDate) === '[object Date]'
    ? moment(dueDate).diff(invoiceDate, 'days')
    : null;

export const strLowerNoSpaces = (str: string) => str.toLowerCase().replace(/\s/g, '');

export const getBillActionOptions = (
  bill: BillType,
  payment: PaymentType,
  goEditPayment?: ((arg0: PaymentType) => void) | null,
  onCancelPaymentClicked?: ((arg0: PaymentType, arg1: DeletePaymentAction) => void) | null,
  onRetryPayment?: (() => void) | null,
  onMarkBillAsPaid?: (() => void) | null
) => {
  if (bill.internalBill) return [];

  if (payment?.metadata?.canUserRetry) {
    const markAsPaidAction = onMarkBillAsPaid && {
      label: 'bills.actions.markAsPaid',
      action: onMarkBillAsPaid,
      variant: ButtonVariant.SECONDARY,
    };
    const retryAction = onRetryPayment && {
      label: 'bills.form.paymentActivity.actions.retryPayment',
      action: onRetryPayment,
      variant: ButtonVariant.PRIMARY,
    };

    return compact([markAsPaidAction, retryAction]);
  }

  if (bill.status === BillStatus.SCHEDULED) {
    const actionsList = [
      {
        label: 'bills.form.paymentActivity.actions.edit',
        action: () => {
          goEditPayment && goEditPayment(payment);
        },
        variant: ButtonVariant.SECONDARY,
      },
    ];
    const cancelAction = {
      label: 'bills.form.paymentActivity.actions.cancel',
      action: () => {
        onCancelPaymentClicked && onCancelPaymentClicked(payment, DeletePaymentAction.SINGLE);
      },
      variant: ButtonVariant.DESTRUCTIVE,
    };
    const cancelCurrentRecurringPaymentAction = {
      label: 'bills.form.paymentActivity.actions.cancelCurrentRecurringPayment',
      action: () => {
        onCancelPaymentClicked && onCancelPaymentClicked(payment, DeletePaymentAction.RECURRING_CURRENT);
      },
      variant: ButtonVariant.DESTRUCTIVE,
    };
    const cancelAllRecurringPayments = {
      label: 'bills.form.paymentActivity.actions.cancelAllRecurringPayments',
      action: () => {
        onCancelPaymentClicked && onCancelPaymentClicked(payment, DeletePaymentAction.RECURRING_ALL);
      },
      variant: ButtonVariant.DESTRUCTIVE,
    };

    if (bill.recurringBillId) {
      actionsList.push(cancelCurrentRecurringPaymentAction, cancelAllRecurringPayments);
    } else {
      actionsList.push(cancelAction);
    }

    return actionsList;
  }

  return [];
};

export const isNumber = (n: any) => !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0);

type GetBillListParams = {
  orgId: number;
  status: BillStatus;
  start?: number;
  limit?: number;
  search?: string;
  vendorId?: string;
};

export const getBillListParams = ({ orgId, status, start, limit, search, vendorId }: GetBillListParams) => {
  const billListInboxParams = {
    orgId,
    filters: {
      start,
      limit,
      search,
      vendorId,
    },
  };
  const isInbox = status === BillStatus.UNPAID;
  const billListParams = isInbox
    ? billListInboxParams
    : {
        orgId,
        scope: 'billsList',
        filters: {
          status,
          start,
          limit,
          search,
          vendorId,
        },
      };
  const paymentRequestsConstantFilters = {
    getBy: 'byOrg',
    approvalStatus: 'pending',
    status: 'pending',
    sorting: 'dueDate',
    start: isNil(start) ? PAGINATION.DEFAULT_START : start,
    limit: PAGINATION.DEFAULT_LIMIT,
  };
  const requestListParams = isInbox
    ? billListInboxParams
    : {
        orgId,
        filters: {
          ...paymentRequestsConstantFilters,
          search,
        },
      };
  const paymentListParams = isInbox
    ? billListInboxParams
    : {
        orgId,
        status,
        search,
        start,
        limit,
        vendorId,
      };

  return {
    billListInboxParams,
    billListParams,
    requestListParams,
    paymentListParams,
  };
};

export const getPayFromLabel = (isBillPaid: boolean, isFinancing?: boolean): string => {
  if (isFinancing) {
    return 'viewBillPaymentActivity.payByTitle';
  }

  if (isBillPaid) {
    return 'viewBillPaymentActivity.paidFromTitle';
  }

  return 'viewBillPaymentActivity.payFromTitle';
};

export const getReceiveLabel = (
  deliveryMethod: DeliveryMethodType,
  isBillPaid: boolean,
  payment: PaymentType
): string => {
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isPaymentCompleted = payment.status === PaymentStatus.COMPLETED;

  if (isVirtualCardDeliveryMethod) {
    return isPaymentCompleted ? 'viewBillPaymentActivity.receivedTitle' : 'viewBillPaymentActivity.receiveTitle';
  }

  if (isBillPaid) {
    return 'viewBillPaymentActivity.receivedTitle';
  }

  return 'viewBillPaymentActivity.receiveTitle';
};

export const getDeliveryDateInfoLabel = (
  deliveryMethod: DeliveryMethodType,
  isBillPaid: boolean,
  payment: PaymentType
): string => {
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isPaymentInProgress = payment.status === PaymentStatus.IN_PROGRESS;

  if (isBillPaid) {
    if (isVirtualCardDeliveryMethod) {
      return isPaymentInProgress
        ? 'viewBillPaymentActivity.deliveryDate.deliveryEtaLabel'
        : 'viewBillPaymentActivity.deliveryDate.paidBillDeliveryOnLabel';
    }

    return 'viewBillPaymentActivity.deliveryDate.paidBillDeliveryEtaLabel';
  }

  return 'viewBillPaymentActivity.deliveryDate.deliveryEtaLabel';
};

export const getDeliveryMethodDescription = (
  deliveryMethod: DeliveryMethodType,
  isBillPaid: boolean,
  payment: PaymentType
): { label: string; values?: Record<string, any> } => {
  const isVirtualCardDeliveryMethod = deliveryMethod.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isPaymentCompleted = payment.status === PaymentStatus.COMPLETED;
  const isPaymentDeliverStatusSent = payment.deliverStatus && payment.deliverStatus === PaymentDeliverStatus.SENT;
  const achTraceNumber = payment?.metadata?.achTraceNumber;

  if (achTraceNumber) {
    return {
      label: 'viewBillPaymentActivity.deliveryMethod.description.achTraceNumber',
      values: { traceNumber: achTraceNumber },
    };
  }

  if (isVirtualCardDeliveryMethod && isBillPaid && isPaymentCompleted && isPaymentDeliverStatusSent) {
    return { label: 'viewBillPaymentActivity.deliveryMethod.description.virtualCardNotCleared' };
  }

  if (isPaymentPushToDebit(deliveryMethod)) {
    return { label: 'viewBillPaymentActivity.deliveryMethod.description.modifiedByVendor' };
  }

  return { label: '' };
};

export const getDeliveryMethodLabelWithValues = (
  deliveryMethod: DeliveryMethodType,
  isBillPaid: boolean,
  payment: PaymentType
): Record<string, any> => {
  const { deliveryType } = deliveryMethod;
  const isACHDeliveryMethod = deliveryType === DeliveryType.ACH;
  const isRPPSDeliveryMethod = deliveryType === DeliveryType.RPPS;
  const isCheckDeliveryMethod = deliveryType === DeliveryType.CHECK;
  const isVirtualCardDeliveryMethod = deliveryType === DeliveryType.VIRTUAL_CARD;
  const isPaymentCompleted = payment?.status === PaymentStatus.COMPLETED;
  const isPushToDebit = isPaymentPushToDebit(deliveryMethod);
  const isInternationalMethod = isInternationalDeliveryMethod(deliveryType);
  const labelData = { label: '', values: {} };

  if (isCheckDeliveryMethod && isBillPaid) {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.paidBillPaperCheckLabel';
    const formattedCheckSerial = getFormattedCheckSerial(payment);
    labelData.values = { formattedCheckSerial };
  } else if (isACHDeliveryMethod && isBillPaid) {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.paidBillAchLabel';
  } else if (isCheckDeliveryMethod) {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.paperCheckLabel';
  } else if (isRPPSDeliveryMethod) {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.rppsLabel';
  } else if (isVirtualCardDeliveryMethod) {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.virtualCardLabel';

    if (isPaymentCompleted) {
      const virtualCard = getLastCreatedVirtualCard(payment);
      labelData.label = 'viewBillPaymentActivity.deliveryMethod.paidVirtualCardLabel';
      labelData.values = { card4digits: virtualCard?.accountNumber4digits };
    } else {
      labelData.label = 'viewBillPaymentActivity.deliveryMethod.virtualCardLabel';
    }
  } else if (isInternationalMethod) {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.internationalLabel';
  } else if (isPushToDebit) {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.debitLabel';
  } else {
    labelData.label = 'viewBillPaymentActivity.deliveryMethod.achLabel';
  }

  return labelData;
};

export type SortedAmexIndustriesByCategory = {
  sortedCategories: string[];
  byCategory: Record<string, AmexIndustryType[]>;
};
const getSortedAmexIndustriesByCategory = (industries: AmexIndustryType[]): SortedAmexIndustriesByCategory => {
  const byCategory = fpFlow(
    fpGroupBy('industry'),
    fpMapValues(fpSortBy((subCategory: AmexIndustryType) => subCategory.description))
  )(industries);
  const sortedCategories = Object.keys(byCategory)
    .filter(String)
    .sort((a, b) => {
      if (a === 'Others') return 1;

      if (b === 'Others') return -1;

      return 0;
    });

  return { sortedCategories, byCategory };
};

export const getAmexAllowedIndustries = async (
  orgId: number,
  cardNetwork: string
): Promise<SortedAmexIndustriesByCategory> => {
  const { list } = await organizationsApi.getMccList(orgId, { cardNetwork });

  return getSortedAmexIndustriesByCategory(list);
};

export const getSuccessPageHeadTexts = ({ payment, hasVendorEmail, isRecurring }) => {
  const isExpedited = payment.deliveryPreference && EXPEDITED_DELIVERY_TYPES.includes(payment.deliveryPreference);
  const isApprovalRequired = payment.approvalDecisionStatus === PaymentApprovalStatus.PENDING;

  let title = 'bills.pay.payBillSuccess.titles.regular';
  let subtitle = hasVendorEmail
    ? 'bills.pay.payBillSuccess.subtitles.regularWithNotify'
    : 'bills.pay.payBillSuccess.subtitles.regular';

  if (isRecurring) {
    title = 'bills.pay.payBillSuccess.titles.recurring';
    subtitle = hasVendorEmail
      ? 'bills.pay.payBillSuccess.subtitles.recurringWithNotify'
      : 'bills.pay.payBillSuccess.subtitles.recurring';
  }

  if (isExpedited) {
    subtitle = 'bills.pay.payBillSuccess.subtitles.expedited';
  }

  if (isApprovalRequired) {
    subtitle = `${subtitle}ApprovalRequired`;
  }

  return { title, subtitle };
};

export const emailVendorOnPaymentSchedule = (payment: PaymentType, orgId: number, contactEmail?: string) => {
  if (
    payment.deliveryMethod?.deliveryType === DeliveryType.CHECK &&
    isAfter(new Date(payment.scheduledDate), new Date()) &&
    !!contactEmail
  ) {
    paymentsApi.sendEditCheckAddress(orgId, payment.id);
  }
};

export const isVerifiedACHFundingSource = (fundingSource: AccountType | undefined) =>
  fundingSource?.fundingType === FundingType.ACH && fundingSource.isVerified;

export const getVerifiedACHFundingSources = (fundingSources: AccountType[]) =>
  fundingSources.filter((fs) => fs.fundingType === FundingType.ACH && fs.isVerified);
