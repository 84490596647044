import { useMemo } from 'react';
import styled from 'styled-components';
import { MICard } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import { FONT_WEIGHT } from 'src/core/theme/foundations/textStyles';
import WarningIcon from 'src/images/general/warning-icon.svg';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { AddressType } from 'src/utils/types';

type Props = {
  onClose: () => void;
  onEditClick: () => void;
  address: AddressType;
  vendorName?: string;
};

export const AmexVendorAddressVerificationFailedModal = ({ onClose, onEditClick, address, vendorName }: Props) => {
  const formattedAddress = useMemo(
    () =>
      Object.values(address)
        .filter((fieldValue) => !!fieldValue)
        .map((fieldValue) => fieldValue)
        .join(', '),
    [address]
  );

  return (
    <ModalMessage
      id="amex-address-verification-failed-modal"
      image={
        <Flex justifyContent="center" pb={6}>
          <Image src={WarningIcon} height={10} width={10} />
        </Flex>
      }
      titleComponent={
        <>
          <Box textStyle="h2Semi" textAlign="center" mb={4}>
            <MIFormattedText label="bills.pay.fundingSource.amexVendorAddressVerificationFailedModal.title" />
          </Box>
          <Box textStyle="body2" textAlign="left">
            <MIFormattedText label="bills.pay.fundingSource.amexVendorAddressVerificationFailedModal.subtitle" />
          </Box>
        </>
      }
      contentComponent={
        <CardContainer>
          <MICard>
            <Flex direction="column" gap={1} p={6} color="black" className={FULL_STORY_MASK_RULE_CLASS}>
              <Box
                fontWeight={FONT_WEIGHT.SEMI}
                textStyle="body2"
                data-testid="address-verification-failed-vendor-name"
              >
                {vendorName}
              </Box>
              <Box textStyle="body2" data-testid="address-verification-failed-address">
                {formattedAddress}
              </Box>
            </Flex>
          </MICard>
        </CardContainer>
      }
      buttonComponent={
        <Button
          isFullWidth
          onClick={onEditClick}
          label="bills.pay.fundingSource.amexVendorAddressVerificationFailedModal.cta"
          variant={ButtonVariants.primary}
          size={ButtonSizes.lg}
        />
      }
      onCloseClick={onClose}
    />
  );
};

const CardContainer = styled.div`
  & > div {
    margin-bottom: 0;
  }
`;
