import { useApi } from 'src/hoc/useApi';
import { financingApi } from 'src/modules/financing/api/financing';
import { FinancingPreEligibilityDecision, IneligibilityReason } from 'src/modules/financing/types';

export const useGetFinancingPreEligibility = () => {
  const {
    onApiCall: getFinancingPreEligibility,
    result: preEligibilityResult,
    loading: isPreEligibilityLoading,
  } = useApi<
    Parameters<typeof financingApi.getPreEligibility>,
    { decision: FinancingPreEligibilityDecision; reason: IneligibilityReason }
  >({
    api: financingApi.getPreEligibility,
    throwError: false,
  });

  return {
    getFinancingPreEligibility,
    preEligibilityResult,
    isPreEligibilityLoading,
  };
};
