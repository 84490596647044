import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import { Link } from 'src/core/ds/link';
import { Colors, MelioLogo } from 'src/core/ds/melioLogo';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import partnersIcon from 'src/images/outside/trusted.png';
import partnersIconMobile from 'src/images/outside/trustedMobile.png';

type Props = {
  showPartners?: boolean;
};

type OutsideFooterContainerProps = {
  children?: React.ReactNode;
};

type FooterLinkProps = {
  children?: React.ReactNode;
  href: string;
};

type SupportContainerProps = {
  children?: React.ReactNode;
};

type LinksContainerProps = {
  children?: React.ReactNode;
};

type PartnersImageProps = {
  src: string;
  alt: string;
};

type TextSeparatorProps = {
  children?: React.ReactNode;
};

type BoldTextProps = {
  children?: React.ReactNode;
};

export const OutsideFooter = ({ showPartners = true }: Props) => {
  const site = useSiteContext();
  const device = useBreak();

  return (
    <OutsideFooterContainer>
      <PoweredByMelio />
      {showPartners && (
        <Box id="outside-footer-partners">
          <MIFormattedText label="vendors.addDeliveryMethodByLink.trustedBy" />
          <PartnersImage src={device.isMobile || device.isPhablet ? partnersIconMobile : partnersIcon} alt="partners" />
        </Box>
      )}
      <SupportContainer>
        <BoldText>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.support" />
        </BoldText>
        <FooterLink href={`mailto:${site.config.support.email}`}>{site.config.support.email}</FooterLink>
      </SupportContainer>
      <LinksContainer>
        <FooterLink href={site.config.agreementLinks.privacyPolicy}>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.privacyPolicy" />
        </FooterLink>
        <TextSeparator>|</TextSeparator>
        <FooterLink href={site.config.agreementLinks.userAgreement}>
          <MIFormattedText label="vendors.addDeliveryMethodByLink.termsOfService" />
        </FooterLink>
      </LinksContainer>
    </OutsideFooterContainer>
  );
};

const OutsideFooterContainerStyle: CSSObject = {
  display: 'flex',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  py: 5,
  px: 0,
  color: 'grey.700',
  textStyle: 'body4',
  bgColor: { base: 'grey.200', md: 'transparent' },
  mb: { base: '5rem', sm: 0 },
};

const PowerByMelioStyle: CSSObject = {
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'grey.700',
  textStyle: 'body4',
  marginTop: 5,
  svg: {
    width: 75,
    top: '-.8rem',
    position: 'relative',
  },
};

const OutsideFooterContainer = ({ children }: OutsideFooterContainerProps) => (
  <Box as="span" sx={OutsideFooterContainerStyle}>
    {children}
  </Box>
);

const FooterLink = ({ href, children }: FooterLinkProps) => (
  <Link href={href} target="_blank" rel="noopener noreferrer" color="grey.700" textDecor="none">
    {children}
  </Link>
);

const SupportContainer = ({ children }: SupportContainerProps) => (
  <Flex justify="center" mb={3} id="outside-footer-support-container">
    {children}
  </Flex>
);

const LinksContainer = ({ children }: LinksContainerProps) => <Flex>{children}</Flex>;

const TextSeparator = ({ children }: TextSeparatorProps) => (
  <Box as="span" my={0} mx={2}>
    {children}
  </Box>
);

const PartnersImage = ({ src, alt }: PartnersImageProps) => (
  <Image src={src} alt={alt} my={5} mx={0} w={{ base: '32.5rem', md: '45rem' }} />
);

const BoldText = ({ children }: BoldTextProps) => (
  <Box as="span" mr={2} textStyle="body4Semi">
    {children}
  </Box>
);

const PoweredByMelio = () => (
  <Box as="p" sx={PowerByMelioStyle} id="powered-by-melio">
    <MIFormattedText label="vendors.addDeliveryMethodByLink.poweredBy" />
    <MelioLogo color={Colors.grey} />
  </Box>
);
