import compact from 'lodash/compact';
import { MouseEvent as ReactMouseEvent } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { MaskField } from 'src/core/ds/form/fields';
import { PaymentCreatorsDropdown } from 'src/pages/settings/components/ApprovalWorkflows/PaymentCreatorsDropdown';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';
import { ModelView } from 'src/ui/form';
import { FormContainer } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { ApprovalWorkflowCreatorsGroup, SingleSelectFlavor } from 'src/utils/consts';
import { useAdminApprovalsOptions, useCreatorsGroupOptions, useForceOpenPaymentCreatorsDropdown } from '../hooks';
import { ApprovalWorkflowModalMode, WorkflowModelType } from '../types';
import { ApprovalWorkflowFormRow } from './ApprovalWorkflowFormRow';
import { ApprovalWorkflowTooltip } from './ApprovalWorkflowTooltip';

type Props = {
  dismiss: (event: ReactMouseEvent | null) => void;
  onSubmit: () => void;
  companyName: string;
  modalMode: ApprovalWorkflowModalMode;
  workflowModelView: ModelView<WorkflowModelType>;
  maxApprovers: number;
};

export const ApprovalWorkflowModalMessage = ({
  dismiss,
  onSubmit,
  companyName,
  workflowModelView,
  modalMode,
  maxApprovers,
}: Props) => {
  const forcePaymentCreatorsDropdownOpen = useForceOpenPaymentCreatorsDropdown(workflowModelView);
  const noCreatorsOptions = workflowModelView.paymentCreatorsOptions.value?.length === 0;
  const singleApproverInOrg = maxApprovers === 1;
  const creatorsGroupOptions = useCreatorsGroupOptions();
  const adminApprovalsOptions = useAdminApprovalsOptions(maxApprovers);

  return (
    <CustomModalMessage
      id="editApprovalWorkflowModal"
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label={`settings.approvalWorkflows.modal.${modalMode}.title`} values={{ companyName }} />
        </ModalTitle>
      }
      contentComponent={
        <FormContainer>
          <ApprovalWorkflowFormRow isFirstRow>
            <WizardSelectField
              label="settings.approvalWorkflows.modal.creatorsGroup.label"
              disabled={noCreatorsOptions}
              model={workflowModelView.creatorsGroup}
              placeholder="settings.approvalWorkflows.modal.creatorsGroup.placeholder"
              required
              options={creatorsGroupOptions}
              hint={
                workflowModelView.creatorsGroup.value === ApprovalWorkflowCreatorsGroup.ALL && !noCreatorsOptions
                  ? 'settings.approvalWorkflows.modal.creatorsGroup.hintAll'
                  : undefined
              }
              flavor={SingleSelectFlavor.INLINE}
              afterLabelComponent={
                noCreatorsOptions && (
                  <ApprovalWorkflowTooltip attributeName="creatorsGroup" testId="input-creatorsGroup-tooltip" />
                )
              }
            />
          </ApprovalWorkflowFormRow>
          {ApprovalWorkflowCreatorsGroup.SOME === workflowModelView.creatorsGroup.value && (
            <ApprovalWorkflowFormRow>
              <PaymentCreatorsDropdown
                testId="ApprovalWorkflowModalMessage-PaymentCreatorsDropdown"
                paymentCreators={compact(workflowModelView.paymentCreatorsOptions.value)}
                onChange={workflowModelView.paymentCreatorsOptions.onChange}
                label="settings.approvalWorkflows.modal.paymentCreators.label"
                errorMessage={workflowModelView.paymentCreatorsOptions.error}
                forceOpen={forcePaymentCreatorsDropdownOpen}
              />
            </ApprovalWorkflowFormRow>
          )}
          <ApprovalWorkflowFormRow>
            <MaskField
              testId="input-threshold"
              label="settings.approvalWorkflows.modal.threshold.label"
              helperText="settings.approvalWorkflows.modal.threshold.hint"
              model={workflowModelView.threshold}
              prefix="currency.symbol"
              placeholder="settings.approvalWorkflows.modal.threshold.placeholder"
              mb={0}
              thousandSeparator
              size="sm"
              isRequired
            />
          </ApprovalWorkflowFormRow>
          <ApprovalWorkflowFormRow testId="ApprovalWorkflowModalMessage-ApprovalDecisionsCountSelect">
            <WizardSelectField
              label="settings.approvalWorkflows.modal.adminApproval.label"
              model={workflowModelView.approvalDecisionsCount}
              placeholder="settings.approvalWorkflows.modal.adminApproval.placeholder"
              required
              options={adminApprovalsOptions}
              flavor={SingleSelectFlavor.INLINE}
              disabled={singleApproverInOrg}
              afterLabelComponent={
                singleApproverInOrg && (
                  <ApprovalWorkflowTooltip attributeName="adminApproval" testId="input-adminApproval-tooltip" />
                )
              }
            />
          </ApprovalWorkflowFormRow>
        </FormContainer>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <Button
            boxSizing="border-box"
            onClick={onSubmit}
            size={ButtonSizes.md}
            width="100%"
            testId="ApprovalWorkflowModalMessage-submit-button"
            label={`settings.approvalWorkflows.modal.${modalMode}.button`}
          />
        </ModalButtonsWrapper>
      }
    />
  );
};

// TODO: remove it once we align all z-indexex(COENG-149)
const CustomModalMessage = styled(ModalMessage)`
  z-index: 1400;
`;
