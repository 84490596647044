import { fetchBatchBillsListSlice } from './regular-batch-bills-list-slice';
import { createBatchPaymentSlice } from './regular-batch-payments-create-slice';
import { regularBatchUpdateDeductionDatesSlice } from './regular-batch-update-deduction-dates-slice';
import { regularBatchUpdateDeliveryOptionsSlice } from './regular-batch-update-delivery-options-slice';

export const REGULAR_BATCH_PAYMENTS_FETCH_BATCH_BILLS_LIST_SUCCESS = fetchBatchBillsListSlice.actions.success;
export const REGULAR_BATCH_PAYMENTS_CREATE_SUCCESS = createBatchPaymentSlice.actions.success;
export const REGULAR_BATCH_PAYMENTS_UPDATE_DEDUCTION_DATES_SUCCESS =
  regularBatchUpdateDeductionDatesSlice.actions.success;
export const REGULAR_BATCH_PAYMENTS_UPDATE_DELIVERY_OPTIONS_SUCCESS =
  regularBatchUpdateDeliveryOptionsSlice.actions.success;
