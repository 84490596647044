export enum CardExtra {
  Color = 'color',
  Component = 'component',
  FeedType = 'feedType',
}

export enum CardComponentType {
  Tooltip = 'tooltip',
  Banner = 'banner',
  BannerInbox = 'banner_payinbox',
  TopBanner = 'topbanner',
}

export type CardExtras = {
  [CardExtra.Color]?: string;
  [CardExtra.Component]: CardComponentType;
  [CardExtra.FeedType]: string;
  [key: string]: unknown;
};

export type Card = {
  expiresAt: Date | null;
  extras?: CardExtras;
  id: string;
  pinned: boolean;
  updated: Date | null;
  viewed: boolean;
  imageUrl?: string;
  linkText?: string;
  title?: string;
  description?: string;
  url?: string;
};

export type CardsFilter = (cards: Card[]) => Card[];

export type UserAttribute = number | boolean | Date | string | string[] | null;

export interface AppboyBridge {
  closeMessage(): void;
}

export interface EngagementServiceContactInfo {
  firstName: string;
  lastName: string;
  email: string;
}

export enum EngagementPartnerNameEnum {
  MELIO = 'melio',
}

export enum EngagementCustomAttributesEnum {
  PARTNER_NAME = 'partnerName',
}

export interface EngagementService {
  identify(userId: string, contactInfo: EngagementServiceContactInfo): void;
  logCustomEvent(eventName: string, eventProperties?: Record<string, unknown>): boolean;
  showContentCards(parentNode?: Element, filterFunction?: CardsFilter): void;
  setUserAttribute(key: string, value: UserAttribute): void;
  logCardClick(card: Card): void;
  logCardImpressions(card: Card): void;
  getContentCards(): Promise<Card[]>;
  dismissContentCard(card: Card): void;
  closeInAppMessages(): void;
  updateContactInfo(contactInfo: EngagementServiceContactInfo): void;
}
