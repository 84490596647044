import { FlexboxProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import Flex from 'src/core/ds/flex';

export type FormRowProps = {
  children: ReactNode;
  size?: 'sm' | 'md';
  flexWrap?: FlexboxProps['flexWrap'];
  mb?: number;
};

export const FormRow = ({ children, size = 'md', flexWrap = 'unset', mb }: FormRowProps) => {
  const spacing = size === 'sm' ? 6 : 10;

  return (
    <Flex flexWrap={flexWrap} gap={spacing} alignItems="unset" w="full" mb={mb}>
      {children}
    </Flex>
  );
};
