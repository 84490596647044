import { featureFlags } from '@melio/shared-web';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { globalLocations } from 'src/pages/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { deleteDeliveryMethodAction, loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin, FeatureFlags } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { ReceivingMethodsSettingsPage } from './components/ReceivingMethodsSettingsPage';
import { useDeliveryMethods } from './hooks/useDeliveryMethods';

const eventPage = 'settings-receiving-methods';

export const ReceivingMethodsSettingsPageContainer = () => {
  const dispatch = useDispatch();
  const { navigate } = useNavigator();
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const ownedVendorId = useSelector(getOwnedVendorId);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showUpdateDeliveryMethodConfirmationDialog, setShowUpdateDeliveryMethodConfirmationDialog] = useState<boolean>(
    false
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
  const [deliveryMethodToDelete, setDeliveryMethodToDelete] = useState<DeliveryMethodType>(deliveryMethodFactory());
  const [deliveryMethodToEdit, setDeliveryMethodToEdit] = useState<DeliveryMethodType>(deliveryMethodFactory());
  const [hasScheduledPayments, setHasScheduledPayments] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verifyingId, setVerifyingId] = useState<number | null>(null);
  const { deliveryMethods, pendingDeliveryMethods } = useDeliveryMethods({ orgId, ownedVendorId });
  const [isMsnUpdateDeliveryMethodFeatureFlagEnabled] = featureFlags.useFeature(
    FeatureFlags.MsnUpdateDeliveryMethod,
    false
  );

  const refreshDeliveryMethods = useCallback(
    () =>
      new Promise((resolve, reject) => {
        dispatch(loadDeliveryMethodsAction(resolve, reject));
      }),
    [dispatch]
  );

  const deleteDeliveryMethod = useCallback(
    (id: number) =>
      new Promise((resolve, reject) => {
        dispatch(deleteDeliveryMethodAction(id, resolve, reject));
      }),
    [dispatch]
  );

  const onDeleteDeliveryMethod = useCallback(async () => {
    setIsLoading(true);

    try {
      await deleteDeliveryMethod(deliveryMethodToDelete.id!);
      setIsLoading(false);
      setShowDeleteDialog(false);
      setDeliveryMethodToDelete(deliveryMethodFactory());
    } catch (e) {
      setIsLoading(false);
      setShowDeleteDialog(false);
      setDeliveryMethodToDelete(deliveryMethodFactory());
    }
  }, [deleteDeliveryMethod, deliveryMethodToDelete]);

  const navigateToEditPlaidBankAccount = useCallback(() => {
    history.push({
      pathname: generatePath(globalLocations.receivingMethod.ach.plaid, {
        orgId,
        vendorId: ownedVendorId || undefined,
        deliveryMethodId: deliveryMethodToEdit.id || undefined,
      }),
      state: {
        origin: DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS,
        deliveryMethod: deliveryMethodToEdit,
        redirectUrl: settingsLocations.receivingMethods,
        exitUrl: settingsLocations.receivingMethods,
      },
    });
    analytics.track(eventPage, 'edit-bank');
  }, [deliveryMethodToEdit, history, ownedVendorId, orgId]);

  const navigateToSelectAchDeliveryMethodPage = useCallback(() => {
    const path = generatePath(
      !deliveryMethodToEdit.id
        ? globalLocations.receivingMethod.ach.addFromGetPaidSettingsWrapper.base
        : globalLocations.receivingMethod.ach.select,
      {
        orgId,
        vendorId: ownedVendorId ?? undefined,
        deliveryMethodId: deliveryMethodToEdit.id ?? undefined,
      }
    );

    navigate(path, false, {
      redirectUrl: settingsLocations.receivingMethods,
      exitUrl: settingsLocations.receivingMethods,
      isPrevDisabled: true,
      preservedState: {
        origin: DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS,
      },
    });
  }, [navigate, orgId, ownedVendorId, deliveryMethodToEdit]);

  const navigateToEditACHBankAccount = useCallback(() => {
    isMsnUpdateDeliveryMethodFeatureFlagEnabled
      ? navigateToSelectAchDeliveryMethodPage()
      : history.push({
          pathname: generatePath(globalLocations.receivingMethod.ach.edit, {
            orgId,
            vendorId: ownedVendorId || undefined,
            deliveryMethodId: deliveryMethodToEdit.id || undefined,
          }),
          state: {
            origin: DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS,
            deliveryMethod: deliveryMethodToEdit,
          },
        });
    analytics.track(eventPage, 'edit-bank');
  }, [
    deliveryMethodToEdit,
    history,
    ownedVendorId,
    orgId,
    isMsnUpdateDeliveryMethodFeatureFlagEnabled,
    navigateToSelectAchDeliveryMethodPage,
  ]);

  const goEditDeliveryMethod = useCallback(() => {
    if (deliveryMethodToEdit?.id) {
      const isPlaidAccountDeliveryMethod = !!deliveryMethodToEdit?.plaidAccount?.id;
      isPlaidAccountDeliveryMethod && isMsnUpdateDeliveryMethodFeatureFlagEnabled
        ? navigateToEditPlaidBankAccount()
        : navigateToEditACHBankAccount();
    }
  }, [
    navigateToEditACHBankAccount,
    navigateToEditPlaidBankAccount,
    isMsnUpdateDeliveryMethodFeatureFlagEnabled,
    deliveryMethodToEdit,
  ]);

  const goViewDeliveryMethod = useCallback(
    (deliveryMethod) => {
      navigate(
        generatePath(globalLocations.receivingMethod.ach.manualView, {
          orgId,
          vendorId: ownedVendorId || undefined,
          deliveryMethodId: deliveryMethod.id,
        }),
        false,
        {
          redirectUrl: settingsLocations.receivingMethods,
          exitUrl: settingsLocations.receivingMethods,
        }
      );
      analytics.track(eventPage, 'view-bank');
    },
    [navigate, orgId, ownedVendorId]
  );

  const goEditBankAccountLink = useCallback(
    (deliveryMethod) => {
      navigate(
        generatePath(globalLocations.receivingMethod.ach.linkBankAccount, {
          orgId,
          vendorId: ownedVendorId ?? undefined,
          deliveryMethodId: deliveryMethod.id,
        }),
        false,
        {
          redirectUrl: settingsLocations.receivingMethods,
          exitUrl: settingsLocations.receivingMethods,
          editLink: true,
        }
      );
      analytics.track(eventPage, 'edit-bank-account-link');
    },
    [navigate, orgId, ownedVendorId]
  );

  const cancelEditDeliveryMethod = useCallback(() => {
    setShowEditDialog(false);
    setDeliveryMethodToEdit(deliveryMethodFactory());
    setHasScheduledPayments(false);
  }, []);

  const tryEditDeliveryMethod = useCallback(
    async (deliveryMethod) => {
      await deliveryMethodsApi
        .getDeliveryMethodStatus({
          orgId,
          vendorId: ownedVendorId,
          id: deliveryMethod.id,
        })
        .then(({ hasScheduledPayments }) => {
          setShowEditDialog(true);
          setDeliveryMethodToEdit(deliveryMethod);
          setHasScheduledPayments(hasScheduledPayments);
        });
    },
    [orgId, ownedVendorId]
  );

  const cancelDeleteDeliveryMethod = useCallback(() => {
    setShowDeleteDialog(false);
    setDeliveryMethodToDelete(deliveryMethodFactory());
    setHasScheduledPayments(false);
  }, []);

  const tryDeleteDeliveryMethod = useCallback(
    async (deliveryMethod) => {
      await deliveryMethodsApi
        .getDeliveryMethodStatus({
          orgId,
          vendorId: ownedVendorId,
          id: deliveryMethod.id,
        })
        .then(({ hasScheduledPayments }) => {
          setShowDeleteDialog(true);
          setDeliveryMethodToDelete(deliveryMethod);
          setHasScheduledPayments(hasScheduledPayments);
        });
    },
    [orgId, ownedVendorId]
  );

  const goAddDeliveryMethods = useCallback(() => {
    analytics.track(eventPage, 'add-bank');

    navigateToSelectAchDeliveryMethodPage();
  }, [navigateToSelectAchDeliveryMethodPage]);

  const onVerifyFinished = useCallback(() => {
    analytics.track(eventPage, 'verify-finish');
    refreshDeliveryMethods();
    setVerifyingId(null);
  }, [refreshDeliveryMethods]);

  const onVerifyClicked = useCallback((id: number) => {
    analytics.track(eventPage, 'verify-click');
    setVerifyingId(id);
  }, []);

  const handleUpdateDeliveryMethod = (deliveryMethod: DeliveryMethodType) => {
    setShowUpdateDeliveryMethodConfirmationDialog(true);
    setDeliveryMethodToEdit(deliveryMethod);
  };

  const handleCancelUpdateDeliveryMethod = () => {
    setShowUpdateDeliveryMethodConfirmationDialog(false);
    setDeliveryMethodToEdit(deliveryMethodFactory());
  };

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <ReceivingMethodsSettingsPage
      hasScheduledPayments={hasScheduledPayments}
      showEditDialog={showEditDialog}
      showUpdateDeliveryMethodConfirmationDialog={showUpdateDeliveryMethodConfirmationDialog}
      showDeleteDialog={showDeleteDialog}
      deliveryMethods={deliveryMethods}
      deliveryMethodToEdit={deliveryMethodToEdit}
      deliveryMethodToDelete={deliveryMethodToDelete}
      verifyingId={verifyingId}
      onVerifyClicked={onVerifyClicked}
      goAddDeliveryMethods={goAddDeliveryMethods}
      goEditDeliveryMethod={goEditDeliveryMethod}
      onDeleteDeliveryMethod={onDeleteDeliveryMethod}
      tryEditDeliveryMethod={tryEditDeliveryMethod}
      tryDeleteDeliveryMethod={tryDeleteDeliveryMethod}
      cancelDeleteDeliveryMethod={cancelDeleteDeliveryMethod}
      cancelEditDeliveryMethod={cancelEditDeliveryMethod}
      onVerifyFinished={onVerifyFinished}
      goEditBankAccountLink={goEditBankAccountLink}
      goViewDeliveryMethod={goViewDeliveryMethod}
      pendingDeliveryMethods={pendingDeliveryMethods}
      onUpdateDeliveryMethod={handleUpdateDeliveryMethod}
      onCancelUpdateDeliveryMethod={handleCancelUpdateDeliveryMethod}
    />
  );
};
