import isEmpty from 'lodash/isEmpty';
import { Loader } from 'src/components/common/Loader';
import { Checkbox } from 'src/core/ds/checkbox';
import menu from 'src/core/theme/components/menu';
import { EmptyMenu } from 'src/pages/pay/components/filters/components/VendorsMenu/EmptyMenu';
import { VendorMenuOption } from 'src/pages/pay/components/filters/components/VendorsMenu/hooks/useVendorsMenu';
import { MAX_SELECTED_VENDORS_COUNT } from 'src/pages/pay/components/filters/consts';

type Props = {
  isLoading: boolean;
  onChange: (value) => void;
  checkboxList: (VendorMenuOption & { isChecked?: boolean | undefined })[];
};

export const VendorsList = ({ isLoading, checkboxList, onChange }: Props) => {
  const selectedVendorsCount = checkboxList.filter((item) => item.isChecked).length;
  const canSelectCheckbox = selectedVendorsCount < MAX_SELECTED_VENDORS_COUNT;

  if (isLoading) {
    return <Loader color="primary" context="page" />;
  }

  return (
    <>
      {isEmpty(checkboxList) ? (
        <EmptyMenu />
      ) : (
        checkboxList.map(({ id, vendorName: text, isChecked }) => (
          <Checkbox
            {...menu.baseStyle().item}
            sx={{
              '& span': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                apply: 'textStyles.body4Semi',
              },
            }}
            alignItems="center"
            key={id}
            isDisabled={isChecked ? false : !canSelectCheckbox}
            isChecked={isChecked}
            value={id}
            textStyle="body4Semi"
            onChange={onChange}
          >
            {text}
          </Checkbox>
        ))
      )}
    </>
  );
};
