import { renderDayContentsCustomFinancing } from 'src/components/common/datepicker/utils';
import { DeliveryDateFormat } from 'src/components/common/DeliveryDateFormat';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Separator } from 'src/core/ds/separator';
import { PopoverDatePicker } from 'src/pages/bill/pay/installments/pages/repayment-terms/PopoverDatePicker';
import { isBusinessDay } from 'src/utils/dates';

type Props = {
  deliveryDate: Date;
  maxScheduledDate: Date;
  minScheduledDate: Date;
  scheduleDate: Date;
  dueDate: Date;
  maxDeliveryDate: Date;
  onDateChange: (Date) => void;
  isDisabled?: boolean;
};

export const CustomFinancingDatePicker = ({
  deliveryDate,
  maxScheduledDate,
  minScheduledDate,
  scheduleDate,
  dueDate,
  maxDeliveryDate,
  onDateChange,
  isDisabled = false,
}: Props) => {
  const getFilteredDate = (date) => isBusinessDay(date);

  const renderDayContentsCustom = (day, date) =>
    renderDayContentsCustomFinancing({
      day,
      date,
      maxDate: maxScheduledDate,
      scheduledDate: scheduleDate,
      dueDate,
      deliveryDate,
      isFastAchBetterExposeEtaEnabled: false,
    });

  return (
    <>
      <Box data-testid="financing-payment-date-picker-label" textStyle="body4Semi" textColor="grey.600">
        <MIFormattedText label="financing.repaymentTerms.date.datePickerLabel" />
      </Box>
      <Box textStyle="body2" textColor="black" mt={1}>
        <PopoverDatePicker
          maxScheduledDate={maxScheduledDate}
          minScheduledDate={minScheduledDate}
          scheduledDate={scheduleDate}
          dueDate={dueDate}
          onDateChange={onDateChange}
          renderDayContents={renderDayContentsCustom}
          filterDate={getFilteredDate}
          isDisabled={isDisabled}
        />
      </Box>
      <Separator color="grey.500" size="sm" my={1} />
      <Box textStyle="caption1" textColor="grey.700">
        <MIFormattedText
          label="financing.repaymentTerms.date.deliveryEta"
          values={{ date: <DeliveryDateFormat date={deliveryDate} maxDate={maxDeliveryDate} /> }}
        />
      </Box>
    </>
  );
};
