import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import mapValues from 'lodash/mapValues';
import { useCallback, useMemo } from 'react';
import { DrawerBody, DrawerCloseButton, DrawerFooter, DrawerHeader } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { InvoiceType } from 'src/modules/invoices/types';
import { CardHeader } from 'src/pages/get-pro/components/cards/CardHeader';
import useCardActionsAndState from 'src/pages/get-pro/components/cards/hooks/useCardActionsAndState';
import InvoiceCard from 'src/pages/get-pro/components/cards/invoice/InvoiceCard';
import InvoiceCardActions from 'src/pages/get-pro/components/cards/invoice/InvoiceCardActions';
import { useDrawerTabs } from 'src/pages/get-pro/components/drawer/components/tabs/hooks/useDrawerTabs';
import { EventEntity } from 'src/pages/get-pro/consts';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import useLoadGetProList from 'src/pages/get-pro/hooks/useLoadGetProList';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';
import { SyncedItemIcon } from './SyncedItemIcon';
import { DrawerTabs } from './tabs/DrawerTabs';
import { TimelineDrawer } from './TimelineDrawer';

type Props = {
  invoice: InvoiceType;
  onHeaderClick?: () => void;
  onClose: () => void;
  editMode?: boolean;
};

export const InvoiceDrawer = ({ invoice, onClose, onHeaderClick, editMode }: Props) => {
  const store = MAP_STORE_BY_TAB[GetProTabs.INVOICES];
  const { listParams } = useGetProListParams();
  const { loadGetProList } = useLoadGetProList(listParams);
  const actions = useStoreActions(store);
  const remove = async () => {
    await actions.proDelete({
      orgId: listParams.orgId,
      id: invoice?.id,
    });
    onClose();
  };
  const accountingSoftwareName = useGetAccountingSoftwareName(invoice?.createOrigin);
  const {
    onCancel,
    onSend,
    onSubmit,
    deleteFile,
    onEditToggle,
    markAsPaid,
    openPdfPreview,
    customers,
    uploadFile,
    fileUrls,
    uploading,
    invoiceMV,
    loadingFileUrls,
    loading,
    inEditMode,
  } = useCardActionsAndState({
    invoice,
    editMode,
    tab: GetProTabs.INVOICES,
  });

  const onSubmitEdit = useCallback(async () => {
    await onSubmit();
    await loadGetProList();
    onEditToggle(false);
  }, [loadGetProList, onSubmit, onEditToggle]);

  const onMarkInvoiceAsPaid = useCallback(async () => {
    onClose();
    await markAsPaid();
  }, [markAsPaid, onClose]);

  const onSendInvoice = useCallback(() => {
    onClose();
    onSend();
  }, [onClose, onSend]);

  const notValid = useMemo(
    () => !isValidationOk(getValidationErrors('paymentRequest', mapValues(invoiceMV, 'value'))),
    [invoiceMV]
  );
  const { tabs, activeTab, onTabClick } = useDrawerTabs(GetProTabs.INVOICES);
  const isTimelineActiveTab = activeTab === tabs.timeline.name;

  return (
    <>
      <DrawerHeader flexDirection="column" minH="auto" pb="0">
        <Flex justify="space-between" w="full">
          <CardHeader
            companyName={invoice?.customer?.contactName || ''}
            description={invoice?.invoiceNumber ? `#${invoice?.invoiceNumber}` : ''}
            Icon={
              accountingSoftwareName ? (
                <SyncedItemIcon placement="bottom" accountingSoftwareName={accountingSoftwareName} />
              ) : undefined
            }
            onClick={onHeaderClick}
          />
          <DrawerCloseButton size="md" />
        </Flex>
        <DrawerTabs tabs={tabs} activeTab={activeTab} onTabClick={onTabClick} />
      </DrawerHeader>
      <DrawerBody pb={10} pt={4}>
        {activeTab === tabs.details.name && (
          <InvoiceCard
            invoiceMV={invoiceMV}
            invoice={invoice}
            inEditMode={inEditMode}
            uploading={uploading}
            loading={loading}
            loadingFileUrls={loadingFileUrls}
            fileUrls={fileUrls}
            customers={customers}
            remove={remove}
            onEditToggle={onEditToggle}
            onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
            deleteFile={deleteFile}
            uploadFile={uploadFile}
            openPdfPreview={openPdfPreview}
          />
        )}

        {isTimelineActiveTab && (
          <TimelineDrawer entity={invoice} entityType={EventEntity.PAYMENT_REQUEST} entityId={invoice.id} />
        )}
      </DrawerBody>
      {!isTimelineActiveTab && (
        <DrawerFooter>
          <InvoiceCardActions
            inEditMode={inEditMode}
            loading={loading}
            uploading={uploading}
            notValid={notValid}
            onSubmit={onSubmitEdit}
            onCancel={onCancel}
            onClose={onClose}
            onSend={onSendInvoice}
          />
        </DrawerFooter>
      )}
    </>
  );
};
