import 'normalize.css';
import './base.css';

import successImage from '../images/bills/dollar.svg';
import scheduledForApproval from '../images/bills/scheduled-for-approval-icon.svg';
import successImageETAChanges from '../images/bills/success.svg';
import cantFindImage from '../images/funding-sources/cant-find-bank-icon.svg';
import moneyBagImage from '../images/funding-sources/money-bag.svg';
import bankPendingVerificationImage from '../images/funding-sources/pending-verification-icon.svg';
import NotFoundPage from '../pages/general/components/NotFoundPage';

const theme = {
  background: {
    default: 'inherit',
    wizard: 'rgba(250, 250, 250, 1)',
  },
  fontFamily: "'Poppins', sans-serif",
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 0,
    outerMargin: 0,
  },
  text: {
    color: {
      main: 'rgba(33, 33, 36, 1)',
      red: 'rgba(208, 2, 27, 1)',
      green: 'rgba(0, 210, 143, 1)',
      subtitle: 'rgba(113, 113, 115, 1)',
      label: 'rgba(142, 142, 144, 1)',
      readonly: 'rgba(214, 214, 216, 1)',
      error: 'rgba(208, 2, 27, 1)',
      light: 'rgba(194, 194, 195, 1)',
      message: 'rgba(0, 210, 143, 1)',
      highlight: 'rgba(120, 73, 255, 1)',
      blue: 'rgba(4,145,255,1)',
    },
    weight: {
      bold: 700,
      semiBold: 600,
      regular: 400,
    },
    size: {
      successTitle: '7rem',
      bigTitle: '5rem',
      successTitleM: '3.4rem',
      bigTitleM: '2.3rem',
      title: '3.4rem',
      subTitle: '2.3rem',
      subNav: '1.8rem',
      regular: '1.6rem',
      sectionTitle: '1.4rem',
      sectionTitleM: '1.2rem',
      hint: '1.2rem',
      small: '1rem',
      xSmall: '0.8rem',
      button: '1.4rem',
      buttonSpace: '0.1rem',
      linkHint: '1.4rem',
      wizardTitle: '4.3rem',
      wizardTitleMobile: '3rem',
      wizardText: '1.6rem',
      wizardStepTitle: '3.2rem',
      wizardStepTitleMobile: '2.3rem',
    },
    lineHeight: {
      regular: '2.4rem',
      bigTitleM: '2.8rem',
      hint: '1.6rem',
      medium: '2rem',
    },
    fontType: {
      regular: `
        font-size: 1.6rem;
        line-height: 2.4rem;
      `,
      medium: `
        font-size: 1.4rem;
        line-height: 2rem;
      `,
      hint: `
        font-size: 1.2rem;
        line-height: 1.6rem;
      `,
      small: `
      font-size: 1rem;
      line-height: 1.4rem;
    `,
    },
  },
  zIndex: {
    menu: 1040,
    mobileHeader: 1010,
    mobileTabs: 1000,
    fab: 1020,
    singleMobileView: 2000,
    overlay: 10000,
    toast: 11000,
  },
  sizes: {
    mobileHeaderHeight: '6.4rem',
    mobileHeaderTabsHeight: '4rem',
    searchBarHeight: '4rem',
    attachmentLarge: '17.9rem',
    sideMenu: '7.3rem',
  },
  animation: {
    transition: {
      menu: '150ms',
      default: '300ms',
    },
  },
  buttons: {
    boxShadow: '0 0.5rem 1rem 0 rgba(33, 33, 36, 0.2)',
  },
  // @TODO find a better system for colors
  colors: {
    brand: 'rgba(120, 73, 255, 1)',
    destructive: 'rgba(208, 2, 27, 1)',
    text: 'rgba(33, 33, 36, 1)',

    primary: {
      opaque: 'rgba(114, 68, 246, 1)',
      opaqueDarker: 'rgba(87, 50, 191, 1)',
      translucent3: 'rgba(120, 73, 255, 0.3)',
      translucent2: 'rgba(120, 73, 255, 0.2)',
      translucent1: 'rgba(120, 73, 255, 0.1)',
      active: 'rgba(102, 61, 220, 1)',
      destructive: 'rgba(208, 2, 27, 1)',
    },
    accented: {
      opaque: 'rgba(0, 210, 143, 1)',
      translucent2: 'rgba(0, 210, 143, 0.2)',
      translucent3: 'rgba(0, 210, 143, 0.3)',
      translucent05: 'rgba(0, 210, 143, 0.05)',
      active: 'rgba(0, 188, 128, 1)',
    },
    dark: {
      opaque: 'rgba(33, 33, 33, 1)',
      lighter: 'rgba(69, 69, 69, 1)',
      translucent05: 'rgba(33, 33, 36, 0.05)',
      translucent04: 'rgba(33, 33, 36, 0.04)',
      translucent03: 'rgba(33, 33, 36, 0.03)',
      translucent6: 'rgba(33, 33, 36, 0.6)',
      translucent5: 'rgba(33, 33, 36, 0.5)',
      translucent3: 'rgba(33, 33, 36, 0.3)',
      translucent2: 'rgba(33, 33, 36, 0.2)',
      translucent1: 'rgba(33, 33, 36, 0.1)',
      transparent: 'rgba(0, 0, 0, 0)',
    },
    light: {
      opaque: 'rgba(153, 153, 156, 1)',
      translucent5: 'rgba(153, 153, 156, 0.5)',
      translucent1: 'rgba(153, 153, 156, 0.1)',
    },
    white: {
      opaque: 'rgba(255, 255, 255, 1)',
      veryLightGrey: 'rgba(250, 250, 250, 1)',
      lightBlue: 'rgba(244, 245, 248, 1)',
      whiteSmoke: 'rgba(246,246,246, 1)',
    },
    border: {
      grey: 'rgba(231, 231, 231, 1)',
      lightGrey: 'rgba(238, 238, 238, 1)',
      darkGrey: 'rgba(219, 219, 219, 1)',
      dark: 'rgba(153, 153, 156, 1)',
      halfDarkGrey: 'rgba(219, 219, 219, 0.5)',
      quarterDarkGrey: 'rgba(219, 219, 219, 0.3)',
    },
    success: {
      opaque: 'rgba(0, 210, 143, 1)',
      translucent1: 'rgba(0, 210, 143, 0.1)',
    },
    teal: {
      opaque: 'rgba(7, 192, 164, 1)',
      translucent1: 'rgba(7, 192, 164, 0.1)',
      translucent2: 'rgba(0, 210, 143, 1);',
    },
    expired: {
      opaque: '#ff602aff',
      translucent2: 'rgba(255, 96, 42, 0.2)',
    },
    warning: {
      opaque: 'rgba(234, 183, 25, 1)',
      translucent1: 'rgba(234, 183, 25, 0.1)',
      translucent2: 'rgba(234, 183, 25, 0.2)',
    },
    failure: {
      opaque: 'rgba(208, 2, 27, 1)',
      translucent1: 'rgba(208, 2, 27, 0.1)',
      translucent2: 'rgba(208, 2, 27, 0.2)',
    },
    progress: {
      done: 'rgba(120, 73, 255, 1)',
      translucent2: 'rgba(120, 73, 255, 0.2)',
      undone: 'rgba(208, 208, 208, 1)',
      scheduled: 'rgba(4,145,255,0.1)',
    },
    intuit: {
      active: 'rgba(0, 119, 197, 1)',
      hover: 'rgba(0, 119, 197, 0.1)',
    },
    icons: {
      card: 'rgba(33, 33, 33, 1)',
      bgNormal: 'rgba(238, 238, 238, 1)',
      bgHover: 'rgba(222, 222, 222, 1)',
    },
  },
  icons: {
    'support-icon': '\\e947',
    'seen-check': '\\e938',
    'payment-repeat': '\\e937',
    'copy-link': '\\e935',
    'bank-transfer': '\\e936',
    pay: '\\e933',
    'request-new': '\\e934',
    'add-logo-image': '\\e931',
    request: '\\e932',
    'download-file': '\\e930',
    'camera-scan-bill': '\\e92f',
    'eta-cal': '\\e92c',
    'upload-file': '\\e92d',
    'add-manually': '\\e92e',
    dashboard: '\\e927',
    'schedule-to-delivery': '\\e928',
    'scheduled-bill': '\\e929',
    calendar: '\\e92a',
    'logout-icon': '\\e92b',
    'full-checkmark-icon': '\\e900',
    'hamburger-icon': '\\e901',
    'notification-icon': '\\e902',
    'question-icon': '\\e903',
    'phone-icon': '\\e904',
    'email-icon': '\\e905',
    'next-icon-2': '\\e906',
    'prev-icon': '\\e907',
    'prev-icon-2': '\\e907',
    'manual-icon': '\\e908',
    'network-icon': '\\e909',
    'drag-n-drop-icon': '\\e90a',
    'user-icon': '\\e90b',
    'search-magnifier-icon': '\\e90c',
    'checkmark-icon': '\\e90d',
    'select-icon': '\\e90e',
    'next-page-icon': '\\e90f',
    'last-page-icon': '\\e910',
    'first-page-icon': '\\e911',
    'prev-page-icon': '\\e912',
    'user-choose-icon': '\\e913',
    'scheduled-icon': '\\e914',
    'paypal-icon': '\\e915',
    'wire-transfer-icon': '\\e916',
    'small-dropdown-arrow': '\\e917',
    'large-dropdown-arrow': '\\e918',
    'close-icon': '\\e919',
    'plus-icon': '\\e91a',
    'minus-icon': '\\e944',
    'processing-icon': '\\e91b',
    'hide-icon': '\\e91c',
    'show-icon': '\\e91d',
    'debit-card-icon': '\\e91e',
    'invoices-icon': '\\e91f',
    'check-icon': '\\e920',
    'bank-icon': '\\e921',
    'credit-card-icon': '\\e922',
    'payments-2-icon': '\\e923',
    'settings-icon': '\\e924',
    'bills-icon': '\\e925',
    'payments-icon': '\\e926',
    'support-settings-icon': '\\e94d',
    'billing-settings-icon': '\\e94e',
    'sync-settings-icon': '\\e94f',
    'add-users-icon': '\\e950',
    'bell-icon': '\\e977',
    'receiving-method-icon': '\\e951',
    'account-settings-icon': '\\e952',
    'company-settings-icon': '\\e953',
    'referral-program-icon': '\\e955',
    'notification-checkmark-icon': '\\e956',
    'payment-pending-approval': '\\e957',
    'decline-icon': '\\e94a',
    'cancel-remove-icon': '\\e94b',
    'edit-pencil-icon': '\\e94c',
    'warning-icon': '\\e954',
    'link-icon': '\\e949',
    'upload-attachment': '\\e948',
    'intuit-arrow-datepicker': '\\e95a',
    'customer-icon': '\\e95c',
    'vendor-icon': '\\e95b',
    'back-arrow': '\\e95e',
    'companies-icon': '\\e95d',
    'team-icon': '\\e97c',
    'intuit-back-arrow': '\\e95e',
    'support-icon-streak': '\\e947',
    'business-owner-icon': '\\e964',
    'accountant-icon': '\\e965',
    'bookkeeper-icon': '\\e966',
    'wallet-icon': '\\e926',
    'employee-icon': '\\e967',
    'intuit-edit-manual': '\\e968',
    'intuit-camera': '\\e969',
    'intuit-photo': '\\e96a',
    'intuit-delete': '\\e96b',
    'intuit-info-icon': '\\e96c',
    'qb-link-status': '\\e96d',
    'attachment-icon': '\\e970',
    'slow-delivery-icon': '\\e971',
    'fast-delivery-icon': '\\e972',
    'help-question-icon': '\\e973',
    'warning-hollow-icon': '\\e974',
    'warning-tri-icon': '\\e975',
    'info-hollow-icon': '\\e976',
    'dirctory-confirmed': '\\e97d',
    'short-logo': '\\e97e',
    globe: '\\e97f',
  },
  components: {
    ViewBill: {
      showHeaderWithCompanyName: true,
    },
  },
  pages: {
    CantFindBankPage: {
      cantFindImage,
    },
    PayBillSuccessPage: {
      successImage,
      scheduledForApproval,
    },
    PayBillSuccessPageContainer: {
      successImage: successImageETAChanges,
    },
    CompleteAddingBankAccountsPage: {
      pendingImage: bankPendingVerificationImage,
      successImage: moneyBagImage,
    },
    NotFoundPage,
  },
};

export type DefaultThemeType = typeof theme;

export { theme };
