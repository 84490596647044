import isNil from 'lodash/isNil';
import { MICategoryList } from 'src/components/common/MICategoryList';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { productReceivedOptions } from 'src/utils/bills';

type Props = {
  onNext: () => void;
  goExit: () => void;
  onPrev: () => void;
  onChange: (selectedId: string) => void;
  isLoading: boolean;
  selectedId: string | null;
};

const ExpeditePaymentPage = ({ onNext, onPrev, goExit, onChange, selectedId, isLoading }: Props) => (
  <StepLayoutPage
    title="bills.pay.expedite.title"
    subtitle="bills.pay.expedite.subtitle"
    goExit={goExit}
    onNext={onNext}
    onPrev={onPrev}
    isLoading={isLoading}
    isNextDisabled={isNil(selectedId)}
  >
    <MICategoryList selectedId={selectedId} categories={productReceivedOptions} onSelect={onChange} />
  </StepLayoutPage>
);

export default ExpeditePaymentPage;
