import { BoxProps, forwardRef, Heading } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { PureComponent } from 'react';
import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { TrackingStatuses } from 'src/utils/consts';
import { PaymentTrackerStage } from 'src/utils/types';

type TrackerProps = {
  additionalStrings: Record<string, any>;
  stages: PaymentTrackerStage[];
};

// used to mark item of stage that's before `current stage`
// to avoid hard-implemented css-trick for immediate previous sibling
const PAYMENT_TRACKER_ITEM_CLASS_PRE_ACTIVE = 'payment-tracker-item-pre-active';

class PaymentProgressTracker extends PureComponent<TrackerProps> {
  setPreActiveIndex(/* trackingStages: PaymentTrackerStage[] */) {
    this.preActiveIndex = -1;

    // TODO: find what status should be compared here, because stage.status is never SCHEDULED
    // trackingStages.forEach((stage, index) => {
    //   if (stage.status === PAYMENT_TRACKER_STAGE_STATUS.SCHEDULED) {
    //     this.preActiveIndex = index - 1;
    //   }
    // });
  }

  getStageComponent = (status: TrackingStatuses): any => {
    /* eslint-disable no-use-before-define */
    switch (status) {
      case TrackingStatuses.COMPLETED:
        return StageItemCompleted;
      case TrackingStatuses.CURRENT:
        return StageItemScheduled;
      case TrackingStatuses.PENDING:
        return StageItem;
      case TrackingStatuses.FAILED:
        return StageItemFailed;
      default:
        return StageItem;
    }
    /* eslint-enable no-use-before-define */
  };

  preActiveIndex = -1;

  renderStages = (stage: PaymentTrackerStage, index: number) => {
    const { label, values, timestamp, status, title } = stage;
    const StageItemComponent = this.getStageComponent(status);
    const preActiveClassName: string = this.preActiveIndex === index ? PAYMENT_TRACKER_ITEM_CLASS_PRE_ACTIVE : '';

    return (
      <StageItemComponent className={preActiveClassName} key={`${index}`}>
        <Box
          className="payment-tracker-item-title"
          color={textColor(status)}
          h={4}
          textStyle="caption1Uppercase"
          ml="-px"
        >
          <MIFormattedText label={title} />
        </Box>
        <Box className="payment-tracker-item-text" textStyle="body4Semi" mt="px" color={textColor(status)}>
          <MIFormattedText label={label} values={values} />
        </Box>
        <Box h={4} color="grey.700" letterSpacing="0.014rem" m="0.6rem 0 0 -0.1rem" textStyle="caption1">
          <MIFormattedDateTime date={timestamp} />
        </Box>
      </StageItemComponent>
    );
  };

  render() {
    const { stages, additionalStrings } = this.props;
    const { trackerTitle } = additionalStrings;

    if (isEmpty(stages)) {
      return null;
    }

    this.setPreActiveIndex();

    return (
      <Box w={{ base: '60%', sm: '32rem' }} textAlign="left" mb={-10}>
        <Heading as="h2" color="black" textStyle="body2" fontWeight="bold" lineHeight="2rem" mb={3}>
          <MIFormattedText label={trackerTitle} />
        </Heading>
        <Box
          pl="3.5rem"
          __css={{
            '.payment-tracker-item-pre-active': {
              '&:before': {
                h: '102%',
              },
            },
          }}
        >
          {stages.map(this.renderStages)}
        </Box>
      </Box>
    );
  }
}

const textColor = (status: TrackingStatuses) => {
  switch (status) {
    case TrackingStatuses.COMPLETED:
    case TrackingStatuses.CURRENT:
      return 'black';
    case TrackingStatuses.FAILED:
      return 'red.500';
    case TrackingStatuses.PENDING:
    default:
      return 'grey.700';
  }
};

const commonStageItemCSS = {
  p: '0 0 2rem 0',
  m: '0 0 1.6rem 0',
  minH: '5.2rem',
  w: 'full',
  fontWeight: 400,
  __css: {
    '::before': {
      content: "''",
      pos: 'absolute',
      left: '-2.75rem',
      top: '1.3rem',
      h: '107%',
      w: '0.2rem',
      bgColor: 'grey.400',
    },
    '::after': {
      content: "''",
      pos: 'absolute',
      top: '0.2rem',
      left: '-3.24rem',
      w: 3,
      h: 3,
      border: '0.2rem solid white',
      borderRadius: 'full',
      bgColor: 'grey.400',
      boxSizing: 'border-box',
    },
    ':last-child': {
      '&:before': {
        background: 'none',
      },
    },
    '.payment-tracker-item-text': {
      fontWeight: 400,
      m: '0.1rem 0 0 -0.1rem',
      letterSpacing: '0.002rem',
    },
    '.payment-tracker-item-title': {
      letterSpacing: '0.105rem',
    },
  },
};

const StageItem = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box ref={ref} pos="relative" {...props} {...commonStageItemCSS} />
));

const StageItemFailed = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box ref={ref} pos="relative" {...props} {...commonStageItemCSS} _after={{ bgColor: 'red.500' }} />
));

const StageItemScheduled = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    ref={ref}
    pos="relative"
    {...props}
    {...commonStageItemCSS}
    _after={{
      content: `'\\E900'`,
      fontSize: '1.8rem',
      fontFamily: "'Melio' !important",
      color: 'primary.500',
      top: 0,
      left: '-3.5rem',
      background: 'none',
      lineHeight: '1.6rem',
      border: 'none',
    }}
    _before={{
      mt: '0.45rem',
      background: 'linear-gradient(0deg,rgba(217,217,217,1) 0%,rgba(120,73,255,1) 100%)',
    }}
    sx={{
      '.payment-tracker-item-text': {
        fontWeight: 600,
        letterSpacing: '-0.003rem',
      },
      '.payment-tracker-item-title': {
        letterSpacing: '0.096rem',
      },
    }}
  />
));

const StageItemCompleted = forwardRef<BoxProps, 'div'>((props, ref) => (
  <Box
    ref={ref}
    pos="relative"
    {...props}
    {...commonStageItemCSS}
    _after={{ bgColor: 'primary.500' }}
    _before={{ bgColor: 'primary.500' }}
    sx={{
      '.payment-tracker-item-title': {
        letterSpacing: '0.1rem',
      },

      '.payment-tracker-item-text': {
        fontWeight: 600,
        letterSpacing: '0.011rem',
      },
    }}
  />
));

export default PaymentProgressTracker;
