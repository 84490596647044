import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import loadingImage from 'src/images/melio-logo-loader.gif';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore from 'src/modules/organizations/organizations-store';
import { authLocations } from 'src/pages/auth/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { getIsLoggedIn, getOrgId } from 'src/redux/user/selectors';
import { useQueryString } from 'src/utils/hooks';
import intuit from 'src/utils/intuit';
import locations from 'src/utils/locations';

function goIntuitSignin(realmId: string) {
  intuit.goConnectToIntuit({
    intuitReturnUrl: `${authLocations.intuit.disconnect}?realmId=${realmId}`,
    quickbooksChooseRealmId: realmId,
    intent: 'melioSyncSignIn',
  });
}

export const IntuitDisconnect = () => {
  const orgId = useSelector(getOrgId);
  const [historyPush] = useHistoryWithOrgId();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { realmId } = useQueryString();
  const { unlinkQuickbookSlice } = useStoreActions(organizationStore);

  useEffect(() => {
    disconnectUser();

    async function disconnectUser() {
      if (isLoggedIn && orgId) {
        if (realmId) {
          try {
            await unlinkQuickbookSlice(orgId);
            historyPush({ path: settingsLocations.accountingSoftware });
          } catch (e: any) {
            if (e.code === 'ATH11') {
              // User not linked, send to Intuit - might not be the same user
              goIntuitSignin(realmId);
            } else if (e.code === 'ATH12') {
              // RealmID doesn't match, go to intuit's OpenID
              goIntuitSignin(realmId);
            } else {
              historyPush({
                path: settingsLocations.accountingSoftware,
              });
            }
          }
        }
      } else {
        // If the user is not logged in, go to the Intuit Open ID
        goIntuitSignin(realmId);
      }
    }
  }, [historyPush, realmId, isLoggedIn, orgId, unlinkQuickbookSlice]);

  if (!realmId) {
    return <Redirect to={locations.index.url()} />;
  }

  return <SuccessLayoutPage illustration={loadingImage} title="onboarding.accountingSoftware.checking.title" />;
};
