import { ActionOption } from 'src/components/common/MIActionsMenu';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { analytics } from 'src/services/analytics';

type Props = {
  actions: ActionOption[];
};

export const ContactActionsMenu = ({ actions }: Props) => {
  const onMenuClick = () => {
    analytics.trackAction(`actions-menu-click`);
  };

  const onActionClick = () => {
    analytics.trackAction(`actions-menu-item-click`);
  };

  return (
    <Menu autoSelect={false} placement="top-end">
      <MenuButton
        onClick={onMenuClick}
        data-testid="contact-actions-menu"
        label="actionsMenu.label"
        pt={2}
        pr={4}
        pb={2}
        pl={5}
        variant={ButtonVariants.tertiary}
        as={Button}
        rightIcon={<Icon name={IconNames.shevronDown} color="black" size={IconSize.s} />}
        size={ButtonSizes.md}
      />
      <MenuList>
        {actions.map(({ label, action, negative }) => (
          <MenuItem
            data-testid={`button-${label}`}
            key={label}
            as={Button}
            boxSizing="border-box"
            width="full"
            onClickCapture={onActionClick}
            onClick={action}
            label={label}
            color={negative ? 'red.500' : 'black'}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
