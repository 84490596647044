import map from 'lodash/map';
import qs from 'qs';
import { mapBillsAndInvoices, mapInvoice, mapPaymentRequestToInvoice, sortItems } from 'src/modules/invoices/utils';
import { getInvoicesFilter } from 'src/pages/get-paid/utils';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { PaymentRequestTabs } from 'src/utils/consts';

const isRequestByTab = (tab) => tab !== PaymentRequestTabs.SCHEDULED && tab !== PaymentRequestTabs.PAID;

export const invoicesApi = {
  create(params: any) {
    const { orgId } = params;
    const url = `/orgs/${orgId}/payment-request`;

    return postRequest(url, { isInvoice: true, ...params }).then(mapInvoice);
  },
  list({ filters, orgId, fromAction = false }) {
    const params = getInvoicesFilter(filters);
    const isRequest = isRequestByTab(filters?.tab);

    if (!isRequest) {
      const url = `/orgs/${orgId}/bills/vendor-bills`;

      return fetchRequest(url, { ...params, includePaidInvoices: true }).then((res) => ({
        items: sortItems(mapBillsAndInvoices([...res.bills, ...res.invoices]), filters?.tab),
        totalCount: res.totalCount,
      }));
    }

    const url = `/orgs/${orgId}/payment-request?${qs.stringify(params)}`;

    return fetchRequest(url).then((res) => ({
      items: map(res.requests, (item) => mapPaymentRequestToInvoice(item)),
      totalCount: res.totalCount,
      ...(fromAction ? { actionIds: res.requests.map(({ id }) => id) } : {}),
    }));
  },
  fetch(params: any) {
    const { id, orgId, tab } = params;
    const isRequest = isRequestByTab(tab);
    const url = isRequest ? `/orgs/${orgId}/payment-request/${id}` : `/orgs/${orgId}/bills/vendor/${id}`;

    return fetchRequest(url).then(mapInvoice);
  },
  update(params: any) {
    const { id, orgId } = params;
    const url = `/orgs/${orgId}/payment-request/${id}`;

    return putRequest(url, params).then(mapInvoice);
  },
  share(params: any) {
    const { orgId, data } = params;
    const url = `/orgs/${orgId}/payment-request/share`;

    return postRequest(url, data).then(() => ({
      id: data.paymentRequestId,
      ...data,
    }));
  },
  delete(params: any) {
    const { orgId, id } = params;
    const url = `/orgs/${orgId}/payment-request/${id}`;

    return deleteRequest(url, params);
  },
  cancel(params: any) {
    const { orgId, id } = params;
    const url = `/orgs/${orgId}/payment-request/${id}/cancel`;

    return putRequest(url, params).then(mapInvoice);
  },
  createBatch({ orgId, data }) {
    const url = `/orgs/${orgId}/payment-request/batch`;

    return postRequest(url, data).then((res) => res.invoices);
  },
  markAsSend(params: any) {
    const { orgId, id, data } = params;
    const url = `/orgs/${orgId}/payment-request/${id}/invoice`;

    return postRequest(url, data).then(({ paymentRequest }) => paymentRequest);
  },
  resend(params: any) {
    const { orgId, id } = params;
    const url = `/orgs/${orgId}/payment-request/${id}/resend`;

    return postRequest(url, params).then(() => ({ id }));
  },
  getCounters(params: any) {
    const url = `/orgs/${params.orgId}/payment-request/counters`;

    return fetchRequest(url);
  },
  batchShare(params: any) {
    const { orgId, data } = params;
    const url = `/orgs/${orgId}/payment-request/batch-share`;

    return postRequest(url, data).then((result) => ({
      invoices: map(result.invoices, mapPaymentRequestToInvoice),
      contacts: result.contacts,
    }));
  },
  batchCancel(params: any) {
    const { orgId, data } = params;
    const url = `/orgs/${orgId}/payment-request/batch-cancel`;

    return putRequest(url, data).then((result) => ({
      invoices: map(result.invoices, mapPaymentRequestToInvoice),
    }));
  },
  batchDelete(params: any) {
    const { orgId, data } = params;
    const url = `/orgs/${orgId}/payment-request/batch-delete`;

    return deleteRequest(url, data);
  },
  toggleMarkAsPaid(params: any) {
    const { orgId, id } = params;
    const url = `/orgs/${orgId}/payment-request/${id}/toggle-marked-as-paid`;

    return postRequest(url, params).then((result) => ({
      invoice: mapPaymentRequestToInvoice(result.invoice),
    }));
  },
};
