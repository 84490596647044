import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

export const PAGE_EVENT = 'settings-email-notifications';

export const MAPPING: EventMappingsTree = {
  '/orgs/*/settings/email-notifications': {
    'page.view': [PAGE_EVENT, 'page-load'],
    ...mapApiSlice('[NOTIFICATIONPREFERENCES] UPDATE', PAGE_EVENT, 'update-notification-preference', {
      request: { payload: 'payload' },
      success: { name: 'payload.name', enabled: 'payload.enabled' },
    }),
    'notification-preference-toggle-clicked': [
      PAGE_EVENT,
      'notification-preference-toggle-clicked',
      { notificationGroup: 'notificationGroup', enabled: 'enabled' },
    ],
  },
};
