import React from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Grid } from 'src/core/ds/grid';
import { Image } from 'src/core/ds/image';
import { AccountingSoftware } from 'src/utils/consts';
import { accountingSoftwareCardContainer, accountingSoftwareCardStyle } from './styles';

type Option = {
  accountingSoftware: AccountingSoftware;
  logo?: string;
  width?: number;
};

type Props = {
  options: Option[];
  selectedAccountingSoftware: AccountingSoftware | undefined;
  onSelectAccountingSoftware: (accountingSoftware: AccountingSoftware) => void;
};

export const AccountingSoftwareCards = ({ options, selectedAccountingSoftware, onSelectAccountingSoftware }: Props) => (
  <Grid gap={4} data-testid="cards-container">
    {options.map(({ accountingSoftware, logo, width }) => (
      <Box
        sx={accountingSoftwareCardContainer}
        key={accountingSoftware}
        onClick={() => onSelectAccountingSoftware(accountingSoftware)}
      >
        <Flex sx={accountingSoftwareCardStyle} data-selected={accountingSoftware === selectedAccountingSoftware}>
          <Image maxWidth={161} src={logo} alt={accountingSoftware} width={width} />
        </Flex>
      </Box>
    ))}
  </Grid>
);
