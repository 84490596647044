import { getDeliveryMethodByType } from 'src/pages/vendor/records';
import { DeliveryType, VendorManagedByEnum } from 'src/utils/consts';
import { DeliveryMethodType, VendorType } from 'src/utils/types';

export const getDeliveryMethodOptions = ({
  deliveryMethods,
  isInternationalEnabled,
  vendor,
}: {
  deliveryMethods: DeliveryMethodType[];
  isInternationalEnabled?: boolean;
  vendor?: VendorType;
}) => {
  const ach = getDeliveryMethodByType(DeliveryType.ACH, deliveryMethods);
  const check = getDeliveryMethodByType(DeliveryType.CHECK, deliveryMethods);
  const virtual = getDeliveryMethodByType(DeliveryType.VIRTUAL, deliveryMethods);
  const rpps = getDeliveryMethodByType(DeliveryType.RPPS, deliveryMethods);
  const virtualCard = getDeliveryMethodByType(DeliveryType.VIRTUAL_CARD, deliveryMethods);
  const international = getDeliveryMethodByType(DeliveryType.INTERNATIONAL, deliveryMethods);
  const options = [virtual, ach, check];

  const isManagedVendor = vendor?.managedBy && vendor?.managedBy !== VendorManagedByEnum.BILLER;

  if (isManagedVendor) {
    if (virtualCard?.id) {
      return [virtualCard];
    }

    if (ach?.id) {
      return [ach];
    }
  }

  if (virtualCard?.id) {
    return [virtualCard];
  }

  if (rpps?.id) {
    return [rpps];
  }

  if (international?.id) {
    return [international];
  }

  if (isInternationalEnabled) {
    options.push(international);
  }

  return options;
};

export const getUpdatedByLabel = (dm: DeliveryMethodType): string => {
  if (dm.deliveryType === DeliveryType.VIRTUAL_CARD) {
    return 'deliveryMethods.addedBy';
  }

  return 'deliveryMethods.updatedBy';
};

export const getDeliveryMethodIcon = (dm: DeliveryMethodType): string | undefined => {
  if (dm.deliveryType === DeliveryType.ACH) {
    return 'icon-bank-transfer';
  }

  if (dm.deliveryType === DeliveryType.CHECK) {
    return 'icon-check-icon';
  }

  if (dm.deliveryType === DeliveryType.RPPS) {
    return 'icon-dirctory-confirmed';
  }

  if (dm.deliveryType === DeliveryType.INTERNATIONAL) {
    return 'icon-globe';
  }

  if (dm.deliveryType === DeliveryType.VIRTUAL) {
    return undefined;
  }

  return 'icon-bank-transfer';
};
