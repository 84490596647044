import merge from 'lodash/merge';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { AccountingPlatform } from 'src/utils/types';
import { accountingPlatformsApi } from './api';
import { name } from './consts';

type ConnectRequest = {
  orgId: number;
  id: number;
  redirect: string;
};

type ConnectState = {
  byId: Record<string, AccountingPlatform>;
  connect: {
    id?: number;
    loading?: boolean;
    error?: any;
    authLink?: string;
    externalId?: string;
  };
};

type ConnectResponse = {
  accountingPlatform: AccountingPlatform;
};

export const ConnectSlice = createApiCallSlice<ConnectRequest, ConnectState, ConnectResponse>({
  initialState: {
    byId: {},
    connect: {},
  },
  name: generateSliceName(name, 'connect'),
  api: accountingPlatformsApi.connect,
  reducers: {
    [ON_REQUEST](state, action) {
      state.connect = { id: action.payload.id, loading: true };
    },
    [ON_SUCCESS](state, action) {
      const { accountingPlatform } = action.payload;

      if (accountingPlatform.id) {
        state.byId[accountingPlatform.id] = merge(state.byId[accountingPlatform.id] || {}, accountingPlatform);
        state.connect = {
          loading: false,
          id: accountingPlatform.id,
          authLink: action.payload.accountingPlatform.authLink,
          externalId: action.payload.accountingPlatform.externalId,
        };
      } else {
        state.connect = { loading: false };
      }
    },
    [ON_FAILURE](state, action) {
      state.connect = { loading: false, error: action.error, id: action.payload.id };
    },
  },
  selectors: {
    status: (state) => state[name].connect,
    authLink: (state) => state[name].connect?.authLink,
    externalId: (state) => state[name].connect?.externalId,
  },
});
