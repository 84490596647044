import { DrawerFooter } from 'src/core/ds/drawer';
import Flex from 'src/core/ds/flex';

export const FooterContainer = ({ children }: { children: JSX.Element }) => (
  <DrawerFooter>
    <Flex direction="row" flexGrow={1} justify="flex-end">
      {children}
    </Flex>
  </DrawerFooter>
);
