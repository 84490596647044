import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { companiesLocations } from 'src/pages/companies/locations';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { getListBankAccountPath } from 'src/pages/receiving-method/utils';
import { CHECK_SYNC_STATUS_INTERVAL } from 'src/pages/settings/components/accounting-software/consts';
import { usePollingSyncStatus } from 'src/pages/settings/hooks/usePollingSyncStatus';
import { settingsLocations } from 'src/pages/settings/locations';
import { getSyncAnimation } from 'src/pages/settings/syncSSO/utils';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { AccountingSoftware, SyncStatus } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { syncSSOLocations } from './locations';

export const RedirectFromCodatPage = () => {
  const query = useQueryString();
  const { connectedAccountingPlatform, status } = useFetchAccountingPlatformsAndData();
  const accountingSoftwareName = connectedAccountingPlatform?.name;
  const orgId = useSelector(getOrgId);
  const deliveryMethods = useSelector(getDeliveryMethods);
  const skipReconciliationFlow =
    (connectedAccountingPlatform && !connectedAccountingPlatform.paymentAccountReconciliationEnabled) ||
    !deliveryMethods.length;
  const ownedVendorId = useSelector(getOwnedVendorId);

  const getIsSyncing = async () => {
    const result = await accountingPlatformsApi.getSyncStatus({ orgId });
    const isSyncComplete = result.syncStatus === SyncStatus.SYNCED;

    return !isSyncComplete;
  };

  const { startPollingStatus, isSyncing } = usePollingSyncStatus({
    getIsSyncing,
    interval: CHECK_SYNC_STATUS_INTERVAL,
    autoStartPolling: false,
    isSyncingDefault: true,
  });

  const PollStatus = async () => {
    if (!skipReconciliationFlow) {
      startPollingStatus();
    }
  };

  useEffect(() => {
    PollStatus();
  }, [connectedAccountingPlatform]);

  const getExitUrlByRedirect = (orgId: number, redirect: RedirectOriginPages) => {
    const MAP_EXIT_URL = {
      [RedirectOriginPages.PAID_DASHBOARD]: getPaidLocations.create.connectAccountingSoftware.connect,
      [RedirectOriginPages.SETTINGS]: settingsLocations.accountingSoftware,
      [RedirectOriginPages.ACCOUNTANT_NEW_CLIENT]: companiesLocations.create.connectAccountingSoftwareReturn,
    };

    const url = MAP_EXIT_URL[redirect] || [RedirectOriginPages.SETTINGS];

    return generatePath(url, { orgId });
  };

  if (!status || status.loading) {
    return (
      <>
        <AreaLoader />
      </>
    );
  }

  if (skipReconciliationFlow) {
    return (
      <Redirect
        push
        to={{
          pathname: generatePath(syncSSOLocations.base, { orgId }),
          state: {
            queryParams: { ...query },
            ...(query.redirect
              ? {
                  redirectUrl: generatePath(syncSSOLocations.base, { orgId }),
                  exitUrl: getExitUrlByRedirect(orgId, query.redirect as RedirectOriginPages),
                }
              : {}),
          },
        }}
      />
    );
  }

  if (isSyncing) {
    return (
      <StepLayoutPage
        title="settings.newAccountingSoftware.SSOSync.bankAccounts.title"
        subtitle="settings.newAccountingSoftware.SSOSync.bankAccounts.subtitle"
        relativeStep={1}
        animatedIllustration={getSyncAnimation(accountingSoftwareName as AccountingSoftware, true)}
      />
    );
  }

  const linkBankAccountPath = getListBankAccountPath({
    vendorId: ownedVendorId!,
    orgId,
    deliveryMethodId: deliveryMethods[0].id!,
  });

  return (
    <Redirect
      push
      to={{
        pathname: linkBankAccountPath,
        state: {
          queryParams: { ...query },
          redirectUrl: generatePath(syncSSOLocations.base, { orgId }),
          exitUrl: getExitUrlByRedirect(orgId, query.redirect as RedirectOriginPages),
        },
      }}
    />
  );
};
