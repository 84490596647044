import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

type Props = {
  id?: string;
  positiveLabel?: string | null;
  negativeLabel?: string | null;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean, id?: string) => any;
  className?: string;
};

export const MISlider = ({ id, positiveLabel, negativeLabel, value, onChange, disabled, className }: Props) => {
  const label = value ? positiveLabel : negativeLabel;
  const idSlider = `${label || ''}_checkbox`;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked, id);
  };

  return (
    <Container className={className}>
      {label && (
        <Label htmlFor={id || idSlider}>
          <MIFormattedText label={label} />
        </Label>
      )}
      <BooleanSliderContainer>
        <Checkbox
          id={id || idSlider}
          disabled={disabled}
          checked={value}
          onChange={handleOnChange}
          data-testid={id || idSlider}
        />
        <RoundSlider />
      </BooleanSliderContainer>
    </Container>
  );
};

const SIZE = 'calc(2rem - 4px)';
const ANIMATION_SPEED = '0.2s';

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Label = styled.label`
  font-size: 1.6rem;
  color: ${(props) => props.theme.text.color.main};
  margin-right: 1rem;
  cursor: pointer;
  min-width: 2.4rem;
`;

export const RoundSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(194, 194, 195, 1);
  -webkit-transition: ${ANIMATION_SPEED};
  transition: ${ANIMATION_SPEED};
  border-radius: ${SIZE};

  :before {
    position: absolute;
    content: '';
    height: ${SIZE};
    width: ${SIZE};
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: ${ANIMATION_SPEED};
    transition: ${ANIMATION_SPEED};
    border-radius: 50%;
  }
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  :enabled:checked + ${RoundSlider} {
    background-color: ${(props) => props.theme.colors.accented.opaque};
  }

  :enabled:focus + ${RoundSlider} {
    box-shadow: 0 0 1px ${(props) => props.theme.colors.accented.opaque};
  }

  :checked + ${RoundSlider}:before {
    transform: translateX(calc(2rem - 2px));
  }
`;

const BooleanSliderContainer = styled.label`
  position: relative;
  display: inline-block;

  width: 3.8rem;
  height: 2rem;

  ${Checkbox} {
    display: none;
  }
`;
