import { ReactNode } from 'react';
import Box from 'src/core/ds/box';

type LabelProps = {
  children?: ReactNode;
};

export const Label = ({ children }: LabelProps) => (
  <Box textStyle="caption1Uppercase" color="grey.700" pl={4} letterSpacing="0.02rem" mb={2}>
    {children}
  </Box>
);
