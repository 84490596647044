import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import partition from 'lodash/fp/partition';
import sortBy from 'lodash/fp/sortBy';
import { useEffect, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { CountriesGroup, CountryType } from 'src/pages/vendor/international-delivery-method/consts';
import { getSupportedCountries } from 'src/services/api/international';
import { CountryOptionRes, InternationalCountryGroup } from 'src/utils/ServerTypes';

const mapCountries = (country: CountryOptionRes): CountryType => ({
  label: country.label,
  value: country.code.toUpperCase(),
  region: country.region || InternationalCountryGroup.ALL,
  isSupported: country.isSupported,
  billingType: country.billingType,
  risk: country.risk,
});

const POPULAR_COUNTRIES = ['CA', 'GB', 'DE'];

export const useCountryOptions = (vendorId): [[CountriesGroup, CountriesGroup], boolean] => {
  const [countryOptions, setCountryOptions] = useState<[CountriesGroup, CountriesGroup]>([
    { groupLabel: InternationalCountryGroup.POPULAR, options: [] },
    { groupLabel: InternationalCountryGroup.ALL, options: [] },
  ]);
  const orgId = useOrgId();
  const { onApiCall: getCountries, loading: loadingCountries } = useApi({ api: getSupportedCountries });

  useEffect(() => {
    let unmounted = false;

    const getCountryOptions = async () => {
      const { countries } = await getCountries({
        orgId,
        vendorId,
      });

      const options = flow(
        map(mapCountries),
        partition(
          (country) => country.region === InternationalCountryGroup.POPULAR && POPULAR_COUNTRIES.includes(country.value)
        ),
        map(sortBy<CountryType>('label')),
        ([popularCountries, allCountries]) =>
          [
            { groupLabel: InternationalCountryGroup.POPULAR, options: popularCountries },
            { groupLabel: InternationalCountryGroup.ALL, options: allCountries },
          ] as [CountriesGroup, CountriesGroup]
      )(countries);

      !unmounted && setCountryOptions(options);
    };

    getCountryOptions();

    return () => {
      unmounted = true;
    };
  }, [getCountries, orgId, vendorId]);

  return [countryOptions, loadingCountries];
};
