import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { Illustration, IllustrationName, IllustrationSize } from 'src/core/ds/illustration';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { intercomService } from 'src/services/intercom';
import { billPayLocations } from '../../../locations';

export const GeneralErrorPage = () => {
  const { navigate } = useNavigator();
  const { id: billId } = useSelector(getBill);
  const orgId = useSelector(getOrgId);

  const openSupportChat = () => {
    intercomService.show();
  };

  const handleClick = () => {
    navigate(generatePath(billPayLocations.funding, { orgId, billId }), true);
  };

  return (
    <Flex height="full" width="full" justifyContent="center" alignItems="center">
      <Flex direction="column" height="full" maxWidth="48.8rem" justifyContent="center" alignItems="center">
        <Box mb={3}>
          <Illustration name={IllustrationName.errorScreen} size={IllustrationSize.lg} />
        </Box>
        <Box textAlign="center" textStyle="h1Semi" mb={2} data-testid="financing-general-error-title">
          <MIFormattedText label="financing.errors.generalError.title" />
        </Box>
        <Box textAlign="center" textStyle="body2" mb={12}>
          <MIFormattedText
            label="financing.errors.generalError.description"
            values={{
              contactSupport: (
                <Box as="a" cursor="pointer" onClick={openSupportChat} textStyle="link1">
                  <MIFormattedText label="financing.errors.generalError.contactSupport" />
                </Box>
              ),
            }}
          />
        </Box>
        <Button
          w="full"
          mb={12}
          label="financing.errors.generalError.cta"
          onClick={handleClick}
          size={ButtonSizes.lg}
          testId="financing-general-error-cta"
        />
      </Flex>
    </Flex>
  );
};
