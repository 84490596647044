import { featureFlags } from '@melio/shared-web';
import { Location } from 'history';
import { MutableRefObject, ReactElement, ReactNode, RefObject } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Fab } from 'src/components/layout/SideMenu/components/Fab';
import { ExpandedButton } from 'src/core/components/expandedButton';
import { getFabProps } from 'src/core/components/expandedButton/utils';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useBreak, withNavigator } from 'src/hoc';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { getOrgId } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/featureFlags';
import { shouldShowSinglePageView } from 'src/utils/query-utils';
import { LoadingOverlay } from './LoadingOverlay';
import { listContainerStyle, projectionContainerStyle, titleStyle } from './styles';

/**
 * ListPage - in use only with ListLayoutPage
 */
type ListPageProps = {
  title: string;
  listViewRef?: RefObject<any>;
  children: ReactNode;
  eventPage?: string;
  device: {
    isDesktop: boolean;
  };
  query: {
    id?: string;
  };
  location: Location;
  showNewItemButton?: boolean;
};

const ListPageComp = ({ title, children, query, listViewRef, location, showNewItemButton }: ListPageProps) => {
  const { isDesktop } = useBreak();
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const isMSNDashboardDisplay = useIsMsnDashboardDisplay();
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const { handleClick, page } = getFabProps({
    path: location.pathname,
    orgId,
    history,
    location,
    isMSNDashboardDisplay,
    shouldSplitContactsTab,
  });
  const shouldShowRegularFab = !isDesktop && showNewItemButton;

  return (
    <Box
      ref={listViewRef}
      className={shouldShowSinglePageView(query) ? 'single' : undefined}
      __css={listContainerStyle}
    >
      <Box p={{ md: '0 7%' }} m={{ lg: '0 auto' }} maxW={{ lg: '40rem' }}>
        {isDesktop && (
          <Flex __css={titleStyle}>
            <Box data-testid="list-layout-page-title">
              <MIFormattedText label={title} />
            </Box>
            {showNewItemButton && <ExpandedButton label={`fab.newFab.${page}`} onClick={handleClick} testId={page} />}
          </Flex>
        )}

        {shouldShowRegularFab && <Fab isSideMenuOpen={false} />}
        {children}
      </Box>
    </Box>
  );
};

/**
 * ViewSinglePage - in use only with ListLayoutPage
 */
type ViewSinglePageProps = {
  children: React.ReactNode;
  query: {
    id?: string;
    singlePageView?: string;
  };
  singleViewRef: MutableRefObject<any>;
  noPadding?: boolean;
  largeWidth?: boolean;
};

const ViewSinglePageComp = ({ children, query, singleViewRef, noPadding, largeWidth }: ViewSinglePageProps) => {
  const shouldShowSinglePage = shouldShowSinglePageView(query) ? 'single' : undefined;

  return (
    <Box className={shouldShowSinglePage} __css={projectionContainerStyle}>
      <Box
        data-testid="projection-scrollable"
        ref={singleViewRef}
        overflowY="auto"
        h="full"
        p={{ md: noPadding ? 0 : '4rem 7% 0 7%' }}
      >
        <Box maxW={largeWidth ? 'full' : '63rem'} my={0} mx="auto" height="full">
          {children}
        </Box>
      </Box>
    </Box>
  );
};

ViewSinglePageComp.defaultProps = {};

const ListPage = compose(withNavigator())(ListPageComp);
const ViewSinglePage = compose(withNavigator())(ViewSinglePageComp);
export { ListPage, ViewSinglePage };

type Props = {
  title: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  listViewRef?: RefObject<any>;
  eventPage?: string;
  singleViewRef?: MutableRefObject<HTMLElement | undefined>;
  showNewItemButton?: boolean;
  testId?: string;
};

export const ListLayoutPage = ({
  title,
  isLoading,
  children,
  listViewRef,
  eventPage,
  showNewItemButton,
  singleViewRef,
  testId,
}: Props) => {
  let listPage = <></>;
  let viewPage = <></>;

  React.Children.forEach(children, (child) => {
    if (child) {
      const c: ReactElement<typeof ListPage | typeof ViewSinglePage> = child as any;

      if (c.type === ListPage) {
        listPage = (
          <ListPage
            listViewRef={listViewRef}
            showNewItemButton={showNewItemButton}
            {...c.props}
            eventPage={eventPage}
            title={title}
          />
        );
      } else if (c.type === ViewSinglePage) {
        viewPage = <ViewSinglePage singleViewRef={singleViewRef} {...c.props} />;
      }
    }
  });

  return (
    <LoadingOverlay isLoading={!!isLoading}>
      <Flex
        boxSizing="border-box"
        h="full"
        w="full"
        pos="absolute"
        overflow="hidden"
        bgColor="grey.200"
        data-testid={testId || 'list-page-container'}
      >
        {listPage}
        {viewPage}
      </Flex>
    </LoadingOverlay>
  );
};

ListLayoutPage.defaultProps = {
  isLoading: false,
  children: null,
  listViewRef: null,
};
