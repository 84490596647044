import { useState } from 'react';
import { ModelView } from 'src/ui/form';

export const useFormManualAddress = <T extends Partial<{ addressLine1: string }>>({
  modelView,
}: {
  modelView: ModelView<T>;
}) => {
  const [hasIntentionForManualAddress, setHasIntentionForManualAddress] = useState(false);
  const [manualAddressLine1, setManualAddressLine1] = useState('');

  const handleGoToManualAddress = () => {
    modelView.addressLine1?.onChange({ value: manualAddressLine1 });
    modelView.addressLine1?.setError?.();
    setHasIntentionForManualAddress(true);
  };

  const handleManualType = (value: string) => {
    if (hasIntentionForManualAddress) modelView.addressLine1?.onChange({ value });

    setManualAddressLine1(value);
  };

  return {
    hasIntentionForManualAddress,
    handleGoToManualAddress,
    handleManualType,
  };
};
