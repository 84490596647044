import { useIntl } from 'react-intl';
import { TaxIdType } from 'src/utils/consts';

const taxIdTypeOptionsValues = [
  {
    label: 'companyInfo.taxIdTypes.EIN',
    value: TaxIdType.EIN,
  },
  {
    label: 'companyInfo.taxIdTypes.SSN',
    value: TaxIdType.SSN,
  },
  {
    label: 'companyInfo.taxIdTypes.ITIN',
    value: TaxIdType.ITIN,
  },
];

export const useTaxIdTypeOptions = () => {
  const intl = useIntl();

  const taxIdTypeOptions = taxIdTypeOptionsValues.map(({ label, ...rest }) => ({
    ...rest,
    label: intl.formatMessage({ id: label }),
  }));

  return {
    taxIdTypeOptions,
  };
};
