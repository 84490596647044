import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { analytics } from 'src/services/analytics';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { ReactComponent as BillIcon } from './images/bills.svg';
import { invoiceAttachment, invoiceDescription } from './styles';

type Props = {
  invoiceName: string;
  invoiceUrl: string;
};

export function InvoiceAttachmentPreview({ invoiceName, invoiceUrl }: Props) {
  const handleAttachmentClick = () => {
    analytics.track('guest', 'view-attachment');

    if (window.self === window.top && invoiceUrl) {
      // we are not in an iframe, open the file normally.
      window.open(invoiceUrl);
    } else {
      // we are in an iframe, tell the parent to open the file.
      window.parent.postMessage({ action: 'open', url: invoiceUrl }, '*');
    }
  };

  return (
    <Box __css={invoiceAttachment} onClick={handleAttachmentClick} data-testid="invoice-attachment-preview">
      <Box>
        <BillIcon />
      </Box>
      <Box __css={invoiceDescription}>
        <MIFormattedText label="guests.paymentRequest.invoiceAttachment" />
        <Flex alignItems="center">
          <Box
            flex={1}
            isTruncated
            textStyle="body2"
            color="black"
            data-testid="invoice-attachment-preview-filename"
            className={FULL_STORY_MASK_RULE_CLASS}
          >
            {invoiceName}
          </Box>
          <Box textStyle="body2" color="primary.700">
            <MIFormattedText label="guests.paymentRequest.view" />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}
