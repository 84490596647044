import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import organizationStore, { getOrganizationStoreActions } from 'src/modules/organizations/organizations-store';
import { SelectCategory, useForm } from 'src/ui/form';
import { Role } from 'src/utils/consts';

type Props = {
  onPrev: () => void;
  onNext: () => void;
  onExit: () => void;
};

const isOwnerOptions = [
  {
    id: 'yes',
    label: 'categoryList.yes',
  },
  {
    id: 'no',
    label: 'categoryList.no',
  },
];

const NewCompanyRoleDetailsPage = ({ onPrev, onNext, onExit }: Props) => {
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const companyName = newCompanyInfo?.companyName;
  const model = useMemo<{ isOwner: string | null | undefined }>(() => {
    let isOwner: string | null = null;

    if (newCompanyInfo?.role) {
      isOwner = newCompanyInfo?.role === Role.OWNER ? 'yes' : 'no';
    }

    return {
      isOwner,
    };
  }, [newCompanyInfo]);

  const dispatch = useDispatch();
  const { updateNewCompanyInfo } = useMemo(() => getOrganizationStoreActions(dispatch), [dispatch]);
  const goNext = async (value: typeof model) => {
    await updateNewCompanyInfo({
      address: newCompanyInfo?.address,
      companyName: newCompanyInfo?.companyName,
      role: value.isOwner === 'yes' ? Role.OWNER : Role.ADMIN,
    });

    onNext();
  };

  const [roleDetailsVM, { submit }] = useForm(model, {
    submit: goNext,
  });

  return (
    <StepLayoutPage
      title="inviteNewCompany.contactInfo.smbIsOwner.title"
      titleValues={{
        companyName: <Title key={companyName}>{companyName}</Title>,
      }}
      relativeStep={2 / 3}
      onNext={submit}
      onPrev={onPrev}
      goExit={onExit}
      isNextDisabled={!roleDetailsVM.isOwner.value}
      nextLabel="progress.continue"
    >
      <SelectCategory options={isOwnerOptions} model={roleDetailsVM.isOwner} />
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default NewCompanyRoleDetailsPage;
