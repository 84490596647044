import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree, EventMappingType } from 'src/services/analytics/types';

const pageEvent = 'contacts';

const s = (type) => type.slice(0, -1);

const vendorAnalyticsProperties = {
  vendorId: 'vendorId',
  billerId: 'billerId',
};

const createOptionsEventMapping = (type: string): Record<string, EventMappingType> => ({
  'page.view': [pageEvent, 'vendor-create-options'],
  [`contacts.options.${type}.single.title`]: [pageEvent, `create-one-${type}`],
  [`contacts.options.${type}.qbo.title`]: [pageEvent, `sync-quickbooks-${type}`],
  [`contacts.options.${type}.multi.link`]: [pageEvent, `explain-upload-file-${type}`],
});

const createBatchExplainEventMapping = (type: string): Record<string, EventMappingType> => ({
  'page.view': [pageEvent, `batch-${type}-csv-explain`],
  'contacts.batchCsvExplain.action': [pageEvent, `batch-explain-${type}-upload-csv`],
});
const createBatchEventMapping = (type: string): Record<string, EventMappingType> => ({
  'page.view': [pageEvent, `batch-${type}`],
  'progress.continue': [pageEvent, `batch-${type}-next-column`],
  'go.back': [pageEvent, `batch-${type}-go-back`],
  'go.exit': [pageEvent, `batch-${type}-go-exit`],
  'column.selected': [pageEvent, `batch-${type}-column-selected`, { column: 'name' }],
  ...mapApiSlice(`[${type.toUpperCase()}] CREATE_BATCH`, pageEvent, `create-batch-${type}`),
});

const creatSingleEventMapping = (type: string): Record<string, EventMappingType> => ({
  'page.view': [pageEvent, `new-${s(type)}`],

  ...mapApiSlice(`[${type.toUpperCase()}] CREATE`, pageEvent, `new-${s(type)}`),
});

const listEventMapping = (type: string): Record<string, EventMappingType> => ({
  'page.view': [pageEvent, `${type}-list`],
  ...mapApiSlice(`[${type.toUpperCase()}] UPDATE`, pageEvent, `edit-${s(type)}`),
  ...mapApiSlice(`[${type.toUpperCase()}] DELETE`, pageEvent, `delete-${s(type)}`),
  'vendors.actions.delete': [pageEvent, `delete-${s(type)}`],
});

const MAPPING: EventMappingsTree = {
  '/orgs/*/contacts/vendors': {
    ...listEventMapping('vendors'),
    'contacts.view.viewLink.unpaid': [pageEvent, `view-unpaid-bills-clicked`, { vendorId: 'vendorId' }],
    'contacts.view.viewLink.scheduled': [pageEvent, 'view-scheduled-bills-clicked', { vendorId: 'vendorId' }],
    'contacts.view.viewLink.paid': [pageEvent, 'view-paid-bills-clicked', { vendorId: 'vendorId' }],
    'copy-link': [pageEvent, 'link-copied'],
    'edit-vendor': [pageEvent, `edit-vendor-mode`, vendorAnalyticsProperties],
    'cancel-vendor': [pageEvent, `cancel-edit-vendor-mode`, vendorAnalyticsProperties],
    ...mapApiSlice('[VENDORS] UPDATE', pageEvent, 'edit-vendor-done', {
      success: {
        billerId: 'payload.deliveryMethods[0].rppsAccount.billerId',
        vendorId: 'payload.id',
      },
    }),
    'copy-link-action': [pageEvent, 'meliome-link-clicked'],
    'fab.newFab.contacts': ['fab', 'new-vendor-button'], // TODO: remove this event (shouldSplitContactsTab)
    'fab.newFab.vendors': ['fab', 'new-vendor-button'],
    replaceDetailsModal: [pageEvent, 'replace-details-modal', { toggle: 'toggle' }],
    'actions-menu-click': [pageEvent, 'actions-menu-clicked'],
    'actions-menu-item-click': [pageEvent, 'actions-menu-item-clicked'],
    'toggle-search-input': ['vendors', 'toggle-search-input', { isOpen: 'isOpen' }],
    'search-in-vendors': ['search', 'in-vendors', { searchValue: 'searchValue' }],
  },
  '/orgs/*/contacts/customers': {
    ...listEventMapping('customers'),
    'vendors.actions.edit': [pageEvent, `edit-customer-mode`],
    'vendors.edit.cancel': [pageEvent, `cancel-edit-vendor-mode`],
    'contacts.view.view': [pageEvent, 'view-payment-requests-tab'],
    'fab.newFab.contacts': ['fab', 'new-customer-button'], // TODO: remove this event (shouldSplitContactsTab)
    'fab.newFab.customers': ['fab', 'new-customer-button'],
    'toggle-search-input': ['customers', 'toggle-search-input', { isOpen: 'isOpen' }],
    'search-in-customers': ['search', 'in-customers', { searchValue: 'searchValue' }],
  },
  '/orgs/*/contacts/new': {
    'page.view': [pageEvent, 'create-type'],
    'contacts.contactType.options.vendors.title': [pageEvent, 'create-vendors'],
    'contacts.contactType.options.customers.title': [pageEvent, 'create-customers'],
  },
  '/orgs/*/contacts/vendors/new/create-options': createOptionsEventMapping('customers'),
  '/orgs/*/contacts/vendors/new/single': {
    ...creatSingleEventMapping('vendors'),
    ...mapApiSlice(`[VENDORS] CREATE`, pageEvent, `new-vendor`, {
      success: {
        billerId: 'meta.identifier.billerId',
        vendorId: 'payload.id',
        managedBy: 'payload.managedBy',
        directoryId: 'meta.identifier.directoryId',
        directoryType: 'meta.identifier.directoryType',
      },
    }),
  },
  '/orgs/*/contacts/vendors/new/batch-csv-explain': createBatchExplainEventMapping('vendors'),
  '/orgs/*/contacts/vendors/new/batch-upload': {
    ...createBatchEventMapping('vendors-review-list'),
    ...mapApiSlice('[VENDORS] CREATE_BATCH', 'contacts_create', 'batch-vendors-save', {
      request: {
        countVendors: 'payload.data.length',
      },
      success: {
        countVendors: 'payload.length',
      },
    }),
  },
  '/orgs/*/contacts/vendors/new/batch-success': {
    'page.view': ['contacts_create', 'batch-vendors-success'],
    'contacts.batch.success.vendors.primaryAction': ['contacts_create', 'batch-vendors-success-go-contacts'],
    'contacts.batch.success.vendors.secondaryAction': ['contacts_create', 'batch-vendors-success-go-dashboard'],
  },
  '/orgs/*/contacts/vendors/new/*/delivery-method': {
    'page.view': ['contacts_create', 'add-delivery-method'],
  },
  '/orgs/*/contacts/customers/new/create-options': createOptionsEventMapping('customers'),
  '/orgs/*/contacts/customers/new/single': creatSingleEventMapping('customers'),
  '/orgs/*/contacts/customers/new/batch-upload': createBatchEventMapping('customers'),
  '/orgs/*/contacts/customers/new/batch-csv-explain': createBatchExplainEventMapping('customers'),
};

export default MAPPING;
