import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { AddBankAccountFlow } from '../AddBankAccountFlow';
import { FLOW_ENTRY_POINT } from '../consts';
import { FlowCustomizationsType } from '../types';

export const AddBankAccountFromGuestWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state: locationState } = useLocation<{ redirectUrl: string; preservedState: Record<string, string> }>();
  const { preservedState } = locationState || {};
  const [newFundingSourceId, setFundingSourceId] = useState<number>();

  const onFSBankAccountAdded = (newFundingSourceId) => {
    setFundingSourceId(newFundingSourceId);
    dispatch(selectFundingSourceAction(newFundingSourceId));
  };

  const saveAndContinuePath = () => {
    history.push(locationState?.redirectUrl, { ...preservedState, selectedFundingSourceId: newFundingSourceId });
  };

  const flowCustomizations: FlowCustomizationsType = {
    onFSBankAccountAdded,
    flowEntryPoint: FLOW_ENTRY_POINT.GUEST,
    newAccountNotificationLabel: 'onboarding.fundingSources.bank.accountingPlatformAccounts.notification',
  };

  return <AddBankAccountFlow flowCustomizations={flowCustomizations} saveAndContinuePath={saveAndContinuePath} />;
};
