import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useAmexIndustry } from 'src/components/funding-source/amex/hooks/useAmexIndustry';
import { AmexIndustryModal } from 'src/components/funding-source/amex/modals/AmexIndustryModal';
import { useModal } from 'src/helpers/react/useModal';
import { withNavigator } from 'src/hoc';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import useGetVendorPaymentPreferences from 'src/modules/vendors/hooks/useGetVendorPaymentPreferences';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { AddFundingSourceWizardOrigin, CardTypes, FundingType } from 'src/utils/consts';
import GuestEditFundingSourcesPage from './components/GuestEditFundingSourcesPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type Props = GuestDataProps & {
  totalAmount: string;
  companyName: string;
  vendorId: string;
  isVendorAbsorbedFee: boolean;
};

const eventPage = 'payor';
const eventName = 'select-funding-source';

const GuestEditFundingSourcesPageContainer = ({
  selectedFundingSourceId,
  link,
  navigateToGuestPage,
  onChange,
  totalAmount,
  companyName,
  nextRoute,
  prevRoute,
  vendorId,
  blockCCMoneyIn,
  isVendorAbsorbedFee,
}: Props) => {
  const { navigate } = useNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();
  const orgId = useSelector(getOrgId);
  const fundingSources = useSelector(getFundingSources);
  const { paymentRequest } = locationState || {};
  const { shouldShowAmexIndustryModal, industriesList, pendingResources } = useAmexIndustry(
    vendorId,
    selectedFundingSourceId
  );

  const { vendorPaymentPreferences, isCheckingVendorEnableCCPayments } = useGetVendorPaymentPreferences(vendorId);

  const handleSelectedFundingSource = (fundingSource) => {
    analytics.track(eventPage, 'select funding source');
    onChange({ id: 'selectedFundingSourceId', value: fundingSource.id });
  };

  const goAddSelectedFundingSource = (selectedFundingSourceType) => {
    const fundingSourcesTypesMap = {
      [FundingType.ACH]: {
        url: onboardingLocations.fundingSources.bank.addFromGuestFlow.base,
        analyticProperty: 'add-bank-account',
      },
      [CardTypes.CREDIT]: {
        url: onboardingLocations.fundingSources.card.index,
        analyticProperty: 'add-credit-card',
      },
      [CardTypes.DEBIT]: {
        url: onboardingLocations.fundingSources.card.index,
        analyticProperty: 'add-debit-card',
      },
    };

    const { analyticProperty, url } = fundingSourcesTypesMap[selectedFundingSourceType];

    if (analyticProperty && url) {
      analytics.track(eventPage, analyticProperty);
      navigate(url, false, {
        redirectUrl: nextRoute,
        exitUrl: generatePath(melioMeLocations.wizard.editFundingSource, { link }),
        preservedState: {
          origin: AddFundingSourceWizardOrigin.GUEST_ONBOARDING,
          ...locationState,
        },
      });
    }
  };

  const analyticsProps = {
    billId: null,
    paymentRequestId: paymentRequest?.id,
    vendorId,
    partialBillId: null,
    isBatch: false,
  };

  const [AmexModal, showAmexModal] = useModal(AmexIndustryModal, {
    id: 'AmexIndustryModal',
    orgId,
    vendorId,
    modalName: 'AmexIndustryModal',
    vendorName: companyName,
    industries: industriesList,
    isDataLoading: pendingResources,
    analyticsProps,
    onConfirm: async () => {
      onNextFundingSources();
    },
  });

  const onNext = () => {
    if (shouldShowAmexIndustryModal) {
      showAmexModal();
    } else {
      onNextFundingSources();
    }
  };

  const onNextFundingSources = () => {
    analytics.track(eventPage, `${eventName}-continue-success`);
    navigateToGuestPage(nextRoute);
  };

  const onPrev = () => {
    navigateToGuestPage(prevRoute);
  };

  useEffect(() => {
    analytics.track(eventPage, `${eventName}-view`);
  }, []);

  if (isCheckingVendorEnableCCPayments || pendingResources) {
    return <AreaLoader />;
  }

  return (
    <>
      {AmexModal}
      <GuestEditFundingSourcesPage
        fundingSources={fundingSources}
        handleSelectedFundingSource={handleSelectedFundingSource}
        selectedFundingSourceId={selectedFundingSourceId}
        goAddSelectedFundingSource={goAddSelectedFundingSource}
        onNext={onNext}
        onPrev={onPrev}
        totalAmount={totalAmount}
        companyName={companyName}
        isVendorEnableCCPayments={isEmpty(vendorPaymentPreferences)}
        blockCCMoneyIn={blockCCMoneyIn}
        isVendorAbsorbedFee={isVendorAbsorbedFee}
      />
    </>
  );
};

export default compose(withNavigator(), withGuestData())(GuestEditFundingSourcesPageContainer);
