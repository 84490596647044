import { InProgressDrawer } from 'src/components/shared/drawers/MSNPortalAndGetPaid/InProgressDrawer';
import { PaidDrawer } from 'src/components/shared/drawers/MSNPortalAndGetPaid/PaidDrawer';
import { Drawer, DrawerContent, DrawerOverlay } from 'src/core/ds/drawer';
import { useBreak } from 'src/hoc';
import { InvoiceStatus } from 'src/utils/consts';
import { EVENT_PAGE } from '../consts';
import { MSNMobileDrawer } from './MSNMobileDrawer';

export const MSNDrawer = ({ drawerState, onCloseDrawer }) => {
  const { isMobile } = useBreak();

  const MSNDesktopDrawer = drawerState?.status === InvoiceStatus.PAID ? PaidDrawer : InProgressDrawer;

  const DrawerComponent = isMobile ? MSNMobileDrawer : MSNDesktopDrawer;

  return (
    <Drawer isOpen={!!drawerState} onClose={onCloseDrawer} autoFocus={false}>
      <DrawerOverlay>
        <DrawerContent maxW="75.8rem">
          <DrawerComponent invoice={drawerState} onClose={onCloseDrawer} eventPage={EVENT_PAGE} />
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
