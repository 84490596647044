import { PaymentCreateFlowOrigin } from 'src/utils/consts';

export enum MarkAsPaidModalStatus {
  CLOSE = 'close',
  OPEN_MARK_BILL_AS_PAID = 'openMarkBillAsPaid',
  OPEN_MARK_PAYMENT_AS_PAID = 'openMarkPaymentAsPaid',
}

export type MarkPaymentAsPaidParams = {
  orgId: number;
  id: number;
  intuitAccountId?: string | null;
  createOrigin: PaymentCreateFlowOrigin;
};

export type MarkPaymentAsUnpaidParams = {
  orgId: number;
  id: number;
  billId: string;
};
export type MarkBillAsPaidParams = {
  orgId: number;
  id: string;
  amount?: number | null;
  intuitAccountId?: string | null;
  createOrigin: PaymentCreateFlowOrigin;
};

export type DeletePaymentParams = {
  orgId: number;
  id: string;
};

export enum RefundPaymentStatus {
  failed = 'failed',
  sent = 'sent',
  refunded = 'refunded',
}
