import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ShadowContainerWithHover } from 'src/components/common/ShadowContainer';
import Box from 'src/core/ds/box/Box';
import { InvitationSummary } from 'src/modules/invitations/components/InvitationSummary';
import { UserSummary } from 'src/modules/users/components/UserSummary';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { InvitationActionType } from 'src/utils/types';

type Props = Pick<React.ComponentProps<typeof UserSummary>, 'details' | 'rightSideRender' | 'user' | 'userRole'> & {
  actions?: any[];
};

export const TeamMemberCard = ({ user, userRole, details, actions, rightSideRender }: Props) => (
  <TeamMemberCardRoot>
    <UserSummary
      user={user}
      details={
        <Box as="span" className={FULL_STORY_MASK_RULE_CLASS}>
          {details}
        </Box>
      }
      userRole={userRole}
      actions={actions}
      rightSideRender={rightSideRender}
    />
  </TeamMemberCardRoot>
);

type TeamMemberInvitationCardProps = Pick<
  React.ComponentProps<typeof InvitationSummary>,
  'invitation' | 'rightSideRender'
> & {
  actions: InvitationActionType[];
};

export const TeamMemberInvitationCard = ({ invitation, actions, rightSideRender }: TeamMemberInvitationCardProps) => (
  <TeamMemberCardRoot>
    <InvitationSummary invitation={invitation} actions={actions} rightSideRender={rightSideRender} />
  </TeamMemberCardRoot>
);

export const PlaceholderTeamMemberCard = () => (
  <PlaceholderTeamMemberCardRoot>
    <PlaceholderTeamMemberCardIcon />
    <PlaceholderTeamMemberCardText>
      <PlaceholderTeamMemberCardBoldText>
        <MIFormattedText label="teamManagement.teamMembers.placeholderName" />
      </PlaceholderTeamMemberCardBoldText>
      <MIFormattedText label="teamManagement.teamMembers.placeholderEmail" />
    </PlaceholderTeamMemberCardText>
  </PlaceholderTeamMemberCardRoot>
);

const TeamMemberCardRoot = styled(ShadowContainerWithHover)`
  padding: 2.4rem 2rem;
  cursor: default;
`;

const PlaceholderTeamMemberCardRoot = styled.div`
  border-radius: 0.8rem;
  padding: 2.4rem 2rem;
  color: ${(props) => props.theme.text.color.light};
  border: 0.2rem dashed ${(props) => props.theme.colors.border.darkGrey};
  display: flex;
  align-items: center;
`;

const PlaceholderTeamMemberCardIcon = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.2rem solid ${(props) => props.theme.colors.border.darkGrey};
`;

const PlaceholderTeamMemberCardText = styled.div`
  margin-left: 1.4rem;
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: ${(props) => props.theme.text.size.subNav};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;

const PlaceholderTeamMemberCardBoldText = styled.div`
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;
