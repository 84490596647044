import { getValidationErrors } from '@melio/sizzers-js-common';
import sumBy from 'lodash/sumBy';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { BillType, PaymentType } from 'src/utils/types';

export function getBillValidationErrors(bill: BillType, isRecurringBill?: boolean, isPartialPaymentsEnabled?: boolean) {
  const validationFields = [
    'vendorId',
    'totalAmount',
    'invoiceNumber',
    'dueDate',
    'note',
    ...(isRecurringBill ? (['occurrences', 'frequency'] as const) : ([] as const)),
  ] as const;

  const validationErrors = getValidationErrors('bill', bill, validationFields, {
    context: { isRecurringBill },
  });
  const payments: PaymentType[] = bill.payments || [];

  if (isPartialPaymentsEnabled && payments.length) {
    const paymentsAmount = sumBy(payments, 'amount') + (bill.externallyPaid || 0);

    if (bill.totalAmount < Number(paymentsAmount) && !validationErrors.totalAmount) {
      return {
        ...validationErrors,
        totalAmount: (
          <Box>
            <MIFormattedText
              label="bills.form.partialPayments.errors.editAmount"
              values={{
                paymentsAmount: <MIFormattedCurrency value={paymentsAmount} />,
              }}
            />
          </Box>
        ),
      };
    }
  }

  return validationErrors;
}
