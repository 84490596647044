import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { organizationsApi } from 'src/modules/organizations/api';
import { usersApi } from 'src/modules/users/api';
import { vendorsApi } from 'src/modules/vendors/api';
import { companyInfoFactory } from 'src/pages/settings/records';
import { updateCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOwnedVendorId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { ADDRESS_DEFAULTS, NotificationVariant } from 'src/utils/consts';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { AddressType, CompanyInfoType, UserContextType, VendorType } from 'src/utils/types';

type Props = {
  companyInfoField: string;
  nextStepURL: string;
  prevStepURL?: string;
  onNextStep?: () => void;
  onPrevStep?: () => void;
  inputFields?: ReadonlyArray<keyof AddressType> | ReadonlyArray<keyof VendorType>;
  eventPage?: string;
};

type Field = {
  value: string | null;
  fieldName: string;
};

export const useVendorCompanyInfoUpdates = ({
  inputFields,
  companyInfoField,
  prevStepURL,
  nextStepURL,
  onNextStep,
  onPrevStep,
  eventPage = 'onboarding-vendor-company-info',
}: Props) => {
  const dispatch = useDispatch();
  const orgId = useOrgId();
  const { navigate } = useNavigator();
  const location = useLocation<Record<string, any>>();

  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const ownedVendorId = useSelector(getOwnedVendorId);
  const profile = useSelector(getProfile);
  const companyInfo = useSelector(getCompanyInfo);

  let isMounted = false;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMounted = true;

    if (companyInfo && !companyInfo.companyName) {
      loadCompanyInfo();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNext = async (
    customCompanyInfo?: CompanyInfoType,
    dataToUpdate?: Record<string, any>,
    userDataToUpdate?: Partial<UserContextType>
  ) => {
    const infoToUpdate = dataToUpdate || {
      [companyInfoField]: companyInfo[companyInfoField],
    };

    const isCreateHandlePage = companyInfoField === 'handle';
    const schemaName = isCreateHandlePage ? 'vendorUserRegistration' : 'companyInfo';
    const inputFieldsForValidation = isCreateHandlePage ? undefined : inputFields;

    let validationErrors = getValidationErrors(schemaName, infoToUpdate, inputFieldsForValidation);

    if (!isEmpty(userDataToUpdate)) {
      validationErrors = { ...validationErrors, ...getValidationErrors('user', userDataToUpdate) };
    }

    if (customCompanyInfo?.phone && customCompanyInfo?.phone.replace(/\D/g, '').length < 10) {
      validationErrors = {
        ...validationErrors,
        ...{ phone: 'inputErrors.companyInfo.phone.string.invalid' },
      };
    }

    const eventName = `add-${companyInfoField}`;

    if (['phone', 'contactName', 'companyName'].includes(companyInfoField)) {
      analytics.setTraits(infoToUpdate);
    }

    if (companyInfoField === 'address') {
      analytics.setTraits({
        city: get(infoToUpdate, 'city', ''),
        zipCode: get(infoToUpdate, 'zipCode', ''),
        addressLine1: get(infoToUpdate, 'addressLine1', '').split(',')[0],
        state: get(infoToUpdate, 'state', ''),
      });
    }

    setValidationErrors(validationErrors);

    try {
      if (!isValidationOk(validationErrors)) {
        analytics.track(eventPage, `${eventName}-validation-error`, validationErrors);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        setErrorMessage(validationErrors.handle!);

        return;
      }

      setIsLoading(true);
      analytics.track(eventPage, `${eventName}-continue-success`);

      isCreateHandlePage
        ? await onNextHandlePage(infoToUpdate)
        : await onNextAllPages(customCompanyInfo, infoToUpdate, userDataToUpdate);
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  const onPrev = (customCompanyInfo?: CompanyInfoType) => {
    const eventName = `add-${getEventNameFromLocation(location)}`;
    analytics.track(eventPage, `${eventName}-back`);

    if (onPrevStep) {
      onPrevStep();
    } else if (prevStepURL) {
      navigate(prevStepURL, false, {
        ...location.state,
        companyInfo: customCompanyInfo || companyInfo,
      });
    }
  };

  const onChange = (value: string | null, fieldName?: string) => {
    const fieldToUpdate = fieldName || companyInfoField;
    updateCompanyInfoAction(
      dispatch,
      {
        ...companyInfo,
        [fieldToUpdate]: value,
      },
      profile
    );
  };

  // use it in case you want to change multiple fields at once
  const onChangeFields = (fields: Field[]) => {
    const fieldToUpdate = fields.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue.fieldName]: currentValue.value,
      }),
      {}
    );
    updateCompanyInfoAction(
      dispatch,
      {
        ...companyInfo,
        ...fieldToUpdate,
      },
      profile
    );
  };

  const loadCompanyInfo = async () => {
    try {
      setIsLoading(true);

      if (!isMounted) return;

      if (!companyInfo.googlePlaceId) {
        updateCompanyInfoAction(
          dispatch,
          {
            ...companyInfo,
            googlePlaceId: ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID,
          },
          profile
        );
      }
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  const onNextHandlePage = async (infoToUpdate: Record<string, any>) => {
    try {
      const { handle = '' } = infoToUpdate;
      analytics.setTraits({ meliome_link: handle });
      await vendorsApi.setHandle(orgId, ownedVendorId!, { handle });

      if (onNextStep) {
        onNextStep();
      } else {
        navigate(nextStepURL, false, location.state);
      }
    } catch (e: any) {
      if (e && e.code) {
        setErrorMessage(`inputErrors.vendorUserRegistration.handle.server.${e.code}`);
        setIsLoading(false);
      }
    }
  };

  const onNextAllPages = async (
    customCompanyInfo: CompanyInfoType | undefined,
    infoToUpdate: Record<string, any>,
    userDataToUpdate?: Partial<UserContextType>
  ) => {
    try {
      const { companyInfo } = await organizationsApi.updateCompanyInfo(orgId, infoToUpdate);

      if (!isEmpty(userDataToUpdate)) {
        await usersApi.update({ id: profile.id, ...userDataToUpdate });
      }

      const companyInfoRecord = companyInfoFactory(companyInfo);

      updateCompanyInfoAction(dispatch, companyInfoRecord, profile);

      if (!nextStepURL) {
        return;
      }

      try {
        if (onNextStep) {
          onNextStep();
        } else {
          navigate(nextStepURL, false, {
            ...location.state,
            companyInfo: customCompanyInfo || companyInfo,
          });
        }
      } catch (err) {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    } catch (error: any) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: error && 'code' in error ? `server.${error?.code}` : 'serverErrors.ERR',
      });
    }
  };

  return {
    isLoading,
    validationErrors,
    errorMessage,
    onNext,
    onPrev,
    onChange,
    onChangeFields,
  };
};
