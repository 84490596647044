import { useStyleConfig } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { VStack } from 'src/core/ds/stack';

type Props = {
  children?: ReactNode;
  label?: string;
  values?: Record<string, string | ReactNode | unknown>;
};

export const Header = ({ children, label, values }: Props) => {
  const style = useStyleConfig('WizardContentHeader');

  return (
    <VStack sx={style}>
      {children || (
        <Box textStyle="display2Semi">
          <MIFormattedText label={label} values={values} />
        </Box>
      )}
    </VStack>
  );
};
