import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import Box from 'src/core/ds/box';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  amount?: string;
  privateData?: boolean;
};

const CardAmount: React.FC<Props> = ({ amount = '0', privateData }) => (
  <Box textStyle="body2" className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined}>
    <FormattedNumber value={Number(amount)} maximumFractionDigits={2} style="currency" currency="USD" />
  </Box>
);

export default CardAmount;
