export const invoicePreview = (clickable: boolean) => ({
  display: 'none',
  maxWidth: 81,
  borderColor: 'grey.400',
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 'lg',
  cursor: clickable ? 'pointer' : 'default',
  img: {
    width: '100%',
  },
});
