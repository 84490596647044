import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { DeliveryMethodOptionsList } from 'src/components/list/DeliveryMethodOptionsList';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useInternationalDetails } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalDetails';
import { vendorLocations } from 'src/pages/vendor/locations';
import { analytics } from 'src/services/analytics';
import { CompanyFormPage, ContactsTab, DeliveryMethodOrigin, DeliveryType } from 'src/utils/consts';
import { DeliveryMethodOptions } from 'src/utils/types';

type Props = {
  goExit: () => void;
  companyName: string;
  deliveryMethodOptions: DeliveryMethodOptions;
  vendorId: number;
  orgId: number;
  origin?: string;
};

const eventPage = 'new-vendor-delivery-method-options';

export const NewVendorDeliveryMethodPage = ({
  goExit,
  companyName,
  deliveryMethodOptions,
  vendorId,
  orgId,
  origin,
}: Props) => {
  const titleValues = { vendor: companyName };
  const history = useHistory();
  const { blockEmailForInternationalPayment } = useInternationalDetails();

  const onDeliveryMethodListItemClick = useCallback((deliveryType: DeliveryType) => {
    const isACHDeliveryType = deliveryType === DeliveryType.ACH;
    const createUrlSuffix = isACHDeliveryType ? 'createFlow' : 'create';
    const url = generatePath(vendorLocations.deliveryMethods[deliveryType][createUrlSuffix], {
      orgId,
      id: vendorId,
    });
    const redirectUrl = generatePath(vendorLocations.view, { orgId, id: vendorId, type: ContactsTab.VENDORS });
    const successPageRedirectUrl = generatePath(onboardingLocations.addVendor.added, { orgId, vendorId });
    analytics.track(eventPage, `select-${deliveryType}-method`, {
      vendorId,
      deliveryType,
    });

    history.push(url, {
      origin:
        CompanyFormPage.ONBOARDING === origin
          ? DeliveryMethodOrigin.NEW_VENDOR_FROM_ONBOARDING
          : DeliveryMethodOrigin.NEW_VENDOR,
      redirectUrl: CompanyFormPage.ONBOARDING === origin ? successPageRedirectUrl : redirectUrl,
      exitUrl: redirectUrl,
    });
  }, []);

  return (
    <>
      <StepLayoutPage
        goExit={goExit}
        title="contacts.create.deliveryMethods.title"
        titleValues={titleValues}
        secondaryActionButtonLabel="contacts.create.deliveryMethods.laterButton"
        onSecondaryActionButtonClick={goExit}
      >
        <DeliveryMethodOptionsList
          deliveryMethodOptions={deliveryMethodOptions}
          showFullDeliveryMethodsList={!blockEmailForInternationalPayment}
          companyName={companyName}
          goAddDeliveryMethod={onDeliveryMethodListItemClick}
        />
      </StepLayoutPage>
    </>
  );
};
