import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import doneImage from 'src/images/general/check-circle.svg';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import ConvertedUnilateralPaymentActivity from 'src/pages/vendor/virtual-delivery-method/components/ConvertedUnilateralPaymentActivity';
import { getDeliveryMethods, getIsLoggedIn, getOwnedVendorId } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';

const ShiftedCurrentPaymentPage = ({ token }) => {
  const [historyPush] = useHistoryWithOrgId();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const { contactEmail, vendorId, paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id));
  const bill = {
    totalAmount: payment?.amount,
    invoiceNumber: payment?.bill?.invoiceNumber,
  };

  const goLearnMore = () => window.open('https://www.meliopayments.com/request');

  const goSignUp = () => {
    historyPush({
      path: registrationLocations.vendorRegister,
      state: {
        email: contactEmail,
        vendorIdToConvertToOwned: vendorId,
      },
    });
  };

  if (isPaymentLoading) return <AreaLoader />;

  return (
    <OutsideLayout>
      <BillPaymentBlock>
        <BillInfoContainer>
          <DoneIcon src={doneImage} />
          <Title data-testid="success-title">
            <MIFormattedText
              label={
                ownedVendorId && orgDeliveryMethods?.length
                  ? 'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.ownedVendorTitle'
                  : 'vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.title'
              }
            />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.subtitle"
              values={{
                totalAmount: <MIFormattedCurrency value={bill.totalAmount} />,
                companyName: organization?.companyName,
                invoiceNumber: bill.invoiceNumber,
              }}
            />
          </Subtitle>
          <ConvertedUnilateralPaymentActivity payment={payment} />
        </BillInfoContainer>
        {!isLoggedIn && (
          <ShiftToAchContainer>
            <ShiftToAchTitle>
              <MIFormattedText
                label="vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.upsaleTitle"
                values={{
                  companyName: <Label>{organization?.companyName}</Label>,
                }}
              />
            </ShiftToAchTitle>
            <ShiftToAchSubtitle>
              <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.upsaleSubTitle" />
              <LearnMoreLink onClick={goLearnMore}>
                <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.learnMore" />
              </LearnMoreLink>
            </ShiftToAchSubtitle>
            <Button
              onClick={goSignUp}
              label="vendors.deliveryMethods.shiftVendorToAch.shiftedCurrentPayment.upsaleCTA"
              size={ButtonSizes.lg}
              isFullWidth
            />
          </ShiftToAchContainer>
        )}
      </BillPaymentBlock>
    </OutsideLayout>
  );
};

export default ShiftedCurrentPaymentPage;

const BillPaymentBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
`;

const BillInfoContainer = styled.div`
  padding: 4rem;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
`;

const ShiftToAchContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-radius: 0 0 0.6rem 0.6rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const ShiftToAchTitle = styled.div`
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  font-weight: ${(props) => props.theme.text.weight.bold};
  line-height: 3.2rem;
`;

const ShiftToAchSubtitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  margin: 1.2rem 0 4rem;
`;

const Label = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

const LearnMoreLink = styled.span`
  color: ${(props) => props.theme.colors.brand};
  cursor: pointer;
  ${(props) => props.theme.text.fontType.medium};
  margin-left: 0.5rem;
  font-size: 1.6rem;
`;
