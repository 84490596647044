export const iconStyle = {
  svg: {
    display: 'inline',
    h: '1.4rem',
    position: 'relative',
    top: 0.5,
  },
};

export const disabledMenuStyle = {
  pointerEvents: 'none',
};

export const containerStyle = {
  width: 'full',
  flex: 1,
  minHeight: '26.75rem',
  mt: 6,
  mb: 20,
  borderRadius: 'lg',
  overflowY: 'overlay',
  filter: 'drop-shadow(0px 5px 15px rgba(33, 33, 36, 0.1))',
  table: {
    borderBottom: 'none',
    position: 'relative',
    borderCollapse: 'collapse',
    overflowY: 'inherit',
  },
  thead: {
    tr: {
      position: 'sticky',
      top: 0,
      zIndex: 7,
    },
    'tr:first-of-type': {
      border: 'none',
    },
  },
  tbody: {
    background: 'none',
    tr: {
      border: 'none',
    },
    'tr:last-of-type': {
      borderBottomLeftRadius: 'lg',
      borderBottomRightRadius: 'lg',
      overflow: 'hidden',
    },
  },
  tr: {
    '&.isChildRow': {
      background: '#F5F5F5',
      height: 10,
      td: {
        height: 10,
      },
      _hover: {
        bg: 'grey.300',
      },
    },
    cursor: 'pointer',
    td: {
      display: 'inline-flex',
      h: '4.4rem',
      px: 4,
      alignItems: 'center',
      '>div': {
        flex: 1,
        overflow: 'hidden',
      },
      '&:first-of-type, &:last-of-type': {
        p: 0,
      },
      '&:nth-of-type(7), &:nth-of-type(8)': {
        pr: 1,
      },
    },
    th: {
      display: 'table-cell',
      px: 4,
      position: 'sticky',
      top: 0,
      '>div': {
        display: 'flex',
        h: '4.9rem',
        px: 0,
        alignItems: 'center',
        color: 'grey.300',
        '>div': {
          border: 'none',
          color: 'black',
          textTransform: 'none',
        },
      },
      '&:first-of-type': {
        px: 0,
        '>div': {
          borderLeft: '1px',
          borderTopLeftRadius: 'lg',
        },
      },
      '&:last-of-type': {
        px: 0,
        '>div': {
          borderRight: '1px',
          borderTopRightRadius: 'lg',
        },
      },
      '&:nth-of-type(7), &:nth-of-type(8)': {
        pr: 1,
      },
    },
  },
};
