import { AmexCardEnum, CardResponse } from '@melio/spend-management-api-axios-client';
import copy from 'copy-to-clipboard';
import { useSelector } from 'react-redux';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { VStack } from 'src/core/ds/stack';
import { analytics, Context, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { getValidationDateField } from 'src/pages/utils/validation-date';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { COPY_ANALYTICS_EVENT_MAPPING, DetailFieldType, FIELD_TYPE, LIMIT_NOT_SET_VALUE } from '../constants';
import { CardDetailsSection } from './CardDetailsSection';
import { StatusBadge } from './CardStatusBadge';

export const CardDetailsPlaceholder = ({ card }: { card: CardResponse }) => {
  const { legalFormattedAddress, legalZipCode } = useSelector(getCompanyInfo);
  const isCancelled = card?.status === AmexCardEnum.Cancelled;

  const handleCopy = (value, type) => {
    analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, COPY_ANALYTICS_EVENT_MAPPING[type]);

    value && copy(value);
    pushNotification({
      type: NotificationVariant.INFO,
      msg: `spendManagement.pages.dashboard.cardDetails.details.${type}.toast`,
    });
  };

  const detailFields: DetailFieldType[] = [
    { key: 'primary', nickname: card?.account?.accountNickname, value: card?.account?.lastFive },
    { key: 'limit', value: card?.spendControls?.currentAmount || 0 },
    getValidationDateField(card?.createRequest?.spendControls?.validToDate, card?.status),
    { key: 'status', value: card?.status },
    { key: 'name', value: Object.values(card?.userDetails?.name).join(' '), type: FIELD_TYPE.NAME },
    { key: 'address', value: legalFormattedAddress as string, type: FIELD_TYPE.ADDRESS },
    { key: 'zipcode', value: legalZipCode as string, type: FIELD_TYPE.ZIPCODE },
  ];

  const getSectionValue = (field: DetailFieldType): JSX.Element => {
    if (field.key === FIELD_TYPE.STATUS) {
      return <StatusBadge status={field.value as string} />;
    }

    if (field.key === FIELD_TYPE.LIMIT) {
      if (field.value === LIMIT_NOT_SET_VALUE) {
        return <MIFormattedText label="spendManagement.pages.dashboard.cardDetails.details.limit.notSet" />;
      }

      return <MIFormattedCurrency value={Number((field.value as number) / 100)} currency="$" />;
    }

    if (field.key === FIELD_TYPE.PRIMARY_CARD) {
      return (
        <MIFormattedText
          label={`spendManagement.pages.dashboard.cardDetails.details.${field.key}.value`}
          values={{ value: field.value, nickname: field.nickname }}
        />
      );
    }

    return <div>{field.value}</div>;
  };

  return (
    <VStack alignItems="flex-start" data-testid="spend-management-card-details">
      {detailFields.map(
        (field) =>
          field.value && (
            <CardDetailsSection
              key={field.key}
              data-testid={`spend-management-card-details-${field.key}`}
              title={`spendManagement.pages.dashboard.cardDetails.details.${field.key}.title`}
              value={getSectionValue(field)}
              onCopy={field.type && !isCancelled ? () => handleCopy(field.value, field.type) : undefined}
            />
          )
      )}
    </VStack>
  );
};
