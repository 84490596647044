import { featureFlags } from '@melio/shared-web';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { organizationsApi } from 'src/modules/organizations/api';
import { billLocations } from 'src/pages/bill/locations';
import { usePayBillActions } from 'src/pages/bill/pay/hooks/usePayBillActions';
import { payBillEventPage, usePayBillDataContext } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { PayBillFlowType, usePayBillFlowType } from 'src/pages/bill/pay/hooks/usePayBillFlowType';
import { usePayBillPaymentOperations } from 'src/pages/bill/pay/hooks/usePayBillPaymentOperations';
import { companyInfoFactory, isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { getBill, getIsRecurring } from 'src/redux/payBillWizard/selectors';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { convertToServerAddress, convertToServerLegalAddress } from 'src/utils/address';
import { getBillPaymentIndex } from 'src/utils/bills';
import { FeatureFlags, TaxIdType } from 'src/utils/consts';
import { isPOBox } from 'src/utils/delivery-methods';
import { GoogleCombinedAddressType, LegalAddressType, NavigateType } from 'src/utils/types';
import { isBillBelongsToSharedVendor } from '../../utils/billGetters';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
  inputFields: ReadonlyArray<keyof LegalAddressType>;
};

export const usePayBillSubmit = ({ navigate, nextStepURL, prevStepURL, inputFields }: Params) => {
  const { setIsLoading, setValidationErrors } = usePayBillDataContext();
  const [isPlatformMtlKycUplift] = featureFlags.useFeature<boolean>(FeatureFlags.PlatformMtlKycUplift, false);

  const bill = useSelector(getBill);
  const isRecurring = useSelector(getIsRecurring);
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);

  const {
    createPayment,
    createRecurringBill,
    createPaymentFromPaymentRequest,
    createInternationalPayment,
    createFinancingPayment,
    reschedulePayment,
    retryFailedToDeliver,
    retryFailedToCollect,
    updatePayment,
    cancelAndRetryPayment,
  } = usePayBillPaymentOperations({
    navigate,
    nextStepURL,
    prevStepURL,
  });

  const { setCompanyInfo } = usePayBillActions();

  const isLegalInfoRequired = () => isCompanyLegalInfoDone(companyInfo) || isBillBelongsToSharedVendor(bill);

  const payBillFlowType = usePayBillFlowType();

  const submitFlowMap = {
    [PayBillFlowType.CancelAndRetryPaymentFlow]: cancelAndRetryPayment,
    [PayBillFlowType.RescheduleDeclinedPayment]: reschedulePayment,
    [PayBillFlowType.RetryFailedToDeliver]: retryFailedToDeliver,
    [PayBillFlowType.RetryFailedToCollect]: retryFailedToCollect,
    [PayBillFlowType.RetryFailedToCollectInstallment]: retryFailedToCollect,
    [PayBillFlowType.UpdatePayment]: updatePayment,
    [PayBillFlowType.CreateRecurringPayment]: createRecurringBill,
    [PayBillFlowType.CreateFromPaymentRequest]: createPaymentFromPaymentRequest,
    [PayBillFlowType.CreateInternationalPayment]: createInternationalPayment,
    [PayBillFlowType.CreateFinancingPayment]: createFinancingPayment,
    [PayBillFlowType.CreatePayment]: createPayment,
  };

  const onSubmit = () => submitFlowMap[payBillFlowType]();

  const redirectToLegalPage = () => {
    if (isRecurring) {
      analytics.track(payBillEventPage, 'recurring-go-to-complete-legal-info', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      navigate(billLocations.pay.recurring.completeLegalInfo);
    } else {
      analytics.track(payBillEventPage, 'go-to-complete-legal-info', {
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      navigate(
        generatePath(billLocations.pay.completeLegalInfo, {
          orgId,
          billId: bill.id,
        })
      );
    }
  };
  const checkLegalAndSubmit = () => {
    if (isLegalInfoRequired()) {
      onSubmit();
    } else {
      redirectToLegalPage();
    }
  };

  const onLegalInfoSubmit = (
    address: GoogleCombinedAddressType,
    companyAddress: GoogleCombinedAddressType,
    legalCompanyName: string,
    taxId?: string,
    taxIdType?: TaxIdType,
    contactFirstName?: string,
    contactLastName?: string,
    phone?: string,
    isValidateLegalAddress?: boolean
  ) => {
    const dataToUpdate = {
      ...companyInfo,
      legalCompanyName,
      taxId,
      taxIdType,
      contactFirstName,
      contactLastName,
      phone,
      ...convertToServerAddress(companyAddress),
      ...convertToServerLegalAddress(address),
    };
    const eventName = 'complete-legal-info';
    const inputFieldsWithLegalAddress =
      isValidateLegalAddress && inputFields?.length
        ? ([...inputFields, 'legalZipCode', 'legalAddressLine1', 'legalCity'] as const)
        : inputFields;

    let validationErrors = getValidationErrors('companyInfo', dataToUpdate, inputFieldsWithLegalAddress);

    if (isPOBox(address?.formattedAddress) && isPlatformMtlKycUplift) {
      validationErrors = {
        ...validationErrors,
        legalGooglePlaceId: 'form.addressAutocomplete.invalidPOBoxAddressLabel',
      };
    }

    setValidationErrors(validationErrors);
    setIsLoading(true);

    if (isValidationOk(validationErrors)) {
      analytics.track(
        payBillEventPage,
        `${eventName}-continue-success`,
        { billPaymentIndex: getBillPaymentIndex(bill) },
        { integrations: { Salesforce: true } }
      );
      organizationsApi
        .updateCompanyInfo(orgId, dataToUpdate)
        .then(({ companyInfo: updatedCompanyInfo }) => {
          const companyInfoRecord = companyInfoFactory(updatedCompanyInfo);
          setCompanyInfo(companyInfoRecord);

          onSubmit();
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      analytics.track(payBillEventPage, `${payBillEventPage}-validation-error`, {
        ...validationErrors,
        billPaymentIndex: getBillPaymentIndex(bill),
      });
      setIsLoading(false);
    }
  };

  return { onSubmit: checkLegalAndSubmit, onLegalInfoSubmit };
};
