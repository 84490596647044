import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useModal } from 'src/helpers/react/useModal';
import NotificationCardFailedBills from 'src/pages/regular-batch-payments/components/notificationCards/NotificationCardFailedBills';
import { FailedBillsListModal } from 'src/pages/regular-batch-payments/components/wizard/BatchPaymentsSuccess/FailedBillsListModal/FailedBillsListModal';

type Props = {
  goToBillList: () => void;
};

const FailedBillsView = ({ goToBillList }: Props) => {
  const [FailedBillsList, showFailedBillsList] = useModal(FailedBillsListModal, {
    modalName: 'FailedBillsListModal',
  });

  return (
    <>
      {FailedBillsList}
      <Box textAlign="left" pb={10}>
        <NotificationCardFailedBills showFailedBillsListModal={showFailedBillsList} />
      </Box>
      <Button variant={ButtonVariants.primary} onClick={goToBillList}>
        <MIFormattedText label="regularBatchPayments.success.goDashboard" />
      </Button>
    </>
  );
};

export default FailedBillsView;
