import { getValidationErrors, SchemaName } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { ValidationError } from 'src/ui/ValidationError';
import { UserOrganization } from 'src/utils/types';

export function validateUserOrgData(
  schemaName: SchemaName,
  data: { approvalAmountThreshold?: number | null },
  currentUserOrg?: UserOrganization
) {
  const validationErrors = getValidationErrors(schemaName, data);
  const isApprovalAmountExceed =
    currentUserOrg?.requireApproval &&
    data.approvalAmountThreshold &&
    currentUserOrg?.approvalAmountThreshold &&
    currentUserOrg.approvalAmountThreshold < data.approvalAmountThreshold;

  if (isApprovalAmountExceed) {
    validationErrors.approvalAmountThreshold = `inputErrors.${schemaName}.approvalAmountThreshold.number.max`;
  }

  if (!isEmpty(validationErrors)) {
    throw new ValidationError({ validationErrors });
  }
}
