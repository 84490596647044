import 'normalize.css';
import './base.css';

import { devices } from './appDevices';

const theme = {
  text: {
    size: {
      wizardStepTitle: '3rem',
      wizardStepTitleMobile: '2.3rem',
    },
  },
  components: {
    MICard: {
      Container: (props) => `
         border: ${props.mode === 'mainSingleScreen' ? '0' : '1px solid rgba(231, 231, 231, 1)'}
         @media ${devices.phablet} {
            margin: 0;
          }
      `,
    },
    GuestRequestInfoPage: {
      Container: () => `
        margin-top: -5rem;
      `,
    },
    StepHeader: {
      StepHeaderContainer: (props) => `
        left: 0px;
        top: 0px;
        right: 0px;
        padding: 2rem;
        box-sizing: border-box;
        margin-bottom: 0 !important;
        background: ${props.theme.colors.white.veryLightGrey};`,
    },
    ProgressBar: {
      TotalBar: () => `
        height: 0.6rem !important;
      `,
    },
    WizardElements: {
      WizardOrLine: {
        WizardOptionContainer: () => `
          @media ${devices.mobile} {
            margin-bottom: 2rem;
          }
        `,
      },
      WizardLinkContainer: () => `
        @media ${devices.mobile} {
            margin-bottom: 2rem;
            line-height: 2.5rem;
          }
      `,
    },
    GuestSignUpPage: {
      ButtonContainer: () => `
        margin-bottom: 0.5rem;
        >div:nth-of-type(1) {
          margin: 2rem 0;
        }
      `,
    },
    CodeVerificationPage: {
      Footer: () => `
        width: 100%;
      `,
    },
    GuestInfoDetailsPage: {
      GuestNameContainer: () => `
        @media ${devices.mobile} {
          flex-direction: row;
        }`,
    },
    MIInputLabel: {
      InputLabel: () => `
        margin-bottom: 0.9rem;
      `,
    },
    MITextInput: {
      TextInput: () => `
        line-height: 3.4rem;
        height: 3.2rem;
      `,
    },
    NotVerifiedDescriptionText: () => `
      line-height: 2rem;
    `,
  },
};

export type MCBThemeType = typeof theme;

export { theme };
