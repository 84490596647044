import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';

type Props = {
  icon?: string;
  imageSrc?: string;
  iconSize?: IconSize;
  size?: string;
};

const FundingSourceIcon = ({ icon, imageSrc, iconSize = IconSize.m, size = '2rem' }: Props) =>
  imageSrc ? (
    <Image src={imageSrc} alt="payment-method-logo" width={size} height={size} objectFit="contain" />
  ) : (
    <Icon name={convertOldIconNameToNew(icon) as IconNames} size={iconSize} />
  );
// TODO remove after merge
const convertOldIconNameToNew = (icon?: string) => {
  switch (icon) {
    case 'icon-bank-icon':
      return IconNames.bank;
    case 'icon-credit-card-icon':
      return IconNames.creditCard;
    default:
      return icon;
  }
};

export default FundingSourceIcon;
