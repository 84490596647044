import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { getBill, getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { getInitialProcessingDates } from 'src/utils/dates';
import { removeUnsupportedDeliveryOptions } from 'src/utils/delivery-methods';
import { FeatureFlags } from 'src/utils/featureFlags';

type Params = {
  setIsLoading: (isLoading: boolean) => void;
  setDeliveryOptionsDates: (deliveryOptionsDates: any) => void;
};

export const useDeliveryOptionsDates = ({ setIsLoading, setDeliveryOptionsDates }: Params) => {
  const [isFastCheckEnabledForAllStates] = featureFlags.useFeature<boolean>(
    FeatureFlags.FastCheckEnabledForAllStates,
    false
  );
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const fundingSource = useSelector(getSelectedFundingSource);
  const orgId = useSelector(getOrgId);
  const site = useSiteContext();
  const companyInfo = useSelector(getCompanyInfo);

  const getDeliveryOptionsDates = async () => {
    setIsLoading(true);
    const { deliveryMethodId, fundingSourceId, amount, id, payBillFlowUUID, deliveryMethod } = payment;
    const deliveryOptionsDates = await getInitialProcessingDates({
      orgId,
      deliveryMethodId,
      fundingSourceId,
      scheduledDate: null,
      amount,
      dueDate: bill.dueDate,
      paymentId: id,
      payBillFlowUUID,
    });
    const possibleDeliveryOptions = removeUnsupportedDeliveryOptions({
      site,
      deliveryOptions: deliveryOptionsDates.deliveryOptions || [],
      companyInfo,
      fundingSource,
      deliveryMethod,
      isFastCheckEnabledForAllStates,
    });
    const validDeliveryOptionsDates = {
      ...deliveryOptionsDates,
      deliveryOptions: possibleDeliveryOptions,
    };

    setDeliveryOptionsDates(validDeliveryOptionsDates);
    setIsLoading(false);

    return validDeliveryOptionsDates;
  };

  return [getDeliveryOptionsDates];
};
