import { ReactElement } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type Props = {
  titleLabel: string;
  subtitleLabel: string;
  testId: string;
  vendorName: string;
  learnMoreLink?: ReactElement;
};

export const AmexModalTitle = ({ titleLabel, subtitleLabel, testId, vendorName, learnMoreLink }: Props) => (
  <>
    <Box textStyle="h2Semi" textAlign="left" mb={4} data-testid={testId}>
      <MIFormattedText
        label={titleLabel}
        values={{
          vendorName,
        }}
      />
    </Box>
    <Box textStyle="body2" textAlign="left">
      <MIFormattedText
        label={subtitleLabel}
        values={{
          vendorName,
          learnMore: learnMoreLink,
        }}
      />
    </Box>
  </>
);
