import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import requestSentStatusProgressBar from 'src/images/onboarding/msn-registration/request-sent-status-progress-bar.svg';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { ImageWithAlt } from 'src/pages/onboarding/msn-registration/public-form/components/ImageWithAlt';

export const VerifyBankAccountStatus = () => {
  const { navigate } = useNavigator();
  const analyticsData = { msnOptinFlow: true, isGuest: false, flows: 'public', dmVerified: false, msnVerified: false };
  const onNext = () => {
    navigate(getPaidLocations.verifyMicroDeposit);
  };

  return (
    <StepLayoutPage
      illustration={IllustrationName.clock}
      title="onboarding.msnCompanyInfo.verifyBankAccountStatus.title"
      subtitle="onboarding.msnCompanyInfo.verifyBankAccountStatus.subtitle"
      onNext={onNext}
      nextLabel="onboarding.msnCompanyInfo.verifyBankAccountStatus.verifyBankAccountButton"
      nextButtonAnalyticsProperties={analyticsData}
    >
      <ImageWithAlt
        src={requestSentStatusProgressBar}
        testid="verify-bank-status-image"
        altLabel="onboarding.msnCompanyInfo.verifyBankAccountStatus.imageAlt"
      />
    </StepLayoutPage>
  );
};
