import sortBy from 'lodash/sortBy';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks/useOrgId';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BY_DUE_DATE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { useBatchBillsList } from '../../hooks/useBatchBillsList';

export const useRemoveBillSubRow = (itemId: string, balance: number) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const { getSingleBatchItem } = useBatchBillsList();
  const parentRow = getSingleBatchItem(itemId);
  const { deliveryMethodId, scheduledDate, fundingSourceId } = parentRow.payment;
  const orgId = useOrgId();
  const { dateOption } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);

  const removeSubRow = async () => {
    await actions.settings.removeBillFromPayment({ itemId, parentId: parentRow.id });
  };

  const updateDeliveryOptions = async () => {
    const newAmount = parentRow.payment.amount && parentRow.payment.amount - balance;
    let deductionDate = scheduledDate;

    if (dateOption === BY_DUE_DATE_OPTION_ID) {
      const bills = parentRow.payment.bills.filter((bill) => bill.id !== itemId);
      const sortByDueDatesBills = sortBy(bills, 'dueDate');

      if (sortByDueDatesBills.length) {
        deductionDate = sortByDueDatesBills[0].dueDate;
      }
    }

    if (deliveryMethodId) {
      await actions.updatePaymentsDeliveryOptions({
        itemId: parentRow.id,
        orgId,
        deductionDate,
        deliveryMethodId,
        fundingSourceId,
        amount: newAmount,
        payBillFlowUUID: parentRow.payBillFlowUUID,
      });
    } else {
      await actions.updatePayments.setAmount({
        itemId: parentRow.id,
        amount: newAmount,
      });
    }
  };

  return { removeSubRow, updateDeliveryOptions, isLoading: parentRow.isLoading };
};
