import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { ReactComponent as ErrorIcon } from 'src/images/general/error-screen.svg';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { FAST_DELIVERY_EVENT_NAMES, FAST_DELIVERY_SERVER_EVENT_NAMES } from 'src/pages/vendor/upgrade-payment/consts';
import { useVendorUpgradePaymentAnalytics } from 'src/pages/vendor/upgrade-payment/hooks';
import { useLocationState } from 'src/utils/hooks';
import { PageWrapper } from '../../components';

export const OfferExpiredPage = ({ token }: { token: string }) => {
  const [expirationReason] = useLocationState<string>('expirationReason', 'token expired');

  const { paymentId } = getJWTPayload(token);
  const { isPaymentLoadFinished } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { trackServerAction, trackAction } = useVendorUpgradePaymentAnalytics(token);

  useEffect(() => {
    if (isPaymentLoadFinished) {
      trackServerAction(FAST_DELIVERY_SERVER_EVENT_NAMES.OFFER_EXPIRED, { reason: expirationReason });
      trackAction(FAST_DELIVERY_EVENT_NAMES.OFFER_EXPIRED, { reason: expirationReason });
    }
  }, [isPaymentLoadFinished, expirationReason, trackAction, trackServerAction]);

  return (
    <PageWrapper>
      <Flex w="100%" px={[5, 5, 10]} py={8} boxSizing="border-box" flexDirection="column" alignItems="center">
        <ErrorIcon width="13rem" height="13rem" />
        <Box textStyle="body2" textAlign="center" mt={1}>
          <MIFormattedText label="vendors.upgradePayment.offerExpired.message" />
        </Box>
      </Flex>
    </PageWrapper>
  );
};
