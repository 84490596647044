import * as React from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { DeliveryDate } from 'src/components/common/DeliveryDate';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { ReactComponent as Mastercard } from 'src/images/icons/mastercard.svg';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { deliveryMethodFactory, getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { devices } from 'src/theme/appDevices';
import { getFormattedCheckSerial } from 'src/utils/bills';
import { DeliveryType, PaymentStatus } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';

type ConvertedUnilateralPaymentActivityProps = {
  payment: PaymentType;
};

const ConvertedUnilateralPaymentActivity: React.FC<ConvertedUnilateralPaymentActivityProps> = ({ payment }) => {
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(payment?.deliveryMethodId));
  const deliveryMethodRecord = deliveryMethodFactory(deliveryMethod);
  const getBankAccountInfo = () => (
    <>
      <MIFormattedText label="vendors.addDeliveryMethodByLink.bankAccount" />
      {getDeliveryMethodDisplay({ deliveryMethod: deliveryMethodRecord, vendorName: '' })}
    </>
  );
  const getDeliveryMethodLabel = () => {
    if (isACH) {
      return 'bills.form.paymentActivity.scheduledBill.deliveryBankTransferMethod';
    }

    if (isVirtualCard) {
      return 'payBillPaymentActivity.deliveryMethod.virtualCardLabel';
    }

    return 'bills.form.paymentActivity.scheduledBill.deliveryPaperCheckMethod';
  };
  const getDeliveryMethodIcon = () => {
    if (isACH) {
      return <Icon name={IconNames.bank} size={IconSize.lg} />;
    }

    if (isVirtualCard) {
      return <Mastercard width="2.4rem" height="2.4rem" />;
    }

    return <Icon name={IconNames.paperCheck} size={IconSize.lg} />;
  };
  const getDeliverytitle = () => {
    if (isPaymentCheckCompleted) {
      return 'bills.form.paymentActivity.checkDeliveryTitleETA';
    }

    if (isVirtualCard) {
      return 'bills.form.paymentActivity.virtualCardTitleETA';
    }

    return 'bills.form.paymentActivity.deliveryTitleETA';
  };
  const isACH = deliveryMethod?.deliveryType === DeliveryType.ACH;
  const isVirtualCard = deliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD;
  const method = getDeliveryMethodLabel();
  const info = isACH
    ? getBankAccountInfo()
    : getDeliveryMethodDisplay({ deliveryMethod: deliveryMethodRecord, vendorName: '' });
  const icon = getDeliveryMethodIcon();
  const formattedCheckSerial = getFormattedCheckSerial(payment);
  const additionalDescription = isACH ? 'bills.form.paymentActivity.deliveryMethodAdditionalDescriptionPayee' : '';
  const isPaymentCheckCompleted = payment?.status === PaymentStatus.COMPLETED && !isACH;

  return (
    <OutsideBillReviewWrapper>
      <PaymentActivityWrapper>
        <BlockTitleContainer>
          <MIFormattedText
            label={
              isPaymentCheckCompleted
                ? 'requests.form.paymentActivity.paymentDetails'
                : 'requests.form.paymentActivity.vendorReceive'
            }
          />
        </BlockTitleContainer>
        <MainPaymentActivityContainer>
          <PaymentActivitySource method={method} info={info} icon={icon} formattedCheckSerial={formattedCheckSerial} />
          <Separator />
          <PaymentActivityDate
            date={
              isPaymentCheckCompleted ? (
                <MIFormattedDate date={payment?.deliveryEta} />
              ) : (
                <DeliveryDate date={payment?.deliveryEta} maxDate={payment?.maxDeliveryEta} />
              )
            }
            description={getDeliverytitle()}
            additionalDescription={additionalDescription}
          />
        </MainPaymentActivityContainer>
      </PaymentActivityWrapper>
    </OutsideBillReviewWrapper>
  );
};
type PaymentActivitySourceProps = {
  method: string;
  info: string | JSX.Element;
  icon: Record<string, any>;
  formattedCheckSerial: string;
};

const PaymentActivitySource: React.FC<PaymentActivitySourceProps> = ({ icon, method, formattedCheckSerial, info }) => (
  <PaymentActivityContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <Box minW={10}>{icon}</Box>
        <PaymentTextContainer>
          <PaymentMethodContainer>
            <PaymentMethodText>
              <MIFormattedText label={method} values={{ formattedCheckSerial }} />
            </PaymentMethodText>
          </PaymentMethodContainer>
          <PaymentInfoText>{info}</PaymentInfoText>
        </PaymentTextContainer>
      </PaymentInfoContainer>
    </PaymentActivityMainContainer>
  </PaymentActivityContainer>
);

type PaymentActivityDateProps = {
  date: string | Record<string, any> | Date;
  description: string | undefined;
  additionalDescription?: string;
};

const PaymentActivityDate: React.FC<PaymentActivityDateProps> = ({ date, description, additionalDescription }) => (
  <PaymentActivityContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <Box minW={10}>
          <Icon name={IconNames.calendarMove} size={IconSize.lg} />
        </Box>
        <PaymentTextContainer>
          <PaymentMethodText>
            <MIFormattedText label={description || ''} />
          </PaymentMethodText>
          <PaymentDateContainer>
            <PaymentInfoText>{date}</PaymentInfoText>
            {additionalDescription && (
              <AdditionalDescription>
                <MIFormattedText label={additionalDescription || ''} />
              </AdditionalDescription>
            )}
          </PaymentDateContainer>
        </PaymentTextContainer>
      </PaymentInfoContainer>
    </PaymentActivityMainContainer>
  </PaymentActivityContainer>
);

const baseContainerStyles = css`
  display: flex;
  flex-direction: column;
`;

const OutsideBillReviewWrapper = styled.div`
  ${baseContainerStyles}
  align-items: center;
  background: ${(props) => props.theme.background.default};
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: none;

  @media ${devices.mobile} {
    align-items: flex-start;
  }

  @media ${devices.mobile}, ${devices.phablet} {
    border: none;
  }
  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.OutsideBillReviewWrapper}
`;

const PaymentActivityWrapper = styled.div`
  ${baseContainerStyles}
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0;

  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.PaymentActivityWrapper}
`;

const MainPaymentActivityContainer = styled.div`
  ${baseContainerStyles}
  margin-bottom: 0;
  padding-top: 2.2rem;
  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.MainPaymentActivityContainer}
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.border.darkGrey};
  height: 0.1rem;
  margin: 2rem 0;
  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.Separator}
`;

const PaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentActivityMainContainer = styled.div`
  display: flex;
  padding-right: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.subtitle};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.BlockTitleContainer}
`;

const PaymentInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PaymentTextContainer = styled.div`
  ${baseContainerStyles}
`;

const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const PaymentMethodText = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.2px;
  ${(props) => props.theme.text.fontType.hint};
`;

const PaymentDateContainer = styled.div`
  ${baseContainerStyles}
  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.PaymentDateContainer}
`;

const PaymentInfoText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  ${(props) => props.theme.text.fontType.regular};
  margin-right: 0.4rem;
  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.PaymentInfoText}
`;

const AdditionalDescription = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.hint};
  ${({ theme }) => theme.components?.ConvertedUnilateralPaymentActivity?.AdditionalDescriptions}
`;

export default ConvertedUnilateralPaymentActivity;
