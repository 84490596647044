export const extractFirstAndLastNameFromFullName = (fullName?: string | null) => {
  const nameChunks = (fullName || '').trim().split(' ');
  const lastName = nameChunks.pop();
  const firstName = nameChunks.join(' ');

  return {
    lastName,
    firstName,
  };
};
