import * as React from 'react';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { CurrencySign } from 'src/utils/consts';
import { FeeDataType } from './hooks/useFeesParams';

type Props = {
  fee: FeeDataType;
  icon?: React.ReactNode;
  'data-testid'?: string;
};

export const FeeItem = ({ fee, icon, 'data-testid': dataTestId }: Props) => (
  <Flex flexWrap="wrap" justify="flex-end" textStyle={fee.style} color="grey.700" data-testId={dataTestId ?? ''}>
    <Flex justifyContent="end" data-testid={fee.label}>
      {icon}
      <MIFormattedText label={fee.label} />
    </Flex>
    <Box as="span" ml={fee.plus ? 5 : 6}>
      {fee.plus && <Box as="span">+</Box>}
      <Box as="span">
        <MIFormattedCurrency value={fee.amount?.toFixed(2)} currency={CurrencySign.USD} privateData />
      </Box>
    </Box>
  </Flex>
);
