import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { ClientType } from 'src/pages/team/team-management/types';
import { CompanyInfoType } from 'src/utils/types';
import { getUserRole, isUserInOrganization } from 'src/utils/user';
import { UserType } from '../types';

export function useUsersWithClients(orgId: number) {
  const organizations = useSelector<any, CompanyInfoType[]>(profileStore.selectors.getOrganizations);
  const users = useSelector<any, UserType[]>(usersStore.selectors.org(orgId).users);
  const isUsersLoading = useSelector<any, boolean>(usersStore.selectors.userManagementListSlice.org(orgId).loading);

  const finalUsers = users.map((user) => {
    const orgs = organizations.filter((org) => isUserInOrganization(user, org.id));

    const clients = orgs
      .map<ClientType>((org) => ({
        ...org,
        companyName: org.companyName || '',
        role: getUserRole(user, org.id),
        logoUrl: org.logoUrl,
      }))
      .sort((a, b) => {
        if (a.id === orgId) return -1;

        if (b.id === orgId) return 1;

        return a.companyName.toUpperCase().localeCompare(b.companyName.toUpperCase());
      });

    return {
      ...user,
      clients,
    };
  });

  return { users: finalUsers, isUsersLoading };
}
