import { CopyOrCreatePaymentLink } from 'src/components/common/CopyOrCreatePaymentLink';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { CopyLinkSize, DeliveryMethodOrigin } from 'src/utils/consts';
import { getVendorHandleURL } from 'src/utils/organizations';
import { DeliveryMethodType } from 'src/utils/types';

type Props = {
  handle: string;
  deliveryMethods: readonly DeliveryMethodType[];
};

export const PaymentPage = ({ handle, deliveryMethods }: Props) => {
  const handleValue = handle && !!deliveryMethods.length ? getVendorHandleURL(handle) : '';
  const isMSNPortal = useIsMsnDashboardDisplay();
  const origin = isMSNPortal ? DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION : DeliveryMethodOrigin.GET_PAID;

  return (
    <MICard>
      <MICardForm>
        <MICardTitle label="settings.company.publicLinkTitle" />
        <Box textStyle="body2" mb={4} mt={6}>
          <MIFormattedText label="settings.company.publicLinkSubTitle" />
        </Box>
        <CopyOrCreatePaymentLink
          clipboardText={handleValue}
          link={handleValue}
          size={CopyLinkSize.NORMAL}
          origin={origin}
        />
      </MICardForm>
    </MICard>
  );
};
