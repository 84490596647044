import { createRestfulSlice, hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { JoinOrganizationRequestType } from 'src/utils/types';
import { joinOrganizationRequestsApi } from './api';
import { acceptRequestSlice, resendJoinRequestSlice, validateRequestSlice } from './slices';

const name = 'joinOrganizationRequests';

const joinOrgRequestsStore = createRestfulSlice<JoinOrganizationRequestType>({
  name,
  api: joinOrganizationRequestsApi,
  schemaName: 'companyInfo',
  selectors: {
    user(userId) {
      const requestsSelectors = {
        list(state) {
          const hashKey = hashListKey({ userId });

          return (state[name].lists[hashKey]?.order || []).map((id) => state[name].byId[id]);
        },
        isLoading(state) {
          const hashKey = hashListKey({ userId });

          return state[name].lists[hashKey]?.loading;
        },
      };

      return requestsSelectors;
    },
  },
  slices: {
    validateRequest: validateRequestSlice,
    acceptRequest: acceptRequestSlice,
    resendJoinRequestSlice,
  },
});

export default joinOrgRequestsStore;
