import { GetProListItemType } from 'src/components/shared/tables/PaymentsTable/types';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from './consts';
import { proPaidPaymentsFetchSlice } from './get-pro-paid-payments-fetch-slice';
import { proPaidPaymentsIsListRefreshableSlice } from './get-pro-paid-payments-is-list-refreshable';
import { proPaidPaymentsMarkAsUnpaidSlice } from './get-pro-paid-payments-mark-as-unpaid';

export const getProPaidPaymentsStore = createRestfulSlice<GetProListItemType>({
  api: getProApi.paid,
  name,
  slices: {
    markAsUnpaid: proPaidPaymentsMarkAsUnpaidSlice,
    proIsListRefreshable: proPaidPaymentsIsListRefreshableSlice,
    proFetch: proPaidPaymentsFetchSlice,
  },
  selectors: {
    batchItems: () => () => [],
  },
});
