import { ActionOption } from 'src/components/common/MIActionsMenu';
import { PageHeader } from 'src/components/common/PageHeader';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { BusinessProfileModalLink } from 'src/pages/contacts/list/components/BusinessProfileModalLink';
import { ContactActionsMenu } from 'src/pages/contacts/list/components/ContactActionsMenu';
import { analytics } from 'src/services/analytics';
import { ContactsTab } from 'src/utils/consts';
import { ContactType, VendorType } from 'src/utils/types';

const eventPage = 'contacts';

type MobileHeaderProps = {
  backVendorsSearch: string;
  backUrl: string;
  contact: VendorType | ContactType;
  contactType: ContactsTab;
  showMenuItems?: boolean;
  actionOptions?: ActionOption[];
  isMsnVendor?: boolean;
  onBusinessProfileClick?: () => void;
};

const handleContactClick = (type) => analytics.track(eventPage, 'contact-action', { type });

export const iconWrapper = {
  display: 'flex',
  h: '5.1rem',
  w: '5.1rem',
  ml: 4,
  textStyle: 'h2',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  textDecor: 'none',
  color: 'black',
  bgColor: 'white',
  border: '1px',
  borderColor: 'grey.500',
  borderRadius: 'lg',
};

export const ContactViewHeaderMobile = ({
  backVendorsSearch,
  backUrl,
  contact,
  contactType,
  showMenuItems,
  actionOptions,
  isMsnVendor,
  onBusinessProfileClick,
}: MobileHeaderProps) => {
  const isContactPhoneOrEmailExist = contact.contactPhone || contact.contactEmail;
  const isCustomerView = contactType === ContactsTab.CUSTOMERS;
  const showPhoneIcon = isCustomerView && contact.contactPhone && contact.contactPhone.length;
  const showEmailIcon = isCustomerView && contact.contactEmail && contact.contactEmail.length;
  const showBusinessProfileLink = isMsnVendor && onBusinessProfileClick;

  return (
    <PageHeader
      backNav={{
        pathname: backUrl,
        search: backVendorsSearch,
      }}
      text={contact.companyName}
      actionOptions={actionOptions}
      showMenuItems={showMenuItems}
      subTitle={
        showBusinessProfileLink ? (
          <BusinessProfileModalLink
            onBusinessProfileClick={onBusinessProfileClick}
            testId="business-profile-link-mobile"
          />
        ) : null
      }
    >
      {isContactPhoneOrEmailExist && (
        <Flex justify="center" flexGrow={0}>
          {showPhoneIcon && (
            <Box
              __css={iconWrapper}
              as="a"
              href={`tel:${contact.contactPhone}`}
              onClick={() => handleContactClick('phone')}
            >
              <Icon name={IconNames.phone} color="black" size={IconSize.m} />
            </Box>
          )}
          {showEmailIcon && (
            <Box
              __css={iconWrapper}
              as="a"
              href={`mailto:${contact.contactEmail}`}
              onClick={() => handleContactClick('email')}
            >
              <Icon name={IconNames.email} color="black" size={IconSize.m} />
            </Box>
          )}
        </Flex>
      )}
    </PageHeader>
  );
};

type ContactHeaderProps = {
  contact: VendorType | ContactType;
  actionOptions?: ActionOption[];
  showMenuItems?: boolean;
  showDirectoryVendorLogo?: boolean;
  contactType: ContactsTab;
  onBusinessProfileClick?: () => void;
};

export const ContactViewHeader = ({
  contact,
  actionOptions,
  showMenuItems,
  showDirectoryVendorLogo,
  onBusinessProfileClick,
  contactType,
}: ContactHeaderProps) => {
  const isContactPhoneOrEmailExist = contact.contactPhone || contact.contactEmail;
  const isCustomerView = contactType === ContactsTab.CUSTOMERS;
  const showPhoneIcon = isCustomerView && contact.contactPhone && contact.contactPhone.length;
  const showEmailIcon = isCustomerView && contact.contactEmail && contact.contactEmail.length;

  return (
    <Flex position="relative" align="center" mb={10}>
      <Avatar name={contact.companyName} size={Size.xl} textColor="white" />
      {showDirectoryVendorLogo && (
        <Icon
          name={IconNames.verifiedSolid}
          width={4}
          height={4}
          top="3.2rem"
          left="3.2rem"
          color="primary.500"
          position="absolute"
          border="2px"
          borderColor="white"
          data-testid="directory-vendor-sign"
          borderRadius="full"
          background="white"
        />
      )}
      <Flex ml={3} flexGrow={1} direction="column" color="black" textStyle="body2Semi">
        {contact.companyName}
        {onBusinessProfileClick ? (
          <BusinessProfileModalLink
            onBusinessProfileClick={onBusinessProfileClick}
            boxSize="fit-content"
            testId="business-profile-link"
          />
        ) : null}
      </Flex>
      {isContactPhoneOrEmailExist && (
        <Flex justify="center" flexGrow={0}>
          {showPhoneIcon && (
            <Box
              __css={iconWrapper}
              as="a"
              data-testid="contact-phone-icon"
              href={`tel:${contact.contactPhone}`}
              onClick={() => handleContactClick('phone')}
            >
              <Icon name={IconNames.phone} color="black" size={IconSize.m} />
            </Box>
          )}
          {showEmailIcon && (
            <Box
              __css={iconWrapper}
              as="a"
              data-testid="contact-email-icon"
              href={`mailto:${contact.contactEmail}`}
              onClick={() => handleContactClick('email')}
            >
              <Icon name={IconNames.email} color="black" size={IconSize.m} />
            </Box>
          )}
        </Flex>
      )}
      {showMenuItems && actionOptions && <ContactActionsMenu actions={actionOptions} />}
    </Flex>
  );
};
