import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box/Box';
import { TextField } from 'src/core/ds/form/fields';
import { Link } from 'src/core/ds/link';
import { NotificationVariant } from 'src/core/ds/toast';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { MicroDepositsErrorsEnum } from 'src/utils/consts';
import { FooterHelp } from './FooterHelp';

type CreateMicroDepositErrorResponse = { code: string; responseData: { reason: string } };

export const VerifyBankAccountPage = () => {
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const { bankAccount } = deliveryMethod;
  const { navigate } = useNavigator();
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);
  const site = useSiteContext();
  const analyticsData = { msnOptinFlow: true, isGuest: false, flows: 'public' };

  const { onApiCall: createMicroDeposits, loading: isCreating } = useApi<
    [vendorId: number, organizationId: number],
    void
  >({
    api: msnPortalApi.createMicroDeposits,
  });

  const onPrev = () => navigate(generatePath(onboardingLocations.msnRegistration.companyDetails, { orgId }));

  const onNext = async () => {
    try {
      await createMicroDeposits(vendorId as number, orgId);
      navigate(generatePath(onboardingLocations.msnRegistration.confirmDetails, { orgId }), true, {
        prevUrl: onboardingLocations.msnRegistration.verifyBankAccount,
        nextUrl: onboardingLocations.msnRegistration.deliveryMethodStatus,
        isGetPaidOrg: true,
      });
    } catch (error: unknown) {
      if ((error as CreateMicroDepositErrorResponse).responseData.reason === MicroDepositsErrorsEnum.ALREADY_SENT) {
        navigate(generatePath(onboardingLocations.msnRegistration.confirmDetails, { orgId }), true, {
          prevUrl: onboardingLocations.msnRegistration.verifyBankAccount,
          nextUrl: onboardingLocations.msnRegistration.verifyBankAccountStatus,
          isGetPaidOrg: true,
        });
      } else {
        pushNotification({
          type: NotificationVariant.error,
          msg: 'onboarding.msnCompanyInfo.generalError.text',
          textValues: {
            contactSupport: (
              <Link borderBottom="1px" color="white" href={`mailto:${site.config.support.email}`}>
                <MIFormattedText label="onboarding.msnCompanyInfo.generalError.contactSupport" />
              </Link>
            ),
          },
          autoClose: false,
        });
      }

      analytics.trackAction('msnPortalVerifyBankAccount.error', analyticsData);
    }
  };

  return (
    <StepLayoutPage
      title="onboarding.msnCompanyInfo.verifyBankAccount.title"
      subtitle="onboarding.msnCompanyInfo.verifyBankAccount.subtitle"
      onNext={onNext}
      onPrev={onPrev}
      isLoading={isCreating}
      nextButtonAnalyticsProperties={analyticsData}
      footer={
        <Box mt={5}>
          <FooterHelp />
        </Box>
      }
      relativeStep={5 / 6}
    >
      <TextField
        testId="verify-bank-account-routingNumber"
        label="onboarding.msnCompanyInfo.verifyBankAccount.routingNumber"
        value={bankAccount?.routingNumber}
        isRequired
        isViewOnly
      />
      <TextField
        testId="verify-bank-account-accountNumber"
        label="onboarding.msnCompanyInfo.verifyBankAccount.accountNumber"
        value={bankAccount?.accountNumber}
        isRequired
        isViewOnly
      />

      <MISecurityDetails eventPage="msn-verify-bank-account" />
    </StepLayoutPage>
  );
};
