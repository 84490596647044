import Box from 'src/core/ds/box';
import { CandidatesDetailsForm } from './components/CandidatesDetailsForm';
import { candidatesDetailsContainerStyle } from './styles';

const CandidatesDetails = ({ capLimitAmount }) => (
  <Box __css={candidatesDetailsContainerStyle}>
    <CandidatesDetailsForm capLimitAmount={capLimitAmount} />
  </Box>
);

export { CandidatesDetails };
