import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { useLocationState } from 'src/utils/hooks';
import { NavigatePathType, NavigateType } from 'src/utils/types';

type PreservedStateNavigatorType = {
  navigate: NavigateType;
  navigateWithPreservedState: ((dataToAdd?: Record<string, any>) => void) | null;
  navigateToExitWithPreservedState: ((dataToAdd?: Record<string, any>) => void) | null;
};

export const usePreservedStateNavigator = (): PreservedStateNavigatorType => {
  const { navigate } = useNavigator();
  const [redirectUrl] = useLocationState<string>('redirectUrl');
  const [exitUrl] = useLocationState<string>('exitUrl');
  const [preservedState] = useLocationState('preservedState', {});

  const navigateWithPreservedState = (dataToAdd: Record<string, any> = {}) => {
    if (redirectUrl) {
      navigate(redirectUrl, false, { ...preservedState, ...dataToAdd });
    }
  };

  const navigateToExitWithPreservedState = (dataToAdd: Record<string, any> = {}) => {
    if (exitUrl) {
      navigate(exitUrl, false, { ...preservedState, ...dataToAdd });
    }
  };

  const handleNavigation = (url: NavigatePathType, shouldReplaceCurrent = false, state: Record<string, any> = {}) => {
    navigate(url, shouldReplaceCurrent, {
      ...state,
      preservedState,
      redirectUrl,
      exitUrl,
    });
  };

  return {
    navigateWithPreservedState: redirectUrl ? navigateWithPreservedState : null,
    navigateToExitWithPreservedState: exitUrl ? navigateToExitWithPreservedState : null,
    navigate: handleNavigation,
  };
};
