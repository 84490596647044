import Flex from 'src/core/ds/flex/Flex';
import { Installment } from 'src/utils/types';
import { AmountCell } from '../AmountCell';
import { DateAndStatusCell } from '../DateAndStatusCell';
import { getRowTextColorByPaymentStatue } from './utils';

type InstallmentListViewRowPropsType = {
  installment: Installment;
  viewDetailsLink?: string;
  showBadge: boolean;
};

export const InstallmentListViewRow = ({
  installment,
  viewDetailsLink,
  showBadge = true,
}: InstallmentListViewRowPropsType) => (
  <Flex
    w="full"
    textStyle="body4"
    color={getRowTextColorByPaymentStatue(showBadge, installment.status)}
    pt={2}
    data-testid="installments-list-row"
  >
    <DateAndStatusCell
      date={installment.scheduledDate}
      status={installment.status}
      viewDetailsLink={viewDetailsLink}
      showBadges={showBadge}
    />
    <AmountCell amount={installment.amount} />
  </Flex>
);
