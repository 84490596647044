import { useSelector } from 'react-redux';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { profileStore } from 'src/modules/profile/profile-store';
import { AccountType } from 'src/utils/types';
import { BlockedSelectedMethodCard, ExposedSelectedMethodCard } from '../SmbSelectedMethodCard';

type Props = {
  menuActions: { label: string; action: () => void; color?: string }[];
  fundingSource: AccountType | undefined;
  isSelectedByFirm?: boolean;
};

export const SelectedMethodCard = ({ menuActions, fundingSource, isSelectedByFirm = false }: Props) => {
  const isUserPartOfFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);

  return (
    <Flex justify="space-between">
      {!isUserPartOfFirm && isSelectedByFirm ? (
        <BlockedSelectedMethodCard />
      ) : (
        !!fundingSource && (
          <ExposedSelectedMethodCard fundingSource={fundingSource} isSelectedByFirm={isSelectedByFirm} />
        )
      )}
      <Box marginInlineStart={6}>
        <Menu autoSelect={false}>
          <MenuButton
            as={IconButton}
            icon={<Icon name={IconNames.more} />}
            variant="action"
            data-testid="billing-card-menu-button"
          />
          <MenuList data-testid="billing-method-card-actions">
            {menuActions.map(({ label, action, color }) => (
              <MenuItem key={label} label={label} onClick={action} color={color} />
            ))}
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
};
