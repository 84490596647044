import { useFeature } from '@melio/shared-web/dist/feature-flags';
import config from 'src/config';
import { InvoiceType } from 'src/modules/invoices/types';
import { FeatureFlags } from 'src/utils/featureFlags';
import { getVendorHandleURL } from 'src/utils/organizations';

export const useFullPaymentRequestLink = ({
  ownedVendorHandle,
  invoice,
}: {
  ownedVendorHandle: string;
  invoice: InvoiceType;
}): string => {
  const [isNewPaymentRequestFulfillmentFlowEnabled] = useFeature(
    FeatureFlags.IsNewPaymentRequestFulfillmentFlow,
    false
  );

  if (isNewPaymentRequestFulfillmentFlowEnabled) {
    const paymentRequestRef = invoice?.publicRef || invoice?.link || '';

    return `${config.platformApp.baseUrl}/ar/meliome/pay/r/${paymentRequestRef}`;
  }

  return `${getVendorHandleURL(ownedVendorHandle)}/r/${invoice?.link || ''}`;
};
