import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useSelector } from 'react-redux';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CompanyType } from 'src/utils/consts';
import { FeatureFlags } from 'src/utils/featureFlags';

export const useProcessingFeeData = () => {
  const {
    companyType,
    createdAt: organizationCreatedAt,
    billingSetting: { fee },
  } = useSelector(getCompanyInfo);
  const { arProcessingFeeExempt, isArProcessingFeeExplanationBannerDismissed } = useOrganizationPreferences();

  const arProcessingFee = fee['ar-processing-fee'];
  const creditCardFee = fee.credit;
  const [isArProcessingFeeEnabled] = useFeature<boolean>(FeatureFlags.isArProcessingFeeEnabled, false);

  const [arProcessingFeeNotificationForExistingOrganizationReleaseDateInMilliseconds] = useFeature<number>(
    FeatureFlags.getArProcessingFeeNotificationForExistingOrganizationReleaseDateInMilliseconds,
    -1
  );
  const createdAtDate = new Date(organizationCreatedAt!);
  const existingOrganizationBeforeTheArProcessingFeeRelease =
    createdAtDate.getTime() < arProcessingFeeNotificationForExistingOrganizationReleaseDateInMilliseconds;

  const isOrganizationAllowedForArProcessingFee =
    companyType !== CompanyType.ACCOUNTING_FIRM && arProcessingFeeExempt !== 'true';

  const shouldPresentAchProcessingFeeNotification =
    isOrganizationAllowedForArProcessingFee &&
    existingOrganizationBeforeTheArProcessingFeeRelease &&
    isArProcessingFeeExplanationBannerDismissed !== 'true';

  return {
    creditCardFee,
    arProcessingFee,
    isOrganizationAllowedForArProcessingFee,
    shouldPresentAchProcessingFeeNotification,
    isArProcessingFeeEnabled,
  };
};
