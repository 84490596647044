import { createDeleteSlice } from 'src/helpers/redux/restDeleteSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from '../constants';

const proInvoicesDeleteSlice = createDeleteSlice({
  storeName: name,
  actionName: 'proDelete',
  api: getProApi.invoices.delete,
});

export default proInvoicesDeleteSlice;
