import { featureFlags } from '@melio/shared-web';
import { differenceInDays, isAfter } from 'date-fns';
import styled from 'styled-components';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  EditElement,
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import { analytics } from 'src/services/analytics';
import { FailedPaymentType, FastACHReviewAndConfirmEntryPoint, FeatureFlags } from 'src/utils/consts';
import { isFastDeliveryType, isLateDeliveryType } from 'src/utils/delivery-methods';
import { getLatestPayment, isUndepositedCheck } from 'src/utils/payments';
import { AccountType, BillType, PaymentType } from 'src/utils/types';
import Tag from './Tag';

type Props = {
  payment: PaymentType;
  bill: BillType;
  onEdit: (hasCustomTrackEvent?: boolean) => void;
  fundingSource: AccountType;
  isEditDisabled: boolean;
};

export const PayBillPaymentActivityDeductionDateInfo = ({
  payment,
  bill,
  onEdit,
  fundingSource,
  isEditDisabled,
}: Props) => {
  const isRecurringBill = !!bill.recurringBill;
  const originPayment = getLatestPayment(bill?.payments);
  const isUndepositedCheckPayment = originPayment ? isUndepositedCheck(originPayment) : false;
  const canEdit =
    payment?.metadata?.failedType !== FailedPaymentType.FAILED_TO_DELIVER &&
    !isRecurringBill &&
    !isUndepositedCheckPayment;
  const isFailedOrUndepositedCheckPayment = payment?.metadata?.failedType || isUndepositedCheckPayment;
  const isUpdated =
    originPayment &&
    isFailedOrUndepositedCheckPayment &&
    differenceInDays(new Date(payment?.scheduledDate), new Date(originPayment?.scheduledDate));
  const regularBillDeductionDateLabel = isAfter(new Date(), new Date(originPayment?.scheduledDate))
    ? 'payBillPaymentActivity.deductionDate.pastLabel'
    : 'payBillPaymentActivity.deductionDate.futureLabel';
  const deductionDateLabel = isRecurringBill
    ? 'payBillPaymentActivity.deductionDate.recurringLabel'
    : regularBillDeductionDateLabel;
  const [experimentGroup] = featureFlags.useFeature<FastACHReviewAndConfirmEntryPoint>(
    FeatureFlags.FastACHReviewAndConfirmEntryPoint,
    FastACHReviewAndConfirmEntryPoint.ControlGroup
  );
  const isFast = isFastDeliveryType(payment.deliveryPreference);
  const isLate = isLateDeliveryType(payment, bill);

  const editWithTrackEvent = () => {
    analytics.track('pay-bill-confirm', 'edit-deduction-date', {
      billId: bill.id,
      fundingSourceId: fundingSource.id,
      isFast,
      variant: experimentGroup,
      deliveryEta: payment.deliveryEta,
      isLate,
    });

    onEdit && onEdit(true);
  };

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <ScheduledIcon />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label={deductionDateLabel} />
            </PaymentMethodText>
            <PaymentInfoText>
              <MIFormattedDate date={payment.scheduledDate} />
              {isUpdated && <Tag label="payBillPaymentActivity.updated" />}
            </PaymentInfoText>
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {canEdit && (
          <EditElement testId="edit-deduction-date" onEdit={editWithTrackEvent} isDisabled={isEditDisabled} />
        )}
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};

const ScheduledIcon = styled.i.attrs({ className: 'icon-scheduled-icon' })``;
