import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import IconButton from 'src/core/ds/iconButton';
import Tooltip from 'src/core/ds/tooltip';
import { ReactComponent as RemoveIcon } from 'src/images/regular-batch-payments/remove.svg';

type Props = {
  vendorId: number;
  removeFromList: (vendorId: number) => void;
};

export const RemoveFromListButton = ({ vendorId, removeFromList }: Props) => {
  const onClick = () => {
    removeFromList(vendorId);
  };

  return (
    <Box>
      <Tooltip
        key="vendors.batchSettings.actions.removeFromList"
        placement="top"
        label={<MIFormattedText label="vendors.batchSettings.actions.removeFromList" />}
      >
        <IconButton
          size="sm"
          icon={<RemoveIcon />}
          aria-label="action"
          label="vendors.batchSettings.actions.removeFromList"
          onClick={onClick}
          mr={6}
          ml={6}
        />
      </Tooltip>
    </Box>
  );
};
