import React from 'react';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { FooterContainer } from './FooterContainer';

type CancelledFooterProps = {
  isLoading: boolean;
  onPrimary: () => void;
};

export const CancelledFooter = ({ isLoading, onPrimary }: CancelledFooterProps) => (
  <FooterContainer>
    <Button
      label="spendManagement.pages.dashboard.cardDetails.actions.viewTransactions"
      variant={ButtonVariants.secondary}
      onClick={() => {
        analytics.track(Page.CARD_LIST, Context.CARD_DETAILS_DRAWER, Event.DISPLAY_TRANSACTIONS);
        onPrimary();
      }}
      isLoading={isLoading}
    />
  </FooterContainer>
);
