import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import compact from 'lodash/compact';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { CompanyContact } from 'src/components/common/CompanyContact';
import { MICard } from 'src/components/common/MICard';
import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PageHeader } from 'src/components/common/PageHeader';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import BillOpenBalanceInfo from 'src/components/list/BillOpenBalanceInfo/BillOpenBalanceInfo';
import { RightPanelPlaceholder } from 'src/components/onboarding/RightPanelPlaceholder';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useModal } from 'src/helpers/react/useModal';
import { useToggle } from 'src/helpers/react/useToggle';
import { useBreak } from 'src/hoc';
import { useGetFileUrls } from 'src/modules/files/hooks/useGetFileUrls';
import { useUploadFile } from 'src/modules/files/hooks/useUploadFile';
import { InvoiceType } from 'src/modules/invoices/types';
import { BillStatus } from 'src/pages/bill/components/BillStatus';
import InvoiceDetails from 'src/pages/get-paid/list/components/InvoiceDetails';
import PaymentDetails from 'src/pages/get-paid/list/components/PaymentDetails';
import RequestDetails from 'src/pages/get-paid/list/components/RequestDetails';
import { getInvoiceStatusInfo } from 'src/pages/get-paid/list/components/utils';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { useForm } from 'src/ui/form/use-form/useForm';
import { isBillHasPartialPayments, serializePaymentId } from 'src/utils/bills';
import { DialogVariants, InvoiceStatus, PaymentRequestTabs, PaymentStatus } from 'src/utils/consts';
import { useStyledTheme } from 'src/utils/hooks';
import { getPaymentById } from 'src/utils/payments';
import { encodeQuery } from 'src/utils/query-utils';
import { ContactType } from 'src/utils/types';

type Props = {
  invoice: InvoiceType;
  vendorHandle: string;
  update: (data) => Promise<void>;
  remove: (removeInvoice?: boolean) => Promise<void>;
  share: () => void;
  onMarkInvoiceAsPaid: (value: boolean) => void;
  isProcessingMarkedAsPaid: boolean;
  backLink: {
    pathname?: string;
    search?: string;
  };
  customers: ContactType[];
  currentPaymentId?: string;
};

const ViewInvoice = ({
  invoice,
  vendorHandle,
  update,
  share,
  remove,
  backLink,
  customers,
  onMarkInvoiceAsPaid,
  isProcessingMarkedAsPaid,
  currentPaymentId,
}: Props) => {
  const theme = useStyledTheme();
  const device = useBreak();
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const isFullSingleView = device.isMobile || device.isPhablet;
  const modelData = useMemo(
    () => ({
      id: invoice?.id,
      totalAmount: invoice?.totalAmount,
      invoiceNumber: invoice?.invoiceNumber,
      dueDate: invoice?.dueDate,
      customerName: invoice?.customer?.contactName,
      customerEmail: invoice?.customer?.contactEmail,
      customerNote: invoice?.customerNote,
      files: map(invoice?.paymentRequest?.files || invoice?.files || [], (file) => file.id),
    }),
    [invoice]
  );

  const openBalanceRef = useRef<HTMLAnchorElement>();
  const isPartialPayments = invoice?.bill && isBillHasPartialPayments(invoice.bill);

  const [inEditMode, onEditToggle] = useToggle(false);
  useEffect(() => {
    onEditToggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);
  const [invoiceMV, { submit, cancel }, , loading] = useForm(modelData, {
    submit: update,
    onClear: onEditToggle,
  });
  const [EditConfirmMessage, onEditConfirm] = useModal(CommonDialog, {
    confirm: submit,
    variant: DialogVariants.SUCCESS,
    title: 'getPaid.edit.changeNotificationMessage.title',
    description: 'getPaid.edit.changeNotificationMessage.description',
    confirmText: 'getPaid.edit.changeNotificationMessage.confirm',
    cancelText: 'getPaid.edit.changeNotificationMessage.discard',
    textValues: { customerName: invoiceMV?.customerName?.value },
    hideIcon: true,
  });
  const fileId = first(invoiceMV.files.value);
  const [fileUrls, loadingFileUrls] = useGetFileUrls(fileId);
  const [uploadFile, , uploading] = useUploadFile((fileResults) => {
    invoiceMV.files.onChange({
      value: compact([fileResults?.file?.id]),
    });
  });

  if (!invoice) {
    return <RightPanelPlaceholder />;
  }

  const deleteFile = () => {
    invoiceMV.files.onChange({ value: [] });
  };

  const onSubmit = () => {
    const validationErrors = getValidationErrors('paymentRequest', mapValues(invoiceMV, 'value'));

    if (
      invoice.status === InvoiceStatus.PENDING &&
      !isEmpty(invoiceMV?.customerEmail?.value) &&
      isValidationOk(validationErrors)
    ) {
      onEditConfirm();
    } else {
      submit();
    }
  };

  const isMarkedAsPaid = !isEmpty(invoice.markedAsPaidAt);
  const showMenu = (invoice.status === InvoiceStatus.FAILED && !inEditMode) || isMarkedAsPaid;
  const currentPayment = getPaymentById(invoice?.payments || [], currentPaymentId);
  const showPaymentDetails = !isEmpty(invoice.payments) || isMarkedAsPaid;
  const showRequestDetails = invoice.link && invoice.status !== InvoiceStatus.CREATED;

  const paymentDetails = showPaymentDetails && (
    <PaymentDetails
      invoice={invoice}
      remove={remove}
      onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
      showMenu={showMenu}
      isMarkedAsPaid={isMarkedAsPaid}
      currentPayment={currentPayment}
    />
  );
  const requestDetails = showRequestDetails && (
    <RequestDetails
      invoice={invoice}
      vendorHandle={vendorHandle}
      email={invoiceMV.customerEmail}
      note={invoiceMV.customerNote}
      viewOnly={!inEditMode || uploading}
      onEdit={onEditToggle}
      remove={remove}
      showMenu={invoice.status === InvoiceStatus.PENDING && !inEditMode}
      inEditMode={inEditMode}
      onMarkInvoiceAsPaid={() => onMarkInvoiceAsPaid(true)}
    />
  );
  const sendInvoiceButton = invoice.status === InvoiceStatus.CREATED && !isMarkedAsPaid && (
    <Button
      label="getPaid.view.send"
      disabled={inEditMode}
      onClick={share}
      variant={ButtonVariants.success}
      w={isFullSingleView ? 'full' : 'auto'}
      boxSizing="border-box"
    />
  );
  const statusInfo = getInvoiceStatusInfo(isMarkedAsPaid ? InvoiceStatus.MARKED_AS_PAID : invoice.status, theme);

  const goViewDetails = () => openBalanceRef.current?.scrollIntoView({ behavior: 'smooth' });

  const onLabelClick = (paymentId, paymentStatus) => {
    const status = paymentStatus === PaymentStatus.COMPLETED ? PaymentRequestTabs.PAID : PaymentRequestTabs.SCHEDULED;
    history.push({
      pathname: generatePath(getPaidLocations.dashboard, { orgId }),
      search: encodeQuery(
        {
          id: serializePaymentId(invoice.id, paymentId),
          status,
        },
        [],
        ''
      ),
    });
  };

  return (
    <>
      {EditConfirmMessage}
      {isProcessingMarkedAsPaid ? (
        <AreaLoader />
      ) : (
        <SingleViewLoadingContainer isEditMode={inEditMode}>
          {isFullSingleView ? (
            !inEditMode && (
              <PageHeader
                backNav={backLink}
                text={invoice.customer?.contactName}
                subTitle={
                  <MIFormattedText
                    label="getPaid.view.header.subtitle"
                    values={{ invoiceNumber: invoice.invoiceNumber }}
                  />
                }
                isPartialPayments={isPartialPayments}
                goViewDetails={goViewDetails}
              >
                {sendInvoiceButton}
              </PageHeader>
            )
          ) : (
            <Header>
              <CompanyContact
                companyName={invoice.customer?.contactName || ''}
                avatarName={invoice.customer?.contactName || ''}
                description={invoice.invoiceNumber || undefined}
                showViewDetailsLink={isPartialPayments}
                goViewDetails={goViewDetails}
              />
              {sendInvoiceButton}
            </Header>
          )}
          {(showPaymentDetails || showRequestDetails || statusInfo) && (
            <Card mode="mainSingleScreen">
              {statusInfo && <InvoiceStatusComponent statusInfo={statusInfo} />}
              {paymentDetails}
              {requestDetails}
            </Card>
          )}
          <Card>
            <InvoiceDetails
              invoice={invoice}
              totalAmount={invoiceMV.totalAmount}
              customerName={invoiceMV.customerName}
              invoiceNumber={invoiceMV.invoiceNumber}
              dueDate={invoiceMV.dueDate}
              viewOnly={!inEditMode || uploading || loading}
              showMenu={invoice.status === InvoiceStatus.CREATED && !inEditMode}
              onEdit={onEditToggle}
              onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
              remove={remove}
              fileUrls={!fileId ? null : fileUrls}
              loadingAttachment={loadingFileUrls}
              deleteFile={deleteFile}
              uploadFile={uploadFile as (file: any) => Promise<void>}
              customers={customers}
            />
            {isPartialPayments && invoice?.bill && (
              <InvoiceOpenBalanceInfoContainer ref={openBalanceRef}>
                <BillOpenBalanceInfo
                  isPartialPayments={isPartialPayments}
                  currentPaymentId={currentPaymentId}
                  bill={invoice.bill}
                  onLabelClick={onLabelClick}
                />
              </InvoiceOpenBalanceInfoContainer>
            )}
          </Card>
          {inEditMode && (
            <MIFloatedEditDoneButtons
              onDone={onSubmit}
              onCancel={cancel}
              doneLabel="getPaid.edit.save"
              cancelLabel="getPaid.edit.cancel"
              isDisabled={loading || uploading}
              isProcessing={loading}
            />
          )}
        </SingleViewLoadingContainer>
      )}
    </>
  );
};

export default ViewInvoice;

const Card = styled(MICard)`
  bottom-border: 1px black;
  > :not(:last-child) {
    border-bottom: ${(props) => `0.1rem solid ${props.theme.colors.border.darkGrey}`};
  }
  @media ${devices.desktop} {
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
`;

const InvoiceStatusComponent = styled(BillStatus)`
  &:first-of-type {
    border-bottom: none;
  }
`;

const InvoiceOpenBalanceInfoContainer = styled.div``;
