import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { accountingSoftwareNameToIntlPath } from 'src/pages/bill/components/BillDetailsForm/utils/accountingSoftwareNameToIntlPath';
import { getOrgId } from 'src/redux/user/selectors';
import { AccountingPlatform } from 'src/utils/types';
import { useConnectedAccountingPlatform } from '../useConnectedAccountingPlatform';
import { isPayablesAccountingPlatform } from './isPayablesAccountingPlatform';

type ReturnType = {
  isConnected: boolean;
  connectedAccountingPlatform?: AccountingPlatform;
  accountingPlatformName?: string;
};

export const usePayablesConnectedAccountingPlatform = (): ReturnType => {
  const { isConnected, connectedAccountingPlatform } = useConnectedAccountingPlatform();
  const isValidPayables = isPayablesAccountingPlatform({ connectedAccountingPlatform });
  const orgId = useSelector(getOrgId);
  const status = useSelector(accountingPlatformsStore.selectors.list.status({ orgId }));
  const accountingPlatformsActions = useStoreActions(accountingPlatformsStore);
  const accountingPlatformName = accountingSoftwareNameToIntlPath(connectedAccountingPlatform?.name);

  useEffect(() => {
    if (isEmpty(status)) {
      accountingPlatformsActions.list({ orgId });
    }
  }, [accountingPlatformsActions, orgId, status]);

  return {
    isConnected: isValidPayables && isConnected,
    connectedAccountingPlatform,
    accountingPlatformName,
  };
};
