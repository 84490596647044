import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';

export const ExpireAtCell: React.FC<CellProps<any>> = ({ cell: { value } }) =>
  useMemo(
    () => (
      <Box textStyle="body3">
        {value.expiryDate && <Box padding="0 16px">{value.expiryDate && value.expiryDate.replace('-', '/')}</Box>}
      </Box>
    ),
    [value]
  );
