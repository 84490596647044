import { featureFlags } from '@melio/shared-web';
import get from 'lodash/get';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks/usePayablesConnectedAccountingPlatform';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { PlaidLink } from 'src/pages/onboarding/funding-sources/bank/components/PlaidLink';
import { PlaidUpgradeModal } from 'src/pages/onboarding/funding-sources/bank/components/PlaidUpgradeModal/PlaidUpgradeModal';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { selectFundingSourceAction } from 'src/redux/payBillWizard/actions';
import { loadDeliveryMethodsAction, loadFundingSourcesAction } from 'src/redux/user/actions';
import { getFundingSources, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import {
  AddFundingSourceWizardOrigin,
  DbAnalyticsTraits,
  DeliveryMethodOrigin,
  DeliveryType,
  FeatureFlags,
} from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { isUnverifiedAchLimitExceeded } from 'src/utils/funding-sources';

const eventPage = 'bank-add-plaid';

type Props = {
  vendorId: string;
};

/**
 * @deprecated please do not use this - use src/flows/add-bank-account-flow/steps/plaid-account/AddPlaidBankAccountPage.tsx
 */
export const SetBankPlaidPageContainer = ({ vendorId }: Props) => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const userFundingSources = useSelector(getFundingSources);
  const [isPlaidOpen, setIsPlaidOpen] = useState<boolean>(true);
  const [isPlaidInUpgrade] = featureFlags.useFeature(FeatureFlags.IsPlaidInUpgrade, false);

  const { navigate } = usePreservedStateNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();

  const batchActions = useStoreActions(regularBatchPaymentsStore);

  const refreshDeliveryMethods = () =>
    new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });

  const refreshFundingSources = () =>
    new Promise((resolve, reject) => {
      dispatch(loadFundingSourcesAction(resolve, reject));
    });

  const onPlaidSuccess = (token: string, metadata: Record<string, any>) => {
    const origin = get(locationState, 'preservedState.origin', '');

    if (!metadata || metadata.error) {
      analytics.track(eventPage, 'plaid-error', { error: metadata.error });

      return;
    }

    const { account } = metadata;
    const isDeliveryMethodOrigin = Object.values(DeliveryMethodOrigin).includes(origin);

    if (!account) {
      analytics.track(eventPage, 'plaid-error', {
        error: 'account not selected',
      });

      return;
    }

    const selectedAccountsIds = [account.id];

    if (isDeliveryMethodOrigin) {
      addDeliveryMethodPlaidAccount(selectedAccountsIds, token, origin);
    } else {
      addFundingSourcePlaidAccount(selectedAccountsIds, token, origin);
    }
  };

  const onPlaidExit = () => {
    const origin = get(locationState, 'preservedState.origin', '');

    analytics.track(eventPage, 'plaid-exit', { isNewPlaidMDRecoveryFlow: false, origin });
    setIsPlaidOpen(false);

    const isDeliveryMethodOrigin = Object.values(DeliveryMethodOrigin).includes(origin);

    if (isUnverifiedAchLimitExceeded(userFundingSources) && !isDeliveryMethodOrigin) {
      navigate(onboardingLocations.fundingSources.bank.select);
    } else if (isDeliveryMethodOrigin) {
      navigate(
        generatePath(onboardingLocations.deliveryMethods.bank.cantFind, {
          orgId,
          id: vendorId,
        }),
        false,
        {
          origin,
          redirectUrl: onboardingLocations.vendorCompanyInfo.added,
        }
      );
    } else {
      navigate(onboardingLocations.fundingSources.bank.cantFind);
    }
  };

  const addDeliveryMethodPlaidAccount = async (
    selectedAccountsIds: Array<string>,
    token: string,
    origin: DeliveryMethodOrigin
  ) => {
    try {
      await deliveryMethodsApi.addPlaidAccount(orgId, vendorId, {
        token,
        selectedAccountsIds,
      });

      if (ownedVendorId && parseInt(vendorId, 10) === ownedVendorId) {
        await refreshDeliveryMethods();
      }

      analytics.track(eventPage, 'delivery-method-success');
      analytics.track('vendor-company-delivery-method', 'add-delivery-method-success', {
        type: DeliveryType.ACH,
        vendorId,
      });
      setIsPlaidOpen(false);

      if (origin === DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS) {
        navigate(settingsLocations.receivingMethods);
      } else {
        navigate(onboardingLocations.vendorCompanyInfo.added);
      }
    } catch (e) {
      capture(e as Error);
      setIsPlaidOpen(false);
    }
  };

  const addFundingSourcePlaidAccount = (selectedAccountsIds: Array<string>, token: string, origin: string) => {
    financialAccountsApi
      .addPlaidAccount(orgId, { token, selectedAccountsIds })
      .then((data) => {
        refreshFundingSources()
          .then(async () => {
            const accounts = data.accounts && data.accounts.length ? data.accounts : [];

            analytics.track(eventPage, 'plaid-success');
            analytics.trackMqlEvent('added-funding', 'mql');
            analytics.trackMqlEvent('added-funding-plaid', 'mql');
            analytics.setTraits({
              [DbAnalyticsTraits.ADDED_FUNDING]: true,
            });
            analytics.setFundingSourceTraits();

            setIsPlaidOpen(false);

            if (origin === AddFundingSourceWizardOrigin.PAY_BILL) {
              dispatch(selectFundingSourceAction(accounts[0].id));
            }

            if (origin === AddFundingSourceWizardOrigin.BATCH_PAY_BILLS) {
              const selectedIds = get(locationState, 'preservedState.selectedIds', []);
              const newFundingSourceId = accounts[0].id;
              await batchActions.settings.update({
                scheduledDate: null,
                fundingSourceId: newFundingSourceId,
                billIds: selectedIds,
              });
            }

            if (isConnectedAccountingPlatform) {
              navigate(onboardingLocations.fundingSources.bank.account, false, {
                ...locationState,
                fundingSourceId: accounts[0].id,
                origin,
                accounts,
              });
            } else {
              navigate(onboardingLocations.fundingSources.bank.complete, false, {
                ...locationState,
                newFundingSourceId: accounts[0].id,
                origin,
              });
            }
          })
          .catch(() => {
            setIsPlaidOpen(false);
          });
      })
      .catch(() => {
        setIsPlaidOpen(false);
      });
  };

  const onPlaidUpgradeModalExit = () => {
    navigate(onboardingLocations.fundingSources.bank.select);
  };

  return isPlaidInUpgrade ? (
    <PlaidUpgradeModal goExit={onPlaidUpgradeModalExit} />
  ) : (
    <PlaidLink orgId={orgId} onSuccess={onPlaidSuccess} onExit={onPlaidExit} showDialog={isPlaidOpen} />
  );
};
