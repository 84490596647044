import { featureFlags } from '@melio/shared-web';
import { useEffect, useState } from 'react';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { authLocations } from 'src/pages/auth/locations';
import { FeatureFlags, Role } from 'src/utils/consts';
import locations from 'src/utils/locations';
import { RegisterFromInvitation, RegisterSuccessResponse } from '../RegisterFromInvitation';

export function RegisterFromInvitationContainer(props) {
  const [isPlatformMtlKycUplift] = featureFlags.useAnonymousFeature<boolean>(FeatureFlags.PlatformMtlKycUplift, false);
  const [isPlatformAccountantsNewFlowEnabled] = featureFlags.useFeature<boolean>(
    FeatureFlags.PlatformAccountantsNewFlow,
    false
  );
  const [registerSuccessResponse, setRegisterSuccessResponse] = useState<RegisterSuccessResponse | null>(null);

  const [historyPush] = useHistoryWithOrgId();

  useEffect(() => {
    if (!registerSuccessResponse) {
      return;
    }

    const {
      userExists,
      isMemberOfAccountingFirm,
      userRole,
      invitedByAccountant,
      existingUserInvitedByFirm,
      orgId,
    } = registerSuccessResponse;

    if (existingUserInvitedByFirm) {
      historyPush({
        path: isPlatformAccountantsNewFlowEnabled
          ? locations.MainApp.dashboard.url({ orgId })
          : authLocations.register.acceptInvitationSuccess,
      });

      return;
    }

    if (userRole === Role.OWNER && invitedByAccountant) {
      historyPush({
        path: isPlatformAccountantsNewFlowEnabled
          ? locations.MainApp.dashboard.url({ orgId })
          : authLocations.register.setAccountantAmount,
      });

      return;
    }

    if (userRole === Role.ACCOUNTANT && !isMemberOfAccountingFirm) {
      historyPush({ path: authLocations.register.chooseAccountantType });

      return;
    }

    if (userExists) {
      historyPush({ path: authLocations.register.acceptInvitationSuccess });

      return;
    }

    if (isMemberOfAccountingFirm) {
      historyPush({ path: authLocations.register.addProfilePicture });

      return;
    }

    if (isPlatformMtlKycUplift) {
      historyPush({ path: authLocations.register.fromInvitationDob });

      return;
    }

    historyPush({ path: authLocations.register.fromInvitationSuccess });
  }, [historyPush, isPlatformAccountantsNewFlowEnabled, isPlatformMtlKycUplift, registerSuccessResponse]);

  return (
    <RegisterFromInvitation
      token={props.match.params.token}
      onRegisterSuccess={(registerSuccessResponse) => {
        setRegisterSuccessResponse(registerSuccessResponse);
      }}
      onInvalidToken={() => historyPush({ path: authLocations.register.invalidInvitation })}
    />
  );
}
