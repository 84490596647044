import { useSelector } from 'react-redux';
import { generatePath, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { ShiftVendorSuvcToAchFromGuestWrapper } from 'src/flows/shift-vendor-suvc-to-ach/wrappers/ShiftVendorSuvcToAchFromGuestWrapper';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { profileStore } from 'src/modules/profile/profile-store';
import { InvalidTokenPage } from 'src/pages/vendor/components/InvalidTokenPage';
import { NewDeliveryMethodVirtualCardPage } from 'src/pages/vendor/components/NewDeliveryMethodVirtualCardPage';
import CheckAddressChangedPage from 'src/pages/vendor/edit-check-adress/CheckAddressChangedPage';
import CheckChangeAddressPage from 'src/pages/vendor/edit-check-adress/CheckChangeAddressPage';
import { CheckDepositedSuccessPage } from 'src/pages/vendor/edit-check-adress/CheckDepositedSuccessPage';
import { CheckSentInfoPage } from 'src/pages/vendor/edit-check-adress/CheckSentInfoPage';
import { EditBlockedCheckAddressPage } from 'src/pages/vendor/edit-check-adress/EditBlockedCheckAddressPage';
import { useEditCheckAddressRedirect } from 'src/pages/vendor/edit-check-adress/hooks/useEditCheckAddressRedirect';
import SwitchedToACHAlreadyPage from 'src/pages/vendor/edit-check-adress/SwitchedToACHAlreadyPage';
import { VendorCandidatesRouter } from 'src/pages/vendor/msn-candidates/Router';
import { AddDeliveryMethodPage } from 'src/pages/vendor/shift-vendor-to-ach/AddDeliveryMethodPage';
import { useInitFetch } from 'src/pages/vendor/shift-vendor-to-ach/hoc/useInitFetchHook';
import LinkDeliveryMethodPage from 'src/pages/vendor/shift-vendor-to-ach/LinkDeliveryMethodPage';
import ShiftedCurrentPaymentPage from 'src/pages/vendor/shift-vendor-to-ach/ShiftedCurrentPaymentPage';
import SuccessAddDeliveryMethodPage from 'src/pages/vendor/shift-vendor-to-ach/SuccessAddDeliveryMethodPage';
import { SwitchCompanyPage } from 'src/pages/vendor/shift-vendor-to-ach/SwitchCompanyPage';
import { ShiftToDebitRouter } from 'src/pages/vendor/shift-vendor-to-debit/ShiftToDebitRouter';
import { UpgradePaymentRouter } from 'src/pages/vendor/upgrade-payment/UpgradePaymentRouter';
import { VirtualCardCandidatesRouter } from 'src/pages/vendor/virtual-card/candidates/VirtualCardCandidatesRouter';
import { VirtualCardDetailsPage } from 'src/pages/vendor/virtual-card/delivery-method/VirtualCardDetailsPage';
import { AddDeliveryMethodSuccessPage } from 'src/pages/vendor/virtual-delivery-method/AddDeliveryMethodSuccessPage';
import { useUnilateralPaymentState } from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { VendorSelectDeliveryMethodPage } from 'src/pages/vendor/virtual-delivery-method/VendorSelectDeliveryMethodPage';
import { VendorSwitchCompanyPage } from 'src/pages/vendor/virtual-delivery-method/VendorSwitchCompanyPage';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { DeliveryType } from 'src/utils/consts';
import { vendorLocations } from './locations';

const UnilateralRouter = () => {
  const match = useRouteMatch<{ token?: string }>();
  const token = match.params?.token || '';
  const [state, actions, newDeliveryMethod, currentDeliveryMethod] = useUnilateralPaymentState({ token });
  const isLoggedIn = useSelector(getIsLoggedIn);
  const organizations = useSelector(profileStore.selectors.getOrganizations);

  const getInitialUrl = () => {
    if (currentDeliveryMethod) {
      if (currentDeliveryMethod.deliveryType === DeliveryType.VIRTUAL) {
        return isLoggedIn && organizations.length > 1
          ? vendorLocations.unilateral.switchCompany
          : vendorLocations.unilateral.addMethod;
      }

      return vendorLocations.unilateral.success;
    }

    return vendorLocations.unilateral.index;
  };

  return (
    <Switch>
      <SmartRoute path={vendorLocations.unilateral.index} exact>
        <Redirect to={generatePath(getInitialUrl(), { token })} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.unilateral.addMethod} exact>
        <VendorSelectDeliveryMethodPage
          state={state}
          actions={actions}
          deliveryMethod={newDeliveryMethod}
          token={token}
        />
      </SmartRoute>
      <SmartRoute path={vendorLocations.unilateral.switchCompany} exact>
        <VendorSwitchCompanyPage token={token} state={state} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.unilateral.invalidToken} exact>
        <InvalidTokenPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.unilateral.success} exact>
        <AddDeliveryMethodSuccessPage newDeliveryMethod={newDeliveryMethod} token={token} />
      </SmartRoute>
    </Switch>
  );
};

const ShiftVendorToAchRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const id = match.params?.id || '';
  useInitFetch(id, token);

  return (
    <Switch>
      <SmartRoute path={vendorLocations.shiftVendorToAch.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.addMethod} exact>
        <AddDeliveryMethodPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.linkMethod} exact>
        <LinkDeliveryMethodPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.switchCompany} exact>
        <SwitchCompanyPage token={token} vendorId={id} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.shiftedCurrentPayment} exact>
        <ShiftedCurrentPaymentPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.success} exact>
        <SuccessAddDeliveryMethodPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.deliveryMethodChangedToVirtualCard} exact>
        <NewDeliveryMethodVirtualCardPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.invalidToken} exact>
        <InvalidTokenPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

const ShiftSuvcToAchRouter = () => {
  const match = useRouteMatch<{ token: string }>();
  const token = match.params?.token || '';

  return (
    <Switch>
      <SmartRoute path={vendorLocations.shiftSuvcToAch.index} exact>
        <ShiftVendorSuvcToAchFromGuestWrapper token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftSuvcToAch.invalidToken} exact>
        <InvalidTokenPage token={token} />;
      </SmartRoute>
    </Switch>
  );
};

const EditCheckAddressRouter = () => {
  const match = useRouteMatch<{ token: string; id: string }>();
  const token = match.params?.token || '';
  const id = match.params?.id || '';
  useEditCheckAddressRedirect({ token, id });

  return (
    <Switch>
      <SmartRoute path={vendorLocations.editCheckAddress.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.checkSent} exact>
        <CheckSentInfoPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.checkDeposited} exact>
        <CheckDepositedSuccessPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.checkNotSentChangeAddress} exact>
        <CheckChangeAddressPage token={token} id={id} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.checkNotSendAddressSaved} exact>
        <CheckAddressChangedPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.switchedToACH} exact>
        <SwitchedToACHAlreadyPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.checkBlocked} exact>
        <EditBlockedCheckAddressPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.deliveryMethodChangedToVirtualCard} exact>
        <NewDeliveryMethodVirtualCardPage token={token} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.invalidToken} exact>
        <InvalidTokenPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

const VirtualCardRouter = () => {
  const match = useRouteMatch<{ token?: string; id?: string }>();
  const token = match.params?.token || '';
  const vcId = match.params?.id || '';

  return (
    <Switch>
      <SmartRoute path={vendorLocations.virtualCard.index} exact>
        <VirtualCardDetailsPage token={token} virtualCardId={vcId} />
      </SmartRoute>
      <SmartRoute path={vendorLocations.virtualCard.invalidToken} exact>
        <InvalidTokenPage token={token} />
      </SmartRoute>
    </Switch>
  );
};

export function VendorsRouter() {
  return (
    <Switch>
      <SmartRoute path={vendorLocations.unilateral.base}>
        <UnilateralRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftSuvcToAch.base}>
        <ShiftSuvcToAchRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftVendorToAch.base}>
        <ShiftVendorToAchRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.editCheckAddress.base}>
        <EditCheckAddressRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.shiftToDebit.base}>
        <ShiftToDebitRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.virtualCard.base}>
        <VirtualCardRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.virtualCardCandidates.base}>
        <VirtualCardCandidatesRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.vendorCandidates.base}>
        <VendorCandidatesRouter />
      </SmartRoute>
      <SmartRoute path={vendorLocations.upgradePayment.base}>
        <UpgradePaymentRouter />
      </SmartRoute>
    </Switch>
  );
}
