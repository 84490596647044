import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotices } from 'src/components/common/MINotices';
import { Link } from 'src/core/ds/link';

export const EmptyState = () => {
  const intl = useIntl();

  const emptyStateLinkHref = intl.formatMessage({ id: 'settings.approvalWorkflows.page.noWorkflowsLinkHref' });

  return (
    <MINotices
      notices={['settings.approvalWorkflows.page.noWorkflows']}
      noticeValues={{
        link: (
          <Link href={emptyStateLinkHref} target="_blank" rel="noopener noreferrer">
            <MIFormattedText label="settings.approvalWorkflows.page.noWorkflowsLinkText" />
          </Link>
        ),
      }}
    />
  );
};
