import { PAGE_EVENT } from 'src/pages/get-pro/consts';
import { EventMappingsTreeChild } from 'src/services/analytics/types';

export const CARD_EVENT_MAPPING: EventMappingsTreeChild = {
  'getPaid.view.invoice.edit': [PAGE_EVENT, 'edit-invoice-from-action'],
  'getPaid.view.invoice.markAsPaid': [PAGE_EVENT, 'mark-as-paid-from-action'],
  'getPaid.view.invoice.delete': [PAGE_EVENT, 'delete-from-action'],
  'getPro.cards.actions.close': [PAGE_EVENT, 'close-drawer'],
  'getPro.cards.actions.send': [PAGE_EVENT, 'send-invoice'],
  'getPro.cards.actions.resend': [PAGE_EVENT, 'resend-invoice'],
  'getPro.cards.actions.remove': [PAGE_EVENT, 'remove-payment'],
  'getPaid.view.invoice.undoMarkAsPaid': [PAGE_EVENT, 'undo-mark-as-paid'],
  'getPaid.view.payment.delete': [PAGE_EVENT, 'delete-payment'],
  'getPaid.view.paymentRequest.edit': [PAGE_EVENT, 'edit-request-from-action'],
  'getPaid.view.paymentRequest.delete': [PAGE_EVENT, 'delete-request-from-action'],
  'getPaid.view.paymentRequest.deleteMessage.confirm': [PAGE_EVENT, 'delete-request-confirm-modal'],
  'getPaid.view.paymentRequest.deleteMessage.cancel': [PAGE_EVENT, 'delete-request-cancel-modal'],
};
