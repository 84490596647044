import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { getOrgId } from 'src/redux/user/selectors';
import { accountingPlatformBankAccountsStore } from '../accounting-platform-bank-accounts-store';

export const useFetchAccountingPlatformBankAccounts = ({ forceReload = false }: { forceReload: boolean }): [] => {
  const [reloaded, setReloaded] = useState(forceReload);
  const accountingPlatformBankAccountsActions = useStoreActions(accountingPlatformBankAccountsStore);

  const orgId = useSelector(getOrgId);
  const { isConnected: isConnectedToAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const status = useSelector(accountingPlatformBankAccountsStore.selectors.list.status({ orgId }));

  useEffect(() => {
    if (isConnectedToAccountingPlatform && (isEmpty(status) || reloaded)) {
      setReloaded(false);
      accountingPlatformBankAccountsActions.list({ orgId });
    }
  }, [status, reloaded, accountingPlatformBankAccountsActions, orgId, isConnectedToAccountingPlatform]);

  return [];
};
