const BASE_LOCATION = '/micro-deposit/:token';

export const microDepositLocations = {
  base: [BASE_LOCATION],
  index: BASE_LOCATION,
  success: `${BASE_LOCATION}/success`,
  alreadyVerified: `${BASE_LOCATION}/already-verified`,
  verification: `${BASE_LOCATION}/verification`,
  tooManyAttempts: `${BASE_LOCATION}/to-many-attempts`,
  error: `${BASE_LOCATION}/error`,
};
