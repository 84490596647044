import { useEffect, useState } from 'react';
import config from 'src/config';

const WEB_STORE_URL_UTM_PARAMS = {
  settings: {
    utm_campaign: 'chrome_extension_settings',
    utm_source: 'product',
    utm_medium: 'null',
  },
  dashboard: {
    utm_campaign: 'chrome_extension_dashboard',
    utm_source: 'product',
    utm_medium: 'null',
  },
  signup_flow: {
    utm_campaign: 'chrome_extension_signup_flow',
    utm_source: 'product',
    utm_medium: 'null',
  },
};

export function useExtension() {
  const extensionId = config.spendManagement.amexExtensionId;
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean | undefined>(undefined);
  const [extensionVersion, setExtensionVersion] = useState<string>('');

  useEffect(() => {
    if (extensionId) {
      try {
        window.chrome.runtime.sendMessage(extensionId, { command: 'VERSION' }, (version: string) => {
          if (version) {
            setIsExtensionInstalled(true);
            setExtensionVersion(version);
          } else {
            setIsExtensionInstalled(false);
          }
        });
      } catch (_e) {
        setIsExtensionInstalled(false);
      }
    } else {
      setIsExtensionInstalled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const webStoreUrlParams = (page: string) => {
    const params = Object.keys(WEB_STORE_URL_UTM_PARAMS[page] || [])
      .map((key) => `${key}=${WEB_STORE_URL_UTM_PARAMS[page][key]}` || '')
      .join('&');

    return params.length ? `?${params}` : '';
  };

  return {
    extensionId,
    isExtensionInstalled,
    extensionVersion,
    webStoreUrlParams,
    extensionLoading: typeof isExtensionInstalled === 'undefined',
    webStoreUrl: `https://chrome.google.com/webstore/detail/${extensionId}`,
  };
}
