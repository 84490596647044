import { BillStatus } from 'src/utils/consts';

export enum AddressNameType {
  LEGAL_ADDRESS = 'legalAddress',
  COMPANY_ADDRESS = 'companyAddress',
}

export const BILL_STATUSES = [BillStatus.UNPAID, BillStatus.SCHEDULED, BillStatus.PAID];

export const FAILED_TO_DELIVER_ERR = 'FAILED_TO_DELIVER';
