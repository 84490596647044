import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { useSearchValue } from 'src/hoc/withListSearchBarContext';
import { LIMIT_NOT_SET_VALUE } from './constants';

export const AmountCell: React.FC<CellProps<any>> = ({ cell: { value } }) => {
  const search = useSearchValue();
  const { createRequestAmount, amount } = value;
  const amountValue = createRequestAmount || amount;

  return useMemo(
    () => (
      <>
        {amount &&
          amountValue !== 'null' && ( // no amount - don't show anything
            <Box isTruncated textStyle="body3Semi">
              {amountValue && amountValue !== String(LIMIT_NOT_SET_VALUE) ? (
                <MIFormattedCurrency value={Number(amountValue / 100)} currency="$" search={search} privateData />
              ) : (
                // amount wasn't set on card creation
                <Box textStyle="body3">
                  <MIFormattedText label="spendManagement.pages.dashboard.cardsList.defaults.amountNotSet" />
                </Box>
              )}
            </Box>
          )}
      </>
    ),
    [amount, createRequestAmount, search]
  );
};
