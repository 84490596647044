import styled, { css } from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { devices } from 'src/theme/appDevices';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { BillType } from 'src/utils/types';
import PartialPaymentsInfo from './PartialPaymentsInfo';

type Props = {
  isPartialPayments: boolean;
  currentPaymentId?: string;
  bill: BillType;
  onLabelClick?: (paymentId, status) => void;
  showLabel?: boolean;
  fullWidth?: boolean;
};

const BillOpenBalanceInfo = ({
  bill,
  isPartialPayments,
  currentPaymentId,
  onLabelClick,
  showLabel = true,
  fullWidth = false,
}: Props) => {
  const openBalance = bill?.balance || 0;
  const totalAmount = Number(convertCurrencyToNumber(bill?.totalAmount));

  return (
    <BillOpenBalanceContainer fullWidth={fullWidth}>
      <OpenBalanceContainer data-testid="bill-open-balance-container">
        <MIFormattedText label="bills.form.partialPayments.openBalance" />
        <MIMoney amount={isPartialPayments ? openBalance : totalAmount} />
      </OpenBalanceContainer>
      {isPartialPayments && (
        <PartialPaymentsContainer>
          <Separator />
          <PartialPaymentsInfoBlock
            bill={bill}
            onLabelClick={onLabelClick}
            currentPaymentId={currentPaymentId}
            showLabel={showLabel}
          />
          <TotalAmountContainer data-testid="bill-total-amount-container">
            <MIFormattedText label="bills.form.partialPayments.total" />
            <MIFormattedCurrency value={totalAmount} />
          </TotalAmountContainer>
        </PartialPaymentsContainer>
      )}
    </BillOpenBalanceContainer>
  );
};

const PartialPaymentsInfoBlock = styled(PartialPaymentsInfo)`
  ${({ theme }) => theme?.components?.BillOpenBalance?.PartialPaymentsInfoBlock}
`;

const BillOpenBalanceContainer = styled.div`
  ${(props) => (props.fullWidth ? `padding: 3.5rem 10rem;` : `padding: 3.5rem 3.6rem;`)}
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${(props) => props.theme.text.color.main};
  border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  border-bottom-right-radius: 0.9rem;
  border-bottom-left-radius: 0.9rem;
  @media ${devices.mobile} {
    padding: 1.7rem 1.6rem;
    box-sizing: border-box;
  }

  ${(props) => props.theme?.components?.BillOpenBalance?.BillOpenBalanceContainer}
`;

const containerBaseStyles = css`
  display: flex;
  justify-content: space-between;
`;

const OpenBalanceContainer = styled.div`
  ${containerBaseStyles};
  letter-spacing: 0.038rem;
  font-size: 2.3rem;
  width: 100%;

  ${({ theme }) => theme?.components?.BillOpenBalance?.OpenBalanceContainer}
`;

const TotalAmountContainer = styled.div`
  ${containerBaseStyles};
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.regular};

  > span {
    font-weight: ${(props) => props.theme.text.weight.semiBold};
  }

  ${({ theme }) => theme?.components?.BillOpenBalance?.TotalAmountContainer}
`;

const PartialPaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2.6rem 0 0.8rem;

  ${({ theme }) => theme?.components?.BillOpenBalance?.PartialPaymentsContainer}
`;

const Separator = styled.div`
  height: 0.1rem;
  background-color: ${(props) => props.theme.text.color.readonly};
  margin-bottom: 2.4rem;

  ${({ theme }) => theme?.components?.BillOpenBalance?.Separator}
`;

export default BillOpenBalanceInfo;
