import { Box } from '@chakra-ui/react';
import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ContactSupportLink } from 'src/components/common/ContactSupportLink';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import { BankAccountType, DbAnalyticsTraits, DeliveryType, NotificationVariant } from 'src/utils/consts';
import { BankType } from 'src/utils/types';
import { useReceivingMethodConfig } from '../context/ReceivingMethodContext';

type Props = {
  onExit: () => void;
  onNext: (deliveryMethodId: number) => void;
  onPrev?: () => void;
  vendorId: number;
  progressBarProps: Record<string, any>;
};

export const ReceivingMethodManualBankAccountPage = ({ onExit, onNext, onPrev, vendorId, progressBarProps }: Props) => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const [isLoading, setIsLoading] = useState(false);
  const errorToastRef = useRef<null | number>(null);

  const { title, subtitle, nextLabel } = {
    ...useReceivingMethodConfig({
      page: 'ReceivingMethodManualBankAccountPage',
    }),
  };

  const refreshDeliveryMethods = () =>
    new Promise((resolve, reject) => {
      dispatch(loadDeliveryMethodsAction(resolve, reject));
    });

  const onSubmit = async (data: Partial<BankType>) => {
    const bankAccount = {
      ...data,
      accountType: BankAccountType.CHECKING,
    };
    const validationErrors = getValidationErrors('deliveryMethodAch', bankAccount, ['routingNumber', 'accountNumber']);

    if (!isValidationOk(validationErrors)) {
      throw new ValidationError({ validationErrors });
    }

    try {
      setIsLoading(true);
      analytics.trackAction('add-ach-receiving-method', {
        type: DeliveryType.ACH,
      });
      analytics.setTraits({
        [DbAnalyticsTraits.ADDED_DELIVERY_METHOD]: true,
      });
      const deliveryMethod = {
        bankAccount,
        deliveryType: DeliveryType.ACH,
        isFilledByVendor: true,
      };
      const response = await deliveryMethodsApi.addDeliveryMethod({
        orgId,
        vendorId,
        params: deliveryMethod,
      });

      await refreshDeliveryMethods();
      analytics.trackAction('add-ach-receiving-method-success', {
        type: DeliveryType.ACH,
      });
      setIsLoading(false);
      onNext(response.deliveryMethod?.id);
    } catch (error: any) {
      analytics.trackAction('add-ach-receiving-method-failure', {
        type: DeliveryType.ACH,
        error,
      });
      setIsLoading(false);

      if (!checkIfToastIsDisplayedById(errorToastRef.current)) {
        errorToastRef.current = pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'onboarding.msnCompanyInfo.generalError.text',
          textValues: {
            contactSupport: <ContactSupportLink />,
          },
          autoClose: false,
          testId: 'notification-add-ach-receiving-method-error',
        });
      }
    }
  };

  const model = useMemo(
    () => ({
      routingNumber: '',
      accountNumber: '',
    }),
    []
  );
  const [bankAccountVM, { submit }] = useForm(model, { submit: onSubmit });

  const Footer = () => (
    <Box mt={34}>
      <MISecurityDetails eventPage="onboarding-vendor-delivery-method" />
    </Box>
  );

  return (
    <StepLayoutPage
      title={title}
      subtitle={subtitle}
      goExit={onExit}
      onPrev={onPrev}
      onSubmit={submit}
      nextLabel={nextLabel}
      isLoading={isLoading}
      hideHeader
      fullWidthCTA
      footer={<Footer />}
      {...progressBarProps}
    >
      <WizardFormContainer>
        <TextField
          model={bankAccountVM.routingNumber}
          label="onboarding.deliveryMethods.bank.routingNumber"
          autoFocus
          isRequired
        />

        <PasswordField
          model={bankAccountVM.accountNumber}
          label="onboarding.deliveryMethods.bank.accountNumber"
          isRequired
          shouldShowValue
        />

        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};

const HiddenInput = styled.input`
  display: none;
`;
