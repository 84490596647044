import { ReactNode, useState } from 'react';
import ReactDOM from 'react-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { ListItem, UnorderedList } from 'src/core/ds/list';
import { analytics } from 'src/services/analytics';
import { AccountingFirmContainer } from './common/AccountingFirmContainer';
import { Label } from './common/Label';
import { LabelNoMargin } from './common/LabelNoMargin';
import { TopPartContainer } from './common/TopPartContainer';
import { ExtraDropdownOptionType, MIExtraDropdownItem } from './MIExtraDropdownItem';
import { baseContainerStyles, baseItemStyles } from './MIExtraDropdownStyles';

type Props = {
  accountingFirm?: ExtraDropdownOptionType;
  emptyState: boolean;
  valuesList: Array<ExtraDropdownOptionType>;
  onChange: (value: ExtraDropdownOptionType) => void;
  value: ExtraDropdownOptionType;
  buttonAction?: () => void;
  buttonLabel?: string;
  mobileTitleLabel: string;
};

type ListProps = {
  children?: ReactNode;
};

type ItemContainerProps = {
  children?: ReactNode;
  selected?: boolean;
  onClick: () => void;
};

export const MIExtraDropdownMobile = ({
  accountingFirm,
  emptyState,
  valuesList,
  value,
  onChange,
  buttonAction,
  buttonLabel,
  mobileTitleLabel,
}: Props) => {
  const [isOpen, toggle] = useState(false);
  const toggleIsOpen = (value) => {
    if (value !== isOpen) {
      analytics.trackAction('companies-switcher-mobile', { isOpen: value });
      toggle(value);
    }
  };

  const onClick = (item) => {
    analytics.track('companies-switcher-mobile', 'company-changed');
    onChange(item);
  };

  if (isOpen) {
    return ReactDOM.createPortal(
      <Flex
        data-testid="organization-switcher-content"
        direction="column"
        w="full"
        h="full"
        position="fixed"
        top={0}
        overflow="hidden"
        outline="none"
        zIndex={10000}
      >
        <Flex
          align="center"
          cursor="pointer"
          bgColor="white"
          h="6rem"
          boxSizing="border-box"
          position="relative"
          boxShadow={400}
          py={2}
          px={3}
        >
          <Flex w="full" align="center" justify="space-between">
            <Flex onClick={() => toggleIsOpen(false)}>
              <Icon name={IconNames.close} size={IconSize.lg} />
            </Flex>
            <Flex textStyle="body2Semi">
              <MIFormattedText label={mobileTitleLabel} />
            </Flex>
            <div />
          </Flex>
        </Flex>
        <Flex direction="column" bgColor="white" w="full" h="full" overflowY="auto">
          {!!accountingFirm && (
            <>
              <AccountingFirmContainer emptyState={emptyState}>
                <Label>
                  <MIFormattedText label="organizationSwitcher.accountingFirm" />
                </Label>
                <ItemContainer
                  selected={!emptyState && value.id === accountingFirm.id}
                  onClick={() => {
                    onClick(accountingFirm);
                  }}
                >
                  <MIExtraDropdownItem item={accountingFirm} selected={!emptyState && value.id === accountingFirm.id} />
                </ItemContainer>
              </AccountingFirmContainer>
              {!emptyState && (
                <Label>
                  <MIFormattedText label="organizationSwitcher.firmsClients" />
                </Label>
              )}
            </>
          )}
          {!accountingFirm && (
            <TopPartContainer>
              <LabelNoMargin>
                <MIFormattedText label="nav.companies" />
              </LabelNoMargin>
            </TopPartContainer>
          )}
          <List>
            {valuesList.map((item) => {
              const isSelected = value.id === item.id;

              return (
                <ItemContainer
                  selected={isSelected}
                  key={item.id}
                  onClick={() => {
                    if (!isSelected) {
                      onClick(item);
                    }
                  }}
                >
                  <MIExtraDropdownItem item={item} selected={isSelected} />
                </ItemContainer>
              );
            })}
          </List>
        </Flex>
        {buttonAction && buttonLabel && (
          <Flex zIndex={1} bgColor="white" p={3} boxShadow={600}>
            <Button
              variant={emptyState ? ButtonVariants.primary : ButtonVariants.tertiary}
              onClick={buttonAction}
              isFullWidth
              size={ButtonSizes.lg}
              label={buttonLabel}
              borderRadius="md"
              textStyle="body2Semi"
            />
          </Flex>
        )}
      </Flex>,
      document.body
    );
  }

  return (
    <Flex
      onClick={() => toggleIsOpen(true)}
      data-testid="organization-switcher-button"
      h="4rem"
      align="center"
      bgColor="white"
      boxSizing="border-box"
      borderBottom="1px"
      borderColor="grey.400"
      py={2}
      px={3}
      cursor="pointer"
      w="full"
    >
      <Flex w="full" align="center" justify="space-between">
        <MIExtraDropdownItem item={value} selected={false} key={value.id} />
        <Icon name={IconNames.shevronRight} size={IconSize.lg} />
      </Flex>
    </Flex>
  );
};

const List = ({ children }: ListProps) => (
  <UnorderedList p={0} m={0}>
    {children}
  </UnorderedList>
);

const ItemContainer = ({ onClick, selected, children }: ItemContainerProps) => (
  <ListItem
    onClick={onClick}
    __css={{ ...baseContainerStyles, ...baseItemStyles }}
    justifyContent="space-between"
    bgColor={selected ? 'grey.200' : undefined}
  >
    {children}
  </ListItem>
);
