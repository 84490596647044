import axios from 'axios';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { useAbortControllerSignal } from 'src/hooks/useAbortControllerSignal';
import { filesApi } from 'src/modules/files/api';
import { billFactory } from 'src/pages/bill/records';
import { getFirstFileId } from 'src/pages/bill/utils/billGetters';
import { VendorOptionType } from 'src/pages/vendor-directory/select-vendor/types';
import {
  createLocalVendorOption,
  createNewLocalVendorOptionFromName,
} from 'src/pages/vendor-directory/select-vendor/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { capture } from 'src/utils/error-tracking';
import { useLocationState } from 'src/utils/hooks';
import { BillType } from 'src/utils/types';

const eventPage = 'bill-create';
export const useBillFiles = () => {
  const orgId = useSelector(getOrgId);
  const [filesFromState] = useLocationState<readonly number[]>('files', []);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadError, setIsUploadError] = useState(false);
  const [files, setFiles] = useState(filesFromState);
  const [fileName, setFileName] = useState('');
  const { onApiCall: getBillDetails } = useApi({
    api: filesApi.billDetails,
  });
  const { onApiCall: uploadFile } = useApi({
    api: filesApi.uploadFile,
  });
  const { getAbortControllerSignal, abortController } = useAbortControllerSignal();

  const handleUploadStart = () => {
    analytics.track(eventPage, 'upload-image');
    setIsUploading(true);
    setIsUploadError(false);
  };

  const handleUploadCancel = useCallback(() => {
    analytics.track(eventPage, 'cancel-upload-image');
    setIsUploading(false);
    setIsUploadError(false);
    setFiles([]);
    abortController?.abort();
  }, [abortController]);

  const handleUploadError = useCallback(() => {
    analytics.track(eventPage, 'error-upload-image');
    setFileName('');
    setFiles([]);
    setIsUploading(false);
    setIsUploadError(true);
  }, []);

  const handleRetry = useCallback(() => {
    analytics.track(eventPage, 'retry-upload-image');
    setFileName('');
    setFiles([]);
    setIsUploading(false);
    setIsUploadError(false);
  }, []);

  const removeAttachment = useCallback(() => {
    setFiles([]);
    setFileName('');
  }, []);

  const handleUploadRemove = useCallback(() => {
    analytics.track(eventPage, 'remove-image');
    setFiles([]);
  }, []);

  const handleUploadSuccess = useCallback((file: Record<string, any>) => {
    analytics.track(eventPage, 'upload-10K-invoice-file-success');
    setIsUploading(false);
    setIsUploadError(false);
    setFileName(file.fileName);
    setFiles([file.id]);
  }, []);

  const extractBillDetailsFromFile = useCallback(
    async (
      fileId: number,
      bill: BillType
    ): Promise<{
      extractedBill: BillType | null;
      extractedVendor: VendorOptionType | null;
    }> => {
      let extractedVendor: VendorOptionType | null = null;
      let extractedBill: BillType | null = null;

      const result = await getBillDetails(orgId, fileId, { signal: getAbortControllerSignal() });

      if (!result?.extract) return { extractedBill, extractedVendor }; // we need to think of a message to user about download failure

      const { extract } = result;
      const dueDate = extract.dueDate ? moment(extract.dueDate).toDate() : bill.dueDate;
      const totalAmount = extract.totalAmount || bill.totalAmount;
      const invoiceNumber = extract.invoiceNumber || bill.invoiceNumber;
      // pick the matched name, if not exist take the raw name.
      const vendorName = extract.vendorMatchedName || extract.vendorName;
      let vendorId: number | undefined;

      if (extract.matchedVendor) {
        extractedVendor = createLocalVendorOption(extract.matchedVendor);
        vendorId = +extractedVendor.value;
      } else if (vendorName) {
        extractedVendor = createNewLocalVendorOptionFromName(vendorName);
        vendorId = +extractedVendor.value;
      }

      extractedBill = billFactory({
        ...bill,
        vendorId,
        totalAmount,
        invoiceNumber,
        dueDate,
      });
      setFiles([fileId]);
      setIsUploading(false);

      return { extractedBill, extractedVendor };
    },
    [getAbortControllerSignal, orgId]
  );

  const loadBillFromFile = useCallback(
    async (
      file: Record<string, any>,
      bill: BillType
    ): Promise<{ extractedBill: BillType | null; extractedVendor: VendorOptionType | null }> => {
      let result;
      try {
        result = await extractBillDetailsFromFile(+file.id, bill);
      } catch (error: any) {
        capture(error, 'extract-bill-details');
        handleUploadError();
      }

      return result;
    },
    [extractBillDetailsFromFile, handleUploadError]
  );

  const setBillFiles = useCallback(async (bill: BillType) => {
    if (bill) {
      const fileId = getFirstFileId(bill);
      setFiles(fileId ? [+fileId] : []);
    }
  }, []);

  const uploadAttachment = useCallback(
    async (file: File, bill: BillType, loadBillFromAttachment = false) => {
      let result: ReturnType<typeof loadBillFromFile> | undefined;
      handleUploadStart();
      try {
        const resultFileUpload = await uploadFile(orgId, file, { signal: getAbortControllerSignal() });

        if (loadBillFromAttachment) {
          result = loadBillFromFile(resultFileUpload.file, bill);
        } else {
          handleUploadSuccess(resultFileUpload.file);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          handleUploadError();
        }
      }

      return result;
    },
    [getAbortControllerSignal, handleUploadError, handleUploadSuccess, loadBillFromFile, orgId]
  );

  return {
    files,
    fileName,
    isUploading,
    isUploadError,
    handleUploadCancel,
    handleUploadRemove,
    handleRetry,
    uploadAttachment,
    removeAttachment,
    setBillFiles,
  };
};
