import { CardTypes, FundingType } from 'src/utils/consts';
import { isAmexNetwork, isBankAccountBlocked, isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

export const getEmptyCreditCardFundingSourceDescription = ({
  isSettings,
  isBillingFee,
  isVendorAbsorbedFee,
  isInternationalDisabled,
}: {
  isInternationalDisabled?: boolean;
  isVendorAbsorbedFee?: boolean;
  isBillingFee?: boolean;
  isSettings: boolean;
}) => {
  if (isInternationalDisabled) {
    return 'vendors.deliveryMethods.international.blockCardDescription';
  }

  if (isVendorAbsorbedFee) {
    return 'onboarding.fundingSources.options.credit.freeFeeDescription';
  }

  if (isBillingFee) {
    return 'billing.addMethod.creditCard.emptyMethodDescription';
  }

  if (isSettings) {
    return 'onboarding.fundingSources.options.credit.sameDayDeliverySettingsDescription';
  }

  return 'onboarding.fundingSources.options.credit.sameDayDeliveryDescription';
};
export const getCreditCardFundingSourceDescription = ({
  isBillingFee,
  isVendorAbsorbedFee,
  isCreditCardExpired,
  isSettings,
  fundingSource,
}: {
  fundingSource: AccountType;
  isBillingFee?: boolean;
  isVendorAbsorbedFee?: boolean;
  isCreditCardExpired: boolean;
  isSettings: boolean;
}): string => {
  if (isCreditCardExpired) {
    return 'bills.pay.fundingSource.expiredDescription';
  }

  if (isBillingFee) {
    return 'billing.addMethod.creditCard.methodDescription';
  }

  if (isAmexNetwork(fundingSource)) {
    return 'onboarding.fundingSources.options.credit.amexDescription';
  }

  if (isVendorAbsorbedFee) {
    return 'onboarding.fundingSources.options.credit.freeFeeDescription';
  }

  if (isSettings) {
    return 'onboarding.fundingSources.options.credit.sameDayDeliverySettingsDescription';
  }

  return 'onboarding.fundingSources.options.credit.sameDayDeliveryDescription';
};

export const isDebitCard = (fundingSource: AccountType) =>
  fundingSource.fundingType === FundingType.CARD && fundingSource.cardAccount?.cardType === CardTypes.DEBIT;

export const getBankAccountDescription = (
  fundingSource: AccountType,
  isDebitCardExpired: boolean,
  isUserNotMicroDepositEligible: boolean,
  isBillingFee?: boolean
) => {
  if (isDebitCardExpired) {
    return 'bills.pay.fundingSource.expiredDescription';
  }

  if (isDebitCard(fundingSource)) {
    return 'bills.pay.fundingSource.debitWithBankDescription';
  }

  if (isManualBankAccountNotVerified(fundingSource)) {
    if (isBankAccountBlocked(fundingSource)) {
      return 'settings.paymentMethods.microDepositsBlocked';
    }

    if (isUserNotMicroDepositEligible) {
      return 'settings.paymentMethods.notVerifiedAccountHint';
    }

    return 'settings.paymentMethods.notVerifiedAccountHintWithAction';
  }

  if (isBillingFee) {
    return 'billing.addMethod.bankAccount.methodDescription';
  }

  return 'bills.pay.fundingSource.achDescription';
};

export const getDebitCardNonMethodDescription = (isInternationalVendor?: boolean, isBillingFee?: boolean) => {
  if (isInternationalVendor) {
    return 'vendors.deliveryMethods.international.emptyDebitCardDescription';
  }

  return isBillingFee
    ? 'billing.addMethod.debitCard.emptyMethodDescription'
    : 'bills.pay.fundingSource.debitDescription';
};

export const getDebitCardMethodDescription = (isDebitCardExpired: boolean, isBillingFee?: boolean) => {
  if (isDebitCardExpired) {
    return 'bills.pay.fundingSource.expiredDescription';
  }

  return isBillingFee ? 'billing.addMethod.debitCard.methodDescription' : 'bills.pay.fundingSource.debitDescription';
};
