import { ChangeEvent, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { ListItemContainer } from 'src/components/list/ListItemContainer';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { convertToDisplayAddress } from 'src/utils/address';
import { AddressType } from 'src/utils/types';

type Props = {
  id: number;
  paymentRequestId?: string;
  search?: string;
  type?: string;
  name: string;
  phone?: string | null;
  email?: string | null;
  address?: AddressType | null;
  isSelected?: boolean;
  showPendingUnilateralTag: boolean;
  showDirectoryVendorLogo: boolean;
  batchSelectionEnabled?: boolean;
  showCheckbox?: boolean;
  isCheckboxMarked?: boolean;
  setCheckboxMarked?: (id: number, value: boolean) => void;
};

const eventPage = 'contacts';

export const VendorListItem = ({
  id,
  paymentRequestId,
  search,
  type,
  name,
  phone,
  email,
  address,
  isSelected,
  showPendingUnilateralTag,
  showDirectoryVendorLogo,
  batchSelectionEnabled = false,
  showCheckbox = false,
  isCheckboxMarked = false,
  setCheckboxMarked,
}: Props) => {
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const indicator = showDirectoryVendorLogo && address ? convertToDisplayAddress(address) : phone || email || '';
  const basePath = `id=${id}${search ? `&search=${search}` : ''}${
    paymentRequestId ? `&paymentRequestId=${paymentRequestId}` : ''
  }`;
  const shouldDisplayCheckBox = (showCheckbox || hovered) && batchSelectionEnabled;
  const showVendorAvatarImage = showDirectoryVendorLogo && !shouldDisplayCheckBox;

  const handleClick = () => {
    analytics.track(eventPage, 'nav-to-single', {
      type,
      id,
    });
    const vendorListSearchPath = encodeURI(basePath || '');

    history.push({
      pathname: generatePath(contactsLocations.index, {
        orgId,
        type,
      }),
      search: vendorListSearchPath,
    });
  };

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onCheckListCard = (e: ChangeEvent<HTMLInputElement>) => {
    if (setCheckboxMarked) {
      setCheckboxMarked(id, e.target.checked);
    }
  };

  return (
    <VendorListItemContainer
      activeClassName="selected"
      isSelected={isSelected}
      onClick={handleClick}
      testId={`vendor-list-item-container-${id}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex h="5.6rem" boxSizing="border-box">
        <Flex flex={1} w="-webkit-fill-available">
          <Flex pos="relative" boxSizing="border-box" align="center" width="full">
            {shouldDisplayCheckBox ? (
              <Checkbox
                data-testid={`vendor-batch-list-checkbox-${id}`}
                p={3}
                pr={0}
                onChange={onCheckListCard}
                isChecked={isCheckboxMarked || false}
              />
            ) : (
              <Box pos="relative">
                <Avatar name={name} size={Size.lg} textColor="white" />
                {showVendorAvatarImage && (
                  <Icon
                    width={4}
                    height={4}
                    borderRadius="full"
                    top="2.6rem"
                    left="2.6rem"
                    position="absolute"
                    border="2px"
                    borderColor="white"
                    name={IconNames.verifiedSolid}
                    color="primary.500"
                    data-testid="directory-vendor-sign"
                    background="white"
                  />
                )}
              </Box>
            )}

            <Flex w="100%" overflow="hidden" justify="center" direction="column" ml={4}>
              <Flex justify="space-between">
                <Box
                  textStyle="body2Semi"
                  color="black"
                  isTruncated
                  maxW={{
                    base: 'auto',
                    md: '30rem',
                  }}
                >
                  <Highlighter
                    searchWords={[search]}
                    autoEscape
                    textToHighlight={name}
                    data-testid="vendor-list-item-name"
                  />
                </Box>
                {showPendingUnilateralTag && (
                  <Box ml={1} h="fit-content">
                    <Badge
                      label="deliveryMethods.definedInfo.virtual.tag"
                      variant={BadgeVariants.FILLED}
                      color={BadgeColors.NEUTRAL}
                      testId="pending-unilateral"
                    />
                  </Box>
                )}
              </Flex>
              <Box textStyle="body4">
                <MIFieldOrEmpty
                  color="grey.700"
                  value={
                    <Highlighter
                      searchWords={[search]}
                      autoEscape
                      textToHighlight={indicator}
                      data-testid="vendor-list-item-contact-info"
                    />
                  }
                  label="vendors.view.emptyIndicator"
                  privateData
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </VendorListItemContainer>
  );
};

const VendorListItemContainer = styled(ListItemContainer)`
  padding: 2.4rem 2rem;
  min-height: 8.8rem;
  padding: ${(props) => (props.isSelected ? '2.2rem 1.8rem' : '2.4rem 2rem')};

  &:last-child {
    margin-bottom: 3rem;
  }
`;
