export const billPayLocations = {
  base: [
    '/orgs/:orgId/bills/pay/recurring',
    '/orgs/:orgId/bills/:billId/payment/:paymentId/edit',
    '/orgs/:orgId/bills/:billId/pay',
  ],
  deliveryMethod: '/orgs/:orgId/bills/:billId/pay/delivery-method',
  date: '/orgs/:orgId/bills/:billId/pay/date',
  funding: '/orgs/:orgId/bills/:billId/pay/funding',
  recurring: {
    funding: '/orgs/:orgId/bills/pay/recurring/funding',
    deliveryMethod: '/orgs/:orgId/bills/pay/recurring/delivery-method',
    memo: '/orgs/:orgId/bills/pay/recurring/memo',
    confirm: '/orgs/:orgId/bills/pay/recurring/confirm',
    success: '/orgs/:orgId/bills/pay/recurring/success',
    completeLegalInfo: '/orgs/:orgId/bills/pay/recurring/complete-legal-info',
    addBankAccount: {
      base: '/orgs/:orgId/bills/pay/recurring/add-bank-account/',
      index: '/orgs/:orgId/bills/pay/recurring/add-bank-account/*',
    },
    addDM: {
      ach: '/orgs/:orgId/bills/pay/recurring/add-ach/',
    },
  },
  installments: {
    applicationFlow: '/orgs/:orgId/bills/:billId/pay/installments/application/',
    scheduleFlow: '/orgs/:orgId/bills/:billId/pay/installments/schedule/',
    eligibilityPending: '/orgs/:orgId/bills/:billId/pay/installments/eligibility-pending/',
    amountRejected: '/orgs/:orgId/bills/:billId/pay/installments/amount-exceeded/',
    eligibilityRejected: '/orgs/:orgId/bills/:billId/pay/installments/eligibility-not-approved/',
    generalError: '/orgs/:orgId/bills/:billId/pay/installments/error',
  },
  confirm: '/orgs/:orgId/bills/:billId/pay/confirm',
  memo: '/orgs/:orgId/bills/:billId/pay/memo',
  completeLegalInfo: '/orgs/:orgId/bills/:billId/pay/complete-legal-info',
  schedule: {
    entry: '/orgs/:orgId/bills/:billId/pay',
    amount: '/orgs/:orgId/bills/:billId/pay/amount',
    deliveryMethod: '/orgs/:orgId/bills/:billId/pay/delivery-method',
    date: '/orgs/:orgId/bills/:billId/pay/date',
    funding: '/orgs/:orgId/bills/:billId/pay/funding',
    confirm: '/orgs/:orgId/bills/:billId/pay/confirm',
    memo: '/orgs/:orgId/bills/:billId/pay/memo',
    expedite: '/orgs/:orgId/bills/:billId/pay/expedite',
    success: '/orgs/:orgId/bills/:billId/pay/success',
    successFromDashboard: '/orgs/:orgId/bills/:billId/pay/success-from-dashboard',
    completeLegalInfo: '/orgs/:orgId/bills/:billId/pay/complete-legal-info',
  },
  edit: {
    deliveryMethod: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/delivery-method',
    deliveryMethodAch: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/delivery-method-ach',
    date: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/date',
    funding: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/funding',
    confirm: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/confirm',
    memo: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/memo',
    fastPayment: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/fast-payment',
    virtualCardRecovery: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/virtual-card-recovery',
    returnedCheckRecovery: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/returned-check-recovery',
    resendUndepositedCheck: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/resend-undeposited-check',
    undepositedCheckFastPayment: '/orgs/:orgId/bills/:billId/payment/:paymentId/edit/undeposited-check-fast-payment',
  },
  addBankAccount: {
    base: '/orgs/:orgId/bills/:billId/pay/add-bank-account/',
    index: '/orgs/:orgId/bills/:billId/pay/add-bank-account/*',
  },
  addDM: {
    ach: '/orgs/:orgId/bills/:billId/pay/add-ach/',
  },
  editDM: {
    ach: '/orgs/:orgId/bills/:billId/pay/edit-ach/:deliveryMethodId/',
  },
} as const;
