import type { SystemStyleObject } from '@chakra-ui/theme-tools';
import Input from './input';

const baseStyle: SystemStyleObject = {
  ...Input.baseStyle.field,
  textAlign: 'center',
  mr: 6,
  pb: 1,
  backgroundColor: 'transparent',
  outline: 'none',
  borderRadius: '0',
  borderBottom: '2px',
  borderColor: 'grey.500',
  _focus: {
    color: 'black',
    borderColor: 'grey.500',
  },
  _invalid: {
    borderColor: 'red.500',
  },
  _last: {
    mr: 0,
  },
};

const sizes: Record<string, SystemStyleObject> = {
  md: {
    w: '6.1rem',
    h: '5.6rem',
    apply: 'textStyles.display2',
  },

  sm: {
    w: '3.8rem',
    h: '3.9rem',
    apply: 'textStyles.h2',
    mr: 4,
  },
};

const defaultProps = {
  size: 'md',
  variant: 'flushed',
};

export default {
  sizes,
  baseStyle,
  defaultProps,
};
