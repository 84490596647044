import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { name } from 'src/modules/vendors/vendors-store';
import { organizationVendorTraitsApi } from './api';

type FetchPayload = {
  orgId: number;
  id: number;
};

type UpdatePayload = {
  orgId: number;
  id: string;
  mccCode: string;
};

type SingleVendorMccCodeState = {
  mccCode: string;
};

type VendorMccCodeState = { byId: Record<string, SingleVendorMccCodeState> };

export const fetchVendorMccCode = createUpdateSlice({
  storeName: name,
  actionName: 'FETCH_VENDOR_MCC',
  async api({ orgId, id }: FetchPayload) {
    const res = await organizationVendorTraitsApi.getMccCode(orgId, id);

    return {
      mccCode: res.mccCode,
    };
  },
  selectors: {
    getMccCode: (vendorId: string) => (state: { vendors: VendorMccCodeState }) =>
      state[name].byId[vendorId]?.mccCode || null,
  },
});

export const updateVendorMccCode = createUpdateSlice({
  storeName: name,
  actionName: 'UPDATE_VENDOR_MCC',
  async api({ orgId, id, mccCode }: UpdatePayload) {
    await organizationVendorTraitsApi.updateMccCode(orgId, id, mccCode);

    return {
      mccCode,
    };
  },
});
