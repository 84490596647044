import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { Option } from 'src/components/form/MIRadioGroup';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { RadioGroupField } from 'src/core/ds/form/fields/RadioGroupField';
import { Radio } from 'src/core/ds/radio';
import { VStack } from 'src/core/ds/stack';
import { profileStore } from 'src/modules/profile/profile-store';
import { ContentWrapper, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { vendorLocations } from '../locations';
import { UnilateralPaymentProps, UnilateralPaymentState } from './hoc/unilateral-payment-hooks';

type Props = {
  state: UnilateralPaymentState;
} & UnilateralPaymentProps;

export const VendorSwitchCompanyPage = (props: Props) => {
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    state: { isPaymentLoading, payment, organization, filesUrls },
    token,
  } = props;
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(orgId);

  const options = useMemo(
    () =>
      organizations.map<Option<string>>((o) => ({
        id: o.id.toString(),
        label: 'companies.acceptRequest.companyOption',
        labelValues: { companyName: o.companyName },
      })),
    [organizations]
  );

  const initUserContext = (orgId) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  const onCompanySwitch = async () => {
    if (orgId !== selectedOrganizationId) {
      await initUserContext(selectedOrganizationId);
    }

    history.push(generatePath(vendorLocations.unilateral.addMethod, { token }));
  };

  if (isPaymentLoading) {
    return <AreaLoader />;
  }

  return (
    <OutsideLayout>
      <Header
        payment={payment}
        organization={organization}
        filesUrls={filesUrls}
        showFullHeader
        note={payment?.note}
        subTitle="vendors.addDeliveryMethodByLink.subTitle"
      />
      <ContentWrapper title="vendors.addDeliveryMethodByLink.switchCompanyTitle">
        <Box overflow="auto" maxH="40rem" pt="5" pb="10">
          <RadioGroupField
            marginBottom={0}
            id="organization"
            name="organization"
            onChange={(id) => {
              setSelectedOrganizationId(Number(id));
            }}
          >
            <VStack spacing={3} w="full" alignItems="flex-start">
              {options.map((option) => (
                <Radio key={option.id} value={option.id} label={option.labelValues?.companyName} />
              ))}
            </VStack>
          </RadioGroupField>
        </Box>
        <Button onClick={onCompanySwitch} label="progress.continue" size={ButtonSizes.lg} isFullWidth />
      </ContentWrapper>
    </OutsideLayout>
  );
};
