import { featureFlags } from '@melio/shared-web';
import styled from 'styled-components';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import MIPaginationBar from 'src/components/common/MIPaginationBar';
import { MITable, MITableBody, MITableHead } from 'src/components/layout/TableElements';
import { useBillingAnalytics } from 'src/pages/settings/components/Billing/useBillingAnalytics';
import { settingsLocations } from 'src/pages/settings/locations';
import { devices } from 'src/theme/appDevices';
import { FeatureFlags, PaginationItemsType } from 'src/utils/consts';
import { BillType, Filters } from 'src/utils/types';
import InternalBillsTableBodyRow from '../InternalBillsTableBodyRow';
import InternalBillsTableHeaderRow from '../InternalBillsTableHeaderRow';

type Props = {
  internalBills: readonly BillType[];
  internalBillsTotal: number;
  onGeneratePdfInvoice: (billId: string, invoiceDate: Date) => void;
  filters: Filters;
};

export const BillingFeeHistoryTable = ({ internalBills, internalBillsTotal, filters, onGeneratePdfInvoice }: Props) => {
  const billingAnalytics = useBillingAnalytics();
  const [isBillingMethodCheckFeeEnabled] = featureFlags.useFeature<boolean>(FeatureFlags.BillingMethodCheckFee, false);
  const title = isBillingMethodCheckFeeEnabled ? 'settings.billing.paymentsTable.title' : 'settings.billing.cardTitle';
  const placeholder = isBillingMethodCheckFeeEnabled
    ? 'settings.billing.paymentsTable.emptyPlaceholder'
    : 'settings.billing.emptyTablePlaceholder';

  return (
    <MICard testId="fee-history-table">
      <BillingSettingsMICardForm>
        <BillingSettingsMICardTitle label={title} />
        {internalBillsTotal === 0 && (
          <EmptyTablePlaceholder>
            <MIFormattedText label={placeholder} />
          </EmptyTablePlaceholder>
        )}
        {internalBillsTotal > 0 && (
          <>
            <InternalBillsTable>
              <MITableHead>
                <InternalBillsTableHeaderRow />
              </MITableHead>
              <MITableBody>
                {internalBills.map((internalBill) => (
                  <InternalBillsTableBodyRow
                    key={internalBill.id}
                    billStatus={internalBill.status}
                    invoiceDate={internalBill.invoiceDate}
                    totalAmount={internalBill.totalAmount}
                    onPdfIconClick={() => {
                      billingAnalytics.track('settings-billing', 'receipt-download', {
                        receiptDate: internalBill.invoiceDate,
                        receiptAmount: internalBill.totalAmount,
                      });
                      onGeneratePdfInvoice(internalBill.id, internalBill.invoiceDate);
                    }}
                  />
                ))}
              </MITableBody>
            </InternalBillsTable>

            <MIPaginationBar
              total={internalBillsTotal}
              start={filters.start}
              limit={filters.limit}
              pathname={settingsLocations.billingItems}
              baseSearch="sorting=invoiceDate:desc"
              itemType={PaginationItemsType.LINK}
            />
          </>
        )}
      </BillingSettingsMICardForm>
    </MICard>
  );
};

const BillingSettingsMICardForm = styled(MICardForm)`
  @media ${devices.mobile} {
    padding-left: 0;
    padding-right: 0;
  }

  ${(props) => props.theme.pages?.Settings?.BillingSettingsMICardForm}
`;

const BillingSettingsMICardTitle = styled(MICardTitle)`
  @media ${devices.mobile} {
    margin: 0 2.5rem 0;
  }
`;

const EmptyTablePlaceholder = styled.p`
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  color: ${(props) => props.theme.text.color.subtitle};

  @media ${devices.mobile} {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
`;

const InternalBillsTable = styled(MITable)`
  margin-top: 3rem;
  margin-bottom: 2.5rem;

  ${(props) => props.theme.pages?.Settings?.InternalBillsTable}
`;
