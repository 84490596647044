import { AmexNotificationDetails } from '@melio/spend-management-api-axios-client';
import { deleteRequest, fetchRequest, patchRequest, postRequest } from 'src/services/api/api';
import { AccountsListParams, DeleteAccountParams, EnrollAccountParams, SetDefaultAccountParams } from './types';

export const accountsApi = {
  list({ orgId, clientRequestId }: AccountsListParams) {
    const url = `/orgs/${orgId}/spend/accounts`;

    return fetchRequest(url, { clientRequestId }).then((res: { accounts: AmexNotificationDetails[] }) => res.accounts);
  },
  enroll({ orgId, clientRequestId, buyerId }: EnrollAccountParams) {
    const url = `/orgs/${orgId}/spend/accounts/enroll`;

    return postRequest(url, { clientRequestId, buyerId }).then(
      (res: { accounts: AmexNotificationDetails[] }) => res.accounts
    );
  },
  delete({ orgId, accountId }: DeleteAccountParams) {
    const url = `/orgs/${orgId}/spend/accounts`;

    return deleteRequest(url, { accountId }).then((res: { id: string }) => res.id);
  },
  default({ orgId, accountId }: SetDefaultAccountParams) {
    const url = `/orgs/${orgId}/spend/accounts/${accountId}/default`;

    return patchRequest(url).then((res: { id: string }) => res.id);
  },
};
