import { MouseEvent } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { AdminApproval } from 'src/pages/team/team-management/AdminApproval';
import { InlineRoleSelect } from 'src/pages/team/team-management/InlineRoleSelect';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';
import { getAccountingFirmSyntheticRole } from 'src/pages/team/team-management/utils/getAccountingFirmSyntheticRole';
import { useForm } from 'src/ui/form';
import { FormContainer, FormRow, FormSectionText } from 'src/ui/form/FormElements';
import { Role } from 'src/utils/consts';
import { UserContextType, UserOrganization } from 'src/utils/types';

type Props = {
  isAccountingFirmSelected?: boolean;
  roleOptions: { label: string; id: Role }[];
  isLoading?: boolean;
  user: Pick<UserContextType, 'firstName' | 'lastName' | 'email'>;
  editedUserOrg: UserOrganization;
  hasApprovalWorkflows?: boolean;
  approvalWorkflowsEnabled?: boolean;
  isAdminApprovalDisabled?: boolean;
  dismiss: (event?: MouseEvent) => void;
  onSubmit: (value: UserOrganization) => Promise<any>;
};

export function EditUserOrganizationModalMessage({
  dismiss,
  onSubmit,
  user,
  editedUserOrg,
  isLoading,
  isAccountingFirmSelected,
  roleOptions,
  hasApprovalWorkflows,
  approvalWorkflowsEnabled,
  isAdminApprovalDisabled,
}: Props) {
  const [userOrgMV, { submit }] = useForm<UserOrganization>(editedUserOrg, {
    submit: onSubmit,
  });

  const showApprovalThreshold = userOrgMV.role.value
    ? [Role.CONTRIBUTOR, Role.ACCOUNTANT].includes(userOrgMV.role.value)
    : false;

  const chosenRole = isAccountingFirmSelected
    ? getAccountingFirmSyntheticRole(userOrgMV.role.value)
    : userOrgMV.role.value;

  const approvalWorkflowsLabel = hasApprovalWorkflows
    ? 'user.editDialog.approvalWorkflows.withWorkflows'
    : 'user.editDialog.approvalWorkflows.withoutWorkflows';

  const name = user.firstName || user.lastName;

  const showOldApproval = showApprovalThreshold && !approvalWorkflowsEnabled;
  const showNewApproval = showApprovalThreshold && approvalWorkflowsEnabled;

  return (
    <ModalMessage
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label="user.editDialog.title" />
        </ModalTitle>
      }
      contentComponent={
        <FormContainer onSubmit={submit}>
          <FormSectionText>
            <MIFormattedText label="user.editDialog.userDetailsSection" />
          </FormSectionText>
          <FormRow>
            <TextField label="user.firstName" value={user.firstName || ''} size="sm" isRequired isViewOnly />
            <TextField label="user.lastName" value={user.lastName || ''} size="sm" isRequired isViewOnly />
          </FormRow>
          <FormRow>
            <PrivateDataContainer>
              <TextField label="user.email" value={user.email} type="email" size="sm" mb={0} isRequired isViewOnly />
            </PrivateDataContainer>
          </FormRow>
          <FormRow />
          <FormSectionText>
            <MIFormattedText label="user.editDialog.userRoleSection" />
          </FormSectionText>
          <InlineRoleSelect model={userOrgMV.role} options={roleOptions} group="user-role" />
          <FormRow>
            <Box textStyle="body4">
              <MIFormattedText label={`user.permissions.${chosenRole}`} />
            </Box>
          </FormRow>
          {showOldApproval ? (
            <>
              <Divider />
              <AdminApproval
                approvalAmountThresholdModel={userOrgMV.approvalAmountThreshold as any}
                requireApprovalModel={userOrgMV.requireApproval}
                disabled={isAdminApprovalDisabled}
              />
            </>
          ) : null}
          {showNewApproval ? (
            <Box
              data-testid="EditUserOrganizationModal-approval-workflows"
              textStyle="body4"
              color="grey.700"
              bgColor="grey.200"
              p={4}
              borderRadius="lg"
            >
              <MIFormattedText label={approvalWorkflowsLabel} values={{ name }} />
            </Box>
          ) : null}
          <button type="submit" hidden />
        </FormContainer>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <Button size={ButtonSizes.lg} label="user.editDialog.submit" onClick={submit} isLoading={isLoading} />
        </ModalButtonsWrapper>
      }
    />
  );
}

const Divider = styled(FormRow)`
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
`;
