import { PaymentType } from 'src/utils/types';

type DatesByScheduledDate = {
  scheduledDate: Date;
  totalDays: number;
  deliveryDate: Date;
  maxDeliveryDate: Date;
};

export type FetchPaymentDetailsWithToken = {
  /** there are some differences between the server and our's type, not sure what they are */
  payment: Partial<PaymentType>;
  deliveryDates: {
    achDates: DatesByScheduledDate;
    checkDates: DatesByScheduledDate;
  };
  filesUrls: Record<string, any>;
  fee: Record<string, any>;
};

export enum UpsellType {
  PUSH_TO_DEBIT = 'push-to-debit',
  PUSH_TO_FAST_ACH = 'push-to-fast-ach',
  PUSH_TO_RTP = 'push-to-rtp',
}

export enum UpgradePaymentFromPayeeType {
  FAST_ACH = 'fast-ach',
  RTP = 'rtp',
}

export type FilesUrlsType = {
  logoUrl: string | null;
  fileStorageUrl: string | null;
  filePreviewUrls: string[];
};

export type UpsellItemType = {
  type: UpsellType;
  isEligible: boolean;
  isCheckToP2DOfferExpired?: boolean;
  deliveryDate?: string;
};

export type FetchPaymentUpsellDetailsResponse = {
  filesUrls: FilesUrlsType;
  upsellItems: UpsellItemType[];
  payment: Partial<PaymentType>;
};
