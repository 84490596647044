import mapKeys from 'lodash/mapKeys';
import { useEffect, useState } from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnEventMetadata,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkStableEvent,
  usePlaidLink,
} from 'react-plaid-link';
import config from 'src/config';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { analytics } from 'src/services/analytics';

const PLAID_ENV = config.services.plaid.env;

export enum PlaidEventName {
  TRANSITION_VIEW = 'TRANSITION_VIEW',
}

export type PlaidConfigProps = {
  orgId: number;
  supportMD?: boolean;
  onSuccess(token: string, metadata: PlaidLinkOnSuccessMetadata): void;
  onExit(error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata): void;
  onEvent?: (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => void;
};

export const usePlaid = ({ orgId, supportMD, onSuccess, onEvent, onExit }: PlaidConfigProps) => {
  const [linkToken, setLinkToken] = useState<string>('');
  // to initialize Link - we need to generate a link_token.
  useEffect(() => {
    let isMount = true;
    const createLinkToken = async () => {
      const { linkToken } = await financialAccountsApi.createPlaidLinkToken(orgId, supportMD);
      isMount && setLinkToken(linkToken);
    };
    createLinkToken();

    return () => {
      isMount = false;
    };
  }, [orgId, supportMD]);

  const addPlaidPrefixToEventMetadata = (metadata: PlaidLinkOnEventMetadata) =>
    mapKeys(metadata, (__value, key) => `plaid_metadata_${key}`);

  const handleEvent = (eventName: PlaidLinkStableEvent | string, metadata: PlaidLinkOnEventMetadata) => {
    if (eventName !== PlaidEventName.TRANSITION_VIEW) {
      analytics.track(
        'add-funding-source',
        `plaid-${config.services.plaid.env}-link-${eventName.toLowerCase()}`,
        addPlaidPrefixToEventMetadata(metadata)
      );
    }

    onEvent?.(eventName, metadata);
  };

  const linkConfig: PlaidLinkOptions = {
    token: linkToken,
    env: PLAID_ENV,
    onSuccess,
    onEvent: handleEvent,
    onExit,
  };

  const { open, ready, error, exit } = usePlaidLink(linkConfig);

  return { ready, open, exit, error };
};
