import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from './createApiCallSlice';
import { updateItemsInState } from './listsActions';
import { RestfulSliceParams, RestfulState } from './types';

export const batchHashFunc = (ids: string[]): string => ids.join(',');

export function batchUpdateSliceReducer(actionName, hashFunc) {
  return {
    [ON_REQUEST](state, action) {
      const ids = action.payload.items.map((i) => i.id);
      const key = hashFunc(ids);
      state[actionName][key] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      const ids = action.meta.identifier.items.map((i) => i.id);
      const key = hashFunc(ids);
      delete state[actionName][key];
      const { items } = action.payload;
      updateItemsInState(state, items);
    },
    [ON_FAILURE](state, action) {
      const ids = action.meta.identifier.items.map((i) => i.id);
      const key = hashFunc(ids);
      state[actionName][key] = {
        loading: false,
        error: action.error,
      };
    },
  };
}

export type BatchUpdateActionPayload<T> = {
  orgId: number;
  items: Partial<T>[];
  shouldAttachInvoice: boolean;
};

export type BatchUpdateActionResponse<T> = {
  ids: string[];
  items: T[];
};

type BatchUpdateRestfulSliceParams<T> = RestfulSliceParams<T> & {
  api: (params: BatchUpdateActionPayload<T>) => Promise<BatchUpdateActionResponse<T>>;
  hashFunc?: (ids: string[]) => string;
};

export function createBatchUpdateSlice<T>({
  storeName,
  api,
  sagas,
  actionName = 'batchDelete',
  hashFunc = batchHashFunc,
}: BatchUpdateRestfulSliceParams<T>) {
  return createApiCallSlice<BatchUpdateActionPayload<T>, Partial<RestfulState<T>>, BatchUpdateActionResponse<T>>({
    name: generateSliceName(storeName, actionName),
    initialState: {
      [actionName]: {},
    },
    api,
    reducers: batchUpdateSliceReducer(actionName, hashFunc),
    selectors: {
      status: (ids) => (state) => state[storeName]?.[actionName]?.[hashFunc(ids)],
    },
    sagas,
  });
}
