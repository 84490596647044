import { ChangeEvent } from 'react';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { handleInputChange } from 'src/utils/input';

type Props = {
  onPrev: () => void;
  onNext: () => void;
  onChange: (memo: string) => void;
  memo: string;
};

const GuestMemoPage = ({ memo, onNext, onPrev, onChange }: Props) => {
  const { StepLayout } = useSiteContext().components;

  return (
    <StepLayout title="bills.pay.memo.title" relativeStep={5 / 6} onPrev={onPrev} onNext={onNext}>
      <PrivateDataContainer>
        <TextField
          id="memo"
          label="bills.pay.memo.inputTitle"
          placeholder="bills.pay.memo.placeholder"
          helperText="bills.pay.memo.hint"
          value={memo}
          autoFocus
          type="text"
          onSubmit={onNext}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange, valueOnly: true })}
        />
      </PrivateDataContainer>
    </StepLayout>
  );
};

export default GuestMemoPage;
