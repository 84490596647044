import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { ReactComponent as BankIcon } from 'src/images/icons/bank-with-arrow-icon.svg';
import { ReactComponent as CalendarImage } from 'src/images/icons/calendar-with-arrow-icon.svg';
import { ReactComponent as PaperCheckIcon } from 'src/images/icons/paper-check-icon.svg';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryType, PaymentStatus } from 'src/utils/consts';
import { getFormattedAddress } from 'src/utils/formatting';
import { CheckType, PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
};

export const VirtualCardPaymentActivity = ({ payment }: Props) => {
  const isACH = payment?.deliveryMethod?.deliveryType === DeliveryType.ACH;
  const isPaymentCompleted = payment?.status === PaymentStatus.COMPLETED;
  const title = isPaymentCompleted
    ? 'vendors.virtualCard.paymentActivity.youReceived'
    : 'vendors.virtualCard.paymentActivity.youWillReceive';
  const icon = isACH ? <BankIcon width="2.4rem" height="2.3rem" /> : <PaperCheckIcon width="2.4rem" height="2.3rem" />;
  const label = isACH
    ? 'vendors.virtualCard.paymentActivity.achLabel'
    : 'vendors.virtualCard.paymentActivity.checkLabel';
  const description = isACH
    ? 'vendors.virtualCard.paymentActivity.achDescription'
    : 'vendors.virtualCard.paymentActivity.checkDescription';
  const descriptionValues = isACH
    ? { digits: getAccountNumber4digits(payment?.deliveryMethod?.bankAccount) }
    : {
        displayName:
          payment?.deliveryMethod?.paperCheck && getFormattedAddress(payment?.deliveryMethod?.paperCheck as CheckType),
      };

  return (
    <>
      <Box textStyle="body4Uppercase" color="grey.700" mt={5} mb={4}>
        <MIFormattedText label={title} />
      </Box>
      <Flex align="center" borderBottom="1px" borderColor="grey.400" pb={5}>
        {icon}
        <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
          <Box textStyle="body4Semi" color="grey.600">
            <MIFormattedText label={label} />
          </Box>
          <Box textStyle="body2" isTruncated>
            <MIFormattedText label={description} values={descriptionValues} />
          </Box>
        </Box>
      </Flex>
      <Flex align="center" pt={5}>
        <CalendarImage width="2.4rem" height="2.3rem" />
        <Box w={{ base: '28rem', md: '40rem' }} ml={5}>
          <Box textStyle="body4Semi" color="grey.600">
            <MIFormattedText
              label={
                isPaymentCompleted
                  ? 'vendors.virtualCard.paymentActivity.deliveryOnLabel'
                  : 'vendors.virtualCard.paymentActivity.deliveryEtaLabel'
              }
            />
          </Box>
          <Box textStyle="body2" isTruncated>
            <MIFormattedDate date={payment?.deliveryEta} />
          </Box>
          {!isPaymentCompleted && isACH && (
            <Box textStyle="body4" color="grey.700">
              <MIFormattedText label="vendors.virtualCard.paymentActivity.deliveryAdditionalDescription" />
            </Box>
          )}
        </Box>
      </Flex>
    </>
  );
};
