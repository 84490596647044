import React from 'react';
import { MICard, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';
import { Switch } from 'src/core/ds/switch';

type Props = {
  shouldGenerateInvoiceFile: boolean;
  onChange(): void;
};

export const InvoiceGenerationCard = ({ shouldGenerateInvoiceFile, onChange }: Props) => (
  <MICard testId="invoice-generation-card">
    <VStack p={8} spacing={4}>
      <Flex alignItems="center" justify="space-between" width="100%">
        <MICardTitle label="settings.accountingSoftware.invoiceGeneration.title" />
        <Switch
          label={`settings.accountingSoftware.invoiceGeneration.${
            shouldGenerateInvoiceFile ? 'invoiceGenerationOn' : 'invoiceGenerationOff'
          }`}
          isChecked={shouldGenerateInvoiceFile}
          onChange={onChange}
          testId="invoice-generation-switch"
          analyticsProperties={{ isCurrentlyTurnedOn: shouldGenerateInvoiceFile }}
        />
      </Flex>
      <Box textStyle="body3" color="black">
        <MIFormattedText label="settings.accountingSoftware.invoiceGeneration.description" />
      </Box>
    </VStack>
  </MICard>
);
