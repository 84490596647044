import * as React from 'react';
import { ComponentProps } from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { MenuButton } from 'src/core/ds/menu';
import { ReactComponent as ChevronDownIcon } from 'src/images/regular-batch-payments/menu-arrow-down.svg';
import { ReactComponent as ChevronUpIcon } from 'src/images/regular-batch-payments/menu-arrow-up.svg';
import { ActionPopover } from './ActionPopover';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  popover?: ComponentProps<typeof ActionPopover>;
  testId?: string;
};

const renderArrow = (isTooltip: boolean, isOpen: boolean) => {
  if (isTooltip) {
    return null;
  }

  return isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;
};

export const ArrowMenuButtonCell = ({ isOpen, children, popover, testId }: Props) => (
  <Flex w="full">
    <MenuButton as={Box} h="full" w="full" data-testid={testId}>
      <Flex align="center" justify="space-between" h="full">
        {children}
        {renderArrow(!!popover, isOpen)}
      </Flex>
    </MenuButton>
    {popover ? <ActionPopover {...popover} /> : null}
  </Flex>
);
