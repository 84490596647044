import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import { DatePickerCircle } from 'src/components/common/datepicker/DatePickerCircle';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { DatePicker } from 'src/components/form/DatePicker';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import Tooltip from 'src/core/ds/tooltip';
import { PaymentDatePickerInfo } from 'src/pages/bill/pay/components/PaymentDatePickerInfo';
import { SelectProps } from 'src/pages/bill/pay/FastPaymentOfferPage/FastPaymentOfferPage';
import { dismissNotification, pushNotification } from 'src/services/notifications/notificationService';
import { convertDateToMonthYearFormat, isCardExpired } from 'src/utils/card';
import { DeliveryType, NotificationVariant, TextInputSize } from 'src/utils/consts';
import { convertDateToCalendarDate, isBusinessDay, isHoliday } from 'src/utils/dates';
import { AccountType, DeliveryOptionType, FieldDateType } from 'src/utils/types';
import { getBillScheduledAndDueDateLabels } from './utils';

type Props = {
  fundingSource?: AccountType | null;
  deliveryDate: Date;
  deliveryOptions?: DeliveryOptionType[];
  maxDeliveryDate: Date | null;
  deliveryMethodType?: DeliveryType;
  scheduledDate: Date;
  totalAmount: string;
  dueDate?: Date | null;
  minDate?: Date;
  maxDate?: Date;
  isEligibleForFastAchAdoption?: boolean;
  onChange: (value: FieldDateType) => Promise<void> | void;
  fundingSourceType?: string;
  onSelectDeliveryOption?: (props: SelectProps) => void;
  renderDayContentsCustom?: ReactDatePickerProps['renderDayContents'];
  isBillOverdueNotificationShown?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
  isFinancingPayment?: boolean;
};

export const renderDayContents = (day, date) => {
  const isHolidayDay = isHoliday(convertDateToCalendarDate(date));
  const month = new Date(date).getMonth();

  return (
    <Tooltip
      data-testid="federal-bank-holiday-tooltip"
      isDisabled={!isHolidayDay}
      placement="top"
      label={<MIFormattedText label="bills.pay.date.federalBankHoliday" />}
    >
      <Box data-testid={`${day}-${month}-day-content`}>{day}</Box>
    </Tooltip>
  );
};

export const PaymentDatePicker = ({
  fundingSource,
  deliveryDate,
  deliveryOptions,
  maxDeliveryDate,
  deliveryMethodType,
  scheduledDate,
  totalAmount,
  minDate,
  maxDate,
  onChange,
  dueDate,
  onSelectDeliveryOption,
  fundingSourceType,
  renderDayContentsCustom,
  isBillOverdueNotificationShown,
  isEligibleForFastAchAdoption,
  isFinancingPayment = false,
  isFastAchBetterExposeEtaEnabled,
}: Props) => {
  const expirationDate = fundingSource?.cardAccount?.expiration;
  const card4digits = fundingSource?.cardAccount?.card4digits;
  const cardNetwork = fundingSource?.cardAccount?.network;
  const [showExpirationNotification, setShowExpirationNotification] = useState(false);
  const toggleExpirationLabel = useCallback((dateToCompare: Date) => {
    const isExpirationDateHappened = Boolean(
      expirationDate && isCardExpired({ expiration: expirationDate }, dateToCompare)
    );
    setShowExpirationNotification(isExpirationDateHappened);
  }, []);

  const getFilteredDate = (date) =>
    expirationDate && isCardExpired({ expiration: expirationDate }, date) ? false : isBusinessDay(date);

  useEffect(() => {
    toggleExpirationLabel(scheduledDate);
  }, [toggleExpirationLabel, scheduledDate]);

  useEffect(() => {
    if (showExpirationNotification) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'bills.pay.date.cardExpiredLabel',
        textValues: {
          cardNetwork,
          card4digits,
          expirationDate: convertDateToMonthYearFormat(expirationDate),
        },
        autoClose: false,
      });
    }

    return () => {
      dismissNotification();
    };
  }, [showExpirationNotification]);

  const dateLabels = getBillScheduledAndDueDateLabels(isFinancingPayment);

  return (
    <PaymentDatePickerContainer>
      <DatePicker
        id="scheduledDate"
        date={scheduledDate}
        dueDate={dueDate}
        deliveryDate={deliveryDate}
        required
        size={TextInputSize.INLINE}
        inline
        withBottomElement
        min={minDate}
        max={maxDate}
        filterDate={getFilteredDate}
        renderDayContents={renderDayContentsCustom || renderDayContents}
        onChange={({ id, date }) =>
          onChange({
            id,
            value: date,
          })
        }
        onMonthChange={toggleExpirationLabel}
        overrideMobile
        isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
      />
      {!isFastAchBetterExposeEtaEnabled ? (
        <DateDescriptionContainer>
          {scheduledDate ? (
            <DateDescription label={dateLabels.scheduledDateLabel} date={scheduledDate} isScheduledDate />
          ) : null}
          {dueDate ? <DateDescription label={dateLabels.dueDateLabel} date={dueDate} isScheduledDate={false} /> : null}
        </DateDescriptionContainer>
      ) : null}
      <PaymentDatePickerInfo
        fundingSource={fundingSource}
        scheduledDate={scheduledDate}
        totalAmount={totalAmount}
        deliveryDate={deliveryDate}
        maxDeliveryDate={maxDeliveryDate}
        deliveryMethodType={deliveryMethodType}
        deliveryOptions={deliveryOptions || []}
        fundingSourceType={fundingSourceType}
        onSelectDeliveryOption={onSelectDeliveryOption}
        isEligibleForFastAchAdoption={isEligibleForFastAchAdoption}
        isBillOverdueNotificationShown={isBillOverdueNotificationShown}
        isFastAchBetterExposeEtaEnabled={isFastAchBetterExposeEtaEnabled}
      />
    </PaymentDatePickerContainer>
  );
};

type ContainerProps = {
  children?: React.ReactNode;
};

const PaymentDatePickerContainer = ({ children }: ContainerProps) => (
  <Box
    sx={{
      w: {
        base: 'full',
        sm: '35.9rem',
      },
      bgColor: 'white',
      mt: 0,
      mb: {
        base: 0,
        sm: 4,
      },
      mx: {
        base: 0,
        sm: 'auto',
      },
      boxShadow: 400,
      borderRadius: 'lg',
      border: '1px',
      borderColor: 'grey.300',
      '.react-datepicker': {
        border: 'none',
      },
    }}
  >
    {children}
  </Box>
);

const DateDescriptionContainer = ({ children }: ContainerProps) => (
  <Flex justify="space-between" bgColor="white" px={5} pt={0} pb={4}>
    {children}
  </Flex>
);

type DateDescriptionProps = {
  label: string;
  date: Date;
  isScheduledDate: boolean;
};

const DateDescription = ({ date, label, isScheduledDate }: DateDescriptionProps) => (
  <Flex align="center">
    <DatePickerCircle isScheduledDate={isScheduledDate} circleDiameter="1.1rem" />
    <Flex textStyle="body4" color="grey.700" whiteSpace="nowrap">
      <MIFormattedText
        label={label}
        values={{
          date: (
            <Box as="span" ml={1} textStyle="body4Semi">
              <MIFormattedDate date={date} />
            </Box>
          ),
        }}
      />
    </Flex>
  </Flex>
);
