import { useSelector } from 'react-redux';
import { getCompanyInfo, getOwnedVendorId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { PaymentRequestType } from 'src/utils/types';

export const useGuestPaymentAnalytics = (paymentRequest: PaymentRequestType) => {
  const profile = useSelector(getProfile);
  const companyInfo = useSelector(getCompanyInfo);
  const ownedVendorId = useSelector(getOwnedVendorId);

  const analyticsProps = {
    ownedVendorId,
    companyType: companyInfo.companyType,
    organizationId: profile.orgId,
    paymentRequestId: paymentRequest.id,
    email: profile.email,
    registrationFlow: profile.registrationFlow,
  };
  const trackEvent = (page: string, event: string, extraProperties?: Record<string, string>) => {
    analytics.track(page, event, { ...analyticsProps, ...extraProperties });
  };

  return { trackEvent };
};
