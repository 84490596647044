import { fetchRequest, putRequest } from 'src/services/api/api';

export const organizationVendorTraitsApi = {
  getMccCode(orgId: number, vendorId: number): Promise<{ mccCode: string }> {
    const url = `/orgs/${orgId}/vendor-traits/mcc-code?vendorId=${vendorId}`;

    return fetchRequest(url);
  },

  updateMccCode(orgId: number, vendorId, mccCode) {
    const url = `/orgs/${orgId}/vendor-traits/mcc-code`;

    return putRequest(url, { vendorId, mccCode });
  },
};
