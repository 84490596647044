import { useMemo } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  InProgressDateFilters,
  InvoicesDateFilters,
  PaidDateFilters,
  RequestsDateFilters,
} from 'src/pages/get-pro/consts';
import { GetProTabs } from 'src/utils/consts';
import { OptionType } from '../components/types';

const DATE_FILTER_OPTIONS_BY_TYPE = {
  [GetProTabs.INVOICES]: Object.keys(InvoicesDateFilters).map((key) => ({
    option: <MIFormattedText label={`getPro.filter.invoices.${InvoicesDateFilters[key]}`} />,
    value: InvoicesDateFilters[key],
  })),
  [GetProTabs.REQUESTS]: Object.keys(RequestsDateFilters).map((key) => ({
    option: <MIFormattedText label={`getPro.filter.requests.${RequestsDateFilters[key]}`} />,
    value: RequestsDateFilters[key],
  })),
  [GetProTabs.IN_PROGRESS]: Object.keys(InProgressDateFilters).map((key) => ({
    option: <MIFormattedText label={`getPro.filter.inProgress.${InProgressDateFilters[key]}`} />,
    value: InProgressDateFilters[key],
  })),
  [GetProTabs.PAID]: Object.keys(PaidDateFilters).map((key) => ({
    option: <MIFormattedText label={`getPro.filter.paid.${PaidDateFilters[key]}`} />,
    value: PaidDateFilters[key],
  })),
};

export const useFilterOptionsByType = (type: GetProTabs): { dateFilterOptions: Array<OptionType> } => {
  const dateFilterOptions = useMemo<OptionType[]>(() => DATE_FILTER_OPTIONS_BY_TYPE[type], [type]);

  return {
    dateFilterOptions,
  };
};
