export const isEnterPressed = (event: React.KeyboardEvent) => {
  const key = event.key || event.keyCode;

  return key === 'Enter' || key === 13;
};

export const isEscPressed = (event: React.KeyboardEvent) => {
  const key = event.key || event.keyCode;

  return key === 'Escape' || key === 27;
};

export const isArrowUpPressed = (event: React.KeyboardEvent) => {
  const key = event.key || event.keyCode;

  return key === 'ArrowUp' || key === 38;
};

export const isArrowDownPressed = (event: React.KeyboardEvent) => {
  const key = event.key || event.keyCode;

  return key === 'ArrowDown' || key === 40;
};
