import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { ApprovalWorkflowType } from 'src/utils/types';

const getBaseUrl = (orgId: number) => `/orgs/${orgId}/approval-workflows`;

export const list = (params: { orgId: number }): Promise<{ items: ApprovalWorkflowType[] }> => {
  const { orgId } = params;
  const url = getBaseUrl(orgId);

  return fetchRequest(url).then((res) => ({ items: res.approvalWorkflows }));
};

export const update = (params) => {
  const { orgId, ...payload } = params;
  const url = `${getBaseUrl(orgId)}/${payload.id}`;

  return putRequest(url, payload).then((res) => res.approvalWorkflow);
};

export const create = (params) => {
  const { orgId, ...payload } = params;
  const url = getBaseUrl(orgId);

  return postRequest(url, payload).then((res) => res.approvalWorkflow);
};

export const deleteWorkflow = (params) => {
  const { orgId, id } = params;
  const url = `${getBaseUrl(orgId)}/${id}`;

  return deleteRequest(url);
};

export const approvedPaymentsAfterUpdate = (params) => {
  const { orgId, id, ...payload } = params;
  const url = `${getBaseUrl(orgId)}/${id}/approved-payments-on-update`;

  return postRequest(url, payload).then((res) => res.approvalWorkflow);
};

export const getPayments = (params) => {
  const { orgId, id } = params;
  const url = `${getBaseUrl(orgId)}/${id}/payments`;

  return fetchRequest(url).then((res) => ({ items: res.payments }));
};

export const getMinThresholds = (params) => {
  const { orgId } = params;
  const url = `${getBaseUrl(orgId)}/min-thresholds`;

  return fetchRequest(url).then((res) => res.minThresholds);
};
