import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { analytics } from 'src/services/analytics';

type Props = {
  showVerifyDialog: () => void;
  label: string;
};

export const VerifyAccountLink = ({ showVerifyDialog, label }: Props) => {
  const onClick = () => {
    analytics.track('bill-payment', 'verify-manual-account-click');
    showVerifyDialog();
  };

  return (
    <Box as="a" cursor="pointer" textStyle="link2" _hover={{ textDecoration: 'none' }} ms={1} onClick={onClick}>
      <MIFormattedText label={label} />
    </Box>
  );
};
