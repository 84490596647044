/* eslint-disable no-template-curly-in-string */
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

const pageEvent = 'define-relationship';

const MAPPING: EventMappingsTree = {
  '/orgs/*/define-relationship/invite-owner': {
    'page.view': [pageEvent, 'invite-owner'],
    'go.back': [pageEvent, 'go-back'],
    'go.exit': [pageEvent, 'go-exit'],
    'select-category': [pageEvent, 'invite-owner', { value: 'value' }],
  },
  '/orgs/*/define-relationship/manage-payment-methods': {
    'page.view': [pageEvent, 'manage-payment-methods'],
    'go.back': [pageEvent, 'go-back'],
    'go.exit': [pageEvent, 'go-exit'],
    'select-category': [pageEvent, 'who-will-manage-funding-source', { value: 'value' }],
    ...mapApiSlice('[ORGANIZATION] UPDATE_ORGANIZATION_PREFERENCE', pageEvent, 'who-will-manage-funding-source'),
  },
  '/orgs/*/define-relationship/add-payment-method': {
    'page.view': [pageEvent, 'add-payment-methods'],
  },
  '/orgs/*/define-relationship/require-approval': {
    'page.view': [pageEvent, 'require-approval'],
    'go.back': [pageEvent, 'go-back'],
    'go.exit': [pageEvent, 'go-exit'],
    'select-category': [pageEvent, 'is-approval-required', { value: 'value' }],
    ...mapApiSlice('[USERS] USER_MANAGEMENT_UPDATE', pageEvent, 'update-user'),
    ...mapApiSlice('[INVITATIONS] CREATE', pageEvent, 'invite-owner'),
  },
  '/orgs/*/define-relationship/success': {
    'page.view': [pageEvent, 'success'],
    'inviteNewCompany.accountantFlow.success.getStarted': [pageEvent, 'continue'],
  },
};

export default MAPPING;
