import { FormattedNumber } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FONT_WEIGHT } from 'src/core/theme/foundations/textStyles';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';

type FeeProps = {
  fee: PaymentFeeInfo;
  isCreditCard: boolean;
};

const moneyBoxStyles = {
  marginRight: 5,
  '* span': {
    fontWeight: FONT_WEIGHT.NORMAL,
  },
};

export const InternationalFee = ({ fee, isCreditCard }: FeeProps) => {
  const { feeStructure, finalAmount } = fee;
  const totalAmount = fee.finalAmount + fee.internationalFee.value;
  const isCardFeeExist = isCreditCard && feeStructure?.type === 'percent' && finalAmount > 0;

  return (
    <Box py={5} pl={5} w="100%" boxSizing="border-box">
      <Box textStyle="body4Semi" color="grey.700" textTransform="uppercase">
        <MIFormattedText label="bills.pay.confirm.fee" />
      </Box>
      {isCardFeeExist && (
        <Flex justify="space-between" mt={4} alignItems="center">
          <Box textStyle="body2">
            <MIFormattedText
              label="bills.pay.confirm.creditCardFee"
              values={{
                fee: <FormattedNumber value={feeStructure?.value} format="percent" />,
              }}
            />
          </Box>
          <Box __css={moneyBoxStyles}>
            <MIMoney amount={fee.finalAmount} size="small" />
          </Box>
        </Flex>
      )}

      <Flex justify="space-between" mt={4} borderBottom="1px" borderColor="grey.400" pb={4} alignItems="center">
        <Box textStyle="body2">
          <MIFormattedText label="bills.pay.confirm.internationalFee" />
        </Box>
        <Box __css={moneyBoxStyles}>
          <MIMoney amount={fee.internationalFee.value} size="small" />
        </Box>
      </Flex>
      <Flex justify="space-between" mt={4}>
        <Box>
          <Box textStyle="body1">
            <MIFormattedText label="bills.pay.confirm.totalFee" />
          </Box>
          <Box textStyle="body4" color="grey.700">
            <MIFormattedText label="bills.pay.confirm.chargedSeparatelyToYourAccount" />
          </Box>
        </Box>
        <Box __css={moneyBoxStyles}>
          <MIMoney amount={totalAmount} />
        </Box>
      </Flex>
    </Box>
  );
};
