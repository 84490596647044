import styled from 'styled-components';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { devices } from 'src/theme/appDevices';

type EditElementProps = {
  onEdit?: (() => void) | null;
  testId?: string;
  isDisabled?: boolean;
};

export const EditElement = ({ onEdit, testId, isDisabled = false }: EditElementProps) => {
  if (!onEdit) {
    return null;
  }

  return (
    <StyledMIInlineLink
      label="bills.form.paymentActivity.editLink"
      onClick={onEdit}
      testId={testId}
      disabled={isDisabled}
    />
  );
};

const StyledMIInlineLink = styled(MIInlineLink)`
  margin: 0 2rem 0 1rem;
  ${(props) => props.theme?.components?.PaymentActivity?.StyledMIInlineLink}
`;

export const PaymentIcon = styled.i<{ isBillPaid?: boolean }>`
  font-size: 2.4rem;
  box-sizing: border-box;
  min-width: 4.5rem;
  color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  > img {
    height: 2.4rem;
    width: 2.4rem;
  }

  > i {
    font-size: 2.4rem;
    color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};
  }

  > svg {
    height: 2.4rem;
    width: 2.4rem;
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentIcon}
`;

export const PaymentActivityContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
  border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.grey};

  ${(props) => props.theme?.components?.PaymentActivity?.PaymentActivityContainer}
`;

export const PaymentActivityMainContainer = styled.div`
  display: flex;
  padding-right: 2rem;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentActivityMainContainer}
`;

export const PaymentInfoContainer = styled.div`
  display: flex;
  align-items: center;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoContainer}
`;

export const PaymentTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentTextContainer}
`;

export const PaymentMethodContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${devices.mobile} {
    flex-direction: column;
  }
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodContainer}
`;

export const PaymentMethodText = styled.div`
  display: flex;
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  letter-spacing: 0.02rem;
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentMethodText}
`;

export const PaymentInfoText = styled.div<{
  isBillPaid?: boolean;
}>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${(props) => (props.isBillPaid ? props.theme.text.color.label : props.theme.text.color.main)};

  @media ${devices.mobile} {
    max-width: 20rem;
  }
  @media ${devices.nonMobile} {
    max-width: 32rem;
  }
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoText}
`;

export const PaymentInfoHint = styled.div`
  ${(props) => props.theme.text.fontType.hint};
  color: ${(props) => props.theme.text.color.subtitle};
  margin-right: 2rem;
  ${(props) => props.theme?.components?.PaymentActivity?.PaymentInfoHint}
`;

export const BlockTitleContainer = styled.h5`
  color: ${(props) => props.theme.text.color.subtitle};
  margin-bottom: 0.2rem;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.hint};
  ${(props) => props.theme?.components?.PaymentActivity?.BlockTitleContainer}
`;
