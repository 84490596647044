import Box from 'src/core/ds/box';

type Props = {
  envName: string;
};

export const EnvRibbon = ({ envName }: Props) =>
  envName !== 'production' ? (
    <Box sx={RibbonWrapperStyle}>
      <Box sx={RibbonStyle}>
        <Box sx={EnvNameStyle}>{envName}</Box>
      </Box>
    </Box>
  ) : null;

const RibbonWrapperStyle = {
  w: '15rem',
  h: '15rem',
  pos: 'absolute',
  overflow: 'hidden',
  top: 0,
  zIndex: '9999',
  pointerEvents: 'none',
  right: 0,
};

const RibbonStyle = {
  pos: 'absolute',
  py: 0.5,
  textStyle: 'toastAction',
  zIndex: 9999,
  pointerEvents: 'auto',
  transform: 'rotate(45deg)',
  top: 6,
  right: -14,
  backgroundImage: 'none',
  boxShadow: 'none',
  backgroundColor: 'green.500',
};

const EnvNameStyle = {
  color: 'white',
  textAlign: 'center',
  w: '200px',
  display: 'inline-block',
  py: 0.5,
  borderWidth: '1px 0px',
  borderColor: 'white',
};
