import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { vendorsApi } from 'src/modules/vendors/api';
import { emailVendorOnPaymentSchedule } from 'src/pages/bill/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { PaymentApprovalStatus } from 'src/utils/consts';
import { isEnterPressed } from 'src/utils/events';
import { isNonNullable } from 'src/utils/isNonNullable';
import { BillType, PaymentType } from 'src/utils/types';

type Props = {
  dismiss: () => void;
  onSuccess: () => void;
  payment: PaymentType;
  bill: BillType;
  eventPage: string;
};

const CollectVendorEmailModalMessage = ({ dismiss, onSuccess, payment, bill, eventPage }: Props) => {
  const orgId = useSelector(getOrgId);
  const [contactEmail, changeContactEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState({
    contactEmail: null,
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const vendorName = bill.vendor?.companyName;
  const pendingApproval = payment.approvalDecisionStatus === PaymentApprovalStatus.PENDING;
  const { vendorId } = bill;
  const analyticsProps = {
    vendorId,
    screenName: 'success',
    partialBillId: getBillPaymentIndex(bill),
  };

  const handleCollectVendorEmailModalCancel = () => {
    analytics.track(eventPage, 'vendor-email-modal-closed');
    dismiss();
  };

  const handleCollectVendorEmailModalSubmit = () => {
    analytics.track(eventPage, 'vendor-email-modal-save', analyticsProps);
    let validationErrors;

    if (isEmpty(contactEmail)) {
      validationErrors = {
        contactEmail: 'inputErrors.vendor.contactEmail.any.empty',
      };
      setValidationErrors(validationErrors);
      analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
    } else {
      validationErrors = getValidationErrors('vendor', { contactEmail }, ['contactEmail']);
      setIsProcessing(true);
      setValidationErrors(validationErrors);

      if (isValidationOk(validationErrors) && isNonNullable(vendorId)) {
        vendorsApi
          .editVendorById({
            orgId,
            id: vendorId,
            params: { contactEmail },
          })
          .then(() => {
            analytics.track(eventPage, 'vendor-email-modal-save-success', {
              vendorEmail: contactEmail,
              ...analyticsProps,
            });

            emailVendorOnPaymentSchedule(payment, orgId, contactEmail);
            setIsProcessing(false);
            dismiss();
            onSuccess();
          });
      } else {
        setIsProcessing(false);
        analytics.track(eventPage, 'vendor-email-modal-save-validation-error', validationErrors);
      }
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    changeContactEmail(value);
  };

  const onKeyPressed = (event: React.KeyboardEvent<any>) => {
    if (isEnterPressed(event)) {
      handleCollectVendorEmailModalSubmit();
    }
  };

  return (
    <ModalMessage
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText
              label="bills.pay.collectVendorEmailModal.title"
              values={{
                vendorName: <StyledVendorName>{vendorName}</StyledVendorName>,
              }}
            />
          </div>
          <div className="text">
            <MIFormattedText label="bills.pay.collectVendorEmailModal.subTitle" values={{ vendorName }} />
          </div>
          <PendingNotification className="text">
            {pendingApproval && <MIFormattedText label="bills.pay.collectVendorEmailModal.pending" />}
          </PendingNotification>
        </>
      }
      contentComponent={
        <FormContainer onKeyDown={onKeyPressed}>
          <PrivateDataContainer>
            <TextField
              id="contactEmail"
              label="bills.pay.collectVendorEmailModal.emailLabel"
              placeholder="bills.pay.collectVendorEmailModal.emailPlaceholder"
              value={contactEmail}
              autoFocus
              isRequired
              type="email"
              onChange={onChange}
              errorMessage={validationErrors.contactEmail}
            />
          </PrivateDataContainer>
        </FormContainer>
      }
      buttonLabel="bills.pay.collectVendorEmailModal.sendEmail"
      onButtonClick={handleCollectVendorEmailModalSubmit}
      onCloseClick={handleCollectVendorEmailModalCancel}
      isLoading={isProcessing}
    />
  );
};

export default CollectVendorEmailModalMessage;

const FormContainer = styled.div`
  width: 100%;

  #contactEmail {
    font-size: ${(props) => props.theme.text.size.regular};
  }
`;

const StyledVendorName = styled.span`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.bigTitleM};
  line-height: 3.2rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};

  ${(props) => props.theme?.pages?.PayBillSuccessPage?.StyledVendorName}
`;

const PendingNotification = styled.div`
  margin-top: 1rem;
`;
