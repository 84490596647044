import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { MICreateOption } from 'src/components/common/MICreateOption';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { Badge } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { VStack } from 'src/core/ds/stack';
import { useModal } from 'src/helpers/react/useModal';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import { CompanyHandlerModal } from 'src/pages/get-paid/create/components/CompanyHandlerModal';
import { useGetPaidOnboardingMainEntryPoint } from 'src/pages/onboarding/vendor-company-info/hooks/useGetPaidOnboardingMainEntryPoint';
import { getCompanyInfo, getOrgId, getOwnedVendorHandle } from 'src/redux/user/selectors';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import type { CompanyInfoType } from 'src/utils/types';
import { getPaidLocations } from '../locations';
import { PageProps } from './CreateInvoiceRouter';

type InvoiceOptionsPageProps = {
  goToUrl: (url: string) => void;
} & PageProps;

export const InvoiceOptionsPage = ({ onExit, goToUrl }: InvoiceOptionsPageProps) => {
  const history = useHistory<{ origin?: string; redirectUrl?: string }>();
  const orgId = useSelector(getOrgId);
  const companyInfo: CompanyInfoType = useSelector(getCompanyInfo);
  const ownedVendorHandle: string | undefined = useSelector(getOwnedVendorHandle);
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');
  const { isFinishedOnboarding, canCreatePaymentRequests, navigateToOnboarding } = useCanCreatePaymentRequest();
  const { getPaidOnboardingEntryPoint } = useGetPaidOnboardingMainEntryPoint();
  const [CompanyHandlerModalComponent, showHandlerModel] = useModal(CompanyHandlerModal, {
    modalName: 'copy-company-handler-modal',
    companyName: companyInfo.companyName,
    handle: ownedVendorHandle,
  });
  const onManualInvoiceClick = () => {
    goToUrl(getPaidLocations.create.manually);
  };

  const onConnectAccountingSoftwareClick = () => {
    goToUrl(getPaidLocations.create.connectAccountingSoftware.connect);
  };

  const handleShowHandleClick = () => {
    if (!canCreatePaymentRequests) {
      navigateToOnboarding(DeliveryMethodOrigin.GET_PAID_SHOW_HANDLE);

      return;
    }

    showHandlerModel();
  };

  useEffect(() => {
    if (!isFinishedOnboarding) {
      history.push(
        generatePath(getPaidOnboardingEntryPoint, {
          orgId,
        }),
        {
          origin: DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST,
        }
      );
    }
  }, [history, orgId, isFinishedOnboarding, getPaidOnboardingEntryPoint]);

  useEffect(() => {
    if (origin === DeliveryMethodOrigin.GET_PAID_SHOW_HANDLE) {
      handleShowHandleClick();
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origin]);

  return (
    <StepLayoutPage
      relativeStep={0}
      goExit={onExit}
      title="getPaid.new.create.title"
      subtitle="getPaid.new.create.subTitle"
      innerSize={100}
      isHideActions
    >
      {CompanyHandlerModalComponent}
      <VStack w="full" justifyContent="center" textAlign="left">
        <MICreateOption
          icon="icon-sync-settings-icon"
          label="getPaid.new.create.connectAccountingSoftware"
          labelValues={{
            recommended: (
              <Box display="inline" mb="1">
                <Badge label="general.recommended" />
              </Box>
            ),
          }}
          description="getPaid.new.create.connectAccountingSoftwareDescription"
          onClick={onConnectAccountingSoftwareClick}
        />
        <MICreateOption
          icon="icon-upload-attachment"
          label="getPaid.new.create.importInvoicesManualOptions"
          description="getPaid.new.create.importInvoicesManualDescription"
          onClick={onManualInvoiceClick}
        />
      </VStack>
      <VStack w="full" justifyContent="center" mt="8" mb="4" textAlign="left">
        <WizardElements.WizardOrLine />
      </VStack>
      <VStack w="full" justifyContent="center">
        <MICreateOption
          label="getPaid.new.create.shareYourCompanyURL"
          description="getPaid.new.create.shareYourCompanyURLDescription"
          onClick={handleShowHandleClick}
        />
      </VStack>
    </StepLayoutPage>
  );
};
