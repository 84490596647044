import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { IllustrationName } from 'src/core/ds/illustration';
import { Link } from 'src/core/ds/link';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { billLocations } from 'src/pages/bill/locations';
import { billingLocations } from 'src/pages/billing/locations';
import { analytics } from 'src/services/analytics';
import { AddFundingSourceWizardOrigin, FundingSourceOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';

const LEARN_MORE_LINK =
  'https://melio.zendesk.com/hc/en-us/articles/4447505319314-How-to-verify-a-bank-account-with-micro-deposits';

/**
 * @deprecated please do not use this -src/flows/add-bank-account-flow/steps/success/AddPlaidBankAccountSuccessPage.tsx
 */
export const CompleteAddingBankAccountsPageContainer = () => {
  const { navigate, navigateWithPreservedState } = usePreservedStateNavigator();
  const fundingSources = useSelector(fundingSourcesStore.selectors.all);
  const [newFundingSourceId] = useLocationState('newFundingSourceId', '');
  const [preservedState] = useLocationState<Record<string, string>>('preservedState');
  const origin = preservedState?.origin;
  const fundingSource = fundingSources.find((fs) => fs.id === newFundingSourceId);

  const goNextFlow = () => {
    if (origin === AddFundingSourceWizardOrigin.BILLING) {
      navigate(billingLocations.add, false, { newFundingSourceId: fundingSource.id });

      return;
    }

    if (navigateWithPreservedState) {
      // Passed state is used in batch payments
      const fs = newFundingSourceId ? { fsId: newFundingSourceId } : { origin: FundingSourceOrigin.PLAID };
      let state: Record<string, any> = {
        newFundingSource: fs,
      };

      if (origin === AddFundingSourceWizardOrigin.GUEST_ONBOARDING) {
        state = {
          selectedFundingSourceId: newFundingSourceId,
        };
      }

      navigateWithPreservedState(state);
    } else {
      navigate(billLocations.index);
    }
  };

  const goLearnMore = () => {
    analytics.trackAction('learn-more');
  };

  let title = 'onboarding.fundingSources.bank.complete.title';
  let subTitle = 'onboarding.fundingSources.bank.complete.subtitle';
  let buttonCTA = 'onboarding.fundingSources.bank.complete.next';
  let illustration = IllustrationName.moneyBagCheck;

  if (fundingSource?.origin === FundingSourceOrigin.MANUAL && !fundingSource?.isVerified) {
    title = 'flows.addBankAccount.pending.title';
    subTitle = 'flows.addBankAccount.pending.subtitle';
    buttonCTA = 'flows.addBankAccount.pending.buttonCTA';
    illustration = IllustrationName.stopWatch;
  }

  const Footer = (
    <Box mt={5} textStyle="body3" data-testid="learn-more-footer">
      <MIFormattedText
        label="onboarding.fundingSources.bank.manually.connectSuccessFooter"
        values={{
          learnMore: (
            <Link href={LEARN_MORE_LINK} isExternal onClick={goLearnMore}>
              <MIFormattedText label="bills.pay.fundingSource.amexIndustryModal.learnMoreLink" />
            </Link>
          ),
        }}
      />
    </Box>
  );

  return (
    <SuccessLayoutPage
      illustration={illustration}
      textAlign="center"
      title={title}
      text={subTitle}
      buttonLabel={buttonCTA}
      buttonAction={goNextFlow}
      hideHeader
      footer={Footer}
    />
  );
};
