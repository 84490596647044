const baseStyle = {
  display: 'flex',
  justifyContent: 'center',
  spacing: '4',
  maxWidth: 'full',
};

export default {
  baseStyle,
};
