import { ReactElement } from 'react';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import moment from 'src/utils/moment-business-days';

type DeliveryDateFormatPropsType = {
  date?: Date;
  maxDate?: Date | null;
  weekday?: 'short' | 'long' | 'narrow';
};

export const DeliveryDateFormat = ({ date, maxDate, weekday }: DeliveryDateFormatPropsType) => {
  if (!maxDate || moment(date).isSameOrAfter(moment(maxDate), 'day')) {
    return <MIFormattedDate date={date} weekday={weekday} />;
  }

  const toDate = moment(maxDate);
  let toDateValue: ReactElement | string = <MIFormattedDate date={maxDate} />;
  let fromDateValue = <MIFormattedDate date={date} />;

  if (moment(date).isSame(toDate, 'year')) {
    fromDateValue = <MIFormattedDate year={undefined} date={date} />;

    if (moment(date).isSame(toDate, 'month')) {
      toDateValue = moment(toDate).format('D, YYYY');
    }
  }

  return (
    <>
      {fromDateValue}
      {' - '}
      {toDateValue}
    </>
  );
};
