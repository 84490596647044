import * as React from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton';

type PageLayoutProps = {
  children: React.ReactNode;
  goBack: () => void;
};

const PageLayout: React.FC<PageLayoutProps> = ({ children, goBack }) => (
  <Flex
    px="3%"
    pt={40}
    bg="grey.200"
    direction="column"
    overflow="auto"
    h="100%"
    boxSizing="border-box"
    alignItems="center"
  >
    <Box maxW="172.8rem">
      <IconButton
        aria-label="right-arrow"
        label="regularBatchPayments.back"
        color="grey.600"
        size="md"
        _hover={{ color: 'grey.200' }}
        icon={<Icon name={IconNames.shevronLeft} />}
        onClick={goBack}
        justifyContent="flex-end"
      />
      {children}
    </Box>
  </Flex>
);

export default PageLayout;
