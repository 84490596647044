import { getValidationErrors, SchemaName, schemas } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { PersistConfig } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { takeEvery } from 'redux-saga/effects';
import { createCreateSlice } from 'src/helpers/redux/restCreateSlice';
import { createDeleteSlice } from 'src/helpers/redux/restDeleteSlice';
import { createFetchSlice } from 'src/helpers/redux/restFetchSlice';
import { createListSlice } from 'src/helpers/redux/restListSlice';
import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { RestfulState } from 'src/helpers/redux/types';
import { CLEAR_STATE } from 'src/redux/user/actionTypes';
import { hasKey } from 'src/utils/hasKey';
import { composeSlices } from './composeSlice';

export function hashListKey(params: { [key: string]: any }): string {
  if (params.identifier) {
    return params.identifier.toString();
  }

  return map(params, (value, key: string) => `${key}:${isObject(value) ? JSON.stringify(value) : value}`).join(':');
}

export function defaultCreateHashFunction() {
  return 'default';
}

type Dispatcher = (dispatch: Dispatch<any>) => (params: any) => void;

type ListResponse<T> = {
  items: T[];
  totalItems?: number;
};

export type CreateRestfulSliceOptions<T extends Entity, I = any> = {
  name: string;
  initialState?: Partial<RestfulState<T>> & I;
  api: {
    fetch?: (...data: T[]) => Promise<T>;
    update?: (...data: T[]) => Promise<T>;
    create?: (...data: T[]) => Promise<T>;
    delete?: ({ orgId, id }) => Promise<any>;
    list?: (query?: any) => Promise<ListResponse<T>>;
  };
  extraReducers?: {
    [actionType: string]: (state: RestfulState<T> & I, action: any) => (RestfulState<T> & I) | void;
  };
  extraSagas?: { [actionType: string]: any };
  schemaName?: SchemaName;
  selectors?: { [key: string]: (...state: any) => any };
  slices?: { [key: string]: any };
  createHashFunc?: (T) => string;
  listHashFunc?: (T) => string;
  dispatchers?: { [key: string]: Dispatcher };
  persistConfig?: Partial<PersistConfig<Partial<RestfulState<T> & I>>>;
  validateFunc?: (obj: T, changes?: Partial<T>) => Promise<any>;
};
export type Entity = {
  id: string | number;
};

export function createRestfulSlice<T extends Entity, I = any>(options: CreateRestfulSliceOptions<T, I>) {
  const {
    name,
    api,
    extraReducers,
    createHashFunc = defaultCreateHashFunction,
    listHashFunc = hashListKey,
    validateFunc,
  } = options;
  const validate =
    validateFunc ||
    (async (obj: T, changes?: Partial<T>) => {
      const fieldsToCompare = changes && obj.id ? (Object.keys(changes) as (keyof T & string)[]) : undefined;
      const resolvedSchemaName = resolveSchemaName(options);

      if (!resolvedSchemaName) {
        // If no valid schema name
        return {};
      }

      const res = getValidationErrors(resolvedSchemaName, obj, fieldsToCompare);

      return isEmpty(res) ? null : res;
    });

  // eslint-disable-next-line max-len
  const listSlice = api.list && createListSlice<T>({ storeName: name, api: api.list, listHashFunc });
  // eslint-disable-next-line max-len
  const createSlice =
    api.create &&
    createCreateSlice<T>({
      storeName: name,
      api: api.create,
      validate,
      createHashFunc,
    });
  const fetchSlice = api.fetch && createFetchSlice<T>({ storeName: name, api: api.fetch });
  const updateSlice = api.update && createUpdateSlice<T>({ storeName: name, api: api.update, validate });
  const deleteSlice = api.delete && createDeleteSlice<T>({ storeName: name, api: api.delete });
  const extraSagas = mapValues(options.extraSagas || {}, (saga, event) => takeEvery(event, saga));
  const persistConfig = options.persistConfig
    ? {
        key: name,
        storage: storageSession,
        ...options.persistConfig,
      }
    : null;

  const slice = composeSlices(
    {
      fetch: fetchSlice,
      update: updateSlice,
      list: listSlice,
      create: createSlice,
      delete: deleteSlice,
      ...(options.slices || {}),
    },
    {
      initialState: options.initialState,
      validate,
      extraReducers: {
        [CLEAR_STATE]() {
          return slice.initialState;
        },
        ...extraReducers,
      },
      selectors: options.selectors || {},
      extraSagas,
      dispatchers: options.dispatchers || {},
      persistConfig,
    }
  );

  return slice;
}

const recMapDispatchers = (dispatch, dispatchers) =>
  mapValues(dispatchers, (dispatcher) => {
    if (isFunction(dispatcher)) {
      return dispatcher(dispatch);
    }

    return recMapDispatchers(dispatch, dispatcher);
  });

export const getStoreActions = (store) => (dispatch) => recMapDispatchers(dispatch, store.dispatchers);

export const useStoreActions = (store) => {
  const dispatcher = useDispatch();

  return useMemo(() => getStoreActions(store)(dispatcher), [store, dispatcher]);
};

function resolveSchemaName(options: Pick<CreateRestfulSliceOptions<Entity>, 'schemaName' | 'name'>) {
  function isValidSchemaName(name: string): name is SchemaName {
    return hasKey(schemas, name);
  }

  if (options.schemaName) {
    return options.schemaName;
  }

  if (isValidSchemaName(options.name)) {
    return options.name;
  }

  return null;
}
