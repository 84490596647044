import { PaymentType } from 'src/utils/types';

export type PayItemPaymentType = Partial<
  Pick<
    PaymentType,
    | 'id'
    | 'amount'
    | 'scheduledDate'
    | 'status'
    | 'billId'
    | 'deliveryEta'
    | 'approvalDecisionStatus'
    | 'metadata'
    | 'createdAt'
    | 'transactions'
    | 'deliveryMethod'
    | 'deliverStatus'
    | 'manual'
    | 'paidDate'
    | 'collectStatus'
    | 'billPayment'
    | 'financing'
    | 'bill'
    | 'isFinanced'
  >
> & {
  id: number;
  flags?: PayCardFlag[];
};

export enum PayCardFlag {
  PENDING_VENDOR_INFO = 'pendingVendorInformation',
  DEPOSITED = 'deposited',
  PARTIAL_PAID_BILL = 'isPartialPaidBill',
}

export interface PayCardBadgeProps {
  flags?: PayCardFlag[];
  isPartiallyPaidItemOnInboxTab: boolean | undefined;
  isSelfServeRefundEnabled: boolean;
  payment?: PayItemPaymentType;
}
