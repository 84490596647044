import { VStack } from 'src/core/ds/stack';
import { NextStepListItem } from './next-step-list/NextStepListItem';

export const VendorCompanyAddedPageContent = () => (
  <VStack alignItems="flex-start" spacing={10}>
    <NextStepListItem
      index="01"
      key="01"
      title="onboarding.vendorCompanyInfo.added.steps.first.title"
      subtitle="onboarding.vendorCompanyInfo.added.steps.first.subtitle"
    />
    <NextStepListItem
      index="02"
      key="02"
      title="onboarding.vendorCompanyInfo.added.steps.second.title"
      subtitle="onboarding.vendorCompanyInfo.added.steps.second.subtitle"
    />
    <NextStepListItem
      index="03"
      key="03"
      title="onboarding.vendorCompanyInfo.added.steps.third.title"
      subtitle="onboarding.vendorCompanyInfo.added.steps.third.subtitle"
    />
  </VStack>
);
