import range from 'lodash/range';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ModelView } from 'src/ui/form';
import { ApprovalWorkflowCreatorsGroup } from 'src/utils/consts';
import { WorkflowModelType } from './types';

export const useCreatorsGroupOptions = (): { label: string; value: ApprovalWorkflowCreatorsGroup }[] => {
  const intl = useIntl();
  const creatorsGroupOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: 'settings.approvalWorkflows.modal.creatorsGroup.all' }, {}),
        value: ApprovalWorkflowCreatorsGroup.ALL,
      },
      {
        label: intl.formatMessage({ id: 'settings.approvalWorkflows.modal.creatorsGroup.specific' }, {}),
        value: ApprovalWorkflowCreatorsGroup.SOME,
      },
    ],
    [intl]
  );

  return creatorsGroupOptions;
};

export const useAdminApprovalsOptions = (maxApprovers: number): { label: string; value: string }[] => {
  const intl = useIntl();
  const adminApprovalsOptions = useMemo(
    () =>
      range(1, maxApprovers + 1).map((value) => ({
        label: intl.formatMessage({ id: 'settings.approvalWorkflows.modal.adminApproval.option' }, { value }),
        value: value.toString(),
      })),
    [intl, maxApprovers]
  );

  return adminApprovalsOptions;
};

export const useForceOpenPaymentCreatorsDropdown = (workflowModelView: ModelView<WorkflowModelType>): boolean => {
  const prevCreatorsGroupRef = useRef(ApprovalWorkflowCreatorsGroup.SOME);
  const [forcePaymentCreatorsDropdownOpen, setForcePaymentCreatorsDropdownOpen] = useState(false);

  useEffect(() => {
    const oldCreatorsGroupValue = prevCreatorsGroupRef.current;
    prevCreatorsGroupRef.current = workflowModelView.creatorsGroup.value;

    if (workflowModelView.creatorsGroup.value !== oldCreatorsGroupValue) {
      setForcePaymentCreatorsDropdownOpen(workflowModelView.creatorsGroup.value === ApprovalWorkflowCreatorsGroup.SOME);
    } else {
      setForcePaymentCreatorsDropdownOpen(false);
    }
  }, [workflowModelView.creatorsGroup.value]);

  return forcePaymentCreatorsDropdownOpen;
};
