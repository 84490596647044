import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { accountingSoftwareDisplayName } from 'src/pages/settings/components/accounting-software/consts';
import { NotificationCardTypes } from 'src/utils/consts';
import {
  AccountingPlatformLogo,
  CardHeaderWrapper,
  ConnectErrorDescriptionWrapper,
  ErrorNotificationCard,
  SupportLink,
} from './AccountingSoftwareCardElements';

type ErrorViewProps = {
  onLink: () => void;
  accountingSoftwareType: string;
  logo: string;
};

export const ErrorView = ({ onLink, accountingSoftwareType, logo }: ErrorViewProps) => {
  const { displayName, displayShortName } = accountingSoftwareDisplayName[accountingSoftwareType];

  return (
    <MICard>
      <MICardForm>
        <AccountingPlatformLogo src={logo} alt="logo" />
        <CardHeaderWrapper>
          <MICardTitle
            label="settings.newAccountingSoftware.SSO.cardTitle"
            values={{ accountingSoftwareType: displayName }}
          />
        </CardHeaderWrapper>
        <ErrorNotificationCard
          type={NotificationCardTypes.ERROR}
          subtitle={{
            label: 'settings.newAccountingSoftware.SSO.linkError.generic',
            values: {
              accountingSoftwareType: displayShortName,
            },
          }}
        />

        <ConnectErrorDescriptionWrapper>
          <Box fontWeight="bold">
            <MIFormattedText label="settings.newAccountingSoftware.SSO.linkError.noteTitle" />
          </Box>
          <MIFormattedText
            label="settings.newAccountingSoftware.SSO.linkError.note"
            values={{
              supportMail: <SupportLink label="settings.newAccountingSoftware.SSO.linkError.supportTeam" />,
              accountingSoftwareType: displayShortName,
            }}
          />
        </ConnectErrorDescriptionWrapper>

        <Button
          variant={ButtonVariants.tertiary}
          size={ButtonSizes.lg}
          label="settings.newAccountingSoftware.relink"
          mt={5}
          onClick={onLink}
        />
      </MICardForm>
    </MICard>
  );
};
