import React from 'react';
import { SyncWithAccountingSoftwareCardProps } from 'src/pages/settings/types';
import { SSOLogoMap } from 'src/pages/settings/utils/accounting-software/SSOLogoMap';
import { AccountingSoftware } from 'src/utils/consts';
import { SyncWithSSOCard } from './components/SyncWithSSOCard';

export const SyncWithXeroCard = ({ model, viewMode, onEdit, isLoading }: SyncWithAccountingSoftwareCardProps) => (
  <SyncWithSSOCard
    isLoading={isLoading}
    model={model}
    viewMode={viewMode}
    onEdit={onEdit}
    accountingSoftwareType={AccountingSoftware.XERO}
    accountingSoftwareLogo={SSOLogoMap[AccountingSoftware.XERO]}
  />
);
