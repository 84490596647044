import { EmptyList } from 'src/components/list/EmptyList';
import NewEmptyList from 'src/components/list/NewEmptyList';
import payEmptyStateIcon from 'src/images/general/pay-empty-state-icon.svg';
import { PayListItemType } from 'src/modules/bills/types';
import { useShowZeroState } from 'src/pages/pay/hooks/useShowZeroState';
import { BillStatus } from 'src/utils/consts';
import { BillType, Filters } from 'src/utils/types';

export const payToVendorSteps = [
  {
    title: 'bills.emptyState.payingSteps.firstStepTitle',
    description: 'bills.emptyState.payingSteps.firstStepDescription',
  },
  {
    title: 'bills.emptyState.payingSteps.secondStepTitle',
    description: 'bills.emptyState.payingSteps.secondStepDescription',
  },
  {
    title: 'bills.emptyState.payingSteps.thirdStepTitle',
    description: 'bills.emptyState.payingSteps.thirdStepDescription',
  },
];

export const getBillsListEmptyLabel = (
  selectedTabBillStatus: BillStatus,
  isSearchPerformed: boolean,
  isFilterPerformed: boolean
) => {
  if (isSearchPerformed) {
    return 'bills.emptyState.search';
  }

  if (isFilterPerformed) {
    return 'bills.emptyState.filter';
  }

  switch (selectedTabBillStatus) {
    case BillStatus.SCHEDULED:
      return 'bills.emptyState.textScheduled';
    case BillStatus.PAID:
      return 'bills.emptyState.textPaid';
    default:
      return 'bills.emptyState.btnHint';
  }
};

type Props = {
  // we want to temporary support an old bill type - BillType
  bills: PayListItemType[] | BillType[];
  goCreateBill: (event: React.SyntheticEvent<HTMLInputElement>) => void | string | Promise<void>;
  billsCount: number;
  selectedTabBillStatus: BillStatus;
  filters: Filters;
  onZeroStateAction: () => void;
  onClearFilters?: () => void;
};

export const BillListEmptyState = ({
  bills,
  goCreateBill,
  billsCount,
  selectedTabBillStatus,
  filters,
  onZeroStateAction,
  onClearFilters,
}: Props) => {
  const { showBillsZeroState, isHideActionButton, isSearchPerformed, isFilterPerformed } = useShowZeroState(
    bills,
    selectedTabBillStatus,
    filters
  );
  const emptyLabel = getBillsListEmptyLabel(selectedTabBillStatus, isSearchPerformed, isFilterPerformed);
  const ctaLabel = billsCount ? 'bills.newEmptyState.buttonText' : 'bills.newEmptyState.ctaButtonText';

  const goClearFilters = () => {
    if (isFilterPerformed) {
      onClearFilters && onClearFilters();
    }
  };

  if (showBillsZeroState) {
    return (
      <NewEmptyList
        iconSrc={payEmptyStateIcon}
        text="bills.newEmptyState.text"
        ctaAction={goCreateBill}
        ctaLabel={ctaLabel}
        withPlus
      />
    );
  }

  return (
    <EmptyList
      zeroStateTitle="bills.emptyState.title"
      ctaLabel="bills.emptyState.btnText"
      ctaAction={goCreateBill}
      text={emptyLabel}
      isHideActionButton={isHideActionButton}
      showZeroState={showBillsZeroState}
      ctaZeroState="bills.emptyState.btnZeroStateText"
      zeroStateAction={onZeroStateAction}
      zeroStateOptions={payToVendorSteps}
      textAction={isFilterPerformed ? goClearFilters : undefined}
      textActionLabel="bills.emptyState.clearFilters"
    />
  );
};
