import pick from 'lodash/pick';
import { useState } from 'react';
import { ModelView } from 'src/ui/form';
import { convertToGoogleAddress, convertToServerAddress } from 'src/utils/address';
import { AddressType, GoogleCombinedAddressType } from 'src/utils/types';

export const useFormGoogleAddress = <
  T extends Partial<{ addressLine1: string; state: string; city: string; zipCode: string; formattedAddress: string }>
>({
  modelView,
}: {
  modelView: ModelView<T>;
}) => {
  const convertModelViewAddress = () => {
    const { addressLine1, state, city, zipCode, formattedAddress } = modelView;

    return formattedAddress?.value || addressLine1?.value
      ? convertToGoogleAddress({
          addressLine1: String(addressLine1.value),
          state: String(state.value),
          city: String(city.value),
          zipCode: String(zipCode.value),
          formattedAddress: String(formattedAddress?.value || ''),
        })
      : null;
  };

  const [address, setAddress] = useState<GoogleCombinedAddressType | null>(convertModelViewAddress);

  const updateLegalBusinessAddressModelView = ({
    addressLine1,
    state,
    city,
    zipCode,
  }: Pick<AddressType, 'addressLine1' | 'state' | 'city' | 'zipCode'>) => {
    modelView.addressLine1?.onChange({ value: addressLine1 || '' });
    modelView.state?.onChange({ value: state || '' });
    modelView.city?.onChange({ value: city || '' });
    modelView.zipCode?.onChange({ value: zipCode || '' });
  };

  const resetLegalBusinessAddressModelView = () => {
    modelView.addressLine1?.onChange({ value: '' });
    modelView.state?.onChange({ value: '' });
    modelView.city?.onChange({ value: '' });
    modelView.zipCode?.onChange({ value: '' });
  };

  const handleAddressChange = (address: GoogleCombinedAddressType) => {
    if (address.addressComponents.length > 0) {
      const serverAddress = convertToServerAddress(address);
      updateLegalBusinessAddressModelView(pick(serverAddress, ['addressLine1', 'state', 'city', 'zipCode']));
      setAddress(address);
    } else {
      resetLegalBusinessAddressModelView();
      setAddress(null);
    }
  };

  return {
    address,
    handleAddressChange,
  };
};
