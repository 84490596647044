import { FlowCustomizationsType } from 'src/flows/add-bank-account-flow/types';
import { LinkAccountingPlatformAccountToFundingSourcePage } from 'src/pages/onboarding/funding-sources/bank/LinkAccountingPlatformAccountToFundingSourcePage';

type Props = {
  fundingSourceId?: string;
  goNext: () => void;
  cancelFlow: () => void;
  flowCustomizations: FlowCustomizationsType;
};

export const LinkAccountingPlatform = ({
  fundingSourceId: fsString,
  goNext,
  cancelFlow,
  flowCustomizations,
}: Props) => {
  const fundingSourceId = Number(fsString);

  return (
    <LinkAccountingPlatformAccountToFundingSourcePage
      fundingSourceId={fundingSourceId}
      goNext={goNext}
      cancelFlow={cancelFlow}
      editMode={false}
      newAccountNotificationLabel={flowCustomizations.newAccountNotificationLabel}
    />
  );
};
