import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { AmountCurrencyField, TextField } from 'src/core/ds/form/fields';
import { useModal } from 'src/helpers/react/useModal';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { UnsupportedCountryModal } from 'src/pages/vendor/international-delivery-method/components/UnsupportedCountryModal';
import { useInternationalWizard } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalWizard';
import {
  getIsComingFromPayBillFlow,
  getIsComingFromVendorDetails,
  isFromOnboarding,
} from 'src/pages/vendor/international-delivery-method/utils';
import { getBillingDetails } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { BillingType } from 'src/utils/ServerTypes';
import { getTranslationValue } from 'src/utils/translations';
import { VendorType } from 'src/utils/types';
import { Footer } from '../components/Footer';
import { useBillingDetailsForm } from '../hooks/useBillingDetailsForm';
import { useCountryOptions } from '../hooks/useCountryOptions';
import { AccountNumberField } from './components/AccountNumberField';
import { CountryGroupLabel } from './components/CountryGroupLabel';

type Props = {
  onExit: () => void;
  vendorId: number;
};

const getTitle = (hasSelectedCountry: boolean) =>
  hasSelectedCountry
    ? 'vendors.deliveryMethods.international.billing.title'
    : 'vendors.deliveryMethods.international.billing.titleSelectCountry';

export const InternationalBillingDetailsPage = ({ onExit, vendorId }: Props) => {
  const { fee } = useSelector(getBillingDetails);
  const { setWizardState } = useInternationalWizard();
  const [countryOptions, loadingCountryOptions] = useCountryOptions(vendorId);
  const vendor = useSelector<any, VendorType>(vendorsStore.selectors.fetch.byId(vendorId));
  const [origin] = useLocationState<string>('origin');
  const countriesByCode = useMemo(() => {
    const allCountries = countryOptions.map((group) => group.options).flat();

    return keyBy(allCountries, 'value');
  }, [countryOptions]);

  const [model, { isBankDetailsLoading, submit, isValidBankDetails }, validationErrors] = useBillingDetailsForm(
    vendorId,
    countriesByCode
  );

  const [UnsupportedCountryModalInstance, showUnsupportedCountryModal] = useModal(UnsupportedCountryModal, {
    modalName: 'UnsupportedCountryModal',
    ctaLabel: isFromOnboarding(origin)
      ? 'vendors.deliveryMethods.international.unsupportedCountry.onboardingCta'
      : 'vendors.deliveryMethods.international.unsupportedCountry.cta',
    onDismiss(ctaClicked: boolean) {
      if (isFromOnboarding(origin) && ctaClicked) {
        onExit();
      }
    },
  });

  const showBankDetailsFields = isValidBankDetails && !isBankDetailsLoading;
  const isComingFromPayBillFlow = origin && getIsComingFromPayBillFlow(origin);
  const isComingFromVendorDetails = useMemo(() => (origin ? getIsComingFromVendorDetails(origin) : false), [origin]);
  const showBillAmount = isComingFromPayBillFlow || origin === DeliveryMethodOrigin.BATCH_PAY_BILLS;

  const hasSelectedCountry = !isNil(model.bankCountry.value);
  const isSwiftRequired = countriesByCode[model.bankCountry.value]?.billingType === BillingType.BIC_SWIFT;
  const countryRiskLevel = countriesByCode[model.bankCountry.value]?.risk;

  useEffect(() => {
    setWizardState((prevState) => ({
      ...prevState,
      countryRiskLevel,
    }));
  }, [countryRiskLevel]);

  const onCountryChange = useCallback(
    ({ value }) => {
      const isSupported = countriesByCode[value]?.isSupported;

      if (countriesByCode[value]) {
        analytics.track('payment-details', 'delivery-method-international_selected-country', {
          selectedCountry: value,
          isSupported,
        });

        if (isSupported) {
          model.bankCountry.onChange({ id: model.bankCountry.id, value });
        } else {
          showUnsupportedCountryModal({ countryLabel: countriesByCode[value]?.label });
        }
      }
    },
    [countriesByCode, showUnsupportedCountryModal, model.bankCountry]
  );

  return (
    <StepLayoutPage
      title={getTitle(hasSelectedCountry)}
      titleValues={{ companyName: vendor?.companyName }}
      nextLabel="progress.continue"
      isLoading={loadingCountryOptions || isBankDetailsLoading}
      goExit={onExit}
      {...(hasSelectedCountry && {
        ...{
          footer: (
            <Footer
              showFooterProps={{ isComingFromVendorDetails }}
              submit={submit}
              isLoading={loadingCountryOptions || isBankDetailsLoading}
            />
          ),
        },
      })}
    >
      {UnsupportedCountryModalInstance}
      <FormContainer>
        <MISingleSelect
          label="vendors.deliveryMethods.international.billing.bankCountry"
          placeholder="vendors.deliveryMethods.international.billing.bankCountryPlaceholder"
          value={model.bankCountry.value}
          onChange={onCountryChange}
          options={countryOptions}
          required
          formatGroupLabel={(group) => <CountryGroupLabel groupLabel={group.groupLabel} />}
          testId="select-country"
        />
        {hasSelectedCountry && (
          <>
            <div>
              {showBillAmount && (
                <AmountCurrencyField
                  label="vendors.deliveryMethods.international.billing.amount"
                  helperText="vendors.deliveryMethods.international.billing.fee"
                  helperTextValues={{ feeAmount: fee.international.value }}
                  model={model.amount}
                  isViewOnly={false}
                  tooltipProps={{
                    label: getTranslationValue('vendors.deliveryMethods.international.billing.amountTooltip'),
                    placement: 'top',
                    testId: 'amount-tooltip',
                  }}
                  isRequired
                  isDisabled
                />
              )}
            </div>
            {isSwiftRequired ? (
              <TextField
                testId="input-swift"
                label="vendors.deliveryMethods.international.billing.swift"
                placeholder="vendors.deliveryMethods.international.billing.swiftPlaceholder"
                model={model.swift}
                isRequired
              />
            ) : (
              <TextField
                testId="input-iban"
                label="vendors.deliveryMethods.international.billing.iban"
                placeholder="vendors.deliveryMethods.international.billing.ibanPlaceholder"
                errorMessage={validationErrors?.iban}
                model={model.iban}
                isRequired
              />
            )}
            {showBankDetailsFields && (
              <>
                <AccountNumberField model={model.accountNumber} errorMessage={validationErrors?.accountNumber} />
                <TextField
                  label="vendors.deliveryMethods.international.billing.bankName"
                  placeholder="vendors.deliveryMethods.international.billing.bankNamePlaceholder"
                  errorMessage={validationErrors?.bankName}
                  model={model.bankName}
                  isRequired
                />
              </>
            )}
          </>
        )}
      </FormContainer>
    </StepLayoutPage>
  );
};

const FormContainer = styled.div`
  > * {
    margin-bottom: 4rem;
  }
`;
