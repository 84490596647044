import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { InvoiceType } from 'src/modules/invoices/types';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import GetProModalContext from 'src/pages/get-pro/components/table/GetProModalContext';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import useLoadGetProList from 'src/pages/get-pro/hooks/useLoadGetProList';
import { getOrgId } from 'src/redux/user/selectors';
import { ButtonsDirections, DialogVariants, GetProTabs, InvoiceStatus } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { INITIATED_BY } from '../consts';
import { getProLocations } from '../locations';
import { MAP_STORE_BY_TAB } from '../utils';

export const useGetProSingleItemActions = (invoice: InvoiceType, tab: GetProTabs) => {
  const { id } = invoice;
  const location = useLocation();
  const currentQueryParams = useQueryString();
  const { showModal, setClear } = useContext(GetProModalContext);
  const orgId = useSelector(getOrgId);
  const actions = useStoreActions(MAP_STORE_BY_TAB[tab]);
  const { listParams } = useGetProListParams();
  const { loadGetProList } = useLoadGetProList(listParams);
  const [historyPush] = useHistoryWithOrgId();
  const customerName = invoice?.customer?.companyName || invoice?.customer?.contactName;
  const totalAmount = invoice?.totalAmount?.toString() || '0';
  const isInitiatedByCustomer = invoice?.initiatedBy === INITIATED_BY.CUSTOMER && !invoice?.paymentRequest;
  const canAcknowledge =
    isInitiatedByCustomer &&
    (invoice?.status === InvoiceStatus.FAILED || invoice?.status === InvoiceStatus.CANCELLED_BY_CUSTOMER);
  const canRemove =
    (invoice?.status === InvoiceStatus.FAILED && !!invoice?.paymentRequest) ||
    invoice?.status === InvoiceStatus.CANCELLED_BY_CUSTOMER;

  const canCancelPending = invoice?.isEligibleForCancel;
  // TODO: getPro update the condition base on status and tab
  const canMarkAsUnpaid = invoice?.status === 'markedAsPaid';
  const canViewInvoiceFile = !!invoice?.files?.length;

  const openPdfPreview = useCallback(() => {
    historyPush({
      path: getProLocations.invoicePdfPreview,
      query: {
        ...currentQueryParams,
        id,
      },
      state: {
        returnLocations: location,
      },
    });
  }, [historyPush, currentQueryParams, id, location]);

  const edit = useCallback(() => {
    historyPush({
      path: getProLocations.edit,
      query: {
        ...currentQueryParams,
        id,
      },
    });
  }, [currentQueryParams, historyPush, id]);

  const markInvoiceAsPaid = useCallback(async () => {
    await actions.proMarkAsPaid({ orgId, id });
    await loadGetProList();
  }, [actions, orgId, id]);

  const markRequestAsPaid = useCallback(async () => {
    const onMarkAsPaidRequest = async () => {
      await actions.proMarkAsPaid({ orgId, id });
      await loadGetProList();
    };

    showModal &&
      showModal({
        confirm: onMarkAsPaidRequest,
        title: 'getPro.confirmationModals.markedAsPaidRequest.title',
        description: 'getPro.confirmationModals.markedAsPaidRequest.description',
        confirmText: 'getPro.confirmationModals.markedAsPaidRequest.confirm',
        cancelText: 'getPro.confirmationModals.markedAsPaidRequest.cancel',
        hideIcon: true,
        minHeight: '31.2rem',
        buttonsDirection: ButtonsDirections.HORIZONTAL,
        variant: DialogVariants.SUCCESS,
        textValues: {
          amount: totalAmount,
          customerName,
        },
      });
  }, [showModal, totalAmount, customerName, actions, orgId, id]);

  const markAsPaid = useCallback(async () => {
    if (tab === GetProTabs.INVOICES) {
      return markInvoiceAsPaid();
    }

    return markRequestAsPaid();
  }, [tab, markInvoiceAsPaid, markRequestAsPaid]);

  const markAsUnpaid = useCallback(async () => {
    await actions.markAsUnpaid({ orgId, id });
    await loadGetProList();
  }, [actions, id, orgId]);

  const send = useCallback(() => {
    historyPush({
      path: getPaidLocations.create.share,
      query: {
        id,
        status: tab,
      },
      state: {
        returnLocations: location,
      },
    });
  }, [historyPush, id, location]);

  const viewCustomerDetails = useCallback(() => {
    historyPush({
      path: getProLocations.customer,
      query: {
        ...currentQueryParams,
        id,
      },
    });
  }, [currentQueryParams, historyPush, id]);

  const onRemove = useCallback(async () => {
    setClear();
    await actions.proDelete({ orgId, id });
    await loadGetProList();
  }, [actions, orgId, id, loadGetProList]);
  const showRemoveConfirmation = useCallback(
    () =>
      showModal &&
      showModal({
        confirm: onRemove,
        title: 'getPro.confirmationModals.removeInvoice.title',
        description: 'getPro.confirmationModals.removeInvoice.description',
        confirmText: 'getPro.confirmationModals.removeInvoice.confirm',
        cancelText: 'getPro.confirmationModals.removeInvoice.cancel',
      }),
    [onRemove, showModal]
  );

  const onCancel = useCallback(
    async (remove) => {
      setClear();

      if (remove) {
        await actions.proDelete({ orgId, id });
      } else {
        await actions.proCancel({ orgId, id });
      }

      await loadGetProList();
    },
    [actions, orgId, id, loadGetProList]
  );

  const cancelRemoveConfirmation = useCallback(
    () =>
      showModal &&
      showModal({
        confirm: onCancel,
        title: 'getPro.confirmationModals.removePaymentRequest.title',
        description: 'getPro.confirmationModals.removePaymentRequest.description',
        textValues: {
          customerName,
        },
        confirmText: 'getPro.confirmationModals.removePaymentRequest.confirm',
        cancelText: 'getPro.confirmationModals.removePaymentRequest.cancel',
        checkbox: {
          value: false,
          label: 'getPro.confirmationModals.removePaymentRequest.checkboxLabel',
        },
      }),
    [customerName, onCancel, showModal]
  );

  const cancelRemovePendingConfirmation = async () => {
    const onCancelPending = async (shouldDelete: boolean) => {
      await actions.proCancelPending({ orgId, id, shouldDelete });
      await loadGetProList();
    };

    if (isInitiatedByCustomer) {
      onCancelPending(false);
    } else {
      showModal &&
        showModal({
          confirm: onCancelPending,
          title: 'getPro.confirmationModals.removePendingPayment.title',
          description: 'getPro.confirmationModals.removePendingPayment.description',
          confirmText: 'getPro.confirmationModals.removePendingPayment.confirm',
          cancelText: 'getPro.confirmationModals.removePendingPayment.cancel',
          checkbox: {
            value: false,
            label: 'getPro.confirmationModals.removePendingPayment.checkboxLabel',
          },
        });
    }
  };

  return {
    edit,
    markAsPaid,
    markAsUnpaid,
    send,
    viewCustomerDetails,
    remove: showRemoveConfirmation,
    cancel: cancelRemoveConfirmation,
    cancelPending: cancelRemovePendingConfirmation,
    openPdfPreview,
    canMarkAsUnpaid,
    canRemove,
    canAcknowledge,
    canViewInvoiceFile,
    canCancelPending,
  };
};
