import { useSelector } from 'react-redux';
import Flex from 'src/core/ds/flex/Flex';
import { financingStore } from 'src/modules/financing/financing-store';
import { CustomFinancingDatePicker } from 'src/pages/bill/pay/installments/pages/repayment-terms/components/CustomFinancingDatePicker';
import { FirstInstallmentDeductionDate } from 'src/pages/bill/pay/installments/pages/repayment-terms/components/FirstInstallmentDeductionDate';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import {
  addBusinessDaysToCurrentUSCentralDate,
  addDaysToCurrentUSCentralDate,
  MAX_FINANCING_SCHEDULE_DATE,
  MIN_FINANCING_SCHEDULE_DATE,
} from 'src/utils/dates';

export const FinancingRepaymentTermsDateComponent = ({
  isInstallmentDatesLoading,
  onDateChange,
  isDisabled,
}: {
  isInstallmentDatesLoading: boolean;
  onDateChange: (Date) => void;
  isDisabled: boolean;
}) => {
  const payment = useSelector(getPayment);
  const { dueDate } = useSelector(getBill);
  const { scheduledDate, deliveryEta, maxDeliveryEta } = payment;

  const { data: result } = useSelector(financingStore.selectors.setInstallmentOption.status);
  const firstInstallmentDate = result?.installments[0]?.scheduledDate;

  const minScheduledDate = addBusinessDaysToCurrentUSCentralDate(MIN_FINANCING_SCHEDULE_DATE);
  const maxScheduledDate = addDaysToCurrentUSCentralDate(MAX_FINANCING_SCHEDULE_DATE);

  return (
    <Flex justifyContent="start" direction="row" alignContent="left">
      <Flex direction="column" flexBasis={200}>
        <CustomFinancingDatePicker
          scheduleDate={scheduledDate}
          dueDate={new Date(dueDate)}
          deliveryDate={deliveryEta}
          onDateChange={onDateChange}
          maxScheduledDate={maxScheduledDate}
          minScheduledDate={minScheduledDate}
          maxDeliveryDate={maxDeliveryEta}
          isDisabled={isDisabled}
        />
      </Flex>
      <Flex direction="column" flexGrow={0} ml={10}>
        <FirstInstallmentDeductionDate
          firstInstallmentDate={firstInstallmentDate}
          isLoading={isInstallmentDatesLoading}
        />
      </Flex>
    </Flex>
  );
};
