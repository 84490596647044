import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { withSiteContext } from 'src/hoc/withSiteContext';
import { billsApi } from 'src/modules/bills/api';
import { billLocations } from 'src/pages/bill/locations';
import { updateBillAction } from 'src/redux/payBillWizard/actions';
import { getBill, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { GlobalState } from 'src/redux/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex, isBillAmountRequiresGoodsConfirmation } from 'src/utils/bills';
import { FundingType } from 'src/utils/consts';
import { AccountType, BillType } from 'src/utils/types';
import ExpeditePaymentPage from './components/ExpeditePaymentPage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type MapStateToProps = {
  bill: BillType;
  orgId: number;
  selectedFundingSource?: AccountType | null;
};

type MapDispatchToProps = {
  updateBill: (goodsReceived: boolean) => void;
};

type State = {
  selectedId: string | null;
  isUpdateBillProcessing: boolean;
};

type Props = PayBillProps &
  MapStateToProps &
  MapDispatchToProps & {
    site: any;
  };

const eventPage = 'expedite-payment-page';

class PlainExpeditePaymentPageContainer extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedId: null,
      isUpdateBillProcessing: false,
    };
  }

  componentDidMount() {
    const { navigate, bill, site, selectedFundingSource, orgId } = this.props;

    if (
      !isNil(bill.goodsReceived) ||
      !isBillAmountRequiresGoodsConfirmation(bill.totalAmount, site.requiresGoodsConfirmationAmount) ||
      (selectedFundingSource && selectedFundingSource.fundingType !== FundingType.CARD)
    ) {
      navigate(generatePath(billLocations.pay.confirm, { orgId, billId: bill.id }));
    } else {
      analytics.page('pay-bill', 'expedite-payment');
    }
  }

  onChange = (id: string) => {
    const { bill } = this.props;
    const answer = id === '0' ? 'no' : 'yes';
    analytics.track(eventPage, `change-product-received-answer-${answer}`, {
      partialBillId: getBillPaymentIndex(bill),
    });
    this.setState({ selectedId: id });
  };

  onNext = () => {
    const { orgId, bill, onNext, updateBill } = this.props;
    const { selectedId } = this.state;
    analytics.track(eventPage, 'continue', {
      partialBillId: getBillPaymentIndex(bill),
    });
    const fileId = !isEmpty(bill.files) ? [bill.files[0].id] : [];
    this.setState({ isUpdateBillProcessing: true });
    const goodsReceived = selectedId ? !!+selectedId : false;
    updateBill(goodsReceived);

    if (bill.isPaymentRequest) {
      this.setState({ isUpdateBillProcessing: false });
      onNext();

      return;
    }

    const newBill = {
      totalAmount: bill.totalAmount,
      balance: bill.balance,
      currency: bill.currency,
      invoiceDate: bill.invoiceDate,
      dueDate: bill.dueDate,
      invoiceNumber: bill.invoiceNumber,
      note: bill.note,
      vendorId: bill.vendorId,
      terms: bill.terms,
      intuitAccountId: bill.intuitAccountId,
      goodsReceived,
      files: fileId,
    };
    billsApi
      .editBillById(orgId, bill.id, newBill, 'all')
      .then(() => {
        this.setState({ isUpdateBillProcessing: false });
        onNext();
      })
      .catch(() => {
        this.setState({
          isUpdateBillProcessing: false,
        });
      });
  };

  render() {
    const { selectedId, isUpdateBillProcessing } = this.state;
    const { goExit, onPrev } = this.props;

    return (
      <ExpeditePaymentPage
        onNext={this.onNext}
        onChange={this.onChange}
        goExit={goExit}
        onPrev={onPrev}
        selectedId={selectedId}
        isLoading={isUpdateBillProcessing}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  bill: getBill(state),
  orgId: getOrgId(state),
  selectedFundingSource: getSelectedFundingSource(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  updateBill(goodsReceived: boolean) {
    dispatch(updateBillAction(goodsReceived));
  },
});

export const ExpeditePaymentPageContainer = compose(
  withPayBillData(),
  withSiteContext(),
  connect(mapStateToProps, mapDispatchToProps)
)(PlainExpeditePaymentPageContainer);
