import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { updateItemsInState } from 'src/helpers/redux/listsActions';
import { InvoiceType } from 'src/modules/invoices/types';
import { invoicesApi } from './api';
import { name } from './constants';

type State = {
  byId: { [key: string]: InvoiceType };
  batchShare: {
    loading: boolean;
    error?: Error;
  };
};

const batchShareSlice = createApiCallSlice<any, State>({
  name: generateSliceName(name, 'batch_share'),
  api: invoicesApi.batchShare,
  initialState: {
    byId: {},
    batchShare: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.batchShare.loading = true;
      delete state.batchShare.error;
    },
    [ON_SUCCESS]: (state, action) => {
      state.batchShare.loading = false;
      const { invoices } = action.payload;
      updateItemsInState(state, invoices);
    },
    [ON_FAILURE]: (state, action) => {
      state.batchShare.loading = false;
      state.batchShare.error = action.error;
    },
  },
  selectors: {
    status: (state) => state[name]?.batchShare,
    loading: (state) => state[name].batchShare.loading,
    error: (state) => state[name].batchShare.error,
  },
});

export default batchShareSlice;
