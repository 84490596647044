export enum Size {
  regular = 'regular',
  small = 'small',
}

export enum Colors {
  white = 'white',
  black = 'black',
  grey = 'grey.600',
  purple = 'primary.500',
}

export enum Type {
  full = 'Full',
  short = 'Short',
  poweredBy = 'PoweredBy',
}

export const SIZES_FULL = {
  regular: {
    width: '10.2rem',
    height: '4rem',
    viewBox: '0 0 102 72',
  },
  small: {
    width: '6.2rem',
    height: '2.4rem',
    viewBox: '0 0 102 40',
  },
};

export const SIZES_SHORT = {
  regular: {
    width: '3.25rem',
    height: '4rem',
    viewBox: '0 0 40 40',
  },
  small: {},
};

export const STYLE = {
  [Type.full]: { ...SIZES_FULL },
  [Type.short]: { ...SIZES_SHORT },
  [Type.poweredBy]: {},
};
