import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { MICardField, MICardTitle } from 'src/components/common/MICard';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import Flex from 'src/core/ds/flex';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { BillAttachment } from 'src/pages/bill/components/BillAttachment';
import { getBillAttachments } from 'src/pages/bill/components/utils';
import { useGetFileUrlsByFileId } from 'src/pages/bill/hooks/useGetFileUrlsByFileId';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { ScreenMode } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { BillType, QboBillAttachment, VendorType } from 'src/utils/types';
import { useFetchBillAccountCategoryOptions } from './BillDetailsForm/hooks/useFetchAccountCategoryOptions';
import { accountingSoftwareNameToIntlPath } from './BillDetailsForm/utils/accountingSoftwareNameToIntlPath';

type Props = {
  bill: BillType | BatchBillType;
  eventPage: string;
  totalAmountField?: React.ReactElement<{ value: number | string | null }>;
};

const BillDetails = ({ bill, eventPage, totalAmountField }: Props) => {
  const orgId = useSelector(getOrgId);
  const intl = useIntl();
  const [qboBillAttachments, setQboBillAttachments] = useState<QboBillAttachment[] | null>(null);
  const { files } = bill;
  const fileId = files?.[0]?.id;
  const { fileStorageUrl, filePreviewUrls } = useGetFileUrlsByFileId(fileId, bill.id);
  const isRPPSVendor = isRppsVendor(bill.vendor as VendorType);
  const {
    isConnected: isConnectedAccountingPlatform,
    connectedAccountingPlatform,
  } = usePayablesConnectedAccountingPlatform();
  const accountCategoriesOptions = useFetchBillAccountCategoryOptions();
  const accountCategory = accountCategoriesOptions.find((option) => option.value === bill.intuitAccountId);

  useEffect(() => {
    const loadQboBillAttachments = async () => {
      setQboBillAttachments(await getBillAttachments(bill.id, orgId));
    };
    loadQboBillAttachments();
  }, [bill.id, orgId]);

  return (
    <Flex justify="space-between">
      <Flex direction="column">
        <MICardTitle label="bills.view.title" />
        <MICardField label="bills.details.vendor">{bill.vendor?.companyName}</MICardField>
        {isRPPSVendor && (
          <MICardField label="bills.form.accountIdentifier">{bill.vendor?.accountIdentifier}</MICardField>
        )}
        {isConnectedAccountingPlatform && (
          <MICardField
            label="bills.form.externalAccount"
            labelValues={{
              accountingPlatform: intl.formatMessage({
                id: accountingSoftwareNameToIntlPath(connectedAccountingPlatform?.name),
              }),
            }}
          >
            <MIFieldOrEmpty value={accountCategory?.label} label="bills.form.externalAccountEmpty" type="text" />
          </MICardField>
        )}
        {totalAmountField || (
          <MICardField label="bills.form.BillOpenBalance">
            <MIFormattedCurrency value={convertCurrencyToNumber(bill.balance)} privateData />
          </MICardField>
        )}
        <MICardField label="bills.form.dueDate">
          <MIFieldOrEmpty value={bill.dueDate} label="bills.form.dueDateEmpty" type="date" />
        </MICardField>
        <MICardField label="bills.form.invoiceNumber">
          <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.form.invoiceNumberEmpty" type="text" />
        </MICardField>
        <MICardField label={bill.isPaymentRequest ? 'bills.form.noteFromVendor' : 'bills.form.description'}>
          <MIFieldOrEmpty value={bill.note} label="bills.form.descriptionEmpty" type="text" />
        </MICardField>
      </Flex>

      <Flex>
        <BillAttachment
          eventPage={eventPage}
          mode={ScreenMode.VIEW}
          fileStorageUrl={fileStorageUrl}
          filePreviewUrls={filePreviewUrls}
          qboBillAttachments={qboBillAttachments}
          withCounter
        />
      </Flex>
    </Flex>
  );
};

export { BillDetails };
