import { CheckboxMenu } from 'src/pages/pay/components/filters/components/CheckboxMenu';
import { VendorsMenu } from 'src/pages/pay/components/filters/components/VendorsMenu';
import { FilterMenuProps, SubmenuProps, ValueLabelPair } from 'src/pages/pay/components/filters/types';
import { tabToDueDateOptions, tabToItemStatusOptions } from 'src/pages/pay/components/filters/utils';
import { PayTabs } from 'src/utils/consts';
import { DueDateMenuKey, InboxStatusMenuKey } from 'src/utils/payListConsts';

export enum FilterMenuKey {
  ItemStatus = 'itemStatus',
  DueDate = 'dueDate',
  VendorId = 'vendorId',
}

export const MAX_SELECTED_VENDORS_COUNT = 20;

export const filterMenuKeys: FilterMenuKey[] = Object.values(FilterMenuKey);
export const itemStatusOption = {
  key: FilterMenuKey.ItemStatus,
  label: 'pay.filter.menu.options.itemStatus',
  render(props: SubmenuProps, { isAdminRole, currentTab, isBillInboxEnabled }: FilterMenuProps) {
    return (
      <CheckboxMenu {...props} options={tabToItemStatusOptions[currentTab]({ isAdminRole, isBillInboxEnabled })} />
    );
  },
} as const;
export const dueDateOption = {
  key: FilterMenuKey.DueDate,
  label: 'pay.filter.menu.options.dueDate',
  render(props: SubmenuProps, { currentTab }: FilterMenuProps) {
    return <CheckboxMenu {...props} options={tabToDueDateOptions[currentTab]()} />;
  },
} as const;
export const vendorsMenuOption = {
  key: FilterMenuKey.VendorId,
  label: 'pay.filter.menu.options.vendorId',
  render(props: SubmenuProps) {
    return <VendorsMenu {...props} />;
  },
};
export const inboxMenuOptions = [itemStatusOption, dueDateOption, vendorsMenuOption] as const;
export const scheduledMenuOptions = [itemStatusOption, vendorsMenuOption] as const;
export const paidMenuOptions = [itemStatusOption, vendorsMenuOption] as const;

export const filterMenuOptions = {
  [PayTabs.INBOX]: inboxMenuOptions,
  [PayTabs.SCHEDULED]: scheduledMenuOptions,
  [PayTabs.PAID]: paidMenuOptions,
} as const;

export const inboxStatusMenuOptionsMap = {
  [InboxStatusMenuKey.Failed]: { value: InboxStatusMenuKey.Failed, label: 'pay.filter.inboxStatusMenuOptions.failed' },
  [InboxStatusMenuKey.PendingApproval]: {
    value: InboxStatusMenuKey.PendingApproval,
    label: 'pay.filter.inboxStatusMenuOptions.pendingApproval',
  },
  [InboxStatusMenuKey.Declined]: {
    value: InboxStatusMenuKey.Declined,
    label: 'pay.filter.inboxStatusMenuOptions.declined',
  },
  [InboxStatusMenuKey.PaymentRequest]: {
    value: InboxStatusMenuKey.PaymentRequest,
    label: 'pay.filter.inboxStatusMenuOptions.paymentRequest',
  },
  [InboxStatusMenuKey.Unpaid]: { value: InboxStatusMenuKey.Unpaid, label: 'pay.filter.inboxStatusMenuOptions.unpaid' },
  [InboxStatusMenuKey.PayInbox]: {
    value: InboxStatusMenuKey.PayInbox,
    label: 'pay.filter.inboxStatusMenuOptions.payInbox',
  },
};
export const inboxStatusMenuKeys = [
  InboxStatusMenuKey.Failed,
  InboxStatusMenuKey.PendingApproval,
  InboxStatusMenuKey.Declined,
  InboxStatusMenuKey.PayInbox,
  InboxStatusMenuKey.PaymentRequest,
  InboxStatusMenuKey.Unpaid,
];

export const dueDateOptions = {
  [DueDateMenuKey.Overdue]: { label: 'pay.filter.dueDateOptions.overdue', value: DueDateMenuKey.Overdue },
  [DueDateMenuKey.DueNextWeek]: { label: 'pay.filter.dueDateOptions.dueNextWeek', value: DueDateMenuKey.DueNextWeek },
  [DueDateMenuKey.DueNextMonth]: {
    label: 'pay.filter.dueDateOptions.dueNextMonth',
    value: DueDateMenuKey.DueNextMonth,
  },
};
export const dueDateOrder = [DueDateMenuKey.Overdue, DueDateMenuKey.DueNextWeek, DueDateMenuKey.DueNextMonth];
export const inboxDueDateOptionsArray: ValueLabelPair[] = dueDateOrder.map((key) => dueDateOptions[key]);

enum ScheduledStatusMenuKey {
  Scheduled = 'scheduled',
  Pending = 'scheduledPending',
}
export const scheduledStatusMenuOptionsMap = {
  [ScheduledStatusMenuKey.Scheduled]: {
    value: ScheduledStatusMenuKey.Scheduled,
    label: 'pay.filter.scheduledStatusMenu.scheduled',
  },
  [ScheduledStatusMenuKey.Pending]: {
    value: ScheduledStatusMenuKey.Pending,
    label: 'pay.filter.scheduledStatusMenu.pending',
  },
};
export const scheduledStatusOrder = [ScheduledStatusMenuKey.Scheduled, ScheduledStatusMenuKey.Pending];
export const scheduledStatusOptionsArray: ValueLabelPair[] = scheduledStatusOrder.map(
  (key) => scheduledStatusMenuOptionsMap[key]
);

enum PaidStatusMenuKey {
  InProgress = 'inProgress',
  Completed = 'completed',
}

export const paidStatusMenuOptionsMap = {
  [PaidStatusMenuKey.InProgress]: {
    value: PaidStatusMenuKey.InProgress,
    label: 'pay.filter.paidStatusMenu.inprogress',
  },
  [PaidStatusMenuKey.Completed]: { value: PaidStatusMenuKey.Completed, label: 'pay.filter.paidStatusMenu.completed' },
};
export const paidStatusOrder = [PaidStatusMenuKey.InProgress, PaidStatusMenuKey.Completed];
export const paidStatusOptionsArray: ValueLabelPair[] = paidStatusOrder.map((key) => paidStatusMenuOptionsMap[key]);
