import { AreaLoader } from 'src/components/common/AreaLoader';
import { getJWTPayload } from 'src/helpers/jwt';
import { useFirstStep } from '../hooks/useFirstStep';
import { useStateAndActions } from '../hooks/useStateAndActions';
import { ShiftVendorSuvcToAchFlow } from '../ShiftVendorSuvcToAchFlow';
import { TokenValuesType } from '../types';

type Props = {
  token: string;
};

export const ShiftVendorSuvcToAchFromGuestWrapper = ({ token }: Props) => {
  const tokenValues = getJWTPayload<TokenValuesType>(token);
  const { firstStep, isLoading } = useFirstStep({ token });
  const suvcState = useStateAndActions({ token, tokenValues });

  if (isLoading || !firstStep) return <AreaLoader />;

  return <ShiftVendorSuvcToAchFlow suvcState={suvcState} firstStep={firstStep} tokenValues={tokenValues} />;
};
