import { createApiCallSlice } from 'src/helpers/redux/createApiCallSlice';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { name } from 'src/modules/accounting-platforms/consts';

type UpdateActiveRequest = {
  orgId?: number;
  isActive: boolean;
  id: number;
};

export const syncActivationSlice = createApiCallSlice<UpdateActiveRequest, void, void>({
  api: accountingPlatformsApi.updateSyncActivation,
  reducers: {},
  name: `[${name}] update sync activation`.toUpperCase(),
  selectors: {},
});
