import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Redirect, useLocation } from 'react-router-dom';
import { CodeVerificationPage } from 'src/components/layout/CodeVerificationPage';
import { useApi } from 'src/hoc/useApi';
import { authApi } from 'src/modules/auth/api';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { authLocations } from 'src/pages/auth/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { initUserAction } from 'src/redux/user/actions';
import { analytics } from 'src/services/analytics';
import guestApi from 'src/services/api/guests';
import { UserContextType } from 'src/utils/types';

type Props = {
  prevRoute: string;
};

export const CodeVerificationPageContainer = ({ prevRoute }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const [isCodeResend, setIsCodeResend] = useState(false);
  const [codeInputKey, setCodeInputKey] = useState(1);
  const analyticsData = { msnOptinFlow: true, flows: 'public' };

  const { navigate } = usePreservedStateNavigator();
  const dispatch = useDispatch();

  const location = useLocation<{ email: string }>();
  const email = location.state?.email ?? '';

  const { onApiCall: onResolveEmailVerificationCall, loading: isResolveEmailVerificationLoading } = useApi({
    api: authApi.vendorResolveEmailVerification,
  });

  const { onApiCall: onAuthCheckCall, loading: isAuthCheckLoading } = useApi<[], Partial<{ user: UserContextType }>>({
    api: authApi.check,
  });

  const { onApiCall: onSendEmailVerificationCodeCall, loading: isSendEmailVerificationCodeLoading } = useApi({
    api: guestApi.sendEmailVerificationCode,
  });

  const initUser = useCallback(
    async (user: UserContextType) =>
      new Promise((resolve, reject) => {
        dispatch(initUserAction(user, false, resolve, reject));
      }),
    [dispatch]
  );

  useEffect(() => {
    setIsLoading(isResolveEmailVerificationLoading || isAuthCheckLoading || isSendEmailVerificationCodeLoading);
  }, [isResolveEmailVerificationLoading, isAuthCheckLoading, isSendEmailVerificationCodeLoading]);

  const onPrev = ({ back }: { back: boolean }) => {
    if (back) {
      analytics.trackAction('msnPortalEmailVerification.back', analyticsData);
    } else {
      analytics.trackAction('msnPortalEmailVerification.email.change', analyticsData);
    }

    navigate(prevRoute, true, { email });
  };

  const resolveEmailVerification = async (code: number) => {
    try {
      await onResolveEmailVerificationCall({
        email,
        code,
        fallbackUtms: '',
        fallbackTams: '',
        registrationFlow: 'msn-portal-public-form',
      });
      const { user } = await onAuthCheckCall();

      if (user) {
        analytics.trackAction('msnPortalEmailVerification.code.correct', { ...analyticsData, isGuest: user.isGuest });
        await initUser(user);
        analytics.trackAction('msnPortalEmailVerification.continue.success', {
          ...analyticsData,
          isGuest: user.isGuest,
        });
        const url = user.isGuest
          ? generatePath(onboardingLocations.msnRegistration.msnVerificationStatusCheck, {
              orgId: user.organizations[0].id,
            })
          : onboardingLocations.msnRegistration.existingOrganizations;
        navigate(url, false);
      }
    } catch (error: any) {
      analytics.trackAction('msnPortalEmailVerification.code.incorrect', analyticsData);
      setValidationErrors(error);
    }
  };

  const onCodeChange = (code: string) => {
    if (code.length === 4) {
      resolveEmailVerification(Number(code));
    }

    if (code.length === 0) {
      setValidationErrors({ validationErrors: {} });
    }
  };

  const sendEmailVerificationCode = async () => {
    analytics.trackAction('msnPortalEmailVerification.code.resend', analyticsData);
    await onSendEmailVerificationCodeCall(email);
    setValidationErrors({});
    setIsCodeResend(true);
    setCodeInputKey((codeInputKey) => codeInputKey + 1);
  };

  if (!email) {
    return <Redirect to={authLocations.login} />;
  }

  return (
    <CodeVerificationPage
      email={email}
      onPrev={() => onPrev({ back: true })}
      isLoading={isLoading}
      errorCode={validationErrors.code}
      onChange={onCodeChange}
      onChangeEmail={() => onPrev({ back: false })}
      sendEmailVerificationCode={sendEmailVerificationCode}
      isCodeResend={isCodeResend}
      codeInputKey={codeInputKey}
    />
  );
};
