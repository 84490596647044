export const PAGE_EVENT = 'auth';

export enum AuthCodeVerificationStatus {
  PENDING = 'pending',
  VERIFIED = 'verified',
  INVALID = 'invalid',
  MAX_ATTEMPTS = 'max-attempts',
  BLOCKED = 'blocked',
}

export const mapAuthFailedCodeVerificationErrors = {
  [AuthCodeVerificationStatus.INVALID]: 'guests.emailVerify.2FA.invalidCodeError',
  [AuthCodeVerificationStatus.MAX_ATTEMPTS]: 'guests.emailVerify.2FA.maxAttemptsError',
  [AuthCodeVerificationStatus.BLOCKED]: 'guests.emailVerify.2FA.blockedError',
};

export const DOB_DEFAULTS = {
  DATE: '1990-01-01',
  MIN_AGE: 18,
  MAX_AGE: 120,
};
