import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useSiteContext } from 'src/hoc/withSiteContext';
import errorImage from 'src/images/general/error-screen.svg';
import canceledImage from 'src/images/outside/canceled.png';
import expiredImage from 'src/images/outside/expired.png';
import invalidImage from 'src/images/outside/invalid.png';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { AnalyticPropsInState } from 'src/pages/vendor/components/InvalidTokenPage';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';
import { devices } from 'src/theme/appDevices';
import { PaymentStatus } from 'src/utils/consts';

type Props = {
  token: string;
  hideLogin?: boolean;
};

export const DefaultErrorPage = ({ token, hideLogin }: Props) => {
  const site = useSiteContext();
  const { paymentId } = getJWTPayload(token);
  const { errorData } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const location = useLocation<AnalyticPropsInState>();
  const { analyticsProps } = location.state ?? {};
  const error = errorData || invalidTokenData;
  const isPaymentProcessedError =
    payment?.status === PaymentStatus.COMPLETED || payment?.status === PaymentStatus.FAILED;
  let companyName = '';
  let email = '';

  if (error && error.data) {
    ({ companyName, email } = error.data);
  }

  useEffect(() => {
    if (error && analyticsProps?.category) {
      analytics.page(analyticsProps.category, error);
    }
  }, [error, analyticsProps]);

  const openSupportChat = () => {
    intercomService.show();
  };

  const chatWithAs = <ChatLink to={openSupportChat} label="vendors.deliveryMethods.shiftToDebit.chatWithUs" />;

  const onEmailClick = (email) => () => {
    analytics.trackAction('support-email');
    window.open(`mailto:${email}`, '_blank');
  };

  const renderIcon = (error) => {
    switch (error?.code) {
      case 'UNP5':
      case 'PMT18':
      case 'PMT19':
        return expiredImage;
      case 'UNP1':
      case 'PMT1':
        return invalidImage;
      case 'UNP4':
        return canceledImage;
      default:
        return errorImage;
    }
  };

  return (
    <OutsideLayout hideLogin={hideLogin}>
      <InvalidUnilateralLinkContainer>
        <ErrorImage src={isPaymentProcessedError ? expiredImage : renderIcon(error)} alt="error" />
        <Subtitle>
          {error && (
            <MIFormattedText
              label={`server.${error.code}`}
              values={{
                companyName,
                email: (
                  <EmailLink onClick={onEmailClick(email)} href="#">
                    {email}
                  </EmailLink>
                ),
                supportEmail: (
                  <EmailLink onClick={onEmailClick(site.config.support.email)} href="#">
                    {site.config.support.email}
                  </EmailLink>
                ),
                chatWithUs: chatWithAs,
              }}
            />
          )}
          {!error && isPaymentProcessedError && (
            <MIFormattedText
              label="vendors.deliveryMethods.shiftToDebit.paymentCompletedError"
              values={{ chatWithUs: chatWithAs }}
            />
          )}
        </Subtitle>
      </InvalidUnilateralLinkContainer>
    </OutsideLayout>
  );
};

const InvalidUnilateralLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 4rem;

  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

const Subtitle = styled.div`
  font-size: ${(props) => props.theme.text.size.regular};
  line-height: 2.4rem;
`;

const ErrorImage = styled.img`
  width: 13rem;
`;

const EmailLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary.opaque};
`;

const ChatLink = styled(MILink)`
  color: ${(props) => props.theme.colors.primary.opaque};
  text-transform: none;
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.regular};
`;
