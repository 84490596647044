import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateUserPreferenceAction } from 'src/redux/user/actions';
import { UserPreferencesType } from 'src/utils/types';

type UpdateUserPreferenceParams = {
  key: keyof UserPreferencesType;
  value: string | boolean;
};

const useUpdateUserPreference = () => {
  const dispatch = useDispatch();
  const [isUpdatingUserPreference, setIsUpdatingUserPreference] = useState(false);

  const updateUserPreference = useCallback(
    async ({ key, value }: UpdateUserPreferenceParams) => {
      setIsUpdatingUserPreference(true);

      return new Promise((resolve, reject) => {
        dispatch(updateUserPreferenceAction(key, value, resolve, reject));
      }).finally(() => {
        setIsUpdatingUserPreference(false);
      });
    },
    [dispatch]
  );

  return { updateUserPreference, isUpdatingUserPreference };
};

export default useUpdateUserPreference;
