import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { vendorLocations } from 'src/pages/vendor/locations';
import { DeliveryType } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { PaymentType } from 'src/utils/types';
import { STEPS } from '../consts';
import { getPaymentStatuses } from '../utils';

export type FirstStepReturnType = {
  isLoading: boolean;
  firstStep: null | STEPS.INFO | STEPS.CONFIRMATION | STEPS.FORM;
};

function calculateFirstStep(payment: PaymentType) {
  if (!payment) return null;

  const {
    isShiftToAchRequested,
    isPaymentVirtualCardCleared,
    isPaymentCompleted,
    isVirtualCardCanceled,
  } = getPaymentStatuses(payment);
  const shouldShowPaymentInfoPage =
    isVirtualCardCanceled || isShiftToAchRequested || isPaymentVirtualCardCleared || isPaymentCompleted;

  const vendorDeliveryMethods = payment?.vendor?.deliveryMethods;
  const isAchMethodAvailable = vendorDeliveryMethods?.some((method) => method.deliveryType === DeliveryType.ACH);

  if (shouldShowPaymentInfoPage) {
    return STEPS.INFO;
  }

  if (!isAchMethodAvailable) {
    return STEPS.FORM;
  }

  return STEPS.CONFIRMATION;
}

type UseFirstStepProps = {
  token: string;
};

export const useFirstStep = ({ token }: UseFirstStepProps): FirstStepReturnType => {
  const history = useHistory();
  const paymentActions = useStoreActions(paymentsStore);
  const { onApiCall: fetchPaymentDetailsWithToken, loading: isLoading, error: isError, result } = useApi<
    [{ token; action }],
    { payload: { payment: PaymentType } }
  >({
    api: paymentActions.fetchPaymentDetailsWithToken,
  });

  if (isError) {
    history.push(generatePath(vendorLocations.shiftVendorToAch.invalidToken, { token }));
  }

  useEffect(() => {
    fetchPaymentDetailsWithToken({ token, action: 'unilateralPayment' }).catch((err) => {
      capture(err, {
        message: `Can not fetch payment details using token: ${token}`,
      });
    });
  }, [token, fetchPaymentDetailsWithToken]);

  return {
    isLoading,
    firstStep: calculateFirstStep(result?.payload?.payment as PaymentType),
  };
};
