import { useMemo } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { BlockTitleContainer } from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';
import { devices } from 'src/theme/appDevices';
import { InstallmentStatus } from 'src/utils/consts';
import { convertNumberToCurrency } from 'src/utils/currency-utils';
import { getCurrentFailedInstallment, isFailedInstallment } from 'src/utils/financing';
import { AccountType, BillType, Override, PaymentFinancing, PaymentType } from 'src/utils/types';
import { InstallmentDeductionDateInfo } from '../PayBillPaymentActivity/components/InstallmentDeductionDateInfo';
import { PayBillPaymentActivityFundingSourceInfo } from '../PayBillPaymentActivity/components/PayBillPaymentActivityFundingSourceInfo';
import { RepaymentCreditKey } from '../PayBillPaymentActivity/components/RepaymentCreditKey';

type Props = {
  bill: BillType;
  fundingSource: AccountType;
  payment: Override<PaymentType, { financing: PaymentFinancing }>;
  isEditDisabled: boolean;
};

export const RepaymentReview = ({ bill, fundingSource, payment, isEditDisabled }: Props) => {
  const failedInstallment = getCurrentFailedInstallment(payment.financing.installments);
  const financing = useMemo(
    () => ({
      ...payment.financing,
      installments: payment.financing.installments.map((installment) =>
        isFailedInstallment(installment) ? { ...installment, status: InstallmentStatus.Retry } : installment
      ),
    }),
    [payment]
  );

  if (!failedInstallment) return null;

  return (
    <MainBox className="review" data-testid="repayment-container">
      <BaseContainer>
        <HighlightedHeader>
          <SubTitle data-testid="installment-amount-title">
            <MIFormattedText
              label="bills.pay.confirm.repayment.installmentAmountTitle"
              values={{
                index: failedInstallment.installmentNumber,
                totalInstallments: payment.financing.installments.length,
              }}
            />
          </SubTitle>

          <AmountContainer data-testid="amount-container">
            <StyledMIMoney size="large" amount={failedInstallment.amount} privateData />
          </AmountContainer>

          <Box data-testid="installment-amount-subtitle" textStyle="body3">
            <MIFormattedText
              label="bills.pay.confirm.repayment.paymentAmountSubtitle"
              values={{
                amount: convertNumberToCurrency(payment.amount),
                vendorName: payment.vendor?.companyName,
              }}
            />
          </Box>
        </HighlightedHeader>
      </BaseContainer>

      <Box w="full" boxSizing="border-box" pt={5} ps={5}>
        <BlockTitleContainer>
          <MIFormattedText label="bills.pay.confirm.repayment.payByTitle" />
        </BlockTitleContainer>
        <PayBillPaymentActivityFundingSourceInfo
          isEditDisabled={isEditDisabled}
          bill={bill}
          payment={payment}
          fundingSource={fundingSource}
        />
        <InstallmentDeductionDateInfo financing={financing} isEditDisabled={isEditDisabled} />
      </Box>

      <PaymentActivityContainer>
        <RepaymentCreditKey />
      </PaymentActivityContainer>
    </MainBox>
  );
};

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.MainBox}
`;

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const PaymentActivityContainer = styled(BaseContainer)`
  padding-bottom: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentActivityContainer}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 1.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;
