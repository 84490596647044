import { MenuList, useMenuOptionGroup } from 'src/core/ds/menu';
import { ValueLabelPair } from 'src/pages/pay/components/filters/types';
import { MenuItemCheckbox } from './MenuItemCheckbox';

export type Value = string[];
export type OnChange = (value: string[]) => void;

type BaseProps = {
  selected: Value;
  onChange: OnChange;
};

type Props = BaseProps & {
  options: ValueLabelPair[];
};

export const useCheckboxMenu = ({ selected = [], options, onChange: parentOnChange }: Props) => {
  const items = options.map(({ value, label }) => <MenuItemCheckbox value={value} label={label} />);
  const onChange = (value: string[] | string) => parentOnChange(([] as string[]).concat(value));
  const { children } = useMenuOptionGroup({ value: selected, onChange, children: items, type: 'checkbox' });

  return children;
};

export const CheckboxMenu = (props: Props) => {
  const children = useCheckboxMenu(props);

  return <MenuList>{children}</MenuList>;
};
