import { useSelector } from 'react-redux';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { PayBillFlowType, usePayBillFlowType } from './usePayBillFlowType';

export const useFinancedPaymentRecoveryFlows = () => {
  const payment = useSelector(getPayment);
  const payBillFlowType = usePayBillFlowType();

  const isFinancedPaymentRecoveryFlow =
    payment &&
    payment.isFinanced &&
    (payBillFlowType === PayBillFlowType.RetryFailedToDeliver ||
      payBillFlowType === PayBillFlowType.CancelAndRetryPaymentFlow);

  return { isFinancedPaymentRecoveryFlow };
};
