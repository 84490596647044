import * as React from 'react';
import { Button, ButtonVariants } from 'src/core/ds/button';

type Props = {
  isDisabled?: boolean;
  loading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  submitAnalyticsProperties?: Record<string, any>;
};

const BatchPaymentRequestsActions: React.FC<Props> = ({
  isDisabled,
  loading,
  onSubmit,
  onCancel,
  submitAnalyticsProperties = {},
}) => (
  <>
    <Button mr={4} variant={ButtonVariants.tertiary} onClick={onCancel} label="getPro.cards.actions.cancel" />

    <Button
      isLoading={loading}
      isDisabled={isDisabled}
      variant={ButtonVariants.success}
      onClick={onSubmit}
      type="submit"
      label="getPro.cards.actions.send"
      analyticsProperties={submitAnalyticsProperties}
    />
  </>
);

export default BatchPaymentRequestsActions;
