import React from 'react';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { CardThemes } from 'src/pages/bill/components/ViewBillPaymentActivity/ViewBillPaymentActivity';

type Props = {
  theme?: CardThemes;
  paymentFailedDate?: Date;
};

export const InstallmentsFundingSourceInfo = ({ theme, paymentFailedDate }: Props) => (
  <PaymentActivityContainer>
    <PaymentActivityMainContainer>
      <PaymentInfoContainer>
        <PaymentIcon isBillPaid={theme === CardThemes.Grey}>
          <Icon name={IconNames.installments} size={IconSize.lg} />
        </PaymentIcon>
        <PaymentTextContainer>
          <PaymentMethodContainer>
            <PaymentMethodText data-testid="installments-view-payment-method">
              <MIFormattedText label="viewBillPaymentActivity.fundingSource.installmentsLabel" />
            </PaymentMethodText>
          </PaymentMethodContainer>
          <PaymentInfoText data-testid="installments-view-payment-info-text" isBillPaid={theme === CardThemes.Grey}>
            <MIFormattedText label="viewBillPaymentActivity.fundingSource.installmentsDescription" />
          </PaymentInfoText>
          {paymentFailedDate ? <FailedPaymentDate paymentFailedDate={paymentFailedDate} /> : null}
        </PaymentTextContainer>
      </PaymentInfoContainer>
    </PaymentActivityMainContainer>
  </PaymentActivityContainer>
);

type FailedPaymentDateProps = {
  paymentFailedDate: Date;
};

const FailedPaymentDate = ({ paymentFailedDate }: FailedPaymentDateProps) => (
  <Box textStyle="body4" color="red.500" data-testid="payment-failed-date">
    <MIFormattedText
      label="viewBillPaymentActivity.fundingSource.paymentFailedDatePrefix"
      values={{
        paymentFailedDate: <MIFormattedDate date={paymentFailedDate} />,
      }}
    />
  </Box>
);
