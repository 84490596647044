import noop from 'lodash/noop';
import once from 'lodash/once';
import * as React from 'react';
import { connect } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { FileInput } from 'src/core/ds/input';
import { filesApi } from 'src/modules/files/api';
import { GlobalState } from 'src/redux/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { SingleSelectFlavor } from 'src/utils/consts';
import { BillCreateOptionsType, FetchFileResultType } from 'src/utils/types';
import dragBillImage from '../../../images/bills/drag-bill.svg';

type MapStateToProps = {
  orgId: number;
};

type Props = {
  orgId: number;
  fileId?: number | string;
  flavor?: 'block' | 'inline';
  allowedTypes?: string[];
  onAddAnother?: () => void;
  onRemove?: (fileId?: number | string) => void;
  options?: BillCreateOptionsType[];
  onChangeAttachment: (file: File, loadBillFromAttachment?: boolean) => void;
} & MapStateToProps;

type State = {
  attachmentURL: string;
};

const eventPage = 'bill-create';

class BillCreateOptionsBase extends React.PureComponent<Props, State> {
  static defaultProps: Partial<Props> = {
    fileId: undefined,
    flavor: 'block',
    allowedTypes: ['image/png', 'image/jpeg', 'application/pdf'],
    options: [],
    onAddAnother: () => noop(),
    onRemove: () => noop(),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      attachmentURL: '',
    };
    this.fileInputRef = React.createRef();
    this.scanFileInputRef = React.createRef();
  }

  componentDidMount() {
    this.fetchFile();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.fileId !== prevProps.fileId) {
      this.fetchFile();
    }
  }

  onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
        const item = event.dataTransfer.items[i];

        if (item.kind === 'file') {
          this.handleFile(item.getAsFile());
        }
      }
    }
  };
  onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  onChangeInputFile = ({ currentTarget: target }: React.SyntheticEvent<HTMLInputElement>) => {
    if (target.files) {
      analytics.track(eventPage, 'file-file-selected');

      for (let i = 0; i < target.files.length; i += 1) {
        this.handleFile(target.files[i]);
      }
    }
  };

  fetchFile() {
    if (this.props.fileId !== undefined) {
      filesApi.fetchFileUrls(this.props.orgId, this.props.fileId).then(this.handleFileFetched);
    }
  }

  handleFile = (file: File | null) => {
    if (file && (this.props.allowedTypes || []).indexOf(file.type) > -1) {
      this.props.onChangeAttachment(file, true);
    }
  };

  handleFileFetched = (data: FetchFileResultType) => {
    this.setState({
      attachmentURL: data.fileStorageUrl,
    });
  };
  handleAddAnother = () => this.props.onAddAnother && this.props.onAddAnother();
  handleRemove = () => this.props.onRemove && this.props.onRemove(this.props.fileId);
  handleAttachmentClick = () => {
    window.open(this.state.attachmentURL);
  };
  // TODO: this is a workaround, because, for some reason, each click on Camera or File button
  // generated two click events
  doOnceEvent = once((isScan?: boolean) =>
    isScan ? analytics.track(eventPage, 'scan-image-button') : analytics.track(eventPage, 'import-from-file-button')
  );
  triggerFileInput = () => {
    this.doOnceEvent();

    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }
  };
  triggerScanFileInput = () => {
    this.doOnceEvent(true);

    if (this.scanFileInputRef.current) {
      this.scanFileInputRef.current.click();
    }
  };
  fileInputRef: {
    current: HTMLInputElement | null;
  };
  scanFileInputRef: {
    current: HTMLInputElement | null;
  };

  renderBillCreateOptions() {
    const { allowedTypes, options } = this.props;

    return (
      <BillCreateOptionsContainer>
        {options &&
          options.map(({ id, icon, imgSrc, label, description, click }) =>
            click ? (
              <BillCreateOptionsWrapper key={id} onClick={click} data-testid={`bill-create-option-${label}`}>
                {imgSrc && <Image w="3.2rem" h="3.2rem" src={imgSrc} />}
                {icon && <Icon name={IconNames[icon]} size={IconSize.xl} />}
                <BillCreateOptionsTextBlock>
                  <LabelText>
                    <MIFormattedText label={label} />
                  </LabelText>
                  <DescriptionText>
                    <MIFormattedText label={description} />
                  </DescriptionText>
                </BillCreateOptionsTextBlock>
              </BillCreateOptionsWrapper>
            ) : (
              <BillCreateOptionsWrapper
                key={id}
                onDrop={this.onDrop}
                onDragEnter={this.onDragEnter}
                onDragOver={this.onDragOver}
                onClick={id === 'scan-bill' ? this.triggerScanFileInput : this.triggerFileInput}
                isHidden={id === 'import-bill-file'}
              >
                {imgSrc && <Image w="3.2rem" h="3.2rem" src={imgSrc} />}
                {icon && <Icon name={IconNames[icon]} size={IconSize.xl} />}
                <BillCreateOptionsTextBlock>
                  <LabelText>
                    <MIFormattedText label={label} />
                  </LabelText>
                  <DescriptionText>
                    <MIFormattedText label={description} />
                  </DescriptionText>
                </BillCreateOptionsTextBlock>
                <FileInput
                  ref={id === 'scan-bill' ? this.scanFileInputRef : this.fileInputRef}
                  onChange={this.onChangeInputFile}
                  type="file"
                  accept={id === 'scan-bill' ? 'image/*' : (allowedTypes || []).join(', ')}
                />
              </BillCreateOptionsWrapper>
            )
          )}
      </BillCreateOptionsContainer>
    );
  }

  renderDropBillCreateOption() {
    const { flavor, allowedTypes } = this.props;

    return (
      <>
        <DragAndDropWrapper
          withUpload
          flavor={flavor}
          onDrop={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragOver={this.onDragOver}
          onClick={this.triggerFileInput}
        >
          <Image src={dragBillImage} alt="bills.new.dragAndDrop" />
          <Box as="span" sx={NoDragDropStyle}>
            <MIFormattedText label="bills.new.dragAndDropMobile" />
          </Box>

          <Box as="span" sx={OkDragDropStyle}>
            <MIFormattedText
              label="bills.new.dragAndDrop"
              values={{
                browse: (
                  <BrowseWrapper>
                    <MIFormattedText label="bills.new.browse" />
                  </BrowseWrapper>
                ),
              }}
            />
          </Box>
        </DragAndDropWrapper>
        <FileInput
          ref={this.fileInputRef}
          onChange={this.onChangeInputFile}
          type="file"
          accept={(allowedTypes || []).join(', ')}
        />
      </>
    );
  }

  renderAttachment() {
    const { fileId, flavor } = this.props;
    const { attachmentURL } = this.state;

    return (
      <DragAndDropWrapper withAttachment fileId={fileId} flavor={flavor}>
        <Attachment src={attachmentURL} onClick={this.handleAttachmentClick} />
        <ActionButtons>
          <AddAnother onClick={this.handleAddAnother}>
            <MIFormattedText label="bills.form.attachment.addAnother" />
          </AddAnother>
          <Remove onClick={this.handleRemove}>
            <MIFormattedText label="bills.form.attachment.remove" />
          </Remove>
        </ActionButtons>
      </DragAndDropWrapper>
    );
  }

  render() {
    const { fileId, options } = this.props;

    if (fileId !== undefined) {
      return this.renderAttachment();
    } else if (options && options.length) {
      return this.renderBillCreateOptions();
    }

    return this.renderDropBillCreateOption();
  }
}

type BillCreateOptionsContainerProps = {
  children?: React.ReactNode;
};

const BillCreateOptionsContainer = ({ children }: BillCreateOptionsContainerProps) => (
  <Flex sx={BillCreateOptionsContainerStyle}>{children}</Flex>
);

type BillCreateOptionsWrapperProps = {
  children?: React.ReactNode;
  'data-testid'?: string;
  isHidden?: boolean;
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
  onClick?: () => void;
};

const BillCreateOptionsWrapper = ({ children, isHidden, ...rest }: BillCreateOptionsWrapperProps) => (
  <Flex hidden={isHidden} sx={BillCreateOptionsWrapperStyle} {...rest}>
    {children}
  </Flex>
);

type BillCreateOptionsTextBlockProps = BillCreateOptionsContainerProps;

const BillCreateOptionsTextBlock = ({ children }: BillCreateOptionsTextBlockProps) => (
  <Flex direction="column" ml={4}>
    {children}
  </Flex>
);

type LabelTextProps = BillCreateOptionsContainerProps;

const LabelText = ({ children }: LabelTextProps) => <Box textStyle="body2Semi">{children}</Box>;

type DescriptionTextProps = BillCreateOptionsContainerProps;

const DescriptionText = ({ children }: DescriptionTextProps) => <Box sx={DescriptionTextStyle}>{children}</Box>;

type DragAndDropWrapperProps = {
  children?: React.ReactNode;
  flavor?: 'block' | 'inline';
  withUpload?: boolean;
  withAttachment?: boolean;
  withProgress?: boolean;
  fileId?: number | string;
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragEnter?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
  onClick?: () => void;
};

const DragAndDropWrapper = ({
  children,
  flavor,
  withUpload,
  withAttachment,
  withProgress,
  fileId,
  ...rest
}: DragAndDropWrapperProps) => (
  <Flex
    sx={DragAndDropWrapperStyle({
      flavor,
      withUpload,
      withAttachment,
      withProgress,
      fileId,
    })}
    {...rest}
  >
    {children}
  </Flex>
);

type BrowseWrapperProps = {
  children?: React.ReactNode;
};

const BrowseWrapper = ({ children }: BrowseWrapperProps) => (
  <Box as="span" textStyle="link1">
    {children}
  </Box>
);

type AttachmentProps = {
  src: string;
  onClick: () => void;
};

const Attachment = ({ ...rest }: AttachmentProps) => <Image sx={AttachmentStyle} {...rest} />;

type ActionButtonsProps = {
  children?: React.ReactNode;
};

const ActionButtons = ({ children }: ActionButtonsProps) => <Box sx={ActionButtonsStyle}>{children}</Box>;

type AddAnotherProps = {
  children?: React.ReactNode;
  onClick?: () => void;
};

const AddAnother = ({ children, onClick }: AddAnotherProps) => (
  <Box mr={3} onClick={onClick}>
    {children}
  </Box>
);

type RemoveProps = {
  children?: React.ReactNode;
  onClick?: () => void;
};

const Remove = ({ children, onClick }: RemoveProps) => (
  <Box color="red.500" onClick={onClick}>
    {children}
  </Box>
);

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  orgId: getOrgId(state),
});

export const BillCreateOptions = connect(mapStateToProps)(BillCreateOptionsBase);

type DragAndDropWrapperStyleArgs = {
  flavor?: 'block' | 'inline';
  withAttachment?: boolean;
  withUpload?: boolean;
  withProgress?: boolean;
  fileId?: string | number;
};

const DragAndDropWrapperStyle = (configs: DragAndDropWrapperStyleArgs) => {
  const isInline = !configs.flavor || configs.flavor === SingleSelectFlavor.INLINE;

  return {
    pos: 'relative',
    w: 'full',
    h: 'full',
    flexDir: 'column',
    justifyContent: 'center',
    boxSizing: 'border-box',
    textStyle: configs.withProgress ? 'body4' : 'body2',
    color: 'grey.600',
    backgroundColor: configs.withAttachment ? 'white' : 'transparent',
    textAlign: configs.withAttachment ? 'left' : 'center',
    cursor: configs.withUpload ? 'pointer' : 'default',
    px: { base: 0, md: isInline ? 6 : 12 },
    pt: { base: 0, md: isInline ? 6 : 12 },
    pb: { base: 0, md: isInline ? 6 : 14 },
    i: {
      display: 'inline-block',
      verticalAlign: 'middle',
      textStyle: 'body1',
      mr: 2,
    },
    img: {
      w: '3.5rem',
      h: '3.9rem',
      mx: 'auto',
      my: 0,
    },
  };
};

const OkDragDropStyle = {
  textStyle: 'body2',
  mt: 6,
  display: { base: 'none', md: 'inline' },
};

const NoDragDropStyle = {
  display: { base: 'inline', md: 'none' },
  pt: { base: 2, md: 0 },
};

const AttachmentStyle = {
  maxWidth: '30%',
  maxHeight: '14.8rem',
  cursor: 'pointer',
};

const ActionButtonsStyle = {
  pos: 'absolute',
  top: 6,
  right: 6,
  w: 'full',
  textAlign: 'right',
  div: {
    cursor: 'pointer',
    display: 'inline-block',
    textTransform: 'uppercase',
    letterSpacing: 'px',
    textStyle: 'body3Semi',
  },
};

const DescriptionTextStyle = {
  textStyle: 'body4',
  textOverflow: 'ellipsis',
  color: 'grey.600',
};

const BillCreateOptionsWrapperStyle = {
  alignItems: 'center',
  w: 'full',
  maxW: '45rem',
  borderRadius: 'lg',
  py: 8,
  px: 6,
  boxSizing: 'border-box',
  cursor: 'pointer',
  backgroundColor: 'white',
  mb: 4,
  transitionProperty: 'box-shadow',
  transitionDuration: '300ms',
  boxShadow: 500,
  _hover: {
    boxShadow: 600,
  },
  _active: {
    boxShadow: 500,
  },
};

const BillCreateOptionsContainerStyle = {
  flexDir: 'column',
  justifyContent: 'center',
  mb: 5,
  alignItems: 'center',
};
