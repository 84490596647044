import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

export const PaymentFeeHeader = () => (
  <>
    <Box textStyle="body4Semi" textTransform="uppercase" color="grey.700" data-testid="fee-header">
      <MIFormattedText label="bills.pay.confirm.fee" />
    </Box>
  </>
);
