import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { VStack } from 'src/core/ds/stack';

export const InstallEmptyState = ({
  onInstallExtensionClick,
  onAddCardClick,
}: {
  onInstallExtensionClick: () => void;
  onAddCardClick: () => void;
}) => (
  <VStack justifyContent="center" alignItems="center" w="full" data-testid="spend-management-cards-empty-state">
    <Flex direction="column" alignItems="center" justifyContent="center" maxW="48rem">
      <Box mb={4} textAlign="center">
        <video
          data-component="Video"
          autoPlay
          src="https://platform-static.meliopayments.com/assets/melio/illustrations/add-card.webm"
          loop
          width="120px"
        >
          <track kind="captions" />
        </video>
      </Box>
      <Box textAlign="center" textStyle="body2Semi">
        <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.installExtension.title" />
      </Box>
      <Box mb={4} textAlign="center" textStyle="body3">
        <MIFormattedText label="spendManagement.pages.dashboard.emptyStates.installExtension.subtitle" />
      </Box>
      <Flex justifyContent="center" w="full" gap={4}>
        <Button
          label="spendManagement.pages.dashboard.emptyStates.installExtension.secondaryButtonLabel"
          variant={ButtonVariants.tertiary}
          onClick={onInstallExtensionClick}
          size={ButtonSizes.lg}
          isFullWidth
        />
        <Button
          label="spendManagement.pages.dashboard.emptyStates.installExtension.buttonLabel"
          variant={ButtonVariants.primary}
          leftIcon={<Icon name={IconNames.plus} size={IconSize.s} />}
          onClick={onAddCardClick}
          size={ButtonSizes.lg}
          isFullWidth
        />
      </Flex>
    </Flex>
  </VStack>
);
