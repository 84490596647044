import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type Props = {
  amount: number;
  vendorName: string;
};

const BillDetailsItem: React.FC<Props> = ({ amount, vendorName }) => (
  <Flex textStyle="body3Semi" justify="space-between">
    <Box>{vendorName}</Box>
    <Box>
      <FormattedNumber value={Number(amount)} maximumFractionDigits={2} style="currency" currency="USD" />
    </Box>
  </Flex>
);

export default BillDetailsItem;
