import { ReactNode } from 'react';
import { UnorderedList } from 'src/core/ds/list';

type AccountingFirmContainerProps = {
  children?: ReactNode;
  emptyState?: boolean;
};

export const AccountingFirmContainer = ({ emptyState, children }: AccountingFirmContainerProps) => (
  <UnorderedList
    zIndex={1}
    pt={3}
    pb={2}
    pl={0}
    ml={0}
    mt={0}
    mb={emptyState ? 0 : 4}
    borderBottom={emptyState ? 'none' : '1px'}
    borderBottomColor="grey.400"
  >
    {children}
  </UnorderedList>
);
