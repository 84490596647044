export const tabsContainerStyle = {
  paddingBottom: 4,
  '>div': {
    '&:first-of-type': {
      borderLeft: 'none',
      borderTopLeftRadius: 'lg',
    },
    '&:last-of-type': {
      borderTopRightRadius: 'lg',
    },
  },
};

export const boxStyle = {
  outline: 0,
  boxShadow: '0px -1px 0px 0px #EEEEEE inset',
  borderLeft: '1px',
  borderLeftColor: 'grey.300',
  bg: 'grey.200',
  px: 6,
  py: 4,
  _hover: {
    cursor: 'pointer',
  },
  '&[data-selected=true]': {
    outline: 0,
    boxShadow: '0px -2px 0px 0px #000000 inset',
    bg: 'white',
  },
  flex: '1',
  h: 8,
};

export const boxTitleStyle = {
  color: 'grey.700',
  '&[data-selected=true]': {
    color: 'black',
  },
  _groupHover: {
    color: 'black',
  },
};
