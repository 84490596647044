import { createSlice } from '@reduxjs/toolkit';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { filesApi } from '../files/api';
import { createWizard, name } from './constants';
import { BillDataResult, getExtractedBillData } from './utils';

type FileUploadState = {
  fileUpload: Record<
    string,
    {
      loading: boolean;
      error: boolean;
      fileData: {
        fileStorageUrl: string;
        filePreviewUrls: string[];
        fileId: string;
      };
      billData: BillDataResult;
    }
  >;
};

const initialState = {
  fileUpload: {
    [createWizard]: {
      loading: false,
      error: false,
      fileData: {
        fileStorageUrl: '',
        filePreviewUrls: [],
        fileId: '',
      },
      billData: {},
    },
  },
};

const clearFileUploadSlice = createSlice({
  name: generateSliceName(name, 'clear_file_upload'),
  initialState,
  reducers: {
    clear(state) {
      state.fileUpload[createWizard].loading = false;
      state.fileUpload[createWizard].error = false;
      state.fileUpload[createWizard].fileData.fileId = '';
      state.fileUpload[createWizard].fileData.fileStorageUrl = '';
      state.fileUpload[createWizard].fileData.filePreviewUrls = [];
      state.fileUpload[createWizard].billData = {};
    },
  },
});

const callApi = async ({ orgId, file }: { orgId: number; file: File }) => {
  const resFileUpload = await filesApi.uploadFile(orgId, file);
  const {
    file: { id: fileId },
  } = resFileUpload;
  const resBillDetails = await filesApi.billDetails(orgId, fileId);

  return {
    ...getExtractedBillData(resBillDetails),
    ...resFileUpload,
  };
};

const fileUploadSlice = createApiCallSlice<any, FileUploadState>({
  name: generateSliceName(name, 'upload_invoice_file'),
  api: callApi,
  initialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.fileUpload[createWizard].loading = true;
      state.fileUpload[createWizard].error = false;
    },
    [ON_SUCCESS]: (state, action) => {
      const {
        file: { id: fileId },
        fileStorageUrl,
        filePreviewUrls,
        dueDate,
        customerNameValue,
        totalAmount,
        invoiceNumber,
      } = action.payload;
      state.fileUpload[createWizard].loading = false;
      state.fileUpload[createWizard].error = false;
      state.fileUpload[createWizard].fileData.fileId = fileId;
      state.fileUpload[createWizard].fileData.fileStorageUrl = fileStorageUrl;
      state.fileUpload[createWizard].fileData.filePreviewUrls = filePreviewUrls;
      state.fileUpload[createWizard].billData.dueDate = dueDate;
      state.fileUpload[createWizard].billData.customerNameValue = customerNameValue;
      state.fileUpload[createWizard].billData.invoiceNumber = invoiceNumber;
      state.fileUpload[createWizard].billData.totalAmount = totalAmount;
    },
    [ON_FAILURE]: (state) => {
      state.fileUpload[createWizard].loading = false;
      state.fileUpload[createWizard].error = true;
    },
  },
  selectors: {
    status: (state) => state[name]?.fileUpload[createWizard],
    billData: (state) => state[name]?.fileUpload[createWizard].billData,
    loading: (state) => state[name]?.fileUpload[createWizard].loading,
    error: (state) => state[name]?.fileUpload[createWizard].error,
  },
});

export { clearFileUploadSlice, fileUploadSlice };
