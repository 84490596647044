import pickBy from 'lodash/pickBy';
import { FundingType } from 'src/utils/consts';
import { getFundingSourcesByType } from 'src/utils/funding-sources';
import { AccountType, BillType, UserContextType } from 'src/utils/types';

export const getFundingSourceEventData = (
  currentUser: UserContextType,
  fundingSources: readonly AccountType[],
  currentBill?: BillType
) =>
  pickBy({
    userId: currentUser.id,
    vendorId: currentBill?.vendor?.id,
    creditCardFundingSourcesCount: getFundingSourcesByType(fundingSources, FundingType.CARD).length,
    bankCardFundingSourcesCount: getFundingSourcesByType(fundingSources, FundingType.ACH).length,
  });
