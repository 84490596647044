import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { verifyFundingSourceAction } from 'src/redux/user/actions';
import { getIsFundingSourceVerifying } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { VerifyFundingSourceMicroDepositsErrorCodes } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import {
  dismiss,
  MicroDepositActions,
  MicroDepositProps,
  MicroDepositState,
  onChange,
  parseErrorToState,
  Resolutions,
  State,
  validateInputs,
} from './microDepositsCommon';

export type FundingSourceMicroDepositProps = {
  fundingSourceId: number | null;
  isEmbedded?: boolean;
  token?: string;
} & MicroDepositProps;

const MicroDepositErrors = VerifyFundingSourceMicroDepositsErrorCodes;
const errorToResolutionMap: Record<string, any> = {
  [MicroDepositErrors.INVALID_TOKEN]: Resolutions.NOT_FOUND,
  [MicroDepositErrors.CONTACT_SUPPORT_VERIFY_MICRO_DEPOSITS]: Resolutions.SUPPORT,
  [MicroDepositErrors.ERR_VERIFY_MICRO_DEPOSITS]: null,
  [MicroDepositErrors.NOT_FOUND]: Resolutions.NOT_FOUND,
};
const { ERR_VERIFY_MICRO_DEPOSITS } = MicroDepositErrors;

function callVerifyApi(fundingSourceId: number, state: State, dispatch: Dispatch<any>, token?: string) {
  const amount1Num = parseFloat(convertCurrencyToNumber(state.amount1));
  const amount2Num = parseFloat(convertCurrencyToNumber(state.amount2));

  return new Promise((resolve, reject) => {
    dispatch(verifyFundingSourceAction(fundingSourceId, token, amount1Num, amount2Num, resolve, reject));
  });
}

async function verifyFundingSource(
  fundingSourceId: number,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>,
  token?: string
) {
  try {
    await callVerifyApi(fundingSourceId, state, dispatch, token);
    setState({
      ...state,
      resolution: Resolutions.SUCCESS,
    });

    return [true];
  } catch (error: any) {
    setState({
      ...state,
      ...parseErrorToState(error, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS),
    });

    return [false, error];
  }
}

async function onSubmit(
  eventPage: string,
  props: FundingSourceMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>
) {
  analytics.track(eventPage, 'submit-continue');
  const [validationOk, validationErrors] = validateInputs(state, setState);

  if (validationOk && props.fundingSourceId) {
    const [success, errorMessage] = await verifyFundingSource(
      props.fundingSourceId,
      state,
      setState,
      dispatch,
      props.token
    );
    analytics.track(eventPage, `verify-confirmed-${success ? 'success' : 'failed'}`, errorMessage);

    if (success && props.onSuccess) {
      props.onSuccess();
    }

    const error = parseErrorToState(errorMessage, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);

    if (!success && error.resolution === Resolutions.SUPPORT && props.onBlocked) {
      props.onBlocked();
    }

    if (!success && error.resolution === Resolutions.NOT_FOUND && props.onInvalidToken) {
      props.onInvalidToken();
    }
  } else {
    analytics.track(eventPage, 'submit-validation-error', validationErrors as Record<string, any>);
  }
}

export function createActions(
  eventPage: string,
  props: FundingSourceMicroDepositProps,
  state: State,
  setState: (state: State) => void,
  dispatch: Dispatch<any>
): MicroDepositActions {
  return {
    onChange: onChange(state, setState),
    dismiss: () => dismiss(setState),
    onSubmit: () => onSubmit(eventPage, props, state, setState, dispatch),
  };
}

async function checkAndUpdateVerificationStatus(props, state, setState) {
  if (props.token) {
    setState({
      ...state,
      isLoading: true,
    });

    try {
      const verificationResult = await financialAccountsApi.getMicroDepositsStatusWithToken({
        token: props.token,
        id: props.fundingSourceId,
      });
      setState({
        ...state,
        companyName: verificationResult?.company?.name,
        isLoading: false,
      });

      if (verificationResult.status === 'VERIFIED' && props.onSuccess) {
        props.onSuccess();
      }
    } catch (e) {
      const parsedError = parseErrorToState(e, errorToResolutionMap, ERR_VERIFY_MICRO_DEPOSITS);
      setState({
        ...state,
        ...parsedError,
        isLoading: false,
      });

      if (parsedError.resolution === Resolutions.SUPPORT && props.onBlocked) {
        props.onBlocked();
      }

      if (parsedError.resolution === Resolutions.NOT_FOUND && props.onInvalidToken) {
        props.onInvalidToken();
      }
    }
  }
}

export function useFundingSourceMicroDepositState(eventPage: string, props: FundingSourceMicroDepositProps) {
  const [state, setState] = useState<State>({
    amount1: '',
    amount2: '',
    validationErrors: {},
  });
  const isFundingSourceVerify = useSelector(getIsFundingSourceVerifying);
  const isLoading = isFundingSourceVerify;
  const dispatch = useDispatch();

  const enhancedState: MicroDepositState = {
    isLoading,
    ...state,
  };

  const actions = createActions(eventPage, props, state, setState, dispatch);

  useEffect(() => {
    checkAndUpdateVerificationStatus(props, state, setState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [enhancedState, actions] as const;
}
