import { useSelector } from 'react-redux';
import { getDeliveryMethods } from 'src/redux/user/selectors';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { ReceivingMethodConfig } from '../context/ReceivingMethodContext';

type Origin = DeliveryMethodOrigin | null;

const useGetConfiguration = (origin: Origin) => {
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const accountNumber4digits = getAccountNumber4digits(deliveryMethod?.bankAccount);

  switch (origin) {
    case DeliveryMethodOrigin.MSN_PORTAL_ONBOARDING || origin === DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS:
      return { prefix: '.msnPortal' };
    case DeliveryMethodOrigin.MSN_CLAIM_ACCOUNT:
      return { prefix: '.msnClaimAccount', subTitleValues: { accountNumber4digits } };
    default:
      return { prefix: '' };
  }
};

const createConfiguration = (prefix, subTitleValues): ReceivingMethodConfig => ({
  ReceivingMethodSelectVerificationTypePage: {
    title: `onboarding.fundingSources.bank.verification${prefix}.title`,
    subtitle: `onboarding.fundingSources.bank.verification${prefix}.deliverySubtitle`,
    subTitleValues,
    plaid: {
      label: `onboarding.deliveryMethods.bank.verification${prefix}.plaid.label`,
      description: `onboarding.deliveryMethods.bank.verification${prefix}.plaid.description`,
    },
    manual: {
      label: `onboarding.deliveryMethods.bank.verification${prefix}.manual.label`,
      description: `onboarding.deliveryMethods.bank.verification${prefix}.manual.description`,
    },
  },
  ReceivingMethodManualBankAccountPage: {
    title: `onboarding.deliveryMethods.bank${prefix}.title`,
    subtitle: `onboarding.deliveryMethods.bank${prefix || '.melioMe'}.subtitle`,
    nextLabel: `onboarding.deliveryMethods.bank${prefix}.save`,
  },
});
export const useCreateReceivingMethodConfig = (origin: Origin = null) => {
  const { prefix, subTitleValues } = useGetConfiguration(origin);

  return createConfiguration(prefix, subTitleValues);
};
