import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import SuccessIllustration from 'src/images/qbo/success-check.svg';
import { ButtonsRowPosition, DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  title;
  subtitle;
  onOkAction: () => void;
};

export const MicroDepositsSuccessDialog = ({ title, subtitle, onOkAction }: Props) => (
  <Dialog
    type={DialogType.CONFIRM}
    variant={DialogVariants.SUCCESS}
    title={title}
    subtitle={subtitle}
    okButtonText="settings.microDeposits.verifyDialogSuccess.ok"
    onOkAction={onOkAction}
    buttonsRowPosition={ButtonsRowPosition.RIGHT}
    minHeight="36.8rem"
    image={SuccessIllustration}
    hideClose
  />
);
