import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { parseQueryString, stringifyQs } from 'src/utils/query-utils';

export function useQueryParams<T extends Record<string, any>>() {
  const location = useLocation();
  const history = useHistory();
  const query = parseQueryString(location.search) as { [key in keyof T]?: string };

  const replace = useCallback(
    (params) => {
      const search = stringifyQs({ ...query, ...params });
      history.replace({
        pathname: location.pathname,
        search,
      });
    },
    [history, location.pathname, query]
  );

  const navigate = useCallback(
    (params) => {
      const search = stringifyQs({ ...query, ...params });
      history.push({
        pathname: location.pathname,
        search,
      });
    },
    [history, location.pathname, query]
  );

  return { query, replace, navigate };
}
