import { EmptyList } from 'src/components/list/EmptyList';
import NewEmptyList from 'src/components/list/NewEmptyList';
import useEmptyStateMessage from 'src/pages/get-pro/components/empty/hooks/useEmptyStateMessage';

const EmptyState = () => {
  const { icon, title, exp, cta, ctaAction, expValues } = useEmptyStateMessage();

  if (cta && ctaAction && icon) {
    return <NewEmptyList iconSrc={icon} title={title} text={exp} ctaLabel={cta} ctaAction={ctaAction} />;
  }

  return <EmptyList text={exp} textValues={expValues} logo={icon} textTitle={title} showVerifyMdIndicator />;
};

export default EmptyState;
