import { AmexNotificationDetails } from '@melio/spend-management-api-axios-client';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { accountsApi } from '../api';
import { EnrollAccountParams, UseEnrollAccountsActionOptions } from '../types';

export const useEnrollAccount = () => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: enrollAccount, loading, error } = useApi<[EnrollAccountParams], AmexNotificationDetails[]>({
    api: accountsApi.enroll,
  });

  return {
    loading,
    error,
    enrollAccount: ({ clientRequestId, buyerId }: UseEnrollAccountsActionOptions) =>
      enrollAccount({ orgId, clientRequestId, buyerId }),
  };
};
