import * as React from 'react';
import { ListItemContainer } from 'src/components/list/ListItemContainer';
import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import { ToNavigationType } from 'src/utils/types';

type Props = {
  to: ToNavigationType;
  testId?: string;
  onClick?: () => void;
  children: React.ReactNode;
  isSelected: boolean;
};

export const LinkListItemContainer = ({ children, isSelected, ...props }: Props) => (
  <SmartLink {...props} css={{ textDecoration: 'none' }}>
    <ListItemContainer isSelected={isSelected}>{children}</ListItemContainer>
  </SmartLink>
);
