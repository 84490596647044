import React, { ReactNode } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PopupItemContainer } from 'src/components/common/PopupItemContainer';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Spacer from 'src/core/ds/spacer';

type Props = {
  label: string;
  labelValues?: Record<string, string | ReactNode | unknown>;
  description: string;
  descriptionValues?: Record<string, string | ReactNode | unknown>;
  buttonLabel: string;
  buttonLabelValues?: Record<string, string | ReactNode | unknown>;
  showIndicator?: boolean;
  onButtonClick: () => void;
  onCloseClick: () => void;
  testId?: string;
};

export const BillGroupIndicator = ({
  label,
  description,
  buttonLabel,
  showIndicator = true,
  onButtonClick,
  onCloseClick,
  labelValues,
  descriptionValues,
  buttonLabelValues,
  testId,
}: Props) => {
  const onClose = (): void => {
    onCloseClick();
  };

  return showIndicator ? (
    <PopupItemContainer testId={testId}>
      <Flex mb={2} textStyle="body3Semi">
        <MIFormattedText label={label} values={labelValues} />
        <Spacer />
        <Box data-testid="close-action" onClick={onClose} cursor="pointer" color="grey.600" _hover={{ color: 'black' }}>
          <Icon name={IconNames.close} size={IconSize.s} />
        </Box>
      </Flex>
      <Box data-testid="description" mb={5} color="grey.700" textStyle="body3">
        <MIFormattedText label={description} values={descriptionValues} />
      </Box>
      <Button
        size={ButtonSizes.sm}
        variant={ButtonVariants.secondary}
        label={buttonLabel}
        isFullWidth
        onClick={onButtonClick}
        values={buttonLabelValues}
      />
    </PopupItemContainer>
  ) : null;
};
