import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';

export const customersApi = {
  list({ orgId, filters = {} }) {
    const url = `/orgs/${orgId}/customers`;

    return fetchRequest(url, { ...filters }).then((res) => ({
      items: res.customers,
    }));
  },

  fetch({ orgId, id }) {
    const url = `/orgs/${orgId}/customers/${id}`;

    return fetchRequest(url).then((res) => res.customer);
  },
  create({ orgId, ...params }) {
    const url = `/orgs/${orgId}/customers`;

    return postRequest(url, params).then((res) => res.customer);
  },
  update({ orgId, id, ...params }) {
    const url = `/orgs/${orgId}/customers/${id}`;

    return putRequest(url, params).then((res) => res.customer);
  },
  delete({ orgId, id }) {
    const url = `/orgs/${orgId}/customers/${id}`;

    return deleteRequest(url).then((res) => res.customer);
  },
  createBatch({ orgId, data }) {
    const url = `/orgs/${orgId}/customers/create-batch`;

    return postRequest(url, data).then((res) => res.customers);
  },
  getCustomerPaymentRequestsSummary({ orgId, id }) {
    const url = `/orgs/${orgId}/customers/${id}/payment-requests-summary`;

    return fetchRequest(url).then((res) => ({
      invoices: {
        ...res?.created,
      },
      requests: {
        ...res?.pending,
      },
      payments: {
        ...res?.fulfilled,
      },
    }));
  },
};
