import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import organizationStore, { getOrganizationStoreActions } from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { companyInfoFactory } from 'src/pages/settings/records';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { convertToGoogleAddress, convertToServerAddress } from 'src/utils/address';
import { Role } from 'src/utils/consts';
import NewCompanyDetailsPage from './components/NewCompanyDetailsPage';

type Props = {
  onNext: () => void;
  onExit: () => void;
};

const eventPage = 'new-company-details';
export const NewCompanyDetailsPageContainer = ({ onExit, onNext }: Props) => {
  const dispatch = useDispatch();
  const { updateNewCompanyInfo } = getOrganizationStoreActions(dispatch);
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);

  const [manualAddressView, setManualAddressView] = useState(false);
  const [address, setAddress] = useState(newCompanyInfo?.address || convertToGoogleAddress(companyInfoFactory()));

  const model = useMemo(() => ({ companyName: newCompanyInfo?.companyName }), [newCompanyInfo]);
  const [companyDetailsMV, actions, validationErrors]: any[] = useForm(model, {
    submit: async (value) => {
      const { companyName } = value;
      const convertedAddress = address.addressComponents ? convertToServerAddress(address) : address;
      const validationErrors = getValidationErrors('companyInfo', { companyName, ...convertedAddress }, [
        'companyName',
        'googlePlaceId',
        'zipCode',
      ]);

      if (!isValidationOk(validationErrors)) {
        analytics.track(eventPage, 'continue-validation-error', validationErrors);
        throw new ValidationError({ validationErrors });
      }

      analytics.track(eventPage, 'continue-success');
      updateNewCompanyInfo({
        companyName,
        address: convertedAddress,
        role: isMemberOfAccountingFirm ? Role.ACCOUNTANT : '',
      });
      onNext();
    },
  });

  useEffect(() => {
    if (newCompanyInfo?.address) {
      setAddress(newCompanyInfo?.address);
    }
  }, [newCompanyInfo]);

  const onSubmitManualAddress = async (newAddress) => {
    await updateNewCompanyInfo({
      companyName: companyDetailsMV.companyName.value,
      address: newAddress,
      role: isMemberOfAccountingFirm ? Role.ACCOUNTANT : '',
    });

    if (newCompanyInfo.companyName) {
      onNext();
    } else {
      setManualAddressView(false);
    }
  };

  const toggleManualAddress = async () => {
    const companyInfo = {
      companyName: companyDetailsMV.companyName.value,
      address,
    };
    updateNewCompanyInfo(companyInfo);
    setManualAddressView(!manualAddressView);
  };

  return (
    <NewCompanyDetailsPage
      goExit={onExit}
      manualAddressView={manualAddressView}
      toggleManualAddress={toggleManualAddress}
      address={address}
      setAddress={setAddress}
      onSubmitManualAddress={onSubmitManualAddress}
      submit={actions.submit}
      companyName={companyDetailsMV.companyName}
      validationErrors={validationErrors?.googlePlaceId || validationErrors?.zipCode}
    />
  );
};
