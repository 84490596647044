import { featureFlags } from '@melio/shared-web';
import { useFeature } from '@melio/shared-web/dist/feature-flags';
import get from 'lodash/get';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin, FeatureFlags } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { onboardingLocations } from '../locations';
import { VendorCompanyLogo } from './components/VendorCompanyLogo';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

const eventPage = 'onboarding-vendor-company-logo';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyLogoPageContainer = ({ companyInfoField, nextStepURL, prevStepURL, inputFields }: Props) => {
  const { navigate } = useNavigator();
  const locationState = useLocation<Record<string, any>>().state;
  const [isDeferredOnboardingEnabled] = useFeature<boolean>(FeatureFlags.EnableGetPaidDeferredOnboarding, false);
  const [isDedicatedGetPaidOnboardingDisable] = useFeature<boolean>(FeatureFlags.EnableGetPaidUnifiedOnboarding, false);
  const [isPlaidMicroDepositsGetPaidEnabled] = featureFlags.useFeature(
    FeatureFlags.PlaidMicroDepositsGetPaidEnabled,
    false
  );
  const orgId = useOrgId();
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');
  const userSettings = get(permissions, 'settings', false);
  const editable = userSettings ? userSettings.editCompanySettings() : false;
  const getProgressBarRelativeStep = () => {
    if (isDedicatedGetPaidOnboardingDisable) {
      return isPlaidMicroDepositsGetPaidEnabled ? 1 / 3 : 1 / 4;
    }

    if (isDeferredOnboardingEnabled) {
      return isUnifiedOnboardingOpen ? 8 / 15 : 5 / 10;
    }

    return isUnifiedOnboardingOpen ? 9 / 15 : 6 / 10;
  };

  const { onPrev, isLoading } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL: isDeferredOnboardingEnabled ? onboardingLocations.vendorCompanyInfo.contactName : prevStepURL,
    inputFields,
  });

  const companyInfo = useSelector(getCompanyInfo);

  const goToNextStep = useCallback(() => {
    if (isDeferredOnboardingEnabled) {
      navigate(generatePath(onboardingLocations.vendorCompanyInfo.link, { orgId }), false, { origin });
    } else if (origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION) {
      navigate(generatePath(onboardingLocations.vendorCompanyInfo.legal, { orgId }), false, { origin });
    } else if (isCompanyLegalInfoDone(companyInfo)) {
      navigate(generatePath(onboardingLocations.vendorCompanyInfo.businessType, { orgId }), false, {
        origin,
      });
    } else {
      navigate(generatePath(onboardingLocations.vendorCompanyInfo.legal, { orgId }), false, {
        origin,
      });
    }
  }, [navigate, orgId, origin, companyInfo, isDeferredOnboardingEnabled]);

  const handleSkip = useCallback(() => {
    analytics.track(eventPage, 'skip');
    goToNextStep();
  }, [goToNextStep]);

  const handleNext = useCallback(() => {
    analytics.track(eventPage, 'continue');
    goToNextStep();
  }, [goToNextStep]);

  const goToRequestsDashboard = () => {
    locationState.origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION
      ? navigate(generatePath(msnPortalLocations.base, { orgId }))
      : navigate(generatePath(getPaidLocations.dashboard, { orgId }));
  };

  let currentGoExit: (() => void) | undefined;
  let currentGoPrev: (() => void) | undefined = onPrev;

  if (!locationState) {
    currentGoExit = goToRequestsDashboard;
  } else if (
    locationState.origin === DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_REQUESTS_LIST ||
    locationState.origin === DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST
  ) {
    currentGoExit = goToRequestsDashboard;
    currentGoPrev = undefined;
  } else if (locationState.origin === DeliveryMethodOrigin.GET_PAID) {
    currentGoPrev = goToRequestsDashboard;
  }

  return (
    <VendorCompanyLogo
      onPrev={currentGoPrev}
      goExit={currentGoExit}
      onNext={handleNext}
      onSkip={handleSkip}
      isLoading={isLoading}
      editable={editable}
      relativeStep={getProgressBarRelativeStep()}
    />
  );
};
