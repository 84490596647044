import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { Checkbox } from 'src/core/ds/checkbox';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { ProcessingFeesExplanation } from 'src/pages/get-paid/create/components/form/ProcessingFeesExplanation';
import { useProcessingFeeData } from 'src/pages/get-paid/hooks/useProcessingFeeData';
import { ModelView } from 'src/ui/form';
import { FormButtons, FormContainer, FormRow } from 'src/ui/form/FormElements';

export type SendPaymentRequestType = {
  paymentRequestId?: number;
  customerName?: string;
  customerEmail?: string;
  customerNote?: string;
  contactPhone?: string;
  shouldAttachInvoice?: boolean;
};

export type SendPaymentRequestModelType = ModelView<SendPaymentRequestType>;

type Props = {
  submit: () => void;
  onShareableLink: () => void;
  enableInvoiceAttachment: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  model: SendPaymentRequestModelType;
};

const SendPaymentRequestForm = ({
  submit,
  model,
  isLoading,
  isDisabled,
  onShareableLink,
  enableInvoiceAttachment,
}: Props) => {
  const { isOrganizationAllowedForArProcessingFee, isArProcessingFeeEnabled } = useProcessingFeeData();
  const shouldDisplayFeeExplanation = isArProcessingFeeEnabled && isOrganizationAllowedForArProcessingFee;
  const onCheckBoxChange = () => {
    model.setModelState((prevState) => ({
      ...prevState,
      shouldAttachInvoice: !prevState.shouldAttachInvoice,
    }));
  };

  return (
    <Box>
      <FormContainer onSubmit={submit}>
        <Box as={FormRow} mb={0}>
          <PrivateDataContainer>
            <TextField
              label="getPaid.new.paymentRequest.customerEmailLabel"
              placeholder="getPaid.new.paymentRequest.customerEmailPlaceholder"
              model={model.customerEmail}
              autoFocus={!model.customerEmail.value}
              type="email"
              errorMessage={model.customerEmail.error}
              isRequired
            />
          </PrivateDataContainer>
        </Box>
        <Box as={FormRow} mb={0}>
          <PrivateDataContainer>
            <TextField
              label="getPaid.new.paymentRequest.noteToCustomerLabel"
              placeholder="getPaid.new.paymentRequest.noteToCustomerPlaceholder"
              model={model.customerNote}
            />
          </PrivateDataContainer>
        </Box>
        {enableInvoiceAttachment && (
          <Box mb={8} mt={4}>
            <Checkbox
              isChecked={Boolean(model?.shouldAttachInvoice.value)}
              data-testid="attach-invoice-file-checkbox"
              onChange={onCheckBoxChange}
            >
              <MIFormattedText label="getPaid.new.paymentRequest.attachInvoiceFile" />
            </Checkbox>
          </Box>
        )}

        <FormRow mbZero>
          <FormButtons>
            <Button
              type="submit"
              label="getPaid.new.paymentRequest.submitLabel"
              variant={ButtonVariants.primary}
              isLoading={isLoading}
              disabled={isDisabled}
              w="full"
              boxSizing="border-box"
            />
            <Box mt={4} />
            <WizardElements.WizardOrLine />
            <Button
              onClick={onShareableLink}
              label="getPaid.new.paymentRequest.secondaryButtonLabel"
              variant={ButtonVariants.secondary}
              w="full"
              boxSizing="border-box"
            />
          </FormButtons>
        </FormRow>
        {shouldDisplayFeeExplanation && <ProcessingFeesExplanation />}
      </FormContainer>
    </Box>
  );
};

export default SendPaymentRequestForm;
