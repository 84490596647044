import * as React from 'react';
import { useSelector } from 'react-redux';
import { MICard } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { OutsideFooter } from 'src/components/common/OutsideLayout/OutsideFooter';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { BillStatus } from 'src/pages/bill/components/BillStatus';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { StatusInfoType } from 'src/utils/types';
import MelioMePageCardWrapper from './MelioMePageCardWrapper';

type Props = {
  onKeyDown?: (event: any) => void;
  children: React.ReactNode;
  goDashboard?: () => void;
  onSignUpClick?: () => void;
  goToAccount?: () => void;
  showNotFoundCard: boolean;
  showPaymentProgressStatus?: boolean;
  statusInfo?: StatusInfoType | null;
  isActionRegister?: boolean;
  blockCCMoneyIn: boolean;
  promotionName: string;
  shadow?: boolean;
  companyName: string;
};

const GuestCardLayout = ({
  children,
  onKeyDown,
  goDashboard,
  showNotFoundCard,
  showPaymentProgressStatus = false,
  statusInfo,
  isActionRegister = true,
  onSignUpClick,
  goToAccount,
  companyName,
}: Props) => (
  <MelioMePageCardWrapper
    goDashboard={goDashboard}
    showPaymentProgressStatus={showPaymentProgressStatus}
    onKeyDown={onKeyDown}
  >
    <Box
      pos="relative"
      w="full"
      maxWidth="53rem"
      textAlign="center"
      bgColor="white"
      borderRadius="lg"
      mt={{ base: 1, sm: 5 }}
      mx={{ base: 3, sm: 4 }}
      mb={{ base: 3, sm: 0 }}
      id="melio-me-card-container"
    >
      <MICard mode="regular">
        {statusInfo && !showNotFoundCard && showPaymentProgressStatus && <BillStatus statusInfo={statusInfo} />}
        <Box onKeyDown={onKeyDown} p={{ base: '4rem 2rem 3rem', md: 10 }}>
          {children}
        </Box>
        {showPaymentProgressStatus && (
          <CTAButton isActionRegister={isActionRegister} onSignUpClick={onSignUpClick} goToAccount={goToAccount} />
        )}
        {!showNotFoundCard && !showPaymentProgressStatus && (
          <Box mb={10} px={5} id="payment-request-link-security">
            <MISecurityDetails
              eventPage="payor"
              label="guests.register.paymentRequestLinkSecurityText"
              values={{ companyName }}
            />
          </Box>
        )}
      </MICard>
      {!showNotFoundCard && <OutsideFooter />}
    </Box>
  </MelioMePageCardWrapper>
);

type CTAButtonProps = {
  onSignUpClick?: () => void;
  goToAccount?: () => void;
  isActionRegister?: boolean;
};

const CTAButton = ({ isActionRegister, onSignUpClick, goToAccount }: CTAButtonProps) => {
  const isLoggedIn = useSelector(getIsLoggedIn);

  if (isActionRegister) {
    return (
      <Flex direction="column" borderTop="1px" borderColor="grey.400" pt={8} px={10} pb={12}>
        <Box textStyle="body3" mb={6}>
          <MIFormattedText label="guests.progressPayment.wantToTrackPayments" />
        </Box>
        <Button onClick={onSignUpClick} label="guests.progressPayment.buttonText" isFullWidth size={ButtonSizes.lg} />
      </Flex>
    );
  } else if (isLoggedIn) {
    return (
      <Flex direction="column" borderTop="1px" borderColor="grey.400" pt={8} px={10} pb={12}>
        <Button
          onClick={goToAccount}
          label="guests.progressPayment.goToYourAccount"
          isFullWidth
          size={ButtonSizes.lg}
        />
      </Flex>
    );
  }

  return null;
};

export default GuestCardLayout;
