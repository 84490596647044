import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IconNames, IconSize } from 'src/core/ds/icon';
import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';

export const GeneralErrorPage = () => {
  const site = useSiteContext();

  return (
    <SuccessLayoutPage
      testId="error-page"
      icon={IconNames.cancelFill}
      iconSize={IconSize['2xl']}
      title="onboarding.msnCompanyInfo.claimAccount.generalError.title"
      text="onboarding.msnCompanyInfo.claimAccount.generalError.subtitle"
      textValues={{
        contactSupport: (
          <Link isExternal href={`mailto:${site.config.support.email}`}>
            <MIFormattedText label="onboarding.msnCompanyInfo.claimAccount.generalError.contactSupport" />
          </Link>
        ),
      }}
    />
  );
};
