import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormControl, FormControlProps, FormLabel } from 'src/core/ds/form';
import { RadioGroup, RadioGroupProps } from 'src/core/ds/radio';
import { ModelViewField } from 'src/ui/form';
import { ErrorMessage, HelperText, OptionalLabel } from './components';

export type Props = Omit<FormControlProps, 'onChange'> &
  RadioGroupProps & {
    id?: string;
    testId?: string;
    model?: ModelViewField<string>;
    onChange?: (selectedValue: string) => void;
    label?: string;
    labelValues?: Record<string, unknown>;
    helperText?: string;
    helperTextValues?: Record<string, unknown>;
    errorMessage?: string | null;
    errorMessageValues?: Record<string, unknown>;
  };

export const RadioGroupField = ({
  id,
  testId,
  model,
  isRequired = false,
  helperText,
  helperTextValues,
  label,
  labelValues,
  errorMessage,
  errorMessageValues,
  onChange,
  value,
  children,
  ...rest
}: Props) => {
  const isError = Boolean(errorMessage) || Boolean(model?.error);
  const inputValue = value || model?.value;
  const inputId = id || model?.id;
  const noticesTestId = `input-${inputId}-notices`;
  const errorMessageText = errorMessage || model?.error;

  const handleChange = (value: string) => {
    onChange ? onChange(value) : model?.onChange({ value });
  };

  return (
    <FormControl isInvalid={isError} {...rest}>
      <FormLabel htmlFor={inputId} sx={{ mb: 2 }}>
        <MIFormattedText label={label} values={labelValues} />
        {!isRequired && <OptionalLabel isError={isError} />}
      </FormLabel>
      <RadioGroup id={inputId} onChange={handleChange} value={inputValue} label={label} testId={testId} {...rest}>
        {children}
      </RadioGroup>
      {!isError ? (
        <HelperText testId={noticesTestId} label={helperText} labelValues={helperTextValues} />
      ) : (
        errorMessageText && (
          <ErrorMessage data-testid={noticesTestId} label={errorMessageText} labelValues={errorMessageValues} />
        )
      )}
    </FormControl>
  );
};
