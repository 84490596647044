import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import loadingImage from 'src/images/melio-logo-loader.gif';

export const LoadingLinkingPage = () => (
  <SuccessLayoutPage
    illustration={loadingImage}
    title="onboarding.accountingSoftware.loading.title"
    text="onboarding.accountingSoftware.loading.subtitle"
  />
);

LoadingLinkingPage.defaultProps = {};
