import * as React from 'react';
import { useCallback } from 'react';
import Box from 'src/core/ds/box';
import { analytics } from 'src/services/analytics';

const iconButtonStyle = {
  h: 5,
  w: 5,
  bg: 'transparent',
  border: 'none',
  color: 'grey.700',
  cursor: 'pointer',
  _hover: {
    color: 'black',
  },
  _active: {
    color: 'black',
  },
  _disabled: {
    color: 'grey.500',
    cursor: 'not-allowed',
  },
  _focus: {
    border: 'none',
    outline: 0,
  },
};

type Props = {
  ariaLabel: string;
  icon: React.ReactNode;
  disabled?: boolean;
  onClick: () => void;
  testId?: string;
};

export const PaginationIconButton: React.FC<Props> = ({ ariaLabel, onClick, icon, disabled, testId }) => {
  const onClickWithEvent = useCallback(() => {
    ariaLabel && analytics.trackAction(`pagination-${ariaLabel}`, {});

    return onClick && onClick();
  }, [onClick, ariaLabel]);

  return (
    <Box
      as="button"
      disabled={disabled}
      aria-label={ariaLabel}
      onClick={onClickWithEvent}
      __css={iconButtonStyle}
      data-testid={testId}
    >
      {icon}
    </Box>
  );
};
