import isNaN from 'lodash/isNaN';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { Resolutions } from 'src/pages/settings/hooks/microDepositsCommon';
import {
  DeliveryMethodMicroDepositProps,
  useDeliveryMethodMicroDepositState,
} from 'src/pages/settings/hooks/useDeliveryMethodMicroDeposits';
import { getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { DeliveryMethodEmailReminderStatus, VerifiedStatus } from 'src/utils/consts';

const { MICRO_DEPOSIT_VERIFIED, SOFT_VERIFIED } = VerifiedStatus;

type Props = {
  onVerifyFinished: (success: boolean) => void;
  deliveryMethodIdToVerify: number;
};

export const useVerifyDeliveryMethodDialog = ({ onVerifyFinished, deliveryMethodIdToVerify }: Props) => {
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);
  const manualDeliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodIdToVerify));
  const shouldCloseIndicatorPerSession = useSelector(
    deliveryMethodsStore.selectors.shouldCloseVerifyDeliveryMethodIndicatorSlice.shouldCloseVerifyIndicator
  );

  const isVerifiedManualBankAccount =
    manualDeliveryMethod?.verifiedStatus === MICRO_DEPOSIT_VERIFIED ||
    manualDeliveryMethod?.verifiedStatus === SOFT_VERIFIED;

  const [showVerifyDeliveryMethodDialog, setShowVerifyDeliveryMethodDialog] = useState(false);
  const [isManualDeliveryMethodVerified, setIsManualDeliveryMethodVerified] = useState(isVerifiedManualBankAccount);

  const microDepositProps: DeliveryMethodMicroDepositProps = {
    deliveryMethodId: deliveryMethodIdToVerify,
    orgId,
    vendorId,
  };
  const [state, actions] = useDeliveryMethodMicroDepositState('', microDepositProps);

  const { onApiCall: getDeliveryMethodRemindersEmailsStatus, result: reminderStatus } = useApi<
    [orgId: number, vendorId: number, deliveryMethodId: number],
    DeliveryMethodEmailReminderStatus
  >({
    api: deliveryMethodsApi.getDeliveryMethodRemindersEmailsStatus,
  });

  useEffect(() => {
    if (vendorId && !isNaN(deliveryMethodIdToVerify)) {
      getDeliveryMethodRemindersEmailsStatus(orgId, vendorId, deliveryMethodIdToVerify);
    }
  }, [orgId, vendorId, deliveryMethodIdToVerify]);

  const onCloseDialogBox = () => {
    const verified = state.resolution === Resolutions.SUCCESS;
    onVerifyFinished(verified);
    setIsManualDeliveryMethodVerified(verified);
    setShowVerifyDeliveryMethodDialog(false);
  };
  const VerifyMicroDepositDialog = showVerifyDeliveryMethodDialog && (
    <VerifyMicroDeposits
      {...state}
      {...actions}
      key={deliveryMethodIdToVerify}
      verifyingId={deliveryMethodIdToVerify}
      onVerifyFinished={onCloseDialogBox}
      eventPage="get-paid-verify-manual-account"
      dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.receivingMethods.title"
      dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.receivingMethods.subtitle"
    />
  );

  const showVerifyDeliveryMethod = () => setShowVerifyDeliveryMethodDialog(true);
  const shouldShowVerifyBankAccount =
    reminderStatus === DeliveryMethodEmailReminderStatus.SENT &&
    !isManualDeliveryMethodVerified &&
    !shouldCloseIndicatorPerSession;

  return {
    VerifyMicroDepositDialog,
    showVerifyDeliveryMethod,
    shouldShowVerifyBankAccount,
  } as const;
};
