import { featureFlags } from '@melio/shared-web';
import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { FeatureFlags } from 'src/utils/featureFlags';
import { handleInputChange } from 'src/utils/input';
import { FieldType } from 'src/utils/types';
import { MELIO_ME_LINK_HOSTNAME } from '../consts';
import { useVendorCompanyInfoConfig } from '../context/VendorCompanyInfoContext';

type Props = {
  onPrev?: () => void;
  goExit?: () => void;
  onNext: () => void;
  onChange: (arg0: FieldType) => void;
  handle: string;
  isLoading?: boolean;
  errorMessage?: string;
  showLearnMoreModal: () => void;
};

export const VendorCompanyLink = ({
  onPrev,
  goExit,
  onNext,
  onChange,
  isLoading,
  errorMessage,
  handle,
  showLearnMoreModal,
}: Props) => {
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const [isDeferredOnboardingEnabled] = useFeature<boolean>(FeatureFlags.EnableGetPaidDeferredOnboarding, false);
  const [isDedicatedGetPaidOnboardingDisable] = useFeature<boolean>(FeatureFlags.EnableGetPaidUnifiedOnboarding, false);

  const [isPlaidMicroDepositsGetPaidEnabled] = featureFlags.useFeature(
    FeatureFlags.PlaidMicroDepositsGetPaidEnabled,
    false
  );
  const getProgressBarRelativeStep = () => {
    if (isDedicatedGetPaidOnboardingDisable) {
      return isPlaidMicroDepositsGetPaidEnabled ? 2 / 3 : 2 / 4;
    }

    if (isDeferredOnboardingEnabled) {
      return isUnifiedOnboardingOpen ? 9 / 15 : 6 / 10;
    }

    return isUnifiedOnboardingOpen ? 8 / 15 : 5 / 10;
  };
  const { title, subTitle, nextLabel, inputTitle, inputHint } = useVendorCompanyInfoConfig<'VendorCompanyLink'>({
    page: 'VendorCompanyLink',
  });

  const shouldRenderFooter = isPlaidMicroDepositsGetPaidEnabled && isDeferredOnboardingEnabled;

  const footer = (
    <Flex textStyle="body4">
      <MIFormattedText label="onboarding.vendorCompanyInfo.link.footerLabel" />
      <Flex
        data-testid="link-step-learn-more"
        onClick={() => showLearnMoreModal()}
        px={1}
        color="#7849FF"
        cursor="pointer"
      >
        <MIFormattedText label="onboarding.vendorCompanyInfo.link.learnMore" />
      </Flex>
    </Flex>
  );

  return (
    <StepLayoutPage
      title={title}
      subtitle={subTitle}
      onPrev={onPrev}
      goExit={goExit}
      onNext={onNext}
      isLoading={isLoading}
      relativeStep={getProgressBarRelativeStep()}
      nextLabel={nextLabel}
      footer={shouldRenderFooter ? footer : <></>}
    >
      <HandleContainer>
        <Box pos="absolute" top={6} textStyle="body1" color="grey.700" opacity={0.5}>
          {MELIO_ME_LINK_HOSTNAME}
        </Box>
        <Box>
          <TextField
            id="handleInput"
            label={inputTitle}
            helperText={inputHint}
            value={handle}
            onSubmit={onNext}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
            isDisabled={isLoading}
            errorMessage={errorMessage}
            autoFocus
            isRequired
          />
        </Box>
      </HandleContainer>
    </StepLayoutPage>
  );
};

const HandleContainer = styled.div`
  position: relative;

  #handleInput {
    padding-left: 10rem;
    box-sizing: border-box;
    line-height: ${(props) => props.theme.text.lineHeight.bigTitleM};
  }
`;
