import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { withNavigator } from 'src/hoc';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { getFundingSources, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { getInitialProcessingDates } from 'src/utils/dates';
import { FieldDateType } from 'src/utils/types';
import { GuestPaymentDatePage } from './components/GuestPaymentDatePage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type Props = GuestDataProps;

const eventPage = 'payor';
const eventName = 'select-payment-date';

const PlainGuestPaymentDatePageContainer = ({
  onChange,
  scheduledDate,
  deliveryDate,
  maxDeliveryDate,
  deliveryMethodType,
  minScheduledDate,
  paymentRequest,
  navigateToGuestPage,
  nextRoute,
  selectedFundingSourceId,
  deliveryMethodId,
  totalAmount,
  prevRoute,
  payBillFlowUUID,
}: Props) => {
  const [isLoading, setLoading] = useState(false);
  const orgId = useSelector(getOrgId);
  const [suggestedProcessingDates, setSuggestedProcessingDates] = useState({});
  const fundingSources = useSelector(getFundingSources);

  const loadSuggestedProcessingDate = useCallback(async () => {
    const dates = await getInitialProcessingDates({
      orgId,
      deliveryMethodId,
      fundingSourceId: selectedFundingSourceId,
      scheduledDate,
      amount: convertCurrencyToNumber(totalAmount),
      dueDate: paymentRequest.dueDate,
      payBillFlowUUID,
    });

    if (dates) {
      setSuggestedProcessingDates(dates);
    }
  }, [orgId, deliveryMethodId, selectedFundingSourceId, scheduledDate, totalAmount, onChange, paymentRequest.dueDate]);

  const updateSuggestedProcessingDates = useCallback(
    (suggestedDates) => {
      const { suggestedScheduledDate, minScheduledDate, deliveryDate, maxDeliveryDate } = suggestedDates;
      onChange({ id: 'minScheduledDate', value: new Date(minScheduledDate) });
      onChange({
        id: 'scheduledDate',
        value: new Date(suggestedScheduledDate),
      });
      onChange({ id: 'deliveryDate', value: new Date(deliveryDate) });
      onChange({ id: 'maxDeliveryDate', value: new Date(maxDeliveryDate) });
    },
    [onChange]
  );

  useEffect(() => {
    if (isEmpty(suggestedProcessingDates)) {
      loadSuggestedProcessingDate();
    }
  }, [suggestedProcessingDates, loadSuggestedProcessingDate]);

  useEffect(() => {
    if (!isEmpty(suggestedProcessingDates)) {
      updateSuggestedProcessingDates(suggestedProcessingDates);
    }
  }, [suggestedProcessingDates, updateSuggestedProcessingDates]);

  const onNext = () => {
    analytics.track(eventPage, `${eventName}-continue-success`);
    navigateToGuestPage(nextRoute);
  };

  const onDateChange = async ({ id, value }: FieldDateType) => {
    setLoading(true);
    const result = await deliveryApi.getDeliveryTime(
      orgId,
      value,
      deliveryMethodId,
      selectedFundingSourceId,
      convertCurrencyToNumber(totalAmount),
      undefined,
      payBillFlowUUID
    );
    setLoading(false);

    analytics.track(eventPage, 'date-selected');
    onChange({ id, value });
    onChange({
      id: 'scheduledDate',
      value: new Date(result.suggestedScheduledDate),
    });
    onChange({ id: 'deliveryDate', value: new Date(result.deliveryDate) });
    onChange({
      id: 'maxDeliveryDate',
      value: new Date(result.maxDeliveryDate),
    });
  };

  const onPrev = () => {
    analytics.track(eventPage, `${eventName}-back`);
    navigateToGuestPage(prevRoute);
  };

  if (!scheduledDate || !deliveryDate) {
    return <AreaLoader />;
  }

  return (
    <GuestPaymentDatePage
      paymentRequest={paymentRequest}
      deliveryDate={deliveryDate}
      maxDeliveryDate={maxDeliveryDate}
      deliveryMethodType={deliveryMethodType}
      scheduledDate={scheduledDate}
      totalAmount={totalAmount}
      onNext={onNext}
      onPrev={onPrev}
      onChange={onDateChange}
      minDate={minScheduledDate}
      isLoading={isLoading}
      selectedFundingSource={fundingSources.find((fs) => fs.id === selectedFundingSourceId)}
    />
  );
};

export const GuestPaymentDatePageContainer = compose(
  withNavigator(),
  withGuestData()
)(PlainGuestPaymentDatePageContainer);
