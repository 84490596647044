import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import {
  CardExpirationDateElement as CardExpirationDateElementType,
  CardNumberElement as CardNumberElementType,
  CardVerificationCodeElement as CardVerificationCodeElementType,
} from '@basis-theory/basis-theory-react/types';
import { useCallback, useRef, useState } from 'react';
import { LinkCardView } from 'src/components/basis-theory/link-card-view/LinkCardView';
import { SecurityComplianceMessage } from 'src/components/basis-theory/SecurityComplianceMessage';
import config from 'src/config';
import Flex from 'src/core/ds/flex/Flex';

export type RetrievedTokenizedData = {
  id: string;
  expirationMonth: string;
  expirationYear: string;
  last4Digits: string;
  cardBin: string;
  cardNumberIdentifiers: {
    token: string;
    fingerprint: string;
  };
};

type Props = {
  onLinkCard(tokenizedData: RetrievedTokenizedData): void;
  onError?(error: unknown): void;
  onReady?(): void;
  showLinkCardButtonSpinner?: boolean;
  showComplianceMessage?: boolean;
};

const CARD_NUMBER_FIELD_ID = 'card_number';
const CARD_EXPIRATION_DATE_FIELD_ID = 'expiration_date';
const CARD_VERIFICATION_CODE_FIELD_ID = 'security_code';

export const LinkCardViewContainer = ({
  onLinkCard,
  onError,
  onReady,
  showLinkCardButtonSpinner,
  showComplianceMessage,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { bt } = useBasisTheory(config.services.basisTheory.key, { elements: true });

  const cardNumberRef = useRef<CardNumberElementType>();
  const cardExpirationRef = useRef<CardExpirationDateElementType>();
  const cardVerificationRef = useRef<CardVerificationCodeElementType>();

  const handleLinkCard = useCallback(async () => {
    let tokenizedData;
    let cardNumberTokenizedData;
    try {
      if (cardNumberRef.current && cardExpirationRef.current && cardVerificationRef.current) {
        setIsLoading(true);

        tokenizedData = await bt?.tokenize({
          type: 'card',
          data: {
            number: cardNumberRef.current,
            expiration_month: cardExpirationRef.current.month(),
            expiration_year: cardExpirationRef.current.year(),
            cvc: cardVerificationRef.current,
          },
          mask: {
            number: '{{ data.number | slice: 0, 6 }}XXXXXX{{ data.number | last4 }}',
            expiration_month: '{{ data.expiration_month }}',
            expiration_year: '{{ data.expiration_year }}',
          },
        });

        cardNumberTokenizedData = await bt?.tokenize({
          type: 'card_number',
          data: cardNumberRef.current,
        });
      } else {
        onError?.(new Error());
      }
    } catch (e) {
      onError?.(e);
    }

    if (tokenizedData && cardNumberTokenizedData) {
      const {
        id,
        data: { number, expiration_month: expirationMonth, expiration_year: expirationYear },
      } = tokenizedData;

      const { id: cardNumberId, fingerprint: cardNumberFingerprint } = cardNumberTokenizedData;

      setIsLoading(false);

      onLinkCard({
        id,
        expirationMonth,
        expirationYear,
        last4Digits: number.slice(-4),
        cardBin: number.slice(0, 6),
        cardNumberIdentifiers: {
          token: cardNumberId,
          fingerprint: cardNumberFingerprint,
        },
      });
    }
  }, [bt, onError, onLinkCard]);

  return (
    <Flex height="full" justifyContent="center" alignItems="center" flexDirection="column">
      <BasisTheoryProvider bt={bt}>
        <Flex width="full" justifyContent="center" mb={showComplianceMessage ? 10 : 0}>
          <LinkCardView
            cardNumberFieldId={CARD_NUMBER_FIELD_ID}
            cardExpirationDateFieldId={CARD_EXPIRATION_DATE_FIELD_ID}
            cardVerificationCodeFieldId={CARD_VERIFICATION_CODE_FIELD_ID}
            showLinkCardButtonSpinner={showLinkCardButtonSpinner || isLoading}
            onLinkCard={handleLinkCard}
            onReady={onReady}
            refs={{ cardNumberRef, cardExpirationRef, cardVerificationRef }}
          />
        </Flex>
      </BasisTheoryProvider>
      {showComplianceMessage ? <SecurityComplianceMessage /> : null}
    </Flex>
  );
};
