import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

type Props = {
  titleLabel: string;
  permissionsLabel: string;
};

export const RolePermissionItem = ({ titleLabel, permissionsLabel }: Props) => (
  <Box textStyle="body4" mb="4">
    <Box textStyle="body4Semi" color="black">
      <MIFormattedText label={titleLabel} />
    </Box>
    <Box color="gray.100" __css={{ ul: { pl: 4 } }}>
      <MIFormattedText label={permissionsLabel} />
    </Box>
  </Box>
);
