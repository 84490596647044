import { ReactNode, SyntheticEvent, useCallback } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconSize, OldToNewIconNames } from 'src/core/ds/icon';
import { Image } from 'src/core/ds/image';
import { analytics } from 'src/services/analytics';

type Props = {
  icon?: string | null;
  imgSrc?: string | null;
  label: string;
  labelValues?: Record<string, any>;
  description?: string;
  onClick?: (event: SyntheticEvent) => void;
  linkLabel?: string | null;
  linkAction?: () => void;
  testId?: string;
  className?: string;
  eventPage?: string;
  fullWidth?: boolean;
  compact?: boolean;
  sxForCreateOptionWrapper?: CSSObject;
  sxForCreateOptionTextWrapper?: CSSObject;
};

export const MICreateOption = ({
  imgSrc,
  icon,
  label,
  labelValues = {},
  description,
  onClick,
  linkAction,
  linkLabel,
  testId,
  className,
  eventPage,
  fullWidth,
  compact,
  sxForCreateOptionWrapper,
  sxForCreateOptionTextWrapper,
}: Props) => {
  const onClickWithEvent = useCallback(
    (event) => {
      analytics.trackAction(label, eventPage ? { eventPage } : {});

      return onClick && onClick(event);
    },
    [onClick, label]
  );

  const onLabelClickWithEvent = useCallback(
    (event) => {
      event.stopPropagation();
      linkLabel && analytics.trackAction(linkLabel, {});

      return linkAction && linkAction();
    },
    [linkAction, linkLabel]
  );

  return (
    <CreateOptionWrapper
      className={className}
      data-testid={testId || label}
      onClick={onClickWithEvent}
      withLink={!!(linkAction && linkLabel)}
      fullWidth={fullWidth}
      compact={compact}
      sx={sxForCreateOptionWrapper}
    >
      {imgSrc && <Image src={imgSrc} w={8} h={8} />}
      {icon && <Icon name={OldToNewIconNames[icon]} size={IconSize.xl} />}
      <CreateOptionTextWrapper sx={sxForCreateOptionTextWrapper}>
        <LabelText>
          <MIFormattedText label={label} values={labelValues} />
        </LabelText>
        {description && (
          <DescriptionText>
            <MIFormattedText label={description} />
          </DescriptionText>
        )}
        {linkAction && linkLabel && (
          <LinkWrapper>
            <SubLink onClick={onLabelClickWithEvent}>
              <MIFormattedText label={linkLabel && linkLabel} />
            </SubLink>
          </LinkWrapper>
        )}
      </CreateOptionTextWrapper>
    </CreateOptionWrapper>
  );
};

type LinkWrapperProps = {
  children?: ReactNode;
};

const LinkWrapper = ({ children }: LinkWrapperProps) => <Box mt={2}>{children}</Box>;

type SubLinkProps = {
  children?: ReactNode;
  onClick?: (event: SyntheticEvent) => void;
};

const SubLink = ({ children, onClick }: SubLinkProps) => (
  <Box as="span" onClick={onClick} color="primary.500">
    {children}
  </Box>
);

type LabelTextProps = {
  children?: ReactNode;
};

const LabelText = ({ children }: LabelTextProps) => (
  <Box color="black" textStyle="body2Semi">
    {children}
  </Box>
);

type DescriptionTextProps = {
  children?: ReactNode;
};

const DescriptionText = ({ children }: DescriptionTextProps) => (
  <Box color="grey.600" textOverflow="ellipsis" textStyle="body4">
    {children}
  </Box>
);

type CreateOptionWrapperProps = {
  children?: ReactNode;
  className?: string;
  'data-testid'?: string;
  onClick?: (event: SyntheticEvent) => void;
  withLink: boolean;
  fullWidth?: boolean;
  compact?: boolean;
  sx?: CSSObject;
};

const CreateOptionWrapper = ({ children, withLink, compact, fullWidth, sx, ...rest }: CreateOptionWrapperProps) => (
  <Flex {...rest} sx={CreateOptionWrapperStyle(withLink, fullWidth, compact, sx)}>
    {children}
  </Flex>
);

const CreateOptionWrapperStyle = (
  withLink?: boolean,
  fullWidth?: boolean,
  compact?: boolean,
  sx?: CSSObject
): CSSObject => ({
  alignItems: withLink ? 'flex-start' : 'center',
  boxShadow: 500,
  w: 'full',
  maxW: fullWidth ? 'none' : '45rem',
  borderRadius: 'lg',
  py: withLink || compact ? 6 : 8,
  px: 6,
  boxSizing: 'border-box',
  cursor: 'pointer',
  bgColor: 'white',
  mb: 4,
  transitionProperty: 'box-shadow',
  transitionDuration: '300ms',
  _hover: {
    boxShadow: 600,
  },
  _active: {
    boxShadow: 500,
  },
  ...sx,
});

type CreateOptionTextWrapperProps = {
  children?: ReactNode;
  sx?: CSSObject;
};

const CreateOptionTextWrapper = ({ children, sx }: CreateOptionTextWrapperProps) => (
  <Flex flexDir="column" id="CreateOptionTextWrapper" ml={4} sx={sx}>
    {children}
  </Flex>
);
