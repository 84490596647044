import { useState } from 'react';
import styled from 'styled-components';
import { ItemizedList } from 'src/components/common/ItemizedList';
import { Loader } from 'src/components/common/Loader';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useModal } from 'src/helpers/react/useModal';
import amexLogo from 'src/images/icons/amex.svg';
import { isSuccessfulEnrollment, useAccounts } from 'src/modules/spend-management/accounts/hooks/useAccounts';
import { useDeleteAccount } from 'src/modules/spend-management/accounts/hooks/useDeleteAccount';
import { useSetDefaultAccount } from 'src/modules/spend-management/accounts/hooks/useSetDefaultAccount';
import { useEnrollment } from 'src/pages/spend-management/hooks/useEnrollment';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { AccountActionsType, AccountItem } from './components/AccountItem';
import { AmexDisconnectModal } from './components/modal/AmexDisconnectModal';

type Props = {
  isOnboard: boolean;
};

export const AmexConnectPage = ({ isOnboard }: Props) => {
  const { accounts, refetch: refetchAccounts, loading: loadingAccounts } = useAccounts({});
  const { deleteAccount } = useDeleteAccount();
  const { setDefaultAccount } = useSetDefaultAccount();
  const [deleteAccountId, setDeleteAccountId] = useState<string | undefined>(undefined);
  const [amexDisconnectModal, showAmexDisconnectModal] = useModal(AmexDisconnectModal, {
    id: 'AmexDisconnectModal',
    modalName: 'AmexDisconnectModal',
    deleteAccountId,
    onConfirm: async () => {
      try {
        const activeAccounts = !loadingAccounts && accounts?.filter(isSuccessfulEnrollment);

        if (!activeAccounts || !activeAccounts.length || !deleteAccountId) {
          throw new Error('Missing account or accountId');
        }

        await deleteAccount({ accountId: deleteAccountId });
        analytics.track(Page.SETTINGS, Context.VIRTUAL_CARDS, Event.CONTINUE_SUCCESS);
      } catch (e) {
        analytics.track(Page.SETTINGS, Context.VIRTUAL_CARDS, Event.CONTINUE_FAILED);
      }

      await refetchAccounts({});
    },
  });

  const onSetDefaultAccount = async (id?: string) => {
    if (id) {
      await setDefaultAccount({ accountId: id });
      await refetchAccounts({});
      analytics.track(Page.SETTINGS, Context.VIRTUAL_CARDS, Event.SET_DEFAULT_ACCOUNT_SUCCESS);
    }
  };

  const onDeleteAccount = (id?: string) => {
    if (id) {
      setDeleteAccountId(id);
      const lastFiveDigits = accounts?.filter((accounts) => accounts.accountId === id)[0]?.lastFive;
      showAmexDisconnectModal({ lastFiveDigits });
      analytics.track(Page.SETTINGS, Context.VIRTUAL_CARDS, Event.ACCOUNT_DISCONNECT);
    }
  };

  const onEnrolled = () => {
    analytics.track(Page.SETTINGS, Context.VIRTUAL_CARDS, Event.ACCOUNT_CONNECT);
    refetchAccounts({});
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'spendManagement.pages.enroll.toasts.successEnrollment',
    });
  };

  const { onEnrollClick, loading } = useEnrollment({ onEnrolled });

  if (loadingAccounts || !accounts) {
    return (
      <MICard>
        <MICardForm>
          <Loader color="primary" context="page" />
        </MICardForm>
      </MICard>
    );
  }

  const activeAccounts = !loadingAccounts && accounts?.filter(isSuccessfulEnrollment);
  const isActiveAccount = !!activeAccounts?.length;
  const prefixLocaleContent = getPrefixLocale(isOnboard, isActiveAccount);

  const actions: AccountActionsType[] = [
    {
      label: 'settings.spendManagement.connectLayout.item.actions.default',
      negative: false,
      action: onSetDefaultAccount,
      hasMultipleAccounts: activeAccounts?.length > 1,
    },
    {
      label: 'settings.spendManagement.connectLayout.item.actions.disconnect',
      negative: true,
      action: onDeleteAccount,
      hasMultipleAccounts: activeAccounts?.length > 1,
    },
  ];

  return (
    <>
      {amexDisconnectModal}
      <MICard>
        <MICardForm>
          <AmexLogo src={amexLogo} alt="amex logo" />
          <MICardTitle label={`${prefixLocaleContent}.title`} values={{ accounts: accounts.length }} />
          {activeAccounts?.length > 0 ? (
            <ItemizedList showLastBorder={false}>
              {activeAccounts.map((account) => (
                <AccountItem account={account} actions={actions} />
              ))}
            </ItemizedList>
          ) : (
            <Box textStyle="body2">
              <MIFormattedText label={`${prefixLocaleContent}.subtitle`} />
            </Box>
          )}

          <Box mt={8}>
            <Button
              variant={ButtonVariants.tertiary}
              label={`${prefixLocaleContent}.button`}
              size={ButtonSizes.md}
              onClick={onEnrollClick}
              isLoading={loading}
            />
          </Box>
        </MICardForm>
      </MICard>
    </>
  );
};

const getPrefixLocale = (isOnboard: boolean, isConnected: boolean) => {
  const prefixLocaleContent = `settings.spendManagement.connectLayout`;

  if (!isOnboard) {
    return `${prefixLocaleContent}.unonboarded`;
  }

  if (!isConnected) {
    return `${prefixLocaleContent}.disconnected`;
  }

  return `${prefixLocaleContent}.connected`;
};

const AmexLogo = styled.img`
  margin: 0 0 1rem 0;
  width: 4rem;
  height: 4rem;
`;
