import { useCallback, useState } from 'react';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { billsApi } from 'src/modules/bills/api';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { billFactory } from 'src/pages/bill/records';
import { BillType } from 'src/utils/types';
import { useBatchSettingsChange } from './useBatchSettingsChange';

type Props = {
  bill: BillType | BatchBillType;
  files: readonly number[];
  uploadAttachment: (file: File, bill: BillType, loadBillFromAttachment?: any) => void;
};

export const useUploadBillAttachment = ({ bill, files, uploadAttachment }: Props) => {
  const orgId = useOrgId();
  const [isBillLoading, setIsBillLoading] = useState<boolean>(false);
  const actions = useStoreActions(regularBatchPaymentsStore);
  const { getParentBatchItemByBillId } = useBatchSettingsChange();
  const { onApiCall: onEditBillById } = useApi({
    api: billsApi.editBillById,
  });

  const handleSelectFile = useCallback(
    async (file: File) => {
      await uploadAttachment(file, billFactory(), false);
    },
    [uploadAttachment]
  );

  const updateBillFiles = async () => {
    setIsBillLoading(true);
    const { object: updatedBill } = await onEditBillById(
      orgId,
      bill.id,
      {
        ...bill,
        files,
      },
      'all'
    );

    const batchItem = getParentBatchItemByBillId(bill.id);

    await actions.updatePayments.setBillPaymentFile({
      itemId: batchItem.id,
      billId: bill.id,
      files: updatedBill.files,
    });

    setIsBillLoading(false);
  };

  return {
    handleSelectFile,
    updateBillFiles,
    isBillLoading,
  };
};
