import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import merge from 'lodash/merge';
import { createIntl, createIntlCache, IntlConfig, IntlShape, RawIntlProvider } from 'react-intl';
import { capture } from 'src/utils/error-tracking';

export type TranslationObject = IntlShape & {
  setDefaultValue?: (key: string, value: unknown) => void;
};

export function addFlattenedValues(values: { error?: string }, prefix: string, obj: any) {
  if (!isObject(obj)) {
    values[prefix] = obj;
  } else {
    if (isArray(obj)) {
      values[`${prefix}_length`] = obj.length;
    }

    Object.keys(obj).forEach((key) => {
      addFlattenedValues(values, `${prefix}_${key}`, obj[key]);
    });
  }

  return values;
}
type TranslationOptions = Omit<IntlConfig, 'onError'>;

export function createTranslationService(options: TranslationOptions, defaults = {}) {
  const DEFAULT_CONFIGURATION = {
    formats: {
      number: {
        currency: {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 2,
        },
        percent: {
          style: 'percent',
          maximumFractionDigits: 2,
        },
      },
    },
    wrapRichTextChunksInFragment: true,
    onError: (err) => {
      const errorMessage = `Translation text was not found. errorMessage: ${err}`;
      capture(new Error(errorMessage));
      console.error(errorMessage);
    },
  };

  const cache = createIntlCache();
  const intl: TranslationObject = createIntl(merge(DEFAULT_CONFIGURATION, options), cache);
  const originalFormatMessage = intl.formatMessage;
  const defaultValues = defaults;

  intl.setDefaultValue = function (key, value) {
    if (!defaultValues[key]) {
      capture(new Error('Only existing keys can be overridden'));
    } else if (value || isNumber(value)) {
      defaultValues[key] = value;
    }
  };
  intl.formatMessage = function (descriptor, values) {
    const newValues =
      values && Object.keys(values).length > 0 ? Object.assign({}, defaultValues, values) : defaultValues;

    return originalFormatMessage(descriptor, newValues);
  };

  return intl;
}

export { RawIntlProvider as TranslationProvider };
