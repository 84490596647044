import { useEffect, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { useOrgId } from 'src/hooks';
import { getSupportedCountries } from 'src/services/api/international';

export const useCountryRisk = (vendorId, countryCode) => {
  const orgId = useOrgId();
  const [countryRisk, setCountyRisk] = useState<string | null>(null);
  const { onApiCall: getCountries, loading } = useApi({ api: getSupportedCountries });

  useEffect(() => {
    const fetchCountries = async () => {
      const { countries } = await getCountries({
        orgId,
        vendorId,
      });

      const country = countries.filter((value) => value.code === countryCode);

      if (country.length) {
        setCountyRisk(country[0].risk);
      }
    };

    fetchCountries();
  }, [countryCode, getCountries, orgId, vendorId]);

  return [countryRisk, loading];
};
