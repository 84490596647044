import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import qbLogo from 'src/images/accounting-software/quickbooks-logo.svg';
import organizationStore from 'src/modules/organizations/organizations-store';
import { useFetchBillAccountCategoryOptions } from 'src/pages/bill/components/BillDetailsForm/hooks/useFetchAccountCategoryOptions';
import { QBOSyncNowButtons } from 'src/pages/settings/components/accounting-software/components/qbo/QBOSyncNowButtons';
import { SyncWithAccountingSoftwareCardProps } from 'src/pages/settings/types';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { AccountingSoftware } from 'src/utils/consts';
import { AccountingPlatformLogo, DescriptionWrapper } from '../AccountingSoftwareCardElements';
import { MelioFeesExpenseAccountView } from '../MelioFeesExpenseAccountView';

type ConnectedViewProps = {
  QBCompanyName: string;
} & SyncWithAccountingSoftwareCardProps;

export const QBOConnectedView = ({ QBCompanyName, isLoading, viewMode, model, onEdit }: ConnectedViewProps) => {
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const unlinkQuickbooksStatus = useSelector(
    organizationStore.selectors.unlinkQuickbookSlice.getUnlinkQuickbooksStatus(orgId)
  );
  const accountCategoriesOptions = useFetchBillAccountCategoryOptions();

  return (
    <>
      {unlinkQuickbooksStatus?.isUnlinking || accountCategoriesOptions.length === 0 ? (
        <AreaLoader />
      ) : (
        <MICard>
          <MICardForm testId="connected-container">
            <AccountingPlatformLogo src={qbLogo} alt="logo" />
            <MICardTitle label="settings.newAccountingSoftware.Quickbooks.cardTitleConnected" />
            <Box mt={3}>
              <DescriptionWrapper>
                <MIFormattedText
                  label="settings.newAccountingSoftware.Quickbooks.connected"
                  values={{
                    QBOAccount: QBCompanyName,
                    melioAccount: companyInfo.companyName,
                  }}
                />
              </DescriptionWrapper>
              <QBOSyncNowButtons />
            </Box>
            <MelioFeesExpenseAccountView
              isLoading={isLoading}
              model={model}
              viewMode={viewMode}
              accountingSoftwareType={AccountingSoftware.QUICKBOOKS}
              onEdit={onEdit}
              accountCategoriesOptions={accountCategoriesOptions}
            />
          </MICardForm>
        </MICard>
      )}
    </>
  );
};
