import { featureFlags } from '@melio/shared-web';
import { isAfter } from 'date-fns';
import * as React from 'react';
import { memo } from 'react';
import { DeliveryDateFormat } from 'src/components/common/DeliveryDateFormat';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { ChecksDelaysTooltip } from 'src/pages/bill/pay/components/ChecksDelays/ChecksDelaysTooltip';
import { ChecksDelaysTooltipTypes, DeliveryType, FastCheckDeliveryType, FeatureFlags } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';

type DeliveryDateCellProps = {
  deliveryEta?: Date;
  maxDeliveryEta?: Date;
  itemId: string | null;
  deliveryMethod?: DeliveryMethodType;
  deliveryPreference?: string;
  dueDate: Date;
};

export const DeliveryDateCell: React.FC<DeliveryDateCellProps> = memo(
  ({ deliveryEta, maxDeliveryEta, deliveryMethod, deliveryPreference, dueDate }) => {
    const isLate = deliveryEta && dueDate && isAfter(new Date(deliveryEta), new Date(dueDate));
    const isEmptyState = !!deliveryMethod?.id;
    const isCheckDM = deliveryMethod?.deliveryType === DeliveryType.CHECK;
    const isFastCheck = deliveryPreference === FastCheckDeliveryType.EXPRESS;
    const [isChecksDelaysEnabled] = featureFlags.useFeature(FeatureFlags.USHolidaysChecks, false);
    const [isGeneralCheckDelays] = featureFlags.useFeature(FeatureFlags.GeneralCheckDelays, false);
    const showChecksDelaysTooltip = isChecksDelaysEnabled && isCheckDM && !isFastCheck;
    const showLateTag = isLate && !showChecksDelaysTooltip;
    const checkDelaysTooltipLabel = isGeneralCheckDelays
      ? 'bills.pay.date.checksDelays.general.tooltip'
      : 'bills.pay.date.checksDelays.tooltip';

    return (
      <Flex align="center" justify="space-between" data-testid="regularBatchPayments.cells.deliveryDate">
        {!isEmptyState ? (
          <Box color="grey.600">
            <MIFormattedText label="regularBatchPayments.cells.deliveryDate.notAvailable" />
          </Box>
        ) : (
          <>
            <Box mr={1} data-testid="delivery-date-cell">
              <DeliveryDateFormat date={deliveryEta} maxDate={maxDeliveryEta} />
            </Box>
            <Flex justify="center" pr={4}>
              {showLateTag && (
                <Badge
                  label="regularBatchPayments.cells.deliveryDate.late"
                  variant={BadgeVariants.OUTLINED}
                  color={BadgeColors.WARNING}
                />
              )}
              {showChecksDelaysTooltip && (
                <ChecksDelaysTooltip tooltipLabel={checkDelaysTooltipLabel} type={ChecksDelaysTooltipTypes.INFO} />
              )}
            </Flex>
          </>
        )}
      </Flex>
    );
  }
);
