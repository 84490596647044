import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  MethodOptionDescription,
  MethodOptionIcon,
  MethodOptionLabel,
} from 'src/components/common/SelectMethods/components/MethodElements';
import { RadioSelectOptionContainer } from 'src/components/common/SelectMethods/components/RadioSelectMethodOption';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { useDisclosure } from 'src/core/ds/hooks';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { billLocations } from 'src/pages/bill/locations';
import {
  financingAnalyticsEvents,
  FinancingExperience,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { FinancingFundingSourceModal } from './FinancingFundingSourceModal';

type Props = {
  paymentAmount: number;
  installmentAmount: number;
  onClick: () => void;
  financingExperience: FinancingExperience;
  isSelected: boolean;
};

type analyticProperties = {
  paymentAmount: number;
  installmentAmountPresented: number;
  payBillFlowUUID: string | undefined;
  financingExperience?: FinancingExperience;
};

const onInstallmentsClickAnalyticsTrack = ({
  financingExperience,
  paymentAmount,
  installmentAmountPresented,
  payBillFlowUUID,
}: analyticProperties) => {
  if (financingExperience === FinancingExperience.FTX) {
    analytics.track(financingPage.addFunding, financingAnalyticsEvents.installmentsClicked, {
      paymentAmount,
      installmentAmountPresented,
      ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
    });
  }
};

export const FinancingFundingSourceCard = ({
  paymentAmount,
  installmentAmount,
  onClick,
  isSelected,
  financingExperience,
}: Props) => {
  const { isOpen, onOpen, onClose: handleClose } = useDisclosure();
  const { payBillFlowUUID } = useSelector(getPayment);
  const bill = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const { navigate } = useNavigator();

  useEffect(() => {
    analytics.track(financingPage.addFunding, financingAnalyticsEvents.financingExposed, {
      financingExperience,
      paymentAmount,
      installmentAmountPresented: installmentAmount,
      installmentsEnabled: true,
      ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
    });
  }, [financingExperience, paymentAmount, installmentAmount, payBillFlowUUID]);

  const onInstallmentsClick = () => {
    onInstallmentsClickAnalyticsTrack({
      financingExperience,
      paymentAmount,
      installmentAmountPresented: installmentAmount,
      payBillFlowUUID,
    });
    onClick();
  };

  const handleOpenModal = (event: React.MouseEvent<HTMLHeadingElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onOpen();
  };

  const handleApply = () => {
    const location =
      financingExperience === FinancingExperience.STX
        ? billLocations.pay.installments.scheduleFlow
        : billLocations.pay.installments.applicationFlow;
    const path = generatePath(location, {
      orgId,
      billId: bill.id,
    });

    navigate(path);
  };

  return (
    <>
      <RadioSelectOptionContainer
        data-testid="radio-select-method-installments"
        onClick={onInstallmentsClick}
        className={isSelected ? 'selected-funding-source' : undefined}
        isSelected={isSelected}
      >
        <Flex flex={1}>
          <MethodOptionIcon name="icon-installments" disabled={false} />
          <Flex flexDirection="column" w="full">
            <MethodOptionLabel>
              <Flex alignItems="center">
                <MIFormattedText label="financing.fundingSource.title" />
                {financingExperience === FinancingExperience.FTX ? (
                  <Flex mb={0.5} pl={2} alignItems="center">
                    <Badge
                      label="deliveryMethods.newTag"
                      variant={BadgeVariants.OUTLINED}
                      color={BadgeColors.PRIMARY}
                    />
                  </Flex>
                ) : null}
              </Flex>
            </MethodOptionLabel>

            <Flex alignItems="flex-start" flexWrap="wrap" direction="column" mt={1}>
              <MethodOptionDescription>
                <Box>
                  <MIFormattedText
                    label={
                      financingExperience === FinancingExperience.FTX
                        ? 'financing.fundingSource.description.applyWith'
                        : 'financing.fundingSource.description.poweredBy'
                    }
                    values={{
                      howItWorks: (
                        <Box as="span" color="primary.500" onClick={handleOpenModal} data-testid="how-it-works">
                          <MIFormattedText label="financing.fundingSource.howItWorksLink" />
                        </Box>
                      ),
                    }}
                  />
                </Box>
              </MethodOptionDescription>
            </Flex>
          </Flex>
        </Flex>
      </RadioSelectOptionContainer>
      <FinancingFundingSourceModal
        isOpen={isOpen}
        learnMoreLinkAnalytics={{
          paymentAmount,
          installmentAmountPresented: installmentAmount,
          payBillFlowUUID,
        }}
        onClose={handleClose}
        onApply={handleApply}
      />
    </>
  );
};
