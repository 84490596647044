import { useSelector } from 'react-redux';
import useUpdateUserPreference from 'src/modules/users/hooks/useUpdateUserPreferences';
import { getUserPreferences } from 'src/redux/user/selectors';
import { UserPreferencesKey } from 'src/utils/consts';
import { UserPreferencesType } from 'src/utils/types';

export const useUserPreference = <Key extends UserPreferencesKey>({
  userPreferenceKey: key,
}: {
  userPreferenceKey: Key;
}) => {
  const userPreferences = useSelector(getUserPreferences);

  const { updateUserPreference } = useUpdateUserPreference();
  const setUserPreferenceValue = (value: UserPreferencesType[typeof key]) =>
    updateUserPreference({
      key,
      value: value ?? '',
    });

  return {
    userPreferenceValue: userPreferences[key],
    setUserPreferenceValue,
  };
};
