import styled from 'styled-components';
import { devices } from '../../theme/appDevices';

const MITable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  @media ${devices.nonMobile} {
    border-radius: 0.7rem;
    border: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  }
`;

const MITableHeaderCell = styled.th`
  height: 3.4rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${(props) => props.theme.text.color.label};
  background-color: ${(props) => props.theme.colors.white.veryLightGrey};
  text-align: left;

  &:first-child {
    @media ${devices.nonMobile} {
      border-top-left-radius: 0.7rem;
    }
  }

  &:last-child {
    @media ${devices.nonMobile} {
      border-top-right-radius: 0.7rem;
    }
  }

  ${(props) => props.theme.pages?.Settings?.MITableHeaderCell}
`;

const MITableBodyCell = styled.td`
  height: 5rem;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.dark.opaque};

  @media ${devices.mobile} {
    border-bottom: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  }

  @media ${devices.nonMobile} {
    border-top: 0.1rem solid ${(props) => props.theme.colors.border.grey};
  }
`;

const MITableRow = styled.tr``;

const MITableHead = styled.thead``;

const MITableBody = styled.tbody``;

export { MITable, MITableBody, MITableBodyCell, MITableHead, MITableHeaderCell, MITableRow };
