import { getBillsSearchPath } from 'src/utils/bills';
import { BillStatus } from 'src/utils/consts';
import { billPayLocations } from './pay/locations';

export const billLocations = {
  base: ['/orgs/:orgId/bills/new', ...billPayLocations.base],
  index: '/orgs/:orgId/bills',
  view: `/orgs/:orgId/bills?id=:id&${getBillsSearchPath({ status: BillStatus.UNPAID })}`,
  create: {
    index: '/orgs/:orgId/bills/new',
    options: '/orgs/:orgId/bills/new/create-options',
  },
  createScannedInvoice: '/orgs/:orgId/bills/scanned-invoices/new',
  filteredView: '/orgs/:orgId/bills?id=:id&status=:status&sorting=:sorting&start=:start&limit=:limit',
  filteredViewWithoutId: '/orgs/:orgId/bills?status=:status&sorting=:sorting&start=:start&limit=:limit',
  pay: billPayLocations,
  refund: '/orgs/:orgId/bills/:billId/payment/:paymentId/refund/',
  successScheduledRefund: '/orgs/:orgId/bills/:billId/payment/:paymentId/refund-success',
} as const;

export type FilteredViewLocation = typeof billLocations.filteredView | typeof billLocations.filteredViewWithoutId;
export type FilteredViewQueryParams = Record<'orgId' | 'start' | 'limit', string | number> & {
  status: BillStatus;
  sorting: string;
};
