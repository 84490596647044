import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';
import { mapMfaFailedCodeVerificationNotifications, MfaCodeVerificationState } from '../consts';

type Props = {
  authVerificationCodeStatus: MfaCodeVerificationState;
  isLastVerificationAttemptDone: boolean;
  onPrev: () => void;
};

export const MfaNotificationCard = ({ authVerificationCodeStatus, isLastVerificationAttemptDone, onPrev }: Props) => {
  const status = isLastVerificationAttemptDone
    ? MfaCodeVerificationState.MAX_ATTEMPTS_REACHED
    : authVerificationCodeStatus;
  const notificationData = mapMfaFailedCodeVerificationNotifications[status];

  if (!notificationData) return null;

  const title = notificationData?.title;
  const subtitle = notificationData?.subtitle;

  return (
    <Box mb={{ base: 5, sm: 10 }} mt={{ base: 0, sm: 2 }}>
      <MINotificationCard
        type={NotificationCardTypes.ERROR}
        title={title ? { label: title } : undefined}
        subtitle={{
          label: subtitle,
          values: {
            startOver: (
              <Box
                data-testid="notification-start-over-link"
                as="a"
                cursor="pointer"
                onClick={onPrev}
                textDecor="underline"
              >
                <MIFormattedText label="guests.mfaVerification.notification.startOver" />
              </Box>
            ),
          },
        }}
      />
    </Box>
  );
};
