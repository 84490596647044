import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';

type Props = {
  userId: string | number;
  onSave: (id: string | number) => void;
  onCancel: () => void;
  isLoading: boolean;
};

export const RoleDowngradeModalMessageButtons = ({ userId, onSave, onCancel, isLoading }: Props) => {
  const handleSaveClick = () => {
    onSave(userId);
  };

  return (
    <ModalButtonsWrapper>
      <Button
        label="user.roleDowngradeModal.cancel"
        variant={ButtonVariants.tertiaryNaked}
        size={ButtonSizes.lg}
        onClick={onCancel}
      />
      <Button
        isLoading={isLoading}
        label="user.roleDowngradeModal.submit"
        size={ButtonSizes.lg}
        onClick={handleSaveClick}
      />
    </ModalButtonsWrapper>
  );
};
