import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import useGetDeliveryMethodDisplayName from 'src/modules/delivery-methods/hooks/useGetDeliveryMethodDisplayName';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { NotificationCardTypes } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import DeliveryMethodCard from './components/DeliveryMethodCard';
import WizardBankAccountSelectField from './components/WizardBankAccountSelectField';
import { NEW_AP_BANK_ACCOUNT_ID } from './consts';
import {
  BankAccountFormType,
  CreateAndLinkExistingBankAccountRequest,
  LinkToExistingBankAccountRequest,
} from './types';

type Props = {
  onExit: () => void;
  onNext: () => void;
  progressBarProps: Record<string, any>;
};

export const ReceivingMethodLinkBankAccountPage = ({ onExit, onNext, progressBarProps }: Props) => {
  const dispatch = useDispatch();
  const locationState = useLocation<{ editLink: boolean }>().state;
  const editLink = locationState?.editLink;
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const platformName = connectedAccountingPlatform?.name;
  const match = useRouteMatch<{ deliveryMethodId?: string }>();
  const orgId = useSelector(getOrgId);
  const deliveryMethodId = match.params?.deliveryMethodId || '';
  const apActions = useStoreActions(accountingPlatformsStore);
  const bankAccounts = useSelector(accountingPlatformsStore.selectors.listBankAccounts.bankAccounts);
  const isBankAccountsLoading = useSelector(accountingPlatformsStore.selectors.listBankAccounts.loading);
  const isBankAccountsFetchError = useSelector(accountingPlatformsStore.selectors.listBankAccounts.error);
  const accountingSoftwareName = platformName && getAccountingSoftwareDisplayName(platformName);
  const accountingSoftwareDisplayShortName = accountingSoftwareName?.displayShortName;

  const isCreateAndLinkBankAccountLoading = useSelector(
    accountingPlatformsStore.selectors.createAndLinkBankAccount.loading
  );

  const { onApiCall: linkToBankAccount, loading: linkingBankAccount } = useApi<[LinkToExistingBankAccountRequest], any>(
    {
      api: accountingPlatformsApi.linkToExistingBankAccount,
      throwError: false,
    }
  );

  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId));
  const { routingNumber, accountNumber } = deliveryMethod?.bankAccount;
  const deliveryMethodDisplayName = useGetDeliveryMethodDisplayName(deliveryMethod);
  const [isFirstBankAccount, setIsFirstBankAccount] = useState(false);

  const refreshDeliveryMethods = useCallback(
    async () =>
      new Promise((resolve, reject) => {
        dispatch(loadDeliveryMethodsAction(resolve, reject));
      }),
    [dispatch]
  );

  const loadBankAccounts = useCallback(async (orgId: number) => apActions.listBankAccounts({ orgId }), [apActions]);

  const createAndLinkBankAccount = useCallback(
    async ({ orgId, deliveryMethodId, name, routingNumber, accountNumber }: CreateAndLinkExistingBankAccountRequest) =>
      apActions.createAndLinkBankAccount({
        orgId,
        deliveryMethodId,
        name,
        routingNumber,
        accountNumber,
      }),
    [apActions]
  );

  const loadAccountsAndLink = useCallback(async () => {
    try {
      const response = await loadBankAccounts(orgId);

      if (
        response.payload.bankAccounts?.length === 0 &&
        !isEmpty(routingNumber) &&
        !isEmpty(accountNumber) &&
        !editLink
      ) {
        capture(new Error('Fetched 0 bank accounts from accounting platform'), {
          orgId,
          platformName,
        });
        setIsFirstBankAccount(true);
        await createAndLinkBankAccount({
          orgId,
          deliveryMethodId,
          name: deliveryMethodDisplayName,
          routingNumber,
          accountNumber,
        });
        await refreshDeliveryMethods();
        analytics.trackAction('create-and-link-first-bank-account');
      }
    } catch (e: any) {
      analytics.trackAction('error-load-bank-accounts');
      capture(new Error('Error loading bank accounts on delivery method reconcile page'), e);
    }
  }, [orgId, editLink, loadBankAccounts]);

  useEffect(() => {
    loadAccountsAndLink();
  }, [loadAccountsAndLink, loadBankAccounts, orgId]);

  const onSubmitBankAccount = async ({ bankAccountId }: BankAccountFormType) => {
    if (bankAccountId && deliveryMethod?.bankAccount) {
      if (bankAccountId === NEW_AP_BANK_ACCOUNT_ID) {
        try {
          await createAndLinkBankAccount({
            orgId,
            deliveryMethodId,
            name: deliveryMethodDisplayName,
            routingNumber,
            accountNumber,
          });
          analytics.trackAction('create-and-link-bank-account');
        } catch (err) {
          analytics.trackAction('error-load-bank-accounts');
          capture(err as Error);
          onExit();
        }
      } else {
        await linkToBankAccount({
          orgId,
          deliveryMethodId,
          accountId: bankAccountId,
        });
        analytics.trackAction('link-bank-account');
      }

      await refreshDeliveryMethods();
    }

    onNext();
  };

  const model = useMemo(() => ({ bankAccountId: null }), []);
  const [bankAccountVM, { submit }] = useForm<BankAccountFormType>(model, {
    submit: onSubmitBankAccount,
  });

  if (isBankAccountsLoading) {
    return <AreaLoader />;
  }

  if (isBankAccountsFetchError) {
    return (
      <StepLayoutPage
        title="linkBankAccountReceivingMethod.link.title"
        subtitle="linkBankAccountReceivingMethod.link.error"
        subTitleValues={{ platformLabel: accountingSoftwareDisplayShortName }}
        hideHeader
        isLoading={linkingBankAccount || isCreateAndLinkBankAccountLoading}
        goExit={onExit}
        {...progressBarProps}
      />
    );
  }

  if (isFirstBankAccount) {
    return (
      <SuccessLayoutPage
        titleValues={{ platformName }}
        title="linkBankAccountReceivingMethod.createSuccess.title"
        text="linkBankAccountReceivingMethod.createSuccess.subtitle"
        textValues={{
          displayName: deliveryMethodDisplayName,
          platformName,
        }}
        buttonLabel="linkBankAccountReceivingMethod.createSuccess.cta"
        buttonAction={onNext}
        hideHeader
        isLoading={isCreateAndLinkBankAccountLoading}
      />
    );
  }

  return (
    <StepLayoutPage
      title="linkBankAccountReceivingMethod.link.title"
      subtitle="linkBankAccountReceivingMethod.link.subtitle"
      subTitleValues={{ platformLabel: accountingSoftwareDisplayShortName }}
      goExit={onExit}
      onNext={submit}
      nextLabel="linkBankAccountReceivingMethod.link.cta"
      isNextDisabled={!bankAccountVM.bankAccountId.value}
      fullWidthCTA
      hideHeader
      isLoading={linkingBankAccount || isCreateAndLinkBankAccountLoading}
      {...progressBarProps}
    >
      <MainContentContainer>
        {bankAccountVM.bankAccountId.value === NEW_AP_BANK_ACCOUNT_ID && (
          <InfoNotificationCard
            type={NotificationCardTypes.INFO}
            subtitle={{
              label: 'linkBankAccountReceivingMethod.link.newAccountNotification',
              values: {
                displayName: deliveryMethodDisplayName,
                platformLabel: accountingSoftwareDisplayShortName,
              },
            }}
          />
        )}
        <WrappedDeliveryMethodCard method={deliveryMethod} />
        <FormRow>
          <WizardBankAccountSelectField
            deliveryMethod={deliveryMethod}
            platformName={platformName}
            bankAccounts={bankAccounts}
            model={bankAccountVM.bankAccountId}
          />
        </FormRow>
      </MainContentContainer>
    </StepLayoutPage>
  );
};

const InfoNotificationCard = styled(MINotificationCard)`
  margin-bottom: 2rem;
`;

const WrappedDeliveryMethodCard = styled(DeliveryMethodCard)`
  margin-bottom: 2rem;
`;

const MainContentContainer = styled.div`
  margin-top: -0.5rem;
`;
