import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { registrationLocations } from 'src/pages/auth/registration/locations';
import { analytics } from 'src/services/analytics';
import { DeliveryType } from 'src/utils/consts';
import { CompanyInfoType, DeliveryMethodType, PaymentType, VendorType } from 'src/utils/types';
import { getSignUpCTA, getSignUpSubtitle, getSignUpTitle } from '../utils';

export type UseSignUpOfferProps = {
  payment?: PaymentType;
  deliveryMethod?: DeliveryMethodType;
  vendor?: VendorType;
  organization?: CompanyInfoType;
};

export const useSignUpOffer = ({ organization, payment, deliveryMethod, vendor }: UseSignUpOfferProps) => {
  const [historyPush] = useHistoryWithOrgId();
  const isAchMethod = deliveryMethod?.deliveryType === DeliveryType.ACH;
  const paymentId = payment?.id;
  const analyticsProps = {
    flow: payment ? 'Unilateral with payment' : 'Unilateral without payment',
    vendorId: vendor?.id,
    vendorEmail: vendor?.contactEmail,
    payorOrgId: organization?.id,
  };

  const titleLabel = getSignUpTitle({ paymentId, isAchMethod });
  const subTitleLabel = getSignUpSubtitle({ paymentId, isAchMethod });
  const learnMoreLabel = 'guests.register.learnMore';
  const shouldShowLearnMore = isAchMethod;
  const signUpButtonLabel = getSignUpCTA({ paymentId, isAchMethod });

  const handleLearnMoreClick = () => {
    analytics.track('unilateral', 'learn-more', analyticsProps);
    window.open('https://www.meliopayments.com/request');
  };

  const handleSignUpClick = () => {
    analytics.track('unilateral', 'go-register', analyticsProps);
    historyPush({
      path: registrationLocations.vendorRegister,
      state: {
        email: vendor?.contactEmail,
        vendorIdToConvertToOwned: vendor?.id,
      },
    });
  };

  return {
    titleLabel,
    subTitleLabel,
    learnMoreLabel,
    shouldShowLearnMore,
    handleLearnMoreClick,
    handleSignUpClick,
    signUpButtonLabel,
  };
};
