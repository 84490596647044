import { organizationsApi } from 'src/modules/organizations/api';
import { retry } from '../../utils/retry';
import { fetchRequest, postRequest } from './api';

export default {
  syncQuickBooksData(orgId) {
    return organizationsApi.runFullsync(orgId);
  },

  getAccountsForBill(orgId) {
    return organizationsApi.getAccountsForBill(orgId);
  },

  syncBillByOriginId(orgId, originId) {
    const url = `/orgs/${orgId}/quickbooks/sync/bill/${originId}`;

    return retry(() => fetchRequest(url), 500, 3);
  },
  requestCodatQbdLink(params) {
    const url = `/orgs/${params.orgId}/quickbooks-desktop/send-mobile-link`;

    return postRequest(url, params);
  },
};
