import { useParams } from 'react-router-dom';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';

type Props = {
  onNext: (deliveryMethodId?: string) => void;
  progressBarProps?: Record<string, any>;
};

export const ReceivingMethodCantFindBankAccountPage = ({ onNext, progressBarProps }: Props) => {
  const params = useParams<{ deliveryMethodId?: string }>();
  const deliveryMethodId = params?.deliveryMethodId || '';

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.bankQuestion}
      title="onboarding.fundingSources.bank.cantFind.title"
      text="onboarding.fundingSources.bank.cantFind.subtitle"
      buttonLabel="onboarding.fundingSources.bank.cantFind.next"
      buttonAction={() => onNext(deliveryMethodId)}
      hideHeader
      {...progressBarProps}
    />
  );
};
