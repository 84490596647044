import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type Props = {
  dateDescriptionIcon: React.ReactNode | null;
  label: string;
  values: Record<string, any>;
};

export const PaymentDateDescription = ({ dateDescriptionIcon, label, values }: Props) => (
  <Box textStyle="body4" color="grey.700" mt={1}>
    <Flex>
      {dateDescriptionIcon}
      <Box w="full">
        <MIFormattedText label={label} values={values} />
      </Box>
    </Flex>
  </Box>
);
