import { useMemo } from 'react';
import { Column } from 'react-table';
import { AmountCell } from 'src/components/shared/tables/PaymentsTable/components/AmountCell';
import { CustomerCell } from 'src/components/shared/tables/PaymentsTable/components/CustomerCell';
import { DeliveryDateCell } from 'src/components/shared/tables/PaymentsTable/components/DeliveryDateCell';
import { HeaderCell } from 'src/components/shared/tables/PaymentsTable/components/HeaderCell';
import { InvoiceNumberCell } from 'src/components/shared/tables/PaymentsTable/components/InvoiceNumberCell';
import { StatusCell } from 'src/components/shared/tables/PaymentsTable/components/StatusCell';
import { DateTableRowType } from 'src/components/shared/tables/PaymentsTable/types';
import { GetProTabs } from 'src/utils/consts';
import { DateCell } from '../components/DateCell';
import { DueDateCell } from '../components/DueDateCell';
import { InitiatedByCell } from '../components/InitiatedByCell';
import { InProgressActionCell } from '../components/InProgressActionCell';
import { InvoicesActionCell } from '../components/InvoicesActionCell';
import { PaidActionCell } from '../components/PaidActionCell';
import { RequestsActionCell } from '../components/RequestsActionCell';

const COLUMNS_BY_TYPE = {
  [GetProTabs.INVOICES]: [
    {
      Header: <HeaderCell label="getPro.table.columns.customer" />,
      accessor: 'customer',
      Cell: CustomerCell,
      width: 0.271,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.invoiceNumber" />,
      accessor: 'invoiceNumber',
      Cell: InvoiceNumberCell,
      width: 0.15,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.created" />,
      accessor: 'created',
      width: 0.18,
      Cell: DateCell,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.dueDate" />,
      accessor: 'dueDate',
      width: 0.15,
      Cell: DueDateCell,
      id: 'dueDate',
    },
    {
      Header: <HeaderCell label="getPro.table.columns.amount" />,
      accessor: 'amount',
      width: 0.15,
      Cell: AmountCell,
      isNumeric: true,
    },
    {
      id: 'invoices-action-cell',
      Cell: InvoicesActionCell,
      accessor: 'id',
      disableSortBy: true,
      width: 0.065,
    },
  ],
  [GetProTabs.REQUESTS]: [
    {
      Header: <HeaderCell label="getPro.table.columns.customer" />,
      accessor: 'customer',
      Cell: CustomerCell,
      width: 0.265,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.invoiceNumber" />,
      accessor: 'invoiceNumber',
      Cell: InvoiceNumberCell,
      width: 0.12,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.status" />,
      accessor: 'status',
      width: 0.225,
      Cell: StatusCell,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.dueDate" />,
      accessor: 'dueDate',
      width: 0.13,
      Cell: DueDateCell,
      id: 'dueDate',
    },
    {
      Header: <HeaderCell label="getPro.table.columns.amount" />,
      accessor: 'amount',
      width: 0.15,
      Cell: AmountCell,
      isNumeric: true,
    },
    {
      id: 'requests-action-cell',
      Cell: RequestsActionCell,
      accessor: 'id',
      disableSortBy: true,
      width: 0.065,
    },
  ],
  [GetProTabs.IN_PROGRESS]: [
    {
      Header: <HeaderCell label="getPro.table.columns.customer" />,
      accessor: 'customer',
      Cell: CustomerCell,
      width: 0.271,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.invoiceNumber" />,
      accessor: 'invoiceNumber',
      Cell: InvoiceNumberCell,
      width: 0.096,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.initiatedBy" />,
      accessor: 'initiatedBy',
      Cell: InitiatedByCell,
      width: 0.11,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.status" />,
      accessor: 'status',
      width: 0.212,
      Cell: StatusCell,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.deliveryDate" />,
      accessor: 'deliveryDate',
      width: 0.11,
      Cell: DeliveryDateCell,
      id: 'deliveryDate',
    },
    {
      Header: <HeaderCell label="getPro.table.columns.amount" />,
      accessor: 'amount',
      width: 0.12,
      Cell: AmountCell,
      isNumeric: true,
    },
    {
      id: 'in-progress-action-cell',
      width: 0.06,
      Cell: InProgressActionCell,
      accessor: 'id',
      disableSortBy: true,
    },
  ],
  [GetProTabs.PAID]: [
    {
      Header: <HeaderCell label="getPro.table.columns.customer" />,
      accessor: 'customer',
      Cell: CustomerCell,
      width: 0.271,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.invoiceNumber" />,
      accessor: 'invoiceNumber',
      Cell: InvoiceNumberCell,
      width: 0.096,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.initiatedBy" />,
      accessor: 'initiatedBy',
      Cell: InitiatedByCell,
      width: 0.11,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.status" />,
      accessor: 'status',
      width: 0.212,
      Cell: StatusCell,
    },
    {
      Header: <HeaderCell label="getPro.table.columns.deliveryDate" />,
      accessor: 'deliveryDate',
      width: 0.11,
      Cell: DeliveryDateCell,
      id: 'deliveryDate',
    },
    {
      Header: <HeaderCell label="getPro.table.columns.amount" />,
      accessor: 'amount',
      width: 0.12,
      Cell: AmountCell,
      isNumeric: true,
    },
    {
      id: 'paid-action-cell',
      width: 0.06,
      Cell: PaidActionCell,
      accessor: 'id',
      disableSortBy: true,
    },
  ],
};

export const useColumnsByType = (type: GetProTabs) => {
  const columns = useMemo<Column<DateTableRowType>[]>(() => COLUMNS_BY_TYPE[type] as Column<DateTableRowType>[], [
    type,
  ]);

  return [columns];
};
