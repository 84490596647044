import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { AddBankAccountFlow } from '../AddBankAccountFlow';
import { FLOW_ENTRY_POINT } from '../consts';
import { FlowCustomizationsType } from '../types';

export const AddBankAccountFromPayBatchWrapper = () => {
  const orgId = useOrgId();
  const billIds = useSelector(regularBatchPaymentsStore.selectors.getBillIds);
  const batchActions = useStoreActions(regularBatchPaymentsStore);

  const onFSBankAccountAdded = useCallback(
    async (newFundingSourceId) => {
      await batchActions.settings.update({
        scheduledDate: null,
        fundingSourceId: newFundingSourceId,
        billIds,
      });
    },
    [batchActions, billIds]
  );

  const flowCustomizations: FlowCustomizationsType = {
    flowEntryPoint: FLOW_ENTRY_POINT.PAY_BATCH,
    onFSBankAccountAdded,
    newAccountNotificationLabel: 'onboarding.fundingSources.bank.accountingPlatformAccounts.notification',
  };
  const saveAndContinuePath = generatePath(batchPaymentsLocations.base, { orgId });

  return <AddBankAccountFlow flowCustomizations={flowCustomizations} saveAndContinuePath={saveAndContinuePath} />;
};
