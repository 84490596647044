import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import config from 'src/config';
import { AddressType } from 'src/utils/types';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const { Lookup } = SmartyStreetsSDK.usAutocompletePro;
const credentials = new SmartyStreetsCore.SharedCredentials(config.services.smartystreets.key);
const client = SmartyStreetsCore.buildClient.usAutocompletePro(credentials);

type Suggestion = {
  city: string;
  entries: number;
  secondary: string;
  state: string;
  streetLine: string;
  zipcode: string;
};

const suggestionToAddress = (suggestion: Suggestion): AddressType => ({
  addressLine1: suggestion.streetLine,
  addressLine2: suggestion.secondary,
  city: suggestion.city,
  state: suggestion.state,
  zipCode: suggestion.zipcode,
});

export const getAddressSuggestion = async (search: string) => {
  if (isEmpty(search)) {
    return [];
  }

  const result = await client.send(new Lookup(search));

  return map(result.result, suggestionToAddress);
};
