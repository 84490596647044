import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { DialogType, DialogVariants } from 'src/utils/consts';
import { getFundingSourceName, getInstitutionName } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSourceToDelete: AccountType;
  onDeleteConfirmed: () => void;
  onDeletePaymentMethodCanceled: () => void;
};

export const DeletePaymentMethodDialog = ({
  fundingSourceToDelete,
  onDeleteConfirmed,
  onDeletePaymentMethodCanceled,
}: Props) => {
  let paymentMethodName = '';
  let paymentMethodNumber = '';

  if (fundingSourceToDelete.cardAccount) {
    paymentMethodName = fundingSourceToDelete.cardAccount.network;
    paymentMethodNumber = fundingSourceToDelete.cardAccount.card4digits;
  } else if (fundingSourceToDelete.bankAccount) {
    paymentMethodName = getInstitutionName(fundingSourceToDelete) || fundingSourceToDelete.displayName || '';
    paymentMethodNumber = getAccountNumber4digits(fundingSourceToDelete.bankAccount);
  }

  return (
    <Dialog
      type={DialogType.CONFIRM}
      variant={DialogVariants.ERROR}
      title="settings.paymentMethods.deleteDialog.title"
      subtitle="settings.paymentMethods.deleteDialog.subtitle"
      subtitleValues={{
        paymentMethodName: <strong>{paymentMethodName}</strong>,
        paymentMethodNumber: <strong>...{paymentMethodNumber}</strong>,
      }}
      titleValues={{
        fundingType: <MIFormattedText label="settings.paymentMethods.ach" />,
        displayName: getFundingSourceName(fundingSourceToDelete),
      }}
      okButtonText="settings.paymentMethods.deleteDialog.confirm"
      onOkAction={onDeleteConfirmed}
      onCancelAction={onDeletePaymentMethodCanceled}
    />
  );
};
