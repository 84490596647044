/* eslint-disable @typescript-eslint/no-non-null-assertion */
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { withNavigator } from 'src/hoc';
import { profileStore } from 'src/modules/profile/profile-store';
import { PartialPaymentAmount } from 'src/pages/bill/pay/components/PartialPaymentAmount/PartialPaymentAmount';
import { usePayBillFinancingData } from 'src/pages/bill/pay/hooks/usePayBillFinancingData';
import { useFundingSourceMicroDepositState } from 'src/pages/settings/hooks/useFundingSourceMicroDeposits';
import { useOrgDefaultFundingSource } from 'src/pages/settings/hooks/useOrgDefaultFundingSource';
import { isInternationalVendor } from 'src/pages/vendor/international-delivery-method/utils';
import { getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { Site } from 'src/sites/site';
import { canPartiallyPayBill, getBillBalance } from 'src/utils/bills';
import { isCardExpired } from 'src/utils/card';
import { CreditCardFeePayment, FundingType, NotificationCardTypes } from 'src/utils/consts';
import { convertNumberToCurrency } from 'src/utils/currency-utils';
import { getCurrentFailedInstallment, hasFailedInstallment } from 'src/utils/financing';
import { useLocationState } from 'src/utils/hooks';
import { AccountType, BillType, VendorType } from 'src/utils/types';

type Props = {
  onPrev: () => void;
  goExit: () => void;
  vendor: VendorType;
  isLoading?: boolean;
  fundingSources: readonly AccountType[];
  fundingSource?: AccountType;
  onFundingSourceChange: (fundingSource: AccountType) => void;
  goAddSelectedFundingSource: (value: string) => void;
  setPaymentAmount: (amount: number) => void;
  bill: BillType;
  isRecurring: boolean;
  verifyingId?: number;
  site: Site;
  onVerifyClicked: (id: number) => void;
  onVerifyFinished: () => void;
  onSetPaymentAmount: () => void;
  onSubmit: (submitWithoutVerifyingMicroDeposit: boolean) => void;
  onChangePartialPaymentAmount: (value: string) => void;
  onBlur: (value: string) => void;
  showWarning: boolean;
  isVendorEnableCCPayments: boolean;
  partialPaymentAmount: string;
  paymentAmountToSubmit: string;
  showInstallments: boolean;
  disabledInstallments: boolean;
};

const sxForCard: CSSObject = {
  mb: 10,
};

const PayBillFundingSourcePage = ({
  vendor,
  isLoading,
  fundingSources,
  fundingSource,
  onFundingSourceChange,
  bill,
  goExit,
  goAddSelectedFundingSource,
  onPrev,
  isRecurring,
  verifyingId,
  onVerifyFinished,
  onVerifyClicked,
  showWarning,
  isVendorEnableCCPayments,
  onSetPaymentAmount,
  partialPaymentAmount,
  onChangePartialPaymentAmount,
  onBlur,
  onSubmit,
  paymentAmountToSubmit,
  showInstallments,
  disabledInstallments,
}: Props) => {
  const payment = useSelector(getPayment);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const selectedFundingSource = useSelector(getSelectedFundingSource);
  const { orgDefaultFundingSourceId } = useOrgDefaultFundingSource();
  const onVerify = permissions.deliveryMethod.verify() ? onVerifyClicked : undefined;
  const microDepositEventPage = 'pay-bill-verify-manual-account';
  const microDepositProps: any = {
    key: verifyingId,
    fundingSourceId: verifyingId,
    onVerifyFinished,
  };
  const [state, actions] = useFundingSourceMicroDepositState(microDepositEventPage, microDepositProps);
  const isVendorAbsorbedFee = bill?.paymentRequest?.feesPaidBy === CreditCardFeePayment.VENDOR;
  const billBalance = getBillBalance(bill, [payment?.id]);
  const [editAmount] = useLocationState('editAmount', false);
  const showChangePartialAmountInput = canPartiallyPayBill(bill, isRecurring, !!payment?.id);

  const isInternational = isInternationalVendor(vendor);

  const { isInstallmentSelected } = usePayBillFinancingData();
  const isFailedInstallment = payment && hasFailedInstallment(payment);
  const relativeStep = isFailedInstallment ? 1 / 2 : 1 / 5;
  const isCtaDisabled = !isInstallmentSelected && !fundingSource;

  const onAddSelectedFundingSource = (value: string) => {
    onSetPaymentAmount();
    goAddSelectedFundingSource(value);
  };

  const renderFooter = (isCtaDisabled: boolean, isLoading?: boolean) => (
    <FooterContainer>
      <Box mb={6}>
        <MISecurityDetails eventPage="pay-bill" showIconCC />
      </Box>
      {!isEmpty(fundingSources) && (
        <WizardElements.WizardStepActionsContainer>
          <WizardElements.WizardButtonContainer isProgressBar width="22.5rem">
            <Button
              size={ButtonSizes.lg}
              onClick={() => onSubmit(false)}
              label="progress.continue"
              isDisabled={isCtaDisabled}
              isLoading={isLoading}
            />
          </WizardElements.WizardButtonContainer>
        </WizardElements.WizardStepActionsContainer>
      )}
    </FooterContainer>
  );

  const filteredFundingSources = payment?.isFinanced
    ? fundingSources.filter((fs) => fs.fundingType === FundingType.ACH && fs.isVerified)
    : fundingSources.filter((fs) => !(fs.fundingType === 'card' && (!fs.isVerified || isCardExpired(fs.cardAccount))));

  const title = useMemo(() => {
    if (isFailedInstallment) {
      return 'installment.pay.fundingSource.title';
    }

    return showChangePartialAmountInput
      ? 'bills.pay.fundingSource.partialPayment.title'
      : 'bills.pay.fundingSource.title';
  }, [isFailedInstallment, showChangePartialAmountInput]);

  const subtitle = useMemo(() => {
    if (isFailedInstallment) {
      return 'installment.pay.fundingSource.subtitle';
    }

    return isRecurring ? 'bills.pay.fundingSource.recurringSubtitle' : 'bills.pay.fundingSource.subtitle';
  }, [isFailedInstallment, isRecurring]);

  const subTitleValues = useMemo(() => {
    if (isFailedInstallment) {
      const installment = getCurrentFailedInstallment(payment.financing!.installments)!;

      return {
        index: installment?.installmentNumber,
        total: payment.financing!.installments.length,
        amount: convertNumberToCurrency(installment.amount),
      };
    }

    return {
      amount: (
        <strong>
          <MIFormattedCurrency value={partialPaymentAmount?.toString() || ''} privateData />
        </strong>
      ),
      companyName: <strong>{vendor.companyName}</strong>,
    };
  }, [isFailedInstallment, partialPaymentAmount, payment.financing, vendor.companyName]);

  const label = 'qbo.header.title';
  const values = {
    amount: (
      <strong>
        <MIFormattedCurrency value={bill.totalAmount} />
      </strong>
    ),
    companyName: <strong>{bill.vendor?.companyName}</strong>,
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  return (
    <StepLayoutPage
      headerLabel={label}
      headerLabelValues={values}
      title={title}
      titleValues={{
        companyName: vendor.companyName,
      }}
      subtitle={showChangePartialAmountInput ? '' : subtitle}
      subTitleValues={subTitleValues}
      goExit={goExit}
      onPrev={onPrev}
      relativeStep={relativeStep}
      isLoading={isLoading}
      footer={renderFooter(isCtaDisabled, isLoading)}
      testId="funding-source"
    >
      {showChangePartialAmountInput && (
        <PartialPaymentAmount
          partialBillAmount={partialPaymentAmount}
          editAmount={editAmount}
          billBalance={billBalance}
          onChange={onChangePartialPaymentAmount}
          onBlur={onBlur}
          disabled={bill.qboSyncVersion === 1}
        />
      )}
      {showWarning && (
        <InfoNotificationCard
          type={NotificationCardTypes.INFO}
          title={{
            label: 'bills.status.paymentFailedActionRequired',
          }}
          subtitle={{
            label: 'bills.pay.fundingSource.warningMessage',
            values: {
              date: <MIFormattedDate date={bill.dueDate} />,
            },
          }}
        />
      )}
      {isFailedInstallment && (
        <InfoNotificationCard
          type={NotificationCardTypes.INFO}
          title={{
            label: 'installment.pay.fundingSource.warningMessageTitle',
          }}
          subtitle={{
            label: 'installment.pay.fundingSource.warningMessage',
          }}
        />
      )}
      <CustomFeed feedType="paymentmethods_above" sxForCard={sxForCard} />
      <FundingSourcesListLayout
        value={fundingSource}
        fundingSources={filteredFundingSources}
        defaultFundingSourceId={orgDefaultFundingSourceId}
        firstFundingSourceId={selectedFundingSource?.id}
        onChange={onFundingSourceChange}
        onAddMethod={onAddSelectedFundingSource}
        onVerifyClicked={onVerify}
        isVendorEnableCCPayments={isVendorEnableCCPayments}
        isVendorAbsorbedFee={isVendorAbsorbedFee}
        isInternationalVendor={isInternational}
        vendorId={vendor.id!}
        totalAmount={+paymentAmountToSubmit}
        showInstallments={showInstallments}
        disabledInstallments={disabledInstallments}
        showAddAnotherOption={!payment?.isFinanced}
      />
      {verifyingId && (
        <VerifyMicroDeposits
          {...state}
          {...actions}
          key={verifyingId}
          verifyingId={verifyingId}
          onVerifyFinished={onVerifyFinished}
          eventPage={microDepositEventPage}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
          onContinueWithoutVerifying={() => onSubmit(true)}
        />
      )}
    </StepLayoutPage>
  );
};

PayBillFundingSourcePage.defaultProps = {
  isLoading: false,
};

const FooterContainer = styled.div`
  ${(props) => props.theme?.pages?.PayFundingSourcePage?.FooterContainer}
`;

const InfoNotificationCard = styled(MINotificationCard)`
  margin-bottom: 2rem;
`;

export default compose(withNavigator())(PayBillFundingSourcePage);
