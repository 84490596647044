import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { compose } from 'recompose';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { billLocations } from 'src/pages/bill/locations';
import EditBankDeliveryMethodPage from 'src/pages/vendor/delivery-methods/components/EditBankDeliveryMethodPage';
import { checkBankAccount } from 'src/pages/vendor/delivery-methods/utils';
import { bankFactory } from 'src/pages/vendor/records';
import { selectNewDeliveryMethodAction, selectPaymentDatesAction } from 'src/redux/payBillWizard/actions';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getFundingSources, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import {
  BANK_ACCOUNT_EXIST,
  DeliveryMethodOrigin,
  DeliveryType,
  FailedPaymentMessage,
  PaymentStatus,
} from 'src/utils/consts';
import { getLatestPayment } from 'src/utils/payments';
import { BankType, DeliveryMethodType, FieldType } from 'src/utils/types';
import { FAILED_TO_DELIVER_ERR } from '../consts';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type Props = PayBillProps;

const eventPage = 'edit-ach-delivery-method';

export const PlainEditAchDeliveryMethodPage = ({ id, navigate }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const {
    id: paymentId,
    deliveryMethodId,
    vendorId,
    fundingSourceId,
    amount,
    scheduledDate,
    deliveryPreference,
  } = payment;
  const orgId = useSelector(getOrgId);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const userFundingSources = useSelector(getFundingSources);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const bankAccount =
    useSelector<any, DeliveryMethodType | undefined>(deliveryMethodsStore.selectors.byId(deliveryMethodId))
      ?.bankAccount ?? undefined;
  const [bank, setBank] = useState(bankFactory(bankAccount));
  const [isBankChanged, setIsBankChanged] = useState(false);
  const vendorName = bill.vendor?.companyName;
  const titleValues = { vendor: vendorName };
  const [isLoading, setIsLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorCode, setErrorCode] = useState('');
  const origin = DeliveryMethodOrigin.EDIT_PAYMENT as DeliveryMethodOrigin;

  const fetchDeliveryMethod = useCallback(async () => {
    if (deliveryMethodId) {
      await deliveryMethodActions.fetch({
        orgId,
        vendorId,
        id: deliveryMethodId,
      });
    }
  }, [deliveryMethodId, orgId, vendorId, deliveryMethodActions]);

  const fetchExpeditedAchDeliveryDates = useCallback(async () => {
    const { deliveryDate, maxDeliveryDate } = await deliveryApi.getExpeditedAchDeliveryDates(
      orgId,
      deliveryMethodId,
      fundingSourceId,
      amount
    );

    dispatch(selectPaymentDatesAction(scheduledDate, deliveryDate, maxDeliveryDate, deliveryPreference));
  }, [deliveryMethodId, orgId, amount, deliveryPreference, dispatch, fundingSourceId, scheduledDate]);

  useEffect(() => {
    if (payment?.id) {
      const { status, metadata } = payment;
      const isReceiverRefusedCredit =
        status === PaymentStatus.FAILED &&
        metadata?.failureMessage === FailedPaymentMessage.RECEIVER_REFUSED_CREDIT_ENTRY;

      if (isReceiverRefusedCredit) {
        fetchExpeditedAchDeliveryDates();
        navigate(generatePath(billLocations.pay.edit.confirm, { orgId, billId: bill.id, paymentId }));
      }
    }
  }, [payment, bill]);

  useEffect(() => {
    if (bankAccount) {
      setBank(bankAccount);
    }
  }, [bankAccount]);

  useEffect(() => {
    if (deliveryMethodId) {
      fetchDeliveryMethod();
    }
  }, [deliveryMethodId, fetchDeliveryMethod]);

  const onChange = ({ id, value }: FieldType) => {
    setBank(
      (prevBank) =>
        ({
          ...prevBank,
          [id]: value,
        } as BankType)
    );

    if (!isBankChanged) {
      setIsBankChanged(true);
    }
  };

  const getValidationsError = () => {
    let validationErrors = getValidationErrors('deliveryMethodAch', bank);

    if (isValidationOk(validationErrors) && Number(vendorId) !== Number(ownedVendorId) && bank) {
      validationErrors = checkBankAccount(bank, userFundingSources);
    }

    return validationErrors;
  };

  const updateDeliveryMethod = async (deliveryMethod) => {
    const { payload } = await deliveryMethodActions.update({
      orgId,
      vendorId,
      id: deliveryMethodId,
      deliveryMethod,
    });
    await dispatch(selectNewDeliveryMethodAction(payload));

    analytics.track(eventPage, 'edit-delivery-method-success', {
      type: deliveryMethod.deliveryType,
      partialBillId: getBillPaymentIndex(bill),
    });
  };

  const onDone = async () => {
    setIsLoading(true);
    const originPayment = getLatestPayment(bill.payments);
    const validationErrors = getValidationsError();
    setValidationErrors(validationErrors);

    const newDeliveryMethod = {
      deliveryType: DeliveryType.ACH,
      bankAccount: bank,
    };

    if (isValidationOk(validationErrors)) {
      try {
        await fetchExpeditedAchDeliveryDates();

        if (originPayment?.deliveryMethodId === deliveryMethodId || isBankChanged) {
          await updateDeliveryMethod(newDeliveryMethod);
        }

        historyPush({
          path: billLocations.pay.edit.confirm,
          params: { billId: id, paymentId },
        });
      } catch (err: any) {
        const errorCode = err?.error?.code || err?.code;

        if (errorCode === BANK_ACCOUNT_EXIST) {
          setErrorCode(FAILED_TO_DELIVER_ERR);
        } else {
          setErrorCode(errorCode);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      analytics.track(eventPage, 'edit-delivery-method-validation-error', {
        ...validationErrors,
        partialBillId: getBillPaymentIndex(bill),
      });
    }
  };

  const goExit = () => {
    history.goBack();
  };

  if (!deliveryMethodId) {
    return <AreaLoader placement="wizard" />;
  }

  return (
    <EditBankDeliveryMethodPage
      vendorName={vendorName}
      bank={bank}
      titleValues={titleValues}
      onDone={onDone}
      onChange={onChange}
      onPrev={goExit}
      goExit={goExit}
      isLoading={isLoading}
      validationErrors={validationErrors}
      origin={origin}
      errorCode={errorCode}
    />
  );
};

export const EditAchDeliveryMethodPage = compose(withPayBillData())(PlainEditAchDeliveryMethodPage);
