import { putRequest } from 'src/services/api/api';

type OrganizationPreferenceValue = string | boolean | number | null;

export type UpdateOrganizationPreferenceParamsType = {
  orgId: number;
  key: string;
  value: OrganizationPreferenceValue;
};

export type UpdateServiceFeesPreferenceParamsType = {
  orgId: number;
  value: boolean;
};

export const organizationPreferencesApi = {
  updateOrganizationPreference({ orgId, key, value }: UpdateOrganizationPreferenceParamsType): Promise<void> {
    const url = `/orgs/${orgId}/organization-preferences`;

    return putRequest(url, { key, value });
  },

  updateServiceFeesPreference({ orgId, value }: UpdateServiceFeesPreferenceParamsType): Promise<void> {
    const url = `/orgs/${orgId}/organization-preferences/service-fees`;

    return putRequest(url, { value: String(value) });
  },
};
