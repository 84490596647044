import { PageHeader } from 'src/components/common/PageHeader';
import { HeaderActions } from 'src/pages/bill/components/BillDetailsHeader';
import { HeaderAction } from 'src/utils/types';

type Props = {
  pathname: string;
  search: string;
  text: string;
  subtitle: React.ReactNode;
  headerActions: HeaderAction[];
};

export const InstallmentStatusMobile = ({ pathname, search, text, subtitle, headerActions }: Props) => (
  <PageHeader
    backNav={{
      pathname,
      search,
    }}
    text={text}
    subTitle={subtitle}
  >
    <HeaderActions headerActions={headerActions} isFullSingleView />
  </PageHeader>
);
