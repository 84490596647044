import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalTitle } from 'src/components/common/ModalMessage';

type Props = {
  companyName: string;
};

export const HideClientModalMessageTitle = ({ companyName }: Props) => (
  <ModalTitle>
    <MIFormattedText label="bookkeeper.company.hideClientDialog.title" values={{ companyName }} />
  </ModalTitle>
);
