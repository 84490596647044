import { isSameDay, parseISO } from 'date-fns';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { PayListItemType } from 'src/modules/bills/types';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { useUserPreference } from 'src/modules/users/hooks/useUserPreference';
import { PayListFilters } from 'src/pages/pay/components/filters/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { PayTabs, UserPreferencesKey } from 'src/utils/consts';

type UserPreferenceMessage = {
  count: number;
  lastShown: Date;
  userClosed: boolean;
};

const SHOW_LIMIT = 5;

const getMessageUserPreferences = (userPreferenceValue: string | undefined | null | boolean): UserPreferenceMessage => {
  const defaultValue = { count: 0, lastShown: new Date(0), userClosed: false };
  try {
    const value = JSON.parse(userPreferenceValue?.toString() || JSON.stringify(defaultValue));

    return { ...value, lastShown: parseISO(value.lastShown) };
  } catch (e) {
    return defaultValue;
  }
};

const shouldShowMessage = ({ count, userClosed }: UserPreferenceMessage) => !userClosed && count < SHOW_LIMIT;

const shouldUpdateUserPreference = (userPreferences: UserPreferenceMessage) =>
  !isSameDay(userPreferences.lastShown, new Date()) && shouldShowMessage(userPreferences);

type Props = {
  eventPage: string;
  currentTab: PayTabs;
  search: string | undefined;
  sorting: string | undefined;
  activeFilters: PayListFilters;
  isLoadingItems: boolean;
  items: PayListItemType[];
};

export type UsePayInboxMessageReturnType = {
  shouldShowAnnouncement: boolean;
  onClose: () => void;
  setAfterCloseMessage: (callback: () => void) => void;
};

export const useAnnouncements = ({
  eventPage,
  currentTab,
  search,
  sorting,
  activeFilters,
  isLoadingItems,
  items,
}: Props): UsePayInboxMessageReturnType => {
  const [afterCloseMessage, setAfterCloseMessage] = useState<() => void>(() => noop);
  const orgId = useSelector(getOrgId);

  // announcement settings
  const { userPreferenceValue, setUserPreferenceValue } = useUserPreference({
    userPreferenceKey: UserPreferencesKey.BillInboxAnnouncementShown,
  });
  const userPreference = useMemo(() => getMessageUserPreferences(userPreferenceValue), [userPreferenceValue]);

  // at least one paid payment
  const paymentsQueryParams = { orgId, status: PayTabs.PAID, limit: 1 };
  const payments = useSelector((state) => paymentsStore.selectors.list.value(state, paymentsQueryParams));
  const paymentsStatus = useSelector(paymentsStore.selectors.list.status(paymentsQueryParams));
  const paymentActions = useStoreActions(paymentsStore);

  // pay list in default state
  const allowedSorts = [undefined, null, '', 'mostRecent', 'payment.status,dueDate', 'mostRecent:desc'];
  const isInboxTab = currentTab === PayTabs.INBOX;
  const listInDefaultState =
    isEmpty(search) && every(activeFilters, (value) => isEmpty(value)) && allowedSorts.includes(sorting);

  const isShowEmptyPayList = !isLoadingItems && items.length === 0;

  const shouldLoadPaidPayments =
    isInboxTab && listInDefaultState && !isShowEmptyPayList && shouldShowMessage(userPreference);

  const shouldShowAnnouncement = shouldLoadPaidPayments && payments.length > 0;

  useEffect(() => {
    if (shouldLoadPaidPayments && paymentsStatus?.loading === undefined) {
      paymentActions.list(paymentsQueryParams);
    }
  }, [shouldLoadPaidPayments]);

  useEffect(() => {
    if (shouldShowAnnouncement && shouldUpdateUserPreference(userPreference)) {
      setUserPreferenceValue(
        JSON.stringify({ count: userPreference.count + 1, lastShown: new Date(), userClosed: false })
      );
    }
  }, [userPreference, shouldShowAnnouncement]);

  const onClose = useCallback(() => {
    setUserPreferenceValue(JSON.stringify({ ...userPreference, userClosed: true }));
    analytics.track(eventPage, 'close-pay-inbox-message');
    afterCloseMessage();
  }, [setUserPreferenceValue, afterCloseMessage, userPreference]);

  return {
    shouldShowAnnouncement,
    onClose,
    setAfterCloseMessage,
  };
};
