/* eslint-disable no-template-curly-in-string, max-len */
import { EventMappingsTree } from 'src/services/analytics/types';

const pageEvent = 'onboarding-vendor-company-info';

const MAPPING: EventMappingsTree = {
  '/orgs/*/meliome/welcome/business/legal-info': {
    'page.view': [pageEvent, 'legal-info'],
    'go.back': [`${pageEvent}-legal`, 'go-back'],
    '[ORGANIZATIONS] UPDATE_VALIDATION_FAILURE': [
      pageEvent,
      'update-legal-info-error',
      { error: 'error', email: 'payload.email' },
    ],
    '[ORGANIZATIONS] UPDATE_FAILURE': [
      pageEvent,
      'update-legal-info-error',
      { error: 'error', email: 'payload.email' },
    ],
    '[ORGANIZATIONS] UPDATE_SUCCESS': [pageEvent, 'update-legal-info-success'],
  },
  '/orgs/*/meliome/welcome/business/added': {
    'page.view': [pageEvent, 'added'],
    'copy-link-action': [pageEvent, 'meliome-link-previewed'],
    'onboarding.vendorCompanyInfo.added.next': [pageEvent, 'success-go-add-first-invoice'],
    'onboarding.vendorCompanyInfo.added.skip': [pageEvent, 'success-go-dashboard'],
  },
  '/orgs/*/meliome/welcome/business/absorb-fees-settings': {
    'absorb-fee-selection-changed': [pageEvent, 'absorb-fee-changed', { value: 'value' }],
    'absorb-fee-selection-submitted': [pageEvent, 'absorb-fee-submitted', { value: 'value' }],
  },
  '/orgs/*/meliome/welcome/business/name': {
    'page.view': [pageEvent, 'companyName'],
  },
  '/orgs/*/meliome/welcome/business/phone': {
    'page.view': [pageEvent, 'phone'],
  },
  '/orgs/*/meliome/welcome/business/contact-name': {
    'page.view': [pageEvent, 'contactName'],
  },
  '/orgs/*/meliome/welcome/business/logo': {
    'page.view': [pageEvent, 'logo'],
  },
};

export default MAPPING;
