import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';

export const InitiatedByCell: React.FC<CellProps<any>> = ({ cell: { value } }) =>
  useMemo(
    () => (
      <Box>
        <MIFormattedText label={`getPro.table.cells.initiatedBy.${value}`} />
      </Box>
    ),
    [value]
  );
