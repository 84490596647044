export enum ApiSliceActionName {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  VALIDATION_FAILURE = 'VALIDATION_FAILURE',
}

export const generateSliceName = (storeName: string, actionName: string) =>
  `[${storeName}] ${actionName}`.toUpperCase();

export const generateSliceActionName = (sliceName: string, sliceActionName: ApiSliceActionName) =>
  `${sliceName}_${sliceActionName}`;

export const generateActionName = (storeName: string, actionName: string, sliceActionName: ApiSliceActionName) =>
  generateSliceActionName(generateSliceName(storeName, actionName), sliceActionName);
