import Box from 'src/core/ds/box';

type Props = {
  isFirstRow?: boolean;
  testId?: string;
  children: React.ReactNode;
};

export const ApprovalWorkflowFormRow = ({ testId, isFirstRow, children }: Props) => (
  <Box data-testid={testId} mt={isFirstRow ? 0 : 10} mb={0}>
    {children}
  </Box>
);
