import * as React from 'react';
import { InvoiceType } from 'src/modules/invoices/types';
import InvoiceDetails from 'src/pages/get-paid/list/components/InvoiceDetails';
import RequestDetails from 'src/pages/get-paid/list/components/RequestDetails';
import { InvoiceModelDataType } from 'src/pages/get-pro/types';
import { ModelView } from 'src/ui/form';
import { InvoiceStatus } from 'src/utils/consts';
import { ContactType, FileUrlsPayloadType } from 'src/utils/types';
import CardBody from '../CardBody';

type Props = {
  invoice?: InvoiceType;
  vendorHandle: string;
  invoiceMV: ModelView<InvoiceModelDataType>;
  inEditMode: boolean;
  uploading: boolean;
  loading: boolean;
  loadingFileUrls: boolean;
  fileUrls?: FileUrlsPayloadType | null;
  customers: ContactType[];
  remove: (removeInvoice?: boolean) => Promise<void>;
  onEditToggle: () => void;
  onMarkRequestAsPaid: () => void;
  deleteFile: () => void;
  uploadFile: (file: File) => Promise<void>;
  openPdfPreview?: () => void;
};

const RequestCard: React.FC<Props> = ({
  invoice,
  vendorHandle,
  invoiceMV,
  inEditMode,
  uploading,
  loading,
  loadingFileUrls,
  fileUrls,
  customers,
  remove,
  onEditToggle,
  onMarkRequestAsPaid,
  deleteFile,
  uploadFile,
  openPdfPreview,
}) => (
  <CardBody withoutBorder>
    <RequestDetails
      showGetPaidTabs
      invoice={invoice as InvoiceType}
      vendorHandle={vendorHandle}
      email={invoiceMV.customerEmail}
      note={invoiceMV.customerNote}
      viewOnly={!inEditMode || uploading}
      onEdit={onEditToggle}
      remove={remove}
      showMenu={!inEditMode}
      inEditMode={inEditMode}
      onMarkInvoiceAsPaid={onMarkRequestAsPaid}
    />
    <InvoiceDetails
      showGetPaidTabs
      invoice={invoice as InvoiceType}
      totalAmount={invoiceMV.totalAmount}
      customerName={invoiceMV.customerName}
      invoiceNumber={invoiceMV.invoiceNumber}
      dueDate={invoiceMV.dueDate}
      viewOnly={!inEditMode || uploading || loading}
      showMenu={invoice?.status === InvoiceStatus.CREATED && !inEditMode}
      onEdit={onEditToggle}
      remove={remove}
      fileUrls={fileUrls || null}
      loadingAttachment={loadingFileUrls}
      deleteFile={deleteFile}
      uploadFile={uploadFile as (file: any) => Promise<void>}
      customers={customers}
      openPdfPreview={openPdfPreview}
    />
  </CardBody>
);

export default RequestCard;
