import { Switch } from 'react-router-dom';
import config from 'src/config';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { fundingSourcesLocations } from 'src/pages/funding-sources/locations';
import { MicroDepositsBlockedPage } from 'src/pages/funding-sources/MicroDepositsBlcokedPage';
import { MicroDepositsSuccessPage } from 'src/pages/funding-sources/MicroDepositsSuccessPage';
import { MicroDepositsVerifyPage } from 'src/pages/funding-sources/MicroDepositsVerifyPage';
import { melioMeLocations } from 'src/pages/meliome/locations';

export const FundingSourcesRouter = () => {
  const [historyPush] = useHistoryWithOrgId();

  return (
    <Switch>
      <SmartRoute path={fundingSourcesLocations.microDepositsVerify} exact>
        <MicroDepositsVerifyPage />
      </SmartRoute>
      <SmartRoute path={fundingSourcesLocations.microDepositsSuccess} exact>
        <MicroDepositsSuccessPage
          signUp={() => historyPush({ path: melioMeLocations.wizard.successSignUp, params: { link: 'micro-deposit' } })}
          learnMore={() => {
            window.open(config.support.site);
          }}
        />
      </SmartRoute>
      <SmartRoute path={fundingSourcesLocations.microDepositsBlocked} exact>
        <MicroDepositsBlockedPage />
      </SmartRoute>
    </Switch>
  );
};
