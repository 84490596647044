export enum BrandName {
  intuit = 'Intuit',
  masterCard = 'Master',
  diners = 'Diners',
  visa = 'Visa',
  discover = 'Discover',
  freshbooks = 'Freshbooks',
  amex = 'Amex',
  evolve = 'Evolve',
  pciDss = 'PciDSS',
  quickbooks = 'Quickbooks',
  quickbooksGray = 'QuickbooksGray',
  masterCardGray = 'MasterGray',
  dinersGray = 'DinersGray',
  visaGray = 'VisaGray',
  discoverGray = 'DiscoverGray',
  amexGray = 'AmexGray',
  evolveGray = 'EvolveGray',
  pciGray = 'PciDSSGray',
  creditKey = 'CreditKey',
  creditKeyGrey = 'CreditKeyGrey',
  default = 'Default',
}
