import { Fragment } from 'react';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import config from 'src/config';
import coreTheme from 'src/core/theme/coreTheme';
import enTranslations from 'src/locale/en-US';
import { billLocations } from 'src/pages/bill/locations';
import GuestCardLayout from 'src/pages/meliome/guest/components/GuestCardLayout';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { vendorLocations } from 'src/pages/vendor/locations';
import { theme } from 'src/theme/defaultTheme';
import { PaymentCreateFlowOrigin, PaymentRequestCreateOrigin, RegistrationFlow } from 'src/utils/consts';
import locations from 'src/utils/locations';

const defaultSite = {
  name: 'melio',
  theme,
  chakraTheme: coreTheme,
  embeddedMode: false,
  preloadImages: [
    '/dashboard/',
    '/accounting-software/',
    '/funding-sources/',
    '/general/',
    '/icons/',
    '/onboarding/',
    '/vendors/',
    '/melio-logo-loader.gif',
  ],
  config,
  routes: {},
  components: {
    GuestCardLayout,
    EmbeddedCloseButton: Fragment,
    EmbeddedHeader: Fragment,
    StepLayout: StepLayoutPage,
    WizardElements,
  },
  messages: {
    'en-US': enTranslations['en-US'],
  },
  authenticatedEntryRedirect: billLocations.index,
  redirectAuthUserOnNonAuthRoutes: locations.MainApp.dashboard.url(),
  onboardingEntryUrl: onboardingLocations.companyInfo.index,
  viewVendorUrl: vendorLocations.view,
  allowSignups: true,
  disableRecurring: false,
  allow3rdPartyAuth: true,
  hasDebitInBankAccounts: true,
  hasFastAch: true,
  hasFastCheck: false,
  hasExpressCheck: true,
  allowedEmbed: [] as string[],
  useEmbeddedAppcenter: false,
  goUnilateralInvalidAddress: true,
  showPayBillEducationView: true,
  scheduleDirectPayment: true,
  authOnEntry: true,
  createOrigin: {
    pay: {
      payor: RegistrationFlow.REGULAR,
      payee: RegistrationFlow.VENDOR,
      payment: PaymentCreateFlowOrigin.PAY,
    },
    meliome: {
      payor: RegistrationFlow.GUEST,
      paymentRequest: PaymentRequestCreateOrigin.MELIOME,
    },
  },
  intercomSettings: {
    custom_launcher_selector: '#MelioIntercomLauncher',
    alignment: 'right',
    horizontal_padding: 20,
    vertical_padding: 20,
  },
  title: 'Melio',
  icon: 'logo64x64.ico',
};

export default defaultSite;
