import { EventMappingsTreeChild } from 'src/services/analytics/types';

const PAGE_EVENT = 'failed-payment';

const properties = {
  vendorId: 'vendorId',
  partialBillId: 'partialBillId',
  fundingSourceId: 'fundingSourceId',
  fundingSourceType: 'fundingSourceType',
  originDeliveryId: 'originDeliveryId',
  originDeliveryType: 'originDeliveryType',
  billId: 'billId',
  paymentId: 'paymentId',
};

export const MAPPING: EventMappingsTreeChild = {
  'selected-ach': [
    PAGE_EVENT,
    'selected-ach',
    {
      ...properties,
    },
  ],
  'selected-overnight-check': [
    PAGE_EVENT,
    'selected-overnight-check',
    {
      ...properties,
    },
  ],
  'selected-expedited-ach': [
    PAGE_EVENT,
    'selected-expedited-ach',
    {
      ...properties,
    },
  ],
  'selected-check': [
    PAGE_EVENT,
    'selected-check',
    {
      ...properties,
    },
  ],
  'selected-express-check': [
    PAGE_EVENT,
    'selected-express-check',
    {
      ...properties,
    },
  ],
  'set-check-type-continue': [
    PAGE_EVENT,
    'set-check-type-continue',
    {
      ...properties,
    },
  ],
};
