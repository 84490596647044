import { useMemo } from 'react';
import * as React from 'react';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';

type StatusValueType = {
  isBillReady?: boolean;
};

type StatusCellProps = CellProps<StatusValueType> | { cell: { value: StatusValueType } };

export const StatusCell: React.FC<StatusCellProps> = ({ cell: { value } }) =>
  useMemo(
    () => (
      <Box role="cell" pl="1.4rem">
        <Box w={2} h={2} m="auto" backgroundColor={value ? 'green.500' : 'grey.600'} borderRadius={4} />
      </Box>
    ),
    [value]
  );
