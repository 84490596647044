import { Location } from 'history';
import { ProgressBar } from 'src/components/common/ProgressBar';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { analytics } from 'src/services/analytics';
import { ProgressBarFlavor, WizardProgressBar } from 'src/utils/consts';
import { StepProgressBar } from '../StepProgressBar';
import { CloseButton } from './components/CloseButton';
import { NavIconContainer } from './components/NavIconContainer';
import { SupportContainer } from './components/SupportContainer';
import { NavIconContainerStyle } from './styles';

type StepHeaderProps = {
  relativeStep?: number | null;
  isPrevDisabled?: boolean;
  onPrev?: { (): void } | null;
  isPrev?: boolean;
  progressBarSteps?: string[];
  minorSteps?: number[];
  progressBarType?: WizardProgressBar;
  exitLink?: string | Location;
  goExit?: { (): void } | null;
  displaySupportLink?: boolean;
  onSupportTrigger?: () => void;
  testId?: string | null;
};

export const StepHeader = ({
  exitLink = '',
  goExit = null,
  relativeStep = null,
  isPrevDisabled = false,
  onPrev = null,
  isPrev = true,
  progressBarSteps = [],
  minorSteps = [],
  progressBarType,
  displaySupportLink,
  onSupportTrigger,
  testId,
}: StepHeaderProps) => {
  const spaced = relativeStep || isPrev;
  const renderProgressBar = () => {
    if (progressBarType === WizardProgressBar.WITH_SUBSTEPS && progressBarSteps && minorSteps) {
      return (
        <Flex flexGrow={3} w="full" justify="center">
          <StepProgressBar progressBarSteps={progressBarSteps} minorSteps={minorSteps} />
        </Flex>
      );
    }

    if (relativeStep) {
      return <ProgressBar relativeStep={relativeStep} flavor={ProgressBarFlavor.WIZARD} />;
    }

    return null;
  };
  const handlePrevClick = () => {
    if (onPrev) {
      analytics.trackAction('go.back');
      onPrev();
    }
  };

  const handleExitClick = goExit
    ? () => {
        analytics.trackAction('go.exit');
        goExit();
      }
    : undefined;

  const backButtonTestId = testId ? `${testId}-back-button` : 'step-back-button';

  return (
    <Flex
      flexDir="row"
      w="full"
      justify={spaced ? 'space-between' : 'flex-end'}
      align={{ base: 'center', sm: 'unset' }}
    >
      <Flex flex={1} justify="start">
        {!isPrevDisabled && isPrev && (
          <NavIconContainer progressBarType={progressBarType} onClick={handlePrevClick} data-testid="previous-icon">
            <Icon data-testid={backButtonTestId} name={IconNames.shevronLeft} size={IconSize.lg} />
          </NavIconContainer>
        )}
        {isPrevDisabled && <NavIconContainer progressBarType={progressBarType} />}
        {displaySupportLink && <div />}
      </Flex>
      {renderProgressBar()}
      <Flex flex={1} justify="flex-end">
        {displaySupportLink && <SupportContainer onSupportTrigger={onSupportTrigger} />}
        <NavIconContainer progressBarType={progressBarType} __css={NavIconContainerStyle}>
          <CloseButton exitLink={exitLink} goExit={handleExitClick} />
        </NavIconContainer>
      </Flex>
    </Flex>
  );
};
