import { useHistory } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import textStyles from 'src/core/theme/foundations/textStyles';

export const UserErrorModal: React.FC<{ dismiss: () => void }> = ({ dismiss }) => {
  const history = useHistory();
  const onConfirm = () => history.go(0);

  return (
    <ModalMessage
      id="user-error-modal"
      titleComponent={
        <>
          <Icon name={IconNames.cancelFill} color="red.500" size={IconSize['2xl']} mb={8} />
          <Box textAlign="left">
            <MIFormattedText label="userErrorModal.title" />
          </Box>
        </>
      }
      contentComponent={
        <Box textAlign="left" __css={textStyles.body2}>
          <MIFormattedText label="userErrorModal.description" />
        </Box>
      }
      buttonComponent={
        <Flex justify="end" mt="3">
          <Button onClick={dismiss} label="userErrorModal.cancelLabel" variant={ButtonVariants.tertiaryNaked} />
          <Button onClick={onConfirm} label="userErrorModal.buttonLabel" variant={ButtonVariants.error} />
        </Flex>
      }
    />
  );
};
