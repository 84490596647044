import Box from 'src/core/ds/box';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { ModelView } from 'src/ui/form';
import { FORM_AUTOCOMPLETE, ScreenMode } from 'src/utils/consts';
import { ContactType } from 'src/utils/types';

type Props = {
  customer: ModelView<ContactType>;
} & Partial<DefaultProps>;

const defaultProps = {
  mode: ScreenMode.EDIT as ScreenMode,
};
type DefaultProps = Readonly<typeof defaultProps>;

export const CustomerForm = ({ customer, mode }: Props) => {
  const isViewOnly = mode === ScreenMode.VIEW;

  return (
    <Box mt={5}>
      <Box mb={6}>
        <TextField
          id="companyName"
          label="vendors.create.name.customerInputTitle"
          model={customer?.companyName}
          type="text"
          isViewOnly={isViewOnly}
          autoComplete={FORM_AUTOCOMPLETE.OFF}
          isRequired
          autoFocus={mode !== ScreenMode.VIEW}
          size="sm"
        />
      </Box>
      <Box mb={6}>
        <TextField
          id="contactName"
          label="vendors.create.contact.inputTitle"
          model={customer?.contactName}
          placeholder="vendors.form.contactNameEmpty"
          type="text"
          isViewOnly={isViewOnly}
          size="sm"
        />
      </Box>
      <Box mb={6}>
        <PrivateDataContainer>
          <TextField
            id="contactEmail"
            label="vendors.create.email.inputTitle"
            model={customer?.contactEmail}
            placeholder="vendors.form.contactEmailEmpty"
            type="email"
            isViewOnly={isViewOnly}
            size="sm"
          />
        </PrivateDataContainer>
      </Box>
      <Box>
        <PrivateDataContainer>
          <MaskField
            id="contactPhone"
            label="vendors.create.phone.inputTitle"
            model={customer?.contactPhone}
            placeholder="vendors.form.contactPhoneEmpty"
            type="tel"
            format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
            isViewOnly={isViewOnly}
            size="sm"
          />
        </PrivateDataContainer>
      </Box>
    </Box>
  );
};
