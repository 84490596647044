import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { BusinessTypeSelector } from 'src/components/common/BusinessTypeSelector';
import { ContactSupportLink } from 'src/components/common/ContactSupportLink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { NotificationVariant } from 'src/core/ds/toast';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getOrgId, getOwnedVendorId, getUserPreferences } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { DeliveryMethodOrigin, OrgPreferencesTypeKeys } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { MsnBusinessType } from 'src/utils/types';

export const SelectBusinessTypePage = () => {
  const [selectedCard, setSelectedCard] = useState<MsnBusinessType>();
  const [isLoading, seIsLoading] = useState(false);
  const { navigate } = useNavigator();
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);
  const user = useSelector(profileStore.selectors.profile);
  const { msnEmptyOrganizationCreated } = useSelector(getUserPreferences);
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();
  const isMelioUserChooseExistingOrganization = !user.isGuest && orgId.toString() !== msnEmptyOrganizationCreated;
  const analyticsData = { msnOptinFlow: true, isGuest: user.isGuest, flows: 'public' };

  const errorToastRef = useRef(null);

  const onPrev = () => {
    navigate(generatePath(onboardingLocations.msnRegistration.companyDetails, { orgId }));
  };

  const navigateToSelectDeliveryMethod = () => {
    if (vendorId) {
      navigate(generatePath(globalLocations.receivingMethod.ach.select, { orgId, vendorId }), false, {
        preservedState: {
          origin: DeliveryMethodOrigin.MSN_PORTAL_ONBOARDING,
        },
        redirectUrl: globalLocations.onboarding.msnRegistration.confirmDetails,
        exitUrl: globalLocations.onboarding.msnRegistration.selectBusinessType,
        progressBarProps: {
          relativeStep: 4 / 6,
        },
      });
    }
  };

  const onSubmit = async () => {
    if (!selectedCard) return;

    seIsLoading(true);
    try {
      await updateOrganizationPreference({
        key: OrgPreferencesTypeKeys.msnBusinessType,
        value: selectedCard,
        orgId,
      });

      if (isMelioUserChooseExistingOrganization) {
        navigate(globalLocations.onboarding.msnRegistration.deliveryMethodStatusCheck, true, {});
      } else {
        navigateToSelectDeliveryMethod();
      }
    } catch (err) {
      if (!checkIfToastIsDisplayedById(errorToastRef.current)) {
        errorToastRef.current = pushNotification({
          type: NotificationVariant.error,
          msg: 'onboarding.msnCompanyInfo.generalError.text',
          textValues: {
            contactSupport: <ContactSupportLink />,
          },
          autoClose: 10000,
        });
      }

      capture(err as Error);
      analytics.trackAction('msnPortalSelectBusinessType.error', analyticsData);
    } finally {
      seIsLoading(false);
    }
  };

  return (
    <StepLayoutPage
      title="onboarding.msnCompanyInfo.selectBusinessType.title"
      subtitle="onboarding.msnCompanyInfo.selectBusinessType.subtitle"
      isNextDisabled={!selectedCard}
      onPrev={onPrev}
      onNext={onSubmit}
      relativeStep={3 / 6}
      isLoading={isLoading}
      nextButtonAnalyticsProperties={analyticsData}
    >
      <BusinessTypeSelector selectedType={selectedCard} onTypeClick={setSelectedCard} />
    </StepLayoutPage>
  );
};
