export default {
  server: {
    baseUrl: 'https://app.meliopayments.com',
  },
  web: {
    baseUrl: 'https://app.meliopayments.com',
  },
  platformApp: {
    baseUrl: 'https://app.melio.com',
  },
  sentry: {
    enable: true,
  },
  logger: {
    shouldLog: true,
  },
  analytics: {
    shouldTrackEvents: true,
    shouldIdentify: true,
  },
  services: {
    braze: {
      enable: true,
      apiKey: '4f799017-8f6c-4e07-bd44-f5ce08c1dfed',
      options: {
        baseUrl: 'sdk.iad-05.braze.com',
        enableLogging: false,
        localization: 'en',
      },
    },
    intuit: {
      clientId: 'Q0IBv5YxrnDsWBvrWm03xWzYPLKxI4khYSIV84XPp4oRRoTkeL',
    },
    plaid: {
      env: 'production',
    },
    segment: {
      key: 'szPSLPsn0eLI4th6z5pTk91AbgVHmxg4',
      shouldLoad: true,
    },
    tabapay: {
      url_legacy: 'https://sso.tabapay.com/SSOMelioISOPayments.html?1542807031653',
      url: 'https://sso.tabapay.com/SSOMelioPayments1.html',
    },
    basisTheory: {
      key: 'key_VygUcaXQL4NtSUMxpKxjS',
    },
    intercom: {
      app_id: 'bc1zfb6n',
      isShow: true,
    },
    featureFlagProvider: {
      clientId: '6062c9171a6a080f1a6b1529',
    },
    zendesk: {
      webWidgetKey: '51fdfad6-7341-4cb2-8eb8-88ccece82bbc',
      messagingKey: '51fdfad6-7341-4cb2-8eb8-88ccece82bbc',
    },
  },
  meliome: {
    baseUrl: 'https://melio.me/',
  },
  qbo: {
    web: {
      baseUrl: 'https://intuit-app.meliopayments.com',
    },
    services: {
      tabapay: {
        url: 'https://sso.tabapay.com/SSOMelioISOIntuit.html?1571897387',
      },
    },
  },
  featureFlags: {
    partialPayments: false,
    qboPartialPayments: false,
  },
  spendManagement: {
    amexExtensionId: 'hchldcgjdbbedfiemllaijbcacglnped',
    amexPartnerId: 'Arcade-Melio-ce49e412-e3',
    amexUrls: {
      login: 'https://www.americanexpress.com/en-us/account/light/login/',
      enrollment: 'https://account-enrollment.americanexpress.com/card-selector',
    },
  },
};
