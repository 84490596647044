import * as React from 'react';
import { ViewDetailsLink } from 'src/components/common/ViewDetailsLink';
import { Avatar, Consts } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

type Props = {
  companyName: string;
  avatarName?: string;
  description?: React.ReactNode;
  showViewDetailsLink?: boolean;
  showDirectoryVendorLogo?: boolean;
  goViewDetails?: () => void;
};

export const CompanyContact = ({
  companyName,
  avatarName,
  description,
  showViewDetailsLink,
  showDirectoryVendorLogo,
  goViewDetails,
}: Props) => (
  <Flex flex="1" minW={0}>
    <Avatar defaultIconSize={IconSize.lg} name={avatarName} textColor="white" size={Consts.Size.xl} />
    {showDirectoryVendorLogo && (
      <Icon
        sx={DirectoryVendorLogoStyle}
        size={IconSize.xs}
        name={IconNames.verifiedSolid}
        color="primary.500"
        data-testid="directory-vendor-sign"
        background="white"
      />
    )}
    <Flex sx={ViewBillContactContainerTextStyle} minW={0}>
      <Box textStyle="body2Semi" letterSpacing="0.01rem">
        {companyName}
      </Box>
      <Flex alignItems="center" textStyle="body3">
        {description && (
          <Box mr={1} color="grey.600" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
            {description}
          </Box>
        )}
        {showViewDetailsLink && <ViewDetailsLink goViewDetails={goViewDetails} />}
      </Flex>
    </Flex>
  </Flex>
);

const ViewBillContactContainerTextStyle = {
  flexDirection: 'column',
  justifyContent: 'center',
  mx: 4,
};

const DirectoryVendorLogoStyle = {
  w: 4,
  h: 4,
  border: '2px',
  borderColor: 'white',
  borderRadius: 'full',
  top: 8,
  left: 8,
  pos: 'absolute',
};
