import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { msnPortalLocations } from 'src/pages/msn-portal/locations';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { VendorCompanyAddedPage } from './components/VendorCompanyAddedPage';

const eventPage = 'onboarding-vendor-company-info-added';

export function VendorCompanyAddedPageContainer() {
  const [historyPush] = useHistoryWithOrgId();
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');

  const handleGoToDashboard = () => {
    if (origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION) {
      historyPush({ path: msnPortalLocations.base });
    } else {
      historyPush({ path: getPaidLocations.dashboard });
    }
  };

  const handleExit = () => {
    analytics.track(eventPage, 'success-go-exit');
    handleGoToDashboard();
  };

  const handleNext = () => {
    if (origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION) {
      historyPush({ path: msnPortalLocations.base });
    } else {
      historyPush({ path: getPaidLocations.create.options });
    }
  };

  return <VendorCompanyAddedPage goToDashboard={handleGoToDashboard} onNext={handleNext} onExit={handleExit} />;
}
