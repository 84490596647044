import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { invitationsApi } from 'src/modules/invitations/api';
import { UserOrganizationType, UserOrgType } from 'src/modules/invitations/invitations-store';

type ValidateInvitationStateType = {
  validation: {
    loading?: boolean;
    error?: any | null;
    userOrganization?: UserOrganizationType;
    invitingUser?: UserOrgType;
    userExists?: boolean;
  };
};

type ValidateInvitationPayloadType = {
  userOrganization?: UserOrganizationType;
  userExists?: boolean;
};

export const validateInvitationSlice = createApiCallSlice<ValidateInvitationPayloadType, ValidateInvitationStateType>({
  name: 'VALIDATE INVITATION',
  api: invitationsApi.validateInvitation,
  initialState: {
    validation: {
      userExists: false,
      loading: false,
      error: null,
      invitingUser: {
        id: null,
        firstName: '',
        lastName: '',
      },
      userOrganization: {},
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.error = null;
    },
    [ON_SUCCESS]: (state, action) => {
      state.validation.loading = false;
      state.validation.userOrganization = action.payload.userOrganization;
      state.validation.invitingUser = action.payload.userOrganization?.user;
      state.validation.userExists = action.payload.userExists;
      state.validation.error = null;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.error = action.error;
    },
  },
});
