import { useFeature } from '@melio/shared-web/dist/feature-flags';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MICompanyInitialsCircle } from 'src/components/common/MICompanyInitialsCircle/MICompanyInitialsCircle';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge, BadgeColors, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { useModal } from 'src/helpers/react/useModal';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import joinOrgRequestsStore from 'src/modules/join-organization-requests/join-organization-requests-store';
import { getProfileActions } from 'src/modules/profile/profile-store';
import { useChangeAccountingFirmModal } from 'src/pages/companies/hooks/useChangeAccountingFirmModal';
import { devices } from 'src/theme/appDevices';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { CompanyType, DialogVariants, FeatureFlags, Role } from 'src/utils/consts';
import { PayorActivityType } from 'src/utils/types';
import { HideClientModalMessage } from './HideClientModalMessage';
import { UnhideClientModalMessage } from './UnhideClientModalMessage';

type MICompanyCardProps = {
  organization: any;
  loggedInOrg?: any;
  isSelected?: boolean;
  invitationStatus: 'accepted' | 'pending';
  color?: string;
  renderBottom?: React.ReactNode;
  onBeforeSwitch?: (toOrgId: number, isHidden: boolean) => void;
  activitySummary?: PayorActivityType;
  onHideClick?: (orgId: number) => void;
  onUnhideClick?: (orgId: number) => void;
};

export const MICompanyCard = ({
  organization,
  loggedInOrg,
  isSelected,
  invitationStatus,
  color,
  renderBottom,
  onBeforeSwitch,
  activitySummary,
  onHideClick,
  onUnhideClick,
}: MICompanyCardProps) => {
  const dispatch = useDispatch();
  const { switchOrganization } = getProfileActions(dispatch);
  const joinOrgRequestsActions = getStoreActions(joinOrgRequestsStore)(dispatch);
  const { isMobile } = useBreak();
  const { id, companyName, companyType } = organization;
  const isAccountingFirm = companyType === CompanyType.ACCOUNTING_FIRM;
  const isAccountingFirmOwner = isAccountingFirm && organization?.userOrganization?.role === Role.OWNER;
  const shouldShowHideClientActions =
    !isSelected &&
    !isAccountingFirm &&
    !isMobile &&
    invitationStatus !== 'pending' &&
    loggedInOrg.companyType === CompanyType.ACCOUNTING_FIRM;

  const [isHideClientsEnabled] = useFeature(FeatureFlags.HideClients, false);
  const [ResendJoinRequest, showResendJoinRequest] = useModal(CommonDialog, {
    confirm: () => joinOrgRequestsActions.resendJoinRequestSlice({ id }),
    title: 'bookkeeper.company.resendDialog.title',
    description: 'bookkeeper.company.resendDialog.description',
    confirmText: 'bookkeeper.company.resendDialog.confirm',
    variant: DialogVariants.SUCCESS,
    hideIcon: true,
    textValues: {
      companyName,
    },
    modalName: 'resendDialog',
  });
  const [DeleteCompany, showDeleteCompany] = useModal(CommonDialog, {
    confirm: () => joinOrgRequestsActions.delete({ id }),
    title: 'bookkeeper.company.deleteDialog.title',
    description: 'bookkeeper.company.deleteDialog.description',
    confirmText: 'bookkeeper.company.deleteDialog.confirm',
    textValues: {
      companyName,
    },
    modalName: 'deleteDialog',
  });

  const [HideClient, showHideClient] = useModal(HideClientModalMessage, {
    onConfirm: () => handleHideClientConfirm(),
    orgId: organization.id,
    orgName: organization.name,
    companyName,
  });
  const [UnhideClient, showUnhideClient] = useModal(UnhideClientModalMessage, {
    onConfirm: () => handleUnhideClientConfirm(),
    orgId: organization.id,
    orgName: organization.name,
    companyName,
  });
  const [ChangeAccountingFirm, showChangeAccountingFirm] = useChangeAccountingFirmModal(id);
  const invitationOptions: ActionOption[] =
    invitationStatus === 'pending'
      ? [
          {
            label: 'bookkeeper.request.actions.resend',
            action: showResendJoinRequest,
          },
          {
            label: 'bookkeeper.request.actions.delete',
            action: showDeleteCompany,
            negative: true,
          },
        ]
      : [];
  const organizationOptions: ActionOption[] = isAccountingFirmOwner
    ? [
        {
          label: 'bookkeeper.company.card.actions.changeFirm',
          action: showChangeAccountingFirm,
        },
      ]
    : [];

  const handleHideClientConfirm = () => {
    onHideClick && onHideClick(organization.id);
  };

  const handleUnhideClientConfirm = () => {
    onUnhideClick && onUnhideClick(organization.id);
  };

  const hiddenStateActions: ActionOption[] = organization.userOrganization?.isHidden
    ? [
        {
          label: 'bookkeeper.company.card.actions.unhideClient',
          action: showUnhideClient,
        },
      ]
    : [
        {
          label: 'bookkeeper.company.card.actions.hideClient',
          action: showHideClient,
        },
      ];

  const actionOptions: ActionOption[] = [
    ...invitationOptions,
    ...organizationOptions,
    ...(isHideClientsEnabled && shouldShowHideClientActions ? hiddenStateActions : []),
  ];
  const showActionOptions = !isEmpty(actionOptions);
  const isExpired = organization.expired <= new Date().toISOString();
  const summaryItems = [
    {
      key: 'inbox',
      title: 'bookkeeper.company.bills.statuses.inbox',
      value: (activitySummary?.payorActivity.unpaid || 0) + (activitySummary?.payorActivity.declined || 0),
      failed: false,
    },
    {
      key: 'pending',
      title: 'bookkeeper.company.bills.statuses.pending',
      value: activitySummary?.payorActivity.pendingApproval || 0,
      failed: false,
    },
    {
      key: 'scheduled',
      title: 'bookkeeper.company.bills.statuses.scheduled',
      value: activitySummary?.payorActivity.scheduled || 0,
      failed: false,
    },
    {
      key: 'failed',
      title: 'bookkeeper.company.bills.statuses.failed',
      value: activitySummary?.payorActivity.failed || 0,
      failed: true,
    },
  ];

  const onCompanyClick = () => {
    if (organization.userOrganization?.isHidden) {
      showUnhideClient();
    } else {
      goToClientOrganization(id);
    }
  };

  const goToClientOrganization = (id: number) => {
    onBeforeSwitch && onBeforeSwitch(id, organization.userOrganization?.isHidden);
    switchOrganization(id);
  };

  const testIdBase = invitationStatus === 'pending' ? 'company-card-request' : 'company-card-organization';
  const testIdWithOrgId = `${testIdBase}-${id}`;

  return (
    <>
      {ChangeAccountingFirm}
      {ResendJoinRequest}
      {DeleteCompany}
      {HideClient}
      {UnhideClient}
      <CardWrapper
        onClick={invitationStatus === 'accepted' ? onCompanyClick : undefined}
        invitationStatus={invitationStatus}
        data-testid={`${testIdWithOrgId}-wrapper`}
        $hasBottomSection={!!renderBottom}
      >
        <CompanyInitialsWrapper>
          <CompanyInitialsContainer>
            <MICompanyInitialsCircle
              organization={organization}
              isSelected={isSelected}
              color={color}
              disabled={invitationStatus === 'pending'}
            />
            <CompanyInitials>
              <CompanyName>{companyName}</CompanyName>
              <TagContainer>
                {isAccountingFirm && (
                  <Badge
                    testId={`${testIdBase}-accountant-tag`}
                    label="bookkeeper.company.card.tags.accountingFirm"
                    variant={BadgeVariants.FILLED}
                    color={BadgeColors.PRIMARY_LIGHT}
                  />
                )}
                {invitationStatus === 'pending' &&
                  (isExpired ? (
                    <Badge
                      label="bookkeeper.company.card.statuses.expired"
                      variant={BadgeVariants.OUTLINED}
                      color={BadgeColors.DANGER}
                    />
                  ) : (
                    <Badge
                      label="bookkeeper.company.card.statuses.pending"
                      variant={BadgeVariants.FILLED}
                      color={BadgeColors.NEUTRAL}
                    />
                  ))}
              </TagContainer>
            </CompanyInitials>
          </CompanyInitialsContainer>
          {showActionOptions && (
            <Box onClick={(e) => e.stopPropagation()}>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<Icon name={IconNames.more} />}
                  variant="action"
                  placement="top"
                  data-testid="company-card-actions"
                />
                <MenuList data-testid={`${testIdWithOrgId}-dots-menu`}>
                  {actionOptions.map(({ label, action, negative }) => (
                    <MenuItem label={label} onClick={action} color={negative ? 'red.500' : 'black'} />
                  ))}
                </MenuList>
              </Menu>
            </Box>
          )}
        </CompanyInitialsWrapper>
        <CompanySummaryWrapper>
          {summaryItems.map((item) => (
            <SummaryItem key={item.key} data-testid={`company-activity-status-${item.key}`}>
              <ItemTitle>
                <MIFormattedText label={item.title} />
              </ItemTitle>
              <ItemValue empty={item.value === 0} invitationStatus={invitationStatus} failed={item.failed}>
                {item.value}
              </ItemValue>
            </SummaryItem>
          ))}
        </CompanySummaryWrapper>
        {renderBottom}
      </CardWrapper>
    </>
  );
};

const CardWrapper = styled.div<{
  invitationStatus: 'accepted' | 'pending';
  $hasBottomSection?: boolean;
}>`
  width: 39.2rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  background-color: ${(props) => props.theme.colors.white.opaque};
  box-shadow: ${(props) => `0 0.5rem 1.5rem 0 ${props.theme.colors.dark.translucent1}`};
  cursor: ${(props) => (props.invitationStatus === 'accepted' ? 'pointer' : 'auto')};
  color: ${(props) =>
    props.invitationStatus === 'pending' ? props.theme.text.color.label : props.theme.text.color.main};
  transition-property: box-shadow;
  transition-duration: ${(props) => props.theme.animation.transition.default};

  &:hover {
    box-shadow: ${(props) => `0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2}`};
  }

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    margin-bottom: 1.5rem;
  }
`;

const CompanyInitialsWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.regular};
`;

const CompanyInitialsContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-right: 1rem;
  flex: 1;
  min-width: 0;
`;

const CompanyInitials = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const CompanyName = styled.div`
  margin: 0 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media ${devices.mobile} {
    width: -webkit-fill-available;
  }
`;

const CompanySummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 1.6rem;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.refular};

  > div:nth-child(n + 2) {
    padding-left: 4rem;
  }
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
  white-space: nowrap;
`;

const ItemTitle = styled.div`
  color: ${(props) => props.theme.text.color.label};
  ${(props) => props.theme.text.fontType.small};
`;

const getSummaryItemColor = ({ empty, invitationStatus, failed, colors }) => {
  if (empty || invitationStatus === 'pending') {
    return colors.label;
  } else if (failed) {
    return colors.error;
  }

  return colors.main;
};

const ItemValue = styled.div<{
  empty: boolean;
  invitationStatus: 'accepted' | 'pending';
  failed?: boolean;
}>`
  color: ${(props) =>
    getSummaryItemColor({
      empty: props.empty,
      invitationStatus: props.invitationStatus,
      failed: props.failed,
      colors: props.theme.text.color,
    })};
  font-size: 1.4rem;
  line-height: 2.1rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TagContainer = styled.div`
  > span {
    display: block;
  }
  @media ${devices.nonMobile} {
    white-space: nowrap;
  }
`;
