import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { billLocations } from 'src/pages/bill/locations';
import { payBillEventPage } from 'src/pages/bill/pay/hooks/usePayBillDataContext';
import { getBill, getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { PayEditLocationName } from 'src/utils/consts';
import { isFastDeliveryType } from 'src/utils/delivery-methods';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { NavigateType } from 'src/utils/types';

type Params = {
  navigate: NavigateType;
  nextStepURL: string;
  prevStepURL?: string;
  nextStepState?: Record<string, string>;
  payBillWizardDates?: Record<string, string | Date>;
};

export const usePayBillNavigateCommon = ({ navigate, nextStepURL, prevStepURL, nextStepState }: Params) => {
  const bill = useSelector(getBill);
  const payment = useSelector(getPayment);
  const location = useLocation();
  const orgId = useSelector(getOrgId);

  const onNext = () => {
    const eventName = `set-${getEventNameFromLocation(location)}`;
    analytics.track(payBillEventPage, `${eventName}-continue`, {
      billId: bill.id,
      billPaymentIndex: getBillPaymentIndex(bill),
      scheduledDate: payment.scheduledDate,
      dueDate: bill.dueDate,
      paymentId: payment.id,
      fundingSourceId: payment.fundingSourceId,
      isFast: isFastDeliveryType(payment?.deliveryPreference),
      deliveryEta: payment.deliveryEta,
    });

    if (nextStepURL) {
      navigate(nextStepURL, false, { ...nextStepState });
    }
  };

  const onPrev = () => {
    const eventName = `set-${getEventNameFromLocation(location)}`;
    analytics.track(payBillEventPage, `${eventName}-back`, {
      billPaymentIndex: getBillPaymentIndex(bill),
    });

    if (prevStepURL) {
      navigate(prevStepURL);
    }
  };

  const getUrl = (type: PayEditLocationName) =>
    payment.id
      ? generatePath(billLocations.pay.edit[type], {
          orgId,
          billId: bill.id,
          paymentId: payment.id,
        })
      : generatePath(billLocations.pay[type], {
          orgId,
          billId: bill.id,
        });

  return {
    onNext,
    onPrev,
    getUrl,
  };
};
