import * as React from 'react';
import { Button } from 'src/core/ds/button';
import { Icon, IconNames } from 'src/core/ds/icon';
import { expandedButtonStyle } from './styles';

type Props = {
  label: string;
  values?: Record<string, any>;
  onClick: () => void;
  testId?: string;
};

export const ExpandedButton: React.FC<Props> = ({ label, values, onClick, testId }) => (
  <Button
    iconSpacing={2}
    sx={expandedButtonStyle}
    leftIcon={<Icon name={IconNames.plus} data-testid="plus-icon" />}
    label={label}
    values={values}
    onClick={onClick}
    testId={`fab-expanded-${testId}`}
  />
);
