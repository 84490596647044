export const shiftVendorToAchLocations = {
  base: [
    '/vendors/:id/ach-details/:token',
    '/vendors/:id/add-delivery-method/:token',
    '/vendors/:id/link-delivery-method/:token',
    '/vendors/:id/add-delivery-method/:token/switch-company',
    '/vendors/:id/add-delivery-method/:token/invalid-link',
    '/vendors/:id/add-delivery-method/:token/success',
  ],
  index: '/vendors/:id/ach-details/:token',
  addMethod: '/vendors/:id/add-delivery-method/:token',
  linkMethod: '/vendors/:id/link-delivery-method/:token',
  switchCompany: '/vendors/:id/add-delivery-method/:token/switch-company',
  invalidToken: '/vendors/:id/add-delivery-method/:token/invalid-link',
  shiftedCurrentPayment: '/vendors/:id/add-delivery-method/:token/shifted-current-payment',
  success: '/vendors/:id/add-delivery-method/:token/success',
  deliveryMethodChangedToVirtualCard: '/vendors/:id/add-delivery-method/:token/new-delivery-method-virtual-card',
};
