import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { BusinessType } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';

const businessTypeOptions = [
  {
    label: 'financing.businessDetails.fields.businessType.values.soleProprietorship',
    value: BusinessType.SoleProprietorship,
  },
  {
    label: 'financing.businessDetails.fields.businessType.values.partnership',
    value: BusinessType.Partnership,
  },
  {
    label: 'financing.businessDetails.fields.businessType.values.LLC',
    value: BusinessType.LLC,
  },
  {
    label: 'financing.businessDetails.fields.businessType.values.corporation',
    value: BusinessType.Corporation,
  },
  {
    label: 'financing.businessDetails.fields.businessType.values.nonProfitCorporation',
    value: BusinessType.NonProfitCorporation,
  },
  {
    label: 'financing.businessDetails.fields.businessType.values.ngo',
    value: BusinessType.NGO,
  },
  {
    label: 'financing.businessDetails.fields.businessType.values.municipal',
    value: BusinessType.Municipal,
  },
  {
    label: 'financing.businessDetails.fields.businessType.values.trust',
    value: BusinessType.Trust,
  },
];

export const useBusinessTaxRelatedOptions = () => {
  const intl = useIntl();

  const businessTypes = useMemo(
    () =>
      businessTypeOptions.map(({ label, ...rest }) => ({
        ...rest,
        label: intl.formatMessage({ id: label }),
      })),
    []
  );

  return {
    businessTypeOptions: businessTypes,
  };
};
