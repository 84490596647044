import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Menu, MenuButton, MenuItem } from 'src/core/ds/menu';
import { useBreak } from 'src/hoc';
import { ActiveIndicator } from 'src/pages/pay/components/filters/components/ActiveIndicator';

interface Props extends React.ComponentProps<typeof Menu> {
  isActive: boolean;
  label: string;
}

export const FilterSubmenu: React.FC<Props> = ({ children, isActive, label, ...props }) => {
  const { isMobile } = useBreak();

  return (
    <Menu
      {...props}
      placement="right-start"
      offset={[-16, 0]}
      closeOnSelect={false}
      flip={isMobile}
      isLazy
      lazyBehavior="unmount"
    >
      <MenuItem as={MenuButton}>
        <Flex justifyContent="space-between" alignItems="center">
          <MIFormattedText label={label} />
          <Flex alignItems="center">
            {isActive && (
              <Box mr={4}>
                <ActiveIndicator />
              </Box>
            )}
            <Icon name={IconNames.shevronRight} size={IconSize.m} />
          </Flex>
        </Flex>
      </MenuItem>
      {children}
    </Menu>
  );
};
