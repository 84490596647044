import { featureFlags } from '@melio/shared-web';
import { addDays, isBefore } from 'date-fns';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Badge, BadgeVariants } from 'src/core/ds/badge';
import Box from 'src/core/ds/box/Box';
import Tooltip from 'src/core/ds/tooltip';
import { useBreak } from 'src/hoc';
import useUpdateUserPreferences from 'src/modules/users/hooks/useUpdateUserPreferences';
import { getUserPreferences } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { FeatureFlags, UserPreferencesKey } from 'src/utils/consts';
import { UserPreferencesType } from 'src/utils/types';
import { TooltipContent } from './BillersVisibilityTooltipContent';

export type BillersVisibilityTooltipProps = PropsWithChildren<{
  location: 'bill' | 'vendor';
}>;

function shouldShowBasedOnPreference(preference?: string | null) {
  if (!preference || preference === 'false' || isBefore(new Date(), new Date(preference))) {
    return true;
  }

  return false;
}

function isPreferenceNotSet(preference?: string | null) {
  return !preference || preference === 'false';
}

function getPreferenceValue(flag: string) {
  return addDays(new Date(), parseInt(flag, 10) || 1).toISOString();
}
export function BillersVisibilityTooltip({ location }: BillersVisibilityTooltipProps) {
  const { updateUserPreference } = useUpdateUserPreferences();
  const userPreferences: UserPreferencesType = useSelector(getUserPreferences);
  const { search } = useLocation();
  const [billersVisibiltyFlagValue] = featureFlags.useFeature(FeatureFlags.BillersVisibilityExisting, 'OFF');
  const billersVisibiltyEnabled = billersVisibiltyFlagValue && billersVisibiltyFlagValue !== 'OFF';
  const { isMobile } = useBreak();
  const prefKey =
    location === 'bill'
      ? UserPreferencesKey.ShownBillerVisibilityTooltipBill
      : UserPreferencesKey.ShownBillerVisibilityTooltipVendor;
  const preference = userPreferences[prefKey];
  const [isEnabled] = useState(
    billersVisibiltyEnabled && shouldShowBasedOnPreference(preference) && !isMobile && !search.includes('vendorId=')
  );
  const preferenceNotSet = isPreferenceNotSet(preference);
  const [isOpen, setIsOpen] = useState<boolean | 'firstTime'>(preferenceNotSet ? 'firstTime' : false);

  useEffect(() => {
    if (isEnabled) {
      analytics.track(`${location}`, 'billers-tooltip-shown');
    }
  }, []);
  function onClose() {
    if (preferenceNotSet) {
      analytics.track(`${location}`, 'billers-tooltip-closed');

      updateUserPreference({ key: prefKey, value: getPreferenceValue(billersVisibiltyFlagValue) });
    }

    setIsOpen(false);
  }
  function onMouseOut() {
    if (isOpen !== 'firstTime') {
      setIsOpen(false);
    }
  }
  function onMouseOver() {
    if (isOpen !== 'firstTime') {
      setIsOpen(true);
    }
  }

  return isEnabled ? (
    <Tooltip
      maxW="256px"
      pointerEvents="auto"
      p={2}
      label={<TooltipContent closeTooltip={onClose} showClose={isOpen === 'firstTime'} location={location} />}
      placement="bottom-start"
      closeOnClick
      shouldWrapChildren
      isOpen={!!isOpen}
    >
      <Box
        ml={2}
        display="inline-block"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onFocus={onMouseOver}
        onBlur={onMouseOut}
      >
        <Badge label="vendorDirectory.education.tooltipNew" variant={BadgeVariants.FILLED} />
      </Box>
    </Tooltip>
  ) : null;
}
