import styled from 'styled-components';
import { baseShadowContainerStyles } from './ShadowContainerStyles';

export const ShadowContainer = styled.div`
  ${baseShadowContainerStyles}
`;

export const ShadowContainerWithHover = styled.div`
  ${baseShadowContainerStyles}

  cursor: pointer;
  transition-property: box-shadow;
  transition-duration: ${(props) => props.theme.animation.transition.default};

  &:hover {
    box-shadow: ${(props) => `0 0.8rem 1.5rem 0 ${props.theme.colors.dark.translucent2}`};
  }
`;
