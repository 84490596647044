import Box from 'src/core/ds/box';
import { ReactComponent as MelioFullLogo } from 'src/icons/melioLogos/melio.svg';
import { ReactComponent as MelioPoweredBy } from 'src/icons/melioLogos/melioPoweredBy.svg';
import { ReactComponent as MelioShortLogo } from 'src/icons/melioLogos/melioShort.svg';
import { Colors, Size, STYLE, Type } from './consts';

type LogoProps = {
  size?: Size;
  color: Colors;
  type?: Type;
};

const MelioLogo = ({ size = Size.regular, color, type = Type.full }: LogoProps) => {
  const logoStyle = { ...STYLE[type][size], fill: color || '' };
  const LOGO_MAP = {
    [Type.full]: <MelioFullLogo />,
    [Type.short]: <MelioShortLogo />,
    [Type.poweredBy]: <MelioPoweredBy />,
  };

  return (
    <Box as="svg" {...logoStyle}>
      {LOGO_MAP[type]}
    </Box>
  );
};
export { MelioLogo };
