import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { checkAndInitUserAction } from 'src/redux/user/actions';

export const useInitOrganization = () => {
  const dispatch = useDispatch();
  const [isOrganizationInitializing, setIsOrganizationInitializing] = useState(false);
  const initOrganization = useCallback(
    async (orgId: number | number) => {
      setIsOrganizationInitializing(true);

      return new Promise((resolve, reject) => {
        dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
      }).finally(() => {
        setIsOrganizationInitializing(false);
      });
    },
    [dispatch]
  );

  return { initOrganization, isOrganizationInitializing };
};
