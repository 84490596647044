import { OperatingSystem } from 'src/pages/onboarding/company-info/consts';

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return OperatingSystem.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return OperatingSystem.IOS;
  }

  return 'other';
};
