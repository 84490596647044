import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { financialAccountsApi } from 'src/modules/funding-sources/api';
import { useRefreshFundingSources } from 'src/modules/funding-sources/hooks/useRefreshFundingSources';
import { getOrgId } from 'src/redux/user/selectors';
import { AccountingPlatformAccountId } from '../types';

type LinkAccountingPlatformAccountParams = {
  fundingSourceId: number;
  accountingPlatformAccountId: AccountingPlatformAccountId;
  orgId?: number;
};

export const useLinkAccountingPlatformAccount = () => {
  const currentOrgId = useSelector(getOrgId);
  const { onApiCall: setAccountingPlatformAccount, loading: isSettingAccount } = useApi({
    api: financialAccountsApi.setAccountingPlatformAccountId,
  });
  const { refreshFundingSources, isFundingSourcesRefreshing } = useRefreshFundingSources();

  const linkAccountingPlatformAccount = useCallback(
    async ({ fundingSourceId, accountingPlatformAccountId, orgId }: LinkAccountingPlatformAccountParams) => {
      await setAccountingPlatformAccount(currentOrgId ?? orgId, fundingSourceId, {
        intuitAccountId: accountingPlatformAccountId,
      });
      await refreshFundingSources();
    },
    [setAccountingPlatformAccount, refreshFundingSources, currentOrgId]
  );

  const isAccountingPlatformAccountLinking = isSettingAccount || isFundingSourcesRefreshing;

  return {
    linkAccountingPlatformAccount,
    isAccountingPlatformAccountLinking,
  };
};
