import isEmpty from 'lodash/isEmpty';
import { RightPanelPlaceholder } from 'src/components/onboarding/RightPanelPlaceholder';
import { PageContainerProps } from 'src/hoc/withListContainer';
import { PayListItemType } from 'src/modules/bills/types';
import { ViewBill } from 'src/pages/bill/components/ViewBill/ViewBill';
import { deserializePaymentId } from 'src/utils/bills';
import { SelectedItemsGallery } from '../bill/components/BillsGallery/SelectedItemsGallery';
import { ViewScannedInvoice } from '../bill/components/ViewScannedInvoice';
import { ViewPayment } from '../payment/components/ViewPaymentWrapper';

type Props = {
  enableBatchSelection: boolean;
  enabledSelectionItems: PayListItemType[];
  selectedIds: string[];
  setPaymentRequestApprovalStatus: (paymentRequestId: number, isApproved: boolean, isTrusted: boolean) => Promise<void>;
  isLoading: boolean;
  itemId: string;
  backPath: string;
  reloadBills: () => void;
  singleViewRef: any;
  filters: PageContainerProps['filters'];
  isScannedInvoice?: boolean;
  eventPage: string;
};

export const ViewBillPage = ({
  enableBatchSelection,
  selectedIds,
  setPaymentRequestApprovalStatus,
  isLoading,
  itemId,
  backPath,
  reloadBills,
  singleViewRef,
  filters,
  isScannedInvoice,
  eventPage,
}: Props) => {
  if (enableBatchSelection && !isEmpty(selectedIds)) {
    return <SelectedItemsGallery selectedIds={selectedIds} />;
  }

  if (itemId) {
    if (isScannedInvoice) {
      return <ViewScannedInvoice filters={filters} eventPage={eventPage} backPath={backPath} />;
    }

    const { paymentId } = deserializePaymentId(itemId);

    if (paymentId) {
      return <ViewPayment paymentId={paymentId} reloadBills={reloadBills} filters={filters} backPath={backPath} />;
    }

    return (
      <ViewBill
        reloadBills={reloadBills}
        backPath={backPath}
        setPaymentRequestApprovalStatus={setPaymentRequestApprovalStatus}
        singleViewRef={singleViewRef}
        filters={filters}
      />
    );
  }

  return <RightPanelPlaceholder isLoading={isLoading} />;
};
