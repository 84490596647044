import partition from 'lodash/partition';
import { useCallback, useMemo } from 'react';
import Flex from 'src/core/ds/flex';
import { Consts, SearchInput } from 'src/core/ds/input';
import { MenuDivider, MenuList } from 'src/core/ds/menu';
import { ClearAll } from 'src/pages/pay/components/filters/components/ClearAll';
import { useVendorsMenu } from 'src/pages/pay/components/filters/components/VendorsMenu/hooks/useVendorsMenu';
import { VendorsList } from 'src/pages/pay/components/filters/components/VendorsMenu/VendorsList';
import { SubmenuProps } from 'src/pages/pay/components/filters/types';

export const VendorsMenu = ({ selected, onChange: parentOnChange }: SubmenuProps) => {
  const { options, setSearch, search, isLoading } = useVendorsMenu(selected);
  const onSearchChange = useCallback(({ target: { value } }) => setSearch(value), [setSearch]);
  const isChecked = (id: string) => selected.includes(id);
  const checkboxList: (typeof options[0] & { isChecked?: boolean })[] = useMemo(() => {
    const mappedOptions = options.map((option) => ({ ...option, isChecked: isChecked(option.id) }));
    const displayedOptions = search
      ? mappedOptions.filter((item) => item.vendorName.toLowerCase().includes(search.trim().toLowerCase()))
      : mappedOptions;
    const [selectedOptions, nonSelectedOptions] = partition(displayedOptions, (option) => option.isChecked);

    return [...selectedOptions, ...nonSelectedOptions];
  }, [search, options, selected.toString()]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value: id, checked: isChecked } = event.currentTarget;
      const newSelected = isChecked ? [...selected, id] : selected.filter((value) => value !== id);
      parentOnChange(newSelected);
    },
    [selected, parentOnChange]
  );
  const onClearAll = () => {
    setSearch('');

    parentOnChange([]);
  };
  const canClearAll = selected.length > 0;

  return (
    <MenuList maxHeight="34.6rem" width="24rem" data-testid="vendors-menu">
      <SearchInput
        placeholder="pay.filter.vendorsMenu.input.placeholder"
        value={search}
        onChange={onSearchChange}
        borderBottom="0"
        variant={Consts.Variant.flushed}
        onClear={() => setSearch('')}
        size={Consts.Size.sm}
        sx={{
          apply: 'textStyles.body4',
          ps: 10,
        }}
      />
      <MenuDivider />
      <Flex h="auto" maxH="21.6rem" overflowY="scroll" grow={1} direction="column">
        <VendorsList checkboxList={checkboxList} isLoading={isLoading} onChange={onChange} />
      </Flex>
      <MenuDivider />
      <ClearAll isDisabled={!canClearAll} onClick={onClearAll} />
    </MenuList>
  );
};
