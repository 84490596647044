import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { Footer } from 'src/pages/vendor/international-delivery-method/components/Footer';
import { useUBOForm } from 'src/pages/vendor/international-delivery-method/ultimate-beneficial-owners/hooks/useUBOForm';
import { getProfile } from 'src/redux/user/selectors';

type Props = {
  onExit: () => void;
  vendorId: number;
};

export const UltimateBeneficialOwnersPage = ({ onExit, vendorId }: Props) => {
  const profile = useSelector(getProfile);
  const { renderUBOForms, submit, isLoading, showFooterProps } = useUBOForm(vendorId);

  return (
    <StepLayoutPage
      title="vendors.deliveryMethods.international.ubo.title"
      titleValues={{ companyName: profile.orgName }}
      subtitle="vendors.deliveryMethods.international.ubo.subtitle"
      goExit={onExit}
      isLoading={isLoading}
      testId="ubo-page"
      footer={<Footer showFooterProps={showFooterProps} submit={submit} isLoading={isLoading} />}
    >
      {renderUBOForms()}
    </StepLayoutPage>
  );
};
