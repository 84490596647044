import { LOCATION_CHANGE } from 'connected-react-router';
import { all, takeEvery } from 'redux-saga/effects';
import { analytics } from 'src/services/analytics';

function* onLocationChange() {
  analytics.trackRoute();
  yield;
}
function* navigationSagas() {
  yield all([takeEvery(LOCATION_CHANGE, onLocationChange)]);
}
const NavigationStore = {
  saga: navigationSagas,
};

export default NavigationStore;
