import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getOrgId } from 'src/redux/user/selectors';

export const VendorCompanyInfoIndex = () => {
  const orgId = useSelector(getOrgId);

  return <Redirect to={generatePath(onboardingLocations.vendorCompanyInfo.name, { orgId })} />;
};
