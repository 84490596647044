import { useEffect, useState } from 'react';
import { FullPageLayout } from 'src/components/layout/FullPageLayout';
import { useBreak } from 'src/hoc';
import { analytics } from 'src/services/analytics';
import { MSNDashboard } from './components/MSNDashboard';
import { MSNDrawer } from './components/MSNDrawer';
import { MSNMobileDashboard } from './components/MSNMobileDashboard';
import { EVENT_PAGE } from './consts';

export const MSNPortalPage = () => {
  const { isMobile } = useBreak();
  const [drawerState, setDrawerState] = useState();

  useEffect(() => {
    analytics.page(EVENT_PAGE, 'incoming-payments');
  }, []);

  const onCloseDrawer = () => setDrawerState(undefined);

  const onOpenDrawer = (invoice) => {
    setDrawerState(invoice);
    analytics.track(EVENT_PAGE, 'payment-click', { paymentId: invoice.id });
  };

  const DashboardComponent = isMobile ? MSNMobileDashboard : MSNDashboard;

  return (
    <FullPageLayout noPadding>
      <DashboardComponent onOpenDrawer={onOpenDrawer} />
      <MSNDrawer drawerState={drawerState} onCloseDrawer={onCloseDrawer} />
    </FullPageLayout>
  );
};
