import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { DropDownOption, FirstCustomOptionProps } from 'src/components/common/DropDown/MIDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';

type AddOptionComponentType = {
  label: string;
} & FirstCustomOptionProps;

export const AddOptionComponent = ({ label, createOption, text, options }: AddOptionComponentType) => {
  const trimmedText = trim(text);

  if (isEmpty(trimmedText)) {
    return null;
  }

  if (find(options, (option) => option.label === trimmedText)) {
    return null;
  }

  const onClick = () => {
    createOption(trimmedText);
  };

  return (
    <DropDownOption onClick={onClick} data-testid="add-option-comp">
      <Flex>
        <Box as="span" mr={3}>
          <Icon name={IconNames.plus} />
        </Box>
        <Box as="span">
          {label ? <MIFormattedText values={{ value: trimmedText }} label={label} /> : { trimmed: trimmedText }}
        </Box>
      </Flex>
    </DropDownOption>
  );
};
