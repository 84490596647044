import { ChangeEvent } from 'react';
import MISingleSelect from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { SingleSelectFlavor } from 'src/utils/consts';
import { FieldType } from 'src/utils/types';
import { US_STATES } from 'src/utils/us-states';

type Props = {
  onChange: (arg: FieldType) => void;
  manualAddress: Record<string, any>;
  validationErrors: Record<string, string>;
};

export const ManualAddressForm = ({ manualAddress, onChange, validationErrors }: Props) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    onChange({ id, value });
  };

  return (
    <Box>
      <Box mb={6}>
        <PrivateDataContainer>
          <TextField
            id="addressLine1"
            label="manualAddress.line1Label"
            placeholder="manualAddress.line1Placeholder"
            value={manualAddress.addressLine1}
            onChange={handleInputChange}
            errorMessage={validationErrors.addressLine1}
            type="text"
            isRequired
            autoFocus
          />
        </PrivateDataContainer>
      </Box>
      <Box mb={6}>
        <PrivateDataContainer>
          <TextField
            id="addressLine2"
            label="manualAddress.line2Label"
            placeholder="manualAddress.line2Placeholder"
            value={manualAddress.addressLine2}
            onChange={handleInputChange}
            errorMessage={validationErrors.addressLine2}
            type="text"
          />
        </PrivateDataContainer>
      </Box>
      <Flex w="full">
        <Box mr={4} flex={1}>
          <PrivateDataContainer>
            <TextField
              id="city"
              label="manualAddress.cityLabel"
              value={manualAddress.city}
              type="text"
              onChange={handleInputChange}
              isRequired
              errorMessage={validationErrors.city}
            />
          </PrivateDataContainer>
        </Box>
        <Box ml={4} flex={1}>
          <MISingleSelect
            id="state"
            label="manualAddress.stateLabel"
            value={manualAddress.state}
            onChange={onChange}
            errorMessage={validationErrors.state}
            isRequired
            options={US_STATES}
            flavor={SingleSelectFlavor.DS}
            privateData
          />
        </Box>
      </Flex>
      <Box>
        <PrivateDataContainer>
          <TextField
            id="zipCode"
            label="manualAddress.zipCodeLabel"
            value={manualAddress.zipCode}
            onChange={handleInputChange}
            type="text"
            isRequired
            errorMessage={validationErrors.zipCode}
          />
        </PrivateDataContainer>
      </Box>
    </Box>
  );
};
