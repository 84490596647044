import Flag from 'react-flagpack';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import {
  getInternationalCountryCode,
  getPayeeBankCountryLabel,
} from 'src/pages/vendor/international-delivery-method/utils';

type Props = {
  payeeBankCountryCode: string;
};

export const InternationalPayeeCountryHint = ({ payeeBankCountryCode }: Props) => {
  const countryLabel = getPayeeBankCountryLabel(payeeBankCountryCode);
  const countryCode = getInternationalCountryCode(payeeBankCountryCode);

  if (!countryLabel) {
    return null;
  }

  return (
    <Box textStyle="body4" color="grey.600">
      <MIFormattedText
        label="viewBillPaymentActivity.deliveryMethod.internationalDirection"
        values={{
          payeeBankCountry: countryLabel,
          flag: <Flag code={countryCode} size="s" />,
        }}
      />
    </Box>
  );
};
