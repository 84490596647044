import Box from 'src/core/ds/box';
import { CandidatesDetails } from './components/card/CandidatesDetails';
import { Header } from './components/header/Header';
import { coverContainerStyle } from './styles';

const VirtualCardCandidatesDetailsPage = ({ capLimitAmount }) => (
  <Box h="full">
    <Box __css={coverContainerStyle} />
    <Box position="absolute" w="full" top={0}>
      <Header />
      <CandidatesDetails capLimitAmount={capLimitAmount} />
    </Box>
  </Box>
);
export { VirtualCardCandidatesDetailsPage };
