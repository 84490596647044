import { isAfter, isBefore, isSameDay } from 'date-fns';
import sortBy from 'lodash/sortBy';
import { DeliveryType, FAST_DELIVERY_TYPES } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';

export const removeRegularCheckFee = (deliveryOptions?: DeliveryOptionType[]) => {
  if (!deliveryOptions) return;

  const regularCheckIndex = deliveryOptions.findIndex((option) => option.type === 'check');

  if (regularCheckIndex >= 0) {
    deliveryOptions[regularCheckIndex].fee = [];
  }
};

type TitleAndSubtitleProps = {
  dueDate: Date | string;
  deliveryType: DeliveryType;
  deliveryDate?: Date;
  maxRegularDeliveryDate?: Date;
};

export const getTitleAndSubtitle = ({
  dueDate,
  deliveryType,
  deliveryDate,
  maxRegularDeliveryDate,
}: TitleAndSubtitleProps) => {
  const isPastDueDate = deliveryDate && isAfter(new Date(deliveryDate), new Date(dueDate));
  const isDueDateSameAsDeliveryDate = isSameDay(new Date(deliveryDate as Date), new Date(dueDate));
  const isMaxDeliveryDateIsBeforeDueDate =
    maxRegularDeliveryDate && isBefore(new Date(maxRegularDeliveryDate as Date), new Date(dueDate));
  const isLateAchDelivery = isPastDueDate && !isDueDateSameAsDeliveryDate;
  const isLateCheckDelivery = !isMaxDeliveryDateIsBeforeDueDate;

  if (deliveryType === DeliveryType.CHECK) {
    return isLateCheckDelivery
      ? {
          subtitle: 'bills.pay.fastPayment.subtitle.fastCheckPastDueDate',
          title: 'bills.pay.fastPayment.title.fastCheckPastDueDate',
        }
      : { subtitle: 'bills.pay.fastPayment.subtitle.fastCheck', title: 'bills.pay.fastPayment.title.fastCheck' };
  }

  return isLateAchDelivery
    ? {
        subtitle: 'bills.pay.fastPayment.subtitle.fastAchPastDueDate',
        title: 'bills.pay.fastPayment.title.fastAchPastDueDate',
      }
    : { subtitle: 'bills.pay.fastPayment.subtitle.fastAch', title: 'bills.pay.fastPayment.title.fastAch' };
};

type SortedDeliveryOptionsProps = {
  deliveryOptions?: DeliveryOptionType[];
  freeDeliveryOptionIndex: number;
  isEligibleForFastAchAdoption: boolean | undefined;
};

export const getSortedDeliveryOptions = ({
  deliveryOptions,
  freeDeliveryOptionIndex,
  isEligibleForFastAchAdoption,
}: SortedDeliveryOptionsProps) => {
  if (isEligibleForFastAchAdoption && freeDeliveryOptionIndex === 0) {
    return sortBy(deliveryOptions, (deliveryOption) => !FAST_DELIVERY_TYPES.includes(deliveryOption.type));
  }

  return deliveryOptions;
};
