import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { RadioSelectOptionContainer } from 'src/components/common/SelectMethods/components/RadioSelectMethodOption';
import Box from 'src/core/ds/box/Box';
import { BrandFullLogo, BrandName } from 'src/core/ds/brandFullLogo';
import Flex from 'src/core/ds/flex/Flex';
import { Link } from 'src/core/ds/link';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger } from 'src/core/ds/popover';
import {
  financingAnalyticsEvents,
  FinancingExperience,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { FinancingEligibilityStatus } from 'src/pages/bill/pay/installments/types';
import { analytics } from 'src/services/analytics';

type Props = {
  tooltipLabel: string;
  paymentAmount: number;
  financingExperience: FinancingExperience;
  installmentsStatus?: FinancingEligibilityStatus;
  learnMoreLink?: string | null;
  learnMoreText?: string | null;
  learnMoreColor?: string;
  payBillFlowUUID: string | undefined;
  testId?: string;
};

export const FinancingFundingSourcePaymentIneligible = ({
  tooltipLabel,
  paymentAmount,
  financingExperience,
  installmentsStatus,
  learnMoreLink,
  learnMoreText,
  learnMoreColor,
  payBillFlowUUID,
  testId,
}: Props) => {
  useEffect(() => {
    analytics.track(financingPage.addFunding, financingAnalyticsEvents.financingExposed, {
      financingExperience,
      paymentAmount,
      installmentsEnabled: false,
      installmentsStatus,
      ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
    });
  }, [paymentAmount, installmentsStatus, financingExperience, payBillFlowUUID]);

  return (
    <Popover trigger="hover" placement="top">
      <PopoverTrigger>
        <RadioSelectOptionContainer data-testid={testId || 'radio-select-method-installments-disabled'} disabled>
          <Flex mr={4} w={6} h={6}>
            <BrandFullLogo name={BrandName.creditKeyGrey} />
          </Flex>
          <Flex alignItems="flex-start" color="grey.400" direction="column">
            <Flex textStyle="body2Semi" alignItems="flex-start">
              <MIFormattedText label="financing.fundingSource.installmentsDisabledTooltip.installmentsUnavailable" />
            </Flex>
            <Flex textStyle="body4">
              <MIFormattedText label="financing.fundingSource.poweredBy" />
            </Flex>
          </Flex>
        </RadioSelectOptionContainer>
      </PopoverTrigger>
      <PopoverContent bg="black" color="white">
        <PopoverBody backgroundColor="black" color="white" px={4} pt={4} pb={2} borderRadius="lg" cursor="default">
          <Box
            mb={2}
            maxW="24rem"
            textStyle="body4"
            data-testid="financing-funding-source-installments-disabled-tooltip"
          >
            <MIFormattedText label={tooltipLabel} />
          </Box>
          {learnMoreLink && (
            <Flex justify="end">
              <Box
                py={2}
                pr={0}
                display="inline-block"
                textStyle="body4Semi"
                _hover={{ color: 'primary.500' }}
                cursor="pointer"
                data-testid="financing-funding-source-ineligible-link"
              >
                <MIFormattedText
                  label={learnMoreText || 'financing.fundingSource.installmentsDisabledTooltip.learnMore'}
                  values={{
                    link: (link) => (
                      <Link href={learnMoreLink} target="_blank" color={learnMoreColor}>
                        {link}
                      </Link>
                    ),
                  }}
                />
              </Box>
            </Flex>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
