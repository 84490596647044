export const registrationLocations = {
  base: [
    '/register',
    '/register/code-verification',
    '/meliome/register',
    '/register/from-landing',
    '/register/from-landing-no-verification',
    '/spend-management/register',
  ],
  index: '/register',
  authorizeGoogleSuccess: '/register/authorize-google-success',
  authorizeIntuitSuccess: '/register/authorize-intuit-success',
  authorizeQuickbooksSuccess: '/register/authorize-quickbooks-success',
  fromInvitation: '/register/from-invitation/:token',
  codeVerification: '/register/code-verification',
  authCodeVerification: '/register/auth-code-verification',
  changeEmail: '/register/change-email',
  fromInvitationDob: '/register/from-invitation-dob',
  fromInvitationSuccess: '/register/from-invitation-success',
  acceptInvitationSuccess: '/register/accept-invitation-success',
  chooseAccountantType: '/register/accountant-type',
  setAccountantAmount: '/register/approve-amount',
  fundingSource: '/register/funding-source',
  invalidInvitation: '/register/invalid-invitation',
  addProfilePicture: '/register/profile-picture',
  vendorRegister: '/meliome/register',
  spendRegister: '/spend-management/register',
  registerFromLanding: '/register/from-landing',
  registerFromLandingNoVerification: '/register/from-landing-no-verification',
};
