import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type Props = {
  helpTextLabel: string;
  children: React.ReactNode;
};

export function UploadPictureLayout({ helpTextLabel, children, ...rest }: Props) {
  return (
    <Flex {...rest} flexDir={{ base: 'column', sm: 'row' }}>
      {children}
      <Box my={{ base: 3, sm: 0 }} mx={{ base: 0 }} ml={{ base: 0, sm: 8 }}>
        <Box as="p" my={0} color="black" textStyle="body4">
          <MIFormattedText label={helpTextLabel} />
        </Box>
      </Box>
    </Flex>
  );
}
