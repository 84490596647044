import { PinInput as ChakraPinInput, PinInputProps } from '@chakra-ui/react';
import Flex from 'src/core/ds/flex';
import { FormControl } from 'src/core/ds/form';
import { useBreak } from 'src/hoc';
import { ErrorMessage } from '../../form/fields/components';

type Props = PinInputProps & {
  testId?: string;
  errorMessage?: string | null;
  errorMessageValues?: Record<string, unknown>;
};

export const PinInput = ({ testId, errorMessage, errorMessageValues, children, ...rest }: Props) => {
  const { isMobile } = useBreak();
  const size = isMobile ? 'sm' : 'md';
  const isError = Boolean(errorMessage);

  return (
    <Flex textAlign="center" data-testid={testId}>
      <FormControl m={0} isInvalid={isError}>
        <ChakraPinInput isInvalid={isError} size={size} placeholder="" {...rest}>
          {children}
        </ChakraPinInput>
        {errorMessage && (
          <Flex px={4} justify={{ base: 'center', md: 'flex-start' }}>
            <ErrorMessage
              size={size}
              testId={`${testId}-error`}
              label={errorMessage}
              labelValues={errorMessageValues}
            />
          </Flex>
        )}
      </FormControl>
    </Flex>
  );
};
