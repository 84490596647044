import Box from 'src/core/ds/box';
import { Image } from 'src/core/ds/image';
import invoicePlaceholder from 'src/images/bills/invoice.jpg';
import { analytics } from 'src/services/analytics';
import { invoicePreview } from './styles';

type Props = {
  invoiceName: string;
  invoiceUrl?: string;
  invoicePreviews?: string[];
};

export function InvoicePreview({ invoiceName, invoiceUrl, invoicePreviews }: Props) {
  const handlePreviewClick = () => {
    analytics.track('guest', 'view-attachment');

    if (window.self === window.top && invoiceUrl) {
      // we are not in an iframe, open the file normally.
      window.open(invoiceUrl);
    } else {
      // we are in an iframe, tell the parent to open the file.
      window.parent.postMessage({ action: 'open', url: invoiceUrl }, '*');
    }
  };

  return (
    <Box __css={invoicePreview(!!invoiceUrl)} onClick={handlePreviewClick} data-testid="invoice-preview">
      <Image
        src={invoicePreviews?.length ? invoicePreviews[0] : invoicePlaceholder}
        data-testid="invoice-preview-image"
        alt={invoiceName}
      />
    </Box>
  );
}
