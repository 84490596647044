import isBoolean from 'lodash/isBoolean';
import { useCallback, useState } from 'react';

export function useToggle(initial = false, notifyChange?: (newValue: boolean) => any) {
  const [state, setState] = useState(initial);

  const toggle = useCallback(
    (value?: boolean) => {
      const newValue = isBoolean(value) ? value : !state;
      setState(newValue);

      if (notifyChange) {
        notifyChange(newValue);
      }
    },
    [notifyChange, state]
  );

  return [state, toggle] as [boolean, typeof toggle];
}
