import { createSlice } from '@reduxjs/toolkit';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { name } from 'src/modules/invoices/constants';
import { invoicesApi } from './api';

export const shareSlice = createUpdateSlice({
  storeName: name,
  api: invoicesApi.share,
  actionName: 'SHARE',
});

export const cancelSlice = createUpdateSlice({
  storeName: name,
  api: invoicesApi.cancel,
  actionName: 'CANCEL',
});

export const markAsSendSlice = createUpdateSlice({
  storeName: name,
  api: invoicesApi.markAsSend,
  actionName: 'MARK_AS_SEND',
});

export const resetCreateInvoiceSlice = createSlice({
  name: generateSliceName(name, 'reset_create_state'),
  initialState: {
    create: {},
  },
  reducers: {
    reset(state) {
      state.create = {};
    },
  },
});
