import { PureComponent, useContext } from 'react';
import { SiteContext } from '../context/SiteContext';

export function withSiteContext() {
  return function (Component: any) {
    return class ComponentWithSiteContext extends PureComponent<any> {
      static defaultProps: any;
      render() {
        const { innerRef, ...props } = this.props;

        return (
          <SiteContext.Consumer>
            {(site) => (
              <Component {...props} site={site} ref={innerRef}>
                {this.props.children}
              </Component>
            )}
          </SiteContext.Consumer>
        );
      }
    };
  };
}

export function useSiteContext() {
  return useContext(SiteContext);
}
