import noop from 'lodash/noop';
import { createContext } from 'react';
import { ModalShowCallback } from 'src/helpers/react/useModal';

export type GetProModalContextType = {
  showModal: ModalShowCallback;
  setClear: () => void;
};

const GetProModalContext = createContext<GetProModalContextType>({
  showModal: () => noop(),
  setClear: () => noop(),
});
export default GetProModalContext;
