import { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  EditElement,
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useMicroDepositOptimizationsEnabled } from 'src/hooks/useMicroDepositOptimizationsEnabled';
import { useOrgMicroDepositEligible } from 'src/hooks/useOrgMicroDepositEligible';
import { PayFundingSourceDescriptionWarningIcon } from 'src/pages/bill/pay/components/PayFundingSourceDescriptionWarningIcon';
import { CardTypes, FailedPaymentType, FundingType } from 'src/utils/consts';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { getLatestPayment, isUndepositedCheck } from 'src/utils/payments';
import { AccountType, BillType, PaymentType } from 'src/utils/types';
import { FundingSourceDescription } from './FundingSourceDescription';
import { FundingSourceIcon } from './FundingSourceIcon';
import { PayBillPaymentMicroDepositDialog } from './PayBillPaymentMicroDepositDialog';
import Tag from './Tag';

type Props = {
  fundingSource: AccountType;
  bill: BillType;
  payment: PaymentType;
  onEdit?: () => void;
  isEditDisabled: boolean;
};

export const PayBillPaymentActivityFundingSourceInfo = ({
  fundingSource,
  bill,
  onEdit,
  payment,
  isEditDisabled,
}: Props) => {
  const { isOrgMicroDepositEligible } = useOrgMicroDepositEligible();
  const { isMicroDepositOptimizationsEnabled } = useMicroDepositOptimizationsEnabled();
  let label = 'payBillPaymentActivity.fundingSource.achLabel';

  if (fundingSource.fundingType === FundingType.CARD) {
    label =
      fundingSource.cardAccount?.cardType === CardTypes.CREDIT
        ? 'payBillPaymentActivity.fundingSource.creditCardLabel'
        : 'payBillPaymentActivity.fundingSource.debitCardLabel';
  }

  const hint = isManualBankAccountNotVerified(fundingSource)
    ? 'payBillPaymentActivity.fundingSource.unverifiedBankAccountHint'
    : '';

  const showFundingSourceDescription =
    isMicroDepositOptimizationsEnabled && isOrgMicroDepositEligible && isManualBankAccountNotVerified(fundingSource);
  const originPayment = getLatestPayment(bill?.payments);
  const isUndepositedCheckPayment = originPayment ? isUndepositedCheck(originPayment) : false;
  const canEdit = payment?.metadata?.failedType !== FailedPaymentType.FAILED_TO_DELIVER && !isUndepositedCheckPayment;
  const isUpdated =
    originPayment &&
    payment?.metadata?.failedType &&
    (fundingSource?.id !== originPayment?.fundingSourceId ||
      originPayment?.fundingSource?.plaidAccount?.plaidItemId !== fundingSource?.plaidAccount?.plaidItemId);

  const [showMicroDepositsWasSentDialog, setShowMicroDepositsWasSentDialog] = useState(false);
  const isDepositsNotSent = !!fundingSource?.bankAccount?.depositsNotSent;

  return (
    <>
      {showMicroDepositsWasSentDialog ? (
        <PayBillPaymentMicroDepositDialog
          fundingSource={fundingSource}
          setShowMicroDepositsWasSentDialog={setShowMicroDepositsWasSentDialog}
        />
      ) : null}
      <PaymentActivityContainer>
        <PaymentActivityMainContainer>
          <PaymentInfoContainer>
            <PaymentIcon>
              <FundingSourceIcon fundingSource={fundingSource} />
            </PaymentIcon>
            <PaymentTextContainer>
              <PaymentMethodContainer>
                <PaymentMethodText>
                  <MIFormattedText label={label} />
                </PaymentMethodText>
              </PaymentMethodContainer>
              <PaymentInfoText>
                <FundingSourceDescription bill={bill} fundingSource={fundingSource} />
                {isUpdated && <Tag label="payBillPaymentActivity.updated" />}
              </PaymentInfoText>
            </PaymentTextContainer>
          </PaymentInfoContainer>
          {canEdit && <EditElement testId="edit-payment-method" onEdit={onEdit} isDisabled={isEditDisabled} />}
        </PaymentActivityMainContainer>
        {hint && (
          <Flex ml={12} textStyle="body4">
            {showFundingSourceDescription ? <PayFundingSourceDescriptionWarningIcon /> : null}
            <Box color="grey.600">
              <MIFormattedText label={hint} />
            </Box>
            {showFundingSourceDescription && !isDepositsNotSent ? (
              <Box
                as="a"
                cursor="pointer"
                textStyle="link3"
                _hover={{ textDecoration: 'none' }}
                ms={1}
                onClick={() => setShowMicroDepositsWasSentDialog(true)}
              >
                <MIFormattedText label="settings.paymentMethods.verifyAccountHint" />
              </Box>
            ) : null}
          </Flex>
        )}
      </PaymentActivityContainer>
    </>
  );
};
