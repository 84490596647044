import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import qbLogo from 'src/images/accounting-software/quickbooks-logo.svg';
import { QBOSyncNowButtons } from 'src/pages/settings/components/accounting-software/components/qbo/QBOSyncNowButtons';
import { NotificationCardTypes } from 'src/utils/consts';
import {
  AccountingPlatformLogo,
  ButtonsContainer,
  ConnectErrorDescriptionWrapper,
  ErrorNotificationCard,
  SupportLink,
} from '../AccountingSoftwareCardElements';
import { ConnectionErrorType } from './enums';

type ErrorViewProps = {
  errorType: ConnectionErrorType;
  onLink: () => void;
  QBCompany?: string;
  connecting?: boolean;
};

export const QBOErrorView = ({ errorType, onLink, QBCompany, connecting }: ErrorViewProps) => {
  const reconnectButton = (
    <Button
      variant={ButtonVariants.tertiary}
      size={ButtonSizes.lg}
      testId="reconnect-to-qbo"
      label="settings.newAccountingSoftware.relink"
      onClick={onLink}
      isLoading={connecting}
    />
  );
  const errorStateMapping = {
    [ConnectionErrorType.INVALID_CREDENTIALS]: {
      errorTitle: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.invalidCredentials.title',
      },
      errorDescription: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.invalidCredentials.description',
        values: {
          supportMail: <SupportLink label="settings.newAccountingSoftware.Quickbooks.supportTeam" />,
        },
      },
      buttons: reconnectButton,
    },
    [ConnectionErrorType.DIFFERENT_REALM_ID]: {
      errorTitle: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.differentRealmId.title',
        values: { companyName: QBCompany },
      },
      errorDescription: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.differentRealmId.description',
        values: {
          supportMail: <SupportLink />,
        },
      },
      buttons: reconnectButton,
    },
    [ConnectionErrorType.INVALID_SUBSCRIPTION]: {
      errorTitle: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.invalidSubscription.title',
      },
      errorDescription: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.invalidSubscription.description',
        values: {
          supportMail: <SupportLink label="settings.newAccountingSoftware.Quickbooks.supportTeam" />,
        },
      },
      buttons: <QBOSyncNowButtons />,
    },
    [ConnectionErrorType.GENERIC_ERROR]: {
      errorTitle: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.genericError.title',
      },
      errorDescription: {
        label: 'settings.newAccountingSoftware.Quickbooks.connectionError.genericError.description',
        values: {
          supportMail: <SupportLink label="settings.newAccountingSoftware.Quickbooks.supportTeam" />,
        },
      },
      buttons: reconnectButton,
    },
  };
  const errorLabels = errorStateMapping[errorType];

  return (
    <MICard data-testid="error-container">
      <MICardForm>
        <AccountingPlatformLogo src={qbLogo} alt="logo" />
        <MICardTitle label="settings.newAccountingSoftware.Quickbooks.cardTitle" />
        <ErrorNotificationCard type={NotificationCardTypes.ERROR} title={errorLabels.errorTitle} />
        <ConnectErrorDescriptionWrapper>
          <MIFormattedText {...errorLabels.errorDescription} />
        </ConnectErrorDescriptionWrapper>
        {errorLabels.buttons && <ButtonsContainer>{errorLabels.buttons}</ButtonsContainer>}
      </MICardForm>
    </MICard>
  );
};
