import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/fp/isEmpty';
import { generatePath } from 'react-router-dom';
import { removeIdsFromState } from 'src/helpers/redux/listsActions';
import { ALL_AT_ONCE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { history } from 'src/providers/AppProviders';
import { DELETE_FUNDING_SOURCE_SUCCESS } from 'src/redux/user/actionTypes';
import { BillingSettingsType } from 'src/utils/types';
import { deliveryApi } from './api';
import { batchPaymentsListName, name } from './consts';
import { BatchItemType } from './types';

const initialState = {
  settings: {
    fundingSourceId: null,
    scheduledDate: null,
    minScheduledDate: null,
    dateOption: ALL_AT_ONCE_OPTION_ID,
    billIds: [],
    bulkPaymentToggleOn: true,
  },
  lists: {},
  createBatch: {},
  byId: {},
};
type SliceState = typeof initialState & { byId: Record<string, BatchItemType> };

export type BatchActionParams = {
  fundingSourceId: number;
  scheduledDate: string | null;
  minScheduledDate: string | null;
  dateOption: string;
  billIds: string[];
  fee?: BillingSettingsType['fee'];
  exitLocation: typeof history.location;
  bulkPaymentToggleOn: boolean;
};

type SliceReducers = {
  update: (state: SliceState, action: any) => void;
  removeBatchItem: (state: SliceState, action: any) => void;
  removeBillFromPayment: (state: SliceState, action: any) => void;
  clearBillIdsList: (state: SliceState) => void;
};

const createRegularBatchBillsSettingsSlice = () => {
  const slice = createSlice<SliceState, SliceReducers>({
    name,
    initialState,
    reducers: {
      update(state, { payload }) {
        state.settings = { ...state.settings, ...payload };
        state.createBatch = {};
      },
      removeBatchItem(state, { payload }) {
        const { itemId } = payload;

        const currentBatchItemBillIds = state.byId[itemId].payment.bills.map((bill) => bill.id.toString());
        state.settings.billIds = state.settings.billIds.filter((id) => !currentBatchItemBillIds.includes(id));
        removeIdsFromState(state, [itemId]);
      },
      removeBillFromPayment(state, { payload }) {
        const { itemId, parentId } = payload;

        state.settings.billIds = state.settings.billIds.filter((id) => id !== itemId.toString());
        state.byId[parentId].payment.bills = state.byId[parentId].payment.bills.filter((bill) => bill.id !== itemId);
        state.byId[parentId].payment.billId = state.byId[parentId].payment.bills[0].id;
      },
      clearBillIdsList(state) {
        if (!isEmpty(state.lists)) {
          removeIdsFromState(state, state.settings.billIds);
          delete state.lists[batchPaymentsListName];
        }
      },
    },
    extraReducers: {
      [DELETE_FUNDING_SOURCE_SUCCESS](state, action) {
        if (state.settings.fundingSourceId === action.deletedFundingSourceId) {
          state.settings = initialState.settings;
        }
      },
    },
  });
  const clearBillIdsList = (dispatch) => () => dispatch(slice.actions.clearBillIdsList());
  const update = (dispatch) => (params: BatchActionParams) => dispatch(slice.actions.update(params));

  return {
    ...slice,
    initialState,
    selectors: {
      settings: (state) => state[name].settings,
    },
    dispatchers: {
      update,
      removeBatchItem: (dispatch) => (params) => dispatch(slice.actions.removeBatchItem(params)),
      removeBillFromPayment: (dispatch) => (params) => dispatch(slice.actions.removeBillFromPayment(params)),
      clearBillIdsList,
      startBatchPaymentsFlow: (dispatch) => async (params: { orgId: number } & BatchActionParams) => {
        const { orgId, minScheduledDate, fundingSourceId } = params;

        if (minScheduledDate) {
          update(dispatch)(params);
        } else {
          const { scheduleDate } = await deliveryApi.getEffectiveScheduleDate({
            orgId,
            scheduledDate: new Date(),
            fundingSourceId,
          });
          update(dispatch)({ ...params, minScheduledDate: scheduleDate });
        }

        clearBillIdsList(dispatch)();
        history.push(generatePath(batchPaymentsLocations.base, { orgId }));
      },
    },
  };
};

export const regularBatchBillsSettingsSlice = createRegularBatchBillsSettingsSlice();
