import { Location } from 'history';
import { NavLink } from 'react-router-dom';
import Box from 'src/core/ds/box';
import { Icon, IconNames } from 'src/core/ds/icon';
import { useSiteContext } from 'src/hoc/withSiteContext';

type Props = {
  exitLink?: string | Location;
  goExit?: () => void;
};

export const CloseButton = ({ exitLink, goExit }: Props) => {
  const { EmbeddedCloseButton } = useSiteContext().components;

  if (exitLink) {
    return (
      <NavLink to={exitLink}>
        <Icon name={IconNames.close} />
      </NavLink>
    );
  }

  if (goExit) {
    return (
      <Box onClick={goExit} color="grey.600" _hover={{ color: 'black' }}>
        <Icon name={IconNames.close} data-testid="close-form-icon" />
      </Box>
    );
  }

  return <EmbeddedCloseButton />;
};
