import { ActionOption } from 'src/components/common/MIActionsMenu';
import { PermissionsType } from 'src/utils/permissions';
import { DeliveryMethodType } from 'src/utils/types';

type ActionsFilterType = {
  deliveryMethod: DeliveryMethodType;
  permissions: PermissionsType;
  isConnectedToAccountingPlatform: boolean;
  isMsnUpdateDeliveryMethodFeatureFlagEnabled?: boolean;
  goEditDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
  goViewDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
  goEditBankAccountLink?: (deliveryMethod: DeliveryMethodType) => void;
  onDeleteDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
  onUpdateDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
};

type ActionOptionFilterType = ActionOption & {
  isVisible?: boolean;
};

export const getDeliveryMethodActionOptions = ({
  permissions,
  isConnectedToAccountingPlatform,
  goEditDeliveryMethod,
  onDeleteDeliveryMethod,
  goViewDeliveryMethod,
  goEditBankAccountLink,
  deliveryMethod,
  onUpdateDeliveryMethod,
  isMsnUpdateDeliveryMethodFeatureFlagEnabled,
}: ActionsFilterType): ActionOption[] => {
  const actionOptions: ActionOptionFilterType[] = [
    {
      label: 'settings.receivingMethods.edit',
      action: () =>
        isMsnUpdateDeliveryMethodFeatureFlagEnabled
          ? onUpdateDeliveryMethod?.(deliveryMethod)
          : goEditDeliveryMethod?.(deliveryMethod),
      isVisible:
        goEditDeliveryMethod &&
        permissions.deliveryMethod.update() &&
        (isMsnUpdateDeliveryMethodFeatureFlagEnabled || !deliveryMethod.plaidAccount),
    },
    {
      label: 'settings.receivingMethods.view',
      action: () => goViewDeliveryMethod?.(deliveryMethod),
      isVisible: goViewDeliveryMethod && !deliveryMethod.plaidAccount,
    },
    {
      label: 'settings.receivingMethods.editBankAccountLink',
      action: () => goEditBankAccountLink?.(deliveryMethod),
      isVisible: goEditBankAccountLink && isConnectedToAccountingPlatform,
    },
    {
      label: 'settings.receivingMethods.delete',
      action: () => onDeleteDeliveryMethod?.(deliveryMethod),
      negative: true,
      isVisible: onDeleteDeliveryMethod && permissions.deliveryMethod.delete(),
    },
  ];
  const enabledActionOptions = actionOptions.filter(({ isVisible }) => isVisible).map(({ isVisible, ...rest }) => rest);

  return enabledActionOptions;
};
