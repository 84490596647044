import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import organizationStore from 'src/modules/organizations/organizations-store';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';

type Props = {
  onPrev: () => void;
  onNext: () => void;
};

export const QuickbookMultiOrgAddAccountingFirmName = ({ onPrev, onNext }: Props) => {
  const dispatch = useDispatch();
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const model = useMemo(
    () => ({
      companyName: newCompanyInfo.companyName || '',
    }),
    [newCompanyInfo]
  );

  const [companyInfoMV, { submit }] = useForm(model, {
    submit: async (value) => {
      const validationErrors = getValidationErrors('companyInfo', value);

      if (!isEmpty(validationErrors)) {
        analytics.trackAction('form-validation-error', { validationErrors });
        throw new ValidationError({ validationErrors });
      }

      dispatch(
        organizationStore.actions.updateNewCompanySlice.update({
          ...newCompanyInfo,
          ...value,
        })
      );
      onNext();
    },
  });

  return (
    <StepLayoutPage title="multiOrgOnboarding.name.title" relativeStep={2 / 5} onNext={submit} onPrev={onPrev}>
      <WizardFormContainer>
        <WizardTextInputField
          model={companyInfoMV.companyName}
          label="multiOrgOnboarding.name.nameLabel"
          hint="multiOrgOnboarding.name.nameHint"
          required
          autoFocus
        />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};
