import Flex from 'src/core/ds/flex';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { RemoveSubRow } from './RemoveSubRow';

type Props = {
  rowHovered: boolean;
  bill: BatchBillType;
};

export const SubActionsCell = ({ rowHovered, bill }: Props) => {
  const stopPropagation = (event) => event.stopPropagation();

  return (
    <Flex onClick={stopPropagation} justifyContent="flex-end" mr={4}>
      <RemoveSubRow itemId={bill.id} balance={bill.balance} rowHovered={rowHovered} />
    </Flex>
  );
};
