import styled from 'styled-components';
import { ActionsDropdown } from 'src/components/common/ActionsDropDown';
import { MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import Flex from 'src/core/ds/flex';
import { InvoiceType } from 'src/modules/invoices/types';
import PaymentActivity from 'src/pages/bill/components/PaymentActivity';
import { useGetProEnabled } from 'src/pages/get-pro/hooks/useGetProEnabled';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { devices } from 'src/theme/appDevices';
import { getPaymentActivityDeliveryData } from 'src/utils/bills';
import { BillingItemTypeEnum, InvoiceStatus, MiMoneyFlavor } from 'src/utils/consts';
import { BillingItemStatusEnum, PaymentType } from 'src/utils/types';

type Props = {
  invoice: InvoiceType;
  showMenu: boolean;
  isMarkedAsPaid: boolean;
  remove: (removeInvoice?: boolean) => Promise<void>;
  onMarkInvoiceAsPaid: (value: boolean) => void;
  showGetPaidTabs?: boolean;
  currentPayment?: PaymentType;
};

const PaymentDetails = ({
  invoice,
  showMenu,
  remove,
  onMarkInvoiceAsPaid,
  isMarkedAsPaid,
  showGetPaidTabs,
  currentPayment,
}: Props) => {
  const { bill, customer } = invoice;
  const payment = currentPayment || ({} as PaymentType);
  const { billingItems, note } = payment;
  const deliveryMethod = deliveryMethodFactory(payment.deliveryMethod);
  const deliveryData = getPaymentActivityDeliveryData(
    payment,
    bill?.status,
    false,
    false,
    bill?.vendor?.companyName || customer?.companyName || '',
    payment.deliveryEta,
    deliveryMethod,
    false,
    undefined,
    undefined,
    true,
    invoice?.status,
    showGetPaidTabs
  );

  const feeBillingItem = billingItems?.find(
    (billingItem) =>
      [
        BillingItemTypeEnum.EXPEDITED_ACH_VENDOR,
        BillingItemTypeEnum.RTP,
        BillingItemTypeEnum.AR_PROCESSING_FEE,
      ].includes(billingItem.type) &&
      [BillingItemStatusEnum.CHARGED, BillingItemStatusEnum.PENDING].includes(billingItem.status)
  );

  const isPaid = invoice.status === InvoiceStatus.PAID;

  const amount = payment?.amount || Number(invoice.totalAmount);

  const setMarkInvoiceAsPaid = () => {
    onMarkInvoiceAsPaid(!isMarkedAsPaid);
  };

  const actionOptions = isMarkedAsPaid
    ? [
        {
          label: 'getPaid.view.invoice.undoMarkAsPaid',
          action: setMarkInvoiceAsPaid,
          negative: false,
        },
      ]
    : [
        {
          label: 'getPaid.view.payment.delete',
          action: remove,
          negative: true,
        },
      ];

  const isGetProEnabled = useGetProEnabled();

  return (
    <MICardForm noHorizontalPadding={showGetPaidTabs}>
      <Flex justify="space-between">
        <MICardTitle label="getPaid.view.payment.title" />
        {showMenu && <ActionsDropdown testId="getPaid.view.payment.actionsMenu" actionOptions={actionOptions} />}
      </Flex>
      {isPaid && feeBillingItem ? (
        <TotalPaymentWithFee data-testid="get-paid-total-payment-amount-with-fee">
          <TotalPaymentItem>
            <MIFormattedText label="getPaid.view.payment.chargedPayment.paymentAmount" />
            <MIFormattedCurrency privateData value={amount} />
          </TotalPaymentItem>
          <TotalPaymentItem>
            <MIFormattedText label="getPaid.view.payment.chargedPayment.feesDeducted" />
            <MIFormattedCurrency privateData value={feeBillingItem.amount} />
          </TotalPaymentItem>
          <TotalPaymentItem>
            <MIFormattedText label="getPaid.view.payment.chargedPayment.amountDeposited" />
            <MIMoney privateData amount={amount - feeBillingItem.amount} flavor={MiMoneyFlavor.POSITIVE} />
          </TotalPaymentItem>
        </TotalPaymentWithFee>
      ) : (
        <TotalPayment data-testid="get-paid-total-payment-amount">
          <SubTitle>
            <MIFormattedText label="getPaid.view.payment.total" />
          </SubTitle>
          <MIMoney privateData amount={amount} flavor={isPaid ? MiMoneyFlavor.POSITIVE : MiMoneyFlavor.DEFAULT} />
        </TotalPayment>
      )}
      {!isGetProEnabled && <Separator />}
      {isMarkedAsPaid ? (
        <MarkAsPaidContainer>
          <MarkAsPaidInfo>
            <MIFormattedText
              label="bills.view.markAsPaidInfo"
              values={{
                date: <MIFormattedDate date={invoice.markedAsPaidAt} />,
              }}
            />
          </MarkAsPaidInfo>
        </MarkAsPaidContainer>
      ) : (
        <PaymentActivity
          bill={bill}
          scheduledData={null}
          deliveryData={deliveryData}
          memo={note}
          onEditNote={null}
          isRecurringBill={false}
          isPayeeMode
        />
      )}
    </MICardForm>
  );
};

export default PaymentDetails;

const SubTitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
  font-weight: ${(props) => props.theme.text.weight.bold};
`;

const TotalPayment = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 3rem 0;
`;

const TotalPaymentWithFee = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 2rem 0 3rem 0;
`;

const TotalPaymentItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 2rem;
`;

const Separator = styled.div`
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.border.darkGrey};
  margin: 0 -3.6rem 3rem;

  @media ${devices.mobile} {
    margin: 0 -1.6rem 3rem;
  }
`;

const MarkAsPaidContainer = styled.div`
  padding: 1.5rem 0 3rem;
`;

const MarkAsPaidInfo = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.6rem;
  padding: 2.7rem 2rem;
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;
