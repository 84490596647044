import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { LegalInfoForm } from 'src/pages/bill/pay/components/LegalInfoForm';
import { ModelView } from 'src/ui/form';
import { CompanyFormPage, FormType, ScreenMode } from 'src/utils/consts';
import { CompanyInfoType, FieldType, GoogleCombinedAddressType } from 'src/utils/types';

type Props = {
  isEditMode: boolean;
  editable: boolean;
  isLoading: boolean;
  onToggleMode: () => void;
  mode: ScreenMode;
  model: ModelView<Partial<CompanyInfoType>>;
  legalAddress: GoogleCombinedAddressType;
  onLegalAddressChange: (address: GoogleCombinedAddressType) => void;
  onChangeModel: (value: FieldType) => void;
  validationErrors: Record<string, any>;
};

export const CompanyLegalInformation = ({
  isEditMode,
  editable,
  isLoading,
  onToggleMode,
  mode,
  model,
  legalAddress,
  onLegalAddressChange,
  onChangeModel,
  validationErrors,
}: Props) => (
  <MICard>
    <MICardForm>
      <Flex align="center" justify="space-between" minH="4.2rem" mb={2}>
        <MICardTitle label="settings.company.legalInfoTitle" />
        {!isEditMode && editable && (
          <Button
            isLoading={isLoading}
            onClick={onToggleMode}
            label="settings.actions.edit"
            size={ButtonSizes.md}
            variant={ButtonVariants.tertiary}
          />
        )}
      </Flex>
      <LegalInfoForm
        mode={mode}
        legalCompanyName={model.legalCompanyName.value}
        taxId={model.taxId.value}
        taxIdType={model.taxIdType.value}
        address={legalAddress}
        onChange={onChangeModel}
        onAddressChange={onLegalAddressChange}
        formType={FormType.DETAILS}
        companyFormPage={CompanyFormPage.SETTINGS}
        validationErrors={validationErrors}
      />
    </MICardForm>
  </MICard>
);
