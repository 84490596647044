import { Switch } from 'react-router-dom';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { authLocations } from './locations';
import IntuitConnectPageContainer from './quickbooks/IntuitConnectPageContainer';

export const AuthPrivateRouter = () => (
  <Switch>
    <SmartRoute path={authLocations.intuit.login} exact>
      <IntuitConnectPageContainer />
    </SmartRoute>
  </Switch>
);
