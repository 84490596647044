import * as React from 'react';
import { compose } from 'recompose';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SearchInputContainer } from 'src/components/list/SortSearchFilter/SearchInputContainer';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { withBreak, withListSearchBarContext, withNavigator } from 'src/hoc';
import { analytics } from 'src/services/analytics';
import { showSearchBar } from 'src/utils/search-bar-utils';
import { ListSearchBarContextType } from 'src/utils/types';

type Props = {
  children: React.ReactElement<any>;
  params: Record<string, any>;
  location: {
    pathname: string;
  };
  canSwitchCompanies: boolean;
  isMemberOfAccountingFirm: boolean;
  isSearchInputEnabled: boolean;
  shouldSplitContactsTab: boolean;
  pageType?: string;
} & ListSearchBarContextType;

type State = {
  isSidebarOpen: boolean;
  isSearchBarOpen: boolean;
};

class MobileHeader extends React.PureComponent<Props, State> {
  static defaultProps = {};

  constructor(props: Props) {
    super(props);
    this.state = {
      isSidebarOpen: false,
      isSearchBarOpen: false,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isSidebarOpen: false });
    }
  }

  onCloseClicked = () => {
    this.setState({ isSidebarOpen: false });
  };

  getPathName = () => {
    const pathName = this.props.location.pathname.split('/');

    return pathName[1] === 'orgs' ? pathName[3] : pathName[1];
  };

  onCancelClicked = () => {
    const pathName = this.getPathName();
    analytics.track(pathName, 'search-cancel');
    this.props.clearSearchContext();
    this.toggleSearchBar();
  };

  getMobileHeaderTitle() {
    const { isMemberOfAccountingFirm, shouldSplitContactsTab, pageType } = this.props;
    const pathName = this.getPathName();

    if (pathName === 'companies' && isMemberOfAccountingFirm) {
      return 'nav.mobileHeader.clients';
    }

    if (pathName === 'contacts') {
      return shouldSplitContactsTab ? `nav.mobileHeader.contacts.${pageType}` : `nav.mobileHeader.contacts.unified`;
    }

    return `nav.mobileHeader.${pathName}`;
  }

  toggleSidebar = () => {
    // TODO: This code doesn't seem to run anywhere
    if (this.state.isSidebarOpen) {
      analytics.track('hamburger-menu', 'close');
    } else {
      analytics.track('hamburger-menu', 'open');
    }

    this.setState(({ isSidebarOpen }) => ({ isSidebarOpen: !isSidebarOpen }));
  };

  toggleSearchBar = () => {
    const pathName = this.getPathName();

    if (!this.state.isSearchBarOpen) analytics.track(pathName, 'search-show');

    this.setState(({ isSearchBarOpen }) => ({
      isSearchBarOpen: !isSearchBarOpen,
    }));
  };

  render() {
    const sideMenu = this.props.children;
    const { orgId } = this.props.params;

    return (
      <>
        <div>
          {React.cloneElement(sideMenu, {
            isOpen: this.state.isSidebarOpen,
            onCloseClicked: this.onCloseClicked,
          })}
        </div>
        <Box
          bgColor="white"
          h="6.4rem"
          pos="absolute"
          top={this.props.canSwitchCompanies ? '4rem' : '0'}
          w="full"
          zIndex="1010"
          boxShadow={{
            base: showSearchBar(this.props.location.pathname, orgId) ? '0' : '400',
            md: '400',
          }}
        >
          <Flex
            boxSizing="border-box"
            direction="row"
            w="full"
            py={{
              base: this.state.isSearchBarOpen ? 4 : 6,
              md: this.state.isSearchBarOpen ? 3 : 6,
            }}
            px={5}
          >
            {!this.state.isSearchBarOpen && (
              <>
                <Box onClick={this.toggleSidebar} data-testid="menu-toggle" cursor="pointer" textStyle="h2" w="2.4rem">
                  <Icon name={IconNames.hamburger} size={IconSize.lg} />
                </Box>
                <Box
                  textStyle="body1Semi"
                  color="black"
                  flexGrow={1}
                  textAlign="center"
                  textTransform="capitalize"
                  data-testid="mobile-header-title"
                >
                  <MIFormattedText label={this.getMobileHeaderTitle()} />
                </Box>
                <Box
                  onClick={this.toggleSearchBar}
                  cursor="pointer"
                  textStyle="h2"
                  w="2.4rem"
                  visibility={showSearchBar(this.props.location.pathname, orgId) ? 'block' : ('hidden' as any)}
                >
                  <Icon name={IconNames.search} size={IconSize.lg} />
                </Box>
              </>
            )}

            {this.state.isSearchBarOpen && (
              <>
                <SearchInputContainer />
                <Button
                  variant={ButtonVariants.tertiaryNaked}
                  label="list.cancelSearch"
                  onClick={this.onCancelClicked}
                />
              </>
            )}
          </Flex>
        </Box>
      </>
    );
  }
}

export default compose(withNavigator(), withBreak(), withListSearchBarContext())(MobileHeader);
