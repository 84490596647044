import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchListResultType, GroupedBatchListResultType } from 'src/modules/regular-batch-payments/types';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { getDefaultMemo, getDefaultMemoFromBills } from 'src/utils/bills';
import { FeatureFlags } from 'src/utils/consts';
import { VendorType } from 'src/utils/types';

type UseSetPaymentsMemoType = {
  setPaymentsMemo: (items: (BatchListResultType | GroupedBatchListResultType)[]) => Promise<void>;
};

export const useSetPaymentsMemo = (): UseSetPaymentsMemoType => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const [isBulkPaymentEnabled] = featureFlags.useFeature(FeatureFlags.BulkPayment, false);

  const setMemoForPayments = (items: GroupedBatchListResultType[], vendorOriginIdToIntuitAcctNumber) => {
    items.forEach((item) => {
      const acctNum = vendorOriginIdToIntuitAcctNumber[item.payment.vendor.id];
      actions.updatePayments.setMemo({
        memo: getDefaultMemoFromBills(item.payment.bills, acctNum),
        itemId: item.id,
      });
    });
  };

  const setMemoForBills = (items: BatchListResultType[], vendorOriginIdToIntuitAcctNumber) => {
    items.forEach((item) => {
      const acctNum = vendorOriginIdToIntuitAcctNumber[item.bill.vendor.id];
      actions.updatePayments.setMemo({
        memo: getDefaultMemo(item.bill.invoiceNumber, acctNum),
        itemId: item.id,
      });
    });
  };

  const getVendorExternalIdAcctNumberMapping = async (
    vendors: VendorType<'created'>[]
  ): Promise<{ [id: string]: string }> => {
    const vendorIds: string[] = uniq(
      vendors.filter((vendor) => vendor.id && !isRppsVendor(vendor)).map(({ id }) => String(id))
    );

    const { vendors: vendorToAccNum } = await accountingPlatformsApi.getVendorsAcctNum(orgId, vendorIds);

    return vendorToAccNum;
  };

  const setPaymentsMemo = async (items: (BatchListResultType | GroupedBatchListResultType)[]) => {
    let vendors;

    if (isBulkPaymentEnabled) {
      vendors = items.map((item) => (item as GroupedBatchListResultType).payment.vendor);
    } else {
      vendors = items.map((item) => (item as BatchListResultType).bill.vendor);
    }

    const vendorExternalIdToAcctNumber = await getVendorExternalIdAcctNumberMapping(vendors);

    if (isEmpty(vendorExternalIdToAcctNumber)) return;

    if (isBulkPaymentEnabled) {
      setMemoForPayments(items as GroupedBatchListResultType[], vendorExternalIdToAcctNumber);
    } else {
      setMemoForBills(items as BatchListResultType[], vendorExternalIdToAcctNumber);
    }
  };

  return {
    setPaymentsMemo,
  };
};
