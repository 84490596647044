import { LocalVendorType } from 'src/pages/vendor-directory/select-vendor/types';
import { DeliveryType, VendorManagedByEnum } from 'src/utils/consts';
import { VendorType } from 'src/utils/types';

type DirectoryVendorType = Pick<VendorType, 'deliveryMethods' | 'managedBy'>;

const isManagedByMsnVendor = (vendor: DirectoryVendorType) => vendor.managedBy === VendorManagedByEnum.MSN;

export const isRppsVendor = (vendor: Pick<LocalVendorType, 'deliveryMethods'>) =>
  vendor.deliveryMethods?.some((dm) => dm.deliveryType === DeliveryType.RPPS);

export const getVendorBillerId = (vendor: Pick<VendorType, 'deliveryMethods'>) =>
  vendor.deliveryMethods?.find((dm) => dm.deliveryType === DeliveryType.RPPS)?.rppsAccount?.billerId;

export const isDirectoryVendor = (vendor: DirectoryVendorType) => isRppsVendor(vendor) || isManagedByMsnVendor(vendor);
