import { PayBillFlowType, usePayBillFlowType } from 'src/pages/bill/pay/hooks/usePayBillFlowType';
import { useSetInstallmentOption } from 'src/pages/bill/pay/hooks/useSetInstallmentOption';
import { SetInstallmentsOptionsResponse } from 'src/pages/bill/pay/installments/types';
import { PaymentFinancing, PaymentType } from 'src/utils/types';

export const useFinancingPaymentReview = ({
  payment,
}: {
  payment: PaymentType;
}): {
  loading: boolean;
  financing: SetInstallmentsOptionsResponse | PaymentFinancing | undefined;
  isFinancingPayment: boolean;
} => {
  const payBillFlowType = usePayBillFlowType();
  const { result: financing, loading = false } = useSetInstallmentOption();

  return {
    loading,
    financing: payBillFlowType === PayBillFlowType.CreateFinancingPayment ? financing : payment.financing,
    isFinancingPayment: payBillFlowType === PayBillFlowType.CreateFinancingPayment || payment.isFinanced,
  };
};
