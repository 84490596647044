import { useSelector } from 'react-redux';
import { useModal, UseModalReturn } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import organizationStore from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { ButtonsDirections, DialogVariants } from 'src/utils/consts';

type Params = {
  userNotOwnerError?: {
    orgId: number;
    email: string;
    name: string;
  };
  setUserNotOwnerError: (params: any) => void;
};

const useSendRequestToSwichAccountingFirm = ({ userNotOwnerError, setUserNotOwnerError }: Params): UseModalReturn => {
  const failedOrgId = userNotOwnerError?.orgId;
  const ownerEmail = userNotOwnerError?.email;
  const ownerName = userNotOwnerError?.name;
  const organizations = useSelector(profileStore.selectors.getOrganizations) || [];
  const organizationActions = useStoreActions(organizationStore);
  const org = organizations.find((org) => org.id === Number(failedOrgId));
  const [SendRequestToSwichAccountingFirm, showSendRequestToSwichAccountingFirm, showing] = useModal(CommonDialog, {
    confirm: () => organizationActions.requestSwitchAccountingFirm({ orgId: failedOrgId }),
    onDismiss: () => setUserNotOwnerError(null),
    title: 'companies.sendSwitchAccountigFirmRequestDialog.title',
    description: 'companies.sendSwitchAccountigFirmRequestDialog.description',
    confirmText: 'companies.sendSwitchAccountigFirmRequestDialog.confirm',
    hideIcon: true,
    variant: DialogVariants.SUCCESS,
    buttonsDirection: ButtonsDirections.HORIZONTAL,
    textValues: {
      companyName: org?.companyName,
      ownerEmail,
      ownerName,
    },
    modalName: 'sendSwitchAccountigFirmRequestDialog',
  });

  return [SendRequestToSwichAccountingFirm, showSendRequestToSwichAccountingFirm, showing];
};

export default useSendRequestToSwichAccountingFirm;
