export const stepIndexStyle = {
  color: 'primary.500',
  bg: 'primary.300',
  borderRadius: 'full',
};

export const stepIndexText = {
  textStyle: 'h1Semi',
  textAlign: 'center',
  color: 'primary.500',
  w: 10,
};

export const nextStepsListStyle = {
  border: '1px',
  borderColor: 'grey.400',
  borderRadius: 'lg',
  bg: 'white',
  p: 6,
};

export const boxStyle = {
  flex: 1,
  flexGrow: 1,
  display: 'flex',
  textAlign: 'left',
  color: 'black',
  ml: 4,
};
