import { VendorSelectField, VendorSelectFieldPropsType } from 'src/components/fields/VendorSelectField';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { AmountCurrencyField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { useFetchBillAccountCategoryOptions } from 'src/pages/bill/components/BillDetailsForm/hooks/useFetchAccountCategoryOptions';
import { BillDetailsForm } from 'src/pages/onboarding/just-pay/components/BillDetailsForm';
import { JustPayUploadInvoiceLayout } from 'src/pages/onboarding/just-pay/components/JustPayUploadInvoiceLayout';
import { ModelView } from 'src/ui/form';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { WizardSelectField } from 'src/ui/form/WizardSelectField';
import { isBillAmountRequiresInvoiceFile } from 'src/utils/bills';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { CreateBillType } from 'src/utils/types';

type Props = {
  submit: () => Promise<void>;
  model: ModelView<CreateBillType>;
  isLoading: boolean;
} & VendorSelectFieldPropsType;

export const JustPayForm = ({
  submit,
  model,
  isLoading,
  filteredVendors,
  onVendorIdChange,
  onVendorsInputChange,
}: Props) => {
  const showUploadLayout = isBillAmountRequiresInvoiceFile(convertCurrencyToNumber(model.totalAmount.value));

  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const accountCategoriesOptions = useFetchBillAccountCategoryOptions();

  return (
    <Box>
      <FormContainer onSubmit={submit}>
        <VendorSelectField
          filteredVendors={filteredVendors}
          onVendorIdChange={onVendorIdChange}
          onVendorsInputChange={onVendorsInputChange}
          vendorId={model.vendorId}
          accountIdentifier={model.accountIdentifier}
          vendorLabel="bills.justPay.vendorLabel"
          vendorPlaceholder="bills.justPay.vendorPlaceholder"
          vendorNoOptions="bills.justPay.vendorNoOptions"
          vendorAccNumLabel="bills.justPay.vendorAccNum"
          vendorAccNumPlaceholder="bills.justPay.vendorAccNumPlaceholder"
        />
        <Box as={FormRow} mbZero>
          <PrivateDataContainer>
            <AmountCurrencyField
              id="just-pay-amount"
              label="bills.justPay.amountLabel"
              placeholder="bills.justPay.amountPlaceholder"
              model={model.totalAmount}
              decimalScale={2}
              isRequired
            />
          </PrivateDataContainer>
        </Box>
        {showUploadLayout ? <JustPayUploadInvoiceLayout model={model} /> : null}
        {isConnectedAccountingPlatform && (
          <FormRow>
            <WizardSelectField
              label="bills.justPay.intuitAccountLabel"
              placeholder="bills.justPay.intuitAccountPlaceholder"
              options={accountCategoriesOptions}
              model={model.intuitAccountId}
              privateData
            />
          </FormRow>
        )}
        <BillDetailsForm model={model} />
        <Box as={FormRow}>
          <Button
            type="submit"
            label="bills.justPay.submitLabel"
            variant={ButtonVariants.primary}
            size={ButtonSizes.lg}
            isLoading={isLoading}
            w="full"
          />
        </Box>
      </FormContainer>
    </Box>
  );
};
