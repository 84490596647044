import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { analytics } from 'src/services/analytics';

type FooterProps = {
  companyName: string;
};

export const Footer = ({ companyName }: FooterProps) => {
  const site = useSiteContext();

  return (
    <>
      <Box color="grey.700" textAlign="center" textStyle="body4" margin={['1.6rem 0 4rem']}>
        <MIFormattedText
          label="vendors.addDeliveryMethodByLink.confirmMelio"
          values={{
            termsOfService: (
              <Link
                href={site.config.agreementLinks.userAgreement}
                target="_blank"
                color="grey.700"
                textTransform="lowercase"
                textDecoration="underline"
                onClick={() => {
                  analytics.track('unilateral', 'terms-of-service');
                }}
              >
                <MIFormattedText label="vendors.addDeliveryMethodByLink.termsOfService" />
              </Link>
            ),
            privacyPolicy: (
              <Link
                href={site.config.agreementLinks.privacyPolicy}
                target="_blank"
                color="grey.700"
                textTransform="lowercase"
                textDecoration="underline"
                onClick={() => {
                  analytics.track('unilateral', 'privacy-policy');
                }}
              >
                <MIFormattedText label="vendors.addDeliveryMethodByLink.privacyPolicy" />
              </Link>
            ),
          }}
        />
      </Box>
      <MISecurityDetails
        eventPage="unilateral-payment"
        label="vendors.deliveryMethods.virtual.securityText"
        values={{ companyName }}
      />
    </>
  );
};
