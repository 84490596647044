import sumBy from 'lodash/sumBy';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useAmountInputHandlers } from 'src/components/common/hooks/useAmountInputHandlers';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { MaskInput } from 'src/core/ds/input';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { billsStore } from 'src/modules/bills/bills-store';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { canPartiallyPayBill, getBillPaymentIndex } from 'src/utils/bills';
import { convertCurrencyToNumber, convertNumberToCurrency } from 'src/utils/currency-utils';
import { BillType } from 'src/utils/types';

type Props = {
  itemId: string;
  fastFeeAmount?: number | string;
  achToCheckFeeAmount: number;
  payment: BatchPaymentType;
  isDisabled: boolean;
  rowHovered: boolean;
  payBillFlowUUID: string;
};

export const AmountCell = ({
  itemId,
  payment,
  isDisabled,
  fastFeeAmount,
  rowHovered,
  payBillFlowUUID,
  achToCheckFeeAmount,
}: Props) => {
  const { bills, billId, deliveryMethodId, scheduledDate, amount, fundingSourceId } = payment;
  const maxPaymentAmount = sumBy(bills, 'balance');

  const orgId = useSelector(getOrgId);
  const actions = useStoreActions(regularBatchPaymentsStore);
  const singleBill = useSelector<any, BillType>(billsStore.selectors.byId(billId));
  const isBulkPayment = bills && bills?.length > 1;

  const disableEditAmount =
    isBulkPayment ||
    isDisabled ||
    singleBill.qboSyncVersion === 1 ||
    !canPartiallyPayBill(singleBill, false, !!payment.id);

  const [focus, setFocus] = useState(false);
  const [partialPaymentAmount, setPartialPaymentAmount] = useState(payment?.amount?.toString());

  const onChangePartialPaymentAmount = ({ value }: { value: string }) => setPartialPaymentAmount(value);

  const dispatchPayment = async (newAmountValue: number) => {
    if (deliveryMethodId) {
      await actions.updatePaymentsDeliveryOptions({
        itemId,
        orgId,
        deductionDate: scheduledDate,
        deliveryMethodId,
        fundingSourceId,
        amount: newAmountValue,
        payBillFlowUUID,
      });
    } else {
      await actions.updatePayments.setAmount({
        itemId,
        amount: newAmountValue,
      });
    }
  };

  const onBlur = async ({ value }: { value: string }) => {
    const newAmountValue = Number(convertCurrencyToNumber(value));

    if (newAmountValue !== amount) {
      await dispatchPayment(newAmountValue);
    }

    setFocus(false);
  };

  const { handleChange, handleBlur, handleKeyPressed } = useAmountInputHandlers({
    totalAmount: partialPaymentAmount,
    setTotalAmount: setPartialPaymentAmount,
    onChange: onChangePartialPaymentAmount,
    onBlur,
    maxValue: maxPaymentAmount,
  });

  const setPlaceholderValue = () => {
    const valueForPlaceholderAppearance = '';
    setPartialPaymentAmount(valueForPlaceholderAppearance);
  };

  const handleFocus = () => {
    if (disableEditAmount) return;

    setPlaceholderValue();
    setFocus(true);

    analytics.trackAction('payment-on-focus-amount', {
      amount,
      itemId,
      partialBillId: getBillPaymentIndex(singleBill),
      payBillFlowUUID,
    });
  };

  const showEditAmountIcon = !disableEditAmount && rowHovered;

  return (
    <AmountContainer onClick={handleFocus}>
      {focus ? (
        <MaskInput
          id="billAmount"
          size="sm"
          prefix="$"
          autoComplete="off"
          value={partialPaymentAmount}
          placeholder="regularBatchPayments.cells.amount.placeholder"
          placeholderValues={{ balance: convertNumberToCurrency(maxPaymentAmount) }}
          onKeyDown={handleKeyPressed}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus={focus}
          thousandSeparator
        />
      ) : (
        <Flex justify="center" align="center" maxWidth="full">
          <Flex
            data-testid="amount-cell"
            direction="column"
            justify="center"
            isTruncated
            aria-disabled={disableEditAmount}
          >
            <Box textStyle="body4Semi" color={isDisabled ? 'grey.500' : 'black'} isTruncated>
              <MIFormattedCurrency value={amount} privateData />
            </Box>
            {fastFeeAmount && <FeeAmountWrapper feeAmount={fastFeeAmount} />}
            {achToCheckFeeAmount && (
              <FeeAmountWrapper feeAmount={achToCheckFeeAmount} feeAmountTestId="amount-cell-ach-to-check-fee" />
            )}
          </Flex>
          {showEditAmountIcon && (
            <Box pr={2} pl={2}>
              <Icon testId="edit-payment-amount" name={IconNames.edit} />
            </Box>
          )}
        </Flex>
      )}
    </AmountContainer>
  );
};

const FeeAmountWrapper = ({ feeAmount, feeAmountTestId }: { feeAmount: string | number; feeAmountTestId?: string }) => (
  <Flex justify="flex-start" color="grey.700" data-testid="amount-cell-fee">
    <Box>+</Box>
    <Box isTruncated {...(feeAmountTestId ? { 'data-testid': feeAmountTestId } : {})}>
      <MIFormattedCurrency value={feeAmount} privateData />
    </Box>
  </Flex>
);

const AmountContainer = styled.div`
  display: flex;
  align-items: center;

  #billAmount {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.6rem;
    border: none;

    &::placeholder {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 1.6rem;
    }
  }

  .input-wrapper {
    border: 0;
  }
`;
