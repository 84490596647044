import { MICategoryList } from 'src/components/common/MICategoryList';
import { ModelViewField } from '../form';

export type SelectCategoryProps<TValue extends string> = {
  model: ModelViewField<TValue | null | undefined>;
  options: any[];
};
export function SelectCategory<TValue extends string>({ model, options }: SelectCategoryProps<TValue>) {
  return (
    <MICategoryList
      categories={options}
      selectedId={model.value}
      onSelect={(value: TValue) => model.onChange({ value })}
    />
  );
}
