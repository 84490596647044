import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PaymentInfoHint } from 'src/components/layout/PaymentActivityElements';
import { DeliveryType, PaymentApprovalStatus } from 'src/utils/consts';
import { isManualBankAccountNotVerified } from 'src/utils/funding-sources';
import { AccountType, DeliveryMethodType, PaymentType } from 'src/utils/types';

type DeliveryDateInfoHintProps = {
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  fundingSource: AccountType;
};

const DeliveryDateInfoHint = ({ payment, deliveryMethod, fundingSource }: DeliveryDateInfoHintProps) => {
  const isApprovalRequired = payment.approvalDecisionStatus === PaymentApprovalStatus.PENDING;
  const isMicrodeposit = isManualBankAccountNotVerified(fundingSource);
  const isUnitaleral = deliveryMethod.deliveryType === DeliveryType.VIRTUAL;
  let hint;
  const isBlockedForProcessing = isApprovalRequired || isMicrodeposit || isUnitaleral;

  if (isApprovalRequired && isUnitaleral) {
    hint = 'viewBillPaymentActivity.deliveryDate.unilateralApprovalRequiredHint';
  } else if (isUnitaleral) {
    hint = 'viewBillPaymentActivity.deliveryDate.unilateralHint';
  } else if (deliveryMethod.deliveryType === DeliveryType.ACH && !isBlockedForProcessing) {
    hint = 'viewBillPaymentActivity.deliveryDate.achHint';
  }

  if (hint) {
    return (
      <PaymentInfoHint>
        <MIFormattedText label={hint} />
      </PaymentInfoHint>
    );
  }

  return null;
};

export default DeliveryDateInfoHint;
