import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { TextField } from 'src/core/ds/form/fields';
import { useOrgId } from 'src/hooks/useOrgId';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import { useBillFiles } from 'src/pages/bill/hooks/useBillFiles';
import { useAddPaymentPurposeModal } from 'src/pages/regular-batch-payments/components/dialog/useAddPaymentPurposeModal';
import { InvoiceAttachmentField } from 'src/pages/vendor/international-delivery-method/components/InvoiceAttachmentField';
import { InternationalPaymentPurpose } from 'src/pages/vendor/international-delivery-method/consts';
import { useInvoiceAttachmentRequired } from 'src/pages/vendor/international-delivery-method/hooks/useInvoiceAttachmentRequired';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { InlineSelectField } from 'src/ui/form/InlineSelectField';
import { convertNumberToCurrency } from 'src/utils/currency-utils';
import { isPaymentBulkPayment } from 'src/utils/payments';
import { useUploadBillAttachment } from '../../hooks/useUploadBillattachment';

type Props = {
  onClose: () => void;
  payment: BatchPaymentType;
  itemId?: string;
  totalAmount: number;
  vendor: BatchPaymentType['vendor'];
};

export const AddPaymentPurposeModal = ({ onClose, payment, itemId, vendor, totalAmount }: Props) => {
  const orgId = useOrgId();
  const singleBill = !isPaymentBulkPayment(payment) ? payment.bills[0] : undefined;
  const [isInvoiceAttachmentRequired] = useInvoiceAttachmentRequired(orgId, vendor.id, singleBill);
  const isInvoiceAttachmentRequiredAndNotBulkPayment = isInvoiceAttachmentRequired && !isPaymentBulkPayment(payment);

  const { submit: submitForm, model } = useAddPaymentPurposeModal(
    onClose,
    itemId,
    isInvoiceAttachmentRequiredAndNotBulkPayment
  );

  const options = Object.values(InternationalPaymentPurpose).map((value) => ({
    label: value,
    value,
  }));

  const {
    fileName,
    files,
    isUploading,
    isUploadError,
    handleRetry,
    uploadAttachment,
    removeAttachment,
  } = useBillFiles();

  const { handleSelectFile, updateBillFiles, isBillLoading } = useUploadBillAttachment({
    bill: singleBill!,
    files,
    uploadAttachment,
  });

  const onSubmit = async () => {
    if (isInvoiceAttachmentRequiredAndNotBulkPayment && files.length) {
      await updateBillFiles();
    }

    submitForm();
  };

  return (
    <ModalMessage
      alignLeft
      titleComponent={
        <>
          <MIFormattedText label="regularBatchPayments.addPaymentPurpose.title" />
          <Box textStyle="body2" mt={4}>
            <MIFormattedText
              label="regularBatchPayments.addPaymentPurpose.subtitle"
              values={{ amount: convertNumberToCurrency(totalAmount), vendor: vendor?.companyName }}
            />
          </Box>
        </>
      }
      contentComponent={
        <FormContainer>
          <FormRow>
            <InlineSelectField
              id="state"
              label="vendors.deliveryMethods.international.paymentPurpose.label"
              model={model.purpose}
              placeholder="vendors.deliveryMethods.international.paymentPurpose.labelPlaceholder"
              options={options}
              required
            />
          </FormRow>
          <FormRow>
            <TextField
              label="vendors.deliveryMethods.international.paymentPurpose.description"
              placeholder="vendors.deliveryMethods.international.paymentPurpose.descriptionPlaceholder"
              model={model.purposeDescription}
              size="sm"
              isRequired
            />
          </FormRow>
          {isInvoiceAttachmentRequiredAndNotBulkPayment ? (
            <FormRow mbZero>
              <InvoiceAttachmentField
                onSelectFile={handleSelectFile}
                onRetry={handleRetry}
                fileName={fileName}
                files={files}
                testId="input-invoiceAttachment"
                isFileUploading={isUploading}
                isFileUploadError={isUploadError}
                onChangeInvoiceFile={handleSelectFile}
                onDeleteInvoiceFile={removeAttachment}
                model={model.invoiceAttachment}
              />
            </FormRow>
          ) : null}
        </FormContainer>
      }
      buttonLabel="regularBatchPayments.addPaymentPurpose.button"
      analyticsProperties={{ itemId }}
      onButtonClick={onSubmit}
      onCloseClick={onClose}
      isLoading={isBillLoading}
    />
  );
};
