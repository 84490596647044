import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box/Box';
import { ButtonVariants } from 'src/core/ds/button';
import { useBoolean } from 'src/core/ds/hooks';
import { useModal } from 'src/helpers/react/useModal';
import { useApi } from 'src/hoc/useApi';
import { useIsInternationalAllowed } from 'src/hooks/useIsInternationalAllowed';
import { financingStore } from 'src/modules/financing/financing-store';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { FinancingAgreement } from 'src/pages/bill/pay/components/FinancingAgreement';
import { PayBillPaymentReview } from 'src/pages/bill/pay/components/PayBillPaymentReview/PayBillPaymentReview';
import { useIsFinancingPayFlow } from 'src/pages/bill/pay/hooks/useIsFinancingPayFlow';
import { useSetInstallmentOption } from 'src/pages/bill/pay/hooks/useSetInstallmentOption';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { MissingAccountNumberModal } from 'src/pages/vendor-directory/MissingAccountNumberModal/MissingAccountNumberModal';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';
import { analytics } from 'src/services/analytics';
import {
  CreditCardFeePayment,
  DialogType,
  DialogVariants,
  FastACHReviewAndConfirmEntryPoint,
  FeatureFlags,
  PaymentApprovalStatus,
} from 'src/utils/consts';
import { isFastDeliveryType, isLateDeliveryType } from 'src/utils/delivery-methods';
import { hasFailedInstallment } from 'src/utils/financing';
import { AccountType, BillType, Override, PaymentFinancing, PaymentType, RecurringBillType } from 'src/utils/types';
import { useDeliveryOptionForTime } from '../hooks/useDeliveryOptionForTime';
import { RepaymentReview } from './PayBillPaymentReview/PayBillRepaymentReview';

type Props = {
  onSubmit: (payment: PaymentType) => void;
  onPrev: () => void;
  goExit: () => void;
  goEditDeliveryMethod: () => void;
  goEditPartialAmount: () => void;
  goEditFundingSource: () => void;
  goEditNote: () => void;
  goEditDate: () => void;
  bill: BillType;
  payment: PaymentType;
  selectedFundingSource: AccountType;
  isLoading: boolean;
  isRecurring: boolean;
  recurringBill?: RecurringBillType;
  errorCode?: string | null;
  onHideErrorMessageAlert: () => void;
  fee?: PaymentFeeInfo | null;
  headerLabel: string;
  title: string;
  titleValues?: Record<string, any>;
  relativeStep?: number;
  buttonLabel: string;
};

type ApprovalDecisionStatusResponse = {
  approvalDecisionStatus: PaymentApprovalStatus;
};

const stripHtml = (htmlString: string): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;

  return div.textContent === null ? '' : div.textContent;
};

export const PayBillConfirmPage = ({
  onSubmit,
  payment,
  isLoading,
  bill,
  goExit,
  onPrev,
  errorCode,
  onHideErrorMessageAlert,
  goEditDeliveryMethod,
  goEditFundingSource,
  goEditNote,
  goEditDate,
  isRecurring,
  recurringBill,
  fee,
  selectedFundingSource,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  buttonLabel,
  goEditPartialAmount,
}: Props) => {
  const isFinancingPayment = useIsFinancingPayFlow();
  const numOfInstallments = useSelector(financingStore.selectors.intentIdWithInstallmentOption.numberOfInstallments);
  const { result: financing, loading: isFinancingLoading } = useSetInstallmentOption();
  const [isFinancingAgreementApproved, { toggle: setIsFinancingAgreementApproved }] = useBoolean();
  const intl = useIntl();
  const isVendorAbsorbedFee = bill?.paymentRequest?.feesPaidBy === CreditCardFeePayment.VENDOR;
  const { deliveryOptions } = useDeliveryOptionForTime({
    bill,
    payment,
    fundingSource: selectedFundingSource,
    isFinancingPayment,
  });
  const [experimentGroup] = featureFlags.useFeature<FastACHReviewAndConfirmEntryPoint>(
    FeatureFlags.FastACHReviewAndConfirmEntryPoint,
    FastACHReviewAndConfirmEntryPoint.ControlGroup
  );
  const isFailedInstallment = payment && hasFailedInstallment(payment);

  const isFast = isFastDeliveryType(payment.deliveryPreference);
  const isLate = isLateDeliveryType(payment, bill);

  const { result: approvalDecisionStatusResult } = useApi<
    Parameters<typeof deliveryApi['getApprovalDecisionStatus']>,
    ApprovalDecisionStatusResponse
  >({
    api: deliveryApi.getApprovalDecisionStatus,
  });

  const paymentData: PaymentType = {
    ...payment,
    approvalDecisionStatus: approvalDecisionStatusResult?.approvalDecisionStatus ?? payment.approvalDecisionStatus,
  };

  useEffect(() => {
    analytics.page('pay-bill', 'confirm', {
      billId: bill.id,
      fundingSourceId: selectedFundingSource.id,
      isFast,
      isLate,
      variant: experimentGroup,
      deliveryEta: payment.deliveryEta,
      isFinancing: isFinancingPayment,
      numOfInstallments,
      totalAmount: payment.amount,
    });
  }, [bill.id, experimentGroup, selectedFundingSource.id, isFast, payment.deliveryEta, isFinancingPayment]);

  const handleOnSubmit = () => {
    analytics.track('pay-bill-confirm', 'confirm-and-schedule-payment', {
      billId: bill.id,
      fundingSourceId: selectedFundingSource.id,
      isFast,
      variant: experimentGroup,
      deliveryEta: payment.deliveryEta,
      paymentId: payment.id,
      isLate,
    });

    const isRPPSVendor = isRppsVendor({
      ...bill.vendor,
      deliveryMethods: bill.vendor?.deliveryMethods ?? [],
    });
    const missingAccountNumber = isRPPSVendor && isEmpty(bill.vendor?.accountIdentifier);

    if (missingAccountNumber) {
      showMissingAccountNumber();

      return;
    }

    onSubmit(payment);
  };

  const [MissingAccountNumber, showMissingAccountNumber] = useModal(MissingAccountNumberModal, {
    id: 'MissingAccountNumberModal',
    vendorId: bill.vendor?.id,
    onSubmit: handleOnSubmit,
    isLoading,
  });

  const selectedDeliveryMethod = deliveryMethodFactory(
    bill.vendor?.deliveryMethods?.find((dm) => dm.id === payment.deliveryMethodId)
  );
  const { isInternationalAllowed } = useIsInternationalAllowed(bill.vendorId);
  const internationalDeliveryMethod = isInternationalDeliveryMethod(selectedDeliveryMethod?.deliveryType);

  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={bill.totalAmount} />
      </strong>
    ),
    companyName: <strong>{bill.vendor?.companyName}</strong>,
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  const activityActions = {
    onEditDate: goEditDate,
    onEditDeliveryMethod: goEditDeliveryMethod,
    onEditFundingSource: goEditFundingSource,
    onEditNote: goEditNote,
    onEditPartialAmount: goEditPartialAmount,
  };

  const recurringBillData = isRecurring && !isEmpty(recurringBill) ? recurringBill : null;
  const billData = {
    ...bill,
    recurringBill: recurringBillData,
  };

  const isFinancingDisabled = isFinancingPayment && !isFinancingAgreementApproved;
  const isInternationalDisabled = internationalDeliveryMethod && !isInternationalAllowed;
  const isNextDisabled = isFinancingDisabled || isInternationalDisabled;
  const nextTooltip = isInternationalDisabled
    ? {
        label: 'bills.pay.confirm.internationalDisabledTooltip',
      }
    : null;

  return (
    <>
      {MissingAccountNumber}
      <StepLayoutPage
        headerLabel={headerLabel}
        headerLabelValues={headerLabelValues}
        title={title}
        titleValues={titleValues}
        goExit={goExit}
        relativeStep={relativeStep}
        onPrev={onPrev}
        onNext={handleOnSubmit}
        isLoading={isLoading}
        innerSize={50}
        nextLabel={buttonLabel}
        ctaVariant={ButtonVariants.success}
        fullWidthCTA
        isNextDisabled={isNextDisabled}
        nextTooltip={nextTooltip}
      >
        {errorCode && (
          <Dialog
            type={DialogType.ALERT}
            variant={DialogVariants.ERROR}
            title="bills.pay.confirm.error.title"
            subtitle={intl.messages[`server.${errorCode}`] ? `server.${errorCode}` : 'server.Network Error'}
            onCancelAction={onHideErrorMessageAlert}
            cancelButtonText="bills.pay.confirm.error.CTA"
          />
        )}
        <CustomFeed feedType="reviewandconfirm_above" />
        {isFailedInstallment ? (
          <RepaymentReview
            bill={billData}
            fundingSource={selectedFundingSource}
            payment={paymentData as Override<PaymentType, { financing: PaymentFinancing }>}
            isEditDisabled={isLoading}
          />
        ) : (
          <PayBillPaymentReview
            bill={billData}
            fee={!isFinancingPayment ? fee : null}
            fundingSource={selectedFundingSource}
            payment={paymentData}
            deliveryMethod={selectedDeliveryMethod}
            activityActions={activityActions}
            isVendorAbsorbedFee={isVendorAbsorbedFee}
            isRecurring={isRecurring}
            deliveryOptions={deliveryOptions}
            isEditDisabled={isLoading}
          />
        )}
        {isFinancingPayment && (
          <Box marginTop={6}>
            <FinancingAgreement
              isChecked={isFinancingAgreementApproved}
              setIsChecked={setIsFinancingAgreementApproved}
              isLoading={isFinancingLoading}
              disabled={isLoading}
              agreementContent={stripHtml(financing.agreement)}
            />
          </Box>
        )}
      </StepLayoutPage>
    </>
  );
};
