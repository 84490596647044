import { useSelector } from 'react-redux';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { profileStore } from 'src/modules/profile/profile-store';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { isBillHasPartialPayments } from 'src/utils/bills';
import { DeletePaymentAction, FailedPaymentType, PaymentStatus } from 'src/utils/consts';
import {
  isFinancedPayment,
  isPaymentBulkPayment,
  isPaymentCancellationInProgress,
  isPaymentCompletedRefund,
  isPaymentFailedRefund,
  isPaymentScheduleRefund,
} from 'src/utils/payments';
import { BillType, PaymentType } from 'src/utils/types';
import { getDeleteLabel } from './utils';

type Props = {
  payment: PaymentType;
  bill: BillType;
  actionOptions: ActionOption[];
  showMarkAsPaid: boolean;
  onCancelPaymentClicked: (paymentForDeleteType: DeletePaymentAction) => void;
  goEditPayment: (payment: PaymentType) => void;
  onDeleteBill: () => void;
  onMarkPaymentAsPaid: () => void;
  isMarkedAsPaid: boolean;
};

export const useEnhancedPaymentCancellationOptions = ({
  payment,
  bill,
  actionOptions,
  showMarkAsPaid,
  onCancelPaymentClicked,
  goEditPayment,
  onDeleteBill,
  onMarkPaymentAsPaid,
  isMarkedAsPaid,
}: Props) => {
  const billHasPartialPayments = isBillHasPartialPayments(bill);
  const isPaymentCancellationInProgressValue = isPaymentCancellationInProgress(payment);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const currentUser = useSelector(profileStore.selectors.profile);
  const isInvalidBalance = payment?.balance !== 0 && payment?.balance !== null;
  const isInternationalPayment = isInternationalDeliveryMethod(payment.deliveryMethod?.deliveryType);
  const isScheduled = payment.status === PaymentStatus.SCHEDULED || payment.status === PaymentStatus.BLOCKED;
  const { metadata } = payment;
  const isFailedRefund = isPaymentFailedRefund(payment);
  const isScheduledRefund = isPaymentScheduleRefund(payment);
  const isCompletedRefund = isPaymentCompletedRefund(payment);
  const isBulkPayment = isPaymentBulkPayment(payment);
  const isFinancingPayment = isFinancedPayment(payment);
  const isDeletePayment = isBulkPayment || billHasPartialPayments;
  const shouldDisplayMarkAsPay = !isInvalidBalance && showMarkAsPaid && !isCompletedRefund;
  let enhancedActionOptions: ActionOption[] = actionOptions;
  let moduleName;

  if ((isFailedRefund || isScheduledRefund) && !isCompletedRefund) {
    return { enhancedActionOptions: [] };
  }

  if (isMarkedAsPaid) {
    return {
      enhancedActionOptions,
    };
  }

  if (!isPaymentCancellationInProgressValue) {
    enhancedActionOptions = [];
    moduleName = 'bills-payment';

    if (payment.status === PaymentStatus.FAILED) {
      if (shouldDisplayMarkAsPay) {
        enhancedActionOptions.push({
          label: 'bills.form.paymentActivity.actions.markAsPaid',
          action: () => onMarkPaymentAsPaid(),
        });
      }

      if (
        permissions.bills.delete(currentUser, bill) &&
        !(metadata?.canUserRetry && metadata?.failedType === FailedPaymentType.FAILED_TO_DELIVER)
      ) {
        enhancedActionOptions.push({
          label: getDeleteLabel(isCompletedRefund, isDeletePayment),
          action: isDeletePayment ? () => onCancelPaymentClicked(DeletePaymentAction.SINGLE) : () => onDeleteBill(),
          negative: true,
        });
      }
    } else if (isScheduled && goEditPayment && onCancelPaymentClicked) {
      if (!isFinancingPayment && !isInternationalPayment && permissions.bills.update(currentUser, payment.bills)) {
        enhancedActionOptions.push({
          label: 'bills.form.paymentActivity.actions.edit',
          action: () => goEditPayment(payment),
        });
      }

      const cancelAction = {
        label: billHasPartialPayments
          ? 'bills.form.paymentActivity.actions.cancelPayment'
          : 'bills.form.paymentActivity.actions.cancel',
        action: () => onCancelPaymentClicked(DeletePaymentAction.SINGLE),
        negative: true,
      };
      const cancelCurrentRecurringPaymentAction = {
        label: 'bills.form.paymentActivity.actions.cancelCurrentRecurringPayment',
        action: () => onCancelPaymentClicked(DeletePaymentAction.RECURRING_CURRENT),
        negative: true,
      };
      const cancelAllRecurringPayments = {
        label: 'bills.form.paymentActivity.actions.cancelAllRecurringPayments',
        action: () => onCancelPaymentClicked(DeletePaymentAction.RECURRING_ALL),
        negative: true,
      };

      if (permissions.bills.delete(currentUser, payment.bills)) {
        if (bill.recurringBillId) {
          enhancedActionOptions.push(cancelCurrentRecurringPaymentAction, cancelAllRecurringPayments);
        }

        if (!bill.internalBill && !bill.recurringBillId) {
          enhancedActionOptions.push(cancelAction);
        }
      }
    }
  }

  return {
    enhancedActionOptions,
    moduleName,
  };
};
