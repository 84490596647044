import { ChangeEvent, SyntheticEvent } from 'react';
import styled from 'styled-components';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts } from 'src/core/ds/input';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { devices } from 'src/theme/appDevices';
import { handleInputChange } from 'src/utils/input';
import { FieldType } from 'src/utils/types';

type Props = {
  onNext: () => Promise<void>;
  onChange: ({ id, value }: FieldType) => void;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  companyName: string;
  validationErrors: Record<string, any>;
  isLoading: boolean;
};

const GuestInfoDetailsPage = ({
  contactFirstName,
  contactLastName,
  contactPhone,
  onNext,
  onChange,
  validationErrors,
  isLoading,
  companyName,
}: Props) => {
  const { StepLayout } = useSiteContext().components;

  return (
    <StepLayout
      title="guests.infoDetails.title"
      subtitle="guests.infoDetails.subtitle"
      relativeStep={1 / 6}
      isLoading={isLoading}
      onNext={onNext}
    >
      <TextField
        id="companyName"
        label="guests.signUp.businessName"
        value={companyName}
        isRequired
        autoFocus
        type="text"
        errorMessage={validationErrors.companyName}
        onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
        placeholder="guests.signUp.businessNamePlaceholder"
      />
      <GuestNameContainer>
        <TextField
          id="contactFirstName"
          label="guests.infoDetails.contactFirstName"
          value={contactFirstName}
          isRequired
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
          errorMessage={validationErrors.contactFirstName}
        />

        <TextField
          id="contactLastName"
          label="guests.infoDetails.contactLastName"
          value={contactLastName}
          isRequired
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
          errorMessage={validationErrors.contactLastName}
        />
      </GuestNameContainer>

      <MaskField
        id="phone"
        label="guests.infoDetails.contactPhone"
        value={contactPhone}
        isRequired
        format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
        onChange={({ event }: { event: SyntheticEvent<HTMLInputElement> }) => handleInputChange({ event, onChange })}
        errorMessage={validationErrors.phone}
      />
    </StepLayout>
  );
};

export default GuestInfoDetailsPage;

const GuestNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  > div:nth-of-type(1) {
    margin-right: 2rem;
  }

  @media ${devices.mobile} {
    flex-direction: column;
  }
  ${(props) => props.theme?.components?.GuestInfoDetailsPage?.GuestNameContainer}
`;
