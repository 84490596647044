import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { buyersApi } from '../api';

export const useBuyers = () => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: createBuyer, loading, error } = useApi<[{ orgId: number }], string>({
    api: buyersApi.create,
  });

  return {
    loading,
    error,
    create: async () => createBuyer({ orgId }),
  };
};
