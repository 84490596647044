import * as React from 'react';
import Box from 'src/core/ds/box';

type Props = {
  children: React.ReactNode;
  testId?: string;
};

export const PopupItemContainer = ({ children, testId }: Props): React.ReactElement => (
  <Box __css={ItemContainer} data-testid={testId}>
    {children}
  </Box>
);

const ItemContainer = {
  backgroundColor: 'white',
  borderRadius: 'lg',
  border: 0,
  w: 'full',
  mb: 5,
  overflow: 'hidden',
  boxShadow: '500',
  boxSizing: 'border-box',
  p: 5,
  transitionProperty: 'box-shadow',
  transitionDuration: '300ms',
  minHeight: '10rem',
  _hover: {
    boxShadow: '600',
  },
  _selected: {
    p: 4,
    border: '2px',
    borderColor: 'green.500',
    boxShadow: '500',
  },
};
