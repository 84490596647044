import { generatePath } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { settingsLocations } from 'src/pages/settings/locations';
import { AddBankAccountFlow } from '../AddBankAccountFlow';
import { FLOW_ENTRY_POINT } from '../consts';
import { FlowCustomizationsType } from '../types';

export const AddBankAccountFromSettingsWrapper = () => {
  const orgId = useOrgId();
  const saveAndContinuePath = generatePath(settingsLocations.paymentMethods, {
    orgId,
  });
  const flowCustomizations: FlowCustomizationsType = {
    newAccountNotificationLabel: 'onboarding.fundingSources.bank.accountingPlatformAccounts.notification',
    flowEntryPoint: FLOW_ENTRY_POINT.SETTINGS,
  };

  return <AddBankAccountFlow flowCustomizations={flowCustomizations} saveAndContinuePath={saveAndContinuePath} />;
};
