import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Checkbox, Label, MISlider, RoundSlider } from 'src/components/common/MISlider';
import Box from 'src/core/ds/box';
import Tooltip from 'src/core/ds/tooltip';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import useDeliverySpeed from 'src/pages/regular-batch-payments/table/components/DeliverySpeedCell/useDeliverySpeed';
import { getFundingSources } from 'src/redux/user/selectors';
import { CardTypes, DeliveryType, FundingType, RISK_BILL_AMOUNT } from 'src/utils/consts';
import { getDeliveryOption } from 'src/utils/delivery-methods';
import { DeliveryOptionType } from 'src/utils/types';

type Props = {
  itemId: string;
  amount: number;
  payment: BatchPaymentType;
  deliveryOptions?: DeliveryOptionType[];
};

export const DeliverySpeedCell = memo(
  ({
    itemId,
    payment: { fundingSourceId, deliveryMethod, deliveryPreference },
    amount,
    deliveryOptions = [],
  }: Props) => {
    const fundingSources = useSelector(getFundingSources);
    const deliveryMethodType = deliveryMethod?.deliveryType;
    const fundingSource = fundingSources.find((fs) => fs.id === fundingSourceId);
    const fundingSourceType =
      fundingSource?.fundingType === FundingType.ACH
        ? fundingSource?.fundingType
        : fundingSource?.cardAccount?.cardType;

    const [isFastDelivery, isOptionDisabled, onChange] = useDeliverySpeed(
      itemId,
      deliveryPreference,
      deliveryMethodType,
      deliveryMethod?.id,
      deliveryOptions
    );

    const currentDeliveryOption = getDeliveryOption(deliveryPreference || deliveryMethodType, deliveryOptions);
    const deliveryType = currentDeliveryOption?.type;

    const notAvailable = !deliveryType;
    const isFastAchTooltipEnabled = isFastDelivery || (!isFastDelivery && deliveryMethodType === DeliveryType.CHECK);

    const fastSpeedLabel = useMemo(
      () =>
        deliveryMethodType === DeliveryType.ACH
          ? 'regularBatchPayments.cells.deliverySpeed.fastAch'
          : 'regularBatchPayments.cells.deliverySpeed.fastCheck',
      [deliveryMethodType]
    );

    const isAmountOverBigTransferThreshold = amount >= RISK_BILL_AMOUNT.BIG_TRANSFER_THRESHOLD;

    // eslint-disable-next-line consistent-return
    const deliveryDurationSpeedLabel = useMemo(() => {
      if (notAvailable) return 'regularBatchPayments.cells.deliverySpeed.notAvailable';

      switch (deliveryType) {
        case DeliveryType.ACH:
          if (fundingSourceType === FundingType.ACH) {
            return isAmountOverBigTransferThreshold
              ? 'bills.pay.date.deliveryOptions.subTitle.achOver100K'
              : 'bills.pay.date.deliveryOptions.subTitle.achBelow100K';
          }

          if (fundingSourceType === CardTypes.CREDIT || fundingSourceType === CardTypes.DEBIT) {
            return 'bills.pay.date.deliveryOptions.subTitle.cardToAchSameDay';
          }

          break;
        case DeliveryType.VIRTUAL_CARD:
          if (fundingSourceType === FundingType.ACH) {
            return isAmountOverBigTransferThreshold
              ? 'bills.pay.date.deliveryOptions.subTitle.virtualCardOverBigTransferThreshold'
              : 'bills.pay.date.deliveryOptions.subTitle.virtualCardBelowBigTransferThreshold';
          }

          return 'bills.pay.date.deliveryOptions.subTitle.virtualCardCreditOrDebit';
        case DeliveryType.RPPS:
          if (fundingSourceType === FundingType.ACH) {
            return isAmountOverBigTransferThreshold
              ? 'bills.pay.date.deliveryOptions.subTitle.rppsOverBigTransferThreshold'
              : 'bills.pay.date.deliveryOptions.subTitle.rppsBelowBigTransferThreshold';
          }

          return 'bills.pay.date.deliveryOptions.subTitle.rppsCreditOrDebit';
        case DeliveryType.INTERNATIONAL:
          if (fundingSourceType === FundingType.ACH) {
            return isAmountOverBigTransferThreshold
              ? 'bills.pay.date.deliveryOptions.subTitle.internationalOverBigTransferThreshold'
              : 'bills.pay.date.deliveryOptions.subTitle.internationalBelowBigTransferThreshold';
          }

          return 'bills.pay.date.deliveryOptions.subTitle.internationalCreditOrDebit';
        default:
          return `bills.pay.date.deliveryOptions.subTitle.${deliveryType}`;
      }
    }, [deliveryType, notAvailable, fundingSourceType, amount]);

    return (
      <Box role="cell" w="100%" data-testid="delivery-speed-cell">
        {!isOptionDisabled ? (
          <Tooltip
            isDisabled={isFastAchTooltipEnabled}
            placement="top"
            label={<MIFormattedText label="regularBatchPayments.tooltips.fastAchTooltip" />}
          >
            <Box>
              <Slider
                id="paymentDeliverySpeed"
                value={isFastDelivery}
                onChange={onChange}
                negativeLabel={fastSpeedLabel}
                positiveLabel={fastSpeedLabel}
              />
            </Box>
          </Tooltip>
        ) : (
          <Box color={notAvailable ? 'grey.600' : 'black'}>
            <MIFormattedText label={deliveryDurationSpeedLabel} />
          </Box>
        )}
      </Box>
    );
  }
);

const Slider = styled(MISlider)<{ disabled?: boolean }>`
  ${Label} {
    ${({ theme }) => theme.text.fontType.hint};
    color: ${({ disabled, theme }) => (disabled ? theme.text.color.label : theme.text.color.main)};
    margin-right: auto;
  }

  ${Checkbox} {
    :enabled:checked + ${RoundSlider} {
      background-color: ${(props) => props.theme.colors.brand};
    }
  }
`;
