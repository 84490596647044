import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MICard, MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedDateTime } from 'src/components/common/MIFormattedDateTime';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { VStack } from 'src/core/ds/stack';
import { useModal } from 'src/helpers/react/useModal';
import { useTriggerSync } from 'src/hooks/accounting-software/useTriggerSync';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { SyncNowEventsOrigin } from 'src/modules/accounting-platforms/consts';
import { useFetchBillAccountCategoryOptions } from 'src/pages/bill/components/BillDetailsForm/hooks/useFetchAccountCategoryOptions';
import { MelioFeesExpenseAccountView } from 'src/pages/settings/components/accounting-software/components/MelioFeesExpenseAccountView';
import { SyncWithAccountingSoftwareCardProps } from 'src/pages/settings/types';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { AccountingSoftware, DialogVariants, SyncStatus } from 'src/utils/consts';
import { accountingSoftwareDisplayName, eventPage } from '../consts';
import {
  AccountingPlatformLogo,
  ButtonsContainer,
  CardHeaderWrapper,
  DescriptionWrapper,
  LastSyncedWrapper,
} from './AccountingSoftwareCardElements';

type ConnectedViewProps = {
  companyName: string;
  accountingSoftwareType: string;
  logo: string;
  onUnlink: () => Promise<void>;
} & SyncWithAccountingSoftwareCardProps;

const isExpenseAccountAllowed = (accountingSoftwareType: string) => {
  const expenseAccountAllowedByAccountingSoftware = {
    [AccountingSoftware.XERO]: true,
  };

  return !!expenseAccountAllowedByAccountingSoftware[accountingSoftwareType];
};

export const ConnectedView = ({
  companyName,
  accountingSoftwareType,
  logo,
  onUnlink,
  model,
  viewMode,
  onEdit,
  isLoading,
}: ConnectedViewProps) => {
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const [lastFullUpdate, setLastFullUpdate] = useState(connectedAccountingPlatform?.lastSyncAt);
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const unlinkStatus = undefined;
  const { displayName, displayShortName } = accountingSoftwareDisplayName[accountingSoftwareType];
  const isSyncingFromStore = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.isSyncing);
  const shouldShowExpenseAccount = isExpenseAccountAllowed(accountingSoftwareType);
  const accountCategoriesOptions = useFetchBillAccountCategoryOptions();

  const getIsSyncing = async () => {
    const result = await accountingPlatformsApi.getSyncStatus({ orgId });
    const isSyncComplete = result.syncStatus === SyncStatus.SYNCED;

    if (isSyncComplete) {
      setLastFullUpdate(result.lastSyncAt);
    }

    return !isSyncComplete;
  };

  const { triggerSync } = useTriggerSync(getIsSyncing);

  const onUnlinkConfirmed = () => {
    analytics.track(eventPage, 'unlink-confirm', {
      type: accountingSoftwareType,
    });

    return onUnlink();
  };

  const onUnlinkCanceled = () => {
    analytics.track(eventPage, 'unlink-cancel', {
      type: accountingSoftwareType,
    });
  };

  const onSyncNowClick = async () => {
    await analytics.trackAction('accountingSoftwareManualSync-clicked', {
      entryPointName: SyncNowEventsOrigin.SETTINGS_PAGE,
      AccountingSoftwareType: connectedAccountingPlatform?.accountingPlatformCompanyName,
    });

    await triggerSync();
  };

  const [unLink, onUnLink] = useModal(CommonDialog, {
    title: `settings.newAccountingSoftware.SSO.unlinkDialog.title`,
    confirmText: `settings.newAccountingSoftware.SSO.unlinkDialog.confirm`,
    textValues: { accountingSoftwareType: displayShortName },
    variant: DialogVariants.ERROR,
    confirm: onUnlinkConfirmed,
    onDismiss: onUnlinkCanceled,
  });

  return (
    <>
      {unLink}
      {unlinkStatus || (shouldShowExpenseAccount && accountCategoriesOptions.length === 0) ? (
        <AreaLoader />
      ) : (
        <>
          <MICard>
            <MICardForm testId="connected-container">
              <VStack spacing={10}>
                <Box>
                  <AccountingPlatformLogo src={logo} alt="logo" />
                  <CardHeaderWrapper>
                    <MICardTitle
                      label="settings.newAccountingSoftware.SSO.cardTitleConnected"
                      values={{ accountingSoftwareType: displayName }}
                    />
                  </CardHeaderWrapper>
                  <Box mt={3}>
                    <DescriptionWrapper>
                      <MIFormattedText
                        label="settings.newAccountingSoftware.SSO.connected"
                        values={{
                          accountingSoftwareAccount: companyName,
                          melioAccount: companyInfo.companyName,
                          accountingSoftwareType: displayShortName,
                        }}
                      />
                    </DescriptionWrapper>
                    <ButtonsContainer>
                      <Button
                        onClick={onSyncNowClick}
                        disabled={isSyncingFromStore}
                        label={`settings.newAccountingSoftware.${isSyncingFromStore ? 'syncingNow' : 'syncNow'}`}
                        variant={ButtonVariants.tertiary}
                        size={ButtonSizes.lg}
                        mt={{ sm: 5, base: 3 }}
                        mr={{ sm: 5, base: 0 }}
                        mb={{ sm: 0, base: 3 }}
                        w={{ sm: 'initial', base: 'full' }}
                        data-testid="button-settings.newAccountingSoftware.syncNow"
                      />
                      <Button
                        onClick={onUnLink}
                        label="settings.newAccountingSoftware.unlink"
                        variant={ButtonVariants.tertiary}
                        size={ButtonSizes.lg}
                        mt={{ sm: 5, base: 3 }}
                        mr={{ sm: 5, base: 0 }}
                        mb={{ sm: 0, base: 3 }}
                        w={{ sm: 'initial', base: 'full' }}
                      />
                    </ButtonsContainer>
                    <LastSyncedWrapper>
                      <MIFormattedText
                        label="settings.newAccountingSoftware.lastSynced"
                        values={{
                          date: <MIFormattedDateTime date={lastFullUpdate} />,
                        }}
                      />
                    </LastSyncedWrapper>
                  </Box>
                </Box>
                {shouldShowExpenseAccount ? (
                  <MelioFeesExpenseAccountView
                    isLoading={isLoading}
                    model={model}
                    viewMode={viewMode}
                    accountingSoftwareType={accountingSoftwareType}
                    onEdit={onEdit}
                    accountCategoriesOptions={accountCategoriesOptions}
                  />
                ) : null}
              </VStack>
            </MICardForm>
          </MICard>
        </>
      )}
    </>
  );
};
