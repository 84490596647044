import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { REGULAR_BATCH_PAYMENTS_BILLS_LIMIT } from '../regular-batch-payments/BatchSettings/consts';

type Props = {
  enableBatchSelection: boolean;
  markedIds: readonly string[];
  isAllMarked: boolean;
  markAll: () => void;
  clearAll: () => void;
};

export const PayListSelectionMenu = ({ enableBatchSelection, markedIds, isAllMarked, markAll, clearAll }: Props) => {
  const intl = useIntl();
  const menuCheckboxAriaLabel = intl.formatMessage({ id: 'regularBatchPayments.selection.ariaLabels.toggleCheckbox' });
  const menuButtonAriaLabel = intl.formatMessage({ id: 'regularBatchPayments.selection.ariaLabels.openMenuButton' });

  return (
    <Flex alignItems="center" ms={0.5} pl={5} pr={4} pb={6} data-testid="pay-list-selection-menu-container">
      <Tooltip
        gutter={4}
        placement="top"
        shouldWrapChildren
        label={<MIFormattedText label={isAllMarked ? 'pay.filter.clearAll' : 'pay.filter.selectAll'} />}
      >
        <Checkbox
          data-testid="pay-list-selection-menu-checkbox"
          aria-label={menuCheckboxAriaLabel}
          spacing={0}
          isChecked={isAllMarked}
          onChange={isAllMarked ? clearAll : markAll}
          isIndeterminate={enableBatchSelection && !isAllMarked}
        />
      </Tooltip>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label={menuButtonAriaLabel}
          icon={<Icon color="black" name={IconNames.caretDown} />}
        />
        <MenuList>
          <MenuItem
            label="regularBatchPayments.selection.selectAll"
            aria-label="regularBatchPayments.selection.ariaLabels.selectAll"
            values={{
              maxSelected: REGULAR_BATCH_PAYMENTS_BILLS_LIMIT,
            }}
            isDisabled={isAllMarked}
            onClick={markAll}
          />
          <MenuItem
            label="regularBatchPayments.selection.clearSelected"
            aria-label="regularBatchPayments.selection.ariaLabels.clearAll"
            onClick={clearAll}
          />
        </MenuList>
      </Menu>
      <Box textStyle="body4" color="grey.700" ml={2}>
        <MIFormattedText
          values={{
            selectedItems: markedIds.length,
          }}
          label="regularBatchPayments.selection.selectedItemsLabel"
        />
      </Box>
    </Flex>
  );
};
