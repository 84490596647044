export enum STEPS {
  SELECT = 'select',
  PLAID = 'plaid',
  MANUALLY = 'manually',
  LINK_ACCOUNT = 'link-account',
  PENDING = 'pending',
  SUCCESS = 'success',
}

export enum FLOW_ENTRY_POINT {
  SETTINGS = 'settings',
  GET_PAID_SETTINGS = 'get-paid-settings',
  PAY_BILL = 'pay-bill',
  PAY_BATCH = 'pay-batch',
  ONBOARDING = 'onboarding',
  GUEST = 'guest',
}

export enum VERIFICATION_OPTIONS {
  MANUALLY = STEPS.MANUALLY,
  PLAID = STEPS.PLAID,
}

export const LEARN_MORE_LINK =
  'https://melio.zendesk.com/hc/en-us/articles/4447505319314-How-to-verify-a-bank-account-with-micro-deposits';
