import { useState } from 'react';
import { MICard } from 'src/components/common/MICard';
import Box from 'src/core/ds/box';
import { RadioGroupField } from 'src/core/ds/form/fields';
import { Option, Radio } from 'src/core/ds/radio';
import { useSiteContext } from 'src/hoc/withSiteContext';

type Props = {
  onNext: (id?: number) => void;
  onPrev: () => void;
  isLoading?: boolean;
  options: Option[];
  subtitle: string;
};

const GuestInfoDetailsPage = ({ options, onNext, isLoading, onPrev, subtitle }: Props) => {
  const { StepLayout } = useSiteContext().components;
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>();

  const onNextStep = () => {
    onNext(selectedOrganizationId);
  };

  return (
    <StepLayout
      title="guests.selectOrg.title"
      subtitle={subtitle}
      relativeStep={2 / 6}
      isLoading={isLoading}
      onNext={onNextStep}
      isNextDisabled={!selectedOrganizationId}
      onPrev={onPrev}
    >
      <MICard mode="regular">
        <Box m={5}>
          <RadioGroupField
            marginBottom={0}
            id="selectOrganization"
            name="organization"
            direction="column"
            value={selectedOrganizationId}
            isRequired
            onChange={(id) => setSelectedOrganizationId(Number(id))}
          >
            {options.map((item) => (
              <Radio key={item.id} value={item.id?.toString()} label={item?.labelValues?.companyName} />
            ))}
          </RadioGroupField>
        </Box>
      </MICard>
    </StepLayout>
  );
};

export default GuestInfoDetailsPage;
