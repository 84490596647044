import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { authApi } from 'src/modules/auth/api';
import joinOrgRequestsStore from 'src/modules/join-organization-requests/join-organization-requests-store';
import organizationStore, { getOrganizationStoreActions } from 'src/modules/organizations/organizations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { CompanyType, Role } from 'src/utils/consts';
import { NewCompanyContactsInfoPage } from './components/NewCompanyContactsInfoPage';

export type companyContactParams = {
  contactFirstName: string;
  contactLastName: string;
  phone: string;
  email: string;
};

type Props = {
  onPrev: () => void;
  onExit: () => void;
  onSuccess: () => void;
};

export const NewCompanyContactsInfoPageContainer = ({ onPrev, onExit, onSuccess }: Props) => {
  const dispatch = useDispatch();
  const organization = useSelector(profileStore.selectors.getCurrentOrg);
  const { updateNewCompanyInfo, create, createNotOwnedOrganization } = useMemo(
    () => getOrganizationStoreActions(dispatch),
    [dispatch]
  );
  const joinOrgRequestsActions = getStoreActions(joinOrgRequestsStore)(dispatch);
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo);
  const isOwnedCompanyCreating = useSelector(organizationStore.selectors.create.status())?.loading;
  const isJoining = useSelector(joinOrgRequestsStore.selectors.create.status())?.loading;
  const { contactFirstName, contactLastName, phone } = organization;
  const isOwner = useMemo(() => newCompanyInfo?.role === Role.OWNER, [newCompanyInfo]);
  const { onApiCall: onCheckEmailUniqueness } = useApi<[{ email: string }], boolean>({
    api: authApi.checkEmailUniqueness,
  });

  const [showExistEmailModal, setShowExistEmailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitOwner = useCallback(
    async (value) => {
      const companyInfo = {
        ...value,
        ...newCompanyInfo?.address,
        companyName: newCompanyInfo?.companyName,
        role: newCompanyInfo?.role,
        companyType: CompanyType.SMB,
      };
      await create(companyInfo);
      setIsLoading(false);
      onSuccess();
    },
    [create, newCompanyInfo, onSuccess]
  );

  const joinOrgRequests = async () => {
    setShowExistEmailModal(false);
    await joinOrgRequestsActions.create(newCompanyInfo);
    onSuccess();
  };

  const submitNotOwner = async (value) => {
    setIsLoading(true);
    const emailValidationErrors = getValidationErrors('userRegistration', { email: value.email }, undefined, {
      errorOrigin: 'companyOwnerInfo',
    });
    const companyValidationErrors = getValidationErrors(
      'companyInfo',
      value,
      ['phone', 'contactFirstName', 'contactLastName'],
      { errorOrigin: 'companyOwnerInfo' }
    );

    const validationErrors = {
      ...emailValidationErrors,
      ...companyValidationErrors,
    };

    if (!isValidationOk(validationErrors)) {
      setIsLoading(false);
      analytics.track('new-company-not-owned', 'continue-validation-error', validationErrors);
      throw new ValidationError({ validationErrors });
    }

    const companyInfo = {
      ...value,
      ...newCompanyInfo?.address,
      companyName: newCompanyInfo?.companyName,
      role: newCompanyInfo?.role,
    };
    await updateNewCompanyInfo(companyInfo);
    try {
      await onCheckEmailUniqueness({ email: value.email });
      await createNotOwnedOrganization(companyInfo);
      onSuccess();
    } catch (err) {
      setShowExistEmailModal(true);
      setIsLoading(false);
    }
  };

  const model = useMemo(
    () => ({
      contactFirstName: isOwner ? contactFirstName : null,
      contactLastName: isOwner ? contactLastName : null,
      phone: isOwner ? phone : null,
      email: '',
    }),
    [contactFirstName, contactLastName, phone, isOwner]
  );

  const [companyContactsInfoMV, { submit }] = useForm(model, {
    submit: isOwner ? submitOwner : submitNotOwner,
  });

  function onModalDismiss(res) {
    if (res.success) {
      onSuccess();
    } else {
      setShowExistEmailModal(false);
    }
  }

  return (
    <NewCompanyContactsInfoPage
      onSubmit={submit}
      model={companyContactsInfoMV}
      goExit={onExit}
      companyInfo={newCompanyInfo}
      onPrev={onPrev}
      companyName={newCompanyInfo?.companyName}
      isLoading={isOwnedCompanyCreating || isJoining || isLoading}
      showExistEmailModal={showExistEmailModal}
      onModalDismiss={onModalDismiss}
      createJoinRequest={joinOrgRequests}
      isOwner={isOwner}
    />
  );
};
