import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { UploadPicture } from 'src/components/common/UploadPicture';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useApi } from 'src/hoc/useApi';
import { filesApi } from 'src/modules/files/api';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getFullName } from 'src/utils/user';

type Props = {
  onNext: (orgId: number) => void;
};

export function AddProfilePicturePage({ onNext }: Props) {
  const [chosenFile, setChosenFile] = useState<File | ''>('');
  const userActions = useStoreActions(usersStore);
  const orgId = useSelector(getOrgId);
  const curUser = useSelector(profileStore.selectors.profile);
  const name = getFullName(curUser.firstName, curUser.lastName);

  const handleFileChange = (file: File | '') => {
    setChosenFile(file);
  };

  const handleAddClick = () => {
    analytics.trackAction('user-picture-clicked');
  };

  const uploadProfilePicture = useCallback(
    async ({ orgId, file, userId }) => {
      await filesApi.uploadProfilePicture(orgId, file);
      await userActions.allUserOrganizations({
        orgId,
        userIds: [userId],
      });
    },
    [userActions]
  );

  const { onApiCall: onUploadProfilePicture, loading: isLoading } = useApi<
    [{ orgId: number; file: File; userId: number }],
    void
  >({
    api: uploadProfilePicture,
  });

  const handleSaveClick = async () => {
    if (!chosenFile) return;

    await onUploadProfilePicture({
      file: chosenFile,
      orgId,
      userId: curUser.id,
    });
    onNext(orgId);
  };

  const handleSkipClick = () => {
    onNext(orgId);
  };

  return (
    <RegisterLayoutPage
      title="auth.addProfilePicture.title"
      text="auth.addProfilePicture.subtitle"
      isLoading={isLoading}
    >
      <Box mb={12}>
        <UploadPicture
          editable
          isLoading={isLoading}
          name={name}
          onFileChange={handleFileChange}
          onAddClick={handleAddClick}
        />
      </Box>
      <Flex direction="column" alignItems="center">
        <Button
          onClick={handleSaveClick}
          mb={4}
          disabled={!chosenFile}
          isLoading={isLoading}
          label="auth.addProfilePicture.buttons.save"
        />
        <Button
          onClick={handleSkipClick}
          variant={ButtonVariants.unstyled}
          label="auth.addProfilePicture.buttons.skip"
        />
      </Flex>
    </RegisterLayoutPage>
  );
}
