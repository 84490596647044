import { put } from 'redux-saga/effects';
import { ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { loadDeliveryMethodsAction } from 'src/redux/user/actions';
import { vendorsApi } from './api';

const name = 'vendors';

const convertLocalVendorToOwned = createUpdateSlice({
  storeName: name,
  api: vendorsApi.convertLocalVendorToOwned,
  actionName: 'convertLocalToOwned',
  sagas: {
    *[ON_SUCCESS](resolve: (value: void | PromiseLike<void>) => void, reject?: (error: Error) => void) {
      yield put(loadDeliveryMethodsAction(resolve, reject));
    },
  },
});

export default convertLocalVendorToOwned;
