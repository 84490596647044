import { MIRadioGroup, Option } from '../../components/form/MIRadioGroup';
import { ModelViewField } from './use-form';

export type InlineRadioFieldProps<T> = {
  model?: ModelViewField<T>;
  group?: string;
  value?: T;
  label?: string;
  id?: string;
  options: Option<T>[];
  required?: boolean;
  disabled?: boolean;
  onChange?: (arg0: { value: T }) => any;
};
export function InlineRadioField<T extends string>(props: InlineRadioFieldProps<T>) {
  const { model, label, onChange, options, ...rest } = props;
  const value = (props.value === undefined ? model?.value : props.value) as T;
  const realOnChange = props.onChange || model?.onChange;
  const id = props.id || (model?.id as string);

  return (
    <MIRadioGroup<T>
      group="group"
      {...rest}
      options={options}
      selected={value}
      onSelect={(value) => realOnChange && realOnChange({ value })}
      id={id}
    />
  );
}
