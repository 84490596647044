import {
  ComponentWithAs,
  forwardRef,
  IconButton as ChakraIconButton,
  IconButtonProps,
  useStyleConfig,
} from '@chakra-ui/react';
import * as React from 'react';
import { useCallback } from 'react';
import { analytics } from 'src/services/analytics';

type Props = Omit<IconButtonProps, 'variant' | 'size'> & {
  size?: 'sm' | 'md' | 'lg';
  variant?: 'action' | 'actionNaked';
  'aria-label': string;
  label?: string;
  testId?: string | null;
  analyticsProperties?: Record<string, any>;
};

const IconButton = forwardRef<Props, 'button'>(
  ({ size, variant, label, testId, analyticsProperties = {}, onClick, ...rest }, ref) => {
    const styles = useStyleConfig('IconButton', { size, variant });
    const dataTestId = testId || `button-${label}`;
    const onClickWithEvent = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        label && analytics.trackAction(label, analyticsProperties);

        return onClick && onClick(event);
      },
      [onClick, label]
    );

    return <ChakraIconButton sx={styles} onClick={onClickWithEvent} data-testid={dataTestId} ref={ref} {...rest} />;
  }
);

export default IconButton as ComponentWithAs<'button', Props>;
