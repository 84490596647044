import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { profileStore } from 'src/modules/profile/profile-store';
import { PayListFilters } from 'src/pages/pay/components/filters/types';
import { analytics } from 'src/services/analytics';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { PayTabs } from 'src/utils/consts';

function convertVendorIdAnalytic(activeFilters: PayListFilters) {
  if (activeFilters.vendorId) {
    return {
      vendorIdCount: activeFilters.vendorId.length,
    };
  }

  return {};
}

function sendAnalytic(currentTab: PayTabs, previousFilters: PayListFilters, activeFilters: PayListFilters) {
  if (!isEqual(previousFilters, activeFilters))
    analytics.trackAction('Filtered', {
      tabName: currentTab,
      previousFilters: {
        ...omit(previousFilters, 'vendorId'),
        ...convertVendorIdAnalytic(previousFilters),
      },
      chosenFilters: {
        ...omit(activeFilters, 'vendorId'),
        ...convertVendorIdAnalytic(activeFilters),
      },
    });
}

export const useFilterMenu = ({
  currentTab,
  activeFilters,
}: {
  currentTab: PayTabs;
  activeFilters: PayListFilters;
}) => {
  const currentUserRole = useSelector(profileStore.selectors.getUserRole);

  const [previousFilters, setPreviousFilters] = useState<PayListFilters>({});
  const onOpen = (activeFilters: PayListFilters) => {
    setPreviousFilters(activeFilters);
  };

  const onClose = (activeFilters: PayListFilters) => {
    sendAnalytic(currentTab, previousFilters, activeFilters);
    setPreviousFilters({});
  };

  return {
    activeFilters,
    currentTab,
    isAdminRole: isAdminRole(currentUserRole),
    onOpen,
    onClose,
  };
};

export type UseFilterMenu = ReturnType<typeof useFilterMenu>;
