import { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { billLocations } from 'src/pages/bill/locations';
import { generateSortQueryString, getSortMenuValue } from 'src/pay/components/SortMenu/SortMenuUtils';
import { SortMenuOnChange, SortMenuValue } from 'src/pay/components/SortMenu/types';
import { analytics } from 'src/services/analytics';
import { PayTabs } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import { sortOptions } from 'src/utils/payListConsts';
import { SortDirection, stringifyQs } from 'src/utils/query-utils';

export const useLocationBasedOptions = () => {
  const { status, sorting: sortingQueryString } = useQueryString();

  // TODO create and redirect to url with default PayTabs if value is not a PayTabs
  const tab = (status as PayTabs) || PayTabs.INBOX;
  const { selected, direction } = getSortMenuValue(tab, sortingQueryString);
  const options = sortOptions[tab];

  return {
    options,
    value: { selected, direction },
  };
};

export const useLocationOnChange = () => {
  const queryString = useQueryString<{ sorting: string; status: string }>();
  const history = useHistory();
  const orgId = useOrgId();
  const onChange: SortMenuOnChange = useCallback(
    (value) => {
      const chosenSorting = generateSortQueryString(value);
      const params = {
        ...queryString,
        sorting: chosenSorting,
      };

      history.push({
        pathname: generatePath(billLocations.index, { orgId }),
        search: stringifyQs(params),
      });
    },
    [queryString]
  );

  return onChange;
};

function sendSortMenuAnalytic({
  currentTab,
  previousSortMenuValue,
  sortMenuValue,
}: {
  currentTab: PayTabs;
  previousSortMenuValue: SortMenuValue;
  sortMenuValue: SortMenuValue;
}) {
  const { selected: previousSorting, direction: previousSortingDirection = SortDirection.DESC } = previousSortMenuValue;
  const { selected: chosenSorting, direction: chosenSortingDirection } = sortMenuValue;

  if (previousSorting !== chosenSorting || previousSortingDirection !== chosenSortingDirection)
    analytics.trackAction('Sorted', {
      tabName: currentTab,
      previousSorting,
      previousSortingDirection,
      chosenSorting,
      chosenSortingDirection,
    });
}

export const useSendAnalyticOnClose = () => {
  const [{ sorting: previousSortingQueryString }, setState] = useState<{ sorting: string }>({ sorting: '' });
  const queryString = useQueryString<{ sorting: string; status: PayTabs }>();

  const onOpenMenu = useCallback(() => {
    setState(queryString);
  }, [queryString]);

  const onCloseMenu = useCallback(() => {
    const { sorting: sortingQueryString, status } = queryString;
    const currentTab = status;

    const previousSortMenuValue = getSortMenuValue(currentTab, previousSortingQueryString);
    const sortMenuValue = getSortMenuValue(currentTab, sortingQueryString);

    sendSortMenuAnalytic({ currentTab, previousSortMenuValue, sortMenuValue });
  }, [queryString]);

  return { onOpenMenu, onCloseMenu };
};

const TOOLTIP_DELAY_AFTER_MENU_CLOSED = 20;
/** fix Chakra bug - tooltip opens when the menu closes */
export const useTooltipWithMenu = () => {
  const [allowTooltip, setAllowTooltip] = useState<boolean>(true);
  const tooltipDelayRef = useRef<number>();

  useEffect(
    () => () => {
      if (tooltipDelayRef) {
        clearTimeout(tooltipDelayRef.current);
        tooltipDelayRef.current = 0;
      }
    },
    []
  );
  const onCloseMenu = () => {
    // fix Chakra bug - tooltip opens when the menu closes
    tooltipDelayRef.current = setTimeout(() => {
      setAllowTooltip(true);
    }, TOOLTIP_DELAY_AFTER_MENU_CLOSED);
    setAllowTooltip(false);
  };

  return { allowTooltip, onCloseMenu };
};
