import isNil from 'lodash/isNil';
import { BillingDetailsModel } from 'src/pages/vendor/international-delivery-method/hooks/useBillingDetailsForm';

export const validateBillingDetails = (fields: Record<string, string>, countryCode: string | null) => (
  key: string,
  value: string,
  model: BillingDetailsModel
) => {
  if (isNil(value) && key !== 'amount') return `vendors.deliveryMethods.international.billing.validation.${key}`;

  if (key === 'iban' && countryCode && !value.startsWith(countryCode))
    return `vendors.deliveryMethods.international.billing.validation.invalid_iban`;

  if (key === 'accountNumber' && model.swift?.trim().toUpperCase() === model.accountNumber?.trim().toUpperCase()) {
    return `vendors.deliveryMethods.international.billing.validation.invalid_accountNumber`;
  }

  const regex = new RegExp(fields[key]);

  if (!regex.test(value)) {
    return `vendors.deliveryMethods.international.billing.validation.${key}`;
  }

  return undefined;
};
