import {
  Table as ChakraTable,
  TableCaption as Caption,
  TableProps,
  Tbody as Body,
  Td as Cell,
  Th as HeaderCell,
  Thead as Header,
  Tr as Row,
} from '@chakra-ui/react';
import * as React from 'react';
import { TableSizes } from './consts';

type Props = Omit<TableProps, 'size' | 'variant'> & {
  size?: 'sm' | 'md';
  variant?: 'simple' | 'unStyled';
  children?: React.ReactNode;
};

const Table = ({ size = TableSizes.sm, variant, children, ...rest }: Props) => (
  <ChakraTable size={size} variant={variant} {...rest}>
    {children}
  </ChakraTable>
);

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.HeaderCell = HeaderCell;
Table.Cell = Cell;
Table.Caption = Caption;

export default Table;
export { Body, Cell, Header, HeaderCell, Row };
