import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { billsApi } from './api';
import { name } from './consts';

export const batchHashFunc = (ids: string[]): string => ids.join(',');
export const setClassificationSlice = createApiCallSlice({
  name: generateSliceName(name, 'set_classifications'),
  api: billsApi.setClassifications,
  initialState: {
    setClassification: {},
    byId: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      const key = batchHashFunc(action.payload.billIds);
      state.setClassification[key] = {
        loading: true,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      const { billIds, classification } = action.meta.identifier;
      const key = batchHashFunc(billIds);
      delete state.setClassification[key];

      billIds.forEach((id) => {
        state.byId[id].classificationId = classification;
      });
    },
    [ON_FAILURE]: (state, action) => {
      const { billIds } = action.meta.identifier;
      const key = batchHashFunc(billIds);
      state.setClassification[key] = {
        loading: false,
        error: action.error,
      };
    },
  },
});
