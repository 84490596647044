import sortBy from 'lodash/sortBy';
import config from 'src/config';
import { CompanyType, OrganizationCreateOrigin } from './consts';

export const sortOrganizations = (organizations: any[]) =>
  sortBy(organizations, [
    (org) => (org.companyType === CompanyType.ACCOUNTING_FIRM ? 0 : 1),
    (org) => (org.companyName || '').toUpperCase(),
  ]);

export function getVendorHandleURL(handler: string) {
  return `${config.meliome.baseUrl}${handler}`;
}

export function isQbdtOrganization(createOrigin?: OrganizationCreateOrigin | null) {
  return (
    createOrigin &&
    {
      [OrganizationCreateOrigin.QBDT_INTEGRATION]: true,
      [OrganizationCreateOrigin.QBDT_MAC]: true,
      [OrganizationCreateOrigin.QBDT_WINDOWS]: true,
    }[createOrigin]
  );
}
