import Box from 'src/core/ds/box';

type Props = {
  isScheduledDate: boolean;
  circleDiameter: number | string;
};

export const DatePickerCircle = ({ isScheduledDate, circleDiameter }: Props) => (
  <Box
    boxSizing="border-box"
    h={circleDiameter}
    w={circleDiameter}
    border="2px"
    borderColor={isScheduledDate ? 'primary.500' : 'grey.600'}
    bgColor={isScheduledDate ? 'primary.500' : 'initial'}
    borderRadius="full"
    mr={2}
  />
);
