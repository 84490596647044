import { useIntl } from 'react-intl';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormControl, FormControlProps, FormLabel } from 'src/core/ds/form';
import { MaskInput, MaskInputProps } from 'src/core/ds/input';
import { ModelViewField } from 'src/ui/form';
import { ErrorMessage, HelperText, OptionalLabel } from './components';

export type Props = Omit<FormControlProps, 'onChange'> &
  Omit<MaskInputProps, 'onChange'> & {
    model?: ModelViewField<string>;
    onChange?: (args: {
      value: string;
      floatValue: number | undefined;
      formattedValue: string;
      event: React.SyntheticEvent<HTMLInputElement>;
    }) => void;
    size?: 'sm' | 'md';
    label?: string;
    prefix?: string;
    labelValues?: Record<string, unknown>;
    helperText?: string;
    helperTextValues?: Record<string, unknown>;
    errorMessage?: string | null;
    errorMessageValues?: Record<string, unknown>;
    isModelOnChangeFormatted?: boolean;
  };

export const MaskField = ({
  id,
  testId,
  model,
  isRequired = false,
  isReadOnly,
  isDisabled,
  size = 'md',
  helperText,
  helperTextValues,
  label,
  prefix = '',
  labelValues,
  errorMessage,
  errorMessageValues,
  onChange,
  value,
  isViewOnly,
  isModelOnChangeFormatted,
  mb,
  ...rest
}: Props) => {
  const intl = useIntl();
  const isError = Boolean(errorMessage) || Boolean(model?.error);
  const inputValue = typeof value === 'string' ? value : model?.value;
  const inputId = id || model?.id;
  const noticesTestId = `input-${inputId}-notices`;
  const errorMessageText = errorMessage || model?.error;
  const formattedPrefix = !prefix ? '' : intl.formatMessage({ id: prefix });

  const handleChange = ({
    value,
    formattedValue,
    floatValue,
    event,
  }: {
    value: string;
    formattedValue: string;
    floatValue: number | undefined;
    event: React.SyntheticEvent<HTMLInputElement>;
  }) => {
    onChange
      ? onChange({ value, formattedValue, floatValue, event })
      : model?.onChange({ value: isModelOnChangeFormatted ? formattedValue : value });
  };

  return (
    <FormControl
      isInvalid={isError}
      size={size}
      isRequired={isRequired}
      isReadOnly={isReadOnly}
      isDisabled={isDisabled}
      mb={mb}
    >
      <FormLabel htmlFor={inputId} hidden={isViewOnly}>
        <MIFormattedText label={label} values={labelValues} />
        {!isRequired && <OptionalLabel isError={isError} />}
      </FormLabel>
      <MaskInput
        id={inputId}
        testId={testId}
        onChange={handleChange}
        value={inputValue}
        isViewOnly={isViewOnly}
        size={size}
        prefix={formattedPrefix}
        {...rest}
      />
      {!isError ? (
        <HelperText testId={noticesTestId} label={helperText} labelValues={helperTextValues} />
      ) : (
        errorMessageText?.trim() && (
          <ErrorMessage size={size} testId={noticesTestId} label={errorMessageText} labelValues={errorMessageValues} />
        )
      )}
    </FormControl>
  );
};
