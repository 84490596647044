import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Option } from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';

type Props = {
  option: Option;
};

export const AddVendorOptionLabel = ({ option }: Props) => (
  <Flex data-testid="bills.new.vendorAddInSelect" pl={4} h="5.6rem" w="full" align="center">
    <Box textStyle="body3" verticalAlign="textBottom" as="i" className="icon-plus-icon" />
    <Box pl={3}>
      <MIFormattedText label="bills.new.vendorAddInSelect" values={{ value: option.label }} />
    </Box>
  </Flex>
);
