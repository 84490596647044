import { getValidationErrors } from '@melio/sizzers-js-common';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getContactActions, isBatchUnilateralEligilibleVendor } from 'src/pages/contacts/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { ContactsTab } from 'src/utils/consts';
import { isNonNullable } from 'src/utils/isNonNullable';
import { VendorType } from 'src/utils/types';

export type EmailValidationType = Partial<{
  id: number;
  contactEmail: string;
}>;

export const useBatchChangeVendor = (
  selectedIds: readonly number[],
  sendBatchUnilateralRequest: (vendors: readonly VendorType<'created'>[]) => void,
  analyticsProps: Record<string, any>
): {
  changedVendors: readonly VendorType<'created'>[];
  validationErrors: readonly EmailValidationType[];
  onEmailChange: (changeField: { id: number; value: string }) => void;
  sendBatchUnilateralRequestWithEmailsValidation: () => void;
} => {
  const orgId = useSelector(getOrgId);
  const selectedVendors = useSelector<any, VendorType[]>(vendorsStore.selectors.getByIds(selectedIds));
  const eligibleVendors = selectedVendors
    .filter((vendor): vendor is VendorType<'created'> => isNonNullable(vendor.id))
    .filter((vendor) => isBatchUnilateralEligilibleVendor(vendor.isEditable, vendor.deliveryMethods));
  const eligibleVendorsWithoutEmail = eligibleVendors.filter((vendor) => isEmpty(vendor.contactEmail));
  const [changedVendors, setChangedVendors] = useState(eligibleVendorsWithoutEmail);
  const [validationErrors, setValidationErrors] = useState<readonly EmailValidationType[]>([]);
  const dispatch = useDispatch();
  const contactActions = getContactActions(ContactsTab.VENDORS)(dispatch);

  const onEditVendor = (editedVendor: VendorType) => contactActions.update({ orgId, ...editedVendor });

  const getUpdatedVendorsList = (
    vendors: readonly VendorType<'created'>[],
    changedVendors: readonly VendorType<'created'>[]
  ) => vendors.map((v) => changedVendors.find((vendor) => vendor.id === v.id) || v);

  const sendBatchUnilateralRequestWithEmailsValidation = async () => {
    const validationErrors: EmailValidationType[] = [];
    const changedVendorsWithoutEmail = changedVendors.filter((vendor) => isEmpty(vendor.contactEmail));

    if (changedVendorsWithoutEmail.length) {
      analytics.track('contacts', 'batch-unilateral-error-empty-email', analyticsProps);
      changedVendorsWithoutEmail.forEach((v) =>
        validationErrors.push({
          id: v?.id,
          contactEmail: 'inputErrors.vendor.contactEmail.any.empty',
        })
      );
      setValidationErrors(validationErrors);
    } else {
      changedVendors.forEach((v) => {
        validationErrors.push({
          id: v?.id,
          contactEmail: getValidationErrors('vendor', { contactEmail: v.contactEmail }, ['contactEmail']).contactEmail,
        });
      });
      setValidationErrors(validationErrors);

      const isEmptyValidationErrors = validationErrors.every((e) => isEmpty(e.contactEmail));

      if (isEmptyValidationErrors) {
        changedVendors.forEach((v) => {
          onEditVendor(v);
        });
        const finalVendorsList = getUpdatedVendorsList(eligibleVendors, changedVendors);
        sendBatchUnilateralRequest(finalVendorsList);
      } else {
        analytics.track('contacts', 'batch-unilateral-error-invalid-email', analyticsProps);
      }
    }
  };

  useEffect(() => {
    const finalVendorsList = getUpdatedVendorsList(eligibleVendorsWithoutEmail, changedVendors);
    setChangedVendors(finalVendorsList);
  }, [selectedIds]);

  const onEmailChange = ({ id, value }: { id: number; value: string }) => {
    const changed = changedVendors.map((v) => (v.id === id ? { ...v, contactEmail: value } : v));
    setChangedVendors(changed);
  };

  return {
    changedVendors,
    validationErrors,
    onEmailChange,
    sendBatchUnilateralRequestWithEmailsValidation,
  };
};
