import styled from 'styled-components';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  EditElement,
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { useDisclosure } from 'src/core/ds/hooks';
import { Skeleton } from 'src/core/ds/skeleton';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { InstallmentsList } from 'src/pages/bill/components/InstallmentsList/InstallmentsList';
import { PaymentFinancing } from 'src/utils/types';

type Props = {
  financing: PaymentFinancing | null | undefined;
  onEdit?: () => void;
  isLoading?: boolean;
  isEditDisabled: boolean;
  canEdit?: boolean;
};

export const InstallmentDeductionDateInfo = ({
  financing,
  onEdit,
  isLoading = false,
  isEditDisabled = false,
  canEdit = false,
}: Props) => {
  const { isOpen: isCollapsed, onToggle } = useDisclosure();
  const { theme } = useSiteContext();
  const deductionDate = financing?.installments?.[0]?.scheduledDate;

  return (
    <PaymentActivityContainer>
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon>
            <ScheduledIcon />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodText>
              <MIFormattedText label="financing.firstInstallmentDeductsLabel" />
            </PaymentMethodText>
            {!financing || isLoading ? (
              <Flex alignItems="center" h={theme.text.lineHeight.regular}>
                <Skeleton variant="medium" w="16rem" />
              </Flex>
            ) : (
              <PaymentInfoText data-testId="financing-deduction-date">
                <MIFormattedDate date={deductionDate} />
              </PaymentInfoText>
            )}
          </PaymentTextContainer>
        </PaymentInfoContainer>
        {canEdit && <EditElement testId="edit-deduction-date" onEdit={onEdit} isDisabled={isEditDisabled} />}
      </PaymentActivityMainContainer>
      <Flex marginTop={1} paddingRight={5} w="full">
        <PaymentIcon />
        {!financing || isLoading ? (
          <Flex alignItems="center" h={theme.text.lineHeight.hint}>
            <Skeleton variant="light" w="12rem" />
          </Flex>
        ) : (
          <Box marginRight={5} w="full">
            <InstallmentsList financing={financing} isCollapsed={isCollapsed} onToggle={onToggle} showBadges />
          </Box>
        )}
      </Flex>
    </PaymentActivityContainer>
  );
};

const ScheduledIcon = styled.i.attrs({ className: 'icon-scheduled-icon' })``;
