import { ReactElement } from 'react';
import styled from 'styled-components';
import { MILink } from 'src/components/common/MILink';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FONT_WEIGHT } from 'src/core/theme/foundations/textStyles';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  address: string | ReactElement[];
  icon?: ReactElement;
  vendorName?: string;
  onEditAddress?: () => void;
};

export const AmexVendorAddressOption = ({ vendorName, icon, address, onEditAddress }: Props) => (
  <Flex className={FULL_STORY_MASK_RULE_CLASS} gap={1} direction="column" mt={-0.5}>
    {vendorName && (
      <Flex gap={1} fontWeight={FONT_WEIGHT.SEMI} textStyle="body2" alignItems="center" color="black">
        {vendorName}
        {icon}
      </Flex>
    )}
    <Box textStyle="body4">
      {address}
      {onEditAddress && (
        <Box as="span" ms={1}>
          <EditLink
            testId="edit-address-link"
            plain
            to={onEditAddress}
            label="bills.pay.fundingSource.amexVendorAddressVerificationConfirmModal.edit"
          />
        </Box>
      )}
    </Box>
  </Flex>
);

const EditLink = styled(MILink)`
  font-size: 1.2rem;
`;
