import { useLocation } from 'react-router-dom';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { parseQueryString, stringifyQs } from 'src/utils/query-utils';
import { Filters } from 'src/utils/types';

export const useListNavigator = () => {
  const location = useLocation();
  const { navigate } = useNavigator();
  const query = parseQueryString(location.search);
  const { id, ...filters } = query;
  const basePath = location.pathname;

  const setSelected = (id) => {
    const search = stringifyQs({ ...query, id });
    navigate(`${location.pathname}?${search}`);
  };

  const setFilters = (filters: Filters) => {
    const current = { id, ...filters };
    const path = basePath || location.pathname;
    navigate(`${path}?${stringifyQs(current)}`, true);
  };

  return {
    navigate,
    locationState: location.state,
    basePath,
    query,
    id,
    filters,
    setSelected,
    setFilters,
  };
};
