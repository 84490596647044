/* eslint-disable no-template-curly-in-string,max-len,no-unused-vars */
import { UPDATE_USER_PREFERENCE } from 'src/redux/user/actionTypes';
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

const PAGE_EVENT = 'onboarding-company-info';

const MAPPING: EventMappingsTree = {
  '/orgs/*/welcome/business/industry': {
    'page.view': [PAGE_EVENT, 'industry'],
    'option-changed-onboardingIndustryId': [
      'onboarding-company-info-industry',
      'select-industry',
      { onboardingIndustryId: 'option.value' },
    ],
    'option-changed-onboardingSubIndustryId': [
      'onboarding-company-info-industry',
      'select-sub-industry',
      { onboardingSubIndustryId: 'option.value' },
    ],
    'go.back': ['onboarding-company-info-industry', 'back'],
    'progress.skip': ['onboarding-company-info-industry', 'skip'],
    ...mapApiSlice(
      '[ORGANIZATION] UPDATE_ORGANIZATION_PREFERENCE',
      'onboarding-company-info-industry',
      'update-onboarding-industry-id'
    ),
    'progress.continue': ['onboarding-company-info-industry', 'submit'],
    'form-validation-error': [
      'onboarding-company-info-industry',
      'validation-error',
      { validationsErrors: 'validationErrors' },
    ],
  },
  '/orgs/*/welcome/business': {
    'page.view': [PAGE_EVENT, 'welcome'],
  },
  '/orgs/*/welcome/business/contacts': {
    'page.view': [PAGE_EVENT, 'contacts'],
    'intent-feature-should-display': [
      'onboarding-company-info',
      'intent-feature-should-display',
      {
        isUnifiedOnboardingOpen: 'isUnifiedOnboardingOpen',
        isAccountingFirm: 'isAccountingFirm',
        isComingFromPaymentRequest: 'isComingFromPaymentRequest',
        isAccountantAddCompanyFlow: 'isAccountantAddCompanyFlow',
        shouldShowIntentPage: 'shouldShowIntentPage',
      },
    ],
  },
  '/orgs/*/welcome/business/quickbooks-connected': {
    'page.view': [PAGE_EVENT, 'add-company-quickbooks-connected'],
    'redirect-to-onboarding-after-qb': [
      'add-company-quickbooks-connected',
      'redirect-to-onboarding-after-qb',
      { missedFields: 'missedFields' },
    ],
  },
  '/orgs/*/welcome/business/name': {
    'page.view': [PAGE_EVENT, 'companyName'],
  },
  '/orgs/*/welcome/business/address': {
    'page.view': [PAGE_EVENT, 'address'],
  },
  '/orgs/*/welcome/business/address-manual': {
    'page.view': [PAGE_EVENT, 'manual-address'],
  },
  '/orgs/*/welcome/business/legal-info': {
    'page.view': [PAGE_EVENT, 'legal-info'],
    'progress.skip': [PAGE_EVENT, 'legal-info-skip'],
    'go.back': [PAGE_EVENT, 'legal-info-back'],
    'progress.continue': [PAGE_EVENT, 'legal-info-submit'],
  },
  '/orgs/*/welcome/business/type': {
    'page.view': [PAGE_EVENT, 'type'],
    'select-category': ['onboarding-company-info-type', 'select-type-${value}'],
    ...mapApiSlice(UPDATE_USER_PREFERENCE, 'onboarding-company-info-type', 'update-allow-companies-switcher'),
    ...mapApiSlice(
      '[ORGANIZATION] UPDATE_ORGANIZATION_PREFERENCE',
      'onboarding-company-info-type',
      'update-onboarding-industry-id'
    ),
    'progress.continue': ['onboarding-company-info-type', 'submit'],
  },
  '/orgs/*/welcome/business/combined': {
    'page.view': [PAGE_EVENT, 'combined'],
  },
  '/orgs/*/welcome/business/monthly-payments-volume': {
    'page.view': [PAGE_EVENT, 'monthly-payments-volume'],
    'progress.skip': ['onboarding-company-info-monthly-payments-volume', 'skip'],
    'go.back': ['onboarding-company-info-monthly-payments-volume', 'back'],
    'select-category': ['onboarding-company-info-monthly-payments-volume', 'select-volume', { volume: 'value' }],
    ...mapApiSlice(
      '[ORGANIZATION] UPDATE_ORGANIZATION_PREFERENCE',
      'onboarding-company-info-monthly-payments-volume',
      'update-onboarding-tpv'
    ),
    'progress.continue': ['onboarding-company-info-monthly-payments-volume', 'submit'],
  },
  '/orgs/*/welcome/business/clients-amount': {
    'page.view': ['onboarding-company-info', 'clients-amount'],
    'progress.skip': ['onboarding-company-info-clients-amount', 'skip'],
    'go.back': ['onboarding-company-info-clients-amount', 'back'],
    'select-category': ['onboarding-company-info-clients-amount', 'select-amount', { amount: 'value' }],
    ...mapApiSlice(
      '[ORGANIZATION] UPDATE_ORGANIZATION_PREFERENCE',
      'onboarding-company-info-monthly-payments-volume',
      'update-onboarding-bookkeeper-num-of-companies'
    ),
    'progress.continue': ['onboarding-company-info-clients-amount', 'submit'],
  },
  '/orgs/*/welcome/business/intent': {
    'page.view': ['onboarding-company-info', 'intent'],
    'select-category': ['onboarding-company-info-intent', 'select-type-${value}'],
    'progress.continue': ['onboarding-company-info-intent', 'submit'],
    'intent-page-user-selected-intent': [
      'onboarding-company-info',
      'intent-page-user-selected-intent',
      {
        intent: 'intent',
        isUnifiedOnboardingOpen: 'isUnifiedOnboardingOpen',
      },
    ],
    'intent-page-user-skipped': [
      'onboarding-company-info',
      'intent-page-user-skipped',
      {
        isUnifiedOnboardingOpen: 'isUnifiedOnboardingOpen',
      },
    ],
  },
};

export default MAPPING;
