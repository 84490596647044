import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { IllustrationName } from 'src/core/ds/illustration';

export const VendorCandidatesInvalidPage = () => (
  <Box data-testid="vendors-candidates-invalid-page" h="full">
    <SuccessLayoutPage
      illustration={IllustrationName.moonPurple}
      title="vendors.candidates.invalid.title"
      text="vendors.candidates.invalid.details"
    />
  </Box>
);
