import * as React from 'react';
import { useRef, useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { UnorderedList } from 'src/core/ds/list';
import useRoveFocusUsingKeyword from 'src/hooks/useRoveFocusUsingKeyword';
import { analytics } from 'src/services/analytics';
import { isEscPressed } from 'src/utils/events';
import { AccountingFirmContainer } from './common/AccountingFirmContainer';
import { Label } from './common/Label';
import { LabelNoMargin } from './common/LabelNoMargin';
import { TopPartContainer } from './common/TopPartContainer';
import { ExtraDropdownOptionType, MIExtraDropdownItem } from './MIExtraDropdownItem';
import { MIExtraDropdownItemWithFocus } from './MIExtraDropdownItemWithFocus';
import { baseContainerStyles } from './MIExtraDropdownStyles';
import {
  CurrentItemContainerProps,
  CurrentItemProps,
  ListContainerProps,
  ListProps,
  ListWrapperProps,
  SwitcherContainerProps,
} from './types';

type Props = {
  accountingFirm?: ExtraDropdownOptionType;
  emptyState: boolean;
  valuesList: Array<ExtraDropdownOptionType>;
  onChange: (value: ExtraDropdownOptionType) => void;
  value: ExtraDropdownOptionType;
  onBack?: () => void;
  buttonAction?: () => void;
  buttonLabel?: string;
  canSwitchCompanies: boolean;
};

export const MIExtraDropdown = ({
  accountingFirm,
  valuesList,
  value,
  onChange,
  onBack,
  buttonAction,
  buttonLabel,
  emptyState,
  canSwitchCompanies,
}: Props) => {
  const [isOpen, toggle] = useState(!!onBack);
  const ref = useRef<HTMLDivElement>(null);
  const [focus, setFocus] = useRoveFocusUsingKeyword(
    ref,
    accountingFirm ? [accountingFirm, ...valuesList] : valuesList,
    (item) => item.label
  );

  const toggleIsOpen = (value) => {
    analytics.trackAction('companies-switcher', {
      isOpen: value,
      location: onBack ? 'bottom' : 'top',
    });
    toggle(value);

    if (!value) {
      setFocus(-1);
    }
  };

  const onClick = (item) => {
    analytics.trackAction(`companies-switcher-${onBack ? 'bottom' : 'top'}`, {
      toOrgId: item.id,
    });
    onChange(item);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (isEscPressed(e) && isOpen) toggleIsOpen(false);
  };

  const onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    // Prevents firing the blur event if any one of its children receives focus
    if (!e.currentTarget.contains(e.relatedTarget as Element) && isOpen) toggleIsOpen(false);
  };

  return (
    <SwitcherContainer
      ref={ref}
      tabIndex={0}
      onClick={() => toggleIsOpen(!isOpen)}
      hasBackButton={!!onBack}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    >
      {!onBack && (
        <CurrentItemContainer $viewOnly={!canSwitchCompanies} data-testid="organization-switcher-button">
          <CurrentItem $viewOnly={!canSwitchCompanies}>
            <MIExtraDropdownItem item={value} selected currentItem />
            {canSwitchCompanies && (
              <Flex ml={2} transform={isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}>
                <Icon name={IconNames.caretDown} />
              </Flex>
            )}
          </CurrentItem>
        </CurrentItemContainer>
      )}
      {canSwitchCompanies && isOpen && (
        <ListWrapper
          hasBackButton={!!onBack}
          hasFooter={buttonAction && buttonLabel}
          data-testid="organization-switcher-content"
        >
          {onBack && (
            <Flex
              onClick={onBack}
              h="4.2rem"
              py={2}
              px={3}
              boxSizing="border-box"
              borderBottom="1px"
              borderColor="grey.400"
              mb={2}
              justify="flex-start"
              align="center"
              textStyle="caption1Semi"
              cursor="pointer"
              _hover={{ bgColor: '#f6f6f6', borderTopRadius: 'lg' }}
            >
              <Flex ml={1} mr={3}>
                <Icon name={IconNames.shevronLeft} size={IconSize.lg} />
              </Flex>
              <MIFormattedText label="general.back" />
            </Flex>
          )}
          {!!accountingFirm && (
            <>
              <AccountingFirmContainer emptyState={emptyState}>
                <Label>
                  <MIFormattedText label="organizationSwitcher.accountingFirm" />
                </Label>
                <MIExtraDropdownItemWithFocus
                  item={accountingFirm}
                  isSelected={value.id === accountingFirm.id}
                  isFocused={focus === 0}
                  onClick={() => {
                    onClick(accountingFirm);
                  }}
                />
              </AccountingFirmContainer>
              {!emptyState && (
                <Label>
                  <MIFormattedText label="organizationSwitcher.firmsClients" />
                </Label>
              )}
            </>
          )}
          {!accountingFirm && (
            <TopPartContainer>
              <LabelNoMargin>
                <MIFormattedText label="nav.companies" />
              </LabelNoMargin>
            </TopPartContainer>
          )}
          <ListContainer hasBackButton={!!onBack} emptyState={!accountingFirm && emptyState}>
            <List>
              {valuesList.map((item: ExtraDropdownOptionType, index: number) => {
                const isSelected = value.id === item.id;

                return (
                  <MIExtraDropdownItemWithFocus
                    item={item}
                    key={item.id}
                    isSelected={isSelected}
                    isFocused={accountingFirm ? focus === index + 1 : focus === index}
                    onClick={() => {
                      if (!isSelected) {
                        onClick(item);
                      }
                    }}
                  />
                );
              })}
            </List>
          </ListContainer>
          {buttonAction && buttonLabel && (
            <Flex py={3} px={5} zIndex={1} borderTop="1px" borderColor="grey.400">
              <Button
                variant={emptyState ? ButtonVariants.primary : ButtonVariants.tertiary}
                onClick={buttonAction}
                isFullWidth
                size={ButtonSizes.sm}
                label={buttonLabel}
                borderRadius="md"
                fontSize="0.8rem"
                fontWeight="600"
                lineHeight="1.2rem"
              />
            </Flex>
          )}
        </ListWrapper>
      )}
    </SwitcherContainer>
  );
};

const SwitcherContainer = React.forwardRef(({ children, ...rest }: SwitcherContainerProps, ref) => (
  <Box outline="none" {...rest} ref={ref as React.RefObject<HTMLDivElement>}>
    {children}
  </Box>
));

const ListWrapper = ({ hasBackButton, hasFooter, children, ...rest }: ListWrapperProps) => (
  <Flex
    {...rest}
    pos="absolute"
    bottom={hasBackButton ? '0.4rem' : 'unset'}
    zIndex={1}
    flexDir="column"
    maxH="33rem"
    w="31.5rem"
    bgColor="white"
    border="1px"
    borderColor="grey.400"
    borderRadius="lg"
    boxShadow={400}
    ml={2}
    pt={hasBackButton ? 0 : 2}
    pb={hasFooter || hasBackButton ? 0 : 2}
  >
    {children}
  </Flex>
);

const ListContainer = ({ hasBackButton, emptyState, children }: ListContainerProps) => (
  <Box sx={ListContainerStyle(hasBackButton, emptyState)}>{children}</Box>
);

const ListContainerStyle = (hasBackButton?: boolean, emptyState?: boolean): CSSObject =>
  hasBackButton
    ? {
        bgColor: 'white',
        bgRepeat: 'no-repeat',
        bgImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(246, 246, 246, 1) 0%, rgba(0, 0, 0, 0) 10px)',
        _before: {
          content: '',
          pos: 'relative',
          zIndex: -1,
          display: 'block',
          h: '3rem',
          mt: 0,
          mx: 0,
          mb: -8,
          bg: 'linear-gradient(to bottom, #fff, #fff, #fff)',
          overflow: 'auto',
          borderBottomRadius: 'lg',
        },
      }
    : {
        zIndex: 1,
        overflow: 'auto',
        borderBottomRadius: 'lg',
        mb: emptyState ? 2 : 0,
      };

const List = ({ children }: ListProps) => (
  <UnorderedList p={0} m={0}>
    {children}
  </UnorderedList>
);

const CurrentItemContainer = ({ $viewOnly, children, ...rest }: CurrentItemContainerProps) => (
  <Box
    {...rest}
    __css={baseContainerStyles}
    py={1}
    px={2}
    bgColor="white"
    h="4rem"
    boxSizing="border-box"
    cursor={$viewOnly ? 'default' : 'pointer'}
  >
    {children}
  </Box>
);

const CurrentItem = ({ $viewOnly, children }: CurrentItemProps) => (
  <Box
    __css={baseContainerStyles}
    p={1}
    cursor={$viewOnly ? 'default' : undefined}
    _hover={{
      bgColor: !$viewOnly ? 'grey.200' : undefined,
      borderRadius: !$viewOnly ? 'md' : undefined,
    }}
  >
    {children}
  </Box>
);
