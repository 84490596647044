import isPlainObject from 'lodash/isPlainObject';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { analytics } from 'src/services/analytics';

export type UseModalOptions = {
  onDismiss?: (result?: any) => any;
  modalName?: string;
  children?: React.ReactNode;
};

/**
 * types passed from useModal to the Component - don't use this on your props interface, but on the React.FC itself.
 */
export type UseModal<T extends Record<string, unknown>> = {
  dismiss: (result?: any) => any;
  modalName?: string;
} & T;

export type ModalShowCallback = (params?: { [key: string]: any }) => void;
export type UseModalReturn = [React.ReactNode | null | false, ModalShowCallback, boolean];

export function useModal<Props extends Record<string, unknown>>(
  Component,
  options: UseModalOptions & Exclude<Props, UseModal<Props>>
): UseModalReturn {
  const [state, setState] = useState({ showing: false, params: {} });

  const actionName = options.modalName || Component.displayName || '';
  const { onDismiss, children, ...props } = options;

  const showDialog = useCallback(
    (params = {}) => {
      analytics.trackAction(actionName, { toggle: 'open' });
      setState({
        showing: true,
        params: isPlainObject(params) ? params : {},
      });
    },
    [actionName]
  );

  const dismiss = useCallback(
    (result) => {
      analytics.trackAction(actionName, { toggle: 'close' });
      setState({
        showing: false,
        params: {},
      });

      onDismiss && onDismiss(result);
    },
    [actionName, onDismiss]
  );

  const DialogComponent = state.showing && (
    <>
      <Component {...props} {...state.params} dismiss={dismiss}>
        {options.children}
      </Component>
    </>
  );

  return [DialogComponent, showDialog, state.showing];
}
