import { locale } from 'src/locale/en-US';

const getNestedObject = (nestedObj: typeof locale['en-US'], pathArr: string[]) =>
  (pathArr.reduce(<T>(obj: T, key: string) => {
    if (obj) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value = obj[key];

      return value !== 'undefined' ? value : undefined;
    }

    return undefined;
  }, nestedObj) as unknown) as string;

export const getTranslationValue = (key: string) => getNestedObject(locale['en-US'], key.split('.'));
