import Box from 'src/core/ds/box';
import { modalHeaderTitleStyle } from './style';

type Props = {
  children: React.ReactNode;
};

export const ModalHeaderTitle = ({ children, ...rest }: Props) => (
  <Box __css={modalHeaderTitleStyle} {...rest}>
    {children}
  </Box>
);
