import { SyncStatus } from 'src/utils/consts';

export const name = 'accountingPlatforms';

export const CONNECTED_STATUSES = [SyncStatus.SYNCED, SyncStatus.STARTED, SyncStatus.FAILURE];

export const enum SyncNowEventsOrigin {
  TOP_BAR = 'top-bar',
  SETTINGS_PAGE = 'settings-page',
}
