export const getTitleAndSubtitle = (
  isPaymentVirtualCardCleared: boolean,
  isPaymentCompleted: boolean,
  isVirtualCardCanceled: boolean
): { title: string; subtitle: string } => {
  if (isVirtualCardCanceled) {
    return {
      title: 'vendors.deliveryMethods.shiftSuvcToAch.canceled.title',
      subtitle: 'vendors.deliveryMethods.shiftSuvcToAch.canceled.subtitle',
    };
  }

  if (isPaymentVirtualCardCleared && !isPaymentCompleted) {
    return {
      title: 'vendors.deliveryMethods.shiftSuvcToAch.cleared.title',
      subtitle: 'vendors.deliveryMethods.shiftSuvcToAch.cleared.subtitle',
    };
  }

  if (isPaymentCompleted) {
    return {
      title: 'vendors.deliveryMethods.shiftSuvcToAch.deposited.title',
      subtitle: 'vendors.deliveryMethods.shiftSuvcToAch.deposited.subtitle',
    };
  }

  return {
    title: 'vendors.deliveryMethods.shiftSuvcToAch.requested.title',
    subtitle: 'vendors.deliveryMethods.shiftSuvcToAch.requested.subtitle',
  };
};
