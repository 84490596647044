import { useLocation } from 'react-router-dom';
import { useGetDeliveryMethod } from 'src/hooks';
import { getIsDeliveryMethodVerifiedByVerificationMethod } from 'src/utils/delivery-methods';
import { DeliveryMethodType } from 'src/utils/types';
import { useIsOrganizationInMsnOnly } from './useIsOrganizationInMsnOnly';

const CLAIM_ACCOUNT_PATH_REGEX = '\\/*/msn-portal/claim-account*';
const MSN_PORTAL_DM_STATUS_PATH_REGEX = '\\/*/msn-portal/business/delivery-method-status';

const getClaimAccountAchSelectPagePathRegEx = (deliveryMethodId: number) =>
  `\\/*/receiving-methods/ach/select/${deliveryMethodId}/*`;

const getClaimAccountPlaidPagePathRegEx = (deliveryMethodId: number) =>
  `\\/*/receiving-methods/ach/plaid/${deliveryMethodId}/*`;

const getIsClaimAccountPath = ({
  deliveryMethod,
  pathname,
}: {
  deliveryMethod: DeliveryMethodType;
  pathname: string;
}) => {
  const verifyDeliveryMethodPaths = deliveryMethod?.id
    ? [getClaimAccountAchSelectPagePathRegEx(deliveryMethod.id), getClaimAccountPlaidPagePathRegEx(deliveryMethod.id)]
    : [];

  return [CLAIM_ACCOUNT_PATH_REGEX, MSN_PORTAL_DM_STATUS_PATH_REGEX, ...verifyDeliveryMethodPaths].some((regex) =>
    new RegExp(regex).test(pathname)
  );
};
export const useIsMsnOrganizationAccessBlocked = () => {
  const { pathname } = useLocation();
  const isMsnOnlyOrganization = useIsOrganizationInMsnOnly();
  const [deliveryMethod] = useGetDeliveryMethod();
  const isDeliveryMethodVerified = getIsDeliveryMethodVerifiedByVerificationMethod(deliveryMethod);
  const isClaimAccountPath = getIsClaimAccountPath({ deliveryMethod, pathname });

  return !!deliveryMethod && isMsnOnlyOrganization && !isDeliveryMethodVerified && !isClaimAccountPath;
};
