import React from 'react';
import { BannerCard } from 'src/components/braze/BannerCard';
import { BannerInboxCard } from 'src/components/braze/BannerInboxCard';
import { ContentCard } from 'src/components/braze/ContentCard';
import { TopBannerCard } from 'src/components/braze/TopBannerCard';
import { CardComponentProps, CustomFeedProps } from 'src/components/braze/types';
import { useContentCards } from 'src/components/braze/useContentCards';
import { CSSObject } from 'src/core/ds';
import { CardComponentType } from 'src/services/engagement/types';
import { getLatestUpdatedCardsFilter } from 'src/services/engagement/utils';

const sxForCardDefault: CSSObject = {
  mb: 4,
};

function getCardComponent(type?: CardComponentType): React.FC<CardComponentProps> {
  switch (type) {
    case CardComponentType.Tooltip:
      return ContentCard;
    case CardComponentType.Banner:
      return BannerCard;
    case CardComponentType.BannerInbox:
      return BannerInboxCard;
    case CardComponentType.TopBanner:
      return TopBannerCard;
    default:
      return ContentCard;
  }
}

export const CustomFeed = ({ feedType, maxCards = 1, sxForCard = sxForCardDefault }: CustomFeedProps) => {
  const feedTypeFilter = getLatestUpdatedCardsFilter(feedType, maxCards);

  const { cards, logClick, handleCloseAndDismiss, logCardImpressions } = useContentCards(feedTypeFilter);

  return (
    <>
      {cards.map((card) => {
        const Component = getCardComponent(card.extras?.component);

        logCardImpressions(card);

        return (
          <Component
            key={card.id}
            card={card}
            onClick={logClick}
            onClose={handleCloseAndDismiss}
            sxForCard={sxForCard}
          />
        );
      })}
    </>
  );
};
