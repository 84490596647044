const baseStyle = {
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: { bg: 'transparent', color: 'black' },
  bg: 'transparent',
  border: 'none',
  color: 'grey.600',
  cursor: 'pointer',
};

const sizes = {
  lg: {
    w: 6,
    h: 6,
    apply: 'textStyles.body2',
  },
  md: {
    w: 5,
    h: 5,
    apply: 'textStyles.body3',
  },
  sm: {
    w: 3,
    h: 3,
    apply: 'textStyles.body4',
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
