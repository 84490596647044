import { addDays } from 'date-fns';
import { useIntl } from 'react-intl';
import { MICard } from 'src/components/common/MICard';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { Separator } from 'src/core/ds/separator';
import { PaymentApprovalLogs } from 'src/pages/bill/components/BillsGallery/PaymentApprovalLogs';
import { BillStatus } from 'src/pages/bill/components/BillStatus';
import { InstallmentsDeductionDatesInfo } from 'src/pages/bill/components/ViewBillPaymentActivity/components/InstallmentsDeductionDatesInfo';
import {
  BlockTitleContainer,
  CardThemes,
} from 'src/pages/bill/components/ViewBillPaymentActivity/ViewBillPaymentActivity';
import { RepaymentCreditKey } from 'src/pages/bill/pay/components/PayBillPaymentActivity/components/RepaymentCreditKey';
import { RepaymentFundingSourceInfo } from 'src/pages/bill/pay/components/PayBillPaymentActivity/components/RepaymentFundingSourceInfo';
import { getPayFromLabel } from 'src/pages/bill/utils';
import { AccountRecord } from 'src/pages/settings/records';
import { intercomService } from 'src/services/intercom';
import { ThemeType } from 'src/theme/global-theme';
import { getScheduledStyling, getStatusInfo } from 'src/utils/bill-details';
import { BillStatus as BillStatuses, PaymentStatus as PaymentStatuses } from 'src/utils/consts';
import { BillType, FinancePayment, Installment, StatusInfoType } from 'src/utils/types';
import { ViewPaymentMultipleBills } from '../ViewPaymentBills';
import { InstallmentAmountSubheader } from './InstallmentAmountSubheader';
import { InstallmentFailedErrorMessage } from './InstallmentFailedErrorMessage';
import { InstallmentPaymentActivityHeader } from './InstallmentPaymentActivityHeader';
import { InstallmentStatus } from './InstallmentStatus';

type Props = {
  payment: FinancePayment;
  bill: BillType;
  theme: ThemeType;
  backPath: string;
  installment: Installment;
};

export const ViewFailedInstallment = ({ payment, bill, theme, backPath, installment }: Props) => {
  const intl = useIntl();
  const fundingSource = AccountRecord(payment.fundingSource);
  const isBillPaid = bill.status === BillStatuses.PAID;
  const payFromLabel = getPayFromLabel(isBillPaid, true);
  const totalInstallmentAmount = payment.financing.installments.length;
  const contactSupportLink = intl.formatMessage({ id: 'settings.support.supportRequestUrl' });

  const openSupportChatIfEnabled = (event: React.MouseEvent<HTMLElement>) => {
    if (intercomService.enabled) {
      event.preventDefault();
      intercomService.show();
    }
  };

  const statusInfo: StatusInfoType = {
    ...getScheduledStyling(theme),
    ...getStatusInfo({ status: PaymentStatuses.FAILED }, theme, payment),
  };

  return (
    <SingleViewLoadingContainer>
      <InstallmentStatus
        currentInstallmentNumber={installment.installmentNumber}
        totalInstallmentAmount={totalInstallmentAmount}
        payment={payment}
        bill={bill}
        backPath={backPath}
      />

      <Flex direction="column" h="full" mb={{ sm: 10 }} data-testid="installment-info-container">
        <MICard mode="mainSingleScreen">
          {statusInfo && <BillStatus statusInfo={statusInfo} />}
          <InstallmentFailedErrorMessage
            titleLabel="financing.failedInstallment.error.title"
            messageLabel="financing.failedInstallment.error.subtitle"
            messageLabelValues={{
              date: <MIFormattedDate date={addDays(new Date(installment.scheduledDate), 14)} />,
              link: (text) => (
                <Link href={contactSupportLink} target="_blank" onClick={openSupportChatIfEnabled}>
                  {text}
                </Link>
              ),
            }}
          />
          <Container>
            <InstallmentPaymentActivityHeader payment={payment} />
          </Container>
          <Container>
            <InstallmentAmountSubheader
              amount={installment.amount}
              payment={payment}
              linkLabel="financing.failedInstallment.status.failed"
            />
          </Container>
          <Separator />
          <Container>
            <Box pt={6} pb={1}>
              <PaymentApprovalLogs payment={payment} />
            </Box>
          </Container>
          <Separator mb={5} />
          <Container>
            <BlockTitleContainer data-testid="billpay-view-title-container">
              <MIFormattedText label={payFromLabel} />
            </BlockTitleContainer>
            <Box data-testid="billpay-view-activity-container">
              <RepaymentFundingSourceInfo
                bill={bill}
                fundingSource={fundingSource}
                failureDate={installment.failureDate}
              />
              <InstallmentsDeductionDatesInfo
                theme={CardThemes.Black}
                financing={payment.financing}
                showInstallmentsBadges
                label="financing.installmentDeductsLabel"
              />
            </Box>
          </Container>

          <Container>
            <Box pt={6} pb={1}>
              <RepaymentCreditKey />
            </Box>
          </Container>
        </MICard>
        <ViewPaymentMultipleBills payment={payment} />
      </Flex>
    </SingleViewLoadingContainer>
  );
};

const Container: React.FC = ({ children }) => <Box px={8}>{children}</Box>;
