import upperFirst from 'lodash/upperFirst';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { getSuccessPageHeadTexts } from 'src/pages/bill/utils';
import { DeliveryType, VendorManagedByEnum } from 'src/utils/consts';
import { CompanyInfoType, DeliveryMethodType, PaymentType, RecurringBillType } from 'src/utils/types';

export const getLabelsWithValues = ({
  payment,
  billVendorCompanyName,
  deliveryMethod,
  hasVendorEmail,
  companyName,
  recurringBill,
  isRecurring,
}: {
  payment: Pick<PaymentType, 'amount' | 'scheduledDate'>;
  billVendorCompanyName: string;
  deliveryMethod?: Pick<DeliveryMethodType, 'deliveryType' | 'managedBy'>;
  companyName: CompanyInfoType['companyName'];
  hasVendorEmail: boolean;
  recurringBill?: Pick<RecurringBillType, 'frequency'>;
  isRecurring?: boolean;
}) => {
  const getButtonLabel = () => {
    const deliveryType = deliveryMethod?.deliveryType;
    const isUnilateralPayment = deliveryType === DeliveryType.VIRTUAL;
    const isDirectoryPayment = deliveryMethod?.managedBy === VendorManagedByEnum.MSN;

    if (isUnilateralPayment) {
      return 'bills.pay.payBillSuccess.buttonLabel.toDashboard';
    }

    if (hasVendorEmail || isDirectoryPayment) {
      return 'bills.pay.payBillSuccess.buttonLabel.done';
    }

    return 'bills.pay.payBillSuccess.buttonLabel.notifyVendor';
  };

  const textValues: Record<string, React.ReactNode> = {
    amount: <MIFormattedCurrency key="amount" value={payment?.amount?.toString() || null} />,
    vendorName: billVendorCompanyName,
    processDate: <MIFormattedDate key="scheduledDate" date={payment.scheduledDate} />,
    companyName,
  };
  const titleValues = { frequency: upperFirst(recurringBill?.frequency) };
  const { title, subtitle } = getSuccessPageHeadTexts({ payment, hasVendorEmail, isRecurring });
  const buttonLabel = getButtonLabel();

  return {
    title,
    titleValues,
    subtitle,
    textValues,
    buttonLabel,
  };
};
