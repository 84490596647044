import { formatRelative, startOfDay } from 'date-fns';
import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { locale } from 'src/utils/date-fns';

type Props = {
  lastSyncAt: string | null;
};

const LastSyncStatus: React.FC<Props> = ({ lastSyncAt }) => {
  if (lastSyncAt) {
    const date = startOfDay(new Date());
    const lastSyncAtDate = new Date(lastSyncAt);
    const formattedLastSyncAt = formatRelative(lastSyncAtDate, date, {
      locale,
    });

    return (
      <Box mx={1} color="grey.600" textStyle="body4">
        <MIFormattedText label="getPro.table.summary.lastSyncAt" values={{ lastSyncAt: formattedLastSyncAt }} />
      </Box>
    );
  }

  return null;
};

export { LastSyncStatus };
