import { useCallback, useEffect, useState } from 'react';
import type { GeneralError } from 'src/utils/types';

export function useAsyncAction<T>(action: () => T | PromiseLike<T>): [boolean, GeneralError] {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<GeneralError>(null);

  const executeAction = useCallback(async () => {
    try {
      await action();
    } catch (e: GeneralError) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [action]);

  useEffect(() => {
    executeAction();
  }, [executeAction]);

  return [loading, error];
}
