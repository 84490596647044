import { VStack } from '@chakra-ui/layout';
import { featureFlags } from '@melio/shared-web';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import Box from 'src/core/ds/box';
import { IconNames } from 'src/core/ds/icon';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { profileStore } from 'src/modules/profile/profile-store';
import { DeliveryType, FeatureFlags, VerifiedStatus } from 'src/utils/consts';
import { getDeliveryMethodName } from 'src/utils/delivery-methods';
import { DeliveryMethodType, PendingDeliveryMethodType } from 'src/utils/types';
import { getDeliveryMethodActionOptions } from '../utils/getDeliveryMethodActionOptions';
import SettingItemDetails from './SettingItemDetails';

type Props = {
  deliveryMethods: readonly DeliveryMethodType[];
  pendingDeliveryMethods?: readonly PendingDeliveryMethodType[];
  goAddDeliveryMethods?: () => void;
  goEditDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
  goViewDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
  goEditBankAccountLink?: (deliveryMethod: DeliveryMethodType) => void;
  onDeleteDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
  onUpdateDeliveryMethod?: (deliveryMethod: DeliveryMethodType) => void;
  onVerifyClicked?: (id: number) => void;
};

type DeliveryMethodList = DeliveryMethodType & {
  isPending?: boolean;
};

const DeliveryMethodsList = ({
  deliveryMethods,
  goAddDeliveryMethods,
  goEditDeliveryMethod,
  onDeleteDeliveryMethod,
  onVerifyClicked,
  goViewDeliveryMethod,
  goEditBankAccountLink,
  pendingDeliveryMethods,
  onUpdateDeliveryMethod,
}: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const { isConnected } = useFetchAccountingPlatformsAndData();
  const [isMsnUpdateDeliveryMethodFeatureFlagEnabled] = featureFlags.useFeature(
    FeatureFlags.MsnUpdateDeliveryMethod,
    false
  );

  const isManualBankAccountNotVerified = (deliveryMethod?: DeliveryMethodType) =>
    deliveryMethod &&
    deliveryMethod.deliveryType === DeliveryType.ACH &&
    deliveryMethod.plaidAccount === null &&
    deliveryMethod.verifiedStatus === VerifiedStatus.NOT_VERIFIED;

  const isBankAccountBlocked = (deliveryMethod?: DeliveryMethodType) =>
    !!(deliveryMethod && deliveryMethod.bankAccount && deliveryMethod.bankAccount.isBlocked);

  const canVerifyManualBankAccount = (deliveryMethod: DeliveryMethodType) =>
    !!(
      isManualBankAccountNotVerified(deliveryMethod) &&
      deliveryMethod.bankAccount &&
      !deliveryMethod.bankAccount.isBlocked
    );

  const getDescription = (deliveryMethod: DeliveryMethodType) => {
    if (isManualBankAccountNotVerified(deliveryMethod)) {
      if (isBankAccountBlocked(deliveryMethod)) {
        return 'settings.receivingMethods.microDepositsBlocked';
      }

      return 'settings.receivingMethods.pendingAchDescription';
    }

    return 'settings.receivingMethods.achDescription';
  };

  const getLinkDescriptionValues = (deliveryMethod, onVerifyClicked) => {
    if (onVerifyClicked && canVerifyManualBankAccount(deliveryMethod)) {
      const onVerifyAccount = (event) => {
        event.preventDefault();
        onVerifyClicked && onVerifyClicked(deliveryMethod.id);
      };

      return (
        <Box as="span" ms={1}>
          <VerifyAccountLink
            testId={`verify-delivery-method-${deliveryMethod.id}`}
            label="settings.receivingMethods.verifyAccountHint"
            onClick={onVerifyAccount}
          />
        </Box>
      );
    }

    return '';
  };

  return (
    <DeliveryMethodContainer>
      <VStack spacing={5} data-testid="receiving-methods-setting-details-container">
        {!isEmpty(deliveryMethods) &&
          [...deliveryMethods, ...(pendingDeliveryMethods || [])].map((deliveryMethod: DeliveryMethodList) => {
            const deliveryMethodName = getDeliveryMethodName(deliveryMethod);
            const linkDescriptionValues = getLinkDescriptionValues(deliveryMethod, onVerifyClicked);
            const itemTestId = `setting-item-details-${deliveryMethod.id}`;
            const imageObj = {
              logo: deliveryMethod.logo,
            };

            const actionOptions = getDeliveryMethodActionOptions({
              deliveryMethod,
              permissions,
              isConnectedToAccountingPlatform: isConnected,
              goEditDeliveryMethod,
              onDeleteDeliveryMethod,
              goViewDeliveryMethod,
              goEditBankAccountLink,
              onUpdateDeliveryMethod,
              isMsnUpdateDeliveryMethodFeatureFlagEnabled,
            });

            return (
              <SettingItemDetails
                testId={deliveryMethod.isPending ? `pending-${itemTestId}` : itemTestId}
                key={deliveryMethod.id}
                goAddItem={goAddDeliveryMethods}
                iconName={IconNames.bank}
                imageObj={imageObj}
                descriptionLabel={getDescription(deliveryMethod)}
                descriptionValues={{
                  accountName: deliveryMethodName,
                  link: linkDescriptionValues,
                }}
                nameLabel="settings.receivingMethods.ach"
                actionOptions={actionOptions}
                isPending={deliveryMethod.isPending}
                hideAddItemLink
                isManualBankAccountNotVerified={isManualBankAccountNotVerified(deliveryMethod)}
              />
            );
          })}
      </VStack>
      {isEmpty(deliveryMethods) && (
        <SettingItemDetails
          testId="setting-item-details-add-ach"
          goAddItem={goAddDeliveryMethods}
          iconName={IconNames.bank}
          imageObj={{ logo: '' }}
          nameLabel="settings.receivingMethods.ach"
          descriptionLabel="settings.receivingMethods.hint"
        />
      )}
    </DeliveryMethodContainer>
  );
};

export default DeliveryMethodsList;

const DeliveryMethodContainer = styled.div`
  margin-top: 1.7rem;
`;

const VerifyAccountLink = styled(MIInlineLink)`
  height: inherit;
`;
