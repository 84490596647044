import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { useBillFiles } from 'src/pages/bill/hooks/useBillFiles';
import { InvoiceAttachmentField } from 'src/pages/vendor/international-delivery-method/components/InvoiceAttachmentField';
import { useInvoiceAttachmentRequired } from 'src/pages/vendor/international-delivery-method/hooks/useInvoiceAttachmentRequired';
import { getOrgId } from 'src/redux/user/selectors';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { convertNumberToCurrency } from 'src/utils/currency-utils';
import { useUploadBillAttachment } from '../../hooks/useUploadBillattachment';
import { useAddBillAttachmentModal } from './useAddBillAttachmentModal';

type Props = {
  onClose: () => void;
  bill: BatchBillType;
};

export const AddBillAttachmentModal = ({ onClose, bill }: Props) => {
  const orgId = useSelector(getOrgId);
  const { vendor, id } = bill;
  const vendorId = vendor.id;
  const [isInvoiceAttachmentRequired] = useInvoiceAttachmentRequired(orgId, vendorId, bill);
  const { submit: submitForm, model } = useAddBillAttachmentModal(onClose);

  const {
    fileName,
    files,
    isUploading,
    isUploadError,
    handleRetry,
    uploadAttachment,
    removeAttachment,
  } = useBillFiles();

  const { handleSelectFile, updateBillFiles, isBillLoading } = useUploadBillAttachment({
    bill,
    files,
    uploadAttachment,
  });

  const onSubmit = async () => {
    if (isInvoiceAttachmentRequired && files.length) {
      await updateBillFiles();
    }

    submitForm();
  };

  const onClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Box onClick={onClick}>
      <ModalMessage
        alignLeft
        titleComponent={
          <>
            <MIFormattedText label="regularBatchPayments.addInvoiceAttachment.title" />
            <Box textStyle="body2" mt={4}>
              <MIFormattedText
                label="regularBatchPayments.addInvoiceAttachment.subtitle"
                values={{
                  invoiceNumber: bill.invoiceNumber ? `#${bill.invoiceNumber}` : '',
                  amount: convertNumberToCurrency(bill.balance),
                  vendor: vendor?.companyName,
                }}
              />
            </Box>
          </>
        }
        contentComponent={
          <FormContainer>
            <FormRow mbZero>
              <InvoiceAttachmentField
                onSelectFile={handleSelectFile}
                onRetry={handleRetry}
                fileName={fileName}
                files={files}
                testId="input-invoiceAttachment"
                isFileUploading={isUploading}
                isFileUploadError={isUploadError}
                onChangeInvoiceFile={handleSelectFile}
                onDeleteInvoiceFile={removeAttachment}
                model={model.invoiceAttachment}
              />
            </FormRow>
          </FormContainer>
        }
        buttonLabel="regularBatchPayments.addInvoiceAttachment.button"
        analyticsProperties={{ billId: id }}
        onButtonClick={onSubmit}
        onCloseClick={onClose}
        isLoading={isBillLoading}
      />
    </Box>
  );
};
