import styled, { css } from 'styled-components';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FieldValue } from 'src/pages/bill/pay/components/PayBillPaymentReview/components/PayBillDetails/components/FieldValue';
import { InvoiceList } from 'src/pages/bill/pay/components/PayBillPaymentReview/components/PayBillDetails/components/InvoiceList/InvoiceList';
import {
  getInvoiceNumberLabel,
  getRecurringBillHintInfo,
} from 'src/pages/bill/pay/components/PayBillPaymentReview/components/PayBillDetails/utils';
import { isRppsVendor } from 'src/pages/vendor-directory/utils';
import { devices } from 'src/theme/appDevices';
import { isPaymentBulkPayment } from 'src/utils/payments';
import { BillType, PaymentType } from 'src/utils/types';

type Props = {
  bill: BillType;
  payment: PaymentType;
};

export const PayBillDetails = ({ bill, payment }: Props) => {
  const companyName = bill.vendor?.companyName || '';
  const isRPPSVendor = isRppsVendor({
    ...bill.vendor,
    deliveryMethods: bill.vendor?.deliveryMethods ?? [],
  });
  const isBulkPayment = isPaymentBulkPayment(payment);
  const isRecurringBill = !!bill.recurringBill;
  const invoiceNumberLabel = getInvoiceNumberLabel(isRecurringBill, isBulkPayment);
  const { label, values, occurrences } = getRecurringBillHintInfo(bill);

  return (
    <PaymentInfoContainer>
      <SubTitle>
        <MIFormattedText label="bills.pay.confirm.payTo" />
      </SubTitle>
      <BoxRow>
        <BoxColumn>
          <FieldName>
            <MIFormattedText label="bills.pay.confirm.businessName" />
          </FieldName>
          <FieldValue testId="bill-company-name">{companyName}</FieldValue>
        </BoxColumn>
        <InvoiceBoxColumn>
          <FieldName>
            <MIFormattedText label={invoiceNumberLabel} values={{ billsCount: payment.bills?.length }} />
          </FieldName>
          {isBulkPayment ? (
            <InvoiceList payment={payment} />
          ) : (
            <FieldValue testId="bill-invoice-number">
              <MIFieldOrEmpty value={bill.invoiceNumber} label="bills.view.noInvoiceNumber" />
            </FieldValue>
          )}
        </InvoiceBoxColumn>
      </BoxRow>
      {isRPPSVendor && (
        <BoxRow>
          <BoxColumn>
            <FieldName>
              <MIFormattedText label="bills.pay.confirm.accountIdentifier" />
            </FieldName>
            <FieldValue testId="bill-account-identifier">
              <MIFieldOrEmpty value={bill.vendor?.accountIdentifier} label="vendors.form.accountIdentifierEmpty" />
            </FieldValue>
          </BoxColumn>
        </BoxRow>
      )}
      {isRecurringBill && (
        <BoxRow>
          <BoxColumn>
            <FieldName>
              <MIFormattedText label="bills.pay.confirm.frequencyField" />
            </FieldName>
            <FieldValue testId="bill-frequency-field">
              <MIFormattedText label={label} values={values} />
            </FieldValue>
          </BoxColumn>
          <BoxColumn>
            <FieldName>
              <MIFormattedText label="bills.pay.confirm.occurrencesField" />
            </FieldName>
            <FieldValue testId="occurrences">{occurrences}</FieldValue>
          </BoxColumn>
        </BoxRow>
      )}
      <SectionSeparator />
    </PaymentInfoContainer>
  );
};

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const PaymentInfoContainer = styled(BaseContainer)`
  padding-bottom: 1.7rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentInfoContainer}
`;

const BoxRow = styled.div`
  display: flex;
  margin-top: 1.8rem;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  min-width: 0;
  width: 95%;
  padding-right: 2rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.BoxColumn}
`;

const InvoiceBoxColumn = styled(BoxColumn)`
  ${(props) => props.theme?.components?.BillPaymentReview?.InvoiceBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}

  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.BillPaymentReview?.FieldName}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;

const SectionSeparator = styled.div`
  display: none;
  ${(props) => props.theme?.components?.BillPaymentReview?.SectionSeparator}
`;
