import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import { PasswordField } from 'src/core/ds/form/fields';
import { ModelView } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';

type ResetPasswordParams = {
  password: string;
  confirmedPassword: string;
};

type Props = {
  isLoading?: boolean;
  hasNoPasswordDefined?: boolean;
  onResetPassword: () => Promise<void>;
  model: ModelView<ResetPasswordParams>;
};

export const ResetPasswordPage = ({ isLoading = false, onResetPassword, model, hasNoPasswordDefined }: Props) => (
  <RegisterLayoutPage
    title={hasNoPasswordDefined ? 'auth.resetPassword.createPasswordTitle' : 'auth.resetPassword.title'}
    buttonLabel={
      hasNoPasswordDefined ? 'auth.resetPassword.createPasswordButton' : 'auth.resetPassword.resetPasswordButton'
    }
    buttonAction={onResetPassword}
    isLoading={isLoading}
  >
    <WizardPasswordValidationInputField
      id="password"
      model={model.password}
      isRequired
      label="auth.resetPassword.password"
    />

    <PasswordField
      id="confirmedPassword"
      model={model.confirmedPassword}
      isRequired
      label="auth.resetPassword.confirmPassword"
    />
  </RegisterLayoutPage>
);
