import React from 'react';
import { Slide, toast } from 'react-toastify';
import { IconNames } from 'src/core/ds/icon';
import { NotificationVariant, Toast } from 'src/core/ds/toast';
import { analytics } from 'src/services/analytics';
import { TIMEOUTS } from 'src/utils/consts';

export function pushNotification(item: {
  type: string;
  msg: string;
  textValues?: Record<string, any>;
  icon?: IconNames;
  autoClose?: number | false;
  testId?: string;
}) {
  const { type, msg, icon, textValues, autoClose = TIMEOUTS.CLOSE_NOTIFICATION, testId } = item;

  analytics.trackAction('toast-notification', { type });

  return toast[type](
    <Toast type={NotificationVariant[type]} text={msg} textValues={textValues} iconName={icon} testId={testId} />,
    {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      transition: Slide,
    }
  );
}

export function checkIfToastIsDisplayedById(toastId: string | number | null) {
  return toastId ? toast.isActive(toastId) : false;
}

export function dismissNotification() {
  toast.dismiss();
}
