import { DeliveryType, VerifiedStatus } from 'src/utils/consts';
import { isVerifiedStatus } from 'src/utils/delivery-methods';
import { DeliveryMethodType, PendingDeliveryMethodType } from 'src/utils/types';

export const getActiveACHDeliveryMethodByVerifiedStatus = (
  deliveryMethods: readonly DeliveryMethodType[]
): DeliveryMethodType | undefined =>
  deliveryMethods?.find(
    (deliveryMethod) =>
      deliveryMethod.deliveryType === DeliveryType.ACH && isVerifiedStatus(deliveryMethod.verifiedStatus)
  );

export const getPendingACHDeliveryMethodByVerifiedStatus = (
  deliveryMethods: readonly PendingDeliveryMethodType[]
): PendingDeliveryMethodType | undefined =>
  deliveryMethods?.find(
    (pendingDeliveryMethod) =>
      pendingDeliveryMethod.verifiedStatus === VerifiedStatus.NOT_VERIFIED &&
      pendingDeliveryMethod.deliveryType === DeliveryType.ACH
  );
