import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  isMissingBillDetails?: boolean;
  onClick: () => void;
};

export const ScannedInvoiceNotification = ({ isMissingBillDetails, onClick }: Props) => {
  if (isMissingBillDetails) {
    return (
      <MINotificationCard
        title={{ label: 'pay.scannedInvoice.alert.missingTitle' }}
        subtitle={{
          label: 'pay.scannedInvoice.alert.missingSubtitle',
          values: {
            link: (...chunks) => (
              <Box display="inline" textStyle="link2" cursor="pointer" onClick={onClick}>
                {chunks}
              </Box>
            ),
          },
        }}
        type={NotificationCardTypes.WARNING}
      />
    );
  }

  return (
    <MINotificationCard
      title={{ label: 'pay.scannedInvoice.alert.reviewTitle' }}
      subtitle={{
        label: 'pay.scannedInvoice.alert.reviewSubtitle',
        values: {
          link: (...chunks) => (
            <Box display="inline" textStyle="link2" cursor="pointer" onClick={onClick}>
              {chunks}
            </Box>
          ),
        },
      }}
      type={NotificationCardTypes.INFO}
    />
  );
};
