import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { filesApi } from 'src/modules/files/api';
import { getOrgId } from 'src/redux/user/selectors';
import { getPaymentRequestIdById, isPaymentRequest } from 'src/utils/bills';
import { FileUrlsPayloadType } from 'src/utils/types';

export const useGetFileUrlsByFileId = (
  fileId?: string | null,
  objectId?: string
): {
  fileStorageUrl: string;
  filePreviewUrls: string[];
  isLoading: boolean;
} => {
  const orgId = useSelector(getOrgId);
  const defaultFileUrls: FileUrlsPayloadType = {
    filePreviewUrls: [],
    fileStorageUrl: '',
  };
  const [fileUrls, setFileUrls] = useState(defaultFileUrls);
  const isPaymentRequestObject = objectId && isPaymentRequest(objectId);

  const { onApiCall: loadFile, loading: isLoading } = useApi({
    api: isPaymentRequestObject ? filesApi.fetchFileUrlsByPaymentRequest : filesApi.fetchFileUrls,
  });

  const update = async () => {
    if (fileId) {
      const result = await loadFile(
        orgId,
        fileId,
        isPaymentRequestObject && objectId ? getPaymentRequestIdById(objectId) : undefined
      );
      setFileUrls(result);
    } else {
      setFileUrls(defaultFileUrls);
    }
  };

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  return {
    filePreviewUrls: fileUrls.filePreviewUrls,
    fileStorageUrl: fileUrls.fileStorageUrl,
    isLoading,
  };
};
