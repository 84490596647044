import React from 'react';
import { BrandFullLogo } from 'src/core/ds/brandFullLogo';
import { brandNameByCardNetwork } from 'src/utils/card';
import { CardNetworkTypes, CardTypes, FundingType } from 'src/utils/consts';
import { hasFundingLogo } from 'src/utils/funding-sources';
import { AccountType, CardNetworkKeysType } from 'src/utils/types';

type FundingSourceIconProps = {
  fundingSource: AccountType;
};

export const FundingSourceIcon = ({ fundingSource }: FundingSourceIconProps) => {
  const bankIcon =
    fundingSource.fundingType === FundingType.ACH && hasFundingLogo(fundingSource) ? (
      <img src={`data:image/jpeg;base64,${fundingSource.logo}`} alt="funding source icon" />
    ) : (
      <div className="icon-bank-icon" />
    );
  const cardType = fundingSource.cardAccount?.cardType || '';
  const network = fundingSource.cardAccount?.network?.toUpperCase() as CardNetworkKeysType;
  let icon = bankIcon;

  if (fundingSource && cardType === CardTypes.CREDIT) {
    icon = <BrandFullLogo name={brandNameByCardNetwork[CardNetworkTypes[network]]} />;
  }

  if (fundingSource && cardType === CardTypes.DEBIT) {
    icon = <BrandFullLogo name={brandNameByCardNetwork[CardNetworkTypes[network]]} />;
  }

  return icon;
};
