import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { getBankAccountDescription, isDebitCard } from 'src/components/common/SelectMethods/types/utils';
import { useMicroDepositOptimizationsEnabled } from 'src/hooks/useMicroDepositOptimizationsEnabled';
import { useOrgMicroDepositEligible } from 'src/hooks/useOrgMicroDepositEligible';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks/usePayablesConnectedAccountingPlatform';
import { profileStore } from 'src/modules/profile/profile-store';
import { PayFundingSourceDescriptionWarningIcon } from 'src/pages/bill/pay/components/PayFundingSourceDescriptionWarningIcon';
import { convertDateToMonthYearFormat, getExpirationDateHint, isCardExpired } from 'src/utils/card';
import { FundingType } from 'src/utils/consts';
import {
  canVerifyManualBankAccount,
  getFundingSourceIcon as getFundingSourceImage,
  getModifyFundingSourceOptions,
  getNameParts,
  isBankAccountBlocked,
  isManualBankAccountNotVerified,
} from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

type Props = {
  onClick?: (event: any) => void;
  method: AccountType;
  isSelected: boolean;
  optionComponent: any;
  onVerifyClicked?: (id: number) => void;
  modifyActions: Record<string, VoidFunction>;
  disabled: boolean;
  isDefault: boolean;
  showFirmBillingFeeBadge: boolean;
  isBillingFee?: boolean;
};

function getDescriptionValues(fundingSource, onVerifyClicked) {
  if (onVerifyClicked && canVerifyManualBankAccount(fundingSource)) {
    return {
      link: (
        <VerifyAccountLink
          testId={`verify-funding-source-${fundingSource.id}`}
          label="settings.paymentMethods.verifyAccountHint"
          onClick={(event) => {
            event.preventDefault();
            onVerifyClicked && onVerifyClicked(fundingSource.id);
          }}
        />
      ),
    };
  }

  return {
    link: '',
  };
}

export const BankAccountPaymentMethod = ({
  onClick,
  method,
  isSelected,
  onVerifyClicked,
  optionComponent: OptionComponent,
  disabled,
  isDefault,
  showFirmBillingFeeBadge,
  modifyActions,
  isBillingFee,
}: Props) => {
  const { isOrgMicroDepositEligible } = useOrgMicroDepositEligible();
  const { isMicroDepositOptimizationsEnabled } = useMicroDepositOptimizationsEnabled();
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const isOrgNotMicroDepositEligible = isMicroDepositOptimizationsEnabled && !isOrgMicroDepositEligible;
  const noMethodDescription = isBillingFee
    ? 'billing.addMethod.bankAccount.emptyMethodDescription'
    : 'onboarding.fundingSources.options.bank.description';

  if (!method) {
    return (
      <OptionComponent
        id="ach"
        key="ach-empty"
        label="onboarding.fundingSources.options.bank.label"
        description={noMethodDescription}
        icon="icon-bank-icon"
        onClick={onClick}
        isEmptyList
      />
    );
  }

  const { icon, imageSrc } = getFundingSourceImage(method);
  const isDebitCardExpired = !!(method.cardAccount && isCardExpired(method.cardAccount));
  const description = getBankAccountDescription(method, isDebitCardExpired, isOrgNotMicroDepositEligible, isBillingFee);
  const descriptionValues = getDescriptionValues(method, onVerifyClicked);
  const label = isDebitCard(method) ? 'bills.pay.fundingSource.debitWithBankLabel' : 'bills.pay.fundingSource.achLabel';
  const descriptionIcon =
    isMicroDepositOptimizationsEnabled && isManualBankAccountNotVerified(method) ? (
      <PayFundingSourceDescriptionWarningIcon />
    ) : null;

  const actionOptions = getModifyFundingSourceOptions(
    permissions,
    modifyActions,
    isConnectedAccountingPlatform,
    disabled,
    method.origin,
    isDebitCardExpired,
    !!method.nickname,
    isDefault
  );

  return (
    <OptionComponent
      id={method.id}
      disabled={disabled}
      isDefault={isDefault}
      showFirmBillingFeeBadge={showFirmBillingFeeBadge}
      onTopDescription={method.nickname}
      label={label}
      labelValues={{
        ...getNameParts(method),
      }}
      hint={getExpirationDateHint(method.cardAccount)}
      hintValues={{
        expirationDate: convertDateToMonthYearFormat(method.cardAccount?.expiration),
      }}
      isExpired={isDebitCardExpired}
      description={description}
      descriptionValues={descriptionValues}
      descriptionIcon={descriptionIcon}
      isSelected={isSelected}
      icon={icon}
      imageSrc={imageSrc}
      onClick={onClick}
      actionOptions={actionOptions}
    />
  );
};

const VerifyAccountLink = styled(MIInlineLink)`
  padding: 1rem;
  margin: -1rem;
  height: inherit;
`;

function isDisabledCheck(method: AccountType) {
  if (method.cardAccount) {
    return isCardExpired(method.cardAccount);
  }

  return isBankAccountBlocked(method);
}

BankAccountPaymentMethod.methodType = FundingType.ACH;
BankAccountPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherBank';
BankAccountPaymentMethod.isDisabled = isDisabledCheck;
BankAccountPaymentMethod.showAddAnotherOption = true;
