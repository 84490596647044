import { VirtualCardCanceledView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardCanceledView';
import { VirtualCardChangedDMView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardChangedDMView';
import { VirtualCardClearedView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardClearedView';
import { VirtualCardDepositedView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardDepositedView';
import { VirtualCardExpiredView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardExpiredView';
import { VirtualCardRecoveredView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardRecoveredView';
import { VirtualCardValidView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardValidView';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { isVirtualCardCleared } from 'src/pages/vendor/virtual-card/utils';
import { DeliveryType, PaymentStatus, VirtualCardStatus } from 'src/utils/consts';
import { PaymentType, VirtualCardType } from 'src/utils/types';

type Props = {
  state: VirtualCardPaymentState;
  payment: PaymentType;
  virtualCard?: VirtualCardType;
};

export const eventPage = 'virtual-card-details';

export const useVirtualCardView = ({ state, payment, virtualCard }: Props) => {
  const lastCreatedVirtualCard = payment?.lastCreatedVirtualCard;
  const isVirtualCardDM = payment?.deliveryMethod?.deliveryType === DeliveryType.VIRTUAL_CARD;
  const isPaymentFailed = payment?.status === PaymentStatus.FAILED;
  const isPaymentCompleted = payment?.status === PaymentStatus.COMPLETED;
  const isPaymentVirtualCardCleared = payment && isVirtualCardCleared(payment);
  const isVirtualCardExpired = virtualCard?.status === VirtualCardStatus.EXPIRED;
  const isExpiredVirtualCardRecovered = isVirtualCardExpired && lastCreatedVirtualCard?.id !== virtualCard?.id;
  const isVirtualCardCanceled = virtualCard?.status === VirtualCardStatus.CANCELED;
  const analyticsProperties = {
    vendorId: payment?.vendor?.id,
    paymentId: payment?.id,
    vendorEmail: payment?.vendor?.contactEmail,
  };

  if (payment) {
    if (isVirtualCardDM) {
      if (isPaymentFailed && isVirtualCardCanceled) {
        return <VirtualCardCanceledView state={state} analyticsProperties={analyticsProperties} />;
      }

      if (isPaymentCompleted) {
        if (isPaymentVirtualCardCleared) {
          return <VirtualCardClearedView state={state} analyticsProperties={analyticsProperties} />;
        }
      }

      if (isExpiredVirtualCardRecovered) {
        return <VirtualCardRecoveredView state={state} analyticsProperties={analyticsProperties} />;
      }

      if (isVirtualCardExpired) {
        return <VirtualCardExpiredView state={state} analyticsProperties={analyticsProperties} />;
      }

      return <VirtualCardValidView state={state} analyticsProperties={analyticsProperties} />;
    }

    if (isPaymentCompleted) {
      return <VirtualCardDepositedView state={state} analyticsProperties={analyticsProperties} />;
    }

    return <VirtualCardChangedDMView state={state} analyticsProperties={analyticsProperties} />;
  }

  return null;
};
