import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useSelector } from 'react-redux';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { CompanyInfoOnboardingOrigin, CompanyType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';

export const useUnifiedOnboardingFlag = (): boolean => {
  const organizationPreferences = useOrganizationPreferences();
  const [origin] = useLocationState<CompanyInfoOnboardingOrigin | null>('origin', null);
  const [isUnifiedOnboardingOpen] = useFeature<boolean>('unified-onboarding', false);
  const companyInfo = useSelector(getCompanyInfo);
  let shouldShowIntentPage = false;
  const isAccountingFirm = companyInfo.companyType === CompanyType.ACCOUNTING_FIRM;
  const isComingFromPaymentRequest =
    origin &&
    [CompanyInfoOnboardingOrigin.PAYMENT_REQUEST, CompanyInfoOnboardingOrigin.GUEST_PAYMENT_REQUEST].includes(origin);
  const isAccountantAddCompanyFlow =
    origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY || !!organizationPreferences.uninvitedOwnerEmail;

  shouldShowIntentPage =
    isUnifiedOnboardingOpen && !isAccountingFirm && !isComingFromPaymentRequest && !isAccountantAddCompanyFlow;

  return shouldShowIntentPage;
};
