import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { DELIVERY_OPTION_TYPES } from 'src/utils/consts';
import { DeliveryOptionType } from 'src/utils/types';

type Props = {
  isCheck: boolean;
  dollarLabel: string;
  deliveryOption: DeliveryOptionType;
  paperCheckLabel: string;
};

export const DeliveryOptionPrice = ({ isCheck, dollarLabel, deliveryOption, paperCheckLabel }: Props) => (
  <Box as="span" textStyle="body4">
    {DELIVERY_OPTION_TYPES.includes(deliveryOption.type) ? (
      <MIFormattedText
        label={dollarLabel}
        values={{
          fee: <MIFormattedCurrency value={deliveryOption.amount} />,
        }}
      />
    ) : (
      <MIFormattedText
        label={isCheck ? paperCheckLabel : 'bills.pay.date.deliveryOptions.price.default'}
        values={{ amount: deliveryOption?.fee?.[0]?.amount?.toFixed(2) }}
      />
    )}
  </Box>
);
