import { useDispatch } from 'react-redux';
import {
  beginRegularPayBillFlowAction,
  cancelAndRetryPaymentAction,
  createBillAndPaymentFromPaymentRequestAction,
  createFinancingPaymentAction,
  createInternationalPaymentAction,
  createPaymentAction,
  createRecurringBillAction,
  endPayBillFlowAction,
  fetchBillAction,
  reschedulePaymentAction,
  retryFailedToCollectPaymentAction,
  retryFailedToDeliverPaymentAction,
  selectPaymentDatesAction,
  setPaymentAmountAction,
  updatePaymentAction,
} from 'src/redux/payBillWizard/actions';
import { setCompanyInfoAction, updateOriginPlaidItemIdAction } from 'src/redux/user/actions';
import { CompanyInfoType, PaymentType } from 'src/utils/types';

export const usePayBillActions = () => {
  const dispatch = useDispatch();

  const endPayBillFlow = (isCloseEvent: boolean, preventCloseIframe?: boolean) => {
    dispatch(endPayBillFlowAction(isCloseEvent, preventCloseIframe));
  };

  const beginRegularPayBillFlow = (id: string, paymentId: string, redirectUrl?: string, exitUrl?: string) => {
    dispatch(beginRegularPayBillFlowAction(id, paymentId, redirectUrl, exitUrl));
  };

  const setCompanyInfo = (companyInfo: CompanyInfoType) => {
    dispatch(setCompanyInfoAction(companyInfo));
  };

  const createRecurringBill = () =>
    new Promise((resolve, reject) => {
      dispatch(createRecurringBillAction(resolve, reject));
    });

  const createPayment = () =>
    new Promise<PaymentType>((resolve, reject) => {
      dispatch(createPaymentAction(resolve, reject));
    });

  const updatePayment = () =>
    new Promise((resolve, reject) => {
      dispatch(updatePaymentAction(resolve, reject));
    });

  const setPaymentAmount = (amount: number) => {
    dispatch(setPaymentAmountAction(amount));
  };

  const reschedulePayment = (): Promise<PaymentType> =>
    new Promise((resolve, reject) => {
      dispatch(reschedulePaymentAction(resolve, reject));
    });
  const retryFailedToDeliverPayment = (): Promise<PaymentType> =>
    new Promise((resolve, reject) => {
      dispatch(retryFailedToDeliverPaymentAction(resolve, reject));
    });
  const retryFailedToCollectPayment = (): Promise<PaymentType> =>
    new Promise((resolve, reject) => {
      dispatch(retryFailedToCollectPaymentAction(resolve, reject));
    });

  const cancelAndRetryPayment = (): Promise<PaymentType> =>
    new Promise((resolve, reject) => {
      dispatch(cancelAndRetryPaymentAction(resolve, reject));
    });

  const selectPaymentDates = (scheduledDate, deliveryEta, maxDeliveryEta, selectedId?) => {
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryEta, maxDeliveryEta, selectedId));
  };

  const updateOriginPlaidItemId = (id: number) => {
    dispatch(updateOriginPlaidItemIdAction(id));
  };

  const fetchBill = (id: string) => {
    dispatch(fetchBillAction(id));
  };

  const createBillAndPaymentFromPaymentRequest = () =>
    new Promise<PaymentType>((resolve, reject) => {
      dispatch(createBillAndPaymentFromPaymentRequestAction(resolve, reject));
    });

  const createInternationalPayment = () =>
    new Promise<PaymentType>((resolve, reject) => {
      dispatch(createInternationalPaymentAction(resolve, reject));
    });

  const createFinancingPayment = () =>
    new Promise<PaymentType>((resolve, reject) => {
      dispatch(createFinancingPaymentAction(resolve, reject));
    });

  return {
    endPayBillFlow,
    beginRegularPayBillFlow,
    setCompanyInfo,
    createRecurringBill,
    createPayment,
    updatePayment,
    setPaymentAmount,
    reschedulePayment,
    retryFailedToDeliverPayment,
    retryFailedToCollectPayment,
    selectPaymentDates,
    updateOriginPlaidItemId,
    fetchBill,
    createBillAndPaymentFromPaymentRequest,
    createInternationalPayment,
    createFinancingPayment,
    cancelAndRetryPayment,
  };
};
