import { IllustrationName } from 'src/core/ds/illustration';

export enum MfaCodeVerificationState {
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  MAX_RESEND_ATTEMPTS_REACHED = 'MAX_RESEND_ATTEMPTS_REACHED',

  EXPIRED_CODE = 'EXPIRED_CODE', // Code expired
  WRONG_CODE = 'WRONG_CODE', // Invalid code
  MAX_ATTEMPTS_REACHED = 'MAX_ATTEMPTS_REACHED', // Too many failed attempts with the same code
  VALIDATION_FAILED = 'VALIDATION_FAILED', // VALIDATION_FAILED
  DENIED = 'DENIED', // Too many failed attempts, try again in 24 hours
  FORBIDDEN = 'FORBIDDEN', // Something went wrong
  EXPIRED_MFA_SESSION = 'EXPIRED_MFA_SESSION', // Session expired
  EXPIRED_AUTHENTICATOR = 'EXPIRED_AUTHENTICATOR', // Authenticator expired
  UNAUTHORIZED = 'UNAUTHORIZED', // User is not authorized
}

export enum MfaAuthenticatorMethod {
  SMS = 'sms',
  EMAIL = 'email',
}

export type MfaCodeVerificationStatusType = Exclude<MfaCodeVerificationState, 'PENDING' | 'VERIFIED'>;

export const mapMfaFailedCodeVerificationErrors = {
  [MfaCodeVerificationState.MAX_RESEND_ATTEMPTS_REACHED]: 'guests.mfaVerification.input.incorrectCode',
  [MfaCodeVerificationState.EXPIRED_CODE]: 'guests.mfaVerification.input.expiredCode',
  [MfaCodeVerificationState.WRONG_CODE]: 'guests.mfaVerification.input.wrongCode',
  [MfaCodeVerificationState.MAX_ATTEMPTS_REACHED]: 'guests.mfaVerification.input.incorrectCode',
  [MfaCodeVerificationState.VALIDATION_FAILED]: 'guests.mfaVerification.input.incorrectCode',
  [MfaCodeVerificationState.DENIED]: 'guests.mfaVerification.input.incorrectCode',
  [MfaCodeVerificationState.FORBIDDEN]: 'guests.mfaVerification.input.incorrectCode',
  [MfaCodeVerificationState.EXPIRED_AUTHENTICATOR]: 'guests.mfaVerification.input.incorrectCode',
  [MfaCodeVerificationState.UNAUTHORIZED]: 'guests.mfaVerification.input.incorrectCode',
};

export const mapMfaFailedCodeVerificationNotifications = {
  [MfaCodeVerificationState.MAX_RESEND_ATTEMPTS_REACHED]: {
    title: 'guests.mfaVerification.notification.maxResendAttempts.title',
    subtitle: 'guests.mfaVerification.notification.maxResendAttempts.subtitle',
  },
  [MfaCodeVerificationState.EXPIRED_CODE]: {
    title: 'guests.mfaVerification.notification.expiredCode.title',
    subtitle: 'guests.mfaVerification.notification.expiredCode.subtitle',
  },
  [MfaCodeVerificationState.MAX_ATTEMPTS_REACHED]: {
    title: 'guests.mfaVerification.notification.maxAttempts.title',
    subtitle: 'guests.mfaVerification.notification.maxAttempts.subtitle',
  },
  [MfaCodeVerificationState.DENIED]: {
    title: 'guests.mfaVerification.notification.denied.title',
    subtitle: 'guests.mfaVerification.notification.denied.subtitle',
  },
  [MfaCodeVerificationState.FORBIDDEN]: {
    subtitle: 'guests.mfaVerification.notification.forbidden.subtitle',
  },
  [MfaCodeVerificationState.EXPIRED_MFA_SESSION]: {
    subtitle: 'guests.mfaVerification.notification.expiredSession.subtitle',
  },
  [MfaCodeVerificationState.EXPIRED_AUTHENTICATOR]: {
    subtitle: 'guests.mfaVerification.notification.expiredSession.subtitle',
  },
};

export const mapMfaSuccessResendPushNotifications = {
  [MfaAuthenticatorMethod.SMS]: 'guests.mfaVerification.pushNotification.sentToPhone',
  [MfaAuthenticatorMethod.EMAIL]: 'guests.mfaVerification.pushNotification.sentToEmail',
};

export const mapMfaCodeVerificationFooterSecondOptionLabel = {
  [MfaAuthenticatorMethod.SMS]: 'guests.mfaVerification.footer.sendToEmail',
  [MfaAuthenticatorMethod.EMAIL]: 'guests.mfaVerification.footer.sendToPhone',
};

export const mapMfaCodeVerificationFooterSecondOptionTestId = {
  [MfaAuthenticatorMethod.SMS]: 'send-to-email-link',
  [MfaAuthenticatorMethod.EMAIL]: 'send-to-sms-link',
};

export const mapMfaFailedCodeVerificationIllustrations = {
  [MfaAuthenticatorMethod.SMS]: IllustrationName.phoneOne,
  [MfaAuthenticatorMethod.EMAIL]: IllustrationName.mailOne,
};

export const mapAnalyticErrorTypes = {
  [MfaCodeVerificationState.EXPIRED_CODE]: 'code-is-expired',
  [MfaCodeVerificationState.WRONG_CODE]: 'code-is-incorrect',
  [MfaCodeVerificationState.MAX_ATTEMPTS_REACHED]: 'too-many-failed-attempts-with-this-code',
  [MfaCodeVerificationState.DENIED]: 'too-many-failed-attempts',
  [MfaCodeVerificationState.FORBIDDEN]: 'something-went-wrong',
  [MfaCodeVerificationState.EXPIRED_MFA_SESSION]: 'this-session-has-expired',
  [MfaCodeVerificationState.EXPIRED_AUTHENTICATOR]: 'this-session-has-expired',
};
