import { ApiSliceActionName, generateActionName } from 'src/helpers/redux/actionNames';
import { name } from './consts';
import {
  addCardAccountWithToken,
  clearWhitePageAddressSlice,
  copyFromOwnedVendorSlice,
  copyFromOwnedVendorWithUnilateralToken,
  createInternationalDeliveryMethodSlice,
  fetchUnilateralRequestDetailsWithTokenSlice,
  replaceDeliveryMethodSlice,
  replaceVirtualDeliveryMethodSlice,
  shiftVirtualCardToACHPaymentStatusCollectedSlice,
  shouldCloseVerifyDeliveryMethodIndicatorSlice,
  updateDeliveryMethodACHSlice,
  updateWithUnilateralToken,
  verifyMicroDepositsForDeliveryMethodSlice,
  whitePagesAddressSlice,
} from './delivery-methods-slice';

export const DELIVERY_METHOD_FETCH_SUCCESS = generateActionName(name, 'FETCH', ApiSliceActionName.SUCCESS);
export const DELIVERY_METHOD_CREATE_SUCCESS = generateActionName(name, 'CREATE', ApiSliceActionName.SUCCESS);
export const DELIVERY_METHOD_UPDATE_SUCCESS = generateActionName(name, 'UPDATE', ApiSliceActionName.SUCCESS);

export const DELIVERY_METHOD_ADD_CARD_ACCOUNT_WITH_TOKEN_SUCCESS = addCardAccountWithToken.actions.success;
export const DELIVERY_METHOD_CLEAR_WHITE_PAGE_ADDRESS_CLEAR = clearWhitePageAddressSlice.actions.clear;
export const DELIVERY_METHOD_COPY_FROM_OWNED_VENDOR_SUCCESS = copyFromOwnedVendorSlice.actions.success;
export const DELIVERY_METHOD_COPY_FROM_OWNED_VENDOR_WITH_UNILATERAL_TOKEN_SUCCESS =
  copyFromOwnedVendorWithUnilateralToken.actions.success;
export const DELIVERY_METHOD_CREATE_INTERNATIONAL_SUCCESS = createInternationalDeliveryMethodSlice.actions.success;
export const DELIVERY_METHOD_FETCH_UNILATERAL_REQUEST_DETAILS_SUCCESS =
  fetchUnilateralRequestDetailsWithTokenSlice.actions.success;
export const DELIVERY_METHOD_REPLACE_WITH_TOKEN_SUCCESS = replaceDeliveryMethodSlice.actions.success;
export const DELIVERY_METHOD_REPLACE_VIRTUAL_SUCCESS = replaceVirtualDeliveryMethodSlice.actions.success;
export const DELIVERY_METHOD_SHOULD_CLOSE_VERIFY_INDICATOR_UPDATE =
  shouldCloseVerifyDeliveryMethodIndicatorSlice.actions.update;
export const DELIVERY_METHOD_UPDATE_ACH_WITH_TOKEN_SUCCESS = updateDeliveryMethodACHSlice.actions.success;
export const DELIVERY_METHOD_UPDATE_UNILATERAL_WITH_TOKEN_SUCCESS = updateWithUnilateralToken.actions.success;
export const DELIVERY_METHOD_VERIFY_MICRO_DEPOSITS_SUCCESS = verifyMicroDepositsForDeliveryMethodSlice.actions.success;
export const DELIVERY_METHOD_VALIDATE_ADDRESS_SUCCESS = whitePagesAddressSlice.actions.success;
export const DELIVERY_METHOD_SHIFT_VIRTUAL_CARD_PAYMENT_TO_ACH_SUCCESS =
  shiftVirtualCardToACHPaymentStatusCollectedSlice.actions.success;
