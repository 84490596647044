import qs from 'qs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';
import { companiesLocations } from '../locations';

export function NewCompanyErrorPage() {
  const { search } = useLocation();
  const [historyPush] = useHistoryWithOrgId();
  const { error } = qs.parse(search, { ignoreQueryPrefix: true }) as Record<string, string>;

  useEffect(() => {
    switch (error) {
      case 'create_company_email_exists':
        return historyPush({
          path: companiesLocations.create.details,
          query: { emailInUse: 'true' },
        });
      default:
        analytics.track('companies', 'accounting-software-connect-error', {
          error,
        });
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'serverErrors.ERR',
        });

        return historyPush({ path: companiesLocations.create.connectAccountingSoftware });
    }
  }, [error, historyPush]);

  return <AreaLoader />;
}
