import { featureFlags } from '@melio/shared-web';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { PayListItemType } from 'src/modules/bills/types';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { useQueryParams } from 'src/pages/pay/hooks/useQueryParams';
import { REGULAR_BATCH_PAYMENTS_BILLS_LIMIT } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { blockBatchSelectionForInternational } from 'src/pages/vendor/international-delivery-method/utils';
import { FailedInstallmentListItem } from 'src/pay/components/FailedInstallmentListItem';
import { PayCard } from 'src/pay/components/PayCard';
import { isInboxTab, shouldShowFailedInstallment } from 'src/pay/utils';
import { getFundingSources } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { deserializePaymentId, getBillItemAmount, isBillHasPartialPayments } from 'src/utils/bills';
import { BillStatus, FeatureFlags, PaymentStatus, PayTabs } from 'src/utils/consts';
import { isInProgressFinancedPayment } from 'src/utils/financing';
import { useQueryString } from 'src/utils/hooks';
import { getLatestPayment, getPaymentById } from 'src/utils/payments';
import { VendorType } from 'src/utils/types';
import { ScheduledInstallmentListItem } from './components/ScheduledInstallmentListItem';

const getBillListItemInfo = (
  status: BillStatus,
  dueDate: string,
  scheduledDate: string
): { dateLabel: string; displayDate: string } => {
  switch (status) {
    case BillStatus.SCHEDULED:
      return { dateLabel: 'bills.view.scheduledOn', displayDate: scheduledDate };
    case BillStatus.PAID:
      return { dateLabel: 'bills.view.processed', displayDate: scheduledDate };
    case BillStatus.UNPAID:
    default:
      return { dateLabel: 'bills.view.due', displayDate: dueDate };
  }
};

type Props = {
  item: PayListItemType;
  markedIds: readonly string[];
  setMarked: (id: string, value: boolean) => void;
  listItemBaseSearch?: string;
  enableBatchSelection: boolean;
  enabledSelectionItems: readonly PayListItemType[];
  onClick?: () => void;
};

export const PayCardContainer = ({
  item,
  markedIds,
  setMarked,
  listItemBaseSearch,
  enableBatchSelection,
  enabledSelectionItems,
  onClick,
}: Props) => {
  const {
    id,
    billsCount,
    vendorCompanyName,
    dueDate,
    totalAmount,
    status: billStatus,
    invoiceNumber,
    payments,
    recurringBillId,
    recurringBillIndex,
    frequency,
    occurrences,
    requestId,
    flags,
    scannedInvoiceId,
    isMissingBillDetails,
  } = item;
  const query = useQueryString<{ id; start; limit; search; selectedIds }>();
  const { paymentId } = deserializePaymentId(item.id);
  const userFundingSources = useSelector(getFundingSources);
  const deserializedPayment = payments && getPaymentById(payments, paymentId);
  const paymentToUse = deserializedPayment || (payments && getLatestPayment(payments));
  const scheduledDate = paymentToUse ? paymentToUse.scheduledDate : null;
  const itemHasPartialPayments = isBillHasPartialPayments(item);
  const vendorWithDeliveryMethods = useSelector<any, VendorType>(vendorsStore.selectors.byId(item.vendorId));

  const blockInternationalVendor = blockBatchSelectionForInternational(
    vendorWithDeliveryMethods?.deliveryMethods,
    userFundingSources
  );
  const isPartiallyPaidItemOnInboxTab = itemHasPartialPayments && !paymentId && isInboxTab(query);
  const status = paymentToUse?.status === PaymentStatus.COMPLETED ? BillStatus.PAID : billStatus;
  const { dateLabel, displayDate } = getBillListItemInfo(
    isPartiallyPaidItemOnInboxTab ? BillStatus.UNPAID : status,
    dueDate as string,
    scheduledDate
  );
  const balance = getBillItemAmount({
    bill: item,
    payment: paymentToUse,
    isBillObject: !paymentId,
    isInboxBillPartiallyPaid: isPartiallyPaidItemOnInboxTab,
  });
  const index = recurringBillIndex || null;
  const isItemCanBeSelected = find(enabledSelectionItems, (enabledBill) => enabledBill.id === item.id);
  const isItemMarked = markedIds.includes(id.toString());
  const showCheckbox = markedIds.length > 0;
  const isItemDisabled = !isItemCanBeSelected && showCheckbox && (enableBatchSelection || blockInternationalVendor);
  const isCheckboxDisabled = !isItemMarked && markedIds.length >= REGULAR_BATCH_PAYMENTS_BILLS_LIMIT;
  const enableBillSelection = isItemCanBeSelected && enableBatchSelection && !blockInternationalVendor;
  const currentBillId = query.id ? query.id : '';
  const isSelected = id.toString() === currentBillId.toString() && isEmpty(markedIds);
  const { navigate: navigateToBill } = useQueryParams();
  const [isFinancingEnabled] = featureFlags.useFeature(FeatureFlags.MelioComAPFinancingEnabled, false);
  const {
    query: { status: tab },
  } = useQueryParams<{ status: string }>();
  const baseSearch = `id=${id}${listItemBaseSearch ? `&${listItemBaseSearch}` : ''}`;

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigateToBill({ id });

      !isEmpty(markedIds) && !isItemDisabled && !isCheckboxDisabled && setMarked(id, true);
    }

    analytics.track('bills', 'nav-to-single', { status });
  };

  if (
    isFinancingEnabled &&
    tab === PayTabs.SCHEDULED &&
    item.payments?.[0] &&
    isInProgressFinancedPayment(item.payments[0])
  ) {
    return (
      <ScheduledInstallmentListItem
        key={id}
        isSelected={isSelected}
        payment={item.payments[0]}
        vendorName={vendorCompanyName as string}
        disabled={isItemDisabled}
        search={query.search}
        onClick={handleClick}
      />
    );
  }

  if (item.payments?.[0] && item.installment && shouldShowFailedInstallment(isFinancingEnabled, tab, item)) {
    return (
      <FailedInstallmentListItem
        key={id}
        isSelected={isSelected}
        installment={item.installment}
        totalNumberOfInstallments={item.totalNumberOfInstallments!}
        vendorName={vendorCompanyName as string}
        disabled={isItemDisabled}
        search={query.search}
        onClick={handleClick}
      />
    );
  }

  return (
    <PayCard
      key={id}
      id={id}
      billsCount={billsCount}
      balance={balance || 0}
      status={status}
      dateLabel={dateLabel}
      vendorName={vendorCompanyName}
      baseSearch={baseSearch}
      invoiceNumber={invoiceNumber}
      isSelected={isSelected}
      deliveryEta={paymentToUse ? paymentToUse.deliveryEta : null}
      query={query}
      payment={paymentToUse}
      isRecurringBill={!!recurringBillId}
      search={query.search}
      displayDate={displayDate}
      frequency={frequency}
      occurrences={occurrences}
      index={index}
      hasPaymentRequest={!!requestId}
      isScannedInvoice={!!scannedInvoiceId}
      showCheckbox={showCheckbox}
      setCheckboxMarked={setMarked}
      isCheckboxMarked={isItemMarked}
      isCheckboxDisabled={isCheckboxDisabled}
      enableBatchSelection={enableBillSelection}
      disabled={isItemDisabled}
      totalAmount={totalAmount}
      isPartiallyPaidItemOnInboxTab={isPartiallyPaidItemOnInboxTab}
      blockInternationalVendor={blockInternationalVendor}
      onClick={handleClick}
      billStatus={billStatus}
      flags={flags}
      isMissingBillDetails={isMissingBillDetails}
    />
  );
};
