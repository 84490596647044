import { inherits } from 'util';

type ServerErrorThisType<Req, Res> = {
  name: string;
  message: string | null;
  code: number | string | null;
  url: string | null;
  method: string | null;
  requestData: Req;
  responseData: Res;
};

type ServerErrorDataType<P, D> = {
  message: string | null;
  code: number | string | null;
  url: string | null;
  data: D;
  params: P;
  method: string | null;
  error?: string;
};

const getName = ({ method, url, code }) => [method, url && url.replace(/\d+/g, '_'), code].join(':');

export function ServerError<P, D>(
  this: ServerErrorThisType<P, D>,
  { error, message, code, url, data, params, method }: ServerErrorDataType<P, D>
) {
  this.name = this.constructor.name || getName({ method, url, code });
  this.message = error ?? message;
  this.code = code;
  this.url = url;
  this.method = method;
  this.requestData = params;
  this.responseData = data;
}

inherits(ServerError, Error);
