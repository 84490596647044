import without from 'lodash/without';
import { useEffect, useRef, useState } from 'react';
import { analytics } from 'src/services/analytics';
import { Card, CardsFilter } from 'src/services/engagement/types';

async function getCards(filterFunction?: CardsFilter): Promise<Card[]> {
  if (analytics.engagementService) {
    try {
      let cards = await analytics.engagementService.getContentCards();

      if (filterFunction) {
        cards = filterFunction(cards);
      }

      return cards;
    } catch (e) {
      return [];
    }
  }

  return [];
}

const INTERVAL_MS = 300;
const MAX_RETRIES = 10;

export function useContentCards(filterFunction?: CardsFilter) {
  const [cards, setCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadedOnce, setLoadedOnce] = useState<boolean>(false);
  const [retriesLeft, setRetriesLeft] = useState<number>(MAX_RETRIES);
  const [impressionsStatistics, setImpressionsStatistics] = useState<Record<string, string>>(
    {} as Record<string, string>
  );

  const timerRef = useRef<number>();

  const stopToRetry = (timerId) => {
    setLoadedOnce(true);

    if (timerId) {
      window.clearTimeout(timerId);
    }
  };

  useEffect(() => {
    async function fetchAndRetry() {
      const shouldRetry = !loadedOnce && retriesLeft > 0 && cards.length === 0;

      if (shouldRetry) {
        timerRef.current = window.setTimeout(async () => {
          setLoading(true);
          const contentCards = await getCards(filterFunction);

          if (contentCards.length > 0) {
            setCards(contentCards);
            stopToRetry(timerRef.current);
          }

          setRetriesLeft((retries) => retries - 1);
          setLoading(false);
        }, INTERVAL_MS);
      } else {
        stopToRetry(timerRef.current);
      }
    }

    fetchAndRetry();

    return () => {
      window.clearTimeout(timerRef.current);
    };
  }, [retriesLeft, cards, filterFunction, loadedOnce]);

  useEffect(
    () => () => {
      setImpressionsStatistics({} as Record<string, string>);
    },
    []
  );

  const logClick = (card: Card) => {
    analytics.engagementService?.logCardClick(card);
  };

  const handleCloseAndDismiss = (card: Card) => {
    setCards((cardsToFilter) => without(cardsToFilter, card));

    if (analytics.engagementService) {
      analytics.engagementService.dismissContentCard(card);
    }
  };

  const logCardImpressions = (card: Card) => {
    if (analytics.engagementService && !impressionsStatistics[card.id]) {
      analytics.engagementService.logCardImpressions(card);
      setImpressionsStatistics((prev) => ({ ...prev, [card.id]: card.id }));
    }
  };

  return { cards, loading, logClick, handleCloseAndDismiss, logCardImpressions };
}
