import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { EditElement } from 'src/components/layout/PaymentActivityElements';
import { useApi } from 'src/hoc/useApi';
import { useGetOrganizationBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetOrganizationBillingFees';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';
import { getOrgId } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import { canPartiallyPayBill } from 'src/utils/bills';
import { DeliveryType, FundingType, PaymentApprovalStatus } from 'src/utils/consts';
import {
  AccountType,
  BillType,
  DeliveryMethodType,
  DeliveryOptionType,
  PaymentFinancing,
  PaymentType,
} from 'src/utils/types';
import { ActivityActionsType, PayBillPaymentActivity } from '../PayBillPaymentActivity/PayBillPaymentActivity';
import { PaymentFee } from '../PaymentFee';
import { PayBillDetails } from './components/PayBillDetails/PayBillDetails';
import { useFinancingPaymentReview } from './hooks/useFinancingPaymentReview';

type Props = {
  bill: BillType;
  fee?: PaymentFeeInfo | null;
  fundingSource: AccountType;
  payment: PaymentType;
  deliveryMethod: DeliveryMethodType;
  activityActions: ActivityActionsType;
  isVendorAbsorbedFee?: boolean;
  isRecurring?: boolean;
  deliveryOptions?: DeliveryOptionType[];
  isEditDisabled: boolean;
};

type ApprovalDecisionStatusResponse = {
  approvalDecisionStatus: PaymentApprovalStatus;
};

export const PayBillPaymentReview = ({
  fee,
  bill,
  fundingSource,
  payment,
  deliveryMethod,
  activityActions,
  isVendorAbsorbedFee,
  isRecurring = false,
  deliveryOptions,
  isEditDisabled,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: getApprovalDecisionStatus } = useApi<
    Parameters<typeof deliveryApi['getApprovalDecisionStatus']>,
    ApprovalDecisionStatusResponse
  >({
    api: deliveryApi.getApprovalDecisionStatus,
  });
  const { organizationBillingFees } = useGetOrganizationBillingFees();
  const totalAmount = payment.amount?.toString() ?? '';

  const isVendorAbsorbedCardFee = isVendorAbsorbedFee && fundingSource?.fundingType === FundingType.CARD;
  const isInternationalPayment = deliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL;
  const { onEditPartialAmount } = activityActions;
  const canEditPaymentAmount = canPartiallyPayBill(bill, isRecurring, !!payment?.id);

  useEffect(() => {
    getApprovalDecisionStatus(orgId, totalAmount);
  }, [orgId, totalAmount, getApprovalDecisionStatus]);

  const { financing, loading: isFinancingLoading, isFinancingPayment } = useFinancingPaymentReview({ payment });

  return (
    <MainBox className="review" data-testid="payment-review-container">
      <BaseContainer>
        <HighlightedHeader>
          <SubTitle>
            <MIFormattedText label="bills.pay.confirm.total" />
          </SubTitle>
          <AmountContainer data-testid="amount-container">
            <StyledMIMoney size="large" amount={totalAmount} privateData />
            {canEditPaymentAmount && (
              <EditElement
                testId="edit-payment-amount-button"
                onEdit={onEditPartialAmount}
                isDisabled={isEditDisabled}
              />
            )}
          </AmountContainer>
        </HighlightedHeader>
      </BaseContainer>
      <PayBillDetails bill={bill} payment={payment} />
      <PaymentActivityContainer>
        <PayBillPaymentActivity
          bill={bill}
          fundingSource={fundingSource}
          payment={payment}
          activityActions={activityActions}
          deliveryMethod={deliveryMethod}
          isFinancingPayment={isFinancingPayment}
          financing={isFinancingPayment ? (financing as PaymentFinancing) : null}
          isFinancingLoading={isFinancingLoading}
          deliveryOptions={deliveryOptions}
          isEditDisabled={isEditDisabled}
        />
      </PaymentActivityContainer>
      {fee ? (
        <PaymentFee
          fee={fee}
          fundingSource={fundingSource}
          isVendorAbsorbedCardFee={isVendorAbsorbedCardFee}
          isInternationalPayment={isInternationalPayment}
          deliveryMethodType={deliveryMethod.deliveryType}
          isRecurring={isRecurring}
          organizationBillingFees={organizationBillingFees}
        />
      ) : null}
    </MainBox>
  );
};

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  text-align: left;

  border-radius: 0.9rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.14);

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) => props.theme?.components?.BillPaymentReview?.MainBox}
`;

const StyledMIMoney = styled(MIMoney)`
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme?.components?.BillPaymentReview?.StyledMIMoney}
`;

const HighlightedHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  box-sizing: border-box;
  width: 100%;
  ${(props) => props.theme?.components?.BillPaymentReview?.HighlightedHeader}
`;

const AmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BaseContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 0 2rem 2rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border.darkGrey};
  ${(props) => props.theme?.components?.BillPaymentReview?.BaseContainer}
`;

const PaymentActivityContainer = styled(BaseContainer)`
  padding-bottom: 0.5rem;
  ${(props) => props.theme?.components?.BillPaymentReview?.PaymentActivityContainer}
`;

const SubTitle = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  margin-bottom: 0.6rem;
  text-transform: uppercase;

  ${(props) => props.theme?.components?.BillPaymentReview?.SubTitle}
`;
