import isEmpty from 'lodash/isEmpty';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { isCardExpired } from 'src/utils/card';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSources: readonly AccountType[];
  selectedFundingSourceId: number;
  onNext: () => void;
  onPrev: () => void;
  handleSelectedFundingSource: (value: AccountType) => void;
  goAddSelectedFundingSource: (value: string) => void;
  totalAmount: string;
  companyName: string;
  isVendorEnableCCPayments: boolean;
  blockCCMoneyIn: boolean;
  isVendorAbsorbedFee?: boolean;
};

const GuestEditFundingSourcesPage = ({
  fundingSources,
  handleSelectedFundingSource,
  selectedFundingSourceId,
  onNext,
  onPrev,
  goAddSelectedFundingSource,
  totalAmount,
  companyName,
  isVendorEnableCCPayments,
  blockCCMoneyIn,
  isVendorAbsorbedFee,
}: Props) => {
  const { StepLayout } = useSiteContext().components;
  const filteredFundingSources = fundingSources.filter(
    (fs) => !(fs.fundingType === 'card' && isCardExpired(fs.cardAccount))
  );

  return (
    <StepLayout
      title="bills.pay.fundingSource.title"
      subtitle="bills.pay.fundingSource.subtitle"
      subTitleValues={{
        amount: (
          <strong>
            <MIFormattedCurrency value={totalAmount ? totalAmount.toString() : ''} />
          </strong>
        ),
        companyName: <strong>{companyName}</strong>,
      }}
      relativeStep={3 / 6}
      innerSize={50}
      onNext={onNext}
      isNextDisabled={!selectedFundingSourceId}
      isHideActions={isEmpty(fundingSources)}
      onPrev={onPrev}
    >
      <FundingSourcesListLayout
        fundingSources={filteredFundingSources}
        blockCCMoneyIn={blockCCMoneyIn}
        value={fundingSources.find((fs) => fs.id === selectedFundingSourceId)}
        onChange={handleSelectedFundingSource}
        onAddMethod={goAddSelectedFundingSource}
        isVendorEnableCCPayments={isVendorEnableCCPayments}
        isVendorAbsorbedFee={isVendorAbsorbedFee}
      />
      <MISecurityDetails eventPage="payor" />
    </StepLayout>
  );
};

export default GuestEditFundingSourcesPage;
