import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { getInvitationActions } from 'src/modules/invitations/invitations-store';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { InvitationType } from 'src/utils/types';
import { getFullName } from 'src/utils/user';

type Props = {
  dismiss: (event: React.MouseEvent) => void;
  invitation: InvitationType;
  orgId: number;
};

export const RemoveInvitationModal = ({ dismiss, invitation, orgId }: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const invitationActions = getInvitationActions(useDispatch());

  const displayFirstName = invitation.firstName || invitation.lastName;
  const fullName = getFullName(invitation.firstName, invitation.lastName);

  const titleLabel = fullName ? 'team.modals.removeInvitation.title' : 'team.modals.removeInvitation.titleNoName';
  const textLabel = fullName ? 'team.modals.removeInvitation.text' : 'team.modals.removeInvitation.textNoName';

  const handleRemoveClick = async (event) => {
    setIsLoading(true);
    analytics.trackAction('remove-invitation-submit', {
      invitation,
    });
    await invitationActions.delete({ orgId, id: invitation.id });
    dismiss(event);
  };

  const handleCancelClick = (event: React.MouseEvent) => {
    dismiss(event);
  };

  return (
    <ModalMessage
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label={titleLabel} values={{ fullName }} />
        </ModalTitle>
      }
      contentComponent={
        <Box textStyle="body2">
          <MIFormattedText label={textLabel} values={{ firstName: displayFirstName }} />
        </Box>
      }
      buttonComponent={
        <ModalButtonsWrapper>
          <ButtonsContainer>
            <Button
              onClick={handleCancelClick}
              variant={ButtonVariants.tertiaryNaked}
              size={ButtonSizes.md}
              label="team.modals.removeInvitation.buttons.cancel"
            />
            <Button
              boxSizing="border-box"
              onClick={handleRemoveClick}
              isLoading={isLoading}
              size={ButtonSizes.md}
              width={['100%', null, 'auto']}
              label="team.modals.removeInvitation.buttons.remove"
            />
          </ButtonsContainer>
        </ModalButtonsWrapper>
      }
    />
  );
};

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media ${devices.mobile}, ${devices.phablet} {
    flex-direction: column;
  }
`;
