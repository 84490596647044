import isEmpty from 'lodash/fp/isEmpty';
import take from 'lodash/fp/take';
import { billsApi } from 'src/modules/bills/api';
import { paymentRequestsApi } from 'src/modules/payment-requests/api';
import { VendorPaymentPreferencesType } from 'src/modules/vendors/check-vendor-payment-preferences';
import { billLocations } from 'src/pages/bill/locations';
import { REGULAR_BATCH_PAYMENTS_BILLS_LIMIT } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { convertPaymentRequestToBill, getVendorBillSearchTab } from 'src/utils/bills';
import { BillStatus, PaymentRequestApprovalStatus, PaymentRequestStatus } from 'src/utils/consts';
import { AccountType, BillsCountByPaymentStatusResponse, BillType, PaymentRequestType } from 'src/utils/types';

const isDeniedPaymentRequest = (paymentReq: PaymentRequestType): boolean =>
  paymentReq.approvalStatus === PaymentRequestApprovalStatus.DENIED;

export const shouldShowPayUnpaidBills = (
  unpaidBillsIds: readonly string[],
  fundingSources: readonly AccountType[],
  vendorPaymentPreferences?: VendorPaymentPreferencesType
): boolean => {
  const isPaymentsToVendorBlocked = vendorPaymentPreferences?.blockPayments;

  return !isPaymentsToVendorBlocked && !isEmpty(fundingSources) && !isEmpty(unpaidBillsIds);
};

export const getUnpaidBillsEligibleForPayment = (
  unpaidBills: BillType[],
  paymentRequests: PaymentRequestType[],
  vendorId: number,
  companyName: string
): BillType[] => {
  const billsFromRequests = paymentRequests
    .filter((paymentReq) => paymentReq.vendorId === vendorId)
    .filter((paymentReq) => !isDeniedPaymentRequest(paymentReq))
    .map((paymentReq) => convertPaymentRequestToBill({ ...paymentReq, companyName }));

  return take(REGULAR_BATCH_PAYMENTS_BILLS_LIMIT, [...unpaidBills, ...billsFromRequests]);
};

export const getStatusViewUrl = (vendorId: number, status: BillStatus) => ({
  pathname: billLocations.index,
  search: getVendorBillSearchTab(status, vendorId),
});

interface UnpaidBillsResources {
  unpaidBills: BillType[];
  paymentRequests: PaymentRequestType[];
}

export const getUnpaidBillsResources = async (orgId: number, vendorId: number): Promise<UnpaidBillsResources> => {
  const { requests } = await paymentRequestsApi.getPaymentRequests({
    orgId,
    filters: { limit: 100, status: PaymentRequestStatus.PENDING, getBy: 'byOrg' },
  });
  const billsResult = await billsApi.getBillList({
    orgId,
    filters: { limit: 40, vendorId, status: 'inbox' },
  });

  return { unpaidBills: billsResult.objects, paymentRequests: requests };
};

export const getUnpaidBillsLabel = (isDesktop: boolean, showUnpaidBillsEmptyState: boolean) => {
  if (showUnpaidBillsEmptyState) {
    return 'vendors.paymentsOverview.unpaidBillsEmptyState';
  }

  if (isDesktop) {
    return 'vendors.paymentsOverview.billsCount';
  }

  return 'vendors.paymentsOverview.billsCountMobile';
};

export const getViewBillsLinkLabel = (status: BillStatus) => {
  switch (status) {
    case BillStatus.UNPAID:
      return 'contacts.view.viewLink.unpaid';
    case BillStatus.SCHEDULED:
      return 'contacts.view.viewLink.scheduled';
    case BillStatus.PAID:
      return 'contacts.view.viewLink.paid';
    default:
      return '';
  }
};

export const getUnpaidBillsCount = (vendorBillsByPaymentStatus: BillsCountByPaymentStatusResponse) =>
  vendorBillsByPaymentStatus.pending.count +
  vendorBillsByPaymentStatus.paymentRequests.count +
  vendorBillsByPaymentStatus.approvalDeclined.count;

export const getUnpaidBillsSum = (vendorBillsByPaymentStatus: BillsCountByPaymentStatusResponse) =>
  vendorBillsByPaymentStatus.pending.sum +
  vendorBillsByPaymentStatus.paymentRequests.sum +
  vendorBillsByPaymentStatus.approvalDeclined.sum;
