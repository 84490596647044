import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import MISingleSelect from 'src/components/common/MISingleSelect';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useOrgId, usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { accountingPlatformBankAccountsStore } from 'src/modules/accounting-platform-bank-accounts/accounting-platform-bank-accounts-store';
import { useFetchAccountingPlatformBankAccounts } from 'src/modules/accounting-platform-bank-accounts/hooks/useFetchAccountingPlatformBankAccounts';
import { useMarkableBills } from '../../BillsGallery/components/useMarkableBills';
import { PartialBillsWarning } from './PartialBillsWarning';

export interface AccountPlatformModalContentProps {
  id: string;
  billIds: string[];
  onConfirm: (accountId?: string) => void;
  isLoading: boolean;
  isPartialBatch: boolean;
  dismiss: () => void;
}

export const AccountPlatformModalContent = ({
  id,
  dismiss,
  billIds = [],
  onConfirm,
  isLoading,
  isPartialBatch,
}: AccountPlatformModalContentProps) => {
  const orgId = useOrgId();
  useFetchAccountingPlatformBankAccounts({ forceReload: false });
  const { markableBills } = useMarkableBills(billIds);
  const bankAccs = useSelector((state) => accountingPlatformBankAccountsStore.selectors.list.value(state, { orgId }));
  const { accountingPlatformName } = usePayablesConnectedAccountingPlatform();

  const [accountId, setAccountId] = useState<string | undefined>(undefined);

  const onAccountChange = (account) => {
    setAccountId(account.value);
  };

  const handleButtonConfirm = () => {
    onConfirm(accountId);
  };

  const accountsDropdown = (
    <Flex flexDir="column">
      <Box mb={isPartialBatch ? 5 : 16}>
        <MIFormattedText
          label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.isConnectedSubtitle"
          values={{ accountingPlatformName: <MIFormattedText label={accountingPlatformName} /> }}
        />
      </Box>
      <MISingleSelect
        required
        label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.accountDropdownLabel"
        placeholder="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.accountDropdownPlaceholder"
        labelValues={{ accountingPlatformName: <MIFormattedText label={accountingPlatformName} /> }}
        id="mark-bills-as-paid-accounts-dropdown"
        testId="mark-bills-as-paid-accounts-dropdown"
        onChange={onAccountChange}
        options={bankAccs.map((acc) => ({ value: acc.id, label: acc.name }))}
        value={accountId}
        isLoading={bankAccs.length === 0}
        isDisabled={isLoading}
        menuPortalTarget={document.body}
      />
    </Flex>
  );

  return (
    <ModalMessage
      id={id}
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText
            label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.title"
            values={{ billsCount: markableBills.length }}
          />
        </Box>
      }
      onCloseClick={dismiss}
      contentComponent={
        <>
          {isPartialBatch && <PartialBillsWarning billIds={billIds} />}
          {accountsDropdown}
        </>
      }
      buttonComponent={
        <Flex justifyContent="end">
          <Button
            onClick={dismiss}
            label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.buttonCancel"
            disabled={isLoading}
            variant={ButtonVariants.tertiaryNaked}
          />
          <Button
            onClick={handleButtonConfirm}
            label="regularBatchPayments.BillsGallery.MarkBillAsPaidModal.buttonLabel"
            testId="mark-bills-as-paid-modal-confirm-button"
            values={{ billsCount: markableBills.length }}
            isLoading={isLoading}
            disabled={!accountId}
          />
        </Flex>
      }
    />
  );
};
