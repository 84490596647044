import { CSSObject } from 'src/core/ds';

const MIN_TABLE_HEIGHT = '50vh';

export const getContainerStyle = (showPagination: boolean): CSSObject => ({
  flex: 1,
  width: 'full',
  overflow: 'auto',
  ...(!showPagination && { borderTopRadius: 'lg' }),
  borderBottomRadius: 'lg',
  minH: MIN_TABLE_HEIGHT,
  filter: 'drop-shadow(0px 5px 15px rgba(33, 33, 36, 0.1))',
  caption: {
    backgroundColor: 'white',
    marginTop: 0,
    height: 'full',
  },
  table: {
    position: 'relative',
    overflow: 'auto',
  },
  tr: {
    _hover: {
      cursor: 'pointer',
    },
    td: {
      alignSelf: 'center',
      borderBottom: 'none',
      '&:last-of-type': {
        borderRight: 'none !important',
      },
      '&:first-of-type': {
        borderLeft: 'none !important',
      },
    },
    th: {
      backgroundColor: 'grey.200',
      display: 'table-cell',
      alignSelf: 'center',
      borderBottom: 'none',
      zIndex: 10,
      '>div': {
        display: 'flex',
        height: '4.9rem',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: 'grey.300',
        borderTop: 'none',
        borderBottom: '1px',
        backgroundColor: 'white',
        '>div': {
          border: 'none',
          color: 'black',
        },
      },
    },
  },
});

export const emptyStateStyle = {
  display: 'flex',
  minHeight: MIN_TABLE_HEIGHT,
  py: 8,
  justifyContent: 'center',
  maxW: '40rem',
  m: '0 auto',
};

export const paginationStyle = {
  borderBottom: 'solid 1px',
  borderBottomColor: 'grey.300',
  backgroundColor: 'white',
  borderTopLeftRadius: 'lg',
  borderTopRightRadius: 'lg',
  p: 4,
};
