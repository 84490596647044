import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useUserPreference } from 'src/modules/users/hooks/useUserPreference';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { UserPreferencesKey } from 'src/utils/consts';

export const INSTALLMENT_METHOD_DISABLED_COUNT = 2;

export const useFinancingFundingSourceDisabledViewsCount = () => {
  const isUserLoggedIn = useSelector(getIsLoggedIn);

  const { userPreferenceValue: viewCount, setUserPreferenceValue: setCount } = useUserPreference({
    userPreferenceKey: UserPreferencesKey.financingFundingSourceDisabledViewsCount,
  });

  const countValue = viewCount || 0;

  const increaseFinancingFundingSourceDisabledViewsCount = useCallback(() => {
    const count = countValue ? +countValue : 0;

    if (!isUserLoggedIn || count >= INSTALLMENT_METHOD_DISABLED_COUNT) {
      return;
    }

    setCount((count + 1).toString());
  }, [setCount, viewCount, isUserLoggedIn]);

  return {
    increaseFinancingFundingSourceDisabledViewsCount,
    financingFundingSourceDisablediewsCount: viewCount ? +viewCount : 0,
  };
};
