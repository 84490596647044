import { ManualAddressType } from 'src/components/common/ManualAddress/ManualAddressOptionsContainer';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { BackButton, ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { useWhitePageAction } from 'src/pages/vendor/hoc/white-page-hooks';
import { ModelView } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { DeliveryType } from 'src/utils/consts';
import { AddressType, EditableDeliveryMethodType } from 'src/utils/types';

type Props = {
  setWhitePageAddressView: (value: boolean) => void;
  whitePageAddress: ManualAddressType;
  model: ModelView<AddressType>;
  isLoading: boolean;
  submit: (deliveryMethod: EditableDeliveryMethodType, isAddressVerified: boolean) => Promise<void>;
  deliveryMethodType?: DeliveryType;
};

function WhitePageAddressContainer(props: Props) {
  const { isLoading, setWhitePageAddressView } = props;
  const [confirmAddress, renderWhitePageAddress] = useWhitePageAction(props);

  return (
    <OutsideLayout>
      <Flex
        direction="column"
        bgColor="white"
        pos="relative"
        borderTopRadius="lg"
        pt={10}
        pb={{ base: 10, sm: 3 }}
        px={{ base: 5, sm: 10 }}
      >
        <BackButton onPrev={() => setWhitePageAddressView(false)} />
      </Flex>
      <ContentWrapper title="vendors.addDeliveryMethodByLink.manualAddressTitle">
        <Box mt={3} mb={10} textStyle="body2">
          <MIFormattedText label="vendors.addDeliveryMethodByLink.manualAddressSubTitle" />
        </Box>
        {renderWhitePageAddress()}
        <FormRow>
          <Button
            onClick={confirmAddress}
            label="vendors.addDeliveryMethodByLink.manualAddressCTA"
            data-testid="vendors.addDeliveryMethodByLink.manualAddressCTA"
            size={ButtonSizes.lg}
            isFullWidth
            isLoading={isLoading}
            mt={5}
          />
        </FormRow>
      </ContentWrapper>
    </OutsideLayout>
  );
}

export default WhitePageAddressContainer;
