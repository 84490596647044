import { SettingRouteType, SettingsPagesRouteType } from '../types';

function filterSettingsItem(item: SettingsPagesRouteType | SettingRouteType): boolean {
  return item.shouldRender;
}

export function settingsPagesFilter(settingsItems: SettingsPagesRouteType[]): SettingsPagesRouteType[] {
  const filteredSettingsGroups = settingsItems.filter(filterSettingsItem);

  return filteredSettingsGroups.map(({ routes, ...groupItem }) => ({
    ...groupItem,
    routes: routes.filter(filterSettingsItem),
  }));
}
