import { AxiosRequestConfig } from 'axios';
import {
  DirectoryDetailsType,
  GetBillerMelioDirectoryParams,
  GetBusinessMelioDirectoryParams,
} from 'src/pages/vendor-directory/select-vendor/types';
import { fetchRequest, postRequest } from './api';

export const directoryApi = {
  search({ orgId, name, conf }: { orgId: number; name: string; conf?: AxiosRequestConfig }) {
    const url = `/orgs/${orgId}/directory`;

    return fetchRequest(url, { name }, { isBypassThrowError: false }, conf);
  },

  validate({ orgId, billerId, accountNumber }: { orgId: number; billerId: string; accountNumber: number }) {
    const url = `/orgs/${orgId}/directory/biller/validate`;

    return postRequest(url, { billerId, accountNumber });
  },

  getBiller({ orgId, billerId }: GetBillerMelioDirectoryParams) {
    const url = `/orgs/${orgId}/directory/biller/${billerId}`;

    return fetchRequest(url);
  },

  getBusiness({ orgId, vendorId }: GetBusinessMelioDirectoryParams): Promise<{ business: DirectoryDetailsType }> {
    const url = `/orgs/${orgId}/directory/business`;

    return fetchRequest(url, { vendorId });
  },

  getSuggestedBillers({ orgId }: { orgId: number }) {
    const url = `/orgs/${orgId}/directory/suggested`;

    return fetchRequest(url, {});
  },
};
