import { Link } from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, NavLink } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useModal } from 'src/helpers/react/useModal';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useBreak } from 'src/hoc';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { profileStore } from 'src/modules/profile/profile-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { billLocations } from 'src/pages/bill/locations';
import { NewVendorForm } from 'src/pages/contacts/create/components/NewVendorForm';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { DuplicateVendorNameModal } from 'src/pages/vendor-directory/DuplicateVendorNameModal/DuplicateVendorNameModal';
import { VendorOptionType } from 'src/pages/vendor-directory/select-vendor/types';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { CompanyFormPage, DirectoryType, SelectVendorType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { NavigationCallbacks, VendorType } from 'src/utils/types';
import createContactLocations from './locations';

type EditVendorModelType = Partial<VendorType> & {
  currentOption?: VendorOptionType;
};

const eventPage = 'contacts';

export const NewVendorPage = ({ onNext, onPrev, goExit }: NavigationCallbacks) => {
  const { isMobile } = useBreak();
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const [origin] = useLocationState('origin');
  const { selectors } = vendorsStore;
  const vendorsActions = getStoreActions(vendorsStore)(useDispatch());
  const createStatus = useSelector(selectors.create.status());
  const isCreating = createStatus?.loading;

  const [duplicateDirectoryVendorNameError, setDuplicateDirectoryVendorNameError] = useState<boolean>(false);
  const [duplicateVendorNameModalView, showDuplicateVendorNameModal] = useModal(DuplicateVendorNameModal, {
    modalName: 'duplicate-vendor-name',
  });

  let primaryButtonClicked = true;

  const contact = useMemo<EditVendorModelType>(
    () => ({
      contactEmail: '',
      contactName: '',
      contactPhone: '',
      accountIdentifier: '',
      address: undefined,
      currentOption: undefined,
      companyName: '',
    }),
    []
  );

  const [contactDataMV, newContactActions] = useForm(contact, {
    submit: async ({ contactEmail, contactName, contactPhone, accountIdentifier, address, currentOption }) => {
      const isBiller = currentOption?.directoryType === DirectoryType.Biller;

      await createVendor({
        contactEmail,
        contactName,
        contactPhone,
        accountIdentifier,
        address,
        // TODO: Remove in this task https://linear.app/meliopayments/issue/PAID-2979/web-or-cleanup-billerid-leftovers
        ...(isBiller ? { billerId: currentOption?.directoryId } : {}),
        directoryId: currentOption?.directoryId,
        directoryType: currentOption?.directoryType,
        companyName: currentOption?.label,
      });
    },
  });

  const isDirectorySelected = useMemo(() => contactDataMV.currentOption.value?.type === SelectVendorType.DIRECTORY, [
    contactDataMV.currentOption.value,
  ]);

  const createVendor = async (values) => {
    try {
      const {
        payload: { id },
      } = await vendorsActions.create(
        { orgId, ...values },
        { disableNotification: CompanyFormPage.ONBOARDING === origin }
      );

      if (isDirectorySelected && primaryButtonClicked) {
        if (CompanyFormPage.ONBOARDING === origin) {
          historyPush({ path: onboardingLocations.addVendor.added, params: { vendorId: id } });
        } else {
          historyPush({ path: billLocations.create.index, query: { vendorId: id, manually: 'true' } });
        }
      } else {
        onNext &&
          onNext({
            id,
            primaryButtonClicked,
            origin,
          });
      }
    } catch (error: any) {
      if (values.billerId && error.error.code === 'VERR') {
        setDuplicateDirectoryVendorNameError(true);
        const eventProperties = {
          billerId: values.billerId,
          oldVendorName: values.companyName,
        };
        analytics.track(eventPage, 'show-duplicate-name-modal', eventProperties);
        showDuplicateVendorNameModal({
          vendorName: contactDataMV.currentOption?.value?.label,
          onClickSave: async (newVendorName) => {
            analytics.track(eventPage, 'duplicate-name-modal-click-save', {
              ...eventProperties,
              newVendorName,
            });
            await createVendor({
              ...values,
              companyName: newVendorName,
            });
          },
          onClickCancel: () => {
            analytics.track(eventPage, 'duplicate-name-modal-cancel', eventProperties);
          },
        });
      } else {
        setDuplicateDirectoryVendorNameError(false);
        throw error;
      }
    }
  };

  const nextLabel = () => {
    if (CompanyFormPage.ONBOARDING === origin) {
      return 'contacts.create.vendors.addAndContinueAction';
    }

    return isDirectorySelected
      ? 'contacts.create.vendors.addAndAddBill'
      : 'contacts.create.vendors.addAndContinueAction';
  };

  const importVendors = (
    <Link
      as={NavLink}
      to={generatePath(createContactLocations.batchUploadVendorsExplain, { orgId })}
      data-testid="link-contacts.create.vendors.subtitleUploadLink"
      onClick={() => {
        analytics.track('contacts_create', 'import-vendors');
      }}
    >
      <MIFormattedText label="contacts.create.vendors.subtitleUploadLink" />
    </Link>
  );

  return (
    <StepLayoutPage
      title="contacts.create.vendors.title"
      nextLabel={nextLabel()}
      fullWidthCTA
      onSubmit={() => {
        primaryButtonClicked = true;
        newContactActions.submit();
      }}
      onPrev={onPrev}
      goExit={goExit}
      isLoading={isCreating}
      secondaryActionButtonLabel={isDirectorySelected ? 'contacts.create.vendors.addAndCloseAction' : undefined}
      onSecondaryActionButtonClick={() => {
        primaryButtonClicked = false;
        newContactActions.submit();
      }}
      subtitle={!isMobile ? 'contacts.create.vendors.subtitle' : undefined}
      subTitleValues={{
        uploadVendorsLink: importVendors,
      }}
      testId="new-vendor"
    >
      <NewVendorForm
        duplicateDirectoryVendorNameError={duplicateDirectoryVendorNameError}
        duplicateVendorNameModalView={duplicateVendorNameModalView}
        onNext={onNext}
        contactDataMV={contactDataMV}
        eventPage={eventPage}
        orgId={orgId}
        primaryButtonClicked={primaryButtonClicked}
      />
    </StepLayoutPage>
  );
};
