/* eslint-disable no-template-curly-in-string, max-len */
import { mapApiSlice } from 'src/services/analytics/event-mapping-utils';
import { EventMappingsTree } from 'src/services/analytics/types';

const MAPPING: EventMappingsTree = {
  '/quickbooks-multi-org-onboarding/company-type': {
    'page.view': ['multiorg-onboarding', 'multiorg-onboarding-company-type'],
    'select-category': ['multiorg-onboarding-company-type', 'select-type-${value}'],
    'progress.continue': ['multiorg-onboarding-company-type', 'continue'],
  },
  '/quickbooks-multi-org-onboarding/companies-list': {
    'page.view': ['multiorg-onboarding', 'multiorg-onboarding-companies-list'],
    'multiOrgOnboarding.companiesList.done': ['multiorg-onboarding-companies-list', 'continue'],
  },
  '/quickbooks-multi-org-onboarding/connect-quickbooks': {
    'page.view': ['companies', 'add-accounting-firm-connect-quickbooks'],
    'multiOrgOnboarding.connectQuickbooks.no': ['add-accounting-firm-connect-quickbooks', 'add-manually'],
    'multiOrgOnboarding.connectQuickbooks.connect': [
      'add-accounting-firm-connect-quickbooks',
      'connect',
      { currentPage: 'eventPage' },
    ],
  },
  '/quickbooks-multi-org-onboarding/quickbooks-success': {
    'page.view': ['companies', 'add-accounting-firm-quickbooks-connected-success'],
    'redirect-to-onboarding-after-qb': [
      'add-accounting-firm-quickbooks-connected-success',
      'redirect-to-onboarding-after-qb',
      { missedFields: 'missedFields' },
    ],
  },
  '/quickbooks-multi-org-onboarding/quickbooks-error': {
    'page.view': ['companies', 'add-accounting-firm-quickbooks-connected-error'],
  },
  '/quickbooks-multi-org-onboarding/add-accounting-firm/details': {
    'page.view': ['companies', 'add-accounting-firm-details'],
    'go.back': ['add-accounting-firm-details', 'back'],
    'progress.continue': ['add-accounting-firm-details', 'continue'],
    'form-validation-error': [
      'add-accounting-firm-details',
      'validation-error',
      { validationsErrors: 'validationErrors' },
    ],
  },
  '/quickbooks-multi-org-onboarding/add-accounting-firm/company-name': {
    'page.view': ['companies', 'add-accounting-firm-company-name'],
    'go.back': ['add-accounting-firm-company-name', 'back'],
    'progress.continue': ['add-accounting-firm-company-name', 'continue'],
    'form-validation-error': [
      'add-accounting-firm-company-name',
      'validation-error',
      { validationsErrors: 'validationErrors' },
    ],
  },
  '/quickbooks-multi-org-onboarding/add-accounting-firm/address': {
    'page.view': ['companies', 'add-accounting-firm-address'],
    'go.back': ['add-accounting-firm-address', 'back'],
    'progress.continue': ['add-accounting-firm-address', 'continue'],
    'form-validation-error': [
      'add-accounting-firm-address',
      'validation-error',
      { validationsErrors: 'validationErrors' },
    ],
  },
  '/quickbooks-multi-org-onboarding/add-accounting-firm/manual-address': {
    'page.view': ['companies', 'add-accounting-firm-manual-address'],
    'progress.continue': ['add-accounting-firm-manual-address', 'continue'],
    'form-validation-error': [
      'add-accounting-firm-manual-address',
      'validation-error',
      { validationsErrors: 'validationErrors' },
    ],
  },
  '/quickbooks-multi-org-onboarding/add-accounting-firm/clients-amount': {
    'page.view': ['companies', 'add-accounting-firm-clients-amount'],
    'go.back': ['add-accounting-firm-clients-amount', 'back'],
    'progress.continue': ['add-accounting-firm-clients-amount', 'continue'],
    'progress.skip': ['add-accounting-firm-clients-amount', 'skip'],
    ...mapApiSlice('[ORGANIZATIONS] CREATE', 'multiorg-onboarding-add-accounting-firm', 'continue'),
  },
};

export default MAPPING;
