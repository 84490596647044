import * as React from 'react';
import styled from 'styled-components';
import { DropDownContainer, SingleSelectContainer } from 'src/components/common/DropDown/MIDropDown';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { TextInputSize } from 'src/utils/consts';

type Props = {
  text?: React.ReactNode;
  forceOpen?: boolean;
  children: React.ReactNode;
  errorMessage?: string;
};

export const SimpleDropdown = ({ text = '', children, errorMessage, forceOpen = false }: Props) => {
  const [isOpen, setOpen] = React.useState(false);
  const prevForceOpenRef = React.useRef(false);

  React.useEffect(() => {
    const prevForceOpenValue = prevForceOpenRef.current;
    prevForceOpenRef.current = forceOpen;

    if (forceOpen && !prevForceOpenValue) {
      setOpen(forceOpen);
    }
  }, [forceOpen]);

  const handleClickOutside = React.useCallback(() => setOpen(false), []);
  const toggle = React.useCallback(() => setOpen((o) => !o), []);

  let borderBottomColor = isOpen ? 'black' : 'grey.500';

  if (errorMessage) {
    borderBottomColor = 'red.500';
  }

  return (
    <SingleSelectContainer handleClickOutside={handleClickOutside}>
      <Box
        data-testid="PaymentCreatorsDropdown-dropdown-input"
        height="3.1rem"
        textStyle="body2"
        color="black"
        borderBottom="2px"
        borderBottomColor={borderBottomColor}
        onClick={toggle}
        position="relative"
        overflow="hidden"
        display="flex"
      >
        {text}
        <Box pr={2} position="absolute" right={0} top={2}>
          <Icon name={isOpen ? IconNames.caretUp : IconNames.caretDown} size={IconSize.m} />
        </Box>
      </Box>
      <StyledDropDownContainer
        size={TextInputSize.INLINE}
        hidden={!isOpen}
        data-testid="PaymentCreatorsDropdown-dropdown-options"
      >
        {children}
      </StyledDropDownContainer>
    </SingleSelectContainer>
  );
};

const StyledDropDownContainer = styled(DropDownContainer)`
  max-height: 25rem;
  overflow: auto;
`;
