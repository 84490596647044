import { Divider, DividerProps } from '@chakra-ui/react';

export type Props = DividerProps &
  Omit<DividerProps, 'size'> & {
    size?: 'sm' | 'md';
    color?: 'grey.300' | 'grey.400' | 'grey.500';
  };

export const Separator = ({ color, size, orientation = 'horizontal', ...rest }: Props) => (
  <Divider {...rest} borderColor={color} size={size} orientation={orientation} />
);
