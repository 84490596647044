import { Draft, PayloadAction } from '@reduxjs/toolkit';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createSimpleApiCallSlice } from 'src/helpers/redux/createSimpleApiCallSlice';
import { createSlice } from 'src/helpers/redux/createSlice';
import { financingApi } from 'src/modules/financing/api/financing';
import { InstallmentOption } from 'src/pages/bill/pay/installments/types';
import { storeName } from './consts';

type IntentIdWithInstallmentOptionState = {
  intentId: string | null;
  chosenInstallmentOption: InstallmentOption | null;
};

export const intentIdWithInstallmentOptionSlice = createSlice<
  IntentIdWithInstallmentOptionState,
  {
    set(
      state: Draft<IntentIdWithInstallmentOptionState>,
      action: PayloadAction<{
        intentId: string;
        chosenInstallmentOption: InstallmentOption;
      }>
    ): void;
  }
>({
  name: generateSliceName(storeName, 'INTENT_ID_WITH_INSTALLMENT_OPTION'),
  initialState: { intentId: null, chosenInstallmentOption: null },
  reducers: {
    set(state, action) {
      state.intentId = action.payload.intentId;
      state.chosenInstallmentOption = action.payload.chosenInstallmentOption;
    },
  },
  selectors: {
    intentId: (state) => state[storeName]?.intentId,
    numberOfInstallments: (state) => state[storeName].chosenInstallmentOption?.numberOfInstallments,
    selectedInstallmentOption: (state: { [storeName]: IntentIdWithInstallmentOptionState }) =>
      state[storeName].chosenInstallmentOption,
  },
});

type BusinessDetailsInitialState = {
  isBusinessOwner: boolean | null;
};
export const businessOwnerDecisionSlice = createSlice<
  BusinessDetailsInitialState,
  {
    set(state: Draft<BusinessDetailsInitialState>, action: PayloadAction<boolean>): void;
  }
>({
  name: generateSliceName(storeName, 'BUSINESS_OWNER_DECISION'),
  initialState: { isBusinessOwner: null },
  reducers: {
    set(state, action) {
      state.isBusinessOwner = action.payload;
    },
  },
  selectors: {
    isBusinessOwner: (state) => state[storeName]?.isBusinessOwner,
  },
});

export const setInstallmentOptionSlice = createSimpleApiCallSlice({
  actionName: 'setInstallmentOption',
  initialDataState: {
    installments: [],
  },
  storeName,
  api: financingApi.setInstallmentsOption,
});

export const getInstallmentOptionsSlice = createSimpleApiCallSlice({
  actionName: 'getInstallmentOptionsSlice',
  initialDataState: {},
  storeName,
  api: financingApi.getInstallmentsOptions,
});
