import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { NotificationVariant } from 'src/utils/consts';

type Props = {
  licenseKey: string;
};

const CopyQBDLicenseKey = ({ licenseKey }: Props) => {
  const onCopy = () => {
    analytics.trackAction('copy-key');
    pushNotification({
      type: NotificationVariant.INFO,
      msg: 'settings.newAccountingSoftware.QuickbooksDesktop.toasts.copied',
    });
  };

  return (
    <CenterPostWrapper>
      <MICopyLinkContainer>
        <MICopyReference data-testid="CopyQBDLicenseKey-text">{`${licenseKey}`}</MICopyReference>
        <CopyToClipboard onCopy={onCopy} text={licenseKey}>
          <CopyButton>
            <MIFormattedText label="general.copy" />
          </CopyButton>
        </CopyToClipboard>
      </MICopyLinkContainer>
    </CenterPostWrapper>
  );
};

const CenterPostWrapper = styled.div`
  text-align: center;
`;

const MICopyLinkContainer = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex: 0 1 20rem;
  background-color: rgba(237, 237, 237, 1);
  border-radius: 0.8rem;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 1.6rem;
  height: 4rem;
  > button {
    min-width: 10rem;
    &:hover {
      background-color: rgba(237, 237, 237, 1);
    }
  }
`;

const MICopyReference = styled.span`
  flex: 0 1 auto;
  color: ${(props) => props.theme.text.color.highlight};
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 1rem 0 0;
  ${(props) => props.theme.text.fontType.regular};
`;

const CopyButton = styled.span`
  flex: 0 1 auto;
  cursor: pointer;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme.text.fontType.small}
  color: ${(props) => props.theme.colors.text};
`;

export default CopyQBDLicenseKey;
