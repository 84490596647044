import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { getOrgId } from 'src/redux/user/selectors';
import { DeliveryMethodType } from 'src/utils/types';

export const useFetchDeliveryMethod = (deliveryMethodId?: string, vendorId?: number) => {
  const deliveryMethod = useSelector<any, DeliveryMethodType>(deliveryMethodsStore.selectors.byId(deliveryMethodId));
  const isDeliveryMethodLoading = useSelector<any, { loading: boolean } | undefined>(
    deliveryMethodsStore.selectors.fetch.status(deliveryMethodId)
  )?.loading;
  const orgId = useSelector(getOrgId);
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);

  const fetchDeliveryMethod = useCallback(async () => {
    if (!deliveryMethod && deliveryMethodId) {
      await deliveryMethodActions.fetch({ orgId, vendorId, id: deliveryMethodId });
    }
  }, [deliveryMethod, deliveryMethodId, vendorId, orgId, deliveryMethodActions]);

  useEffect(() => {
    fetchDeliveryMethod();
  }, [fetchDeliveryMethod, deliveryMethodId]);

  return {
    deliveryMethod,
    isDeliveryMethodLoading,
    fetchDeliveryMethod: () => deliveryMethodActions.fetch({ orgId, vendorId, id: deliveryMethodId }),
  };
};
