import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIMoney } from 'src/components/common/MIMoney';
import { accountingPlatformBankAccountsStore } from 'src/modules/accounting-platform-bank-accounts/accounting-platform-bank-accounts-store';
import { useFetchAccountingPlatformBankAccounts } from 'src/modules/accounting-platform-bank-accounts/hooks/useFetchAccountingPlatformBankAccounts';
import { devices } from 'src/theme/appDevices';
import { BillStatus, MiMoneyFlavor } from 'src/utils/consts';
import { AccountingPlatformBankAccountType, BillType, PaymentType } from 'src/utils/types';

type MarkAsPaidPaymentDetailsProps = {
  payment: PaymentType;
  bill: BillType;
};

const MarkAsPaidPaymentDetails = ({ payment, bill }: MarkAsPaidPaymentDetailsProps) => {
  useFetchAccountingPlatformBankAccounts({ forceReload: false });
  const intuitAccount = useSelector<any, AccountingPlatformBankAccountType | undefined>(
    accountingPlatformBankAccountsStore.selectors.byId(payment?.intuitAccountId)
  );

  return (
    <>
      <FullPaymentContainer>
        <Header>
          <Title>
            <MIFormattedText label="bills.form.paymentActivity.fullPaymentTitle" />
          </Title>
        </Header>
        <MIMoney
          amount={payment.amount || 0}
          flavor={MiMoneyFlavor.DEFAULT}
          showAmountArrowIcon={bill.status === BillStatus.PAID}
          privateData
        />
      </FullPaymentContainer>
      <FullPaymentSeparator />
      <MarkAsPaidContainer>
        <MarkAsPaidInfoContainer>
          <MarkAsPaidInfo>
            <MIFormattedText
              label="bills.view.markAsPaidInfo"
              values={{
                date: <MIFormattedDate date={payment.scheduledDate} />,
              }}
            />
          </MarkAsPaidInfo>
          {intuitAccount && (
            <MarkAsPaidAccountInfo>
              <MIFormattedText
                label="bills.view.markAsPaidAcountInfo"
                values={{
                  account: intuitAccount.name,
                }}
              />
            </MarkAsPaidAccountInfo>
          )}
        </MarkAsPaidInfoContainer>
      </MarkAsPaidContainer>
    </>
  );
};

export default MarkAsPaidPaymentDetails;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Text = styled.div`
  font-size: 1.2rem;
  line-height: 2rem;
  color: ${(props) => props.theme.text.color.label};
`;

const Title = styled(Text)`
  font-size: ${(props) => props.theme.text.size.regular};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  color: ${(props) => props.theme.text.color.main};
`;

const FullPaymentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.2rem;
  margin-top: 0;
`;

const FullPaymentSeparator = styled.div`
  height: 0.1rem;
  background-color: ${(props) => props.theme.colors.border.darkGrey};
  margin: 0 -3.6rem 3rem;

  @media ${devices.mobile} {
    margin: 0 -1.6rem 3rem;
  }
`;

const MarkAsPaidContainer = styled.div`
  padding: 1.5rem 0 3rem;
`;

const MarkAsPaidInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.1rem solid ${(props) => props.theme.colors.border.darkGrey};
  border-radius: 0.6rem;
  padding: 2.7rem 2rem;
`;

const MarkAsPaidInfo = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
`;

const MarkAsPaidAccountInfo = styled.div`
  margin-top: 0.4rem;
  ${(props) => props.theme.text.fontType.hint}
  color: ${(props) => props.theme.text.color.darkGrey};
  font-weight: ${(props) => props.theme.text.weight.regular};
`;
