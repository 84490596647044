import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'src/core/ds/button';
import IconButton from 'src/core/ds/iconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ThreeDotsIcon } from 'src/images/get-pro/three-dots.svg';
import { useGetProSingleItemActions } from 'src/pages/get-pro/hooks/useGetProSingleItemActions';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';

type ActionItemType = {
  action: () => void;
  label: string;
  disabled?: boolean;
  color?: string;
};

export const InProgressAndPaidMenu = ({ id, tab }: { id: string; tab: GetProTabs }) => {
  const invoice = useSelector(MAP_STORE_BY_TAB[tab].selectors.fetch.byId(id));

  const {
    viewCustomerDetails,
    markAsUnpaid,
    remove,
    openPdfPreview,
    canMarkAsUnpaid,
    canRemove,
    canAcknowledge,
    canViewInvoiceFile,
    canCancelPending,
    cancelPending,
  } = useGetProSingleItemActions(invoice, tab);

  const actionsList = useMemo<ActionItemType[]>(
    () => [
      ...(canViewInvoiceFile
        ? [
            {
              action: openPdfPreview,
              label: `getPro.table.item.menu.${tab}.viewInvoice`,
            },
          ]
        : []),
      {
        action: viewCustomerDetails,
        label: `getPro.table.item.menu.${tab}.customerDetails`,
      },
      {
        action: markAsUnpaid,
        label: `getPro.table.item.menu.${tab}.markAsUnpaid`,
        disabled: !canMarkAsUnpaid,
      },
      {
        action: remove,
        label: `getPro.table.item.menu.${tab}.remove`,
        disabled: !(canRemove || canAcknowledge),
        color: 'red.500',
      },
      {
        action: cancelPending,
        label: `getPro.table.item.menu.${tab}.cancel`,
        disabled: !canCancelPending || canRemove || canAcknowledge,
        color: 'red.500',
      },
    ],
    [
      canViewInvoiceFile,
      openPdfPreview,
      tab,
      viewCustomerDetails,
      markAsUnpaid,
      canMarkAsUnpaid,
      remove,
      canRemove,
      canAcknowledge,
      cancelPending,
      canCancelPending,
    ]
  );

  const renderActionItem = ({ action, label, disabled, color = 'black' }: ActionItemType) => {
    if (disabled) return null;

    return <MenuItem key={label} onClick={action} as={Button} label={label} color={color} />;
  };

  return useMemo(
    () => (
      <Menu isLazy placement="bottom-start">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          size="sm"
          icon={<ThreeDotsIcon />}
          label={`getPro.table.item.menu.${tab}.options`}
        />
        <MenuList>{actionsList.map(renderActionItem)}</MenuList>
      </Menu>
    ),
    [id]
  );
};
