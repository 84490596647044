import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import customersStore from 'src/modules/customers/customers-store';
import invoiceStore from 'src/modules/invoices/invoices-store';
import { BatchUploadContacts } from 'src/pages/contacts/create/BatchUploadContacts';
import { PageProps } from 'src/pages/get-paid/create/CreateInvoiceRouter';
import { getOrgId } from 'src/redux/user/selectors';
import { useForm } from 'src/ui/form';
import { ContactsTab, Currency } from 'src/utils/consts';
import { convertCurrencyToNumber } from 'src/utils/currency-utils';
import { useQueryString } from 'src/utils/hooks';
import useBatchCustomerUpload from '../hooks/useBatchCustomerUpload';
import InvoiceDetailsForm from './components/form/InvoiceDetailsForm';
import { getCustomerEmailById, getCustomerOptions, getInvoiceModel } from './utils';

type Props = PageProps & {
  onShareInvoice: (id: string) => void;
  onGoToSelectedItem: (id: string) => void;
};

const NewInvoicePage = ({ onExit, onShareInvoice, onGoToSelectedItem }: Props) => {
  const { customerId } = useQueryString();
  const [submitType, setSubmitType] = useState(null);
  const orgId = useSelector(getOrgId);
  const createStatus = useSelector(invoiceStore.selectors.create.status());
  const invoiceActions = useStoreActions(invoiceStore);
  const paymentRequest = useSelector(invoiceStore.selectors.fetch.byId(createStatus?.id));
  const customerActions = useStoreActions(customersStore);
  const contacts = useSelector((state) => customersStore.selectors.list.value(state, { orgId }));
  const contactList = useMemo(() => getCustomerOptions(contacts), [contacts]);
  const model = useMemo(() => getInvoiceModel(contacts, customerId), [customerId, contacts]);
  const fetchCustomersList = useCallback(() => {
    customerActions.list({ orgId });
  }, [customerActions, orgId]);

  const { customerCsvFile, onUploadBatchCustomer, onDoneBatchCustomer, onExitBatchCustomer } = useBatchCustomerUpload(
    fetchCustomersList
  );

  const onCancel = () => {
    invoiceStore.actions.clearFileUpload.clear();
  };

  const [invoiceMV, { submit }] = useForm(model, {
    submit: async () => {
      const { dueDate, invoiceNumber, customerId, totalAmount, customerName } = invoiceMV;
      const totalAmountValue = convertCurrencyToNumber(totalAmount.value);
      const customerEmail = getCustomerEmailById(contacts, customerId.value);
      const data = {
        totalAmount: totalAmountValue,
        dueDate: dueDate.value,
        invoiceNumber: invoiceNumber.value,
        customerName: customerName.value,
        customerId: customerId.value,
        customerEmail,
        customerNote: '',
        currency: Currency.USD,
      };
      await invoiceActions.create({ orgId, ...data });
    },
  });

  useEffect(() => {
    if (paymentRequest) {
      if (submitType === 'saveAndSent') {
        onShareInvoice(paymentRequest.id);
      } else {
        onGoToSelectedItem(paymentRequest.id);
      }
    }
  }, [paymentRequest, submitType, orgId]);

  useEffect(() => {
    fetchCustomersList();

    return onCancel;
  }, [fetchCustomersList]);

  if (customerCsvFile) {
    return (
      <BatchUploadContacts
        file={customerCsvFile}
        type={ContactsTab.CUSTOMERS}
        goExit={onExitBatchCustomer}
        onPrev={onExitBatchCustomer}
        onNext={onDoneBatchCustomer}
      />
    );
  }

  return (
    <StepLayoutPage goExit={onExit} isPrevDisabled relativeStep={0}>
      <InvoiceDetailsForm
        submit={submit}
        cancel={onCancel}
        setSubmitType={setSubmitType}
        submitType={submitType}
        model={invoiceMV}
        createStatus={createStatus?.loading}
        contactOptions={contactList}
        onBatchCustomerUpload={onUploadBatchCustomer}
      />
    </StepLayoutPage>
  );
};

export default NewInvoicePage;
