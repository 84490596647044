import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { IllustrationName } from 'src/core/ds/illustration';
import { Link } from 'src/core/ds/link';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { learnMoreApplicationIsPending } from 'src/pages/bill/pay/installments/consts';
import { billPayLocations } from 'src/pages/bill/pay/locations';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import locations from 'src/utils/locations';
import { FinancingErrorStatus } from '../components/FinancingErrorStatus';

export const EligibilityPendingPage = () => {
  const { id: billId } = useSelector(getBill);
  const orgId = useSelector(getOrgId);
  const { navigate } = useNavigator();

  const goToFundingSourcePage = useCallback(() => {
    navigate(generatePath(billPayLocations.funding, { orgId, billId }), true);
  }, [orgId, billId]);

  const handleLinkClick = () => {
    analytics.trackAction('financing-error-page-link-clicked', { link: learnMoreApplicationIsPending });
  };

  const handleSaveAndPayLater = () => {
    navigate(locations.MainApp.dashboard.url());
  };

  const handlePayAnotherWay = () => goToFundingSourcePage();

  const footer = (
    <MIFormattedText
      label="financing.errors.pending.footer"
      values={{
        link: (...chunks) => (
          <Link href={learnMoreApplicationIsPending} target="_blank" onClick={handleLinkClick}>
            {chunks}
          </Link>
        ),
      }}
    />
  );

  return (
    <FinancingErrorStatus
      illustration={IllustrationName.clock}
      title="financing.errors.pending.title"
      description="financing.errors.pending.description"
      cancelButtonLabel="financing.errors.pending.cancelCTA"
      actionButtonLabel="financing.errors.pending.actionCTA"
      onCancel={handlePayAnotherWay}
      onAction={handleSaveAndPayLater}
      footer={footer}
    />
  );
};
