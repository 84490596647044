import { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import { TextField } from 'src/core/ds/form/fields';
import { UseModal } from 'src/helpers/react/useModal';
import { analytics } from 'src/services/analytics';
import { FormContainer } from 'src/ui/form/FormElements';
import { isEnterPressed } from 'src/utils/events';
import { PAGE_EVENT } from '../SelectedItemsGallery';

type Props = {
  numOfPayments: number;
};

export const DeclineReasonModalContent: React.FC<UseModal<Props>> = ({ dismiss, numOfPayments }) => {
  const [reason, setReason] = useState('');

  const onDecline = () => {
    analytics.track(PAGE_EVENT, 'batch-actions-payments_decline-success', {
      'batch-actions-decline-reason': reason,
    });
    dismiss(reason);
  };
  const onKeyPressed = (event: React.KeyboardEvent) => {
    if (isEnterPressed(event)) {
      dismiss();
      event.preventDefault();
    }
  };
  const handleDeclineReason = (event: React.ChangeEvent<HTMLInputElement>) => setReason(event.currentTarget.value);

  return (
    <ModalMessage
      id="decline-payment-modal"
      titleComponent={
        <>
          <div className="title">
            <MIFormattedText
              label="bills.form.batchDeclineDialog.title"
              values={{
                numOfPayments,
              }}
            />
          </div>
        </>
      }
      contentComponent={
        <FormContainer onKeyDown={onKeyPressed}>
          <TextField
            testId="decline-reason-input"
            id="declineNote"
            label="bills.form.batchDeclineDialog.noteTitle"
            placeholder="bills.form.batchDeclineDialog.placeholder"
            value={reason}
            autoFocus
            onChange={handleDeclineReason}
          />
        </FormContainer>
      }
      buttonLabel="bills.form.batchDeclineDialog.buttonLabel"
      onButtonClick={onDecline}
      onCloseClick={() => {
        analytics.track(PAGE_EVENT, 'batch-actions-decline-payment-modal-closed');
        dismiss(null);
      }}
    />
  );
};
