import { featureFlags } from '@melio/shared-web';
import { IconNames } from 'src/core/ds/icon';
import { useIsMsnDashboardDisplay } from 'src/modules/msn-portal';
import { useIsOrganizationInMsnOnly } from 'src/modules/msn-portal/hooks/useIsOrganizationInMsnOnly';
import { globalLocations } from 'src/pages/locations';
import { FeatureFlags } from 'src/utils/consts';
import { MenuItemPropsType } from '../../types/type';
import { usePayGroupItems } from './usePayGroupItems';

export const useMsnItems = () => {
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const isMsnDashboardDisplay = useIsMsnDashboardDisplay();
  const isMsnOnlyOrg = useIsOrganizationInMsnOnly();
  const payGroupItems = usePayGroupItems();

  const msnMenuItems: Array<MenuItemPropsType> = [];

  if (isMsnOnlyOrg) {
    msnMenuItems.push({
      key: 'menu-item-payments-in',
      to: globalLocations.msnPortal.base,
      label: 'nav.incoming',
      icon: IconNames.getPaid,
      eventName: 'payments-in',
    });

    if (!shouldSplitContactsTab) {
      msnMenuItems.push({
        key: 'menu-item-settings',
        to: globalLocations.settings.index,
        label: 'nav.settings',
        icon: IconNames.settings,
        eventName: 'settings',
      });
    }
  } else if (isMsnDashboardDisplay) {
    msnMenuItems.push(...payGroupItems, {
      key: 'menu-item-payments-in',
      to: globalLocations.msnPortal.base,
      label: 'nav.incoming',
      icon: IconNames.getPaid,
      eventName: 'payments-in',
    });

    if (!shouldSplitContactsTab) {
      msnMenuItems.push({
        key: 'menu-item-contacts',
        to: globalLocations.contacts.dashboard,
        label: 'nav.contacts',
        icon: IconNames.contacts,
        eventName: 'contacts-list',
      });

      msnMenuItems.push({
        key: 'menu-item-settings',
        to: globalLocations.settings.index,
        label: 'nav.settings',
        icon: IconNames.settings,
        eventName: 'settings',
      });
    }
  }

  return {
    showMsnMenuItemsOnly: isMsnDashboardDisplay || isMsnOnlyOrg,
    msnMenuItems,
  };
};
