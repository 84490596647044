import { useDispatch } from 'react-redux';
import { useModal } from 'src/helpers/react/useModal';
import { useBreak } from 'src/hoc';
import { getInvitationActions } from 'src/modules/invitations/invitations-store';
import { ClientsPreview } from 'src/pages/team/team-management/ClientsPreview';
import { TeamMemberInvitationCard } from 'src/pages/team/team-management/TeamMemberCard';
import { TeamMemberClientsAmount } from 'src/pages/team/team-management/TeamMemberClientsAmount';
import { AccessLevel } from 'src/utils/consts';
import { InvitationActionType } from 'src/utils/types';
import { useGetInvitationActions } from '../../utils/invitation-actions';
import { RemoveInvitationModal } from './modals/RemoveInvitationModal';

export function InvitationContainer({ invitation, organizations, orgId }) {
  const { isMobile, isPhablet } = useBreak();
  const isSmallScreen = isMobile || isPhablet;
  const clients = (invitation.relatedInvitations || [])
    .filter((i) => i.accessLevel !== AccessLevel.NONE)
    .map((relatedInvitation) => ({
      ...organizations.find((org) => org.id === relatedInvitation.organizationId),
      role: relatedInvitation.role,
      accessLevel: relatedInvitation.accessLevel,
    }));
  const canAccessFirm = clients.some((c) => c.id === orgId);
  const invitationActions = getInvitationActions(useDispatch());
  const [DeleteInvitation, showDeleteInvitation] = useModal(RemoveInvitationModal, {
    invitation,
    orgId,
    modalName: 'removeInvitationModal',
  });
  const actions = useGetInvitationActions<InvitationActionType>({ invitation }, (actionType) => {
    if (actionType === 'resend') {
      return {
        label: 'invitation.action.resend',
        action: () => invitationActions.resend({ orgId, id: invitation.id }),
        negative: false,
      };
    }

    if (actionType === 'delete') {
      return {
        label: 'team.actions.invitation.remove',
        negative: true,
        action: showDeleteInvitation,
      };
    }

    return null;
  });

  return (
    <>
      {DeleteInvitation}
      <TeamMemberInvitationCard
        invitation={invitation}
        actions={actions}
        rightSideRender={
          clients.length > 0 &&
          !isSmallScreen && (
            <TeamMemberClientsAmount
              disabled
              thisFirm={canAccessFirm}
              amount={clients.length}
              hoverContent={clients?.length ? <ClientsPreview clients={clients} firmOrgId={orgId} /> : null}
            />
          )
        }
      />
    </>
  );
}
