import { History } from 'history';
import curry from 'lodash/curry';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore, { getDeliveryMethodActions } from 'src/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { editCheckAddressLocations } from 'src/pages/vendor/edit-check-adress/locations';
import { UnilateralDeliveryMethodType } from 'src/pages/vendor/virtual-delivery-method/hoc/unilateral-payment-hooks';
import { DeliveryType } from 'src/utils/consts';
import { deliveryTypePredicate } from 'src/utils/delivery-methods';
import { FieldType, PaymentType } from 'src/utils/types';

export type EditCheckAddressProps = {
  token: string;
  id: string;
};

export type EditCheckAddressState = {
  payment: PaymentType;
  organization: any;
  isPaymentLoading: boolean;
  isDeliveryMethodProcessing: boolean;
  updatingDeliveryMethodId?: string;
};

const onChange = curry(
  (state: EditCheckAddressState, setState: (state: EditCheckAddressState) => void, { id, value }: FieldType) => {
    setState({
      ...state,
      [id]: value,
    });
  }
);

const onSubmit = curry(
  async (
    props: EditCheckAddressProps,
    state: EditCheckAddressState,
    actions: any,
    history: History,
    value: UnilateralDeliveryMethodType,
    isAddressVerified: boolean
  ) => {
    const { token, id } = props;
    const { payment, updatingDeliveryMethodId } = state;

    if (isAddressVerified) {
      await actions.updateDeliveryMethodACHWithToken({
        token,
        deliveryMethod: { id: updatingDeliveryMethodId, ...value },
        orgId: payment?.organizationId,
        vendorId: updatingDeliveryMethodId,
      });

      history.push(generatePath(editCheckAddressLocations.checkNotSendAddressSaved, { token, id }));
      actions.fetchEmailToVendorDetails({ token });
    } else {
      const paperCheck = {
        ...value.paperCheck,
        deliveryType: DeliveryType.CHECK,
        deliveryMethodId: payment.deliveryMethodId,
      };
      await actions.validateAddress(paperCheck);
    }
  }
);

export function useEditCheckAddressFormState(props: EditCheckAddressProps) {
  const { token } = props;
  const history = useHistory();
  const paymentActions = useStoreActions(paymentsStore);

  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id));
  const [emailOnScheduleState, setState] = useState({});

  const { loading: isLoading } = useSelector(deliveryMethodsStore.selectors.validation);
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor = useSelector(fetchSelector.byId(payment?.vendor?.id));
  const vendorDeliveryMethods = vendor?.deliveryMethods;
  const bundledActions = {
    ...useStoreActions(deliveryMethodsStore),
    ...getDeliveryMethodActions(useDispatch()),
    ...paymentActions,
  };
  const updatingDeliveryMethodId = vendor?.deliveryMethods?.find(deliveryTypePredicate(DeliveryType.CHECK))?.id;
  const isDeliveryMethodUpdating = useSelector(deliveryMethodsStore.selectors.update.status(updatingDeliveryMethodId))
    ?.loading;

  const deliveryMethodModel = useMemo(() => {
    let paperCheckModel = {
      printName: '',
      addressLine1: '',
      addressLine2: '',
      state: '',
      city: '',
      zipCode: '',
    };
    const checkDeliveryMethod = vendorDeliveryMethods?.find(deliveryTypePredicate(DeliveryType.CHECK));

    if (checkDeliveryMethod?.paperCheck) {
      paperCheckModel = merge(paperCheckModel, pick(checkDeliveryMethod?.paperCheck, keys(paperCheckModel)));
    }

    return { paperCheck: paperCheckModel };
  }, [vendorDeliveryMethods]);

  const enhancedState: EditCheckAddressState = {
    ...emailOnScheduleState,
    payment,
    organization,
    isPaymentLoading,
    updatingDeliveryMethodId,
    isDeliveryMethodProcessing: isLoading || isDeliveryMethodUpdating,
  };

  const actions = {
    onChange: onChange(enhancedState, setState),
    onSubmit: onSubmit(props, enhancedState, bundledActions, history),
  };

  return {
    actions,
    deliveryMethodModel,
    isDeliveryMethodProcessing: isLoading || isDeliveryMethodUpdating,
  };
}
