import { connect, useSelector } from 'react-redux';
import { generatePath, RouteComponentProps } from 'react-router-dom';
import { getOrgIdByUrl } from 'src/helpers/utilities';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { analytics } from 'src/services/analytics';
import { hasKey } from 'src/utils/hasKey';
import { parseQueryString } from 'src/utils/query-utils';
import { UserContextType } from 'src/utils/types';
import { getRedirectLoginUrl } from 'src/utils/user';
import { useSignInScreenName } from '../hooks/useSignInScreenName';
import { LoginFlowType, useLoginFlowType } from '../multi-factor-authentication/hooks/useLoginFlowType';
import { eventPage } from './LoginPageContainer';

export const GoogleLoginSuccessRedirect = connect(null)(({ location }: RouteComponentProps) => {
  const { navigate } = useNavigator();
  const userDetails = useSelector<any, UserContextType>(profileStore.selectors.profile);
  const { setLoginFlowType } = useLoginFlowType();
  const queryParams = parseQueryString(location.search);
  const { screenName } = useSignInScreenName();
  const locationState =
    hasKey(queryParams, 'state') && typeof queryParams.state === 'string' ? JSON.parse(queryParams.state) : {};

  const orgIdFromUrl = getOrgIdByUrl(locationState?.redirectUrl);

  if (orgIdFromUrl && orgIdFromUrl !== userDetails.orgId) {
    const orgIdfromUrlBelongsToUser = userDetails.organizations.find((org) => org.id === orgIdFromUrl);

    if (orgIdfromUrlBelongsToUser) {
      userDetails.orgId = orgIdFromUrl;
    }
  }

  analytics.track(eventPage, 'login-with-google-success', { screenName }, { integrations: { Salesforce: true } });
  setLoginFlowType(LoginFlowType.Google);

  if (locationState?.redirectUrl) {
    const { preservedState, redirectUrl } = locationState;

    if (
      redirectUrl.includes(`meliome/pay/${preservedState?.link}/edit-funding-source`) &&
      userDetails.organizations.length > 1
    ) {
      const { customerEmail, customerName } = preservedState.paymentRequest;
      let redirectUrlToPaymentRequest: string;

      if (customerEmail && customerName) {
        redirectUrlToPaymentRequest = generatePath(melioMeLocations.wizard.requestPayment, {
          link: preservedState.link,
          hash: preservedState.paymentRequest.link,
        });
      } else {
        redirectUrlToPaymentRequest = generatePath(melioMeLocations.wizard.index, { link: preservedState.link });
      }

      navigate(redirectUrlToPaymentRequest);
    } else {
      navigate(redirectUrl);
    }
  } else if (userDetails) {
    const redirectUrl = getRedirectLoginUrl({
      orgId: userDetails.orgId,
      registrationFlow: userDetails.registrationFlow,
      userPreferences: userDetails.userPreferences ?? { allowCompaniesSwitcher: false },
      organizations: userDetails.organizations,
    });

    navigate(redirectUrl);
  } else {
    navigate('/');
  }

  return null;
});
