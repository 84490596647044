import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollHashIntoView = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elem = document.getElementById(location.hash.slice(1));

      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
};
