import Box from 'src/core/ds/box';
import { InvoiceType } from 'src/modules/invoices/types';
import PaymentDetails from 'src/pages/get-paid/list/components/PaymentDetails';
import RequestDetails from 'src/pages/get-paid/list/components/RequestDetails';
import { InvoiceModelDataType } from 'src/pages/get-pro/types';
import { ModelView } from 'src/ui/form';
import { InvoiceStatus } from 'src/utils/consts';
import { getLatestPayment } from 'src/utils/payments';
import CardStatus from '../CardStatus';
import { PaymentProgressBar } from '../progressBar/PaymentProgressBar';
import { PendingNotification } from './PendingNotification';

type Props = {
  invoice?: InvoiceType;
  isMSNPortal: boolean;
  remove: (removeInvoice?: boolean) => Promise<void>;
  onMarkInvoiceAsPaid: (value: boolean) => void;
  isMarkedAsPaid: boolean;
  vendorHandle: string;
  invoiceMV: ModelView<InvoiceModelDataType>;
  inEditMode: boolean;
  uploading: boolean;
  onEditToggle: () => void;
};

export const PaymentInfo = ({
  invoice,
  isMSNPortal,
  remove,
  onMarkInvoiceAsPaid,
  isMarkedAsPaid,
  vendorHandle,
  invoiceMV,
  inEditMode,
  uploading,
  onEditToggle,
}: Props) => {
  const currentPayment = getLatestPayment(invoice?.payments || []);
  const isPaymentProgressBarVisible =
    isMSNPortal &&
    !!currentPayment &&
    !(invoice?.status === InvoiceStatus.FAILED || invoice?.status === InvoiceStatus.CANCELLED_BY_CUSTOMER);

  return (
    <>
      <CardStatus status={invoice?.status} />
      <Box px={{ base: 4, sm: 0 }}>
        {isPaymentProgressBarVisible && <PaymentProgressBar payment={currentPayment} />}
      </Box>
      {invoice?.status === InvoiceStatus.PENDING && <PendingNotification pendingReason={invoice?.pendingReason} />}
      <PaymentDetails
        showGetPaidTabs
        invoice={invoice as InvoiceType}
        remove={remove}
        onMarkInvoiceAsPaid={onMarkInvoiceAsPaid}
        showMenu={invoice?.status === InvoiceStatus.CREATED || invoice?.status === InvoiceStatus.MARKED_AS_PAID}
        isMarkedAsPaid={isMarkedAsPaid}
        currentPayment={currentPayment}
      />
      {invoice?.initiatedBy !== 'customer' && (
        <RequestDetails
          showGetPaidTabs
          invoice={invoice as InvoiceType}
          vendorHandle={vendorHandle}
          email={invoiceMV.customerEmail}
          note={invoiceMV.customerNote}
          viewOnly={!inEditMode || uploading}
          onEdit={onEditToggle}
          remove={remove}
          showMenu={invoice?.status === InvoiceStatus.CREATED}
          inEditMode={inEditMode}
        />
      )}
    </>
  );
};
