import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PaymentCard } from 'src/components/msn/mobile/PaymentCard';
import Box from 'src/core/ds/box';

export const MSNMobilePaymentsGroup = ({ payments, label, onOpenDrawer }) => (
  <Box width="full">
    <Box textTransform="uppercase" textStyle="body4Semi" color="grey.700" pb={4}>
      <MIFormattedText label={label} />
    </Box>
    {payments.map(({ id, customer, amount, invoice, invoiceNumber, status }) => (
      <PaymentCard
        key={id}
        name={customer.name}
        amount={amount.value}
        status={status.type}
        updatedAt={new Date(invoice.updatedAt)}
        onClick={() => onOpenDrawer(invoice)}
        {...(invoiceNumber && { invoiceNumber })}
        {...(invoice.deliveryDate && { deliveryETA: new Date(invoice.deliveryDate) })}
      />
    ))}
  </Box>
);
