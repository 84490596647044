import { useEffect, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { TokenStateEnum } from '../consts';

export const useCandidateTokenState = (token: string): { tokenState: TokenStateEnum; candidateId: string } => {
  const [tokenState, setTokenState] = useState<TokenStateEnum>(TokenStateEnum.LOADING);
  const [candidateId, setCandidateId] = useState<string>('');
  const { onApiCall: getMSNCandidateByToken } = useApi<[string], Record<string, any>>({
    api: msnPortalApi.getMSNCandidateByToken,
  });

  const fetchCandidate = async () => {
    try {
      const { isVendorFilledForm, candidateId } = await getMSNCandidateByToken(token);
      setCandidateId(candidateId);

      if (isVendorFilledForm) {
        setTokenState(TokenStateEnum.VENDOR_FILLED_FORM);
      } else {
        setTokenState(TokenStateEnum.VALID);
      }
    } catch {
      setTokenState(TokenStateEnum.INVALID);
    }
  };

  useEffect(() => {
    fetchCandidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tokenState, candidateId };
};
