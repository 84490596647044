import { ReactNode } from 'react';
import Box from 'src/core/ds/box';
import { MenuItem } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';

export type Props = {
  action: () => void;
  label: string;
  color?: string;
  disabledTooltipLabel?: ReactNode;
  isDisabled?: boolean;
};

export function MenuActionItem({ action, label, disabledTooltipLabel, isDisabled = false, color = 'black' }: Props) {
  const hideTooltip = !isDisabled || !disabledTooltipLabel;

  return (
    <Tooltip placement="top" label={disabledTooltipLabel} isDisabled={hideTooltip}>
      <MenuItem key={label} onClick={action} label={label} color={color} w="auto" as={Box} isDisabled={isDisabled} />
    </Tooltip>
  );
}
