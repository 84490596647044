import { featureFlags } from '@melio/shared-web';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { generatePath, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useExtraAnalyticsProps } from 'src/analytics/useExtraAnalyticsProps';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { PageHeader } from 'src/components/common/PageHeader';
import { AppLayout } from 'src/components/layout/AppLayout';
import { BillGroupHeader } from 'src/components/list/BillGroupHeader';
import SettingsListItem from 'src/components/list/SettingsListItem';
import { RightPanelPlaceholder } from 'src/components/onboarding/RightPanelPlaceholder';
import { CSSObject } from 'src/core/ds';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import { useBreak } from 'src/hoc';
import { useOrgId } from 'src/hooks';
import { useIsOrganizationInMsnOnly } from 'src/modules/msn-portal/hooks/useIsOrganizationInMsnOnly';
import { profileStore } from 'src/modules/profile/profile-store';
import { settingsLocations } from 'src/pages/settings/locations';
import { getCanContactSupport, getCompanyInfo, getDeliveryMethods, getProfile } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import { DeliveryType, FeatureFlags } from 'src/utils/consts';
import { CompanyInfoType, DeliveryMethodType, UserContextType } from 'src/utils/types';
import { useIsSpendManagementEnabled } from '../spend-management/hooks/useIsSpendManagementEnabled';
import { isCompanyLegalInfoDone } from './records';
import { getSettingsPagesRoutesGroups } from './utils/settings-pages-routes';

const sxForCard: CSSObject = {
  mt: 4,
  mb: -4,
};

export const SettingsListPageContainer = () => {
  const deliveryMethods = useSelector(getDeliveryMethods);
  const orgId = useOrgId();
  const canContactSupport = useSelector(getCanContactSupport);
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const accessLevel = useSelector(profileStore.selectors.accessLevel);
  const companyInfo = useSelector(getCompanyInfo);
  const profile = useSelector(getProfile);
  const location = useLocation();
  const [enableApprovalWorkflows] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);
  const { isDesktop, isTablet } = useBreak();
  const match = useRouteMatch();
  const isPureMSNOrganization = useIsOrganizationInMsnOnly();
  const { isSpendManagementEnabled } = useIsSpendManagementEnabled();

  useExtraAnalyticsProps('settings', { origin: 'settings' });

  const hasDeliveryMethod = (deliveryMethods: DeliveryMethodType[], excludeVirtual: boolean) =>
    excludeVirtual
      ? !!deliveryMethods.filter((deliveryMethod) => deliveryMethod.deliveryType !== DeliveryType.VIRTUAL).length
      : deliveryMethods.length > 0;

  const shouldShowSinglePageView = (location: Location<unknown>, isDesktop: boolean, profile: UserContextType) =>
    location.pathname !== generatePath(settingsLocations.index, { orgId: profile.orgId }) && !isDesktop;

  const shouldShowDeliveryMethods = (companyInfo: CompanyInfoType, deliveryMethods: DeliveryMethodType[]): boolean =>
    hasDeliveryMethod(deliveryMethods, true) ||
    (!!companyInfo.ownedVendorId && !!companyInfo.ownedVendorHandle && isCompanyLegalInfoDone(companyInfo));

  const showDeliveryMethods = shouldShowDeliveryMethods(companyInfo, deliveryMethods);

  const routesGroups = getSettingsPagesRoutesGroups({
    orgId,
    showDeliveryMethods,
    permissions,
    canContactSupport,
    accessLevel,
    enableApprovalWorkflows,
    isPureMSNOrganization,
    isSpendManagementEnabled,
  });
  const getFirstTabPath = () => routesGroups[0].routes[0].path;
  const settingsIndexPath = generatePath(settingsLocations.index, { orgId: profile.orgId });
  const shouldNavigateToFirstOption = isDesktop || isTablet;

  return (
    <AppLayout>
      <Container>
        <ListContainer path={location.pathname} orgId={orgId} data-testid="settings-list">
          <ListContent>
            {isDesktop && (
              <Title>
                <MIFormattedText label="settings.title" />
              </Title>
            )}
            <CustomFeed feedType="settings_above" sxForCard={sxForCard} />
            <SettingsListContainer data-testid="settings-menu">
              {routesGroups.map((groups, idx) => (
                <BillGroupContainer key={idx}>
                  <BillGroupHeader header={groups.header} />
                  {groups.routes.map((route) => (
                    <SettingsListItem
                      key={route.path}
                      to={generatePath(route.path, match.params)}
                      title={route.title}
                      description={route.description}
                      icon={route.icon}
                      search={route.search ?? ''}
                      showNewSettingTag={route.showNewSettingTag}
                    />
                  ))}
                </BillGroupContainer>
              ))}
            </SettingsListContainer>
          </ListContent>
        </ListContainer>
        <ProjectionContainer
          data-testid="settings-projection"
          className={shouldShowSinglePageView(location, isDesktop, profile) ? 'single' : undefined}
        >
          <ProjectionScrollable data-testid="settings-scrollable">
            <ProjectionContent data-testid="settings-content">
              {!isDesktop && <PageHeader backNav={{ pathname: settingsIndexPath }} text={companyInfo.companyName} />}
              {location.pathname !== settingsIndexPath && isDesktop && (
                <Header>
                  <Avatar
                    size={Size.xl}
                    name={profile.orgName as string}
                    link={companyInfo.logoUrl as string}
                    textColor="white"
                  />
                  <OrgName>{profile.orgName}</OrgName>
                </Header>
              )}
              <Switch>
                {routesGroups.map((groups) =>
                  groups.routes.map((route) => <Route key={route.path} path={route.path} exact render={route.main} />)
                )}
                {shouldNavigateToFirstOption && <Redirect exact from={settingsIndexPath} to={getFirstTabPath()} />}
                <Route component={RightPanelPlaceholder} />
              </Switch>
            </ProjectionContent>
          </ProjectionScrollable>
        </ProjectionContainer>
      </Container>
    </AppLayout>
  );
};

const ProjectionContainer = styled.div`
  transition: ${(props) => props.theme.animation.transition.menu};
  box-sizing: border-box;
  position: relative;

  @media ${devices.mobile}, ${devices.phablet} {
    background-color: rgba(243, 243, 243, 1);
    position: absolute;
    left: calc(100% + 1rem);
    height: 100%;
    width: 100%;
    &.single {
      left: 0;
    }
  }

  @media ${devices.tablet} {
    background-color: rgba(255, 255, 255, 1);
    width: 60%;
    padding: 6.5rem 0 4rem 0;
  }

  @media ${devices.desktop} {
    background-color: rgba(255, 255, 255, 1);
    width: 60%;
    padding-bottom: 4rem;
  }
`;
const Container = styled.div`
  background-color: rgba(250, 250, 250, 1);
  box-sizing: border-box;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
`;

const SettingsListContainer = styled.div`
  margin-top: 4rem;
`;

const ListContainer = styled.div<{ path: string; orgId: number }>`
  background-color: rgba(250, 250, 250, 1);
  overflow-y: auto;
  border-right: 0.1rem solid rgba(231, 231, 231, 1);
  -webkit-overflow-scrolling: touch; //make ios scroll smooth

  @media ${devices.mobile} {
    flex: 1;
    padding: 8.2rem 2rem 4rem 2rem;
    display: block;
    transition: ${(props) => props.theme.animation.transition.menu};
  }

  @media ${devices.phablet} {
    flex: 1;
    padding: 8.2rem 4rem 4rem 4rem;
    display: block;
    transition: ${(props) => props.theme.animation.transition.menu};
  }

  @media ${devices.tablet} {
    width: 40%;
    padding: 8rem 0 4rem 0;
  }

  @media ${devices.desktop} {
    width: 40%;
    padding: 4rem 0;
    margin-left: ${(p) => p.theme.sizes.sideMenu};
  }
`;

const ListContent = styled.div`
  @media ${devices.tablet} {
    padding: 0 7%;
  }

  @media ${devices.desktop} {
    padding: 0 7%;
    max-width: 40rem;
    margin: 0 auto;
  }
`;

const Title = styled.div`
  font-size: 3.4rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  color: ${(props) => props.theme.text.color.main};
  line-height: 5.1rem;
`;

const ProjectionScrollable = styled.div`
  overflow-y: auto;
  height: 100%;
  -webkit-overflow-scrolling: touch;

  @media ${devices.desktop}, ${devices.tablet} {
    padding: 4rem 7% 0 7%;
  }
`;

const ProjectionContent = styled.div`
  max-width: 63rem;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: 4rem;
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  display: flex;
  align-items: center;

  @media ${devices.mobile} {
    margin: 2.8rem 2rem 2rem 2rem;
  }

  @media ${devices.phablet} {
    margin: 4rem;
  }
`;

const OrgName = styled.div`
  font-size: 1.8rem;
  color: ${(props) => props.theme.text.color.main};
  margin-left: 1.4rem;
`;

const BillGroupContainer = styled.div`
  margin-bottom: 3rem;
`;
