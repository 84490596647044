import { useContext } from 'react';
import { BusinessApplicationContext } from 'src/pages/bill/pay/installments/context/business-context/BusinessApplicationContextProvider';
import {
  BusinessDetailsModel,
  BusinessType,
} from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import {
  BeneficialOwner,
  BusinessOwnershipDetailsModel,
  MainOwner,
} from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';

export type BusinessApplicationContextType = {
  business: {
    name: string;
    legalName: string;
    legalAddressLine1: string;
    legalCity: string;
    legalState: string;
    legalZipCode: string;
    totalAnnualRevenue: number;
    type: BusinessType;
    phone: string;
    taxId: string;
    industry: string;
  } | null;
  ownership: {
    owner: MainOwner;
    beneficialOwners: BeneficialOwner[];
  } | null;
  setBusinessDetails(businessDetails: BusinessDetailsModel): void;
  setOwnershipDetails(ownershipDetails: BusinessOwnershipDetailsModel): void;
};

export const useBusinessApplicationContext = () => {
  const context = useContext(BusinessApplicationContext);

  return {
    business: context?.business,
    ownership: context?.ownership,
    setBusinessDetails: context?.setBusinessDetails,
    setOwnershipDetails: context?.setOwnershipDetails,
  };
};
