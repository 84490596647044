import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, PartsStyleObject, SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: PartsStyleObject<typeof parts> = {
  field: {
    width: 'full',
    boxSizing: 'border-box',
    minWidth: 0,
    border: 0,
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transitionProperty: 'common',
    transitionDuration: 'normal',
  },
};

const size: Record<string, SystemStyleObject> = {
  md: {
    apply: 'textStyles.body1',
    h: '3.6rem',
    borderBottom: '2px',
  },

  sm: {
    apply: 'textStyles.body2',
    h: '2.8rem',
    borderBottom: '1px',
  },
};

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
  md: {
    field: size.md,
    addon: size.md,
  },
  sm: {
    field: size.sm,
    addon: size.sm,
  },
};

const variantFilled: PartsStyleFunction<typeof parts> = () => ({
  field: {
    border: 'none !important',
    borderColor: 'transparent',
    bg: 'grey.300',
    color: 'black',
    borderRadius: 'lg',
    h: '4rem',
    py: 2,
    px: 4,
    apply: 'textStyles.body2',
    _hover: {
      bg: 'white',
    },
    _placeholder: {
      color: 'grey.700',
      userSelect: 'none',
    },
    _readOnly: {
      boxShadow: 'none !important',
      userSelect: 'none',
      cursor: 'default',
    },
    _focus: {
      boxShadow: '400',
      color: 'black',
      bg: 'white',
      _invalid: {
        borderColor: 'red.500',
      },
    },
    _hidden: {
      display: 'block',
      pointerEvents: 'none',
      border: 'none',
      color: 'black',
    },
    _disabled: {
      color: 'grey.700',
      borderColor: 'grey.700',
      cursor: 'not-allowed',
      _hover: {
        bg: 'grey.300',
      },
    },
  },
});

const variantFlushed: PartsStyleFunction<typeof parts> = () => ({
  field: {
    border: 'none',
    borderColor: 'grey.500',
    borderRadius: 0,
    px: 0,
    bg: 'transparent',
    _readOnly: {
      boxShadow: 'none !important',
      userSelect: 'none',
      cursor: 'default',
      color: 'grey.700',
      borderColor: 'grey.500',
      pointerEvents: 'none',
      '&[data-date-input=true]': {
        color: 'black',
      },
    },
    _hidden: {
      display: 'block',
      pointerEvents: 'none',
      border: 'none',
      color: 'black',
    },
    _invalid: {
      borderColor: 'red.500',
    },
    _focus: {
      borderColor: 'black',
      _invalid: {
        borderColor: 'red.500',
      },
    },
    _placeholder: {
      color: 'grey.500',
    },
    _disabled: {
      color: 'grey.400',
      borderColor: 'grey.400',
      cursor: 'not-allowed',
      '&[disabled-search=true]': {
        color: 'grey.700',
        cursor: 'default',
      },
    },
  },
});

const variants = {
  filled: variantFilled,
  flushed: variantFlushed,
};

const defaultProps = {
  size: 'md',
  variant: 'flushed',
};

export default {
  parts: parts.keys,
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
