import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchDateOption } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { analyticsValues, dateOptionsMenu } from 'src/pages/regular-batch-payments/components/header/components/utils';

type Props = {
  onChange: (dateOption: BatchDateOption) => void;
  disableButton: boolean;
};

export const PaymentDeductionDateMenu = ({ onChange, disableButton }: Props) => {
  const isMultipleDates = useSelector(regularBatchPaymentsStore.selectors.isMultipleDeductionDate);
  const isMultipleFundingSource = useSelector(regularBatchPaymentsStore.selectors.isMultipleFundingSource);

  const { dateOption } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const defaultDate = dateOptionsMenu.find((date) => date.value === dateOption);

  const handleDateOptionSelected = useCallback(
    (value: string) => () => {
      onChange(value as BatchDateOption);
    },
    [onChange]
  );

  return (
    <Menu placement="bottom-end" autoSelect={false}>
      <Tooltip
        label={isMultipleFundingSource ? <MIFormattedText label="regularBatchPayments.BatchSettings.tooltip" /> : null}
        placement="top"
        shouldWrapChildren
        closeOnClick={false}
      >
        <Box>
          <MenuButton
            disabled={isMultipleFundingSource || disableButton}
            as={Button}
            variant={ButtonVariants.tertiary}
            rightIcon={<ChevronDownIcon width={8} height={8} />}
            size={ButtonSizes.sm}
            textStyle="body4semi"
            data-testid="select-deduction-date-menu-button"
            skipAnalyticsEvent
            paddingX={3}
          >
            {isMultipleDates ? (
              <MIFormattedText label="regularBatchPayments.BatchSettings.multipleDates" />
            ) : (
              <MIFormattedText label={defaultDate!.label} />
            )}
          </MenuButton>
        </Box>
      </Tooltip>
      <MenuList>
        {dateOptionsMenu.map(({ value, label }) => (
          <MenuItem
            key={value}
            onClick={handleDateOptionSelected(value)}
            as={Button}
            isActive={value === dateOption}
            label={label}
            testId={`deduction-date-menu-${value}`}
            analyticsProperties={{ deductionDateType: analyticsValues[label] }}
            skipAnalyticsEvent={value === dateOption}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
