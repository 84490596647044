import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { useProcessingFeeData } from 'src/pages/get-paid/hooks/useProcessingFeeData';
import { NotificationCardTypes, OrgPreferencesTypeKeys } from 'src/utils/consts';

export const AchTransferFeeNotification = ({ removeNotification }: { removeNotification: () => void }) => {
  const { arProcessingFee } = useProcessingFeeData();

  const { updateOrganizationPreference } = useUpdateOrganizationPreference();

  const handleOnClick = async () => {
    removeNotification();
    await updateOrganizationPreference({
      key: OrgPreferencesTypeKeys.isArProcessingFeeExplanationBannerDismissed,
      value: 'true',
    });
  };

  return (
    <Box mb={8} data-testid="ach-transfer-fee-notification">
      <MINotificationCard
        title={{
          label: 'getPro.processingFeeBanner.title',
        }}
        subtitle={{
          label: 'getPro.processingFeeBanner.description',
          values: {
            processingFeePercentNumber: arProcessingFee.value,
            processingFeeCapNumber: arProcessingFee.cap,
            gotIt: (
              <Button
                data-testid="got-it-button"
                variant={ButtonVariants.tertiaryNaked}
                label="getPro.processingFeeBanner.gotIt"
                sx={{ padding: 0, minWidth: 0, height: 'auto', textDecoration: 'underline' }}
                onClick={handleOnClick}
              />
            ),
          },
        }}
        type={NotificationCardTypes.INFO}
      />
    </Box>
  );
};
