import styled, { css } from 'styled-components';
import { MIFieldOrEmpty } from 'src/components/common/MIFieldOrEmpty';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { EditElement } from 'src/components/layout/PaymentActivityElements';
import { PaymentType } from 'src/utils/types';

type Props = {
  onEdit: () => void;
  payment: PaymentType;
  isEditDisabled: boolean;
};

export const PayBillPaymentActivityMemoInfo = ({ payment, onEdit, isEditDisabled }: Props) => (
  <NoteBoxRow data-testid="payment-activity-memo-info">
    <NoteBoxColumn>
      <FieldName>
        <MIFormattedText label="payBillPaymentActivity.memo.label" />
      </FieldName>
      <FieldValue>
        <MIFieldOrEmpty value={payment.note || ''} label="payBillPaymentActivity.memo.emptyPlaceholder" privateData />
      </FieldValue>
    </NoteBoxColumn>
    <EditElement testId="edit-memo" onEdit={onEdit} isDisabled={isEditDisabled} />
  </NoteBoxRow>
);

const NoteBoxRow = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.2rem;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxRow}
`;

const BoxColumn = styled.div`
  flex: 1;
  min-width: 0;
  width: 100%;
  margin: 0.6rem 0 0rem;
  ${(props) => props.theme?.components?.PaymentActivity?.BoxColumn}
`;

const NoteBoxColumn = styled(BoxColumn)`
  margin-top: 0;
  ${(props) => props.theme?.components?.PaymentActivity?.NoteBoxColumn}
`;

const baseTextStyles = css`
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};
  line-height: 1.8rem;
  ${(props) => props.theme?.components?.PaymentActivity?.baseTextStyles}
`;

const FieldName = styled.div`
  ${baseTextStyles}
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  ${(props) => props.theme?.components?.PaymentActivity?.FieldName}
`;

const FieldValue = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-weight: ${(props) => props.theme.text.weight.regular};
  ${(props) => props.theme.text.fontType.regular};
  ${(props) => props.theme?.components?.PaymentActivity?.FieldValue}
`;
