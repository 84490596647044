import { useEffect } from 'react';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { VirtualCardCompletedViewHeader } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardCompletedViewHeader';
import { VirtualCardPaymentActivity } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardPaymentActivity';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { eventPage } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardView';
import { analytics } from 'src/services/analytics';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardDepositedView = ({ state, analyticsProperties }: Props) => {
  const { payment } = state;
  useEffect(() => {
    analytics.track(eventPage, 'deposited', analyticsProperties);
  }, [analyticsProperties]);

  return (
    <ContentWrapper>
      <VirtualCardCompletedViewHeader state={state} isDeposited />
      <VirtualCardPaymentActivity payment={payment} />
    </ContentWrapper>
  );
};
