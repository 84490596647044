import { DeliveryType, VerifiedStatus } from 'src/utils/consts';
import { PendingDeliveryMethodType } from 'src/utils/types';

export function pendingDeliveryMethodFactory(
  initialValues?: Partial<PendingDeliveryMethodType>
): PendingDeliveryMethodType {
  return {
    id: null,
    logo: '',
    deliveryType: DeliveryType.ACH,
    paperCheck: null,
    bankAccount: null,
    plaidAccount: null,
    hasScheduledPayments: false,
    isFilledByVendor: false,
    isVerified: false,
    verifiedStatus: VerifiedStatus.NOT_VERIFIED,
    createdById: '',
    updatedAt: null,
    cardAccountId: null,
    intuitAccountId: null,
    virtualCardAccount: null,
    internationalAccount: null,
    cardAccount: null,
    ...initialValues,
  };
}
