import { USERS_HIDE_FIRM_CLIENT_SUCCESS, USERS_UNHIDE_FIRM_CLIENT_SUCCESS } from 'src/modules/users/users-actions';

export const ACTION_NOTIFICATION_MAPPING = {
  [USERS_HIDE_FIRM_CLIENT_SUCCESS]: {
    type: 'info',
    msg: 'bookkeeper.company.hideClientDialog.toastr',
  },
  [USERS_UNHIDE_FIRM_CLIENT_SUCCESS]: {
    type: 'success',
    msg: 'bookkeeper.company.unhideClientDialog.toastr',
  },
};
