import { featureFlags } from '@melio/shared-web';
import { addDays, isBefore } from 'date-fns';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import sortBy from 'lodash/fp/sortBy';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MethodListItemType } from 'src/components/common/SelectMethods/components/MethodsList';
import { INSTALLMENT_METHOD_FIRST_COUNT } from 'src/pages/bill/pay/hooks/useFinancingFundingSourceViewCount';
import { getAccountNumber4digits } from './bank-account';
import {
  CardNetworkTypes,
  CardTypes,
  FeatureFlags,
  FUNDING_LOGO,
  FUNDING_SOURCE_LIMIT,
  FundingSourceOrigin,
  FundingType,
} from './consts';
import { PermissionsType } from './permissions';
import { AccountType } from './types';

const isManualBankAccountNotVerified = (fundingSource?: AccountType | null) =>
  !!(
    fundingSource &&
    fundingSource.fundingType === FundingType.ACH &&
    fundingSource.origin === FundingSourceOrigin.MANUAL &&
    !fundingSource.isVerified
  );

const canVerifyManualBankAccount = (fundingSource?: AccountType | null) =>
  !!(
    fundingSource &&
    isManualBankAccountNotVerified(fundingSource) &&
    fundingSource.bankAccount &&
    fundingSource.bankAccount.canVerify &&
    !fundingSource.bankAccount.isBlocked
  );

const isBankAccountBlocked = (fundingSource?: AccountType | null) =>
  !!(fundingSource && fundingSource.bankAccount && fundingSource.bankAccount.isBlocked);

const isSandboxIndicator = (site: any) => site.config.services.tabapay.url.indexOf('sandbox') !== -1;

type SortedListItem = Omit<MethodListItemType, 'methods' | 'componentType'> & {
  methods: AccountType[];
  componentType: { methodType: FundingType | CardTypes };
};

const isInstallmentMethodFirst = (viewCount: number) => viewCount < INSTALLMENT_METHOD_FIRST_COUNT;

const sortMethodsOfListItem = (listItems: SortedListItem[], firstFundingSourceId?: number | null) =>
  map(
    (listItem) => ({
      ...listItem,
      methods: sortBy((method) => (method.id === firstFundingSourceId ? 0 : 1), listItem.methods),
    }),
    listItems
  );
const sortListItems = (
  listItems: SortedListItem[],
  firstFundingSourceId?: number | null,
  isFinancingFundingSourceFTX = false,
  financingFundingSourceFTXViewsCount = 0
) => {
  const [isCreditCardFirst] = featureFlags.useFeature(FeatureFlags.CreditCardFirst, false);

  return sortBy((listItem) => {
    if (listItem.isInstallmentDisabled) return listItems.length;

    if (listItem.methods.find((method) => method.id === firstFundingSourceId)) {
      return isFinancingFundingSourceFTX && isInstallmentMethodFirst(financingFundingSourceFTXViewsCount) ? 1 : 0;
    }

    if (listItem.componentType.methodType === FundingType.INSTALLMENTS) {
      return isFinancingFundingSourceFTX && isInstallmentMethodFirst(financingFundingSourceFTXViewsCount) ? 0 : 1;
    }

    if (!isEmpty(listItem.methods)) return 2;

    return isCreditCardFirst && listItem.componentType.methodType === CardTypes.CREDIT ? 2 : 3;
  }, listItems);
};
const sortFundingSourceSelectOptions = ({
  list,
  firstFundingSourceId,
  isFinancingFundingSourceFTX = false,
  financingFundingSourceFTXViewsCount = 0,
}: {
  list: SortedListItem[];
  firstFundingSourceId?: number | null;
  isFinancingFundingSourceFTX?: boolean;
  financingFundingSourceFTXViewsCount?: number;
}): SortedListItem[] =>
  sortListItems(
    sortMethodsOfListItem(list, firstFundingSourceId),
    firstFundingSourceId,
    isFinancingFundingSourceFTX,
    financingFundingSourceFTXViewsCount
  );

const getModifyFundingSourceOptions = (
  permissions: PermissionsType,
  modifyActions: Record<string, () => void>,
  isConnectedAccountingPlatform: boolean,
  disabled: boolean,
  origin?: string | null,
  isExpired?: boolean | null,
  hasLabel?: boolean,
  isDefault?: boolean
) => {
  const actionOptions: ActionOption[] = [];

  if (modifyActions) {
    if (origin === FundingSourceOrigin.MANUAL && !isExpired) {
      actionOptions.push({
        label: 'settings.paymentMethods.view',
        action: () => modifyActions.view(),
      });
    }

    if (isConnectedAccountingPlatform && !disabled && permissions.fundingSources.update()) {
      actionOptions.push({
        label: 'settings.paymentMethods.editBankAccountLink',
        action: () => modifyActions.edit(),
      });
    }

    if (!disabled && permissions.fundingSources.update()) {
      actionOptions.push({
        label: hasLabel ? 'settings.paymentMethods.editLabel' : 'settings.paymentMethods.addLabel',
        action: () => modifyActions.editLabel(),
      });
    }

    if (!isDefault && !disabled && modifyActions.setDefault && permissions.fundingSources.update()) {
      actionOptions.push({
        label: 'settings.paymentMethods.setAsDefault',
        action: () => modifyActions.setDefault(),
      });
    }

    if (permissions.fundingSources.delete()) {
      actionOptions.push({
        label: 'settings.paymentMethods.delete',
        action: () => modifyActions.delete(),
        negative: true,
      });
    }
  }

  return actionOptions;
};

const getNameParts = (fundingSource: AccountType) => {
  if (fundingSource?.bankAccount) {
    return {
      displayName: fundingSource.displayName.trim() || '',
      institutionName: fundingSource?.plaidAccount?.plaidItem?.institutionName || undefined,
      identifier: getAccountNumber4digits(fundingSource.bankAccount),
    };
  } else if (fundingSource?.cardAccount) {
    return {
      displayName: '',
      institutionName: fundingSource.cardAccount.network,
      identifier: fundingSource.cardAccount.card4digits,
    };
  }

  return {
    displayName: '',
    institutionName: '',
    identifier: '',
  };
};

const getInstitutionName = (fundingSource: AccountType) => fundingSource.plaidAccount?.plaidItem.institutionName ?? '';

const getFundingSourceName = (fundingSource: AccountType, showDisplayName = true) => {
  const displayName = fundingSource.displayName || '';
  const institutionName = getInstitutionName(fundingSource);
  const cutAccountNumber = getAccountNumber4digits(fundingSource.bankAccount);
  let info = '';

  if (fundingSource.bankAccount) {
    info = institutionName ? `(${institutionName}, ...${cutAccountNumber})` : `(...${cutAccountNumber})`;

    if (showDisplayName) {
      info = `${displayName} ${info}`;
    }
  } else if (fundingSource.cardAccount) {
    info = `${fundingSource.cardAccount.network} (...${fundingSource.cardAccount.card4digits})`;
  }

  return info;
};

const getFundingSourceType = (fundingSource): string | undefined =>
  fundingSource?.fundingType === FundingType.CARD ? fundingSource?.cardAccount?.cardType : fundingSource?.fundingType;

const getFundingSourceNetwork = (fundingSource?: AccountType) => {
  if (fundingSource?.fundingType === FundingType.INSTALLMENTS) {
    return null;
  }

  if (fundingSource?.fundingType === FundingType.CARD) {
    return fundingSource?.cardAccount?.network;
  }

  return FundingType.ACH;
};

const isUnverifiedAchLimitExceeded = (fundingSources: readonly AccountType[]) =>
  fundingSources.filter((fs) => fs.fundingType === FundingType.ACH && !fs.isVerified).length >=
  FUNDING_SOURCE_LIMIT.ACH_UNVERIFIED;

const getFundingSourcesByType = (fundingSources: readonly AccountType[], type: FundingType) =>
  fundingSources.filter((fundingSource) => fundingSource.fundingType === type);

const getAmexCreditFundingSourcesIds = (fundingSources: readonly AccountType[]) =>
  fundingSources.filter((fs) => isCreditAmexNetwork(fs)).map((fs) => fs?.id);

const isAmexNetwork = (fundingSource?: AccountType) => fundingSource?.cardAccount?.network === CardNetworkTypes.AMEX;
const isCreditAmexNetwork = (fundingSource?: AccountType) =>
  fundingSource?.cardAccount?.network === CardNetworkTypes.AMEX &&
  fundingSource?.cardAccount?.cardType === CardTypes.CREDIT;

const getDebitCardFundingSourceId = (fundingSources: readonly AccountType[]) =>
  fundingSources.filter((fs) => isDebitCard(fs)).map((fs) => fs?.id);

const isCreditCard = (fundingSource?: AccountType) => fundingSource?.cardAccount?.cardType === CardTypes.CREDIT;
const isDebitCard = (fundingSource?: AccountType) => fundingSource?.cardAccount?.cardType === CardTypes.DEBIT;
const isMastercard = (fundingSource?: AccountType) =>
  fundingSource?.cardAccount?.network === CardNetworkTypes.MASTERCARD;

const getIsRecentlySentMicroDeposits = (depositsDeliverDate: Date) =>
  isBefore(new Date(), addDays(new Date(depositsDeliverDate), 3));

function hasFundingLogo(withLogo: { logo?: string | null }): boolean {
  return !isEmpty(withLogo.logo) && withLogo.logo !== FUNDING_LOGO.DEFAULT;
}

function getFundingSourceIcon(fundingSource: AccountType) {
  if (isDebitCard(fundingSource)) {
    return {
      icon: 'icon-bank-icon',
      imageSrc: '',
    };
  }

  const hasLogo = hasFundingLogo(fundingSource);
  const bankLogo = hasLogo ? `data:image/jpeg;base64,${fundingSource.logo}` : '';

  return {
    icon: bankLogo ? '' : 'icon-bank-icon',
    imageSrc: bankLogo,
  };
}

export {
  canVerifyManualBankAccount,
  getAmexCreditFundingSourcesIds,
  getDebitCardFundingSourceId,
  getFundingSourceIcon,
  getFundingSourceName,
  getFundingSourceNetwork,
  getFundingSourcesByType,
  getFundingSourceType,
  getInstitutionName,
  getIsRecentlySentMicroDeposits,
  getModifyFundingSourceOptions,
  getNameParts,
  hasFundingLogo,
  isAmexNetwork,
  isBankAccountBlocked,
  isCreditAmexNetwork,
  isCreditCard,
  isDebitCard,
  isManualBankAccountNotVerified,
  isMastercard,
  isSandboxIndicator,
  isUnverifiedAchLimitExceeded,
  sortFundingSourceSelectOptions,
  sortListItems,
  sortMethodsOfListItem,
};
