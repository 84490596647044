import { MIAddressAutocompleteSmartyStreets } from 'src/components/common/MIAddressAutocompleteSmartyStreets';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import Tooltip from 'src/core/ds/tooltip';
import {
  datOfBirthMask,
  UBOModelType,
  uboTaxIdMask,
} from 'src/pages/vendor/international-delivery-method/ultimate-beneficial-owners/utils';
import { ModelView } from 'src/ui/form';
import { FormContainer, FormRow } from 'src/ui/form/FormElements';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';

type Props = {
  model: ModelView<UBOModelType>;
  index: number;
  onChangeAddress: (GoogleCombinedAddressType, index) => void;
  addressError?: string;
};

export const UBOForm = ({ model, index, onChangeAddress, addressError }: Props) => (
  <>
    <Flex textStyle="toastAction" color="grey.700" mb={4}>
      <MIFormattedText label="vendors.deliveryMethods.international.ubo.description" />
      <Tooltip
        label={<MIFormattedText label="vendors.deliveryMethods.international.ubo.descriptionTooltip" />}
        shouldWrapChildren
        placement="top"
        data-testid="owner-tooltip"
      >
        <Box ml={2} data-testid="vendors.deliveryMethods.international.ubo.descriptionTooltip">
          <Icon name={IconNames.helpCircle} size={IconSize.s} />
        </Box>
      </Tooltip>
    </Flex>
    <FormContainer id={`form${index}`}>
      <FormRow>
        <WizardTextInputField
          model={model[`firstName${index}`]}
          label="vendors.deliveryMethods.international.ubo.firstNameLabel"
          required
          autoFocus
        />
        <WizardTextInputField
          model={model[`lastName${index}`]}
          label="vendors.deliveryMethods.international.ubo.lastNameLabel"
          required
        />
      </FormRow>
      <FormRow>
        <WizardTextInputField
          model={model[`dateOfBirth${index}`]}
          label="vendors.deliveryMethods.international.ubo.dateOfBirthLabel"
          placeholder="vendors.deliveryMethods.international.ubo.dateOfBirthPlaceholder"
          mask={datOfBirthMask}
          required
        />
        <WizardTextInputField
          model={model[`taxId${index}`]}
          label="vendors.deliveryMethods.international.ubo.taxIdLabel"
          placeholder="vendors.deliveryMethods.international.ubo.taxIdPlaceholder"
          mask={uboTaxIdMask}
          required
          afterLabelComponent={
            <Tooltip
              placement="top"
              label={<MIFormattedText label="vendors.deliveryMethods.international.ubo.taxIdTooltip" />}
              shouldWrapChildren
              data-testid="taxId-tooltip"
            >
              <Box
                display="inline-block"
                position="absolute"
                ml={2}
                data-testid="vendors.deliveryMethods.international.ubo.taxIdTooltip"
              >
                <Icon color="grey.700" name={IconNames.helpCircle} size={IconSize.s} />
              </Box>
            </Tooltip>
          }
        />
      </FormRow>
      <FormRow>
        <MIAddressAutocompleteSmartyStreets
          id={`address${index}`}
          label="vendors.deliveryMethods.international.ubo.addressLabel"
          placeholder="vendors.deliveryMethods.international.ubo.addressPlaceholder"
          model={model[`address${index}`]}
          onSelected={(address) => onChangeAddress(address, index)}
          required
          autocomplete="off"
          eventPage="vendor-delivery-method"
        />
      </FormRow>
      {!addressError && (
        <Box textStyle="body3" color="grey.600" mt={-5}>
          <MIFormattedText label="vendors.deliveryMethods.international.ubo.addressNotice" />
        </Box>
      )}
    </FormContainer>
  </>
);
