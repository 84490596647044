import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { TokenValuesType } from 'src/flows/shift-vendor-suvc-to-ach/types';
import { isQBPaymentSource } from 'src/flows/shift-vendor-suvc-to-ach/utils';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { analytics } from 'src/services/analytics';
import { DateFormats } from 'src/utils/date-fns';

export const useSuvcToAchAnalytics = (tokenValues: TokenValuesType) => {
  const { paymentId, orgId, userId, vendorId, businessId } = tokenValues;
  const dateTime = format(new Date(), DateFormats.isoDateWithHoursAndMinutes);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));

  const analyticsProps = {
    dateTime,
    newDeliveryMethod: 'ACH',
    userId,
    paymentId,
    vendorId,
    email: payment?.vendor?.contactEmail,
    orgId,
    businessId,
    paymentOrigin: isQBPaymentSource(payment?.origin),
  };
  const trackEvent = (page: string, event: string, extraProperties?: Record<string, string>) => {
    analytics.track(page, event, { ...analyticsProps, ...extraProperties });
  };

  const pageEvent = (page: string, event: string, extraProperties?: Record<string, string>) => {
    analytics.page(page, event, { ...analyticsProps, ...extraProperties });
  };

  return { trackEvent, pageEvent };
};
