import { Option } from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { DirectoryType } from 'src/utils/consts';

type Props = {
  option: Option;
  isSelected: boolean;
};

export const LocalVendorOptionLabel = ({ option, isSelected }: Props) => {
  const shouldShowDirectoryIndication =
    option.directoryId ||
    option.isRppsVendor ||
    [DirectoryType.Biller, DirectoryType.Business].includes(option.directoryType);

  return isSelected ? (
    <Flex align="center">
      <Box isTruncated>{option.label}</Box>
      {shouldShowDirectoryIndication ? (
        <Box pl={2}>
          <Icon
            name={IconNames.verifiedSolid}
            pl={4}
            ml="auto"
            flexShrink={0}
            color="primary.500"
            size={IconSize.lg}
            data-testid="directory-vendor-sign"
          />
        </Box>
      ) : null}
    </Flex>
  ) : (
    <Flex pr={4} pl={4} align="center" h="5.6rem" data-testid={`vendor-local-option-${option.value}`}>
      <Box isTruncated textStyle="body3">
        {option.label}
      </Box>
      <Flex pl={2}>
        {shouldShowDirectoryIndication ? (
          <Icon
            name={IconNames.verifiedSolid}
            pl={4}
            ml="auto"
            flexShrink={0}
            color="primary.500"
            size={IconSize.s}
            data-testid="directory-vendor-sign"
          />
        ) : null}
      </Flex>
    </Flex>
  );
};
