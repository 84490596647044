import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { CHECK_SYNC_STATUS_INTERVAL } from 'src/pages/settings/components/accounting-software/consts';
import { usePollingSyncStatus } from 'src/pages/settings/hooks/usePollingSyncStatus';
import { getOrgId } from 'src/redux/user/selectors';
import { AccountingSoftware, NotificationCardTypes, SyncStatus } from 'src/utils/consts';
import { getSyncAnimation } from './utils';

type Props = {
  onNext: () => void;
};

export const SyncSSOInProgressPage = ({ onNext }: Props) => {
  const orgId = useSelector(getOrgId);
  const [syncStatus, setSyncStatus] = useState<SyncStatus | undefined>(undefined);
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const accountingSoftwareName = connectedAccountingPlatform?.name;
  const connectionFailed = syncStatus === SyncStatus.FAILURE;

  const notification = connectionFailed
    ? {
        type: NotificationCardTypes.ERROR,
        title: {
          label: 'settings.newAccountingSoftware.SSOSync.inProgress.errorTitle',
        },
        subtitle: {
          label: 'settings.newAccountingSoftware.SSOSync.inProgress.errorSubtitle',
        },
      }
    : undefined;

  const getIsSyncing = async () => {
    try {
      const { syncStatus } = await accountingPlatformsApi.getSyncStatus({ orgId });
      setSyncStatus(syncStatus);

      return syncStatus !== SyncStatus.SYNCED;
    } catch {
      return true;
    }
  };

  const { isSyncing } = usePollingSyncStatus({
    getIsSyncing,
    interval: CHECK_SYNC_STATUS_INTERVAL,
    isSyncingDefault: true,
  });

  useEffect(() => {
    if (!isSyncing) {
      onNext();
    }
  }, [isSyncing, onNext]);

  return (
    <StepLayoutPage
      title="settings.newAccountingSoftware.SSOSync.inProgress.title"
      subtitle="settings.newAccountingSoftware.SSOSync.inProgress.subtitle"
      nextLabel="settings.newAccountingSoftware.SSOSync.inProgress.action"
      relativeStep={1 / 1}
      displaySupportLink
      animatedIllustration={getSyncAnimation(accountingSoftwareName as AccountingSoftware, !connectionFailed)}
      notification={notification}
      testId="new-accounting-software-sync-in-progress"
    />
  );
};
