import isEmpty from 'lodash/isEmpty';
import { CouponStatus } from 'src/utils/consts';
import { CouponType } from 'src/utils/types';

export const getCashBackCoupon = (coupons: CouponType[] | null) => {
  if (!isEmpty(coupons)) {
    return (
      coupons && coupons.find((coupon) => coupon.template.name === 'cashback' && coupon.status === CouponStatus.CREATED)
    );
  }

  return null;
};

export const getCouponAmount = (coupon: CouponType | null | undefined) => {
  try {
    return coupon && JSON.parse(coupon.metadataHistory[0].metadata).amount;
  } catch (e) {
    return null;
  }
};
