import { ReactNode } from 'react';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { VendorCompanyInfoConfigProvider } from './context/VendorCompanyInfoContext';
import { useCreateVendorCompanyInfoConfig } from './hooks/useVendorCompanyInfoConfig';

type Props = {
  children: ReactNode;
};

export function VendorCompanyRouteWrapper({ children }: Props) {
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');
  const config = useCreateVendorCompanyInfoConfig(origin);

  return <VendorCompanyInfoConfigProvider value={config}>{children}</VendorCompanyInfoConfigProvider>;
}
