import { featureFlags } from '@melio/shared-web';
import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore, { getUserActions } from 'src/modules/users/users-store';
import { validateUserOrgData } from 'src/modules/users/utils/validateUserOrgData';
import { useIsAccountingFirmSelected } from 'src/pages/team/team-management/hooks/useIsAccountingFirmSelected';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { FeatureFlags, Role } from 'src/utils/consts';
import { usePermittedRoleOptions } from 'src/utils/permissions';
import { UserOrganization } from 'src/utils/types';
import { RoleDowngradeModalMessage } from '../RoleDowngradeModalMessage';
import { EditUserOrganizationModalMessage } from './EditUserOrganizationModalMessage';

type Props = {
  dismiss: (event?: MouseEvent) => void;
  userId: number;
  orgId: number;
  userRole: Role;
};

export function EditUserOrganizationModal({ dismiss, userId, orgId, userRole }: Props) {
  const [modifiedUserOrganization, setModifiedUserOrganization] = useState<UserOrganization | undefined>();
  const [approvalWorkflowsEnabled] = featureFlags.useFeature(FeatureFlags.AprovalWorkflows, false);
  const user = useSelector(usersStore.selectors.byId(userId));
  const currentUserOrg = useSelector(profileStore.selectors.getUserOrganization);
  const isAccountant = useSelector(profileStore.selectors.getUserRole) === Role.ACCOUNTANT;
  const { roleOptions } = usePermittedRoleOptions({ showFirmAdminRole: true });
  const { isAccountingFirmSelected } = useIsAccountingFirmSelected();
  const userHasApprovalWorkflows = useSelector(approvalWorkflowsStore.selectors.userHasWorkflows(userId));

  const isAdminApprovalDisabled = isAccountant && currentUserOrg?.requireApproval;

  const { userOrg, isUpdating } = useStructuredSelectors(usersStore.selectors.userOrganization(userId, orgId));
  const actions = getUserActions(useDispatch());

  const handleSubmit = async (value: UserOrganization): Promise<any> => {
    if (userRole === Role.ADMIN && !isAdminRole(value.role)) {
      setModifiedUserOrganization(value);
    } else {
      await updateUserOrganization(value);
    }
  };

  const updateUserOrganization = async (value: UserOrganization) => {
    const requireApproval = value.role === Role.ADMIN ? false : value.requireApproval;
    const approvalAmountThreshold = requireApproval ? Number(value.approvalAmountThreshold) : null;
    const data = { ...value, requireApproval, approvalAmountThreshold };
    validateUserOrgData('userOrganization', data, currentUserOrg);
    await actions.updateUserOrganization(data);
    // The dismiss function is called here instead of being passed down in order to avoid unexpected useForm behavior
    dismiss();
  };

  return !modifiedUserOrganization ? (
    <EditUserOrganizationModalMessage
      approvalWorkflowsEnabled={approvalWorkflowsEnabled}
      isAdminApprovalDisabled={isAdminApprovalDisabled}
      dismiss={dismiss}
      onSubmit={handleSubmit}
      editedUserOrg={userOrg}
      roleOptions={roleOptions}
      user={user}
      hasApprovalWorkflows={userHasApprovalWorkflows}
      isAccountingFirmSelected={isAccountingFirmSelected}
      isLoading={isUpdating}
    />
  ) : (
    <RoleDowngradeModalMessage
      userOrganization={modifiedUserOrganization}
      onSave={updateUserOrganization}
      onCancel={dismiss}
      userFullName={`${user.firstName} ${user.lastName}`}
      userId={user.id}
      isLoading={isUpdating}
    />
  );
}
