import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodContainer,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box';
import { InternationalPayeeCountryHint } from 'src/pages/bill/components/InternationalPayeeCountryHint';
import { getDeliveryMethodDescription, getDeliveryMethodLabelWithValues } from 'src/pages/bill/utils';
import { DeliveryMethodIcon } from 'src/pages/vendor/components/DeliveryMethodIcon';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getDeliveryMethodDisplay } from 'src/pages/vendor/records';
import { isVirtualCardCleared } from 'src/pages/vendor/virtual-card/utils';
import { BillStatus, DeliveryType, PaymentStatus } from 'src/utils/consts';
import { BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import { ViewBillPaymentActivityFailureDate } from './ViewBillPaymentActivityFailureDate';

type Props = {
  bill: BillType;
  deliveryMethod: DeliveryMethodType;
  payment: PaymentType;
  failureDate?: Date | string;
};

export const ViewBillPaymentActivityDeliveryMethodInfo = ({ deliveryMethod, bill, payment, failureDate }: Props) => {
  const { deliveryType } = deliveryMethod;
  const isBillPaid = bill.status === BillStatus.PAID;
  const isVirtualCardDeliveryMethod = deliveryType === DeliveryType.VIRTUAL_CARD;
  const isInternationalMethod = isInternationalDeliveryMethod(deliveryType);
  const isPaymentCompleted = payment.status === PaymentStatus.COMPLETED;
  const isPaymentDeliverStatusCleared = isVirtualCardCleared(payment);
  const isVirtualCardPaymentCompleted =
    isVirtualCardDeliveryMethod && isBillPaid && isPaymentCompleted && isPaymentDeliverStatusCleared;
  const labelWithValues = getDeliveryMethodLabelWithValues(deliveryMethod, isBillPaid, payment);

  const deliveryMethodInfo = getDeliveryMethodDisplay({
    deliveryMethod,
    vendorName: bill?.vendor?.companyName,
    managedBy: bill?.vendor?.managedBy,
    shorten: true,
  });
  const deliveryMethodInfoDescription = getDeliveryMethodDescription(deliveryMethod, isBillPaid, payment);

  return (
    <PaymentActivityContainer data-testid="bill-pay-delivery-method-info">
      <PaymentActivityMainContainer>
        <PaymentInfoContainer>
          <PaymentIcon isBillPaid={isBillPaid}>
            <DeliveryMethodIcon deliveryMethod={deliveryMethod} />
          </PaymentIcon>
          <PaymentTextContainer>
            <PaymentMethodContainer>
              <PaymentMethodText data-testid="bill-payment-method">
                <MIFormattedText label={labelWithValues.label} values={labelWithValues.values} />
              </PaymentMethodText>
            </PaymentMethodContainer>
            <PaymentInfoText
              data-testid="bill-pay-delivery-method"
              isBillPaid={isVirtualCardDeliveryMethod ? isVirtualCardPaymentCompleted : isBillPaid}
            >
              {deliveryMethodInfo}
              {isInternationalMethod && (
                <InternationalPayeeCountryHint
                  payeeBankCountryCode={deliveryMethod.internationalAccount?.payeeBankCountry || ''}
                />
              )}
            </PaymentInfoText>
            {deliveryMethodInfoDescription.label && (
              <Box
                data-testid="bill-pay-delivery-method-description"
                textStyle="body4"
                color={isBillPaid ? 'grey.500' : 'black'}
              >
                <MIFormattedText
                  label={deliveryMethodInfoDescription.label}
                  values={deliveryMethodInfoDescription?.values}
                />
              </Box>
            )}
            {failureDate && <ViewBillPaymentActivityFailureDate failureDate={failureDate} />}
          </PaymentTextContainer>
        </PaymentInfoContainer>
      </PaymentActivityMainContainer>
    </PaymentActivityContainer>
  );
};
