import { convertNumberToCurrency, formatToAmountStandard } from 'src/utils/currency-utils';

const formatWithCommas = (value: string) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
const formatMaxValue = (maxValue: number) => convertNumberToCurrency(maxValue).replace(/\$/g, '');
const formatTotalAmount = (value?: string) => formatToAmountStandard(value || '');
const getInputValues = (maxValue?: number) =>
  maxValue
    ? {
        id: 'boundedTotalAmount',
        placeholder: 'guests.register.openBalancePlaceholder',
        placeholderValues: { balance: formatMaxValue(maxValue) },
      }
    : {
        id: 'totalAmount',
        placeholder: 'guests.register.amountPlaceholder',
        placeholderValues: {},
      };

export { formatMaxValue, formatTotalAmount, formatWithCommas, getInputValues };
