import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Link } from 'src/core/ds/link';
import { Modal } from 'src/core/ds/modal';
import { Separator } from 'src/core/ds/separator';
import {
  financingAnalyticsEvents,
  financingPage,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { learnMoreInstallmentInMelio } from 'src/pages/bill/pay/installments/consts';
import { analytics } from 'src/services/analytics';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onApply: () => void;
  learnMoreLinkAnalytics: AnalyticsProps;
};

type AnalyticsProps = {
  paymentAmount: number;
  installmentAmountPresented: number;
  payBillFlowUUID: string | undefined;
};

type DetailsListProps = {
  title: string;
  list: string;
};

const LearnMoreLink = ({ paymentAmount, installmentAmountPresented, payBillFlowUUID }: AnalyticsProps) => (
  <Box textStyle="body3" as="span">
    <Link
      data-testid="financing-funding-source-modal-learn-more"
      href={learnMoreInstallmentInMelio}
      isExternal
      onClick={(event) => {
        event.stopPropagation();
        analytics.track(financingPage.addFunding, financingAnalyticsEvents.installmentsLearnMoreClicked, {
          paymentAmount,
          installmentAmountPresented,
          ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
        });
      }}
    >
      <Flex alignItems="center">
        <MIFormattedText label="financing.fundingSource.modal.learnMore" />
        <Icon color="primary.500" ml={1} name={IconNames.arrowRight} size={IconSize.s} />
      </Flex>
    </Link>
  </Box>
);

const DetailsList = ({ title, list }: DetailsListProps) => (
  <>
    <Box textStyle="body2Semi">
      <MIFormattedText label={title} />
    </Box>
    <Box as="ul" fontSize="1.4rem" pl={6} m={0} mt={4}>
      <MIFormattedText
        label={list}
        values={{
          item: (...chunks) => (
            <Box as="li" mb={3}>
              {chunks}
            </Box>
          ),
        }}
      />
    </Box>
  </>
);

export const FinancingFundingSourceModal = ({ isOpen, onApply, onClose, learnMoreLinkAnalytics }: Props) => {
  const { paymentAmount, installmentAmountPresented, payBillFlowUUID } = learnMoreLinkAnalytics;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" closeOnOverlayClick={false} closeOnEsc={false}>
      <Modal.Header style={{ marginBottom: '2.4rem' }}>
        <Modal.HeaderTitle>
          <Flex data-testid="financing-funding-source-modal-title">
            <Icon name={IconNames.installments} size={IconSize.xl} mr={4} />
            <Box textStyle="h2semi">
              <MIFormattedText label="financing.fundingSource.modal.title" />
            </Box>
          </Flex>
          <Box textStyle="body3" mt={6}>
            <MIFormattedText label="financing.fundingSource.modal.subtitle" />
          </Box>
        </Modal.HeaderTitle>
      </Modal.Header>
      <Modal.CloseButton />
      <Modal.Body>
        <Flex direction="column" alignItems="flex-start" w="full">
          <Separator mb={6} />
          <DetailsList
            title="financing.fundingSource.modal.repayment.title"
            list="financing.fundingSource.modal.repayment.list"
          />
          <Separator my={6} />
          <DetailsList
            title="financing.fundingSource.modal.howItWorks.title"
            list="financing.fundingSource.modal.howItWorks.list"
          />
          <Box mt={6}>
            <LearnMoreLink
              installmentAmountPresented={installmentAmountPresented}
              payBillFlowUUID={payBillFlowUUID}
              paymentAmount={paymentAmount}
            />
          </Box>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={ButtonVariants.tertiary} mr={4} data-testid="financing-funding-source-modal-maybe-later">
          <Box color="black" onClick={onClose}>
            <MIFormattedText label="financing.fundingSource.modal.actions.later" />
          </Box>
        </Button>
        <Button variant={ButtonVariants.primary} onClick={onApply} data-testid="financing-funding-source-modal-apply">
          <Box color="white">
            <MIFormattedText label="financing.fundingSource.modal.actions.apply" />
          </Box>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
