import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';
import { useProcessingFeeData } from 'src/pages/get-paid/hooks/useProcessingFeeData';
import { settingsLocations } from 'src/pages/settings/locations';
import { getOrgId } from 'src/redux/user/selectors';

export const ProcessingFeesExplanation = () => {
  const { arProcessingFee, creditCardFee } = useProcessingFeeData();
  const orgId = useSelector(getOrgId);

  return (
    <Box textStyle="body3" color="grey.600" textAlign="center" data-testid="processing-fees-explanation" mt={4}>
      <MIFormattedText
        label="getPaid.new.paymentRequest.processingFeeDescription"
        values={{
          processingFeePercentNumber: arProcessingFee.value,
          processingFeeCapNumber: arProcessingFee.cap,
          creditFeePercent: creditCardFee.value,
          manageCardFees: (
            <Link
              color="black"
              textDecorationLine="underline"
              href={generatePath(settingsLocations.receivingMethods, { orgId })}
              target="_blank"
            >
              <MIFormattedText label="getPaid.new.paymentRequest.manageCardFees" />
            </Link>
          ),
        }}
      />
    </Box>
  );
};
