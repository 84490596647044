import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { AMOUNT_COLOR_BY_VARIANT, AMOUNT_STYLE_BY_SIZE, AmountSize, AmountVariant } from './consts';
import { fractionStyle } from './style';

type Props = {
  amount?: string;
  currency?: string;
  variant?: AmountVariant;
  size?: AmountSize;
};

export const Amount: React.FC<Props> = ({
  amount = '0',
  currency = 'USD',
  variant = AmountVariant.DEFAULT,
  size = AmountSize.MEDIUM,
}) => {
  const formattedAmount = Number(amount || 0).toFixed(2);
  const [displayAmount, fraction] = formattedAmount.split('.');

  return (
    <Flex align="baseline" color={AMOUNT_COLOR_BY_VARIANT[variant]} className={FULL_STORY_MASK_RULE_CLASS}>
      <Box isTruncated textStyle={AMOUNT_STYLE_BY_SIZE[size].whole}>
        <FormattedNumber value={Number(displayAmount)} maximumFractionDigits={0} style="currency" currency={currency} />
      </Box>
      <Box textStyle={AMOUNT_STYLE_BY_SIZE[size].fraction} __css={fractionStyle}>
        {fraction}
      </Box>
    </Flex>
  );
};
