import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { PaymentType } from 'src/utils/types';

type Props = {
  payment: PaymentType;
};

export const InstallmentPaymentActivityHeader = ({ payment }: Props) => (
  <Box
    data-testid="view-payment-details-header"
    textStyle={{
      base: 'body1Semi',
      md: 'h2Semi',
    }}
    verticalAlign="center"
    mb={2}
  >
    <Flex justifyContent="space-between" alignItems="center">
      <MIFormattedText label="financing.installmentAmountSubheader.title" />
    </Flex>
    <Box textStyle="body4" color="grey.700" mt={1}>
      <MIFormattedText label="bills.form.paymentActivity.paymentIdLabel" values={{ paymentId: payment.id }} />
    </Box>
  </Box>
);
