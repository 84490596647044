import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { GetProTabs } from 'src/utils/consts';
import { customersApi } from './api';
import { name } from './constants';

type PaymentRequestSummaryType = {
  totalAmountSum?: string;
  totalCount?: string;
  status?: GetProTabs;
};

export type CustomerPaymentRequestSummaryType = {
  customerId?: string | null;
  invoices?: PaymentRequestSummaryType;
  requests?: PaymentRequestSummaryType;
  payments?: PaymentRequestSummaryType;
};

type CustomerPaymentRequestsSummaryStateType = {
  byId: { [key: string]: CustomerPaymentRequestSummaryType };
  paymentRequestsSummary: {
    loading: boolean;
    error: any;
  };
};
type CustomerPaymentRequestSummaryPayloadType = {
  orgId: number;
  id: string;
};
type CustomerPaymentRequestSummaryResponseType = CustomerPaymentRequestSummaryType;

export const customerPaymentRequestSummary = createApiCallSlice<
  CustomerPaymentRequestSummaryPayloadType,
  CustomerPaymentRequestsSummaryStateType,
  CustomerPaymentRequestSummaryResponseType
>({
  name: `${name.toUpperCase()} GET_CUSTOMER_PAYMENT_REQUESTS_SUMMARY`,
  api: customersApi.getCustomerPaymentRequestsSummary,
  initialState: {
    byId: {},
    paymentRequestsSummary: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.paymentRequestsSummary.loading = true;
      state.paymentRequestsSummary.error = undefined;
    },
    [ON_SUCCESS]: (state, action) => {
      const { id } = action.meta.identifier;
      state.paymentRequestsSummary.loading = false;
      state.paymentRequestsSummary.error = undefined;
      state.byId[id] = { ...(state.byId[id] || {}), ...action.payload };
    },
    [ON_FAILURE]: (state, action) => {
      state.paymentRequestsSummary.loading = false;
      state.paymentRequestsSummary.error = action.error;
    },
  },
  selectors: {
    byId: (id) => (state) => state[name]?.byId[id],
    status: (state) => state[name]?.paymentRequestsSummary,
    loading: (state) => state[name].paymentRequestsSummary.loading,
  },
});
