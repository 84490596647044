import * as React from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';

type Props = {
  amount: number;
  onClick?: (event: React.SyntheticEvent) => void;
  isFullAccess?: boolean;
  thisFirm?: boolean;
  disabled?: boolean;
  hoverContent?: React.ReactNode;
};

export const TeamMemberClientsAmount = ({
  onClick,
  amount: allClientsAmount,
  isFullAccess,
  thisFirm,
  disabled,
  hoverContent,
}: Props) => {
  const [showHoverContent, toggleHoverContent] = React.useState(false);
  const amount = thisFirm ? allClientsAmount - 1 : allClientsAmount;

  const handleMouseEnter = () => {
    !!hoverContent && toggleHoverContent(true);
  };

  const handleMouseLeave = () => {
    !!hoverContent && toggleHoverContent(false);
  };

  if (isFullAccess) {
    return (
      <TeamMemberClientsAmountText disabled={disabled}>
        <MIFormattedText label="teamManagement.clientsAmount.fullAccess" />
      </TeamMemberClientsAmountText>
    );
  }

  if (amount === 0) {
    if (thisFirm) {
      return (
        <TeamMemberClientsAmountText
          disabled={disabled}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <MIFormattedText label="teamManagement.clientsAmount.thisFirm" />

          {hoverContent && showHoverContent && <HoverContent>{hoverContent}</HoverContent>}
        </TeamMemberClientsAmountText>
      );
    } else if (onClick) {
      return (
        <TeamMemberClientsAmountText disabled={disabled}>
          {disabled ? (
            <MIFormattedText label="teamManagement.clientsAmount.assignClients" />
          ) : (
            <MIInlineLink onClick={onClick} label="teamManagement.clientsAmount.assignClients" />
          )}
        </TeamMemberClientsAmountText>
      );
    }
  }

  return (
    <TeamMemberClientsAmountText disabled={disabled} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <MIFormattedText label="teamManagement.clientsAmount.amountOfClients" values={{ amount }} />

      {thisFirm && <MIFormattedText label="teamManagement.clientsAmount.andThisFirm" />}

      {hoverContent && showHoverContent && <HoverContent>{hoverContent}</HoverContent>}
    </TeamMemberClientsAmountText>
  );
};

const TeamMemberClientsAmountText = styled.span<{ disabled?: boolean }>`
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => props.theme.text.weight.regular};
  line-height: ${(props) => props.theme.text.lineHeight.medium};

  position: relative;

  a {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  &:hover {
    color: ${(props) => props.theme.text.color.main};
  }

  color: ${(props) => (props.disabled ? props.theme.text.color.label : props.theme.text.color.subtitle)};
`;

const HoverContent = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  z-index: 5;
`;
