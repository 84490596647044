import { ReactNode } from 'react';
import Box from 'src/core/ds/box';

type LabelNoMarginProps = {
  children?: ReactNode;
};

export const LabelNoMargin = ({ children }: LabelNoMarginProps) => (
  <Box textStyle="caption1Uppercase" color="grey.700" pl={4} letterSpacing="0.02rem">
    {children}
  </Box>
);
