import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useShortcut } from 'src/hooks/useShortcut';
import { isArrowDownPressed, isArrowUpPressed } from 'src/utils/events';

/**
 * Hook responsible for moving the focus between list items using arrow keys to make it more accessible
 * and keyboard friendly.
 * Also supports jumping to list item using keyboard character press.
 * @param ref - the ref object to add event listener on.
 * @param items - the list items.
 * @param getItemValue - the item string value to search on.
 */
const useRoveFocusUsingKeyword = function <T>(
  ref: React.RefObject<HTMLElement>,
  items: T[],
  getItemValue: (T) => string
): [number, (value: number) => void] {
  const [currentFocus, setCurrentFocus] = useState<number>(-1);

  /**
   * Hook that creates a keydown event handler that listens
   * to printable keyboard character press
   */
  const onCharacterPress = useShortcut({
    preventDefault: (event) => event.key !== ' ',
  });

  const handleKeyDown = useCallback(
    (e) => {
      if (isArrowDownPressed(e)) {
        e.preventDefault();
        setCurrentFocus(currentFocus === items.length - 1 ? 0 : currentFocus + 1);
      } else if (isArrowUpPressed(e)) {
        e.preventDefault();
        setCurrentFocus(currentFocus === 0 ? items.length - 1 : currentFocus - 1);
      }

      const characterHandler = onCharacterPress((character: string) => {
        const matchingItems = items.filter((item) =>
          getItemValue(item).toLowerCase().startsWith(character.toLowerCase())
        );

        if (matchingItems) {
          const newFocusIndex = items.indexOf(matchingItems[0]);
          setCurrentFocus(newFocusIndex);
        }
      });

      characterHandler(e);
    },
    [currentFocus, setCurrentFocus, onCharacterPress, items, getItemValue]
  );

  useEffect(() => {
    const { current } = ref;

    if (current) {
      current.addEventListener('keydown', handleKeyDown, false);
    }

    return () => {
      if (current) {
        current.removeEventListener('keydown', handleKeyDown, false);
      }
    };
  }, [handleKeyDown, ref]);

  return [currentFocus, setCurrentFocus];
};

export default useRoveFocusUsingKeyword;
