import { featureFlags } from '@melio/shared-web';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withTheme } from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { PageContainerProps } from 'src/hoc/withListContainer';
import { useOrgId } from 'src/hooks';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { useQueryParams } from 'src/pages/pay/hooks/useQueryParams';
import { ThemeType } from 'src/theme/global-theme';
import { FeatureFlags } from 'src/utils/featureFlags';
import { getIsViewInstallment } from 'src/utils/financing';
import { BillType, FinancePayment, PaymentType } from 'src/utils/types';
import { ViewInstallmentBase } from './installment/ViewInstallmentBase';
import { ViewPaymentBase } from './ViewPayment';

export const scrollToGalleryEvent = 'scroll-to-gallery';

type WrapperProps = {
  paymentId: string;
  reloadBills: () => void;
  theme: ThemeType;
  filters: PageContainerProps['filters'];
  backPath: string;
};

const ViewPaymentWrapper = ({ paymentId, reloadBills, theme, filters, backPath }: WrapperProps) => {
  const orgId = useOrgId();
  const [shouldScrollToGallery, setScrollToGallery] = useState<boolean>(false);
  const { fetch: fetchPaymentById } = useStoreActions(paymentsStore);
  const isPaymentLoading = useSelector(paymentsStore.selectors.fetch.status(paymentId))?.loading;
  const payment: PaymentType & { bills: BillType[] } = useSelector(paymentsStore.selectors.byId(paymentId));
  const approvalDecisionStatus = useSelector(paymentsStore.selectors.approvalDecisionStatus)?.loading;
  const isLoading = isPaymentLoading || approvalDecisionStatus;
  const bill = payment?.bills && payment?.bills[0];
  const [isFinancingEnabled] = featureFlags.useFeature(FeatureFlags.MelioComAPFinancingEnabled);
  const {
    query: { status: tab },
  } = useQueryParams<{ status: string }>();
  const loadPayment = useCallback(
    async (newPaymentId?: number) => {
      await fetchPaymentById({ orgId, id: newPaymentId || paymentId });
    },
    [fetchPaymentById, orgId, paymentId]
  );

  useEffect(() => {
    (paymentId || !approvalDecisionStatus) && loadPayment();
  }, [loadPayment, paymentId, approvalDecisionStatus]);

  useEffect(() => {
    const scrollToGallery = () => {
      setScrollToGallery(true);
    };

    document.addEventListener(scrollToGalleryEvent, scrollToGallery);

    return () => {
      document.removeEventListener(scrollToGalleryEvent, scrollToGallery);
    };
  }, []);

  if (isLoading || !payment || !bill) {
    return <AreaLoader />;
  }

  const { isViewInstallment, installment } = getIsViewInstallment(tab, payment);

  if (isFinancingEnabled && isViewInstallment && installment) {
    return (
      <ViewInstallmentBase
        payment={payment as FinancePayment}
        bill={bill}
        theme={theme}
        backPath={backPath}
        installment={installment}
      />
    );
  }

  return (
    <ViewPaymentBase
      payment={payment}
      bill={bill}
      reloadBills={reloadBills}
      theme={theme}
      filters={filters}
      backPath={backPath}
      loadPayment={loadPayment}
      shouldScrollToGallery={shouldScrollToGallery}
      afterScrollToGallery={() => setScrollToGallery(false)}
    />
  );
};

export const ViewPayment = withTheme(ViewPaymentWrapper);
