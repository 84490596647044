import { theme } from 'src/theme/defaultTheme';

export const titleStyle = {
  justifyContent: 'space-between',
  alignItems: 'center',
  textTransform: 'capitalize',
  color: 'black',
  textStyle: 'h1Semi',
  display: 'flex',
};

export const listContainerStyle = {
  bgColor: 'grey.200',
  overflowY: 'auto',
  borderRight: '0.1rem solid',
  borderRightColor: 'grey.300',
  w: { md: '40%' },
  flex: { base: 1, sm: 'none' },
  transition: { base: '150ms', sm: 'unset' },
  p: { base: '12.8rem 2rem 4rem 2rem', sm: '12.8rem 4rem 4rem 4rem', md: '8rem 0 4rem 0', lg: '4rem 0' },
  ms: { lg: theme.sizes.sideMenu },
};

export const projectionContainerStyle = {
  boxSizing: 'border-box',
  transition: '150ms',
  pos: { base: 'absolute', md: 'relative' },
  bgColor: { base: 'grey.300', md: 'white' },
  w: { base: 'full', md: '60%' },
  h: { base: 'full', md: 'unset' },
  zIndex: { base: 'overlay', md: 'unset' },
  p: { md: '6.5rem 0 4rem 0', lg: '0 0 4rem 0' },
  left: { base: 'calc(100% + 1rem)', sm: 'unset' },
  '&.single': {
    left: { base: 0, sm: 'unset' },
  },
};

export const FileUploadErrorStyle = {
  align: 'center',
  justify: 'space-between',
  pos: 'relative',
  textTransform: 'uppercase',
  h: 6,
  color: 'black',
  textStyle: 'body4Semi',
};

export const FileNameStyle = {
  flex: 1,
  letterSpacing: '-0.02rem',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textStyle: 'body2',
  w: { base: '15rem', sm: 'unset' },
};

export const UploadAreaStyle = {
  maxW: '45rem',
  w: 'full',
  pos: 'relative',
  h: 12,
  border: '0.1rem dashed',
  borderRadius: 'lg',
  '#actionContainer': { w: 'full' },
};

export const addressRowStyle = {
  flexDir: 'row',
  maxW: '37rem',
  flexWrap: 'wrap',
  whiteSpace: 'normal',
  textStyle: 'body2',
  cursor: 'default',
  my: 1,
  mx: 0,
};
