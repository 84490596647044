import { BillType } from 'src/utils/types';
import { BillStatus, PAGINATION, PaymentRequestStatus, PaymentRequestTabs, PaymentStatus } from './consts';
import { encodeQuery } from './query-utils';

const getPaymentRequestsDefaultFilters = (tab: BillType['status'] | PaymentRequestTabs) => {
  const statusFilterParamsMap = {
    [PaymentRequestTabs.UNSENT]: {
      status: PaymentRequestStatus.CREATED,
      paymentStatus: PaymentStatus.FAILED,
      sorting: 'payment.status,dueDate',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [PaymentRequestTabs.SENT]: {
      status: PaymentRequestStatus.PENDING,
      paymentStatus: PaymentStatus.FAILED,
      sorting: 'payment.status,dueDate',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [PaymentRequestTabs.SCHEDULED]: {
      status: PaymentRequestStatus.FULFILLED,
      billStatus: BillStatus.SCHEDULED,
      sorting: 'payment.deliveryETA:asc',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
    [PaymentRequestTabs.PAID]: {
      status: PaymentRequestStatus.FULFILLED,
      billStatus: BillStatus.PAID,
      sorting: 'payment.scheduledDate:desc',
      start: PAGINATION.DEFAULT_START,
      limit: PAGINATION.DEFAULT_LIMIT,
    },
  };

  return statusFilterParamsMap[tab];
};

const getPaymentRequestsSearchPath = (
  tab: BillStatus | PaymentRequestTabs,
  baseSearch = '',
  excludeFields: string[] = [],
  start: number = PAGINATION.DEFAULT_START,
  limit: number = PAGINATION.DEFAULT_LIMIT
) => {
  const defaultFilters = getPaymentRequestsDefaultFilters(tab);
  const paymentRequestFilters = { ...defaultFilters, start, limit };

  return encodeQuery(paymentRequestFilters, excludeFields, baseSearch);
};

export { getPaymentRequestsDefaultFilters, getPaymentRequestsSearchPath };
