import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { CreateAndLinkExistingBankAccountRequest } from 'src/pages/receiving-method/ach/types';
import { accountingPlatformsApi } from './api';
import { name } from './consts';

type CreateAndLinkBankAccountsState = {
  createAndLinkBankAccount: {
    created: boolean;
    loading: boolean;
    error?: Error;
  };
};

export const CreateAndLinkBankAccountSlice = createApiCallSlice<
  CreateAndLinkExistingBankAccountRequest,
  CreateAndLinkBankAccountsState
>({
  initialState: {
    createAndLinkBankAccount: {
      created: false,
      loading: false,
      error: undefined,
    },
  },
  name: `[${name}] create and link bank account`.toUpperCase(),
  api: accountingPlatformsApi.createBankAccountAndLink,
  reducers: {
    [ON_REQUEST](state) {
      state.createAndLinkBankAccount = { loading: true, created: false };
    },
    [ON_SUCCESS](state) {
      state.createAndLinkBankAccount = {
        loading: false,
        created: true,
      };
    },
    [ON_FAILURE](state, action) {
      state.createAndLinkBankAccount = { loading: false, error: action.error, created: false };
    },
  },
  selectors: {
    loading: (state) => state[name].createAndLinkBankAccount.loading,
    created: (state) => state[name].createAndLinkBankAccount.created,
    error: (state) => state[name].createAndLinkBankAccount?.error,
  },
});
