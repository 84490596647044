import { createDeleteSlice } from 'src/helpers/redux/restDeleteSlice';
import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { PaymentType } from 'src/utils/types';
import { paymentsApi } from './api';

const name = 'payments';

export const markPaymentAsPaidSlice = createUpdateSlice<PaymentType>({
  storeName: name,
  api: paymentsApi.markPaymentAsPaid,
  actionName: 'markAsPaid',
});

export const markPaymentAsUnpaidSlice = createDeleteSlice<PaymentType>({
  storeName: name,
  api: paymentsApi.markPaymentAsUnpaid,
  actionName: 'markAsUnpaid',
});
