import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import pciIcon from 'src/images/icons/pci-dss-logo.png';
import lockIcon from 'src/images/icons/privacy.svg';
import { analytics } from 'src/services/analytics';

type Props = {
  className?: string;
  eventPage: string;
  label?: string;
  values?: Record<string, any>;
  showIconCC?: boolean;
};

export const MISecurityDetails = ({ className, eventPage, label, values }: Props) => {
  const goSecurityPage = () => {
    analytics.track(eventPage, 'go-security-page');
    window.open('https://www.meliopayments.com/security');
  };

  return (
    <SecurityDetails className={className}>
      <LockContainer>
        <LockImage src={lockIcon} alt="lock" />
        <LockText>
          <MIFormattedText
            label={label || 'guests.register.securityText'}
            values={{
              ...values,
              link: (
                <GoSecurityPage onClick={goSecurityPage}>
                  <MIFormattedText label="guests.register.learnMore" />
                </GoSecurityPage>
              ),
            }}
          />
        </LockText>
      </LockContainer>
      <Image src={pciIcon} alt="pci" h="3.4rem" ml={3} />
    </SecurityDetails>
  );
};

type SecurityDetailsProps = {
  className?: string;
  children?: React.ReactNode;
};

const SecurityDetails = ({ children, className }: SecurityDetailsProps) => (
  <Flex my={0} mx="auto" w="full" maxW="45rem" className={className}>
    {children}
  </Flex>
);

type LockContainerProps = {
  children?: React.ReactNode;
};

const LockContainer = ({ children }: LockContainerProps) => <Flex textStyle="caption1">{children}</Flex>;

type LockImageProps = {
  src: string;
  alt?: string;
};

const LockImage = ({ ...rest }: LockImageProps) => <Image sx={LockImageStyle} {...rest} />;

const LockImageStyle: CSSObject = {
  h: { base: 8, sm: 10 },
  mr: 3,
  w: 8,
  mt: { base: 1, sm: -1 },
  minW: 8,
};

type LockTextProps = {
  children?: React.ReactNode;
};

const LockText = ({ children }: LockTextProps) => (
  <Box textAlign="left" color="grey.700">
    {children}
  </Box>
);

type GoSecurityPageProps = {
  children?: React.ReactNode;
  onClick?: () => void;
};

const GoSecurityPage = ({ children, onClick }: GoSecurityPageProps) => (
  <Box as="span" onClick={onClick} color="primary.500" cursor="pointer">
    {children}
  </Box>
);
