import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { NotificationVariant } from 'src/core/ds/toast';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { authApi } from 'src/modules/auth/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { useLoginWithGoogle } from 'src/pages/auth/login-with-google-hook';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { LoginFlow, RegistrationOrigin } from 'src/utils/consts';
import { useLocationState, useQueryString } from 'src/utils/hooks';
import intuit from 'src/utils/intuit';
import { CredentialsType } from 'src/utils/types';
import { spendManagementLocations } from '../../spend-management/locations';
import { LoginPage } from '../components/LoginPage';
import { useLoginUser } from '../hooks/useLoginUser';
import { authLocations } from '../locations';
import { registrationLocations } from '../registration/locations';

export const eventPage = 'login';
type Props = {
  loginFlow?: LoginFlow;
};

export const LoginPageContainer: React.VFC<Props> = ({ loginFlow }) => {
  const queryParams = useQueryString();
  const site = useSiteContext();
  const location = useLocation();
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();
  const { onLogin, isLoading: isLoadingLogin, errorCode: errorCodeLogin, validationErrors } = useLoginUser();
  const { navigateWithPreservedState } = usePreservedStateNavigator();
  const [redirectUrl] = useLocationState<string>('redirectUrl', '');

  const {
    onApiCall: onRequestPassword,
    loading: isLoadingRequestPassword,
    error: errorRequestPassword,
    resetState: resetRequestPasswordState,
  } = useApi({
    api: authApi.requestResetPassword,
  });
  const [isPasswordRequestSucceeded, setIsPasswordRequestSucceeded] = useState<boolean>(false);

  const isLoading = isLoadingLogin || isLoadingRequestPassword;
  const errorCode = errorCodeLogin || (queryParams.error_code ?? null);

  const isSpendManagementLoginFlow = loginFlow === LoginFlow.SPEND_MANAGEMENT;

  const realEventPage = isSpendManagementLoginFlow ? 'spend-management-login' : eventPage;

  const redirectFailureUrl = isSpendManagementLoginFlow ? authLocations.loginSpendManagement : authLocations.login;

  const setLocationStates = useCallback(
    (values: Record<string, unknown>) => {
      const state = { ...((location.state as unknown) as Record<string, unknown>), ...values };
      history.push({ ...location, state });
    },
    [location, history]
  );

  useEffect(() => {
    if (!location.pathname.includes(authLocations.login) && location.hash === '#unauthorized') {
      pushNotification({ type: NotificationVariant.info, msg: 'auth.login.loggedOutHint' });
    }
  }, [location.pathname, location.hash]);

  useEffect(() => {
    if (isSpendManagementLoginFlow) {
      setLocationStates({ redirectUrl: spendManagementLocations.index, loginFlow });
    } else {
      setLocationStates({ redirectUrl: `${location.pathname}${location.search}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSpendManagementLoginFlow, loginFlow]);

  const { openGoogleOAuth } = useLoginWithGoogle({
    redirectFailureTo: redirectFailureUrl,
    redirectSuccessTo: generatePath(authLocations.googleLoginSuccess, {
      ...(navigateWithPreservedState && {
        redirectUrl,
      }),
    }),
  });

  const goLinkQuickbooks = () => {
    analytics.track(realEventPage, 'link-quickbooks');
    intuit.goConnectToIntuit({
      registrationOrigin: RegistrationOrigin.APP,
      intuitReturnUrl: authLocations.register.authorizeIntuitSuccess,
      intent: 'melioSyncSignIn',
      appUrl: redirectUrl,
    });
  };

  const navigateToRegister = () =>
    historyPush({
      path: isSpendManagementLoginFlow ? registrationLocations.spendRegister : authLocations.register.index,
    });

  const handleLogin = (credentials: CredentialsType) => {
    analytics.track(realEventPage, 'click-continue');

    resetRequestPasswordState();
    setIsPasswordRequestSucceeded(false);

    onLogin(credentials);
  };

  const handleLoginWithGoogle = () => {
    analytics.track(realEventPage, 'login-with-google');

    openGoogleOAuth();
  };

  const handleRequestPassword = (email: string) => {
    analytics.track(realEventPage, 'login-request-password');

    onRequestPassword({ email, hasNoPasswordDefined: true }).then(() => {
      setIsPasswordRequestSucceeded(true);
    });
  };

  return (
    <LoginPage
      onLogin={handleLogin}
      loginWithGoogle={handleLoginWithGoogle}
      onRequestPassword={handleRequestPassword}
      isPasswordRequestSucceeded={isPasswordRequestSucceeded}
      errorCodeRequestPassword={errorRequestPassword?.code}
      goLinkQuickbooks={!site.embeddedMode && !isSpendManagementLoginFlow ? goLinkQuickbooks : undefined}
      isLoading={isLoading}
      errorCode={errorCode}
      goRegister={navigateToRegister}
      goResetPassword={authLocations.requestResetPassword}
      validationErrors={validationErrors}
    />
  );
};
