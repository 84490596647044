import React from 'react';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { useSinglePaymentAnalytics } from 'src/pages/regular-batch-payments/analytics/BatchPaymentsAnalytics';
import { ActionsCellButton } from 'src/pages/regular-batch-payments/table/components/ActionsCell/ActionCellButton';

type Props = {
  rowHovered: boolean;
  itemId: string;
};

export const RemoveFromListAction = ({ rowHovered, itemId }: Props) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const { trackRemoveFromListAction } = useSinglePaymentAnalytics(itemId);

  const removeFromList = async () => {
    await actions.settings.removeBatchItem({ itemId });
    trackRemoveFromListAction();
  };

  return (
    <ActionsCellButton
      showOnHover
      rowHovered={rowHovered}
      icon={<Icon name={IconNames.close} size={IconSize.m} />}
      label="regularBatchPayments.cells.actions.removeFromList"
      onClick={removeFromList}
    />
  );
};
