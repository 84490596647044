import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';

type Props = {
  onSubscribe: () => void;
  onDone: () => void;
};

export const ComingSoonPage = ({ onSubscribe, onDone }: Props) => (
  <SuccessLayoutPage
    illustration={IllustrationName.kiss}
    title="onboarding.accountingSoftware.comingSoon.title"
    text="onboarding.accountingSoftware.comingSoon.text"
    buttonLabel="onboarding.accountingSoftware.comingSoon.actions.notify"
    buttonAction={onSubscribe}
    linkLabel="onboarding.accountingSoftware.comingSoon.actions.cancel"
    linkAction={onDone}
  />
);

ComingSoonPage.defaultProps = {};
