import React, { useEffect, useRef } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { HStack } from 'src/core/ds/stack';
import { useApi } from 'src/hoc/useApi';
import { accountingPlatformsApi } from 'src/modules/accounting-platforms/api';
import { useAccountingSoftwareSyncActions } from 'src/pages/settings/hooks/useAccountingSoftwareSyncActions';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';

type Props = {
  dismiss: (event?: React.MouseEvent) => void;
  accountingSoftwareType: string;
  orgId: number;
};

export const OptOutModal = ({ dismiss, accountingSoftwareType, orgId }: Props) => {
  const toastId = useRef<null | number>(null);
  const { fetch: fetchAccountingSoftware } = useAccountingSoftwareSyncActions(accountingSoftwareType);
  const { onApiCall: handleOptOut, result: results, loading: isLoading, error } = useApi({
    api: async () => accountingPlatformsApi.optOutInvoiceGeneration({ orgId }).then(fetchAccountingSoftware),
    throwError: false,
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (results) {
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'settings.accountingSoftware.invoiceGeneration.updateSuccess',
      });
      dismiss();
    } else if (error && !checkIfToastIsDisplayedById(toastId.current)) {
      toastId.current = pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'settings.accountingSoftware.invoiceGeneration.updateFailed',
      });
    }
  }, [results, isLoading, error]);

  return (
    <ModalMessage
      alignLeft
      id="opt-out-modal"
      titleComponent={<MIFormattedText label="settings.accountingSoftware.invoiceGeneration.optOutModal.title" />}
      contentComponent={
        <Box textStyle="body2">
          <MIFormattedText label="settings.accountingSoftware.invoiceGeneration.optOutModal.body" />
        </Box>
      }
      footerSubTitle={
        <HStack spacing={3} justify="flex-end" width="100%">
          <Button
            variant={ButtonVariants.tertiaryNaked}
            label="settings.accountingSoftware.invoiceGeneration.optOutModal.cancelAction"
            onClick={dismiss}
            disabled={isLoading}
          />
          <Button
            variant={ButtonVariants.primary}
            label="settings.accountingSoftware.invoiceGeneration.optOutModal.approveAction"
            onClick={handleOptOut}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </HStack>
      }
    />
  );
};
