import { NotificationCardTypes } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';
import { getLearnMoreAboutRefundLink } from '../consts';
import { RenderMessage } from './RenderMessage';

type Props = { payment: PaymentType };

export const RenderCompletedRefundMessage = ({ payment }: Props) => {
  const achTraceNumber = payment.metadata?.achTraceNumber;
  const completedRefundSubtitle = {
    label: 'bills.form.paymentActivity.completedPaymentDescription',
    values: {
      learnMore: getLearnMoreAboutRefundLink,
      traceNumber: achTraceNumber,
    },
  };

  return <RenderMessage type={NotificationCardTypes.HELP} subtitle={completedRefundSubtitle} />;
};
