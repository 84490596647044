import { Highlight } from 'src/components/common/MIAutocompleteExtras/components';
import { SuggestionType } from 'src/components/common/MIAutocompleteExtras/Suggestion';

export const renderBasicHighlight = <T,>(props: { option: SuggestionType<T>; text: string }) => {
  const { text: query, option } = props;
  const index = option.label.toLowerCase().indexOf(query.toLowerCase());

  return (
    <>
      <span>{option.label.slice(0, index)}</span>
      <Highlight>{option.label.slice(index, index + query.length)}</Highlight>
      <span>{option.label.slice(index + query.length)}</span>
    </>
  );
};
