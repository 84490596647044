import { featureFlags } from '@melio/shared-web';
import { format, parse, subYears } from 'date-fns';
import { ChangeEvent, SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { profileStore } from 'src/modules/profile/profile-store';
import { DOB_DEFAULTS } from 'src/pages/auth/consts';
import { validateDateOfBirth } from 'src/pages/utils/validation-date';
import { devices } from 'src/theme/appDevices';
import { CompanyInfoOnboardingOrigin, FeatureFlags } from 'src/utils/consts';
import { checkApostropheForPluralPossessive } from 'src/utils/string-utils';
import { CompanyInfoType } from 'src/utils/types';

type Props = {
  onNext: (
    companyInfo: CompanyInfoType,
    dataToUpdate: Record<string, any>,
    userDataToUpdate: Record<string, any>
  ) => void;
  onPrev: () => void;
  onChange: (value?: string | number | null, fieldName?: string) => void;
  companyInfo: CompanyInfoType;
  isLoading?: boolean;
  validationErrors: Record<string, any>;
  locationState: Record<string, any>;
};

const CompanyContactsForm = ({
  companyInfo,
  onPrev,
  onNext,
  onChange,
  isLoading = false,
  validationErrors = {},
  locationState,
}: Props) => {
  const [isPlatformMtlKycUplift] = featureFlags.useFeature<boolean>(FeatureFlags.PlatformMtlKycUplift, false);
  const { companyName, contactFirstName, contactLastName, phone } = companyInfo;
  const { isCompanyConnectedFromAccountingSoftware, origin, invitingOrgId } = locationState;
  const { profile } = useStructuredSelectors(profileStore.selectors);
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(
    profile?.dateOfBirth ? new Date(profile.dateOfBirth) : null
  );
  const [birthDayError, setBirthDayValidationError] = useState<Record<string, any> | null>(null);
  const organizationPreferences = useOrganizationPreferences();
  const isAccountantAddCompanyFlow =
    origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY || organizationPreferences.uninvitedOwnerEmail;
  const isAccountantAddNewCompany = isAccountantAddCompanyFlow && !isCompanyConnectedFromAccountingSoftware;
  const [contactInfo, setContactInfo] = useState({
    contactFirstName: isAccountantAddNewCompany ? '' : contactFirstName,
    contactLastName: isAccountantAddNewCompany ? '' : contactLastName,
    phone: isAccountantAddNewCompany ? '' : phone,
  });
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const isDateOfBirthFieldAvailable = isPlatformMtlKycUplift && !isAccountantAddCompanyFlow;
  const onChangeCallback = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    handleChange(id, value);
  };

  const onFormattedInputCallback = ({ event }: { event: SyntheticEvent<HTMLInputElement> }) => {
    const { id, value } = event.currentTarget;
    handleChange(id, value);
  };

  const onDateChange = (value: string) => {
    if (value.length !== 8) {
      setDateOfBirth(null);

      return;
    }

    try {
      const parsedDate = parse(value, 'MMddyyyy', new Date());
      const validationErrors = validateDateOfBirth(parsedDate);

      if (Object.keys(validationErrors).length > 0) {
        setBirthDayValidationError(validationErrors);

        return;
      }

      // If all validations pass, update the state and clear any previous validation errors
      setDateOfBirth(parsedDate);
      setBirthDayValidationError({});
    } catch (error) {
      setBirthDayValidationError({
        dateOfBirth: 'inputErrors.user.dateOfBirth.date.notCorrectDate',
      });
    }
  };

  const handleChange = (id: string, value: string) => {
    onChange(value, id);
    setContactInfo({ ...contactInfo, [id]: value });
  };

  const onNextCallback = () => {
    if (isDateOfBirthFieldAvailable) {
      if (!dateOfBirth) {
        setBirthDayValidationError({ dateOfBirth: 'Enter a valid date of birth.' });

        return;
      }

      const validationErrors = validateDateOfBirth(dateOfBirth);

      setBirthDayValidationError(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        onNext(companyInfo, { contactFirstName, contactLastName, phone }, { dateOfBirth });
      }
    }

    onNext(companyInfo, { contactFirstName, contactLastName, phone }, { dateOfBirth });
  };

  const getProgressBarRelativeStep = () => {
    if (isAccountantAddCompanyFlow) {
      return 1 / 6;
    }

    if (isUnifiedOnboardingOpen) {
      return 2 / 13;
    }

    return 2 / 7;
  };

  return (
    <StepLayoutPage
      title={
        isAccountantAddCompanyFlow
          ? 'inviteNewCompany.accountantFlow.contactsTitle'
          : 'onboarding.companyInfo.contacts.title'
      }
      titleValues={{
        companyName: <Title>{checkApostropheForPluralPossessive(companyName ?? '')}</Title>,
      }}
      relativeStep={getProgressBarRelativeStep()}
      onNext={onNextCallback}
      isPrevDisabled={!!isAccountantAddCompanyFlow}
      onPrev={invitingOrgId ? undefined : onPrev}
      isLoading={isLoading}
    >
      <RowContainer>
        <TextField
          id="contactFirstName"
          label={
            isAccountantAddCompanyFlow
              ? 'inviteNewCompany.accountantFlow.inputTitleFirstName'
              : 'onboarding.companyInfo.contacts.inputTitleFirstName'
          }
          value={contactInfo.contactFirstName}
          onSubmit={onNextCallback}
          onChange={onChangeCallback}
          errorMessage={validationErrors.contactFirstName}
          autoFocus
          isRequired
        />
        <TextField
          id="contactLastName"
          label={
            isAccountantAddCompanyFlow
              ? 'inviteNewCompany.accountantFlow.inputTitleLastName'
              : 'onboarding.companyInfo.contacts.inputTitleLastName'
          }
          value={contactInfo.contactLastName}
          onSubmit={onNextCallback}
          onChange={onChangeCallback}
          errorMessage={validationErrors.contactLastName}
          isRequired
        />
      </RowContainer>
      {isDateOfBirthFieldAvailable && (
        <MaskField
          id="contactDateOfBirth"
          label="onboarding.companyInfo.contacts.inputTitleDateOfBirth"
          value={dateOfBirth ? format(dateOfBirth, 'MMddyyyy') : dateOfBirth}
          helperText="onboarding.companyInfo.contacts.inputHintDateOfBirth"
          placeholder="MM/DD/YYYY"
          type="text"
          format={Consts.MASK_FORMAT_OPTIONS.dateOfBirth}
          onSubmit={onNextCallback}
          onChange={({ value }) => onDateChange(value)}
          errorMessage={birthDayError?.dateOfBirth}
          min={subYears(new Date(), DOB_DEFAULTS.MAX_AGE)}
          max={subYears(new Date(), DOB_DEFAULTS.MIN_AGE)}
          isRequired
        />
      )}
      <PrivateDataContainer>
        <MaskField
          id="phone"
          label={
            isAccountantAddCompanyFlow
              ? 'inviteNewCompany.accountantFlow.inputTitlePhone'
              : 'onboarding.companyInfo.contacts.inputTitlePhone'
          }
          helperText={
            isAccountantAddCompanyFlow
              ? 'inviteNewCompany.accountantFlow.inputHintPhone'
              : 'onboarding.companyInfo.contacts.inputHintPhone'
          }
          value={contactInfo.phone}
          type="tel"
          format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
          onSubmit={onNextCallback}
          onChange={onFormattedInputCallback}
          errorMessage={validationErrors.phone}
          isRequired
        />
      </PrivateDataContainer>
    </StepLayoutPage>
  );
};

const RowContainer = styled.div`
  display: flex;
  > div:first-child {
    margin-right: 2rem;
  }

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;

export default CompanyContactsForm;
