import { GetProTabs, PaymentRequestCreateOrigin } from 'src/utils/consts';

export const EVENT_PAGE = 'get-paid-pro';

export enum InvoicesDateFilters {
  ALL = 'all',
  PAST_DUE = 'past_due',
  DUE_7_DAYS = 'due_7_days',
  DUE_MONTH = 'due_month',
  DUE_NEXT_MONTH = 'due_next_month',
}

export enum RequestsDateFilters {
  ALL = 'all',
  PAST_DUE = 'past_due',
  DUE_7_DAYS = 'due_7_days',
  DUE_MONTH = 'due_month',
  DUE_NEXT_MONTH = 'due_next_month',
}

export enum InProgressDateFilters {
  ALL = 'all',
  NEXT_7_DAYS = 'next_7_days',
  THIS_MONTH = 'this_month',
}

export enum PaidDateFilters {
  ALL = 'all',
  TODAY = 'today',
  LAST_7_DAYS = 'last_7_days',
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
}

export enum InvoicesActions {
  SEND_REQUESTS = 'sendRequests',
  MARK_AS_PAID = 'markAsPaid',
  REMOVE = 'remove',
}

export enum RequestsActions {
  MARK_AS_PAID = 'markAsPaid',
  CANCEL = 'cancel',
}

export const DATE_FILTERS = {
  invoices: { ...InvoicesDateFilters },
  requests: { ...RequestsDateFilters },
  inProgress: { ...InProgressDateFilters },
  paid: { ...PaidDateFilters },
};

export const GET_ALL_FILTER = {
  invoices: InvoicesDateFilters.ALL,
  requests: RequestsDateFilters.ALL,
  inProgress: InProgressDateFilters.ALL,
  paid: PaidDateFilters.ALL,
};

export enum InProgressStatusFilters {
  Scheduled = 'scheduled',
  Processing = 'in-progress',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

export const STATUS_FILTERS_BY_TAB = {
  [GetProTabs.IN_PROGRESS]: InProgressStatusFilters,
};

export enum InvoicesQueryActions {
  MARK_CREATED_LAST_7_DAYS = 'mark_created_last_7_days',
  MARK_CREATED_SINCE = 'mark_created_since',
}

export enum InvoicesColumns {
  CREATED = 'created',
}

export const TABS = [GetProTabs.INVOICES, GetProTabs.REQUESTS, GetProTabs.IN_PROGRESS, GetProTabs.PAID];

export enum PaymentsRequestStatus {
  SCHEDULED = 'scheduled',
  PROCESSING = 'inProgress',
  FULLY_PAID = 'completed',
  MARKED_AS_PAID = 'markedAsPaid',
  FAILED = 'failed',
  BLOCKED = 'blocked',
  CANCELLED = 'cancelled',
  CANCELLED_BY_CUSTOMER = 'cancelledByCustomer',
  SENT = 'sent',
  SENT_SEEN = 'sentSeen',
  REMINDER = 'reminder',
  REMINDER_SEEN = 'reminderSeen',
}

export const PAGE_ITEM_COUNT = 50;

export const GET_PAID_DISABLED_EMPTY_MESSAGE = {
  title: 'getPro.emptyState.getPaidDisabled.title',
  exp: 'getPro.emptyState.getPaidDisabled.exp',
  cta: 'getPro.emptyState.getPaidDisabled.cta',
};

export const INITIATED_BY = {
  CUSTOMER: 'customer',
  VENDOR: 'vendor',
};

export enum EventTypes {
  INVOICE_CREATED = 'invoiceCreated',
  PAYMENT_REQUEST_SHARED = 'paymentRequestShared',
  PAYMENT_REQUEST_MARKED_AS_SENT = 'paymentRequestMarkedAsSent',
  PAYMENT_REQUEST_NOTIFICATION_FOUND = 'paymentRequestNotificationFound',
  BILLS_OVERDUE_FOUNDE = 'billsOverdueFound',
  PAYMENT_SCHEDULED = 'paymentScheduled',
  FUNDING_SOURCE_VERIFIED = 'fundingSourceVerified',
  PAYMENT_COLLECTED = 'paymentCollected',
  PAYMENT_DELIVERED = 'paymentDelivered',
  PAYMENT_CANCELED = 'paymentCanceled',
  PAYMENT_FAILED = 'paymentFailed',
  PAYMENT_APPROVAL_DECISION_STATUS = 'paymentApprovalDecisionStatus',
  PAYMENT_RESCHEDULED = 'paymentRescheduled',
  PAYMENT_REQUEST_MARKED_AS_PAID = 'paymentRequestMarkedAsPaid',
  PAYMENT_REQUEST_SHARED_EMAIL_CLICK = 'postmarkPaymentRequestSharedEmailClick',
  PAYMENT_REQUEST_REMINDER_EMAIL_CLICK = 'postmarkPaymentRequestNotificationFoundEmailClick',
  PAYMENT_REQUEST_SHARED_EMAIL_OPENED = 'postmarkPaymentRequestSharedEmailOpen',
  PAYMENT_REQUEST_REMINDER_EMAIL_OPENED = 'postmarkPaymentRequestNotificationFoundEmailOpen',
  PAYMENT_REQUEST_CANCELLED = 'paymentRequestCancelled',
  PAYMENT_REQUEST_DELETED = 'paymentRequestDeleted',
  PAYMENT_SYNCED_WITH_ACCOUNTING_SOFTWARE = 'paymentSyncedWithAccountingSoftware',
}

export enum EventEntity {
  PAYMENT_REQUEST = 'payment-request',
  PAYMENT = 'payment',
  BILL = 'bill',
}

export const PAGE_EVENT = 'get-paid-pro';
export const LIST_CHECK_UPDATE_PERIOD = 5 * 60 * 1000;
export const ACCOUNTING_SOFTWARE_ORIGIN = [PaymentRequestCreateOrigin.CODAT, PaymentRequestCreateOrigin.QBO];

export const GET_PRO_URL_IDENTIFIER = 'get-pro';
