import pick from 'lodash/fp/pick';
import values from 'lodash/fp/values';
import merge from 'lodash/merge';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { REGULAR_BATCH_PAYMENTS_FETCH_BATCH_BILLS_LIST_SUCCESS } from 'src/modules/regular-batch-payments/regular-batch-payments-actions';
import { SCANNED_INVOICE_CREATE_BILL_SUCCESS } from '../scanned-invoices/actions';
import { billsApi } from './api';
import { batchMarkAsPaidSlice } from './batch-mark-as-paid-slice';
import { billListIndexSlice } from './bill-list-index-slice';
import { name } from './consts';
import { markAsPaidSlice } from './mark-as-paid-slice';
import { regularListSlice } from './regular-list-slice';
import { setClassificationSlice } from './set-classification-slice';

const selectors = {
  byId: (id: string) => (state) => state[name]?.byId[id],
  getByIds: (ids: string[]) => (state) => values(pick(ids, state[name].byId)),
};

export const billsStore = createRestfulSlice<any>({
  name,
  schemaName: 'bill',
  api: {
    fetch: ({ orgId, id }: { orgId: number; id: string }) =>
      billsApi.getBillById({ orgId, id }).then(({ object }) => object),
    list: (params) => billsApi.getBills(params).then((res) => ({ items: res.objects, totalCount: res.totalCount })),
    create: ({ orgId, params }) => billsApi.createBill(orgId, params).then((res) => res.object),
  },
  slices: {
    setClassification: setClassificationSlice,
    regularList: regularListSlice,
    markAsPaid: markAsPaidSlice,
    batchMarkAsPaid: batchMarkAsPaidSlice,
    billListInbox: billListIndexSlice,
  },
  selectors,
  extraReducers: {
    [REGULAR_BATCH_PAYMENTS_FETCH_BATCH_BILLS_LIST_SUCCESS]: (state, action) => {
      action.payload.items.forEach((item) => {
        // TODO: remove this once 'bulk-payment' is gone.
        if (item.bill) {
          state.byId[item.bill.id] = merge(state.byId[item.bill.id] || {}, item.bill);
        } else {
          item.payment.bills.forEach((bill) => {
            state.byId[bill.id] = merge(state.byId[bill.id] || {}, bill);
          });
        }
      });
    },
    [SCANNED_INVOICE_CREATE_BILL_SUCCESS]: (state, action) => {
      state.byId[action.payload.bill.id] = merge(state.byId[action.payload.bill.id] || {}, action.payload.bill);
    },
  },
});
