import { OnSelected, Props } from 'src/components/common/MIAutocomplete';
import { ExpandedSuggestion, Suggestion, SuggestionType } from 'src/components/common/MIAutocompleteExtras/Suggestion';
import { renderBasicHighlight } from 'src/components/common/MIAutocompleteExtras/utils';

export type MIAutocompleteSuggestionType<ValueType> = SuggestionType<ValueType>;

export const MIAutocompleteSuggestion = {
  Suggestion,
  ExpandedSuggestion,
  renderBasicHighlight,
};
export type { OnSelected as MIAutocompleteOnSelected, Props as MIAutocompleteProps };
