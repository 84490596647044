import { vendorsApi } from 'src/modules/vendors/api';
import { VendorType, VirtualCardType } from 'src/utils/types';
import { VendorManagedByEnum } from './consts';

export const sendVendorDetailsRequest = (orgId: number, vendorId: number) => {
  vendorsApi.sendFillDeliveryMethodEmail(orgId, vendorId);
};

export const getVirtualCardProcessedDate = (virtualCard: VirtualCardType): Date | null =>
  virtualCard?.authorizationDate || virtualCard?.postedDate;

export const shouldCollectAmexAddressForVendor = (vendor?: VendorType) => {
  if (!vendor) {
    return false;
  }

  const isSharedVendor = vendor.managedBy === VendorManagedByEnum.SHARED;
  const isOwnedVendor = !!vendor.ownedById;

  return !isSharedVendor && !isOwnedVendor && !vendor.address;
};
