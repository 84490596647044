export const billingLocations = {
  base: ['/orgs/:orgId/billing-fee'],
  add: '/orgs/:orgId/billing-fee/add',
  success: '/orgs/:orgId/billing-fee/success',
  accountingFirm: {
    assignClients: '/orgs/:orgId/billing-fee/accounting-firm/assign-clients',
  },
  client: {
    applyFirmMethodSuccess: '/orgs/:orgId/billing-fee/apply-firm-success',
    changeMethodSuccess: '/orgs/:orgId/billing-fee/change-method-success',
  },
};
