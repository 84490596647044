import * as React from 'react';
import styled from 'styled-components';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import { Badge, BadgeColors } from 'src/core/ds/badge';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { devices } from 'src/theme/appDevices';
import { InvitationActionType, InvitationType } from 'src/utils/types';

type InvitationSummaryInvitationType = Partial<InvitationType> &
  Pick<InvitationType, 'firstName' | 'lastName' | 'expired' | 'status' | 'email'>;

export type InvitationSummaryProps = {
  invitation: InvitationSummaryInvitationType;
  actions: InvitationActionType[];
  rightSideRender?: React.ReactNode;
};
export function InvitationSummary({ invitation, actions, rightSideRender }: InvitationSummaryProps) {
  const fullName = `${invitation.firstName} ${invitation.lastName}`;
  const status = invitation.expired && invitation.expired <= new Date().toISOString() ? 'expired' : invitation.status;

  return (
    <DetailsContainer>
      <Avatar name={fullName} textColor="white" size={Size.lg} bg="grey.400" />
      <UserData>
        <UserNameContainer>
          <UserName>{fullName}</UserName>{' '}
          <Badge
            label={`invitation.status.${status}`}
            color={status === 'expired' ? BadgeColors.NEUTRAL_LIGHT : BadgeColors.NEUTRAL}
          />
        </UserNameContainer>
        <UserEmail>{invitation.email}</UserEmail>
      </UserData>

      {rightSideRender && <RightSide withActions={!!actions}>{rightSideRender}</RightSide>}

      <ActionContainer withRightContent={!!rightSideRender}>
        <Menu autoSelect={false} isLazy>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Icon name={IconNames.more} />}
            variant="action"
            placement="top"
          />
          <MenuList>
            {actions.map(({ label, action, negative }) => (
              <MenuItem key={label} label={label} onClick={action} color={negative ? 'red.500' : 'black'} />
            ))}
          </MenuList>
        </Menu>
      </ActionContainer>
    </DetailsContainer>
  );
}

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`;

const UserEmail = styled.div`
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => props.theme.text.weight.regular};
  color: ${(props) => props.theme.text.color.label};
  line-height: 1.8rem;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 30rem;
  }
`;

const UserData = styled.div`
  display: flex;
  overflow: hidden;
  justify-content: center;
  flex-direction: column;
  margin-left: 1.4rem;
`;

const UserNameContainer = styled.div`
  display: flex;
  align-items: center;
`;
const UserName = styled.span`
  display: inline-block;
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 2.3rem;

  @media ${devices.desktop}, ${devices.tablet} {
    max-width: 25rem;
  }
  margin-right: 1rem;
`;
const ActionContainer = styled.div<{ withRightContent?: boolean }>`
  align-self: center;
  flex-grow: ${(props) => (props.withRightContent ? '0' : '10')};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 1rem;
`;

const RightSide = styled.div<{ withActions?: boolean }>`
  align-self: center;
  margin-left: auto;
  margin-right: ${(props) => (props.withActions ? '3.8rem' : '8rem')};
`;
