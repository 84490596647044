import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';
import { PaymentType } from 'src/utils/types';
import { getSubtitleValuesForVoidedCheckNotification } from './utils';

type Props = {
  payment: PaymentType;
};

export const NotificationCardVoidedCheck = ({ payment }: Props) => {
  const subtitleValues = getSubtitleValuesForVoidedCheckNotification(payment);

  return (
    <Box pb={6}>
      <MINotificationCard
        type={NotificationCardTypes.INFO}
        title={{
          label: 'bills.form.paymentActivity.notifications.voidedCheck.title',
        }}
        subtitle={{
          label: 'bills.form.paymentActivity.notifications.voidedCheck.subtitle',
          values: {
            ...subtitleValues,
          },
        }}
      />
    </Box>
  );
};
