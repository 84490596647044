import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import curry from 'lodash/curry';
import find from 'lodash/find';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import uniqBy from 'lodash/uniqBy';
import zipObject from 'lodash/zipObject';
import memoize from 'memoize-one';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ColumnExplain,
  FileUploading,
  NoCsvDataToUploadError,
  UploadError,
} from 'src/components/common/BatchUpload/BatchUploadPages';
import { SelectColumnPage } from 'src/components/common/BatchUpload/SelectColumnPage';
import BatchUploadContactsReviewPage from 'src/pages/contacts/create/components/BatchUploadContactsReviewPage';
import { getContactActions, getContactSelector } from 'src/pages/contacts/utils';
import { getSampleColumns, resetScroll } from 'src/pages/get-paid/create/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { ContactsTab } from 'src/utils/consts';
import { useLoadCsv, useLocationState } from 'src/utils/hooks';
import { ContactType, NavigationCallbacks } from 'src/utils/types';

const COLUMNS_KEYS = ['companyName', 'contactName', 'contactEmail', 'contactPhone'];

const convertDataToContacts = (selectedColumns, data) =>
  data.map((row) => reduce(selectedColumns, (res, value, key) => ({ ...res, [key]: row[value] }), {}));

const validateContacts = (contacts) =>
  contacts.filter((contact) => isValidationOk(getValidationErrors('vendor', contact, COLUMNS_KEYS)));

const removeDuplicates = (contacts) => uniqBy(contacts, 'companyName');

const compareContactsToUpdate = curry((newContact: ContactType, existingContact: ContactType) => {
  if (newContact.companyName === existingContact.companyName) {
    if (
      (isEmpty(existingContact.contactEmail) && !isEmpty(newContact.contactEmail)) ||
      (isEmpty(existingContact.contactPhone) && !isEmpty(newContact.contactPhone)) ||
      (isEmpty(existingContact.contactName) && !isEmpty(newContact.contactName))
    ) {
      return false;
    }

    return true;
  }

  return false;
});

const removeExisting = curry((existing, newContacts) =>
  newContacts.filter((newContact) => find(existing, compareContactsToUpdate(newContact)) === undefined)
);

const createContactsToUpload = memoize((selectedColumns, existingContacts, newContacts) =>
  flow([convertDataToContacts, validateContacts, removeDuplicates, removeExisting(existingContacts)])(
    selectedColumns,
    newContacts
  )
);

type Props = {
  file: File | null;
  type: ContactsTab;
} & NavigationCallbacks;

export const BatchUploadContacts = ({ file, type, goExit, onNext, onPrev }: Props) => {
  const dispatch = useDispatch();
  const orgId = useSelector(getOrgId);
  const contactSelectors = getContactSelector(type);
  const history = useHistory();
  const [currentColumnIndex, updateCurrentColumnIndex] = useLocationState('columnIndex', -1);
  const setCurrentColumnIndex = (index) => updateCurrentColumnIndex(index);
  const isUploading = useSelector(contactSelectors.createBatch.status)?.loading;
  const contactList = useSelector((state) => contactSelectors.list.value(state, { orgId }));
  const actions = useMemo(() => getContactActions(type)(dispatch), [type, dispatch]);
  const [selectedColumns, setSelectedColumns] = useState(zipObject(COLUMNS_KEYS, [] as string[]));
  const [fileContent, isLoading, isError] = useLoadCsv(file);
  useEffect(() => {
    actions.list({ orgId });
  }, [orgId, actions]);

  if (isError || (!isLoading && getSampleColumns(fileContent).length < COLUMNS_KEYS.length)) {
    return (
      <UploadError
        onPrev={onPrev}
        subtitle="contacts.batch.error.subtitle"
        buttonLabel="contacts.batch.error.cancelButton"
      />
    );
  }

  if (isLoading) {
    return (
      <FileUploading
        onPrev={onPrev}
        title="contacts.batch.loading.title"
        subtitle="contacts.batch.loading.subtitle"
        buttonLabel="contacts.batch.loading.button"
      />
    );
  }

  const onPrevColumn = () => (currentColumnIndex > -1 ? history.goBack() : onPrev());

  if (currentColumnIndex === -1) {
    return (
      <ColumnExplain
        title="contacts.batchColumnExplain.title"
        subtitle="contacts.batchColumnExplain.subtitle"
        onPrev={onPrevColumn}
        onExit={goExit}
        onNext={() => setCurrentColumnIndex(0)}
      />
    );
  }

  const onSelectNextColumn = (columnName) => {
    setSelectedColumns({
      ...selectedColumns,
      [COLUMNS_KEYS[currentColumnIndex]]: columnName,
    });
    resetScroll();

    if (currentColumnIndex <= COLUMNS_KEYS.length) {
      setCurrentColumnIndex(currentColumnIndex + 1);
    }
  };

  if (currentColumnIndex < COLUMNS_KEYS.length) {
    const columnsToFilter = COLUMNS_KEYS.slice(0, currentColumnIndex).map((col) => selectedColumns[col]);
    const columnOptions = getSampleColumns(fileContent).filter(
      (column) => columnsToFilter.indexOf(column.name) === -1 && !isEmpty(column.name)
    );
    const selectedColumn = selectedColumns[COLUMNS_KEYS[currentColumnIndex]];

    return (
      <SelectColumnPage
        selected={find(columnOptions, (c) => c.name === selectedColumn) ? selectedColumn : undefined}
        options={columnOptions}
        onNext={onSelectNextColumn}
        onPrev={onPrevColumn}
        onExit={goExit}
        title="contacts.batch.column.title"
        label={`contacts.batch.columnNames.${COLUMNS_KEYS[currentColumnIndex]}`}
      />
    );
  }

  const contacts = createContactsToUpload(selectedColumns, contactList, fileContent);
  const onConfirmAndSave = async () => {
    await actions.createBatch({ orgId, data: contacts });
    onNext && onNext();
  };

  if (isEmpty(contacts)) {
    return (
      <NoCsvDataToUploadError
        onPrev={onPrev}
        title="contacts.batch.noContactsToUpload.title"
        buttonLabel="contacts.batch.noContactsToUpload.button"
      />
    );
  }

  return (
    <BatchUploadContactsReviewPage
      contactType={type}
      onExit={goExit}
      onPrev={onPrevColumn}
      onNext={onConfirmAndSave}
      contacts={contacts}
      isLoading={isUploading}
    />
  );
};
