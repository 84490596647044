import {
  CreateAndLinkExistingBankAccountRequest,
  LinkToExistingBankAccountRequest,
} from 'src/pages/receiving-method/ach/types';
import { fetchRequest, postRequest, putRequest } from 'src/services/api/api';

export const accountingPlatformsApi = {
  connect(params: { orgId: number; id: ToDo; redirect: string }) {
    const { orgId, id, redirect } = params;
    const url = `/orgs/${orgId}/accounting-platforms/${id}`;

    return postRequest(url, { redirect });
  },
  getSyncStatus(params: { orgId?: number }) {
    const { orgId } = params;
    const url = `/orgs/${orgId}/accounting-platforms/sync`;

    return fetchRequest(url).then((res) => res.accountingPlatform);
  },
  async getSyncStatusSummary(params: { orgId?: number; id?: number }) {
    const { orgId } = params;
    const url = `/orgs/${orgId}/accounting-platforms/sync`;

    const syncStatusResult = await fetchRequest(url).then((res) => res.accountingPlatform);

    return {
      isActive: syncStatusResult.isActive,
      lastUpdateDate: syncStatusResult.lastSyncAt,
      accountingPlatformName: syncStatusResult.name,
      syncStatus: syncStatusResult.syncStatus,
    };
  },
  triggerSync({ orgId }: { orgId: number }) {
    const url = `/orgs/${orgId}/accounting-platforms/sync`;

    return postRequest(url);
  },

  list(params: { orgId: number }) {
    const { orgId } = params;
    const url = `/orgs/${orgId}/accounting-platforms`;

    return fetchRequest(url).then((res) => ({ items: res.platforms }));
  },

  updateSyncActivation(params: { orgId?: number; id: number; isActive: boolean }) {
    const { orgId, id, isActive } = params;
    const action = isActive ? 'activate' : 'deactivate';
    const url = `/orgs/${orgId}/accounting-platforms/${id}/${action}`;

    return putRequest(url);
  },

  listBankAccounts(params: { orgId: number }) {
    const { orgId } = params;
    const url = `/orgs/${orgId}/accounting-platforms/bank-accounts`;

    return fetchRequest(url);
  },

  linkToExistingBankAccount(params: LinkToExistingBankAccountRequest) {
    const { orgId, deliveryMethodId, accountId } = params;
    const url = `/orgs/${orgId}/accounting-platforms/bank-accounts`;

    return postRequest(url, { deliveryMethodId, accountId });
  },

  createBankAccountAndLink(params: CreateAndLinkExistingBankAccountRequest) {
    const { orgId, name, routingNumber, accountNumber, deliveryMethodId } = params;
    const url = `/orgs/${orgId}/accounting-platforms/bank-accounts`;

    return postRequest(url, {
      deliveryMethodId,
      name,
      routingNumber,
      accountNumber,
    });
  },

  createAccountingPlatformAccount(
    orgId: number,
    params: {
      name: string;
      type: string;
    }
  ): Promise<{ remoteAccountId: string }> {
    const url = `/orgs/${orgId}/accounting-platforms/payable-accounts`;

    return postRequest(url, params);
  },

  optInInvoiceGeneration(params: { orgId: number }): Promise<void> {
    const { orgId } = params;
    const url = `/orgs/${orgId}/accounting-platforms/opt-in-auto-generation-invoices-files`;

    return postRequest(url);
  },

  optOutInvoiceGeneration(params: { orgId: number }): Promise<void> {
    const { orgId } = params;
    const url = `/orgs/${orgId}/accounting-platforms/opt-out-auto-generation-invoices-files`;

    return postRequest(url);
  },

  getUnsyncedQuickbooksClients({ orgId, customersFileRef }: { orgId: number; customersFileRef: string }): Promise<any> {
    const url = `/orgs/${orgId}/accounting-firm/import-clients-qboa/${customersFileRef}`;

    return fetchRequest(url);
  },

  updateMelioFeesAccountId({
    orgId,
    melioFeesAccountId,
  }: {
    orgId: number;
    melioFeesAccountId: string | null;
  }): Promise<unknown> {
    const url = `/orgs/${orgId}/accounting-platforms/melio-fees-account`;

    return putRequest(url, { melioFeesAccountId });
  },

  getVendorsAcctNum(orgId: number, vendorIds: string[]) {
    const url = `/orgs/${orgId}/accounting-platforms/get-vendor-external-acct-num?vendorIds=${vendorIds.join()}`;

    return fetchRequest(url);
  },
};
