import styled from 'styled-components';
import MIAddressAutocomplete from 'src/components/common/MIAddressAutocomplete';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { ModelViewField } from 'src/ui/form';
import { AddressFormControlMode } from 'src/utils/consts';
import { ManualAddressContainer } from './ManualAddressContainer';

type Props = {
  manualAddressView: boolean;
  onSubmitManualAddress: (newAddress) => Promise<void>;
  toggleManualAddress: () => void;
  submit: any;
  validationErrors: Record<string, any>;
  goExit: () => void;
  companyName: ModelViewField<string>;
  address: Record<string, any>;
  setAddress: (address) => void;
};

const NewCompanyDetailsPage = ({
  manualAddressView,
  onSubmitManualAddress,
  toggleManualAddress,
  goExit,
  submit,
  validationErrors,
  address,
  setAddress,
  companyName,
}: Props) => (
  <>
    {manualAddressView ? (
      <ManualAddressContainer onSuccess={onSubmitManualAddress} onExit={toggleManualAddress} />
    ) : (
      <StepLayoutPage
        title="inviteNewCompany.details.title"
        isPrevDisabled
        goExit={goExit}
        relativeStep={1 / 2}
        onNext={submit}
      >
        <FormContainer onSubmit={submit}>
          <TextField label="inviteNewCompany.details.companyNameLabel" model={companyName} isRequired autoFocus />
          <MIAddressAutocomplete
            id="address"
            addressLabel="inviteNewCompany.details.companyAddressLabel"
            aptNumberLabel="inviteNewCompany.details.aptNumberLabel"
            address={address}
            onChange={(address) => setAddress(address)}
            goToManualAddress={toggleManualAddress}
            required
            showAddManualAddress
            mode={AddressFormControlMode.INLINE}
            errorMessage={validationErrors}
          />
        </FormContainer>
      </StepLayoutPage>
    )}
  </>
);

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  > *:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

export default NewCompanyDetailsPage;
