import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { useApi } from 'src/hoc/useApi';
import { invoicesApi } from 'src/modules/invoices/api';
import { InvoiceType } from 'src/modules/invoices/types';
import { getOrgId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications/notificationService';
import { NotificationVariant } from 'src/utils/consts';

type Props = {
  invoice: InvoiceType;
};

const ResendRequestButton = ({ invoice }: Props) => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: resend, result, loading, resetState } = useApi({
    api: invoicesApi.resend,
  });
  const onResend = async () => {
    await resend({
      orgId,
      id: invoice.id,
    });
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'getPaid.share.success',
    });
  };

  useEffect(() => {
    resetState();
  }, [invoice, resetState]);

  return (
    <Box
      ml={{
        base: 0,
        xl: 3,
      }}
    >
      <Button
        variant={ButtonVariants.tertiary}
        label="requests.melioMeLinkCard.resendRequest"
        size={ButtonSizes.sm}
        paddingY="5px"
        onClick={onResend}
        disabled={loading || !!result}
      />
    </Box>
  );
};

export default ResendRequestButton;
