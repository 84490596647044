import * as React from 'react';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { theme } from 'src/theme/defaultTheme';

type Props = {
  children: React.ReactNode;
  noPadding?: boolean;
};

export const FullPageLayout = ({ children, noPadding }: Props) => (
  <Flex __css={MainContainerStyle}>
    <Box __css={ContainerStyle(noPadding)}>{children}</Box>
  </Flex>
);

const MainContainerStyle = {
  display: 'flex',
  h: 'full',
  bgColor: 'grey.200',
  overflow: 'hidden',
  pos: 'absolute',
  w: { base: 'full', lg: `calc(100% - ${theme.sizes.sideMenu})` },
  ml: { lg: '7.3rem' },
};

const ContainerStyle = (noPadding?: boolean) => ({
  bgColor: 'grey.200',
  overflowY: 'auto',
  w: 'full',
  transition: '150ms',
  p: { base: '8.5rem 0rem 0rem 0rem', lg: noPadding ? '0' : '4rem 0 0' },
});
