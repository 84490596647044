import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import set from 'lodash/fp/set';
import merge from 'lodash/merge';
import { useEffect, useState } from 'react';
import { differenceDeep } from 'src/helpers/difference-deep';
import { mapValuesDeep } from 'src/helpers/immutable-js/map-values-deep';
import { useMounted } from 'src/hooks/useMounted';
import { analytics } from 'src/services/analytics';
import { Expandable } from 'src/utils/types';

export type ModelViewField<T> = {
  value?: T extends Record<string, any> ? Partial<T> : T extends null ? any : T;
  id: string;
  onChange: (change: Expandable<{ value: T }>) => any;
  changeAndUpdate: (change: { value: T }) => any;
  setError?: (errMessage?: string) => void;
  error?: string;
} & (T extends Record<string, any> ? ModelView<T> : Record<string, unknown>);

export type ModelView<M> = {
  [F in keyof M]-?: ModelViewField<M[F]>;
} & {
  setModelState: (state: Record<string, any>) => void;
  setValidationErrors: (state: Record<string, string>) => void;
};

export type ValidationErrors<T> = Partial<{ [F in keyof T]: string }>;

export type UseFormResult<T> = [
  ModelView<T>,
  {
    submit: (
      event?:
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLButtonElement, MouseEvent> // temp solution until full transition to chakra
        | Event,
      onSubmitChanges?: any
    ) => Promise<any>;
    cancel: () => void;
  },
  ValidationErrors<T>,
  boolean,
  T
];

export type ModelViewOptions<T> = {
  submit: (value: T, changes: Partial<T>) => Promise<any>;
  onetimeModel?: boolean;
  onClear?: () => any;
  onChange?: ({ key: string, value: any, modelState: T }) => T;
  validator?: (key: string, value: any, modelState: T) => Promise<string | undefined> | undefined | string;
  validateOnChange?: boolean;
};

export function useForm<T extends Record<string, unknown>>(model: T, options: ModelViewOptions<T>): UseFormResult<T> {
  const { isMounted } = useMounted();
  const [modelState, setModelState] = useState(model);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const validateOnChange = options.validateOnChange === undefined ? true : options.validateOnChange;

  async function performSubmit(newValue: T, onSubmitChanges?: Record<string, any>) {
    const diff = differenceDeep(newValue, model);
    try {
      setLoading(true);
      const res = await options.submit(newValue, { ...diff, ...onSubmitChanges });

      if (isMounted()) {
        setLoading(false);
      }

      return res;
    } catch (e: any) {
      if (e.error?.validationErrors) {
        analytics.trackAction('form-validation-error', {
          validationErrors: e.error?.validationErrors,
        });

        if (isMounted()) {
          setValidationErrors(e.error?.validationErrors);
        }
      }

      if (isMounted()) {
        setLoading(false);
      }

      return null;
    }
  }

  const getValidationErrors = async (f, key, value, modelState) => {
    const error = await f(key, value, modelState);

    return error ? { [key]: error } : null;
  };

  const performValidation = async (newValue: T): Promise<Record<string, string> | undefined> => {
    let validations;

    if (options?.validator) {
      const validationErrorsPromise = Object.entries(newValue).map(async ([key, value]) =>
        getValidationErrors(options.validator, key, value, modelState)
      );

      const result = await Promise.all(validationErrorsPromise);
      validations = merge({}, ...result);
    }

    setValidationErrors({
      ...validationErrors,
      ...validations,
    });

    return validations;
  };

  const mv: ModelView<T> = {
    ...mapValuesDeep<T, any>(model, (value, key: string) => ({
      value: get(key, modelState),
      id: key,
      onChange({ value }) {
        if (validationErrors[key]) {
          setValidationErrors((validationErrors) => ({
            ...validationErrors,
            [key]: undefined,
          }));
        }

        if (options?.validator && validateOnChange) {
          const validationError = options?.validator(key, value, modelState);
          setValidationErrors((validationErrors) => ({
            ...validationErrors,
            [key]: validationError,
          }));
        }

        setModelState((prevState) => {
          let newState = set(key, value, prevState);

          if (options.onChange) {
            newState = options.onChange({ key, value, modelState: newState });
          }

          return newState;
        });
      },
      changeAndUpdate({ value }) {
        setModelState((prevState) => set(key, value, prevState));

        return performSubmit(set(key, value, modelState));
      },
      setError(errMessage?: string) {
        setValidationErrors((validationErrors) => ({
          ...validationErrors,
          [key]: errMessage,
        }));
      },
      error: validationErrors[key],
    })),
    setModelState: setModelState as any,
    setValidationErrors: setValidationErrors as any,
  };
  useEffect(() => {
    if (!options.onetimeModel) {
      setModelState(model);
    }
  }, [model, options.onetimeModel]);

  async function submit(
    event?:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent> // temp solution until full transition to chakra
      | Event,
    onSubmitChanges?: Record<string, any>
  ) {
    event && event.preventDefault();
    const validation = await performValidation(modelState);

    if (isEmpty(validation)) {
      const res = await performSubmit(modelState, onSubmitChanges);

      if (res && options.onClear) {
        options.onClear && options.onClear();
      }

      return res;
    }

    analytics.trackAction('form-validation-error', {
      validationErrors: validation,
    });

    return null;
  }

  function cancel() {
    setModelState(model);
    setValidationErrors({});
    options.onClear && options.onClear();
  }

  return [
    mv,
    {
      submit,
      cancel,
    },
    validationErrors,
    loading,
    modelState,
  ];
}
