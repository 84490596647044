import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormErrorMessage } from 'src/core/ds/form';

type Props = {
  size?: string;
  testId?: string;
  label?: string;
  labelValues?: Record<string, unknown>;
};

export const ErrorMessage = ({ size, testId, label, labelValues }: Props) => (
  <FormErrorMessage size={size} data-testid={testId}>
    <MIFormattedText label={label} values={labelValues} />
  </FormErrorMessage>
);
