import { useDisclosure } from '@chakra-ui/react';
import { ComponentProps, useCallback } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { IconNames } from 'src/core/ds/icon';
import { useOrgId } from 'src/hooks';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { AddBillAttachmentModal } from 'src/pages/regular-batch-payments/components/dialog/AddBillAttachmentModal';
import { useInvoiceAttachmentRequired } from 'src/pages/vendor/international-delivery-method/hooks/useInvoiceAttachmentRequired';
import { analytics } from 'src/services/analytics';
import { ActionPopover } from '../ActionPopover';

type DisabledSelectionProps = {
  bill: BatchBillType;
};

export const InternationalPaymentBillSubCell = ({ bill }: DisabledSelectionProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const orgId = useOrgId();
  const [isInvoiceAttachmentRequired] = useInvoiceAttachmentRequired(orgId, bill.vendor.id, bill);

  const openPurposeModal = useCallback(() => {
    analytics.track('regular-batch-payments', 'international-purpose-opened');
    onOpen();
  }, [onOpen]);

  const openModal = (e) => {
    e.stopPropagation();
    openPurposeModal();
  };

  const tooltipProps: ComponentProps<typeof ActionPopover> = {
    label: 'regularBatchPayments.tooltips.internationalAttachInvoice',
    iconName: IconNames.warning,
    iconColor: 'yellow.500',
    popoverTestId: 'missing-invoice-popover',
    iconTestId: 'missing-invoice-icon',
  };

  return (
    <>
      {isOpen && <AddBillAttachmentModal onClose={onClose} bill={bill} />}
      {isInvoiceAttachmentRequired && (
        <Flex align="center" justify="space-between" h="full" onClick={openModal}>
          <Box textStyle="link3" isTruncated>
            <MIFormattedText label="regularBatchPayments.cells.deliveryMethod.attachInvoiceLabel" />
          </Box>
          <ActionPopover {...tooltipProps} />
        </Flex>
      )}
    </>
  );
};
