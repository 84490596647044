import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { DockedButtonMobile } from 'src/components/mobile/DockedButtonMobile';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { getJWTPayload } from 'src/helpers/jwt';
import { useBreak } from 'src/hoc';
import doneImage from 'src/images/general/check-circle.svg';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import DeliveryMethodSuccessUpsaleVendorPage from 'src/pages/vendor/shift-vendor-to-ach/DeliveryMethodSuccessUpsaleVendorPage';
import { getIsLoggedIn, getOrgId } from 'src/redux/user/selectors';
import { devices } from 'src/theme/appDevices';
import locations from 'src/utils/locations';

type Props = {
  token: string;
};

const SuccessAddDeliveryMethodPage = ({ token }: Props) => {
  const { isMobile } = useBreak();
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { payerCompanyName } = getJWTPayload(token);
  const { deliveryMethodId } = useSelector(deliveryMethodsStore.selectors.validation);
  const deliveryMethod = useSelector(deliveryMethodsStore.selectors.byId(deliveryMethodId));

  const goDashboard = () => {
    history.push(locations.MainApp.dashboard.url({ orgId }));
  };

  if (!isLoggedIn) {
    return <DeliveryMethodSuccessUpsaleVendorPage token={token} />;
  }

  return (
    <>
      <OutsideLayout>
        <HeaderContentContainer>
          <DoneIcon src={doneImage} />
          <Title>
            <MIFormattedText label="vendors.deliveryMethods.shiftVendorToAch.success.title" />
          </Title>
          <Subtitle>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftVendorToAch.success.subtitle"
              values={{
                companyName: payerCompanyName,
                digits: deliveryMethod?.bankAccount?.accountNumber.slice(-4),
              }}
            />
          </Subtitle>
          {isMobile ? (
            <DockedButtonMobile
              label="vendors.deliveryMethods.shiftVendorToAch.success.goDashboard"
              onClick={goDashboard}
            />
          ) : (
            <Button
              onClick={goDashboard}
              label="vendors.deliveryMethods.shiftVendorToAch.success.goDashboard"
              size={ButtonSizes.lg}
              isFullWidth
            />
          )}
        </HeaderContentContainer>
      </OutsideLayout>
    </>
  );
};

const DoneIcon = styled.img`
  height: 4rem;
  width: 4rem;
  margin-bottom: 1rem;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.text.weight.bold};
  margin-bottom: 0.5rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const Subtitle = styled.div`
  margin-bottom: 4rem;
  color: ${(props) => props.theme.text.color.subtitle};
  ${(props) => props.theme.text.fontType.regular};
  @media ${devices.mobile} {
    margin-bottom: 0rem;
  }
`;

const HeaderContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.background.wizard};
  padding: 4rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  align-items: left;
  position: relative;
  @media ${devices.mobile}, ${devices.phablet} {
    padding: 4rem 2rem;
  }
`;

export default SuccessAddDeliveryMethodPage;
