import { OrderedSet } from 'immutable';
import styled from 'styled-components';
import { MILogoButton } from 'src/components/common/MILogoButton';
import { devices } from 'src/theme/appDevices';
import { accountingSoftwareOptions } from 'src/utils/accounting-software';
import { AccountingSoftware } from 'src/utils/consts';

type Props = {
  selected: AccountingSoftware | null | undefined;
  onSelect: (selected: AccountingSoftware) => void;
  isDisableUnavailable: boolean;
};

export const AccountingSoftwareList = ({ selected, onSelect, isDisableUnavailable }: Props) => {
  const options = OrderedSet(accountingSoftwareOptions);

  return (
    <AccountingSoftwareGrid>
      {options.toArray().map((item) => {
        const isSelected = item.code === selected;
        const isDisabled = isDisableUnavailable ? !item.isActive : false;

        return (
          <AccountingSoftwareItem key={item.code}>
            <MILogoButton id={item.code} onClick={onSelect} selected={isSelected} disabled={isDisabled} />
          </AccountingSoftwareItem>
        );
      })}
    </AccountingSoftwareGrid>
  );
};

AccountingSoftwareList.defaultProps = {};

const AccountingSoftwareGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
`;

const AccountingSoftwareItem = styled.div`
  margin: 1rem;
  width: 46%;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    width: 100%;
  }
`;
