import * as React from 'react';
import { useSelector } from 'react-redux';
import { StaticContext } from 'react-router';
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom';
import config from 'src/config';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getOrgId } from 'src/redux/user/selectors';
import { NavigateType } from 'src/utils/types';
import { JsonObject, JsonValue } from 'src/utils/utility-types';
import { convertStringToUrlObject, parseQueryString, stringifyQs } from '../utils/query-utils';

export function getNavigate<TParams>(props: RouteComponentProps<TParams>, orgId?: number): NavigateType {
  return (url, shouldReplaceCurrent = false, state = undefined, exitIframe = false, newTab = false) => {
    const parsedUrl = convertStringToUrlObject(url);

    parsedUrl.pathname = generatePath(parsedUrl.pathname ?? '', {
      orgId,
      ...props.match.params,
    });

    if (newTab) {
      const urlAsString = parsedUrl.search ? parsedUrl.pathname : `${parsedUrl.pathname}?${parsedUrl.search}`;
      window.open(urlAsString);

      return null;
    }

    if (exitIframe) {
      const appUrl = new URL(parsedUrl.pathname, config.web.baseUrl);
      appUrl.search = parsedUrl.search ?? '';

      if (window.top && window !== window.parent) {
        window.top.location.href = appUrl.href;

        return null;
      }
    }

    if (shouldReplaceCurrent) {
      return props.history.replace({ ...parsedUrl, state });
    }

    return props.history.push({ ...parsedUrl, state });
  };
}

function getPathWithParams<TProps>(props: NavigationProperties<TProps, unknown>): typeof generatePath {
  return (url, params) => generatePath(url, { ...props.match.params, ...params });
}

export type NavigationProperties<TProps, TState> = RouteComponentProps<TProps, StaticContext, TState> & {
  navigate: NavigateType;
  locationState: Record<string, any>;
  basePath: string;
  pathWithParams: string;
  params: Record<string, unknown>;
  query: Record<string, string>;
};
export type ComponentWithoutNavigationProps<TProps, TState> = React.ComponentClass<
  Omit<TProps, keyof NavigationProperties<TProps, TState>>
>;

function withNavigator() {
  return function <TProps, TState extends JsonObject>(
    Component: React.ComponentType<TProps>
  ): ComponentWithoutNavigationProps<TProps, TState> {
    return withRouter<RouteComponentProps<any>, any>((oProps) => {
      const props = { ...oProps };
      const orgId = useSelector(getOrgId);
      const navigate = getNavigate(oProps, orgId);
      const pathWithParams = getPathWithParams(oProps);

      const query = parseQueryString(oProps.location.search);

      return (
        <Component
          {...props}
          locationState={{ ...oProps.location.state }}
          basePath={oProps.location.pathname}
          navigate={navigate}
          pathWithParams={pathWithParams}
          params={props.match.params}
          query={query}
        >
          {props.children}
        </Component>
      );
    }) as any;
  };
}

export type NavigationState<TState extends JsonValue = JsonValue> = {
  preservedState?: JsonObject;
  redirectUrl?: string;
  exitUrl?: string;
} & TState;

function withPreservedStateNavigator() {
  return function withPreservedStateNavigator<TProps, TState extends NavigationState<JsonObject>>(
    Component: React.ComponentType<TProps>
  ) {
    return withRouter<NavigationProperties<TProps, TState>, React.ComponentType<NavigationProperties<TProps, TState>>>(
      (props) => {
        const { navigate } = useNavigator();
        const query = parseQueryString(props.location.search);

        const handleNavigation: NavigateType = (url, shouldReplaceCurrent = false, state) => {
          const { preservedState, redirectUrl, exitUrl } = props.location.state || {};
          navigate(url, shouldReplaceCurrent, {
            ...state,
            preservedState,
            redirectUrl,
            exitUrl,
          });
        };

        const navigateWithPreservedState = (dataToAdd) => {
          const { preservedState, redirectUrl } = props.location.state || {};
          navigate(redirectUrl!, false, { ...preservedState, ...dataToAdd });
        };

        const navigateToExitWithPreservedState = (dataToAdd) => {
          const { preservedState, exitUrl } = props.location.state || {};
          navigate(exitUrl!, false, { ...preservedState, ...dataToAdd });
        };

        return (
          // @ts-expect-error -- typing HOCs is a PITA, not worth the effort
          <Component
            {...props}
            locationState={{ ...props.location.state }}
            basePath={props.location.pathname}
            navigate={handleNavigation}
            navigateWithPreservedState={props.location.state?.redirectUrl ? navigateWithPreservedState : null}
            navigateToExitWithPreservedState={props.location.state?.exitUrl ? navigateToExitWithPreservedState : null}
            query={query}
          >
            {props.children}
          </Component>
        );
      }
    );
  };
}

function withListNavigator() {
  return function (Component: any) {
    return withRouter((oProps) => {
      const props = { ...oProps };
      const { navigate } = useNavigator();
      const query = parseQueryString(oProps.location.search);
      const { id, ...filters } = query;
      const setSelected = (id) => {
        const search = stringifyQs({ ...query, id });
        navigate(`${oProps.location.pathname}?${search}`);
      };

      return (
        <Component
          {...props}
          locationState={oProps.location.state}
          basePath={oProps.location.pathname}
          query={query}
          navigate={navigate}
          id={id}
          filters={filters}
          setSelected={setSelected}
        >
          {props.children}
        </Component>
      );
    });
  };
}

export { withListNavigator, withNavigator, withPreservedStateNavigator };
