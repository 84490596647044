import { BadgeColors } from 'src/core/ds/badge';
import { Event } from 'src/pages/spend-management/SpendManagementAnalytics';

export type DetailFieldType = {
  key: string;
  value: string | number;
  nickname?: string;
  type?: FIELD_TYPE;
};

export enum FIELD_TYPE {
  PRIMARY_CARD = 'primary',
  LIMIT = 'limit',
  NAME = 'name',
  ADDRESS = 'address',
  ZIPCODE = 'zipcode',
  STATUS = 'status',
}

export const BADGE_STATUS_COLOR = {
  ACTIVE: BadgeColors.SUCCESS,
  CANCELLED: BadgeColors.NEUTRAL,
  IN_PROGRESS: BadgeColors.NEUTRAL,
  EXPIRED: BadgeColors.DANGER,
};

export const COPY_ANALYTICS_EVENT_MAPPING = {
  name: Event.COPY_NAME,
  cardNumber: Event.COPY_CC_NUMBER,
  expiryDate: Event.COPY_CC_EXPIRED,
  cvv: Event.COPY_CVV,
  address: Event.COPY_ADDRESS,
  zipcode: Event.COPY_ZIPCODE,
};

export const LIMIT_NOT_SET_VALUE = 10000000;
export const CARD_NAME_CHAR_LIMIT = 30;
