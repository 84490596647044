import { BillStatus, Currency } from 'src/utils/consts';
import { CreateBillType } from 'src/utils/types';

export const getJustPayBillModel = (): CreateBillType => ({
  vendorId: null,
  totalAmount: '',
  intuitAccountId: '',
  invoiceNumber: '',
  dueDate: new Date().toISOString(),
  balance: null,
  status: BillStatus.UNPAID,
  currency: Currency.USD,
  note: '',
  accountIdentifier: '',
  files: [],
});
