import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { EditElement } from 'src/components/layout/PaymentActivityElements';
import Box from 'src/core/ds/box/Box';

type BaseContainerProps = {
  title: string;
  textStyle?: string;
  editText?: () => void;
  testId?: string;
  children: React.ReactNode;
};

export const RefundReviewBaseContainer = ({ title, textStyle, editText, testId, children }: BaseContainerProps) => (
  <Box w="full" m={6}>
    <Box display="flex" justifyContent="space-between" textStyle="body4Semi" color="grey.700" mr={6}>
      <MIFormattedText label={title} />
      {editText && <EditElement onEdit={editText} />}
    </Box>
    <Box textStyle={textStyle} mt={1} data-testid={testId} color="black">
      {children}
    </Box>
  </Box>
);
