import { MIFloatedEditDoneButtons } from 'src/components/common/MIFloatedEditDoneButtons';
import Box from 'src/core/ds/box';
import { analytics } from 'src/services/analytics';
import { BatchContactsScreenMode } from './consts';

type Props = {
  mode: BatchContactsScreenMode;
  count: number;
  cancel: () => void;
  submit: () => void;
  isDoneDisabled: boolean;
  analyticsProps: Record<string, any>;
};

export const BatchSettingsFooter = ({ mode, count, cancel, submit, isDoneDisabled, analyticsProps }: Props) => {
  const isUnilateralMode = mode === BatchContactsScreenMode.UNILATERAL;

  const onDone = () => {
    analytics.track('contacts', 'batch-unilateral-actions-done', analyticsProps);
    submit();
  };

  const onCancel = () => {
    analytics.track('contacts', 'batch-unilateral-actions-cancel', analyticsProps);
    cancel();
  };

  return (
    <>
      {isUnilateralMode && (
        <Box mt={isUnilateralMode ? '10rem' : 0}>
          <MIFloatedEditDoneButtons
            onDone={onDone}
            onCancel={onCancel}
            doneLabel="vendors.batchSettings.footer.unilateralConfirm"
            doneValues={{ count }}
            cancelLabel="vendors.batchSettings.footer.cancel"
            isDoneDisabled={isDoneDisabled}
          />
        </Box>
      )}
    </>
  );
};
