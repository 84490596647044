import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';

export const AgreementLabel = () => {
  const site = useSiteContext();

  return (
    <Box color="grey.700" textStyle="body4" textAlign="center">
      <MIFormattedText
        label="vendors.upgradePayment.landing.agreement.base"
        values={{
          termsOfService: (
            <Link
              href={site.config.agreementLinks.userAgreement}
              target="_blank"
              rel="noopener noreferrer"
              color="primary.500"
              textDecor="none"
            >
              <MIFormattedText label="vendors.upgradePayment.landing.agreement.termsOfService" />
            </Link>
          ),
          privacyPolicy: (
            <Link
              href={site.config.agreementLinks.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
              color="primary.500"
              textDecor="none"
            >
              <MIFormattedText label="vendors.upgradePayment.landing.agreement.privacyPolicy" />
            </Link>
          ),
        }}
      />
    </Box>
  );
};
