import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormControl, FormLabel, FormRow } from 'src/core/ds/form';
import { ErrorMessage } from 'src/core/ds/form/fields/components';
import { CompanyIndustryAutocomplete } from 'src/pages/onboarding/company-info/components/CompanyIndustryAutocomplete';
import { UpdateIndustryRequestData } from 'src/services/api/industry';
import { ModelViewField } from 'src/ui/form';

type Props = {
  label: string;
  model: ModelViewField<string>;
};

export const BusinessIndustryFormRow = ({ label, model }: Props) => {
  const handleBusinessIndustryChange = ({ industryText }: UpdateIndustryRequestData) => {
    model.onChange({ value: industryText || '' });
  };

  const handleBusinessIndustryReset = () => {
    model.onChange({ value: '' });
  };

  const isError = Boolean(model?.error);
  const errorMessage = model.error;

  return (
    <FormRow>
      <FormControl isInvalid={isError}>
        <FormLabel htmlFor={model.id}>
          <MIFormattedText label={label} />
        </FormLabel>
        <CompanyIndustryAutocomplete
          onReset={handleBusinessIndustryReset}
          onSelectNaicsCode={handleBusinessIndustryChange}
          defaultValue={model.value}
        />
        {errorMessage && <ErrorMessage size="md" testId={model.id} label={errorMessage} />}
      </FormControl>
    </FormRow>
  );
};
