import noop from 'lodash/noop';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import { useForm } from 'src/ui/form';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';

const ViewManualBankAccountPage = () => {
  const { goBack } = useHistory();
  const params = useParams<{ orgId: string; id: string }>();
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(params.id));
  const model = useMemo(() => pick(fundingSource?.bankAccount, ['routingNumber', 'accountNumber']), [fundingSource]);
  const [bankAccountVM] = useForm(model, { submit: async () => noop });

  return (
    <StepLayoutPage
      title="onboarding.fundingSources.bank.manually.view.title"
      subtitle="onboarding.fundingSources.bank.manually.view.subtitle"
      goExit={goBack}
      onSubmit={goBack}
      nextLabel="progress.prev"
      hideHeader
      fullWidthCTA
      footer={
        <WizardElements.SimpleTextFooterSlim>
          <MIFormattedText label="onboarding.fundingSources.bank.manually.saveFooter" />
        </WizardElements.SimpleTextFooterSlim>
      }
    >
      <WizardFormContainer>
        <PrivateDataContainer>
          <TextField
            data-private
            value={bankAccountVM.routingNumber.value}
            label="onboarding.fundingSources.bank.manually.routingNumber"
            isRequired
            isReadOnly
          />
        </PrivateDataContainer>

        <PasswordField
          data-private
          value={bankAccountVM.accountNumber.value}
          label="onboarding.fundingSources.bank.manually.accountNumber"
          isRequired
        />
        <HiddenInput type="password" autoComplete="new-password" />
      </WizardFormContainer>
    </StepLayoutPage>
  );
};

export default ViewManualBankAccountPage;

const HiddenInput = styled.input`
  display: none;
`;
