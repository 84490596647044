import { CardCreationSourceEnum, CardPciData, CardResponse } from '@melio/spend-management-api-axios-client';
import { deleteRequest, fetchRequest, postRequest } from 'src/services/api/api';
import { CardPciParams, CardsListParams, CreateCardParams, DeleteCardParams } from './types';

export const cardsApi = {
  list({ orgId, accountId, status = [] }: CardsListParams) {
    const url = `/orgs/${orgId}/spend/cards`;

    return fetchRequest(url, { accountId, status: status?.join(',') }).then(
      (res: { cards: CardResponse[] }) => res.cards
    );
  },
  create({ orgId, accountId, nickname, amount, expiryDate }: CreateCardParams) {
    const url = `/orgs/${orgId}/spend/cards`;
    const amountInCents = amount ? parseFloat(amount) * 100 : null;

    return postRequest(url, {
      accountId,
      nickname,
      amount: amountInCents,
      expiryDate,
      cardCreationSource: CardCreationSourceEnum.Web,
    }).then((res: { cardId: string }) => res.cardId);
  },
  delete({ orgId, cardId }: DeleteCardParams) {
    const url = `/orgs/${orgId}/spend/cards`;

    return deleteRequest(url, { cardId }).then((res: { id: string }) => res.id);
  },
  pci({ orgId, cardId }: CardPciParams) {
    const url = `/orgs/${orgId}/spend/card/pci`;

    return fetchRequest(url, { cardId }).then((res: CardPciData) => res.cardDetails);
  },
};
