import { useEffect } from 'react';
import { ContentWrapper, Header } from 'src/pages/vendor/components/VendorLayoutElements';
import { VirtualCardDetailsFAQ } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardDetailsFAQ';
import { VirtualCardDetailsView } from 'src/pages/vendor/virtual-card/delivery-method/components/VirtualCardDetailsView';
import { VirtualCardPaymentState } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardState';
import { eventPage } from 'src/pages/vendor/virtual-card/delivery-method/hooks/useVirtualCardView';
import { analytics } from 'src/services/analytics';

type Props = {
  state: VirtualCardPaymentState;
  analyticsProperties?: Record<string, any>;
};

export const VirtualCardValidView = ({ state, analyticsProperties }: Props) => {
  const { payment, organization, vendor, filesUrls } = state;
  useEffect(() => {
    analytics.track(eventPage, 'valid', analyticsProperties);
  }, [analyticsProperties]);

  return (
    <>
      <Header
        payment={payment}
        organization={organization}
        vendor={vendor}
        filesUrls={filesUrls}
        showFullHeader
        subTitle="vendors.virtualCard.valid.header.subtitle"
        hideAmount
      >
        <VirtualCardDetailsView state={state} analyticsProperties={analyticsProperties} />
      </Header>
      <ContentWrapper title="vendors.virtualCard.valid.content.title">
        <VirtualCardDetailsFAQ />
      </ContentWrapper>
    </>
  );
};
