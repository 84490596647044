import { generatePath } from 'react-router-dom';
import { authLocations } from 'src/pages/auth/locations';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { analytics } from 'src/services/analytics';
import GuestConfirmPageContainer from './GuestConfirmPageContainer';
import GuestEditFundingSourcesPageContainer from './GuestEditFundingSourcesPageContainer';
import GuestEmailVerifyPageContainer from './GuestEmailVerifyPageContainer';
import { GuestExpeditePaymentPageContainer } from './GuestExpeditePaymentPageContainer';
import { GuestInfoDetailsPageContainer } from './GuestInfoDetailsPageContainer';
import { GuestMemoPageContainer } from './GuestMemoPageContainer';
import { GuestPaymentDatePageContainer } from './GuestPaymentDatePageContainer';
import { GuestPaymentRedirectPageContainer } from './GuestPaymentRedirectPageContainer';
import GuestPaymentRequestInfoPageContainer from './GuestPaymentRequestInfoPageContainer';
import GuestRequestInfoPageContainer from './GuestRequestInfoPageContainer';
import GuestSelectLinkPage from './GuestSelectLink';
import GuestSignUpPageContainer from './GuestSignUpPageContainer';
import GuestSuccessPageContainer from './GuestSuccessPageContainer';
import { GuestSuccessSignUpPageContainer } from './GuestSuccessSignUpPageContainer';

const eventPage = 'payor';

export const guestPublicRoutes = [
  {
    path: melioMeLocations.requestPaymentWithOptions,
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'select-link');

      return <GuestSelectLinkPage {...props} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.index, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'payment-amount');

      return <GuestRequestInfoPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.signUp, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'signup');

      return <GuestSignUpPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.emailVerify, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'email-verification');

      return (
        <GuestEmailVerifyPageContainer
          {...props}
          link={props.match.params.link}
          nextRoute={melioMeLocations.wizard.infoDetails}
          prevRoute={melioMeLocations.wizard.signUp}
        />
      );
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.requestPayment, { link: ':link', hash: ':hash' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'start');

      return (
        <GuestPaymentRequestInfoPageContainer
          {...props}
          link={props.match.params.link}
          hash={props.match.params.hash}
        />
      );
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.requestPaymentNoEditing, {
      link: ':link',
      totalAmount: ':totalAmount',
      invoiceNumber: ':invoiceNumber',
    }),
    component: (props) => {
      analytics.page(eventPage, 'button-with-params');

      return <GuestRequestInfoPageContainer editDisabled {...props} link={props.match.params.link} />;
    },
  },
  {
    path: melioMeLocations.guestPaymentRedirect,
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'guest-payment-redirect');

      return <GuestPaymentRedirectPageContainer {...props} />;
    },
  },
  {
    path: melioMeLocations.registerEmailVerify,
    component: (props) => {
      analytics.page(eventPage, 'register-verify-email');

      return (
        <GuestEmailVerifyPageContainer
          {...props}
          prevRoute={authLocations.register.index}
          nextRoute={melioMeLocations.wizard.successSignUp}
        />
      );
    },
  },
];

// user should be logged in to see these routes
export const guestPrivateRoutes = [
  {
    path: generatePath(melioMeLocations.wizard.paymentDate, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'payment-date');

      return <GuestPaymentDatePageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.infoDetails, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'info-details');

      return <GuestInfoDetailsPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.memo, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'memo');

      return <GuestMemoPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.confirm, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'confirm');

      return <GuestConfirmPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.expedite, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'expedite-payment');

      return <GuestExpeditePaymentPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.editFundingSource, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'confirm');

      return <GuestEditFundingSourcesPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    // todo: remove this when isGuestPayorFlowCombineSuccessScreenFF completes
    path: generatePath(melioMeLocations.wizard.success, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'success');

      return <GuestSuccessPageContainer {...props} link={props.match.params.link} />;
    },
  },
  {
    path: generatePath(melioMeLocations.wizard.successSignUp, { link: ':link' }),
    exact: true,
    component: (props) => {
      analytics.page(eventPage, 'successSignUp');

      return <GuestSuccessSignUpPageContainer {...props} link={props.match.params.link} />;
    },
  },
];
