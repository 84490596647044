import { featureFlags } from '@melio/shared-web';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Size } from 'src/core/ds/avatar/consts';
import Box from 'src/core/ds/box';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { useBreak } from 'src/hoc';
import withOutsideClickHandler from 'src/hoc/withOutsideClickHandler';
import { useCanSwitchCompanies } from 'src/hooks/useCanSwitchCompanies';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { FeatureFlags } from 'src/utils/consts';
import { getFullName } from 'src/utils/user';
import { OrganizationSwitcher } from '../../../common/organization-switcher';
import { UserBadgePic } from './UserBadgePic';

type Props = {
  isSideMenuOpen: boolean;
  firstName: string;
  lastName: string;
  companyName: string | null;
  email: string;
  profilePicUrl?: string;
  onAccountSettingsClick: () => void;
  onLogout: () => void;
  className?: string;
};

export const UserBadge = ({
  isSideMenuOpen,
  firstName,
  lastName,
  companyName,
  email,
  profilePicUrl,
  onAccountSettingsClick,
  onLogout,
  className,
}: Props) => {
  const { isMobile } = useBreak();
  const [isMenuOpen, toggleIsMenuOpen] = useState(false);
  const [isSwitcherOpen, toggleIsSwitcherOpen] = useState(false);
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const userName = firstName ? getFullName(firstName, lastName) : email;
  const canSwitchCompanies = useCanSwitchCompanies();
  const actionOptions = [
    {
      label: 'nav.accountSettings',
      action: () => onAccountSettingsClick(),
    },
    {
      label: 'nav.logout',
      action: () => onLogout(),
    },
  ];

  if (canSwitchCompanies) {
    const option = {
      label: 'nav.switchCompany',
      action: () => {
        toggleIsSwitcherOpen(true);
      },
      iconName: 'icon-next-icon-2',
    };
    actionOptions.unshift(option);
  }

  useEffect(() => {
    isSwitcherOpen &&
      analytics.trackAction('companies-switcher', {
        isOpen: isSwitcherOpen,
        location: 'bottom',
      });
  }, [isSwitcherOpen]);

  const onHandleClickOutside = () => {
    toggleIsMenuOpen(false);

    if (isSwitcherOpen) toggleIsSwitcherOpen(false);
  };

  const handleClickUserBadge = () => {
    if (!isMobile) {
      toggleIsMenuOpen(!isMenuOpen);
      analytics.track('side-menu', 'user-badge');

      if (isSwitcherOpen) toggleIsSwitcherOpen(false);
    }
  };

  return (
    <UserBadgeContainer
      className={className}
      handleClickOutside={onHandleClickOutside}
      shouldSplitContactsTab={shouldSplitContactsTab}
    >
      <UserBadgePicContainer>
        <Menu isOpen={isMenuOpen} onOpen={handleClickUserBadge} onClose={() => toggleIsMenuOpen(false)}>
          <MenuButton
            as={Box}
            data-testid="user-badge"
            cursor="pointer"
            _hover={shouldSplitContactsTab ? { filter: 'brightness(80%)' } : { opacity: 0.8 }}
            transition={shouldSplitContactsTab ? 'filter .3s' : 'opacity .3s'}
          >
            <UserBadgePic name={userName} link={profilePicUrl} size={Size.lg} />
          </MenuButton>
          <MenuList>
            {actionOptions.map(({ label, action }, idx) => (
              <MenuItem label={label} onClick={action} key={`${idx}-${label}`} />
            ))}
          </MenuList>
        </Menu>
        {canSwitchCompanies && isSwitcherOpen && (
          <SwitcherContainer>
            <OrganizationSwitcher
              onBack={() => {
                toggleIsSwitcherOpen(false);
                toggleIsMenuOpen(true);
              }}
              canSwitchCompanies
            />
          </SwitcherContainer>
        )}
      </UserBadgePicContainer>
      <UserInfoContainer className={isSideMenuOpen ? 'open' : ''}>
        <UserBadgeName>{userName}</UserBadgeName>
        {companyName && <UserBadgeCompanyName>{companyName}</UserBadgeCompanyName>}
      </UserInfoContainer>
    </UserBadgeContainer>
  );
};

const UserBadgeContainer = withOutsideClickHandler(styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => props.theme.text.size.hint};
  text-decoration: none;
  width: 100%;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  box-sizing: border-box;
  margin: ${({ shouldSplitContactsTab }) => (shouldSplitContactsTab ? '1.6rem 0 0.8rem;' : '1.5rem 0;')};
  height: ${({ shouldSplitContactsTab }) => (shouldSplitContactsTab ? '5.6rem' : 'auto')};

  @media ${devices.desktop} {
    padding-left: 1.5rem;
  }
`);

const UserBadgePicContainer = styled.div`
  display: flex;
  align-items: center;
`;

const UserInfoContainer = styled.div`
  align-self: center;
  display: none;
  margin-left: 1.5rem;
  transition: ${(props) => props.theme.animation.transition.default};
  &.open {
    display: block;
    overflow: hidden;
  }
`;

const UserBadgeName = styled.div`
  color: ${(props) => props.theme.colors.dark.opaque};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  height: 1.7rem;
  line-height: 1.7rem;
  overflow: hidden;
`;

const UserBadgeCompanyName = styled.div`
  color: ${(props) => props.theme.text.color.label};
  font-weight: ${(props) => props.theme.text.weight.regular};
  height: 1.7rem;
  line-height: 1.7rem;
  overflow: hidden;
`;

const SwitcherContainer = styled.div`
  position: absolute;
  z-index: 10000;
  left: 5rem;
  width: 31.5rem;
  bottom: 0;
`;
