import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';

type TagProps = {
  label: string;
};

const Tag = ({ label }: TagProps) => (
  <TagText>
    <MIFormattedText label={label} />
  </TagText>
);

export default Tag;

const TagText = styled.span`
  color: #00d28f;
  margin-left: 0.6rem;
`;
