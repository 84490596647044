import { featureFlags } from '@melio/shared-web';
import { IconNames } from 'src/core/ds/icon';
import { usePlatformRedirection } from 'src/modules/platform-app/usePlatformRedirection';
import { globalLocations } from 'src/pages/locations';
import { FeatureFlags } from 'src/utils/consts';
import { MenuItemPropsType } from '../../types/type';

export const usePayGroupItems = (): Array<MenuItemPropsType> => {
  const [shouldSplitContactsTab] = featureFlags.useFeature(FeatureFlags.SplitContactsTab, false);
  const [isNewPayDashboardEnabled] = featureFlags.useFeature(FeatureFlags.NewDashboardEnabled, false);
  const { isOrgMigratedToPlatform } = usePlatformRedirection({ isEnabled: true });
  const migratedOrgWithNewDashboard = isOrgMigratedToPlatform && isNewPayDashboardEnabled;

  const links: Array<MenuItemPropsType> = [
    {
      key: 'menu-item-pay',
      to: globalLocations.bill.index,
      label: 'nav.pay',
      icon: IconNames.pay,
      eventName: 'pay-list',
    },
  ];

  if (shouldSplitContactsTab && !migratedOrgWithNewDashboard) {
    links.push({
      key: 'menu-item-vendors',
      to: globalLocations.contacts.vendors,
      label: 'nav.vendors',
      icon: IconNames.vendor,
      eventName: 'vendors-list',
    });
  }

  return links;
};
