import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useApi } from 'src/hoc/useApi';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { LocationState } from 'src/pages/receiving-method/ReceivingMethodRouter';
import { getOrgId } from 'src/redux/user/selectors';
import { BusinessVerificationStatus } from 'src/utils/consts';

export const MsnVerificationStatusCheck = () => {
  const { state: locationState } = useLocation<LocationState>();
  const orgId = useSelector(getOrgId);
  const { navigate } = useNavigator();

  const { onApiCall: fetchBusinessVerificationStatus, result: businessVerificationStatus } = useApi<
    [{ orgId: number }],
    { status: BusinessVerificationStatus }
  >({
    api: msnPortalApi.getMSNVerificationStatus,
  });

  useEffect(() => {
    fetchBusinessVerificationStatus({ orgId });
  }, [orgId, fetchBusinessVerificationStatus]);

  useEffect(() => {
    if (businessVerificationStatus === null) return;

    let redirectRoute;

    switch (businessVerificationStatus?.status) {
      case BusinessVerificationStatus.PENDING_TO_CREATE:
        redirectRoute = generatePath(onboardingLocations.msnRegistration.deliveryMethodStatus, { orgId });
        break;
      case BusinessVerificationStatus.VERIFIED:
        redirectRoute = generatePath(onboardingLocations.msnRegistration.companyVerified, { orgId });
        break;
      case BusinessVerificationStatus.REJECTED:
      case BusinessVerificationStatus.OPT_OUT:
        redirectRoute = generatePath(onboardingLocations.msnRegistration.companyRejected, { orgId });
        break;
      case BusinessVerificationStatus.NOT_REGISTERED:
      default:
        redirectRoute = generatePath(onboardingLocations.msnRegistration.contactDetails, { orgId });
    }

    navigate(redirectRoute, true, { exitUrl: locationState?.exitUrl });
  }, [businessVerificationStatus, navigate, orgId, locationState?.exitUrl]);

  return <AreaLoader />;
};
