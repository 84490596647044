import { MouseEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'src/components/common/Amount/Input';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import { analytics } from 'src/services/analytics';
import { convertNumberToCurrency } from 'src/utils/currency-utils';
import { DisabledTooltip, RegularTooltip } from './Tooltips';

type Props = {
  partialBillAmount: string;
  editAmount: boolean;
  billBalance: number;
  onChange: (FieldType) => void;
  onBlur: (value: string) => void;
  disabled?: boolean;
};

const PartialPaymentAmount = ({
  partialBillAmount,
  editAmount,
  billBalance,
  onChange,
  onBlur,
  disabled = false,
}: Props) => {
  const inputRef = useRef<Pick<HTMLInputElement, 'focus'>>(null);

  const openBalanceFormattedAsCurrency = convertNumberToCurrency(billBalance);
  const [focus, setFocus] = useState(editAmount);
  const Tooltip = disabled ? DisabledTooltip : RegularTooltip;

  const resetValue = () => {
    const valueForPlaceholderAppearance = '';
    onChange({ value: valueForPlaceholderAppearance });
  };

  const handleFocusEffect = () => {
    resetValue();

    analytics.trackAction('payment-on-focus-amount', {
      amount: partialBillAmount,
    });
  };

  useEffect(() => {
    if (focus) {
      handleFocusEffect();
    }
  }, [focus]);

  const handlePencilClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  const toggleFocus = () => {
    setFocus((focus) => !focus);
  };

  const handleFocus = () => {
    if (disabled || focus) return;

    inputRef?.current?.focus();
  };

  return (
    <PartialAmountWrapper>
      <PartialAmountContainer onClick={handleFocus}>
        <Input
          ref={inputRef}
          totalAmount={partialBillAmount}
          onChange={onChange}
          onBlur={onBlur}
          toggleFocus={toggleFocus}
          autoFocus={editAmount}
          focus={focus}
          maxValue={billBalance}
          viewOnly={disabled}
        />
        {!focus && (
          <Tooltip>
            <PencilContainer>
              <IconButton
                aria-label="action"
                variant="action"
                size="sm"
                onClick={handlePencilClick}
                icon={<Icon name={IconNames.edit} color="black" />}
              />
            </PencilContainer>
          </Tooltip>
        )}
      </PartialAmountContainer>
      <PartialAmountSubtitle>
        <Box textStyle="body4">
          <MIFormattedText
            privateData
            label="bills.pay.fundingSource.partialPayment.subtitle"
            values={{ openBalance: openBalanceFormattedAsCurrency }}
          />
        </Box>
      </PartialAmountSubtitle>
    </PartialAmountWrapper>
  );
};

const PartialAmountSubtitle = styled.div`
  ${(props) => props.theme.text.fontType.regular};
  text-align: center;
  margin: ${(props) => (props.error ? '3rem 0 2rem' : '1rem 0 2rem')};
`;

const PartialAmountContainer = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  max-width: 45rem;
`;

const PencilContainer = styled.div`
  display: flex;
  align-items: center;
  height: 6rem;
  font-size: 2.4rem;
  padding-left: 0.8rem;
  cursor: pointer;
  color: ${(props) => props.theme.text.color.subtitle};
`;

const PartialAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export { PartialPaymentAmount };
