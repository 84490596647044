import { useEffect } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MILink } from 'src/components/common/MILink';
import Box from 'src/core/ds/box';
import { useTimer } from 'src/hooks/useTimer';
import { MfaAuthenticatorType } from 'src/utils/types';
import {
  mapMfaCodeVerificationFooterSecondOptionLabel,
  mapMfaCodeVerificationFooterSecondOptionTestId,
  MfaAuthenticatorMethod,
} from '../consts';

export const RESEND_TIMER = 30;

type Props = {
  authenticator?: MfaAuthenticatorType;
  isResendCodeActionDisabled: boolean;
  isSessionExpired: boolean;
  isMoreThanOneAuthenticator: boolean;
  onPrev: () => void;
  handleResendCodeClick: () => void;
  handleSendToPhoneClick: () => void;
  handleSendToEmailClick: () => void;
  handleContactSupportClick: () => void;
};

export const MfaCodeVerificationLayoutFooter = ({
  authenticator,
  isResendCodeActionDisabled,
  isSessionExpired,
  isMoreThanOneAuthenticator,
  onPrev,
  handleResendCodeClick,
  handleSendToPhoneClick,
  handleSendToEmailClick,
  handleContactSupportClick,
}: Props) => {
  const { secondsLeft, startTimer, setSecondsLeft } = useTimer(RESEND_TIMER);
  const shouldShowOptions = !isSessionExpired;
  const shouldShowSecondOption = isMoreThanOneAuthenticator && secondsLeft === 0;
  const label = isResendCodeActionDisabled
    ? 'guests.mfaVerification.footer.resendAnotherCode'
    : 'guests.mfaVerification.footer.sendCodeAgain';
  const mapSecondOptionAction = {
    [MfaAuthenticatorMethod.SMS]: handleSendToEmailClick,
    [MfaAuthenticatorMethod.EMAIL]: handleSendToPhoneClick,
  };
  const secondOptionAction = authenticator ? mapSecondOptionAction[authenticator?.type] : undefined;
  const secondOptionTestId = authenticator
    ? mapMfaCodeVerificationFooterSecondOptionTestId[authenticator?.type]
    : undefined;
  const secondOptionLabel = authenticator
    ? mapMfaCodeVerificationFooterSecondOptionLabel[authenticator?.type]
    : undefined;

  useEffect(() => {
    setSecondsLeft(0);
  }, [setSecondsLeft]);

  return (
    <Box my={4} textStyle="body2">
      {shouldShowOptions ? (
        <Box mb={2}>
          <MIFormattedText
            label={label}
            values={{
              resendCode: (
                <Box data-testid="resend-code-link" as="span" onClick={startTimer}>
                  <Link
                    disabled={secondsLeft > 0}
                    to={handleResendCodeClick}
                    label="guests.mfaVerification.footer.resendCode"
                  />
                </Box>
              ),
              or: shouldShowSecondOption ? <MIFormattedText label="guests.mfaVerification.footer.or" /> : null,
              sendTo: shouldShowSecondOption ? (
                <Box
                  data-testid={secondOptionTestId}
                  as="a"
                  cursor="pointer"
                  onClick={secondOptionAction}
                  textStyle="link1"
                  _hover={{ textDecoration: 'none', color: 'primary.600' }}
                  _active={{ color: 'primary.600' }}
                >
                  <MIFormattedText label={secondOptionLabel} />
                </Box>
              ) : null,
              counter: secondsLeft > 0 ? `in ${secondsLeft} seconds` : null,
              startOver: (
                <Box data-testid="start-over-link" as="span">
                  <Link to={onPrev} label="guests.mfaVerification.footer.startOver" />
                </Box>
              ),
            }}
          />
        </Box>
      ) : null}

      <MIFormattedText
        label="guests.mfaVerification.footer.contactSupport"
        values={{
          chatWithUs: (
            <Box
              data-testid="support-link"
              as="a"
              cursor="pointer"
              onClick={handleContactSupportClick}
              textStyle="link1"
              _hover={{ textDecoration: 'none', color: 'primary.600' }}
              _active={{ color: 'primary.600' }}
            >
              <MIFormattedText label="guests.mfaVerification.footer.chatWithUs" />
            </Box>
          ),
        }}
      />
    </Box>
  );
};

const Link = styled(MILink)`
  color: ${(props) => (props.disabled ? props.theme.colors.primary.grey : props.theme.text.color.highlight)};
  font-weight: ${(props) => props.theme.text.weight.regular};
  font-size: ${(props) => props.theme.text.size.regular};
  text-decoration: none;
  &:hover {
    color: ${(props) => (props.disabled ? 'rgba(33, 33, 36, 0.3)' : 'rgba(92, 53, 203, 1)')};
  }
`;
