import React from 'react';
import { DeliveryDateFormat } from 'src/components/common/DeliveryDateFormat';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { DeliveryOptionType } from 'src/utils/types';

type Props = {
  subtitle: string;
  subtitleValues: Record<string, React.ReactNode>;
  deliveryOption: DeliveryOptionType;
  deliveryExplanationLabel: string;
  isEligibleForFastAchAdoption?: boolean;
  isFastAchBetterExposeEtaEnabled?: boolean;
};

export const PaymentDeliveryOptionTitle = ({
  subtitle,
  subtitleValues,
  deliveryOption,
  deliveryExplanationLabel,
  isEligibleForFastAchAdoption,
  isFastAchBetterExposeEtaEnabled,
}: Props) => {
  const betterExposeEtaDeliveryOptionTitle = () => (
    <Box textStyle="body2" lineHeight={1.1} display="inline-block">
      <MIFormattedText label={subtitle} values={subtitleValues} />
    </Box>
  );

  if (isEligibleForFastAchAdoption) {
    if (isFastAchBetterExposeEtaEnabled) {
      return betterExposeEtaDeliveryOptionTitle();
    }

    return (
      <MIFormattedText
        label={deliveryExplanationLabel}
        values={{
          date: <DeliveryDateFormat date={deliveryOption.deliveryDate} maxDate={deliveryOption.maxDeliveryDate} />,
        }}
      />
    );
  }

  if (isFastAchBetterExposeEtaEnabled) {
    return betterExposeEtaDeliveryOptionTitle();
  }

  return <MIFormattedText label={subtitle} values={subtitleValues} />;
};
