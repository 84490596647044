import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

export const ConnectWithPlaidInfo = () => {
  const PlaidConnectionStep = ({ step }: { step: 'first' | 'second' | 'third' }) => (
    <Flex textStyle="body3" alignItems="center" gap={2}>
      <Icon size={IconSize.s} name={IconNames.checkCircle} />
      <MIFormattedText label={`onboarding.vendorCompanyInfo.link.connectWithPlaidModal.steps.${step}`} />
    </Flex>
  );

  return (
    <Flex width="100%" flexDirection="column" gap={4} mb={10}>
      <PlaidConnectionStep step="first" />
      <PlaidConnectionStep step="second" />
      <PlaidConnectionStep step="third" />
    </Flex>
  );
};
