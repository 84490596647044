import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { ContactSupportLink } from 'src/components/common/ContactSupportLink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useBusinessDetailsBoxInForm } from 'src/components/msn/VerifiedVendor/hooks/useBusinessDetailsBoxInForm';
import { VerifiedVendorDetails } from 'src/components/msn/VerifiedVendor/VerifiedVendorDetails';
import { NotificationVariant } from 'src/core/ds/toast';
import { useApi } from 'src/hoc/useApi';
import { VendorDashboardDisplay } from 'src/modules/msn-portal';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { useUpdateOrganizationPreference } from 'src/modules/organizations/hooks/useUpdateOrganizationPreference';
import { profileStore } from 'src/modules/profile/profile-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { checkIfToastIsDisplayedById, pushNotification } from 'src/services/notifications';
import { OrgPreferencesTypeKeys, ScreenMode } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { getMaskedEmail } from '../../utils';

type LocationState = {
  prevUrl: string;
  nextUrl: string;
  isGetPaidOrg: boolean;
};

export const ConfirmBusinessDetails = () => {
  const { state: locationState } = useLocation<LocationState>();
  const companyInfo = useSelector(getCompanyInfo);
  const orgId = useSelector(getOrgId);
  const { navigate } = useNavigator();
  const user = useSelector(profileStore.selectors.profile);
  const { updateOrganizationPreference } = useUpdateOrganizationPreference();
  const { deliveryMethodDefaultValue } = useBusinessDetailsBoxInForm({
    mode: ScreenMode.EDIT,
  });
  const analyticsData = { msnOptinFlow: true, isGuest: user.isGuest, flows: 'public' };

  const errorToastRef = useRef(null);

  const { onApiCall: registerOrganizationToMSN, loading: isLoading } = useApi<[{ orgId: number }], Record<string, any>>(
    {
      api: msnPortalApi.registerOrganizationToMSN,
    }
  );

  const navigateToContactDetailsStep = () => {
    analytics.trackAction('msnPortalConfirmDetails.editDetailsButton', analyticsData);
    navigate(generatePath(onboardingLocations.msnRegistration.contactDetails, { orgId }));
  };

  const vendorDashboardDisplayPreferenceValue = locationState?.isGetPaidOrg
    ? VendorDashboardDisplay.GetPaid
    : VendorDashboardDisplay.MSNPortal;
  const handleNext = async () => {
    try {
      await updateOrganizationPreference({
        key: OrgPreferencesTypeKeys.vendorDashboardDisplay,
        value: vendorDashboardDisplayPreferenceValue,
        orgId,
      });
      await registerOrganizationToMSN({ orgId });
      locationState?.nextUrl
        ? navigate(locationState.nextUrl)
        : navigate(generatePath(onboardingLocations.msnRegistration.deliveryMethodStatus, { orgId }));
    } catch (e) {
      if (!checkIfToastIsDisplayedById(errorToastRef.current)) {
        errorToastRef.current = pushNotification({
          type: NotificationVariant.error,
          msg: 'onboarding.msnCompanyInfo.generalError.text',
          textValues: {
            contactSupport: <ContactSupportLink />,
          },
          autoClose: 10000,
        });
      }

      analytics.trackAction('msnPortalConfirmDetails.registerError', { ...analyticsData, error: e });
      capture(e as Error);
    }
  };

  const handlePrev = () => {
    analytics.trackAction('msnPortalConfirmDetails.prevButton', analyticsData);
    locationState?.prevUrl
      ? navigate(generatePath(locationState.prevUrl, { orgId }))
      : navigate(generatePath(onboardingLocations.msnRegistration.selectBusinessType, { orgId }));
  };

  return (
    <StepLayoutPage
      title="onboarding.msnCompanyInfo.confirmDetails.title"
      subtitle="onboarding.msnCompanyInfo.confirmDetails.subtitle"
      onNext={handleNext}
      onPrev={handlePrev}
      relativeStep={6 / 6}
      isLoading={isLoading}
      nextButtonAnalyticsProperties={analyticsData}
    >
      <VerifiedVendorDetails
        onClick={navigateToContactDetailsStep}
        buttonLabel="onboarding.msnCompanyInfo.confirmDetails.editButton"
        businessName={companyInfo.companyName as string}
        businessAddress={companyInfo.formattedAddress as string}
        businessContactInfo={{
          fullName: `${companyInfo.contactFirstName} ${companyInfo.contactLastName}`,
          email: getMaskedEmail(user.email),
          phone: companyInfo.phone,
          deliveryMethodLabel: deliveryMethodDefaultValue,
        }}
      />
    </StepLayoutPage>
  );
};
