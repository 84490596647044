export const nonAdminGroups = [
  {
    name: 'billsAndPayments',
    items: [
      'billQbSynced',
      'billDueReminder',
      'paymentByMeScheduled',
      'paymentByMeUpdated',
      'approvalByOtherActions',
      'paymentRequestActions',
      'feeReceipt',
    ],
  },
  {
    name: 'companyAccount',
    items: ['fundingSourceActions', 'invitationAccepted'],
  },
  {
    name: 'vendors',
    items: ['deliveryMethodUpdated'],
  },
];

export const adminGroups = [
  {
    name: 'billsAndPayments',
    items: [
      'billQbSynced',
      'billDueReminder',
      'paymentByMeScheduled',
      'paymentByOtherScheduled',
      'paymentByMeUpdated',
      'approvalByMeRequired',
      'approvalByOtherActions',
      'paymentRequestActions',
      'feeReceipt',
    ],
  },
  {
    name: 'companyAccount',
    items: ['fundingSourceActions', 'invitationAccepted'],
  },
  {
    name: 'vendors',
    items: ['deliveryMethodUpdated'],
  },
];
