import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { convertToGoogleAddress, convertToServerAddress } from 'src/utils/address';
import { GoogleCombinedAddressType } from 'src/utils/types';
import VendorCompanyAddressForm from './components/VendorCompanyAddressForm';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyAddressPageContainer = ({
  companyInfoField,
  nextStepURL,
  prevStepURL,
  inputFields,
}: Props) => {
  const companyInfo = useSelector(getCompanyInfo);
  const [address, setAddress] = useState(convertToGoogleAddress(companyInfo));
  const vendorCompanyInfoUpdates = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
  });

  const { isLoading, validationErrors, onNext, onPrev } = vendorCompanyInfoUpdates;
  const { navigate } = useNavigator();
  const addressPageOnNext = () => {
    const { companyInfo, companyAddress } = generateServerAddress();

    if (companyAddress) {
      onNext(companyInfo, companyAddress);
    }
  };

  const addressPageOnPrev = () => {
    const { companyInfo } = generateServerAddress();

    if (companyInfo) {
      onPrev(companyInfo);
    }
  };

  const onChange = (address: GoogleCombinedAddressType) => {
    setAddress(address);
  };

  const handleGoToManualAddress = () => {
    navigate(onboardingLocations.vendorCompanyInfo.addressManual);
  };

  const generateServerAddress = () => {
    if (address) {
      const companyAddress = convertToServerAddress(address);

      return {
        companyInfo: {
          ...companyInfo,
          ...companyAddress,
        },
        companyAddress,
      };
    }

    return { companyInfo, companyAddress: null };
  };

  return (
    <VendorCompanyAddressForm
      onNext={addressPageOnNext}
      onPrev={addressPageOnPrev}
      onChange={onChange}
      address={address}
      isLoading={isLoading}
      validationErrors={validationErrors}
      goToManualAddress={handleGoToManualAddress}
    />
  );
};
