import { Option } from 'src/components/common/MISingleSelect';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { HStack } from 'src/core/ds/stack';
import { convertToDisplayAddress } from 'src/utils/address';

type Props = {
  option: Option;
  isSelected: boolean;
};

export const NetworkVendorOptionLabel = ({ option, isSelected }: Props) =>
  isSelected ? (
    <HStack align="center" spacing={2}>
      <Box isTruncated>{option.label}</Box>
      <Icon
        name={IconNames.verifiedSolid}
        pl={4}
        ml="auto"
        flexShrink={0}
        color="primary.500"
        size={IconSize.lg}
        data-testid="directory-vendor-sign"
      />
    </HStack>
  ) : (
    <Flex pr={4} pl={4} align="center" h="5.6rem" data-testid={`vendor-network-option-${option.directoryId}`}>
      <Flex direction="column" overflow="hidden">
        <Box isTruncated textStyle="body3">
          {option.label}
        </Box>
        <Box pt={0.5} isTruncated textStyle="caption1" color="grey.600">
          {convertToDisplayAddress(option.address)}
        </Box>
      </Flex>
    </Flex>
  );
