import { useApi } from 'src/hoc/useApi';
import { ApplyForEligibilityApplicationData, financingApi } from 'src/modules/financing/api/financing';
import { BusinessApplicationContextType } from 'src/pages/bill/pay/installments/context/business-context/useBusinessApplicationContext';
import { BusinessType } from 'src/pages/bill/pay/installments/pages/application-details/business-details/types';
import { BusinessOwnershipDetailsModel } from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { ApplicationResponse } from 'src/pages/bill/pay/installments/types';
import { convertPhoneToRequestFormat } from 'src/pages/bill/pay/installments/utils';

const convertBusinessAndOwnershipDetailsToRequestSchema = (
  business: NonNullable<BusinessApplicationContextType['business']>,
  { owner, beneficialOwners }: BusinessOwnershipDetailsModel
): ApplyForEligibilityApplicationData => ({
  business: {
    name: business.name,
    legalName: business.legalName,
    legalAddressLine1: business.legalAddressLine1,
    legalCity: business.legalCity,
    legalState: business.legalState,
    legalZipCode: business.legalZipCode,
    totalAnnualRevenue: business.totalAnnualRevenue,
    type: business.type,
    phone: convertPhoneToRequestFormat(business.phone),
    taxId: business.type !== BusinessType.SoleProprietorship ? business.taxId : owner.ssn,
    industry: business.industry,
  },
  owner: {
    firstName: owner.firstName,
    lastName: owner.lastName,
    email: owner.email,
    dateOfBirth: owner.dateOfBirth,
    phone: convertPhoneToRequestFormat(owner.phone),
    addressLine1: owner.addressLine1,
    city: owner.city,
    state: owner.state,
    zipCode: owner.zipCode,
    ssn: owner.ssn,
    ownershipPercentage: owner.ownershipPercentage,
  },
  beneficialOwners: beneficialOwners.map((beneficialOwner) => ({
    firstName: beneficialOwner.firstName,
    lastName: beneficialOwner.lastName,
    email: beneficialOwner.email,
    ownershipPercentage: beneficialOwner.ownershipPercentage,
    dateOfBirth: beneficialOwner.dateOfBirth,
    ssn: beneficialOwner.ssn,
  })),
});

export const useApplyForEligibility = () => {
  const { onApiCall, result, loading, error } = useApi<
    Parameters<typeof financingApi.applyForEligibility>,
    ApplicationResponse
  >({
    api: financingApi.applyForEligibility,
  });

  const applyForEligibility = (
    orgId: number,
    business: NonNullable<BusinessApplicationContextType['business']>,
    businessOwnershipDetailsModel: BusinessOwnershipDetailsModel
  ) => {
    const applicationData = convertBusinessAndOwnershipDetailsToRequestSchema(business, businessOwnershipDetailsModel);

    return onApiCall({ orgId, ...applicationData });
  };

  return {
    applyForEligibility,
    result,
    loading,
    error,
  };
};
