import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { ComingSoonPage } from './components/ComingSoonPage';

const eventPage = 'link-accounting-software-coming-soon';

type Props = {
  type: string;
};

const ComingSoonPageContainer = ({ type }: Props) => {
  const { navigate } = useNavigator();
  const onSubscribe = () => {
    analytics.track(eventPage, 'notify-me', { type });
  };

  const onDone = () => {
    analytics.track(eventPage, 'cancel', { type });
    navigate(onboardingLocations.accountingSoftware.index, false, {
      locationState: {
        origin: 'welcome',
      },
    });
  };

  return <ComingSoonPage onSubscribe={onSubscribe} onDone={onDone} />;
};

ComingSoonPageContainer.defaultProps = {};

export default ComingSoonPageContainer;
