import Flex from 'src/core/ds/flex';

export const ActiveIndicatorOverlay: React.FC = ({ children }) => (
  <Flex
    position="absolute"
    right="-px"
    top="0px"
    backgroundColor="grey.200"
    width="3"
    height="3"
    justifyContent="center"
    alignItems="center"
    borderRadius="full"
  >
    {children}
  </Flex>
);
