import { useEffect, useRef, useState } from 'react';

export const useTimer = (seconds: number) => {
  const [secondsLeft, setSecondsLeft] = useState(seconds);
  const timeoutRef = useRef<number>();

  useEffect(() => {
    startTimer();

    return () => {
      clearInterval(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (secondsLeft === 0) {
      clearInterval(timeoutRef.current);
    }
  }, [secondsLeft]);

  const startTimer = () => {
    setSecondsLeft(seconds);
    timeoutRef.current = setInterval(() => {
      setSecondsLeft((currentCounter) => currentCounter - 1);
    }, 1000);
  };

  return {
    startTimer,
    secondsLeft,
    setSecondsLeft,
  };
};
