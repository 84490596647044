import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import partition from 'lodash/partition';
import pick from 'lodash/pick';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import { PayListItemType } from 'src/modules/bills/types';
import { BatchBillType, BatchItemType } from 'src/modules/regular-batch-payments/types';
import { billFactory } from 'src/pages/bill/records';
import { REGULAR_BATCH_PAYMENTS_SUPPORTED_BILL_TAGS } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { MainTableRowType } from 'src/pages/regular-batch-payments/components/table/types';
import { blockBatchSelectionForInternational } from 'src/pages/vendor/international-delivery-method/utils';
import { canPartiallyPayItem } from 'src/pay/utils';
import {
  deserializePaymentId,
  getBillTag,
  isBillHasPartialPayments,
  isPaymentRequest,
  isScannedInvoice,
} from 'src/utils/bills';
import {
  DeliveryType,
  FeatureFlags,
  FundingType,
  PaymentApprovalStatus,
  RISK_MAX_PAYMENT_AMOUNT,
} from 'src/utils/consts';
import { hasVendorCheckDeliveryMethod, removeUnsupportedDeliveryOptions } from 'src/utils/delivery-methods';
import { isCreditAmexNetwork } from 'src/utils/funding-sources';
import { getLatestPayment } from 'src/utils/payments';
import { AccountType, BillType, CompanyInfoType, VendorType } from 'src/utils/types';
import { shouldCollectAmexAddressForVendor } from 'src/utils/vendors';

export const getSubRowsFromBatchItem = (batchItem: BatchItemType): BatchBillType[] =>
  batchItem.payment.bills && batchItem.payment.bills.length > 1 ? batchItem.payment?.bills : [];

type MapBatchBillItemToDataTableRowTypeParams = {
  bills: Record<string, BillType>;
  fundingSources: readonly AccountType[];
  selectedVendors: readonly VendorType[];
  isFastCheckEnabledForAllStates: boolean;
  isAmexAddressFeatureEnabled: boolean;
  site: any;
  companyInfo: CompanyInfoType;
};

export const mapBatchBillItemToDataTableRowType = ({
  bills,
  fundingSources,
  selectedVendors,
  isFastCheckEnabledForAllStates,
  isAmexAddressFeatureEnabled,
  site,
  companyInfo,
}: MapBatchBillItemToDataTableRowTypeParams) => (item: BatchItemType): MainTableRowType => {
  const payments = bills[item.id]?.payments || [];
  const deliveryMethods = bills[item.id]?.vendor?.deliveryMethods || [];
  const vendor = selectedVendors.find((vendor) => (vendor?.id ?? undefined) === item.payment.vendorId);
  const bill = billFactory(item);
  bill.vendor!.deliveryMethods = deliveryMethods;
  const selectedDeliveryMethod = deliveryMethods.find((dm) => dm.id === item.payment.deliveryMethod?.id);
  const selectedFundingSource = fundingSources.find((fs) => fs.id === item.payment.fundingSourceId);

  const possibleDeliveryOptions = removeUnsupportedDeliveryOptions({
    site,
    deliveryOptions: item.deliveryOptions || [],
    companyInfo,
    fundingSource: selectedFundingSource,
    deliveryMethod: selectedDeliveryMethod || item.payment.deliveryMethod,
    isFastCheckEnabledForAllStates,
  });
  const isAmexCreditCard = isCreditAmexNetwork(selectedFundingSource);

  const isInternationalReady =
    item.payment.deliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL ? item.payment.purpose : true;

  const isAmexIndustryReady = isAmexCreditCard ? vendor?.mccCode : true;
  const isAmexAddressReady =
    isAmexCreditCard && isAmexAddressFeatureEnabled
      ? hasVendorCheckDeliveryMethod(deliveryMethods) || !shouldCollectAmexAddressForVendor(vendor)
      : true;

  const isBillReady =
    !isNil(item.payment.deliveryMethodId) && !!isInternationalReady && !!isAmexIndustryReady && isAmexAddressReady;

  return {
    id: item.id,
    totalAmount: item.payment.amount || 0,
    dueDate: item.dueDate,
    vendor: {
      id: item.payment?.vendor?.id,
      name: item.payment?.vendor?.companyName || '',
      deliveryMethods,
      isOwnedVendor: !!bills[item.id]?.vendor?.ownedById,
      email: item.payment?.vendor?.contactEmail,
    },
    payment: item.payment,
    // TODO: remove this usage.
    payments,
    deliveryOptions: possibleDeliveryOptions,
    fee: keyBy(item.fee, 'feeType'),
    internationalFee: item.internationalFee,
    minScheduledDate: item.minScheduledDate,
    isBillReady,
    isInvoiceAttachmentRequired: item.isInvoiceAttachmentRequired,
    payBillFlowUUID: item.payBillFlowUUID,
    isLoading: item.loading,
    subRows: getSubRowsFromBatchItem(item),
  };
};

export const isAchToRegularCheck = (
  deliveryType: DeliveryType | undefined,
  deliveryPreference: string | undefined,
  fundingType?: FundingType | null
) =>
  fundingType === FundingType.ACH &&
  deliveryType === DeliveryType.CHECK &&
  (!deliveryPreference || deliveryPreference === 'check');

export const batchBillItemToAchToCheckFee = (
  availableFreeChecks = 0
): (({
  item,
  fundingSources,
  companyInfo,
}: {
  item: BatchItemType;
  fundingSources: readonly AccountType[];
  companyInfo: CompanyInfoType;
}) => BatchItemType) => {
  let available = availableFreeChecks;

  return ({
    item,
    fundingSources,
    companyInfo,
  }: {
    item: BatchItemType;
    fundingSources: readonly AccountType[];
    companyInfo: CompanyInfoType;
  }) => {
    const fundingType = fundingSources.find((fs) => fs.id === item.payment?.fundingSourceId)?.fundingType;
    const isAchToCheckFeeBill = isAchToRegularCheck(
      item.payment?.deliveryMethod?.deliveryType,
      item.payment?.deliveryPreference,
      fundingType
    );

    if (!isAchToCheckFeeBill) return item;

    available -= 1;

    if (available < 0) {
      return {
        ...item,
        fee: [
          {
            amount: companyInfo.billingSetting.fee['ach-to-check']?.value,
            feeType: 'ach-to-check',
            isFixed: true,
          },
        ],
      };
    }

    return item;
  };
};

export const getEligibleToBatchPaymentsBills = <T extends BillType | PayListItemType>(
  bills: T[],
  vendors: VendorType[],
  fundingSources: readonly AccountType[],
  featureFlags: FeatureFlagsStatus
): T[] =>
  [...bills].filter((bill) => {
    const tag = getBillTag(bill);

    const { paymentId } = deserializePaymentId(bill.id);
    const vendorWithDeliveryMethods = vendors?.find((vendor: VendorType) => Number(vendor?.id) === bill.vendorId);

    if (blockBatchSelectionForInternational(vendorWithDeliveryMethods?.deliveryMethods ?? [], fundingSources))
      return false;

    const availableToPartiallyPay = canPartiallyPayItem(bill as PayListItemType, false, !!paymentId);

    if (isBillHasPartialPayments(bill) && availableToPartiallyPay) return true;

    if (isPaymentRequest(bill.id)) return true;

    if (isScannedInvoice(bill.id)) return false;

    if (featureFlags[FeatureFlags.BatchApprove]) {
      if (tag === PaymentApprovalStatus.PENDING) return true;
    }

    return REGULAR_BATCH_PAYMENTS_SUPPORTED_BILL_TAGS.some((t) => t === tag);
  });

export type FeatureFlagsStatus = {
  [key in FeatureFlags.BatchApprove]?: boolean;
};

type GetSelectableItemsArgs = {
  items: PayListItemType[];
  userVendors;
  userFundingSources;
  featureFlags: FeatureFlagsStatus;
  sampleSelectedItem;
};

function canItemsBeSelectedTogether(item, sampleItem) {
  if (!sampleItem) return true;

  const typesThatCanBeSelectedTogether = ['bill', 'paymentRequest'];

  const sampleItemPayment = getLatestPayment(sampleItem.payments);
  const currentItemPayment = getLatestPayment(item.payments);

  if (item.type === sampleItem.type) {
    return sampleItem.type === 'payment'
      ? sampleItemPayment?.approvalDecisionStatus === currentItemPayment?.approvalDecisionStatus
      : true;
  }

  return [sampleItem.type, item.type].every((type) => typesThatCanBeSelectedTogether.includes(type));
}

export function getSelectableListItems({
  items,
  userVendors,
  userFundingSources,
  featureFlags,
  sampleSelectedItem,
}: GetSelectableItemsArgs) {
  const eligibleToBatchPaymentsBills = getEligibleToBatchPaymentsBills(
    items,
    userVendors,
    userFundingSources,
    featureFlags
  );

  if (featureFlags[FeatureFlags.BatchApprove]) {
    return eligibleToBatchPaymentsBills.filter((bill) => canItemsBeSelectedTogether(bill, sampleSelectedItem));
  }

  return eligibleToBatchPaymentsBills;
}

export const canGroupBatchPaymentsList = (batchPaymentList: MainTableRowType[]) => {
  const listWithoutPaymentRequests = batchPaymentList.filter(
    (batchItem) => !batchItem.payment.bills?.[0]?.isPaymentRequest
  );
  const paymentsGroupByVendor = values(groupBy(map(listWithoutPaymentRequests, 'payment'), 'vendorId'));
  const noMultipleBillsGroups = paymentsGroupByVendor.every((paymentsPerVendor) => paymentsPerVendor.length === 1);

  return !noMultipleBillsGroups;
};

export const BatchPaymentListHasNoSubRows = (batchPaymentsList: MainTableRowType[]) =>
  batchPaymentsList.every((batchRow) => batchRow.subRows?.length === 0);

export const isBatchPaymentRiskTotalAmountValid = (
  batchPaymentList: MainTableRowType[],
  bulkPaymentToggleOn: boolean
) => {
  const listWithoutPaymentRequests = batchPaymentList.filter(
    (batchItem) => !batchItem.payment.bills?.[0]?.isPaymentRequest
  );

  const paymentsGroupByVendor = values(
    groupBy(
      map(listWithoutPaymentRequests, (group) => pick(group, ['payment', 'subRows'])),
      'payment.vendorId'
    )
  ).filter((group) => (bulkPaymentToggleOn ? groupHasSubRowsFilter(group) : true));

  const [validBillsGroups, invalidBillsGroups] = partition(
    paymentsGroupByVendor,
    (group) => sumBy(group, 'payment.amount') < RISK_MAX_PAYMENT_AMOUNT
  );

  return (
    validBillsGroups.some((group) => (!bulkPaymentToggleOn ? group.length > 1 : group.length > 0)) ||
    !invalidBillsGroups.some((group) => group.length > 0)
  );
};

const groupHasSubRowsFilter = (group: Pick<MainTableRowType, 'payment' | 'subRows'>[]): boolean => {
  const hasGroupSubRows = !!group?.[0]?.subRows?.length;

  return group.length > 1 || hasGroupSubRows;
};
