import { createRef, useEffect, useState } from 'react';

export function useRefsArray<T>(length: number) {
  const [refs, setRefs] = useState<React.RefObject<T>[]>([]);

  useEffect(() => {
    setRefs((refs) =>
      Array(length)
        .fill(1)
        .map((_, i) => refs[i] || createRef())
    );
  }, [length]);

  return refs;
}
