const contactCreateLocations = {
  base: ['/orgs/:orgId/contacts/:type/new', '/orgs/:orgId/contacts/new'],
  type: '/orgs/:orgId/contacts/new',
  options: '/orgs/:orgId/contacts/:type/new/create-options',
  single: '/orgs/:orgId/contacts/:type/new/single',
  customersSingle: '/orgs/:orgId/contacts/customers/new/single',
  vendorsSingle: '/orgs/:orgId/contacts/vendors/new/single',
  vendorsSingleWithVendorName: '/orgs/:orgId/contacts/vendors/new/single?vendorName=:vendorName',
  deliveryMethods: '/orgs/:orgId/contacts/vendors/new/:id/delivery-method',
  complete: '/orgs/:orgId/contacts/:type/new/complete',
  batchUploadCustomersExplain: '/orgs/:orgId/contacts/customers/new/batch-csv-explain',
  batchUploadCustomersReview: '/orgs/:orgId/contacts/customers/new/batch-upload',
  batchUploadVendorsExplain: '/orgs/:orgId/contacts/vendors/new/batch-csv-explain',
  batchUploadVendorsReview: '/orgs/:orgId/contacts/vendors/new/batch-upload',
  batchUploadVendorsSuccess: '/orgs/:orgId/contacts/vendors/new/batch-success',
};

export default contactCreateLocations;
