import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MILink } from 'src/components/common/MILink';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import { CardTypes, FundingType } from 'src/utils/consts';

type Props = {
  onPrev?: () => void;
  onExit?: () => void;
  exitUrl: string;
  redirectUrl: string;
  preservedState?: any;
  relativeStep?: number;
};

const eventPage = 'add-funding-source';

const AddFundingSource: React.FC<Props> = ({
  redirectUrl,
  exitUrl,
  preservedState,
  onPrev,
  onExit,
  relativeStep,
}: Props) => {
  const { navigate } = useNavigator();

  const organization = useSelector(profileStore.selectors.getCurrentOrg);
  const userFundingSources = useSelector(paymentsStore.selectors.getUserFundingSources);

  const goAddSelectedFundingSource = (selectedFundingSourceType) => {
    const fundingSourcesTypesMap = {
      [FundingType.ACH]: {
        url: onboardingLocations.fundingSources.bank.select,
        analyticProperty: 'add-bank-account',
      },
      [CardTypes.CREDIT]: {
        url: onboardingLocations.fundingSources.card.index,
        analyticProperty: 'add-credit-card',
      },
      [CardTypes.DEBIT]: {
        url: onboardingLocations.fundingSources.card.index,
        analyticProperty: 'add-debit-card',
      },
    };

    const { analyticProperty, url } = fundingSourcesTypesMap[selectedFundingSourceType];

    if (analyticProperty && url) {
      analytics.track(eventPage, analyticProperty);
      navigate(url, false, {
        redirectUrl,
        exitUrl,
        preservedState,
      });
    }
  };

  const companyName = organization?.companyName;

  return (
    <StepLayoutPage
      title="auth.register.addFundingSource.title"
      titleValues={{ companyName: <Title>{companyName}</Title> }}
      relativeStep={relativeStep || 2 / 3}
      subtitle="auth.register.addFundingSource.subtitle"
      onPrev={onPrev}
      goExit={onExit}
      subTitleValues={{ companyName }}
    >
      <FundingSourcesListLayout
        fundingSources={userFundingSources}
        onChange={goAddSelectedFundingSource}
        onAddMethod={goAddSelectedFundingSource}
      />
      <MISecurityDetails eventPage="register-add-funding-source" showIconCC />
      <StyledWizardLinkContainer>
        <MILink label="auth.register.addFundingSource.skip" preserveState={preservedState} to={redirectUrl} />
      </StyledWizardLinkContainer>
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
  width: 90%;
`;

const StyledWizardLinkContainer = styled(WizardElements.WizardLinkContainer)`
  margin-top: 4.6rem;
`;

export default AddFundingSource;
