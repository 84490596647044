import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalTitle } from 'src/components/common/ModalMessage';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';

export const DeleteAdminModalMessageTitle = () => (
  <ModalTitle>
    <Flex direction="column">
      <Flex justifyContent="center" marginBlockEnd={10}>
        <Icon name={IconNames.alertFill} color="red.500" size={IconSize['2xl']} />
      </Flex>
      <MIFormattedText label="user.deleteAdminModal.title" />
    </Flex>
  </ModalTitle>
);
