import { getValidationErrors } from '@melio/sizzers-js-common';
import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { updateSliceReducer } from 'src/helpers/redux/restUpdateSlice';
import clientServiceApi from 'src/services/api/clientService';
import { createInternationalDeliveryMethod } from 'src/services/api/international';
import { DeliveryType } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { deliveryMethodsApi, unilateralPaymentsApi } from './api';

type State = {
  byId: { [key: string]: any };
  validation: {
    invalidTokenData: any;
    deliveryMethodId?: string;
    loading: boolean;
    error: Error | null;
  };
};

const DeliveryMethodInitialState = {
  byId: {},
  validation: {
    invalidTokenData: null,
    loading: false,
    error: null,
    deliveryMethodId: '',
  },
};

const replaceVirtualDeliveryMethodSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] REPLACE VIRTUAL METHOD',
  api: unilateralPaymentsApi.createDeliveryMethod,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.byId[payload.id] = payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error.code ? action.error : null;
    },
  },
  async validate({ deliveryMethod }) {
    const res = getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const fetchUnilateralRequestDetailsWithTokenSlice = createApiCallSlice({
  name: '[DELIVERYMETHODS] FETCH_REQUEST_DETAILS',
  api: unilateralPaymentsApi.getRequestDataWithToken,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error.code ? action.error : null;
    },
  },
});

const whitePagesAddressSlice = createApiCallSlice<any, any>({
  name: '[DELIVERYMETHODS] ADDRESS VALIDATION',
  api: clientServiceApi.getAddressValidation,
  initialState: {
    meta: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.meta[action.payload.deliveryMethodId] = {
        loading: true,
        whitePageAddress: {},
      };
    },
    [ON_SUCCESS]: (state, action) => {
      const { deliveryMethodId } = action.meta.identifier;
      state.meta[deliveryMethodId] = {
        loading: false,
        whitePageAddress: action.payload,
      };
    },
    [ON_FAILURE]: (state, action) => {
      state.meta[action.payload.deliveryMethodId] = {
        loading: false,
        whitePageAddress: {},
      };
    },
  },
  async validate(data) {
    const deliveryMethod: Partial<DeliveryMethodType> = {
      deliveryType: data.deliveryType,
    };

    if (data.deliveryType === DeliveryType.CHECK) {
      deliveryMethod.paperCheck = data;
    } else {
      deliveryMethod.cardAccount = data;
    }

    const res = getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const clearWhitePageAddressSlice = createSlice({
  name: '[DELIVERYMETHODS] CLEAR_ADDRESS',
  initialState: {
    meta: {},
  },
  reducers: {
    clear(state, { payload }) {
      state.meta[payload] = { loading: false, whitePageAddress: {} };
    },
  },
});

type CopyFromOwnedVendorState = {
  byId: { [key: string]: any };
  copyWithUnilateralToken: { [key: string]: any };
};

const copyFromOwnedVendorWithUnilateralToken = createApiCallSlice<any, CopyFromOwnedVendorState>({
  name: '[DELIVERYMETHODS] COPY_WITH_UNILATERAL_TOKEN',
  api: unilateralPaymentsApi.copyFromOwnedVendor,
  initialState: {
    byId: {},
    copyWithUnilateralToken: {},
  },
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.copyWithUnilateralToken[action.payload.deliveryMethodId] = {
        loading: true,
      };
    },
    [ON_SUCCESS]: (state, action) => {
      delete state.copyWithUnilateralToken[action.meta.identifier.deliveryMethodId];
      state.byId[action.payload.id] = action.payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.copyWithUnilateralToken[action.payload.deliveryMethodId] = {
        loading: false,
        error: action.error,
      };
    },
  },
  selectors: {
    status: (id) => (state) => state.deliveryMethods.copyWithUnilateralToken?.[id],
  },
});

const deliveryMethodSliceReducer = {
  [ON_REQUEST]: (state) => {
    state.validation.loading = true;
    state.validation.error = null;
  },
  [ON_SUCCESS]: (state, { payload }) => {
    state.validation.loading = false;
    state.validation.error = null;
    state.validation.deliveryMethodId = payload.deliveryMethod.id;
    state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
  },
  [ON_FAILURE]: (state, action) => {
    state.validation.loading = false;
    state.validation.invalidTokenData = action.error;
  },
};

const updateDeliveryMethodACHSlice = createApiCallSlice({
  name: '[DELIVERYMETHODS] UPDATE_ACH_METHOD',
  api: deliveryMethodsApi.updateDeliveryMethodACHWithToken,
  reducers: deliveryMethodSliceReducer,
  async validate({ deliveryMethod }) {
    const res = getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const replaceDeliveryMethodSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] REPLACE_METHOD',
  api: deliveryMethodsApi.replaceDeliveryMethodWithToken,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.validation.deliveryMethodId = payload.deliveryMethod.id;
      state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error;
    },
  },
  async validate({ deliveryMethod }) {
    const res = getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const copyFromOwnedVendorSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] COPY_FORM_OWNED_VENDOR',
  api: deliveryMethodsApi.copyFromOwnedVendor,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.validation.deliveryMethodId = payload.deliveryMethod.id;
      state.byId[payload.deliveryMethod.id] = payload.deliveryMethod;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error;
    },
  },
});

const updateWithUnilateralToken = createApiCallSlice({
  name: '[DELIVERYMETHODS] UPDATE_WITH_UNILATERAL_TOKEN',
  api: unilateralPaymentsApi.updateDeliveryMethod,
  reducers: updateSliceReducer(),
  async validate({ deliveryMethod }) {
    if (deliveryMethod?.deliveryType === 'virtual-card') {
      return null;
    }

    const res = getValidationErrors('deliveryMethod', deliveryMethod);

    return isEmpty(res) ? null : res;
  },
});

const verifyMicroDepositsForDeliveryMethodSlice = createApiCallSlice({
  name: '[DELIVERYMETHODS] VERIFY_MICRO_DEPOSITS',
  api: deliveryMethodsApi.verifyMicroDeposits,
  initialState: {
    isDeliveryMethodBeingVerifying: false,
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.isDeliveryMethodBeingVerifying = true;
    },
    [ON_SUCCESS]: (state) => {
      state.isDeliveryMethodBeingVerifying = false;
    },
    [ON_FAILURE]: (state) => {
      state.isDeliveryMethodBeingVerifying = false;
    },
  },
});

const initialState = {
  shouldCloseVerifyIndicator: false,
};

const shouldCloseVerifyDeliveryMethodIndicator = () => {
  const slice = createSlice({
    name: '[DELIVERYMETHODS] SHOULD_CLOSE_DELIVERY_METHOD_INDICATOR',
    initialState,
    reducers: {
      update: (state) => {
        state.shouldCloseVerifyIndicator = true;
      },
    },
  });

  return {
    ...slice,
    initialState,
    selectors: {
      shouldCloseVerifyIndicator: (state) => state.deliveryMethods?.shouldCloseVerifyIndicator,
    },
    dispatchers: {
      update: (dispatch) => () => dispatch(slice.actions.update()),
    },
  };
};

/**
 * @deprecated
 */
const addCardAccountWithToken = createApiCallSlice({
  name: '[DELIVERYMETHODS] ADD_CARD_ACCOUNT',
  api: deliveryMethodsApi.addCardAccountWithToken,
  reducers: deliveryMethodSliceReducer,
});

const createCardAccountWithToken = createApiCallSlice({
  name: '[DELIVERYMETHODS] CREATE_CARD_ACCOUNT',
  api: deliveryMethodsApi.createCardAccountWithToken,
  reducers: deliveryMethodSliceReducer,
});

const shouldCloseVerifyDeliveryMethodIndicatorSlice = shouldCloseVerifyDeliveryMethodIndicator();

const createInternationalDeliveryMethodSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] CREATE_INTERNATIONAL_ACCOUNT',
  api: createInternationalDeliveryMethod,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.error = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.error = null;
      state.validation.deliveryMethodId = payload.id;
      state.byId[payload.id] = payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error;
      state.validation.error = action.error;
    },
  },
});

const shiftVirtualCardToACHPaymentStatusCollectedSlice = createApiCallSlice<any, State>({
  name: '[DELIVERYMETHODS] SHIFT_VIRTUAL_CARD_TO_ACH_PAYMENT_STATUS_COLLECTED',
  api: deliveryMethodsApi.shiftVirtualCardToAch,
  initialState: DeliveryMethodInitialState,
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validation.loading = true;
      state.validation.invalidTokenData = null;
    },
    [ON_SUCCESS]: (state, { payload }) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = null;
      state.byId[payload.id] = payload;
    },
    [ON_FAILURE]: (state, action) => {
      state.validation.loading = false;
      state.validation.invalidTokenData = action.error.code ? action.error : null;
    },
  },
});

export {
  addCardAccountWithToken,
  clearWhitePageAddressSlice,
  copyFromOwnedVendorSlice,
  copyFromOwnedVendorWithUnilateralToken,
  createCardAccountWithToken,
  createInternationalDeliveryMethodSlice,
  fetchUnilateralRequestDetailsWithTokenSlice,
  replaceDeliveryMethodSlice,
  replaceVirtualDeliveryMethodSlice,
  shiftVirtualCardToACHPaymentStatusCollectedSlice,
  shouldCloseVerifyDeliveryMethodIndicatorSlice,
  updateDeliveryMethodACHSlice,
  updateWithUnilateralToken,
  verifyMicroDepositsForDeliveryMethodSlice,
  whitePagesAddressSlice,
};
