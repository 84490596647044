import { ChangeEvent } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormControl, FormControlProps, FormLabel } from 'src/core/ds/form';
import { PasswordInput, PasswordInputProps } from 'src/core/ds/input';
import { ModelViewField } from 'src/ui/form';
import { ErrorMessage, HelperText, OptionalLabel } from './components';

export type Props = FormControlProps &
  PasswordInputProps & {
    id?: string;
    model?: ModelViewField<string>;
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    size?: 'sm' | 'md';
    label?: string;
    labelValues?: Record<string, unknown>;
    helperText?: string;
    helperTextValues?: Record<string, unknown>;
    errorMessage?: string | null;
    errorMessageValues?: Record<string, unknown>;
    optionalLabelText?: string;
  };

export const PasswordField = ({
  id,
  testId,
  model,
  isRequired = false,
  size = 'md',
  helperText,
  helperTextValues,
  label,
  labelValues,
  errorMessage,
  errorMessageValues,
  onChange,
  value,
  shouldShowValue,
  optionalLabelText,
  ...rest
}: Props) => {
  const isError = Boolean(errorMessage) || Boolean(model?.error);
  const inputValue = value || model?.value || '';
  const inputId = id || model?.id;
  const noticesTestId = `input-${inputId}-notices`;
  const errorMessageText = errorMessage || model?.error;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange ? onChange(event) : model?.onChange({ value: event?.target.value });
  };

  return (
    <FormControl isInvalid={isError} size={size} {...rest}>
      <FormLabel htmlFor={inputId}>
        <MIFormattedText label={label} values={labelValues} />
        {!isRequired && <OptionalLabel optionalLabelText={optionalLabelText} isError={isError} />}
      </FormLabel>
      <PasswordInput
        id={inputId}
        testId={testId}
        onChange={handleChange}
        value={inputValue}
        size={size}
        shouldShowValue={shouldShowValue}
        {...rest}
      />
      {!isError ? (
        <HelperText testId={noticesTestId} label={helperText} labelValues={helperTextValues} />
      ) : (
        errorMessageText && (
          <ErrorMessage size={size} testId={noticesTestId} label={errorMessageText} labelValues={errorMessageValues} />
        )
      )}
    </FormControl>
  );
};
