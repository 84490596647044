import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { useGetPaidOnboardingMainEntryPoint } from 'src/pages/onboarding/vendor-company-info/hooks/useGetPaidOnboardingMainEntryPoint';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { SelectCategory, useForm } from 'src/ui/form';
import { CompanyInfoOnboardingOrigin, IntentType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { onboardingLocations } from '../locations';
import { INTENT_OPTIONS } from './consts';

export const CompanyIntentPage = ({ prevStepURL }) => {
  const dispatch = useDispatch();
  const companyInfo = useSelector(getCompanyInfo);
  const model = useMemo(() => ({ onboardingIntent: companyInfo.onboardingIntent }), [companyInfo]);
  const { navigate } = useNavigator();
  const { getPaidOnboardingEntryPoint } = useGetPaidOnboardingMainEntryPoint();
  const [origin] = useLocationState<CompanyInfoOnboardingOrigin | null>('origin', null);

  const submitAction = async (value) => {
    dispatch(
      setCompanyInfoAction({
        ...companyInfo,
        onboardingIntent: value.onboardingIntent,
      })
    );

    const { url, origin } = getNextPageUrlAndOrigin(value.onboardingIntent);
    navigate(url, false, { origin });
  };

  const onPrev = () => {
    navigate(prevStepURL);
  };

  const onSkip = () => {
    const { url, origin } = getNextPageUrlAndOrigin(IntentType.PAY);
    navigate(url, false, { origin });
  };

  const renderFooter = () => (
    <WizardElements.WizardButtonContainer>
      <Button label="onboarding.companyInfo.intent.skip" variant={ButtonVariants.tertiaryNaked} onClick={onSkip} />
    </WizardElements.WizardButtonContainer>
  );

  const getNextPageUrlAndOrigin = (companyIntent: string) =>
    companyIntent === IntentType.PAY
      ? { url: onboardingLocations.companyInfo.added, origin }
      : { url: getPaidOnboardingEntryPoint, origin: null };

  const [companyIntentMV, { submit }] = useForm(model, {
    submit: submitAction,
  });

  return (
    <StepLayoutPage
      title="onboarding.companyInfo.intent.title"
      subtitle="onboarding.companyInfo.intent.subtitle"
      onNext={submit}
      onPrev={onPrev}
      relativeStep={7 / 13}
      isNextDisabled={!companyIntentMV.onboardingIntent.value}
      footer={renderFooter()}
    >
      <Box>
        <SelectCategory options={INTENT_OPTIONS} model={companyIntentMV.onboardingIntent} />
      </Box>
    </StepLayoutPage>
  );
};
