import isEmpty from 'lodash/isEmpty';
import { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ContentContainer, ModalContainer, ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { AmountCurrencyField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { WizardNewSelectField } from 'src/ui/form/WizardNewSelectField';
import { ValidationError } from 'src/ui/ValidationError';

type MarkAsPaidFormType = {
  accountingPlatformAccountId?: string | null;
  amount: string;
};

type Props = {
  dismiss: () => void;
  submit: ({ accountingPlatformAccountId, amount }: MarkAsPaidFormType) => Promise<void>;
  options: { label: string; value: string }[];
  isConnectedToAccountingPlatform: boolean;
  accountingPlatformName: string;
  showPaidAmount: boolean;
  balance?: number | null;
  invoiceNumber?: string | null;
};

export const ViewBillMarkAsPaidModal: FunctionComponent<Props> = ({
  dismiss,
  submit,
  options,
  isConnectedToAccountingPlatform,
  accountingPlatformName,
  showPaidAmount,
  balance,
  invoiceNumber,
}) => {
  const model = useMemo(() => ({ accountingPlatformAccountId: null, amount: balance?.toString() || '0' }), []);
  const [markAsPaidVM, { submit: onSubmit }, , isLoading] = useForm<MarkAsPaidFormType>(model, {
    submit: async (model) => {
      if (showPaidAmount && balance) {
        let validationErrors = {};

        if (Number(model.amount) > balance) {
          validationErrors = {
            amount: (
              <Box>
                <MIFormattedText
                  label="bills.form.partialPayments.errors.amountToBalance"
                  values={{
                    paymentsAmount: <MIFormattedCurrency value={balance} />,
                  }}
                />
              </Box>
            ),
          };
        } else if (Number(model.amount) === 0) {
          validationErrors = {
            amount: <MIFormattedText label="bills.form.partialPayments.errors.enterAmount" />,
          };
        }

        if (!isEmpty(validationErrors)) throw new ValidationError({ validationErrors });
      }

      await submit(model);
      dismiss();
    },
  });

  const getTitle = () => {
    if (showPaidAmount) {
      return isConnectedToAccountingPlatform
        ? 'bills.form.partialPayments.markAsPaid.accountingPlatformLinkedTitle'
        : 'bills.form.partialPayments.markAsPaid.title';
    }

    return 'bills.form.markAsPaidDialog.dialogTitle';
  };

  return (
    <StyledModalMessage
      id="view-bill-mark-as-paid-modal"
      onCloseClick={dismiss}
      titleComponent={
        <Flex mb={-4}>
          <MIFormattedText label={getTitle()} />
        </Flex>
      }
      contentComponent={
        <>
          {showPaidAmount && (
            <>
              {isConnectedToAccountingPlatform && (
                <Box color="grey.700" textStyle="body4" mb={8}>
                  <MIFormattedText
                    label="bills.form.partialPayments.markAsPaid.description"
                    values={{
                      invoiceNumber,
                      accountingPlatformName: <MIFormattedText label={accountingPlatformName} />,
                    }}
                  />
                </Box>
              )}
              <StyledFormRow isIdent={isConnectedToAccountingPlatform}>
                <PrivateDataContainer>
                  <AmountCurrencyField
                    label="bills.form.partialPayments.markAsPaid.amountLabel"
                    model={markAsPaidVM.amount}
                    decimalScale={2}
                    fixedDecimalScale
                    isRequired
                  />
                </PrivateDataContainer>
              </StyledFormRow>
            </>
          )}
          {isConnectedToAccountingPlatform && (
            <StyledFormRow>
              <WizardNewSelectField
                label="bills.form.markAsPaidDialog.accountingPlatformAccountLabel"
                labelValues={{ accountingPlatformName: <MIFormattedText label={accountingPlatformName} /> }}
                placeholder="bills.form.markAsPaidDialog.accountingPlatformAccountPlaceholder"
                required
                options={options}
                model={markAsPaidVM.accountingPlatformAccountId}
                allowCustom={false}
                privateData
              />
            </StyledFormRow>
          )}
        </>
      }
      buttonComponent={
        <Flex justify="flex-end" mt={12}>
          <Flex w={{ base: 'full', md: '60%' }} direction={{ base: 'column', sm: 'unset' }}>
            <Button
              label="dialogs.buttons.cancel"
              variant={ButtonVariants.tertiaryNaked}
              size={ButtonSizes.lg}
              onClick={dismiss}
              mr={{ sm: 3 }}
              mb={{ base: 3, sm: 'unset' }}
            />
            <Button
              label="general.save"
              size={ButtonSizes.lg}
              onClick={onSubmit}
              isDisabled={isConnectedToAccountingPlatform && !markAsPaidVM.accountingPlatformAccountId.value}
              isLoading={isLoading}
              isFullWidth
            />
          </Flex>
        </Flex>
      }
    />
  );
};

const StyledModalMessage = styled(ModalMessage)`
  ${ModalContainer} {
    overflow-y: visible;
  }
  ${ContentContainer} {
    margin-bottom: 0;
  }
`;

const StyledFormRow = styled(FormRow)<{ isIdent?: boolean }>`
  > div > div {
    margin-bottom: ${(props) => !props.isIdent && 0};
  }
  .input-container {
    &:first-of-type {
      margin-bottom: ${(props) => (props.isIdent ? '4rem' : '0')};
    }

    @media ${devices.mobile}, ${devices.phablet} {
      margin-bottom: 4rem;
    }
  }
`;
