import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { DefaultErrorPage } from 'src/pages/vendor/components/DefaultErrorPage';
import { PaymentCancelledPage } from 'src/pages/vendor/components/PaymentCancelledPage';
import { ServerErrorCode } from 'src/utils/error-codes';

type Props = {
  token: string;
  hideLogin?: boolean;
};

export const category = {
  push2debit: 'push-to-debit',
} as const;

export type AnalyticPropsInState = {
  analyticsProps: {
    category: typeof category[keyof typeof category];
  };
};

type PaymentTokenErrorType = 'payment-cancelled' | 'cant-process' | 'general' | '';

type JWTTokenBase = {
  paymentId: string;
};

export function usePaymentErrorFromToken(token: string) {
  const payload = getJWTPayload<JWTTokenBase>(token);
  const paymentId = payload?.paymentId?.toString();
  const { errorData } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation);
  const error = errorData || invalidTokenData;
  const errorType = errorCodeToAnalyticsPageName(error);

  return { error, errorType };
}

export function errorCodeToAnalyticsPageName(error?: { code: ServerErrorCode }): PaymentTokenErrorType {
  switch (error?.code) {
    case 'PMT17':
      return 'payment-cancelled';
    case 'PMT19':
      return 'cant-process';
    default:
      return '';
  }
}

export function InvalidTokenPage({ token, hideLogin = false }: Props) {
  const { error, errorType } = usePaymentErrorFromToken(token);

  if (!error) {
    return <AreaLoader />;
  }

  switch (errorType) {
    case 'payment-cancelled':
      return <PaymentCancelledPage token={token} hideLogin={hideLogin} />;
    default:
      return <DefaultErrorPage token={token} hideLogin={hideLogin} />;
  }
}
