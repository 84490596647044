const base = '/orgs/:orgId/vendors/:id/delivery-method';
export const deliveryMethodsLocations = {
  add: `${base}/add`,
  ach: {
    createFlow: `${base}/ach/create/bank-details`,
    createFromBatchPaymentsFlow: `${base}/ach/create-from-batch-payments/bank-details`,
    createFromReturnedCheckRecoveryFlow: `${base}/ach/create-from-returned-check-recovery/bank-details`,
    createFromVirtualCardRecoveryFlow: `${base}/ach/create-from-virtual-card-recovery/bank-details`,
    createFromUndepositedCheckResendFlow: `${base}/ach/create-from-undeposited-check-resend/bank-details`,
    editBankDetails: `${base}/ach/:deliveryMethodId/edit/bank-details`,
  },
  check: {
    create: `${base}/check/new/name`,
    edit: `${base}/check/:deliveryMethodId/name`,
    address: {
      create: `${base}/check/new/address`,
      edit: `${base}/check/:deliveryMethodId/address`,
    },
  },
  virtual: {
    create: `${base}/virtual/new`,
  },
  international: {
    create: `${base}/international/billing`,
  },
};
