import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { invitationsApi } from 'src/modules/invitations/api';

type StateType = {
  batchCreate: {
    loading: boolean;
    error: any;
  };
};
type PayloadType = {
  orgId: number;
  items: [];
};
type ResponseType = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  requireApproval: boolean | null;
  approvalThresholdAmount: number | null;
  organizationId: number;
  invitedByUserId: number;
  status: string;
  expired: string;
}[];

export const batchCreateInvitationsSlice = createApiCallSlice<PayloadType, StateType, ResponseType>({
  name: '[INVITATIONS] BATCH_CREATE',
  api: invitationsApi.batchCreate,
  initialState: {
    batchCreate: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.batchCreate.loading = true;
      state.batchCreate.error = undefined;
    },
    [ON_SUCCESS]: (state) => {
      state.batchCreate.loading = false;
      state.batchCreate.error = undefined;
    },
    [ON_FAILURE]: (state, action) => {
      state.batchCreate.loading = false;
      state.batchCreate.error = action.error;
    },
  },
  selectors: {
    status: (state) => state.invitations.batchCreate,
    loading: (state) => state.invitations.batchCreate.loading,
  },
});
