import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { billingLocations } from 'src/pages/billing/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { AddFundingSourceWizardOrigin, CardNetworkTypes, FundingType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import locations from 'src/utils/locations';
import { LinkAccountingPlatformAccountToFundingSourcePage } from './LinkAccountingPlatformAccountToFundingSourcePage';

/**
 * @deprecated please do not use this - use src/flows/add-bank-account-flow/steps/link-accounting-platform/LinkAccountingPlatform.tsx
 */
export const LinkAccountingPlatformAccountToFundingSourcePageContainer = () => {
  const { navigateToExitWithPreservedState, navigate, navigateWithPreservedState } = usePreservedStateNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();

  const [fundingSourceId] = useLocationState<number>('fundingSourceId');
  const [editMode] = useLocationState('editMode', '');
  const [origin] = useLocationState('origin', '');
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(fundingSourceId));

  const isFromPayBillFlow = origin === AddFundingSourceWizardOrigin.PAY_BILL;
  const isAmexCreditCard = fundingSource.cardAccount?.network === CardNetworkTypes.AMEX;

  const goExit = () => {
    if (navigateToExitWithPreservedState) {
      navigateToExitWithPreservedState(locationState);
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
  };

  const goNext = useCallback(() => {
    if (
      fundingSource.fundingType === FundingType.ACH &&
      (origin === AddFundingSourceWizardOrigin.SETTINGS || origin === AddFundingSourceWizardOrigin.BILLING)
    ) {
      navigate(onboardingLocations.fundingSources.bank.complete, false, {
        ...locationState,
        origin,
        newFundingSourceId: fundingSourceId,
      });
    } else if (origin === AddFundingSourceWizardOrigin.BILLING) {
      navigate(billingLocations.add, false, {
        ...locationState,
        origin,
        newFundingSourceId: fundingSourceId,
      });
    } else if (navigateWithPreservedState) {
      if (isFromPayBillFlow || isAmexCreditCard) {
        goExit();
      } else {
        const data =
          fundingSource?.fundingType === FundingType.CARD ? { selectedFundingSourceId: fundingSourceId } : {};
        navigateWithPreservedState(data);
      }
    } else {
      navigate(locations.MainApp.dashboard.url());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LinkAccountingPlatformAccountToFundingSourcePage
      fundingSourceId={fundingSourceId}
      goNext={goNext}
      cancelFlow={goExit}
      editMode={!!editMode}
      newAccountNotificationLabel="onboarding.fundingSources.bank.accountingPlatformAccounts.paymentNotification"
    />
  );
};
