import styled from 'styled-components';
import { SingleViewLoadingContainer } from 'src/components/layout/Containers';
import { InvoiceType } from 'src/modules/invoices/types';
import { Card, Header } from 'src/pages/get-paid/list/components/batchActions/BatchElements';
import BatchInvoiceCard from 'src/pages/get-paid/list/components/batchActions/BatchInvoiceCard';
import DeleteSentInvoicesButton from 'src/pages/get-paid/list/components/batchActions/DeleteSentInvoicesButton';
import LeftHeaderText from 'src/pages/get-paid/list/components/LeftHeaderText';
import { getTotalAmount } from 'src/pages/get-paid/list/components/utils';
import { devices } from 'src/theme/appDevices';

type Props = {
  invoices: Array<InvoiceType>;
};

const SentBatchView = ({ invoices }: Props) => {
  const numOfInvoicesSelected = invoices.length;
  const selectedInvoicesTotalAmount = getTotalAmount(invoices);

  return (
    <>
      <SingleViewLoadingContainer>
        <Header>
          <LeftHeaderText
            numOfInvoices={numOfInvoicesSelected}
            totalAmount={selectedInvoicesTotalAmount}
            headerLabel="getPaid.view.batch.title.sent"
            descriptionLabel="getPaid.view.batch.description"
          />
          <DeleteButton numOfInvoices={numOfInvoicesSelected} totalAmount={selectedInvoicesTotalAmount} />
        </Header>
        <Card mode="mainSingleScreen">
          {invoices.map((invoice) => (
            <BatchInvoiceCard invoice={invoice} key={invoice.id} />
          ))}
        </Card>
      </SingleViewLoadingContainer>
    </>
  );
};

export default SentBatchView;

const DeleteButton = styled(DeleteSentInvoicesButton)`
  @media ${devices.mobile}, ${devices.phablet} {
    margin-top: 2rem;
  }
`;
