import React from 'react';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { StatusInfo } from 'src/components/payment-progress-bar/ProgressBar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type Props = {
  paymentProcess: StatusInfo[];
  paymentCurrentStageIndex: number;
};

type GetStageProps = {
  isFinalStage: boolean;
  stageText: string;
  stageDate?: string;
  stageComment?: string;
  stageIcon?: React.ReactNode;
  color: string;
};

type TextLabelProps = {
  stageText: string;
  color: string;
};

const GetTextLabel = ({ stageText, color }: TextLabelProps): JSX.Element => (
  <Box w={20} pt={2} color={color} data-testid={`payment-progress-bar-labels-${stageText}`} textStyle="body4Uppercase">
    {stageText}
  </Box>
);

const GetStage = ({ isFinalStage, stageText, stageDate, stageComment, stageIcon, color }: GetStageProps): JSX.Element =>
  stageDate ? (
    <>
      <Box>
        <GetTextLabel stageText={stageText} color={color} />
        <Box pt={1} color="grey.600" data-testid={`payment-progress-bar-labels-${stageText}-date`} textStyle="body4">
          <MIFormattedDate date={stageDate} />
          <Box data-testid={`payment-progress-bar-labels-${stageText}-date-comment`}>
            <MIFormattedText label={stageComment} />
          </Box>
        </Box>
      </Box>
      {isFinalStage ? null : <Box bgColor="transparent" h="0.1rem" flex="1" />}
    </>
  ) : (
    <>
      <Box>
        <GetTextLabel stageText={stageText} color="grey.700" />
        {stageIcon}
      </Box>
      {isFinalStage ? null : <Box bgColor="transparent" h="0.1rem" flex="1" />}
    </>
  );

export const ProgressBarLabels = ({ paymentProcess, paymentCurrentStageIndex }: Props): JSX.Element => (
  <Flex textAlign="center">
    {paymentProcess.map((stage, stageIndex) => {
      const isPaymentComplete = paymentProcess?.length - 1 === paymentCurrentStageIndex;
      const isFinal = paymentProcess?.length - 1 === stageIndex;
      const color = isPaymentComplete ? 'grey.700' : 'black';

      return (
        <GetStage
          key={stageIndex}
          isFinalStage={isFinal}
          stageText={stage.label}
          stageDate={stage.date}
          stageIcon={stage.icon}
          stageComment={stage.comment}
          color={color}
        />
      );
    })}
  </Flex>
);
