import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { useRefreshItemsList } from 'src/hooks/useRefreshItemsList';
import { updateIsPolling, updateIsSyncing } from 'src/modules/accounting-platforms/accounting-platforms-actions';
import { accountingPlatformsStore } from 'src/modules/accounting-platforms/accounting-platforms-store';
import { CHECK_SYNC_STATUS_INTERVAL_TOP_BAR } from 'src/pages/settings/components/accounting-software/consts';
import { usePollingSyncStatus } from 'src/pages/settings/hooks/usePollingSyncStatus';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant, SyncStatus } from 'src/utils/consts';

export const useTriggerSync = (customGetIsSyncing?: () => Promise<boolean>) => {
  const orgId = useOrgId();
  const apActions = useStoreActions(accountingPlatformsStore);
  const dispatch = useDispatch();
  const { refreshAfterSync } = useRefreshItemsList();

  const syncSummary = useSelector(accountingPlatformsStore.selectors.fetchSyncSummary.syncSummary);
  const onSyncCompleted = useCallback(async () => {
    dispatch(updateIsPolling(false));
    dispatch(updateIsSyncing(false));

    if (syncSummary.syncStatus === SyncStatus.FAILURE) {
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'settings.accountingSoftware.syncNowError',
      });
    } else {
      pushNotification({
        type: NotificationVariant.SUCCESS,
        msg: 'settings.newAccountingSoftware.syncSuccessful',
        textValues: { accountingSoftwareName: syncSummary.accountingPlatformName },
      });
    }

    refreshAfterSync();
  }, [dispatch, orgId, syncSummary, refreshAfterSync]);

  const getIsSyncing = async () => {
    if (customGetIsSyncing) {
      return customGetIsSyncing();
    }

    const syncSummaryResult = await apActions.fetchSyncSummary({ orgId });
    const isSyncComplete =
      syncSummaryResult.payload.syncStatus === SyncStatus.SYNCED ||
      syncSummaryResult.payload.syncStatus === SyncStatus.FAILURE;

    return !isSyncComplete;
  };

  const { startPollingStatus } = usePollingSyncStatus({
    getIsSyncing,
    interval: CHECK_SYNC_STATUS_INTERVAL_TOP_BAR,
    autoStartPolling: false,
    isSyncingDefault: false,
    onSyncCompleted,
  });
  const triggerSync = async () => {
    try {
      dispatch(updateIsSyncing(true));
      await apActions.triggerSync({ orgId });
      startPollingStatus();
    } catch {
      dispatch(updateIsSyncing(false));
      pushNotification({
        type: NotificationVariant.ERROR,
        msg: 'settings.accountingSoftware.syncNowError',
      });
    }
  };

  return {
    triggerSync,
    startPollingStatus,
  };
};
