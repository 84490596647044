import map from 'lodash/map';
import styled from 'styled-components';
import { BillGroupHeader } from 'src/components/list/BillGroupHeader';
import { CollapsableGroup } from 'src/components/list/CollapsableGroup';
import { InvoiceType } from 'src/modules/invoices/types';
import InvoiceListItem from 'src/pages/get-paid/list/components/InvoiceListItem';
import { groupPartialPaymentsByBill } from 'src/utils/bills';
import { InvoiceStatus, PaymentRequestTabs } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';

type Props = {
  groupedInvoices: {
    groupName: string;
    invoices: InvoiceType[];
  }[];
  selectedId?: string;
  setSelected: (id: string) => void;
  markedIds: string[];
  setMarked: (id: string, makred: boolean) => void;
  enableMultiSelect: boolean;
  search?: string;
};

const InvoicesList = (props: Props) => {
  const { groupedInvoices, selectedId, setSelected, markedIds, setMarked, enableMultiSelect, search } = props;
  const query = useQueryString();

  const renderInvoiceItem = (invoice) => (
    <InvoiceListItem
      key={invoice.id || invoice.invoiceNumber}
      invoice={invoice}
      disabled={invoice.status === InvoiceStatus.FAILED && markedIds.length > 0}
      isSelected={invoice.id === selectedId}
      setSelected={setSelected}
      isMarked={markedIds.indexOf(invoice.id) !== -1}
      setMarked={setMarked}
      search={search}
      enableMultiSelect={enableMultiSelect}
      showCheckbox={invoice.id === selectedId || markedIds.length > 0}
      selectedId={selectedId}
    />
  );

  return (
    <>
      {map(groupedInvoices, ({ groupName, invoices }) => {
        const groups = groupPartialPaymentsByBill(invoices);

        return (
          <InvoicesGroup key={groupName}>
            {groupName && <BillGroupHeader header={groupName} />}
            {query.status === PaymentRequestTabs.PAID
              ? groups.map((payments, index) => {
                  const ids = payments.map((payment) => payment.id);
                  const collapsed = !ids.includes(query.id);

                  return (
                    <CollapsableGroup key={index} collapsed={collapsed}>
                      {payments.map((payment) => renderInvoiceItem(payment))}
                    </CollapsableGroup>
                  );
                })
              : invoices.map((invoice) => renderInvoiceItem(invoice))}
          </InvoicesGroup>
        );
      })}
    </>
  );
};

export default InvoicesList;

const InvoicesGroup = styled.div``;
