import pick from 'lodash/pick';
import { RefObject, useEffect, useRef } from 'react';
import { BillersVisiblityModal } from 'src/components/education/BillersVisibilityModal';
import { ListLayoutPage, ListPage, ViewSinglePage } from 'src/components/layout/ListLayoutPage';
import { PageProps } from 'src/hoc/withListContainer';
import { PayListItemType, PreferredSortByTabType } from 'src/modules/bills/types';
import { BILL_STATUSES } from 'src/pages/bill/consts';
import { useShowZeroState } from 'src/pages/pay/hooks/useShowZeroState';
import { stickyPayFilters } from 'src/pay/query-string';
import { analytics } from 'src/services/analytics';
import { getBillsTabs, isScannedInvoice } from 'src/utils/bills';
import { BillStatus } from 'src/utils/consts';
import { stringifyQs } from 'src/utils/query-utils';
import { useBatchSelection, useSelectedIds } from '../regular-batch-payments/BatchSettings/useBatchSelection';
import { PayList } from './PayList';
import { ViewBillPage } from './ViewBillPage';

type Props = {
  items: PayListItemType[];
  listViewRef: RefObject<unknown>;
  goCreateBill: () => void;
  isLoading: boolean;
  itemId: string;
  updateIsClosedBillsZeroStatePreference: () => void;
  itemsCount: number;
  reloadBills: () => void;
  eventPage: string;
  backPath: string;
  setPaymentRequestApprovalStatus: (paymentRequestId: number, isApproved: boolean, isTrusted: boolean) => Promise<void>;
  filters: PageProps['filters'];
  onClearFilters: () => void;
  shouldShowBillInboxAnnouncement: boolean;
  onCloseBillInboxAnnouncement: () => void;
  preferredSortByTab: PreferredSortByTabType;
};
export const PayListContainer = ({
  items,
  filters,
  onClearFilters,
  isLoading,
  updateIsClosedBillsZeroStatePreference,
  goCreateBill,
  listViewRef,
  itemId,
  itemsCount,
  reloadBills,
  setPaymentRequestApprovalStatus,
  eventPage,
  backPath,
  shouldShowBillInboxAnnouncement,
  onCloseBillInboxAnnouncement,
  preferredSortByTab,
}: Props) => {
  const {
    enabledSelectionBills,
    enableBatchSelection,
    selectedIds,
    setSelected,
    selectAll,
    clearAll,
    isAllSelected,
  } = useBatchSelection(items);
  const singleViewRef = useRef<HTMLElement>();
  const selectedTabBillStatus: BillStatus = BILL_STATUSES.includes(filters?.status as BillStatus)
    ? (filters?.status as BillStatus)
    : BillStatus.UNPAID;
  const { showBillsZeroState } = useShowZeroState(items, selectedTabBillStatus, filters);
  const listBaseSearch = stringifyQs(pick(filters, stickyPayFilters));
  const tabs = getBillsTabs(BILL_STATUSES, selectedTabBillStatus, 'bills', listBaseSearch, preferredSortByTab);
  const isShowEmptyBillList = !isLoading && items.length === 0;
  const isScannedInvoiceView = isScannedInvoice(itemId);
  const ref = useRef(useSelectedIds());

  useEffect(() => {
    ref.current.clear();
  }, []);

  const onZeroStateAction = () => {
    analytics.track('bills-list', 'zero-state-ok-cliked');

    if (updateIsClosedBillsZeroStatePreference) {
      updateIsClosedBillsZeroStatePreference();
    }
  };

  return (
    <ListLayoutPage
      listViewRef={listViewRef}
      eventPage={eventPage}
      singleViewRef={singleViewRef}
      title="bills.title"
      showNewItemButton={!showBillsZeroState}
      testId="pay-list-page"
    >
      <ListPage>
        <PayList
          items={items}
          pathname="bills"
          goCreateBill={goCreateBill}
          listItemBaseSearch={`${filters.search ? `search=${filters.search}` : ''}`}
          tabs={tabs}
          filters={filters}
          onClearFilters={onClearFilters}
          isLoading={isLoading}
          onZeroStateAction={onZeroStateAction}
          itemsCount={itemsCount}
          enabledSelectionItems={enabledSelectionBills}
          enableBatchSelection={enableBatchSelection}
          setMarked={setSelected}
          markedIds={selectedIds}
          markAll={selectAll}
          clearAll={clearAll}
          isAllMarked={isAllSelected}
          selectedTabBillStatus={selectedTabBillStatus}
          isShowEmptyBillList={isShowEmptyBillList}
          shouldShowBillInboxAnnouncement={shouldShowBillInboxAnnouncement}
          onCloseBillInboxAnnouncement={onCloseBillInboxAnnouncement}
        />
        <BillersVisiblityModal />
      </ListPage>
      <ViewSinglePage noPadding={selectedIds.length} largeWidth={selectedIds.length}>
        <ViewBillPage
          itemId={itemId}
          isLoading={isLoading}
          enableBatchSelection={enableBatchSelection}
          enabledSelectionItems={enabledSelectionBills}
          selectedIds={selectedIds}
          reloadBills={reloadBills}
          singleViewRef={singleViewRef}
          backPath={backPath}
          setPaymentRequestApprovalStatus={setPaymentRequestApprovalStatus}
          filters={filters}
          isScannedInvoice={isScannedInvoiceView}
          eventPage={eventPage}
        />
      </ViewSinglePage>
    </ListLayoutPage>
  );
};
