import { featureFlags } from '@melio/shared-web';
import compact from 'lodash/compact';
import { generatePath } from 'react-router-dom';
import { IconNames } from 'src/core/ds/icon';
import { AccountingSoftwareSettingsPageContainer } from 'src/pages/settings/AccountingSoftwareSettingsPageContainer';
import { BillingSettingsPageContainer } from 'src/pages/settings/BillingSettingsPageContainer';
import { CompanySettingsPageContainer } from 'src/pages/settings/CompanySettingsPageContainer';
import { AccountSettingsPage } from 'src/pages/settings/components/AccountSettingsPage';
import { ApprovalWorkflowsPage } from 'src/pages/settings/components/ApprovalWorkflows';
import { approvalWorkflowsLocations } from 'src/pages/settings/components/ApprovalWorkflows/locations';
import { EmailNotificationsPage } from 'src/pages/settings/components/EmailNotificationsPage';
import { settingsLocations } from 'src/pages/settings/locations';
import { ManageUsersSettingsPage } from 'src/pages/settings/ManageUsersSettingsPage';
import { PaymentMethodsSettingsPageContainer } from 'src/pages/settings/PaymentMethodsSettingsPageContainer';
import { ReceivingMethodsSettingsPageContainer } from 'src/pages/settings/ReceivingMethodsSettingsPageContainer';
import { SupportSettingsPageContainer } from 'src/pages/settings/SupportSettingsPageContainer';
import { analytics } from 'src/services/analytics';
import { AccessLevel, FeatureFlags, PAGINATION } from 'src/utils/consts';
import { PermissionsType } from 'src/utils/permissions';
import { SpendManagementSettingPage } from '../spend-management/SpendManagementSettingPage';
import { SettingsPagesRouteType } from '../types';
import { settingsPagesFilter } from './settingsPagesFilter';

type Props = {
  orgId: number;
  showDeliveryMethods: boolean;
  permissions: PermissionsType;
  canContactSupport: boolean;
  accessLevel: AccessLevel;
  enableApprovalWorkflows: boolean;
  isPureMSNOrganization: boolean;
  isSpendManagementEnabled: boolean;
};

const search = `sorting=invoiceDate:desc&start=${PAGINATION.DEFAULT_START}&limit=${PAGINATION.DEFAULT_LIMIT}`;

const CATEGORY_NAME = 'settings';
enum PAGES {
  ACCOUNT = 'account',
  COMPANY = 'company',
  USERS = 'users',
  ACCOUNTING_SOFTWARE = 'accounting-software',
  VIRTUAL_CARDS = 'virtual-cards',
  PAYMENT_METHODS = 'payment-methods',
  RECEIVING_METHODS = 'receiving-methods',
  BILLING = 'billing',
  SUPPORT = 'support',
}

export const getSettingsPagesRoutesGroups = ({
  orgId,
  showDeliveryMethods,
  permissions,
  canContactSupport,
  accessLevel,
  enableApprovalWorkflows,
  isPureMSNOrganization,
  isSpendManagementEnabled,
}: Props) => {
  const canAccessOrg = accessLevel !== AccessLevel.NONE;
  const [isBillingMethodCheckFeeEnabled] = featureFlags.useFeature<boolean>(FeatureFlags.BillingMethodCheckFee, false);

  const routes: SettingsPagesRouteType[] = [
    {
      header: 'settings.groupsHeaders.companyGroup',
      shouldRender: true,
      routes: [
        {
          path: generatePath(settingsLocations.account, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.ACCOUNT);

            return <AccountSettingsPage />;
          },
          title: 'settings.account.title',
          description: 'settings.account.description',
          icon: IconNames.userId,
          shouldRender: true,
        },
        {
          path: generatePath(settingsLocations.company, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.COMPANY);

            return <CompanySettingsPageContainer />;
          },
          title: 'settings.company.title',
          description: 'settings.company.description',
          icon: IconNames.company,
          shouldRender: canAccessOrg,
        },
        {
          path: generatePath(settingsLocations.users, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.USERS);

            return <ManageUsersSettingsPage />;
          },
          title: `settings.manageUsers.title`,
          description: `settings.manageUsers.description`,
          icon: IconNames.userAdd,
          shouldRender: !isPureMSNOrganization && canAccessOrg && permissions.userManagement.read(),
        },
        {
          path: generatePath(settingsLocations.emailNotifications, { orgId }),
          main: () => <EmailNotificationsPage />,
          title: `settings.emailNotifications.title`,
          description: `settings.emailNotifications.description`,
          icon: IconNames.bell,
          shouldRender: !isPureMSNOrganization && canAccessOrg,
        },
        {
          path: generatePath(settingsLocations.accountingSoftware, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.ACCOUNTING_SOFTWARE);

            return <AccountingSoftwareSettingsPageContainer />;
          },
          title: 'settings.newAccountingSoftware.title',
          description: 'settings.newAccountingSoftware.description',
          icon: IconNames.refresh,
          shouldRender: !isPureMSNOrganization && canAccessOrg && permissions.settings.editAccountingSoftware(),
        },
        {
          path: generatePath(settingsLocations.spendManagement, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.VIRTUAL_CARDS);

            return <SpendManagementSettingPage />;
          },
          title: `settings.spendManagement.card.title`,
          description: `settings.spendManagement.card.description`,
          icon: IconNames.creditCard,
          shouldRender: isSpendManagementEnabled,
        },
        {
          path: generatePath(approvalWorkflowsLocations.base, { orgId }),
          main: () => <ApprovalWorkflowsPage />,
          title: `settings.approvalWorkflows.page.title`,
          description: `settings.approvalWorkflows.page.description`,
          icon: IconNames.flow,
          showNewSettingTag: true,
          shouldRender: !isPureMSNOrganization && enableApprovalWorkflows && canAccessOrg,
        },
      ],
    },
    {
      header: 'settings.groupsHeaders.paymentsAndBillingGroup',
      shouldRender: canAccessOrg,
      routes: [
        {
          path: generatePath(settingsLocations.paymentMethods, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.PAYMENT_METHODS);

            return <PaymentMethodsSettingsPageContainer />;
          },
          title: 'settings.paymentMethods.title',
          description: 'settings.paymentMethods.description',
          icon: IconNames.wallet,
          shouldRender: !isPureMSNOrganization,
        },
        {
          path: generatePath(settingsLocations.receivingMethods, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.RECEIVING_METHODS);

            return <ReceivingMethodsSettingsPageContainer />;
          },
          title: 'settings.receivingMethods.title',
          description: 'settings.receivingMethods.description',
          icon: IconNames.moneyReceive,
          shouldRender: showDeliveryMethods || isPureMSNOrganization,
        },
        {
          path: generatePath(settingsLocations.billingItems, { orgId }),
          main: (props) => {
            analytics.page(CATEGORY_NAME, PAGES.BILLING);

            return <BillingSettingsPageContainer {...props} />;
          },
          search,
          title: 'settings.billing.title',
          description: isBillingMethodCheckFeeEnabled
            ? 'settings.billing.description'
            : 'settings.billing.oldDescription',
          icon: IconNames.billingSettings,
          shouldRender: true,
        },
      ],
    },
    {
      header: 'settings.groupsHeaders.supportGroup',
      shouldRender: canContactSupport,
      routes: [
        {
          path: generatePath(settingsLocations.support, { orgId }),
          main: () => {
            analytics.page(CATEGORY_NAME, PAGES.SUPPORT);

            return <SupportSettingsPageContainer />;
          },
          title: 'settings.support.title',
          description: 'settings.support.description',
          icon: IconNames.chat,
          showNewSettingTag: false,
          shouldRender: true,
        },
      ],
    },
  ];

  return settingsPagesFilter(routes).map(({ routes, ...restGroup }) => ({
    routes: compact(routes),
    ...restGroup,
  }));
};
