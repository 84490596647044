import { ModalMessage } from 'src/components/common/ModalMessage';
import { UserOrganization } from 'src/utils/types';
import { RoleDowngradeModalMessageButtons } from './components/RoleDowngradeModalMessageButtons';
import { RoleDowngradeModalMessageContent } from './components/RoleDowngradeModalMessageContent';
import { RoleDowngradeModalMessageTitle } from './components/RoleDowngradeModalMessageTitle';

type Props = {
  userId: string | number;
  userFullName: string;
  onCancel: () => void;
  onSave: (userOrganization: UserOrganization) => Promise<any>;
  userOrganization: UserOrganization;
  isLoading: boolean;
};

export const RoleDowngradeModalMessage = ({
  userId,
  userFullName,
  onCancel,
  onSave,
  userOrganization,
  isLoading,
}: Props) => {
  const handleSave = () => {
    onSave(userOrganization);
  };

  return (
    <ModalMessage
      id="downgrade-role-confirmation-modal"
      titleComponent={<RoleDowngradeModalMessageTitle />}
      contentComponent={<RoleDowngradeModalMessageContent userFullName={userFullName} />}
      buttonComponent={
        <RoleDowngradeModalMessageButtons
          isLoading={isLoading}
          userId={userId}
          onSave={handleSave}
          onCancel={onCancel}
        />
      }
      onCloseClick={onCancel}
    />
  );
};
