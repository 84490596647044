import BillOpenBalanceInfo from 'src/components/list/BillOpenBalanceInfo/BillOpenBalanceInfo';
import Box from 'src/core/ds/box';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { isBillHasPartialPayments } from 'src/utils/bills';
import { BillType } from 'src/utils/types';
import { BillDetails } from './BillDetails';

type Props = {
  bill: BillType | BatchBillType;
  paymentId: string;
  eventPage: string;
  totalAmountField?: React.ReactElement<{ value: number | string | null }>;
  onLabelClick?: (paymentId, status) => void;
};

export const BillDetailsWithOpenBalance = ({ bill, paymentId, eventPage, totalAmountField, onLabelClick }: Props) => (
  <>
    <Box py={10} px={8}>
      <BillDetails bill={bill} eventPage={eventPage} totalAmountField={totalAmountField} />
    </Box>
    {isBillHasPartialPayments(bill) && (
      <BillOpenBalanceInfo
        isPartialPayments={isBillHasPartialPayments(bill)}
        currentPaymentId={paymentId}
        bill={bill}
        onLabelClick={onLabelClick}
      />
    )}
  </>
);
