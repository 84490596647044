import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { DateTableRowType } from 'src/components/shared/tables/PaymentsTable/types';
import Box from 'src/core/ds/box';
import IconButton from 'src/core/ds/iconButton';
import Tooltip from 'src/core/ds/tooltip';
import { ReactComponent as SendIcon } from 'src/images/get-pro/send.svg';
import { useGetProSingleItemActions } from 'src/pages/get-pro/hooks/useGetProSingleItemActions';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';
import { InvoiceAndRequestMenu } from './menu';

export const RequestsActionCell = ({ row: { id } }: CellProps<DateTableRowType>) => {
  const invoice = useSelector(MAP_STORE_BY_TAB[GetProTabs.REQUESTS].selectors.fetch.byId(id));
  const { send: onReSendRequest } = useGetProSingleItemActions(invoice, GetProTabs.REQUESTS);
  const stopPropagation = (event) => event.stopPropagation();

  return useMemo(
    () => (
      <Box display="flex" onClick={stopPropagation} justifyContent="flex-end" mr={4}>
        <Tooltip placement="top" label={<MIFormattedText label="getPro.tooltip.resend" />}>
          <IconButton
            size="sm"
            icon={<SendIcon />}
            aria-label="resend-request"
            label="getPro.table.item.menu.requests.resend"
            onClick={onReSendRequest}
            mr={4}
            analyticsProperties={{ id }}
          />
        </Tooltip>
        <InvoiceAndRequestMenu invoice={invoice} tab={GetProTabs.REQUESTS} />
      </Box>
    ),
    [id, invoice, onReSendRequest]
  );
};
