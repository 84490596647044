import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'src/components/common/Loader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex/Flex';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { financingStore } from 'src/modules/financing/financing-store';
import { InstallmentsErrorModal } from 'src/pages/bill/pay/components/InstallmentsErrorModal';
import {
  FINANCING_CATEGORY,
  financingAnalyticsEvents,
  financingPage,
  mapOffers,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { TermsAndConditions } from 'src/pages/bill/pay/installments/pages/components/TermsAndConditions';
import { useInstallmentsOptionsRequest } from 'src/pages/bill/pay/installments/pages/installments-options/useInstallmentsOptionsRequest';
import { useDeliveryDateChange } from 'src/pages/bill/pay/installments/pages/repayment-terms/hooks/useDeliveryDateChange';
import { useRepaymentTermsPageData } from 'src/pages/bill/pay/installments/pages/repayment-terms/hooks/useRepaymentTermsPageData';
import { useSetOptionOnRepaymentTerms } from 'src/pages/bill/pay/installments/pages/repayment-terms/hooks/useSetOptionOnRepaymentTerms';
import { InstallmentOptionWithRepayment } from 'src/pages/bill/pay/installments/pages/repayment-terms/InstallmentOptionWithRepayment';
import { InstallmentOption } from 'src/pages/bill/pay/installments/types';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { analytics } from 'src/services/analytics';
import { convertNumberToCurrency } from 'src/utils/currency-utils';

type Props = {
  goNext: () => void;
  cancelFlow: () => void;
};

export const RepaymentTermsPage = ({ goNext, cancelFlow }: Props) => {
  const payment = useSelector(getPayment);
  const { scheduledDate, fundingSourceId, payBillFlowUUID } = payment;
  const [isContinueLoading, setContinueLoading] = useState(false);

  const chosenNumberOfInstallments = useSelector(
    financingStore.selectors.intentIdWithInstallmentOption.numberOfInstallments
  );
  const { intentIdWithInstallmentOption } = useStoreActions(financingStore);
  const { installmentsOptions, loading: installmentOptionsLoading } = useInstallmentsOptionsRequest();

  const { onDeliveryDateChange, setIsScheduledDateLoading, isScheduledDateLoading } = useDeliveryDateChange();
  const {
    setOption,
    isLoading: isSetInstallmentOptionLoading,
    result: setOptionResult,
    error,
    resetError,
  } = useSetOptionOnRepaymentTerms();
  const isInstallmentsDatesLoading = isSetInstallmentOptionLoading && !isContinueLoading;

  const selectInstallmentOption = async (option: InstallmentOption) => {
    await intentIdWithInstallmentOption.set({
      intentId: installmentsOptions.intentId,
      chosenInstallmentOption: { numberOfInstallments: option.numberOfInstallments },
    });
  };

  const handleContinue = async () => {
    const { totalAmount, totalFees, totalFeePercent, installments } = setOptionResult;
    analytics.track(financingPage.repaymentTerms, financingAnalyticsEvents.installmentsOptionContinue, {
      numOfInstallments: chosenNumberOfInstallments,
      totalAmount,
      feeRate: totalFeePercent,
      feeAmount: totalFees,
      repaymentAmount: installments[0].amount,
      paymentSentDate: scheduledDate.toString(),
      fundingSourceId,
      ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
    });

    setContinueLoading(true);

    await setOption(payment.scheduledDate, chosenNumberOfInstallments);
    setContinueLoading(false);
    goNext();
  };

  const handleChangeFundingSource = () => {
    analytics.track(financingPage.repaymentTerms, financingAnalyticsEvents.installmentsErrorModalPayAnotherWayClick, {
      ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
    });

    cancelFlow();
  };

  const handleTryAgain = async () => {
    analytics.track(financingPage.repaymentTerms, financingAnalyticsEvents.installmentsErrorModalTryAgainClick, {
      ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
    });

    setContinueLoading(false);
    resetError();
    await setOption(payment.scheduledDate, chosenNumberOfInstallments);
  };

  const sortedInstallmentsOptions = useMemo(() => {
    if (!isEmpty(installmentsOptions) && !installmentOptionsLoading) {
      return orderBy(installmentsOptions.options, ['numberOfInstallments'], ['asc']);
    }

    return [];
  }, [installmentsOptions, installmentOptionsLoading]);

  const offers = useMemo(() => mapOffers(sortedInstallmentsOptions), [sortedInstallmentsOptions]);

  useEffect(() => {
    analytics.page(FINANCING_CATEGORY, financingPage.repaymentTerms, { payBillFlowUUID });
  }, [payBillFlowUUID]);

  useEffect(() => {
    if (!isEmpty(sortedInstallmentsOptions)) {
      analytics.track(financingPage.repaymentTerms, financingAnalyticsEvents.installmentsOptionsExposed, {
        offers,
        ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
      });
    }
  }, [sortedInstallmentsOptions, payBillFlowUUID, offers]);

  useRepaymentTermsPageData(setIsScheduledDateLoading);

  const onSelectDeliveryDate = (date: Date) => {
    const callback = (suggestedDate: Date) => {
      setOption(suggestedDate, installmentsOptions.options[0].numberOfInstallments);
    };
    onDeliveryDateChange(date, callback);
  };
  const continueButtonDisabled =
    !chosenNumberOfInstallments || ((isScheduledDateLoading || isSetInstallmentOptionLoading) && !isContinueLoading);

  return (
    <WizardLayout>
      <WizardLayout.TopBar onCancelClick={cancelFlow} onBackClick={cancelFlow} progressBarValue={50} />
      <WizardLayout.Content>
        <WizardLayout.Header>
          <Box textStyle="h1Semi" textAlign="center" mb={4} data-testid="financing-repayment-terms-title">
            <MIFormattedText label="financing.repaymentTerms.header.title" />
          </Box>
          <Box textStyle="body2" textAlign="center" mb={10}>
            <MIFormattedText
              label="financing.repaymentTerms.header.subtitle"
              values={{
                amount: convertNumberToCurrency(payment.amount),
              }}
            />
          </Box>
        </WizardLayout.Header>
        <WizardLayout.Body>
          {installmentOptionsLoading ? (
            <Flex justifyContent="center">
              <Loader color="primary" />
            </Flex>
          ) : (
            <>
              <InstallmentsErrorModal
                isOpen={error}
                onChangeFundingSource={handleChangeFundingSource}
                onTryAgain={handleTryAgain}
              />

              <Flex flexFlow="column" width="full">
                {(sortedInstallmentsOptions || []).map((option) => {
                  const isSelected = chosenNumberOfInstallments === option.numberOfInstallments;

                  const onInstallmentClick = () => {
                    if (!isSelected && !isContinueLoading) {
                      analytics.track(financingPage.installmentsSplit, financingAnalyticsEvents.optionClicked, {
                        numOfInstallments: option.numberOfInstallments,
                        offers: mapOffers(sortedInstallmentsOptions),
                        ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
                      });
                      selectInstallmentOption(option);
                    }
                  };

                  return (
                    <InstallmentOptionWithRepayment
                      key={option.numberOfInstallments}
                      option={option}
                      onInstallmentClick={onInstallmentClick}
                      isSelected={isSelected}
                      onDeliveryDateChange={onSelectDeliveryDate}
                      isInstallmentDatesLoading={isInstallmentsDatesLoading}
                      isDisabled={isContinueLoading}
                    />
                  );
                })}
              </Flex>
            </>
          )}
        </WizardLayout.Body>

        <Button
          label="financing.repaymentTerms.cta.continue"
          data-testid="financing-repayment-terms-continue-button"
          size={ButtonSizes.lg}
          onClick={handleContinue}
          width="100%"
          isLoading={isContinueLoading}
          disabled={continueButtonDisabled}
        />
        <Flex flexDirection="row" justifyContent="start" alignItems="center" w="full">
          <TermsAndConditions payBillFlowUUID={payBillFlowUUID} />
        </Flex>
      </WizardLayout.Content>
    </WizardLayout>
  );
};
