import { Avatar } from 'src/core/ds/avatar';
import { HStack } from 'src/core/ds/stack';
import { CompanyInfoType } from 'src/utils/types';
import { OrganizationInfoCard } from './OrganizationInfoCard';
import { singleOrganizationStyle } from './style';

type SingleOrganizationProps = Pick<CompanyInfoType, 'companyName' | 'city' | 'formattedAddress'>;

export const SingleOrganization = ({ companyName, city, formattedAddress }: SingleOrganizationProps) => (
  <HStack data-testId="single-organization-view" sx={singleOrganizationStyle} spacing={4}>
    <Avatar name={companyName as string} size="lg" textColor="white" />
    <OrganizationInfoCard companyDetails={`${companyName} - ${city}`} address={formattedAddress as string} />
  </HStack>
);
