import Flag from 'react-flagpack';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import Tooltip, { TooltipPropsType } from 'src/core/ds/tooltip';
import { getInternationalCountryCode } from 'src/pages/vendor/international-delivery-method/utils';

export type CurrencySuffixProps = {
  currency?: string;
  code?: string;
  overrideStyles?: Record<string, any>;
  tooltipProps?: TooltipPropsType;
  isDisabled?: boolean;
  size?: 'sm' | 'md';
  flagSize?: 'S' | 'M' | 'L';
};

export const CurrencySuffix = ({
  currency = 'currency.usd',
  code = 'US',
  overrideStyles,
  tooltipProps,
  isDisabled,
  flagSize,
  size = 'md',
}: CurrencySuffixProps) => {
  const countryCode = getInternationalCountryCode(code);
  const textStyle = size === 'md' ? 'body1' : 'body2';
  const flagSizeStyle = size === 'md' ? { width: '2.4rem', height: '1.8rem' } : { width: '2rem', height: '1.5rem' };

  const styles = {
    '* img': {
      ...flagSizeStyle,
    },
  };

  return (
    <Tooltip isDisabled={!tooltipProps} {...tooltipProps} data-testid={tooltipProps?.testId || ''}>
      <Flex cursor="auto" alignItems="center" data-testid="currency-suffix">
        <Box mr={2} {...flagSizeStyle} __css={{ ...styles, ...overrideStyles }}>
          <Flag code={countryCode} flagSize={flagSize} hasBorder={false} />
        </Box>
        <Box color={isDisabled ? 'grey.400' : 'grey.700'} textStyle={textStyle}>
          <MIFormattedText label={currency} />
        </Box>
      </Flex>
    </Tooltip>
  );
};
