import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { getJWTPayload } from 'src/helpers/jwt';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useApi } from 'src/hoc/useApi';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { GetVirtualCardDataProps, paymentsApi } from 'src/modules/payments/api';
import { paymentsStore } from 'src/modules/payments/payment-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { vendorLocations } from 'src/pages/vendor/locations';
import { virtualCardLocations } from 'src/pages/vendor/virtual-card/delivery-method/locations';
import { FetchFileResultType, PaymentType, VendorType, VirtualCardType } from 'src/utils/types';

type State = {
  virtualCard?: VirtualCardType;
  virtualCard4digitsArray?: string[];
};

export type VirtualCardPaymentState = {
  token: string;
  vendor: VendorType;
  payment: PaymentType;
  filesUrls: FetchFileResultType;
  organization: any;
  isPaymentLoading: boolean;
} & State;

export type VirtualCardPaymentProps = {
  token: string;
  virtualCardId: string;
};

export const useVirtualCardState = ({ token, virtualCardId }: VirtualCardPaymentProps) => {
  const history = useHistory();
  const [virtualCardState, setVirtualCardState] = useState<State>({});
  const { paymentId } = getJWTPayload(token);
  const payment = useSelector(paymentsStore.selectors.byId(paymentId));
  const organization = useSelector(organizationStore.selectors.byId(payment?.organization?.id));
  const fetchSelector = vendorsStore.selectors.fetch;
  const vendor = useSelector(fetchSelector.byId(payment?.vendor?.id));
  const { filesUrls } = useStructuredSelectors(paymentsStore.selectors.payment(payment?.id));
  const { isPaymentLoading } = useStructuredSelectors(paymentsStore.selectors.validation(paymentId));
  const { invalidTokenData } = useSelector(deliveryMethodsStore.selectors.validation);
  const paymentActions = useStoreActions(paymentsStore);

  const { onApiCall: getVirtualCardWithToken } = useApi<[GetVirtualCardDataProps], Record<string, any>>({
    api: paymentsApi.getVirtualCardData,
  });

  useEffect(() => {
    paymentActions
      .fetchPaymentDetailsWithToken({
        token,
        action: 'readVirtualCardDetails',
      })
      .catch(() => {
        history.push(generatePath(virtualCardLocations.invalidToken, { token }));
      });
  }, [paymentActions, token, history]);

  const initialState = useCallback(async () => {
    try {
      const response = await getVirtualCardWithToken({
        token,
        virtualCardId,
      });
      const { virtualCard } = response;
      setVirtualCardState({
        virtualCard,
        virtualCard4digitsArray: virtualCard?.cardNumber.match(/\d{4}/g),
      });
    } catch (e) {
      history.push(generatePath(virtualCardLocations.invalidToken, { token }));
    }
  }, [token, history, virtualCardId, getVirtualCardWithToken]);

  useEffect(() => {
    initialState();
  }, [initialState]);

  const enhancedState = useMemo<VirtualCardPaymentState>(
    () => ({
      ...virtualCardState,
      token,
      vendor,
      payment,
      filesUrls,
      organization,
      isPaymentLoading,
    }),
    [virtualCardState, token, vendor, payment, filesUrls, organization, isPaymentLoading]
  );

  useEffect(() => {
    if (!isEmpty(invalidTokenData)) {
      history.push(generatePath(vendorLocations.unilateral.invalidToken, { token }));
    }
  }, [invalidTokenData, token, history]);

  return [enhancedState];
};
