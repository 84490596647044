import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import organizationStore from 'src/modules/organizations/organizations-store';
import { getOrgId } from 'src/redux/user/selectors';

type ReturnValue = {
  UBOInfoDoesNotExists: boolean;
  getUBOInfo: () => void;
};

export const useUBO = (): ReturnValue => {
  const orgId = useSelector(getOrgId);
  const isUBOInfoExists = useSelector(organizationStore.selectors.isUBOInfoExists);
  const organizationsActions = useStoreActions(organizationStore);

  const getUBOInfo = () => organizationsActions.getUBOInfo({ orgId });

  return {
    UBOInfoDoesNotExists: !isUBOInfoExists,
    getUBOInfo,
  };
};
