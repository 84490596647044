import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { ItemizedList } from 'src/components/common/ItemizedList';
import { MICard, MICardForm, MICardHeader, MICardTitle } from 'src/components/common/MICard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { Button } from 'src/core/ds/button';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import { InvitationSummary } from 'src/modules/invitations/components/InvitationSummary';
import invitationsStore, { getInvitationActions } from 'src/modules/invitations/invitations-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { UserSummary } from 'src/modules/users/components/UserSummary';
import usersStore, { getUserActions } from 'src/modules/users/users-store';
import { DeleteCollaboratorModal } from 'src/pages/settings/ManageUsersSettingsPage/modals/DeleteCollaboratorModal';
import { useFetchUserOrgs } from 'src/pages/team/team-management/hooks/useFetchAllUserOrgs';
import { useFetchRelatedInvitations } from 'src/pages/team/team-management/hooks/useFetchRelatedInvitations';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { FULL_STORY_MASK_RULE_CLASS, RegistrationFlow, RegistrationOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import {
  InvitationActionType,
  InvitationType,
  InvitationWithRelatedInvitationsType,
  UserOrganization,
  UserPreferencesType,
} from 'src/utils/types';
import { getUserRole } from 'src/utils/user';
import { useGetInvitationActions } from '../../utils/invitation-actions';
import { useGetUserActions } from '../../utils/user-actions';
import { ChangeOwnerModal } from './modals/ChangeOwnerModal';
import { CreateInvitationModal } from './modals/CreateInvitationModal';
import { EditUserOrganizationModal } from './modals/EditUserOrganizationModal';

type User = {
  email: string;
  isEmailVerified: boolean;
  id: number;
  isIntuitLinked: boolean;
  isGuest: boolean;
  name?: string | null;
  orgId: number;
  orgName?: string | null;
  userPreferences?: UserPreferencesType | null;
  organizations: Record<string, any>[];
  registrationOrigin: RegistrationOrigin;
  registrationFlow: RegistrationFlow;
  firstName: string | null;
  lastName: string | null;
  userOrganizations: UserOrganization[];
};

type UserItemProps = {
  user: User;
  orgId: number;
  isMemberOfAccountingFirm?: boolean;
  isContributor?: boolean;
};

const UserItem = memo(({ user, orgId, isMemberOfAccountingFirm, isContributor }: UserItemProps) => {
  const [userIdToEdit] = useLocationState('userIdToEdit');
  const currentUser = useSelector(profileStore.selectors.profile);
  const userRole = getUserRole(user, orgId);
  const { isChanging } = useStructuredSelectors(usersStore.selectors.changeOwner(orgId));

  const [changeOwner, showChangeOwner] = useModal(ChangeOwnerModal, {
    userId: user.id,
    orgId,
    orgName: currentUser.orgName,
    isChanging,
    modalName: 'changeOwner',
  });
  const [EditUser, showEditUser] = useModal(EditUserOrganizationModal, {
    orgId,
    userId: user.id,
    userRole,
    modalName: 'editUser',
  });
  const [DeleteUser, showDeleteUser] = useModal(DeleteCollaboratorModal, {
    orgId,
    userId: user.id,
    userRole,
    userFullName: `${user.firstName} ${user.lastName}`,
    modalName: 'deleteUser',
  });

  const actions = useGetUserActions<Record<string, any>>({ orgId, user }, (actionType) => {
    if (actionType === 'transfer-ownership') {
      return {
        label: 'user.action.makeOwner',
        action: () => showChangeOwner({ userId: user.id }),
      };
    }

    if (actionType === 'edit') {
      const teamManagementLabel = isContributor
        ? 'user.action.teamManagement.editContributor'
        : 'user.action.teamManagement.editMember';

      return {
        label: isMemberOfAccountingFirm ? teamManagementLabel : 'user.action.edit',
        action: () => showEditUser({ userId: user.id }),
      };
    }

    if (actionType === 'delete') {
      return {
        label: isMemberOfAccountingFirm ? 'user.action.teamManagement.removeFromClient' : 'user.action.delete',
        negative: true,
        action: showDeleteUser,
      };
    }

    return null;
  });

  useEffect(() => {
    if (userIdToEdit === user.id) {
      showEditUser();
    }
  }, [user, userIdToEdit, showEditUser]);

  return (
    <div>
      {changeOwner}
      {EditUser}
      {DeleteUser}
      <UserSummary
        user={user}
        userRole={userRole}
        actions={isEmpty(actions) ? undefined : actions}
        details={
          <Box as="span" className={FULL_STORY_MASK_RULE_CLASS}>
            {user.email}
          </Box>
        }
      />
    </div>
  );
});

type InvitationItemProps = {
  invitation: InvitationType;
  orgId: number;
  isMemberOfAccountingFirm?: boolean;
};

const InvitationItem = memo(({ invitation, orgId, isMemberOfAccountingFirm }: InvitationItemProps) => {
  const invitationActions = getInvitationActions(useDispatch());
  const [DeleteInvitation, showDeleteInvitation] = useModal(CommonDialog, {
    confirm: () => invitationActions.delete({ orgId, id: invitation.id }),
    title: 'invitation.deleteDialog.title',
    description: 'invitation.deleteDialog.description',
    confirmText: 'invitation.deleteDialog.confirm',
    textValues: {
      fullName: `${invitation.firstName} ${invitation.lastName}`,
    },
  });
  const actions = useGetInvitationActions<InvitationActionType>({ invitation }, (actionType) => {
    if (actionType === 'resend') {
      return {
        label: 'invitation.action.resend',
        action: () => invitationActions.resend({ orgId, id: invitation.id }),
        negative: false,
      };
    }

    if (actionType === 'delete') {
      return {
        label: isMemberOfAccountingFirm ? 'invitation.action.teamManagement.cancel' : 'invitation.action.cancel',
        negative: true,
        action: showDeleteInvitation,
      };
    }

    return null;
  });

  return (
    <div>
      {DeleteInvitation}
      <InvitationSummary invitation={invitation} actions={actions} />
    </div>
  );
});

export function ManageUsersSettingsPage() {
  const currentUserId = useSelector(profileStore.selectors.getCurrentUserId);
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const userActions = getUserActions(useDispatch());
  const invitationActions = getInvitationActions(useDispatch());
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const accountingFirm = useSelector(profileStore.selectors.getFirstAccountingFirm);
  const approvalWorkflowsLoading = useSelector(approvalWorkflowsStore.selectors.list.status())?.loading;
  const teamManagementView = isMemberOfAccountingFirm;
  const userData = useStructuredSelectors(usersStore.selectors.org(orgId));
  const users = sortBy<User>(userData.users, [(user) => (user.id === currentUserId ? 0 : 1)]);

  const { otherContributors, teamMembers } = splitUsersToTeamMembersAndOtherContributors({
    users,
    firmOrgId: accountingFirm?.id,
  });

  const invitationData = useStructuredSelectors(invitationsStore.selectors.org(orgId));
  const {
    otherContributors: otherInvitations,
    teamMembers: teamMemberInvitations,
  } = splitInvitationsToTeamMembersAndOtherContributors({
    invitations: invitationData.invitations,
    firmOrgId: accountingFirm?.id,
  });

  const isLoading = userData.isLoading || invitationData.isLoading || approvalWorkflowsLoading;

  const [CreateInvitation, showCreateInvitation] = useModal(CreateInvitationModal, {
    modalName: 'invitationModal',
    orgId,
  });

  useEffect(() => {
    invitationActions.list({ orgId });
    userActions.list({ orgId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useFetchApprovalWorkflows({ orgId });

  useFetchRelatedInvitations({
    orgId,
    invitationIds: (invitationData.invitations || []).map((i) => i.id),
  });
  useFetchUserOrgs({ orgId, userIds: (users || []).map((user) => user.id) });

  if (isLoading) {
    return <AreaLoader />;
  }

  return (
    <MICard>
      {CreateInvitation}
      <MICardForm>
        <MICardHeader>
          <MICardTitle label="settings.manageUsers.cardTitle" />
          <Button
            label="settings.manageUsers.inviteUser"
            onClick={showCreateInvitation}
            leftIcon={<Icon name={IconNames.plus} size={IconSize.s} />}
          />
        </MICardHeader>
        {teamManagementView ? (
          <>
            {(teamMembers.length > 0 || teamMemberInvitations.length > 0) && (
              <UsersGroup label="settings.manageUsers.teamManagement.teamMembers">
                {teamMembers.map((user) => (
                  <UserItem user={user} orgId={orgId} key={user.id} isMemberOfAccountingFirm />
                ))}
                {teamMemberInvitations.map((i) => (
                  <InvitationItem invitation={i} orgId={orgId} key={i.id} isMemberOfAccountingFirm />
                ))}
              </UsersGroup>
            )}

            {(otherContributors.length > 0 || otherInvitations.length > 0) && (
              <UsersGroup label="settings.manageUsers.teamManagement.contributors">
                {otherContributors.map((user) => (
                  <UserItem user={user} orgId={orgId} key={user.id} isMemberOfAccountingFirm isContributor />
                ))}
                {otherInvitations.map((i) => (
                  <InvitationItem invitation={i} orgId={orgId} key={i.id} isMemberOfAccountingFirm />
                ))}
              </UsersGroup>
            )}
          </>
        ) : (
          <UsersGroup>
            {users.map((user) => (
              <UserItem user={user} orgId={orgId} key={user.id} />
            ))}
            {invitationData.invitations.map((i) => (
              <InvitationItem invitation={i} orgId={orgId} key={i.id} />
            ))}
          </UsersGroup>
        )}
      </MICardForm>
    </MICard>
  );
}

const UsersGroupContainer = styled.div`
  margin-top: 3rem;
`;

type GroupHeaderProps = {
  label: string;
};
const GroupHeader = ({ label }: GroupHeaderProps) => (
  <GroupHeaderContainer>
    <MIFormattedText label={label} />
  </GroupHeaderContainer>
);

const GroupHeaderContainer = styled.div`
  color: ${(props) => props.theme.text.color.subtitle};
  font-size: ${(props) => props.theme.text.size.sectionTitleM};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  line-height: ${(props) => props.theme.text.lineHeight.regular};
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  margin-bottom: 0.6rem;
`;

type UsersGroupProps = {
  label?: string;
  children?: any;
};
const UsersGroup = ({ children, label }: UsersGroupProps) => (
  <UsersGroupContainer>
    {label ? <GroupHeader label={label} /> : null}
    <ItemizedList>{children}</ItemizedList>
  </UsersGroupContainer>
);

const splitUsersToTeamMembersAndOtherContributors = ({ users, firmOrgId }: { users: User[]; firmOrgId?: number }) =>
  users.reduce<{ teamMembers: User[]; otherContributors: User[] }>(
    (all, user) => {
      if (user.userOrganizations?.some((uo) => uo.organizationId === firmOrgId)) {
        all.teamMembers.push(user);
      } else {
        all.otherContributors.push(user);
      }

      return all;
    },
    { teamMembers: [], otherContributors: [] }
  );
const splitInvitationsToTeamMembersAndOtherContributors = ({
  invitations,
  firmOrgId,
}: {
  invitations: InvitationWithRelatedInvitationsType[];
  firmOrgId?: number;
}) =>
  invitations.reduce<{
    teamMembers: InvitationType[];
    otherContributors: InvitationType[];
  }>(
    (all, invitation) => {
      if (invitation.relatedInvitations?.some((ri) => ri.organizationId === firmOrgId)) {
        all.teamMembers.push(invitation);
      } else {
        all.otherContributors.push(invitation);
      }

      return all;
    },
    { teamMembers: [], otherContributors: [] }
  );

function useFetchApprovalWorkflows({ orgId }) {
  const approvalWorkflowActions = useStoreActions(approvalWorkflowsStore);

  useEffect(() => {
    approvalWorkflowActions.list({ orgId });
  }, [approvalWorkflowActions, orgId]);
}
