/* eslint-disable prefer-arrow-callback,max-params */
import { Location } from 'history';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import isNumber from 'lodash/fp/isNumber';
import join from 'lodash/fp/join';
import keys from 'lodash/fp/keys';
import last from 'lodash/fp/last';
import omit from 'lodash/fp/omit';
import split from 'lodash/fp/split';
import take from 'lodash/fp/take';
import mapValues from 'lodash/mapValues';
import { parse } from 'qs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type ExternalNavRecovery<Steps extends string> = { recoveryTarget: Steps; shouldReplace: string };
type LocationState = Record<string, { stepIndex: number }> | undefined;
type PersistedState<Steps extends string> = {
  flowHistory: Steps[];
  historyLength: number;
  externalNavRecovery: ExternalNavRecovery<Steps> | null;
};

type PageUrl = string;
type PageUrlSuffix = string;

export type OnComplete = PageUrl | (() => void);

export const APP_INIT_URL_KEY = 'appInitUrl';

let isVerbose = false;

const log = (msg: string, ...args: unknown[]) => {
  if (isVerbose) {
    // eslint-disable-next-line no-console
    console.log(`%cuseWizard: ${msg}`, 'color: #737cdd', ...args);
  }
};

const getCurrentStepIndex = (location: Location, flowName: string): number => {
  const indexFromLocation = (location.state as LocationState)?.[flowName]?.stepIndex;

  return isNumber(indexFromLocation) ? indexFromLocation : -1;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NavigationMap<Steps extends string> = Record<Steps, (...args: any) => Steps | void>;

export interface UseWizardArgs<Steps extends string> {
  firstStep: Steps;
  locationsMap: Record<Steps, PageUrlSuffix>;
  cancelUrlFallback: PageUrl;
  flowName: string;
  navigationMap: NavigationMap<Steps>;
  verbose?: boolean;
}

type GoNextMap<Steps extends string, NM extends NavigationMap<Steps>> = {
  [K in Steps]: (goNextArgs?: { navArgs: Parameters<NM[K]>; shouldReplace?: boolean }) => void;
};

export interface UseWizardReturnType<Steps extends string, NM extends NavigationMap<Steps>> {
  goNextMap: GoNextMap<Steps, NM>;
  goBack: () => void;
  goBackToStep: (step: Steps) => void;
  completeFlow: (onComplete: OnComplete) => void;
  cancelFlow: () => void;
  currentStep: Steps;
  getExtNavReturnUrl: (targetStep: Steps, shouldReplace?: boolean) => string;
}

const buildTargetUrl = (currentStepLocation: string, newStepLocation: string, pathname: string) => {
  const sanitizedCurrentStepLocation = currentStepLocation.replace('/', '');
  const replaceCurrentStepWithNewStep = (pathParts: string[]): string[] => [
    ...take(pathParts.indexOf(sanitizedCurrentStepLocation), pathParts),
    newStepLocation,
  ];

  return flow(split('/'), replaceCurrentStepWithNewStep, join('/'))(pathname);
};

const getPersistedState = <Steps extends string>(
  flowName: string,
  resetToFirstStep: () => void
): PersistedState<Steps> | null => {
  try {
    const persistedStateString = window.sessionStorage.getItem(flowName);

    if (persistedStateString) {
      const persistedState = JSON.parse(persistedStateString);

      return persistedState as PersistedState<Steps>;
    }

    resetToFirstStep();
  } catch (e) {
    resetToFirstStep();
  }

  return null;
};

const getQueryExternalNavRecoveryTarget = <Steps extends string>(
  location: Location,
  locationsMap: UseWizardArgs<Steps>['locationsMap']
): null | ExternalNavRecovery<Steps> => {
  const { queryExternalNavRecoveryStep, shouldReplace } = (parse(location.search, {
    ignoreQueryPrefix: true,
  }) as unknown) as {
    queryExternalNavRecoveryStep: Steps;
    shouldReplace: string;
  };

  return queryExternalNavRecoveryStep && locationsMap[queryExternalNavRecoveryStep]
    ? { recoveryTarget: queryExternalNavRecoveryStep, shouldReplace }
    : null;
};

export const useWizard = <Steps extends string, NM extends NavigationMap<Steps>>({
  firstStep,
  locationsMap,
  flowName,
  cancelUrlFallback,
  navigationMap,
  verbose,
}: UseWizardArgs<Steps>): UseWizardReturnType<Steps, NM> => {
  isVerbose = !!verbose;
  const history = useHistory();
  const location = useLocation<LocationState>();
  const [onCompleteState, setOnCompleteState] = useState<OnComplete | null>(null);
  const [startedCancelFlowAction, setStartedCancelFlowAction] = useState<boolean>(false);

  const [flowHistory, setFlowHistory] = useState<Steps[]>([]);
  const [isFirstPageInApp, setIsFirstPageInApp] = useState<boolean>(false);

  const stepFromUrl = keys(locationsMap).find((step) => {
    const stepUrl = locationsMap[step as Steps];

    return location.pathname.includes(stepUrl);
  }) as Steps | undefined;
  const stepFromUrlLocation = stepFromUrl ? locationsMap[stepFromUrl] : '';

  const isFirstStepLocation = stepFromUrl === firstStep;
  const currentStepIndex = getCurrentStepIndex(location, flowName);

  const applyOnComplete = () => {
    if (!onCompleteState) {
      return;
    }

    window.sessionStorage.removeItem(flowName);
    log('Apply onComplete');

    if (typeof onCompleteState === 'string') {
      history.replace(onCompleteState, omit([flowName], location.state));
    } else {
      onCompleteState();
    }
  };

  useEffect(() => {
    const initialAppUrl = window.sessionStorage.getItem(APP_INIT_URL_KEY);
    setIsFirstPageInApp(!initialAppUrl || initialAppUrl === location.pathname);
    log('wizard mounted', {
      flowName,
      isFirstPageInApp: !initialAppUrl || initialAppUrl === location.pathname,
    });
  }, []);

  useEffect(
    function persistFlowHistoryToSessionStorage() {
      if (getExternalNavRecoveryTarget()) {
        return;
      }

      window.sessionStorage.setItem(flowName, JSON.stringify({ flowHistory, historyLength: history.length }));
    },
    [flowHistory]
  );

  const goBackToFirstStep = () => {
    if (currentStepIndex > 0) {
      history.go(-1 * currentStepIndex);
    }
  };

  const resetToFirstStep = () => {
    setFlowHistory([firstStep]);
    history.replace(locationsMap[firstStep], {
      ...location.state,
      [flowName]: { stepIndex: 0 },
    });
  };

  const getExternalNavRecoveryTarget = (): ExternalNavRecovery<Steps> | null => {
    const externalNavRecoveryRes = getQueryExternalNavRecoveryTarget(location, locationsMap);
    const storedExternalNavRecovery = getPersistedState<Steps>(flowName, resetToFirstStep)?.externalNavRecovery;

    if (externalNavRecoveryRes?.recoveryTarget && storedExternalNavRecovery?.recoveryTarget) {
      log('Found both search and session recovery targets, skipping recovery');

      return null;
    }

    if (externalNavRecoveryRes?.recoveryTarget) {
      log('found recoveryTarget in search param', {
        recoveryTarget: externalNavRecoveryRes.recoveryTarget,
        shouldReplace: externalNavRecoveryRes.shouldReplace,
      });

      return externalNavRecoveryRes;
    }

    if (storedExternalNavRecovery?.recoveryTarget) {
      log('found recoveryTarget in session storage', {
        recoveryTarget: storedExternalNavRecovery.recoveryTarget,
        shouldReplace: storedExternalNavRecovery.shouldReplace,
      });

      return storedExternalNavRecovery;
    }

    return null;
  };

  const returnToLastControlledStep = (
    persistedState: PersistedState<Steps>,
    recoveryTarget: Steps,
    shouldReplace: string
  ) => {
    const numStepsToGoBack = history.length - persistedState.historyLength;
    log('returning to last controlled step', {
      persistedLength: persistedState.historyLength,
      recoveryTarget,
      numStepsToGoBack,
      stepFromUrl,
    });
    window.sessionStorage.setItem(
      flowName,
      JSON.stringify({ ...persistedState, externalNavRecovery: { recoveryTarget, shouldReplace } })
    );
    history.go(-1 * numStepsToGoBack);
  };

  const completeExternalNavRecovery = (
    persistedState: PersistedState<Steps>,
    recoveryTarget: Steps,
    stepFromUrlArg: Steps,
    shouldReplace: string
  ) => {
    log('complete external nav recovery', {
      persistedState,
      recoveryTarget,
      stepFromUrlArg,
      shouldReplace,
    });
    window.sessionStorage.setItem(flowName, JSON.stringify({ ...persistedState, externalNavRecovery: null }));

    const nextStepLocation = buildTargetUrl(
      locationsMap[stepFromUrlArg],
      locationsMap[recoveryTarget],
      location.pathname
    );

    const takeAmount = shouldReplace === 'true' ? currentStepIndex : currentStepIndex + 1;
    const flowHistoryToSet = [...take(takeAmount, persistedState.flowHistory), recoveryTarget as Steps];
    setFlowHistory(flowHistoryToSet);

    if (shouldReplace === 'true') {
      log('Replacing url after external nav', {
        recoveryTarget,
        shouldReplace,
      });
      history.replace(nextStepLocation, {
        ...location.state,
        [flowName]: { stepIndex: currentStepIndex },
      });
    } else {
      history.push(nextStepLocation, {
        ...location.state,
        [flowName]: { stepIndex: currentStepIndex + 1 },
      });
    }
  };

  const handleExternalNavRecoveryFlow = (externalNavRecovery: ExternalNavRecovery<Steps>) => {
    const { recoveryTarget, shouldReplace } = externalNavRecovery;
    const persistedState = getPersistedState<Steps>(flowName, resetToFirstStep);

    if (!persistedState || !stepFromUrl || !recoveryTarget) {
      return;
    }

    log('Handle external nav recovery', {
      recoveryTarget,
      stepFromUrl,
      persistedState,
    });

    if (last(persistedState.flowHistory) !== stepFromUrl) {
      returnToLastControlledStep(persistedState, recoveryTarget, shouldReplace);
    } else if (stepFromUrl !== recoveryTarget) {
      completeExternalNavRecovery(persistedState, recoveryTarget, stepFromUrl, shouldReplace);
    }
  };

  useEffect(
    function handleInvalidStepsOrder() {
      const externalNavRecovery = getExternalNavRecoveryTarget();

      if (externalNavRecovery) {
        handleExternalNavRecoveryFlow(externalNavRecovery);

        return;
      }

      const missingLocationState = currentStepIndex < 0;
      const noMatchingStep = !stepFromUrl;

      if (onCompleteState || startedCancelFlowAction) {
        return;
      }

      if (noMatchingStep || missingLocationState || head(flowHistory) !== firstStep) {
        if (currentStepIndex > 0) {
          goBackToFirstStep();
        } else {
          resetToFirstStep();
        }
      } else if (currentStepIndex > flowHistory.length) {
        cancelFlow();
      }
    },
    [currentStepIndex, onCompleteState, stepFromUrl, firstStep, flowHistory]
  );

  const cancelFlow = useCallback(() => {
    setStartedCancelFlowAction(true);
  }, []);

  useEffect(
    function cancelFlowEffect() {
      if (!startedCancelFlowAction) {
        return;
      }

      window.sessionStorage.removeItem(flowName);

      if (isFirstPageInApp) {
        if (isFirstStepLocation) {
          history.replace(cancelUrlFallback, omit([flowName], location.state));
        } else {
          history.go(-1 * currentStepIndex);
        }
      } else {
        const stepsFromEntryPage = currentStepIndex + 1;
        history.go(-1 * stepsFromEntryPage);
      }
    },
    [startedCancelFlowAction, isFirstPageInApp, isFirstStepLocation]
  );

  const completeFlow = useCallback(
    (onComplete: OnComplete) => {
      if (currentStepIndex < 0) {
        throw new Error(`tried to complete flow ${flowName} without currentStepIndex`);
      }

      log('completeFlow called');
      setOnCompleteState(onComplete);
    },
    [location, flowName, currentStepIndex]
  );

  useEffect(
    function completeFlowEffect() {
      if (!onCompleteState) {
        return;
      }

      if (isFirstStepLocation) {
        applyOnComplete();
      } else {
        log('Complete navigating back to entry page', {
          stepsCount: -1 * currentStepIndex,
        });

        history.go(-1 * currentStepIndex);
      }
    },
    [onCompleteState, isFirstStepLocation, currentStepIndex]
  );

  const goToStep = useCallback(
    (nextStep: Steps, shouldReplace?: boolean) => {
      const persistedState = getPersistedState<Steps>(flowName, resetToFirstStep);
      const lastPersistedStep = last(persistedState?.flowHistory);
      const currentStep = persistedState?.flowHistory && persistedState.flowHistory[currentStepIndex];

      if (!stepFromUrlLocation || currentStepIndex < 0 || currentStep === nextStep) {
        return;
      }

      log('navigating to step', {
        nextStep,
        shouldReplace,
        lastPersistedStep,
        currentStep,
      });

      const nextStepLocation = buildTargetUrl(stepFromUrlLocation, locationsMap[nextStep], location.pathname);

      if (shouldReplace === true) {
        setFlowHistory((flowHistory) => [...take(currentStepIndex, flowHistory), nextStep]);
        history.replace(nextStepLocation, {
          ...location.state,
          [flowName]: { stepIndex: currentStepIndex + 1 },
        });
      } else {
        setFlowHistory((flowHistory) => [...take(currentStepIndex + 1, flowHistory), nextStep]);
        history.push(nextStepLocation, {
          ...location.state,
          [flowName]: { stepIndex: currentStepIndex + 1 },
        });
      }
    },
    [currentStepIndex, stepFromUrlLocation, history, flowName, locationsMap, location.pathname, location.state]
  );

  const goNextMap = useMemo(
    () =>
      (mapValues(
        navigationMap,
        <K extends Steps>(nextStepCallback: NM[K]) => (goNextArgs?: {
          navArgs: Parameters<NM[K]>;
          shouldReplace?: boolean;
        }) => {
          const navArgs = (goNextArgs?.navArgs || []) as [];
          const nextStep = nextStepCallback(...navArgs);

          if (nextStep) {
            goToStep(nextStep, goNextArgs?.shouldReplace);
          }
        }
      ) as unknown) as GoNextMap<Steps, typeof navigationMap>,
    [navigationMap, goToStep]
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goBackToStep = useCallback(
    (targetStep: Steps) => {
      const stepIndex = flowHistory.findIndex((step) => step === targetStep);

      if (!locationsMap[targetStep]) {
        throw new Error(
          `tried to go back to a step that is not mapped to a location - ${targetStep}. Flow name: ${flowName}`
        );
      }

      if (stepIndex < 0) {
        throw new Error(`Can not go back to a step that was not visited - ${targetStep}. Flow name: ${flowName}`);
      }

      const stepsToGoBack = currentStepIndex - stepIndex;
      history.go(-1 * stepsToGoBack);
    },
    [currentStepIndex, flowHistory, flowName, locationsMap, history]
  );

  const getExtNavReturnUrl = useCallback(
    (targetStep: Steps, shouldReplace?: boolean) => {
      if (!stepFromUrlLocation) {
        throw new Error(`Can't create return url while no matching step for current location`);
      }

      const resultPath = buildTargetUrl(stepFromUrlLocation, locationsMap[targetStep], location.pathname);
      const search = `queryExternalNavRecoveryStep=${targetStep}&shouldReplace=${!!shouldReplace}`;
      const result = `${resultPath}?${search}`;

      log('created ext nav return url', {
        url: result,
        shouldReplace,
      });

      return result;
    },
    [stepFromUrlLocation, locationsMap, location.pathname]
  );

  return {
    completeFlow,
    cancelFlow,
    currentStep: stepFromUrl || firstStep,
    goNextMap,
    goBack,
    goBackToStep,
    getExtNavReturnUrl,
  };
};

export const useInitWizard = () => {
  useEffect(() => {
    if (!window.sessionStorage.getItem(APP_INIT_URL_KEY)) {
      window.sessionStorage.setItem(APP_INIT_URL_KEY, window.location.pathname);
    }
  }, []);
};
