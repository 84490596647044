import { Action, Dispatch } from 'redux';
import { analytics } from 'src/services/analytics';

export function reduxAnalyticsMiddleware() {
  return (next: Dispatch) => (action: Action) => {
    analytics.trackAction(action.type, action);

    return next(action);
  };
}
