import { useSelector } from 'react-redux';
import { useIsAccountingFirm } from 'src/hooks/accountants/useIsAccountingFirm';
import { profileStore } from 'src/modules/profile/profile-store';
import { CompanyType } from 'src/utils/consts';
import { CompanyInfoType } from 'src/utils/types';

export function useFirmClients(): CompanyInfoType[] {
  const isFirm = useIsAccountingFirm();
  const firmOrganizations = useSelector(profileStore.selectors.getOrganizations);

  if (!isFirm) return [];

  return firmOrganizations.filter((org) => org.companyType !== CompanyType.ACCOUNTING_FIRM);
}
