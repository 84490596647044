export enum FeatureFlags {
  ExistingUsersMsnOnboarding = 'enable-existing-users-in-msn-onboarding',
  AprovalWorkflows = 'approval-workflows',
  TabapayMigration = 'tabapay-in-migration',
  HideClients = 'hide-clients',
  USHolidaysChecks = 'meliocom-us-holidays-checks',
  CreditCardFirst = 'credit-card-first',
  BillersVisibilityExisting = 'billers-visiblity-existing',
  SwitchToZendeskChat = 'switch-to-zendesk-chat',
  ZendeskMessaging = 'zendesk-messaging-in-melio-com',
  ZendeskMessagingAuthentication = 'zendesk-messaging-authentication-in-melio-com',
  BatchAmexMccPay = 'batch-amex-mcc-pay',
  AmexVendorAddress = 'meliocom-amex-vendor-address',
  AmexVendorAddressBatchFlow = 'meliocom-amex-vendor-address-batch-flow',
  EmailInMemo = 'email-in-memo',
  AttachInvoiceFile = 'attach-invoice-file',
  IncludeInProgress = 'include-in-progress',
  InvoiceGeneration = 'invoice-generation',
  IsPlaidInUpgrade = 'is-plaid-in-upgrade',
  PayVendorsMenu = 'pay-vendors-menu',
  AccountantAddClientLegalInfo = 'accountants-client-onboarding-legal-info-page',
  MsnMvp1 = 'msn-mvp-1',
  FirmAsVendor = 'firm-as-vendor',
  ImportClientsQboa = 'import-clients-qboa',
  selfServeRefund = 'self-serve-refund',
  BulkPayment = 'bulk-payment',
  BillInbox = 'bill-inbox',
  BanksCutoffTimes = 'banks-cutoff-times',
  BatchApprove = 'more-batch-actions-approve',
  CardVaultingPull = 'card-vaulting-pull-behavior',
  CardVaultingPush = 'card-vaulting-push-behavior',
  MsnUpdateDeliveryMethod = 'msn-enable-update-delivery-method-with-verification',
  InternationalHighRiskCountries = 'international-payments-high-risk-countries',
  MicroDepositOptimizations = 'micro-deposit-optimizations',
  FastAchBanner = 'fast-ach-banner',
  MelioComAPFinancingEnabled = 'meliocom-ap-financing-enabled',
  BillingMethodCheckFee = 'billing-method-check-fee',
  BusinessDirectorySearch = 'business-directory-search',
  FastAchBetterExposeEta = 'fast-ach-better-expose-eta-in-the-date-picker',
  FastACHReviewAndConfirmEntryPoint = 'fast-ach-edit-delivery-date-experiment',
  EnableRescheduleDeclinedApprovalBulkPayment = 'enable-reschedule-declined-approval-bulk-payment',
  FastCheckEnabledForAllStates = 'fast-check-enabled-for-all-states',
  SetDefaultValueLastSevenDaysPaidTab = 'show-default-value-last-seven-days-paid-tab',
  FeedbackWidget = 'feedback-widget-meliocom',
  MsnIncomingDashboardEnabled = 'msn-incoming-dashboard-enabled',
  MelioToPlatform = 'melio-to-platform',
  melioMigrationWhitelistToBlacklist = 'melio-migration-whitelist-to-blacklist',
  SplitContactsTab = 'split-contacts-tab',
  FinancingRetryFlows = 'financing-retry-flows',
  FinancingRetryFailedToDeliver = 'financing-retry-failed-to-deliver',
  DisableSelfRecoveryFlows = 'melio-disable-self-recovery-flows',
  GeneralCheckDelays = 'general-check-delays',
  FastCheckEnabled = 'meliocom-fast-check-enabled',
  EnableMastercardFSForInternational = 'meliocom-international-mastercard',
  FinancingRecoveryFlowsQa = 'financing-recovery-flows-qa',
  BatchUncombined = 'meliocom-batch-uncombined',
  ShouldUseEtaApiServiceForDeliveryDate = 'should-use-eta-api-service-for-delivery-date',
  SignInMfa = 'sign-in-mfa',
  IsSpendManagementEnabled = 'is-spend-management-enabled',
  VendorUpgradePaymentToFACH = 'meliocom-p2fach-web',
  ShowDisabledInstallmentsIfNotEligible = 'meliocom-installments-fallback-visibility',
  VendorUpgradePaymentToRTP = 'meliocom-p2rtp-web',
  NewDashboardEnabled = 'platform-enable-new-experience',
  QboMultiOrgOnboardingChangesOnMeliocom = 'qbo-multi-org-onboarding-changes-on-meliocom',
  InviteOwnerOfAccountingFirmClient = 'meliocom-invite-owner-of-accounting-firm-client',
  GuestPayorFlowLandingPageWhyMelio = 'guest-payor-flow-landing-page-why-melio',
  GuestPayorFlowRemoveScreensPhase1 = 'guest-payor-flow-remove-screens-phase1',
  GuestPayorFlowCombineSuccessScreen = 'guest-payor-flow-combine-success-screen',
  PlatformMtlKycUplift = 'platform-mtl-kyc-uplift',
  AndroidAppPromotionEnabled = 'android-app-promotion-enabled',
  IosAppPromotionEnabled = 'ios-app-promotion-enabled',
  GrowthRegisterPageExperiment = 'growth-register-page-experiment',
  IsGetPaidMtlCheckEnabled = 'is-get-paid-mtl-check-enabled',
  IsNewPaymentRequestFulfillmentFlow = 'is-new-payment-request-fulfillment-flow',
  PlatformAccountantsNewFlow = 'platform-accountants-new-flow',
  EnableGetPaidDeferredOnboarding = 'enable-get-paid-deferred-onboarding',
  isArProcessingFeeEnabled = 'is-ar-processing-fee-enabled',
  PlaidMicroDepositsGetPaidEnabled = 'plaid-micro-deposits-get-paid',
  EnableGetPaidUnifiedOnboarding = 'enable-get-paid-unified-onboarding',
  getArProcessingFeeNotificationForExistingOrganizationReleaseDateInMilliseconds = 'get-ar-processing-fee-notification-for-existing-organization-release-date-in-milliseconds',
}
