import { featureFlags } from '@melio/shared-web';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useBreak } from 'src/hoc';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import useUpdateUserPreferences from 'src/modules/users/hooks/useUpdateUserPreferences';
import { billLocations } from 'src/pages/bill/locations';
import { getUserPreferences } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { FeatureFlags, UserPreferencesKey } from 'src/utils/consts';
import { UserPreferencesType } from 'src/utils/types';
import { BillersVisibilityModalMessage } from './BillersVisibilityModalMessage';

export function BillersVisiblityModal() {
  const { isUpdatingUserPreference, updateUserPreference } = useUpdateUserPreferences();
  const userPreferences: UserPreferencesType = useSelector(getUserPreferences);
  const [billersVisibiltyFlagValue] = featureFlags.useFeature(FeatureFlags.BillersVisibilityExisting, 'OFF');
  const billersVisibiltyEnabled = billersVisibiltyFlagValue && billersVisibiltyFlagValue !== 'OFF';
  const [historyPush] = useHistoryWithOrgId();
  const { isMobile } = useBreak();

  const show =
    billersVisibiltyEnabled && !isMobile && !isUpdatingUserPreference && !userPreferences.shownBillerVisibilityModal;

  useEffect(() => {
    if (show) {
      analytics.track(`billers-modal`, 'billers-modal-shown');
    }
  }, [show]);
  function onClose() {
    if (show) {
      updateUserPreference({ key: UserPreferencesKey.ShownBillerVisibilityModal, value: 'true' });
    }
  }
  function onNewBill() {
    if (show) {
      updateUserPreference({ key: UserPreferencesKey.ShownBillerVisibilityModal, value: 'true' });
    }

    historyPush({
      path: billLocations.create.index,
      state: { manually: true },
    });
  }

  return show ? <BillersVisibilityModalMessage onClose={onClose} onNewBill={onNewBill} /> : null;
}
