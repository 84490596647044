import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MINotices } from 'src/components/common/MINotices';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box/Box';
import { MaskField } from 'src/core/ds/form/fields';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { EMAIL_SOURCE } from 'src/pages/funding-sources/consts';
import { fundingSourcesLocations } from 'src/pages/funding-sources/locations';
import NotFoundPage from 'src/pages/general/components/NotFoundPage';
import { Resolutions as SUPPORT } from 'src/pages/settings/hooks/microDepositsCommon';
import { analytics } from 'src/services/analytics';
import { isMicroDepositValueAllowed } from 'src/utils/currency-utils';
import { handleInputChange } from 'src/utils/input';
import { parseQueryString } from 'src/utils/query-utils';
import { useFundingSourceMicroDepositState } from '../settings/hooks/useFundingSourceMicroDeposits';

const eventPage = 'micro-deposits-verification-page';
const amountFormat = '$0.##';

export const MicroDepositsVerifyPage = () => {
  const [historyPush] = useHistoryWithOrgId();
  const location = useLocation();
  const match = useRouteMatch<{ id: string; token: string }>();
  const { id, token } = match.params;
  const query = parseQueryString(location.search);
  const { source, emailTag } = query;

  const fundingSourceMicroDepositProps = {
    isEmbedded: source === EMAIL_SOURCE,
    fundingSourceId: parseFloat(id),
    token,
    onSuccess: () => historyPush({ path: fundingSourcesLocations.microDepositsSuccess, params: { id } }),
    onBlocked: () => historyPush({ path: fundingSourcesLocations.microDepositsBlocked, params: { id } }),
  };

  const [state, actions]: any = useFundingSourceMicroDepositState(eventPage, fundingSourceMicroDepositProps);
  const { onSubmit, onChange } = actions;

  const onInputChange = ({ event }: { event: React.SyntheticEvent<HTMLInputElement> }) => {
    handleInputChange({ event, onChange });
  };

  useEffect(() => {
    analytics.page(
      'funding-sources',
      source === EMAIL_SOURCE ? 'micro-payments-email' : 'micro-payments',
      source === EMAIL_SOURCE ? { emailTag, fundingSourceId: id } : undefined
    );
  }, []);

  if (state.resolution === SUPPORT.NOT_FOUND) {
    return <NotFoundPage />;
  }

  if (state.isLoading) {
    return <AreaLoader />;
  }

  return (
    <StepLayoutPage
      title="fundingSources.microDeposits.title"
      subtitle="settings.microDeposits.verifyDialog.guestSubtitle"
      onNext={onSubmit}
      nextLabel="settings.microDeposits.verifyDialog.confirm"
      isLoading={state.isLoading}
      fullWidthCTA
      hideHeader
      subTitleValues={{ companyName: state.companyName }}
    >
      <WizardElements.WizardFormRow>
        <Box w="full" mr={5}>
          <MaskField
            id="amount1"
            label="settings.microDeposits.verifyDialog.sum1Label"
            placeholder="settings.microDeposits.verifyDialog.placeholder"
            value={state.amount1}
            autoFocus
            isRequired
            type="text"
            errorMessage={state.validationErrors.amount1}
            size="sm"
            onChange={onInputChange}
            isAllowed={isMicroDepositValueAllowed}
            allowEmptyFormatting
            format={amountFormat}
          />
        </Box>
        <Box w="full">
          <MaskField
            id="amount2"
            label="settings.microDeposits.verifyDialog.sum2Label"
            placeholder="settings.microDeposits.verifyDialog.placeholder"
            value={state.amount2}
            isRequired
            type="text"
            errorMessage={state.validationErrors.amount2}
            size="sm"
            onChange={onInputChange}
            isAllowed={isMicroDepositValueAllowed}
            allowEmptyFormatting
            format={amountFormat}
          />
        </Box>
      </WizardElements.WizardFormRow>
      <Box>{state.serverError && <MINotices size="wizard" errorMessage={state.serverError} align="center" />}</Box>
    </StepLayoutPage>
  );
};
