import filter from 'lodash/filter';
import get from 'lodash/get';
import { generatePath } from 'react-router-dom';
import { createRestfulSlice, hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { BILLS_LIST_INDEX_SUCCESS } from 'src/modules/bills/bills-actions';
import {
  DELIVERY_METHOD_COPY_FROM_OWNED_VENDOR_WITH_UNILATERAL_TOKEN_SUCCESS,
  DELIVERY_METHOD_REPLACE_VIRTUAL_SUCCESS,
  DELIVERY_METHOD_SHIFT_VIRTUAL_CARD_PAYMENT_TO_ACH_SUCCESS,
  DELIVERY_METHOD_UPDATE_UNILATERAL_WITH_TOKEN_SUCCESS,
} from 'src/modules/delivery-methods/delivery-method-actions';
import { refundPaymentSlice } from 'src/modules/payments/refund-payment-slice';
import { billLocations } from 'src/pages/bill/locations';
import { history } from 'src/providers/AppProviders';
import { DeliveryType, RefundReason } from 'src/utils/consts';
import { paymentsApi } from './api';
import { batchSetApprovalDecisionSlice } from './batch-set-approval-decision.slice';
import { markPaymentAsPaidSlice, markPaymentAsUnpaidSlice } from './payments-mark-as-paid-slice';
import {
  approvePaymentSlice,
  declinePaymentSlice,
  fetchEmailToVendorDetails,
  fetchPaymentDetailsWithToken,
  fetchPaymentUpsellDetailsSlice,
  getDeliveryTimeSlice,
  updatePaymentWithToken,
} from './payments-slice';

export const name = 'payments';

export const PAY_STATUS_CONSTS = {
  ALL_PASSED: 'allPassed',
  ALL_FAILED: 'allFailed',
  SOME_FAILED: 'someFailed',
};

export const paymentsStore = createRestfulSlice({
  name,
  api: {
    list: (params) => paymentsApi.list(params).then(({ objects, totalCount }) => ({ items: objects, totalCount })),
    delete: (params) => paymentsApi.deletePaymentById(params.orgId, params.id),
    fetch: ({ orgId, id }: { orgId: number; id: string }) =>
      paymentsApi.getPaymentById(orgId, id).then(({ object }) => object),
  },
  initialState: {},
  selectors: {
    approvalDecisionStatus: (state) => state[name].approvalDecision,
    // BPSP - Bill Pay Service Provider: (fs.fundingType === 'card' && !fs.isVerified)
    getUserFundingSources: (state) =>
      filter(state.user.fundingSources, (fs) => !(fs.fundingType === 'card' && !fs.isVerified)),
    getPaymentLoading: (state) => get(state, 'payments.meta.batchPayments.save.status.loading', false),
    byId: (paymentId) => (state) => state[name].byId[paymentId],
    payment(paymentId) {
      const paymentSelectors = {
        filesUrls(state) {
          return state[name].meta[paymentId]?.filesUrls;
        },
        fee(state) {
          return state[name].meta[paymentId]?.fee;
        },
        deliveryDates(state) {
          return state[name].meta[paymentId]?.deliveryDates;
        },
        deliveryMethodExist(state) {
          return state[name].meta[paymentId]?.deliveryMethodExist;
        },
        upsellItems(state) {
          return state[name].meta[paymentId]?.upsellItems;
        },
      };

      return paymentSelectors;
    },
    validation(paymentId) {
      const validationData = {
        isPaymentLoading(state) {
          return state[name].meta[paymentId]?.loading;
        },
        isPaymentLoadFinished(state) {
          return state[name].meta[paymentId]?.isLoadFinished;
        },
        errorData(state) {
          return state[name].meta[paymentId]?.error;
        },
      };

      return validationData;
    },
  },
  slices: {
    approvePaymentSlice,
    declinePaymentSlice,
    getDeliveryTimeSlice,
    fetchPaymentDetailsWithToken,
    fetchPaymentUpsellDetails: fetchPaymentUpsellDetailsSlice,
    fetchEmailToVendorDetails,
    updatePaymentWithToken,
    markPaymentAsPaid: markPaymentAsPaidSlice,
    batchSetApprovalDecision: batchSetApprovalDecisionSlice,
    markPaymentAsUnpaid: markPaymentAsUnpaidSlice,
    refundPayment: refundPaymentSlice,
  },
  extraReducers: {
    [DELIVERY_METHOD_REPLACE_VIRTUAL_SUCCESS](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [DELIVERY_METHOD_COPY_FROM_OWNED_VENDOR_WITH_UNILATERAL_TOKEN_SUCCESS](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [DELIVERY_METHOD_UPDATE_UNILATERAL_WITH_TOKEN_SUCCESS](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [DELIVERY_METHOD_SHIFT_VIRTUAL_CARD_PAYMENT_TO_ACH_SUCCESS](state, action) {
      virtualDeliveryMethodReplaceSuccessHandler(state, action);
    },
    [BILLS_LIST_INDEX_SUCCESS]: (state, action) => {
      const { payments, paymentsTotalCount } = action.payload;
      const key = hashListKey(action.meta.identifier);
      state.lists[key] = {
        loading: false,
        order: payments.map((e) => e.id),
        totalCount: paymentsTotalCount,
      };
      payments.forEach((item) => {
        state.byId[item.id] = item;
      });
    },
  },
});

function virtualDeliveryMethodReplaceSuccessHandler(state, action) {
  const paymentId = action?.meta?.identifier?.paymentId;

  if (paymentId) {
    const deliveryType = action?.payload?.deliveryType;
    const { achDates, checkDates } = state.meta[paymentId].deliveryDates;
    const dates = deliveryType === DeliveryType.CHECK ? checkDates : achDates;
    state.byId[paymentId].deliveryMethodId = action.payload.id;
    state.byId[paymentId].deliveryEta = dates.deliveryDate;
    state.byId[paymentId].maxDeliveryEta = dates.maxDeliveryDate;
  }
}

export type PaymentParamsType = {
  orgId: number;
  id: string;
  reason?: string | null;
};

export function getPaymentsActions(dispatch: any) {
  return {
    approvePayment: (params: PaymentParamsType) => dispatch(paymentsStore.actions.approvePaymentSlice(params)),
    declinePayment: (params: PaymentParamsType) => dispatch(paymentsStore.actions.declinePaymentSlice(params)),
    startSinglePaymentFlow: ({
      billId,
      orgId,
      nextLocationState,
    }: {
      billId: string;
      orgId: number;
      nextLocationState?: unknown;
    }) => {
      history.push(generatePath(billLocations.pay.funding, { billId, orgId }), nextLocationState);
    },
    markAsPaid: (params: {
      orgId: number;
      id: number;
      accountingPlatformAccountId?: string | null;
      createOrigin?: string;
    }) => dispatch(paymentsStore.actions.markPaymentAsPaid(params)),
    markAsUnpaid: (params: { orgId: number; id: number; billId: string }) =>
      dispatch(paymentsStore.actions.markPaymentAsUnpaid(params)),
    delete: (params: { orgId: number; id: number }) => dispatch(paymentsStore.actions.delete(params)),
    refund: (params: { orgId: number; paymentId: number; reason: RefundReason }) =>
      dispatch(paymentsStore.actions.refundPayment(params)),
  };
}
