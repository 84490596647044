import { useCallback, useEffect, useRef } from 'react';

export function useCallbackRef<T extends (...args: any[]) => any>(fn?: T): T {
  const ref = useRef(fn);
  useEffect(() => {
    ref.current = fn;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(((...args) => ref.current?.(...args)) as T, []);
}

export function useInterval(callback: () => void, delay: number | null) {
  const fn = useCallbackRef(callback);
  useEffect(() => {
    let intervalId: number | null = null;
    const tick = () => fn();

    if (delay !== null) {
      intervalId = window.setInterval(tick, delay);
    }

    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [delay, fn]);
}
