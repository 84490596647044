import { GetProListItemType } from 'src/components/shared/tables/PaymentsTable/types';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { proCancelPendingSlice } from './cancel-pending-slice';
import { name } from './consts';
import { proInProgressPaymentsDeleteSlice } from './get-pro-in-progress-payments-delete';
import { proInProgressPaymentsFetchSlice } from './get-pro-in-progress-payments-fetch-slice';
import { proInProgressPaymentsIsListRefreshableSlice } from './get-pro-in-progress-payments-is-list-refreshable';

export const getProInProgressPaymentsStore = createRestfulSlice<GetProListItemType>({
  api: getProApi.inProgress,
  name,
  slices: {
    proIsListRefreshable: proInProgressPaymentsIsListRefreshableSlice,
    proDelete: proInProgressPaymentsDeleteSlice,
    proFetch: proInProgressPaymentsFetchSlice,
    proCancelPending: proCancelPendingSlice,
  },
  selectors: {
    batchItems: () => () => [],
  },
});
