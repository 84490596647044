import IdleTimer from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { reportSessionAnalyticsEvent } from 'src/components/layout/SessionExpired/utils';
import config from 'src/config';
import { authApi } from 'src/modules/auth/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { profileStore } from 'src/modules/profile/profile-store';
import { authLocations } from 'src/pages/auth/locations';
import { useLoginFlowType } from 'src/pages/auth/multi-factor-authentication/hooks/useLoginFlowType';
import { clearUserInfoAction } from 'src/redux/user/actions';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { UserContextType } from 'src/utils/types';

export const SessionExpired = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();
  const { clearLoginFlowType } = useLoginFlowType();
  const currentUser = useSelector<any, UserContextType>(profileStore.selectors.profile);
  const [historyPush] = useHistoryWithOrgId();

  const handleOnIdle = () => {
    if (document.visibilityState === 'visible') {
      sessionStorage.setItem('isSessionExpiredActiveTab', 'true');
    }

    if (currentUser) {
      reportSessionAnalyticsEvent(currentUser.id, currentUser.orgId, 'analytics_melio_pay_session_expired');
    }

    analytics.track('login', 'session-expired');

    authApi.logout().then(() => {
      dispatch(clearUserInfoAction());
      clearLoginFlowType();
      historyPush({ path: authLocations.login });
    });
  };

  return (
    <>
      {isLoggedIn && (
        <IdleTimer
          onIdle={handleOnIdle}
          timeout={config.sessionExpired.timeout}
          crossTab={{
            type: 'localStorage',
            emitOnAllTabs: true,
            channelName: 'idle-timer',
          }}
        />
      )}
    </>
  );
};
