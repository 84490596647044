import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Box from 'src/core/ds/box';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import customersStore from 'src/modules/customers/customers-store';
import { Tag, TagCloseButton, TagLabel } from 'src/pages/get-pro/components/tag';
import { getOrgId } from 'src/redux/user/selectors';
import { useQueryState } from 'src/utils/hooks';
import { ContactType } from 'src/utils/types';

const ContactTag = () => {
  const [contactId, setContactId] = useQueryState('contactId');
  const customerActions = useStoreActions(customersStore);
  const orgId = useSelector(getOrgId);
  const customer = useSelector<any, ContactType>(customersStore.selectors.fetch.byId(contactId));
  const contactName = useMemo(() => customer?.companyName, [customer]);

  useEffect(() => {
    if (contactId && !customer) {
      customerActions.fetch({ id: contactId, orgId });
    }
  }, [contactId, customer, customerActions, orgId]);

  const onClose = () => setContactId(undefined);

  if (!customer || !contactId) {
    return null;
  }

  return (
    <Box ml={3} mt="auto" mb="auto">
      <Tag>
        <TagLabel>{contactName}</TagLabel>
        <TagCloseButton onClick={onClose} />
      </Tag>
    </Box>
  );
};
export default ContactTag;
