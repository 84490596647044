import isString from 'lodash/isString';
import trim from 'lodash/trim';
import { ReactElement } from 'react';
import { FormatDateOptions } from 'react-intl';
import { MIFormattedDate } from 'src/components/common/MIFormattedDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import { PasswordInput } from 'src/core/ds/input';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type ValueType = ReactElement | string | number | Date | null | undefined;

type MIFieldOrEmptyProps = {
  value: ValueType;
  type?: string;
  label: string;
  color?: string;
  hideValue?: boolean;
  privateData?: boolean;
} & FormatDateOptions;

export const MIFieldOrEmpty = ({ value, type, label, color, hideValue, privateData, ...rest }: MIFieldOrEmptyProps) => {
  let outputValue: ValueType = value;
  let testValue = value;

  if (type === 'date') {
    outputValue = <MIFormattedDate date={testValue as Date} {...rest} />;
  }

  if (isString(testValue)) {
    testValue = trim(testValue);
  }

  const isEmptyValue = (testValue !== 0 && !testValue) || testValue === ''; // the number 0 is an actual value;

  const renderValue = () =>
    hideValue && isString(testValue) ? (
      <PasswordInput size="sm" isViewOnly isRequired value={testValue} isReadOnly />
    ) : (
      <Box
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        color={color || 'black'}
        className={privateData ? FULL_STORY_MASK_RULE_CLASS : undefined}
      >
        {outputValue}
      </Box>
    );

  return (
    <>
      {!isEmptyValue ? (
        renderValue()
      ) : (
        <Box color="grey.400">
          <MIFormattedText label={label} privateData={privateData} />
        </Box>
      )}
    </>
  );
};
