import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';

const DaySlotLabelText = ({
  labelText,
  textStyle,
  dataTestId,
}: {
  labelText: string;
  textStyle?: string;
  dataTestId?: string;
}) => (
  <Flex
    justify="center"
    pos="absolute"
    color="black"
    textTransform="none"
    textAlign="center"
    w="full"
    top={0.5}
    lineHeight={1.4}
    textStyle={textStyle}
    data-testid={dataTestId}
  >
    <MIFormattedText label={labelText} />
  </Flex>
);

export const DaySlotLabel = ({ isDeductionDate, isDueDate, isDeliveryDate }) => (
  <Box pos="relative" textStyle="caption1">
    {isDeductionDate && !isDeliveryDate ? (
      <DaySlotLabelText labelText="bills.pay.date.daySlotLabel.deduct" dataTestId="day-slot-label-deduct" />
    ) : null}
    {isDueDate && !isDeductionDate && !isDeliveryDate ? (
      <DaySlotLabelText labelText="bills.pay.date.daySlotLabel.due" dataTestId="day-slot-label-due" />
    ) : null}
    {isDeliveryDate ? (
      <DaySlotLabelText labelText="bills.pay.date.daySlotLabel.delivery" dataTestId="day-slot-label-delivery" />
    ) : null}
  </Box>
);
