import { MIRadioGroup } from 'src/components/form/MIRadioGroup';
import { Card } from 'src/core/ds/card';
import { CompanyInfoType } from 'src/utils/types';
import { OrganizationInfoCard } from './OrganizationInfoCard';

type MultipleOrganizationsProps = {
  organizations: CompanyInfoType[];
  onSelect: (id: number) => void;
  selectedOrganizationId: number;
};

export const MultipleOrganizationsRadioGroupList = ({
  organizations,
  onSelect,
  selectedOrganizationId,
}: MultipleOrganizationsProps) => (
  <Card id="multiple-organizations-view">
    <MIRadioGroup<number>
      id="msn-multiple-organizations"
      group="msn-multiple-organizations"
      onSelect={onSelect}
      options={organizations.map(({ id, companyName, city, formattedAddress }, index) => ({
        id,
        component: (
          <OrganizationInfoCard
            companyDetails={`${companyName} - ${city}`}
            address={formattedAddress as string}
            mb={index === organizations.length - 1 ? 0 : 5}
          />
        ),
      }))}
      selected={selectedOrganizationId}
      colDirection
    />
  </Card>
);
