import Box from 'src/core/ds/box';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { ModelView } from 'src/ui/form';
import { ScreenMode } from 'src/utils/consts';
import { VendorFormType } from 'src/utils/types';

const defaultProps = {
  mode: ScreenMode.EDIT as ScreenMode,
};
type DefaultProps = Readonly<typeof defaultProps>;
type Props = {
  vendor: ModelView<VendorFormType>;
  isRPPSVendor: boolean | null;
} & Partial<DefaultProps>;

export const VendorForm = ({ vendor, mode, isRPPSVendor }: Props) => {
  const isViewOnly = mode === ScreenMode.VIEW;

  return (
    <Box mt={5}>
      <Box mb={6}>
        <TextField
          id="companyName"
          label="vendors.create.name.inputTitle"
          model={vendor?.companyName}
          type="text"
          isViewOnly={isViewOnly}
          isRequired
          autoFocus={!isViewOnly}
          size="sm"
        />
      </Box>
      {isRPPSVendor && (
        <>
          <Box mb={6}>
            <TextField
              id="accountIdentifier"
              label="vendors.create.accountIdentifier.inputTitle"
              model={vendor?.accountIdentifier}
              placeholder="vendors.form.accountIdentifierEmpty"
              type="text"
              isViewOnly={isViewOnly}
              size="sm"
            />
          </Box>
          <Box mb={6}>
            <TextField
              id="address"
              label="vendors.create.address.inputTitle"
              model={vendor?.address}
              isRequired
              isDisabled
              type="text"
              isViewOnly={isViewOnly}
              size="sm"
            />
          </Box>
        </>
      )}
      <Box mb={6}>
        <TextField
          id="contactName"
          label="vendors.create.contact.inputTitle"
          model={vendor?.contactName}
          placeholder="vendors.form.contactNameEmpty"
          type="text"
          isViewOnly={isViewOnly}
          size="sm"
        />
      </Box>
      <Box mb={6}>
        <PrivateDataContainer>
          <TextField
            id="contactEmail"
            label="vendors.create.email.inputTitle"
            model={vendor?.contactEmail}
            placeholder="vendors.form.contactEmailEmpty"
            type="email"
            isViewOnly={isViewOnly}
            size="sm"
          />
        </PrivateDataContainer>
      </Box>
      <MaskField
        id="contactPhone"
        label="vendors.create.phone.inputTitle"
        model={vendor?.contactPhone}
        placeholder="vendors.form.contactPhoneEmpty"
        type="tel"
        format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
        isViewOnly={isViewOnly}
        size="sm"
      />
    </Box>
  );
};
