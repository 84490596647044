import { RouteComponentProps } from 'react-router-dom';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { analytics } from 'src/services/analytics';
import locations from 'src/utils/locations';
import { parseQueryString } from 'src/utils/query-utils';

const eventPage = 'register';

export function GoogleRegisterSuccessRedirect({ location }: RouteComponentProps) {
  const { navigate } = useNavigator();
  const locationState = parseQueryString(location.search);
  const { redirectUrl = locations.index.url() } = locationState;

  analytics.track(eventPage, 'login-with-google-success');

  navigate(redirectUrl, false, { origin: locationState.origin });

  return null;
}
