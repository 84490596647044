import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import MISingleSelect, { Option } from 'src/components/common/MISingleSelect';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import config from 'src/config';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import contactCreateLocations from 'src/pages/contacts/create/locations';
import SuggestedBillers from 'src/pages/onboarding/add-vendor/components/SuggestedBillers';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { VendorOptionType } from 'src/pages/vendor-directory/select-vendor/types';
import {
  createNetworkVendorOption,
  createNewLocalVendorOptionFromName,
  getSelectOverrideStyle,
  groupLocalAndDirectoryVendors,
} from 'src/pages/vendor-directory/select-vendor/utils';
import { VendorOptionLabel } from 'src/pages/vendor-directory/select-vendor/VendorOptionLabel';
import { OptionGroupLabel } from 'src/pages/vendor-directory/select-vendor/VendorsGroupLabel';
import { analytics } from 'src/services/analytics';
import { directoryApi } from 'src/services/api/directory';
import { useForm } from 'src/ui/form';
import { FormContainer } from 'src/ui/form/FormElements';
import { CompanyFormPage, DirectoryType, SelectVendorType } from 'src/utils/consts';
import locations from 'src/utils/locations';
import { VendorType } from 'src/utils/types';

type EditVendorModelType = Partial<VendorType> & {
  currentOption?: VendorOptionType;
};

const eventPage = 'onboarding-add-vendor';

const VendorNamePage = () => {
  const { navigate } = usePreservedStateNavigator();
  const site = useSiteContext();
  const orgId = useSelector(profileStore.selectors.getCurrentOrgId);
  const {
    onApiCall: fetchSuggestedBillers,
    result: suggestedBillersResult,
    loading: isSuggestedBillersLoading,
  } = useApi({
    api: directoryApi.getSuggestedBillers,
  });
  const selectRef = useRef<any>();
  const [isSelectDropdownOpened, setSelectDropdownOpened] = useState(false);
  const [companyNameInputValue, setCompanyNameInputValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<VendorOptionType[]>([]);
  const [isVendorSuggestionsLoading, setIsVendorSuggestionsLoading] = useState(false);
  const groupedOptions = useMemo(() => groupLocalAndDirectoryVendors([], suggestions) as Option[], [suggestions]);
  const [suggestedBillersSelectedValue, setSuggestedBillersSelectedValue] = useState('');

  function onSuggstionSelected(suggestions: string) {
    setSuggestedBillersSelectedValue(suggestions);
    setIsVendorSuggestionsLoading(true);
  }

  const openSelectDropdownOnFocus = () => {
    selectRef.current.handleInputChange(suggestedBillersSelectedValue, {
      action: 'input-change',
    });
    selectRef.current.focus();
  };

  useEffect(() => {
    openSelectDropdownOnFocus();
    onCompanyNameInputChange({ value: suggestedBillersSelectedValue });
  }, [suggestedBillersSelectedValue]);

  useEffect(() => {
    fetchSuggestedBillers({ orgId });
  }, []);

  const contact = useMemo<EditVendorModelType>(
    () => ({
      currentOption: undefined,
      companyName: '',
    }),
    []
  );

  const [contactDataMV] = useForm(contact, {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    submit: async () => {},
  });

  const searchSuggestions = debounce(async (value) => {
    try {
      setIsVendorSuggestionsLoading(true);
      const {
        vendors: { biller: billers },
      } = await directoryApi.search({
        orgId,
        name: value,
      });
      const directoryVendors = billers.map((biller) =>
        createNetworkVendorOption({
          networkVendor: biller,
          directoryType: DirectoryType.Biller,
        })
      );
      setSuggestions(directoryVendors);
    } finally {
      setIsVendorSuggestionsLoading(false);
    }
  }, config.debounceDelay);

  const onSuggestionSelect = (option) => {
    setSelectDropdownOpened(false);
    analytics.track(eventPage, 'vendor-companyName-select', {
      vendorType: option?.type || SelectVendorType.LOCAL,
      searchValue: companyNameInputValue,
      billerSuggestedCount: suggestions.length,
    });

    // eslint-disable-next-line no-underscore-dangle
    if (option.__isNew__) {
      return;
    }

    if (option.meta.action === 'clear') {
      contactDataMV.setModelState({
        currentOption: undefined,
      });
      setSelectDropdownOpened(false);
      setSuggestions([]);
      setSuggestedBillersSelectedValue('');

      return;
    }

    contactDataMV.setModelState((prevState) => ({
      ...prevState,
      currentOption: option,
    }));
  };

  const onCompanyNameInputChange = ({ value }) => {
    setCompanyNameInputValue(value);
    setSelectDropdownOpened(true);
    let newCurrentOption;

    if (isEmpty(value)) {
      newCurrentOption = undefined;
      setSelectDropdownOpened(false);
    } else {
      newCurrentOption = createNewLocalVendorOptionFromName(value);
      searchSuggestions(value);
    }

    contactDataMV.setModelState((prevState) => ({
      ...prevState,
      currentOption: newCurrentOption,
    }));
  };

  const goToDashboard = () =>
    navigate(locations.MainApp.dashboard.url(), false, {
      origin: CompanyFormPage.ONBOARDING,
    });

  const goToAddVendorAccountNumber = () => {
    analytics.track(eventPage, 'biller-suggestions-submit-form', {
      suggestedBillersSelectedValue,
      searchValue: companyNameInputValue,
    });

    navigate(
      generatePath(onboardingLocations.addVendor.accountNumber, {
        orgId,
        billerId: contactDataMV.currentOption.value?.directoryId,
      }),
      false,
      {
        origin: CompanyFormPage.ONBOARDING,
      }
    );
  };

  const goToCreateVendor = () => {
    analytics.track(eventPage, 'biller-suggestions-create-new-vendor-navigate', {
      searchValue: companyNameInputValue,
    });

    navigate(
      generatePath(contactCreateLocations.vendorsSingleWithVendorName, {
        orgId,
        vendorName: companyNameInputValue,
      }),
      false,
      {
        origin: CompanyFormPage.ONBOARDING,
      }
    );
  };

  return (
    <StepLayoutPage
      title="onboarding.addVendor.name.title"
      subtitle="onboarding.addVendor.name.subtitle"
      nextLabel="onboarding.addVendor.name.nextLabel"
      goExit={goToDashboard}
      onNext={goToAddVendorAccountNumber}
      isNextDisabled={!contactDataMV.currentOption.value?.directoryId}
      isCenterVert={false}
    >
      <FormContainer>
        <MISingleSelect
          innerRef={(ref) => {
            selectRef.current = ref;
          }}
          id="companyName"
          placeholder="onboarding.addVendor.name.vendorNameLabel"
          options={groupedOptions}
          value={contactDataMV.currentOption.value}
          onChange={onSuggestionSelect}
          onInputChange={onCompanyNameInputChange}
          overrideStyles={getSelectOverrideStyle(groupedOptions as Option[], site.theme, true)}
          formatOptionLabel={(option, formatOptions) => (
            <VendorOptionLabel option={option} formatOptions={formatOptions} />
          )}
          formatGroupLabel={(group) => <OptionGroupLabel group={group} />}
          isValidNewOption={(inputValue: string, selectValue: Option[]): boolean =>
            !!(inputValue && selectValue.some((option) => option.label === inputValue))
          }
          isClearable
          isLoading={isVendorSuggestionsLoading}
          noOptionsLabel={null}
          showDropdownIndicator={false}
          filterOption={(option) => option}
          allowCustom
          onCreateOption={goToCreateVendor}
          openMenuOnFocus
          required
        />
        {!isSelectDropdownOpened && !contactDataMV.currentOption.value && (
          <SuggestedBillers
            isLoading={isSuggestedBillersLoading}
            data={suggestedBillersResult?.suggestedBillers}
            onChangeSuggestedBillerValue={onSuggstionSelected}
            optionGroupLabel="onboarding.addVendor.name.suggestedGroupLabel"
          />
        )}
      </FormContainer>
    </StepLayoutPage>
  );
};

export default VendorNamePage;
