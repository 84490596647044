import { billsApi } from 'src/modules/bills/api';
import { FilterType } from 'src/modules/bills/types';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { BillStatus } from 'src/utils/consts';
import { convertLimit, convertStart } from 'src/utils/pagination-utils';
import { PaymentRequestType } from 'src/utils/types';

type GetPaymentRequestsParams = {
  orgId: number;
  filters: FilterType;
};

export const paymentRequestsApi = {
  createPaymentRequest(orgId: number, params) {
    const url = `/orgs/${orgId}/payment-request`;

    return postRequest(url, params);
  },

  editPaymentRequest(orgId: number, id: number, params) {
    const url = `/orgs/${orgId}/payment-request/${id}`;

    return putRequest(url, { ...params });
  },

  getPaymentRequestById({
    orgId,
    id,
  }: {
    orgId: number;
    id: number | string;
  }): Promise<{ paymentRequest: PaymentRequestType }> {
    const url = `/orgs/${orgId}/payment-request/${id}`;

    return fetchRequest(url);
  },

  getPaymentRequests({ orgId, filters = {} }: GetPaymentRequestsParams) {
    const url = `/orgs/${orgId}/payment-request`;
    const params = { ...filters, start: convertStart(filters.start), limit: convertLimit(filters.limit) };
    const { billStatus } = filters;

    if (billStatus === BillStatus.PAID || billStatus === BillStatus.SCHEDULED) {
      return billsApi.getVendorBills({
        orgId,
        filters: { ...filters, status: billStatus },
      });
    }

    return fetchRequest(url, params);
  },

  sharePaymentRequest(orgId: number, params) {
    const url = `/orgs/${orgId}/payment-request/share`;

    return postRequest(url, { ...params });
  },

  deletePaymentRequestById(orgId: number, id: number) {
    const url = `/orgs/${orgId}/payment-request/${id}`;

    return deleteRequest(url);
  },

  resendRequest(orgId: number, id: number) {
    const url = `/orgs/${orgId}/payment-request/${id}/resend`;

    return postRequest(url);
  },

  revokeApprovalStatus(orgId: number, id: number | string) {
    const url = `/orgs/${orgId}/payment-request/${id}/approval/revoke`;

    return postRequest(url);
  },
};
