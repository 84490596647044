import { GetProTabs } from 'src/utils/consts';

export const INITIAL_DATE_FILTER_STATE = Object.values(GetProTabs).reduce(
  (result, tab) => ({
    ...result,
    [tab]: undefined,
  }),
  {}
);

export const INITIAL_STATUS_FILTER_STATE = Object.values(GetProTabs).reduce(
  (result, tab) => ({
    ...result,
    [tab]: [],
  }),
  {}
);
