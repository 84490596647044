import { AccountingSoftware } from 'src/utils/consts';

export const accountingSoftwareDisplayName = {
  [AccountingSoftware.QUICKBOOKS]: {
    displayName: 'Quickbooks Online',
    displayShortName: 'Quickbooks',
  },
  [AccountingSoftware.QUICKBOOKS_DESKTOP]: {
    displayName: 'Quickbooks Desktop',
    displayShortName: 'Quickbooks',
  },
  [AccountingSoftware.FRESHBOOKS]: {
    displayName: 'Freshbooks',
    displayShortName: 'Freshbooks',
  },
  [AccountingSoftware.MICROSOFT_DYNAMICS_365]: {
    displayName: 'Microsoft Dynamics 365 Business Central',
    displayShortName: 'Business Central',
  },
  [AccountingSoftware.XERO]: {
    displayName: 'Xero',
    displayShortName: 'Xero',
  },
  [AccountingSoftware.ORACLE_NETSUITE]: {
    displayName: 'Oracle Netsuite',
    displayShortName: 'Netsuite',
  },
  [AccountingSoftware.RIGHT_NETWORKS]: {
    displayName: 'Right Networks Quickbooks Desktop Cloud',
    displayShortName: 'Quickbooks',
  },
  [AccountingSoftware.SAGE_INTACCT]: {
    displayName: 'Sage Intacct',
    displayShortName: 'Intacct',
  },
  [AccountingSoftware.KASHOO]: {
    displayName: 'Kashoo',
    displayShortName: 'Kashoo',
  },
  [AccountingSoftware.MICROSOFT_DYNAMICS]: {
    displayName: 'Microsoft Dynamics (AX, GP, NAV, SL)',
    displayShortName: 'Microsoft Dynamics',
  },
  [AccountingSoftware.QUICKBOOKS_FOR_MAC]: {
    displayName: 'Quickbooks for Mac',
    displayShortName: 'Quickbooks',
  },
  [AccountingSoftware.QUICKEN]: {
    displayName: 'Quicken',
    displayShortName: 'Quicken',
  },
  [AccountingSoftware.SAGE]: {
    displayName: 'Sage 100/200/300/ERP',
    displayShortName: 'Sage',
  },
  [AccountingSoftware.SAGE_50]: {
    displayName: 'Sage 50',
    displayShortName: 'Sage',
  },
  [AccountingSoftware.SAP]: {
    displayName: 'Sap',
    displayShortName: 'Sap',
  },
  [AccountingSoftware.THOMSON]: {
    displayName: 'Thomson Reuters CBS',
    displayShortName: 'CBS',
  },
  [AccountingSoftware.WAVE]: {
    displayName: 'Wave',
    displayShortName: 'Wave',
  },
  [AccountingSoftware.YARDI]: {
    displayName: 'Yardi',
    displayShortName: 'Yardi',
  },
  [AccountingSoftware.ZOHO_BOOKS]: {
    displayName: 'Zoho Books',
    displayShortName: 'Zoho Books',
  },
};

export const CHECK_SYNC_STATUS_INTERVAL = 5 * 1000;
export const CHECK_SYNC_STATUS_INTERVAL_TOP_BAR = 2 * 1000;
export const DEFAULT_MELIO_FEES_ACCOUNT_NAME = 'Melio Service Fees';
export const LEGACY_QBO_MELIO_FEES_ACCOUNT_NAME = 'Melio Credit card fee';
export const DEFAULT_FEES_ACCOUNT_OPTION_ID = '-1';

export const eventPage = 'settings-accounting-software';
