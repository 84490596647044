import { NotificationCardTypes } from 'src/utils/consts';
import { getContactCreditKeyLink } from '../consts';
import { RenderMessage } from './RenderMessage';

export const RenderTerminatedMessage = () => {
  const terminatedSubtitle = {
    label: 'bills.form.paymentActivity.notifications.terminated.subtitle',
    values: {
      contactCreditKey: getContactCreditKeyLink,
    },
  };

  return (
    <RenderMessage
      type={NotificationCardTypes.WARNING}
      title={{ label: 'bills.form.paymentActivity.notifications.terminated.title' }}
      subtitle={terminatedSubtitle}
    />
  );
};
