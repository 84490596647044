import { MICard, MICardForm } from 'src/components/common/MICard';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay } from 'src/core/ds/drawer';
import { BatchBillType } from 'src/modules/regular-batch-payments/types';
import { BillDetails } from 'src/pages/bill/components/BillDetails';
import { CardHeader } from 'src/pages/get-pro/components/cards/CardHeader';
import { PAGE_EVENT } from 'src/pages/regular-batch-payments/analytics/event-mapping';
import { BillType } from 'src/utils/types';

type Props = {
  bill: BillType | BatchBillType;
  isOpen: boolean;
  onClose: () => void;
};

const BillDrawer = ({ bill, isOpen, onClose }: Props) => (
  <Drawer isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
    <DrawerOverlay transition="all 150ms linear">
      <DrawerContent maxW="75.8rem" transition="all 150ms linear">
        <DrawerHeader>
          <CardHeader companyName={bill.vendor?.companyName ?? ''} description={bill.invoiceNumber} />
          <DrawerCloseButton data-testid="bill-drawer-close-button" size="lg" />
        </DrawerHeader>
        <DrawerBody>
          <MICard mode="mainSingleScreen">
            <MICardForm>
              <BillDetails bill={bill} eventPage={PAGE_EVENT} />
            </MICardForm>
          </MICard>
        </DrawerBody>
      </DrawerContent>
    </DrawerOverlay>
  </Drawer>
);

export default BillDrawer;
