import { useEffect, useState } from 'react';
import { generatePath, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { analytics } from 'src/services/analytics';
import { VendorCandidatesAlreadyFilledPage } from './components/AlreadyFilledPage';
import { CandidatesFormDetailsPage } from './components/form/CandidatesFormDetailsPage';
import { VendorCandidatesInvalidPage } from './components/InvalidPage';
import { VendorCandidatesSuccessPage } from './components/SuccessPage';
import { msnVendorCandidateEventPage, TokenStateEnum } from './consts';
import { useCandidateTokenState } from './hooks/useCandidatesTokenState';
import { vendorCandidatesLocations } from './locations';

export const VendorCandidatesRouter = () => {
  const [isFetchingData, setIsFetchingData] = useState(true);
  const { token } = useRouteMatch<{ token: string }>().params;
  const history = useHistory();
  const { tokenState, candidateId } = useCandidateTokenState(token);
  const shouldDisplayLoader = isFetchingData;

  const checkTokenState = () => {
    if (tokenState === TokenStateEnum.VENDOR_FILLED_FORM) {
      analytics.track(msnVendorCandidateEventPage, 'already-signed-up-page-loaded', { candidateId });
      history.push(generatePath(vendorCandidatesLocations.alreadyFilled, { token }));
    }

    if (tokenState === TokenStateEnum.INVALID) {
      analytics.track(msnVendorCandidateEventPage, 'invalid-token-page-loaded', { candidateId, token });
      history.push(generatePath(vendorCandidatesLocations.invalid, { token }));
    }

    if (tokenState === TokenStateEnum.VALID) {
      analytics.track(msnVendorCandidateEventPage, 'form-page-loaded', { candidateId });
      history.push(generatePath(vendorCandidatesLocations.details, { id: candidateId, token }));
    }

    setIsFetchingData(false);
  };

  useEffect(() => {
    checkTokenState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenState]);

  return (
    <Switch>
      {shouldDisplayLoader ? (
        <AreaLoader />
      ) : (
        <>
          <SmartRoute path={vendorCandidatesLocations.details} exact>
            <CandidatesFormDetailsPage />
          </SmartRoute>
          <SmartRoute path={vendorCandidatesLocations.success} exact>
            <VendorCandidatesSuccessPage />
          </SmartRoute>
          <SmartRoute path={vendorCandidatesLocations.alreadyFilled} exact>
            <VendorCandidatesAlreadyFilledPage />
          </SmartRoute>
          <SmartRoute path={vendorCandidatesLocations.invalid} exact>
            <VendorCandidatesInvalidPage />
          </SmartRoute>
        </>
      )}
    </Switch>
  );
};
