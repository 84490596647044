import * as React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Menu } from 'src/core/ds/menu';
import Tooltip from 'src/core/ds/tooltip';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { BatchPaymentType } from 'src/modules/regular-batch-payments/types';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { batchPaymentsLocations } from 'src/pages/regular-batch-payments/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryType, VendorManagedByEnum } from 'src/utils/consts';
import { DeliveryMethodType } from 'src/utils/types';
import { DeliveryMethodMenu } from './DeliveryMethodMenu';

type DeliveryMethodCellProps = {
  itemId: string;
  payment: BatchPaymentType;
  payBillFlowUUID: string;
  toggleRowExpanded?: (value?: boolean) => void;
};

export const DeliveryMethodCell: React.FC<DeliveryMethodCellProps> = ({
  itemId,
  payment,
  payBillFlowUUID,
  toggleRowExpanded,
}) => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const { deliveryMethods, ownedById, managedBy } = useSelector(vendorsStore.selectors.fetch.byId(payment?.vendorId));
  const isOwnedVendor = !!ownedById;
  const currentDeliveryMethodId = payment.deliveryMethod?.id;
  const vendorManagedBy = payment.vendor?.managedBy;
  const deliveryType = payment.deliveryMethod?.deliveryType;
  const isDeliveryMethodTooltipEnabled =
    (deliveryType && [DeliveryType.RPPS, DeliveryType.VIRTUAL_CARD].includes(deliveryType)) ||
    (vendorManagedBy && [VendorManagedByEnum.MSN, VendorManagedByEnum.BILLER].includes(vendorManagedBy));

  const { scheduledDate, fundingSourceId, amount, deliveryMethodId, vendorId } = payment;

  const onChangeDeliveryMethod = async (deliveryMethod: DeliveryMethodType) => {
    if (deliveryMethodId === deliveryMethod.id) return;

    await actions.updatePaymentsDeliveryOptions({
      itemId,
      orgId,
      deductionDate: scheduledDate,
      deliveryMethodId: deliveryMethod.id,
      fundingSourceId,
      amount,
      deliveryMethod,
      payBillFlowUUID,
      // we want to reset delivery speed cell on delivery method change.
      deliveryPreference: null,
    });

    analytics.trackAction('ChangeDeliveryMethod', {
      itemId,
      vendorId,
      DeliveryMethodId: deliveryMethod.id,
      payBillFlowUUID,
    });
  };

  const onAddDeliveryMethod = () => {
    analytics.trackAction('AddDeliveryMethodClick', {
      itemId,
      vendorId,
      payBillFlowUUID,
    });

    history.push(
      generatePath(batchPaymentsLocations.addDeliveryMethod, {
        orgId,
      }),
      { itemId }
    );
  };

  return (
    <Tooltip
      data-testid="delivery-method-choosed-by-vendor-tooltip"
      isDisabled={!isDeliveryMethodTooltipEnabled}
      label={<MIFormattedText label="regularBatchPayments.tooltips.deliveryMethodTooltip" />}
      placement="top"
    >
      <Box>
        <Menu autoSelect={false} isLazy>
          {({ isOpen }) => (
            <DeliveryMethodMenu
              payment={payment}
              deliveryMethods={deliveryMethods}
              currentDeliveryMethodId={currentDeliveryMethodId ?? undefined}
              onChangeDeliveryMethod={onChangeDeliveryMethod}
              onAddDeliveryMethod={onAddDeliveryMethod}
              isOpen={isOpen}
              isOwnedVendor={isOwnedVendor}
              listTestId={`regularBatchPayments.deliveryMethodMenuList-${itemId}`}
              itemId={itemId}
              totalAmount={payment.amount}
              vendor={payment.vendor}
              purpose={payment.purpose}
              managedBy={managedBy}
              toggleRowExpanded={toggleRowExpanded}
            />
          )}
        </Menu>
      </Box>
    </Tooltip>
  );
};
