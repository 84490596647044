import { Dispatch } from 'redux';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { selectCreateIsLoading } from 'src/helpers/redux/restCreateSlice';
import {
  PAYMENTS_FETCH_DETAILS_WITH_TOKEN_SUCCESS,
  PAYMENTS_FETCH_EMAIL_TO_VENDOR_DETAILS_SUCCESS,
  PAYMENTS_FETCH_PAYMENT_UPSELL_DETAILS_SUCCESS,
} from 'src/modules/payments/payment-actions';
import { LOAD_DELIVERY_METHODS_SUCCESS } from 'src/redux/user/actionTypes';
import { DeliveryMethodType, EditableDeliveryMethodType } from 'src/utils/types';
import { deliveryMethodsApi } from './api';
import { name } from './consts';
import {
  addCardAccountWithToken,
  clearWhitePageAddressSlice,
  copyFromOwnedVendorSlice,
  copyFromOwnedVendorWithUnilateralToken,
  createCardAccountWithToken,
  createInternationalDeliveryMethodSlice,
  fetchUnilateralRequestDetailsWithTokenSlice,
  replaceDeliveryMethodSlice,
  replaceVirtualDeliveryMethodSlice,
  shiftVirtualCardToACHPaymentStatusCollectedSlice,
  shouldCloseVerifyDeliveryMethodIndicatorSlice,
  updateDeliveryMethodACHSlice,
  updateWithUnilateralToken,
  verifyMicroDepositsForDeliveryMethodSlice,
  whitePagesAddressSlice,
} from './delivery-methods-slice';
import { updateRegularBatchPaymentSaga } from './sagas';

const createDeliveryMethodIsLoading = selectCreateIsLoading('default');

const persistConfig = {
  whitelist: ['shouldCloseVerifyIndicator'],
};

type DeliveryMethodStoreType = {
  orgId: number;
  vendorId: string;
  id: string;
  deliveryMethod: DeliveryMethodType;
};

type DeliveryMethodStoreInitialStateType = {
  validation: {
    loading: boolean;
    invalidTokenData: any | null;
    deliveryMethodId: string;
  };
  isDeliveryMethodBeingVerifying: boolean;
};

const deliveryMethodsStore = createRestfulSlice<DeliveryMethodStoreType, DeliveryMethodStoreInitialStateType>({
  name,
  schemaName: 'deliveryMethod',
  persistConfig,
  api: {
    create({ orgId, vendorId, ...params }) {
      return deliveryMethodsApi.addDeliveryMethod({ orgId, vendorId, params }).then((res) => res.deliveryMethod);
    },
    update({ orgId, vendorId, id, deliveryMethod }) {
      return deliveryMethodsApi
        .editDeliveryMethodById(orgId, vendorId, id, deliveryMethod)
        .then((res) => res.deliveryMethod);
    },
    // @ts-expect-error - TODO: COENG-794
    fetch: ({ orgId, vendorId, id }) =>
      deliveryMethodsApi.getDeliveryMethodById({ orgId, vendorId, id }).then((res) => res.deliveryMethod),
  },
  extraReducers: {
    [LOAD_DELIVERY_METHODS_SUCCESS](state, { deliveryMethods }) {
      deliveryMethods.forEach((deliveryMethod) => {
        state.byId[deliveryMethod.id] = deliveryMethod;
      });
    },
    [PAYMENTS_FETCH_DETAILS_WITH_TOKEN_SUCCESS](state: any, { payload }) {
      payload.payment.vendor.deliveryMethods.forEach((dm) => {
        state.byId[dm.id] = dm;
      });
    },
    [PAYMENTS_FETCH_EMAIL_TO_VENDOR_DETAILS_SUCCESS](state: any, { payload }) {
      payload.payment.vendor.deliveryMethods.forEach((dm) => {
        state.byId[dm.id] = dm;
      });
    },
    [PAYMENTS_FETCH_PAYMENT_UPSELL_DETAILS_SUCCESS](state: any, { payload }) {
      const { deliveryMethod: achDeliveryMethod } = payload.payment.vendor;
      const { deliveryMethod } = payload.payment;
      state.byId[achDeliveryMethod.id] = achDeliveryMethod;
      state.byId[deliveryMethod.id] = deliveryMethod;
    },
  },
  selectors: {
    byId: (deliveryMethodId) => (state) => state[name].byId[deliveryMethodId],
    all: (state) => Object.values(state[name]?.byId || {}),
    manualAddress(deliveryMethodId) {
      const paymentSelectors = {
        whitePageAddress(state) {
          return state[name].meta[deliveryMethodId]?.whitePageAddress;
        },
        isAddressLoading(state) {
          return state[name].meta[deliveryMethodId]?.loading;
        },
      };

      return paymentSelectors;
    },
    validation: (state) => state[name].validation,
    isCreating: (state) => createDeliveryMethodIsLoading()(state[name]),
    isDeliveryMethodBeingVerifying: (state) => state[name].isDeliveryMethodBeingVerifying,
  },
  slices: {
    clearWhitePageAddressSlice,
    replaceVirtualDeliveryMethod: replaceVirtualDeliveryMethodSlice,
    fetchUnilateralRequestDetails: fetchUnilateralRequestDetailsWithTokenSlice,
    validateAddress: whitePagesAddressSlice,
    copyFromOwnedVendorWithUnilateralToken,
    replaceDeliveryMethodSlice,
    updateDeliveryMethodACHSlice,
    shiftVirtualCardToACHPaymentStatusCollected: shiftVirtualCardToACHPaymentStatusCollectedSlice,
    copyFromOwnedVendorSlice,
    updateWithUnilateralToken,
    addCardAccountWithToken,
    createCardAccountWithToken,
    verifyMicroDepositsForDeliveryMethodSlice,
    shouldCloseVerifyDeliveryMethodIndicatorSlice,
    createInternationalDeliveryMethodSlice,
  },
  extraSagas: updateRegularBatchPaymentSaga,
});

export function getDeliveryMethodActions(dispatch: Dispatch) {
  return {
    async clearWhitePageAddress({ deliveryMethodId }) {
      return dispatch(clearWhitePageAddressSlice.actions.clear(deliveryMethodId));
    },
    async create({ orgId, vendorId, params }) {
      return dispatch(deliveryMethodsStore.actions.create({ orgId, vendorId, ...params }));
    },
    async edit({
      orgId,
      vendorId,
      id,
      deliveryMethod,
    }: {
      orgId: number;
      vendorId: number;
      id: string;
      deliveryMethod: EditableDeliveryMethodType;
    }) {
      return dispatch(
        deliveryMethodsStore.actions.update({
          orgId,
          vendorId,
          id,
          deliveryMethod,
        })
      );
    },
    async replaceDeliveryMethodWithToken({ token, deliveryMethod, orgId, vendorId }) {
      return dispatch(
        replaceDeliveryMethodSlice.actions({
          token,
          deliveryMethod,
          orgId,
          vendorId,
        })
      );
    },
    async shiftVirtualCardToAch({ token, deliveryMethod, id, paymentId, orgId, vendorId }) {
      return dispatch(
        shiftVirtualCardToACHPaymentStatusCollectedSlice.actions({
          token,
          deliveryMethod,
          orgId,
          paymentId,
          id,
          vendorId,
        })
      );
    },
    async updateDeliveryMethodACHWithToken({ token, deliveryMethod, orgId, vendorId }) {
      return dispatch(
        updateDeliveryMethodACHSlice.actions({
          token,
          deliveryMethod,
          orgId,
          vendorId,
        })
      );
    },
    async copyFromOwnedVendor({ orgId, vendorId, token }) {
      return dispatch(copyFromOwnedVendorSlice.actions({ orgId, vendorId, token }));
    },
    async verifyMicroDepositsForDeliveryMethod({ orgId, vendorId, params }) {
      return dispatch(
        verifyMicroDepositsForDeliveryMethodSlice.actions({
          orgId,
          vendorId,
          params,
        })
      );
    },
  };
}

export default deliveryMethodsStore;
