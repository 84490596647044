import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { vendorsApi } from './api';

const name = 'vendors';

const vendorsUploadBatchPayments = createApiCallSlice<any, any>({
  name: '[VENDORS] CREATE_BATCH',
  api: vendorsApi.createVendorsBatch,
  initialState: {
    byId: {},
    createBatch: {
      loading: false,
      error: undefined,
      count: 0,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.createBatch.loading = true;
      state.createBatch.error = undefined;
      state.createBatch.count = 0;
    },
    [ON_SUCCESS]: (state, action) => {
      state.createBatch.loading = false;
      state.createBatch.error = undefined;
      action.payload.forEach((vendor) => {
        state.byId[vendor.id] = vendor;
      });
      state.createBatch.count = action.payload.length;
    },
    [ON_FAILURE]: (state, action) => {
      state.createBatch.loading = false;
      state.createBatch.error = action.error;
    },
  },
  selectors: {
    status: (state) => state[name]?.createBatch,
  },
});

export default vendorsUploadBatchPayments;
