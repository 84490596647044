import { RecordOf } from 'immutable';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { CodeVerificationPage } from 'src/components/layout/CodeVerificationPage';
import { withNavigator } from 'src/hoc';
import { authApi } from 'src/modules/auth/api';
import { initUserAction, setCompanyInfoAction } from 'src/redux/user/actions';
import { analytics } from 'src/services/analytics';
import guestApi from 'src/services/api/guests';
import { CompanyInfoType, UserContextType } from 'src/utils/types';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type MapDispatchToProps = {
  initUser: (user: RecordOf<UserContextType>) => Promise<void | { companyInfo: CompanyInfoType }>;
  setCompanyInfo: (companyInfo: RecordOf<CompanyInfoType>) => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = GuestDataProps & {} & MapDispatchToProps;

type State = {
  validationErrors: Record<string, any>;
  isLoading: boolean;
  codeInputKey: number;
  isCodeResend: boolean;
};

const eventPage = 'payor';
const eventName = 'email-code-verification';

class GuestEmailVerifyPageContainer extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      validationErrors: {},
      isLoading: false,
      codeInputKey: 1,
      isCodeResend: false,
    };
  }

  onPrev = () => {
    analytics.track(eventPage, `${eventName}-back`);
    const { navigateToGuestPage, prevRoute } = this.props;

    analytics.track(eventPage, `${eventName}-change-email`);
    navigateToGuestPage(prevRoute);
  };

  onCodeChange = (code: string) => {
    if (code.length === 4) {
      this.resolveEmailVerification(Number(code));
    }

    if (code.length === 0) {
      this.setState({ validationErrors: {} });
    }
  };

  resolveEmailVerification = async (code: number) => {
    const { email, initUser, link, navigateToGuestPage } = this.props;
    this.setState({ isLoading: true });

    try {
      const fallbackUtms = `meliome|payor|${link}`;
      const fallbackTams = `|meliome_${link}|`;
      await guestApi.resolveEmailVerification({
        email,
        code,
        fallbackUtms,
        fallbackTams,
      });
      analytics.track(eventPage, `${eventName}-correct-code`);
      const { user } = await authApi.check();
      analytics.alias(user.id);
      analytics.setTraits({ utms: fallbackUtms });
      await initUser(user);

      this.setState({ isLoading: false });
      analytics.track(eventPage, `${eventName}-continue-success`);
      navigateToGuestPage(this.props.nextRoute);
    } catch (e: any) {
      if (e) {
        analytics.track(eventPage, `${eventName}-incorrect-code`);
        this.setState({ validationErrors: { code: e.code }, isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    }
  };

  sendEmailVerificationCode = () => {
    const { email } = this.props;

    analytics.track(eventPage, `${eventName}-resend-code`);
    guestApi
      .sendEmailVerificationCode(email)
      .then(() => {
        this.setState(({ codeInputKey }) => ({
          validationErrors: {},
          isLoading: false,
          codeInputKey: codeInputKey + 1,
          isCodeResend: true,
        }));
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { email } = this.props;
    const { validationErrors, isLoading, codeInputKey, isCodeResend } = this.state;

    return (
      <CodeVerificationPage
        codeInputKey={codeInputKey}
        email={email}
        errorCode={validationErrors.code}
        onPrev={this.onPrev}
        isLoading={isLoading}
        sendEmailVerificationCode={this.sendEmailVerificationCode}
        onChange={this.onCodeChange}
        onChangeEmail={this.onPrev}
        isCodeResend={isCodeResend}
      />
    );
  }
}

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  initUser(user: RecordOf<UserContextType>) {
    return new Promise((resolve, reject) => {
      dispatch(initUserAction(user, false, resolve, reject));
    });
  },
  setCompanyInfo(companyInfo: RecordOf<CompanyInfoType>) {
    dispatch(setCompanyInfoAction(companyInfo));
  },
});

export default compose(
  withNavigator(),
  withGuestData(),
  connect(null, mapDispatchToProps)
)(GuestEmailVerifyPageContainer);
