import { useFeature } from '@melio/shared-web/dist/feature-flags';
import isEmpty from 'lodash/isEmpty';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { ActionOption } from 'src/components/common/MIActionsMenu';
import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { DeliveryMethodOptionsList } from 'src/components/list/DeliveryMethodOptionsList';
import { useInternationalDetails } from 'src/pages/vendor/international-delivery-method/hooks/useInternationalDetails';
import {
  getInternationalDescription,
  isFundingSourceAllowedForInternational,
} from 'src/pages/vendor/international-delivery-method/utils';
import { DeliveryType, FeatureFlags } from 'src/utils/consts';
import { AccountType, BillType, DeliveryMethodOptions, DeliveryMethodType } from 'src/utils/types';

type Props = {
  onNext: () => void;
  goExit: () => void;
  onPrev: () => void;
  companyName: string;
  amount: string;
  isLoading?: boolean;
  deliveryMethodOptions: DeliveryMethodOptions;
  selectedDeliveryMethod: DeliveryType | undefined;
  selectedFundingSource: AccountType | undefined;
  onDeliveryMethodChange: (value: DeliveryType) => void;
  bill: BillType;
  headerLabel: string;
  relativeStep?: number;
  title: string;
  titleValues?: Record<string, any>;
  subtitle?: string;
  getActionOptions: (deliveryMethod: DeliveryMethodType) => ActionOption[];
};

export const PayBillSelectDeliveryMethodPage = ({
  onNext,
  companyName,
  isLoading = false,
  deliveryMethodOptions,
  selectedDeliveryMethod,
  onDeliveryMethodChange,
  goExit,
  onPrev,
  amount,
  bill,
  selectedFundingSource,
  headerLabel,
  relativeStep,
  title,
  titleValues,
  getActionOptions,
}: Props) => {
  const [isInternationalMastercardEnabled] = useFeature(FeatureFlags.EnableMastercardFSForInternational, false);
  const { blockEmailForInternationalPayment } = useInternationalDetails();

  const headerLabelValues = {
    amount: (
      <strong>
        <MIFormattedCurrency value={amount} />
      </strong>
    ),
    companyName: <strong>{companyName}</strong>,
  };

  if (!bill.id) {
    return <AreaLoader />;
  }

  const isLocalVendor = bill.vendor?.deliveryMethods?.some((dm) => dm.deliveryType !== DeliveryType.INTERNATIONAL);
  const isInternationalDeliveryMethodDisabled = !isFundingSourceAllowedForInternational({
    fundingSource: selectedFundingSource,
    isInternationalMastercardEnabled,
  });
  const isInternationalDescription = getInternationalDescription({
    fundingSource: selectedFundingSource,
    isInternationalMastercardEnabled,
  });

  return (
    <>
      <StepLayoutPage
        headerLabel={headerLabel}
        headerLabelValues={headerLabelValues}
        title={title}
        titleValues={titleValues}
        goExit={goExit}
        onPrev={onPrev}
        relativeStep={relativeStep}
        onNext={onNext}
        isLoading={isLoading}
        innerSize={50}
        isNextDisabled={!selectedDeliveryMethod}
        isHideActions={isEmpty(bill.vendor?.deliveryMethods)}
        testId="pay-bill-select-delivery-method-page"
      >
        <CustomFeed feedType="deliverymethod_above" />
        <DeliveryMethodOptionsList
          deliveryMethodOptions={deliveryMethodOptions}
          showFullDeliveryMethodsList={!isLocalVendor && !blockEmailForInternationalPayment}
          companyName={companyName}
          goAddDeliveryMethod={onDeliveryMethodChange}
          isInternationalDeliveryMethodDisabled={isInternationalDeliveryMethodDisabled}
          internationalMethodDesc={isInternationalDescription}
          selectedDeliveryMethod={selectedDeliveryMethod}
          getActionOptions={getActionOptions}
        />
      </StepLayoutPage>
    </>
  );
};
