import { postRequest } from 'src/services/api/api';

export const analyticsApi = {
  track(params: {
    token: string;
    eventName: string;
    properties: {
      [p: string]: unknown;
    };
  }) {
    return postRequest('/guest-analytics/track', params);
  },
};
