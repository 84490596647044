import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RedirectOriginPages } from 'src/pages/receiving-method/consts';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { useQueryString } from 'src/utils/hooks';
import { getListBankAccountPath, getUrlByRedirectOrigin } from './utils';

const QBOReceivingMethodRedirectPage = () => {
  const ownedVendorId = useSelector(getOwnedVendorId);
  const query = useQueryString();
  const userDeliveryMethods = useSelector(getDeliveryMethods);
  const orgId = useSelector(getOrgId);
  const redirectUrl = getUrlByRedirectOrigin(orgId, query?.redirect as RedirectOriginPages);

  if (userDeliveryMethods.length > 0 && ownedVendorId && !query?.error) {
    const linkBankAccountPath = getListBankAccountPath({
      vendorId: ownedVendorId,
      orgId,
      deliveryMethodId: userDeliveryMethods[0].id!,
    });

    return (
      <Redirect
        push
        to={{
          pathname: linkBankAccountPath,
          state: {
            queryParams: { ...query },
            redirectUrl,
          },
        }}
      />
    );
  }

  return (
    <Redirect
      push
      to={{
        pathname: redirectUrl,
        state: {
          queryParams: { ...query },
        },
      }}
    />
  );
};

export { QBOReceivingMethodRedirectPage };
