import { PayListFilters } from 'src/pages/pay/components/filters/types';
import { PayTabs } from 'src/utils/consts';
import { PaginationQueryParams, QueryParams } from 'src/utils/types';
import { billsApi } from '../bills/api';
import { PayListNormalizedType } from '../bills/types';
import { paymentsApi } from '../payments/api';

type fetchItemsType = ({
  orgId,
  filters,
}: {
  orgId: number;
  filters: Omit<QueryParams, 'vendorId'> & PaginationQueryParams & PayListFilters;
}) => Promise<PayListNormalizedType>;

export const getPayListItems: {
  [key in PayTabs]: fetchItemsType;
} = {
  [PayTabs.INBOX]: billsApi.getInboxTabPayItemList,
  [PayTabs.SCHEDULED]: paymentsApi.getScheduledTabPayItemList,
  [PayTabs.PAID]: billsApi.getPaidTabPayItemList,
};
