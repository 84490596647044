import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { CSSObject } from 'src/core/ds';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { useBreak } from 'src/hoc';
import { Logo } from 'src/images/Logo';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { authLocations } from 'src/pages/auth/locations';
import { getIsLoggedIn, getOrgId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { LogoFillType } from 'src/utils/consts';
import locations from 'src/utils/locations';

type OutsideHeaderProps = {
  redirectUrl?: string;
  hideLogin?: boolean;
  analyticsProps?: Record<string, any>;
};

type OutsideHeaderContainerProps = {
  children?: React.ReactNode;
};

type UserContainerProps = {
  children?: React.ReactNode;
  onClick: () => void;
};

type UserNameProps = {
  children?: React.ReactNode;
};

type LogInContainerProps = {
  children?: React.ReactNode;
};

type LogoContainerProps = {
  children?: React.ReactNode;
  onClick: () => void;
};

export const OutsideHeader: React.FC<OutsideHeaderProps> = ({
  redirectUrl,
  hideLogin,
  analyticsProps,
}: OutsideHeaderProps) => {
  const device = useBreak();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const profile = useSelector(getProfile);
  const orgId = useSelector(getOrgId);
  const history = useHistory();
  const [historyPush] = useHistoryWithOrgId();

  const goDashboard = () => {
    history.push(locations.MainApp.dashboard.url({ orgId }));
  };

  const goToLogin = () => {
    analytics.trackAction('log-in', analyticsProps);
    historyPush({ path: authLocations.login, state: { redirectUrl } });
  };

  const goLearnMore = () => {
    analytics.track('unilateral', 'learn-more');
    window.open('https://www.meliopayments.com/request');
  };

  return (
    <OutsideHeaderContainer>
      <LogoContainer onClick={goLearnMore}>
        <Logo fill={LogoFillType.WHITE} size={device.isMobile || device.isPhablet ? 'mobile' : 'regular'} isFull />
      </LogoContainer>
      {!hideLogin && (
        <>
          {isLoggedIn && profile.orgName ? (
            <UserContainer onClick={goDashboard}>
              <UserName>{profile.orgName}</UserName>
              <Icon name={IconNames.user} />
            </UserContainer>
          ) : (
            <LogInContainer>
              <MIFormattedText
                label={
                  device.isMobile || device.isPhablet
                    ? 'vendors.addDeliveryMethodByLink.loginHintMobile'
                    : 'vendors.addDeliveryMethodByLink.loginHint'
                }
              />
              <Button
                label="vendors.addDeliveryMethodByLink.loginSubmit"
                onClick={goToLogin}
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="white"
                border="2px"
                __css={{
                  ml: { base: 4, sm: 5 },
                  borderColor: 'white',
                }}
              />
            </LogInContainer>
          )}
        </>
      )}
    </OutsideHeaderContainer>
  );
};

const OutsideHeaderContainer = ({ children }: OutsideHeaderContainerProps) => (
  <Flex sx={OutsideHeaderContainerStyle}>{children}</Flex>
);

const OutsideHeaderContainerStyle: CSSObject = {
  justifyContent: 'space-between',
  bgColor: 'primary.500',
  h: { base: '7.7rem', md: '15rem' },
  pb: 0,
  px: { base: 5, md: 12 },
  pt: { base: 0, md: 8 },
  alignItems: { base: 'center', md: 'initial' },
};

const LogInContainer = ({ children }: LogInContainerProps) => <Flex sx={LogInContainerStyle}>{children}</Flex>;

const LogInContainerStyle: CSSObject = {
  alignItems: 'baseline',
  color: 'white',
  textStyle: { base: 'body4', md: 'body4Semi' },
};

const LogoContainer = ({ children, onClick }: LogoContainerProps) => (
  <Button
    onClick={onClick}
    cursor="pointer"
    sx={{
      p: 0,
      _hover: {
        bgColor: 'transparent',
      },
      _active: {
        bgColor: 'transparent',
      },
      h: { base: '3.5rem', md: '4.2rem' },
    }}
  >
    {children}
  </Button>
);

const UserContainer = ({ onClick, children }: UserContainerProps) => (
  <Button onClick={onClick} sx={UserContainerStyle}>
    {children}
  </Button>
);

const UserContainerStyle: CSSObject = {
  color: 'white',
  textStyle: 'body2Semi',
  cursor: 'pointer',
  display: 'flex',
  h: '2rem',
  p: 0,
  _hover: {
    bgColor: 'transparent',
  },
  _active: {
    bgColor: 'transparent',
  },
};

const UserName = ({ children }: UserNameProps) => (
  <Box as="span" sx={UserNameStyle}>
    {children}
  </Box>
);

const UserNameStyle: CSSObject = {
  flex: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  mr: 2,
};
