import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useOrgId } from 'src/hooks';
import { payListItemsStore } from 'src/modules/pay/pay-list-items-store';
import { GET_PRO_URL_IDENTIFIER } from 'src/pages/get-pro/consts';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import useLoadGetProList from 'src/pages/get-pro/hooks/useLoadGetProList';
import { PAY_PAGE_URL_IDENTIFIER } from 'src/pages/pay/consts';

export const useRefreshItemsList = () => {
  const orgId = useOrgId();
  const dispatch = useDispatch();
  const { list: fetchItems } = getStoreActions(payListItemsStore)(dispatch);
  const { listParams } = useGetProListParams();
  const { loadGetProList } = useLoadGetProList(listParams);
  const { pathname } = useLocation();

  const refreshAfterSync = () => {
    if (pathname.includes(GET_PRO_URL_IDENTIFIER)) {
      loadGetProList();
    }

    if (pathname.includes(PAY_PAGE_URL_IDENTIFIER)) {
      fetchItems({ orgId });
    }
  };

  return { refreshAfterSync };
};
