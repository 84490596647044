import { FieldError } from '@basis-theory/basis-theory-js/types/elements/shared';
import { FieldErrorMessage } from 'src/components/basis-theory/fields/common/FieldErrorMessage';
import Flex from 'src/core/ds/flex/Flex';

type Props = {
  testId: string;
  empty: boolean;
  emptyLabel: string;
  invalidLabel: string;
  fieldErrors?: FieldError[];
};

export const FieldErrors = ({ testId, fieldErrors, empty, emptyLabel, invalidLabel }: Props) => (
  <Flex flexDirection="column" data-testid={testId}>
    {empty ? <FieldErrorMessage label={emptyLabel} /> : null}
    {fieldErrors?.map((error, index) =>
      error.type === 'invalid' || (error.type === 'incomplete' && !empty) ? (
        <FieldErrorMessage key={index} label={invalidLabel} />
      ) : null
    )}
  </Flex>
);
