import { useEffect, useState } from 'react';

type Props<T> = {
  options?: Option<T>[];
  isAllSelected?: boolean;
};

type Option<T> = T & { id: number; isChecked?: boolean };

export type BatchActionType = 'selectAll' | 'clearAll' | undefined;

export const useCheckboxOptions = <T>({ options, isAllSelected }: Props<T>) => {
  const [checkboxes, setCheckboxes] = useState<Option<T>[]>([]);
  const [batchAction, setBatchAction] = useState<BatchActionType>();
  const markedCheckboxes = checkboxes.filter(({ isChecked }) => isChecked);

  useEffect(() => {
    const parsedOptions = options?.map((option) => ({ ...option, isChecked: isAllSelected || option.isChecked }));
    parsedOptions && setCheckboxes(parsedOptions);
  }, [options]);

  useEffect(() => {
    if (markedCheckboxes.length === checkboxes.length) {
      setBatchAction('clearAll');
    } else if (markedCheckboxes.length === 0) {
      setBatchAction('selectAll');
    }
  }, [markedCheckboxes, checkboxes]);

  const toggleCheckbox = (id: number) => {
    setCheckboxes((options) => {
      const prevOptions = [...options];
      const checkboxToToggleIndex = prevOptions.findIndex((checkbox) => checkbox.id === id);

      if (checkboxToToggleIndex > -1) {
        prevOptions[checkboxToToggleIndex].isChecked = !prevOptions[checkboxToToggleIndex].isChecked;

        return prevOptions;
      }

      return options;
    });
  };

  const toggleAll = () => {
    setBatchAction('clearAll');
    batchToggle({ isChecked: true });
  };

  const clearAll = () => {
    setBatchAction('selectAll');
    batchToggle({ isChecked: false });
  };

  const batchToggle = ({ isChecked }: { isChecked: boolean }) => {
    setCheckboxes((options) => options.map((option) => ({ ...option, isChecked })));
  };

  return {
    checkboxes,
    markedCheckboxes,
    toggleCheckbox,
    batchAction,
    toggleAll,
    clearAll,
  };
};
