import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useModal } from 'src/helpers/react/useModal';
import organizationStore, { getOrganizationStoreActions } from 'src/modules/organizations/organizations-store';
import { EmailAlreadyExistModal } from 'src/pages/companies/invite-new-company/components/EmailAlreadyExistModal';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { WizardFormContainer } from 'src/ui/wizard/WizardFormContainer';
import { Role } from 'src/utils/consts';

type Props = {
  onNext: () => void;
  onExit: () => void;
  emailInUse?: boolean;
};
type CompanyDetailsModel = {
  email: string;
  companyName: string;
};

export const AccountantNewCompanyDetailsPage = ({ onExit, onNext, emailInUse }: Props) => {
  const newCompanyInfo = useSelector(organizationStore.selectors.getNewCompanyInfo, shallowEqual);
  const dispatch = useDispatch();
  const { updateNewCompanyInfo } = useMemo(() => getOrganizationStoreActions(dispatch), [dispatch]);
  const [mv, { submit }, , loading] = useForm<Partial<CompanyDetailsModel>>(newCompanyInfo, {
    submit: async (model) => {
      if (validateCompanyDetailsModel(model)) {
        await updateNewCompanyInfo(model);
        onNext();
      }
    },
  });
  const [EmailAlreadyExists, showEmailExistsModal] = useModal(EmailAlreadyExistModal, {
    joinOrgRequest: {
      email: mv.email.value,
      companyName: mv.companyName.value,
      role: Role.ACCOUNTANT,
    },
    modalName: 'email-already-exist-modal',
    onDismiss: ({ success }) => {
      if (success) {
        onExit();
      }
    },
  });
  useEffect(() => {
    if (emailInUse) {
      showEmailExistsModal();
    }
  }, [showEmailExistsModal, emailInUse]);

  return (
    <>
      {EmailAlreadyExists}
      <StepLayoutPage
        title="inviteNewCompany.accountantDetails.title"
        subtitle="inviteNewCompany.accountantDetails.subtitle"
        isPrevDisabled
        goExit={onExit}
        onNext={submit}
        isLoading={loading}
      >
        <WizardFormContainer>
          <TextField
            model={mv.companyName}
            label="inviteNewCompany.details.accountantsCompanyNameLabel"
            autoFocus
            isRequired
          />
          <PrivateDataContainer>
            <TextField
              model={mv.email}
              type="email"
              label="inviteNewCompany.accountantDetails.emailLabel"
              helperText="inviteNewCompany.accountantDetails.emailHint"
              isRequired
            />
          </PrivateDataContainer>
        </WizardFormContainer>
      </StepLayoutPage>
    </>
  );
};

function validateCompanyDetailsModel(model: Partial<CompanyDetailsModel>): model is CompanyDetailsModel {
  const userValidation = getValidationErrors('userRegistration', { email: model.email }, ['email'], {
    errorOrigin: 'companyOwnerInfo',
  });
  const companyValidation = getValidationErrors('companyInfo', { companyName: model.companyName }, ['companyName']);
  const validationErrors = { ...userValidation, ...companyValidation };

  if (!isValidationOk(validationErrors)) {
    throw new ValidationError({ validationErrors });
  }

  return true;
}
