import { Location } from 'history';
import { MouseEvent, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { getFabClass } from 'src/helpers/utilities';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import { MenuItemType, useFabMenuItems } from 'src/hooks/useFabMenuItems';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { SmartLink } from 'src/modules/navigation/components/SmartLink';
import { profileStore } from 'src/modules/profile/profile-store';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { devices } from 'src/theme/appDevices';
import { BlockCreateLinkModal } from '../../../common/BlockCreateLinkModal';

const CollapsedFab = ({ toggleFab }: { toggleFab: () => void }) => (
  <FabButtonIcon onClick={toggleFab} data-testid="fab-button-plus">
    +
  </FabButtonIcon>
);

const ExpandedFab = ({
  toggleFab,
  menuItems,
  handleClick,
}: {
  toggleFab: () => void;
  menuItems: MenuItemType[];
  handleClick: (item: MenuItemType, event: MouseEvent<HTMLElement>) => void;
}) => (
  <>
    <FabButtonControls>
      <FabButtonControlsClose onClick={toggleFab} data-testid="fab-button-close">
        +
      </FabButtonControlsClose>
      <FabButtonControlsLabel>
        <MIFormattedText label="fab.new" />
      </FabButtonControlsLabel>
    </FabButtonControls>

    {menuItems.map((item) => (
      <FabButtonItem {...item} onClick={(event) => handleClick(item, event)} data-testid={`fab-action-${item.label}`}>
        <MIFormattedText label={item.label} />
      </FabButtonItem>
    ))}
  </>
);

export const Fab = ({ isSideMenuOpen = false }: { isSideMenuOpen?: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showBlockCreateLinkModal, setShowBlockCreateLinkModal] = useState(false);

  const location: Location = useLocation();

  const { canCreatePaymentRequests } = useCanCreatePaymentRequest();
  const orgId = useSelector(getOrgId);
  const requestsPermissions = useSelector(profileStore.selectors.getPermissions).requests;

  const menuItems = useFabMenuItems(orgId, location);
  const className = getFabClass(isSideMenuOpen, isExpanded);

  const toggleFab = () => {
    analytics.track('fab', 'toggle', { isExpand: !isExpanded });
    setIsExpanded(!isExpanded);
  };

  const hideBlockCreateLinkModal = () => {
    analytics.track('fab', 'hide-block-create-link-modal');
    setShowBlockCreateLinkModal(false);
  };

  const handleClick = (item: MenuItemType, event: MouseEvent<HTMLElement>) => {
    analytics.track('fab', item.key);

    if (item.key === 'fab-request') {
      if (!canCreatePaymentRequests && !requestsPermissions.createMelioLink()) {
        event.preventDefault();
        setIsExpanded(false);
        setShowBlockCreateLinkModal(true);
      }
    }
  };

  const ref = useRef<HTMLElement>();
  useOutsideClick(ref, toggleFab);

  return (
    <>
      {showBlockCreateLinkModal && (
        <BlockCreateLinkModal onButtonClick={hideBlockCreateLinkModal} onCloseClick={hideBlockCreateLinkModal} />
      )}
      <FabButton className={className}>
        <FabButtonInner className={isExpanded ? 'expanded' : 'collapsed'}>
          {isExpanded ? (
            <ExpandedFab toggleFab={toggleFab} menuItems={menuItems} handleClick={handleClick} />
          ) : (
            <CollapsedFab toggleFab={toggleFab} />
          )}
        </FabButtonInner>
      </FabButton>
    </>
  );
};

const FabButton = styled.div`
  background-color: ${(props) => props.theme.colors.primary.opaque};
  border-radius: 0.6rem;
  box-shadow: 0 0.5rem 1rem 0 ${(props) => props.theme.colors.dark.translucent2};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white.opaque};
  display: flex;
  font-size: ${(props) => props.theme.text.size.sectionTitle};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  position: fixed;
  transition: ${(props) => props.theme.animation.transition.menu};
  user-select: none;
  z-index: ${(props) => props.theme.zIndex.fab};

  @media ${devices.nonDesktop} {
    right: 2rem;
    bottom: 2rem;
    display: flex;
  }

  @media ${devices.desktop} {
    left: 1.5rem;
    top: 10.8rem;
  }

  &.menuClosedFabCollapsed {
    @media ${devices.mobile} {
      height: 5rem;
      width: 5rem;
    }

    @media ${devices.phablet} {
      height: 5rem;
      width: 5rem;
    }

    @media ${devices.tablet} {
      height: 5rem;
      width: 5rem;
    }

    @media ${devices.desktop} {
      height: 4.2rem;
      width: 4.2rem;
    }
  }

  &.menuClosedFabExpanded {
    height: auto;
    width: 15.2rem;
  }

  &.menuOpenFabCollapsed {
    height: 4.2rem;
    width: 19.2rem;
  }

  &.menuOpenFabExpanded {
    height: auto;
    width: 19.2rem;
  }
  ${(props) => props.theme.components?.FabButton?.FabButton}
`;

const FabButtonInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.collapsed {
    cursor: pointer;
    line-height: 4.2rem;
  }

  &.expanded {
    height: auto;
    padding: 1.6rem 0 1.2rem 0;
  }
`;

const FabButtonIcon = styled.div`
  font-size: 2.2rem;
  font-weight: 400;
  height: 100%;
  text-align: center;
  width: 100%;

  @media ${devices.mobile} {
    line-height: 5rem;
  }

  @media ${devices.phablet} {
    line-height: 5rem;
  }

  @media ${devices.tablet} {
    line-height: 5rem;
  }

  @media ${devices.desktop} {
    line-height: 4.2rem;
  }
`;

const FabButtonItem = styled(({ ...props }: MenuItemType) => <SmartLink {...props} />)`
  color: ${(props) => props.theme.colors.white.opaque};
  height: 3.4rem;
  line-height: 3.4rem;
  padding-left: 1.6rem;
  text-decoration: none;
  &:active {
    background-color: ${(props) => props.theme.colors.primary.active};
  }
`;

const FabButtonControls = styled.div`
  display: flex;
  flex-direction: row;
  height: 3.4rem;
  justify-content: space-between;
`;

const FabButtonControlsLabel = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: ${(props) => props.theme.text.size.hint};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding-right: 1.6rem;
  text-transform: uppercase;
`;

const FabButtonControlsClose = styled.div`
  cursor: pointer;
  font-size: 2.2rem;
  font-weight: 400;
  padding-left: 2rem;
  transform: rotate(-45deg);
`;
