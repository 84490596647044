import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { analyticsApi } from 'src/analytics/api/guest-analytics-api';
import { getJWTPayload } from 'src/helpers/jwt';
import { paymentsStore } from 'src/modules/payments/payment-store';
import { analytics } from 'src/services/analytics';
import { FAST_DELIVERY_EVENT_NAMES, FAST_DELIVERY_SERVER_EVENT_NAMES } from '../consts';

type TokenValues = {
  paymentId: string;
  vendorId: string;
};

type ExtraAnalyticsProps = Record<string, unknown>;

export const useVendorUpgradePaymentAnalytics = (token: string) => {
  const { paymentId, vendorId } = getJWTPayload<TokenValues>(token);

  const payment = useSelector(paymentsStore.selectors.byId(paymentId));

  const staticAnalyticsProps = useMemo(
    () => ({
      paymentId,
      vendorId,
      vendorEmail: payment?.vendor?.contactEmail,
    }),
    [payment, paymentId, vendorId]
  );

  const staticServerAnalyticsProps = useMemo(
    () => ({
      ...staticAnalyticsProps,
      payorOrgId: payment?.organization?.id,
    }),
    [payment, staticAnalyticsProps]
  );

  const trackAction = useCallback(
    (action: typeof FAST_DELIVERY_EVENT_NAMES[keyof typeof FAST_DELIVERY_EVENT_NAMES], props?: ExtraAnalyticsProps) => {
      analytics.trackAction(action, { ...staticAnalyticsProps, ...props });
    },
    [staticAnalyticsProps]
  );

  const trackServerAction = useCallback(
    (
      action: typeof FAST_DELIVERY_SERVER_EVENT_NAMES[keyof typeof FAST_DELIVERY_SERVER_EVENT_NAMES],
      props?: ExtraAnalyticsProps
    ) => {
      analyticsApi.track({ token, eventName: action, properties: { ...staticServerAnalyticsProps, ...props } });
    },
    [staticServerAnalyticsProps, token]
  );

  return {
    trackAction,
    trackServerAction,
    staticAnalyticsProps,
  };
};
