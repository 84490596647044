import cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { authApi } from 'src/modules/auth/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { authLocations } from 'src/pages/auth/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import accountingSoftwareApi from 'src/services/api/accountingSoftwareSync';
import { pushNotification } from 'src/services/notifications/notificationService';
import { AuthErrorCodes, NotificationVariant } from 'src/utils/consts';
import { capture } from 'src/utils/error-tracking';
import { useQueryString } from 'src/utils/hooks';

const eventPage = 'authorize-quickbooks';

const AuthorizeQuickbooksSuccessPage = () => {
  const { orgId } = useQueryString();
  const [historyPush] = useHistoryWithOrgId();

  const goQuickbooksOnboarding = useCallback(async () => {
    analytics.track(eventPage, 'register-intuit-success');

    await accountingSoftwareApi.syncQuickBooksData(orgId);
    analytics.setOneTimeMarketingTraits();

    try {
      await authApi.updateUserMarketingDetails({
        marketingTam: cookies.get('trackingTam'),
        marketingUtms: cookies.get('utms'),
      });
    } catch (err: any) {
      if (err.code === AuthErrorCodes.ERR_USER_NOT_VERIFIED) {
        analytics.track(eventPage, 'email-is-not-verified');
        historyPush({ path: authLocations.register.codeVerification });
      } else {
        analytics.track(eventPage, 'failure');
        capture(err, {
          message: `Failed to authorize using Quickbooks account`,
          orgId,
        });
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'serverErrors.ERR',
        });
      }

      return;
    }

    analytics.track(eventPage, 'success');
    historyPush({ path: onboardingLocations.quickbooks });
  }, [orgId, historyPush]);

  useEffect(() => {
    goQuickbooksOnboarding();
  }, [goQuickbooksOnboarding]);

  return <AreaLoader />;
};

export default AuthorizeQuickbooksSuccessPage;
