const styles = {
  global: {
    'input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
    'input[type="number"]::-webkit-inner-spin-button ,input[type="number"]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      margin: 0,
    },
  },
};

export default styles;
