import { PayListNormalizedType } from 'src/modules/bills/types';
import { convertPayFilters } from 'src/modules/pay/utils';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { PayListFilters } from 'src/pages/pay/components/filters/types';
import { mapBackwardComaptibleSortingToQueryString } from 'src/pay/components/SortMenu/backwards-compatibility';
import { analytics } from 'src/services/analytics';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { BillStatus, DbAnalyticsTraits } from 'src/utils/consts';
import { convertLimit, convertStart } from 'src/utils/pagination-utils';
import { DeliveryOptionType, PaginationQueryParams, QueryParams } from 'src/utils/types';

export type PaymentFilters = {
  start?: number;
  limit?: number;
};

type PaymentFeeProps = {
  orgId: number;
  fundingSourceId: number;
  amount?: string | number | null;
  scheduledDate: Date;
  deliveryPreference?: string | null;
  deliveryMethodId: string | number;
  cardFeePaidBy?: string;
  allowDoubleFee?: boolean;
  paymentId?: number;
  isFinancingPayment: boolean;
};

type GetPaymentDeliveryTimeParams = {
  orgId: number;
  deductionDate: Date;
  deliveryMethodId: number;
  fundingSourceId: number;
  amount: number;
  paymentId?: number;
  payBillFlowUUID?: string;
};

export type GetVirtualCardDataProps = {
  token: string;
  virtualCardId: string;
};

export type GetPaymentDeliveryTimeResponse = {
  suggestedScheduledDate: Date;
  actualDeliveryDays: number;
  deliveryDate: Date;
  maxDeliveryDate: Date;
  deliveryOptions: DeliveryOptionType[];
};

type PaymentsCSVReportFilters = {
  startDate?: Date;
  endDate?: Date;
  includeScheduledPayments?: boolean;
};

export type BatchItemStatus = {
  id: number;
  status: 'success' | 'failure';
  message?: string;
};

export type ApprovalDecision = {
  id: number;
  decision: 'approved' | 'declined';
  reason?: string;
};

export type BatchApprovalDecisionUpdateRequest = {
  decisions: ApprovalDecision[];
};

export type BatchApprovalDecisionUpdateResponse = {
  statuses: BatchItemStatus[];
};

// TODO: add a scope argument to GET requests to get payments which point to deleted funding sources & delivery methods
export const paymentsApi = {
  getPayments({ orgId, filters = {}, sorting }: { orgId: number; filters: PaymentFilters; sorting?: string }) {
    const url = `/orgs/${orgId}/payments`;
    const params = {
      ...filters,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
      sorting,
    };

    return fetchRequest(url, params);
  },

  list({
    orgId,
    status,
    search,
    start,
    limit,
    vendorId,
  }: {
    orgId: number;
    status: BillStatus;
    search?: string;
    start?: number;
    limit?: number;
    vendorId?: string;
  }) {
    const url = `/orgs/${orgId}/payments/list`;
    const params = {
      status,
      search,
      start: convertStart(start),
      limit: convertLimit(limit),
      vendorId,
    };

    return fetchRequest(url, params);
  },
  getScheduledTabPayItemList({
    orgId,
    filters,
  }: {
    orgId: number;
    filters: Omit<QueryParams, 'vendorId'> & PaginationQueryParams & PayListFilters;
  }): Promise<PayListNormalizedType> {
    const url = `/orgs/${orgId}/payments/list-pay-normalized`;

    const sorting = mapBackwardComaptibleSortingToQueryString(filters.sorting) ?? filters.sorting;
    const params = {
      ...filters,
      ...convertPayFilters(filters),
      sorting,
      start: convertStart(filters.start),
      limit: convertLimit(filters.limit),
    };

    return fetchRequest(url, params);
  },

  createPayment(orgId, params) {
    const url = `/orgs/${orgId}/payments`;

    analytics.setTraits({ [DbAnalyticsTraits.FIRST_TIME_PAY]: true });

    return postRequest(url, params).then((res) => res.object);
  },

  createInternationalPayment(orgId, params) {
    const url = `/orgs/${orgId}/payment-api/`;

    analytics.setTraits({ [DbAnalyticsTraits.FIRST_TIME_PAY]: true });

    return postRequest(url, params);
  },

  createFinancingPayment(orgId, params) {
    const url = `/orgs/${orgId}/payment-api/`;

    analytics.setTraits({ [DbAnalyticsTraits.FIRST_TIME_PAY]: true });

    return postRequest(url, params);
  },

  retryFailedToCollect(orgId, payment) {
    const url = `/orgs/${orgId}/payments/${payment.id}/retry-failed-to-collect`;

    return postRequest(url, payment);
  },

  retryFailedToDeliver(orgId, payment) {
    const url = `/orgs/${orgId}/payments/${payment.id}/retry-failed-to-deliver`;

    return postRequest(url, payment);
  },

  cancelAndRetry(orgId, params) {
    const url = `/orgs/${orgId}/payments/${params.id}/cancel-and-retry`;

    return postRequest(url, {
      newDeliveryMethodId: params.newDeliveryMethodId,
      deliveryPreference: params.deliveryPreference,
    });
  },

  reschedule(orgId, payment) {
    const url = `/orgs/${orgId}/payments/${payment.id}/reschedule`;

    return postRequest(url, payment);
  },

  deletePaymentById(orgId: number, id: number, params = {}) {
    const url = `/orgs/${orgId}/payments/${id}`;

    return deleteRequest(url, params);
  },

  editPaymentById(orgId, params) {
    const url = `/orgs/${orgId}/payments/${params.id}`;

    return putRequest(url, params);
  },

  getPaymentById(orgId, paymentId) {
    const url = `/orgs/${orgId}/payments/${paymentId}`;

    return fetchRequest(url, {});
  },
  approvePayment({ orgId, id, reason }) {
    const url = `/orgs/${orgId}/payments/${id}/approval-decision`;

    return postRequest(url, { status: 'approved', reason });
  },
  batchSetApprovalDecision({ orgId, decisions }: { orgId: number; decisions: ApprovalDecision[] }) {
    const url = `/orgs/${orgId}/payments/approval-decision/batch`;

    return postRequest<BatchApprovalDecisionUpdateRequest>(url, { decisions });
  },
  declinePayment({ orgId, id, reason }) {
    const url = `/orgs/${orgId}/payments/${id}/approval-decision`;

    return postRequest(url, { status: 'declined', reason });
  },

  getDeliveryTime({
    orgId,
    deductionDate,
    deliveryMethodId,
    fundingSourceId,
    amount,
    paymentId,
    payBillFlowUUID,
  }: GetPaymentDeliveryTimeParams): Promise<GetPaymentDeliveryTimeResponse> {
    return deliveryApi.getDeliveryTime(
      orgId,
      deductionDate,
      deliveryMethodId,
      fundingSourceId,
      amount,
      paymentId,
      payBillFlowUUID
    );
  },

  getPaymentFee({
    orgId,
    fundingSourceId,
    amount,
    scheduledDate,
    deliveryPreference,
    deliveryMethodId,
    cardFeePaidBy,
    allowDoubleFee = false,
    paymentId,
    isFinancingPayment,
  }: PaymentFeeProps) {
    const url = `/orgs/${orgId}/payments/fee`;

    return fetchRequest(url, {
      amount,
      fundingSourceId,
      scheduledDate,
      deliveryPreference,
      deliveryMethodId,
      cardFeePaidBy,
      allowDoubleFee,
      paymentId,
      isFinancingPayment,
    });
  },

  getDetailsWithToken(params) {
    const url = '/payment/details';

    return fetchRequest(url, params);
  },

  getPaymentUpsellDetails({ token }) {
    const url = `/payment/details/upgrade/${token}`;

    return fetchRequest(url);
  },

  upgradePaymentFromPayee({ paymentId, token, type }) {
    const url = `/payment/${paymentId}/upgrade`;

    return postRequest(url, { token, type });
  },

  getEmailToVendorData(params) {
    const url = '/payment/details/email-to-vendor';

    return fetchRequest(url, params);
  },

  getVirtualCardData(params: GetVirtualCardDataProps) {
    const url = '/payment/details/virtual-card';

    return fetchRequest(url, params);
  },

  updatePaymentByIdWithToken(params) {
    const url = `/payment/${params.id}/push-to-debit`;

    return putRequest(url, params);
  },

  sendEditCheckAddress(orgId, id) {
    const url = `/orgs/${orgId}/payments/${id}/send-edit-check-address`;

    return postRequest(url);
  },

  getPaymentsReport({ orgId, filters }: { orgId: number; filters: PaymentsCSVReportFilters }) {
    const url = `/orgs/${orgId}/payments/report`;

    return fetchRequest(url, {
      startDate: filters.startDate?.toISOString(),
      endDate: filters.endDate?.toISOString(),
      includeScheduledPayments: filters.includeScheduledPayments,
    });
  },

  markPaymentAsPaid({
    orgId,
    id,
    accountingPlatformAccountId,
    createOrigin,
  }: {
    orgId: number;
    id: number;
    accountingPlatformAccountId?: string;
    createOrigin?: string;
  }) {
    const url = `/orgs/${orgId}/payments/${id}/mark-as-paid`;

    return postRequest(url, {
      intuitAccountId: accountingPlatformAccountId,
      createOrigin,
    }).then((res) => res.object);
  },

  markPaymentAsUnpaid({ orgId, id, billId }: { orgId: number; id: number; billId: string }) {
    // TODO: create new api in payments controller for this
    const url = `/orgs/${orgId}/bills/${billId}/mark-as-paid`;

    return postRequest(url, {
      isPaid: false,
      paymentId: id,
    }).then((res) => res.object);
  },

  retryFailedToDeliverWithToken(token: string) {
    const url = '/payment/retry-failed-to-deliver';

    return postRequest(url, {
      token,
    });
  },

  refundPayment({ orgId, paymentId, reason }) {
    const url = `/orgs/${orgId}/payments/${paymentId}/refund`;

    return postRequest(url, { paymentId, reason });
  },
};
