import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';

type Props = {
  titleLabel: string;
  messageLabel: string;
  messageLabelValues: Record<string, any>;
};

export const InstallmentFailedErrorMessage = ({ titleLabel, messageLabel, messageLabelValues }: Props) => (
  <Box px={{ base: 4, lg: 8 }} pt={8} pb={6}>
    <MINotificationCard
      type={NotificationCardTypes.ERROR}
      title={{ label: titleLabel }}
      subtitle={{
        label: messageLabel,
        values: messageLabelValues,
      }}
    />
  </Box>
);
