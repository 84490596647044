import { featureFlags } from '@melio/shared-web';
import { isNullLegalAddress } from 'src/utils/address';
import { CompanyInfoType, LegalAddressType } from 'src/utils/types';

export function useLegalAddress(companyInfo: CompanyInfoType) {
  const [shouldPrePopulate] = featureFlags.useFeature<'prefilled' | 'empty'>('pre-populate-legal-address', 'empty');

  const legalAddress: LegalAddressType =
    shouldPrePopulate === 'prefilled' && isNullLegalAddress(companyInfo)
      ? {
          legalAddressLat: companyInfo.addressLat,
          legalAddressLine1: companyInfo.addressLine1,
          legalAddressLine2: companyInfo.addressLine2,
          legalAddressLng: companyInfo.addressLng,
          legalAptNumber: companyInfo.aptNumber,
          legalCity: companyInfo.city,
          legalCountryCode: companyInfo.countryCode,
          legalState: companyInfo.state,
          legalFormattedAddress: companyInfo.formattedAddress,
          legalGooglePlaceId: companyInfo.googlePlaceId,
          legalZipCode: companyInfo.zipCode,
        }
      : companyInfo;

  return legalAddress;
}
