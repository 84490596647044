import * as React from 'react';
import { Loader } from 'src/components/common/Loader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Avatar, AvatarBadge, Consts } from 'src/core/ds/avatar';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { FileInput } from 'src/core/ds/input';
import { ACCEPT_FILES, BadgeSize } from 'src/utils/consts';
import { MIInitialsBadgePlaceholder as LoadingPlaceholder } from './MIInitialsBadge';

const SIZE = BadgeSize.X_LARGE;

type Props = {
  pictureUrl?: string | null;
  name?: string;
  editable?: boolean;
  isLoading?: boolean;
  onAddClick: () => void;
  onDeleteClick?: () => void;
  onFileChange: (file: File | '') => void | Promise<void>;
};

export const UploadPicture = ({
  pictureUrl,
  name,
  editable,
  isLoading,
  onAddClick,
  onDeleteClick,
  onFileChange,
}: Props) => {
  const [localPicture, setLocalPicture] = React.useState<string | null>(null);
  const [isPictureFormatError, setIsPictureFormatError] = React.useState(false);
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);

  const url = localPicture || pictureUrl;

  React.useEffect(() => {
    if (!pictureUrl) {
      setLocalPicture(null);
      setIsPictureFormatError(false);
    }
  }, [pictureUrl]);

  const handleAddPictureClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();

      onAddClick && onAddClick();
    }
  };

  const handleFileChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const { currentTarget: target } = event;
    const file = target.files && target.files.length > 0 ? target.files[0] : '';
    const mimeType = file && file.type;

    if (mimeType && mimeType.startsWith('image/')) {
      setLocalPicture(URL.createObjectURL(file));
      setIsPictureFormatError(false);
      onFileChange && onFileChange(file);
    } else {
      setLocalPicture(null);
      setIsPictureFormatError(true);
    }
  };

  function BadgeWrapper({ isLoading, pictureUrl, name, editable }: BadgeProps) {
    return (
      <Avatar size={Consts.Size['3xl']} bg="grey.600" name={name} link={pictureUrl as string} textColor="white">
        {editable && (
          <AvatarBadge
            onClick={handleAddPictureClick}
            boxSize="4.2rem"
            bgColor="white"
            top={-4}
            borderRadius="full"
            zIndex="docked"
            boxShadow={400}
            data-testid="upload-button"
          >
            <Icon name={IconNames.imageAdd} color="black" />
          </AvatarBadge>
        )}
        {isLoading && (
          <LoadingPlaceholder size={SIZE} __css={LoadingPlaceholderStyle}>
            <Loader color="dark" />
          </LoadingPlaceholder>
        )}
      </Avatar>
    );
  }

  return (
    <Flex align="center" direction="column" mb={3}>
      <Flex direction="row" pos="relative">
        <BadgeWrapper pictureUrl={url} isLoading={isLoading} name={name} editable />
        <FileInput
          ref={fileInputRef}
          onChange={handleFileChange}
          onClick={onFileInputClick}
          type="file"
          accept={ACCEPT_FILES.IMAGES}
        />
      </Flex>
      {pictureUrl && editable && onDeleteClick && (
        <DeletePictureContainer onClick={onDeleteClick}>
          <MIFormattedText label="bills.form.attachment.delete" />
        </DeletePictureContainer>
      )}
      {isPictureFormatError && (
        <Box as="span" mt={1} color="red.500" textAlign="center">
          <MIFormattedText label="bills.form.attachment.formatError" />
        </Box>
      )}
    </Flex>
  );
};

type DeletePictureContainerProps = {
  children?: React.ReactNode;
  onClick: () => void;
};

const DeletePictureContainer = ({ children, onClick }: DeletePictureContainerProps) => (
  <Box __css={DeletePictureContainerStyle()} onClick={onClick}>
    {children}
  </Box>
);

function onFileInputClick(event: any) {
  event.target.value = null;
}

type BadgeProps = {
  isLoading?: boolean;
  pictureUrl?: string | null;
  name?: string;
  editable?: boolean;
};

const LoadingPlaceholderStyle = {
  position: 'absolute',
  ':before': {
    content: "''",
  },

  '> div': {
    top: '50%',
    transform: 'translate(0, -50%)',
  },
};

const DeletePictureContainerStyle = (destructive?: boolean) => ({
  color: destructive ? 'red.500' : 'black',
  textStyle: 'body4Uppercase',
  cursor: 'pointer',
  mt: 3,
});
