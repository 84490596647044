export enum IconNames {
  close = 'Close',
  plus = 'Plus',
  minus = 'Minus',
  closeMini = 'CloseMini',
  checkDouble = 'CheckDouble',
  check = 'Check',
  checkCircle = 'CheckCircle',
  checkFill = 'CheckFill',
  print = 'Print',
  refresh = 'Refresh',
  download = 'Download',
  upload = 'Upload',
  search = 'Search',
  edit = 'Edit',
  compose = 'Compose',
  filter = 'Filter',
  exit = 'Exit',
  duplicate = 'Duplicate',
  moreVert = 'MoreVert',
  more = 'More',
  repeat = 'Repeat',
  processFill = 'ProcessFill',
  declineFill = 'DeclineFill',
  pauseFill = 'PauseFill',
  linkFill = 'LinkFill',
  camera = 'Camera',
  settings = 'Settings',
  install = 'Install',
  linkOpen = 'LinkOpen',
  dashboard = 'Dashboard',
  bell = 'Bell',
  gallery = 'Gallery',
  noteAdd = 'NoteAdd',
  glasses = 'Glasses',
  hamburger = 'Hamburger',
  promote = 'Promote',
  warning = 'Warning',
  infoCircle = 'InfoCircle',
  alertCircle = 'AlertCircle',
  alertFill = 'AlertFill',
  helpCircle = 'HelpCircle',
  eyeHide = 'EyeHide',
  eyeShow = 'EyeShow',
  fileAdd = 'FileAdd',
  fileMove = 'FileMove',
  attachment = 'Attachment',
  delete = 'Delete',
  imageAdd = 'ImageAdd',
  caretLeftMini = 'CaretLeftMini',
  caretRightMini = 'CaretRightMini',
  caretDownMini = 'CaretDownMini',
  caretUpMini = 'CaretUpMini',
  caretLeft = 'CaretLeft',
  caretRight = 'CaretRight',
  caretDown = 'CaretDown',
  caretUp = 'CaretUp',
  arrowLeft = 'ArrowLeft',
  arrowRight = 'ArrowRight',
  arrowUp = 'ArrowUp',
  arrowDown = 'ArrowDown',
  shevronLeft = 'ShevronLeft',
  shevronRight = 'ShevronRight',
  shevronUp = 'ShevronUp',
  shevronDown = 'ShevronDown',
  company = 'Company',
  shop = 'Shop',
  send = 'Send',
  chat = 'Chat',
  chatEmpty = 'ChatEmpty',
  phone = 'Phone',
  email = 'Email',
  calendarMove = 'CalendarMove',
  scheduled = 'Scheduled',
  calendar = 'Calendar',
  stopWatch = 'Stopwatch',
  stopWatchFast = 'StopwatchFast',
  stopWatchFill = 'StopwatchFill',
  rocket = 'Rocket',
  track = 'Track',
  ship = 'Ship',
  bankOut = 'BankOut',
  bankIn = 'BankIn',
  bank = 'Bank',
  pay = 'Pay',
  getPaid = 'GetPaid',
  directoryPayment = 'DirectoryPayment',
  contacts = 'Contacts',
  creditCard = 'CreditCard',
  debitCard = 'DebitCard',
  wireTransfer = 'WireTransfer',
  calculator = 'Calculator',
  bills = 'Bills',
  wallet = 'Wallet',
  moneyPayment = 'MoneyPayment',
  invoices = 'Invoices',
  moneyReceive = 'MoneyReceive',
  paperCheck = 'PaperCheck',
  billingSettings = 'BillingSettings',
  laptop = 'Laptop',
  smartphone = 'Smartphone',
  tabletEdit = 'TabletEdit',
  tabletSearch = 'TabletSearch',
  qrCode = 'QrCode',
  flow = 'Flow',
  international = 'International',
  user = 'User',
  userAdd = 'UserAdd',
  userRemove = 'UserRemove',
  userId = 'UserId',
  team = 'Team',
  userFill = 'UserFill',
  stopwatch = 'Stopwatch',
  cancelCircle = 'CancelCircle',
  cancelFill = 'CancelFill',
  sort = 'Sort',
  getStarted = 'Get_started',
  mailInbox = 'MailInbox',
  duplicateFlip = 'DuplicateFlip',
  installments = 'Installments',
  verifiedSolid = 'VerifiedSolid',
  shield = 'Shield',
  vendor = 'Vendor',
  customer = 'Customer',
}

export enum IconSize {
  xs = '1rem',
  s = 4,
  m = 5,
  lg = 6,
  xl = 8,
  '2xl' = 12,
}

// TODO to be depreceted
export const OldToNewIconNames: Record<string, IconNames> = {
  'icon-vendor-icon': IconNames.track,
  'icon-customer-icon': IconNames.shop,
  'icon-upload-file': IconNames.upload,
  'icon-account-settings-icon': IconNames.userId,
  'icon-add-manually': IconNames.tabletEdit,
  'icon-sync-settings-icon': IconNames.refresh,
  'icon-upload-attachment': IconNames.fileAdd,
  'icon-next-icon-2': IconNames.shevronRight,
  'icon-bookkeeper-icon': IconNames.calculator,
  'icon-business-owner-icon': IconNames.shop,
  'icon-fast-delivery-icon': IconNames.rocket,
  'icon-slow-delivery-icon': IconNames.ship,
  'icon-bank-icon': IconNames.bank,
  'icon-credit-card-icon': IconNames.creditCard,
  'icon-debit-card-icon': IconNames.debitCard,
  'icon-plus-icon': IconNames.plus,
  'icon-support-icon': IconNames.chatEmpty,
  'icon-search-magnifier-icon': IconNames.search,
  'icon-wallet-icon': IconNames.wallet,
  'icon-receiving-method-icon': IconNames.moneyReceive,
  'icon-installments': IconNames.installments,
};
