import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Badge } from 'src/core/ds/badge';
import Box from 'src/core/ds/box/Box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import { CloseButton } from 'src/core/ds/closeButton/CloseButton';
import Flex from 'src/core/ds/flex';
import { messageStyle } from 'src/pages/get-pro/components/message/components/styles';

type Props = {
  title?: string;
  titleValues?: Record<string, string | undefined>;
  description?: string;
  descriptionValues?: Record<string, string | undefined>;
  badge?: string;
  action?: string;
  actionValues?: Record<string, string | undefined>;
  testId?: string;
  onClick?: () => void;
  onClose?: () => void;
};

const Message: React.FC<Props> = ({
  title,
  description,
  action,
  onClick,
  onClose,
  badge,
  testId,
  titleValues,
  descriptionValues,
  actionValues,
}) => (
  <Box __css={messageStyle} data-testid={testId || 'get-pro-message'}>
    <Flex>
      <Flex direction="column">
        {badge ? <Badge label={badge} mb={3} w="fit-content" /> : null}
        <Box textStyle="body1Semi" mb={2}>
          <MIFormattedText label={title} values={titleValues} />
        </Box>
        <Box textStyle="body2" color="black" mb={4}>
          <MIFormattedText label={description} values={descriptionValues} />
        </Box>
        <Button w="fit-content" size={ButtonSizes.md} onClick={onClick} label={action} values={actionValues} />
      </Flex>
      <CloseButton p={2} size="sm" onClick={onClose} testId="close-message" />
    </Flex>
  </Box>
);

export default Message;
