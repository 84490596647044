import { useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { WizardLayout } from 'src/core/ds/layouts/WizardLayout';
import { HStack, VStack } from 'src/core/ds/stack';
import { useApi } from 'src/hoc/useApi';
import { authApi } from 'src/modules/auth/api';
import { getOrgId } from 'src/redux/user/selectors';
import { companiesLocations } from '../../locations';

export const Instructions = () => {
  const currentOrgId = useSelector(getOrgId);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const { onApiCall: loginWithQuickbooks } = useApi({
    api: authApi.loginWithQuickbooksUnsynced,
  });

  const handleContinueClick = async () => {
    setIsLoading(true);
    const res = await loginWithQuickbooks();

    window.location = res.authRedirectUrl;
  };

  const handleCancelClick = () => {
    history.push(generatePath(companiesLocations.index, { orgId: currentOrgId }));
  };

  return (
    <WizardLayout>
      <WizardLayout.TopBar onCancelClick={handleCancelClick} />
      <WizardLayout.Content>
        <WizardLayout.Header>
          <Flex direction="column" marginBlockStart={6} gap={2}>
            <Box textStyle="h1Semi" width="full" textAlign="center">
              <MIFormattedText label="companies.importQboa.instructions.title" />
            </Box>
            <Box textStyle="body2" width="full" textAlign="center" marginBlockStart={1}>
              <MIFormattedText label="companies.importQboa.instructions.subTitle" />
            </Box>
          </Flex>
        </WizardLayout.Header>
        <WizardLayout.Body>
          <ListCard />
        </WizardLayout.Body>
        <WizardLayout.Footer>
          <Button
            w="full"
            size={ButtonSizes.lg}
            variant={ButtonVariants.primary}
            label="companies.importQboa.instructions.submit"
            onClick={handleContinueClick}
            isLoading={isLoading}
          />
        </WizardLayout.Footer>
      </WizardLayout.Content>
    </WizardLayout>
  );
};

const ListCard = () => (
  <Flex
    flexDirection="column"
    gap={5}
    width="48.8rem"
    padding={6}
    justifyContent="start"
    borderRadius={2}
    background="white"
    boxSizing="border-box"
  >
    <Box textStyle="body1Semi" w="full" textAlign="start">
      <MIFormattedText label="companies.importQboa.instructions.steps.title" />
    </Box>
    <VStack align="start">
      <ListItem index={1} label="companies.importQboa.instructions.steps.one" />
      <ListItem index={2} label="companies.importQboa.instructions.steps.two" />
    </VStack>
  </Flex>
);

const ListItem = ({ index, label }) => (
  <HStack>
    <Box width="2rem" textStyle="body2Semi" color="primary.500" fontWeight="bold">
      {`${index}.`}
    </Box>
    <Box textStyle="body2">
      <MIFormattedText label={label} />
    </Box>
  </HStack>
);
