import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { IdType } from 'react-table';
import { InProgressDrawer } from 'src/components/shared/drawers/MSNPortalAndGetPaid/InProgressDrawer';
import { PaidDrawer } from 'src/components/shared/drawers/MSNPortalAndGetPaid/PaidDrawer';
import { Drawer, DrawerContent, DrawerOverlay } from 'src/core/ds/drawer';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getProLocations } from 'src/pages/get-pro/locations';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';
import { useQueryState, useQueryString } from 'src/utils/hooks';
import BatchPaymentRequestsDrawer from './components/BatchPaymentRequestsDrawer';
import CustomerDrawer from './components/CustomerDrawer';
import { InvoiceDrawer } from './components/InvoiceDrawer';
import InvoicePDFPreviewDrawer from './components/InvoicePDFPreviewDrawer';
import { RequestDrawer } from './components/RequestDrawer';

type Props = {
  selectedIds: Record<IdType<string>, boolean>;
  setSelectedIds: (ids: Record<IdType<string>, boolean>) => void;
  setClearSelected: () => void;
};

const CardDrawer: React.FC<Props> = ({ selectedIds, setSelectedIds, setClearSelected }) => {
  const query = useQueryString();
  const [selectedId, setSelectedId] = useQueryState('id');
  const [historyPush] = useHistoryWithOrgId();
  const history = useHistory();
  const currentDrawer = query.status || GetProTabs.INVOICES;
  const onClose = () => {
    setSelectedId(undefined);
    const { id, ...rest } = query;
    historyPush({
      path: getProLocations.dashboard,
      query: {
        ...rest,
      },
      state: {
        showBackButton: false,
      },
    });
  };
  const entity = useSelector(MAP_STORE_BY_TAB[currentDrawer].selectors.fetch.byId(selectedId));
  const batchEntities = useSelector(MAP_STORE_BY_TAB[GetProTabs.INVOICES].selectors.batchItems(selectedIds));
  const isBatchSend = !isEmpty(useRouteMatch(getProLocations.batchSend));
  const isOpen = !isEmpty(entity) || (!isEmpty(batchEntities) && isBatchSend);
  const onHeaderClick = useCallback(() => {
    historyPush({
      path: getProLocations.customer,
      query: {
        ...query,
        id: selectedId!,
      },
      state: {
        showBackButton: true,
      },
    });
  }, [historyPush, query, selectedId]);
  const onBackDrawer = () => history.goBack();
  const onBatchClose = () => {
    setSelectedIds({});
    setClearSelected();
    history.goBack();
  };
  const DrawerComponentByType = {
    [GetProTabs.INVOICES]: InvoiceDrawer,
    [GetProTabs.REQUESTS]: RequestDrawer,
    [GetProTabs.IN_PROGRESS]: InProgressDrawer,
    [GetProTabs.PAID]: PaidDrawer,
  };
  const DrawerComponent = DrawerComponentByType[currentDrawer] || InvoiceDrawer;

  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent maxW="75.8rem">
          <Switch>
            <SmartRoute path={getProLocations.view}>
              <DrawerComponent onHeaderClick={onHeaderClick} invoice={entity} onClose={onClose} />
            </SmartRoute>
            <SmartRoute path={getProLocations.edit}>
              <DrawerComponent onHeaderClick={onHeaderClick} invoice={entity} onClose={onClose} editMode />
            </SmartRoute>
            <SmartRoute path={getProLocations.customer}>
              <CustomerDrawer onBackDrawer={onBackDrawer} customer={entity?.customer} />
            </SmartRoute>
            <SmartRoute path={getProLocations.invoicePdfPreview}>
              <InvoicePDFPreviewDrawer onHeaderClick={onHeaderClick} onBackDrawer={onBackDrawer} invoice={entity} />
            </SmartRoute>
            <SmartRoute path={getProLocations.batchSend}>
              <BatchPaymentRequestsDrawer
                onCancel={onBatchClose}
                selectedInvoices={batchEntities}
                setClearSelected={setClearSelected}
              />
            </SmartRoute>
          </Switch>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default CardDrawer;
