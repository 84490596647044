import { CSSObject } from 'src/core/ds';

export const baseContainerStyles: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
};

export const baseItemStyles: CSSObject = {
  h: '4.2rem',
  py: 2,
  px: 3,
  boxSizing: 'border-box',
  _hover: {
    bgColor: 'grey.200',
  },
};
