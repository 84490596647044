import { Tab as ChakraTab, TabProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { analytics } from 'src/services/analytics';
import { ToNavigationType, TrackEventType } from 'src/utils/types';
import { linkStyle, unreadBadgeStyle } from './styles';

export type Props = TabProps & {
  to: ToNavigationType;
  label: string;
  trackEvent: TrackEventType;
  showUnreadBadge?: boolean;
};

export const Tab = ({ label, to, trackEvent, showUnreadBadge, ...rest }: Props) => {
  const handleClick = () => {
    const { name, page, properties } = trackEvent;
    analytics.track(`${name}-list`, page, properties);
  };

  return (
    <>
      <ChakraTab {...rest} data-testid={label}>
        <Link style={linkStyle} to={to} onClick={handleClick}>
          <MIFormattedText label={label} />
        </Link>
        {showUnreadBadge && <Box sx={unreadBadgeStyle} />}
      </ChakraTab>
    </>
  );
};
