import { SyncWithAccountingSoftwareCardProps } from 'src/pages/settings/types';
import { SSOLogoMap } from 'src/pages/settings/utils/accounting-software/SSOLogoMap';
import { AccountingSoftware } from 'src/utils/consts';
import { SyncWithSSOCard } from './components/SyncWithSSOCard';

export const SyncWithFreshbooksCard = ({ model, viewMode, onEdit, isLoading }: SyncWithAccountingSoftwareCardProps) => (
  <SyncWithSSOCard
    model={model}
    isLoading={isLoading}
    viewMode={viewMode}
    onEdit={onEdit}
    accountingSoftwareType={AccountingSoftware.FRESHBOOKS}
    accountingSoftwareLogo={SSOLogoMap[AccountingSoftware.FRESHBOOKS]}
  />
);
