import { generatePath, useParams } from 'react-router-dom';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import { useReceivingMethodConfig } from 'src/pages/receiving-method/context/ReceivingMethodContext';
import { receivingMethodLocations } from 'src/pages/receiving-method/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { AddAchFlow } from 'src/pages/vendor/delivery-methods/add-ach-flow/AddAchFlow';
import { FlowCustomizationsType } from '../types';

type Props = {
  orgId: number;
  vendorId: number;
};

export const AddAchFromReceivingMethodFlowWrapper = ({ orgId, vendorId }: Props) => {
  const params = useParams<{ orgId: string; vendorId: string; deliveryMethodId?: string }>();
  const { connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();
  const deliveryMethodId = params?.deliveryMethodId || '';
  const receivingMethodsUrl = generatePath(settingsLocations.receivingMethods, { orgId });
  const linkBankAccountUrl = generatePath(receivingMethodLocations.ach.linkBankAccount, {
    orgId,
    vendorId,
    deliveryMethodId,
  });

  const onNextAddMethod = () => {
    if (
      connectedAccountingPlatform?.isActive &&
      connectedAccountingPlatform?.accountReceivablesEnabled &&
      connectedAccountingPlatform?.paymentAccountReconciliationEnabled
    ) {
      return linkBankAccountUrl;
    }

    return receivingMethodsUrl;
  };

  const { title, subtitle, nextLabel } = {
    ...useReceivingMethodConfig({
      page: 'ReceivingMethodManualBankAccountPage',
    }),
  };

  const flowCustomizations: FlowCustomizationsType = {
    stepLayout: {
      hideHeader: true,
      title,
      subtitle,
      nextLabel,
      fullWidthCTA: true,
    },
    firstStep: {
      inputLabels: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
    },
  };

  return (
    <AddAchFlow
      vendorId={vendorId}
      deliveryMethodId={deliveryMethodId}
      flowCustomizations={flowCustomizations}
      saveAndContinue={onNextAddMethod}
    />
  );
};
