import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { MICreateOption } from 'src/components/common/MICreateOption';
import { MICreateOptionWithFileInput } from 'src/components/common/MICreateOptionWithFileInput';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import syncQuickbooks from 'src/images/accounting-software/quickbooks-logo.svg';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getContactType } from 'src/pages/contacts/utils';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { ACCEPT_FILES, ContactsTab } from 'src/utils/consts';
import intuit from 'src/utils/intuit';
import { NavigationCallbacks } from 'src/utils/types';

export const SelectCreateOption = (props: NavigationCallbacks) => {
  const match = useRouteMatch<{ type: string; orgId: string }>();
  const type: ContactsTab = getContactType(match?.params?.type);
  const orgId = match?.params?.orgId ? Number(match?.params?.orgId) : undefined;
  const history = useHistory();
  // TODO: remove this comment when quickbooks customers sync is deployed
  const isIntuitLinked = true; // useSelector(getIsIntuitLinked);

  const goToCreateUrl = (url: string, state: Record<string, any> = {}) => () => {
    history.push(generatePath(url, { orgId }), state);
  };
  const createSingle =
    type === ContactsTab.VENDORS ? contactsLocations.create.vendorsSingle : contactsLocations.create.customersSingle;
  const batchExplainUrl =
    type === ContactsTab.VENDORS
      ? contactsLocations.create.batchUploadVendorsExplain
      : contactsLocations.create.batchUploadCustomersExplain;
  const batchCreateUrl =
    type === ContactsTab.VENDORS
      ? contactsLocations.create.batchUploadVendorsReview
      : contactsLocations.create.batchUploadCustomersReview;
  const syncFromQuickBooks = () => {
    intuit.goConnectToIntuit({
      intuitReturnUrl: generatePath(onboardingLocations.accountingSoftware.quickbooks.backToBills, { orgId }),
    });
  };

  return (
    <StepLayoutPage title={`contacts.options.${type}.title`} subtitle={`contacts.options.${type}.subtitle`} {...props}>
      <CreateOptionsContainer>
        <MICreateOptionWithFileInput
          icon="icon-upload-file"
          label={`contacts.options.${type}.multi.title`}
          description={`contacts.options.${type}.multi.subtitle`}
          eventPage="contacts"
          accept={ACCEPT_FILES.TEXT_LISTS}
          onSelectFile={(file: File) => goToCreateUrl(batchCreateUrl, { file })()}
          testId="create-batch-contacts"
          linkLabel={`contacts.options.${type}.multi.link`}
          linkAction={goToCreateUrl(batchExplainUrl)}
        />
        <MICreateOption
          icon="icon-account-settings-icon"
          label={`contacts.options.${type}.single.title`}
          description={`contacts.options.${type}.single.subtitle`}
          onClick={goToCreateUrl(createSingle)}
          testId="create-single-contact"
        />
        {!isIntuitLinked && (
          <>
            <WizardElements.WizardOrLine />
            <MICreateOption
              imgSrc={syncQuickbooks}
              label={`contacts.options.${type}.qbo.title`}
              description={`contacts.options.${type}.qbo.subtitle`}
              onClick={syncFromQuickBooks}
              testId="quickbooks-connect"
            />
          </>
        )}
      </CreateOptionsContainer>
    </StepLayoutPage>
  );
};

const CreateOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
