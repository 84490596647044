import { ReactNode } from 'react';
import { Avatar } from 'src/core/ds/avatar';
import { Size } from 'src/core/ds/avatar/consts';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';

type Props = {
  companyName: string;
  description?: string | null;
  Icon?: ReactNode;
  onClick?: () => void;
};

export function CardHeader({ companyName, description, onClick, Icon }: Props) {
  return (
    <Box display="flex" onClick={onClick} __css={onClick && { cursor: 'pointer' }} data-testid="card-header">
      <Avatar name={companyName} size={Size.xl} textColor="white" />
      <Flex mx={4} direction="column" justify="center">
        <Box textStyle="body3Semi">{companyName}</Box>
        {description && (
          <Box textStyle="body3" mt={1} color="grey.700" className={FULL_STORY_MASK_RULE_CLASS}>
            {Icon}
            {Icon && <span>&nbsp;</span>}
            {description}
          </Box>
        )}
      </Flex>
    </Box>
  );
}
