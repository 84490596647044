import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Option } from 'src/components/form/MIRadioGroup';
import { OutsideLayout } from 'src/components/layout/OutsideLayout';
import { DockedButtonMobile } from 'src/components/mobile/DockedButtonMobile';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { RadioGroupField } from 'src/core/ds/form/fields/RadioGroupField';
import { Radio } from 'src/core/ds/radio';
import { getJWTPayload } from 'src/helpers/jwt';
import { useBreak } from 'src/hoc';
import { profileStore } from 'src/modules/profile/profile-store';
import { ContentWrapper } from 'src/pages/vendor/components/VendorLayoutElements';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { shiftVendorToAchLocations } from './locations';

type Props = {
  token: string;
  vendorId: string;
};

export const SwitchCompanyPage = ({ token, vendorId }: Props) => {
  const { payerCompanyName } = getJWTPayload(token);
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile } = useBreak();
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(orgId);

  const options = useMemo(
    () =>
      organizations.map<Option<number>>((o) => ({
        id: o.id,
        label: 'companies.acceptRequest.companyOption',
        labelValues: { companyName: o.companyName },
      })),
    [organizations]
  );

  const initUserContext = (orgId) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  const onCompanySwitch = async () => {
    if (orgId !== selectedOrganizationId) {
      await initUserContext(selectedOrganizationId);
    }

    history.push(generatePath(shiftVendorToAchLocations.addMethod, { token, id: vendorId }));
  };

  return (
    <>
      <OutsideLayout>
        <Flex
          direction="column"
          align="flex-start"
          bgColor="white"
          pos="relative"
          borderTopRadius="lg"
          py={10}
          px={{ base: 5, sm: 10 }}
        >
          <Box textStyle="h2Semi" isTruncated>
            <MIFormattedText
              label="vendors.deliveryMethods.shiftVendorToAch.addAchTitle"
              values={{
                companyName: (
                  <Box as="span" color="grey.700">
                    {payerCompanyName}
                  </Box>
                ),
              }}
            />
          </Box>
        </Flex>
        <ContentWrapper title="vendors.addDeliveryMethodByLink.switchCompanyTitle">
          <Box overflow="auto" maxH="40rem" pt={5}>
            <RadioGroupField
              id="organization"
              isRequired
              direction="column"
              value={selectedOrganizationId.toString()}
              name="organization"
              onChange={(id) => {
                setSelectedOrganizationId(Number(id));
              }}
            >
              {options.map((option) => (
                <Radio key={option.id} value={option.id.toString()} label={option?.labelValues?.companyName} />
              ))}
            </RadioGroupField>
          </Box>
          {!isMobile && (
            <Button onClick={onCompanySwitch} label="progress.continue" size={ButtonSizes.lg} isFullWidth />
          )}
        </ContentWrapper>
      </OutsideLayout>
      {isMobile && <DockedButtonMobile label="progress.continue" onClick={onCompanySwitch} />}
    </>
  );
};
