export const boxStyle = {
  cursor: 'pointer',
  bg: 'white',
  mb: 5,
  w: '100%',
  maxW: '45rem',
  borderRadius: 'lg',
  boxShadow: '400',
  py: 6,
  px: 5,
  transition: '300ms',
  transitionProperty: 'box-shadow',
  _hover: {
    '&:not(:active)': {
      boxShadow: '600',
    },
  },
};
