import { useSelector } from 'react-redux';
import { useApi } from 'src/hoc/useApi';
import { getOrgId } from 'src/redux/user/selectors';
import { accountsApi } from '../api';
import { DeleteAccountParams, UseDeleteAccountOptions } from '../types';

export const useDeleteAccount = () => {
  const orgId = useSelector(getOrgId);
  const { onApiCall: deleteAccount, loading, error } = useApi<[DeleteAccountParams], string>({
    api: accountsApi.delete,
  });

  return {
    loading,
    error,
    deleteAccount: ({ accountId }: UseDeleteAccountOptions) => deleteAccount({ orgId, accountId }),
  };
};
