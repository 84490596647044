import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Icon, IconSize } from 'src/core/ds/icon';
import { devices } from 'src/theme/appDevices';
import { StatusInfoType } from 'src/utils/types';

type Props = {
  statusInfo: StatusInfoType;
  className?: string;
};

export const BillStatus = ({ statusInfo, className }: Props) => {
  const { color, icon, iconName, label, description, descriptionValues, status } = statusInfo;
  let renderIcon;

  // This condition is to support the new DS
  if (iconName) {
    renderIcon = <Icon name={iconName} size={IconSize.xl} color={color} />;
  } else if (typeof icon === 'string') {
    renderIcon = <i className={icon} />;
  } else {
    renderIcon = icon;
  }

  return (
    <StatusContainer statusInfo={statusInfo} className={className} data-testid="bill-status">
      <Icons color={color} label={label} status={status}>
        {renderIcon}
      </Icons>
      <Status color={color} status={status}>
        <MIFormattedText label={label} />
        {description && (
          <StatusDescriptionText>
            <MIFormattedText label={description} values={descriptionValues} />
          </StatusDescriptionText>
        )}
      </Status>
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: ${(props) => props.statusInfo.colorOpacity};
  padding: 0 3rem;

  @media ${devices.mobile} {
    padding: 0 1.5rem;
  }

  ${(props) => props.theme?.components?.BillStatus?.StatusContainer}
`;

const Status = styled.div`
  color: ${(props) => props.color};
  font-weight: ${(props) => props.theme.text.weight.semiBold};
  padding: 1.2rem 0 1.2rem 1.3rem;
  text-align: left;
  align-self: center;

  @media ${devices.mobile} {
    padding: 1.2rem 0 1.2rem 0.4rem;
  }
  ${(props) => props.theme.text.fontType.medium};
  ${(props) => props.theme?.components?.BillStatus?.Status}
`;

const Icons = styled.div`
  font-size: 3rem;
  color: ${(props) => props.color};
  margin: 1.9rem 1.1rem 1.5rem 0;
  ${(props) => props.theme?.components?.BillStatus?.Icon}
`;

const StatusDescriptionText = styled.div`
  color: ${(props) => props.theme.text.color.main};
  font-size: 1.2rem;
  font-weight: ${(props) => props.theme.text.weight.regular};
  opacity: 0.7;
  ${(props) => props.theme?.components?.BillStatus?.StatusDescriptionText}
`;
