import { hashListKey } from 'src/helpers/redux/createRestfulSlice';
import { createListSlice } from 'src/helpers/redux/restListSlice';
import { getProApi } from 'src/modules/get-pro/api';
import { name } from './../constants';

const proInvoicesListSlice = createListSlice({
  storeName: name,
  api: getProApi.invoices.list,
  actionName: 'proList',
  listHashFunc: hashListKey,
});

export default proInvoicesListSlice;
