import pick from 'lodash/pick';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { BatchActionParams } from 'src/modules/regular-batch-payments/regular-batch-bills-settings-slice';
import regularBatchPaymentsStore from 'src/modules/regular-batch-payments/regular-batch-payments-store';
import { ALL_AT_ONCE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { useBatchBillsList } from 'src/pages/regular-batch-payments/hooks/useBatchBillsList';
import { useSetPaymentsMemo } from 'src/pages/regular-batch-payments/hooks/useSetPaymentsMemo';
import { getOrgId } from 'src/redux/user/selectors';
import { MainTableRowType } from '../components/table/types';
import { BatchPaymentListHasNoSubRows, canGroupBatchPaymentsList, isBatchPaymentRiskTotalAmountValid } from '../utils';

export type ChangeBatchSettingParams = {
  fundingSourceId: number;
  scheduledDate: string | null;
  minScheduledDate: string | null;
  dateOption: string;
  bulkPaymentToggleOn: boolean;
};

type UseBatchSettingsChangeType = {
  changeBatchSetting: (params: Partial<BatchActionParams>, shouldLoadMemo?: boolean) => Promise<void>;
  isBulkPaymentsToggleDisabled: boolean;
  bulkPaymentToggleOn: boolean;
  invalidBatchPaymentListGrouping: boolean;
  invalidBatchPaymentRiskTotalAmount: boolean;
  getParentBatchItemByBillId: (id: string) => MainTableRowType;
};

export const useBatchSettingsChange = (): UseBatchSettingsChangeType => {
  const actions = useStoreActions(regularBatchPaymentsStore);
  const orgId = useSelector(getOrgId);
  const { bulkPaymentToggleOn } = useSelector(regularBatchPaymentsStore.selectors.settings.settings);
  const { billsList, loadBillsList } = useBatchBillsList();

  const currentSettingsParams: ChangeBatchSettingParams & { billIds: number[] } = useSelector(
    regularBatchPaymentsStore.selectors.settings.settings
  );

  const updateBatchSetting = async (params: ChangeBatchSettingParams) => {
    await actions.settings.update({ orgId, billIds: currentSettingsParams.billIds, ...params });
  };

  const { setPaymentsMemo } = useSetPaymentsMemo();

  const changeBatchSetting = async (params: Partial<BatchActionParams>, shouldLoadMemo = false) => {
    const allParams = { ...currentSettingsParams, ...params };
    const { fundingSourceId, scheduledDate } = allParams;

    let updatedSettingsParams = allParams;

    if (allParams.dateOption === ALL_AT_ONCE_OPTION_ID) {
      const { scheduleDate: minScheduledDate } = await deliveryApi.getEffectiveScheduleDate({
        orgId,
        scheduledDate: new Date(),
        fundingSourceId,
      });
      const updatedScheduledDate = scheduledDate
        ? Math.max(new Date(scheduledDate).getTime(), new Date(minScheduledDate).getTime())
        : new Date(minScheduledDate).getTime();

      updatedSettingsParams = {
        ...allParams,
        scheduledDate: new Date(updatedScheduledDate).toISOString(),
        minScheduledDate,
      };
    } else {
      updatedSettingsParams = {
        ...allParams,
        scheduledDate: null,
      };
    }

    await updateBatchSetting(updatedSettingsParams);
    const {
      payload: { items },
    } = await loadBillsList({
      ...pick(updatedSettingsParams, ['fundingSourceId', 'scheduledDate']),
      isGroupedByVendor: updatedSettingsParams?.bulkPaymentToggleOn,
    });

    if (shouldLoadMemo) {
      setPaymentsMemo(items);
    }
  };

  const invalidBatchPaymentListGrouping = useMemo(
    () =>
      (!bulkPaymentToggleOn && !canGroupBatchPaymentsList(billsList)) ||
      (bulkPaymentToggleOn && BatchPaymentListHasNoSubRows(billsList)),
    [bulkPaymentToggleOn, billsList]
  );

  const invalidBatchPaymentRiskTotalAmount = useMemo(
    () => !isBatchPaymentRiskTotalAmountValid(billsList, bulkPaymentToggleOn),
    [billsList, bulkPaymentToggleOn]
  );

  const isBulkPaymentsToggleDisabled = useMemo(
    () => invalidBatchPaymentListGrouping || invalidBatchPaymentRiskTotalAmount,
    [invalidBatchPaymentListGrouping, invalidBatchPaymentRiskTotalAmount]
  );

  const getParentBatchItemByBillId = useCallback(
    (id: string) => {
      let batchItem;

      if (bulkPaymentToggleOn && !isBulkPaymentsToggleDisabled) {
        batchItem = billsList.find((batchItem) => batchItem.subRows?.find((subRow) => subRow.id === id));
      } else {
        batchItem = billsList.find((batchItem) => batchItem.payment.bills[0].id === id);
      }

      return batchItem!;
    },
    [billsList, bulkPaymentToggleOn, isBulkPaymentsToggleDisabled]
  );

  return {
    changeBatchSetting,
    isBulkPaymentsToggleDisabled,
    bulkPaymentToggleOn,
    invalidBatchPaymentListGrouping,
    invalidBatchPaymentRiskTotalAmount,
    getParentBatchItemByBillId,
  };
};
