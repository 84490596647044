export const getCheckAddressNextLabelForNewState = (
  isRetryReturnedCheckPayment: boolean,
  isUndepositedCheckPayment: boolean,
  isVendorOrigin: boolean
) => {
  if (isRetryReturnedCheckPayment || isUndepositedCheckPayment) {
    return 'vendors.deliveryMethods.check.confirmAndContinue';
  }

  if (isVendorOrigin) {
    return 'vendors.deliveryMethods.check.saveAndAddBill';
  }

  return 'vendors.deliveryMethods.check.submit';
};
