import { useState } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import IconButton from 'src/core/ds/iconButton/IconButton';
import Tooltip from 'src/core/ds/tooltip';
import { analytics } from 'src/services/analytics';
import { ImageView } from './components/ImageView';
import { Pagination } from './components/Pagination';
import { buttonRemoveFile, downloadButtonWrapper } from './styles';

type Props = {
  fileStorageUrl?: string | null;
  filePreviewUrls?: string[] | null;
  onUploadRemove?: () => void;
};

export const InvoiceFilePreview = ({ filePreviewUrls, fileStorageUrl, onUploadRemove }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onNextPageClick = () => {
    analytics.track('bill', 'view-next-doc-page');
    setCurrentPage(currentPage + 1);
  };

  const onPrevPageClick = () => {
    analytics.track('bill', 'view-prev-doc-page');
    setCurrentPage(currentPage - 1);
  };

  const numPages = (filePreviewUrls && filePreviewUrls.length) || 0;

  const showDelete = !!(onUploadRemove && fileStorageUrl);

  const showDownload = !!fileStorageUrl && !onUploadRemove;

  return (
    <>
      <Flex
        h={{ base: 'inherit', md: 'full' }}
        align="center"
        justify="center"
        borderRadius={fileStorageUrl?.length ? 'lg' : 'none'}
        mx={5}
      >
        {fileStorageUrl && (
          <ImageView currentPage={currentPage} filePreviewUrls={filePreviewUrls} fileStorageUrl={fileStorageUrl} />
        )}
        {numPages > 1 && (
          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            onNextPageClick={onNextPageClick}
            onPrevPageClick={onPrevPageClick}
          />
        )}
      </Flex>
      {showDelete ? (
        <Flex justify="center" alignContent="space-between">
          <Button label="bills.new.removeFile" onClick={onUploadRemove} sx={buttonRemoveFile} />
        </Flex>
      ) : null}
      {showDownload ? (
        <Box __css={downloadButtonWrapper}>
          <Tooltip placement="top" label={<MIFormattedText label="bills.new.downloadFile" />}>
            <IconButton
              as="a"
              href={fileStorageUrl}
              aria-label="download"
              pointerEvents="auto"
              download
              icon={<Icon name={IconNames.download} />}
              variant="action"
            />
          </Tooltip>
        </Box>
      ) : null}
    </>
  );
};
