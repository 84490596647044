import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { financingStore } from 'src/modules/financing/financing-store';
import { getPayment } from 'src/redux/payBillWizard/selectors';
import { getOrgId } from 'src/redux/user/selectors';
import { SetInstallmentsOptionsResponse } from '../installments/types';

export const useSetInstallmentOption = (): {
  intentId: string;
  numberOfInstallments: number;
  result: SetInstallmentsOptionsResponse;
  loading: boolean;
  error: string;
  setInstallmentOption: () => void;
} => {
  const orgId = useSelector(getOrgId);
  const payment = useSelector(getPayment);
  const intentId = useSelector(financingStore.selectors.intentIdWithInstallmentOption.intentId);
  const numberOfInstallments = useSelector(financingStore.selectors.intentIdWithInstallmentOption.numberOfInstallments);
  const { setInstallmentOption } = useStoreActions(financingStore);
  const { data: result, loading, error } = useSelector(financingStore.selectors.setInstallmentOption.status);

  const invokeSetInstallmentOption = (): void => {
    setInstallmentOption({
      orgId,
      intentId,
      numberOfInstallments,
      paymentAmount: payment.amount,
      scheduleDate: payment.scheduledDate,
    });
  };

  return {
    intentId,
    numberOfInstallments,
    result,
    loading,
    error,
    setInstallmentOption: invokeSetInstallmentOption,
  };
};
