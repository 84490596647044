import isEmpty from 'lodash/isEmpty';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { authApi } from 'src/modules/auth/api';
import clientService from 'src/services/api/clientService';

type ChangePasswordStorePayloadType = {
  currentPassword: string;
  password: string;
  confirmedPassword: string;
};

type ChangePasswordStoreStateType = {
  changingPassword: boolean;
};

const changePasswordStore = createApiCallSlice<ChangePasswordStorePayloadType, ChangePasswordStoreStateType>({
  name: '[PROFILE] PASSWORD_CHANGE',
  api: ({ currentPassword, password }) => authApi.changePassword(currentPassword, password),
  reducers: {
    [ON_REQUEST]: (state) => {
      state.changingPassword = true;
    },
    [ON_FAILURE]: (state) => {
      state.changingPassword = false;
    },
    [ON_SUCCESS]: (state) => {
      state.changingPassword = false;
    },
  },
  async validate(credentials: ChangePasswordStorePayloadType) {
    const { validationErrors } = await clientService.getPasswordValidationErrors(
      'userRegistration',
      credentials,
      Object.keys(credentials) as (keyof ChangePasswordStorePayloadType)[]
    );

    return isEmpty(validationErrors) ? null : validationErrors;
  },
});

export default changePasswordStore;
