import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuItem, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { analytics } from 'src/services/analytics';
import { ActionOptionType } from './types';

type Props = {
  actions: readonly ActionOptionType[];
  analyticsProps: Record<string, any>;
};

export const BatchActionsMenu: React.FC<Props> = ({ actions, analyticsProps }) => {
  const onMenuClick = () => {
    analytics.track('contacts', 'batch-unilateral-actions-menu-click', analyticsProps);
  };

  const onActionClick = () => {
    analytics.track('contacts', 'batch-unilateral-action-click', analyticsProps);
  };

  return (
    <Menu autoSelect={false} placement="top-end">
      <MenuButton
        onClick={onMenuClick}
        data-testid="vendor-batch-actions-menu"
        pt={2}
        pr={4}
        pb={2}
        pl={5}
        variant={ButtonVariants.primary}
        as={Button}
        rightIcon={<ChevronDownIcon width="8px" height="5px" />}
        size={ButtonSizes.lg}
      >
        <MIFormattedText label="actionsMenu.label" />
      </MenuButton>
      <MenuList>
        {actions.map((a) => {
          const { label, action } = a;

          return (
            <MenuItem
              data-testid={`button-${a.label}`}
              key={label}
              as={Button}
              boxSizing="border-box"
              width="100%"
              onClickCapture={onActionClick}
              onClick={action}
              label={label}
              color="black"
            />
          );
        })}
      </MenuList>
    </Menu>
  );
};
