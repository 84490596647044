import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { authLocations } from 'src/pages/auth/locations';

export const RegistrationInvalidInvitation = () => {
  const [historyPush] = useHistoryWithOrgId();

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.errorScreen}
      title="auth.registrationInvalidInvitation.title"
      text="auth.registrationInvalidInvitation.subtitle"
      buttonLabel="auth.registrationInvalidInvitation.buttonLabel"
      buttonAction={() => historyPush({ path: authLocations.login })}
    />
  );
};
