import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { Image } from 'src/core/ds/image';
import NotFoundImg from 'src/images/general/full-moon.svg';

const UnsupportedResolutionLayout = () => (
  <SuccessLayoutPage
    title="regularBatchPayments.unsupportedResolution.title"
    text="regularBatchPayments.unsupportedResolution.description"
    hasLogo
  >
    <Image src={NotFoundImg} m={{ base: '-5rem 0 0 0', md: '-3rem 0 2rem' }} h={{ base: '34rem', md: '24rem' }} />
  </SuccessLayoutPage>
);

export default UnsupportedResolutionLayout;
