import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { analytics as analyticsService } from 'src/services/analytics';

export const MODULE_NAME = 'spend-management';

export enum Page {
  LOGIN = 'login',
  SIGNUP = 'signup',
  ORG_DETAILS = 'org-details',
  ORG_SETUP_DONE = 'org-setup-done',
  ACCOUNT_ENROLLED = 'account-enrolled',
  EXTENSION_SUCCESS = 'extension-success',
  CARD_LIST = 'cards-list',
  TRANSACTION_LIST = 'transactions-list',
  SETTINGS = 'settings',
}

export enum Context {
  ENROLL_PROGRESS = 'enroll-progress',
  EMPTY_STATE = 'empty-state',
  CARD_DETAILS_DRAWER = 'card-details-drawer',
  CARD_DETAILS_DRAWER_TERMINATE_CARD_MODAL = 'card-details-drawer-terminate-card-modal',
  NEW_CARD_DRAWER = 'new-card-drawer',
  TRANSACTION_DETAILS_DRAWER = 'transaction-details-drawer',
  ACCOUNT_INDICATOR = 'account-indicator',
  VIRTUAL_CARDS = 'virtual-cards',
  TRANSACTIONS = 'transactions',
  EXTENSION = 'extension',
  ONBOARDING_PROCESS = 'on-boarding',
}

export enum Event {
  CLICK_CONTINUE = 'click-continue',
  CLICK_TOS = 'click-tos',
  CLICK_PRIVACY = 'click-privacy',
  VALIDATION_ERROR = 'validation-error',
  CONTINUE_FAILED = 'continue-failed',
  CONTINUE_SUCCESS = 'continue-success',
  SET_DEFAULT_ACCOUNT_SUCCESS = 'set-default-account-success',
  CLICK_SAVE = 'click-save',
  CLICK_CHANGE_ACCOUNT = 'click-change-account',
  CLICK_ENROLL = 'click-enroll',
  CLICK_DASHBOARD = 'click-dashboard',
  SUCCESS = 'success',
  FAILED = 'failed',
  FAILED_TIMEOUT = 'failed-timeout',
  CLICK_INSTALL = 'click-install',
  CLICK_CREATE_CARD = 'click-create-card',
  CLICK_CARD = 'click-card',
  PAGE_CHANGE = 'page-change',
  CLICK_TAB_TRANSACTIONS = 'click-tab-transactions',
  CLICK_CANCEL = 'click-cancel',
  CLICK_CLOSE_X = 'click-close-x',
  CLICK_CLOSE_BG = 'click-close-bg',
  CLICK_TERMINATE_CARD = 'click-terminate-card',
  CLICK_TRANSACTION = 'click-transaction',
  CLICK_TAB_CARDS = 'click-tab-cards',
  MENU_OPEN = 'menu-open',
  MENU_CLOSE = 'menu-close',
  CLICK_SETTINGS = 'click-settings',
  CLICK_AMEX = 'click-amex-site',
  DISPLAY_TRANSACTIONS = 'display-transactions',
  MORE_ACTIONS_OPENED = 'more-actions-opened',
  MORE_ACTIONS_CLOSED = 'more-actions-closed',
  VIEW_TRANSACTIONS = 'view-transaction',
  DEACTIVATE_CARD = 'deactivate-card',
  DEACTIVATE_CARD_CANCEL = 'deactivate-card-cancel',
  DEACTIVATE_CARD_CONFIRM = 'deactivate-card-confirm',
  REVEAL_DETAILS = 'reveal-details',
  HIDE_DETAILS = 'hide-details',
  COPY_NAME = 'copy-name-on-card',
  COPY_CC_NUMBER = 'copy-cc-number',
  COPY_CC_EXPIRED = 'copy-cc-expired',
  COPY_CVV = 'copy-cvv',
  COPY_ADDRESS = 'copy-address',
  COPY_ZIPCODE = 'copy-zipcode',
  ACCOUNT_DISCONNECT = 'disconnect-card',
  ACCOUNT_CONNECT = 'connect-card',
}

export const pathToPageNames: Record<typeof spendManagementLocations[keyof typeof spendManagementLocations], Page> = {
  [spendManagementLocations.onboarding]: Page.ORG_DETAILS,
  [spendManagementLocations.enroll]: Page.ORG_SETUP_DONE,
  [spendManagementLocations.installExtension]: Page.ACCOUNT_ENROLLED,
  [spendManagementLocations.extensionSuccess]: Page.EXTENSION_SUCCESS,
  [spendManagementLocations.cardsList]: Page.CARD_LIST,
  [spendManagementLocations.transactionsList]: Page.TRANSACTION_LIST,
};

type OmitFirstArgs<F> = F extends (x: any, y: any, ...args: infer P) => infer R ? (...args: P) => R : never;

export const analytics = {
  page: (pageName, ...rest) => analyticsService.page(MODULE_NAME, pageName, ...rest),
  pageByPath: (path, ...rest) =>
    pathToPageNames[path] && analyticsService.page(MODULE_NAME, pathToPageNames[path], ...rest),
  // eslint-disable-next-line max-params
  track: (pageName, context, event, ...rest) =>
    analyticsService.track(`${MODULE_NAME}-${pageName}`, context ? `${context}-${event}` : event, ...rest),
} as {
  page: (page: Page, ...args: [...Parameters<OmitFirstArgs<typeof analyticsService.page>>]) => void;
  pageByPath: (
    path: keyof typeof pathToPageNames,
    ...args: [...Parameters<OmitFirstArgs<typeof analyticsService.page>>]
  ) => void;
  track: (
    page: Page,
    context: Context | undefined,
    event: Event,
    ...args: [...Parameters<OmitFirstArgs<typeof analyticsService.track>>]
  ) => void;
};
