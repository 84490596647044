import React, { useMemo } from 'react';
import Box from 'src/core/ds/box';
import { getFormatTableDate } from '../utils';

type Props = {
  date: string;
};

export const TableDate: React.FC<Props> = ({ date }) => {
  const formatDate = getFormatTableDate(date);

  return useMemo(
    () => (
      <Box isTruncated role="cell" aria-label={formatDate}>
        {formatDate}
      </Box>
    ),
    [formatDate]
  );
};
