import sumBy from 'lodash/sumBy';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { IdType } from 'react-table';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { InvoiceType } from 'src/modules/invoices/types';
import { MAP_STORE_BY_TAB } from 'src/pages/get-pro/utils';
import { GetProTabs } from 'src/utils/consts';

type Props = {
  totalSum?: number;
  totalNumberOfItems?: number;
  selectedIds?: Record<IdType<string>, boolean>;
  tab?: GetProTabs;
};

const TotalLabel: React.FC<Props> = ({
  totalSum = 0,
  totalNumberOfItems = 0,
  selectedIds,
  tab = GetProTabs.INVOICES,
}) => {
  const store = MAP_STORE_BY_TAB[tab];
  const batchEntities: InvoiceType[] = useSelector(store.selectors.batchItems(selectedIds));
  const totalBatchSum = sumBy(batchEntities, (entity) => Number(entity.totalAmount));

  return (
    <>
      {batchEntities.length > 0 ? (
        <Box textStyle="body4Semi">
          <MIFormattedText
            label="getPro.table.summary.totalSelected"
            values={{
              numberOfItems: batchEntities.length,
              amount: (
                <FormattedNumber
                  value={Number(totalBatchSum)}
                  maximumFractionDigits={2}
                  style="currency"
                  currency="USD"
                />
              ),
            }}
          />
        </Box>
      ) : (
        <Box textStyle="body4">
          <MIFormattedText
            label="getPro.table.summary.total"
            values={{
              numberOfItems: totalNumberOfItems,
              amount: (
                <FormattedNumber value={Number(totalSum)} maximumFractionDigits={2} style="currency" currency="USD" />
              ),
            }}
          />
        </Box>
      )}
    </>
  );
};

export default TotalLabel;
