import { MILink } from 'src/components/common/MILink';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';

type UserExistNotificationCardProps = {
  errorCode: string;
  onLogin?: () => void;
  preserveState?: boolean;
};

const UserExistErrorNotificationCard = ({ errorCode, onLogin, preserveState }: UserExistNotificationCardProps) => (
  <Box mb={10}>
    <MINotificationCard
      type={NotificationCardTypes.ERROR}
      subtitle={{
        label: `server.${errorCode}`,
        values: {
          login: (
            <MILink
              to={onLogin || '/login'}
              label="server.ALREADY_EXISTS_CONTINUE"
              plain
              preserveState={preserveState}
            />
          ),
        },
      }}
    />
  </Box>
);

export { UserExistErrorNotificationCard };
