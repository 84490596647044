import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

type Props = {
  titleLabel: string;
  emptyLabel?: string;
  value?: React.ReactNode;
  isTruncated?: boolean;
};

const CardField: React.FC<Props> = ({ titleLabel, emptyLabel, value = '', isTruncated }) => (
  <Flex direction="column" h="6rem" isTruncated={isTruncated}>
    <Box textStyle="body4Semi" color="grey.600" mb={0.5}>
      <MIFormattedText label={titleLabel} />
    </Box>
    {value ? (
      <Box isTruncated={isTruncated} textStyle="body2">
        {value}
      </Box>
    ) : (
      <Box isTruncated={isTruncated} textStyle="body2" color="grey.400">
        {emptyLabel && <MIFormattedText label={emptyLabel} />}
      </Box>
    )}
  </Flex>
);

export default CardField;
