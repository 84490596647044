import { ChangeEvent } from 'react';
import { forwardRef } from 'src/core/ds';
import { PinInputField } from 'src/core/ds/input';

export const PinInputFieldWithForceSelectionRange = forwardRef((props, ref) => (
  <PinInputField
    {...props}
    ref={ref}
    onSelect={(e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.selectionStart === 0) {
        e.target.setSelectionRange(1, 1);
      }
    }}
  />
));
