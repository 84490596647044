import Box from 'src/core/ds/box';
import { Icon, IconProps, IconSize } from 'src/core/ds/icon';
import { modalImageStyle } from './style';

type Props = IconProps;

export const ModalIcon = ({ size = IconSize['2xl'], ...rest }: Props) => (
  <Box __css={modalImageStyle}>
    <Icon size={size} {...rest} />
  </Box>
);
