import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { SandboxIndicator } from 'src/components/common/SandboxIndicator';
import { TabapayMigrationModal } from 'src/components/funding-source/tabapay/components/TabapayMigrationModal';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useBreak } from 'src/hoc';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useNewTabapayUrl } from 'src/hooks/useNewTabapayUrl';
import { devices } from 'src/theme/appDevices';
import { DialogType, DialogVariants, FULL_STORY_MASK_RULE_CLASS } from 'src/utils/consts';
import { isSandboxIndicator } from 'src/utils/funding-sources';

type Props = {
  goNext: () => void;
  goExit: () => void;
  onError: () => void;
  loadIframe: () => void;
  isLoading: boolean;
  errorCode: string | null | undefined;
  onHideErrorMessageAlert: () => void;
};

const AddCardAccountsPage = ({
  goExit,
  goNext,
  isLoading,
  loadIframe,
  errorCode,
  onHideErrorMessageAlert,
  onError,
}: Props) => {
  const site = useSiteContext();

  const { isDesktop, isPhablet } = useBreak();
  const iFrameWidth = isDesktop || isPhablet ? '700px' : '200px';
  const iFrameHeight = '470px';
  const isSandboxIndicatorShown = isSandboxIndicator(site);
  const { iframeSrc } = useNewTabapayUrl();

  return (
    <>
      <StepLayoutPage
        goExit={goExit}
        onNext={goNext}
        isLoading={isLoading}
        title="onboarding.fundingSources.card.iframeTitle"
        innerSize={75}
        isHideActions
        hideHeader
        isSandboxIndicatorShown={isSandboxIndicatorShown}
      >
        {errorCode && (
          <Dialog
            type={DialogType.ALERT}
            variant={DialogVariants.ERROR}
            title="onboarding.fundingSources.card.notSupportedTitle"
            subtitle={`server.${errorCode}`}
            okButtonText="dialogs.buttons.close"
            cancelButtonText="dialogs.buttons.close"
            onOkAction={onHideErrorMessageAlert}
            onCancelAction={onHideErrorMessageAlert}
          />
        )}
        <TabapayMigrationModal goExit={goExit} />
        <IframeContainer>
          {isLoading && (
            <LoaderContainer>
              <AreaLoader />
            </LoaderContainer>
          )}
          <TabapayIframe
            isLoading={isLoading}
            onLoad={loadIframe}
            title="taba pay SSO"
            id="sso"
            onError={onError}
            src={iframeSrc}
            frameBorder="0"
            scrolling="no"
            width={iFrameWidth}
            height={iFrameHeight}
          />
          <SecurityDetailsContainer>
            <MISecurityDetails eventPage="payment-method-credit-card" />
          </SecurityDetailsContainer>
        </IframeContainer>
      </StepLayoutPage>
      {isSandboxIndicatorShown && !site.embeddedMode && <SandboxIndicator />}
    </>
  );
};

AddCardAccountsPage.defaultProps = {};

export default AddCardAccountsPage;

const LoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -20%);
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.LoaderContainer}
`;
const TabapayIframe = styled.iframe<{ isLoading?: boolean }>`
  opacity: ${(props) => (props.isLoading ? '0' : '1')};
  width: 1px;
  min-width: 100%;
  width: 100%;
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.TabapayIframe}
`;

const IframeContainer = styled.div.attrs({ className: FULL_STORY_MASK_RULE_CLASS })`
  position: relative;
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.IframeContainer}
`;

const SecurityDetailsContainer = styled.div`
  width: 45rem;
  margin: 0 auto;

  @media ${devices.mobile}, ${devices.phablet} {
    width: 34rem;
  }
  ${(props) => props.theme?.pages?.AddCardAccountsPage?.SecurityDetailsContainer}
`;
