import { useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box/Box';
import { Button } from 'src/core/ds/button';
import { IllustrationName } from 'src/core/ds/illustration';
import { Stack } from 'src/core/ds/stack';
import businessApprovedStatusProgressBar from 'src/images/onboarding/msn-registration/business-approved-status-progress-bar.svg';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { globalLocations } from 'src/pages/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { ImageWithAlt } from 'src/pages/onboarding/msn-registration/public-form/components/ImageWithAlt';
import { getCompanyInfo, getDeliveryMethods, getOrgId } from 'src/redux/user/selectors';

export const CompanyVerifiedPage = () => {
  const organizationId = useSelector(getOrgId);
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const user = useSelector(profileStore?.selectors?.profile);
  const companyInfo = useSelector(getCompanyInfo);
  const { navigate } = useNavigator();
  const orgId = useSelector(getOrgId);
  const accountNumber4digits = Number(deliveryMethod?.bankAccount?.accountNumber?.substr(-4));

  if (!organizationId) return <Redirect to={onboardingLocations.msnRegistration.register} />;

  const navigateGetPaidDashboard = () => {
    const redirectRoute = generatePath(`${globalLocations.getPro.base}?status=inProgress`, {
      orgId,
    });
    navigate(redirectRoute);
  };

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.checkCircle}
      title="onboarding.msnCompanyInfo.companyVerified.title"
      titleValues={{ companyName: companyInfo.companyName }}
      text="onboarding.msnCompanyInfo.companyVerified.text"
      textValues={{ accountNumber4digits }}
      hideHeader
    >
      <Stack spacing={10}>
        <ImageWithAlt
          src={businessApprovedStatusProgressBar}
          testid="company-verified-image"
          altLabel="onboarding.msnCompanyInfo.companyVerified.imageAlt"
        />
        {user?.isGuest ? null : (
          <Box>
            <Button
              onClick={navigateGetPaidDashboard}
              label="onboarding.msnCompanyInfo.deliveryMethodStatus.continueButton"
            />
          </Box>
        )}
      </Stack>
    </SuccessLayoutPage>
  );
};
