import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Link } from 'src/core/ds/link';
import { analytics } from 'src/services/analytics';
import { intercomService } from 'src/services/intercom';
import { ButtonsRowPosition, DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  onOkAction: () => void;
};

export const MicroDepositsLimitReachedDialog = ({ onOkAction }: Props) => {
  const LEARN_MORE_LINK =
    'https://melio.zendesk.com/hc/en-us/articles/4620783240092-Failed-verification-of-bank-account-with-micro-deposits';

  const goLearnMore = () => {
    analytics.track('micro-deposits-unable-to-verify', 'click-learn-more');
  };

  const openSupportChat = () => {
    analytics.track('micro-deposits-unable-to-verify', 'support-open-start-chat');
    intercomService.show();
  };

  return (
    <Dialog
      type={DialogType.CONFIRM}
      variant={DialogVariants.ERROR}
      title="settings.microDeposits.verifyDialogLimitReached.title"
      subtitle="settings.microDeposits.verifyDialogLimitReached.subtitle"
      subtitleValues={{
        learnMore: (
          <Link href={LEARN_MORE_LINK} target="_blank" isExternal onClick={goLearnMore}>
            <MIFormattedText label="settings.microDeposits.verifyDialogLimitReached.learnMoreLink" />
          </Link>
        ),
        liveChat: (
          <Box as="a" cursor="pointer" onClick={openSupportChat} textStyle="link1">
            <MIFormattedText label="settings.microDeposits.verifyDialogLimitReached.liveChat" />
          </Box>
        ),
      }}
      okButtonText="settings.microDeposits.verifyDialogLimitReached.dismiss"
      onOkAction={onOkAction}
      buttonsRowPosition={ButtonsRowPosition.RIGHT}
      hideClose
    />
  );
};
