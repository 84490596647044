import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications/notificationService';
import { CopyLinkSize, NotificationVariant } from 'src/utils/consts';
import { removeProtocol } from 'src/utils/string-utils';

type Props = {
  link: string;
  clipboardText: string;
  buttonLabel: string;
  linkLabel: string;
  onCopy?: () => void;
  onCancelAction?: () => void;
  size?: string;
};

export const MICopyWithPreviewLink = ({
  link,
  clipboardText,
  buttonLabel,
  linkLabel,
  onCopy,
  onCancelAction,
  size,
}: Props) => {
  const onGoToLink = () => {
    analytics.trackAction('copy-link-action');
  };

  const handleOnCopy = () => {
    analytics.trackAction('copy-link');
    onCopy && onCopy();
    pushNotification({
      type: NotificationVariant.INFO,
      msg: 'requests.melioMeLinkCard.toastMessage',
    });
    onCancelAction && onCancelAction();
  };

  return (
    <>
      <Flex
        flexDir="row"
        justify="space-between"
        align="center"
        maxW="45.2rem"
        w="calc(100% - 1.4rem)"
        bgColor={size === CopyLinkSize.NORMAL ? 'white' : 'grey.300'}
        borderRadius="lg"
        pl={size === CopyLinkSize.NORMAL ? 0 : 4}
      >
        <Box textStyle={{ base: 'body1', sm: 'body2' }} color="black" isTruncated pr={14}>
          {removeProtocol(link)}
        </Box>
        <CopyToClipboard onCopy={handleOnCopy} text={clipboardText} data-testid="copy-link-to-clipboard">
          <Button
            variant={ButtonVariants.primary}
            label={buttonLabel}
            size={ButtonSizes.lg}
            textStyle="caption1Semi"
            minW="13rem"
            px={3}
            borderRightRadius="md"
            borderLeftRadius="none"
          />
        </CopyToClipboard>
      </Flex>
      <Flex w="full" mt={4}>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onGoToLink}
          data-testid="meliome-preview-link"
          alignItems="center"
          textStyle="link3"
          minWidth="10rem"
          pr={2}
        >
          <MIFormattedText label={linkLabel} />
        </Link>
      </Flex>
    </>
  );
};
