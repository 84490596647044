import { Modal as ChakraModal, ModalCloseButton, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react';
import {
  ModalBody,
  ModalCover,
  ModalFooter,
  ModalHeader,
  ModalHeaderSubTitle,
  ModalHeaderTitle,
  ModalIcon,
  ModalIllustration,
} from './components';

type Props = ModalProps & {
  isMarketingModal?: boolean;
};

const Modal = ({ children, scrollBehavior = 'inside', isCentered = true, ...rest }: Props) => (
  <ChakraModal scrollBehavior={scrollBehavior} isCentered={isCentered} {...rest}>
    <ModalOverlay />
    <ModalContent data-testid="modal-container">{children}</ModalContent>
  </ChakraModal>
);
Modal.Header = ModalHeader;
Modal.Illustration = ModalIllustration;
Modal.Icon = ModalIcon;
Modal.HeaderTitle = ModalHeaderTitle;
Modal.HeaderSubTitle = ModalHeaderSubTitle;
Modal.CloseButton = ModalCloseButton;
Modal.Overlay = ModalOverlay;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Cover = ModalCover;

export { Modal };
