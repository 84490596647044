import { getCardIcon, getCardImageUrl } from 'src/utils/card';
import { CardTypes, FundingType } from 'src/utils/consts';
import { getFundingSourceIcon, getNameParts, isDebitCard } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

function isCreditCard(method: AccountType) {
  return method.fundingType === FundingType.CARD && method.cardAccount?.cardType === CardTypes.CREDIT;
}

export const getPaymentMethodIcon = (method: AccountType) => {
  if (isCreditCard(method) || isDebitCard(method)) {
    const icon = getCardIcon(method);
    const imageSrc = getCardImageUrl(method);

    return { icon, imageSrc };
  }

  return getFundingSourceIcon(method);
};

export const getPaymentMethodLabel = (method: AccountType) => {
  const label = isCreditCard(method) ? 'bills.pay.fundingSource.creditLabel' : 'bills.pay.fundingSource.achLabel';
  const values = getNameParts(method);
  const { nickname } = method;

  return { label, values, nickname };
};

export const getShortPaymentMethodLabel = (method: AccountType) => {
  const label = isCreditCard(method)
    ? 'regularBatchPayments.cells.paymentMethod.creditLabel'
    : 'regularBatchPayments.cells.paymentMethod.achLabel';
  const values = getNameParts(method);
  const { nickname } = method;

  return { label, values, nickname };
};

export const getPaymentMethodTitleLabel = (method: AccountType) =>
  isCreditCard(method)
    ? 'viewBillPaymentActivity.fundingSource.creditCardLabel'
    : 'viewBillPaymentActivity.fundingSource.achLabel';
