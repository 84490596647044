import { useDispatch, useSelector } from 'react-redux';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage } from 'src/components/common/ModalMessage';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import joinOrgRequestsStore from 'src/modules/join-organization-requests/join-organization-requests-store';

type Props = {
  dismiss: (result: any) => void;
  joinOrgRequest: {
    companyName: string;
    email: string;
    contactFirstName?: string;
    contactLastName?: string;
    phone?: string;
  };
};

export const EmailAlreadyExistModal = ({ joinOrgRequest, dismiss }: Props) => {
  const joinOrgRequestsActions = getStoreActions(joinOrgRequestsStore)(useDispatch());
  const isJoining = useSelector(joinOrgRequestsStore.selectors.create.status())?.loading;
  const joinOrganization = async () => {
    await joinOrgRequestsActions.create(joinOrgRequest);
    dismiss({ success: true });
  };

  return (
    <ModalMessage
      titleComponent={
        <Box textAlign="left">
          <MIFormattedText label="inviteNewCompany.contactInfo.emailExistModal.title" />
        </Box>
      }
      contentComponent={
        <Box textStyle="body2">
          <MIFormattedText
            label="inviteNewCompany.contactInfo.emailExistModal.description"
            values={{ email: joinOrgRequest.email }}
          />
        </Box>
      }
      onCloseClick={dismiss}
      footerComponent={
        <Flex justify="flex-end">
          <Button
            onClick={dismiss}
            label="general.cancel"
            variant={ButtonVariants.tertiaryNaked}
            size={ButtonSizes.lg}
          />
          <Button
            onClick={joinOrganization}
            label="inviteNewCompany.contactInfo.emailExistModal.sendRequest"
            size={ButtonSizes.lg}
            isLoading={isJoining}
          />
        </Flex>
      }
    />
  );
};
