import isNil from 'lodash/isNil';
import { ApprovalWorkflowsMinThresholds, UserOrganization } from 'src/utils/types';

export const calcActorUserOrgsThresholds = (
  userOrgs: readonly UserOrganization[],
  minThresholds?: ApprovalWorkflowsMinThresholds | null
): UserOrganization[] =>
  userOrgs.map((userOrg) => {
    const threshold = minThresholds?.[userOrg.organizationId] ?? null;
    const requireApproval = !isNil(threshold) && threshold >= 0;

    return { ...userOrg, requireApproval, approvalAmountThreshold: threshold };
  });
