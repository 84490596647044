import { fetchRequest, postRequest } from 'src/services/api/api';

export const vendorRequestsApi = {
  createRequest({ orgId, ownedVendorId, data }) {
    const url = `/orgs/${orgId}/vendor-requests/${ownedVendorId}`;

    return postRequest(url, data);
  },

  async getVendorInfo({ orgId, vendorId }) {
    const url = `/orgs/${orgId}/vendors/${vendorId}/delivery-method-suggestions`;

    return fetchRequest(url);
  },

  async getVendorsNameList({ orgId }) {
    const url = `/orgs/${orgId}/vendors/get-name-list`;

    return fetchRequest(url).then((res) => res.objects);
  },
};
