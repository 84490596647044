import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FormControl, FormControlProps, FormLabel } from 'src/core/ds/form';
import { AmountCurrencyInput, AmountCurrencyInputProps } from 'src/core/ds/input';
import { ModelViewField } from 'src/ui/form';
import { ErrorMessage, HelperText, OptionalLabel } from './components';

export type Props = FormControlProps &
  Omit<AmountCurrencyInputProps, 'onChange'> & {
    model?: ModelViewField<string>;
    onChange?: (changeField: { id: string; value: string }) => void;
    size?: 'sm' | 'md';
    label?: string;
    labelValues?: Record<string, unknown>;
    helperText?: string;
    helperTextValues?: Record<string, unknown>;
    errorMessage?: string | null;
    errorMessageValues?: Record<string, unknown>;
  };

export const AmountCurrencyField = ({
  id,
  testId,
  model,
  isRequired,
  isReadOnly,
  isDisabled,
  size,
  helperText,
  helperTextValues,
  label,
  labelValues,
  errorMessage,
  errorMessageValues,
  onChange,
  isViewOnly,
  value,
  fixedDecimalScale,
  decimalScale,
  ...rest
}: Props) => {
  const isError = Boolean(errorMessage) || Boolean(model?.error);
  const inputValue = value || model?.value;
  const inputId = id || model?.id || 'amount-currency-field';
  const noticesTestId = `input-${inputId}-notices`;
  const errorMessageText = errorMessage || model?.error;

  const originalOnChange = model?.onChange || onChange;

  const handleAmountChange = (value: string) => {
    originalOnChange && originalOnChange({ id: inputId, value });
  };

  return (
    <FormControl isInvalid={isError} size={size} isReadOnly={isReadOnly} isDisabled={isDisabled} {...rest}>
      <FormLabel htmlFor={inputId} hidden={isViewOnly}>
        <MIFormattedText label={label} values={labelValues} />
        {!isRequired && <OptionalLabel isError={isError} />}
      </FormLabel>
      <AmountCurrencyInput
        id={inputId}
        testId={testId}
        onChange={handleAmountChange}
        value={inputValue}
        size={size}
        isViewOnly={isViewOnly}
        isDisabled={isDisabled}
        fixedDecimalScale={fixedDecimalScale}
        decimalScale={decimalScale}
        {...rest}
      />
      {!isError ? (
        <HelperText testId={noticesTestId} label={helperText} labelValues={helperTextValues} />
      ) : (
        errorMessageText && (
          <ErrorMessage size={size} testId={noticesTestId} label={errorMessageText} labelValues={errorMessageValues} />
        )
      )}
    </FormControl>
  );
};
