import { EventMappingsTree } from 'src/services/analytics/types';

const msnRegistration = 'msn-registration';
const analyticInfo = {
  flowEntryPoint: 'flowEntryPoint',
  email: 'email',
  orgId: 'orgId',
};
const registrationCompletedAnalyticInfo = {
  networkRegistrationFlow: 'networkRegistrationFlow',
  accountVerificationMethod: 'accountVerificationMethod',
};

export const msnClaimAccountEventsMapping: EventMappingsTree = {
  '/msn-portal/claim-account/public/*/email-verification': {
    'page.view': [msnRegistration, 'code-verification'],
    'msnClaimAccountEmailVerification.code.success': [msnRegistration, 'code-verification-success', analyticInfo],
    'msnClaimAccountEmailVerification.code.incorrect': [msnRegistration, 'code-verification-error', analyticInfo],
    'msnClaimAccountEmailVerification.code.resend': [msnRegistration, 'code-verification-resend-code', analyticInfo],
  },
  '/msn-portal/claim-account/public/*/create-password': {
    'page.view': [msnRegistration, 'create-password'],
    'msnClaimAccountCreatePassword.success': [msnRegistration, 'create-password-success', analyticInfo],
    'msnClaimAccountCreatePassword.registration-completed': [
      msnRegistration,
      'registration-completed',
      registrationCompletedAnalyticInfo,
    ],
    'msnClaimAccountCreatePassword.validation-error': [
      msnRegistration,
      'create-password-validation-error',
      analyticInfo,
    ],
    'msnClaimAccountCreatePassword.error': [msnRegistration, 'create-password-error', analyticInfo],
    'msnClaimAccountCreatePassword.continue': [msnRegistration, 'create-password-continue', analyticInfo],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/select/*': {
    'page.view': [msnRegistration, 'bank-verification-type-select'],
    'onboarding.deliveryMethods.bank.verification.msnClaimAccount.plaid.label': [
      msnRegistration,
      'bank-account-verification-add-plaid',
    ],
    'onboarding.deliveryMethods.bank.verification.msnClaimAccount.manual.label': [
      msnRegistration,
      'bank-account-verification-add-manual',
    ],
  },
  '/orgs/*/msn-portal/claim-account/verify-with-deposits': {
    'page.view': [msnRegistration, 'bank-manually'],
    'progress.continue': [msnRegistration, 'method_ach-receiving-method-continue', analyticInfo],
    'msnClaimAccountVerifyWithDeposits.success': [msnRegistration, 'method_ach-receiving-method-success', analyticInfo],
    'msnClaimAccountVerifyWithDeposits.error': [msnRegistration, 'method_ach-receiving-method-error', analyticInfo],
  },
  '/orgs/*/msn-portal/claim-account/verify-with-deposits-status': {
    'page.view': [msnRegistration, 'bank-manually-success'],
  },
  '/orgs/*/vendors/*/receiving-methods/ach/plaid/*': {
    'msnClaimAccountPlaid.registration-completed': [
      msnRegistration,
      'registration-completed',
      registrationCompletedAnalyticInfo,
    ],
  },
  '/micro-deposit/*/success': {
    'msnClaimAccountMicroDeposit.registration-completed': [
      msnRegistration,
      'registration-completed',
      registrationCompletedAnalyticInfo,
    ],
  },
};
