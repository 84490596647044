import { MINotificationCard } from 'src/components/common/MINotificationCard';
import Box from 'src/core/ds/box';
import { NotificationCardTypes } from 'src/utils/consts';

export const NotificationCardUndepositedCheck = () => (
  <Box pt={8} pb={14}>
    <MINotificationCard
      type={NotificationCardTypes.WARNING}
      subtitle={{
        label: 'vendors.deliveryMethods.check.notifications.undepositedCheck.subtitle',
      }}
    />
  </Box>
);
