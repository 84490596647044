import { ReactNode } from 'react';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Checkbox } from 'src/core/ds/checkbox';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { Separator } from 'src/core/ds/separator';
import { devices } from 'src/theme/appDevices';
import { ModelViewField } from 'src/ui/form/use-form';
import { isAdminRole } from 'src/utils/accounting-firm-utils';
import { Role } from 'src/utils/consts';
import { ShadowContainer } from '../../../components/common/ShadowContainer';
import { AdminApproval } from './AdminApproval';
import { RoleOption } from './types';
import { UserRoleDropdown } from './UserRoleDropdown';

interface Props {
  id?: string;
  requireApprovalDisabled?: boolean;
  requireApprovalAmountThresholdPlaceholder?: number;
  isChecked: boolean;
  onCheck: (checked: boolean) => void;
  userRoleModel: ModelViewField<Role | null>;
  requireApprovalModel: ModelViewField<boolean>;
  approvalAmountThresholdModel: ModelViewField<string>;
  roleOptions: RoleOption[];
  renderData?: ReactNode;
  approvalWorkflowsEnabled: boolean;
  showApprovalFields: boolean;
  'data-testid'?: string;
}

export const PermissionsAssignment = ({
  id,
  renderData,
  isChecked,
  onCheck,
  userRoleModel,
  approvalAmountThresholdModel,
  requireApprovalModel,
  requireApprovalDisabled,
  requireApprovalAmountThresholdPlaceholder,
  roleOptions,
  approvalWorkflowsEnabled,
  showApprovalFields: showApprovalFieldsProp,
  'data-testid': testId,
}: Props) => {
  const handleUserRoleChange = (newRole: RoleOption) => {
    userRoleModel.onChange({ value: newRole.id });
  };

  const isDisabled = userRoleModel.value === Role.OWNER;
  const isAdmin = isAdminRole(userRoleModel.value);
  const showApprovalSection = isChecked && !isAdmin;

  const showApprovalFieldsWithoutFeatureFlag = true; // approval-workflows before the flag the fields were always shown
  const showApprovalFieldsWithFeatureFlag = showApprovalFieldsProp;
  const showApprovalFieldsFinal = approvalWorkflowsEnabled
    ? showApprovalFieldsWithFeatureFlag
    : showApprovalFieldsWithoutFeatureFlag;

  const showApprovalForm = showApprovalSection && showApprovalFieldsFinal;
  const showApprovalWorkflowsMessage = showApprovalSection && !showApprovalFieldsFinal;

  return (
    <PermissionsAssignmentRoot checked={isChecked} data-testid={testId}>
      <Flex alignItems="center" justifyContent="space-between" minHeight={12}>
        <Box mr={2}>
          <Checkbox
            id={`is-checked_${id}`}
            isChecked={isChecked}
            onChange={(event) => onCheck(event.target.checked)}
            isDisabled={isDisabled}
          />
        </Box>
        <PermissionsAssignmentRenderWrapper>{renderData}</PermissionsAssignmentRenderWrapper>
        {isChecked ? (
          <UserRoleDropdown
            currentRole={userRoleModel.value || Role.ACCOUNTANT}
            roles={roleOptions}
            disabled={isDisabled}
            onRoleChange={handleUserRoleChange}
          />
        ) : null}
      </Flex>
      {showApprovalForm ? (
        <Box>
          <Separator mb={5} color="grey.400" />
          <Box ml={[0, null, 8]}>
            <AdminApproval
              id={`admin-approval${id ? `_${id}` : ''}`}
              disabled={requireApprovalDisabled}
              approvalAmountThresholdModel={approvalAmountThresholdModel}
              requireApprovalModel={requireApprovalModel}
              amountThresholdPlaceholder={requireApprovalAmountThresholdPlaceholder}
            />
          </Box>
        </Box>
      ) : null}
      {showApprovalWorkflowsMessage ? (
        <Box>
          <Separator mb={5} color="grey.400" />
          <Flex textStyle="body4" alignItems="flex-start" color="grey.700">
            <Box mr={2}>
              <Icon name={IconNames.infoCircle} size={IconSize.s} />
            </Box>
            <MIFormattedText label="team.modals.assignClients.approvalWorkflows" />
          </Flex>
        </Box>
      ) : null}
    </PermissionsAssignmentRoot>
  );
};

const PermissionsAssignmentRoot = styled<{ checked: boolean }>(ShadowContainer)`
  box-sizing: border-box;
  width: 100%;
  padding-bottom: ${(props) => (props.checked ? '1.6' : '1')}rem;
  padding-right: 2.8rem;
  ${(props) => (props.checked ? `background-color: ${props.theme.colors.white.veryLightGrey};` : '')}
  box-shadow: none;
  @media ${devices.mobile} {
    padding-right: 1.8rem;
  }
`;

const PermissionsAssignmentRenderWrapper = ({ children }) => (
  <Flex alignItems="center" mr="auto" maxWidth="24rem" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
    {children}
  </Flex>
);
