import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import AccountingSoftwarePageContainer from './AccountingSoftwarePageContainer';
import ComingSoonPageContainer from './ComingSoonPageContainer';
import { LinkQuickbooksPageContainer } from './quickbooks/LinkQuickbooksPageContainer';
import SuccessLinkingPageContainer from './quickbooks/SuccessLinkingPageContainer';

const eventPage = 'link-accounting-software';

export default [
  {
    path: onboardingLocations.accountingSoftware.index,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'index');

      return <AccountingSoftwarePageContainer {...cProps} />;
    },
  },
  {
    path: onboardingLocations.accountingSoftware.quickbooks.index,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'quickbooks');

      return <LinkQuickbooksPageContainer {...cProps} />;
    },
  },
  {
    path: onboardingLocations.accountingSoftware.quickbooks.success,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'quickbooks-success');

      return <SuccessLinkingPageContainer {...cProps} />;
    },
  },
  {
    path: onboardingLocations.accountingSoftware.comingSoon.index,
    exact: true,
    component: (cProps) => {
      analytics.page(eventPage, 'coming-soon');

      return <ComingSoonPageContainer {...cProps} type={cProps.match.params.type} />;
    },
  },
];
