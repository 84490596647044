import { MIFormattedCurrency } from 'src/components/common/MiFormattedCurrency';
import Box from 'src/core/ds/box';

type SubAmountCellType = {
  amount: number;
};

export const SubAmountCell = ({ amount }: SubAmountCellType) => (
  <Box textStyle="body4Semi" isTruncated>
    <MIFormattedCurrency value={amount} privateData />
  </Box>
);
