import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { FullPageLayout } from 'src/components/layout/FullPageLayout';
import { UserBadgeList } from 'src/components/user/UserBadgeList';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { HStack, VStack } from 'src/core/ds/stack';
import { getStoreActions, useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useBreak } from 'src/hoc';
import { useApi } from 'src/hoc/useApi';
import joinOrgRequestsStore from 'src/modules/join-organization-requests/join-organization-requests-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { ZeroStateMessage } from 'src/pages/companies/components/ZeroStateMessage';
import { addNewClientTestId } from 'src/pages/companies/consts';
import { useDisplayOrganizations } from 'src/pages/companies/hooks/useDisplayOrganizations';
import useSendRequestToSwichAccountingFirm from 'src/pages/companies/hooks/useSendRequestToSwitchAccountingFirm';
import { ImportClientsMenu } from 'src/pages/companies/import-clients/ImportClientsMenu';
import { globalLocations } from 'src/pages/locations';
import { useClientBgColor } from 'src/pages/team/team-management/hooks/useClientBgColor';
import { analytics } from 'src/services/analytics';
import { getActivitySummary } from 'src/services/api/activitySummary';
import { devices } from 'src/theme/appDevices';
import { CompanyType, FeatureFlags, Role } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { CompaniesDashboardFilter } from './CompaniesDashboardFilter';
import { MICompanyCard } from './MICompanyCard';

export const CompaniesDashboard = () => {
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();
  const { isDesktop } = useBreak();
  const {
    filterMode,
    currentOrg,
    currentUser,
    currentUserRole,
    organizations,
    filterActions,
    hasHiddenOrgs,
    hasCompanyWithUsers,
    handleHideClientClick,
    handleUnhideClientClick,
    shouldDisplayFilterActions,
  } = useDisplayOrganizations();
  const orgId = currentOrg.id;
  const isAccountingFirm = currentOrg.companyType === CompanyType.ACCOUNTING_FIRM;
  const isMemberOfAccountingFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const joinRequestsData = useStructuredSelectors(joinOrgRequestsStore.selectors.user(currentUser.id));
  const isShowZeroState = organizations.length === (isAccountingFirm ? 0 : 1) && joinRequestsData.list.length === 0;
  const [userNotOwnerError, setUserNotOwnerError] = useLocationState<{
    orgId: number;
    email: string;
    name: string;
  }>('userNotOwnerError');
  const userActions = useStoreActions(usersStore);
  const isCurUserAccountingFirmAdmin = [Role.ADMIN, Role.OWNER].includes(currentUserRole);
  const { getColorProps } = useClientBgColor();
  const [isImportQboaClientsEnabled] = useFeature(FeatureFlags.ImportClientsQboa, false);
  const isUsersLoading = useSelector(usersStore.selectors.userManagementListSlice.org(orgId).loading);
  const isAllUserOrgsLoading = useSelector(usersStore.selectors.allUserOrganizations.loading);

  const [SendRequestToSwichAccountingFirm, showSendRequestToSwichAccountingFirm] = useSendRequestToSwichAccountingFirm({
    userNotOwnerError,
    setUserNotOwnerError,
  });

  const currentUserId = currentUser.id;

  const { onApiCall: onActivitySummaryCall, result: activitySummary, loading: activitySummaryLoading } = useApi({
    api: getActivitySummary,
  });

  useEffect(() => {
    const joinOrgRequestsActions = getStoreActions(joinOrgRequestsStore)(dispatch);
    joinOrgRequestsActions.list({ userId: currentUserId });
  }, [currentUserId, dispatch]);

  useEffect(() => {
    onActivitySummaryCall();
  }, [onActivitySummaryCall]);

  useEffect(() => {
    if (orgId) {
      userActions.userManagementListSlice({ orgId }).then((res) => {
        const userIds = res.payload?.map((user) => user.id);

        if (userIds.length) {
          userActions.allUserOrganizations({
            orgId,
            userIds,
          });
        }
      });
    }
  }, [orgId, userActions]);

  useEffect(() => {
    if (userNotOwnerError) {
      showSendRequestToSwichAccountingFirm();
    }
  }, [userNotOwnerError, showSendRequestToSwichAccountingFirm]);

  const handleNewClick = () => {
    analytics.trackAction('add-new-company');
    historyPush({ path: globalLocations.companies.create.details });
  };

  const fireAnalyticsBeforeSwitch = (toOrgId: number, isHidden: boolean) => {
    analytics.trackAction('companies-dashboard-switch-org', { toOrgId, isHidden });
  };

  const renderDashBoard = () => (
    <CompaniesDashboardWrapper>
      {organizations.map((item) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link key={item.id}>
          <MICompanyCard
            key={item.id}
            loggedInOrg={currentOrg}
            organization={item}
            isSelected={item.id === currentOrg.id}
            invitationStatus="accepted"
            color={getColorProps(item.companyName).color}
            onBeforeSwitch={fireAnalyticsBeforeSwitch}
            activitySummary={activitySummary && activitySummary[item.id]}
            onHideClick={handleHideClientClick}
            onUnhideClick={handleUnhideClientClick}
            renderBottom={
              item.users?.length > 0 && (
                <CompanyUsersWrapper>
                  <UserBadgeList users={item.users} max={4} />
                </CompanyUsersWrapper>
              )
            }
          />
        </Link>
      ))}
      {filterMode === 'active' ? (
        joinRequestsData.list.map((item) => (
          <MICompanyCard
            key={item.id}
            organization={item}
            loggedInOrg={currentOrg}
            isSelected={item.id === currentOrg.id}
            invitationStatus={item.status}
            renderBottom={hasCompanyWithUsers && <CompanyUsersWrapper />}
          />
        ))
      ) : (
        <></>
      )}
    </CompaniesDashboardWrapper>
  );

  const renderDashboardWithZeroState = () => {
    const firmLabel = hasHiddenOrgs
      ? 'companies.zeroStateMessage.noVisibleClients.item'
      : 'companies.zeroStateMessage.accountant.item';
    const smbLabel = 'companies.zeroStateMessage.smb.item';
    const label = isMemberOfAccountingFirm ? firmLabel : smbLabel;

    return (
      <VStack alignItems="start" spacing={10}>
        <ZeroStateMessage
          isAccountingFirm={isAccountingFirm}
          hasHiddenOrgs={hasHiddenOrgs}
          onAddClientClick={handleNewClick}
        />
        <CompaniesDashboardWrapper>
          {organizations.map((item) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link key={item.id}>
              <MICompanyCard
                key={item.id}
                loggedInOrg={currentOrg}
                organization={item}
                isSelected={item.id === currentOrg.id}
                activitySummary={activitySummary && activitySummary[item.id]}
                invitationStatus="accepted"
                color={getColorProps(item.companyName).color}
              />
            </Link>
          ))}
          <EmptyCompanyCard data-testid={label}>
            <MIFormattedText label={label} />
          </EmptyCompanyCard>
        </CompaniesDashboardWrapper>
      </VStack>
    );
  };

  const pageTitle = isMemberOfAccountingFirm
    ? `companies.pageTitle.accountingFirm.${isCurUserAccountingFirmAdmin ? 'admin' : 'member'}`
    : 'nav.companies';

  if (joinRequestsData.isLoading || isUsersLoading || isAllUserOrgsLoading || activitySummaryLoading) {
    return (
      <FullPageLayout>
        <AreaLoader />
      </FullPageLayout>
    );
  }

  return (
    <FullPageLayout>
      {SendRequestToSwichAccountingFirm}
      <CompaniesDashboardContainer data-testid="companies-dashboard">
        {isDesktop && (
          <Flex flexDirection="column">
            <Flex
              justifyContent="space-between"
              alignItems="center"
              marginBlockEnd={shouldDisplayFilterActions ? '2.4rem' : '4rem'}
            >
              <Box>
                <Title>
                  <MIFormattedText label={pageTitle} />
                </Title>
                {isAccountingFirm && <Subtitle>{currentOrg?.companyName}</Subtitle>}
              </Box>
              <HStack spacing={3}>
                {isImportQboaClientsEnabled && isAccountingFirm && <ImportClientsMenu />}
                <NewClientButton onClick={handleNewClick} isMemberOfAccountingFirm={isMemberOfAccountingFirm} />
              </HStack>
            </Flex>
            <Flex
              justifyContent="flex-end"
              alignItems="center"
              marginBlockStart={0}
              marginBlockEnd={shouldDisplayFilterActions ? '2.4rem' : 0}
            >
              {shouldDisplayFilterActions && <CompaniesDashboardFilter actions={filterActions} />}
            </Flex>
          </Flex>
        )}
        {isShowZeroState ? renderDashboardWithZeroState() : renderDashBoard()}
      </CompaniesDashboardContainer>
      {!isDesktop && (
        <ButtonContainer>
          <NewClientButton onClick={handleNewClick} isMemberOfAccountingFirm={isMemberOfAccountingFirm} />
        </ButtonContainer>
      )}
    </FullPageLayout>
  );
};

function NewClientButton({ onClick, isMemberOfAccountingFirm }) {
  return (
    <Button
      leftIcon={<Icon name={IconNames.plus} size={IconSize.s} />}
      data-testid={addNewClientTestId}
      onClick={onClick}
      size={ButtonSizes.md}
      width={['100%', null, null, 'auto']}
      label={isMemberOfAccountingFirm ? 'inviteNewCompany.addClient' : 'inviteNewCompany.addCompany'}
    />
  );
}

const CompaniesDashboardContainer = styled.div`
  padding: 0 7% 7% 7%;
  max-width: 90rem;
  margin: 0 auto;

  @media ${devices.nonDesktop} {
    margin-bottom: 5rem;
  }
`;

const Title = styled.div`
  font-size: 3.4rem;
  font-weight: ${(props) => props.theme.text.weight.bold};
  color: ${(props) => props.theme.text.color.main};
  line-height: 4.2rem;
`;

const Subtitle = styled.div`
  ${(props) => props.theme.text.fontType.medium}
`;

const Link = styled.a`
  text-decoration: none;
  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    width: 100%;
  }
`;

const CompaniesDashboardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div,
  a {
    margin-bottom: 2rem;
  }

  > div:nth-child(odd),
  a:nth-child(odd) {
    margin-right: 2rem;
  }

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    flex-direction: column;
    align-items: center;

    > div:nth-child(odd),
    a:nth-child(odd) {
      margin-right: 0rem;
    }

    > a {
      margin-bottom: 0rem;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 1.6rem;
  box-shadow: 0 -1px 0 0 ${(props) => props.theme.text.color.readonly};
  background-color: ${(props) => props.theme.colors.white.opaque};
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  > a {
    justify-content: center;
    width: 100%;
  }
`;

const EmptyCompanyCard = styled.div`
  width: 44rem;
  height: 13.8rem;
  line-height: 13.8rem;
  border-radius: 0.8rem;
  text-align: center;
  border: 2px dashed rgba(216, 216, 216, 1);
  box-sizing: border-box;
  color: ${(props) => props.theme.text.color.label};
  font-size: ${(props) => props.theme.text.size.hint};

  @media ${devices.mobile}, ${devices.phablet}, ${devices.tablet} {
    width: 100%;
    width: fill-available;
    margin-bottom: 1.5rem;
  }
`;

const CompanyUsersWrapper = styled.div`
  height: 1.6rem;
  display: flex;
  margin-top: 1.6rem;
  position: relative;
`;
