import { useCallback, useEffect, useRef } from 'react';

export const useFileInput = (
  callback,
  opts: { accept: string; multiple: boolean; testId?: string } = { accept: '', multiple: false }
) => {
  const input = useRef<HTMLInputElement>();
  const { accept, multiple, testId } = opts;
  useEffect(() => {
    input.current = document.createElement('input');
    input.current.type = 'file';

    document.body.insertBefore(input.current, null);
    input.current.style.display = 'none';

    return () => {
      if (input.current) {
        input.current.remove();
        input.current = undefined;
      }
    };
  }, [testId]);

  useEffect(() => {
    if (input.current) {
      input.current.accept = accept || '';
      input.current.multiple = !!multiple;

      if (testId) {
        input.current.setAttribute('data-testid', testId);
      }
    }
  }, [accept, multiple, testId]);
  useEffect(() => {
    if (input.current) {
      input.current.onchange = () => {
        if (input.current && input.current.files) {
          callback(input.current.files);
        }
      };
    }
  }, [callback]);

  return useCallback(() => {
    if (input.current) {
      input.current.value = '';
      input.current.click();
    }
  }, []);
};
