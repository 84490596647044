import { generatePath, Redirect, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MicroDepositStatuses, MicroDepositStatusURLMapper } from '../consts';
import { useMicroDepositStatusState } from '../hooks/useMicroDepositStatusState';

export const MicroDepositIndex = () => {
  const { token } = useRouteMatch<{ token: string }>().params;
  const { microDepositStatus } = useMicroDepositStatusState(token);
  const isLoading = microDepositStatus === MicroDepositStatuses.WAITING_FOR_STATUS;

  return isLoading ? (
    <AreaLoader />
  ) : (
    <Redirect to={generatePath(MicroDepositStatusURLMapper[microDepositStatus], { token })} />
  );
};
