import PapaParse, { ParseLocalConfig, ParseResult } from 'papaparse';

export function loadCSVFile<T>(papaParseOptions: Partial<ParseLocalConfig>, file: File): Promise<ParseResult<T>> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      PapaParse.parse(reader.result, {
        fileEncoding: 'UTF-8',
        error: (err) => reject(err),
        complete: (csvData: ParseResult<T>) => {
          resolve(csvData);
        },
        ...papaParseOptions,
      });
    };

    reader.onerror = reject;

    reader.readAsText(file);
  });
}
