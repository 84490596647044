import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useApi } from 'src/hoc/useApi';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import { useCreateAndLinkAccountingPlatformAccount } from 'src/modules/funding-sources/hooks/useCreateAndLinkAccountingPlatformAccount';
import { useGetFundingSourceDisplayName } from 'src/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import { useLinkAccountingPlatformAccount } from 'src/modules/funding-sources/hooks/useLinkAccountingPlatformAccount';
import { organizationsApi } from 'src/modules/organizations/api';
import { accountingSoftwareNameToIntlPath } from 'src/pages/bill/components/BillDetailsForm/utils/accountingSoftwareNameToIntlPath';
import { FirstAccountingPlatformAccountAddedSuccessPage } from 'src/pages/onboarding/funding-sources/bank/components/FirstAccountingPlatformAccountAddedSuccessPage';
import { TruncatedMethodCard } from 'src/pages/onboarding/funding-sources/bank/components/TruncatedMethodCard';
import { WizardAccountingPlatformAccountSelectField } from 'src/pages/onboarding/funding-sources/bank/components/WizardAccountingPlatformAccountSelectField';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { useForm } from 'src/ui/form';
import { FormRow } from 'src/ui/form/FormElements';
import { ValidationError } from 'src/ui/ValidationError';
import {
  AccountingSoftware,
  CardTypes,
  FundingType,
  NotificationCardTypes,
  NotificationVariant,
} from 'src/utils/consts';
import { AccountType } from 'src/utils/types';

type Props = {
  fundingSourceId?: number;
  goNext: () => void;
  cancelFlow: () => void;
  newAccountNotificationLabel: string;
  editMode: boolean;
};

export const LinkAccountingPlatformAccountToFundingSourcePage = ({
  fundingSourceId,
  goNext,
  cancelFlow,
  editMode,
  newAccountNotificationLabel,
}: Props) => {
  const orgId = useSelector(getOrgId);
  const fundingSource = useSelector(fundingSourcesStore.selectors.byId(fundingSourceId));
  const [isFirstAccountingPlatformAccountAdded, setIsFirstAccountingPlatformAccountAdded] = useState(false);

  const { connectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const {
    onApiCall: loadAccountingPlatformAccounts,
    result: accountingPlatformAccountsResult,
    loading: isAccountingPlatformAccountsLoading,
  } = useApi({
    api: organizationsApi.getAccountsPaymentOptions,
    initialLoading: true,
  });
  const { linkAccountingPlatformAccount, isAccountingPlatformAccountLinking } = useLinkAccountingPlatformAccount();
  const {
    createAndLinkAccountingPlatformAccount,
    isAccountingPlatformAccountCreatingAndLinking,
  } = useCreateAndLinkAccountingPlatformAccount();
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const fundingSourceDisplayName = getFundingSourceDisplayName({
    fundingSource,
    useShortName: connectedAccountingPlatform?.name === AccountingSoftware.XERO,
  });
  const currentFundingSourceType =
    fundingSource?.fundingType === FundingType.ACH ? fundingSource?.fundingType : fundingSource?.cardAccount.cardType;

  const accountingPlatformName = accountingSoftwareNameToIntlPath(connectedAccountingPlatform?.name);

  const fireAnalyticsOnAccountLinked = useCallback(() => {
    analytics.trackAction('accounting-platform-account-connected', {
      type: currentFundingSourceType === 'credit' ? 'Credit card' : 'Bank',
      accountingPlatform: accountingPlatformName,
      fundingSourceId,
    });
  }, [currentFundingSourceType, fundingSourceId, accountingPlatformName]);

  useEffect(() => {
    loadAccounts();

    async function loadAccounts() {
      try {
        const result = await loadAccountingPlatformAccounts(orgId);
        const accountingPlatformAccounts = result?.accounts || [];

        if (isEmpty(accountingPlatformAccounts)) {
          await createAndLinkAccountingPlatformAccount({
            name: fundingSourceDisplayName,
            type: currentFundingSourceType,
            fundingSourceId: fundingSourceId!,
          });
          fireAnalyticsOnAccountLinked();
          setIsFirstAccountingPlatformAccountAdded(true);
        }
      } catch (e) {
        if (editMode) {
          pushNotification({
            type: NotificationVariant.ERROR,
            msg: 'server.ERR',
          });
        }

        goNext();
      }
    }
  }, [
    fireAnalyticsOnAccountLinked,
    loadAccountingPlatformAccounts,
    createAndLinkAccountingPlatformAccount,
    fundingSourceId,
    currentFundingSourceType,
    fundingSourceDisplayName,
    orgId,
    goNext,
    editMode,
  ]);
  const onSubmitAccountingPlatformAccount = async ({ intuitAccountId }) => {
    if (intuitAccountId) {
      try {
        if (intuitAccountId === 'new') {
          await createAndLinkAccountingPlatformAccount({
            name: fundingSourceDisplayName,
            type: currentFundingSourceType,
            fundingSourceId: fundingSourceId!,
          });
        } else {
          await linkAccountingPlatformAccount({
            fundingSourceId: fundingSourceId!,
            accountingPlatformAccountId: intuitAccountId,
          });
        }

        fireAnalyticsOnAccountLinked();
        goNext();
      } catch (e) {
        const validationErrors = {
          intuitAccountId: 'onboarding.fundingSources.bank.accountingPlatformAccounts.errors.exist',
        };
        throw new ValidationError({ validationErrors });
      }
    } else {
      const validationErrors = {
        intuitAccountId: (
          <MIFormattedText
            label="onboarding.fundingSources.bank.accountingPlatformAccounts.errors.required"
            values={{ accountingPlatformName: <MIFormattedText label={accountingPlatformName} /> }}
          />
        ),
      };
      throw new ValidationError({ validationErrors });
    }
  };

  const model = useMemo(() => ({ intuitAccountId: fundingSource?.intuitAccountId }), [fundingSource]);
  const [intuitAccountVM, { submit }] = useForm(model, {
    submit: onSubmitAccountingPlatformAccount,
  });
  const { fundingTypeText, nextLabel } = getLabels(fundingSource);

  const isFirstAccountingPlatformAccountCreating =
    isAccountingPlatformAccountCreatingAndLinking && isEmpty(accountingPlatformAccountsResult?.accounts);

  if (isAccountingPlatformAccountsLoading || isFirstAccountingPlatformAccountCreating) {
    return <AreaLoader />;
  }

  if (isFirstAccountingPlatformAccountAdded) {
    return (
      <FirstAccountingPlatformAccountAddedSuccessPage
        fundingSource={fundingSource}
        accountingPlatformName={accountingPlatformName}
        goNext={goNext}
      />
    );
  }

  const isFormSubmitting = isAccountingPlatformAccountCreatingAndLinking || isAccountingPlatformAccountLinking;

  return (
    <StepLayoutPage
      title="onboarding.fundingSources.bank.accountingPlatformAccounts.title"
      titleValues={{
        type: <MIFormattedText label={fundingTypeText} />,
        accountingPlatformName: <MIFormattedText label={accountingPlatformName} />,
      }}
      subtitle="onboarding.fundingSources.bank.accountingPlatformAccounts.subtitle"
      subTitleValues={{
        accountingPlatformName: <MIFormattedText label={accountingPlatformName} />,
      }}
      goExit={cancelFlow}
      onNext={submit}
      nextLabel={nextLabel}
      isLoading={isFormSubmitting}
      fullWidthCTA
      hideHeader
    >
      <MainContentContainer>
        {intuitAccountVM.intuitAccountId.value === 'new' && (
          <NotificationContainer>
            <MINotificationCard
              type={NotificationCardTypes.INFO}
              subtitle={{
                label: newAccountNotificationLabel,
                values: {
                  displayName: fundingSourceDisplayName,
                  accountingPlatformName: <MIFormattedText label={accountingPlatformName} />,
                },
              }}
            />
          </NotificationContainer>
        )}
        <WrappedTruncatedMethodCard method={fundingSource} />
        <FormRow>
          <WizardAccountingPlatformAccountSelectField
            fundingSource={fundingSource}
            accountingPlatformAccounts={accountingPlatformAccountsResult?.accounts}
            connectedAccountingPlatformName={accountingSoftwareNameToIntlPath(connectedAccountingPlatform?.name)}
            model={intuitAccountVM.intuitAccountId}
          />
        </FormRow>
      </MainContentContainer>
    </StepLayoutPage>
  );
};

const getLabels = (fundingSource: AccountType) => {
  const fundingSourceType =
    fundingSource?.fundingType === FundingType.ACH ? fundingSource?.fundingType : fundingSource?.cardAccount?.cardType;
  let fundingTypeText = 'onboarding.fundingSources.bank.accountingPlatformAccounts.ach';
  let nextLabel = 'onboarding.fundingSources.bank.accountingPlatformAccounts.linkAch';

  if (fundingSourceType === CardTypes.DEBIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.accountingPlatformAccounts.debitAccount';
    nextLabel = 'onboarding.fundingSources.bank.accountingPlatformAccounts.linkDebit';
  } else if (fundingSourceType === CardTypes.CREDIT) {
    fundingTypeText = 'onboarding.fundingSources.bank.accountingPlatformAccounts.creditAccount';
    nextLabel = 'onboarding.fundingSources.bank.accountingPlatformAccounts.linkCredit';
  }

  return {
    fundingTypeText,
    nextLabel,
  };
};

const NotificationContainer = styled.div`
  margin-bottom: 2rem;
`;

const WrappedTruncatedMethodCard = styled(TruncatedMethodCard)`
  margin-bottom: 2rem;
`;

const MainContentContainer = styled.div`
  margin-top: -0.5rem;
`;
