import lottie, { AnimationConfig } from 'lottie-web';
import { useEffect, useRef } from 'react';
import Box from 'src/core/ds/box';

export type LottieProps = {
  animationData: unknown;
  width?: string;
  height?: string;
} & Partial<AnimationConfig>;

export const Lottie = ({ animationData, width = 'full', height = 'full', ...rest }: LottieProps) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        renderer: 'svg',
        animationData,
        container: element.current,
        ...rest,
      });
    }
  }, [animationData]);

  return <Box w={width} h={height} ref={element} />;
};
