import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { VendorCompanyNameForm } from './components/VendorCompanyNameForm';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyNamePageContainer = ({ companyInfoField, nextStepURL, prevStepURL, inputFields }: Props) => {
  const { onNext, onChange, isLoading, validationErrors } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
  });

  const companyInfo = useSelector(getCompanyInfo);

  return (
    <VendorCompanyNameForm
      onNext={onNext}
      onChange={onChange}
      companyName={companyInfo.companyName ?? ''}
      isLoading={isLoading}
      validationErrors={validationErrors}
    />
  );
};
