import assign from 'lodash/assign';
import get from 'lodash/get';
import set from 'lodash/set';
import { getJWTPayload } from 'src/helpers/jwt';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { PaymentType } from 'src/utils/types';
import { GetPaymentDeliveryTimeResponse, paymentsApi } from './api';

const name = 'payments';

type FetchPaymentStateType = {
  byId: {
    [key: string]: PaymentType;
  };
  meta: {
    [key: string]: any;
  };
};

const fetchPaymentReducers = {
  [ON_REQUEST]: (state: FetchPaymentStateType, action) => {
    const { paymentId } = getJWTPayload(action.payload.token);
    state.meta[paymentId] = { loading: true, error: null };
  },
  [ON_SUCCESS]: (state: FetchPaymentStateType, { payload }) => {
    const { filesUrls, deliveryDates, fee } = payload;
    state.byId[payload.payment.id] = payload.payment;
    state.meta[payload.payment.id] = {
      loading: false,
      error: null,
      filesUrls,
      deliveryDates,
      fee,
    };
  },
  [ON_FAILURE]: (state: FetchPaymentStateType, action) => {
    const { paymentId } = getJWTPayload(action.meta.identifier.token);
    state.meta[paymentId] = { loading: false, error: action.error };
    const requestType = action.type.split('_').slice(0, -1).join('_');

    if (action.error.validationErrors) {
      const errorData = Object.keys(action.error.validationErrors).reduce((obj, key) => {
        obj[key] = action.error.validationErrors[key].replace(`serverErrors.${requestType}_REQUEST.`, '');

        return obj;
      }, {});
      action.error.data = errorData;
    }
  },
};

export const fetchPaymentDetailsWithToken = createApiCallSlice<any, FetchPaymentStateType>({
  name: generateSliceName(name, 'fetch_payment_details'),
  api: paymentsApi.getDetailsWithToken,
  reducers: fetchPaymentReducers,
});

export const fetchEmailToVendorDetails = createApiCallSlice<any, FetchPaymentStateType>({
  name: generateSliceName(name, 'fetch_email_to_vendor_details'),
  api: paymentsApi.getEmailToVendorData,
  reducers: fetchPaymentReducers,
});

export const updatePaymentWithToken = createApiCallSlice<any, any>({
  name: generateSliceName(name, 'update'),
  api: paymentsApi.updatePaymentByIdWithToken,
  reducers: {
    [ON_REQUEST]: (state, action) => {
      state.meta[action.payload.id] = { loading: true, error: null };
    },
    [ON_SUCCESS]: (state, action) => {
      state.meta[action.meta.identifier.id] = { loading: false, error: null };
    },
    [ON_FAILURE]: (state, action) => {
      state.meta[action.payload.id] = { loading: false, error: action.error };
    },
  },
});

type GetDeliveryTimeSlicePayloadType = {
  deliveryDate: Date;
  suggestedScheduledDate: Date;
  orgId: number;
  deductionDate: Date;
  deliveryMethodId: number;
  fundingSourceId: number;
  amount: number;
  billId: number;
};

type GetDeliveryTimeSliceStateType = {
  batchPayments: Record<
    number,
    {
      status: {
        loading: boolean;
        error?: Error;
      };
    }
  >;
};

const approvalDecisionReducers = {
  [ON_REQUEST]: (state, action) => {
    state.approvalDecision.loading = true;
    state.approvalDecision.error = null;
    state.approvalDecision.approvalStatus = action.payload.status;
  },
  [ON_FAILURE]: (state, action) => {
    state.approvalDecision.loading = false;
    state.approvalDecision.error = action.error;
  },
  [ON_SUCCESS]: (state) => {
    state.approvalDecision.loading = false;
    state.approvalDecision.error = null;
  },
};

export const approvePaymentSlice = createApiCallSlice({
  name: generateSliceName(name, 'payment_approved'),
  api: paymentsApi.approvePayment,
  initialState: {
    approvalDecision: {
      loading: false,
      error: undefined,
      approvalStatus: undefined,
    },
    batchPayments: {},
    deliveryMethods: {},
    meta: {},
    byId: {},
  },
  reducers: approvalDecisionReducers,
});

export const declinePaymentSlice = createApiCallSlice({
  name: generateSliceName(name, 'payment_declined'),
  api: paymentsApi.declinePayment,
  reducers: approvalDecisionReducers,
});

export const getDeliveryTimeSlice = createApiCallSlice<
  GetDeliveryTimeSlicePayloadType,
  GetDeliveryTimeSliceStateType,
  GetPaymentDeliveryTimeResponse
>({
  name: generateSliceName(name, 'batch_payments_get_delivery_date'),
  api: paymentsApi.getDeliveryTime,
  initialState: {
    batchPayments: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      const currentBillId = get(action, 'payload.billId');

      if (state.batchPayments[currentBillId]) {
        state.batchPayments[currentBillId].status = { loading: false };
      } else {
        set(state, `batchPayments[${currentBillId}]`, {
          status: { loading: true },
        });
      }
    },
    [ON_SUCCESS](state, action) {
      const currentBillId = get(action, 'meta.identifier.billId');

      if (state.batchPayments[currentBillId]) {
        assign(state.batchPayments[currentBillId], {
          deliveryEta: action.payload.deliveryDate,
          scheduledDate: action.payload.suggestedScheduledDate,
        });
      } else {
        set(state, `batchPayments[${currentBillId}]`, {
          deliveryEta: action.payload.deliveryDate,
          scheduledDate: action.payload.suggestedScheduledDate,
        });
      }

      state.batchPayments[currentBillId].status = { loading: false };
    },
    [ON_FAILURE](state, action) {
      const currentBillId = get(action, 'meta.identifier.billId');
      state.batchPayments[currentBillId].status = {
        loading: false,
        error: action.error,
      };
    },
  },
});

export const fetchPaymentUpsellDetailsSlice = createApiCallSlice<any, FetchPaymentStateType>({
  name: generateSliceName(name, 'fetch_payment_upsell_details'),
  api: paymentsApi.getPaymentUpsellDetails,
  reducers: {
    [ON_REQUEST]: (state: FetchPaymentStateType, action) => {
      const { paymentId } = getJWTPayload(action.payload.token);
      state.meta[paymentId] = { loading: true, error: null };
    },
    [ON_SUCCESS]: (state: FetchPaymentStateType, { payload }) => {
      const { filesUrls, payment, upsellItems } = payload;
      const paymentId = payment.id;

      state.byId[paymentId] = payment;
      state.meta[paymentId] = {
        loading: false,
        isLoadFinished: true,
        error: null,
        filesUrls,
        upsellItems,
      };
    },
    [ON_FAILURE]: (state: FetchPaymentStateType, action) => {
      const { paymentId } = getJWTPayload(action.meta.identifier.token);
      state.meta[paymentId] = { loading: false, isLoadFinished: true, error: action.error };
    },
  },
});
