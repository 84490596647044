import pick from 'lodash/pick';
import { generateSliceName } from 'src/helpers/redux/actionNames';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { name } from 'src/modules/invoices/constants';
import { invoicesApi } from './api';

type Payload = {
  sent: number;
  unsent: number;
  failed: number;
};

type State = {
  totalCounters: {
    loading: boolean;
    error?: Error;
    counters: {
      unsent?: number;
      sent?: number;
      failed?: number;
    };
  };
};

const totalCountersSlice = createApiCallSlice<Payload, State>({
  initialState: {
    totalCounters: {
      loading: false,
      error: undefined,
      counters: {},
    },
  },
  name: generateSliceName(name, 'get_counters'),
  api: invoicesApi.getCounters,
  reducers: {
    [ON_REQUEST](state) {
      state.totalCounters = {
        loading: true,
        counters: {
          ...state.totalCounters.counters,
        },
      };
    },
    [ON_SUCCESS](state, action) {
      state.totalCounters = {
        loading: false,
        counters: pick(action.payload, ['unsent', 'sent', 'failed']),
      };
    },
    [ON_FAILURE](state, action) {
      state.totalCounters = {
        loading: false,
        error: action.error,
        counters: {},
      };
    },
  },
  selectors: {
    counters: (state) => state[name]?.totalCounters.counters,
    loading: (state) => state[name]?.totalCounters.loading,
  },
});

export default totalCountersSlice;
