import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import BillDetailsItem from 'src/pages/regular-batch-payments/components/wizard/BatchPaymentsSuccess/FailedBillsListModal/BillDetailsItem';
import { useCreateBatchPayments } from 'src/pages/regular-batch-payments/hooks/useCreateBatchPayments';

export const FailedBillsList: React.FC = () => {
  const {
    createBatchPaymentData: { failedPayments },
  } = useCreateBatchPayments();

  return (
    <Box maxH={300} mt={2} overflow="auto">
      {failedPayments?.map(({ amount, vendorName, errorCode, bills }, idx) => (
        <Flex key={idx} __css={containerStyle} data-testid="batch-failed-payment-list-item">
          <BillDetailsItem amount={amount} vendorName={vendorName} />
          <Box textStyle="body4" color="grey.700" mt={1}>
            {bills.length > 1 ? (
              <MIFormattedText label="regularBatchPayments.failedBillsList.multipleBills" />
            ) : (
              <MIFormattedText
                label="regularBatchPayments.failedBillsList.invoiceNumber"
                values={{ invoiceNumber: bills?.[0].invoiceNumber }}
              />
            )}
          </Box>
          <Box textStyle="body4" color="grey.700" mt={0.5} mb={4}>
            <MIFormattedText
              label="regularBatchPayments.failedBillsList.error"
              values={{
                errors: <MIFormattedText label={`regularBatchPayments.failedBillsList.errors.${errorCode}`} />,
              }}
            />
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

const containerStyle = {
  direction: 'column',
  marginTop: '1.6rem',
  borderBottom: '1px solid #D8D8D8',
  '&:last-child': {
    borderBottom: 0,
  },
  '&:first-child': {
    marginTop: 0,
  },
};
