import { useMemo } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { BlockedForPaymentDialog } from 'src/components/common/BlockedForPaymentDialog';
import { useModal } from 'src/helpers/react/useModal';
import useFetchVendor from 'src/modules/vendors/hooks/useFetchVendor';
import { withPayBillData } from 'src/pages/bill/pay/hoc/withPayBillData';
import { NewVendorDeliveryMethodPage } from 'src/pages/contacts/create/NewVendorDeliveryMethodPage';
import { contactsLocations } from 'src/pages/contacts/locations';
import { getContactType } from 'src/pages/contacts/utils';
import { getDeliveryMethodByType } from 'src/pages/vendor/records';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { CompanyFormPage, DeliveryType, NotificationVariant } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { generatePathWithSearch } from 'src/utils/query-utils';

const eventPage = 'new-vendor-delivery-method-options';

function PlainNewVendorDeliveryMethodPageContainer() {
  const history = useHistory();
  const match = useRouteMatch<{ type: string }>();
  const blockDialog = () => <BlockedForPaymentDialog onCancelAction={onCloseBlockPaymentDialog} />;
  const [BlockPaymentModal] = useModal(blockDialog, {});
  const params = useParams<{ orgId: string; id: string }>();
  const [orgId, id] = [Number(params.orgId), Number(params.id)];
  const { vendor } = useFetchVendor(id);
  const contactType = getContactType(match?.params?.type);
  const [origin] = useLocationState('origin', '');

  const onCloseBlockPaymentDialog = () => {
    history.push(contactsLocations.base[1]);
  };

  const goToContactEditUrlById = () => {
    analytics.track(eventPage, `skip-delivery-method-options`, {
      vendorId: id,
    });
    history.push(
      generatePathWithSearch(
        contactsLocations.index,
        {
          orgId,
          type: contactType,
        },
        { id }
      )
    );

    if (CompanyFormPage.ONBOARDING === origin) {
      const successAddVendorNotificationParams = {
        type: NotificationVariant.SUCCESS,
        msg: 'contacts.create.successNotification',
        textValues: { payload_companyName: vendor?.companyName },
      };

      pushNotification(successAddVendorNotificationParams);
    }
  };

  const deliveryMethodOptions = useMemo(() => {
    const ach = getDeliveryMethodByType(DeliveryType.ACH, vendor?.deliveryMethods);
    const check = getDeliveryMethodByType(DeliveryType.CHECK, vendor?.deliveryMethods);
    const virtual = getDeliveryMethodByType(DeliveryType.VIRTUAL, vendor?.deliveryMethods);
    const international = getDeliveryMethodByType(DeliveryType.INTERNATIONAL, vendor?.deliveryMethods);

    return { domestic: [ach, check, virtual], international: [international] };
  }, []);

  return (
    <>
      {BlockPaymentModal}
      <NewVendorDeliveryMethodPage
        goExit={goToContactEditUrlById}
        orgId={orgId}
        vendorId={id}
        origin={origin}
        companyName={vendor?.companyName ?? ''}
        deliveryMethodOptions={deliveryMethodOptions}
      />
    </>
  );
}

export const NewVendorDeliveryMethodPageContainer = withPayBillData()(PlainNewVendorDeliveryMethodPageContainer);
