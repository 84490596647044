import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { invitationsApi } from 'src/modules/invitations/api';

type StateType = {
  validateCreate: {
    loading: boolean;
    error: any;
  };
};
type PayloadType = {
  orgId: number;
  email: string;
};

export const validateCreateInvitationSlice = createApiCallSlice<PayloadType, StateType, any>({
  name: '[INVITATIONS] VALIDATE_CREATE',
  api: invitationsApi.validateCreate,
  initialState: {
    validateCreate: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.validateCreate.loading = true;
      state.validateCreate.error = undefined;
    },
    [ON_SUCCESS]: (state) => {
      state.validateCreate.loading = false;
      state.validateCreate.error = undefined;
    },
    [ON_FAILURE]: (state, action) => {
      state.validateCreate.loading = false;
      state.validateCreate.error = action.error;
    },
  },
  selectors: {
    status: (state) => state.invitations.validateCreate,
    loading: (state) => state.invitations.validateCreate.loading,
    error: (state) => state.invitations.validateCreate.error,
  },
});
