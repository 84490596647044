import { useEffect, useState } from 'react';
import { generatePath, useRouteMatch } from 'react-router';
import { CodeVerificationPage } from 'src/components/layout/CodeVerificationPage';
import { useApi } from 'src/hoc/useApi';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { analytics } from 'src/services/analytics';
import guestsApi from 'src/services/api/guests';

export type Props = { email: string; orgId: string };

export const ClaimAccountEmailVerificationPageContainer = ({ email, orgId }: Props) => {
  const { navigate } = useNavigator();
  const { token } = useRouteMatch<{ token: string }>().params;
  const [validationErrors, setValidationErrors] = useState<Record<string, any>>({});
  const [isCodeResend, setIsCodeResend] = useState(false);
  const [codeInputKey, setCodeInputKey] = useState(1);
  const analyticsInfo = {
    flowEntryPoint: 'msn-registration',
    email,
    orgId,
  };

  const { onApiCall: sendEmailCodeWithToken, error: sendEmailCodeError } = useApi({
    api: guestsApi.sendEmailVerificationCodeWithToken,
  });

  const {
    onApiCall: resoleEmailVerificationWithToken,
    result: resolveEmailVerificationResult,
    error: resolveEmailVerificationError,
    loading: isResolveEmailVerificationLoading,
  } = useApi({ api: guestsApi.resolveEmailVerificationWithToken });

  useEffect(() => {
    if (token) {
      sendEmailCodeWithToken({ token });
    }
  }, [token]);

  useEffect(() => {
    if (sendEmailCodeError) {
      navigate(onboardingLocations.msnRegistration.claimAccount.generalError);
    }
  }, [sendEmailCodeError]);

  useEffect(() => {
    if (resolveEmailVerificationResult) {
      const { user } = resolveEmailVerificationResult;

      if (user) {
        analytics.trackAction('msnClaimAccountEmailVerification.code.success', analyticsInfo);
        navigate(generatePath(onboardingLocations.msnRegistration.claimAccount.createPassword, { token }), false, {
          emailCodeVerified: true,
        });
      }
    } else if (resolveEmailVerificationError) {
      analytics.trackAction('msnClaimAccountEmailVerification.code.incorrect', analyticsInfo);
      setValidationErrors(resolveEmailVerificationError);
    }
  }, [resolveEmailVerificationError, resolveEmailVerificationResult]);

  const resendEmailVerificationCode = () => {
    sendEmailCodeWithToken({ token });
    setValidationErrors({});
    setIsCodeResend(true);
    setCodeInputKey((codeInputKey) => codeInputKey + 1);
    analytics.trackAction('msnClaimAccountEmailVerification.code.resend', analyticsInfo);
  };

  const handleCodeChange = (code: string) => {
    if (code.length === 4) {
      resoleEmailVerificationWithToken({ token, code: Number(code) });
    }

    if (code.length === 0) {
      setValidationErrors({ validationErrors: {} });
    }
  };

  return (
    <CodeVerificationPage
      email={email}
      isLoading={isResolveEmailVerificationLoading}
      errorCode={validationErrors.code}
      onChange={handleCodeChange}
      sendEmailVerificationCode={resendEmailVerificationCode}
      isCodeResend={isCodeResend}
      codeInputKey={codeInputKey}
    />
  );
};
