import filter from 'lodash/filter';
import map from 'lodash/map';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import {
  INVOICES_BATCH_CANCEL_SUCCESS,
  INVOICES_CANCEL_SUCCESS,
  INVOICES_MARK_AS_PAID_SUCCESS,
} from 'src/modules/invoices/invoices-actions';
import {
  cancelSlice,
  markAsSendSlice,
  resetCreateInvoiceSlice,
  shareSlice,
} from 'src/modules/invoices/invoices-slices';
import totalCountersSlice from 'src/modules/invoices/total-counters';
import { InvoiceType } from 'src/modules/invoices/types';
import { removeInvoicesFromList } from 'src/modules/invoices/utils';
import { PaymentRequestFilter } from 'src/utils/consts';
import { invoicesApi } from './api';
import batchCancelSlice from './batch-cancel';
import batchDeleteSlice from './batch-delete';
import batchShareSlice from './batch-share';
import { name } from './constants';
import { clearFileUploadSlice, fileUploadSlice } from './file-upload';
import invoicesBatchUpload from './invoices-batch-upload';
import markedAsPaidSlice from './marked-as-paid';
import proInvoicesBatchDeleteSlice from './pro-slices/pro-invoices-batch-delete';
import { proInvoicesBatchMarkAsPaidSlice } from './pro-slices/pro-invoices-batch-mark-as-paid';
import proInvoicesBatchSendSlice from './pro-slices/pro-invoices-batch-send';
import proInvoicesDeleteSlice from './pro-slices/pro-invoices-delete';
import proInvoicesFetchSlice from './pro-slices/pro-invoices-fetch-slice';
import proInvoicesIsListRefreshableSlice from './pro-slices/pro-invoices-is-list-refreshable';
import proListSlice from './pro-slices/pro-invoices-list';
import proInvoicesMarkAsPaidSlice from './pro-slices/pro-invoices-mark-as-paid';
import proInvoicesSendSlice from './pro-slices/pro-invoices-send';
import proInvoicesUpdateSlice from './pro-slices/pro-invoices-update';

const invoicesStore = createRestfulSlice<InvoiceType>({
  api: invoicesApi,
  name,
  schemaName: 'paymentRequest',
  slices: {
    share: shareSlice,
    fileUpload: fileUploadSlice,
    cancelPaymentRequest: cancelSlice,
    clearFileUpload: clearFileUploadSlice,
    createBatch: invoicesBatchUpload,
    markAsSend: markAsSendSlice,
    totalCounters: totalCountersSlice,
    batchShare: batchShareSlice,
    batchDelete: batchDeleteSlice,
    batchCancel: batchCancelSlice,
    markedAsPaid: markedAsPaidSlice,
    resetCreateInvoice: resetCreateInvoiceSlice,
    proList: proListSlice,
    proFetch: proInvoicesFetchSlice,
    proDelete: proInvoicesDeleteSlice,
    proUpdate: proInvoicesUpdateSlice,
    proBatchDelete: proInvoicesBatchDeleteSlice,
    proMarkAsPaid: proInvoicesMarkAsPaidSlice,
    proBatchMarkAsPaid: proInvoicesBatchMarkAsPaidSlice,
    proSend: proInvoicesSendSlice,
    proBatchSend: proInvoicesBatchSendSlice,
    proIsListRefreshable: proInvoicesIsListRefreshableSlice,
  },
  selectors: {
    batchItems: (ids) => (state) => filter(state[name].byId, (invoice) => ids[invoice.id]),
  },
  extraReducers: {
    [INVOICES_CANCEL_SUCCESS](state, action) {
      const { id } = action.payload;
      removeInvoicesFromList(state, PaymentRequestFilter.SENT, [id]);
    },
    [INVOICES_BATCH_CANCEL_SUCCESS](state, action) {
      const { invoices } = action.payload;
      const ids = map(invoices, 'id');
      removeInvoicesFromList(state, PaymentRequestFilter.SENT, ids);
    },
    [INVOICES_MARK_AS_PAID_SUCCESS](state, action) {
      const { id } = action.payload.invoice;
      removeInvoicesFromList(state, PaymentRequestFilter.UNSENT, [id]);
    },
  },
});

export default invoicesStore;
