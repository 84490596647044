import { format } from 'date-fns';
import { BillFrequency } from 'src/utils/consts';
import { DateFormats } from 'src/utils/date-fns';
import { BillType } from 'src/utils/types';

export const getInvoiceNumberLabel = (isRecurringBill: boolean, isBulkPayment: boolean): string => {
  if (isRecurringBill) {
    return 'bills.pay.confirm.billNumberField';
  } else if (isBulkPayment) {
    return 'bills.view.bulkPaymentBillsCount';
  }

  return 'bills.pay.confirm.invoiceNumberField';
};

export const getRecurringBillHintInfo = (bill: BillType) => {
  const occurrences = bill?.recurringBill?.occurrences;
  const dueDate = bill.recurringBill?.dueDate;
  const isWeekly = bill.recurringBill?.frequency === BillFrequency.WEEKLY;
  const dateFormat = isWeekly ? DateFormats.dayLong : DateFormats.dayOfMonthWithOrdinal;
  const specificDay = dueDate ? format(new Date(dueDate), dateFormat) : '';

  return {
    label: isWeekly ? 'bills.pay.confirm.recurringWeeklyHint' : 'bills.pay.confirm.recurringMonthlyHint',
    values: {
      specificDay,
      occurrences,
    },
    occurrences,
  };
};
