import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { useIsAccountingFirm } from 'src/hooks/accountants/useIsAccountingFirm';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useGetFirmBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetFirmBillingFees';
import { useGetOrganizationBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetOrganizationBillingFees';
import { profileStore } from 'src/modules/profile/profile-store';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';
import { useBillingAnalytics } from 'src/pages/settings/components/Billing/useBillingAnalytics';
import { settingsLocations } from 'src/pages/settings/locations';
import { getAccountNumber4digits } from 'src/utils/bank-account';
import { getCard4Lastdigits } from 'src/utils/card';
import { FundingType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { getBillingMethod, getPaymentMethodTypeLabel, getSuccessText } from '../utils';

export const SuccessBillingPage = () => {
  useBillingAnalytics({
    trackRoute: { category: 'settings-billing', page: 'payment-method-added' },
  });
  const isAccountingFirm = useIsAccountingFirm();
  const [preservedState] = useLocationState<Record<string, boolean>>('preservedState');
  const isMethodChanged = preservedState?.isMethodChanged;
  const { companyName: firmName } = useSelector(profileStore.selectors.getFirstAccountingFirm) || {};
  const { companyName: clientName } = useSelector(profileStore.selectors.getCurrentOrg);
  const { organizationBillingFees, loading: isOrgBillingFeeLoading } = useGetOrganizationBillingFees();
  const {
    firmBillingFees,
    assignedClients,
    firmFundingSource,
    loading: isFirmBillingFeeLoading,
  } = useGetFirmBillingFees();
  const selectedBillingMethod = isAccountingFirm
    ? getBillingMethod(firmBillingFees)
    : getBillingMethod(organizationBillingFees);
  const isMethodAssignedByFirm = Boolean(selectedBillingMethod?.managedByOrganizationId);
  const fundingSourceId = selectedBillingMethod?.fundingSourceId;

  const areClientsAssignedToBillingMethod = Object.keys(assignedClients || {}).some((id) => assignedClients?.[id]);
  const [filteredFundingSources] = useGetValidFundingSources();
  const currentFundingSource =
    filteredFundingSources.find((fs) => fs.id === fundingSourceId) ||
    (isMethodAssignedByFirm ? firmFundingSource : undefined);
  const [historyPush] = useHistoryWithOrgId();
  const last4Digits =
    currentFundingSource?.fundingType === FundingType.ACH
      ? getAccountNumber4digits(currentFundingSource.bankAccount)
      : getCard4Lastdigits(currentFundingSource?.cardAccount);

  const handleDoneClick = () => {
    historyPush({ path: settingsLocations.billingItems });
  };

  return isFirmBillingFeeLoading || isOrgBillingFeeLoading || !selectedBillingMethod ? (
    <AreaLoader />
  ) : (
    <SuccessLayoutPage
      illustration={IllustrationName.checkCircle}
      title={isMethodChanged ? 'billing.success.changedMethodTitle' : 'billing.success.title'}
      text={getSuccessText({
        fundingSource: currentFundingSource,
        isAccountingFirm,
        isChangeMode: isMethodChanged,
        areClientsAssigned: areClientsAssignedToBillingMethod,
        isMethodAssignedByFirm,
      })}
      textValues={{
        firmName,
        paymentMethod: <MIFormattedText label={getPaymentMethodTypeLabel(currentFundingSource)} />,
        last4Digits,
        clientName,
      }}
      buttonLabel="billing.success.done"
      buttonAction={handleDoneClick}
    />
  );
};
