import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import { FundingSourcesListLayout } from 'src/components/common/SelectMethods/containers/FundingSourcesListLayout';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { VerifyMicroDeposits } from 'src/components/micro-deposits/VerifyMicroDeposits';
import Box from 'src/core/ds/box';
import { useModal } from 'src/helpers/react/useModal';
import { useApi } from 'src/hoc/useApi';
import { useMicroDepositParams, useOrgId } from 'src/hooks';
import { useFirmClients } from 'src/hooks/accountants/useFirmClients';
import { useIsAccountingFirm } from 'src/hooks/accountants/useIsAccountingFirm';
import { accountingFirmsApi, FirmBillingFeeClient } from 'src/modules/accounting-firms/api';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { organizationBillingFeeApi } from 'src/modules/organization-billing-fee/api';
import { useGetFirmBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetFirmBillingFees';
import { useGetOrganizationBillingFees } from 'src/modules/organization-billing-fee/hooks/useGetOrganizationBillingFees';
import { profileStore } from 'src/modules/profile/profile-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';
import { EVENT_PAGE, useBillingAnalytics } from 'src/pages/settings/components/Billing/useBillingAnalytics';
import { settingsLocations } from 'src/pages/settings/locations';
import { AddFundingSourceWizardOrigin, BillingFeeType, CardTypes, FundingType } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { AccountType } from 'src/utils/types';
import { billingLocations } from '../locations';
import { getBillingMethod } from '../utils';
import { UpdateBillingModal } from './helpers/UpdateBillingModal';

const MICRO_DEPOSIT_EVENT_PAGE = 'billing-verify-manual-account';

function getDisplayedFundingSources(
  showFirmBillingMethodCard: boolean,
  fundingSources: AccountType[],
  firmFundingSource: AccountType | undefined
) {
  if (showFirmBillingMethodCard && firmFundingSource) {
    return [firmFundingSource, ...fundingSources];
  }

  return fundingSources;
}

export const SelectBillingMethodPage = () => {
  const billingAnalytics = useBillingAnalytics({
    trackRoute: { category: 'settings-billing', page: 'payment-method' },
  });
  const currentOrgId = useOrgId();
  const [historyPush] = useHistoryWithOrgId();
  const isAccountingFirm = useIsAccountingFirm();
  const isUserPartOfFirm = useSelector(profileStore.selectors.isMemberOfAccountingFirm);
  const [newFundingSourceId] = useLocationState<number>('newFundingSourceId');
  const [fundingSources] = useGetValidFundingSources();
  const {
    organizationBillingFees,
    loading: isFetchingOrgBillingFee,
    currentOrganizationBillingMethod,
    isMethodAssignedByFirm,
  } = useGetOrganizationBillingFees();
  const {
    firmFundingSource,
    firmId,
    loading: isFetchingFirmBillingFee,
    currentFirmBillingMethod,
  } = useGetFirmBillingFees();
  const { onApiCall: createOrganizationBillingFee, loading: isCreatingBillingFee } = useApi({
    api: organizationBillingFeeApi.createBillingFee,
  });
  const { onApiCall: updateOrganizationBillingFee, loading: isUpdatingOrgBillingFee } = useApi({
    api: organizationBillingFeeApi.updateBillingFee,
  });
  const { onApiCall: createFirmBillingFee, loading: isCreatingFirmBillingFee } = useApi({
    api: accountingFirmsApi.createBillingFee,
  });
  const { onApiCall: updateFirmBillingFee, loading: isUpdatingFirmBillingFee } = useApi({
    api: accountingFirmsApi.updateBillingFee,
  });
  const [selectedFundingSrcId, setSelectedFundingSrcId] = useState<number | undefined>();
  const { verifyingId, setVerifyingId, state, actions } = useMicroDepositParams(MICRO_DEPOSIT_EVENT_PAGE);
  const firmClients = useFirmClients();
  const currentOrgFundingSource = fundingSources.find(
    ({ id }) => id === currentOrganizationBillingMethod?.fundingSourceId
  );
  const firmFundingSourceId = firmFundingSource?.id;
  const currentFundingSourceId =
    currentOrgFundingSource?.id || (isMethodAssignedByFirm ? firmFundingSourceId : undefined);
  const showFirmBillingMethodCard: boolean = isUserPartOfFirm && !isAccountingFirm && !!firmFundingSource;
  const isUserUnableToSeeFirmMethod = !isUserPartOfFirm && isMethodAssignedByFirm;
  const displayedFundingSources = getDisplayedFundingSources(
    showFirmBillingMethodCard,
    fundingSources,
    firmFundingSource
  );
  const currentBillingMethod = getBillingMethod(organizationBillingFees);
  const subTitle = isUserUnableToSeeFirmMethod
    ? 'billing.addMethod.coveredByAccountantSubTitle'
    : 'billing.addMethod.subTitle';
  const isEditMode = Boolean(currentFundingSourceId) || Boolean(currentBillingMethod?.id);
  const isNextButtonEnabled = selectedFundingSrcId && currentFundingSourceId !== selectedFundingSrcId;
  const showProgressBar = isAccountingFirm && !isEditMode;

  useEffect(() => {
    if (newFundingSourceId) {
      setSelectedFundingSrcId(newFundingSourceId);

      return;
    }

    if (isMethodAssignedByFirm || (!currentFundingSourceId && isUserPartOfFirm)) {
      setSelectedFundingSrcId(firmFundingSourceId);

      return;
    }

    if (!currentFundingSourceId) return;

    setSelectedFundingSrcId(currentFundingSourceId);
  }, [currentFundingSourceId, firmFundingSourceId, isMethodAssignedByFirm, isUserPartOfFirm, newFundingSourceId]);

  const handleCancelClick = () => {
    historyPush({ path: settingsLocations.billingItems });
  };

  const handleChangeFundingSource = (fundingSource: AccountType) => {
    setSelectedFundingSrcId(fundingSource.id);
  };

  const handleAddSelectedFundingSource = (selectedFundingSourceType) => {
    const fundingSourcesTypesMap = {
      [FundingType.ACH]: {
        url: onboardingLocations.fundingSources.bank.select,
      },
      [CardTypes.CREDIT]: {
        url: onboardingLocations.fundingSources.card.index,
      },
      [CardTypes.DEBIT]: {
        url: onboardingLocations.fundingSources.card.index,
      },
    };

    const { url } = fundingSourcesTypesMap[selectedFundingSourceType];
    billingAnalytics.track('settings-billing', 'add-another-payment-method-click', {
      paymentMethodType: selectedFundingSourceType,
    });

    if (url) {
      const exitUrl = billingLocations.add;
      const preservedState = currentFundingSourceId
        ? {
            isMethodChanged: Boolean(currentFundingSourceId),
          }
        : undefined;
      historyPush({
        path: url,
        state: {
          exitUrl,
          preservedState: { ...preservedState, origin: AddFundingSourceWizardOrigin.BILLING },
        },
      });
    }
  };

  const handleNextClick = async () => {
    const paymentMethodReplaced = Boolean(currentFundingSourceId && currentFundingSourceId !== selectedFundingSrcId);
    billingAnalytics.track('settings-billing', 'payment-method-continue', {
      paymentMethodID: selectedFundingSrcId,
      paymentMethodType: fundingSources.find((fs) => fs.id === selectedFundingSrcId)?.fundingType,
      replaced: paymentMethodReplaced,
    });

    if (isUserUnableToSeeFirmMethod || paymentMethodReplaced) {
      await handleUpdateNewBillingFee();

      return;
    }

    isAccountingFirm ? await handleFirmNextClick() : await handleCreateNewBillingFee();
  };

  const handleFirmNextClick = async () => {
    if (!selectedFundingSrcId) return;

    const hasClients = firmClients.length > 0;
    const billingFundingSource: AccountType | undefined = displayedFundingSources.find(
      ({ id }) => id === selectedFundingSrcId
    );

    if (!billingFundingSource) return;

    if (hasClients) {
      historyPush({
        path: billingLocations.accountingFirm.assignClients,
        state: { billingPaymentMethod: billingFundingSource },
      });

      return;
    }

    if (!currentFirmBillingMethod) {
      const apiPayload: {
        orgId: number;
        clients: FirmBillingFeeClient[];
        fundingSourceId: number;
      } = {
        orgId: currentOrgId,
        clients: [],
        fundingSourceId: billingFundingSource.id,
      };
      await createFirmBillingFee(apiPayload);
    }

    historyPush({
      path: billingLocations.success,
    });
  };

  const handleCreateNewBillingFee = async () => {
    if (selectedFundingSrcId) {
      const isFirmMethodSelected = selectedFundingSrcId === firmFundingSourceId;
      const billingFee = {
        fundingSourceId: selectedFundingSrcId,
        isActive: true,
        billingFeeType: BillingFeeType.AchToCheck,
        managedByOrganizationId: isUserPartOfFirm && isFirmMethodSelected ? firmId : null,
      };
      await createOrganizationBillingFee({ orgId: currentOrgId, billingFee });
      historyPush({ path: billingLocations.success });
    }
  };

  const handleUpdateNewBillingFee = async () => {
    const currentBillingMethod = getBillingMethod(organizationBillingFees);

    if (selectedFundingSrcId && currentBillingMethod?.id) {
      isAccountingFirm
        ? await handleUpdateFirmBillingFee(currentBillingMethod.id, selectedFundingSrcId)
        : await handleUpdateSmbBillingFee(currentBillingMethod.id, selectedFundingSrcId);
    }
  };

  const handleUpdateFirmBillingFee = async (currentBillingMethodId: number, selectedFundingSrcId: number) => {
    await updateFirmBillingFee({
      orgId: currentOrgId,
      billingFeeId: currentBillingMethodId,
      clients: [],
      fundingSourceId: selectedFundingSrcId,
    });
    historyPush({ path: billingLocations.success, state: { preservedState: { isMethodChanged: true } } });
  };

  const [ChangeBillingMethod, showChangeMethodModal] = useModal(UpdateBillingModal, {
    billingFeeId: currentOrganizationBillingMethod?.id,
    billingFee: {
      fundingSourceId: selectedFundingSrcId,
      isActive: true,
      managedByOrganizationId: null,
    },
  });

  const handleUpdateSmbBillingFee = async (currentBillingMethodId: number, selectedFundingSrcId: number) => {
    if (isUserUnableToSeeFirmMethod) {
      showChangeMethodModal();
    } else {
      const isFirmMethodSelected = selectedFundingSrcId === firmFundingSourceId;
      const billingFee = {
        fundingSourceId: selectedFundingSrcId,
        isActive: true,
        managedByOrganizationId: isUserPartOfFirm && isFirmMethodSelected ? firmId : null,
      };
      await updateOrganizationBillingFee({
        orgId: currentOrgId,
        billingFeeId: currentBillingMethodId,
        billingFee,
      });
      historyPush({ path: billingLocations.success, state: { preservedState: { isMethodChanged: true } } });
    }
  };

  const handleVerifyClicked = (id: number) => {
    setVerifyingId(id);
  };

  const handleVerifyFinished = () => {
    setVerifyingId(null);
  };

  if (isFetchingOrgBillingFee || isFetchingFirmBillingFee) return <AreaLoader />;

  return (
    <StepLayoutPage
      title="billing.addMethod.title"
      subtitle={subTitle}
      nextLabel={isEditMode || !isAccountingFirm ? 'general.save' : 'progress.continue'}
      goExit={handleCancelClick}
      onNext={handleNextClick}
      isNextDisabled={!isNextButtonEnabled}
      relativeStep={showProgressBar ? 1 / 2 : null}
      isLoading={
        (isCreatingBillingFee || isCreatingFirmBillingFee || isUpdatingOrgBillingFee || isUpdatingFirmBillingFee) &&
        !isUserUnableToSeeFirmMethod
      }
    >
      {ChangeBillingMethod}
      <FundingSourcesListLayout
        value={displayedFundingSources.find(({ id }) => id === selectedFundingSrcId)}
        fundingSources={displayedFundingSources}
        onAddMethod={handleAddSelectedFundingSource}
        onChange={handleChangeFundingSource}
        isBillingFee
        firmBillingMethodId={!isAccountingFirm ? firmFundingSourceId : null}
        onVerifyClicked={handleVerifyClicked}
      />
      <Box marginBlockStart={10}>
        <MISecurityDetails eventPage={EVENT_PAGE} />
      </Box>
      {verifyingId && (
        <VerifyMicroDeposits
          {...state}
          {...actions}
          key={verifyingId}
          verifyingId={verifyingId}
          onVerifyFinished={handleVerifyFinished}
          eventPage={MICRO_DEPOSIT_EVENT_PAGE}
          dialogSuccessTitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.title"
          dialogSuccessSubtitle="settings.microDeposits.verifyDialogSuccess.paymentMethods.subtitle"
        />
      )}
    </StepLayoutPage>
  );
};
