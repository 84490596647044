import isEmpty from 'lodash/isEmpty';
import { ChangeEvent, PureComponent } from 'react';
import { generatePath, RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { PasswordField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { billLocations } from 'src/pages/bill/locations';
import { analytics } from 'src/services/analytics';
import {
  DeliveryMethodOrigin,
  DeliveryType,
  NotificationCardTypes,
  VENDOR_ONBOARDING_PROGRESS_BAR_STEPS,
  WizardProgressBar,
} from 'src/utils/consts';
import { handleInputChange } from 'src/utils/input';
import { BankType, FieldType } from 'src/utils/types';

type Props = {
  onDone: (navigateNextUrl?: string) => void;
  goExit: () => void;
  onPrev: () => void;
  history: RouteComponentProps['history'];
  bank: BankType;
  onChange: (arg0: FieldType) => void;
  isLoading?: boolean;
  validationErrors: Record<string, string>;
  origin: DeliveryMethodOrigin;
  errorCode: string;
  vendorName: string;
  titleValues?: Record<string, any>;
  orgId: number;
  vendorId: string;
};

const eventPage = 'new-vendor_add-bank-delivery-method';

class EditBankDeliveryMethodPage extends PureComponent<Props> {
  render() {
    const {
      isLoading,
      goExit,
      onDone,
      onChange,
      onPrev,
      validationErrors,
      bank,
      origin,
      errorCode,
      vendorName,
      titleValues,
      orgId,
      vendorId,
    } = this.props;

    const { routingNumber, accountNumber } = bank;
    const { SimpleTextFooter, WizardFormRow } = WizardElements;
    const isVendorOrigin = [
      DeliveryMethodOrigin.VENDOR_DETAILS,
      DeliveryMethodOrigin.NEW_VENDOR,
      DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS,
    ].includes(origin);

    const handleSaveDetailsAndAddBill = () => {
      analytics.track(eventPage, `save-details-and-add-bill`, {
        type: DeliveryType.ACH,
        vendorId,
      });
      onDone(`${generatePath(billLocations.create.index, { orgId })}?vendorId=${vendorId}&manually=true`);
    };

    const stepLayoutPagePropsMap = {
      [DeliveryMethodOrigin.VENDOR_DETAILS]: {
        title: isEmpty(vendorName)
          ? 'vendors.deliveryMethods.bank.titleNoVendorAlternative'
          : 'vendors.deliveryMethods.bank.title',
        titleValues: { vendor: vendorName },
        goExit,
        onSubmit: isVendorOrigin ? handleSaveDetailsAndAddBill : onDone,
        nextLabel: isVendorOrigin ? 'vendors.deliveryMethods.bank.saveAndAddBill' : 'vendors.deliveryMethods.bank.save',
        secondaryActionButtonLabel: 'vendors.deliveryMethods.bank.saveAndClose',
        onSecondaryActionButtonClick: isVendorOrigin ? onDone : undefined,
        isLoading,
        footer: (
          <SimpleTextFooter>
            <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
          </SimpleTextFooter>
        ),
        fullWidthCTA: true,
      },
      [DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_REQUESTS_LIST]: {
        title: 'onboarding.deliveryMethods.bank.title',
        subtitle: 'onboarding.deliveryMethods.bank.melioMe.subtitle',
        onSubmit: onDone,
        nextLabel: 'onboarding.deliveryMethods.bank.save',
        isLoading,
        goExit,
        fullWidthCTA: true,
      },
      [DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST]: {
        title: 'onboarding.deliveryMethods.bank.title',
        subtitle: 'onboarding.deliveryMethods.bank.melioMe.subtitle',
        onSubmit: onDone,
        nextLabel: 'onboarding.deliveryMethods.bank.save',
        isLoading,
        goExit,
        fullWidthCTA: true,
      },
      [DeliveryMethodOrigin.ONBOARDING]: {
        title: 'onboarding.deliveryMethods.bank.title',
        subtitle: 'onboarding.deliveryMethods.bank.melioMe.subtitle',
        progressBarType: WizardProgressBar.WITH_SUBSTEPS,
        progressBarSteps: VENDOR_ONBOARDING_PROGRESS_BAR_STEPS,
        minorSteps: [1, 1],
        onSubmit: onDone,
        nextLabel: 'onboarding.deliveryMethods.bank.save',
        isLoading,
        onPrev,
        fullWidthCTA: true,
      },
      [DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS]: {
        title: 'onboarding.deliveryMethods.bank.title',
        subtitle: 'onboarding.deliveryMethods.bank.melioMe.subtitle',
        onSubmit: onDone,
        nextLabel: 'onboarding.deliveryMethods.bank.save',
        isLoading,
        goExit,
        fullWidthCTA: true,
      },
      [DeliveryMethodOrigin.EDIT_PAYMENT]: {
        title: 'bills.pay.editAchDeliveryMethod.title',
        titleValues,
        relativeStep: 1 / 2,
        onSubmit: onDone,
        nextLabel: 'bills.pay.editAchDeliveryMethod.save',
        isLoading,
        goExit,
        onPrev,
        fullWidthCTA: true,
      },
      [DeliveryMethodOrigin.NEW_VENDOR_FROM_ONBOARDING]: {
        title: 'vendors.deliveryMethods.bank.title',
        titleValues: { vendor: vendorName },
        goExit,
        onSubmit: onDone,
        nextLabel: 'vendors.deliveryMethods.bank.save',
        secondaryActionButtonLabel: 'vendors.deliveryMethods.bank.saveAndClose',
        onSecondaryActionButtonClick: onDone,
        isLoading,
        footer: (
          <SimpleTextFooter>
            <MIFormattedText label="vendors.deliveryMethods.bank.saveFooter" />
          </SimpleTextFooter>
        ),
        fullWidthCTA: true,
      },
    };

    const inputLabelsMap = {
      [DeliveryMethodOrigin.VENDOR_DETAILS]: {
        routingNumber: 'vendors.deliveryMethods.bank.routingNumber',
        accountNumber: 'vendors.deliveryMethods.bank.accountNumber',
      },
      [DeliveryMethodOrigin.OWNED_VENDOR_SETTINGS]: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
      [DeliveryMethodOrigin.ONBOARDING]: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
      [DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_REQUESTS_LIST]: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
      [DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST]: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
      [DeliveryMethodOrigin.EDIT_PAYMENT]: {
        routingNumber: 'onboarding.deliveryMethods.bank.routingNumber',
        accountNumber: 'onboarding.deliveryMethods.bank.accountNumber',
      },
    };
    const stepLayoutPageProps =
      stepLayoutPagePropsMap[origin] || stepLayoutPagePropsMap[DeliveryMethodOrigin.VENDOR_DETAILS];
    const inputLabels = inputLabelsMap[origin] || inputLabelsMap[DeliveryMethodOrigin.VENDOR_DETAILS];
    const shouldShowValue = routingNumber === '';

    return (
      <StepLayoutPage hideHeader {...stepLayoutPageProps}>
        {errorCode && (
          <NotificationCard
            type={NotificationCardTypes.ERROR}
            subtitle={{
              label: `server.${errorCode}`,
            }}
          />
        )}
        <WizardFormRow>
          <Box w="full">
            <PrivateDataContainer>
              <TextField
                id="routingNumber"
                testId="input-routingNumber"
                value={routingNumber}
                label={inputLabels.routingNumber}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
                type="tel"
                autoFocus
                isRequired
                errorMessage={validationErrors.routingNumber}
                autoComplete="off"
              />
            </PrivateDataContainer>
          </Box>
        </WizardFormRow>

        <WizardFormRow>
          <Box w="full">
            <PrivateDataContainer>
              <PasswordField
                id="accountNumber"
                testId="input-accountNumber"
                value={accountNumber}
                label={inputLabels.accountNumber}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange({ event, onChange, inputMode: 'numeric' })
                }
                errorMessage={validationErrors.accountNumber}
                shouldShowValue={shouldShowValue}
                autoComplete="new-password"
                inputMode="numeric"
                isRequired
              />
            </PrivateDataContainer>
          </Box>
          <HiddenInput type="password" autoComplete="new-password" />
        </WizardFormRow>
      </StepLayoutPage>
    );
  }
}

export default compose(withRouter)(EditBankDeliveryMethodPage);

const HiddenInput = styled.input`
  display: none;
`;

const NotificationCard = styled(MINotificationCard)`
  margin: 0 0 4rem;
`;
