import { useEffect } from 'react';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { MicroDepositStatusResponse } from 'src/modules/delivery-methods/types';
import { ERR_BANK_ACCOUNT_BLOCKED_CODE, MicroDepositStatuses, VERIFIED } from '../consts';

export const useMicroDepositStatusState = (token: string): { microDepositStatus: MicroDepositStatuses } => {
  const { onApiCall: getMicroDepositsStatusWithToken, result: apiResult, error: apiError } = useApi<
    [string],
    MicroDepositStatusResponse
  >({
    api: deliveryMethodsApi.getMicroDepositsStatusWithToken,
  });
  let microDepositStatus = MicroDepositStatuses.WAITING_FOR_STATUS;
  useEffect(() => {
    getMicroDepositsStatusWithToken(token);
  }, [token, getMicroDepositsStatusWithToken]);

  if (apiError) {
    microDepositStatus =
      apiError?.code === ERR_BANK_ACCOUNT_BLOCKED_CODE
        ? MicroDepositStatuses.TOO_MANY_ATTEMPTS
        : MicroDepositStatuses.ERROR;
  }

  if (apiResult) {
    microDepositStatus =
      apiResult.status === VERIFIED ? MicroDepositStatuses.ALREADY_VERIFIED : MicroDepositStatuses.NOT_VERIFIED;
  }

  return { microDepositStatus };
};
