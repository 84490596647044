import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { MICategoryList } from 'src/components/common/MICategoryList';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import invitationsStore from 'src/modules/invitations/invitations-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import organizationStore, { getOrganizationStoreActions } from 'src/modules/organizations/organizations-store';
import { authLocations } from 'src/pages/auth/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { getOrgId } from 'src/redux/user/selectors';
import { CompanyType, Role } from 'src/utils/consts';

const accountantTypeOptions = [
  {
    id: 'yes',
    label: 'categoryList.yes',
  },
  {
    id: 'no',
    label: 'auth.registerFromInvitation.no',
  },
];

export const RegisterFromInvitationAccountantType = () => {
  const [isExternal, setIsExternal] = useState('');
  const dispatch = useDispatch();
  const [historyPush] = useHistoryWithOrgId();
  const { create } = useMemo(() => getOrganizationStoreActions(dispatch), [dispatch]);
  const isCreating = useSelector(organizationStore.selectors.create.status())?.loading;
  const {
    userOrganization: { user, organizationId },
  } = useSelector(invitationsStore.selectors.validation);
  const invitingUserCompanyName = `${user.firstName} ${user.lastName}`;
  const orgId = useSelector(getOrgId);

  const initUserContext = (orgId) =>
    new Promise((resolve, reject) => {
      dispatch(checkAndInitUserAction({ orgId }, resolve, reject));
    });

  const createAccountingFirm = async () => {
    const res = await create({
      role: Role.ACCOUNTANT,
      noInvitation: false,
      companyType: CompanyType.ACCOUNTING_FIRM,
    });
    await initUserContext(res.payload.id);

    return res.payload.id;
  };

  const goNext = async () => {
    const isExternalAccountantType = isExternal === 'yes';

    if (!isExternalAccountantType) {
      historyPush({ path: authLocations.register.acceptInvitationSuccess });

      return;
    }

    const newOrgId = organizationId === orgId ? await createAccountingFirm() : orgId;
    historyPush({
      path: generatePath(onboardingLocations.companyInfo.contacts, { orgId: newOrgId }),
      state: { invitingOrgId: orgId },
    });
  };

  return (
    <StepLayoutPage
      title="auth.registerFromInvitation.accountantTypeTitle"
      titleValues={{ companyName: <Title>{invitingUserCompanyName}</Title> }}
      onNext={goNext}
      isNextDisabled={!isExternal}
      nextLabel="progress.continue"
      isLoading={isCreating}
    >
      <MICategoryList categories={accountantTypeOptions} selectedId={isExternal} onSelect={setIsExternal} />
    </StepLayoutPage>
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;
