import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { approvalWorkflowsStore } from 'src/modules/approval-workflows/approval-workflows-store';
import { profileStore } from 'src/modules/profile/profile-store';
import usersStore from 'src/modules/users/users-store';
import { Role } from 'src/utils/consts';

export function useFetchApprovalWorkflowsAndUsers({ orgId }) {
  const userActions = useStoreActions(usersStore);
  const approvalWorkflowActions = useStoreActions(approvalWorkflowsStore);

  useEffect(() => {
    userActions.userManagementListSlice({ orgId });
    approvalWorkflowActions.list({ orgId });
  }, [orgId, approvalWorkflowActions, userActions]);
}

export function useCanCreateWorkflow() {
  const currentUserRole = useSelector(profileStore.selectors.getUserRole);

  return currentUserRole !== Role.CONTRIBUTOR;
}
