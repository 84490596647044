import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { CardTypes, FundingType } from 'src/utils/consts';
import { getNameParts } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

type Params = {
  fundingSource: AccountType;
  useShortName: boolean;
};

export const useGetFundingSourceDisplayName = () => {
  const intl = useIntl();
  const getFundingSourceDisplayName = useCallback(
    ({ fundingSource, useShortName }: Params): string => {
      if (!fundingSource) {
        return '';
      }

      const label = getLabel(fundingSource, useShortName);
      const nameParts = getNameParts(fundingSource);

      return intl.formatMessage({ id: label }, nameParts);
    },
    [intl]
  );

  const getLabel = (fundingSource: AccountType, useShortName: boolean): string => {
    let label = 'bills.pay.fundingSource.achLabel';

    if (fundingSource.fundingType === FundingType.CARD) {
      label =
        fundingSource.cardAccount?.cardType === CardTypes.CREDIT
          ? 'bills.pay.fundingSource.creditLabel'
          : 'bills.pay.fundingSource.debitLabel';
    }

    return `${label}${useShortName ? 'Short' : ''}`;
  };

  return { getFundingSourceDisplayName };
};
