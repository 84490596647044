import { MISecurityDetails } from 'src/components/common/MISecurityDetails';
import Box from 'src/core/ds/box/Box';
import { useBreak } from 'src/hoc';

export const SecurityComplianceMessage = () => {
  const { isMobile, isPhablet } = useBreak();

  return (
    <Box width={isMobile || isPhablet ? '34rem' : '45rem'} margin="0 auto">
      <MISecurityDetails eventPage="payment-method-credit-card" />
    </Box>
  );
};
