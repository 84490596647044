import { featureFlags } from '@melio/shared-web';
import { useSelector } from 'react-redux';
import {
  getCreditCardFundingSourceDescription,
  getEmptyCreditCardFundingSourceDescription,
} from 'src/components/common/SelectMethods/types/utils';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks/usePayablesConnectedAccountingPlatform';
import { profileStore } from 'src/modules/profile/profile-store';
import {
  convertDateToMonthYearFormat,
  getCardIcon,
  getCardImageUrl,
  getExpirationDateHint,
  isCardExpired,
} from 'src/utils/card';
import { CardTypes, FeatureFlags } from 'src/utils/consts';
import { getModifyFundingSourceOptions, getNameParts } from 'src/utils/funding-sources';
import { AccountType } from 'src/utils/types';

type Props = {
  onClick?: () => void;
  method: AccountType;
  showDropdownSelectedMethod: boolean;
  isSelected: boolean;
  optionComponent: any;
  disabled: boolean;
  isDefault: boolean;
  showFirmBillingFeeBadge: boolean;
  isSettings: boolean;
  modifyActions: Record<string, VoidFunction>;
  isVendorAbsorbedFee?: boolean;
  isInternationalVendor?: boolean;
  isBillingFee?: boolean;
};

export const CreditPaymentMethod = ({
  onClick,
  method,
  isSelected,
  optionComponent: OptionComponent,
  disabled,
  isDefault,
  showFirmBillingFeeBadge,
  isSettings,
  modifyActions,
  isVendorAbsorbedFee,
  isInternationalVendor,
  isBillingFee,
}: Props) => {
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const { isConnected: isConnectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const [isInternationalMastercardEnabled] = featureFlags.useFeature(
    FeatureFlags.EnableMastercardFSForInternational,
    false,
    {
      shouldTrack: false,
    }
  );

  const isInternationalDisabled = isInternationalVendor && !isInternationalMastercardEnabled;

  if (!method) {
    const description = getEmptyCreditCardFundingSourceDescription({
      isVendorAbsorbedFee,
      isInternationalDisabled,
      isBillingFee,
      isSettings,
    });

    return (
      <OptionComponent
        id="credit"
        label="onboarding.fundingSources.options.credit.label"
        description={description}
        descriptionValues={{
          cardIcons: null,
        }}
        icon="icon-credit-card-icon"
        onClick={onClick}
        isEmptyList
        disabled={isInternationalDisabled}
      />
    );
  }

  const icon = getCardIcon(method);
  const imageSrc = getCardImageUrl(method);
  const isCreditCardExpired = isCardExpired(method.cardAccount);
  const actionOptions = getModifyFundingSourceOptions(
    permissions,
    modifyActions,
    isConnectedAccountingPlatform,
    disabled,
    method.origin,
    isCreditCardExpired,
    !!method.nickname,
    isDefault
  );

  const description = getCreditCardFundingSourceDescription({
    isBillingFee,
    isSettings,
    isCreditCardExpired,
    isVendorAbsorbedFee,
    fundingSource: method,
  });

  return (
    <OptionComponent
      id={method.id}
      isSelected={isSelected}
      disabled={disabled}
      isDefault={isDefault}
      showFirmBillingFeeBadge={showFirmBillingFeeBadge}
      isExpired={isCreditCardExpired}
      icon={icon}
      imageSrc={imageSrc}
      label="bills.pay.fundingSource.creditLabel"
      labelValues={{
        ...getNameParts(method),
      }}
      onTopDescription={method.nickname}
      hint={getExpirationDateHint(method.cardAccount)}
      hintValues={{
        expirationDate: convertDateToMonthYearFormat(method.cardAccount?.expiration),
      }}
      description={description}
      onClick={onClick}
      actionOptions={actionOptions}
    />
  );
};

CreditPaymentMethod.methodType = CardTypes.CREDIT;
CreditPaymentMethod.addAnotherMethodLabel = 'settings.paymentMethods.addAnotherCreditCard';
CreditPaymentMethod.isDisabled = (method) => isCardExpired(method.cardAccount);
CreditPaymentMethod.showAddAnotherOption = true;
