import { CardNumberElement } from '@basis-theory/basis-theory-react';
import { CardNumberElement as CardNumberElementType } from '@basis-theory/basis-theory-react/types';
import { MutableRefObject, useState } from 'react';
import { BasisTheoryCardBrandType } from 'src/components/basis-theory/brands-logos/types';
import { FieldErrors } from 'src/components/basis-theory/fields/common/FieldErrors';
import { FieldInputWrapper } from 'src/components/basis-theory/fields/common/FieldInputWrapper';
import { FieldTitle } from 'src/components/basis-theory/fields/common/FieldTitle';
import { CARD_NUMBER_CONFIGURATION } from 'src/components/basis-theory/fields/consts';
import { useErrorValidations } from 'src/components/basis-theory/fields/hooks/useErrorValidations';
import { CardFieldProps } from 'src/components/basis-theory/types';
import Flex from 'src/core/ds/flex/Flex';
import { useCardBrand } from './hooks/useCardBrand';

type Props = CardFieldProps<CardNumberElementType> & {
  onCardBrandChange(cardBrand: BasisTheoryCardBrandType): void;
};

export const CardNumberField = ({
  elementStyle,
  onCardBrandChange,
  onError,
  attemptedToLink,
  elementRef,
  ...rest
}: Props) => {
  const [focus, setFocus] = useState(false);

  const onBlur = () => {
    setFocus(false);
  };

  const { handleChange: onChange, handleBlur, shouldShowError, errors, empty } = useErrorValidations({
    onError,
    onBlur,
    attemptedToLink,
  });

  const { handleChange } = useCardBrand({
    onChange,
    onCardBrandChange,
  });

  const handleFocus = () => {
    setFocus(true);
  };

  return (
    <Flex flexDirection="column" w="full">
      <FieldTitle id="cn" label="linkCard.cardNumberTitle" error={shouldShowError} />
      <FieldInputWrapper error={shouldShowError} focus={focus}>
        <CardNumberElement
          style={elementStyle}
          {...rest}
          iconPosition="none"
          placeholder={CARD_NUMBER_CONFIGURATION.placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          ref={elementRef as MutableRefObject<CardNumberElementType>}
        />
      </FieldInputWrapper>
      {shouldShowError ? (
        <FieldErrors
          testId="card-number-errors"
          fieldErrors={errors}
          emptyLabel="linkCard.errors.cardNumber.empty"
          invalidLabel="linkCard.errors.cardNumber.invalid"
          empty={empty}
        />
      ) : null}
    </Flex>
  );
};
