import styled from 'styled-components';
import { MISecurityDetails } from 'src/components/common/MISecurityDetails';

type Props = {
  companyName?: string;
};

export const ContentFooter = ({ companyName }: Props) => (
  <SecurityDetails
    eventPage="push-to-fast-ach"
    label="vendors.upgradePayment.footer.securityText"
    values={{ companyName }}
  />
);

const SecurityDetails = styled(MISecurityDetails)`
  padding-top: 4rem;
  justify-content: space-between;
`;
