import { ApprovalWorkflowCreatorsGroup } from 'src/utils/consts';
import { PaymentCreatorIdsType } from 'src/utils/types';
import { WorkflowModelType } from './types';
import { workflowModelToDBWorkflow } from './utils';

const invalidPaymentCreators = (
  creatorsGroup: ApprovalWorkflowCreatorsGroup | null,
  paymentCreatorIds?: PaymentCreatorIdsType
): boolean => {
  if (creatorsGroup === ApprovalWorkflowCreatorsGroup.SOME) {
    return !paymentCreatorIds?.length;
  }

  return paymentCreatorIds !== undefined;
};

export const getApprovalWorkflowFormValidator = (maxThreshold?: number) => (
  key: string,
  value: any,
  modelState: WorkflowModelType
): string | undefined => {
  const newModelState = { ...modelState };
  newModelState[key] = value;
  const dbWorkflow = workflowModelToDBWorkflow(newModelState);

  switch (key) {
    case 'approvalDecisionsCount':
      if (
        Number.isNaN(dbWorkflow.approvalDecisionsCount) ||
        dbWorkflow.approvalDecisionsCount < 1 ||
        dbWorkflow.approvalDecisionsCount > 3
      )
        return 'settings.approvalWorkflows.modal.validation.approvalDecisionsCount';

      break;
    case 'threshold':
      if (Number.isNaN(dbWorkflow.threshold) || dbWorkflow.threshold < 0 || dbWorkflow.threshold.toString().length > 10)
        return 'settings.approvalWorkflows.modal.validation.threshold';

      if (maxThreshold && dbWorkflow.threshold && dbWorkflow.threshold > maxThreshold)
        return 'settings.approvalWorkflows.modal.validation.thresholdTooHigh';

      break;

    case 'creatorsGroup':
      if (
        !dbWorkflow.creatorsGroup ||
        !Object.values(ApprovalWorkflowCreatorsGroup).some((group) => group === dbWorkflow.creatorsGroup)
      )
        return 'settings.approvalWorkflows.modal.validation.creatorsGroup';

      break;

    case 'paymentCreatorsOptions':
      if (invalidPaymentCreators(modelState.creatorsGroup, dbWorkflow.paymentCreatorIds))
        return 'settings.approvalWorkflows.modal.validation.paymentCreators';

      break;

    default:
      return undefined;
  }

  return undefined;
};
