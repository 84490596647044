import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useApi } from 'src/hoc/useApi';
import clientServiceApi from 'src/services/api/clientService';
import { PASSWORD } from 'src/ui/form/components/StrongPassword/consts';

export const usePasswordValidation = (password: string): { score: number; label: string } => {
  const { onApiCall: getPasswordScore } = useApi({
    api: clientServiceApi.getPasswordScore,
  });
  const [score, setScore] = useState(PASSWORD.INVALID_SCORE);
  const getPasswordScoreWithDebounce = debounce(async (password) => {
    if (password.length >= PASSWORD.MIN_LENGTH) {
      const passwordScoreResponse = await getPasswordScore(password);
      setScore(passwordScoreResponse.score);
    } else {
      setScore(PASSWORD.INVALID_SCORE);
    }
  }, 300);
  useEffect(() => {
    getPasswordScoreWithDebounce(password);
  }, [password]);

  const passwordScoreMapping = {
    '-1': null,
    '0': 'weak',
    '1': 'weak',
    '2': 'good',
    '3': 'strong',
    '4': 'strong',
  };

  const label = `inputErrors.userRegistrationStrong.password.strongPassword.${passwordScoreMapping[score]}`;

  return {
    score,
    label,
  };
};
