import { isFinancedPayment, isPaymentFailedRefund } from 'src/utils/payments';
import { PaymentType } from 'src/utils/types';
import { getLearnMoreAboutFinancingLink, getLearnMoreAboutRefundLink } from './consts';

export const getLearnMoreLink = (payment: PaymentType) => {
  const isFinancing = isFinancedPayment(payment);
  const isFailedRefund = isPaymentFailedRefund(payment);

  if (isFinancing) {
    return getLearnMoreAboutFinancingLink;
  }

  if (isFailedRefund) {
    return getLearnMoreAboutRefundLink;
  }

  return null;
};
