import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex/Flex';
import {
  PrivacyPolicy,
  TermsAndConditions,
  TermsOfService,
} from 'src/pages/bill/pay/installments/pages/components/TermsAndConditions';

type Props = {
  onPrivacyPolicyClick: () => void;
  onTermsOfServiceClick: () => void;
  payBillFlowUUID?: string;
};

export const Agreement = ({ onPrivacyPolicyClick, onTermsOfServiceClick, payBillFlowUUID }: Props) => (
  <Flex direction="column" textStyle="caption1" color="grey.700">
    <Box mb={2}>
      <MIFormattedText
        label="financing.businessOwnershipDetails.agreementMessage.part1"
        values={{
          termsOfServiceAndElectronicDisclosures: (
            <TermsOfService
              onClick={onTermsOfServiceClick}
              label="financing.businessOwnershipDetails.agreementMessage.termsOfServiceAndElectronicDisclosures"
            />
          ),
          privacyPolicy: <PrivacyPolicy onClick={onPrivacyPolicyClick} />,
        }}
      />
    </Box>
    <Box mb={4}>
      <MIFormattedText label="financing.businessOwnershipDetails.agreementMessage.part2" />
    </Box>
    <TermsAndConditions payBillFlowUUID={payBillFlowUUID} />
  </Flex>
);
