import { DeliveryDate } from 'src/components/common/DeliveryDate';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import {
  PaymentActivityContainer,
  PaymentActivityMainContainer,
  PaymentIcon,
  PaymentInfoContainer,
  PaymentInfoText,
  PaymentMethodText,
  PaymentTextContainer,
} from 'src/components/layout/PaymentActivityElements';
import { Badge } from 'src/core/ds/badge';
import Box from 'src/core/ds/box';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { getDeliveryDateInfoLabel } from 'src/pages/bill/utils';
import { BillStatus, DeliveryType } from 'src/utils/consts';
import { isSameDay } from 'src/utils/dates';
import { isFastDeliveryType } from 'src/utils/delivery-methods';
import { getCheckDepositedDate } from 'src/utils/payments';
import { AccountType, BillType, DeliveryMethodType, PaymentType } from 'src/utils/types';
import CheckDeposited from './CheckDeposited';
import DeliveryDateInfoHint from './DeliveryDateInfoHint';

type Props = {
  payment: PaymentType;
  bill: BillType;
  deliveryMethod: DeliveryMethodType;
  fundingSource: AccountType;
};

export const ViewBillPaymentActivityDeliveryDateInfo = ({ payment, bill, deliveryMethod, fundingSource }: Props) => {
  const isBillPaid = bill.status === BillStatus.PAID;
  const isSameDayDelivery = isSameDay(payment.deliveryEta);
  const isFast = isFastDeliveryType(payment.deliveryPreference);
  const label = getDeliveryDateInfoLabel(deliveryMethod, isBillPaid, payment);
  let checkDepositedDate;

  if (isBillPaid && deliveryMethod.deliveryType === DeliveryType.CHECK) {
    checkDepositedDate = getCheckDepositedDate(payment.transactions);
  }

  return (
    <>
      <PaymentActivityContainer data-testid="bill-pay-delivery-date-info">
        <PaymentActivityMainContainer>
          <PaymentInfoContainer>
            <PaymentIcon isBillPaid={isBillPaid}>
              <Icon name={IconNames.calendarMove} size={IconSize.lg} />
            </PaymentIcon>
            <PaymentTextContainer>
              <PaymentMethodText>
                <MIFormattedText label={label} />
              </PaymentMethodText>
              <PaymentInfoText isBillPaid={isBillPaid}>
                {isSameDayDelivery ? (
                  <MIFormattedText label="bills.pay.date.deliveryOptions.fastAchDates.today" />
                ) : (
                  <DeliveryDate date={payment.deliveryEta} maxDate={payment.maxDeliveryEta} />
                )}
                {isFast && (
                  <Box ml={1} pos="relative" top="-2px" as="span">
                    <Badge label="bills.pay.date.deliveryOptions.price.fastTag" />
                  </Box>
                )}
              </PaymentInfoText>
              <PaymentInfoText>
                {!isBillPaid && (
                  <DeliveryDateInfoHint
                    payment={payment}
                    deliveryMethod={deliveryMethod}
                    fundingSource={fundingSource}
                  />
                )}
              </PaymentInfoText>
            </PaymentTextContainer>
          </PaymentInfoContainer>
        </PaymentActivityMainContainer>
      </PaymentActivityContainer>
      {checkDepositedDate && <CheckDeposited date={checkDepositedDate} />}
    </>
  );
};
