import { isValid } from 'date-fns';
import flow from 'lodash/flow';
import isEmpty from 'lodash/isEmpty';
import dateFromModelValue from 'src/components/modals/DownloadCsvReportModal/modules/dateFromModelValue';
import {
  BeneficialOwner,
  BeneficialOwnerConstructedFields,
  InternalBusinessOwnershipDetailsModel,
} from 'src/pages/bill/pay/installments/pages/application-details/business-ownership-details/types';
import { EMAIL_REGEX, TAX_ID_SSN_REGEX } from 'src/pages/bill/pay/installments/utils';

export type ValidatableBeneficialOwnerKeys = BeneficialOwnerConstructedFields;
const extractKeyFromDynamicKey = (key: ValidatableBeneficialOwnerKeys) =>
  flow(
    (key: ValidatableBeneficialOwnerKeys) => key.split('.'),
    (splitKey: [...Array<string>, keyof BeneficialOwner]) => splitKey[splitKey.length - 1]
  )(key);
export const validator = (key: ValidatableBeneficialOwnerKeys, modelState: InternalBusinessOwnershipDetailsModel) => {
  const extractedKey = extractKeyFromDynamicKey(key);
  switch (extractedKey) {
    case 'firstName':
      if (isEmpty(modelState[key])) return 'financing.businessOwnershipDetails.fields.firstName.errors.empty';

      break;
    case 'lastName':
      if (isEmpty(modelState[key])) return 'financing.businessOwnershipDetails.fields.lastName.errors.empty';

      break;
    case 'email':
      if (isEmpty(modelState[key])) return 'financing.businessOwnershipDetails.fields.email.errors.empty';

      if (!EMAIL_REGEX.test(modelState[key])) return 'financing.businessOwnershipDetails.fields.email.errors.invalid';

      break;
    case 'dateOfBirth':
      if (isEmpty(modelState[key])) return 'financing.businessOwnershipDetails.fields.dateOfBirth.errors.empty';

      if ((modelState[key].length >= 1 && modelState[key].length < 10) || !isValid(dateFromModelValue(modelState[key])))
        return 'financing.businessOwnershipDetails.fields.dateOfBirth.errors.invalid';

      break;
    case 'ssn':
      if (isEmpty(modelState[key])) return 'financing.businessOwnershipDetails.fields.ssn.errors.empty';

      if (!TAX_ID_SSN_REGEX.test(modelState[key]))
        return 'financing.businessOwnershipDetails.fields.ssn.errors.invalid';

      break;
    case 'ownershipPercentage':
      if (isEmpty(modelState[key])) return 'financing.businessOwnershipDetails.fields.ownershipPercentage.errors.empty';

      break;
    default:
      return undefined;
  }

  return undefined;
};
