import { Dispatch } from '@reduxjs/toolkit';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import { GLOBAL_ERROR_OCCURRED, initialState, name } from 'src/modules/error/consts';
import { GlobalState } from 'src/redux/types';
import { ErrorState } from 'src/utils/types';

const errorStore = createRestfulSlice({
  name,
  initialState,
  api: {},
  extraReducers: {
    [GLOBAL_ERROR_OCCURRED](state, payload) {
      const { title, subtitle } = payload;
      state[name].errorOccurred = true;
      state[name].title = title;
      state[name].subtitle = subtitle;
    },
  },
  selectors: {
    getError: (state: GlobalState): ErrorState => state[name],
  },
});

export function getErrorStoreActions(dispatch: Dispatch<any>) {
  return {
    globalErrorOccurred: (title?: string, subtitle?: string) =>
      dispatch({
        type: GLOBAL_ERROR_OCCURRED,
        title,
        subtitle,
      }),
  };
}

export default errorStore;
