import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';

export type FormProps = {
  children: React.ReactNode;
  onSubmit?: () => Promise<void>;
  onKeyDown?: (event: React.KeyboardEvent<any>) => void;
  size?: 'sm' | 'md';
  id?: string;
};

export const Form = ({ size = 'md', id, onSubmit, onKeyDown, children }: FormProps) => {
  const spacing = size === 'sm' ? 4 : 10;

  return (
    <Box as="form" w="full" onSubmit={onSubmit} onKeyDown={onKeyDown} id={id}>
      <Flex w="full" direction="column" gap={spacing}>
        {children}
      </Flex>
    </Box>
  );
};
