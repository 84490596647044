import pick from 'lodash/fp/pick';
import { createUpdateSlice } from 'src/helpers/redux/restUpdateSlice';
import { billsApi } from 'src/modules/bills/api';
import { name } from 'src/modules/vendors/vendors-store';
import { BillStatus } from 'src/utils/consts';
import { BillsCountByPaymentStatusResponse, BillsCountByStatusResponse } from 'src/utils/types';

type Payload = {
  orgId: number;
  id: string;
};

type SingleVendorBillsState = {
  billsCountByBillStatus: BillsCountByStatusResponse | null;
  billsCountByPaymentStatus: BillsCountByPaymentStatusResponse | null;
};

type VendorBillsCountState = { byId: Record<string, SingleVendorBillsState> };

const emptyVendorBillsCount: BillsCountByStatusResponse = {
  paid: { count: 0, sum: 0 },
  unpaid: { count: 0, sum: 0 },
  scheduled: { count: 0, sum: 0 },
};

const emptyVendorBillsByPaymentStatusCount: BillsCountByPaymentStatusResponse = {
  approvalDeclined: { count: 0, sum: 0 },
  approvalPending: { count: 0, sum: 0 },
  failed: { count: 0, sum: 0 },
  paid: { count: 0, sum: 0 },
  paymentRequests: { count: 0, sum: 0 },
  pending: { count: 0, sum: 0 },
  scheduled: { count: 0, sum: 0 },
};

export const countVendorBillsSlice = createUpdateSlice({
  storeName: name,
  actionName: 'countBills',
  async api({ orgId, id }: Payload) {
    const res = await billsApi.getBillsStatusCount({ orgId, vendorId: id });

    return {
      billsCountByBillStatus: pick(Object.values(BillStatus), res),
      billsCountByPaymentStatus: res.byPaymentStatus,
    };
  },
  selectors: {
    getVendorBillsCount: (vendorId: string) => (state: {
      vendors: VendorBillsCountState;
    }): BillsCountByStatusResponse => state.vendors.byId[vendorId]?.billsCountByBillStatus || emptyVendorBillsCount,
    getVendorBillsCountByPaymentStatus: (vendorId: string) => (state: {
      vendors: VendorBillsCountState;
    }): BillsCountByPaymentStatusResponse =>
      state.vendors.byId[vendorId]?.billsCountByPaymentStatus || emptyVendorBillsByPaymentStatusCount,
  },
});
