import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/redux/user/selectors';

export const useIsOnboard = () => {
  const companyInfo = useSelector(getCompanyInfo);

  return !!(
    companyInfo.companyName &&
    companyInfo.formattedAddress &&
    companyInfo.legalCompanyName &&
    companyInfo.legalFormattedAddress &&
    companyInfo.contactFirstName &&
    companyInfo.contactLastName &&
    companyInfo.phone &&
    companyInfo.companyType
  );
};
