import { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { generatePath, Switch, useLocation } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { useApi } from 'src/hoc/useApi';
import { msnPortalApi } from 'src/modules/msn-portal/api';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { CreatePasswordPage } from 'src/pages/onboarding/msn-registration/claim-account/CreatePasswordPage';
import { GeneralErrorPage } from 'src/pages/onboarding/msn-registration/claim-account/GeneralErrorPage';
import { ClaimAccountEmailVerificationPageContainer } from './containers/EmailVerificationPageContainer';

export const ClaimAccountTokenValidationPublicRouter = () => {
  const { navigate } = useNavigator();
  const { state: locationState } = useLocation<{ emailCodeVerified: boolean }>();
  const { token } = useRouteMatch<{ token: string }>().params;
  const { onApiCall: getClaimAccountTokenValidationStatus, loading, result, error } = useApi({
    api: msnPortalApi.getClaimAccountTokenValidationStatus,
    initialLoading: true,
  });

  useEffect(() => {
    if (token) {
      getClaimAccountTokenValidationStatus({ token });
    }
  }, [token, getClaimAccountTokenValidationStatus]);

  useEffect(() => {
    if (result) {
      const { isTokenValid, isGuest, organizationId: orgId } = result;

      if (isTokenValid) {
        if (isGuest) {
          navigate(
            locationState?.emailCodeVerified
              ? generatePath(onboardingLocations.msnRegistration.claimAccount.createPassword, { token })
              : generatePath(onboardingLocations.msnRegistration.claimAccount.emailVerification, { token })
          );
        } else {
          navigate(generatePath(onboardingLocations.msnRegistration.claimAccount.deliveryMethodStatusCheck, { orgId }));
        }
      } else {
        navigate(onboardingLocations.msnRegistration.claimAccount.generalError);
      }
    }
  }, [result, token]);

  useEffect(() => {
    if (error) {
      navigate(onboardingLocations.msnRegistration.claimAccount.generalError);
    }
  }, [error]);

  if (loading) {
    return <AreaLoader />;
  }

  return result?.email && result?.organizationId ? (
    <Switch>
      <SmartRoute exact path={onboardingLocations.msnRegistration.claimAccount.emailVerification}>
        <ClaimAccountEmailVerificationPageContainer email={result.email} orgId={result.organizationId} />
      </SmartRoute>
      <SmartRoute exact path={onboardingLocations.msnRegistration.claimAccount.createPassword}>
        <CreatePasswordPage email={result.email} orgId={result.organizationId} />
      </SmartRoute>
    </Switch>
  ) : (
    <GeneralErrorPage />
  );
};
