import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { compose } from 'recompose';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { withNavigator } from 'src/hoc';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import {
  CompanyIndustryAutocomplete,
  OnSelectNaicsCode,
} from 'src/pages/onboarding/company-info/components/CompanyIndustryAutocomplete';
import { CompanyInfoProps, withCompanyInfoUpdates } from 'src/pages/onboarding/company-info/hoc/withCompanyInfoUpdates';
import { getCompanyInfo, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { industryApi, UpdateIndustryRequestData } from 'src/services/api/industry';

type Props = {
  nextStepURL: string;
  prevStepURL: string;
} & CompanyInfoProps;

export const CompanyIndustryPage = compose(withNavigator(), withCompanyInfoUpdates())(CompanyIndustryPageFn);

function CompanyIndustryPageFn({ navigateToCompanyOnboardingPage, nextStepURL, prevStepURL }: Props) {
  const orgId = Number(useSelector(getOrgId));
  const companyInfo = useSelector(getCompanyInfo);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const [{ naicsCode, industryText }, setNaicsData] = useState<UpdateIndustryRequestData>({
    naicsCode: null,
    industryText: '',
  });
  const [isFreeTextIndustry, setIsFreeTextIndustry] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();

  const onPrev = useCallback(() => {
    navigateToCompanyOnboardingPage(prevStepURL);
  }, [prevStepURL, navigateToCompanyOnboardingPage]);
  const onNext = useCallback(async () => {
    analytics.track('onboarding-company-info-industry', 'continue', {
      selectedIndustry: naicsCode,
      isGetPaid: !!ownedVendorId,
    });
    setLoading(true);
    try {
      await industryApi.updateIndustry(orgId, { naicsCode, industryText });
      navigateToCompanyOnboardingPage(nextStepURL);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  }, [orgId, naicsCode, navigateToCompanyOnboardingPage, nextStepURL, industryText, ownedVendorId]);

  const onReset = () => {
    setNaicsData({ naicsCode: null, industryText: undefined });
    setIsFreeTextIndustry(false);
  };
  const onSelectNaicsCode: OnSelectNaicsCode = (naicsData) => {
    setNaicsData(naicsData);

    if (naicsData.industryText && naicsData.naicsCode === null) setIsFreeTextIndustry(true);
  };

  const getProgressBarRelativeStep = () => (isUnifiedOnboardingOpen ? 5 / 13 : 5 / 7);

  return (
    <StepLayoutPage
      title="onboarding.companyInfo.industry.title"
      titleValues={{
        companyName: (
          <>
            <br />
            <CompanyName>{companyInfo.companyName}</CompanyName>
          </>
        ),
      }}
      relativeStep={getProgressBarRelativeStep()}
      isLoading={isLoading}
      onNext={onNext}
      onPrev={onPrev}
      isNextDisabled={!(naicsCode || isFreeTextIndustry)}
    >
      <CompanyIndustryAutocomplete onSelectNaicsCode={onSelectNaicsCode} onReset={onReset} />
    </StepLayoutPage>
  );
}

const CompanyName = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;
