import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';

type Props = { isLoading?: boolean; label: string };

export const ContactsPlaceholder = ({ isLoading, label }: Props) => {
  if (isLoading) {
    return null;
  }

  return (
    <Flex justify="center" align="center" h="full" data-testid="contacts-placeholder">
      <Box textStyle="body2" color="grey.600">
        <MIFormattedText label={label} />
      </Box>
    </Flex>
  );
};
