import { featureFlags } from '@melio/shared-web';
import { getMaxLength } from '@melio/sizzers-js-common';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import MISingleSelect, { Option } from 'src/components/common/MISingleSelect';
import { BillersVisibilityTooltip } from 'src/components/education/BillersVisibilityTooltip';
import { reviewVendorDetailsNotification } from 'src/components/msn/VerifiedVendor/consts';
import { useBusinessDetailsBoxInForm } from 'src/components/msn/VerifiedVendor/hooks/useBusinessDetailsBoxInForm';
import { VerifiedVendorDetails } from 'src/components/msn/VerifiedVendor/VerifiedVendorDetails';
import config from 'src/config';
import Box from 'src/core/ds/box';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { Consts, PrivateDataContainer } from 'src/core/ds/input';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { useAbortControllerSignal } from 'src/hooks/useAbortControllerSignal';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { getDirectoryVendors } from 'src/pages/bill/hooks/utils';
import { VendorOptionType } from 'src/pages/vendor-directory/select-vendor/types';
import {
  createNewLocalVendorOptionFromName,
  getSelectOverrideStyle,
  groupLocalAndDirectoryVendors,
} from 'src/pages/vendor-directory/select-vendor/utils';
import { VendorOptionLabel } from 'src/pages/vendor-directory/select-vendor/VendorOptionLabel';
import { OptionGroupLabel } from 'src/pages/vendor-directory/select-vendor/VendorsGroupLabel';
import { analytics } from 'src/services/analytics';
import { directoryApi } from 'src/services/api/directory';
import { ModelView } from 'src/ui/form';
import { convertToDisplayAddress } from 'src/utils/address';
import { DirectoryType, ScreenMode, SelectVendorType } from 'src/utils/consts';
import { FeatureFlags } from 'src/utils/featureFlags';
import { useQueryString } from 'src/utils/hooks';
import { VendorType } from 'src/utils/types';

type EditVendorModelType = Partial<VendorType> & {
  currentOption?: VendorOptionType;
};

type Props = {
  eventPage: string;
  orgId: any;
  contactDataMV: ModelView<EditVendorModelType>;
  onNext?: ((arg0?: any) => void) | undefined;
  primaryButtonClicked?: boolean;
  duplicateVendorNameModalView: any;
  duplicateDirectoryVendorNameError: boolean;
  formCustomization?: {
    fields: any;
  };
};

export const NewVendorForm = ({
  eventPage,
  orgId,
  contactDataMV,
  duplicateVendorNameModalView,
  duplicateDirectoryVendorNameError,
  formCustomization,
}: Props) => {
  const site = useSiteContext();
  const query = useQueryString();
  const [suggestions, setSuggestions] = useState<VendorOptionType[]>([]);
  const { getAbortControllerSignal, abortController } = useAbortControllerSignal();
  const [isBusinessDirectorySearchEnabled] = featureFlags.useFeature(FeatureFlags.BusinessDirectorySearch, false);
  const [companyNameInputValue, setCompanyNameInputValue] = useState<string>(query?.vendorName || '');
  const { selectors } = vendorsStore;
  const createStatus = useSelector(selectors.create.status());
  const error = createStatus?.error || {};
  const validationErrors = error?.validationErrors;

  const contactDataMVCurrentValue = contactDataMV.currentOption.value;
  const isBillerSelected = contactDataMVCurrentValue?.directoryType === DirectoryType.Biller;
  const groupedOptions = useMemo(() => groupLocalAndDirectoryVendors([], suggestions) as Option[], [suggestions]);

  const {
    businessDetails,
    deliveryMethodDefaultValue,
    shouldShowBusinessDetailsBox,
    shouldDisplayReviewVendorDetailsNotification,
  } = useBusinessDetailsBoxInForm({
    mode: ScreenMode.EDIT,
    vendorOptions: groupedOptions,
    selectedVendorId: contactDataMVCurrentValue ? Number(contactDataMVCurrentValue.value) : undefined,
  });

  const onCompanyNameInputChange = ({ value }) => {
    const signal = getAbortControllerSignal();

    // We should cancel the existing search fetching if the user keeps on typing, causing race conditions.
    debouncedSearchSuggestions.cancel();
    abortController?.abort();

    setCompanyNameInputValue(value);
    let newCurrentOption: VendorOptionType | undefined;

    if (isEmpty(value)) {
      newCurrentOption = undefined;
    } else {
      newCurrentOption = createNewLocalVendorOptionFromName(value);
      debouncedSearchSuggestions(value, signal);
    }

    contactDataMV.setModelState((prevState) => ({
      ...prevState,
      currentOption: newCurrentOption,
      ...(prevState.currentOption?.type === SelectVendorType.DIRECTORY
        ? {
            contactEmail: '',
            contactName: '',
            contactPhone: '',
            accountIdentifier: '',
            address: undefined,
          }
        : {}),
    }));
  };

  const debouncedSearchSuggestions = useCallback(
    debounce(async (value, signal) => {
      const {
        vendors: { biller: billers, business: businesses },
      } = await directoryApi.search({
        orgId,
        name: value,
        conf: { signal },
      });

      const directoryVendors = getDirectoryVendors({
        billers,
        businesses,
        isBusinessDirectorySearchEnabled,
      });

      setSuggestions(directoryVendors);
    }, config.debounceDelay),
    []
  );

  const onSuggestionSelect = (option) => {
    // We should not search for vendors when user already select a vendor, causing race conditions.
    debouncedSearchSuggestions.cancel();
    abortController?.abort();

    analytics.track(eventPage, 'vendor-companyName-select', {
      vendorType: option?.type || SelectVendorType.LOCAL,
      searchValue: companyNameInputValue,
      billerSuggestedCount: suggestions.length,
    });

    // eslint-disable-next-line no-underscore-dangle
    if (option.__isNew__) {
      return;
    }

    if (option.meta.action === 'clear') {
      contactDataMV.setModelState({
        contactEmail: '',
        contactName: '',
        contactPhone: '',
        accountIdentifier: '',
        address: undefined,
        currentOption: undefined,
      });

      return;
    }

    const { contactName, address, contactPhone } = option;
    contactDataMV.setModelState((prevState) => ({
      ...prevState,
      contactName: contactName || prevState.contactName,
      contactEmail: prevState.contactEmail,
      contactPhone: contactPhone || prevState.contactPhone,
      address,
      currentOption: option,
    }));
  };

  useEffect(() => {
    query?.vendorName &&
      contactDataMV.setModelState((prevState) => ({
        ...prevState,
        currentOption: createNewLocalVendorOptionFromName(query?.vendorName),
      }));
    // useEffect is being called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {duplicateVendorNameModalView}
      <Box mb={10} sx={{ '> *': { marginBottom: 10 } }}>
        <MISingleSelect
          id="companyName"
          label={
            formCustomization ? formCustomization.fields?.companyName?.label : 'contacts.create.vendors.companyName'
          }
          placeholder={formCustomization ? formCustomization.fields?.companyName?.placeholder : ''}
          errorMessage={duplicateDirectoryVendorNameError ? null : contactDataMV.companyName?.error}
          options={groupedOptions}
          value={contactDataMVCurrentValue}
          onChange={onSuggestionSelect}
          labelValues={{
            newTag: <BillersVisibilityTooltip location="vendor" />,
          }}
          onInputChange={onCompanyNameInputChange}
          overrideStyles={getSelectOverrideStyle(groupedOptions as Option[], site.theme, true)}
          formatOptionLabel={(option, formatOptions) => (
            <VendorOptionLabel option={option} formatOptions={formatOptions} />
          )}
          formatGroupLabel={(group) => <OptionGroupLabel group={group} />}
          isValidNewOption={(inputValue: string, selectValue: Option[]): boolean =>
            !!(inputValue && selectValue.some((option) => option.label === inputValue))
          }
          isClearable
          noOptionsLabel={null}
          showDropdownIndicator={false}
          filterOption={(option) => option}
          allowCustom
          required
        />

        {shouldShowBusinessDetailsBox ? (
          <Box flex={1} mb={-8} mt={-3} data-testid="verified-vendor-details-card-on-vendor-addition">
            {businessDetails && (
              <VerifiedVendorDetails
                businessName={businessDetails.label ?? ''}
                businessAddress={convertToDisplayAddress(businessDetails.address ?? {})}
                businessContactInfo={{
                  email: businessDetails.contactEmail,
                  fullName: businessDetails.contactName,
                  phone: businessDetails.contactPhone,
                  deliveryMethodLabel: deliveryMethodDefaultValue,
                }}
                notification={
                  shouldDisplayReviewVendorDetailsNotification ? reviewVendorDetailsNotification : undefined
                }
              />
            )}
          </Box>
        ) : (
          <>
            {isBillerSelected && (
              <TextField
                id="accountIdentifier"
                label="bills.form.vendorAccNum"
                placeholder="bills.form.vendorAccNumPlaceholder"
                helperText="bills.form.vendorAccNumHint"
                model={contactDataMV.accountIdentifier}
                errorMessage={validationErrors?.accountIdentifier}
                isRequired
              />
            )}

            {isBillerSelected && contactDataMV.address.value && (
              <TextField
                id="address"
                label="contacts.create.vendors.address"
                value={contactDataMV.address.value && convertToDisplayAddress(contactDataMV.address.value)}
                type="text"
                isRequired
                isDisabled
              />
            )}

            <TextField
              id="contactName"
              label={
                formCustomization ? formCustomization.fields?.contactName?.label : 'contacts.create.vendors.contactName'
              }
              model={contactDataMV.contactName}
              errorMessage={validationErrors?.contactName}
            />

            <PrivateDataContainer>
              <TextField
                id="contactEmail"
                label="contacts.create.email"
                model={contactDataMV.contactEmail}
                type="email"
                errorMessage={validationErrors?.contactEmail}
                maxLength={getMaxLength('vendor', 'contactEmail')}
              />
            </PrivateDataContainer>

            <MaskField
              id="contactPhone"
              label="contacts.create.phone"
              model={contactDataMV.contactPhone}
              type="tel"
              format={Consts.MASK_FORMAT_OPTIONS.USAPhone}
              errorMessage={validationErrors?.contactPhone}
              autoComplete="off"
              isModelOnChangeFormatted
            />
          </>
        )}
      </Box>
    </>
  );
};
