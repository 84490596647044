import { useEffect, useRef } from 'react';
import { analytics } from 'src/services/analytics';
import { FundingType } from 'src/utils/consts';

type PaymentMethodInfo = {
  paymentMethodType: FundingType | null | undefined;
  paymentMethodID: number | undefined;
};

export const EVENT_PAGE = 'settings-billing';

type EventMappings = {
  [EVENT_PAGE]: {
    'ui-events': {
      'receipt-download': {
        receiptDate: Date;
        receiptAmount: number;
      };
      'add-method-click': never;
      'payment-method-continue': PaymentMethodInfo & { replaced: boolean };
      'client-to-cover-skip': never;
      'client-to-cover-select': {
        numOfClients: number;
        numOfClientsSelected: number;
        numOfClientsDeSelected: number;
      };
      'payment-method-action': PaymentMethodInfo & { action: 'edit-method' | 'edit-clients' | 'remove-method' };
      'remove-payment-method-cancel': PaymentMethodInfo;
      'remove-payment-method': PaymentMethodInfo;
      'add-another-payment-method-click': Omit<PaymentMethodInfo, 'paymentMethodID'>;
    };
    'page-events': {
      'payment-method': never;
      'clients-to-cover': { numOfClients: number };
      'payment-method-added': never;
    };
  };
};

type Category = keyof EventMappings;

type UIEvent<C> = C extends Category ? keyof EventMappings[C]['ui-events'] : never;
type UIEventProperties<C, E> = C extends Category
  ? E extends UIEvent<C>
    ? EventMappings[C]['ui-events'][E]
    : never
  : never;

type PageEvent<C> = C extends Category ? keyof EventMappings[C]['page-events'] : never;
type PageEventProperties<C extends Category, E extends PageEvent<C>> = EventMappings[C]['page-events'][E];

type UseBillingAnalyticsOptions<C extends Category, P extends PageEvent<C>> = {
  trackRoute?: { category: C; page: P; eventProperties?: PageEventProperties<C, P> };
};
export function useBillingAnalytics<C extends Category, P extends PageEvent<C>>(
  options?: UseBillingAnalyticsOptions<C, P>
) {
  const ranOnce = useRef<{ ran: boolean }>({ ran: false });

  useEffect(() => {
    if (!ranOnce.current.ran) {
      ranOnce.current.ran = true;

      if (options?.trackRoute) {
        const { trackRoute } = options;
        const { category, page, eventProperties } = trackRoute;
        analytics.page(category, page, eventProperties);
      }
    }
  }, [options]);

  return {
    track<C extends Category, E extends UIEvent<C>>(page: C, event: E, properties?: UIEventProperties<C, E>) {
      analytics.track(page, event, properties);
    },
  };
}
