import {
  CardExpirationDateElement as CardExpirationDateElementType,
  CardNumberElement as CardNumberElementType,
  CardVerificationCodeElement as CardVerificationCodeElementType,
} from '@basis-theory/basis-theory-react/types';
import { MutableRefObject, useEffect, useState } from 'react';
import { BasisTheoryCardBrandType } from 'src/components/basis-theory/brands-logos/types';
import { CardExpirationDateField } from 'src/components/basis-theory/fields/CardExpirationDateField';
import { CardNumberField } from 'src/components/basis-theory/fields/CardNumberField';
import { CardVerificationCodeField } from 'src/components/basis-theory/fields/CardVerificationCodeField';
import { ELEMENT_COMMON_STYLE } from 'src/components/basis-theory/fields/consts';
import Box from 'src/core/ds/box/Box';
import Flex from 'src/core/ds/flex/Flex';
import { PrivateDataContainer } from 'src/core/ds/input';

type Props = {
  cardNumberFieldId: string;
  cardExpirationDateFieldId: string;
  cardVerificationCodeFieldId: string;
  onCardBrandChange(cardBrand: BasisTheoryCardBrandType): void;
  onReady(): void;
  onError(hasError: boolean): void;
  attemptedToLink: boolean;
  refs: {
    cardNumberRef: MutableRefObject<CardNumberElementType | undefined>;
    cardExpirationRef: MutableRefObject<CardExpirationDateElementType | undefined>;
    cardVerificationRef: MutableRefObject<CardVerificationCodeElementType | undefined>;
  };
};

export const CardDetails = ({
  cardNumberFieldId,
  cardExpirationDateFieldId,
  cardVerificationCodeFieldId,
  onCardBrandChange,
  onReady,
  onError,
  attemptedToLink,
  refs,
}: Props) => {
  const [cardNumberReady, setCardNumberReady] = useState(false);
  const [cardExpirationDateReady, setCardExpirationDateReady] = useState(false);
  const [cardVerificationCodeReady, setCardVerificationCodeReady] = useState(false);

  const [cardNumberError, setCardNumberError] = useState(true);
  const [cardExpirationDateError, setCardExpirationDateError] = useState(true);
  const [cardVerificationCodeError, setCardVerificationCodeError] = useState(true);

  useEffect(() => {
    const isReady = [cardNumberReady, cardExpirationDateReady, cardVerificationCodeReady].every(
      (isFieldReady) => isFieldReady
    );

    if (isReady) {
      onReady();
    }
  }, [cardNumberReady, cardExpirationDateReady, cardVerificationCodeReady, onReady]);

  useEffect(() => {
    const hasError = [cardNumberError, cardExpirationDateError, cardVerificationCodeError].some(
      (fieldHasError) => fieldHasError
    );
    onError(hasError);
  }, [cardNumberError, cardExpirationDateError, cardVerificationCodeError, onError]);

  const handleCardNumberReady = () => {
    setCardNumberReady(true);
  };

  const handleCardExpirationDateReady = () => {
    setCardExpirationDateReady(true);
  };

  const handleCardVerificationCodeReady = () => {
    setCardVerificationCodeReady(true);
  };

  const handleCardNumberError = (hasError: boolean) => {
    setCardNumberError(hasError);
  };

  const handleCardExpirationDateError = (hasError: boolean) => {
    setCardExpirationDateError(hasError);
  };

  const handleCardVerificationCodeError = (hasError: boolean) => {
    setCardVerificationCodeError(hasError);
  };

  return (
    <PrivateDataContainer>
      <Box mb={16}>
        <CardNumberField
          id={cardNumberFieldId}
          onReady={handleCardNumberReady}
          onError={handleCardNumberError}
          elementStyle={ELEMENT_COMMON_STYLE}
          onCardBrandChange={onCardBrandChange}
          attemptedToLink={attemptedToLink}
          elementRef={refs.cardNumberRef}
        />
      </Box>
      <Flex width="full" justifyContent="space-between" mb={16}>
        <CardExpirationDateField
          id={cardExpirationDateFieldId}
          onReady={handleCardExpirationDateReady}
          onError={handleCardExpirationDateError}
          elementStyle={ELEMENT_COMMON_STYLE}
          attemptedToLink={attemptedToLink}
          elementRef={refs.cardExpirationRef}
        />
        <CardVerificationCodeField
          id={cardVerificationCodeFieldId}
          onReady={handleCardVerificationCodeReady}
          onError={handleCardVerificationCodeError}
          elementStyle={ELEMENT_COMMON_STYLE}
          attemptedToLink={attemptedToLink}
          elementRef={refs.cardVerificationRef}
        />
      </Flex>
    </PrivateDataContainer>
  );
};
