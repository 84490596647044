import { PayCardFlag, PayItemPaymentType } from 'src/pay/types';
import { BillFrequency, BillStatus, PaymentCreateFlowOrigin, PayTabs } from 'src/utils/consts';
import { Installment, VendorType } from 'src/utils/types';

export type FilterType = {
  start?: number;
  limit?: number;
  [key: string]: any;
};

export type PreferredSortByTabType = { [key in PayTabs]?: string };

export type PayListNormalizedType = {
  objects: PayListItemType[];
  totalCount: number;
};

export type MarkAsPaidParams = {
  orgId: number;
  id: string;
  accountingPlatformAccountId?: string | null;
  createOrigin?: PaymentCreateFlowOrigin;
  amount?: number | null;
};

export enum PayListItemEntityType {
  Payment = 'payment',
  Bill = 'bill',
  PaymentRequest = 'paymentRequest',
  ScannedInvoice = 'scannedInvoice',
  Installment = 'installment',
}

export type PayListItemType = {
  id: string;
  billId: number;
  requestId?: string;
  scannedInvoiceId?: number;
  suggestedVendorId?: number;
  isMissingBillDetails?: boolean;
  balance: number;
  internalBill: boolean;
  totalAmount?: number;
  amount?: number;
  status: BillStatus;
  vendorId?: number;
  vendor?: VendorType;
  createdAt?: Date;
  paidDate?: Date;
  vendorCompanyName?: string;
  payments?: PayItemPaymentType[];
  dueDate?: Date | string;
  invoiceNumber?: string;
  occurrences?: number;
  frequency?: BillFrequency;
  recurringBillId?: string;
  recurringBillIndex?: number;
  flags?: PayCardFlag[];
  externallyPaid?: number;
  type: PayListItemEntityType;
  billsCount: number;
  installment?: Installment;
  totalNumberOfInstallments?: number;
};
