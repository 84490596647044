import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { lineStyles, pointStyles } from './styles';

type Props = {
  active: boolean;
  last?: boolean;
};

export const Timeline = ({ active, last }: Props) => (
  <Flex direction="column" align="center" w={3} mr={5} pt={1}>
    <Box __css={pointStyles} data-active={active} />
    {!last && <Box __css={lineStyles} data-active={active} />}
  </Flex>
);
