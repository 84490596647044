import { AreaLoader } from 'src/components/common/AreaLoader';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import * as WizardElements from 'src/components/layout/WizardElements';
import Box from 'src/core/ds/box';
import { Button, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { analytics, Context, Event, Page } from 'src/pages/spend-management/SpendManagementAnalytics';
import { EnrollInProgressPage } from './EnrollInProgressPage';
import { useEnrollment } from './hooks/useEnrollment';

export function EnrollPage({ onEnrolled }: { onEnrolled: () => Promise<void> }) {
  const { onEnrollClick, loading, error, enrolling } = useEnrollment({ onEnrolled });
  const [historyPush] = useHistoryWithOrgId();

  if (error) return null;

  if (loading) return <AreaLoader />;

  if (enrolling) return <EnrollInProgressPage />;

  return (
    <WizardElements.WizardContainer>
      <WizardElements.WizardInner isCenterVert>
        <Box mb={4} textAlign="center">
          <video
            data-component="Video"
            autoPlay
            src="https://platform-static.meliopayments.com/assets/melio/illustrations/small-business.webm"
            loop
            width="144px"
          >
            <track kind="captions" />
          </video>
        </Box>

        <Box textAlign="center" maxW="48.8rem" mb={2} textStyle="h1Semi">
          <MIFormattedText label="spendManagement.pages.enrollment.title" />
        </Box>
        <Box textAlign="center" maxW="48.8rem" mb={12} textStyle="body2">
          <MIFormattedText label="spendManagement.pages.enrollment.subtitle" />
        </Box>
        <Box maxW="48.8rem" w="full" mb={6}>
          <Button
            label="spendManagement.pages.enrollment.buttonLabel"
            variant={ButtonVariants.primary}
            onClick={() => {
              analytics.track(Page.ORG_SETUP_DONE, Context.ENROLL_PROGRESS, Event.CLICK_ENROLL);
              onEnrollClick();
            }}
            isFullWidth
          />
        </Box>
        <Flex maxW="48.8rem" justifyContent="center" w="full">
          <Button
            label="spendManagement.pages.enrollment.gotoDashboard"
            variant={ButtonVariants.tertiaryNaked}
            onClick={() => {
              analytics.track(Page.ORG_SETUP_DONE, Context.ENROLL_PROGRESS, Event.CLICK_DASHBOARD);
              historyPush({ path: spendManagementLocations.index });
            }}
            isFullWidth
          />
        </Flex>
      </WizardElements.WizardInner>
    </WizardElements.WizardContainer>
  );
}
