import { ReactNode, useMemo } from 'react';
import { defaultOptionRender, OptionType } from 'src/components/common/DropDown/MIDropDown';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames } from 'src/core/ds/icon';
import { usePayablesConnectedAccountingPlatform } from 'src/hooks/usePayablesConnectedAccountingPlatform';
import { useGetFundingSourceDisplayName } from 'src/modules/funding-sources/hooks/useGetFundingSourceDisplayName';
import { AccountingPlatformAccountId } from 'src/modules/funding-sources/types';
import { accountCategoryToAccountCategoryOption } from 'src/pages/shared';
import { ModelViewField } from 'src/ui/form';
import { WizardNewSelectField } from 'src/ui/form/WizardNewSelectField';
import { AccountingSoftware } from 'src/utils/consts';
import { AccountingSoftwareAccountCategory, AccountType } from 'src/utils/types';

interface Props {
  fundingSource: AccountType;
  accountingPlatformAccounts: AccountingSoftwareAccountCategory[];
  connectedAccountingPlatformName: string | undefined;
  model: ModelViewField<AccountingPlatformAccountId>;
}

export const WizardAccountingPlatformAccountSelectField = ({
  accountingPlatformAccounts = [],
  fundingSource,
  connectedAccountingPlatformName,
  ...restProps
}: Props) => {
  const { getFundingSourceDisplayName } = useGetFundingSourceDisplayName();
  const { connectedAccountingPlatform } = usePayablesConnectedAccountingPlatform();
  const accountCategoriesOptions = useMemo(() => {
    const useShortName = connectedAccountingPlatform?.name === AccountingSoftware.XERO;
    const fundingSourceDisplayName = getFundingSourceDisplayName({
      fundingSource,
      useShortName,
    });
    const options = accountingPlatformAccounts.map(accountCategoryToAccountCategoryOption);

    if (options.some(({ label }) => label === fundingSourceDisplayName)) {
      return options;
    }

    return [{ value: 'new', label: `${fundingSourceDisplayName} (New)` }, ...options];
  }, [accountingPlatformAccounts, getFundingSourceDisplayName, fundingSource]);

  const renderOption = (option: OptionType, searchText?: string | null): ReactNode | null => {
    if (option.value === 'new') {
      return <AddOption searchText={searchText} connectedAccountingPlatformName={connectedAccountingPlatformName} />;
    }

    return defaultOptionRender(option, searchText);
  };

  return (
    <WizardNewSelectField
      label="onboarding.fundingSources.bank.accountingPlatformAccounts.accountLabel"
      placeholder="onboarding.fundingSources.bank.accountingPlatformAccounts.placeholder"
      labelValues={{ accountingPlatformName: <MIFormattedText label={connectedAccountingPlatformName} /> }}
      required
      renderOption={renderOption}
      options={accountCategoriesOptions}
      privateData
      {...restProps}
    />
  );
};

const AddOption = ({ searchText, connectedAccountingPlatformName }) => {
  if (searchText) {
    return null;
  }

  return (
    <Flex align="center">
      <Icon name={IconNames.plus} mr={2} ml={0.5} />
      <MIFormattedText
        label="onboarding.fundingSources.bank.accountingPlatformAccounts.customLabel"
        values={{ accountingPlatformName: <MIFormattedText label={connectedAccountingPlatformName} /> }}
      />
    </Flex>
  );
};
