import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useLocationState } from 'src/utils/hooks';

type LocationState = {
  userAlreadyConnected?: boolean;
  QBCompany?: string;
  createdOrganization?: boolean;
  error?: string;
};

const useQBORedirectQueryParams = () => {
  const [locationStateQuery, setLocationStateQuery] = useLocationState<LocationState | null>('queryParams', {});
  const [queryParams, setQueryParams] = useState<LocationState>({});
  const { QBCompany, error, createdOrganization, userAlreadyConnected } = queryParams;

  useEffect(() => {
    if (locationStateQuery && !isEmpty(locationStateQuery)) {
      setQueryParams(locationStateQuery);
      setLocationStateQuery(null);
    }
  }, [locationStateQuery, setLocationStateQuery]);

  return {
    QBCompany,
    error,
    createdOrganization,
    userAlreadyConnected,
  };
};

export { useQBORedirectQueryParams };
