import { fetchRequest, postRequest, putRequest } from 'src/services/api/api';

export const unilateralPaymentsApi = {
  createDeliveryMethod(params) {
    return postRequest('/unilateral-payments/create-delivery-method', params).then((res) => res.deliveryMethod);
  },
  copyFromOwnedVendor(params) {
    return postRequest('/unilateral-payments/copy-from-owned-vendor', params).then((res) => res.deliveryMethod);
  },
  updateDeliveryMethod({ id, token, deliveryMethod }) {
    return putRequest(`/unilateral-payments/delivery-methods/${id}`, {
      token,
      deliveryMethod,
    }).then((res) => res.deliveryMethod);
  },
  getRequestDataWithToken(params) {
    const url = '/unilateral-payments/fetch-request-data';

    return fetchRequest(url, params);
  },
};
