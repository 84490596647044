import React from 'react';
import { useDisclosure } from 'src/core/ds/hooks';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { AddMemoModal } from 'src/pages/regular-batch-payments/components/dialog/AddMemoModal';
import { ActionsCellButton } from 'src/pages/regular-batch-payments/table/components/ActionsCell/ActionCellButton';

type Props = {
  rowHovered: boolean;
  itemId: string;
  vendorId: number | undefined;
  payBillFlowUUID: string;
};

export const AddMemoAction = ({ rowHovered, itemId, vendorId, payBillFlowUUID }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ActionsCellButton
        rowHovered={rowHovered}
        icon={<Icon name={IconNames.noteAdd} size={IconSize.m} />}
        label="regularBatchPayments.cells.actions.addMemoToVendor"
        analyticsProperties={{
          itemId,
          vendorId,
          payBillFlowUUID,
        }}
        onClick={onOpen}
      />
      {isOpen && <AddMemoModal itemId={itemId} onClose={onClose} />}
    </>
  );
};
