import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Flex from 'src/core/ds/flex';
import { useBreak } from 'src/hoc';
import { HeaderActions } from 'src/pages/bill/components/BillDetailsHeader';
import { BillType, FinancePayment } from 'src/utils/types';
import { useInstallmentHeaderActions } from './hooks/useInstallmentHeaderActions';
import { InstallmentStatusDesktop } from './InstallmentStatusDesktop';
import { InstallmentStatusMobile } from './InstallmentStatusMobile';

export const InstallmentStatus: React.FC<{
  currentInstallmentNumber: number | undefined;
  totalInstallmentAmount: number;
  payment?: FinancePayment;
  bill?: BillType;
  backPath: string;
}> = ({ currentInstallmentNumber, totalInstallmentAmount, payment, bill, backPath }) => {
  const device = useBreak();
  const headerActions = useInstallmentHeaderActions({ payment, bill, currentInstallmentNumber });
  const isFullSingleView = device.isMobile || device.isPhablet;
  const providerDisclaimer = <MIFormattedText label="financing.scheduledInstallment.providerDisclaimer" />;
  const singleViewHeaderText = `Installment ${currentInstallmentNumber} of ${totalInstallmentAmount}`;
  const [pathname, search] = backPath.split('?');

  return (
    <>
      {isFullSingleView ? (
        <InstallmentStatusMobile
          pathname={pathname}
          search={search}
          text={singleViewHeaderText}
          subtitle={providerDisclaimer}
          headerActions={headerActions}
        />
      ) : (
        <Flex justifyContent="space-between">
          <InstallmentStatusDesktop
            currentInstallmentNumber={currentInstallmentNumber}
            totalInstallmentAmount={totalInstallmentAmount}
            providerDisclaimer={providerDisclaimer}
          />
          <HeaderActions headerActions={headerActions} isFullSingleView={isFullSingleView} />
        </Flex>
      )}
    </>
  );
};
