import { format, isSameDay } from 'date-fns';
import { ReactNode } from 'react';
import { Consts as InputConsts } from 'src/core/ds/input';

export const getCalendarClassName = (
  containerClassName: string | undefined,
  withBottomElement: boolean | undefined,
  inline: boolean | undefined
) =>
  containerClassName ||
  `${inline ? 'big-calender' : 'small-calendar'} ${withBottomElement ? 'with-bottom-element' : ''}`;

export const getDayClassName = (
  date: Date | null | undefined,
  dueDate: Date | null | undefined,
  calendarClassName: string
) => {
  const dateObj = getDateAsDateObject(date);
  const dueDateObj = getDateAsDateObject(dueDate);

  const isDueDateToday = !dueDateObj
    ? dateObj && isSameDay(dateObj, new Date())
    : dateObj && isSameDay(dateObj, dueDateObj);

  if (isDueDateToday && calendarClassName.includes('small-calendar')) {
    return 'highlightToday';
  } else if (isDueDateToday) {
    return 'highlightDueDate';
  }

  return '';
};

export const getFormattedDate = (date: Date | undefined | null, dateFormat = 'yyyy-MM-dd') => {
  const dateObj = getDateAsDateObject(date);

  return dateObj && format(dateObj, dateFormat);
};

export const getDateAsDateObject = (date: Date | null | undefined) => (date ? new Date(date) : undefined);

export const getContainerStyle = (
  inline: boolean | undefined,
  isMobile: boolean,
  customInput: ReactNode,
  size?: 'sm' | 'md'
) => ({
  position: 'relative',
  width: 'full',
  textAlign: inline ? 'center' : 'normal',
  '&.react-datepicker__close-icon': {
    top: size === InputConsts.Size.md ? '3.5rem' : '2.5rem',
    display: (customInput || isMobile) && 'none',
  },
});
