const parts = ['item', 'command', 'list', 'button', 'groupTitle', 'divider'];

function baseStyleList() {
  return {
    bg: 'white',
    boxShadow: '600',
    color: 'black',
    zIndex: 8,
    py: 2,
    borderRadius: 'lg',
    border: '1px',
    borderColor: 'grey.400',
    minW: '18.5rem',
  };
}

function baseStyleItem() {
  return {
    py: 3,
    px: 5,
    border: 'none',
    justifyContent: 'flex-start',
    borderRadius: 'none',
    bg: 'white',
    apply: 'textStyles.body4Semi',
    transition: 'background 50ms ease-in 0s',
    cursor: 'pointer',
    _focus: {
      bg: 'grey.300',
    },
    _active: {
      bg: 'grey.300',
    },
    _hover: {
      bg: 'grey.300',
    },
    _disabled: {
      cursor: 'not-allowed',
      color: 'grey.500',
    },
  };
}

function baseGroupTitleStyle() {
  return {
    py: 3,
    px: 5,
    border: 'none',
    justifyContent: 'flex-start',
    borderRadius: 'nonoe',
    bg: 'white',
    apply: 'textStyles.body4Semi',
    cursor: 'default',
    userSelect: 'none',
    my: 0,
    color: 'grey.600',
  };
}

const baseStyleDivider = {
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'grey.400',
  my: 2,
};

const baseStyle = () => ({
  list: baseStyleList(),
  item: baseStyleItem(),
  groupTitle: baseGroupTitleStyle(),
  command: {},
  divider: baseStyleDivider,
});

export default {
  parts,
  baseStyle,
};
