export const pointStyles = {
  w: '0.8rem',
  minH: '0.8rem',
  borderRadius: 'full',
  bg: 'grey.500',
  mb: 0.5,
  '&[data-active=true]': {
    w: '1.2rem',
    minH: '1.2rem',
    bg: 'primary.500',
  },
};

export const lineStyles = {
  w: '0.2rem',
  h: 'full',
  bg: 'grey.300',
  '&[data-active=true]': {
    bgGradient: 'linear(to-b, primary.500 0%, grey.300 80%)',
  },
};
