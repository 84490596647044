import { useSelector } from 'react-redux';
import { FinancingFundingSource } from 'src/components/common/SelectMethods/components/installments/FinancingFundingSource';
import { FinancingFundingSourceCard } from 'src/components/common/SelectMethods/components/installments/FinancingFundingSourceCard';
import { FinancingFundingSourcePaymentIneligible } from 'src/components/common/SelectMethods/components/installments/FinancingFundingSourcePaymentIneligible';
import { getStaticMonthlyInstallmentAmount } from 'src/components/common/SelectMethods/utils';
import { financingStore } from 'src/modules/financing/financing-store';
import { FinancingPreEligibilityDecision } from 'src/modules/financing/types';
import { usePayBillFinancingData } from 'src/pages/bill/pay/hooks/usePayBillFinancingData';
import { FinancingExperience } from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { FundingType } from 'src/utils/consts';

type Props = {
  totalAmount: string;
  isSelected: boolean;
  optionComponent: any;
  isInstallmentDisabled: boolean;
  preEligibilityDecision: FinancingPreEligibilityDecision;
  vendorId: number;
  onClick: () => void;
  isInternationalVendor?: boolean;
};

export const disabledLearnMoreLink =
  'https://melio.zendesk.com/hc/en-us/articles/6464064007964-Who-is-eligible-for-installments-with-Melio-';

const isOrganizationEligibleToApply = (preEligibilityDecision, isBusinessOwner) => {
  const preEligibleToApply = preEligibilityDecision === FinancingPreEligibilityDecision.Unknown;

  return preEligibleToApply && isBusinessOwner !== false;
};

export const InstallmentsPaymentMethod = ({ totalAmount, onClick, vendorId, isInstallmentDisabled }: Props) => {
  const { preEligibilityData } = usePayBillFinancingData();
  const isBusinessOwner = useSelector(financingStore.selectors.businessOwnerDecision.isBusinessOwner);

  const preEligibleToApply = isOrganizationEligibleToApply(preEligibilityData?.decision, isBusinessOwner);

  if (preEligibleToApply) {
    const installmentAmount = getStaticMonthlyInstallmentAmount(+totalAmount);

    return (
      <FinancingFundingSourceCard
        isSelected={false}
        paymentAmount={+totalAmount}
        installmentAmount={installmentAmount}
        onClick={onClick}
        financingExperience={FinancingExperience.FTX}
      />
    );
  }

  if (isInstallmentDisabled) {
    // static disabled in case of the user is not eligible, and FF is on
    return (
      <FinancingFundingSourcePaymentIneligible
        testId="radio-select-method-installments-static-disabled"
        tooltipLabel="financing.fundingSource.installmentsDisabledTooltip.disabledVisible"
        paymentAmount={+totalAmount}
        financingExperience={FinancingExperience.FTX}
        payBillFlowUUID={undefined}
        learnMoreLink={disabledLearnMoreLink}
        learnMoreText="financing.fundingSource.installmentsDisabledTooltip.disabledLearnMore"
        learnMoreColor="white"
      />
    );
  }

  return <FinancingFundingSource totalAmount={totalAmount} vendorId={vendorId} />;
};

InstallmentsPaymentMethod.methodType = FundingType.INSTALLMENTS;
InstallmentsPaymentMethod.showAddAnotherOption = false;
