import { AllUserOrganizationsApiResponseType } from 'src/modules/users/all-user-organizations-slice';
import { deleteRequest, fetchRequest, postRequest, putRequest } from 'src/services/api/api';
import { UserOrganization } from 'src/utils/types';

export const userManagementApi = {
  delete(params) {
    const url = `/orgs/${params.orgId}/users-management/${params.id}`;

    return deleteRequest(url, params);
  },
  update(params) {
    const url = `/orgs/${params.orgId}/users-management/${params.id}`;

    return putRequest(url, params).then((res) => res.user);
  },
  list(params) {
    const url = `/orgs/${params.orgId}/users-management`;

    return fetchRequest(url).then((res) => res.users);
  },
  changeOwner(params) {
    const url = `/orgs/${params.orgId}/users-management/${params.id}/change-owner`;

    return postRequest(url, params);
  },
  addUsers({ orgId, targetOrgId, items }: { orgId: number; targetOrgId: number; items: UserOrganization[] }) {
    const url = `/orgs/${orgId}/users-management/add-users`;

    return postRequest(url, { targetOrgId, items }).then((res) => res.userOrganizations);
  },
  hideFirmUserOrganizations({ userId, targetOrgId, orgId }: { userId: number; targetOrgId: number; orgId: number }) {
    const url = `/orgs/${orgId}/users-management/update-hidden-firm-user-organization`;

    return postRequest(url, { userId, targetOrgId, isHidden: true }).then((res) => res.userOrganizations);
  },
  unhideFirmUserOrganizations({ userId, targetOrgId, orgId }: { userId: number; targetOrgId: number; orgId: number }) {
    const url = `/orgs/${orgId}/users-management/update-hidden-firm-user-organization`;

    return postRequest(url, { userId, targetOrgId, isHidden: false }).then((res) => res.userOrganizations);
  },
  editUserOrganizations({
    orgId,
    targetUserId,
    items,
  }: {
    orgId: number;
    targetUserId: number;
    items: UserOrganization[];
  }) {
    const url = `/orgs/${orgId}/users-management/edit-user-organizations`;

    return putRequest(url, { targetUserId, items }).then((res) => res.userOrganizations);
  },
  allUserOrganizations({
    orgId,
    userIds,
  }: {
    orgId: number;
    userIds: number[];
  }): Promise<AllUserOrganizationsApiResponseType> {
    const url = `/orgs/${orgId}/users-management/all-firm-user-organizations`;

    return fetchRequest(url, { userIds });
  },
};
