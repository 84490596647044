import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MenuItemOption } from 'src/core/ds/menu';

type Props = {
  index: number;
  label: string;
  onClick: () => void;
  onFilterChange: (index: number) => void;
  testId: string;
};

export const CompaniesDashboardFilterItem = ({ index, label, testId, onClick, onFilterChange }: Props) => {
  const handleClick = () => {
    onFilterChange(index);
    onClick();
  };

  return (
    <MenuItemOption key={index} onClick={handleClick} data-testid={testId}>
      <MIFormattedText label={label} />
    </MenuItemOption>
  );
};
