import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { MIInlineLink } from 'src/components/common/MIInlineLink';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { UserExistErrorNotificationCard } from 'src/components/common/UserExistNotificationCard';
import { UserHasNoPasswordNotificationCard } from 'src/components/common/UserHasNoPasswordNotificationCard';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import * as WizardElements from 'src/components/layout/WizardElements';
import RegistrationTermsAndConditions from 'src/components/onboarding/RegistrationTermsAndConditions';
import { OrSeparator } from 'src/core/components/orSeparator';
import Box from 'src/core/ds/box';
import { PrivateDataContainer } from 'src/core/ds/input';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useApi } from 'src/hoc/useApi';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { authApi } from 'src/modules/auth/api';
import authenticationStore from 'src/modules/auth/auth-store';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { LoginWithGoogleButton } from 'src/pages/auth/components/login-with-google-button';
import { authLocations } from 'src/pages/auth/locations';
import { useLoginWithGoogle } from 'src/pages/auth/login-with-google-hook';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { initUserAction } from 'src/redux/user/actions';
import { analytics } from 'src/services/analytics';
import guestApi from 'src/services/api/guests';
import { useForm } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';
import { WizardTextInputField } from 'src/ui/form/WizardTextInputField';
import {
  CompanyInfoOnboardingOrigin,
  NO_PASSWORD_ERROR_CODES,
  NotificationCardTypes,
  RegistrationFlow,
  RegistrationOrigin,
  TextInputSize,
} from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import intuit from 'src/utils/intuit';
import { UserContextType } from 'src/utils/types';
import locations from '../../utils/locations';
import { spendManagementLocations } from '../spend-management/locations';
import { LoginWithIntuitButton } from './components/login-with-intuit-button';

type Props = {
  registrationFlow: RegistrationFlow;
};

export const getRegistrationFlowData = (registrationFlow, site) => {
  switch (registrationFlow) {
    case RegistrationFlow.VENDOR:
      return site.createOrigin.pay.payee;
    case RegistrationFlow.PUSH_TO_DEBIT:
      return RegistrationFlow.PUSH_TO_DEBIT;
    case RegistrationFlow.SPEND_MANAGEMENT:
      return RegistrationFlow.SPEND_MANAGEMENT;
    case RegistrationFlow.PUSH_TO_FAST_ACH:
      return RegistrationFlow.PUSH_TO_FAST_ACH;
    default:
      return site.createOrigin.pay.payor;
  }
};

type SignUpFooterProps = {
  loginWithQuickbooks?: () => void;
  loginWithGoogle?: () => void;
};

const AlternativeLoginButtons = ({ loginWithGoogle, loginWithQuickbooks }: SignUpFooterProps) => {
  const wizardButtonContainerStyles = {
    margin: 0,
    button: {
      marginBottom: 0,
    },
  };

  const google = loginWithGoogle ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithGoogleButton onClick={loginWithGoogle} />
    </WizardElements.WizardButtonContainer>
  ) : null;

  const intuit = loginWithQuickbooks ? (
    <WizardElements.WizardButtonContainer css={wizardButtonContainerStyles} fullWidthCTA={false}>
      <LoginWithIntuitButton onClick={loginWithQuickbooks} />
      <Box textStyle="body3" color="grey.700" py={2} textAlign="center">
        <MIFormattedText label="auth.intuitExplainer" />
      </Box>
    </WizardElements.WizardButtonContainer>
  ) : null;

  return (
    <>
      <Box mb={4}>{google}</Box>
      <Box mb={0}>{intuit}</Box>
    </>
  );
};

export function useRegisterPageLogic({ registrationFlow }: Props) {
  const queryParams = useQueryString();
  const { navigate } = usePreservedStateNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();
  const isSpendManagementRegistrationFlow = registrationFlow === RegistrationFlow.SPEND_MANAGEMENT;
  const isVendorRegistrationFlow = [
    RegistrationFlow.VENDOR,
    RegistrationFlow.PUSH_TO_DEBIT,
    RegistrationFlow.PUSH_TO_FAST_ACH,
  ].includes(registrationFlow);
  const eventPage = isVendorRegistrationFlow ? 'vendor-register' : 'register-with-email';
  const prefilledEmail: string =
    locationState?.email || (locationState?.preservedState?.email as string | undefined) || queryParams?.email || '';
  const site = useSiteContext();
  const isEmailPrefilled = !!prefilledEmail;
  const dispatch = useDispatch();
  const initUser = useCallback(
    async (user: UserContextType) =>
      new Promise((resolve, reject) => {
        dispatch(initUserAction(user, false, resolve, reject));
      }),
    [dispatch]
  );

  const { onApiCall: onSendVerificationCodeCall } = useApi({
    api: guestApi.sendEmailVerificationCode,
  });
  const { vendorRegistration, regularRegistration } = getStoreActions(authenticationStore)(dispatch);
  const { isLoading: isLoadingRegister, error, user } = useStructuredSelectors(
    authenticationStore.selectors.registration()
  );
  const combinedError =
    error || (queryParams.error ? { code: queryParams.error_code ?? 'LOGIN_WITH_GOOGLE_FAILED' } : null);

  const {
    onApiCall: onRequestPassword,
    loading: isLoadingRequestPassword,
    error: errorRequestPassword,
    resetState: resetRequestPasswordState,
  } = useApi({
    api: authApi.requestResetPassword,
  });
  const [isPasswordRequestSucceeded, setIsPasswordRequestSucceeded] = useState<boolean>(false);
  const isLoading = isLoadingRegister || isLoadingRequestPassword;
  useEffect(() => {
    if (queryParams.error) {
      analytics.track('register', 'login-with-google-error', {
        error: queryParams.error,
      });
    }
  }, [queryParams.error]);

  const model = useMemo(
    () => ({
      password: '',
      email: prefilledEmail || '',
    }),
    [prefilledEmail]
  );
  const vendorIdToConvertToOwned = isVendorRegistrationFlow
    ? locationState?.vendorIdToConvertToOwned || queryParams?.vendorIdToConvertToOwned
    : '';
  const onRegistration = useCallback(
    async (email, password) => {
      resetRequestPasswordState();
      setIsPasswordRequestSucceeded(false);

      const registerData = {
        email,
        password,
        registrationOrigin: isVendorRegistrationFlow ? RegistrationFlow.VENDOR : RegistrationOrigin.APP,
        registrationFlow: getRegistrationFlowData(registrationFlow, site),
        referringVendor: !isVendorRegistrationFlow ? locationState?.preservedState?.vendorId : null,
      };

      if (isVendorRegistrationFlow) {
        await vendorRegistration(registerData);
      } else {
        await regularRegistration(registerData);
      }

      analytics.triggerCampaignEvents();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vendorRegistration, regularRegistration, isVendorRegistrationFlow, registrationFlow, site, locationState]
  );

  const isFromPaymentRequestFlow = !!locationState?.preservedState?.paymentRequest;
  const origin = isFromPaymentRequestFlow
    ? CompanyInfoOnboardingOrigin.PAYMENT_REQUEST
    : CompanyInfoOnboardingOrigin.SETUP;

  const { openGoogleOAuth } = useLoginWithGoogle({
    redirectFailureTo: isSpendManagementRegistrationFlow
      ? authLocations.register.spendRegister
      : authLocations.register.index,
    redirectSuccessTo: {
      pathname: authLocations.register.authorizeGoogleSuccess,
      search: {
        origin,
        redirectUrl: isSpendManagementRegistrationFlow ? spendManagementLocations.index : locations.index.url(),
      },
    },
  });

  const [registerMV, { submit }] = useForm(model, {
    submit: ({ email, password }) => onRegistration(email, password),
  });
  const goRestoreGuestSession = useCallback(async () => {
    const email = registerMV.email.value;
    analytics.track(eventPage, 'register-guest-restore-session');

    if (email) {
      onSendVerificationCodeCall(email).then(() => {
        navigate(melioMeLocations.registerEmailVerify, false, { email });
      });
    }
  }, [registerMV.email.value, eventPage, navigate, onSendVerificationCodeCall]);

  useEffect(() => {
    if (error?.code === 'ATH18') {
      goRestoreGuestSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if (user) {
      initUser(user);

      if (user.isEmailVerified) {
        const isFromPaymentRequestFlow = !!locationState?.preservedState?.paymentRequest;
        const origin = isFromPaymentRequestFlow
          ? CompanyInfoOnboardingOrigin.PAYMENT_REQUEST
          : CompanyInfoOnboardingOrigin.SETUP;

        if (isSpendManagementRegistrationFlow) {
          navigate(spendManagementLocations.index, false, { origin });
        } else {
          navigate(site.onboardingEntryUrl, false, { origin });
        }
      } else {
        navigate(authLocations.register.codeVerification, false, { vendorIdToConvertToOwned });
      }
    }
  }, [
    user,
    locationState,
    initUser,
    navigate,
    site.onboardingEntryUrl,
    vendorIdToConvertToOwned,
    isVendorRegistrationFlow,
    isSpendManagementRegistrationFlow,
  ]);

  const handleRequestPassword = () => {
    analytics.track(eventPage, 'register-request-password');

    const email = registerMV.email.value;

    if (email) {
      onRequestPassword({ email, hasNoPasswordDefined: true }).then(() => {
        setIsPasswordRequestSucceeded(true);
      });
    }
  };

  const goLogin = () => {
    navigate(isSpendManagementRegistrationFlow ? authLocations.loginSpendManagement : authLocations.login, false, {
      registrationFlow: registrationFlow ?? locationState.registrationFlow,
    });
  };

  const goLinkQuickbooks = () => {
    analytics.track(eventPage, 'link-quickbooks');
    intuit.goConnectToIntuit({
      registrationOrigin: RegistrationOrigin.APP,
      intuitReturnUrl: authLocations.register.authorizeIntuitSuccess,
      intent: 'melioSyncSignIn',
    });
  };

  return {
    goLinkQuickbooks,
    goLogin,
    handleRequestPassword,
    registerMV,
    submit,
    openGoogleOAuth,
    isLoading,
    isPasswordRequestSucceeded,
    combinedError,
    isEmailPrefilled,
    isSpendManagementRegistrationFlow,
    errorRequestPassword,
  };
}
export const RegisterPage: React.VFC<Props> = (props) => {
  const {
    goLinkQuickbooks,
    goLogin,
    handleRequestPassword,
    registerMV,
    submit,
    openGoogleOAuth,
    isLoading,
    isPasswordRequestSucceeded,
    combinedError,
    isEmailPrefilled,
    isSpendManagementRegistrationFlow,
    errorRequestPassword,
  } = useRegisterPageLogic(props);
  const termsAndConditions = (
    <Box pt={6}>
      <RegistrationTermsAndConditions
        label="auth.regularRegister.termsAndConditions"
        css={{
          marginTop: 0,
        }}
      />
    </Box>
  );

  const orLine = (
    <Box my={4}>
      <OrSeparator color="grey.500" />
    </Box>
  );

  const emailPasswordInputs = (
    <>
      <WizardTextInputField
        label="auth.vendorRegister.workEmail"
        model={registerMV.email}
        required
        autoFocus={!isEmailPrefilled}
        size={TextInputSize.WIZARD}
        type="email"
        privateData
      />
      <PrivateDataContainer>
        <WizardPasswordValidationInputField
          testId="input-password"
          label="auth.vendorRegister.password"
          model={registerMV.password}
          isRequired
          autoFocus={isEmailPrefilled}
        />
      </PrivateDataContainer>
    </>
  );

  const alternativeLoginButtons = (
    <AlternativeLoginButtons
      loginWithQuickbooks={isSpendManagementRegistrationFlow ? undefined : goLinkQuickbooks}
      loginWithGoogle={() => {
        analytics.track('register', 'login-with-google');
        openGoogleOAuth();
      }}
    />
  );

  return (
    <RegisterLayoutPage
      title="auth.signUp.title"
      text="auth.signUp.subtitle"
      textValues={{
        link: (label) => <StyledMIInlineLink onClick={goLogin} label={label} testId={`link-${label}`} />,
      }}
      buttonLabel={(() => 'auth.signUp.buttonLabel')()}
      buttonAction={submit}
      isLoading={isLoading}
      footer={termsAndConditions}
    >
      <RegisterServerErrors
        errorRequestPassword={errorRequestPassword}
        isPasswordRequestSucceeded={isPasswordRequestSucceeded}
        handleRequestPassword={handleRequestPassword}
        combinedError={combinedError}
        goLogin={goLogin}
      />
      <>
        {alternativeLoginButtons}
        {orLine}
        {emailPasswordInputs}
      </>
    </RegisterLayoutPage>
  );
};

interface RegisterServerErrorsProps {
  isPasswordRequestSucceeded: boolean;
  combinedError?: { code: string };
  errorRequestPassword?: { code: string };
  handleRequestPassword: () => void;
  goLogin: () => void;
}
export function RegisterServerErrors({
  isPasswordRequestSucceeded,
  combinedError,
  errorRequestPassword,
  handleRequestPassword,
  goLogin,
}: RegisterServerErrorsProps) {
  const isRequestPasswordError = !!errorRequestPassword?.code;
  const isNoPasswordError =
    !isRequestPasswordError &&
    !isPasswordRequestSucceeded &&
    NO_PASSWORD_ERROR_CODES.includes(combinedError?.code || '');
  const isUserExistsError =
    !isNoPasswordError && !isRequestPasswordError && !isPasswordRequestSucceeded && !!combinedError?.code;

  return (
    <>
      {isNoPasswordError && (
        <UserHasNoPasswordNotificationCard errorCode={combinedError?.code} onRequestPassword={handleRequestPassword} />
      )}
      {isUserExistsError && (
        <UserExistErrorNotificationCard errorCode={combinedError?.code} onLogin={goLogin} preserveState />
      )}
      {isPasswordRequestSucceeded && (
        <StyledMINotificationCard
          type={NotificationCardTypes.SUCCESS}
          subtitle={{
            label: 'auth.requestPassword.successNotification',
          }}
        />
      )}
      {isRequestPasswordError && (
        <StyledMINotificationCard
          type={NotificationCardTypes.ERROR}
          subtitle={{
            label: `server.${errorRequestPassword?.code}`,
          }}
        />
      )}
    </>
  );
}
const StyledMIInlineLink = styled(MIInlineLink)`
  font-size: inherit;
  line-height: inherit;
  height: auto;
`;

const StyledMINotificationCard = styled(MINotificationCard)`
  margin-bottom: 4rem;
`;
