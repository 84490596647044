import { compose } from 'recompose';
import { withPreservedStateNavigator } from '../../../hoc';
import { CompanyManualAddressForm } from './components/CompanyManualAddressForm';
import { ManualAddressProps, withCompanyInfoUpdates } from './hoc/withCompanyInfoUpdates';

const CompanyManualAddressPageContainer = ({
  isLoading,
  validationErrors,
  onPrev,
  validManualAddress,
  manualAddressValidationErrors,
  selectedAddressId,
  onEditAddress,
  confirmMode,
  invalidAddress,
  onManualAddressSelected,
  onManualAddressChange,
  manualAddress,
  onAddressConfirmed,
  onAddressAddRequest,
}: ManualAddressProps) => (
  <CompanyManualAddressForm
    selectedAddressId={selectedAddressId}
    confirmMode={confirmMode}
    manualAddress={manualAddress}
    onManualAddressChange={onManualAddressChange}
    onAddressConfirmed={onAddressConfirmed}
    onAddressAddRequest={onAddressAddRequest}
    onManualAddressSelected={onManualAddressSelected}
    validManualAddress={validManualAddress}
    onEditAddress={onEditAddress}
    onPrev={onPrev}
    isLoading={isLoading}
    validationErrors={validationErrors}
    manualAddressValidationErrors={manualAddressValidationErrors}
    invalidAddress={invalidAddress}
  />
);

export default compose(withPreservedStateNavigator(), withCompanyInfoUpdates())(CompanyManualAddressPageContainer);
