import { isBefore, min } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import { batchPaymentsListName, name } from 'src/modules/regular-batch-payments/consts';
import { ALL_AT_ONCE_OPTION_ID } from 'src/pages/regular-batch-payments/BatchSettings/consts';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { getDefaultMemo, getDefaultMemoFromBills } from 'src/utils/bills';
import { DeliveryType, PaymentCreateFlowOrigin, PaymentStatus } from 'src/utils/consts';
import { getDeliveryOption, hasVendorCheckDeliveryMethod } from 'src/utils/delivery-methods';
import { isCreditAmexNetwork } from 'src/utils/funding-sources';
import { uuid } from 'src/utils/uuid';
import { shouldCollectAmexAddressForVendor } from 'src/utils/vendors';
import {
  BatchItemType,
  BatchListResultType,
  BatchPaymentType,
  FailedPaymentType,
  GroupedBatchListResultType,
} from './types';

const getCurrentBatchItems = (state): BatchItemType[] => {
  const currentBatchItemsIds: string[] = state.lists[batchPaymentsListName]?.order || [];
  const batchItems: BatchItemType[] = currentBatchItemsIds.map((batchItemId) => state.byId[batchItemId]);

  return batchItems;
};

const getBatchItemByBillIds = (state, billIds: string[]): BatchItemType | undefined => {
  const batchItems = getCurrentBatchItems(state);

  return batchItems.find((batchItem) => batchItem.payment.bills.find((bill) => billIds.includes(bill.id)));
};

function getScheduledDateFromSettings(settings, dueDate) {
  if (settings.dateOption === ALL_AT_ONCE_OPTION_ID || isBefore(new Date(dueDate), new Date(settings.scheduledDate))) {
    return settings.scheduledDate;
  }

  return dueDate;
}

const mapGroupedPayloadToBatchBill = (item: GroupedBatchListResultType, settings): BatchItemType => {
  const createOrigin = item.payment.bills[0].isPaymentRequest
    ? PaymentCreateFlowOrigin.REQUEST
    : PaymentCreateFlowOrigin.PAY;
  const isInternationalDelivery = item.payment.deliveryMethod
    ? isInternationalDeliveryMethod(item.payment.deliveryMethod.deliveryType)
    : false;

  const firstBill = item.payment.bills[0]!;

  const minDueDate = min(item.payment.bills.map((bill) => new Date(bill.dueDate)));

  return {
    id: item.id,
    fee: Object.values(item.fee || {}),
    // TODO: remove the usages, we now have multiple bills
    dueDate: minDueDate,
    isInvoiceAttachmentRequired: item.isInvoiceAttachmentRequired,
    internationalFee: isInternationalDelivery ? settings.fee?.international.value : undefined,
    minScheduledDate: item.minScheduledDate || settings.minScheduledDate,
    deliveryOptions: item.deliveryOptions,
    payment: {
      ...item.payment,
      // TODO: remove billId and bill and use only bills.
      billId: firstBill.id,
      bill: firstBill,
      vendor: { ...item.payment.vendor, mccCode: item.mccCode },
      scheduledDate: item.payment.scheduledDate || getScheduledDateFromSettings(settings, minDueDate),
      createOrigin,
      ...(item.payment.deliveryMethod?.deliveryType !== DeliveryType.RPPS && {
        note: getDefaultMemoFromBills(item.payment.bills),
      }),
    },
    payBillFlowUUID: uuid(),
  };
};

const mapPayloadToBatchBill = (item: BatchListResultType, settings): BatchItemType => {
  const deliveryMethod = item.defaultDeliveryMethod;
  const selectedDeliveryOption = getDeliveryOption(deliveryMethod?.deliveryType, item.deliveryOptions) as Record<
    string,
    any
  >;

  const scheduledDate =
    selectedDeliveryOption?.scheduledDate || getScheduledDateFromSettings(settings, item.bill.dueDate);

  const createOrigin = item.bill.isPaymentRequest ? PaymentCreateFlowOrigin.REQUEST : PaymentCreateFlowOrigin.PAY;

  return {
    id: item.bill.id,
    dueDate: item.bill.dueDate,
    isInvoiceAttachmentRequired: item.isInvoiceAttachmentRequired,
    fee: selectedDeliveryOption?.fee,
    internationalFee: isInternationalDeliveryMethod(deliveryMethod?.deliveryType)
      ? settings.fee?.international.value
      : undefined,
    minScheduledDate: selectedDeliveryOption?.minScheduledDate || settings.minScheduledDate,
    deliveryOptions: item.deliveryOptions,
    payment: {
      organizationId: item.bill.organizationId,
      amount: item.bill.balance,
      // TODO: remove billId and bill and use only bills.
      billId: item.bill.id,
      bill: item.bill,
      bills: [item.bill],
      vendorId: item.bill.vendorId!,
      vendor: {
        ...item.bill.vendor,
        deliveryMethods: [deliveryMethod],
        mccCode: item.bill.vendor.mccCode,
      },
      fundingSourceId: settings.fundingSourceId,
      fundingSource: {
        displayName: '',
        origin: null,
        plaidAccount: null,
      },
      deliveryMethod,
      deliveryMethodId: deliveryMethod?.id,
      deliveryPreference: '',
      scheduledDate,
      deliveryEta: selectedDeliveryOption?.deliveryDate,
      maxDeliveryEta: selectedDeliveryOption?.maxDeliveryDate,
      currency: item.bill.currency,
      createOrigin,
      status: PaymentStatus.BLOCKED,
      ...(deliveryMethod?.deliveryType !== DeliveryType.RPPS && {
        note: getDefaultMemo(item.bill.invoiceNumber, ''),
      }),
    },
    payBillFlowUUID: uuid(),
  };
};

const mapFailedPaymentsData = (item: BatchItemType, errorCode): FailedPaymentType => ({
  errorCode,
  amount: item.payment.amount,
  vendorName: item.payment.vendor.companyName,
  bills: item.payment.bills,
});

const updatePayment = (state, action) => {
  const { deliveryOptions } = action.payload;
  const {
    itemId,
    amount,
    fundingSourceId,
    deliveryMethodId,
    deliveryPreference,
    deliveryMethod,
  } = action.meta.identifier;
  const oldBillData = state.byId[itemId];
  const selectedDeliveryMethod = deliveryMethod || oldBillData.payment.deliveryMethod;
  const selectedDeliveryPreference = isNull(deliveryPreference)
    ? deliveryPreference
    : oldBillData.payment.deliveryPreference;
  const selectedDeliveryOption = getDeliveryOption(
    selectedDeliveryPreference || selectedDeliveryMethod?.deliveryType,
    deliveryOptions
  ) as Record<string, any>;

  state.byId[itemId] = {
    ...oldBillData,
    deliveryOptions,
    fee: selectedDeliveryOption.fee,
    minScheduledDate: selectedDeliveryOption.minScheduledDate,
    payment: {
      ...oldBillData.payment,
      amount,
      fundingSourceId,
      deliveryMethodId,
      deliveryMethod: selectedDeliveryMethod,
      deliveryPreference: selectedDeliveryPreference,
      scheduledDate: selectedDeliveryOption?.scheduledDate,
      deliveryEta: selectedDeliveryOption?.deliveryDate,
      maxDeliveryEta: selectedDeliveryOption?.maxDeliveryDate,
    },
  };
};

export const isValidInternationalPayment = (
  payment: BatchPaymentType,
  isInvoiceAttachmentRequired: boolean
): boolean => {
  if (payment.deliveryMethod?.deliveryType === DeliveryType.INTERNATIONAL) {
    const checkFiles = isInvoiceAttachmentRequired ? payment.bills!.every((bill) => !isEmpty(bill.files)) : true;

    return Boolean(payment.purpose && checkFiles);
  }

  return true;
};

const getReadyBatchItems = (state, isAmexAddressFeatureEnabled: boolean) =>
  (state[name].lists[batchPaymentsListName]?.order || [])
    .filter((id: string) => state[name].byId[id]?.payment.deliveryMethodId)
    .filter((id: string) =>
      isValidInternationalPayment(state[name].byId[id].payment, state[name].byId[id].isInvoiceAttachmentRequired)
    )
    .filter((id: string) => {
      const fundingSource = state.fundingSources?.byId[state[name].byId[id]?.payment.fundingSourceId];
      const isAmexCreditCard = isCreditAmexNetwork(fundingSource);
      const vendor = state.vendors.byId[state[name].byId[id]?.payment.vendor?.id];
      const isAmexIndustryReady = isAmexCreditCard ? vendor?.mccCode : true;

      const isAmexAddressReady =
        isAmexCreditCard && isAmexAddressFeatureEnabled
          ? hasVendorCheckDeliveryMethod(vendor.deliveryMethods) || !shouldCollectAmexAddressForVendor(vendor)
          : true;

      return isAmexIndustryReady && isAmexAddressReady;
    });

export {
  getBatchItemByBillIds,
  getCurrentBatchItems,
  getReadyBatchItems,
  mapFailedPaymentsData,
  mapGroupedPayloadToBatchBill,
  mapPayloadToBatchBill,
  updatePayment,
};
