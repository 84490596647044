import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { Loader } from 'src/components/common/Loader';
import { useApi } from 'src/hoc/useApi';
import { deliveryMethodsApi } from 'src/modules/delivery-methods/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { globalLocations } from 'src/pages/locations';
import { msnPortalLocations } from 'src/pages/msn-portal';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getDeliveryMethods, getOrgId, getOwnedVendorId } from 'src/redux/user/selectors';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { getIsDeliveryMethodVerifiedByVerificationMethod } from 'src/utils/delivery-methods';

export const DeliveryMethodStatusCheckRouter = () => {
  const [deliveryMethod] = useSelector(getDeliveryMethods);
  const isDeliveryMethodVerified = getIsDeliveryMethodVerifiedByVerificationMethod(deliveryMethod);
  const orgId = useSelector(getOrgId);
  const vendorId = useSelector(getOwnedVendorId);
  const { navigate } = useNavigator();

  const { onApiCall: getDMStatusByMicroDeposit } = useApi({
    api: deliveryMethodsApi.getVerificationStatus,
  });

  const navigateToSelectDeliveryMethod = () => {
    if (vendorId) {
      navigate(
        generatePath(globalLocations.receivingMethod.ach.select, {
          orgId,
          vendorId,
          deliveryMethodId: String(deliveryMethod.id),
        }),
        true,
        {
          isPrevDisabled: true,
          isExistDisable: true,
          progressBarProps: {
            relativeStep: 1 / 2,
          },
          preservedState: {
            origin: DeliveryMethodOrigin.MSN_CLAIM_ACCOUNT,
          },
          redirectUrl: msnPortalLocations.base,
        }
      );
    } else {
      navigate(onboardingLocations.msnRegistration.claimAccount.generalError);
    }
  };

  useEffect(() => {
    const decideAndNavigateToCorrectPage = async () => {
      if (isDeliveryMethodVerified) {
        navigate(msnPortalLocations.base);
      } else {
        try {
          const { status } = await getDMStatusByMicroDeposit({ orgId, vendorId, deliveryMethodId: deliveryMethod.id });

          if (status === 'PENDING') {
            navigate(generatePath(onboardingLocations.msnRegistration.deliveryMethodStatus, { orgId }));
          } else {
            navigate(msnPortalLocations.base);
          }
        } catch {
          navigateToSelectDeliveryMethod();
        }
      }
    };

    decideAndNavigateToCorrectPage();
  }, []);

  return <Loader />;
};
