import * as React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { MaskField } from 'src/core/ds/form/fields';
import { InlineSwitchField } from 'src/ui/form/InlineSwitchField';
import { ModelViewField } from 'src/ui/form/use-form';

type Props = {
  id?: string;
  requireApprovalModel: ModelViewField<boolean>;
  approvalAmountThresholdModel: ModelViewField<string>;
  disabled?: boolean;
  amountThresholdPlaceholder?: number;
};

export const AdminApproval = ({
  id,
  requireApprovalModel,
  approvalAmountThresholdModel,
  disabled,
  amountThresholdPlaceholder,
}: Props) => {
  const idSuffix = id ? `_${id}` : '';

  return (
    <Box color="grey.700">
      <Row textStyle="body4Semi">
        <MIFormattedText label="teamManagement.adminApproval.requireAdminApproval" />
        <InlineSwitchField
          id={`require-approval${idSuffix}`}
          model={requireApprovalModel}
          hidePositiveNegativeLabels
          disabled={disabled}
        />
      </Row>
      {requireApprovalModel.value && (
        <Row mt={2} textStyle="body4">
          <MIFormattedText label="teamManagement.adminApproval.forAnyPaymentThatExceeds" />
          <Box width={16} __css={{ input: { textAlign: 'right' } }}>
            <MaskField
              id={`amount-threshold${idSuffix}`}
              testId={`input-amount-threshold${idSuffix}`}
              label=""
              size="sm"
              prefix="currency.symbol"
              model={approvalAmountThresholdModel}
              placeholder="form.input.currency.placeholder"
              placeholderValues={{ amount: amountThresholdPlaceholder || 0 }}
              mb={0}
              isRequired
            />
          </Box>
        </Row>
      )}
    </Box>
  );
};

const Row = (props: React.ComponentProps<typeof Flex>) => (
  <Flex alignItems="center" justifyContent="space-between" {...props} />
);
