import React from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'src/helpers/react/useModal';
import { getOrgId } from 'src/redux/user/selectors';
import { InvoiceGenerationCard } from './InvoiceGenerationCard';
import { OptInModal } from './OptInModal';
import { OptOutModal } from './OptOutModal';

type Props = {
  shouldGenerateInvoiceFile: boolean;
  accountingSoftwareType: string;
};

export const InvoiceGenerationContainer = ({ shouldGenerateInvoiceFile, accountingSoftwareType }: Props) => {
  const [optOutModal, openOptOutModal] = useModal(OptOutModal, { id: 'optOutModal' });
  const [optInModal, openOptInModal] = useModal(OptInModal, { id: 'optInModal' });
  const orgId = useSelector(getOrgId);

  const handleInvoiceGenerationSettingChanged = () => {
    if (shouldGenerateInvoiceFile) {
      openOptOutModal({ accountingSoftwareType, orgId });
    } else {
      openOptInModal({ accountingSoftwareType, orgId });
    }
  };

  return (
    <>
      {optInModal}
      {optOutModal}
      <InvoiceGenerationCard
        shouldGenerateInvoiceFile={shouldGenerateInvoiceFile}
        onChange={handleInvoiceGenerationSettingChanged}
      />
    </>
  );
};
