import { Avatar as ChakraAvatar, AvatarProps } from '@chakra-ui/react';
import { Icon, IconNames, IconSize } from '../icon';

type Props = AvatarProps & {
  textColor?: string | undefined;
  bg?: string | undefined;
  link?: string | undefined;
  defaultIconSize?: IconSize;
};

export const Avatar = ({
  link,
  bg = 'grey.600',
  textColor = 'black',
  children,
  defaultIconSize = IconSize.m,
  ...rest
}: Props) => (
  <ChakraAvatar
    {...rest}
    src={link}
    icon={<Icon size={defaultIconSize} name={IconNames.user} color="white" />}
    bg={bg}
    color={textColor}
    data-testid="initials-badge"
  >
    {children}
  </ChakraAvatar>
);
