import { ApiSliceActionName, generateActionName } from 'src/helpers/redux/actionNames';
import {
  assignPaymentRequestToOrgSlice,
  getOrgAssignedToPaymentRequestSlice,
} from 'src/modules/organizations/multi-orgs';
import {
  createNotOwnedOrganizationSlice,
  getUBOInfoSlice,
  requestSwitchAccountingFirmSlice,
  switchAccountingFirmSlice,
  unlinkQuickbookSlice,
  updateNewCompanySlice,
  updateOrganizationPreferenceSlice,
  updateOrganizationServiceFeesSlice,
} from 'src/modules/organizations/organizations-slice';
import { name } from './constants';

export const ORGANIZATIONS_FETCH_SUCCESS = generateActionName(name, 'FETCH', ApiSliceActionName.SUCCESS);
export const ORGANIZATIONS_UPDATE_SUCCESS = generateActionName(name, 'UPDATE', ApiSliceActionName.SUCCESS);
export const ORGANIZATIONS_CREATE_SUCCESS = generateActionName(name, 'CREATE', ApiSliceActionName.SUCCESS);
export const ORGANIZATIONS_ASSIGN_PAYMENT_REQUEST_SUCCESS = assignPaymentRequestToOrgSlice.actions.success;
export const ORGANIZATIONS_GET_ORG_ASSIGNED_TO_PAYMENT_REQUEST_SUCCESS =
  getOrgAssignedToPaymentRequestSlice.actions.success;
export const ORGANIZATIONS_CREATE_NOT_OWNED_SUCCESS = createNotOwnedOrganizationSlice.actions.success;
export const ORGANIZATIONS_GET_UBO_INFO_SUCCESS = getUBOInfoSlice.actions.success;
export const ORGANIZATIONS_REQUEST_SWITCH_ACCOUNTING_FIRM_SUCCESS = requestSwitchAccountingFirmSlice.actions.success;
export const ORGANIZATIONS_SWITCH_ACCOUNTING_FIRM_SUCCESS = switchAccountingFirmSlice.actions.success;
export const ORGANIZATIONS_UNLINK_QUICKBOOKS_SUCCESS = unlinkQuickbookSlice.actions.success;
export const ORGANIZATIONS_UPDATE_NEW_COMPANY_UPDATE = updateNewCompanySlice.actions.update;
export const ORGANIZATIONS_UPDATE_PREFERENCE_SUCCESS = updateOrganizationPreferenceSlice.actions.success;
export const ORGANIZATIONS_UPDATE_SERVICE_FEES_SUCCESS = updateOrganizationServiceFeesSlice.actions.success;
