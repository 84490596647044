import mergeWith from 'lodash/mergeWith';
import unionWith from 'lodash/unionWith';
import { createApiCallSlice, ON_FAILURE, ON_REQUEST, ON_SUCCESS } from 'src/helpers/redux/createApiCallSlice';
import { createDeleteSlice } from 'src/helpers/redux/restDeleteSlice';
import { selectList, selectListIsLoading } from 'src/helpers/redux/restListSlice';
import { UserOrganization } from 'src/utils/types';
import { userManagementApi } from './api';

type HideFirmClientsResponse = UserOrganization[];
type HideFirmClientsState = {
  byId: Record<number, { userOrganizations: UserOrganization[] }>;
  hideFirmClient: {
    loading: boolean;
    error: undefined | string;
  };
};
type HideFirmClientsProps = {
  userId: number;
  targetOrgId: number;
  orgId: number;
};

const managementHashFunc = (payload) => `user-management:${payload.orgId}`;

export function mergeUserOrganizationsCustomizer(objValue: [], srcValue: UserOrganization[], key: string) {
  if (key === 'userOrganizations') {
    return unionWith(srcValue, objValue, (a, b) => a.organizationId === b.organizationId && a.userId === b.userId);
  }

  return undefined;
}

function userManagementListReducer(hashFunc) {
  return {
    [ON_REQUEST](state, action) {
      const key = hashFunc(action.payload);
      state.lists[key] = { ...state.lists[key], loading: true };
    },
    [ON_SUCCESS](state, action) {
      const key = hashFunc(action.meta.identifier);
      state.lists[key] = {
        loading: false,
        order: action.payload.map((e) => e.id),
      };
      (action.payload || []).forEach((listItem) => {
        state.byId[listItem.id] = mergeWith(state.byId[listItem.id] || {}, listItem, mergeUserOrganizationsCustomizer);
      });
    },
    [ON_FAILURE](state, action) {
      const key = hashFunc(action.meta.identifier);
      state.lists[key] = { loading: false, error: action.error };
    },
  };
}

// TODO: convert to use createListSlice
const userManagementListSlice = createApiCallSlice<any, any>({
  api: userManagementApi.list,
  initialState: {
    lists: {},
  },
  name: '[USERS] USER_MANAGEMENT_LIST',
  reducers: userManagementListReducer(managementHashFunc),
  select: selectList(managementHashFunc),
  selectors: {
    org: (orgId) => ({
      loading: selectListIsLoading('users', managementHashFunc({ orgId })),
    }),
  },
});

const userManagementUpdateSlice = createApiCallSlice({
  api: userManagementApi.update,
  name: '[USERS] USER_MANAGEMENT_UPDATE',
  initialState: {
    byId: {},
    update: {},
  },
  reducers: {
    [ON_REQUEST](state, action) {
      state.update[action.payload.id] = { loading: true };
    },
    [ON_SUCCESS](state, action) {
      delete state.update[action.payload.id];
      state.byId[action.payload.id] = mergeWith(
        state.byId[action.payload.id] || {},
        action.payload,
        mergeUserOrganizationsCustomizer
      );
    },
    [ON_FAILURE](state, action) {
      state.update[action.payload.id] = { loading: false, error: action.error };
    },
  },
});

const userManagementHideFirmClientSlice = createApiCallSlice<
  HideFirmClientsProps,
  HideFirmClientsState,
  HideFirmClientsResponse
>({
  name: '[USERS] USER_MANAGEMENT_HIDE_USER_ORG',
  api: userManagementApi.hideFirmUserOrganizations,
  initialState: {
    byId: {},
    hideFirmClient: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.hideFirmClient.loading = true;
      state.hideFirmClient.error = undefined;
    },
    [ON_SUCCESS]: (state, { meta, payload }) => {
      state.hideFirmClient.loading = false;
      state.hideFirmClient.error = undefined;
      const currentUserId = meta.identifier.userId;
      const modifiedUserOrganization = payload.find(({ userId }) => userId === currentUserId);

      if (modifiedUserOrganization) {
        state.byId[currentUserId] = mergeWith(
          state.byId[currentUserId] || {},
          { userOrganizations: [modifiedUserOrganization] },
          mergeUserOrganizationsCustomizer
        );
      }
    },
    [ON_FAILURE]: (state, action) => {
      state.hideFirmClient.loading = false;
      state.hideFirmClient.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state.users.hideFirmClient.loading,
  },
});

const userManagementUnhideFirmClientSlice = createApiCallSlice<
  HideFirmClientsProps,
  HideFirmClientsState,
  HideFirmClientsResponse
>({
  name: '[USERS] USER_MANAGEMENT_UNHIDE_USER_ORG',
  api: userManagementApi.unhideFirmUserOrganizations,
  initialState: {
    byId: {},
    hideFirmClient: {
      loading: false,
      error: undefined,
    },
  },
  reducers: {
    [ON_REQUEST]: (state) => {
      state.hideFirmClient.loading = true;
      state.hideFirmClient.error = undefined;
    },
    [ON_SUCCESS]: (state, { meta, payload }) => {
      state.hideFirmClient.loading = false;
      state.hideFirmClient.error = undefined;
      const currentUserId = meta.identifier.userId;
      const modifiedUserOrganization = payload.find(({ userId }) => userId === currentUserId);

      if (modifiedUserOrganization) {
        state.byId[currentUserId] = mergeWith(
          state.byId[currentUserId] || {},
          { userOrganizations: [modifiedUserOrganization] },
          mergeUserOrganizationsCustomizer
        );
      }
    },
    [ON_FAILURE]: (state, action) => {
      state.hideFirmClient.loading = false;
      state.hideFirmClient.error = action.error;
    },
  },
  selectors: {
    loading: (state) => state.users.hideFirmClient.loading,
  },
});

const userManagementDeleteSlice = createDeleteSlice({
  api: userManagementApi.delete,
  storeName: 'users',
});

const userManagementChangeOwnerSlice = createApiCallSlice({
  api: userManagementApi.changeOwner,
  initialState: {
    byId: {},
    changeOwner: {},
  },
  name: '[USERS] USER_MANAGEMENT_CHANGE_OWNER',
  reducers: {
    [ON_REQUEST](state, action) {
      const { orgId } = action.payload;
      state.changeOwner[orgId] = { isChanging: true };
    },
    [ON_SUCCESS](state, { meta, payload }) {
      const { orgId } = meta.identifier;
      state.changeOwner[orgId] = { isChanging: false };
      const { newOwnerUser, previousOwnerUser } = payload;

      if (newOwnerUser && previousOwnerUser) {
        state.byId[newOwnerUser.id] = mergeWith(
          state.byId[newOwnerUser.id] || {},
          newOwnerUser,
          mergeUserOrganizationsCustomizer
        );
        state.byId[previousOwnerUser.id] = mergeWith(
          state.byId[previousOwnerUser.id] || {},
          previousOwnerUser,
          mergeUserOrganizationsCustomizer
        );
      }
    },
    [ON_FAILURE](state, action) {
      const { orgId } = action.meta.identifier;
      state.changeOwner[orgId] = { isChanging: false, error: action.error };
    },
  },
});

export {
  userManagementChangeOwnerSlice,
  userManagementDeleteSlice,
  userManagementHideFirmClientSlice,
  userManagementListSlice,
  userManagementUnhideFirmClientSlice,
  userManagementUpdateSlice,
};
