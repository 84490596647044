import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { getStoreActions } from 'src/helpers/redux/createRestfulSlice';
import usersStore from 'src/modules/users/users-store';
import { devices } from 'src/theme/appDevices';
import { useForm } from 'src/ui/form';
import { FormContainer } from 'src/ui/form/FormElements';
import { InlineRadioField } from 'src/ui/form/InlineRadioField';
import { Role } from 'src/utils/consts';
import { getRoleOptions } from 'src/utils/permissions';

export function ChangeOwnerModal({ dismiss, userId, orgId, orgName, isChanging }) {
  const user = useSelector(usersStore.selectors.byId(userId));
  const changeOwnerRoles = getRoleOptions().filter((roles) => roles.id !== Role.CONTRIBUTOR);
  const usersActions = getStoreActions(usersStore)(useDispatch());
  const model = useMemo(() => ({ role: Role.ADMIN }), []);
  const [userRoleMV, { submit }] = useForm(model, {
    submit: ({ role }) => usersActions.userManagementChangeOwnerSlice({ orgId, id: userId, role }),
    onClear: dismiss,
  });
  const userFullName = `${user.firstName} ${user.lastName}`;

  return (
    <ModalMessage
      onCloseClick={dismiss}
      titleComponent={
        <TitleContainer>
          <MIFormattedText label="user.makeOwnerDialog.title" values={{ userFullName }} />
        </TitleContainer>
      }
      contentComponent={
        <>
          <ChooseRoleSection>
            <MIFormattedText
              label="user.makeOwnerDialog.chooseRoleSection"
              values={{ companyName: orgName, userFullName }}
            />
          </ChooseRoleSection>
          <ChooseRoleTitle>
            <MIFormattedText label="user.makeOwnerDialog.chooseRoleTitle" />
          </ChooseRoleTitle>
          <FormContainer onSubmit={submit}>
            <InlineRadioField model={userRoleMV.role} options={changeOwnerRoles} group="user-role" />
          </FormContainer>
        </>
      }
      buttonComponent={
        <ButtonsContainer>
          <Button
            variant={ButtonVariants.tertiaryNaked}
            size={ButtonSizes.lg}
            label="user.makeOwnerDialog.cancel"
            onClick={dismiss}
          />
          <Button label="user.makeOwnerDialog.submit" size={ButtonSizes.lg} onClick={submit} isLoading={isChanging} />
        </ButtonsContainer>
      }
    />
  );
}

const TitleContainer = styled(ModalTitle)`
  margin-bottom: -1rem;
`;

const ChooseRoleSection = styled.div`
  margin-bottom: 4rem;
  letter-spacing: 0.027rem;
  ${(props) => props.theme.text.fontType.regular};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -1rem;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const ChooseRoleTitle = styled.div`
  margin-bottom: 2rem;
`;
