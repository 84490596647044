import React, { useMemo } from 'react';
import { CellProps } from 'react-table';
import Box from 'src/core/ds/box';

export const ValidToCell: React.FC<CellProps<any>> = ({ cell: { value } }) =>
  useMemo(
    () => (
      <Box textStyle="body3">
        {value.validDate && (
          <Box isTruncated role="cell">
            {value.validDate}
          </Box>
        )}
      </Box>
    ),
    [value]
  );
