import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { getOrgId } from 'src/redux/user/selectors';

export const useRequestDashboardPath = () => {
  const orgId = useSelector(getOrgId);

  return [generatePath(getPaidLocations.dashboard, { orgId })];
};
