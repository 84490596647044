import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Dispatch } from 'redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { financingStore } from 'src/modules/financing/financing-store';
import { paymentsApi } from 'src/modules/payments/api';
import { deliveryApi } from 'src/modules/regular-batch-payments/api';
import { AccountRecord } from 'src/pages/settings/records';
import { isInternationalDeliveryMethod } from 'src/pages/vendor/international-delivery-method/utils';
import { resetErrorAction, selectPaymentDatesAction, setFeeAction } from 'src/redux/payBillWizard/actions';
import {
  getBill,
  getErrorCode,
  getFee,
  getIsLoading,
  getIsRecurring,
  getPayment,
  getRecurringBill,
  getSelectedFundingSource,
} from 'src/redux/payBillWizard/selectors';
import { PaymentFeeInfo } from 'src/redux/payBillWizard/types';
import { GlobalState } from 'src/redux/types';
import { getBillingDetails, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { getBillPaymentIndex } from 'src/utils/bills';
import { CreditCardFeePayment, FailedPaymentType, PaymentStatus } from 'src/utils/consts';
import { hasFailedInstallment } from 'src/utils/financing';
import { getLatestPayment, isReturnedCheck, isUndepositedCheck } from 'src/utils/payments';
import { AccountType, BillingSettingsType, BillType, PaymentType, RecurringBillType } from 'src/utils/types';
import { PayBillConfirmPage } from './components/PayBillConfirmPage';
import { PayBillProps, withPayBillData } from './hoc/withPayBillData';

type MapStateToProps = {
  recurringBill?: RecurringBillType;
  bill: BillType;
  payment: PaymentType;
  fee?: PaymentFeeInfo | null;
  isRecurring: boolean;
  isLoading: boolean;
  selectedFundingSource?: AccountType | null;
  orgId: number;
  errorCode?: string | null;
  billingSetting?: BillingSettingsType;
  isFinancingPayment: boolean;
};

type MapDispatchToProps = {
  selectPaymentDates: (scheduledDate: Date, deliveryEta: Date, maxDeliveryEta: Date) => void;
  resetError: () => void;
  setFee: (PaymentFeeInfo: PaymentFeeInfo) => void;
};

type Props = {
  headerLabel: string;
} & PayBillProps &
  MapStateToProps &
  MapDispatchToProps;

type State = {
  isProcessingDatesLoading: boolean;
  isFeesLoading: boolean;
};

const eventPage = 'pay-bill-confirm';

class PlainPayBillConfirmPageContainer extends PureComponent<Props, State> {
  readonly state: State = {
    isProcessingDatesLoading: false,
    isFeesLoading: true,
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const { isRecurring, payment, isFinancingPayment } = this.props;
    const isReturnedCheckPayment = isReturnedCheck(payment);
    const isRecurringPayment = isRecurring && payment.fundingSourceId && payment.deliveryMethodId;
    const isFinancedPayment = isFinancingPayment || payment.isFinanced;
    const isRegularFailedToDeliverFlow =
      payment.status === PaymentStatus.FAILED &&
      payment?.metadata?.failedType === FailedPaymentType.FAILED_TO_DELIVER &&
      !isReturnedCheckPayment &&
      !isFinancedPayment;

    setTimeout(() => {
      window.scrollBy(0, 1);
    }, 10);

    const newState = { isFeesLoading: true, isProcessingDatesLoading: false };

    if (isRegularFailedToDeliverFlow) {
      this.setClosestDeliveryDates(newState);
    } else if (isRecurringPayment) {
      this.setLatestProcessingDates(newState);
    }

    if (!isFinancedPayment) {
      this.setPaymentFees(payment.deliveryPreference);
    } else {
      newState.isFeesLoading = false;
    }

    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState(newState);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setPaymentFees(deliveryPreference?: string) {
    const { VENDOR, PAYOR } = CreditCardFeePayment;
    const { orgId, payment, setFee, bill, isFinancingPayment } = this.props;
    const isVendorAbsorbedFee = bill?.paymentRequest?.feesPaidBy === CreditCardFeePayment.VENDOR;
    this.setState({ isFeesLoading: true });
    paymentsApi
      .getPaymentFee({
        orgId,
        fundingSourceId: payment.fundingSourceId,
        amount: payment.amount,
        scheduledDate: payment.scheduledDate,
        deliveryPreference,
        deliveryMethodId: payment.deliveryMethodId,
        cardFeePaidBy: isVendorAbsorbedFee ? VENDOR : PAYOR,
        allowDoubleFee: true,
        paymentId: payment.id,
        isFinancingPayment,
      })
      .then((res) => {
        setFee(res.fee);

        if (this._isMounted) {
          this.setState({ isFeesLoading: false });
        }
      });
  }

  setClosestDeliveryDates(newState: State) {
    const { orgId, payment } = this.props;
    newState.isProcessingDatesLoading = true;
    deliveryApi
      .getClosestDeliveryDates(orgId, payment.deliveryMethodId, payment.fundingSourceId, payment.amount)
      .then(({ deliveryDate, maxDeliveryDate }) => {
        this.setPaymentDates(payment.scheduledDate, deliveryDate, maxDeliveryDate);
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isProcessingDatesLoading: false });
        }
      });
  }

  setLatestProcessingDates(newState: State) {
    const { orgId, bill, payment } = this.props;
    newState.isProcessingDatesLoading = true;
    deliveryApi
      .getLatestProcessingDate(
        orgId,
        new Date(bill.dueDate),
        payment.deliveryMethodId,
        payment.fundingSourceId,
        payment.amount,
        payment.id,
        payment.payBillFlowUUID
      )
      .then(({ suggestedScheduledDate, deliveryDate, maxDeliveryDate }) => {
        this.setPaymentDates(suggestedScheduledDate, deliveryDate, maxDeliveryDate);
      })
      .catch(() => {
        if (this._isMounted) {
          this.setState({ isProcessingDatesLoading: false });
        }
      });
  }

  setPaymentDates(scheduledDate, deliveryDate, maxDeliveryDate) {
    const { selectPaymentDates } = this.props;
    selectPaymentDates(new Date(scheduledDate), new Date(deliveryDate), new Date(maxDeliveryDate));
    this.setState({ isProcessingDatesLoading: false });

    if (this._isMounted) {
      this.setState({ isProcessingDatesLoading: false });
    }
  }

  onHideErrorMessageAlert = () => {
    const { bill } = this.props;
    analytics.track(eventPage, 'hide-error-alert', {
      partialBillId: getBillPaymentIndex(bill),
    });
    this.props.resetError();
  };

  render() {
    const { isProcessingDatesLoading, isFeesLoading } = this.state;
    const {
      onSubmit,
      bill,
      payment,
      isLoading,
      goExit,
      recurringBill,
      errorCode,
      selectedFundingSource,
      goEditDeliveryMethod,
      goEditFundingSource,
      goEditPartialAmount,
      goEditNote,
      goEditDate,
      isRecurring,
      fee,
      onPrevConfirmPayment,
      billingSetting,
    } = this.props;

    const showInternationalFee = isInternationalDeliveryMethod(payment.deliveryMethod?.deliveryType);
    const internationalFee = billingSetting?.fee.international;
    const originPayment = getLatestPayment(bill.payments);
    const isUndepositedCheckPayment = originPayment ? isUndepositedCheck(originPayment) : false;
    const isFailedInstallment = payment && hasFailedInstallment(payment);

    const buttonLabel = () => {
      if (isFailedInstallment) {
        return 'bills.pay.confirm.repayment.buttonLabel';
      }

      return isUndepositedCheckPayment ? 'bills.pay.confirm.resend' : 'bills.pay.confirm.action';
    };

    if (isProcessingDatesLoading || isFeesLoading) {
      return <AreaLoader placement="wizard" />;
    }

    return (
      <PayBillConfirmPage
        isRecurring={isRecurring}
        recurringBill={recurringBill}
        onSubmit={onSubmit}
        onPrev={onPrevConfirmPayment}
        bill={bill}
        headerLabel="qbo.header.title"
        relativeStep={5 / 5}
        payment={payment}
        selectedFundingSource={selectedFundingSource || AccountRecord()}
        isLoading={isLoading}
        goExit={goExit}
        goEditDeliveryMethod={goEditDeliveryMethod}
        goEditNote={goEditNote}
        goEditDate={goEditDate}
        goEditFundingSource={goEditFundingSource}
        goEditPartialAmount={goEditPartialAmount}
        errorCode={errorCode}
        fee={showInternationalFee ? ({ ...fee, ...{ internationalFee } } as PaymentFeeInfo) : fee}
        onHideErrorMessageAlert={this.onHideErrorMessageAlert}
        title={isFailedInstallment ? 'bills.pay.confirm.repayment.title' : 'bills.pay.confirm.title'}
        buttonLabel={buttonLabel()}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  bill: getBill(state),
  payment: getPayment(state),
  isRecurring: getIsRecurring(state),
  recurringBill: getRecurringBill(state),
  isLoading: getIsLoading(state),
  selectedFundingSource: getSelectedFundingSource(state),
  orgId: getOrgId(state),
  errorCode: getErrorCode(state),
  fee: getFee(state),
  billingSetting: getBillingDetails(state),
  isFinancingPayment: financingStore.selectors.isFinancingPayment(state),
});

const mapDispatchToProps = (dispatch: Dispatch): MapDispatchToProps => ({
  selectPaymentDates(scheduledDate, deliveryEta, maxDeliveryEta, selectedId?) {
    dispatch(selectPaymentDatesAction(scheduledDate, deliveryEta, maxDeliveryEta, selectedId));
  },
  resetError() {
    dispatch(resetErrorAction());
  },
  setFee(fee) {
    dispatch(setFeeAction(fee));
  },
});

export const PayBillConfirmPageContainer = compose(
  withPayBillData(),
  connect(mapStateToProps, mapDispatchToProps)
)(PlainPayBillConfirmPageContainer);
