import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ActionsDropdown } from 'src/components/common/ActionsDropDown';
import { MICardForm, MICardTitle } from 'src/components/common/MICard';
import { MaskField, TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useModal } from 'src/helpers/react/useModal';
import { InvoiceType } from 'src/modules/invoices/types';
import InvoiceAttachment from 'src/pages/get-paid/list/components/InvoiceAttachment';
import { useGetAccountingSoftwareName } from 'src/pages/get-pro/hooks/useGetAccountingSoftwareName';
import { devices } from 'src/theme/appDevices';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { InlineDatePickerField } from 'src/ui/form/InlineDatePickerField';
import { InlineSelectField } from 'src/ui/form/InlineSelectField';
import { ModelViewField } from 'src/ui/form/use-form/useForm';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { FULL_STORY_MASK_RULE_CLASS, InvoiceStatus, ScreenMode } from 'src/utils/consts';
import { ContactType, FileUrlsPayloadType } from 'src/utils/types';

type Props = {
  invoice: InvoiceType;
  totalAmount: ModelViewField<string>;
  customerName: ModelViewField<string>;
  invoiceNumber: ModelViewField<string>;
  dueDate: ModelViewField<string>;
  viewOnly: boolean;
  rightOptionsMenu?: any;
  showMenu: boolean;
  onEdit: () => void;
  onMarkInvoiceAsPaid?: (value: boolean) => void;
  remove: (keepInvoice?: boolean) => Promise<void>;
  uploadFile: (file) => Promise<void>;
  deleteFile: () => void;
  fileUrls?: FileUrlsPayloadType | null;
  customers: ContactType[];
  loadingAttachment: boolean;
  openPdfPreview?: () => void;
  showGetPaidTabs?: boolean;
};

const InvoiceDetails = ({
  invoice,
  showMenu,
  totalAmount,
  customerName,
  customers,
  viewOnly,
  invoiceNumber,
  dueDate,
  onEdit,
  remove,
  fileUrls,
  uploadFile,
  deleteFile,
  loadingAttachment,
  onMarkInvoiceAsPaid,
  openPdfPreview,
  showGetPaidTabs,
}: Props) => {
  const [DeleteInvoiceModal, onDelete] = useModal(CommonDialog, {
    confirm: remove,
    title: 'getPaid.view.invoice.deleteMessage.title',
    textValues: {
      totalAmount: invoice?.totalAmount,
      companyName: invoice?.customer?.contactName,
    },
    description: 'getPaid.view.invoice.deleteMessage.description',
    confirmText: 'getPaid.view.invoice.deleteMessage.confirm',
    cancelText: 'getPaid.view.invoice.deleteMessage.cancel',
  });
  const accountingSoftwareName = useGetAccountingSoftwareName(invoice.createOrigin);
  const setMarkInvoiceAsPaid = () => {
    onMarkInvoiceAsPaid && onMarkInvoiceAsPaid(!isMarkedAsPaidAt);
  };

  const customersOptions = useMemo(
    () =>
      customers.map((customer) => ({
        value: customer.companyName,
        label: customer.companyName,
        id: customer.id,
      })),
    [customers]
  );

  const canEditCustomerName = invoice?.status === InvoiceStatus.CREATED;
  const isMarkedAsPaidAt = !isEmpty(invoice?.markedAsPaidAt);
  const integrationName = accountingSoftwareName && getAccountingSoftwareDisplayName(accountingSoftwareName);
  const integrationNameDisplayShortName = integrationName?.displayShortName;
  const actionOptions = [
    {
      label: 'getPaid.view.invoice.markAsPaid',
      action: setMarkInvoiceAsPaid,
      disabled: accountingSoftwareName
        ? {
            label: 'getPro.table.item.menu.disabledMenuItemTooltip.markAsPaid',
            values: {
              integrationName: integrationNameDisplayShortName,
            },
          }
        : false,
    },
    {
      label: 'getPaid.view.invoice.edit',
      action: onEdit,
      disabled: accountingSoftwareName
        ? {
            label: 'getPro.table.item.menu.disabledMenuItemTooltip.edit',
            values: {
              integrationName: integrationNameDisplayShortName,
            },
          }
        : false,
    },
    {
      label: 'getPaid.view.invoice.delete',
      action: onDelete,
      negative: true,
    },
  ];

  return (
    <MICardForm noHorizontalPadding={showGetPaidTabs}>
      {DeleteInvoiceModal}
      <Header>
        <MICardTitle label="getPaid.view.invoice.title" />
        {showMenu && (
          <Actions>
            {!isMarkedAsPaidAt && (
              <ActionsDropdown testId="getPaid.view.invoice.actionsMenu" actionOptions={actionOptions} />
            )}
          </Actions>
        )}
      </Header>
      <FormContent>
        <Left viewOnly={viewOnly}>
          <InlineSelectField
            label="getPaid.view.invoice.customerName"
            model={customerName}
            viewOnly={!canEditCustomerName || viewOnly}
            disabled={!canEditCustomerName && !viewOnly}
            required
            options={customersOptions}
            createLabel="requests.new.customerAddInSelect"
            allowCustom
          />
          <PrivateDataContainer>
            <MaskField
              size="sm"
              label="getPaid.view.invoice.amount"
              prefix="currency.symbol"
              fixedDecimalScale
              decimalScale={2}
              model={totalAmount}
              thousandSeparator
              isViewOnly={viewOnly}
              isRequired
            />
          </PrivateDataContainer>

          <InlineDatePickerField
            label="getPaid.view.invoice.dueDate"
            placeholder="getPaid.view.invoice.dueDateEmpty"
            model={dueDate}
            id="dueDate"
            required
            viewOnly={viewOnly}
          />

          <PrivateDataContainer>
            <TextField
              label="getPaid.view.invoice.invoiceNumber"
              model={invoiceNumber}
              isViewOnly={viewOnly}
              size="sm"
              mb={0}
              isRequired
            />
          </PrivateDataContainer>
        </Left>
        <Right className={FULL_STORY_MASK_RULE_CLASS}>
          {!loadingAttachment && (
            <InvoiceAttachment
              mode={viewOnly ? ScreenMode.VIEW : ScreenMode.EDIT}
              fileStorageUrl={fileUrls?.fileStorageUrl}
              filePreviewUrls={fileUrls?.filePreviewUrls}
              onOpenPreview={openPdfPreview}
              onChangeAttachment={uploadFile}
              onDeleteAttachment={deleteFile}
            />
          )}
        </Right>
      </FormContent>
    </MICardForm>
  );
};

export default InvoiceDetails;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
  }
`;

const Left = styled.div<{ viewOnly: boolean }>`
  width: 60%;
  margin-right: 2rem;
  & > div {
    margin-bottom: 2rem;
  }
  & input {
    color: ${(props) => props.theme.text.color.main};
    ${(props) => props.viewOnly && 'border-bottom: none;'}
  }

  @media ${devices.mobile} {
    width: 100%;
    margin-right: 0;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
  @media ${devices.mobile} {
    align-items: flex-start;
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${devices.mobile} {
    margin-left: 2.2rem;
  }

  @media ${devices.nonMobile} {
    width: 17.5rem;
  }
  ${(props) => props.theme.components?.ViewBill?.Actions}
`;
