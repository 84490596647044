import React from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';

type BottomUploadComponentProps = {
  label: string;
  action: () => void;
  shadow: boolean;
};

export const BottomButtonComponent = ({ label, action, shadow }: BottomUploadComponentProps) => (
  <Box boxShadow={shadow ? '400' : 'unset'}>
    <Box w="full" textAlign="center" borderBottom="1px" borderColor="grey.400" pt={3}>
      <Box as="span" px={4} bg="white" color="grey.700" textStyle="body4">
        <MIFormattedText label="general.or" />
      </Box>
    </Box>
    <Box w="full" boxSizing="border-box" p={5}>
      <Button onClick={action} label={label} isFullWidth size={ButtonSizes.sm} variant={ButtonVariants.tertiary} />
    </Box>
  </Box>
);
