import { AccountingSoftware } from 'src/utils/consts';

const mapping: Record<AccountingSoftware, string> = {
  [AccountingSoftware.QUICKBOOKS]: 'accountingSoftwareNames.quickbooks',
  [AccountingSoftware.QUICKBOOKS_DESKTOP]: 'accountingSoftwareNames.quickbooksDesktop',
  [AccountingSoftware.FRESHBOOKS]: 'accountingSoftwareNames.freshbooks',
  [AccountingSoftware.MICROSOFT_DYNAMICS_365]: 'accountingSoftwareNames.microsoftDynamics_365',
  [AccountingSoftware.XERO]: 'accountingSoftwareNames.xero',
  [AccountingSoftware.ORACLE_NETSUITE]: 'accountingSoftwareNames.oracleNetsuite',
  [AccountingSoftware.RIGHT_NETWORKS]: 'accountingSoftwareNames.rightNetworks',
  [AccountingSoftware.SAGE_INTACCT]: 'accountingSoftwareNames.sageIntacct',
  [AccountingSoftware.KASHOO]: 'accountingSoftwareNames.kashoo',
  [AccountingSoftware.MICROSOFT_DYNAMICS]: 'accountingSoftwareNames.microsoftDynamics',
  [AccountingSoftware.QUICKBOOKS_FOR_MAC]: 'accountingSoftwareNames.quickbooksForMac',
  [AccountingSoftware.QUICKEN]: 'accountingSoftwareNames.quicken',
  [AccountingSoftware.SAGE]: 'accountingSoftwareNames.sage',
  [AccountingSoftware.SAGE_50]: 'accountingSoftwareNames.sage_50',
  [AccountingSoftware.SAP]: 'accountingSoftwareNames.sap',
  [AccountingSoftware.THOMSON]: 'accountingSoftwareNames.thomson',
  [AccountingSoftware.WAVE]: 'accountingSoftwareNames.wave',
  [AccountingSoftware.YARDI]: 'accountingSoftwareNames.yardi',
  [AccountingSoftware.ZOHO_BOOKS]: 'accountingSoftwareNames.zohoBooks',
  [AccountingSoftware.NONE]: 'accountingSoftwareNames.none',
};

export const accountingSoftwareNameToIntlPath = (accountingSoftwareName?: AccountingSoftware) =>
  mapping[accountingSoftwareName || AccountingSoftware.NONE];
