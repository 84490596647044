import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { compose } from 'recompose';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { withNavigator } from 'src/hoc/index';
import organizationStore from 'src/modules/organizations/organizations-store';
import { getProfileActions, profileStore } from 'src/modules/profile/profile-store';
import { melioMeLocations } from 'src/pages/meliome/locations';
import { analytics } from 'src/services/analytics';
import { CompanyInfoType } from 'src/utils/types';
import GuestSelectOrgPage from './components/GuestSelectOrgPage';
import { GuestDataProps, withGuestData } from './hoc/withGuestData';

type Props = GuestDataProps;

const eventPage = 'payor';
const eventName = 'select-organization';

function GuestSelectOrgPageContainer({
  vendorId,
  prevRoute,
  navigateToGuestPage,
  link,
  paymentRequest,
  isAccountingOrg,
  ownedById,
}: Props) {
  const { contact = {}, customerName } = paymentRequest;
  const { email: customerEmail } = contact;
  const organizationsActions = useStoreActions(organizationStore);
  const organizations = useSelector<any, CompanyInfoType[]>(profileStore.selectors.getOrganizations);
  const isLoading = useSelector(organizationStore.selectors.assignPaymentRequestToOrg.loading);
  const { switchOrganization } = getProfileActions(useDispatch());
  const shouldAssignPaymentRequest = customerEmail && customerName && !isAccountingOrg;
  const filteredOrganizations = organizations.filter((org) => org?.id !== ownedById);

  const orgOptions = filteredOrganizations.map((org) => ({
    id: org.id.toString(),
    label: 'guests.selectOrg.companyOption',
    labelValues: { companyName: org.companyName },
  }));

  const onNext = async (organizationId?: number) => {
    const params = {
      organizationId,
      vendorId,
      contactEmail: customerEmail,
      companyName: customerName,
    };

    if (shouldAssignPaymentRequest) {
      await organizationsActions.assignPaymentRequestToOrg(params);
    }

    if (organizationId) {
      switchOrganization(organizationId);
    }

    analytics.track(eventPage, `${eventName}-continue-success`);
    navigateToGuestPage(generatePath(melioMeLocations.wizard.editFundingSource, { link }), {}, false, true);
  };

  const onPrev = () => {
    navigateToGuestPage(prevRoute);
  };

  const subtitle = shouldAssignPaymentRequest
    ? 'guests.selectOrg.subtitle.paymentRequestLink'
    : 'guests.selectOrg.subtitle.genericLink';

  return (
    <GuestSelectOrgPage
      options={orgOptions}
      onNext={onNext}
      isLoading={isLoading}
      onPrev={onPrev}
      subtitle={subtitle}
    />
  );
}

export default compose(withNavigator(), withGuestData())(GuestSelectOrgPageContainer);
