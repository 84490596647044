import { useCallback, useState } from 'react';
import { useOrgId } from 'src/hooks/useOrgId';
import { useGetValidFundingSources } from 'src/pages/regular-batch-payments/table/hooks/useGetValidFundingSources';
import { useOrgDefaultFundingSource } from 'src/pages/settings/hooks/useOrgDefaultFundingSource';
import { getDefaultFundingSource } from 'src/redux/utils';

export const useDefaultFundingSourceId = (): {
  getDefaultFundingSourceId: () => void;
  defaultFundingSourceId: number | undefined;
  isLoading: boolean;
} => {
  const orgId = useOrgId();
  const [filteredFundingSources] = useGetValidFundingSources();
  const { orgDefaultFundingSourceId } = useOrgDefaultFundingSource();
  const [defaultFundingSourceId, setDefaultFundingSourceId] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const getDefaultFundingSourceId = useCallback(async () => {
    const fs = await getDefaultFundingSource(orgId, filteredFundingSources, orgDefaultFundingSourceId);
    setDefaultFundingSourceId(fs?.id);
    setIsLoading(false);
  }, [filteredFundingSources, orgDefaultFundingSourceId, orgId]);

  return {
    getDefaultFundingSourceId,
    defaultFundingSourceId,
    isLoading,
  };
};
