export const SITE_NAME = {
  APP: {
    DEFAULT: 'app.meliopayments.com',
    ALPHA: 'alpha-app.meliopayments.com',
    DEV: 'dev-app.meliopayments.com',
  },
  MCB: {
    DEFAULT: 'melio-button',
  },
};
