import { ChangeEvent, SyntheticEvent } from 'react';
import { FieldType } from './types';

type InputChangeProps = {
  onChange: (arg0: FieldType | any) => void;
  event: ChangeEvent<HTMLInputElement> | SyntheticEvent<HTMLInputElement>;
  valueOnly?: boolean;
  idToNumber?: boolean;
  inputMode?: string;
};

export const handleInputChange = ({
  event,
  onChange,
  valueOnly = false,
  idToNumber = false,
  inputMode,
}: InputChangeProps) => {
  const { id, value } = event.currentTarget;
  const regex = /(^[0-9]+$|^$)/;

  if (inputMode === 'numeric' && !regex.test(value)) {
    return;
  }

  valueOnly ? onChange(value) : onChange({ id: idToNumber ? Number(id) : id, value });
};
