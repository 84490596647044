import { formErrorAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyleText: SystemStyleObject = {
  color: 'red.500',
  mt: 1,
};

const baseStyle: PartsStyleFunction<typeof parts> = (props) => {
  const textStyle = props.size === 'sm' ? 'textStyles.body4' : 'textStyles.body3';

  return { text: { ...baseStyleText, apply: textStyle } };
};

export default {
  parts: parts.keys,
  baseStyle,
};
