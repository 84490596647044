import { MouseEvent } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { ModalMessage, ModalTitle } from 'src/components/common/ModalMessage';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { ModalButtonsWrapper } from 'src/pages/team/team-management/ModalButtonsWrapper';

type Props = {
  dismiss: (event: MouseEvent | null) => void;
  onSubmit: () => void;
  companyName: string;
  modalName: string;
};

export const DeleteApprovalWorkflowModal = ({ dismiss, onSubmit, companyName, modalName }: Props) => {
  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    onSubmit();
    dismiss(e);
  };

  return (
    <ModalMessage
      id={modalName}
      onCloseClick={dismiss}
      titleComponent={
        <ModalTitle>
          <MIFormattedText label="settings.approvalWorkflows.deleteModal.title" values={{ companyName }} />
        </ModalTitle>
      }
      contentComponent={<MIFormattedText label="settings.approvalWorkflows.deleteModal.text" />}
      buttonComponent={
        <ModalButtonsWrapper>
          <Flex direction={['column', null, 'row']} alignItems="center" justifyContent="flex-end">
            <Button
              onClick={dismiss}
              variant={ButtonVariants.tertiaryNaked}
              size={ButtonSizes.md}
              label="settings.approvalWorkflows.deleteModal.buttons.cancel"
            />
            <Button
              variant={ButtonVariants.error}
              boxSizing="border-box"
              onClick={handleSubmit}
              size={ButtonSizes.md}
              width={['100%', null, 'auto']}
              label="settings.approvalWorkflows.deleteModal.buttons.submit"
            />
          </Flex>
        </ModalButtonsWrapper>
      }
    />
  );
};
