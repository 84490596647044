import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { IllustrationName } from 'src/core/ds/illustration';
import { useSiteContext } from 'src/hoc/withSiteContext';

export const VirtualCardCandidatesInvalidPage = () => {
  const site = useSiteContext();

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.errorScreen}
      title="vendors.virtualCard.candidates.invalid.title"
      text="vendors.virtualCard.candidates.invalid.details"
      textValues={{
        email: (
          <Box as="a" color="primary.500" textDecoration="none" href={`mailto:${site.config.support.email}`}>
            {site.config.support.email}
          </Box>
        ),
      }}
    />
  );
};
