import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { fundingSourcesStore } from 'src/modules/funding-sources/funding-sources-store';
import { AccountRecord } from 'src/pages/settings/records';
import { deliveryMethodFactory } from 'src/pages/vendor/records';
import { PaymentDetailsProps } from '../PaymentDetails';
import { ViewBillPaymentActivity } from '../ViewBillPaymentActivity/ViewBillPaymentActivity';

export const PaymentActivity: React.FC<PaymentDetailsProps> = ({ bill, payment }) => {
  const fundingSource = AccountRecord(
    useSelector(fundingSourcesStore.selectors.byId(payment.fundingSourceId)) || payment.fundingSource
  );
  const deliveryMethod = deliveryMethodFactory(payment.deliveryMethod);

  return (
    <PaymentContainer>
      <ViewBillPaymentActivity
        bill={bill}
        payment={payment}
        fundingSource={fundingSource}
        deliveryMethod={deliveryMethod}
      />
    </PaymentContainer>
  );
};

export const PaymentContainer = styled.div`
  flex: 1;
`;
