import { useState } from 'react';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { Menu, MenuButton, MenuList } from 'src/core/ds/menu';
import { ReactComponent as ChevronDownIcon } from 'src/images/get-pro/menu-arrow-down.svg';
import { ReactComponent as FilterIcon } from 'src/images/get-pro/menu-filter.svg';
import { analytics } from 'src/services/analytics';
import { CompaniesDashboardFilterItem } from './CompaniesDashboardFilterItem';

type Props = {
  actions: readonly Action[];
};

type Action = { label: string; onClick: () => void; testId: string };

export const CompaniesDashboardFilter = ({ actions }: Props) => {
  const [activeFilterIndex, setActiveFilterIndex] = useState(0);

  const handleFilterChange = (index: number) => {
    setActiveFilterIndex(index);
  };

  const handleFilterButtonClick = () => {
    analytics.trackAction('filter-button-click');
  };

  return actions?.length ? (
    <Menu>
      <MenuButton
        as={Button}
        variant={ButtonVariants.tertiary}
        rightIcon={<ChevronDownIcon />}
        leftIcon={<FilterIcon />}
        size={ButtonSizes.sm}
        label={actions[activeFilterIndex].label}
        data-testid="companies-dashboard-filter-button"
        onClick={handleFilterButtonClick}
      />
      <MenuList>
        {actions.map(({ label, onClick, testId }, index) => (
          <CompaniesDashboardFilterItem
            index={index}
            label={label}
            onClick={onClick}
            onFilterChange={handleFilterChange}
            testId={testId}
          />
        ))}
      </MenuList>
    </Menu>
  ) : (
    <></>
  );
};
