import { useCallback, useEffect } from 'react';
import { generatePath, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { analytics } from 'src/services/analytics';
import { TokenStateEnum } from './hooks/consts';
import { useCandidateByToken } from './hooks/useCandidatesTokenState';
import { virtualCardCandidatesLocations } from './locations';
import { VirtualCardCandidatesAlreadyFilledPage } from './VirtualCardCandidatesAlreadyFilledPage';
import { VirtualCardCandidatesDetailsPage } from './VirtualCardCandidatesDetailsPage';
import { VirtualCardCandidatesInvalidPage } from './VirtualCardCandidatesInvalidPage';
import { VirtualCardCandidatesSuccessPage } from './VirtualCardCandidatesSuccessPage';

export const virtualCardCandidateEventPage = 'virtual-card-candidate';

export const VirtualCardCandidatesRouter = () => {
  const { token } = useRouteMatch<{ token: string }>().params;
  const history = useHistory();
  const { tokenState, candidateId, capLimitAmount } = useCandidateByToken(token);

  const checkTokenState = useCallback(() => {
    if (tokenState === TokenStateEnum.VALID) {
      analytics.track(virtualCardCandidateEventPage, 'form-page-loaded', {
        candidateId,
        isConditionalAmount: Boolean(capLimitAmount),
        ...(Boolean(capLimitAmount) && {
          ConditionalAmount: capLimitAmount,
        }),
      });
      history.push(generatePath(virtualCardCandidatesLocations.details, { token, id: candidateId }));
    }

    if (tokenState === TokenStateEnum.VENDOR_FILLED_FORM) {
      analytics.track(virtualCardCandidateEventPage, 'already-signed-up-page-loaded', { candidateId });
      history.push(generatePath(virtualCardCandidatesLocations.alreadyFilled, { token }));
    }

    if (tokenState === TokenStateEnum.INVALID) {
      analytics.track(virtualCardCandidateEventPage, 'invalid-token-page-loaded', { candidateId, token });
      history.push(generatePath(virtualCardCandidatesLocations.invalid, { token }));
    }
  }, [candidateId, history, token, tokenState]);

  useEffect(() => {
    checkTokenState();
  }, [checkTokenState]);

  return (
    <Switch>
      <SmartRoute path={virtualCardCandidatesLocations.index} exact>
        <AreaLoader />
      </SmartRoute>
      <SmartRoute path={virtualCardCandidatesLocations.details} exact>
        <VirtualCardCandidatesDetailsPage capLimitAmount={capLimitAmount} />
      </SmartRoute>
      <SmartRoute path={virtualCardCandidatesLocations.success} exact>
        <VirtualCardCandidatesSuccessPage />
      </SmartRoute>
      <SmartRoute path={virtualCardCandidatesLocations.alreadyFilled} exact>
        <VirtualCardCandidatesAlreadyFilledPage />
      </SmartRoute>
      <SmartRoute path={virtualCardCandidatesLocations.invalid} exact>
        <VirtualCardCandidatesInvalidPage />
      </SmartRoute>
    </Switch>
  );
};
