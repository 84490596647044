import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { RegisterLayoutPage } from 'src/components/layout/RegisterLayoutPage';
import RegistrationTermsAndConditions from 'src/components/onboarding/RegistrationTermsAndConditions';
import { TextField } from 'src/core/ds/form/fields';
import { PrivateDataContainer } from 'src/core/ds/input';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import authStore from 'src/modules/auth/auth-store';
import invitationsStore from 'src/modules/invitations/invitations-store';
import organizationStore from 'src/modules/organizations/organizations-store';
import { authLocations } from 'src/pages/auth/locations';
import { getPasswordValidation } from 'src/pages/auth/utils';
import { checkAndInitUserAction } from 'src/redux/user/actions';
import { getIsLoggedIn, getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { useForm } from 'src/ui/form';
import { WizardPasswordValidationInputField } from 'src/ui/form/WizardPasswordValidationInputField';
import { CompanyType, Role } from 'src/utils/consts';
import locations from 'src/utils/locations';
import { ConfirmMergeExistingFirm } from './components/ConfirmMergeExistingFirm';

const eventPage = 'register-invitation';

export type RegisterSuccessResponse = {
  userExists?: boolean;
  isMemberOfAccountingFirm?: boolean;
  userRole?: string;
  invitedByAccountant?: boolean;
  existingUserInvitedByFirm?: boolean;
  orgId: number;
};

type Props = {
  token: string;
  onRegisterSuccess: (options: RegisterSuccessResponse) => void;
  onInvalidToken: () => void;
};
type RenderStatusState = {
  status: 'register' | 'firm-inviting-multi-org' | 'loading';
  props?: Record<string, any>;
};

export function RegisterFromInvitation({ onInvalidToken, onRegisterSuccess, token }: Props) {
  const [renderStatusState, setRenderStatusState] = useState<RenderStatusState>({ status: 'loading' });
  const passwordModel = useMemo(() => ({ password: '' }), []);
  const dispatch = useDispatch();
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const invitationActions = useStoreActions(invitationsStore);
  const authActions = useStoreActions(authStore);

  const { error, loading: validating, userOrganization: invitingUserOrganization } = useSelector(
    invitationsStore.selectors.validation
  );
  const invitation = useSelector(invitationsStore.selectors.validationInvitation);
  const organization = useSelector(organizationStore.selectors.byId(invitation?.organizationId));
  const companyName = organization?.companyName;
  const companyType = organization?.companyType;
  const buttonLabel =
    companyType === CompanyType.ACCOUNTING_FIRM
      ? 'auth.registerFromInvitation.signupButtonAccountingFirm'
      : 'auth.registerFromInvitation.signupButton';

  const isLoggedIn = useSelector(getIsLoggedIn);
  const { isRegisteringFromInvitation, registrationError } = useStructuredSelectors(authStore.selectors);

  const acceptInvitation = async (params, orgId: number, userExists: boolean) => {
    const { payload } = await authActions.acceptInvitation(params);
    const organizations = payload?.user?.organizations;
    const isMemberOfAccountingFirm = organizations.some((org) => org.companyType === CompanyType.ACCOUNTING_FIRM);
    setRenderStatusState({ status: 'loading' });

    dispatch(
      checkAndInitUserAction(userExists ? { orgId } : {}, () => {
        onRegisterSuccess({
          userExists,
          isMemberOfAccountingFirm,
          userRole: payload?.userOrganizations?.role,
          invitedByAccountant: invitingUserOrganization?.role === Role.ACCOUNTANT,
          orgId,
        });
      })
    );
  };

  const acceptExistingUserInvitedByFirm = async (orgId: number) => {
    await authActions.acceptUserInvitation({ token });

    dispatch(checkAndInitUserAction({ orgId }));

    onRegisterSuccess({
      existingUserInvitedByFirm: true,
      orgId,
    });
  };

  useEffect(() => {
    if (validating) {
      setRenderStatusState({ status: 'loading' });
    }
  }, [validating]);

  useEffect(() => {
    invitationActions.validateInvitation({ token }).then(({ payload }) => {
      if (payload.userExists && payload?.organization?.companyType === CompanyType.ACCOUNTING_FIRM) {
        if (payload.isMultiOrg) {
          setRenderStatusState({
            status: 'firm-inviting-multi-org',
            props: { firmCompanyName: payload?.organization?.companyName },
          });
        } else {
          setRenderStatusState({ status: 'loading' });
          acceptExistingUserInvitedByFirm(payload?.invitation?.organizationId);
        }
      } else if (payload.userExists) {
        setRenderStatusState({ status: 'loading' });
        acceptInvitation({ token }, payload?.invitation?.organizationId, true);
      } else {
        setRenderStatusState({ status: 'register' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token) {
      invitationActions.inviteeOrganizations({ token });
    }
  }, [invitationActions, token]);

  useEffect(() => {
    if (error && onInvalidToken) {
      if (error.code === 'ATH21') {
        const url = isLoggedIn ? locations.MainApp.dashboard.url({ orgId }) : authLocations.login;
        history.push(url);
      } else {
        error && onInvalidToken && onInvalidToken();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, onInvalidToken, isLoggedIn, history, orgId]);

  const email = invitation?.email;

  const goRegister = async (password: string) => {
    await acceptInvitation({ token, password }, organization?.id, false);
  };

  const [passwordMV, { submit }] = useForm(passwordModel, {
    submit: ({ password }) => goRegister(password),
    validateOnChange: false,
    validator: async (key, password) => {
      const passwordValidation = await getPasswordValidation(key, password);

      if (passwordValidation) {
        analytics.track(eventPage, 'click-join', { passwordValidation });
      }

      return passwordValidation;
    },
  });

  if (renderStatusState.status === 'loading') {
    return <AreaLoader />;
  }

  if (renderStatusState.status === 'register') {
    return (
      <RegisterLayoutPage
        title="auth.registerFromInvitation.title"
        text="auth.registerFromInvitation.subtitle"
        textValues={{ companyName: <b>{companyName}</b> }}
        buttonValues={{ companyName }}
        buttonLabel={buttonLabel}
        buttonAction={submit}
        isLoading={isRegisteringFromInvitation}
        footer={
          <RegistrationTermsAndConditions
            label="auth.regularRegister.termsAndConditions"
            onClickPrivacyPolicy={() => analytics.track(eventPage, 'click-privacy-policy')}
            onClickUserAgreement={() => analytics.track(eventPage, 'click-user-agreement')}
          />
        }
      >
        <PrivateDataContainer>
          <TextField
            id="email"
            label="auth.registerFromInvitation.workEmail"
            value={email}
            isRequired
            type="email"
            isViewOnly
          />
        </PrivateDataContainer>
        <WizardPasswordValidationInputField
          id="password"
          model={passwordMV.password}
          label="auth.registerFromInvitation.password"
          errorMessage={passwordMV.password.error || (registrationError?.code && `server.${registrationError?.code}`)}
          helperText="auth.registerFromInvitation.passwordNotice"
          isRequired
          autoFocus
        />
      </RegisterLayoutPage>
    );
  }

  if (renderStatusState.status === 'firm-inviting-multi-org') {
    return (
      <ConfirmMergeExistingFirm
        invitation={invitation}
        firmCompanyName={renderStatusState.props?.firmCompanyName}
        onNext={acceptExistingUserInvitedByFirm}
      />
    );
  }

  return null;
}
