import { fetchRequest, postRequest } from 'src/services/api/api';

export const multiOrgsApi = {
  assignPaymentRequestToOrg(params) {
    const url = '/multi-orgs/assign-payment-request-to-org';

    return postRequest(url, params);
  },
  getOrgAssignedToPaymentRequest(params) {
    const url = '/multi-orgs/get-org-assigned-to-payment-request';

    return fetchRequest(url, params);
  },
};
