import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import { useSelector } from 'react-redux';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useExtraAnalyticsProps } from 'src/analytics/useExtraAnalyticsProps';
import { SmartRoute } from 'src/modules/navigation/components/SmartRoute';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { InternationalBillingDetailsPage } from 'src/pages/vendor/international-delivery-method/billing-details/InternationalBillingDetailsPage';
import { InternationalBusinessPage } from 'src/pages/vendor/international-delivery-method/business-details/InternationalBusinessDetailsPage';
import { InternationalPaymentPurposePage } from 'src/pages/vendor/international-delivery-method/payment-purpose/InternationalPaymentPurposePage';
import { UltimateBeneficialOwnersPage } from 'src/pages/vendor/international-delivery-method/ultimate-beneficial-owners/UltimateBeneficialOwnersPage';
import { getBill } from 'src/redux/payBillWizard/selectors';
import { getBillPaymentIndex } from 'src/utils/bills';
import { useLocationState } from 'src/utils/hooks';
import { InternationalWizardProvider } from './hooks/useInternationalWizard';
import { internationalLocations } from './locations';
import { VendorDetailsPage } from './vendor-details/VendorDetailsPage';

export const InternationalWizardRouter = () => {
  const [historyPush] = useHistoryWithOrgId();
  const [exitUrl] = useLocationState<string>('exitUrl');
  const onExit = exitUrl ? () => historyPush({ path: exitUrl }) : noop;
  const currentBill = useSelector(getBill);
  const match = useRouteMatch<{ id: string }>();
  const vendorId = Number(match.params?.id);
  const partialBillId = getBillPaymentIndex(currentBill);

  useExtraAnalyticsProps(
    'internationalDeliveryMethod',
    pickBy({
      billId: currentBill?.id,
      partialBillId,
      vendorId,
    })
  );

  return (
    <InternationalWizardProvider>
      <Switch>
        <SmartRoute path={[internationalLocations.base, internationalLocations.billing]} exact>
          <InternationalBillingDetailsPage onExit={onExit} vendorId={vendorId} />
        </SmartRoute>
        <SmartRoute path={internationalLocations.vendorDetails} exact>
          <VendorDetailsPage onExit={onExit} vendorId={vendorId} />
        </SmartRoute>
        <SmartRoute path={internationalLocations.businessDetails} exact>
          <InternationalBusinessPage onExit={onExit} />
        </SmartRoute>
        <SmartRoute path={internationalLocations.ubo} exact>
          <UltimateBeneficialOwnersPage onExit={onExit} vendorId={vendorId} />
        </SmartRoute>
        <SmartRoute path={internationalLocations.paymentPurpose} exact>
          <InternationalPaymentPurposePage vendorId={vendorId} />
        </SmartRoute>
      </Switch>
    </InternationalWizardProvider>
  );
};
