import * as React from 'react';
import styled from 'styled-components';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { CompanyAddedSuccessFooter } from 'src/pages/onboarding/company-info/components/CompanyAddedSuccessFooter';
import { analytics } from 'src/services/analytics';
import { CompanyInfoOnboardingOrigin } from 'src/utils/consts';

type Props = {
  onNext: () => void;
  goToDashboard: () => void;
  origin?: string;
  isAccountantAddCompanyFlow: boolean;
  companyName?: string | null;
  orgId?: number;
  isSMB: boolean;
};

export const CompanyAddedPage: React.FC<Props> = ({
  origin,
  onNext,
  goToDashboard,
  orgId,
  isAccountantAddCompanyFlow,
  companyName,
  isSMB,
}) => {
  analytics.trackToServer(
    'analytics_onboarding_completed_setup',
    {
      table: 'organizations',
      key: 'is_completed_setup',
      id: orgId,
      value: true,
    },
    false
  );

  const isPaymentRequest = origin === CompanyInfoOnboardingOrigin.PAYMENT_REQUEST;

  let title = 'onboarding.companyInfo.added.accountReady';
  let text = 'onboarding.companyInfo.added.getStartedText';
  let doneLabel = 'onboarding.companyInfo.added.getStarted';
  let doneLinkLabel;

  if (isAccountantAddCompanyFlow) {
    title = 'inviteNewCompany.accountantFlow.success.title';
    text = 'inviteNewCompany.accountantFlow.success.accountantBillPayText';
    doneLabel = 'inviteNewCompany.accountantFlow.success.getStarted';
  }

  if (isSMB) {
    title = 'onboarding.companyInfo.added.title';
    text = 'onboarding.companyInfo.added.text';
    doneLabel = 'onboarding.companyInfo.added.getStarted';
  }

  if (isPaymentRequest) {
    title = 'onboarding.companyInfo.added.title';
    text = 'onboarding.companyInfo.added.text';
    doneLabel = 'onboarding.companyInfo.added.continueToPay';
    doneLinkLabel = 'onboarding.companyInfo.added.exploreLink';
  }

  const doneAction = () => {
    analytics.track('onboarding-company-info', 'success-add-vendor');
    onNext();
  };

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.business}
      title={title}
      titleValues={{
        companyName: <Title key={companyName}>{companyName}</Title>,
      }}
      text={text}
      textValues={{ companyName }}
      footer={
        <CompanyAddedSuccessFooter
          buttonAction={doneAction}
          doneLabel={doneLabel}
          doneLinkLabel={doneLinkLabel}
          linkAction={goToDashboard}
        />
      }
    />
  );
};

const Title = styled.span`
  color: ${(props) => props.theme.text.color.subtitle};
`;
