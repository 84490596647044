import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Icon, IconNames, IconSize } from 'src/core/ds/icon';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { billingLocations } from 'src/pages/billing/locations';
import { useBillingAnalytics } from 'src/pages/settings/components/Billing/useBillingAnalytics';

export const EmptyMethodCard = () => {
  const [historyPush] = useHistoryWithOrgId();
  const { track } = useBillingAnalytics();

  const handleAddMethod = () => {
    track('settings-billing', 'add-method-click');
    historyPush({ path: billingLocations.add });
  };

  return (
    <Flex border="1px" borderColor="grey.400" borderRadius="lg" p={6} justify="space-between">
      <Flex>
        <Icon name={IconNames.wallet} size={IconSize.lg} color="black" alignSelf="center" />
        <Flex direction="column" marginInlineStart={4}>
          <Box textStyle="body2Semi" mb={1}>
            <MIFormattedText label="settings.billing.paymentMethod.emptyMethodTitle" />
          </Box>
          <Box textStyle="body4" color="grey.600">
            <MIFormattedText label="settings.billing.paymentMethod.emptyMethodSubTitle" />
          </Box>
        </Flex>
      </Flex>
      <Box textStyle="link2" onClick={handleAddMethod} data-testid="add-billing-link">
        <MIFormattedText label="settings.billing.paymentMethod.addMethod" />
      </Box>
    </Flex>
  );
};
