import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router-dom';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { authApi } from 'src/modules/auth/api';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { getPasswordValidation } from 'src/pages/auth/utils';
import { billLocations } from 'src/pages/bill/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { setProfileAction } from 'src/redux/user/actions';
import { getOrgId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import guestApi from 'src/services/api/guests';
import { useForm } from 'src/ui/form';
import { ValidationError } from 'src/ui/ValidationError';
import { getBillsDefaultFilters, getBillsSearchPath } from 'src/utils/bills';
import { BillStatus, CompanyInfoOnboardingOrigin } from 'src/utils/consts';
import { melioClose } from 'src/utils/external-events';
import { useLocationState } from 'src/utils/hooks';
import GuestSuccessSignUpPage from './components/GuestSuccessSignUpPage';

const eventPage = 'payor';

type LocationState = {
  billId: number;
  vendorId: number;
  logoUrl: string;
  fileStorageUrl: string;
  filePreviewUrls: string[];
  link: string;
};

export const GuestSuccessSignUpPageContainer = ({ link }) => {
  const site = useSiteContext();
  const [isLoading, setIsLoading] = useState(false);
  const passwordModel = useMemo(() => ({ password: '' }), []);
  const { state: locationState } = useLocation<LocationState>();
  const [billId] = useLocationState('billId', null);

  const [vendorId, setVendorId] = useState<number>();
  const orgId = useSelector(getOrgId);

  const { navigate } = useNavigator();
  const dispatcher = useDispatch();
  const profile = useSelector(getProfile);

  const onInit = useCallback(async () => {
    const { id = 0 } = await guestApi.getPublicVendorInfo(link);
    setVendorId(id);
  }, [link]);

  useEffect(() => {
    onInit();
  }, []);

  const [passwordMV, { submit }] = useForm(passwordModel, {
    submit: ({ password }) => goNext(password),
    validateOnChange: false,
    validator: async (key, password) => {
      const passwordValidation = await getPasswordValidation(key, password);

      if (passwordValidation) {
        analytics.track(eventPage, 'password-validation-error', { passwordValidation });
      }

      return passwordValidation;
    },
  });

  const goNext = async (password: string) => {
    if (profile.isGuest) {
      setIsLoading(true);

      analytics.track(eventPage, 'password-continue');

      try {
        const { user } = await authApi.guestRegister({
          email: profile.email,
          password,
          registrationFlow: site.createOrigin.meliome.payor,
          referringVendor: vendorId,
        });
        dispatcher(setProfileAction({ ...user }));

        analytics.track(eventPage, 'password-continue-success');
        analytics.triggerCampaignEvents();
        setIsLoading(false);
        const redirectUrl = `${billLocations.index}?${getBillsSearchPath({ status: BillStatus.SCHEDULED })}`;
        const { logoUrl, fileStorageUrl, filePreviewUrls, ...rest } = locationState;
        navigate(
          onboardingLocations.companyInfo.address,
          false,
          {
            preservedState: {
              ...rest,
            },
            redirectUrl,
            origin: CompanyInfoOnboardingOrigin.GUEST_PAYMENT_REQUEST,
          },
          false,
          site.embeddedMode
        );
        melioClose();
      } catch (e: any) {
        setIsLoading(false);
        throw new ValidationError({
          validationErrors: { password: `inputErrors.userRegistration.password.server.${e.code}` },
        });
      }
    } else {
      const defaultFilters = getBillsDefaultFilters(BillStatus.SCHEDULED);

      navigate(generatePath(billLocations.filteredView, { orgId, id: billId as string, ...defaultFilters }));
    }
  };

  return <GuestSuccessSignUpPage goNext={submit} passwordModel={passwordMV.password} isLoading={isLoading} />;
};
