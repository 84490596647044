import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import { report } from '../../../utils/error-tracking';

export const ErrorPage = () => (
  <Box w="calc(100% - 9rem)" h="full" flex="1" boxSizing="border-box" p={16}>
    <h2 data-testid="errorPageTitle">
      <MIFormattedText label="error.title" />
    </h2>
    <p>We are sorry — something has gone wrong.</p>
    <p>Our team has been notified, but click here fill out a report.</p>
    <Button label="error.report" onClick={report} variant={ButtonVariants.tertiary} size={ButtonSizes.lg} />
  </Box>
);

ErrorPage.defaultProps = {};
