import { Skeleton } from 'src/core/ds/skeleton';
import { HStack, VStack } from 'src/core/ds/stack';

export const RepaymentTermsDateLoading = () => (
  <HStack alignItems="center">
    <VStack spacing={2} alignItems="flex-start">
      <Skeleton variant="medium" width="16rem" height={4} />
      <Skeleton variant="light" width="12rem" height={4} />
    </VStack>
  </HStack>
);
