import { CardChangeEvent } from '@basis-theory/basis-theory-js/types/elements/events';
import { useRef } from 'react';
import { BasisTheoryCardsBrands } from 'src/components/basis-theory/brands-logos/consts';
import { BasisTheoryCardBrandType } from 'src/components/basis-theory/brands-logos/types';

type Props = {
  onChange(event: Omit<CardChangeEvent, 'cardBrand'>): void;
  onCardBrandChange(cardBrand: BasisTheoryCardBrandType): void;
};

export const useCardBrand = ({ onChange, onCardBrandChange }: Props) => {
  const cardBrandRef = useRef<BasisTheoryCardBrandType>(BasisTheoryCardsBrands.unknown);

  const isSupportedByBasisTheory = (cardBrand: CardChangeEvent['cardBrand']): cardBrand is BasisTheoryCardBrandType => {
    const supportedCardBrands = Object.values(BasisTheoryCardsBrands);

    return supportedCardBrands.includes(cardBrand as BasisTheoryCardBrandType);
  };

  const handleChange = ({ cardBrand, ...event }: CardChangeEvent) => {
    onChange({ ...event });

    if (cardBrand !== cardBrandRef.current && isSupportedByBasisTheory(cardBrand)) {
      cardBrandRef.current = cardBrand;
      onCardBrandChange(cardBrand);
    }
  };

  return {
    handleChange,
  };
};
