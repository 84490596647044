import { MIFormattedText } from 'src/components/common/MIFormattedText';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { Image } from 'src/core/ds/image';
import NotFoundImg from 'src/images/general/full-moon.svg';

export const EmptyMenu = () => (
  <Flex direction="column" justify="center" alignItems="center" h="16rem">
    <Image src={NotFoundImg} w="6.5rem" h="6.5rem" />
    <Box textStyle="body3Semi" color="grey.600" mt={4}>
      <MIFormattedText label="pay.filter.noResultFound" />
    </Box>
  </Flex>
);
