import { isBefore, isSameMonth, isSameWeek } from 'date-fns';
import { OrderedSet, RecordOf } from 'immutable';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import sumBy from 'lodash/sumBy';
import { getBillTag, hasBillPayments, serializePaymentId } from 'src/utils/bills';
import { BillStatus, DeliveryType, PaymentApprovalStatus, PaymentStatus } from 'src/utils/consts';
import { getLatestPayment } from 'src/utils/payments';
import { BillsGroup, BillType, PaymentType } from 'src/utils/types';

function getComparedDate(bill: RecordOf<BillType> | BillType, status: string | null) {
  if (status === BillStatus.SCHEDULED || status === BillStatus.PAID) {
    if (!isEmpty(bill.payments)) {
      return bill.payments?.[bill.payments.length - 1].scheduledDate;
    }

    return new Date();
  }

  return bill.dueDate;
}

const createBillsGroup = ({
  header,
  bills = [],
  sum = 0,
}: {
  header: string;
  bills?: Array<RecordOf<BillType> | BillType>;
  sum?;
  count?: number;
}): BillsGroup => ({
  header,
  bills,
  sum,
});

const groupFailedPayments = (bills: OrderedSet<RecordOf<BillType> | BillType>): BillsGroup[] => {
  const failedPayments = createBillsGroup({
    header: 'list.group.bill.failedPayment',
  });
  const pendingApprovalPayments = createBillsGroup({
    header: 'list.group.bill.pendingApprovalPayment',
  });
  const declinedPayments = createBillsGroup({
    header: 'list.group.bill.declinedPayment',
  });
  bills.forEach((bill) => {
    const tag = getBillTag(bill);

    if (tag === PaymentApprovalStatus.PENDING) {
      pendingApprovalPayments.bills.push(bill);
      pendingApprovalPayments.sum += bill.totalAmount;
    } else if (tag === PaymentApprovalStatus.DECLINED) {
      declinedPayments.bills.push(bill);
      declinedPayments.sum += bill.totalAmount;
    } else if (tag === PaymentStatus.FAILED) {
      failedPayments.bills.push(bill);
      failedPayments.sum += bill.totalAmount;
    }
  });

  return [pendingApprovalPayments, failedPayments, declinedPayments];
};

const groupInboxBillForPartialPayments = (bills: OrderedSet<RecordOf<BillType>>): BillsGroup[] => {
  const status = BillStatus.UNPAID;
  const overdueBills = createBillsGroup({
    header: 'list.group.bill.overdueBills',
  });
  const billsDueThisWeek = createBillsGroup({
    header: 'list.group.bill.billsDueThisWeek',
  });
  const billsForLater = createBillsGroup({
    header: 'list.group.bill.billsForLater',
  });

  bills.forEach((bill) => {
    const compareDate = new Date(getComparedDate(bill, status));
    const now = new Date();

    if (isBefore(compareDate, now) && !hasBillPayments(bill)) {
      overdueBills.bills.push(bill);
      overdueBills.sum += bill.totalAmount;
    } else if (isSameWeek(compareDate, now)) {
      billsDueThisWeek.bills.push(bill);
      billsDueThisWeek.sum += bill.totalAmount;
    } else {
      billsForLater.bills.push(bill);
      billsForLater.sum += bill.totalAmount;
    }
  });

  return [overdueBills, billsDueThisWeek, billsForLater];
};

const groupPaymentRequests = (requests: Array<RecordOf<BillType>>): BillsGroup =>
  createBillsGroup({
    header: 'list.group.bill.requestedPayments',
    bills: requests,
  });

export const getPaymentConvertedToBill = (bill: BillType, payment: PaymentType, status?: BillStatus): BillType => ({
  ...bill,
  id: serializePaymentId(bill.id, payment.id),
  ...(payment.vendor && { vendor: payment.vendor }),
  payments: [payment],
  status: status === BillStatus.UNPAID ? (payment.status as BillStatus) : bill.status,
});

export const convertPaymentsToBill = (payments: PaymentType[]): BillType => {
  const lastPayment: PaymentType = head(payments)!;
  const bill = getPaymentConvertedToBill(lastPayment.bill as BillType, lastPayment);

  return {
    ...bill,
    payments,
  };
};

const groupPaidBillsWithPayments = (bills: OrderedSet<RecordOf<BillType>>): BillsGroup[] => {
  const lastPaymentScheduledDate = (payments: PaymentType[]) => new Date(head(payments)?.scheduledDate);
  const billsWithPayments = bills.toJS().map(
    (bill): BillType => {
      const paidPayments = bill.payments.filter(
        (payment) => payment.status === PaymentStatus.IN_PROGRESS || payment.status === PaymentStatus.COMPLETED
      );
      const sortedPayments = orderBy(paidPayments, (payment) => new Date(payment.scheduledDate).getTime(), 'desc');

      return {
        ...bill,
        payments: sortedPayments,
        id: serializePaymentId(bill.id, sortedPayments?.[0]?.id),
      };
    }
  );
  const sortedBills = orderBy(billsWithPayments, (bill) => lastPaymentScheduledDate(bill.payments), 'desc');
  const now = new Date();
  const [thisWeekPayments, beforeThisWeekPayments] = partition(sortedBills, (bill: BillType) =>
    isSameWeek(lastPaymentScheduledDate(bill.payments), now)
  );
  const billsBeforeThisWeek = createBillsGroup({
    header: 'list.group.bill.billsBeforeThisWeek',
    bills: beforeThisWeekPayments,
    sum: sumBy(beforeThisWeekPayments, (bill) => sumBy(bill.payments, 'amount')),
  });
  const billsDueThisWeek = createBillsGroup({
    header: 'list.group.bill.billsDueThisWeek',
    bills: thisWeekPayments,
    sum: sumBy(thisWeekPayments, (bill) => sumBy(bill.payments, 'amount')),
  });

  return [billsDueThisWeek, billsBeforeThisWeek];
};

const groupByPaymentsDataPartialPayments = (
  payments: PaymentType[],
  bills: OrderedSet<RecordOf<BillType>>,
  status: BillStatus,
  requests?: Array<RecordOf<BillType>>
) => {
  let groupedBills: BillsGroup[] = [];
  const paymentsAsBills = OrderedSet(
    payments.map((payment) => getPaymentConvertedToBill(payment.bill as BillType, payment, status))
  );
  switch (status) {
    case BillStatus.PAID:
      groupedBills = groupPaidBillsWithPayments(bills);
      break;
    case BillStatus.SCHEDULED:
      groupedBills = groupScheduledBills(paymentsAsBills);
      break;

    default:
      groupedBills = [...groupFailedPayments(paymentsAsBills), ...groupInboxBillForPartialPayments(bills)];
  }

  if (requests && requests.length > 0) {
    groupedBills = [groupPaymentRequests(requests), ...groupedBills];
  }

  return groupedBills.filter((group) => group.bills && group.bills.length);
};

const groupScheduledBills = (bills: OrderedSet<RecordOf<BillType> | BillType>): BillsGroup[] => {
  const status = BillStatus.SCHEDULED;
  const billsDueThisWeek = createBillsGroup({
    header: 'list.group.bill.scheduled.billsDueThisWeek',
  });
  const billsDueThisMonth = createBillsGroup({
    header: 'list.group.bill.scheduled.billsDueThisMonth',
  });
  const billsForRest = createBillsGroup({
    header: 'list.group.bill.scheduled.billsForLater',
  });
  const pendingApprovalPayments = createBillsGroup({
    header: 'list.group.bill.pendingApprovalPayment',
  });
  const pendingVendorInfo = createBillsGroup({
    header: 'list.group.bill.scheduled.billsWithoutVendorInfo',
  });

  bills.forEach((bill) => {
    const compareDate = new Date(getComparedDate(bill, status));
    const now = new Date();
    const tag = getBillTag(bill);
    const latestPayment = getLatestPayment(bill.payments);
    const deliveryType = latestPayment?.deliveryMethod?.deliveryType;

    if (tag === PaymentApprovalStatus.PENDING) {
      pendingApprovalPayments.bills.push(bill);
      pendingApprovalPayments.sum += bill.totalAmount;
    } else if (deliveryType === DeliveryType.VIRTUAL) {
      pendingVendorInfo.bills.push(bill);
      pendingVendorInfo.sum += bill.totalAmount;
    } else if (isSameWeek(compareDate, now)) {
      billsDueThisWeek.bills.push(bill);
      billsDueThisWeek.sum += bill.totalAmount;
    } else if (isSameMonth(compareDate, now)) {
      billsDueThisMonth.bills.push(bill);
      billsDueThisMonth.sum += bill.totalAmount;
    } else {
      billsForRest.bills.push(bill);
      billsForRest.sum += bill.totalAmount;
    }
  });

  return [pendingApprovalPayments, pendingVendorInfo, billsDueThisWeek, billsDueThisMonth, billsForRest];
};

export { groupByPaymentsDataPartialPayments };
