export const receivingMethodLocations = {
  base: ['/orgs/:orgId/vendors/:vendorId/receiving-methods'],
  ach: {
    select: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/select/:deliveryMethodId?',
    exists: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/exists',
    manual: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/manual',
    manualView: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/manual/:deliveryMethodId',
    edit: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/edit/:deliveryMethodId/bank-details',
    plaid: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/plaid/:deliveryMethodId?',
    cantFind: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/cant-find/:deliveryMethodId?',
    linkBankAccount: '/orgs/:orgId/vendors/:vendorId/receiving-methods/ach/link-bank-account/:deliveryMethodId',
    addFromGetPaidOnboardingWrapper: {
      base: '/orgs/:orgId/vendors/:vendorId/receiving-methods/add-from-get-paid-onboarding/',
      index: '/orgs/:orgId/vendors/:vendorId/receiving-methods/add-from-get-paid-onboarding/*',
    },
    addFromGetPaidSettingsWrapper: {
      base: '/orgs/:orgId/vendors/:vendorId/receiving-methods/add-from-get-paid-settings/',
      index: '/orgs/:orgId/vendors/:vendorId/receiving-methods/add-from-get-paid-settings/*',
    },
  },
  redirect: '/orgs/:orgId/vendors/:vendorId/receiving-methods/redirect',
} as const;
