import { PAGE_EVENT } from 'src/pages/get-pro/consts';
import { EventMappingsTree } from 'src/services/analytics/types';
import { ACTION_MAPPING } from './action-event-mapping';
import { CARD_EVENT_MAPPING } from './card-event-mapping';
import { FILTER_MAPPING } from './filter-event-mapping';
import { MESSAGE_MAPPING } from './message-event-mapping';
import { TAB_MAPPING } from './tab-event-mapping';
import { TABLE_MAPPING } from './table-event-mapping';

export const getProEventsMapping: EventMappingsTree = {
  '/orgs/*/get-pro': {
    'page.view': [PAGE_EVENT, 'pro-table'],
    'pagination-next-page': [PAGE_EVENT, 'pagination-next-page'],
    'pagination-previous-page': [PAGE_EVENT, 'pagination-previous-page'],
    'fab.newFab.get-paid': ['fab', 'new-invoice-button'],
    ...ACTION_MAPPING,
    ...FILTER_MAPPING,
    ...MESSAGE_MAPPING,
    ...TABLE_MAPPING,
    ...TAB_MAPPING,
  },
  '/orgs/*/get-pro/*': {
    'page.view': [PAGE_EVENT, 'pro-table'],
    ...ACTION_MAPPING,
    ...FILTER_MAPPING,
    ...MESSAGE_MAPPING,
    ...TABLE_MAPPING,
    ...TAB_MAPPING,
  },
  '/orgs/*/get-pro/view': {
    'page.view': [PAGE_EVENT, 'view-invoice'],
    ...CARD_EVENT_MAPPING,
  },
  '/orgs/*/get-pro/edit': {
    'page.view': [PAGE_EVENT, 'edit-invoice'],
    ...CARD_EVENT_MAPPING,
  },
  '/orgs/*/get-pro/customer': {
    'page.view': [PAGE_EVENT, 'view-customer'],
    'getPro.customerSummary.invoices': [PAGE_EVENT, 'invoices_filter-invoices-by-customer'],
    'getPro.customerSummary.requests': [PAGE_EVENT, 'requests_filter-requests-by-customer'],
    'getPro.customerSummary.inProgress': [PAGE_EVENT, 'inProgress_filter-inProgress-by-customer'],
    'getPro.customerSummary.paid': [PAGE_EVENT, 'paid_filter-paid-by-customer'],
  },
  '/orgs/*/get-pro/batch-send': {
    'page.view': [PAGE_EVENT, 'batch-send'],
    'getPro.cards.actions.close': [PAGE_EVENT, 'close-drawer'],
    'getPro.cards.actions.send': [PAGE_EVENT, 'send-invoice', { selectedIds: 'selectedIds' }],
  },
};
