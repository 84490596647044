import type { PartsStyleFunction, SystemStyleFunction, SystemStyleObject } from '@chakra-ui/theme-tools';

const parts = ['content', 'header', 'body', 'footer', 'popper', 'arrow', 'closeButton'];
const baseStylePopper: SystemStyleObject = {
  zIndex: 25,
};

const baseStyleContent: SystemStyleFunction = (props) => ({
  '--popper-arrow-bg': 'white',
  '--popper-arrow-shadow-color': 'grey.200',
  '--popper-bg': 'white',
  bg: 'white',
  width: 'xs',
  boxShadow: props.theme.shadows['600'],
  zIndex: 'inherit',
  _focus: {
    outline: 0,
    boxShadow: 'outline',
  },
});

const baseStyleHeader: SystemStyleObject = {
  px: 3,
  py: 2,
  borderBottomWidth: '1px',
};

const baseStyleBody: SystemStyleObject = {
  px: 3,
  py: 2,
};

const baseStyleFooter: SystemStyleObject = {
  px: 3,
  py: 2,
  borderTopWidth: '1px',
};

const baseStyleCloseButton: SystemStyleObject = {
  position: 'absolute',
  borderRadius: 'md',
  top: 1,
  insetEnd: 2,
  padding: 2,
};

const baseStyle: PartsStyleFunction = (props) => ({
  popper: baseStylePopper,
  content: baseStyleContent(props),
  header: baseStyleHeader,
  body: baseStyleBody,
  footer: baseStyleFooter,
  arrow: {},
  closeButton: baseStyleCloseButton,
});

export default {
  parts,
  baseStyle,
};
