import { getValidationErrors } from '@melio/sizzers-js-common';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import unionWith from 'lodash/unionWith';
import { createRestfulSlice } from 'src/helpers/redux/createRestfulSlice';
import {
  DELIVERY_METHOD_CREATE_SUCCESS,
  DELIVERY_METHOD_FETCH_UNILATERAL_REQUEST_DETAILS_SUCCESS,
} from 'src/modules/delivery-methods/delivery-method-actions';
import {
  PAYMENTS_FETCH_DETAILS_WITH_TOKEN_SUCCESS,
  PAYMENTS_FETCH_EMAIL_TO_VENDOR_DETAILS_SUCCESS,
  PAYMENTS_FETCH_PAYMENT_UPSELL_DETAILS_SUCCESS,
} from 'src/modules/payments/payment-actions';
import { REGULAR_BATCH_PAYMENTS_FETCH_BATCH_BILLS_LIST_SUCCESS } from 'src/modules/regular-batch-payments/regular-batch-payments-actions';
import { validateVendorAccountIdentifier } from 'src/pages/vendor-directory/select-vendor/utils';
import { ADD_NEW_DELIVERY_METHOD } from 'src/redux/payBillWizard/actionTypes';
import { vendorsApi } from './api';
import checkVendorPaymentPreferences from './check-vendor-payment-preferences';
import convertLocalVendorToOwned from './convert-local-vendor-to-owned';
import { countVendorBillsSlice } from './count-vendor-bills-slice';
import { vendorInfo } from './vendor-management-slice';
import { fetchVendorMccCode, updateVendorMccCode } from './vendor-mcc-slice';
import vendorsUploadBatchPayments from './vendors-upload-batch';

export const name = 'vendors';

const validateVendor = async (vendor) => {
  const { orgId, billerId, accountIdentifier } = vendor;
  let validationErrors = getValidationErrors('vendor', vendor, Object.keys(vendor));

  if (isEmpty(validationErrors.accountIdentifier)) {
    const accountIdentifierValidationError = await validateVendorAccountIdentifier(orgId, billerId, accountIdentifier);
    validationErrors = merge(validationErrors, accountIdentifierValidationError);
  }

  return isEmpty(validationErrors) ? null : validationErrors;
};

const vendorsStore = createRestfulSlice<any>({
  name,
  schemaName: 'vendor',
  api: {
    fetch: (params) => vendorsApi.getVendorById(params).then(({ object }) => object),
    update: ({ orgId, id, ...params }) => vendorsApi.editVendorById({ orgId, id, params }).then((res) => res.object),
    create: ({ orgId, ...params }) => vendorsApi.createVendor(orgId, params).then((res) => res.object),
    list: (params) => vendorsApi.getVendors(params).then((res) => ({ items: res.objects, totalCount: res.totalCount })),
    delete: ({ orgId, id }: any) => vendorsApi.deleteVendorById(orgId, id),
  },
  extraReducers: {
    [PAYMENTS_FETCH_DETAILS_WITH_TOKEN_SUCCESS](state, { payload }) {
      const { vendor } = payload.payment;
      state.byId[vendor.id] = vendor;
    },
    [PAYMENTS_FETCH_EMAIL_TO_VENDOR_DETAILS_SUCCESS](state: any, { payload }) {
      const { vendor } = payload.payment;
      state.byId[vendor.id] = vendor;
    },
    [PAYMENTS_FETCH_PAYMENT_UPSELL_DETAILS_SUCCESS](state: any, { payload }) {
      const { vendor } = payload.payment;
      state.byId[vendor.id] = vendor;
    },
    [ADD_NEW_DELIVERY_METHOD](state, action) {
      const vendorId = action?.deliveryMethod?.vendorId;

      if (state.byId[vendorId]) {
        state.byId[vendorId].deliveryMethods = unionWith(
          [action.deliveryMethod],
          state.byId[vendorId].deliveryMethods || [],
          (a, b) => a.id === b.id
        );
      }
    },
    [REGULAR_BATCH_PAYMENTS_FETCH_BATCH_BILLS_LIST_SUCCESS]: (state, action) => {
      action.payload.items.forEach((item) => {
        // TODO: remove this once 'bulk-payment' is gone.
        if (item.bill) {
          state.byId[item.bill.vendorId] = item.bill.vendor;
        } else {
          item.payment.bills.forEach((bill) => {
            state.byId[bill.vendorId] = bill.vendor;
          });
        }
      });
    },
    [DELIVERY_METHOD_FETCH_UNILATERAL_REQUEST_DETAILS_SUCCESS](state: any, { payload }) {
      const { vendor } = payload;
      state.byId[vendor.id] = vendor;
    },
    [DELIVERY_METHOD_CREATE_SUCCESS](state, { payload }) {
      if (state.byId[payload.vendorId]) {
        state.byId[payload.vendorId].deliveryMethods = unionWith(
          [payload],
          state.byId[payload.vendorId].deliveryMethods || [],
          (a: any, b: any) => a.id === b.id
        );
      }
    },
  },
  validateFunc: validateVendor,
  slices: {
    vendorInfo,
    createBatch: vendorsUploadBatchPayments,
    convertLocalToOwned: convertLocalVendorToOwned,
    checkVendorPaymentPreferences,
    countBillsByType: countVendorBillsSlice,
    fetchVendorMccCode,
    updateVendorMccCode,
  },
  selectors: {
    byId: (vendorId) => (state) => state[name].byId[vendorId],
    getByIds: (ids) => (state) => ids.map((id) => find(state[name].byId, (vendor) => Number(vendor.id) === Number(id))),
  },
});

export default vendorsStore;
