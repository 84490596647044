import { MIDialog as Dialog } from 'src/components/common/MIDialog';
import { Link } from 'src/core/ds/link';
import { useSiteContext } from 'src/hoc/withSiteContext';
import { DialogType, DialogVariants } from 'src/utils/consts';

type Props = {
  onCancelAction: () => void;
};

export const BlockedForPaymentDialog = ({ onCancelAction }: Props) => {
  const site = useSiteContext();

  return (
    <Dialog
      type={DialogType.CONFIRM}
      variant={DialogVariants.WARNING}
      title="bills.form.blockedForPaymentDialog.title"
      subtitle="bills.form.blockedForPaymentDialog.subtitle"
      subtitleValues={{
        supportEmail: (
          <Link target="_self" href={`mailto:${site.config.support.email}`}>
            {site.config.support.email}
          </Link>
        ),
      }}
      cancelButtonText="dialogs.buttons.close"
      onCancelAction={onCancelAction}
    />
  );
};
