import Box from 'src/core/ds/box';
import { CurrencySign } from 'src/utils/consts';

type Props = { placeholder?: boolean; currencySign?: CurrencySign };

const CurrencySignLabel = ({ placeholder = false, currencySign = CurrencySign.USD }: Props) => (
  <Box textStyle="h2" mt={3} mr={0.5} color={placeholder ? 'grey.500' : 'initial'}>
    {currencySign}
  </Box>
);

export { CurrencySignLabel };
