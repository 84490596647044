import { FinancingPreEligibilityDecision } from 'src/modules/financing/types';
import {
  financingPage,
  getFinancingExperience,
} from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { analytics } from 'src/services/analytics';

type FundingSourcePageLoadedParams = {
  isFinancingEnabled: boolean;
  financingPreEligibility?: FinancingPreEligibilityDecision;
  payBillFlowUUID: string | undefined;
};
export const fundingSourcePageLoaded = ({
  financingPreEligibility,
  isFinancingEnabled,
  payBillFlowUUID,
}: FundingSourcePageLoadedParams) => {
  if (isFinancingEnabled) {
    if (financingPreEligibility) {
      const financingExperience = getFinancingExperience(financingPreEligibility);
      analytics.page('pay-bill', financingPage.addFunding, {
        financingExperience,
        financingPreEligibility,
        ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
      });
    }
  } else {
    analytics.page('pay-bill', financingPage.addFunding);
  }
};

type EditFundingSourcePageLoadedParams = {
  paymentId: number;
  billId: number;
  fundingSourceId: number | undefined;
  payBillFlowUUID: string | undefined;
};

export const editFundingSourcePageLoaded = ({
  paymentId,
  billId,
  fundingSourceId,
  payBillFlowUUID,
}: EditFundingSourcePageLoadedParams) => {
  analytics.page('pay-bill', financingPage.editFunding, {
    billId,
    paymentId,
    ...(fundingSourceId ? { failedOnFSid: fundingSourceId } : {}),
    ...(payBillFlowUUID ? { payBillFlowUUID } : {}),
  });
};
