import * as React from 'react';
import { useSelector } from 'react-redux';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import { IllustrationName } from 'src/core/ds/illustration';
import { profileStore } from 'src/modules/profile/profile-store';
import { getCompanyInfo, getOrgId } from 'src/redux/user/selectors';
import { Role } from 'src/utils/consts';

type Props = {
  onNext: (orgId: number) => void;
};

export const RegistrationFromInvitationSuccess: React.FC<Props> = ({ onNext }: Props) => {
  const companyInfo = useSelector(getCompanyInfo);
  const companyName = companyInfo?.companyName;
  const profile = useSelector(profileStore.selectors.profile);
  const isOwner = useSelector(profileStore.selectors.getUserRole) === Role.OWNER;
  const orgId = useSelector(getOrgId);

  if (isOwner) {
    return (
      <SuccessLayoutPage
        illustration={IllustrationName.checkCircle}
        title="auth.registerFromInvitationSuccessOwner.title"
        text="auth.registerFromInvitationSuccessOwner.subtitle"
        textValues={{ companyName, firstName: profile?.firstName || '' }}
        notes={['auth.registerFromInvitationSuccessOwner.notes']}
        notesTitle="auth.registerFromInvitationSuccessOwner.noteTitle"
        buttonLabel="auth.registerFromInvitationSuccessOwner.buttonLabel"
        buttonAction={() => onNext(orgId)}
      />
    );
  }

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.highFivePurple}
      title="auth.registerFromInvitationSuccess.title"
      text="auth.registerFromInvitationSuccess.subtitle"
      textValues={{ companyName }}
      buttonLabel="auth.registerFromInvitationSuccess.buttonLabel"
      buttonAction={() => onNext(orgId)}
    />
  );
};
