import * as React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FinancingFundingSourceCard } from 'src/components/common/SelectMethods/components/installments/FinancingFundingSourceCard';
import { FinancingFundingSourcePaymentIneligible } from 'src/components/common/SelectMethods/components/installments/FinancingFundingSourcePaymentIneligible';
import { useSetPreSelectedInstallment } from 'src/components/common/SelectMethods/components/installments/hooks/useSetPreSelectedInstallment';
import { getIneligibleTooltip, learnMoreLink } from 'src/components/common/SelectMethods/components/installments/utils';
import { LoadingSelectMethod } from 'src/components/common/SelectMethods/components/LoadingSelectMethod';
import { useOrgId } from 'src/hooks';
import { financingStore } from 'src/modules/financing/financing-store';
import { IneligibilityReason } from 'src/modules/financing/types';
import { usePayBillFinancingData } from 'src/pages/bill/pay/hooks/usePayBillFinancingData';
import { FinancingExperience } from 'src/pages/bill/pay/installments/analytics/financing-analytics-util';
import { useInstallmentsOptionsRequest } from 'src/pages/bill/pay/installments/pages/installments-options/useInstallmentsOptionsRequest';
import { FinancingEligibilityStatus } from 'src/pages/bill/pay/installments/types';
import { getPayment } from 'src/redux/payBillWizard/selectors';

export type CalculatedInstallmentsCardProps = {
  vendorId: number;
  totalAmount: string;
  disabled?: boolean;
};

const getLearnMoreLink = (installmentsOptions) =>
  installmentsOptions?.reason === IneligibilityReason.IneligibleVendor ? learnMoreLink : null;

export const FinancingFundingSource = ({ vendorId, totalAmount }: CalculatedInstallmentsCardProps) => {
  const { payBillFlowUUID } = useSelector(getPayment);
  const orgId = useOrgId();
  const isBusinessOwner = useSelector(financingStore.selectors.businessOwnerDecision.isBusinessOwner);
  const {
    installmentsOptions,
    getInstallmentOptions,
    error,
    loading: loadingOptions,
  } = useInstallmentsOptionsRequest();

  const { preEligibilityData, isInstallmentSelected, setIsInstallmentSelected } = usePayBillFinancingData();

  useSetPreSelectedInstallment();

  useEffect(() => {
    getInstallmentOptions({ orgId, vendorId, paymentAmount: +totalAmount });
  }, [orgId, totalAmount, vendorId]);

  if (!preEligibilityData) return null;

  if (loadingOptions) {
    return <LoadingSelectMethod id="installments" />;
  }

  const showOptionsEnabledView =
    !error && installmentsOptions?.decision === FinancingEligibilityStatus.Eligible && installmentsOptions?.options;

  if (showOptionsEnabledView && Array.isArray(installmentsOptions?.options) && installmentsOptions.intentId) {
    const smallestInstallmentAmount = Math.min(
      ...installmentsOptions.options.map((option) => option.installmentAmount)
    );

    return (
      <FinancingFundingSourceCard
        isSelected={isInstallmentSelected}
        financingExperience={FinancingExperience.STX}
        paymentAmount={+totalAmount}
        installmentAmount={+smallestInstallmentAmount}
        onClick={() => {
          setIsInstallmentSelected(true);
        }}
      />
    );
  }

  const ineligibleTooltipLabel = getIneligibleTooltip({
    eligibilityStatus: installmentsOptions?.decision,
    reason: installmentsOptions?.reason,
    error,
    isBusinessOwner,
  });

  const learnMoreLink = getLearnMoreLink(installmentsOptions);

  return (
    <FinancingFundingSourcePaymentIneligible
      tooltipLabel={ineligibleTooltipLabel}
      paymentAmount={+totalAmount}
      installmentsStatus={installmentsOptions?.decision}
      financingExperience={FinancingExperience.STX}
      learnMoreLink={learnMoreLink}
      payBillFlowUUID={payBillFlowUUID}
    />
  );
};
