import every from 'lodash/every';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { MICard } from 'src/components/common/MICard';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import deliveryMethodsStore from 'src/modules/delivery-methods/delivery-methods-store';
import vendorsStore from 'src/modules/vendors/vendors-store';
import { BatchList } from 'src/pages/contacts/list/components/BatchSettings/BatchList';
import { BatchSettingsFooter } from 'src/pages/contacts/list/components/BatchSettings/BatchSettingsFooter';
import { BatchSettingsHeader } from 'src/pages/contacts/list/components/BatchSettings/BatchSettingsHeader';
import { useBatchChangeVendor } from 'src/pages/contacts/list/components/BatchSettings/useBatchChangeVendor';
import { contactsLocations } from 'src/pages/contacts/locations';
import { batchSendVendorDetailsRequest, isBatchUnilateralEligilibleVendor } from 'src/pages/contacts/utils';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { pushNotification } from 'src/services/notifications';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import {
  ButtonsDirections,
  ContactsTab,
  DeliveryType,
  DialogType,
  DialogVariants,
  NotificationVariant,
} from 'src/utils/consts';
import { isNonNullable } from 'src/utils/isNonNullable';
import { VendorType } from 'src/utils/types';
import { BatchContactsScreenMode } from './consts';

type Props = {
  analyticsProps: Record<string, any>;
  selectedIds: number[];
  removeFromList: (vendorId: number) => void;
  resetSelection: () => void;
};

export const BatchSettings = ({ analyticsProps, selectedIds, removeFromList, resetSelection }: Props) => {
  const history = useHistory();
  const orgId = useSelector(getOrgId);
  const [mode, setMode] = useState<BatchContactsScreenMode>(BatchContactsScreenMode.VIEW);
  const selectedVendors = useSelector<any, readonly VendorType[]>(
    vendorsStore.selectors.getByIds(selectedIds)
  ).filter((vendor): vendor is VendorType<'created'> => isNonNullable(vendor.id));
  const deliveryMethodActions = useStoreActions(deliveryMethodsStore);
  const isEligibleVendors = every(selectedVendors, (vendor) =>
    isBatchUnilateralEligilibleVendor(vendor.isEditable, vendor.deliveryMethods)
  );
  const isEmailsExist = every(selectedVendors, (vendor) => !isEmpty(vendor.contactEmail));
  const count = selectedVendors.length;
  const eligibleVendors = filter(selectedVendors, (vendor) =>
    isBatchUnilateralEligilibleVendor(vendor.isEditable, vendor.deliveryMethods)
  );
  const eligibleVendorsCount = eligibleVendors.length;

  const createOrUpdateVirtualDeliveryMethod = async (deliveryMethod, vendor, existingVirtualDeliveryMethod) => {
    const vendorId = vendor?.id;

    if (existingVirtualDeliveryMethod?.id) {
      const shouldUpdateVirtualAccount = existingVirtualDeliveryMethod.virtualAccount?.email !== vendor.contactEmail;

      if (shouldUpdateVirtualAccount) {
        await deliveryMethodActions.update({
          orgId,
          vendorId,
          id: existingVirtualDeliveryMethod.id,
          deliveryMethod,
        });
      }
    } else {
      await deliveryMethodActions.create({
        orgId,
        vendorId,
        ...deliveryMethod,
      });
    }
  };

  const sendBatchUnilateralAction = () => {
    if (isEmailsExist && isEligibleVendors) {
      showUnilateralDialog();
    } else {
      setMode(BatchContactsScreenMode.UNILATERAL);
    }
  };

  const sendBatchUnilateralRequest = async (vendors: readonly VendorType<'created'>[]) => {
    const vendorIds = vendors.map((vendor) => vendor.id);
    vendors.forEach((vendor) => {
      const existingVirtualDeliveryMethod = vendor.deliveryMethods?.find(
        (dm) => dm.deliveryType === DeliveryType.VIRTUAL
      );
      const virtualDeliveryMethod = {
        deliveryType: DeliveryType.VIRTUAL,
        virtualAccount: {
          email: vendor.contactEmail,
          phone: existingVirtualDeliveryMethod?.virtualAccount?.phone || vendor.contactPhone,
        },
      };
      createOrUpdateVirtualDeliveryMethod(virtualDeliveryMethod, vendor, existingVirtualDeliveryMethod);
    });
    batchSendVendorDetailsRequest(orgId, vendorIds);
    onBatchUnilateralRequestSent();
  };

  const {
    changedVendors,
    validationErrors,
    onEmailChange,
    sendBatchUnilateralRequestWithEmailsValidation,
  } = useBatchChangeVendor(selectedIds, sendBatchUnilateralRequest, analyticsProps);

  const isChangedVendorsEmailsExist = every(changedVendors, (vendor) => !isEmpty(vendor.contactEmail));

  const onBatchUnilateralRequestSent = () => {
    resetSelection();
    analytics.track('contacts', 'batch-unilateral-success', analyticsProps);
    pushNotification({
      type: NotificationVariant.SUCCESS,
      msg: 'vendors.batchSettings.notification.unilateralSuccess',
      textValues: { count: eligibleVendorsCount },
    });
    history.push({
      pathname: generatePath(contactsLocations.index, { orgId, type: ContactsTab.VENDORS }),
    });
  };

  const onDialogCancelClick = () => {
    analytics.track('contacts', 'batch-unilateral-dialog-cancel', analyticsProps);
  };

  const onDialogSubmitClick = async () => {
    analytics.track('contacts', 'batch-unilateral-dialog-submit', analyticsProps);
    sendBatchUnilateralRequest(selectedVendors);
  };

  const [UnilateralDialog, showUnilateralDialog] = useModal(CommonDialog, {
    variant: DialogVariants.SUCCESS,
    type: DialogType.CONFIRM,
    title: 'vendors.batchSettings.unilateralDialog.title',
    textValues: { count },
    description: 'vendors.batchSettings.unilateralDialog.description',
    confirmText: 'vendors.batchSettings.unilateralDialog.confirm',
    buttonsDirection: ButtonsDirections.HORIZONTAL,
    hideIcon: true,
    maxWidth: '56rem',
    minHeight: '28rem',
    onDismiss: onDialogCancelClick,
    confirm: onDialogSubmitClick,
  });

  const actions = [
    {
      label: 'vendors.batchSettings.actions.batchUnilateral',
      action: () => sendBatchUnilateralAction(),
    },
  ];

  const sortedVendors = useMemo(() => {
    if (mode === BatchContactsScreenMode.UNILATERAL) {
      const sortedVendorsByEmail = sortBy(selectedVendors, [
        (v) => isBatchUnilateralEligilibleVendor(v.isEditable, v.deliveryMethods) && (isEmpty(v.contactEmail) ? 0 : 1),
      ]);
      const sortedVendors = sortBy(sortedVendorsByEmail, [
        (v) => (!isBatchUnilateralEligilibleVendor(v.isEditable, v.deliveryMethods) ? 1 : 0),
      ]);

      return sortedVendors;
    }

    return selectedVendors;
  }, [selectedVendors, mode]);

  const hasSelectedManagedVendors = selectedVendors.some((vendor) => vendor.managedBy);

  return (
    <>
      {UnilateralDialog}
      <BatchSettingsHeader
        analyticsProps={analyticsProps}
        actions={actions}
        count={count}
        mode={mode}
        showMenuItems={!hasSelectedManagedVendors}
      />
      <MICard mode="mainSingleScreen">
        <BatchList
          mode={mode}
          vendors={sortedVendors}
          changedVendors={changedVendors}
          validationErrors={validationErrors}
          removeFromList={removeFromList}
          onEmailChange={onEmailChange}
        />
      </MICard>
      <BatchSettingsFooter
        analyticsProps={analyticsProps}
        mode={mode}
        count={eligibleVendorsCount}
        cancel={resetSelection}
        submit={sendBatchUnilateralRequestWithEmailsValidation}
        isDoneDisabled={!isChangedVendorsEmailsExist || isEmpty(eligibleVendors)}
      />
    </>
  );
};
