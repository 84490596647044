import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FinancingPreEligibilityDecision } from 'src/modules/financing/types';
import { useUserPreference } from 'src/modules/users/hooks/useUserPreference';
import { getIsLoggedIn } from 'src/redux/user/selectors';
import { UserPreferencesKey } from 'src/utils/consts';
import { usePayBillFinancingData } from './usePayBillFinancingData';

export const INSTALLMENT_METHOD_FIRST_COUNT = 3;

export const useFinancingFundingSourceViewCount = () => {
  const isUserLoggedIn = useSelector(getIsLoggedIn);
  const { preEligibilityData } = usePayBillFinancingData();

  const isFinancingFundingSourceFTX = preEligibilityData?.decision === FinancingPreEligibilityDecision.Unknown;

  const {
    userPreferenceValue: financingFundingSourceFTXViewsCount,
    setUserPreferenceValue: setFinancingFundingSourceFTXViewsCount,
  } = useUserPreference({
    userPreferenceKey: UserPreferencesKey.FinancingFundingSourceFTXViewsCount,
  });

  const increaseFinancingFundingSourceFTXViewsCount = useCallback(() => {
    const count = financingFundingSourceFTXViewsCount ? +financingFundingSourceFTXViewsCount : 0;

    if (!isUserLoggedIn || !isFinancingFundingSourceFTX || count === INSTALLMENT_METHOD_FIRST_COUNT) {
      return;
    }

    setFinancingFundingSourceFTXViewsCount((count + 1).toString());
  }, [
    setFinancingFundingSourceFTXViewsCount,
    financingFundingSourceFTXViewsCount,
    isUserLoggedIn,
    isFinancingFundingSourceFTX,
  ]);

  return {
    increaseFinancingFundingSourceFTXViewsCount,
    financingFundingSourceFTXViewsCount: financingFundingSourceFTXViewsCount ? +financingFundingSourceFTXViewsCount : 0,
    isFinancingFundingSourceFTX,
  };
};
